import gql from 'graphql-tag'

export const GET_CLASSROOM_BY_VERSION = gql`
  query($id: uuid) {
    classroom_cross_mentoring_versions(where: {id: {_eq: $id}}) {
      name
      id
      created_by_org
      status
      description
      # Period
      start_date
      end_date
      classroom_cross_mentoring_mentees {
        member_id: id
        is_mentee
        is_mentor
        people_work_placement {
          id
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
          global_user {
            id
            name
            avatar
          }
        }
      }
      classrooms {
        id
        academy_course_category {
          name
        }
        privacy
        name
        is_finished
        avatar_url
        global_user {
          id
          name
          avatar
        }
      }
    }
  }
`
