import gql from 'graphql-tag'

export const GET_GLOBAL_MODULE = gql`
  query {
    global_modules {
      id
      name
      routes
    }
  }
`
