import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {useMutation, useQuery} from '@apollo/react-hooks'
import moment from 'moment'

import PaymentPlanForm from '../form/PaymentPlanForm'
import RequestWrapper from '../../../../shared-components/layouts/RequestWrapper'

import {EDIT_PAYMENT_PLAN} from '../../../../../graphql/mutations'
import {GET_PAYMENT_PLAN} from '../../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../../utils'
import {USER_ID} from '../../../../../utils/globals'

const PaymentPlanFormPageComponent = ({
  isAbleToEdit,
  loanId,
  setRefetchLoanInformation,
}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [dataForm, setDataForm] = useState(false)
  const [defaultData, setDefaultData] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const [serviceLoading, setServiceLoading] = useState(false)
  const [showFormAction, setShowFormAction] = useState(false)

  const [editPaymentPlan] = useMutation(EDIT_PAYMENT_PLAN, GQL_FINANCE_OPTIONS)

  const {data, error} = useQuery(GET_PAYMENT_PLAN, {
    variables: {
      loanId: loanId,
    },
    fetchPolicy: 'cache-and-network',
    ...GQL_FINANCE_OPTIONS,
  })

  useEffect(() => {
    const _data = data?.people_work_loans?.[0]

    if (_data) {
      setDataForm({
        ..._data,
      })
      setDefaultData({
        ..._data,
      })
    }
  }, [data])

  useEffect(() => {
    let isChanged = false
    for (const [key, value] of Object.entries(dataForm)) {
      if (key === 'payment_date_start') {
        if (
          new Date(value)?.getTime() !== new Date(defaultData[key])?.getTime()
        ) {
          isChanged = true
          break
        }
      } else {
        if (value !== defaultData[key]) {
          isChanged = true
          break
        }
      }
    }

    setShowFormAction(isChanged)
  }, [dataForm])

  const onEditPaymentPlan = () => {
    setIsSubmit(true)

    if (
      dataForm.amount_per_installment > 0 &&
      dataForm.installment &&
      dataForm.installment_name &&
      dataForm.payment_date_start
    ) {
      setServiceLoading(true)

      delete dataForm?.__typename
      delete dataForm?.loan_amount_w_interest
      dataForm.payment_date_start = moment(dataForm.payment_date_start).format(
        'YYYY-MM-DD'
      )

      const variables = {
        id: loanId,
        last_update_by: USER_ID,
        ...dataForm,
      }

      editPaymentPlan({variables: variables})
        .then(() => {
          enqueueSnackbar('Payment Plan edited', {
            variant: 'success',
            autoHideDuration: 3000,
          })

          setRefetchLoanInformation(true)
        })
        .catch(() => {
          enqueueSnackbar('Edit Payment Plan failed, please try again later', {
            variant: 'error',
            autoHideDuration: 3000,
          })
        })
        .finally(() => {
          setIsSubmit(false)
          setRefetchLoanInformation(false)
          setServiceLoading(false)
        })
    }
  }

  return (
    <RequestWrapper loading={!defaultData} error={error}>
      <form id="edit-payment-plan-form">
        <PaymentPlanForm
          dataForm={dataForm}
          defaultData={defaultData}
          isAbleToEdit={isAbleToEdit}
          isEdit={true}
          isSubmit={isSubmit}
          mutation={onEditPaymentPlan}
          serviceLoading={serviceLoading}
          setDataForm={setDataForm}
          showFormAction={showFormAction}
        />
      </form>
    </RequestWrapper>
  )
}

export default withRouter(PaymentPlanFormPageComponent)
