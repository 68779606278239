import styled, {css} from 'styled-components'

export const WidgetChartWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  align-items: ${props => props.alignItems};
}
`

export const DoughnutContainer = styled.div`
  align-items: center;
  display: flex;
  height: 150px;
  padding: 15px 0;
  position: relative;
  width: 50%;
  z-index: 1;
  ${props =>
    props.isColumnLabelType &&
    css`
      width: 100%;
    `};

  .text-center {
    text-align: center;
  }
`

export const AggregateContainer = styled.div`
  align-items: center;
  color: #414141;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: bold;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  text-align: center;
`
