import React, {useEffect, useState} from 'react'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {FormControl, RadioGroup} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
} from '../../../../../../GlobalStyles'
import {ActionLink, LitleRedText} from '../../../PayrollPageStyles'
import {inputRequiredHelperText} from '../../../payroll-settings/payroll-settings-tabs/formValidation.helper'

import AddEditBankAccount from '../../../../../shared-components/popup/AddEditBankAccountPopup'
import BankRadioItem from './BankRadioItem'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import RequestWrapper from '../../../../../shared-components/layouts/RequestWrapper'
import FieldInformationTooltip from '../../../../../shared-components/tooltip/FieldInformationTooltip'

import {GET_USER_BANK_ACCOUNT} from '../../../../../../graphql/queries'
import {DELETE_BANK_ACCOUNT} from '../../../../../../graphql/mutations'
import {COMPANY_ID} from '../../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../../utils'

const informationHelperText = {
  bank: (
    <>
      <div>Employee bank account</div>
      <div>Main bank account is used for all financial activities</div>
    </>
  ),
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: 30,
  },
}))

export default function BankInputGroup({
  enqueueSnackbar,
  isSubmit,
  userId,
  userName,
  dataForm,
  setDataForm,
}) {
  const classes = useStyles()
  const [bankData, setBankData] = useState(null)
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedDeleteEditBankData, setSelectedDeleteEditBankData] = useState({
    id: null,
    name: null,
  })

  const [deleteBankAccount] = useMutation(
    DELETE_BANK_ACCOUNT,
    GQL_FINANCE_OPTIONS
  )

  const {data, loading, error, refetch} = useQuery(GET_USER_BANK_ACCOUNT, {
    variables: {
      userID: userId,
    },
    ...GQL_FINANCE_OPTIONS,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data) {
      setBankData([
        ...data.main_bank?.map(account => ({
          ...account?.people_profile_bank,
          isMain: true,
          isAdminAllowedToWrite:
            account?.people_profile_bank?.creator_company === COMPANY_ID,
        })),
        ...data.all_bank
          .map(account => {
            if (
              account?.account_number !==
              data.main_bank?.[0]?.people_profile_bank?.account_number
            ) {
              return {
                ...account,
                isAdminAllowedToWrite: account?.creator_company === COMPANY_ID,
              }
            }
          })
          .filter(account => account !== undefined),
      ])
    }
  }, [data])

  const handleDeleteBankAccount = () => {
    const changes = {
      deletedAt: new Date(),
    }
    deleteBankAccount({
      variables: {
        id: selectedDeleteEditBankData?.id,
        changes,
      },
    })
      .then(() => {
        enqueueSnackbar('Bank account deleted successfully', {
          variant: 'success',
        })

        // eslint-disable-next-line eqeqeq
        if (dataForm?.bank_account_id == selectedDeleteEditBankData.id) {
          setDataForm({
            ...dataForm,
            bank_account_id: null,
          })
        }

        refetch()
        setOpenDeleteDialog(false)
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  return (
    <RequestWrapper loading={loading} error={error}>
      <FormChildContainer fullWidth>
        <FormControl style={{marginTop: 0}}>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Bank Account*</TitleName>
            <FieldInformationTooltip title={informationHelperText.bank} />
          </FormChildTitle>

          <FormChildInput>
            {bankData && (
              <div className={classes.root}>
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    defaultValue={dataForm?.bank_account_id?.toString()}
                    onChange={e => {
                      setDataForm({
                        ...dataForm,
                        bank_account_id: e.target.value,
                      })
                    }}
                  >
                    {bankData?.map((account, index) => (
                      <BankRadioItem
                        key={index}
                        value={account}
                        selectedData={selectedDeleteEditBankData}
                        setSelectedData={setSelectedDeleteEditBankData}
                        editButtonAction={() => setOpenAddEditPopup(true)}
                        deleteButtonAction={() => setOpenDeleteDialog(true)}
                      />
                    ))}
                  </RadioGroup>

                  {isSubmit && !dataForm?.bank_account_id && (
                    <LitleRedText>
                      {inputRequiredHelperText(
                        isSubmit,
                        !dataForm?.bank_account_id
                      )}
                    </LitleRedText>
                  )}
                </FormControl>

                <ActionLink
                  style={{marginTop: '15px'}}
                  onClick={() => setOpenAddEditPopup(true)}
                >
                  + Add new bank account
                </ActionLink>
              </div>
            )}
          </FormChildInput>
        </FormControl>
      </FormChildContainer>

      {openDeleteDialog && (
        <DeletePopup
          open={openDeleteDialog}
          id={selectedDeleteEditBankData?.id}
          name={selectedDeleteEditBankData?.name}
          mutation={() => {
            handleDeleteBankAccount()
          }}
          handleClose={() => {
            setSelectedDeleteEditBankData({
              id: null,
              name: null,
            })
            setOpenDeleteDialog(false)
          }}
        />
      )}

      {openAddEditPopup && (
        <AddEditBankAccount
          data={selectedDeleteEditBankData}
          open={openAddEditPopup}
          refetch={refetch}
          role={'organization-finance-admin'}
          userId={userId}
          userName={userName}
          onClose={() => {
            setSelectedDeleteEditBankData({
              id: null,
              name: null,
            })
            setOpenAddEditPopup(false)
          }}
        />
      )}
    </RequestWrapper>
  )
}
