import {Divider, Tab, Tabs} from '@material-ui/core'
import React, {useState} from 'react'
import {WrapperSection} from '../../DetailEmployeeStyles'
import RecommendationItem from './RecommendationItem'
import {dateFormat} from '../../../../../../utils/helpers'

export default function TabRecommendation({
  setOpen,
  recommendation_from,
  recommendation_to,
  isModal = false,
}) {
  const [value, setValue] = useState(0)

  const _renderData = (data, limit = null) => {
    return data.slice(0, limit || data.length).map(res => {
      return (
        <RecommendationItem
          date={isModal ? dateFormat(res.last_modified, 'DD MMM YYYY') : null}
          key={`${res.id}-itm`}
          res={res}
        />
      )
    })
  }

  const handleChange = (e, newValue) => {
    e.preventDefault()
    setValue(newValue)
  }

  function a11yProps(index) {
    return {
      id: `referral-tab-${index}`,
      'aria-controls': `referral-tabpanel-${index}`,
    }
  }

  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="referral">
        <Tab
          label={`Diterima (${recommendation_to.length})`}
          {...a11yProps(0)}
        />
        <Tab
          label={`Dikirimkan (${recommendation_from.length})`}
          {...a11yProps(1)}
        />
      </Tabs>
      <Divider style={{margin: '0px 26px'}} />
      {value === 0 && (
        <WrapperSection>
          {_renderData(recommendation_to, isModal ? null : 2)}
          {!isModal && recommendation_to[0] && (
            <h3 onClick={() => setOpen(true)} className="show-more">
              Tampilankan Lebih Banyak Rujukan...
            </h3>
          )}
        </WrapperSection>
      )}
      {value === 1 && (
        <WrapperSection>
          {_renderData(recommendation_from, isModal ? null : 2)}
          {!isModal && recommendation_from[0] && (
            <h3 onClick={() => setOpen(true)} className="show-more">
              Tampilankan Lebih Banyak Rujukan...
            </h3>
          )}
        </WrapperSection>
      )}
    </>
  )
}
