import React from 'react'
import {TextField} from '@material-ui/core'

import helperStyle, {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
} from '../../GlobalStyles'
import FieldInformationTooltip from '../../pages/shared-components/tooltip/FieldInformationTooltip'

export default function TextInputGroup(props) {
  const {
    dataForm,
    setDataForm,
    defaultValue,
    fieldLabel,
    fieldNumber,
    fieldName,
    helperText = () => {
      return 'This field is required'
    },
    isRequired = true,
    placeholder,
    showErrorHelper = () => false,
    tooltipMesssage,
    ...restProps
  } = props

  const classes = helperStyle()

  return (
    <FormChildContainer>
      <FormChildTitle>
        <TitleNumber>{fieldNumber}.</TitleNumber>
        <TitleName>{fieldLabel}</TitleName>
        {tooltipMesssage && <FieldInformationTooltip title={tooltipMesssage} />}
      </FormChildTitle>

      <FormChildInput>
        <TextField
          fullWidth
          id={`text-field-${fieldNumber}-${fieldName}`}
          className={classes.helperMarginLeft0}
          defaultValue={defaultValue}
          error={showErrorHelper()}
          helperText={showErrorHelper() && helperText()}
          margin="normal"
          placeholder={placeholder}
          size="small"
          variant="outlined"
          required={isRequired}
          onChange={event => {
            setDataForm({
              ...dataForm,
              [fieldName]: event.target.value,
            })
          }}
          {...restProps}
        />
      </FormChildInput>
    </FormChildContainer>
  )
}
