import React, {useState, Fragment} from 'react'
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  InputAdornment,
  Radio,
  Icon,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core'
import {
  GreyTypography,
  GenerateTooltip,
  LineClamp,
} from '../../../../../GlobalStyles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {useSnackbar} from 'notistack'
import TooltipAttachmentGoal from '../../TooltipAttachmentGoal'
import {
  getTypeFile,
  handleChangeAttachment,
  trimString,
} from '../../../../../utils/helpers'
import {COMPANY_ID, TOKEN, UPLOAD_URL} from '../../../../../utils/globals'
import {goalType} from '../../modal/tabs/validate'
import axios from 'axios'
import UploadButton from '../../../../../components/attachment/UploadButton'
import AttachmentItem from '../../../../../components/attachment/AttachmentItem'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {
  FILE_TYPE_UPLOAD,
  priorities,
  // visibilitys,
} from '../../../../shared-components/data/SharedData'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import Select, {components} from 'react-select'
import {
  CHECK_GROUP_GOAL,
  GET_LIST_GOAL_GROUP,
} from '../../../../../graphql/queries'
import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'
import _ from 'lodash'
import {ChromePicker} from 'react-color'
import {
  ADD_NEW_GOAL_GROUP,
  DELETE_GOAL_GROUP,
} from '../../../../../graphql/mutations'
import {FlexBetween, FlexCenter, PsmGrey} from '../../modal/modalStyles'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'

const useStyles = makeStyles(() => ({
  disabled: {
    backgroundColor: '#eff2f4',
  },
  flexAttachment: {display: 'flex', alignItems: 'center'},
  flex: {display: 'flex'},
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 350,
  },
  flexAbsolute: {
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
  },
  outlinedInput: {
    padding: '10px 14px',
  },
  icon: {
    borderRadius: 5,
    padding: 10,
  },
  checkedIcon: {
    borderRadius: 5,
    color: '#fff',
  },
}))

const customSelect = {
  control: provided => ({
    ...provided,
    width: '100%',
    outline: 'none',
    marginTop: 10,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: '#055469',
  }),
  multiValueLabel: base => ({
    ...base,
    color: '#fff',
  }),
  multiValueRemove: base => ({
    ...base,
    color: '#ffff',
    '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
  }),
  menu: base => ({
    ...base,
    maxWidth: '100%',
  }),
}

const DetailStep = props => {
  const {
    dataKpi,
    handleChange,
    change,
    handleDate,
    isError,
    setChange,
    selectedGroup,
    setSelectedGroup,
    colorPicker,
    setColorPicker,
    label,
    setLabel,
    colorOptions,
    setColorOptions,
  } = props
  const classes = useStyles()
  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()
  const [disabledButton, setDisabledButton] = useState(false)
  const INITIAL_ALERT_LABEL = {
    canDelete: true,
    open: false,
    idLabel: null,
  }
  const [alertLabel, setAlertLabel] = useState(INITIAL_ALERT_LABEL)

  const handleChangeFile = e => {
    const tempFile = Array.from(e.target.files)
    const fileCount =
      (tempFile?.length ?? 0) + (change?.attachment?.length ?? 0)

    if (fileCount > 10) {
      enqueueSnackbar('The number of attachments must not exceed 10', {
        variant: 'error',
        autoHideDuration: 1000,
      })
    } else {
      tempFile.forEach(val => {
        if (!FILE_TYPE_UPLOAD.includes(getTypeFile(val.name))) {
          enqueueSnackbar('There are file types that are not allowed', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        } else if (val.size >= 1024 * 1024 * 25) {
          enqueueSnackbar('Some of your attachment size is more than 25MB', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        } else {
          handleChangeAttachment(
            val,
            setDisabledButton,
            setChange,
            axios,
            UPLOAD_URL,
            TOKEN
          )
        }
      })
    }
  }

  const handleDeleteAttachButton = i => {
    const newState = [...change.attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setChange(prevState => ({...prevState, attachment: newState}))
  }

  const handleChangeNameFileAttBtn = (e, i) => {
    const newState = [...change.attachment]
    newState[i].name = e.target.value
    setChange(prevState => ({...prevState, attachment: newState}))
  }

  const disabledStyle = {classes: {disabled: classes.disabled}}

  const handleChangeGroup = dataGroups => {
    const multipleValue = {...dataGroups}

    setChange({...change, group: multipleValue.idGroup})
    setSelectedGroup(oldArray => {
      const arr = [...oldArray, multipleValue]
      arr.reverse()
      const revArr = arr
      const newValue = _.uniqBy(revArr, 'idParent')

      return newValue
    })
  }
  const [deleteGoalGroup] = useMutation(DELETE_GOAL_GROUP)
  const [addGoalGroup] = useMutation(ADD_NEW_GOAL_GROUP, {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-staff',
      },
    },
  })
  const {data: dataGoalGroup, refetch: refetchGoalGroup} = useQuery(
    GET_LIST_GOAL_GROUP,
    {
      variables: {
        company: COMPANY_ID,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const labelOptionsGoal = [
    {
      idGroup: null,
      value: 'Tidak Ada',
      label: 'Tidak Ada',
      color: '#A9A8A8',
      user: 'DEFAULT',
    },
  ]
  const tempDataOptions = dataGoalGroup?.performance_goal_groups?.map(res => {
    const option = {
      idGroup: res.id,
      value: res.name,
      label: res.name,
      color: res.color,
      user: res.added_by,
    }
    labelOptionsGoal.push(option)
  })

  const onSaveLabel = () => {
    const data = {name: label.name, color: label.color}
    addGoalGroup({variables: data})
      .then(() => {
        enqueueSnackbar('Success add new label', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        setLabel({...label, open: false, name: ''})
        refetchGoalGroup()
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleDeleteLabel = async idLabel => {
    const {data, error} = await client.query({
      query: CHECK_GROUP_GOAL,
      variables: {group: idLabel},
    })

    if (error) {
      return console.error({error})
    }

    const lengthLabel = data?.performance_goals?.length
    const isExsist = lengthLabel > 0

    setChange({...change, group: null})
    setSelectedGroup([])
    setAlertLabel({
      canDelete: !isExsist,
      idLabel: idLabel,
      open: true,
    })
  }

  const onDeleteLabel = () => {
    deleteGoalGroup({variables: {id: alertLabel.idLabel}})
      .then(() => {
        enqueueSnackbar('Success delete new label', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        setAlertLabel(INITIAL_ALERT_LABEL)
        refetchGoalGroup()
      })
      .catch(() => {
        enqueueSnackbar('Delete data error, please try again', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const groupMenu = option => {
    const canDelete = option.user !== 'DEFAULT'
    return (
      <FlexBetween>
        <div style={{width: '80%', display: 'flex'}}>
          <div
            style={{
              background: option.color,
              width: 0,
              borderRadius: 5,
              padding: 10,
              marginRight: 12,
            }}
          />
          <LineClamp
            lineClamp={1}
            style={{
              alignSelf: 'center',
              fontSize: 12,
              color: '#a9a8a8',
            }}
          >
            {option.label}
          </LineClamp>
        </div>
        {canDelete && (
          <IconButton
            style={{padding: 0}}
            onClick={() => handleDeleteLabel(option.idGroup)}
          >
            <DeleteOutlineIcon style={{color: '#EF4D5E'}} />
          </IconButton>
        )}
      </FlexBetween>
    )
  }

  const renderValue = option => {
    return (
      <FlexCenter>
        <div
          style={{
            background: option.color || '#A9A8A8',
            width: 0,
            borderRadius: 5,
            padding: 10,
            marginRight: 12,
          }}
        />
        <PsmGrey>{trimString(option.label || 'Tidak Ada', 50)}</PsmGrey>
      </FlexCenter>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={9}>
        <GreyTypography variant="body2">Nama*</GreyTypography>
        <TextField
          id="name"
          variant="outlined"
          margin="dense"
          value={change.goal}
          error={change.goal.length < 1 && isError}
          helperText={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {change.goal.length < 1 && isError && 'Bagian ini diperlukan'}
              </div>
              <div>{`${change.goal.length}/255`}</div>
            </div>
          }
          inputProps={{maxLength: 255}}
          onChange={handleChange('goal')}
          placeholder="Nama"
          fullWidth
        />
      </Grid>

      {/* <Grid item sm={12}>
        <Grid item sm={3}>
          <GreyTypography variant="body2">Visibilitas*</GreyTypography>
          <TextField
            id="visibility"
            variant="outlined"
            margin="dense"
            select
            onChange={handleChange('visibility')}
            fullWidth
            value={change.visibility}
            error={change.visibility.length < 1 && isError}
            helperText={
              change.visibility.length < 1 &&
              isError &&
              'Bagian ini diperlukan'
            }
          >
            {visibilitys.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid> */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <GreyTypography variant="body2">Label</GreyTypography>
            <Select
              value={selectedGroup}
              options={labelOptionsGoal || tempDataOptions}
              onChange={value => handleChangeGroup(value)}
              styles={customSelect}
              placeholder="Pilih Label"
              getOptionLabel={groupMenu}
              components={{
                SingleValue: ({data}) => renderValue(data),
                Menu: props => (
                  <Fragment>
                    <components.Menu {...props}>
                      {props.children}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 16,
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            textTransform: 'capitalize',
                            width: '100%',
                          }}
                          onClick={() => setLabel({...label, open: true})}
                        >
                          Buat Label Baru
                        </Button>
                      </div>
                    </components.Menu>
                  </Fragment>
                ),
              }}
            />

            <GreyTypography variant="body2" style={{marginTop: 20}}>
              Tipe {GLOBAL_GLOSSARY.performance.Goal_short}
            </GreyTypography>
            <TextField
              id="type"
              variant="outlined"
              margin="dense"
              select
              onChange={handleChange('type')}
              fullWidth
              disabled
              value={dataKpi?.type ?? '-'}
              InputProps={disabledStyle}
            >
              <MenuItem value={dataKpi?.type ?? '-'}>
                {goalType[dataKpi?.type] ?? '-'}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={4}>
            {label.open && (
              <div style={{margin: '29px 0px'}}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant="outlined"
                    InputProps={{
                      classes: {
                        input: classes.outlinedInput,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            style={{
                              padding: 10,
                              background: label.color,
                              borderRadius: 5,
                            }}
                          ></div>
                        </InputAdornment>
                      ),
                    }}
                    value={label.name}
                    onChange={event =>
                      setLabel({...label, name: event.target.value})
                    }
                  />
                  <Button
                    style={{marginLeft: 10}}
                    variant="contained"
                    color="primary"
                    disabled={label.name === ''}
                    onClick={onSaveLabel}
                  >
                    Simpan
                  </Button>
                </div>
                <div style={{marginTop: 20}}>
                  <GreyTypography variant="body2">Pilih Warna</GreyTypography>
                  <div className={classes.flexWrap}>
                    {colorOptions.map((col, i) => {
                      return (
                        <Radio
                          key={i}
                          onChange={event =>
                            setLabel({...label, color: event.target.value})
                          }
                          value={col.color}
                          checked={label.color === col.color}
                          icon={
                            <span
                              className={classes.icon}
                              style={{background: col.color}}
                            />
                          }
                          checkedIcon={
                            <Icon
                              className={classes.checkedIcon}
                              style={{
                                background: col.color,
                                fontSize: 20,
                              }}
                            >
                              check
                            </Icon>
                          }
                        ></Radio>
                      )
                    })}
                    <Icon
                      onClick={() => setColorPicker(true)}
                      style={{
                        border: '1px #a9a8a8 solid',
                        color: '#a9a8a8',
                        borderRadius: 5,
                        cursor: 'pointer',
                        fontSize: 19,
                        margin: 9,
                      }}
                    >
                      add
                    </Icon>
                  </div>
                  <div className={classes.flexAbsolute}>
                    {colorPicker && (
                      <Paper style={{padding: 14, marginBottom: 10}}>
                        <Typography variant="body2" gutterBottom>
                          Pilih Warna
                        </Typography>
                        <ChromePicker
                          color={label.color || false}
                          value={label.color}
                          onChangeComplete={color =>
                            setLabel({...label, color: color.hex})
                          }
                        />
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 10,
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{width: '80%'}}
                            onClick={() => {
                              setColorOptions(prev => [
                                ...prev,
                                {color: label.color},
                              ])
                              setColorPicker(false)
                            }}
                          >
                            Tutup
                          </Button>
                        </div>
                      </Paper>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={9}>
        <GreyTypography variant="body2">
          Aspek {GLOBAL_GLOSSARY.performance.Goal}
        </GreyTypography>
        <TextField
          id="aspect"
          variant="outlined"
          margin="dense"
          fullWidth
          value={dataKpi?.kpi_aspect?.name || '-'}
          disabled
          InputProps={disabledStyle}
        />
      </Grid>
      <Grid item sm={9}>
        <GreyTypography variant="body2">Perspektif</GreyTypography>
        <TextField
          id="perspective"
          variant="outlined"
          margin="dense"
          value={dataKpi?.kpi_perspective?.name || '-'}
          disabled
          fullWidth
          InputProps={disabledStyle}
        />
      </Grid>
      <Grid item sm={9}>
        <GreyTypography variant="body2">Keterangan*</GreyTypography>
        <TextField
          id="description"
          variant="outlined"
          margin="dense"
          onChange={handleChange('description')}
          multiline
          rows={4}
          rowsMax={10}
          maxLength={1500}
          error={change.description.length < 1 && isError}
          helperText={
            change.description.length < 1 && isError && 'Bagian ini diperlukan'
          }
          value={change.description}
          fullWidth
        />
      </Grid>

      <Grid item sm={6}>
        <GreyTypography variant="body2">Metrik</GreyTypography>
        <TextField
          id="metric"
          variant="outlined"
          margin="dense"
          value={dataKpi?.formula || '-'}
          fullWidth
          multiline
          rows={3}
          disabled
          InputProps={disabledStyle}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <GreyTypography variant="body2">Target Dasar*</GreyTypography>
            <TextField
              id="target-base"
              variant="outlined"
              margin="dense"
              type="number"
              onChange={handleChange('targetBase')}
              fullWidth
              error={!change.targetBase && isError}
              helperText={
                isError && !change.targetBase && 'Bagian ini diperlukan'
              }
              value={change.targetBase}
            />
          </Grid>
          <Grid item xs={0.5}>
            <GreyTypography variant="body2" style={{marginTop: '2rem'}}>
              {dataKpi?.kpi_weight_unit?.name}
            </GreyTypography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={6}>
        <GreyTypography variant="body2">Sifat Penilaian</GreyTypography>
        <TextField
          id="scoring"
          variant="outlined"
          margin="dense"
          disabled
          value={dataKpi?.kpi_scoring_nature?.name || '-'}
          fullWidth
          InputProps={disabledStyle}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={9}>
          <Grid item xs={4}>
            <GreyTypography variant="body2">Tanggal Mulai*</GreyTypography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                inputVariant="outlined"
                format="yyyy/MM/dd"
                value={change.start}
                onChange={date => handleDate('start', date)}
                name="dateTo"
                error={isError && !change.start}
                helperText={isError && !change.start && 'Bagian ini diperlukan'}
                margin="dense"
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={4}>
            <GreyTypography variant="body2">Tanggal Selesai*</GreyTypography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                inputVariant="outlined"
                format="yyyy/MM/dd"
                value={change.end}
                onChange={date => handleDate('end', date)}
                name="dateTo"
                minDate={change.start}
                margin="dense"
              />
              {isError && !change.end && (
                <p
                  style={{marginTop: 10, color: '#F44336', fontSize: 12}}
                  variant="caption"
                  component="p"
                >
                  Bagian ini diperlukan
                </p>
              )}
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={3}>
        <GreyTypography variant="body2">Prioritas*</GreyTypography>
        <TextField
          id="priority"
          variant="outlined"
          margin="dense"
          select
          onChange={handleChange('priority')}
          fullWidth
          value={change.priority}
          error={change.priority.length < 1 && isError}
          helperText={
            change.priority.length < 1 && isError && 'Bagian ini diperlukan'
          }
        >
          {priorities.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item sm={12}>
        <Grid container>
          <Grid item sm={12}>
            <div className={classes.flexAttachment}>
              <GreyTypography variant="body2">
                Lampiran (Opsional){' '}
              </GreyTypography>
              <GenerateTooltip
                title={<TooltipAttachmentGoal />}
                placement="right"
              >
                <InfoOutlinedIcon
                  style={{marginLeft: 7, width: 16}}
                  color="primary"
                />
              </GenerateTooltip>
            </div>
            <UploadButton
              multiple
              disabled={change?.attachment?.length > 10 || disabledButton}
              onChange={handleChangeFile}
              style={{marginTop: 16}}
            />
            {change?.attachment?.map((res, i) => {
              const {name, size, url, isUploading, loaded} = res
              return (
                <AttachmentItem
                  key={i}
                  name={name}
                  size={size}
                  url={url}
                  onRemove={() => handleDeleteAttachButton(i)}
                  onNameChange={e => handleChangeNameFileAttBtn(e, i)}
                  isUploading={isUploading}
                  progress={loaded}
                  style={{maxWidth: 500, marginTop: 24}}
                />
              )
            })}
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        open={alertLabel.open && !alertLabel.canDelete}
        handleClose={() => setAlertLabel(INITIAL_ALERT_LABEL)}
        feature="Tidak dapat menghapus Label"
        message="Tidak dapat menghapus karena label ini masih digunakan pada RHK atau Rencana Aksi"
        type="Konfirmasi"
      />
      <DeletePopup
        open={alertLabel.open && alertLabel.canDelete}
        handleClose={() => setAlertLabel(INITIAL_ALERT_LABEL)}
        name="label ini"
        description="All goal / task that using this label will be unassigned to any label once you delete this label"
        mutation={onDeleteLabel}
        title="Label ini"
        isClose={false}
        isHighlight={false}
        isTitle={true}
      />
    </Grid>
  )
}

export default DetailStep
