import {GLOBAL_GLOSSARY} from '../../../utils/constant'

export const status_data = {
  ACTIVE: 'Aktif',
  INACTIVE: 'Tidak Aktif',
  DRAFT: 'Draf',
  PENDING: 'Tertunda',
  PUBLISHED: 'Terbit',
  PUBLISH: 'Terbit',
  COMPLETED: 'Selesai',
}

export const Status = [
  {name: status_data.ACTIVE, value: 'ACTIVE'},
  {name: status_data.INACTIVE, value: 'INACTIVE'},
]

export const visibility = {
  PUBLIC: 'Publik',
  PRIVATE: 'Pribadi',
}

export const visibilitys = [
  {
    value: 'PUBLIC',
    label: visibility?.PUBLIC,
  },
  {
    value: 'PRIVATE',
    label: visibility?.PRIVATE,
  },
]

export const priority = {
  HIGH: 'Tinggi',
  MEDIUM: 'Sedang',
  LOW: 'Rendah',
}

export const priorities = [
  {value: 'HIGH', label: priority.HIGH},
  {value: 'MEDIUM', label: priority.MEDIUM},
  {value: 'LOW', label: priority.LOW},
]

export const FILE_TYPE_UPLOAD = [
  'doc',
  'docx',
  'xls',
  'pdf',
  'xlsx',
  'ppt',
  'pptx',
  'jpg',
  'jpeg',
  'png',
  'gif',
  'tiff',
  'pjp',
  'pjpeg',
  'jfif',
  'tif',
]

export const goalTypeOpt = [
  {value: 1, label: GLOBAL_GLOSSARY.performance.Qualitative},
  {value: 2, label: GLOBAL_GLOSSARY.performance.Quantitative},
]

export const status_update_task = {
  TODO: 'Akan Dikerjakan',
  INPROGRESS: 'Sedang dikerjakan',
  DONE: 'Selesai dikerjakan',
  COMPLETED: 'Selesai',
  ARCHIVED: 'Diarsipkan',
}

export const progress_update_task = {
  Below: 'Di bawah',
  Meet: 'Memenuhi',
  Exceed: 'Melebihi',
}

export const level_skill = {
  beginner: 'Pemula',
  intermediate: 'Menengah',
  advanced: 'Lanjutan',
  advance: 'Lanjutan',
}
