import {
  makeStyles,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import {useQuery} from '@apollo/react-hooks'
import {BADGE_INDIVIDUAL} from '../../graphql/queries'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabContainer: {
    marginTop: 15,
  },
  tabRoot: {fontSize: 14},
  iconImg: {
    width: 227,
    borderRadius: 5,
    marginRight: 24,
    objectFit: 'cover',
  },
  mb12: {marginBottom: 12},
  ml12: {marginLeft: 12},
  dividers: {margin: '24px -24px !important'},
  mb8: {marginBottom: 8},
  mb16: {marginBottom: 16},
  avatar: {
    width: 42,
    height: 42,
    margin: '0 24px 0 0',
    borderRadius: 5,
    objectFit: 'cover',
  },
  mainRoot: {
    padding: 24,
  },
  // Card Employee detail
  root: {
    display: 'flex',
    width: '50%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 150,
  },
}))

const CardBadgeIndidual = ({placementId}) => {
  const classes = useStyles()
  const {data: dataBadge, loading: loadingBadge, error: errorBadge} = useQuery(
    BADGE_INDIVIDUAL,
    {
      variables: {
        placement: placementId,
      },
    }
  )

  if (loadingBadge) {
    return <CircularProgress />
  } else if (errorBadge) {
    return <Typography>{JSON.stringify(errorBadge)}</Typography>
  }

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={dataBadge.people_work_placements[0].global_user.avatar}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="subtitle1" color="inherit">
            {dataBadge.people_work_placements[0].global_user.name}
          </Typography>
          <Typography variant="subtitle2" color="primary">
            {dataBadge.people_work_placements[0].regno}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {dataBadge.people_work_placements[0].company_job_profile.title}
          </Typography>
          <Typography variant="subtitle2" color="secondary">
            Pegawai Aktif
          </Typography>
        </CardContent>
      </div>
    </Card>
  )
}

export default CardBadgeIndidual

CardBadgeIndidual.propTypes = {
  placementId: PropTypes.number,
}
