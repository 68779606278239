import gql from 'graphql-tag'

export const GET_CLAIM_SETTINGS = gql`
  query($companyId: uuid!, $search: String, $limit: Int, $offset: Int) {
    total: finance_claim_policy_children_aggregate(
      where: {
        company: {_eq: $companyId}
        confirm_type: {_in: ["supervisor", "position", "free"]}
        deletedAt: {_is_null: true}
        name: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
    policies: finance_claim_policy_children(
      where: {
        company: {_eq: $companyId}
        confirm_type: {_in: ["supervisor", "position", "free"]}
        deletedAt: {_is_null: true}
        name: {_ilike: $search}
      }
      order_by: {name: asc}
      offset: $offset
      limit: $limit
    ) {
      id
      name
      description
      confirm_type
      approver_1: first_job_profile {
        id
        title
        placements: people_work_placements(
          where: {
            company: {_eq: $companyId}
            status: {_eq: "ACTIVE"}
            deletedAt: {_is_null: true}
          }
        ) {
          id
          user: global_user {
            id
            name
          }
        }
      }
      approver_2: second_job_profile {
        id
        title
        placements: people_work_placements(
          where: {
            company: {_eq: $companyId}
            status: {_eq: "ACTIVE"}
            deletedAt: {_is_null: true}
          }
        ) {
          id
          user: global_user {
            id
            name
          }
        }
      }
      applies_to: company_job_profile_r_claims(
        order_by: {company_job_profile: {title: asc}}
        limit: 9
      ) {
        profile: company_job_profile {
          id
          title
        }
      }
    }
  }
`

export const CHECK_CLAIM_POLICY_DUPLICATE = gql`
  query($where: finance_claim_policy_children_bool_exp) {
    total: finance_claim_policy_children_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_CLAIM_POLICY_DETAILS = gql`
  query($companyId: uuid!, $policyId: Int!) {
    details: finance_claim_policy_children_by_pk(id: $policyId) {
      id
      name
      description
      confirm_type
      filter_position
      approver_1: first_job_profile {
        id
        code
        title
        placements: people_work_placements(
          where: {
            company: {_eq: $companyId}
            status: {_eq: "ACTIVE"}
            deletedAt: {_is_null: true}
          }
        ) {
          id
          user: global_user {
            id
            name
          }
        }
      }
      approver_2: second_job_profile {
        id
        code
        title
        placements: people_work_placements(
          where: {
            company: {_eq: $companyId}
            status: {_eq: "ACTIVE"}
            deletedAt: {_is_null: true}
          }
        ) {
          id
          user: global_user {
            id
            name
          }
        }
      }
      applies_to: company_job_profile_r_claims {
        profile: company_job_profile {
          id
        }
      }
    }
  }
`

export const GET_CLAIM_APPLIES = gql`
  query($policyId: Int!, $search: String, $offset: Int, $limit: Int) {
    available: company_job_profile_r_claim_aggregate(
      where: {finance_claim_policy_child: {id: {_eq: $policyId}}}
    ) {
      aggregate {
        count
      }
    }
    total: company_job_profile_r_claim_aggregate(
      where: {
        finance_claim_policy_child: {id: {_eq: $policyId}}
        company_job_profile: {
          _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    positions: company_job_profile_r_claim(
      where: {
        finance_claim_policy_child: {id: {_eq: $policyId}}
        company_job_profile: {
          _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
        }
      }
      order_by: {company_job_profile: {title: asc}}
      limit: $limit
      offset: $offset
    ) {
      profile: company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_CLAIM_POSITION_DROPDOWN = gql`
  query($companyId: uuid!, $search: String) {
    positions: company_job_profiles(
      where: {
        company: {_eq: $companyId}
        deletedAt: {_is_null: true}
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
      }
      order_by: {title: asc}
      limit: 20
    ) {
      id
      code
      title
    }
  }
`

export const GET_CLAIM_POSITION_COUNT = gql`
  query(
    $companyId: uuid!
    $filterPosition: Int_comparison_exp
    $filterOffice: Int_comparison_exp
    $filterJobLevel: Int_comparison_exp
    $filterJobGrade: Int_comparison_exp
    $filterJobTitle: Int_comparison_exp
    $filterJobFunction: Int_comparison_exp
    $filterJobFamily: Int_comparison_exp
    $filterOrgUnit: Int_comparison_exp
    $filterOrgLevel: Int_comparison_exp
  ) {
    total: company_job_profiles_aggregate(
      where: {
        company: {_eq: $companyId}
        id: $filterPosition
        office_address: $filterOffice
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        company_employee_position: {
          id: $filterJobTitle
          family_id: $filterJobFamily
          job_mapping_id: $filterJobFunction
        }
        company_organization: {
          id: $filterOrgUnit
          organization_level_id: $filterOrgLevel
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_CLAIM_POSITION_LIST = gql`
  query($limit: Int, $offset: Int, $where: company_job_profiles_bool_exp) {
    total: company_job_profiles_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    positions: company_job_profiles(
      where: $where
      order_by: {title: asc}
      limit: $limit
      offset: $offset
    ) {
      id
      code
      title
    }
  }
`
