import gql from 'graphql-tag'

export const IMPORT_DATA = gql`
  mutation($table: String, $file: String) {
    insert_company_import_data_logs_one(
      object: {table_name: $table, data_url: $file}
    ) {
      date_added
    }
  }
`
export const IMPORT_DATA_FILTER = gql`
  mutation($table: String, $file: String, $filter: jsonb) {
    insert_company_import_data_logs_one(
      object: {table_name: $table, data_url: $file, filter: $filter}
    ) {
      date_added
    }
  }
`
export const CLOSE_BALOON = gql`
  mutation($id: Int) {
    update_company_import_data_logs(
      _set: {viewed: true}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const CLOSE_BALOON_IMPORT_MULTIPLE = gql`
  mutation($id: [Int!]!) {
    update_company_import_data_logs(
      _set: {viewed: true}
      where: {id: {_in: $id}}
    ) {
      affected_rows
    }
  }
`
