import gql from 'graphql-tag'

export const ASSIGN_TO_COURSE = gql`
  mutation($filter: JSON!, $courseId: Int!) {
    courseOrgAssign(courseId: $courseId, filter: $filter) {
      success
      orgAssignId
    }
  }
`
