import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import {TextFieldStyledNoLine} from '../../GlobalStyles'

const options = ['Latest', 'Oldest', 'A to Z', 'Z to A']

export default function SortMenuList({
  setSelectedPeriodSort = () => {},
  setSelectedAlphabetSort = () => {},
}) {
  const [sort, setSort] = React.useState(0)

  const handleMenuItemClick = value => {
    setSort(value)
    setSelectedPeriodSort(value === 0 ? 'desc' : value === 1 ? 'asc' : null)
    setSelectedAlphabetSort(value === 2 ? 'asc' : value === 3 ? 'desc' : null)
  }

  return (
    <TextFieldStyledNoLine
      onChange={e => handleMenuItemClick(e.target.value)}
      select
      style={{width: 'auto', minWidth: 90}}
      value={sort}
    >
      {options.map((label, index) => (
        <MenuItem key={index} value={index}>
          {label}
        </MenuItem>
      ))}
    </TextFieldStyledNoLine>
  )
}
