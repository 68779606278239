import gql from 'graphql-tag'

export const ADD_HOLIDAY = gql`
  mutation(
    $name: String
    $description: String
    $type: String
    $recurring: Int
    $recur_time_x: Int
    $date: date
    $recur_end_opt: Int
    $recur_end_x: Int
    $recur_end_date: date
  ) {
    insert_time_holidays_one(
      object: {
        name: $name
        description: $description
        type: $type
        recurring: $recurring
        recur_time_x: $recur_time_x
        date: $date
        recur_end_opt: $recur_end_opt
        recur_end_x: $recur_end_x
        recur_end_date: $recur_end_date
      }
    ) {
      id
    }
  }
`

export const EDIT_HOLIDAY = gql`
  mutation(
    $id: Int
    $name: String
    $description: String
    $type: String
    $recurring: Int
    $recur_time_x: Int
    $date: date
    $recur_end_opt: Int
    $recur_end_x: Int
    $recur_end_date: date
  ) {
    update_time_holidays(
      _set: {
        name: $name
        description: $description
        type: $type
        recurring: $recurring
        recur_time_x: $recur_time_x
        date: $date
        recur_end_opt: $recur_end_opt
        recur_end_x: $recur_end_x
        recur_end_date: $recur_end_date
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const ADD_EVENT = gql`
  mutation addEventSchedules($object: [event_schedules_insert_input!]!) {
    insert_event_schedules(objects: $object) {
      affected_rows
    }
  }
`

export const ADD_COMPANY_HOLIDAY = gql`
  mutation($objects: [time_holidays_insert_input!]!) {
    insert_time_holidays(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`
