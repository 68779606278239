import React, {useState} from 'react'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  Flex,
  CustomTypography,
} from '../../../../GlobalStyles'
import {Divider, IconButton} from '@material-ui/core'
import {withRouter} from 'react-router-dom'

import ArrowBack from '@material-ui/icons/ArrowBack'

const CategoryDetailPage = props => {
  const state = props?.location?.state

  const [detail, setDetail] = useState({
    compCategory: '',
    compName: '',
    compCode: '',
    type: '',
    reqLevel: '',
    description: '',
  })

  React.useEffect(() => {
    if (!state?.data) {
      props.history.push('/learning/competency/model')
    } else {
      const {data} = state
      const desc = data.competency_version_dictionary.competency_dictionary.description.levels.find(
        val => val.number === data.level
      )
      setDetail({
        compCategory:
          data?.competency_version_dictionary?.competency_dictionary
            ?.competency_category?.name,
        compName:
          data?.competency_version_dictionary?.competency_dictionary?.name?.en,
        compCode:
          data?.competency_version_dictionary?.competency_dictionary
            ?.competency_category?.code,
        type: data?.competency_version_dictionary?.competency_dictionary?.type,
        reqLevel: `Level ${data.level}`,
        description: desc.description,
      })
    }
  }, [state])

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => props.history.goBack()}
            style={{color: '#000', marginLeft: 10}}
          >
            <ArrowBack />
          </IconButton>
          <CustomTypography fweight="bold" left="10px">
            {detail.compName}
          </CustomTypography>
        </FormTitleContainer>
      </FormToolbar>
      <Divider />
      <div
        style={{
          padding: '1.6rem',
        }}
      >
        <Flex column style={{gap: '15px'}}>
          <Flex style={{gap: '10px'}}>
            <CustomTypography fweight="bold" style={{minWidth: '10rem'}}>
              Kategori Kompetensi
            </CustomTypography>
            <div>:</div>
            <CustomTypography fweight="bold">
              {detail.compCategory}
            </CustomTypography>
          </Flex>
          <Flex style={{gap: '10px'}}>
            <CustomTypography fweight="bold" style={{minWidth: '10rem'}}>
              Kode Kompetensi
            </CustomTypography>
            <div>:</div>
            <CustomTypography fweight="bold">
              {detail.compCode}
            </CustomTypography>
          </Flex>
          <Flex style={{gap: '10px'}}>
            <CustomTypography fweight="bold" style={{minWidth: '10rem'}}>
              Nama Kompetensi
            </CustomTypography>
            <div>:</div>
            <CustomTypography fweight="bold">
              {detail.compName}
            </CustomTypography>
          </Flex>
          <Flex style={{gap: '10px'}}>
            <CustomTypography fweight="bold" style={{minWidth: '10rem'}}>
              Tipe
            </CustomTypography>
            <div>:</div>
            <CustomTypography fweight="bold">{detail.type}</CustomTypography>
          </Flex>
          <Flex style={{gap: '10px'}}>
            <CustomTypography fweight="bold" style={{minWidth: '10rem'}}>
              Required Level
            </CustomTypography>
            <div>:</div>
            <CustomTypography fweight="bold">
              {detail.reqLevel}
            </CustomTypography>
          </Flex>
          <Flex style={{gap: '10px'}}>
            <CustomTypography fweight="bold" style={{minWidth: '10rem'}}>
              Keterangan Level
            </CustomTypography>
            <div>:</div>
            <CustomTypography
              fweight="bold"
              style={{marginTop: -17}}
              dangerouslySetInnerHTML={{
                __html: detail.description || '',
              }}
            />
          </Flex>
        </Flex>
      </div>
    </PaperContainer>
  )
}

export default withRouter(CategoryDetailPage)
