import gql from 'graphql-tag'

export const UPDATE_ARCHIVE_OM = gql`
  mutation($id: Int!, $obj: company_archived_logs_set_input!) {
    update_company_archived_logs_by_pk(pk_columns: {id: $id}, _set: $obj) {
      id
      name
      description
      status
      created_at
    }
  }
`

export const DELETE_ARCHIVE_OM = gql`
  mutation($id: Int) {
    update_company_archived_logs(
      where: {id: {_eq: $id}}
      _set: {deleted_at: "now()"}
    ) {
      affected_rows
    }
  }
`
