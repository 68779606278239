import React from 'react'
import {PageContainer} from '../../../GlobalStyles'
import Card from '../../../components/card/CardComponent'
import ListHistory from './list-history/ListHistory'

export default function HistoryPage() {
  const [searchText, setSearchText] = React.useState('')

  return (
    <Card icon="device_hub" title="Riwayat" onSearch={setSearchText}>
      <PageContainer>
        <ListHistory searchText={searchText} />
      </PageContainer>
    </Card>
  )
}
