import React, {useEffect, useContext} from 'react'
import {Route} from 'react-router-dom'
import {DataContext} from '../../ContextConfig'

const PrivateRoute = ({component: Component, ...props}) => {
  const {setDataContext} = useContext(DataContext)
  useEffect(() => {
    if (props.refreshContext) {
      setDataContext(null)
    }
  }, [])

  return <Route {...props} render={props => <Component {...props} />} />
}

export default PrivateRoute
