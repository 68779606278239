import React, {useState, useEffect} from 'react'
import Chart from 'react-apexcharts'
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {CenterWrapper, MediumTextBlackBold} from '../../PayrollPageStyles'
import styled from 'styled-components'
import {Flex, GreyTypography} from '../../../../../GlobalStyles'
import {
  GET_COMPONENT_ANALYTICS,
  GET_LIST_MONTHLY_COMPONENT,
  GET_LIST_MONTHLY_SALARY,
} from '../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../../utils/globals'
import {CircularProgress} from '@material-ui/core'
import moment from 'moment'
import {GQL_FINANCE_OPTIONS} from '../../utils'

const MonthlyPayment = () => {
  const [startDate, setStartDate] = useState(
    moment()
      .add(-11, 'month')
      .startOf('month')
      .format('YYYY-MM')
  )
  const [endDate, setEndDate] = useState(
    moment()
      .startOf('month')
      .format('YYYY-MM')
  )
  const [range, setRange] = useState(dateRange(startDate, endDate))
  const [showChart, setShowChart] = useState(false)
  const [series, setSeries] = useState([])

  const {data: componentList, loading, error} = useQuery(
    GET_COMPONENT_ANALYTICS,
    {
      variables: {
        company: COMPANY_ID,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )
  const {data: monthlyComponent, loading: loadingMonthlyComp} = useQuery(
    GET_LIST_MONTHLY_COMPONENT,
    {
      variables: {
        company: COMPANY_ID,
        year_start: Number(startDate.split('-')[0]),
        year_end: Number(endDate.split('-')[0]),
        month_start: Number(startDate.split('-')[1]),
        month_end: Number(endDate.split('-')[1]),
        // component:n
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )
  const {data: monthlySalary, loading: loadingMonthlySalary} = useQuery(
    GET_LIST_MONTHLY_SALARY,
    {
      wlb_skipPatch: true,
      variables: {
        company: COMPANY_ID,
        year_start: Number(startDate.split('-')[0]),
        year_end: Number(endDate.split('-')[0]),
        month_start: Number(startDate.split('-')[1]),
        month_end: Number(endDate.split('-')[1]),
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  const onChangeDate = (value, type) => {
    setShowChart(false)
    if (type === 'start') {
      setStartDate(value)
      setRange(dateRange(value, endDate))
    } else {
      setEndDate(value)
      setRange(dateRange(startDate, value))
    }
  }

  useEffect(() => {
    if (componentList && monthlySalary && monthlyComponent) {
      if (
        componentList.finance_nonfixed_components &&
        monthlySalary.v_payroll_dashboard_monthly_salary &&
        monthlyComponent.v_payroll_dashboard_monthly_component
      ) {
        const {finance_nonfixed_components: component} = componentList
        const {v_payroll_dashboard_monthly_salary: salaries} = monthlySalary
        const {
          v_payroll_dashboard_monthly_component: componentSpend,
        } = monthlyComponent

        // ITERATION DATA FOR SALARY
        const salarySeries = arrayDateRange(startDate, endDate).map(date => {
          let dataEachMonth = 0
          const year = Number(date.split('-')[0])
          const month = Number(date.split('-')[1])

          salaries.forEach(s => {
            if (s.month === month && s.year === year) {
              dataEachMonth = s.total
            }
          })
          return dataEachMonth
        })

        // ITERATION DATA FOR COMPONENTS
        const componentMonthly = component.map(comp => {
          const filteredComponent = componentSpend.filter(
            c => c.component === comp.id
          )
          const componentDataSeries = arrayDateRange(startDate, endDate).map(
            date => {
              let dataEachMonth = 0
              const year = Number(date.split('-')[0])
              const month = Number(date.split('-')[1])
              filteredComponent.forEach(fc => {
                if (fc.month === month && fc.year === year) {
                  dataEachMonth = fc.total
                }
              })
              return dataEachMonth
            }
          )
          return {name: comp.name, data: componentDataSeries}
        })
        const newSeries = [{name: 'Salary', data: salarySeries}].concat(
          componentMonthly
        )
        setSeries(newSeries)
        setShowChart(true)
      }
    }
  }, [componentList, monthlySalary, monthlyComponent, startDate, endDate])

  return (
    <div style={{padding: '12px 24px'}}>
      <MediumTextBlackBold>Monthly Payments</MediumTextBlackBold>
      <Flex style={{margin: '10px 0'}}>
        <DatePickerWrap width="160px">
          <GreyTypography>Start</GreyTypography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              views={['month', 'year']}
              className="date-picker-dialog"
              KeyboardButtonProps={{'aria-label': 'change date'}}
              inputVariant="outlined"
              format="yyyy-MM"
              placeholder="Start Date"
              maxDate={
                endDate === null
                  ? null
                  : moment(endDate)
                      .add(-1, 'month')
                      .endOf('month')
              }
              value={startDate}
              color={'primary'}
              onChange={value =>
                onChangeDate(moment(value).format('YYYY-MM-DD'), 'start')
              }
            />
          </MuiPickersUtilsProvider>
        </DatePickerWrap>
        <DatePickerWrap width="160px">
          <GreyTypography>End</GreyTypography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              views={['month', 'year']}
              className="date-picker-dialog"
              KeyboardButtonProps={{'aria-label': 'change date'}}
              inputVariant="outlined"
              placeholder="End Date"
              format="yyyy-MM"
              size="small"
              minDate={
                startDate === null
                  ? null
                  : moment(startDate)
                      .add(1, 'month')
                      .startOf('month')
              }
              value={endDate}
              /* eslint-disable no-unused-vars */
              onChange={value =>
                onChangeDate(moment(value).format('YYYY-MM-DD'), 'end')
              }
            />
          </MuiPickersUtilsProvider>
        </DatePickerWrap>
      </Flex>
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {(loading || loadingMonthlyComp || loadingMonthlySalary) && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {componentList && monthlyComponent && monthlySalary && showChart && (
        <Chart
          options={{
            chart: {
              type: 'bar',
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: false,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom',
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            xaxis: {
              categories: range,
            },
            legend: {
              position: 'bottom',
              height: 100,
              showForSingleSeries: false,
              showForNullSeries: false,
              showForZeroSeries: false,
              itemMargin: {
                vertical: 5,
              },
            },
            fill: {
              opacity: 1,
            },
          }}
          series={series}
          type="bar"
          width="100%"
        />
      )}
    </div>
  )
}

export default MonthlyPayment

export const DatePickerWrap = styled.div`
  margin-right: 20px;
  border-radius: 5px;
  width: ${props => {
    if (props.width) {
      return props.width
    } else {
      return '200px'
    }
  }};
  .MuiFormControl-root {
    width: 100%;
    background-color: white;
    border-radius: 5px;
  }
  .MuiFormControl-marginNormal {
    margin-top: 6px;
  }
  .MuiOutlinedInput-input {
    padding: 8px 10px;
  }
  .MuiSelect-root
    .MuiSelect-select
    .MuiSelect-selectMenu
    .MuiInputBase-input
    .MuiInput-input {
    padding-left: 10px;
  }
  .MuiSvgIcon-root {
    color: #014a62;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`

function dateRange(startDate, endDate) {
  var start = startDate.split('-')
  var end = endDate.split('-')
  var startYear = parseInt(start[0])
  var endYear = parseInt(end[0])
  var dates = []
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j
      dates.push([months[month], i].join(' '))
    }
  }
  return dates
}

function arrayDateRange(startDate, endDate) {
  var start = startDate.split('-')
  var end = endDate.split('-')
  var startYear = parseInt(start[0])
  var endYear = parseInt(end[0])
  var dates = []

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j
      dates.push([i, month + 1].join('-'))
    }
  }
  return dates
}
