import gql from 'graphql-tag'

export const GET_COURSE = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: academy_courses_aggregate(where: {title: {_ilike: $search}}) {
      aggregate {
        count
      }
    }

    academy_courses(
      where: {title: {_ilike: $search}}
      offset: $offset
      limit: $limit
    ) {
      id
      title
    }
  }
`
