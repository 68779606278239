import React, {useState} from 'react'
import {
  PaperContainer,
  AddEditTitle,
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  FormChildInput,
  TitleName,
  FormChildButton,
} from '../../../../GlobalStyles'
import {Divider, Toolbar, IconButton, Button} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {TextFieldStyled} from '../MultiraterNpsPageStyles'

const EditRespondents = () => {
  const [openPopup, setPopup] = useState(false)

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link
          to={{
            pathname: `/survey/multirater-nps/respondents`,
            state: {active: 'respondents'},
          }}
        >
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <AddEditTitle variant="h6">Edit Asesi</AddEditTitle>
      </Toolbar>
      <Divider></Divider>
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Name</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextFieldStyled fullWidth margin="normal" variant="outlined" />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Email</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextFieldStyled fullWidth margin="normal" variant="outlined" />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Organization Unit</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextFieldStyled fullWidth margin="normal" variant="outlined" />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Designation</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextFieldStyled fullWidth margin="normal" variant="outlined" />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Level</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextFieldStyled fullWidth margin="normal" variant="outlined" />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>6.</TitleNumber>
            <TitleName>Office Address</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextFieldStyled fullWidth margin="normal" variant="outlined" />
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <FormChildButton>
        <Button
          color="default"
          size="large"
          component={Link}
          to={{
            pathname: `/survey/multirater-nps/respondents`,
            state: {active: 'respondents'},
          }}
          style={{marginRight: 12}}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          size="large"
        >
          Save
        </Button>
      </FormChildButton>
      <AddEdit
        handleClose={handleClose}
        open={openPopup}
        name={'New Asesi'}
        type={'Add'}
        feature={''}
      />
    </PaperContainer>
  )
}

export default withRouter(EditRespondents)
