import styled from 'styled-components'
import {Typography} from '@material-ui/core'

export const competencyOptions = {
  selection: false,
  emptyRowsWhenPaging: false,
  headerStyle: {
    fontSize: 14,
  },
  rowStyle: {
    fontSize: 14,
    wordBreak: 'break-word',
  },
}

export const TextContentTitle = styled(Typography)`
  font-weight: 700;
  font-size: 13px;
  min-width: 10rem;
`

export const TextContent = styled(Typography)`
  font-size: 13px;
  line-height: 2.5;
  margin-top: -5px;
`
