import React from 'react'
import {Wrapper, LeftWrapper, RightWrapper} from './ErrorStyles'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'

const NotFoundComponent = () => {
  return (
    <Wrapper>
      <LeftWrapper>
        <img src={require('../../assets/images/404.png')} />
      </LeftWrapper>
      <RightWrapper>
        <h2>Oops, Page not found</h2>
        <p>
          We are really sorry for the inconvenience. It look like you trying to
          access a page that has been deleted or never existed
        </p>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={{
            pathname: '/dashboard',
          }}
        >
          Back to Homepage
        </Button>
      </RightWrapper>
    </Wrapper>
  )
}

export default NotFoundComponent
