import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'

import StructureComponent from './StructureComponent'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'member-structure':
      return <StructureComponent state={state} searchText={searchText} />
    default:
      return <StructureComponent state={state} searchText={searchText} />
  }
}

const StructurePageComponent = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <div>
      <Card
        icon="business"
        title="Structure"
        onSearch={onSearch}
        hidden={'hidden'}
      >
        <PageContainer style={{margin: '0 -20px'}}>
          {renderComponent(active, state, searchText)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default StructurePageComponent
