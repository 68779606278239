import React, {useState} from 'react'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {WrapperSection} from '../../DetailEmployeeStyles'
import {OhsaItem} from './OhsaItem'
import {dateFormat} from '../../../../../../utils/helpers'
import AddEdit from './AddEdit'
import {ButtonAdd} from '../../SharedDetailEmployee'

export default function Ohsa({
  people_profile_ohsa,
  user_name,
  user_name_long,
  refetch,
  setAction,
  enqueueSnackbar,
  userIdDetail,
  action,
  INITIAL_ACTION_STATE,
}) {
  const [open, setOpen] = useState(false)

  const handlePreview = () => {}

  const renderValue = (value, secondValue) => {
    if (value !== null && value !== '' && value !== undefined) {
      return value
    } else {
      return secondValue
    }
  }
  const _renderData = (data, limit = null) => {
    return data.slice(0, limit || data.length).map(res => {
      return (
        <OhsaItem
          key={`${res.id}-itm`}
          onPreview={handlePreview}
          company_name={res.company_name || '-'}
          company_location={renderValue(
            res.company_location.company_location,
            '-'
          )}
          category={res.category || '-'}
          employees={res.employees.name || '-'}
          time={dateFormat(res.incident_date_time, 'HH:mm') || '-'}
          date={dateFormat(res.incident_date_time, 'll') || '-'}
          severity={res.severity || '-'}
          description={res.description || '-'}
          incident_code={res.incident_code || '-'}
          incident_location={renderValue(
            res.incident_location.incident_location,
            '-'
          )}
          attachments={res.attachments || []}
          onEdit={() =>
            handleOpenForm({
              actionType: 'edit',
              data: res,
              isFromModal: action.isFromModal,
            })
          }
        />
      )
    })
  }
  const handleCloseAction = () => {
    if (action.isFromModal) {
      setOpen(true)
    }
    setAction(INITIAL_ACTION_STATE)
  }
  const handleOpenForm = ({actionType, data = null, isFromModal}) => {
    if (isFromModal) {
      setOpen(false)
    }
    setAction(e => ({
      ...e,
      open: true,
      actionType,
      data,
      isFromModal,
    }))
  }

  return (
    <>
      <WrapperSection>
        {_renderData(people_profile_ohsa, 2)}
        {people_profile_ohsa[0] && (
          <h3 onClick={() => setOpen(true)} className="show-more">
            Tampilkan Lebih Banyak Riwayat Kesehatan dan Keselamatan Kerja...
          </h3>
        )}
      </WrapperSection>

      <ModalDetail
        onClose={() => setOpen(false)}
        open={open}
        title={`Riwayat Kesehatan dan Keselamatan Kerja ${user_name}`}
      >
        {_renderData(people_profile_ohsa)}
        <ButtonAdd
          label="Riwayat Kesehatan dan Keselamatan Kerja"
          onClick={() =>
            handleOpenForm({
              actionType: 'add',
              isFromModal: true,
            })
          }
        />
      </ModalDetail>
      <AddEdit
        setAction={setAction}
        refetch={refetch}
        enqueueSnackbar={enqueueSnackbar}
        userIdDetail={userIdDetail}
        open={action.open}
        data={action.data}
        onClose={handleCloseAction}
        actionType={action.actionType}
        user_name_long={user_name_long}
      />
    </>
  )
}
