import {Dialog, IconButton, makeStyles, Typography} from '@material-ui/core'
import React from 'react'
import {
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
} from '../../../../GlobalStyles'

const useStyles = makeStyles(() => ({
  gutterBig: {
    marginBottom: 20,
  },

  ul: {paddingLeft: 16},
  li: {marginBottom: 12, fontSize: 12, lineHeight: 1.5},
}))

const InformationPopUp = ({open, handleClose, quota, isUnlimited}) => {
  const classes = useStyles()
  const informationText = [
    {
      strongText: 'Employee Leave and Permission Balance',
      description: ` is a column containing the standard balance of leave for each employee, with a standard balance value of ${
        isUnlimited ? `unlimited` : `${quota} days`
      }`,
    },
    {
      strongText: 'Carry Over',
      description:
        ' is a column containing the balance of previous employee leave that can be used for this period and has an expiration date',
    },
    {
      strongText: 'Used',
      description:
        ' is a column containing the amount of leave and permission balance that has been used by employees',
    },
    {
      strongText: 'Planned',
      description:
        ' is a column containing the amount of employee leave and permission balance in waiting status',
    },
    {
      strongText: 'Balance Remaining',
      additional: 'Employee Leave and Permission Balance + Carry Over - Used',
      description:
        ' is a column containing the amount of employee leave and permission balance that can still be used. Balance Remaining is calculated from: ',
    },
  ]
  return (
    <Dialog open={open} onClose={handleClose}>
      <ModalHeader>
        <Typography variant="body2">Additional Information</Typography>
        <IconButton onClick={handleClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContentEvent>
        <Typography variant="caption" className={classes.gutterBig}>
          The following is additional information about the columns in the leave
          and permission balance table
        </Typography>
        <ul className={classes.ul}>
          {informationText.map((res, i) => (
            <li className={classes.li} key={i}>
              <strong>{res.strongText}</strong>
              {res.description}
              {res.additional && (
                <>
                  <br />
                  <strong>{res.additional}</strong>
                </>
              )}
            </li>
          ))}
        </ul>
      </ModalContentEvent>
    </Dialog>
  )
}

export default InformationPopUp
