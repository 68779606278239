import React, {useState} from 'react'

import {withRouter, Link} from 'react-router-dom'
import {Box, IconButton, Typography} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
} from '../../../../../GlobalStyles'

import helperStyle, {AntTabs, StyledTab} from '../../PayrollPageStyles'
import BasicSettings from './BasicSettings'
import AssignedComponents from './AssignedComponents'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

const EmployeeSettingsEditPage = props => {
  const {userId, userWorkId} = props?.location?.state

  const classes = helperStyle()
  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (event, newActiveTab) => {
    event.preventDefault()
    setActiveTab(newActiveTab)
  }

  const TAB_CONTENT = {
    0: <BasicSettings userId={userId} />,
    1: <AssignedComponents userId={userId} userWorkId={userWorkId} />,
  }

  return (
    <>
      <PaperContainer>
        <FormToolbar disableGutters>
          <FormTitleContainer>
            <Link
              to={{
                pathname: '/financial/payroll/employee-settings',
                state: {
                  active: 'employee-settings',
                },
              }}
            >
              <IconButton edge="end" aria-label="back">
                <ArrowBack />
              </IconButton>
            </Link>

            <AddEditTitle variant="h6" display="inline">
              Edit Employee Settings
            </AddEditTitle>
          </FormTitleContainer>
        </FormToolbar>

        <AntTabs
          className={classes.tabs}
          indicatorColor="primary"
          onChange={handleTabChange}
          textColor="primary"
          value={activeTab}
        >
          <StyledTab label="Basic Settings" />
          <StyledTab label="Assigned Components" />
        </AntTabs>

        <TabPanel value={activeTab} index={activeTab}>
          {TAB_CONTENT[activeTab]}
        </TabPanel>
      </PaperContainer>
    </>
  )
}

export default withRouter(EmployeeSettingsEditPage)
