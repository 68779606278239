import React from 'react'
import {makeStyles, Typography, CircularProgress} from '@material-ui/core'
// import { MoreHorizOutlined } from '@material-ui/icons'
import classNames from 'classnames'
import {useQuery} from '@apollo/react-hooks'
import {
  LIST_COMPETENCY_ADDED_BY_USER,
  BADGE_INDIVIDUAL,
} from '../../../../../graphql/queries'

import {USER_ID} from '../../../../../utils/globals'
import NoDataListFree from '../../../../shared-components/NoDataListFreeComponent'

const useStyles = makeStyles(theme => ({
  competenceRoot: {},
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordion: {
    margin: '25px 0 25px 0',
  },
  accordionHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordionDetails: {
    backgroundColor: '#F7F8F9',
  },

  accordionCard: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  m24: {
    margin: 24,
  },
  p24: {
    padding: 24,
  },
}))

const AddedByUser = props => {
  const [userId, setUserId] = React.useState(USER_ID)
  const classes = useStyles()

  const {
    data: individualData,
    loading: loadingIndividualData,
    error: errorIndividualData,
  } = useQuery(BADGE_INDIVIDUAL, {
    variables: {
      placement: props.placementId,
    },
  })
  const {
    data: dataCompetencies,
    error: errorCompetencies,
    loading: loadingCompetencies,
    refetch: refetchCompetencies,
  } = useQuery(LIST_COMPETENCY_ADDED_BY_USER, {
    variables: {
      userId,
    },
  })

  React.useEffect(() => {
    if (individualData) {
      setUserId(individualData.people_work_placements[0].global_user.id)
    }
  }, [individualData])

  React.useEffect(() => {
    if (userId) {
      refetchCompetencies()
    }
  }, [userId])

  if (loadingCompetencies || loadingIndividualData) {
    return <CircularProgress />
  } else if (errorCompetencies || errorIndividualData) {
    console.error(errorCompetencies || errorIndividualData)
  }

  return (
    <div className={classes.competenceRoot}>
      <div className={classes.rowBetween}>
        <Typography
          variant="subtitle1"
          hidden={dataCompetencies.learning_diary_competencies.length === 0}
        >
          Daftar Kompetensi
        </Typography>
      </div>
      {dataCompetencies.learning_diary_competencies.length === 0 ? (
        <NoDataListFree
          message1="Sorry, No List"
          message2="This employee doesn't have competency added by user."
        />
      ) : (
        dataCompetencies.learning_diary_competencies.map((item, idx) => (
          <div
            key={idx}
            className={classNames(classes.accordionDetails, classes.accordion)}
          >
            <Typography variant="subtitle1" className={classes.p24}>
              {item.name}
            </Typography>
            {/* <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<MoreHorizOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.accordionDetails}
          >
            <div className={classes.accordionHeader}>
              <Typography variant="subtitle1">{item.title}</Typography>
            </div>
          </AccordionSummary>
          {/*   <AccordionDetails className={classes.accordionDetails}>
            <Card className={classes.accordionCard}>
              <div className={classes.col}>
                <Typography variant="subtitle1" className={classes.m24}>
                  Competency Detail
                </Typography>
                <Divider />
                <table className={classes.m24}>
                  <tr>
                    <td>Competency Name</td>
                    <td>Managing People</td>
                  </tr>
                  <tr>
                    <td>Level</td>
                    <td>Non Level</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>Lorem Ipsum dolor</td>
                  </tr>
                </table>
              </div>
            </Card>
          </AccordionDetails> 
          </Accordion> */}
          </div>
        ))
      )}
    </div>
  )
}

export default AddedByUser
