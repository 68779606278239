import React, {useEffect, useState} from 'react'
import moment from 'moment'
import gql from 'graphql-tag'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {withRouter} from 'react-router-dom'
import {Button} from '@material-ui/core'

import FormAction from '../../../../../components/form/FormActionComponent'
import InstallmentTableForm from '../form/InstallmentTableForm'
import ModalConfirmation from '../../../payroll/payroll-settings/ModalConfirmation'
import RequestWrapper from '../../../../shared-components/layouts/RequestWrapper'

import {Flex} from '../../../../../GlobalStyles'
import {capitalize, convertArrayToHashMap} from '../../../../../utils/helpers'

import {GET_LOAN_INSTALLMENT} from '../../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../../utils'

const InstallmentTableFormPageComponent = ({
  loanId,
  // setRefetchLoanInformation,
  setRefetchNumberPaid,
}) => {
  const apolloClient = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()
  const [dataForm, setDataForm] = useState({})
  const [defaultData, setDefaultData] = useState(null)
  const [isAbleToEdit, setIsAbleToEdit] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [rowChangesId, setRowChangesId] = useState({})
  const [serviceLoading, setServiceLoading] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [modalType, setModalType] = useState('')

  const {data, error, refetch} = useQuery(GET_LOAN_INSTALLMENT, {
    variables: {
      loanId: loanId,
    },
    fetchPolicy: 'cache-and-network',
    ...GQL_FINANCE_OPTIONS,
  })

  useEffect(() => {
    const _data = data?.people_work_installment?.map(data => {
      return {
        ...data,
        id: data.id,
        status: {
          value: data?.status,
          label: `${capitalize(data?.status || '-')}`,
        },
      }
    })

    if (_data) {
      setDataForm(convertArrayToHashMap(_data, 'id'))
      setDefaultData(_data)
    }
  }, [data])

  const onEditLoanInstallment = async () => {
    setServiceLoading(true)

    let mutationString = ''
    await Object.keys(rowChangesId).map(key => {
      mutationString += `
        installment${key}: update_people_work_installment(
          where: {
              id: {_eq: ${key} }
          }
          _set: {
            plan_date: "${moment(dataForm[key].plan_date).format('YYYY-MM-DD')}"
            status: "${dataForm[key].status?.value || dataForm[key].status}"
            plan_amount: ${Number(dataForm[key].plan_amount)}
            main_payment: ${Number(dataForm[key].main_payment)}
            interest_payment: ${Number(dataForm[key].interest_payment)}
            actual_date: "${moment(dataForm[key].actual_date).format(
              'YYYY-MM-DD'
            )}"
            paid_in_period: "${moment(dataForm[key].paid_in_period).format(
              'YYYY-MM'
            )}"
            actual_payment: ${Number(dataForm[key].actual_payment)}
          }
        ){
          affected_rows
        }\n`
    })

    const EDIT_LOAN_INSTALLMENT = `mutation {
      ${mutationString}
    }`

    apolloClient
      .mutate({
        mutation: gql`
          ${EDIT_LOAN_INSTALLMENT}
        `,
        ...GQL_FINANCE_OPTIONS,
      })
      .then(async () => {
        enqueueSnackbar('Loan Installment updated succesfully', {
          variant: 'success',
        })
        await refetch()

        setIsAbleToEdit(false)
        // setRefetchLoanInformation(true)
        setRefetchNumberPaid(true)
        setRowChangesId({})
      })
      .catch(error => {
        console.error(error)

        enqueueSnackbar(
          'Update Loan Installment failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
      .finally(() => {
        setIsSubmit(false)
        // setRefetchLoanInformation(false)
        setServiceLoading(false)
        setShowModalConfirm(false)
        setModalType('')
      })
  }

  return (
    <RequestWrapper loading={!defaultData} error={error}>
      <Flex flexEnd>
        {!isAbleToEdit && defaultData?.length > 0 && (
          <Button
            onClick={() => setIsAbleToEdit(true)}
            variant="contained"
            color="primary"
            size="large"
            style={{
              marginBottom: 16,
            }}
          >
            Edit
          </Button>
        )}
      </Flex>

      <InstallmentTableForm
        dataForm={dataForm}
        defaultData={defaultData}
        isAbleToEdit={isAbleToEdit}
        isSubmit={isSubmit}
        rowChangesId={rowChangesId}
        setDataForm={setDataForm}
        setRowChangesId={setRowChangesId}
      />

      {isAbleToEdit && (
        <FormAction
          openConfirmartionDialog={() => {
            setModalType('confirmation')
            setShowModalConfirm(true)
          }}
          openCancellationDialog={() => {
            setModalType('cancel')
            setShowModalConfirm(true)
          }}
          disabledSubmitButton={isSubmit || serviceLoading}
        />
      )}

      {showModalConfirm && (
        <ModalConfirmation
          open={showModalConfirm}
          type={modalType}
          onClose={() => {
            setShowModalConfirm(false)
            setModalType('')
          }}
          onConfirm={() => {
            setIsSubmit(true)
            onEditLoanInstallment()
          }}
          onCancel={() => {
            setIsAbleToEdit(false)
            setShowModalConfirm(false)
            setModalType('')
          }}
        />
      )}
    </RequestWrapper>
  )
}

export default withRouter(InstallmentTableFormPageComponent)
