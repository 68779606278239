import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {useQuery} from '@apollo/react-hooks'

import InformationItem from '../../../../../components/basic-information-item/InformationItem'
import {Flex} from '../../../../../GlobalStyles'
import {convertToRoundedRupiah} from '../../../../../utils/helpers'
import RequestWrapper from '../../../../shared-components/layouts/RequestWrapper'
import {
  GET_LOAN_INFORMATION,
  GET_USER_NAME_REGNO,
} from '../../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../../utils'

const LoanInformation = ({loanId, refetchLoanInformation}) => {
  const [skipFetchEditorData, setSkipFetchEditorData] = useState(true)

  const {data, loading, error, refetch} = useQuery(GET_LOAN_INFORMATION, {
    variables: {
      loanId: loanId,
    },
    fetchPolicy: 'network-only',
    ...GQL_FINANCE_OPTIONS,
    onCompleted: () => {
      setSkipFetchEditorData(true)
    },
  })

  const {
    data: editor_data,
    loading: editor_data_loading,
    error: error_editor_data,
  } = useQuery(GET_USER_NAME_REGNO, {
    variables: {
      id: data?.loan?.[0]?.last_update_by,
    },
    skip: skipFetchEditorData,
    fetchPolicy: 'cache-and-network',
    ...GQL_FINANCE_OPTIONS,
  })

  useEffect(() => {
    if (data?.loan?.[0]?.last_update_by) {
      setSkipFetchEditorData(false)
    }
  }, [data])

  useEffect(() => {
    if (refetchLoanInformation) {
      refetch()
    }
  }, [refetchLoanInformation])

  const employeeInformation = [
    {
      title: 'Employee Name',
      desc: data?.loan?.[0]?.people_work_placement?.global_user?.name,
    },
    {
      title: 'Employee ID',
      desc: data?.loan?.[0]?.people_work_placement?.regno,
    },
    {
      title: 'Position',
      desc: `${data?.loan?.[0]?.people_work_placement?.company_employee_position?.name} at
        ${data?.loan?.[0]?.people_work_placement?.company_profile?.brand_name}`,
    },
    {
      title: 'Loan Date',
      desc: moment(data?.loan?.[0]?.date_added).format('MMM DD, YYYY'),
    },
    {
      title: 'Policy Name',
      desc: data?.loan?.[0]?.finance_loan_policy?.name,
    },
    {
      title: 'Purpose',
      desc: data?.loan?.[0]?.description,
    },
  ]

  const loanInformation = [
    {
      title: 'Total Loan',
      desc: convertToRoundedRupiah(
        data?.total_loan?.aggregate?.sum?.plan_amount || 0
      ),
    },
    {
      title: 'Paid Amount',
      desc: convertToRoundedRupiah(
        data?.paid_amount?.aggregate?.sum?.actual_payment || 0
      ),
    },
    {
      title: 'Outstanding Amount',
      desc: convertToRoundedRupiah(
        data?.total_loan?.aggregate?.sum?.plan_amount -
          data?.paid_amount?.aggregate?.sum?.actual_payment || 0
      ),
    },
    {
      title: 'Remaining Installment',
      desc: `${data?.loan?.[0]?.installment_num?.length || 0} of ${data
        ?.loan?.[0]?.installment || 0}`,
    },
    {
      title: 'Last Updated By',
      desc: (
        <>
          <span style={{color: '#039be5'}}>
            {editor_data?.global_users?.[0]?.people_work_placements?.[0]
              ?.regno || ''}
          </span>{' '}
          {editor_data?.global_users?.[0]?.name || ''}
        </>
      ),
    },
    {
      title: 'Last Updated Date',
      desc: moment(data?.loan?.[0]?.last_modified).format('MMM DD, YYYY'),
    },
  ]

  return (
    <RequestWrapper
      loading={loading || editor_data_loading}
      error={error || error_editor_data}
    >
      <Flex style={{gap: 20, padding: '1.6rem', paddingTop: 0}}>
        <div style={{width: '50%'}}>
          {employeeInformation.map((data, index) => (
            <InformationItem key={index} {...data} />
          ))}
        </div>

        <div style={{width: '50%'}}>
          {loanInformation.map((data, index) => (
            <InformationItem key={index} {...data} />
          ))}
        </div>
      </Flex>
    </RequestWrapper>
  )
}

export default LoanInformation
