import gql from 'graphql-tag'

export const GET_COMPETENCY_VERSION = gql`
  query($id: Int) {
    competency_versions(where: {id: {_eq: $id}, status: {_eq: "ACTIVE"}}) {
      id
      name
    }
  }
`
// Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/version/LIST%20VERSION.md
export const LIST_COMPETENCY_VERSION = gql`
  query($company: uuid, $search: String, $offset: Int, $limit: Int) {
    total: competency_versions_aggregate(
      where: {
        company: {_eq: $company}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
    competency_versions(
      where: {
        company: {_eq: $company}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
      date_start
      date_end
      status
    }
  }
`
// Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/version/DETAIL%20VERSION.md
export const GET_COMPETENCY_VERSION_DICTIONARIES = gql`
  query($version: Int, $search: String, $offset: Int, $limit: Int) {
    competency_dictionaries_aggregate(
      where: {
        competency_version_dictionaries: {version: {_eq: $version}}
        _or: [
          {dictionary_id: {_ilike: $search}}
          {search_name: {_ilike: $search}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    competency_dictionaries(
      where: {
        competency_version_dictionaries: {version: {_eq: $version}}
        _or: [
          {dictionary_id: {_ilike: $search}}
          {search_name: {_ilike: $search}}
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      dictionary_id
      name
      type
      competency_category {
        id
        name
      }
      description
    }
  }
`

// Doc : https://github.com/worklifebeyond/wlb-api-documentation/blob/1beda5a3439f4fc5b62f6d5f26ddcc9fe779629c/competency/Dictionaries/LIST%20DICTIONARY.md
export const GET_COMPETENCY_DICTIONARIES = gql`
  query($offset: Int, $limit: Int, $query: String, $version: Int) {
    competency_dictionaries_aggregate(where: {search_name: {_ilike: $query}}) {
      aggregate {
        count
      }
    }
    competency_dictionaries(
      limit: $limit
      offset: $offset
      where: {search_name: {_ilike: $query}}
    ) {
      id
      dictionary_id
      name
      description
      type
      status
      category
      competency_category {
        id
        name
        code
      }
    }
  }
`

// Doc : https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/Dictionaries/DETAIL%20DICTIONARY.md
export const GET_DETAIL_DICTIONARY = gql`
  query($id: bigint) {
    competency_dictionaries(where: {id: {_eq: $id}}) {
      id
      dictionary_id
      name
      description
      type
      category
      status
      competency_category {
        id
        name
        code
      }
    }
  }
`

// Doc : https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/Dictionaries/INSERT%20DICTIONARY.md
export const GET_COMPETENCY_CATEGORIES = gql`
  query($company: uuid) {
    competency_categories(
      where: {company: {_eq: $company}}
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`

export const COUNT_ACTIVE_DICTIONARY = gql`
  query($company: uuid!) {
    competency_versions_aggregate(
      where: {company: {_eq: $company}, status: {_eq: "ACTIVE"}}
    ) {
      aggregate {
        count
      }
    }
  }
`
export const CHECK_CODE_COMPETENCY = gql`
  query checkCodeCompetency($company: uuid!, $code: String!) {
    competency_dictionaries_aggregate(
      where: {company: {_eq: $company}, dictionary_id: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const CHECK_CODE_VERSION = gql`
  query chechkVersionDictionary($company: uuid!, $code: String!) {
    competency_versions_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`
