import {
  Button,
  Typography,
  Tabs,
  Tab,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {newTableStyles} from '../../shared-components/table/TableOptions'
import {EnhancedToolbar, PaperContainer} from '../../../GlobalStyles'

import CompanyTalentList from './list-tabs/CompanyTalentList'
import ExternalTalentList from './list-tabs/ExternalTalentList'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
}))

const ListComponent = props => {
  const classes = useStyles()
  const tableClasses = newTableStyles()
  const [tabsValue, setTabsValue] = useState(0)
  const [open, setOpen] = React.useState(false)
  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const renderComponent = activeComponent => {
    switch (activeComponent) {
      case 0:
        return <CompanyTalentList searchText={props.searchText} />
      case 1:
        return <ExternalTalentList searchText={props.searchText} />
      default:
        return <CompanyTalentList searchText={props.searchText} />
    }
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Talent List</Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          // onClick={() => setOpen(true)}
        >
          {tabsValue === 1 ? 'Add External Talent' : 'Invite Talent'}
        </Button>
      </EnhancedToolbar>
      <Tabs
        value={tabsValue}
        onChange={handleChangeTabs}
        indicatorColor="secondary"
        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
      >
        <Tab label="Company Talent" className={classes.tabRoot} />
        <Tab label="External Talent" className={classes.tabRoot} />
      </Tabs>
      <div className={tableClasses.tableRoot}>{renderComponent(tabsValue)}</div>
      <Dialog
        open={open}
        onClick={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </PaperContainer>
  )
}

export default ListComponent
