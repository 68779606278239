import React, {useState, useEffect} from 'react'
import {FlexBetween, GreyTypography, Flex} from '../../../../../GlobalStyles'
import {AssignFilter} from '@worklifebeyond/wlb-utils-components'
import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import {Magnify} from 'mdi-material-ui'
import MaterialTable from 'material-table'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import {TableOptions} from '../../../../shared-components/table/TableOptions'
import moment from 'moment'
import {useQuery} from '@apollo/react-hooks'
import {
  GET_LIST_APPLIED_POSITION_OVERTIME,
  GET_NEW_POSITION_OVERTIME,
} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import isEmpty from 'lodash/isEmpty'
import SelectDataPopup from '../../../../shared-components/popup/SelectDataPopup'
import assignFiltersOld from '../../../../../utils/assignFiltersOld'

export const AssignPositionTabChild = ({
  variablesQuery,
  setVariablesQuery,
  id,
  isFilterPolicy,
  setIsFilterPolicy,
  setIsUpdatePosition,
  setCount,
  isError,
}) => {
  const [search, setSearch] = useState('')
  const [openNewPos, setOpenNewPos] = useState(false)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const [paginationPos, setPaginationPos] = useState({
    offset: 0,
    limit: 10,
  })

  const handleApplyAssign = result => {
    const {include, exclude} = result

    const _vars = {}

    for (const p in include) {
      if (include[p] && include[p].length > 0) {
        if (!_vars[p]) {
          _vars[p] = {}
        }

        _vars[p]._in = include[p]
      }
    }

    for (const p in exclude) {
      if (exclude[p] && exclude[p].length > 0) {
        if (!_vars[p]) {
          _vars[p] = {}
        }

        _vars[p]._nin = exclude[p]
      }
    }

    setPagination({...pagination, offset: 0})
    setVariablesQuery(_vars)

    if (id) {
      setIsUpdatePosition(true)
      setIsFilterPolicy(false)
    }
  }

  const selections = React.useMemo(() => {
    const include = {}
    const exclude = {}

    for (const v in variablesQuery) {
      const {_in, _nin} = variablesQuery[v]

      include[v] = _in
      exclude[v] = _nin
    }

    return {include, exclude}
  }, [variablesQuery])

  const {data, loading} = useQuery(GET_LIST_APPLIED_POSITION_OVERTIME, {
    variables: {
      ...variablesQuery,
      policy: id || null,
      companyId: COMPANY_ID,
      search: `%${search}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      isFilterPolicy,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (data) {
      setCount(data.totalAll.aggregate.count)
    }
  }, [data])

  const {data: newData} = useQuery(GET_NEW_POSITION_OVERTIME, {
    variables: {
      policy: id,
      company: COMPANY_ID,
      search: `%${search}%`,
      offset: paginationPos.offset * paginationPos.limit,
      limit: paginationPos.limit,
    },
    fetchPolicy: 'no-cache',
  })

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleNewPos = () => {
    setOpenNewPos(prev => !prev)
  }

  const onSyncPosition = () => {
    setIsFilterPolicy(false)
    setIsUpdatePosition(true)
    setOpenNewPos(false)
  }

  return (
    <>
      <FlexBetween>
        <div>
          <Typography variant="body2" style={{fontWeight: 600}} gutterBottom>
            {!loading &&
              (data?.totalAll.aggregate.count ===
              data?.totalPosition.aggregate.count
                ? `Applied to All Positions (${data?.totalAll?.aggregate?.count} Positions)`
                : `Applied to ${data?.totalAll?.aggregate?.count} Positions`)}{' '}
            {isError && data?.total?.aggregate?.count === 0 && (
              <Typography variant="body2" color="error" display="inline">
                This field is required
              </Typography>
            )}
          </Typography>
          {isEmpty(variablesQuery) &&
            data?.totalAll.aggregate.count !==
              data?.totalPosition.aggregate.count && (
              <Flex>
                <GreyTypography variant="body2">
                  There are new positions added recently
                </GreyTypography>
                <Typography
                  style={{marginLeft: 8, cursor: 'pointer'}}
                  color="secondary"
                  variant="body2"
                  onClick={handleNewPos}
                >
                  View new positions
                </Typography>
              </Flex>
            )}
        </div>

        <AssignFilter
          filters={assignFiltersOld}
          initialSelections={selections}
          limit={20}
          onApply={handleApplyAssign}
        >
          <Button variant="contained" color="primary" size="large">
            Filter
          </Button>
        </AssignFilter>
      </FlexBetween>
      <TextField
        margin="normal"
        variant="outlined"
        size="small"
        fullWidth
        value={search}
        onChange={event => setSearch(event.target.value)}
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment className="ml-0" position="start">
              <Magnify style={{color: '#014a62', width: 20, height: 20}} />
            </InputAdornment>
          ),
        }}
      />

      <MaterialTable
        columns={[
          {
            title: 'Position Code',
            field: 'code',
            render: row => (row.code ? `[${row.code}]` : '-'),
            cellStyle: {color: '#039BE5'},
          },
          {
            title: 'Position',
            field: 'title',
          },
        ]}
        isLoading={loading}
        data={data && data.company_job_profiles}
        options={{
          ...TableOptions,
          showTitle: false,
          toolbar: false,
          selection: false,
          emptyRowsWhenPaging: false,
          sorting: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: <NoDataListComponent />,
          },
        }}
        totalCount={(data && data.total.aggregate.count) || 0}
        pageSize={pagination.limit}
        components={{
          Container: props => <Paper {...props} elevation={0} />,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        page={pagination.offset}
      />

      <SelectDataPopup
        open={openNewPos}
        onCancel={handleNewPos}
        handleConfirm={onSyncPosition}
        titleText="New Positions"
        cancelText="Cancel"
        saveText="Synchronize All"
        appliedText={`New Positions (${newData?.count.aggregate.count} Positions)`}
        search={false}
        data={newData?.company_job_profiles.map(res => ({
          id: res.id,
          value1: res.title,
          value2: `Added on ${moment(res.date_added).format('LL')}`,
        }))}
        count={newData?.count.aggregate.count}
        type="newPosition"
        pagination={paginationPos}
        setPagination={setPaginationPos}
        altText="To apply policy to these positions, you need to synchronize these positions to current position listed on policy"
      />
    </>
  )
}
