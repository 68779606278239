import React, {useState} from 'react'

import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'
import {PageContainer} from '../../../GlobalStyles'
import SuccessionComponent from './talent-succession/SuccessionComponent'
import DetailSuccessionComponent from './talent-succession/detail/DetailSuccessionComponent'
import ListSuccessor from './talent-succession/detail/ListSuccessor'
import AspirationComponent from './aspiartion/AspirationComponent'
import DetailAspiration from './aspiartion/DetailAspiration'
import CompareTalentSuccession from './talent-succession/detail/compare-talent/CompareTalentSuccession'
import AddTalentPool from './talent-succession/detail/add-talent-pool/AddTalentPool'
import DevelopmentPlan from './development-plan/DevelopmentPlanComponent'
import DevelopmentDetail from './development-plan/DevelopmentDetail'
// import ListTalentPool from './talent-succession/detail/ListTalentPool'

const renderComponent = (activeComponent, searchText = '') => {
  switch (activeComponent) {
    case 'succession':
      return <SuccessionComponent />
    case 'aspiration':
      return <AspirationComponent searchText={searchText} />
    case 'detail-aspiration':
      return <DetailAspiration />
    case 'detail-succession':
      return <DetailSuccessionComponent />
    case 'compare-talent':
      return <CompareTalentSuccession />
    case 'add-pool':
      return <AddTalentPool searchText={searchText} />
    case 'list-successor':
      return <ListSuccessor />
    case 'list-talent-pool':
      return <ListSuccessor />
    case 'development-plan':
      return <DevelopmentPlan searchText={searchText} />
    case 'development-detail':
      return <DevelopmentDetail />
    default:
      return <SuccessionComponent />
  }
}

const SuccessionPage = props => {
  let activeTab = ''
  let activeMenu = ''

  if (props.location.state) {
    activeMenu = props.location.state.active
    switch (props.location.state.active) {
      case 'upload':
      case 'detail-succession':
      case 'list-successor':
      case 'list-talent-pool':
      case 'compare-talent':
      case 'add-pool':
        activeTab = 'succession'
        break
      case 'detail-aspiration':
      case 'aspiration':
        activeTab = 'aspiration'
        break
      case 'development-plan':
      case 'development-detail':
        activeTab = 'development-plan'
        break
      default:
        activeTab = 'succession'
    }
  }
  const data_tabs = [
    {
      label: 'Suksesi Talenta',
      state: 'succession',
      url: '/talent/succession',
      searchable: true,
    },
    {
      label: 'Aspirasi',
      state: 'aspiration',
      url: '/talent/succession/aspiration',
      searchable: true,
    },
    {
      label: 'Rencana Pengembangan',
      state: 'development-plan',
      url: '/talent/succession/development-plan',
      searchable: true,
    },
  ]
  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }
  // useEffect(() => {
  //   if (props.location.pathname === '/talent/succession/aspiration') {
  //     props.history.push({
  //       state: {
  //         active: 'aspiration',
  //         searchable: true,
  //       },
  //     })
  //   } else if (
  //     props.location.pathname === '/talent/succession/development-plan'
  //   ) {
  //     props.history.push({
  //       state: {
  //         active: 'development-plan',
  //         searchable: true,
  //       },
  //     })
  //   } else {
  //     props.history.push({
  //       state: {
  //         active: 'succession',
  //       },
  //     })
  //   }
  // }, [])
  return (
    <div>
      <Card icon="how_to_reg" title="Talenta" onSearch={onSearch}>
        <PageContainer>
          <WlbTab data={data_tabs} activeMenu={activeTab} />
          {renderComponent(activeMenu, searchText)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default SuccessionPage
