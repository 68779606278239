import React, {useEffect, useState} from 'react'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {withRouter} from 'react-router-dom'

import EmployeeLoanForm from '../form/EmployeeLoanForm'
import AlertPopup from '../../../../shared-components/popup/ResultPopup'
import FormAction from '../../../../../components/form/FormActionComponent'
import RequestWrapper from '../../../../shared-components/layouts/RequestWrapper'

import {capitalize} from '../../../../../utils/helpers'
import {GET_EMPLOYEE_LOAN} from '../../../../../graphql/queries'
import {EDIT_EMPLOYEE_LOAN} from '../../../../../graphql/mutations'
import {GQL_FINANCE_OPTIONS} from '../../../utils'
import {USER_ID} from '../../../../../utils/globals'

const EmployeeLoanFormPageComponent = ({
  history,
  isAbleToEdit,
  loanId,
  setRefetchLoanInformation,
}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [cancelIsOpen, setCancelIsOpen] = useState(false)
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const [dataForm, setDataForm] = useState({})
  const [defaultData, setDefaultData] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const [serviceLoading, setServiceLoading] = useState(false)
  const [showFormAction, setShowFormAction] = useState(false)

  const [editEmployeeLoan] = useMutation(
    EDIT_EMPLOYEE_LOAN,
    GQL_FINANCE_OPTIONS
  )

  const {data, error} = useQuery(GET_EMPLOYEE_LOAN, {
    variables: {
      loanId: loanId,
    },
    fetchPolicy: 'cache-and-network',
    ...GQL_FINANCE_OPTIONS,
  })

  useEffect(() => {
    const _data = data?.people_work_loans?.[0]
    const _status = {
      status: {
        label: `${capitalize(_data?.status || '-')}`,
        value: _data?.status || '-',
      },
    }

    if (_data) {
      setDataForm({
        ..._data,
        ..._status,
      })
      setDefaultData({
        ..._data,
        ..._status,
      })
    }
  }, [data])

  useEffect(() => {
    let isChanged = false
    for (const [key, value] of Object.entries(dataForm)) {
      if (key !== 'amount_per_installment') {
        if (
          (value?.value || dataForm[key]) !==
          (defaultData[key]?.value || defaultData[key])
        ) {
          isChanged = true
          break
        }
      }
    }

    setShowFormAction(isChanged)
  }, [dataForm])

  const disabledSubmitButton =
    !dataForm?.description ||
    !dataForm?.letter_num ||
    !dataForm?.loan_amount > 0 ||
    !dataForm?.loan_amount_w_interest > 0 ||
    !dataForm?.status
  // dataForm?.loan_rate === undefined ||
  // dataForm?.loan_rate === ''

  const onEditEmployeeLoan = () => {
    setIsSubmit(true)

    if (!disabledSubmitButton) {
      setServiceLoading(true)

      if (dataForm.loan_rate === '') dataForm.loan_rate = 0
      dataForm.status = dataForm.status.value
      dataForm.amount_per_installment =
        dataForm.loan_amount_w_interest / dataForm.installment
      delete dataForm?.__typename
      delete dataForm?.flat_rate
      delete dataForm?.installment

      const variables = {
        id: loanId,
        last_update_by: USER_ID,
        ...dataForm,
      }

      editEmployeeLoan({variables: variables})
        .then(() => {
          enqueueSnackbar('Employee Loan edited', {
            variant: 'success',
            autoHideDuration: 3000,
            onClose: () => setRefetchLoanInformation(true),
          })
        })
        .catch(() => {
          enqueueSnackbar('Edit Employee Loan failed, please try again later', {
            variant: 'error',
            autoHideDuration: 3000,
          })
        })
        .finally(() => {
          setIsSubmit(false)
          setRefetchLoanInformation(false)
          setServiceLoading(false)
        })
    }
  }

  return (
    <RequestWrapper loading={!defaultData} error={error}>
      <form id="edit-employee-loan-form">
        <EmployeeLoanForm
          dataForm={dataForm}
          defaultData={defaultData}
          setDataForm={setDataForm}
          isAbleToEdit={isAbleToEdit}
          isSubmit={isSubmit}
          isEdit={true}
        />
      </form>

      {showFormAction && (
        <FormAction
          disabledSubmitButton={
            (isSubmit && disabledSubmitButton) || serviceLoading
          }
          openCancellationDialog={() => setCancelIsOpen(true)}
          openConfirmartionDialog={() => {
            setConfirmIsOpen(true)
          }}
        />
      )}

      {confirmIsOpen && (
        <AlertPopup
          open={confirmIsOpen}
          feature="Employee Loan"
          message="Are you sure you want to save changes?"
          type="Confirm"
          status="cancel"
          handleClose={() => setConfirmIsOpen(false)}
          mutation={() => {
            setConfirmIsOpen(false)
            onEditEmployeeLoan()
          }}
        />
      )}

      {cancelIsOpen && (
        <AlertPopup
          open={cancelIsOpen}
          feature="Discard Changes?"
          message="Are you sure you want to discard changes?"
          type="Discard"
          status="cancel"
          handleClose={() => setCancelIsOpen(false)}
          mutation={() => {
            setCancelIsOpen(false)
            history.goBack()
          }}
        />
      )}
    </RequestWrapper>
  )
}

export default withRouter(EmployeeLoanFormPageComponent)
