import React from 'react'
import {Typography} from '@material-ui/core'
import NoListImage from '../../assets/images/nodatalist.png'

const NoDataListComponent = props => {
  return (
    <div
      style={{
        margin: '0px auto',
        padding: '100px 0',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        height: '50vh',
        alignItems: 'center',
      }}
    >
      <div>
        <img src={NoListImage} alt="no-data" width="250px" height="auto"></img>
        <Typography variant="h6">
          {props.title ||
            (props.search
              ? 'Tidak ada hasil yang ditemukan'
              : 'Maaf belum ada daftar')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {props.message && props.message}
        </Typography>
        <div
          style={{
            padding: '50px 0',
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default NoDataListComponent
