import MultiraterNpsPage from './MultiraterNpsPageComponent'

export const MultiraterNpsPageConfig = {
  routes: [
    {
      path: '/survey/multirater-nps/question-bank',
      component: MultiraterNpsPage,
      state: 'question-bank',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/question-bank/add',
      component: MultiraterNpsPage,
      state: 'add-group-question',
      exact: false,
    },
    {
      path: '/survey/multirater-nps/question-bank/:id',
      component: MultiraterNpsPage,
      state: 'view-question-bank',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/question-bank/:id/add',
      component: MultiraterNpsPage,
      state: 'add-question',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/question-bank/:id/edit',
      component: MultiraterNpsPage,
      state: 'edit-group-question',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/question-bank/:group/edit/:id',
      component: MultiraterNpsPage,
      state: 'edit-question',
    },
    {
      path: '/survey/multirater-nps/respondents',
      component: MultiraterNpsPage,
      state: 'respondents',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/respondents/add',
      component: MultiraterNpsPage,
      state: 'add-respondents',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/respondents/edit/:id',
      component: MultiraterNpsPage,
      state: 'edit-respondents',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/respondents-group',
      component: MultiraterNpsPage,
      state: 'respondents-group',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/respondents-group/add',
      component: MultiraterNpsPage,
      state: 'add-respondents-group',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/respondents-group/edit/:id',
      component: MultiraterNpsPage,
      state: 'edit-respondents-group',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/survey-center',
      component: MultiraterNpsPage,
      state: 'survey-center',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/survey-center/add',
      component: MultiraterNpsPage,
      state: 'add-survey-center',
      exact: true,
    },
    {
      path: '/survey/multirater-nps/survey-center/edit/:id',
      component: MultiraterNpsPage,
      state: 'edit-survey-center',
      exact: false,
    },
    {
      path: '/survey/multirater-nps/survey-center/detail/:id',
      component: MultiraterNpsPage,
      state: 'detail-survey-center',
      exact: false,
    },
    {
      path: '/survey/multirater-nps/survey-center/detail/:id/asesi/:user_id',
      component: MultiraterNpsPage,
      state: 'detail-asesi',
      exact: true,
    },
  ],
}
