import React from 'react'
import {TextField} from '@material-ui/core'

const formatter = new Intl.NumberFormat('id-ID')

function TextFieldCurrency(props) {
  const {value, onChange} = props
  const masked = formatter.format(value || 0)

  return (
    <TextField
      {...props}
      value={masked}
      onChange={event => {
        const name = event.target.name
        const value = event.target.value.replace(/[^\d]/g, '')
        const parsed = value ? parseInt(value) : 0
        onChange({target: {name, value: parsed}})
      }}
    />
  )
}

export default TextFieldCurrency
