import React from 'react'
import {Link} from 'react-router-dom'
import {useApolloClient, useQuery} from '@apollo/react-hooks'

import {
  Typography,
  Button,
  Divider,
  TablePagination,
  Menu,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'

import {
  PaperContainer,
  EnhancedToolbar,
  StyledMenuItem,
} from '../../../../GlobalStyles'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import RequestWrapper from '../../../shared-components/layouts/RequestWrapper'

import {PAGE_SIZE_OPTIONS} from '../ClaimPageStyles'
import {ClaimPolicyList, ClaimPolicyItem} from './ClaimPolicyItem'
import ClaimAppliesToDialog from './ClaimAppliesToDialog'

import {COMPANY_ID} from '../../../../utils/globals'
import {GET_CLAIM_SETTINGS} from '../../../../graphql/queries/claim/getClaimSettings.query'
import {DELETE_CLAIM_POLICY} from '../../../../graphql/mutations/claim/claimPolicies.mutation'

function ClaimSettings(props) {
  return (
    <PaperContainer style={{display: 'grid'}}>
      <EnhancedToolbar>
        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
          Claim Settings
        </Typography>

        <Button
          component={Link}
          to={{
            pathname: '/financial/claim/settings/add',
            state: {active: 'settings-add'},
          }}
          variant="contained"
          color="primary"
          size="large"
        >
          Add Claim Policy
        </Button>
      </EnhancedToolbar>

      <Divider />

      <PolicyListing {...props} />
    </PaperContainer>
  )
}

export default ClaimSettings

const INITIAL_PAGINATION = {
  page: 0,
  limit: 20,
}

function PolicyListing(props) {
  const {searchText} = props

  const [pagination, setPagination] = React.useState(INITIAL_PAGINATION)

  const [activeData, setActiveData] = React.useState(null)

  const [menuAnchor, setMenuAnchor] = React.useState(null)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openMore, setOpenMore] = React.useState(false)

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const {data, loading, error, refetch} = useQuery(GET_CLAIM_SETTINGS, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: COMPANY_ID,
      search: `%${searchText}%`,
      offset: pagination.page * pagination.limit,
      limit: pagination.limit,
    },
  })

  // eslint-disable-next-line no-unused-vars
  const handlePageChange = (ev, page) => {
    setPagination({...pagination, page})
  }

  const handleLimitChange = ev => {
    setPagination({page: 0, limit: ev.target.value})
  }

  const handleMenuClick = (ev, data) => {
    setActiveData(data)
    setMenuAnchor(ev.target)
  }

  const handleMenuClose = () => {
    setMenuAnchor(null)
  }

  const handleDeleteOpen = () => {
    setMenuAnchor(null)
    setOpenDelete(true)
  }

  const handleDeleteConfirm = () => {
    const promise = client.mutate({
      mutation: DELETE_CLAIM_POLICY,
      variables: {
        policyId: activeData.id,
      },
    })

    promise.then(
      result => {
        if (result.errors) {
          return Promise.reject(result.errors)
        }

        enqueueSnackbar('Claim policy deleted', {variant: 'success'})
        setActiveData(null)

        refetch()
      },
      () => {
        enqueueSnackbar('Failed to delete claim policy', {variant: 'error'})
        setActiveData(null)
      }
    )
  }

  const handleDeleteCancel = () => {
    setOpenDelete(false)
  }

  // eslint-disable-next-line no-unused-vars
  const handleMoreOpen = (ev, data) => {
    setActiveData(data)
    setOpenMore(true)
  }

  const handleMoreClose = () => {
    setOpenMore(false)
  }

  React.useLayoutEffect(() => {
    if (pagination.page !== 0) {
      handlePageChange(0)
    }
  }, [searchText])

  return (
    <>
      <RequestWrapper
        loading={loading}
        error={error}
        isEmpty={!data?.total.aggregate.count}
        emptyMessage="There are no claim policies set up yet"
      >
        <ClaimPolicyList>
          {data?.policies.map(item => (
            <ClaimPolicyItem
              key={item.id}
              data={item}
              onMenuClick={handleMenuClick}
              onMoreClick={handleMoreOpen}
            />
          ))}
        </ClaimPolicyList>
      </RequestWrapper>

      <Divider />

      <TablePagination
        count={data?.total.aggregate.count}
        page={pagination.page}
        rowsPerPage={pagination.limit}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
      />

      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleMenuClose}>
        <StyledMenuItem
          component={Link}
          to={{
            pathname: `/financial/claim/settings/${activeData?.id}/edit`,
            state: {active: 'settings-edit'},
          }}
        >
          Edit
        </StyledMenuItem>
        <StyledMenuItem onClick={handleDeleteOpen}>Delete</StyledMenuItem>
      </Menu>

      <DeletePopup
        open={openDelete}
        handleClose={handleDeleteCancel}
        mutation={handleDeleteConfirm}
        name={activeData?.name}
      />

      <ClaimAppliesToDialog
        open={openMore}
        policyId={activeData?.id}
        onClose={handleMoreClose}
      />
    </>
  )
}
