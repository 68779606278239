import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import CloseIcon from '@material-ui/icons/Close'
import React, {useState} from 'react'
import {BoldTypography} from '../../../../../components/typography/TypographyStyles'
import {
  GreyTypography,
  MediumAvatar,
  WhiteModalHeader,
  BlueIcon,
  SmallAvatar,
} from '../../../../../GlobalStyles'
import {Avatar, Paper, TextField, InputAdornment} from '@material-ui/core'
import MaterialTable from 'material-table'
import {TableOptions} from '../../../../shared-components/table/TableOptions'
import {
  GET_EMP_MEMBER,
  GET_DETAIL_CLUSTER,
  GET_DETAIL_SUBCLUSTER,
} from '../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import Loading from '../../../../../components/loading/LoadingComponent'
import {AvatarGroup} from '@material-ui/lab'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const DetailNode = ({
  open,
  handleClose,
  classes,
  company,
  isHead,
  isCluster,
}) => {
  const [search, setSearch] = useState('')
  const variables = isCluster
    ? {id: company, search: `%${search}%`}
    : {company, search: `%${search}%`}
  const {data, error} = useQuery(
    isCluster && !isHead
      ? GET_DETAIL_SUBCLUSTER
      : isCluster && isHead
      ? GET_DETAIL_CLUSTER
      : GET_EMP_MEMBER,
    {
      variables,
      context: {
        headers: {
          'X-Hasura-Role': 'holding-administrator',
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const dataCompany = !isCluster && data.company && data.company[0]

  const dataHolding = !isCluster && data.holding && data.holding[0]

  const column = isCluster
    ? [
        {
          title: '',
          field: 'name',

          sorting: false,
          render: row => (
            <Avatar
              src={row.profile_company.logo}
              style={{marginRight: 16}}
              variant="rounded"
            ></Avatar>
          ),
        },
        {
          title: 'Category',
          field: 'category.name',
        },
        {
          title: 'Employee',
          field: 'profile_company.company_people_count.aggregate.count',
          render: row => (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <AvatarGroup>
                {row.profile_company.people_member.slice(0, 5).map((ava, i) => (
                  <SmallAvatar
                    alt={`${i}`}
                    key={i}
                    src={ava.global_user.avatar}
                  />
                ))}
              </AvatarGroup>
              <BoldTypography variant="body1" style={{marginLeft: 10}}>
                {row.profile_company.company_people_count.aggregate.count}
              </BoldTypography>
            </div>
          ),
        },
        // {
        //   title: '',
        //   field: 'action',
        //   filtering: false,
        //   render: () => (
        //     <div style={{display: 'flex'}}>
        //       <IconButton>
        //         <Icon color="secondary">delete</Icon>
        //       </IconButton>
        //     </div>
        //   ),
        // },
      ]
    : [
        {
          title: '',
          field: 'name',

          sorting: false,
          render: row => (
            <Avatar
              src={row.global_user.avatar}
              style={{marginRight: 16}}
            ></Avatar>
          ),
        },
        {
          title: 'Employee ID',
          field: 'regno',
        },
        {
          title: 'Employee Name',
          field: 'global_user.name',
        },
        {
          title: 'Position',
          field: 'company_job_profile.title',
        },

        // {
        //   title: '',
        //   field: 'action',
        //   filtering: false,
        //   render: () => (
        //     <div style={{display: 'flex'}}>
        //       <IconButton>
        //         <Icon color="secondary">delete</Icon>
        //       </IconButton>
        //     </div>
        //   ),
        // },
      ]

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => handleClose(isCluster)}
      TransitionComponent={Transition}
      style={{width: '50%', left: '50%'}}
    >
      <WhiteModalHeader
        className={classes.paddingWrapp}
        style={{alignItems: 'end'}}
      >
        <div>
          {isCluster ? (
            <BoldTypography variant="h6" gutterBottom>
              {isHead
                ? data.holding_relations &&
                  data.holding_relations[0] &&
                  data.holding_relations[0].cluster &&
                  data.holding_relations[0].cluster.name
                : data.holding_relations &&
                  data.holding_relations[0] &&
                  data.holding_relations[0].subcluster &&
                  data.holding_relations[0].subcluster.name}
            </BoldTypography>
          ) : (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <MediumAvatar
                variant="rounded"
                src={dataCompany && dataCompany.logo}
                sizes="large"
                style={{marginRight: 24}}
              ></MediumAvatar>
              <div>
                <BoldTypography variant="h6" gutterBottom>
                  {dataCompany && dataCompany.brand_name}
                </BoldTypography>
                <GreyTypography variant="body1">
                  {dataCompany && dataCompany.legal_name}
                </GreyTypography>
              </div>
            </div>
          )}

          {!isCluster && (
            <div className={classes.wrapperCategory}>
              {isHead ? (
                <div
                  className={`${classes.divCategory} ${classes.bgSecondary} ${classes.mr18}`}
                >
                  {dataCompany && dataCompany.global_company_industry_type.name}
                </div>
              ) : (
                dataHolding && (
                  <>
                    <div
                      className={`${classes.divCategory} ${classes.bgSecondary} ${classes.mr18}`}
                    >
                      {dataHolding.holding_category &&
                        dataHolding.holding_category.name}
                    </div>
                    <div
                      className={`${classes.divCategory} ${classes.bgSecondary} ${classes.mr18}`}
                    >
                      {dataHolding.holding_cluster &&
                        dataHolding.holding_cluster.name}
                    </div>
                    <div
                      className={`${classes.divCategory} ${classes.bgSecondary} ${classes.mr18}`}
                    >
                      {dataHolding.holding_subcluster &&
                        dataHolding.holding_subcluster.name}
                    </div>
                    <div
                      className={`${classes.divCategory} ${classes.bgPrimary}`}
                    >
                      {dataHolding.holding_class &&
                        dataHolding.holding_class.name}
                    </div>
                  </>
                )
              )}
            </div>
          )}
        </div>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => handleClose(isCluster)}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </WhiteModalHeader>
      <Divider />
      <div className={classes.paddingWrapp}>
        <TextField
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BlueIcon>search</BlueIcon>
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          fullWidth
          size="small"
          margin="normal"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <MaterialTable
          style={{fontSize: 12}}
          options={TableOptions}
          columns={column}
          components={{
            Container: props => <Paper {...props} elevation={0} />,
          }}
          title={
            isCluster
              ? `Company (${
                  data.holding_count ? data.holding_count.aggregate.count : '-'
                })`
              : `Employee (${data.total_people &&
                  data.total_people.aggregate.count})`
          }
          data={isCluster ? data.holding_relations : data.member}
        />
      </div>
    </Dialog>
  )
}

export default DetailNode
