import React from 'react'
import {
  makeStyles,
  Typography,
  Grid,
  Paper,
  Avatar,
  CircularProgress,
} from '@material-ui/core'
import {
  MediumAvatar,
  GreyTypography,
  FlexBetween,
  BlueLink,
} from '../../../../../GlobalStyles'
import {
  BoldTypography,
  TypographyStyleds,
} from '../../../../../components/typography/TypographyStyles'
import moment from 'moment'
import {capitalize} from '../../../../../utils/helpers'
import NoBadge from '../../../../../assets/images/no-badge.svg'
import {withRouter} from 'react-router-dom'
import {level_skill} from '../../../../shared-components/data/SharedData'
import {useQuery} from '@apollo/react-hooks'
import {GET_LIST_TALENT_SEARCHED} from '../../../../../graphql/queries'

const useStyles = makeStyles(() => ({
  gridContainer: {
    margin: 0,
    width: '100%',
    padding: 8,
  },
  paper: {
    padding: 20,
  },
  textCenter: {
    textAlign: 'center',
  },
  successor: {
    margin: '12px 0 16px',
    display: 'flex',
    padding: 12,
    alignItems: 'center',
  },
  indicator: {
    padding: 12,
  },
  namedSuccessor: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    cursor: 'pointer',
  },
  green: {
    color: '#4caf50',
  },
  spacer: {
    marginLeft: 10,
  },
  spacerPool: {
    marginLeft: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  spacerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    width: '100%',
  },
  mr20: {
    marginRight: 20,
  },
  flexDetail: {
    alignItems: 'center',
    display: 'flex',
  },
  paperCategory: {
    padding: '20px 0',
    textAlign: 'center',
    height: '100%',
    boxSizing: 'border-box',
  },
  red: {
    color: '#ef4d5e',
  },
  paperMobility: {
    textAlign: 'center',
    padding: 17,
    marginTop: 20,
    marginBottom: 42,
  },
  seeAll: {
    textAlign: 'center',
    marginTop: 16,
  },
  yellow: {
    color: '#ffa000',
  },
  flexStart: {display: 'flex', alignItems: 'flex-start'},
  textContent: {
    padding: '5px 0px',
  },
  textTitle: {
    marginTop: '10px',
  },
  noList: {
    paddingTop: '5px',
    paddingBottom: '15px',
    textAlign: 'center',
  },
  loaderWrap: {
    padding: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const TalentSuccessorComponent = props => {
  const classes = useStyles()
  const {type, userId} = props

  const {data, loading} = useQuery(GET_LIST_TALENT_SEARCHED, {
    skip: !userId,
    variables: {
      id: userId,
    },
  })

  const global_user = data?.global_users?.[0]
  const people_work_placement = global_user?.people_work_placements?.[0]

  const colorSwitch = value => {
    const val = value.toLowerCase()

    let color = null
    switch (val) {
      case 'low':
        color = classes.green
        break
      case 'medium':
        color = classes.yellow
        break
      default:
        color = classes.red
    }
    return color
  }

  const renderTime = (start, end) => {
    const dateNow = moment().format('YYYY-MM-DD')
    let year
    let month
    let day
    if (end !== null) {
      year = parseInt(moment(end).diff(start, 'years', true))
      month = parseInt(moment(end).diff(start, 'months', true))
      day = parseInt(moment(end).diff(start, 'days', true))
    } else {
      year = parseInt(moment(dateNow).diff(start, 'years', true))
      month = parseInt(moment(dateNow).diff(start, 'months', true))
      day = parseInt(moment(dateNow).diff(start, 'days', true))
    }

    const floorYear = Math.floor(year)
    const floorMonth = Math.floor(month)
    const floorDay = Math.floor(day)

    if (floorYear !== 0) {
      return `${floorYear} Years`
    } else if (start === end) {
      return `1 Days`
    } else if (floorYear === 0 && floorMonth === 0) {
      return `${floorDay} Days`
    } else {
      return `${floorMonth} Months`
    }
  }

  const NoListComponent = () => {
    return (
      <GreyTypography className={classes.noList}>
        Tidak Ada Daftar
      </GreyTypography>
    )
  }

  const UnknownComponent = () => {
    return (
      <TypographyStyleds
        weight="bold"
        color="#a9a8a8"
        size="12px"
        className={classes.textContent}
      >
        Unknown
      </TypographyStyleds>
    )
  }

  const NoBadgeComponent = () => {
    return (
      <div className={classes.textCenter}>
        <img
          src={NoBadge}
          alt="no-badge"
          width={type === 'compare-talent' ? '50' : '75'}
        />
        <Typography
          variant={type === 'compare-talent' ? 'caption' : 'subtitle2'}
          component={type === 'compare-talent' && 'p'}
        >
          Tidak Ada Lencana
        </Typography>
        <BoldTypography variant="body2">-</BoldTypography>
      </div>
    )
  }

  if (loading) {
    return (
      <div className={classes.loaderWrap}>
        <CircularProgress />
      </div>
    )
  }

  const AvatarComp = type === 'talent-detail' ? Avatar : MediumAvatar

  return (
    <>
      <Paper className={classes.successor}>
        <AvatarComp src={global_user?.avatar} alt={global_user?.name} />

        <div className={classes.spacerTop}>
          <div className={classes.flexDetail}>
            <div className={classes.flexStart}>
              <div className={classes.mr20}>
                <BoldTypography variant="body1">
                  {global_user?.name}
                </BoldTypography>
                <GreyTypography variant="body2">
                  {people_work_placement?.regno || '-'}
                </GreyTypography>
                <Typography variant="caption">
                  {`${moment(people_work_placement?.start_date).format(
                    'YYYY'
                  )} - 
                  ${moment(people_work_placement?.end_date).format('YYYY')}`}
                </Typography>
              </div>
            </div>
          </div>

          {people_work_placement?.talent_assignments?.[0] ? (
            <div className={classes.textCenter}>
              <img
                src={
                  people_work_placement.talent_assignments[0].talent_box?.badge
                }
                alt={
                  people_work_placement.talent_assignments[0].talent_box?.name
                }
                width="35"
              ></img>
              <Typography variant="caption" component="p" gutterBottom>
                {people_work_placement.talent_assignments[0].talent_box?.name}
              </Typography>
              <BoldTypography variant="body2">
                {people_work_placement.talent_assignments[0].talent_score?.toFixed(
                  2
                )}
              </BoldTypography>
            </div>
          ) : (
            <NoBadgeComponent />
          )}
        </div>
      </Paper>

      <Grid container item spacing={2}>
        <Grid item xs={4}>
          <Paper className={classes.paperCategory}>
            <Typography variant="body2" gutterBottom>
              Risiko Kehilangan
            </Typography>
            <BoldTypography
              variant="body1"
              className={colorSwitch(
                people_work_placement?.placement_fields.talent_information
                  .risk_of_loss || 'LOW'
              )}
            >
              {
                level[
                  people_work_placement?.placement_fields.talent_information
                    .risk_of_loss
                ]
              }
            </BoldTypography>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paperCategory}>
            <Typography variant="body2" gutterBottom>
              Dampak Kehilangan
            </Typography>
            <BoldTypography
              variant="body1"
              className={colorSwitch(
                people_work_placement?.placement_fields.talent_information
                  .impact_of_loss || 'LOW'
              )}
            >
              {
                level[
                  people_work_placement?.placement_fields.talent_information
                    .impact_of_loss
                ]
              }
            </BoldTypography>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paperCategory}>
            <Typography variant="body2" gutterBottom>
              Pemimpin Masa Depan
            </Typography>
            <BoldTypography
              variant="body1"
              className={
                people_work_placement?.placement_fields.talent_information
                  .future_leader
                  ? classes.green
                  : classes.red
              }
            >
              {people_work_placement?.placement_fields.talent_information
                .future_leader
                ? 'Iya'
                : 'Tidak'}
            </BoldTypography>
          </Paper>
        </Grid>
      </Grid>

      <Paper className={classes.paperMobility}>
        <Typography variant="body2">Mobilitas Talenta</Typography>
        <GreyTypography style={{fontWeight: 600}}>
          {
            people_work_placement?.placement_fields.talent_information
              .reason_for_leaving
          }
        </GreyTypography>
      </Paper>

      <TypographyStyleds
        weight="bold"
        color="#014a62"
        className={classes.textTitle}
      >
        Pengalaman
      </TypographyStyleds>
      {global_user?.people_profile_experiences.length > 0 ? (
        global_user.people_profile_experiences.map((val, i) => {
          return (
            <FlexBetween key={i}>
              <TypographyStyleds
                weight="bold"
                size="12px"
                className={classes.textContent}
              >
                {val.title}
              </TypographyStyleds>
              <TypographyStyleds
                size="12px"
                color="#039be5"
                className={classes.textContent}
              >
                {renderTime(val.from, val.to)}
              </TypographyStyleds>
            </FlexBetween>
          )
        })
      ) : (
        <NoListComponent />
      )}

      <TypographyStyleds
        weight="bold"
        color="#014a62"
        className={classes.textTitle}
      >
        Projek
      </TypographyStyleds>
      {global_user?.people_profile_projects.length > 0 ? (
        global_user.people_profile_projects.map((val, i) => {
          return (
            <FlexBetween key={i}>
              <TypographyStyleds
                weight="bold"
                size="12px"
                className={classes.textContent}
              >
                {val.project_name}
              </TypographyStyleds>
              <TypographyStyleds
                size="12px"
                color="#039be5"
                className={classes.textContent}
              >
                {renderTime(val.start_date, val.end_date)}
              </TypographyStyleds>
            </FlexBetween>
          )
        })
      ) : (
        <NoListComponent />
      )}

      <TypographyStyleds
        weight="bold"
        color="#014a62"
        className={classes.textTitle}
      >
        Kegiatan Organisasi
      </TypographyStyleds>
      {global_user?.people_profiles.length > 0 ? (
        global_user.people_profiles[0].people_profile_organization_activities
          .length > 0 ? (
          global_user.people_profiles[0].people_profile_organization_activities.map(
            (val, i) => {
              return (
                <FlexBetween key={i}>
                  <TypographyStyleds
                    weight="bold"
                    key={i}
                    size="12px"
                    className={classes.textContent}
                  >
                    {val.organization_name}
                  </TypographyStyleds>
                  <TypographyStyleds
                    size="12px"
                    color="#039be5"
                    className={classes.textContent}
                  >
                    {renderTime(val.start_date, val.end_date)}
                  </TypographyStyleds>
                </FlexBetween>
              )
            }
          )
        ) : (
          <NoListComponent />
        )
      ) : (
        <NoListComponent />
      )}

      <TypographyStyleds
        weight="bold"
        color="#014a62"
        className={classes.textTitle}
      >
        Pendidikan
      </TypographyStyleds>
      {global_user?.people_profile_educations &&
      global_user.people_profile_educations.length > 0 ? (
        global_user.people_profile_educations.map((val, i) => {
          return (
            <FlexBetween key={i}>
              {val?.company_profile ? (
                <TypographyStyleds
                  weight="bold"
                  size="12px"
                  className={classes.textContent}
                >
                  {val.company_profile.legal_name}
                </TypographyStyleds>
              ) : (
                <div className={classes.textContent}>
                  <UnknownComponent />
                </div>
              )}
              <TypographyStyleds
                size="12px"
                color="#039be5"
                className={classes.textContent}
              >
                {renderTime(val.from, val.to)}
              </TypographyStyleds>
            </FlexBetween>
          )
        })
      ) : (
        <NoListComponent />
      )}

      <TypographyStyleds
        weight="bold"
        color="#014a62"
        className={classes.textTitle}
      >
        Keahlian
      </TypographyStyleds>
      {global_user?.people_profile_skills &&
      global_user.people_profile_skills.length > 0 ? (
        global_user.people_profile_skills.map((val, i) => {
          return (
            <FlexBetween key={i}>
              <TypographyStyleds
                weight="bold"
                size="12px"
                className={classes.textContent}
              >
                {val.people_skill.name}
              </TypographyStyleds>
              <TypographyStyleds
                size="12px"
                color="#039be5"
                className={classes.textContent}
              >
                {level_skill?.[val?.people_skill_level?.name] ||
                  capitalize(val?.people_skill_level?.name)}
              </TypographyStyleds>
            </FlexBetween>
          )
        })
      ) : (
        <NoListComponent />
      )}

      <TypographyStyleds
        weight="bold"
        color="#014a62"
        className={classes.textTitle}
      >
        Pelatihan, Lisensi, dan Sertifikasi
      </TypographyStyleds>
      {global_user?.people_profile_certificates &&
      global_user.people_profile_certificates.length > 0 ? (
        global_user.people_profile_certificates.map((val, i) => {
          return (
            <TypographyStyleds
              weight="bold"
              size="12px"
              className={classes.textContent}
              key={i}
            >
              {val.name}
            </TypographyStyleds>
          )
        })
      ) : (
        <NoListComponent />
      )}

      <TypographyStyleds
        weight="bold"
        color="#014a62"
        className={classes.textTitle}
      >
        Penghargaan
      </TypographyStyleds>
      {global_user?.people_profile_awards &&
      global_user.people_profile_awards.length > 0 ? (
        global_user.people_profile_awards.map((val, i) => {
          return (
            <TypographyStyleds
              weight="bold"
              size="12px"
              className={classes.textContent}
              key={i}
            >
              {val.award_name}
            </TypographyStyleds>
          )
        })
      ) : (
        <NoListComponent />
      )}

      <TypographyStyleds
        weight="bold"
        color="#014a62"
        className={classes.textTitle}
      >
        Pembicara/Informan/Juri
      </TypographyStyleds>
      {global_user?.people_profile_speakers &&
      global_user.people_profile_speakers.length > 0 ? (
        global_user.people_profile_speakers.map((val, i) => {
          return (
            <TypographyStyleds
              weight="bold"
              size="12px"
              className={classes.textContent}
              key={i}
            >
              {val.event_name}
            </TypographyStyleds>
          )
        })
      ) : (
        <NoListComponent />
      )}

      <BlueLink
        className={classes.textTitle}
        style={{fontSize: '12px'}}
        to={{
          pathname: `/directory/employee/${global_user?.id}`,
          state: {
            active: 'detail-employee',
          },
        }}
      >
        Lihat Profil Lengkap
      </BlueLink>
    </>
  )
}

export default withRouter(TalentSuccessorComponent)

export const level = {
  LOW: 'Rendah',
  MEDIUM: 'Sedang',
  HIGH: 'Tinggi',
}
