import gql from 'graphql-tag'

export const COUNT_PAYROLL_PROCESS = gql`
  query CountPayrollProcess($company: uuid, $year: Int) {
    v_finance_payrolls_aggregate(
      where: {company: {_eq: $company}, year: {_eq: $year}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_PAYROLL_PROCESS = gql`
  query GetListPayrollProcess(
    $limit: Int
    $offset: Int
    $company: uuid
    $year: Int
  ) {
    v_finance_payrolls(
      limit: $limit
      offset: $offset
      where: {company: {_eq: $company}, year: {_eq: $year}}
    ) {
      year
      month
      total_salary
      addition
      deduction
      total_final_salary
      process_step
    }
  }
`

export const GET_EMPLOYEE_PAYROLL_PROCESS = gql`
  query GetEmployeePayrollProcess(
    $company: uuid
    $year: Int
    $month: Int
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $search: String
  ) {
    employee_count: people_work_payroll_aggregate(
      where: {
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_payroll(
      limit: $limit
      offset: $offset
      where: {
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      id
      employee_placement {
        id
        regno
        status
        end_date
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          company_address {
            id
            office_name
            global_province {
              id
              name
            }
            global_city {
              id
              name
            }
          }
        }
      }
      salary
      addition: income
      deduction
      final_salary
      postponed_items
      allowances
      non_fixed_components
      approver
      approve_step
      finance_personnel
      date_printed
      date_sent
      process_step
    }
  }
`

export const GET_EMPLOYEE_PAYROLL_PROCESS_SAMPLING = gql`
  query(
    $limit: Int
    $offset: Int
    $company: uuid
    $year: Int
    $month: Int
    $job_profile: Int
    $organization: Int
    $position: Int
    $rank: Int
    $search: String
  ) {
    employee_count: people_work_payroll_aggregate(
      where: {
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            id: {_eq: $job_profile}
            organization: {_eq: $organization}
            position: {_eq: $position}
            rank: {_eq: $rank}
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_payroll(
      limit: $limit
      offset: $offset
      where: {
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            id: {_eq: $job_profile}
            organization: {_eq: $organization}
            position: {_eq: $position}
            rank: {_eq: $rank}
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      id
      employee_placement {
        id
        regno
        company_job_profile {
          id
          title
        }
        global_user {
          id
          name
          avatar
        }
      }
      salary
      addition: income
      deduction
      final_salary
      approver
      finance_personnel
      date_printed
      date_sent
      process_step
      approve_step
      postponed_items
    }
  }
`

export const GET_EMPLOYEE_APPROVAL_PAYROLL_PROCESS = gql`
  query GetEmployeeApprovalPayrollProcess(
    $login: uuid
    $company: uuid
    $year: Int
    $month: Int
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $search: String
  ) {
    employee_count: people_work_payroll_aggregate(
      where: {
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
            company_profile: {
              finance_payroll_settings: {
                _or: [
                  {
                    first_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                  {
                    second_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                  {
                    third_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                ]
              }
            }
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_payroll(
      limit: $limit
      offset: $offset
      where: {
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
            company_profile: {
              finance_payroll_settings: {
                _or: [
                  {
                    first_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                  {
                    second_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                  {
                    third_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                ]
              }
            }
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      id
      employee_placement {
        id
        regno
        status
        end_date
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          company_address {
            id
            office_name
            global_province {
              id
              name
            }
            global_city {
              id
              name
            }
          }
        }
      }
      salary
      addition: income
      deduction
      final_salary
      approver
      finance_personnel
      date_printed
      date_sent
      approve_step
      process_step
    }
  }
`

export const GET_PAYROLL_PROCESS_SLIP = gql`
  query($id: bigint!) {
    people_work_payroll_by_pk(id: $id) {
      year
      month
      employee_placement {
        id
        regno
        global_user {
          id
          name
          npwp: people_identities(where: {type: {_eq: "NPWP"}}) {
            id
          }
        }
        company_job_profile {
          id
          title
          company_organization {
            id
            name
          }
          company_employee_position {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
        people_work_salaries(where: {active: {_eq: true}}) {
          currency
          value
          salary_fields
          people_profile_bank {
            id
            global_bank {
              id
              name
            }
            branch
            account_name
            account_number
          }
        }
      }

      salary
      addition: income
      deduction
      final_salary
      postponed_items
      allowances
      non_fixed_components
      payroll_fields
      approver
      approve_step
      finance_personnel
      date_printed
      date_sent
      process_step
    }
  }
`

export const GET_IS_APPROVER = gql`
  query($login: uuid, $company: uuid!) {
    finance_payroll_settings_by_pk(id: $company) {
      first_approval_job_profile
      second_approval_job_profile
      third_approval_job_profile

      first_job_profile {
        id
        title
      }
      second_job_profile {
        id
        title
      }

      third_job_profile {
        id
        title
      }

      is_first: first_job_profile {
        id
        people_work_placements(where: {user: {_eq: $login}}) {
          user
          global_user {
            id
            name
          }
        }
      }

      is_second: second_job_profile {
        id
        people_work_placements(where: {user: {_eq: $login}}) {
          user
          global_user {
            id
            name
          }
        }
      }

      is_third: third_job_profile {
        id
        people_work_placements(where: {user: {_eq: $login}}) {
          user
          global_user {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_EMPLOYEE_PAYROLL_PROCESS_CALCULATION = gql`
  query(
    $company: uuid
    $year: Int
    $month: Int
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $search: String
  ) {
    people_work_payroll(
      limit: $limit
      offset: $offset
      where: {
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      salary
      addition: income
      deduction
      final_salary
      postponed_items
      allowances
      non_fixed_components
    }
  }
`
