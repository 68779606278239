import gql from 'graphql-tag'

export const DELETE_JOB_PROFILE = gql`
  mutation($id: Int) {
    update_company_job_profiles(
      _set: {deletedAt: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
