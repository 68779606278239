import React from 'react'

import {Avatar, IconButton} from '@material-ui/core'
import MaterialTable from 'material-table'

import {RedIcon} from '../../../../../GlobalStyles'
import {TableOptions, TableContainer} from '../../ClaimLimitPageStyles'

function EmployeeBalanceTopUpList(props) {
  const {
    data,
    totalCount,
    loading,
    offset,
    limit,
    onChangeOffset,
    onChangeLimit,
    onRemoveWallet,
  } = props

  const renderActions = field => (
    <IconButton
      aria-label="Remove employee"
      onClick={() => onRemoveWallet(field.user.wallet.id)}
    >
      <RedIcon>delete</RedIcon>
    </IconButton>
  )

  const TABLE_COLUMNS_CONFIG = [
    {title: 'Avatar', render: renderAvatar, width: '8%'},
    {title: 'Employee ID', field: 'regno'},
    {title: 'Employee Name', field: 'user.name'},
    {title: 'Position', field: 'profile.position'},
    {title: 'Actions', render: renderActions, sorting: false, width: '10%'},
  ]

  return (
    <MaterialTable
      isLoading={loading}
      data={data}
      totalCount={totalCount}
      page={offset}
      pageSize={limit}
      onChangePage={onChangeOffset}
      onChangeRowsPerPage={onChangeLimit}
      columns={TABLE_COLUMNS_CONFIG}
      options={TableOptions}
      components={{Container: TableContainer}}
    />
  )
}

export default EmployeeBalanceTopUpList

function renderAvatar(data) {
  return <Avatar alt={data.user.name} src={data.user.avatar} />
}
