import React from 'react'
import {IconButton, Typography, Toolbar, Divider} from '@material-ui/core'
import {
  PaperContainer,
  TextBlackBold,
  GreyTypography,
  Flex,
  FlexBetween,
} from '../../../../GlobalStyles'
import {ArrowBack} from '@material-ui/icons'
import {withRouter} from 'react-router-dom'
import helperStyle, {
  BigAvatar,
  CustomAccordion,
  CustomAccordionSummary,
  CustomAccordionDetails,
  ContentAccordion,
  TextLightBlue,
} from './DevelopmentPageStyles'
import {capitalize} from '../../../../utils/helpers'
import {useQuery} from '@apollo/react-hooks'
import {GET_DEVELOPMENT_PLAN_DETAIL} from '../../../../graphql/queries'
// import moment from 'moment'
import Loading from '../../../../components/loading/LoadingComponent'

const DevelopmentDetailComponent = props => {
  const classes = helperStyle()

  let companyId = ''
  let userId = ''
  if (props.location.state) {
    companyId = props.location.state.companyId
    userId = props.location.state.userId
  } else {
    props.history.push({
      state: {
        active: 'development-plan',
        searchable: true,
      },
    })
  }

  const {data, error} = useQuery(GET_DEVELOPMENT_PLAN_DETAIL, {
    wlb_skipPatch: true,
    variables: {
      company: companyId,
      id: userId,
    },
  })

  const renderStatus = val => {
    const _status = {
      waiting: 'Menunggu',
      onprogress: 'Dalam Perkembangan',
      active: 'Aktif',
      done: 'Selesai',
    }
    let _color
    if (val.toLowerCase() === 'done') {
      _color = '#4caf50'
    } else if (val.toLowerCase() === 'waiting') {
      _color = '#ffa000'
    } else {
      _color = '#039be5'
    }
    return (
      <div className={classes.divStatus} style={{backgroundColor: _color}}>
        {_status[val.toLowerCase()] || capitalize(val)}
      </div>
    )
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const res = data && data.people_work_placements[0]

  return (
    <PaperContainer style={{overflow: 'unset'}}>
      <Toolbar disableGutters>
        <div onClick={() => props.history.goBack()}>
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </div>
        <Typography variant="h6" style={{marginLeft: '15px'}}>
          Rincian Pengembangan
        </Typography>
      </Toolbar>
      <Divider />
      <div style={{padding: '20px'}}>
        <Flex className={classes.mb30}>
          <BigAvatar className={classes.mr25} src={res?.global_user?.avatar} />
          <Flex className={classes.column}>
            <Typography className={classes.bold}>
              {res?.global_user?.name}
            </Typography>
            <GreyTypography>{res?.regno}</GreyTypography>
            <TextBlackBold className={classes.mt10}>
              {res?.company_job_profile?.title}
            </TextBlackBold>
          </Flex>
        </Flex>
        <Typography className={classes.bold}>Rencana Pengembangan</Typography>
        {res.talent_development_plans.map(val => {
          return (
            <CustomAccordion key={val} style={{marginTop: '15px'}}>
              <CustomAccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <FlexBetween>
                  <Flex style={{flexDirection: 'column !important'}}>
                    <TextBlackBold>{val.name}</TextBlackBold>
                    {/* <GreyTypography style={{fontSize: '13px'}}>
                      {moment().format('ll')} - {moment().format('ll')}
                    </GreyTypography> */}
                  </Flex>
                  {renderStatus(val.status)}
                </FlexBetween>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                <ContentAccordion>
                  <div style={{marginTop: '20px'}}>
                    <TextLightBlue className={classes.bold}>
                      {val.measure_of_success}
                    </TextLightBlue>
                    {/* <div style={{paddingLeft: '10px'}}>
                      {[0, 1, 2].map(val => {
                        return (
                          <GreyTypography style={{fontSize: '13px'}} key={val}>
                            Able to develop front-end sofe of website using
                            Reactjs.
                          </GreyTypography>
                        )
                      })}
                    </div> */}
                  </div>
                </ContentAccordion>
              </CustomAccordionDetails>
            </CustomAccordion>
          )
        })}
      </div>
    </PaperContainer>
  )
}

export default withRouter(DevelopmentDetailComponent)
