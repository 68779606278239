import React, {useState, useEffect} from 'react'
import {PageContainer} from '../../../GlobalStyles'
import Card from '../../../components/card/CardComponent'
import {withRouter} from 'react-router-dom'
import ListOrganizationLevel from './list-organization-level/ListOrganizationLevel'
import AddEditOrganizationLevel from './list-organization-level/AddEditOrganizationLevel'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'organization-level':
      return <ListOrganizationLevel searchText={searchText} />
    case 'add-organization-level':
      return <AddEditOrganizationLevel status={state.status} />
    case 'edit-organization-level':
      return (
        <AddEditOrganizationLevel status={state.status} data={state.data} />
      )
    default:
      return <ListOrganizationLevel searchText={searchText} />
  }
}

const OrganizationLevel = props => {
  let activeMenu = 'organization-level'

  let state = {}

  if (props.location.state) {
    activeMenu = props.location.state.active
      ? props.location.state.active
      : 'organization-level'
    state = props.location.state
  }

  useEffect(() => {
    if (props.location.pathname === '/organization/organization-level') {
      props.history.push({
        state: {
          active: 'organization-level',
          searchable: true,
        },
      })
    }
  }, [])

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <Card icon="device_hub" title="Level Organisasi" onSearch={onSearch}>
      <PageContainer>
        {renderComponent(activeMenu, state, searchText)}
      </PageContainer>
    </Card>
  )
}

export default withRouter(OrganizationLevel)
