import React, {useState} from 'react'
import {Button, Divider, CircularProgress, IconButton} from '@material-ui/core'
import MaterialTable from 'material-table'

import {
  PaperContainer,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  Flex,
  FormToolbar,
  FormTitleContainer,
  CustomTypography,
} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Link, withRouter} from 'react-router-dom'
import {TableOptions} from '../../../shared-components/table/TableOptions'
import {competencyOptions} from '../CompetencyPageStyles'

import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {DETAIL_MODEL} from '../../../../graphql/queries'
import {DELETE_MODEL_IN_VERSION} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'

const ModelDetail = props => {
  const id = props.match.params.id
  const {search, location} = props
  const {enqueueSnackbar} = useSnackbar()

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const [deleteModel] = useMutation(DELETE_MODEL_IN_VERSION)

  const {
    data: listDetailModel,
    error: errorDetailModel,
    loading: loadingDetailModel,
    refetch: refetchDetailModel,
  } = useQuery(DETAIL_MODEL, {
    variables: {
      job_profile: id,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      search: `%${search}%`,
    },
  })

  const [openDelete, setOpenDelete] = useState(false)
  const [valueDelete, setValueDelete] = useState({
    id: '',
    name: '',
  })

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleClickOpen = row => {
    setOpenDelete(true)
    setValueDelete({
      id: row.id,
      name: row?.competency_version_dictionary?.competency_dictionary?.name?.en,
    })
  }

  const handleClose = () => {
    setOpenDelete(false)
  }

  const handleMutation = () => {
    deleteModel({
      variables: {
        id: valueDelete.id,
      },
    })
      .then(() => {
        refetchDetailModel()
        enqueueSnackbar(`${valueDelete.name} removed`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
      })
      .catch(error => {
        enqueueSnackbar(JSON.stringify(error), {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
      .finally(() => {
        setValueDelete({
          id: 0,
          name: '',
        })
      })
  }

  const goBack = () => {
    return props.history.goBack()
  }

  React.useEffect(() => {
    refetchDetailModel()
  }, [])

  if (errorDetailModel) {
    return <div>{JSON.stringify(errorDetailModel)}</div>
  } else if (loadingDetailModel) {
    return <CircularProgress />
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => goBack()}
            style={{color: '#000', marginLeft: 10}}
          >
            <ArrowBack />
          </IconButton>
          <CustomTypography fweight="bold" left="10px">
            {location?.state?.position}
          </CustomTypography>
        </FormTitleContainer>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={{
            pathname: `/learning/competency/model/${id}/add`,
            state: {active: 'competence-model'},
          }}
        >
          Add Competency
        </Button>
      </FormToolbar>
      <Divider />

      <MaterialTable
        style={{
          padding: '1.6rem 1.6rem 0',
        }}
        localization={{
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
        columns={[
          {
            title: 'Competency Category',
            field:
              'competency_version_dictionary.competency_dictionary.competency_category.name',
          },
          {
            title: 'Competency Code',
            field:
              'competency_version_dictionary.competency_dictionary.dictionary_id',
          },
          {
            title: 'Competency Name',
            field:
              'row.competency_version_dictionary.competency_dictionary.name.en',
            render: row => (
              <Flex
                style={{
                  width: 250,
                }}
              >
                {
                  row.competency_version_dictionary.competency_dictionary.name
                    .en
                }
              </Flex>
            ),
          },
          {
            title: 'Level',
            field: 'level',
          },
          {
            title: 'Level Description',
            field:
              'row.competency_version_dictionary.competency_dictionary.description',
            render: row => {
              const desc = row.competency_version_dictionary.competency_dictionary.description.levels.find(
                val => val.number === row.level
              )

              return (
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 5,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: desc.description || '',
                  }}
                />
              )
            },
          },
          {
            title: 'Action',
            field: 'id',
            sorting: false,
            render: row => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                          // handleClickOpen(row)
                        }}
                        component={Link}
                        to={{
                          pathname: `/learning/competency/model/detail/${row.id}`,
                          state: {
                            active: 'competence-model',
                            data: row,
                          },
                        }}
                        fontColor="#000"
                      >
                        Detail
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                          handleClickOpen(row)
                        }}
                        fontColor="#ff0000"
                      >
                        Delete
                      </StyledMenuItem>
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            ),
          },
        ]}
        data={listDetailModel.competency_model_items}
        title=""
        options={{
          ...TableOptions,
          ...competencyOptions,
        }}
        components={{
          Toolbar: () => null,
        }}
        isLoading={false}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={listDetailModel.total.aggregate.count}
        page={pagination.offset}
        pageSize={pagination.limit}
      />
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        mutation={handleMutation}
        id={valueDelete.id}
        name={valueDelete.name}
      />
    </PaperContainer>
  )
}

export default withRouter(ModelDetail)
