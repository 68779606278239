import MicroLearningPage from './MicroLearningPageComponent'

export const MicroLearningPageConfig = {
  routes: [
    {
      path: '/learning/micro-learning',
      component: MicroLearningPage,
      exact: true,
    },
    {
      path: '/learning/micro-learning/detail/:id',
      component: MicroLearningPage,
      exact: true,
    },
    {
      path: '/learning/micro-learning/add',
      component: MicroLearningPage,
      exact: true,
    },
    {
      path: '/learning/micro-learning/edit/:id',
      component: MicroLearningPage,
      exact: true,
    },
  ],
}
