import gql from 'graphql-tag'

export const GET_LEGAL_DROPDOWN = gql`
  query {
    legal_type: global_legal_type_placements(order_by: [{id: asc}]) {
      id
      name
      value
    }

    legal_time: global_legal_time_placements(order_by: [{id: asc}]) {
      id
      name
      value
    }

    legal_place: global_legal_place_placements(order_by: [{id: asc}]) {
      id
      name
      value
    }
  }
`
