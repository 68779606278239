import React from 'react'
import {Link as RouterLink, withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Box,
  Divider,
  Typography,
  Link,
  Button,
  CircularProgress,
} from '@material-ui/core'

import {PaperContainer, EnhancedToolbar} from '../../../../GlobalStyles'
import NoDataListFree from '../../../shared-components/NoDataListFreeComponent'
import {
  DetailsCard,
  BalanceAmount,
  CATEGORY_COLOR,
} from '../ClaimLimitPageStyles'
import CompanyTransactionList from './CompanyTransactionList'

import {COMPANY_ID} from '../../../../utils/globals'
import {
  GET_COMPANY_SUMMARY,
  GET_COMPANY_TRANSACTION_HISTORY,
} from '../../../../graphql/queries/budget/limit/getCompanyBalance.query'
import {convertToRupiah} from '../../../../utils/helpers'
import {getCompanyTransactionFilterVariables} from '../filters'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: '700',
  },
}))

function Company(props) {
  const {searchText} = props

  const styles = useStyles()

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="subtitle1" style={{fontWeight: '600'}}>
          Company Claim Limit
        </Typography>
      </EnhancedToolbar>

      <Divider />

      <DetailsCard>
        <Box display="flex">
          <CompanySummary />
          <Box display="flex" flexDirection="column" ml={1}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={RouterLink}
              to={{
                pathname: `/budget/limit/company/topup`,
                state: {active: 'company-topup'},
              }}
            >
              Top Up Claim Limit
            </Button>
          </Box>
        </Box>
      </DetailsCard>

      <Divider />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={3}
        height={48}
      >
        <Typography className={styles.title}>Recent Activity</Typography>

        <Link
          color="secondary"
          component={RouterLink}
          to={{
            pathname: `/budget/limit/company/history`,
            state: {
              active: 'company-history',
              searchable: true,
            },
          }}
        >
          View All
        </Link>
      </Box>

      <CompanyRecentTransactions searchText={searchText} />
    </PaperContainer>
  )
}

export default withRouter(Company)

function CompanySummary() {
  const {data, loading, error} = useQuery(GET_COMPANY_SUMMARY, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {companyID: COMPANY_ID},
  })

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
        py={5.75}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  const deposit = data.in.aggregate.sum.nominal
  const withdraw = data.out.aggregate.sum.nominal
  const balance = deposit - withdraw

  const formattedDeposit = convertToRupiah(deposit)
  const formattedWithdraw = convertToRupiah(withdraw)
  const formattedBalance = convertToRupiah(balance)

  // `key` prop is required here because MUI has layout issues when
  // a box of certain style morphs into another.
  return (
    <Box display="grid" gridGap={24} flexGrow={1} key={1}>
      <BalanceAmount label="Company Claim Limit" amount={formattedBalance} />

      <Box display="flex">
        <BalanceAmount
          label="In"
          color={CATEGORY_COLOR.In}
          icon="arrow_downward"
          amount={formattedDeposit}
        />

        <Divider
          orientation="vertical"
          style={{marginLeft: 24, marginRight: 24}}
        />

        <BalanceAmount
          label="Out"
          color={CATEGORY_COLOR.Out}
          icon="arrow_upward"
          amount={formattedWithdraw}
        />
      </Box>
    </Box>
  )
}

function CompanyRecentTransactions(props) {
  const {searchText} = props

  const where = React.useMemo(() => {
    return getCompanyTransactionFilterVariables({searchText})
  }, [searchText])

  const {data, loading, error} = useQuery(GET_COMPANY_TRANSACTION_HISTORY, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      companyID: COMPANY_ID,
      where: where,
      limit: 5,
    },
  })

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  if (!loading && !data?.total.aggregate.count) {
    return (
      <NoDataListFree
        message1="Sorry, No List"
        message2="You don't have any recent transactions"
      />
    )
  }

  return (
    <CompanyTransactionList
      summary
      data={data?.transactions || []}
      totalCount={data?.total.aggregate.count || 0}
      loading={loading}
    />
  )
}
