import LoanPage from './LoanPageComponent'

export const LoanPageConfig = {
  routes: [
    {
      path: '/financial/loan/activity',
      component: LoanPage,
      state: {active: 'activity'},
      exact: true,
    },
    {
      path: '/financial/loan/activity/create',
      component: LoanPage,
      state: {active: 'create-loan-activity'},
      exact: true,
    },
    {
      path: '/financial/loan/activity/:id/edit',
      component: LoanPage,
      state: {active: 'edit-loan-activity'},
      exact: true,
    },
    {
      path: '/financial/loan/loan-processed',
      component: LoanPage,
      state: {active: 'loan-processed'},
      exact: true,
    },
    {
      path: '/financial/loan/settings',
      component: LoanPage,
      state: {active: 'settings', searchable: true},
      exact: true,
    },
    {
      path: '/financial/loan/settings/policy/create',
      component: LoanPage,
      state: {active: 'create-loan-setting-policy'},
      exact: true,
    },
    {
      path: '/financial/loan/settings/policy/:id/edit',
      component: LoanPage,
      state: {active: 'edit-loan-setting-policy'},
      exact: true,
    },
  ],
}
