import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import {Tab} from '@material-ui/core'

import EmployeeLoanFormPageComponent from '../edit/EmployeeLoanFormPageComponent'
import InstallmentTableFormPageComponent from '../edit/InstallmentTableFormPageComponent'
import PaymentPlanFormPageComponent from '../edit/PaymentPlanFormPageComponent'

import {AntTabs, FormContainer} from '../../../../../GlobalStyles'
import RequestWrapper from '../../../../shared-components/layouts/RequestWrapper'

import {GET_PAID_INSTALLMENT} from '../../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../../utils'

const EditLoanTab = ({loanId, activeTab, setRefetchLoanInformation}) => {
  const [value, setValue] = useState(activeTab || 0)
  const [isAbleToEdit, setIsAbleToEdit] = useState(false)
  const [refetchNumberPaid, setRefetchNumberPaid] = React.useState(false)

  const {data, loading, error, refetch} = useQuery(GET_PAID_INSTALLMENT, {
    variables: {
      loanId: loanId,
    },
    fetchPolicy: 'cache-and_network',
    ...GQL_FINANCE_OPTIONS,
  })

  useEffect(() => {
    if (refetchNumberPaid) {
      ;(async () => {
        try {
          await refetch()
          setRefetchNumberPaid(false)
        } catch (e) {
          console.error(e)
        }
      })()
    }
  }, [refetchNumberPaid])

  useEffect(() => {
    setIsAbleToEdit(data?.loan?.[0]?.installment_num?.length === 0)
  }, [data])

  const TAB_CONTENT = {
    0: (
      <EmployeeLoanFormPageComponent
        isAbleToEdit={isAbleToEdit}
        loanId={loanId}
        setRefetchLoanInformation={setRefetchLoanInformation}
      />
    ),
    1: (
      <PaymentPlanFormPageComponent
        isAbleToEdit={isAbleToEdit}
        loanId={loanId}
        setRefetchLoanInformation={setRefetchLoanInformation}
      />
    ),
    2: (
      <InstallmentTableFormPageComponent
        loanId={loanId}
        setRefetchLoanInformation={setRefetchLoanInformation}
        setRefetchNumberPaid={setRefetchNumberPaid}
      />
    ),
  }

  return (
    <RequestWrapper error={error} loading={loading}>
      <AntTabs
        value={value}
        onChange={(e, newValue) => {
          e.preventDefault()
          setValue(newValue)
        }}
        aria-label="loan-activity-tabs"
      >
        <Tab label="Employee Loan" />
        <Tab label="Payment Plan" />
        <Tab label="Installment" />
      </AntTabs>

      <FormContainer>{TAB_CONTENT[value]}</FormContainer>
    </RequestWrapper>
  )
}

export default withRouter(EditLoanTab)
