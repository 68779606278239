import JobGradePage from './JobGradePageComponent'

export const JobGradePageConfig = {
  routes: [
    {
      path: '/organization/job-grade',
      component: JobGradePage,
      state: 'job-grade',
      exact: true,
    },
    {
      path: '/organization/job-grade/add',
      component: JobGradePage,
      state: 'add-job-grade',
      exact: false,
    },
    {
      path: '/organization/job-grade/edit',
      component: JobGradePage,
      state: 'edit-job-grade',
      exact: false,
    },
  ],
}
