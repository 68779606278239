import gql from 'graphql-tag'

export const GET_DROPDOWN_EDIT_MEMBER = gql`
  query getConfigList($company: uuid, $version: Int, $cluster: Int) {
    holding_version(
      where: {company: {_eq: $company}, status: {_eq: "ACTIVE"}}
      order_by: {name: asc}
    ) {
      id
      name
    }

    holding_categories(
      where: {company: {_eq: $company}, version: {_eq: $version}}
      order_by: {name: asc}
    ) {
      id
      name
      version
    }

    holding_clusters(
      order_by: {name: asc}
      where: {company: {_eq: $company}, version: {_eq: $version}}
    ) {
      id
      name
      version
    }

    holding_subcluster(
      order_by: {holding_cluster: {name: asc}}
      where: {
        company: {_eq: $company}
        version: {_eq: $version}
        holding_cluster: {id: {_eq: $cluster}}
      }
    ) {
      id
      name
      version
      holding_cluster {
        id
      }
    }

    holding_classes(
      where: {company: {_eq: $company}, version: {_eq: $version}}
      order_by: {name: asc}
    ) {
      id
      name
      version
    }
  }
`
