import React from 'react'
import {PaperContainer} from '../../../../GlobalStyles'
import {
  Typography,
  IconButton,
  makeStyles,
  Divider,
  Avatar,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  NameDetail,
  HeaderDetail,
  NumberDetail,
  OfficeDetail,
  AspirationWrapper,
  ExpansionPanelStyle,
  ExpansionPanelSummaryStyle,
  ExpansionPanelDetailStyle,
  ContentAspiration,
  TitleAspiration,
} from './AspirationComponentStyled'
import {withRouter} from 'react-router-dom'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: '2.4',
    marginLeft: 10,
  },
  body: {
    padding: '20px 50px',
    minHeight: 785,
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoUser: {
    display: 'Flex',
    flexDirection: 'column',
  },
  avatar: {
    width: '85px !important',
    height: '85px !important',
    marginRight: 17,
  },
  expan: {
    '&::before': {
      opacity: '0',
    },
    marginBottom: 15,
  },
  titleBody: {
    fontWeight: '600',
    marginBottom: 10,
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '22px 32px',
    backgroundColor: '#ffffff',
    width: '100%',
  },
  detailWrapper: {
    marginBottom: 16,
  },
}))

const DetailAspiration = props => {
  const classes = useStyles()
  const {data, page, rowsPerPage} = props.location.state

  const handleBack = () => {
    props.history.push({
      pathname: `/talent/succession/aspiration`,
      state: {
        active: 'aspiration',
        prevPage: page,
        prevrowsPerPage: rowsPerPage,
        searchable: true,
      },
    })
  }

  return (
    <PaperContainer>
      <HeaderDetail>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography className={classes.title}>Rincian Aspirasi</Typography>
      </HeaderDetail>
      <Divider />
      <div className={classes.body}>
        <div className={classes.user}>
          <Avatar src={data.global_user.avatar} className={classes.avatar} />
          <div className={classes.infoUser}>
            <NameDetail>{data.global_user.name}</NameDetail>
            <NumberDetail>{data.regno}</NumberDetail>
            <OfficeDetail>
              {data.company_job_profile && data.company_job_profile.title} at{' '}
              {data.company_job_profile &&
                data.company_job_profile.company_address &&
                data.company_job_profile.company_address.office_name}
            </OfficeDetail>
          </div>
        </div>

        <AspirationWrapper>
          <Typography className={classes.titleBody}>Aspirasi</Typography>
          {data.talent_aspirations.map((res, i) => {
            return (
              <ExpansionPanelStyle key={i} className={classes.expan}>
                <ExpansionPanelSummaryStyle>
                  {res.company_job_profile && res.company_job_profile.title}
                </ExpansionPanelSummaryStyle>
                <ExpansionPanelDetailStyle>
                  <div className={classes.bodyWrapper}>
                    <div className={classes.detailWrapper}>
                      <TitleAspiration>Keterangan Pekerjaan</TitleAspiration>
                      <ContentAspiration>
                        {res.company_job_profile &&
                          res.company_job_profile.job_description}
                      </ContentAspiration>
                    </div>
                    <div className={classes.detailWrapper}>
                      <TitleAspiration>Ringkasan Pekerjaan</TitleAspiration>
                      <ContentAspiration>
                        {res.company_job_profile &&
                          res.company_job_profile.job_summary}
                      </ContentAspiration>
                    </div>
                    <div className={classes.detailWrapper}>
                      <TitleAspiration>Kompetensi</TitleAspiration>
                      {res.company_job_profile &&
                      res.company_job_profile.company_job_profile_competencies
                        .length > 0 ? (
                        res.company_job_profile.company_job_profile_competencies.map(
                          (res, i) => (
                            <ContentAspiration key={i}>
                              {res.competency_dictionary &&
                                res.competency_dictionary.name.en}
                            </ContentAspiration>
                          )
                        )
                      ) : (
                        <ContentAspiration> - </ContentAspiration>
                      )}
                    </div>
                    <div className={classes.detailWrapper}>
                      <TitleAspiration>Pendidikan</TitleAspiration>
                      {res.company_job_profile &&
                      data.company_job_profile
                        .company_job_profile_r_educations ? (
                        data.company_job_profile.company_job_profile_r_educations.map(
                          (res, i) => (
                            <ContentAspiration key={i}>
                              {res.global_faculty.name}
                            </ContentAspiration>
                          )
                        )
                      ) : (
                        <ContentAspiration> - </ContentAspiration>
                      )}
                    </div>
                    <div className={classes.detailWrapper}>
                      <TitleAspiration>Pengalaman</TitleAspiration>
                      <ContentAspiration>
                        {res.company_job_profile &&
                          res.company_job_profile.experience_level}{' '}
                        Years
                      </ContentAspiration>
                    </div>
                  </div>
                </ExpansionPanelDetailStyle>
              </ExpansionPanelStyle>
            )
          })}
        </AspirationWrapper>
      </div>
    </PaperContainer>
  )
}

export default withRouter(DetailAspiration)
