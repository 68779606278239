import gql from 'graphql-tag'

export const GET_GLOBAL_LANGUAGES = gql`
  query {
    global_languages {
      id
      language_code
      name
      native_name
    }
  }
`

export const GET_SUB_AND_CATEGORIES = gql`
  query {
    academy_course_categories(where: {parent: {_is_null: true}}) {
      id
      name
      code
      subcategory: academy_course_categories {
        id
        name
        code
        parent
      }
    }
  }
`

export const GET_COUNT_SLUG = gql`
  query($slug: String!, $id: uuid) {
    microlearnings_aggregate(where: {id: {_neq: $id}, slug: {_eq: $slug}}) {
      aggregate {
        count
      }
    }
  }
`
