import {useQuery} from '@apollo/react-hooks'
import {
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import {AssignFilter} from '@worklifebeyond/wlb-utils-components'
import MaterialTable from 'material-table'
import React, {useState} from 'react'
import {BlueIcon, FlexBetween, TitleName} from '../../../../../../GlobalStyles'
import {GET_FILTER_POSITION} from '../../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../../utils/globals'
import NoDataListFree from '../../../../../shared-components/NoDataListFreeComponent'
import {TableOptions} from '../../../../../shared-components/table/TableOptions'
// import {
//   BlueIcon,
//   Flex,
//   FlexBetween,
//   GreyTypography,
// } from '../../../../../GlobalStyles'
// import {
//   GET_FILTER_POSITION,
//   GET_NEW_POSITION_ATTENDANCE,
// } from '../../../../../graphql/queries'
// import {COMPANY_ID} from '../../../../../ utils/globals'
// import SelectDataPopup from '../../../../shared-components/popup/SelectDataPopup'
// import {TableOptions} from '../../../../shared-components/table/TableOptions'
import assignFiltersOld from '../../../../../../utils/assignFiltersOld'

export default function StepFour({
  isEdit,
  isError,
  variables,
  setVars,
  setisUpdatePosition,
  assignOption,
  setAssignOption,
}) {
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const {data, error, loading} = useQuery(GET_FILTER_POSITION, {
    skip: assignOption === 1 && !variables,
    variables: {
      ...(assignOption === 1 ? variables : null),
      companyId: COMPANY_ID,
      search: `%${search}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      isFilterPolicy: false,
    },
    fetchPolicy: 'no-cache',
  })

  const selections = React.useMemo(() => {
    const include = {}
    const exclude = {}

    if (assignOption === 1) {
      for (const v in variables) {
        const {_in, _nin} = variables[v]

        include[v] = _in
        exclude[v] = _nin
      }
    }

    return {include, exclude}
  }, [variables, assignOption])

  const handleApplyAssign = result => {
    const {include, exclude} = result

    const _vars = {}

    for (const p in include) {
      if (include[p] && include[p].length > 0) {
        if (!_vars[p]) {
          _vars[p] = {}
        }

        _vars[p]._in = include[p]
      }
    }

    for (const p in exclude) {
      if (exclude[p] && exclude[p].length > 0) {
        if (!_vars[p]) {
          _vars[p] = {}
        }

        _vars[p]._nin = exclude[p]
      }
    }

    setPagination({...pagination, offset: 0})
    setAssignOption(1)
    setVars(_vars)

    if (isEdit) {
      setisUpdatePosition(true)
    }
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  if (error) {
    JSON.stringify(error)
  }

  return (
    <>
      <TitleName gutterBottom>
        Method of adding position
        {assignOption === 'null' && isError ? (
          <Typography variant="body2" color="error" display="inline">
            This field is required
          </Typography>
        ) : (
          ''
        )}
      </TitleName>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="option"
          name="option1"
          value={true}
          style={{display: 'flex', flexDirection: 'row'}}
        >
          <FormControlLabel
            control={
              <Radio
                onChange={() => {
                  setAssignOption(1)
                  setisUpdatePosition(true)
                }}
                checked={assignOption === 1}
              />
            }
            label="Custom Select"
          />
          <FormControlLabel
            control={
              <Radio
                onChange={() => {
                  setAssignOption(2)
                  setisUpdatePosition(true)
                }}
                checked={assignOption === 2}
              />
            }
            label="Select all (Allow to edit)"
          />
        </RadioGroup>
        <Typography style={{color: '#a9a8a8'}}></Typography>
      </FormControl>
      <FlexBetween style={{marginBottom: 20}}>
        <div>
          <Typography variant="body2" style={{fontWeight: 600}} gutterBottom>
            <span>
              {assignOption === 1
                ? `Applied to ${data?.totalAll.aggregate.count || 0} Positions`
                : `Applied to All Positions (${data?.totalAll.aggregate.count ||
                    0} Positions)`}
            </span>

            {isError && (!data || data.total.aggregate.count === 0) && (
              <Typography
                variant="body2"
                color="error"
                display="inline"
                style={{marginLeft: 16}}
              >
                This field is required
              </Typography>
            )}
          </Typography>
        </div>

        <AssignFilter
          filters={assignFiltersOld}
          limit={20}
          initialSelections={selections}
          onApply={handleApplyAssign}
        >
          <Button size="large" variant="contained" color="primary">
            Filter
          </Button>
        </AssignFilter>
      </FlexBetween>

      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        fullWidth
        value={search}
        onChange={e => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BlueIcon>search</BlueIcon>
            </InputAdornment>
          ),
        }}
        style={{marginBottom: 10}}
      />
      <MaterialTable
        columns={[
          {
            title: 'Position Code',
            field: 'code',
            render: row => (row.code ? `[${row.code}]` : '-'),
            cellStyle: {color: '#039BE5'},
          },
          {
            title: 'Position',
            field: 'title',
          },
        ]}
        options={{
          ...TableOptions,
          selection: false,
          emptyRowsWhenPaging: false,
        }}
        components={{
          Toolbar: () => null,
          Container: props => <Paper {...props} elevation={0} />,
        }}
        data={(data && data.company_job_profiles) || []}
        isLoading={loading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={data && data.total.aggregate.count}
        page={pagination.offset}
        pageSize={pagination.limit}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <NoDataListFree
                message1="Sorry, No List"
                message2={
                  search
                    ? "It seems we can't find any results based on your search"
                    : 'Please first select the position to be added with the Add Position button or the button bellow'
                }
                button={
                  <AssignFilter
                    filters={assignFiltersOld}
                    initialSelections={selections}
                    limit={20}
                    onApply={handleApplyAssign}
                  >
                    <Button size="large" variant="contained" color="primary">
                      Add Position
                    </Button>
                  </AssignFilter>
                }
              />
            ),
          },
        }}
      />
    </>
  )
}
