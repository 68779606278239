import React, {useEffect, useState} from 'react'
import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'

import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../DetailEmployeeStyles'
import {SwitchForm, ButtonSubmit} from '../../SharedDetailEmployee'
import {isErrorForm} from '../ShareComponents-detail/helperDetail.jsx'
import FormInput from '../ShareComponents-detail/FormInput'

import {
  ADD_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
} from '../../../../../../graphql/mutations'
import {GET_PEOPLE_PROFILE_PROJECT_POSITION} from '../../../../../../graphql/queries'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_STATE = {
  user: null,
  is_share: false,
  project_name: '',
  start_date: undefined,
  end_date: undefined,
  is_current: false,
  location: null,
  creators: [],
  project_url: '',
  description: '',
  position: '',
}

const INITIAL_SEARCH_STATE = {
  position: '',
}

export default function AddEditProject({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
}) {
  const client = useApolloClient()

  const [formState, setFormState] = useState(STATE_INITIAL)
  const [formData, setFormData] = useState(INITIAL_STATE)
  const [openDelete, setOpenDelete] = useState(false)
  const [search, setSearch] = useState(INITIAL_SEARCH_STATE)
  const [deleteProject] = useMutation(DELETE_PROJECT)

  const isEditing = actionType === 'edit'
  const isError = formState === STATE_ERROR
  const isDispatching = formState === STATE_DISPATCH

  const {data: dataProjectPosition, loading: loadingProjectPosition} = useQuery(
    GET_PEOPLE_PROFILE_PROJECT_POSITION,
    {
      wlb_skipPatch: true,
      skip: !open,
      variables: {
        userId: userIdDetail,
        search: `%${search.position}%`,
        limit: 10,
      },
    }
  )

  useEffect(() => {
    if (actionType === 'edit' && open) {
      setFormData(e => ({
        ...e,
        user: userIdDetail,
        is_share: data.is_share,
        project_name: data.project_name,
        start_date: data.start_date,
        end_date: data.end_date,
        is_current: data.is_current,
        location: data.location?.project_location,
        creators: data.creators,
        project_url: data.project_url,
        description: data.description,
        position: data.position
          ? {value: data.position, label: data.position}
          : null,
      }))
    } else {
      setFormData(e => ({
        ...e,
        user: userIdDetail,
      }))
    }
  }, [open, actionType])

  const handleSave = async () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const verb = isEditing ? 'ubah' : 'tambah'
    let promise

    const variables = Object.assign({}, formData)
    const project_location = formData.location
    variables.location = {project_location}
    variables.end_date = variables.is_current ? null : variables.end_date
    variables.position = variables?.position?.value || null

    if (isEditing) {
      promise = client.mutate({
        mutation: UPDATE_PROJECT,
        variables: {
          id: data?.id,
          object: {...variables},
        },
      })
    } else {
      promise = client.mutate({
        mutation: ADD_PROJECT,
        variables: {
          objects: [variables],
        },
      })
    }

    promise.then(
      () => {
        refetch()
        setFormState(STATE_INITIAL)
        enqueueSnackbar(`Projek  di${verb}`, {variant: 'success'})

        handleCloseModal()
      },
      err => {
        setFormState(STATE_ERROR)
        console.error(err)
        enqueueSnackbar(`Failed to ${verb} project`, {
          variant: 'error',
        })
      }
    )
  }

  const handleDelete = () => {
    deleteProject({
      variables: {
        id: data.id,
        user: userIdDetail,
      },
    })
      .then(() => {
        setFormState(STATE_INITIAL)
        refetch()
        handleCloseModal()
        setOpenDelete(false)
        enqueueSnackbar('Hapus projek berhasil', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Delete project failed, please try again later', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleInputSearchChange = field => query => {
    setSearch({...search, [field]: query})
  }

  const handleAutocompleteChange = item => {
    const {fieldName, label, value} = item
    const selectedOption = {label, value}

    if (fieldName === 'position') {
      setFormData({
        ...formData,
        [fieldName]: selectedOption,
      })
    }
  }

  const handleOpenModalDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }

  const handleCloseModalDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  const handleCloseModal = () => {
    setFormData(INITIAL_STATE)
    setFormState(STATE_INITIAL)
    onClose()
  }

  const _experiences =
    dataProjectPosition?.experiences?.map(data => ({
      value: `${data.as} at ${data.work_at}`,
      label: `${data.as} at ${data.work_at}`,
    })) || []

  const _educations =
    dataProjectPosition?.educations?.map(data => ({
      value: `Student at ${data.study_at?.brand_name}`,
      label: `Student at ${data.study_at?.brand_name}`,
    })) || []

  const positionOptions = _experiences?.concat(_educations)

  const fieldsList = [
    {
      label: 'Nama Projek*',
      placeholder: 'Tambahkan nama projek',
      fieldName: 'project_name',
      type: 'textfield',
      required: true,
      value: formData.project_name,
      error: !formData.project_name,
    },
    {
      label: ['Tanggal Mulai*', 'Tanggal Selesai*'],
      labelDate: 'Saat ini saya sedang bekerja dalam projek ini',
      fieldName: ['start_date', 'end_date', 'is_current'],
      type: 'date',
      required: true,
      value: [
        formData.start_date,
        formData.end_date,
        formData.is_current,
        true,
      ],
      error: [!formData.start_date, !formData.end_date],
    },
    {
      label: 'Lokasi Projek*',
      placeholder: 'Tambahkan lokasi projek',
      fieldName: 'location',
      type: 'textfield',
      value: formData.location,
      required: true,
      error: !formData.location,
    },
    {
      label: 'Kreator Projek',
      placeholder: 'Tekan Enter untuk Tambahkan kreator projek',
      fieldName: 'creators',
      type: 'tag-textfield',
      value: formData.creators,
    },
    {
      label: 'Kerja Sama',
      placeholder: 'Tambahkan kerja sama',
      fieldName: 'position',
      type: 'autocomplete',
      value: formData?.position,
      isLoading: loadingProjectPosition,
      onChange: handleAutocompleteChange,
      onInputChange: handleInputSearchChange('position'),
      option: positionOptions,
    },
    {
      label: 'Tautan Projek',
      placeholder: 'Tambahkan tautan projek',
      fieldName: 'project_url',
      type: 'textfield',
      value: formData.project_url,
    },
    {
      label: 'Keterangan',
      placeholder: 'Tambahkan keterangan',
      fieldName: 'description',
      type: 'textfield',
      multiline: true,
      rows: 6,
      value: formData.description,
    },
  ]

  return (
    <>
      <ModalDetail
        onDelete={actionType === 'edit' && handleOpenModalDelete}
        onClose={handleCloseModal}
        open={open}
        maxWidth="sm"
        title={`${actionType === 'add' ? 'Tambahkan' : 'Ubah'} Projek `}
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Bagikan dengan jaringan:"
            value={formData.is_share}
            labelPlacement="start"
            onChange={e =>
              setFormData({
                ...formData,
                is_share: e.target.checked,
              })
            }
          />
        </div>

        <ModalAddEditWraper>
          <FormInput
            fieldsList={fieldsList}
            open={open}
            setValues={setFormData}
            values={formData}
            errorState={isError}
          />
        </ModalAddEditWraper>

        <ButtonSubmit
          disabled={isDispatching}
          onCancel={handleCloseModal}
          onSave={handleSave}
        />
      </ModalDetail>

      <DeletePopup
        open={openDelete}
        handleClose={handleCloseModalDelete}
        name={data?.project_name}
        mutation={handleDelete}
      />
    </>
  )
}
