import React, {useState, useEffect} from 'react'
import helperStyle, {
  PaperContainer,
  FormContainer,
  AddEditTitle,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  ThinTextfield,
  FormToolbar,
  FormTitleContainer,
  TextHelper,
  StyledReactSelect,
} from '../../../../GlobalStyles'
import {
  Divider,
  IconButton,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Link, withRouter} from 'react-router-dom'
import {COMPANY_ID} from '../../../../utils/globals'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {UPDATE_KPI_LIST, INSERT_KPI_LIST} from '../../../../graphql/mutations'
import {
  LIST_DROPDOWN_LEVEL,
  LIST_DROPDOWN_PERSPECTIVE,
  LIST_DROPDOWN_SCORING,
  LIST_DROPDOWN_WEIGHT,
  LIST_DROPDOWN_ASPECT,
  LIST_DROPDOWN_METHOD,
  LIST_DROPDOWN_MONITORING,
  CHECK_CODE_LIBRARY,
} from '../../../../graphql/queries'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import {goalTypeOpt} from '../../../shared-components/data/SharedData'
import {goalType} from '../../goal/modal/tabs/validate'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'

const AddEditListComponent = props => {
  const classes = helperStyle()
  const {dataListKpi, history} = props
  const {enqueueSnackbar} = useSnackbar()
  const [isEdit, setIsEdit] = useState(false)
  const [allData, setAllData] = useState({
    version: '',
    level: '',
    code: '',
    name: '',
    formula: '',
    description: '',
    scoring: '',
    weight: '',
    aspect: '',
    method: '',
    monitoring: '',
    prespective: '',
    type: null,
    // status: '',
  })
  const [levelDropdown, setLevelDropdown] = useState([])
  const [perspectiveDropdown, setPerspectiveDropdown] = useState([])
  const [scoringDropdown, setScoringDropdown] = useState([])
  const [weightDropdown, setWeightDropdown] = useState([])
  const [aspectDropdown, setAspectDropdown] = useState([])
  const [methodDropdown, setMethodDropdown] = useState([])
  const [monitoringDropdown, setMonitoringDropdown] = useState([])
  const [typeDropdown, setTypeDropdown] = useState([])
  const [searchDropdown, setSearchDropdown] = useState({
    level: '',
    perspective: '',
    scoring: '',
    weight: '',
    aspect: '',
    method: '',
    monitoring: '',
  })

  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  const [mutationAddKpiList] = useMutation(INSERT_KPI_LIST)
  const [mutationEditKpiList] = useMutation(UPDATE_KPI_LIST(dataListKpi?.id))

  const {data: checkCodeLibrary, error: checkCodeLibraryError} = useQuery(
    CHECK_CODE_LIBRARY,
    {
      variables: {
        company: COMPANY_ID,
        code: allData && allData.code,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {data: level, loading: levelLoad, error: levelError} = useQuery(
    LIST_DROPDOWN_LEVEL,
    {
      variables: {
        companyId: COMPANY_ID,
        search: `%${searchDropdown.level}%`,
        offset: 0,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {
    data: perspective,
    loading: perspectiveLoad,
    error: perspectiveError,
  } = useQuery(LIST_DROPDOWN_PERSPECTIVE, {
    variables: {
      companyId: COMPANY_ID,
      search: `%${searchDropdown.perspective}%`,
      offset: 0,
      limit: 30,
    },
    fetchPolicy: 'cache-and-network',
  })

  const {data: scoring, loading: scoringLoad, error: scoringError} = useQuery(
    LIST_DROPDOWN_SCORING,
    {
      variables: {
        companyId: COMPANY_ID,
        search: `%${searchDropdown.scoring}%`,
        offset: 0,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {data: weight, loading: weightLoad, error: weightError} = useQuery(
    LIST_DROPDOWN_WEIGHT,
    {
      variables: {
        companyId: COMPANY_ID,
        search: `%${searchDropdown.weight}%`,
        offset: 0,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {data: aspect, loading: aspectLoad, error: aspectError} = useQuery(
    LIST_DROPDOWN_ASPECT,
    {
      variables: {
        companyId: COMPANY_ID,
        search: `%${searchDropdown.aspect}%`,
        offset: 0,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {data: method, loading: methodLoad, error: methodError} = useQuery(
    LIST_DROPDOWN_METHOD,
    {
      variables: {
        companyId: COMPANY_ID,
        search: `%${searchDropdown.method}%`,
        offset: 0,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {
    data: monitoring,
    loading: monitoringLoad,
    error: monitoringError,
  } = useQuery(LIST_DROPDOWN_MONITORING, {
    variables: {
      companyId: COMPANY_ID,
      search: `%${searchDropdown.monitoring}%`,
      offset: 0,
      limit: 30,
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (dataListKpi) {
      setIsEdit(true)
      setAllData({
        version: dataListKpi?.kpi_version?.id,
        level: dataListKpi?.kpi_level?.id,
        code: dataListKpi?.code,
        name: dataListKpi?.name,
        formula: dataListKpi?.formula,
        description: dataListKpi?.description,
        scoring: dataListKpi?.kpi_scoring_nature?.id,
        weight: dataListKpi?.kpi_weight_unit?.id,
        aspect: dataListKpi?.kpi_aspect?.id,
        method: dataListKpi?.kpi_method?.id,
        monitoring: dataListKpi?.kpi_monitoring_frequency?.id,
        prespective: dataListKpi?.kpi_perspective?.id,
        type: dataListKpi?.type,
      })
      setLevelDropdown({
        label: `${dataListKpi?.kpi_level?.code} - ${dataListKpi?.kpi_level?.name}`,
        value: dataListKpi?.kpi_level?.id,
      })
      setPerspectiveDropdown({
        label: `${dataListKpi?.kpi_perspective?.code} - ${dataListKpi?.kpi_perspective?.name}`,
        value: dataListKpi?.kpi_perspective?.id,
      })
      setScoringDropdown({
        label: `${dataListKpi?.kpi_scoring_nature?.code} - ${dataListKpi?.kpi_scoring_nature?.name}`,
        value: dataListKpi?.kpi_scoring_nature?.id,
      })
      setWeightDropdown({
        label: `${dataListKpi?.kpi_weight_unit?.code} - ${dataListKpi?.kpi_weight_unit?.name}`,
        value: dataListKpi?.kpi_weight_unit?.id,
      })
      setAspectDropdown({
        label: `${dataListKpi?.kpi_aspect?.code} - ${dataListKpi?.kpi_aspect?.name}`,
        value: dataListKpi?.kpi_aspect?.id,
      })
      setMethodDropdown({
        label: `${dataListKpi?.kpi_method?.code} - ${dataListKpi?.kpi_method?.name}`,
        value: dataListKpi?.kpi_method?.id,
      })
      setMonitoringDropdown({
        label: `${dataListKpi?.kpi_monitoring_frequency?.code} - ${dataListKpi?.kpi_monitoring_frequency?.name}`,
        value: dataListKpi?.kpi_monitoring_frequency?.id,
      })
      setTypeDropdown({
        label: dataListKpi?.type ? goalType[dataListKpi.type] : '-',
        value: dataListKpi?.type,
      })
    }
  }, [isEdit, dataListKpi])

  const mutateScoring = () => {
    if (
      !allData.level ||
      !allData.code ||
      allData.code.length > 50 ||
      !allData.name ||
      !allData.formula ||
      !allData.description ||
      !allData.scoring ||
      !allData.weight ||
      !allData.aspect ||
      !allData.method ||
      !allData.monitoring ||
      !allData.prespective ||
      (!isEdit && !allData.type) ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const data = allData
      if (!isEdit) {
        mutationAddKpiList({variables: {data: data}})
          .then(() => {
            enqueueSnackbar(`Success Add data`, {variant: 'success'})
            history.push({
              pathname: '/performance/kpi/list',
              state: {active: 'list', searchable: true},
            })
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
            })
          })
      } else {
        mutationEditKpiList({variables: {dataEdited: data}})
          .then(() => {
            enqueueSnackbar(`Success Update data`, {
              variant: 'success',
            })
            history.push({
              pathname: '/performance/kpi/list',
              state: {active: 'list', searchable: true},
            })
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
            })
          })
      }
    }
  }

  if (
    (level === undefined && levelError === undefined) ||
    (perspective === undefined && perspectiveError === undefined) ||
    (scoring === undefined && scoringError === undefined) ||
    (weight === undefined && weightError === undefined) ||
    (aspect === undefined && aspectError === undefined) ||
    (method === undefined && methodError === undefined) ||
    (monitoring === undefined && monitoringError === undefined) ||
    (checkCodeLibrary === undefined && checkCodeLibraryError === undefined)
  ) {
    return <CircularProgress />
  } else if (
    levelError !== undefined ||
    perspectiveError !== undefined ||
    scoringError !== undefined ||
    weightError !== undefined ||
    aspectError !== undefined ||
    methodError !== undefined ||
    monitoringError !== undefined ||
    checkCodeLibraryError !== undefined
  ) {
    console.error(
      levelError ||
        perspectiveError ||
        scoringError ||
        weightError ||
        aspectError ||
        methodError ||
        monitoringError ||
        checkCodeLibraryError
    )

    return null
  }

  const codeChecker =
    checkCodeLibrary?.kpi_lists_aggregate?.aggregate?.count > 0

  const checkSameCodeWithAdd =
    allData?.code?.length >= 1 ? dataListKpi?.code === allData.code : false

  const levels = level?.kpi_levels?.map(opt => ({
    value: opt.id,
    label: `${opt.code} - ${opt.name}`,
  }))

  const perspectives = perspective?.kpi_perspectives?.map(opt => ({
    value: opt.id,
    label: `${opt.code} - ${opt.name}`,
  }))

  const scorings = scoring?.kpi_scoring_natures?.map(opt => ({
    value: opt.id,
    label: `${opt.code} - ${opt.name}`,
  }))

  const weights = weight?.kpi_weight_units?.map(opt => ({
    value: opt.id,
    label: `${opt.code} - ${opt.name}`,
  }))

  const aspects = aspect?.kpi_aspects?.map(opt => ({
    value: opt.id,
    label: `${opt.code} - ${opt.name}`,
  }))

  const methods = method?.kpi_methods?.map(opt => ({
    value: opt.id,
    label: `${opt.code} - ${opt.name}`,
  }))

  const monitorings = monitoring?.kpi_monitoring_frequencies?.map(opt => ({
    value: opt.id,
    label: `${opt.code} - ${opt.name}`,
  }))

  const handleChange = (name, value) => {
    setAllData({
      ...allData,
      [name]: value,
      version: null,
    })
  }

  const action = isEdit ? 'Edit' : 'Add'

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link
            to={{
              pathname: '/performance/kpi/list',
              state: {active: 'list', searchable: true},
            }}
          >
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6" display="inline">
            {isEdit ? 'Ubah' : 'Tambahkan'} Kumpulan{' '}
            {GLOBAL_GLOSSARY.performance.Goal}
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            size="large"
            component={Link}
            to={{
              pathname: '/performance/kpi/list',
              state: {active: 'list', searchable: true},
            }}
            style={{marginRight: 12}}
          >
            Batalkan
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="large"
          >
            Simpan
          </Button>
        </div>
      </FormToolbar>
      <Divider></Divider>
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Nama {GLOBAL_GLOSSARY.performance.Goal}</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                handleChange('name', event.target.value)
              }}
              variant="outlined"
              value={allData.name}
              error={!allData.name && isError}
              helperText={
                !allData.name && isError ? 'Bagian ini diperlukan' : ''
              }
              placeholder={`Tambahkan ${GLOBAL_GLOSSARY.performance.Goal}`}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Kode {GLOBAL_GLOSSARY.performance.Goal}</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                handleChange('code', event.target.value)
              }}
              variant="outlined"
              value={allData.code}
              error={
                (!allData.code && isError) ||
                (allData.code && allData.code.length > 50) ||
                (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
              }
              helperText={
                !allData.code && isError
                  ? 'Bagian ini diperlukan'
                  : (codeChecker
                    ? !checkSameCodeWithAdd
                    : checkSameCodeWithAdd)
                  ? `Kode kumpulan ${GLOBAL_GLOSSARY.performance.Goal} sudah ada`
                  : allData.code && allData.code.length > 50
                  ? 'Jumlah karakter maksimum adalah 50 karakter'
                  : `Kode kumpulan ${GLOBAL_GLOSSARY.performance.Goal} harus unik`
              }
              placeholder={`Tambahkan Kode ${GLOBAL_GLOSSARY.performance.Goal}`}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Level {GLOBAL_GLOSSARY.performance.Goal}</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <StyledReactSelect
              id="goalLevel"
              options={levels}
              placeholder={`Pilih level ${GLOBAL_GLOSSARY.performance.Goal}`}
              value={levelDropdown}
              isLoading={levelLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  level: dataSearchDropdown,
                })
              }
              onChange={event => {
                handleChange('level', event.value)
                setLevelDropdown(event)
              }}
              marginNormal
            />
            <TextHelper className={classes.margin0}>
              {!allData.level && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Jenis {GLOBAL_GLOSSARY.performance.Goal}</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <StyledReactSelect
              id="goalType"
              options={goalTypeOpt}
              isDisabled={isEdit}
              placeholder={`Pilih jenis ${GLOBAL_GLOSSARY.performance.Goal}`}
              value={typeDropdown}
              onChange={event => {
                handleChange('type', event.value)
                setTypeDropdown(event)
              }}
              marginNormal
            />
            <TextHelper className={classes.margin0}>
              {!isEdit && !allData.type && isError && 'Bagian ini diperlukan'}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Perspektif {GLOBAL_GLOSSARY.performance.Goal}</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <StyledReactSelect
              id="goalPerspective"
              options={perspectives}
              placeholder={`Pilih Perspektif ${GLOBAL_GLOSSARY.performance.Goal}`}
              value={perspectiveDropdown}
              isLoading={perspectiveLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  perspective: dataSearchDropdown,
                })
              }
              onChange={event => {
                handleChange('prespective', event.value)
                setPerspectiveDropdown(event)
              }}
              marginNormal
            />
            <TextHelper className={classes.margin0}>
              {!allData.prespective && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>6.</TitleNumber>
            <TitleName>Metrik</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                handleChange('formula', event.target.value)
              }}
              variant="outlined"
              value={allData.formula}
              error={!allData.formula && isError}
              helperText={
                !allData.formula && isError ? 'Bagian ini diperlukan' : ''
              }
              placeholder="Tambahkan Metrik"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>7.</TitleNumber>
            <TitleName>
              Sifat Penilaian {GLOBAL_GLOSSARY.performance.Goal}
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <StyledReactSelect
              id="goalScoring"
              options={scorings}
              placeholder={`Pilih Sifat Penilaian ${GLOBAL_GLOSSARY.performance.Goal}`}
              value={scoringDropdown}
              isLoading={scoringLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  scoring: dataSearchDropdown,
                })
              }
              onChange={event => {
                handleChange('scoring', event.value)
                setScoringDropdown(event)
              }}
              marginNormal
            />
            <TextHelper className={classes.margin0}>
              {!allData.scoring && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>8.</TitleNumber>
            <TitleName>
              {GLOBAL_GLOSSARY.performance['Weight Unit']}{' '}
              {GLOBAL_GLOSSARY.performance.Goal}
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <StyledReactSelect
              id="goalWeight"
              options={weights}
              placeholder={`Pilih ${GLOBAL_GLOSSARY.performance['Weight Unit']} ${GLOBAL_GLOSSARY.performance.Goal}`}
              value={weightDropdown}
              isLoading={weightLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  weight: dataSearchDropdown,
                })
              }
              onChange={event => {
                handleChange('weight', event.value)
                setWeightDropdown(event)
              }}
              marginNormal
            />
            <TextHelper className={classes.margin0}>
              {!allData.weight && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>9.</TitleNumber>
            <TitleName>Aspek {GLOBAL_GLOSSARY.performance.Goal}</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <StyledReactSelect
              id="goalAspect"
              options={aspects}
              placeholder={`Pilih Aspek ${GLOBAL_GLOSSARY.performance.Goal}`}
              value={aspectDropdown}
              isLoading={aspectLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  aspect: dataSearchDropdown,
                })
              }
              onChange={event => {
                handleChange('aspect', event.value)
                setAspectDropdown(event)
              }}
              marginNormal
            />
            <TextHelper className={classes.margin0}>
              {!allData.aspect && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>10.</TitleNumber>
            <TitleName>Metode {GLOBAL_GLOSSARY.performance.Goal}</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <StyledReactSelect
              id="goalMetod"
              options={methods}
              placeholder={`Pilih Metode ${GLOBAL_GLOSSARY.performance.Goal}`}
              value={methodDropdown}
              isLoading={methodLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  method: dataSearchDropdown,
                })
              }
              onChange={event => {
                handleChange('method', event.value)
                setMethodDropdown(event)
              }}
              marginNormal
            />
            <TextHelper className={classes.margin0}>
              {!allData.method && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>11.</TitleNumber>
            <TitleName>
              Frekuensi pemantauan {GLOBAL_GLOSSARY.performance.Goal}
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <StyledReactSelect
              id="goalMonitoring"
              options={monitorings}
              placeholder={`Pilih Frekuensi pemantauan ${GLOBAL_GLOSSARY.performance.Goal}`}
              value={monitoringDropdown}
              isLoading={monitoringLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  monitoring: dataSearchDropdown,
                })
              }
              onChange={event => {
                handleChange('monitoring', event.value)
                setMonitoringDropdown(event)
              }}
              marginNormal
            />
            <TextHelper className={classes.margin0}>
              {!allData.monitoring && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>12.</TitleNumber>
            <TitleName>Keterangan</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                handleChange('description', event.target.value)
              }}
              variant="outlined"
              value={allData.description}
              error={!allData.description && isError}
              helperText={
                !allData.description && isError ? 'Bagian ini diperlukan' : ''
              }
              placeholder={`Tambahkan keterangan ${GLOBAL_GLOSSARY.performance.Goal}`}
            />
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        mutation={mutateScoring}
        header={CONFIRMATION[action]?.header}
        body={CONFIRMATION[action]?.body}
        button={CONFIRMATION[action]?.button}
        buttonClose="Batalkan"
      />
    </PaperContainer>
  )
}

export default withRouter(AddEditListComponent)

const CONFIRMATION = {
  Add: {
    header: `Tambahkan kumpulan ${GLOBAL_GLOSSARY.performance.Goal} yang baru `,
    body: `Apakah anda yakin akan menambahkan kumpulan ${GLOBAL_GLOSSARY.performance.Goal} yang baru ?`,
    button: 'Tambah',
  },
  Edit: {
    header: `Ubah kumpulan ${GLOBAL_GLOSSARY.performance.Goal}`,
    body: `Apakah anda yakin akan mengubah kumpulan ${GLOBAL_GLOSSARY.performance.Goal} ?`,
    button: 'Ubah',
  },
}
