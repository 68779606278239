import React, {useState, useLayoutEffect} from 'react'
import {
  Button,
  InputAdornment,
  TablePagination,
  TextField,
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'

import ComponentPayrollList from './component-payroll/list-components/ComponentList'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import PayrollSettingsWrapper from '../PayrollSettingsWrapper'
import usePagination from '../../hooks/usePagination'
import {
  FlexBetween,
  FromButton,
  GreyTypography,
} from '../../../../../GlobalStyles'
import SortMenuList from '../../../../../components/sort-menu-list'

import {COMPANY_ID} from '../../../../../utils/globals'
import {
  GET_BALOON_STATUS_BUTTON_QUERY,
  GET_COMPONENT_SETTING,
} from '../../../../../graphql/queries'
import {Magnify} from 'mdi-material-ui'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import {TextDarkBlue} from '../../../../company/office/OfficePageStyles'
import {CLOSE_BALOON, IMPORT_DATA} from '../../../../../graphql/mutations'
import ImportPopup from '../../../../../components/importExcel/ImportPopup'
import ImportBaloonPayroll from '../../../../../components/importExcel/ImportBaloonPayroll'

const PayrollSettings = props => {
  const {activeComponent, setActiveComponent, generalSettingData} = props

  const [search, setSearch] = useState('')
  const [listOfComponents, setListOfComponents] = useState([])
  const [totalData, setTotalData] = useState(0)
  const [selectedPeriodSort, setSelectedPeriodSort] = useState('desc')
  const [selectedAlphabetSort, setSelectedAlphabetSort] = useState(null)
  const {
    pageSizeOption,
    limit,
    offset,
    currentPage,
    rowPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination()

  const [openImport, setImport] = useState(false)
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )
  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    refetch: refetchBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON_QUERY, {
    variables: {
      company: COMPANY_ID,
      table: 'finance_settings_component_import',
    },
  })

  const {data: componentSettingData, loading, error, refetch} = useQuery(
    GET_COMPONENT_SETTING,
    {
      variables: {
        company: COMPANY_ID,
        limit: limit,
        offset: offset,
        sort_id: selectedPeriodSort,
        sort_name: selectedAlphabetSort,
        search: `%${search}%`,
      },
      ...GQL_FINANCE_OPTIONS,
      fetchPolicy: 'cache-and-network',
    }
  )

  useLayoutEffect(() => {
    if (componentSettingData) {
      if (componentSettingData.finance_payroll_settings_by_pk) {
        if (
          componentSettingData.finance_payroll_settings_by_pk.company_profile
            .finance_nonfixed_components
        ) {
          setListOfComponents(
            componentSettingData.finance_payroll_settings_by_pk.company_profile
              .finance_nonfixed_components
          )
          setTotalData(componentSettingData.total.aggregate.count)
        }
      }
    }
  }, [componentSettingData])

  if (
    error !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationImportError !== undefined
  ) {
    return (
      <div>
        {JSON.stringify(error) ||
          JSON.stringify(errorGetBaloonStatus) ||
          JSON.stringify(mutationCloseBaloonError) ||
          JSON.stringify(mutationImportError)}
      </div>
    )
  }

  if (getBaloonStatus) {
    // console.log("[getBaloonStatus]",getBaloonStatus)
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  const importMutation = async () => {
    try {
      // eslint-disable-next-line
      const response = await mutationImport({
        variables: {
          table: 'finance_settings_component_import',
          file: fileURL,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus &&
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  const handleClickOpenImport = () => {
    setImport(true)
  }

  const handleCloseImport = () => {
    setImport(false)
  }

  if (error) {
    console.error(error)
  }

  return (
    <PayrollSettingsWrapper
      activeComponent={activeComponent}
      setActiveComponent={setActiveComponent}
      hasChange={false}
      generalSettingData={generalSettingData}
    >
      {getBaloonStatus && (
        <ImportBaloonPayroll
          data={getBaloonStatus}
          closeBaloonMutation={closeBaloonMutation}
          setImport={setImport}
          mutationCloseBaloon={mutationCloseBaloon}
          refetchBaloonStatus={refetchBaloonStatus}
        />
      )}
      <FromButton style={{minHeight: 0}}>
        <>
          <Button
            variant={'outlined'}
            color="primary"
            size="large"
            onClick={() => handleClickOpenImport()}
            disabled={
              (getBaloonStatus &&
                getBaloonStatus.company_import_data_logs.length > 0 &&
                // eslint-disable-next-line
                getBaloonStatus.company_import_data_logs[0].status ===
                  'WAITING') ||
              false
            }
          >
            {getBaloonStatus &&
            getBaloonStatus.company_import_data_logs.length > 0 &&
            getBaloonStatus.company_import_data_logs[0].status === 'WAITING' ? (
              <GreyTypography>Importing on proces...</GreyTypography>
            ) : (
              <TextDarkBlue>Import Component Data</TextDarkBlue>
            )}
          </Button>{' '}
          {listOfComponents.length > 0 && (
            <Button
              size="large"
              variant="contained"
              color="primary"
              component={Link}
              style={{marginLeft: 12}}
              to={{
                pathname: '/financial/payroll/setting/add-component',
                state: {active: 'settings-add-component'},
              }}
            >
              Add Component
            </Button>
          )}
        </>
      </FromButton>

      <FlexBetween>
        <TextField
          margin="normal"
          variant="outlined"
          style={{flexGrow: '1', marginRight: 10}}
          fullWidth
          onChange={event => setSearch(event.target.value)}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment className="ml-0" position="start">
                <Magnify style={{color: '#014a62', width: 20, height: 20}} />
              </InputAdornment>
            ),
          }}
        />
        <SortMenuList
          setSelectedPeriodSort={setSelectedPeriodSort}
          setSelectedAlphabetSort={setSelectedAlphabetSort}
        ></SortMenuList>
      </FlexBetween>

      {listOfComponents.length === 0 && !loading && (
        <NoDataListComponent message={`You can start add component here`}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            component={Link}
            to={{
              pathname: '/financial/payroll/setting/add-component',
              state: {active: 'settings-add-component'},
            }}
          >
            Add Component
          </Button>
        </NoDataListComponent>
      )}

      {listOfComponents.map((item, i) => {
        return (
          <ComponentPayrollList
            refetch={refetch}
            key={i}
            data={item}
            isOpen={i === 0}
          />
        )
      })}

      {totalData > 0 && (
        <TablePagination
          rowsPerPageOptions={pageSizeOption}
          component="div"
          rowsPerPage={rowPerPage}
          page={currentPage}
          count={totalData}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <ImportPopup
        open={openImport}
        position={'Component Data'}
        handleClose={handleCloseImport}
        mutation={importMutation}
        isAlreadyImported={isAlreadyImported}
        setIsAlreadyImported={setIsAlreadyImported}
        setImport={setImport}
        fileURL={fileURL}
        setFileURL={setFileURL}
        closeBaloon={closeBaloonMutation}
        errorsImport={
          getBaloonStatus &&
          getBaloonStatus.company_import_data_logs.length > 0 &&
          getBaloonStatus.company_import_data_logs[0].reports &&
          getBaloonStatus.company_import_data_logs[0].reports.failed.length > 0
            ? getBaloonStatus.company_import_data_logs[0].reports.failed
            : []
        }
      />
    </PayrollSettingsWrapper>
  )
}

export default PayrollSettings
