import gql from 'graphql-tag'

export const GET_DIRECTORY_ALUMNI_LIST = gql`
  query getAlumniIDs(
    $search: String
    $limit: Int
    $offset: Int
    $isOrganizationLevelFilterActive: Boolean!
    $incOrganizationLevelIDs: [Int!]
    $excOrganizationLevelIDs: [Int!]
    $isOrganizationUnitFilterActive: Boolean!
    $incOrganizationUnitIDs: [Int!]
    $excOrganizationUnitIDs: [Int!]
    $isJobLevelFilterActive: Boolean!
    $incJobLevelIDs: [Int!]
    $excJobLevelIDs: [Int!]
    $isJobGradeFilterActive: Boolean!
    $incJobGradeIDs: [Int!]
    $excJobGradeIDs: [Int!]
    $isJobTitleFilterActive: Boolean!
    $incJobTitleIDs: [Int!]
    $excJobTitleIDs: [Int!]
    $isJobFamilyFilterActive: Boolean!
    $incJobFamilyIDs: [Int!]
    $excJobFamilyIDs: [Int!]
    $isPositionFilterActive: Boolean!
    $incPositionIDs: [Int!]
    $excPositionIDs: [Int!]
    $isOfficeLocationFilterActive: Boolean!
    $incOfficeLocationIDs: [Int!]
    $excOfficeLocationIDs: [Int!]
    $isEmployeeTypeFilterActive: Boolean!
    $incEmployeeTypes: [String!]
    $excEmployeeTypes: [String!]
    $isTimeArrangementFilterActive: Boolean!
    $incTimeArrangements: [String!]
    $excTimeArrangements: [String!]
    $isWorkingArrangementFilterActive: Boolean!
    $incWorkingArrangements: [String!]
    $excWorkingArrangements: [String!]
    $isSalaryFilterActive: Boolean!
    $incMinSalary: float8
    $incMaxSalary: float8
    $excMinSalary: float8
    $excMaxSalary: float8
    $isAgeFilterActive: Boolean!
    $incMinBirthdate: date
    $incMaxBirthdate: date
    $excMinBirthdate: date
    $excMaxBirthdate: date
    $isGenderFilterActive: Boolean!
    $incGenders: [enum_gender!]
    $excGenders: [enum_gender!]
    $isReligionFilterActive: Boolean!
    $incReligionIDs: [Int!]
    $excReligionIDs: [Int!]
    $isEducationFilterActive: Boolean!
    $incEducationIDs: [uuid!]
    $excEducationIDs: [uuid!]
    $isHobbyFilterActive: Boolean!
    $incHobbyIDs: [Int!]
    $excHobbyIDs: [Int!]
    $incBadges: [Int!]
    $excBadges: [Int!]
    $incTalentScoreFrom: float8
    $incTalentScoreTo: float8
    $excTalentScoreFrom: float8
    $excTalentScoreTo: float8
    $isFilterTalent: Boolean
  ) {
    total_no_filter: v_latest_placements_aggregate(
      where: {status: {_eq: "INACTIVE"}}
    ) {
      aggregate {
        count
      }
    }
    total: v_latest_placements_aggregate(
      where: {
        _and: [
          { status: { _eq: "INACTIVE" } }
          {
            people_work_placement: {
              _and: [
                {
                  _or: [
                    { regno: { _ilike: $search } }
                    { global_user: { name: { _ilike: $search } } }
                    { company_job_profile: { title: { _ilike: $search } } }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isOrganizationLevelFilterActive } }
                    {
                      _and: [
                        {
                          _not: {
                            id: { _is_null: $isOrganizationLevelFilterActive }
                          }
                        }
                        {
                          company_organization: {
                            _and: [
                              {
                                organization_level_id: {
                                  _in: $incOrganizationLevelIDs
                                }
                              }
                              {
                                _or: [
                                  {
                                    organization_level_id: {
                                      _nin: $excOrganizationLevelIDs
                                    }
                                  }
                                  { organization_level_id: { _is_null: true } }
                                ]
                              }
                            ]
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isOrganizationUnitFilterActive } }
                    {
                      _and: [
                        {
                          _not: {
                            id: { _is_null: $isOrganizationUnitFilterActive }
                          }
                        }
                        { organization: { _in: $incOrganizationUnitIDs } }
                        {
                          _or: [
                            { organization: { _nin: $excOrganizationUnitIDs } }
                            { organization: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isJobLevelFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isJobLevelFilterActive } } }
                        { rank: { _in: $incJobLevelIDs } }
                        {
                          _or: [
                            { rank: { _nin: $excJobLevelIDs } }
                            { rank: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isJobGradeFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isJobGradeFilterActive } } }
                        {
                          company_job_profile: {
                            _and: [
                              { job_grade_id: { _in: $incJobGradeIDs } }
                              {
                                _or: [
                                  { job_grade_id: { _nin: $excJobGradeIDs } }
                                  { job_grade_id: { _is_null: true } }
                                ]
                              }
                            ]
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isJobTitleFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isJobTitleFilterActive } } }
                        { position: { _in: $incJobTitleIDs } }
                        {
                          _or: [
                            { position: { _nin: $excJobTitleIDs } }
                            { position: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isJobFamilyFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isJobFamilyFilterActive } } }
                        {
                          company_employee_position: {
                            _and: [
                              { job_mapping_id: { _in: $incJobFamilyIDs } }
                              {
                                _or: [
                                  { job_mapping_id: { _nin: $excJobFamilyIDs } }
                                  { job_mapping_id: { _is_null: true } }
                                ]
                              }
                            ]
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isPositionFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isPositionFilterActive } } }
                        { job_profile: { _in: $incPositionIDs } }
                        {
                          _or: [
                            { job_profile: { _nin: $excPositionIDs } }
                            { job_profile: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isOfficeLocationFilterActive } }
                    {
                      _and: [
                        {
                          _not: {
                            id: { _is_null: $isOfficeLocationFilterActive }
                          }
                        }
                        { office_address: { _in: $incOfficeLocationIDs } }
                        {
                          _or: [
                            { office_address: { _nin: $excOfficeLocationIDs } }
                            { office_address: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isEmployeeTypeFilterActive } }
                    {
                      _and: [
                        {
                          _not: { id: { _is_null: $isEmployeeTypeFilterActive } }
                        }
                        { type: { _in: $incEmployeeTypes } }
                        {
                          _or: [
                            { type: { _nin: $excEmployeeTypes } }
                            { type: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isTimeArrangementFilterActive } }
                    {
                      _and: [
                        {
                          _not: {
                            id: { _is_null: $isTimeArrangementFilterActive }
                          }
                        }
                        { type_time: { _in: $incTimeArrangements } }
                        {
                          _or: [
                            { type_time: { _nin: $excTimeArrangements } }
                            { type_time: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isWorkingArrangementFilterActive } }
                    {
                      _and: [
                        {
                          _not: {
                            id: { _is_null: $isWorkingArrangementFilterActive }
                          }
                        }
                        { type_place: { _in: $incWorkingArrangements } }
                        {
                          _or: [
                            { type_place: { _nin: $excWorkingArrangements } }
                            { type_place: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isSalaryFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isSalaryFilterActive } } }
                        { current_salary: { _gte: $incMinSalary } }
                        { current_salary: { _lte: $incMaxSalary } }
                        {
                          _or: [
                            { current_salary: { _lt: $excMinSalary } }
                            { current_salary: { _gt: $excMaxSalary } }
                            { current_salary: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isAgeFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isAgeFilterActive } } }
                        {
                          global_user: {
                            people_profiles: {
                              _and: [
                                { birthdate: { _gte: $incMinBirthdate } }
                                { birthdate: { _lte: $incMaxBirthdate } }
                                {
                                  _or: [
                                    { birthdate: { _lt: $excMinBirthdate } }
                                    { birthdate: { _gt: $excMaxBirthdate } }
                                    { birthdate: { _is_null: true } }
                                  ]
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isGenderFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isGenderFilterActive } } }
                        {
                          global_user: {
                            people_profiles: {
                              _and: [
                                { gender: { _in: $incGenders } }
                                {
                                  _or: [
                                    { gender: { _nin: $excGenders } }
                                    { gender: { _is_null: true } }
                                  ]
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isReligionFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isReligionFilterActive } } }
                        {
                          global_user: {
                            people_profiles: {
                              _and: [
                                { religion: { _in: $incReligionIDs } }
                                {
                                  _or: [
                                    { religion: { _nin: $excReligionIDs } }
                                    { religion: { _is_null: true } }
                                  ]
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isEducationFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isEducationFilterActive } } }
                        {
                          global_user: {
                            people_profile_educations: {
                              _and: [
                                { school: { _in: $incEducationIDs } }
                                {
                                  _or: [
                                    { school: { _nin: $excEducationIDs } }
                                    { school: { _is_null: true } }
                                  ]
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isHobbyFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isHobbyFilterActive } } }
                        {
                          global_user: {
                            people_profile_hobbies: {
                              _and: [
                                { hobby: { _in: $incHobbyIDs } }
                                {
                                  _or: [
                                    { hobby: { _nin: $excHobbyIDs } }
                                    { hobby: { _is_null: true } }
                                  ]
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isFilterTalent } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isFilterTalent } } }
                        {
                          global_user: {
                            talent_assignments: {
                              badge_classification: {
                                _in: $incBadges
                                _nin: $excBadges
                              }
                              _and: [
                                {
                                  talent_score: {
                                    _gte: $incTalentScoreFrom
                                    _lte: $incTalentScoreTo
                                  }
                                }
                                {
                                  _not: {
                                    talent_score: {
                                      _gte: $excTalentScoreFrom
                                      _lte: $excTalentScoreTo
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements: v_latest_placements(
      where: {
        _and: [
          { status: { _eq: "INACTIVE" } }
          {
            people_work_placement: {
              _and: [
                {
                  _or: [
                    { regno: { _ilike: $search } }
                    { global_user: { name: { _ilike: $search } } }
                    { company_job_profile: { title: { _ilike: $search } } }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isOrganizationLevelFilterActive } }
                    {
                      _and: [
                        {
                          _not: {
                            id: { _is_null: $isOrganizationLevelFilterActive }
                          }
                        }
                        {
                          company_organization: {
                            _and: [
                              {
                                organization_level_id: {
                                  _in: $incOrganizationLevelIDs
                                }
                              }
                              {
                                _or: [
                                  {
                                    organization_level_id: {
                                      _nin: $excOrganizationLevelIDs
                                    }
                                  }
                                  { organization_level_id: { _is_null: true } }
                                ]
                              }
                            ]
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isOrganizationUnitFilterActive } }
                    {
                      _and: [
                        {
                          _not: {
                            id: { _is_null: $isOrganizationUnitFilterActive }
                          }
                        }
                        { organization: { _in: $incOrganizationUnitIDs } }
                        {
                          _or: [
                            { organization: { _nin: $excOrganizationUnitIDs } }
                            { organization: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isJobLevelFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isJobLevelFilterActive } } }
                        { rank: { _in: $incJobLevelIDs } }
                        {
                          _or: [
                            { rank: { _nin: $excJobLevelIDs } }
                            { rank: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isJobGradeFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isJobGradeFilterActive } } }
                        {
                          company_job_profile: {
                            _and: [
                              { job_grade_id: { _in: $incJobGradeIDs } }
                              {
                                _or: [
                                  { job_grade_id: { _nin: $excJobGradeIDs } }
                                  { job_grade_id: { _is_null: true } }
                                ]
                              }
                            ]
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isJobTitleFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isJobTitleFilterActive } } }
                        { position: { _in: $incJobTitleIDs } }
                        {
                          _or: [
                            { position: { _nin: $excJobTitleIDs } }
                            { position: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isJobFamilyFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isJobFamilyFilterActive } } }
                        {
                          company_employee_position: {
                            _and: [
                              { job_mapping_id: { _in: $incJobFamilyIDs } }
                              {
                                _or: [
                                  { job_mapping_id: { _nin: $excJobFamilyIDs } }
                                  { job_mapping_id: { _is_null: true } }
                                ]
                              }
                            ]
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isPositionFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isPositionFilterActive } } }
                        { job_profile: { _in: $incPositionIDs } }
                        {
                          _or: [
                            { job_profile: { _nin: $excPositionIDs } }
                            { job_profile: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isOfficeLocationFilterActive } }
                    {
                      _and: [
                        {
                          _not: {
                            id: { _is_null: $isOfficeLocationFilterActive }
                          }
                        }
                        { office_address: { _in: $incOfficeLocationIDs } }
                        {
                          _or: [
                            { office_address: { _nin: $excOfficeLocationIDs } }
                            { office_address: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isEmployeeTypeFilterActive } }
                    {
                      _and: [
                        {
                          _not: { id: { _is_null: $isEmployeeTypeFilterActive } }
                        }
                        { type: { _in: $incEmployeeTypes } }
                        {
                          _or: [
                            { type: { _nin: $excEmployeeTypes } }
                            { type: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isTimeArrangementFilterActive } }
                    {
                      _and: [
                        {
                          _not: {
                            id: { _is_null: $isTimeArrangementFilterActive }
                          }
                        }
                        { type_time: { _in: $incTimeArrangements } }
                        {
                          _or: [
                            { type_time: { _nin: $excTimeArrangements } }
                            { type_time: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isWorkingArrangementFilterActive } }
                    {
                      _and: [
                        {
                          _not: {
                            id: { _is_null: $isWorkingArrangementFilterActive }
                          }
                        }
                        { type_place: { _in: $incWorkingArrangements } }
                        {
                          _or: [
                            { type_place: { _nin: $excWorkingArrangements } }
                            { type_place: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isSalaryFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isSalaryFilterActive } } }
                        { current_salary: { _gte: $incMinSalary } }
                        { current_salary: { _lte: $incMaxSalary } }
                        {
                          _or: [
                            { current_salary: { _lt: $excMinSalary } }
                            { current_salary: { _gt: $excMaxSalary } }
                            { current_salary: { _is_null: true } }
                          ]
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isAgeFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isAgeFilterActive } } }
                        {
                          global_user: {
                            people_profiles: {
                              _and: [
                                { birthdate: { _gte: $incMinBirthdate } }
                                { birthdate: { _lte: $incMaxBirthdate } }
                                {
                                  _or: [
                                    { birthdate: { _lt: $excMinBirthdate } }
                                    { birthdate: { _gt: $excMaxBirthdate } }
                                    { birthdate: { _is_null: true } }
                                  ]
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isGenderFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isGenderFilterActive } } }
                        {
                          global_user: {
                            people_profiles: {
                              _and: [
                                { gender: { _in: $incGenders } }
                                {
                                  _or: [
                                    { gender: { _nin: $excGenders } }
                                    { gender: { _is_null: true } }
                                  ]
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isReligionFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isReligionFilterActive } } }
                        {
                          global_user: {
                            people_profiles: {
                              _and: [
                                { religion: { _in: $incReligionIDs } }
                                {
                                  _or: [
                                    { religion: { _nin: $excReligionIDs } }
                                    { religion: { _is_null: true } }
                                  ]
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isEducationFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isEducationFilterActive } } }
                        {
                          global_user: {
                            people_profile_educations: {
                              _and: [
                                { school: { _in: $incEducationIDs } }
                                {
                                  _or: [
                                    { school: { _nin: $excEducationIDs } }
                                    { school: { _is_null: true } }
                                  ]
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isHobbyFilterActive } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isHobbyFilterActive } } }
                        {
                          global_user: {
                            people_profile_hobbies: {
                              _and: [
                                { hobby: { _in: $incHobbyIDs } }
                                {
                                  _or: [
                                    { hobby: { _nin: $excHobbyIDs } }
                                    { hobby: { _is_null: true } }
                                  ]
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
                {
                  _or: [
                    { id: { _is_null: $isFilterTalent } }
                    {
                      _and: [
                        { _not: { id: { _is_null: $isFilterTalent } } }
                        {
                          global_user: {
                            talent_assignments: {
                              badge_classification: {
                                _in: $incBadges
                                _nin: $excBadges
                              }
                              _and: [
                                {
                                  talent_score: {
                                    _gte: $incTalentScoreFrom
                                    _lte: $incTalentScoreTo
                                  }
                                }
                                {
                                  _not: {
                                    talent_score: {
                                      _gte: $excTalentScoreFrom
                                      _lte: $excTalentScoreTo
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          }
        ]
      }
      order_by: [{ people_work_placement: { global_user: { name: asc } } }]
      limit: $limit
      offset: $offset
    ) {
      id
    }
  }
`
