import gql from 'graphql-tag'

export const GET_MAIN_OFFICE = gql`
  query($company: uuid) {
    company_profiles(where: {id: {_eq: $company}}) {
      id
      company_address {
        id
        office_code
        office_name
        global_province {
          id
          name
        }
        global_city {
          id
          name
        }
        address
        address_field
        postal_code
        phone
        npwp_number
        npwp_regdate
        status
        npwp_responsible_name
        bpjs_tk_regno
        bpjs_kes_regno
        company_job_profiles_aggregate {
          aggregate {
            count
          }
        }
        company_job_profiles(
          #order_by: [{id: desc}]
          limit: 5
        ) {
          id
          title
        }
      }
    }
  }
`

export const GET_LIST_OFFICE = gql`
  query($search: String, $company: uuid, $offset: Int, $limit: Int) {
    total: company_addresses_aggregate(
      where: {
        company: {_eq: $company}
        _or: [
          {office_code: {_ilike: $search}}
          {office_name: {_ilike: $search}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    company_addresses(
      where: {
        company: {_eq: $company}
        _or: [
          {office_name: {_ilike: $search}}
          {office_code: {_ilike: $search}}
        ]
      }
      #order_by: [{id: asc}]
      offset: $offset
      limit: $limit
    ) {
      id
      office_code
      office_name
      global_province {
        id
        name
      }
      global_city {
        id
        name
      }
      address
      address_field
      postal_code
      phone
      npwp_number
      npwp_regdate
      status
      npwp_responsible_name
      bpjs_tk_regno
      bpjs_kes_regno
      company_job_profiles_aggregate {
        aggregate {
          count
        }
      }
      company_job_profiles(
        #order_by: [{id: desc}]
        limit: 5
      ) {
        id
        title
      }
    }
  }
`
