import LeavePage from './LeavePageComponent'

export const LeavePageConfig = {
  routes: [
    {
      path: '/time/leave',
      component: LeavePage,
      state: 'activity',
      exact: true,
    },
    {
      path: '/time/leave/activity',
      component: LeavePage,
      state: 'activity',
      exact: false,
    },
    {
      path: '/time/leave/calendar',
      component: LeavePage,
      state: 'calndar',
      exact: false,
    },
    {
      path: '/time/leave/leave-balance',
      component: LeavePage,
      state: 'leave-balance',
      exact: true,
    },
    {
      path: '/time/leave/leave-balance/:id',
      component: LeavePage,
      state: 'leave-balance',
      exact: false,
    },
    {
      path: '/time/leave/settings',
      component: LeavePage,
      state: 'settings',
      exact: false,
    },
    {
      path: '/time/leave/settings/:id',
      component: LeavePage,
      // state: 'setting',
      exact: false,
    },
  ],
}
