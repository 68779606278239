import React from 'react'
import StepTwoPlus from './StepTwoPlus'

const StepTwo = props => {
  const {dataContext, setDataContext, isError, checked, setListQuestions} = props
  return (
    <StepTwoPlus
      isError={isError}
      checked={checked}
      dataContext={dataContext}
      setDataContext={setDataContext}
      setListQuestions={setListQuestions}
    />
  )
}

export default StepTwo
