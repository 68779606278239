import React, {useState, useEffect} from 'react'
import helperStyle, {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  PaperContainer,
  TextHelper,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import Header from './Header'
import {withRouter} from 'react-router-dom'
import {WrapperContent} from '../../BusinessTripPageStyles'
import {Divider, TextField} from '@material-ui/core'
import Select from 'react-select'

// import ModalConfirmation from '../shared-component-regulations/ModalConfirmation'
import {ADD_COMPONENT, UPDATE_COMPONENT} from '../../../../../graphql/mutations'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import AddEditPopupFreeText from '../../../../shared-components/popup/AddEditPopupFreeText'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'
import {GET_LIST_TYPE_TRIP} from '../../../../../graphql/queries'
import {Loading} from 'mdi-material-ui'
import {COMPANY_ID} from '../../../../../utils/globals'
import {customSelect} from '../../../../../components/select/customSelect'

const INITIAL_FORM_DATA = {
  name: '',
  type: null,
  description: '',
}

const AddEditComponent = props => {
  const {data, type, history} = props
  const [typeCom, setType] = useState([])

  const classes = helperStyle()

  const {enqueueSnackbar} = useSnackbar()
  const [open, setOpen] = useState({
    confirmation: false,
    cancel: false,
  })
  const [allData, setAllData] = useState(INITIAL_FORM_DATA)
  const [isError, setIsError] = useState(false)

  const [addComponent] = useMutation(ADD_COMPONENT)
  const [updateComponent] = useMutation(UPDATE_COMPONENT)

  const {data: dataType, error} = useQuery(GET_LIST_TYPE_TRIP, {
    wlb_skipPatch: true,
  })

  useEffect(() => {
    if (type === 'Edit') {
      setAllData({
        name: data && data.name,
        type:
          data && data.business_trip_component_type
            ? data.business_trip_component_type.id
            : null,
        description: data && data.description,
      })

      setType({
        label:
          data && data.business_trip_component_type
            ? data.business_trip_component_type.name
            : null,
        value:
          data && data.business_trip_component_type
            ? data.business_trip_component_type.id
            : null,
      })
    }
  }, data)

  if (dataType === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const isNameEmpty = !allData.name
  const isNameOverLimit = allData.name.length > 50
  const isDescriptionEmpty = !allData.description
  const isTypeEmpty = !allData.type

  const handleAlertCancel = status => {
    open.cancel
      ? setOpen({...open, cancel: false})
      : setOpen({...open, cancel: true})

    if (
      (open.cancel && status !== 'notNow' && type === 'Add') ||
      (open.cancel && status !== 'cancel' && type === 'Edit')
    ) {
      history.push({
        pathname: '/business/trip/settings',
        state: {active: 'settings', searchable: true, activeTab: 'Component'},
      })
    }
  }

  const handleAlertConfirm = notif => {
    if (isNameEmpty || isNameOverLimit || isDescriptionEmpty || isTypeEmpty) {
      setIsError(true)
      return
    }

    if (notif === 'confirm') {
      setOpen({...open, confirmation: !open.confirmation})
    }
  }

  const handleMutationComponent = () => {
    if (type === 'Add') {
      allData.company = COMPANY_ID
      const object = allData
      addComponent({variables: {object}})
        .then(() => {
          enqueueSnackbar('Component Added', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Added Component failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else {
      const changes = allData
      updateComponent({
        variables: {
          id: data.id,
          changes,
        },
      })
        .then(() => {
          enqueueSnackbar('Component Updated', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Update Component failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }

    history.push({
      pathname: '/business/trip/settings',
      state: {active: 'settings', searchable: true, loadQuery: true},
    })
  }

  const dataComponentType = dataType.business_trip_component_type.map(res => ({
    value: res.id,
    label: res.name,
  }))

  return (
    <>
      <PaperContainer style={{boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)'}}>
        <Header
          onClickSubmit={() => handleAlertConfirm('confirm')}
          onClickCancel={handleAlertCancel}
          {...props}
        />
        <WrapperContent style={{minHeight: 650, padding: 24}}>
          <FormChildContainer>
            <TitleNumber
              id="parent"
              style={{
                color: '#1c5269',
                paddingBottom: '24px',
              }}
            >
              Component Information
            </TitleNumber>
            <FormChildTitle>
              <TitleNumber id="parent">1.</TitleNumber>
              <TitleName>Budget Component Name*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                id="office-code"
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                placeholder={'Add Budget Component Name'}
                value={allData.name}
                fullWidth
                className={classes.helperMarginLeft0}
                margin="normal"
                error={isNameOverLimit || (isError && isNameEmpty)}
                helperText={
                  isNameOverLimit
                    ? 'The maximum number of characters is 50 characters'
                    : isError && isNameEmpty
                    ? 'This field is required'
                    : ''
                }
                onChange={event => {
                  setAllData({
                    ...allData,
                    name: event.target.value,
                  })
                }}
                variant="outlined"
              />
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber id="description">2.</TitleNumber>
              <TitleName>Description*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                id="description"
                placeholder={'Add Description'}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                value={allData.description}
                onChange={event => {
                  setAllData({
                    ...allData,
                    description: event.target.value,
                  })
                }}
                error={isError && isDescriptionEmpty}
                helperText={
                  isError && isDescriptionEmpty && 'This field is required'
                }
                fullWidth
                size="small"
                margin="normal"
                variant="outlined"
                multiline
                rows="4"
              />
            </FormChildInput>
          </FormChildContainer>
          <Divider></Divider>
          <TitleNumber
            id="parent"
            style={{
              color: '#1c5269',
              padding: '24px 0px',
            }}
          >
            Component Type
          </TitleNumber>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber id="province">3.</TitleNumber>
              <TitleName>Budget Components Type*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <Select
                className={classes.marginTop15}
                id="parent"
                styles={customSelect({
                  error: allData && !allData.type && isError,
                })}
                options={dataComponentType}
                value={typeCom}
                onChange={event => {
                  setType(event)
                  setAllData({
                    ...allData,
                    type: event.value,
                  })
                }}
                placeholder="Choose Budget Component Type"
                // styles={{
                //   menu: provided => ({...provided, zIndex: 9999}),
                // }}
                required
              />
              <TextHelper className={classes.marginLeft0}>
                {isError && isTypeEmpty ? 'This field is required' : ''}
              </TextHelper>
            </FormChildInput>
          </FormChildContainer>
        </WrapperContent>
      </PaperContainer>

      <AddEditPopupFreeText
        open={open.confirmation}
        handleClose={() => handleAlertConfirm('confirm')}
        button={type !== 'Edit' ? 'Add' : 'Save'}
        body={
          type !== 'Edit'
            ? `Are you sure you want to add a component?`
            : 'Are you sure you want to save changes?'
        }
        header={type !== 'Edit' ? `Add New Component?` : 'Save Changes?'}
        headerClose={false}
        mutation={handleMutationComponent}
      />

      <AlertDialog
        open={open.cancel}
        handleClose={handleAlertCancel}
        feature={
          type === 'Edit' ? `Discard Changes?` : `Cancel to ${type} Component?`
        }
        message={
          type === 'Edit'
            ? `Are you sure you want to discard changes?`
            : `You have unsaved changes. Are you sure want to cancel ${type.toLowerCase()} new  component?`
        }
        type={type === 'Edit' ? 'Discard' : 'Confirm'}
        status={type === 'Edit' ? 'cancel' : 'notNow'}
        positiveTitileButton={type === 'Edit' ? undefined : 'Yes, Cancel'}
      />

      {/* <ModalConfirmation
        type="confirmation"
        open={open.confirmation}
        model={model}
        typeAction={type}
        onClose={() => {
          setOpen({...open, confirmation: false})
        }}
        onConfirm={() => {
          setOpen({...open, confirmation: false})
          props.history.goBack()
        }}
      />
      <ModalConfirmation
        type="cancel"
        open={open.cancel}
        model={model}
        typeAction={type}
        onClose={() => {
          setOpen({...open, cancel: false})
        }}
        onConfirm={() => {
          setOpen({...open, cancel: false})
          props.history.goBack()
        }}
      /> */}
    </>
  )
}

export default withRouter(AddEditComponent)
