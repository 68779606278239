import React, {useEffect} from 'react'
import {useMutation, useQuery} from '@apollo/react-hooks'
import moment from 'moment'
// import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'
// import DateFnsUtils from '@date-io/date-fns'
import MaterialTable from 'material-table'
import {useSnackbar} from 'notistack'
import {
  Button,
  // CircularProgress
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import helperStyle, {
  BigTextBlackBold,
  TableOptions,
  TextBlueBold,
  pageSizeOption,
} from '../PayrollPageStyles'
import {
  PaperContainer,
  EnhancedToolbar,
  RootContainer,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import RequestWrapper from '../../../shared-components/layouts/RequestWrapper'
import GenerateManualPopup from '../../../shared-components/payroll/popup/GenerateManualPopup'
import {convertToRoundedRupiah} from '../../../../utils/helpers'
import {
  GET_PAYROLL_PROCESS,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../../../graphql/queries'
import {
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
} from '../../../../graphql/mutations'
import {COMPANY_ID} from '../../../../utils/globals'
// import {DatePickerWrap} from '../../../../components/card/CardStyles'
import {GQL_FINANCE_OPTIONS} from '../utils'

const monthName = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mei',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const steps = [
  'Payroll Data',
  'Calculation',
  'Approval',
  'Review',
  'Salary Slip',
  'PAID',
]

const ListPayrollProcess = props => {
  const {period, isDisabled} = props
  const classes = helperStyle()

  const {enqueueSnackbar} = useSnackbar()
  const [showModalGenerateManual, setShowModalGenerateManual] = React.useState(
    false
  )
  const [currentPage, setCurrentPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(pageSizeOption[0])
  const [year, setYear] = React.useState(null)
  const {data: payrollProcessList, loading, error, refetch} = useQuery(
    GET_PAYROLL_PROCESS,
    {
      wlb_skipPatch: true,
      variables: {
        company: COMPANY_ID,
        year: Number(moment(year).format('YYYY')),
        offset: currentPage * rowsPerPage,
        limit: rowsPerPage,
      },
      ...GQL_FINANCE_OPTIONS,
      fetchPolicy: 'network-only',
    }
  )

  const [mutationExport] = useMutation(EXPORT_MUTATION)

  const [closeBaloonExport] = useMutation(CLOSE_BALOON_EXPORT)

  const {data: getExportBaloon} = useQuery(GET_STATUS_EXPORT_BALOON, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'finance_payroll_process',
    },
  })

  useQuery(GET_EXPORT_DISABLED_BUTTON, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'finance_payroll_process',
    },
  })

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  const handleGenerateData = period => {
    if (period?.period_month) {
      mutationExport({
        variables: {
          table: 'finance_payroll_process',
          filter: period,
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  if (getExportBaloon) {
    if (
      getExportBaloon.company_export_data_logs.length > 0 &&
      getExportBaloon.company_export_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  useEffect(() => {
    setYear(moment(period || new Date()))
  }, [period])

  useEffect(() => {
    setCurrentPage(0)
  }, [year, rowsPerPage])

  const isDisabledGenerateData = payrollProcessList?.v_finance_payrolls?.find(
    data => data?.process_step < 5
  )

  return (
    <RootContainer style={{width: '82%'}}>
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={closeBaloonExportMutation}
        feature="Payroll Process"
        isPayroll
        availableToSeeProblem
        availableToDownloadExcel={false}
      />
      <PaperContainer>
        <EnhancedToolbar>
          <BigTextBlackBold>Payroll Process</BigTextBlackBold>
          {/* <DatePickerWrap>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              views={['year']}
              className="date-picker-dialog"
              KeyboardButtonProps={{'aria-label': 'change date'}}
              inputVariant="outlined"
              format="yyyy"
              placeholder="Choose Period"
              value={year}
              color={'primary'}
              onChange={value => {
                setYear(moment(value).format('YYYY'))
              }}
            />
          </MuiPickersUtilsProvider>
          </DatePickerWrap> */}
          <GenerateTooltip
            title={
              isDisabled || isDisabledGenerateData
                ? `You can only generate data after the status of all period is PAID`
                : ``
            }
            placement="left"
          >
            <span>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={() => setShowModalGenerateManual(true)}
                disabled={isDisabled || isDisabledGenerateData}
              >
                Generate Data
              </Button>
            </span>
          </GenerateTooltip>
        </EnhancedToolbar>

        <RequestWrapper
          loading={loading && !payrollProcessList?.v_finance_payrolls}
          error={error}
          isEmpty={
            !loading && payrollProcessList?.v_finance_payrolls?.length === 0
          }
          emptyMessage="You can set your employee and customize your payroll method on settings"
        >
          <MaterialTable
            data={payrollProcessList?.v_finance_payrolls}
            options={{...TableOptions, paging: false}}
            page={currentPage}
            onChangePage={page => {
              setCurrentPage(page)
            }}
            onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
            columns={[
              {
                title: 'Period',
                field: 'periode',
                customSort: (a, b) => {
                  return (
                    moment().set({year: a.year, month: a.month}) -
                    moment().set({year: b.year, month: b.month})
                  )
                },
                render: rowData =>
                  `${monthName[rowData.month - 1]} ${rowData.year}`,
              },
              {
                title: 'Basic Salary',
                field: 'total_salary',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.total_salary - b.total_salary
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.total_salary, false),
              },
              {
                title: 'Earning',
                field: 'addition',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.addition - b.addition
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.addition, false),
              },
              {
                title: 'Deduction',
                field: 'deduction',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.deduction - b.deduction
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.deduction, false),
              },
              {
                title: 'Total',
                field: 'total_final_salary',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.total_final_salary - b.total_final_salary
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.total_final_salary, false),
              },
              // {
              //   title: 'Status',
              //   field: 'status',
              //   render: rowData => (
              //     <Typography
              //       style={
              //         rowData.status === 'paid'
              //           ? {color: 'green'}
              //           : {color: 'red'}
              //       }
              //     >
              //       {rowData.status}
              //     </Typography>
              //   ),
              // },
              {
                title: 'Status',
                field: 'process_step',
                headerStyle: {minWidth: 120},
                cellStyle: {minWidth: 120},
                customSort: (a, b) => {
                  return a.process_step - b.process_step
                },
                render: rowData =>
                  typeof rowData.process_step === 'number'
                    ? steps[rowData.process_step]
                    : '-',
              },
              {
                title: 'Action',
                field: 'action',
                headerStyle: {minWidth: 100},
                cellStyle: {minWidth: 100},
                sorting: false,
                render: rowData => {
                  const search = `?year=${rowData.year}&month=${rowData.month}`
                  if (rowData.process_step === 5) {
                    return (
                      <>
                        <Link
                          to={{
                            pathname: '/financial/payroll/payroll-process/step',
                            search: search,
                            state: {
                              active: 'step-payroll-process',
                              status: 'Paid',
                              showPeriod: false,
                              showMonthYearPeriod: true,
                              searchable: true,
                              filter: true,
                              // id: rowData.id,
                              // jobName: rowData.organizationUnit,
                            },
                          }}
                          className={classes.link}
                        >
                          <TextBlueBold>Details</TextBlueBold>
                        </Link>
                      </>
                    )
                  } else {
                    return (
                      <>
                        <Link
                          to={{
                            pathname: '/financial/payroll/payroll-process/step',
                            search: search,
                            state: {
                              active: 'step-payroll-process',
                              showPeriod: false,
                              showMonthYearPeriod: true,
                              searchable: true,
                              filter: true,
                              // id: rowData.id,
                              // jobName: rowData.organizationUnit,
                            },
                          }}
                          className={classes.link}
                        >
                          <TextBlueBold>Process</TextBlueBold>
                        </Link>
                      </>
                    )
                  }
                },
              },
            ]}
          />
        </RequestWrapper>

        {!isDisabled && showModalGenerateManual && (
          <GenerateManualPopup
            mutation={handleGenerateData}
            onClose={() => setShowModalGenerateManual(false)}
            open={showModalGenerateManual}
            title={'Payroll'}
          />
        )}
      </PaperContainer>
    </RootContainer>
  )
}

export default ListPayrollProcess
