import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import {
  Typography,
  Dialog,
  TextField,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  FormLabel,
} from '@material-ui/core'
import SortableTree from 'react-sortable-tree'
import 'react-sortable-tree/style.css'

export const PayrollContainer = styled.div`
  width: 100%;

  @media print {
    .hide-on-print,
    .hide-on-print * {
      display: none !important;
    }
  }
`

export const DetailPayrollContainer = styled.div``

export const PayrollDetailWrapper = styled.div`
  padding: 0 50px;
  .MuiOutlinedInput-inputAdornedStart {
    text-align: right;
  }
`

export const BlueLink = styled(Link)`
  color: #007fb2;
  text-decoration: none;
  margin: 18px;
`

export const RedButton = styled(Button)`
  background: #f44336;
  text-decoration: none;
  &:hover {
    background: red;
  }
`

export const BigTextBlackBold = styled.span`
  font-size: 1.25rem;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #000000;
  text-transform: capitalize;
`

export const DialogWrapper = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 705px;
    max-width: 705px;
  }

  .MuiDialogTitle-root {
    background-color: #014a62;
  }

  .MuiDialogContent-root {
    overflow-y: unset;
  }

  .MuiTypography-h6 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white !important;
  }

  .dialog-assignee-list {
    margin-top: 11px;
    display: grid;
    grid-template-columns: repeat(2, 49%);
    grid-column-gap: 14px;
    grid-row-gap: 14.6px;
  }

  .dialog-assignee-list-wrapper {
    max-height: 380px;
    min-height: 380px;
    overflow-y: auto;
  }

  .not-found {
    text-align: center;
    width: 100%;
  }
`

export const MediumTextBlackBold = styled(Typography)`
  font-size: 15px;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #000000;
`

export const MediumTextGrey = styled(Typography)`
  font-size: 15px;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #a9a8a8;
`

export const TextBlackBold = styled(Typography)`
  font-size: 13px;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #000000;
`

export const TextBlack = styled(Typography)`
  font-size: 0.875rem;
  font-family: ${props => props.theme.font};
  font-weight: 400;
  color: #000000;
`

export const TextSpanGrey = styled.span`
  font-size: 0.875rem;
  font-family: ${props => props.theme.font};
  font-weight: 400;
  color: #a9a8a8;
`

export const TextGrey = styled(Typography)`
  font-size: 0.875rem;
  font-family: ${props => props.theme.font};
  font-weight: 400;
  color: #a9a8a8;
`

export const TextGreyBold = styled(Typography)`
  font-size: 13px;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #a9a8a8;

  ul {
    padding-left: 15px;
    margin: 0px;
  }

  ol {
    padding-left: 15px;
    margin: 0px;
  }

  p {
    margin: 0px;
  }
`

export const TextBlueBold = styled(Typography)`
  font-size: 13px;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #039be5;
  cursor: pointer;
`

export const BigRedText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #ef4d5e;
`

export const RedText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #ef4d5e;
`

export const TextDarkBlue = styled.span`
  font-family: ${props => props.theme.font};
  font-size: 0.9rem;
  font-weight: 600;
  color: #014a62;
`

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font};
  justify-content: space-between;
`

export const FlexStart = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font};
  justify-content: flex-start;
`

export const WrapperContent = styled.div``

export const StyledTree = styled(SortableTree)`
  .rst__rowLabel {
    padding: 0px;
    width: 100%;
  }
`

export const LitleRedText = styled(Typography)`
  font-size: 12px;
  color: #ef4d5e;
  cursor: pointer;
`

export const LitleLightBlueText = styled(Typography)`
  font-size: 12px;
  color: #039be5;
  cursor: pointer;
`

export const LitleGreyText = styled(Typography)`
  font-size: 13px;
  color: #a9a8a8;
`

export const BigTextWhite = styled.span`
  font-family: ${props => props.theme.font};
  font-size: 17px;
  color: #ffffff;
`

export const RpContainer = styled.div`
  border-right: 1px solid #a9a8a8;
  color: #000000;
  padding: 10px;
`

export const StyledDialog = styled(Dialog)`
  font-family: ${props => props.theme.font};
`

export const ModalHeader = styled.div`
  padding: 1.6rem 2rem;
  background-color: #014a62;
  color: #ffffff;
`
export const ModalHeaderWhite = styled.div`
  padding: 1.6rem 2rem;
  background-color: #eff2f4;
`
export const ModalContent = styled.div`
  padding: 1.6rem 2rem;
`

export const WhiteButton = styled(Button)`
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgb(1, 74, 98);
`

export const WhiteButtonNoBorder = styled(Button)`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.16);
`
export const pageSizeOption = [25, 50, 100]

export const TableOptions = {
  search: false,
  selection: false,
  sorting: true,
  showTitle: false,
  toolbar: false,
  headerStyle: {position: 'sticky', top: 0, fontWeight: 'bold'},
  maxBodyHeight: '500px',
  style: {
    width: '140%',
    fontWeight: 700,
    lineHeight: 1.43,
    marginTop: `-100px`,
    color: '#a9a8a8',
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
  pageSize: pageSizeOption[0],
  pageSizeOptions: pageSizeOption,
  emptyRowsWhenPaging: false,
}

export const TitleModalWhite = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
`

const helperStyle = makeStyles(theme => ({
  materialTabelWidth: {
    '& > .MuiTable-root': {
      width: '140%',
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#e0e0e0',
    borderStyle: 'solid',
  },
  tableBorder: {
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderStyle: 'solid',
  },
  tableHeader: {
    backgroundColor: '#f7f8f9',
  },
  root: {
    marginLeft: '0px',
  },
  rootAvatar: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > .MuiAvatar-root': {
      width: '60px',
      height: '60px',
    },
  },
  marginLeft10: {
    marginLeft: '10px',
  },
  marginLeft50: {
    marginLeft: '50px',
  },
  marginLeft30: {
    marginLeft: '30px',
  },
  margin20: {
    margin: '20px',
  },
  margin50: {
    margin: '50px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: '0 0 auto',
    marginLeft: 10,
  },
  spacer: {
    flex: '1 1 25%',
  },
  link: {
    textDecoration: 'none',
    color: '#007fb2',
  },
  linkDarkBlue: {
    textDecoration: 'none',
    color: '#014a62',
  },
  linkLightBlue: {
    textDecoration: 'none',
    color: '#039be5',
    cursor: 'pointer',
  },
  linkRed: {
    textDecoration: 'none',
    color: '#ef4d5e',
  },
  padding0: {
    padding: '0px !important',
  },
  padding20: {
    padding: '20px',
  },
  paddingTable: {
    padding: '50px 50px 30px 50px',
  },
  paddingLeft0: {
    paddingLeft: '0px',
  },
  paddingRight0: {
    paddingRight: '0px !important',
  },
  cursorNone: {
    cursor: 'pointer',
  },
  rootTree: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  margin0: {
    margin: '0px !important',
  },
  marginTopBot20: {
    margin: '20px auto',
  },
  marginTopBot35: {
    margin: '35px auto',
  },
  marginBot10: {
    marginBottom: '10px',
  },
  marginBot30: {
    marginBottom: '30px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginTop20: {
    marginTop: '20px !important',
  },
  marginLeftRight10: {
    margin: 'auto 10px',
  },
  marginRP: {
    margin: '0px 0px 0px 10px !important',
  },
  buttonText: {
    textDecoration: 'none',
    color: '#fff',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
  },
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  outlinedInput: {
    padding: '10px 14px',
  },
  iconBorder: {
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '30px',
    height: '25px',
    margin: '10px auto',
    padding: '5px',
    display: 'inline-block',
    borderRadius: '5px',
    border: 'solid 1px #a9a8a8',
  },
  iconAdd: {
    fontSize: '24px !important',
    color: '#014a62',
  },
  iconDelete: {
    fontSize: '15px !important',
    color: '#014a62',
  },
  iconBigBorder: {
    width: '67px',
    height: '67px',
    padding: '5px',
    margin: '10px',
    borderRadius: '5px',
    border: 'solid 1px #a9a8a8',
    cursor: 'pointer',
  },
  width100P: {
    width: '100% !important',
  },
  width90P: {
    width: '90% !important',
  },
  width80P: {
    width: '80% !important',
  },
  width70P: {
    width: '70% !important',
  },
  width50P: {
    width: '50% !important',
  },
  width40P: {
    width: '40% !important',
  },
  width60P: {
    width: '60% !important',
  },
  width35P: {
    width: '35% !important',
  },
  width30P: {
    width: '30% !important',
  },
  width20P: {
    width: '20% !important',
  },
  width15: {
    width: '20px',
  },
  width200: {
    width: '200px',
  },
  buttonSpacer: {marginRight: 12},
  flexWrap: {
    flexWrap: 'wrap',
  },
  thinTextfield: {
    padding: '12px 14px',
  },
  verticalAlignBot: {
    verticalAlign: 'bottom',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  backgroundColorRed: {
    backgroundColor: '#ef4d5e',
  },
  colorRed: {
    color: '#ef4d5e',
    '&$checked': {
      color: '#ef4d5e',
    },
  },
  height600px: {
    height: '600px',
  },
  maxHeight25: {
    maxHeight: '25px',
  },
  heighMuiInputt15: {
    '& > .MuiOutlinedInput-root': {
      height: '15px !important',
    },
  },
  btnDel: {
    backgroundColor: '#ef4d5e',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ef4d5e',
    },
  },

  backButton: {
    marginRight: theme.spacing(1),
    color: '#a9a8a8',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    color: '#039be5 !important',
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  labelActive: {
    color: '#039be5 !important',
  },
  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  maxHeight400: {
    minHeight: '400px',
    maxHeight: '400px',
  },
  bgColorGrey: {
    backgroundColor: '#eff2f4',
  },
  notViewScroll: {
    '&::-webkit-scrollbar': {
      width: '0px',
    },
  },
  helperMarginLeft0: {
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
    },
  },
  overflowX: {
    overflowX: 'auto',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  logo: {
    width: '50px',
    height: '50px',
  },
  alignSelfStart: {
    alignSelf: 'start',
  },
  buttonImportSalary: {
    padding: '10px 30px',
  },
  maxWidthModal: {
    maxWidth: 851,
  },
  buttonReportDownload: {
    maxWidth: '198px',
    margin: 'auto',
    padding: '14px 58px',
    borderRadius: '5px',
  },
  textBlackBold: {
    fontSize: '13px',
    fontWeight: '600',
    color: '#000000',
  },
  textBold: {
    fontSize: '13px',
    fontWeight: '600',
  },
  customDatePicker: {
    width: '11rem',
    marginRight: 12,
    '& > .MuiOutlinedInput-root': {
      height: '39px !important',
    },
  },
  cardBorder: {
    border: '2px solid rgb(169,168,168,0.2)',
  },
  errorChips: {
    backgroundColor: 'rgb(244, 67, 54)',
    color: 'white',
  },
}))

export const SelectStyled = styled(Select)`
  margin: 10px 0px;
`

export const ExpansionPanelStyle = styled(Accordion)`
  background-color: #f7f8f9;
  box-shadow: none;
  margin-top: 20px;
  padding: 0px;
  &::before {
    opacity: 0;
  }
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`

export const ListStyle = styled(List)`
  height: 426px;
  overflow-y: auto;
`

export const ExpansionPanelSummaryStyle = styled(AccordionSummary)`
  background-color: #f7f8f9;
  box-shadow: none;
  padding: 0px 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  height: 58px;
  font-size: 12px;

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const ExpansionPanelDetailStyle = styled(AccordionDetails)`
  box-shadow: none;
  background-color: #ffffff;
  margin: 20px;
  display: flex;
  flex-direction: column;
`

export const ItemMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;

  .item {
    padding: 10px 26px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    color: #014a62;
  }

  .item-delete {
    color: #f16271;
  }
`

export default helperStyle

export const FieldSalary = styled(TextField)`
  .MuiInputBase-input {
    padding: 10px 14px;
    text-align: right;
  }
`
export const TableEmployeeStyled = styled.div`
  font-size: 14px;

  .Component-horizontalScrollContainer-106 table.MuiTable-root {
    width: 140%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
  }

  thead {
    display: table-header-group;
  }

  tbody {
    display: table-row-group;
  }
  th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    text-align: left;
  }

  .MuiPaper-rounded > div:first-child {
    /* max-width: 900px; */
    overflow: auto;
  }

  .avatar-wrapper {
    display: inline-block;
  }

  .action {
    text-align: center;
  }

  .head {
    height: 50px;
  }

  td {
    padding: 20px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }

  .action {
    cursor: pointer;
    text-align: center;

    .wrapper-action {
      span.red {
        margin-right: 24px;
      }
    }

    .blue {
      color: #039be5;
    }
    .red {
      color: #ef4d5e;
    }
  }
`
export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .info {
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    .name {
      font-size: 14px;
      margin-bottom: 4px;
      margin-top: 0px;
      color: #014a62;
      font-weight: 600;
    }

    .id {
      font-size: 12px;
      margin-bottom: 4px;
    }

    .position {
      font-size: 12px;
      color: #a9a8a8;
    }
  }
`
export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 41px;

  .title {
    font-size: 21px;
    margin-bottom: 42px;
  }

  .table-detail {
    font-size: 12px;

    .body {
      tr {
        td {
          padding: 8px 0px;
        }

        td:nth-child(odd) {
          font-weight: bold;
        }
      }
    }
  }

  .avatar {
    text-align: center;
    width: 70px;

    div {
      margin: auto;
    }
  }

  .id,
  .name {
    width: 150px;
  }
  .position {
    width: 300px;
  }
  .salary {
    width: 140px;
    text-align: right;
  }
  .action {
    text-align: center;
    width: 76px;
  }
  .table-review {
    font-size: 13px;
    margin-top: 32px;
    border-spacing: 0px;
    border: 1px solid #eff2f4;

    .br {
      border-right: 1px solid #eff2f4;
    }

    td {
      width: 446px;
      padding: 14px 16px;
    }
    thead {
      background-color: #eff2f4;
    }

    .right {
      text-align: right;
    }
  }

  @media print {
    .hide-on-print,
    .hide-on-print * {
      display: none !important;
    }
  }
`

export const ReportFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 32px 0px;
  font-size: 13px;

  .right,
  .left {
    .wrap-item {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;

      .name {
        width: 180px;
      }

      .value {
        max-width: 150px;
      }

      .bold {
        font-weight: bold;
      }
    }
  }
`

export const WrapperModalConfirmation = styled.div`
  display: flex;
  flex-direction: column;

  .header-modal-confirmation {
    padding: 24px 20px;
    color: #014a62;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }

  .body-modal-confirmation {
    padding: 11px 20px;
    display: flex;
    flex-direction: column;

    .content-body-modal-confirmation {
      border-radius: 5px;
      background-color: #eff2f4;
      padding: 12px 20px;

      .title-content-modal-confirmation {
        font-size: 12px;
        margin-bottom: 8px;
      }

      ul {
        padding-left: 14px;
        li {
          font-size: 10px;
          color: #000000;
          margin-bottom: 6px;
        }
      }

      .see-all-modal-confirmation {
        font-size: 10px;
        color: #039be5;
        cursor: pointer;
      }
    }

    .message {
      font-size: 12px;
      color: #000000;
      margin-top: 11px;
      line-height: 1.5;

      .dynamic-name {
        color: #014a62;
      }
    }
  }

  .action-wrapper-modal-confirmation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px 20px;
  }
`

export const WrapperModalGeneral = styled('div')`
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    background-color: #014a62;
    padding: 12px 30px;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #fff;
      font-size: 20px;
      font-weight: normal;
    }
    .wrap-close {
      .icon-close {
        color: #fff;
      }
    }
  }

  .body {
    margin: 25px 30px;

    .wrap-date-picker {
      margin-top: 10px;
      width: 100%;
    }

    .text-alert-notes {
      margin-left: 2px;
      color: #ffa000;
    }
  }

  .action-wrapper-modal-confirmation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 30px 20px;
  }
`

export const CaluseItemBody = styled(AccordionDetails)`
  box-shadow: none;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
`

export const CaluseItemHead = styled(AccordionSummary)`
  background-color: #fff;
  box-shadow: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
  }
`

export const ItemClause = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  background-color: #fff;
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;
`

export const ClauseItemWrapper = styled(Accordion)`
  background-color: #fff;
  box-shadow: none;
  padding: 0px;
  &::before {
    opacity: 0;
  }
  border-radius: 5px;
  box-shadow: none;
  width: 100%;
`

export const DetailComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & > div {
    width: 50%;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    text-transform: capitalize;
  }
`

export const AsigneeComponentWrapper = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(4, 23%);
    grid-row-gap: 12px;
    grid-column-gap: 10px;
  }

  li {
    font-size: 12px;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 12px;
    padding-right: 10px;
  }

  li::marker {
    color: #039be5;
  }

  .view-more {
    font-size: 12px;
    line-height: 1.5;
    text-transform: capitalize;
    color: #039be5;
    font-weight: 600;
    cursor: pointer;
  }
`

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;

  .MuiFormControl-root {
    width: 90%;
    margin-right: 12px;
  }

  .MuiOutlinedInput-input {
    padding: 12px 15px;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: unset;
  }

  .outlined {
    border-radius: 5px;
    border: solid 1px #c4c4c4;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`

export const UserItemWrapper = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  padding: 19px;

  .MuiAvatar-root {
    margin-right: 20px;
  }

  .user-name {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 5px;
  }

  .user-id {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #a9a8a8;
    margin-bottom: 5px;
  }

  .user-desc {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
`

export const CenterWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`

export const Badge = styled.div`
  background-color: #a9a8a8;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font: normal normal 600 12px/18px;
  text-align: center;
`

export const BadgeWrapper = styled.div`
  display: inline-block;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: left;
  color: #a9a8a8;
  margin-left: 20px;

  & > div:first-child {
    width: 80px;
    display: inline-block;
    margin-right: 20px;
  }
`

export const WaitingForText = styled.div`
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: left;
  color: #a9a8a8;
  .component_waiting_step_text > span {
    color: #3b86ff;
  }
`

export const TaxOfficeWrapper = styled.div`
  width: 395px;
  height: 122px;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  display: flex;

  .desciription {
    h3:first-child {
      margin-bottom: 7px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.29;
      letter-spacing: normal;
      text-align: left;
      color: #014a62;
    }

    h3:nth-child(2) {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.29;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }

    p {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #a9a8a8;
    }
  }

  div:first-child {
    margin-right: 16px;

    img {
      width: 45px;
      margin-top: 16px;
    }
  }
`

export const TaxComponentsWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 24px;
  grid-column-gap: 24px;
`

export const FormWarningWrapper = styled.div`
  .MuiGrid-item:nth-child(2) {
    svg {
      color: #014a62;
      cursor: pointer;
    }
  }

  ${({fullWidth}) =>
    fullWidth &&
    `
    .MuiGrid-spacing-xs-1 {

      width: calc(120% + 8px);

      & > div:first-child {
        width: 93%;
      }
    }
    `}
`

export const PostponedItem = styled.div`
  display: inline-block;

  div {
    width: 28px;
    height: 28px;
    border-radius: 90px;
    border: 2px solid #ef4d5e;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 6px;
  }
`

export const PopOverWrapper = styled.div`
  position: sticky;
  width: 202px;
  min-height: 77px;
  background: #0000002e;
  z-index: 10;
  text-align: left;
  padding: 13px 18px;
  font-size: 10px;
`

export const ActionLink = styled(Typography)`
  color: #039be5;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
`

export const StyledFormLabel = styled(FormLabel)`
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 20px;
`

export const AntTabs = withStyles({
  root: {
    backgroundColor: '#EFF2F4',
    color: '#000',
  },
  indicator: {
    backgroundColor: '#1890ff',
    height: 2,
  },
})(Tabs)

export const StyledTab = withStyles({
  root: {
    color: '#000',
    fontWeight: '500',
    fontSize: 14,
    opacity: 1,
  },
})(Tab)
