import React, {useState} from 'react'
import {
  PaperContainer,
  FormContainer,
  AddEditTitle,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  FormChildButton,
} from '../../../../GlobalStyles'
import {
  Divider,
  IconButton,
  Toolbar,
  TextField,
  Button,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Link, withRouter} from 'react-router-dom'
import {useMutation} from '@apollo/react-hooks'
import {ADD_GROUP_QUESTION_BANK} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'

function AddGroupQuestionBank(props) {
  const {enqueueSnackbar} = useSnackbar()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }
  const [mutationAddQuestion] = useMutation(ADD_GROUP_QUESTION_BANK)
  const addGroupQuestion = () => {
    if (name === '' || description === '') {
      const ids = document.getElementById('top')
      ids.scrollIntoView({behavior: 'smooth'})
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const data = {
        name,
        description,
      }

      mutationAddQuestion({variables: {data}})
        .then(() => {
          enqueueSnackbar('Success add data', {variant: 'success'})
          props.history.push({
            pathname: `/survey/multirater-nps/question-bank`,
            state: {active: 'question-bank'},
          })
        })
        .catch(() => {
          enqueueSnackbar('Add data error, please try again', {
            variant: 'error',
          })
        })
    }
  }
  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link
          to={{
            pathname: `/survey/multirater-nps/question-bank`,
            state: {active: 'question-bank', searchable: true},
          }}
        >
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <AddEditTitle variant="h6">Tambahkan Grup Umpan Balik</AddEditTitle>
      </Toolbar>
      <Divider></Divider>
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Nama *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                setName(event.target.value)
              }}
              variant="outlined"
              value={name}
              error={name.length < 1 && isError}
              helperText={
                name.length < 1 && isError ? 'Bagian ini harus diisi' : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Deskripsi *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                setDescription(event.target.value)
              }}
              variant="outlined"
              value={description}
              error={description.length < 1 && isError}
              helperText={
                description.length < 1 && isError
                  ? 'Bagian ini harus diisi'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <FormChildButton>
        <Button
          color="default"
          size="large"
          component={Link}
          to={{
            pathname: `/survey/multirater-nps/question-bank`,
            state: {active: 'question-bank'},
          }}
          style={{marginRight: 12}}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          size="large"
        >
          Selanjutnya
        </Button>
      </FormChildButton>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        mutation={addGroupQuestion}
        header="Tambahkan Grup Umpan Balik?"
        body="Apakah anda yakin untuk menambah grup umpan balik?"
        button="Tambah"
        buttonClose="Batal"
      />
    </PaperContainer>
  )
}

export default withRouter(AddGroupQuestionBank)
