import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {useMutation} from '@apollo/react-hooks'
import moment from 'moment'

import PaymentPlanForm from '../form/PaymentPlanForm'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'

import {USER_ID} from '../../../../../utils/globals'
import {ADD_LOAN_ACTIVITY} from '../../../../../graphql/mutations'
import {GQL_FINANCE_OPTIONS} from '../../../utils'

const PaymentPlanFormPageComponent = props => {
  const {dataForm, setDataForm, setActiveTab} = props

  const {enqueueSnackbar} = useSnackbar()
  const [isSubmit, setIsSubmit] = useState(false)
  const [serviceLoading, setServiceLoading] = useState(false)
  const [informationPopup, setInformationPopup] = useState({
    dataId: null,
    isShow: false,
  })

  const [addLoanActivity] = useMutation(ADD_LOAN_ACTIVITY, GQL_FINANCE_OPTIONS)

  const onAddActivity = () => {
    setIsSubmit(true)

    if (
      dataForm.installment &&
      dataForm.amount_per_installment > 0 &&
      dataForm.payment_date_start &&
      dataForm.installment_name
    ) {
      setServiceLoading(true)
      if (dataForm.loan_rate === '') dataForm.loan_rate = 0
      dataForm.policy = dataForm.policy.id
      dataForm.status = dataForm.status.value
      dataForm.user_work_id = dataForm.user_work_id.value
      dataForm.payment_date_start = moment(dataForm.payment_date_start).format(
        'YYYY-MM-DD'
      )

      const variables = {
        objects: [
          {
            ...dataForm,
            added_by: USER_ID,
            last_update_by: USER_ID,
          },
        ],
      }

      addLoanActivity({variables: variables})
        .then(data => {
          const insertedDataId =
            data?.data.insert_people_work_loans.returning[0].id

          enqueueSnackbar('Loan Activity added', {
            variant: 'success',
            autoHideDuration: 3000,
          })

          setInformationPopup({
            dataId: insertedDataId,
            isShow: true,
          })
        })
        .catch(() => {
          enqueueSnackbar('Add Loan Activity failed, please try again later', {
            variant: 'error',
            autoHideDuration: 3000,
          })
        })
        .finally(() => {
          setServiceLoading(false)
        })
    }
  }

  const handleConfirmInformationPopupButton = () => {
    setServiceLoading(false)
    setInformationPopup({
      ...informationPopup,
      isShow: false,
    })

    const state = informationPopup?.dataId
      ? {
          active: 'edit-loan-activity',
          loanId: informationPopup?.dataId,
          activeTab: 2, // Tab 2 is installment tab
        }
      : {
          active: 'activity',
          activityId: informationPopup?.dataId,
          searchable: !informationPopup?.dataId,
        }

    props.history.push({
      pathname: `/financial/loan/activity/${
        informationPopup?.dataId ? informationPopup?.dataId + '/edit' : ''
      }`,
      state: {
        ...state,
      },
    })
  }

  return (
    <>
      <PaymentPlanForm
        dataForm={dataForm}
        handlePreviousButton={() => setActiveTab(0)}
        isSubmit={isSubmit}
        mutation={onAddActivity}
        serviceLoading={serviceLoading}
        setDataForm={setDataForm}
      />

      {informationPopup.isShow && (
        <AlertDialog
          feature="Loan Activity Successfully Created!"
          mutation={handleConfirmInformationPopupButton}
          open={informationPopup.isShow}
          type="Confirm"
          handleClose={handleConfirmInformationPopupButton}
          message={
            <div>
              Loan Activity successfully created. Please set{' '}
              <b>the loan installment</b> in edit page.
            </div>
          }
        />
      )}
    </>
  )
}

export default withRouter(PaymentPlanFormPageComponent)
