import {getSearchVariables} from '../../../utils/searchFilters'

export const getClaimFilterVariables = ({
  tabsValue,
  searchText = '',
  filterData = {},
}) => {
  const search = `%${searchText}%`

  return {
    formatted_id: searchText ? {_ilike: search} : undefined,

    status: {
      _in:
        tabsValue === 'request'
          ? ['waiting']
          : ['waiting', 'processing', 'approved', 'rejected', 'cancelled'],
    },

    people_work_placement: getSearchVariables(filterData),
  }
}
