import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import ExportInformationFailedPopup from "./ExportInformationFailedPopup";

const ExportBaloonFree = ({
  data,
  closeBaloonMutation,
  retry,
  completeMessage,
  waitingMessage,
  errorMessage,
  titleErrorPopup,
  statusAllowToClose = ['FAILED']
}) => {
  const [openInformationFailedPopup, setOpenInformationFailedPopup] = useState(
    false
  );

  return (
    <>
      {data &&
      data.company_export_data_logs &&
      data.company_export_data_logs.length > 0 &&
      data &&
      data.company_export_data_logs &&
      data.company_export_data_logs[0].status !== "WAITING" &&
      !data.company_export_data_logs[0].viewed ? (
        <Alert
          color={
            data.company_export_data_logs[0].status === "FAILED"
              ? "error"
              : "success"
          }
          severity={
            data.company_export_data_logs[0].status === "FAILED"
              ? "error"
              : "success"
          }
          style={{ marginBottom: 8, marginLeft: 8 }}
          onClose={
            statusAllowToClose.includes(data.company_export_data_logs[0].status) &&
            closeBaloonMutation
          }
        >
          {(function () {
            if (data.company_export_data_logs[0].status === "WAITING") {
              return waitingMessage;
            } else if (data.company_export_data_logs[0].status === "FAILED") {
              return (
                <>
                  <span style={{ color: "#ef4d5e" }}>
                    {errorMessage(setOpenInformationFailedPopup)}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#4caf50" }}>{completeMessage}</span>
                </>
              );
            }
          })()}
        </Alert>
      ) : data &&
        data.company_export_data_logs &&
        data.company_export_data_logs.length > 0 &&
        data.company_export_data_logs[0].status === "WAITING" ? (
        <Alert
          color={"info"}
          severity={"info"}
          style={{ marginBottom: 8, marginLeft: 8 }}
        >
          {waitingMessage}
        </Alert>
      ) : (
        true
      )}
      <ExportInformationFailedPopup
        title={titleErrorPopup}
        reports={data?.company_export_data_logs?.[0]?.reports}
        retry={retry}
        setOpenInformationFailedPopup={setOpenInformationFailedPopup}
        openInformationFailedPopup={openInformationFailedPopup}
      />
    </>
  );
};

export default ExportBaloonFree;
