import React, {useState} from 'react'
import {withRouter, Link} from 'react-router-dom'
import {
  Toolbar,
  IconButton,
  Divider,
  Paper,
  Typography,
  Avatar,
  TablePagination,
  //   Button,
  Icon,
  Tooltip,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {
  AddEditTitle,
  PaperContainer,
  GreyTypography,
  SkyBlueIcon,
  BlueIcon,
} from '../../../../../GlobalStyles'
// import NoBadge from '../../../../../assets/images/no-badge.svg'
import {makeStyles} from '@material-ui/core/styles'

import ModalPlacement from './modal-placement/ModalPlacementComponent'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {SEE_ALL_POOL_SUCCESSOR} from '../../../../../graphql/queries'
import Loading from '../../../../../components/loading/LoadingComponent'
import {holdingRolesContext} from '../../../../../utils/helpers'
import {UPDATE_HOLDING_NOMINATION_PEOPLE} from '../../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../../shared-components/popup/AddEditPopup'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'

const useStyles = makeStyles(() => ({
  paper: {
    padding: 30,
  },
  paper2: {
    padding: '20px 30px 0px 30px',
    minHeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  informationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  badgeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 16,
  },
  name: {
    fontSize: 16,
    marginBottom: 6,
  },
  commite: {
    color: '#A9A8A8',
    fontSize: 14,
    margin: '0px 0px 7px 0px',
  },
  year: {
    color: '#4CAF50',
    fontSize: 10,
  },
  avatar: {
    width: '80px !important',
    height: '80px !important',
    marginRight: 30,
  },
  badge: {
    width: 40,
    margin: 'auto',
  },
  rank: {
    fontSize: 10,
    marginTop: 6,
  },
  score: {
    fontSize: 12,
    fontWeight: '600',
  },
  grey: {
    color: '#A9A8A8',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 0px',
  },
  title: {
    fontSize: 16,
    color: '#A9A8A8',
    marginBottom: 10,
  },
  pagination: {
    marginTop: 'auto',
  },
  addButton: {
    marginLeft: 'auto',
    marginRight: 20,
    padding: '9px 34px',
  },
  flexTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  dividerLittle: {
    height: '20px !important',
    margin: ' 0 4px 0 7px !important',
  },
}))

const SeeAll = props => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [openAdd, setOpenAdd] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState('')
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const {detail, type} = props.location.state
  const [onMutation] = useMutation(UPDATE_HOLDING_NOMINATION_PEOPLE)

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleOpenPlc = () => {
    setOpen(prev => !prev)
  }

  const variables = {
    id_nomination: detail.id,
    search: `%${props.searchText}%`,
    status: type === 'pool' ? 'is_talent' : 'is_successor',
  }

  const {data, error, refetch} = useQuery(SEE_ALL_POOL_SUCCESSOR, {
    variables,
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const handleOpen = (ids, type, names) => {
    if (type === 'add') {
      setOpenAdd(true)
    } else {
      setOpenDel(true)
    }
    setId(ids)
    setName(names)
    setConfirmType(type)
  }

  const handleClose = () => {
    if (confirmType === 'add') {
      setOpenAdd(false)
    } else {
      setOpenDel(false)
    }
  }

  const handleMutation = () => {
    const changes = {
      candidate_status: confirmType === 'add' ? 'is_successor' : 'is_talent',
    }
    onMutation({
      variables: {id, changes},
      ...holdingRolesContext,
    })
      .then(() => {
        enqueueSnackbar('Success Update data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(
          'Update data error, please try again' + JSON.stringify(e),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const response = data.holding_nomination_people
  // console.log(response)

  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link onClick={() => props.history.goBack()}>
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <AddEditTitle variant="h6" className={classes.grey}>
          {detail.name}
        </AddEditTitle>
        <Typography style={{marginLeft: 10}} variant="h6">
          / {type === 'pool' ? 'Talent Pool' : 'Successor Nomination'}
        </Typography>
      </Toolbar>
      <Divider />
      <Paper className={classes.paper}>
        <Paper className={classes.paper2}>
          <div>
            {(rowsPerPage > 0
              ? response.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : response
            ).map((rowData, i) => {
              const avatar =
                rowData.holding_source.people_work_placement.global_user.avatar

              const name =
                rowData.holding_source.people_work_placement.global_user.name

              const pos =
                rowData.holding_source.people_work_placement.company_job_profile
                  .title

              const office =
                rowData.holding_source.people_work_placement.company_profile
                  .brand_name
              const nameBadge =
                rowData.holding_source.people_work_placement
                  .talent_assignments[0].talent_box.name
              const imgBadge =
                rowData.holding_source.people_work_placement
                  .talent_assignments[0].talent_box.badge
              const score =
                rowData.holding_source.people_work_placement
                  .talent_assignments[0].talent_score
              return (
                <div key={i} className={classes.row}>
                  <Avatar src={avatar} alt={name} className={classes.avatar} />
                  <div className={classes.informationWrapper}>
                    <Typography className={classes.name}>{name}</Typography>
                    <Typography className={classes.commite}>
                      {pos} at {office}
                    </Typography>
                    <div className={classes.flexTitle}>
                      <img
                        src={imgBadge}
                        alt="asd"
                        width="20"
                        style={{marginRight: 6}}
                      ></img>
                      <GreyTypography variant="caption">
                        {nameBadge}
                      </GreyTypography>
                      <Divider
                        orientation="vertical"
                        className={classes.dividerLittle}
                      ></Divider>
                      <Icon
                        style={{
                          fontSize: 20,
                          color: '#4caf50',
                          marginRight: 4,
                        }}
                      >
                        star
                      </Icon>
                      <GreyTypography variant="caption">
                        {score.toFixed(2)}
                      </GreyTypography>
                    </div>
                  </div>
                  <div className={classes.badgeWrapper}>
                    {type === 'pool' ? (
                      <Tooltip title="Add to Talent Nomination">
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleOpen(
                              rowData.id,
                              'add',
                              rowData.holding_source.people_work_placement
                                .global_user.name
                            )
                          }
                        >
                          <BlueIcon>person_add</BlueIcon>
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <div style={{display: 'flex'}}>
                        <Tooltip title="Placement">
                          <IconButton
                            style={{marginRight: 24}}
                            size="small"
                            onClick={handleOpenPlc}
                          >
                            <SkyBlueIcon>next_week</SkyBlueIcon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove from Talent Nomination">
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleOpen(
                                rowData.id,
                                'delete',
                                rowData.holding_source.people_work_placement
                                  .global_user.name
                              )
                            }
                          >
                            <Icon color="secondary">person_remove</Icon>
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.pagination}>
            <Divider />
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={response.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
      </Paper>
      <ModalPlacement open={open} handleClose={handleOpenPlc} />
      <AddEdit
        open={openAdd}
        handleClose={handleClose}
        type={confirmType}
        name={`${name} to talent nomination`}
        feature=""
        mutation={handleMutation}
      />
      <DeletePopup
        open={openDel}
        handleClose={handleClose}
        id={id}
        name={`${name} from talent nomination`}
        mutation={handleMutation}
      />
    </PaperContainer>
  )
}

export default withRouter(SeeAll)
