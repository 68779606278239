import gql from 'graphql-tag'

export const REJECT_STATUS_LEAVE = gql`
  mutation updateStatusLeave(
    $id: bigint
    $status: String!
    $leave_fields: jsonb
  ) {
    update_people_work_leaves(
      _set: {status: $status}
      _append: {leave_fields: $leave_fields}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const APPROVE_STATUS_LEAVE_CONFIRM1 = gql`
  mutation updateconfirmtype1(
    $id: bigint!
    $latest_status: String
    $leave_fields: jsonb
  ) {
    pendingtoconfirm1: update_people_work_leaves(
      _set: {status: "confirm1"}
      _append: {leave_fields: $leave_fields}
      where: {
        id: {_eq: $id}
        status: {_eq: $latest_status}
        time_leave_policy_child: {
          second_confirm_profile: {_is_null: false}
          confirm_type: {_eq: "1"}
        }
      }
    ) {
      affected_rows
    }

    confirm1toapproved: update_people_work_leaves(
      _set: {status: "approved"}
      _append: {leave_fields: $leave_fields}
      where: {
        id: {_eq: $id}
        status: {_eq: $latest_status}
        time_leave_policy_child: {
          second_confirm_profile: {_is_null: false}
          confirm_type: {_eq: "1"}
        }
      }
    ) {
      affected_rows
    }

    spv_autoapproved: update_people_work_leaves(
      _set: {status: "approved"}
      _append: {leave_fields: $leave_fields}
      where: {
        id: {_eq: $id}
        status: {_nin: ["cancelled", "approved"]}
        time_leave_policy_child: {
          second_confirm_profile: {_is_null: true}
          confirm_type: {_eq: "1"}
        }
      }
    ) {
      affected_rows
    }
  }
`
export const APPROVE_STATUS_LEAVE_CONFIRM2 = gql`
  mutation updateconfirmtype2(
    $id: bigint!
    $latest_status: String
    $leave_fields: jsonb
  ) {
    pendingtoconfirm1: update_people_work_leaves(
      _set: {status: "confirm1"}
      _append: {leave_fields: $leave_fields}
      where: {
        id: {_eq: $id}
        status: {_eq: $latest_status}
        time_leave_policy_child: {
          second_confirm_profile: {_is_null: false}
          confirm_type: {_eq: "2"}
        }
      }
    ) {
      affected_rows
    }

    confirm1toapproved: update_people_work_leaves(
      _set: {status: "approved"}
      _append: {leave_fields: $leave_fields}
      where: {
        id: {_eq: $id}
        status: {_eq: $latest_status}
        time_leave_policy_child: {
          second_confirm_profile: {_is_null: false}
          confirm_type: {_eq: "2"}
        }
      }
    ) {
      affected_rows
    }

    first_autoapproved: update_people_work_leaves(
      _set: {status: "approved"}
      _append: {leave_fields: $leave_fields}
      where: {
        id: {_eq: $id}
        status: {_nin: ["cancelled", "approved"]}
        time_leave_policy_child: {
          second_confirm_profile: {_is_null: true}
          confirm_type: {_eq: "2"}
        }
      }
    ) {
      affected_rows
    }
  }
`
