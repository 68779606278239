import React from 'react'
import {Divider} from '@material-ui/core'
import {EnhancedToolbar, PaperContainer} from '../../../../GlobalStyles'
import {
  BigTextBlackBold,
  // MediumTextBlackBold,
} from '../PayrollPageStyles'
// import {convertToCurrency} from '../../../../utils/helpers'
import MonthlyPayment from './graph/MonthlyPayment'
/* Hide: WLB-5031 */
// import Leave from './graph/Leave'

export default function PayrollAnalytics() {
  return (
    <PaperContainer>
      <EnhancedToolbar>
        <BigTextBlackBold>Payroll Analytics</BigTextBlackBold>
      </EnhancedToolbar>
      <Divider />
      <div>
        <MonthlyPayment />
        {/* /* Hide: WLB-5031 */}
        {/* <Leave /> */}
      </div>
    </PaperContainer>
  )
}
