import React, {Fragment, useEffect, useState} from 'react'
import helperStyle, {
  RootContainer,
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  GenerateTooltip,
} from '../../../GlobalStyles'
import {Typography, Button, Divider, Avatar, Tabs, Tab} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import MaterialTable from 'material-table'
import {TableOptions} from './EmployeePageStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {
  GET_DIRECTORY_EMPLOYEE_WT_EDUCATION,
  GET_BALOON_STATUS_BUTTON_QUERY_PARALEL,
  GET_DIRECTORY_EMPLOYEE_LIST_DETAIL,
  GET_DIRECTORY_ALUMNI_LIST,
} from '../../../graphql/queries'
import {
  IMPORT_DATA,
  CLOSE_BALOON,
  CLOSE_BALOON_IMPORT_MULTIPLE,
} from '../../../graphql/mutations'
import ImportPopup from '../../../components/importExcel/ImportPopup'
import ImportBaloon from '../../../components/importExcel/ImportBaloon'
import moment from 'moment'
import {WrapperContent} from '../../organization/OrganizationPageStyles'
import {
  getDataVariableEmployee,
  getDataVariableAlumni,
} from './diretoryFilterData'
import PopupWLB from '../../shared-components/popup/PopupWLB'
import {useSnackbar} from 'notistack'
import {COMPANY_ID, REAST_API_URL} from '../../../utils/globals'
import Axios from 'axios'
import useDownloadReport from '../../../hooks/useDownloadReport'
import ExportBaloon from '../../../components/exportExcel/ExportBaloon'
import {
  useActivateEmployee,
  generateVariable,
  generateTemplateMaster,
  getLocationFrom,
  useDeleteRegisteredEmployee,
} from './helper-employee'
import {
  DEFAULT_DATA_RUNNING,
  EMPOYEE_PERSONAL_DATA,
  TEMPLATES_IMPORT_PERSONAL_DATA,
  TEXT_TOOLTIP,
  INITIAL_INFO_STATE,
} from './constant'
import ButtonImport from './ButtonImport'
import {
  generateTempaltesPersonal,
  handlerImportMultiple,
  getTotalImportProcess,
  generateDataProcess,
  generateIdBaloon,
} from './import-helper'
import ImportBaloonMultiple from '../../../components/importExcel/ImportBaloonMultiple'
import RunningProcess from '../../../components/importExcel/RunningProcess'
import ButtonMoreHorizontal from '../../../components/button/ButtonMoreHorizontal'
import NoDataListComponent from '../../shared-components/NoDataListComponent'
import {TableLocalization} from '../../shared-components/table/TableOptions'

function EmployeeList(props) {
  const {enqueueSnackbar} = useSnackbar()
  const classes = helperStyle()
  const [openExport, setOpenExport] = useState(false)
  const [infoState, setInfoState] = useState(INITIAL_INFO_STATE)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const [isShowListTemplate, setIsShowListTemplate] = useState(true)
  const [openImport, setImport] = useState(false)
  const [openAllBaloon, setOpenAllBaloon] = useState(false)
  const [errorImportMultiple, setErrorImportMultiple] = useState([])
  const [positionImport, setPositionImport] = useState('Employees')
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')
  const [empIds, setEmpIds] = useState([])
  const [selectedRowsData, setSelectedRowsData] = useState([])
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )
  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    refetch: refetchBaloonStatus,
    loading: loadingBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON_QUERY_PARALEL, {
    variables: {
      company: COMPANY_ID,
      table: 'global_users',
      multiple_table: TEMPLATES_IMPORT_PERSONAL_DATA.map(
        ({tableName}) => tableName
      ),
    },
  })

  const [closeRunningProcessBaloon] = useMutation(CLOSE_BALOON_IMPORT_MULTIPLE)
  const {searchText, empType, location, filterData} = props

  const {
    pendingRegister,
    pendingReplacement,
    isActive,
    isWillJoin,
    isPendingPage,
    isPendingReplacement,
    isPendingRegister,
    isAlumni,
  } = getLocationFrom({pathname: location.pathname})

  const TABLE_REPORT_NAME = 'people_export'

  const [onUpdateActivation] = useActivateEmployee(enqueueSnackbar)
  const [onDeleteRegisrered] = useDeleteRegisteredEmployee()
  const {dataBaloon, isDisableButton, onDownload} = useDownloadReport({
    table: TABLE_REPORT_NAME,
    filter: {status: isAlumni ? 'inactive' : 'active'},
  })

  const variables_filter = getDataVariableEmployee(filterData)
  const variables_alumni_filter = getDataVariableAlumni(filterData)
  const {variables, title, activeState} = generateVariable({
    pathname: location.pathname,
  })

  const {data, error, loading, refetch} = useQuery(
    isAlumni ? GET_DIRECTORY_ALUMNI_LIST : GET_DIRECTORY_EMPLOYEE_WT_EDUCATION,
    {
      variables: {
        ...(!isAlumni && variables),
        ...(!isAlumni && variables_filter),
        ...(isAlumni && variables_alumni_filter),
        search: `%${searchText}%`,
        offset: pagination.offset * pagination.limit,
        limit: pagination.limit,
      },
      fetchPolicy: 'no-cache',
    }
  )

  const {
    data: dataEmpListDetails,
    loading: loadingEmpListDetails,
    error: errorEmpListDetails,
    refetch: refetchListDetail,
  } = useQuery(GET_DIRECTORY_EMPLOYEE_LIST_DETAIL, {
    wlb_skipPatch: true,
    variables: {
      id: empIds,
    },
  })

  const handleResendEmail = async rowData => {
    let path = ''
    const isReplacementEmail =
      rowData.global_user &&
      rowData.global_user.status === 'ACTIVE' &&
      rowData.status === 'REGISTERED'
    if (rowData.global_user && rowData.global_user.status === 'REGISTERED') {
      path = 'accounts/resendVerification'
    } else if (isReplacementEmail) {
      path = 'replacement/resend_placement'
    }
    const url = REAST_API_URL + `/global/${path}`

    Axios({
      method: 'post',
      url: url,
      data: {
        ...(isReplacementEmail && {userWorkId: rowData.id}),
        email: rowData.global_user && rowData.global_user.email,
      },
    })
      .then(function() {
        enqueueSnackbar(`Kirim Ulang Email Berhasil`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
      })
      .catch(function() {
        enqueueSnackbar('Kirim Ulang Email Gagal, silakan coba lagi nanti', {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  useEffect(() => {
    setPagination({
      ...pagination,
      offset: 0,
    })
  }, [searchText])

  useEffect(() => {
    if (data) {
      const employeeIds = []
      data.people_work_placements.map(employeeId =>
        employeeIds.push(employeeId.id)
      )
      setEmpIds(employeeIds)
    }
  }, [data])

  if (
    error !== undefined ||
    errorGetBaloonStatus !== undefined ||
    errorEmpListDetails !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationImportError !== undefined
  ) {
    return (
      <div>
        {JSON.stringify(error) ||
          JSON.stringify(errorGetBaloonStatus) ||
          JSON.stringify(mutationCloseBaloonError) ||
          JSON.stringify(mutationImportError)}
      </div>
    )
  }

  if (getBaloonStatus) {
    // console.log("[getBaloonStatus]",getBaloonStatus)
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  const handleActionAfterActivate = async ({setOpen}) => {
    setOpen(false)
    await refetch()
    await refetchListDetail()
  }

  const handleActivateAllEmployees = () => {
    setInfoState(e => ({...e, isLoading: true}))
    onUpdateActivation({
      id: selectedRowsData.map(res => res.id),
      user: selectedRowsData.map(res => res.global_user.id),
      withSnackbar: false,
      onSucess: async () => {
        await refetch()
        await refetchListDetail()
        setSelectedRowsData([])
        setInfoState({
          open: true,
          isLoading: false,
          title: 'Employee Activation Successfull',
          content: 'All employees has been activated',
          onConfirm: () => setInfoState(INITIAL_INFO_STATE),
          onCancel: undefined,
        })
      },
    })
  }

  const handleDeleteAllEmployees = (user, isAllDeleted) => {
    setInfoState(e => ({...e, isLoading: true}))
    onDeleteRegisrered({
      user,
      onSucess: async () => {
        await refetch()
        await refetchListDetail()
        setSelectedRowsData([])
        setInfoState({
          open: true,
          isLoading: false,
          title: 'Employee Activation Deleted',
          content: `${
            isAllDeleted ? 'All employees' : 'Employees'
          } activation has been deleted`,
          onConfirm: () => setInfoState(INITIAL_INFO_STATE),
          onCancel: undefined,
        })
      },
      onError: () => {
        enqueueSnackbar(`Delete employee error, please try again later`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      },
    })
  }

  const handleSetActivateEmployees = () => {
    setInfoState({
      open: true,
      title: 'Activate All Employees?',
      content: 'Are you sure to activate all employees?',
      onConfirm: () => handleActivateAllEmployees(),
      onCancel: () => setInfoState(INITIAL_INFO_STATE),
    })
  }

  const handleSetDeleteEmployees = ({user, title, content, isAllDeleted}) => {
    setInfoState({
      open: true,
      title,
      content,
      onConfirm: () => handleDeleteAllEmployees(user, isAllDeleted),
      onCancel: () => setInfoState(INITIAL_INFO_STATE),
    })
  }

  const column = [
    {
      title:
        isPendingPage && selectedRowsData[0] ? (
          <ButtonMoreHorizontal
            id="_action_selected"
            list={[
              ...[
                isPendingReplacement && {
                  label: 'Activate All',
                  onClick: () => handleSetActivateEmployees(),
                },
              ],
              ...[
                isPendingRegister && {
                  label: 'Delete All',
                  onClick: () =>
                    handleSetDeleteEmployees({
                      title: 'Delete All Employees Activation?',
                      content:
                        'Are you sure to delete all employee activation?',
                      user: selectedRowsData.map(res => res.global_user.id),
                      isAllDeleted: true,
                    }),
                },
              ],
            ]}
          />
        ) : (
          'Avatar'
        ),
      field: 'global_user.name',
      sorting: false,
      render: row => (
        <Avatar
          size="small"
          alt={row.global_user.name}
          src={row.global_user.avatar}
        />
      ),
    },
    {
      title: 'NIP',
      field: 'regno',
      cellStyle: {
        wordBreak: 'normal',
      },
    },
    {
      title: 'Nama',
      field: 'global_user.name',
      cellStyle: {
        wordBreak: 'break-word',
        minWidth: 150,
      },
    },
    {
      title: 'Posisi',
      field: 'company_job_profile.title',
      cellStyle: {
        minWidth: 160,
      },
    },
    {
      title: isPendingPage ? 'Tanggal Pengajuan' : 'Tanggal Mulai',
      field: isPendingPage ? 'date_added' : 'start_date',
      cellStyle: {
        wordBreak: 'normal',
      },
      render: row =>
        isPendingPage
          ? moment(row.date_added).format('ll')
          : row.start_date && moment(row.start_date).format('ll'),
    },
    isAlumni
      ? {
          title: 'Status Keputusan',
          sorting: false,
          render: row =>
            (row.termination_status && row.termination_status.name) || '-',
        }
      : {},
    {
      title: 'Aksi',
      field: 'action',
      sorting: false,
      render: rowData => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <Fragment>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>
              <StyledMenus {...bindMenu(popupState)}>
                {(isActive || isAlumni) && (
                  <StyledMenuItem
                    component={Link}
                    to={{
                      pathname:
                        '/directory/' +
                        activeState +
                        '/' +
                        rowData.global_user.id,
                      state: {
                        active: 'detail-employee',
                        empType,
                      },
                    }}
                    variant="h6"
                    style={{justifyContent: 'normal'}}
                  >
                    Detail
                  </StyledMenuItem>
                )}
                {isPendingRegister && (
                  <StyledMenuItem
                    style={{justifyContent: 'start'}}
                    onClick={() => {
                      popupState.close()
                      handleSetDeleteEmployees({
                        title: 'Hapus Aktivasi Pegawai?',
                        content:
                          'Apakah kamu yakin untuk menghapus aktivasi pegawai ini?',
                        user: [rowData.global_user.id],
                        isAllDeleted: false,
                      })
                    }}
                  >
                    Hapus
                  </StyledMenuItem>
                )}
                {isPendingReplacement &&
                  rowData.global_user.status === 'ACTIVE' &&
                  rowData.status === 'REGISTERED' && (
                    <StyledMenuItem
                      style={{justifyContent: 'start'}}
                      onClick={() =>
                        onUpdateActivation({
                          id: [rowData.id],
                          user: [rowData.global_user.id],
                          onSucess: () =>
                            handleActionAfterActivate({
                              setOpen: popupState.setOpen,
                            }),
                        })
                      }
                    >
                      Activate
                    </StyledMenuItem>
                  )}
                {isWillJoin && (
                  <StyledMenuItem
                    component={Link}
                    to={{
                      pathname: `/directory/${activeState}/edit/${rowData.id}`,
                      state: {
                        active: 'edit-employee',
                        isReplacement: true,
                        userId: rowData.global_user.id,
                      },
                    }}
                  >
                    Penggantian
                  </StyledMenuItem>
                )}
                {isPendingPage && (
                  <StyledMenuItem
                    component={Link}
                    to={{
                      pathname: `/directory/${activeState}/edit/${rowData.id}`,
                      state: {
                        active: 'edit-employee',
                        isEdit: true,
                        userId: rowData.global_user.id,
                      },
                    }}
                  >
                    Ubah Informasi Penempatan
                  </StyledMenuItem>
                )}
                {isPendingPage && (
                  <StyledMenuItem
                    style={{justifyContent: 'start'}}
                    onClick={() => handleResendEmail(rowData)}
                  >
                    Kirim Ulang Email
                  </StyledMenuItem>
                )}
              </StyledMenus>
            </Fragment>
          )}
        </PopupState>
      ),
    },
  ]

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  if (isAlumni)
    column.splice(5, 0, {
      title: 'Tanggal Berakhir',
      field: 'end_date',
      cellStyle: {
        wordBreak: 'normal',
      },
      render: row => row.end_date && moment(row.end_date).format('ll'),
    })

  const importMutation = async ({isMultiple, file}) => {
    if (isMultiple) {
      const _insert = datas => {
        datas.forEach(({tableName, url}) => {
          mutationImport({
            variables: {
              table: tableName,
              file: url,
            },
          }).then(() => {
            refetchBaloonStatus()
          })
        })
      }
      await _insert(handlerImportMultiple(file.multipleFiles))
    } else {
      try {
        // eslint-disable-next-line
        const response = await mutationImport({
          variables: {
            table: 'global_users',
            file: fileURL,
          },
        })
        refetchBaloonStatus()
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    }
  }

  const closeBaloonMutation = async id => {
    try {
      await mutationCloseBaloon({
        variables: {
          id,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  const handleClickOpenImport = position => {
    setImport(true)
    setPositionImport(position)
    setIsShowListTemplate(true)
  }

  const handleCloseImport = () => {
    setImport(false)
    setPositionImport('Employees')
    setErrorImportMultiple([])
  }

  const handleSetImport = (open, position) => {
    setImport(open)
    setPositionImport(position)
    if (position === EMPOYEE_PERSONAL_DATA) {
      setIsShowListTemplate(false)
      setErrorImportMultiple(
        getBaloonStatus.multiple_table[0].reports.failed || []
      )
    }
  }

  const handleOnSeeProblem = data => {
    if (data.type === 'Employee Personal Data') {
      setErrorImportMultiple(data.data.reports.failed)
      setIsShowListTemplate(false)
    }
    setImport(true)
    setPositionImport(data.type === 'Employee List' ? 'Employees' : data.type)
  }

  const handleCloseRunningProcess = async () => {
    const id = generateIdBaloon(getBaloonStatus)
    await closeRunningProcessBaloon({
      variables: {
        id,
      },
    }).then(() => {
      refetchBaloonStatus()
    })
  }

  const handleDownloadData = async () => {
    await onDownload({
      variables: {
        table: TABLE_REPORT_NAME,
        filter: {status: isAlumni ? 'inactive' : 'active'},
      },
    })
    setOpenExport(true)
  }

  const handleChangeTab = (event, newValue) => {
    event.preventDefault()
    props.history.push({
      pathname: newValue,
    })
  }

  const isDisable =
    (isAlumni && data && data.total_no_filter.aggregate.count === 0) ||
    isDisableButton

  const TOTAL_PROCESS = getTotalImportProcess(getBaloonStatus)

  return (
    <RootContainer>
      {TOTAL_PROCESS === 1 &&
        getBaloonStatus &&
        getBaloonStatus.company_import_data_logs.length > 0 && (
          <ImportBaloon
            data={getBaloonStatus}
            closeBaloonMutation={closeBaloonMutation}
            setImport={open => handleSetImport(open, 'Employees')}
            mutationCloseBaloon={mutationCloseBaloon}
            refetchBaloonStatus={refetchBaloonStatus}
          />
        )}
      {TOTAL_PROCESS === 1 &&
        getBaloonStatus &&
        getBaloonStatus.multiple_table.length > 0 && (
          <ImportBaloon
            data={getBaloonStatus}
            bridge="multiple_table"
            closeBaloonMutation={closeBaloonMutation}
            setImport={open => handleSetImport(open, EMPOYEE_PERSONAL_DATA)}
            mutationCloseBaloon={mutationCloseBaloon}
            refetchBaloonStatus={refetchBaloonStatus}
          />
        )}
      {TOTAL_PROCESS > 1 && (
        <ImportBaloonMultiple
          totalProcess={TOTAL_PROCESS}
          onSeeAll={() => setOpenAllBaloon(true)}
          onClose={handleCloseRunningProcess}
        />
      )}
      {(isActive || isAlumni) && (
        <ExportBaloon isLastGenerateStatus data={dataBaloon} />
      )}
      <PaperContainer style={{overflow: 'unset'}}>
        <EnhancedToolbar>
          <Typography variant="h6">{title}</Typography>

          <WrapperContent>
            {(isActive || isAlumni) && (
              <GenerateTooltip
                title={isDisable ? TEXT_TOOLTIP : ``}
                placement="left"
              >
                <div style={{display: 'inline'}}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={isDisable}
                    size="large"
                    style={{marginRight: 12}}
                    onClick={handleDownloadData}
                  >
                    Download
                  </Button>
                </div>
              </GenerateTooltip>
            )}
            {(isActive || isAlumni) && (
              <ButtonImport
                disabled={[
                  (getBaloonStatus &&
                    getBaloonStatus.company_import_data_logs.length > 0 &&
                    // eslint-disable-next-line
                    getBaloonStatus.company_import_data_logs[0].status ===
                      'WAITING') ||
                    false,
                  false,
                ]}
                onClickOption={handleClickOpenImport}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={{
                pathname: `/directory/employee/add-employee`,
                state: {active: 'add-employee'},
              }}
            >
              Tambah Pegawai
            </Button>
          </WrapperContent>
        </EnhancedToolbar>
        <Divider />
        {isPendingPage && (
          <Tabs
            value={location.pathname}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
            classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
          >
            <Tab
              value={pendingRegister}
              label="Daftar"
              className={classes.tabRoot}
            />
            <Tab
              value={pendingReplacement}
              label="Penggantian"
              className={classes.tabRoot}
            />
          </Tabs>
        )}
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontSize: 12, boxShadow: 'none'}}
          options={{
            ...TableOptions,
            selection: isPendingPage,
            emptyRowsWhenPaging: false,
          }}
          columns={column}
          components={{
            Toolbar: () => null,
          }}
          localization={{
            pagination: TableLocalization.pagination,
            body: {
              emptyDataSourceMessage: (
                <NoDataListComponent search={searchText} />
              ),
            },
          }}
          isLoading={loading || loadingEmpListDetails}
          title=""
          data={dataEmpListDetails && dataEmpListDetails.people_work_placements}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={data && data.total.aggregate.count}
          page={pagination.offset}
          pageSize={pagination.limit}
          onSelectionChange={rows => setSelectedRowsData(rows)}
        />
        <ImportPopup
          open={openImport}
          position={positionImport}
          handleClose={handleCloseImport}
          mutation={importMutation}
          onDownloadMaster={
            positionImport === 'Employees'
              ? ({provinces, cities, religions}) =>
                  generateTemplateMaster({provinces, cities, religions})
              : undefined
          }
          fileTemplate={
            'https://public-media.wlb.co.id/1dc6d763-8ad8-4e8a-8d26-5bb73dd569f2-1602486963025.xlsx'
          }
          isAlreadyImported={isAlreadyImported}
          setIsAlreadyImported={setIsAlreadyImported}
          setImport={setImport}
          fileURL={fileURL}
          setFileURL={setFileURL}
          closeBaloon={closeBaloonMutation}
          errorsImport={
            getBaloonStatus &&
            getBaloonStatus.company_import_data_logs.length > 0 &&
            getBaloonStatus.company_import_data_logs[0].reports &&
            getBaloonStatus.company_import_data_logs[0].reports.failed.length >
              0
              ? getBaloonStatus.company_import_data_logs[0].reports.failed
              : []
          }
          templates={{
            isMultiple: positionImport === EMPOYEE_PERSONAL_DATA,
            listTemplates: TEMPLATES_IMPORT_PERSONAL_DATA,
            onClickTemplates: (clickedList, MASTER_DATA) =>
              generateTempaltesPersonal(clickedList, MASTER_DATA),
            errorsImportMultiple: errorImportMultiple,
            isShowListTemplate,
            // isErrorValidateTableName: ({name}) => {
            //   if(name) {
            //     return tableNameChecker(name)
            //   } else return false
            // }
          }}
        />
        <PopupWLB
          open={openExport}
          onClose={() => setOpenExport(false)}
          onClickButton={() => setOpenExport(false)}
          title="Download Data is Processing!"
          content="Download Data is processing! We’ll send you an email when it’s ready."
        />
        <PopupWLB
          open={infoState.open}
          isLoading={infoState.isLoading}
          onClose={() => setInfoState(INITIAL_INFO_STATE)}
          onClickButton={infoState.onConfirm && (() => infoState.onConfirm())}
          onClickCancel={infoState.onCancel && (() => infoState.onCancel())}
          title={infoState.title}
          content={infoState.content}
        />

        <RunningProcess
          open={openAllBaloon}
          onClose={() => setOpenAllBaloon(false)}
          onCloseBaloon={closeBaloonMutation}
          data={{
            ...DEFAULT_DATA_RUNNING,
            import: generateDataProcess(getBaloonStatus),
          }}
          onSeeProbem={handleOnSeeProblem}
          onReload={() => refetchBaloonStatus()}
          loading={loadingBaloonStatus}
        />
      </PaperContainer>
    </RootContainer>
  )
}

export default withRouter(EmployeeList)
