import React from 'react'
import {Link} from 'react-router-dom'

import MaterialTable from 'material-table'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'

import {MoreIcon, StyledMenuItem, StyledMenus} from '../../../../GlobalStyles'
import {
  TableOptions,
  TableContainer,
  CATEGORY_COLOR,
} from '../ClaimLimitPageStyles'
import {convertToRupiah} from '../../../../utils/helpers'

function EmployeeBalanceHistoryList(props) {
  const {
    data,
    totalCount,
    loading,
    offset,
    limit,
    onChangeOffset,
    onChangeLimit,
    onEditClick,
  } = props

  const renderActions = data => {
    return (
      <PopupState variant="popover">
        {state => (
          <>
            <MoreIcon {...bindTrigger(state)}>more_horiz</MoreIcon>

            <StyledMenus {...bindMenu(state)}>
              <StyledMenuItem
                component={Link}
                to={{
                  pathname: `/budget/limit/employee/${data.id}`,
                  state: {active: 'employee-details', searchable: true},
                }}
              >
                Details
              </StyledMenuItem>
              <StyledMenuItem
                component={Link}
                to={{
                  pathname: `/budget/limit/employee/${data.id}/topup`,
                  state: {active: 'employee-individual-topup'},
                }}
              >
                Top Up
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  state.close()

                  if (onEditClick) {
                    const userWalletId = data.user.wallet.id

                    const total = data.user.wallet.total.aggregate.sum.value
                    const claimed = data.claimed.aggregate.sum.value
                    const availableNominal = total - claimed

                    onEditClick({
                      userWalletId: userWalletId,
                      availableLimit: availableNominal,
                    })
                  }
                }}
              >
                Edit
              </StyledMenuItem>
            </StyledMenus>
          </>
        )}
      </PopupState>
    )
  }

  const TABLE_COLUMNS_CONFIG = [
    {title: 'Employee ID', field: 'regno'},
    {title: 'Employee Name', field: 'user.name'},
    {title: 'In', render: renderDepositAmount, align: 'right'},
    {title: 'Available Claim Limit', render: renderLimit, align: 'right'},
    {title: 'Actions', render: renderActions, sorting: false},
  ]

  return (
    <MaterialTable
      isLoading={loading}
      data={data}
      totalCount={totalCount}
      page={offset}
      pageSize={limit}
      onChangePage={onChangeOffset}
      onChangeRowsPerPage={onChangeLimit}
      columns={TABLE_COLUMNS_CONFIG}
      options={TableOptions}
      components={{Container: TableContainer}}
    />
  )
}

export default EmployeeBalanceHistoryList

function renderDepositAmount(data) {
  const nominal = data.user.wallet.in[0]?.nominal || 0
  const formattedNominal = convertToRupiah(nominal)

  return <span style={{color: CATEGORY_COLOR.In}}>+{formattedNominal}</span>
}

function renderLimit(data) {
  const total = data.user.wallet.total.aggregate.sum.value
  const claimed = data.claimed.aggregate.sum.value
  const available = total - claimed

  const formattedAvailable = convertToRupiah(available)

  return formattedAvailable
}
