import React from 'react'
import {TaxOfficeWrapper} from '../../../../PayrollPageStyles'

export default function ComponentTaxOffice(props) {
  const {name, code, address, onClick} = props

  return (
    <TaxOfficeWrapper
      style={{
        cursor: onClick ? 'pointer' : 'normal',
      }}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <div>
        <img
          alt={name}
          src={require('../../../../../../../assets/images/location_city.png')}
        />
      </div>
      <div className="desciription">
        <h3>{code !== null ? code : '-'}</h3>
        <h3>{name !== null ? name : '-'}</h3>
        <p>{address !== null ? address : '-'}</p>
      </div>
    </TaxOfficeWrapper>
  )
}
