import React from 'react'

import {IconButton, Link} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import TodayIcon from '@material-ui/icons/Today'
import EditIcon from '@material-ui/icons/Edit'

import {ItemWrapper} from '../../../DetailEmployeeStyles'
import {
  capitalize,
  dateFormat,
  differenceDate,
} from '../../../../../../../utils/helpers'

const DependentItem = props => {
  const {data, userName, onEdit, onDetails} = props

  const birthdate = React.useMemo(() => {
    return dateFormat(data.birthdate, 'MMM DD, YYYY')
  }, [data])

  const age = React.useMemo(() => {
    return differenceDate(data.birthdate)
  }, [data])

  return (
    <ItemWrapper>
      <h1 className="title">{data.name}</h1>
      <h2 className="subtitle">{capitalize(data.relationship || '-')}</h2>

      <table className="content" style={{'--header-width': '1%'}}>
        <tr>
          <th scope="row">
            <LocationOnIcon color="primary" />
          </th>
          <td>{data.address || '-'}</td>
        </tr>
        <tr>
          <th scope="row">
            <TodayIcon color="primary" />
          </th>
          <td>
            {birthdate} ({age} Tahun)
          </td>
        </tr>
      </table>

      {data.is_beneficiary && (
        <h3 className="main" style={{margin: '8px 0 0'}}>
          Penerima dari {userName}
        </h3>
      )}

      <Link
        color="secondary"
        component="button"
        onClick={onDetails}
        style={{marginTop: 16}}
      >
        Tampilkan Detail Tanggungan
      </Link>

      <IconButton onClick={onEdit} className="edit">
        <EditIcon />
      </IconButton>
    </ItemWrapper>
  )
}

export default DependentItem
