import React from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import DeletePopup from '../../../../../../shared-components/popup/DeletePopup'
import {GridListing} from '../../../DetailEmployeeStyles'
import LoadingEmployee from '../../../../LoadingEmployee'
import {ButtonAddDataTab} from '../data-tab-shared'
import EmergencyContactItem from './EmergencyContactItem'
import AddEditEmergencyContact from './AddEditEmergencyContact'

import {GET_EMERGENCY_CONTACTS} from '../../../../../../../graphql/queries'
import {DELETE_EMERGENCY_CONTACT} from '../../../../../../../graphql/mutations'

const INITIAL_DETAIL_STATE = {
  openForm: false,
  openDelete: false,
  data: null,
}

const EmergencyContact = props => {
  const {userId} = props

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const [detailState, setDetailState] = React.useState(INITIAL_DETAIL_STATE)

  const {data, error, refetch} = useQuery(GET_EMERGENCY_CONTACTS, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      user: userId,
    },
  })

  if (error) {
    return <div>{'' + error}</div>
  }

  if (!data) {
    return <LoadingEmployee noborder smallpadding />
  }

  const handleContactEdit = data => {
    setDetailState({...detailState, openForm: true, data: data})
  }

  const handleContactAdd = () => {
    setDetailState({...detailState, openForm: true, data: null})
  }

  const handleFormClose = () => {
    setDetailState({...detailState, openForm: false})
  }

  const handleFormSubmit = shouldRefetch => {
    handleFormClose()

    if (shouldRefetch) {
      refetch()
    }
  }

  const handleDeleteOpen = () => {
    setDetailState({...detailState, openDelete: true})
  }

  const handleDeleteClose = action => {
    setDetailState({
      ...detailState,
      openForm: action === 'action-cancel',
      openDelete: false,
    })
  }

  const handleDeleteConfirm = () => {
    const promise = client.mutate({
      mutation: DELETE_EMERGENCY_CONTACT,
      variables: {
        id: detailState.data.id,
      },
    })

    promise.then(
      () => {
        refetch()
        enqueueSnackbar('Identity card deleted', {variant: 'success'})
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Failed to delete identity card`, {variant: 'error'})
      }
    )
  }

  return (
    <>
      <GridListing>
        {data.people_emergency_contacts.map(item => (
          <EmergencyContactItem
            key={item.id}
            data={item}
            onEdit={() => handleContactEdit(item)}
          />
        ))}
      </GridListing>

      <ButtonAddDataTab onClick={handleContactAdd}>
        Tambahkan Kontak Darurat
      </ButtonAddDataTab>

      <AddEditEmergencyContact
        open={detailState.openForm}
        userId={userId}
        initialData={detailState.data}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        onDelete={handleDeleteOpen}
      />

      <DeletePopup
        name={detailState.data?.name}
        open={detailState.openDelete}
        mutation={handleDeleteConfirm}
        handleClose={handleDeleteClose}
      />
    </>
  )
}

export default EmergencyContact
