import React, {useState, useContext, useEffect} from 'react'
import helperStyle, {OrganizationContainer} from '../OfficePageStyles'
import {
  AddEditTitle,
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  TabsStyled,
  TabStyled,
} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {useSnackbar} from 'notistack'
import {IconButton, Button} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import {DataContext} from '../../../../ContextConfig'
import classNames from 'classnames'

import AddEditConfirm from '../../../shared-components/popup/AddEditPopup'
import TabOfficeData from './tabs-add-edit/TabOfficeData'
// import TabTaxInsuranceData from './tabs-add-edit/TabTaxInsuranceData'
import AlertDialog from '../../../shared-components/popup/ResultPopup'

import {useMutation} from '@apollo/react-hooks'
import {
  ADD_OFFICE_ADDRESS,
  EDIT_OFFICE_ADDRESS,
} from '../../../../graphql/mutations'

const AddEditOfficeList = props => {
  const {dataOffice, dataMain, history, dataCheck} = props
  const {dataContext, setDataContext} = useContext(DataContext)
  const {enqueueSnackbar} = useSnackbar()
  const classes = helperStyle()
  const hasJobProfile =
    dataOffice &&
    dataOffice.company_job_profiles_aggregate &&
    dataOffice.company_job_profiles_aggregate.aggregate &&
    dataOffice.company_job_profiles_aggregate.aggregate.count > 0

  const [isError, setIsError] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [tabsValue, setTabsValue] = useState(0)
  const [dialogCancel, setDialogCancel] = useState(false)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [switchChecked, setSwitchChecked] = useState(false)

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const handleAlert = status => {
    dialogCancel ? setDialogCancel(false) : setDialogCancel(true)
    if (dialogCancel && status !== 'notNow') {
      props.history.push({
        pathname: '/company/office',
        state: {active: 'office', searchable: true},
      })
    }
  }

  const openDialogConfirm = () => {
    dialogConfirm ? setDialogConfirm(false) : setDialogConfirm(true)
  }

  const [addOffice] = useMutation(ADD_OFFICE_ADDRESS)
  const [editOffice] = useMutation(EDIT_OFFICE_ADDRESS)

  const nameChecker =
    dataCheck &&
    dataCheck.company_addresses &&
    dataCheck.company_addresses.some(
      v =>
        v.office_name.toLowerCase() ===
        (dataContext && dataContext.office_name.toLowerCase())
    )

  const codeChecker =
    dataCheck &&
    dataCheck.company_addresses &&
    dataCheck.company_addresses.some(v => {
      if (v && v.office_code && dataContext && dataContext.office_code) {
        return (
          v.office_code.toLowerCase() === dataContext.office_code.toLowerCase()
        )
      }
    })

  const checkSameCodeWithAdd =
    dataContext && dataContext.office_code && dataContext.office_code.length > 1
      ? (dataOffice && dataOffice.office_code) ===
        (dataContext && dataContext.office_code)
      : false

  // const checkSameNameWithAdd =
  //   (dataOffice && dataOffice.office_name) ===
  //   (dataContext && dataContext.office_name)

  const handleSave = () => {
    if (
      !dataContext.office_code ||
      !dataContext.office_name ||
      !dataContext.address ||
      !dataContext.postal_code ||
      !dataContext.province ||
      !dataContext.city ||
      // (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd) ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setTabsValue(0)
      setIsError(true)
      setTimeout(() => setIsError(false), 2000)
    } else {
      openDialogConfirm()
    }
  }

  const handleAdd = () => {
    const variable = {
      office_code: dataContext && dataContext.office_code,
      office_name: dataContext && dataContext.office_name,
      address: dataContext && dataContext.address,
      address_field: {
        lat:
          dataContext &&
          dataContext.address_field &&
          dataContext.address_field.lat,
        lng:
          dataContext &&
          dataContext.address_field &&
          dataContext.address_field.lng,
        additional_info:
          dataContext &&
          dataContext.address_field &&
          dataContext.address_field.additional_info,
      },
      postal_code: parseInt(dataContext && dataContext.postal_code),
      province: dataContext && dataContext.province,
      city: dataContext && dataContext.city,
      npwp_number: dataContext && dataContext.npwp_number,
      npwp_regdate: dataContext && dataContext.npwp_regdate,
      npwp_responsible_name: dataContext && dataContext.npwp_responsible_name,
      // bpjs_kes_regno: dataContext && dataContext.bpjs_kes_regno,
      // bpjs_tk_regno: dataContext && dataContext.bpjs_tk_regno,
      status: dataContext && dataContext.status,
    }
    addOffice({variables: {objects: variable}})
      .then(() => {
        enqueueSnackbar(`Success Add data`, {variant: 'success'})
        history.push({
          pathname: '/company/office',
          state: {active: 'office', searchable: true},
        })
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
        })
      })
  }

  const handleEdit = () => {
    const variable = {
      id: dataOffice && dataOffice.id,
      office_code: dataContext && dataContext.office_code,
      office_name: dataContext && dataContext.office_name,
      address: dataContext && dataContext.address,
      address_field: {
        lat:
          dataContext &&
          dataContext.address_field &&
          dataContext.address_field.lat,
        lng:
          dataContext &&
          dataContext.address_field &&
          dataContext.address_field.lng,
        additional_info:
          dataContext &&
          dataContext.address_field &&
          dataContext.address_field.additional_info,
      },
      postal_code: parseInt(dataContext && dataContext.postal_code),
      province: dataContext && dataContext.province,
      city: dataContext && dataContext.city,
      npwp_number: dataContext && dataContext.npwp_number,
      npwp_regdate: dataContext && dataContext.npwp_regdate,
      npwp_responsible_name: dataContext && dataContext.npwp_responsible_name,
      status: dataContext && dataContext.status,
      // bpjs_kes_regno: dataContext && dataContext.bpjs_kes_regno,
      // bpjs_tk_regno: dataContext && dataContext.bpjs_tk_regno,
    }
    editOffice({variables: variable})
      .then(() => {
        enqueueSnackbar(`Success Edit data`, {variant: 'success'})
        history.push({
          pathname: '/company/office',
          state: {active: 'office', searchable: true},
        })
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
        })
      })
  }

  useEffect(() => {
    if (dataOffice) {
      setIsEdit(true)
      setDataContext({
        office_code: dataOffice && dataOffice.office_code,
        office_name: dataOffice && dataOffice.office_name,
        address: dataOffice && dataOffice.address,
        address_field: {
          lat:
            dataOffice &&
            dataOffice.address_field &&
            dataOffice.address_field.lat,
          lng:
            dataOffice &&
            dataOffice.address_field &&
            dataOffice.address_field.lng,
          additional_info:
            dataOffice &&
            dataOffice.address_field &&
            dataOffice.address_field.additional_info,
        },
        postal_code: dataOffice && dataOffice.postal_code,
        province:
          dataOffice &&
          dataOffice.global_province &&
          dataOffice.global_province.id,

        provinceLabel:
          dataOffice &&
          dataOffice.global_province &&
          dataOffice.global_province.name,
        city: dataOffice && dataOffice.global_city && dataOffice.global_city.id,
        cityLabel:
          dataOffice && dataOffice.global_city && dataOffice.global_city.name,
        npwp_number: dataOffice && dataOffice.npwp_number,
        npwp_regdate: dataOffice && dataOffice.npwp_regdate,
        npwp_responsible_name: dataOffice && dataOffice.npwp_responsible_name,
        // bpjs_kes_regno: dataOffice && dataOffice.bpjs_kes_regno,
        // bpjs_tk_regno: dataOffice && dataOffice.bpjs_tk_regno,
        status: dataOffice && dataOffice.status,
      })
      if (dataOffice.status === 'ACTIVE') {
        setSwitchChecked(true)
      }
    } else {
      setDataContext({
        office_code: '',
        office_name: '',
        address: '',
        postal_code: '',
        npwp_number: '',
        npwp_regdate: null,
        npwp_responsible_name: '',
        // bpjs_kes_regno: '',
        // bpjs_tk_regno: '',
        status: 'ACTIVE',
      })
    }
  }, [isEdit, dataOffice])

  return (
    <OrganizationContainer>
      <PaperContainer
        className={classNames(classes.heightAuto, classes.overflowYNone)}
      >
        <FormToolbar disableGutters>
          <FormTitleContainer>
            <Link
              to={{
                pathname: '/company/office',
                state: {active: 'office', searchable: true},
              }}
            >
              <IconButton edge="end" aria-label="back">
                <ArrowBack />
              </IconButton>
            </Link>
            <AddEditTitle variant="h6" display="inline">
              {isEdit ? 'Edit' : 'Add New'} Office
            </AddEditTitle>
          </FormTitleContainer>
          <div>
            <Button
              size="large"
              onClick={handleAlert}
              style={{marginRight: 12}}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              size="large"
            >
              Save
            </Button>
          </div>
        </FormToolbar>
        <OrganizationContainer>
          <TabsStyled value={tabsValue} onChange={handleChange}>
            <TabStyled label="Office Data" />
            {/* <TabStyled label="Tax and Insurance Data" /> */}
          </TabsStyled>
          {tabsValue === 0 && (
            <TabOfficeData
              setAllData={setDataContext}
              allData={dataContext}
              dataEdit={dataOffice}
              dataMain={dataMain}
              checkSameCodeWithAdd={checkSameCodeWithAdd}
              // checkSameNameWithAdd={checkSameNameWithAdd}
              isError={isError}
              codeChecker={codeChecker}
              nameChecker={nameChecker}
              switchChecked={switchChecked}
              setSwitchChecked={setSwitchChecked}
            />
          )}
          {/* {tabsValue === 1 && (
            <TabTaxInsuranceData
              setAllData={setDataContext}
              allData={dataContext}
              isError={isError}
            />
          )} */}
        </OrganizationContainer>
      </PaperContainer>

      {hasJobProfile ? (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type="Confirm"
          applied={dataOffice && dataOffice.company_job_profiles}
          mutation={isEdit ? handleEdit : handleAdd}
        />
      ) : (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type={isEdit ? 'Edit' : 'Add'}
          name={dataContext && dataContext.office_name}
          feature="Office"
          mutation={isEdit ? handleEdit : handleAdd}
        />
      )}
      <AlertDialog
        open={dialogCancel}
        handleClose={handleAlert}
        feature={`Cancel to ${dataOffice ? 'Edit' : 'Add New'} Office?`}
        message={`You have unsaved changes. Are you sure want to cancel ${
          dataOffice ? `edit ${dataOffice.office_name}` : 'add new'
        }?`}
        type="Yes, Cancel"
        status="notNow"
      />
    </OrganizationContainer>
  )
}

export default withRouter(AddEditOfficeList)
