import React, {useState} from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
  // TextField,
} from '@material-ui/core'
import {PrimaryModalHeader, TextHelper} from '../../../../GlobalStyles'
import {makeStyles} from '@material-ui/styles'
import {UPDATE_COMPANY_PROFILE} from '../../../../graphql/mutations'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {withRouter} from 'react-router-dom'
import {COMPANY_ID} from '../../../../utils/globals'
import classNames from 'classnames'

import CKEditor from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'

const editorConfiguration = {
  toolbar: [''],
}

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
  },
  ckeditorHideTitle: {
    '& .ck-toolbar': {
      display: 'none !important',
    },
  },
  checkbox: {padding: 0, marginRight: 10},
  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnImport: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  fullWidth: {
    width: '100% !important',
  },
  actions: {paddingRight: 24, paddingBottom: 24},
}))

const EditDescription = ({data, open, handleClose, reload, feature}) => {
  const res = data && data.company_profiles[0]
  const profile_fields = res && res.profile_field
  const description = profile_fields && res.profile_field.description
  const classes = useStyles()
  const [desc, setDesc] = useState(description)
  const [isError, setIsError] = useState(false)
  const {enqueueSnackbar} = useSnackbar()

  const [updateCompanyProfile] = useMutation(UPDATE_COMPANY_PROFILE, {
    context: {
      headers: {
        'X-Hasura-Role':
          feature === 'holding-profile'
            ? 'holding-administrator'
            : 'organization-hr-admin',
      },
    },
  })

  const updateComPro = () => {
    if (!desc) {
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const profile_field = {
        banner: profile_fields && res.profile_field.banner,
        founded: profile_fields && res.profile_field.founded,
        website: profile_fields && res.profile_field.website,
        headline: profile_fields && res.profile_field.headline,
        linkedin: profile_fields && res.profile_field.linkedin,
        facebook: profile_fields && res.profile_field.facebook,
        twitter: profile_fields && res.profile_field.twitter,
        schedule: profile_fields && res.profile_field.schedule,
        description: desc,
        specialities: profile_fields && res.profile_field.specialities,
      }

      const descriptions = {
        id: COMPANY_ID,
        legal_name: res && res.legal_name,
        brand_name: res && res.brand_name,
        logo: res && res.logo,
        code_phone: res && res.country_code,
        phone: res && res.phone,
        address: res && res.main_office && res.main_office.id,
        industry_type:
          res &&
          res.global_company_industry_type &&
          res.global_company_industry_type.id,
        legal_type: res && res.company_legal_type && res.company_legal_type.id,
        profile_field: profile_field,
      }

      updateCompanyProfile({variables: descriptions})
        .then(() => {
          enqueueSnackbar(`Success Update data`, {
            variant: 'success',
          })
          handleClose()
          reload()
        })
        .catch(() => {
          enqueueSnackbar('Update data error, please try again', {
            variant: 'error',
          })
        })
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <PrimaryModalHeader>
        <Typography variant="h6" style={{color: '#fff'}}>
          Description
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon style={{color: '#fff'}}>close</Icon>
        </IconButton>
      </PrimaryModalHeader>
      <DialogContent
        className={classNames(classes.content, classes.ckeditorHideTitle)}
      >
        <CKEditor
          editor={DecoupledEditor}
          config={editorConfiguration}
          onInit={editor => {
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
              )
          }}
          data={description}
          // eslint-disable-next-line no-unused-vars
          onChange={(event, editor) => {
            const data = editor.getData()
            setDesc(data)
          }}
        />
        <TextHelper>
          {!desc && isError ? 'This field is required' : ''}
        </TextHelper>
        {/* <TextField
          id="outlined-multiline-static"
          multiline
          className={classes.fullWidth}
          rows={10}
          onChange={event => setDesc(event.target.value)}
          defaultValue={description}
          error={!desc && isError}
          helperText={!desc && isError ? 'This field is required' : ''}
          variant="outlined"
        /> */}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          className={classes.btnImport}
          variant="contained"
          onClick={() => updateComPro()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withRouter(EditDescription)
