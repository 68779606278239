import gql from 'graphql-tag'

export const GET_ALL_ORGANIZATION = gql`
  {
    company_organizations {
      id
      name
    }
  }
`
