import gql from 'graphql-tag'

// Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/version/EDIT%20VERSION.md
export const EDIT_COMPETENCY_VERSION = gql`
  mutation(
    $id: Int!
    $code: String
    $name: String
    $date_start: date
    $date_end: date
    $status: String
  ) {
    update_status: update_competency_versions(
      where: {status: {_eq: $status}}
      _set: {status: "INACTIVE"}
    ) {
      affected_rows
    }
    update_competency_versions(
      where: {id: {_eq: $id}}
      _set: {
        code: $code
        name: $name
        date_start: $date_start
        date_end: $date_end
        status: $status
      }
    ) {
      affected_rows
    }
  }
`
