import React, {useState} from 'react'
import {Typography, Divider, Paper} from '@material-ui/core'
import {
  GET_DATA_CURRENT_BADGE,
  GET_DATA_BADGE_SUMMARY,
} from '../../../../graphql/queries'
import {EnhancedToolbar} from '../../../../GlobalStyles'
import {SectionDetailContainer} from '../IndividualSummaryPageStyle'
import Loading from '../../../../components/loading/LoadingComponent'
import {useQuery} from '@apollo/react-hooks'
import NoBadge from '../../../../assets/images/no_badge.png'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import {parameters_versions} from '../../../../utils/helpers'

const BadgesDetailComponent = ({idUser}) => {
  const [more, setMore] = useState(5)
  const {data: dataCurrentBadges, error: errorCurrentBadge} = useQuery(
    GET_DATA_CURRENT_BADGE,
    {
      wlb_skipPatch: true,
      variables: {
        user: idUser,
      },
    }
  )

  const {data, error, loading} = useQuery(GET_DATA_BADGE_SUMMARY, {
    wlb_skipPatch: true,
    variables: {
      user: idUser,
      offset: 0,
      limit: more,
    },
  })

  if (
    (data === undefined && error === undefined) ||
    (dataCurrentBadges === undefined && errorCurrentBadge === undefined)
  ) {
    return <Loading />
  } else if (error !== undefined || errorCurrentBadge !== undefined) {
    return <div>{JSON.stringify(error || errorCurrentBadge)}</div>
  }

  const currentBadge = dataCurrentBadges?.talent_assignments?.[0]
  const totalAllBadges = data?.total?.aggregate?.count
  const getValueParam = {
    task: currentBadge?.indicator_perf_goal,
    multirater: currentBadge?.indicator_perf_multirater,
    nps: currentBadge?.indicator_perf_nps,
    ogf: currentBadge?.indicator_perf_ogf,
  }

  const seeMore = () => {
    if (more > totalAllBadges) {
      setMore(5)
    } else {
      setMore(prev => prev + 5)
    }
  }

  const renderTextSeeMore = () => {
    let textSeeMore = ''
    if (loading) {
      textSeeMore = 'Loading...'
    } else if (totalAllBadges > 5) {
      textSeeMore =
        more >= totalAllBadges ? 'Lihat lebih sedikit' : 'Lihat lebih banyak'
    }
    return textSeeMore
  }

  return (
    <SectionDetailContainer>
      <Paper className="paper-padding space-section">
        <EnhancedToolbar>
          <Typography variant="h6" color="primary">
            Lencana
          </Typography>
        </EnhancedToolbar>
        <Divider className="divider-margin" />
        <p className="p-type-badge">Lencana Saat Ini</p>
        <div className="info-container">
          <div className="badges-container">
            <img
              src={currentBadge?.talent_box?.badge ?? NoBadge}
              alt="ava"
              width="100"
              height="100"
              className="badges"
            />
            <div className="badges-section">
              <p className="p-title-badge">
                {currentBadge?.talent_box?.name ?? 'Tidak Ada Lencana'}
              </p>
              <p className="p-position">
                {currentBadge?.people_work_placement?.company_job_profile
                  ?.title ?? '-'}
              </p>
              <p className="p-performance">
                {currentBadge?.talentVersionByTalentVersion?.name ?? '-'}
              </p>
              <p className="p-score">
                {currentBadge?.talent_score?.toFixed(2) ?? '-'}
              </p>
            </div>
          </div>
          {currentBadge && (
            <>
              <div className="axis-section">
                <p className="p-axis">X Axis</p>
                <div className="axis-container">
                  {currentBadge?.talentVersionByTalentVersion?.flexy_components?.x?.parameters?.map(
                    (param, key) => {
                      return (
                        <div className="axis-wrapper" key={key}>
                          <p className="p-parameter">
                            {parameters_versions?.[param?.parameter]?.ID ||
                              param?.parameter}
                          </p>
                          <p className="p-value">
                            {getValueParam[param?.parameter]?.toFixed(2) ?? 0}
                          </p>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
              <div className="axis-section">
                <p className="p-axis">Y Axis</p>
                <div className="axis-container">
                  {currentBadge?.talentVersionByTalentVersion?.flexy_components?.y?.parameters?.map(
                    (param, key) => {
                      return (
                        <div className="axis-wrapper" key={key}>
                          <p className="p-parameter">
                            {parameters_versions?.[param?.parameter]?.ID ||
                              param?.parameter}
                          </p>
                          <p className="p-value">
                            {getValueParam[param?.parameter]?.toFixed(2) ?? 0}
                          </p>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <Divider className="divider-margin" />
        <p className="p-type-badge">Semua Lencana</p>
        {data?.talent_assignments?.map((allBadge, keyAll) => {
          return (
            <div className="info-container" key={keyAll}>
              <div className="badges-container">
                <img
                  src={allBadge?.talent_box?.badge ?? NoBadge}
                  alt="ava"
                  width="100"
                  height="100"
                  className="badges"
                />
                <div className="badges-section">
                  <p className="p-title-badge">
                    {allBadge?.talent_box?.name ?? 'No Badge'}
                  </p>
                  <p className="p-position">
                    {allBadge?.people_work_placement?.company_job_profile
                      ?.title ?? '-'}
                  </p>
                  <p className="p-performance">
                    {allBadge?.talentVersionByTalentVersion?.name ?? '-'}
                  </p>
                  <p className="p-score">
                    {allBadge?.talent_score?.toFixed(2) ?? '-'}
                  </p>
                </div>
              </div>
              <div className="axis-section">
                <p className="p-axis">X Axis</p>
                <div className="axis-container">
                  {allBadge?.talentVersionByTalentVersion?.flexy_components?.x?.parameters?.map(
                    (param, key) => {
                      return (
                        <div className="axis-wrapper" key={key}>
                          <p className="p-parameter">
                            {parameters_versions?.[param?.parameter]?.ID ||
                              param?.parameter}
                          </p>
                          <p className="p-value">
                            {getValueParam[param?.parameter]?.toFixed(2) ?? 0}
                          </p>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
              <div className="axis-section">
                <p className="p-axis">Y Axis</p>
                <div className="axis-container">
                  {allBadge?.talentVersionByTalentVersion?.flexy_components?.y?.parameters?.map(
                    (param, key) => {
                      return (
                        <div className="axis-wrapper" key={key}>
                          <p className="p-parameter">
                            {parameters_versions?.[param?.parameter]?.ID ||
                              param?.parameter}
                          </p>
                          <p className="p-value">
                            {getValueParam[param?.parameter]?.toFixed(2) ?? 0}
                          </p>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </div>
          )
        })}
        <p className="p-see-more" onClick={seeMore}>
          {renderTextSeeMore()}
        </p>
        {data.talent_assignments.length === 0 && (
          <NoDataListComponent
            title="Maaf Tidak Ada Daftar"
            message="Belum ada lencana"
          />
        )}
      </Paper>
    </SectionDetailContainer>
  )
}

export default BadgesDetailComponent
