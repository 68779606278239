import {NoConnection} from '@worklifebeyond/wlb-utils-components'
import React from 'react'
import history from '../../utils/history'
import {CenterWrapper} from './ErrorStyles'

export default function NoConnectionComponent() {
  return (
    <CenterWrapper>
      <NoConnection onRefresh={history.back} />
    </CenterWrapper>
  )
}
