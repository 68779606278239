export const calcMethods = [
  {
    name: 'Standard',
    value: 'standard',
  },
  {
    name: 'Monthly Accrual',
    value: 'accrual',
  },
]

export const months = [
  {
    name: 'January',
    value: 1,
  },
  {
    name: 'February',
    value: 2,
  },
  {
    name: 'March',
    value: 3,
  },
  {
    name: 'April',
    value: 4,
  },
  {
    name: 'Mei',
    value: 5,
  },
  {
    name: 'June',
    value: 6,
  },
  {
    name: 'July',
    value: 7,
  },
  {
    name: 'August',
    value: 8,
  },
  {
    name: 'September',
    value: 9,
  },
  {
    name: 'October',
    value: 10,
  },
  {
    name: 'November',
    value: 11,
  },
  {
    name: 'December',
    value: 12,
  },
]

export const leftOverValidOptions = [
  {
    name: 'Next 3 months',
    value: 3,
  },
  {
    name: 'Next 6 months',
    value: 6,
  },
  {
    name: 'Next 1 year',
    value: 12,
  },
]

export const quotaStartOptions = [
  {
    name: 'Since joined',
    value: 0,
  },
  {
    name: '1 month from joined',
    value: 1,
  },
  {
    name: '3 months from joined',
    value: 3,
  },
  {
    name: '6 months from joined',
    value: 6,
  },
  {
    name: '1 year from joined',
    value: 12,
  },
  {
    name: '3 year from joined',
    value: 36,
  },
  {
    name: '6 year from joined',
    value: 72,
  },
  {
    name: '7 year from joined',
    value: 84,
  },
  {
    name: '8 year from joined',
    value: 96,
  },
]

export const quotaRenewOptions = [
  {
    name: '1 January',
    value: 1,
  },
  {
    name: "Staff's joined date",
    value: 2,
  },
  {
    name: 'Particular date',
    value: 3,
  },
]

export const selectStyle = isError => ({
  container: base => ({...base, marginTop: 10}),
  control: base => ({...base, ...(isError && {borderColor: '#f44336'})}),
})
