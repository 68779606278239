import {format} from 'date-fns'
import moment from 'moment'
import {timeZones} from './work-schedule/Constants'

export const convertTimezone = ({
  date,
  timezone = null,
  timezoneName = null,
}) => {
  const convertTZ = (date, tzString) => {
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        {timeZone: tzString}
      )
    )
  }
  if (date) {
    if (timezone) {
      return convertTZ(date, timezone)
    } else if (!timezone && timezoneName) {
      const selectedTimezone = timeZones.filter(
        zone => zone.value === timezoneName
      )
      const _timezone =
        (selectedTimezone &&
          selectedTimezone[0] &&
          selectedTimezone[0].utc[0]) ||
        ''
      return convertTZ(date, _timezone)
    } else return date
  } else return date
}

export const getTimeInOut = (datetime, timezoneName, format = {}) => {
  if (datetime) {
    const dateConverted = convertTimezone({
      date: datetime,
      timezoneName: timezoneName,
    })
    const selectedDate = moment(dateConverted)
    return selectedDate.format(format.dateFormat || 'll')
  } else return '-'
}

export const getInOutDateDetail = (data, time, format) => {
  const setting =
    (data.time_attendance_policy &&
      data.time_attendance_policy.mobile_setting) ||
    {}
  return getTimeInOut(time, setting.timezoneName, {
    dateFormat: format,
  })
}

export const getGMT = data => {
  const policy = data.time_attendance_policy
  const tz = policy?.mobile_setting?.timezone

  return tz ? (tz > 0 ? `GMT+${tz}` : `GMT${tz}`) : 'GMT+7'
}

export const generateDay = date => {
  if (date) {
    return format(new Date(date), 'cccc')
  } else {
    return ''
  }
}

export const getDay = date => {
  return generateDay(date && date.clock_in)
}
