import React from 'react'
import {Checkbox, FormControlLabel} from '@material-ui/core'

import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TextHelper,
  TitleName,
  TitleNumber,
} from '../../GlobalStyles'
import FieldInformationTooltip from '../../pages/shared-components/tooltip/FieldInformationTooltip'

export default function CheckboxInputGroup(props) {
  const {
    dataForm,
    setDataForm,
    defaultValue,
    checkboxLabel = '',
    errorText,
    fieldLabel,
    fieldNumber,
    fieldName,
    isRequired = true,
    labelPlacement = 'end',
    showErrorHelper = () => false,
    tooltipMesssage,
    ...restProps
  } = props

  return (
    <FormChildContainer>
      <FormChildTitle>
        <TitleNumber>{fieldNumber}.</TitleNumber>
        <TitleName>{fieldLabel}</TitleName>
        {tooltipMesssage && <FieldInformationTooltip title={tooltipMesssage} />}
      </FormChildTitle>

      <FormChildInput>
        <FormControlLabel
          label={checkboxLabel}
          labelPlacement={labelPlacement}
          name={fieldName}
          control={
            <Checkbox
              color="primary"
              defaultChecked={defaultValue}
              onChange={event => {
                setDataForm({
                  ...dataForm,
                  [fieldName]: event.target.checked,
                })
              }}
              {...restProps}
            />
          }
        />

        {showErrorHelper() && (
          <TextHelper style={{margin: 0}}>
            {isRequired && 'This field is required'}
            {errorText && errorText}
          </TextHelper>
        )}
      </FormChildInput>
    </FormChildContainer>
  )
}
