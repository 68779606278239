import IndividualSummaryPage from './IndividualSummaryPageComponent'

export const IndividualSummaryPageConfig = {
  routes: [
    {
      path: '/performance/individual-summary',
      component: IndividualSummaryPage,
      state: 'individual-summary',
      exact: true,
    },
    {
      path: '/performance/individual-summary/detail/:idPlacement/:idUser',
      component: IndividualSummaryPage,
      state: 'individual-summary-detail',
      exact: true,
    },
  ],
}
