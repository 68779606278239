import gql from 'graphql-tag'

export const GET_LIST_TRIP_COMPONENT = gql`
  query($company: uuid, $search: String, $offset: Int, $limit: Int) {
    total: business_trip_component_aggregate(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        name: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
    business_trip_component(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        name: {_ilike: $search}
      }
      order_by: {name: asc}
      limit: $limit
      offset: $offset
    ) {
      id
      name
      business_trip_component_type {
        name
        id
      }
      description
    }
  }
`

export const GET_LIST_TRIP_POLICY = gql`
  query($company: uuid, $search: String, $offset: Int, $limit: Int) {
    total: business_trip_policies_aggregate(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        name: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
    business_trip_policies(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        name: {_ilike: $search}
      }
      order_by: {name: asc}
      limit: $limit
      offset: $offset
    ) {
      id
      name
      description
      destination_type
      budget_component_type
      currency_to
      currency_from
      agg_pwbt: people_work_business_trips_aggregate(
        where: {
          status: {_in: ["pending", "confirm1", "confirm2", "processing"]}
        }
      ) {
        aggregate {
          count_requests: count
        }
      }
      confirm_type
      companyJobProfileByFirstConfirmProfile {
        id
        title
      }
      companyJobProfileBySecondConfirmProfile {
        id
        title
      }
      companyJobProfileByThirdConfirmProfile {
        id
        title
      }
      filter_position
      company_job_profile_r_business_trips {
        company_job_profile {
          id
          title
        }
      }
      business_trip_policy_destinations {
        global_destination {
          id
          name
        }
      }
      v_business_trip_budget_totals {
        name
        nominal
      }
    }
  }
`

export const GET_TRIP_ACTIVITY = gql`
  query(
    $where: people_work_business_trips_bool_exp!
    $offset: Int
    $limit: Int
  ) {
    total: people_work_business_trips_aggregate(where: $where) {
      aggregate {
        count
      }
    }

    trips: people_work_business_trips(
      where: $where
      order_by: [{date_added: desc}, {id: desc}]
      offset: $offset
      limit: $limit
    ) {
      id
      id_display
      date_start
      date_end
      date_added
      status
      global_user {
        id
        avatar
        name
      }
      people_work_placement {
        id
        regno
        company_job_profile {
          id
          title
        }
        company_profile {
          id
          legal_name
        }
      }
    }
  }
`

export const GET_LIST_TYPE_TRIP = gql`
  query getBusinesstripType {
    business_trip_component_type(where: {deletedAt: {_is_null: true}}) {
      id
      name
    }
  }
`

export const GET_LIST_POSITION_BUSINNES_TRIP = gql`
  query($search: String, $limit: Int, $id: Int) {
    company_job_profiles(
      where: {
        _or: [
          {title: {_ilike: $search}}
          {code: {_ilike: $search}}
          {job_profile_supervisor: {title: {_ilike: $search}}}
          {company_organization: {name: {_ilike: $search}}}
        ]
      }
      limit: $limit
    ) {
      id
      title
    }
  }
`

export const GET_LIST_DESTINATION = gql`
  query DestinationSelected(
    $destinations: Int_comparison_exp
    $search: String
    $limit: Int
    $offset: Int
  ) {
    totalAllDestination: global_destination_aggregate(
      where: {id: $destinations}
    ) {
      aggregate {
        count
      }
    }

    totalAllDestinationwithSearch: global_destination_aggregate(
      where: {
        id: $destinations
        _or: [
          {name: {_ilike: $search}}
          {global_country: {country_name: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    global_destination(
      where: {
        id: $destinations
        _or: [
          {name: {_ilike: $search}}
          {global_country: {country_name: {_ilike: $search}}}
        ]
      }
      limit: $limit
      offset: $offset
      order_by: {name: asc}
    ) {
      id
      name
      type
      global_country {
        id
        country_name
      }
    }
  }
`

export const GET_LIST_COMPONENT = gql`
  query getListBusinessTripComponet($company: uuid) {
    business_trip_component(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      id
      name
      business_trip_component_type {
        id
        name
      }
      description
    }
  }
`

export const GET_ACTIVITY_BY_ID = gql`
  query getDetail($id: Int!) {
    people_work_business_trips_by_pk(id: $id) {
      id
      id_display
      globalUserByAddedBy {
        id
        name
      }
      peopleWorkPlacementByAddedByWorkId {
        id
        regno
        company_job_profile {
          id
          title
        }
      }
      currency
      date_added
      approved_date
      status
      business_trip_policy {
        id
        name
        confirm_type
        currency_from
        companyJobProfileByFirstConfirmProfile {
          id
          title
          people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
            id
            global_user {
              id
              name
            }
          }
          spv_2: job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              id
              global_user {
                id
                name
              }
            }
            spv_3: job_profile_supervisor {
              id
              title
              people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                global_user {
                  id
                  name
                }
              }
            }
          }
        }
        companyJobProfileBySecondConfirmProfile {
          id
          title
          people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
            id
            global_user {
              id
              name
            }
          }
          spv_2: job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              id
              global_user {
                id
                name
              }
            }
            spv_3: job_profile_supervisor {
              id
              title
              people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                global_user {
                  id
                  name
                }
              }
            }
          }
        }
        companyJobProfileByThirdConfirmProfile {
          id
          title
          people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
            id
            global_user {
              id
              name
            }
          }
          spv_2: job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              id
              global_user {
                id
                name
              }
            }
            spv_3: job_profile_supervisor {
              id
              title
              people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                global_user {
                  id
                  name
                }
              }
            }
          }
        }
      }
      participant_name: global_user {
        id
        name
      }
      participant_position: people_work_placement {
        id
        regno
        company_profile {
          id
          legal_name
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              id
              global_user {
                id
                name
              }
            }
            spv_2: job_profile_supervisor {
              id
              title
              people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                global_user {
                  id
                  name
                }
              }
              spv_3: job_profile_supervisor {
                id
                title
                people_work_placements(
                  where: {status: {_eq: "ACTIVE"}}
                  limit: 1
                ) {
                  id
                  global_user {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      description
      attachment
      date_start
      date_end
      globalUserByCancelledBy {
        id
        name
        people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
      globalUserByApprovedBy {
        id
        name
        people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
      business_trip_fields
      destination: people_work_business_trip_destinations(
        order_by: [{destination_start_date: asc}, {id: asc}]
      ) {
        id
        globalDestinationByFrom {
          id
          name
        }
        globalDestinationByTo {
          id
          name
        }
        last_modified
        globalUserByUpdatedBy {
          id
          name
        }
        duration
        destination_start_date
        destination_end_date
        budget: people_work_business_trip_destination_budgets(
          order_by: {business_trip_component: {name: asc}}
        ) {
          id
          business_trip_component {
            id
            name
          }
          nominal
          payment_type
          status
          budget_calculation
          people_profile_bank {
            id
            bank_name
            account_number
            account_name
          }
          attachment
        }
      }
    }
  }
`

export const GET_BANK_TRIP = gql`
  query getListBank($user: uuid) {
    people_profile_banks(
      where: {user: {_eq: $user}, deletedAt: {_is_null: true}}
    ) {
      id
      bank_name
      account_name
      account_number
    }
  }
`

export const GET_CONFIRMED_TRIP_PAYMENTS = gql`
  query checkerButtonPaymentConfirm($id: Int!) {
    people_work_business_trip_destination_budgets(
      where: {
        people_work_business_trip_destination: {
          people_work_business_trip: {id: {_eq: $id}}
        }
      }
    ) {
      id
      status
    }
  }
`
export const GET_BUDGET_EDIT = gql`
  query getDestinationBudget($id: Int, $filter: [Int!]!) {
    business_trip_policy_destinations(
      where: {
        policy: {_eq: $id}
        _or: [
          {global_destination: {id: {_in: $filter}}}
          {city: {_is_null: true}}
        ]
      }
      order_by: {global_destination: {name: asc}}
    ) {
      global_destination {
        id
        name
      }
      business_trip_policy_destination_budgets(
        where: {business_trip_component: {deletedAt: {_is_null: true}}}
      ) {
        id
        nominal
        budget_calculation
        business_trip_component {
          id
          name
          description
        }
      }
    }
  }
`

export const GET_TRIP_REPORTS = gql`
  query(
    $where: people_work_business_trips_bool_exp!
    $offset: Int
    $limit: Int
  ) {
    total: people_work_business_trips_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    reports: people_work_business_trips(
      where: $where
      order_by: [{date_start: desc}, {id: desc}]
      offset: $offset
      limit: $limit
    ) {
      id
      id_display
      date_start
      date_end
      date_added
      status
      global_user {
        avatar
        name
      }
      people_work_placement {
        regno
        company_job_profile {
          title
        }
        company_profile {
          legal_name
        }
      }
    }
  }
`

export const GET_REPORT_TRIP_BY_ID = gql`
  query($id: Int!) {
    people_work_business_trips_by_pk(id: $id) {
      id
      id_display
      peopleWorkPlacementByAddedByWorkId {
        id
        global_user {
          id
          name
        }
        company_job_profile {
          id
          title
        }
      }

      currency
      approved_date
      date_added
      date_start
      date_end
      people_work_placement {
        id
        regno
        global_user {
          id
          name
        }
        company_job_profile {
          id
          title
        }
      }

      description
      attachment

      business_trip_policy {
        id
        name
        currency_from
      }

      destination: people_work_business_trip_destinations(
        order_by: [{destination_start_date: asc}, {id: asc}]
      ) {
        id
        duration
        destination_start_date
        destination_end_date
        globalDestinationByFrom {
          id
          name
        }
        globalDestinationByTo {
          id
          name
        }
        budget: people_work_business_trip_destination_budgets(
          order_by: {business_trip_component: {name: asc}}
        ) {
          id
          business_trip_component {
            id
            name
            type
            description
            business_trip_component_type {
              name
              id
            }
          }
          payment_type
          people_profile_bank {
            id
            bank_name
            account_number
            account_name
          }
          nominal
          realization
          budget_calculation
          attachment: report_attachments
        }
      }
      budget_diff_action
      status
      report_status
    }
  }
`
export const GET_CHECK_DELETE_COMPONENT = gql`
  query checkerComponentWithPolicy($id: Int) {
    business_trip_component(where: {id: {_eq: $id}}) {
      business_trip_policy_destination_budgets_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`
export const GET_TOTAL_EXIST_PILICY_NAME = gql`
  query($name: String) {
    business_trip_policies_aggregate(
      where: {name: {_eq: $name}, deletedAt: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
  }
`
