import gql from 'graphql-tag'

export const GET_TOP_ORG_UNIT_STRUCTURE = gql`
  query($company: uuid) {
    top: company_organizations(
      where: {parent: {_is_null: true}, company: {_eq: $company}}
    ) {
      id
      name
      as_assistant
      line_type
      organization_level {
        id
        name
        color_hex
      }
      company_job_profiles(where: {parent: {_is_null: true}}) {
        id
        title
        people_work_placements(
          where: {
            status: {_eq: "ACTIVE"}
            global_user: {status: {_eq: "ACTIVE"}}
          }
        ) {
          id
          global_user {
            id
            name
            avatar
          }
        }
      }
      organization_children_aggregate(limit: 1) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_CHILD_ORG_UNIT_STRUCTURE = gql`
  query($parent: Int) {
    company_organizations(where: {parent: {_eq: $parent}}) {
      id
      name
      parent
      as_assistant
      line_type
      organization_level {
        id
        name
        color_hex
      }
      company_job_profiles(
        where: {job_profile_supervisor: {organization: {_eq: $parent}}}
      ) {
        id
        title
        people_work_placements(
          where: {
            status: {_eq: "ACTIVE"}
            global_user: {status: {_eq: "ACTIVE"}}
          }
        ) {
          id
          regno
          global_user {
            id
            name
            avatar
          }
        }
      }
      organization_children_aggregate(limit: 1) {
        aggregate {
          count
        }
      }
    }
  }
`
