import gql from 'graphql-tag'

export const GET_ATTENDANCE_POLICY = gql`
  query($search: String, $company: uuid, $offset: Int, $limit: Int) {
    total: time_attendance_policies_aggregate(
      where: {name: {_ilike: $search}, company: {_eq: $company}}
    ) {
      aggregate {
        count
      }
    }
    time_attendance_policies(
      where: {name: {_ilike: $search}, company: {_eq: $company}}
      offset: $offset
      limit: $limit
      order_by: [{id: asc}]
    ) {
      id
      name
      type
      schedule
      flexi
      late_tolerance
      location_option
      description
      mobile_setting
      filter_position
      time_attendance_policy_locations {
        time_attendance_location {
          id
          name
          location
        }
      }
      company_job_profile_r_attendances(
        limit: 20
        where: {company_job_profile: {deletedAt: {_is_null: true}}}
      ) {
        company_job_profile {
          id
          title
          code
        }
      }
      total_applied_to: company_job_profile_r_attendances_aggregate(
        where: {company_job_profile: {deletedAt: {_is_null: true}}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_APPLIED_LIST = gql`
  query($search: String, $id: Int, $offset: Int, $limit: Int) {
    time_attendance_policies(where: {id: {_eq: $id}}) {
      id
      company_job_profile_r_attendances(
        limit: $limit
        offset: $offset
        where: {
          company_job_profile: {
            title: {_ilike: $search}
            deletedAt: {_is_null: true}
          }
        }
      ) {
        company_job_profile {
          id
          title
        }
      }
      total_applied_to: company_job_profile_r_attendances_aggregate(
        where: {company_job_profile: {deletedAt: {_is_null: true}}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`
export const GET_ALL_APPLIED_CHILD_LEAVE = gql`
  query($id: Int, $search: String, $offset: Int, $limit: Int) {
    time_leave_policy_children(where: {id: {_eq: $id}}) {
      total: company_job_profile_r_leaves_aggregate(
        where: {company_job_profile: {title: {_ilike: $search}}}
      ) {
        aggregate {
          count
        }
      }
      company_job_profile_r_leaves(
        where: {company_job_profile: {title: {_ilike: $search}}}
        offset: $offset
        limit: $limit
      ) {
        company_job_profile {
          id
          title
        }
      }
    }
  }
`

export const GET_ATT_POLICY_NAME = gql`
  query attPolName($name: String, $ids: [Int!]!) {
    time_attendance_policies(where: {id: {_nin: $ids}, name: {_ilike: $name}}) {
      id
      name
    }
  }
`
