import React, {useState} from 'react'
import {
  Button,
  makeStyles,
  IconButton,
  Icon,
  ExpansionPanelDetails,
  // Checkbox,
  Typography,
} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'

import {
  newTableStyles,
  NewTableOptions,
} from '../../../../shared-components/table/TableOptions'

import {
  SkyBlueIcon,
  StyledExpansion,
  StyledExpansionSummary,
} from '../../../../../GlobalStyles'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MaterialTable from 'material-table'
import {GET_HOLDING_CLUSTER_BY_VERSION} from '../../../../../graphql/queries'
import {holdingRolesContext} from '../../../../../utils/helpers'
import {useQuery, useMutation} from '@apollo/react-hooks'
import Loading from '../../../../../components/loading/LoadingComponent'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import {
  DELETE_HOLDING_CLUSTER,
  DELETE_HOLDING_SUBCLUSTER,
} from '../../../../../graphql/mutations'
import {useSnackbar} from 'notistack'

const useStyles = makeStyles(() => ({
  root: {padding: 26},
  action: {display: 'flex', width: '10%', justifyContent: 'flex-end'},
  fieldName: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
  },
  panelDetail: {
    borderTop: '1px solid',
    padding: 24,
    display: 'block',
  },
}))

function ClusterList(props) {
  const classes = useStyles()
  const tableClasses = newTableStyles()

  const {enqueueSnackbar} = useSnackbar()
  const [openDelete, setOpenDelete] = useState(false)
  const [selected, setSelected] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [name, setName] = useState('')
  const [delType, setDelType] = useState('')

  const {data, error, refetch} = useQuery(GET_HOLDING_CLUSTER_BY_VERSION, {
    variables: {version: localStorage.getItem('idVersion')},
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const [deleteCluster] = useMutation(DELETE_HOLDING_CLUSTER(selectedId))
  const [deleteSubcluster] = useMutation(DELETE_HOLDING_SUBCLUSTER(selectedId))

  const handleMutationDelete = () => {
    if (delType === 'cluster') {
      deleteCluster({...holdingRolesContext})
        .then(() => {
          enqueueSnackbar('Data deleted', {
            variant: 'success',
            autoHideDuration: 1500,
          })
          if (selected instanceof Array) {
            setSelected([])
          }
          refetch()
        })
        .catch(e => {
          enqueueSnackbar(
            'Delete failed, please try again later' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    } else {
      deleteSubcluster({...holdingRolesContext})
        .then(() => {
          enqueueSnackbar('Data deleted', {
            variant: 'success',
            autoHideDuration: 1500,
          })
          if (selected instanceof Array) {
            setSelected([])
          }
          refetch()
        })
        .catch(e => {
          enqueueSnackbar(
            'Delete failed, please try again later' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    }
  }

  const handleDelete = (e, id, name, type) => {
    if (type === 'cluster') {
      e.stopPropagation()
    }
    setDelType(type)
    setSelectedId(id)
    setName(name)
    setOpenDelete(true)
  }
  const handleClose = () => {
    setOpenDelete(false)
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to={{
          pathname: '/group/config/add-cluster',
          state: {
            active: 'add-cluster',
          },
        }}
      >
        Add Cluster
      </Button>
      <div style={{marginTop: 34}}>
        {data.holding_clusters &&
          data.holding_clusters.length > 0 &&
          data.holding_clusters.map((res, i) => {
            return (
              <StyledExpansion key={i} elevation={2}>
                <StyledExpansionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{padding: '10px 24px'}}
                >
                  <div className={classes.fieldName}>
                    {/* <Checkbox
                      color="primary"
                      name={res.name}
                      onClick={e => e.stopPropagation()}
                    /> */}
                    <Typography variant="body1" style={{margin: '0 20px'}}>
                      {res.name}
                    </Typography>
                  </div>
                  <div style={{width: '60%'}}>
                    <Typography variant="body1">{res.description}</Typography>
                  </div>
                  <div className={classes.action}>
                    <IconButton
                      onClick={() => {
                        props.history.push({
                          pathname: '/group/config/cluster/' + res.id,
                          state: {
                            active: 'edit-cluster',
                          },
                        })
                      }}
                    >
                      <SkyBlueIcon>edit</SkyBlueIcon>
                    </IconButton>
                    <IconButton
                      onClick={e =>
                        handleDelete(e, res.id, res.name, 'cluster')
                      }
                    >
                      <Icon color="secondary">delete</Icon>
                    </IconButton>
                  </div>
                </StyledExpansionSummary>
                <ExpansionPanelDetails className={classes.panelDetail}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    component={Link}
                    to={{
                      pathname: '/group/config/add-subcluster',
                      state: {
                        active: 'add-subcluster',
                      },
                    }}
                  >
                    Add Subcluster
                  </Button>
                  {res.holding_subclusters.length > 0 && (
                    <div className={tableClasses.greyTableRoot}>
                      <MaterialTable
                        // style={TableStyle}
                        options={NewTableOptions}
                        columns={[
                          {
                            title: 'Select All',
                            field: 'name',
                            cellStyle: {
                              wordBreak: 'break-word',
                              minWidth: 150,
                            },
                          },
                          {
                            title: '',
                            field: 'description',
                            cellStyle: {
                              minWidth: 160,
                            },
                          },

                          {
                            title: '',
                            field: 'action',
                            render: res => (
                              <div style={{display: 'flex'}}>
                                <IconButton
                                  onClick={() => {
                                    props.history.push({
                                      pathname:
                                        '/group/config/subcluster/' + res.id,
                                      state: {
                                        active: 'edit-subcluster',
                                      },
                                    })
                                  }}
                                >
                                  <SkyBlueIcon>edit</SkyBlueIcon>
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleDelete(
                                      null,
                                      res.id,
                                      res.name,
                                      'subcluster'
                                    )
                                  }
                                >
                                  <Icon color="secondary">delete</Icon>
                                </IconButton>
                              </div>
                            ),
                          },
                        ]}
                        components={{
                          Toolbar: () => null,
                        }}
                        title=""
                        data={res.holding_subclusters}
                      />
                    </div>
                  )}
                </ExpansionPanelDetails>
              </StyledExpansion>
            )
          })}
      </div>
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        name={name}
        mutation={handleMutationDelete}
      />
    </div>
  )
}

export default withRouter(ClusterList)
