import React, {useState} from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import {WhiteModalHeader} from '../../../GlobalStyles'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10, color: '#A9A8A8'},

  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnDel: {
    backgroundColor: '#EF4D5E',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#EF4D5E',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {wordBreak: 'break-all'},
  colorCancel: {color: '#A9A8A8'},
}))

export default function DeletePopup(props) {
  const classes = useStyles()
  const {
    open,
    handleClose,
    name,
    mutation,
    feature,
    title,
    setDeleteType,
    deleteType,
    description = '',
    isClose = true,
    isTitle = false,
    isHighlight = true,
  } = props
  const [checked, setChecked] = useState(false)
  const handleChange = e => setChecked(e.target.checked)
  return (
    <Dialog
      onClose={() => {
        handleClose()
        setChecked(false)
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <WhiteModalHeader>
        <Typography variant="h6" color="error" className={classes.wordBreak}>
          Hapus {isTitle ? title : title || name}?
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose('action-cancel')
            setChecked(false)
          }}
        >
          {isClose && <Icon>close</Icon>}
        </IconButton>
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        {feature === 'calendar' ? (
          <>
            <Typography variant="body2" display="inline">
              By selecting the deletion options below, you agree to permanently
              delete
            </Typography>
            <Typography
              variant="body2"
              color="error"
              display="inline"
              className={classes.wordBreak}
            >
              {` ${name}`}
            </Typography>
            <div className={classes.checkContainer}>
              <RadioGroup
                aria-label="type"
                name="type"
                onChange={event => setDeleteType(event.target.value)}
              >
                <FormControlLabel
                  value="this"
                  control={<Radio />}
                  label="This holiday"
                />
                {/* <FormControlLabel
                  value="following"
                  control={<Radio />}
                  label="This and following holidays"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="All holidays"
                /> */}
              </RadioGroup>
            </div>
          </>
        ) : (
          <>
            {' '}
            <Typography variant="body2" display="inline">
              Apakah anda yakin untuk menghapus
            </Typography>
            <Typography
              variant="body2"
              color={isHighlight ? 'error' : 'initial'}
              display="inline"
              className={classes.wordBreak}
            >
              {` ${name} `}
            </Typography>
            <Typography variant="body2" display="inline">
              secara permanen?
            </Typography>
            <div className={classes.checkContainer}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                color="primary"
                classes={{
                  root: classes.checkbox,
                }}
              />
              <Typography variant="body2">
                {`Saya mengerti bahwa menghapus ${
                  isHighlight ? 'berkas ini' : name
                } bersifat permanen dan tidak dapat dibatalkan. ${description}`}
              </Typography>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          className={classes.colorCancel}
          onClick={() => {
            handleClose('action-cancel')
            setChecked(false)
          }}
        >
          Batalkan
        </Button>
        <Button
          className={classes.btnDel}
          disabled={feature ? !deleteType : !checked}
          variant="contained"
          onClick={() => {
            setChecked(false)
            handleClose('action-delete')
            mutation()
          }}
        >
          Hapus
        </Button>
      </DialogActions>
    </Dialog>
  )
}
