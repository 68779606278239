import React, {useState} from 'react'
import {Button, Popover} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {
  ExpansionPanelStyle,
  ExpansionPanelSummaryStyle,
  ExpansionPanelDetailStyle,
  ItemMenu,
} from '../RegulationsStyles'
import ClauseItem from './ClauseItem'
import {Link} from 'react-router-dom'

export default function RegulationListItem({data, setOpen, clauses}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'more-regulations' : undefined

  return (
    <>
      <ExpansionPanelStyle style={{margin: '0 0 20px'}}>
        <ExpansionPanelSummaryStyle>
          <span>{data.name}</span>
          <MoreHorizIcon
            aria-describedby={id}
            onClick={e => {
              e.stopPropagation()
              setAnchorEl(e.currentTarget)
            }}
          />
        </ExpansionPanelSummaryStyle>
        <ExpansionPanelDetailStyle>
          <Button
            style={{marginBottom: 5, fontSize: 12, width: 100}}
            variant="contained"
            color="primary"
            component={Link}
            to={{
              pathname: '/company/add-clause',
              state: {active: 'add-clause', regulation: data},
            }}
          >
            Add Clause
          </Button>
          {clauses &&
            clauses.map((val, key) => (
              <ClauseItem
                setOpen={setOpen}
                key={key}
                data={val}
                // name={val.name}
                // content={val.content}
              />
            ))}
        </ExpansionPanelDetailStyle>
      </ExpansionPanelStyle>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ItemMenu>
          <Button
            component={Link}
            to={{
              pathname: '/company/edit-regulations',
              state: {active: 'edit-regulations', dataEdit: data},
            }}
            className="item"
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setOpen(e => ({
                ...e,
                delete: true,
                deleteName: data && data.name,
                deleteId: data && data.id,
                model: 'Regulation',
              }))
              setAnchorEl(null)
            }}
            className="item"
          >
            Delete
          </Button>
        </ItemMenu>
      </Popover>
    </>
  )
}
