import React, {useState, useEffect} from 'react'
import {
  // OrganizationContainer,
  BigTextBlackBold,
  WhiteButton,
  WrapperContent,
  TextDarkBlue,
  TextGrey,
} from '../../OrganizationPageStyles'
import {Button} from '@material-ui/core'
import {
  RootContainer,
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import Loading from '../../../../components/loading/LoadingComponent'
import {Link} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import MaterialTable from 'material-table'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {
  GET_JOB_FAMILY_LIST,
  GET_JOB_FAMILY_DETAIL,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
  GET_BALOON_STATUS_BUTTON_QUERY,
} from '../../../../graphql/queries'
import {
  DELETE_JOB_FAMILY_NAME,
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
  IMPORT_DATA,
  CLOSE_BALOON,
} from '../../../../graphql/mutations'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import {COMPANY_ID} from '../../../../utils/globals'
import {disbaledButtonExport} from '../../../../utils/helpers'

import DeletePopup from '../../../shared-components/popup/DeletePopup'
// import DownloadPopup from '../../../shared-components/popup/DownloadPopup'
import DetailDataOrganization from '../../../shared-components/popup/DetailDataOrganization'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import ImportBaloon from '../../../../components/importExcel/ImportBaloon'

const ListJobFamilyName = ({searchText}) => {
  // const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [OpenAlert, setOpenAlert] = useState(false)
  // const [OpenCantDel, setOpenCantDel] = useState(false)

  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )
  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  const {data: getExportBaloon, error: errorGetExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'job_families',
      },
    }
  )
  const {data: getExportDisabled, error: errorGetExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'job_families',
      },
    }
  )

  const [openImport, setImport] = useState(false)
  const [openExport, setExport] = useState(false)
  const [openDetail, setDetail] = useState(false)
  const [dataFamilyName, setDataFamilyName] = useState(false)
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )
  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    refetch: refetchBaloonStatus,
    // loading: loadingGetBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON_QUERY, {
    variables: {
      company: COMPANY_ID,
      table: 'job_families',
    },
  })

  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')

  const [deleteFamilyName] = useMutation(DELETE_JOB_FAMILY_NAME)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })
  const {data, error, refetch} = useQuery(GET_JOB_FAMILY_LIST, {
    variables: {
      search: `%${searchText}%`,
      company: COMPANY_ID,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const [skipQuery, setSkipQuery] = useState(true)
  const [JFDetail, setJFDetail] = useState(null)
  const {loading: JFDetailLoading} = useQuery(GET_JOB_FAMILY_DETAIL, {
    variables: {id: dataFamilyName && dataFamilyName.id},
    skip: skipQuery,
    onCompleted: data => {
      setJFDetail(data)
      setSkipQuery(true)
    },
  })

  /* if detail button clicked, then fetch job family detail */
  useEffect(() => {
    if (openDetail) {
      setSkipQuery(false)
    }
  }, [openDetail])

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (
    error !== undefined ||
    mutationExportError !== undefined ||
    closeBaloonExportError !== undefined ||
    errorGetExportBaloon !== undefined ||
    errorGetExportDisabled !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationImportError !== undefined
  ) {
    return <div>{error}</div>
  }

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  const onDeleteFamilyName = () => {
    const variables = {
      id: dataFamilyName.id,
      companyId: COMPANY_ID,
    }
    deleteFamilyName({variables: variables})
      .then(() => {
        enqueueSnackbar('Job Family deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete Job Family failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleOpenAlert = data => {
    setDataFamilyName(data)
    setOpenAlert(true)
    /* job family has not any dependency */
    // const countJobProfile =
    //   data &&
    //   data.job_functions_aggregate &&
    //   data.job_functions_aggregate.aggregate &&
    // data.job_functions_aggregate.aggregate.count > 0
    // setDataFamilyName(data)
    // countJobProfile ? setOpenCantDel(true) : setOpenAlert(true)
  }

  const handleCloseAlert = () => {
    setOpenAlert(false)
    /* job family has not any dependency */
    // status === 'delete' ? setOpenAlert(false) : setOpenCantDel(false)
  }

  const importMutation = async () => {
    try {
      const response = await mutationImport({
        variables: {
          table: 'job_families',
          file: fileURL,
        },
      })
      // eslint-disable-next-line no-console
      console.log(response)
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const handleClickOpenImport = () => {
    setImport(true)
  }
  const handleCloseImport = () => {
    setImport(false)
  }

  const handleDownload = () => {
    setExport(!openExport)
    if (openExport) {
      mutationExport({
        variables: {
          table: 'job_families',
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleOpenDetail = data => {
    setDataFamilyName(data)
    setDetail(!openDetail)
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  // const defaultExport = [
  //   {
  //     'Job Family Code': '-',
  //     'Job Family': '-',
  //     Description: '-',
  //   },
  // ]

  // const dataForExport = data.job_families.map(res => {
  //   return {
  //     'Job Family Code': res.code ? res.code : '-',
  //     'Job Family': res.name ? res.name : '-',
  //     Description: res.description ? res.description : '-',
  //   }
  // })

  // const dataExport = dataForExport.length > 0 ? dataForExport : defaultExport

  const column = [
    {
      title: 'Kode Rumpun Jabatan',
      field: 'code',
      defaultSort: 'asc',
      headerStyle: {minWidth: 300, maxWidth: 300},
      cellStyle: {minWidth: 300, maxWidth: 300},
      render: rowData => (
        <div style={{wordBreak: 'break-word'}}>{rowData.code}</div>
      ),
    },
    {
      title: 'Nama Rumpun Jabatan',
      field: 'name',
      headerStyle: {minWidth: 500, maxWidth: 500},
      cellStyle: {minWidth: 500, maxWidth: 500},
      render: rowData => (
        <div style={{wordBreak: 'break-word'}}>{rowData.name}</div>
      ),
    },
    // {
    //   title: 'Description',
    //   field: 'description',
    // },
    {
      title: 'Aksi',
      field: 'action',
      render: rowData => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleOpenDetail(rowData)
                  }}
                >
                  Rincian
                </StyledMenuItem>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: `/organization/job-family/edit`,
                    state: {
                      active: 'edit-job-family',
                      status: 'Edit',
                      data: rowData,
                      dataCheck: data,
                    },
                  }}
                  variant="h6"
                >
                  Ubah
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleOpenAlert(rowData)
                  }}
                >
                  Hapus
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>
      ),
    },
  ]

  return (
    <RootContainer>
      <ImportBaloon
        data={getBaloonStatus}
        closeBaloonMutation={closeBaloonMutation}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
        refetchBaloonStatus={refetchBaloonStatus}
      />
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
      />
      <PaperContainer>
        <EnhancedToolbar>
          <BigTextBlackBold>Rumpun Jabatan</BigTextBlackBold>
          <WrapperContent>
            <GenerateTooltip
              title={
                (getExportBaloon &&
                  getExportBaloon.company_export_data_logs &&
                  getExportBaloon.company_export_data_logs.length > 0 &&
                  getExportBaloon.company_export_data_logs[0].status ===
                    'FAILED') ||
                disbaledButtonExport(
                  getExportDisabled &&
                    getExportDisabled.company_export_data_logs &&
                    getExportDisabled.company_export_data_logs[0] &&
                    getExportDisabled.company_export_data_logs[0].date_added
                )
                  ? `Anda hanya dapat menghasilkan data sekali sehari. Untuk mengunduh data gunakan link yang sudah dibuat`
                  : ``
              }
              placement="left"
            >
              <span>
                <WhiteButton
                  variant="contained"
                  size="large"
                  style={{marginRight: 12}}
                  onClick={() => handleDownload()}
                  disabled={
                    (getExportBaloon &&
                      getExportBaloon.company_export_data_logs &&
                      getExportBaloon.company_export_data_logs.length > 0 &&
                      getExportBaloon.company_export_data_logs[0].status ===
                        'FAILED') ||
                    disbaledButtonExport(
                      getExportDisabled &&
                        getExportDisabled.company_export_data_logs &&
                        getExportDisabled.company_export_data_logs[0] &&
                        getExportDisabled.company_export_data_logs[0].date_added
                    )
                  }
                >
                  <TextDarkBlue>Ekspor Data</TextDarkBlue>
                </WhiteButton>
              </span>
            </GenerateTooltip>
            <WhiteButton
              variant="contained"
              size="large"
              style={{marginRight: 12}}
              onClick={() => handleClickOpenImport()}
              disabled={
                getBaloonStatus.company_import_data_logs.length > 0 &&
                getBaloonStatus.company_import_data_logs[0].status === 'WAITING'
              }
            >
              {getBaloonStatus &&
              getBaloonStatus.company_import_data_logs.length > 0 &&
              getBaloonStatus.company_import_data_logs[0].status ===
                'WAITING' ? (
                <TextGrey>Impor dalam proses...</TextGrey>
              ) : (
                <TextDarkBlue>Impor Data</TextDarkBlue>
              )}
            </WhiteButton>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={{
                pathname: `/organization/job-family/add`,
                state: {
                  active: 'add-job-family',
                  status: 'Add',
                  dataCheck: data,
                },
              }}
            >
              Tambah Rumpun Jabatan
            </Button>
          </WrapperContent>
        </EnhancedToolbar>
        <MaterialTable
          columns={column}
          data={data && data.job_families}
          options={{
            ...TableOptions,
            selection: false,
            showTitle: false,
            toolbar: false,
            emptyRowsWhenPaging: false,
          }}
          localization={{
            pagination: TableLocalization.pagination,
            body: {
              emptyDataSourceMessage: (
                <NoDataListComponent search={searchText} />
              ),
            },
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          page={pagination.offset}
          pageSize={pagination.limit}
          totalCount={data.total.aggregate.count}
        />
        <DeletePopup
          open={OpenAlert}
          handleClose={() => handleCloseAlert('delete')}
          id={dataFamilyName.id}
          name={dataFamilyName.name}
          mutation={() => onDeleteFamilyName()}
        />
        {/* <AlertDialog
        open={OpenCantDel}
        handleClose={() => handleCloseAlert('cantDelete')}
        feature={`Tidak dapat menghapus ${dataFamilyName.name}`}
        name={dataFamilyName.name}
        fMessage="Anda tidak dapat menghapus "
        lMessage=" karena digunakan pada posisi."
        type="Confirm"
        positiveTitleButton="Konfirmasi"
      /> */}
        <ImportPopup
          open={openImport}
          position={'Job Family'}
          handleClose={handleCloseImport}
          mutation={importMutation}
          fileTemplate={
            'https://public-media.wlb.co.id/1dc6d763-8ad8-4e8a-8d26-5bb73dd569f2-1602486963025.xlsx'
          }
          isAlreadyImported={isAlreadyImported}
          setIsAlreadyImported={setIsAlreadyImported}
          setImport={setImport}
          fileURL={fileURL}
          setFileURL={setFileURL}
          closeBaloon={closeBaloonMutation}
          errorsImport={
            getBaloonStatus.company_import_data_logs.length > 0 &&
            getBaloonStatus.company_import_data_logs[0].reports &&
            getBaloonStatus.company_import_data_logs[0].reports.failed.length >
              0
              ? getBaloonStatus.company_import_data_logs[0].reports.failed
              : []
          }
        />
        <AlertDialog
          open={openExport}
          handleClose={handleDownload}
          feature="Ekspor data sedang diproses!"
          message="Ekspor data sedang diproses! Kami akan mengirimkan email kepada Anda jika sudah selesai."
          type="Confirm"
          positiveTitleButton="Konfirmasi"
        />
        <DetailDataOrganization
          handleClose={() => setDetail(!openDetail)}
          open={openDetail}
          loading={JFDetailLoading}
          data={
            JFDetail &&
            JFDetail.job_families.length > 0 &&
            JFDetail.job_families[0]
          }
          feature="Job Family"
          isTabs={true}
        />
      </PaperContainer>
    </RootContainer>
  )
}

export default ListJobFamilyName
