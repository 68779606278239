import React from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../../DetailEmployeeStyles'
import FormInput from '../../ShareComponents-detail/FormInput'
import {ButtonSubmit} from '../../../SharedDetailEmployee'

import {
  RADIO_YES,
  RADIO_NO,
  RADIO_BOOL_OPTIONS,
  BLOOD_TYPE_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  WORKING_STATUS_OPTIONS,
  GENDER_OPTIONS,
} from '../data-tab-shared'

import usePrevious from '../../../../../../../hooks/usePrevious'
import {
  capitalize,
  dateFormat,
  differenceDate,
} from '../../../../../../../utils/helpers'
import {isErrorForm} from '../../ShareComponents-detail/helperDetail'
import {
  GET_COUNTRIES,
  GET_DEPENDANT_RELATIONSHIPS,
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
} from '../../../../../../../graphql/queries'
import {
  ADD_PEOPLE_DEPENDENT,
  UPDATE_PEOPLE_DEPENDENT,
} from '../../../../../../../graphql/mutations'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_FORM_DATA = {
  name: '',
  gender: null,
  relationship: null,
  is_beneficiary: false,
  birthplace: null,
  birthdate: null,
  dependent_status: '',
  identity_number: '',
  marital_status: null,
  job_status: null,
  civil_servant: RADIO_NO,
  part_company: RADIO_NO,
  company: '',
  phone_code: null,
  phone_number: null,
  blood_type: null,
  country: null,
  province: null,
  city: null,
  district: '',
  subdistrict: '',
  postal_code: '',
  address: '',
}

const INITIAL_SEARCH_STATE = {
  relation: '',
  country: '',
  province: '',
  city: '',
}

const AddEditDependent = props => {
  const {open = false, userId, initialData, onClose, onSubmit, onDelete} = props

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [formState, setFormState] = React.useState(STATE_INITIAL)
  const [formData, setFormData] = React.useState(INITIAL_FORM_DATA)

  const [search, setSearch] = React.useState(INITIAL_SEARCH_STATE)

  const isEditing = !!initialData

  const isError = formState === STATE_ERROR
  const isDispatching = formState === STATE_DISPATCH

  const prevOpen = usePrevious(open)
  const isNeverOpen = prevOpen === false && prevOpen === open

  const {data: dataRelation, loading: loadingRelation} = useQuery(
    GET_DEPENDANT_RELATIONSHIPS,
    {
      wlb_skipPatch: true,
      skip: isNeverOpen,
      variables: {
        search: `%${search.relation}%`,
        limit: 20,
      },
    }
  )

  const {data: dataCountry, loading: loadingCountry} = useQuery(GET_COUNTRIES, {
    wlb_skipPatch: true,
    skip: isNeverOpen,
    variables: {
      search: `%${search.country}%`,
      limit: 20,
      id: [103],
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })

  const {data: dataProvince, loading: loadingProvince} = useQuery(
    GLOBAL_PROVINCES_SEARCHABLE,
    {
      skip: isNeverOpen,
      variables: {
        search: `%${search.province}%`,
        limit: 20,
        country: formData?.country?.value,
      },
    }
  )

  const {data: dataCity, loading: loadingCity} = useQuery(
    GLOBAL_CITIES_SEARCHABLE,
    {
      skip: isNeverOpen,
      variables: {
        search: `%${search.city}%`,
        limit: 20,
        province: formData?.province?.value,
      },
    }
  )

  React.useEffect(() => {
    if (!open) {
      return
    }

    setFormState(STATE_INITIAL)

    if (initialData) {
      const fields = initialData.dependant_fields

      const relation = initialData.relationship

      const country = initialData.global_country
      const province = initialData.global_province
      const city = initialData.global_city

      setFormData({
        name: initialData.name,
        relationship: relation
          ? {label: capitalize(relation), value: relation}
          : null,
        birthplace: initialData.birthplace,
        birthdate: dateFormat(initialData.birthdate),
        identity_number: initialData.identity_number,
        marital_status: initialData.marital_status,
        job_status: initialData.working_status,
        phone_code: fields?.phone_code,
        phone_number: fields?.phone,
        gender: initialData.gender,
        is_beneficiary: initialData.is_beneficiary,
        civil_servant: initialData.civil_servant ? RADIO_YES : RADIO_NO,
        part_company: initialData.is_company_group_employee
          ? RADIO_YES
          : RADIO_NO,
        company: initialData.company,
        blood_type: initialData.blood_type,
        country: country ? {label: country.name, value: country.id} : null,
        province: province ? {label: province.name, value: province.id} : null,
        city: city ? {label: city.name, value: city.id} : null,
        district: initialData.district,
        subdistrict: initialData.sub_district,
        postal_code: initialData.postal_code,
        address: initialData.address,
        dependent_status: initialData.dependent_status,
      })
    } else {
      setFormData(INITIAL_FORM_DATA)
    }
  }, [open])

  if (isNeverOpen) {
    return null
  }

  const bindAutocompleteSearch = field => query => {
    setSearch({...search, [field]: query})
  }

  const handleAutocompleteChange = item => {
    const {fieldName, label, value} = item

    if (fieldName === 'country') {
      setFormData({
        ...formData,
        country: {label, value},
        province: null,
        city: null,
      })
    } else if (fieldName === 'province') {
      setFormData({
        ...formData,
        province: {label, value},
        city: null,
      })
    } else {
      setFormData({
        ...formData,
        [fieldName]: {label, value},
      })
    }
  }

  const handleSubmit = () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const data = {
      user: userId,

      name: formData.name,
      relationship: formData.relationship.value,
      birthplace: formData.birthplace,
      birthdate: formData.birthdate,
      identity_number: formData.identity_number,
      marital_status: formData.marital_status,
      working_status: formData.job_status,
      dependant_fields: {
        phone: formData.phone_number,
        phone_code: formData.phone_code,
      },
      gender: formData.gender,
      is_beneficiary: formData.is_beneficiary,
      civil_servant: formData.civil_servant === RADIO_YES,
      is_company_group_employee: formData.part_company === RADIO_YES,
      company: formData.company,
      blood_type: formData.blood_type,
      country: formData.country.value,
      province: formData.province.value,
      city: formData.city.value,
      district: formData.district,
      sub_district: formData.subdistrict,
      postal_code: formData.postal_code,
      address: formData.address,
      dependent_status: formData.dependent_status,
    }

    const verb = isEditing ? 'ubah' : 'tambah'
    let promise

    if (isEditing) {
      promise = client.mutate({
        mutation: UPDATE_PEOPLE_DEPENDENT,
        variables: {
          id: initialData.id,
          data: data,
        },
      })
    } else {
      promise = client.mutate({
        mutation: ADD_PEOPLE_DEPENDENT,
        variables: {
          data: data,
        },
      })
    }

    promise.then(
      () => {
        if (!isEditing) {
          setFormData(INITIAL_FORM_DATA)
        }

        setFormState(STATE_INITIAL)
        enqueueSnackbar(`Tanggungan di${verb}`, {variant: 'success'})

        if (onSubmit) {
          onSubmit(!isEditing)
        }
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Failed to ${verb} dependent`, {variant: 'error'})

        setFormState(STATE_INITIAL)
      }
    )
  }

  const fieldsList = [
    {
      type: 'textfield',
      label: 'Nama*',
      placeholder: 'Tambahkan nama',
      required: true,
      fieldName: 'name',
      value: formData.name,
      error: !formData.name,
    },
    {
      type: 'select',
      label: 'Jenis Kelamin*',
      placeholder: 'Pilih jenis kelamin',
      required: true,
      fieldName: 'gender',
      value: formData.gender,
      option: GENDER_OPTIONS,
      error: !formData.gender,
    },
    {
      type: 'autocomplete',
      label: 'Hubungan*',
      placeholder: 'Pilih hubungan',
      required: true,
      fieldName: 'relationship',
      value: formData.relationship,
      option: dataRelation?.relationships.map(item => ({
        label: capitalize(item.name),
        value: item.name,
      })),
      error: !formData.relationship,
      isLoading: loadingRelation,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('relation'),
    },
    {
      type: 'switch',
      label: 'Penerima',
      text: 'Tanggungan ini akan menjadi penerima tunjangan',
      fieldName: 'is_beneficiary',
      value: formData.is_beneficiary,
    },
    {
      type: 'textfield',
      label: 'Tempat Lahir*',
      placeholder: 'Tambahkan tempat lahir',
      required: true,
      fieldName: 'birthplace',
      value: formData.birthplace,
      error: !formData.birthplace,
    },
    {
      type: 'dateCustom',
      label: ['Tanggal Lahir*'],
      filter: ['date'],
      required: true,
      fieldName: 'birthdate',
      value: formData.birthdate,
      error: !formData.birthdate,
    },
    {
      type: 'textfield',
      label: 'Status Tanggungan',
      placeholder: 'Tambahkan status tanggungan',
      fieldName: 'dependent_status',
      value: formData.dependent_status,
    },
    {
      type: 'textfield',
      label: 'Usia Tanggungan',
      placeholder: 'Tambahkan usia',
      disabled: true,
      style: {background: '#f4f5f6'},
      value:
        formData.birthdate && formData.birthdate !== 'Invalid date'
          ? differenceDate(formData.birthdate)
          : '',
    },
    {
      type: 'textfield',
      label: 'Nomor Identitas*',
      placeholder: 'Tambahkan nomor identitas',
      required: true,
      fieldName: 'identity_number',
      value: formData.identity_number,
      error: !formData.identity_number,
    },
    {
      type: 'select',
      label: 'Status Perkawinan*',
      placeholder: 'Pilih status perkawinan',
      required: true,
      fieldName: 'marital_status',
      value: formData.marital_status,
      option: MARITAL_STATUS_OPTIONS,
      error: !formData.marital_status,
    },
    {
      type: 'select',
      label: 'Status Pekerjaan*',
      placeholder: 'Pilih statsu pekerjaan',
      required: true,
      fieldName: 'job_status',
      value: formData.job_status,
      option: WORKING_STATUS_OPTIONS,
      error: !formData.job_status,
    },
    {
      type: 'radio',
      label: 'Aparatur Sipil Negara?*',
      fieldName: 'civil_servant',
      value: formData.civil_servant,
      option: RADIO_BOOL_OPTIONS,
      row: true,
    },
    {
      type: 'radio',
      label: 'Pegawai Swasta*',
      fieldName: 'part_company',
      value: formData.part_company,
      option: RADIO_BOOL_OPTIONS,
      row: true,
    },
    {
      type: 'textfield',
      label: 'Perusahaan',
      placeholder: 'Tambahan perusahaan',
      fieldName: 'company',
      value: formData.company,
    },
    {
      type: 'phone-field',
      label: 'Nomor Telepon*',
      placeholder: 'Tambahkan nomor telepon',
      required: true,
      fieldName: ['phone_code', 'phone_number'],
      value: [formData.phone_code, formData.phone_number],
      error: [!formData.phone_code, !formData.phone_number],
    },
    {
      type: 'select',
      label: 'Golongan Darah*',
      placeholder: 'Pilih golongan darah',
      required: true,
      fieldName: 'blood_type',
      value: formData.blood_type,
      option: BLOOD_TYPE_OPTIONS,
      error: !formData.blood_type,
    },
    {
      type: 'autocomplete',
      label: 'Negara*',
      placeholder: 'Pilih negara',
      required: true,
      fieldName: 'country',
      value: formData.country,
      option: dataCountry?.countries.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData.country,
      isLoading: loadingCountry,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('country'),
    },
    {
      type: 'autocomplete',
      label: 'Provinsi*',
      placeholder: 'Pilih provinsi',
      required: true,
      fieldName: 'province',
      value: formData.province,
      option: dataProvince?.global_provinces.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData.province,
      isLoading: loadingProvince,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('province'),
    },
    {
      type: 'autocomplete',
      label: 'Kota*',
      placeholder: 'Pilih kota',
      required: true,
      fieldName: 'city',
      value: formData.city,
      option: dataCity?.global_cities.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData.city,
      isLoading: loadingCity,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('city'),
    },
    {
      type: 'textfield',
      label: 'Kecamatan*',
      placeholder: 'Tambahkan kecamatan',
      required: true,
      fieldName: 'district',
      value: formData.district,
      error: !formData.district,
    },
    {
      type: 'textfield',
      label: 'Kelurahan',
      placeholder: 'Tambahkan kelurahan',
      fieldName: 'subdistrict',
      value: formData.subdistrict,
    },
    {
      type: 'textfield',
      label: 'Kode Pos*',
      placeholder: 'Tambahkan kode pos',
      inputType: 'number',
      required: true,
      fieldName: 'postal_code',
      value: formData.postal_code,
      error: !formData.postal_code,
    },
    {
      type: 'textfield',
      label: 'Alamat*',
      placeholder: 'Tambahkan alamat',
      fieldName: 'address',
      multiline: true,
      rows: 5,
      required: true,
      value: formData.address,
      error: !formData.address,
    },
  ]

  return (
    <ModalDetail
      open={open}
      maxWidth="sm"
      title={`${isEditing ? 'Ubah' : 'Tambahkan'} Tanggungan`}
      onClose={isDispatching ? null : onClose}
      onDelete={isEditing ? onDelete : null}
    >
      <ModalAddEditWraper>
        <FormInput
          open
          fieldsList={fieldsList}
          setValues={setFormData}
          values={formData}
          errorState={isError}
          HELPER_TEXT="Bagian ini diperlukan"
        />
      </ModalAddEditWraper>

      <ButtonSubmit
        disabled={isDispatching}
        onCancel={onClose}
        onSave={handleSubmit}
      />
    </ModalDetail>
  )
}

export default AddEditDependent
