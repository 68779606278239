import gql from 'graphql-tag'
import {COMPANY_ID} from '../../../../utils/globals'

export const GET_HOLDING_VERSION_BY_ID = gql`
  query getVersionById($id:Int) {
    holding_version(
      where: {
        company: {_eq: "${COMPANY_ID}"}
        id: {_eq: $id}
      }
      order_by: {id: asc}
    ) {
      id
      name
      description
      status
      start_date
      end_date
    }
  }
`
