import React from 'react'
import {withRouter} from 'react-router-dom'
import {Icon, IconButton, Tab, Typography} from '@material-ui/core'

import CreatePolicyPageComponent from './CreatePolicyPageComponent'
import EditPolicyPageComponent from './EditPolicyPageComponent'

import {
  AntTabs,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
} from '../../../../GlobalStyles'

const CreateEditPolicyPageWrapper = props => {
  const isEdit = props?.location?.pathname?.split('/')?.includes('edit')

  const [value, setValue] = React.useState(0)

  const TAB_CONTENT = {
    0: isEdit ? (
      <EditPolicyPageComponent policyId={props?.state?.policyId} />
    ) : (
      <CreatePolicyPageComponent />
    ),
  }

  return (
    <PaperContainer>
      <FormToolbar>
        <FormTitleContainer>
          <IconButton
            onClick={() => {
              props.history.push({
                pathname: '/financial/loan/settings',
                state: {active: 'settings', searchable: true},
              })
            }}
            aria-label="Go back to previous page"
            edge="start"
          >
            <Icon>arrow_back</Icon>
          </IconButton>

          <Typography variant="subtitle1" style={{fontWeight: '600'}}>
            {isEdit ? 'Edit' : 'Add New'} Loan {props.title}
          </Typography>
        </FormTitleContainer>
      </FormToolbar>

      <AntTabs
        value={value}
        onChange={(e, newValue) => {
          e.preventDefault()
          setValue(newValue)
        }}
        aria-label="loan-activity-tabs"
      >
        <Tab label="Policy Detail" />
      </AntTabs>

      {TAB_CONTENT[value]}
    </PaperContainer>
  )
}

export default withRouter(CreateEditPolicyPageWrapper)
