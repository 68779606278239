import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Avatar, Dialog, IconButton, Typography} from '@material-ui/core'
import {ModalContent, ModalHeader, WhiteIcon} from '../../../../../GlobalStyles'
import {DetailModalLeave, StyledListCard} from '../../../leave/LeavePageStyles'
import moment from 'moment'
import ModalDetailActivity from '../../../leave/activity/detail-activity/ModalDetailActivity'

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
    fontWeight: 600,
  },
  sub_title: {
    flexGrow: 1,
    fontWeight: 100,
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
  textGrey: {color: '#a9a8a8'},
  blueLink: {color: '#039be5', cursor: 'pointer'},
}))

export default function ModalDetailCalender(props) {
  const classes = useStyles()

  moment.locale()

  const {event} = props
  // console.log({event})
  const [open, setOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(undefined)
  const handleClickOpen = item => {
    setSelectedItem(item)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth="sm"
      fullWidth={true}
    >
      <ModalHeader style={{backgroundColor: event && event.label_color}}>
        <div>
          <Typography
            variant="h6"
            className={classes.title}
            style={{color: event && event.text_color}}
          >
            {event && moment(event.date).format('LL')}
          </Typography>
          <Typography
            variant={'h6'}
            className={classes.sub_title}
            style={{color: event && event.text_color}}
          >
            {event && event.title}
          </Typography>
        </div>
        <IconButton aria-label="close" onClick={props.handleClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContent>
        <Typography variant="body1" gutterBottom>
          Employees
        </Typography>
        {event &&
          event.leaves.map(leave => {
            // console.log(leave)
            return (
              <StyledListCard key={leave.id}>
                <Avatar
                  className={classes.bigAvatar}
                  src={leave.people_work_placement.global_user.avatar}
                />
                <DetailModalLeave>
                  <Typography variant="body2">
                    {leave.time_leave_policy_child.name}
                  </Typography>
                  <Typography variant="body1" className={classes.title}>
                    {leave.people_work_placement.global_user.name}
                  </Typography>
                  <Typography variant="body2" className={classes.textGrey}>
                    {leave.people_work_placement.company_job_profile.title}
                  </Typography>
                </DetailModalLeave>
                <Typography
                  className={classes.blueLink}
                  onClick={() => handleClickOpen(leave)}
                >
                  Detail
                </Typography>
              </StyledListCard>
            )
          })}
      </ModalContent>
      <ModalDetailActivity
        open={open}
        selectedItem={selectedItem}
        handleClose={handleClose}
      />
    </Dialog>
  )
}
