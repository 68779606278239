import { useQuery } from "@apollo/react-hooks";
import { DEFAULT_PAGINATION } from "../../../../../../utils/globals";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Divider,
} from "@material-ui/core";
import React from "react";
import TabelRaterItem from "./TabelRaterItem";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 16,
    backgroundColor: "#fff",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 5,
    "&::before": {
      content: "none",
    },
  },
  summaryContent: { margin: 0, fontSize: 16 },
  summaryRoot: {
    padding: "12px 40px",
    fontSize: 18,
    fontWeight: "600",
    color: "#014a62",
  },
  detailRoot: { padding: 0 },
  tableContainer: { padding: "0 14px" },
  contentContainer: { width: "100%" },
}));

export default function RaterItemDeailAsesi({
  title,
  query,
  user,
  survey,
  searchText,
  totalQuestion,
  show = false,
}) {
  const classes = useStyles();
  const [pagination, setPagination] = React.useState({
    limit: DEFAULT_PAGINATION[0],
    offset: 0,
  });

  const { data, loading, error } = useQuery(query, {
    skip: !show,
    variables: {
      user,
      survey,
      search: `%${searchText}%`,
      offset: pagination.offset,
      limit: pagination.limit,
    },
  });

  const handleChangePage = (event, page) => {
    event.preventDefault();
    setPagination({ ...pagination, offset: page * pagination.limit });
  };

  const handleChangeRowsPerPage = (event, page) => {
    event.preventDefault();
    setPagination({
      ...pagination,
      limit: page.props.value,
      offset: 0,
    });
  };

  const TOTAL = data?.total?.aggregate?.count || 0;

  return (
    <Accordion classes={{ root: classes.root }}>
      <AccordionSummary
        classes={{ root: classes.summaryRoot, content: classes.summaryContent }}
      >
        {`${title} (${TOTAL})`}
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.detailRoot }}>
        <div className={classes.contentContainer}>
          <Divider />
          <div className={classes.tableContainer}>
            <TabelRaterItem
              data={data?.people_work_placements || []}
              count={TOTAL}
              loading={loading}
              error={error}
              searchText={searchText}
              rowsPerPage={pagination.limit}
              page={pagination.offset}
              totalQuestion={totalQuestion}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
