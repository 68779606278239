import React, {useState, useEffect} from 'react'
import {PaperContainer} from '../../../../GlobalStyles'
import Header from './Header'
import {withRouter} from 'react-router-dom'
import {WrapperContent, FooterCk} from './EditRegulation.style'
import {TextField} from '@material-ui/core'
// import ModalConfirmation from '../shared-component-regulations/ModalConfirmation'
import CKEditor from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import {
  ADD_REGULATION,
  ADD_CLAUSE,
  UPDATE_REGULATION,
  UPDATE_CLAUSE,
} from '../../../../graphql/mutations'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import AddEditConfirm from '../../../shared-components/popup/AddEditPopup'
import AlertDialog from '../../../shared-components/popup/ResultPopup'

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
}

const AddEditComponent = props => {
  const {model, type, history, state} = props

  const {enqueueSnackbar} = useSnackbar()
  const [open, setOpen] = useState({
    confirmation: false,
    cancel: false,
  })
  const [allData, setAllData] = useState({})
  const [isError, setIsError] = useState(false)

  const [addRegulation] = useMutation(ADD_REGULATION)
  const [addClause] = useMutation(ADD_CLAUSE)
  const [updateRegulation] = useMutation(UPDATE_REGULATION)
  const [updateClause] = useMutation(UPDATE_CLAUSE)

  useEffect(() => {
    if (model === 'Clause' && type === 'Add') {
      const idRegulation = state && state.regulation.id
      setAllData({...allData, regulation: idRegulation})
    }
    if (state.dataEdit && type === 'Edit') {
      const dataForEdit = state && state.dataEdit
      if (model === 'Regulation') {
        setAllData({
          ...allData,
          id: dataForEdit && dataForEdit.id,
          name: dataForEdit && dataForEdit.name,
        })
      } else {
        setAllData({
          ...allData,
          content: dataForEdit && dataForEdit.content,
          id: dataForEdit && dataForEdit.id,
          name: dataForEdit && dataForEdit.name,
        })
      }
    }
  }, [])

  const handleAlertCancel = status => {
    open.cancel
      ? setOpen({...open, cancel: false})
      : setOpen({...open, cancel: true})
    if (open.cancel && status !== 'notNow') {
      history.push({
        pathname: '/company/regulations',
        state: {active: 'list-regulations', searchable: true},
      })
    }
  }

  const handleAlertConfirm = notif => {
    // if (model === 'Regulation') {
    if (!allData.name) {
      setIsError(true)
    } else {
      if (notif === 'confirm') {
        open.confirmation
          ? setOpen({...open, confirmation: false})
          : setOpen({...open, confirmation: true})
      }
    }
    // } else {
    //   if (!allData.name || !allData.content) {
    //     setIsError(true)
    //   } else {
    //     if (notif === 'confirm') {
    //       open.confirmation
    //         ? setOpen({...open, confirmation: false})
    //         : setOpen({...open, confirmation: true})
    //     }
    //   }
    // }
  }

  const handleMutationReguation = () => {
    const dataRegulation = {
      objects: [allData],
    }
    if (type === 'Add') {
      addRegulation({variables: dataRegulation})
        .then(() => {
          enqueueSnackbar('Regulation Added', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Added Regulation failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else {
      updateRegulation({variables: allData})
        .then(() => {
          enqueueSnackbar('Regulation Updated', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Update Regulation failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
    history.push({
      pathname: '/company/regulations',
      state: {active: 'list-regulations', searchable: true},
    })
  }

  const handleMutationClause = () => {
    const dataClause = {
      objects: [allData],
    }
    if (type === 'Add') {
      addClause({variables: dataClause})
        .then(() => {
          enqueueSnackbar('Clause Added', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Added Clause failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else {
      if (type === 'Edit' && !allData.content) {
        setAllData({...allData, content: state.dataEdit.content})
      }
      updateClause({variables: allData})
        .then(() => {
          enqueueSnackbar('Clause Updated', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Update Clause failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
    history.push({
      pathname: '/company/regulations',
      state: {active: 'list-regulations', searchable: true},
    })
  }

  return (
    <>
      <PaperContainer style={{boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)'}}>
        <Header
          onClickSubmit={() => handleAlertConfirm('confirm')}
          onClickCancel={handleAlertCancel}
          {...props}
        />
        <WrapperContent>
          <div className="title-field-wrapper">
            <h5 className="number">1.</h5>
            <h5 className="title-field">{model} Names*</h5>
          </div>
          <div className="input-field-wrapper">
            <TextField
              style={{width: '417.2px'}}
              size="small"
              variant="outlined"
              value={allData && allData.name}
              onChange={event => {
                setAllData({
                  ...allData,
                  name: event.target.value,
                })
              }}
              error={!allData.name && isError}
              helperText={
                !allData.name && isError ? 'This field is required' : ''
              }
            />
          </div>
          {model === 'Clause' && (
            <>
              <div className="title-field-wrapper">
                <h5 className="number">2.</h5>
                <h5 className="title-field"> Clause</h5>
              </div>
              <div className="input-field-wrapper ">
                <div className="ckeditor">
                  <CKEditor
                    editor={DecoupledEditor}
                    config={editorConfiguration}
                    onInit={editor => {
                      editor.ui
                        .getEditableElement()
                        .parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.getEditableElement()
                        )
                    }}
                    data={
                      type === 'Add'
                        ? allData && allData.content
                        : state.dataEdit.content
                    }
                    // eslint-disable-next-line no-unused-vars
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setAllData({
                        ...allData,
                        content: data,
                      })
                    }}
                  />
                  <FooterCk />
                </div>
              </div>
            </>
          )}
        </WrapperContent>
      </PaperContainer>

      <AddEditConfirm
        open={open.confirmation}
        handleClose={() => handleAlertConfirm('confirm')}
        type={type}
        name={allData.name}
        feature={model}
        mutation={
          model === 'Regulation'
            ? handleMutationReguation
            : handleMutationClause
        }
      />

      <AlertDialog
        open={open.cancel}
        handleClose={handleAlertCancel}
        feature={`Cancel to ${type} ${model}?`}
        message={`You have unsaved changes. Are you sure want to cancel ${type} ${model}?`}
        type="Yes, Cancel"
        status="notNow"
      />
      {/* <ModalConfirmation
        type="confirmation"
        open={open.confirmation}
        model={model}
        typeAction={type}
        onClose={() => {
          setOpen({...open, confirmation: false})
        }}
        onConfirm={() => {
          setOpen({...open, confirmation: false})
          props.history.goBack()
        }}
      />

      <ModalConfirmation
        type="cancel"
        open={open.cancel}
        model={model}
        typeAction={type}
        onClose={() => {
          setOpen({...open, cancel: false})
        }}
        onConfirm={() => {
          setOpen({...open, cancel: false})
          props.history.goBack()
        }}
      /> */}
    </>
  )
}

export default withRouter(AddEditComponent)
