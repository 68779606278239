import React from 'react'
import {WrapChipsSection} from '../DetailEmployeeStyles'
import {Icon} from '@material-ui/core'

export default function ChipsItem({
  chipsName,
  onEdit,
  hideEdit,
  ...children
}) {
  return (
    <WrapChipsSection>
      <div className="chips-wrap">
        <div className="chips">
          <h4 className="chips-name">{chipsName}</h4>
        </div>
      </div>
      <div className="content">
        <div className="divider" {...children} />
      </div>

      {!hideEdit && (
        <Icon onClick={onEdit} className="icon-edit">
          edit
        </Icon>
      )}
    </WrapChipsSection>
  )
}
