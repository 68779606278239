import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

const DATA = [1, 2, 3, 4]

export default function SkeletonChartDoughnut() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 12,
        marginBottom: 12,
      }}
    >
      <Skeleton variant="circle" width={140} height={140} />
      <div style={{marginLeft: 12}}>
        {DATA.map(res => (
          <Skeleton
            key={res}
            variant="rect"
            width="220px"
            height="20px"
            style={{marginBottom: 14}}
          />
        ))}
      </div>
    </div>
  )
}
