import React from 'react'
import {Dialog, Button} from '@material-ui/core'
import {WrapperModalConfirmation} from '../PayrollPageStyles'

export default function ModalConfirmation({
  open,
  onClose,
  onConfirm,
  type,
  onCancel,
  isUpdate,
}) {
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="xs">
      <WrapperModalConfirmation>
        <div className="header-modal-confirmation">
          {type === 'confirmation'
            ? 'Confirm Changes?'
            : isUpdate
            ? 'Reset Changes?'
            : `Discard Changes?`}
        </div>
        <div className="body-modal-confirmation">
          {type === 'confirmation'
            ? 'Are you sure to save changes?'
            : 'Are you sure want to discard unsaved changes?'}
        </div>
        <div className="action-wrapper-modal-confirmation">
          <Button style={{fontSize: 12}} onClick={onClose}>
            Cancel
          </Button>
          <Button
            style={{fontSize: 12, marginLeft: 12}}
            onClick={e => {
              e.preventDefault()
              if (onConfirm && type === 'confirmation') {
                onConfirm()
              }
              if (onCancel && type !== 'confirmation') {
                onCancel()
              }
              onClose()
            }}
            size="large"
            variant="contained"
            color="primary"
          >
            {type === 'confirmation' ? 'Confirm' : `Discard`}
          </Button>
        </div>
      </WrapperModalConfirmation>
    </Dialog>
  )
}
