import React, {useState, useEffect} from 'react'
import MaterialTable from 'material-table'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Button} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import helperStyle, {
  BigTextBlackBold,
  OrganizationContainer,
  TextDarkBlue,
  WhiteButton,
  WrapperContent,
  TextGrey,
} from '../../OrganizationPageStyles'
import {
  RootContainer,
  EnhancedToolbar,
  MoreIcon,
  PaperContainer,
  StyledMenuItem,
  StyledMenus,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import Loading from '../../../../components/loading/LoadingComponent'
import {COMPANY_ID} from '../../../../utils/globals'
import {disbaledButtonExport} from '../../../../utils/helpers'
import {
  GET_LIST_TABLE_DESIGNATION,
  GET_DESIGNATION_DETAIL,
  GET_COMPANY_PROFILE_BY_DESIGNATION,
  GET_BALOON_STATUS_BUTTON,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../../../graphql/queries'
import {
  DELETE_DESIGNATION,
  IMPORT_DATA,
  CLOSE_BALOON,
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
} from '../../../../graphql/mutations'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import ImportBaloon from '../../../../components/importExcel/ImportBaloon'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
// import DownloadPopup from '../../../shared-components/popup/DownloadPopup'
import DetailDataOrganization from '../../../shared-components/popup/DetailDataOrganization'

const ListDesignation = ({searchText}) => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [OpenAlert, setOpenAlert] = useState(false)
  const [OpenCantDel, setOpenCantDel] = useState(false)
  const [dataDesignation, setDataDesignation] = useState(false)
  const [openExport, setExport] = useState(false)
  const [openDetail, setDetail] = useState(false)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })
  const {data, error, loading, refetch} = useQuery(GET_LIST_TABLE_DESIGNATION, {
    variables: {
      search: `%${searchText}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })
  const [deleteDesignation] = useMutation(DELETE_DESIGNATION)
  const [openImport, setImport] = useState(false)
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )
  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    // loading: loadingGetBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'company_employee_positions',
    },
  })

  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')

  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )

  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  const {data: getExportBaloon, error: errorGetExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_employee_positions',
      },
    }
  )

  const {data: getExportDisabled, error: errorGetExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_employee_positions',
      },
    }
  )

  const [skipQueryDetail, setSkipQueryDetail] = useState(true)
  const [JTDetail, setJTDetail] = useState(null)
  const {loading: JTDetailLoading} = useQuery(GET_DESIGNATION_DETAIL, {
    variables: {
      id: dataDesignation && dataDesignation.id,
    },
    skip: skipQueryDetail,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (
        data &&
        data.company_employee_positions &&
        data.company_employee_positions.length > 0
      )
        setJTDetail(data.company_employee_positions[0])

      setSkipQueryDetail(true)
    },
  })

  const [skipQueryCompProfile, setSkipQueryCompProfile] = useState(true)
  const {data: compProfile} = useQuery(GET_COMPANY_PROFILE_BY_DESIGNATION, {
    variables: {
      jobTitle: dataDesignation && dataDesignation.id,
    },
    skip: skipQueryCompProfile,
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      setSkipQueryCompProfile(true)
    },
  })

  /* if delete button clicked, then fetch company profile */
  useEffect(() => {
    if (compProfile) {
      const countJobProfile =
        compProfile &&
        compProfile.total &&
        compProfile.total.aggregate &&
        compProfile.total.aggregate.count > 0

      countJobProfile ? setOpenCantDel(true) : setOpenAlert(true)
    }
  }, [compProfile])

  /* if detail button clicked, then fetch job family detail */
  useEffect(() => {
    if (openDetail) {
      setSkipQueryDetail(false)
    }
  }, [openDetail])

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  if (
    (data === undefined && error === undefined) ||
    getBaloonStatus === undefined
  ) {
    return <Loading />
  } else if (
    error !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationImportError !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationExportError !== undefined ||
    closeBaloonExportError !== undefined ||
    errorGetExportBaloon !== undefined ||
    errorGetExportDisabled !== undefined
  ) {
    return <div>{error}</div>
  }

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  const onDeleteDesignation = () => {
    const variables = {
      id: dataDesignation.id,
    }
    deleteDesignation({
      variables: variables,
    })
      .then(() => {
        enqueueSnackbar('Job Title deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete Job Title failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleOpenAlert = data => {
    setDataDesignation(data)
    setSkipQueryCompProfile(false)
  }

  const handleCloseAlert = status => {
    status === 'delete' ? setOpenAlert(false) : setOpenCantDel(false)
  }

  const handleDownload = () => {
    setExport(!openExport)
    if (openExport) {
      mutationExport({
        variables: {
          table: 'company_employee_positions',
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleOpenDetail = data => {
    setDataDesignation(data)
    setDetail(!openDetail)
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  // const defaultExport = [
  //   {
  //     'Job Title Code': '-',
  //     'Job Title': '-',
  //     // 'Job Function': '-',
  //     Description: '-',
  //     Status: '-',
  //   },
  // ]

  // const dataForExport = data.company_employee_positions.map(res => {
  //   return {
  //     'Job Title Code': res.designation_code ? res.designation_code : '-',
  //     // 'Job Family': res.job_family_mapping
  //     //   ? `${res.job_family_mapping &&
  //     //       res.job_family_mapping.job_family &&
  //     //       res.job_family_mapping.job_family.name} ${res.job_family_mapping &&
  //     //       res.job_family_mapping.function_name}`
  //     //   : '-',
  //     'Job Title': res.name ? res.name : '-',
  //     // 'Job Function': res.job_family_mapping
  //     //   ? `${res.job_family_mapping.function_name &&
  //     //       res.job_family_mapping.function_name}`
  //     //   : '-',
  //     Description: res.description ? res.description : '-',
  //     Status: res.status ? res.status : '-',
  //   }
  // })

  // const dataExport = dataForExport.length > 0 ? dataForExport : defaultExport

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  const handleClickOpenImport = () => {
    setImport(true)
  }
  const handleCloseImport = () => {
    setImport(false)
  }

  const importMutation = async () => {
    try {
      const response = await mutationImport({
        variables: {
          table: 'company_employee_positions',
          file: fileURL,
        },
      })
      // eslint-disable-next-line no-console
      console.log(response)
      refetch()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const column = [
    {
      title: 'Kode jabatan',
      field: 'designation_code',
      defaultSort: 'asc',
      headerStyle: {
        minWidth: 160,
        maxWidth: 160,
      },
      cellStyle: {
        minWidth: 160,
        maxWidth: 160,
      },
    },
    {
      title: 'Jabatan',
      field: 'name',
      headerStyle: {
        minWidth: 270,
        maxWidth: 270,
      },
      cellStyle: {
        minWidth: 270,
        maxWidth: 270,
      },
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData => (
        <Button
          variant="contained"
          style={
            rowData &&
            rowData.status &&
            rowData.status.toLowerCase() === 'active'
              ? {
                  backgroundColor: '#4caf50',
                  color: '#ffffff',
                  width: '100px',
                }
              : {
                  backgroundColor: '#ef4d5e',
                  color: '#ffffff',
                  width: '100px',
                }
          }
          size="medium"
        >
          {rowData &&
          rowData.status &&
          rowData.status.toLowerCase() === 'active'
            ? 'Aktif'
            : 'Tidak Aktif'}
        </Button>
      ),
    },
    // {
    //   title: 'Total Employee',
    //   field: 'count_employee',
    //   cellStyle: {
    //     textAlign: 'center',
    //   },
    // },
    {
      title: 'Aksi',
      field: 'action',
      render: rowData => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleOpenDetail(rowData)
                  }}
                >
                  Rincian
                </StyledMenuItem>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: `/organization/job-title/edit`,
                    state: {
                      active: 'edit-job-title',
                      status: 'Edit',
                      data: rowData,
                    },
                  }}
                  variant="h6"
                >
                  Ubah
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleOpenAlert(rowData)
                  }}
                >
                  Hapus
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>
      ),
    },
  ]

  return (
    <RootContainer>
      <ImportBaloon
        data={getBaloonStatus}
        closeBaloonMutation={closeBaloonMutation}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
      />
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
      />
      <OrganizationContainer className={classes.root}>
        <PaperContainer>
          <EnhancedToolbar>
            <BigTextBlackBold>Jabatan</BigTextBlackBold>
            <WrapperContent>
              <GenerateTooltip
                title={
                  (getExportBaloon &&
                    getExportBaloon.company_export_data_logs &&
                    getExportBaloon.company_export_data_logs.length > 0 &&
                    getExportBaloon.company_export_data_logs[0].status ===
                      'FAILED') ||
                  disbaledButtonExport(
                    getExportDisabled &&
                      getExportDisabled.company_export_data_logs &&
                      getExportDisabled.company_export_data_logs[0] &&
                      getExportDisabled.company_export_data_logs[0].date_added
                  )
                    ? `you can only generate data once a day. to download data use the link that already generated`
                    : ``
                }
                placement="left"
              >
                <span>
                  <WhiteButton
                    variant="contained"
                    size="large"
                    style={{
                      marginRight: 12,
                    }}
                    onClick={() => handleDownload()}
                    disabled={
                      (getExportBaloon &&
                        getExportBaloon.company_export_data_logs &&
                        getExportBaloon.company_export_data_logs.length > 0 &&
                        getExportBaloon.company_export_data_logs[0].status ===
                          'FAILED') ||
                      disbaledButtonExport(
                        getExportDisabled &&
                          getExportDisabled.company_export_data_logs &&
                          getExportDisabled.company_export_data_logs[0] &&
                          getExportDisabled.company_export_data_logs[0]
                            .date_added
                      )
                    }
                  >
                    <TextDarkBlue>Ekspor Data</TextDarkBlue>
                  </WhiteButton>
                </span>
              </GenerateTooltip>
              <WhiteButton
                variant="contained"
                size="large"
                style={{
                  marginRight: 12,
                }}
                onClick={handleClickOpenImport}
                disabled={
                  getBaloonStatus.company_import_data_logs.length > 0 &&
                  getBaloonStatus.company_import_data_logs[0].status ===
                    'WAITING'
                }
              >
                {getBaloonStatus.company_import_data_logs.length > 0 &&
                getBaloonStatus.company_import_data_logs[0].status ===
                  'WAITING' ? (
                  <TextGrey>Importing on proces...</TextGrey>
                ) : (
                  <TextDarkBlue>Impor Jabatan</TextDarkBlue>
                )}
              </WhiteButton>
              <Button
                className={classes.marginLeft10}
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to={{
                  pathname: `/organization/job-title/add`,
                  state: {
                    active: 'add-job-title',
                    status: 'Add',
                  },
                }}
              >
                Tambah Jabatan
              </Button>
            </WrapperContent>
          </EnhancedToolbar>
          <MaterialTable
            options={{
              ...TableOptions,
              selection: false,
              showTitle: false,
              toolbar: false,
              emptyRowsWhenPaging: false,
            }}
            isLoading={loading}
            localization={{
              pagination: TableLocalization.pagination,
              body: {
                emptyDataSourceMessage: (
                  <NoDataListComponent search={searchText} />
                ),
              },
            }}
            columns={column}
            data={data && data.company_employee_positions}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            title=""
            totalCount={data.total.aggregate.count}
            page={pagination.offset}
            pageSize={pagination.limit}
          />
          <DeletePopup
            open={OpenAlert}
            handleClose={() => handleCloseAlert('delete')}
            id={dataDesignation.id}
            name={dataDesignation.name}
            title={'Job Function'}
            mutation={() => onDeleteDesignation()}
          />
          <AlertDialog
            open={OpenCantDel}
            handleClose={() => handleCloseAlert('cantDelete')}
            feature={`Tidak dapat menghapus ${dataDesignation.name}`}
            name={dataDesignation.name}
            fMessage="Anda tidak dapat menghapus "
            lMessage=" karena digunakan pada posisi"
            type="Konfirmasi"
          />
          <ImportPopup
            open={openImport}
            position="Designation"
            handleClose={handleCloseImport}
            mutation={importMutation}
            fileTemplate={
              'https://public-media.wlb.co.id/1dc6d763-8ad8-4e8a-8d26-5bb73dd569f2-1602486963025.xlsx'
            }
            isAlreadyImported={isAlreadyImported}
            setIsAlreadyImported={setIsAlreadyImported}
            setImport={setImport}
            fileURL={fileURL}
            setFileURL={setFileURL}
            closeBaloon={closeBaloonMutation}
            errorsImport={
              getBaloonStatus.company_import_data_logs.length > 0 &&
              getBaloonStatus.company_import_data_logs[0].reports &&
              getBaloonStatus.company_import_data_logs[0].reports.failed
                .length > 0
                ? getBaloonStatus.company_import_data_logs[0].reports.failed
                : []
            }
          />
        </PaperContainer>
        <AlertDialog
          open={openExport}
          handleClose={handleDownload}
          feature="Ekspor data sedang diproses!"
          message="Ekspor data sedang diproses! Kami akan mengirimkan email kepada Anda jika sudah selesai."
          type="Konfirmasi"
        />
        <DetailDataOrganization
          handleClose={() => setDetail(!openDetail)}
          open={openDetail}
          loading={JTDetailLoading}
          data={JTDetail}
          additionalInformation={{
            'Jenis Jabatan':
              JTDetail && JTDetail.job_function && JTDetail.job_function.name,
          }}
          status={true}
          feature="Job Title"
        />
      </OrganizationContainer>
    </RootContainer>
  )
}

export default ListDesignation
