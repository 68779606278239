import {Button} from '@material-ui/core'
import React from 'react'
import {HistoryItemWrap} from '../HistoryStyles'

export default function HistoryItem({title, period, generateDate, url}) {
  return (
    <HistoryItemWrap>
      <div className="info">
        <h3 className="title">{title}</h3>
        <p className="period">Period {period}</p>
        <p className="generate-date">
          Menghasilkan pada tanggal {generateDate}
        </p>
      </div>
      <Button className="download-button" color="secondary">
        <a href={url} rel="noreferrer">
          Unduh
        </a>
      </Button>
    </HistoryItemWrap>
  )
}
