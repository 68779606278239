import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {useMutation, useQuery} from '@apollo/react-hooks'

import PolicyDetailForm from './form/PolicyDetailForm'
import RequestWrapper from '../../../shared-components/layouts/RequestWrapper'

import {COMPANY_ID} from '../../../../utils/globals'
import {EDIT_LOAN_POLICY} from '../../../../graphql/mutations'
import {GET_LOAN_POLICY} from '../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../utils'

const EditLoanPolicy = props => {
  const {enqueueSnackbar} = useSnackbar()
  const [dataForm, setDataForm] = useState(null)
  const [defaultData, setDefaultData] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const [serviceLoading, setServiceLoading] = useState(false)

  const [editLoanPolicy] = useMutation(EDIT_LOAN_POLICY, GQL_FINANCE_OPTIONS)
  const {data, error} = useQuery(GET_LOAN_POLICY, {
    variables: {
      company: COMPANY_ID,
      id: props?.policyId,
    },
    fetchPolicy: 'cache-and-network',
    ...GQL_FINANCE_OPTIONS,
  })

  useEffect(() => {
    const _data = data?.finance_loan_policies?.[0]
    const _installment = {
      installment: {
        value: _data?.installment,
        label: `${_data?.installment}x`,
      },
    }

    if (_data) {
      setDataForm({
        ..._data,
        ..._installment,
      })
      setDefaultData({
        ..._data,
        ..._installment,
      })
    }
  }, [data])

  const onEditPolicy = () => {
    setIsSubmit(true)
    if (
      dataForm.name
      // Remove the mandatory properties from fields: untill further notice
      // dataForm.loan_max_amount > 0 &&
      // dataForm.installment &&
      // dataForm.interest_rate >= 0
    ) {
      setServiceLoading(true)

      delete dataForm?.__typename
      dataForm.installment = dataForm.installment?.value || 0

      const variables = {
        ...dataForm,
      }

      editLoanPolicy({variables: variables})
        .then(() => {
          enqueueSnackbar('Loan Policy edited', {
            variant: 'success',
            autoHideDuration: 1000,
          })

          setTimeout(() => {
            props.history.push({
              pathname: '/financial/loan/settings',
              state: {active: 'settings', searchable: true},
            })

            setServiceLoading(false)
          }, 1000)
        })
        .catch(() => {
          enqueueSnackbar('Edit Loan Policy failed, please try again later', {
            variant: 'error',
            autoHideDuration: 3000,
          })
        })
    }
  }

  return (
    <RequestWrapper loading={!defaultData} error={error}>
      <PolicyDetailForm
        dataForm={dataForm}
        defaultData={defaultData}
        setDataForm={setDataForm}
        serviceLoading={serviceLoading}
        mutation={onEditPolicy}
        isSubmit={isSubmit}
      />
    </RequestWrapper>
  )
}

export default withRouter(EditLoanPolicy)
