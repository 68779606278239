import React from 'react'
import {
  TextField,
  Popover,
  Typography,
  InputAdornment,
  Button,
  makeStyles,
} from '@material-ui/core'
import {TextFieldStyled, WrapperDisable} from '../../../EmployeePageStyles'
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {GET_GLOBAL_PHONE_CODE} from '../../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'

const useStyles = makeStyles(() => ({
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
  },
  root: {
    marginBottom: 32,
  },
  rootField: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

export const PhoneField = props => {
  const {
    error,
    onChangePhone,
    value,
    placeholder,
    open,
    label,
    ...other
  } = props

  const classes = useStyles()

  const {data: dataPhone} = useQuery(GET_GLOBAL_PHONE_CODE, {
    skip: !open,
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  const handleClick = (value, popupState) => {
    onChangePhone(value)
    popupState.setOpen(false)
  }

  return (
    <div className={classes.root}>
      <p className={classes.label}>{label}</p>
      <div className={classes.rootField}>
        <PopupState variant="popover" popupId="demo-popup-popover">
          {popupState => (
            <>
              <WrapperDisable widthAuto>
                <TextFieldStyled
                  style={{
                    marginTop: 16,
                    marginBottom: 8,
                    width: 110,
                    marginRight: 14,
                    cursor: 'pointer',
                  }}
                  placeholder="Kode Negara"
                  size="small"
                  variant="outlined"
                  value={value && value[0]}
                  error={error && error[0]}
                  helperText={error && error[0] ? ' ' : null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        style={{cursor: 'pointer', paddingRight: 0}}
                        position="end"
                      >
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    ),
                  }}
                  {...bindTrigger(popupState)}
                />
              </WrapperDisable>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px 0px',
                  }}
                >
                  {dataPhone &&
                    dataPhone.global_phone_code.map((res, i) => (
                      <Button
                        key={i}
                        onClick={() => handleClick(res.code, popupState)}
                        style={
                          value && value[0] === res.code
                            ? {
                                backgroundColor: 'rgba(0, 0, 0, 0.14)',
                                justifyContent: 'flex-start',
                                cursor: 'pointer',
                                borderRadius: 0,
                              }
                            : {
                                cursor: 'pointer',
                                justifyContent: 'flex-start',
                              }
                        }
                      >
                        <Typography style={{fontSize: 14}}>
                          {res.country} ({res.code})
                        </Typography>
                      </Button>
                    ))}
                </div>
              </Popover>
            </>
          )}
        </PopupState>
        <TextField
          fullWidth
          size="small"
          margin="normal"
          type="number"
          variant="outlined"
          value={value && value[1]}
          placeholder={placeholder}
          error={error && error[1]}
          helperText={error && error[1] ? 'Bagian ini diperlukan' : null}
          inputProps={{min: 0}}
          {...other}
        />
      </div>
    </div>
  )
}
