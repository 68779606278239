import React, {Fragment, useState, useEffect} from 'react'
import MaterialTable from 'material-table'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  RootContainer,
  WhiteButton,
  TextDarkBlue,
  TextGrey,
} from '../../../../GlobalStyles'
import {Typography, Divider, Button} from '@material-ui/core'
import {useMutation, useQuery, useApolloClient} from '@apollo/react-hooks'
import {
  GET_KPI_METHOD,
  CHECK_METHOD_ALREADY_TAKEN,
  GET_BALOON_STATUS_BUTTON,
} from '../../../../graphql/queries'
import Loading from '../../../../components/loading/LoadingComponent'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Link} from 'react-router-dom'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {useSnackbar} from 'notistack'
import {
  DELETE_KPI_METHOD,
  DELETE_MULTIPLE_KPI_METHOD,
  CLOSE_BALOON,
  IMPORT_DATA,
} from '../../../../graphql/mutations'

import ImportBaloon from '../../../../components/importExcel/ImportBaloon'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import ReadMoreAndLess from 'react-read-more-less'
import DetailDataOrganization from '../../../shared-components/popup/DetailDataOrganization'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import {COMPANY_ID} from '../../../../utils/globals'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {status_data} from '../../../shared-components/data/SharedData'
import {DELETE_CONFIRMATION} from '../kpi-helper'

export default function MethodComponent(props) {
  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()
  const [openDelete, setOpenDelete] = useState(false)
  const [selected, setSelected] = useState([])
  const [state, setState] = useState({
    id: '',
    name: '',
    position: 'Method',
  })
  const [openImport, setImport] = useState(false)
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')
  const [openMultipleDelete, setOpenMultipleDelete] = useState(false)
  const [openDetail, setDetail] = useState(false)
  const [dataMethod, setDataMethod] = useState(false)
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )

  const {data: getBaloonStatus, error: errorGetBaloonStatus} = useQuery(
    GET_BALOON_STATUS_BUTTON,
    {
      wlb_skipPatch: true,
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'kpi_methods',
      },
    }
  )

  const handleClickOpenImport = () => {
    setImport(true)
  }
  const handleCloseImport = () => {
    setImport(false)
  }

  const [deleteMethod] = useMutation(DELETE_KPI_METHOD)
  const [deleteMultipleMethod] = useMutation(DELETE_MULTIPLE_KPI_METHOD)
  const [dialogCantDel, setDialogCantDel] = useState(false)
  const onDeleteMethod = () => {
    const variables = {
      id: state.id,
    }

    deleteMethod({variables})
      .then(() => {
        enqueueSnackbar('Goal Method deleted', {
          variant: 'success',
          autoHideDuration: 500,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete method failed, please try again later', {
          variant: 'error',
          autoHideDuration: 500,
        })
      })
  }

  const {data, error, refetch} = useQuery(GET_KPI_METHOD, {
    wlb_skipPatch: true,
    variables: {
      search: `%${props.searchText}%`,
    },
  })

  const [skipQueryCheckMethod, setSkipQueryCheckMethod] = useState(true)
  const {data: checkMethodAlreadyTaken} = useQuery(CHECK_METHOD_ALREADY_TAKEN, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      method: state.id,
    },
    skip: skipQueryCheckMethod,
    onCompleted: () => {
      setSkipQueryCheckMethod(true)
    },
  })

  const handleClickOpen = (id, name) => {
    setState({id, name})
    setSkipQueryCheckMethod(false)
  }
  const handleClose = () => {
    setOpenDelete(false)
    setDialogCantDel(false)
  }

  useEffect(() => {
    if (checkMethodAlreadyTaken) {
      const isMethodAlreadyTaken =
        checkMethodAlreadyTaken?.kpi_lists_aggregate?.aggregate?.count > 0
      isMethodAlreadyTaken ? setDialogCantDel(true) : setOpenDelete(true)
    }
  }, [checkMethodAlreadyTaken])

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  if (
    (data === undefined && error === undefined) ||
    getBaloonStatus === undefined
  ) {
    return <Loading />
  } else if (
    error !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationImportError !== undefined
  ) {
    return console.error({
      error,
      errorGetBaloonStatus,
      mutationCloseBaloonError,
      mutationImportError,
    })
  }

  // const handleDuplicate = () => {
  //   const dataSelected = selected

  //   const result = dataSelected.map(res => ({
  //     name: res.name,
  //     description: res.description,
  //     status: res.status,
  //   }))
  //   const data = {
  //     data: result,
  //   }

  //   mutationAddMethod({variables: data})
  //     .then(() => {
  //       setSelected([])
  //       enqueueSnackbar('Success Add data', {
  //         variant: 'success',
  //         autoHideDuration: 3000,
  //       })
  //     })
  //     .catch(() => {
  //       enqueueSnackbar(
  //         'Add item failed, Format Wrong you can use this template',
  //         {
  //           variant: 'error',
  //           autoHideDuration: 3000,
  //         }
  //       )
  //     })
  // }

  const checkDeleteMultiple = async () => {
    const dataSelected = selected
    const selectedId = []

    dataSelected.map(res => {
      selectedId.push(res.id)
    })

    const {data: checkMethodAlreadyTaken} = await client.query({
      query: CHECK_METHOD_ALREADY_TAKEN,
      variables: {
        method: selectedId,
      },
    })

    const isMethodAlreadyTaken =
      checkMethodAlreadyTaken &&
      checkMethodAlreadyTaken.kpi_lists_aggregate &&
      checkMethodAlreadyTaken.kpi_lists_aggregate.aggregate &&
      checkMethodAlreadyTaken.kpi_lists_aggregate.aggregate.count > 0

    if (isMethodAlreadyTaken) {
      setDialogCantDel(true)
    } else {
      setOpenMultipleDelete(true)
    }
  }

  const handleMultipleDelete = () => {
    const dataSelected = selected
    const selectedId = []

    dataSelected.map(res => {
      selectedId.push(res.id)
    })

    deleteMultipleMethod({
      variables: {
        id: selectedId,
      },
    })
      .then(() => {
        setSelected([])
        enqueueSnackbar('Success Delete data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete failed, please try again later', {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const getTitle = () => {
    if (selected.length === 0) {
      return `Kode metode ${GLOBAL_GLOSSARY.performance.Goal}`
    } else {
      return (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <Fragment>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>
              <StyledMenus {...bindMenu(popupState)}>
                {/* <StyledMenuItem onClick={handleDuplicate}>
                  Duplicate
                </StyledMenuItem> */}
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    checkDeleteMultiple()
                  }}
                >
                  Hapus
                </StyledMenuItem>
              </StyledMenus>
            </Fragment>
          )}
        </PopupState>
      )
    }
  }

  const importMutation = async () => {
    try {
      await mutationImport({
        variables: {
          table: 'kpi_methods',
          file: fileURL,
        },
      })
    } catch (error) {
      console.error({error})
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
    } catch (error) {
      console.error({error})
    }
  }

  const handleOpenDetail = data => {
    setDetail(!openDetail)
    setDataMethod(data)
  }

  return (
    <RootContainer>
      <ImportBaloon
        data={getBaloonStatus}
        closeBaloonMutation={closeBaloonMutation}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
        feature="goal_library"
      />
      <PaperContainer>
        <EnhancedToolbar>
          <Typography variant="h6">
            Metode {GLOBAL_GLOSSARY.performance.Goal}
          </Typography>
          <div>
            <WhiteButton
              variant="contained"
              size="large"
              style={{marginRight: '10px'}}
              onClick={() => handleClickOpenImport()}
              disabled={
                getBaloonStatus.company_import_data_logs.length > 0 &&
                getBaloonStatus.company_import_data_logs[0].status === 'WAITING'
              }
            >
              {getBaloonStatus.company_import_data_logs.length > 0 &&
              getBaloonStatus.company_import_data_logs[0].status ===
                'WAITING' ? (
                <TextGrey>Importing on proces...</TextGrey>
              ) : (
                <TextDarkBlue>
                  Impor metode {GLOBAL_GLOSSARY.performance.Goal}
                </TextDarkBlue>
              )}
            </WhiteButton>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={{
                pathname: `/performance/kpi/method/add`,
                state: {active: 'add-method'},
              }}
            >
              Tambahkan metode {GLOBAL_GLOSSARY.performance.Goal}
            </Button>
          </div>
        </EnhancedToolbar>
        <Divider></Divider>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0'}}
          columns={[
            {
              title: getTitle(),
              field: 'code',
              render: rowData => (
                <ReadMoreAndLess
                  className="read-more-content"
                  charLimit={30}
                  readMoreText="  lebih banyak"
                  readLessText="  lebih sedikit"
                >
                  {rowData && rowData.code ? rowData.code : ''}
                </ReadMoreAndLess>
              ),
            },
            {
              title: `Nama metode ${GLOBAL_GLOSSARY.performance.Goal}`,
              field: 'name',
              sorting: false,
              render: rowData => (
                <ReadMoreAndLess
                  className="read-more-content"
                  charLimit={30}
                  readMoreText="  lebih banyak"
                  readLessText="  lebih sedikit"
                >
                  {rowData && rowData.name ? rowData.name : ''}
                </ReadMoreAndLess>
              ),
            },
            {
              title: 'Rincian',
              field: 'description',
              render: rowData => (
                <ReadMoreAndLess
                  className="read-more-content"
                  charLimit={30}
                  readMoreText="  lebih banyak"
                  readLessText="  lebih sedikit"
                >
                  {rowData && rowData.description ? rowData.description : ''}
                </ReadMoreAndLess>
              ),
            },
            {
              title: 'Status',
              field: 'status',
              lookup: {ACTIVE: 'ACTIVE', INACTIVE: 'INACTIVE'},
              render: rowData => (
                <Typography
                  style={
                    rowData && rowData.status === 'ACTIVE'
                      ? {color: 'green'}
                      : {color: 'red'}
                  }
                >
                  {rowData && rowData.status
                    ? status_data[rowData.status] || rowData.status
                    : ''}
                </Typography>
              ),
            },
            {
              title: 'Aksi',
              field: 'id',
              sorting: false,
              render: rowData => (
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <Fragment>
                      <MoreIcon {...bindTrigger(popupState)}>
                        more_horiz
                      </MoreIcon>

                      <StyledMenus {...bindMenu(popupState)}>
                        <StyledMenuItem
                          style={{justifyContent: 'left'}}
                          onClick={() => {
                            popupState.close()
                            handleOpenDetail(rowData)
                          }}
                        >
                          Rincian
                        </StyledMenuItem>
                        <StyledMenuItem
                          style={{justifyContent: 'left'}}
                          component={Link}
                          to={{
                            pathname: '/performance/kpi/method/edit',
                            state: {
                              active: 'edit-method',
                              dataMethod: rowData,
                            },
                          }}
                          variant="h6"
                        >
                          Ubah
                        </StyledMenuItem>
                        <StyledMenuItem
                          style={{justifyContent: 'left'}}
                          onClick={() => {
                            handleClickOpen(rowData.id, rowData.name)
                            popupState.close()
                          }}
                        >
                          Hapus
                        </StyledMenuItem>
                      </StyledMenus>
                    </Fragment>
                  )}
                </PopupState>
              ),
            },
          ]}
          data={data.kpi_methods}
          title=""
          options={{
            ...TableOptions,
            showTitle: false,
            toolbar: false,
            emptyRowsWhenPaging: false,
          }}
          components={{
            Toolbar: () => null,
          }}
          localization={{pagination: TableLocalization.pagination}}
          onSelectionChange={rows => setSelected(rows)}
        />
        <DeletePopup
          open={openDelete}
          handleClose={handleClose}
          id={state.id}
          name={state.name}
          mutation={() => onDeleteMethod()}
        />
        <DeletePopup
          open={openMultipleDelete}
          handleClose={() => setOpenMultipleDelete(false)}
          name="selected items"
          mutation={() => handleMultipleDelete()}
        />
        <ImportPopup
          open={openImport}
          position="Goal Method"
          handleClose={handleCloseImport}
          mutation={importMutation}
          fileTemplate={''}
          isAlreadyImported={isAlreadyImported}
          setIsAlreadyImported={setIsAlreadyImported}
          setImport={setImport}
          fileURL={fileURL}
          setFileURL={setFileURL}
          closeBaloon={closeBaloonMutation}
          errorsImport={
            getBaloonStatus.company_import_data_logs.length > 0 &&
            getBaloonStatus.company_import_data_logs[0].reports &&
            getBaloonStatus.company_import_data_logs[0].reports.failed.length >
              0
              ? getBaloonStatus.company_import_data_logs[0].reports.failed
              : []
          }
        />
        <AlertDialog
          open={dialogCantDel}
          handleClose={() => setDialogCantDel(!dialogCantDel)}
          feature={DELETE_CONFIRMATION.header}
          message={DELETE_CONFIRMATION.body.replace(
            '[feature_name]',
            `Metode ${GLOBAL_GLOSSARY.performance.Goal}`
          )}
          positiveTitleButton="Konfirmasi"
          type="Confirm"
        />
        <DetailDataOrganization
          handleClose={handleOpenDetail}
          open={openDetail}
          data={dataMethod}
          status={true}
          feature={`Metode ${GLOBAL_GLOSSARY.performance.Goal}`}
          topBgColor={true}
          title="Rincian Metode"
        />
      </PaperContainer>
    </RootContainer>
  )
}
