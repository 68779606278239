import gql from 'graphql-tag'

export const GET_POSITIONS = gql`
  {
    company_employee_positions(order_by: {name: asc}) {
      id
      name
    }
  }
`

export const GET_POSITIONS_SUBS = gql`
  subscription {
    company_employee_positions(order_by: {name: asc}) {
      id
      name
    }
  }
`
