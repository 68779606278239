import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {useMutation} from '@apollo/react-hooks'

import PolicyDetailForm from './form/PolicyDetailForm'

import {COMPANY_ID} from '../../../../utils/globals'
import {ADD_LOAN_POLICY} from '../../../../graphql/mutations'
import {GQL_FINANCE_OPTIONS} from '../../utils'

const CreateLoanPolicy = props => {
  const {enqueueSnackbar} = useSnackbar()
  const [dataForm, setDataForm] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [serviceLoading, setServiceLoading] = useState(false)

  const [addLoanPolicy] = useMutation(ADD_LOAN_POLICY, GQL_FINANCE_OPTIONS)

  const onAddPolicy = () => {
    setIsSubmit(true)
    if (
      dataForm.name
      // Remove the mandatory properties from fields: untill further notice
      // dataForm.loan_max_amount > 0 &&
      // dataForm.installment &&
      // dataForm.interest_rate >= 0
    ) {
      setServiceLoading(true)

      dataForm.installment = dataForm.installment?.value || 0

      const variables = {
        objects: [
          {
            ...dataForm,
            company: COMPANY_ID,
          },
        ],
      }

      addLoanPolicy({variables: variables})
        .then(() => {
          enqueueSnackbar('Loan Policy added', {
            variant: 'success',
            autoHideDuration: 1000,
          })

          setTimeout(() => {
            props.history.push({
              pathname: '/financial/loan/settings',
              state: {active: 'settings', searchable: true},
            })

            setServiceLoading(false)
          }, 1000)
        })
        .catch(() => {
          enqueueSnackbar('Add Loan Policy failed, please try again later', {
            variant: 'error',
            autoHideDuration: 3000,
          })

          setServiceLoading(false)
        })
    }
  }

  return (
    <PolicyDetailForm
      dataForm={dataForm}
      setDataForm={setDataForm}
      serviceLoading={serviceLoading}
      mutation={onAddPolicy}
      isSubmit={isSubmit}
    />
  )
}

export default withRouter(CreateLoanPolicy)
