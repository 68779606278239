import JobFamily from './JobFamilyPageComponent'

export const JobFamilyPageConfig = {
  routes: [
    {
      path: '/organization/job-family',
      component: JobFamily,
      state: 'job-family',
      exact: true,
    },
    {
      path: '/organization/job-family/add',
      component: JobFamily,
      state: 'add-job-family',
      exact: false,
    },
    {
      path: '/organization/job-family/edit',
      component: JobFamily,
      state: 'edit-job-family',
      exact: false,
    },
    // {
    //   path: '/organization/jf-function',
    //   component: JobFamily,
    //   state: 'jf-function',
    //   exact: true,
    // },
    // {
    //   path: '/organization/jf-function/add',
    //   component: JobFamily,
    //   state: 'add-jf-function',
    //   exact: false,
    // },
    // {
    //   path: '/organization/jf-function/edit',
    //   component: JobFamily,
    //   state: 'edit-jf-function',
    //   exact: false,
    // },
    // {
    //   path: '/organization/mapping-table',
    //   component: JobFamily,
    //   state: 'mapping-table',
    //   exact: true,
    // },
  ],
}
