import React from 'react'
import {Pie} from 'react-chartjs-2'
import {DoughnutContainer, AggregateContainer} from './DoughnutChartStyles'

const options = {
  legend: {
    display: false,
  },
}

const PieChart = props => {
  const {aggregate} = props.data
  const {data, labelType} = props

  return (
    <DoughnutContainer isColumnLabelType={labelType === 'column'}>
      <Pie data={data} options={options} />
      {aggregate && (
        <AggregateContainer>
          <span>{aggregate.value}</span>
          <span>{aggregate.label}</span>
        </AggregateContainer>
      )}
    </DoughnutContainer>
  )
}

export default PieChart
