import React from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery, useMutation} from '@apollo/react-hooks'

import clsx from 'classnames'

import {
  makeStyles,
  Box,
  Divider,
  Typography,
  Button,
  IconButton,
  Icon,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import {AssignFilter} from '@worklifebeyond/wlb-utils-components'

import {PaperContainer, EnhancedToolbar} from '../../../../../GlobalStyles'
import FieldInformationTooltip from '../../../../shared-components/tooltip/FieldInformationTooltip'
import NoDataListFree from '../../../../shared-components/NoDataListFreeComponent'
import {
  CurrencyInput,
  useInputState,
  numberFormatter,
} from '../../ClaimLimitPageStyles'
import AlertPopup from '../../../../shared-components/popup/ResultPopup'
import EmployeeBalanceTopUpList from './EmployeeTopupList'

import {COMPANY_ID} from '../../../../../utils/globals'
import {GET_COMPANY_SUMMARY} from '../../../../../graphql/queries/budget/limit/getCompanyBalance.query'
import {GET_SELECTED_EMPLOYEES} from '../../../../../graphql/queries/budget/limit/getEmployeeTopUp.query'
import {TOPUP_EMPLOYEE_BALANCE} from '../../../../../graphql/mutations/budget/limit/employeeBalance.mutation'
import {convertToAngka, convertToRupiah} from '../../../../../utils/helpers'

import {getEmployeeTopupFilterVariables} from '../../filters'
import assignFilters from '../../../../../utils/assignFilters'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },

  employeeCount: {
    marginLeft: theme.spacing(2),
  },
  paymentInfo: {
    maxWidth: 600,
    borderSpacing: theme.spacing(1.75, 1),
    marginBottom: theme.spacing(-1),
  },
  paymentInfoHeader: {
    color: theme.palette.text.secondary,
    fontWeight: '400',
  },
  paymentInfoData: {
    color: theme.palette.primary.main,
    textAlign: 'right',
  },

  root: {
    marginTop: 0,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  item: {
    marginTop: theme.spacing(4),

    '&::marker': {
      fontSize: 14,
      fontWeight: 700,
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  labelSelection: {
    fontSize: 14,
    fontWeight: 700,
  },
  labelWarn: {
    color: theme.palette.warning.dark,
    fontSize: 14,
    marginBottom: theme.spacing(2),
  },
  labelError: {
    color: theme.palette.error.main,
    maxWidth: 600 - 14 * 2,
    margin: theme.spacing(1, 1.75, 0),
  },
  count: {
    fontWeight: '700',
    marginBottom: theme.spacing(2),
  },
  input: {
    width: '100%',
    maxWidth: 600,
  },

  radioRow: {
    flexDirection: 'row',
  },

  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  stepIndicator: {
    color: theme.palette.text.secondary,
    width: theme.spacing(6),
    height: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: theme.palette.text.secondary,
  },
  stepIndicatorCurrent: {
    color: theme.palette.primary.main,
    fontWeight: '700',
    borderColor: theme.palette.primary.main,
  },
  stepIndicatorDone: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  stepLabel: {
    color: theme.palette.text.secondary,
    width: 'max-content',
    marginTop: theme.spacing(1),
    position: 'absolute',
    top: '100%',
  },
  stepLabelCurrent: {
    color: theme.palette.primary.main,
  },
  stepDivider: {
    width: theme.spacing(18),
    borderTopStyle: 'solid',
    borderTopWidth: 2,
    borderTopColor: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  stepDividerDone: {
    borderTopColor: theme.palette.primary.main,
  },

  tooltipWrapper: {
    '& .MuiIconButton-root': {
      marginTop: -4,
    },

    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(2.25),
    },
  },
}))

// Default states
const STEP_CONFIG = ['Select Employees', 'Nominal Top Up']

const STEP_SELECTION = 0 // Step 1: Employee selection
const STEP_TOPUP = 1 // Step 2: Payment nominal

const SELECT_MODE_CUSTOM = 0 // Custom employee selection
const SELECT_MODE_ALL = 1 // Select all employees

const PAYMENT_MODE_EQUAL = 0 // Each employee receives equal amount
const PAYMENT_MODE_DIVIDED = 1 // Nominal is split between each employee

const DEFAULT_STATE = {
  step: STEP_SELECTION,
  cancelOpen: false,
  confirmOpen: false,

  select: SELECT_MODE_CUSTOM,
  filter: undefined,

  payment: PAYMENT_MODE_EQUAL,
  nominal: 0,
  description: '',
}

function EmployeeTopup(props) {
  const [state, setState] = React.useState(DEFAULT_STATE)

  const mergeState = (field, value) => {
    setState(state => ({...state, [field]: value}))
  }

  const handleChange = field => event => {
    let value = event

    // `event instanceof Event` doesn't work because React uses synthetic
    // events inplace of actual event object.
    if (event?.target) {
      const target = event.target

      switch (target.type) {
        case 'number':
          value = target.valueAsNumber
          break
        case 'radio':
          value = Number(target.value)
          break
        default:
          value = target.value
          break
      }
    }

    mergeState(field, value)
  }

  // Cancellation popup
  const openCancellation = () => {
    mergeState('cancelOpen', true)
  }

  const closeCancellation = () => {
    mergeState('cancelOpen', false)
  }

  const goBack = () => {
    props.history.goBack()
  }

  return (
    <PaperContainer>
      {state.step === STEP_SELECTION && (
        <StepSelection
          state={state}
          handleChange={handleChange}
          mergeState={mergeState}
          openCancellation={openCancellation}
          history={props.history}
        />
      )}
      {state.step === STEP_TOPUP && (
        <StepTopup
          state={state}
          handleChange={handleChange}
          mergeState={mergeState}
          openCancellation={openCancellation}
          history={props.history}
        />
      )}

      <AlertPopup
        open={state.cancelOpen}
        mutation={goBack}
        handleClose={closeCancellation}
        feature="Discard Changes?"
        message="Are you sure you want to discard changes this top up?"
        type="Discard"
        status="cancel"
      />
    </PaperContainer>
  )
}

export default withRouter(EmployeeTopup)

function FormProgression(props) {
  const {step} = props.state

  const styles = useStyles()

  return (
    <div className={styles.progressContainer}>
      {STEP_CONFIG.map((label, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <div
              className={clsx(styles.stepDivider, {
                [styles.stepDividerDone]: step >= index,
              })}
            />
          )}

          <div className={styles.stepContainer}>
            <div
              className={clsx(styles.stepIndicator, {
                [styles.stepIndicatorCurrent]: step === index,
                [styles.stepIndicatorDone]: step > index,
              })}
            >
              {step > index ? <Icon>done</Icon> : `${index + 1}`}
            </div>

            <div
              className={clsx(styles.stepLabel, {
                [styles.stepLabelCurrent]: step === index,
              })}
            >
              {label}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

// Step 1: Employee Selection
function StepSelection(props) {
  const {state, mergeState, openCancellation} = props
  const {select, filter} = state

  const styles = useStyles()

  const [search, handleSearchInput] = useInputState('')
  const [pagination, setPagination] = React.useState({
    offset: 0,
    limit: 20,
  })

  const filterVariables = React.useMemo(() => {
    return {
      where: getEmployeeTopupFilterVariables({
        filterData: filter?.data,
        excludeWallets: filter?.exclude,
      }),
      whereFiltered: getEmployeeTopupFilterVariables({
        searchText: search,
        filterData: filter?.data,
        excludeWallets: filter?.exclude,
      }),
    }
  }, [search, filter])

  const {data, loading, error} = useQuery(GET_SELECTED_EMPLOYEES, {
    skip: select === SELECT_MODE_CUSTOM && !filter,
    wlb_skipPatch: true,
    variables: {
      ...filterVariables,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const handleChangeOffset = offset => {
    setPagination({...pagination, offset})
  }

  const handleChangeLimit = limit => {
    setPagination({...pagination, offset: 0, limit})
  }

  const handleRemoveWallet = walletID => {
    mergeState('select', SELECT_MODE_CUSTOM)
    mergeState('filter', {
      ...filter,
      exclude: [...(filter?.exclude || []), walletID],
    })
  }

  const handleFilterApply = data => {
    handleChangeOffset(0)
    mergeState('select', SELECT_MODE_CUSTOM)
    mergeState('filter', {data})
  }

  const handleSelectChange = event => {
    const value = Number(event.target.value)

    mergeState('select', value)

    if (value === SELECT_MODE_ALL) {
      mergeState('filter', undefined)
    }
  }

  const stepForwards = () => {
    mergeState('step', STEP_TOPUP)
  }

  return (
    <>
      <EnhancedToolbar>
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={openCancellation}
            aria-label="Go back to previous page"
            edge="start"
            className={styles.backButton}
          >
            <Icon>arrow_back</Icon>
          </IconButton>

          <Typography variant="subtitle1" style={{fontWeight: '600'}}>
            Top Up Employees
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Button
            onClick={stepForwards}
            disabled={loading || !(data?.total.aggregate.count || 0)}
            variant="contained"
            color="primary"
            size="large"
          >
            Next
          </Button>
        </Box>
      </EnhancedToolbar>

      <Divider />

      <FormProgression {...props} />

      <Box p={3} pt={0}>
        <Box className={styles.item}>
          <Typography className={styles.label}>
            Method of Select Employee
          </Typography>

          <RadioGroup
            value={select}
            onChange={handleSelectChange}
            className={styles.radioRow}
          >
            <FormControlLabel
              value={SELECT_MODE_CUSTOM}
              control={<Radio />}
              label="Custom Select"
            />
            <FormControlLabel
              value={SELECT_MODE_ALL}
              control={<Radio />}
              label="Select All (Allow to Edit)"
            />
          </RadioGroup>
        </Box>

        <Box
          className={styles.item}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={1}
        >
          <Typography className={styles.labelSelection}>
            Applied to
            {select === SELECT_MODE_ALL && ' All'} Employees
            {!loading &&
              !error &&
              ` (${data?.total.aggregate.count || 0} Employees)`}
          </Typography>
          <AssignFilter
            filters={assignFilters}
            initialSelections={filter}
            limit={6}
            onApply={handleFilterApply}
          >
            <Button
              variant="contained"
              color="primary"
              style={{marginLeft: 16}}
            >
              {select === SELECT_MODE_CUSTOM ? 'Assignee' : 'Filter'}
            </Button>
          </AssignFilter>
        </Box>
        {select === SELECT_MODE_CUSTOM && !filter ? null : (
          <Typography className={styles.labelWarn}>
            *Note: You can still change the selected employees with the &quot;
            {select === SELECT_MODE_CUSTOM ? 'Assignee' : 'Filter'}&quot; button
            on the right
          </Typography>
        )}

        <OutlinedInput
          value={search}
          onChange={handleSearchInput}
          placeholder="Search..."
          fullWidth
          inputProps={{style: {paddingTop: 14, paddingBottom: 14}}}
          startAdornment={
            <InputAdornment position="start">
              <Icon>search</Icon>
            </InputAdornment>
          }
        />

        {error ? (
          <div>{JSON.stringify(error)}</div>
        ) : !loading && select === SELECT_MODE_CUSTOM && !filter ? (
          <NoDataListFree
            message1="Sorry, No List"
            message2="Please add the employee you want to top up first"
            button={
              <AssignFilter
                filters={assignFilters}
                initialSelections={filter}
                limit={6}
                onApply={handleFilterApply}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{marginLeft: 16}}
                >
                  Assignee
                </Button>
              </AssignFilter>
            }
          />
        ) : (
          <EmployeeBalanceTopUpList
            data={data?.employees || []}
            totalCount={data?.total_filtered.aggregate.count || 0}
            loading={loading}
            offset={pagination.offset}
            limit={pagination.limit}
            onChangeOffset={handleChangeOffset}
            onChangeLimit={handleChangeLimit}
            onRemoveWallet={handleRemoveWallet}
          />
        )}
      </Box>
    </>
  )
}

// Step 2: Top up payment
function StepTopup(props) {
  const {state, handleChange, mergeState, openCancellation} = props
  const {confirmOpen, filter, payment, nominal, description} = state

  const styles = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  // Company balance, employee IDs
  const filterVariables = React.useMemo(() => {
    return {
      where: getEmployeeTopupFilterVariables({
        filterData: filter?.data,
        excludeWallets: filter?.exclude,
      }),
      whereFiltered: getEmployeeTopupFilterVariables({
        filterData: filter?.data,
        excludeWallets: filter?.exclude,
      }),
    }
  }, [filter])

  const {
    data: employeeData,
    loading: loadingEmployee,
    error: employeeError,
  } = useQuery(GET_SELECTED_EMPLOYEES, {
    wlb_skipPatch: true,
    variables: filterVariables,
  })

  const {
    data: companyData,
    loading: loadingCompany,
    error: companyError,
  } = useQuery(GET_COMPANY_SUMMARY, {
    wlb_skipPatch: true,
    variables: {companyID: COMPANY_ID},
  })

  const loading = loadingCompany || loadingEmployee
  const error = companyError || employeeError

  const employeeNominal =
    payment === PAYMENT_MODE_DIVIDED
      ? nominal / (employeeData?.employees.length || 0)
      : nominal

  const actualNominal = employeeNominal * (employeeData?.employees.length || 0)

  const companyDeposit = companyData?.in.aggregate.sum.nominal || 0
  const companyWithdraw = companyData?.out.aggregate.sum.nominal || 0
  const companyBalance = companyDeposit - companyWithdraw
  const remainingCompanyBalance = companyBalance - actualNominal

  const nominalEmpty = !nominal || employeeNominal < 0
  const nominalOverBalance = remainingCompanyBalance < 0

  // Top up employee mutation
  // eslint-disable-next-line
  const [topupEmployee, {loading: dispatching}] = useMutation(
    TOPUP_EMPLOYEE_BALANCE
  )

  // Masked currency input
  const maskedNominal = numberFormatter.format(nominal)

  const handleNominalInput = event => {
    const value = convertToAngka(event.target.value)
    mergeState('nominal', value)
  }

  // Back button
  const stepBackwards = () => {
    mergeState('step', STEP_SELECTION)
  }

  // Confirmation popup
  const openConfirmation = () => {
    mergeState('confirmOpen', true)
  }

  const closeConfirmation = () => {
    mergeState('confirmOpen', false)
  }

  const handleConfirmation = () => {
    mergeState('confirmOpen', false)

    const variables = {
      userWalletIDs: employeeData.employees.map(x => x.user.wallet.id),
      nominal: employeeNominal,
      description,
    }

    topupEmployee({variables}).then(
      ({data}) => {
        const message = data.result.message
        const success = !data.result.total_failed_topups

        enqueueSnackbar(message, {variant: success ? 'success' : 'error'})

        props.history.push({
          pathname: '/budget/limit/employee',
          state: {active: 'employee', searchable: true},
        })
      },
      () => {
        enqueueSnackbar('Balance failed to top up', {variant: 'error'})
      }
    )
  }

  return (
    <>
      <EnhancedToolbar>
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={openCancellation}
            disabled={dispatching}
            aria-label="Go back to previous page"
            edge="start"
            className={styles.backButton}
          >
            <Icon>arrow_back</Icon>
          </IconButton>

          <Typography variant="subtitle1" style={{fontWeight: '600'}}>
            Top Up Employees
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Button
            onClick={stepBackwards}
            disabled={dispatching}
            variant="outlined"
            color="primary"
            size="large"
          >
            Previous
          </Button>

          <Button
            onClick={openConfirmation}
            disabled={
              loading ||
              error ||
              dispatching ||
              nominalEmpty ||
              nominalOverBalance
            }
            variant="contained"
            color="primary"
            size="large"
            style={{marginLeft: 16}}
          >
            Submit
          </Button>
        </Box>
      </EnhancedToolbar>

      <Divider />

      <FormProgression {...props} />

      <ol className={styles.root}>
        <li className={styles.item}>
          <Typography className={styles.label}>
            Top Up Type Distribution
            <TopUpTypeExplanation />
          </Typography>

          <RadioGroup
            value={payment}
            onChange={handleChange('payment')}
            className={styles.radioRow}
          >
            <FormControlLabel
              value={PAYMENT_MODE_EQUAL}
              control={<Radio />}
              label="Equal"
            />
            <FormControlLabel
              value={PAYMENT_MODE_DIVIDED}
              control={<Radio />}
              label="Divided"
            />
          </RadioGroup>
        </li>

        <li className={styles.item}>
          <Typography className={styles.label}>Nominal*</Typography>

          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <CurrencyInput
                required
                error={nominalOverBalance}
                disabled={dispatching}
                value={maskedNominal}
                onChange={handleNominalInput}
                className={styles.input}
              />

              {!loading && !error && (
                <Typography className={styles.employeeCount}>
                  {payment === PAYMENT_MODE_EQUAL ? '×' : '÷'}{' '}
                  {employeeData.employees.length} selected employees
                </Typography>
              )}
            </Box>

            {nominalOverBalance && (
              <Typography className={styles.labelError}>
                Your balance is not enough, make sure the total balance with the
                total number of employees to top up is sufficient
              </Typography>
            )}

            {loading ? (
              <Box pt={2}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <div>{JSON.stringify(error)}</div>
            ) : (
              <table className={styles.paymentInfo}>
                <tbody>
                  <tr>
                    <th className={styles.paymentInfoHeader}>
                      {payment === PAYMENT_MODE_EQUAL
                        ? 'Grand total'
                        : 'Nominal for each employees'}
                    </th>
                    <td className={styles.paymentInfoData}>
                      {convertToRupiah(
                        payment === PAYMENT_MODE_EQUAL
                          ? actualNominal
                          : employeeNominal,
                        false
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className={styles.paymentInfoHeader}>
                      Remaining balance
                    </th>
                    <td
                      className={clsx(
                        styles.paymentInfoData,
                        nominalOverBalance && styles.labelError
                      )}
                    >
                      {convertToRupiah(remainingCompanyBalance, false)}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Box>
        </li>

        <li className={styles.item}>
          <Typography className={styles.label}>Description</Typography>
          <OutlinedInput
            disabled={dispatching}
            value={description}
            onChange={handleChange('description')}
            className={styles.input}
            placeholder="Add top up description"
            multiline
          />
        </li>
      </ol>

      <AlertPopup
        open={confirmOpen}
        mutation={handleConfirmation}
        handleClose={closeConfirmation}
        feature="Top Up Employees?"
        message="Are you sure you want to top up selected employees?"
        type="Confirm"
        status="cancel"
      />
    </>
  )
}

function TopUpTypeExplanation() {
  const styles = useStyles()

  const body = (
    <div>
      <p>
        You can specify the type of top up you want to do, default or divided
        equally.
      </p>
      <ul style={{paddingLeft: 12}}>
        <li>
          <b>Equal</b>, The nominal field to be filled is nominal that will be
          given to each person selected in the Select Employees
        </li>
        <li>
          <b>Divided</b>, The nominal field to be filled is a grand total, each
          employee will receive a grand total divided by the number of selected
          employees
        </li>
      </ul>
    </div>
  )

  return (
    <span className={styles.tooltipWrapper}>
      <FieldInformationTooltip title={body} />
    </span>
  )
}
