import {Checkbox, Radio, Typography} from '@material-ui/core'
import React from 'react'
import {CustomTypography, FlexCenters} from '../../../../../GlobalStyles'

export default function TabsQuestion({classes, data}) {
  return (
    <>
      {data.length > 0 &&
        data.map((res, i) => {
          return (
            <div className={classes.mb16} key={i}>
              <FlexCenters className={classes.mb8}>
                <CustomTypography fweight="bold">{i + 1}.</CustomTypography>
                <div
                  style={{width: '100%'}}
                  className={classes.ml12}
                  dangerouslySetInnerHTML={{__html: res.question}}
                />
              </FlexCenters>
              {res.microlearning_question_answers.map(opt => {
                return res.question_type === 0 ? (
                  <FlexCenters
                    key={opt.id}
                    className={`${classes.ml12} ${classes.mb8}`}
                  >
                    <Radio
                      checked={opt.is_correct}
                      color="primary"
                      disabled={!opt.is_correct}
                    />
                    <Typography>{opt.answer}</Typography>
                  </FlexCenters>
                ) : (
                  <FlexCenters
                    key={opt.id}
                    className={`${classes.ml12} ${classes.mb8}`}
                  >
                    <Checkbox
                      checked={opt.is_correct}
                      color="primary"
                      disabled={!opt.is_correct}
                    />
                    <Typography>{opt.answer}</Typography>
                  </FlexCenters>
                )
              })}
            </div>
          )
        })}
    </>
  )
}
