import gql from 'graphql-tag'

export const CHECK_DELETE_LIST_OF_COMP = gql`
  query checkDeleteCompetency($id: Int!) {
    competency_version_dictionaries_aggregate(where: {competency: {_eq: $id}}) {
      aggregate {
        count
      }
    }
  }
`
// soft delete
/* export const DELETE_LIST_OF_COMP = gql`
  mutation DeleteCompetency($id: bigint!) {
    update_competency_dictionaries(
      _set: {deleted_at: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
` */
// hard delete
export const DELETE_LIST_OF_COMP = gql`
  mutation DeleteCompetency($id: bigint!) {
    delete_competency_dictionaries(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`
