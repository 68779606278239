import React from 'react'
import {Typography} from '@material-ui/core'

const NotFoundComponent = ({title = 'No Data', message}) => {
  return (
    <div
      style={{
        padding: '3rem 0px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        height: '50vh',
        alignItems: 'center',
      }}
    >
      <div>
        <img
          src={require('../../assets/images/nodataemp.png')}
          alt="no-data"
          height="170"
          width="170"
        ></img>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{message}</Typography>
      </div>
    </div>
  )
}

export default NotFoundComponent
