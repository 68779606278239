import React from 'react'
import classNames from 'classnames'
import {
  Card,
  Checkbox,
  Divider,
  TextField,
  InputAdornment,
  CircularProgress,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
} from '@material-ui/core'
import helperStyle, {
  FlexBetween,
  FlexStart,
  ListStyle,
  CenterWrapper,
  TextBlack,
  TextDarkBlue,
} from '../CrossMentoringPageStyles'
import {Search} from '@material-ui/icons'

const AssigneePeopleComponent = props => {
  const classes = helperStyle()
  const {
    people,
    checked,
    title,
    search,
    setSearch,
    handleToggle,
    handleToggleAll,
    numberOfChecked,
    loading,
  } = props

  return (
    <Card
      elevation={0}
      className={classNames(classes.cardBorder, classes.width40P)}
    >
      <FlexBetween>
        <FlexStart style={{padding: 12}}>
          <Checkbox
            color="primary"
            onClick={handleToggleAll(people)}
            checked={
              numberOfChecked(people) === people.length && people.length !== 0
            }
            indeterminate={
              numberOfChecked(people) !== people.length &&
              numberOfChecked(people) !== 0
            }
            disabled={people.length === 0}
          />
          <TextBlack>{title}</TextBlack>
        </FlexStart>
      </FlexBetween>
      <Divider />
      <div style={{padding: 12}}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search .."
          fullWidth
          value={search}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Divider />
      <ListStyle>
        <div id={'people-list'}>
          {loading ? (
            <CenterWrapper>
              <CircularProgress />
            </CenterWrapper>
          ) : people && people.length > 0 ? (
            people.map((val, key) => {
              return (
                <ListItem
                  key={key}
                  style={{paddingLeft: 12, paddingRight: 12}}
                  onClick={handleToggle(val)}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={checked.indexOf(val) !== -1}
                    />
                  </ListItemIcon>
                  <ListItemAvatar>
                    <Avatar
                      alt={
                        val.global_user && val.global_user.name
                          ? val.global_user.name
                          : 'No Name'
                      }
                      src={
                        val.global_user && val.global_user.avatar
                          ? val.global_user.avatar
                          : ''
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <TextDarkBlue>
                        {val.global_user && val.global_user.name
                          ? val.global_user.name
                          : 'No Name'}
                      </TextDarkBlue>
                    }
                    secondary={
                      <TextBlack>
                        {val.company_job_profile &&
                          val.company_job_profile.title}
                      </TextBlack>
                    }
                  />
                </ListItem>
              )
            })
          ) : (
            <CenterWrapper>No Records to Display</CenterWrapper>
          )}
        </div>
      </ListStyle>
    </Card>
  )
}

export default AssigneePeopleComponent
