import React, {useState, useEffect} from 'react'
import {
  makeStyles,
  Typography,
  Button,
  Link,
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
  Card,
  CircularProgress,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import {Edit, Close, Check} from '@material-ui/icons'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
} from '../../../../../GlobalStyles'
import {LIST_COMPETENCY_ADDED_BY_COMPANY} from '../../../../../graphql/queries'
import {
  EDIT_INDIVIDUAL_LEVEL,
  DELETE_INDIVIDUAL_COMPETENCY,
} from '../../../../../graphql/mutations'
import PropTypes from 'prop-types'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import {useSnackbar} from 'notistack'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import classNames from 'classnames'
import NoDataListFree from '../../../../shared-components/NoDataListFreeComponent'

const useStyles = makeStyles(theme => ({
  competenceRoot: {},
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordion: {
    margin: '25px 0 25px 0',
  },
  accordionHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordionDetails: {
    backgroundColor: '#F7F8F9',
  },

  accordionCard: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  m24: {
    margin: 24,
  },
  redFont: {
    color: '#ef4d5e',
  },
  greenFont: {
    color: '#4caf50',
  },
  customIconPointer: {
    cursor: 'pointer',
  },
  editLevelItem: {
    marginTop: 8,
  },
  showSection: {
    visibility: 'visible',
  },
  hideSection: {
    visibility: 'hidden',
  },
}))

const IndividualCompetency = ({
  classes,
  handleDelete,
  item,
  refetchCompetencies,
  enqueueSnackbar,
}) => {
  const [individualLevel, setIndividualLevel] = React.useState(
    item.individual_level
  )
  const [isIndividualLevelEdited, setIsIndividualLevelEdited] = React.useState(
    false
  )
  const [editIndividualLevel] = useMutation(EDIT_INDIVIDUAL_LEVEL)

  const mutationEditIndividualLevel = () => {
    editIndividualLevel({variables: {id: item.id, level: individualLevel}})
      .then(() => {
        refetchCompetencies()
        setTimeout(() => {
          enqueueSnackbar(`Level updated`, {
            variant: 'success',
            autoHideDuration: 3000,
          })
        }, 3000)
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
      .finally(() => {
        setIsIndividualLevelEdited(false)
      })
  }

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <>
                <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                <StyledMenus {...bindMenu(popupState)}>
                  <StyledMenuItem
                    onClick={() => {
                      popupState.close()
                      handleDelete(item)
                    }}
                  >
                    Hapus
                  </StyledMenuItem>
                </StyledMenus>
              </>
            )}
          </PopupState>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionDetails}
      >
        <div className={classes.accordionHeader}>
          <Typography variant="subtitle1">
            {
              item.competency_version_dictionary?.competency_dictionary?.name
                ?.en
            }
          </Typography>
          <Typography variant="subtitle2">
            {item.competency_version_dictionary?.competency_version?.name}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Card className={classes.accordionCard}>
          <div className={classes.col}>
            <Typography variant="subtitle1" className={classes.m24}>
              Rincian Kompetensi
            </Typography>
            <Divider />
            <table className={classes.m24}>
              <tr>
                <td style={{width: 350}}>Kode Kompetensi</td>
                <td>
                  {
                    item.competency_version_dictionary?.competency_dictionary
                      ?.dictionary_id
                  }
                </td>
              </tr>
              <tr>
                <td>Nama Kompetensi</td>
                <td>
                  {
                    item.competency_version_dictionary?.competency_dictionary
                      ?.name?.en
                  }
                </td>
              </tr>
              <tr>
                <td>Kategori</td>
                <td>
                  {
                    item.competency_version_dictionary?.competency_dictionary
                      ?.competency_category?.name
                  }
                </td>
              </tr>
              <tr>
                <td>Level</td>
                <td>{item.individual_level}</td>
              </tr>
            </table>
          </div>
          <div className={classes.col}>
            <Typography color="primary" className={classes.m24}>
              Rincian Kesenjangan
            </Typography>

            <table className={classes.m24}>
              <tr>
                <td style={{width: 350}}>Level yang Dibutuhkan</td>
                <td>
                  <Typography>
                    {
                      item.competency_version_dictionary
                        ?.competency_model_items[0]?.required_level
                    }
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>Level Individu</td>
                <td>
                  {!isIndividualLevelEdited ? (
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>{item.individual_level}</Typography>
                      </Grid>
                      <Grid item>
                        <Edit
                          color="secondary"
                          className={classes.customIconPointer}
                          onClick={() => setIsIndividualLevelEdited(true)}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography className={classes.editLevelItem}>
                          {item.individual_level}
                        </Typography>
                      </Grid>
                      <Grid item spacing={2}>
                        <FormControl variant="outlined" size="small">
                          <Select
                            value={individualLevel}
                            onChange={e => setIndividualLevel(e.target.value)}
                          >
                            {[...Array(5)].map((_, index) => (
                              <MenuItem key={`${_}${index}`} value={index + 1}>
                                {index + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Close
                          color="error"
                          className={classNames(
                            classes.customIconPointer,
                            classes.editLevelItem
                          )}
                          onClick={() => setIsIndividualLevelEdited(false)}
                        />
                        <Check
                          color="secondary"
                          className={classNames(
                            classes.customIconPointer,
                            classes.editLevelItem
                          )}
                          onClick={() => mutationEditIndividualLevel()}
                        />
                      </Grid>
                    </Grid>
                  )}
                </td>
              </tr>
              <tr>
                <td>Rincian Kesenjangan</td>
                <td>
                  {' '}
                  <Typography
                    className={
                      item.individual_level -
                        item.competency_version_dictionary
                          ?.competency_model_items[0]?.required_level <
                      0
                        ? classes.redFont
                        : classes.greenFont
                    }
                  >
                    {item.individual_level -
                      item.competency_version_dictionary
                        ?.competency_model_items[0]?.required_level}
                  </Typography>
                </td>
              </tr>
            </table>
          </div>
        </Card>
      </AccordionDetails>
    </Accordion>
  )
}
const AddedByCompany = ({placementId, history, jobProfileId}) => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [openDelete, setOpenDelete] = useState(false)
  const [valueDelete, setValueDelete] = useState({
    id: 0,
  })
  const {
    data: dataCompetencies,
    error: errorCompetencies,
    loading: loadingCompetencies,
    refetch: refetchCompetencies,
  } = useQuery(LIST_COMPETENCY_ADDED_BY_COMPANY, {
    variables: {
      placement: placementId,
    },
  })
  const handleDelete = row => {
    setOpenDelete(true)
    setValueDelete({
      id: row.id,
      name: row.competency_version_dictionary.competency_version.name,
    })
  }

  const [deleteCompetency] = useMutation(DELETE_INDIVIDUAL_COMPETENCY)

  const mutationDelete = async () => {
    deleteCompetency({variables: {id: valueDelete.id}})
      .then(() => {
        refetchCompetencies()
        setTimeout(() => {
          enqueueSnackbar(`${valueDelete.name} removed`, {
            variant: 'success',
            autoHideDuration: 3000,
          })
        }, 3000)
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
      .finally(() => {
        setValueDelete({
          id: 0,
          name: '',
        })
      })
  }

  useEffect(() => {
    refetchCompetencies()
  }, [])

  if (loadingCompetencies) {
    return <CircularProgress />
  } else if (errorCompetencies) {
    return <Typography>{JSON.stringify(errorCompetencies)}</Typography>
  }

  return (
    <div className={classes.competenceRoot}>
      <div className={classes.rowBetween}>
        <Typography
          variant="subtitle1"
          className={
            dataCompetencies.competency_individuals.length === 0
              ? classes.hideSection
              : classes.showSection
          }
        >
          Daftar Kompetensi
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          style={{textDecoration: 'none', color: 'white'}}
          onClick={() =>
            history.push({
              pathname: `/learning/competency/detail/${placementId}/add`,
              state: {
                active: 'individual-competency',
                jobProfile: jobProfileId,
              },
            })
          }
        >
          Tambah Kompetensi
        </Button>
      </div>
      {dataCompetencies.competency_individuals.length === 0 ? (
        <NoDataListFree
          message1="Sorry, No List"
          message2="This employee doesn't have competency added by company."
        />
      ) : (
        dataCompetencies.competency_individuals.map((item, idx) => (
          <IndividualCompetency
            key={idx}
            classes={classes}
            handleDelete={handleDelete}
            item={item}
            refetchCompetencies={refetchCompetencies}
            enqueueSnackbar={enqueueSnackbar}
          />
        ))
      )}
      <DeletePopup
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        mutation={mutationDelete}
        id={valueDelete.id}
        name={valueDelete.name}
      />
    </div>
  )
}

export default AddedByCompany

AddedByCompany.propTypes = {
  placementId: PropTypes.number,
}
