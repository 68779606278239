import gql from 'graphql-tag'

export const GET_LIST_THR = gql`
  query getTHRProcesses(
    $company: uuid!
    $date_start: date
    $date_end: date
    $limit: Int
    $offset: Int
  ) {
    thr_count: finance_thr_schedules_aggregate(
      where: {
        company: {_eq: $company}
        _and: [{schedule: {_gte: $date_start}}, {schedule: {_lte: $date_end}}]
        deleted_at: {_is_null: true}
      }
    ) {
      aggregate {
        count
      }
    }
    schedules: finance_thr_schedules(
      limit: $limit
      offset: $offset
      where: {
        company: {_eq: $company}
        _and: [{schedule: {_gte: $date_start}}, {schedule: {_lte: $date_end}}]
        deleted_at: {_is_null: true}
      }
      order_by: [{schedule: desc}]
    ) {
      id
      holiday_date
      process_step
      religions
      schedule
      status
      total_aggregate: people_work_thrs_aggregate {
        aggregate {
          sum {
            final_thr
            income
            deduction
            thr
          }
        }
      }
    }
  }
`

export const GET_LIST_EMPLOYEE_THR = gql`
  query($where: people_work_thr_bool_exp, $offset: Int, $limit: Int) {
    employee_count: people_work_thr_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    employees: people_work_thr(limit: $limit, offset: $offset, where: $where) {
      id
      thr
      income
      deduction
      final_thr
      allowances
      date_sent
      date_printed
      process_step
      approve_step
      employee_placement: people_work_placement {
        id
        regno
        status
        end_date
        people_work_salaries(where: {active: {_eq: true}}) {
          currency
          value
          salary_fields
          people_profile_bank {
            id
            global_bank {
              id
              name
            }
            branch
            account_name
            account_number
          }
        }
        company_job_profile {
          id
          title
          company_employee_position {
            id
            name
          }
          company_organization {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
            global_province {
              id
              name
            }
            global_city {
              id
              name
            }
          }
        }
        global_user {
          id
          name
          avatar
        }
      }
    }
  }
`

export const GET_LIST_APPROVAL_THR = gql`
  query(
    $login: uuid!
    $schedule_id: Int
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $search: String
  ) {
    employee_count: people_work_thr_aggregate(
      where: {
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
            company_profile: {
              finance_payroll_settings: {
                _or: [
                  {
                    first_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                  {
                    second_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                  {
                    third_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                ]
              }
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    employees: people_work_thr(
      limit: $limit
      offset: $offset
      where: {
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
            company_profile: {
              finance_payroll_settings: {
                _or: [
                  {
                    first_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                  {
                    second_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                  {
                    third_job_profile: {
                      people_work_placements: {user: {_eq: $login}}
                    }
                  }
                ]
              }
            }
          }
        }
      }
    ) {
      id
      approve_step
      process_step
      thr
      income
      deduction
      final_thr
      allowances
      date_sent
      date_printed
      employee_placement: people_work_placement {
        id
        regno
        status
        end_date
        people_work_salaries(where: {active: {_eq: true}}) {
          currency
          value
          salary_fields
          people_profile_bank {
            id
            global_bank {
              id
              name
            }
            branch
            account_name
            account_number
          }
        }
        company_job_profile {
          id
          title
          company_employee_position {
            id
            name
          }
          company_organization {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
            global_province {
              id
              name
            }
            global_city {
              id
              name
            }
          }
        }
        global_user {
          id
          name
          avatar
        }
      }
    }
  }
`

export const GET_IS_APPROVER_THR = gql`
  query($login: uuid, $company: uuid!) {
    finance_payroll_settings_by_pk(id: $company) {
      first_approval_job_profile
      second_approval_job_profile
      third_approval_job_profile
      first_job_profile {
        id
        title
      }
      second_job_profile {
        id
        title
      }

      third_job_profile {
        id
        title
      }
      is_first: first_job_profile {
        id
        people_work_placements(where: {user: {_eq: $login}}) {
          user
          global_user {
            id
            name
          }
        }
      }

      is_second: second_job_profile {
        id
        people_work_placements(where: {user: {_eq: $login}}) {
          user
          global_user {
            id
            name
          }
        }
      }

      is_third: third_job_profile {
        id
        people_work_placements(where: {user: {_eq: $login}}) {
          user
          global_user {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_RELIGIONS = gql`
  query getReligions($religions: [Int!]) {
    global_religions(
      where: {id: {_in: $religions}, deleted_at: {_is_null: true}}
      order_by: [{id: asc}]
    ) {
      id
      name
    }
  }
`
