import React, {Fragment, useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {Link, withRouter} from 'react-router-dom'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  GenerateTooltip,
  WhiteButton,
  TextDarkBlue,
} from '../../../../GlobalStyles'
import MaterialTable from 'material-table'
import ReadMoreAndLess from 'react-read-more-less'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import ModalDetail from '../modal/ModalDetail'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'

// graphql
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_LIST_GOAL,
  GET_LIST_TASK,
} from '../../../../graphql/queries/goals/getListGoal.query'
import {TableOptions} from '../../../shared-components/table/TableOptions'

// global js
import {TableRow, TableCell, makeStyles, Tabs, Tab} from '@material-ui/core'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {
  GET_EXPORT_DISABLED_BUTTON,
  GET_STATUS_EXPORT_BALOON,
} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {
  CLOSE_BALOON_EXPORT,
  DELETE_TASK,
  EXPORT_MUTATION,
} from '../../../../graphql/mutations'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import {disbaledButtonExport} from '../../../../utils/helpers'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import ChoosePeriodPopup from '../../../shared-components/popup/ChoosePeriodPopup'
import {status_update_task} from '../../../shared-components/data/SharedData'
import {TASK_STATUS_IDN} from '../constant'
import DeletePopup from '../../../shared-components/popup/DeletePopup'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#F7F8F9'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
  tableStyle: {padding: '1.6rem 1.6rem 0', marginTop: '-20px;'},
}))

const ListComponent = ({state, searchText, filterData, history, setIsGoal}) => {
  const {feature = 'Goal'} = state
  const classes = useStyles()
  const INITIAL_TAB = feature === 'Goal' ? 0 : 1
  const INITIAL_ACTION = {
    open: false,
    action: '',
    feature: '',
    message: '',
    id: '',
  }

  const [openDetail, setOpenDetail] = useState(false)
  const [openAction, setOpenAction] = useState(INITIAL_ACTION)
  const [tabsValue, setTabsValue] = useState(INITIAL_TAB)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const {enqueueSnackbar} = useSnackbar()
  const [openExport, setExport] = useState(false)

  const isGoal = tabsValue === 0

  const OpenModalDetail = id => {
    setSelectId(id)
    setOpenDetail(true)
  }
  const CloseModalDetail = () => {
    setOpenDetail(false)
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
    setIsGoal(newValue === 0)
  }

  const [selectedId, setSelectId] = useState(0)
  const query = isGoal
    ? GET_LIST_GOAL
    : GET_LIST_TASK(filterData?.include?.assignee)
  const {data, refetch, error, loading} = useQuery(query, {
    variables: {
      company: COMPANY_ID,
      search: `%${searchText}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      isCompany: true,
      isPersonal: true,
      ...filterData.include,
    },
  })

  const {
    data: getExportBaloon,
    error: errorGetExportBaloon,
    loading: loadingExportBaloon,
  } = useQuery(GET_STATUS_EXPORT_BALOON, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'performance_goal_all',
    },
  })

  const {
    data: getExportDisabled,
    error: errorGetExportDisabled,
    loading: loadingExportDisabled,
  } = useQuery(GET_EXPORT_DISABLED_BUTTON, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'performance_goal_all',
    },
  })

  const [deleteTask] = useMutation(DELETE_TASK)
  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )

  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  const handleDownload = ({startDate, endDate}) => {
    setExport(false)
    const format = 'YYYY-MM-DD'
    const start_date = moment(startDate).format(format)
    const end_date = moment(endDate).format(format)
    mutationExport({
      variables: {
        table: 'performance_goal_all',
        ...(startDate &&
          endDate && {
            filter: {
              start_date,
              end_date,
            },
          }),
      },
    })
      .then(() => {
        enqueueSnackbar('Generate Data is processing!', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        // refetch()
      })
      .catch(() => {
        enqueueSnackbar('Generate Data failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const onDeleteTask = () => {
    deleteTask({
      variables: {
        id: openAction.id,
      },
    })
      .then(() => {
        enqueueSnackbar('Task deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete Task failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  useEffect(() => {
    refetch()
  }, [])

  if (
    (data === undefined && error === undefined) ||
    loadingExportBaloon ||
    loadingExportDisabled
  ) {
    return (
      <TableRow>
        <TableCell colSpan="4">Loading...</TableCell>
      </TableRow>
    )
  }
  if (error) {
    console.error(
      error ||
        errorGetExportDisabled ||
        errorGetExportBaloon ||
        closeBaloonExportError ||
        mutationExportError
    )
  }

  const dataTable = isGoal ? data?.performance_goals : data?.performance_tasks

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleDetailAction = (popupState, rowData) => {
    if (isGoal) {
      OpenModalDetail(rowData.id)
      popupState.close()
    } else {
      history.push({
        pathname: '/performance/goal/detail/' + rowData.id,
        state: {
          active: 'detail-goal-task',
          feature: isGoal ? 'Goal' : 'Task',
        },
      })
    }
  }

  const handleEditAction = (popupState, editCondition, rowData) => {
    if (editCondition) {
      history.push({
        pathname: '/performance/goal/edit/' + rowData.id,
        state: {
          active: 'edit-goal',
          feature: isGoal ? 'Goal' : 'Task',
        },
      })
    } else {
      setOpenAction({
        open: true,
        action: 'alert',
        feature: 'Unable to Edit',
        message:
          'Unable to edit because cascading goal used in this goal is inactive',
      })
      popupState.close()
    }
  }

  const handleDeleteAction = (popupState, isInactive, rowData) => {
    if (isInactive) {
      setOpenAction({
        open: true,
        action: 'alert',
        feature: 'Unable to Delete',
        message:
          'Unable to delete task because the task cascading status is inactive.',
      })
    } else {
      setOpenAction({
        open: true,
        action: 'delete',
        feature: rowData.name,
        id: rowData.id,
      })
    }
    popupState.close()
  }

  const column = [
    {
      title: `Nama ${
        isGoal
          ? GLOBAL_GLOSSARY.performance.Goal_short
          : GLOBAL_GLOSSARY.performance.Task
      }`,
      field: 'name',
      render: rowData => (
        <div style={{width: '150px'}}>
          <ReadMoreAndLess
            className="read-more-content"
            charLimit={30}
            readMoreText="Lebih banyak"
            readLessText="Lebih sedikit"
          >
            {rowData.name + ' '}
          </ReadMoreAndLess>
        </div>
      ),
    },
    {
      title: `ID ${
        isGoal
          ? GLOBAL_GLOSSARY.performance.Goal_short
          : GLOBAL_GLOSSARY.performance.Task
      }`,
      field: 'id',
      render: rowData => (
        <Typography variant="subtitle2">{rowData.id}</Typography>
      ),
    },
    {
      title: `Tipe ${
        isGoal
          ? GLOBAL_GLOSSARY.performance.Goal_short
          : GLOBAL_GLOSSARY.performance.Task
      }`,
      field: 'goal_type',
      render: rowData => {
        const goal_types =
          rowData?.kpi_list?.kpi_cascading_lists_aggregate?.aggregate?.count ||
          rowData?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate
            ?.aggregate?.count > 0
            ? 'Company Goal'
            : 'Personal Goal'
        return <Typography variant="subtitle2">{goal_types}</Typography>
      },
    },
    {
      title: 'Perspektif',
      field: 'perspective',
      render: rowData => (
        <ReadMoreAndLess
          className="read-more-content"
          charLimit={30}
          readMoreText="Lebih banyak"
          readLessText="Lebih sedikit"
        >
          {rowData?.kpi_list?.kpi_perspective?.name ||
            rowData?.performance_goal?.kpi_list?.kpi_perspective?.name ||
            ''}
        </ReadMoreAndLess>
      ),
    },
    {
      title: 'Tanggal Mulai',
      field: 'start_date',
      render: rowData => (
        <ReadMoreAndLess
          className="read-more-content"
          charLimit={30}
          readMoreText="Lebih banyak"
          readLessText="Lebih sedikit"
        >
          {moment(new Date(rowData?.startdate)).format('DD MMMM YYYY')}
        </ReadMoreAndLess>
      ),
    },
    {
      title: 'Tanggal Selesai',
      field: 'end_date',
      render: rowData => (
        <ReadMoreAndLess
          className="read-more-content"
          charLimit={30}
          readMoreText="Lebih banyak"
          readLessText="Lebih sedikit"
        >
          {moment(new Date(rowData?.enddate)).format('DD MMMM YYYY')}
        </ReadMoreAndLess>
      ),
    },
    {
      title: 'Pembuat',
      field: 'creator',
      render: rowData => (
        <ReadMoreAndLess
          className="read-more-content"
          charLimit={30}
          readMoreText="Lebih banyak"
          readLessText="Lebih sedikit"
        >
          {rowData?.global_user?.name || rowData?.created_by_user?.name}
        </ReadMoreAndLess>
      ),
    },
    {
      title: 'Perkembangan',
      field: 'progress',
      render: rowData => (
        <Typography variant="subtitle2" style={{color: '#007fb2'}}>
          {`${rowData?.progress_percentage?.toFixed(2) ?? 0} %`}
        </Typography>
      ),
    },
    {
      title: 'Status',
      field: 'Status',
      render: rowData => {
        const status = isGoal
          ? rowData?.total_task?.aggregate?.count ===
              rowData?.complete_task?.aggregate?.count &&
            rowData?.total_task?.aggregate?.count !== 0
            ? status_update_task.COMPLETED
            : status_update_task.TODO
          : TASK_STATUS_IDN[rowData.status?.toUpperCase()]
        const isInactiveCascading =
          rowData?.performance_goal?.kpi_list?.kpi_cascading_lists?.[0]?.cascading_main?.status?.toLowerCase() !==
          'active'
        const isCompanyType =
          rowData?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate
            ?.aggregate?.count > 0
        const isInactive = !isGoal && isCompanyType && isInactiveCascading
        const isComplete =
          status?.toLowerCase() === 'completed' ||
          status?.toLowerCase() === 'complete'
        return (
          <>
            <Typography
              variant="subtitle2"
              style={{
                color: isComplete ? '#4caf50' : '#039be5',
              }}
            >
              {status}
            </Typography>
            {isInactive && (
              <Typography
                variant="subtitle2"
                style={{
                  color: '#EF4D5E',
                  marginLeft: 3,
                }}
              >
                (Inactive)
              </Typography>
            )}
          </>
        )
      },
    },
    {
      title: 'Aksi',
      field: 'id',
      sorting: false,
      render: rowData => {
        const goal_types =
          rowData?.kpi_list?.kpi_cascading_lists_aggregate?.aggregate?.count > 0
        const status_cascading =
          rowData?.kpi_list?.kpi_cascading_lists?.[0]?.cascading_main?.status?.toLowerCase() ===
            'active' || !goal_types
        const isInactiveCascading =
          rowData?.performance_goal?.kpi_list?.kpi_cascading_lists?.[0]?.cascading_main?.status?.toLowerCase() !==
          'active'
        const isCompanyType =
          rowData?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate
            ?.aggregate?.count > 0
        const isInactive = !isGoal && isCompanyType && isInactiveCascading
        const editCondition = isGoal ? status_cascading : !isInactive
        return (
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <Fragment>
                <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                <StyledMenus {...bindMenu(popupState)}>
                  <StyledMenuItem
                    justifyContent="left"
                    onClick={() => {
                      handleDetailAction(popupState, rowData)
                    }}
                  >
                    Rincian
                  </StyledMenuItem>

                  <StyledMenuItem
                    justifyContent="left"
                    onClick={() =>
                      handleEditAction(popupState, editCondition, rowData)
                    }
                    variant="h6"
                  >
                    Ubah
                  </StyledMenuItem>

                  {!isGoal && (
                    <StyledMenuItem
                      justifyContent="left"
                      fontColor="#EF4D5E"
                      onClick={() => {
                        handleDeleteAction(popupState, isInactive, rowData)
                      }}
                    >
                      Hapus
                    </StyledMenuItem>
                  )}
                </StyledMenus>
              </Fragment>
            )}
          </PopupState>
        )
      },
    },
  ]

  return (
    <>
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
        isReport
      />
      <PaperContainer>
        <EnhancedToolbar>
          <Typography variant="h6">{`Daftar ${
            isGoal
              ? GLOBAL_GLOSSARY.performance.Goal
              : GLOBAL_GLOSSARY.performance.Task
          }`}</Typography>
          <div>
            <GenerateTooltip
              title={
                getExportBaloon?.company_export_data_logs?.[0]?.status ===
                  'FAILED' ||
                disbaledButtonExport(
                  getExportDisabled?.company_export_data_logs?.[0]?.date_added
                )
                  ? `you can only generate data once a day. to download data use the link that already generated`
                  : ``
              }
              placement="left"
            >
              <span>
                <WhiteButton
                  variant="contained"
                  size="large"
                  style={{
                    marginRight: 12,
                  }}
                  disabled={
                    getExportBaloon?.company_export_data_logs?.[0]?.status ===
                      'FAILED' ||
                    disbaledButtonExport(
                      getExportDisabled?.company_export_data_logs?.[0]
                        ?.date_added
                    )
                  }
                  onClick={() => setExport(true)}
                >
                  <TextDarkBlue>Buat Laporan</TextDarkBlue>
                </WhiteButton>
              </span>
            </GenerateTooltip>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={{
                pathname: `/performance/goal/create`,
                state: {
                  active: 'create-goal',
                  feature: isGoal ? 'Goal' : 'Task',
                },
              }}
            >
              Buat Daftar{' '}
              {isGoal
                ? GLOBAL_GLOSSARY.performance.Goal
                : GLOBAL_GLOSSARY.performance.Task}
            </Button>
          </div>
        </EnhancedToolbar>
        <Tabs
          value={tabsValue}
          onChange={handleChangeTabs}
          indicatorColor="secondary"
          classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
        >
          <Tab
            label={GLOBAL_GLOSSARY.performance.Goal}
            className={classes.tabRoot}
          />
          <Tab
            label={GLOBAL_GLOSSARY.performance.Task}
            className={classes.tabRoot}
          />
        </Tabs>
        <MaterialTable
          classes={classes.tableStyle}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <NoDataListComponent search={searchText} />
              ),
            },
          }}
          columns={column}
          data={dataTable}
          options={{
            ...TableOptions,
            selection: false,
            showTitle: false,
            toolbar: false,
            emptyRowsWhenPaging: false,
          }}
          isLoading={loading}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={data.total.aggregate.count}
          page={pagination.offset}
          pageSize={pagination.limit}
        />
      </PaperContainer>
      {openDetail && (
        <ModalDetail
          open={openDetail}
          handleClose={CloseModalDetail}
          id={selectedId}
        />
      )}
      <ChoosePeriodPopup
        open={openExport}
        onClose={() => setExport(false)}
        onConfirm={handleDownload}
        enableFuture
        custom
      />
      <AlertDialog
        open={openAction.open && openAction.action === 'alert'}
        handleClose={() => setOpenAction(INITIAL_ACTION)}
        feature={openAction.feature}
        message={openAction.message}
        type="Confirm"
      />
      <DeletePopup
        open={openAction.open && openAction.action === 'delete'}
        handleClose={() => setOpenAction(INITIAL_ACTION)}
        name={openAction.feature}
        description="Tindakan ini akan menghapus seluruh data"
        mutation={() => onDeleteTask()}
        title="rencana aksi ini"
        isClose={false}
        isTitle={true}
      />
    </>
  )
}

export default withRouter(ListComponent)
