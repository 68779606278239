import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'

import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
} from '../../GlobalStyles'
import FieldInformationTooltip from '../../pages/shared-components/tooltip/FieldInformationTooltip'

export default function DateInputGroup(props) {
  const {
    dataForm,
    setDataForm,
    dateFormat = 'yyyy-MM-dd',
    fieldLabel,
    fieldNumber,
    fieldName,
    isRequired = true,
    pickerViews,
    tooltipMesssage,
    ...restProps
  } = props

  return (
    <FormChildContainer>
      <FormChildTitle>
        <TitleNumber>{fieldNumber}.</TitleNumber>
        <TitleName>{fieldLabel}</TitleName>
        {tooltipMesssage && <FieldInformationTooltip title={tooltipMesssage} />}
      </FormChildTitle>

      <FormChildInput>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            style={{width: '100%'}}
            format={dateFormat}
            inputVariant="outlined"
            placeholder="Select start date"
            required={isRequired}
            size="small"
            value={dataForm[fieldName] || null}
            views={pickerViews}
            onChange={value => {
              setDataForm({
                ...dataForm,
                [fieldName]: value,
              })
            }}
            {...restProps}
          />
        </MuiPickersUtilsProvider>
      </FormChildInput>
    </FormChildContainer>
  )
}
