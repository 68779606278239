import React, {useLayoutEffect, useState} from 'react'
// import SchedulePayrollSettings from './payroll-settings-tabs/SchedulePayrollSettings'
import ComponentPayrollSettings from './payroll-settings-tabs/ComponentPayrollSettings'
import THRPayrollSettings from './payroll-settings-tabs/THRPayrollSettings'
import TaxPayrollSettings from './payroll-settings-tabs/tax-payroll/TaxPayrollWrapper.component'
import BPJSKesPayrollSetting from './payroll-settings-tabs/BPJSKesPayrollSetting'
import BPJSTKPayrollSetting from './payroll-settings-tabs/BPJSTKPayrollSetting'
import GeneralPayrollSettings from './payroll-settings-tabs/GeneralPayrollSetting'
import PremiumPayrollSettings from './payroll-settings-tabs/PremiumPayrollSettings'
import {withRouter} from 'react-router-dom'

const renderComponent = (
  activeComponent,
  setActiveComponent,
  generalSettingData
) => {
  switch (activeComponent) {
    case 0:
      return (
        // <SchedulePayrollSettings
        //   setActiveComponent={setActiveComponent}
        //   activeComponent={activeComponent}
        // />
        <GeneralPayrollSettings
          setActiveComponent={setActiveComponent}
          activeComponent={activeComponent}
          generalSettingData={generalSettingData}
        />
      )
    case 1:
      return (
        <ComponentPayrollSettings
          setActiveComponent={setActiveComponent}
          activeComponent={activeComponent}
          generalSettingData={generalSettingData}
        />
      )
    case 2:
      return (
        <THRPayrollSettings
          setActiveComponent={setActiveComponent}
          activeComponent={activeComponent}
          generalSettingData={generalSettingData}
        />
      )
    case 3:
      return (
        <BPJSKesPayrollSetting
          setActiveComponent={setActiveComponent}
          activeComponent={activeComponent}
          generalSettingData={generalSettingData}
        />
      )
    case 4:
      return (
        <BPJSTKPayrollSetting
          setActiveComponent={setActiveComponent}
          activeComponent={activeComponent}
          generalSettingData={generalSettingData}
        />
      )
    // Hotfix: temporary hide
    // case 5:
    //   return (
    //     <PremiumPayrollSettings
    //       setActiveComponent={setActiveComponent}
    //       activeComponent={activeComponent}
    //       generalSettingData={generalSettingData}
    //     />
    //   )
    case 5:
      return (
        <TaxPayrollSettings
          setActiveComponent={setActiveComponent}
          activeComponent={activeComponent}
          generalSettingData={generalSettingData}
        />
      )
    default:
      return (
        <GeneralPayrollSettings
          setActiveComponent={setActiveComponent}
          activeComponent={activeComponent}
          generalSettingData={generalSettingData}
        />
      )
  }
}

const PayrollSettings = props => {
  const [value, setValue] = useState(0)
  const {state} = props.location
  useLayoutEffect(() => {
    if (state) {
      setValue(state.tabValue)
    } else {
      setValue(0)
    }
  }, [])

  return <>{renderComponent(value, setValue, props.generalSettingData)}</>
}

export default withRouter(PayrollSettings)
