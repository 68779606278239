import gql from 'graphql-tag'

export const BADGE_INDIVIDUAL = gql`
  query($placement: Int) {
    people_work_placements(where: {id: {_eq: $placement}}) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
        company_organization {
          id
          name
        }
        company_employee_position {
          id
          name
        }
        company_employee_rank {
          id
          name
        }
        company_address {
          id
          office_name
        }
      }
      competency_individuals_aggregate(
        where: {
          competency_version_dictionary: {
            competency_version: {status: {_eq: "ACTIVE"}}
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const LIST_INDIVIDUAL_COMPETENCY = gql`
  query($company: uuid, $search: String, $offset: Int, $limit: Int) {
    total: people_work_placements_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_placements(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        code
        title
      }
      competency_individuals_aggregate(
        where: {
          competency_version_dictionary: {
            competency_version: {status: {_eq: "ACTIVE"}}
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const LIST_COMPETENCY_ADDED_BY_COMPANY = gql`
  query($placement: Int) {
    competency_individuals(
      where: {
        placement: {_eq: $placement}
        competency_version_dictionary: {deleted_at: {_is_null: true}}
      }
    ) {
      id
      individual_level: level
      competency_version_dictionary {
        deleted_at
        id
        competency_version {
          id
          name
          date_start
          date_end
          status
        }
        competency_dictionary {
          id
          dictionary_id
          name
          description
          type
          competency_category {
            id
            name
          }
        }
        competency_model_items(
          where: {
            company_job_profile: {
              people_work_placements: {id: {_eq: $placement}}
            }
          }
        ) {
          id
          required_level: level
        }
      }
    }
  }
`
// doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/eca6bb4432293b2cbc761ce0632d2595f6b7a274/learning/GET%20COMPETENCIES%20ADDED%20BY%20USER.md
export const LIST_COMPETENCY_ADDED_BY_USER = gql`
  query($userId: uuid!, $limit: Int, $offset: Int, $query: String) {
    learning_diary_competencies(
      where: {user_id: {_eq: $userId}, name: {_ilike: $query}}
      limit: $limit
      offset: $offset
    ) {
      name
      description
    }
  }
  # query($user: uuid!) {
  #   learning_log_competencies(
  #     where: {created_by: {_eq: $user}, title: {_neq: ""}}
  #   ) {
  #     id
  #     title
  #   }
  # }
`

export const LIST_COMPETENCIES_DICTIONARIES = gql`
  query($job_profile: Int, $placement: Int) {
    competency_model_items(
      where: {
        job_profile: {_eq: $job_profile}
        competency_version_dictionary: {
          competency_version: {status: {_eq: "ACTIVE"}}
        }
        _not: {competency_individuals: {placement: {_eq: $placement}}}
      }
      order_by: [
        {
          competency_version_dictionary: {
            competency_dictionary: {dictionary_id: asc}
          }
        }
      ]
    ) {
      competency_version_dictionary {
        id
        competency_dictionary {
          dictionary_id
          name
        }
      }
    }
  }
`

export const LIST_COMPETENCIES_DICTIONARIES_MODEL = gql`
  query($job_profile: Int) {
    competency_version_dictionaries(
      where: {
        competency_version: {status: {_eq: "ACTIVE"}}
        _or: [
          {_not: {competency_model_items: {}}}
          {_not: {competency_model_items: {job_profile: {_eq: $job_profile}}}}
        ]
      }
    ) {
      id
      competency_dictionary {
        id
        dictionary_id
        name
      }
    }
  }
`
