import gql from 'graphql-tag'

// Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/Dictionaries/EDIT%20DICTIONARY.md
export const UPDATE_COMPETENCY = gql`
  mutation(
    $id: bigint!
    $dictionary_id: String!
    $name: jsonb!
    $description: jsonb
    $type: String!
    $category: Int
  ) {
    update_competency_dictionaries(
      where: {id: {_eq: $id}}
      _set: {
        dictionary_id: $dictionary_id
        name: $name
        description: $description
        type: $type
        category: $category
      }
    ) {
      affected_rows
    }
  }
`
