import {useMutation} from '@apollo/react-hooks'
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {
  FlexBetween,
  FormChildButton,
  FormChildContainerFull,
  FormContainer,
  ModalHeader,
  TitleName,
  WhiteIcon,
} from '../../../../../GlobalStyles'
import {
  ADD_ARCHIVE_OM,
  UPDATE_ARCHIVE_OM,
} from '../../../../../graphql/mutations'
import helperStyle from '../../../OrganizationPageStyles'

export default function FormArchive({handleClose, open, selected, refetch}) {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isError, setIsError] = useState(false)
  const [addArchive] = useMutation(ADD_ARCHIVE_OM)
  const [updateArchive] = useMutation(UPDATE_ARCHIVE_OM)

  useEffect(() => {
    if (open === 'edit') {
      setName(selected.name)
      setDescription(selected.description)
    }
  }, [open])

  const clear = () => {
    handleClose()
    setName('')
    setDescription('')
  }

  const handleGenerate = () => {
    const success = msg => {
      enqueueSnackbar(msg, {
        variant: 'success',
        autoHideDuration: 2500,
      })
      clear()
      refetch()
    }
    if (!name) {
      setIsError(true)
      setTimeout(() => setIsError(false), 2500)
    } else {
      if (open === 'edit') {
        updateArchive({
          variables: {
            id: selected.id,
            obj: {
              name,
              description,
            },
          },
        })
          .then(() => {
            success('Edit Archive Success')
          })
          .catch(e => console.log(e))
      } else {
        //   mutationadd
        addArchive({
          variables: {
            obj: {
              name,
              description,
              status: 'PENDING',
            },
          },
        })
          .then(() => {
            success('Generate Archive Success')
          })
          .catch(e => console.log(e))
      }
    }
  }

  return (
    <Dialog onClose={clear} open={open} fullWidth maxWidth="sm">
      <ModalHeader>
        <FlexBetween>
          <Typography variant="h6">
            {open === 'edit'
              ? 'Edit ' + selected.name
              : 'Generate Current Archive'}
          </Typography>
          <IconButton onClick={clear}>
            <WhiteIcon>close</WhiteIcon>
          </IconButton>
        </FlexBetween>
      </ModalHeader>
      <FormContainer>
        <FormChildContainerFull>
          <TitleName>Archive Name*</TitleName>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
            error={!name && isError}
            helperText={!name && isError ? 'This field is required' : ''}
          />
        </FormChildContainerFull>
        <FormChildContainerFull>
          <TitleName>Description</TitleName>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </FormChildContainerFull>
        <FormChildButton className={classes.padding0}>
          <Button size="large" onClick={clear}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeft10}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleGenerate}
          >
            {open === 'edit' ? 'Save' : 'Generate'}
          </Button>
        </FormChildButton>
      </FormContainer>
    </Dialog>
  )
}
