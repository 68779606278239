import {
  Button,
  Checkbox,
  Paper,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import helperStyle, {Flex} from '../../../../../GlobalStyles'
import {REAST_API_URL, TOKEN} from '../../../../../utils/globals'
import Axios from 'axios'
import classNames from 'classnames'

export default function CreateCurriculum({
  style,
  setOpenAdd,
  courseId,
  selected,
  getCourseById,
  sectionId,
  setSectionId,
}) {
  const classes = helperStyle()
  const initialStateAnswer = [
    {
      answer: '',
      is_correct: true,
    },
    {
      answer: '',
      is_correct: false,
    },
    {
      answer: '',
      is_correct: false,
    },
    {
      answer: '',
      is_correct: false,
    },
  ]
  const [questionType, setType] = useState('')
  const [stateAnswer, setAnswer] = useState(initialStateAnswer)

  const [state, setState] = useState({
    sectionTitle: '',
    sectionObjective: '',
  })

  useEffect(() => {
    if (sectionId) {
      getSectionById()
    }
  }, [])

  useEffect(() => {
    // logic untuk fill data edit
    if (selected) {
      // setQuestion(selected.question)
      setType(selected.question_type === 0 ? 'mChoice' : 'mAnswer')
      setAnswer(selected.microlearning_question_answers)
    }
  }, [selected])

  const handleMoreAnswer = () => {
    setAnswer([
      ...stateAnswer,
      {
        answer: '',
        is_correct: false,
      },
    ])
  }

  const handleChangeAnswer = (e, i) => {
    stateAnswer[i].answer = e.target.value
    setAnswer([...stateAnswer])
  }

  const handleRadio = newCorrectIndex => {
    const prevCorrectIndex = stateAnswer.findIndex(i => i.is_correct)
    if (prevCorrectIndex !== -1) {
      stateAnswer[prevCorrectIndex].is_correct = false
    }
    stateAnswer[newCorrectIndex].is_correct = true
    setAnswer([...stateAnswer])
  }

  const onCancel = () => {
    setSectionId(false)
    setOpenAdd(false)
    setState({
      sectionTitle: '',
      sectionObjective: '',
    })
  }

  const handleCheckbox = (e, i) => {
    stateAnswer[i].is_correct = e.target.checked
    setAnswer([...stateAnswer])
  }

  const getSectionById = async () => {
    try {
      const response = await Axios.get(
        `${REAST_API_URL}/academy/section/${sectionId}`,
        {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      setState({
        sectionTitle: response.data.title,
        sectionObjective: response.data.objective[0],
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onEditSection = async () => {
    try {
      await Axios.patch(
        `${REAST_API_URL}/academy/section/${sectionId}/update`,
        {
          title: state.sectionTitle,
          objective: [state.sectionObjective],
        },
        {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )
      setSectionId(false)
      setState({
        sectionTitle: '',
        sectionObjective: '',
      })
      getCourseById()
    } catch (error) {
      console.error(error)
    }
  }

  const onSaveSection = async () => {
    try {
      await Axios.post(
        `${REAST_API_URL}/academy/section/create`,
        {
          courseId,
          // courseId: 284,
          objective: [state.sectionObjective],
          order: 1,
          title: state.sectionTitle,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )
      setOpenAdd(false)
      setState({
        sectionTitle: '',
        sectionObjective: '',
      })
      getCourseById()
    } catch (error) {
      console.error(error)
    }
  }

  const renderAnswer = () => (
    <div className={classes.marginBot8}>
      <Typography variant="body2" className={classes.marginTop16} paragraph>
        Jawaban
      </Typography>

      {questionType === 'mChoice' ? (
        stateAnswer.map((ans, i) => (
          <div style={style.answerContainer} key={i}>
            <Radio
              color="primary"
              checked={ans.is_correct}
              onChange={() => handleRadio(i)}
            />
            <TextField
              fullWidth
              InputProps={{disableUnderline: true}}
              placeholder="Tambah jawaban"
              value={ans.answer}
              onChange={e => handleChangeAnswer(e, i)}
            ></TextField>
          </div>
        ))
      ) : questionType === 'mAnswer' ? (
        stateAnswer.map((ans, i) => (
          <div color="primary" style={style.answerContainer} key={i}>
            <Checkbox
              color="primary"
              checked={ans.is_correct}
              onChange={e => handleCheckbox(e, i)}
            />
            <TextField
              fullWidth
              InputProps={{disableUnderline: true}}
              placeholder="Tambah jawaban"
              value={ans.answer}
              onChange={e => handleChangeAnswer(e, i)}
            ></TextField>
          </div>
        ))
      ) : questionType === 'sAnswer' ? (
        <TextField
          variant="outlined"
          multiline
          rows="4"
          fullWidth
          value="this is the answer"
          className="bg-white mb-16"
        />
      ) : null}
      {stateAnswer.length < 5 && (
        <Typography
          variant="body2"
          style={{color: '#039BE5', cursor: 'pointer'}}
          onClick={handleMoreAnswer}
        >
          + Tambah jawaban lain
        </Typography>
      )}
    </div>
  )

  const disabledButton = !state.sectionTitle || !state.sectionObjective

  return (
    <Paper style={style.expansion} className={classes.padding24}>
      <div className={`${classes.marginBot20}`}>
        <Typography variant="body2">Judul Bagian</Typography>

        <TextField
          variant="outlined"
          value={state.sectionTitle}
          onChange={e => setState({...state, sectionTitle: e.target.value})}
          fullWidth
          margin="normal"
          style={{background: 'white'}}
          placeholder="Tambah Judul Bagian"
        ></TextField>
      </div>
      <div className={classes.marginBot20}>
        <Typography variant="body2" className={classes.marginBot16}>
          Apa yang dapat pelajar lakukan pada akhir bagian ini?
        </Typography>
        <TextField
          variant="outlined"
          value={state.sectionObjective}
          onChange={e =>
            setState({
              ...state,
              sectionObjective: e.target.value,
            })
          }
          fullWidth
          margin="normal"
          style={{background: 'white'}}
          placeholder="Tambahkan tujuan pembelajaran"
        ></TextField>
      </div>
      {questionType && renderAnswer()}
      <Flex style={{justifyContent: 'flex-end'}}>
        <Button
          size="large"
          className={classNames(classes.marginRight10, classes.textRed)}
          onClick={onCancel}
        >
          Batal
        </Button>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={sectionId ? onEditSection : onSaveSection}
          disabled={disabledButton}
        >
          {sectionId ? 'Ubah' : 'Tambah'}
        </Button>
      </Flex>
    </Paper>
  )
}
