import React from 'react'
import {
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
  FormChildInputHalf,
} from '../../../../../GlobalStyles'
import {TextField, MenuItem} from '@material-ui/core'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {selectProps} from '../../../../../utils/helpers'

export default function FormAbout({values, handleChange, isError}) {
  return (
    <FormContainer id="top">
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Periode Name</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            value={values.name}
            onChange={handleChange('name')}
            placeholder="Enter the name"
            error={!values.name && isError}
            helperText={!values.name && isError ? 'This field is required' : ''}
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Description</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
            value={values.description}
            onChange={handleChange('description')}
            placeholder="Enter the description"
            error={!values.description && isError}
            helperText={
              !values.description && isError ? 'This field is required' : ''
            }
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>Start Date</TitleName>
        </FormChildTitle>
        <FormChildInputHalf>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={values.start_date}
              onChange={handleChange('start_date')}
              margin="normal"
            />
          </MuiPickersUtilsProvider>
        </FormChildInputHalf>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>End Date</TitleName>
        </FormChildTitle>
        <FormChildInputHalf>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={values.end_date}
              onChange={handleChange('end_date')}
              margin="normal"
              minDate={values.start_date}
            />
          </MuiPickersUtilsProvider>
        </FormChildInputHalf>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>5.</TitleNumber>
          <TitleName>Status</TitleName>
        </FormChildTitle>
        <FormChildInputHalf>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            select
            value={values.status}
            onChange={handleChange('status')}
            SelectProps={selectProps(values.status)}
            error={!values.status && isError}
            helperText={
              !values.status && isError ? 'This field is required' : ''
            }
          >
            <MenuItem value="" disabled>
              Select status
            </MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </TextField>
        </FormChildInputHalf>
      </FormChildContainer>
    </FormContainer>
  )
}
