import OfficePage from './OfficePageComponent'

export const OfficePageConfig = {
  routes: [
    {
      path: '/company/office',
      component: OfficePage,
      state: 'office',
      exact: true,
    },
    {
      path: '/company/office/add',
      component: OfficePage,
      state: 'add-office',
      exact: false,
    },
    {
      path: '/company/office/edit',
      component: OfficePage,
      state: 'edit-office',
      exact: false,
    },
    {
      path: '/company/office/detail',
      component: OfficePage,
      state: 'detail-office',
      exact: false,
    },
  ],
}
