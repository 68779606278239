import React from 'react'

import {TextField, MenuItem} from '@material-ui/core'

import PerHour from './standard-type/PerHour'
import BasedOnSalary from './standard-type/BasedOnSalary'
import {TitleName} from '../../../../../GlobalStyles'
import {selectProps} from '../../../../../utils/helpers'
import {
  STANDARD_BASE_ON_SALARY,
  STANDARD_PERHOUR,
} from './standard-type/helpers'

export default function PayWithMoney(props) {
  const {classes, standard, setStandard, setMoneyCompensation, isError} = props

  const handleChangeStandard = e => {
    const value = e.target.value
    setStandard(value)
    switch (value) {
      case STANDARD_PERHOUR: {
        setMoneyCompensation({IDR: '0'})
        break
      }
      case STANDARD_BASE_ON_SALARY: {
        // eslint-disable-next-line no-unused-vars
        const multiplierArr = Array.from({length: 12}, (_, id) => ({
          id,
          value: '',
        }))
        setMoneyCompensation({multipliers: multiplierArr, divisor: 173})
        break
      }
      default:
        break
    }
  }

  return (
    <div>
      <TitleName>Overtime Paid with Money Calculated with</TitleName>
      <div className={classes.flexPerHour}>
        <SelectStandard
          isError={isError}
          classes={classes}
          standard={standard}
          setStandard={setStandard}
          onChange={handleChangeStandard}
        />
      </div>
      {standard === STANDARD_PERHOUR && <PerHour {...props} />}
      {standard === STANDARD_BASE_ON_SALARY && <BasedOnSalary {...props} />}
    </div>
  )
}

const SelectStandard = props => {
  const {classes, standard = '', onChange, isError} = props

  const isErrorStandard = !standard && isError

  return (
    <TextField
      id="standard-select"
      select
      style={{width: '50%'}}
      fullWidth
      value={standard}
      onChange={onChange}
      SelectProps={selectProps(standard)}
      margin="normal"
      variant="outlined"
      error={isErrorStandard}
      helperText={isErrorStandard && 'This field is required'}
      InputProps={{
        inputProps: {
          placeholder: 'Choose Standard',
        },
        classes: {
          input: classes.outlinedInput,
        },
      }}
    >
      <MenuItem value="" disabled>
        Choose Standard
      </MenuItem>
      <MenuItem value={STANDARD_PERHOUR}>Per Hour</MenuItem>
      <MenuItem value={STANDARD_BASE_ON_SALARY}>Based on Salary</MenuItem>
    </TextField>
  )
}
