import gql from 'graphql-tag'

export const GET_HOLDING_INSTRUCTOR = gql`
  query($offset: Int, $limit: Int) {
    people_work_placements(
      offset: $offset
      limit: $limit
      where: {global_user: {roles: {_eq: "110"}}}
    ) {
      id
      company_job_profile {
        position
        title
      }
      company_profile {
        brand_name
        legal_name
      }
      global_user {
        id
        name
        avatar
      }
    }
  }
`
