import gql from 'graphql-tag'

export const GET_ALL_RANK = gql`
  subscription getListRank {
    company_employee_ranks {
      id
      code
      name
    }
  }
`
