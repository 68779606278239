import React from 'react'

import clsx from 'classnames'
import {makeStyles, Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  input: {
    height: 0,
    width: 0,
    overflow: 'hidden',
    visibility: 'hidden',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(8.75),
    width: theme.spacing(8.75),
  },
}))

export default function UploadButton(props) {
  const {className, style, multiple, disabled, onClick, onChange} = props

  const styles = useStyles()
  const inputRef = React.useRef()

  const handleChange = ev => {
    if (onChange) {
      onChange(ev)
    }

    ev.target.value = ''
  }

  const handleClick = ev => {
    if (onClick) {
      onClick(ev)
    }

    if (!ev.defaultPrevented) {
      inputRef.current.click()
    }
  }

  return (
    <>
      <input
        ref={inputRef}
        onChange={handleChange}
        type="file"
        multiple={multiple}
        className={styles.input}
      />

      <Button
        disabled={disabled}
        onClick={handleClick}
        variant="contained"
        className={clsx(styles.button, className)}
        style={style}
      >
        <AddIcon fontSize="large" color="secondary" />
      </Button>
    </>
  )
}
