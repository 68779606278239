import React from "react";
import FieldInformationTooltip from "../../../../shared-components/tooltip/FieldInformationTooltip";
import { TooltipExpiredBalanceContent } from "../../LeavePageStyles";

const TooltipContent = () => {
  return (
    <TooltipExpiredBalanceContent>
      <p>Balance will expired after duration is finished (Maximum balance duration is 12 months)</p>
      <div className="content">
        <p className="ex">Ex: </p>
        <p>
          Quota Renewable Every is set on January and Balance Duration is set
          for 2 months, so the Carry Over will be expired in March
        </p>
      </div>
    </TooltipExpiredBalanceContent>
  );
};

export default function ExpiredBalanceTooltip() {
  return <FieldInformationTooltip title={<TooltipContent />} />;
}
