import React from 'react'
import {EnhancedToolbar} from '../../../../GlobalStyles'
import {
  Typography,
  Divider,
  makeStyles,
  Paper,
  TextField,
  MenuItem,
} from '@material-ui/core'
import {persentaseData, dataNotDefined} from '../../../../utils/helpers'

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  input: {
    marginRight: '1rem',
    width: '158px',
  },
  divRoot: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 36px 36px',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  parentLinier: {
    width: '100%',
    height: '10px',
    borderRadius: '8px',
    backgroundColor: '#edf0f5',
    marginBottom: 8,
  },
  childLinier: {
    height: '10px',
    borderRadius: '8px',
  },
  score: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#a9a8a8',
    marginBottom: 8,
  },
  bar: {
    width: '45%',
    marginRight: '1rem',
    marginTop: '2rem',
  },
  title: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}))

const TalentInformation = ({
  data,
  // organization,
  // office,
  position,
  condTalentInformation,
  setCondTalentInformation,
}) => {
  const classes = useStyles()
  const changeFilters = name => e => {
    setCondTalentInformation({...condTalentInformation, [name]: e.target.value})
  }

  const customPersentase = (a, b) => {
    let nilai = 0
    if (a === 0 && b === 0) {
      nilai = 0
    } else {
      nilai = (a / b) * 100
    }
    if (nilai > 100) {
      return Math.floor(100)
    } else {
      return Math.floor(nilai)
    }
  }

  return (
    <div className={classes.root}>
      <Paper>
        <EnhancedToolbar>
          <Typography variant="h6" color="primary">
            Talent Information
          </Typography>
          <div
            style={{display: 'flex', marginTop: '1rem', marginBottom: '1rem'}}
          >
            {/* <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Organization"
              value={condTalentInformation.organization}
              onChange={changeFilters('organization')}
            >
              <MenuItem value="all">All Organization</MenuItem>
              {organization &&
                organization.company_organizations.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Office Location"
              value={condTalentInformation.office}
              onChange={changeFilters('office')}
            >
              <MenuItem value="all">All Office</MenuItem>
              {office &&
                office.company_addresses.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.office_name}
                  </MenuItem>
                ))}
            </TextField> */}
            <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Position"
              value={condTalentInformation.jobProfile}
              onChange={changeFilters('jobProfile')}
            >
              <MenuItem value="all">All Position</MenuItem>
              {position &&
                position.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </EnhancedToolbar>
        <Divider />
        <div className={classes.divRoot}>
          <div className={classes.bar}>
            <Typography variant="h6" color="primary">
              Impact of Loss
            </Typography>
            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#ff6565'}}
              >
                High
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {data && data.impact_high.aggregate.count} of{' '}
                  {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      data && data.impact_high.aggregate.count,
                      data && data.total.aggregate.count
                    )}%`,
                    backgroundColor: '#ff6565',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    data && data.impact_high.aggregate.count,
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#ffa000'}}
              >
                Medium
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {' '}
                  {data && data.impact_medium.aggregate.count} of{' '}
                  {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      data && data.impact_medium.aggregate.count,
                      data && data.total.aggregate.count
                    )}%`,
                    backgroundColor: '#ffa000',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    data && data.impact_medium.aggregate.count,
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#4caf50'}}
              >
                Low
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {data && data.impact_low.aggregate.count} of{' '}
                  {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      data && data.impact_low.aggregate.count,
                      data && data.total.aggregate.count
                    )}%`,
                    backgroundColor: '#4caf50',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    data && data.impact_low.aggregate.count,
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#a9a8a8'}}
              >
                Not Defined
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {dataNotDefined(
                    data && data.total.aggregate.count,
                    data && data.impact_high.aggregate.count,
                    data && data.impact_medium.aggregate.count,
                    data && data.impact_low.aggregate.count
                  )}{' '}
                  of {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      dataNotDefined(
                        data && data.total.aggregate.count,
                        data && data.impact_high.aggregate.count,
                        data && data.impact_medium.aggregate.count,
                        data && data.impact_low.aggregate.count
                      ),
                      data && data.total.aggregate.count
                    )}`,
                    backgroundColor: '#a9a8a8',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    dataNotDefined(
                      data && data.total.aggregate.count,
                      data && data.impact_high.aggregate.count,
                      data && data.impact_medium.aggregate.count,
                      data && data.impact_low.aggregate.count
                    ),
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.bar}>
            <Typography variant="h6" color="primary">
              Risk of Loss
            </Typography>
            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#ff6565'}}
              >
                High
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {data && data.risk_high.aggregate.count} of{' '}
                  {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      data && data.risk_high.aggregate.count,
                      data && data.total.aggregate.count
                    )}`,
                    backgroundColor: '#ff6565',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    data && data.risk_high.aggregate.count,
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#ffa000'}}
              >
                Medium
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {data && data.risk_medium.aggregate.count} of{' '}
                  {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      data && data.risk_medium.aggregate.count,
                      data && data.total.aggregate.count
                    )}%`,
                    backgroundColor: '#ffa000',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    data && data.risk_medium.aggregate.count,
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#4caf50'}}
              >
                Low
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {data && data.risk_low.aggregate.count} of{' '}
                  {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      data && data.risk_low.aggregate.count,
                      data && data.total.aggregate.count
                    )}%`,
                    backgroundColor: '#4caf50',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    data && data.risk_low.aggregate.count,
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#a9a8a8'}}
              >
                Not Defined
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {dataNotDefined(
                    data && data.total.aggregate.count,
                    data && data.risk_high.aggregate.count,
                    data && data.risk_medium.aggregate.count,
                    data && data.risk_low.aggregate.count
                  )}{' '}
                  of {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      dataNotDefined(
                        data && data.total.aggregate.count,
                        data && data.risk_high.aggregate.count,
                        data && data.risk_medium.aggregate.count,
                        data && data.risk_low.aggregate.count
                      ),
                      data && data.total.aggregate.count
                    )}%`,
                    backgroundColor: '#a9a8a8',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    dataNotDefined(
                      data && data.total.aggregate.count,
                      data && data.risk_high.aggregate.count,
                      data && data.risk_medium.aggregate.count,
                      data && data.risk_low.aggregate.count
                    ),
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.bar}>
            <Typography variant="h6" color="primary">
              Future Leader
            </Typography>
            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#4caf50'}}
              >
                Yes
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {data && data.future_yes.aggregate.count} of{' '}
                  {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      data && data.future_yes.aggregate.count,
                      data && data.total.aggregate.count
                    )}%`,
                    backgroundColor: '#4caf50',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    data && data.future_yes.aggregate.count,
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>

            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#039be5'}}
              >
                No
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {data && data.future_no.aggregate.count} of{' '}
                  {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      data && data.future_no.aggregate.count,
                      data && data.total.aggregate.count
                    )}%`,
                    backgroundColor: '#039be5',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    data && data.future_no.aggregate.count,
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>

            <div>
              <Typography
                variant="body1"
                className={classes.title}
                style={{color: '#a9a8a8'}}
              >
                Not Defined
              </Typography>
              <div className={classes.score}>
                <Typography variant="body2">Talent</Typography>
                <Typography variant="body2">
                  {dataNotDefined(
                    data && data.total.aggregate.count,
                    data && data.future_yes.aggregate.count,
                    data && data.future_no.aggregate.count,
                    0
                  )}{' '}
                  of {data && data.total.aggregate.count}
                </Typography>
              </div>
              <div className={classes.parentLinier}>
                <div
                  className={classes.childLinier}
                  style={{
                    width: `${customPersentase(
                      dataNotDefined(
                        data && data.total.aggregate.count,
                        data && data.future_yes.aggregate.count,
                        data && data.future_no.aggregate.count,
                        0
                      ),
                      data && data.total.aggregate.count
                    )}`,
                    backgroundColor: '#a9a8a8',
                  }}
                />
              </div>
              <div className={classes.score}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2">
                  {persentaseData(
                    dataNotDefined(
                      data && data.total.aggregate.count,
                      data && data.future_yes.aggregate.count,
                      data && data.future_no.aggregate.count,
                      0
                    ),
                    data && data.total.aggregate.count
                  )}
                  %
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  )
}
export default TalentInformation
