import gql from 'graphql-tag'

export const GET_LIST_MICROLEARNING = gql`
  query($offset: Int!, $limit: Int!, $createdByOrgId: uuid, $search: String!) {
    microlearnings(
      offset: $offset
      limit: $limit
      where: {
        name: {_ilike: $search}
        created_by_org_id: {_eq: $createdByOrgId}
      }
      order_by: [{date_added: desc}]
    ) {
      id
      slug
      description
      total_finished
      total_earned
      enroll_count: microlearning_members_aggregate(
        where: {is_valid: {_eq: true}}
      ) {
        aggregate {
          count
        }
      }
      microlearning_reviews_aggregate {
        aggregate {
          avg {
            star
          }
          count
        }
      }
      academy_course_category {
        id
        name
      }
      global_user {
        id
        name
        avatar
      }
      name
      date_added
      status
      price
      type
      icon_url
      created_by_user_id
    }
    microlearnings_aggregate(
      where: {
        name: {_ilike: $search}
        created_by_org_id: {_eq: $createdByOrgId}
      }
      order_by: [{date_added: asc}]
    ) {
      aggregate {
        count
      }
    }
  }
`
