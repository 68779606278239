import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import MaterialTable from 'material-table'
import Select from 'react-select'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import {TextField, InputAdornment} from '@material-ui/core'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'

import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import helperStyle from '../../../../../GlobalStyles'
import {
  convertToAngka,
  convertToRoundedRupiah,
  currencyMaskingMethod,
} from '../../../../../utils/helpers'
import {TableOptions} from '../../../payroll/PayrollPageStyles'

const InstallmentTableForm = props => {
  const {
    dataForm,
    defaultData,
    isAbleToEdit,
    isSubmit,
    rowChangesId,
    setDataForm,
    setRowChangesId,
  } = props

  const handleChangeDataFormValue = (rowId, fieldName, value) => {
    if (!rowChangesId[rowId]) {
      setRowChangesId({
        ...rowChangesId,
        [rowId]: true,
      })
    }

    setDataForm({
      ...dataForm,
      [rowId]: {
        ...dataForm[rowId],
        [fieldName]: value,
      },
    })
  }

  const INSTALLMENT_COLUMNS = [
    {
      title: 'No',
      field: 'tableData',
      align: 'center',
      cellStyle: {minWidth: isAbleToEdit ? null : 100},
      render: rowData => rowData.tableData.id + 1,
    },
    {
      title: 'Plan Date',
      field: 'plan_date',
      cellStyle: {minWidth: 160},
      render: rowData => {
        return isAbleToEdit ? (
          <DateInputForm
            dataForm={dataForm}
            handleChangeDataFormValue={handleChangeDataFormValue}
            fieldName="plan_date"
            placeholder="Select plan date"
            rowData={rowData}
          />
        ) : (
          moment(rowData.plan_date).format('ll')
        )
      },
    },
    {
      title: 'Status',
      field: 'status',
      cellStyle: {minWidth: isAbleToEdit ? 150 : 100},
      render: rowData => {
        return isAbleToEdit ? (
          <Select
            options={STATUS_OPTIONS}
            placeholder="Select status"
            searchable={false}
            defaultValue={rowData.status}
            onChange={opt =>
              handleChangeDataFormValue(rowData.id, 'status', opt.value)
            }
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral50: '#bfbfbf',
              },
            })}
          />
        ) : (
          rowData.status?.label
        )
      },
    },
    {
      title: 'Plan Amount',
      field: 'plan_amount',
      align: 'right',
      cellStyle: {minWidth: 180, maxWidth: 200, paddingTop: 5},
      render: rowData => {
        return isAbleToEdit ? (
          <NumberInputForm
            key={rowData.id}
            dataForm={dataForm}
            handleChangeDataFormValue={handleChangeDataFormValue}
            isSubmit={isSubmit}
            fieldName="plan_amount"
            placeholder="Input plan amount"
            rowData={rowData}
          />
        ) : (
          convertToRoundedRupiah(rowData.plan_amount || 0)
        )
      },
    },
    {
      title: 'Main Payment',
      field: 'main_payment',
      align: 'right',
      cellStyle: {minWidth: 180, maxWidth: 200, paddingTop: 5},
      render: rowData => {
        return isAbleToEdit ? (
          <NumberInputForm
            key={rowData.id}
            dataForm={dataForm}
            handleChangeDataFormValue={handleChangeDataFormValue}
            isSubmit={isSubmit}
            fieldName="main_payment"
            placeholder="Input main payment"
            rowData={rowData}
          />
        ) : (
          convertToRoundedRupiah(rowData.main_payment || 0)
        )
      },
    },
    {
      title: 'Interest Payment',
      field: 'interest_payment',
      align: 'right',
      cellStyle: {minWidth: 160},
      render: rowData => {
        return convertToRoundedRupiah(rowData.interest_payment || 0)
      },
    },
    {
      title: 'Actual Date',
      field: 'actual_date',
      cellStyle: {minWidth: 160},
      render: rowData => {
        return isAbleToEdit ? (
          <DateInputForm
            dataForm={dataForm}
            handleChangeDataFormValue={handleChangeDataFormValue}
            fieldName="actual_date"
            placeholder="Select actual date"
            rowData={rowData}
          />
        ) : (
          moment(rowData.actual_date).format('ll')
        )
      },
    },
    {
      title: 'Paid in Periode',
      field: 'paid_in_period',
      cellStyle: {minWidth: 150},
      render: rowData => {
        return isAbleToEdit ? (
          <DateInputForm
            dataForm={dataForm}
            format={'MMM, yyyy'}
            handleChangeDataFormValue={handleChangeDataFormValue}
            fieldName="paid_in_period"
            placeholder="Select paid in period"
            rowData={rowData}
            views={['month', 'year']}
          />
        ) : (
          moment(rowData.paid_in_period).format('MMM, YYYY')
        )
      },
    },
    {
      title: 'Actual Amount',
      field: 'actual_payment',
      align: 'right',
      cellStyle: {minWidth: 180, maxWidth: 200, paddingTop: 5},
      render: rowData => {
        return isAbleToEdit ? (
          <NumberInputForm
            key={rowData.id}
            dataForm={dataForm}
            handleChangeDataFormValue={handleChangeDataFormValue}
            isSubmit={isSubmit}
            fieldName="actual_payment"
            placeholder="Input actual amount"
            rowData={rowData}
          />
        ) : (
          convertToRoundedRupiah(rowData.actual_payment)
        )
      },
    },
  ]

  return (
    <MaterialTable
      data={defaultData}
      columns={INSTALLMENT_COLUMNS}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <NoDataListComponent message="The loan installment has not been completely generated, please refresh the browser page in a moment" />
          ),
        },
      }}
      options={{
        ...TableOptions,
        draggable: false,
        maxBodyHeight: isAbleToEdit ? null : '600px',
        paging: false,
        sorting: false,
        fixedColumns: {
          left: isAbleToEdit ? 1 : 0,
        },
      }}
    />
  )
}

const STATUS_OPTIONS = [
  {
    value: 'overdue',
    label: 'Overdue',
  },
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'unpaid',
    label: 'Unpaid',
  },
]

const NumberInputForm = ({
  dataForm,
  handleChangeDataFormValue,
  isSubmit,
  fieldName,
  placeholder,
  rowData,
}) => {
  const classes = helperStyle()

  return (
    <TextField
      id={`text-field-${fieldName}${rowData.id}`}
      defaultValue={currencyMaskingMethod(rowData[fieldName] || null)}
      error={isSubmit && !dataForm[rowData.id][fieldName]}
      name={fieldName + rowData.id}
      margin="normal"
      variant="outlined"
      placeholder={placeholder}
      required={true}
      size="small"
      inputProps={{
        style: {textAlign: 'right'},
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
        classes: {input: classes.inputNumberPlaceholder},
      }}
      onChange={event => {
        const value = event.target.value
        const inputElement = document.getElementById(
          `text-field-${fieldName}${rowData.id}`
        )

        if (inputElement) {
          inputElement.value = currencyMaskingMethod(value)
        }

        const dataFormValue = convertToAngka(value)
        handleChangeDataFormValue(rowData.id, fieldName, dataFormValue)
      }}
    />
  )
}

const DateInputForm = ({
  dataForm,
  format,
  handleChangeDataFormValue,
  fieldName,
  placeholder,
  rowData,
  views,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        key={rowData.tableData?.id}
        format={format || 'MMM dd, yyyy'}
        inputVariant="outlined"
        name={fieldName + rowData.id}
        placeholder={placeholder}
        size="small"
        value={dataForm[rowData.id]?.[fieldName] || null}
        onChange={value =>
          handleChangeDataFormValue(rowData.id, fieldName, value)
        }
        {...views}
      />
    </MuiPickersUtilsProvider>
  )
}

export default withRouter(InstallmentTableForm)
