import gql from 'graphql-tag'

export const GET_JOB_FUNCTION = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    total: job_family_functions_aggregate(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    job_family_functions(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
            ]
          }
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
      description
      company_employee_positions {
        id
        name
      }
      company_employee_positions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_JOB_FUNCTION_DROPDOWN = gql`
  query($company: uuid!, $search: String!) {
    job_family_functions(
      where: {
        _and: [
          {company: {_eq: $company}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
            ]
          }
        ]
      }
      limit: 30
    ) {
      id
      code
      name
    }
  }
`

export const CHECK_CODE_JOB_FUNCTION = gql`
  query($company: uuid, $code: String) {
    job_family_functions_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`
