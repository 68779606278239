import React from 'react'
import {Avatar, Icon, Tooltip} from '@material-ui/core'
import {
  TyphoSection,
  WrapperItemSection,
  Style,
} from '../../DetailEmployeeStyles'
import {capitalizeEachWord} from '../../../../../../utils/helpers'
import ModalPeopleSkillEndorsement from './ModalPeopleSkillEndorsement'

const MODAL_PEOPLE_SKILL_INITIAL_STATE = {open: false, title: ''}

export const SkilsItem = ({res, onEdit}) => {
  const [dataModalPeopleSkill, setDataModalPeopleSkill] = React.useState(
    MODAL_PEOPLE_SKILL_INITIAL_STATE
  )

  const MAX_USER_THUMBNAIL = 4

  const peopleSkill =
    res.people_skill?.people_profile_skills_aggregate?.aggregate?.count > 0

  return (
    <WrapperItemSection>
      <div style={{height: peopleSkill && 100, marginRight: 23}}>
        <div style={Style.wrapperSkills}>
          <div style={Style.score}>
            {res.endorsements_skill_count?.aggregate?.count}
          </div>
          {capitalizeEachWord(res.people_skill.name)}
        </div>
      </div>

      <div className="divider" style={{paddingBottom: peopleSkill ? 30 : 0}}>
        <div className="content">
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <TyphoSection blue="true" style={{lineHeight: '34px'}}>
              {capitalizeEachWord(res.people_skill_level?.name)}
            </TyphoSection>

            <Icon onClick={onEdit} className="icon-edit">
              edit
            </Icon>
          </div>

          {peopleSkill && (
            <TyphoSection grey="true" mt="true">
              Share the same skill with :
            </TyphoSection>
          )}

          {peopleSkill && (
            <div style={{...Style.avatarGroup, margin: '0px 0px 12px'}}>
              {res.people_skill?.people_profile_skills?.map((user, idx) => {
                return (
                  <Tooltip title={user?.global_user?.name} key={idx}>
                    <Avatar
                      src={user?.global_user?.avatar}
                      style={{marginRight: 12}}
                      alt="user"
                    />
                  </Tooltip>
                )
              })}

              {res.people_skill?.people_profile_skills_aggregate?.aggregate
                .count > MAX_USER_THUMBNAIL && (
                <Avatar
                  alt="count"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setDataModalPeopleSkill({
                      open: true,
                      title: res.people_skill.name,
                      data: res.people_skill?.people_profile_skills,
                    })
                  }}
                >
                  +
                  {res.people_skill?.people_profile_skills_aggregate?.aggregate
                    .count - MAX_USER_THUMBNAIL}
                </Avatar>
              )}
            </div>
          )}
        </div>
      </div>

      <ModalPeopleSkillEndorsement
        open={dataModalPeopleSkill.open}
        user={res?.user}
        skill={res?.people_skill?.id}
        title={`People with ${capitalizeEachWord(dataModalPeopleSkill.title)} ${
          !dataModalPeopleSkill.title.includes('Skill') ? 'Skill' : ''
        }`}
        onClose={() =>
          setDataModalPeopleSkill(MODAL_PEOPLE_SKILL_INITIAL_STATE)
        }
      />
    </WrapperItemSection>
  )
}
