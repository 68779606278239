import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import MaterialTable from 'material-table'
import {
  PaperContainer,
  EnhancedToolbar,
  RootContainer,
} from '../../../../GlobalStyles'
import {
  GET_BALOON_DOWNLOAD_SUMMARY,
  GET_STATUS_BUTTON_DONWLOAD_EMPLOYEE_REPORT,
  GET_TRIP_REPORTS,
} from '../../../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../utils/globals'
import {
  TextBlueBold,
  WrapperContent,
} from '../../../organization/OrganizationPageStyles'
import {Avatar, Tooltip} from '@material-ui/core'
import {getTripFilterVariables} from '../filter'
import moment from 'moment'
import DetailComponent from './detail/DetailComponent'
import NoDataListFree from '../../../shared-components/NoDataListFreeComponent'
import ChoosePeriodPopup from '../../../shared-components/popup/ChoosePeriodPopup'
import {DOWNLOAD_DATA_REPORT} from '../../../../graphql/mutations'
import ImportBaloonMultiple from '../../../directory/download-report/page-download/ImportBaloonMultiple'
import BaloonDownloadBusinessTrip from './detail/BaloonDownloadBusinessTrip'
import RunningProcessBusinessTrip from './detail/RunningProcessBusinessTrip'
import {getTripDate} from '../businessTripHelper'

// import { RowData } from '../../../directory/employee/detail-employee/DetailEmployeeStyles'

const TableOptions = {
  search: false,
  selection: false,
  sorting: true,
  headerStyle: {
    fontWeight: 700,
    lineHeight: 1.43,
    marginTop: `-100px`,
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
  fontSize: '12px',
  selectionProps: () => ({
    color: 'primary',
  }),
  pageSize: 20,
  emptyRowsWhenPaging: false,
  pageSizeOptions: [20, 50, 100],
  toolbar: false,
}

const TripReport = props => {
  const INITIAL_DATA_PAGINATION = {
    offset: 0,
    limit: 20,
  }

  const {filterData, searchText} = props
  const [openAllBaloon, setOpenAllBaloon] = useState(false)

  const [open, setOpen] = useState({
    detail: false,
    generate: false,
  })

  const [pagination, setPagination] = useState(INITIAL_DATA_PAGINATION)

  const variables_filter = React.useMemo(() => {
    return getTripFilterVariables({
      tabsValue: 'report',
      searchText,
      filterData,
    })
  }, [searchText, filterData])

  const {data, loading, error, refetch} = useQuery(GET_TRIP_REPORTS, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: variables_filter,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const {data: dataBaloonDonloadDirectory} = useQuery(
    GET_BALOON_DOWNLOAD_SUMMARY,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'business_trip_report',
        today: moment(new Date()).format('YYYY-MM-DD'),
        tomorrow: moment(new Date())
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      },
    }
  )
  const {data: dataStatusButton} = useQuery(
    GET_STATUS_BUTTON_DONWLOAD_EMPLOYEE_REPORT,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'business_trip_report',
        today: moment(new Date()).format('YYYY-MM-DD'),
        tomorrow: moment(new Date())
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      },
    }
  )
  const [downloadData, {error: errorDownloadData}] = useMutation(
    DOWNLOAD_DATA_REPORT
  )

  useEffect(() => {
    refetch()
  }, [])

  if (error) {
    console.error(error)
  }

  const CloseModalDetail = () => {
    setOpen({
      ...open,
      detail: false,
    })
  }

  const CloseModalGenerate = () => {
    setOpen({
      ...open,
      generate: false,
    })
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      offset: 0,
      limit: row,
    })
  }
  const onTry = ({startDate, endDate}) => {
    const format = 'YYYY-MM-DD'
    const start_date = moment(startDate).format(format)
    const end_date = moment(endDate).format(format)
    downloadData({
      variables: {
        table: 'business_trip_report',
        filter: {
          start_date,
          end_date,
        },
      },
    })
  }

  const handleDownloadData = ({startDate, endDate}) => {
    setOpen({
      ...open,
      generate: false,
    })
    const format = 'YYYY-MM-DD'
    const start_date = moment(startDate).format(format)
    const end_date = moment(endDate).format(format)
    // setOpenExport(true)
    downloadData({
      variables: {
        table: 'business_trip_report',
        filter: {
          start_date,
          end_date,
        },
      },
    })
  }
  const isDisableDownload = type => {
    if (dataStatusButton) {
      if (dataStatusButton.company_export_data_logs.length > 0) {
        return type === 'message'
          ? 'You can only generate data once a day. To download the data use the link that already generated'
          : true
      }
    } else {
      return type === 'message' ? '' : false
    }
  }
  const TOTAL_PROCESS = dataBaloonDonloadDirectory
    ? dataBaloonDonloadDirectory.company_export_data_logs.length
    : 0

  const isDisable = isDisableDownload('button')
  const message = isDisableDownload('message')

  return (
    <RootContainer style={{width: '83%'}}>
      {TOTAL_PROCESS > 1 ? (
        <ImportBaloonMultiple
          totalProcess={TOTAL_PROCESS}
          onSeeAll={() => setOpenAllBaloon(true)}
          onRetry={onTry}
        />
      ) : (
        <BaloonDownloadBusinessTrip
          dataBaloonDonloadDirectory={dataBaloonDonloadDirectory}
          dataStatusButton={dataStatusButton}
          errorDownloadData={errorDownloadData}
          onRetry={onTry}
        />
      )}
      <PaperContainer>
        <EnhancedToolbar>
          <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
            Trip Report
          </Typography>
          <WrapperContent>
            <Tooltip title={message} placement="left">
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isDisable}
                  style={{marginRight: '10px'}}
                  onClick={() => {
                    setOpen({
                      generate: true,
                    })
                  }}
                >
                  Generate Data
                </Button>
              </span>
            </Tooltip>
          </WrapperContent>
        </EnhancedToolbar>
        <Divider></Divider>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontFamily: 'muli'}}
          columns={[
            {
              title: 'Request ID',
              field: 'id_display',
              headerStyle: {minWidth: 200},
              cellStyle: {minWidth: 200},
            },
            {
              title: 'Avatar',
              field: 'name',
              render: rowData => <Avatar src={rowData.global_user.avatar} />,
            },
            {
              title: 'Employee ID',
              field: 'people_work_placement.regno',
              headerStyle: {minWidth: 100},
            },
            {
              title: 'Employee Name',
              field: 'global_user.name',
              headerStyle: {minWidth: 200},
              cellStyle: {minWidth: 200},
            },
            {
              title: 'Position',
              field: 'people_work_placement.company_job_profile.title',
              headerStyle: {minWidth: 200},
              cellStyle: {minWidth: 200},
            },
            {
              title: 'Trip Date',
              render: rowData => {
                const {date_start, date_end} = rowData
                return getTripDate(date_start, date_end)
              },
              headerStyle: {minWidth: 300},
              cellStyle: {minWidth: 300},
              field: `date_start`,
            },
            {
              title: 'Action',
              field: 'id',
              sorting: false,
              headerStyle: {minWidth: 100},
              cellStyle: {minWidth: 100},
              render: rowData => (
                <TextBlueBold
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setOpen({
                      detail: true,
                      id: rowData.id,
                    })
                  }}
                >
                  Details
                </TextBlueBold>
              ),
            },
          ]}
          data={data && data.reports}
          title=""
          isLoading={loading}
          options={TableOptions}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={(data && data.total.aggregate.count) || 0}
          page={pagination.offset}
          pageSize={pagination.limit}
          components={{
            Toolbar: () => null,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <NoDataListFree
                  message1="Sorry, No List"
                  message2={'No one has applied for trip'}
                />
              ),
            },
          }}
        />

        <DetailComponent
          key={open.id}
          open={open.detail}
          id={open.id}
          handleClose={CloseModalDetail}
        />
        <ChoosePeriodPopup
          open={open.generate}
          onClose={CloseModalGenerate}
          onConfirm={handleDownloadData}
          enableFuture
        />
        <RunningProcessBusinessTrip
          open={openAllBaloon}
          onClose={() => {
            setOpenAllBaloon(false)
          }}
          data={dataBaloonDonloadDirectory}
          onRetry={onTry}
        />
      </PaperContainer>
    </RootContainer>
  )
}

export default TripReport
