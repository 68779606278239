import gql from 'graphql-tag'

export const TOPUP_EMPLOYEE_BALANCE = gql`
  mutation($userWalletIDs: [String!]!, $nominal: Float!, $description: String) {
    result: topUpEmployees(
      user_wallet_ids: $userWalletIDs
      nominal: $nominal
      description: $description
    ) {
      message
      transaction_id
      formatted_id
      total_successful_top_ups
      total_failed_top_ups
    }
  }
`

export const EDIT_EMPLOYEE_CLAIM_LIMIT = gql`
  mutation($userWalletID: String!, $nominal: Float!) {
    editClaimLimit(user_wallet_id: $userWalletID, nominal: $nominal) {
      success
    }
  }
`
