import styled from 'styled-components'

export const CardContainer = styled.div`
  flex: 1;
  margin: 0 10px;
`

export const CaptionContainter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 30%;
`

export const CaptionLabel = styled.span`
  color: ${props => (props.isList ? 'black' : '#a9a8a8')};
  font-size: 0.8rem;
  margin-right: 16px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
`

export const CaptionLabelDetail = styled.span`
  color: ${props => (props.isList ? 'black' : '#a9a8a8')};
  font-size: 0.8rem;
  margin-right: 16px;
  width: 100%;
`

export const ListContainer = styled.div`
  padding-bottom: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ProgressLineBarWrap = styled.div`
  width: 78%;
  background-color: #f7f8f9;
  border-radius: 5px;
  height: 20px;
  display: flex;
  align-items: center;

  .bar {
    position: relative;
    height: 20px;
    width: ${props => props.percent}%;
    background-color: #039be5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  p {
    color: #fff;
    font-size: 10px;
    margin: 0px;
    margin-right: 9px;
  }

  .absolute {
    position: absolute;
  }

  .ml-12 {
    margin-left: 12px;
  }

  .color-secondary {
    color: #039be5;
  }
`
