import React, {useState, useEffect, useContext} from 'react'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  FlexBetween,
  WrapperContent,
  TextBlackBold,
  StyledTree,
  // WhiteButton,
  // TextDarkBlue,
} from '../../OrganizationPageStyles'
import {
  Toolbar,
  Icon,
  IconButton,
  Divider,
  // Button,
  CircularProgress,
} from '@material-ui/core'
import {
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  PaperContainer,
} from '../../../../GlobalStyles'
import {Link} from 'react-router-dom'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {
  LIST_JOB_PROFILE_TREE,
  CHECK_PARENT_POSITION,
} from '../../../../graphql/queries/index'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {ADD_IMPORT_JOB_PROFILE} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import DetailJobProfileModal from './modal-job-profile/DetailJobProfileModal'
import DeleteAlertModal from './modal-job-profile/DeleteAlertModal'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import {DataContext} from '../../../../ContextConfig'
// import classNames from 'classnames'
import {COMPANY_ID} from '../../../../utils/globals'

const DetailJobProfile = props => {
  const {id, jobName, parentChecker} = props
  const [treeData, setTreeData] = useState([])
  const [position, setPosition] = useState({
    id: 0,
    namePosition: '',
  })
  const {enqueueSnackbar} = useSnackbar()
  const {dataContext, setDataContext} = useContext(DataContext)
  const [openImport, setImport] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)
  const [progresLoading, setProgresLoading] = useState(true)
  const [notification, setNoification] = useState({
    notifDelete: false,
    notifAlert: false,
    notifAlertCantDel: false,
  })
  const [parentName, setParentName] = useState('')
  // const [parentData, setParentData] = useState({})
  const classes = helperStyle()

  const {data, loading, error, refetch} = useQuery(LIST_JOB_PROFILE_TREE, {
    variables: {
      organization: id,
    },
  })

  const {refetch: refetchParent} = useQuery(CHECK_PARENT_POSITION, {
    variables: {
      company: COMPANY_ID,
    },
  })
  const [mutationAdd] = useMutation(ADD_IMPORT_JOB_PROFILE)

  const clearUseContext = () => {
    setDataContext(null)
  }

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
      clearUseContext()
    }, 2000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [dataContext])

  useEffect(() => {
    refetchParent()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (data && treeData.length === 0) {
        const temp = []
        const topParent =
          data &&
          data.company_job_profiles.filter(val => {
            return (
              (val.job_profile_supervisor
                ? val.job_profile_supervisor.organization
                : 0) !== id
            )
          })
        data &&
          data.company_job_profiles.forEach(val => {
            temp.push({
              ...val,
              // id: val.id,
              // title: val.title,
              // parent: val.parent,
              children: [],
            })
          })

        const idParent = []
        topParent.map(val => {
          idParent.push(val.id)
        })

        const tree = (items, id = idParent, link = 'parent') => {
          if (id.length) {
            const childArray = []
            for (let a = 0; a < id.length; a++) {
              const childs = items
                .filter(item => item[link] === id[a])
                .map(item => ({
                  ...item,
                  children: tree(items, item.id),
                }))
              childArray.push(childs)
            }

            return childArray
          } else {
            const childs = items
              .filter(item => item[link] === id)
              .map(item => ({
                ...item,
                children: tree(items, item.id),
              }))
            return childs
          }
        }

        if (topParent[0] === undefined) {
          setTreeData([...data.company_job_profiles])
        } else {
          for (let a = 0; a < topParent.length; a++) {
            topParent[a].children = tree(temp)[a]
          }
          setTreeData([...topParent])
        }
      }
      setProgresLoading(false)
    }, 3000)
    const nameParent = treeData[0] && treeData[0].title
    setParentName(nameParent)
    // if (parentChecker) {
    //   setParentData(parentChecker)
    // }
  }, [data, treeData, parentChecker])

  if (loading) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const handleOpenDetail = () => {
    setOpenDetail(true)
    // handleClose()
  }

  const handleCloseDetail = () => {
    setOpenDetail(false)
  }

  const handleClick = (id, name) => {
    setPosition({
      id: id,
      namePosition: name,
    })
  }

  const handleOpenAlert = data => {
    const countPlacement =
      data &&
      data.people_work_placements_aggregate &&
      data.people_work_placements_aggregate.aggregate &&
      data.people_work_placements_aggregate.aggregate.count > 0
    if (
      (data.children && data.children.length === 0) ||
      typeof (data.children && data.children.length) === 'undefined'
    ) {
      if (countPlacement) {
        setNoification({...notification, notifAlertCantDel: true})
      } else {
        setNoification({...notification, notifDelete: true})
      }
    } else {
      if (countPlacement) {
        setNoification({...notification, notifAlertCantDel: true})
      } else {
        setNoification({...notification, notifAlert: true})
      }
    }
  }

  const handleCloseAlert = () => {
    setNoification({
      notifDelete: false,
      notifAlert: false,
      notifAlertCantDel: false,
    })
  }

  const doDelete = data => {
    setTreeData(data)
  }

  // const handleClickOpenImport = () => {
  //   setImport(true)
  // }
  const handleCloseImport = () => {
    setImport(false)
  }

  const addJobProfile = dataJobProfile => {
    const objects = dataJobProfile
    mutationAdd({variables: {jobProfiles: objects}})
      .then(() => {
        enqueueSnackbar('Success Add data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        setImport(false)
      })
      .catch(() => {
        enqueueSnackbar(
          'Add item failed, Format Wrong you can use this template',
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
        setImport(false)
      })
  }

  return (
    <OrganizationContainer className={classes.root}>
      <PaperContainer>
        <Toolbar className={classes.paddingLeft0}>
          <FlexBetween className={classes.width100P}>
            <WrapperContent>
              <Link
                to={{
                  pathname: '/organization/organization-unit',
                  state: {active: 'organization-unit', searchable: true},
                }}
                style={{textDecoration: 'none'}}
              >
                <IconButton edge="end" aria-label="back">
                  <Icon>arrow_back</Icon>
                </IconButton>
              </Link>
              <BigTextBlackBold
                className={classes.title}
              >{`Rincian ${jobName}`}</BigTextBlackBold>
            </WrapperContent>
            {/* <div className={classNames(classes.actions)}>
              <WhiteButton
                variant="contained"
                size="large"
                onClick={() => handleClickOpenImport()}
              >
                <TextDarkBlue>Import Position</TextDarkBlue>
              </WhiteButton>
              <Link
                className={classNames(classes.buttonText, classes.marginLeft10)}
                to={{
                  pathname: '/organization/position/add',
                  state: {
                    active: 'add-position',
                    id: id,
                    jobName: jobName,
                    parentName: parentName,
                    status: 'Add',
                    dataCheck: data,
                    parentChecker: parentChecker,
                    menu: 'organization',
                  },
                }}
              >
                <Button variant="contained" color="primary" size="large">
                  Add Position
                </Button>
              </Link>
            </div> */}
          </FlexBetween>
        </Toolbar>
        <Divider />
        <OrganizationContainer style={{height: '500px'}}>
          <CircularProgress
            className={progresLoading ? '' : classes.displayNone}
          />
          <StyledTree
            canDrag={false}
            // eslint-disable-next-line no-unneeded-ternary
            treeData={treeData ? treeData : []}
            onChange={data => {
              setTreeData(data)
            }}
            generateNodeProps={({node}) => ({
              title: (
                <FlexBetween>
                  <TextBlackBold>{`${
                    node && node.code ? node.code : ''
                  } ${node && node.title}`}</TextBlackBold>
                  <WrapperContent>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <>
                          <MoreIcon {...bindTrigger(popupState)}>
                            more_horiz
                          </MoreIcon>

                          <StyledMenus {...bindMenu(popupState)}>
                            <StyledMenuItem
                              variant="h6"
                              onClick={() => {
                                popupState.close()
                                handleClick(node.id, node.title)
                                handleOpenDetail()
                              }}
                            >
                              Rincian
                            </StyledMenuItem>
                            <StyledMenuItem
                              component={Link}
                              to={{
                                pathname: `/organization/position/edit`,
                                state: {
                                  active: 'edit-position',
                                  status: 'Edit',
                                  id: id,
                                  jobName: jobName,
                                  // data: data.company_job_profiles,
                                  dataEdit: node,
                                  dataCheck: data,
                                  parentName: parentName,
                                  // idEdit: node.id,
                                  parentChecker: parentChecker,
                                },
                              }}
                              variant="h6"
                            >
                              Ubah
                            </StyledMenuItem>
                            <StyledMenuItem
                              onClick={() => {
                                popupState.close()
                                handleClick(node.id, node.title)
                                handleOpenAlert(node)
                              }}
                            >
                              Hapus
                            </StyledMenuItem>
                          </StyledMenus>
                        </>
                      )}
                    </PopupState>
                  </WrapperContent>
                </FlexBetween>
              ),
            })}
          />
        </OrganizationContainer>
      </PaperContainer>
      <DetailJobProfileModal
        open={openDetail}
        close={handleCloseDetail}
        id={position.id}
      />
      <DeleteAlertModal
        open={notification.notifDelete}
        idJob={position.id}
        data={treeData}
        jobName={jobName}
        close={handleCloseAlert}
        namePosition={position.namePosition}
        refetch={refetch}
        doDelete={data => doDelete(data)}
      />
      <AlertDialog
        open={notification.notifAlert}
        handleClose={handleCloseAlert}
        feature={`Hapus ${position.namePosition}`}
        name={position.namePosition}
        fMessage="Tidak dapat menghapus "
        lMessage=" , Silahkan hapus atau pindahkan posisi pekerjaan yang mendasarinya ke posisi lain yang sudah ada sebelum melanjutkan penghapusan ini. "
        type="Cancel"
      />
      <AlertDialog
        open={notification.notifAlertCantDel}
        handleClose={handleCloseAlert}
        feature={`Tidak dapat menghapus ${position.namePosition}`}
        name={position.namePosition}
        fMessage="Anda tidak dapat menghapus "
        lMessage=" karena digunakan pada posisi "
        type="Confirm"
        positiveTitleButton="Konfirmasi"
      />
      <ImportPopup
        open={openImport}
        position={'Position'}
        handleClose={handleCloseImport}
        mutation={addJobProfile}
        fileTemplate={
          'https://public-media.wlb.co.id/5db6681b-f1e6-456a-9a9e-c347bdffdad1-1602485495533.xlsx'
        }
        errorsImport={[]}
      />
    </OrganizationContainer>
  )
}

export default DetailJobProfile
