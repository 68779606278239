import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'
import ClassificationDetailComponent from './detail/ClassificationDetailComponent'
import ClassificationAddComponent from './form/ClassificationAddComponent'
import ListComponent from './list/ListComponent'
// import {capitalize} from '../../../utils/helpers'

const renderComponent = (activeComponent, searchText = '') => {
  switch (activeComponent) {
    case 'perf-classification':
      return <ListComponent searchText={searchText} />
    case 'perf-classification-add':
      return <ClassificationAddComponent />
    case 'perf-classification-edit':
      return <ClassificationAddComponent />
    case 'perf-classification-detail':
      return <ClassificationDetailComponent />
    default:
      return <ListComponent searchText={searchText} />
  }
}

const ClassificationPage = props => {
  let active = ''
  if (props.location.state) {
    active = props.location.state.active
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <Card icon="bar_chart" title="Evaluasi Kinerja Pegawai" onSearch={onSearch}>
      <PageContainer style={{display: 'block'}}>
        {renderComponent(active, searchText)}
      </PageContainer>
    </Card>
  )
}

export default ClassificationPage
