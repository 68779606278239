import gql from 'graphql-tag'

export const ADD_LOAN_POLICY = gql`
  mutation AddLoanPolicy($objects: [finance_loan_policies_insert_input!]!) {
    insert_finance_loan_policies(objects: $objects) {
      affected_rows
    }
  }
`

export const EDIT_LOAN_POLICY = gql`
  mutation EditLoanPolicy(
    $id: Int!
    $name: String
    $loan_max_amount: float8
    $installment: Int
    $interest_rate: float8
  ) {
    update_finance_loan_policies_by_pk(
      pk_columns: {id: $id}
      _set: {
        name: $name
        loan_max_amount: $loan_max_amount
        installment: $installment
        interest_rate: $interest_rate
      }
    ) {
      id
      name
      loan_max_amount
      installment
      interest_rate
    }
  }
`

export const DELETE_LOAN_POLICY = gql`
  mutation DeleteLoanPolicy($id: Int!) {
    update_finance_loan_policies(
      _set: {deleted_at: "now()"}
      where: {_and: [{id: {_eq: $id}}, {deleted_at: {_is_null: true}}]}
    ) {
      affected_rows
    }
  }
`
