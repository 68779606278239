import React from 'react'

import MaterialTable from 'material-table'

import {
  TableOptions,
  StatusPill,
  ActionableLink,
  getClaimType,
  getClaimTypeLabel,
} from '../ClaimPageStyles'

import {dateFormat} from '../../../../utils/helpers'

function ClaimHistoryList(props) {
  const {
    loading,
    data,
    totalCount,
    offset,
    limit,
    onChangeOffset,
    onChangeLimit,
    onDetailsClick,
  } = props

  const TABLE_COLUMNS_CONFIG = [
    {
      title: 'Request ID',
      field: 'formatted_id',
    },
    {
      title: 'Employee ID',
      field: 'employee.regno',
    },
    {
      title: 'Employee Name',
      field: 'employee.user.name',
    },
    {
      title: 'Position',
      field: 'employee.profile.title',
    },
    {
      title: 'Claim Policy',
      field: 'policy.name',
      render: renderClaimPolicy,
    },
    {
      title: 'Claim Type',
      field: 'status',
      customSort: handleClaimTypeSort,
      render: getClaimTypeLabel,
    },
    {
      title: 'Claim Date',
      customSort: handleClaimDateSort,
      render: renderClaimDate,
    },
    {
      title: 'Status',
      field: 'status',
      render: renderStatus,
    },
    {
      title: 'Action',
      sorting: false,
      render: data => {
        const handleClick = event => {
          if (onDetailsClick) {
            onDetailsClick(event, data)
          }
        }

        return <ActionableLink onClick={handleClick}>Details</ActionableLink>
      },
    },
  ]

  return (
    <MaterialTable
      style={{fontSize: 14}}
      isLoading={loading}
      data={data}
      totalCount={totalCount}
      page={offset}
      pageSize={limit}
      onChangePage={onChangeOffset}
      onChangeRowsPerPage={onChangeLimit}
      columns={TABLE_COLUMNS_CONFIG}
      options={TableOptions}
    />
  )
}

export default ClaimHistoryList

function renderClaimPolicy(data) {
  return data.policy ? data.policy.name : '-'
}

function renderClaimDate(data) {
  const date = data.date_issued || data.date_added
  return dateFormat(date, 'MMM DD, YYYY')
}

function renderStatus(data) {
  const status = data.status

  return <StatusPill status={status} />
}

function handleClaimDateSort(a, b) {
  const aDate = a.date_issued || a.date_added || ''
  const bDate = b.date_issued || b.date_added || ''

  return aDate.localeCompare(bDate)
}

function handleClaimTypeSort(a, b) {
  const aType = getClaimType(a)
  const bType = getClaimType(b)

  return aType - bType
}
