import gql from 'graphql-tag'

export const GET_KPI_LEVEL = gql`
  query getKpiLevel($search: String) {
    kpi_levels(
      where: {
        _or: [
          {code: {_ilike: $search}}
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      code
      name
      description
      status
    }
  }
`

export const CHECK_CODE_LEVEL = gql`
  query checkCodeLevel($company: uuid, $code: String) {
    kpi_levels_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const CHECK_LEVEL_ALREADY_TAKEN = gql`
  query checkDeleteLevel($level: [Int!]!) {
    kpi_lists_aggregate(where: {level: {_in: $level}}) {
      aggregate {
        count
      }
    }
  }
`
