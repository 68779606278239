import {useMutation, useQuery} from '@apollo/react-hooks'
import React, {useEffect, useState} from 'react'
import {GET_LIST_CATEGORY_OHSA} from '../../../../../../graphql/queries/category/category.query'
import {
  ADD_OHSA,
  UPDATE_OHSA,
  DELETE_OHSA,
} from '../../../../../../graphql/mutations'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../DetailEmployeeStyles'
import {SwitchForm, ButtonSubmit} from '../../SharedDetailEmployee'
import FormInput from '../ShareComponents-detail/FormInput'
import {isErrorForm} from '../ShareComponents-detail/helperDetail.jsx'

const INITIAL_VALUES_STATE = {
  user: null,
  is_share: false,
  incident_code: null,
  company_name: null,
  incident_location: null,
  company_location: null,
  category: null,
  incident_date_time: undefined,
  severity: null,
  description: null,
  attachments: [],
}

const HELPER_TEXT = 'Bagian ini diperlukan'

export default function AddEdit({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
  user_name_long,
}) {
  const [values, setValues] = useState(INITIAL_VALUES_STATE)
  const [errorState, setErrorState] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [addOhsa] = useMutation(ADD_OHSA)
  const [updateOhsa] = useMutation(UPDATE_OHSA)
  const [deleteOhsa] = useMutation(DELETE_OHSA)

  const {data: categoryOhsa} = useQuery(GET_LIST_CATEGORY_OHSA, {
    wlb_skipPatch: true,
  })
  useEffect(() => {
    if (actionType === 'edit' && open) {
      setValues(e => ({
        ...e,
        user: userIdDetail,
        is_share: data.is_share,
        incident_code: data.incident_code,
        company_name: data.company_name,
        incident_location: data.incident_location.incident_location,
        company_location: data.company_location.company_location,
        category: data.category,
        incident_date_time: data.incident_date_time,
        severity: data.severity,
        description: data.description,
        attachments: data.attachments,
      }))
    } else {
      setValues(e => ({
        ...e,
        user: userIdDetail,
      }))
    }
  }, [categoryOhsa, open, actionType])
  const fieldsList = [
    {
      label: 'Kode Kejadian*',
      placeholder: 'Tambahkan kode kejadian',
      fieldName: 'incident_code',
      type: 'textfield',
      required: true,
      value: values.incident_code,
      error: !values.incident_code,
    },
    {
      label: 'Nama Perusahaan*',
      placeholder: 'Tambahkan nama perusahaan',
      fieldName: 'company_name',
      type: 'textfield',
      required: true,
      value: values.company_name,
      error: !values.company_name,
    },
    {
      label: 'Lokasi Kejadian*',
      placeholder: 'Tambahkan lokasi kejadian',
      fieldName: 'incident_location',
      type: 'textfield',
      required: true,
      value: values.incident_location,
      error: !values.incident_location,
    },
    {
      label: 'Lokasi Perusahaan*',
      placeholder: 'Tambahkan lokasi perusahaan',
      fieldName: 'company_location',
      type: 'textfield',
      required: true,
      value: values.company_location,
      error: !values.company_location,
    },
    {
      label: 'Pegawai*',
      placeholder: 'Tambahkan pegawai',
      fieldName: 'employee',
      type: 'textfield',
      required: true,
      value: user_name_long,
    },
    {
      label: 'Kategori Riwayat Kesehatan dan Keselamatan Kerja*',
      placeholder: 'Pilih kategori riwayat kesehatan dan keselematan kerja',
      fieldName: 'category',
      type: 'select',
      required: true,
      value: values.category,
      error: !values.category,
      option: categoryOhsa ? categoryOhsa.people_profile_ohsa_categories : [],
    },
    {
      label: ['Tanggal Kejadian*', 'Waktu Kejadian*'],
      filter: ['date', 'time'],
      fieldName: 'incident_date_time',
      type: 'dateCustom',
      required: true,
      value: values.incident_date_time,
      error: !values.incident_date_time,
    },
    {
      label: 'Dampak Kejadian*',
      placeholder: 'Tambahkan dampak',
      fieldName: 'severity',
      type: 'textfield',
      required: true,
      value: values.severity,
      error: !values.severity,
    },
    {
      label: 'Keterangan',
      placeholder: 'Tambahkan keterangan',
      fieldName: 'description',
      type: 'textfield',
      required: false,
      multiline: true,
      rows: 6,
      value: values.description,
    },
    {
      label: 'Lampiran',
      fieldName: 'attachment',
      type: 'attachment',
      attachments: values.attachments,
      error: true,
      multiple: true,
      link: 'url',
    },
  ]
  const handleChange = ({fieldName, value}, target, ekstra) => {
    if (target === 'date') {
      setValues(e => ({
        ...e,
        [ekstra]: value,
      }))
    } else {
      setValues(e => ({
        ...e,
        [fieldName]: value,
      }))
    }
  }
  const handleClose = () => {
    onClose()
    setValues(INITIAL_VALUES_STATE)
  }

  const handleSave = async () => {
    const isError = await isErrorForm(fieldsList)
    if (isError) {
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000)
    } else {
      // const _initalSalary = await changeToNumber(values.initial_salary)
      // const _ilastSalary = await changeToNumber(values.last_salary)
      const object = {
        user: values.user,
        is_share: values.is_share,
        incident_code: values.incident_code,
        company_name: values.company_name,
        incident_location: {
          incident_location: values.incident_location,
        },
        company_location: {
          company_location: values.company_location,
        },
        category: values.category,
        incident_date_time: values.incident_date_time,
        severity: values.severity,
        description: values.description,
        attachments: values.attachments,
      }
      if (actionType === 'add') {
        addOhsa({
          variables: {object},
        })
          .then(() => {
            refetch()
            enqueueSnackbar(
              'Berhasil menambah Riwayat Kesehatan dan Keselamatan Kerja',
              {
                variant: 'success',
                autoHideDuration: 2000,
              }
            )
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar(
              'Gagal menambah Riwayat Kesehatan dan Keselamatan Kerja',
              {
                variant: 'error',
                autoHideDuration: 2000,
              }
            )
          })
      } else {
        updateOhsa({
          variables: {
            id: data.id,
            object: object,
          },
        })
          .then(() => {
            refetch()
            enqueueSnackbar(
              'Berhasil mengubah Riwayat Kesehatan dan Keselamatan Kerja',
              {
                variant: 'success',
                autoHideDuration: 2000,
              }
            )
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar(
              'Gagal mengubah Riwayat Kesehatan dan Keselamatan Kerja',
              {
                variant: 'error',
                autoHideDuration: 2000,
              }
            )
          })
      }
    }
  }

  const handleDelete = () => {
    deleteOhsa({
      variables: {
        id: data.id,
        userId: userIdDetail,
      },
    })
      .then(() => {
        refetch()
        setOpenDelete(false)
        enqueueSnackbar(
          'Berhasil menghapus Riwayat Kesehatan dan Keselamatan Kerja',
          {
            variant: 'success',
            autoHideDuration: 2000,
          }
        )
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar(
          'Gagal menghapus Riwayat Kesehatan dan Keselamatan Kerja',
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        )
      })
  }

  const handleOpenDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  return (
    <>
      <ModalDetail
        onDelete={actionType === 'edit' && handleOpenDelete}
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        title={
          (actionType === 'add'
            ? 'Tambahkan'
            : actionType === 'edit'
            ? 'Ubah'
            : '') + ' Riwayat Kesehatan dan Keselamatan Kerja'
        }
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Bagikan dengan jaringan:"
            value={values.is_share}
            labelPlacement="start"
            onChange={e =>
              handleChange({
                fieldName: 'is_share',
                value: e.target.checked,
              })
            }
          />
        </div>
        <ModalAddEditWraper>
          <FormInput
            fieldsList={fieldsList}
            open={open}
            setValues={setValues}
            values={values}
            errorState={errorState}
            HELPER_TEXT={HELPER_TEXT}
          />
        </ModalAddEditWraper>
        <ButtonSubmit onCancel={handleClose} onSave={handleSave} />
      </ModalDetail>
      <DeletePopup
        open={openDelete}
        handleClose={handleCloseDelete}
        name="Riwayat Kesehatan dan Keselamatan Kerja"
        mutation={handleDelete}
      />
    </>
  )
}
