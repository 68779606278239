import gql from 'graphql-tag'

export const GET_DATA_FILTER = gql`
  query($company: uuid) {
    office: company_addresses(
      where: {company: {_eq: $company}, status: {_eq: "ACTIVE"}}
    ) {
      id
      office_code
      office_name
    }

    global_legal_type_placements {
      name
    }

    global_legal_time_placements {
      name
    }

    global_legal_place_placements {
      name
    }

    global_degrees {
      id
      name
    }
  }
`
