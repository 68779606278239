import React, {useState, useMemo} from 'react'
import PropTypes from 'prop-types'

import {makeStyles} from '@material-ui/core/styles'

import {Divider, Paper, Toolbar, Typography, Tooltip} from '@material-ui/core'

import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'
import {GET_ATTENDANCE_REPORT} from '../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {PaperContainer, RootContainer} from '../../../../GlobalStyles'
import {TableOptionsTime} from '../../../shared-components/table/TableOptions'
import MaterialTable from 'material-table'
import useDownloadReport from '../../../../hooks/useDownloadReport'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import ButtonDownload from './ButtonDownload'
import {getDataVariableAttendanceReport} from '../filter'

const useStyle = makeStyles(theme => ({
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
  },
  datePickerAction: {
    marginRight: 30,
  },
  buttonAction: {
    height: 61,
    marginTop: 6,
    width: 144,
  },
  buttonText: {
    textDecoration: 'none',
    color: '#fff',
  },
  title: {
    flex: '0 0 auto',
  },
  customDatePicker: {
    width: '11rem',
    marginRight: 12,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  prevCount: {color: '#039BE5', cursor: 'pointer'},
  tooltip: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: '#f7f8f9',
    color: '#000',
    padding: 13,
    fontSize: 11,
    '& span': {
      display: 'block',
    },
  },
  arrow: {color: '#f7f8f9'},
  wlbTeal: {color: '#014A62'},
  ul: {paddingLeft: 16},
  li: {lineHeight: 1.5, color: '#014A62'},
}))

const CustomDatePicker = ({classes, selectedDate, onDateSelected}) => {
  const handleDateChange = date => {
    onDateSelected(date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        inputVariant="outlined"
        disableFuture={true}
        openTo="month"
        views={['year', 'month']}
        label="Select Month"
        value={selectedDate}
        onChange={handleDateChange}
        className={classes.customDatePicker}
      />
    </MuiPickersUtilsProvider>
  )
}

const EnhancedTableToolbar = props => {
  const classes = useStyle()
  const {
    selectedDate,
    onDateSelected,
    isDisableDownload,
    handleDownload,
  } = props

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.title}>
        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
          Attendance Report
        </Typography>
      </div>
      <div className={classes.actions}>
        <CustomDatePicker
          classes={classes}
          selectedDate={selectedDate}
          onDateSelected={onDateSelected}
        />
        <ButtonDownload
          isDisable={isDisableDownload}
          onDownload={handleDownload}
        >
          Generate Data
        </ButtonDownload>
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

export default function AttendanceReport({filterData, searchText}) {
  const classes = useStyle()

  const [startDate, setStartDate] = useState(() => {
    const date = new Date()
    date.setDate(1)
    date.setHours(0, 0, 0, 0)

    return date.toISOString()
  })

  const endDate = useMemo(() => {
    const start = new Date(startDate)
    const date = new Date()

    const startYear = start.getFullYear()
    const startMonth = start.getMonth()

    date.setHours(23, 59, 59, 999)

    if (startYear !== date.getFullYear() || startMonth !== date.getMonth()) {
      date.setUTCFullYear(startYear, startMonth + 1, 0)
    }

    return date.toISOString()
  }, [startDate])

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const {dataBaloon, isDisableButton, onDownload} = useDownloadReport({
    table: 'people_work_attendances',
  })

  const variables_filter = getDataVariableAttendanceReport(filterData)

  const [reports, setReports] = useState([])
  // const [totalPages, setTotalPages] = useState(0)

  const variables = {
    startDate: startDate,
    endDate: endDate,
    // employee: USER_ID,
    // supervisor: SUPERVISOR_USER_ID,
    search: `%${searchText}%`,
  }

  const {
    data: dataAttendance,
    error: errorAttendance,
    loading: loadingAttendance,
  } = useQuery(GET_ATTENDANCE_REPORT, {
    variables: {
      ...variables,
      ...variables_filter,
      current_page: pagination.offset + 1,
      current_limit: pagination.limit,
      status: ['ACTIVE', 'INACTIVE'],
    },
    onCompleted: ({getAttendanceReports}) => {
      setReports(getAttendanceReports.result.data)
    },
  })

  const handleResetPagination = () => {
    setPagination({
      ...pagination,
      offset: 0,
    })
  }

  if (errorAttendance !== undefined) {
    return <div>{JSON.stringify(errorAttendance)}</div>
  }

  const handleDownload = () => {
    onDownload({
      variables: {
        table: 'people_work_attendances',
        filter: {
          start_date: startDate.split('T')[0],
          end_date: endDate.split('T')[0],
        },
      },
    })
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      offset: 0,
      limit: row,
    })
  }

  const renderCount = (row, field) => {
    const currentData = row[field]

    let type
    let pastData

    switch (field) {
      case 'countIn': {
        type = 'In'
        pastData = row.prev_data?.[`total_prev_${field}`]
        break
      }
      case 'countAbsent': {
        type = 'Absence'
        pastData = row.prev_data?.[`total_prev_${field}`]
        break
      }
      case 'countOnLeave': {
        type = 'On Leave'
        pastData = row.prev_data?.totalPrevOnLeave
        break
      }
      case 'countOnTrip': {
        type = 'On Trip'
        pastData = row.prev_data?.totalPrevOntrip
        break
      }
      case 'countOnTripNonWorkingDays': {
        type = 'On Trip Non Working'
        pastData = row.prev_data?.totalPrevOntripNonWorkingDays
        break
      }
      case 'countLateDays': {
        type = 'Late in Day'
        pastData = row.prev_data?.[`total_prev_${field}`]
        break
      }
      case 'countLateMinutes': {
        type = 'Late in Minute'
        pastData = row.prev_data?.[`total_prev_${field}`]
        break
      }
    }

    return (
      <span>
        {currentData}{' '}
        {row.prev_data && (
          <Tooltip
            title={
              <>
                <span> This employee was replaced to a new position.</span>
                <span className={classes.wlbTeal}> Previous Position is :</span>
                <ul className={classes.ul}>
                  {row.prev_data.prev_positon?.map((res, i) => (
                    <li key={i} className={classes.li}>
                      {res.position}
                    </li>
                  ))}
                </ul>
                <span>
                  {type} in Current Position : {currentData}
                </span>
                <span className={classes.wlbTeal} style={{marginBottom: '1em'}}>
                  {type} in Previous Positions : {pastData}
                </span>
                <span>
                  For more details, please generate attendance report{' '}
                </span>
              </>
            }
            placement="left"
            arrow
            classes={{
              tooltipArrow: classes.tooltip,
              arrow: classes.arrow,
            }}
          >
            <span className={classes.prevCount}>[{pastData}]</span>
          </Tooltip>
        )}
      </span>
    )
  }
  return (
    <RootContainer style={{overflow: 'inherit'}}>
      <ExportBaloon isLastGenerateStatus data={dataBaloon} />
      <PaperContainer style={{overflow: 'unset'}}>
        <EnhancedTableToolbar
          selectedDate={startDate}
          isDisableDownload={isDisableButton}
          onDateSelected={date => {
            handleResetPagination()

            date.setDate(1)
            date.setHours(0, 0, 0, 0)
            setStartDate(date.toISOString())
          }}
          // dataForExport={dataForExport}
          handleDownload={handleDownload}
        />
        <Divider />
        <MaterialTable
          style={{fontSize: 14}}
          columns={[
            {
              title: 'Employee ID',
              field: 'regno',
              cellStyle: {minWidth: 220},
            },
            {
              title: 'Name',
              field: 'name',
              cellStyle: {minWidth: 280},
            },
            {
              title: 'Position',
              field: 'title',
              cellStyle: {minWidth: 280},
            },
            {
              title: 'In',
              field: 'countIn',
              render: row => renderCount(row, 'countIn'),
            },
            {
              title: 'Absence',
              field: 'countAbsent',
              render: row => renderCount(row, 'countAbsent'),
            },
            {
              title: 'On Leave',
              field: 'countOnLeave',
              render: row => renderCount(row, 'countOnLeave'),
            },
            {
              title: 'On Trip',
              field: 'countOnTrip',
              render: row => renderCount(row, 'countOnTrip'),
            },
            {
              title: 'On Trip Non Working',
              field: 'countOnTripNonWorkingDays',
              render: row => renderCount(row, 'countOnTripNonWorkingDays'),
            },
            {
              title: 'Late in Day',
              field: 'countLateDays',
              render: row => renderCount(row, 'countLateDays'),
            },
            {
              title: 'Late in Minute',
              field: 'countLateMinutes',
              render: row => renderCount(row, 'countLateMinutes'),
            },
          ]}
          data={reports}
          options={{
            ...TableOptionsTime,
            selection: false,
            emptyRowsWhenPaging: false,
            headerStyle: {whiteSpace: 'nowrap'},
          }}
          components={{
            Toolbar: () => null,
            Container: props => <Paper elevation={0} {...props}></Paper>,
          }}
          isLoading={loadingAttendance}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={
            (dataAttendance &&
              dataAttendance.getAttendanceReports.result.total_data) ||
            0
          }
          page={pagination.offset}
          pageSize={pagination.limit}
        />
      </PaperContainer>
    </RootContainer>
  )
}
