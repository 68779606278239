import gql from 'graphql-tag'

export const GET_LIST_ORG_LEVEL = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: company_employee_ranks_aggregate(
      where: {_or: [{name: {_ilike: $search}}, {code: {_ilike: $search}}]}
    ) {
      aggregate {
        count
      }
    }

    company_employee_ranks(
      where: {_or: [{name: {_ilike: $search}}, {code: {_ilike: $search}}]}
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
      color_hex
      count_employee
      company_job_profiles_aggregate {
        aggregate {
          count
        }
      }
      company_job_profiles(limit: 5) {
        id
        title
      }
    }
  }
`

export const GET_ORGANIZATION_LEVELS = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    total: company_organization_levels_aggregate(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
              {status: {_ilike: $search}}
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    company_organization_levels(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
              {status: {_ilike: $search}}
            ]
          }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      color_hex
      name
      description
      status
    }
  }
`

export const CHECK_CODE_ORGANIZATION_LEVEL = gql`
  query($company: uuid, $code: String) {
    company_organization_levels_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const CHECK_CODE_JOB_LEVEL = gql`
  query($company: uuid, $code: String) {
    company_employee_ranks_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const CHECK_COMPANY_ORTGANIZATION = gql`
  query($orgLevel: Int) {
    total: company_organizations_aggregate(
      where: {organization_level_id: {_eq: $orgLevel}}
    ) {
      aggregate {
        count
      }
    }
    company_organizations(
      where: {organization_level_id: {_eq: $orgLevel}}
      limit: 5
    ) {
      id
      code
      name
    }
  }
`

export const GET_COMPANY_ORGANIZATION_BY_ORG_LEVEL = gql`
  query($orgLevel: Int) {
    total: company_organizations_aggregate(
      where: {organization_level_id: {_eq: $orgLevel}}
    ) {
      aggregate {
        count
      }
    }
    company_organizations(
      where: {organization_level_id: {_eq: $orgLevel}}
      limit: 5
    ) {
      id
      code
      name
    }
  }
`
