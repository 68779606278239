import gql from 'graphql-tag'

export const GET_PROVINCE = gql`
  {
    global_provinces(order_by: {name: asc}) {
      id
      code
      name
    }
  }
`

export const GLOBAL_PROVINCES_SEARCHABLE = gql`
  query getProvinces(
    $search: String
    $limit: Int
    $offset: Int
    $country: Int
  ) {
    total_data: global_provinces_aggregate(
      where: {
        name: {_ilike: $search}
        global_country: {id: {_eq: $country}}
        deletedAt: {_is_null: true}
      }
    ) {
      aggregate {
        count
      }
    }
    global_provinces(
      where: {name: {_ilike: $search}, deletedAt: {_is_null: true}}
      limit: $limit
      offset: $offset
      order_by: [{name: asc}]
    ) {
      id
      code
      name
    }
  }
`
