import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Button, Popover, Dialog} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {
  ExpansionPanelStyle,
  ExpansionPanelSummaryStyle,
  ExpansionPanelDetailStyle,
  MediumTextBlackBold,
  ItemMenu,
  DetailComponentWrapper,
  AsigneeComponentWrapper,
  WrapperModalConfirmation,
} from '../../../../PayrollPageStyles'
import moment from 'moment'
import {convertToRoundedRupiah} from '../../../../../../../utils/helpers'
import AssigneeModal from './AssigneeModal'
import {DELETE_COMPONENT} from '../../../../../../../graphql/mutations'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {GQL_FINANCE_OPTIONS} from '../../../../utils'

// import ClauseItem from './ClauseItem'

const types = ['Earning', 'Deduction']
// const parts = ['Thp', 'Non Thp']

const DetailComponent = ({name, value}) => {
  return (
    <DetailComponentWrapper>
      <div>{name}</div>
      <div>{value}</div>
    </DetailComponentWrapper>
  )
}

export default function ComponentListItem({
  data,
  noMargin = false,
  isOpen,
  refetch,
  // clauses,
}) {
  const {enqueueSnackbar} = useSnackbar()

  const [anchorEl, setAnchorEl] = useState(null)
  const [isExpanded, setIsExpanded] = useState(isOpen)
  const [isModalShow, setIsModalShow] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const open = Boolean(anchorEl)
  const id = open ? 'more-regulations' : undefined

  const [deleteComponent] = useMutation(DELETE_COMPONENT, GQL_FINANCE_OPTIONS)

  const convertCalcBaseAbbreviation = calcBase => {
    switch (calcBase) {
      case 'base':
        return 'Basic Salary'
      case 'thp':
        return 'Take Home Pay'
      case 'pmw':
        return 'Province Minimum Wage'
      default:
        return calcBase
    }
  }

  const onCloseModal = () => {
    setShowDeleteModal(false)
  }
  const onDeleteComponent = () => {
    const variables = {
      id: data.id,
      deletedAt: moment().format('YYYY-MM-DD, h:mm:ss'),
    }

    deleteComponent({
      variables,
      ...GQL_FINANCE_OPTIONS,
    })
      .then(() => {
        enqueueSnackbar('Component Deleted', {
          variant: 'success',
          autoHideDuration: 1500,
        })
        refetch()
      })
      .catch(() =>
        enqueueSnackbar('Delete Component Failed', {
          variant: 'error',
          autoHideDuration: 1500,
        })
      )
      .finally(() => {
        onCloseModal()
        setAnchorEl(false)
      })
  }

  return (
    <>
      <ExpansionPanelStyle
        style={noMargin ? {marginTop: 0} : null}
        expanded={isExpanded}
        /* eslint-disable no-unused-vars */
        onChange={(_, value) => {
          setIsExpanded(value)
        }}
      >
        <ExpansionPanelSummaryStyle>
          <MediumTextBlackBold>{data.name}</MediumTextBlackBold>
          <MoreHorizIcon
            aria-describedby={id}
            onClick={e => {
              e.stopPropagation()
              setAnchorEl(e.currentTarget)
            }}
          />
        </ExpansionPanelSummaryStyle>
        <ExpansionPanelDetailStyle>
          <h4>Policy Details</h4>
          <DetailComponent name="Component Code" value={data.code || '-'} />
          <DetailComponent name="Component Type" value={types[data.type - 1]} />
          <DetailComponent
            name="Component Frequency"
            value={data.component_frequency}
          />
          {/* <DetailComponent
            name="Component Method Based on"
            value={data.component_method_base.join(', ')}
          /> */}
          {data.component_calculation !== 'other' && (
            <DetailComponent
              name="Calculation Base"
              // value={data.component_calculation}
              value={`${convertCalcBaseAbbreviation(
                data.component_calculation
              )} (${data.component_calculation_mul}%) `}
              // value={`${data.component_calculation} ${
              //   data.component_calculation_mul
              // } ${formatatedSalary(String(data.custom_calculation_value))} `}
            />
          )}
          {data.component_calculation === 'other' && (
            <DetailComponent
              name="Calculation Base"
              // value={data.component_calculation}
              value={`${data.component_calculation} (${convertToRoundedRupiah(
                data.custom_calculation_value
              )}) `}
              // value={`${data.component_calculation} ${
              //   data.component_calculation_mul
              // } ${formatatedSalary(String(data.custom_calculation_value))} `}
            />
          )}
          {/* <DetailComponent
            name="Calculation Mul"
            value={data.component_calculation_mul}
          /> */}
          {/* <DetailComponent
            name="Calculation Value"
            value={formatatedSalary(String(data.custom_calculation_value))}
          /> */}
          <DetailComponent
            name="Include in THR"
            value={data.part_of === 1 ? 'Yes' : 'No'}
          />
          <DetailComponent
            name="Include in BPJS"
            value={data.include_in_bpjs ? 'Yes' : 'No'}
          />
          <DetailComponent name="Taxable" value={data.taxable ? 'Yes' : 'No'} />
          {data.taxable && (
            <DetailComponent
              name="Tax Paid by"
              value={
                data.tax_paid_by === null
                  ? '-'
                  : data.tax_paid_by === 1
                  ? 'Employee'
                  : 'Company'
              }
            />
          )}
          {/* <DetailComponent name="Part Of" value={parts[data.part_of - 1]} /> */}
          <DetailComponent
            name="Calculation Date Start From"
            value={moment(data.effective_date).format('MMMM Do, YYYY')}
          />
          {data.finance_component_assignments?.length > 0 && (
            <>
              <h4>Assignee</h4>
              <AsigneeComponentWrapper>
                <ul>
                  {data.finance_component_assignments
                    .slice(0, 12)
                    .map((item, i) => {
                      const name =
                        item?.people_work_placement?.global_user?.name
                      return <li key={i}>{name}</li>
                    })}
                </ul>
                {data.finance_component_assignments?.length > 12 && (
                  <div
                    className="view-more"
                    onClick={() => setIsModalShow(true)}
                  >
                    View More Assignee
                  </div>
                )}
              </AsigneeComponentWrapper>
              <AssigneeModal
                open={isModalShow}
                onClose={() => setIsModalShow(false)}
                id={data.id}
              />
            </>
          )}
        </ExpansionPanelDetailStyle>
      </ExpansionPanelStyle>

      <Popover
        // id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ItemMenu>
          <Button
            component={Link}
            to={{
              pathname: `/financial/payroll/setting/edit-component/${data.id}`,
              state: {
                active: 'settings-edit-component',
                componentData: data,
              },
            }}
            className="item"
          >
            Edit
          </Button>
          <Button onClick={() => setShowDeleteModal(true)} className="item">
            Delete
          </Button>
        </ItemMenu>
      </Popover>
      <ModalConfirmDelete
        onClose={onCloseModal}
        onConfirm={onDeleteComponent}
        open={showDeleteModal}
      />
    </>
  )
}

const ModalConfirmDelete = ({onClose, onConfirm, open}) => {
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="xs">
      <WrapperModalConfirmation>
        <div className="header-modal-confirmation">Delete Changes?</div>
        <div className="body-modal-confirmation">
          This Component has been used by many participant, are you sure to
          delete this component?
        </div>
        <div className="action-wrapper-modal-confirmation">
          <Button style={{fontSize: 12}} onClick={onClose}>
            Cancel
          </Button>
          <Button
            style={{fontSize: 12, marginLeft: 12}}
            onClick={e => {
              e.preventDefault()
              onConfirm()
              onClose()
            }}
            size="large"
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </div>
      </WrapperModalConfirmation>
    </Dialog>
  )
}
