import React, {useEffect, useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {Box, Button, TablePagination, Typography} from '@material-ui/core'

import {EnhancedToolbar, Flex, PaperContainer} from '../../../../GlobalStyles'

import usePagination from '../../payroll/hooks/usePagination'
import ExpansionPanel from '../../../../components/expansion-panel/ExpansionPanel'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import RequestWrapper from '../../../shared-components/layouts/RequestWrapper'

import {DELETE_LOAN_POLICY} from '../../../../graphql/mutations'
import {GET_LOAN_POLICY} from '../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import {COMPANY_ID} from '../../../../utils/globals'
import {convertToRoundedRupiah} from '../../../../utils/helpers'

const Settings = ({title, searchText}) => {
  const {
    pageSizeOption,
    limit,
    offset,
    currentPage,
    rowPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination()
  const {enqueueSnackbar} = useSnackbar()

  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [dataDelete, setDataDelete] = useState(null)
  const [dataMapping, setDataMapping] = useState([])

  const [deleteLoanPolicy] = useMutation(
    DELETE_LOAN_POLICY,
    GQL_FINANCE_OPTIONS
  )

  const {loading, data, refetch: refetchPolicyList} = useQuery(
    GET_LOAN_POLICY,
    {
      variables: {
        company: COMPANY_ID,
        limit: limit,
        offset: offset,
        search: searchText ? `%${searchText}%` : undefined,
      },
      fetchPolicy: 'cache-and-network',
      ...GQL_FINANCE_OPTIONS,
    }
  )

  useEffect(() => {
    if (data?.finance_loan_policies) {
      const _dataMapping = data?.finance_loan_policies?.map(item => {
        const loanInformation = [
          {
            title: 'Maximum Amount',
            desc: `${convertToRoundedRupiah(item.loan_max_amount)} per Years`,
          },
          {title: 'Installment', desc: `${item.installment} months`},
          {title: 'Interest', desc: `${item.interest_rate}%`},
        ]

        return {name: item.name, id: item.id, loanInformation}
      })

      setDataMapping(_dataMapping)
    }
  }, [data, searchText, currentPage, rowPerPage])

  const onDeletePolicy = () => {
    const variables = {
      id: dataDelete.id,
    }
    deleteLoanPolicy({variables: variables})
      .then(() => {
        enqueueSnackbar('Loan Policy deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })

        refetchPolicyList()
      })
      .catch(() => {
        enqueueSnackbar('Delete Loan Policy failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="subtitle1" style={{fontWeight: '600'}}>
          Loan {title}
        </Typography>

        <Flex flexEnd>
          <Link
            to={{
              pathname: `/financial/loan/settings/policy/create`,
              state: {
                active: 'create-loan-setting-policy',
              },
            }}
            style={{textDecoration: 'none'}}
          >
            <Button color="primary" variant="contained">
              Add Loan Policy
            </Button>
          </Link>
        </Flex>
      </EnhancedToolbar>

      <RequestWrapper isEmpty={!loading && dataMapping?.length === 0}>
        <Box p={3}>
          {dataMapping?.map((item, index) => (
            <ExpansionPanel
              key={index}
              data={item}
              dataDetailSection={item?.loanInformation}
              detailSectionTitle="Policy Details"
              isOpen={index === 0}
              panelTitle={item?.name}
              editPathname={{
                pathname: `/financial/loan/settings/policy/${item?.id}/edit`,
                state: {
                  active: 'create-loan-setting-policy',
                  policyId: item?.id,
                },
              }}
              handleDelete={item => {
                setDataDelete(item)
                setOpenDeletePopup(true)
              }}
            />
          ))}

          <TablePagination
            component="div"
            count={data?.total?.aggregate?.count}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={currentPage}
            rowsPerPage={rowPerPage}
            rowsPerPageOptions={pageSizeOption}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
          />
        </Box>

        {openDeletePopup && (
          <DeletePopup
            open={openDeletePopup}
            handleClose={() => setOpenDeletePopup(false)}
            id={dataDelete?.id}
            name={dataDelete?.name}
            mutation={() => onDeletePolicy()}
          />
        )}
      </RequestWrapper>
    </PaperContainer>
  )
}

export default withRouter(Settings)
