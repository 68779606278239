import gql from 'graphql-tag'

export const GET_RELIGION = gql`
  {
    global_religions(order_by: [{id: asc}]) {
      id
      name
    }
  }
`
