import React, {useState} from 'react'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  WrapperContent,
} from '../../OrganizationPageStyles'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
  PaperContainer,
  EnhancedToolbar,
} from '../../../../GlobalStyles'
import {useSnackbar} from 'notistack'
import {Icon, IconButton, Button, Divider, TextField} from '@material-ui/core'
import {withRouter, Link} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {ADD_JOB_GRADE, EDIT_JOB_GRADE} from '../../../../graphql/mutations'
import {CHECK_CODE_JOB_GRADE} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'

import Loading from '../../../../components/loading/LoadingComponent'
import AddEditConfirm from '../../../shared-components/popup/AddEditPopup'
import AlertDialog from '../../../shared-components/popup/ResultPopup'

const AddEditGrade = props => {
  const {status, data} = props
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const dataJFname = data
    ? {
        code: data.code,
        name: data.name,
        description: data.description,
        company: COMPANY_ID,
      }
    : {
        code: '',
        name: '',
        description: '',
        // company: COMPANY_ID,
      }

  const hasJobProfile =
    data &&
    data.company_job_profiles_aggregate &&
    data.company_job_profiles_aggregate.aggregate &&
    data.company_job_profiles_aggregate.aggregate.count > 0

  const [allDataFrom, setAllDataFrom] = useState(dataJFname)
  const [isError, setIsError] = useState(false)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [dialogCancel, setDialogCancel] = useState(false)

  const [addData] = useMutation(ADD_JOB_GRADE)
  const [editData] = useMutation(EDIT_JOB_GRADE)

  // const nameChecker =
  //   dataCheck &&
  //   dataCheck.job_grades &&
  //   dataCheck.job_grades.some(
  //     v => v.name.toLowerCase() === allDataFrom.name.toLowerCase()
  //   )

  const {data: checkCodeJobGrade, error: checkCodeJobGradeError} = useQuery(
    CHECK_CODE_JOB_GRADE,
    {
      variables: {
        company: COMPANY_ID,
        code: allDataFrom && allDataFrom.code,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  if (checkCodeJobGrade === undefined && checkCodeJobGradeError === undefined) {
    return <Loading />
  } else if (checkCodeJobGradeError !== undefined) {
    return <div>{checkCodeJobGradeError}</div>
  }

  const codeChecker =
    checkCodeJobGrade &&
    checkCodeJobGrade.job_grades_aggregate &&
    checkCodeJobGrade.job_grades_aggregate.aggregate &&
    checkCodeJobGrade.job_grades_aggregate.aggregate.count > 0

  // const checkSameNameWithAdd = data && data.name === allDataFrom.name
  const checkSameCodeWithAdd =
    allDataFrom && allDataFrom.code && allDataFrom.code.length >= 1
      ? data && data.code === allDataFrom.code
      : false

  const checkCharaCode =
    allDataFrom && allDataFrom.code && allDataFrom.code.length > 30
  const checkCharaName =
    allDataFrom && allDataFrom.name && allDataFrom.name.length > 100

  const openDialogConfirm = () => {
    if (
      !allDataFrom.code ||
      !allDataFrom.name ||
      checkCharaCode ||
      checkCharaName ||
      // (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd) ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 2000)
    } else {
      dialogConfirm ? setDialogConfirm(false) : setDialogConfirm(true)
    }
  }

  const handleAddJFName = () => {
    if (status === 'Add') {
      const dataForAdd = {
        objects: [allDataFrom],
      }
      addData({variables: dataForAdd})
        .then(() => {
          enqueueSnackbar('Job Grade Added', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          props.history.push({
            pathname: '/organization/job-grade',
            state: {active: 'job-grade', searchable: true},
          })
        })
        .catch(() => {
          enqueueSnackbar('Added Job Grade failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else if (status === 'Edit') {
      const dataForEdit = {
        id: data && data.id,
        companyId: COMPANY_ID,
        newCode: allDataFrom.code,
        newName: allDataFrom.name,
        newDescription: allDataFrom.description,
      }
      editData({variables: dataForEdit})
        .then(() => {
          enqueueSnackbar('Job Grade Edited', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          props.history.push({
            pathname: '/organization/job-grade',
            state: {active: 'job-grade', searchable: true},
          })
        })
        .catch(() => {
          enqueueSnackbar('Edit Job Grade failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleAlert = status => {
    dialogCancel ? setDialogCancel(false) : setDialogCancel(true)
    if (dialogCancel && status !== 'notNow') {
      props.history.push({
        pathname: '/organization/job-grade',
        state: {active: 'job-grade', searchable: true},
      })
    }
  }

  return (
    <OrganizationContainer>
      <PaperContainer className={classes.height600px}>
        <EnhancedToolbar className={classes.paddingLeft0}>
          <WrapperContent>
            <IconButton
              component={Link}
              to={{
                pathname: '/organization/job-grade',
                state: {active: 'job-grade-unit', searchable: true},
              }}
              edge="end"
              aria-label="back"
            >
              <Icon>arrow_back</Icon>
            </IconButton>
            <BigTextBlackBold className={classes.marginLeft10}>{`${
              status === 'Add' ? 'Tambah' : 'Ubah'
            } Kelas Jabatan`}</BigTextBlackBold>
          </WrapperContent>
          <div>
            <Button
              size="large"
              onClick={handleAlert}
              style={{marginRight: 12}}
            >
              Batal
            </Button>
            <Button
              className={classes.marginLeft10}
              variant="contained"
              color="primary"
              size="large"
              onClick={openDialogConfirm}
            >
              Simpan
            </Button>
          </div>
        </EnhancedToolbar>
        <Divider />
        <OrganizationContainer>
          <FormContainer id="top">
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>1.</TitleNumber>
                <TitleName>Kode Kelas Jabatan *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="levelCode"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="JG-2040-0001 / JG-{Kode Instansi}-Increment"
                  value={allDataFrom.code}
                  margin="normal"
                  className={classes.helperMarginLeft0}
                  error={
                    (!allDataFrom.code && isError) ||
                    checkCharaCode ||
                    (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
                  }
                  helperText={
                    !allDataFrom.code && isError
                      ? 'Bagian ini diperlukan'
                      : (codeChecker
                        ? !checkSameCodeWithAdd
                        : checkSameCodeWithAdd)
                      ? 'Kode Kelas Jabatan sudah ada'
                      : checkCharaCode
                      ? 'Maksimum 30 karakter'
                      : 'Kode Kelas Jabatan harus unik'
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      code: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>2.</TitleNumber>
                <TitleName>Nama Kelas Jabatan *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgName"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  className={classes.helperMarginLeft0}
                  fullWidth
                  placeholder="Kelas Jabatan 10"
                  value={allDataFrom.name}
                  margin="normal"
                  error={
                    (!allDataFrom.name && isError) || checkCharaName
                    // ||(nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
                  }
                  helperText={
                    !allDataFrom.name && isError
                      ? 'This field is required'
                      : checkCharaName
                      ? 'The maximum number of characters is 100 characters'
                      : ''
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      name: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>3.</TitleNumber>
                <TitleName>Deskripsi</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgName"
                  style={{padding: '0px'}}
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="Dapat diisikan dengan jumlah tunjangan, dan fasilitas-fasilitas lain"
                  value={allDataFrom.description}
                  margin="normal"
                  className={classes.multilinePadding}
                  multiline
                  rows="5"
                  // error={
                  //   !allDataFrom.name && isError
                  // }
                  // helperText={}
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      description: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
          </FormContainer>
        </OrganizationContainer>
      </PaperContainer>
      {hasJobProfile ? (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type="Confirm"
          applied={data && data.company_job_profiles}
          mutation={handleAddJFName}
          appliedName="Position"
        />
      ) : (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type={status}
          name={data && data.name}
          feature="Job Grade"
          mutation={handleAddJFName}
        />
      )}
      <AlertDialog
        open={dialogCancel}
        handleClose={handleAlert}
        feature={`Batal Untuk ${
          data ? 'Mengubah' : 'Menambahkan Baru'
        } Kelas Jabatan?`}
        message={`Anda memiliki perubahan yang belum disimpan. Anda yakin ingin membatalkan ${
          data ? `edit ${data.office_name}` : 'tambah Kelas Jabatan'
        }?`}
        type="Yes, Cancel"
        status="notNow"
        positiveTitleButton="Ya, Batalkan"
      />
    </OrganizationContainer>
  )
}

export default withRouter(AddEditGrade)
