import gql from 'graphql-tag'

export const GET_ALL_OFFICE = gql`
  subscription getListOffice {
    company_addresses {
      id
      office_code
      office_name
    }
  }
`
