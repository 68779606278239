import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {makeStyles} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import {useQuery} from '@apollo/react-hooks'
import {GET_LEAVE_BALANCE} from '../../../../graphql/queries'
import {
  LeaveApplyContainer,
  LeaveNameContainer,
  LeaveNameChild,
  BlueLink,
} from '../LeavePageStyles'
import Loading from '../../../../components/loading/LoadingComponent'
import {Divider, TablePagination} from '@material-ui/core'
import {COMPANY_ID} from '../../../../utils/globals'
import NoDataListFree from '../../../shared-components/NoDataListFreeComponent'
import {Link} from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontStyle: 'bold',
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // padding: '10px',
  },
  expanded: {
    borderTop: '1px solid rgba(0, 0, 0, 0.16)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    margin: '0 !important',
  },
  bold: {
    fontWeight: 700,
  },
  mr15: {marginRight: '1.5rem'},
  textGrey: {color: '#a9a8a8'},
  emptyStateContainer: {margin: '136px 0'},
  buttonCreatePolicy: {
    padding: '14px 48px',
    marginTop: 16,
    fontSize: 12,
    fontWeight: 600,
  },
}))

export default function LeaveBalance({searchText}) {
  const classes = useStyles()

  const [limit, setLimit] = React.useState(20)
  const [offset, setOffset] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [rowPerPage, setRowPerPage] = React.useState(20)
  const {data, error} = useQuery(GET_LEAVE_BALANCE, {
    variables: {
      search: `%${searchText}%`,
      company: COMPANY_ID,
      offset,
      limit,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setCurrentPage(newPage)
    setOffset(newPage * limit)
  }

  const handleChangeRowsPerPage = event => {
    setRowPerPage(+event.target.value)
    setLimit(event.target.value)
    setOffset(0)
    setCurrentPage(0)
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Leave and Permission Balance
          </Typography>
        </Toolbar>
        <Divider />
        {data.time_leave_policies.length === 0 ? (
          <div className={classes.emptyStateContainer}>
            <NoDataListFree
              message1="Sorry, No Policy"
              message2="You haven't created any policies"
              button={
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  component={Link}
                  classes={{root: classes.buttonCreatePolicy}}
                  to={{
                    pathname: `/time/leave/settings/addparent`,
                    state: {active: 'add-settings-parent'},
                  }}
                >
                  Create Policy
                </Button>
              }
            />
          </div>
        ) : (
          data.time_leave_policies.map((policy, key) => {
            return (
              <ExpansionPanel
                key={key}
                elevation={0}
                classes={{
                  expanded: classes.expanded,
                }}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="body1"
                    className={classes.bold}
                    gutterBottom
                    color="primary"
                  >
                    {policy.name}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'block'}} width={1}>
                  {policy.time_leave_policy_children.map(
                    (policyChildren, k) => {
                      return (
                        <div key={k}>
                          <LeaveNameContainer>
                            <LeaveNameChild>
                              <Typography
                                variant="body1"
                                className={(classes.bold, classes.mr15)}
                              >
                                {k + 1}.
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.bold}
                              >
                                {policyChildren.name}
                              </Typography>
                            </LeaveNameChild>
                            <LeaveNameChild>
                              <BlueLink
                                to={{
                                  pathname: `/time/leave/leave-balance/${policyChildren.id}`,
                                  state: {
                                    active: 'edit-leave-balance',
                                    policy: policyChildren,
                                  },
                                }}
                                className={classes.mr15}
                              >
                                View Details
                              </BlueLink>
                            </LeaveNameChild>
                          </LeaveNameContainer>
                          <LeaveApplyContainer style={{marginBottom: '1rem'}}>
                            <Typography
                              variant="body1"
                              className={classes.textGrey}
                              display="inline"
                              style={{marginRight: 16}}
                            >
                              Leave and Permission Balance
                            </Typography>
                            <Typography
                              className={classes.textGrey}
                              display="inline"
                            >
                              {policyChildren.infinite_quota
                                ? 'Unlimited'
                                : policyChildren.quota + ' days'}
                            </Typography>
                          </LeaveApplyContainer>
                        </div>
                      )
                    }
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          })
        )}
      </Paper>
      {data.time_leave_policies.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          rowsPerPage={rowPerPage}
          page={currentPage}
          count={data.total.aggregate.count}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}
