import {
  Button,
  Dialog,
  DialogActions,
  makeStyles,
  withStyles,
  Tooltip,
  Typography,
  TextField,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import {
  FormChildContainerFull,
  FormChildTitle,
  FormContainer,
  ModalContentEvent,
  ModalHeader,
  TextHelper,
  TitleName,
} from '../../../../../GlobalStyles'
// import CircularProgress from '@material-ui/core/CircularProgress'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_BANK_TRIP } from '../../../../../graphql/queries'
import { Loading } from 'mdi-material-ui'
import RSelect, { components } from 'react-select'
import classNames from 'classnames'
import { UPLOAD_PAYMENT_PROCESS } from '../../../../../graphql/mutations'
import AddEditPopupFreeText from '../../../../shared-components/popup/AddEditPopupFreeText'
import { useSnackbar } from 'notistack'
import { TOKEN, UPLOAD_URL } from '../../../../../utils/globals'
import Axios from 'axios'
import {
  faFileExcel,
  faFileWord,
  faFilePdf,
  faFile,
  faFilePowerpoint,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PAYMENT_TYPE_OPTIONS } from '../../businessTripHelper'

const useStyles = makeStyles(() => ({
  rootDialog: {
    maxWidth: 750,
  },
  textTitle: {
    fontSize: 14,
    margin: 0,
    fontWeight: '600',
    color: '#014a62',
  },
  rootTite: {
    padding: '25px 20px',
    borderBottom: '1px solid #eff2f4',
  },
  rootContent: {
    padding: '22px 20px',
    fontSize: 12,
  },
  rootActions: {
    padding: '24px',
  },

  buttonFile: {
    margin: '8px 0px 12px 0px',
    width: '8vw',
    height: '8vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    width: '50%',
    height: '50%',
  },
  iconInfo: {
    marginLeft: 10,
    width: 18,
    height: 18,
  },
  textTooltip: {
    fontSize: 12,
    color: '#000',
    display: 'block',
    margin: '2px 0px',
  },
  textBlue: {
    color: '#014a62',
    fontSize: 12,
    display: 'block',
    margin: '2px 0px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  fileUploadded: {
    backgroundColor: '#eff2f4',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    borderRadius: 5,
  },
  top: {
    display: 'flex',
    flexDirection: 'row ',
  },
  fileName: {
    backgroundColor: '#fff',
    padding: '10px 16px',
    width: '100%',
    margin: '0px 10px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    marginTop: 12,
    marginRight: '3vw',
  },
  size: {
    color: '#000',
    fontSize: 12,
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: '#a9a8a8',
    borderRadius: '50%',
    margin: '0px 10px',
  },
  preview: {
    color: '#039be5',
    fontSize: 12,
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  textError: {
    color: '#f44336',
    margin: '8px 14px 0',
    fontSize: '0.75rem',
    fontWeight: '400',
  },
  pdf: {
    color: '#ef4d5e',
  },
  xls: {
    color: '#2c5a1a',
  },
  ppt: {
    color: '#ef4d5e',
  },
  doc: {
    color: '#039be5',
  },
}))

const getIcon = url => {
  let type = null
  let icon = faFile
  if (url) {
    const fileType = url.split('.').pop()
    switch (fileType) {
      case 'pdf':
        type = 'pdf'
        icon = faFilePdf
        break
      case 'xls':
      case 'xlsx':
        type = 'xls'
        icon = faFileExcel
        break
      case 'ppt':
        type = 'ppt'
        icon = faFilePowerpoint
        break
      case 'doc':
      case 'docx':
        type = 'doc'
        icon = faFileWord
        break
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'tiff':
      case 'pjp':
      case 'pjpeg':
        type = 'img'
        icon = null
        break
      default:
        type = null
        icon = faFile
        break
    }
  }
  return [type, icon]
}

const InfoTooltip = withStyles(() => ({
  tooltip: {
    padding: '12px 14px',
    backgroundColor: '#eff2f4',
    color: '#000',
    fontSize: 14,
    borderRadius: 5,
  },
  arrow: {
    color: '#eff2f4',
    backgroundColor: '#eff2f4',
  },
}))(Tooltip)

export default function UploadAttachment({ onClose, open, data, idUser }) {
  const classes = useStyles()
  const [allData, setAllData] = useState({
    type: null,
    bank_id: null,
    status: 'paid',
    id: data?.id ? data?.id : null,
  })
  const [select, setSelect] = useState({
    type: null,
    bank: null,
  })
  const { enqueueSnackbar } = useSnackbar()
  const [errorSize, setErrorSize] = useState(false)
  const [errorFile, setErrorFile] = useState(false)
  const [openConfirm, setConfirm] = useState(false)
  const [errorField, setErrorField] = useState(false)
  const [error, setError] = useState({
    name: false,
    type: false,
    file: false,
  })
  const [valueAttachment, setValueAttachment] = useState([])

  const [uploadPayment] = useMutation(UPLOAD_PAYMENT_PROCESS)

  const { data: dataBank, error: errorBank } = useQuery(GET_BANK_TRIP, {
    wlb_skipPatch: true,
    variables: {
      user: idUser,
    },
  })

  useEffect(() => {
    if (data) {
      setAllData({
        type: data?.payment_type,
        bank_id: data?.people_profile_bank?.id,
        status: 'paid',
        id: data?.id ? data?.id : null,
      })
      const selected_type =
        PAYMENT_TYPE_OPTIONS?.filter(
          e => e.value === data?.payment_type
        )?.[0] || null
      setSelect(prev => ({
        ...prev,
        type: selected_type,
        ...(data?.people_profile_bank && {
          bank: {
            value: data?.people_profile_bank?.id,
            label: data?.people_profile_bank?.account_number,
            description: `${data?.people_profile_bank?.bank_name} - ${data?.people_profile_bank?.account_name}`,
          },
        }),
      }))
      setValueAttachment(data?.attachment || [])
    }
  }, [data])

  if (dataBank === undefined && errorBank === undefined) {
    return <Loading />
  } else if (errorBank !== undefined) {
    return <div>{errorBank}</div>
  }

  const fileChecker = name => {
    const nameSplited = name.split('.')
    const idx = nameSplited.length - 1
    const res = nameSplited[idx]
    if (res === 'pdf') {
      return false
    } else if (res === 'jpg' || res === 'jpeg' || res === 'png') {
      return false
    } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
      return false
    } else {
      return true
    }
  }

  const handleChangeFile = e => {
    const tempFile = Array.from(e.target.files)
    if (tempFile.some(t => t.size > 1000 * 1000 * 25)) {
      setErrorSize(true)
      setTimeout(() => {
        setErrorSize(false)
      }, 3000)
      e.target.value = null
    } else if (!tempFile.some(t => !fileChecker(t.name))) {
      setErrorFile(true)
      setTimeout(() => {
        setErrorFile(false)
      }, 3000)
    } else {
      const newAttachment = []
      tempFile.map(val => {
        const formData = new FormData()
        formData.append('file', val)
        return Axios.post(UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true
        }).then(response => {
          newAttachment.push({
            name: val.name,
            url: response.data.url,
            size: val.size,
          })
          setValueAttachment([...valueAttachment, ...newAttachment])
        })
      })

      e.target.value = null
    }
  }

  const MenuList = props => {
    return (
      <components.MenuList {...props}>{props.children}</components.MenuList>
    )
  }

  const label = option => {
    return (
      <div className={classNames(classes.rselect2)}>
        <div
          style={{
            width: 80,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: 0,
          }}
        >
          {option.label}
        </div>
        <div
          style={{
            Width: 172,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className={classes.width30P}
        >
          {option.description}
        </div>
        {/* <span className={classes.width50P}>{option.label}</span> */}
      </div>
    )
  }

  const handleSubmit = () => {
    if (allData.type === null) {
      setErrorField(true)
    } else if (allData.type === 1) {
      if (allData.bank_id === null) {
        setErrorField(true)
      } else if (valueAttachment.length === 0) {
        setError({
          ...error,
          file: true,
        })
      } else {
        setConfirm(true)
      }
    } else if (allData.type === 2) {
      setConfirm(true)
    }
    setTimeout(() => {
      setErrorField(false)
      setError({
        ...error,
        file: false,
      })
    }, 2000)
  }
  const handleMutation = () => {
    if (allData) {
      uploadPayment({
        variables: {
          id: allData.id,
          status: allData.status,
          type: allData.type,
          bank_id: allData.bank_id,
          attachments: valueAttachment,
        },
      })
        .then(() => {
          onClose()
          enqueueSnackbar('Attachment Payment Added', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          onClose()
          enqueueSnackbar('Attachment Payment failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }
  return (
    <Dialog
      classes={{
        paper: classes.rootDialog,
      }}
      onClose={onClose}
      open={open}
      scroll="body"
      fullWidth
    >
      <ModalHeader classes={{ root: classes.rootTite }}>
        Upload Attachment
      </ModalHeader>
      <ModalContentEvent classes={{ root: classes.rootContent }}>
        <FormContainer>
          <FormChildContainerFull>
            <FormChildTitle style={{ display: 'flex', alignItems: 'center' }}>
              <TitleName>Payment Type*</TitleName>
            </FormChildTitle>
            <div>
              <RSelect
                className={classes.marginTop15}
                id="parent"
                options={PAYMENT_TYPE_OPTIONS}
                value={select.type}
                onChange={event => {
                  if (event.value === 2) {
                    setSelect({
                      ...select,
                      bank: null,
                      type: event,
                    })
                    setAllData({
                      ...allData,
                      bank_id: null,
                      type: event.value,
                    })
                  } else {
                    setSelect({
                      ...select,
                      type: event,
                    })
                    setAllData({
                      ...allData,
                      type: event.value,
                    })
                  }
                }}
                placeholder="Choose Budget Component Type"
                styles={{
                  menu: provided => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
              <TextHelper className={classes.marginLeft0}>
                {allData && !allData.type && errorField
                  ? 'This field is required'
                  : ''}
              </TextHelper>
            </div>
          </FormChildContainerFull>
          {allData.type !== null && (
            <>
              {allData.type === 1 && (
                <FormChildContainerFull>
                  <FormChildTitle
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <TitleName>Bank Account*</TitleName>
                  </FormChildTitle>
                  <div>
                    <RSelect
                      placeholder={'Choose Bank Account'}
                      components={{ MenuList }}
                      value={select?.bank}
                      onChange={event => {
                        setSelect({
                          ...select,
                          bank: event,
                        })
                        setAllData({
                          ...allData,
                          bank_id: event.value,
                        })
                      }}
                      // getOptionValue={option => [
                      // option && option.name,
                      // option && option.kpi_level && option.kpi_level.name,
                      // option && option.code,
                      // ]}
                      getOptionValue={option => option.label}
                      getOptionLabel={option => label(option)}
                      options={dataBank.people_profile_banks.map(res => ({
                        value: res.id,
                        label: res.account_number,
                        description: `${res.bank_name} - ${res.account_name}`,
                      }))}
                    // isLoading={loading || loadingById}
                    // onInputChange={d => setSearchDropdown(d)}
                    />
                    <TextHelper className={classes.marginLeft0}>
                      {allData &&
                        !allData.bank_id &&
                        !allData.type === 1 &&
                        errorField
                        ? 'This field is required'
                        : ''}
                    </TextHelper>
                  </div>
                </FormChildContainerFull>
              )}
              <FormChildContainerFull>
                <FormChildTitle style={{ display: 'flex', alignItems: 'center' }}>
                  <TitleName>Transfer Evidence</TitleName>
                  <InfoTooltip
                    // arrow
                    title={
                      <>
                        <Typography className={classes.textTooltip}>
                          Appropriate file extension:
                        </Typography>
                        <div className={classes.tooltipWrapper}>
                          <Typography className={classes.textTooltip}>
                            Document {'( '}
                          </Typography>
                          <Typography className={classes.textBlue}>
                            .pdf,
                          </Typography>
                          <Typography className={classes.textTooltip}>
                            {' )'}
                          </Typography>
                        </div>
                        <div className={classes.tooltipWrapper}>
                          <Typography className={classes.textTooltip}>
                            Image {'( '}
                          </Typography>
                          <Typography className={classes.textBlue}>
                            .jpg, .jpeg, .png,
                          </Typography>
                          <Typography className={classes.textTooltip}>
                            {' )'}
                          </Typography>
                        </div>
                        <Typography className={classes.textTooltip}>
                          Maximum file size of 25MB
                        </Typography>
                      </>
                    }
                    placement="right"
                  >
                    <ErrorOutlineOutlinedIcon
                      className={classes.iconInfo}
                      color="primary"
                    />
                  </InfoTooltip>
                </FormChildTitle>
                <div>
                  <input
                    style={{ visibility: 'hidden' }}
                    type="file"
                    id="atch"
                    name="updateFile"
                    onChange={e => handleChangeFile(e)}
                    // git
                    multiple
                  />
                  <label htmlFor="atch">
                    <div className={classes.buttonFile}>
                      <AddIcon className={classes.icon} color="primary" />
                    </div>
                  </label>
                  {error.file ? (
                    <Typography className={classes.textError}>
                      This field must not be empty
                    </Typography>
                  ) : null}
                  {errorSize ? (
                    <Typography className={classes.textError}>
                      file size cannot exceed 25MB
                    </Typography>
                  ) : null}
                  {errorFile ? (
                    <Typography className={classes.textError}>
                      This file type is not permitted
                    </Typography>
                  ) : null}
                  {valueAttachment.length > 0 &&
                    valueAttachment.map((res, i) => {
                      const [type, icon] = getIcon(res.url)
                      return (
                        <div key={i} className={classes.fileUploadded}>
                          <div className={classes.top}>
                            {/* {fileCheck(res.name)} */}
                            {icon ? (
                              <FontAwesomeIcon
                                className={classes[type]}
                                icon={icon}
                                style={{ marginRight: 8, fontSize: '56px' }}
                              />
                            ) : (
                              <img
                                alt="icon"
                                className={classes[type]}
                                src={res.url}
                                style={{ marginRight: 6, width: '45px' }}
                              />
                            )}
                            <TextField
                              value={valueAttachment[i].name}
                              variant="outlined"
                              onChange={e => {
                                const newVal = valueAttachment.slice()
                                newVal[i].name = e.target.value
                                setValueAttachment(newVal)
                              }}
                              fullWidth
                              style={{
                                background: 'white',
                                margin: '0 10px',
                              }}
                            />
                            <ClearIcon
                              onClick={() => {
                                const newVal = valueAttachment.slice()
                                newVal.splice(i, 1)
                                setValueAttachment(newVal)
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                          <div className={classes.bottom}>
                            <Typography className={classes.size}>
                              {res.size / 1000} kB
                            </Typography>
                            <div className={classes.dot}></div>
                            <a
                              href={res.url}
                              rel="noopener noreferrer"
                              target="_blank"
                              className={classes.link}
                            >
                              <Typography className={classes.preview}>
                                Preview
                              </Typography>
                            </a>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </FormChildContainerFull>
            </>
          )}
        </FormContainer>
      </ModalContentEvent>
      <DialogActions classes={{ root: classes.rootActions }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disableElevation
          //   disabled={isLoading}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>

      <AddEditPopupFreeText
        open={openConfirm}
        handleClose={() => setConfirm(false)}
        button={'Confirm'}
        body={`Are you sure want to upload attachment to this component`}
        header={` Upload Attachment`}
        feature={''}
        mutation={handleMutation}
      />
    </Dialog>
  )
}
