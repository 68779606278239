import React from "react";
import { Divider } from "@material-ui/core";
import {
  LabelContainer,
  LabelWrapper,
  LabelSymbol,
  LabelCaptionList,
  LabelMore,
} from "./LabelStyle";

const LabelList = (props) => {
  const { isWithDivider = true } = props;
  const { labels, more, dataSlice } = props.data;
  let dataValue, backgroundColor;

  if (props.data.datasets !== undefined) {
    ({ dataLabel: dataValue, backgroundColor } = props.data.datasets[0]);
  }

  return (
    <>
      {dataValue && (
        <>
          {isWithDivider && <Divider orientation="vertical" flexItem />}
          <LabelContainer list>
            {dataValue.slice(0, dataSlice || dataValue.length).map((value, idx) => {
              return (
                <LabelWrapper key={idx}>
                  <div
                    className="main-label"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: "80%",
                    }}
                  >
                    <LabelSymbol color={backgroundColor[idx]} />
                    <LabelCaptionList className="w-90 clamp-2">{labels[idx]}</LabelCaptionList>
                  </div>
                  <LabelCaptionList
                    style={{ width: "40%" }}
                    className="value-label"
                  >
                    {value}
                  </LabelCaptionList>
                </LabelWrapper>
              );
            })}
            {more && (
              <LabelMore onClick={more.onClickMore}>{more.moreText}</LabelMore>
            )}
          </LabelContainer>
        </>
      )}
    </>
  );
};

export default LabelList;
