import JobLevelPage from './JobLevelPageComponent'

export const JobLevelPageConfig = {
  routes: [
    {
      path: '/organization/job-level',
      component: JobLevelPage,
      state: 'job-level',
      exact: true,
    },
    {
      path: '/organization/job-level/add',
      component: JobLevelPage,
      state: 'add-job-level',
      exact: false,
    },
    {
      path: '/organization/job-level/edit',
      component: JobLevelPage,
      state: 'edit-job-level',
      exact: false,
    },
  ],
}
