import React from 'react'
import {
  Card,
  Checkbox,
  Typography,
  Divider,
  TextField,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core'

import helperStyle, {
  FlexBetween,
  FlexStart,
  ListStyle,
  CenterWrapper,
  TextGrey,
  TextBlack,
  TextDarkBlue,
} from '../../../../PayrollPageStyles'
import {Search} from '@material-ui/icons'
import classNames from 'classnames'
import EmployeeFilterPopUp from '../../../../../../shared-components/popup/EmployeeFilterPopUp'
// import FilterPopup from '../../pages/shared-components/popup/FilterPopup'
// import {useInfiniteScroll} from '../../../../../shared-components/customHooks'

const AssigneePeopleComponent = props => {
  const {
    people,
    checked,
    title = 'People',
    filter = false,
    search,
    setSearch,
    handleToggle,
    handleToggleAll,
    numberOfChecked,
    setFilterData,
    loading,
  } = props
  // const [isBottom, setIsBottom] = useInfiniteScroll('people-list')
  const classes = helperStyle()

  return (
    <Card
      elevation={0}
      className={classNames(classes.cardBorder, classes.width40P)}
    >
      <FlexBetween>
        <FlexStart style={{padding: 12}}>
          <Checkbox
            color="primary"
            onClick={handleToggleAll(people)}
            checked={
              numberOfChecked(people) === people.length && people.length !== 0
            }
            indeterminate={
              numberOfChecked(people) !== people.length &&
              numberOfChecked(people) !== 0
            }
            disabled={people.length === 0}
          />
          <Typography variant="body2">{title}</Typography>
        </FlexStart>
        {filter && (
          <IconButton>
            <EmployeeFilterPopUp setFilterData={setFilterData} />
          </IconButton>
        )}
      </FlexBetween>
      <Divider />
      <div style={{padding: 12}}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search .."
          fullWidth
          value={search}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Divider />
      <ListStyle>
        <div id={'people-list'}>
          {loading && (
            <CenterWrapper>
              <CircularProgress />
            </CenterWrapper>
          )}
          {!loading &&
            people &&
            people.map((item, i) => {
              const job = item.people_work_placement?.company_job_profile?.title
              const user = item.people_work_placement?.global_user
              const employeeId = item.people_work_placement?.regno
              const officeName = item.people_work_placement?.company_job_profile
                ?.office_location
                ? item.people_work_placement?.company_job_profile
                    ?.office_location?.office_name
                : null
              const officeCity = item.people_work_placement?.company_job_profile
                ?.office_location
                ? item.people_work_placement?.company_job_profile
                    ?.office_location?.global_city?.name
                : null
              const officeProvince = item.people_work_placement
                ?.company_job_profile?.office_location
                ? item.people_work_placement?.company_job_profile
                    ?.office_location?.global_province?.name
                : null

              return (
                <ListItem
                  key={i}
                  style={{paddingLeft: 12, paddingRight: 12}}
                  onClick={handleToggle(item)}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={checked.indexOf(item) !== -1}
                    />
                  </ListItemIcon>
                  <ListItemAvatar>
                    <Avatar alt={user.name} src={user.avatar}>
                      {user.name.slice(0, 1)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<TextDarkBlue>{user.name}</TextDarkBlue>}
                    secondary={
                      <span>
                        <TextBlack>
                          {employeeId} <br />
                        </TextBlack>
                        <TextGrey>
                          {job}{' '}
                          {(officeName || officeCity || officeProvince) && 'at'}{' '}
                          {officeName} {officeCity} {officeProvince}
                        </TextGrey>
                      </span>
                    }
                  />
                </ListItem>
              )
            })}
          {!loading && people && people.length === 0 && (
            <CenterWrapper>No Records to Display</CenterWrapper>
          )}
        </div>
      </ListStyle>
    </Card>
  )
}

export default AssigneePeopleComponent
