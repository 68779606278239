import gql from 'graphql-tag'

export const REJECT_OVERTIME_REQUEST = gql`
  mutation rejectOvertime(
    $id: bigint
    $status: String
    $overtime_fields: jsonb
  ) {
    update_people_work_overtimes(
      _set: {status: $status, overtime_fields: $overtime_fields}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const APPROVE_OVERTIME_REQUST = gql`
  mutation($id: bigint!, $overtime_fields: jsonb) {
    a: update_people_work_overtimes(
      _set: {status: "approved"}
      _append: {overtime_fields: $overtime_fields}
      where: {id: {_in: [$id]}, status: {_eq: "confirm1"}}
    ) {
      affected_rows
    }
    b: update_people_work_overtimes(
      _set: {status: "confirm1"}
      _append: {overtime_fields: $overtime_fields}
      where: {
        id: {_in: [$id]}
        status: {_neq: "approved"}
        time_overtime_policy: {
          second_confirm_profile: {_is_null: false}
          confirm_type: {_eq: "1"}
        }
      }
    ) {
      affected_rows
    }
    c: update_people_work_overtimes(
      _set: {status: "approved"}
      _append: {overtime_fields: $overtime_fields}
      where: {
        id: {_in: [$id]}
        time_overtime_policy: {second_confirm_profile: {_is_null: true}}
      }
    ) {
      affected_rows
    }
  }
`
export const UPDATE_STATUS_OVERTIME_REQUST = gql`
  mutation($id: bigint!, $status: String!, $overtime_fields: jsonb) {
    update_people_work_overtimes(
      _set: { status: $status }
      _append: { overtime_fields: $overtime_fields }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`