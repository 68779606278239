import React from 'react'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import {
  AttachmentItemHover,
  WrapAttachment,
} from '../../../../../shared-components/attachment'
import {TyphoSection} from '../../DetailEmployeeStyles'
import ChipsItem from '../../SharedDetailEmployee/ChipsItem'
import useEllipsisText from '../../../../../../hooks/useEllipsisText'
import useToggle from '../../../../../../hooks/useToggle'

export const ServiceAgreementItem = ({
  onPreview,
  code,
  date,
  description,
  attachments,
  onEdit,
}) => {
  const _description = description?.length === 0 ? '-' : description
  const {briefText, isOverLine, isOverLength} = useEllipsisText({
    text: _description,
  })
  const [more, setMore] = useToggle()

  return (
    <ChipsItem chipsName={code} onEdit={onEdit}>
      <TyphoSection title="true" style={{color: '#014a62'}}>
        {date}
      </TyphoSection>
      <TyphoSection mb="true" style={{color: '#333333'}}>
        {more ? _description : briefText}
      </TyphoSection>
      {more && (
        <>
          <WrapAttachment>
            {attachments &&
              attachments.map((attachment, i) => (
                <AttachmentItemHover
                  key={`${i}-itm`}
                  iconAction={
                    <VisibilityOutlinedIcon
                      onClick={() =>
                        onPreview(attachment.url || attachment.link)
                      }
                      className="icon"
                    />
                  }
                  fileName={attachment.name}
                  url={attachment.url || attachment.link}
                />
              ))}
          </WrapAttachment>
        </>
      )}

      {(isOverLine || isOverLength || attachments?.length > 0) && (
        <TyphoSection
          mt="true"
          style={{color: '#a9a8a8', cursor: 'pointer'}}
          onClick={setMore}
        >
          {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
        </TyphoSection>
      )}
    </ChipsItem>
  )
}
