import React from 'react'

import {PaperSection, WrapperInfoBlock} from '../../../GlobalStyles'
import {Typography, Divider, makeStyles} from '@material-ui/core'
import {TypographyStyleds} from '../../../components/typography/TypographyStyles'
import {dateENOrUS} from '../../../utils/helpers'
// import {capitalize} from '../../../utils/helpers'

const useStyles = makeStyles(() => ({
  divider: {margin: '22px 0!important'},
  dividerVertical: {height: '65px!important', margin: '0 34px!important'},
  numberContainer: {display: 'flex', textAlign: 'center'},
  paperContainer: {height: 'auto', overflow: 'auto', minWidth: '12rem'},
}))

const DetailSection = ({
  countEmp,
  industry,
  type,
  headquarter,
  countFollowers,
  founded,
  speciality,
}) => {
  const classes = useStyles()
  return (
    <PaperSection elevation={2} className={classes.paperContainer}>
      <Typography variant="body1">Detail</Typography>
      <Divider className={classes.divider} />
      <div className={classes.numberContainer}>
        <div>
          <TypographyStyleds variant="h6" color="#007fb2">
            {countEmp}
          </TypographyStyleds>
          <TypographyStyleds variant="caption" color="#a9a8a8">
            Employees
          </TypographyStyleds>
        </div>
        <Divider orientation="vertical" className={classes.dividerVertical} />
        <div>
          <TypographyStyleds variant="h6" color="#007fb2">
            {countFollowers}
          </TypographyStyleds>
          <TypographyStyleds variant="caption" color="#a9a8a8">
            Followers
          </TypographyStyleds>
        </div>
      </div>
      <Divider className={classes.divider} />
      <WrapperInfoBlock>
        <TypographyStyleds variant="body2" color="#a9a8a8">
          Industry
        </TypographyStyleds>
        <TypographyStyleds variant="caption" gutterBottom>
          {industry ?? '-'}
        </TypographyStyleds>
      </WrapperInfoBlock>
      <WrapperInfoBlock>
        <TypographyStyleds variant="body2" color="#a9a8a8">
          Type
        </TypographyStyleds>
        <TypographyStyleds variant="caption" gutterBottom>
          {/* {capitalize(type)} */}
          {type ?? '-'}
        </TypographyStyleds>
      </WrapperInfoBlock>
      <WrapperInfoBlock>
        <TypographyStyleds variant="body2" color="#a9a8a8">
          Founded
        </TypographyStyleds>
        <TypographyStyleds variant="caption" gutterBottom>
          {founded ? dateENOrUS(founded) : '-'}
        </TypographyStyleds>
      </WrapperInfoBlock>
      <WrapperInfoBlock>
        <TypographyStyleds variant="body2" color="#a9a8a8">
          Headquarters
        </TypographyStyleds>
        <TypographyStyleds variant="caption" gutterBottom>
          {headquarter}
        </TypographyStyleds>
      </WrapperInfoBlock>
      <WrapperInfoBlock>
        <TypographyStyleds variant="body2" color="#a9a8a8">
          Specialities
        </TypographyStyleds>
        <TypographyStyleds variant="caption" gutterBottom>
          {speciality ?? '-'}
        </TypographyStyleds>
      </WrapperInfoBlock>
    </PaperSection>
  )
}

export default DetailSection
