import gql from 'graphql-tag'

export const GET_LAERNING_PROFILE = gql`
  query($userId: String!) {
    learningProfile(userId: $userId) {
      certificates {
        name_to
        course_name
      }
      learning_duration {
        audiobook
        quiz
        course
        event_tracker
        practice_test
        quiz
        ebook
        classroom
      }
      teach_duration {
        audiobook
        quiz
        course
        event_tracker
        practice_test
        quiz
        ebook
        classroom
      }
      learning_material {
        audiobook
        course
        event
        event_tracker
        practice_test
        quiz
        ebook
        classroom
      }
      microlearnings {
        name
        false_answer_count
        correct_answer_count
        question_total
      }
    }
  }
`
