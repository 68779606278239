import React from 'react'
import {
  TyphoSection,
  WrapperItemSection,
  Style,
} from '../../DetailEmployeeStyles'
import useEllipsisText from '../../../../../../hooks/useEllipsisText'
import useToggle from '../../../../../../hooks/useToggle'

export const HobbiesItem = ({res}) => {
  const {briefText, isOverLine, isOverLength} = useEllipsisText({
    text: res?.description,
  })
  const [more, setMore] = useToggle()

  return (
    <WrapperItemSection>
      <div style={{height: 100, marginRight: 90}}>
        <div style={Style.wrapper}>
          <div style={Style.score}>
            {res.people_hobby && res.people_hobby.icon ? (
              <img
                style={{width: '60%', height: '60%'}}
                src={res.people_hobby && res.people_hobby.icon}
                alt="icon"
              />
            ) : (
              <div
                style={{
                  width: '60%',
                  height: '60%',
                  borderRadius: '50%',
                  backgroundColor: '#ccc',
                }}
              />
            )}
          </div>
          {res.people_hobby.name}
        </div>
      </div>
      <div className="divider">
        <div className="content">
          <TyphoSection
            style={{color: 'rgb(0, 127, 178)'}}
            title="true"
            bold="true"
          >
            {`${res?.people_hobby?.hobby_category?.name} - 
            ${res?.people_hobby?.hobby_sub_category?.name}`}
          </TyphoSection>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              padding: '10px 0px',
            }}
          >
            {res?.hobby_fields?.details?.map((hobby, idx) => (
              <div
                key={idx}
                style={{
                  padding: '7px 21px',
                  border: 'solid 1px #d0d4d4',
                  borderRadius: 16,
                  margin: '0px 10px 10px 0px',
                  backgroundColor: '#eff2f4',
                  fontSize: 12,
                }}
              >
                {hobby}
              </div>
            ))}
          </div>
          {res?.description?.length > 0 && (
            <TyphoSection mb="true">
              {more ? res?.description : briefText}
            </TyphoSection>
          )}

          {(isOverLine || isOverLength) && (
            <TyphoSection
              mt="true"
              style={{color: '#a9a8a8', cursor: 'pointer'}}
              onClick={setMore}
            >
              {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
            </TyphoSection>
          )}
        </div>
      </div>
    </WrapperItemSection>
  )
}
