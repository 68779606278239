import React from 'react'
import {withRouter} from 'react-router-dom'
import {convertToRoundedRupiah, dateFormat} from '../../../../utils/helpers'

import MaterialTable from 'material-table'

import {CustomTypography} from '../../../../GlobalStyles'
import {TableOptions} from '../ClaimPageStyles'
import RequestWrapper from '../../../shared-components/layouts/RequestWrapper'
import ClaimActionModal from '../activity/ClaimActionModal'

function ClaimReportDetail(props) {
  const {
    loading,
    data,
    totalCount,
    offset,
    limit,
    onChangeOffset,
    onChangeLimit,
  } = props
  const [idActivity, setIdActivity] = React.useState(0)
  const [showActionModal, setShowActionModal] = React.useState(false)

  const handleActionModal = id => {
    setIdActivity(id)
    setShowActionModal(true)
  }

  const handleClose = () => {
    setShowActionModal(false)
  }

  const renderClaimDate = data => {
    const date = data.claim_issued || data.claim_added
    return dateFormat(date, 'MMM DD, YYYY')
  }

  const renderClaimNominal = data => {
    return convertToRoundedRupiah(data.claim_nominal, false)
  }

  const customSortEmployee = (a, b) => {
    return a.people_work_placement.global_user.employee_name.localeCompare(
      b.people_work_placement.global_user.employee_name
    )
  }

  const customSortPosition = (a, b) => {
    return a.people_work_placement.company_job_profile.title.localeCompare(
      b.people_work_placement.company_job_profile.title
    )
  }

  const customSortEmployeeID = (a, b) => {
    return a.people_work_placement.employee_id.localeCompare(
      b.people_work_placement.employee_id
    )
  }

  const TABLE_COLUMNS_CONFIG = [
    {
      title: 'Request ID',
      field: 'claim_formatted_id',
      cellStyle: {
        minWidth: 120,
      },
    },
    {
      title: 'Employee ID',
      customSort: customSortEmployeeID,
      render: rowData => rowData.people_work_placement.employee_id,
    },
    {
      title: 'Employee Name',
      customSort: customSortEmployee,
      cellStyle: {
        minWidth: 150,
      },
      render: rowData =>
        rowData.people_work_placement.global_user.employee_name,
    },
    {
      title: 'Position',
      customSort: customSortPosition,
      cellStyle: {
        minWidth: 175,
      },
      render: rowData =>
        rowData.people_work_placement.company_job_profile.title,
    },
    {
      title: 'Claim Date',
      field: 'claim_date',
      cellStyle: {
        minWidth: 150,
      },
      render: renderClaimDate,
    },
    {
      title: 'Claim Nominal',
      field: 'claim_nominal',
      cellStyle: {
        minWidth: 150,
      },
      render: renderClaimNominal,
    },
    {
      title: 'Action',
      sorting: false,
      render: rowData => (
        <CustomTypography
          fweight="bold"
          fcolor="lightBlue"
          cursor="pointer"
          style={{textDecoration: 'none'}}
          onClick={() => handleActionModal(rowData.claim_id)}
        >
          Detail
        </CustomTypography>
      ),
    },
  ]

  return (
    <RequestWrapper
      loading={loading}
      isEmpty={!loading && !data?.length}
      emptyMessage="There is no claim report"
    >
      <MaterialTable
        data={data}
        totalCount={totalCount}
        page={offset}
        pageSize={limit}
        onChangePage={onChangeOffset}
        onChangeRowsPerPage={onChangeLimit}
        columns={TABLE_COLUMNS_CONFIG}
        options={TableOptions}
      />
      <ClaimActionModal
        id={idActivity}
        open={showActionModal}
        onClose={handleClose}
      />
    </RequestWrapper>
  )
}

export default withRouter(ClaimReportDetail)
