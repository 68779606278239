import gql from 'graphql-tag'

export const GET_LIST_LEAVE_COMPANY_HOLIDAY = gql`
  query getDropdownLeaveCompanyHoliday(
    $where: time_leave_policy_children_bool_exp
    $limit: Int
  ) {
    time_leave_policy_children(
      where: $where
      order_by: {name: asc}
      limit: $limit
    ) {
      id
      name
    }
  }
`
