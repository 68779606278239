import styled from 'styled-components'
import {
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import {makeStyles, withStyles} from '@material-ui/core/styles'

export const BigAvatar = styled(Avatar)`
  width: 80px !important;
  height: 80px !important;
`

export const ContentAccordion = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px 67px 25px 27px;
`

export const TextLightBlue = styled(Typography)`
  font-size: 13px;
  color: #039be5;
`

export const CustomAccordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion)

export const CustomAccordionSummary = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      border: 'none',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(AccordionSummary)

export const CustomAccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
  },
}))(AccordionDetails)

const helperStyle = makeStyles(() => ({
  column: {
    flexDirection: 'column !important',
  },
  mt10: {
    marginTop: '10px',
  },
  mr10: {
    marginRight: '10px',
  },
  mr25: {
    marginRight: '25px',
  },
  mb30: {
    marginBottom: '30px',
  },
  bold: {
    fontWeight: 'bold',
  },
  divStatus: {
    padding: '5px 10px',
    borderRadius: '5px',
    color: '#fff',
    fontSize: '12px',
    textAlign: 'center',
    width: '97px',
  },
  customList: {
    width: '7px',
    height: '7px',
    backgroundColor: '#a9a8a8',
    margin: '5px 8px 0px 0px',
    borderRadius: '50%',
  },
}))

export default helperStyle
