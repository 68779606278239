import gql from 'graphql-tag'

export const EDIT_ORG_LEVEL = gql`
  mutation($id: Int, $name: String, $code: String, $color_hex: String) {
    update_company_employee_ranks(
      _set: {name: $name, code: $code, color_hex: $color_hex}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
