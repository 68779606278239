import React, {useState, useEffect} from 'react'
import {Button, CircularProgress} from '@material-ui/core'
import MaterialTable from 'material-table'
import {Link, withRouter} from 'react-router-dom'
import helperStyle, {
  TableOptions,
  TableEmployeeStyled,
  CenterWrapper,
  TextDarkBlue,
  TextBlueBold,
  pageSizeOption,
  MediumTextBlackBold,
} from '../../PayrollPageStyles'
import {
  convertToRoundedRupiah,
  employeeColumnSort,
} from '../../../../../utils/helpers'
import ModalPaySlip from '../../shared-components/ModalPayslip'
import {
  GET_BALOON_STATUS_BUTTON_QUERY,
  GET_LIST_EMPLOYEE_THR,
} from '../../../../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import {EnhancedToolbar, GreyTypography} from '../../../../../GlobalStyles'
import {COMPANY_ID} from '../../../../../utils/globals'
import {CLOSE_BALOON, IMPORT_DATA} from '../../../../../graphql/mutations'
import ImportBaloonPayroll from '../../../../../components/importExcel/ImportBaloonPayroll'
import ImportPopup from '../../../../../components/importExcel/ImportPopup'
import EmployeeAvatar from '../../../../shared-components/EmployeeAvatar'

function StepTHRProcess(props) {
  // eslint-disable-next-line no-unused-vars
  const {
    religion,
    activeStep,
    searchText,
    location,
    filterData,
    setProssesImport,
  } = props
  const {state} = location
  const classes = helperStyle()
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const [openPaySlip, setOpenPaySlip] = useState(false)
  const [employeeId, setEmployeeId] = useState()
  const [openImport, setImport] = useState(false)
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')
  const slicedParams = location.search.split('&') // example: [ "?year=2021", "month=8", "id=289", "religion=Islam" ]

  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    refetch: refetchBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON_QUERY, {
    variables: {
      company: COMPANY_ID,
      table: 'finance_thr_process_component',
    },
  })

  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )

  const getFilter = filter => {
    if (JSON.stringify(filter) === '[]') {
      return {}
    }
    return {_in: filter}
  }

  const {data: thrProcessData, refetch, loading, error} = useQuery(
    GET_LIST_EMPLOYEE_THR,
    {
      variables: {
        where: {
          id_schedule: {_eq: state.id || null},
          people_work_placement: {
            _or: [
              {global_user: {name: {_ilike: `%${searchText}%`}}},
              {regno: {_ilike: `%${searchText}%`}},
            ],
            company_job_profile: {
              _and: [
                {
                  id: getFilter(filterData.job_profile),
                },
                {
                  organization: getFilter(filterData.organization),
                },
                {
                  position: getFilter(filterData.position),
                },
                {
                  rank: getFilter(filterData.rank),
                },
                {
                  office_address: getFilter(filterData.office),
                },
              ],
            },
          },
        },
        offset: currentPage * rowsPerPage,
        limit: rowsPerPage,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  useEffect(() => {
    setCurrentPage(0)
    if (
      getBaloonStatus &&
      getBaloonStatus.company_import_data_logs.length > 0
    ) {
      const check = getBaloonStatus.company_import_data_logs.filter(row => {
        return row.status === 'WAITING'
      })
      if (check.length > 0) {
        setProssesImport(true)
      } else {
        setProssesImport(false)
      }
    } else {
      setProssesImport(false)
    }
  }, [getBaloonStatus, searchText, rowsPerPage, filterData])

  const importMutation = async () => {
    try {
      // eslint-disable-next-line
      const response = await mutationImport({
        variables: {
          table: 'finance_thr_process_component',
          file: fileURL,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus &&
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  const handleClickOpenImport = () => {
    setImport(true)
  }

  const handleCloseImport = () => {
    setImport(false)
  }
  if (
    error !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationImportError !== undefined
  ) {
    return (
      <div>
        {JSON.stringify(error) ||
          JSON.stringify(errorGetBaloonStatus) ||
          JSON.stringify(mutationCloseBaloonError) ||
          JSON.stringify(mutationImportError)}
      </div>
    )
  }

  if (getBaloonStatus) {
    // console.log("[getBaloonStatus]",getBaloonStatus)
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  return (
    <>
      {!thrProcessData && loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {activeStep === 1 && (
        <>
          <EnhancedToolbar>
            <MediumTextBlackBold>THR Component Calculation</MediumTextBlackBold>
            <Button
              variant={'outlined'}
              color="primary"
              size="large"
              style={{marginRight: 12}}
              onClick={() => handleClickOpenImport()}
              disabled={
                (getBaloonStatus &&
                  getBaloonStatus.company_import_data_logs.length > 0 &&
                  // eslint-disable-next-line
                  getBaloonStatus.company_import_data_logs[0].status ===
                    'WAITING') ||
                false
              }
            >
              {getBaloonStatus &&
              getBaloonStatus.company_import_data_logs.length > 0 &&
              getBaloonStatus.company_import_data_logs[0].status ===
                'WAITING' ? (
                <GreyTypography>Importing on proces...</GreyTypography>
              ) : (
                <TextDarkBlue>Import Component</TextDarkBlue>
              )}
            </Button>
          </EnhancedToolbar>
          {getBaloonStatus && (
            <ImportBaloonPayroll
              data={getBaloonStatus}
              closeBaloonMutation={closeBaloonMutation}
              setImport={setImport}
              mutationCloseBaloon={mutationCloseBaloon}
              refetchBaloonStatus={refetchBaloonStatus}
            />
          )}
        </>
      )}

      {thrProcessData && (
        <TableEmployeeStyled>
          {activeStep >= 4 && (
            <ModalPaySlip
              open={openPaySlip}
              handleClose={() => {
                setEmployeeId()
                setOpenPaySlip(false)
              }}
              employeeId={employeeId}
              from={'thr'}
            />
          )}
          <MaterialTable
            data={thrProcessData.employees}
            options={{...TableOptions}}
            totalCount={thrProcessData.employee_count.aggregate.count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={page => {
              setCurrentPage(page)
            }}
            isLoading={thrProcessData && loading}
            onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
            columns={[
              {
                title: 'Employee',
                field: 'employee',
                headerStyle: {minWidth: 350},
                cellStyle: {minWidth: 350},
                customSort: (a, b) => employeeColumnSort(a, b),
                render: rowData => {
                  return (
                    <EmployeeAvatar
                      src={rowData.employee_placement?.global_user.avatar}
                      regno={rowData.employee_placement?.regno}
                      title={
                        rowData.employee_placement?.company_job_profile.title
                      }
                      global_user={rowData.employee_placement?.global_user}
                      city_name={
                        rowData.employee_placement?.company_job_profile
                          .company_address.global_city.name
                      }
                      province_name={
                        rowData.employee_placement?.company_job_profile
                          .company_address.global_province.name
                      }
                      empStatus={rowData.employee_placement?.status}
                      empEndDate={rowData.employee_placement?.end_date}
                    />
                  )
                },
              },
              // {
              //   title: 'Religion',
              //   field: 'religion',
              //   headerStyle: {minWidth: 175},
              //   cellStyle: {minWidth: 175, textAlign: 'left'},
              //   render: () => {
              //     return religion
              //   },
              // },
              {
                title: 'THR',
                field: 'thr',
                headerStyle: {
                  minWidth: 175,
                  textAlign: 'left',
                },
                cellStyle: {
                  minWidth: 175,
                  textAlign: 'left',
                },
                render: rowData => {
                  return convertToRoundedRupiah(rowData.thr, false)
                },
              },
              {
                title: 'Earning',
                field: 'income',
                headerStyle: {minWidth: 175},
                cellStyle: {
                  minWidth: 175,
                  textAlign: 'left',
                },
                customSort: (a, b) => {
                  return a.income - b.income
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.income, false),
              },
              {
                title: 'Deduction',
                field: 'deduction',
                headerStyle: {minWidth: 175},
                cellStyle: {
                  minWidth: 175,
                  textAlign: 'left',
                },
                customSort: (a, b) => {
                  return a.deduction - b.deduction
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.deduction, false),
              },
              {
                title: 'THR Total',
                field: 'thr_total',
                headerStyle: {
                  minWidth: 175,
                  textAlign: 'left',
                },
                cellStyle: {
                  minWidth: 175,
                  textAlign: 'left',
                },
                customSort: (a, b) => {
                  return a.thr_total - b.thr_total
                },
                render: rowData => {
                  // Total Formula thr + income a.k.a allowance - deduction
                  return convertToRoundedRupiah(rowData?.final_thr, false)
                },
              },
              {
                title: 'Action',
                field: 'action',
                sorting: false,
                headerStyle: {textAlign: 'center'},
                cellStyle: {minWidth: '120px', textAlign: 'center'},
                render: rowData => {
                  return activeStep < 4 ? (
                    <Link
                      to={{
                        pathname: `/financial/payroll/thr-process/thr-detail/${rowData.id}`,
                        state: {
                          active: 'thr-detail',
                          employeeId: rowData.id,
                          filterData: filterData,
                          scheduleId: state.id || null,
                          religion: religion,
                          year: slicedParams[0].replace(/\D/g, ''),
                          month: slicedParams[1].replace(/\D/g, ''),
                        },
                      }}
                      className={classes.link}
                    >
                      <TextBlueBold>Details</TextBlueBold>
                    </Link>
                  ) : (
                    <TextBlueBold
                      className={classes.link}
                      onClick={() => {
                        setEmployeeId(rowData.id)
                        setOpenPaySlip(true)
                      }}
                    >
                      View Payslip
                    </TextBlueBold>
                  )
                },
              },
              // {
              //   title: 'Action',
              //   field: 'action',
              //   render: rowData => {
              //     return (
              //       <TextBlueBold
              //         className={classes.link}
              //         onClick={() => {
              //           if (rowData.people_work_thrs.length > 0) {
              //             setEmployeeId(rowData.people_work_thrs[0].id)
              //           }
              //           setOpenPaySlip(true)
              //         }}
              //       >
              //         View Payslip
              //       </TextBlueBold>
              //     )
              //   },
              // },
            ]}
          />
        </TableEmployeeStyled>
      )}
      <ImportPopup
        open={openImport}
        position={'Component'}
        handleClose={handleCloseImport}
        mutation={importMutation}
        // onDownloadMaster={({provinces, cities, religions}) =>
        //   generateTemplateMaster({provinces, cities, religions})
        // }
        // fileTemplate={
        //   'https://public-media.wlb.co.id/1dc6d763-8ad8-4e8a-8d26-5bb73dd569f2-1602486963025.xlsx'
        // }
        isAlreadyImported={isAlreadyImported}
        setIsAlreadyImported={setIsAlreadyImported}
        setImport={setImport}
        fileURL={fileURL}
        setFileURL={setFileURL}
        closeBaloon={closeBaloonMutation}
        errorsImport={
          getBaloonStatus &&
          getBaloonStatus.company_import_data_logs.length > 0 &&
          getBaloonStatus.company_import_data_logs[0].reports &&
          getBaloonStatus.company_import_data_logs[0].reports.failed.length > 0
            ? getBaloonStatus.company_import_data_logs[0].reports.failed
            : []
        }
      />
    </>
  )
}

export default withRouter(StepTHRProcess)
