import React from 'react'
import {
  capitalize,
  dateFormat,
  differenceDate,
} from '../../../../../../../utils/helpers'

import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'
import {ItemWrapper} from '../../../DetailEmployeeStyles'
import {MARTITAL_STATUS, WORKING_STATUS, GENDER_TYPES} from '../data-tab-shared'

const row = (header, content) => (
  <tr>
    <th scope="row">{header}</th>
    <td>{content || '-'}</td>
  </tr>
)

const DetailDependent = props => {
  const {open, data, onClose} = props

  if (!data) {
    return null
  }

  return (
    <ModalDetail open={open} onClose={onClose} title="Detail Tanggungan">
      <div style={{padding: 24}}>
        <ItemWrapper>
          <h1 className="title">{data.name}</h1>
          <table className="content" style={{'--row-gap': '16px'}}>
            {row('Nama', data.name)}
            {row('Hubungan', capitalize(data.relationship || '-'))}
            {row('Penerima', data.is_beneficiary ? 'Ya' : 'Tidak')}
            {row('Tempat Lahir', data.birthplace)}
            {row('Tanggal Lahir', dateFormat(data.birthdate, 'DD MMMM YYYY'))}
            {row('Jenis Kelamin', GENDER_TYPES[data.gender])}
            {row('Usia Tanggungan', differenceDate(data.birthdate))}
            {row('Nomor Identitas', data.identity_number)}
            {row('Status Perkawinan', MARTITAL_STATUS[data.marital_status])}
            {row('Status Pekerjaan', WORKING_STATUS[data.working_status])}
            {row('Aparatur Sipil Negara', data.civil_servant ? 'Ya' : 'Tidak')}
            {row(
              'Pegawai Swasta',
              data.is_company_group_employee ? 'Ya' : 'Tidak'
            )}
            {row('Perusahaan', data.company)}
            {row('Status Tanggungan', data.dependent_status)}
            {row(
              'Nomor Telepon',
              data.dependant_fields?.phone_code + data.dependant_fields?.phone
            )}
            {row('Golongan Darah', data.blood_type)}
            {row('Alamat', data.address)}
            {row('Negara', data.global_country?.name)}
            {row('Kode Pos', data.postal_code)}
          </table>
        </ItemWrapper>
      </div>
    </ModalDetail>
  )
}

export default DetailDependent
