import {useQuery} from '@apollo/react-hooks'
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Icon,
  makeStyles,
  Paper,
  Radio,
  Typography,
} from '@material-ui/core'
import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {BoldTypography} from '../../../../../components/typography/TypographyStyles'
import {
  CustomTypography,
  Flex,
  FlexBetween,
  FlexCenters,
  GreyTypography,
} from '../../../../../GlobalStyles'
import {GET_MICROLEARNING_INDIVIDUAL_REPONSE} from '../../../../../graphql/queries'
import LoadingSurvey from '../../../../survey/multirater-nps/survei-center/LoadingSurvey'

const useStyles = makeStyles(() => ({
  root: {background: '#F7F8F9 0% 0% no-repeat padding-box', minHeight: '100vh'},
  header: {
    display: 'flex',
    height: 81,
    boxShadow: '0px 3px 6px #0000001A',
    justifyContent: 'space-between',
    padding: '0 98px',
    alignItems: 'center',
    textAlign: 'center',
    zIndex: 1,
    position: 'relative',
    background: 'white',
  },
  pointer: {cursor: 'pointer'},
  body: {
    padding: '22px 86px',

    display: 'flex',
    // minHeight: 'calc(100vh - 81px - 44px)',
    minHeight: 600,
  },
  sidebar: {width: '24vw', marginRight: '1vw'},
  sidebarTitle: {padding: '24px'},
  content: {width: '75vw', padding: '20px 24px', position: 'relative'},

  loading: {
    backgroundColor: '#fff',
    marginLeft: 10,
    marginTop: '25vh',
    borderRadius: '5px',
    width: '100%',
    fontFamily: 'Muli',
    height: '100vh',
  },
  questionContainer: {margin: '20px 0'},
  avatarQuestion: {margin: 'auto', marginBottom: 8},
  scoreStep: {
    display: 'flex',
    flexDirection: 'row',
    color: 'grey',
    margin: '20px 0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ml12: {marginLeft: 12},
  activeQuest: {
    margin: '0 0 10px',
    padding: '10px 12px 6px 20px',
    backgroundColor: '#f7f8f9',
    color: '#014a62',
    width: '100%',
    justifyContent: 'space-between',
  },
  quest: {
    margin: '0 0 10px',
    padding: '10px 12px 6px 20px',
    color: '#a9a8a8',
    width: '100%',
    justifyContent: 'space-between',
  },
  correctIcon: {
    color: '#4caf50',
    border: '1px solid #4caf50',
    borderRadius: '50%',
    fontSize: '16px !important',
    padding: 3,
    marginLeft: 12,
  },
  wrongIcon: {
    color: '#ef4d5e',
    border: '1px solid #ef4d5e',
    borderRadius: '50%',
    fontSize: '16px !important',
    padding: 3,
    marginLeft: 12,
  },
  halfIcon: {
    color: '#ffa000',
    border: '1px solid #ffa000',
    borderRadius: '50%',
    fontSize: '16px !important',
    padding: 3,
    marginLeft: 12,
  },
  dividerContent: {margin: '20px -24px'},
  mb8: {marginBottom: 8},
  footer: {
    justifyContent: 'space-between',
    display: 'flex',
    position: 'absolute',
    bottom: '3%',
    width: '94%',
  },
}))

const EmpResponse = props => {
  const userId = props.match.params.userId
  const classes = useStyles()
  const back = () => props.history.goBack()
  const [indexQuest, setindexQuest] = useState(0)

  const {data, loading} = useQuery(GET_MICROLEARNING_INDIVIDUAL_REPONSE, {
    variables: {
      microlearningId: props.match.params.id,
      userId,
    },
  })

  const handleChangeQuest = q => {
    setindexQuest(q)
  }

  const handleNextPrev = isPrev => {
    setindexQuest(isPrev ? indexQuest - 1 : indexQuest + 1)
  }

  if (loading) {
    return (
      <div className={classes.loading}>
        <LoadingSurvey />
      </div>
    )
  }

  const renderCorrect = res => {
    if (res.microlearning_member_answers.length === 0) {
      return null
    } else if (res.microlearning_member_answers[0].score === 100) {
      return <Icon className={classes.correctIcon}>check</Icon>
    } else if (
      res.microlearning_member_answers[0].score < 100 &&
      res.microlearning_member_answers[0].score > 0
    ) {
      return <Icon className={classes.halfIcon}>check</Icon>
    } else {
      return <Icon className={classes.wrongIcon}>close</Icon>
    }
  }

  const renderCorrectAnswer = opt => {
    if (opt.is_correct && opt.microlearning_member_answer_options.length > 0) {
      return (
        <Icon style={{color: '#4caf50'}} className={classes.ml12}>
          check
        </Icon>
      )
    } else if (
      !opt.is_correct &&
      opt.microlearning_member_answer_options.length > 0
    ) {
      return (
        <Icon style={{color: '#4caf50'}} className={classes.ml12}>
          close
        </Icon>
      )
    } else {
      return null
    }
  }

  const _data = data && data.microlearnings[0]

  const dataMember =
    _data.microlearning_members.length > 0 && _data.microlearning_members[0]

  const user = dataMember.global_user

  const questionData =
    _data.microlearning_questions.length > 0 &&
    _data.microlearning_questions[indexQuest]

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <img
          src={require('../../../../../assets/slicing/logo-wlb-teal.svg')}
          alt="logo"
          width={150}
          onClick={back}
          className={classes.pointer}
        />
        <Flex>
          <BoldTypography variant="body1">{_data.name}</BoldTypography>
          <Typography variant="body1" className={classes.ml12}>
            {_data.status}
          </Typography>
        </Flex>
        <GreyTypography
          variant="body1"
          color="primary"
          className={classes.pointer}
          onClick={back}
        >
          Close
        </GreyTypography>
      </div>
      <div className={classes.body}>
        <Paper elevation={3} className={classes.sidebar}>
          <BoldTypography
            variant="body1"
            color="primary"
            className={classes.sidebarTitle}
          >
            Question
          </BoldTypography>

          <Divider />
          <div className={classes.questionContainer}>
            {_data.microlearning_questions.length > 0 &&
              _data.microlearning_questions.map((res, i) => {
                return (
                  <Button
                    key={i}
                    className={
                      indexQuest === i ? classes.activeQuest : classes.quest
                    }
                    onClick={() => handleChangeQuest(i)}
                  >
                    <Flex style={{width: '85%', alignItems: 'center'}}>
                      <BoldTypography variant="body2">{i + 1}.</BoldTypography>
                      <Typography
                        variant="body2"
                        component="div"
                        noWrap
                        className={classes.ml12}
                        dangerouslySetInnerHTML={{__html: res.question}}
                      />
                    </Flex>
                    {renderCorrect(res)}
                  </Button>
                )
              })}
          </div>
        </Paper>
        <Paper elevation={3} className={classes.content}>
          <FlexBetween>
            <FlexCenters>
              <Avatar src={user.avatar} alt={user.name} />
              <div>
                <Typography
                  className={classes.ml12}
                  color="primary"
                  variant="h6"
                >
                  {user.name}
                </Typography>
              </div>
            </FlexCenters>
            <div>
              <Typography variant="body1">Score:</Typography>
              <Flex style={{alignItems: 'baseline'}}>
                <CustomTypography size="32px" fcolor="green">
                  {dataMember.score}
                </CustomTypography>
                <Typography variant="body1" className={classes.ml12}>
                  ({dataMember.question_answered_count}/{_data.question_count})
                </Typography>
              </Flex>
            </div>
          </FlexBetween>
          <Divider className={classes.dividerContent} />
          <FlexCenters className={classes.mb8}>
            <CustomTypography fweight="bold">
              {indexQuest + 1}.
            </CustomTypography>
            <div
              style={{width: '100%', fontFamily: 'muli'}}
              className={classes.ml12}
              dangerouslySetInnerHTML={{__html: questionData.question}}
            />
          </FlexCenters>
          {questionData &&
            questionData.microlearning_question_answers.map(opt => {
              return questionData.question_type === 0 ? (
                <FlexCenters
                  key={opt.id}
                  className={`${classes.ml12} ${classes.mb8}`}
                >
                  <Radio
                    checked={opt.is_correct}
                    color="primary"
                    disabled={!opt.is_correct}
                  />
                  <Typography>{opt.answer}</Typography>
                  {renderCorrectAnswer(opt)}
                </FlexCenters>
              ) : (
                <FlexCenters
                  key={opt.id}
                  className={`${classes.ml12} ${classes.mb8}`}
                >
                  <Checkbox
                    checked={opt.is_correct}
                    color="primary"
                    disabled={!opt.is_correct}
                  />
                  <Typography>{opt.answer}</Typography>
                  {renderCorrectAnswer(opt)}
                </FlexCenters>
              )
            })}
          <div className={classes.footer}>
            <Button
              onClick={() => handleNextPrev(true)}
              color="primary"
              size="large"
              variant="outlined"
              disabled={indexQuest === 0}
            >
              Previous
            </Button>
            <Button
              onClick={() => handleNextPrev(false)}
              color="primary"
              size="large"
              variant="contained"
              disabled={indexQuest + 1 === _data.question_count}
            >
              Next
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default withRouter(EmpResponse)
