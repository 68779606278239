import gql from 'graphql-tag'

export const GET_ASPIRATION_LIST = gql`
  query($offset: Int, $limit: Int, $where: people_work_placements_bool_exp!) {
    total: people_work_placements_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: $where
      offset: $offset
      limit: $limit
      order_by: {talent_aspirations_aggregate: {max: {last_modified: asc}}}
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      talent_aspirations {
        id
        company_job_profile {
          id
          title
          job_description
          job_summary
          company_address {
            id
            office_name
            address
          }
          company_job_profile_r_educations {
            global_degree {
              id
              name
            }
            global_faculty {
              id
              name
            }
          }
          company_job_profile_competencies {
            id
            competency_dictionary {
              id
              name
            }
            competency_category {
              id
              name
              description
            }
            competency_version {
              id
              name
            }
            level
          }
          experience_level
        }
      }
      company_job_profile {
        id
        title
        company_address {
          id
          office_name
          address
        }
      }
      talent_assignments(
        order_by: {id: desc}
        limit: 1
        where: {
          talentVersionByTalentVersion: {
            status: {_eq: "COMPLETED"}
            classification_type: {_eq: "TALENT"}
            visibility: {_eq: "PUBLISH"}
          }
        }
      ) {
        id
        talent_version
        talent_score
        talent_box {
          id
          name
          badge
        }
      }
    }
  }
`
