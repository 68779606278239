import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'

import {
  makeStyles,
  Box,
  Chip,
  Divider,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {Autocomplete} from '@material-ui/lab'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import MaterialTable from 'material-table'

import {FilterContainer, PaperContainer} from '../../../../GlobalStyles'
import {TableOptionsTime} from '../../../shared-components/table/TableOptions'
import {
  AttendanceActivityDetailActivity,
  AttendanceEditActivity,
} from './DialogComponent'

import {getGMT, getInOutDateDetail} from '../attendance-helper'
import {
  GET_REPORT_USER,
  GET_DROPDOWN_POSITION_ATT,
} from '../../../../graphql/queries'

const StartDatePicker = ({classes, startDate, onDateChange}) => {
  const [selectedDate, setSelectedDate] = React.useState(startDate)

  const handleDateChange = date => {
    date.setHours(0, 0, 0, 0)

    setSelectedDate(date)
    onDateChange(date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        label="Start Date"
        format="MMM dd, yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        inputVariant="outlined"
        className={classes.customDatePicker}
        InputAdornmentProps={{
          classes: {
            root: classes.m0,
          },
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

const EndDatePicker = ({classes, endDate, onDateChange}) => {
  const [selectedDate, setSelectedDate] = React.useState(endDate)

  const handleDateChange = date => {
    date.setHours(23, 59, 59, 999)

    setSelectedDate(date)
    onDateChange(date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        label="End Date"
        format="MMM dd, yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        inputVariant="outlined"
        className={classes.customDatePicker}
        InputAdornmentProps={{
          classes: {
            root: classes.m0,
          },
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

const EnhancedTableToolbar = ({user}) => {
  return (
    <Toolbar>
      <IconButton
        component={Link}
        to={{
          pathname: '/time/attendance',
          state: {active: 'activity', searchable: true},
        }}
        edge="start"
      >
        <ArrowBackIcon />
      </IconButton>

      <Typography variant="subtitle1" style={{fontWeight: '600'}}>
        {user && user.name} Attendance Activity
      </Typography>
    </Toolbar>
  )
}

const DetailActivity = ({detailActivityLabel, detailActivityData}) => {
  const classes = useStyles()
  return (
    <div className={classes.detailActivityWrapper}>
      <Typography className={classes.detailActivityLabel}>
        {detailActivityLabel}
      </Typography>
      <Typography className={classes.detailActivityTitle}>
        {detailActivityData}
      </Typography>
    </div>
  )
}

DetailActivity.propTypes = {
  detailActivityLabel: PropTypes.string.isRequired,
  detailActivityData: PropTypes.string.isRequired,
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    padding: 24,
    justifyContent: 'space-between',
  },
  detailActivityWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailActivityLabel: {
    color: '#014a62',
    fontSize: 15,
    fontWeight: 600,
  },
  detailActivityTitle: {
    color: '#000000',
    fontSize: 17,
    fontWeight: 600,
  },
  detail: {
    color: '#007fb2',
    cursor: 'pointer',
  },
  customDatePicker: {
    width: '11rem',
    marginRight: 12,
  },
  customPosition: {
    width: '20rem',
    marginRight: 12,
  },
  table: {
    minWidth: 750,
    // marginLeft: 31,
    // marginRight: 31,
  },
  m0: {margin: 0},
}))

const OPTION_ALL = {id: null, company_job_profile: {title: 'All'}}

export default function ActivityDetailDate({
  data,
  // profile,
  initialStateData: initState,
}) {
  const currentDate = React.useMemo(() => new Date(), [])

  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [startDate, setStartDate] = React.useState(() =>
    initState
      ? initState.startDate
      : moment(currentDate)
          .startOf('month')
          .toDate()
  )
  const [endDate, setEndDate] = React.useState(() =>
    initState
      ? initState.endDate
      : moment(currentDate)
          .endOf('day')
          .toDate()
  )
  const [attendances, setAttendances] = React.useState(
    initState ? initState.attendances : []
  )

  const [countReport, setCountReport] = React.useState(
    initState ? initState.countReport : undefined
  )
  const [selected, setSelected] = React.useState(
    initState ? initState.selectedAttendance : undefined
  )

  const [position, setPosition] = useState(data)

  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openActivityDialog, setOpenActivityDialog] = useState(false)

  const {loading, error, refetch} = useQuery(GET_REPORT_USER, {
    skip: !position,
    variables: {
      userWorkId: data.id,
      selectedPlacementId: position?.id,
      startDate: startDate,
      endDate: endDate,
    },
    onCompleted: data => {
      if (data) {
        const reports = data.getAttendanceReportsUser

        const info = [
          {
            label: 'In',
            data: `${reports.countIn} Days`,
          },
          {
            label: 'Absence',
            data: `${reports.countAbsent} Days`,
          },
          {
            label: 'On Leave',
            data: `${reports.countOnLeave} Days`,
          },
          {
            label: 'On Trip',
            data: `${reports.countOnTrip} Days`,
          },
          {
            label: 'On Trip Non Working',
            data: `${reports.countOnHolidayTrip} Days`,
          },
          {
            label: 'Late in Day',
            data: `${reports.countLateDays} Days`,
          },
          {
            label: 'Late in Minute',
            data: `${Math.round(reports.countLateMinutes)} Minutes`,
          },
        ]

        setCountReport(info)
        setAttendances(reports.result)
      }
    },
  })

  const {data: dataPosition, loading: loadingPos, error: errPos} = useQuery(
    GET_DROPDOWN_POSITION_ATT,
    {
      variables: {
        user: data.global_user.id,
      },
    }
  )

  const handleCloseEdit = success => {
    if (success) {
      enqueueSnackbar('Success Update data', {
        variant: 'success',
        autoHideDuration: 3000,
      })
      refetch()
    }

    setOpenEditDialog(false)
  }

  return (
    <>
      <PaperContainer>
        <EnhancedTableToolbar user={data.global_user} />

        <Divider />

        {(error || errPos) && (
          <Box
            borderRadius={4}
            bgcolor="error.main"
            color="error.contrastText"
            p={1}
            m={3}
            mb={1}
          >
            Something went wrong, please try again later
          </Box>
        )}

        <FilterContainer>
          <Autocomplete
            options={
              dataPosition
                ? [OPTION_ALL].concat(dataPosition.people_work_placements)
                : []
            }
            getOptionLabel={option =>
              option &&
              option.company_job_profile &&
              option.company_job_profile.title
            }
            onChange={(event, newValue) => {
              event.preventDefault()
              setPosition(newValue)
            }}
            value={position}
            disabled={loadingPos}
            className={classes.customPosition}
            renderInput={params => (
              <TextField
                {...params}
                margin="normal"
                label="Position"
                variant="outlined"
                placeholder="Choose Position"
              />
            )}
          />
          <StartDatePicker
            classes={classes}
            startDate={startDate}
            onDateChange={date => {
              setStartDate(date)
            }}
          />
          <EndDatePicker
            classes={classes}
            endDate={endDate}
            onDateChange={date => {
              setEndDate(date)
            }}
          />
        </FilterContainer>

        <div className={classes.content}>
          {countReport &&
            countReport.map((activity, index) => (
              <div key={index}>
                <DetailActivity
                  detailActivityLabel={activity.label}
                  detailActivityData={activity.data}
                />
              </div>
            ))}
        </div>

        <MaterialTable
          style={{padding: '24px 24px 0'}}
          isLoading={loading}
          columns={[
            {
              title: 'Date',
              field: 'clock_in',

              render: rowData =>
                getInOutDateDetail(rowData, rowData.clock_in, 'LL'),
            },
            {
              title: 'Clock - In',
              field: 'clock_in',
              render: rowData =>
                getInOutDateDetail(rowData, rowData.clock_in, 'LT'),
            },
            {
              title: 'Clock-in Time Zone',
              field: 'clock_in',
              sorting: false,
              render: rowData => getGMT(rowData),
            },
            {
              title: 'Clock - Out',
              field: 'clock_out',
              render: rowData => (
                <Typography>
                  {moment(rowData.clock_out).diff(
                    moment(rowData.clock_in),
                    'days',
                    false
                  ) > 0 && (
                    <Chip
                      label={moment(rowData.clock_out).diff(
                        moment(rowData.clock_in),
                        'days',
                        false
                      )}
                      size="small"
                      color="primary"
                      style={{
                        marginRight: '5px',
                      }}
                    />
                  )}

                  {rowData.clock_out === null
                    ? 'Not Checkout yet'
                    : getInOutDateDetail(rowData, rowData.clock_out, 'LT')}
                </Typography>
              ),
            },
            {
              title: 'Clock-out Time Zone',
              field: 'clock_out',
              sorting: false,
              render: rowData => getGMT(rowData),
            },
            {
              title: 'Late (minutes)',
              field: 'late_minutes',
              render: rowData =>
                rowData.late_minutes < 0 ? 0 : rowData.late_minutes,
            },
            {
              title: 'Description',
              field: 'description',
            },
            {
              title: 'Action',
              field: '',
              align: 'center',
              sorting: false,
              render: rowData => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  {rowData.clock_out !== null && (
                    <Typography
                      style={{
                        textDecoration: 'none',
                        color: '#007fb2',
                        cursor: 'pointer',
                        fontSize: 14,
                        marginRight: 12,
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        setSelected(rowData)
                        setOpenEditDialog(true)
                      }}
                    >
                      Edit
                    </Typography>
                  )}
                  <Typography
                    component={Link}
                    style={{
                      textDecoration: 'none',
                      color: '#007fb2',
                      cursor: 'pointer',
                      fontSize: 14,
                    }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    to={{
                      pathname: '/time/attendance/date/detail',
                      state: {
                        active: 'date-detail',
                        attendance: rowData,
                        rootData: data,
                        previousStateData: {
                          // rowsPerPage: rowsPerPage,
                          // page: page,
                          attendances: attendances,
                          startDate: startDate,
                          endDate: endDate,
                          countReport: countReport,
                          selectedAttendance: selected,
                        },
                      },
                    }}
                  >
                    Detail
                  </Typography>
                </div>
              ),
            },
          ]}
          data={attendances}
          options={{
            ...TableOptionsTime,
            toolbar: false,
            selection: false,
            emptyRowsWhenPaging: false,
            rowStyle: {
              wordBreak: 'unset',
            },
          }}
          onRowClick={(event, rowData) => {
            event.preventDefault()
            setSelected(rowData)
            setOpenActivityDialog(true)
          }}
        />
      </PaperContainer>
      {openEditDialog && (
        <AttendanceEditActivity
          isShow={openEditDialog}
          date={'Friday, 01 March 2019'}
          onClose={handleCloseEdit}
          attendanceData={selected}
        />
      )}
      <AttendanceActivityDetailActivity
        isShow={openActivityDialog}
        closeDialog={() => {
          setOpenActivityDialog(false)
        }}
        date={'Attendance Detail - Mon, 01 Mar 2019'}
        peopleData={data}
        attendanceData={selected}
      />
    </>
  )
}
