import gql from 'graphql-tag'

export const ADD_TALENT_JOB_PROFILE = gql`
  mutation(
    $competency_model: [competency_model_items_insert_input!]!
    $job_profile: Int
    $salary_from: float8
    $salary_to: float8
    $slot: Int
    $education: [company_job_profile_r_educations_insert_input!]!
    $course: [company_job_profile_r_course_insert_input!]!
  ) {
    insert_competency_model_items(objects: $competency_model) {
      affected_rows
    }

    update_company_job_profiles(
      where: {id: {_eq: $job_profile}}
      _set: {
        rate_salary_from: $salary_from
        rate_salary_to: $salary_to
        slots: $slot
      }
    ) {
      affected_rows
    }

    insert_company_job_profile_r_educations(objects: $education) {
      affected_rows
    }

    insert_company_job_profile_r_course(objects: $course) {
      affected_rows
    }
  }
`
