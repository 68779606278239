import React, {useState} from 'react'
import {
  PaperContainer,
  FormContainer,
  AddEditTitle,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  FormChildButton,
} from '../../../../GlobalStyles'
import {
  Divider,
  IconButton,
  Toolbar,
  TextField,
  Button,
  Typography,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Link, withRouter} from 'react-router-dom'
import {useMutation} from '@apollo/react-hooks'
import {ADD_QUESTION_BANK} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'

function AddQuestionBank(props) {
  const {id} = props.match.params
  const {enqueueSnackbar} = useSnackbar()
  const [question, setQuestion] = useState('')
  const [questionNumber, setQuestionNumber] = useState('')
  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }
  const [mutationAddQuestion] = useMutation(ADD_QUESTION_BANK)
  const addQuestion = () => {
    if (question === '' || questionNumber === '') {
      const ids = document.getElementById('top')
      ids.scrollIntoView({behavior: 'smooth'})
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const data = {
        group: id,
        question_self: question,
        question_others: question,
        number: questionNumber,
      }

      mutationAddQuestion({variables: {data}})
        .then(() => {
          enqueueSnackbar('Success add data', {variant: 'success'})
          props.history.push({
            pathname: `/survey/multirater-nps/question-bank/${id}`,
            state: {active: 'view-question-bank'},
          })
        })
        .catch(() => {
          enqueueSnackbar('Add data error, please try again', {
            variant: 'error',
          })
        })
    }
  }
  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link
          to={{
            pathname: `/survey/multirater-nps/question-bank/${id}`,
            state: {active: 'view-question-bank'},
          }}
        >
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <AddEditTitle variant="h6">Tambah Pertanyaan</AddEditTitle>
      </Toolbar>
      <Divider></Divider>
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Pertanyaan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                setQuestion(event.target.value)
              }}
              variant="outlined"
              value={question}
              error={question.length < 1 && isError}
              helperText={
                question.length < 1 && isError ? 'Bagian ini diperlukan' : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Nomor Pertanyaan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              type="number"
              inputProps={{min: '1'}}
              margin="normal"
              onChange={event => {
                setQuestionNumber(event.target.value)
              }}
              variant="outlined"
              value={questionNumber}
              error={questionNumber.length < 1 && isError}
              helperText={
                questionNumber.length < 1 && isError
                  ? 'Bagian ini diperlukan'
                  : ''
              }
            />
            <Typography variant="body2">
              Urutan default &quot;0&quot; akan menempatkan pertanyaan ini di
              akhir daftar
            </Typography>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <FormChildButton>
        <Button
          color="default"
          size="large"
          component={Link}
          to={{
            pathname: `/survey/multirater-nps/question-bank/${id}`,
            state: {active: 'view-question-bank'},
          }}
          style={{marginRight: 12}}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          size="large"
        >
          Simpan
        </Button>
      </FormChildButton>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        header="Tambah Pertanyaan baru"
        body="Apakah Anda yakin ingin menambah pertanyaan baru?"
        button="Tambah"
        buttonClose="Batal"
        mutation={addQuestion}
      />
    </PaperContainer>
  )
}

export default withRouter(AddQuestionBank)
