import React, {useState, useEffect} from 'react'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {Box, Button, Popover, Typography} from '@material-ui/core'
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'
import {useSnackbar} from 'notistack'

import Annual from './tab/Annual'
import Payroll from './tab/Payroll'
import Tax from './tab/Tax'
import Thr from './tab/Thr'

import helperStyle, {
  AntTabs,
  BigTextBlackBold,
  ItemMenu,
  StyledTab,
} from '../PayrollPageStyles'
import {DatePickerWrap} from '../../../../components/card/CardStyles'
import {
  EnhancedToolbar,
  Flex,
  PaperContainer,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import useDownloadReport from '../../../../hooks/useDownloadReport'
import AlertDialog from '../../../shared-components/popup/ResultPopup'

import {CLEAR_ANNUAL_REPORT} from '../../../../graphql/mutations'
import {GQL_FINANCE_OPTIONS} from '../utils'
import {useMutation} from '@apollo/react-hooks'

const TABLE_REPORT_NAME = 'finance_annual_report'
const TABLE_REPORT_GENERATE = 'finance_annual_generate'

const confrmAlert = {
  generate: {
    title: 'Generate Data is Processing!',
    body:
      'Generate Data data is processing!. Don’t worry, you can still do other activities',
  },
  report: {
    title: 'Download Data is Processing!',
    body:
      'Download data is processing!. We’ll send you an email when it’s ready.',
  },
}

export default function ReportComponent(props) {
  const {searchText, history} = props
  const {enqueueSnackbar} = useSnackbar()

  const classes = helperStyle()

  const [value, setValue] = useState(0)
  const [year, setYear] = useState()
  const [isDownloadable, setIsDownloadable] = useState(false)
  const [openGenerateAlert, setOpenGenerateAlert] = useState(false)
  const [isGenerate, setIsGenerate] = useState(false)
  const [anchor, setAnchor] = useState(null)

  useEffect(() => {
    const TABS = {
      payroll: 0,
      thr: 1,
      tax: 2,
      annual: 3,
    }

    setValue(TABS[props.history.location.state.tab || 'payroll'])
  }, [])

  const {
    dataBaloon: dataGenerate,
    onDownload: onGenerate,
    onCloseBaloon: onCloseGenerate,
    isDisableButton: isDisableButtonGenerate,
  } = useDownloadReport({
    table: TABLE_REPORT_GENERATE,
  })
  const {
    dataBaloon,
    onDownload,
    onCloseBaloon,
    isDisableButton,
  } = useDownloadReport({
    table: TABLE_REPORT_NAME,
  })
  const YEAR_ON_DATE = Number(moment(year).format('YYYY'))
  const [clearAnnualReport] = useMutation(CLEAR_ANNUAL_REPORT, {
    variables: {year: YEAR_ON_DATE},
    ...GQL_FINANCE_OPTIONS,
  })

  const handleChange = (e, newValue) => {
    e.preventDefault()

    if (Number(newValue) === 3) {
      history.push({
        ...history.location,
        state: {
          ...history.location.state,
          rangePeriod: false,
          showPeriod: false,
          searchable: true,
          filter: true,
        },
      })
    } else {
      history.push({
        ...history.location,
        state: {
          ...history.location.state,
          rangePeriod: false,
          showPeriod: false,
          searchable: false,
        },
      })
    }

    setValue(newValue)
  }

  const handleGenerateAnnual = () => {
    clearAnnualReport()
      .then(() => {
        onGenerate({
          variables: {
            table: TABLE_REPORT_GENERATE,
            filter: {period_year: YEAR_ON_DATE},
          },
        })
      })
      .catch(() => {
        enqueueSnackbar(
          'Clear data before generate data error, please try again',
          {
            variant: 'error',
          }
        )
      })
  }

  const handleDownloadAnnual = () => {
    onDownload({
      variables: {
        table: TABLE_REPORT_NAME,
        filter: {period_year: YEAR_ON_DATE},
      },
    })
  }

  const generateAnnualReport = () => {
    setOpenGenerateAlert(false)
    setAnchor(null)
    if (isGenerate) {
      return handleGenerateAnnual()
    }

    handleDownloadAnnual()
  }

  const openPopover = event => {
    setAnchor(event.currentTarget)
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <BigTextBlackBold>Payroll Report</BigTextBlackBold>

        <Flex flexEnd style={{gap: 10}}>
          <DatePickerWrap>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                views={['year']}
                className="date-picker-dialog"
                KeyboardButtonProps={{'aria-label': 'change date'}}
                inputVariant="outlined"
                format="yyyy"
                placeholder="Choose Period"
                value={year}
                color={'primary'}
                onChange={value => setYear(value)}
              />
            </MuiPickersUtilsProvider>
          </DatePickerWrap>

          {value === 3 && isDownloadable && (
            <div>
              <Button variant="contained" color="primary" onClick={openPopover}>
                Annual Report
              </Button>
              <Popover
                anchorEl={anchor}
                open={Boolean(anchor)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={() => setAnchor(null)}
              >
                <ItemMenu>
                  <GenerateTooltip
                    title={
                      isDisableButtonGenerate
                        ? `You can only generate data once a day`
                        : ``
                    }
                    placement="left"
                  >
                    <span>
                      <Button
                        size="small"
                        color="primary"
                        variant="text"
                        disabled={isDisableButtonGenerate}
                        style={{
                          minWidth: 125,
                          justifyContent: 'flex-start',
                        }}
                        onClick={() => {
                          setOpenGenerateAlert(true)
                          setIsGenerate(true)
                        }}
                      >
                        Generate
                      </Button>
                    </span>
                  </GenerateTooltip>
                  <GenerateTooltip
                    title={
                      isDisableButton
                        ? `You can only download data once a day`
                        : ``
                    }
                    placement="left"
                  >
                    <span>
                      <Button
                        size="small"
                        color="primary"
                        variant="text"
                        disabled={isDisableButton}
                        style={{
                          minWidth: 125,
                          justifyContent: 'flex-start',
                        }}
                        onClick={() => {
                          setOpenGenerateAlert(true)
                          setIsGenerate(false)
                        }}
                      >
                        Download
                      </Button>
                    </span>
                  </GenerateTooltip>
                </ItemMenu>
              </Popover>
            </div>
          )}
        </Flex>
      </EnhancedToolbar>

      <AntTabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        className={classes.tabs}
      >
        <StyledTab label="Payroll" />
        <StyledTab label="THR" />
        <StyledTab label="Tax" />
        <StyledTab label="Annual" />
      </AntTabs>

      <TabPanel value={value} index={value}>
        {value === 0 && <Payroll period={year} />}
        {value === 1 && <Thr period={year} searchText={searchText} />}
        {value === 2 && <Tax period={year} searchText={searchText} />}
        {value === 3 && (
          <Annual
            year={YEAR_ON_DATE}
            searchText={searchText}
            setIsDownloadable={setIsDownloadable}
            onGenerate={onGenerate}
            onDownload={onDownload}
            isDisableButtonGenerate={isDisableButtonGenerate}
            dataBaloon={dataBaloon}
            dataGenerate={dataGenerate}
            onCloseBaloon={onCloseBaloon}
            onCloseGenerate={onCloseGenerate}
          />
        )}
      </TabPanel>
      <AlertDialog
        open={openGenerateAlert}
        handleClose={() => setOpenGenerateAlert(false)}
        mutation={generateAnnualReport}
        feature={confrmAlert[isGenerate ? 'generate' : 'report'].title}
        message={confrmAlert[isGenerate ? 'generate' : 'report'].body}
        type="Confirm"
      />
    </PaperContainer>
  )
}

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  )
}
