export const FILE_TYPE_UPLOAD = [
  'doc',
  'docx',
  'xls',
  'pdf',
  'xlsx',
  'ppt',
  'jpg',
  'jpeg',
  'png',
  'gif',
  'tiff',
  'pjp',
  'pjpeg',
  'jfif',
  'tif',
]

export const DATA_FILTER = [
  {
    label: 'About',
    value: 'about',
  },
  {
    label: 'Training, License & Certification',
    value: 'tlc',
  },
  {
    label: 'Identity Card',
    value: 'identity_card',
  },
  // {
  //   label: 'Disciplinary',
  //   value: 'disciplinary',
  // },
  {
    label: 'Dependent',
    value: 'dependent',
  },
  // {
  //   label: 'Service Agreement',
  //   value: 'service_agreement',
  // },
  {
    label: 'Emergency Contact',
    value: 'emergency_contact',
  },
  // {
  //   label: 'OHSA History',
  //   value: 'ohsa_history',
  // },
  // {
  //   label: 'Medical Record',
  //   value: 'medical_record',
  // },
  {
    label: 'Hobbies',
    value: 'hobbies',
  },
  {
    label: 'Working Status',
    value: 'working_status',
  },
  {
    label: 'Skills',
    value: 'skills',
  },
  {
    label: 'Experience',
    value: 'experience',
  },
  {
    label: 'Referral',
    value: 'recomendation',
  },
  // {
  //   label: 'Organization Activity',
  //   value: 'organization_activity',
  // },
  // {
  //   label: 'Phone Number',
  //   value: 'phone_number',
  // },
  // {
  //   label: 'Project',
  //   value: 'project',
  // },
  // {
  //   label: 'Address',
  //   value: 'address',
  // },
  // {
  //   label: 'Speaker/Informat/Jury',
  //   value: 'speaker',
  // },
  // {
  //   label: 'Email Address',
  //   value: 'email_address',
  // },
  {
    label: 'Education',
    value: 'education',
  },
  // {
  //   label: 'Social Media Contact',
  //   value: 'social_media_contact',
  // },
  // {
  //   label: 'Award',
  //   value: 'award',
  // },
]

export const EMPOYEE_PERSONAL_DATA = 'Employee Personal Data'

export const TEMPLATES_IMPORT_PERSONAL_DATA = [
  // 'Address',
  // 'Phone Number',
  // 'Email',
  // 'Another Contact',
  // 'About',
  // 'Identity Card',
  // 'Dependent',
  // 'Emergency Contact',
  // 'Back Account',
  // 'Insurance',
  // 'Medical Profile',
  // 'Medical History',
  // 'Assesment',
  {templateName: 'Experience', tableName: 'people_profile_experiences'},
  // 'Project',
  // 'Organization Activity',
  // 'Education',
  {templateName: 'Training Licenses Certification', tableName: 'people_profile_certificates'},
  // 'Speaker/Informant/Jury',
  // 'Hobby',
  // 'Skill',
  // 'Award',
  // 'Publication',
  // 'Service Agreement',
  // 'Disciplinary',
  // 'OHSA'
]

export const TEXT_TOOLTIP = `You can only generate data once a day. to download data use the link that already generated`

export const DEFAULT_DATA_RUNNING = {
  import: [],
  export: [],
}

export const INITIAL_INFO_STATE = {
  open: false,
  title: '',
  content: '',
  type: '',
  onConfirm: undefined,
  isLoading: false,
}