import gql from 'graphql-tag'

export const GET_JOB_FAMILY_NAME = gql`
  query($companyId: uuid!, $search: String) {
    job_families(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {_or: [{name: {_ilike: $search}}, {code: {_ilike: $search}}]}
        ]
      }
    ) {
      id
      code
      name
      description
      job_functions {
        id
        name
      }
      job_functions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const CHECK_CODE_JOB_FAMILY = gql`
  query($company: uuid, $code: String) {
    job_families_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_JOB_FAMILY_LIST = gql`
  query($company: uuid, $search: String, $offset: Int, $limit: Int) {
    total: job_families_aggregate(
      where: {
        company: {_eq: $company}
        _or: [{name: {_ilike: $search}}, {code: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
    job_families(
      where: {
        company: {_eq: $company}
        _or: [{name: {_ilike: $search}}, {code: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
      description
      filter_job_title
      company_employee_positions {
        id
        designation_code
        name
      }
    }
  }
`

export const GET_JOB_FAMILY_DETAIL = gql`
  query($id: Int) {
    job_families(where: {id: {_eq: $id}}) {
      id
      code
      name
      description
    }
  }
`

export const GET_JOB_FAMILY_FUNCTION = gql`
  subscription getJFFunction($companyId: uuid!, $search: String) {
    job_family_functions(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
            ]
          }
        ]
      }
    ) {
      id
      code
      name
      job_family {
        id
        code
        name
      }
      description
      company_profile {
        id
        legal_name
        brand_name
      }
      company_employee_positions(order_by: [{id: desc}]) {
        id
        name
      }
      company_employee_positions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_MAPPING_TABLE = gql`
  subscription getMappingTable($companyId: uuid!, $search: String) {
    job_family_mapping: job_family_functions(
      where: {
        _and: {
          company: {_eq: $companyId}
          _or: [
            {job_family: {name: {_ilike: $search}}}
            {name: {_ilike: $search}}
            {job_family_category: {name: {_ilike: $search}}}
            {job_family_grade: {name: {_ilike: $search}}}
            {job_family_level: {name: {_ilike: $search}}}
          ]
        }
      }
    ) {
      job_family {
        id
        name
      }
      function_id: id
      function_name: name
      job_family_category {
        id
        name
      }
      job_family_grade {
        id
        name
      }
      job_family_level {
        id
        name
      }
    }
  }
`
export const GET_JOB_FAMILY_FUNCTION_SHEET = gql`
  query getJFName($company: uuid!) {
    job_families(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
      order_by: [{code: asc_nulls_last}]
    ) {
      id
      code
      name
    }
  }
`
export const GET_JOB_TITLE_TEMPLATE_SHEET = gql`
  query($company: uuid!) {
    job_family_functions(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
      order_by: [{code: asc}]
    ) {
      id
      code
      job_family {
        name
      }
      name
    }
  }
`
