import gql from 'graphql-tag'

export const INSERT_PREMIUM_SETTING = gql`
  mutation(
    $name: String
    $insurance: Int #ambil dari global_insurane_list
    $premium_fee: float8
    $company_portion: float8
    $employee_portion: float8
    $description: String
    $age_expire: Int
    $age_beneficiaries: Int
    $premium_fields: jsonb
    $effective_date: date
    $assignments: [finance_premium_assignments_insert_input!]! #fill with id from people_work_placements/assignee candidate
  ) {
    insert_finance_insurance_premiums_one(
      object: {
        insurance_company: $insurance
        premium_name: $name
        premium_fee: $premium_fee
        company_portion: $company_portion
        employee_portion: $employee_portion
        description: $description
        effective_date: $effective_date
        age_expire: $age_expire
        age_beneficiaries: $age_beneficiaries
        premium_fields: $premium_fields
        finance_premium_assignments: {data: $assignments}
      }
    ) {
      id
    }
  }
`
