import React, {useState} from 'react'
import {useApolloClient} from '@apollo/react-hooks'

import {ButtonSubmit, SwitchForm} from '../../SharedDetailEmployee'
import {ModalAddEditWraper} from '../../DetailEmployeeStyles'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import FormInput from '../ShareComponents-detail/FormInput'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'

import {
  ADD_SPEAKER,
  DELETE_SPEAKER,
  UPDATE_SPEAKER,
} from '../../../../../../graphql/mutations'
import {isErrorForm} from '../ShareComponents-detail/helperDetail'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_FORM_DATA = {
  event_name: '',
  location: '',
  organizer: '',
  roles: ['example role'],
  scale: null,
  total_participants: null,
  description: '',
  is_share: false,
}

const AddEditSpeaker = ({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
}) => {
  const client = useApolloClient()

  const [formState, setFormState] = useState(STATE_INITIAL)
  const [formData, setFormData] = useState(INITIAL_FORM_DATA)
  const [openDelete, setOpenDelete] = useState(false)

  const isEditing = actionType === 'edit'

  const isError = formState === STATE_ERROR
  const isDispatching = formState === STATE_DISPATCH

  React.useEffect(() => {
    if (actionType === 'edit' && open) {
      setFormData(e => ({
        ...e,
        event_name: data.event_name,
        location: data.location?.event_location,
        organizer: data.organizer,
        roles: data.roles,
        scale: data.scale,
        total_participants: data.total_participants,
        description: data.description,
        is_share: data.is_share,
      }))
    } else {
      setFormData(e => ({
        ...e,
        user: userIdDetail,
      }))
    }
  }, [open, actionType])

  const handleAutocompleteChange = item => {
    const {fieldName, label, value} = item

    setFormData({
      ...formData,
      [fieldName]: {label, value},
    })
  }

  const saveEditMutation = () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const verb = isEditing ? 'ubah' : 'tambah'
    let promise
    const variables = Object.assign({}, formData)
    variables.location = {event_location: formData.location}
    variables.total_participants = Number(formData.total_participants)

    if (isEditing) {
      promise = client.mutate({
        mutation: UPDATE_SPEAKER,
        variables: {
          id: data?.id,
          object: variables,
        },
      })
    } else {
      promise = client.mutate({
        mutation: ADD_SPEAKER,
        variables: {
          objects: variables,
        },
      })
    }

    promise.then(
      () => {
        refetch()
        setFormState(STATE_INITIAL)
        enqueueSnackbar(`Pembicara/narasumber/juri di${verb}`, {
          variant: 'success',
        })

        handleCloseModal()
      },
      err => {
        setFormState(STATE_INITIAL)
        console.error(err)
        enqueueSnackbar(
          `Gagal ${
            verb === 'add' ? 'menambahkan' : 'mengubah'
          } pembicara/narasumber/juri`,
          {
            variant: 'error',
          }
        )
      }
    )
  }

  const deleteMutation = () => {
    const promise = client.mutate({
      mutation: DELETE_SPEAKER,
      variables: {
        id: data.id,
      },
    })

    promise.then(
      () => {
        refetch()
        handleCloseModal()
        enqueueSnackbar(`Pembicara/narasumber/juri dihapus`, {
          variant: 'success',
        })
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Gagal menghapus pembicara/narasumber/juri`, {
          variant: 'error',
        })
      }
    )
  }

  const handleOpenModalDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }

  const closeModalDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  const handleCloseModal = () => {
    onClose()
    setFormData(INITIAL_FORM_DATA)
  }

  const fieldsList = [
    {
      type: 'textfield',
      label: 'Nama Acara*',
      placeholder: 'Tambahkan nama acara',
      fieldName: 'event_name',
      required: true,
      value: formData.event_name,
      error: !formData.event_name,
    },
    {
      type: 'tag-textfield',
      label: 'Peran*',
      placeholder: 'Tambahkan peran',
      fieldName: 'roles',
      required: true,
      value: formData.roles,
      error: formData.roles?.length === 0,
    },
    {
      type: 'textfield',
      label: 'Penyelenggara*',
      placeholder: 'Tambahkan penyelenggara',
      fieldName: 'organizer',
      required: true,
      value: formData.organizer,
      error: !formData.organizer,
    },
    {
      type: 'select',
      label: 'Skala Acara*',
      placeholder: 'Pilih skala acara',
      required: true,
      fieldName: 'scale',
      value: formData.scale,
      option: [
        {name: 'Perusahaan', value: 'Perusahaan'},
        {name: 'Nasional', value: 'Nasional'},
        {name: 'Internasional', value: 'Internasional'},
        {name: 'Non-formal', value: 'Non-formal'},
      ],
      error: !formData.scale,
      onChange: handleAutocompleteChange,
    },
    {
      type: 'textfield',
      label: 'Lokasi*',
      placeholder: 'Tambahkan lokasi',
      fieldName: 'location',
      required: true,
      value: formData.location,
      error: !formData.location,
    },
    {
      type: 'textfield',
      inputType: 'number',
      label: 'Total Peserta*',
      placeholder: 'Tambahkan total peserta',
      fieldName: 'total_participants',
      required: true,
      value: formData.total_participants,
      error:
        formData.total_participants === null ||
        Number(formData.total_participants) < 1,
      validation:
        formData.total_participants === null ||
        Number(formData.total_participants) < 1,
      inputProps: {min: 1},
      customHandleChange: ({fieldName, value}) => {
        const parseIntValue = parseInt(value)
        const validatedValue = (parseIntValue < 0
          ? parseIntValue * -1
          : parseIntValue
        ).toString()
        setFormData(prev => ({...prev, [fieldName]: validatedValue}))
      },
      customHelperText: 'Total Peserta tidak boleh kosong atau 0',
    },
    {
      type: 'textfield',
      label: 'Keterangan*',
      placeholder: 'Tambahkan keterangan',
      fieldName: 'description',
      multiline: true,
      required: true,
      rows: 6,
      value: formData.description,
      error: !formData.description,
    },
  ]

  return (
    <>
      <ModalDetail
        onDelete={isEditing && handleOpenModalDelete}
        onClose={handleCloseModal}
        open={open}
        maxWidth="sm"
        title={`${
          isEditing ? 'Ubah' : 'Tambahkan'
        } Pengalaman Pembicara/Narasumber/Juri`}
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Berbagi dengan jaringan:"
            value={formData.is_share}
            labelPlacement="start"
            onChange={e =>
              setFormData({
                ...formData,
                is_share: e.target.checked,
              })
            }
          />
        </div>

        <ModalAddEditWraper>
          <FormInput
            open
            fieldsList={fieldsList}
            setValues={setFormData}
            values={formData}
            errorState={isError}
          />
        </ModalAddEditWraper>

        <ButtonSubmit
          disabled={isDispatching}
          onCancel={onClose}
          onSave={saveEditMutation}
        />
      </ModalDetail>

      <DeletePopup
        open={openDelete}
        handleClose={closeModalDelete}
        name={data?.event_name}
        mutation={deleteMutation}
      />
    </>
  )
}

export default AddEditSpeaker
