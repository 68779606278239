import React, {useState} from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
} from '@material-ui/core'
import {
  WhiteModalHeader,
  ContainerApplied,
  ContainerListApplied,
  ListApplied,
  CountApplied,
} from '../../../GlobalStyles'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},

  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnPopup: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {
    wordBreak: 'break-all',
    color: '#014a62',
    textTransform: 'capitalize',
  },
  word: {wordBreak: 'break-all'},
  marginTop5: {
    marginTop: '5px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginBot10: {
    marginBottom: '10px',
  },
}))

export default function AddEdit(props) {
  const classes = useStyles()
  const {
    open,
    handleClose,
    name,
    mutation,
    type,
    feature,
    applied,
    disabled = false,
    noAutoClose = false,
    titleChanges = null,
    seeAllText = null,
    appliedName = 'job profiles',
    mutationDraft,
  } = props

  const [limit, setLimit] = useState(5)

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <WhiteModalHeader>
        <Typography variant="h6" color="error" className={classes.wordBreak}>
          {type === 'discard'
            ? 'Discard Changes?'
            : type === 'Confirm'
            ? 'Confirm Changes?'
            : !name
            ? `${type} ${feature}?`
            : `${type} ${name}?`}
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon>close</Icon>
        </IconButton>
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        {applied ? (
          <ContainerApplied className={classes.marginBot10}>
            <Typography variant="body2" display="inline">
              {titleChanges ||
                `The changes will be applied to this following ${appliedName}:`}
            </Typography>
            <ContainerListApplied>
              {applied &&
                applied.slice(0, limit).map((data, index) => {
                  return (
                    <ListApplied key={index} className={classes.marginTop5}>
                      {data.title || data.name}
                    </ListApplied>
                  )
                })}
              {applied && applied.length >= 5 && limit <= 5 ? (
                <CountApplied
                  className={classes.marginTop10}
                  onClick={() => setLimit(applied && applied.length)}
                >
                  {(applied && applied.length - 5) === 0
                    ? ''
                    : seeAllText ||
                      `See all ${applied && applied.length - 5} ${appliedName}`}
                  {/* {`See all ${applied && applied.length - 5} job profiles`} */}
                </CountApplied>
              ) : (
                <CountApplied
                  className={classes.marginTop10}
                  onClick={() => setLimit(5)}
                >
                  {applied && applied.length >= 5 ? 'Show less' : ''}
                </CountApplied>
              )}
            </ContainerListApplied>
          </ContainerApplied>
        ) : (
          ''
        )}
        <Typography variant="body2" display="inline">
          Are you sure you want to{' '}
          {type === 'discard'
            ? 'discard unsaved changes?'
            : type === 'Confirm'
            ? 'save changes?'
            : !name
            ? `${type} this ${feature}?`
            : `${type} ${feature} - ${name}?`}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose}>Cancel</Button>
        {feature === 'Micro Learning' && type === 'discard' && (
          <Button variant="outlined" onClick={mutationDraft}>
            Save as draft
          </Button>
        )}
        <Button
          className={classes.btnPopup}
          variant="contained"
          disabled={disabled}
          onClick={
            feature === 'New Calendar' || noAutoClose
              ? () => {
                  mutation()
                }
              : () => {
                  handleClose()
                  mutation()
                }
          }
        >
          {type}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
