import gql from 'graphql-tag'

export const COUNT_DASHBOARD = gql`
  query getDashboard($company: uuid) {
    male_aggregate: people_profile_aggregate(
      where: {
        gender: {_eq: "M"}
        global_user: {
          status: {_eq: "ACTIVE"}
          people_work_placements: {
            company: {_eq: $company}
            status: {_eq: "ACTIVE"}
            start_date: {_lte: "now()", _gt: null}
            replacement_status: {_eq: false}
            global_placement_termination_id: {_is_null: true}
            company_job_profile: {}
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    female_aggregate: people_profile_aggregate(
      where: {
        gender: {_eq: "F"}
        global_user: {
          status: {_eq: "ACTIVE"}
          people_work_placements: {
            company: {_eq: $company}
            status: {_eq: "ACTIVE"}
            start_date: {_lte: "now()", _gt: null}
            replacement_status: {_eq: false}
            global_placement_termination_id: {_is_null: true}
            company_job_profile: {}
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    total_employee: people_work_placements_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        start_date: {_lte: "now()", _gt: null}
        global_user: {status: {_eq: "ACTIVE"}, people_profiles: {}}
        replacement_status: {_eq: false}
        global_placement_termination_id: {_is_null: true}
        company_job_profile: {}
      }
    ) {
      aggregate {
        count
      }
    }
    total_position: company_job_profiles_aggregate(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
    total_ou: company_organizations_aggregate(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_ORGANIUZATION_AND_POSITION_DASHBOARD = gql`
  query getOrganizationPosition($company: uuid) {
    total_getOrganization: v_organizations_count_aggregate(
      where: {company: {_eq: $company}}
    ) {
      aggregate {
        sum {
          count
        }
      }
    }

    len_data_organization: v_organizations_count_aggregate(
      where: {company: {_eq: $company}}
    ) {
      aggregate {
        count
      }
    }

    getOrganization: v_organizations_count(
      where: {company: {_eq: $company}}
      order_by: {count: desc}
    ) {
      id
      name
      count
    }

    total_getPosition: v_job_profile_count_aggregate(
      where: {company: {_eq: $company}}
    ) {
      aggregate {
        sum {
          count
        }
      }
    }

    len_data_position: v_job_profile_count_aggregate(
      where: {company: {_eq: $company}}
    ) {
      aggregate {
        count
      }
    }

    getPosition: v_job_profile_count(
      where: {company: {_eq: $company}}
      order_by: {count: desc}
    ) {
      id
      title
      count
    }
  }
`
