import React, {useState} from 'react'
import {useQuery} from '@apollo/react-hooks'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'
import TablePagination from '@material-ui/core/TablePagination'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import Select, {components} from 'react-select'
import {
  GET_GOAL_LIST,
  GET_KPI_LIST,
  GET_LIST_PERSPECTIVE_FILTER,
} from '../../../../../graphql/queries'
import {makeStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {Magnify} from 'mdi-material-ui'
import {COMPANY_ID} from '../../../../../utils/globals'
import {goalType} from '../../modal/tabs/validate'
import moment from 'moment'
import LoadingSurvey from '../../../../survey/multirater-nps/survei-center/LoadingSurvey'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {PRIORITY_STYLE, TYPE_IDN, TYPE_STYLE} from '../../constant'
import {Icon} from '@material-ui/core'
import {priority} from '../../../../shared-components/data/SharedData'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    color: '#055469',
  },
  expanded: {
    marginTop: 0,
  },
  panelDetails: {
    paddingTop: 0,
    display: 'block',
    paddingLeft: '3rem',
  },
  buttonSpacer: {
    marginRight: 12,
  },
  search: {
    '& > .MuiOutlinedInput-root': {
      height: '39px !important',
    },
  },
  select: {
    width: '40%',
    margin: '10px 0px 25px 0px',
  },
  rootRadio: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 12,
  },
  wrapperKpiDetail: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  KpiVersion: {
    fontSize: 16,
    color: '#014a62',
    lineHeight: '1.5',
  },
  KpiDate: {
    color: '#a9a8a8',
    fontSize: 16,
    lineHeight: '1.5',
  },
  KpiName: {
    color: '#000',
    fontSize: 16,
    lineHeight: '1.5',
  },
  goalType: {
    color: '#039be5',
    fontSize: 16,
    lineHeight: '1.5',
  },
  idTask: {
    color: '#039be5',
    fontSize: 16,
    display: 'inline',
  },
  padding15: {
    padding: '15px',
  },
  width65P: {
    width: '65%!important',
  },
  chipsRoot: {
    fontSize: 12,
    color: '#fff',
    padding: '4px 16px',
    borderRadius: 5,
    lineHeight: '15px',
    display: 'inline',
  },
  radio: {
    color: '#A9A8A8',
  },
}))

const KpiStep = ({handleChangeRadio, idx, isGoal}) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [perspectiveDropdown, setPerspectiveDropdown] = useState(null)
  const [searchDropdown, setSearchDropdown] = useState('')
  const [rowPerPage, setRowPerPage] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(20)
  const [offset, setOffset] = useState(0)

  const query = isGoal ? GET_KPI_LIST : GET_GOAL_LIST
  const {data, error, loading} = useQuery(query, {
    variables: {
      search: `%${search}%`,
      perspective: isGoal
        ? perspectiveDropdown?.value
        : {_eq: perspectiveDropdown?.value},
      company: COMPANY_ID,
      limit: limit,
      offset: offset,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'organization-staff',
      },
    },
  })

  const {
    data: dataPerspective,
    error: errorPerspective,
    loading: loadingPerspective,
  } = useQuery(GET_LIST_PERSPECTIVE_FILTER, {
    variables: {
      company: COMPANY_ID,
      search: `%${searchDropdown}%`,
      limit: 10,
      offset: 0,
    },
  })

  if (error || errorPerspective) {
    console.error(error || errorPerspective)
  }

  const listData = isGoal ? data?.kpi_lists : data?.performance_goals
  const totalData = data?.total.aggregate.count
  const _dataPerspective = dataPerspective?.kpi_perspectives?.map(options => ({
    value: options.id,
    label: options.name,
  }))

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setCurrentPage(newPage)
    setOffset(newPage * limit)
  }

  const handleChangeRowsPerPage = event => {
    setRowPerPage(+event.target.value)
    setLimit(event.target.value)
    setOffset(0)
    setCurrentPage(0)
  }

  const DropdownIndicator = props => {
    const {isArrow, isDisabled} = props.selectProps
    return (
      <components.DropdownIndicator {...props}>
        <Icon style={{color: isArrow && !isDisabled ? '#757575' : '#a9a8a8'}}>
          {isArrow ? 'arrow_drop_down' : 'search'}
        </Icon>
      </components.DropdownIndicator>
    )
  }

  const renderList = res => {
    const goal_types =
      res?.kpi_list?.kpi_cascading_lists_aggregate?.aggregate?.count > 0
        ? 'COMPANY_GOAL'
        : 'PERSONAL_GOAL'
    if (isGoal) {
      return (
        <div className={classes.wrapperKpiDetail}>
          <Typography className={classes.KpiVersion}>
            {res?.kpi_cascading_lists?.[0]?.cascading_main?.name}
          </Typography>
          <Typography className={classes.KpiName}>{res.name}</Typography>
          <Typography className={classes.goalType}>
            {goalType[res.type] ?? '-'}
          </Typography>
          <Typography className={classes.KpiDate}>
            {`${moment(
              res?.kpi_cascading_lists[0]?.cascading_main?.date_start
            ).format('MMMM DD, YYYY')} - ${moment(
              res?.kpi_cascading_lists[0]?.cascading_main?.date_end
            ).format('MMMM DD, YYYY')}`}
          </Typography>
        </div>
      )
    } else {
      return (
        <div className={classes.wrapperKpiDetail}>
          <div>
            <Typography component="span" className={classes.goalType}>
              {`[${res.id}]`}
            </Typography>
            <Typography
              component="span"
              className={classes.KpiName}
              style={{marginLeft: 5}}
            >
              {res.name}
            </Typography>
          </div>
          <Typography className={classes.KpiDate}>
            {`${moment(res?.startdate).format('MMMM D, YYYY')} - ${moment(
              res?.enddate
            ).format('MMMM D, YYYY')}`}
          </Typography>
          <div style={{marginTop: 5}}>
            <div
              style={{backgroundColor: PRIORITY_STYLE[res?.priority]}}
              className={classes.chipsRoot}
            >
              {priority[res?.priority]}
            </div>
            <div
              style={{backgroundColor: TYPE_STYLE[goal_types], marginLeft: 5}}
              className={classes.chipsRoot}
            >
              {TYPE_IDN[goal_types]}
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <Typography style={{color: '#a9a8a8', fontSize: 15}}>
        {isGoal
          ? `Pilih Kumpulan ${GLOBAL_GLOSSARY.performance.Goal}`
          : GLOBAL_GLOSSARY.performance.Goal}
      </Typography>
      <TextField
        margin="normal"
        variant="outlined"
        className={classes.search}
        fullWidth
        onChange={event => setSearch(event.target.value)}
        placeholder="Cari"
        InputProps={{
          endAdornment: (
            <InputAdornment className="ml-0" position="start">
              <Magnify style={{color: '#014a62', width: 20, height: 20}} />
            </InputAdornment>
          ),
        }}
      />
      <Typography style={{color: '#a9a8a8', fontSize: 12, marginTop: 25}}>
        Perspektif
      </Typography>
      <Select
        className={classes.select}
        id="select-perspective"
        options={_dataPerspective}
        value={perspectiveDropdown}
        isClearable
        placeholder="Pilih Perspektif"
        isLoading={loadingPerspective}
        isArrow={true}
        components={{DropdownIndicator}}
        onInputChange={dataSearchDropdown =>
          setSearchDropdown(dataSearchDropdown)
        }
        onChange={event => {
          setPerspectiveDropdown(event)
        }}
        styles={{
          control: provided => ({
            ...provided,
            '&:hover': {borderColor: '#000'},
            marginTop: '1rem',
            minHeight: 40,
          }),
          placeholder: defaultStyles => {
            return {
              ...defaultStyles,
              color: '#bfbfbf',
            }
          },
          indicatorSeparator: () => ({
            display: 'none',
          }),
        }}
      />

      {loading ? (
        <LoadingSurvey />
      ) : listData?.length === 0 ? (
        <NoDataListComponent search={search || perspectiveDropdown?.value} />
      ) : (
        listData?.map((res, key) => {
          return (
            <Paper key={key}>
              <FormControlLabel
                classes={{
                  root: classes.rootRadio,
                }}
                className={classes.padding15}
                key={res.id}
                control={
                  <Radio
                    style={{alignSelf: 'center'}}
                    classes={{
                      root: classes.radio,
                    }}
                    key={res.id}
                    color="primary"
                    checked={idx === res.id.toString()}
                    value={res.id.toString()}
                    onChange={e => {
                      handleChangeRadio(e, res, res?.kpi_perspective?.id)
                    }}
                  />
                }
                label={renderList(res)}
                labelPlacement="end"
              />
            </Paper>
          )
        })
      )}
      <Divider />
      {listData?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          rowsPerPage={rowPerPage}
          page={currentPage}
          count={totalData}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Baris Per halaman"
          backIconButtonText="halaman sebelumnya"
          nextIconButtonText="halaman berikutnya"
          labelDisplayedRows={({from, to, count}) =>
            `${from} - ${to} dari ${count}`
          }
        />
      )}
    </>
  )
}

export default KpiStep
