import React from 'react'
import {Button, Divider} from '@material-ui/core'

import {Flex, FormTitleContainer} from '../../GlobalStyles'

export default function FormAction(props) {
  const {
    position = 'bottom',
    cancelButtonDisabled,
    disabledSubmitButton,
    openCancellationDialog,
    openConfirmartionDialog,
    cancelLabelButton = 'Cancel',
    submitLabelButton = 'Save',
  } = props

  const isBottomPosition = position === 'bottom'

  return (
    <>
      {isBottomPosition && (
        <Divider
          style={{
            marginBottom: `${isBottomPosition && '1.5rem'}`,
          }}
        />
      )}

      <Flex flexEnd>
        <FormTitleContainer>
          <Button
            disabled={cancelButtonDisabled}
            onClick={openCancellationDialog}
            variant="outlined"
            color="primary"
            size="large"
            style={{marginLeft: 16}}
          >
            {cancelLabelButton}
          </Button>

          <Button
            disabled={disabledSubmitButton}
            onClick={openConfirmartionDialog}
            variant="contained"
            color="primary"
            size="large"
            style={{marginLeft: 16}}
          >
            {submitLabelButton}
          </Button>
        </FormTitleContainer>
      </Flex>
    </>
  )
}
