import React, {useState} from 'react'
import {Button, makeStyles, IconButton, Icon} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import {
  newTableStyles,
  NewTableOptions,
} from '../../../../shared-components/table/TableOptions'
import MaterialTable from 'material-table'
import {SkyBlueIcon} from '../../../../../GlobalStyles'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {holdingRolesContext} from '../../../../../utils/helpers'
import Loading from '../../../../../components/loading/LoadingComponent'
import {GET_HOLDING_CLASS_BY_VERSION} from '../../../../../graphql/queries'
import {DELETE_HOLDING_CLASS} from '../../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'

const useStyles = makeStyles(() => ({
  root: {padding: 26},
}))

function ClassList(props) {
  const classes = useStyles()
  const tableClasses = newTableStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [openDelete, setOpenDelete] = useState(false)
  const [selected, setSelected] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [name, setName] = useState('')
  const {data, error, refetch} = useQuery(GET_HOLDING_CLASS_BY_VERSION, {
    variables: {version: localStorage.getItem('idVersion')},
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })
  const [deleteClass] = useMutation(DELETE_HOLDING_CLASS(selectedId))

  const onDeleteClass = () => {
    deleteClass({...holdingRolesContext})
      .then(() => {
        enqueueSnackbar('Data deleted', {
          variant: 'success',
          autoHideDuration: 1500,
        })
        if (selected instanceof Array) {
          setSelected([])
        }
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(
          'Delete failed, please try again later' + JSON.stringify(e),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  const handleClickOpen = (id, name) => {
    setSelectedId(id)
    setName(name)
    setOpenDelete(true)
  }
  const handleClose = () => {
    setOpenDelete(false)
  }

  const handleClickMultipleDelete = () => {
    const idToDel = selected.map(resToDel => {
      return resToDel.id
    })
    setSelectedId(idToDel)
    setName('selected item')
    setOpenDelete(true)
  }

  const getTitle = () => {
    if (selected.length === 0) {
      return 'Select All'
    } else {
      return (
        <Icon
          style={{cursor: 'pointer'}}
          onClick={handleClickMultipleDelete}
          color="secondary"
        >
          delete
        </Icon>
      )
    }
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to={{
          pathname: '/group/config/add-class',
          state: {
            active: 'add-class',
          },
        }}
      >
        Add Class
      </Button>
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          // style={TableStyle}
          options={NewTableOptions}
          columns={[
            {
              title: getTitle(),
              field: 'name',
              cellStyle: {
                wordBreak: 'break-word',
                minWidth: 150,
              },
            },
            {
              title: '',
              field: 'description',
              cellStyle: {
                minWidth: 160,
              },
            },

            {
              title: '',
              field: 'action',
              render: rowData => (
                <div style={{display: 'flex'}}>
                  <IconButton
                    onClick={() => {
                      props.history.push({
                        pathname: '/group/config/class/' + rowData.id,
                        state: {
                          active: 'edit-class',
                        },
                      })
                    }}
                  >
                    <SkyBlueIcon>edit</SkyBlueIcon>
                  </IconButton>
                  <IconButton
                    onClick={() => handleClickOpen(rowData.id, rowData.name)}
                  >
                    <Icon color="secondary">delete</Icon>
                  </IconButton>
                </div>
              ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={data.holding_classes}
          onSelectionChange={rows => setSelected(rows)}
        />
      </div>
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        name={name}
        mutation={onDeleteClass}
      />
    </div>
  )
}

export default withRouter(ClassList)
