import gql from 'graphql-tag'

export const GET_OFFICE_ADDRESS = gql`
  {
    company_addresses {
      id
      office_name
    }
  }
`
