import {dateFormat, getFullDateWithOrdinal} from '../../../utils/helpers'

const getSetupBudget = (budgetOption, budgets) => {
  return budgetOption === 1
    ? budgets.map(({city}) => ({city, budget: []}))
    : budgets
}

export const getSetupDestination = ({
  isUpdateDestination,
  budgetOption,
  budgets,
}) => {
  return isUpdateDestination ? getSetupBudget(budgetOption, budgets) : []
}

export const getErrorPolicyName = ({
  dataContext,
  isNameAlreadyExist,
  isError,
}) => {
  let isErrorName = false
  let helperText = ''

  const emptyName = !dataContext?.name
  const isLongName = dataContext?.name.length > 50
  const errroState = emptyName || isLongName || isNameAlreadyExist

  if (dataContext && errroState && isError) {
    isErrorName = true
  }

  if (isError) {
    if (emptyName) helperText = 'This field is required'
    else if (isLongName)
      helperText = 'The maximum number of characters is 50 characters'
    else if (isNameAlreadyExist) helperText = 'Name is already exist'
  }

  return {isErrorName, helperText}
}

const getApproval = ({approval, confirm_type, isShowApproval = false}) => {
  return isShowApproval &&
    approval !== '' &&
    (confirm_type === 2 || confirm_type === 1)
    ? approval
    : null
}

export const getConfirmType = (
  isHaveSecondApproval,
  isHaveThirdApproval,
  dataContext
) => {
  const {confirm_type} = dataContext || {}
  return {
    first_confirm_profile: getApproval({
      approval: dataContext?.first_confirm_profile,
      confirm_type,
      isShowApproval: confirm_type === 2,
    }),
    second_confirm_profile: getApproval({
      approval: dataContext?.second_confirm_profile,
      confirm_type,
      isShowApproval: isHaveSecondApproval,
    }),
    third_confirm_profile: getApproval({
      approval: dataContext?.third_confirm_profile,
      confirm_type,
      isShowApproval: isHaveThirdApproval,
    }),
  }
}

export const DELETE_MESSAGE = {
  Component: 'Unable to delete [name] because the component is being used',
  Policy:
    'You cannot delete [name] because the policy currently being used by users',
}

export const COLOR_STATUS_TRIP = {
  pending: '#ffa000',
  confirm1: '#ffa000',
  confirm2: '#ffa000',
  rejected: '#F83352',
  cancelled: '#a9a8a8',
  approved: '#4caf50',
  processing: '#a35a31',
}

export const STATUS_TRIP = {
  pending: 'Waiting',
  confirm1: 'Waiting Second Approval',
  confirm2: 'Waiting Third Approval',
  processing: 'Processing',
  rejected: 'Rejected',
  cancelled: 'Cancelled',
  approved: 'Approved',
}

export const getTripDate = (start, end) => {
  return start === end
    ? getFullDateWithOrdinal(start)
    : `${getFullDateWithOrdinal(start)} - ${getFullDateWithOrdinal(end)}`
}

export const getUserApproval = aproval_origin => {
  const job_profile_supervisor_1 = aproval_origin
  const job_profile_supervisor_2 = job_profile_supervisor_1?.spv_2
  const job_profile_supervisor_3 = job_profile_supervisor_2?.spv_3

  const is_spv_1 = job_profile_supervisor_1?.people_work_placements?.[0]
  const is_spv_2 = job_profile_supervisor_2?.people_work_placements?.[0]
  const is_spv_3 = job_profile_supervisor_3?.people_work_placements?.[0]

  const name = (is_spv_1 || is_spv_2 || is_spv_3)?.global_user?.name
  const title = is_spv_1
    ? job_profile_supervisor_1?.title
    : is_spv_2
    ? job_profile_supervisor_2?.title
    : is_spv_3
    ? job_profile_supervisor_3?.title
    : ''

  return {name, title}
}

export const getUpdater = approval => {
  const {name, people_work_placements} = approval || {}
  return `${name} ${people_work_placements?.[0]?.company_job_profile?.title}`
}

export const getTripReportDate = (start, end) => {
  if (!start || !end) return '-'

  const formatStart = start === end ? 'MMMM Do, YYYY' : 'MMMM Do'

  return `${dateFormat(start, formatStart)} - ${dateFormat(
    end,
    'MMMM Do, YYYY'
  )}`
}

export const getStatusApproval = data => {
  const {status, business_trip_policy} = data || {}
  const {
    companyJobProfileBySecondConfirmProfile,
    companyJobProfileByThirdConfirmProfile,
  } = business_trip_policy || {}

  let statusResult = 'processing'

  switch (status) {
    case 'pending':
      if (companyJobProfileBySecondConfirmProfile) {
        statusResult = 'confirm1'
      }
      break
    case 'confirm1':
      if (companyJobProfileByThirdConfirmProfile) {
        statusResult = 'confirm2'
      }
      break
  }

  return statusResult
}

export const namePending = dataDetail => {
  const {status, business_trip_policy, participant_position} = dataDetail || {}
  const spv =
    participant_position?.company_job_profile?.job_profile_supervisor || {}
  const {
    confirm_type,
    companyJobProfileByFirstConfirmProfile,
    companyJobProfileBySecondConfirmProfile,
    companyJobProfileByThirdConfirmProfile,
  } = business_trip_policy || {}

  const approval = {
    pending: confirm_type === 1 ? spv : companyJobProfileByFirstConfirmProfile,
    confirm1: companyJobProfileBySecondConfirmProfile,
    confirm2: companyJobProfileByThirdConfirmProfile,
  }

  const {title, name} = getUserApproval(approval?.[status])

  return status === 'pending' && confirm_type === 1
    ? `${name}, ${title}`
    : title
}

const constructBudget = business_trip_policy_destination_budgets => {
  return business_trip_policy_destination_budgets?.map(budget => {
    return {
      nominal: budget.nominal,
      type: {
        label: budget.business_trip_component?.name,
        value: budget.business_trip_component?.id,
        description: budget.business_trip_component?.description,
      },
      budget_calculation: budget.budget_calculation,
    }
  })
}

const getGeneralBudget = budgetDataEdit => {
  return [
    {
      city: null,
      name: null,
      budget: constructBudget(
        budgetDataEdit?.business_trip_policy_destinations?.[0]
          ?.business_trip_policy_destination_budgets
      ),
    },
  ]
}

const getSpecificBudget = budgetDataEdit => {
  return budgetDataEdit?.business_trip_policy_destinations?.map(destination => {
    return {
      city: destination?.global_destination?.id,
      name: destination?.global_destination?.name,
      budget: constructBudget(
        destination?.business_trip_policy_destination_budgets
      ),
    }
  })
}

export const getInitialBudget = (budgetDataEdit, budget_component_type) => {
  const type = budget_component_type === 1 ? 'general' : 'specific'
  const budget =
    budget_component_type === 1
      ? getGeneralBudget(budgetDataEdit)
      : getSpecificBudget(budgetDataEdit)

  return {
    type,
    budget,
  }
}

export const PAYMENT_TYPE_OPTIONS = [
  {
    value: 1,
    label: 'Transfer',
  },
  {
    value: 2,
    label: 'Ticket / Voucher',
  },
]

export const getBudgetCalculationLabel = budget => {
  switch (budget.budget_calculation) {
    case 'flat':
      return 'Flat'
    case 'multiply_by_days':
    default:
      return 'Multiply by day(s)'
  }
}

export const calculateBudget = (trip, budget) => {
  switch (budget.budget_calculation) {
    case 'flat':
      return budget.nominal
    case 'multiply_by_days':
    default:
      return trip.duration * budget.nominal
  }
}

export const handleTripDetailSort = data => {
  // NOTE(intrnl): even if we've requested backend to return a sorted list,
  // it's not stable which means that if there are two values with the same
  // sort values then they can get mixed up

  // in the case that backend returns what we expect out of it, this sort
  // operation should be rather inexpensive to do
  if (data && data.people_work_business_trips_by_pk) {
    const trip = data.people_work_business_trips_by_pk

    // NOTE(intrnl): it's okay to mutate here, we're expecting sorted data
    trip.destination.sort((a, b) => {
      // NOTE(intrnl): sort by start date, and then sort by its ID
      const aStartDate = a.destination_start_date
      const bStartDate = b.destination_start_date

      if (aStartDate === bStartDate) {
        return a.id - b.id
      }

      return aStartDate.localeCompare(bStartDate)
    })
  }
}
