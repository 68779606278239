import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {CircularProgress, ExpansionPanelDetails} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, {useState} from 'react'
import {SwitchValue} from '../../../../../components/color-switch/ColorSwitcher'
import {SkyBlueIcon} from '../../../../../GlobalStyles'
import {
  GET_DETAIL_TASK,
  GET_LIST_TASK_IN_GOAL,
} from '../../../../../graphql/queries'
import {
  FlexBetween,
  FlexCenter,
  GreyTypography,
  StyledBorderedExpansion,
  StyledExpansionSummaryAssign,
  StyledLinear,
  StyledSmallButton,
  TextBlue,
  TextBold,
} from '../modalStyles'
import AddTask from './AddTask'
import Detail from './Detail'
import InfiniteScroll from 'react-infinite-scroll-component'
import NoDataListFree from '../../../../shared-components/NoDataListFreeComponent'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {
  progress_update_task,
  status_update_task,
} from '../../../../shared-components/data/SharedData'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3, 2),
  },
  assign: {
    flexGrow: 1,
    padding: theme.spacing(3, 2),
    backgroundColor: '#eff2f4',
  },
  add_task: {
    width: '100%',
    height: '64px',
    // borderRadius: '5px',
    // backgroundColor: '#055469',
    // color: '#fff',
  },
  hight: {
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#ef4d5e',
    color: '#fff !important',
  },
  medium: {
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#ffa000',
    color: '#fff !important',
  },
  low: {
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#4caf50',
    color: '#fff !important',
  },
  assign_image: {
    width: '50px',
    height: '50px',
    borderRadius: '150px',
  },
  title: {
    fontFamily: 'Muli',
    fontSize: '15px',
    fontWeight: 'normal',
    color: '#a9a8a8',
  },
  progressDone: {
    '& .MuiLinearProgress-bar': {background: '#4caf50'},
  },
}))

export default function Task(props) {
  const classes = useStyles()
  const [addTask, setAddTask] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const [detail, setDetail] = useState(null)
  const [dataEdit, setDataEdit] = useState(null)
  const client = useApolloClient()

  const [state, setState] = useState({
    items: 4,
    hasMore: true,
  })

  const {data, error, refetch} = useQuery(GET_LIST_TASK_IN_GOAL, {
    wlb_skipPatch: true,
    variables: {goal: props.idGoals, limit: state.items},
    pollInterval: 500,
  })

  const getDetailTask = async (id, isEdit) => {
    const {data: detailData, loading: loads} = await client.query({
      query: GET_DETAIL_TASK,
      variables: {id},
      fetchPolicy: 'network-only',
    })
    if (detailData) {
      if (isEdit) {
        setDataEdit(detailData)
        setAddTask(true)
      } else {
        setDetail(detailData)
      }
    }
    return loads
  }

  if (error !== undefined) {
    return <div>{JSON.stringify(error)}</div>
  }
  const dataDetail = data && data.performance_tasks
  const handleAddTask = () => {
    setDataEdit(null)
    setAddTask(true)
  }
  const handleCancelAdd = () => {
    // LoadList()
    setAddTask(false)
  }

  // eslint-disable-next-line no-unused-vars
  const handleExpand = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)

    if (isExpanded) {
      getDetailTask(panel)
    }
  }

  const fetchMore = () => {
    if (state.items > data.total.aggregate.count) {
      setState({
        ...state,
        hasMore: false,
      })
      return
    }
    setTimeout(() => {
      setState({
        ...state,
        items: state.items + 4,
      })
    }, 500)
  }

  const isActiveCascading =
    props?.dataKpi?.kpi_cascading_lists?.[0]?.cascading_main?.status?.toLowerCase() ===
    'active'

  const goal_types =
    props?.dataKpi?.kpi_cascading_lists_aggregate?.aggregate?.count > 0

  const renderStatus = status => {
    return status_update_task[status] || status
  }

  return (
    <>
      {addTask ? (
        <AddTask
          idGoals={props.idGoals}
          dataKpi={props.dataKpi}
          dataDateGoal={props.dataDateGoal}
          onBack={handleCancelAdd}
          refetch={refetch}
          dataEdit={dataEdit ? dataEdit.performance_tasks[0] : null}
          type="add-task"
        />
      ) : (
        <div className={classes.root}>
          <Grid container spacing={3}>
            {(isActiveCascading || !goal_types) && (
              <Grid item xs={12}>
                <Button
                  className={classes.add_task}
                  variant="contained"
                  color="primary"
                  onClick={handleAddTask}
                  style={{textTransform: 'capitalize'}}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{
                      flexDirection: 'column',
                      cursor: 'pointer',
                      fontSize: '20px',
                      marginRight: '10px',
                    }}
                  />
                  Tambah {GLOBAL_GLOSSARY.performance.Task}
                </Button>
              </Grid>
            )}
            <div style={{width: '100%'}}>
              {data && data.performance_tasks.length > 0 ? (
                <InfiniteScroll
                  dataLength={state.items}
                  next={fetchMore}
                  hasMore={state.hasMore}
                  loader={
                    <div style={{textAlign: 'center'}}>
                      <CircularProgress
                        size={15}
                        style={{marginRight: '10px'}}
                      />
                      <span>Loading</span>
                    </div>
                  }
                  height={'34rem'}
                >
                  {dataDetail
                    ? dataDetail.map((value, index) => {
                        const resWeight = value?.kpi_weight_unit?.name
                        return (
                          <StyledBorderedExpansion
                            elevation={0}
                            style={{marginTop: 0, margin: 12}}
                            expanded={expanded === value.id}
                            onChange={handleExpand(value.id)}
                            key={index}
                          >
                            <StyledExpansionSummaryAssign>
                              <FlexBetween>
                                <div>
                                  <StyledSmallButton
                                    style={{
                                      height: 'auto',
                                    }}
                                    background={SwitchValue(
                                      value.priority,
                                      'priority'
                                    )}
                                  >
                                    {value.priority.toLowerCase()}
                                  </StyledSmallButton>
                                  <FlexCenter>
                                    <Typography variant="body1">
                                      {value.name}
                                    </Typography>
                                    {(value.status === 'ARCHIVED' ||
                                      value.status === 'COMPLETED') && (
                                      <SkyBlueIcon
                                        style={{
                                          marginLeft: 8,
                                          fontSize: '1rem',
                                        }}
                                      >
                                        check_circle_outline
                                      </SkyBlueIcon>
                                    )}
                                  </FlexCenter>
                                  <Typography
                                    style={{fontWeight: 600, fontSize: '12px'}}
                                  >
                                    {renderStatus(value.status)}
                                  </Typography>
                                  <GreyTypography
                                    variant="body2"
                                    component="label"
                                  >
                                    #{value.id}
                                  </GreyTypography>
                                </div>
                                {(isActiveCascading || !goal_types) && (
                                  <TextBlue
                                    style={{
                                      cursor: 'pointer',
                                      marginLeft: '30px',
                                    }}
                                    onClick={e => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      getDetailTask(value.id, true)
                                      setExpanded(false)
                                    }}
                                  >
                                    Ubah
                                  </TextBlue>
                                )}
                              </FlexBetween>
                              <FlexCenter
                                style={{justifyContent: 'space-between'}}
                              >
                                <StyledLinear
                                  variant="determinate"
                                  color="secondary"
                                  className={
                                    value.progress_percentage >= 100 &&
                                    classes.progressDone
                                  }
                                  value={
                                    value.progress_percentage > 100
                                      ? 100
                                      : value.progress_percentage
                                  }
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <TextBold
                                    style={{
                                      marginRight: 10,
                                      width: 'max-content',
                                    }}
                                  >
                                    {value.progress
                                      ? value.progress.toFixed(2)
                                      : 0}{' '}
                                    {resWeight} (
                                    {value.progress_percentage
                                      ? value.progress_percentage.toFixed(2)
                                      : 0}
                                    %)
                                  </TextBold>
                                  <Typography
                                    style={{
                                      color:
                                        value.progress_percentage < 100
                                          ? '#014a62'
                                          : '#4caf50',
                                    }}
                                  >
                                    {value.progress_percentage < 100
                                      ? progress_update_task.Below
                                      : value.progress_percentage === 100
                                      ? progress_update_task.Meet
                                      : value.progress_percentage > 100
                                      ? progress_update_task.Exceed
                                      : '-'}
                                  </Typography>
                                </div>
                              </FlexCenter>
                            </StyledExpansionSummaryAssign>
                            <ExpansionPanelDetails
                              style={{paddingLeft: 32, paddingRight: 32}}
                            >
                              {expanded === value.id && detail && (
                                <Detail data={detail} type="taskDetail" />
                              )}
                            </ExpansionPanelDetails>
                          </StyledBorderedExpansion>
                        )
                      })
                    : ''}
                </InfiniteScroll>
              ) : (
                <NoDataListFree
                  message1={`${GLOBAL_GLOSSARY.performance.Task} Tidak Ditemukan `}
                />
              )}
            </div>
          </Grid>
        </div>
      )}
    </>
  )
}
