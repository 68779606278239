import React from 'react'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  Flex,
  CustomTypography,
} from '../../../../GlobalStyles'
import {Divider, IconButton, CircularProgress} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import {withRouter} from 'react-router-dom'
import {GET_DETAIL_COMP_CATEGORY} from '../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {TextContentTitle, TextContent} from '../CompetencyPageStyles'

import ArrowBack from '@material-ui/icons/ArrowBack'

const CategoryDetailPage = props => {
  const id = props?.match?.params?.id

  const {data, loading} = useQuery(GET_DETAIL_COMP_CATEGORY, {variables: {id}})

  return (
    <PaperContainer style={{minHeight: 500}}>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => props.history.goBack()}
            style={{color: '#000', marginLeft: 10}}
          >
            <ArrowBack />
          </IconButton>
          {loading ? (
            <Skeleton
              variant="text"
              width={210}
              height={40}
              style={{marginLeft: 10}}
            />
          ) : (
            <CustomTypography fweight="bold" left="10px">
              {data?.competency_categories[0]?.name}
            </CustomTypography>
          )}
        </FormTitleContainer>
      </FormToolbar>
      <Divider />
      <div
        style={{
          padding: '1.6rem',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Flex column style={{gap: '15px'}}>
            <Flex style={{gap: '10px'}}>
              <TextContentTitle>Category Name</TextContentTitle>
              <div>:</div>
              <Flex style={{gap: '10px'}}>
                <img
                  src={data?.competency_categories[0]?.icon}
                  alt=""
                  width={20}
                />
                {data?.competency_categories[0]?.name}
              </Flex>
            </Flex>
            <Flex style={{gap: '10px'}}>
              <TextContentTitle>Category Code</TextContentTitle>
              <div>:</div>
              <TextContent>{data?.competency_categories[0]?.code}</TextContent>
            </Flex>
            <Flex style={{gap: '10px'}}>
              <TextContentTitle>Parent</TextContentTitle>
              <div>:</div>
              <TextContent>
                {data?.competency_categories[0]?.category_parent?.name}
              </TextContent>
            </Flex>
            <Flex style={{gap: '10px'}}>
              <TextContentTitle>Description</TextContentTitle>
              <div>:</div>
              <TextContent>
                {data?.competency_categories[0]?.description}
              </TextContent>
            </Flex>
          </Flex>
        )}
      </div>
    </PaperContainer>
  )
}

export default withRouter(CategoryDetailPage)
