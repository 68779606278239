import React from 'react'
import {useApolloClient} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'
import FormInput from '../../ShareComponents-detail/FormInput'
import {ModalAddEditWraper} from '../../../DetailEmployeeStyles'
import {ButtonSubmit} from '../../../SharedDetailEmployee'
import {isErrorForm} from '../../ShareComponents-detail/helperDetail'

import {
  BLOOD_RHESUS_OPTIONS,
  BLOOD_TYPE_OPTIONS,
  RADIO_BOOL_OPTIONS,
  RADIO_NO,
  RADIO_YES,
} from '../data-tab-shared'

import usePrevious from '../../../../../../../hooks/usePrevious'
import {
  ADD_MEDICAL_RECORD,
  UPDATE_MEDICAL_RECORD,
} from '../../../../../../../graphql/mutations'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_FORM_DATA = {
  name: '',
  reason: '',
  _conditions: RADIO_NO,
  conditions: '',
  _disabilities: RADIO_NO,
  disabilities: '',
  hospitalized: '',
  blood_type: '',
  blood_rhesus: '',
  body_height: '',
  _body_height_unit: 'cm',
  body_weight: '',
  _body_weight_unit: 'kg',
  head_size: '',
  _head_size_unit: 'cm',
  clothes_size: '',
  pants_size: '',
}

const STYLE_OFFSET = {marginTop: -16}
const STYLE_ADORN = {width: '40%', verticalAlign: 'middle'}

const AddEditMedicalRecord = props => {
  const {open = false, userId, initialData, onClose, onSubmit, onDelete} = props

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [formState, setFormState] = React.useState(STATE_INITIAL)
  const [formData, setFormData] = React.useState(INITIAL_FORM_DATA)

  const isEditing = !!initialData

  const isError = formState === STATE_ERROR
  const isDispatching = formState === STATE_DISPATCH

  const prevOpen = usePrevious(open)
  const isNeverOpen = prevOpen === false && prevOpen === open

  React.useEffect(() => {
    if (!open) {
      return
    }

    setFormState(STATE_INITIAL)

    if (initialData) {
      const conditions = initialData.additional_medical_conditions
      const disabilities = initialData.disabilities

      // NOTE(intrnl): enforce into string, make sure that falsy values like
      // undefined and null are converted into empty strings
      const bodyHeight = ('' + (initialData.body_height || '')).split(' ')
      const bodyWeight = ('' + (initialData.body_weight || '')).split(' ')
      const headSize = ('' + (initialData.head_size || '')).split(' ')

      setFormData({
        name: initialData.medical_test_name,
        reason: initialData.medical_test_reason,
        _conditions: conditions ? RADIO_YES : RADIO_NO,
        conditions: conditions || '',
        _disabilities: disabilities ? RADIO_YES : RADIO_NO,
        disabilities: disabilities || '',
        hospitalized: initialData.hospitalized_reason || '',
        blood_type: initialData.blood_type,
        blood_rhesus: initialData.blood_rhesus,
        body_height: bodyHeight[0],
        _body_height_unit: bodyHeight[1] || INITIAL_FORM_DATA._body_height_unit,
        body_weight: bodyWeight[0],
        _body_weight_unit: bodyWeight[1] || INITIAL_FORM_DATA._body_weight_unit,
        head_size: headSize[0] || '',
        _head_size_unit: headSize[1] || INITIAL_FORM_DATA._head_size_unit,
        clothes_size: initialData.clothes_size || '',
        pants_size: initialData.pants_size || '',
      })
    } else {
      setFormData(INITIAL_FORM_DATA)
    }
  }, [open, initialData])

  if (isNeverOpen) {
    return null
  }

  const handleSubmit = () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const data = {
      user_id: userId,

      medical_test_name: formData.name,
      medical_test_reason: formData.reason,
      additional_medical_conditions:
        formData._conditions === RADIO_YES ? formData.conditions : '',
      disabilities:
        formData._disabilities === RADIO_YES ? formData.disabilities : '',
      hospitalized_reason: formData.hospitalized,
      blood_type: formData.blood_type,
      blood_rhesus: formData.blood_rhesus,
      body_height: `${formData.body_height} ${formData._body_height_unit}`,
      body_weight: `${formData.body_weight} ${formData._body_weight_unit}`,
      head_size:
        formData.head_size !== ''
          ? `${formData.head_size} ${formData._head_size_unit}`
          : null,
      clothes_size: formData.clothes_size,
      pants_size: formData.pants_size,
    }

    const verb = isEditing ? 'edit' : 'add'
    let promise

    if (isEditing) {
      promise = client.mutate({
        mutation: UPDATE_MEDICAL_RECORD,
        variables: {
          id: initialData.id,
          data: data,
        },
      })
    } else {
      promise = client.mutate({
        mutation: ADD_MEDICAL_RECORD,
        variables: {
          data: data,
        },
      })
    }

    promise.then(
      () => {
        if (!isEditing) {
          setFormData(INITIAL_FORM_DATA)
        }

        setFormState(STATE_INITIAL)
        enqueueSnackbar(`Medical profile ${verb}ed`, {variant: 'success'})

        if (onSubmit) {
          onSubmit(!initialData)
        }
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Failed to ${verb} medical profile`, {variant: 'error'})

        setFormState(STATE_INITIAL)
      }
    )
  }

  const fieldsList = [
    {
      type: 'textfield',
      fieldName: 'name',
      required: true,
      label: 'Nama Tes Medis*',
      placeholder: 'Tambahkan nama tes medis',
      value: formData.name,
      error: !formData.name,
    },
    {
      type: 'textfield',
      fieldName: 'reason',
      required: true,
      multiline: true,
      label: 'Alasan Tes Medis*',
      placeholder: 'Tambahkan alasan tes medis',
      value: formData.reason,
      error: !formData.reason,
    },
    {
      type: 'radio',
      fieldName: '_conditions',
      row: true,
      label: 'Apakan anda mempunyai kondisi medis yang perlu diperhatikan?*',
      value: formData._conditions,
      option: RADIO_BOOL_OPTIONS,
    },
    formData._conditions === RADIO_YES && {
      type: 'textfield',
      fieldName: 'conditions',
      required: true,
      multiline: true,
      rows: 3,
      placeholder: 'Tambahkan kondisi medis yang perlu diperhatikan',
      value: formData.conditions,
      error: !formData.conditions,
      style: STYLE_OFFSET,
    },
    {
      type: 'radio',
      fieldName: '_disabilities',
      row: true,
      label: 'Punya Disabilitas?*',
      value: formData._disabilities,
      option: RADIO_BOOL_OPTIONS,
    },
    formData._disabilities === RADIO_YES && {
      type: 'textfield',
      fieldName: 'disabilities',
      required: true,
      multiline: true,
      rows: 3,
      placeholder: 'Tambahkan info disabilitas',
      value: formData.disabilities,
      error: !formData.disabilities,
      style: STYLE_OFFSET,
    },
    {
      type: 'textfield',
      fieldName: 'hospitalized',
      multiline: true,
      label: 'Penyebab masuk rumah sakit',
      placeholder: 'Tambahkan penyebab masuk rumah sakit',
      value: formData.hospitalized,
    },
    {
      type: 'select',
      fieldName: 'blood_type',
      required: true,
      label: 'Golongan Darah*',
      placeholder: 'Tipe golongan darah',
      value: formData.blood_type,
      error: !formData.blood_type,
      option: BLOOD_TYPE_OPTIONS,
    },
    {
      type: 'select',
      fieldName: 'blood_rhesus',
      required: true,
      label: 'Resus*',
      placeholder: 'Pilih resus',
      value: formData.blood_rhesus,
      error: !formData.blood_rhesus,
      option: BLOOD_RHESUS_OPTIONS,
    },
    {
      type: 'textfield',
      required: true,
      inputType: 'number',
      inputProps: {min: 0},
      fieldName: 'body_height',
      label: 'Tinggi Badan*',
      placeholder: 'Tambahkan tinggi badan',
      value: formData.body_height,
      error: !formData.body_height,
      style: STYLE_ADORN,
      endAdornment: (
        <span style={{marginLeft: 16}}>{formData._body_height_unit}</span>
      ),
    },
    {
      type: 'textfield',
      required: true,
      inputType: 'number',
      inputProps: {min: 0},
      fieldName: 'body_weight',
      label: 'Berat Badan*',
      placeholder: 'Tambahkan berat badan',
      value: formData.body_weight,
      error: !formData.body_weight,
      style: STYLE_ADORN,
      endAdornment: (
        <span style={{marginLeft: 16}}>{formData._body_weight_unit}</span>
      ),
    },
    {
      type: 'textfield',
      inputType: 'number',
      inputProps: {min: 0},
      fieldName: 'head_size',
      label: 'Ukuran Kepala',
      placeholder: 'Tambahkan ukuran kepala',
      value: formData.head_size,
      error: !formData.head_size,
      style: STYLE_ADORN,
      endAdornment: (
        <span style={{marginLeft: 16}}>{formData._head_size_unit}</span>
      ),
    },
    {
      type: 'textfield',
      fieldName: 'clothes_size',
      label: 'Ukuran Baju',
      placeholder: 'Tambahkan ukuran baju',
      value: formData.clothes_size,
      error: !formData.clothes_size,
      style: STYLE_ADORN,
    },
    {
      type: 'textfield',
      fieldName: 'pants_size',
      label: 'Ukuran Celana',
      placeholder: 'Tambahkan ukuran celana',
      value: formData.pants_size,
      error: !formData.pants_size,
      style: STYLE_ADORN,
    },
  ]

  return (
    <ModalDetail
      open={open}
      maxWidth="sm"
      title={`${isEditing ? 'Ubah' : 'Tambahkan'} Profil Medis`}
      onClose={isDispatching ? null : onClose}
      onDelete={isEditing ? onDelete : null}
    >
      <ModalAddEditWraper>
        <FormInput
          fieldsList={fieldsList}
          setValues={setFormData}
          values={formData}
          errorState={isError}
          HELPER_TEXT="Bagian ini diperlukan"
        />
      </ModalAddEditWraper>

      <ButtonSubmit
        disabled={isDispatching}
        onCancel={onClose}
        onSave={handleSubmit}
      />
    </ModalDetail>
  )
}

export default AddEditMedicalRecord
