import gql from 'graphql-tag'

export const GET_LEAVE_ACTIVITY = gql`
  query getLeaveActivity(
    $where: people_work_leaves_bool_exp
    $offset: Int
    $limit: Int
  ) {
    people_work_leaves(where: $where, limit: $limit, offset: $offset) {
      id
      id_display
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              global_user {
                id
                name
                avatar
              }
            }
            spv_2: job_profile_supervisor {
              id
              title
              people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                global_user {
                  id
                  name
                }
              }
              spv_3: job_profile_supervisor {
                id
                title
                people_work_placements(
                  where: {status: {_eq: "ACTIVE"}}
                  limit: 1
                ) {
                  id
                  global_user {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      datestart
      dateend
      date_added
      last_modified
      contacts
      description
      is_half
      globalUserByModifiedBy {
        id
        name
      }
      status
      quota_taken
      time_leave_policy_child {
        id
        name
        confirm_type
        first_job_profile {
          id
          title
        }
        second_job_profile {
          id
          title
        }
      }
      leave_fields
    }

    total: people_work_leaves_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
