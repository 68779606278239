import gql from 'graphql-tag'

export const GET_LIST_JOB_GRADE = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    total: job_grades_aggregate(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    job_grades(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
            ]
          }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
      description
      company_profile {
        id
        legal_name
        brand_name
      }
      company_job_profiles(limit: 5) {
        id
        title
      }
      company_job_profiles_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`
export const GET_JOB_GRADE_QUERY = gql`
  query($companyId: uuid!, $search: String) {
    job_grades(
      where: {
        _and: {
          company: {_eq: $companyId}
          _or: [
            {code: {_ilike: $search}}
            {name: {_ilike: $search}}
            {description: {_ilike: $search}}
          ]
        }
      }
    ) {
      id
      code
      name
      description
      company_profile {
        id
        legal_name
        brand_name
      }
    }
  }
`

export const CHECK_CODE_JOB_GRADE = gql`
  query($company: uuid, $code: String) {
    job_grades_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LIST_JOB_GRADE_SHEET = gql`
  query($company: uuid!) {
    job_grades(
      where: {_and: [{company: {_eq: $company}}, {deletedAt: {_is_null: true}}]}
      order_by: {name: asc}
    ) {
      id
      code
      name
    }
  }
`
