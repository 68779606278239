import {Button, Divider, CircularProgress, IconButton} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  Flex,
  FormToolbar,
  FormTitleContainer,
  CustomTypography,
} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Link, withRouter} from 'react-router-dom'
import {TableOptions} from '../../../shared-components/table/TableOptions'
import {useQuery, useMutation, useApolloClient} from '@apollo/react-hooks'
import {GET_COMPETENCY_VERSION_DICTIONARIES} from '../../../../graphql/queries'
import {
  DELETE_COMPETENCY_IN_VERSION,
  CHECK_DELETE_COMPETENCY,
} from '../../../../graphql/mutations'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {useSnackbar} from 'notistack'
import ModalUnableDelete from '../ModalUnableDelete'
import {competencyOptions} from '../CompetencyPageStyles'

const DictionaryDetail = props => {
  const id = props.match.params.id
  const client = useApolloClient()
  const {search} = props
  const {enqueueSnackbar} = useSnackbar()

  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [valueDelete, setValueDelete] = useState({
    id: 0,
    name: '',
  })
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const [deleteCompetency] = useMutation(DELETE_COMPETENCY_IN_VERSION)

  const {data, loading, error, refetch} = useQuery(
    GET_COMPETENCY_VERSION_DICTIONARIES,
    {
      variables: {
        version: id,
        search: `%${search}%`,
      },
    }
  )

  useEffect(() => {
    refetch()
  }, [])

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  } else if (loading) {
    return <CircularProgress />
  }

  const goBack = () => {
    return props.history.goBack()
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleDelete = row => {
    setOpenDelete(true)
    setValueDelete({
      id: row.id,
      name: row.name.en,
    })
  }

  const mutationDelete = async () => {
    await client
      .query({
        query: CHECK_DELETE_COMPETENCY,
        variables: {version: id, competency: valueDelete.id},
      })
      .then(res => {
        if (res.data.competency_model_items_aggregate.aggregate.count > 0) {
          setOpen(true)
        } else {
          deleteCompetency({
            variables: {version: id, competency: valueDelete.id},
          })
            .then(() => {
              refetch()
              enqueueSnackbar(`${valueDelete.name} removed`, {
                variant: 'success',
                autoHideDuration: 3000,
              })
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e), {
                variant: 'error',
                autoHideDuration: 3000,
              })
            })
            .finally(() => {
              setValueDelete({
                id: 0,
                name: '',
              })
            })
        }
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => goBack()}
            style={{color: '#000', marginLeft: 10}}
          >
            <ArrowBack />
          </IconButton>
          <CustomTypography fweight="bold" left="10px">
            Dictionary Details
          </CustomTypography>
        </FormTitleContainer>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={{
            pathname: `/learning/competency/dictionary/${id}/add`,
            state: {
              active: 'competence-dictionary',
            },
          }}
        >
          Add Competency
        </Button>
      </FormToolbar>
      <Divider />

      <MaterialTable
        style={{
          padding: '1.6rem 1.6rem 0',
        }}
        localization={{
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
        columns={[
          {
            title: 'Competency Code',
            field: 'dictionary_id',
          },
          {
            title: 'Competency Name',
            field: 'name.en',
            render: row => (
              <Flex
                style={{
                  width: 250,
                }}
              >
                {row.name.en}
              </Flex>
            ),
          },
          {
            title: 'Category',
            field: 'competency_category.name',
          },
          {
            title: 'Description',
            field: 'description',
            render: row => (
              <div
                style={{
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 5,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {row.description?.text}
              </div>
            ),
          },
          {
            title: 'Action',
            field: 'id',
            sorting: false,
            render: row => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={{
                          pathname: `/learning/competency/dictionary/detail/${row.id}`,
                          state: {
                            active: 'competence-dictionary',
                            data: row,
                          },
                        }}
                        fontColor="#000"
                      >
                        Detail
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                          handleDelete(row)
                        }}
                        fontColor="#ff0000"
                      >
                        Delete
                      </StyledMenuItem>
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            ),
          },
        ]}
        data={(data && data.competency_dictionaries) || []}
        title=""
        options={{
          ...TableOptions,
          ...competencyOptions,
        }}
        components={{
          Toolbar: () => null,
        }}
        isLoading={loading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={data.competency_dictionaries_aggregate.aggregate.count}
        page={pagination.offset}
        pageSize={pagination.limit}
      />
      <DeletePopup
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        mutation={mutationDelete}
        id={valueDelete.id}
        name={valueDelete.name}
      />
      <ModalUnableDelete
        open={open}
        onClose={() => setOpen(false)}
        title="Competency"
        name={valueDelete.name}
        because="currently being used on Model"
      />
    </PaperContainer>
  )
}

export default withRouter(DictionaryDetail)
