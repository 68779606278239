import {useMutation} from '@apollo/react-hooks'
import React, {useEffect, useState} from 'react'
import {
  ADD_DISCIPLINE,
  UPDATE_DISCIPLINE,
  DELETE_DISCIPLINE,
} from '../../../../../../graphql/mutations'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../DetailEmployeeStyles'
import {SwitchForm, ButtonSubmit} from '../../SharedDetailEmployee'
import FormInput from '../ShareComponents-detail/FormInput'
import {isErrorForm} from '../ShareComponents-detail/helperDetail.jsx'

const INITIAL_VALUES_STATE = {
  user: null,
  is_share: false,
  reference_number: null,
  discipline_letter_number: null,
  reference_date: undefined,
  discipline_name: null,
  start_date: null,
  end_date: undefined,
  remark: null,
  attachments: [],
}

const HELPER_TEXT = 'This field is required'

export default function AddEdit({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
  user_name_long,
}) {
  const [values, setValues] = useState(INITIAL_VALUES_STATE)
  const [errorState, setErrorState] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [addDiscipline] = useMutation(ADD_DISCIPLINE)
  const [updateDiscipline] = useMutation(UPDATE_DISCIPLINE)
  const [deleteDiscipline] = useMutation(DELETE_DISCIPLINE)

  useEffect(() => {
    if (actionType === 'edit' && open) {
      setValues(e => ({
        ...e,
        user: userIdDetail,
        is_share: data.is_share,
        reference_number: data.reference_number,
        discipline_letter_number: data.discipline_letter_number,
        reference_date: data.reference_date,
        discipline_name: data.discipline_name,
        start_date: data.start_date,
        end_date: data.end_date,
        remark: data.remark,
        attachments: data.attachments,
      }))
    } else {
      setValues(e => ({
        ...e,
        user: userIdDetail,
      }))
    }
  }, [open, actionType])

  const fieldsList = [
    {
      label: 'Reference Number*',
      placeholder: 'Add Reference Number',
      fieldName: 'reference_number',
      type: 'textfield',
      required: true,
      value: values.reference_number,
      error: !values.reference_number,
    },
    {
      label: 'Disciplinary Letter Number*',
      placeholder: 'Add Disciplinary Letter Number',
      fieldName: 'discipline_letter_number',
      type: 'textfield',
      required: true,
      value: values.discipline_letter_number,
      error: !values.discipline_letter_number,
    },
    {
      label: ['Reference Date*'],
      filter: ['date'],
      fieldName: 'reference_date',
      type: 'dateCustom',
      required: true,
      value: values.reference_date,
      error: !values.reference_date,
    },
    {
      label: 'Disciplines Name*',
      placeholder: 'Add Disciplines Name',
      fieldName: 'discipline_name',
      type: 'textfield',
      required: true,
      value: values.discipline_name,
      error: !values.discipline_name,
    },
    {
      label: 'Employee*',
      placeholder: 'Add Employee',
      fieldName: 'employee',
      type: 'textfield',
      required: true,
      value: user_name_long,
    },
    {
      type: 'date',
      required: true,
      label: ['Start Date*', 'End Date*'],
      // labelDate: 'The certification does not expire',
      fieldName: ['start_date', 'end_date'],
      value: [values.start_date, values.end_date, false, false],
      error: [!values.start_date, !values.end_date],
    },
    {
      label: 'Remark',
      placeholder: 'Add Remark',
      fieldName: 'remark',
      type: 'textfield',
      multiline: true,
      rows: 6,
      value: values.remark,
      error: true,
    },
    {
      label: 'Attachment',
      fieldName: 'attachment',
      type: 'attachment',
      attachments: values.attachments,
      error: true,
      multiple: true,
      link: 'url',
    },
  ]
  const handleChange = ({fieldName, value}, target, ekstra) => {
    if (target === 'date') {
      setValues(e => ({
        ...e,
        [ekstra]: value,
      }))
    } else {
      setValues(e => ({
        ...e,
        [fieldName]: value,
      }))
    }
  }

  const handleClose = () => {
    onClose()
    setValues(INITIAL_VALUES_STATE)
  }

  const handleSave = async () => {
    const isError = await isErrorForm(fieldsList)
    if (isError) {
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000)
    } else {
      // const _initalSalary = await changeToNumber(values.initial_salary)
      // const _ilastSalary = await changeToNumber(values.last_salary)
      const object = values
      delete object.employee
      if (actionType === 'add') {
        addDiscipline({
          variables: {object},
        })
          .then(() => {
            refetch()
            enqueueSnackbar('Add Disciplinary success', {
              variant: 'success',
              autoHideDuration: 2000,
            })
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar('Add Disciplinary failed, please try again later', {
              variant: 'error',
              autoHideDuration: 2000,
            })
          })
      } else {
        updateDiscipline({
          variables: {
            id: data.id,
            object: object,
          },
        })
          .then(() => {
            refetch()
            enqueueSnackbar('Edit Disciplinary success', {
              variant: 'success',
              autoHideDuration: 2000,
            })
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar(
              'Edit Disciplinary failed, please try again later',
              {
                variant: 'error',
                autoHideDuration: 2000,
              }
            )
          })
      }
    }
  }

  const handleDelete = () => {
    deleteDiscipline({
      variables: {
        id: data.id,
        userId: userIdDetail,
      },
    })
      .then(() => {
        refetch()
        setOpenDelete(false)
        enqueueSnackbar('Delete Disciplinary success', {
          variant: 'success',
          autoHideDuration: 2000,
        })
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar('Delete Disciplinary failed, please try again later', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleOpenDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  return (
    <>
      <ModalDetail
        onDelete={actionType === 'edit' && handleOpenDelete}
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        title={
          (actionType === 'add' ? 'Add' : actionType === 'edit' ? 'Edit' : '') +
          ' Disciplinary'
        }
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Share with network:"
            value={values.is_share}
            labelPlacement="start"
            onChange={e =>
              handleChange({
                fieldName: 'is_share',
                value: e.target.checked,
              })
            }
          />
        </div>
        <ModalAddEditWraper>
          <FormInput
            fieldsList={fieldsList}
            open={open}
            setValues={setValues}
            values={values}
            errorState={errorState}
            HELPER_TEXT={HELPER_TEXT}
          />
        </ModalAddEditWraper>
        <ButtonSubmit onCancel={handleClose} onSave={handleSave} />
      </ModalDetail>
      <DeletePopup
        open={openDelete}
        handleClose={handleCloseDelete}
        name={'Disciplinary'}
        mutation={handleDelete}
      />
    </>
  )
}
