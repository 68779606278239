import {
  Avatar,
  Divider,
  Icon,
  IconButton,
  Typography,
  Tooltip,
  Button,
  //   Button,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState} from 'react'
import {BoldTypography} from '../../../components/typography/TypographyStyles'
import {PaperContainer, EnhancedToolbar} from '../../../GlobalStyles'
import {
  NewTableOptions,
  newTableStyles,
} from '../../shared-components/table/TableOptions'
import {GET_SOURCE_HOLDING_TALENT} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'
import {useQuery, useMutation} from '@apollo/react-hooks'
import Loading from '../../../components/loading/LoadingComponent'

// import {Link} from 'react-router-dom'
import verified from '../../../assets/images/verified.svg'
import unverified from '../../../assets/images/unverified.svg'
import {capitalize, holdingRolesContext} from '../../../utils/helpers'
import {useSnackbar} from 'notistack'
import {
  DEL_FROM_SOURCE_TALENT_HOLDING,
  UPDATE_HOLDING_TALENT_SOURCE,
} from '../../../graphql/mutations'
import DeletePopup from '../../shared-components/popup/DeletePopup'
import AddEdit from '../../shared-components/popup/AddEditPopup'

export default function SourceComponent(props) {
  const tableClasses = newTableStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [name, setName] = useState('')
  const [id, setId] = useState('')
  const [status, setStatus] = useState('')
  const [openDel, setOpenDel] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const {data, error, refetch} = useQuery(GET_SOURCE_HOLDING_TALENT, {
    variables: {search: `%${props.searchText}%`, company: COMPANY_ID},
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const [mutationDelFromSource] = useMutation(DEL_FROM_SOURCE_TALENT_HOLDING)
  const [mutationUpdateSource] = useMutation(UPDATE_HOLDING_TALENT_SOURCE)

  const handleOpen = (ids, type, names, statuses) => {
    if (type === 'verify') {
      setOpenAdd(true)
      setStatus(statuses)
    } else {
      setOpenDel(true)
    }
    setId(ids)
    setName(names)
    setConfirmType(type)
  }

  const handleClose = () => {
    if (confirmType === 'verify') {
      setOpenAdd(false)
    } else {
      setOpenDel(false)
    }
  }

  const handleMutation = () => {
    if (confirmType === 'verify') {
      mutationUpdateSource({
        variables: {
          id,
          changes: {
            status: status === 'VERIFIED' ? 'UNVERIFIED' : 'VERIFIED',
          },
        },
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          refetch()
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    } else {
      mutationDelFromSource({
        variables: {id},
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          refetch()
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    }
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Talent Source</Typography>
      </EnhancedToolbar>
      <Divider />
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontSize: 12}}
          options={{...NewTableOptions, paging: true, sorting: true}}
          columns={[
            {
              title: 'Talent',
              field: 'people_work_placement.global_user.name',
              cellStyle: {
                wordBreak: 'break-word',
              },
              render: row => (
                <div style={{display: 'flex'}}>
                  <Avatar
                    variant="rounded"
                    src={row.people_work_placement.global_user.avatar}
                    style={{marginRight: 16}}
                  ></Avatar>
                  {/* <Link
                    to={{
                      pathname: `/member/list/${row.company_member_profile.id}`,
                      state: {active: 'detail-company'},
                    }}
                    style={{color: '#000', textDecoration: 'none'}}
                  > */}
                  <div style={{minWidth: 150}}>
                    <BoldTypography variant="body2" gutterBottom>
                      {row.people_work_placement.global_user.name}
                    </BoldTypography>
                    <Typography variant="caption">
                      {row.people_work_placement.company_profile.legal_name}
                    </Typography>
                  </div>

                  {/* </Link> */}
                </div>
              ),
            },

            {
              title: 'Employee ID',
              field: 'people_work_placement.regno',
              cellStyle: {
                textTransform: 'capitalize',
              },
            },
            {
              title: 'Position',
              field: 'people_work_placement.company_job_profile.title',
            },
            {
              title: 'Email',
              field: 'people_work_placement.email',
              cellStyle: {color: '#039be5', fontWeight: 'normal'},
            },
            {
              title: 'Status',
              field: 'status',
              render: row => (
                <Button
                  onClick={() =>
                    handleOpen(
                      row.id,
                      'verify',
                      row.people_work_placement.global_user.name,
                      row.status
                    )
                  }
                >
                  {row.status === 'VERIFIED' ? (
                    <img src={verified} alt={row.id}></img>
                  ) : (
                    <img src={unverified} alt={row.id}></img>
                  )}

                  <Typography variant="body2" style={{marginLeft: 8}}>
                    {capitalize(row.status)}
                  </Typography>
                </Button>
              ),
            },
            {
              title: '',
              field: 'action',
              filtering: false,
              render: row => (
                <Tooltip title="Remove from Talent Source">
                  <IconButton
                    onClick={() =>
                      handleOpen(
                        row.id,
                        'delete',
                        row.people_work_placement.global_user.name
                      )
                    }
                  >
                    <Icon color="secondary">person_remove</Icon>
                  </IconButton>
                </Tooltip>
              ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={data.holding_source}
        />
      </div>
      <DeletePopup
        open={openDel}
        handleClose={handleClose}
        id={id}
        name={`${name} from talent source`}
        mutation={handleMutation}
      />
      <AddEdit
        open={openAdd}
        handleClose={handleClose}
        type={
          confirmType === 'verify' && status === 'VERIFIED'
            ? 'unverify'
            : 'verify'
        }
        name={`${name}`}
        feature=""
        mutation={handleMutation}
      />
    </PaperContainer>
  )
}
