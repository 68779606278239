import React, {useState} from 'react'
import {EnhancedToolbar} from '../../../../GlobalStyles'
import {
  Typography,
  makeStyles,
  Paper,
  TextField,
  Icon,
  MenuItem,
  Divider,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import SeeAll from './SeeAllComponent'

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    marginRight: '1rem',
    maxWidth: '158px',
    minWidth: '150px',
  },
  paper: {
    width: '100%',
  },
  seeAll: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: 22,
    color: '#039be5',
    marginTop: 16,
  },
}))

const TableComAsp = ({
  dataAspiration,
  dataCompetency,
  // organization,
  // office,
  position,
  setCondAspiration,
  setCondCompetency,
  condAspiration,
  condCompetency,
}) => {
  const classes = useStyles()
  const [openSeeAll, setOpenSeeAll] = useState(false)
  const [type, setType] = useState('')
  const changeFiltersAsp = name => e => {
    setCondAspiration({...condAspiration, [name]: e.target.value})
  }
  const changeFiltersComp = name => e => {
    setCondCompetency({...condCompetency, [name]: e.target.value})
  }

  function findOdd(para) {
    const dataSet = []
    // const value = []
    para &&
      para.forEach(para => {
        const dataTest = {
          position: para.title,
          aspiration:
            para.people_work_placements_aggregate.aggregate.count !== 0
              ? para.people_work_placements.length !== 0
                ? para.people_work_placements[0].talent_aspirations[0]
                    .company_job_profile.title
                : '-'
              : '-',
          total: para.people_work_placements_aggregate.aggregate.count,
        }
        dataSet.push(dataTest)
      })
    // console.log({dataSet})
    // dataSet.forEach(element => {
    //   let copy = dataSet.slice(0)
    //   console.log({copy})
    //   let myCount = 0
    //   copy = copy.filter(
    //     word =>
    //       element.position === word.position &&
    //       element.aspiration === word.aspiration
    //   )
    //   myCount = copy.length
    //   if (myCount > 0) {
    //     const dataTest = {
    //       position: element.position,
    //       aspiration: element.aspiration,
    //       total: myCount,
    //     }

    //     value.push(dataTest)
    //     dataSet = dataSet.filter(
    //       word =>
    //         element.position !== word.position &&
    //         element.aspiration !== word.aspiration
    //     )
    //   }
    // })

    return dataSet
  }
  function findComp(para) {
    let dataSet = []
    const value = []
    para &&
      para.forEach(options => {
        options.global_user.competency_individuals.forEach(res => {
          const dataTest = {
            name:
              res.competency_version_dictionary.competency_dictionary &&
              res.competency_version_dictionary.competency_dictionary.name.id,
          }
          dataSet.push(dataTest)
        })
      })
    dataSet.forEach(element => {
      let copy = dataSet.slice(0)
      let myCount = 0
      copy = copy.filter(word => element.name === word.name)
      myCount = copy.length
      if (myCount > 0) {
        const dataTest = {
          name: element.name,
          total: myCount,
        }
        value.push(dataTest)
        dataSet = dataSet.filter(word => element.name !== word.name)
      }
    })
    return value
  }

  const dataAsp = findOdd(dataAspiration)
  const dataComp = findComp(dataCompetency)
  const handleOpen = types => {
    setOpenSeeAll(true)
    setType(types)
  }

  const datatable = (types, isSeeAll) => (
    <MaterialTable
      style={{fontSize: 14}}
      components={{
        Container: props => <Paper {...props} elevation={0} />,
      }}
      columns={
        types === 'Aspiration'
          ? [
              {
                title: 'Position',
                field: 'position',
              },
              {
                field: 'arrow',
                sorting: false,
                cellStyle: {
                  width: 75,
                  maxWidth: 75,
                },
                headerStyle: {
                  width: 75,
                  maxWidth: 75,
                },
                align: 'center',
                render: () => (
                  <Icon style={{color: '#039be5', fontSize: 56}}>
                    arrow_right_alt
                  </Icon>
                ),
              },
              {
                title: 'Aspiration',
                field: 'aspiration',
              },
              {title: 'Total', field: 'total', type: 'numeric'},
            ]
          : [
              {title: 'Position', field: 'name'},
              {
                title: 'Total',
                field: 'total',
                type: 'numeric',
              },
            ]
      }
      data={
        types === 'Aspiration'
          ? dataAsp.slice(0, isSeeAll ? dataAsp.length : 5)
          : dataComp.slice(0, isSeeAll ? dataComp.length : 7)
      }
      options={{
        toolbar: false,
        paging: isSeeAll,
      }}
    />
  )

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} style={{marginRight: '1rem'}}>
        <EnhancedToolbar>
          <Typography
            variant="h6"
            color="primary"
            style={{marginRight: '1rem'}}
          >
            Aspiration
          </Typography>
          <div
            style={{display: 'flex', marginTop: '1rem', marginBottom: '1rem'}}
          >
            {/* <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Organization"
              value={condAspiration.organization}
              onChange={changeFiltersAsp('organization')}
            >
              <MenuItem value="all">All Organization</MenuItem>
              {organization &&
                organization.company_organizations.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Office Location"
              value={condAspiration.office}
              onChange={changeFiltersAsp('office')}
            >
              <MenuItem value="all">All Office</MenuItem>
              {office &&
                office.company_addresses.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.office_name}
                  </MenuItem>
                ))}
            </TextField> */}
            <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Position"
              value={condAspiration.jobProfile}
              onChange={changeFiltersAsp('jobProfile')}
              style={{marginRight: 0}}
            >
              <MenuItem value="all">All Position</MenuItem>
              {position &&
                position.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </EnhancedToolbar>
        <Divider />
        {datatable('Aspiration')}
        {dataAsp.length > 5 && (
          <div
            className={classes.seeAll}
            onClick={() => handleOpen('Aspiration')}
          >
            See All
          </div>
        )}
      </Paper>
      <Paper className={classes.paper}>
        <EnhancedToolbar>
          <Typography
            variant="h6"
            color="primary"
            style={{marginRight: '1rem'}}
          >
            Competencies
          </Typography>
          <div
            style={{display: 'flex', marginTop: '1rem', marginBottom: '1rem'}}
          >
            {/* <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Organization"
              value={condCompetency.organization}
              onChange={changeFiltersComp('organization')}
              style={{marginRight: 0}}
            >
              <MenuItem value="all">All Organization</MenuItem>
              {organization &&
                organization.company_organizations.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField> */}
          </div>
        </EnhancedToolbar>
        <Divider />
        {datatable('Competencies')}
        {dataComp.length > 7 && (
          <div
            className={classes.seeAll}
            onClick={() => handleOpen('Competencies')}
          >
            See All
          </div>
        )}
      </Paper>
      <SeeAll
        handleClose={() => setOpenSeeAll(false)}
        open={openSeeAll}
        type={type}
        table={datatable(type, true)}
      />
    </div>
  )
}
export default TableComAsp
