import React, {useState} from 'react'
import helperStyle, {
  TextBlackBold,
  BtnAddSquare,
  SelectedMentor,
  UserWrapper,
  FlexStart,
  TextContentBold,
  TextContentRed,
} from '../../CrossMentoringPageStyles'
import {Avatar, IconButton} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import AssigneeContainer from '../AssigneeContainer'
import ModalInviteCompany from '../ModalAddMentor'
import {nullFormat} from 'numeral'

const iconPlusStyle = {
  fontSize: 30,
  color: '#039be5',
}

const MemberStepComponent = props => {
  const classes = helperStyle()
  const {
    isError,
    assignee,
    setAssignee,
    selectedMentor,
    setSelectedMentor,
    dataMentor,
    dataMentee,
  } = props
  const [hasChange, setHasChange] = useState(false)
  const [employees, setEmployees] = useState([])
  const [assignment, setAssignment] = useState({assignments: []})
  const [openInvite, setOpenInvite] = useState(false)

  const onChangeForAssignment = (target, input) => {
    setAssignment({
      ...assignment,
      [target]: input,
    })
  }

  const handleOpenInvite = () => {
    setOpenInvite(prev => !prev)
  }

  const handleClearMentor = () => {
    setSelectedMentor(nullFormat)
  }

  return (
    <div>
      <FlexStart className={classes.mb20}>
        <TextBlackBold className={classes.mr25}>Mentor</TextBlackBold>
        {isError && !selectedMentor ? (
          <TextContentRed>Please select mentor</TextContentRed>
        ) : (
          ''
        )}
      </FlexStart>
      {selectedMentor ? (
        <SelectedMentor>
          <FlexStart>
            <Avatar
              src={selectedMentor.global_user.avatar}
              className={classes.mr25}
            />
            <UserWrapper>
              <TextBlackBold>{selectedMentor.global_user.name}</TextBlackBold>
              <TextContentBold>
                {selectedMentor.company_job_profile
                  ? selectedMentor.company_job_profile.title
                  : ''}
              </TextContentBold>
            </UserWrapper>
          </FlexStart>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={handleClearMentor}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </SelectedMentor>
      ) : (
        <BtnAddSquare style={{width: 'auto'}} onClick={handleOpenInvite}>
          <AddIcon style={iconPlusStyle} />
        </BtnAddSquare>
      )}
      <FlexStart className={classes.mb20}>
        <TextBlackBold className={classes.mr25}>Mentee List</TextBlackBold>
        {isError && assignee.length === 0 ? (
          <TextContentRed>Please select mentee</TextContentRed>
        ) : (
          ''
        )}
      </FlexStart>
      <AssigneeContainer
        handleComponent={onChangeForAssignment}
        employees={employees}
        setEmployees={setEmployees}
        assignee={assignee}
        setAssignee={setAssignee}
        setHasChange={setHasChange}
        dataMentee={dataMentee}
        groupLearning
        mentee
      />
      <ModalInviteCompany
        open={openInvite}
        handleClose={handleOpenInvite}
        selectedMentor={selectedMentor}
        setSelectedMentor={setSelectedMentor}
        dataMentor={dataMentor}
        // refetchMain={refetch}
      />
    </div>
  )
}

export default MemberStepComponent
