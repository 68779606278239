import gql from 'graphql-tag'

export const GET_JOB_PROFILE_DROPDOWN = gql`
  query($company: uuid, $search: String, $limit: Int) {
    company_job_profiles(
      order_by: {title: asc}
      where: {company: {_eq: $company}, title: {_ilike: $search}}
      limit: $limit
    ) {
      id
      title
      code
      company_organization {
        id
        name
      }
      company_employee_position {
        id
        name
      }
      company_address {
        id
        office_name
      }
    }
  }
`

export const GET_FILTERED_POSITION = gql`
  query(
    $company: uuid
    $search: String
    $filterOrganization: Int_comparison_exp
    $filterJobLevel: Int_comparison_exp
    $filterJobGrade: Int_comparison_exp
    $filterJobTitle: Int_comparison_exp
    $filterPosition: Int_comparison_exp
    $filterOffice: Int_comparison_exp
  ) {
    total: company_job_profiles_aggregate(
      order_by: {code: asc}
      where: {
        company: {_eq: $company}
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
        organization: $filterOrganization
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        position: $filterJobTitle
        id: $filterPosition
        office_address: $filterOffice
      }
    ) {
      aggregate {
        count
      }
    }
    company_job_profiles(
      order_by: {code: asc}
      where: {
        company: {_eq: $company}
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
        organization: $filterOrganization
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        position: $filterJobTitle
        id: $filterPosition
        office_address: $filterOffice
      }
    ) {
      id
      code
      title
      company_organization {
        id
        name
      }
      company_employee_position {
        id
        name
      }
      company_address {
        id
        office_name
      }
    }
  }
`

export const GET_JOB_PROFILE_BY_ID = gql`
  query getJobProfile($id: [Int!]) {
    company_job_profiles(where: {id: {_in: $id}}) {
      id
      code
      title
    }
  }
`

export const GET_JOB_PROFILE_DIRECTORY = gql`
  query($company: uuid, $search: String, $limit: Int) {
    company_job_profiles(
      where: {company: {_eq: $company}, title: {_ilike: $search}}
      limit: $limit
    ) {
      id
      title
      organization
      position
      rank
      office_address
      roles
      job_profile_supervisor {
        id
        people_work_placements(order_by: [{id: asc}], limit: 1, offset: 0) {
          id
          user
        }
      }
    }
  }
`
