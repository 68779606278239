import {useMutation, useQuery} from '@apollo/react-hooks'
import {Divider, makeStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {ArrowBack} from '@material-ui/icons'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {FormContainer, TabsStyled, TabStyled} from '../../../../GlobalStyles'
import {UPDATE_LEAVE_POLICY_WITH_FILTER_ASSIGN} from '../../../../graphql/mutations'
import {
  GET_LEAVE_SETTINGS_DETAIL_QUERY,
  LIST_JOB_PROFILE_DROPDOWN_FORM,
} from '../../../../graphql/queries/index'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {manipulateFilter} from '../../attendance/work-schedule/filterDataAssignment'
import {
  AssignPositionTabChild,
  EditDetailPolicyTabChild,
} from './add-edit-tab-policy-child'

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontStyle: 'bold',
  },
  title: {
    flexGrow: 1,
    marginLeft: '15px',
  },
  paper: {
    width: '100%',
    // marginBottom: '10rem',
    marginBottom: 228,
    marginLeft: theme.spacing(1),
    // padding: '10px',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  outlinedInput: {
    padding: '10px 14px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'baseline',
    width: '18rem',
  },
  listRoot: {
    paddingTop: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: '1rem',
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  buttonSpacer: {marginRight: 12},
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  titlePage: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    display: 'flex',
  },
}))

function EditSetting(props) {
  const {root, parent, selected} = props
  const secondConfirmations = [
    {
      name: 'Leave can be taken',
      value: '1',
    },
    {
      name: 'Second confirmation',
      value: '2',
    },
  ]
  const classes = useStyles()

  const {enqueueSnackbar} = useSnackbar()

  const [selectedParent, setSelectedParent] = useState('')
  const [balance, setBalance] = useState(undefined)
  const [infiniteQuota, setInfiniteQuota] = useState(undefined)
  const [selectedConfirmationType, setSelectedConfirmationType] = useState('')
  const [selectedPosition, setSelectedPosition] = useState(null)
  const [selectedPositionSecond, setSelectedPositionSecond] = useState(null)
  const [secondConfirmationState, setSecondConfirmationState] = useState(null)
  const [name, setName] = React.useState('')
  const [gender, setGender] = React.useState('')
  const [isError, setIsError] = React.useState(false)
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [tabsValue, setTabsValue] = useState(0)
  const [isUpdatePosition, setIsUpdatePosition] = useState(false)
  const [countApplied, setCountApplied] = useState(null)
  const [filter, setFilter] = useState({})
  const [isFilterPolicy, setIsFilterPolicy] = useState(true)
  const [searchJP, setSearchJP] = useState('')
  const handleClickOpen = type => {
    const setError = val => {
      setTabsValue(val)
      const ids = document.getElementById('top')
      ids.scrollIntoView({behavior: 'smooth'})
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    }
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      if (
        (parseInt(balance) === 0 && !infiniteQuota) ||
        (parseInt(selectedConfirmationType) === 2 && !selectedPosition) ||
        (parseInt(secondConfirmationState) === 2 && !selectedPositionSecond) ||
        nameChecker
      ) {
        setError(0)
      } else if (!countApplied || countApplied === 0) {
        setError(1)
      } else {
        setConfirmType(type)
        setPopup(true)
      }
    }
  }
  const handleClose = () => {
    setPopup(false)
  }

  const {data, error} = useQuery(GET_LEAVE_SETTINGS_DETAIL_QUERY, {
    variables: {id: selected.id},
    fetchPolicy: 'cache-and-network',
    onCompleted: ({time_leave_policy_children}) => {
      const {id, name} = time_leave_policy_children?.[0]?.time_leave_policy || {}
      setSelectedParent({value: id, label: name})
    }
  })

  const {data: _dataJobProfile, loading: loadingJP} = useQuery(
    LIST_JOB_PROFILE_DROPDOWN_FORM,
    {
      variables: {
        search: `%${searchJP}%`,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const dataJP = _dataJobProfile?.company_job_profiles.map(r => {
    return {
      value: r.id,
      label: r.title,
    }
  })

  const [updatePolicy] = useMutation(UPDATE_LEAVE_POLICY_WITH_FILTER_ASSIGN)

  useEffect(() => {
    if (data && data.time_leave_policy_children[0]) {
      const detail = data.time_leave_policy_children[0]
      setInfiniteQuota(detail.infinite_quota)
      setName(detail.name)
      if (detail.second_job_profile) {
        setSecondConfirmationState(secondConfirmations[1].value)
        setSelectedPositionSecond({
          value: detail.second_job_profile && detail.second_job_profile.id,
          label: detail.second_job_profile && detail.second_job_profile.title,
        })
      } else {
        setSecondConfirmationState(secondConfirmations[0].value)
      }
      if (detail.first_job_profile) {
        setSelectedPosition({
          value: detail.first_job_profile && detail.first_job_profile.id,
          label: detail.first_job_profile && detail.first_job_profile.title,
        })
      }
      setSelectedConfirmationType(detail.confirm_type)
      if (detail.filter_position) {
        const dataFilter = detail.filter_position
        const _newFilter = {}
        const isFilterExist = (data, stateFilter) => {
          let inOrNin = {_in: dataFilter[data]}
          if (data.includes('exclude')) {
            inOrNin = {_nin: dataFilter[data]}
          }
          if (inOrNin._in || inOrNin._nin) {
            _newFilter[stateFilter] = {..._newFilter[stateFilter], ...inOrNin}
          }
        }

        isFilterExist('filter_exclude_org_unit', 'filterOrgUnit')
        isFilterExist('filter_include_org_unit', 'filterOrgUnit')
        isFilterExist('filter_include_org_level', 'filterOrgLevel')
        isFilterExist('filter_exclude_org_level', 'filterOrgLevel')
        isFilterExist('filter_exclude_position', 'filterPosition')
        isFilterExist('filter_include_position', 'filterPosition')
        isFilterExist('filter_exclude_job_title', 'filterJobTitle')
        isFilterExist('filter_include_job_title', 'filterJobTitle')
        isFilterExist('filter_exclude_job_level', 'filterJobLevel')
        isFilterExist('filter_include_job_level', 'filterJobLevel')
        isFilterExist('filter_exclude_office', 'filterOffice')
        isFilterExist('filter_include_office', 'filterOffice')
        isFilterExist('filter_exclude_job_grade', 'filterJobGrade')
        isFilterExist('filter_include_job_grade', 'filterJobGrade')
        setFilter(_newFilter)
      }
    }
  }, [data])

  if (data === undefined && error === undefined) {
    return <div>Loading ...</div>
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  if (data.time_leave_policy_children.length === 0)
    return <div>Data not found</div>

  const detail = data.time_leave_policy_children[0]

  const nameChecker =
    parent &&
    parent.time_leave_policy_children &&
    parent.time_leave_policy_children
      .filter(x => x.name !== detail.name)
      .some(v => v.name.toLowerCase() === name.toLowerCase())

  const history = () => {
    props.history.push({
      pathname: '/time/leave/settings',
      state: {searchable: true, active: 'settings'},
    })
  }

  const updatePolicySettings = () => {
    const variables = {
      id: detail.id,
      parent: selectedParent.value,
      name: name || detail.name,
      infinite_quota: infiniteQuota,
      quota: balance || detail.quota,
      confirm_type: selectedConfirmationType || detail.confirm_type,
      first_confirm_profile:
        selectedConfirmationType === '2'
          ? selectedPosition && selectedPosition.value
          : null,
      second_confirm_profile:
        (selectedPositionSecond && selectedPositionSecond.value) || null,
      gender: gender || 'U',
      isUpdatePosition,
      filter: manipulateFilter(filter),
    }
    updatePolicy({variables})
      .then(() => {
        enqueueSnackbar('Success Update data', {variant: 'success'})
        history()
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
        })
      })
  }

  const handleSearch = e => {
    setSearchJP(e)
  }

  const handleChangeTab = (e, newValue) => {
    e.preventDefault()
    setTabsValue(newValue)
  }

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar} disableGutters>
        <div className={classes.titlePage}>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handleClickOpen('discard')}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Edit [{parent && parent.name}] - {selected && selected.name}
          </Typography>
        </div>
        <div className={classes.action}>
          <Button
            size="large"
            className={classes.buttonSpacer}
            onClick={() => handleClickOpen('discard')}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickOpen('edit')}
            size="large"
          >
            Save
          </Button>
        </div>
      </Toolbar>
      <Divider />
      <TabsStyled value={tabsValue} onChange={handleChangeTab}>
        <TabStyled label="Detail Policy" />
        <TabStyled label="Assign Position" />
      </TabsStyled>
      <FormContainer id="top">
        {tabsValue === 0 && (
          <EditDetailPolicyTabChild
            name={name}
            setName={setName}
            selectedParent={selectedParent}
            setSelectedParent={setSelectedParent}
            balance={balance}
            setBalance={setBalance}
            infiniteQuota={infiniteQuota}
            setInfiniteQuota={setInfiniteQuota}
            selectedConfirmationType={selectedConfirmationType}
            setSelectedConfirmationType={setSelectedConfirmationType}
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
            handleSearch={handleSearch}
            secondConfirmationState={secondConfirmationState}
            setSecondConfirmationState={setSecondConfirmationState}
            selectedPositionSecond={selectedPositionSecond}
            setSelectedPositionSecond={setSelectedPositionSecond}
            gender={gender}
            setGender={setGender}
            isError={isError}
            detail={detail}
            root={root}
            nameChecker={nameChecker}
            dataJP={dataJP}
            loading={loadingJP}
          />
        )}
        {tabsValue === 1 && (
          <AssignPositionTabChild
            count={countApplied}
            setCount={setCountApplied}
            isError={isError}
            policyId={selected.id}
            variablesQuery={filter}
            setVars={setFilter}
            isEdit={true}
            setIsUpdatePosition={setIsUpdatePosition}
            isFilterPolicy={isFilterPolicy}
            setIsFilterPolicy={setIsFilterPolicy}
          />
        )}
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={detail.name}
        feature="Leave Policy"
        mutation={confirmType === 'discard' ? history : updatePolicySettings}
      />
    </Paper>
  )
}

export default withRouter(EditSetting)
