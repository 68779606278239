import React, {Fragment, useState} from 'react'
import MaterialTable from 'material-table'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  // FilterContainers,
  // FilterTextfield,
} from '../../../../GlobalStyles'
import {Typography, Divider, Button} from '@material-ui/core'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {GET_CASECADING} from '../../../../graphql/queries'
import Loading from '../../../../components/loading/LoadingComponent'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Link} from 'react-router-dom'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {
  DELETE_CASECADING_KPI,
  INSERT_CASCADING_KPI,
  DELETE_MULTIPLE_CASCADING_KPI,
} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {status_data} from '../../../shared-components/data/SharedData'

// import DetailKpiListComponent from './modalKpiList/DetailKpiListComponent'

const CasecadingComponent = ({searchText}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [openDelete, setOpenDelete] = useState(false)
  const [mutationAddCascadingKpi] = useMutation(INSERT_CASCADING_KPI)
  const [state, setState] = useState({
    id: '',
    name: '',
    position: '',
    data: '',
  })

  const [openMultipleDelete, setOpenMultipleDelete] = useState(false)
  const [selected, setSelected] = useState([])

  const handleClickOpen = (id, name) => {
    setState({id, name})
    setOpenDelete(true)
  }
  const handleClose = () => {
    setOpenDelete(false)
  }

  const [deleteCascadingKpi] = useMutation(DELETE_CASECADING_KPI(state.id))
  const [deleteMultipleCascadingKpi] = useMutation(
    DELETE_MULTIPLE_CASCADING_KPI
  )

  const onDeleteCascadingKpi = () => {
    deleteCascadingKpi()
      .then(() => {
        enqueueSnackbar('Cascading Goal deleted', {
          variant: 'success',
          autoHideDuration: 500,
        })
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete Cascading Goal failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 500,
          }
        )
      })
  }

  const {data, error} = useQuery(GET_CASECADING, {
    wlb_skipPatch: true,
    variables: {
      search: `%${searchText}%`,
    },
  })

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const handleDuplicate = () => {
    const dataSelected = selected

    const result = dataSelected.map(res => ({
      name: res.name,
      description: res.description,
      date_start: res.date_start,
      date_end: res.date_end,
      version: res.version,
      status: res.status,
    }))

    const data = {
      data: result,
    }

    mutationAddCascadingKpi({variables: data})
      .then(() => {
        setSelected([])
        enqueueSnackbar('Success Add data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Add item failed, please try again later', {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const handleMultipleDelete = () => {
    const dataSelected = selected
    const selectedId = []

    dataSelected.map(res => {
      selectedId.push(res.id)
    })

    deleteMultipleCascadingKpi({
      variables: {
        id: selectedId,
      },
    })
      .then(() => {
        setSelected([])
        enqueueSnackbar('Success Delete data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Delete Failed, please try again later', {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const getTitle = () => {
    if (selected.length === 0) {
      return `Nama Turunan ${GLOBAL_GLOSSARY.performance.Goal}`
    } else {
      return (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <Fragment>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>
              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem onClick={handleDuplicate}>
                  Duplikat
                </StyledMenuItem>
                <StyledMenuItem onClick={() => setOpenMultipleDelete(true)}>
                  Hapus
                </StyledMenuItem>
              </StyledMenus>
            </Fragment>
          )}
        </PopupState>
      )
    }
  }

  return (
    <PaperContainer style={{overflow: 'unset'}}>
      <EnhancedToolbar>
        <Typography variant="h6">
          Turunan {GLOBAL_GLOSSARY.performance.Goal}
        </Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: `/performance/kpi/cascading/add`,
              state: {active: 'add-cascading'},
            }}
          >
            Tambahkan Turunan {GLOBAL_GLOSSARY.performance.Goal}
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider></Divider>
      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0'}}
        columns={[
          {title: getTitle(), field: 'name'},
          {
            title: 'Periode - Mulai',
            field: 'date_start',
          },
          {
            title: 'Periode - Selesai',
            field: 'date_end',
          },
          {
            title: 'Keterangan',
            field: 'description',
          },
          {
            title: 'Status',
            field: 'status',
            lookup: {ACTIVE: 'ACTIVE', INACTIVE: 'INACTIVE'},
            render: rowData => (
              <Typography
                style={
                  rowData.status === 'ACTIVE'
                    ? {color: 'green'}
                    : {color: 'red'}
                }
              >
                {status_data[rowData.status] || rowData.status}
              </Typography>
            ),
          },
          {
            title: 'Aksi',
            field: 'id',
            sorting: false,
            render: rowData => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <Fragment>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        component={Link}
                        to={{
                          pathname: '/performance/kpi/cascading/detail',
                          state: {
                            active: 'cascading-detail',
                            dataCascading: rowData,
                          },
                        }}
                        variant="h6"
                      >
                        Rincian
                      </StyledMenuItem>
                      <StyledMenuItem
                        component={Link}
                        to={{
                          pathname: '/performance/kpi/cascading/edit',
                          state: {
                            active: 'edit-cascading',
                            dataCascading: rowData,
                          },
                        }}
                        variant="h6"
                      >
                        Ubah
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          handleClickOpen(rowData.id, rowData.name)
                          popupState.close()
                        }}
                      >
                        Hapus
                      </StyledMenuItem>
                    </StyledMenus>
                  </Fragment>
                )}
              </PopupState>
            ),
          },
        ]}
        data={data.kpi_cascadings}
        title=""
        options={TableOptions}
        components={{
          Toolbar: () => null,
        }}
        localization={{pagination: TableLocalization.pagination}}
        onSelectionChange={rows => setSelected(rows)}
      />
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        id={state.id}
        name={state.name}
        mutation={() => onDeleteCascadingKpi()}
      />
      <DeletePopup
        open={openMultipleDelete}
        handleClose={() => setOpenMultipleDelete(false)}
        name="item yang dipilih"
        mutation={() => handleMultipleDelete()}
      />
    </PaperContainer>
  )
}

export default CasecadingComponent
