import React from 'react'
import {WrapIconItem} from '../../DetailEmployeeStyles'
import {GET_LIST_EMAIL_ADDRESS} from '../../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import LoadingEmployee from '../../../LoadingEmployee'
import {differenceDateFrom} from '../../../../../../utils/helpers'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import verified from '../../../../../../assets/images/verified.svg'

const _getDiference = date => {
  if (date) {
    return differenceDateFrom(date, new Date(), 'day')
  } else return '-'
}

export default function EmailAddress({userId}) {
  const {data, loading} = useQuery(GET_LIST_EMAIL_ADDRESS, {
    wlb_skipPatch: true,
    fetchPolicy: 'network-only',
    variables: {
      userId,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  if (loading) {
    return <LoadingEmployee noborder smallpadding />
  }

  return (
    <div>
      {data &&
        data.people_emails &&
        data.people_emails.map(res => {
          const _dif = _getDiference(res.date_exp_verification) + 1
          if (
            _dif > 0 ||
            res.date_exp_verification === null ||
            res.is_verified
          ) {
            return (
              <WrapIconItem key={res.email}>
                <p style={{display: 'flex'}} className="black title">
                  {res.email}{' '}
                  {res.is_verified && (
                    <img
                      className="verified"
                      src={verified}
                      alt="verified-logo"
                    />
                  )}
                </p>
                {data.owner && data.owner.email === res.email && (
                  <div className="is-main">
                    <p>Main Email Address</p>
                  </div>
                )}
                {!res.is_verified &&
                  !res.is_main &&
                  res.date_exp_verification !== null && (
                    <p
                      className="red text-12"
                      style={{display: 'flex', alignItems: 'center'}}
                    >
                      {`This email will be deleted in ${_dif} days if it's not get
                      verified`}
                      <InfoOutlinedIcon
                        style={{fontSize: 11, marginLeft: 20}}
                      />
                    </p>
                  )}
              </WrapIconItem>
            )
          }
        })}
    </div>
  )
}
