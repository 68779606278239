import React, {useState} from 'react'
import WidgetSection from '../../../../components/widgets/WidgetSection'
import {
  WrapperChart,
  WrapperDashboard,
  WrapperWidgetDashboard,
} from '../../DashboardStyles'
import CardList from '../../../../components/widgets/progres-bar/card/CardListComponent'
import Skeleton from '@material-ui/lab/Skeleton'
import {GET_LOCATION_AND_EDUCATION_DASHBOARD} from '../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../utils/globals'
import {LabelMore} from './LocationStyle'
import MoreLocation from './MoreLocation'

export const calculatePercent = (data, dataTotal) => {
  return Math.round((data / dataTotal) * 100) || 0
}

const LIMIT = 7

export default function LocationAndEducation() {
  const [detailChart, setDetailChart] = useState({
    open: false,
    title: '',
  })
  const [dataChart, setDataChart] = useState({
    location: [],
    education: [],
  })

  const {data, loading} = useQuery(GET_LOCATION_AND_EDUCATION_DASHBOARD, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
      limit: LIMIT,
    },
    onCompleted: data => {
      setDataChart(() => {
        return {
          location: data.getLocation.map(({label, count}) => {
            return {
              label,
              count,
              percent: calculatePercent(
                count,
                data.total_Location.aggregate.sum.count
              ),
            }
          }),
          education: data.getEducation.map(({label, count}) => {
            return {
              label,
              count,
              percent: calculatePercent(
                count,
                data.total_Education.aggregate.sum.count
              ),
            }
          }),
          totalLocation: data.total_Location.aggregate.count,
          totalEducation: data.total_Education.aggregate.count,
        }
      })
    },
  })

  const handleClickMore = title => {
    setDetailChart({
      open: true,
      title,
    })
  }

  return (
    <>
      <WrapperDashboard>
        <WrapperWidgetDashboard>
          <WidgetSection title="Lokasi">
            <WrapperChart isList>
              {!loading && data && <CardList data={dataChart.location} />}
              {loading && (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              )}
              {!loading && dataChart?.totalLocation > LIMIT && (
                <LabelMore onClick={() => handleClickMore('Lokasi')}>
                  +{dataChart?.totalLocation - LIMIT} Lebih Lokasi
                </LabelMore>
              )}
            </WrapperChart>
          </WidgetSection>
        </WrapperWidgetDashboard>

        <WrapperWidgetDashboard>
          <WidgetSection title="Pendidikan">
            <WrapperChart isList>
              {!loading && data && <CardList data={dataChart.education} />}
              {loading && (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              )}
              {!loading && dataChart?.totalEducation > LIMIT && (
                <LabelMore onClick={() => handleClickMore('Pendidikan')}>
                  +{dataChart?.totalEducation - LIMIT} Lebih Pendidikan
                </LabelMore>
              )}
            </WrapperChart>
          </WidgetSection>
        </WrapperWidgetDashboard>
      </WrapperDashboard>

      <MoreLocation
        onClose={() => setDetailChart({open: false, title: ''})}
        open={detailChart.open}
        title={detailChart.title}
      />
    </>
  )
}
