import React, {useState} from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'

import {ModalAddEditWraper} from '../../DetailEmployeeStyles'

import FormInput from '../ShareComponents-detail/FormInput'
import {ButtonSubmit, SwitchForm} from '../../SharedDetailEmployee'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'

import {isErrorForm} from '../ShareComponents-detail/helperDetail'
import {
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
} from '../../../../../../graphql/queries'
import {
  ADD_ORGANIZATION_ACTIVITY,
  UPDATE_ORGANIZATION_ACTIVITY,
  DELETE_ORGANIZATION_ACTIVITY,
} from '../../../../../../graphql/mutations'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_FORM_DATA = {
  organization_name: '',
  organization_type: '',
  member_id: '',
  position_name: '',
  position_description: '',
  start_date: null,
  end_date: null,
  is_current: false,
  is_shared_with_network: false,
  country: null,
  province_id: null,
  city_id: null,
}

const INITIAL_SEARCH_STATE = {
  country: '',
  province: '',
  city: '',
}

const AddEditOrganizationActivity = ({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
}) => {
  const client = useApolloClient()

  const [formState, setFormState] = useState(STATE_INITIAL)
  const [formData, setFormData] = useState(INITIAL_FORM_DATA)
  const [openDelete, setOpenDelete] = useState(false)

  const [search, setSearch] = useState(INITIAL_SEARCH_STATE)

  const isEditing = actionType === 'edit'

  const isError = formState === STATE_ERROR
  const isDispatching = formState === STATE_DISPATCH

  const {data: dataProvince, loading: loadingProvince} = useQuery(
    GLOBAL_PROVINCES_SEARCHABLE,
    {
      skip: !open,
      variables: {
        search: `%${search.province}%`,
        limit: 20,
        country: formData?.country?.value,
      },
    }
  )

  const {data: dataCity, loading: loadingCity} = useQuery(
    GLOBAL_CITIES_SEARCHABLE,
    {
      skip: !open,
      variables: {
        search: `%${search.city}%`,
        limit: 20,
        province: formData?.province?.value,
      },
    }
  )

  React.useEffect(() => {
    if (actionType === 'edit' && open) {
      const country = data.country
      const province = data.province
      const city = data.city

      setFormData(e => ({
        ...e,
        organization_name: data.organization_name,
        organization_type: data.organization_type,
        member_id: data.member_id,
        position_name: data.position_name,
        position_description: data.position_description,
        start_date: data.start_date,
        end_date: data.end_date,
        is_current: !data.end_date,
        is_shared_with_network: data.is_shared_with_network,
        country: country ? {label: country, value: 103} : null,
        province_id: province
          ? {label: province.name, value: province.id}
          : null,
        city_id: city ? {label: city.name, value: city.id} : null,
      }))
    } else {
      setFormData(e => ({
        ...e,
        user_id: userIdDetail,
      }))
    }
  }, [open, actionType])

  const bindAutocompleteSearch = field => query => {
    setSearch({...search, [field]: query})
  }

  const handleAutocompleteChange = item => {
    const {fieldName, label, value} = item

    if (fieldName === 'country') {
      setFormData({
        ...formData,
        country: {label, value},
        province_id: null,
        city_id: null,
      })
    } else if (fieldName === 'province') {
      setFormData({
        ...formData,
        province_id: {label, value},
        city_id: null,
      })
    } else {
      setFormData({
        ...formData,
        [fieldName]: {label, value},
      })
    }
  }

  const handleSubmit = () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const verb = isEditing ? 'ubah' : 'tambah'
    let promise

    const variables = Object.assign({}, formData)
    variables.country = formData.country?.label
    variables.city_id = formData.city_id?.value
    variables.province_id = formData.province_id?.value
    variables.end_date = variables.is_current ? null : formData.end_date
    delete variables.is_current

    if (isEditing) {
      promise = client.mutate({
        mutation: UPDATE_ORGANIZATION_ACTIVITY,
        variables: {
          id: data?.id,
          object: {...variables},
        },
      })
    } else {
      promise = client.mutate({
        mutation: ADD_ORGANIZATION_ACTIVITY,
        variables: {
          objects: [{...variables}],
        },
      })
    }

    promise.then(
      () => {
        refetch()
        setFormState(STATE_INITIAL)
        enqueueSnackbar(`Keorganisasian di${verb}`, {variant: 'success'})

        handleCloseModal()
      },
      err => {
        setFormState(STATE_ERROR)
        console.error(err)
        enqueueSnackbar(`Failed to ${verb} organization activity`, {
          variant: 'error',
        })
      }
    )
  }

  const handleDeleteConfirm = () => {
    const promise = client.mutate({
      mutation: DELETE_ORGANIZATION_ACTIVITY,
      variables: {
        id: data.id,
        userId: userIdDetail,
      },
    })

    promise.then(
      () => {
        refetch()
        handleCloseModal()
        enqueueSnackbar(`Keorganisasian dihapus`, {variant: 'success'})
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Failed to delete organization activity`, {
          variant: 'error',
        })
      }
    )
  }

  const handleOpenModalDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }

  const handleCloseModalDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  const handleCloseModal = () => {
    setFormState(STATE_INITIAL)
    setFormData(INITIAL_FORM_DATA)
    onClose()
  }

  const fieldsList = [
    {
      type: 'textfield',
      label: 'Nama Organisasi*',
      placeholder: 'Tambahkan nama organisasi',
      required: true,
      fieldName: 'organization_name',
      value: formData.organization_name,
      error: !formData.organization_name,
    },
    {
      type: 'textfield',
      label: 'Tipe Organisasi*',
      placeholder: 'Tambahkan tipe organisasi',
      required: true,
      fieldName: 'organization_type',
      value: formData.organization_type,
      error: !formData.organization_type,
    },
    {
      type: 'textfield',
      label: 'Nomor Keanggotaan',
      placeholder: 'Tambahkan nomor keanggotaan',
      fieldName: 'member_id',
      value: formData.member_id,
      error: !formData.member_id,
    },
    {
      type: 'textfield',
      label: 'Jabatan*',
      placeholder: 'Tambah Jabatan',
      required: true,
      fieldName: 'position_name',
      value: formData.position_name,
      error: !formData.position_name,
    },
    {
      label: 'Keterangan Jabatan',
      placeholder: 'Tambahakan keterangan posisi',
      fieldName: 'position_description',
      type: 'textfield',
      multiline: true,
      rows: 6,
      value: formData.position_description,
    },
    {
      label: ['Tanggal Mulai*', 'Tanggal Selesai*'],
      labelDate: 'Saat ini saya sedang bekerja dalam organisasi ini',
      fieldName: ['start_date', 'end_date', 'is_current'],
      type: 'date',
      required: true,
      value: [
        formData.start_date,
        formData.end_date,
        formData.is_current,
        true,
      ],
      error: [!formData.start_date, !formData.end_date],
    },
    {
      type: 'autocomplete',
      label: 'Negara*',
      placeholder: 'Pilih Negara',
      required: true,
      fieldName: 'country',
      value: formData.country,
      option: [
        {
          label: 'Indonesia',
          value: 103,
        },
      ],
      error: !formData.country,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('country'),
    },
    {
      type: 'autocomplete',
      label: 'Provinsi*',
      placeholder: 'Pilih provinsi',
      required: true,
      fieldName: 'province_id',
      value: formData.province_id,
      option: dataProvince?.global_provinces.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData.province_id,
      isLoading: loadingProvince,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('province'),
    },
    {
      type: 'autocomplete',
      label: 'Kota*',
      placeholder: 'Tambahkan kota',
      required: true,
      fieldName: 'city_id',
      value: formData.city_id,
      option: dataCity?.global_cities.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData.city_id,
      isLoading: loadingCity,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('city'),
    },
  ]

  return (
    <>
      <ModalDetail
        onDelete={isEditing && handleOpenModalDelete}
        onClose={handleCloseModal}
        open={open}
        maxWidth="sm"
        title={`${isEditing ? 'Ubah' : 'Tambahkan'} Keorganisasian`}
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Bagikan dengan jaringan:"
            value={formData.is_shared_with_network}
            labelPlacement="start"
            onChange={e =>
              setFormData({
                ...formData,
                is_shared_with_network: e.target.checked,
              })
            }
          />
        </div>

        <ModalAddEditWraper>
          <FormInput
            open
            fieldsList={fieldsList}
            setValues={setFormData}
            values={formData}
            errorState={isError}
          />
        </ModalAddEditWraper>

        <ButtonSubmit
          disabled={isDispatching}
          onCancel={onClose}
          onSave={handleSubmit}
        />
      </ModalDetail>
      <DeletePopup
        name={data?.organization_name}
        open={openDelete}
        handleClose={handleCloseModalDelete}
        mutation={handleDeleteConfirm}
      />
    </>
  )
}

export default AddEditOrganizationActivity
