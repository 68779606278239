import {useMutation, useQuery} from '@apollo/react-hooks'
import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Divider,
  InputAdornment,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'
import {Skeleton} from '@material-ui/lab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {
  BlueIcon,
  SkyBlueIcon,
  GreyIcon,
  PaperContainer,
} from '../../../../GlobalStyles'
import {
  UPDATE_LEAVE_BALANCE,
  UPDATE_LEAVE_BALANCE_ACCRUAL,
} from '../../../../graphql/mutations'
import {GET_LEAVE_BALANCE_DETAIL} from '../../../../graphql/queries'
import {COMPANY_ID, DEFAULT_PAGINATION} from '../../../../utils/globals'
import NotFoundComponent from '../../../shared-components/NotFoundComponent'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import InformationPopUp from './InformationPopUp'
import {getMonthlyAccuralQuota} from '../LeaveHelpers'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontStyle: 'bold',
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    margin: '0 12px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    minHeight: '70vh',
  },
  expanded: {
    borderTop: '1px solid rgba(0, 0, 0, 0.16)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    margin: '0 !important',
  },
  bold: {
    fontWeight: 700,
  },

  mr15: {marginRight: '1.5rem'},
  textGrey: {color: '#a9a8a8'},
  tableWrapper: {
    margin: '1.6rem',
    maxHeight: 440,
    overflow: 'auto',
  },
  stickyHeader: {
    backgroundColor: '#fff',
    minWidth: 65,
  },
  flexTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipAccrualInfo: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: '#f7f8f9',
    color: '#000',
    width: 'auto',
    padding: 13,
    textAlign: 'left',
  },
  tooltip: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: '#f7f8f9',
    color: '#000',
    width: 150,
    padding: 13,
    textAlign: 'center',
  },
  arrow: {color: '#f7f8f9'},
}))

export default function EditLeaveBalance({policy}) {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [selected, setSelected] = useState({
    carry_over: '',
    taken_carry_over: '',
    quota: '',
    taken_current: '',
    user_work_id: '',
    max_monthly_accrual_quota: '',
  })

  const [selectedYear, setSeledtedYear] = useState(new Date())

  const [loadingState, setLoadingState] = useState(false)

  const [pagination, setPagination] = useState({
    limit: DEFAULT_PAGINATION[0],
    offset: 0,
  })

  const [openInformation, setOpenInformation] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  const positions =
    (policy &&
      policy.company_job_profile_r_leaves &&
      policy.company_job_profile_r_leaves.map(
        pos => pos.company_job_profile && pos.company_job_profile.id
      )) ||
    []
  const policyId = (policy && policy.id) || 0

  const columns = [
    {
      name: 'nik',
      label: 'Employee ID',
    },
    {
      name: 'Name',
      label: 'Employee Name',
      minWidth: '9rem',
    },
    {
      name: 'budget-standard',
      label: 'Employee Leave and Permission Balance',
      align: 'right',
      minWidth: '5rem',
    },
    {
      name: 'used',
      label: 'Used',
      minWidth: '3rem',
      align: 'right',
    },
    {
      name: 'carry-over',
      label: 'Carry Over',
      minWidth: '5rem',
      align: 'right',
    },
    {
      name: 'carry-over-used',
      label: 'Carry Over Used',
      minWidth: '5rem',
      align: 'right',
    },
    {
      name: 'planned',
      label: 'Planned',
      align: 'right',
    },
    {
      name: 'balance',
      label: 'Balance Remaining',
      align: 'right',
    },
    {
      name: 'action',
      label: 'Action',
      align: 'center',
    },
  ]

  const YEAR = selectedYear.getFullYear()

  const {data, loading, error, refetch} = useQuery(GET_LEAVE_BALANCE_DETAIL, {
    fetchPolicy: 'network-only',
    variables: {
      job_profiles: positions.filter(n => n),
      id: policyId,
      company: COMPANY_ID,
      gender: policy.gender === 'U' ? undefined : {_eq: policy.gender},
      limit: pagination.limit,
      offset: pagination.offset * pagination.limit,
      date_start_leave: `${YEAR}-01-01`,
      date_end_leave: `${YEAR}-12-31`,
      period_year: [YEAR, YEAR + 1],
    },
  })

  const [onUpdateBalance] = useMutation(UPDATE_LEAVE_BALANCE)
  const [onUpdateBalanceAccrual] = useMutation(UPDATE_LEAVE_BALANCE_ACCRUAL)

  const {time_leave_policy, quota, infinite_quota} =
    data?.time_leave_policy_children?.[0] || {}

  const policyQuota = quota
  const isUnlimited = infinite_quota
  const isProrate = time_leave_policy?.quota_prorate_option
  const isAccrual = time_leave_policy?.calc_method === 'accrual'
  const quotaResetOption = time_leave_policy?.quota_reset_option
  const quotaResetMonth = time_leave_policy?.quota_reset_option_month
  const quotaResetDate = time_leave_policy?.quota_reset_option_date

  const thisYear = new Date().getFullYear()

  if (isUnlimited) {
    columns.pop()
  }

  const handleEdit = ({
    open,
    user_work_id,
    carry_over,
    quota,
    taken_current,
    taken_carry_over,
    max_monthly_accrual_quota,
  }) => {
    if (open) {
      setSelected({
        carry_over,
        taken_carry_over,
        quota,
        taken_current,
        user_work_id,
        max_monthly_accrual_quota,
      })
    } else {
      setSelected({
        carry_over: '',
        taken_carry_over: '',
        quota: '',
        taken_current: '',
        user_work_id: '',
        max_monthly_accrual_quota: '',
      })
    }
  }

  const renderAccrualBalance = (row, quotas) => {
    const {max_monthly_accrual_quota} = row?.people_work_leave_quotas?.[0] || {}
    return (
      <span>
        {quotas || 0} of {max_monthly_accrual_quota || row.quota || 0}{' '}
        <Tooltip
          arrow
          title="Number of Balance will increase every month"
          placement="right"
          classes={{
            tooltipArrow: classes.tooltipAccrualInfo,
            arrow: classes.arrow,
          }}
        >
          <IconButton size="small">
            <SkyBlueIcon style={{fontSize: 12}}>info</SkyBlueIcon>
          </IconButton>
        </Tooltip>
      </span>
    )
  }

  const renderEmployeeBalance = ({isAccrual, row, quotas}) => {
    if (isAccrual) return renderAccrualBalance(row, quotas)
    else return quotas
  }

  const renderTextField = (val, name) => {
    return (
      <TextField
        variant="outlined"
        size="small"
        value={val}
        type="number"
        style={{minWidth: 85}}
        onChange={e => {
          let vals = 0
          if (e.target.value) {
            vals = parseFloat(e.target.value)
          }
          setSelected({
            ...selected,
            [name]: vals,
          })
        }}
      />
    )
  }

  const handleSaveEdit = () => {
    const variables = {
      period_year: YEAR,
      leave_subpolicy_id: policyId,
      carry_over: selected?.carry_over || 0,
      taken_carry_over: selected?.taken_carry_over || 0,
      quota: selected?.quota || 0,
      taken_current: selected?.taken_current || 0,
      user_work_id: selected?.user_work_id,
      ...(isAccrual && {
        max_monthly_accrual_quota: selected?.max_monthly_accrual_quota,
      }),
    }
    setLoadingState(true)
    const updateData = isAccrual ? onUpdateBalanceAccrual : onUpdateBalance
    updateData({variables})
      .then(() => {
        enqueueSnackbar('Success Update data', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        handleEdit({open: false})
        refetch()
        setLoadingState(false)
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPagination(e => ({...e, offset: newPage}))
  }

  const handleChangeRowsPerPage = event => {
    setPagination(e => ({
      ...e,
      offset: 0,
      limit: parseInt(event.target.value, 10),
    }))
  }

  const handleInformation = () => {
    setOpenInformation(prev => !prev)
  }

  const handleConfirm = () => {
    setOpenConfirm(prev => !prev)
  }

  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link
          to={{
            pathname: '/time/leave/leave-balance',
            state: {searchable: true, active: 'leave-balance'},
          }}
        >
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <div className={classes.flexTitle}>
          <Typography variant="body1" className={classes.title}>
            Leave and Permission Balance - {policy && policy.name}
          </Typography>
          <Tooltip
            title="Click here to read additional information about this page"
            placement="top"
            arrow
            classes={{tooltipArrow: classes.tooltip, arrow: classes.arrow}}
          >
            <IconButton size="small" onClick={handleInformation}>
              <GreyIcon style={{fontSize: 20}}>help</GreyIcon>
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
      <Divider />
      <div style={{padding: '1.4rem 0 0 1.6rem'}}>
        <Typography variant="body1" color="primary" gutterBottom>
          Period
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            views={['year']}
            inputVariant="outlined"
            value={selectedYear}
            onChange={setSeledtedYear}
            size="small"
            style={{width: '10rem'}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <BlueIcon>today</BlueIcon>
                </InputAdornment>
              ),
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      {loading && (
        <div style={{padding: '10px 22px 0px'}}>
          <Loader />
          <Loader />
          <Loader />
          <Loader />
        </div>
      )}
      {!loading && error && <div>{JSON.stringify(error)}</div>}
      {!loading && data?.total?.aggregate?.count === 0 && (
        <NotFoundComponent message="" />
      )}
      {!loading && data?.people_work_placements && (
        <div
          style={{
            width: '96%',
            overflow: 'auto',
            margin: '1.2rem 1.6rem 0px',
          }}
        >
          <Table style={{fontSize: '0.875rem'}}>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    classes={{stickyHeader: classes.stickyHeader}}
                    style={{minWidth: column.minWidth}}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.people_work_placements?.map(res => {
                const row = {
                  ...res,
                  quota: data.time_leave_policy_children[0].quota,
                }

                const startDate = res.join_date
                const carryOver =
                  row?.people_work_leave_quotas?.[0]?.carry_over || 0
                const carryOverTaken =
                  row.people_work_leave_quotas?.[0]?.taken_carry_over || 0
                const takenCurrent =
                  row.people_work_leave_quotas?.[0]?.taken_current || 0
                const baseQuota = row.people_work_leave_quotas?.[0]?.quota
                const initialQuota = isAccrual
                  ? getMonthlyAccuralQuota(row.quota, res.join_date) || 0
                  : Math.floor(isProrate ? row.quota : baseQuota || row.quota)
                const planed =
                  row?.people_work_leaves_aggregate?.aggregate?.sum
                    ?.quota_planned || 0

                let quotas = initialQuota

                let remainingQuota = getBalanceRemaining({
                  balance: quotas,
                  carryOver,
                  balanceUsed: takenCurrent,
                  carryOverUsed: carryOverTaken,
                  isAccrual,
                })
                if (isUnlimited) {
                  quotas = 'Unlimited'
                  remainingQuota = 'Unlimited'
                } else {
                  if (isProrate) {
                    let yearReset = parseInt(moment(startDate).format('YYYY'))

                    if (
                      (quotaResetOption === 1 || quotaResetOption === 3) &&
                      yearReset === thisYear
                    ) {
                      const joinMonth = moment(startDate).month() + 1
                      if (joinMonth >= quotaResetMonth) {
                        yearReset++
                      }

                      const nextReset = moment([
                        yearReset,
                        quotaResetMonth - 1,
                        quotaResetDate,
                      ])
                      const monthDiff = Math.ceil(
                        nextReset.diff(moment(startDate), 'months', true)
                      )

                      const _prorate = monthDiff / 12
                      const totalProrate = _prorate * initialQuota

                      quotas = Math.floor(totalProrate)
                      remainingQuota = getBalanceRemaining({
                        balance: quotas,
                        carryOver,
                        balanceUsed: takenCurrent,
                        carryOverUsed: carryOverTaken,
                        isAccrual,
                      })
                    }
                  }
                }

                const isSelected = selected.user_work_id === row.id

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column, index) => {
                      let value
                      switch (index) {
                        case 0:
                          value = row.regno
                          break
                        case 1:
                          value = row.global_user.name
                          break
                        case 2:
                          value = isSelected
                            ? renderTextField(selected.quota, 'quota', row.id)
                            : renderEmployeeBalance({isAccrual, row, quotas})
                          break
                        case 3:
                          value = isSelected
                            ? renderTextField(
                                selected.taken_current,
                                'taken_current',
                                row.id
                              )
                            : takenCurrent
                          break
                        case 4:
                          value = isSelected
                            ? renderTextField(
                                selected.carry_over,
                                'carry_over',
                                row.id
                              )
                            : carryOver
                          break
                        case 5:
                          value = carryOverTaken
                          break
                        case 6:
                          value = planed
                          break
                        case 7:
                          value = remainingQuota
                          break
                        case 8:
                          value = !isUnlimited && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {isSelected ? (
                                <>
                                  <Button
                                    size="small"
                                    style={{marginRight: 8}}
                                    onClick={() => handleEdit({open: false})}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={
                                      Object.values(selected).some(o =>
                                        Number.isNaN(o)
                                      ) || loadingState
                                    }
                                    // onClick={handleSaveEdit}
                                    onClick={handleConfirm}
                                  >
                                    Save
                                  </Button>
                                </>
                              ) : (
                                <Typography
                                  variant="body2"
                                  color="secondary"
                                  style={{cursor: 'pointer'}}
                                  onClick={() =>
                                    handleEdit({
                                      open: true,
                                      user_work_id: row.id,
                                      carry_over: carryOver,
                                      quota: quotas,
                                      taken_current: takenCurrent,
                                      taken_carry_over: carryOverTaken,
                                      max_monthly_accrual_quota:
                                        row?.people_work_leave_quotas?.[0]
                                          ?.max_monthly_accrual_quota ||
                                        row?.quota,
                                    })
                                  }
                                >
                                  Edit
                                </Typography>
                              )}
                            </div>
                          )
                          break

                        default:
                          value = undefined
                          break
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      )}
      {data?.people_work_placements?.[0] && (
        <TablePagination
          rowsPerPageOptions={DEFAULT_PAGINATION}
          component="div"
          rowsPerPage={pagination.limit}
          page={pagination.offset}
          count={data?.total?.aggregate?.count || 0}
          backIconButtonProps={{'aria-label': 'previous page'}}
          nextIconButtonProps={{'aria-label': 'next page'}}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <InformationPopUp
        open={openInformation}
        handleClose={handleInformation}
        quota={policyQuota}
        isUnlimited={isUnlimited}
      />
      <AddEdit
        open={openConfirm}
        handleClose={handleConfirm}
        type="Confirm"
        mutation={handleSaveEdit}
      />
    </PaperContainer>
  )
}

const Loader = () => (
  <Skeleton
    style={{marginBottom: '-10px'}}
    animation="wave"
    height={73}
    width="100%"
  />
)

const getBalanceRemaining = ({
  balance,
  carryOver,
  balanceUsed,
  carryOverUsed,
  isAccrual,
}) => {
  const balanceRemaining = balance + carryOver - (balanceUsed + carryOverUsed)

  if (isAccrual) {
    return balanceRemaining
  }

  return Math.floor(balanceRemaining)
}
