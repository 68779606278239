import ListPageComponent from './ListPageComponent'

export const TalentHoldingListPageConfig = {
  routes: [
    {
      path: '/holding-talent/list-talent',
      component: ListPageComponent,
      state: 'holding-talent-list',
      exact: true,
    },
    {
      path: '/holding-talent/pending-talent',
      component: ListPageComponent,
      state: 'holding-talent-pending',
      exact: true,
    },
    {
      path: '/holding-talent/source',
      component: ListPageComponent,
      state: 'holding-talent-source',
      exact: true,
    },
    {
      path: '/holding-talent/nomination',
      component: ListPageComponent,
      state: 'holding-talent-nomination',
      exact: true,
    },
    {
      path: '/holding-talent/nomination/add',
      component: ListPageComponent,
      state: 'add-nomination',
      exact: true,
    },
    {
      path: '/holding-talent/nomination/edit/:id',
      component: ListPageComponent,
      state: 'edit-nomination',
      exact: true,
    },
    {
      path: '/holding-talent/nomination/:id',
      component: ListPageComponent,
      state: 'detail-nomination',
      exact: true,
    },
    {
      path: '/holding-talent/nomination/all-successor',
      component: ListPageComponent,
      state: 'all-successor',
      exact: true,
    },
    {
      path: '/holding-talent/nomination/all-pool',
      component: ListPageComponent,
      state: 'all-pool',
      exact: true,
    },
  ],
}
