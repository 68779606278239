import React, {useState, useEffect} from 'react'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Dialog,
  Icon,
  IconButton,
  FormControl,
  InputAdornment,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
} from '@material-ui/core'
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'
import Calendar from '@material-ui/icons/Today'
import {useQuery} from '@apollo/react-hooks'

import AlertDialog from '../../popup/ResultPopup'

import {DatePickerWrap} from '../../../../components/card/CardStyles'
import {
  GET_RELIGION,
  GET_RELIGION_THR_SCHEDULE,
} from '../../../../graphql/queries'
import {CustomTypography} from '../../../../GlobalStyles'
import {WrapperModalGeneral} from '../../../financial/payroll/PayrollPageStyles'
import {GQL_FINANCE_OPTIONS} from '../../../financial/utils'
import {COMPANY_ID} from '../../../../utils/globals'

export default function GenerateManualPopup({
  mutation,
  onClose,
  open,
  title,
  type = 'payroll',
  enqueueSnackbar,
}) {
  const isThr = type === 'thr'
  const datePickerViews = isThr ? ['date', 'month', 'year'] : ['year', 'month']

  const [isSubmit, setIsSubmit] = useState(false)
  const [openWarningPopup, setOpenWarningPopup] = useState(false)
  const [religionIds, setReligionIds] = useState([])
  const [religionMap, setReligionMap] = useState(null)
  const [selectedDate, setSelectedData] = useState(new Date())
  const [period, setPeriod] = useState({
    period_year: Number(moment(selectedDate).format('YYYY')),
    period_month: Number(moment(selectedDate).format('M')),
  })

  const {data: religionThrSchedule} = useQuery(GET_RELIGION_THR_SCHEDULE, {
    variables: {
      company: COMPANY_ID,
    },
    skip: type !== 'thr' && !open,
    fetchPolicy: 'network-only',
    ...GQL_FINANCE_OPTIONS,
  })

  const {data: listOfReligions} = useQuery(GET_RELIGION, {
    skip: type !== 'thr' && !open,
  })

  useEffect(() => {
    if (listOfReligions && religionThrSchedule) {
      const _religionThrSchedule =
        religionThrSchedule.finance_thr_settings_by_pk?.schedules
      const map = {}

      listOfReligions.global_religions.forEach((el, idx) => {
        if (!map[el.id])
          map[el.id] = {
            ...el,
            checked: false,
            isAbleToChecked: !!_religionThrSchedule?.[idx]?.date,
          }
      })

      setReligionMap(map)
    }
  }, [listOfReligions, religionThrSchedule])

  const handleDateChange = period => {
    setSelectedData(period)
    setPeriod({
      ...period,
      period_year: Number(moment(period).format('YYYY')),
      period_month: Number(moment(period).format('M')),
    })
  }

  const handleReligionChecked = (id, newCheckedValue) => {
    setReligionMap({
      ...religionMap,
      [id]: {
        ...religionMap[id],
        checked: newCheckedValue,
      },
    })

    const indexId = religionIds.indexOf(id)
    if (indexId > -1) {
      religionIds.splice(indexId, 1)
      setReligionIds([...religionIds])
    } else {
      setReligionIds([...religionIds, id])
    }
  }

  const handleConfirm = () => {
    onClose()
    setOpenWarningPopup(false)
    setIsSubmit(true)

    const data = {...period}
    if (isThr) {
      if (religionIds.length > 0) {
        data.period_date = Number(moment(selectedDate).format('D'))
        data.period_religion = religionIds.toString()
      } else {
        return null
      }
    }

    mutation(data)
  }

  const handleGenerateClicked = () => {
    if (type === 'thr' && religionIds.length < 1) {
      setIsSubmit(true)
      enqueueSnackbar('Please choose at least one religion', {
        variant: 'error',
        autoHideDuration: 1000,
      })
    } else {
      setOpenWarningPopup(true)
    }
  }

  const error = isSubmit && type === 'thr' && religionIds.length < 1

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        scroll="body"
        maxWidth="sm"
        style={{display: openWarningPopup ? 'none' : 'block'}}
      >
        <WrapperModalGeneral>
          <div className="header">
            <h1 className="title">{`Generate Data ${title}`}</h1>

            <IconButton
              aria-label="close"
              className="wrap-close"
              onClick={onClose}
            >
              <Icon className="icon-close">close</Icon>
            </IconButton>
          </div>

          <div className="body">
            <CustomTypography size="1rem" fweight="bold">
              {isThr ? 'Choose Disbursement Date' : 'Choose Period'}:
            </CustomTypography>

            <DatePickerWrap className="wrap-date-picker">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  className="date-picker-dialog"
                  color={'primary'}
                  openTo={isThr ? 'date' : 'year'}
                  placeholder="Choose Period"
                  inputVariant="outlined"
                  views={datePickerViews}
                  value={selectedDate}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="ml-0" position="end">
                        <Calendar
                          style={{
                            color: '#014a62',
                            width: 20,
                            height: 20,
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onChange={val => {
                    handleDateChange(val)
                  }}
                />
              </MuiPickersUtilsProvider>
            </DatePickerWrap>

            {isThr && (
              <>
                <div style={{marginTop: 30}}>
                  <FormControl required error={error} component="fieldset">
                    <FormLabel
                      component="legend"
                      style={{
                        fontSize: '1rem',
                        fontWeight: 600,
                        color: '#000000',
                      }}
                    >
                      Religion
                    </FormLabel>

                    <FormGroup
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      {listOfReligions &&
                        religionMap &&
                        listOfReligions.global_religions.map(
                          (religion, index) => {
                            return (
                              <FormControlLabel
                                key={index}
                                disabled={
                                  !religionMap[religion.id].isAbleToChecked
                                }
                                control={
                                  <Checkbox
                                    checked={religionMap[religion.id].checked}
                                    style={{
                                      color:
                                        error &&
                                        religionMap[religion.id].isAbleToChecked
                                          ? 'red'
                                          : religionMap[religion.id]
                                              .isAbleToChecked
                                          ? '#014a62'
                                          : '',
                                    }}
                                    onChange={() =>
                                      handleReligionChecked(
                                        religion.id,
                                        !religionMap[religion.id].checked
                                      )
                                    }
                                  />
                                }
                                label={
                                  <span>
                                    {religion.name}{' '}
                                    {!religionMap[religion.id]
                                      .isAbleToChecked && (
                                      <i>
                                        (Please set thr schedule in settings
                                        menu)
                                      </i>
                                    )}
                                  </span>
                                }
                              />
                            )
                          }
                        )}
                    </FormGroup>

                    <FormHelperText>
                      {error ? 'Need to filled mandatory field' : ''}
                    </FormHelperText>
                  </FormControl>
                </div>
              </>
            )}

            <p className="text-alert-notes">{`Notes: Generate ${
              isThr ? `THR` : 'Payroll'
            } Process takes quite a long time and can only be generate after the status of all ${
              isThr ? `THR` : 'Period'
            } is PAID. Make sure you choose the correct period ${
              isThr ? 'and religion' : ''
            }`}</p>
          </div>

          <div className="action-wrapper-modal-confirmation">
            <Button onClick={onClose}>Cancel</Button>

            <Button
              color="primary"
              size="large"
              style={{marginLeft: 12}}
              variant="contained"
              onClick={() => handleGenerateClicked()}
            >
              Generate
            </Button>
          </div>
        </WrapperModalGeneral>
      </Dialog>

      {openWarningPopup && (
        <AlertDialog
          feature="Warning"
          handleClose={() => setOpenWarningPopup(false)}
          message="This may overwrite existing payroll data in the period, are you sure?"
          mutation={handleConfirm}
          open={openWarningPopup}
          positiveTitleButton="Yes"
          status="cancel"
          type="warning"
        />
      )}
    </>
  )
}
