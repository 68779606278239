import gql from 'graphql-tag'

export const DELETE_TASK = gql`
  mutation deleteTask($id: bigint!) {
    update_performance_tasks(
      where: {id: {_eq: $id}}
      _set: {deletedat: "now()"}
    ) {
      returning {
        id
      }
    }
  }
`

export const UPDATE_ATTACHMENT_TASK = gql`
  mutation updateAttachmentTask($id: bigint!, $attachment: jsonb) {
    update_performance_tasks(
      _set: {attachment: $attachment}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
