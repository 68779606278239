import gql from 'graphql-tag'

export const ADD_TALENT_CLASSIFICATION = gql`
  mutation addTalentClassification($data: [talent_versions_insert_input!]!) {
    insert_talent_versions(objects: $data) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const ADD_CANDIDATE_GROUP = gql`
  mutation($data: [talent_candidate_groups_insert_input!]!) {
    insert_talent_candidate_groups(objects: $data) {
      returning {
        id
      }
    }
  }
`

export const ADD_TALENT_COMMITTEE = gql`
  mutation($object: [talent_committees_insert_input!]!) {
    insert_talent_committees(objects: $object) {
      returning {
        id
      }
    }
  }
`
