import React from 'react'
import {makeStyles, StepConnector} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
// stepper components
import KpiStep from './step/KpiStep'
import DetailStep from './step/DetailStep'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import AddTask from '../modal/tabs/AddTask'

const formGoalStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  buttonAction: {
    width: 120,
    height: 52,
  },
  instructions: {
    padding: '36px 36px 18px',
  },
  labelActive: {
    color: '#003d51 !important',
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    color: '#003d51 !important',
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  paper: {margin: '0 0 8px 8px'},
  steperPosition: {
    width: '50%',
    margin: 'auto',
  },
  chipsRoot: {
    fontSize: 12,
    color: '#fff',
    padding: '4px 16px',
    borderRadius: 5,
    lineHeight: '15px',
    display: 'inline',
  },
  pfGroupWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'start',
    margin: '12px 0px',
    maxWidth: '30%',
    minWidth: '15%',
  },
  pfGroup: {
    width: '75%',
    fontSize: 14,
    margin: 0,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
  },
  box: {
    width: 24,
    height: 24,
    borderRadius: 5,
    marginRight: 15,
  },
  wrapperReporter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  nameReporter: {
    fontSize: 14,
    color: '#333333',
    fontWeight: 600,
  },
  avatarSpacerReporter: {marginRight: '1rem', width: 42, height: 42},
  rootReporterSelect: {
    minWidth: 241,
    backgroundColor: '#fff',
  },
  heightSelect: {
    '& > .MuiOutlinedInput-root': {
      height: '66px !important',
    },
  },
  dropdownWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarDropdown: {
    width: 30,
    height: 30,
    marginRight: 12,
  },
  nameDropdown: {
    fontSize: 12,
    color: '#000',
    fontWeight: 600,
  },
  positionDropdown: {
    fontSize: 12,
    color: '#a9a8a8',
    marginTop: 4,
  },
  titleReporter: {color: '#a9a8a8'},
  editIcon: {
    fontSize: 18,
    color: '#039be5',
    cursor: 'pointer',
    marginLeft: 16,
  },
  assignee: {
    marginRight: 6,
    width: 42,
    height: 42,
  },
  onProgress: {
    width: '100%',
    marginTop: 24,

    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#039BE5',
    },
  },
  completedProgress: {
    width: '100%',
    marginTop: 24,

    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#4CAF50',
    },
  },
  updateField: {
    '& > .MuiOutlinedInput-root': {
      height: 44,
      width: 90,
    },
    alignSelf: 'center',
  },
  buttonUpdate: {
    width: 96,
    height: 44,
  },
  tabsRoot: {background: '#F7F8F9'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
}))

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-40% + 30px)',
    right: 'calc(60% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#003d51',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#003d51',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

function getSteps() {
  return [`Pilih Kumpulan ${GLOBAL_GLOSSARY.performance.Goal}`, 'Rincian']
}

function getStepContent(
  stepIndex,
  handleChangeRadio,
  id,
  idPerspective,
  value,
  handleChange,
  change,
  setChange,
  handleDate,
  isError,
  selectedGroup,
  setSelectedGroup,
  colorPicker,
  setColorPicker,
  label,
  setLabel,
  colorOptions,
  setColorOptions,
  isGoal
) {
  const dataDateGoal = {startDate: value?.startdate, endDate: value?.enddate}
  const dataKpi = value?.kpi_list
  switch (stepIndex) {
    case 0:
      return (
        <KpiStep
          handleChangeRadio={handleChangeRadio}
          idx={id}
          idPerspective={idPerspective}
          isGoal={isGoal}
        />
      )
    case 1:
      if (isGoal) {
        return (
          <DetailStep
            dataKpi={value}
            handleChange={handleChange}
            change={change}
            handleDate={handleDate}
            isError={isError}
            setChange={setChange}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            colorPicker={colorPicker}
            setColorPicker={setColorPicker}
            label={label}
            setLabel={setLabel}
            colorOptions={colorOptions}
            setColorOptions={setColorOptions}
            isGoal={isGoal}
          />
        )
      } else {
        return (
          <AddTask
            idGoals={id}
            dataKpi={dataKpi}
            dataDateGoal={dataDateGoal}
            setDataCreate={setChange}
            dataCreate={change}
            isErrorTask={isError}
          />
        )
      }
    default:
      return 'Unknown stepIndex'
  }
}

// const nameScoring =
//   value.kpi_scoring_nature &&
//   value.kpi_scoring_nature.global_scoring_nature &&
//   value.kpi_scoring_nature.global_scoring_nature.name

// const checkScoring = () => {
//   if (
//     (nameScoring === 'Lower is Better' &&
//       parseInt(change.targetBase) <= parseInt(change.targetStretch)) ||
//     (nameScoring === 'Higher is Better' &&
//       parseInt(change.targetBase) >= parseInt(change.targetStretch)) ||
//     (nameScoring === 'Precise is Better' &&
//       parseInt(change.targetBase) !== parseInt(change.targetStretch))
//   ) {
//     return true
//   } else {
//     return false
//   }
// }

// const renderErrorStretch = () => {
//   if (isError) {
//     if (
//       nameScoring === 'Lower is Better' &&
//       parseInt(change.targetBase) <= parseInt(change.targetStretch)
//     ) {
//       return 'Target stretch must be lower than target base'
//     } else if (
//       nameScoring === 'Higher is Better' &&
//       parseInt(change.targetBase) >= parseInt(change.targetStretch)
//     ) {
//       return 'Target stretch must be higher than target base'
//     } else if (
//       nameScoring === 'Precise is Better' &&
//       parseInt(change.targetBase) !== parseInt(change.targetStretch)
//     ) {
//       return 'Target stretch must be same with target base'
//     } else if (!change.targetStretch) {
//       return 'This field is required'
//     }
//   }
// }

export {formGoalStyles, CustomConnector, getStepContent, getSteps}
