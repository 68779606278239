import gql from 'graphql-tag'

export const EDTT_ORGANIZATION = gql`
  mutation(
    $code: String
    $name: String
    $parent: Int
    $id: Int!
    $companyId: uuid!
    $acting_as_id: Int
    $organization_level_id: Int
    $as_assistant: Boolean
    $line_type: Int
  ) {
    update_company_organizations(
      _set: {
        code: $code
        name: $name
        parent: $parent
        acting_as_id: $acting_as_id
        organization_level_id: $organization_level_id
        as_assistant: $as_assistant
        line_type: $line_type
      }
      where: {_and: [{id: {_eq: $id}}, {company: {_eq: $companyId}}]}
    ) {
      affected_rows
    }
  }
`

export const SET_ORGANIZATION_ASSISTANT = gql`
  mutation($id: Int!, $assistant: Boolean!) {
    update_company_organizations_by_pk(
      pk_columns: {id: $id}
      _set: {as_assistant: $assistant}
    ) {
      id
      as_assistant
    }
  }
`

export const SET_ORGANIZATION_LINETYPE = gql`
  mutation($id: Int!, $lineType: Int!) {
    update_company_organizations_by_pk(
      pk_columns: {id: $id}
      _set: {line_type: $lineType}
    ) {
      id
      line_type
    }
  }
`

export const SET_NEW_PARENT_ORG = gql`
  mutation(
    $organization1: Int
    $position1: Int
    $positionDropdown: Int
    $parentOrganization1: Int
    $parentPosition1: Int
    $a: Boolean
    $b: Boolean
    $c: Boolean
  ) {
    a: update_company_organizations(
      where: {id: {_eq: $organization1}, _not: {id: {_is_null: $a}}}
      _set: {parent: $parentOrganization1}
    ) {
      affected_rows
    }
    b: update_company_job_profiles(
      where: {id: {_eq: $position1}, _not: {id: {_is_null: $b}}}
      _set: {parent: $parentPosition1}
    ) {
      affected_rows
    }
    c: update_company_job_profiles(
      where: {id: {_eq: $positionDropdown}, _not: {id: {_is_null: $c}}}
      _set: {parent: null}
    ) {
      affected_rows
    }
  }
`

export const SET_NEW_PARENT_POSITION = gql`
  mutation(
    $organization1: Int
    $position1: Int
    $organizationDropdown: Int
    $parentOrganization1: Int
    $parentPosition1: Int
    $a: Boolean
    $b: Boolean
    $c: Boolean
  ) {
    a: update_company_organizations(
      where: {id: {_eq: $organization1}, _not: {id: {_is_null: $a}}}
      _set: {parent: $parentOrganization1}
    ) {
      affected_rows
    }
    b: update_company_job_profiles(
      where: {id: {_eq: $position1}, _not: {id: {_is_null: $b}}}
      _set: {parent: $parentPosition1}
    ) {
      affected_rows
    }
    c: update_company_organizations(
      where: {id: {_eq: $organizationDropdown}, _not: {id: {_is_null: $c}}}
      _set: {parent: null}
    ) {
      affected_rows
    }
  }
`
