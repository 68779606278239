import React from 'react'
import {PaperContainer} from '../../../../../GlobalStyles'
import {
  HeaderBoxSection,
  TitleSection,
  BodyBoxSection,
} from '../DetailEmployeeStyles'
import {Divider, IconButton} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

export default function BoxSection({
  title,
  withPadding,
  isNoBorder = false,
  onAdd = null,
  ...content
}) {
  return (
    <PaperContainer
      style={{
        overflow: 'hidden',
        marginBottom: 30,
        width: 'auto',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      }}
    >
      <HeaderBoxSection isNoBorder={isNoBorder}>
        <TitleSection>{title}</TitleSection>
        {onAdd && (
          <IconButton size="small" onClick={onAdd}>
            <AddIcon />
          </IconButton>
        )}
      </HeaderBoxSection>
      {!isNoBorder && <Divider style={{margin: '0px 26px'}} />}
      <BodyBoxSection
        style={withPadding ? {padding: 26} : {padding: 0}}
        {...content}
      />
    </PaperContainer>
  )
}
