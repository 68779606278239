import React, {useState} from 'react'
import {Button, Popover} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {
  ExpansionPanelStyle,
  ExpansionPanelSummaryStyle,
  ExpansionPanelDetailStyle,
  ItemMenu,
  ItemClause,
} from '../SettingStyles'
import {Link} from 'react-router-dom'
import {useApolloClient} from '@apollo/react-hooks'
import {GET_CHECK_DELETE_COMPONENT} from '../../../../../graphql/queries'
import {TextBlue} from '../../../component/modalStyles'
import {
  BoxListContent,
  BoxListName,
  FlexColumn,
  FlexRow,
  TitleList,
} from './ListStyles'

export default function ListComponent({data, setOpen}) {
  const client = useApolloClient()
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'more-setting-component' : undefined

  const [limitWord, setWord] = useState(570)

  const getCheckDelete = async (id, name) => {
    const {data} = await client.query({
      query: GET_CHECK_DELETE_COMPONENT,
      variables: {id},
    })
    if (data) {
      const isZeroAggregate =
        data?.business_trip_component?.[0]
          ?.business_trip_policy_destination_budgets_aggregate.aggregate
          .count === 0
      setOpen(e => ({
        ...e,
        ...(isZeroAggregate ? {delete: true} : {confirm: true}),
        deleteName: name,
        deleteId: id,
        model: 'Component',
      }))
      setAnchorEl(null)
    }
  }
  return (
    <>
      <ExpansionPanelStyle>
        <ExpansionPanelSummaryStyle>
          <span style={{fontWeight: 600}}>{data?.name}</span>
          <MoreHorizIcon
            aria-describedby={id}
            onClick={e => {
              e.stopPropagation()
              setAnchorEl(e.currentTarget)
            }}
          />
        </ExpansionPanelSummaryStyle>
        <ExpansionPanelDetailStyle>
          <ItemClause>
            <FlexColumn style={{width: '97%'}}>
              <TitleList>{'Component Details'}</TitleList>
              <FlexColumn padding={'20px 20px'}>
                <FlexRow padding={'5px'}>
                  <BoxListName>{'Component Name'}</BoxListName>
                  <BoxListContent>{data?.name}</BoxListContent>
                </FlexRow>
                <FlexRow padding={'5px'}>
                  <BoxListName>{'Component Type'}</BoxListName>
                  <BoxListContent>
                    {data?.business_trip_component_type
                      ? data.business_trip_component_type.name
                      : '-'}
                  </BoxListContent>
                </FlexRow>
                <FlexRow padding={'5px'}>
                  <BoxListName>{'Description'}</BoxListName>
                  <BoxListContent>
                    {data?.description.slice(0, limitWord)}
                    {data?.description.length >= 480 && (
                      <TextBlue
                        style={{cursor: 'pointer'}}
                        onClick={() =>
                          setWord(
                            limitWord === 570 ? data?.description.length : 570
                          )
                        }
                      >
                        {limitWord === 570 ? 'See more' : 'See less'}
                      </TextBlue>
                    )}
                  </BoxListContent>
                </FlexRow>
              </FlexColumn>
            </FlexColumn>
          </ItemClause>
        </ExpansionPanelDetailStyle>
      </ExpansionPanelStyle>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ItemMenu>
          <Button
            component={Link}
            to={{
              pathname: '/business/trip/settings/add-component',
              state: {
                active: 'add-component',
                type: 'Edit',
                dataEdit: data,
              },
            }}
            className="item"
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              getCheckDelete(data.id, data.name)
            }}
            className="item"
          >
            Delete
          </Button>
        </ItemMenu>
      </Popover>
    </>
  )
}
