import React from 'react'

import {Box, Popover} from '@material-ui/core'
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'

import {ChromePicker} from 'react-color'

import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
  TextHelper,
} from '../../GlobalStyles'
import FieldInformationTooltip from '../../pages/shared-components/tooltip/FieldInformationTooltip'
import {ColorPickButton} from './FormStyles'

export const DEFAULT_COLOR_OPTIONS = [
  '#ef4d5e',
  '#a9a8a8',
  '#ffa000',
  '#007fb2',
  '#4caf50',
  '#a35a31',
  '#874caf',
  '#f04ba0',
]

const ColorInputGroup = props => {
  const {
    fieldNumber,
    fieldLabel,
    tooltipMessage,
    defaultColors,
    value,
    error,
    errorText = 'This field is required',
    onChange,
  } = props

  return (
    <FormChildContainer>
      <FormChildTitle>
        <TitleNumber>{fieldNumber}.</TitleNumber>
        <TitleName>{fieldLabel}</TitleName>
        {tooltipMessage && <FieldInformationTooltip title={tooltipMessage} />}
      </FormChildTitle>

      <FormChildInput style={{marginTop: 8}}>
        <ColorPickerField
          defaultColors={defaultColors}
          value={value}
          onChange={onChange}
        />
      </FormChildInput>

      {error && <TextHelper style={{marginBottom: 0}}>{errorText}</TextHelper>}
    </FormChildContainer>
  )
}

export default ColorInputGroup

export const ColorPickerField = props => {
  const {defaultColors = DEFAULT_COLOR_OPTIONS, value, onChange} = props

  // NOTE(intrnl): the `value` property controls what's currently selected, we
  // want to be able to show what the user last picked if they chose the
  // default colors but then wanted to use what they had before.
  const [lastPicked, setLastPicked] = React.useState([])
  const colors = defaultColors.concat(lastPicked)

  // NOTE(intrnl): state resettling
  // https://beta.reactjs.org/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  if (value && value !== lastPicked && !defaultColors.includes(value)) {
    setLastPicked(value)

    // NOTE(intrnl): makes it faster for React to resettle the lastPicked state
    return null
  }

  return (
    <Box
      display="grid"
      gridAutoFlow="column"
      gridAutoColumns={48}
      gridAutoRows={48}
      gridGap={8}
    >
      <PopupState variant="popover" popupId="color-picker">
        {popupState => (
          <>
            <ColorPickButton
              title="Pick a custom color"
              variant="contained"
              {...bindTrigger(popupState)}
            >
              <AddIcon color="secondary" />
            </ColorPickButton>

            <Popover {...bindPopover(popupState)}>
              <ColorPicker value={value} onChange={onChange} />
            </Popover>
          </>
        )}
      </PopupState>

      {colors.map((color, idx) => {
        const selected = color === value

        return (
          <ColorPickButton
            key={idx}
            title={`${color}${selected ? ' (selected)' : ''}`}
            onClick={() => onChange && onChange(color)}
            style={{backgroundColor: color}}
          >
            {selected && <CheckIcon style={{color: getCheckColor(color)}} />}
          </ColorPickButton>
        )
      })}
    </Box>
  )
}

export const ColorPicker = props => {
  const {value, onChange} = props

  // NOTE(intrnl): the color picker is controlled, we don't want to update the
  // parent component every so often, but we still want to allow the color
  // picker to show the right color as you mess around with the color swatch
  const [settled, setSettled] = React.useState(value)
  const [color, setColor] = React.useState(value || '#ffffff')

  if (value && value !== settled) {
    setColor(value)
    setSettled(value)

    return null
  }

  return (
    <ChromePicker
      color={color}
      onChange={color => {
        setColor(color.hex)
      }}
      onChangeComplete={color => {
        setColor(color.hex)

        if (onChange) {
          onChange(color.hex)
        }
      }}
    />
  )
}

const getCheckColor = color => {
  const red = parseInt(color.slice(1, 3), 16)
  const green = parseInt(color.slice(4, 5), 16)
  const blue = parseInt(color.slice(5, 7), 16)

  // #000000 -> 0
  // #ffffff -> 114.11999999999998
  return red * 0.299 + green * 0.587 + blue * 0.114 > 105 ? '#000' : '#fff'
}
