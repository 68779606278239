import gql from 'graphql-tag'

export const GET_EMP_MEMBER = gql`
  query getCompanyMemberPeople($company: uuid, $search: String) {
    total_people: people_work_placements_aggregate(
      where: {
        company: {_eq: $company}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    holding: holding_relations(
      where: {company_member: {_eq: $company}}
      order_by: {id: asc}
    ) {
      id
      holding_category {
        id
        name
      }
      holding_cluster {
        id
        name
      }
      holding_subcluster {
        id
        name
      }
      holding_class {
        id
        name
      }
    }

    company: company_profiles(where: {id: {_eq: $company}}) {
      id
      logo
      brand_name
      legal_name
      field
      global_company_industry_type {
        id
        name
      }
    }

    member: people_work_placements(
      where: {
        company: {_eq: $company}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
      order_by: [{global_user: {name: asc}}]
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`
