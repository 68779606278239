import React, {useState, useEffect} from 'react'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Button,
  TextField,
  InputAdornment,
  Paper,
  Typography,
} from '@material-ui/core'
import {TextBlackBold, FlexBetween} from '../../../../../GlobalStyles'
import {
  TableLocalization,
  TableOptions,
} from '../../../../shared-components/table/TableOptions'
import {GET_FILTERED_POSITION} from '../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../../utils/globals'
import MaterialTable from 'material-table'
import SearchIcon from '@material-ui/icons/Search'
import {AssignFilter} from '@worklifebeyond/wlb-utils-components'
import {getDataVariable, checkNullFilter} from './TalentFIlterList'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import {withRouter} from 'react-router-dom'
import assignFiltersOld from '../../../../../utils/assignFiltersOld'

const useStyles = makeStyles(() => ({
  my20: {
    margin: '20px 0px',
  },
  py20: {
    padding: '20px 0px',
  },
  icRed: {
    color: '#ef4d5e',
    cursor: 'pointer',
  },
  nolistWrapper: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentNoList: {
    textAlign: 'center',
    color: '#a9a8a8 !important',
    width: '30%',
    padding: '10px 0px',
  },
  textCodeBlue: {
    color: '#039be5',
  },
}))

const StepThree = props => {
  const classes = useStyles()
  const {
    values,
    setValues,
    setCandidate,
    setEmployee,
    isError,
    radioVal,
    setRadioVal,
    status,
  } = props
  const {id} = props.match.params

  const [positionSearch, setPositionSearch] = useState('')
  const [isFilter, setIsFilter] = useState(false)
  const [variable, setVariable] = useState(
    getDataVariable(values.filter_position)
  )
  const isEditPerformance = status === 'perf-classification-edit'

  const [skipQueryResult, setSkipQueryResult] = useState(true)
  const {data: resultDataFilter, loading} = useQuery(GET_FILTERED_POSITION, {
    variables: {
      ...variable,
      company: COMPANY_ID,
      search: `%${positionSearch}%`,
    },
    fetchPolicy: 'cache-and-network',
    skip: skipQueryResult,
    onCompleted: data => {
      const talentGroup = data?.company_job_profiles?.map(_data => {
        return {
          job_profile: _data.id,
          committee_email_notif_template: '',
          committee_date_start: values.start_date,
          committee_date_end: values.end_date,
        }
      })
      const talentCandidate = data?.company_job_profiles?.map(_data => {
        return {
          value: _data.id,
          label: _data.title,
          code: _data.code,
        }
      })

      if ((!isEditPerformance && radioVal === 'all') || isFilter) {
        setValues({
          ...values,
          talent_candidate_groups: {data: talentGroup ?? []},
        })
        setEmployee([])
      }
      setCandidate(talentCandidate)
    },
  })

  const isFilterNotNull = checkNullFilter(variable)

  useEffect(() => {
    if ((radioVal === 'custom' && isFilterNotNull) || radioVal === 'all') {
      setSkipQueryResult(false)
    }
    const isFilterEmpty =
      Object.keys(values?.filter_position ?? {}).length === 0

    if (isFilterEmpty && id) {
      setRadioVal('all')
      setSkipQueryResult(false)
    }
  }, [radioVal, isFilterNotNull])

  const selections = React.useMemo(() => {
    const include = {}
    const exclude = {}

    for (const v in variable) {
      const {_in, _nin} = variable[v]

      include[v] = _in
      exclude[v] = _nin
    }

    return {include, exclude}
  }, [variable])

  const handleChangeRadio = event => {
    setRadioVal(event.target.value)
    setVariable(getDataVariable(null))
    setValues({
      ...values,
      filter_position: {},
    })

    setSkipQueryResult(event.target.value === 'custom')
    if (event.target.value === 'custom') {
      setValues({
        ...values,
        filter_position: {include: {}, exclude: {}},
        talent_candidate_groups: {data: []},
      })
      setCandidate([])
    }
  }

  const handleApplyAssign = result => {
    const generateIdFilter = data => {
      const _data = {}

      for (const property in data) {
        const array = data[property]

        _data[property] = array && array.length > 0 ? array : undefined
      }

      return _data
    }

    const include = generateIdFilter(result.include)
    const exclude = generateIdFilter(result.exclude)

    const temp_data = {include, exclude}
    const checkFilter = checkNullFilter(getDataVariable(temp_data))

    setVariable(getDataVariable(checkFilter ? temp_data : null))
    setSkipQueryResult(!checkFilter)
    setValues({
      ...values,
      filter_position: checkFilter ? temp_data : {include: {}, exclude: {}},
      ...(!checkFilter && {talent_candidate_groups: {data: []}}),
    })
    setIsFilter(!!checkFilter)
    setRadioVal('custom')
  }

  return (
    <div>
      <TextBlackBold>Metode untuk menambahkan Jabatan</TextBlackBold>
      <RadioGroup
        row
        name="position"
        value={radioVal}
        onChange={handleChangeRadio}
        className={classes.py20}
      >
        <FormControlLabel
          value="custom"
          control={<Radio color="primary" />}
          label={<TextBlackBold>Kustomisasi Pilihan</TextBlackBold>}
        />
        <FormControlLabel
          value="all"
          control={<Radio color="primary" />}
          label={
            <TextBlackBold>Pilih Semua (izinkan untuk mengubah)</TextBlackBold>
          }
        />
      </RadioGroup>
      <FlexBetween>
        <TextBlackBold>
          Terapkan untuk {resultDataFilter?.total?.aggregate?.count ?? 0}{' '}
          Jabatan
          {values?.talent_candidate_groups?.data?.length === 0 && isError && (
            <span style={{color: 'red', marginLeft: 6, fontStyle: 'italic'}}>
              *Please add position first
            </span>
          )}
        </TextBlackBold>
        <AssignFilter
          filters={assignFiltersOld}
          initialSelections={selections}
          limit={20}
          onApply={handleApplyAssign}
          language="indonesian"
        >
          <Button variant="contained" color="primary" size="large">
            Saring
          </Button>
        </AssignFilter>
      </FlexBetween>
      <TextField
        variant="outlined"
        placeholder="Cari"
        size="small"
        fullWidth
        className={classes.py20}
        onChange={event => setPositionSearch(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <MaterialTable
        columns={[
          {
            title: 'Kode Posisi',
            field: 'code',
            headerStyle: {minWidth: 160, maxWidth: 160},
            cellStyle: {minWidth: 160, maxWidth: 160},
            render: rowData => (
              <Typography className={classes.textCodeBlue}>
                {`[${rowData.code}]`}
              </Typography>
            ),
          },
          {
            title: 'Posisi',
            field: 'title',
            headerStyle: {minWidth: 700, maxWidth: 700},
            cellStyle: {minWidth: 700, maxWidth: 700},
          },
        ]}
        data={resultDataFilter?.company_job_profiles}
        isLoading={loading}
        options={{
          ...TableOptions,
          showTitle: false,
          selection: false,
          emptyRowsWhenPaging: false,
        }}
        components={{
          Toolbar: () => null,
          Container: props => <Paper {...props} elevation={0} />,
        }}
        localization={{
          pagination: {...TableLocalization.pagination},
          body: {
            emptyDataSourceMessage: (
              <NoDataListComponent
                search={positionSearch}
                message={!positionSearch && 'Please add position first'}
              >
                <div>
                  {!positionSearch && (
                    <AssignFilter
                      filters={assignFiltersOld}
                      initialSelections={selections}
                      limit={20}
                      onApply={handleApplyAssign}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.my20}
                        style={{width: 'auto'}}
                      >
                        Add Position
                      </Button>
                    </AssignFilter>
                  )}
                </div>
              </NoDataListComponent>
            ),
          },
        }}
      />
    </div>
  )
}

export default withRouter(StepThree)
