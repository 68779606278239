import {
  GET_OFFICE_LOCATION_FILTER,
  GET_JOB_GRADE_FILTER,
  GET_JOB_LEVEL_FILTER,
  GET_JOB_TITLE_FILTER,
  GET_ORGANIZATION_UNIT_FILTER,
  GET_POSITION_FILTER,
} from '../graphql/queries/filters/searchFilter'

import {createGetVariables, getDataCode} from './assignFilters'

/** @deprecated migrate to `assignFilters` */
const assignFiltersOld = [
  {
    name: 'Unit Organisasi',
    fieldName: 'filterOrgUnit',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_ORGANIZATION_UNIT_FILTER,
        getVariables: createGetVariables(),
        setData: getDataCode,
      },
    },
  },
  {
    name: 'Jenjang Jabatan',
    fieldName: 'filterJobLevel',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_JOB_LEVEL_FILTER,
        getVariables: createGetVariables(),
        setData: getDataCode,
      },
    },
  },
  {
    name: 'Kelas Jabatan',
    fieldName: 'filterJobGrade',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_JOB_GRADE_FILTER,
        getVariables: createGetVariables(),
        setData: getDataCode,
      },
    },
  },
  {
    name: 'Jabatan',
    fieldName: 'filterJobTitle',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_JOB_TITLE_FILTER,
        getVariables: createGetVariables({
          searchKeys: ['name', 'designation_code'],
          status: 'ACTIVE',
        }),
        setData: getDataCode,
      },
    },
  },
  {
    name: 'Posisi',
    fieldName: 'filterPosition',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_POSITION_FILTER,
        getVariables: createGetVariables({searchKeys: ['title', 'code']}),
        setData: getDataCode,
      },
    },
  },
  {
    name: 'Lokasi Kantor',
    fieldName: 'filterOffice',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_OFFICE_LOCATION_FILTER,
        getVariables: createGetVariables({
          searchKeys: ['office_name', 'office_code'],
          status: 'ACTIVE',
        }),
        setData: getDataCode,
      },
    },
  },
]

export default assignFiltersOld
