import React, {useEffect, useState} from 'react'
import {Line} from 'react-chartjs-2'
import {useQuery} from '@apollo/react-hooks'
import Skeleton from '@material-ui/lab/Skeleton'
import {GET_LINE_CHART_DIRECTORY} from '../../../../graphql/queries'
import {WrapperDashboard, WrapperWidgetDashboard} from '../../DashboardStyles'
import WidgetSection from '../../../../components/widgets/WidgetSection'
import {WrapperTitleChart} from './LineChartStyle'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {Button, Popover} from '@material-ui/core'
import {dateEndOf} from '../../../../utils/helpers'

const options = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      borderWidth: 5,
    },
    point: {
      borderWidth: 5,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          stepSize: 10,
        },
      },
    ],
  },
  layout: {
    padding: {
      top: 36,
      right: 30,
      bottom: 36,
      left: 30,
    },
  },
  legend: {
    display: true,
    labels: {
      boxWidth: 13,
      fontColor: '#000',
      fontSize: 12,
      padding: 42,
    },
    position: 'bottom',
  },
  // legend: {
  //   display: false,
  // },
}

const labels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const year = new Date().getFullYear()

export default function LineChartComponent() {
  const [dataChart, setDataChart] = useState({})
  const [option, setOption] = useState('Semua')
  const endOfMonth = dateEndOf(new Date(), 'month', 'YYYY-MM-DD')

  const {data, loading} = useQuery(GET_LINE_CHART_DIRECTORY, {
    wlb_skipPatch: true,
    variables: {
      from: `${year}-01-01`,
      to: endOfMonth,
    },
  })

  useEffect(() => {
    if (data) {
      setDataChart(() => {
        return {
          labels,
          datasets: [
            ...(['Semua', 'Pegawai Aktif'].includes(option)
              ? [
                  {
                    label: 'Pegawai Aktif',
                    data: data.getJoinEmployee.active_employee.map(
                      ({count}) => count
                    ),
                    fill: false,
                    borderColor: '#039be5',
                    tension: 0,
                  },
                ]
              : []),
            ...(['Semua', 'Pegawai Baru'].includes(option)
              ? [
                  {
                    label: 'Pegawai Baru',
                    data: data.getJoinEmployee.join_employee.map(
                      ({count}) => count
                    ),
                    fill: false,
                    borderColor: '#4caf50',
                    tension: 0,
                  },
                ]
              : []),
            ...(['Semua', 'Persentase Pergantian Bulanan'].includes(option)
              ? [
                  {
                    label: 'Persentase Pergantian Bulanan',
                    data: data.getJoinEmployee.turnover.map(({count}) => count),
                    fill: false,
                    borderColor: '#874caf',
                    tension: 0,
                  },
                ]
              : []),
            ...(['Semua', 'Alumni'].includes(option)
              ? [
                  {
                    label: 'Alumni',
                    data: data.getJoinEmployee.alumni.map(({count}) => count),
                    fill: false,
                    borderColor: '#ffa000',
                    tension: 0,
                  },
                ]
              : []),
          ],
        }
      })
    }
  }, [data, setDataChart, option])

  const Title = ({setOption, option}) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleClickItem = opt => {
      handleClose()
      setOption(opt)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
      <>
        <WrapperTitleChart onClick={handleClick} aria-describedby={id}>
          <p className="title">{option}</p>
          <ExpandMoreIcon />
        </WrapperTitleChart>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Button
              style={{display: 'flex', justifyContent: 'flex-start'}}
              onClick={() => handleClickItem('Semua')}
            >
              Semua
            </Button>
            <Button
              style={{display: 'flex', justifyContent: 'flex-start'}}
              onClick={() => handleClickItem('Pegawai Aktif')}
            >
              Pegawai Aktif
            </Button>
            <Button
              style={{display: 'flex', justifyContent: 'flex-start'}}
              onClick={() => handleClickItem('Persentase Pergantian Bulanan')}
            >
              Persentase Pergantian Bulanan
            </Button>
            <Button
              style={{display: 'flex', justifyContent: 'flex-start'}}
              onClick={() => handleClickItem('Pegawai Baru')}
            >
              Pegawai Baru
            </Button>
            <Button
              style={{display: 'flex', justifyContent: 'flex-start'}}
              onClick={() => handleClickItem('Alumni')}
            >
              Alumni
            </Button>
          </div>
        </Popover>
      </>
    )
  }

  return (
    <WrapperDashboard style={{flex: '1'}}>
      <WrapperWidgetDashboard>
        <WidgetSection title={<Title setOption={setOption} option={option} />}>
          <div style={{height: 548}}>
            {!loading && data && <Line data={dataChart} options={options} />}
            {loading && (
              <>
                <Skeleton />
                <Skeleton width="60%" />
              </>
            )}
          </div>
        </WidgetSection>
      </WrapperWidgetDashboard>
    </WrapperDashboard>
  )
}
