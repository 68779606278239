import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import ReportComponent from './report/ReportComponent'
import SummaryComponent from './summary/SummaryComponent'
import VersionDetailListComponent from './list/VersionDetailListComponent'
import VersionListComponent from './list/VersionListComponent'
import {PageContainer} from '../../../GlobalStyles'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'individual':
      return <VersionListComponent searchText={searchText} />
    case 'individual-detail':
      return (
        <VersionDetailListComponent
          searchText={searchText}
          version={state.version}
        />
      )
    case 'summary':
      return (
        <SummaryComponent
          searchText={searchText}
          emp={state.employee}
          version={state.version}
        />
      )
    case 'individual-report':
      return <ReportComponent />
    default:
      return <VersionListComponent searchText={searchText} />
  }
}

const IndividualPage = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }
  // const searchable = props.location.pathname.split('/')[3] ? false : true
  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <Card
      icon="bar_chart"
      title="Individual Performance"
      onSearch={onSearch}
      // searchable={searchable}
    >
      <PageContainer>
        {renderComponent(active, state, searchText)}
      </PageContainer>
    </Card>
  )
}

export default IndividualPage
