import React from 'react'

import {
  Avatar,
  Icon,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import ComponentSelect from '../ComponentSelect'
import {
  GET_EVENT_CATEGORY,
  GET_LIST_EMPLOYEE_COMPANY,
} from '../../../../../../graphql/queries'
import { useQuery } from '@apollo/react-hooks'
import { COMPANY_ID, TOKEN, UPLOAD_URL } from '../../../../../../utils/globals'
import helperStyle, {
  FlexBetween,
  FormChildContainerFull,
  FormChildInput,
  FormChildInputPad15,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
} from '../../../../../../GlobalStyles'
import {
  FlexStart,
  TextBlack,
  TextGrey,
} from '../../../../../organization/OrganizationPageStyles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  DatePickerStyled,
  TextFieldStyled,
  TimePickerStyled,
} from '../../../../../directory/employee/EmployeePageStyles'
import DateFnsUtils from '@date-io/date-fns'
import classNames from 'classnames'
import { MapMarker } from 'mdi-material-ui'
import Select, { components } from 'react-select'
import MapPopup from '../../../../../shared-components/popup/MapPopup'
import { Paper } from '../../../../../../components/paper/PaperStyles'
import _ from 'lodash'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add'
import photo from '../../../../../../assets/images/photo.svg'

import ClearIcon from '@material-ui/icons/Clear'
import ComponentInput from '../ComponentInput'
import { convertToCurrency } from '../../../../../../utils/helpers'

const useStyles = makeStyles(() => ({
  buttonFile: {
    margin: '8px 0px 12px 0px',
    width: '8vw',
    height: '8vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    width: '50%',
    height: '50%',
  },
  iconInfo: {
    marginLeft: 10,
    width: 18,
    height: 18,
  },
  textTooltip: {
    fontSize: 12,
    color: '#000',
    display: 'block',
    margin: '2px 0px',
  },
  textBlue: {
    color: '#014a62',
    fontSize: 12,
    display: 'block',
    margin: '2px 0px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  fileUploadded: {
    backgroundColor: '#eff2f4',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    borderRadius: 5,
  },
  top: {
    display: 'flex',
    flexDirection: 'row ',
  },
  fileName: {
    backgroundColor: '#fff',
    padding: '10px 16px',
    width: '100%',
    margin: '0px 10px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    marginTop: 12,
    marginRight: '3vw',
  },
  size: {
    color: '#000',
    fontSize: 12,
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: '#a9a8a8',
    borderRadius: '50%',
    margin: '0px 10px',
  },
  preview: {
    color: '#039be5',
    fontSize: 12,
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  textError: {
    color: '#f44336',
    margin: '8px 14px 0',
    fontSize: '0.75rem',
    fontWeight: '400',
  },
}))

function FormEvent(props) {
  const { handleChangeInput, value, setValue, error } = props
  const useStylesList = makeStyles(() => ({
    list: { display: 'flex', margin: '10px 0' },
    textGrey: { color: '#a9a8a8' },
    spacer: { marginRight: 10 },
    selectedEmp: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    cardEmp: {
      width: '26.355%',
      margin: 10,
      padding: 9,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #a9a8a8',
      boxShadow: ' 0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
    cardIconFeed: {
      width: '54px',
      height: '54px',
      margin: 10,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
      border: 'solid 1px #a9a8a8',
    },
  }))

  const customSelect = {
    control: provided => ({
      ...provided,
      width: '100%',
      outline: 'none',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: '#055469',
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#fff',
    }),
    multiValueRemove: base => ({
      ...base,
      color: '#ffff',
      '&:hover': { color: '#ffff', backgroundColor: 'rgba(0,0,0,0)' },
    }),
    menu: base => ({
      ...base,
      maxWidth: '100%',
    }),
  }

  const classesUpload = useStyles()
  const classes = helperStyle()
  const classesList = useStylesList()
  const [mapOpen, setMapOpen] = React.useState(false)
  const [selectedRep, setSelectedRep] = React.useState([])

  const [errorSize, setErrorSize] = React.useState(false)
  const [errorFile, setErrorFile] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [fileType, setFileType] = React.useState(null)

  const [errorSizePoster, setErrorSizePoster] = React.useState(false)
  const [errorFilePoster, setErrorFilePoster] = React.useState(false)

  const [valueAttachment, setValueAttachment] = React.useState({
    fileUpload: [],
  })
  const [valuePoster, setValuePoster] = React.useState({
    fileUpload: [],
  })

  // const [switchChecked, setSwitchChecked] = React.useState(false)

  const { data: typeCategory } = useQuery(GET_EVENT_CATEGORY, {
    variables: {
      company: COMPANY_ID,
    },
  })

  const { data: dataEmp, error: errorEmp } = useQuery(GET_LIST_EMPLOYEE_COMPANY, {
    variables: {
      company: COMPANY_ID,
    },
  })

  React.useEffect(() => {
    const poster = []
    const enrollments = []
    let thumbnail = ''
    valuePoster.fileUpload.length >= 1 &&
      valuePoster.fileUpload.map((row, i) => {
        if (i === 0) {
          thumbnail = row.link
        }
        poster.push({
          ['poster-' + (i + 1)]: row.link,
          name: row.name,
        })
      })

    selectedRep &&
      selectedRep.map(row => {
        enrollments.push({
          user: row.id,
          status: 'ACTIVE',
        })
      })
    setValue({
      ...value,
      poster_detail: poster,
      schedule_fields: valueAttachment.fileUpload,
      thumbnail: thumbnail,
      event_enrollments: enrollments,
    })
  }, [valueAttachment, valuePoster, selectedRep])

  if (dataEmp === undefined && errorEmp === undefined) {
    return <CircularProgress />
  } else if (errorEmp !== undefined) {
    return <div>{errorEmp}</div>
  }

  const handleMapOpen = () => {
    mapOpen ? setMapOpen(false) : setMapOpen(true)
  }

  const setClearFile = i => {
    const newState = [...valueAttachment.fileUpload]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValueAttachment({ ...valueAttachment, fileUpload: newState })
    setFileType(null)
  }
  const setClearFilePoster = i => {
    const newState = [...valuePoster.fileUpload]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValuePoster({ ...valuePoster, fileUpload: newState })
  }

  const notifWarning = () => {
    if (value.notif_timer !== '' && value.notif_timer !== '') {
      let notif = 'Weeks'
      if (value.notif_unit_of_time === 1) {
        notif = 'menutes'
      } else if (value.notif_unit_of_time === 2) {
        notif = 'days'
      }
      return (
        <FormChildInputPad15>
          <FlexStart>
            <TextGrey>Notification will received</TextGrey>
            <TextBlack
              style={{ padding: '0px 5px' }}
            >{` ${value.notif_timer} ${notif} `}</TextBlack>
            <TextGrey>before event start</TextGrey>
          </FlexStart>
        </FormChildInputPad15>
      )
    }
  }

  const setAddress = (address, lat, lng) => {
    setValue({
      ...value,
      location_info: {
        ...value.location_info,
        address: address,
        lat: lat,
        lng: lng,
      },
    })
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon>search</Icon>
      </components.DropdownIndicator>
    )
  }

  const handleChangeRep = (val, i) => {
    const dataRep = { idx: i, ...val }
    const isDuplicate = _.some(selectedRep, dataRep)
    if (!isDuplicate) {
      setSelectedRep(oldArray => [...oldArray, dataRep])
      // setSelectedRep(() => [dataRep])
    }
  }

  const handleDel = (idTask, name) => {
    const aryName = selectedRep

    const filter = {
      idx: idTask,
      value: name,
    }
    const wbDel = aryName.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] !== filter[key]) return false
      }
      return true
    })
    const arrAfterDel = aryName.filter(e => e !== wbDel[0])
    setSelectedRep(arrAfterDel)
  }

  const fileChecker = (name, type) => {
    const nameSplited = name.split('.')
    const idx = nameSplited.length - 1
    const res = nameSplited[idx]
    if (type === 'poster') {
      if (
        res === 'jpg' ||
        res === 'jpeg' ||
        res === 'png' ||
        res === 'gif' ||
        res === 'tiff' ||
        res === 'pjp' ||
        res === 'pjpeg' ||
        res === 'jfif' ||
        res === 'tif'
      ) {
        setFileType('image')
        return false
      } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
        setFileType('image')
        return false
      } else {
        return true
      }
    } else {
      if (
        res === 'doc' ||
        res === 'docx' ||
        res === 'xls' ||
        res === 'pdf' ||
        res === 'xlsx' ||
        res === 'ppt'
      ) {
        setFileType('document')
        return false
      } else if (
        res === 'jpg' ||
        res === 'jpeg' ||
        res === 'png' ||
        res === 'gif' ||
        res === 'tiff' ||
        res === 'pjp' ||
        res === 'pjpeg' ||
        res === 'jfif' ||
        res === 'tif'
      ) {
        setFileType('image')
        return false
      } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
        setFileType('image')
        return false
      } else {
        return true
      }
    }
  }

  const handleChangeFile = e => {
    const tempFile = Array.from(e.target.files)

    if (tempFile[0].size > 1024 * 1024 * 25) {
      setErrorSize(true)
      setTimeout(() => {
        setErrorSize(false)
      }, 3000)
    } else if (fileChecker(tempFile[0].name, 'attachment')) {
      setErrorFile(true)
      setTimeout(() => {
        setErrorFile(false)
      }, 3000)
    } else {
      setLoading(true)
      tempFile.map(val => {
        const formData = new FormData()
        formData.append('file', val)
        return axios
          .post(UPLOAD_URL, formData, {
            headers: {
              Authorization: 'Bearer ' + TOKEN,
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          })
          .then(response => {
            setLoading(false)
            setValueAttachment(prevState => {
              return {
                ...valueAttachment,
                fileUpload: [
                  ...prevState.fileUpload,
                  { name: val.name, link: response.data.url, size: val.size },
                ],
              }
            })
          })
          .catch(() => {
            setLoading(false)
          })
      })
      e.target.value = null
    }
  }

  const handleChangePoster = e => {
    const tempFile = Array.from(e.target.files)

    if (tempFile[0].size > 1024 * 1024 * 25) {
      setErrorSizePoster(true)
      setTimeout(() => {
        setErrorSizePoster(false)
      }, 3000)
    } else if (fileChecker(tempFile[0].name, 'poster')) {
      setErrorFilePoster(true)
      setTimeout(() => {
        setErrorFilePoster(false)
      }, 3000)
    } else {
      tempFile.map(val => {
        const formData = new FormData()
        formData.append('file', val)
        return axios
          .post(UPLOAD_URL, formData, {
            headers: {
              Authorization: 'Bearer ' + TOKEN,
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          })
          .then(response => {
            setValuePoster(prevState => {
              return {
                ...valuePoster,
                fileUpload: [
                  ...prevState.fileUpload,
                  { name: val.name, link: response.data.url },
                ],
              }
            })
          })
          .catch(() => { })
      })
      e.target.value = null
    }
  }

  const empMenu = option => {
    return (
      <div className={classesList.list}>
        <div className={classesList.spacer}>
          <Avatar src={option.avatar} alt={option.value}></Avatar>
        </div>
        <div style={{ width: '100%' }}>
          <Typography variant="body2">{option.value}</Typography>
          <Typography variant="body2" className={classesList.textGrey}>
            {/* {option.organization} */}
          </Typography>
          <Typography variant="body2" className={classesList.textGrey}>
            {option.pos}
          </Typography>
        </div>
        <Icon className={classesList.textGrey}>add</Icon>
      </div>
    )
  }
  const emp = []
  console.log(dataEmp)
  dataEmp &&
    dataEmp.people_work_placements.map(row => {
      emp.push({
        id: row.global_user.id,
        value: row.global_user.name,
        avatar: row.global_user.avatar,
        pos: `${row.company_job_profile ? row.company_job_profile.title : ''
          } at ${row.company_profile.brand_name}`,
      })
    })

  const listEmp = emp
  // console.log(value)
  // console.log(selectedRep)
  return (
    <>
      <FormContainer>
        <FormChildContainerFull style={{ width: '100% !important' }}>
          <ComponentSelect
            id="category"
            placeholder="Select category"
            number="2"
            name="Category*"
            error={error && value.category === ''}
            onChange={e => handleChangeInput(e, 'category')}
            value={value.category}
            child={
              typeCategory &&
              typeCategory.event_categories.map((res, i) => {
                return (
                  <MenuItem key={i} value={res.id}>
                    {res.name}
                  </MenuItem>
                )
              })
            }
          />
          <ComponentSelect
            id="type"
            placeholder="Select event type"
            number="3"
            name="Event Type*"
            error={error && value.type === ''}
            onChange={e => handleChangeInput(e, 'type')}
            value={value.type}
            child={
              typeCategory &&
              typeCategory.event_types.map((res, i) => {
                return (
                  <MenuItem key={i} value={res.id}>
                    {res.name}
                  </MenuItem>
                )
              })
            }
          />
        </FormChildContainerFull>
        <FormChildContainerFull>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Time </TitleName>
          </FormChildTitle>
          {/* <FormChildInputPad15>
            <FlexStart>
              <TitleName>All Day</TitleName>
              <FormControlLabel
                style={{paddingLeft:'15px'}}
                  control={
                    <Switch
                      checked={switchChecked}
                      onChange={event => {
                        setSwitchChecked(event.target.checked)
                      }}
                      color="primary"
                    />
                  }
                />
            </FlexStart>
          </FormChildInputPad15> */}
          <FormChildInputPad15>
            <TitleName>Start</TitleName>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FlexStart>
                <DatePickerStyled
                  id="datestart"
                  style={{ fontSize: 14 }}
                  inputVariant="outlined"
                  format="MMMM dd, yyyy"
                  margin="normal"
                  placeholder="Select Date"
                  size="small"
                  value={value.datestart}
                  error={error && !value.datestart}
                  onChange={e => handleChangeInput(e, 'datestart', true)}
                  helperText={
                    error && !value.datestart ? 'This field is required' : null
                  }
                />

                <TimePickerStyled
                  id="datestarttime"
                  style={{ fontSize: 14, paddingLeft: '15px' }}
                  inputVariant="outlined"
                  margin="normal"
                  size="small"
                  value={value.datestarttime}
                  error={error && !value.datestarttime}
                  onChange={e => handleChangeInput(e, 'datestarttime', true)}
                  helperText={
                    error && !value.datestarttime
                      ? 'This field is required'
                      : null
                  }
                />
              </FlexStart>
            </MuiPickersUtilsProvider>
          </FormChildInputPad15>

          <FormChildInputPad15>
            <TitleName>End</TitleName>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FlexStart>
                <DatePickerStyled
                  id="dateend"
                  style={{ fontSize: 14 }}
                  inputVariant="outlined"
                  format="MMMM dd, yyyy"
                  margin="normal"
                  placeholder="Select Date"
                  size="small"
                  value={value.dateend}
                  error={error && !value.dateend}
                  onChange={e => handleChangeInput(e, 'dateend', true)}
                  helperText={
                    error && !value.dateend ? 'This field is required' : null
                  }
                />

                <TimePickerStyled
                  id="dateendtime"
                  style={{ fontSize: 14, paddingLeft: '15px' }}
                  inputVariant="outlined"
                  margin="normal"
                  size="small"
                  value={value.dateendtime}
                  error={error && !value.dateendtime}
                  onChange={e => handleChangeInput(e, 'dateendtime', true)}
                  helperText={
                    error && !value.dateendtime
                      ? 'This field is required'
                      : null
                  }
                />
              </FlexStart>
            </MuiPickersUtilsProvider>
          </FormChildInputPad15>
        </FormChildContainerFull>

        <FormChildContainerFull>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Notification </TitleName>
          </FormChildTitle>
          <FormChildInputPad15>
            <FlexStart>
              <TextFieldStyled
                id="notif_timer"
                fullWidth
                size="small"
                margin="normal"
                variant="outlined"
                error={error}
                value={value.notif_timer}
                helperText={
                  error && !value.notif_timer ? 'This field is required' : null
                }
                onChange={e => handleChangeInput(e, 'notif_timer')}
              />
              <TextFieldStyled
                fullWidth
                id="notif_unit_of_time"
                style={{ fontSize: 14, paddingLeft: '15px' }}
                size="small"
                margin="normal"
                variant="outlined"
                select
                value={value.notif_unit_of_time}
                error={error}
                helperText={error ? 'This field is required' : null}
                onChange={e => handleChangeInput(e, 'notif_unit_of_time')}
              >
                <MenuItem value="default" disabled>
                  Select
                </MenuItem>
                <MenuItem value={1}>Menutes</MenuItem>
                <MenuItem value={2}>Days</MenuItem>
                <MenuItem value={3}>Weeks</MenuItem>
              </TextFieldStyled>
            </FlexStart>
          </FormChildInputPad15>
          {notifWarning()}
        </FormChildContainerFull>

        <FormChildContainerFull>
          <FormChildTitle>
            <TitleNumber id="address">6.</TitleNumber>
            <TitleName>Location*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              id="location_info"
              value={
                value && value.location_info ? value.location_info.address : ''
              }
              className={classNames(
                classes.height39,
                classes.marginTop15,
                classes.helperMarginLeft0
              )}
              error={value && !value.location_info && error}
              helperText={
                value && !value.location_info && error
                  ? 'This field is required'
                  : ''
              }
              variant="outlined"
              disabled={true}
              onClick={handleMapOpen}
              InputProps={{
                className: classes.myInheritText,
                endAdornment: (
                  <InputAdornment className="ml-0" position="end">
                    <MapMarker
                      style={{ color: '#a9a8a8', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </FormChildInput>
        </FormChildContainerFull>
        <FormChildContainerFull>
          <FormChildTitle>
            <TitleNumber>7.</TitleNumber>
            <TitleName>Participant*</TitleName>
            {error && value.event_enrollments.length === 0 && (
              <Typography
                variant="caption"
                color="error"
                style={{ marginLeft: 15 }}
              >
                This field is required
              </Typography>
            )}
          </FormChildTitle>
          <FormChildInput style={{ paddingTop: '20px' }}>
            <Select
              // placeholder="Search..."
              components={{
                DropdownIndicator,
                SingleValue: () => {
                  return 'Search...'
                },
              }}
              id="event_enrollments"
              value={selectedRep}
              getOptionLabel={empMenu}
              options={listEmp}
              onChange={value => handleChangeRep(value, 0)}
              // isMulti
              // isClearable={false}
              styles={customSelect}
            />
          </FormChildInput>
          <FormChildInput style={{ paddingTop: '20px' }}>
            {selectedRep &&
              selectedRep
                .filter(v => v.idx === 0)
                .map((option, i) => {
                  return (
                    <Paper
                      key={i}
                      style={{ padding: '8px 10px', marginTop: '10px' }}
                      elevation={0}
                    >
                      <FlexStart>
                        <Avatar src={option.avatar} alt={option.value}></Avatar>
                        <FlexBetween style={{ paddingLeft: '10px' }}>
                          <div>
                            <Typography variant="body2">
                              {option.value}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.textGrey}
                            >
                              {option.pos}
                            </Typography>
                          </div>
                          <Icon
                            className={classes.textGrey}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDel(option.idx, option.value)}
                          >
                            close
                          </Icon>
                        </FlexBetween>
                      </FlexStart>
                    </Paper>
                  )
                })}
          </FormChildInput>
        </FormChildContainerFull>
        <FormChildContainerFull>
          <FormChildTitle>
            <TitleNumber>8.</TitleNumber>
            <TitleName>Event Poster</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <input
              style={{ visibility: 'hidden' }}
              type="file"
              id="posters"
              name="updateFile"
              onChange={e => handleChangePoster(e)}
              // git
              multiple
            />
            <div style={{ display: 'flex', flexDirection: 'start' }}>
              <label htmlFor="posters">
                <div
                  // style={
                  //   valueAttachment.fileUpload.length > 0
                  //     ? {cursor: 'not-allowed'}
                  //     : null
                  // }
                  className={classesUpload.buttonFile}
                >
                  <AddIcon className={classesUpload.icon} color="primary" />
                </div>
              </label>
              {valuePoster.fileUpload.length > 0
                ? valuePoster.fileUpload.map((res, i) => {
                  return (
                    <label key={i} style={{ marginLeft: '15px' }}>
                      <div
                        className={classesUpload.buttonFile}
                        style={{
                          cursor: 'default ',
                          backgroundImage: `url('${res.link}')`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          alignItems: 'start',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Icon
                          className={classes.textGrey}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setClearFilePoster(i)}
                        >
                          close
                        </Icon>
                      </div>
                    </label>
                  )
                })
                : null}
            </div>

            {error && value.poster_detail.length === 0 ? (
              <Typography className={classesUpload.textError}>
                This field must not be empty
              </Typography>
            ) : null}
            {errorSizePoster ? (
              <Typography className={classesUpload.textError}>
                file size cannot exceed 25MB
              </Typography>
            ) : null}
            {errorFilePoster ? (
              <Typography className={classesUpload.textError}>
                This file type is not permitted
              </Typography>
            ) : null}
          </FormChildInput>
        </FormChildContainerFull>

        <ComponentInput
          placeholder="Add event Add event price"
          id="name"
          number="9"
          // type="number"
          name="Price "
          value={value.pricing ? convertToCurrency(value.pricing) : ''}
          error={error && value.pricing === ''}
          helperText={
            error && value.pricing === '' ? 'This field is required' : null
          }
          onChange={e => handleChangeInput(e, 'pricing')}
        />
        <FormChildContainerFull id="date">
          <FormChildTitle>
            <TitleNumber>10.</TitleNumber>
            <TitleName>Description *</TitleName>
            {error && value.description === '' && (
              <Typography
                variant="caption"
                color="error"
                style={{ marginLeft: 15 }}
              >
                This field is required
              </Typography>
            )}
          </FormChildTitle>
          <FormChildInput style={{ paddingTop: '10px' }}>
            <TextField
              rows="5"
              cols="30"
              multiline
              size="small"
              margin="normal"
              variant="outlined"
              fullWidth
              placeholder="Add event description"
              value={value.description}
              error={error && !value.description}
              onChange={e => handleChangeInput(e, 'description')}
            />
          </FormChildInput>
        </FormChildContainerFull>

        <FormChildContainerFull>
          <FormChildTitle>
            <TitleNumber>11.</TitleNumber>
            <TitleName>Attachment</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <input
              style={{ visibility: 'hidden' }}
              type="file"
              id="atch"
              name="updateFile"
              onChange={e => handleChangeFile(e)}
              // git
              multiple
            />
            <label htmlFor="atch">
              <div
                // style={
                //   valueAttachment.fileUpload.length > 0
                //     ? {cursor: 'not-allowed'}
                //     : null
                // }
                className={classesUpload.buttonFile}
              >
                <AddIcon className={classesUpload.icon} color="primary" />
              </div>
            </label>

            {error && value.schedule_fields.length === 0 ? (
              <Typography className={classesUpload.textError}>
                This field must not be empty
              </Typography>
            ) : null}
            {errorSize ? (
              <Typography className={classesUpload.textError}>
                file size cannot exceed 25MB
              </Typography>
            ) : null}
            {errorFile ? (
              <Typography className={classesUpload.textError}>
                This file type is not permitted
              </Typography>
            ) : null}
            {valueAttachment.fileUpload.length > 0
              ? valueAttachment.fileUpload.map((res, i) => {
                return (
                  <div key={i} className={classesUpload.fileUploadded}>
                    <div className={classesUpload.top}>
                      {/* {fileCheck(res.name)} */}
                      <img
                        src={fileType === 'document' ? document : photo}
                        style={{ marginRight: 6 }}
                        alt="doc"
                      />
                      <div className={classesUpload.fileName}>{res.name}</div>
                      <ClearIcon
                        onClick={() => setClearFile(i)}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div className={classesUpload.bottom}>
                      <Typography className={classesUpload.size}>
                        {res.size / 1000} kB
                      </Typography>
                      <div className={classesUpload.dot}></div>
                      <a
                        href={res.link}
                        rel="noopener noreferrer"
                        target="_blank"
                        className={classesUpload.link}
                      >
                        <Typography className={classesUpload.preview}>
                          Preview
                        </Typography>
                      </a>
                    </div>
                  </div>
                )
              })
              : null}
            {loading ? (
              <div className={classesUpload.fileUploadded}>
                <div className={classesUpload.top}>
                  {/* {fileCheck(res.name)} */}
                  <img
                    src={fileType === 'document' ? document : photo}
                    style={{ marginRight: 6 }}
                    alt="doc"
                  />
                  <div className={classesUpload.fileName}>Uploading data</div>
                  <ClearIcon
                    onClick={() => setClearFile()}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <div className={classesUpload.bottom}>
                  <Typography className={classesUpload.size}></Typography>
                  <div className={classesUpload.dot}></div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className={classesUpload.link}
                  >
                    <Typography className={classesUpload.preview}></Typography>
                  </a>
                </div>
              </div>
            ) : null}
          </FormChildInput>
        </FormChildContainerFull>
      </FormContainer>

      <MapPopup
        handleClose={handleMapOpen}
        open={mapOpen}
        setDataAddress={setAddress}
        addressName={value && value.location_info}
        addressCordinate={value && value.location_info}
      />
    </>
  )
}

export default FormEvent
