import gql from 'graphql-tag'

export const GET_TALENT_CLASSIFICATION = gql`
  query getTalentClassification(
    $company: uuid!
    $search: String
    $offset: Int
    $limit: Int
    $classification_type: String
  ) {
    talent_versions(
      where: {
        company: {_eq: $company}
        name: {_ilike: $search}
        classification_type: {_eq: $classification_type}
      }
      order_by: [{date_added: desc}]
      offset: $offset
      limit: $limit
    ) {
      id
      name
      start_date
      end_date
      status
      visibility
      globalUserAdded {
        id
        name
      }
      globalUserModified {
        id
        name
      }
      date_added
      date_modified
    }
  }
`
export const GET_DETAIL_TALENT_CLASSIFICATION = gql`
  query(
    $version: uuid!
    $age18: date!
    $age24: date!
    $age25: date!
    $age34: date!
    $age35: date!
    $age49: date!
    $age50: date!
  ) {
    total_employee: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talent_version: {_eq: $version}
          badge_classification: {_is_null: false}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    total_departement: company_organizations_aggregate(
      where: {
        company_job_profiles: {
          people_work_placements: {
            talent_assignments: {
              talent_version: {_eq: $version}
              badge_classification: {_is_null: false}
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    total_designation: company_employee_positions_aggregate(
      where: {
        company_job_profiles: {
          people_work_placements: {
            talent_assignments: {
              talent_version: {_eq: $version}
              badge_classification: {_is_null: false}
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    gender_male: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talent_version: {_eq: $version}
          badge_classification: {_is_null: false}
        }
        global_user: {people_profiles: {gender: {_eq: "M"}}}
      }
    ) {
      aggregate {
        count
      }
    }

    gender_female: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talent_version: {_eq: $version}
          badge_classification: {_is_null: false}
        }
        global_user: {people_profiles: {gender: {_eq: "F"}}}
      }
    ) {
      aggregate {
        count
      }
    }

    age1824: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talent_version: {_eq: $version}
          badge_classification: {_is_null: false}
        }
        global_user: {
          people_profiles: {birthdate: {_lte: $age18, _gte: $age24}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    age2534: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talent_version: {_eq: $version}
          badge_classification: {_is_null: false}
        }
        global_user: {
          people_profiles: {birthdate: {_lte: $age25, _gte: $age34}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    age3549: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talent_version: {_eq: $version}
          badge_classification: {_is_null: false}
        }
        global_user: {
          people_profiles: {birthdate: {_lte: $age35, _gte: $age49}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    age50: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talent_version: {_eq: $version}
          badge_classification: {_is_null: false}
        }
        global_user: {people_profiles: {birthdate: {_lte: $age50}}}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
export const GET_TALENT_DASHBOARD_INFORMATION = gql`
  query(
    $version: uuid
    $organization: Int
    $office: Int
    $position: Int
    $jobProfile: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talentVersionByTalentVersion: {id: {_eq: $version}}
        }
        company_job_profile: {
          id: {_eq: $jobProfile}
          organization: {_eq: $organization}
          office_address: {_eq: $office}
          position: {_eq: $position}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    impact_high: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talentVersionByTalentVersion: {id: {_eq: $version}}
        }
        company_job_profile: {
          organization: {_eq: $organization}
          office_address: {_eq: $office}
          position: {_eq: $position}
        }
        placement_fields: {
          _contains: {talent_information: {impact_of_loss: "HIGH"}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    impact_medium: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talentVersionByTalentVersion: {id: {_eq: $version}}
        }
        company_job_profile: {
          organization: {_eq: $organization}
          office_address: {_eq: $office}
          position: {_eq: $position}
        }
        placement_fields: {
          _contains: {talent_information: {impact_of_loss: "MEDIUM"}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    impact_low: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talentVersionByTalentVersion: {id: {_eq: $version}}
        }
        company_job_profile: {
          organization: {_eq: $organization}
          office_address: {_eq: $office}
          position: {_eq: $position}
        }
        placement_fields: {
          _contains: {talent_information: {impact_of_loss: "LOW"}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    risk_high: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talentVersionByTalentVersion: {id: {_eq: $version}}
        }
        company_job_profile: {
          organization: {_eq: $organization}
          office_address: {_eq: $office}
          position: {_eq: $position}
        }
        placement_fields: {
          _contains: {talent_information: {risk_of_loss: "HIGH"}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    risk_medium: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talentVersionByTalentVersion: {id: {_eq: $version}}
        }
        company_job_profile: {
          organization: {_eq: $organization}
          office_address: {_eq: $office}
          position: {_eq: $position}
        }
        placement_fields: {
          _contains: {talent_information: {risk_of_loss: "MEDIUM"}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    risk_low: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talentVersionByTalentVersion: {id: {_eq: $version}}
        }
        company_job_profile: {
          organization: {_eq: $organization}
          office_address: {_eq: $office}
          position: {_eq: $position}
        }
        placement_fields: {
          _contains: {talent_information: {risk_of_loss: "LOW"}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    future_yes: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talentVersionByTalentVersion: {id: {_eq: $version}}
        }
        company_job_profile: {
          organization: {_eq: $organization}
          office_address: {_eq: $office}
          position: {_eq: $position}
        }
        placement_fields: {
          _contains: {talent_information: {future_leader: true}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    future_no: people_work_placements_aggregate(
      where: {
        talent_assignments: {
          talentVersionByTalentVersion: {id: {_eq: $version}}
        }
        company_job_profile: {
          organization: {_eq: $organization}
          office_address: {_eq: $office}
          position: {_eq: $position}
        }
        placement_fields: {
          _contains: {talent_information: {future_leader: false}}
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
export const GET_ASPIRATION_DASHBOARD = gql`
  query(
    $version: uuid
    $organization: Int
    $office: Int
    $position: Int
    $jobProfile: Int
  ) {
    company_job_profiles(
      where: {
        people_work_placements: {
          talent_assignments: {
            talent_version: {_eq: $version}
            people_work_placement: {
              company_job_profile: {id: {_eq: $jobProfile}}
            }
          }
        }
      }
    ) {
      id
      title
      code
      people_work_placements_aggregate(
        where: {talent_assignments: {talent_version: {_eq: $version}}}
      ) {
        aggregate {
          count
        }
      }
      people_work_placements(
        where: {
          talent_assignments: {talent_version: {_eq: $version}}
          talent_aspirations: {id: {_is_null: false}}
        }
      ) {
        talent_aspirations(limit: 1, order_by: [{id: desc}]) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`

export const GET_COMPETENCY_DASHBOARD = gql`
  query($version: uuid, $organization: Int, $office: Int, $position: Int) {
    people_work_placements(
      where: {
        talent_assignments: {
          talentVersionByTalentVersion: {id: {_eq: $version}}
        }
        company_job_profile: {
          organization: {_eq: $organization}
          office_address: {_eq: $office}
          position: {_eq: $position}
        }
      }
    ) {
      id
      global_user {
        id
        competency_individuals(
          where: {
            competency_version_dictionary: {
              competency_version: {status: {_eq: "ACTIVE"}}
            }
          }
        ) {
          id
          competency_version_dictionary {
            competency_dictionary {
              dictionary_id
              name
            }
            competency_model_items(limit: 1) {
              level
            }
          }
          level
        }
      }
    }
  }
`
export const GET_LIST_TALENT_LIST = gql`
  query(
    $version: uuid
    $jobProfile: Int
    $organization: Int
    $office: Int
    $position: Int
    $rank: Int
    $badge: Int
  ) {
    talent_assignments(
      where: {
        talent_version: {_eq: $version}
        people_work_placement: {
          company_job_profile: {
            id: {_eq: $jobProfile}
            organization: {_eq: $organization}
            office_address: {_eq: $office}
            position: {_eq: $position}
            rank: {_eq: $rank}
          }
        }
        badge_classification: {_is_null: false, _eq: $badge}
      }
      order_by: [{talent_score: desc}]
    ) {
      id
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          company_employee_position {
            id
            name
          }
        }
        placement_fields
      }
      talent_box {
        id
        name
        badge
      }
      talent_score
      date_modified
      indicator_perf_nps
      indicator_perf_ogf
      indicator_perf_goal
      indicator_perf_multirater
      indicator_pot_attendance
      indicator_pot_competence
      indicator_pot_experience
      indicator_pot_socmed_post
      indicator_pot_total_course
      talentVersionByTalentVersion {
        id
        flexy_components
      }
    }
  }
`

export const GET_TALENT_BOXES = gql`
  query(
    $version: uuid
    $jobProfile: Int
    $organization: Int
    $office: Int
    $position: Int
    $rank: Int
  ) {
    talent_versions(where: {id: {_eq: $version}}) {
      id
      name
      box_width
      box_height
      # indicator_perf_nps
      # indicator_perf_ogf
      # indicator_perf_goal
      # indicator_perf_multirater
      # indicator_pot_attendance
      # indicator_pot_competence
      # indicator_pot_socmed_post
      # indicator_pot_total_course

      # indicator_perf_nps_fields
      # indicator_perf_ogf_fields
      # indicator_perf_goal_fields
      # indicator_perf_multirater_fields
      # indicator_pot_attendance_fields
      # indicator_pot_competence_fields
      # indicator_pot_socmed_post_fields
      # indicator_pot_total_course_fields
      flexy_components
      talent_boxes(order_by: [{index_y: desc}]) {
        id
        name
        badge
        color_hex
        index_x
        index_y
        talent_assignments_aggregate(
          where: {
            people_work_placement: {
              company_job_profile: {
                id: {_eq: $jobProfile}
                organization: {_eq: $organization}
                office_address: {_eq: $office}
                position: {_eq: $position}
                rank: {_eq: $rank}
              }
            }
            badge_classification: {_is_null: false}
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`

export const GET_TALENT_CLASS_ID = gql`
  query($version: uuid) {
    talent_versions(where: {id: {_eq: $version}}) {
      id
      name
      box_height
      box_width
      status
      filter_position
      globalUserAdded {
        id
        name
      }
      globalUserModified {
        id
        name
      }
      date_added
      date_modified
      start_date
      end_date
      # min_pot
      # max_pot
      # min_perf
      # max_perf
      # indicator_perf_goal
      # indicator_perf_ogf
      # indicator_perf_nps
      # indicator_perf_multirater
      # indicator_pot_attendance
      # indicator_pot_competence
      # indicator_pot_experience
      # indicator_pot_total_course
      # indicator_pot_socmed_post
      # indicator_perf_goal_fields
      # indicator_perf_nps_fields
      # indicator_perf_ogf_fields
      # indicator_perf_multirater_fields
      # indicator_pot_attendance_fields
      # indicator_pot_competence_fields
      # indicator_pot_experience_fields
      # indicator_pot_total_course_fields
      # indicator_pot_socmed_post_fields
      talent_boxes(order_by: {id: asc}) {
        id
        badge
        color_hex
        name
        index_x
        index_y
        description
        level
      }
      talent_candidate_groups {
        id
        committee_email_notif_template
        committee_date_start
        committee_date_end
        job_profile
        talent_committees {
          id
          committee
          is_pic
          status
        }
      }
      flexy_components
    }
  }
`

export const GET_TALENT_CLASS_COMMITTEE = gql`
  query(
    $version: uuid
    $group: Int
    $search: String
    $sort: order_by
    $offset: Int
    $limit: Int
  ) {
    total: talent_committees_aggregate(
      where: {
        talent_candidate_group: {version: {_eq: $version}, id: {_eq: $group}}
        global_user: {name: {_ilike: $search}}
      }
    ) {
      aggregate {
        count
      }
    }
    talent_committees(
      where: {
        talent_candidate_group: {version: {_eq: $version}, id: {_eq: $group}}
        global_user: {name: {_ilike: $search}}
      }
      offset: $offset
      limit: $limit
      order_by: [{global_user: {name: $sort}}]
    ) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(order_by: [{id: desc}], limit: 1) {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`

export const GET_LIST_PARTICIPANT = gql`
  query($version: uuid, $search: String, $offset: Int, $limit: Int) {
    total: talent_candidate_groups_aggregate(
      where: {
        version: {_eq: $version}
        company_job_profile: {
          _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    talent_candidate_groups(
      where: {
        version: {_eq: $version}
        company_job_profile: {
          _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      company_job_profile {
        id
        code
        title
      }
      committee_date_start
      committee_date_end
    }
  }
`

export const GET_DETAIL_PARTICIPANT = gql`
  query($id: Int) {
    talent_candidate_groups(where: {id: {_eq: $id}}) {
      id
      company_job_profile {
        id
        code
        title
      }
      committee_date_start
      committee_date_end
      pic: talent_committees(where: {is_pic: {_eq: true}}) {
        id
        global_user {
          id
          name
          avatar
          people_work_placements(order_by: [{id: desc}], limit: 1) {
            id
            regno
            company_job_profile {
              id
              title
            }
          }
        }
      }
      committee: talent_committees(where: {is_pic: {_eq: false}}) {
        id
        global_user {
          id
          name
          avatar
          people_work_placements(order_by: [{id: desc}], limit: 1) {
            id
            regno
            company_job_profile {
              id
              title
            }
          }
        }
      }
    }
  }
`
