import React, { useState, useContext } from 'react'
import helperStyle, { OrganizationContainer } from '../../OrganizationPageStyles'
import {
  Icon,
  IconButton,
  Divider,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  withStyles,
  CircularProgress,
} from '@material-ui/core'
import {
  PaperContainer,
  AddEditTitle,
  FormToolbar,
  FormTitleContainer,
} from '../../../../GlobalStyles'
import { DataContext } from '../../../../ContextConfig'
import { Link, withRouter } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  ADD_ALL_JOB_PROFILE,
  EDIT_ALL_JOB_PROFILE,
  SET_NEW_PARENT_POSITION,
} from '../../../../graphql/mutations'
import {
  // LIST_ALL_PROFILE,
  GET_DETAIL_JOB_PROFILE,
  CHECK_CODE_POSITION,
} from '../../../../graphql/queries'
import { useSnackbar } from 'notistack'
import { currencyToNormal } from '../../../../utils/helpers'

import TabJobProfile from './add-job-profile/TabJobProfile'
import TabTalent from './add-job-profile/TabTalent'
import TabWorkPolicies from './add-job-profile/TabWorkPolicies'
import AddEditConfirm from '../../../shared-components/popup/AddEditPopup'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import { TOKEN, UPLOAD_URL, COMPANY_ID } from '../../../../utils/globals'
import Axios from 'axios'

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const AddJobProfile = props => {
  const classes = helperStyle()
  const { enqueueSnackbar } = useSnackbar()
  const {
    id,
    jobName,
    status,
    // idEdit,
    // data,
    dataEdit,
    parentName,
    // dataCheck,
    parentChecker,
    idParentOUJobProfile,
    idParentTopOU,
    menu,
  } = props
  const { dataContext, setDataContext } = useContext(DataContext)
  const [activeStep, setActiveStep] = useState(0)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(0)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [dialogCancel, setDialogCancel] = useState(false)
  const [isParent, setIsParent] = useState(false)
  const [variable, setVariable] = useState({})
  const [valueAttachment, setValueAttachment] = useState([])

  const getSteps = () => {
    return ['Posisi', 'Talenta', 'Pengaturan Tambahan']
  }

  const idCom =
    dataContext &&
    dataContext.IdCompetency &&
    dataContext.IdCompetency.map(data => ({
      competency: data.competency,
      dictionary: data.dictionary,
      level: 1,
    }))

  const idEdu =
    dataContext &&
    dataContext.IdEducation &&
    dataContext.IdEducation.map(data => ({
      degree: data.degree,
      major: data.major,
    }))

  const idCourse =
    dataContext && dataContext.IdCourse
      ? dataContext.IdCourse.map(data => ({
        course: data.course,
      }))
      : []

  const idKpi =
    dataContext &&
    dataContext.IdKpi &&
    dataContext.IdKpi.map(data => ({
      kpi: data.kpi,
    }))

  // const idWorkSch =
  //   dataContext &&
  //   dataContext.IdWorkSchedule &&
  //   dataContext.IdWorkSchedule.map(data => ({
  //     policy: data.policy,
  //   }))

  // const idLeavePolicy =
  //   dataContext &&
  //   dataContext.IdLeavePolicy &&
  //   dataContext.IdLeavePolicy.map(data => ({
  //     policy: data.policy,
  //   }))

  // const idTimePolicy =
  //   dataContext &&
  //   dataContext.IdTimePolicy &&
  //   dataContext.IdTimePolicy.map(data => ({
  //     policy: data.policy,
  //   }))

  // const idAllowancePolicy =
  //   dataContext &&
  //   dataContext.IdAllowancePolicy &&
  //   dataContext.IdAllowancePolicy.map(data => ({
  //     policy: data.policy,
  //   }))

  // const idLoanPolicy =
  //   dataContext &&
  //   dataContext.IdLoanPolicy &&
  //   dataContext.IdLoanPolicy.map(data => ({
  //     policy: data.policy,
  //   }))

  // const idCashAdvPolicy =
  //   dataContext &&
  //   dataContext.IdCashAdvPolicy &&
  //   dataContext.IdCashAdvPolicy.map(data => ({
  //     policy: data.policy,
  //   }))

  // const idDigitalDoc =
  //   dataContext &&
  //   dataContext.IdDigitalDoc &&
  //   dataContext.IdDigitalDoc.map(data => ({
  //     document: data.document,
  //   }))

  // const idFacility =
  //   dataContext && dataContext.IdFacility
  //     ? dataContext.IdFacility.map(data => ({
  //         facility: data.facility,
  //       }))
  //     : null

  const [saveData] = useMutation(ADD_ALL_JOB_PROFILE)

  const [editData] = useMutation(EDIT_ALL_JOB_PROFILE)

  const [EditNewParent] = useMutation(SET_NEW_PARENT_POSITION)

  // const {
  //   data: parent,
  //   loading: parentLoad,
  //   error: parentError,
  //   refetch,
  // } = useQuery(LIST_ALL_PROFILE)
  const {
    data: dataDetailPosition,
    loading: loadingDetailPosition,
    error: errorDetailPosition,
  } = useQuery(GET_DETAIL_JOB_PROFILE, {
    fetchPolicy: 'network-only',
    variables: { id: dataEdit && dataEdit.id },
    skip: status === 'Add',
  })

  const { data: checkCode, error: checkCodeError } = useQuery(
    CHECK_CODE_POSITION,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        company: COMPANY_ID,
        code: dataContext ? dataContext.code : ' ',
      },
    }
  )

  if (
    (checkCode === undefined && checkCodeError === undefined) ||
    loadingDetailPosition
  ) {
    return <CircularProgress />
  } else if (
    checkCodeError !== undefined ||
    errorDetailPosition !== undefined
  ) {
    return (
      <div>
        {checkCodeError}
        {errorDetailPosition}
      </div>
    )
  }

  const _dataDetailPosition =
    dataDetailPosition &&
    dataDetailPosition.company_job_profiles &&
    dataDetailPosition.company_job_profiles[0]

  const handleMutation = async () => {
    const variables = {
      code: dataContext && dataContext.code,
      title: dataContext && dataContext.title,
      icon: dataContext && dataContext.icon,
      parent: dataContext && dataContext.parent,
      organization: dataContext && dataContext.organization,
      position: dataContext && dataContext.position,
      rank: dataContext && dataContext.rank,
      office: dataContext && dataContext.office,
      jobdesc: dataContext && dataContext.jobdesc,
      jobresp: dataContext && dataContext.jobresp,
      salary_from: dataContext && currencyToNormal(dataContext.salary_from),
      salary_to: dataContext && currencyToNormal(dataContext.salary_to),
      slots: dataContext && dataContext.slot ? parseInt(dataContext.slot) : 0,
      competency_model: idCom,
      education: idEdu,
      course: idCourse,
      kpi: idKpi,
      // work_schedule: idWorkSch,
      // leave: idLeavePolicy,
      // overtime: idTimePolicy,
      // allowance: idAllowancePolicy,
      // loan: idLoanPolicy,
      // cash_adv: idCashAdvPolicy,
      // documents: idDigitalDoc,
      // facilities: idFacility,
      roles: dataContext && dataContext.IdAccessRight,
      job_grade_id: dataContext && dataContext.grade,
      require_successor: dataContext && dataContext.require_successor,
      acting_as: dataContext && dataContext.acting_as,
      attachment: null,
      as_assistant: dataContext?.asAssistant,
      line_type: dataContext?.lineType,
    }
    const varForEdit = {
      id: _dataDetailPosition && _dataDetailPosition.id,
      code: dataContext && dataContext.code,
      title: dataContext && dataContext.title,
      icon: dataContext && dataContext.icon,
      parent: dataContext && dataContext.parent,
      organization: dataContext && dataContext.organization,
      position: dataContext && dataContext.position,
      rank: dataContext && dataContext.rank,
      office: dataContext && dataContext.office,
      jobdesc: dataContext && dataContext.jobdesc,
      jobresp: dataContext && dataContext.jobresp,
      salary_from: dataContext && currencyToNormal(dataContext.salary_from),
      salary_to: dataContext && currencyToNormal(dataContext.salary_to),
      slots: dataContext && dataContext.slot ? parseInt(dataContext.slot) : 0,
      competency_model: dataContext && dataContext.IdCompetency,
      education: dataContext && dataContext.IdEducation,
      course: dataContext && dataContext.IdCourse,
      kpi: dataContext && dataContext.IdKpi,
      roles: dataContext && dataContext.IdAccessRight,
      job_grade_id: dataContext && dataContext.grade,
      require_successor: dataContext && dataContext.require_successor,
      acting_as: dataContext && dataContext.acting_as,
      attachment: null,
      as_assistant: dataContext?.asAssistant,
      line_type: dataContext?.lineType,
    }

    if (valueAttachment.length > 0) {
      if (status === 'Add') {
        variables.attachment = valueAttachment.map(res => ({
          name: res.name,
          link: res.link,
          size: res.size,
        }))
      } else {
        varForEdit.attachment = valueAttachment.map(res => ({
          name: res.name,
          link: res.link,
          size: res.size,
        }))
      }
      for (let i = 0; i < valueAttachment.length; i++) {
        if (valueAttachment[i].filesTarget) {
          const formData = new FormData()
          formData.append('file', valueAttachment[i].filesTarget)
          const resImg = await Axios.post(UPLOAD_URL, formData, {
            withCredentials: true,
            onUploadProgress: progressEvent => {
              const loads = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              )
              setLoading(loads)
            },

            headers: {
              Authorization: 'Bearer ' + TOKEN,
              'Content-Type': 'multipart/form-data',
            },
          })
          if (status === 'Add') {
            variables.attachment[i].link = resImg.data.url
          } else {
            varForEdit.attachment[i].link = resImg.data.url
          }
        }
      }
    }
    if (status === 'Add') {
      saveData({
        variables,
      })
        .then(() => {
          enqueueSnackbar('Position Added', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          props.history.push(goBack)
        })
        .catch(() => {
          enqueueSnackbar('Add Position failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else {
      EditNewParent({ variables: variable })
        .then(() => {
          editData({
            variables: varForEdit,
          })
            .then(() => {
              enqueueSnackbar('Position Edited', {
                variant: 'success',
                autoHideDuration: 1000,
              })
              props.history.push(goBack)
            })
            .catch(() => {
              enqueueSnackbar('Edit Position failed, please try again later', {
                variant: 'error',
                autoHideDuration: 1000,
              })
            })
        })
        .catch(() => {
          enqueueSnackbar('Set New Parent failed, please try again later', {
            variant: 'error',
            autoHideDuration: 500,
          })
        })
    }
  }

  const openDialogConfirm = () => {
    dialogConfirm ? setDialogConfirm(false) : setDialogConfirm(true)
  }

  const goBack = jobName
    ? {
      pathname: '/organization/position/detail',
      state: {
        active: 'detail-position',
        jobName: jobName,
        id: id,
        parentChecker: parentChecker,
      },
    }
    : {
      pathname: '/organization/position',
      state: { active: 'position', searchable: true },
    }

  const openDialogCancel = status => {
    dialogCancel ? setDialogCancel(false) : setDialogCancel(true)
    if (dialogCancel && status !== 'cancel') {
      props.history.push(goBack)
      clearUseContext()
    }
  }

  const steps = getSteps()

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <TabJobProfile
            jobName={jobName}
            id={id}
            status={status}
            allDatas={dataContext}
            setAllData={setDataContext}
            dataEdit={_dataDetailPosition}
            // checkSameNameWithAdd={checkSameNameWithAdd}
            checkSameCodeWithAdd={checkSameCodeWithAdd}
            isError={isError}
            parentName={parentName}
            isParent={isParent}
            setIsParent={setIsParent}
            // nameChecker={nameChecker}
            codeChecker={codeChecker}
            parentChecker={parentChecker}
            idParentOUJobProfile={idParentOUJobProfile}
            idParentTopOU={idParentTopOU}
            setVariable={setVariable}
            menu={menu}
            valueAttachment={valueAttachment}
            setValueAttachment={setValueAttachment}
          />
        )
      case 1:
        return (
          <TabTalent
            idJob={id}
            allDatas={dataContext}
            status={status}
            setAllData={setDataContext}
            dataEdit={_dataDetailPosition}
            isError={isError}
          />
        )
      case 2:
        return (
          <TabWorkPolicies
            allDatas={dataContext}
            setAllData={setDataContext}
            jobName={jobName}
            status={status}
            id={id}
            dataEdit={_dataDetailPosition}
            isError={isError}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  // const nameChecker =
  //   parent &&
  //   parent.company_job_profiles &&
  //   parent.company_job_profiles.some(
  //     v =>
  //       v.title.toLowerCase() ===
  //       (dataContext && dataContext.title.toLowerCase())
  //   )

  // const codeChecker =
  //   parent &&
  //   parent.company_job_profiles &&
  //   parent.company_job_profiles.some(v => {
  //     if (v && v.code && dataContext && dataContext.code) {
  //       return v.code.toLowerCase() === dataContext.code.toLowerCase()
  //     }
  //   })
  const codeChecker =
    checkCode &&
    checkCode.company_job_profiles_aggregate &&
    checkCode.company_job_profiles_aggregate.aggregate &&
    checkCode.company_job_profiles_aggregate.aggregate.count > 0

  // const checkSameNameWithAdd =
  //   (dataEdit && dataEdit.title) === (dataContext && dataContext.title)

  const checkSameCodeWithAdd =
    dataContext && dataContext.code && dataContext.code.length >= 1
      ? (_dataDetailPosition && _dataDetailPosition.code) ===
      (dataContext && dataContext.code)
      : false

  const handleNext = () => {
    if (activeStep === 0) {
      if (
        !dataContext.code ||
        dataContext.code.length > 30 ||
        !dataContext.title ||
        dataContext.title.length > 150 ||
        // !dataContext.icon ||
        (!isParent && !dataContext.parent) ||
        !dataContext.organization ||
        !dataContext.position ||
        !dataContext.rank ||
        !dataContext.office ||
        // !dataContext.grade ||
        // (!dataContext.require_successor &&
        //   dataContext.require_successor !== false) ||
        // !dataContext.jobdesc ||
        // !dataContext.jobresp ||
        // (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
        (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
      ) {
        setIsError(true)
        setTimeout(() => setIsError(false), 2000)
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    } else if (activeStep === 1) {
      if (
        // (dataContext && dataContext.IdCompetency
        //   ? dataContext.IdCompetency.length === 0
        //   : true) ||
        // !dataContext.salary_from ||
        // !dataContext.salary_to ||
        dataContext.slot === 0 ||
        !dataContext.slot
        // (dataContext && dataContext.IdEducation
        //   ? dataContext.IdEducation.length === 0
        //   : true)
      ) {
        setIsError(true)
        setTimeout(() => setIsError(false), 2000)
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    } else if (activeStep === 2) {
      if (
        // (dataContext && dataContext.IdKpi
        //   ? dataContext.IdKpi.length === 0
        //   : true) ||
        // (dataContext && dataContext.IdWorkSchedule
        //   ? dataContext.IdWorkSchedule.length === 0
        //   : true) ||
        // (dataContext && dataContext.IdLeavePolicy
        //   ? dataContext.IdLeavePolicy.length === 0
        //   : true) ||
        // (dataContext && dataContext.IdTimePolicy
        //   ? dataContext.IdTimePolicy.length === 0
        //   : true) ||
        // (dataContext && dataContext.IdAllowancePolicy
        //   ? dataContext.IdAllowancePolicy.length === 0
        //   : true) ||
        // (dataContext && dataContext.IdLoanPolicy
        //   ? dataContext.IdLoanPolicy.length === 0
        //   : true) ||
        // (dataContext && dataContext.IdCashAdvPolicy
        //   ? dataContext.IdCashAdvPolicy.length === 0
        //   : true) ||
        // (dataContext && dataContext.IdDigitalDoc
        //   ? dataContext.IdDigitalDoc.length === 0
        //   : true) ||
        typeof (dataContext && dataContext.IdAccessRight) === 'undefined' ||
        dataContext.IdAccessRight.length === 0
      ) {
        setIsError(true)
        setTimeout(() => setIsError(false), 2000)
      } else {
        openDialogConfirm()
      }
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const clearUseContext = () => {
    setDataContext(null)
  }

  return (
    <OrganizationContainer className={classes.root}>
      <PaperContainer className={classes.notViewScroll}>
        <FormToolbar disableGutters>
          <FormTitleContainer>
            <Link to={goBack} style={{ textDecoration: 'none' }}>
              <IconButton
                // onClick={clearUseContext}
                edge="end"
                aria-label="back"
              >
                <Icon>arrow_back</Icon>
              </IconButton>
            </Link>
            <AddEditTitle variant="h6">{`${status === 'Add' ? 'Tambah' : 'Ubah'
              } Posisi`}</AddEditTitle>
          </FormTitleContainer>
          <div>
            {activeStep === 0 ? (
              <Button
                size="large"
                className={classes.backButton}
                onClick={openDialogCancel}
              >
                Batal
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.backButton}
                onClick={handleBack}
              >
                Sebelumnya
              </Button>
            )}
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? 'Simpan' : 'Selanjutnya'}
            </Button>
          </div>
        </FormToolbar>
        <Divider />

        {loading > 0 || loading < 100 ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress variant="static" value={loading} />
          </div>
        ) : (
          ''
        )}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomConnector />}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                classes={{
                  active: classes.labelActive,
                  completed: classes.labelActive,
                }}
                StepIconProps={{
                  classes: {
                    root: classes.iconRoot,
                    text: classes.iconText,
                    active: classes.iconActive,
                    completed: classes.iconActive,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <div style={{ padding: '2rem' }}>{getStepContent(activeStep)}</div>
      </PaperContainer>
      <AddEditConfirm
        open={dialogConfirm}
        handleClose={openDialogConfirm}
        type={status}
        name={_dataDetailPosition && _dataDetailPosition.title}
        feature="Position"
        mutation={handleMutation}
      />
      <AlertDialog
        open={dialogCancel}
        handleClose={openDialogCancel}
        feature={`Batal Untuk ${_dataDetailPosition ? 'Mengubah' : 'Menambahkan'
          } Posisi?`}
        message={`Anda memiliki perubahan yang belum disimpan. Yakin ingin membatalkan ${_dataDetailPosition
            ? `perubahan ${_dataDetailPosition.title}`
            : 'penambahan posisi baru'
          }?`}
        positiveTitleButton="Ya, Batalkan"
        type="Yes, Cancel"
        status="notNow"
      />
    </OrganizationContainer>
  )
}

export default withRouter(AddJobProfile)
