import React, {useState, useLayoutEffect} from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {Magnify} from 'mdi-material-ui'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import classNames from 'classnames'
import MaterialTable from 'material-table'

import helperStyle, {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FlexBetween,
  RedIcon,
  TextBlackBold,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import {
  CenterWrapper,
  TableOptions,
  pageSizeOption,
} from '../../PayrollPageStyles'
import PayrollSettingsWrapper from '../PayrollSettingsWrapper'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import FilterAssignee from '../../../../shared-components/popup/FilterAssignee'
import FieldInformationTooltip from '../../../../shared-components/tooltip/FieldInformationTooltip'

import {
  checkStringInput,
  inputRequiredHelperText,
  onChangeNumber,
  // onChanger,
  stringHelperText,
} from './formValidation.helper'

import {
  COMPANY_ID,
  // USER_ID
} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'

import {
  GET_BPJS_SETTINGS,
  GET_LIST_BPJSKES_EXCLUSION_EMPLOYEE,
} from '../../../../../graphql/queries'
import {
  UPDATE_BPJS_SETTING,
  DELETE_BPJSKES_EMPLOYEE_EXCLUSION,
  ADD_BPJSKES_EMPLOYEE_EXCLUSION,
} from '../../../../../graphql/mutations'

// const calculationBase = [
//   {value: 1, label: 'Basic Salary'},
//   {value: 2, label: 'Basic Salary + Component'},
// ]

const informationText = {
  BPJSCompID: `BPJSKes Company Number`,
  percentagePaidComp: `What percentage of BPJSKes is paid by the company`,
  percentagePaidEmployee: `What percentage of BPJSKes is paid by the employee`,
  // multiplierBase: (
  //   <>
  //     <div>1. Basic Salary : calculate base from basic salary</div>
  //     <div>
  //       2. Basic Salary + Component : calculate base from basic salary and
  //       allowances
  //     </div>
  //   </>
  // ),
  rulesEffectiveDate: `The date when the new configuration will be effective`,
  employeeExclusion: `Insurance Exclusion for those already covered by other family member or those who have not completed the government administration`,
}

const BPJSKesPayrollSetting = props => {
  const {activeComponent, setActiveComponent, generalSettingData} = props
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()

  const [updateBPJS] = useMutation(UPDATE_BPJS_SETTING, GQL_FINANCE_OPTIONS)

  const [companyCode, setCompanyCode] = useState(null)
  const [dependents, setDependents] = useState(null)
  const [personnel, setPersonnel] = useState(null)
  // const [multiplierBase, setMultiplierBase] = useState(null)
  // const [multiplierValue, setMultiplierValue] = useState(null)
  const [effectiveDate, setEffectiveDate] = useState(null)

  const [npp, setNpp] = useState(null)
  const [multiplierBaseEmployment, setMultiplierBaseEmployment] = useState(null)
  const [workSafety, setWorkSafety] = useState(null)
  const [mortality, setMortality] = useState(null)
  const [pensionCompany, setPensionCompany] = useState(null)
  const [pensionPersonnel, setPensionpersonnel] = useState(null)
  const [jhtCalculated, setJhtCalculated] = useState(null)
  const [applyingJp, setApplyingJp] = useState(null)
  const [jpCalculated, setJpCalculated] = useState(null)
  const [pensionGuaranteeCompany, setPensionGuaranteeCompany] = useState(null)
  const [pensionGuaranteePersonnel, setPensionGuaranteePersonnel] = useState(
    null
  )
  const [jpPersonnel, setJpPersonnel] = useState(null)
  const [jpAge, setJpAge] = useState(null)
  const [rulesEffectiveDate, setRulesEffectiveDate] = useState(null)

  const [isDisabled, setIsDisabled] = useState(false)
  const [isReTrigger, setIsReTrigger] = useState(false)
  const [hasChange, setHasChange] = useState(false)
  const [hasSubmit, setHasSubmit] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  const [search, setSearch] = useState('')
  const [dataDelete, setDataDelete] = useState({})
  const [OpenAlert, setOpenAlert] = useState(false)

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])

  const {data: bpjsData, refetch, loading} = useQuery(GET_BPJS_SETTINGS, {
    variables: {
      company: COMPANY_ID,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const {
    data: dataExclusion,
    loading: loadingExclusion,
    error: errorExclusion,
    refetch: refetchExclusion,
  } = useQuery(GET_LIST_BPJSKES_EXCLUSION_EMPLOYEE, {
    variables: {
      search: `%${search}%`,
      company: COMPANY_ID,
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const [deleteExclusion] = useMutation(
    DELETE_BPJSKES_EMPLOYEE_EXCLUSION,
    GQL_FINANCE_OPTIONS
  )

  const [addExclusion] = useMutation(
    ADD_BPJSKES_EMPLOYEE_EXCLUSION,
    GQL_FINANCE_OPTIONS
  )

  const onUpdateBPJS = () => {
    setHasSubmit(true)
    if (
      !companyCode ||
      dependents === null ||
      personnel === null ||
      // multiplierBase === null ||
      effectiveDate === null
    ) {
      enqueueSnackbar('Please input all required data', {
        variant: 'error',
      })
    } else {
      setIsDisabled(true)
      const variables = {
        company: COMPANY_ID,
        plugin: {
          IDN: {
            BPJS: {
              Kes: {
                regno: Number(companyCode),
                coverage: {
                  company: dependents,
                  employee: personnel,
                },
                calculationBase: 1, // default value
                effectiveDate: effectiveDate,
              },
              TK: {
                regno: Number(npp),
                calculationBase: multiplierBaseEmployment,
                jkk: workSafety,
                jkm: mortality,
                jht: {
                  company: pensionCompany,
                  employee: pensionPersonnel,
                  includeInTax: jhtCalculated,
                },
                jp: {
                  enabled: applyingJp,
                  includeInTax: jpCalculated,
                  coverage: {
                    company: pensionGuaranteeCompany,
                    employee:
                      pensionGuaranteePersonnel &&
                      Number(pensionGuaranteePersonnel.toFixed(3)),
                  },
                  includeForeigner: jpPersonnel,
                  includeAbove57: jpAge,
                },
                effectiveDate: rulesEffectiveDate,
              },
            },
          },
        },
      }

      updateBPJS({
        variables,
        ...GQL_FINANCE_OPTIONS,
      })
        .then(() => {
          enqueueSnackbar('BPJS Kesehatan settings saved', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          refetch()
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        })
        .finally(() => {
          setIsDisabled(false)
          setHasChange(false)
          setHasSubmit(false)
        })
    }
  }

  const onAddFilterExclusion = result => {
    const variables = {
      objects: result,
    }
    addExclusion({variables: variables})
      .then(() => {
        enqueueSnackbar('Insurance Exclusion for Employees Added', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetchExclusion()
      })
      .catch(() => {
        enqueueSnackbar(
          'Added Insurance Exclusion for Employees failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  const onDeleteExclusion = () => {
    const variables = {
      id: dataDelete.id,
    }
    deleteExclusion({variables: variables})
      .then(() => {
        enqueueSnackbar('Insurance Exclusion for Employees deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetchExclusion()
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete Insurance Exclusion for Employees failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 1000,
          }
        )
      })
  }

  const onCancel = () => {
    refetch()
    setIsReTrigger(curr => !curr)
    setHasChange(false)
    setHasSubmit(false)

    const _bpjsData =
      bpjsData.finance_payroll_settings_by_pk.payroll_setting_plugins
    if (!_bpjsData || Object.keys(_bpjsData).length === 0) {
      setCompanyCode('')
      setDependents(null)
      setPersonnel(null)
      // setMultiplierBase(null)
      setEffectiveDate(null)
    }
  }

  const handleOpenAlert = data => {
    setDataDelete(data)
    setOpenAlert(!OpenAlert)
  }

  useLayoutEffect(() => {
    if (bpjsData) {
      if (bpjsData.finance_payroll_settings_by_pk) {
        if (
          bpjsData.finance_payroll_settings_by_pk.payroll_setting_plugins.IDN
        ) {
          const bpjsKes =
            bpjsData.finance_payroll_settings_by_pk.payroll_setting_plugins.IDN
              .BPJS.Kes
          const bpjsTK =
            bpjsData.finance_payroll_settings_by_pk.payroll_setting_plugins.IDN
              .BPJS.TK
          setCompanyCode(Number(bpjsKes.regno))
          setDependents(bpjsKes.coverage.company)
          setPersonnel(bpjsKes.coverage.employee)
          // setMultiplierBase(bpjsKes.calculationBase)
          setEffectiveDate(bpjsKes.effectiveDate)

          setNpp(Number(bpjsTK.regno))
          setMultiplierBaseEmployment(bpjsTK.calculationBase)
          setWorkSafety(bpjsTK.jkk)
          setMortality(bpjsTK.jkm)
          setPensionCompany(bpjsTK.jht.company)
          setPensionpersonnel(bpjsTK.jht.employee)
          setJhtCalculated(bpjsTK.jht.includeInTax)
          setApplyingJp(bpjsTK.jp.enabled)
          setJpCalculated(bpjsTK.jp.includeInTax)
          setPensionGuaranteeCompany(bpjsTK.jp.coverage.company)
          setPensionGuaranteePersonnel(bpjsTK.jp.coverage.employee)

          setJpPersonnel(bpjsTK.jp.includeForeigner)
          setJpAge(bpjsTK.jp.includeAbove57)
          setRulesEffectiveDate(bpjsTK.effectiveDate)
        }
      }
    }
  }, [bpjsData, isReTrigger])

  if (errorExclusion !== undefined) {
    return JSON.stringify(errorExclusion)
  }

  return (
    <PayrollSettingsWrapper
      activeComponent={activeComponent}
      setActiveComponent={setActiveComponent}
      onConfirm={onUpdateBPJS}
      isDisabledButton={isDisabled}
      onCancel={onCancel}
      secondaryButton="Reset"
      hasChange={hasChange}
      generalSettingData={generalSettingData}
    >
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {!loading && (
        <>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>1.</TitleNumber>
              <TitleName>BPJSKES Company ID*</TitleName>
              <FieldInformationTooltip title={informationText.BPJSCompID} />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                error={hasSubmit && !companyCode}
                helperText={inputRequiredHelperText(hasSubmit, !companyCode)}
                onChange={e => {
                  onChangeNumber(e, setCompanyCode)
                  setHasChange(true)
                }}
                type="number"
                size="small"
                margin="normal"
                variant="outlined"
                inputProps={{
                  min: 0,
                }}
                value={companyCode || 'placeholder'}
                style={{width: '120%'}}
                placeholder="Add BPJSKES Company ID"
              />
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>2.</TitleNumber>
              <TitleName>Percentage Paid by Company*</TitleName>
              <FieldInformationTooltip
                title={informationText.percentagePaidComp}
              />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                style={{width: '120%'}}
                select
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
                value={dependents || 'placeholder'}
                onChange={e => {
                  setDependents(e.target.value)
                  setPersonnel(Number((0.05 - e.target.value).toFixed(2)))
                  setHasChange(true)
                }}
                error={hasSubmit && !dependents}
                helperText={inputRequiredHelperText(hasSubmit, !dependents)}
              >
                <MenuItem value={'placeholder'} disabled>
                  Select Percentage
                </MenuItem>
                <MenuItem value={0.04}>4.0%</MenuItem>
                <MenuItem value={0.05}>5.0%</MenuItem>
              </TextField>
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Percentage Paid by Employee </TitleName>
              <FieldInformationTooltip
                title={informationText.percentagePaidEmployee}
              />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                style={{width: '120%'}}
                select
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
                value={personnel !== null ? personnel : 'placeholder'}
                error={checkStringInput(personnel)}
                helperText={stringHelperText(personnel)}
                disabled
              >
                <MenuItem value={'placeholder'} disabled>
                  Percentage
                </MenuItem>
                <MenuItem value={0.01}>1.0%</MenuItem>
                <MenuItem value={0}>0%</MenuItem>
              </TextField>
            </FormChildInput>
          </FormChildContainer>

          {/* Hide: WLB 4428 */}
          {/* <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>4.</TitleNumber>
              <TitleName>Multiplier Base*</TitleName>
              <FieldInformationTooltip title={informationText.multiplierBase} />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                style={{width: '120%'}}
                size="small"
                select
                fullWidth
                margin="normal"
                variant="outlined"
                value={multiplierBase || 'placeholder'}
                onChange={e => {
                  onChanger(e, setMultiplierBase)
                  setHasChange(true)
                }}
                error={hasSubmit && !multiplierBase}
                helperText={inputRequiredHelperText(hasSubmit, !multiplierBase)}
              >
                <MenuItem value="placeholder" disabled>
                  Select Multiplier Base
                </MenuItem>
                {calculationBase.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer> */}

          {/* <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>5.</TitleNumber>
          <TitleName>Maximum Multiplier Value</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            style={{width: '120%'}}
            size="small"
            margin="normal"
            variant="outlined"
            placeholder="Add Maximum Multiplier Value"
            value={multiplierValue}
            onChange={e => {
              onChanger(e, setMultiplierValue)
              setHasChange(true)
            }}
            error={checkStringInput(multiplierValue)}
            helperText={stringHelperText(multiplierValue)}
          />
        </FormChildInput>
      </FormChildContainer> */}

          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>4.</TitleNumber>
              <TitleName>Rules Effective Date*</TitleName>
              <FieldInformationTooltip
                title={informationText.rulesEffectiveDate}
              />
            </FormChildTitle>
            <FormChildInput>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  style={{width: '120%'}}
                  margin="normal"
                  id="date-picker-dialog"
                  format="yyyy-MM-dd"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputVariant="outlined"
                  placeholder="Select Date"
                  value={effectiveDate}
                  onChange={value => {
                    setEffectiveDate(value)
                    setHasChange(true)
                  }}
                  error={hasSubmit && !effectiveDate}
                  helperText={inputRequiredHelperText(
                    hasSubmit,
                    !effectiveDate
                  )}
                />
              </MuiPickersUtilsProvider>
            </FormChildInput>
          </FormChildContainer>

          <Divider />

          <FormChildContainer className={classes.marginTop10}>
            <FormChildTitle>
              <TitleNumber>5.</TitleNumber>
              <TitleName>Insurance Exclusion for Employees*</TitleName>
              <FieldInformationTooltip
                title={informationText.employeeExclusion}
              />
            </FormChildTitle>
          </FormChildContainer>
          {dataExclusion === undefined && errorExclusion === undefined ? (
            <CircularProgress />
          ) : (
            <>
              <FlexBetween>
                <TextBlackBold
                  className={classes.marginLeft28}
                >{`Applied to People (${dataExclusion?.total?.aggregate.count} People)`}</TextBlackBold>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setOpenFilter(true)
                  }}
                >
                  Assignee
                </Button>
              </FlexBetween>
              <TextField
                margin="normal"
                variant="outlined"
                className={classNames(
                  classes.height39,
                  classes.marginLeft28,
                  classes.width96P
                )}
                fullWidth
                onChange={event => setSearch(event.target.value)}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="ml-0" position="start">
                      <Magnify
                        style={{color: '#014a62', width: 20, height: 20}}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <MaterialTable
                data={dataExclusion?.exclude_people}
                isLoading={loadingExclusion}
                onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
                page={currentPage}
                pageSize={rowsPerPage}
                totalCount={dataExclusion?.total?.aggregate?.count}
                onChangePage={page => {
                  setCurrentPage(page)
                }}
                options={{
                  ...TableOptions,
                  showTitle: false,
                  toolbar: false,
                  selection: false,
                  emptyRowsWhenPaging: false,
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <NoDataListComponent search={search} />
                    ),
                  },
                }}
                components={{
                  Container: props => (
                    <Paper
                      {...props}
                      elevation={0}
                      className={classes.marginLeft28}
                    />
                  ),
                }}
                columns={[
                  {
                    title: 'Avatar',
                    field: 'global_user.avatar',
                    defaultSort: 'asc',
                    headerStyle: {minWidth: 100},
                    cellStyle: {minWidth: 100},
                    render: rowData => (
                      <Avatar alt="ok" src={rowData.global_user.avatar}>
                        {rowData.global_user.name.slice(0, 1)}
                      </Avatar>
                    ),
                  },
                  {
                    title: 'Employee ID',
                    field: 'global_user.people_work_placements.regno',
                    defaultSort: 'asc',
                    headerStyle: {minWidth: 200},
                    cellStyle: {minWidth: 200},
                    render: rowData => (
                      <div style={{wordBreak: 'break-word'}}>
                        {rowData.global_user?.people_work_placements[0]?.regno}
                      </div>
                    ),
                  },
                  {
                    title: 'Employee Name',
                    field: 'global_user.name',
                    defaultSort: 'asc',
                    headerStyle: {minWidth: 200},
                    cellStyle: {minWidth: 200},
                    render: rowData => (
                      <div style={{wordBreak: 'break-word'}}>
                        {rowData.global_user.name}
                      </div>
                    ),
                  },
                  {
                    title: 'Position',
                    field:
                      'global_user.people_work_placements.company_job_profile.title',
                    defaultSort: 'asc',
                    headerStyle: {minWidth: 200},
                    cellStyle: {minWidth: 200},
                    render: rowData => (
                      <div style={{wordBreak: 'break-word'}}>
                        {
                          rowData.global_user.people_work_placements[0]
                            ?.company_job_profile?.title
                        }
                      </div>
                    ),
                  },
                  {
                    title: 'Action',
                    field: 'action',
                    headerStyle: {minWidth: 100},
                    cellStyle: {minWidth: 100},
                    render: rowData => (
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleOpenAlert(rowData)}
                      >
                        <RedIcon>delete</RedIcon>
                      </IconButton>
                    ),
                  },
                ]}
              />

              <FilterAssignee
                feature="bpjskes-setting"
                open={openFilter}
                onAdd={onAddFilterExclusion}
                preData={dataExclusion && dataExclusion.filter_assignee}
                handleClose={() => setOpenFilter(false)}
              />

              <DeletePopup
                open={OpenAlert}
                handleClose={handleOpenAlert}
                id={dataDelete?.id}
                name={dataDelete?.global_user?.name}
                mutation={() => onDeleteExclusion()}
              />
            </>
          )}
        </>
      )}
    </PayrollSettingsWrapper>
  )
}

export default withRouter(BPJSKesPayrollSetting)
