import React, {useState} from 'react'
import {
  Dialog,
  IconButton,
  Icon,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import helperStyle, {
  WhiteModalHeader,
  FlexBetween,
  WrapperContainer,
  ButtonFullWidthDarkBlue,
} from '../../../GlobalStyles'

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import GoogleMapReact from 'google-map-react'
import {MapMarker, Magnify} from 'mdi-material-ui'
import Geocode from 'react-geocode'
import classNames from 'classnames'

const MapPopup = props => {
  const {
    handleClose,
    open,
    setDataAddress,
    addressName,
    addressCordinate,
  } = props
  const classes = helperStyle()

  const dataCordinate = addressCordinate
    ? addressCordinate.lat === '' || addressCordinate.lng === ''
      ? {
          lat: -6.9174639,
          lng: 107.61912280000001,
        }
      : {
          lat: addressCordinate.lat,
          lng: addressCordinate.lng,
        }
    : {
        lat: -6.9174639,
        lng: 107.61912280000001,
      }

  const [address, setAddress] = useState(addressName)
  // const [addressPlaceholder, setAddressPlaceholder] = useState('')
  const [latitude, setLatitude] = useState(dataCordinate.lat)
  const [longitude, setLongitude] = useState(dataCordinate.lng)
  // const [locationInfo, setLocationInfo] = useState(null)
  const [isMapLoaded, setMapLoaded] = useState(false)

  const handleApiLoaded = maps => {
    window.google = {maps}
    if (isMapLoaded === false) setMapLoaded(true)
  }

  const handleClickMap = e => {
    Geocode.setApiKey('AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ')
    Geocode.fromLatLng(e.lat, e.lng).then(
      response => {
        const address = response.results[0].formatted_address
        setLatitude(e.lat)
        setLongitude(e.lng)
        // setAddressPlaceholder(address)
        setAddress(address)
      },
      () => {}
    )
  }

  const handleChange = address => {
    setAddress(address)
  }

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        // console.log(results)
        // setLocationInfo(results)
        return getLatLng(results[0])
      })
      .then(latLng => {
        // console.log('Success', latLng.lat, latLng.lng)
        setLatitude(latLng.lat)
        setLongitude(latLng.lng)
        // setAddressPlaceholder(address)
        setAddress(address)
      })
      .catch(() => {})
  }

  const handleSetAddress = () => {
    setDataAddress(address, latitude, longitude)
    handleClose()
  }
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <WhiteModalHeader className={classes.padding15}>
        <FlexBetween className={classes.widthFull}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.paddingHeaderMap}
          >
            <Icon className={classes.iconClose}>close</Icon>
          </IconButton>

          {isMapLoaded && (
            <PlacesAutocomplete
              value={address || ''}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className={classes.widthFull}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    {...getInputProps({
                      placeholder: 'Search',
                      className: 'location-search-input',
                    })}
                    fullWidth
                    className={classNames(classes.height39, classes.marginTop8)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="ml-0" position="end">
                          <Magnify
                            style={{color: '#014a62', width: 20, height: 20}}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, key) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item'
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                        : {backgroundColor: '#ffffff', cursor: 'pointer'}
                      return (
                        <div
                          key={key}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <MenuItem>{suggestion.description}</MenuItem>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          )}
        </FlexBetween>
      </WhiteModalHeader>
      <WrapperContainer className={classes.height380px}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ',
            libraries: 'places',
          }}
          // defaultCenter={{lat: latitude | -6.917464, lng: longitude | 107.619125}}
          center={{
            lat: latitude,
            lng: longitude,
          }}
          defaultZoom={16}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({maps}) => handleApiLoaded(maps)}
          onClick={e => handleClickMap(e)}
        >
          {/* <Typography
                    lat={latitude | -6.917464}
                    lng={longitude | 107.619125}
                  >
                    Marker
                  </Typography> */}
          <MapMarker
            style={{color: '#014a62'}}
            lat={latitude}
            lng={longitude}
            title={
              <React.Fragment>
                address
                <span />
              </React.Fragment>
            }
          />
        </GoogleMapReact>
      </WrapperContainer>
      <ButtonFullWidthDarkBlue onClick={handleSetAddress}>
        Confirm
      </ButtonFullWidthDarkBlue>
    </Dialog>
  )
}

export default MapPopup
