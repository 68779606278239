import React from 'react'
import {Link, withRouter} from 'react-router-dom'

const Activity = props => {
  return (
    <>
      <h1>Loan Processed Page</h1>
    </>
  )
}

export default withRouter(Activity)
