import React, {useState} from 'react'
import {
  Dialog,
  Typography,
  IconButton,
  Grid,
  MenuItem,
  TextField,
  makeStyles,
  Button,
  InputAdornment,
} from '@material-ui/core'

import {GET_GLOBAL_ICONS, GET_CATEGORY_ICON} from '../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import Loading from '../../../components/loading/LoadingComponent'
import NoDataListComponent from '../NoDataListComponent'
import {
  ModalHeader,
  WhiteIcon,
  ModalContentEvent,
  FlexBetween,
  FormChildButton,
  BlueIcon,
  GreyTypography,
} from '../../../GlobalStyles'

const useStyles = makeStyles(() => ({
  selectCat: {background: '#fff', borderRadius: 4},
  textFieldContainer: {padding: '0 20px'},
  grid: {minHeight: '40vh', marginTop: 16},
  imgIcon: {maxWidth: '50px'},
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    cursor: 'pointer',
    padding: 8,
    minHeight: '7vh',
  },
}))

const ModalGlobalIcon = props => {
  const classes = useStyles()
  const {open, handleClose, setIcon} = props
  const [category, setCategory] = useState(null)
  const [search, setSearch] = useState('')
  const [selectedIcon, setSelectedIcon] = useState('')

  const {data, error} = useQuery(GET_GLOBAL_ICONS, {
    variables: {
      search: `%${search}%`,
      category: category === 'all' ? null : category,
    },
  })

  const {data: dataCategory, error: errorCat} = useQuery(GET_CATEGORY_ICON)

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  if (dataCategory === undefined && errorCat === undefined) {
    return <Loading />
  } else if (errorCat !== undefined) {
    return <div>{errorCat}</div>
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <ModalHeader style={{display: 'block'}}>
        <FlexBetween>
          <Typography variant="h6">Pilih Ikon</Typography>

          <IconButton aria-label="close" onClick={handleClose}>
            <WhiteIcon>close</WhiteIcon>
          </IconButton>
        </FlexBetween>
        <div className={classes.textFieldContainer}>
          <TextField
            variant="outlined"
            fullWidth
            select
            value={category || ''}
            margin="normal"
            SelectProps={{displayEmpty: true}}
            onChange={e => setCategory(e.target.value)}
            className={classes.selectCat}
          >
            <MenuItem value="" disabled>
              Pilih Kategori
            </MenuItem>
            <MenuItem value="all">All Category</MenuItem>
            {dataCategory.global_icon_categories.map((cat, i) => (
              <MenuItem value={cat.id} key={i}>
                {cat.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </ModalHeader>
      <ModalContentEvent style={{padding: '1.2rem 2.8rem'}}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Cari Ikon"
          value={search}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BlueIcon>search</BlueIcon>
              </InputAdornment>
            ),
          }}
        />

        <Grid container spacing={3} className={classes.grid}>
          {data.global_icon_directories.length > 0 ? (
            data.global_icon_directories.map((res, i) => {
              return (
                <Grid item xs={2} key={i}>
                  <div
                    style={{
                      border:
                        selectedIcon === res.source
                          ? '3px solid #039be5'
                          : '1px solid #a9a8a8',
                    }}
                    className={classes.iconContainer}
                    onClick={() => {
                      setSelectedIcon(res.source)
                    }}
                  >
                    <div style={{textAlign: 'center'}}>
                      <img
                        alt={i}
                        src={res.source}
                        className={classes.imgIcon}
                      ></img>
                      <GreyTypography variant="caption" component="p">
                        {res.label}
                      </GreyTypography>
                    </div>
                  </div>
                </Grid>
              )
            })
          ) : (
            <NoDataListComponent message={``} />
          )}
        </Grid>
        <FormChildButton style={{paddingRight: 0}}>
          <Button size="large" style={{marginRight: 12}} onClick={handleClose}>
            Batalkan
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={!selectedIcon}
            onClick={() => {
              setIcon(selectedIcon)
              handleClose()
            }}
          >
            Konfirmasi
          </Button>
        </FormChildButton>
      </ModalContentEvent>
    </Dialog>
  )
}

export default ModalGlobalIcon
