export const getDataVariable = filterData => {
  return {
    filterOrganization: {
      _in: filterData?.include?.filterOrganization,
      _nin: filterData?.exclude?.filterOrganization,
    },
    filterJobLevel: {
      _in: filterData?.include?.filterJobLevel,
      _nin: filterData?.exclude?.filterJobLevel,
    },
    filterJobGrade: {
      _in: filterData?.include?.filterJobGrade,
      _nin: filterData?.exclude?.filterJobGrade,
    },
    filterJobTitle: {
      _in: filterData?.include?.filterJobTitle,
      _nin: filterData?.exclude?.filterJobTitle,
    },
    filterPosition: {
      _in: filterData?.include?.filterPosition,
      _nin: filterData?.exclude?.filterPosition,
    },
    filterOffice: {
      _in: filterData?.include?.filterOffice,
      _nin: filterData?.exclude?.filterOffice,
    },
  }
}

export const checkNullFilter = variable => {
  return (
    variable.filterOrganization._in ||
    variable.filterOrganization._nin ||
    variable.filterJobLevel._in ||
    variable.filterJobLevel._nin ||
    variable.filterJobGrade._in ||
    variable.filterJobGrade._nin ||
    variable.filterJobTitle._in ||
    variable.filterJobTitle._nin ||
    variable.filterPosition._in ||
    variable.filterPosition._nin ||
    variable.filterOffice._in ||
    variable.filterOffice._nin
  )
}
