import ClaimLimitPage from './ClaimLimitPageComponent'

export const ClaimLimitPageConfig = {
  routes: [
    // Company Balance
    {
      path: '/budget/limit/company',
      component: ClaimLimitPage,
      state: 'company-balance',
      exact: true,
    },
    {
      path: '/budget/limit/company/history',
      component: ClaimLimitPage,
      state: 'company-balance-history',
      exact: true,
    },
    {
      path: '/budget/limit/company/topup',
      component: ClaimLimitPage,
      state: 'company-balance-topup',
      exact: true,
    },
    {
      path: '/budget/limit/company/:transactionID',
      component: ClaimLimitPage,
      state: 'company-balance-details',
      exact: true,
    },

    // Employee Balance
    {
      path: '/budget/limit/employee',
      component: ClaimLimitPage,
      state: 'employee-balance',
      exact: true,
    },
    {
      path: '/budget/limit/employee/topup',
      component: ClaimLimitPage,
      state: 'employee-balance-topup',
      exact: true,
    },
    {
      path: '/budget/limit/employee/:employeeID',
      component: ClaimLimitPage,
      state: 'employee-balance-details',
      exact: true,
    },
    {
      path: '/budget/limit/employee/:employeeID/history',
      component: ClaimLimitPage,
      state: 'employee-balance-history',
      exact: true,
    },
    {
      path: '/budget/limit/employee/:employeeID/topup',
      component: ClaimLimitPage,
      state: 'employee-baalnce-individual-topup',
      exact: true,
    },
    {
      path: '/budget/limit/employee/:employeeID/:transactionID',
      component: ClaimLimitPage,
      state: 'employee-balance-transaction',
      exact: true,
    },

    // Wallet Report
    {
      path: '/budget/limit/report',
      component: ClaimLimitPage,
      state: 'wallet-report',
      exact: true,
    },
  ],
}
