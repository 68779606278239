import gql from 'graphql-tag'

export const GET_LEAVE_BALANCE = gql`
  query($search: String, $company: uuid, $offset: Int, $limit: Int) {
    total: time_leave_policies_aggregate(where: {company: {_eq: $company}, name: { _ilike: $search }}) {
      aggregate {
        count
      }
    }
    time_leave_policies(
      where: { company: { _eq: $company }, name: { _ilike: $search } }
      offset: $offset
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
      time_leave_policy_children {
        id
        gender
        name
        quota
        infinite_quota
        company_job_profile_r_leaves {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`
