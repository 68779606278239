import gql from 'graphql-tag'

export const GET_KPI_WEIGHT = gql`
  query getKpiWeight($search: String) {
    kpi_weight_units(
      where: {
        _or: [
          {code: {_ilike: $search}}
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      code
      name
      description
      status
    }
  }
`

export const CHECK_CODE_WEIGHT = gql`
  query checkCodeWeight($company: uuid, $code: String) {
    kpi_weight_units_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const CHECK_WEIGHT_ALREADY_TAKEN = gql`
  query checkDeleteWeight($weight: [Int!]!) {
    kpi_lists_aggregate(where: {weight: {_in: $weight}}) {
      aggregate {
        count
      }
    }
  }
`
