import React, { useState } from 'react'
import {
  PaperContainer,
  EnhancedToolbar,
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
  FormContainer,
} from '../../../GlobalStyles'
import {
  Typography,
  Button,
  Divider,
  TextField,
  MenuItem,
  Tooltip,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import document from '../../../assets/images/document.svg'
import photo from '../../../assets/images/photo.svg'
import ClearIcon from '@material-ui/icons/Clear'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_TYPE_LIST } from '../../../graphql/queries/document/company-file/getListType.query'
import axios from 'axios'
import { UPLOAD_URL, TOKEN } from '../../../utils/globals'
import { ADD_COMPANY_FILE } from '../../../graphql/mutations/document/company-file/addCompanyFile.mutation'

const useStyles = makeStyles(() => ({
  buttonFile: {
    margin: '8px 0px 12px 0px',
    width: '8vw',
    height: '8vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    width: '50%',
    height: '50%',
  },
  iconInfo: {
    marginLeft: 10,
    width: 18,
    height: 18,
  },
  textTooltip: {
    fontSize: 12,
    color: '#000',
    display: 'block',
    margin: '2px 0px',
  },
  textBlue: {
    color: '#014a62',
    fontSize: 12,
    display: 'block',
    margin: '2px 0px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  fileUploadded: {
    backgroundColor: '#eff2f4',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    borderRadius: 5,
  },
  top: {
    display: 'flex',
    flexDirection: 'row ',
  },
  fileName: {
    backgroundColor: '#fff',
    padding: '10px 16px',
    width: '100%',
    margin: '0px 10px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    marginTop: 12,
    marginRight: '3vw',
  },
  size: {
    color: '#000',
    fontSize: 12,
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: '#a9a8a8',
    borderRadius: '50%',
    margin: '0px 10px',
  },
  preview: {
    color: '#039be5',
    fontSize: 12,
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  textError: {
    color: '#f44336',
    margin: '8px 14px 0',
    fontSize: '0.75rem',
    fontWeight: '400',
  },
}))

const InfoTooltip = withStyles(() => ({
  tooltip: {
    padding: '12px 14px',
    backgroundColor: '#eff2f4',
    color: '#000',
    fontSize: 14,
    borderRadius: 5,
  },
  arrow: {
    color: '#eff2f4',
    backgroundColor: '#eff2f4',
  },
}))(Tooltip)

const UploadFile = props => {
  const classes = useStyles()
  const [type, setType] = useState('select')
  const [fileType, setFileType] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorSize, setErrorSize] = useState(false)
  const [errorFile, setErrorFile] = useState(false)
  const [error, setError] = useState({
    name: false,
    type: false,
    file: false,
  })
  const [fileName, setFileName] = useState('')
  const [valueAttachment, setValueAttachment] = useState({
    fileUpload: [],
  })

  const { data } = useQuery(GET_TYPE_LIST, { wlb_skipPatch: true })
  const [addFile] = useMutation(ADD_COMPANY_FILE)

  const fileChecker = name => {
    const nameSplited = name.split('.')
    const idx = nameSplited.length - 1
    const res = nameSplited[idx]

    if (
      res === 'doc' ||
      res === 'docx' ||
      res === 'xls' ||
      res === 'pdf' ||
      res === 'xlsx' ||
      res === 'ppt'
    ) {
      setFileType('document')
      return false
    } else if (
      res === 'jpg' ||
      res === 'jpeg' ||
      res === 'png' ||
      res === 'gif' ||
      res === 'tiff' ||
      res === 'pjp' ||
      res === 'pjpeg' ||
      res === 'jfif' ||
      res === 'tif'
    ) {
      setFileType('image')
      return false
    } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
      setFileType('image')
      return false
    } else {
      return true
    }
  }

  const handleChangeFile = e => {
    const tempFile = Array.from(e.target.files)

    if (tempFile[0].size > 1024 * 1024 * 25) {
      setErrorSize(true)
      setTimeout(() => {
        setErrorSize(false)
      }, 3000)
    } else if (fileChecker(tempFile[0].name)) {
      setErrorFile(true)
      setTimeout(() => {
        setErrorFile(false)
      }, 3000)
    } else {
      setLoading(true)
      tempFile.map(val => {
        const formData = new FormData()
        formData.append('file', val)
        return axios
          .post(UPLOAD_URL, formData, {
            headers: {
              Authorization: 'Bearer ' + TOKEN,
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
          })
          .then(response => {
            setLoading(false)
            setValueAttachment(() => {
              return {
                ...valueAttachment,
                fileUpload: [
                  { name: val.name, link: response.data.url, size: val.size },
                ],
              }
            })
          })
          .catch(() => {
            setLoading(false)
          })
      })
      e.target.value = null
    }
  }

  const handleSubmit = () => {
    if (fileName === '') {
      setError({
        ...error,
        name: true,
      })
      setTimeout(() => {
        setError({
          ...error,
          name: false,
        })
      }, 2000)
    } else if (type === 'select') {
      setError({
        ...error,
        type: true,
      })
      setTimeout(() => {
        setError({
          ...error,
          type: false,
        })
      }, 2000)
    } else if (valueAttachment.fileUpload.length === 0) {
      setError({
        ...error,
        file: true,
      })
      setTimeout(() => {
        setError({
          ...error,
          file: false,
        })
      }, 2000)
    } else {
      addFile({
        variables: {
          name: fileName,
          type,
          file: valueAttachment.fileUpload[0].link,
        },
      })
        .then(() => {
          props.history.push({
            pathname: '/document/company-file',
            state: { active: 'company-file', searchable: true },
          })
        })
        .catch(() => { })
    }
  }

  const setClearFile = () => {
    setValueAttachment({
      fileUpload: [],
    })
    setFileType(null)
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Company File</Typography>
        <div>
          <Button
            color="primary"
            size="large"
            style={{ marginRight: 20, padding: '8px 20px' }}
            // component={Link}
            onClick={() => props.history.goBack()}
          >
            cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ padding: '8px 20px' }}
            // component={Link}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider />
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>File Name</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              onChange={e => setFileName(e.target.value)}
              fullWidth
              size="small"
              margin="normal"
              variant="outlined"
              value={fileName}
              error={error.name}
              helperText={error.name ? 'This field must not be empty' : null}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Type</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              select
              size="small"
              margin="normal"
              variant="outlined"
              style={{ width: '14vw' }}
              placeholder="Select Type"
              value={type}
              onChange={e => setType(e.target.value)}
              error={error.type}
              helperText={error.type ? 'This field must not be empty' : null}
            >
              <MenuItem disabled value="select">
                Select Type
              </MenuItem>
              {data &&
                data.global_file_types.map((res, i) => {
                  return (
                    <MenuItem key={i} value={res.id}>
                      {res.name}
                    </MenuItem>
                  )
                })}
            </TextField>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle style={{ display: 'flex', alignItems: 'center' }}>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Upload File</TitleName>
            <InfoTooltip
              // arrow
              title={
                <>
                  <Typography className={classes.textTooltip}>
                    Appropriate file extension:
                  </Typography>
                  <div className={classes.tooltipWrapper}>
                    <Typography className={classes.textTooltip}>
                      Document {'( '}
                    </Typography>
                    <Typography className={classes.textBlue}>
                      .doc, .docx, .xls, .pdf, .xlsx, .ppt
                    </Typography>
                    <Typography className={classes.textTooltip}>
                      {' )'}
                    </Typography>
                  </div>
                  <div className={classes.tooltipWrapper}>
                    <Typography className={classes.textTooltip}>
                      Image {'( '}
                    </Typography>
                    <Typography className={classes.textBlue}>
                      .jpg, .jpeg, .png, .gif, .tiff, .pjp, .pjpeg, .jfif, .tif
                    </Typography>
                    <Typography className={classes.textTooltip}>
                      {' )'}
                    </Typography>
                  </div>
                  <Typography className={classes.textTooltip}>
                    Maximum file size of 25MB
                  </Typography>
                </>
              }
              placement="right"
            >
              <ErrorOutlineOutlinedIcon
                className={classes.iconInfo}
                color="primary"
              />
            </InfoTooltip>
          </FormChildTitle>
          <FormChildInput>
            <input
              disabled={valueAttachment.fileUpload.length > 0}
              style={{ visibility: 'hidden' }}
              type="file"
              id="atch"
              name="updateFile"
              onChange={e => handleChangeFile(e)}
              // git
              multiple
            />
            <label htmlFor="atch">
              <div
                style={
                  valueAttachment.fileUpload.length > 0
                    ? { cursor: 'not-allowed' }
                    : null
                }
                className={classes.buttonFile}
              >
                <AddIcon className={classes.icon} color="primary" />
              </div>
            </label>
            {error.file ? (
              <Typography className={classes.textError}>
                This field must not be empty
              </Typography>
            ) : null}
            {errorSize ? (
              <Typography className={classes.textError}>
                file size cannot exceed 25MB
              </Typography>
            ) : null}
            {errorFile ? (
              <Typography className={classes.textError}>
                This file type is not permitted
              </Typography>
            ) : null}
            {valueAttachment.fileUpload.length > 0
              ? valueAttachment.fileUpload.map((res, i) => {
                return (
                  <div key={i} className={classes.fileUploadded}>
                    <div className={classes.top}>
                      {/* {fileCheck(res.name)} */}
                      <img
                        src={fileType === 'document' ? document : photo}
                        style={{ marginRight: 6 }}
                        alt="doc"
                      />
                      <div className={classes.fileName}>{res.name}</div>
                      <ClearIcon
                        onClick={() => setClearFile()}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div className={classes.bottom}>
                      <Typography className={classes.size}>
                        {res.size / 1000} kB
                      </Typography>
                      <div className={classes.dot}></div>
                      <a
                        href={res.link}
                        rel="noopener noreferrer"
                        target="_blank"
                        className={classes.link}
                      >
                        <Typography className={classes.preview}>
                          Preview
                        </Typography>
                      </a>
                    </div>
                  </div>
                )
              })
              : null}
            {loading ? (
              <div className={classes.fileUploadded}>
                <div className={classes.top}>
                  {/* {fileCheck(res.name)} */}
                  <img
                    src={fileType === 'document' ? document : photo}
                    style={{ marginRight: 6 }}
                    alt="doc"
                  />
                  <div className={classes.fileName}>Uploading data</div>
                  <ClearIcon
                    onClick={() => setClearFile()}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <div className={classes.bottom}>
                  <Typography className={classes.size}></Typography>
                  <div className={classes.dot}></div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className={classes.link}
                  >
                    <Typography className={classes.preview}></Typography>
                  </a>
                </div>
              </div>
            ) : null}
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
    </PaperContainer>
  )
}

export default withRouter(UploadFile)
