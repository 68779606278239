import React, {useState, useLayoutEffect} from 'react'
import {useQuery} from '@apollo/react-hooks'
import {makeStyles, IconButton} from '@material-ui/core'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import AssigneePeopleComponent from './AssigneePeopleComponent'
import {COMPANY_ID} from '../../../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../../../utils'
import {GET_ASSIGNEE_CANDIDATE} from '../../../../../../../graphql/queries'
// import {isNotEmptyArrayOfObject} from '../../../../../../utils/helpers'

const AssigneeFormComponent = props => {
  const {
    handleComponent,
    assignee,
    employees,
    setEmployees,
    setAssignee,
    setHasChange,
  } = props
  const classes = useStyles()
  const limit = 100
  const [checked, setChecked] = useState([])
  const [employeeSearch, setEmployeeSearch] = useState('')
  const [assigneeSearch, setAssigneeSearch] = useState('')
  // const [assignee, setAssignee] = useState(assignment || [])
  // const [employees, setEmployees] = useState([])
  const numberOfChecked = items => intersection(checked, items).length
  const leftChecked = intersection(checked, employees)
  const rightChecked = intersection(checked, assignee)

  const [filterData, setFilterData] = useState({
    position: [],
    organization: [],
    job_profile: [],
    rank: [],
    office: [],
  })

  const {data: assigneeCandidate, loading: assigneeCandidateLoading} = useQuery(
    GET_ASSIGNEE_CANDIDATE,
    {
      variables: {
        company: COMPANY_ID,
        position:
          JSON.stringify(filterData.position) === '[]'
            ? null
            : filterData.position,
        organization:
          JSON.stringify(filterData.organization) === '[]'
            ? null
            : filterData.organization,
        job_profile:
          JSON.stringify(filterData.job_profile) === '[]'
            ? null
            : filterData.job_profile,
        rank: JSON.stringify(filterData.rank) === '[]' ? null : filterData.rank,
        office:
          JSON.stringify(filterData.office) === '[]' ? null : filterData.office,
        offset: 0,
        limit: limit,
        search: `%${employeeSearch}%`,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  useLayoutEffect(() => {
    if (assigneeCandidate) {
      if (props.assignee) {
        setEmployees(
          assigneeCandidate.employees
            .filter(emp => {
              const assigneeId = assignee.map(
                ass => ass.people_work_placement.id
              )
              return !assigneeId.includes(emp.id)
            })
            .map(emp => ({
              people_work_placement: {
                id: emp.id,
                regno: emp.regno,
                global_user: {
                  name: emp.global_user.name,
                  avatar: emp.global_user.avatar,
                },
                company_job_profile: {
                  title: emp.company_job_profile.company_employee_position.name,
                  position: emp.company_job_profile.company_employee_rank.name,
                  organization:
                    emp.company_job_profile.company_organization.name,
                  office_location: emp.company_job_profile.company_address,
                },
              },
            }))
        )
      } else {
        setEmployees(
          assigneeCandidate.employees.map(emp => ({
            people_work_placement: {
              id: emp.id,
              regno: emp.regno,
              global_user: {
                name: emp.global_user.name,
                avatar: emp.global_user.avatar,
              },
              company_job_profile: {
                title: emp.company_job_profile.company_employee_position.name,
                position: emp.company_job_profile.company_employee_rank.name,
                organization: emp.company_job_profile.company_organization.name,
                office_location: emp.company_job_profile.company_address,
              },
            },
          }))
        )
      }
    }
  }, [assigneeCandidate])

  const onClickRight = () => {
    const currentValue = assignee.length
    setAssignee(assignee.concat(leftChecked))
    setEmployees(not(employees, leftChecked))
    setChecked(not(checked, leftChecked))
    const toTransfer = assignee.concat(leftChecked).map(a => {
      return {user_work_id: a.people_work_placement.id}
    })
    handleComponent('assignments', toTransfer)
    if (setHasChange) {
      if (currentValue !== assignee.concat(leftChecked).length) {
        setHasChange(true)
      }
    }
  }
  const onClickLeft = () => {
    const currentValue = employees.length
    setEmployees(employees.concat(rightChecked))
    setAssignee(not(assignee, rightChecked))
    setChecked(not(checked, rightChecked))
    const toTransfer = not(assignee, rightChecked).map(a => {
      return {user_work_id: a.people_work_placement.id}
    })
    handleComponent('assignments', toTransfer)
    if (setHasChange) {
      if (currentValue !== employees.concat(rightChecked).length) {
        setHasChange(true)
      }
    }
  }

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }
  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <AssigneePeopleComponent
          title="People"
          filter={true}
          people={employees}
          search={employeeSearch}
          setSearch={setEmployeeSearch}
          handleToggle={handleToggle}
          handleToggleAll={handleToggleAll}
          checked={checked}
          numberOfChecked={numberOfChecked}
          setFilterData={setFilterData}
          loading={assigneeCandidateLoading}
        />
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className={classes.arrow}>
            <IconButton onClick={() => onClickRight()}>
              <ChevronRight />
            </IconButton>
          </div>
          <div className={classes.arrow}>
            <IconButton onClick={() => onClickLeft()}>
              <ChevronLeft />
            </IconButton>
          </div>
        </div>
        <AssigneePeopleComponent
          title="Assigned"
          people={assignee.filter(item => {
            return (
              item.people_work_placement.global_user.name
                .toLowerCase()
                .includes(assigneeSearch.toLowerCase()) ||
              item.people_work_placement.regno
                .toLowerCase()
                .includes(assigneeSearch.toLowerCase())
            )
          })}
          checked={checked}
          search={assigneeSearch}
          setSearch={setAssigneeSearch}
          handleToggle={handleToggle}
          handleToggleAll={handleToggleAll}
          numberOfChecked={numberOfChecked}
          loading={assigneeCandidateLoading}
        />
      </div>
    </>
  )
}

export default AssigneeFormComponent

const useStyles = makeStyles(() => ({
  arrow: {border: '1px solid #eff2f4', borderRadius: '5px', margin: 20},
}))

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}
