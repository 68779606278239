import React, {useState} from 'react'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {
  Avatar,
  Button,
  Divider,
  Fab,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
  Tooltip,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {getYear, parseISO} from 'date-fns'
import Carousel from 'nuka-carousel'
import {Link, withRouter} from 'react-router-dom'
import Loading from '../../../../components/loading/LoadingComponent'
import {BoldTypography} from '../../../../components/typography/TypographyStyles'
import {
  AddEditTitle,
  BigAvatar,
  BlueIcon,
  FlexBetween,
  GreyTypography,
  PaperContainer,
  SkyBlueIcon,
} from '../../../../GlobalStyles'
import {GET_DETAIL_HOLDING_NOMINATION} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import {holdingRolesContext} from '../../../../utils/helpers'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {UPDATE_HOLDING_NOMINATION_PEOPLE} from '../../../../graphql/mutations'

const useStyles = makeStyles(() => ({
  gridContainer: {
    margin: 0,
    width: '100%',
    padding: 8,
  },
  paper: {
    padding: 20,
  },
  textCenter: {
    textAlign: 'center',
  },
  successor: {
    margin: '12px 0 16px',
    textAlign: 'center',
    padding: 12,
  },
  indicator: {
    padding: 12,
  },
  namedSuccessor: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  green: {
    color: '#4caf50',
  },
  spacer: {
    marginLeft: 16,
  },
  spacerPool: {
    marginLeft: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  spacerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    width: '100%',
  },
  mr20: {
    marginRight: 20,
  },
  flexDetail: {
    alignItems: 'center',
    display: 'flex',
  },
  paperCategory: {
    padding: '20px 0',
    textAlign: 'center',
  },
  red: {
    color: '#ef4d5e',
  },
  paperMobility: {
    textAlign: 'center',
    padding: 17,
    marginTop: 20,
  },
  paperContribut: {
    padding: 20,
    marginTop: 20,
    marginBottom: 42,
  },
  seeAll: {
    textAlign: 'center',
    marginTop: 20,
    width: '100%',
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarPeople: {
    marginRight: 24,
    width: '56px !important',
    height: '56px !important',
  },
  fab: {
    margin: '0 .5rem 22rem',
    color: '#A19F9F',
    background: '#fff',
    width: 36,
    height: 36,
  },
  icArrow: {marginTop: 6},
  mb14: {marginBottom: 14},
}))

const DetailSuccessionComponent = props => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [openAdd, setOpenAdd] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState('')

  const [onMutation] = useMutation(UPDATE_HOLDING_NOMINATION_PEOPLE)

  const colorSwitch = value => {
    const val = value.toLowerCase()

    let color = null
    switch (val) {
      case 'low':
        color = classes.green
        break
      case 'medium':
        color = classes.yellow
        break
      default:
        color = classes.red
    }
    return color
  }

  const handleOpen = (ids, type, names) => {
    if (type === 'add') {
      setOpenAdd(true)
    } else {
      setOpenDel(true)
    }
    setId(ids)
    setName(names)
    setConfirmType(type)
  }

  const handleClose = () => {
    if (confirmType === 'add') {
      setOpenAdd(false)
    } else {
      setOpenDel(false)
    }
  }

  const handleMutation = () => {
    const changes = {
      candidate_status: confirmType === 'add' ? 'is_successor' : 'is_talent',
    }
    onMutation({
      variables: {id, changes},
      ...holdingRolesContext,
    })
      .then(() => {
        enqueueSnackbar('Success Update data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(
          'Update data error, please try again' + JSON.stringify(e),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  const {data, error, refetch} = useQuery(GET_DETAIL_HOLDING_NOMINATION, {
    variables: {
      id_nomination: props.match.params.id,
      company_head: COMPANY_ID,
    },
    ...holdingRolesContext,
    fetchPolicy: 'no-cache',
  })

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const detail = data.detail[0]

  const incumbent =
    data.current_incumbent[0].company_job_profile.people_work_placements

  const displayFab = {display: incumbent.length === 0 ? 'none' : 'block'}

  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <IconButton onClick={props.history.goBack} edge="end" aria-label="back">
          <ArrowBack />
        </IconButton>

        <FlexBetween className={classes.mr20}>
          <AddEditTitle variant="h6">{detail.name}</AddEditTitle>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              props.history.push({
                pathname: '/holding-talent/nomination/edit/' + detail.id,
                state: {active: 'edit-nomination'},
              })
            }}
          >
            Edit Holding Vacancy
          </Button>
        </FlexBetween>
      </Toolbar>
      <Divider />
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{display: 'flex'}} className={classes.mb14}>
              <Avatar
                variant="rounded"
                src={detail.company_profile.logo}
                className={classes.avatarPeople}
              />
              <div>
                <BoldTypography variant="h6">
                  {detail.company_profile.brand_name}
                </BoldTypography>
                <Typography variant="body1">
                  {detail.company_profile.legal_name}
                </Typography>
              </div>
            </div>
            <Typography variant="body1" color="primary">
              {detail.name}
            </Typography>
            <Typography
              variant="body2"
              color="secondary"
              className={classes.mb14}
            >
              {detail.company_job_profile.title} at{' '}
              {detail.company_profile.brand_name}
            </Typography>
            <Typography variant="body2">{detail.description}</Typography>
          </Paper>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={3}
          className={classes.gridContainer}
        >
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography variant="body1" gutterBottom>
                Current Incumbent
              </Typography>
              <Carousel
                swiping
                renderCenterRightControls={({
                  currentSlide,
                  slideCount,
                  nextSlide,
                }) => {
                  return (
                    <Fab
                      className={classes.fab}
                      disabled={slideCount - currentSlide === 1}
                      style={displayFab}
                      onClick={nextSlide}
                    >
                      <Icon className={classes.icArrow}>chevron_right</Icon>
                    </Fab>
                  )
                }}
                renderTopRightControls={null}
                renderCenterLeftControls={({previousSlide, currentSlide}) => {
                  return (
                    <Fab
                      className={classes.fab}
                      disabled={currentSlide === 0}
                      style={displayFab}
                      onClick={previousSlide}
                    >
                      <Icon className={classes.icArrow}>chevron_left</Icon>
                    </Fab>
                  )
                }}
                renderBottomCenterControls={null}
              >
                {incumbent.map((res, i) => {
                  const dataAssignment = res.global_user.talent_assignments
                  return (
                    <div key={i} className={classes.successor}>
                      <BoldTypography variant="body1">
                        {res.global_user.name}
                      </BoldTypography>
                      <BigAvatar
                        src={res.global_user.avatar}
                        alt={res.global_user.name}
                        style={{margin: '16px auto'}}
                      ></BigAvatar>
                      <GreyTypography variant="body2">
                        {res.regno}
                      </GreyTypography>
                      <Typography variant="body2" className={classes.mb14}>
                        {`${getYear(parseISO(res.start_date))} - ${getYear(
                          parseISO(res.end_date)
                        )}`}
                      </Typography>
                      <Grid container item spacing={2}>
                        <Grid item xs={4}>
                          <Paper className={classes.paperCategory}>
                            <Typography variant="body2" gutterBottom>
                              Risk of Loss
                            </Typography>
                            <BoldTypography
                              variant="body1"
                              className={colorSwitch(
                                res.placement_fields.talent_information
                                  .risk_of_loss
                              )}
                            >
                              {
                                res.placement_fields.talent_information
                                  .risk_of_loss
                              }
                            </BoldTypography>
                          </Paper>
                        </Grid>
                        <Grid item xs={4}>
                          <Paper className={classes.paperCategory}>
                            <Typography variant="body2" gutterBottom>
                              Impact of Loss
                            </Typography>
                            <BoldTypography
                              variant="body1"
                              className={colorSwitch(
                                res.placement_fields.talent_information
                                  .impact_of_loss
                              )}
                            >
                              {
                                res.placement_fields.talent_information
                                  .impact_of_loss
                              }
                            </BoldTypography>
                          </Paper>
                        </Grid>

                        <Grid item xs={4}>
                          <Paper className={classes.paperCategory}>
                            <Typography variant="body2" gutterBottom>
                              Future Leader
                            </Typography>
                            <BoldTypography
                              variant="body1"
                              className={
                                res.placement_fields.talent_information
                                  .future_leader
                                  ? classes.green
                                  : classes.red
                              }
                            >
                              {res.placement_fields.talent_information
                                .future_leader
                                ? 'Yes'
                                : 'No'}
                            </BoldTypography>
                          </Paper>
                        </Grid>
                      </Grid>
                      <Paper className={classes.paperMobility}>
                        <Typography variant="body2">Talent Mobility</Typography>
                        <GreyTypography style={{fontWeight: 600}}>
                          {res.placement_fields.talent_information
                            .reason_for_leaving
                            ? res.placement_fields.talent_information
                                .reason_for_leaving
                            : '-'}
                        </GreyTypography>
                      </Paper>
                      <Paper className={classes.paperContribut}>
                        <div style={{display: 'flex', textAlign: 'left'}}>
                          <Avatar
                            variant="rounded"
                            src={
                              dataAssignment.length > 0 &&
                              dataAssignment[0].talent_box &&
                              dataAssignment[0].talent_box.badge
                            }
                            style={{marginRight: 16}}
                          ></Avatar>
                          <div>
                            <BoldTypography variant="body1">
                              {dataAssignment.length > 0 &&
                                dataAssignment[0].talent_box &&
                                dataAssignment[0].talent_box.name}
                            </BoldTypography>
                            <Typography variant="body2" color="secondary">
                              {dataAssignment.length > 0 &&
                              dataAssignment[0].talent_score
                                ? dataAssignment[0].talent_score.toFixed(2)
                                : '-'}
                            </Typography>
                          </div>
                        </div>
                      </Paper>

                      {dataAssignment.length > 0 && (
                        <>
                          <FlexBetween>
                            <BoldTypography variant="body1">
                              Potential Indicator
                            </BoldTypography>
                          </FlexBetween>
                          <div className={classes.indicator}>
                            <FlexBetween>
                              <Typography variant="body2" gutterBottom>
                                Competency (Index)
                              </Typography>
                              <Typography variant="body2">
                                {dataAssignment[0].indicator_pot_competence}
                              </Typography>
                            </FlexBetween>
                            <FlexBetween>
                              <Typography variant="body2" gutterBottom>
                                Course (Courses)
                              </Typography>
                              <Typography variant="body2">
                                {dataAssignment[0].indicator_pot_total_course}
                              </Typography>
                            </FlexBetween>
                            <FlexBetween>
                              <Typography variant="body2" gutterBottom>
                                Attendance (Attendances)
                              </Typography>
                              <Typography variant="body2">
                                {dataAssignment[0].indicator_pot_attendance}
                              </Typography>
                            </FlexBetween>
                            <FlexBetween>
                              <Typography variant="body2" gutterBottom>
                                Social Media Post (Posts)
                              </Typography>
                              <Typography variant="body2">
                                {dataAssignment[0].indicator_pot_socmed_post}
                              </Typography>
                            </FlexBetween>
                          </div>
                          <FlexBetween>
                            <BoldTypography variant="body1">
                              Performance Indicator
                            </BoldTypography>
                          </FlexBetween>
                          <div className={classes.indicator}>
                            <FlexBetween>
                              <Typography variant="body2" gutterBottom>
                                Task (Index)
                              </Typography>
                              <Typography variant="body2">
                                {dataAssignment[0].indicator_perf_goal}
                              </Typography>
                            </FlexBetween>
                            <FlexBetween>
                              <Typography variant="body2" gutterBottom>
                                Ongoing Feedback (Total)
                              </Typography>
                              <Typography variant="body2">
                                {dataAssignment[0].indicator_perf_ogf}
                              </Typography>
                            </FlexBetween>
                            <FlexBetween>
                              <Typography variant="body2" gutterBottom>
                                Multirater (Total)
                              </Typography>
                              <Typography variant="body2">
                                {dataAssignment[0].indicator_perf_multirater}
                              </Typography>
                            </FlexBetween>
                            <FlexBetween>
                              <Typography variant="body2" gutterBottom>
                                NPS (Total)
                              </Typography>
                              <Typography variant="body2">
                                {dataAssignment[0].indicator_perf_nps}
                              </Typography>
                            </FlexBetween>
                          </div>
                        </>
                      )}
                    </div>
                  )
                })}
              </Carousel>
            </Paper>
          </Grid>
          <Grid item container xs={6} alignContent="flex-start">
            <Grid item xs={12} className={classes.mb14}>
              <Paper className={classes.paper}>
                <Typography variant="body1" className={classes.mb14}>
                  Successor Nomination
                </Typography>

                {data.successor.slice(0, 5).map((resSuccessor, i) => {
                  return (
                    <div className={classes.namedSuccessor} key={i}>
                      <Avatar
                        src={
                          resSuccessor.holding_source.people_work_placement
                            .global_user.avatar
                        }
                        alt={
                          resSuccessor.holding_source.people_work_placement
                            .global_user.name
                        }
                      ></Avatar>
                      <div
                        className={classes.spacerPool}
                        style={{alignItems: 'baseline'}}
                      >
                        <div>
                          <BoldTypography variant="body2">
                            {
                              resSuccessor.holding_source.people_work_placement
                                .global_user.name
                            }
                          </BoldTypography>
                          <Typography
                            variant="caption"
                            className={
                              resSuccessor.status === 'ACTIVE'
                                ? classes.green
                                : classes.red
                            }
                          >
                            {resSuccessor.status}
                          </Typography>
                        </div>
                        <div style={{display: 'flex'}}>
                          <Tooltip title="Placement">
                            <IconButton style={{marginRight: 24}} size="small">
                              <SkyBlueIcon>next_week</SkyBlueIcon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove from Talent Nomination">
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleOpen(
                                  resSuccessor.id,
                                  'delete',
                                  resSuccessor.holding_source
                                    .people_work_placement.global_user.name
                                )
                              }
                            >
                              <Icon color="secondary">person_remove</Icon>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  )
                })}
                {data.successor.length > 5 && (
                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.seeAll}
                      color="secondary"
                      component={Link}
                      to={{
                        pathname: 'all-successor',
                        state: {
                          active: 'all-successor',
                          type: 'nomination',
                          detail,
                          searchable: true,
                        },
                      }}
                    >
                      See All
                    </Button>
                  </div>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant="body1" className={classes.mb14}>
                  Talent Pool
                </Typography>

                {data.talent_pool.slice(0, 5).map((resPool, i) => {
                  return (
                    <div className={classes.namedSuccessor} key={i}>
                      <Avatar
                        src={
                          resPool.holding_source.people_work_placement
                            .global_user.avatar
                        }
                        alt={
                          resPool.holding_source.people_work_placement
                            .global_user.name
                        }
                      ></Avatar>
                      <div className={classes.spacerPool}>
                        <div>
                          <BoldTypography variant="body2">
                            {
                              resPool.holding_source.people_work_placement
                                .global_user.name
                            }
                          </BoldTypography>
                          <GreyTypography variant="body2">
                            {
                              resPool.holding_source.people_work_placement
                                .company_job_profile.title
                            }{' '}
                            at{' '}
                            {
                              resPool.holding_source.people_work_placement
                                .company_profile.brand_name
                            }
                          </GreyTypography>
                        </div>
                        <Tooltip title="Add to Talent Nomination">
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleOpen(
                                resPool.id,
                                'add',
                                resPool.holding_source.people_work_placement
                                  .global_user.name
                              )
                            }
                          >
                            <BlueIcon>person_add</BlueIcon>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  )
                })}
                {data.talent_pool.length > 5 && (
                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.seeAll}
                      color="secondary"
                      component={Link}
                      to={{
                        pathname: 'all-pool',
                        state: {
                          active: 'all-pool',
                          type: 'pool',
                          detail,
                          searchable: true,
                        },
                      }}
                    >
                      See All
                    </Button>
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddEdit
        open={openAdd}
        handleClose={handleClose}
        type={confirmType}
        name={`${name} to talent nomination`}
        feature=""
        mutation={handleMutation}
      />
      <DeletePopup
        open={openDel}
        handleClose={handleClose}
        id={id}
        name={`${name} from talent nomination`}
        mutation={handleMutation}
      />
    </PaperContainer>
  )
}

export default withRouter(DetailSuccessionComponent)
