import React, {useState, useLayoutEffect} from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {withRouter} from 'react-router-dom'
import {
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
} from '@material-ui/core'

import {
  FormChildContainer,
  FormChildContainerPayroll,
  FormChildInput,
  FormChildTitle,
  ThinTextfield,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import helperStyle, {
  CenterWrapper,
  FlexStart,
  LitleRedText,
  TextGreyBold,
} from '../../PayrollPageStyles'
import {
  checkStringInput,
  inputRequiredHelperText,
  onChangeNumber,
  onChanger,
} from './formValidation.helper'

import ExcludedEmployeeComponent from './shared-component/form-asignee/ExcludedEmployeeComponent'
import FieldInformationTooltip from '../../../../../pages/shared-components/tooltip/FieldInformationTooltip'
import PayrollSettingsWrapper from '../PayrollSettingsWrapper'

import {UPDATE_THR_SETTINGS} from '../../../../../graphql/mutations'
import {GET_THR_SETTING, GET_RELIGION} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'

// eslint-disable-next-line no-unused-vars
const months = Array.from(Array(12), (x, index) => index + 1)

const THRPayrollSettings = props => {
  const {activeComponent, setActiveComponent, generalSettingData} = props
  const classes = helperStyle()

  const {enqueueSnackbar} = useSnackbar()
  const [roundingProd, setRoundingPeriod] = useState(null)
  const [minWorkMonths, setMinWorkMonths] = useState(null)
  const [prorateMethod, setProrateMethod] = useState(null)
  const [workPeriod, setWorkPeriod] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [thrComponent, setThrComponent] = useState(null)
  const [thrMultiplier, setThrMultiplier] = useState('1.0')
  // const [thrMultiplierSource, setThrMultiplierSource] = useState(1)
  const [taxPaidBy, setTaxPaidBy] = useState(null)
  const [religions, setReligions] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const [isReTrigger, setIsReTrigger] = useState(false)
  const [hasChange, setHasChange] = useState(false)
  const [hasSubmit, setHasSubmit] = useState(false)

  const {data: thrSettingData, loading, error, refetch} = useQuery(
    GET_THR_SETTING,
    {
      variables: {
        company: COMPANY_ID,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  const [updateThrSettings] = useMutation(
    UPDATE_THR_SETTINGS,
    GQL_FINANCE_OPTIONS
  )

  const {data: listOfReligions} = useQuery(GET_RELIGION)

  const informationText = {
    givenAfter: `THR will be given to employees who have worked over the specified time`,
    roundingUp: `Rounding off work time`,
    roundedUp: `The minimum number of days that can be rounded to one month`,
    prorateMethod: (
      <>
        <div>The method for prorating THR</div>
        <div>
          1. In a Year: prorate employee salary in a year, including the changes
        </div>
        <div>2. Last Salary: only recognize last salary</div>
      </>
    ),
    THRCalc: `How will THR be calculated?`,
    THRDate: `The date when the religious holiday occurs`,
    peopleExcluded: `Employees who will not receive THR`,
    taxPaidBy: `Is the tax of THR paid by employee or company`,
  }

  useLayoutEffect(() => {
    if (thrSettingData && listOfReligions) {
      if (thrSettingData.finance_thr_settings_by_pk) {
        const tmp = thrSettingData.finance_thr_settings_by_pk
        setMinWorkMonths(tmp.min_work_months)
        setRoundingPeriod(tmp.roundup)
        setWorkPeriod(tmp.roundup_days)
        setProrateMethod(tmp.prorate_method)
        setThrComponent(tmp.thr_component)
        setReligions(tmp.schedules)
        setThrMultiplier(tmp.thr_multiplier)
        // setThrMultiplierSource(tmp.thr_multiplier_source)
        setTaxPaidBy(tmp.paid_tax_by)
      }
    }
    if (listOfReligions) {
      const schedules = thrSettingData?.finance_thr_settings_by_pk?.schedules
      setReligions(() => {
        const newReligion = listOfReligions.global_religions.map(religion => {
          religion.date = null
          if (thrSettingData?.finance_thr_settings_by_pk) {
            if (schedules instanceof Array) {
              schedules.forEach(rlg => {
                if (rlg.id === religion.id) {
                  religion.date = rlg.date
                }
              })
            } else if (schedules.id === religion.id) {
              religion.date = schedules.date
            }
          }
          return religion
        })
        return newReligion
      })
    }
  }, [thrSettingData, isReTrigger, listOfReligions])
  // const addReligions = () => {
  //   setReligions(prev => {
  //     return prev.concat({
  //       id: -1,
  //       name: '',
  //       date: '',
  //     })
  //   })
  // }

  const onUpdateThr = () => {
    setHasSubmit(true)
    const variables = {
      company: COMPANY_ID,
      min_work_months: minWorkMonths,
      roundup: roundingProd,
      roundup_days: workPeriod || 0,
      prorate_method: prorateMethod,
      // thr_component: thrComponent,
      thr_multiplier: thrMultiplier,
      thr_multiplier_source: 1, // default value
      paid_tax_by: taxPaidBy,
      schedules: [...religions],
    }
    if (
      !minWorkMonths ||
      roundingProd === null ||
      (roundingProd === true && !workPeriod) ||
      !prorateMethod ||
      // Hotfix: allow THR Multiplier Calculation to zero
      // !thrMultiplier ||
      // !thrMultiplierSource ||
      taxPaidBy === null
    ) {
      enqueueSnackbar('Please input all required data', {
        variant: 'error',
        autoHideDuration: 3000,
      })
    } else {
      setIsDisabled(true)
      updateThrSettings({
        variables,
        ...GQL_FINANCE_OPTIONS,
        refetchQueries: [
          {
            query: GET_THR_SETTING,
            variables: {
              company: COMPANY_ID,
            },
            ...GQL_FINANCE_OPTIONS,
          },
        ],
      })
        .then(() => {
          enqueueSnackbar('THR settings saved', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          refetch()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
        .finally(() => {
          setIsDisabled(false)
          setHasChange(false)
          setHasSubmit(false)
        })
    }
  }
  const onCancel = () => {
    refetch()
    setIsReTrigger(curr => !curr)
    setHasChange(false)
    setHasSubmit(false)
    if (!thrSettingData.finance_thr_settings_by_pk) {
      setRoundingPeriod(null)
      setMinWorkMonths(null)
      setProrateMethod(null)
      setWorkPeriod(null)
      setThrComponent(null)
      setThrMultiplier('1.0')
      // setThrMultiplierSource(1)
      setTaxPaidBy(null)
      setReligions([])
    }
  }

  return (
    <PayrollSettingsWrapper
      activeComponent={activeComponent}
      setActiveComponent={setActiveComponent}
      onConfirm={onUpdateThr}
      isDisabledButton={thrSettingData === undefined || isDisabled}
      onCancel={onCancel}
      secondaryButton="Reset"
      hasChange={hasChange}
      generalSettingData={generalSettingData}
    >
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {thrSettingData && (
        <>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>1.</TitleNumber>
              <TitleName>THR will be given after*</TitleName>
              <FieldInformationTooltip title={informationText.givenAfter} />
            </FormChildTitle>
            <FormChildInput
              className={classes.marginTop10}
              style={{display: 'flex'}}
            >
              <ThinTextfield
                variant="outlined"
                // type="number"
                select
                placeholder="Select Options"
                style={{width: '60%'}}
                className={classes.buttonSpacer}
                value={minWorkMonths || 'placeholder'}
                onChange={e => {
                  onChanger(e, setMinWorkMonths)
                  setHasChange(true)
                  setIsDisabled(false)
                }}
                error={hasSubmit && !minWorkMonths}
                helperText={inputRequiredHelperText(hasSubmit, !minWorkMonths)}
              >
                <MenuItem value="placeholder" disabled>
                  Select Options
                </MenuItem>
                {months.map(item => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item} months
                    </MenuItem>
                  )
                })}
              </ThinTextfield>
              {/* <TextGreyBold className={classes.marginTop10}>
                Months
              </TextGreyBold> */}
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>2.</TitleNumber>
              <TitleName>Rounding up the work period*</TitleName>
              <FieldInformationTooltip title={informationText.roundingUp} />
            </FormChildTitle>
            <FormChildInput>
              <RadioGroup
                row
                name="position"
                value={roundingProd}
                onChange={e => {
                  setRoundingPeriod(e.target.value === 'true')
                  setHasChange(true)
                  setIsDisabled(false)
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
              {hasSubmit && roundingProd === null && (
                <LitleRedText>
                  {inputRequiredHelperText(hasSubmit, roundingProd === null)}
                </LitleRedText>
              )}
            </FormChildInput>
          </FormChildContainer>

          {roundingProd && (
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>
                  <span style={{color: 'white'}}>3.</span>
                </TitleNumber>
                <TitleName>Work period will be rounded up when*</TitleName>
                <FieldInformationTooltip title={informationText.roundedUp} />
              </FormChildTitle>
              <FormChildInput
                className={classes.marginTop10}
                style={{display: 'flex', alignItems: 'center'}}
              >
                <ThinTextfield
                  variant="outlined"
                  type="number"
                  placeholder="Add Number of Days"
                  style={{width: '60%'}}
                  className={classes.buttonSpacer}
                  value={workPeriod}
                  onChange={e => {
                    onChangeNumber(e, setWorkPeriod)
                    setHasChange(true)
                    setIsDisabled(false)
                  }}
                  error={hasSubmit && roundingProd && !workPeriod}
                  helperText={inputRequiredHelperText(
                    hasSubmit,
                    roundingProd && !workPeriod
                  )}
                />
                <TextGreyBold className={classes.marginTop10}>
                  Days
                </TextGreyBold>
              </FormChildInput>
            </FormChildContainer>
          )}

          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>THR prorate method*</TitleName>
              <FieldInformationTooltip title={informationText.prorateMethod} />
            </FormChildTitle>
            <FormChildInput>
              <ThinTextfield
                variant="outlined"
                select
                placeholder="Add Number of Days"
                style={{width: '60%'}}
                className={classes.buttonSpacer}
                value={prorateMethod || 'placeholder'}
                onChange={e => {
                  onChanger(e, setProrateMethod)
                  setHasChange(true)
                  setIsDisabled(false)
                }}
                error={hasSubmit && !prorateMethod}
                helperText={inputRequiredHelperText(hasSubmit, !prorateMethod)}
              >
                <MenuItem value="placeholder" disabled>
                  Select Method
                </MenuItem>
                <MenuItem value={1}>In a year</MenuItem>
                <MenuItem value={2}>Last salary</MenuItem>
              </ThinTextfield>
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>4.</TitleNumber>
              <TitleName>THR Calculation Multiplier*</TitleName>
              <FieldInformationTooltip title={informationText.THRCalc} />
            </FormChildTitle>
            <FormChildInput>
              {/* Hide: WLB 4428 */}
              {/* <FlexStart>
                <div>
                  <TitleName>Multiplier</TitleName> */}
              <ThinTextfield
                variant="outlined"
                type="number"
                placeholder="Multiplier"
                className={classes.buttonSpacer}
                value={thrMultiplier}
                style={{width: '60%'}}
                onChange={e => {
                  // Hotfix: allow THR Multiplier Calculation to zero
                  // if (
                  //   parseFloat(e.target.value) >= 1 ||
                  //   e.target.value.match(/^.[.|,]$/g) > 0 ||
                  //   e.target.value.length === 0
                  // ) {
                  onChanger(e, setThrMultiplier)
                  setHasChange(true)
                  setIsDisabled(false)
                  // }
                }}
                error={hasSubmit && !thrMultiplier}
                helperText={inputRequiredHelperText(hasSubmit, !thrMultiplier)}
              />
              {/* Hide: WLB 4428 */}
              {/* </div>
                <div style={{margin: 10, marginTop: 34}}>x</div>
                <div>
                  <TitleName>Base Multiplier</TitleName>
                  <ThinTextfield
                    variant="outlined"
                    select
                    placeholder="Add Number of Days"
                    className={classes.buttonSpacer}
                    value={thrMultiplierSource || 'placeholder'}
                    onChange={e => {
                      onChanger(e, setThrMultiplierSource)
                      setHasChange(true)
                      setIsDisabled(false)
                    }}
                    error={hasSubmit && !thrMultiplierSource}
                    helperText={inputRequiredHelperText(
                      hasSubmit,
                      !thrMultiplierSource
                    )}
                  >
                    <MenuItem value={'placeholder'} disabled>
                      Select Component
                    </MenuItem>
                    <MenuItem value={1} selected>
                      Basic Salary
                    </MenuItem></ThinTextfield>
                </div>
              </FlexStart> */}
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>5.</TitleNumber>
              <TitleName>Tax Paid by*</TitleName>
              <FieldInformationTooltip title={informationText.taxPaidBy} />
            </FormChildTitle>
            <FormChildInput>
              <FormControl error={hasSubmit && !taxPaidBy}>
                <RadioGroup
                  row
                  name="taxPaidBy"
                  onChange={e => {
                    setTaxPaidBy(parseInt(e.target.value))
                    setHasChange(true)
                    setIsDisabled(false)
                  }}
                  value={taxPaidBy}
                  error={checkStringInput(taxPaidBy)}
                  helperText={!taxPaidBy}
                >
                  <FormControlLabel
                    key="1"
                    value={1}
                    control={<Radio color="primary" />}
                    label="Employee"
                  />
                  <FormControlLabel
                    key="0"
                    value={2}
                    control={<Radio color="primary" />}
                    label="Company"
                  />
                </RadioGroup>
                <FormHelperText>
                  {inputRequiredHelperText(hasSubmit, !taxPaidBy)}
                </FormHelperText>
              </FormControl>
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainerPayroll>
            <FormChildTitle>
              <TitleNumber>6.</TitleNumber>
              <TitleName>Religious Holiday Date</TitleName>
              <FieldInformationTooltip title={informationText.THRDate} />
            </FormChildTitle>
            {listOfReligions &&
              religions.map((item, i) => {
                return (
                  <FlexStart key={i}>
                    <FormChildInput style={{width: '40%'}}>
                      <ThinTextfield
                        style={{width: '100%'}}
                        size="small"
                        disabled
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={item.name}
                        onChange={e => {
                          e.persist()
                          setReligions(prev => {
                            const selectedReligion = listOfReligions.global_religions.filter(
                              item => item.id === e.target.value
                            )[0]
                            const tmp = Object.assign([], prev)
                            tmp[i].id = e.target.value
                            tmp[i].name = selectedReligion.name
                            return tmp
                          })
                          setHasChange(true)
                          setIsDisabled(false)
                        }}
                      ></ThinTextfield>
                    </FormChildInput>
                    <FormChildInput style={{width: '30%'}}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          margin="normal"
                          format="yyyy-MM-dd"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          inputVariant="outlined"
                          placeholder="Select date"
                          className={classes.customDatePicker}
                          value={item.date}
                          onChange={value => {
                            setReligions(prev => {
                              const tmp = Object.assign([], prev)
                              tmp[i].date = value
                              return tmp
                            })
                            setHasChange(true)
                            setIsDisabled(false)
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormChildInput>
                  </FlexStart>
                )
              })}
            {/* <FormChildInput>
              <span onClick={addReligions} className={classes.linkLightBlue}>
                Add Religion
              </span>
            </FormChildInput> */}
          </FormChildContainerPayroll>

          <Divider />

          <FormChildContainerPayroll className={classes.marginTop10}>
            <FormChildTitle>
              <TitleNumber>7.</TitleNumber>
              <TitleName>People Excluded from THR</TitleName>
              <FieldInformationTooltip title={informationText.peopleExcluded} />
            </FormChildTitle>
          </FormChildContainerPayroll>

          <ExcludedEmployeeComponent />
        </>
      )}
    </PayrollSettingsWrapper>
  )
}

export default withRouter(THRPayrollSettings)
