import React from 'react'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import UserItemTable from '../shared-feedback/UserItemTable'
import {DEFAULT_PAGINATION} from '../../../../../../utils/globals'
import LoadingItemTable from '../shared-feedback/LoadingItemTable'
import NoDataListComponent from '../../../../../shared-components/NoDataListComponent'
import {getTableNumber} from '../../../../../../utils/helpers'

const useStyles = makeStyles(() => ({
  titlePage: {fontSize: 24, fontWeight: '600', margin: '0 0 24px'},
  percentage: {color: '#039be5'},
}))

export default function TabelRaterItem({
  data,
  rowsPerPage,
  page,
  count,
  onChangePage,
  onChangeRowsPerPage,
  totalQuestion,
  loading,
  error,
  searchText,
}) {
  const classes = useStyles()

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={50} align="center">
                No
              </TableCell>
              <TableCell width={200}>Nama</TableCell>
              <TableCell>Posisi</TableCell>
              <TableCell width={90} align="right">
                Total
              </TableCell>
              <TableCell width={120} align="center">
                Persentase
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              data.map((row, idx) => {
                const answered_question =
                  row.global_user?.multirater_responses_from_aggregate
                    ?.aggregate?.count || 0
                return (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      {getTableNumber(rowsPerPage, page, idx)}
                    </TableCell>
                    <TableCell width={200}>
                      <UserItemTable
                        avatar={row.global_user?.avatar}
                        name={row.global_user?.name || '-'}
                      />
                    </TableCell>
                    <TableCell>
                      {row.company_job_profile?.title || '-'}
                    </TableCell>
                    <TableCell align="right">
                      {`${answered_question} / ${totalQuestion}`}
                    </TableCell>
                    <TableCell align="center" className={classes.percentage}>
                      {`${getPercentage(answered_question, totalQuestion)}%`}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <LoadingItemTable width="100%" height={42} style={{marginBottom: 12}} />
      )}
      {!loading && count === 0 && (
        <NoDataListComponent
          search={!!searchText}
          message="Currently, no rater listed for this level"
        />
      )}
      {error && <p>{JSON.stringify(error)}</p>}
      {count > 0 && (
        <TablePagination
          rowsPerPageOptions={DEFAULT_PAGINATION}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          count={count}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          backIconButtonProps={{'aria-label': 'previous page'}}
          nextIconButtonProps={{'aria-label': 'next page'}}
        />
      )}
    </div>
  )
}

const getPercentage = (value, total) => {
  return total ? parseFloat(((value / total) * 100).toFixed(2)) : 0
}
