import gql from 'graphql-tag'

export const GET_THR_SETTING = gql`
  query($company: uuid!) {
    finance_thr_settings_by_pk(id: $company) {
      id
      min_work_months
      roundup
      roundup_days
      prorate_method
      thr_component
      thr_multiplier
      thr_multiplier_source
      schedules
      paid_tax_by
    }
  }
`

export const GET_ASSIGNEE_CANDIDATE = gql`
  query(
    $company: uuid!
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $search: String
  ) {
    employee_count: people_work_placements_aggregate(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        status: {_eq: "ACTIVE"}
        company: {_eq: $company}
        company_job_profile: {
          _and: [
            {id: {_in: $job_profile}}
            {organization: {_in: $organization}}
            {position: {_in: $position}}
            {rank: {_in: $rank}}
            {office_address: {_in: $office}}
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    employees: people_work_placements(
      limit: $limit
      offset: $offset
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        status: {_eq: "ACTIVE"}
        company: {_eq: $company}
        company_job_profile: {
          _and: [
            {id: {_in: $job_profile}}
            {organization: {_in: $organization}}
            {position: {_in: $position}}
            {rank: {_in: $rank}}
            {office_address: {_in: $office}}
          ]
        }
      }
    ) {
      id
      regno
      company_job_profile {
        id
        title
        company_employee_position {
          id
          name
        }
        company_organization {
          id
          name
        }
        company_employee_rank {
          id
          name
        }
        company_address {
          id
          office_name
          global_province {
            id
            name
          }
          global_city {
            id
            name
          }
        }
      }
      global_user {
        id
        name
        avatar
      }
    }
  }
`

export const GET_LIST_THR_PROCESS = gql`
  query(
    $schedule_id: Int
    $job_profile: Int
    $organization: Int
    $position: Int
    $rank: Int
    $offset: Int
    $limit: Int
    $search: String
  ) {
    employee_count: people_work_placements_aggregate(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        people_work_thrs: {id_schedule: {_eq: $schedule_id}}
        status: {_eq: "ACTIVE"}
        company_job_profile: {
          id: {_eq: $job_profile}
          organization: {_eq: $organization}
          position: {_eq: $position}
          rank: {_eq: $rank}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    employees: people_work_placements(
      limit: $limit
      offset: $offset
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        people_work_thrs: {id_schedule: {_eq: $schedule_id}}
        status: {_eq: "ACTIVE"}
        company_job_profile: {
          id: {_eq: $job_profile}
          organization: {_eq: $organization}
          position: {_eq: $position}
          rank: {_eq: $rank}
        }
      }
    ) {
      id
      regno
      company_job_profile {
        id
        title
        company_employee_position {
          id
          name
        }
        company_organization {
          id
          name
        }
        company_employee_rank {
          id
          name
        }
      }
      global_user {
        id
        name
        avatar
      }
      people_work_thrs(where: {id_schedule: {_eq: $schedule_id}}) {
        id
        thr
        date_sent
        date_printed
      }
    }
  }
`

export const GET_THR_SLIP = gql`
  query($id: bigint!) {
    people_work_thr_by_pk(id: $id) {
      year
      month
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          npwp: people_identities(where: {type: {_eq: "NPWP"}}) {
            id
          }
        }
        company_job_profile {
          id
          title
          company_organization {
            id
            name
          }
          company_employee_position {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
        people_work_salaries(where: {active: {_eq: true}}) {
          currency
          value
          salary_fields
          people_profile_bank {
            id
            global_bank {
              id
              name
            }
            branch
            account_name
            account_number
          }
        }
      }
      id
      thr
      income
      deduction
      allowances
      non_fixed_components
      payroll_fields
      date_sent
      date_printed
    }
  }
`

export const GET_RELIGION_THR_SCHEDULE = gql`
  query GetReligionThrSchedule($company: uuid!) {
    finance_thr_settings_by_pk(id: $company) {
      id
      schedules
    }
  }
`

export const GET_LIST_THR_EXCLUSION_EMPLOYEE = gql`
  query GetListThrExclusionEmployee(
    $company: uuid!
    $limit: Int
    $offset: Int
    $search: String
  ) {
    total: finance_thr_assignments_aggregate(
      where: {thr_id: {_eq: $company}, deleted_at: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
    filter_assignee: finance_thr_assignments(
      where: {thr_id: {_eq: $company}, deleted_at: {_is_null: true}}
    ) {
      id
      people_work_placement {
        id
        user
      }
      company: thr_id
    }
    exclude_people: finance_thr_assignments(
      where: {
        thr_id: {_eq: $company}
        deleted_at: {_is_null: true}
        _or: [{people_work_placement: {global_user: {name: {_ilike: $search}}}}]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      people_work_placement {
        id
        user
        company
        global_user {
          id
          name
          avatar
          people_work_placements {
            id
            regno
            company_job_profile {
              id
              title
            }
          }
        }
      }
    }
  }
`
