import React from 'react'
import {
  Flex,
  FlexBetween,
  FlexCenters,
  TextGrey,
  TextDarkAzure,
  TextHelper,
  ButtonBlue,
} from '../../../../../GlobalStyles'
import {Divider, Typography, Icon, Avatar, TextField} from '@material-ui/core'
import moment from 'moment'
import Axios from 'axios'
import {REAST_API_URL, TOKEN, USER_ID} from '../../../../../utils/globals'

const Comment = ({data, classes, getComments}) => {
  const [state, setState] = React.useState({
    isReplyComment: false,
    commentText: '',
    lesson: '',
  })

  React.useEffect(() => {
    lessonById()
  }, [])

  const lessonById = () => {
    Axios.get(`${REAST_API_URL}/academy/lesson/${data.lessonId}`, {
      headers: {
        Authorization: 'Bearer ' + TOKEN,
      },
    })
      .then(response => {
        setState({
          ...state,
          lesson: response.data,
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  const createComment = () => {
    try {
      Axios.post(
        `${REAST_API_URL}/academy/discussion/${data.id}/response`,
        {
          fulltext: state.commentText,
          userId: USER_ID,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      getComments()
      setState({...state, isReplyComment: false, commentText: ''})
    } catch (error) {
      console.error(error)
    }
  }

  const helpfulUnhelpfulComment = async (isMarkedHelpfulByMe, helpfulId) => {
    try {
      if (isMarkedHelpfulByMe && helpfulId) {
        await Axios.delete(
          `${REAST_API_URL}/academy/discussion/${data.id}/helpful/${helpfulId}/remove`,

          {
            headers: {
              Authorization: 'Bearer ' + TOKEN,
            },
          }
        )
      } else {
        await Axios.post(
          `${REAST_API_URL}/academy/discussion/${data.id}/helpful/`,
          {
            lessonId: data.lessonId,

            userId: USER_ID,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: 'Bearer ' + TOKEN,
            },
          }
        )
      }

      getComments()
    } catch (error) {
      console.error(error)
    }
  }

  const helpfulUnhelpfulResponse = async (
    isMarkedHelpfulByMe,
    helpfulId,
    discussionId
  ) => {
    try {
      if (isMarkedHelpfulByMe) {
        await Axios.delete(
          `${REAST_API_URL}/academy/discussion/${discussionId}/response/${data.id}/helpful/${helpfulId}/remove`,

          {
            headers: {
              Authorization: 'Bearer ' + TOKEN,
            },
          }
        )
      } else {
        await Axios.post(
          `${REAST_API_URL}/academy/discussion/${discussionId}/response/${data.id}/helpful/`,
          {
            lessonId: data.lessonId,

            userId: USER_ID,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: 'Bearer ' + TOKEN,
            },
          }
        )
      }

      getComments()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Divider className={classes.dividers} />
      <Flex>
        <Avatar
          src={
            data.Student.avatar ||
            require('../../../../../assets/images/default-avatar.png')
          }
          className={classes.avatar}
          variant="circle"
        />
        <FlexBetween style={{paddingRight: 20}}>
          <Flex column>
            <FlexBetween>
              <Typography className="font-semibold">
                {data.Student.name}
              </Typography>
            </FlexBetween>
            <Typography variant="body1">{data.fulltext}</Typography>
            {state.lesson && (
              <TextDarkAzure>Dalam {state.lesson.title}</TextDarkAzure>
            )}
            <FlexCenters>
              <TextGrey>Menyukai ({data.countHelpful || 0})</TextGrey>
              <TextGrey style={{marginLeft: 5}} variant="caption">
                {' '}
                Komentar ({data.Responses.length})
              </TextGrey>
            </FlexCenters>
            <FlexCenters>
              {data.isMarkedHelpfulByMe ? (
                <Icon
                  style={{color: 'red', cursor: 'pointer'}}
                  onClick={() => helpfulUnhelpfulComment(true, data.helpfulId)}
                >
                  favorite
                </Icon>
              ) : (
                <Icon
                  style={{color: 'gray', cursor: 'pointer'}}
                  onClick={() => helpfulUnhelpfulComment(false, false)}
                >
                  favorite_border
                </Icon>
              )}

              <Icon
                style={{color: 'gray', marginLeft: 15, cursor: 'pointer'}}
                onClick={() =>
                  setState({...state, isReplyComment: !state.isReplyComment})
                }
              >
                chat_bubble_outline
              </Icon>
            </FlexCenters>
            {state.isReplyComment && (
              <Flex column style={{marginTop: 40, width: 512}}>
                <Typography>Komentar </Typography>
                <TextField
                  multiline={true}
                  variant="outlined"
                  minRows={7}
                  maxRows={7}
                  style={{width: '100%', marginTop: 5}}
                  placeholder="Tambah Komentar"
                  value={state.commentText}
                  onChange={e =>
                    setState({...state, commentText: e.target.value})
                  }
                />
                <Flex style={{marginTop: 10}} flexEnd>
                  <TextHelper
                    style={{marginRight: 15, fontSize: 16, cursor: 'pointer'}}
                    onClick={() => setState({...state, isReplyComment: false})}
                  >
                    Batal
                  </TextHelper>
                  <ButtonBlue onClick={() => createComment()}>
                    Komentar
                  </ButtonBlue>
                </Flex>
              </Flex>
            )}
          </Flex>

          {/* <TextGrey>
            {data.dateAdded !== null
              ? moment(data.dateAdded).format('MMM Do YY')
              : 'just now'}
          </TextGrey> */}
        </FlexBetween>
        <TextGrey>
          {data.dateAdded !== null
            ? moment(data.dateAdded).format('MMM Do YY')
            : 'just now'}
        </TextGrey>
      </Flex>
      <Divider className={classes.dividers} />
      <Flex column>
        {data.Responses.map(data => {
          return (
            <Flex
              style={{marginLeft: 70, marginTop: 20, marginBottom: 20}}
              key={data.id}
            >
              <Avatar
                src={
                  data.Student.avatar ||
                  require('../../../../../assets/images/default-avatar.png')
                }
                className={classes.avatar}
                variant="circle"
              />
              <FlexBetween
                style={{
                  paddingRight: 20,
                }}
              >
                <Flex column>
                  <FlexBetween>
                    <Typography className="font-semibold">
                      {data.Student.name}
                    </Typography>
                  </FlexBetween>
                  <Typography variant="body1">{data.fulltext}</Typography>
                  {/* <TextDarkAzure>In Introduction Lesson</TextDarkAzure> */}
                  <Flex>
                    <TextGrey>Menyukai ({data.countHelpful || 0})</TextGrey>
                  </Flex>
                  <FlexCenters>
                    {data.isMarkedHelpfulByMe ? (
                      <Icon
                        style={{color: 'red', cursor: 'pointer'}}
                        onClick={() =>
                          helpfulUnhelpfulResponse(
                            true,
                            data.helpfulId,
                            data.id
                          )
                        }
                      >
                        favorite
                      </Icon>
                    ) : (
                      <Icon
                        style={{color: 'gray', cursor: 'pointer'}}
                        onClick={() =>
                          helpfulUnhelpfulResponse(false, false, false)
                        }
                      >
                        favorite_border
                      </Icon>
                    )}
                  </FlexCenters>
                </Flex>

                <TextGrey>
                  {data.dateAdded !== null
                    ? moment(data.dateAdded).format('MMM Do YY')
                    : 'just now'}
                </TextGrey>
              </FlexBetween>
            </Flex>
          )
        })}
      </Flex>
    </>
  )
}

export default function TabsComment({classes, getComments, data}) {
  return (
    <>
      {data.map(data => (
        <Comment
          data={data}
          classes={classes}
          getComments={getComments}
          key={data.id}
        />
      ))}
    </>
  )
}
