// beberapa fitur di hide based on TIME-656 jira
import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {withRouter} from 'react-router-dom'

import TextField from '@material-ui/core/TextField'
// import Select from '@material-ui/core/Select'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import {Button, Divider, Radio, RadioGroup} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import {useMutation, useApolloClient} from '@apollo/react-hooks'

import {
  calcMethods,
  months,
  // leftOverValidOptions,
  quotaStartOptions,
  quotaRenewOptions,
} from './Constants'

import {ADD_NEW_LEAVE_POLICY_PARENT} from '../../../../graphql/mutations'
import {
  FormContainer,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  FormChildButton,
} from '../../../../GlobalStyles'

import classNames from 'classnames'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
// import ColorPickerPopup from '../../../shared-components/color-picker/ColorPickerPopup'
import {GET_LEAVE_PARENT_POLICY_NAME} from '../../../../graphql/queries'
import {selectProps} from '../../../../utils/helpers'
import {WrapperField} from '../LeavePageStyles'
import ExpiredBalanceTooltip from './modal/ExpiredBalanceTooltip'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontStyle: 'bold',
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    marginLeft: '12px',
  },
  paper: {
    width: '100%',
    // marginBottom: '10rem',
    marginBottom: theme.spacing(2),
  },
  expanded: {
    borderTop: '1px solid rgba(0, 0, 0, 0.16)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    margin: '0 !important',
  },
  bold: {
    fontWeight: 700,
  },
  mr15: {marginRight: '1.5rem'},
  textGrey: {color: '#a9a8a8'},
  outlinedInput: {
    padding: '10px 14px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'baseline',
    // width: '18rem',
  },
  inputWidth: {
    width: '50%',
  },
  withSwitch: {
    width: '75% !important',
  },
  buttonSpacer: {marginRight: 12},
  toolbar: {display: 'flex', justifyContent: 'space-between'},
  titleText: {display: 'flex', alignItems: 'center'},
}))

function AddLeavePolicyParent(props) {
  const classes = useStyles()
  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()
  const [name, setName] = React.useState('')
  const [selectedMethod, setSelectedMethod] = React.useState('')
  const [selectedMonth, setSelectedMonth] = useState(1)
  const [selectedDate, setSelectedDate] = useState(1)
  const [selectedRenewOption, setSelectedRenewOption] = useState('')
  const [selectedQuotaStart, setSelectedQuotaStart] = useState('')
  const [isProRate, setIsProRate] = useState(false)
  const [isUnpaid, setIsUnpaid] = useState('')
  const [colorLabel, setColorLabel] = useState('')
  const [colorText, setColorText] = useState('')
  const [isError, setIsError] = React.useState(false)
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [allowDebt, setAllowDebt] = useState(false)
  const [remainingLeaveBalance, setRemainingLeaveBalance] = useState(1)
  const [leftoverType, setLeftoverType] = useState({
    leftover_valid_max: null,
    leftover_valid_until_month: null,
    leftover_money_settings: null,
  })

  const [isExist, setIsExist] = useState(false)

  const [addPolicy] = useMutation(ADD_NEW_LEAVE_POLICY_PARENT)

  const handleClickOpen = async type => {
    const {
      leftover_valid_max,
      leftover_valid_until_month,
      leftover_money_settings,
    } = leftoverType
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      const {data: parentName} = await client.query({
        query: GET_LEAVE_PARENT_POLICY_NAME,
        variables: {name},
      })
      if (parentName.time_leave_policies.length > 0) {
        setIsExist(true)
        setTimeout(() => setIsExist(false), 3000)
      }
      if (
        name === '' ||
        selectedMethod === '' ||
        selectedRenewOption === '' ||
        selectedQuotaStart === '' ||
        colorLabel === '' ||
        colorText === '' ||
        (selectedRenewOption === 3 && !selectedDate) ||
        parentName.time_leave_policies.length > 0
      ) {
        const ids = document.getElementById('top')
        ids.scrollIntoView({behavior: 'smooth'})
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else if (
        (remainingLeaveBalance === 1 && isErrorBalance(leftover_valid_max)) ||
        (remainingLeaveBalance === 1 &&
          isErrorBalance(leftover_valid_until_month)) ||
        (remainingLeaveBalance === 2 && isErrorBalance(leftover_money_settings))
      ) {
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else {
        setConfirmType(type)
        setPopup(true)
      }
    }
  }
  const handleClose = () => {
    setPopup(false)
  }

  const history = () => props.history.goBack()

  const handleAllowDebt = value => {
    setAllowDebt(value === 'true')
  }
  const handleRemainingLeaveBalance = value => {
    setRemainingLeaveBalance(parseInt(value))
  }
  const handleChangeLeftOver = (value, target) => {
    setLeftoverType(e => ({...e, [target]: value}))
  }

  const handleSelectedMethod = event => {
    const {value} = event.target
    if (value === 'accrual') {
      setSelectedRenewOption(2)
    }
    setSelectedMethod(value)
  }

  const handleSelectedRenew = event => {
    const {value} = event.target
    if (value === 2) {
      setIsProRate(false)
    }
    setSelectedRenewOption(value)
  }

  const addPolicyParent = () => {
    const {
      leftover_valid_max,
      leftover_valid_until_month,
      leftover_money_settings,
    } = leftoverType
    const data = {
      name: name,
      calc_method: selectedMethod,
      label_color: colorLabel,
      text_color: colorText,
      quota_reset_option: selectedRenewOption,
      quota_reset_option_date: selectedDate,
      quota_reset_option_month: selectedMonth,
      quota_prorate_option: isProRate,
      quota_effective_after_month: selectedQuotaStart,
      allow_leave_debt: allowDebt,
      is_unpaid: isUnpaid === 'Paid' || false,
      leftover_type: remainingLeaveBalance,
      leftover_valid_max:
        remainingLeaveBalance === 1 ? parseInt(leftover_valid_max) : null,
      leftover_valid_until_month:
        remainingLeaveBalance === 1
          ? parseInt(leftover_valid_until_month)
          : null,
      leftover_money_settings:
        remainingLeaveBalance === 2
          ? {value: parseInt(leftover_money_settings)}
          : null,
    }
    addPolicy({variables: {data}})
      .then(() => {
        enqueueSnackbar('Success Update data', {variant: 'success'})
        history()
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
        })
      })
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar} disableGutters>
          <div className={classes.titleText}>
            <IconButton
              onClick={() => handleClickOpen('discard')}
              edge="end"
              aria-label="back"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add Leave and Permission Policy Parent
            </Typography>
          </div>
          <FormChildButton>
            <Button
              size="large"
              className={classes.buttonSpacer}
              onClick={() => handleClickOpen('discard')}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickOpen('add')}
              size="large"
            >
              Save
            </Button>
          </FormChildButton>
        </Toolbar>
        <Divider />
        <FormContainer id="top">
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>1.</TitleNumber>
              <TitleName>Name*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                id="leave-name"
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                placeholder="Annual Leave"
                fullWidth
                margin="normal"
                onChange={event => {
                  setName(event.target.value)
                }}
                variant="outlined"
                value={name}
                error={(name.length < 1 || isExist) && isError}
                helperText={
                  name.length < 1 && isError
                    ? 'This field is required'
                    : isExist && isError
                    ? 'Name is already exist'
                    : ''
                }
              />
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>2.</TitleNumber>
              <TitleName>Leave and Permission Type*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <RadioGroup
                row
                name="gender"
                value={isUnpaid}
                onChange={event => {
                  setIsUnpaid(event.target.value)
                }}
              >
                <FormControlLabel
                  value={'Paid'}
                  control={<Radio color="primary" />}
                  label="Paid"
                />
                <FormControlLabel
                  value={'Unpaid'}
                  control={<Radio color="primary" />}
                  label="Unpaid"
                />
              </RadioGroup>
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainer
            className={classes.withSwitch}
            style={{paddingBottom: 0}}
          >
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Calculation Method*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <div className={classes.flexCenter}>
                <TextField
                  id="part-of-leave"
                  className={classNames(
                    classes.inputWidth,
                    classes.buttonSpacer
                  )}
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  select
                  onChange={handleSelectedMethod}
                  margin="normal"
                  variant="outlined"
                  value={selectedMethod}
                  error={selectedMethod.length < 1 && isError}
                  helperText={
                    selectedMethod.length < 1 && isError
                      ? 'This field is required'
                      : ''
                  }
                  SelectProps={selectProps(selectedMethod)}
                >
                  <MenuItem value="" disabled>
                    Select calculation method
                  </MenuItem>
                  {calcMethods.map((method, i) => {
                    return (
                      <MenuItem key={i} value={method.value}>
                        {method.name}
                      </MenuItem>
                    )
                  })}
                </TextField>
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={isLeftOver}
                      onChange={event => {
                        setIsLeftOver(event.target.checked)
                      }}
                      color="primary"
                      inputProps={{'aria-label': 'primary checkbox'}}
                    />
                  }
                  label="Is leftover enable"
                /> */}
              </div>
            </FormChildInput>
          </FormChildContainer>
          {/* {selectedMethod === calcMethods[1].value && (
            <FormChildInput>
              <TextField
                id="employee-balance"
                helperText="add after date"
                value={1}
                disabled={true}
                type="number"
                margin="normal"
                variant="outlined"
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
              />
            </FormChildInput>
          )} */}
          {/* {isLeftOver && (
            <FormChildContainer style={{paddingBottom: 0}}>
              <FormChildInput>
                <TextField
                  id="select-position"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  select
                  helperText="Leftover Valid Until Month(s)"
                  fullWidth
                  onChange={event => {
                    setLeftOverMonth(event.target.value)
                  }}
                  margin="normal"
                  variant="outlined"
                  value={leftOverMonth}
                >
                  {leftOverValidOptions.map((method, idLeft) => {
                    return (
                      <MenuItem value={method.value} key={idLeft}>
                        {method.name}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </FormChildInput>
            </FormChildContainer>
          )} */}
          <FormChildContainer
            className={classes.withSwitch}
            style={{padding: '1rem 0 0 0'}}
          >
            <FormChildTitle>
              <TitleNumber>4.</TitleNumber>
              <TitleName>Quota renewable every*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <div className={classes.flexCenter}>
                <TextField
                  id="renew-option"
                  className={classNames(
                    classes.inputWidth,
                    classes.buttonSpacer
                  )}
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  select
                  value={selectedRenewOption}
                  onChange={handleSelectedRenew}
                  margin="normal"
                  variant="outlined"
                  error={selectedRenewOption === '' && isError}
                  helperText={
                    selectedRenewOption === '' && isError
                      ? 'This field is required'
                      : ''
                  }
                  SelectProps={selectProps(selectedRenewOption)}
                  disabled={selectedMethod === 'accrual'}
                >
                  <MenuItem value="" disabled>
                    Select quota renewable options
                  </MenuItem>
                  {quotaRenewOptions.map((method, index) => {
                    return (
                      <MenuItem key={index} value={method.value}>
                        {method.name}
                      </MenuItem>
                    )
                  })}
                </TextField>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isProRate}
                      onChange={event => {
                        setIsProRate(event.target.checked)
                      }}
                      color="primary"
                      inputProps={{'aria-label': 'primary checkbox'}}
                      disabled={selectedRenewOption === 2}
                    />
                  }
                  label="Is Prorate"
                />
              </div>
            </FormChildInput>
          </FormChildContainer>
          {selectedRenewOption === 3 && (
            <FormChildContainer style={{display: 'flex', paddingBottom: 0}}>
              <FormChildInput
                style={{flexDirection: 'column'}}
                className={classes.flexCenter}
              >
                <TitleName>Date*</TitleName>
                <TextField
                  id="renew-every"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  className={classNames(classes.buttonSpacer)}
                  error={!selectedDate && isError}
                  type="number"
                  margin="normal"
                  value={selectedDate}
                  onChange={event => {
                    setSelectedDate(event.target.value)
                  }}
                  variant="outlined"
                />
              </FormChildInput>
              <FormChildInput
                style={{flexDirection: 'column'}}
                className={classes.flexCenter}
              >
                <TitleName>Month*</TitleName>
                <TextField
                  id="part-of-leave"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  style={{width: 196}}
                  fullWidth
                  select
                  value={selectedMonth}
                  onChange={event => {
                    setSelectedMonth(event.target.value)
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {months.map((method, index) => {
                    return (
                      <MenuItem key={index} value={method.value}>
                        {method.name}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </FormChildInput>
            </FormChildContainer>
          )}
          <FormChildContainer style={{paddingTop: '1rem '}}>
            <FormChildTitle>
              <TitleNumber>5.</TitleNumber>
              <TitleName>Staff can use this quota from*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                id="quota-start-from"
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                select
                fullWidth
                margin="normal"
                variant="outlined"
                value={selectedQuotaStart}
                onChange={event => {
                  setSelectedQuotaStart(event.target.value)
                }}
                error={selectedQuotaStart === '' && isError}
                helperText={
                  selectedQuotaStart === '' && isError
                    ? 'This field is required'
                    : ''
                }
                SelectProps={selectProps(selectedQuotaStart)}
              >
                <MenuItem value="" disabled>
                  Select when staff can use this quota
                </MenuItem>
                {quotaStartOptions.map((method, index) => {
                  return (
                    <MenuItem key={index} value={method.value}>
                      {method.name}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>6.</TitleNumber>
              <TitleName>Label Color*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                id="label-color"
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                fullWidth
                margin="normal"
                onChange={event => setColorLabel(event.target.value)}
                variant="outlined"
                value={colorLabel}
                type="color"
                className={classes.inputWidth}
                error={colorLabel === '' && isError}
                helperText={
                  colorLabel === '' && isError ? 'This field is required' : ''
                }
              />
              {/* <ColorPickerPopup
                id="label-color"
                color={colorLabel}
                setColor={color => setColorLabel(color)}
                errorText={
                  colorLabel === '' && isError && 'This field is required'
                }
              /> */}
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>7.</TitleNumber>
              <TitleName>Text Color*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                id="text-color"
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                className={classes.inputWidth}
                fullWidth
                margin="normal"
                onChange={event => setColorText(event.target.value)}
                variant="outlined"
                value={colorText}
                type="color"
                error={colorText === '' && isError}
                helperText={
                  colorText === '' && isError ? 'This field is required' : ''
                }
              />
              {/* <ColorPickerPopup
                id="text-color"
                color={colorText}
                setColor={color => setColorText(color)}
                errorText={
                  colorText === '' && isError && 'This field is required'
                }
              /> */}
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>8.</TitleNumber>
              <TitleName>Allow Leave and Permission Debt</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <RadioGroup
                row
                name="position"
                // value={allowDebt}
                onChange={event => {
                  handleAllowDebt(event.target.value)
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Yes"
                  checked={allowDebt}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="No"
                  checked={!allowDebt}
                />
              </RadioGroup>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>9.</TitleNumber>
              <TitleName>Remaining Leave Balance*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <RadioGroup
                row
                name="leftover-type"
                value={remainingLeaveBalance}
                onChange={event => {
                  handleRemainingLeaveBalance(event.target.value)
                }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label="Carry Over"
                  checked={remainingLeaveBalance === 1}
                />
                {/* <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label="Paid with Money"
                  checked={remainingLeaveBalance === 2}
                /> */}
              </RadioGroup>
            </FormChildInput>
          </FormChildContainer>

          {remainingLeaveBalance === 1 && (
            <FormChildContainer style={{paddingBottom: 0}}>
              <FormChildInput
                style={{flexDirection: 'column'}}
                className={classes.flexCenter}
              >
                <TitleName>Maximal Balance*</TitleName>
                <WrapperField>
                  <TextField
                    id="leftover-valid-max"
                    InputProps={{
                      classes: {
                        input: classes.outlinedInput,
                      },
                    }}
                    placeholder="Add Maximal Balance"
                    className={classNames(classes.buttonSpacer)}
                    style={{width: '350px'}}
                    type="number"
                    margin="normal"
                    error={
                      isErrorBalance(leftoverType.leftover_valid_max) && isError
                    }
                    value={leftoverType.leftover_valid_max}
                    onChange={event => {
                      const min = 0
                      let value = parseInt(event.target.value, 10)
                      if (value < min) value = min
                      handleChangeLeftOver(value, 'leftover_valid_max')
                    }}
                    fullWidth
                    variant="outlined"
                    helperText={
                      isErrorBalance(leftoverType.leftover_valid_max) && isError
                        ? 'This field is required'
                        : ''
                    }
                  />
                  <div>Days</div>
                </WrapperField>
              </FormChildInput>
              <FormChildInput
                style={{flexDirection: 'column'}}
                className={classes.flexCenter}
              >
                <TitleName>
                  Balance Duration * <ExpiredBalanceTooltip />
                </TitleName>
                <WrapperField>
                  <TextField
                    id="leftover-valid-until-month"
                    InputProps={{
                      classes: {
                        input: classes.outlinedInput,
                      },
                      inputProps: {
                        min: 0,
                        pattern: '[0-9]*',
                      },
                    }}
                    className={classNames(classes.buttonSpacer)}
                    style={{width: '350px'}}
                    fullWidth
                    variant="outlined"
                    type="number"
                    margin="normal"
                    placeholder="Add Expired Balance"
                    error={
                      isErrorBalance(leftoverType.leftover_valid_until_month) &&
                      isError
                    }
                    value={leftoverType.leftover_valid_until_month}
                    onChange={event => {
                      const max = 12
                      const min = 0
                      let value = parseInt(event.target.value, 10)
                      if (value > max) value = max
                      else if (value < min) value = min
                      handleChangeLeftOver(value, 'leftover_valid_until_month')
                    }}
                    helperText={
                      isErrorBalance(leftoverType.leftover_valid_until_month) &&
                      isError
                        ? 'This field is required'
                        : ''
                    }
                  />
                  <div>Month</div>
                </WrapperField>
              </FormChildInput>
            </FormChildContainer>
          )}
          {remainingLeaveBalance === 2 && (
            <FormChildContainer style={{paddingBottom: 0}}>
              <FormChildInput
                style={{flexDirection: 'column'}}
                className={classes.flexCenter}
              >
                <TitleName>Paid per Day*</TitleName>
                <WrapperField>
                  <TextField
                    id="leftover-money-settings"
                    InputProps={{
                      classes: {
                        input: classes.outlinedInput,
                      },
                    }}
                    className={classNames(classes.buttonSpacer)}
                    style={{width: '350px'}}
                    fullWidth
                    type="number"
                    margin="normal"
                    placeholder="Add Paid per Day Amount"
                    error={
                      isErrorBalance(leftoverType.leftover_money_settings) &&
                      isError
                    }
                    value={leftoverType.leftover_money_settings}
                    onChange={event => {
                      const min = 0
                      let value = parseInt(event.target.value, 10)
                      if (value < min) value = min
                      handleChangeLeftOver(value, 'leftover_money_settings')
                    }}
                    variant="outlined"
                    helperText={
                      isErrorBalance(leftoverType.leftover_money_settings) &&
                      isError
                        ? 'This field is required'
                        : ''
                    }
                  />
                  <div>Days</div>
                </WrapperField>
              </FormChildInput>
            </FormChildContainer>
          )}
        </FormContainer>
        {/* <Divider></Divider>
        <FormChildButton>
          <Button
            color="danger"
            size="large"
            className={classes.buttonSpacer}
            onClick={() => handleClickOpen('discard')}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickOpen('add')}
            size="large"
          >
            Save
          </Button>
        </FormChildButton> */}
      </Paper>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={name}
        feature="Leave and Permission Policy"
        mutation={confirmType === 'discard' ? history : addPolicyParent}
      />
    </div>
  )
}

export const isErrorBalance = variables => {
  return variables === null || isNaN(variables)
}

export default withRouter(AddLeavePolicyParent)
