import React from 'react'

export const tableStyle = (additionalStyle, type) => {
  const cellStyle = {
    minWidth: 175,
    padding: '26px 16px',
    ...additionalStyle,
  }

  const cellNumberStyle = {
    textAlign: 'right',
    ...cellStyle,
  }

  const cellFirstStyle = {
    minWidth: 175,
    padding: '26px 16px 26px 28px',
    ...additionalStyle,
  }

  const STYLES = {
    cell: {
      cellStyle: cellStyle,
      headerStyle: cellStyle,
    },
    'cell-first': {
      cellStyle: cellFirstStyle,
      headerStyle: cellFirstStyle,
    },
    'cell-number': {
      cellStyle: cellNumberStyle,
      headerStyle: cellNumberStyle,
    },
  }

  return STYLES[type]
}

export const titleGroupStyle = (title1, title2) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 50,
      }}
    >
      <div style={{textAlign: 'center', height: 25}}>{title1}</div>
      <div style={{height: 25}}>{title2}</div>
    </div>
  )
}

export default {tableStyle, titleGroupStyle}
