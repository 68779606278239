import {useApolloClient} from '@apollo/react-hooks'
import {
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, {useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {
  GreyTypography,
  MediumAvatar,
  PaperContainer,
  PaperFloatingBg,
  StyledReactSelect,
} from '../../../../../../GlobalStyles'
import {GET_LIST_COMPARE_TALENT} from '../../../../../../graphql/queries'
import TalentSuccessorComponent from '../TalentSuccessorComponent'

const useStyles = makeStyles(() => ({
  gridContainer: {
    margin: 0,
    width: '100%',
    padding: 8,
  },
  bgAbsolute: {
    background: '#014a62',
  },
  list: {display: 'flex', margin: 8, alignItems: 'center'},
  spacer: {marginRight: 10},
}))

const customFloatingBG = {
  minHeight: '87vh',
  background: 'linear-gradient(to bottom, #014a62 150px, transparent 0)',
}

const CompareTalentSuccession = props => {
  const {pos} = props.location.state

  const client = useApolloClient()
  const classes = useStyles()

  const [searchFirst, setSearchFirst] = useState(null)
  const [searchSecond, setSearchSecond] = useState(null)

  const handleChange = (value, type) => {
    if (type === 'first') {
      setSearchFirst(value)
    } else {
      setSearchSecond(value)
    }
  }

  const empMenu = option => {
    return (
      <div className={classes.list}>
        <div className={`${classes.spacer} hidden`}>
          <MediumAvatar src={option.avatar} alt={option.value}></MediumAvatar>
        </div>
        <div style={{width: '100%'}}>
          <Typography variant="body1">{option.value}</Typography>

          <GreyTypography className="hidden" variant="body2">
            {option.position}
          </GreyTypography>
        </div>
      </div>
    )
  }

  const getListCompareEmployee = async search => {
    const {data} = await client.query({
      query: GET_LIST_COMPARE_TALENT,
      variables: {search: `%${search}%`, limit: 20},
    })

    return data?.people_work_placements?.map(resEmp => ({
      label: resEmp.global_user.id,
      value: resEmp.global_user.name,
      avatar: resEmp.global_user.avatar,
      position: resEmp.company_job_profile?.title,
    }))
  }

  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link
          to={{
            pathname: '/talent/succession/' + props.match.params.id,
            state: {active: 'detail-succession'},
          }}
        >
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <GreyTypography
          variant="h6"
          style={{marginLeft: '1rem', marginRight: 6}}
        >
          {`${pos} Rincian Suksesi `}
        </GreyTypography>
        <Typography variant="h6">/ Membandingkan Talenta</Typography>
      </Toolbar>
      <Divider />
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid container item xs={6}>
          <PaperFloatingBg padding="50px 26px 26px" style={customFloatingBG}>
            <StyledReactSelect
              value={searchFirst}
              onChange={val => handleChange(val, 'first')}
              getOptionLabel={empMenu}
              placeholder="Search"
              indicatorSearch
              isSearchable
              defaultOptions
              isAsync
              cacheOptions
              loadOptions={getListCompareEmployee}
              style={selectStyle}
            />
            {searchFirst && (
              <TalentSuccessorComponent
                userId={searchFirst.label}
                type="compare-talent"
              />
            )}
          </PaperFloatingBg>
        </Grid>
        <Grid container item xs={6}>
          <PaperFloatingBg padding="50px 26px 26px" style={customFloatingBG}>
            <StyledReactSelect
              value={searchSecond}
              onChange={val => handleChange(val, 'second')}
              getOptionLabel={empMenu}
              placeholder="Search"
              indicatorSearch
              isSearchable
              defaultOptions
              isAsync
              cacheOptions
              loadOptions={getListCompareEmployee}
              style={selectStyle}
            />
            {searchSecond && (
              <TalentSuccessorComponent
                userId={searchSecond.label}
                type="compare-talent"
              />
            )}
          </PaperFloatingBg>
        </Grid>
      </Grid>
    </PaperContainer>
  )
}

export default withRouter(CompareTalentSuccession)

const selectStyle = {
  singleValue: base => ({...base, '.hidden': {display: 'none'}}),
}
