import React from 'react'
import PropTypes from 'prop-types'

import {
  lighten,
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'

import {
  Divider,
  Toolbar,
  Typography,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import {Link} from 'react-router-dom'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#014a62',
    },
  },
})

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
  },
  datePickerAction: {
    marginRight: 30,
  },
  buttonAction: {
    height: 61,
    marginTop: 6,
    width: 200,
  },
  buttonText: {
    textDecoration: 'none',
    color: '#fff',
  },
  title: {
    flex: '0 0 auto',
    marginLeft: 10,
  },
  uploadButton: {
    color: '#fff',
    textDecoration: 'none',
  },
}))

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles()

  return (
    <MuiThemeProvider theme={theme}>
      <Toolbar className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            Mobile Attendance Settings
          </Typography>
        </div>
      </Toolbar>
    </MuiThemeProvider>
  )
}

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
}

const DetailActivity = ({detailActivityLabel, detailActivityData}) => {
  const classes = useStyles()

  return (
    <div className={classes.detailActivityWrapper}>
      <Typography className={classes.detailActivityLabel}>
        {detailActivityLabel}
      </Typography>
      <Typography className={classes.detailActivityTitle}>
        {detailActivityData}
      </Typography>
    </div>
  )
}

DetailActivity.propTypes = {
  detailActivityLabel: PropTypes.string.isRequired,
  detailActivityData: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  content: {
    minWidth: 850,
    minHeight: 750,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-left',
    alignItems: 'flex-left',
    padding: 25,
  },
  watchLaterIcon: {
    color: '#014a62',
    width: 126,
    height: 126,
  },
  whiteTextButton: {
    color: '#fff',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 17,
    marginBottom: 17,
  },
  blackTextButton: {
    color: '#000',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 15,
  },
  stepIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  detailActivityWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 41,
    paddingLeft: 81,
    paddingRight: 88,
    paddingBottom: 82,
  },
  detailActivityLabel: {
    color: '#014a62',
    fontSize: 15,
    fontWeight: 600,
  },
  detailActivityTitle: {
    color: '#000000',
    fontSize: 17,
    fontWeight: 600,
  },
  editTemplateListsTitle: {
    color: '#000',
    fontSize: 15,
  },
  editTemplateListsSpacer: {
    marginTop: 30,
  },
  stepIcon: {
    color: '#014962',
    width: 97,
    height: 97,
  },
  editTemplateListsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detail: {
    color: '#007fb2',
    cursor: 'pointer',
  },
  customDatePicker: {
    width: 160,
  },

  tableWrapper: {
    overflowX: 'auto',
  },
  buttonAddWorkSchedule: {
    marginTop: 43,
  },
  greyText: {
    color: '#a9a8a8',
  },
  expandableHeading: {
    color: '#014a62',
    fontWeight: 'bold',
  },
  expandableIcon: {
    color: '#014a62',
  },
}))

const headCells = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Date',
  },
  {id: 'clock-in', numeric: true, disablePadding: false, label: 'Clock - In'},
  {
    id: 'clock-out',
    numeric: true,
    disablePadding: false,
    label: 'Clock - Out',
  },
  {
    id: 'work-schedule',
    numeric: true,
    disablePadding: false,
    label: 'Work Schedule',
  },
  {
    id: 'information',
    numeric: true,
    disablePadding: false,
    label: 'Information',
  },
  {id: 'action', numeric: true, disablePadding: false, label: 'Action'},
]

function EnhancedTableHead(props) {
  const {classes, order, orderBy, onRequestSort} = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default function MobileAttendanceSetting() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar />
        <Divider style={{marginTop: 7}} />
        <div style={{padding: 20}}>
          <Typography style={{fontWeight: 'bold'}}>
            1. Can employee use the application to confirm their attendance?
          </Typography>
          <FormControl
            component="fieldset"
            style={{paddingLeft: 20, paddingTop: 31, paddingBottom: 46}}
          >
            <RadioGroup
              aria-label="option"
              name="option1"
              value={true}
              onChange={() => {}}
              style={{display: 'flex', flexDirection: 'row'}}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <Divider style={{marginTop: 7}} />
        <div style={{padding: 20}}>
          <Typography style={{fontWeight: 'bold'}}>
            2. When doing attendance using the application, do employee have to
            include photos?
          </Typography>
          <FormControl
            component="fieldset"
            style={{paddingLeft: 20, paddingTop: 31, paddingBottom: 46}}
          >
            <RadioGroup
              aria-label="option"
              name="option1"
              value={true}
              onChange={() => {}}
              style={{display: 'flex', flexDirection: 'row'}}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
            <Typography style={{color: '#a9a8a8'}}>
              Photo can only be seen by the staff concerned, the direct
              supervisor and HR admin.
            </Typography>
          </FormControl>
        </div>
        <Divider style={{marginTop: 7}} />
        <div style={{padding: 20}}>
          <Typography style={{fontWeight: 'bold'}}>
            3. Can employee do clock-in attendance with applications more than
            once a day?
          </Typography>
          <FormControl
            component="fieldset"
            style={{paddingLeft: 20, paddingTop: 31, paddingBottom: 46}}
          >
            <RadioGroup
              aria-label="option"
              name="option1"
              value={true}
              onChange={() => {}}
              style={{display: 'flex', flexDirection: 'row'}}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <Divider style={{marginTop: 7}} />
        <div style={{padding: 20}}>
          <Typography style={{fontWeight: 'bold'}}>
            4. Limitation of attendance hours through the application.
          </Typography>
          <FormControl
            component="fieldset"
            style={{paddingLeft: 20, paddingTop: 31, paddingBottom: 46}}
          >
            <Typography style={{color: '#a9a8a8'}}>
              {`Outside the limit of set attendance hours, staff cannot do
              attendance using the application. Attendance logs that are
              recorded on attendance recording machines will still be recorded
              in attendance activities. Click "not set" at the time limit for
              attendance if your company has no time limit for attendance.`}
            </Typography>
            <div style={{paddingTop: 26}}>
              <Typography style={{fontWeight: 'bold'}}>
                The initial limit can be Clock-in on the application.
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextField
                  value={'10 : 00 AM'}
                  onChange={() => {}}
                  margin="normal"
                  variant="outlined"
                  type="time"
                  style={{marginRight: 5}}
                />
                <Link style={{paddingLeft: 29, textDecoration: 'none'}}>
                  <Typography style={{color: '#007fb2'}}>Not Set</Typography>
                </Link>
              </div>
            </div>
            <div style={{paddingTop: 26}}>
              <Typography style={{fontWeight: 'bold'}}>
                The deadline to do Clock-in to the application.
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextField
                  value={'10 : 00 AM'}
                  onChange={() => {}}
                  margin="normal"
                  variant="outlined"
                  type="time"
                  style={{marginRight: 5}}
                />
                <Link style={{paddingLeft: 29, textDecoration: 'none'}}>
                  <Typography style={{color: '#007fb2'}}>Not Set</Typography>
                </Link>
              </div>
            </div>
            <div style={{paddingTop: 26}}>
              <Typography style={{fontWeight: 'bold'}}>
                The initial limit can be Clock-out on the application.
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextField
                  value={'10 : 00 AM'}
                  onChange={() => {}}
                  margin="normal"
                  variant="outlined"
                  type="time"
                  style={{marginRight: 5}}
                />
                <Link style={{paddingLeft: 29, textDecoration: 'none'}}>
                  <Typography style={{color: '#007fb2'}}>Not Set</Typography>
                </Link>
              </div>
            </div>
            <div style={{paddingTop: 26}}>
              <Typography style={{fontWeight: 'bold'}}>
                The deadline to do Clock-Out to the application.
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextField
                  value={'10 : 00 AM'}
                  onChange={() => {}}
                  margin="normal"
                  variant="outlined"
                  type="time"
                  style={{marginRight: 5}}
                />
                <Link style={{paddingLeft: 29, textDecoration: 'none'}}>
                  <Typography style={{color: '#007fb2'}}>Not Set</Typography>
                </Link>
              </div>
            </div>
          </FormControl>
        </div>

        <Divider style={{marginTop: 7}} />
        <div style={{padding: 20}}>
          <Typography style={{fontWeight: 'bold'}}>
            5. Can empoyee do attendance outside the set location limit/wifi?
          </Typography>
          <FormControl
            component="fieldset"
            style={{paddingLeft: 20, paddingTop: 31, paddingBottom: 46}}
          >
            <RadioGroup
              aria-label="option"
              name="option1"
              value={true}
              onChange={() => {}}
              style={{display: 'flex', flexDirection: 'row'}}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>
        <Divider style={{marginTop: 7}} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            paddingBottom: 10,
            paddingRight: 10,
            paddingTop: 30,
          }}
        >
          <Typography
            style={{color: '#a9a8a8', marginRight: 58, cursor: 'pointer'}}
          >
            Cancel
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonAction}
          >
            <Link style={{color: '#fff', textDecoration: 'none'}}>Save</Link>
          </Button>
        </div>
      </Paper>
    </div>
  )
}
