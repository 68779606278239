import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

export const CardContainer = styled.div`
  --step-1: #003d51;
  --step-2: transparent;
  --step-height: 180px;
  background: linear-gradient(
    180deg,
    var(--step-1) 0%,
    var(--step-1) var(--step-height),
    var(--step-2) var(--step-height),
    var(--step-2) 100%
  );
  display: flex;
  flex-direction: column;
  padding: 20px;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  color: #fff;
  min-height: 45px;
  align-items: center;
`

export const CardContent = styled.div`
  display: flex;
  margin-top: 20px;
`

export const SearchContainer = styled.div`
  width: 100%;
`

export const DatePickerWrap = styled.div`
  margin-right: 20px;
  border-radius: 5px;
  width: ${props => props.width || '200px'};

  .MuiFormControl-root {
    width: 100%;
    background-color: white;
    border-radius: 5px;
  }

  .MuiFormControl-marginNormal {
    margin-top: 6px;
  }

  .MuiOutlinedInput-input {
    padding: 12px 14px;
  }

  .MuiSelect-root
    .MuiSelect-select
    .MuiSelect-selectMenu
    .MuiInputBase-input
    .MuiInput-input {
    padding-left: 10px;
  }

  .MuiSvgIcon-root {
    color: #014a62;
  }
`

export const BoxCustomContainer = styled.div`
  width: 100%;
  max-width: 198px;
  display: flex;
  flex-grow: 1;
`

export const BoxCustom = styled(Box)`
  width: 100%;
  padding-right: 8px;
`

export const IconText = styled(Typography)`
  font-size: 16px;
  margin-right: 12px;
`

export const IconCustom = styled(Icon)`
  font-size: 19px;
  margin-right: 12px;
`
