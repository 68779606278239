import gql from 'graphql-tag'

export const UPDATE_HOLDING_NOMINATION = gql`
  mutation update_holding_nomination_config(
    $id: Int
    $changes: holding_nomination_config_set_input
  ) {
    update_holding_nomination_config(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`
