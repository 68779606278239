import React, {useState} from 'react'
import classNames from 'classnames'
import MaterialTable from 'material-table'
import {
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@material-ui/core'
import {Magnify} from 'mdi-material-ui'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import RequestWrapper from '../../../../../../shared-components/layouts/RequestWrapper'
import DeletePopup from '../../../../../../shared-components/popup/DeletePopup'
import NoDataListComponent from '../../../../../../shared-components/NoDataListComponent'
import FilterAssignee from '../../../../../../shared-components/popup/FilterAssignee'

import helperStyle, {
  RedIcon,
  TextBlackBold,
  FlexBetween,
} from '../../../../../../../GlobalStyles'
import {TableOptions, pageSizeOption} from '../../../../PayrollPageStyles'

import {COMPANY_ID} from '../../../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../../../../utils'

import {GET_LIST_THR_EXCLUSION_EMPLOYEE} from '../../../../../../../graphql/queries'
import {
  DELETE_THR_EXCLUSION_EMPLOYEE,
  ADD_THR_EXCLUSION_EMPLOYEE,
} from '../../../../../../../graphql/mutations'

const ExcludedEmployeeComponent = () => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [openFilter, setOpenFilter] = useState(false)
  const [search, setSearch] = useState('')
  const [dataDelete, setDataDelete] = useState({})
  const [openAlert, setopenAlert] = useState(false)

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])

  const [deleteExclusion] = useMutation(
    DELETE_THR_EXCLUSION_EMPLOYEE,
    GQL_FINANCE_OPTIONS
  )

  const [addExclusion] = useMutation(
    ADD_THR_EXCLUSION_EMPLOYEE,
    GQL_FINANCE_OPTIONS
  )

  const {
    data: dataExclusion,
    loading: loadingExclusion,
    error: errorExclusion,
    refetch: refetchExclusion,
  } = useQuery(GET_LIST_THR_EXCLUSION_EMPLOYEE, {
    variables: {
      search: `%${search}%`,
      company: COMPANY_ID,
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const onAddFilterExclusion = result => {
    const assignments = result?.map(data => {
      return {
        thr_id: data.company,
        user_work_id: data.user_work_id,
        user_id: data.user,
      }
    })

    addExclusion({
      variables: {
        assignments,
      },
    })
      .then(() => {
        enqueueSnackbar('THR for Employees Added', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetchExclusion()
      })
      .catch(() => {
        enqueueSnackbar(
          'Added THR for Employees failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  const onDeleteExclusion = () => {
    deleteExclusion({variables: {id: dataDelete.id}})
      .then(() => {
        enqueueSnackbar('THR for Employees deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetchExclusion()
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete THR for Employees failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 1000,
          }
        )
      })
  }

  const handleOpenAlert = data => {
    setDataDelete(data)
    setopenAlert(!openAlert)
  }

  const exceludedPeople = dataExclusion?.exclude_people?.map(data => {
    return {
      id: data.id,
      user: data.people_work_placement?.user,
      company: data.people_work_placement?.company,
      global_user: data.people_work_placement?.global_user,
    }
  })

  const filterAssignee = dataExclusion?.filter_assignee?.map(data => {
    return {
      id: data.id,
      user: data.people_work_placement?.user,
      company: data.people_work_placement?.company,
    }
  })

  return (
    <>
      <FlexBetween>
        <TextBlackBold
          className={classes.marginLeft28}
        >{`Applied to People (${dataExclusion?.total?.aggregate?.count ||
          0} People)`}</TextBlackBold>

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            setOpenFilter(true)
          }}
        >
          Assignee
        </Button>
      </FlexBetween>

      <RequestWrapper
        error={errorExclusion}
        loading={!exceludedPeople}
        isEmpty={
          !exceludedPeople && dataExclusion?.total?.aggregate?.count === 0
        }
      >
        <TextField
          margin="normal"
          variant="outlined"
          className={classNames(
            classes.height39,
            classes.marginLeft28,
            classes.width96P
          )}
          fullWidth
          onChange={event => setSearch(event.target.value)}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment className="ml-0" position="start">
                <Magnify style={{color: '#014a62', width: 20, height: 20}} />
              </InputAdornment>
            ),
          }}
        />

        <MaterialTable
          data={exceludedPeople}
          isLoading={loadingExclusion}
          onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
          page={currentPage}
          pageSize={rowsPerPage}
          totalCount={dataExclusion?.total?.aggregate?.count}
          onChangePage={page => {
            setCurrentPage(page)
          }}
          options={{
            ...TableOptions,
            showTitle: false,
            toolbar: false,
            selection: false,
            emptyRowsWhenPaging: false,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: <NoDataListComponent search={search} />,
            },
          }}
          components={{
            Container: props => (
              <Paper
                {...props}
                elevation={0}
                className={classes.marginLeft28}
              />
            ),
          }}
          columns={[
            {
              title: 'Avatar',
              field: 'global_user.avatar',
              defaultSort: 'asc',
              headerStyle: {minWidth: 100},
              cellStyle: {minWidth: 100},
              render: rowData => (
                <Avatar alt="ok" src={rowData.global_user.avatar}>
                  {rowData?.global_user?.name?.slice(0, 1)}
                </Avatar>
              ),
            },
            {
              title: 'Employee ID',
              field: 'global_user.people_work_placements.regno',
              defaultSort: 'asc',
              headerStyle: {minWidth: 200},
              cellStyle: {minWidth: 200},
              render: rowData => (
                <div style={{wordBreak: 'break-word'}}>
                  {rowData.global_user?.people_work_placements?.[0]?.regno}
                </div>
              ),
            },
            {
              title: 'Employee Name',
              field: 'global_user.name',
              defaultSort: 'asc',
              headerStyle: {minWidth: 200},
              cellStyle: {minWidth: 200},
              render: rowData => (
                <div style={{wordBreak: 'break-word'}}>
                  {rowData?.global_user?.name}
                </div>
              ),
            },
            {
              title: 'Position',
              field:
                'global_user.people_work_placements.company_job_profile.title',
              defaultSort: 'asc',
              headerStyle: {minWidth: 200},
              cellStyle: {minWidth: 200},
              render: rowData => (
                <div style={{wordBreak: 'break-word'}}>
                  {
                    rowData?.global_user?.people_work_placements?.[0]
                      ?.company_job_profile?.title
                  }
                </div>
              ),
            },
            {
              title: 'Action',
              field: 'action',
              headerStyle: {minWidth: 100},
              cellStyle: {minWidth: 100},
              render: rowData => (
                <IconButton
                  aria-label="delete"
                  onClick={() => handleOpenAlert(rowData)}
                >
                  <RedIcon>delete</RedIcon>
                </IconButton>
              ),
            },
          ]}
        />
      </RequestWrapper>

      {openFilter && (
        <FilterAssignee
          isUsePwpId
          feature="bpjskes-setting"
          open={openFilter}
          onAdd={onAddFilterExclusion}
          preData={filterAssignee}
          handleClose={() => setOpenFilter(false)}
        />
      )}

      {openAlert && (
        <DeletePopup
          open={openAlert}
          handleClose={handleOpenAlert}
          id={dataDelete?.id}
          name={dataDelete?.global_user?.name}
          mutation={() => onDeleteExclusion()}
        />
      )}
    </>
  )
}

export default ExcludedEmployeeComponent
