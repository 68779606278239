import React, {useState, useEffect} from 'react'
import helperStyle, {
  PaperContainer,
  FormContainer,
  AddEditTitle,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  ThinTextfield,
  FormToolbar,
  FormTitleContainer,
} from '../../../../../GlobalStyles'
import {
  Divider,
  IconButton,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Link, withRouter} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  UPDATE_KPI_SCORING,
  ADD_NEW_KPI_SCORING,
} from '../../../../../graphql/mutations'
import {
  GET_SCORING_NATURE,
  CHECK_CODE_SCORING,
  CHECK_SCORING_ALREADY_TAKEN,
} from '../../../../../graphql/queries'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../../shared-components/popup/AddEditPopupFreeText'
import {COMPANY_ID} from '../../../../../utils/globals'
import Loading from '../../../../../components/loading/LoadingComponent'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {status_data} from '../../../../shared-components/data/SharedData'

function FormScoring(props) {
  const classes = helperStyle()
  const {dataScoring, history} = props
  const {enqueueSnackbar} = useSnackbar()
  const [isEdit, setIsEdit] = useState(false)
  const [allDataForm, setAllDataForm] = useState({
    code: '',
    name: '',
    scoring: 'none',
    description: '',
    status: 'ACTIVE',
  })
  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)
  const [dialogCantDel, setDialogCantDel] = useState(false)
  const [skipQueryCheckScoring, setSkipQueryCheckScoring] = useState(true)

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  const [mutationAddScoring] = useMutation(ADD_NEW_KPI_SCORING)
  const [mutationEditScoring] = useMutation(UPDATE_KPI_SCORING)
  const {data, loading, error} = useQuery(GET_SCORING_NATURE)

  useEffect(() => {
    if (dataScoring) {
      setIsEdit(true)
      setAllDataForm({
        ...allDataForm,
        code: dataScoring.code || '',
        name: dataScoring.name,
        description: dataScoring.description,
        scoring: dataScoring?.global_scoring_nature?.id ?? 'none',
        status: dataScoring.status,
      })
      setSkipQueryCheckScoring(false)
    }
  }, [isEdit, dataScoring])

  const {data: checkCodeScoring, error: checkCodeScoringError} = useQuery(
    CHECK_CODE_SCORING,
    {
      variables: {
        company: COMPANY_ID,
        code: allDataForm && allDataForm.code,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {data: checkScoringAlreadyTaken} = useQuery(
    CHECK_SCORING_ALREADY_TAKEN,
    {
      variables: {
        scoring: dataScoring && dataScoring.id,
      },
      skip: skipQueryCheckScoring,
      fetchPolicy: 'cache-and-network',
    }
  )

  if (checkCodeScoring === undefined && checkCodeScoringError === undefined) {
    return <Loading />
  } else if (checkCodeScoringError !== undefined) {
    return JSON.stringify(checkCodeScoringError)
  }

  const codeChecker =
    checkCodeScoring?.kpi_scoring_natures_aggregate?.aggregate?.count > 0

  const isScoringAlreadyTaken =
    checkScoringAlreadyTaken?.kpi_lists_aggregate?.aggregate?.count > 0

  const checkSameCodeWithAdd =
    allDataForm?.code?.length >= 1
      ? dataScoring?.code === allDataForm.code
      : false

  const mutateScoring = () => {
    if (
      !allDataForm.code ||
      allDataForm.code.length > 50 ||
      !allDataForm.name ||
      !allDataForm.description ||
      allDataForm.scoring === 'none' ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const vars = {
        code: allDataForm.code,
        name: allDataForm.name,
        description: allDataForm.description,
        status: allDataForm.status,
        global_scoring: allDataForm.scoring,
      }
      if (!isEdit) {
        mutationAddScoring({variables: {object: vars}})
          .then(() => {
            enqueueSnackbar(`Success Add data`, {variant: 'success'})
            history.push({
              pathname: '/performance/kpi/scoring',
              state: {active: 'scoring', searchable: true},
            })
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
            })
          })
      } else {
        mutationEditScoring({variables: {...vars, id: dataScoring?.id}})
          .then(() => {
            enqueueSnackbar(`Success Update data`, {
              variant: 'success',
            })
            history.push({
              pathname: '/performance/kpi/scoring',
              state: {active: 'scoring', searchable: true},
            })
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
            })
          })
      }
    }
  }

  if (loading) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const action = isEdit ? 'Edit' : 'Add'

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link
            to={{
              pathname: '/performance/kpi/scoring',
              state: {active: 'scoring', searchable: true},
            }}
          >
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6" display="inline">
            {isEdit ? 'Ubah' : 'Tambahkan'} kode sifat penilaian
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            size="large"
            component={Link}
            to={{
              pathname: '/performance/kpi/scoring',
              state: {active: 'scoring', searchable: true},
            }}
            style={{marginRight: 12}}
          >
            Batalkan
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="large"
          >
            Simpan
          </Button>
        </div>
      </FormToolbar>
      <Divider></Divider>
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>
              Kode sifat penilaian {GLOBAL_GLOSSARY.performance.Goal} *
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                setAllDataForm({...allDataForm, code: event.target.value})
              }}
              variant="outlined"
              value={allDataForm.code}
              error={
                (!allDataForm.code && isError) ||
                (allDataForm.code && allDataForm.code.length > 50) ||
                (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
              }
              helperText={
                !allDataForm.code && isError
                  ? 'bagian ini diperlukan'
                  : (codeChecker
                    ? !checkSameCodeWithAdd
                    : checkSameCodeWithAdd)
                  ? `Kode sifat penilaian ${GLOBAL_GLOSSARY.performance.Goal} sudah ada`
                  : allDataForm.code && allDataForm.code.length > 50
                  ? 'Jumlah karakter maksimum adalah 50 karakter'
                  : `Kode sifat penilaian ${GLOBAL_GLOSSARY.performance.Goal} harus unik`
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>
              Nama sifat penilaian {GLOBAL_GLOSSARY.performance.Goal} *
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                setAllDataForm({...allDataForm, name: event.target.value})
              }}
              variant="outlined"
              value={allDataForm.name}
              error={allDataForm.name.length < 1 && isError}
              helperText={
                allDataForm.name.length < 1 && isError
                  ? 'bagian ini diperlukan'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Sifat Penilaian *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              select
              fullWidth
              onChange={event => {
                setAllDataForm({...allDataForm, scoring: event.target.value})
              }}
              margin="normal"
              variant="outlined"
              value={allDataForm.scoring}
              error={allDataForm.scoring === 'none' && isError}
              helperText={
                allDataForm.scoring === 'none' && isError
                  ? 'bagian ini diperlukan'
                  : ''
              }
            >
              <MenuItem className={classes.textGrey} value="none" disabled>
                Pilih sifat penilaian {GLOBAL_GLOSSARY.performance.Goal}
              </MenuItem>
              {data &&
                data.global_scoring_natures.map((res, key) => {
                  return (
                    <MenuItem key={key} value={res.id}>
                      {res.name}
                    </MenuItem>
                  )
                })}
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Keterangan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                setAllDataForm({
                  ...allDataForm,
                  description: event.target.value,
                })
              }}
              variant="outlined"
              value={allDataForm.description}
              error={allDataForm.description.length < 1 && isError}
              helperText={
                allDataForm.description.length < 1 && isError
                  ? 'bagian ini diperlukan'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Status *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              select
              fullWidth
              onChange={event => {
                if (isScoringAlreadyTaken) {
                  setDialogCantDel(!dialogCantDel)
                } else {
                  setAllDataForm({
                    ...allDataForm,
                    status: event.target.value,
                  })
                }
              }}
              margin="normal"
              variant="outlined"
              value={allDataForm.status}
            >
              <MenuItem value="ACTIVE">{status_data.ACTIVE}</MenuItem>
              <MenuItem value="INACTIVE">{status_data.INACTIVE}</MenuItem>
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        mutation={mutateScoring}
        header={CONFIRMATION[action]?.header}
        body={CONFIRMATION[action]?.body}
        button={CONFIRMATION[action]?.button}
        buttonClose="Batalkan"
      />
      <AlertDialog
        open={dialogCantDel}
        handleClose={() => setDialogCantDel(!dialogCantDel)}
        feature={'Unable to Change Status'}
        message="Unable to set status to inactive because this goal scoring nature is already used in the goal library"
        type="Confirm"
      />
    </PaperContainer>
  )
}

export default withRouter(FormScoring)

const CONFIRMATION = {
  Add: {
    header: `Tambahkan Sifat Penilaian?`,
    body: `Apakah anda yakin akan menambahkan jenis penilian ini?`,
    button: 'Tambah',
  },
  Edit: {
    header: `Ubah Sifat Penilaian?`,
    body: `Apakah anda yakin akan mengubah jenis penilian ini?`,
    button: 'Ubah',
  },
}
