import gql from 'graphql-tag'

export const GET_LIST_GOAL = gql`
  query getListGoals(
    $offset: Int
    $limit: Int
    $status: [String!]
    $company: uuid!
    $search: String
    $assignee: [uuid!]
    $order: order_by
    $creator: [uuid!]
    $reporter: [uuid!]
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $type: [Int!]
    $priority: [enum_mpp_priority!]
    $progressPersenFrom: float8
    $progressPersenTo: float8
    $dateFrom: date
    $dateTo: date
    $aspect: [Int!]
    $perspective: [Int!]
    $scoringNature: [Int!]
    $weightUnit: [Int!]
    $filterStatus: [performance_goals_bool_exp]
    $label: [uuid!]
  ) {
    total: performance_goals_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        kpi_list: {
          aspect: {_in: $aspect}
          prespective: {_in: $perspective}
          scoring: {_in: $scoringNature}
          type: {_in: $type}
          _or: [
            {
              kpi_cascading_lists: {
                cascading_main: {
                  _and: [
                    {status: {_eq: "ACTIVE"}}
                    {company: {_eq: $company}}
                    {_not: {id: {_is_null: $isCompany}}}
                  ]
                }
              }
            }
            {
              _and: [
                {_not: {kpi_cascading_lists: {}}}
                {_not: {id: {_is_null: $isPersonal}}}
              ]
            }
          ]
        }
        _or: [
          {
            performance_tasks: {
              performance_task_assignments: {user: {_in: $assignee}}
            }
          }
          {created_by: {_in: $assignee}}
        ]
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
        _and: $filterStatus
      }
    ) {
      aggregate {
        count
      }
    }
    performance_goals(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        kpi_list: {
          aspect: {_in: $aspect}
          prespective: {_in: $perspective}
          scoring: {_in: $scoringNature}
          type: {_in: $type}
          _or: [
            {
              kpi_cascading_lists: {
                cascading_main: {
                  _and: [
                    {status: {_eq: "ACTIVE"}}
                    {company: {_eq: $company}}
                    {_not: {id: {_is_null: $isCompany}}}
                  ]
                }
              }
            }
            {
              _and: [
                {_not: {kpi_cascading_lists: {}}}
                {_not: {id: {_is_null: $isPersonal}}}
              ]
            }
          ]
        }
        _or: [
          {
            performance_tasks: {
              performance_task_assignments: {user: {_in: $assignee}}
            }
          }
          {created_by: {_in: $assignee}}
        ]
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
        _and: $filterStatus
      }
      order_by: [{id: $order, date_added: desc_nulls_last}]
      offset: $offset
      limit: $limit
    ) {
      id
      name
      startdate
      enddate
      progress_percentage
      kpi_list {
        id
        kpi_perspective {
          id
          name
        }
        kpi_cascading_lists(
          order_by: [{cascading_main: {status: asc}}]
          limit: 1
        ) {
          id
          cascading_main {
            id
            name
            status
          }
        }
        kpi_cascading_lists_aggregate {
          aggregate {
            count
          }
        }
      }
      global_user {
        id
        name
        avatar
      }
      total_task: performance_tasks_aggregate {
        aggregate {
          count
        }
      }
      complete_task: performance_tasks_aggregate(
        where: {status: {_in: ["COMPLETED", "ARCHIVED"]}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_LIST_TASK = assignee => {
  return gql`
    query getListTasks(
      $offset: Int
      $limit: Int
      $status: [String!]
      $company: uuid!
      $search: String
      $assignee: [uuid!]
      $order: order_by
      $creator: [uuid!]
      $reporter: [uuid!]
      $isCompany: Boolean!
      $isPersonal: Boolean!
      $type: [Int!]
      $priority: [enum_mpp_priority!]
      $progressPersenFrom: float8
      $progressPersenTo: float8
      $dateFrom: date
      $dateTo: date
      $aspect: [Int!]
      $perspective: [Int!]
      $scoringNature: [Int!]
      $weightUnit: [Int!]
      $label: [bigint!]
    ) {
      total: performance_tasks_aggregate(
        where: {
          status: {_in: $status}
          company: {_eq: $company}
          aspect: {_in: $aspect}
          performance_goal: {
            kpi_list: {
              prespective: {_in: $perspective}
              scoring: {_in: $scoringNature}
              _or: [
                {
                  kpi_cascading_lists: {
                    cascading_main: {
                      _and: [
                        {company: {_eq: $company}}
                        {_not: {id: {_is_null: $isCompany}}}
                      ]
                    }
                  }
                }
                {
                  _and: [
                    {_not: {kpi_cascading_lists: {}}}
                    {_not: {id: {_is_null: $isPersonal}}}
                  ]
                }
              ]
            }
          }
          name: {_ilike: $search}
          ${
            assignee
              ? 'performance_task_assignments : {user: {_in: $assignee}}'
              : ''
          }
          created_by: {_in: $creator}
          reporter: {_in: $reporter}
          method_type: {_in: $type}
          priority: {_in: $priority}
          progress_percentage: {
            _gte: $progressPersenFrom
            _lte: $progressPersenTo
          }
          startdate: {_gte: $dateFrom, _lte: $dateTo}
          target_unit: {_in: $weightUnit}
          group: {_in: $label}
        }
      ) {
        aggregate {
          count
        }
      }
      performance_tasks(
        where: {
          status: {_in: $status}
          company: {_eq: $company}
          aspect: {_in: $aspect}
          performance_goal: {
            kpi_list: {
              prespective: {_in: $perspective}
              scoring: {_in: $scoringNature}
              _or: [
                {
                  kpi_cascading_lists: {
                    cascading_main: {
                      _and: [
                        {company: {_eq: $company}}
                        {_not: {id: {_is_null: $isCompany}}}
                      ]
                    }
                  }
                }
                {
                  _and: [
                    {_not: {kpi_cascading_lists: {}}}
                    {_not: {id: {_is_null: $isPersonal}}}
                  ]
                }
              ]
            }
          }
          name: {_ilike: $search}
          ${
            assignee
              ? 'performance_task_assignments : {user: {_in: $assignee}}'
              : ''
          }
          created_by: {_in: $creator}
          reporter: {_in: $reporter}
          method_type: {_in: $type}
          priority: {_in: $priority}
          progress_percentage: {
            _gte: $progressPersenFrom
            _lte: $progressPersenTo
          }
          startdate: {_gte: $dateFrom, _lte: $dateTo}
          target_unit: {_in: $weightUnit}
          group: {_in: $label}
        }
        order_by: [{id: $order, date_added: desc_nulls_last}]
        offset: $offset
        limit: $limit
      ) {
        id
        name
        startdate
        enddate
        method_type
        status
        performance_goal {
          id
          kpi_list {
            id
            kpi_perspective {
              id
              name
            }
            kpi_cascading_lists_aggregate {
              aggregate {
                count
              }
            }
            kpi_cascading_lists(limit: 1, order_by: [{id: desc}]) {
              id
              cascading_main {
                id
                status
              }
            }
          }
        }
        created_by_user {
          id
          name
        }
        progress_percentage
      }
    }
  `
}

export const GET_COUNT_TARGET_GOAL = isEditTask => {
  if (isEditTask) {
    return gql`
      query($idTask: bigint) {
        performance_tasks(where: {id: {_eq: $idTask}}) {
          performance_goal {
            id
            target
            performance_tasks_aggregate(where: {id: {_neq: $idTask}}) {
              aggregate {
                sum {
                  target
                }
              }
            }
          }
        }
      }
    `
  } else {
    return gql`
      query($idGoal: bigint, $task: bigint) {
        performance_goals(
          where: {
            id: {_eq: $idGoal}
            kpi_list: {
              kpi_scoring_nature: {
                global_scoring_nature: {name: {_eq: "Higher is Better"}}
              }
            }
          }
        ) {
          target
          performance_tasks_aggregate(where: {id: {_neq: $task}}) {
            aggregate {
              sum {
                target
              }
            }
          }
        }
      }
    `
  }
}

export const GET_DETAIL_GOALS_BY_ID_EDIT = gql`
  query detailGoal($id: bigint!) {
    performance_goals(where: {id: {_eq: $id}}) {
      id
      name
      description
      performance_goal_group {
        id
        name
        color
      }
      global_user {
        id
        name
      }
      kpi_list {
        id
        name
        type
        formula
        description
        kpi_scoring_nature {
          id
          name
        }
        kpi_weight_unit {
          id
          name
        }
        kpi_aspect {
          id
          name
        }
        kpi_level {
          id
          name
        }
        kpi_version {
          id
          name
        }
        kpi_method {
          id
          name
        }
        kpi_monitoring_frequency {
          id
          name
        }
        kpi_perspective {
          id
          name
        }
      }
      target
      target_stretch
      weight
      date_added
      startdate
      enddate
      priority
      visibility
      attachment
      status
    }
  }
`

export const GET_DROPDOWN_WEIGHT = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    kpi_weight_units(
      where: {
        _and: [
          {company: {_eq: $companyId}, status: {_eq: "ACTIVE"}}
          {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
    }
  }
`

export const GET_DROPDOWN_ASPECT = gql`
  query listAspect($limit: Int, $offset: Int, $search: String) {
    kpi_aspects(
      where: {
        _or: [{name: {_ilike: $search}}, {description: {_ilike: $search}}]
        status: {_eq: "ACTIVE"}
      }
      limit: $limit
      offset: $offset
      order_by: {id: desc}
    ) {
      id
      name
      description
      status
    }
  }
`

export const GET_FILTER_PLACEMENT = gql`
  query listCreator(
    $where: people_work_placements_bool_exp
    $limit: Int!
    $offset: Int
  ) {
    people_work_placements(where: $where, offset: $offset, limit: $limit) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        code
        title
      }
    }
    total: people_work_placements_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_FILTER_ASPECT = gql`
  query listAspect($where: kpi_aspects_bool_exp, $offset: Int, $limit: Int!) {
    items: kpi_aspects(where: $where, offset: $offset, limit: $limit) {
      id
      code
      name
    }
    total: kpi_aspects_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_FILTER_PERSPECTIVE = gql`
  query listPerspective(
    $where: kpi_perspectives_bool_exp
    $offset: Int
    $limit: Int!
  ) {
    total: kpi_perspectives_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: kpi_perspectives(where: $where, offset: $offset, limit: $limit) {
      id
      code
      name
    }
  }
`

export const GET_FILTER_SCORING_NATURE = gql`
  query listScoringNature(
    $where: kpi_scoring_natures_bool_exp
    $offset: Int
    $limit: Int!
  ) {
    total: kpi_scoring_natures_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: kpi_scoring_natures(where: $where, offset: $offset, limit: $limit) {
      id
      code
      name
    }
  }
`

export const GET_FILTER_WEIGHT_UNIT = gql`
  query listWeightUnit(
    $where: kpi_weight_units_bool_exp
    $offset: Int
    $limit: Int!
  ) {
    total: kpi_weight_units_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: kpi_weight_units(where: $where, offset: $offset, limit: $limit) {
      id
      code
      name
    }
  }
`

export const GET_LIST_GOAL_GROUP = gql`
  query($company: uuid!, $search: String) {
    performance_goal_groups(
      where: {company: {_eq: $company}, name: {_ilike: $search}}
    ) {
      id
      name
      color
    }
  }
`

export const CHECK_GROUP_TASK = gql`
  query($group: bigint!) {
    performance_tasks(where: {group: {_eq: $group}}) {
      id
      name
    }
  }
`

export const CHECK_GROUP_GOAL = gql`
  query($group: uuid!) {
    performance_goals(where: {group: {_eq: $group}}) {
      id
      name
    }
  }
`

export const GET_FILTER_GOAL_LABEL = gql`
  query listLabel(
    $where: performance_goal_groups_bool_exp
    $limit: Int!
    $offset: Int
  ) {
    performance_goal_groups(where: $where, offset: $offset, limit: $limit) {
      id
      name
      color
    }
    total: performance_goal_groups_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_FILTER_LABEL = gql`
  query listLabel(
    $company: uuid!
    $search: String
    $offset: Int
    $limit: Int!
    $nin: [bigint!]
  ) {
    performance_groups(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        name: {_ilike: $search}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      color
    }
    total: performance_groups_aggregate(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        name: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LIST_GROUP = gql`
  query getGroup {
    performance_groups {
      id
      name
      color
    }
  }
`
