import searchFilters, {
  getSearchVariables,
} from '../../../../utils/searchFilters'

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

export const MENUS = ['activity']

export const listFilter = active => {
  if (active === 'activity') {
    return searchFilters.concat([
      {
        name: 'Status',
        fieldName: 'status',
        type: 'checkbox',
        options: {
          list: [
            {value: 'pending', label: 'Pending'},
            {value: 'confirm1', label: 'Waiting from second approval'},
            {value: 'approved', label: 'Approved'},
            {value: 'rejected', label: 'Rejected'},
            {value: 'cancelled', label: 'Cancelled'},
          ],
        },
      },
    ])
  }

  return searchFilters
}

export const getLeaveActivityFilterVariables = ({
  tabsValue,
  startDate,
  endDate,
  searchText = '',
  filterData = {},
}) => {
  const {include = {}, exclude = {}} = filterData

  const search = `%${searchText}%`

  const where = {
    people_work_placement: getSearchVariables(filterData),

    status:
      tabsValue === 'request'
        ? {_in: ['pending', 'confirm1']}
        : include.status || exclude.status
        ? {_in: include.status, _nin: exclude.status}
        : undefined,

    datestart: {_gte: startDate, _lte: endDate},
    calendar_id: {_is_null: true},

    _or: searchText
      ? [
          {people_work_placement: {regno: {_ilike: search}}},
          {people_work_placement: {global_user: {name: {_ilike: search}}}},
          {id_display: {_ilike: search}},
        ]
      : undefined,
  }

  return where
}
