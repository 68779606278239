import {Button, Divider, Icon, IconButton, Typography} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {EnhancedToolbar, PaperContainer} from '../../../GlobalStyles'
import {
  NewTableOptions,
  newTableStyles,
} from '../../shared-components/table/TableOptions'
import {GET_HOLDING_VERSION} from '../../../graphql/queries'
import {useQuery, useMutation} from '@apollo/react-hooks'
import Loading from '../../../components/loading/LoadingComponent'
import {capitalize, holdingRolesContext} from '../../../utils/helpers'
import DeletePopup from '../../shared-components/popup/DeletePopup'
import {DELETE_HOLDING_VERSION} from '../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {TypographyStyleds} from '../../../components/typography/TypographyStyles'

function ConfiguringList(props) {
  const {enqueueSnackbar} = useSnackbar()
  const tableClasses = newTableStyles()
  const [openDelete, setOpenDelete] = useState(false)
  const [state, setState] = useState({
    id: '',
    name: '',
  })

  const {data, error, refetch} = useQuery(GET_HOLDING_VERSION, {
    variables: {search: `%${props.searchText}%`},
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const handleClickOpen = (id, name) => {
    setState({id, name})
    setOpenDelete(true)
  }

  const handleClose = () => {
    setOpenDelete(false)
  }

  const [deleteVersion] = useMutation(DELETE_HOLDING_VERSION)

  const onDeleteVersion = () => {
    const variables = {
      id: state.id,
    }

    deleteVersion({
      variables,
      context: {
        headers: {
          'X-Hasura-Role': 'holding-administrator',
        },
      },
    })
      .then(() => {
        enqueueSnackbar('Delete success', {
          variant: 'success',
          autoHideDuration: 1500,
        })
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(
          'Delete failed, please try again later' + JSON.stringify(e),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Version</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: `/group/config/add`,
              state: {active: 'add-version'},
            }}
            onClick={() => localStorage.clear()}
          >
            Add Version
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider />
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontSize: 12}}
          options={{...NewTableOptions, paging: true, sorting: true}}
          columns={[
            {
              title: 'Name',
              field: 'name',
              cellStyle: {
                wordBreak: 'break-word',
                minWidth: 150,
              },
              render: row => (
                <TypographyStyleds
                  variant="body2"
                  onClick={() => {
                    props.history.push({
                      pathname: '/group/config/' + row.id,
                      state: {active: 'detail-version'},
                    })
                  }}
                  pointer
                >
                  {row.name}
                </TypographyStyleds>
              ),
            },
            {
              title: 'Description',
              field: 'description',
              cellStyle: {
                minWidth: 160,
              },
            },
            {
              title: 'Status',
              field: 'status',
              cellStyle: {
                minWidth: 160,
              },
              render: rowData => (
                <Typography
                  style={
                    rowData.status === 'ACTIVE'
                      ? {color: 'green'}
                      : {color: 'red'}
                  }
                >
                  {capitalize(rowData.status)}
                </Typography>
              ),
            },
            {
              title: '',
              field: 'action',
              sorting: false,
              render: rowData => (
                <div style={{display: 'flex'}}>
                  <IconButton
                    onClick={() => handleClickOpen(rowData.id, rowData.name)}
                  >
                    <Icon color="secondary">delete</Icon>
                  </IconButton>
                </div>
              ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={data.holding_version}
        />
      </div>
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        id={state.id}
        name={state.name}
        mutation={onDeleteVersion}
      />
    </PaperContainer>
  )
}

export default withRouter(ConfiguringList)
