export const SwitchValue = (value, type) => {
  if (type === 'colorStatus') {
    if (value === 'approved' || value === 'Approved') {
      return '#4caf50'
    } else if (value === 'pending' || value === 'Pending') {
      return '#ffa000'
    } else {
      return '#ef4d5e'
    }
  } else {
    if (value > 75) {
      return 'Outstanding'
    } else if (value > 35 && value <= 75) {
      return 'Almost'
    } else if (value > 0 && value <= 35) {
      return 'Poor'
    }
  }
}
