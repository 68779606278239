import {COMPANY_ID} from './globals'

import {
  GET_OFFICE_LOCATION_FILTER,
  GET_JOB_GRADE_FILTER,
  GET_JOB_LEVEL_FILTER,
  GET_JOB_TITLE_FILTER,
  GET_ORGANIZATION_UNIT_FILTER,
  GET_POSITION_FILTER,
} from '../graphql/queries/filters/searchFilter'

export const createGetVariables = (options = {}) => {
  const {
    searchKeys = ['name', 'code'],
    status,
    statusEq = '_ilike',
    company = true,
    idKey = 'id',
    deletedAtKey = 'deletedAt',
  } = options

  const getVariables = ({search, limit, _in, _nin}) => {
    return {
      limit,
      where: {
        company: company ? {_eq: COMPANY_ID} : undefined,

        id: idKey === 'id' ? {_in, _nin} : undefined,
        value: idKey === 'value' ? {_in, _nin} : undefined,

        status: status ? {[statusEq]: status} : undefined,

        deletedAt: deletedAtKey === 'deletedAt' ? {_is_null: true} : undefined,
        deleted_at:
          deletedAtKey === 'deleted_at' ? {_is_null: true} : undefined,

        _or: search
          ? searchKeys.map(key => ({[key]: {_ilike: search}}))
          : undefined,
      },
    }
  }

  return getVariables
}

export const getDataCode = result => {
  const count = result.total.aggregate.count

  const options = result.items.map(item => ({
    value: item.id,
    label: `${item.code} - ${item.name}`,
  }))

  return [options, count]
}

export const getDataId = result => {
  const count = result.total.aggregate.count

  const options = result.items.map(item => ({
    value: item.id,
    label: item.name,
  }))

  return [options, count]
}

export const getDataValue = result => {
  const count = result.total.aggregate.count

  const options = result.items.map(item => ({
    value: item.value,
    label: item.name,
  }))

  return [options, count]
}

const assignFilters = [
  {
    name: 'Unit Organisasi',
    fieldName: 'organizationUnit',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_ORGANIZATION_UNIT_FILTER,
        getVariables: createGetVariables(),
        setData: getDataCode,
      },
    },
  },
  {
    name: 'Jenjang Jabatan',
    fieldName: 'jobLevel',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_JOB_LEVEL_FILTER,
        getVariables: createGetVariables(),
        setData: getDataCode,
      },
    },
  },
  {
    name: 'Kelas Jabatan',
    fieldName: 'jobGrade',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_JOB_GRADE_FILTER,
        getVariables: createGetVariables(),
        setData: getDataCode,
      },
    },
  },
  {
    name: 'Jabatan',
    fieldName: 'jobTitle',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_JOB_TITLE_FILTER,
        getVariables: createGetVariables({
          searchKeys: ['name', 'designation_code'],
          status: 'ACTIVE',
        }),
        setData: getDataCode,
      },
    },
  },
  {
    name: 'Posisi',
    fieldName: 'position',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_POSITION_FILTER,
        getVariables: createGetVariables({searchKeys: ['title', 'code']}),
        setData: getDataCode,
      },
    },
  },
  {
    name: 'Lokasi Kantor',
    fieldName: 'officeLocation',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_OFFICE_LOCATION_FILTER,
        getVariables: createGetVariables({
          searchKeys: ['office_name', 'office_code'],
          status: 'ACTIVE',
        }),
        setData: getDataCode,
      },
    },
  },
]

export default assignFilters

const persistFields = {
  org_level: 'organizationLevel',
  org_unit: 'organizationUnit',
  job_level: 'jobLevel',
  job_grade: 'jobGrade',
  job_title: 'jobTitle',
  position: 'position',
  office: 'officeLocation',
}

export const manipulateFilters = data => {
  const {include, exclude} = data

  const inc = {}
  const exc = {}

  for (const p in include) {
    if (include[p] && include[p].length > 0) {
      inc[p] = include[p]
    }
  }

  for (const p in exclude) {
    if (exclude[p] && exclude[p].length > 0) {
      exc[p] = exclude[p]
    }
  }

  return {include: inc, exclude: exc}
}

export const parseFilters = state => {
  const include = {}
  const exclude = {}

  let hasInclude = false
  let hasExclude = false

  if (state) {
    for (const from in persistFields) {
      const to = persistFields[from]

      const inc = state[`filter_include_${from}`]
      const exc = state[`filter_exclude_${from}`]

      if (inc) {
        hasInclude = true
        include[to] = inc
      }

      if (exc) {
        hasExclude = true
        exclude[to] = exc
      }
    }
  }

  if (!hasInclude && !hasExclude) {
    return undefined
  }

  return {include, exclude}
}

export const formatFilters = state => {
  const result = {}

  if (state) {
    for (const from in persistFields) {
      const to = persistFields[from]
      const map = state[to]

      if (map) {
        result[`filter_include_${from}`] = map._in
        result[`filter_exclude_${from}`] = map._nin
      }
    }
  }

  return result
}

export const getPositionFilterVariables = ({
  searchText = '',
  filterData = {},
}) => {
  const {include = {}, exclude = {}} = filterData

  const search = `%${searchText}%`

  return {
    company: {_eq: COMPANY_ID},
    _or: searchText
      ? [{code: {_ilike: search}}, {title: {_ilike: search}}]
      : undefined,
    id:
      include.position || exclude.position
        ? {_in: include.position, _nin: exclude.position}
        : undefined,
    rank:
      include.jobLevel || exclude.jobLevel
        ? {_in: include.jobLevel, _nin: exclude.jobLevel}
        : undefined,
    job_grade_id:
      include.jobGrade || exclude.jobGrade
        ? {_in: include.jobGrade, _nin: exclude.jobGrade}
        : undefined,
    office_address:
      include.officeLocation || exclude.officeLocation
        ? {_in: include.officeLocation, _nin: exclude.officeLocation}
        : undefined,
    company_employee_position: {
      id:
        include.jobTitle || exclude.jobTitle
          ? {_in: include.jobTitle, _nin: exclude.jobTitle}
          : undefined,
      job_mapping_id:
        include.jobFunction || exclude.jobFunction
          ? {_in: include.jobFunction, _nin: exclude.jobFunction}
          : undefined,
      family_id:
        include.jobFamily || exclude.jobFamily
          ? {_in: include.jobFamily, _nin: exclude.jobFamily}
          : undefined,
    },
    company_organization: {
      id:
        include.organizationUnit || exclude.organizationUnit
          ? {_in: include.organizationUnit, _nin: exclude.organizationUnit}
          : undefined,
      organization_level_id:
        include.organizationlevel || exclude.organizationlevel
          ? {_in: include.organizationlevel, _nin: exclude.organizationlevel}
          : undefined,
    },
  }
}
