import React from 'react'
import {
  Dialog,
  Typography,
  IconButton,
  Grid,
  makeStyles,
} from '@material-ui/core'
import {
  ModalHeader,
  FlexBetween,
  WhiteIcon,
  ModalContentEvent,
} from '../../../../GlobalStyles'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'

const useStyles = makeStyles(() => ({
  iconWrapper: {
    height: '40px',
    width: '40px',
    padding: '10px',
    border: '1px solid #BCBCBC',
    borderRadius: '3px',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid #039be5',
    },
  },
}))

const PopupIcon = props => {
  const {open, handleClose, icons, icon, setIcon} = props
  const classes = useStyles()

  const handleSelect = val => {
    handleClose()
    setIcon(val)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      scroll="paper"
      maxWidth="sm"
    >
      <ModalHeader style={{display: 'block'}}>
        <FlexBetween>
          <Typography variant="h6">Pilih Ikon</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <WhiteIcon>close</WhiteIcon>
          </IconButton>
        </FlexBetween>
      </ModalHeader>
      <ModalContentEvent
        dividers={true}
        style={{padding: '1.2rem 2.8rem', minHeight: 400}}
      >
        <Grid container spacing={3}>
          {icons && icons.length > 0 ? (
            icons.map(val => {
              return (
                <Grid item xs={2} key={val.id}>
                  <div
                    className={classes.iconWrapper}
                    onClick={() => handleSelect(val)}
                    style={{
                      border:
                        icon?.id === val.id || icon?.source === val.source
                          ? '1px solid #039be5'
                          : '1px solid #BCBCBC',
                    }}
                  >
                    <img
                      alt={val.label}
                      src={val.source}
                      style={{width: '100%', height: '100%'}}
                    />
                  </div>
                </Grid>
              )
            })
          ) : (
            <NoDataListComponent message={``} />
          )}
        </Grid>
      </ModalContentEvent>
    </Dialog>
  )
}

export default PopupIcon
