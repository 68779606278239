import gql from 'graphql-tag'

export const DELETE_LOCATION_RESTRICTION = gql`
  mutation UpdateLocationRestriction($id: Int, $deleteAt: timestamp) {
    update_time_attendance_location(
      _set: {deletedAt: $deleteAt}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
