import {Dialog, Icon, IconButton} from '@material-ui/core'
import React from 'react'
import {WrapModalTemates} from '../DetailEmployeeStyles'

export default function ModalPopup({
  onClose,
  open,
  title,
  maxWidth = 'md',
  children,
}) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      scroll="body"
      maxWidth={maxWidth}
    >
      <WrapModalTemates>
        <div className="header">
          <h1 className="title">{title}</h1>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className="wrap-close"
          >
            <Icon className="icon-close">close</Icon>
          </IconButton>
        </div>
        <div className="content">{children}</div>
      </WrapModalTemates>
    </Dialog>
  )
}
