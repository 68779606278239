import React from 'react'
import {
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {Typography, makeStyles} from '@material-ui/core'
import UploadButton from '../../../../components/attachment/UploadButton'

const useStyles = makeStyles(() => ({
  textTooltip: {
    fontSize: 12,
    color: '#000',
    display: 'block',
    margin: '2px 0px',
  },
  textBlue: {
    color: '#014a62',
    fontSize: 12,
    display: 'block',
    margin: '2px 0px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  button: {
    marginTop: 22,
  },
}))

const ContentTooltip = () => {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.textTooltip}>
        Ekstensi file yang sesuai:
      </Typography>
      <div className={classes.tooltipWrapper}>
        <Typography className={classes.textTooltip}>Document {'( '}</Typography>
        <Typography className={classes.textBlue}>
          .doc, .docx, .xls, .pdf, .xlsx, .ppt
        </Typography>
        <Typography className={classes.textTooltip}>{' )'}</Typography>
      </div>
      <div className={classes.tooltipWrapper}>
        <Typography className={classes.textTooltip}>Image {'( '}</Typography>
        <Typography className={classes.textBlue}>
          .jpg, .jpeg, .png, .gif, .tiff, .pjp, .pjpeg, .jfif, .tif
        </Typography>
        <Typography className={classes.textTooltip}>{' )'}</Typography>
      </div>
      <Typography className={classes.textTooltip}>
        Ukuran file maksimum 25MB setiap file
      </Typography>
      <Typography className={classes.textTooltip}>
        Dapat melampirkan hingga 5 file
      </Typography>
    </>
  )
}

export default function AttachementComponent({
  number,
  name,
  disabled,
  onUpload,
}) {
  const styles = useStyles()
  const length = number.split('').length

  return (
    <FormChildContainer>
      <FormChildTitle>
        <TitleNumber style={length === 2 ? {paddingRight: 10} : null}>
          {number}.
        </TitleNumber>
        <TitleName style={{display: 'flex', alignItems: 'center'}}>
          {name}{' '}
          <GenerateTooltip title={<ContentTooltip />} placement="right">
            <InfoOutlinedIcon
              style={{marginLeft: 7, width: 16}}
              color="primary"
            />
          </GenerateTooltip>
        </TitleName>
      </FormChildTitle>
      <FormChildInput>
        <UploadButton
          multiple
          disabled={disabled}
          onChange={onUpload}
          className={styles.button}
        />
      </FormChildInput>
    </FormChildContainer>
  )
}
