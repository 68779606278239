import React from 'react'
import {
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
} from '../../../../GlobalStyles'
import {MenuItem} from '@material-ui/core'
import {TextFieldStyled, WrapperDisable} from '../EmployeePageStyles'

export default function ComponentSelect(props) {
  const {
    id,
    number,
    name,
    child,
    error,
    placeholder = 'Select',
    ...other
  } = props
  const length = number.split('').length
  return (
    <FormChildContainer id={id}>
      <FormChildTitle>
        <TitleNumber style={length === 2 ? {paddingRight: 10} : null}>
          {number}.
        </TitleNumber>
        <TitleName>{name}</TitleName>
      </FormChildTitle>
      <FormChildInput>
        <WrapperDisable>
          <TextFieldStyled
            fullWidth
            size="small"
            margin="normal"
            variant="outlined"
            select
            value="religion"
            error={error}
            helperText={error ? 'Bagian ini diperlukan' : null}
            {...other}
          >
            <MenuItem value="default" disabled>
              {placeholder}
            </MenuItem>
            {child}
          </TextFieldStyled>
        </WrapperDisable>
      </FormChildInput>
    </FormChildContainer>
  )
}
