import gql from 'graphql-tag'

export const ADD_LOCATION_RESTRICTION = gql`
  mutation addLocationRestriction(
    $data: [time_attendance_location_insert_input!]!
  ) {
    insert_time_attendance_location(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`
