import {useMutation} from '@apollo/react-hooks'
import {
  Button,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core'
import React, {useState} from 'react'
import helperStyle, {Flex, FlexCenters} from '../../../../../GlobalStyles'
import {DELETE_QUESTION_MICROLEARNING} from '../../../../../graphql/mutations/learning/micro-learning/addQuestionMicrolearning.mutation'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import CreateQuestion from './CreateQuestion'

export default function ManageQuestion({
  style,
  data,
  microlearningId,
  refetch,
  isError,
}) {
  const classes = helperStyle()
  const [openAdd, setOpenAdd] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [selected, setSelected] = useState(null)

  const [mutationDeleteQuestion] = useMutation(DELETE_QUESTION_MICROLEARNING)

  const handleOpenAdd = () => {
    setOpenAdd(true)
  }

  const handleEditDelete = (e, isEdit, val) => {
    e.stopPropagation()
    setSelected(val)
    if (isEdit) {
      setOpenAdd(true)
    } else {
      setOpenDelete(true)
    }
  }

  const onDelete = () => {
    mutationDeleteQuestion({
      variables: {question_id: selected.id},
    })
      .then(() => {
        refetch()
      })
      .catch(e => e)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.marginBot28}
        onClick={handleOpenAdd}
      >
        Tambah pertanyaan
      </Button>
      {isError && !data?.length && (
        <Typography style={{color: 'red'}}>
          Silahkan tambahkan pertanyaan dan jawaban
        </Typography>
      )}
      {openAdd && (
        <CreateQuestion
          microlearningId={microlearningId}
          style={style}
          setOpenAdd={setOpenAdd}
          refetch={refetch}
          selected={selected}
          setSelected={setSelected}
        />
      )}
      {data &&
        data.map((res, i) => (
          <ExpansionPanel style={style.expansion} key={i}>
            <ExpansionPanelSummary classes={{content: classes.itemsCenter}}>
              {/* <Icon style={style.grey} className="mr-16">
                menu
              </Icon> */}
              <div
                className={classes.itemsCenter}
                style={{
                  paddingRight: 0,
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <FlexCenters>
                  <Typography
                    style={{width: 130, fontWeight: 600, marginRight: 12}}
                  >
                    Pertanyaan {i + 1} :
                  </Typography>
                  <div
                    className={classes.widthFull}
                    dangerouslySetInnerHTML={{__html: res.question}}
                  ></div>
                </FlexCenters>
                <Flex>
                  <IconButton
                    className={classes.marginRight10}
                    onClick={e => handleEditDelete(e, true, res)}
                  >
                    <Icon style={style.grey}>create</Icon>
                  </IconButton>
                  <IconButton onClick={e => handleEditDelete(e, false, res)}>
                    <Icon style={style.grey}>delete</Icon>
                  </IconButton>
                </Flex>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{display: 'block'}}>
              {res.microlearning_question_answers.map(opt => {
                return res.question_type === 0 ? (
                  <FlexCenters className={classes.marginLeft10} key={opt.id}>
                    <Radio checked={opt.is_correct} color="primary" />
                    <Typography>{opt.answer}</Typography>
                  </FlexCenters>
                ) : (
                  <FlexCenters className={classes.marginLeft10} key={opt.id}>
                    <Checkbox checked={opt.is_correct} color="primary" />
                    <Typography>{opt.answer}</Typography>
                  </FlexCenters>
                )
              })}
              {/* untuk short answer onhold */}
              {/* <TextField
                variant="outlined"
                multiline
                rows="4"
                fullWidth
                value="this is the answer"
                className="bg-white"
              /> */}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      <DeletePopup
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        mutation={onDelete}
        name="question"
      />
    </>
  )
}
