import {
  Button,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core'
import React from 'react'
import {Link} from 'react-router-dom'
import {ArrowBack} from '@material-ui/icons'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  spacer: {
    flex: '1',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
  },
  datePickerAction: {
    marginRight: 30,
  },
  buttonAction: {
    height: 61,
    marginTop: 6,
    width: 200,
  },
  buttonText: {
    textDecoration: 'none',
    color: '#fff',
  },
  title: {
    flex: '0 0 auto',
    marginLeft: 12,
    marginRight: 'auto',
  },
  uploadButton: {
    color: '#fff',
    textDecoration: 'none',
  },
  buttonSpacer: {marginRight: 12},
}))

const FormToolbarWS = ({
  handleClickOpen,
  tabsValue,
  steps,
  handleNext,
  handleBack,
  isEdit,
}) => {
  const classes = useToolbarStyles()

  return (
    <Toolbar disableGutters>
      <Link
        to={{
          pathname: '/time/attendance/work_schedule',
          state: {searchable: true, active: 'work-schedule'},
        }}
      >
        <IconButton edge="end" aria-label="back">
          <ArrowBack />
        </IconButton>
      </Link>

      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {isEdit ? 'Edit' : 'Add'} Work Schedule
        </Typography>
      </div>
      {isEdit ? (
        <>
          <Button
            size="large"
            className={classes.buttonSpacer}
            onClick={() => handleClickOpen('discard')}
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.buttonSpacer}
            onClick={handleNext}
          >
            Save Changes
          </Button>
        </>
      ) : (
        <>
          {tabsValue === 0 ? (
            <Button
              size="large"
              className={classes.buttonSpacer}
              onClick={() => handleClickOpen('discard')}
            >
              Cancel
            </Button>
          ) : (
            <Button
              size="large"
              onClick={handleBack}
              className={classes.buttonSpacer}
            >
              Previous
            </Button>
          )}

          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.buttonSpacer}
            onClick={handleNext}
          >
            {tabsValue === steps.length - 1 ? 'Submit' : 'Next'}
          </Button>
        </>
      )}
    </Toolbar>
  )
}

export default FormToolbarWS
