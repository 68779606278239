import gql from 'graphql-tag'

export const ADD_JOB_PROFILE = gql`
  mutation(
    $title: String
    $icon: String
    $parent: Int
    $organization: Int
    $position: Int
    $rank: Int
    $office: Int
    $jobdesc: String
    $jobresp: String
  ) {
    insert_company_job_profiles(
      objects: {
        title: $title
        job_icon: $icon
        parent: $parent
        organization: $organization
        position: $position
        rank: $rank
        office_address: $office
        job_summary: $jobdesc
        job_description: $jobresp
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
