import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  FormContainer,
  AddEditTitle,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  FormChildButton,
} from '../../../../GlobalStyles'
import {
  Divider,
  IconButton,
  Toolbar,
  TextField,
  Button,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Link, withRouter} from 'react-router-dom'
import {useMutation, useApolloClient} from '@apollo/react-hooks'
import {UPDATE_GROUP_QUESTION_BANK} from '../../../../graphql/mutations'
import {GET_DETAIL_GROUP_QUESTION_BANK} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'

function EditGroupQuestionBank(props) {
  const {id} = props.match.params
  const {enqueueSnackbar} = useSnackbar()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)
  const client = useApolloClient()

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  const [mutationEditGroupQuestion] = useMutation(UPDATE_GROUP_QUESTION_BANK)

  useEffect(() => {
    async function fetchData() {
      const {data} = await client.query({
        query: GET_DETAIL_GROUP_QUESTION_BANK,
        variables: {
          company: COMPANY_ID,
          id: id,
        },
      })
      if (!name || !description) {
        setName(data.multirater_bank_groups[0].name)
        setDescription(data.multirater_bank_groups[0].description)
      }
    }
    fetchData()
  }, [name, description, id, COMPANY_ID])

  const editGroupQuestion = () => {
    if (name === '' || description === '') {
      const ids = document.getElementById('top')
      ids.scrollIntoView({behavior: 'smooth'})
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const data = {
        id,
        name,
        description,
      }

      mutationEditGroupQuestion({variables: data})
        .then(() => {
          enqueueSnackbar('Berhasil mengubah data', {variant: 'success'})
          props.history.push({
            pathname: `/survey/multirater-nps/question-bank`,
            state: {active: 'question-bank'},
          })
        })
        .catch(() => {
          enqueueSnackbar('Gagal mengubah data', {
            variant: 'error',
          })
        })
    }
  }
  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link
          to={{
            pathname: `/survey/multirater-nps/question-bank`,
            state: {active: 'question-bank'},
          }}
        >
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <AddEditTitle variant="h6">Ubah Grup Umpan Balik</AddEditTitle>
      </Toolbar>
      <Divider></Divider>
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Nama *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                setName(event.target.value)
              }}
              variant="outlined"
              value={name}
              error={name && name.length < 1 && isError}
              helperText={
                name && name.length < 1 && isError
                  ? 'Bagian ini diperlukan'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Keterangan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                setDescription(event.target.value)
              }}
              variant="outlined"
              value={description}
              error={description && description.length < 1 && isError}
              helperText={
                description && description.length < 1 && isError
                  ? 'Bagian ini diperlukan'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <FormChildButton>
        <Button
          color="default"
          size="large"
          component={Link}
          to={{
            pathname: `/survey/multirater-nps/question-bank`,
            state: {active: 'question-bank'},
          }}
          style={{marginRight: 12}}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          size="large"
        >
          Simpan
        </Button>
      </FormChildButton>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        header="Ubah Grup Umpan Balik"
        body="Apakah Anda yakin ingin mengubah grup umpan balik?"
        button="Ubah"
        buttonClose="Batal"
        mutation={editGroupQuestion}
      />
    </PaperContainer>
  )
}

export default withRouter(EditGroupQuestionBank)
