import moment from 'moment'

/**
 * * getMonthlyAccuralQuota - Monthly Accural Quota Functions
 *
 * ex:
 * maksimal kuota pertahun ada 5, berarti kalo monthly accural itu 5/12=0.4
 * 0.4 kuota di tambah perbulannya, jadi di bulan ketiga totalnya menjadi 1.2
 *
 * * quota is reset every year in the month the user joins
 * * see: https://docs.google.com/spreadsheets/d/1yWPKwLOKks-AQkl4peRpu6MZgiIVm2J-DdpNGZ7419U/edit#gid=796791265
 *
 * @param {number} totalQuota - maximum policy quota
 * @param {string} peopleJoinDate - people joined date
 * @returns {number} - the quota obtained this month
 */
export const getMonthlyAccuralQuota = (totalQuota, peopleJoinDate) => {
  const quotaPerMonth = totalQuota / 12
  const firstDayJoindate = moment(peopleJoinDate)
    .startOf('month')
    .format('YYYY-MM-DD')

  const now = moment()
  const year = now.format('Y')
  const month = now.format('M')
  const day = now.format('D')

  const monthDiff = Math.ceil(
    moment([year, month - 1, day]).diff(firstDayJoindate, 'months', true)
  )

  const multiplier = getMultiplierAccual(monthDiff - 1)

  return Math.floor(quotaPerMonth * multiplier * 10) / 10
}

/**
 * function used to get quota multiplier
 * @param {number} monthDiff - month difference between join date and current month
 * if join month August and current month August => monthDiff: 0
 * if join month July and current month August => monthDiff: 1
 * if join month June and current month August => monthDiff: 2
 * @returns {number} - quota multiplier every month
 */
const getMultiplierAccual = monthDiff => {
  const enhancer = monthDiff >= 12 ? 1 : 0
  return (monthDiff % 12) + enhancer
}
