import React from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Box,
  Toolbar,
  Divider,
  Typography,
  IconButton,
  Icon,
  CircularProgress,
} from '@material-ui/core'

import {PaperContainer} from '../../../../../GlobalStyles'
import NoDataListFree from '../../../../shared-components/NoDataListFreeComponent'
import EmployeeBalanceTransactionList from '../EmployeeTransactionList'

import {
  GET_EMPLOYEE_SUMMARY,
  GET_EMPLOYEE_TRANSACTION_HISTORY,
} from '../../../../../graphql/queries/budget/limit/getEmployeeBalance.query'
import {getEmployeeTransactionFilterVariables} from '../../filters'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
}))

function EmployeeHistory(props) {
  const styles = useStyles()

  const {searchText, filterData} = props
  const {employeeID} = props.match.params

  const {data, loading, error} = useQuery(GET_EMPLOYEE_SUMMARY, {
    wlb_skipPatch: true,
    variables: {
      employeePlacementID: employeeID,
    },
  })

  return (
    <PaperContainer>
      <Toolbar>
        <IconButton
          aria-label="Go back to previous page"
          onClick={props.history.goBack}
          edge="start"
          className={styles.backButton}
        >
          <Icon>arrow_back</Icon>
        </IconButton>

        {(!loading || !error) && (
          <Typography variant="subtitle1" style={{fontWeight: '600'}}>
            Transaction History for {data?.summary.user.name}
          </Typography>
        )}
      </Toolbar>

      <Divider />

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <div>{JSON.stringify(error)}</div>
      ) : (
        <EmployeeTransactionsList
          employeeName={data.summary.user.name}
          employeeID={employeeID}
          userID={data.summary.user.id}
          searchText={searchText}
          filterData={filterData}
        />
      )}
    </PaperContainer>
  )
}

export default withRouter(EmployeeHistory)

function EmployeeTransactionsList(props) {
  const {employeeName, employeeID, userID, searchText, filterData} = props

  const [pagination, setPagination] = React.useState({
    offset: 0,
    limit: 20,
  })

  const where = React.useMemo(() => {
    return getEmployeeTransactionFilterVariables({
      userId: userID,
      searchText,
      filterData,
    })
  }, [userID, searchText, filterData])

  const {data, loading, error} = useQuery(GET_EMPLOYEE_TRANSACTION_HISTORY, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: where,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const handleChangeOffset = offset => {
    setPagination({...pagination, offset})
  }

  const handleChangeLimit = limit => {
    setPagination({...pagination, offset: 0, limit})
  }

  React.useLayoutEffect(() => {
    handleChangeOffset(0)
  }, [filterData])

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  if (!loading && !data?.total.aggregate.count) {
    return (
      <NoDataListFree
        message1="Sorry, No List"
        message2={`${employeeName} doesn't have any transactions yet`}
      />
    )
  }

  return (
    <EmployeeBalanceTransactionList
      employeeID={employeeID}
      data={data?.transactions || []}
      totalCount={data?.total.aggregate.count || 0}
      loading={loading}
      offset={pagination.offset}
      limit={pagination.limit}
      onChangeOffset={handleChangeOffset}
      onChangeLimit={handleChangeLimit}
    />
  )
}
