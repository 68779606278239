/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
// import {Filter} from '@worklifebeyond/wlb-utils-components'

import Activity from './activity/index'
import CreateLoanActivity from './activity/CreateActivityPage'
import EditLoanActivity from './activity/EditActivityPage'
import LoanProcessed from './loan-processed/index'
import Settings from './settings/index'
import CreateEditPolicyPageWrapper from './settings/CreateEditPolicyPageWrapper'

import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'
import RequestWrapper from '../../shared-components/layouts/RequestWrapper'
// import {
//   // FILTER_ANCHOR_ORIGIN,
//   // EMPLOYEE_FILTER_CONFIG,
//   // FILTER_TRANSFORM_ORIGIN,
//   mapFilterResults,
// } from '../../shared-components/employee-filter-plugin'
import {
  // BlueIcon,
  // ButtonFilter,
  // FilterText,
  PageContainer,
} from '../../../GlobalStyles'

import {COMPANY_ID} from '../../../utils/globals'
import {GET_LOAN_POLICY} from '../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../utils'

const LoanPage = props => {
  const [filterData, setFilterData] = useState({})
  const [searchText, setSearchText] = useState('')

  const {loading, error, data} = useQuery(GET_LOAN_POLICY, {
    variables: {
      company: COMPANY_ID,
      limit: 1,
    },
    fetchPolicy: 'cache-and-network',
    ...GQL_FINANCE_OPTIONS,
  })

  const isAbleToCreateLoan = data?.finance_loan_policies?.length > 0
  let activeTab = ''
  let activeComponent = ''
  let state = {}

  if (props?.location?.pathname) {
    const active = props.location.pathname?.split('/')
    state = props?.location?.state
    activeComponent = state?.active || 'activity'

    if (active?.includes('loan-processed')) {
      activeTab = 'loan-processed'
    } else if (active?.includes('settings')) {
      activeTab = 'settings'
    } else {
      activeTab = 'activity'
    }
  }

  // const handleFilterApply = result => {
  //   const include = mapFilterResults(result[0])
  //   const exclude = mapFilterResults(result[1])

  //   setFilterData({include, exclude})
  // }

  /**
   * TODO: put attention, it caused error console
   */
  const onSearch = search => {
    setSearchText(search)
  }

  const renderComponent = ({activeComponent, ...rest}) => {
    const COMPONENTS = {
      activity: <Activity title="Activity" {...rest} />,
      'create-loan-activity': <CreateLoanActivity title="Activity" {...rest} />,
      'edit-loan-activity': <EditLoanActivity title="Activity" {...rest} />,
      'loan-processed': <LoanProcessed title="Processed" {...rest} />,
      settings: <Settings title="Settings" {...rest} />,
      'create-loan-setting-policy': (
        <CreateEditPolicyPageWrapper title="Policy" {...rest} />
      ),
      'edit-loan-setting-policy': (
        <CreateEditPolicyPageWrapper title="Policy" {...rest} />
      ),
    }

    return COMPONENTS[activeComponent] || COMPONENTS.activity
  }

  return (
    <div>
      <Card
        icon="monetization_on"
        title="Loan"
        onSearch={onSearch}
        searchable={state?.searchable}
        // Hide: waiting for query optimization
        // endAdornment={
        //   EMPLOYEE_FILTER_CONFIG &&
        //   activeComponent !== 'settings' && (
        //     <Filter
        //       id="filter-employee-wlb"
        //       onApply={handleFilterApply}
        //       listFilter={EMPLOYEE_FILTER_CONFIG}
        //       anchorOrigin={FILTER_ANCHOR_ORIGIN}
        //       transformOrigin={FILTER_TRANSFORM_ORIGIN}
        //     >
        //       <ButtonFilter position="end">
        //         <BlueIcon>filter_list</BlueIcon>
        //         <FilterText variant="body2">Filter</FilterText>
        //       </ButtonFilter>
        //     </Filter>
        //   )
        // }
      >
        <PageContainer>
          <WlbTab data={TABS} activeMenu={activeTab} />

          <RequestWrapper error={error} loading={loading}>
            {renderComponent({
              activeComponent,
              isAbleToCreateLoan,
              state,
              searchText,
              filterData,
            })}
          </RequestWrapper>
        </PageContainer>
      </Card>
    </div>
  )
}

export default withRouter(LoanPage)

export const TABS = [
  {
    label: 'Activity',
    state: 'activity',
    url: '/financial/loan/activity',
    searchable: true,
  },
  /**
   * Hide: API is not available yet
  {
      label: 'Loan Processed',
      state: 'loan-processed',
      url: '/financial/loan/loan-processed',
      searchable: true,
    },
   */
  {
    label: 'Settings',
    state: 'settings',
    url: '/financial/loan/settings',
    searchable: true,
  },
]
