import gql from 'graphql-tag'

export const DELETE_KPI_MONITORING = gql`
  mutation updateKPIMonitoring($id: Int!) {
    update_kpi_monitoring_frequencies(
      _set: {deleted_at: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const DELETE_MULTIPLE_KPI_MONITORING = gql`
  mutation($id: [Int!]!) {
    update_kpi_monitoring_frequencies(
      _set: {deleted_at: "now()"}
      where: {id: {_in: $id}}
    ) {
      affected_rows
    }
  }
`
