import React from 'react'
import EmployeeReport from './page-download/EmployeeReport'
import {PageContainer} from '../../../GlobalStyles'
import Card from '../../../components/card/CardComponent'

const renderComponent = activeComponent => {
  switch (activeComponent) {
    default:
      return <EmployeeReport />
  }
}

export default function DownloadReportComponent(props) {
  let active = ''
  // let state = {};
  if (props.location.state) {
    active = props.location.state.active
    // state = props.location.state;
  }

  return (
    <Card icon="people" title="Pegawai">
      <PageContainer>{renderComponent(active)}</PageContainer>
    </Card>
  )
}
