import { useApolloClient } from '@apollo/react-hooks'
import {
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  StyledReactSelect,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import { GET_LEAVE_PARENT_POLICY_NAME } from '../../../../../graphql/queries'
import {selectProps} from '../../../../../utils/helpers'
import {useStyles} from '../AddLeavePolicyChildComponent'
import { selectStyle } from '../Constants'

const confirmationTypes = [
  {
    name: 'Direct Supervisor',
    value: '1',
  },
  {
    name: 'Position',
    value: '2',
  },
  {
    name: 'No Approval',
    value: '3',
  },
]

const secondConfirmations = [
  {
    name: 'Leave can be taken',
    value: '1',
  },
  {
    name: 'Second confirmation',
    value: '2',
  },
]

export const EditDetailPolicyTabChild = ({
  name,
  setName,
  selectedParent,
  setSelectedParent,
  balance,
  setBalance,
  infiniteQuota,
  setInfiniteQuota,
  selectedConfirmationType,
  setSelectedConfirmationType,
  selectedPosition,
  setSelectedPosition,
  handleSearch,
  secondConfirmationState,
  setSecondConfirmationState,
  selectedPositionSecond,
  setSelectedPositionSecond,
  gender,
  setGender,
  isError,
  detail,
  nameChecker,
  dataJP,
  loading,
}) => {
  const client = useApolloClient()
  const classes = useStyles()

  const onConfirmationChange = event => {
    setSelectedConfirmationType(event.target.value)
    setSelectedPosition(null)
  }

  const getLeaveParent = async (search) => {
    const { data } = await client.query({
      query: GET_LEAVE_PARENT_POLICY_NAME,
      variables: { name: `%${search}%`, limit: 20 },
    });
    return data?.time_leave_policies?.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  };

  return (
    <>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Leave and Permission Name *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            id="leave-name"
            InputProps={{
              classes: {
                input: classes.outlinedInput,
              },
            }}
            placeholder="Leave and Permission Name"
            fullWidth
            margin="normal"
            onChange={event => {
              setName(event.target.value)
            }}
            variant="outlined"
            value={name}
            error={(name.length < 1 && isError) || (nameChecker && isError)}
            helperText={
              name.length < 1 && isError
                ? 'This field is required'
                : nameChecker && isError
                ? 'Name is already exist'
                : ''
            }
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Part of Leave and Permission *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <StyledReactSelect
            isAsync
            cacheOptions
            defaultOptions
            value={selectedParent}
            loadOptions={getLeaveParent}
            onChange={newValue => setSelectedParent(newValue)}
            placeholder="Select part of leave and permission"
            style={selectStyle(!selectedParent && isError)}
          />
          {!selectedParent && isError && (
            <Typography style={{marginLeft: 14}} variant="caption" color="error">
              This field is required
            </Typography>
          )}
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>Employee Leave and Permission Balance *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <div className={classes.flexCenter}>
            <TextField
              id="employee-balance"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
                endAdornment: (
                  <InputAdornment position="end">Days</InputAdornment>
                ),
              }}
              className={classes.buttonSpacer}
              margin="normal"
              onChange={event => {
                setBalance(event.target.value)
              }}
              type="number"
              variant="outlined"
              value={balance || detail.quota}
              error={parseInt(balance) === 0 && !infiniteQuota && isError}
              helperText={
                parseInt(balance) === 0 && !infiniteQuota && isError
                  ? 'This field is required'
                  : ''
              }
              disabled={
                infiniteQuota === undefined
                  ? detail.infinite_quota
                  : infiniteQuota
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={
                    infiniteQuota === undefined
                      ? detail.infinite_quota
                      : infiniteQuota
                  }
                  onChange={event => {
                    setInfiniteQuota(event.target.checked)
                  }}
                  color="primary"
                  inputProps={{'aria-label': 'primary checkbox'}}
                />
              }
              label="Unlimited"
            />
          </div>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer style={{paddingBottom: 0}}>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>Leave and Permission Approval From *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            id="part-of-leave"
            InputProps={{
              classes: {
                input: classes.outlinedInput,
              },
            }}
            select
            fullWidth
            onChange={onConfirmationChange}
            margin="normal"
            variant="outlined"
            value={selectedConfirmationType || detail.confirm_type}
            SelectProps={selectProps(selectedConfirmationType)}
          >
            <MenuItem value="" disabled>
              Select approval from
            </MenuItem>
            {confirmationTypes.map((type, key) => {
              return (
                <MenuItem key={key} value={type.value}>
                  {type.name}
                </MenuItem>
              )
            })}
          </TextField>
        </FormChildInput>
      </FormChildContainer>

      {selectedConfirmationType === confirmationTypes[1].value && (
        <FormChildContainer style={{paddingBottom: 0}}>
          <FormChildInput>
            <StyledReactSelect
              value={selectedPosition}
              options={dataJP}
              onChange={v => {
                setSelectedPosition(v)
              }}
              placeholder="Select Position"
              isLoading={loading}
              onInputChange={e => handleSearch(e)}
            />
            {isError && !selectedPosition && (
              <Typography
                variant="caption"
                style={{color: 'red', marginTop: 10}}
              >
                This field is required
              </Typography>
            )}
          </FormChildInput>
        </FormChildContainer>
      )}
      <FormChildContainer style={{padding: '1rem 0 0 0'}}>
        <FormChildTitle>
          <TitleNumber>5.</TitleNumber>
          <TitleName>After Getting Approval Then *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            id="select-position"
            InputProps={{
              classes: {
                input: classes.outlinedInput,
              },
            }}
            select
            fullWidth
            onChange={event => {
              // setSelectedPosition(event.target.value);
              setSecondConfirmationState(event.target.value)
              if (event.target.value === '1') {
                setSelectedPositionSecond(null)
              }
            }}
            margin="normal"
            variant="outlined"
            value={
              secondConfirmationState ||
              (detail.second_confirm_profile !== null
                ? secondConfirmations[1].value
                : secondConfirmations[0].value)
            }
            SelectProps={selectProps(secondConfirmationState)}
          >
            <MenuItem value="" disabled>
              Select after approval actions
            </MenuItem>
            {secondConfirmations.map((type, key) => {
              return (
                <MenuItem key={key} value={type.value}>
                  {type.name}
                </MenuItem>
              )
            })}
          </TextField>
        </FormChildInput>
      </FormChildContainer>

      {secondConfirmationState === secondConfirmations[1].value && (
        <FormChildContainer>
          <FormChildInput>
            <StyledReactSelect
              value={selectedPositionSecond}
              options={dataJP}
              onChange={v => {
                setSelectedPositionSecond(v)
              }}
              placeholder="Select Position"
              isLoading={loading}
              onInputChange={e => handleSearch(e)}
            />
            {isError && !selectedPositionSecond && (
              <Typography
                variant="caption"
                style={{color: 'red', marginTop: 10}}
              >
                This field is required
              </Typography>
            )}
          </FormChildInput>
        </FormChildContainer>
      )}
      <FormChildContainer style={{paddingTop: '1rem'}}>
        <FormChildTitle>
          <TitleNumber>6.</TitleNumber>
          <TitleName>Gender</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <RadioGroup
            row
            name="gender"
            value={gender || (detail && detail.gender ? detail.gender : 'U')}
            onChange={event => {
              setGender(event.target.value)
            }}
          >
            <FormControlLabel
              value={'M'}
              control={<Radio color="primary" />}
              label="Male"
            />
            <FormControlLabel
              value={'F'}
              control={<Radio color="primary" />}
              label="Female"
            />
            <FormControlLabel
              value={'U'}
              control={<Radio color="primary" />}
              label="Unisex"
            />
          </RadioGroup>
        </FormChildInput>
      </FormChildContainer>
    </>
  )
}
