import React from 'react'
import {differenceDateFrom, convertToRupiah} from '../../../../../utils/helpers'

export const getApproalType = (data, actionStatus) => {
  let approvalType = ''
  const _getApprove = (data, type) => {
    let _type = `first_${type}`
    if (data && data.time_overtime_policy) {
      const {confirm_type} = data.time_overtime_policy
      if (confirm_type === '1' && data.status === 'confirm1')
        _type = `second_${type}`
      else if (confirm_type === '2') {
        if (data.status === 'confirm1') {
          _type = `second_${type}`
        } else {
          _type = `first_${type}`
        }
      }
    }
    return _type
  }
  if (actionStatus === 'approved') approvalType = _getApprove(data, 'approve')
  else if (actionStatus === 'rejected')
    approvalType = _getApprove(data, 'reject')

  return approvalType
}

const generateStatus = (status, confirm_type, second, isSame = false) => {
  let statusnew = status
  if (status === 'pending') {
    statusnew = 'pending'
  } else if (confirm_type === '1' && status === 'confirm1' && second === null) {
    statusnew = 'approved'
  } else if (confirm_type === '1' && status === 'confirm1' && second) {
    if (isSame) {
      statusnew = 'approved'
    } else {
      statusnew = 'pending'
    }
  } else if (confirm_type === '2' && status === 'pending') {
    statusnew = 'pending'
  } else if (confirm_type === '2' && status === 'confirm1') {
    statusnew = 'pending'
  }
  switch (statusnew) {
    case 'pending':
      return <span style={statusStyle['text-warning']}>Waiting</span>
    case 'approved':
      return <span style={statusStyle['text-success']}>Approved</span>
    case 'cancelled':
      return <span style={statusStyle['text-disable']}>Cancelled</span>
    case 'rejected':
      return <span style={statusStyle['text-danger']}>Rejected</span>
    default:
      return <span />
  }
}

export const generateSubmissionStatus = ({data, policy_type, field_from}) => {
  const status = data && data.status
  const policy = data && data[policy_type]
  const confirm_type = policy && policy.confirm_type
  const first = policy && policy.first_job_profile
  const second = policy && policy.second_job_profile

  const job_profile_supervisor_1 =
    data?.people_work_placement?.company_job_profile?.job_profile_supervisor
  const job_profile_supervisor_2 = job_profile_supervisor_1?.spv_2
  const job_profile_supervisor_3 = job_profile_supervisor_2?.spv_3

  const is_spv_1 = job_profile_supervisor_1?.people_work_placements?.[0]
  const is_spv_2 = job_profile_supervisor_2?.people_work_placements?.[0]
  const is_spv_3 = job_profile_supervisor_3?.people_work_placements?.[0]

  const spvName = (is_spv_1 || is_spv_2 || is_spv_3)?.global_user?.name
  const spvPosition = is_spv_1
    ? job_profile_supervisor_1.title
    : is_spv_2
    ? job_profile_supervisor_2.title
    : is_spv_3
    ? job_profile_supervisor_3.title
    : ''

  const isRejected = data && data.status === 'rejected'
  const bridge = isRejected
    ? [`first_reject`, 'second_reject']
    : [`first_approve`, 'second_approve']
  const fields = data && data[field_from]

  const firstUserAction = (fields && [field_from] &&
    fields[bridge[0]] && [
      ' ' + fields[bridge[0]].user.name,
      ' ' + (fields[bridge[0]].user.title || ''),
    ]) || [undefined, undefined]
  const secondUserAction = (fields &&
    fields[bridge[1]] && [
      ' ' + fields[bridge[1]].user.name,
      ' ' + (fields[bridge[1]].user.title || ''),
    ]) || [undefined, undefined]

  if (status === 'cancelled') {
    return (
      <>
        {generateStatus(status, confirm_type, second)} by{' '}
        <span style={statusStyle['text-secondary']}>User</span>
      </>
    )
  } else if (status === 'rejected') {
    return (
      <>
        {generateStatus(status, confirm_type, second, true)} by{' '}
        <span style={statusStyle['text-secondary']}>
          {second
            ? secondUserAction[1] || firstUserAction[0] + firstUserAction[1]
            : confirm_type === '2'
            ? secondUserAction[1]
            : firstUserAction[0] + firstUserAction[1]}
        </span>
      </>
    )
  } else if (status === 'approved') {
    return (
      <>
        <span style={statusStyle['text-success']}>Approved</span>{' '}
        {confirm_type !== '3' && ' by '}
        <span style={statusStyle['text-secondary']}>
          {confirm_type === '3'
            ? null
            : second
            ? secondUserAction[1] || firstUserAction[1] || second.title
            : confirm_type === '2'
            ? secondUserAction[1] || first.title
            : (firstUserAction[0] || spvName) +
              ' ' +
              (firstUserAction[1] || spvPosition)}
        </span>
      </>
    )
  } else if (status === 'pending') {
    return (
      <>
        {generateStatus(status, confirm_type, second)} for{' '}
        <span style={statusStyle['text-secondary']}>
          {confirm_type === '1'
            ? spvName + ' ' + spvPosition
            : first && first.title}
        </span>{' '}
        approval
      </>
    )
  } else if (status === 'confirm1') {
    return (
      <>
        {generateStatus(status, confirm_type, second)} for{' '}
        <span style={statusStyle['text-secondary']}>{second.title}</span>{' '}
        approval
      </>
    )
  }
}

const minutesToHours = totalMinutes => Math.floor(totalMinutes / 60)

const getCompensationLeave = selectedItem => {
  const {minimum_duration_leave, name} =
    selectedItem?.time_overtime_policy || {}
  const totalHours = minutesToHours(minimum_duration_leave)
  return `Minimum ${totalHours} hours for 1 day ${name} quota`
}

const baseOnSalary = (selectedItem, longTime, salary = 0) => {
  let totalPaid = 0

  const totoalHours =
    longTime ||
    differenceDateFrom(selectedItem?.dateend, selectedItem?.datestart, 'hours')

  const convert_to_money =
    selectedItem?.convert_to_money ||
    selectedItem?.time_overtime_policy?.convert_to_money

  const {multipliers, divisor} = convert_to_money

  multipliers.slice(0, totoalHours).forEach(multiplier => {
    totalPaid += (parseFloat(salary) * parseFloat(multiplier)) / divisor
  })

  return `Paid ${convertToRupiah(totalPaid.toFixed(2))}`
}

const perHour = (selectedItem, longTime) => {
  const totoalHours =
    longTime ||
    differenceDateFrom(selectedItem?.dateend, selectedItem?.datestart, 'hours')
  const moneyPerHour =
    selectedItem?.convert_to_money?.IDR ||
    selectedItem?.time_overtime_policy?.convert_to_money?.IDR
  return `Paid ${convertToRupiah(totoalHours * Number(moneyPerHour), false)}`
}

const getCompensationMoney = (selectedItem, longTime, salary) => {
  const isBaseOnSalary =
    selectedItem?.convert_to_money?.multipliers?.[0] ||
    selectedItem?.time_overtime_policy?.convert_to_money?.multipliers?.[0]

  return isBaseOnSalary
    ? baseOnSalary(selectedItem, longTime, salary)
    : perHour(selectedItem, longTime)
}
export const getCompensationDescription = ({
  selectedItem,
  longTime,
  salary,
}) => {
  return selectedItem?.convert_to === 'Leave'
    ? getCompensationLeave(selectedItem)
    : getCompensationMoney(selectedItem, longTime, salary)
}

const statusStyle = {
  'text-warning': {color: '#ffa000'},
  'text-danger': {color: '#ef4d5e'},
  'text-success': {color: '#4caf50'},
  'text-disable': {color: '#a9a8a8'},
  'text-secondary': {color: '#039be5'},
}

export const compensationType = {
  Money: 'Pay with Money',
  Leave: 'Convert to Leave',
}
