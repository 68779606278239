import gql from 'graphql-tag'

export const ADD_LOAN_ACTIVITY = gql`
  mutation AddLoanActivity($objects: [people_work_loans_insert_input!]!) {
    insert_people_work_loans(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const EDIT_EMPLOYEE_LOAN = gql`
  mutation EditEmployeeLoan(
    $id: Int!
    $amount_per_installment: float8
    $description: String
    $last_update_by: uuid
    $letter_num: String
    $loan_amount: float8
    $loan_amount_w_interest: float8
    $loan_rate: float8
    $status: String
    $remarks: String
  ) {
    update_people_work_loans_by_pk(
      pk_columns: {id: $id}
      _set: {
        amount_per_installment: $amount_per_installment
        description: $description
        last_update_by: $last_update_by
        letter_num: $letter_num
        loan_amount: $loan_amount
        loan_amount_w_interest: $loan_amount_w_interest
        loan_rate: $loan_rate
        status: $status
        remarks: $remarks
      }
    ) {
      id
      amount_per_installment
      description
      last_update_by
      letter_num
      loan_amount
      loan_amount_w_interest
      loan_rate
      status
      remarks
    }
  }
`

export const EDIT_PAYMENT_PLAN = gql`
  mutation EditPaymentPlan(
    $id: Int!
    $amount_per_installment: float8
    $deducted_from_salary: Boolean
    $installment: Int
    $installment_name: String
    $last_update_by: uuid
    $payment_date_start: date
  ) {
    update_people_work_loans_by_pk(
      pk_columns: {id: $id}
      _set: {
        amount_per_installment: $amount_per_installment
        deducted_from_salary: $deducted_from_salary
        installment: $installment
        installment_name: $installment_name
        last_update_by: $last_update_by
        payment_date_start: $payment_date_start
      }
    ) {
      id
      amount_per_installment
      deducted_from_salary
      installment
      installment_name
      last_update_by
      payment_date_start
    }
  }
`
