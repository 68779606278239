import gql from 'graphql-tag'

export const GET_PAYROLL_REPORT = gql`
  query($limit: Int, $offset: Int, $company: uuid, $year: Int) {
    v_finance_payrolls(
      limit: $limit
      offset: $offset
      where: {
        company: {_eq: $company}
        year: {_eq: $year}
        process_step: {_eq: 5}
      }
    ) {
      year
      month
      total_salary
      addition
      deduction
      total_final_salary
    }
  }
`

export const GET_PAYROLL_REPORT_DETAIL = gql`
  query(
    $company: uuid
    $year: Int
    $month: Int
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $search: String
  ) {
    employee_count: people_work_payroll_aggregate(
      where: {
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_payroll(
      limit: $limit
      offset: $offset
      where: {
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      id
      employee_placement {
        id
        regno
        status
        end_date
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          company_organization {
            id
            name
          }
          company_employee_position {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
            global_province {
              id
              name
            }
            global_city {
              id
              name
            }
          }
        }
      }
      salary
      addition: income
      deduction
      final_salary
      approver
      approve_step
      finance_personnel
      date_printed
      date_sent
      process_step
    }
  }
`

export const GET_PAYROLL_REPORT_SLIP = gql`
  query($id: bigint!) {
    people_work_payroll_by_pk(id: $id) {
      year
      month
      employee_placement {
        id
        regno
        global_user {
          id
          name
          npwp: people_identities(where: {type: {_eq: "NPWP"}}) {
            id
          }
        }
        company_job_profile {
          id
          title
          company_organization {
            id
            name
          }
          company_employee_position {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
        people_work_salaries(where: {active: {_eq: true}}) {
          currency
          value
          salary_fields
          people_profile_bank {
            id
            global_bank {
              id
              name
            }
            branch
            account_name
            account_number
          }
        }
      }
      approver
      finance_personnel
      date_sent
      date_printed

      salary
      allowances
      non_fixed_components
      final_salary
      postponed_items
    }
  }
`
