import gql from 'graphql-tag'

export const GET_DETAIL_SUCCESSION = gql`
  query($job_profile: Int) {
    people_work_placements(
      where: {job_profile: {_eq: $job_profile}, status: {_eq: "ACTIVE"}}
    ) {
      id
      regno
      start_date
      end_date
      global_user {
        id
        name
        avatar
      }
      placement_fields
      company_job_profile {
        id
        title
        successors: talent_pull_successor(
          where: {
            candidate_status: {_eq: "SUCCESSOR"}
            people_work_placement: {status: {_eq: "ACTIVE"}}
          }
          distinct_on: candidate
        ) {
          id
          candidate_status
          readiness_status
          people_work_placement {
            id
            regno
            start_date
            end_date
            placement_fields
            global_user {
              id
              name
              avatar
              talent_assignments(order_by: [{id: desc}], limit: 1) {
                id
                talent_version
                badge_classification
                status
                indicator_perf_nps
                indicator_perf_ogf
                indicator_perf_goal
                indicator_perf_multirater
                indicator_pot_attendance
                indicator_pot_competence
                indicator_pot_experience
                indicator_pot_socmed_post
                indicator_pot_total_course
                talentVersionByTalentVersion {
                  id
                  name
                  start_date
                  end_date
                  box_height
                  box_width
                  indicator_perf_nps
                  indicator_perf_ogf
                  indicator_perf_goal
                  indicator_perf_multirater
                  indicator_pot_attendance
                  indicator_pot_competence
                  indicator_pot_socmed_post
                  indicator_pot_total_course
                  indicator_perf_nps_fields
                  indicator_perf_ogf_fields
                  indicator_perf_goal_fields
                  indicator_perf_multirater_fields
                  indicator_pot_attendance_fields
                  indicator_pot_competence_fields
                  indicator_pot_socmed_post_fields
                  indicator_pot_total_course_fields
                  talent_boxes {
                    id
                    name
                    level
                    badge
                    color_hex
                    index_x
                    index_y
                    description
                  }
                  min_pot
                  min_perf
                  max_pot
                  max_perf
                }
              }
            }
            company_job_profile {
              id
              title
            }
          }
        }

        talent_pool: talent_pull_successor(
          where: {
            candidate_status: {_eq: "TALENT"}
            people_work_placement: {status: {_eq: "ACTIVE"}}
          }
          distinct_on: candidate
        ) {
          id
          candidate_status
          readiness_status
          people_work_placement {
            id
            regno
            start_date
            end_date
            placement_fields
            global_user {
              id
              name
              avatar
              talent_assignments(order_by: [{id: desc}], limit: 1) {
                id
                talent_version
                badge_classification
                status
                indicator_perf_nps
                indicator_perf_ogf
                indicator_perf_goal
                indicator_perf_multirater
                indicator_pot_attendance
                indicator_pot_competence
                indicator_pot_experience
                indicator_pot_socmed_post
                indicator_pot_total_course
                talentVersionByTalentVersion {
                  id
                  name
                  start_date
                  end_date
                  box_height
                  box_width
                  indicator_perf_nps
                  indicator_perf_ogf
                  indicator_perf_goal
                  indicator_perf_multirater
                  indicator_pot_attendance
                  indicator_pot_competence
                  indicator_pot_socmed_post
                  indicator_pot_total_course
                  indicator_perf_nps_fields
                  indicator_perf_ogf_fields
                  indicator_perf_goal_fields
                  indicator_perf_multirater_fields
                  indicator_pot_attendance_fields
                  indicator_pot_competence_fields
                  indicator_pot_socmed_post_fields
                  indicator_pot_total_course_fields
                  talent_boxes {
                    id
                    name
                    level
                    badge
                    color_hex
                    index_x
                    index_y
                    description
                  }
                  min_pot
                  min_perf
                  max_pot
                  max_perf
                }
              }
            }
            company_job_profile {
              id
              title
            }
          }
        }
        subordinate: job_profile_subordinate {
          id
          people_work_placements {
            id
            regno
            start_date
            end_date
            global_user {
              id
              name
              avatar
            }
          }
        }
      }
      talent_assignments(
        where: {
          talentVersionByTalentVersion: {
            status: {_eq: "COMPLETED"}
            visibility: {_eq: "PUBLISH"}
            classification_type: {_eq: "TALENT"}
          }
          badge_classification: {_is_null: false}
        }
        order_by: [{id: desc}]
        limit: 1
      ) {
        id
        talent_version
        badge_classification
        status
        indicator_perf_nps
        indicator_perf_ogf
        indicator_perf_goal
        indicator_perf_multirater
        indicator_pot_attendance
        indicator_pot_competence
        indicator_pot_experience
        indicator_pot_socmed_post
        indicator_pot_total_course
        talentVersionByTalentVersion {
          id
          name
          start_date
          end_date
          box_height
          box_width
          flexy_components
          indicator_perf_nps
          indicator_perf_ogf
          indicator_perf_goal
          indicator_perf_multirater
          indicator_pot_attendance
          indicator_pot_competence
          indicator_pot_socmed_post
          indicator_pot_total_course
          indicator_perf_nps_fields
          indicator_perf_ogf_fields
          indicator_perf_goal_fields
          indicator_perf_multirater_fields
          indicator_pot_attendance_fields
          indicator_pot_competence_fields
          indicator_pot_socmed_post_fields
          indicator_pot_total_course_fields
          talent_boxes {
            id
            name
            level
            badge
            color_hex
            index_x
            index_y
            description
          }
          min_pot
          min_perf
          max_pot
          max_perf
        }
      }
    }
  }
`

export const GET_LIST_DETAIL_SUCCESSOR = gql`
  query($jobProfile: Int!, $status: String!, $offset: Int!, $limit: Int!) {
    talent_pull_successors_aggregate(
      where: {
        job_profile: {_eq: $jobProfile}
        candidate_status: {_eq: $status}
        people_work_placement: {
          status: {_eq: "ACTIVE"}
          global_user: {status: {_eq: "ACTIVE"}}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    talent_pull_successors(
      where: {
        job_profile: {_eq: $jobProfile}
        candidate_status: {_eq: $status}
        people_work_placement: {
          status: {_eq: "ACTIVE"}
          global_user: {status: {_eq: "ACTIVE"}}
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      readiness_status
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          avatar
          talent_assignments(
            where: {
              talentVersionByTalentVersion: {
                status: {_eq: "COMPLETED"}
                visibility: {_eq: "PUBLISH"}
                classification_type: {_eq: "TALENT"}
              }
              badge_classification: {_is_null: false}
            }
            order_by: [{id: desc}]
            limit: 1
          ) {
            id
            talent_score
            talent_box {
              id
              name
              badge
            }
            talentVersionByTalentVersion {
              id
              name
            }
          }
        }
        company_job_profile {
          id
          title
        }
      }
    }
  }
`
export const GET_LIST_SUCCESSOR = gql`
  query($jobProfile: Int!, $limit: Int!) {
    named_successor: talent_pull_successors(
      where: {
        job_profile: {_eq: $jobProfile}
        candidate_status: {_eq: "SUCCESSOR"}
        people_work_placement: {
          status: {_eq: "ACTIVE"}
          global_user: {status: {_eq: "ACTIVE"}}
        }
      }
      limit: $limit
    ) {
      id
      readiness_status
      people_work_placement {
        id
        global_user {
          id
          name
          avatar
        }
      }
    }

    talent_pool: talent_pull_successors(
      where: {
        job_profile: {_eq: $jobProfile}
        candidate_status: {_eq: "TALENT"}
        people_work_placement: {
          status: {_eq: "ACTIVE"}
          global_user: {status: {_eq: "ACTIVE"}}
        }
      }
      limit: $limit
    ) {
      id
      people_work_placement {
        id
        global_user {
          id
          name
          avatar
        }
      }
    }
  }
`
