import gql from 'graphql-tag'

export const GET_BALOON_STATUS_BUTTON = gql`
  query($company: uuid!, $table: String!) {
    company_import_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        _or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]
      }
      limit: 1
    ) {
      id
      status
      reports
      viewed
      table_name
    }
  }
`

/** @deprecated Use `GET_BALOON_STATUS_BUTTON` directly */
export const GET_BALOON_STATUS_BUTTON_QUERY = GET_BALOON_STATUS_BUTTON

export const GET_BALOON_STATUS_BUTTON_QUERY_PARALEL = gql`
  query($company: uuid, $table: String, $multiple_table: [String!]) {
    company_import_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        _or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]
      }
      limit: 1
    ) {
      id
      status
      reports
      viewed
    }

    multiple_table: company_import_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_in: $multiple_table}
        _or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]
      }
      order_by: {id: desc}
    ) {
      id
      status
      reports
      viewed
    }
  }
`

export const GET_BALOON_STATUS_BUTTON_QUERY_MULTIPLE = gql`
  query($company: uuid, $table: [String!], $filter: jsonb) {
    company_import_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_in: $table}
        filter: {_contains: $filter}
        _or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]
      }
      order_by: [{id: desc}]
    ) {
      id
      status
      reports
      viewed
      table_name
      filter
    }
  }
`
