import gql from 'graphql-tag'

//Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/Individuals/INSERT%20INDIVIDUAL.md
export const ADD_INDIVIDUAL_COMPETENCY = gql`
  mutation($objects: [competency_individuals_insert_input!]!) {
    insert_competency_individuals(objects: $objects) {
      affected_rows
    }
  }
`

//Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/Model%20items/INSERT%20MODEL%20ITEM.md
export const ADD_MODEL_ITEM = gql`
  mutation($objects: [competency_model_items_insert_input!]!) {
    insert_competency_model_items(objects: $objects) {
      affected_rows
    }
  }
`
