import gql from 'graphql-tag'
import {COMPANY_ID} from '../../../../utils/globals'

export const GET_HOLDING_CLUSTER_BY_VERSION = gql`
  query($version: Int) {
    holding_clusters(
      where: {
        company: {_eq: "${COMPANY_ID}"}
        version: {_eq: $version}
      }
      order_by: {id: asc}
    ) {
      id
      name
      description
      version
      holding_subclusters {
        id
        name
        description
      }
    }
  }
`

export const GET_HOLDING_CLUSTER_BY_ID = gql`
  query($id: Int) {
    holding_clusters(
      where: {
        company: {_eq: "${COMPANY_ID}"}
        id: {_eq: $id}
      }
      order_by: {id: asc}
    ) {
      id
      name
      description
      
    }
  }
`
export const GET_HOLDING_SUBCLUSTER_BY_ID = gql`
  query($id: Int) {
    holding_subcluster(
      where: {company: {_eq: "${COMPANY_ID}"}, id: {_eq: $id}}
      order_by: {holding_cluster: {id: asc}}
    ) {
      id
      name
      description
      holding_cluster {
        id
      }
    }
  }
`
