import { ApolloProvider } from '@apollo/react-hooks'
import React, { useState } from 'react'
import AppContainer from './AppContainer'
import { DataContext } from './ContextConfig'
import client from './graphql/connector'
import { ALLOWED_ROLES, ENVIRONMENT, MAIN_APP_URL, ROLES } from './utils/globals'

const App = () => {
  const [dataContext, setDataContext] = useState(null)
  const checkRole = ROLES.filter(element => ALLOWED_ROLES.includes(element))

  if (ENVIRONMENT !== 'development') {
    if (!checkRole) {
      window.location = MAIN_APP_URL
    }
  }
  //
  return (
    <>
      <DataContext.Provider value={{ dataContext, setDataContext }}>
        <ApolloProvider client={client}>
          <AppContainer />
        </ApolloProvider>
      </DataContext.Provider>
    </>
  )
}

export default App
