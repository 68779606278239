import {GLOBAL_GLOSSARY} from '../utils/constant'
import {
  // ALLOWED_ROLES,
  APP_DEPENDENT_URL,
  ENVIRONMENT,
  hasModule,
  // hasRole,
  // isNotSuperAdmin,
} from '../utils/globals'

const navigationConfig = (companyModule, allModule) => {
  const _navConfig = [
    {
      id: 'dashboard-menu',
      title: 'Dasbor',
      type: 'item',
      icon: 'dashboard',
      visible: true,
      url: '/dashboard',
      state: 'dashboard',
    },
    {
      id: 'time-menu',
      title: 'Waktu',
      type: 'collapse',
      icon: 'access_time',
      visible: true,
      children: [
        {
          id: 'time-calender-menu',
          title: 'Calendar',
          type: 'item',
          url: '/time/calendar/activity',
          visible: true,
          state: 'activity',
          hideSearch: true,
        },
        {
          id: 'time-attendance-menu',
          title: 'Attendance',
          type: 'item',
          url: '/time/attendance/activity',
          visible: true,
          state: 'activity',
        },
        {
          id: 'time-leave-menu',
          title: 'Leave and Permission',
          type: 'item',
          url: '/time/leave/activity',
          visible: true,
          state: 'activity',
        },
        {
          id: 'time-overtime-menu',
          title: 'Overtime',
          type: 'item',
          url: '/time/overtime/activity',
          visible: true,
          state: 'activity',
        },
      ],
    },
    {
      id: 'financial-menu',
      title: 'Keuangan',
      type: 'collapse',
      icon: 'attach_money',
      visible: true,
      children: [
        {
          id: 'claim',
          title: 'Claim',
          type: 'item',
          url: `/financial/claim/activity`,
          visible: true,
        },
        {
          id: 'cash-advance',
          title: 'Cash Advance',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/financial/cash_advance`,
          visible: false,
        },
        {
          id: 'loan',
          title: 'Loan',
          type: 'item',
          url: `/financial/loan/activity`,
          visible: true,
        },
        {
          id: 'nonfixed-component',
          title: 'Non-fixed Component',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/financial/non_fixed`,
          visible: false,
        },
        {
          id: 'petty-cash',
          title: 'Petty Cash',
          type: 'item',
          // url: `${APP_DEPENDENT_URL}/financial/petty_cash`,
          url: '#',
          visible: false,
        },
        {
          id: 'allowance',
          title: 'Allowance',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/financial/allowance`,
          visible: false,
        },
        {
          id: 'payroll',
          title: 'Payroll',
          type: 'item',
          // url: `${APP_DEPENDENT_URL}/financial/payroll`,
          url: `/financial/payroll/analytic`,
          visible: true,
        },
        // {
        //     'id'   : 'analytics-dashboard',
        //     'title': 'Analytics',
        //     'type' : 'item',
        //     'url'  : '/apps/dashboards/analytics'
        // },
        // {
        //     'id'   : 'project-dashboard',
        //     'title': 'Project',
        //     'type' : 'item',
        //     'url'  : '/apps/dashboards/project'
        // }
      ],
    },
    {
      id: 'budget-menu',
      title: 'Anggaran',
      type: 'collapse',
      icon: 'credit_card',
      visible: true,
      children: [
        {
          id: 'budget-limit',
          title: 'Claim Limit',
          type: 'item',
          url: '/budget/limit/company',
          visible: true,
        },
      ],
    },
    {
      id: 'employee-directory-menu',
      title: 'Direktori',
      type: 'collapse',
      icon: 'group',
      visible: true,
      children: [
        {
          id: 'employee',
          title: 'Pegawai',
          type: 'item',
          url: `/directory/employee`,
          state: 'employee',
          visible: true,
        },
        {
          id: 'employee-will-join',
          title: 'Akan Bergabung',
          type: 'item',
          url: `/directory/employee-will-join`,
          state: 'employee-will-join',
          visible: true,
        },
        {
          id: 'employee-pending',
          title: 'Menunggu Aktivasi',
          type: 'item',
          url: `/directory/employee-pending-register`,
          state: 'employee-pending',
          visible: true,
        },
        {
          id: 'employee-alumni',
          title: 'Alumni',
          type: 'item',
          url: `/directory/employee-alumni`,
          state: 'employee-alumni',
          visible: true,
        },
        {
          id: 'employee-alumni',
          title: 'Unduh Laporan',
          type: 'item',
          url: `/directory/download-report`,
          state: 'download-report',
          hideSearch: true,
          visible: true,
        },
      ],
    },
    {
      id: 'business-menu',
      title: 'Perjalanan Dinas',
      type: 'collapse',
      icon: 'local_airport',
      visible: true,
      children: [
        // {
        //   id: 'claim',
        //   title: 'Claim',
        //   type: 'item',
        //   url: `${APP_DEPENDENT_URL}/financial/claim`,
        //   visible: false,
        // },
        {
          id: 'trip',
          title: 'Trip',
          type: 'item',
          // url: `${APP_DEPENDENT_URL}/financial/payroll`,
          url: `/business/trip/activity`,
          state: 'activity',
          visible: true,
        },
      ],
    },
    {
      id: 'recruitment-menu',
      title: 'Recruitment',
      type: 'collapse',
      icon: 'group_add',
      visible: false,
      children: [
        {
          id: 'home-recruitment',
          title: 'Home',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/recruitment/home`,
          visible: true,
        },
        {
          id: 'vacant-job-recruitment',
          title: 'Vacant Job',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/recruitment/vacant_job`,
          visible: true,
        },
        {
          id: 'job-vacancy-recruitment',
          title: 'Job Vacancy',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/recruitment/job_vacancy`,
          visible: true,
        },
        {
          id: 'selection-recruitment',
          title: 'Selection',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/recruitment/selection`,
          visible: true,
        },
        {
          id: 'setting-recruitment',
          title: 'Settings',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/recruitment/settings`,
          visible: true,
        },
      ],
    },
    {
      id: 'company-menu',
      title: 'Perusahaan',
      type: 'collapse',
      icon: 'business',
      visible: true,
      children: [
        {
          id: 'company-profile',
          title: 'Company Profile',
          type: 'item',
          // url: `${APP_DEPENDENT_URL}/company/profile`,
          url: `/company/profile`,
          visible: true,
        },
        {
          id: 'regulations-dashboard',
          title: 'Regulation',
          type: 'item',
          // url: `${APP_DEPENDENT_URL}/company/regulations`,
          url: `/company/regulations`,
          visible: true,
        },
        {
          id: 'office-dashboard',
          title: 'Office',
          type: 'item',
          // url: `${APP_DEPENDENT_URL}/company/office`,
          url: `/company/office`,
          visible: true,
        },
        {
          id: 'organization-dashboard',
          title: 'Organization',
          type: 'item',
          url: `/company/organization/unit`,
          visible: false,
        },
        {
          id: 'broadcast',
          title: 'Broadcast',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/company/broadcast`,
          visible: false,
        },
      ],
    },
    {
      id: 'organization-menu',
      title: 'Organisasi',
      type: 'collapse',
      icon: 'device_hub',
      visible: true,
      children: [
        {
          id: 'organization-level',
          title: 'Level Organisasi',
          type: 'item',
          url: `/organization/organization-level`,
          // url: `/page-under-development`,
          visible: true,
        },
        {
          id: 'organization-unit',
          title: 'Unit Organisasi',
          type: 'item',
          url: `/organization/organization-unit`,
          visible: true,
        },
        {
          id: 'job-level',
          title: 'Jenjang Jabatan',
          type: 'item',
          url: `/organization/job-level`,
          visible: true,
        },
        {
          id: 'job-grade',
          title: 'Kelas Jabatan',
          type: 'item',
          url: `/organization/job-grade`,
          // url: `/page-under-development`,
          visible: true,
        },
        {
          id: 'job-function',
          title: 'Jenis Jabatan',
          type: 'item',
          url: `/organization/job-function`,
          visible: true,
        },
        {
          id: 'job-title',
          title: 'Jabatan',
          type: 'item',
          url: `/organization/job-title`,
          visible: true,
        },
        {
          id: 'job-family',
          title: 'Rumpun Jabatan',
          type: 'item',
          url: `/organization/job-family`,
          visible: true,
        },
        {
          id: 'position',
          title: 'Posisi',
          type: 'item',
          url: `/organization/position`,
          visible: true,
        },
        {
          id: 'history',
          title: 'Riwayat',
          type: 'item',
          url: `/organization/history`,
          visible: true,
        },
        {
          id: 'archive',
          title: 'Archive',
          type: 'item',
          url: `/organization/archive`,
          visible: false,
        },
        {
          id: 'hc-planning',
          title: 'HC Planning',
          type: 'item',
          url: `/page-under-development`,
          // url: `/organization/hc-planning`,
          visible: false,
        },
      ],
    },
    {
      id: 'report-menu',
      title: 'Report',
      type: 'collapse',
      icon: 'insert_drive_file',
      visible: false,
      children: [
        {
          id: 'hc-data-report',
          title: 'HC Data Report',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/report/hc_data_report`,
          visible: true,
        },
        {
          id: 'payroll-report',
          title: 'Payroll Report',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/report/payroll_report`,
          visible: true,
        },
      ],
    },
    {
      id: 'certificate-menu',
      title: 'Certificate',
      type: 'item',
      icon: 'assignment',
      visible: false,
      url: `${APP_DEPENDENT_URL}/certificate`,
    },
    {
      id: 'hr-helpdesk-menu',
      title: 'HR Helpdesk',
      type: 'item',
      icon: 'headset_mic',
      visible: false,
      url: `${APP_DEPENDENT_URL}/hr_helpdesk`,
    },
    {
      id: 'name-card-menu',
      title: 'Name Card',
      type: 'item',
      icon: 'credit_card',
      visible: false,
      url: `${APP_DEPENDENT_URL}/name_card`,
    },
    {
      id: 'learning',
      title: 'Pembelajaran',
      type: 'collapse',
      icon: 'school',
      visible: true,
      children: [
        {
          id: 'competency',
          title: 'Pengelolaan Kompetensi',
          type: 'item',
          url: `/learning/competency`,
          // url: `${APP_DEPENDENT_URL}/learning/competency`,
          visible: true,
        },
        {
          id: 'course',
          title: 'Kursus',
          type: 'item',
          url: `/learning/course`,
          visible: true,
        },
        {
          id: 'micro-learning',
          title: 'Pembelajaran Mikro',
          type: 'item',
          url: `/learning/micro-learning`,
          visible: true,
        },
      ],
    },
    {
      id: 'performance',
      title: 'Kinerja',
      type: 'collapse',
      icon: 'outline-assessment',
      visible: true,
      children: [
        {
          id: 'individual-performance',
          title: 'Individual Performance',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/performance/individual`,
          visible: false,
        },

        {
          id: 'vms',
          title: 'VMS',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/performance/vms`,
          visible: false,
        },
        // {
        //   id: 'kpi',
        //   title: 'KPI',
        //   type: 'item',
        //   url: `${APP_DEPENDENT_URL}/performance/KPI`,
        // },
        {
          id: 'individual-performance',
          title: 'Individual Performance',
          type: 'item',
          url: '/performance/individual',
          state: 'individual',
          visible: false,
        },
        {
          id: 'performance-individual-summary',
          title: 'Ringkasan Kinerja Individu',
          type: 'item',
          url: '/performance/individual-summary',
          state: 'individual-summary',
          visible: true,
        },
        {
          id: 'performance-classification',
          title: 'Evaluasi Kinerja Pegawai',
          type: 'item',
          url: '/performance/classifications',
          state: 'perf-classification',
          visible: true,
        },
        {
          id: 'performance-goal',
          title: `Daftar ${GLOBAL_GLOSSARY.performance.Goal}`,
          type: 'item',
          url: '/performance/goal',
          state: 'goal',
          visible: true,
        },
        {
          id: 'performance-kpi-menu',
          // title: 'KPI',
          title: `Kumpulan ${GLOBAL_GLOSSARY.performance.Goal}`,
          type: 'item',
          url: '/performance/kpi/aspect',
          state: 'aspect',
          visible: true,
        },
      ],
    },
    {
      id: 'talent',
      title: 'Talenta',
      type: 'collapse',
      icon: 'how_to_reg',
      visible: true,
      children: [
        {
          id: 'classification-talent',
          title: 'Klasifikasi',
          type: 'item',
          url: '/talent/classification',
          state: 'classification',
          visible: true,
        },
        {
          id: 'succession-talent',
          title: 'Suksesi',
          type: 'item',
          url: '/talent/succession',
          state: 'succession',
          visible: true,
        },
      ],
    },
    {
      id: 'survey',
      title: 'Umpan Balik',
      type: 'collapse',
      icon: 'assignment_ind',
      visible: true,
      children: [
        {
          id: 'multirater-nps',
          title: 'Multirater & NPS',
          type: 'item',
          url: '/survey/multirater-nps/survey-center',
          state: 'survey-center',
          visible: true,
        },
        {
          id: 'ongoing-feedback',
          title: 'On-going Feedback',
          type: 'item',
          url: '/page-under-development',
          state: 'ongoing-feedback',
          visible: false,
        },
        {
          id: 'engagement-survey',
          title: 'Engagement Survey',
          type: 'item',
          url: '/page-under-development',
          state: 'engagement-survey',
          visible: false,
        },
        {
          id: 'community',
          title: 'Community',
          type: 'item',
          url: '/page-under-development',
          state: 'community',
          visible: false,
        },
      ],
    },
    {
      id: 'document-menu',
      title: 'Dokumen',
      type: 'collapse',
      icon: 'insert_drive_file',
      visible: true,
      children: [
        {
          id: 'company-file',
          title: 'Company File',
          type: 'item',
          url: `/document/company-file`,
          state: 'company-file',
          visible: true,
        },
        // {
        //   id: 'reference-letter',
        //   title: 'Reference Letter',
        //   type: 'item',
        //   url: `/document/reference-letter`,
        //   state: 'reference-letter',
        //   visible: true,
        // },
      ],
    },
    {
      id: 'support',
      title: 'Bantuan',
      type: 'item',
      icon: 'headset_mic',
      visible: true,
      url: 'https://web.whatsapp.com/send?phone=6285162976397',
      state: 'Support',
    },
    {
      id: 'settings-menu',
      title: 'Settings',
      type: 'collapse',
      icon: 'settings',
      visible: false,
      children: [
        {
          id: 'onboarding',
          title: 'Onboarding',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/settings/onboarding`,
          visible: true,
        },
        {
          id: 'access-rights',
          title: 'Access Rights',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/settings/access_rights`,
          visible: true,
        },
        {
          id: 'directory-data',
          title: 'Directory Data',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/settings/directory_data`,
          visible: true,
        },
        {
          id: 'column-customization',
          title: 'Column Customization',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/settings/column_customization`,
          visible: true,
        },
        {
          id: 'day-off',
          title: 'Day Off',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/settings/day_off`,
          visible: true,
        },
        {
          id: 'account-transfer',
          title: 'Account Transfer',
          type: 'item',
          url: `${APP_DEPENDENT_URL}/settings/account_transfer`,
          visible: true,
        },
      ],
    },
    {
      id: 'aci-menu',
      title: 'ACI (Akhlak Culture Index)',
      type: 'item',
      icon: 'assignment_ind',
      visible: false,
      url: `https://survey.actconsulting.co/achi/wlb/bumn`,
      state: 'dashboard',
    },
  ]

  let filteredNav = _navConfig.filter(f => f.visible)

  // if (ENVIRONMENT !== 'development') {
  // commentn hide menu by roles
  // for (let i = 3; i < ALLOWED_ROLES.length; i++) {
  //   if (isNotSuperAdmin && !hasRole(ALLOWED_ROLES[i])) {
  //     delete filteredNav[i - 2]
  //   }
  // }

  // hide navigation by selected module
  if (ENVIRONMENT !== 'development') {
    for (let i = 0; i < allModule?.global_modules?.length; i++) {
      const name = allModule?.global_modules?.[i]?.name
      if (
        name === 'directory' ||
        name === 'company' ||
        name === 'organization' ||
        name === 'document' ||
        name === 'dashboard'
      ) {
        continue
      } else if (!hasModule(companyModule, name)) {
        filteredNav = filteredNav.filter(i => !i.id.includes(name))
      }
    }
  }

  return filteredNav
}

export default navigationConfig
