import styled from 'styled-components'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'

export const RegulationsContentWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-height: 400px;
`

export const ExpansionPanelStyle = styled(ExpansionPanel)`
  background-color: #f7f8f9;
  box-shadow: none;
  margin-top: 20px;
  padding: 0px;
  &::before {
    opacity: 0;
  }
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`

export const ExpansionPanelSummaryStyle = styled(ExpansionPanelSummary)`
  background-color: #f7f8f9;
  box-shadow: none;
  padding: 0px 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  height: 58px;
  font-size: 12px;

  .MuiExpansionPanelSummary-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const ExpansionPanelDetailStyle = styled(ExpansionPanelDetails)`
  box-shadow: none;
  background-color: #f7f8f9;
  padding: 20px;
  display: flex;
  flex-direction: column;
`
export const ItemClause = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  background-color: #fff;
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;
`

export const ItemMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;

  .item {
    padding: 10px 26px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    color: #014a62;
  }
`

export const ClauseItemWrapper = styled(ExpansionPanel)`
  background-color: #fff;
  box-shadow: none;
  padding: 0px;
  &::before {
    opacity: 0;
  }
  border-radius: 5px;
  box-shadow: none;
  width: 100%;
`

export const CaluseItemHead = styled(ExpansionPanelSummary)`
  background-color: #fff;
  box-shadow: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  .MuiExpansionPanelSummary-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
  }
`

export const CaluseItemBody = styled(ExpansionPanelDetails)`
  box-shadow: none;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
`
export const WrapperModalConfirmation = styled.div`
  dispaly: flex;
  flex-direction: column;

  .header-modal-confirmation {
    padding: 24px 20px;
    color: #014a62;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }

  .body-modal-confirmation {
    padding: 11px 20px;
    display: flex;
    flex-direction: column;

    .content-body-modal-confirmation {
      border-radius: 5px;
      background-color: #eff2f4;
      padding: 12px 20px;

      .title-content-modal-confirmation {
        font-size: 12px;
        margin-bottom: 8px;
      }

      ul {
        padding-left: 14px;
        li {
          font-size: 10px;
          color: #000000;
          margin-bottom: 6px;
        }
      }

      .see-all-modal-confirmation {
        font-size: 10px;
        color: #039be5;
        cursor: pointer;
      }
    }

    .message {
      font-size: 12px;
      color: #000000;
      margin-top: 11px;
      line-height: 1.5;

      .dynamic-name {
        color: #014a62;
      }
    }
  }

  .action-wrapper-modal-confirmation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px 20px;
  }
`
