import React, {useState} from 'react'
import Axios from 'axios'
import ArrowBack from '@material-ui/icons/ArrowBack'
import MaterialTable from 'material-table'
import {IconButton, CircularProgress, Button} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import EmployeeAvatar from '../../../../../shared-components/EmployeeAvatar'
import ModalPaySlip from '../../../shared-components/ModalPayslip'
import {
  EnhancedToolbar,
  PaperContainer,
  RootContainer,
  GenerateTooltip,
} from '../../../../../../GlobalStyles'
import {
  BigTextBlackBold,
  CenterWrapper,
  TableOptions,
  TextBlueBold,
  pageSizeOption,
} from '../../../PayrollPageStyles'
import {tableStyle} from '../../../PayrollTableStyles'
import {GQL_FINANCE_OPTIONS} from '../../../utils'
import {GET_PAYROLL_REPORT_DETAIL} from '../../../../../../graphql/queries'
import {COMPANY_ID, REAST_API_URL, TOKEN} from '../../../../../../utils/globals'
import {
  convertToRoundedRupiah,
  employeeColumnSort,
} from '../../../../../../utils/helpers'
import ExportBaloon from '../../../../../../components/exportExcel/ExportBaloon'
import AlertDialog from '../../../../../shared-components/popup/ResultPopup'
import useDownloadReport from '../../../../../../hooks/useDownloadReport'

export default withRouter(function DetailAnnualReport(props) {
  const {enqueueSnackbar} = useSnackbar()
  const {searchText, filterData} = props
  const {month, year} = props.location.state
  const [openPaySlip, setOpenPaySlip] = useState(false)
  const [employeeId, setEmployeeId] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const [openDownload, setOpenDownload] = useState(false)
  const TABLE_REPORT_NAME = ''

  const {
    dataBaloon,
    isDisableButton,
    onDownload,
    onCloseBaloon,
  } = useDownloadReport({
    table: TABLE_REPORT_NAME,
    filter: {year: year},
  })

  const {data: payrollReportDetailList, loading, error} = useQuery(
    GET_PAYROLL_REPORT_DETAIL,
    {
      variables: {
        company: COMPANY_ID,
        year: year,
        month: month,
        offset: currentPage * rowsPerPage,
        limit: rowsPerPage,
        organization:
          JSON.stringify(filterData.organization) === '[]'
            ? null
            : filterData.organization,
        position:
          JSON.stringify(filterData.position) === '[]'
            ? null
            : filterData.position,
        job_profile:
          JSON.stringify(filterData.job_profile) === '[]'
            ? null
            : filterData.job_profile,
        rank: JSON.stringify(filterData.rank) === '[]' ? null : filterData.rank,
        office:
          JSON.stringify(filterData.office) === '[]' ? null : filterData.office,
        search: `%${searchText}%`,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  const handleDownload = () => {
    Axios.get(
      `${REAST_API_URL}/company/profile/${COMPANY_ID}/payroll/report/download/${year}/annual`,
      {
        headers: {
          Authorization: 'Bearer ' + TOKEN,
          'Content-Disposition': 'attachment; filename=template.xlsx',
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      }
    )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Annual Report ${year}.xlsx`)
        document.body.appendChild(link)
        link.click()
        enqueueSnackbar(`Download Annual Report ${year} Success`, {
          variant: 'success',
          autoHideDuration: 500,
        })
      })
      .catch(err => {
        console.error(err, ' error')
      })

    setOpenDownload(prev => !prev)
    if (openDownload) {
      onDownload({
        variables: {
          table: TABLE_REPORT_NAME,
          filter: {year: year},
        },
      })
    }
  }

  return (
    <RootContainer>
      <ExportBaloon
        isLastGenerateStatus
        isReport
        data={dataBaloon}
        closeBaloonMutation={onCloseBaloon}
        retry={handleDownload}
      />
      <PaperContainer>
        <EnhancedToolbar style={{paddingLeft: 12}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Link
              to={{
                pathname: '/financial/payroll/report',
                state: {
                  active: 'report',
                  searchable: false,
                  showPeriod: false,
                  tab: 'annual',
                },
              }}
            >
              <IconButton edge="end" aria-label="back">
                <ArrowBack />
              </IconButton>
            </Link>
            <BigTextBlackBold style={{marginLeft: 16}}>
              Annual Report Detail - {year}
            </BigTextBlackBold>
          </div>
          {payrollReportDetailList?.people_work_payroll?.status ===
            'COMPLETED' && (
            <GenerateTooltip
              title={
                isDisableButton
                  ? `You can only generate data once a day. To download data use the link that already generated.`
                  : ``
              }
              placement="left"
            >
              <span>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  style={{
                    color: 'white',
                    marginRight: '28px',
                  }}
                  onClick={handleDownload}
                  disabled={isDisableButton}
                >
                  Download Excel
                </Button>
              </span>
            </GenerateTooltip>
          )}
        </EnhancedToolbar>
        {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
        {!payrollReportDetailList && loading && (
          <CenterWrapper>
            <CircularProgress />
          </CenterWrapper>
        )}
        {payrollReportDetailList && (
          <>
            <ModalPaySlip
              open={openPaySlip}
              handleClose={() => {
                setEmployeeId()
                setOpenPaySlip(false)
              }}
              employeeId={employeeId}
              from={'payroll'}
            />
            <MaterialTable
              totalCount={
                payrollReportDetailList.employee_count.aggregate.count
              }
              options={{...TableOptions}}
              style={{fontSize: 12}}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={page => {
                setCurrentPage(page)
              }}
              isLoading={payrollReportDetailList && loading}
              onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
              columns={[
                {
                  title: 'Employee',
                  field: 'employee',
                  ...tableStyle({minWidth: 380}, 'cell'),
                  customSort: employeeColumnSort,
                  render: rowData => {
                    if (rowData.employee_placement.global_user) {
                      return (
                        <EmployeeAvatar
                          src={rowData.employee_placement.global_user.avatar}
                          regno={rowData.employee_placement.regno}
                          title={
                            rowData.employee_placement.company_job_profile.title
                          }
                          global_user={rowData.employee_placement.global_user}
                          city_name={
                            rowData.employee_placement.company_job_profile
                              .company_address.global_city.name
                          }
                          province_name={
                            rowData.employee_placement.company_job_profile
                              .company_address.global_province.name
                          }
                        />
                      )
                    } else {
                      return (
                        <EmployeeAvatar
                          src={''}
                          regno={rowData.employee_placement.regno}
                          title={
                            rowData.employee_placement.company_job_profile.title
                          }
                        />
                      )
                    }
                  },
                },
                {
                  title: 'Basic Salary',
                  field: 'salary',
                  ...tableStyle({}, 'cell-number'),
                  render: rowData => {
                    return convertToRoundedRupiah(rowData.salary)
                  },
                },
                {
                  title: 'Earning',
                  field: 'addition',
                  ...tableStyle({}, 'cell-number'),
                  render: rowData => {
                    return convertToRoundedRupiah(rowData.addition)
                  },
                },
                {
                  title: 'Deduction',
                  field: 'deduction',
                  ...tableStyle({}, 'cell-number'),
                  render: rowData => {
                    return convertToRoundedRupiah(rowData.deduction)
                  },
                },
                {
                  title: 'Total',
                  field: 'final_salary',
                  ...tableStyle({}, 'cell-number'),
                  render: rowData => {
                    return convertToRoundedRupiah(rowData.final_salary)
                  },
                },
                {
                  title: 'Action',
                  field: 'action',
                  sorting: false,
                  ...tableStyle({minWidth: 100}, 'cell'),
                  render: rowData => (
                    <TextBlueBold
                      onClick={() => {
                        setOpenPaySlip(true)
                        setEmployeeId(rowData.id)
                      }}
                    >
                      Details
                    </TextBlueBold>
                  ),
                },
              ]}
              data={payrollReportDetailList.people_work_payroll}
            />
          </>
        )}

        <AlertDialog
          open={openDownload}
          handleClose={handleDownload}
          feature="Download Data is Processing!"
          message="Download Data is processing! We’ll send you an email when it’s ready."
          type="Confirm"
        />
      </PaperContainer>
    </RootContainer>
  )
})
