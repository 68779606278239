import gql from 'graphql-tag'

export const GET_PAYROLL_DETAIL = gql`
  query($id: bigint!) {
    people_work_payroll_by_pk(id: $id) {
      employee_placement {
        id
        regno
        status
        end_date
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          company_address {
            id
            office_name
            global_province {
              id
              name
            }
            global_city {
              id
              name
            }
          }
        }
      }
      approver
      finance_personnel
      date_sent
      date_printed
      salary
      allowances
      non_fixed_components
      postponed_items
      payroll_fields
      approve_step
      process_step
    }
  }
`

export const GET_PAYROLL_DETAIL_COMPONENT_LIST = gql`
  query($company: uuid, $type: Int) {
    finance_nonfixed_components(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      id
      name
      type
    }
  }
`
