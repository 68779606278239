import gql from 'graphql-tag'

export const UPDATE_MICROLEARNING = gql`
  mutation($microlearningId: uuid, $set: microlearnings_set_input) {
    update_microlearnings(where: {id: {_eq: $microlearningId}}, _set: $set) {
      returning {
        id
      }
    }
  }
`

export const UPDATE_ASSIGNMENT_MICROLEARNING = gql`
  mutation($filter: JSON!, $orgAssignmentId: Int!) {
    updateMicrolearningOrgAssign(
      filter: $filter
      orgAssignmentId: $orgAssignmentId
    ) {
      success
    }
  }
`

export const UPDATE_MICROLEARNING_SCHEDULE = gql`
  mutation(
    $microlearningId: uuid
    $dailyQuestion: Int
    $schedule: String
    $scheduleTime: time
  ) {
    update_microlearning_members(
      where: {microlearning_id: {_eq: $microlearningId}}
      _set: {
        daily_question: $dailyQuestion
        schedule: $schedule
        schedule_time: $scheduleTime
        user_settings: false
      }
    ) {
      affected_rows
    }
  }
`
