import gql from 'graphql-tag'

export const UPDATE_BASIC_SETTING = gql`
  mutation editBasicSetting(
    $id_current: Int!
    $object: people_work_salaries_set_input!
  ) {
    update_people_work_salaries_by_pk(
      pk_columns: {id: $id_current}
      _set: $object
    ) {
      id
      primary_bank
      salary_fields
      value
      currency
      ktp
      npwp
      start_date
      end_date
    }
  }
`

export const UPDATE_PREV_PERIOD_BASIC_SETTING = gql`
  mutation editPrevPeriodBasicSetting($id_prev: Int!, $prev_end_date: date) {
    update_people_work_salaries_by_pk(
      pk_columns: {id: $id_prev}
      _set: {end_date: $prev_end_date}
    ) {
      id
    }
  }
`

export const UPDATE_NEXT_PERIOD_BASIC_SETTING = gql`
  mutation editNextPeriodBasicSetting($id_next: Int!, $next_start_date: date) {
    update_people_work_salaries_by_pk(
      pk_columns: {id: $id_next}
      _set: {start_date: $next_start_date}
    ) {
      id
    }
  }
`

export const ADD_BASIC_SETTING = gql`
  mutation addBasicSetting($object: people_work_salaries_insert_input!) {
    insert_people_work_salaries_one(object: $object) {
      id
      primary_bank
      salary_fields
      value
      currency
      ktp
      npwp
      start_date
      end_date
    }
  }
`

export const UPDATE_PEOPLE_PROFILE_IDENTITY = gql`
  mutation($new_card_id: String, $id: String, $user_id: uuid, $type: String) {
    update_people_identities(
      _set: {id: $new_card_id, deletedAt: null}
      where: {
        _and: [{user: {_eq: $user_id}}, {type: {_eq: $type}}, {id: {_eq: $id}}]
      }
    ) {
      affected_rows
    }
  }
`

export const ADD_PEOPLE_PROFILE_IDENTITY = gql`
  mutation($new_card_id: String, $user_id: uuid, $type: String) {
    insert_people_identities_one(
      object: {id: $new_card_id, type: $type, user: $user_id}
    ) {
      id
      type
    }
  }
`
