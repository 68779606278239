import React, {useState} from 'react'
import {
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleName,
  TitleNumber,
  FormChildInput,
  TextHelper,
} from '../../../../../../GlobalStyles'
import {
  TextField,
  makeStyles,
  FormControlLabel,
  Switch,
  withStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Select from 'react-select'
import {FlexStart} from '../../../../component/modalStyles'
import {FlexColumnStart} from '../../../../../performance/goal/modal/modalStyles'
import {GET_LIST_POSITION_BUSINNES_TRIP} from '../../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import {customSelect} from '../../../../../../components/select/customSelect'
import {getErrorPolicyName} from '../../../businessTripHelper'

const InfoTooltip = withStyles(() => ({
  tooltip: {
    padding: '12px 14px',
    backgroundColor: '#eff2f4',
    color: '#000',
    fontSize: 14,
    borderRadius: 5,
  },
  arrow: {
    color: '#eff2f4',
    backgroundColor: '#eff2f4',
  },
}))(Tooltip)

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    // color: '#039be5 !important',
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  labelActive: {
    color: '#4D4F5C !important',
  },
  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  mb16: {
    marginBottom: '16px !important',
  },
  paper: {
    minHeight: '70vh',
  },
  contentDialog: {
    color: '#000',
    fontSize: 18,
    margin: '30px 0px 30px 0px',
    fontWeight: '500',
  },
  titleDialog: {
    fontSize: 22,
  },
  actionDialog: {
    padding: '28px 18px',
  },
  iconInfo: {
    paddingLeft: '10px',
    fontSize: '13px',
  },
}))

function StepOne(props) {
  const {
    dataContext,
    isError,
    setDataContext,
    select,
    checked,
    setSelect,
    setChecked,
  } = props
  const [search, setSearch] = useState(null)

  const dataComponentType = [
    {
      value: 0,
      label: 'No Approval',
    },
    {
      value: 1,
      label: 'Direct Supervisor',
    },
    {
      value: 2,
      label: 'Position',
    },
  ]

  const {data: position, loading: loadPosition} = useQuery(
    GET_LIST_POSITION_BUSINNES_TRIP,
    {
      wlb_skipPatch: true,
      variables: {
        search: `%${search}%`,
        limit: 10,
      },
    }
  )

  const {isErrorName, helperText} = getErrorPolicyName({
    isNameAlreadyExist: props.isNameAlreadyExist,
    dataContext,
    isError,
  })

  const dataPosition = []
  position &&
    position.company_job_profiles.map(res => {
      dataPosition.push({
        value: res.id,
        label: res.title,
      })
    })

  const classes = useStyles()

  return (
    <FormContainer>
      <FormChildContainer style={{width: '75%'}}>
        <FormChildTitle>
          <TitleNumber id="parent">1.</TitleNumber>
          <TitleName>Policy Name*</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            id="office-code"
            InputProps={{
              classes: {
                input: classes.outlinedInput,
              },
            }}
            placeholder={'Add Policy Name'}
            value={dataContext?.name}
            fullWidth
            className={classes.helperMarginLeft0}
            margin="normal"
            error={isErrorName}
            helperText={helperText}
            onChange={event => {
              setDataContext({
                ...dataContext,
                name: event.target.value,
              })
            }}
            variant="outlined"
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer style={{width: '75%'}}>
        <FormChildTitle>
          <TitleNumber id="description">2.</TitleNumber>
          <TitleName>Description*</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            id="description"
            placeholder={'Add Description'}
            InputProps={{
              inputProps: {
                min: 0,
              },
              classes: {
                input: classes.outlinedInput,
              },
            }}
            value={dataContext && dataContext.description}
            onChange={event => {
              setDataContext({
                ...dataContext,
                description: event.target.value,
              })
            }}
            error={dataContext && !dataContext.description && isError}
            helperText={
              dataContext &&
              !dataContext.description &&
              isError &&
              'This field is required'
            }
            fullWidth
            size="small"
            margin="normal"
            variant="outlined"
            multiline
            rows="4"
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer fullWidth>
        <FormChildTitle>
          <TitleNumber id="province">3.</TitleNumber>
          <TitleName>Trip Approval</TitleName>
          <InfoTooltip
            placement="right"
            title={
              <Typography className={classes.textTooltip}>
                approval will be applied to trip and reporting approval
              </Typography>
            }
          >
            <ErrorOutlineOutlinedIcon
              className={classes.iconInfo}
              color="primary"
            />
          </InfoTooltip>
        </FormChildTitle>
        <FlexColumnStart style={{paddingTop: '10px'}}>
          <FlexStart style={{width: '100%'}}>
            <FormChildInput style={{width: '64%'}}>
              <Select
                className={classes.marginTop15}
                id="parent"
                options={dataComponentType}
                value={select.approval1.label ? select.approval1 : undefined}
                styles={customSelect({
                  error: select && select.approval1.label === null && isError,
                })}
                onChange={event => {
                  setDataContext(prev => ({
                    ...prev,
                    confirm_type: event.value === 0 ? 3 : event.value,
                  }))
                  if (event.value === 0) {
                    setSelect({
                      ...select,
                      approval1: event,
                      position1: {
                        label: null,
                        value: null,
                      },
                      approval2: {
                        label: null,
                        value: null,
                      },
                      position2: {
                        label: null,
                        value: null,
                      },
                    })
                    setChecked(false)
                  } else {
                    setSelect({
                      ...select,
                      approval1: event,
                    })
                  }
                }}
                fullWidth
                placeholder="Choose Approval"
                required
              />
              <TextHelper className={classes.marginLeft0}>
                {dataContext && select.approval1.value === null && isError
                  ? 'This field is required'
                  : ''}
              </TextHelper>
            </FormChildInput>
            <FormControlLabel
              style={{paddingLeft: '8px', width: '250px'}}
              disabled={select.approval1.value === 0}
              onClick={() => {
                if (select.approval1.value !== 0)
                  setChecked({
                    ...checked,
                    needApproval1: !checked.needApproval1,
                  })
              }}
              control={
                <Switch
                  checked={checked.needApproval1}
                  disabled={select.approval1.value === 0}
                />
              }
              label="Needs Second Approval"
            />
          </FlexStart>
          {select.approval1.value === 2 && (
            <>
              <TitleName style={{marginLeft: '29px'}}>Position</TitleName>
              <FormChildInput style={{width: '64%', paddingTop: '10px'}}>
                <Select
                  className={classes.marginTop15}
                  id="parent"
                  options={dataPosition}
                  value={select.position1.value ? select.position1 : undefined}
                  styles={customSelect({
                    error: select && select.position1.value === null && isError,
                  })}
                  isLoading={loadPosition}
                  onInputChange={dataSearchDropdown =>
                    setSearch(dataSearchDropdown)
                  }
                  onChange={event => {
                    setSelect({
                      ...select,
                      position1: event,
                    })
                    setDataContext({
                      ...dataContext,
                      first_confirm_profile: event.value,
                    })
                  }}
                  fullWidth
                  placeholder={`Choose Position for ${
                    checked.needApproval1 ? 'First' : ''
                  } Approval`}
                />
                <TextHelper className={classes.marginLeft0}>
                  {dataContext && select.position1.value === null && isError
                    ? 'This field is required'
                    : ''}
                </TextHelper>
              </FormChildInput>
            </>
          )}
          {checked.needApproval1 && (
            <>
              <TitleName style={{marginLeft: '29px'}}>
                Second Approval
              </TitleName>

              <FlexStart>
                <FormChildInput style={{width: '64%', paddingTop: '10px'}}>
                  <Select
                    className={classes.marginTop15}
                    id="parent"
                    options={dataPosition}
                    value={
                      select.position2.value ? select.position2 : undefined
                    }
                    isLoading={loadPosition}
                    onInputChange={dataSearchDropdown =>
                      setSearch(dataSearchDropdown)
                    }
                    onChange={event => {
                      setSelect({
                        ...select,
                        position2: event,
                      })
                      setDataContext({
                        ...dataContext,
                        second_confirm_profile: event.value,
                      })
                    }}
                    fullWidth
                    placeholder="Choose Position for Second Approval"
                    styles={customSelect({
                      error:
                        select && select.position2.value === null && isError,
                    })}
                  />
                  <TextHelper className={classes.marginLeft0}>
                    {dataContext && select.position2.value === null && isError
                      ? 'This field is required'
                      : ''}
                  </TextHelper>
                </FormChildInput>
                <FormControlLabel
                  style={{paddingLeft: '8px', width: '250px'}}
                  disabled={select.approval1.value === 0}
                  onClick={() => {
                    if (select.approval1.value !== 0)
                      setChecked({
                        ...checked,
                        needApproval2: !checked.needApproval2,
                      })
                  }}
                  control={
                    <Switch
                      checked={checked.needApproval2}
                      disabled={select.approval1.value === 0}
                    />
                  }
                  label="Needs Third Approval"
                />
              </FlexStart>
            </>
          )}
          {checked.needApproval2 && (
            <>
              <TitleName style={{marginLeft: '29px'}}>Third Approval</TitleName>
              <FormChildInput style={{width: '64%', paddingTop: '10px'}}>
                <Select
                  className={classes.marginTop15}
                  id="parent"
                  options={dataPosition}
                  value={select.position3.value ? select.position3 : undefined}
                  isLoading={loadPosition}
                  onInputChange={dataSearchDropdown =>
                    setSearch(dataSearchDropdown)
                  }
                  onChange={event => {
                    setSelect({
                      ...select,
                      position3: event,
                    })
                    setDataContext({
                      ...dataContext,
                      third_confirm_profile: event.value,
                    })
                  }}
                  fullWidth
                  placeholder="Choose Position for Third Approval"
                  styles={customSelect({
                    error: select && select.position3.value === null && isError,
                  })}
                />
                <TextHelper className={classes.marginLeft0}>
                  {dataContext && select.position3.value === null && isError
                    ? 'This field is required'
                    : ''}
                </TextHelper>
              </FormChildInput>
            </>
          )}
        </FlexColumnStart>
      </FormChildContainer>
    </FormContainer>
  )
}

export default StepOne
