import React, {useState} from 'react'
import {
  Avatar,
  CircularProgress,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Slide,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import {useQuery} from '@apollo/react-hooks'
import {withRouter} from 'react-router-dom'

import {BlueIcon} from '../../../../../../GlobalStyles'
import {
  DialogWrapper,
  SearchContainer,
  UserItemWrapper,
  CenterWrapper,
} from '../../../PayrollPageStyles'
import EmployeeFilterPopUp from '../../../../../shared-components/popup/EmployeeFilterPopUp'

import {GET_DETAIL_PREMIUM} from '../../../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../../utils'

const UserItem = ({name, id, desc, avatar}) => {
  return (
    <UserItemWrapper>
      <Avatar src={avatar} alt={name}>
        {name.slice(0, 1)}
      </Avatar>
      <div>
        <div className="user-name">{name}</div>
        <div className="user-id">{id}</div>
        <div className="user-desc">{desc}</div>
      </div>
    </UserItemWrapper>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Index = props => {
  const {open, onClose, id: premiumId} = props

  const [search, setSearch] = useState('')
  const [filterData, setFilterData] = useState({
    position: [],
    organization: [],
    job_profile: [],
    rank: [],
    office: [],
  })

  const {data, loading, error} = useQuery(GET_DETAIL_PREMIUM, {
    variables: {
      id: premiumId,
      search: `%${search}%`,
      position:
        JSON.stringify(filterData.position) === '[]'
          ? null
          : filterData.position,
      organization:
        JSON.stringify(filterData.organization) === '[]'
          ? null
          : filterData.organization,
      job_profile: null,
      rank: null,
      office: null,
    },
    ...GQL_FINANCE_OPTIONS,
    skip: !open,
  })

  return (
    <DialogWrapper
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        style={{borderBottom: '2px solid #f7f8f9', color: '#014a62'}}
        id="alert-dialog-slide-title"
      >
        Assignee
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{color: '#000000'}}
        >
          <SearchContainer>
            <TextField
              variant="outlined"
              type={props.hidden}
              placeholder="Search"
              value={search}
              onChange={e => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{display: props.hidden ? 'none' : 'flex'}}
                  >
                    <BlueIcon>search</BlueIcon>
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <div className="outlined">
              <EmployeeFilterPopUp setFilterData={setFilterData} />
            </div>
          </SearchContainer>
          <div className="dialog-assignee-list-wrapper">
            <div style={{marginTop: 15}}>
              Employee{' '}
              {data &&
                `(${data.finance_insurance_premiums_by_pk.finance_premium_assignments.length})`}
            </div>
            {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
            {loading && (
              <CenterWrapper>
                <CircularProgress />
              </CenterWrapper>
            )}
            {!loading && data && (
              <div className="dialog-assignee-list">
                {data.finance_insurance_premiums_by_pk.finance_premium_assignments.map(
                  (item, i) => {
                    const user = item.people_work_placement.global_user
                    const job =
                      item.people_work_placement.company_job_profile.title
                    return (
                      <UserItem
                        key={i}
                        name={user.name}
                        id={item.people_work_placement.regno}
                        desc={job}
                        avatar={user.avatar}
                      />
                    )
                  }
                )}
              </div>
            )}
            {!loading &&
              data &&
              data.finance_insurance_premiums_by_pk.finance_premium_assignments
                .length === 0 && (
                <div className="not-found">Not Records to Display</div>
              )}
          </div>
        </DialogContentText>
      </DialogContent>
    </DialogWrapper>
  )
}

export default withRouter(Index)
