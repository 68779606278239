import {Button, Dialog, Divider, makeStyles} from '@material-ui/core'
import React, {useState} from 'react'
import {BoldTypography} from '../../../../../../components/typography/TypographyStyles'
import {
  FormChildButton,
  ModalContentEvent,
  WhiteModalHeader,
} from '../../../../../../GlobalStyles'
import FirstPlacement from './FirstPlacementComponent'
import SecondPlacement from './SecondPlacementComponent'

const useStyles = makeStyles(() => ({
  mr28: {marginRight: 28},
  mt20: {marginTop: '20px !important'},
  mr13: {marginRight: 13},
  companyWrapper: {
    display: 'flex',
    margin: '20px 0',
    background: '#EFF2F4 0% 0% no-repeat padding-box',
    borderRadius: 5,
    padding: 16,
  },
  structureHeader: {textAlign: 'left', cursor: 'pointer'},
  flexTitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  dividerLittle: {
    height: '20px !important',
    margin: ' 0 4px 0 7px !important',
  },
}))

const ModalPlacement = ({handleClose, open}) => {
  const classes = useStyles()
  const [isNext, setIsNext] = useState(false)

  const handleNext = () => {
    setIsNext(prev => !prev)
  }
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={isNext ? 'md' : 'xs'}
      fullWidth={true}
    >
      <WhiteModalHeader>
        <BoldTypography variant="body2" color="primary">
          Placement
        </BoldTypography>

        {/* <IconButton aria-label="close" onClick={handleClose}>
          <Icon>close</Icon>
        </IconButton> */}
      </WhiteModalHeader>
      <Divider />
      <ModalContentEvent>
        {isNext ? (
          <SecondPlacement classes={classes} />
        ) : (
          <FirstPlacement classes={classes} />
        )}

        <FormChildButton style={{paddingRight: 0, minHeight: 56}}>
          <Button
            onClick={isNext ? handleNext : handleClose}
            size="large"
            className={classes.mr13}
          >
            {isNext ? 'Back' : 'Cancel'}
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {isNext ? 'Confirm' : 'Next'}
          </Button>
        </FormChildButton>
      </ModalContentEvent>
    </Dialog>
  )
}

export default ModalPlacement
