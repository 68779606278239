import React from 'react'
import {Link as RouterLink, withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Toolbar,
  Box,
  Divider,
  Typography,
  Link,
  Button,
  IconButton,
  Icon,
  Avatar,
  CircularProgress,
} from '@material-ui/core'

import {PaperContainer} from '../../../../../GlobalStyles'
import NoDataListFree from '../../../../shared-components/NoDataListFreeComponent'
import {
  DetailsCard,
  BalanceAmount,
  CATEGORY_COLOR,
} from '../../ClaimLimitPageStyles'
import EmployeeBalanceTransactionList from '../EmployeeTransactionList'

import {COMPANY_ID} from '../../../../../utils/globals'
import {
  GET_EMPLOYEE_SUMMARY,
  GET_EMPLOYEE_BALANCE,
  GET_EMPLOYEE_TRANSACTION_HISTORY,
} from '../../../../../graphql/queries/budget/limit/getEmployeeBalance.query'
import {
  convertToRupiah,
  getYearStartEndDate,
} from '../../../../../utils/helpers'
import EmployeeClaimEditDialog from '../EmployeeClaimEditDialog'
import {getEmployeeTransactionFilterVariables} from '../../filters'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  title: {
    fontWeight: '700',
  },
}))

function EmployeeDetails(props) {
  const styles = useStyles()

  const {searchText} = props
  const {employeeID} = props.match.params

  const {data, loading, error} = useQuery(GET_EMPLOYEE_SUMMARY, {
    wlb_skipPatch: true,
    variables: {
      employeePlacementID: employeeID,
    },
  })

  return (
    <PaperContainer>
      <Toolbar>
        <IconButton
          aria-label="Go back to previous page"
          onClick={props.history.goBack}
          edge="start"
          className={styles.backButton}
        >
          <Icon>arrow_back</Icon>
        </IconButton>

        {data && (
          <>
            <Avatar
              alt={data.summary.user.name}
              src={data.summary.user.avatar}
              className={styles.avatar}
            />

            <Box display="flex" flexDirection="column">
              <Typography color="textPrimary">
                {data.summary.user.name}
              </Typography>
              <Typography color="textSecondary">
                {data.summary.profile.position}
              </Typography>
            </Box>
          </>
        )}
      </Toolbar>

      <Divider />

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <div>{JSON.stringify(error)}</div>
      ) : (
        <>
          <EmployeeSummary
            employeeID={data.summary.id}
            userID={data.summary.user.id}
            userWalletID={data.summary.user.wallet.id}
          />

          <Divider />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={3}
            height={48}
          >
            <Typography className={styles.title}>
              Recent Transactions
            </Typography>

            <Link
              color="secondary"
              component={RouterLink}
              to={{
                pathname: `/budget/limit/employee/${data.summary.id}/history`,
                state: {active: 'employee-history', searchable: true},
              }}
            >
              View All
            </Link>
          </Box>

          <EmployeeRecentTransactionsList
            employeeID={data.summary.id}
            employeeName={data.summary.user.name}
            userID={data.summary.user.id}
            searchText={searchText}
          />
        </>
      )}
    </PaperContainer>
  )
}

export default withRouter(EmployeeDetails)

function EmployeeSummary(props) {
  const {employeeID, userID, userWalletID} = props

  const ranges = React.useMemo(() => getYearStartEndDate(), [])

  const [showEditDialog, setShowEditDialog] = React.useState(false)

  const {data, loading, error, refetch} = useQuery(GET_EMPLOYEE_BALANCE, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      userID: userID,
      companyID: COMPANY_ID,
      startDate: ranges.start,
      endDate: ranges.end,
    },
  })

  const total = data?.total.aggregate.sum.value || 0
  const claimed = data?.claimed.aggregate.sum.value || 0
  const available = total - claimed

  const formattedTotal = convertToRupiah(total)
  const formattedClaimed = convertToRupiah(claimed)
  const formattedAvailable = convertToRupiah(available)

  const openEditDialog = () => {
    setShowEditDialog(true)
  }

  const closeEditDialog = shouldRefetch => {
    setShowEditDialog(false)

    if (shouldRefetch) {
      refetch()
    }
  }

  return (
    <>
      <DetailsCard>
        <Box display="flex">
          {loading ? (
            <Box display="flex" justifyContent="center" flexGrow={1} py={7.25}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <div>{JSON.stringify(error)}</div>
          ) : (
            // `key` prop is required here because MUI has layout issues when
            // a box of certain style morphs into another.
            <Box display="grid" gridGap={24} flexGrow={1} key={1}>
              <BalanceAmount
                label="Available Claim Limit"
                amount={formattedAvailable}
              >
                <Typography color="textSecondary">
                  of {formattedTotal} total
                </Typography>
              </BalanceAmount>

              <BalanceAmount
                label="Claimed"
                amount={formattedClaimed}
                color={CATEGORY_COLOR.In}
              />
            </Box>
          )}
          <Box display="flex" alignItems="start" ml={1}>
            <Button
              disabled={loading}
              onClick={openEditDialog}
              variant="outlined"
              color="primary"
              size="large"
              style={{marginRight: 16}}
            >
              Edit Total Claim Limit
            </Button>

            <Button
              component={RouterLink}
              to={{
                pathname: `/budget/limit/employee/${employeeID}/topup`,
                state: {active: 'employee-individual-topup'},
              }}
              variant="contained"
              color="primary"
              size="large"
            >
              Top Up
            </Button>
          </Box>
        </Box>
      </DetailsCard>

      <EmployeeClaimEditDialog
        open={showEditDialog}
        userWalletId={userWalletID}
        availableLimit={available}
        onClose={closeEditDialog}
      />
    </>
  )
}

function EmployeeRecentTransactionsList(props) {
  const {employeeName, employeeID, userID, searchText} = props

  const where = React.useMemo(() => {
    return getEmployeeTransactionFilterVariables({
      userId: userID,
      searchText,
    })
  }, [userID, searchText])

  const {data, loading, error} = useQuery(GET_EMPLOYEE_TRANSACTION_HISTORY, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: where,
      limit: 5,
    },
  })

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  if (!loading && !data?.total.aggregate.count) {
    return (
      <NoDataListFree
        message1="Sorry, No List"
        message2={`${employeeName} doesn't have any recent transactions`}
      />
    )
  }

  return (
    <EmployeeBalanceTransactionList
      summary
      employeeID={employeeID}
      data={data?.transactions || []}
      totalCount={data?.total.aggregate.count || 0}
      loading={loading}
    />
  )
}
