import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useMutation, useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import {
  Button,
  IconButton,
  makeStyles,
  Divider,
  Stepper,
  withStyles,
  StepConnector,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tabs,
  Tab,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
} from '../../../../../GlobalStyles'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'
// import {DataContext} from '../../../../../ContextConfig'
import StepOne from './step-policy/StepOne'
import StepTwo from './step-policy/StepTwo'
import StepThree from './step-policy/StepThree'
import StepFour from './step-policy/StepFour'

// import {COMPANY_ID} from '../../../../../utils/globals'
import {
  ADD_POLICY_BUSINESS_TRIP,
  EDIT_POLICY_BUSINESS_TRIP,
} from '../../../../../graphql/mutations'
import {
  GET_BUDGET_EDIT,
  GET_TOTAL_EXIST_PILICY_NAME,
} from '../../../../../graphql/queries'
import {manipulateFilter} from '../../../../time/attendance/work-schedule/filterDataAssignment'
import {getConfirmType, getInitialBudget} from '../../businessTripHelper'
import {currencyOptions} from '../../currencyExchanges'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    // color: '#039be5 !important',
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  labelActive: {
    color: '#4D4F5C !important',
  },
  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  mb16: {
    marginBottom: '16px !important',
  },
  paper: {
    minHeight: '70vh',
  },
  contentDialog: {
    color: '#000',
    fontSize: 18,
    margin: '30px 0px 30px 0px',
    fontWeight: '500',
  },
  titleDialog: {
    fontSize: 22,
  },
  actionDialog: {
    padding: '28px 18px',
  },
}))

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#014a62',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#014a62',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const AntTabs = withStyles({
  root: {
    backgroundColor: '#EFF2F4',
    color: '#000',
  },
  indicator: {
    backgroundColor: '#1890ff',
    height: 2,
  },
})(Tabs)

const StyledTab = withStyles({
  root: {
    color: '#000',
    fontWeight: '500',
    fontSize: 14,
    opacity: 1,
  },
})(Tab)

const getSteps = () => {
  return ['Details', 'Destination', 'Budget Component', 'Assignee']
}

const AddEditPolicy = props => {
  const editId = props.match.params.id
  const isEdit = editId !== undefined
  const {data} = props
  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const [selectedLocations, setSelectedLocations] = useState([])
  const [isUpdateDestination, setisUpdateDestination] = useState(false)
  const [isUpdatePosition, setisUpdatePosition] = useState(false)
  const [isNameAlreadyExist, setIsNameAlreadyExist] = useState(false)
  const [budget, setBudget] = useState([])
  const [budgetHistory, setBudgetHistory] = useState({
    general: [],
    specific: [],
  })
  const [countLoc, setCountLoc] = useState(null)
  const [locationOption, setLocationOption] = useState(1)
  const [budgetOption, setBudgetOption] = useState('null')
  const [assignOption, setAssignOption] = useState(2)
  const [select, setSelect] = useState({
    approval1: {
      label: null,
      value: null,
    },
    position1: {
      label: null,
      value: null,
    },
    position2: {
      label: null,
      value: null,
    },
    position3: {
      label: null,
      value: null,
    },
    currency: currencyOptions[0],
  })
  const [checked, setChecked] = useState({
    needApproval1: false,
    needApproval2: false,
  })
  const [prevName, setPrevName] = useState('')
  const [dataContext, setDataContext] = useState({
    name: '',
    description: '',
    currency_to: 'idr',
    confirm_type: 1,
    first_confirm_profile: '',
    second_confirm_profile: '',
    third_confirm_profile: '',
    destination_type: 1,
    budget_component_type: 1,
    setup_destination: [],
  })

  const [filter, setFilter] = useState(null)

  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [isError, setIsError] = useState(false)
  // const [staggedRespondent, setStaggedRespondent] = useState([])
  const [open, setOpen] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [openAlertBudget, setOpenBudget] = useState(false)

  const [addPolicy] = useMutation(ADD_POLICY_BUSINESS_TRIP)
  const [editPolicy] = useMutation(EDIT_POLICY_BUSINESS_TRIP)

  const {data: budgetDataEdit} = useQuery(GET_BUDGET_EDIT, {
    wlb_skipPatch: true,
    skip: !editId,
    fetchPolicy: 'network-only',
    variables: {
      filter: selectedLocations,
      id: editId,
    },
  })
  const steps = getSteps()

  // perubahan probis ada penambahan step rater list di step 4, respondent menjadi step 5
  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <StepOne
            isError={isError}
            select={select}
            checked={checked}
            setChecked={setChecked}
            setSelect={setSelect}
            dataContext={dataContext}
            setDataContext={setDataContext}
            isNameAlreadyExist={isNameAlreadyExist}
          />
        )
      case 1:
        return (
          <StepTwo
            budgetDataEdit={budgetDataEdit}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            count={countLoc}
            setCount={setCountLoc}
            isError={isError}
            locationOption={locationOption}
            setLocationOption={setLocationOption}
            setisUpdateDestination={setisUpdateDestination}
            isEdit={isEdit}
            budgetOption={budgetOption}
            setBudgetOption={setBudgetOption}
            // setPrevLoc={setPrevLoc}
            setDataContext={setDataContext}
            setBudgetHistory={setBudgetHistory}
          />
        )
      case 2:
        return (
          <StepThree
            budgetDataEdit={budgetDataEdit}
            isError={isError}
            budgetOption={budgetOption}
            setBudgetOption={setBudgetOption}
            selectedLocations={selectedLocations}
            locationOption={locationOption}
            activeStep={activeStep}
            setBudget={setBudget}
            setisUpdateDestination={setisUpdateDestination}
            budgetHistory={budgetHistory}
            setBudgetHistory={setBudgetHistory}
            dataContext={dataContext}
            setDataContext={setDataContext}
            select={select}
            setSelect={setSelect}
          />
        )
      case 3:
        return (
          // step foour jadi step 5 (Respondents)
          <StepFour
            setisUpdatePosition={setisUpdatePosition}
            variables={filter}
            setVars={setFilter}
            isError={isError}
            assignOption={assignOption}
            setAssignOption={setAssignOption}
            isEdit={isEdit}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }
  // validasi errors

  const checkErrors = async () => {
    const {
      name,
      description,
      confirm_type,
      first_confirm_profile,
      second_confirm_profile,
      third_confirm_profile,
    } = dataContext

    let valError = false
    if (
      name === '' ||
      description === '' ||
      name.length > 50 ||
      select.approval1.value === null ||
      (select.approval1.value === 2 && select.position1.value === null) ||
      (checked.needApproval1 && select.position2.value === null) ||
      (checked.needApproval2 && select.position3.value === null) ||
      (checked.needApproval1 &&
        confirm_type === 2 &&
        first_confirm_profile === null &&
        second_confirm_profile === null) ||
      (checked.needApproval1 &&
        checked.needApproval2 &&
        confirm_type === 2 &&
        first_confirm_profile === null &&
        second_confirm_profile === null &&
        third_confirm_profile === null)
    ) {
      setIsError(true)
      valError = true
    }

    if (editId && name !== prevName) {
      const {data} = await client.query({
        query: GET_TOTAL_EXIST_PILICY_NAME,
        variables: {name},
      })
      const isNameAlreadyExist =
        data?.business_trip_policies_aggregate.aggregate.count > 0

      if (isNameAlreadyExist) {
        setActiveStep(0)
        setIsError(true)
        valError = true
        setIsNameAlreadyExist(true)
        setTimeout(() => {
          setIsNameAlreadyExist(false)
        }, 3000)
      }
    }

    if (assignOption === 1 && !filter) {
      setIsError((valError = true))
      setActiveStep(3)
    }

    if (isUpdateDestination) {
      if (budgetOption === 2) {
        if (budget.length > 0) {
          const a = budget.filter(row => row.budget.length === 0)
          if (a.length !== 0) {
            setIsError(true)
            valError = true
            setOpenBudget(true)
          }
        } else {
          setIsError(true)
          valError = true
          setOpenBudget(true)
        }
      } else if (budgetOption === 1) {
        if (budget?.length === 0 || budget?.[0]?.budget?.length === 0) {
          setIsError(true)
          valError = true
          setActiveStep(2)
        }
      }
      if (locationOption === 2) {
        if (selectedLocations.length === 0) {
          setIsError(true)
          valError = true
          setActiveStep(1)
        }
      }
    }
    if (locationOption === 1 && budgetOption === 2) {
      setIsError(true)
      valError = true
      setActiveStep(2)
    }
    setTimeout(() => setIsError(false), 3000)
    if (!valError) {
      setOpen(true)
    }
  }

  const handleNext = async () => {
    const {
      name,
      description,
      confirm_type,
      first_confirm_profile,
      second_confirm_profile,
      third_confirm_profile,
    } = dataContext
    if (activeStep === 0) {
      const {data} = await client.query({
        query: GET_TOTAL_EXIST_PILICY_NAME,
        variables: {name},
      })

      const isNameAlreadyExist =
        data?.business_trip_policies_aggregate.aggregate.count > 0

      if (name === '' || description === '') {
        setIsError(true)
      } else if (name.length > 50) {
        setIsError(true)
      } else if (select.approval1.value === null) {
        setIsError(true)
      } else if (
        select.approval1.value === 2 &&
        select.position1.value === null
      ) {
        setIsError(true)
      } else if (checked.needApproval1 && select.position2.value === null) {
        setIsError(true)
      } else if (checked.needApproval2 && select.position3.value === null) {
        setIsError(true)
      } else if (
        checked.needApproval1 &&
        confirm_type === 2 &&
        first_confirm_profile === null &&
        second_confirm_profile === null
      ) {
        setIsError(true)
      } else if (
        checked.needApproval2 &&
        confirm_type === 2 &&
        first_confirm_profile === null &&
        second_confirm_profile === null &&
        third_confirm_profile === null
      ) {
        setIsError(true)
      } else if (isNameAlreadyExist) {
        setIsError(true)
        setIsNameAlreadyExist(true)
        setTimeout(() => {
          setIsNameAlreadyExist(false)
        }, 3000)
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    } else if (activeStep === 1) {
      if (locationOption === 2) {
        setBudgetOption(2)
        if (selectedLocations.length === 0) {
          setIsError(true)
        } else {
          setActiveStep(prevActiveStep => prevActiveStep + 1)
        }
      } else {
        setBudgetOption(1)
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    } else if (activeStep === 2) {
      if (budgetOption === 2) {
        if (budget.length > 0) {
          const a = budget.filter(row => row.budget.length === 0)
          if (a.length !== 0) {
            setIsError(true)
            setOpenBudget(true)
          } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
          }
        } else {
          setIsError(true)
          setOpenBudget(true)
        }
      } else if (budgetOption === 1) {
        if (budget?.length === 0 || budget?.[0]?.budget?.length === 0) {
          setIsError(true)
        } else {
          setActiveStep(prevActiveStep => prevActiveStep + 1)
        }
      }
    } else if (activeStep === 3) {
      if (assignOption === 1 && !filter) {
        setIsError(true)
      }
    }

    setTimeout(() => setIsError(false), 3000)
    // setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const backtoTable = () => {
    props.history.push({
      pathname: '/business/trip/settings',
      state: {active: 'settings', searchable: true, activeTab: 'Policy'},
    })
  }

  useEffect(() => {
    if (editId) {
      const currency = data.currency_to
      const currencyOpt =
        (currency && currencyOptions.find(x => x.value === currency)) ||
        currencyOptions[0]

      const assignOpt = JSON.stringify(data.filter_position) !== '{}' ? 1 : 2

      setDataContext({
        ...dataContext,
        name: data.name,
        description: data.description,
        currency_to: currencyOpt.value,
        confirm_type: data.confirm_type,
        first_confirm_profile:
          data.companyJobProfileByFirstConfirmProfile !== null
            ? data.companyJobProfileByFirstConfirmProfile.id
            : null,
        second_confirm_profile:
          data.companyJobProfileBySecondConfirmProfile !== null
            ? data.companyJobProfileBySecondConfirmProfile.id
            : null,
        third_confirm_profile:
          data.companyJobProfileByThirdConfirmProfile !== null
            ? data?.companyJobProfileByThirdConfirmProfile?.id
            : null,
        destination_type: data.destination_type,
        budget_component_type: data.budget_component_type,
        setup_destination: [],
      })
      setPrevName(data.name)
      setBudgetOption(data.budget_component_type)
      setLocationOption(data.destination_type)
      setAssignOption(assignOpt)

      const valDefault = {
        label: null,
        value: null,
      }
      let approval1 = valDefault
      let position1 = valDefault
      let position2 = valDefault
      let position3 = valDefault
      if (data.confirm_type === 1) {
        approval1 = {
          value: 1,
          label: 'Direct Supervisor',
        }
      } else if (data.confirm_type === 2) {
        if (data.companyJobProfileByFirstConfirmProfile !== null) {
          approval1 = {
            value: 2,
            label: 'Position',
          }
          position1 = {
            value: data.companyJobProfileByFirstConfirmProfile.id,
            label: data.companyJobProfileByFirstConfirmProfile.title,
          }
        }
      } else if (data.confirm_type === 3) {
        approval1 = {
          value: 0,
          label: 'No Approval',
        }
      }
      if (data.companyJobProfileBySecondConfirmProfile) {
        position2 = {
          value: data.companyJobProfileBySecondConfirmProfile.id,
          label: data.companyJobProfileBySecondConfirmProfile.title,
        }
      }
      if (data.companyJobProfileByThirdConfirmProfile) {
        position3 = {
          value: data.companyJobProfileByThirdConfirmProfile.id,
          label: data.companyJobProfileByThirdConfirmProfile.title,
        }
      }
      const destination = []
      if (data.business_trip_policy_destinations.length > 0) {
        data.business_trip_policy_destinations.map(row => {
          if (row.global_destination?.id !== undefined) {
            destination.push(row.global_destination?.id)
          }
        })
      }

      if (assignOpt === 1) {
        const _dataFilter = data.filter_position

        const _newFilter = {}
        const isFilterExist = (data, stateFilter) => {
          let inOrNin = {_in: _dataFilter[data]}
          if (data.includes('exclude')) {
            inOrNin = {_nin: _dataFilter[data]}
          }
          if (inOrNin._in || inOrNin._nin) {
            _newFilter[stateFilter] = {
              ..._newFilter[stateFilter],
              ...inOrNin,
            }
          }
        }
        isFilterExist('filter_exclude_org_unit', 'filterOrgUnit')
        isFilterExist('filter_include_org_unit', 'filterOrgUnit')
        isFilterExist('filter_include_org_level', 'filterOrgLevel')
        isFilterExist('filter_exclude_org_level', 'filterOrgLevel')
        isFilterExist('filter_exclude_position', 'filterPosition')
        isFilterExist('filter_include_position', 'filterPosition')
        isFilterExist('filter_exclude_job_title', 'filterJobTitle')
        isFilterExist('filter_include_job_title', 'filterJobTitle')
        isFilterExist('filter_exclude_job_level', 'filterJobLevel')
        isFilterExist('filter_include_job_level', 'filterJobLevel')
        isFilterExist('filter_exclude_office', 'filterOffice')
        isFilterExist('filter_include_office', 'filterOffice')
        isFilterExist('filter_exclude_job_grade', 'filterJobGrade')
        isFilterExist('filter_include_job_grade', 'filterJobGrade')
        setFilter(_newFilter)
      } else {
        setFilter(null)
      }

      setSelectedLocations(destination)

      setChecked({
        needApproval1: !!data.companyJobProfileBySecondConfirmProfile,
        needApproval2: !!data.companyJobProfileByThirdConfirmProfile,
      })
      setSelect({
        ...select,
        approval1: approval1,
        position1: position1,
        position2: position2,
        position3: position3,
        currency: currencyOpt,
      })
      setisUpdateDestination(false)
      setisUpdatePosition(assignOpt === 2)
    }
  }, [editId])

  useEffect(() => {
    const {type, budget} = getInitialBudget(
      budgetDataEdit,
      data?.budget_component_type
    )

    setBudgetHistory(prev => ({
      ...prev,
      [type]: budget,
    }))
  }, [data, budgetDataEdit])

  const handleSubmit = () => {
    const successed = () => {
      enqueueSnackbar(`All Changes have been saved`, {
        variant: 'success',
        autoHideDuration: 1000,
      })
      backtoTable()
    }
    const {
      first_confirm_profile,
      second_confirm_profile,
      third_confirm_profile,
    } = getConfirmType(
      checked.needApproval1,
      checked.needApproval2,
      dataContext
    )
    if (editId) {
      editPolicy({
        variables: {
          id: parseInt(editId),
          name: dataContext.name,
          description: dataContext.description,
          confirm_type: dataContext.confirm_type,
          first_confirm_profile,
          second_confirm_profile,
          third_confirm_profile,
          destination_type: locationOption,
          budget_component_type: budgetOption,
          filter:
            isUpdatePosition && assignOption === 1 && filter
              ? manipulateFilter(filter)
              : {},
          isUpdatePosition: isUpdatePosition,
          isUpdateDestination: isUpdateDestination,
          setup_destination: isUpdateDestination ? budget : [],
          currency_to: dataContext.currency_to,
        },
      })
        .then(() => {
          successed()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
            autoHideDuration: 1000,
          })
        )
    } else {
      addPolicy({
        variables: {
          name: dataContext.name,
          description: dataContext.description,
          confirm_type: dataContext.confirm_type,
          first_confirm_profile,
          second_confirm_profile,
          third_confirm_profile,
          destination_type: locationOption,
          budget_component_type: budgetOption,
          filter: assignOption === 1 ? manipulateFilter(filter) : {},
          setup_destination: budget,
          currency_to: dataContext.currency_to,
        },
      })
        .then(() => {
          successed()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
            autoHideDuration: 1000,
          })
        )
    }
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setActiveStep(newValue)

    if (editId && newValue === 3) {
      setisUpdatePosition(true)
    }
  }

  const renderButtonCancel = () => {
    if (editId) {
      return (
        <Button
          className={classes.backButton}
          onClick={() => setOpenCancel(true)}
          size="large"
        >
          Cancel
        </Button>
      )
    } else {
      if (activeStep === 0) {
        return (
          <Button
            className={classes.backButton}
            onClick={() => setOpenCancel(true)}
            size="large"
          >
            Cancel
          </Button>
        )
      } else {
        return (
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={handleBack}
            className={classes.backButton}
          >
            Previous
          </Button>
        )
      }
    }
  }

  const renderButtonSubmit = () => {
    let handle
    let text
    if (editId) {
      handle = checkErrors
      text = 'Submit'
    } else {
      if (activeStep === steps.length - 1) {
        handle = checkErrors
        text = 'Submit'
      } else {
        handle = handleNext
        text = 'Next'
      }
    }
    return {handle, text}
  }

  const handleAlertCancel = status => {
    setOpenCancel(!openCancel)
    if (openCancel && status !== 'notNow') {
      props.history.push({
        pathname: '/business/trip/settings',
        state: {active: 'settings', searchable: true, activeTab: 'Policy'},
      })
    }
  }
  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => setOpenCancel(true)}
          >
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6">
            {editId ? 'Edit' : 'Add'} Policy
          </AddEditTitle>
        </FormTitleContainer>

        <div>
          {renderButtonCancel()}
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={renderButtonSubmit().handle}
            disabled={!editId && activeStep === 4}
          >
            {renderButtonSubmit().text}
          </Button>
        </div>
      </FormToolbar>

      {editId ? (
        <AntTabs
          value={activeStep}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTabs}
          aria-label="disabled tabs example"
          className={classes.tabs}
        >
          <StyledTab label="Details" />
          <StyledTab label="Destination" />
          <StyledTab label="Budget Component" />
          <StyledTab label="Assignee" />
        </AntTabs>
      ) : (
        <>
          <Divider className={classes.mb16} />
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<CustomConnector />}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    active: classes.labelActive,
                    completed: classes.labelActive,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.iconRoot,
                      text: classes.iconText,
                      active: classes.iconActive,
                      completed: classes.iconActive,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      )}

      <div style={{padding: '2rem'}}>{getStepContent(activeStep)}</div>

      <Dialog
        maxwidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle className={classes.titleDialog} style={{color: '#014A62'}}>
          {editId ? 'Save Changes' : 'Add New Business Trip Policy ?'}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText className={classes.contentDialog}>
            Are you sure
            {editId
              ? ' you want to save changes?'
              : ' want to add a new business trip policy?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionDialog}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            size="large"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={openCancel}
        handleClose={handleAlertCancel}
        feature={`Cancel to ${editId ? 'Edit' : 'Add'} Policy?`}
        message={`You have unsaved changes. Are you sure want to cancel ${
          editId ? 'Edit' : 'Add'
        } new  policy?`}
        type="Confirm"
        status="notNow"
      />

      <AlertDialog
        open={openAlertBudget}
        handleClose={() => setOpenBudget(false)}
        feature={`No Budget Filled`}
        message={`You need to fill out all the budget component for this trip`}
        type="Confirm"
        status="false"
      />
    </PaperContainer>
  )
}

export default withRouter(AddEditPolicy)
