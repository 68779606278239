import gql from 'graphql-tag'

export const COUNT_OVERTIME_DASHBOARD = gql`
  query getOvertimeDashboard($from: String!, $to: String!) {
    getOvertimeDashboard(from: $from, to: $to) {
      month
      count
    }
  }
`
