import gql from 'graphql-tag'

export const UPDATE_ATTENDANCE = gql`
  mutation(
    $id: Int!
    $clockIn: String
    $clockOut: String
    $description: String
    $attendanceFields: JSON
    $lateMinutes: Int
  ) {
    attendanceUpdate(
      id: $id
      clockIn: $clockIn
      clockOut: $clockOut
      description: $description
      attendanceFields: $attendanceFields
      lateMinutes: $lateMinutes
    ) {
      affected_rows
    }
  }
`
