import React from 'react'
import {Dialog, Button} from '@material-ui/core'
import moment from 'moment'
import {WrapperModalConfirmation} from '../PayrollPageStyles'

export default function ModalConfirmation({
  open,
  onClose,
  onConfirm,
  type,
  isLoading,
  activeStep,
  year,
  month,
  onConfirmDiscard,
  listOfApprover,
  actionAfterClose,
}) {
  const months = moment.monthsShort()

  const handleClose = () => {
    if ((type === 'confirmation' || type === 'reject') && activeStep === 2)
      return actionAfterClose()
    else return onClose()
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="xs">
      <WrapperModalConfirmation>
        <div className="header-modal-confirmation">
          {/* {type === 'confirmation' && active ? 'Confirm Changes?' : `Discard Changes?`} */}
          {type === 'confirmation' && activeStep === 0
            ? 'Calculate Payroll?'
            : type === 'confirmation' &&
              activeStep === 1 &&
              listOfApprover.length > 0
            ? 'Request Approval?'
            : type === 'confirmation' &&
              activeStep === 1 &&
              listOfApprover.length === 0
            ? 'Process?'
            : type === 'confirmation' && activeStep === 3
            ? 'Transferred THP Confirmation?'
            : type === 'reject' && activeStep === 2
            ? 'Reject Confirmation?'
            : type === 'confirmation' && activeStep === 2
            ? 'Approve Confirmation?'
            : type === 'confirmation' && activeStep === 4
            ? `Send Payroll Slip Confirmation?`
            : (type === 'cancel' && activeStep === 1) ||
              (type === 'cancel' && activeStep === 4)
            ? `Confirm Changes`
            : `Discard Changes?`}
        </div>
        <div className="body-modal-confirmation">
          {type === 'confirmation' && activeStep === 0
            ? `Are you sure to calculate ${
                months[month - 1]
              } ${year} payroll data?`
            : type === 'confirmation' &&
              activeStep === 1 &&
              listOfApprover.length > 0
            ? `Are you sure to request approval from ${
                listOfApprover.length === 3
                  ? `${listOfApprover[0]}, ${listOfApprover[1]}, and ${listOfApprover[2]}`
                  : listOfApprover.length === 2
                  ? `${listOfApprover[0]} and ${listOfApprover[1]}`
                  : listOfApprover.join()
              }?`
            : type === 'confirmation' &&
              activeStep === 1 &&
              listOfApprover.length === 0
            ? 'Are you sure to process Payroll Data?'
            : type === 'confirmation' && activeStep === 2
            ? 'Are you sure, you want to approve all of Payroll calculations?'
            : type === 'confirmation' && activeStep === 3
            ? 'Are you sure you have transferred all of employees Payroll?'
            : type === 'reject' && activeStep === 2
            ? 'Are you sure, you want to reject all of Payroll calculations?'
            : type === 'confirmation' && activeStep === 4
            ? `Are you sure to send ${year}-${month} Payroll Slip to all employees?`
            : (type === 'cancel' && activeStep === 1) ||
              (type === 'cancel' && activeStep === 4)
            ? `Are you sure want process to the previous step?`
            : 'Are you sure want to discard unsaved changes?'
          // type === 'confirmation' && activeStep === 2
          // ?
          // 'Are you sure, you want to approve all of Payroll calculations?'
          // :
          }
        </div>
        <div className="action-wrapper-modal-confirmation">
          <>
            <Button
              style={{fontSize: 12}}
              onClick={() => handleClose()}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              style={{fontSize: 12, marginLeft: 12}}
              onClick={e => {
                e.preventDefault()
                if (onConfirm && type === 'confirmation') {
                  onConfirm()
                }
                if (onConfirmDiscard && type !== 'confirmation') {
                  onConfirmDiscard()
                }
                onClose()
              }}
              size="large"
              variant="contained"
              color="primary"
            >
              {(type === 'cancel' && activeStep === 1) ||
              (type === 'cancel' && activeStep === 4)
                ? 'Confirm'
                : type === 'cancel'
                ? 'Discard'
                : 'Confirm'}
            </Button>
          </>

          {/* {activeStep === 4 && (
            <Button
              disabled={isLoading}
              style={{fontSize: 12, marginLeft: 12}}
              size="large"
              variant="contained"
              color="primary"
            >
              Finish
            </Button>
          )} */}
        </div>
      </WrapperModalConfirmation>
    </Dialog>
  )
}
