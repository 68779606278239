import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
} from '../../../../GlobalStyles'
import {
  Button,
  Divider,
  Typography,
  Checkbox,
  TablePagination,
} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {GET_LIST_SURVEY_CENTER_SUBSCRIBE} from '../../../../graphql/queries/survey/multirater-nps/survey-center/getListSurveyCenter.query'
import {COMPANY_ID, DEFAULT_PAGINATION} from '../../../../utils/globals'
import {
  DELETE_SURVEY,
  UPDATE_SURVEY_STATUS,
} from '../../../../graphql/mutations/survey/multirater-nps/survey-center/surveyCenter.mutation'
import {useSnackbar} from 'notistack'
import {
  TableHeaderStyled,
  TableBodyStyled,
  NomorRow,
  TitleRow,
  TypeRow,
  DateRow,
  TableWrapper,
  RespondentsRow,
  StatusRow,
} from '../MultiraterNpsPageStyles'
import PopupNomor from './PopupNomor'
import {dateFormat} from '../../../../utils/helpers'
import LoadingItemTable from './detail/shared-feedback/LoadingItemTable'
import {status_data} from '../../../shared-components/data/SharedData'

function SurveyCenter(props) {
  const {searchText} = props
  const {enqueueSnackbar} = useSnackbar()
  const [selected, setSelected] = useState([])
  const [dataSelected, setDataSelected] = useState([])
  const [pagination, setPagination] = useState({
    rowsPerPage: DEFAULT_PAGINATION[0],
    page: 0,
  })

  const [deleteState, setDeleteState] = useState({
    open: false,
    id: '',
    name: '',
  })

  useEffect(() => {
    setPagination(prev => ({...prev, page: 0}))
  }, [searchText, setPagination])

  const {data, error, loading, refetch} = useQuery(
    GET_LIST_SURVEY_CENTER_SUBSCRIBE,
    {
      fetchPolicy: 'no-cache',
      variables: {
        company_id: COMPANY_ID,
        title: `%${searchText}%`,
        limit: pagination.rowsPerPage,
        offset: pagination.page * pagination.rowsPerPage,
      },
    }
  )

  const [mutationDeleteSurvey] = useMutation(DELETE_SURVEY)
  const [updateSurveyStatus] = useMutation(UPDATE_SURVEY_STATUS)

  const handleOpenDelete = (id, name) => {
    setDeleteState({
      open: true,
      id,
      name,
    })
  }

  const handleCloseDelete = () => {
    setDeleteState({
      open: false,
      id: '',
      name: '',
    })
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPagination(e => ({...e, page: newPage}))
  }

  const handleChangeRowsPerPage = event => {
    setPagination(e => ({
      ...e,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    }))
  }

  const handleClick = (event, id, name) => {
    event.preventDefault()
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    let nameSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
      nameSelected = nameSelected.concat(dataSelected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
      nameSelected = nameSelected.concat(dataSelected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
      nameSelected = nameSelected.concat(dataSelected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
      nameSelected = nameSelected.concat(
        dataSelected.slice(0, selectedIndex),
        dataSelected.slice(selectedIndex + 1)
      )
    }

    const insert = nameSelected.map(function(el) {
      const o = Object.assign({}, el)
      o.company = COMPANY_ID
      return o
    })

    const result = insert.map(res => ({
      id: res.id,
      type: res.type,
      nps_include: res.nps_include,
      title: res.title,
      description: res.description,
      status: res.status,
      startdate: res.startdate,
      enddate: res.enddate,
      scale: res.scale,
      buttons: res.buttons,
      notification_email_format: res.notification_email_format,
      // company: res.company,
    }))

    setSelected(newSelected)
    setDataSelected(result)
  }

  const onDeleteQuestion = (id, title) => {
    const variables = {
      id,
    }
    mutationDeleteSurvey({variables})
      .then(() => {
        refetch()
        enqueueSnackbar(`Umpan balik ${title} dihapus `, {
          variant: 'success',
          autoHideDuration: 3000,
        })
      })
      .catch(() => {
        enqueueSnackbar(`Delete ${title} failed, please try again later`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const handleSetStatus = (id, status, title) => {
    updateSurveyStatus({
      variables: {
        id,
        status,
      },
    })
      .then(() => {
        refetch()
        enqueueSnackbar(`Feedback ${title} updated to Publish `, {
          variant: 'success',
          autoHideDuration: 3000,
        })
      })
      .catch(() => {
        enqueueSnackbar(
          `Feedback ${title} failed updated to Publish, please try again later`,
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  const isSelected = id => selected.indexOf(id) !== -1

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography style={{fontWeight: '600'}} variant="subtitle1">
          Pusat Umpan Balik
        </Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: `/survey/multirater-nps/survey-center/add`,
              state: {active: 'add-survey-center'},
            }}
          >
            Menambahkan Umpan Balik
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider></Divider>
      <div
        style={{
          width: '90%',
          overflowX: 'scroll',
          margin: 'auto',
          display: 'flex',
        }}
      >
        <TableWrapper>
          <TableHeaderStyled>
            <div style={{width: 42, height: 'auto'}} />
            <NomorRow>
              {selected.length < 1 ? (
                'No'
              ) : (
                <PopupNomor
                  refetch={refetch}
                  selected={selected}
                  setSelected={setSelected}
                  dataSelected={dataSelected}
                  setDataSelected={setDataSelected}
                />
              )}
            </NomorRow>
            <TitleRow style={{marginRight: 12}}>Judul Umpan Balik</TitleRow>
            <TypeRow>Jenis Umpan Balik</TypeRow>
            <DateRow>Dibuat Pada</DateRow>
            <DateRow>Dimodifikasi Pada</DateRow>
            <DateRow>Tanggal Aktivasi</DateRow>
            <DateRow>Tanggal Penutupan</DateRow>
            <RespondentsRow>Asesi</RespondentsRow>
            <StatusRow>Status</StatusRow>
            <Typography>Tindakan</Typography>
          </TableHeaderStyled>

          {loading && (
            <LoadingItemTable
              total={6}
              width="100%"
              height={42}
              style={{marginBottom: 12}}
            />
          )}
          {!loading && error && <div>{JSON.stringify(error, 0, 4)}</div>}
          {!loading &&
            data?.multirater_surveys?.map((row, index) => {
              const isItemSelected = isSelected(row.id)
              const labelId = `enhanced-table-checkbox-${index}`
              let isNpsExist = false
              let isMultiraterExist = false

              row.multirater_survey_groups.some(s => {
                if (s.isnps) {
                  isNpsExist = true
                } else {
                  isMultiraterExist = true
                }
              })

              return (
                <TableBodyStyled key={row.id}>
                  <Checkbox
                    onClick={event => handleClick(event, row.id, row)}
                    checked={isItemSelected}
                    inputProps={{'aria-labelledby': labelId}}
                    color="primary"
                  />
                  <NomorRow>
                    {index + 1 + pagination.rowsPerPage * pagination.page}.
                  </NomorRow>
                  <TitleRow style={{marginRight: 12}}>{row.title}</TitleRow>
                  <TypeRow>
                    {isNpsExist && 'NPS'}
                    {isNpsExist && isMultiraterExist && ', '}
                    {isMultiraterExist && 'Multirater'}
                  </TypeRow>
                  <DateRow>
                    {row.date_created
                      ? dateFormat(row.date_created, 'DD MMM YYYY')
                      : '-'}
                  </DateRow>
                  <DateRow>
                    {row.last_modified
                      ? dateFormat(row.last_modified, 'DD MMM YYYY')
                      : '-'}
                  </DateRow>
                  <DateRow>
                    {row.startdate
                      ? dateFormat(row.startdate, 'DD MMM YYYY')
                      : '-'}
                  </DateRow>
                  <DateRow>
                    {row.enddate ? dateFormat(row.enddate, 'DD MMM YYYY') : '-'}
                  </DateRow>
                  <RespondentsRow>
                    {row.multirater_responses_aggregate.aggregate.count} /{' '}
                    {row.multirater_respondents_aggregate.aggregate.count}
                  </RespondentsRow>
                  <StatusRow style={{color: surveyType?.[row.status]?.color}}>
                    {surveyType?.[row.status]?.status}
                  </StatusRow>
                  <div style={{margin: 'auto 0px'}}>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <>
                          <MoreIcon {...bindTrigger(popupState)}>
                            more_horiz
                          </MoreIcon>
                          <StyledMenus {...bindMenu(popupState)}>
                            <StyledMenuItem
                              component={Link}
                              to={{
                                pathname: `/survey/multirater-nps/survey-center/detail/${row.id}`,
                                state: {
                                  active: 'detail-survey-center',
                                  searchable: true,
                                },
                              }}
                              variant="h6"
                            >
                              Detail
                            </StyledMenuItem>
                            {row.status === 'DRAFT' && (
                              <StyledMenuItem
                                variant="h6"
                                onClick={() => {
                                  popupState.close()
                                  handleSetStatus(row.id, 'PUBLISH', row.title)
                                }}
                              >
                                Menerbitkan
                              </StyledMenuItem>
                            )}
                            <StyledMenuItem
                              variant="h6"
                              component={Link}
                              to={`/survey/multirater-nps/survey-center/preview/${row.id}`}
                            >
                              Pratinjau
                            </StyledMenuItem>
                            {row.status === 'DRAFT' && (
                              <StyledMenuItem
                                component={Link}
                                to={{
                                  pathname: `/survey/multirater-nps/survey-center/edit/${row.id}`,
                                  state: {
                                    active: 'edit-survey-center',
                                  },
                                }}
                                variant="h6"
                              >
                                Ubah
                              </StyledMenuItem>
                            )}
                            {row.status === 'PUBLISH' && (
                              <StyledMenuItem
                                variant="h6"
                                onClick={() => {
                                  popupState.close()
                                  handleSetStatus(
                                    row.id,
                                    'COMPLETED',
                                    row.title
                                  )
                                }}
                              >
                                Selesaikan
                              </StyledMenuItem>
                            )}
                            {row.status !== 'PUBLISH' && (
                              <StyledMenuItem
                                onClick={() => {
                                  handleOpenDelete(row.id, row.title)
                                  popupState.close()
                                }}
                              >
                                Hapus
                              </StyledMenuItem>
                            )}
                          </StyledMenus>
                        </>
                      )}
                    </PopupState>
                  </div>
                </TableBodyStyled>
              )
            })}
        </TableWrapper>
      </div>
      <TablePagination
        rowsPerPageOptions={DEFAULT_PAGINATION}
        component="div"
        count={data?.total?.aggregate?.count || 0}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Baris Per halaman"
        backIconButtonText="halaman sebelumnya"
        nextIconButtonText="halaman berikutnya"
        labelDisplayedRows={({from, to, count}) =>
          `${from} - ${to} dari ${count}`
        }
      />
      <DeletePopup
        open={deleteState.open}
        handleClose={handleCloseDelete}
        id={deleteState.id}
        name={deleteState.name}
        mutation={() => onDeleteQuestion(deleteState.id, deleteState.name)}
      />
    </PaperContainer>
  )
}

export default withRouter(SurveyCenter)

const surveyType = {
  DRAFT: {
    status: status_data.DRAFT,
    color: '#000',
  },
  PUBLISH: {
    status: status_data.PUBLISHED,
    color: '#039BE5',
  },
  COMPLETED: {
    status: status_data.COMPLETED,
    color: '#4CAF50',
  },
}
