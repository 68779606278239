import gql from 'graphql-tag'

export const DELETE_MODEL_IN_VERSION = gql`
  mutation($id: [bigint!]) {
    update_competency_model_items(
      _set: {deleted_at: "now()"}
      where: {id: {_in: $id}}
    ) {
      affected_rows
    }
  }
`
