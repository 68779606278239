import React from 'react'
import AsyncSelect, {components} from 'react-select'
import {Icon} from '@material-ui/core'
import {BlueIcon} from '../../../../../../GlobalStyles'

const ReactSelectNew = props => {
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon>arrow_drop_down</Icon>
      </components.DropdownIndicator>
    )
  }

  const DropdownIndicatorSearch = props => {
    return (
      <components.DropdownIndicator {...props}>
        <BlueIcon>search</BlueIcon>
      </components.DropdownIndicator>
    )
  }

  const SingleValue = ({children, ...props}) => (
    <components.SingleValue {...props}>{children}</components.SingleValue>
  )

  const SingleValuePlain = props => {
    return (
      <components.SingleValue {...props}>
        {props.data.value}
      </components.SingleValue>
    )
  }

  const MultiValue = props => {
    return (
      <components.MultiValue {...props}>
        {props.data.value}
      </components.MultiValue>
    )
  }
  const customSelect = {
    control: provided => ({
      ...provided,
      width: '100%',
      minWidth: 250,
      outline: 'none',
      // height: 55,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: '#039be5',
      padding: 8,
      borderRadius: 5,
      margin: 5,
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#fff',
    }),
    multiValueRemove: base => ({
      ...base,
      color: '#ffff',
      '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
    }),
    menu: base => ({
      ...base,
      maxWidth: '100%',
    }),
  }

  return (
    <AsyncSelect
      styles={customSelect}
      components={{
        DropdownIndicator: props.indicatorSearch
          ? DropdownIndicatorSearch
          : DropdownIndicator,
        MultiValue,
        SingleValue: props.singleValuePlain ? SingleValuePlain : SingleValue,
      }}
      {...props}
    />
  )
}

export default ReactSelectNew
