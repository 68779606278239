import LearningProfilePageComponent from './LearningProfilePageComponent'

export const LearningProfilePageConfig = {
  routes: [
    {
      path: '/holding-learning/learning-profile',
      component: LearningProfilePageComponent,
      state: 'learning-profile-list',
      exact: true,
    },
    {
      path: '/holding-learning/detail-profile/:userid',
      component: LearningProfilePageComponent,
      state: 'detail-learning-profile',
      exact: true,
    },
  ],
}
