import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'

import ListComponent from './ListComponent'
import PendingActivationComponent from './PendingActivationComponent'
import ConfiguringComponent from './ConfiguringComponent'
import AddCompanyComponent from './add-company/AddCompanyComponent'
import ProfileDashboard from '../../company-group/profile/ProfileDashboard'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'member-list':
      return <ListComponent state={state} searchText={searchText} />
    case 'member-pending':
      return (
        <PendingActivationComponent state={state} searchText={searchText} />
      )
    case 'member-config':
      return <ConfiguringComponent state={state} searchText={searchText} />
    case 'add-company':
      return <AddCompanyComponent />
    case 'detail-company':
      return <ProfileDashboard feature="holding-member" />
    default:
      return <ListComponent state={state} searchText={searchText} />
  }
}

const ListPageComponent = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <div>
      <Card icon="business" title="Member" onSearch={onSearch}>
        <PageContainer>
          {renderComponent(active, state, searchText)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default ListPageComponent
