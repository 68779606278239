import PropTypes from 'prop-types'
import React, {useRef, useEffect, useState} from 'react'
import addClass from 'dom-helpers/addClass'
import removeClass from 'dom-helpers/removeClass'
import getWidth from 'dom-helpers/width'
import scrollbarSize from 'dom-helpers/scrollbarSize'

import {navigate} from 'react-big-calendar/lib/utils/constants'

import * as dates from 'react-big-calendar/lib/utils/dates'
import {inRange} from 'react-big-calendar/lib/utils/eventLevels'
import {isSelected} from 'react-big-calendar/lib/utils/selection'
import moment from 'moment'
import {ScheduleContainer, TextBlackBold} from '../../../../../GlobalStyles'
import {Divider} from '@material-ui/core'
import ModalCalendarSchedule from '../detail-calendar/ModalCalendarSchedule'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'

const Schedule = ({
  selected,
  getters,
  accessors,
  localizer,
  components,
  // length,
  date,
  events,
  refetch,
}) => {
  const headerRef = useRef(null)
  const dateColRef = useRef(null)
  const timeColRef = useRef(null)
  const contentRef = useRef(null)
  const tbodyRef = useRef(null)
  useEffect(() => {
    _adjustHeader()
  })

  const [openModal, setOpenModal] = useState(false)
  const [dataCalendar, setDataCalendar] = useState(null)
  const [color, setColor] = useState('')

  const handleOpenModal = (data, color) => {
    if (openModal) {
      setOpenModal(false)
    } else {
      setDataCalendar(data)
      setColor(color)
      setOpenModal(true)
    }

    // if (type === "leave") {
    //   setOpenModal({ ...openModal, modalLeave: true })
    // } else if (type === "holiday") {
    //   setOpenModal({ ...openModal, modalHoliday: true })
    // } else if (!type) {
    //   setOpenModal({ modalLeave: false, modalHoliday: false })
    //   setDataCalendar(null)
    // }
  }

  const checkDataHoliday = events.filter(
    holiday => holiday.typeCalender.toLowerCase().indexOf('holiday') > -1
  )

  const renderDay = (day, events, dayKey) => {
    const {event: Event, date: AgendaDate} = components

    events = events.filter(e =>
      inRange(e, dates.startOf(day, 'day'), dates.endOf(day, 'day'), accessors)
    )

    return events.map((event, idx) => {
      if (event.typeCalender === 'holiday') {
        const title = accessors.title(event)
        const end = accessors.end(event)
        const start = accessors.start(event)

        const userProps = getters.eventProp(
          event,
          start,
          end,
          isSelected(event, selected)
        )

        const dateLabel =
          idx === 0 &&
          moment(localizer.format(day, 'agendaDateFormat')).format(
            'DD MMMM, dddd'
          )
        const first =
          idx === 0 ? (
            <div className="schedule-date">
              {AgendaDate ? (
                <AgendaDate day={day} label={dateLabel} />
              ) : (
                <>
                  <TextBlackBold>{dateLabel}</TextBlackBold>
                  <Divider />
                </>
              )}
            </div>
          ) : (
            false
          )

        return (
          <div
            key={dayKey + '_' + idx}
            className="schedule-container"
            onClick={() => handleOpenModal(event, userProps.style)}
          >
            {first}
            <div className="schedule-row">
              <div style={userProps.style} className="chip-schedule" />
              <div className="time-schedule">{timeRangeLabel(day, event)}</div>
              <div className="name-schedule">
                {Event ? <Event event={event} title={title} /> : title}
              </div>
            </div>
          </div>
        )
      }
    }, [])
  }

  const timeRangeLabel = (day, event) => {
    let labelClass = ''
    const TimeComponent = components.time
    let label = localizer.messages.allDay

    const end = accessors.end(event)
    const start = accessors.start(event)

    if (!accessors.allDay(event)) {
      if (dates.eq(start, end)) {
        label = localizer.format(start, 'agendaTimeFormat')
      } else if (dates.eq(start, end, 'day')) {
        label = localizer.format({start, end}, 'agendaTimeRangeFormat')
      } else if (dates.eq(day, start, 'day')) {
        label = localizer.format(start, 'agendaTimeFormat')
      } else if (dates.eq(day, end, 'day')) {
        label = localizer.format(end, 'agendaTimeFormat')
      }
    }

    if (dates.gt(day, start, 'day')) labelClass = 'rbc-continues-prior'
    if (dates.lt(day, end, 'day')) labelClass += ' rbc-continues-after'

    return (
      <span className={labelClass.trim()}>
        {TimeComponent ? (
          <TimeComponent event={event} day={day} label={label} />
        ) : (
          label
        )}
      </span>
    )
  }

  const _adjustHeader = () => {
    if (!tbodyRef.current) return

    const header = headerRef.current
    const firstRow = tbodyRef.current.firstChild

    if (!firstRow) return

    const isOverflowing =
      contentRef.current.scrollHeight > contentRef.current.clientHeight

    let _widths = []
    const widths = _widths

    _widths = [getWidth(firstRow.children[0]), getWidth(firstRow.children[1])]

    if (widths[0] !== _widths[0] || widths[1] !== _widths[1]) {
      dateColRef.current.style.width = _widths[0] + 'px'
      timeColRef.current.style.width = _widths[1] + 'px'
    }

    if (isOverflowing) {
      addClass(header, 'rbc-header-overflowing')
      header.style.marginRight = scrollbarSize() + 'px'
    } else {
      removeClass(header, 'rbc-header-overflowing')
    }
  }

  let dateChange = date
  if (date._i !== undefined) {
    dateChange = date._i
  }

  const start = new Date(dateChange.getFullYear(), 0, 1)
  const end = new Date(dateChange.getFullYear(), 11, 31)

  const range = dates.range(start, end, 'day')

  events = events.filter(event => inRange(event, start, end, accessors))

  events.sort((a, b) => +accessors.start(a) - +accessors.start(b))

  return (
    <div className="rbc-agenda-view">
      {events.length !== 0 ? (
        <React.Fragment>
          <ScheduleContainer>
            {checkDataHoliday.length > 0 ? (
              range.map((day, idx) => renderDay(day, events, idx))
            ) : (
              <NoDataListComponent message={``} />
            )}
          </ScheduleContainer>
        </React.Fragment>
      ) : (
        <NoDataListComponent message={``} />
      )}

      <ModalCalendarSchedule
        refetch={refetch}
        open={openModal}
        data={dataCalendar}
        color={color}
        handleClose={handleOpenModal}
      />
    </div>
  )
}

Schedule.propTypes = {
  events: PropTypes.array,
  date: PropTypes.instanceOf(Date),
  length: PropTypes.number.isRequired,

  selected: PropTypes.object,

  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object.isRequired,
}

Schedule.defaultProps = {
  length: 30,
}

Schedule.range = (start, {length = Schedule.defaultProps.length}) => {
  const end = dates.add(start, length, 'day')
  return {start, end}
}

Schedule.navigate = (date, action, {length = Schedule.defaultProps.length}) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -length, 'day')

    case navigate.NEXT:
      return dates.add(date, length, 'day')

    default:
      return date
  }
}

Schedule.title = (
  start,
  {length = Schedule.defaultProps.length, localizer}
) => {
  const end = dates.add(start, length, 'day')
  return localizer.format({start, end}, 'agendaHeaderFormat')
}

export default Schedule
