import gql from 'graphql-tag'

export const GET_ANNUAL_REPORT = gql`
  query ListAnnualPayrollReport(
    $company: uuid!
    $year: Int!
    $offset: Int
    $limit: Int
    $search: String
    $rank: [Int!]
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $office: [Int!]
  ) {
    total: people_work_payroll_annual_aggregate(
      where: {
        people_work_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
          people_work_payrolls: {year: {_eq: $year}}
        }
        year: {_eq: $year}
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_payroll_annual(
      where: {
        people_work_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
          people_work_payrolls: {year: {_eq: $year}}
        }
        year: {_eq: $year}
      }
      limit: $limit
      offset: $offset
    ) {
      id
      salary
      allowance_corrections
      nfc_corrections
      employee_placement: people_work_placement {
        id
        regno
        status
        end_date
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          company_organization {
            id
            name
          }
          company_employee_position {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
            global_province {
              id
              name
            }
            global_city {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const CHECK_ANNUAL_REPORT = gql`
  query CheckAnnualReport($company: uuid, $year: Int) {
    people_work_payroll_aggregate(
      where: {
        employee_placement: {company: {_eq: $company}}
        year: {_eq: $year}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
