import gql from 'graphql-tag'

export const ADD_TALENT_SUCCESSOR = gql`
  mutation(
    $job_profile: Int
    $candidate: Int
    $candidate_status: String
    $readiness_status: String
  ) {
    delete_talent_pull_successors(
      where: {job_profile: {_eq: $job_profile}, candidate: {_eq: $candidate}}
    ) {
      affected_rows
    }

    insert_talent_pull_successors(
      objects: {
        job_profile: $job_profile
        candidate: $candidate
        candidate_status: $candidate_status
        readiness_status: $readiness_status
      }
    ) {
      affected_rows
    }
  }
`

export const DELETE_TALENT_POOL = gql`
  mutation($id: Int!) {
    delete_talent_pull_successors(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`
