import React, {useState} from 'react'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  WrapperContent,
} from '../../OrganizationPageStyles'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
  PaperContainer,
  EnhancedToolbar,
} from '../../../../GlobalStyles'
import {useSnackbar} from 'notistack'
import {Icon, IconButton, Button, Divider, TextField} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {ADD_ORG_LEVEL, EDIT_ORG_LEVEL} from '../../../../graphql/mutations'
import {CHECK_CODE_JOB_LEVEL} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'

import Loading from '../../../../components/loading/LoadingComponent'
import AddEditPopup from '../../../shared-components/popup/AddEditPopupFreeText'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import ColorInputGroup from '../../../../components/form/ColorInputGroup'
import AppliedContent from '../../../shared-components/popup/AppliedContent'

const AddEditLevel = props => {
  const {status, data} = props
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const dataLevel = data
    ? {
        code: data.code,
        name: data.name,
        color_hex: data.color_hex,
      }
    : {
        code: '',
        name: '',
        color_hex: '',
      }

  const hasJobProfile =
    data &&
    data.company_job_profiles_aggregate &&
    data.company_job_profiles_aggregate.aggregate &&
    data.company_job_profiles_aggregate.aggregate.count > 0

  const [allDataFrom, setAllDataFrom] = useState(dataLevel)
  const [isError, setIsError] = useState(false)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [dialogCancel, setDialogCancel] = useState(false)

  const [addData] = useMutation(ADD_ORG_LEVEL)
  const [editData] = useMutation(EDIT_ORG_LEVEL)

  const {data: checkCodeJobLvl, error: checkCodeJobLvlError} = useQuery(
    CHECK_CODE_JOB_LEVEL,
    {
      variables: {
        company: COMPANY_ID,
        code: allDataFrom && allDataFrom.code,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  if (checkCodeJobLvl === undefined && checkCodeJobLvlError === undefined) {
    return <Loading />
  } else if (checkCodeJobLvlError !== undefined) {
    return <div>{checkCodeJobLvlError}</div>
  }

  // const nameChecker =
  //   dataCheck &&
  //   dataCheck.company_employee_ranks &&
  //   dataCheck.company_employee_ranks.some(
  //     v => v.name.toLowerCase() === allDataFrom.name.toLowerCase()
  //   )

  const codeChecker =
    checkCodeJobLvl &&
    checkCodeJobLvl.company_employee_ranks_aggregate &&
    checkCodeJobLvl.company_employee_ranks_aggregate.aggregate &&
    checkCodeJobLvl.company_employee_ranks_aggregate.aggregate.count > 0

  // const checkSameNameWithAdd = data && data.name === allDataFrom.name
  const checkSameCodeWithAdd =
    allDataFrom && allDataFrom.code && allDataFrom.code.length >= 1
      ? data && data.code === allDataFrom.code
      : false

  const checkCharaCode =
    allDataFrom && allDataFrom.code && allDataFrom.code.length > 30
  const checkCharaName =
    allDataFrom && allDataFrom.name && allDataFrom.name.length > 100

  const openDialogConfirm = () => {
    if (
      !allDataFrom.code ||
      !allDataFrom.name ||
      !allDataFrom.color_hex ||
      checkCharaCode ||
      checkCharaName ||
      // (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd) ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 2000)
    } else {
      dialogConfirm ? setDialogConfirm(false) : setDialogConfirm(true)
    }
  }

  const handleAlert = status => {
    dialogCancel ? setDialogCancel(false) : setDialogCancel(true)
    if (dialogCancel && status !== 'notNow') {
      props.history.push({
        pathname: '/organization/job-level',
        state: {active: 'job-level', searchable: true},
      })
    }
  }

  const handleAddLevel = () => {
    if (status === 'Add') {
      const dataForAdd = {
        objects: [allDataFrom],
      }
      addData({variables: dataForAdd})
        .then(() => {
          enqueueSnackbar('Jenjang Jabatan Ditambahkan', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          props.history.push({
            pathname: '/organization/job-level',
            state: {active: 'job-level', searchable: true},
          })
        })
        .catch(() => {
          enqueueSnackbar(
            'Menambahkan Jenjang Jabatan gagal, coba lagi nanti',
            {
              variant: 'error',
              autoHideDuration: 1000,
            }
          )
        })
    } else if (status === 'Edit') {
      const dataForEdit = {
        id: data && data.id,
        name: allDataFrom.name,
        code: allDataFrom.code,
        color_hex: allDataFrom.color_hex,
      }
      editData({variables: dataForEdit})
        .then(() => {
          enqueueSnackbar('Jenjang Jabatan Diubah', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          props.history.push({
            pathname: '/organization/job-level',
            state: {active: 'job-level', searchable: true},
          })
        })
        .catch(() => {
          enqueueSnackbar('Ubah Jenjang Jabatan gagal, coba lagi nanti', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  return (
    <OrganizationContainer>
      <PaperContainer className={classes.height600px}>
        <EnhancedToolbar className={classes.paddingLeft0}>
          <WrapperContent>
            <Link
              to={{
                pathname: '/organization/job-level',
                state: {active: 'job-level', searchable: true},
              }}
              style={{textDecoration: 'none'}}
            >
              <IconButton edge="end" aria-label="back">
                <Icon>arrow_back</Icon>
              </IconButton>
            </Link>
            <BigTextBlackBold className={classes.marginLeft10}>{`${
              status === 'Add' ? 'Tambah' : 'Ubah'
            } Jenjang Jabatan`}</BigTextBlackBold>
          </WrapperContent>
          <div>
            <Button
              size="large"
              onClick={handleAlert}
              style={{marginRight: 12}}
            >
              Batal
            </Button>
            <Button
              className={classes.marginLeft10}
              variant="contained"
              color="primary"
              size="large"
              onClick={openDialogConfirm}
            >
              Simpan
            </Button>
          </div>
        </EnhancedToolbar>
        <Divider />
        <OrganizationContainer>
          <FormContainer id="top">
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>1.</TitleNumber>
                <TitleName>Kode Jenjang Jabatan *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="levelCode"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="Tambah Kode Jenjang Jabatan"
                  value={allDataFrom.code}
                  margin="normal"
                  className={classes.helperMarginLeft0}
                  error={
                    (!allDataFrom.code && isError) ||
                    checkCharaCode ||
                    (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
                  }
                  helperText={
                    !allDataFrom.code && isError
                      ? 'Bagian ini diperlukan'
                      : (codeChecker
                        ? !checkSameCodeWithAdd
                        : checkSameCodeWithAdd)
                      ? 'Kode Jenjang Jabatan sudah ada'
                      : checkCharaCode
                      ? 'Jumlah karakter maksimum adalah 30 karakter'
                      : 'Kode Jenjang Jabatan harus unik'
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      code: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>2.</TitleNumber>
                <TitleName>Nama Jenjang Jabatan *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgName"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="Tambah Nama Jenjang Jabatan"
                  className={classes.helperMarginLeft0}
                  value={allDataFrom.name}
                  margin="normal"
                  error={
                    (!allDataFrom.name && isError) || checkCharaName
                    // ||(nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
                  }
                  helperText={
                    !allDataFrom.name && isError
                      ? 'Bagian ini diperlukan'
                      : checkCharaName
                      ? 'Jumlah karakter maksimum adalah 100 karakter'
                      : ''
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      name: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>

            <ColorInputGroup
              fieldNumber="3"
              fieldLabel="Warna Legenda *"
              tooltipMessage="Warna legenda akan ditampilkan di Struktur Organisasi"
              value={allDataFrom.color_hex}
              error={isError && !allDataFrom.color_hex}
              onChange={value => {
                setAllDataFrom({...allDataFrom, color_hex: value})
              }}
            />
          </FormContainer>
        </OrganizationContainer>
      </PaperContainer>
      {hasJobProfile ? (
        <AddEditPopup
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          mutation={handleAddLevel}
          header={'Konfirmasi Perubahan?'}
          body={
            <div>
              <AppliedContent
                applied={data && data.company_job_profiles}
                classes={classes}
                target="Posisi"
              />
              Apakah Anda yakin ingin menyimpan perubahan?
            </div>
          }
          buttonClose="Batal"
          button="Konfirmasi"
        />
      ) : (
        <AddEditPopup
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          mutation={handleAddLevel}
          header={CONFIRMATION[status].header.replace('[name]', data?.name)}
          body={CONFIRMATION[status].body.replace('[name]', data?.name)}
          button={CONFIRMATION[status].button}
          buttonClose="Batal"
        />
      )}
      <AlertDialog
        open={dialogCancel}
        handleClose={handleAlert}
        feature={`Batal Untuk ${data ? 'Ubah' : 'Tambah'} Jenjang Jabatan?`}
        message={`Anda memiliki perubahan yang belum disimpan. Anda yakin ingin membatalkan ${
          data ? `ubah ${data.name}` : 'menambahkan Jenjang Jabatan baru'
        }?`}
        type="Yes, Cancel"
        positiveTitleButton="Ya, Batal"
        status="notNow"
      />
    </OrganizationContainer>
  )
}

export default withRouter(AddEditLevel)

const CONFIRMATION = {
  Add: {
    header: 'Tambah Jenjang Jabatan',
    body: 'Apakah Anda yakin ingin menambahkan Jenjang Jabatan?',
    button: 'Tambah',
  },
  Edit: {
    header: 'Ubah [name]',
    body: 'Apakah Anda yakin ingin mengubah [name]?',
    button: 'Ubah',
  },
}
