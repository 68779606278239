import {Avatar, Divider, Icon, Typography} from '@material-ui/core'
import React from 'react'
import {BoldTypography} from '../../../../../../components/typography/TypographyStyles'
import {GreyTypography, MediumAvatar} from '../../../../../../GlobalStyles'

const FirstPlacement = ({classes}) => {
  return (
    <>
      <div className={classes.companyWrapper}>
        <MediumAvatar
          src="https://vignette.wikia.nocookie.net/naruto/images/0/09/Naruto_newshot.png/revision/latest/scale-to-width-down/340?cb=20170621101134"
          className={classes.mr28}
        ></MediumAvatar>
        <div className={classes.structureHeader}>
          <BoldTypography variant="body1" gutterBottom>
            Josdeph Hendrix
          </BoldTypography>
          <GreyTypography variant="body2" gutterBottom>
            CEO at PT. Superbarber
          </GreyTypography>
          <div className={classes.flexTitle}>
            <img
              src="https://avatarfiles.alphacoders.com/115/thumb-115925.jpg"
              alt="asd"
              width="20"
              style={{marginRight: 6}}
            ></img>
            <Typography variant="caption">Solid Contributor</Typography>
            <Divider
              orientation="vertical"
              className={classes.dividerLittle}
            ></Divider>
            <Icon
              style={{
                fontSize: 20,
                color: '#4caf50',
                marginRight: 4,
              }}
            >
              star
            </Icon>
            <Typography variant="caption">3.98</Typography>
          </div>
        </div>
      </div>
      <>
        <Typography variant="body2" style={{marginBottom: 20}}>
          To Position
        </Typography>
        <div style={{display: 'flex'}}>
          <Avatar
            src="https://vignette.wikia.nocookie.net/naruto/images/0/09/Naruto_newshot.png/revision/latest/scale-to-width-down/340?cb=20170621101134"
            className={classes.mr13}
            variant="rounded"
          ></Avatar>
          <div className={classes.structureHeader}>
            <BoldTypography variant="body2" color="primary" gutterBottom>
              CEO at PT. Marvelous
            </BoldTypography>
            <GreyTypography variant="body2" gutterBottom>
              PT. Marvelous
            </GreyTypography>
          </div>
        </div>
      </>
    </>
  )
}

export default FirstPlacement
