import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import CloseIcon from '@material-ui/icons/Close'
import React, {useState} from 'react'

import {
  BlueIcon,
  MoreIcon,
  StyledMenus,
  WhiteModalHeader,
} from '../../../../GlobalStyles'
import {
  Avatar,
  Paper,
  // TextField,
  // InputAdornment,
  makeStyles,
  Typography,
  Icon,
  MenuItem,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import {TableOptions} from '../../../shared-components/table/TableOptions'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {BoldTypography} from '../../../../components/typography/TypographyStyles'
import ModalNominateTalent from './nominate-talent/ModalNominateTalent'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {DEL_FROM_SOURCE_TALENT_HOLDING} from '../../../../graphql/mutations'
import {holdingRolesContext} from '../../../../utils/helpers'
import {useSnackbar} from 'notistack'
import LoadingEmployee from '../../../directory/employee/LoadingEmployee'
import {GET_DETAIL_HOLDING_CHART} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'

const useStyles = makeStyles(() => ({
  root: {
    padding: 26,
    background: '#f7f8f9',
    minHeight: '70vh',
    width: '-webkit-fill-available',
  },
  mr18: {marginRight: 18, marginLeft: 12},
  shadow: {boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)'},
  wrapperButton: {display: 'flex'},
  little: {minWidth: 46, background: 'white'},
  structureWrapper: {marginTop: 40},
  paperStructure: {padding: 15, width: 200, margin: 'auto'},
  divider: {margin: '15px -15px 0!important'},
  companyInfo: {padding: '14px 0', display: 'flex', alignItems: 'center'},
  agroupRoot: {margin: '0 14px 0 6px'},
  divCategory: {
    width: 116,
    height: 25,
    borderRadius: 3,
    backgroundColor: '#EFF2F4',
    fontSize: 12,
    color: '#000',
    fontWeight: 600,
    lineHeight: 2,
  },
  structureHeader: {textAlign: 'left', cursor: 'pointer'},
  wrapperCategory: {display: 'flex', textAlign: 'center', margin: '26px 0'},
  paddingWrapp: {padding: '26px 32px'},
  btnZoom: {
    position: 'absolute',
    top: '7rem',
    zIndex: 1,
    right: '3%',
  },
  bgPrimary: {backgroundColor: '#014a62!important', color: '#fff!important'},
  bgSecondary: {backgroundColor: '#039be5!important', color: '#fff!important'},
  actions: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  spacerIcon: {marginRight: 12},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const DetailChart = ({open, handleClose, search_x, search_y, search_z}) => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [openNominate, setOpenNominate] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [sourceId, setSourceId] = useState(0)
  const [pwpId, setPwpId] = useState(0)
  const [confirmType, setConfirmType] = useState('')
  const [selectedNomination, setSelectedNomination] = useState([])

  const {data, loading, error, refetch} = useQuery(GET_DETAIL_HOLDING_CHART, {
    variables: {
      company: COMPANY_ID,
      search_x,
      search_y,
      search_z,
    },
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const handleClickAction = (id, type, pwp_id) => {
    if (type === 'add') {
      setOpenNominate(true)
    } else {
      setOpenDel(true)
    }
    setSourceId(id)
    setPwpId(pwp_id)
    setConfirmType(type)
  }

  const handleCloseAction = () => {
    if (confirmType === 'add') {
      setOpenNominate(false)
      setSelectedNomination([])
    } else {
      setOpenDel(false)
    }
    setSourceId(0)
  }

  const [mutationDelFromSource] = useMutation(DEL_FROM_SOURCE_TALENT_HOLDING)

  const handleMutation = () => {
    mutationDelFromSource({
      variables: {id: sourceId},
      ...holdingRolesContext,
    })
      .then(() => {
        enqueueSnackbar('Success Update data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(
          'Update data error, please try again' + JSON.stringify(e),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  if (loading) return <LoadingEmployee />
  if (error) return <div>{error}</div>

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{width: '50%', left: '50%'}}
    >
      <WhiteModalHeader className={classes.paddingWrapp}>
        <div className={classes.wrapperCategory}>
          <Typography>X = </Typography>
          <div
            className={`${classes.divCategory} ${classes.bgSecondary} ${classes.mr18}`}
          >
            {data.clasification.length > 0 && data.clasification[0].x.name}
          </div>
          <Typography>Y = </Typography>
          <div
            className={`${classes.divCategory} ${classes.bgSecondary} ${classes.mr18}`}
          >
            {data.clasification.length > 0 && data.clasification[0].y.name}
          </div>
          <Typography>Z = </Typography>
          <div
            className={`${classes.divCategory} ${classes.bgSecondary} ${classes.mr18}`}
          >
            {data.clasification.length > 0 && data.clasification[0].z.name}
          </div>
        </div>

        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </WhiteModalHeader>
      <Divider />
      <div className={classes.paddingWrapp}>
        {/* <TextField
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BlueIcon>search</BlueIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            fullWidth
            size="small"
            margin="normal"
            value={search}
            onChange={e => setSearch(e.target.value)}
          /> */}
        <MaterialTable
          style={{fontSize: 12}}
          options={{...TableOptions, selection: false, search: true}}
          localization={{
            body: {
              emptyDataSourceMessage: 'No verified talent source',
            },
          }}
          columns={[
            {
              title: '',
              field: '',
              render: row => (
                <Avatar
                  src={row.global_user.avatar}
                  alt={row.global_user.name}
                />
              ),
              sorting: false,
            },
            {title: 'Employee ID', field: 'regno'},
            {title: 'Employee Name', field: 'global_user.name'},
            {title: 'Position', field: 'company_job_profile.title'},
            {
              title: 'Action',
              field: 'id',
              sorting: false,
              render: row =>
                row.holding_sources.length > 0 && (
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {popupState => (
                      <>
                        <MoreIcon {...bindTrigger(popupState)}>
                          more_horiz
                        </MoreIcon>

                        <StyledMenus {...bindMenu(popupState)}>
                          <MenuItem
                            className={classes.actions}
                            onClick={() => {
                              popupState.close()
                              handleClickAction(
                                row.holding_sources[0].id,
                                'add',
                                row.id
                              )
                            }}
                          >
                            <BlueIcon className={classes.spacerIcon}>
                              person_add
                            </BlueIcon>
                            <BoldTypography variant="body2">
                              Nominate
                            </BoldTypography>
                          </MenuItem>
                          <MenuItem
                            className={classes.actions}
                            onClick={() => {
                              popupState.close()
                              handleClickAction(
                                row.holding_sources[0].id,
                                'delete',
                                row.id
                              )
                            }}
                          >
                            <Icon
                              color="secondary"
                              className={classes.spacerIcon}
                            >
                              person_remove
                            </Icon>
                            <BoldTypography variant="body2">
                              Remove
                            </BoldTypography>
                          </MenuItem>
                        </StyledMenus>
                      </>
                    )}
                  </PopupState>
                ),
            },
          ]}
          components={{
            Container: props => <Paper {...props} elevation={0} />,
          }}
          title={`Talent (${data.total.aggregate.count})`}
          data={data.data}
        />
      </div>
      <ModalNominateTalent
        handleClose={handleCloseAction}
        open={openNominate}
        source_id={sourceId}
        pwp_id={pwpId}
        selectedId={selectedNomination}
        setSelectedId={setSelectedNomination}
      />
      <DeletePopup
        open={openDel}
        handleClose={handleCloseAction}
        id={sourceId}
        pwp_id={pwpId}
        name={`from talent source`}
        mutation={() => handleMutation()}
      />
    </Dialog>
  )
}

export default DetailChart
