import React from 'react'
import {InputAdornment, TextField} from '@material-ui/core'

import helperStyle, {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
} from '../../GlobalStyles'
import FieldInformationTooltip from '../../pages/shared-components/tooltip/FieldInformationTooltip'

import {convertToAngka} from '../../utils/helpers'

export default function NumberInputGroup(props) {
  const {
    additionalInputProps,
    additionalonchangemethod,
    dataForm,
    setDataForm,
    defaultValue,
    fieldLabel,
    fieldNumber,
    fieldName,
    isCurrency,
    isDecimal,
    isFullWidth,
    isRequired = true,
    maskingMethod,
    placeholder,
    showErrorHelper = () => false,
    tooltipMesssage,
    value,
    helperText = () => {
      return 'This field is required'
    },
    ...restProps
  } = props

  const classes = helperStyle()
  const styleFullWidth = isFullWidth ? {style: {width: '100%'}} : ''
  const formValue = value
    ? {value: value}
    : {defaultValue: maskingMethod ? maskingMethod(defaultValue || null) : null}

  return (
    <FormChildContainer {...styleFullWidth}>
      <FormChildTitle>
        <TitleNumber>{fieldNumber}.</TitleNumber>
        <TitleName>{fieldLabel}</TitleName>
        {tooltipMesssage && <FieldInformationTooltip title={tooltipMesssage} />}
      </FormChildTitle>

      <FormChildInput>
        <TextField
          fullWidth
          {...formValue}
          id={`text-field-${fieldNumber}-${fieldName}`}
          className={classes.helperMarginLeft0}
          error={showErrorHelper()}
          helperText={showErrorHelper() && helperText()}
          margin="normal"
          variant="outlined"
          placeholder={placeholder}
          required={isRequired}
          size="small"
          inputProps={{
            style: {textAlign: 'right'},
          }}
          InputProps={{
            startAdornment: isCurrency && (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            classes: {input: classes.inputNumberPlaceholder},
            ...additionalInputProps,
          }}
          onChange={event => {
            const value = event.target.value
            const inputElement = document.getElementById(
              `text-field-${fieldNumber}-${fieldName}`
            )

            if (inputElement) {
              inputElement.value = maskingMethod
                ? maskingMethod(value || null)
                : convertToAngka(value)
            }

            const dataFormValue = isDecimal
              ? value
                ? parseFloat(value)
                : value
              : convertToAngka(value)

            additionalonchangemethod
              ? additionalonchangemethod(dataFormValue)
              : setDataForm({
                  ...dataForm,
                  [fieldName]: dataFormValue,
                })
          }}
          {...restProps}
        />
      </FormChildInput>
    </FormChildContainer>
  )
}
