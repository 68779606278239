export const isErrorForm = fieldsList => {
  let isError = false
  const _fieldsList = [...fieldsList]
  const filteredList = _fieldsList.filter(list => list.required)
  Object.keys(filteredList).map(function(key) {
    if (filteredList[key].type === 'date') {
      if (
        (filteredList[key].value[0] === null ||
          filteredList[key].value[1] === null) &&
        !filteredList[key].value[2]
      ) {
        isError = true
      } else if (
        filteredList[key].value[0] === null &&
        filteredList[key].value[2]
      ) {
        isError = true
      }
    } else {
      if (['', null, undefined].indexOf(filteredList[key].value) !== -1) {
        isError = true
      }
    }
  })

  return isError
}
