import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {Typography} from '@material-ui/core'

import {GET_CURRENCY_EXCHANGE} from '../../../graphql/queries'

import {formatCurrency, getCurrencySign} from './currencyExchanges'

export function ConversionDisplay({from, to = 'idr', date, value, smaller}) {
  const {data} = useQuery(GET_CURRENCY_EXCHANGE, {
    wlb_skipPatch: true,
    skip: from === to,
    fetchPolicy: date ? 'cache-first' : 'cache-and-network',
    variables: {
      currency: from,
      date: date ? date.split('T')[0] : '',
    },
  })

  const formattedFrom = React.useMemo(() => {
    return formatCurrency(from, value)
  }, [from, value])

  const formattedTo = React.useMemo(() => {
    return (
      data &&
      formatCurrency(to, value * Math.round(data.getCurrencyExchange.idr))
    )
  }, [to, value, data])

  return (
    <div>
      <Typography
        style={{fontSize: smaller ? 12 : 14, fontWeight: smaller ? 400 : 600}}
        align="right"
      >
        {formattedFrom}
      </Typography>

      {from !== to && (
        <Typography
          style={{fontSize: smaller ? 12 : 14}}
          align="right"
          color="textSecondary"
        >
          {data ? formattedTo : `${getCurrencySign(to)} ⋯`}
        </Typography>
      )}
    </div>
  )
}

export function InputConversionDisplay({from, to, value, dense}) {
  const {data} = useQuery(GET_CURRENCY_EXCHANGE, {
    skip: from === to,
    fetchPolicy: 'cache-and-network',
    variables: {
      currency: from,
      date: '',
    },
  })

  const formattedTo = React.useMemo(() => {
    return (
      data &&
      formatCurrency(to, value * Math.round(data.getCurrencyExchange.idr))
    )
  }, [to, value, data])

  return (
    <Typography
      style={{
        fontSize: dense ? 12 : 14,
        lineHeight: `14px`,
        marginTop: dense ? 4 : 8,
      }}
      align="right"
      color="textSecondary"
    >
      {data ? formattedTo : `${getCurrencySign(to)} ⋯`}
    </Typography>
  )
}
