import gql from 'graphql-tag'

export const ADD_VERSION_CROSS_MENTORING = gql`
  mutation(
    $name: String!
    $description: String!
    $start_date: date!
    $end_date: date!
    $status: String!
  ) {
    insert_classroom_cross_mentoring_versions(
      objects: [
        {
          name: $name
          description: $description
          start_date: $start_date
          end_date: $end_date
          status: $status
        }
      ]
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
