import styled from 'styled-components'

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-family: ${props => props.theme.font};
  color: black;
  padding: 8px 28px 8px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);

  .title-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;

    .title {
      font-size: 16px;
      font-weight: 600;
      margin-left: 12px;
    }
  }
`
export const WrapperContent = styled.div`
  padding: 30px 24px;
  min-height: 400px;

  .title-field-wrapper {
    display: flex;

    h5 {
      font-size: 14px;
      font-weight: 600;
      margin: 0px;
    }

    .number {
      margin-right: 1rem;
    }
  }

  .input-field-wrapper {
    margin-top: 19px;
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;

    .ckeditor {
      max-width: 561px;
    }
  }
`

export const FooterCk = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 0px 0px 5px 5px;
  background-color: #014a62;
`
