import React, {useState, useEffect} from 'react'
import helperStyle, {
  FlexStart,
  OrganizationContainer,
  LitleRedText,
  LitleLightBlueText,
} from '../../../OrganizationPageStyles'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
  TextHelper,
} from '../../../../../GlobalStyles'
import {CircularProgress} from '@material-ui/core'
import classNames from 'classnames'
import Select from 'react-select'
import {useQuery} from '@apollo/react-hooks'
import {
  GET_LIST_KPI,
  // GET_LIST_WORK_SCHEDULE,
  // GET_LIST_LEAVE_POLICY,
  // GET_LIST_TIME_POLICY,
  // GET_LIST_ALLOWANCE_POLICY,
  // GET_LIST_LOAN_POLICY,
  // GET_LIST_CASH_ADVANCE_POLICY,
  // GET_LIST_DIGITAL_DOCUMENT,
  // GET_LIST_FACILITY,
} from '../../../../../graphql/queries'
import {withRouter} from 'react-router-dom'

const {getRoleFromValueToNumber, getOrgRoles} = require('wlb-role-helper')

const TabWorkPolicies = props => {
  const {allDatas, status, dataEdit, setAllData, isError} = props
  const classes = helperStyle()

  const [countKpi, setCountKpi] = useState(0)
  // const [countWorkSchedule, setCountWorkSchedule] = useState(0)
  // const [countLeavePolicy, setCountLeavePolicy] = useState(0)
  // const [countTimePolicy, setCountTimePolicy] = useState(0)
  // const [countAllowancePolicy, setCountAllowancePolicy] = useState(0)
  // const [countLoanPolicy, setCountLoanPolicy] = useState(0)
  // const [countCashAdvPolicy, setCountCashAdvPolicy] = useState(0)
  // const [countDigitalDoc, setCountDigitalDoc] = useState(0)
  // const [countFacility, setCountFacility] = useState(0)
  const [countAccessRight, setCountAccessRight] = useState(0)

  const [kpiDropdown, setKpiDropdown] = useState([])
  // const [workScheduleDropdown, setWorkScheduleDropdown] = useState([])
  // const [leavePolicyDropdown, setLeavePolicyDropdown] = useState([])
  // const [timePolicyDropdown, setTimePolicyDropdown] = useState([])
  // const [allowancePolicyDropdown, setAllowancePolicyDropdown] = useState([])
  // const [loanPolicyDropdown, setLoanPolicyDropdown] = useState([])
  // const [cashAdvPolicyDropdown, setCashAdvPolicyDropdown] = useState([])
  // const [digitalDocDropdown, setDigitalDocDropdown] = useState([])
  // const [facilityDropdown, setFacilityDropdown] = useState([])
  const [accessRightDropdown, setAccessRightDropdown] = useState([])

  const [idKpi, setIdKpi] = useState([])
  // const [idWorkSchedule, setIdWorkSchedule] = useState([])
  // const [idLeavePolicy, setIdLeavePolicy] = useState([])
  // const [idTimePolicy, setIdTimePolicy] = useState([])
  // const [idAllowancePolicy, setIdAllowancePolicy] = useState([])
  // const [idLoanPolicy, setIdLoanPolicy] = useState([])
  // const [idCashAdvPolicy, setIdCashAdvPolicy] = useState([])
  // const [idDigitalDoc, setIdDigitalDoc] = useState([])
  // const [idFacility, setIdFacility] = useState([])
  const [idAccessRight, setIdAccessRight] = useState([6])
  // const [dataIdAccessRight, setdataIdAccessRight] = useState('')
  const [searchDropdown, setSearchDropdown] = useState('')

  const allDataForm = {
    kpi: idKpi,
    // work_schedule: idWorkSchedule,
    // leave: idLeavePolicy,
    // overtime: idTimePolicy,
    // allowance: idAllowancePolicy,
    // loan: idLoanPolicy,
    // cash_adv: idCashAdvPolicy,
    // documents: idDigitalDoc,
    // facilities: idFacility,
    roles: idAccessRight,
  }

  // eslint-disable-next-line no-use-before-define
  // console.log({allDatas})
  // console.log({idAccessRight})

  const accessRight = [
    {
      value: 2,
      label: 'organization-finance-admin',
    },
    {
      value: 3,
      label: 'organization-hr-admin',
    },
    {
      value: 6,
      label: 'organization-staff',
    },
  ]

  useEffect(() => {
    if (allDatas.IdKpi && allDatas.dataDropdownKpi && idKpi.length === 0) {
      if (allDatas.IdKpi.length > 0) {
        setIdKpi([...allDatas.IdKpi])
        setCountKpi(allDatas.IdKpi.length - 1)
        setKpiDropdown([...allDatas.dataDropdownKpi])
      }
    }
    // if (
    //   allDatas.IdWorkSchedule &&
    //   allDatas.dataDropdownWorkSchedule &&
    //   idWorkSchedule.length === 0
    // ) {
    //   if (allDatas.IdWorkSchedule.length > 0) {
    //     setIdWorkSchedule([...allDatas.IdWorkSchedule])
    //     setCountWorkSchedule(allDatas.IdWorkSchedule.length - 1)
    //     setWorkScheduleDropdown([...allDatas.dataDropdownWorkSchedule])
    //   }
    // }
    // if (
    //   allDatas.IdLeavePolicy &&
    //   allDatas.dataDropdownLeavePolicy &&
    //   idLeavePolicy.length === 0
    // ) {
    //   if (allDatas.IdLeavePolicy.length > 0) {
    //     setCountLeavePolicy(allDatas.IdLeavePolicy.length - 1)
    //     setLeavePolicyDropdown([...allDatas.dataDropdownLeavePolicy])
    //     setIdLeavePolicy([...allDatas.IdLeavePolicy])
    //   }
    // }
    // if (
    //   allDatas.IdTimePolicy &&
    //   allDatas.dataDropdownTimePolicy &&
    //   idTimePolicy.length === 0
    // ) {
    //   if (allDatas.IdTimePolicy.length > 0) {
    //     setCountTimePolicy(allDatas.IdTimePolicy.length - 1)
    //     setTimePolicyDropdown([...allDatas.dataDropdownTimePolicy])
    //     setIdTimePolicy([...allDatas.IdTimePolicy])
    //   }
    // }
    // if (
    //   allDatas.IdAllowancePolicy &&
    //   allDatas.dataDropdownAllowancePolicy &&
    //   idAllowancePolicy.length === 0
    // ) {
    //   if (allDatas.IdAllowancePolicy.length > 0) {
    //     setCountAllowancePolicy(allDatas.IdAllowancePolicy.length - 1)
    //     setAllowancePolicyDropdown([...allDatas.dataDropdownAllowancePolicy])
    //     setIdAllowancePolicy([...allDatas.IdAllowancePolicy])
    //   }
    // }
    // if (
    //   allDatas.IdLoanPolicy &&
    //   allDatas.dataDropdownLoanPolicy &&
    //   idLoanPolicy.length === 0
    // ) {
    //   if (allDatas.IdLoanPolicy.length > 0) {
    //     setCountLoanPolicy(allDatas.IdLoanPolicy.length - 1)
    //     setLoanPolicyDropdown([...allDatas.dataDropdownLoanPolicy])
    //     setIdLoanPolicy([...allDatas.IdLoanPolicy])
    //   }
    // }
    // if (
    //   allDatas.IdCashAdvPolicy &&
    //   allDatas.dataDropdownCashAdvPolicy &&
    //   idCashAdvPolicy.length === 0
    // ) {
    //   if (allDatas.IdCashAdvPolicy.length > 0) {
    //     setCountCashAdvPolicy(allDatas.IdCashAdvPolicy.length - 1)
    //     setCashAdvPolicyDropdown([...allDatas.dataDropdownCashAdvPolicy])
    //     setIdCashAdvPolicy([...allDatas.IdCashAdvPolicy])
    //   }
    // }
    // if (
    //   allDatas.IdDigitalDoc &&
    //   allDatas.dataDropdownDigitalDoc &&
    //   idDigitalDoc.length === 0
    // ) {
    //   if (allDatas.IdDigitalDoc.length > 0) {
    //     setCountDigitalDoc(allDatas.IdDigitalDoc.length - 1)
    //     setDigitalDocDropdown([...allDatas.dataDropdownDigitalDoc])
    //     setIdDigitalDoc([...allDatas.IdDigitalDoc])
    //   }
    // }
    // if (
    //   allDatas.IdFacility &&
    //   allDatas.dataDropdownFacility &&
    //   idFacility.length === 0
    // ) {
    //   if (allDatas.IdFacility.length > 0) {
    //     setCountFacility(allDatas.IdFacility.length - 1)
    //     setFacilityDropdown([...allDatas.dataDropdownFacility])
    //     setIdFacility([...allDatas.IdFacility])
    //   }
    // }
    if (
      allDatas.IdAccessRight &&
      allDatas.dataDropdownAccessRight &&
      idAccessRight.length === 0
    ) {
      if (allDatas.IdAccessRight.length > 0) {
        setCountAccessRight(allDatas.IdAccessRight.length - 1)
        setAccessRightDropdown([...allDatas.dataDropdownAccessRight])
        setIdAccessRight([...allDatas.IdAccessRight])
      }
    }
  }, [
    allDatas,
    // idKpi,
    // idWorkSchedule,
    // idLeavePolicy,
    // idTimePolicy,
    // idAllowancePolicy,
    // idLoanPolicy,
    // idCashAdvPolicy,
    // idDigitalDoc,
    // idFacility,
    idAccessRight,
  ])

  const {data: kpi, loading: kpiLoad, error: kpiError} = useQuery(
    GET_LIST_KPI,
    {
      variables: {
        search: `%${searchDropdown}%`,
        offset: 0,
        limit: 30,
      },
    }
  )
  // const {
  //   data: workSchedule,
  //   loading: workScheduleLoad,
  //   error: workScheduleError,
  // } = useQuery(GET_LIST_WORK_SCHEDULE)
  // const {
  //   data: leavePolicy,
  //   loading: leavePolicyLoad,
  //   error: leavePolicyError,
  // } = useQuery(GET_LIST_LEAVE_POLICY)
  // const {
  //   data: timePolicy,
  //   loading: timePolicyLoad,
  //   error: timePolicyError,
  // } = useQuery(GET_LIST_TIME_POLICY)
  // const {
  //   data: allowancePolicy,
  //   loading: allowancePolicyLoad,
  //   error: allowancePolicyError,
  // } = useQuery(GET_LIST_ALLOWANCE_POLICY)
  // const {
  //   data: loanPolicy,
  //   loading: loanPolicyLoad,
  //   error: loanPolicyError,
  // } = useQuery(GET_LIST_LOAN_POLICY)
  // const {
  //   data: cashAdvPolicy,
  //   loading: cashAdvPolicyLoad,
  //   error: cashAdvPolicyError,
  // } = useQuery(GET_LIST_CASH_ADVANCE_POLICY)
  // const {
  //   data: digitalDoc,
  //   loading: digitalDocLoad,
  //   error: digitalDocError,
  // } = useQuery(GET_LIST_DIGITAL_DOCUMENT)
  // const {
  //   data: facility,
  //   loading: facilityLoad,
  //   error: facilityError,
  // } = useQuery(GET_LIST_FACILITY)

  useEffect(() => {
    if (status === 'Edit') {
      const Kpis = []
      const idKpis = []
      dataEdit &&
        dataEdit.company_job_profile_r_kpi.map(options => {
          const kpiData = {
            value: options.kpi_list && options.kpi_list.id,
            label: options.kpi_list && options.kpi_list.name,
          }

          const idKpiData = {
            job_profile: dataEdit.id,
            kpi: options.kpi_list && options.kpi_list.id,
          }

          Kpis.push(kpiData)
          idKpis.push(idKpiData)
        })

      setKpiDropdown(Kpis)
      setIdKpi(idKpis)
      setCountKpi(dataEdit.company_job_profile_r_kpi.length - 1)

      const orgRoles = getOrgRoles(dataEdit.roles ? dataEdit.roles : [])
      const accessRights = []
      const dataIdAR = [6]
      orgRoles.map(options => {
        const getIdAccess = accessRight.filter(
          roles => roles.label.toLowerCase().indexOf(options.toLowerCase()) > -1
        )
        const valueGetIdAccess =
          getIdAccess && getIdAccess[0] && getIdAccess[0].value

        if (valueGetIdAccess !== 6) {
          const accessRightData = {
            value: valueGetIdAccess,
            label: options,
          }
          accessRights.push(accessRightData)
          dataIdAR.push(valueGetIdAccess)
        }
      })

      setIdAccessRight(dataIdAR)
      setAccessRightDropdown(accessRights)
      setCountAccessRight(orgRoles.length - 1)
      // setdataIdAccessRight(dataEdit.roles ? dataEdit.roles : [])
      const idAccessRightCustom = getRoleFromValueToNumber(dataIdAR)
      setAllData({
        ...allDatas,
        IdKpi: idKpis,
        IdAccessRight: dataEdit.roles ? idAccessRightCustom : [],
      })
    }
    if (status === 'Add') {
      setAllData({
        ...allDatas,
        IdKpi: [],
        IdAccessRight: '00000010',
      })
    }
  }, [status])

  if (
    kpi === undefined &&
    kpiError === undefined
    // workScheduleLoad ||
    // leavePolicyLoad ||
    // timePolicyLoad ||
    // allowancePolicyLoad ||
    // loanPolicyLoad ||
    // cashAdvPolicyLoad ||
    // digitalDocLoad ||
    // facilityLoad
  ) {
    return <CircularProgress />
  } else if (
    kpiError !== undefined
    // workScheduleError !== undefined ||
    // leavePolicyError !== undefined ||
    // timePolicyError !== undefined ||
    // allowancePolicyError !== undefined ||
    // loanPolicyError !== undefined ||
    // cashAdvPolicyError !== undefined ||
    // digitalDocError !== undefined ||
    // facilityError !== undefined
  ) {
    return (
      <div>
        {kpiError}
        {/* {workScheduleError}
        {leavePolicyError}
        {timePolicyError}
        {allowancePolicyError}
        {loanPolicyError}
        {cashAdvPolicyError}
        {digitalDocError}
        {facilityError} */}
      </div>
    )
  }

  const kpis = []
  kpi &&
    kpi.kpi_lists.map(options => {
      const kpiOptions = {
        value: options.id,
        label: `${options.code} - ${options.name}`,
      }

      kpis.push(kpiOptions)
    })

  // const workSch = []
  // workSchedule &&
  //   workSchedule.time_attendance_policies.map(options => {
  //     const workScheduleOptions = {
  //       value: options.id,
  //       label: options.name,
  //     }

  //     workSch.push(workScheduleOptions)
  //   })

  // const leavePolicies = []
  // leavePolicy &&
  //   leavePolicy.time_leave_policy_children.map(options => {
  //     const leavePolicyOptions = {
  //       value: options.id,
  //       label: options.name,
  //     }

  //     leavePolicies.push(leavePolicyOptions)
  //   })

  // const timePolicies = []
  // timePolicy &&
  //   timePolicy.time_overtime_policies.map(options => {
  //     const timePolicyOptions = {
  //       value: options.id,
  //       label: options.name,
  //     }

  //     timePolicies.push(timePolicyOptions)
  //   })

  // const allowancePolicies = []
  // allowancePolicy &&
  //   allowancePolicy.finance_allowance_policy_children.map(options => {
  //     const allowancePolicyOptions = {
  //       value: options.id,
  //       label: options.name,
  //     }

  //     allowancePolicies.push(allowancePolicyOptions)
  //   })

  // const loanPolicies = []
  // loanPolicy &&
  //   loanPolicy.finance_loan_policies.map(options => {
  //     const loanPolicyOptions = {
  //       value: options.id,
  //       label: options.name,
  //     }

  //     loanPolicies.push(loanPolicyOptions)
  //   })

  // const cashAdvPolicies = []
  // cashAdvPolicy &&
  //   cashAdvPolicy.finance_cash_advance_policies.map(options => {
  //     const cashAdvPolicyOptions = {
  //       value: options.id,
  //       label: options.name,
  //     }

  //     cashAdvPolicies.push(cashAdvPolicyOptions)
  //   })

  // const digitalDocument = []
  // digitalDoc &&
  //   digitalDoc.company_files.map(options => {
  //     const digitalDocOptions = {
  //       value: options.id,
  //       label: options.name,
  //     }

  //     digitalDocument.push(digitalDocOptions)
  //   })

  // const facilities = []
  // facility &&
  //   facility.company_facilities.map(options => {
  //     const facilityOptions = {
  //       value: options.id,
  //       label: options.name,
  //     }

  //     facilities.push(facilityOptions)
  //   })

  const addDataKpi = (event, id) => {
    const data = kpiDropdown
    const dataForm = idKpi
    if (data.length === id) {
      const add = [
        ...data,
        {
          label: event.label,
          value: event.value,
        },
      ]
      let addFrom = []
      if (status === 'Add') {
        addFrom = [
          ...dataForm,
          {
            kpi: event.value,
          },
        ]
      } else if (status === 'Edit') {
        addFrom = [
          ...dataForm,
          {
            job_profile: dataEdit.id,
            kpi: event.value,
          },
        ]
      }

      setIdKpi(addFrom)
      setKpiDropdown(add)
      setAllData({
        ...allDatas,
        dataDropdownKpi: add,
        IdKpi: addFrom,
      })
    } else {
      const update = [...data]
      update[id].label = event.label
      update[id].value = event.value

      const updateForm = [...dataForm]
      updateForm[id].kpi = event.value

      setKpiDropdown(update)
      setIdKpi(updateForm)
    }
  }

  // const addDataWorkSchedule = (event, id) => {
  //   const data = workScheduleDropdown
  //   const dataForm = idWorkSchedule
  //   if (data.length === id) {
  //     const add = [
  //       ...data,
  //       {
  //         label: event.label,
  //         value: event.value,
  //       },
  //     ]

  //     let addFrom = []
  //     if (status === 'Add') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           policy: event.value,
  //         },
  //       ]
  //     } else if (status === 'Edit') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           profile: dataEdit.id,
  //           policy: event.value,
  //         },
  //       ]
  //     }

  //     setWorkScheduleDropdown(add)
  //     setIdWorkSchedule(addFrom)
  //     setAllData({
  //       ...allDatas,
  //       dataDropdownWorkSchedule: add,
  //       IdWorkSchedule: addFrom,
  //     })
  //   } else {
  //     const update = [...data]
  //     update[id].label = event.label
  //     update[id].value = event.value

  //     const updateForm = [...dataForm]
  //     updateForm[id].policy = event.value

  //     setWorkScheduleDropdown(update)
  //     setIdWorkSchedule(updateForm)
  //   }
  // }

  // const addDataLeavePolicy = (event, id) => {
  //   const data = leavePolicyDropdown
  //   const dataForm = idLeavePolicy
  //   if (data.length === id) {
  //     const add = [
  //       ...data,
  //       {
  //         label: event.label,
  //         value: event.value,
  //       },
  //     ]

  //     let addFrom = []
  //     if (status === 'Add') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           policy: event.value,
  //         },
  //       ]
  //     } else if (status === 'Edit') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           profile: dataEdit.id,
  //           policy: event.value,
  //         },
  //       ]
  //     }

  //     setLeavePolicyDropdown(add)
  //     setIdLeavePolicy(addFrom)
  //     setAllData({
  //       ...allDatas,
  //       dataDropdownLeavePolicy: add,
  //       IdLeavePolicy: addFrom,
  //     })
  //   } else {
  //     const update = [...data]
  //     update[id].label = event.label
  //     update[id].value = event.value

  //     const updateForm = [...dataForm]
  //     updateForm[id].policy = event.value

  //     setLeavePolicyDropdown(update)
  //     setIdLeavePolicy(updateForm)
  //   }
  // }

  // const addDataTimePolicy = (event, id) => {
  //   const data = timePolicyDropdown
  //   const dataForm = idTimePolicy
  //   if (data.length === id) {
  //     const add = [
  //       ...data,
  //       {
  //         label: event.label,
  //         value: event.value,
  //       },
  //     ]
  //     let addFrom = []
  //     if (status === 'Add') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           policy: event.value,
  //         },
  //       ]
  //     } else if (status === 'Edit') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           profile: dataEdit.id,
  //           policy: event.value,
  //         },
  //       ]
  //     }

  //     setTimePolicyDropdown(add)
  //     setIdTimePolicy(addFrom)
  //     setAllData({
  //       ...allDatas,
  //       dataDropdownTimePolicy: add,
  //       IdTimePolicy: addFrom,
  //     })
  //   } else {
  //     const update = [...data]
  //     update[id].label = event.label
  //     update[id].value = event.value

  //     const updateForm = [...dataForm]
  //     updateForm[id].policy = event.value

  //     setTimePolicyDropdown(update)
  //     setIdTimePolicy(updateForm)
  //   }
  // }

  // const addDataAllowancePolicy = (event, id) => {
  //   const data = allowancePolicyDropdown
  //   const dataForm = idAllowancePolicy
  //   if (data.length === id) {
  //     const add = [
  //       ...data,
  //       {
  //         label: event.label,
  //         value: event.value,
  //       },
  //     ]
  //     let addFrom = []
  //     if (status === 'Add') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           policy: event.value,
  //         },
  //       ]
  //     } else if (status === 'Edit') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           profile: dataEdit.id,
  //           policy: event.value,
  //         },
  //       ]
  //     }
  //     setAllowancePolicyDropdown(add)
  //     setIdAllowancePolicy(addFrom)
  //     setAllData({
  //       ...allDatas,
  //       dataDropdownAllowancePolicy: add,
  //       IdAllowancePolicy: addFrom,
  //     })
  //   } else {
  //     const update = [...data]
  //     update[id].label = event.label
  //     update[id].value = event.value

  //     const updateForm = [...dataForm]
  //     updateForm[id].policy = event.value

  //     setAllowancePolicyDropdown(update)
  //     setIdAllowancePolicy(updateForm)
  //   }
  // }

  // const addDataLoanPolicy = (event, id) => {
  //   const data = loanPolicyDropdown
  //   const dataForm = idLoanPolicy
  //   if (data.length === id) {
  //     const add = [
  //       ...data,
  //       {
  //         label: event.label,
  //         value: event.value,
  //       },
  //     ]
  //     let addFrom = []
  //     if (status === 'Add') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           policy: event.value,
  //         },
  //       ]
  //     } else if (status === 'Edit') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           profile: dataEdit.id,
  //           policy: event.value,
  //         },
  //       ]
  //     }
  //     setLoanPolicyDropdown(add)
  //     setIdLoanPolicy(addFrom)
  //     setAllData({
  //       ...allDatas,
  //       dataDropdownLoanPolicy: add,
  //       IdLoanPolicy: addFrom,
  //     })
  //   } else {
  //     const update = [...data]
  //     update[id].label = event.label
  //     update[id].value = event.value

  //     const updateForm = [...dataForm]
  //     updateForm[id].policy = event.value

  //     setLoanPolicyDropdown(update)
  //     setIdLoanPolicy(updateForm)
  //   }
  // }

  // const addDataCashAdvPolicy = (event, id) => {
  //   const data = cashAdvPolicyDropdown
  //   const dataForm = idCashAdvPolicy
  //   if (data.length === id) {
  //     const add = [
  //       ...data,
  //       {
  //         label: event.label,
  //         value: event.value,
  //       },
  //     ]
  //     let addFrom = []
  //     if (status === 'Add') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           policy: event.value,
  //         },
  //       ]
  //     } else if (status === 'Edit') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           profile: dataEdit.id,
  //           policy: event.value,
  //         },
  //       ]
  //     }
  //     setCashAdvPolicyDropdown(add)
  //     setIdCashAdvPolicy(addFrom)
  //     setAllData({
  //       ...allDatas,
  //       dataDropdownCashAdvPolicy: add,
  //       IdCashAdvPolicy: addFrom,
  //     })
  //   } else {
  //     const update = [...data]
  //     update[id].label = event.label
  //     update[id].value = event.value

  //     const updateForm = [...dataForm]
  //     updateForm[id].policy = event.value

  //     setCashAdvPolicyDropdown(update)
  //     setIdCashAdvPolicy(updateForm)
  //   }
  // }

  // const addDataDigitalDocument = (event, id) => {
  //   const data = digitalDocDropdown
  //   const dataForm = idDigitalDoc
  //   if (data.length === id) {
  //     const add = [
  //       ...data,
  //       {
  //         label: event.label,
  //         value: event.value,
  //       },
  //     ]

  //     let addFrom = []
  //     if (status === 'Add') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           document: event.value,
  //         },
  //       ]
  //     } else if (status === 'Edit') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           job_profile: dataEdit.id,
  //           document: event.value,
  //         },
  //       ]
  //     }
  //     setDigitalDocDropdown(add)
  //     setIdDigitalDoc(addFrom)
  //     setAllData({
  //       ...allDatas,
  //       dataDropdownDigitalDoc: add,
  //       IdDigitalDoc: addFrom,
  //     })
  //   } else {
  //     const update = [...data]
  //     update[id].label = event.label
  //     update[id].value = event.value

  //     const updateForm = [...dataForm]
  //     updateForm[id].document = event.value

  //     setDigitalDocDropdown(update)
  //     setIdDigitalDoc(updateForm)
  //   }
  // }

  // const addDataFacility = (event, id) => {
  //   const data = facilityDropdown
  //   const dataForm = idFacility
  //   if (data.length === id) {
  //     const add = [
  //       ...data,
  //       {
  //         label: event.label,
  //         value: event.value,
  //       },
  //     ]
  //     let addFrom = []
  //     if (status === 'Add') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           facility: event.value,
  //         },
  //       ]
  //     } else if (status === 'Edit') {
  //       addFrom = [
  //         ...dataForm,
  //         {
  //           job_profile: dataEdit.id,
  //           facility: event.value,
  //         },
  //       ]
  //     }
  //     setFacilityDropdown(add)
  //     setIdFacility(addFrom)
  //     setAllData({
  //       ...allDatas,
  //       dataDropdownFacility: add,
  //       IdFacility: addFrom,
  //     })
  //   } else {
  //     const update = [...data]
  //     update[id].label = event.label
  //     update[id].value = event.value

  //     const updateForm = [...dataForm]
  //     updateForm[id].facility = event.value

  //     setFacilityDropdown(update)
  //     setIdFacility(updateForm)
  //   }
  // }

  const addDataAccessRight = (event, id) => {
    const data = accessRightDropdown
    const dataForm = idAccessRight
    // const addFrom = getRoleFromValueToNumber(dataId)
    if (data.length === id) {
      const add = [
        ...data,
        {
          label: event.label,
          value: event.value,
        },
      ]
      setAccessRightDropdown(add)
      idAccessRight.push(event.value)
      setIdAccessRight(idAccessRight)
      const addFrom = getRoleFromValueToNumber(idAccessRight)
      // setdataIdAccessRight(addFrom)
      setAllData({
        ...allDatas,
        dataDropdownAccessRight: add,
        IdAccessRight: addFrom,
      })
    } else {
      const update = [...data]
      update[id].label = event.label
      update[id].value = event.value

      const updateForm = [...dataForm]
      updateForm[id] = event.value

      setAccessRightDropdown(update)
      setIdAccessRight(updateForm)
      const addFrom = getRoleFromValueToNumber(updateForm)

      setAllData({
        ...allDatas,
        dataDropdownAccessRight: update,
        IdAccessRight: addFrom,
      })
    }
  }

  const listKpiDropdown = index => {
    const htmlSelectKpi = []
    for (let count = 0; index >= count; count++) {
      htmlSelectKpi.push(
        <FlexStart key={count}>
          <Select
            placeholder="Pilih KPI"
            className={classNames(classes.width80P, classes.marginTop10)}
            id="competency"
            options={kpis}
            value={kpiDropdown[count] ? kpiDropdown[count] : ''}
            isLoading={kpiLoad}
            onInputChange={dataSearchDropdown =>
              setSearchDropdown(dataSearchDropdown)
            }
            onChange={event => addDataKpi(event, count)}
            styles={{
              menu: provided => ({...provided, zIndex: 9999}),
            }}
          />
          {index === 0 ? (
            ''
          ) : (
            <LitleRedText
              onClick={() => delTextFieldKpi(count)}
              className={classes.marginLeft10}
            >
              Hapus
            </LitleRedText>
          )}
        </FlexStart>
      )
    }
    return htmlSelectKpi
  }

  // const listWSDropdown = index => {
  //   const htmlSelectWS = []
  //   for (let count = 0; index >= count; count++) {
  //     htmlSelectWS.push(
  //       <FlexStart key={count}>
  //         <Select
  //           className={classNames(classes.width80P, classes.marginTop10)}
  //           id="workSchedule"
  //           options={workSch}
  //           value={
  //             workScheduleDropdown[count] ? workScheduleDropdown[count] : ''
  //           }
  //           onChange={event => addDataWorkSchedule(event, count)}
  //           styles={{
  //             menu: provided => ({...provided, zIndex: 9999}),
  //           }}
  //         />
  //         {index === 0 ? (
  //           ''
  //         ) : (
  //           <LitleRedText
  //             onClick={() => delTextFieldWS(count)}
  //             className={classes.marginLeft10}
  //           >
  //             Remove
  //           </LitleRedText>
  //         )}
  //       </FlexStart>
  //     )
  //   }
  //   return htmlSelectWS
  // }

  // const listLPDropdown = index => {
  //   const htmlSelectLP = []
  //   for (let count = 0; index >= count; count++) {
  //     htmlSelectLP.push(
  //       <FlexStart key={count}>
  //         <Select
  //           className={classNames(classes.width80P, classes.marginTop10)}
  //           id="workSchedule"
  //           options={leavePolicies}
  //           value={leavePolicyDropdown[count] ? leavePolicyDropdown[count] : ''}
  //           onChange={event => addDataLeavePolicy(event, count)}
  //           styles={{
  //             menu: provided => ({...provided, zIndex: 9999}),
  //           }}
  //         />
  //         {index === 0 ? (
  //           ''
  //         ) : (
  //           <LitleRedText
  //             onClick={() => delTextFieldLP(count)}
  //             className={classes.marginLeft10}
  //           >
  //             Remove
  //           </LitleRedText>
  //         )}
  //       </FlexStart>
  //     )
  //   }
  //   return htmlSelectLP
  // }

  // const listTPDropdown = index => {
  //   const htmlSelectTP = []
  //   for (let count = 0; index >= count; count++) {
  //     htmlSelectTP.push(
  //       <FlexStart key={count}>
  //         <Select
  //           className={classNames(classes.width80P, classes.marginTop10)}
  //           id="workSchedule"
  //           options={timePolicies}
  //           value={timePolicyDropdown[count] ? timePolicyDropdown[count] : ''}
  //           onChange={event => addDataTimePolicy(event, count)}
  //           styles={{
  //             menu: provided => ({...provided, zIndex: 9999}),
  //           }}
  //         />
  //         {index === 0 ? (
  //           ''
  //         ) : (
  //           <LitleRedText
  //             onClick={() => delTextFieldTP(count)}
  //             className={classes.marginLeft10}
  //           >
  //             Remove
  //           </LitleRedText>
  //         )}
  //       </FlexStart>
  //     )
  //   }
  //   return htmlSelectTP
  // }

  // const listAPDropdown = index => {
  //   const htmlSelectAP = []
  //   for (let count = 0; index >= count; count++) {
  //     htmlSelectAP.push(
  //       <FlexStart key={count}>
  //         <Select
  //           className={classNames(classes.width80P, classes.marginTop10)}
  //           id="allowancePolicy"
  //           options={allowancePolicies}
  //           value={
  //             allowancePolicyDropdown[count]
  //               ? allowancePolicyDropdown[count]
  //               : ''
  //           }
  //           onChange={event => addDataAllowancePolicy(event, count)}
  //           styles={{
  //             menu: provided => ({...provided, zIndex: 9999}),
  //           }}
  //         />
  //         {index === 0 ? (
  //           ''
  //         ) : (
  //           <LitleRedText
  //             onClick={() => delTextFieldAP(count)}
  //             className={classes.marginLeft10}
  //           >
  //             Remove
  //           </LitleRedText>
  //         )}
  //       </FlexStart>
  //     )
  //   }
  //   return htmlSelectAP
  // }

  // const listLoPDropdown = index => {
  //   const htmlSelectLP = []
  //   for (let count = 0; index >= count; count++) {
  //     htmlSelectLP.push(
  //       <FlexStart key={count}>
  //         <Select
  //           className={classNames(classes.width80P, classes.marginTop10)}
  //           id="LoanPolicy"
  //           options={loanPolicies}
  //           value={loanPolicyDropdown[count] ? loanPolicyDropdown[count] : ''}
  //           onChange={event => addDataLoanPolicy(event, count)}
  //           styles={{
  //             menu: provided => ({...provided, zIndex: 9999}),
  //           }}
  //         />
  //         {index === 0 ? (
  //           ''
  //         ) : (
  //           <LitleRedText
  //             onClick={() => delTextFieldLoP(count)}
  //             className={classes.marginLeft10}
  //           >
  //             Remove
  //           </LitleRedText>
  //         )}
  //       </FlexStart>
  //     )
  //   }
  //   return htmlSelectLP
  // }

  // const listCAPDropdown = index => {
  //   const htmlSelectCAP = []
  //   for (let count = 0; index >= count; count++) {
  //     htmlSelectCAP.push(
  //       <FlexStart key={count}>
  //         <Select
  //           className={classNames(classes.width80P, classes.marginTop10)}
  //           id="CAPolicy"
  //           options={cashAdvPolicies}
  //           value={
  //             cashAdvPolicyDropdown[count] ? cashAdvPolicyDropdown[count] : ''
  //           }
  //           onChange={event => addDataCashAdvPolicy(event, count)}
  //           styles={{
  //             menu: provided => ({...provided, zIndex: 9999}),
  //           }}
  //         />
  //         {index === 0 ? (
  //           ''
  //         ) : (
  //           <LitleRedText
  //             onClick={() => delTextFieldCAP(count)}
  //             className={classes.marginLeft10}
  //           >
  //             Remove
  //           </LitleRedText>
  //         )}
  //       </FlexStart>
  //     )
  //   }
  //   return htmlSelectCAP
  // }

  // const listDDDropdown = index => {
  //   const htmlSelectDD = []
  //   for (let count = 0; index >= count; count++) {
  //     htmlSelectDD.push(
  //       <FlexStart key={count}>
  //         <Select
  //           className={classNames(classes.width80P, classes.marginTop10)}
  //           id="CAPolicy"
  //           options={digitalDocument}
  //           value={digitalDocDropdown[count] ? digitalDocDropdown[count] : ''}
  //           onChange={event => addDataDigitalDocument(event, count)}
  //           styles={{
  //             menu: provided => ({...provided, zIndex: 9999}),
  //           }}
  //         />
  //         {index === 0 ? (
  //           ''
  //         ) : (
  //           <LitleRedText
  //             onClick={() => delTextFieldDD(count)}
  //             className={classes.marginLeft10}
  //           >
  //             Remove
  //           </LitleRedText>
  //         )}
  //       </FlexStart>
  //     )
  //   }
  //   return htmlSelectDD
  // }

  // const listFacilityDropdown = index => {
  //   const htmlSelectFacility = []
  //   for (let count = 0; index >= count; count++) {
  //     htmlSelectFacility.push(
  //       <FlexStart key={count}>
  //         <Select
  //           className={classNames(classes.width80P, classes.marginTop10)}
  //           id="CAPolicy"
  //           options={facilities}
  //           value={facilityDropdown[count] ? facilityDropdown[count] : ''}
  //           onChange={event => addDataFacility(event, count)}
  //           styles={{
  //             menu: provided => ({...provided, zIndex: 9999}),
  //           }}
  //         />
  //         {index === 0 ? (
  //           ''
  //         ) : (
  //           <LitleRedText
  //             onClick={() => delTextFieldFacility(count)}
  //             className={classes.marginLeft10}
  //           >
  //             Remove
  //           </LitleRedText>
  //         )}
  //       </FlexStart>
  //     )
  //   }
  //   return htmlSelectFacility
  // }

  const listAccessRightDropdown = index => {
    const htmlSelectAccessRight = []
    for (let count = 0; index >= count; count++) {
      htmlSelectAccessRight.push(
        <FlexStart key={count}>
          <Select
            className={classNames(classes.width80P, classes.marginTop10)}
            id="CAPolicy"
            placeholder="Pilih Role"
            options={accessRight}
            value={accessRightDropdown[count] ? accessRightDropdown[count] : ''}
            onChange={event => addDataAccessRight(event, count)}
            styles={{
              menu: provided => ({...provided, zIndex: 9999}),
            }}
          />
          {index === 0 ? (
            ''
          ) : (
            <LitleRedText
              onClick={() => delTextFieldAccessRight(count)}
              className={classes.marginLeft10}
            >
              Hapus
            </LitleRedText>
          )}
        </FlexStart>
      )
    }
    return htmlSelectAccessRight
  }

  const addTextFieldKpi = () => {
    const length = countKpi
    setCountKpi(length + 1)
  }

  const delTextFieldKpi = id => {
    const data = kpiDropdown
    const dataForm = idKpi
    const length = countKpi
    setCountKpi(length - 1)
    data.splice(id, 1)
    dataForm.splice(id, 1)
    setIdKpi(dataForm)
    setKpiDropdown(data)
  }

  // const addTextFieldWS = () => {
  //   const length = countWorkSchedule
  //   setCountWorkSchedule(length + 1)
  // }

  // const delTextFieldWS = id => {
  //   const data = workScheduleDropdown
  //   const dataForm = idWorkSchedule
  //   const length = countWorkSchedule
  //   setCountWorkSchedule(length - 1)
  //   data.splice(id, 1)
  //   dataForm.splice(id, 1)
  //   setIdWorkSchedule(dataForm)
  //   setWorkScheduleDropdown(data)
  // }

  // const addTextFieldLP = () => {
  //   const length = countLeavePolicy
  //   setCountLeavePolicy(length + 1)
  // }

  // const delTextFieldLP = id => {
  //   const data = leavePolicyDropdown
  //   const dataForm = idLeavePolicy
  //   const length = countLeavePolicy
  //   setCountLeavePolicy(length - 1)
  //   data.splice(id, 1)
  //   dataForm.splice(id, 1)
  //   setLeavePolicyDropdown(data)
  //   setIdLeavePolicy(dataForm)
  // }

  // const addTextFieldTP = () => {
  //   const length = countTimePolicy
  //   setCountTimePolicy(length + 1)
  // }

  // const delTextFieldTP = id => {
  //   const data = timePolicyDropdown
  //   const dataForm = idTimePolicy
  //   const length = countTimePolicy
  //   setCountTimePolicy(length - 1)
  //   data.splice(id, 1)
  //   dataForm.splice(id, 1)
  //   setTimePolicyDropdown(data)
  //   setIdTimePolicy(dataForm)
  // }

  // const addTextFieldAP = () => {
  //   const length = countAllowancePolicy
  //   setCountAllowancePolicy(length + 1)
  // }

  // const delTextFieldAP = id => {
  //   const data = allowancePolicyDropdown
  //   const dataForm = idAllowancePolicy
  //   const length = countAllowancePolicy
  //   setCountAllowancePolicy(length - 1)
  //   data.splice(id, 1)
  //   dataForm.splice(id, 1)
  //   setAllowancePolicyDropdown(data)
  //   setIdAllowancePolicy(dataForm)
  // }

  // const addTextFieldLoP = () => {
  //   const length = countLoanPolicy
  //   setCountLoanPolicy(length + 1)
  // }

  // const delTextFieldLoP = id => {
  //   const data = loanPolicyDropdown
  //   const dataForm = idLoanPolicy
  //   const length = countLoanPolicy
  //   setCountLoanPolicy(length - 1)
  //   data.splice(id, 1)
  //   dataForm.splice(id, 1)
  //   setLoanPolicyDropdown(data)
  //   setIdLoanPolicy(dataForm)
  // }

  // const addTextFieldCAP = () => {
  //   const length = countCashAdvPolicy
  //   setCountCashAdvPolicy(length + 1)
  // }

  // const delTextFieldCAP = id => {
  //   const data = cashAdvPolicyDropdown
  //   const dataForm = idCashAdvPolicy
  //   const length = countCashAdvPolicy
  //   setCountCashAdvPolicy(length - 1)
  //   data.splice(id, 1)
  //   dataForm.splice(id, 1)
  //   setCashAdvPolicyDropdown(data)
  //   setIdCashAdvPolicy(dataForm)
  // }

  // const addTextFieldDD = () => {
  //   const length = countDigitalDoc
  //   setCountDigitalDoc(length + 1)
  // }

  // const delTextFieldDD = id => {
  //   const data = digitalDocDropdown
  //   const dataForm = idDigitalDoc
  //   const length = countDigitalDoc
  //   setCountDigitalDoc(length - 1)
  //   data.splice(id, 1)
  //   dataForm.splice(id, 1)
  //   setDigitalDocDropdown(data)
  //   setIdDigitalDoc(dataForm)
  // }

  // const addTextFieldFacility = () => {
  //   const length = countFacility
  //   setCountFacility(length + 1)
  // }

  // const delTextFieldFacility = id => {
  //   const data = facilityDropdown
  //   const dataForm = idFacility
  //   const length = countFacility
  //   setCountFacility(length - 1)
  //   data.splice(id, 1)
  //   dataForm.splice(id, 1)
  //   setFacilityDropdown(data)
  //   setIdFacility(dataForm)
  // }

  const addTextFieldAccessRight = () => {
    const length = countAccessRight
    setCountAccessRight(length + 1)
  }

  const delTextFieldAccessRight = id => {
    const findStaff = idAccessRight.find(v => {
      return v === 6
    })

    const length = countAccessRight
    setCountAccessRight(length - 1)

    accessRightDropdown.splice(id, 1)
    idAccessRight.splice(findStaff ? id + 1 : id, 1)

    setAccessRightDropdown(accessRightDropdown)
    setIdAccessRight(idAccessRight)
    const addFrom = getRoleFromValueToNumber(idAccessRight)
    setAllData({
      ...allDatas,
      IdAccessRight: addFrom,
    })

    // setdataIdAccessRight(getRoleFromValueToNumber(idAccessRight))
  }

  return (
    <OrganizationContainer>
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Perjanjian Kerja</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {listKpiDropdown(countKpi)}
            <LitleLightBlueText
              onClick={addTextFieldKpi}
              className={classes.marginTop10}
            >
              Tambah
            </LitleLightBlueText>
            {/* <TextHelper className={classes.margin0}>
              {allDataForm.kpi.length === 0 && isError
                ? 'This field is required'
                : ''}
            </TextHelper> */}
          </FormChildInput>
        </FormChildContainer>
        {/* <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Work Schedule *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {listWSDropdown(countWorkSchedule)}
            <LitleLightBlueText
              onClick={addTextFieldWS}
              className={classes.marginTop10}
            >
              Add More
            </LitleLightBlueText>
            <TextHelper className={classes.margin0}>
              {allDataForm.work_schedule.length === 0 && isError
                ? 'This field is required'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Leave Policy *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {listLPDropdown(countLeavePolicy)}
            <LitleLightBlueText
              onClick={addTextFieldLP}
              className={classes.marginTop10}
            >
              Add More
            </LitleLightBlueText>
            <TextHelper className={classes.margin0}>
              {allDataForm.leave.length === 0 && isError
                ? 'This field is required'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Time Policy *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {listTPDropdown(countTimePolicy)}
            <LitleLightBlueText
              onClick={addTextFieldTP}
              className={classes.marginTop10}
            >
              Add More
            </LitleLightBlueText>
            <TextHelper className={classes.margin0}>
              {allDataForm.overtime.length === 0 && isError
                ? 'This field is required'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Allowance Policy *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {listAPDropdown(countAllowancePolicy)}
            <LitleLightBlueText
              onClick={addTextFieldAP}
              className={classes.marginTop10}
            >
              Add More
            </LitleLightBlueText>
            <TextHelper className={classes.margin0}>
              {allDataForm.allowance.length === 0 && isError
                ? 'This field is required'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>6.</TitleNumber>
            <TitleName>Loan Policy *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {listLoPDropdown(countLoanPolicy)}
            <LitleLightBlueText
              onClick={addTextFieldLoP}
              className={classes.marginTop10}
            >
              Add More
            </LitleLightBlueText>
            <TextHelper className={classes.margin0}>
              {allDataForm.loan.length === 0 && isError
                ? 'This field is required'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>7.</TitleNumber>
            <TitleName>Cash Advance Policy *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {listCAPDropdown(countCashAdvPolicy)}
            <LitleLightBlueText
              onClick={addTextFieldCAP}
              className={classes.marginTop10}
            >
              Add More
            </LitleLightBlueText>
            <TextHelper className={classes.margin0}>
              {allDataForm.cash_adv.length === 0 && isError
                ? 'This field is required'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>8.</TitleNumber>
            <TitleName>Digital Document *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {listDDDropdown(countDigitalDoc)}
            <LitleLightBlueText
              onClick={addTextFieldDD}
              className={classes.marginTop10}
            >
              Add More
            </LitleLightBlueText>
            <TextHelper className={classes.margin0}>
              {allDataForm.documents.length === 0 && isError
                ? 'This field is required'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>9.</TitleNumber>
            <TitleName>Facility</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {listFacilityDropdown(countFacility)}
            <LitleLightBlueText
              onClick={addTextFieldFacility}
              className={classes.marginTop10}
            >
              Add More
            </LitleLightBlueText>
          </FormChildInput>
        </FormChildContainer> */}
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Role *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              className={classNames(classes.width80P, classes.marginTop10)}
              id="CAPolicy"
              // options={[
              //   {
              //     value: 6,
              //     label: 'organization-staff',
              //   },
              // ]}
              isDisabled
              value={[
                {
                  value: 6,
                  label: 'organization-staff',
                },
              ]}
              // onChange={event => addDataAccessRight(event, count)}
              styles={{
                menu: provided => ({...provided, zIndex: 9999}),
              }}
            />
            {listAccessRightDropdown(countAccessRight)}
            <LitleLightBlueText
              onClick={addTextFieldAccessRight}
              className={classes.marginTop10}
            >
              Tambah
            </LitleLightBlueText>
            <TextHelper className={classes.margin0}>
              {allDataForm.roles.length === 0 && isError
                ? 'Bagian ini harus diisi'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
    </OrganizationContainer>
  )
}

export default withRouter(TabWorkPolicies)
