import gql from 'graphql-tag'

export const GET_KPI_PERSPECTIVE = gql`
  query getKpiPerspective($search: String) {
    kpi_perspectives(
      where: {
        _or: [
          {code: {_ilike: $search}}
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      code
      name
      description
      status
    }
  }
`
export const GET_KPI_PERSPECTIVE_EDIT = id => {
  return gql`
    {
      kpi_perspectives (where:{id:{_eq:${id}}}){
        id
        name
        description
        status
      }
    }
  `
}

export const CHECK_CODE_PERSPECTIVE = gql`
  query checkCodePerspective($company: uuid, $code: String) {
    kpi_perspectives_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const CHECK_PERSPECTIVE_ALREADY_TAKEN = gql`
  query checkDeletePerspective($prespective: [Int!]!) {
    kpi_lists_aggregate(where: {prespective: {_in: $prespective}}) {
      aggregate {
        count
      }
    }
  }
`
