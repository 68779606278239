import React, {useEffect, useState} from 'react'
import {useQuery} from '@apollo/react-hooks'

import {
  withStyles,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  TablePagination,
} from '@material-ui/core'
// import classNames from 'classnames'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import RSelect from 'react-select'

import helperStyle, {
  FormChildTitle,
  TitleName,
} from '../../../../../../GlobalStyles'
import AddBudgetModal from './AddBudgetModal'
import ListBudget from './ListBudget'

import {
  GET_LIST_DESTINATION,
  GET_LIST_COMPONENT,
  GET_CURRENCY_EXCHANGE,
} from '../../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../../utils/globals'

import {
  currencyOptions,
  formatCurrency,
  getCurrencyOptionLabel,
  getCurrencySign,
} from '../../../currencyExchanges'

const InfoTooltip = withStyles(() => ({
  tooltip: {
    padding: '12px 14px',
    backgroundColor: '#eff2f4',
    color: '#000',
    fontSize: 14,
    borderRadius: 5,
  },
  arrow: {
    color: '#eff2f4',
    backgroundColor: '#eff2f4',
  },
}))(Tooltip)

const selectInputStyles = baseStyles => ({...baseStyles, width: '50%'})

function StepThree(props) {
  const {
    budgetDataEdit,
    selectedLocations,
    locationOption,
    setBudget,
    budgetOption,
    setBudgetOption,
    isError,
    activeStep,
    setisUpdateDestination,
    budgetHistory,
    setBudgetHistory,
    dataContext,
    setDataContext,
    select,
    setSelect,
  } = props
  const classes = helperStyle()
  const [dataBudget, setDataBudget] = useState([])

  const [open, setOpen] = useState({
    open: false,
  })

  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [page, setPage] = React.useState(0)

  const {data, loading} = useQuery(GET_LIST_DESTINATION, {
    wlb_skipPatch: true,
    variables: {
      destinations: {_in: selectedLocations},
      search: `%%`,
    },
  })

  const {data: dataType} = useQuery(GET_LIST_COMPONENT, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  useEffect(() => {
    const a = []
    if (budgetOption === 2) {
      if (budgetHistory.specific.length === 0) {
        if (data) {
          data.global_destination.map(row => {
            const editData =
              budgetDataEdit !== undefined
                ? budgetDataEdit.business_trip_policy_destinations.filter(
                    res => res.global_destination?.id === row.id
                  )
                : []
            const budget = []
            if (editData.length > 0) {
              editData[0].business_trip_policy_destination_budgets.length > 0 &&
                editData[0].business_trip_policy_destination_budgets.map(
                  row1 => {
                    budget.push({
                      type: {
                        label: row1.business_trip_component.name,
                        value: row1.business_trip_component.id,
                        description: row1.business_trip_component.description,
                      },
                      nominal: row1.nominal,
                      budget_calculation: row.budget_calculation,
                      edit: false,
                      slice: true,
                    })
                  }
                )
            }
            a.push({
              city: row.id,
              name: row.name,
              budget: [],
            })
          })
        }
        setDataBudget(a)
      } else {
        setDataBudget(budgetHistory.specific)
      }
    } else {
      const budget = []
      if (budgetDataEdit && budgetOption !== 1) {
        budgetDataEdit.business_trip_policy_destinations.length > 0 &&
          budgetDataEdit.business_trip_policy_destinations[0].business_trip_policy_destination_budgets.map(
            row => {
              budget.push({
                type: {
                  label: row.business_trip_component.name,
                  value: row.business_trip_component.id,
                  description: row.business_trip_component.description,
                },
                nominal: row.nominal,
                budget_calculation: row.budget_calculation,
                edit: false,
                slice: true,
              })
            }
          )
      }
      setDataBudget(
        budgetHistory.general.length === 0
          ? [
              {
                city: null,
                name: null,
                budget: budget,
              },
            ]
          : budgetHistory.general
      )
    }
  }, [data, budgetDataEdit, budgetOption])

  useEffect(() => {
    const b = []
    if (dataBudget.length > 0) {
      if (locationOption === 2 && budgetOption === 1) {
        const budget = []
        dataBudget?.[0]?.budget?.length > 0 &&
          dataBudget[0].budget.map(res => {
            budget.push({
              type: res.type.value,
              nominal: parseInt(res.nominal),
              budget_calculation: res.budget_calculation,
            })
          })
        selectedLocations.length > 0 &&
          selectedLocations.map(row => {
            b.push({
              city: row,
              budget: budget,
            })
          })
      } else {
        dataBudget.map(row => {
          const budget = []
          row?.budget?.length > 0 &&
            row.budget.map(res => {
              budget.push({
                type: res.type.value,
                nominal: parseInt(res.nominal),
                budget_calculation: res.budget_calculation,
              })
            })
          b.push({
            city: row.city,
            budget: budget,
          })
        })
      }

      const history = budgetHistory
      if (budgetOption === 1) {
        history.general = dataBudget
      } else {
        history.specific = dataBudget
      }
      setBudgetHistory(history)
      setBudget(b)
    }
  }, [dataBudget, activeStep])

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // const [value, setValues] = useState({})

  // const MenuList = props => {
  //   return (
  //     <components.MenuList {...props}>{props.children}</components.MenuList>
  //   )
  // }
  // const customStyles = {
  //   control: base => ({
  //     ...base,
  //     height: 50,
  //     minHeight: 50,
  //     borderRadius: 5,
  //     width: '338px',
  //   }),
  //   singleValue: css => ({
  //     ...css,
  //     minWidth: '100%',
  //   }),
  // }

  // const label = option => {
  //   return (
  //     <div className={classNames(classes.rselect2)}>
  //       <div
  //         style={{
  //           whiteSpace: 'nowrap',
  //           overflow: 'hidden',
  //           textOverflow: 'ellipsis',
  //           color: '#000',
  //           fontWeight: 'bold',
  //           margin: 0,
  //         }}
  //       >
  //         {option.label}
  //       </div>
  //       <div
  //         style={{
  //           whiteSpace: 'nowrap',
  //           overflow: 'hidden',
  //           textOverflow: 'ellipsis',
  //         }}
  //       >
  //         {option.description}
  //       </div>
  //       {/* <span className={classes.width50P}>{option.label}</span> */}
  //     </div>
  //   )
  // }

  // const handleChange = (event, set) => {
  //   if (set === 'select') {
  //     setValues({
  //       ...value,
  //       select: event,
  //     })
  //   }
  // }

  return (
    <>
      <TitleName gutterBottom>
        Budget Component Option
        {budgetOption === 1 && isError ? (
          <Typography
            style={{marginLeft: 21}}
            variant="body2"
            color="error"
            display="inline"
          >
            This field is required
          </Typography>
        ) : (
          ''
        )}
      </TitleName>
      <FormControl component="fieldset">
        <RadioGroup
          key={1}
          aria-label="option"
          name="option1"
          value={true}
          style={{display: 'flex', flexDirection: 'row'}}
        >
          <FormControlLabel
            control={
              <Radio
                onChange={() => {
                  setBudgetOption(1)
                  setisUpdateDestination(true)
                }}
                checked={budgetOption === 1}
              />
            }
            label="General"
          />
          <Tooltip
            title={
              locationOption === 1
                ? "You can't choose budget component option, because the destination location is flexible"
                : ''
            }
            placement="right-start"
          >
            <FormControlLabel
              control={
                <Radio
                  disabled={locationOption === 1}
                  onChange={() => {
                    setBudgetOption(2)
                    setisUpdateDestination(true)
                  }}
                  checked={budgetOption === 2}
                />
              }
              label="Specific"
            />
          </Tooltip>
        </RadioGroup>
        {isError && locationOption === 1 && budgetOption === 2 && (
          <Typography
            style={{marginBottom: 12}}
            variant="body2"
            color="error"
            display="inline"
          >
            Budget Component Option can not be Specific
          </Typography>
        )}
        <Typography variant="body2" color="textSecondary">
          {budgetOption === 2
            ? 'The budget component will be set differently for each destination'
            : budgetOption === 1
            ? 'The budget component will be the same in all the destination'
            : ''}
        </Typography>
      </FormControl>

      <TitleName gutterBottom style={{marginTop: 24}}>
        Budget Calculation
      </TitleName>
      <TitleName gutterBottom style={{marginTop: 8, fontWeight: 'normal'}}>
        Currency*
      </TitleName>

      <RSelect
        placeholder="Choose Currency"
        options={currencyOptions}
        value={select.currency}
        onChange={ev => {
          setDataContext(dataContext => ({
            ...dataContext,
            currency_to: ev.value,
          }))
          setSelect(select => ({...select, currency: ev}))
        }}
        getOptionLabel={getCurrencyOptionLabel}
        styles={{
          container: selectInputStyles,
        }}
      />

      {dataContext.currency_to !== 'idr' && (
        <ExchangeDisplay from={dataContext.currency_to} to={'idr'} />
      )}

      <FormChildTitle style={{marginTop: 24}}>
        <TitleName>Budget Component</TitleName>
        <InfoTooltip
          // arrow
          style={{paddingLeft: '5px'}}
          title={
            <Typography className={classes.textTooltip}>
              Budget will be used daily for the whole trip and used in the
              destination
            </Typography>
          }
          placement="right"
        >
          <ErrorOutlineOutlinedIcon
            className={classes.iconInfo}
            color="primary"
          />
        </InfoTooltip>
      </FormChildTitle>
      {budgetOption === 1 && (
        <TitleName
          style={{paddingTop: 12, color: '#039be5', cursor: 'pointer'}}
          onClick={() => {
            setOpen({
              ...open,
              open: true,
              type: 'add',
              values: {
                select: undefined,
                checkbox: 1,
                nominal: null,
                budget_calculation: 'multiply_by_days',
              },
            })
          }}
        >
          Add New Component
        </TitleName>
      )}

      {!loading &&
        dataBudget
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => (
            <ListBudget
              key={index}
              dataType={dataType}
              currency={dataContext.currency_to}
              setDataBudget={setDataBudget}
              dataBudget={dataBudget}
              data={row}
              isStrictLocation={locationOption === 2}
              setisUpdateDestination={setisUpdateDestination}
              setOpen={setOpen}
              budgetType={budgetOption === 1 ? 'General' : 'spesific'}
            />
          ))}

      {dataBudget.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          count={
            budgetOption === 2
              ? dataBudget?.length
              : dataBudget?.[0]?.budget?.length || 0
          }
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <AddBudgetModal
        open={open.open}
        handleClose={() =>
          setOpen({
            ...open,
            open: false,
          })
        }
        options={dataType}
        values={open?.values}
        setDataBudget={setDataBudget}
        index={open?.index}
        budgetType={budgetOption === 1 ? 'General' : 'spesific'}
        data={open?.data}
        dataBudget={dataBudget}
        type={open?.type}
        currency={dataContext.currency_to}
        setisUpdateDestination={setisUpdateDestination}
      />
    </>
  )
}

export default StepThree

function ExchangeDisplay({from, to = 'idr'}) {
  const {data} = useQuery(GET_CURRENCY_EXCHANGE, {
    wlb_skipPatch: true,
    skip: from === to,
    fetchPolicy: 'cache-and-network',
    variables: {
      currency: from,
      date: '',
    },
  })

  const formattedFrom = React.useMemo(() => {
    return formatCurrency(from, 1)
  }, [from])

  const formattedTo = React.useMemo(() => {
    return data
      ? formatCurrency(to, data.getCurrencyExchange.idr)
      : `${getCurrencySign(to)} ⋯`
  }, [to, data])

  return (
    <Typography variant="body2" color="textSecondary" style={{marginTop: 4}}>
      <span>{formattedFrom}</span>
      <span> = </span>
      <span>{formattedTo}</span>
    </Typography>
  )
}
