import gql from 'graphql-tag'

export const DELETE_LEAVE_POLICY_PARENT = gql`
  mutation DeleteLeavePolicyParent($id: Int!, $deleteAt: timestamp) {
    update_time_leave_policies(
      _set: {deletedAt: $deleteAt}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
