import gql from 'graphql-tag'

export const GET_LIST_LEAVE_POLICY = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: time_leave_policy_children_aggregate(
      where: {name: {_ilike: $search}}
    ) {
      aggregate {
        count
      }
    }

    time_leave_policy_children(
      where: {name: {_ilike: $search}}
      offset: $offset
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`
