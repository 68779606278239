import React from 'react'

import {IconButton} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import {ItemWrapper} from '../../../DetailEmployeeStyles'

import {capitalize} from '../../../../../../../utils/helpers'

const row = (header, content) => (
  <tr>
    <th scope="row">{header}</th>
    <td>{content || '-'}</td>
  </tr>
)

const EmergencyContactItem = props => {
  const {data, onEdit} = props

  return (
    <ItemWrapper>
      <h1 className="title">{data.name}</h1>

      <table className="content">
        {row('Relationship', capitalize(data.relationship))}
        {row('Phone Number', data.country_code + data.phone)}
        {row('Address', data.address)}
      </table>

      <IconButton onClick={onEdit} className="edit">
        <EditIcon />
      </IconButton>
    </ItemWrapper>
  )
}

export default EmergencyContactItem
