import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {Avatar, Typography, List, Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

export const BlackLinkBold = styled(Link)`
  color: #007fb2;
  text-decoration: none;
`

export const TextGrey = styled(Typography)`
  color: #a9a8a8;
  font-size: 12px;
`

export const ClassroomThumbnail = styled(Avatar)`
  margin-right: 15px;
  margin-top: 5px;
`

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font};
  justify-content: space-between;
`

export const FlexStart = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font};
  justify-content: flex-start;
`

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ListStyle = styled(List)`
  height: 426px;
  overflow-y: auto;
`

export const CenterWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`

export const ItemCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-content: center;
`

export const TextGreySemiBold = styled(Typography)`
  font-size: 0.875rem;
  font-family: ${props => props.theme.font};
  font-weight: 400;
  color: #a9a8a8;
`

export const TextBlack = styled(Typography)`
  font-size: 0.875rem;
  font-family: ${props => props.theme.font};
  font-weight: 400;
  color: #000000;
`

export const TextBlackBold = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`

export const TextContentBold = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
`

export const TextContentRed = styled.span`
  font-size: 12px;
  color: red;
`

export const TextDarkBlue = styled.span`
  font-family: ${props => props.theme.font};
  font-size: 0.9rem;
  font-weight: 600;
  color: #014a62;
`

export const TitleBlueBold = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  margin-bottom: 20px;
  color: #014a62;
`

export const BtnAddSquare = styled(Button)`
  padding: 24.6px 25.4px 25.4px 24.6px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
`

export const SelectedMentor = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 339px;
  border-radius: 5px;
  padding: 7.8px 9.9px 9.9px 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 20px;
`

const helperStyle = makeStyles(theme => ({
  cardBorder: {
    border: '2px solid rgb(169,168,168,0.2)',
  },
  width40P: {
    width: '40% !important',
  },
  width100P: {
    width: '100% !important',
  },
  avatarSize: {
    width: '182px !important',
    height: '182px !important',
  },
  errorAvatar: {
    border: '1px solid red',
    width: '182px !important',
    height: '182px !important',
  },
  mr25: {
    marginRight: '25px',
  },
  mb20: {
    marginBottom: '20px',
  },
  my15: {
    margin: '15px 0px',
  },
}))

export default helperStyle
