import React, {useState, useEffect} from 'react'
import {
  Button,
  IconButton,
  Divider,
  MenuItem,
  makeStyles,
  TextField,
} from '@material-ui/core'
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
  FormContainer,
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
  ThinTextfield,
} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import {withRouter} from 'react-router-dom'
import {ADD_COMPETENCY, UPDATE_COMPETENCY} from '../../../../graphql/mutations'
import {
  GET_COMPETENCY_CATEGORIES,
  CHECK_CODE_COMPETENCY,
} from '../../../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {COMPANY_ID} from '../../../../utils/globals'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
}))

const AddListOfCompetence = props => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const id = props?.match?.params?.id

  const [open, setOpen] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState({
    dictionary_id: '',
    name: '',
    description: '',
    type: '',
    category: 0,
  })
  const [levels, setLevels] = useState([
    {
      number: 1,
      description: '',
    },
    {
      number: 2,
      description: '',
    },
    {
      number: 3,
      description: '',
    },
    {
      number: 4,
      description: '',
    },
    {
      number: 5,
      description: '',
    },
  ])

  const [addCompetency] = useMutation(ADD_COMPETENCY)
  const [updateCompetency] = useMutation(UPDATE_COMPETENCY)

  const {
    data: categories,
    loading: loadingCategories,
    refetch: refetchCategories,
  } = useQuery(GET_COMPETENCY_CATEGORIES, {
    variables: {company: COMPANY_ID},
  })

  const {data: isCodeAvailable, refetch: refetchIsCodeAvailable} = useQuery(
    CHECK_CODE_COMPETENCY,
    {
      variables: {company: COMPANY_ID, code: value.dictionary_id},
    }
  )

  const goBack = () => {
    return props.history.goBack()
  }

  useEffect(() => {
    if (id) {
      refetchCategories()
      const {data} = props.location.state
      const _value = {
        dictionary_id: data.dictionary_id,
        name: data.name.id,
        description: data.description.text,
        type: data.type,
        category: data.category,
      }
      if (data.description.levels) {
        setLevels(data.description.levels)
      }
      setValue(_value)
    }
  }, [])

  const handleConfirm = type => {
    setOpen(true)
    setConfirmType(type)
  }

  const handleChange = name => event => {
    const _value = {
      ...value,
      [name]: event.target.value,
    }

    setValue(_value)
    if (name === 'dictionary_id') {
      refetchIsCodeAvailable()
    }
  }

  const handleSave = () => {
    if (
      !value.dictionary_id ||
      !value.name ||
      !value.category ||
      !value.description ||
      !value.type ||
      !_isCodeAvailable
    ) {
      setError(true)
    } else {
      const variable = {
        ...value,
        name: {id: value.name, en: value.name},
        description: {text: value.description, levels},
      }

      setIsLoading(true)
      if (id) {
        updateCompetency({
          variables: {...variable, id},
        })
          .then(() => {
            goBack()
            enqueueSnackbar('Competency Updated', {
              variant: 'success',
            })
          })
          .catch(e => {
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        addCompetency({
          variables: {objects: [variable]},
        })
          .then(() => {
            goBack()
            enqueueSnackbar('Competency Submited', {
              variant: 'success',
            })
          })
          .catch(e => {
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
  }

  let _isCodeAvailable

  if (id && props.location.state?.data) {
    _isCodeAvailable =
      value.dictionary_id === props.location.state?.data?.dictionary_id
        ? true
        : isCodeAvailable?.competency_dictionaries_aggregate?.aggregate
            ?.count === 0
  } else {
    _isCodeAvailable =
      isCodeAvailable?.competency_dictionaries_aggregate?.aggregate?.count === 0
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handleConfirm('discard')}
          >
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6">
            {id ? 'Ubah' : 'Tambah'} Kompetensi
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            className={classes.backButton}
            onClick={() => handleConfirm('discard')}
          >
            Batal
          </Button>
          <Button
            onClick={() => handleConfirm('submit')}
            size="large"
            variant="contained"
            color="primary"
          >
            Kirim
          </Button>
        </div>
      </FormToolbar>
      <Divider />
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Kode Kompetensi*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              variant="outlined"
              value={value.dictionary_id}
              onChange={handleChange('dictionary_id')}
              error={(!value.dictionary_id && error) || !_isCodeAvailable}
              helperText={
                (!value.dictionary_id && error && 'bagian ini diperlukan') ||
                (!_isCodeAvailable && 'Kode Kompetensi sudah digunakan')
              }
              placeholder="Tambah Kode Kompetensi"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Nama Kompetensi*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              variant="outlined"
              value={value.name}
              onChange={handleChange('name')}
              error={!value.name && error}
              helperText={!value.name && error && 'bagian ini diperlukan'}
              placeholder="Tambah Nama Kompetensi"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Kategori*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              select
              margin="normal"
              variant="outlined"
              value={value.category}
              onChange={handleChange('category')}
              error={!value.category && error}
              helperText={!value.category && error && 'bagian ini diperlukan'}
            >
              {loadingCategories ? (
                <MenuItem value="">Loading ...</MenuItem>
              ) : (
                categories.competency_categories.map(val => {
                  return (
                    <MenuItem key={val.id} value={val.id}>
                      {val.name}
                    </MenuItem>
                  )
                })
              )}
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Keterangan*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              variant="outlined"
              value={value.description}
              onChange={handleChange('description')}
              error={!value.description && error}
              helperText={
                !value.description && error && 'bagian ini diperlukan'
              }
              placeholder="Tambah Keterangan"
              multiline
              rows={4}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Tipe*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              select
              margin="normal"
              variant="outlined"
              value={value.type}
              onChange={handleChange('type')}
              error={!value.type && error}
              helperText={!value.type && error && 'bagian ini diperlukan'}
            >
              <MenuItem value={'level'}>Level</MenuItem>
              <MenuItem value={'nonlevel'}>Non Level</MenuItem>
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>

      <AddEdit
        open={open}
        handleClose={() => setOpen(false)}
        mutation={confirmType === 'discard' ? goBack : handleSave}
        header={CONFIRMATION[confirmType]?.header}
        body={CONFIRMATION[confirmType]?.body}
        button={CONFIRMATION[confirmType]?.button}
        buttonClose="Batal"
        disabled={isLoading}
      />
    </PaperContainer>
  )
}

export default withRouter(AddListOfCompetence)

const CONFIRMATION = {
  submit: {
    header: 'Kirim Kompetensi?',
    body: 'Apakah Anda yakin akan mengirimkan Kompetensi ini?',
    button: 'Kirim',
  },
  discard: {
    header: 'Buang Perubahan?',
    body: 'Apakah Anda yakin akan membuang perubahan yang belum disimpan?',
    button: 'Buang',
  },
}
