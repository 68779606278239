import React from 'react'
import {Wrapper, LeftWrapper, RightWrapper} from './ErrorStyles'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'

const Error500Component = () => {
  return (
    <Wrapper>
      <LeftWrapper>
        <img src={require('../../assets/images/500.png')} />
      </LeftWrapper>
      <RightWrapper>
        <h2>Internal Service Error</h2>
        <p>
          Don&apos;t worry, It&apos;s not you - its us. The server has been
          deserted for a while. Please be patient or try again later.
        </p>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={{
            pathname: '/dashboard',
          }}
        >
          Back to Homepage
        </Button>
      </RightWrapper>
    </Wrapper>
  )
}

export default Error500Component
