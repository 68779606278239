import gql from 'graphql-tag'

export const GET_LIST_ORGANIZATION = gql`
  query getListOrg(
    $company: uuid
    $offset: Int
    $limit: Int
    $search: String
    $exclude_orgs: [Int!]
  ) {
    total: company_organizations_aggregate(
      where: {
        company: {_eq: $company}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
        deletedAt: {_is_null: true}
      }
    ) {
      aggregate {
        count
      }
    }
    company_organizations(
      where: {
        id: {_nin: $exclude_orgs}
        company: {_eq: $company}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
        deletedAt: {_is_null: true}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
      as_assistant
      line_type
      organization_parent {
        id
        code
        name
      }
      organization_level {
        id
        name
        code
      }
      acting_as {
        id
        name
      }
      company_job_profiles(limit: 5) {
        id
        title
      }
      company_job_profiles_aggregate {
        aggregate {
          count
        }
      }
    }
    parent_job_profile: company_job_profiles(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      title
      company_organization {
        id
        name
      }
    }
    parent_organization: company_organizations(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      name
    }
  }
`

export const GET_DATA_NEWPARENT_ORG = gql`
  query($company: uuid, $organization: Int) {
    company_organizations(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      name
    }
    company_job_profiles(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      title
    }
    set_new_parent: company_job_profiles(
      where: {
        organization: {_eq: $organization}
        _or: [
          {parent: {_is_null: true}}
          {job_profile_supervisor: {organization: {_neq: $organization}}}
        ]
      }
      limit: 1
    ) {
      id
      code
      title
    }
  }
`

export const GET_DATA_NEWPARENT_POSITION = gql`
  query($company: uuid) {
    company_organizations(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      name
    }
    company_job_profiles(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      title
    }
    #dropdown: company_organizations(where: {company: {_eq: $company}}) {
    #  id
    #  code
    #  name
    #}
  }
`

export const CHECK_PARENT_POSITION = gql`
  query($company: uuid) {
    company_job_profiles(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      title
    }
  }
`
export const DROPDOWN_LEVEL = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    total: company_organization_levels_aggregate(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
              {status: {_ilike: $search}}
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    company_organization_levels(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
              {status: {_ilike: $search}}
            ]
            status: {_ilike: "Active"}
          }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
      description
      status
      company_profile {
        id
        legal_name
        brand_name
      }
      company_organizations {
        id
        name
      }
      company_organizations_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const CHECK_CODE_ORGANIZATION = gql`
  query($company: uuid, $code: String) {
    company_organizations_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LIST_ORG_LEVEL_TEMPLATE_SHEET = gql`
  query($company: uuid!) {
    company_organization_levels(
      where: {
        company: {_eq: $company}
        status: {_eq: "Active"}
        deletedAt: {_is_null: true}
      }
      order_by: [{code: asc_nulls_last}]
    ) {
      id
      code
      name
    }
  }
`

export const GET_ORGUNIT_CHILD_COUNT = gql`
  query($organizationId: Int!) {
    organization: company_organizations_by_pk(id: $organizationId) {
      id
      name
      suborganizations: organization_children_aggregate(
        where: {as_assistant: {_eq: false}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`
