import React from 'react'
import {makeStyles, TextField, Typography} from '@material-ui/core'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {
  FormChildContainerFull,
  FormChildInput,
  FormChildInputPad15,
  FormChildTitle,
  StyledReactSelect,
  TitleName,
  TitleNumber,
} from '../../../../../../GlobalStyles'
import {DatePickerStyled} from '../../../../../directory/employee/EmployeePageStyles'
import DateFnsUtils from '@date-io/date-fns'
import {GET_LIST_LEAVE_COMPANY_HOLIDAY} from '../../../../../../graphql/queries'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../../../utils/globals'

const useStyles = makeStyles(() => ({
  wrap: {
    padding: '0 1.6rem',
  },
  recurringInfo: {
    fontSize: 12,
    margin: '0 0 12px',
    color: '#ffa000',
    fontStyle: 'italic',
  },
  rootRecurring: {
    marginRight: '-6px',
  },
  labelRecurring: {
    fontSize: 12,
  },
  wrapDate: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 337,
  },
  chooseDate: {
    maxWidth: 223,
    fontSize: 12,
    marginBottom: 12,
  },
  spaceInput: {marginTop: 12},
  textError: {margin: '4px 14px 0'},
}))

function FormCompanyHoliday(props) {
  const client = useApolloClient()
  const classes = useStyles()
  const {handleChangeInput, value, error, setValue, dataEdit} = props

  useQuery(GET_LIST_LEAVE_COMPANY_HOLIDAY, {
    wlb_skipPatch: true,
    skip: value.leave_policy || !dataEdit?.leave_policy_id,
    variables: {
      where: {
        deletedAt: {_is_null: true},
        time_leave_policy: {company: {_eq: COMPANY_ID}},
        id: {_eq: dataEdit?.leave_policy_id},
      },
      limit: 1,
    },
    onCompleted: data => {
      const {id, name} = data?.time_leave_policy_children?.[0] || {}
      const leave_policy = id
        ? {
            value: id,
            label: name,
          }
        : undefined
      setValue(prev => ({...prev, leave_policy}))
    },
  })

  const handleChange = leave_policy => {
    setValue(prev => ({...prev, leave_policy}))
  }

  const loadOptions = async searchValue => {
    const {data} = await client.query({
      query: GET_LIST_LEAVE_COMPANY_HOLIDAY,
      variables: {
        where: {
          deletedAt: {_is_null: true},
          time_leave_policy: {company: {_eq: COMPANY_ID}},
          name: {_ilike: `%${searchValue}%`},
        },
        limit: 20,
      },
    })
    return data?.time_leave_policy_children?.map(({id, name}) => ({
      value: id,
      label: name,
    }))
  }

  return (
    <>
      <div className={classes.wrap}>
        <FormChildContainerFull style={{width: '100% !important'}}>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Date</TitleName>
          </FormChildTitle>
          <FormChildInputPad15>
            <TitleName>Choose date *</TitleName>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className={classes.wrapDate}>
                <DatePickerStyled
                  fullWidth
                  inputVariant="outlined"
                  format="MMMM dd, yyyy"
                  margin="normal"
                  placeholder="Select Date"
                  size="small"
                  className={classes.chooseDate}
                  value={value.date}
                  error={error && !value.date}
                  onChange={e => handleChangeInput(e, 'date', true)}
                  helperText={
                    error && !value.date ? 'This field is required' : null
                  }
                />
              </div>
            </MuiPickersUtilsProvider>
          </FormChildInputPad15>
        </FormChildContainerFull>
      </div>
      <div className={classes.wrap}>
        <FormChildContainerFull>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Description *</TitleName>
          </FormChildTitle>
          <FormChildInput className={classes.spaceInput}>
            <TextField
              rows="10"
              cols="65"
              multiline
              size="small"
              margin="normal"
              variant="outlined"
              fullWidth
              placeholder="Add event description"
              value={value.description}
              error={error && !value.description}
              onChange={e => handleChangeInput(e, 'description')}
              helperText={
                error && !value.description && 'This field is required'
              }
            />
          </FormChildInput>
        </FormChildContainerFull>
      </div>
      <div className={classes.wrap}>
        <FormChildContainerFull>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Leave Quota *</TitleName>
          </FormChildTitle>
          <FormChildInput className={classes.spaceInput}>
            <StyledReactSelect
              isAsync
              isSearchable
              defaultOptions
              filterOption={false}
              value={value.leave_policy}
              menuPosition="fixed"
              placeholder="Choose Leave Quota"
              onChange={handleChange}
              loadOptions={loadOptions}
              style={selectStyle(!value.leave_policy && error)}
              disabled={!!dataEdit}
            />
            <Typography
              variant="caption"
              color="error"
              className={classes.textError}
            >
              {!value.leave_policy && error && 'This field is required'}
            </Typography>
          </FormChildInput>
        </FormChildContainerFull>
      </div>
    </>
  )
}

export default FormCompanyHoliday

export const selectStyle = isError => ({
  container: base => ({...base, marginTop: 10}),
  control: base => ({...base, ...(isError && {borderColor: '#f44336'})}),
})
