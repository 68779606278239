import gql from 'graphql-tag'

export const GET_COMPETENCY = gql`
  query($company: uuid, $search: String, $offset: Int, $limit: Int) {
    total: competency_version_dictionaries_aggregate(
      where: {
        competency_version: {status: {_eq: "ACTIVE"}, company: {_eq: $company}}
        competency_dictionary: {dictionary_id: {_ilike: $search}}
      }
    ) {
      aggregate {
        count
      }
    }

    competency_version_dictionaries(
      where: {
        competency_version: {status: {_eq: "ACTIVE"}, company: {_eq: $company}}
        competency_dictionary: {dictionary_id: {_ilike: $search}}
      }
      order_by: [{competency_dictionary: {dictionary_id: asc}}]
      offset: $offset
      limit: $limit
    ) {
      id
      competency_dictionary {
        id
        dictionary_id
        name
      }
    }
  }
`
