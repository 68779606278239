import ProfilePageComponent from './ProfilePageComponent'

export const ProfilePageConfig = {
  routes: [
    {
      path: '/group/profile',
      component: ProfilePageComponent,
      state: 'group-profile',
      exact: true,
    },
    {
      path: '/group/profile/edit-intro',
      component: ProfilePageComponent,
      state: 'edit-intro-group',
      exact: true,
    },
  ],
}
