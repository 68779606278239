import gql from 'graphql-tag'

export const UPDATE_SCHEDULE_SETTINGS = gql`
  mutation(
    $pay_date: Int
    $cut_off_date: Int
    $leave_unpaid_eval: Int
    $leave_unpaid_formula: Int
    $leave_unpaid_days: Int
    $employee_irregular_start_salary: Int
    $first_approval_type: Int
    $first_approval_job_profile: Int
    $second_approval_job_profile: Int
    $third_approval_job_profile: Int
  ) {
    insert_finance_payroll_settings_one(
      object: {
        pay_date: $pay_date
        cut_off_date: $cut_off_date
        leave_unpaid_eval: $leave_unpaid_eval
        leave_unpaid_formula: $leave_unpaid_formula
        leave_unpaid_days: $leave_unpaid_days
        employee_irregular_start_salary: $employee_irregular_start_salary
        employee_irregular_start_days: $leave_unpaid_days
        employee_irregular_start_eval: $leave_unpaid_eval
        first_approval_type: $first_approval_type
        first_approval_job_profile: $first_approval_job_profile
        second_approval_job_profile: $second_approval_job_profile
        third_approval_job_profile: $third_approval_job_profile
      }
      on_conflict: {
        constraint: company_payroll_settings_pk
        update_columns: [
          pay_date
          cut_off_date
          leave_unpaid_eval
          leave_unpaid_formula
          leave_unpaid_days
          employee_irregular_start_salary
          first_approval_type
          first_approval_job_profile
          second_approval_job_profile
          third_approval_job_profile
        ]
      }
    ) {
      id
      pay_date
      cut_off_date
      leave_unpaid_eval
      leave_unpaid_formula
      leave_unpaid_days
      employee_irregular_start_salary
      first_approval_type
      first_approval_job_profile
      second_approval_job_profile
      third_approval_job_profile
    }
  }
`

export const ADD_EXCLUSION_EMPLOYEE = gql`
  mutation($objects: [finance_exclude_payroll_insert_input!]!) {
    insert_finance_exclude_payroll(
      on_conflict: {
        constraint: finance_exclude_payroll_user_company_deletedAt_key
        update_columns: [user, company, deletedAt]
      }
      objects: $objects
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const DELETE_EXCLUSION_EMPLOYEE = gql`
  mutation($id: Int!) {
    update_finance_exclude_payroll(
      _set: {deletedAt: "now()"}
      where: {_and: [{id: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      affected_rows
    }
  }
`
