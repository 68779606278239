import React from 'react'
import {IconButton, Link} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import FormatAlignLeft from '@material-ui/icons/FormatAlignLeft'
import LocationOnIcon from '@material-ui/icons/LocationOn'

import {ItemWrapper} from '../../../DetailEmployeeStyles'
import {dateFormat} from '../../../../../../../utils/helpers'

import {AttachmentCard} from '../../../../../../../components/attachment'

const AssessmentItem = props => {
  const {data, onEdit, onDetails} = props

  return (
    <ItemWrapper>
      <h1 className="title">{data.name}</h1>

      <h2 className="subtitle">{dateFormat(data.test_date, 'll')}</h2>

      <table className="content" style={{'--header-width': '1%'}}>
        <tbody>
          <tr>
            <th scope="row">
              <LocationOnIcon color="primary" />
            </th>
            <td className="highlight-text">{data.institution || '-'}</td>
          </tr>
          <tr>
            <th scope="row">
              <FormatAlignLeft color="primary" />
            </th>
            <td>{data.subject || '-'}</td>
          </tr>
        </tbody>
      </table>

      {data.disease && (
        <p className="text primary">{data.test_result || '-'}</p>
      )}

      {data.consequence && (
        <p className="text">{data.general_conclusion || '-'}</p>
      )}

      <div className="attachments row">
        {data.attachments?.slice(0, 3)?.map((atch, i) => (
          <AttachmentCard
            key={`${i}-atch-assessment`}
            name={atch.name || '-'}
            url={atch.url || atch.link}
          />
        ))}
      </div>

      <Link
        color="secondary"
        component="button"
        onClick={() => onDetails(data)}
        style={{marginTop: 16}}
      >
        Tampilkan Detail Asesmen
      </Link>

      <IconButton onClick={() => onEdit(data)} className="edit">
        <EditIcon />
      </IconButton>
    </ItemWrapper>
  )
}

export default AssessmentItem
