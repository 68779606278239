import React from 'react'
import {
  LabelContainer,
  LabelWrapper,
  LabelSymbol,
  LabelCaptionColumn,
  LabelCaptionWrapper,
} from './LabelStyle'

const LabelColumn = props => {
  const showPercentageForGenderChart = gender => {
    const {data} = props
    if (gender === 'Male') {
      return Math.round(
        (data.datasets[0].data[0] /
          data.datasets[0].data.reduce((a, b) => a + b, 0)) *
          100
      ).toString()
    } else {
      return Math.round(
        (data.datasets[0].data[1] /
          data.datasets[0].data.reduce((a, b) => a + b, 0)) *
          100
      ).toString()
    }
  }

  const {labels} = props.data
  const {backgroundColor} = props.data.datasets[0]

  return (
    <LabelContainer column>
      {labels.map((label, idx) => {
        return (
          <LabelWrapper key={idx} column>
            <LabelSymbol color={backgroundColor[idx]} />
            <LabelCaptionWrapper>
              <LabelCaptionColumn>{label}</LabelCaptionColumn>
              <LabelCaptionColumn
                className="value-label"
                color={backgroundColor[idx]}
              >
                {`${showPercentageForGenderChart(label)}%`}
              </LabelCaptionColumn>
            </LabelCaptionWrapper>
          </LabelWrapper>
        )
      })}
    </LabelContainer>
  )
}

export default LabelColumn
