import React from 'react'
import {
  QuestionStyled,
  ActionButtonGreen,
  ActionButtonRed,
  InputWrapper,
  TextFieldWrapper,
} from '../../SurveyCenterStyled'
import {InputBase} from '@material-ui/core'
import MultiraterIconPrimary from '../../../../../../assets/images/multirater-icon-primary.png'
import Nps from '../../../../../../assets/images/nps.png'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'

export default function FieldInput({
  header,
  value,
  onChange,
  error,
  helperText,
  onClickCancel,
  onClickSubmit,
}) {
  return (
    <QuestionStyled>
      <InputWrapper>
        {header === 'multirater' ? (
          <img
            style={{width: 'auto', height: '100%', margin: 'auto 0px'}}
            src={MultiraterIconPrimary}
            alt="multi-icon"
          />
        ) : (
          <img
            style={{width: 'auto', height: '100%', margin: 'auto 0px'}}
            src={Nps}
            alt="multi-icon"
          />
        )}
        <TextFieldWrapper>
          <InputBase
            style={{width: '100%'}}
            placeholder="Masukkan Judul"
            value={value}
            onChange={onChange}
            variant="outlined"
            error={error}
            helperText={helperText}
          />
        </TextFieldWrapper>
        <ActionButtonGreen variant="contained" onClick={onClickSubmit}>
          <DoneIcon style={{color: '#FFF'}} />
        </ActionButtonGreen>
        <ActionButtonRed onClick={onClickCancel}>
          <ClearIcon style={{color: '#FFF'}} />
        </ActionButtonRed>
      </InputWrapper>
    </QuestionStyled>
  )
}
