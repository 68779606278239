import gql from 'graphql-tag'
export const GET_ORGANIZATION_FILTERS = gql`
  query {
    total: company_organizations_aggregate {
      aggregate {
        count
      }
    }

    company_organizations(offset: 0, limit: null, order_by: [{name: asc}]) {
      id
      name
      parent
      count_employee
      company_job_profiles_aggregate {
        aggregate {
          count
        }
      }
      organization_parent {
        id
        name
      }
    }
  }
`
export const GET_OFFICE_FILTERS = gql`
  query($company: uuid) {
    company_addresses(
      where: {company: {_eq: $company}}
      order_by: [{office_name: asc}]
    ) {
      id
      office_name
    }
  }
`

export const GET_POSITION_FILTERS = gql`
  query {
    total: company_employee_positions_aggregate {
      aggregate {
        count
      }
    }

    company_employee_positions(
      offset: 0
      limit: null
      order_by: [{name: asc}]
    ) {
      id
      name
      count_employee
    }
  }
`

export const GET_POSITION_TALENT_FILTERS = gql`
  query getTalentClassification($version: uuid, $search: String, $limit: Int) {
    talent_candidate_groups(
      where: {
        version: {_eq: $version}
        company_job_profile: {
          _or: [{title: {_ilike: $search}}, {code: {_ilike: $search}}]
          people_work_placements: {
            talent_assignments: {
              talent_version: {_eq: $version}
              badge_classification: {_is_null: false}
            }
          }
        }
      }
      limit: $limit
    ) {
      id
      company_job_profile {
        id
        code
        title
      }
    }
  }
`
