import gql from 'graphql-tag'

export const GET_DROPDOWN_CHART = gql`
  query getList($company: uuid) {
    list_axis_x: holding_clusters(
      where: {company: {_eq: $company}}
      order_by: {id: asc}
    ) {
      id
      name
    }
    list_axis_y: holding_classes(
      where: {company: {_eq: $company}}
      order_by: {id: asc}
    ) {
      id
      name
    }
    list_axis_z: holding_categories(
      where: {company: {_eq: $company}}
      order_by: {id: asc}
    ) {
      id
      name
    }
  }
`

export const GET_HOLDING_CHART = gql`
  query(
    $company_holding_id: uuid!
    $search_x: [Int!]
    $search_y: [Int!]
    $search_z: [Int!]
  ) {
    list_axis_x: holding_clusters(
      where: {
        company: {_eq: $company_holding_id}
        id: {_in: $search_x}
        holding_version: {status: {_eq: "ACTIVE"}}
      }
      order_by: {name: asc_nulls_last}
    ) {
      id
      name
    }

    list_axis_y: holding_classes(
      where: {
        company: {_eq: $company_holding_id}
        id: {_in: $search_y}
        holding_version: {status: {_eq: "ACTIVE"}}
      }
      order_by: {name: asc_nulls_last}
    ) {
      id
      name
    }

    holding_axis_z: holding_categories(
      where: {
        company: {_eq: $company_holding_id}
        id: {_in: $search_z}
        holding_version: {status: {_eq: "ACTIVE"}}
      }
      order_by: {name: asc_nulls_last}
    ) {
      id
      name
    }

    v_holding_chart(
      where: {
        company_head: {_eq: $company_holding_id}
        member_cluster: {_in: $search_x}
        member_class: {_in: $search_y}
        member_category: {_in: $search_z}
      }
    ) {
      company_head_profile {
        id
        legal_name
      }
      axis_x: holding_cluster {
        id
        name
      }
      axis_y: holding_class {
        id
        name
      }
      axis_z: holding_category {
        id
        name
      }
      count
    }
  }
`

export const GET_DETAIL_HOLDING_CHART = gql`
  query(
    $company: uuid
    $search: String
    $search_x: Int
    $search_y: Int
    $search_z: Int
  ) {
    clasification: holding_relations(
      where: {
        company_head: {_eq: $company}
        status: {_like: "ACTIVE"}
        holding_cluster: {id: {_eq: $search_x}}
        holding_class: {id: {_eq: $search_y}}
        holding_category: {id: {_eq: $search_z}}
      }
    ) {
      x: holding_cluster {
        id
        name
      }
      y: holding_class {
        id
        name
      }
      z: holding_category {
        id
        name
      }
    }

    data: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company_profile: {
          holdingRelationsByCompanyMember: {
            company_head: {_eq: $company}
            status: {_like: "ACTIVE"}
            holding_cluster: {id: {_eq: $search_x}}
            holding_class: {id: {_eq: $search_y}}
            holding_category: {id: {_eq: $search_z}}
          }
        }
        holding_sources: {
          company_head: {_eq: $company}
          status: {_eq: "VERIFIED"}
        }
        _or: [
          {email: {_ilike: $search}}
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      regno
      email
      holding_sources {
        id
      }
      global_user {
        id
        name
        avatar
      }
      company_profile {
        id
        legal_name
        brand_name
      }
      company_job_profile {
        id
        title
      }
    }

    total: people_work_placements_aggregate(
      where: {
        status: {_eq: "ACTIVE"}
        company_profile: {
          holdingRelationsByCompanyMember: {
            company_head: {_eq: $company}
            status: {_like: "ACTIVE"}
            holding_cluster: {id: {_eq: $search_x}}
            holding_class: {id: {_eq: $search_y}}
            holding_category: {id: {_eq: $search_z}}
          }
        }
        holding_sources: {
          company_head: {_eq: $company}
          status: {_eq: "VERIFIED"}
        }
        _or: [
          {email: {_ilike: $search}}
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
