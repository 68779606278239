import gql from 'graphql-tag'

export const GET_SCORING_NATURE = gql`
  subscription getScoringNature {
    global_scoring_natures(order_by: [{id: asc}]) {
      id
      name
    }
  }
`
