import React from 'react'
import {
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
} from '../../../../GlobalStyles'
import {TextField} from '@material-ui/core'
import {WrapperDisable} from '../EmployeePageStyles'

export default function ComponentInput(props) {
  const {
    number,
    name,
    error,
    id,
    textInfo,
    styleWrapper,
    disabled = false,
    ...other
  } = props
  const length = number.split('').length
  return (
    <FormChildContainer id={id}>
      <FormChildTitle>
        <TitleNumber style={length === 2 ? {paddingRight: 10} : null}>
          {number}.
        </TitleNumber>
        <TitleName>{name}</TitleName>
      </FormChildTitle>
      <FormChildInput>
        <WrapperDisable style={styleWrapper}>
          <TextField
            fullWidth
            size="small"
            disabled={disabled}
            margin="normal"
            variant="outlined"
            error={error}
            helperText={error ? 'Bagian ini diperlukan' : null}
            {...other}
          />
          {textInfo && (
            <p
              style={{
                margin: '0 18px',
                color: '#a9a8a8',
                fontSize: 12,
              }}
            >
              {textInfo}
            </p>
          )}
        </WrapperDisable>
      </FormChildInput>
    </FormChildContainer>
  )
}
