import React, {useState} from 'react'
import helperStyle, {
  BigTextBlackBold,
  AntTabs,
  StyledTab,
} from '../PayrollPageStyles'
import {PaperContainer, FormToolbar} from '../../../../GlobalStyles'
import {Typography, Button, Box} from '@material-ui/core'
import ModalConfirmation from './ModalConfirmation'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

const PayrollSettings = props => {
  const {
    children,
    setActiveComponent,
    activeComponent = 0,
    onConfirm,
    isDisabledButton,
    onCancel,
    hasChange = true,
    secondaryButton,
    generalSettingData,
  } = props
  const classes = helperStyle()

  const [isOpen, setIsOpen] = useState(false)
  const [type, setType] = useState('')
  const isDisabled = generalSettingData === null

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setActiveComponent(newValue)
  }

  const onCancelButton = () => {
    setType('cancel')
    setIsOpen(true)
  }

  const onSave = () => {
    setType('confirmation')
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
    setType('')
  }

  return (
    <>
      <ModalConfirmation
        open={isOpen}
        onClose={onClose}
        type={type}
        onConfirm={onConfirm}
        onCancel={onCancel}
        isUpdate={true}
      />
      <PaperContainer>
        <FormToolbar>
          <BigTextBlackBold>Payroll Settings</BigTextBlackBold>
          {hasChange && (
            <div>
              <Button
                size="large"
                className={classes.backButton}
                onClick={onCancelButton}
                disabled={isDisabledButton}
              >
                {secondaryButton || 'Cancel'}
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={onSave}
                disabled={isDisabledButton}
              >
                Save
              </Button>
            </div>
          )}
        </FormToolbar>
        <AntTabs
          className={classes.tabs}
          indicatorColor="primary"
          onChange={handleChange}
          textColor="primary"
          value={activeComponent}
          variant="scrollable"
        >
          <StyledTab label="General" />
          <StyledTab label="Component" disabled={isDisabled} />
          <StyledTab label="THR" disabled={isDisabled} />
          <StyledTab label="BPJS Kesehatan" disabled={isDisabled} />
          <StyledTab label="BPJS TK" disabled={isDisabled} />
          {/* Hotfix: temporary hide */}
          {/* <StyledTab label="Premium" disabled={isDisabled} /> */}
          <StyledTab label="Tax" disabled={isDisabled} />
        </AntTabs>
        <TabPanel value={activeComponent} index={activeComponent}>
          {children}
        </TabPanel>
      </PaperContainer>
    </>
  )
}

export default PayrollSettings
