import gql from 'graphql-tag'

export const GET_COMPANY_DASHBOARD = gql`
  query($company: uuid) {
    getLocation: v_address_employee_count(where: {company: {_eq: $company}}) {
      city
      count
    }
    getEducation: v_education_count(where: {company: {_eq: $company}}) {
      name
      count
    }
    getDepartement: v_organizations_count(where: {company: {_eq: $company}}) {
      id
      name
      count
    }
    getDesignation: v_job_profile_count(where: {company: {_eq: $company}}) {
      id
      title
      count
    }
  }
`

export const GET_LOCATION_AND_EDUCATION_DASHBOARD = gql`
  query getLocationandEducation($company: uuid, $limit: Int) {
    total_Location: v_address_employee_count_aggregate(
      where: {company: {_eq: $company}}
    ) {
      aggregate {
        count
        sum {
          count
        }
      }
    }

    getLocation: v_address_employee_count(
      where: {company: {_eq: $company}}
      limit: $limit
    ) {
      label: city
      count
    }

    total_Education: v_education_count_aggregate(
      where: {company: {_eq: $company}}
    ) {
      aggregate {
        count
        sum {
          count
        }
      }
    }

    getEducation: v_education_count(
      where: {company: {_eq: $company}}
      limit: $limit
    ) {
      label: name
      count
    }
  }
`

export const GET_ORGANIZATION_DROPDOWN = gql`
  query getOrganizations(
    $company: uuid!
    $search: String
    $limit: Int
    $offset: Int
  ) {
    total: company_organizations_aggregate(
      where: {
        _and: [
          {company: {_eq: $company}}
          {deletedAt: {_is_null: true}}
          {_or: [{name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [{name: asc}]
    ) {
      aggregate {
        count
      }
    }
    org_dropdown: company_organizations(
      where: {
        _and: [
          {company: {_eq: $company}}
          {deletedAt: {_is_null: true}}
          {_or: [{name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`
