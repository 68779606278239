import {useMutation} from '@apollo/react-hooks'
import {UPDATE_STATUS_PENDING_ACTIVATION} from '../../../../graphql/mutations'

export const useActivateEmployee = enqueueSnackbar => {
  const [updateStatus] = useMutation(UPDATE_STATUS_PENDING_ACTIVATION)

  const onUpdate = ({id, user, onSucess, withSnackbar = true}) => {
    updateStatus({
      variables: {
        id,
        user,
      },
    })
      .then(() => {
        onSucess()
        if (withSnackbar) {
          enqueueSnackbar('Aktivasi Pegawai Berhasil', {
            variant: 'success',
            autoHideDuration: 3000,
          })
        }
      })
      .catch(() => {
        enqueueSnackbar('Aktivasi Pegawai Gagal, silakan coba lagi nanti', {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  return [onUpdate]
}
