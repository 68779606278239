import gql from 'graphql-tag'

export const GET_SOURCE_HOLDING_TALENT = gql`
  query getHoldingSource($company: uuid, $status: String, $search: String) {
    holding_source(
      where: {
        company_head: {_eq: $company}
        status: {_ilike: $status}
        _or: [
          {people_work_placement: {email: {_ilike: $search}}}
          {people_work_placement: {regno: {_ilike: $search}}}
          {people_work_placement: {global_user: {name: {_ilike: $search}}}}
          {
            people_work_placement: {
              company_profile: {legal_name: {_ilike: $search}}
            }
          }
          {
            people_work_placement: {
              company_profile: {brand_name: {_ilike: $search}}
            }
          }
          {
            people_work_placement: {
              company_job_profile: {title: {_ilike: $search}}
            }
          }
        ]
      }
    ) {
      id
      status
      people_work_placement {
        id
        regno
        email
        global_user {
          id
          name
          avatar
        }
        company_profile {
          id
          legal_name
          brand_name
        }
        company_job_profile {
          id
          title
        }
      }
    }
  }
`
