import React, {useState} from 'react'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {useQuery} from '@apollo/react-hooks'
import {LIST_RECOMMENDATION_SENT} from '../../../../../../graphql/queries'
import TabRecommendation from './TabRecommendation'

export default function Recommendation({recommendation_to, user, user_name}) {
  const [open, setOpen] = useState(false)

  const {data: recommendation_from} = useQuery(LIST_RECOMMENDATION_SENT, {
    wlb_skipPatch: true,
    variables: {
      user,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  return (
    <>
      <TabRecommendation
        setOpen={setOpen}
        recommendation_from={
          (recommendation_from &&
            recommendation_from.people_profile_recommendations) ||
          []
        }
        recommendation_to={recommendation_to}
      />
      <ModalDetail
        title={`${user_name} Rujukan`}
        onClose={() => setOpen(false)}
        open={open}
      >
        <TabRecommendation
          isModal
          setOpen={setOpen}
          recommendation_from={
            (recommendation_from &&
              recommendation_from.people_profile_recommendations) ||
            []
          }
          recommendation_to={recommendation_to}
        />
      </ModalDetail>
    </>
  )
}
