import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'
import ListComponent from './list/ListComponent'
import ClassificationDetail from './detail/ClassificationDetailComponent'
import ClassificationAddComponent from '../../performance/classification/form/ClassificationAddComponent'
// import {capitalize} from '../../../utils/helpers'

const renderComponent = (activeComponent, searchText = '') => {
  switch (activeComponent) {
    case 'classification':
      return <ListComponent searchText={searchText} />
    case 'add-classification':
      return <ClassificationAddComponent />
    case 'edit-classification':
      return <ClassificationAddComponent />
    case 'classification-detail':
      return <ClassificationDetail />
    default:
      return <ListComponent searchText={searchText} />
  }
}

const ClassificationPage = props => {
  let active = ''
  if (props.location.state) {
    active = props.location.state.active
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <div>
      <Card icon="how_to_reg" title="Talenta" onSearch={onSearch}>
        <PageContainer>{renderComponent(active, searchText)}</PageContainer>
      </Card>
    </div>
  )
}

export default ClassificationPage
