import {
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {FlexCenters, PaperContainer} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import TabsAbout from './tabs/TabsAboutComponent'
import {useQuery} from '@apollo/react-hooks'
import {
  GET_MICRO_LEARNING_BY_ID,
  GET_QUESTIONS_BY_MICROLEARNING_ID,
  GET_RESPONSE_BY_MICROLEARNING_ID,
} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import LoadingSurvey from '../../../survey/multirater-nps/survei-center/LoadingSurvey'
import TabsQuestion from './tabs/TabsQuestionComponent'
import TabsResponse from './tabs/TabsResponseComponent'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
  root: {padding: 24},
  iconImg: {
    width: 227,
    borderRadius: 5,
    marginRight: 24,
    objectFit: 'cover',
  },
  mb12: {marginBottom: 12},
  ml12: {marginLeft: 12},
  dividers: {margin: '24px -24px !important'},
  mb8: {marginBottom: 8},
  mb16: {marginBottom: 16},
  avatar: {
    width: 42,
    height: 42,
    margin: '0 24px 0 0',
    borderRadius: 5,
    objectFit: 'cover',
  },
}))

const DetailComponent = props => {
  const classes = useStyles()
  const id = props.match.params.id
  const [tabsValue, setTabsValue] = useState(0)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  useEffect(() => {
    props.history.push({
      state: {
        searchable: tabsValue === 2,
      },
    })
  }, [tabsValue])
  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const {data, loading, error} = useQuery(GET_MICRO_LEARNING_BY_ID, {
    wlb_skipPatch: true,
    variables: {
      id,
      createdByOrgId: COMPANY_ID,
    },
  })

  const {data: dataQuest, loading: loadingQuest, error: errQuest} = useQuery(
    GET_QUESTIONS_BY_MICROLEARNING_ID,
    {
      wlb_skipPatch: true,
      skip: tabsValue !== 1,
      variables: {
        id,
      },
    }
  )

  const {
    data: dataResponse,
    loading: loadingResponse,
    error: errResponse,
  } = useQuery(GET_RESPONSE_BY_MICROLEARNING_ID, {
    variables: {
      id,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      query: `%${props.search}%`,
    },
    skip: tabsValue !== 2,
  })

  if (loading || loadingQuest) {
    return (
      <Paper style={{width: '100%', marginTop: '5rem'}}>
        <LoadingSurvey />
      </Paper>
    )
  }
  if (error || errQuest || errResponse) {
    return JSON.stringify(error || errQuest || errResponse)
  }
  return (
    <PaperContainer>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          display: 'flex',
        }}
        disableGutters
      >
        <FlexCenters>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() =>
              props.history.push({
                state: {
                  searchable: true,
                },
                pathname: '/learning/micro-learning',
              })
            }
          >
            <ArrowBack />
          </IconButton>

          <Typography variant="h6" className={classes.ml12}>
            {data.microlearnings[0].name}
          </Typography>
        </FlexCenters>
      </Toolbar>
      <Tabs
        value={tabsValue}
        onChange={handleChangeTabs}
        indicatorColor="secondary"
        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
      >
        <Tab label="Tentang" className={classes.tabRoot} />
        <Tab label="Pertanyaan" className={classes.tabRoot} />
        <Tab label="Tanggapan" className={classes.tabRoot} />
      </Tabs>
      <div className={classes.root}>
        {tabsValue === 0 && (
          <TabsAbout classes={classes} data={data.microlearnings[0]} />
        )}
        {tabsValue === 1 && (
          <TabsQuestion
            classes={classes}
            data={dataQuest.microlearnings[0].microlearning_questions}
          />
        )}
        {tabsValue === 2 && (
          <TabsResponse
            classes={classes}
            data={dataResponse}
            loading={loadingResponse}
            pagination={pagination}
            setPagination={setPagination}
            microlearningId={id}
          />
        )}
      </div>
    </PaperContainer>
  )
}
export default withRouter(DetailComponent)
