import styled from 'styled-components'

export const IndividualSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${props => props.theme.font};
  color: black;
  overflow: hidden;
  width: 100vw;
`

export const SummaryDetailContainer = styled.div`
  width: 100%;

  .toolbar-container {
    justify-content: space-between;
    display: flex;

    .toolbar-flex {
      display: flex;
      align-items: center;

      .title {
        margin-left: 16px;
        align-self: center;
      }
    }
  }

  .avatar-container {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 1.6rem;
    min-width: 400px;

    .avatar {
      border-radius: 5px 0px 0px 5px;
    }

    .avatar-section {
      padding: 16px;

      .p-name {
        font-family: Muli;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        margin: 0px !important;
      }

      .p-id {
        font-family: Muli;
        font-size: 12px;
        font-weight: 600;
        color: #014a62;
        margin-top: 5px;
        margin: 0px !important;
      }

      .p-placement {
        font-family: Muli;
        font-size: 12px;
        font-weight: 600;
        color: #a9a8a8;
      }

      .p-status {
        font-family: Muli;
        font-size: 12px;
        font-weight: 600;
        color: #4caf50;
        margin-bottom: 0px !important;
      }
    }
  }
`

export const SectionDetailContainer = styled.div`
  padding: 4px 24px;

  .space-section {
    margin-top: 26px;
  }

  .paper-padding {
    padding-bottom: 5px;

    .section-filter {
      display: flex;

      .MuiInputBase-adornedEnd {
        width: 180px;
        height: 40px;
        margin-left: 20px;

        .MuiSvgIcon-root {
          color: #014a62;
        }
      }
    }

    .divider-margin {
      margin: 15px 0px;
    }

    .p-type-badge {
      font-family: Muli;
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin: 9px 0px 0px 24px !important;
    }

    .p-see-more {
      font-family: Muli;
      font-size: 14px;
      font-weight: 600;
      color: #039be5;
      cursor: pointer;
      margin: 20px 0px 24px 24px !important;
    }

    .info-container {
      justify-content: space-between;
      display: flex;

      .badges-container {
        display: flex;
        align-items: center;
        width: 550px;
        margin: 20px 30px;

        .badges {
          border-radius: 5px 0px 0px 5px;
        }

        .badges-section {
          padding: 16px;

          .p-title-badge {
            font-family: Muli;
            font-size: 16px;
            font-weight: 600;
            color: #000;
            margin: 0px !important;
          }

          .p-performance {
            font-family: Muli;
            font-size: 12px;
            font-weight: 600;
            color: #014a62;
            margin: 5px 0px 8px 0px !important;
          }

          .p-position {
            font-family: Muli;
            font-size: 12px;
            font-weight: 600;
            color: #a9a8a8;
            margin: 8px 0px;
          }

          .p-score {
            font-family: Muli;
            font-size: 24px;
            font-weight: 600;
            color: #039be5;
            margin: 0px !important;
          }
        }
      }

      .axis-section {
        width: 300px;
        margin: 20px 30px;
        padding: 16px;

        .p-axis {
          font-family: Muli;
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          margin: 0px !important;
        }

        .axis-container {
          margin-top: 16px;

          .axis-wrapper {
            justify-content: space-between;
            display: flex;
            margin-top: 8px;

            .p-parameter {
              font-family: Muli;
              font-size: 14px;
              color: #000000;
              margin: 0px !important;
              text-transform: capitalize;
            }

            .p-value {
              font-family: Muli;
              font-size: 14px;
              font-weight: 600;
              color: #014a62;
              margin: 0px !important;
            }
          }
        }
      }

      .info-section {
        margin: 20px 30px;
        display: flex;
        flex-direction: row;
        width: 230px;

        .icon-container {
          width: 55px;
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: solid 2px #055464;

          .icon-style {
            font-size: 30px;
            color: #055464;
          }
        }

        .text-container {
          text-align: left;
          margin-left: 1.5rem;
          margin-top: 5px;

          .p-number {
            font-size: 20px;
            font-family: Muli;
            color: #055469;
            margin: 0px !important;
          }

          .p-text {
            font-family: Muli;
            font-size: 14px;
            color: #a9a8a8;
            margin-top: 8px !important;
            max-width: 160px;
          }
        }
      }
    }

    .chart-container {
      margin: 30px;
      display: flex;
      justify-content: space-between;

      .margin-chart {
        margin-left: 30px;
      }

      .wrap-chart {
        width: 100%;

        .p-chart-title {
          font-family: Muli;
          font-size: 16px;
          color: #000000;
          font-weight: 600;
          margin-top: 8px !important;
        }

        .chart-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .chart-goal {
            margin: auto;
            width: 50%;

            .inner-chart {
              width: 100% !important;
            }
          }

          .chart-task {
            width: 40%;

            .inner-chart {
              width: 100% !important;
            }
          }

          .info-chart-task {
            width: 60% !important;
          }

          .info-chart {
            width: 50%;
            margin: auto;
            padding: 0 1rem;

            .legend-container {
              display: flex;
              flex-direction: row;
              margin-bottom: 1rem;
              align-items: center;

              .circle {
                align-self: start;
                margin-top: 1px;
                border-radius: 50%;
                margin-right: 1rem;
                width: 12px;
                height: 12px;
              }

              .p-legend-status {
                font-family: Muli;
                font-size: 14px;
                color: #000000;
                margin: 0px !important;
              }

              .p-legend-value-goal {
                font-family: Muli;
                font-size: 20px;
                color: #a9a8a8;
                margin: 8px 0px !important;
              }

              .p-legend-value-task {
                font-family: Muli;
                font-size: 14px;
                color: #a9a8a8;
                margin: 0px !important;
              }

              .legend-info-containter {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
              }
            }
          }
        }

        .average-container {
          margin-top: 15px;

          .span-average {
            font-family: Muli;
            font-size: 14px;
            color: #000000;
          }

          .span-average-score {
            font-family: Muli;
            font-weight: bold;
            font-size: 14px;
            color: #014a62;
            margin-left: 5px;
          }
        }
      }

      .divider-chart {
        height: 16rem;
      }
    }
  }
`
