import gql from 'graphql-tag'

export const UPDATE_KPI_MONITORING = id => {
  return gql`
      mutation updateKPIMonitoring($data: kpi_monitoring_frequencies_set_input) {
        update_kpi_monitoring_frequencies(where: {id: {_eq: ${id}}}, _set: $data) {
          returning {
            id
          }
        }
      }
    `
}
