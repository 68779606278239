import {
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  Divider,
} from '@material-ui/core'
import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {FlexCenters, PaperContainer} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import CardBadgeIndidual from '../../../../components/card/CardBadgeIndividual'
import AddedByCompany from './tabs/AddedByCompany'
import AddedByUser from './tabs/AddedByUser'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabContainer: {
    marginTop: 15,
  },
  tabRoot: {fontSize: 14},
  root: {padding: 24},
  iconImg: {
    width: 227,
    borderRadius: 5,
    marginRight: 24,
    objectFit: 'cover',
  },
  mb12: {marginBottom: 12},
  ml12: {marginLeft: 12},
  dividers: {margin: '24px -24px !important'},
  mb8: {marginBottom: 8},
  mb16: {marginBottom: 16},
  avatar: {
    width: 42,
    height: 42,
    margin: '0 24px 0 0',
    borderRadius: 5,
    objectFit: 'cover',
  },
  mainRoot: {
    padding: 24,
  },
}))

const IndividualCompetencyDetail = props => {
  const classes = useStyles()
  const id = props.match.params.id
  const [tabsValue, setTabsValue] = useState(0)

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  return (
    <PaperContainer>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          display: 'flex',
        }}
        disableGutters
      >
        <FlexCenters>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() =>
              props.history.push({
                state: {
                  searchable: true,
                },
                pathname: '/learning/competency',
              })
            }
          >
            <ArrowBack />
          </IconButton>

          <Typography variant="h6" className={classes.ml12}>
            Rincian Kompetensi Pegawai
          </Typography>
        </FlexCenters>
      </Toolbar>
      <Divider />
      <div className={classes.mainRoot}>
        <CardBadgeIndidual placementId={id} />

        <Tabs
          value={tabsValue}
          onChange={handleChangeTabs}
          indicatorColor="secondary"
          classes={{
            indicator: classes.tabsIndicator,
            root: classes.tabContainer,
          }}
        >
          <Tab label="Ditambahkan oleh Instansi" className={classes.tabRoot} />
          <Tab label="Ditambahkan oleh Pengguna" className={classes.tabRoot} />
        </Tabs>
        <div className={classes.root}>
          {tabsValue === 0 && (
            <AddedByCompany
              placementId={id}
              history={props.history}
              jobProfileId={props.location.state.jobProfile}
            />
          )}
          {tabsValue === 1 && <AddedByUser placementId={id} />}
        </div>
      </div>
    </PaperContainer>
  )
}
export default withRouter(IndividualCompetencyDetail)
