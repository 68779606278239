import React from 'react'
import DoughnutCardWidget from '../../../../components/widgets/doughnut/DoughnutCardComponent'
import ModalDetail from '../../../shared-components/popup/ModalDetail'
import {WrapperChart} from '../../DashboardStyles'
import SkeletonChartDoughnut from '../../SkeletonChartDoughnut'

export default function MoreOrganization({
  open,
  title,
  data,
  onClose,
  loading,
}) {
  const _data = Object.assign(
    {},
    title === 'Organisasi' ? data.organization : data.position
  )
  delete _data.dataSlice
  delete _data.more

  return (
    <ModalDetail onClose={onClose} open={open} title={title} maxWidth="sm">
      {!loading && data && (
        <WrapperChart>
          <DoughnutCardWidget
            alignItems="flex-start"
            data={_data}
            labelType="list"
          />
        </WrapperChart>
      )}
      {loading && <SkeletonChartDoughnut />}
    </ModalDetail>
  )
}
