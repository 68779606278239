import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import {capitalize} from '../../../../../utils/helpers'
import {timeZones} from '../Constants'

const TabsDetailAdd = props => {
  const {
    name,
    setName,
    classes,
    isError,
    isExist,
    description,
    setDescription,
    setSelectedZone,
    selectedZone,
    isIncludePhoto,
    setIncludePhoto,
    allowedMoreOnce,
    setAllowedMoreOnce,
    falseChecker,
    scheduleChecked,
    scheduleRecess,
    schedules,
    handleCheckbox,
    isFlexi,
    initialClockIn,
    setInitialClockIn,
    initialClockOut,
    setInitialClckOut,
    lateTolerance,
    setLateTolerance,
  } = props
  return (
    <>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Name *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            placeholder="Add Work Schedule Name"
            margin="normal"
            variant="outlined"
            value={name}
            onChange={event => {
              setName(event.target.value)
            }}
            fullWidth
            InputProps={{
              classes: {
                input: classes.outlinedInput,
              },
            }}
            error={(name.length < 1 || isExist) && isError}
            helperText={
              name.length < 1 && isError
                ? 'This field is required'
                : isExist && isError
                ? 'Name is already exist'
                : ''
            }
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Description</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            placeholder="Add Work Schedule Description"
            margin="normal"
            variant="outlined"
            value={description}
            onChange={event => {
              setDescription(event.target.value)
            }}
            fullWidth
            InputProps={{
              classes: {
                multiline: classes.outlinedInput,
              },
            }}
            multiline
            rows={4}
          />
        </FormChildInput>
      </FormChildContainer>
      {/* <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Attendance Method *</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                id="attendance-method"
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                select
                fullWidth
                onChange={event => {
                  setMethod(event.target.value)
                }}
                margin="normal"
                variant="outlined"
                value={method}
              >
                {methods.map((method, key) => {
                  return (
                    <MenuItem key={key} value={method.value}>
                      {method.name}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer> */}
      <FormChildTitle>
        <TitleNumber>3.</TitleNumber>
        <TitleName>
          When doing attendance using the application, do employee have to
          include photos?
        </TitleName>
      </FormChildTitle>
      <FormControl component="fieldset" style={{padding: '20px 0 30px 20px'}}>
        <RadioGroup
          aria-label="option"
          name="option1"
          value={true}
          style={{display: 'flex', flexDirection: 'row'}}
        >
          <FormControlLabel
            value={isIncludePhoto}
            control={<Radio onChange={() => setIncludePhoto(true)} />}
            label="Yes"
          />
          <FormControlLabel
            value={!isIncludePhoto}
            control={<Radio onChange={() => setIncludePhoto(false)} />}
            label="No"
          />
        </RadioGroup>
        <Typography style={{color: '#a9a8a8'}}>
          Photo can only be seen by the staff concerned, the direct supervisor
          and HR admin.
        </Typography>
      </FormControl>
      <FormChildTitle>
        <TitleNumber>4.</TitleNumber>
        <TitleName>Allows multiple checkin with other joint policies</TitleName>
      </FormChildTitle>
      <FormControl component="fieldset" style={{padding: '20px 0 30px 20px'}}>
        <RadioGroup
          aria-label="option"
          name="option2"
          value={true}
          style={{display: 'flex', flexDirection: 'row'}}
        >
          <FormControlLabel
            value={allowedMoreOnce}
            control={<Radio onChange={() => setAllowedMoreOnce(true)} />}
            label="Yes"
          />
          <FormControlLabel
            value={!allowedMoreOnce}
            control={<Radio onChange={() => setAllowedMoreOnce(false)} />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
      {/* <Divider style={{margin: '8px -1.6rem 22px -1.6rem'}} /> */}
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>5.</TitleNumber>
          <TitleName>Timezone *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            id="select-timezone"
            InputProps={{
              classes: {
                input: classes.outlinedInput,
              },
            }}
            select
            fullWidth
            onChange={event => {
              setSelectedZone(event.target.value)
            }}
            margin="normal"
            variant="outlined"
            value={selectedZone}
            error={selectedZone === 'null' && isError}
            helperText={
              selectedZone === 'null' && isError ? 'This field is required' : ''
            }
          >
            <MenuItem value="null" disabled>
              Select Timezone
            </MenuItem>
            {timeZones.map((zone, key) => {
              return (
                <MenuItem key={key} value={`${zone.offset}~${zone.value}`}>
                  {zone.text}
                </MenuItem>
              )
            })}
          </TextField>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer style={{width: '75%'}}>
        <FormChildTitle>
          <TitleNumber>6.</TitleNumber>
          <TitleName>Time</TitleName>
        </FormChildTitle>
        <table style={{width: '100%', marginTop: 12}}>
          <tr>
            <th align="left">
              <Typography>Working Days *</Typography>
              <Typography
                variant="caption"
                style={{
                  color: '#f44336',
                  display:
                    falseChecker(scheduleChecked) && isError ? 'block' : 'none',
                }}
              >
                This field is required
              </Typography>
            </th>
            <th align="left">Work Hour</th>
            <th align="left">
              <Typography>Recess *</Typography>
              <Typography
                variant="caption"
                style={{
                  color: '#f44336',
                  display:
                    scheduleRecess.includes('') && isError ? 'block' : 'none',
                }}
              >
                This field is required
              </Typography>
            </th>
          </tr>
          {schedules.map((schedule, key) => {
            return (
              <tr key={key}>
                <td>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={schedule.checked === 1}
                        onChange={event => handleCheckbox(event, 'checkbox')}
                        value={schedule.name}
                        color="primary"
                        // disabled={isFlexi === 1}
                      />
                    }
                    label={capitalize(schedule.name)}
                  />
                </td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      // justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      value={schedule.clockIn}
                      onChange={event =>
                        handleCheckbox(event, 'clockIn', schedule.name)
                      }
                      margin="normal"
                      variant="outlined"
                      type="time"
                      InputProps={{
                        classes: {
                          input: classes.outlinedTimeInput,
                        },
                      }}
                      style={{marginRight: 5}}
                      disabled={isFlexi === 1}
                    />{' '}
                    -{' '}
                    <TextField
                      value={schedule.clockOut}
                      onChange={event =>
                        handleCheckbox(event, 'clockOut', schedule.name)
                      }
                      margin="normal"
                      variant="outlined"
                      type="time"
                      InputProps={{
                        classes: {
                          input: classes.outlinedTimeInput,
                        },
                      }}
                      style={{marginLeft: 5}}
                      disabled={isFlexi === 1}
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      // justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      value={schedule.recess}
                      onChange={event =>
                        handleCheckbox(event, 'recess', schedule.name)
                      }
                      margin="normal"
                      type="number"
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.outlinedTimeInput,
                        },
                      }}
                      style={{marginRight: 5, width: '7rem'}}
                      disabled={isFlexi === 1}
                    />
                    <Typography>Minutes</Typography>
                  </div>
                </td>
              </tr>
            )
          })}
          <tr>
            <td colSpan={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFlexi === 1}
                    onChange={event => handleCheckbox(event, 'flexi')}
                    value="flexi"
                    color="primary"
                  />
                }
                label="Flexible Time"
              />
            </td>
          </tr>
        </table>
      </FormChildContainer>
      {isFlexi !== 1 && (
        <>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>7.</TitleNumber>
              <TitleName>Initial Clock-in time *</TitleName>
            </FormChildTitle>

            <Typography className={classes.helperText}>
              Number of minutes allowed for check-in before the Clock-in time.
            </Typography>

            <FormChildInput
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <TextField
                id="clock-in"
                InputProps={{
                  classes: {
                    input: classes.outlinedTimeInput,
                  },
                }}
                style={{marginRight: 12, width: '7rem'}}
                fullWidth
                type="number"
                value={initialClockIn}
                onChange={event => {
                  if (event.target.value >= 0) {
                    setInitialClockIn(event.target.value)
                  }
                }}
                variant="outlined"
                error={initialClockIn === '' && isError}
                helperText={
                  initialClockIn === '' && isError
                    ? 'This field is required'
                    : ''
                }
              />
              <Typography>Minutes</Typography>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>8.</TitleNumber>
              <TitleName>Initial Clock-out time *</TitleName>
            </FormChildTitle>
            <Typography className={classes.helperText}>
              Number of minutes allowed for check-out before the Clock-out time.
            </Typography>
            <FormChildInput
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <TextField
                id="clock-out"
                InputProps={{
                  classes: {
                    input: classes.outlinedTimeInput,
                  },
                }}
                style={{marginRight: 12, width: '7rem'}}
                fullWidth
                type="number"
                value={initialClockOut}
                onChange={event => {
                  if (event.target.value >= 0) {
                    setInitialClckOut(event.target.value)
                  }
                }}
                variant="outlined"
                error={initialClockOut === '' && isError}
                helperText={
                  initialClockOut === '' && isError
                    ? 'This field is required'
                    : ''
                }
              />
              <Typography>Minutes</Typography>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>9.</TitleNumber>
              <TitleName>Grace period from the time of entry *</TitleName>
            </FormChildTitle>
            <FormChildInput
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <TextField
                margin="normal"
                variant="outlined"
                value={lateTolerance}
                required
                onChange={event => {
                  if (event.target.value >= 0) {
                    setLateTolerance(event.target.value)
                  }
                }}
                type="number"
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.outlinedTimeInput,
                  },
                }}
                style={{width: '7rem'}}
                error={lateTolerance === '' && isError}
                helperText={
                  lateTolerance === '' && isError
                    ? 'This field is required'
                    : ''
                }
              />
              <Typography style={{marginLeft: 11}}>Minutes</Typography>
            </FormChildInput>
          </FormChildContainer>
        </>
      )}
    </>
  )
}

export default TabsDetailAdd
