import React, {useState, useEffect} from 'react'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  WrapperContent,
} from '../../OrganizationPageStyles'
import {
  PaperContainer,
  EnhancedToolbar,
  TabsStyled,
  TabStyled,
} from '../../../../GlobalStyles'
import TabDetailJobFamily from './tabs-add-edit/TabDetailJobFamily'
import TabJobTitle from './tabs-add-edit/TabJobTitle'
import {useSnackbar} from 'notistack'
import {Icon, IconButton, Button} from '@material-ui/core'
import {withRouter, Link} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  ADD_JOB_FAMILY_NAME,
  EDIT_JOB_FAMILY_NAME,
  ADD_FILTER_JFAMILY_JOB_TITLE,
} from '../../../../graphql/mutations'
import {CHECK_CODE_JOB_FAMILY} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import classNames from 'classnames'

import AddEditConfirm from '../../../shared-components/popup/AddEditPopup'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import Loading from '../../../../components/loading/LoadingComponent'

const AddEditJobFamilyName = props => {
  const {status, data, history} = props
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const dataJFname = data
    ? {
        id: data.id,
        code: data.code,
        name: data.name,
        description: data.description,
        company: COMPANY_ID,
        jobTitle: null,
      }
    : {
        code: '',
        name: '',
        description: '',
        // company: COMPANY_ID,
      }

  const hasJobProfile =
    data &&
    data.job_functions_aggregate &&
    data.job_functions_aggregate.aggregate &&
    data.job_functions_aggregate.aggregate.count > 0

  const [allDataFrom, setAllDataFrom] = useState(dataJFname)
  const [isError, setIsError] = useState(false)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [dialogCancel, setDialogCancel] = useState(false)
  const [alertUnable, setAlertUnable] = useState(false)
  const [tabsValue, setTabsValue] = useState(0)

  const [addData] = useMutation(ADD_JOB_FAMILY_NAME)
  const [editData] = useMutation(EDIT_JOB_FAMILY_NAME)
  const [addFilterJobTitle] = useMutation(ADD_FILTER_JFAMILY_JOB_TITLE)

  useEffect(() => {
    if (status === 'Edit') {
      const idJobFamily = []
      data.company_employee_positions.map(_data => idJobFamily.push(_data.id))
      setAllDataFrom({
        ...allDataFrom,
        jobTitle: {
          include: {
            jobTitle:
              data.filter_job_title &&
              data.filter_job_title.jobTitleFilter &&
              data.filter_job_title.jobTitleFilter._in,
            jobFunction:
              data.filter_job_title &&
              data.filter_job_title.functionFilter &&
              data.filter_job_title.functionFilter._in,
          },
          exclude: {
            jobTitle:
              data.filter_job_title &&
              data.filter_job_title.jobTitleFilter &&
              data.filter_job_title.jobTitleFilter._nin,
            jobFunction:
              data.filter_job_title &&
              data.filter_job_title.functionFilter &&
              data.filter_job_title.functionFilter._nin,
          },
        },
      })
    }
  }, [status])

  const {data: checkCodeJobFamily, error: checkCodeJobFamilyError} = useQuery(
    CHECK_CODE_JOB_FAMILY,
    {
      variables: {
        company: COMPANY_ID,
        code: allDataFrom && allDataFrom.code,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  if (
    checkCodeJobFamily === undefined &&
    checkCodeJobFamilyError === undefined
  ) {
    return <Loading />
  } else if (checkCodeJobFamilyError !== undefined) {
    return <div>{checkCodeJobFamilyError}</div>
  }
  // const nameChecker =
  //   dataCheck &&
  //   dataCheck.job_families &&
  //   dataCheck.job_families.some(
  //     v => v.name.toLowerCase() === allDataFrom.name.toLowerCase()
  //   )

  const codeChecker =
    checkCodeJobFamily &&
    checkCodeJobFamily.job_families_aggregate &&
    checkCodeJobFamily.job_families_aggregate.aggregate &&
    checkCodeJobFamily.job_families_aggregate.aggregate.count > 0

  // const checkSameNameWithAdd = data && data.name === allDataFrom.name
  const checkSameCodeWithAdd =
    allDataFrom && allDataFrom.code && allDataFrom.code.length >= 1
      ? data && data.code === allDataFrom.code
      : false

  const checkCharaCode = allDataFrom && allDataFrom.code.length > 30
  const checkCharaName = allDataFrom && allDataFrom.name.length > 100

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const checkFilter =
    (allDataFrom &&
      allDataFrom.jobTitle &&
      allDataFrom.jobTitle.exclude &&
      allDataFrom.jobTitle.exclude.jobTitle &&
      allDataFrom.jobTitle.exclude.jobTitle.length > 0) ||
    (allDataFrom &&
      allDataFrom.jobTitle &&
      allDataFrom.jobTitle.include &&
      allDataFrom.jobTitle.include.jobTitle &&
      allDataFrom.jobTitle.include.jobTitle.length > 0) ||
    (allDataFrom &&
      allDataFrom.jobTitle &&
      allDataFrom.jobTitle.exclude &&
      allDataFrom.jobTitle.exclude.jobFunction &&
      allDataFrom.jobTitle.exclude.jobFunction.length > 0) ||
    (allDataFrom &&
      allDataFrom.jobTitle &&
      allDataFrom.jobTitle.include &&
      allDataFrom.jobTitle.include.jobFunction &&
      allDataFrom.jobTitle.include.jobFunction.length > 0)

  const openDialogConfirm = () => {
    if (
      !allDataFrom.code ||
      !allDataFrom.name ||
      checkCharaCode ||
      checkCharaName ||
      // (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd) ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setTabsValue(0)
      setIsError(true)
      setTimeout(() => setIsError(false), 2000)
    } else if (!checkFilter) {
      setAlertUnable(true)
      setTabsValue(1)
    } else {
      dialogConfirm ? setDialogConfirm(false) : setDialogConfirm(true)
    }
  }

  const handleAddJFName = () => {
    const _functionFilter = {
      _in:
        allDataFrom.jobTitle &&
        allDataFrom.jobTitle.include &&
        allDataFrom.jobTitle.include.jobFunction,
      _nin:
        allDataFrom.jobTitle &&
        allDataFrom.jobTitle.exclude &&
        allDataFrom.jobTitle.exclude.jobFunction,
    }
    const _jobTitleFilter = {
      _in:
        allDataFrom.jobTitle &&
        allDataFrom.jobTitle.include &&
        allDataFrom.jobTitle.include.jobTitle,
      _nin:
        allDataFrom.jobTitle &&
        allDataFrom.jobTitle.exclude &&
        allDataFrom.jobTitle.exclude.jobTitle,
    }
    if (status === 'Add') {
      const dataForAdd = {
        objects: [
          {
            code: allDataFrom.code,
            name: allDataFrom.name,
            description: allDataFrom.description,
          },
        ],
      }
      addData({variables: dataForAdd})
        .then(returnId => {
          const dataFilter = {
            company: COMPANY_ID,
            family: returnId.data.insert_job_families.returning[0].id,
            functionFilter: _functionFilter,
            jobTitleFilter: _jobTitleFilter,
            filter_job_title: {
              functionFilter: _functionFilter,
              jobTitleFilter: _jobTitleFilter,
            },
          }
          addFilterJobTitle({variables: dataFilter})
            .then(() => {
              enqueueSnackbar('Job Family Added', {
                variant: 'success',
                autoHideDuration: 1000,
              })
              history.push({
                pathname: '/organization/job-family',
                state: {active: 'job-family', searchable: true},
              })
            })
            .catch(() => {
              enqueueSnackbar(
                'Added Job Title failed, please try again later',
                {
                  variant: 'error',
                  autoHideDuration: 1000,
                }
              )
            })
        })
        .catch(() => {
          enqueueSnackbar('Added Job Family failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else if (status === 'Edit') {
      const dataForEdit = {
        id: data && data.id,
        object: {
          code: allDataFrom.code,
          name: allDataFrom.name,
          description: allDataFrom.description,
          filter_job_title: {
            functionFilter: _functionFilter,
            jobTitleFilter: _jobTitleFilter,
          },
        },
        company: COMPANY_ID,
        functionFilter: _functionFilter,
        jobTitleFilter: _jobTitleFilter,
      }
      editData({variables: dataForEdit})
        .then(() => {
          enqueueSnackbar('Job Family Edited', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          history.push({
            pathname: '/organization/job-family',
            state: {active: 'job-family', searchable: true},
          })
        })
        .catch(() => {
          enqueueSnackbar('Edit Job Family failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleAlert = status => {
    dialogCancel ? setDialogCancel(false) : setDialogCancel(true)
    if (dialogCancel && status !== 'notNow') {
      history.push({
        pathname: '/organization/job-family',
        state: {active: 'job-family', searchable: true},
      })
    }
  }

  return (
    <OrganizationContainer>
      <PaperContainer
        className={classNames(
          classes.height600px,
          classes.margin0,
          classes.notViewScroll
        )}
      >
        <EnhancedToolbar className={classes.paddingLeft0}>
          <WrapperContent>
            <IconButton
              component={Link}
              to={{
                pathname: '/organization/job-family',
                state: {active: 'job-family', searchable: true},
              }}
              edge="end"
              aria-label="back"
            >
              <Icon>arrow_back</Icon>
            </IconButton>
            <BigTextBlackBold className={classes.marginLeft10}>{`${
              status === 'Add' ? 'Tambah' : 'Ubah'
            } Rumpun Jabatan`}</BigTextBlackBold>
          </WrapperContent>
          <div>
            <Button
              size="large"
              onClick={handleAlert}
              style={{marginRight: 12}}
            >
              Batal
            </Button>
            <Button
              className={classes.marginLeft10}
              variant="contained"
              color="primary"
              size="large"
              onClick={openDialogConfirm}
            >
              Simpan
            </Button>
          </div>
        </EnhancedToolbar>
        <OrganizationContainer>
          <TabsStyled value={tabsValue} onChange={handleChange}>
            <TabStyled label="Rincian Rumpun Jabatan" />
            <TabStyled label="Jabatan" />
          </TabsStyled>
          {tabsValue === 0 && (
            <TabDetailJobFamily
              allDataFrom={allDataFrom}
              setAllDataFrom={setAllDataFrom}
              checkSameCodeWithAdd={checkSameCodeWithAdd}
              checkCharaCode={checkCharaCode}
              checkCharaName={checkCharaName}
              isError={isError}
              codeChecker={codeChecker}
            />
          )}
          {tabsValue === 1 && (
            <TabJobTitle
              allDataFrom={allDataFrom}
              setAllDataFrom={setAllDataFrom}
              status={status}
            />
          )}
        </OrganizationContainer>
      </PaperContainer>
      {hasJobProfile ? (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type="Confirm"
          applied={data && data.job_functions}
          mutation={handleAddJFName}
          appliedName="Job Function"
        />
      ) : (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type={status}
          name={allDataFrom.name}
          feature="Job Family"
          mutation={handleAddJFName}
        />
      )}
      <AlertDialog
        open={dialogCancel}
        handleClose={handleAlert}
        feature={`Batal Untuk ${
          data ? 'Mengubah' : 'Menambahkan'
        } Rumpun Jabatan?`}
        message={`Anda memiliki perubahan yang belum disimpan. Yakin ingin membatalkan ${
          data ? `perubahan ${data.name}` : 'penambahan Rumpun Jabatan baru'
        }?`}
        positiveTitleButton="Ya, Batalkan"
        type="Yes, Cancel"
        status="notNow"
      />
      <AlertDialog
        open={alertUnable}
        handleClose={() => setAlertUnable(false)}
        feature="Tidak Dapat Menyimpan"
        message="Tidak ada jabatan yang diterapkan. Untuk menyimpan detail kelompok pekerjaan, Anda harus menerapkannya ke judul pekerjaan terlebih dahulu"
        positiveTitleButton="Konfirmasi"
        type="Confirm"
      />
    </OrganizationContainer>
  )
}

export default withRouter(AddEditJobFamilyName)
