import React, {useState} from 'react'
import {
  Typography,
  Dialog,
  IconButton,
  Icon,
  Divider,
  TextField,
  InputAdornment,
  makeStyles,
  Button,
} from '@material-ui/core'
import {
  ModalContentEvent,
  WhiteModalHeader,
  BlueIcon,
  GreyTypography,
  MediumAvatar,
  FormChildButton,
} from '../../../../GlobalStyles'
import {BoldTypography} from '../../../../components/typography/TypographyStyles'
import {holdingRolesContext} from '../../../../utils/helpers'
import {GET_COMPANY_TO_INVITE} from '../../../../graphql/queries'
import Loading from '../../../../components/loading/LoadingComponent'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../utils/globals'
import {INVITE_TO_COMPANY} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'

const useStyles = makeStyles(() => ({
  mr28: {marginRight: 28},
  mt20: {marginTop: '20px !important'},
  companyWrapper: {display: 'flex', margin: '20px 0'},
  structureHeader: {textAlign: 'left', cursor: 'pointer', width: '100%'},
  marginText: {marginTop: 8, marginBottom: 30},
  buttonSpacer: {marginRight: 12},
  noVacancy: {
    paddingTop: '7rem',
    textAlign: 'center',
    color: 'rgb(0, 61, 81)',
    minHeight: '12rem',
  },
}))

const ModalInviteCompany = ({handleClose, open, refetchMain}) => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [search, setSearch] = useState('')
  const [isNext, setIsNext] = useState(false)
  const [selected, setSelected] = useState(null)
  const {data, error, refetch} = useQuery(GET_COMPANY_TO_INVITE, {
    variables: {
      search: `%${search}%`,
      company_head: COMPANY_ID,
    },
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const [inviteCompany] = useMutation(INVITE_TO_COMPANY)

  const handleClick = (dir, res) => {
    if (dir === 'next') {
      setIsNext(true)
      setSelected(res)
    } else {
      setIsNext(false)
      setSelected(null)
    }
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const content = (res, isSelected) => (
    <div className={classes.companyWrapper} key={res.id}>
      <MediumAvatar
        variant="rounded"
        src={res.logo}
        className={classes.mr28}
      ></MediumAvatar>
      <div
        className={classes.structureHeader}
        onClick={() => handleClick('next', res)}
      >
        <BoldTypography variant="body1" gutterBottom>
          {res.brand_name}
        </BoldTypography>
        <GreyTypography variant="body2" gutterBottom>
          {res.legal_name}
        </GreyTypography>

        {res.profile_field ? (
          <Typography
            variant="body2"
            component="div"
            dangerouslySetInnerHTML={{
              __html: res.profile_field.description,
            }}
          ></Typography>
        ) : (
          '-'
        )}

        {!isSelected && <Divider className={classes.mt20} />}
      </div>
    </div>
  )

  const handleMutation = () => {
    const object = {
      company_head: COMPANY_ID,
      company_member: selected.id,
    }
    inviteCompany({variables: {object}, ...holdingRolesContext})
      .then(() => {
        enqueueSnackbar('Success Update data', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
        refetchMain()
        handleClose()
        handleClick('back')
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true}>
      <WhiteModalHeader style={{justifyContent: 'normal'}}>
        {isNext ? (
          <>
            <IconButton
              aria-label="close"
              size="small"
              className={classes.buttonSpacer}
              onClick={() => handleClick('back')}
            >
              <Icon>arrow_back</Icon>
            </IconButton>
            <Typography variant="body1" color="primary">
              Invite Confirmation
            </Typography>
          </>
        ) : (
          <Typography variant="body1" color="primary">
            Invite Company
          </Typography>
        )}

        {/* <IconButton aria-label="close" onClick={handleClose}>
          <Icon>close</Icon>
        </IconButton> */}
      </WhiteModalHeader>
      <Divider />
      <ModalContentEvent>
        {isNext ? (
          <div className={classes.marginText}>
            <Typography variant="body2" display="inline">
              Are you sure to invite{' '}
            </Typography>
            <Typography variant="body2" color="secondary" display="inline">
              {selected.brand_name + ' '}
            </Typography>
            <Typography variant="body2" display="inline">
              to be a company member?
            </Typography>
          </div>
        ) : (
          <TextField
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BlueIcon>search</BlueIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            fullWidth
            size="small"
            margin="normal"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        )}
        {isNext ? (
          content(selected, true)
        ) : data.company_profiles.length > 0 ? (
          data.company_profiles.map(res => content(res, false))
        ) : (
          <div className={classes.noVacancy}>Company not found</div>
        )}
        {isNext && (
          <FormChildButton style={{paddingBottom: 0}}>
            <Button
              size="large"
              className={classes.buttonSpacer}
              onClick={() => {
                handleClose()
                handleClick('back')
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleMutation}
            >
              Invite
            </Button>
          </FormChildButton>
        )}
      </ModalContentEvent>
    </Dialog>
  )
}

export default ModalInviteCompany
