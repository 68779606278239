import {
  Dialog,
  Typography,
  IconButton,
  Icon,
  makeStyles,
  DialogContent,
} from '@material-ui/core'
import React from 'react'
import {PrimaryModalHeader, WrapperScroll} from '../../GlobalStyles'
import {Alert} from '@material-ui/lab'

const useStyles = makeStyles(() => ({
  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  alert: {
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  type: {fontSize: 10},
  reloadTxt: {fontSize: 10, cursor: 'pointer', textDecoration: 'underline'},
  message: {fontSize: 12, fontWeight: '500'},
  titleGroup: {marginBottom: 10, fontWeight: '600'},
  wrapperHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  msgAlert: {width: '100%'},
}))

const BaloonItem = ({status, type, onSeeProbem, onReload, loading}) => {
  const classes = useStyles()
  const getStatus = status => {
    switch (status) {
      case 'COMPLETED':
        return 'success'
      case 'FAILED':
        return 'error'
      case 'WAITING':
        return 'info'
      default:
        return 'info'
    }
  }
  const getMessage = (status, onSeeProbem) => {
    switch (status) {
      case 'COMPLETED':
        return 'Import data is completed!'
      case 'FAILED':
        return (
          <div>
            Import data has failed!{' '}
            <a
              onClick={onSeeProbem}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                marginLeft: 14,
              }}
            >
              See the problem
            </a>
          </div>
        )
      case 'WAITING':
        return "Import Data is still in progress. Don't worry, you can still do other activities"
      default:
        return 'info'
    }
  }
  return (
    <Alert
      color={getStatus(status)}
      classes={{
        message: classes.msgAlert,
      }}
      severity={getStatus(status)}
      className={classes.alert}
      // onClose={status === 'COMPLETED' ? onClose : null}
    >
      <div>
        <div className={classes.wrapperHeader}>
          <Typography className={classes.type}>{type}</Typography>
          {status === 'WAITING' && (
            <Typography onClick={onReload} className={classes.reloadTxt}>
              {loading ? 'Loading Data' : 'Reload Import Status'}
            </Typography>
          )}
        </div>
        <Typography className={classes.message}>
          {getMessage(status, onSeeProbem)}
        </Typography>
      </div>
    </Alert>
  )
}

const DEFAULT_VALUE_DATA = {
  import: [],
  export: [],
}

export default function RunningProcess({
  open,
  onClose,
  onCloseBaloon,
  data = DEFAULT_VALUE_DATA,
  onSeeProbem,
  onReload,
  loading,
}) {
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <PrimaryModalHeader>
        <Typography variant="h6" style={{color: '#fff'}}>
          Running Process
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon style={{color: '#fff'}}>close</Icon>
        </IconButton>
      </PrimaryModalHeader>
      <DialogContent className={classes.content}>
        <Typography className={classes.titleGroup}>Import Data</Typography>
        <WrapperScroll maxHeight="270px">
          {data.import.map((res, i) => {
            const {data, type} = res
            return (
              <BaloonItem
                key={`${i}`}
                status={data.status}
                type={type}
                onClose={() => onCloseBaloon(data.id)}
                onSeeProbem={() => onSeeProbem(res)}
                onReload={onReload}
                loading={loading}
              />
            )
          })}
        </WrapperScroll>
      </DialogContent>
    </Dialog>
  )
}
