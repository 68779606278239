import gql from 'graphql-tag'

export const GET_ATTENDANCE_REPORT = gql`
  query getAttendanceReport(
    $startDate: String
    $endDate: String
    $employee: String
    $position: Int
    $year: String
    $month: String
    $supervisor: String
    $search: String
    $jobProfile: JSON
    $exc_jobProfile: JSON
    $organization: JSON
    $exc_organization: JSON
    $level: JSON
    $exc_level: JSON
    $office: JSON
    $exc_office: JSON
    $job_grades: JSON
    $exc_job_grades: JSON
    $employeeType: JSON
    $exc_employeeType: JSON
    $timeArrangement: JSON
    $exc_timeArrangement: JSON
    $workArrangement: JSON
    $exc_workArrangement: JSON
    $organization_level: JSON
    $exc_organization_level: JSON
    $job_family: JSON
    $exc_job_family: JSON
    $gender: JSON
    $exc_gender: JSON
    $isFilterBirth: Boolean
    $birthFrom: String
    $birthTo: String
    $isFilterExcBirth: Boolean
    $exc_birthFrom: String
    $exc_birthTo: String
    $job_title: JSON
    $exc_job_title: JSON
    $status: JSON
    $current_page: Int
    $current_limit: Int
    $sort_by: JSON
  ) {
    getAttendanceReports(
      startDate: $startDate
      endDate: $endDate
      employee: $employee
      position: $position
      year: $year
      month: $month
      supervisor: $supervisor
      jobProfile: $jobProfile
      exc_jobProfile: $exc_jobProfile
      organization: $organization
      exc_organization: $exc_organization
      level: $level
      exc_level: $exc_level
      office: $office
      exc_office: $exc_office
      job_grades: $job_grades
      exc_job_grades: $exc_job_grades
      employeeType: $employeeType
      exc_employeeType: $exc_employeeType
      timeArrangement: $timeArrangement
      exc_timeArrangement: $exc_timeArrangement
      workArrangement: $workArrangement
      exc_workArrangement: $exc_workArrangement
      organization_level: $organization_level
      exc_organization_level: $exc_organization_level
      job_family: $job_family
      exc_job_family: $exc_job_family
      gender: $gender
      exc_gender: $exc_gender
      isFilterBirth: $isFilterBirth
      birthFrom: $birthFrom
      birthTo: $birthTo
      isFilterExcBirth: $isFilterExcBirth
      exc_birthFrom: $exc_birthFrom
      exc_birthTo: $exc_birthTo
      job_title: $job_title
      exc_job_title: $exc_job_title
      status: $status
      search: $search
      current_page: $current_page
      current_limit: $current_limit
      sort_by: $sort_by
    ) {
      result
    }
  }
`
export const GET_ATTENDANCE_TOTAL_PAGE = gql`
  query getAttendanceReport(
    $startDate: String
    $endDate: String
    $employee: String
    $organization: Int
    $position: Int
    $officeAddress: Int
    $year: String
    $month: String
    $supervisor: String
    $page: Int
    $search: String
    $limit: Int
  ) {
    getAttendanceReports(
      startDate: $startDate
      endDate: $endDate
      employee: $employee
      organization: $organization
      position: $position
      officeAddress: $officeAddress
      year: $year
      month: $month
      supervisor: $supervisor
      page: $page
      search: $search
      limit: $limit
    ) {
      pages
      total
    }
  }
`
export const GET_ATTENDANCE_REPORT_DATA = totalPage => {
  let req = ''
  for (let index = 0; index < totalPage; index++) {
    req += `
      report${index + 1}: getAttendanceReports(
        startDate: $startDate
        endDate: $endDate
        employee: $employee
        organization: $organization
        position: $position
        officeAddress: $officeAddress
        year: $year
        month: $month
        supervisor: $supervisor
        page: ${index + 1}
        search: $search
      ) {
        pages
        total
        data
      }
    `
  }
  return gql`
    query getAttendanceReport(
      $startDate: String
      $endDate: String
      $employee: String
      $organization: Int
      $position: Int
      $officeAddress: Int
      $year: String
      $month: String
      $supervisor: String
      $search: String
    ) {
      ${req}
    }
  `
}

export const GET_REPORT_USER = gql`
  query(
    $userWorkId: Int
    $startDate: String
    $endDate: String
    $selectedPlacementId: Int
  ) {
    getAttendanceReportsUser(
      userWorkId: $userWorkId
      startDate: $startDate
      endDate: $endDate
      job_profile_dropdown: $selectedPlacementId
    ) {
      result
      countIn
      countAbsent
      countLateDays
      countLateMinutes
      countOnLeave
      countOnTrip
      countOnHolidayTrip
    }
  }
`

export const GET_ATTENDANCE_REPORT_SEPARATE = gql`
  query getReport(
    $company: uuid
    $today_start_ts: timestamp
    $today_end_ts: timestamp
    $offset: Int
    $limit: Int
  ) {
    people_work_attendances(
      where: {
        deletedAt: {_is_null: true}
        people_work_placement: {
          deletedAt: {_is_null: true}
          company: {_eq: $company}
          status: {_eq: "ACTIVE"}
        }
        _and: [
          {date_added: {_gte: $today_start_ts}}
          {date_added: {_lte: $today_end_ts}}
        ]
      }
      order_by: {people_work_placement: {global_user: {name: asc}}}
    ) {
      people_work_placement {
        regno
        global_user {
          name
        }
        company_job_profile {
          designation: title
          organization: company_organization {
            name
          }
        }
      }
      date_added
      clock_in
      clock_out
    }
  }
`

export const GET_ATTENDANCE_REPORT_CALCULATED = gql`
  query($offset: Int!, $limit: Int!, $startDate: String, $endDate: String) {
    getAttendanceReportsExport(
      offset: $offset
      limit: $limit
      startDate: $startDate
      endDate: $endDate
    ) {
      data
    }
  }
`
