import React from "react";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import { TitleName } from "../../../../../../GlobalStyles";
import {
  currencyMaskingMethod,
  getNumber,
} from "../../../../../../utils/helpers";
import { compansationHandler } from "./helpers";

export default function PerHour(props) {
  const {
    classes,
    moneyCompensation,
    isConvertToMoney,
    isError,
    standard,
    setMoneyCompensation,
  } = props;
  const { isError: isErrorHour } = compansationHandler(
    standard,
    moneyCompensation
  );
  const isErrorPerhour = isErrorHour && isError;

  const handleChangeCompensation = (e) => {
    const { isNumber, number } = getNumber(e.target.value);

    if (isNumber) {
      setMoneyCompensation({ IDR: number });
    }
  };

  return (
    <>
      <TitleName>Reward Given</TitleName>
      <div className={classes.flexPerHour}>
        <TextField
          size="small"
          disabled={!isConvertToMoney}
          value={currencyMaskingMethod(moneyCompensation?.IDR)}
          onChange={handleChangeCompensation}
          variant="outlined"
          id="standard-start-adornment"
          onFocus={(e) => e.target.select()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
            classes: {
              input: classes.fiedCurrency,
            },
          }}
          className={classes.buttonSpacer}
          error={isErrorPerhour}
          helperText={isErrorPerhour && "This field is required"}
        />
        <Typography>Per Hour</Typography>
      </div>
    </>
  );
}
