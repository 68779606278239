import React from 'react'
import {flushSync} from 'react-dom'
import {Chip, makeStyles, TextField} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  inputStyle: {
    padding: '15px 18px',
  },
  inputStyleTextArea: {
    padding: 0,
  },
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
    marginBottom: 17,
  },
  root: {
    marginBottom: 32,
  },
  chipsWrapper: {marginTop: 16, gap: 12, display: 'flex', flexWrap: 'wrap'},
  chips: {padding: 8},
}))

export const TagTextFieldForm = props => {
  const {
    label,
    endAdornment,
    placeholder,
    name,
    values,
    setValues,
    multiline = false,
    ...rest
  } = props

  const tagRef = React.useRef()

  const removeTagFromValues = index => {
    const deletedValueIndex = index
    const newTags = [...values?.[name]]
    newTags.splice(deletedValueIndex, 1)

    setValues(e => ({
      ...e,
      [name]: newTags,
    }))
  }

  const addNewTagToValues = e => {
    e.preventDefault()

    flushSync(() => {
      setValues(e => ({
        ...e,
        [name]: [...values[name], tagRef.current.value],
      }))
    })

    tagRef.current.value = ''
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      {label && <p className={classes.label}>{label}</p>}
      <TextField
        fullWidth
        inputRef={tagRef}
        variant="outlined"
        placeholder={placeholder}
        InputProps={{
          classes: {
            input: multiline ? classes.inputStyleTextArea : classes.inputStyle,
          },
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            addNewTagToValues(e)
          }
        }}
        {...rest}
      />
      {endAdornment}

      {values?.[name]?.[0] && (
        <div className={classes.chipsWrapper}>
          {values?.[name]?.map((label, index) => {
            return (
              <Chip
                key={index}
                className={classes.chips}
                label={label}
                onDelete={() => removeTagFromValues(index)}
                color="primary"
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
