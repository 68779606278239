import {
  faFileExcel,
  faFilePdf,
  faFileWord,
  faFile,
  faFileVideo,
} from '@fortawesome/free-solid-svg-icons'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import React, {useEffect, useRef, useState} from 'react'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import FileDownloadIcon from '@material-ui/icons/GetApp'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {Typography, Icon} from '@material-ui/core'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {downloadFile, formatBytes} from '../../../../../utils/helpers'
import {AttachmentItemHover} from '../../../../shared-components/attachment/AttachmentItemHover'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import {useMutation} from '@apollo/react-hooks'
import {UPDATE_ATTACHMENT_TASK} from '../../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    fontFamily: 'Muli',
    fontSize: '12px',
    fontWeight: 'normal',
    color: '#a9a8a8',
  },
  attachmentImg: {
    objectFit: 'cover',
    height: 315,
    width: '100%',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  iframeWrapper: {
    width: '100%',
    height: 400,
    borderRadius: '5px',
    backgroundColor: '#ffff',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  iframeContent: {
    width: '100%',
    height: 315,
  },
  icon: {
    fontSize: '50px !important',
    marginRight: '1rem',
  },
  detailFile: {
    display: 'flex',
    padding: '1rem',
    alignItems: 'center',
  },
  unknownContainer: {
    height: 315,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#eff2f4',
  },
  iconUnknown: {
    color: '#d0d4d4',
    fontSize: '200px !important',
  },
  iconImage: {
    fontSize: '50px !important',
    marginRight: '1rem',
  },
}))
function FileEx(props) {
  const classes = useStyles()
  const {value, file} = props
  const playerRef = useRef()

  useEffect(() => {
    if (
      file === 'webm' ||
      file === 'mpeg' ||
      file === 'mp4' ||
      file === 'avi' ||
      file === 'mov' ||
      file === 'flv'
    ) {
      const player = videojs(playerRef.current, {controls: true}, () => {
        player.src(value.link)
      })

      return () => {
        player.dispose()
      }
    }
  })
  const renderInformationFile = () => (
    <div>
      <Typography variant="body2">{value.name}</Typography>
      <Typography variant="body2" style={{color: '#a9a8a8'}}>
        {value.size && formatBytes(value.size)}
      </Typography>
    </div>
  )

  const renderItem = () => {
    if (file === 'jpg' || file === 'png' || file === 'jpeg') {
      return (
        <>
          <a href={`${value.link}`} target="_blank" rel="noopener noreferrer">
            <img
              src={value.link}
              alt="attachment"
              className={classes.attachmentImg}
            />
          </a>
          <div className={classes.detailFile}>
            <Icon className={classes.iconImage} style={{color: '#EF4d5E'}}>
              image
            </Icon>
            {renderInformationFile()}
          </div>
        </>
      )
    } else if (
      file === 'pdf' ||
      file === 'doc' ||
      file === 'docx' ||
      file === 'xlsx' ||
      file === 'xls' ||
      file === 'csv'
    ) {
      return (
        <>
          <iframe
            title={value.name}
            className={classes.iframeContent}
            frameBorder="0"
            src={`https://docs.google.com/viewer?url=${value.link}&embedded=true`}
          ></iframe>
          <div className={classes.detailFile}>
            <FontAwesomeIcon
              icon={
                file === 'pdf'
                  ? faFilePdf
                  : file === 'doc' || file === 'docx'
                  ? faFileWord
                  : file === 'xlsx' || file === 'xls' || file === 'csv'
                  ? faFileExcel
                  : faFile
              }
              style={{
                color:
                  file === 'pdf'
                    ? '#E82B03'
                    : file === 'doc' || file === 'docx'
                    ? '#039be5'
                    : file === 'xlsx' || file === 'xls' || file === 'csv'
                    ? '#2C5A1A'
                    : '#eff2f4',
              }}
              className={classes.icon}
            />
            {renderInformationFile()}
          </div>
        </>
      )
    } else if (
      file === 'webm' ||
      file === 'mpeg' ||
      file === 'mp4' ||
      file === 'avi' ||
      file === 'mov' ||
      file === 'flv'
    ) {
      return (
        <>
          <div className={classes.iframeContent}>
            <div data-vjs-player>
              <video
                ref={playerRef}
                className="video-js"
                style={{height: '100%', width: '100%'}}
              />
            </div>
          </div>

          <div className={classes.detailFile}>
            <FontAwesomeIcon
              icon={faFileVideo}
              style={{color: '#874CAF'}}
              className={classes.icon}
            />
            {renderInformationFile()}
          </div>
        </>
      )
    } else
      return (
        <>
          <div className={classes.unknownContainer}>
            <FontAwesomeIcon icon={faFile} className={classes.iconUnknown} />
          </div>
          <div className={classes.detailFile}>
            <FontAwesomeIcon
              icon={faFile}
              style={{
                color: '#d0d4d4',
              }}
              className={classes.icon}
            />
            {renderInformationFile()}
          </div>
        </>
      )
  }
  return <div className={classes.iframeWrapper}>{renderItem()}</div>
}

export default function Detail({data, type}) {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const INITIAL_DELETE_DATA = {
    open: false,
    id: '',
  }

  const [deleteAttachment, setDeleteAttachment] = useState(INITIAL_DELETE_DATA)

  const [updateAttachment] = useMutation(UPDATE_ATTACHMENT_TASK)

  let dataAttachment = []
  let file = []
  let nameFile = ''

  return type === 'task' ? (
    <div className={classes.root}>
      {data.performance_tasks.map(dataDetail => {
        const handleDeleteHover = i => {
          const newState = [...dataDetail?.attachment]
          if (i > -1) {
            newState.splice(i, 1)
          }

          updateAttachment({
            variables: {
              id: dataDetail.id,
              attachment: newState,
            },
          })
            .then(() => {
              enqueueSnackbar('Attachment deleted', {
                variant: 'success',
                autoHideDuration: 1000,
              })
            })
            .catch(() => {
              enqueueSnackbar(
                'Delete Attachment failed, please try again later',
                {
                  variant: 'error',
                  autoHideDuration: 1000,
                }
              )
            })
        }

        dataDetail?.attachment?.map(res => {
          if (res.name) {
            nameFile = res.link || res.url
            file = nameFile.split('.')
            const data = {
              link: nameFile,
              name: res.name,
              file: file[file.length - 1],
              size: res.size,
            }
            return dataAttachment.push(data)
          } else {
            nameFile = res
            file = nameFile.split('.')
            const data = {
              link: res,
              name: 'name not found',
              file: file[file.length - 1],
              size: res.size,
            }
            return dataAttachment.push(data)
          }
        }) ?? (dataAttachment = [])
        return (
          <Grid container spacing={3} key={dataDetail.id}>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.title}>
                Keterangan
              </Typography>
              <Typography>{dataDetail.description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title}>Lampiran</Typography>
              <Grid container style={{marginTop: 0}} spacing={2}>
                {dataAttachment.length > 0 ? (
                  dataAttachment.map((value, index) => {
                    const fileUrl = value.link || value.url
                    const getFileName = fileUrl
                    const getFileExtension = getFileName?.split('.')
                    const extensionFile =
                      getFileExtension?.[getFileExtension?.length - 1]
                    return (
                      <Grid item xs={3} key={index}>
                        <AttachmentItemHover
                          key={`${index}-itm`}
                          size={{width: 220, height: 160, padding: 0}}
                          full
                          iconAction={
                            <>
                              <VisibilityOutlinedIcon
                                onClick={() => window.open(value.link)}
                                className="icon"
                              />
                              <DeleteOutlineIcon
                                style={{marginLeft: 5}}
                                onClick={() => {
                                  setDeleteAttachment({
                                    ...deleteAttachment,
                                    open: true,
                                    id: index,
                                  })
                                }}
                                className="icon"
                              />
                              <FileDownloadIcon
                                className="icon"
                                onClick={() =>
                                  downloadFile(
                                    fileUrl,
                                    value.name,
                                    extensionFile || value.file
                                  )
                                }
                              />
                            </>
                          }
                          fileName={value.name}
                          url={value.link}
                        />
                      </Grid>
                    )
                  })
                ) : (
                  <NoDataListComponent
                    title="Disini Kosong"
                    message="Oops! Sepertinya belum ada apa-apa di folder ini"
                  />
                )}
              </Grid>
            </Grid>
            <DeletePopup
              open={deleteAttachment.open}
              handleClose={() => setDeleteAttachment(INITIAL_DELETE_DATA)}
              name="Attachment"
              mutation={() => handleDeleteHover(deleteAttachment.id)}
            />
          </Grid>
        )
      })}
    </div>
  ) : (
    <div className={classes.root}>
      {data.performance_goals.map(dataDetail => {
        dataDetail.attachment && dataDetail.attachment.length > 0
          ? dataDetail.attachment.map(res => {
              nameFile = res.link || res.url
              file = nameFile.split('.')

              const data = {
                link: nameFile,
                name: res.name,
                file: file[file.length - 1],
                size: res.size,
              }

              return dataAttachment.push(data)
            })
          : (dataAttachment = [])
        return (
          <Grid container spacing={3} key={dataDetail.id}>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.title}>
                {GLOBAL_GLOSSARY.performance.Description_goal}
              </Typography>
              <Typography>{dataDetail.description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title}>Lampiran</Typography>
              <Grid container style={{marginTop: 0}} spacing={2}>
                {dataAttachment.length > 0 ? (
                  dataAttachment.map((value, index) => {
                    return (
                      <Grid item xs={6} key={index}>
                        <FileEx value={value} file={value.file} />
                      </Grid>
                    )
                  })
                ) : (
                  <center>
                    <img src="https://cdn.dribbble.com/users/888330/screenshots/2653750/empty_data_set_1x.png" />
                  </center>
                )}
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </div>
  )
}
