import gql from 'graphql-tag'

export const CHANGE_STATUS_TALENT_VERSION = gql`
  mutation($id: uuid, $status: String) {
    update_talent_versions(where: {id: {_eq: $id}}, _set: {status: $status}) {
      affected_rows
    }
  }
`

export const CHANGE_VISIBILIY_TALENT_VERSION = gql`
  mutation($id: uuid, $visibility: String) {
    update_talent_versions(
      where: {id: {_eq: $id}}
      _set: {visibility: $visibility}
    ) {
      affected_rows
    }
  }
`
