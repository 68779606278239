import gql from 'graphql-tag'

export const UPDATE_KPI_METHOD = id => {
  return gql`
      mutation updateKpiMethod($data: kpi_methods_set_input) {
        update_kpi_methods(where: {id: {_eq: ${id}}}, _set: $data) {
          returning {
            id
          }
        }
      }
    `
}
