import React from 'react'
import styled from 'styled-components'

import {withStyles, Link, Tabs, Paper} from '@material-ui/core'

import {capitalize} from '../../../utils/helpers'
import {TableOptions as BaseTableOptions} from '../payroll/PayrollPageStyles'

// Page container
export const ClaimPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${props => props.theme.font};
  color: black;
  overflow: hidden;
  width: 100vw;
`

// Table configuration
export const DEFAULT_PAGE_SIZE = 20
export const PAGE_SIZE_OPTIONS = [20, 50, 100]

export const TableOptions = {
  ...BaseTableOptions,
  maxBodyHeight: null,
  pageSize: DEFAULT_PAGE_SIZE,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
}

export function TableContainer(props) {
  return <Paper {...props} elevation={0} />
}

// Link
export const ActionableLink = withStyles(theme => ({
  root: {
    display: 'block',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
}))(Link)

// Status pill
const PrimitiveStatusPill = styled.div`
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  border-radius: 4px;
  padding: 4px 12px;
`

export const StatusColors = {
  approved: '#4caf50',
  waiting: '#ffa000',
  rejected: '#ef4d5e',
  cancelled: '#a9a8a8',
  processing: '#a35a31',
}

export function StatusPill(props) {
  const {status, ...rest} = props

  const backgroundColor = StatusColors[status]

  return (
    <PrimitiveStatusPill style={{backgroundColor}} {...rest}>
      {capitalize(status)}
    </PrimitiveStatusPill>
  )
}

// Tabs
export const ExtendedTabs = withStyles({
  root: {
    backgroundColor: '#eff2f4',
  },
  indicator: {
    height: 4,
  },
})(Tabs)

export function getClaimType(data) {
  if (!data.invoice) {
    return 1
  }

  switch (data.invoice.module_name) {
    case 'marketplace':
      return 3
    case 'academy':
    case 'book':
    case 'classroom':
    case 'microlearning':
      return 2
    default:
      return 0
  }
}

export function getClaimTypeLabel(data) {
  switch (getClaimType(data)) {
    case 0:
      return 'Unknown'
    case 1:
      return 'Financial'
    case 2:
      return 'Learning'
    case 3:
      return 'Vendor'
  }
}
