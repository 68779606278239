export const isErrorForm = fieldsList => {
  return fieldsList.some(field => {
    if (!field || !field.required) {
      return false
    }

    const type = field.type
    const value = field.value
    const validation = field.validation

    if (type === 'date') {
      return (
        ((value[0] === null || value[1] === null) && !value[2]) ||
        (value[0] === null && value[2])
      )
    }

    if (type === 'dateCustom') {
      return !value || value === 'Invalid date'
    }

    if (type === 'phone-field') {
      return !value[0] || !value[1]
    }

    if (type === 'tag-textfield') {
      return value?.length === 0
    }

    if (validation) {
      return validation
    }

    return value === null || value === ''
  })
}
