import gql from 'graphql-tag'

export const GET_LIST_EMPLOYEES = gql`
  {
    people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_organization {
        id
        name
      }
      company_employee_position {
        id
        name
      }
      company_profile {
        id
        brand_name
      }
    }
  }
`

// query ini digunakan dibanyak component
export const GET_DROPDOWN_EMP_GOALS_REPORTER = gql`
  query($company: uuid, $search: String, $limit: Int) {
    people_work_placements(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
      }
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_DROPDOWN_REPORTER_TASK = gql`
  query assignmentedTask(
    $task: bigint!
    $search: String
    $offset: Int
    $limit: Int
  ) {
    performance_task_assignments(
      where: {task: {_eq: $task}, global_user: {name: {_ilike: $search}}}
      offset: $offset
      limit: $limit
    ) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`
