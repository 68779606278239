import gql from 'graphql-tag'

export const ADD_QUESTION_BANK = gql`
  mutation addQuestionBank($data: [multirater_bank_questions_insert_input!]!) {
    insert_multirater_bank_questions(objects: $data) {
      affected_rows
    }
  }
`

export const UPDATE_QUESTION_BANK = gql`
  mutation(
    $id: bigint
    $question_self: String
    $question_others: String
    $number: Int
    $group: bigint
  ) {
    update_multirater_bank_questions(
      _set: {
        question_self: $question_self
        question_others: $question_others
        number: $number
        group: $group
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const DELETE_QUESTION_BANK = gql`
  mutation($id: bigint) {
    delete_multirater_bank_questions(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`
