import gql from 'graphql-tag'

export const GET_LIST_RESPONDENTS_GROUP = gql`
  query getListRespondentsGroup($company: uuid!, $id: bigint) {
    multirater_respondent_groups(
      where: {company: {_eq: $company}, id: {_eq: $id}}
      order_by: {id: desc}
    ) {
      id
      name
    }
  }
`
