import gql from 'graphql-tag'

export const GET_LEAVE_REQUEST = gql`
  query($where: people_work_leaves_bool_exp, $limit: Int, $offset: Int) {
    people_work_leaves(where: $where, limit: $limit, offset: $offset) {
      id
      id_display
      is_half
      quota_taken
      contacts
      people_work_placement {
        id
        regno
        global_user {
          id
          name
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              global_user {
                id
                name
                avatar
              }
            }
            spv_2: job_profile_supervisor {
              id
              title
              people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                global_user {
                  id
                  name
                }
              }
              spv_3: job_profile_supervisor {
                id
                title
                people_work_placements(
                  where: {status: {_eq: "ACTIVE"}}
                  limit: 1
                ) {
                  id
                  global_user {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      time_leave_policy_child {
        id
        name
        confirm_type
        first_job_profile {
          id
          title
          people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
            id
            global_user {
              id
              name
            }
          }
        }
        second_job_profile {
          id
          title
          people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
            id
            global_user {
              id
              name
            }
          }
        }
      }
      leave_fields
      date_added
      description
      datestart
      dateend
      cancelled_by_user {
        id
        name
      }
      globalUserByModifiedBy {
        id
        name
      }
      status
    }
    total: people_work_leaves_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_USER_WORKID = gql`
  query($user: uuid) {
    people_work_placements(
      where: {status: {_eq: "ACTIVE"}, user: {_eq: $user}}
    ) {
      id
    }
  }
`
