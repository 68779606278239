import gql from 'graphql-tag'

export const GET_ALL_POSITION = gql`
  query($offset: Int, $limit: Int) {
    company_employee_positions(
      offset: $offset
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`
