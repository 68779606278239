import gql from 'graphql-tag'

export const GET_FOLLOWERS_COMPANY = gql`
  query($company: String) {
    getTotalFollowersCompany(company: $company) {
      total
      is_following
      is_followed
    }
  }
`
