import styled from 'styled-components'

export const ParameterInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .axis-box {
    width: 50%;
    .flex-between {
      display: flex;
      justify-content: space-between;
    }
    .margin-0 {
      margin: 0px !important;
    }
    .bundle-container {
      margin-top: 17px;
      width: 100%;
      .indicatior-container {
        display: flex;
        justify-content: space-between;
        .title-container {
          width: 20%;
        }
        .value-container {
          width: 80%;
        }
      }
      .p-title {
        font-family: Muli;
        font-size: 14px;
        color: #a9a8a8;
        margin: 0px !important;
      }
      .p-value {
        margin-top: 8px;
        font-family: Muli;
        font-size: 14px;
        color: #000000;
        font-weight: 600;
        margin-bottom: 0px !important;
      }
      .p-indicator {
        font-family: Muli;
        font-size: 12px;
        color: #a9a8a8;
      }
      .p-parameter {
        font-family: Muli;
        font-size: 14px;
        color: #a9a8a8;
        font-weight: 600;
        margin: 0px !important;
      }
    }
  }
`

export const ParticipantContainer = styled.div`
  padding: 24px;
  .table-container {
    padding: 24px;
    .icon-search {
      color: '#014a62';
      width: 20px;
      height: 20px;
    }
    .p-black {
      font-size: 0.875rem;
      font-family: Muli;
      color: #000000;
      margin: 0px;
    }
    .p-grey {
      font-size: 0.875rem;
      font-family: Muli;
      color: #a9a8a8;
      margin: 0px;
    }
    .p-blue {
      font-size: 0.875rem;
      font-family: Muli;
      color: #039be5;
      margin: 0px;
      cursor: pointer;
    }
  }
`

export const DetailParticipantContainer = styled.div`
  .title {
    color: '#ffffff';
    aligns-self: 'center';
  }
  .body-wrap {
    max-height: 400px;
    overflow-y: auto;
    background-color: #f7f8f9;

    .section-detail {
      padding: 0px;
      .wrap-section {
        padding: 24px;
        .people-section {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .list-people {
            display: flex;
            justify-content: space-between;
            width: 33%;
            margin-top: 15px;
            .people-detail {
              margin-left: 10px;
              align-self: center;
            }
            .MuiAvatar-root {
              width: 50px;
              height: 50px;
            }
          }
        }
        .text-section {
          margin-top: 30px;
        }
        .p-title {
          font-family: Muli;
          font-size: 14px;
          color: #000000;
          font-weight: 600;
          margin: 0px !important;
        }
        .p-value {
          font-family: Muli;
          font-size: 14px;
          color: #000000;
          margin-top: 8px !important;
          margin-bottom: 0px !important;
        }
        .p-small {
          font-family: Muli;
          font-size: 12px;
          color: #000000;
          margin-top: 3px !important;
          margin: 0px !important;
        }
      }
    }
  }
  .grey-section {
    background-color: #f7f8f9;
  }
`

export const HoverBadgeContainer = styled.div`
  width: 200px;
  height: 100px;
  padding: 10px;
  .p-title-axis {
    font-family: Muli;
    font-weight: 600;
    font-size: 11px;
    color: #014a62;
    margin: 0px !important;
  }
  .y-axis-section {
    margin-top: 13px;
  }
  .hover-between {
    display: flex;
    justify-content: space-between;
    .name-section {
      width: 80%;
    }
    .value-section {
      width: 20%;
    }
    .p-value-axis {
      font-family: Muli;
      font-size: 11px;
      color: #a9a8a8;
      margin-top: 7px;
      margin: 0px !important;
    }
  }
`
