/* eslint-disable no-console */
import React, {useState} from 'react'
import {useApolloClient} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import moment from 'moment'

import {ButtonSubmit} from '../../../SharedDetailEmployee'
import {isErrorForm} from '../../ShareComponents-detail/helperDetail'
import {ModalAddEditWraper} from '../../../DetailEmployeeStyles'
import DeletePopup from '../../../../../../shared-components/popup/DeletePopup'
import FormInput from '../../ShareComponents-detail/FormInput'
import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'

import {
  ADD_ASSESSMENT,
  DELETE_ASSESSMENT,
  UPDATE_ASSESSMENT,
} from '../../../../../../../graphql/mutations'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_FORM_DATA = {
  name: '',
  subject: '',
  institution: '',
  test_date: undefined,
  test_cost: '',
  test_score: '',
  test_score_range: '',
  test_result: '',
  recommendation_result: '',
  general_conclusion: '',
  purpose: '',
  attachments: [],
}

const AssessmentAddEditDelete = props => {
  const {
    open = false,
    userId,
    data: initialData,
    onClose,
    isEdit,
    refetch,
  } = props
  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [formState, setFormState] = React.useState(STATE_INITIAL)
  const [formData, setFormData] = useState(
    isEdit ? initialData : INITIAL_FORM_DATA
  )
  const [modalDelete, setModalDelete] = useState({
    data: initialData,
    open: false,
  })

  const isError = formState === STATE_ERROR
  const isDispatching = formState === STATE_DISPATCH

  React.useEffect(() => {
    if (initialData) {
      setFormData({...initialData})
    }
  }, [initialData])

  const deleteMutation = () => {
    const promise = client.mutate({
      mutation: DELETE_ASSESSMENT,
      variables: {
        userId: userId,
        id: initialData.id,
      },
    })

    promise.then(
      () => {
        refetch()
        onClose()
        enqueueSnackbar(`Assessment deleted`, {variant: 'success'})
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Failed to delete Assessment`, {variant: 'error'})
      }
    )
  }

  const submitMutation = () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const objects = {
      ...formData,
      user_id: userId,
      test_score: Number(formData?.test_score),
      test_date: moment(formData.test_date).format('YYYY-MM-DD'),
    }
    delete objects.__typename

    const verb = isEdit ? 'edit' : 'add'
    let promise

    if (isEdit) {
      promise = client.mutate({
        mutation: UPDATE_ASSESSMENT,
        variables: {
          id: initialData.id,
          objects,
        },
      })
    } else {
      promise = client.mutate({
        mutation: ADD_ASSESSMENT,
        variables: {
          objects,
        },
      })
    }

    promise.then(
      () => {
        setFormState(STATE_INITIAL)
        refetch()
        onClose()

        enqueueSnackbar(`Assessment ${verb}ed`, {variant: 'success'})
      },
      err => {
        setFormState(STATE_INITIAL)

        console.error(err)
        enqueueSnackbar(`Failed to ${verb} assessment`, {variant: 'error'})
      }
    )
  }

  const fieldsList = [
    {
      type: 'textfield',
      label: 'Nama Asesmen*',
      placeholder: 'Tambahkan nama asesmen ',
      required: true,
      fieldName: 'name',
      value: formData?.name,
      error: !formData?.name,
    },
    {
      type: 'textfield',
      label: 'Pokok Penilaian *',
      placeholder: 'Tambahkan pokok penilaian',
      required: true,
      fieldName: 'subject',
      value: formData?.subject,
      error: !formData?.subject,
    },
    {
      type: 'textfield',
      label: 'Penyelenggara*',
      placeholder: 'Tambahkan nama penyelenggara',
      required: true,
      fieldName: 'institution',
      value: formData?.institution,
      error: !formData?.institution,
    },
    {
      label: ['Tanggal Asesmen*'],
      filter: ['date'],
      fieldName: 'test_date',
      type: 'dateCustom',
      required: true,
      value: formData.test_date,
      error: !formData.test_date,
    },
    {
      type: 'textfield',
      label: 'Biaya Asesmen*',
      placeholder: 'Tambahkan biaya asesmen',
      required: true,
      fieldName: 'test_cost',
      value: formData?.test_cost,
      error: !formData?.test_cost,
    },
    {
      type: 'textfield',
      inputType: 'number',
      label: 'Skor Asesmen*',
      placeholder: 'Tambahkan skor asesmen ',
      required: true,
      fieldName: 'test_score',
      value: formData?.test_score,
      error: !formData?.test_score,
      inputProps: {min: 0},
    },
    {
      type: 'textfield',
      inputType: 'number',
      label: 'Rentang Skor Asesmen*',
      placeholder: 'Tambahkan rentang skor asesmen',
      required: true,
      fieldName: 'test_score_range',
      value: formData?.test_score_range,
      error: !formData?.test_score_range,
      inputProps: {min: 0},
    },
    {
      type: 'textfield',
      label: 'Hasil Asesmen',
      placeholder: 'Tambahkan hasil asesmen',
      required: false,
      fieldName: 'test_result',
      value: formData?.test_result,
    },
    {
      type: 'textfield',
      label: 'Hasil Rekomendasi',
      placeholder: 'Tambahkan hasil rekomendasi',
      required: false,
      fieldName: 'recommendation_result',
      value: formData?.recommendation_result,
    },
    {
      type: 'textfield',
      label: 'Kesimpulan Umum',
      placeholder: 'Tambahkan kesimpulan umum',
      required: false,
      fieldName: 'general_conclusion',
      value: formData?.general_conclusion,
    },
    {
      type: 'textfield',
      label: 'Tujuan',
      placeholder: 'Tambahkan tujuan',
      required: false,
      fieldName: 'purpose',
      value: formData?.purpose,
    },
    {
      type: 'attachment',
      fieldName: 'attachment',
      label: 'Lampiran',
      attachments: formData?.attachments,
      error: true,
      multiple: true,
      link: 'url',
    },
  ]

  return (
    <>
      <ModalDetail
        open={open}
        maxWidth="sm"
        title={`${isEdit ? 'Ubah' : 'Tambahkan'} Asesmen`}
        onClose={
          isDispatching
            ? null
            : () => {
                setFormData(INITIAL_FORM_DATA)
                onClose()
              }
        }
        onDelete={
          isEdit
            ? () => {
                setModalDelete({...modalDelete, open: true})
              }
            : null
        }
      >
        <ModalAddEditWraper>
          <FormInput
            open
            fieldsList={fieldsList}
            setValues={setFormData}
            values={formData}
            errorState={isError}
          />
        </ModalAddEditWraper>

        <ButtonSubmit
          disabled={isDispatching}
          onCancel={onClose}
          onSave={submitMutation}
        />
      </ModalDetail>

      <DeletePopup
        name={modalDelete.data?.name}
        open={modalDelete.open}
        mutation={deleteMutation}
        handleClose={() => setModalDelete({...modalDelete, open: false})}
      />
    </>
  )
}

export default AssessmentAddEditDelete
