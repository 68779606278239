import React from 'react'
import {IconButton, Link} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Person from '@material-ui/icons/Person'

import {ItemWrapper} from '../../../DetailEmployeeStyles'
import {dateFormat} from '../../../../../../../utils/helpers'

import {AttachmentCard} from '../../../../../../../components/attachment'

const MedicalHistoryItem = props => {
  const {data, onEdit, onDetails} = props

  return (
    <ItemWrapper>
      <h1 className="title">{data.name}</h1>

      <h2 className="subtitle">
        {dateFormat(data.start_date, 'll') +
          ' - ' +
          dateFormat(data.end_date, 'll')}
      </h2>

      <table className="content" style={{'--header-width': '1%'}}>
        <tbody>
          <tr>
            <th scope="row">
              <LocationOnIcon color="primary" />
            </th>
            <td className="highlight-text">{data.hospital_name || '-'}</td>
          </tr>
          <tr>
            <th scope="row">
              <Person color="primary" />
            </th>
            <td>{data.doctor_name || '-'}</td>
          </tr>
        </tbody>
      </table>

      {data.disease && <p className="text primary">{data.disease || '-'}</p>}

      {data.consequence && <p className="text">{data.consequence || '-'}</p>}

      <div className="attachments row">
        {data.attachments?.slice(0, 3)?.map((atch, i) => (
          <AttachmentCard
            key={`${i}-atch-medical-history`}
            name={atch.name || '-'}
            url={atch.url || atch.link}
          />
        ))}
      </div>

      <Link
        color="secondary"
        component="button"
        onClick={() => onDetails(data)}
        style={{marginTop: 16}}
      >
        Tampilkan Detail Riwayat Medis
      </Link>

      <IconButton onClick={() => onEdit(data)} className="edit">
        <EditIcon />
      </IconButton>
    </ItemWrapper>
  )
}

export default MedicalHistoryItem
