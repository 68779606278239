import React, {useMemo, useState} from 'react'
import ProfileSection from '../../shared-components/company-profile/ProfileSectionComponent'
import DescriptionSection from '../../shared-components/company-profile/DescriptionSectionComponent'
import MemberSection from '../../shared-components/company-profile/MemberSectionComponent'
import DetailSection from '../../shared-components/company-profile/DetailSectionComponent'
import OfficeLocationSection from '../../shared-components/company-profile/OfficeLocationSectionComponent'
import {
  GET_PROFILE_BY_ID,
  GET_HOLDING_MEMBER,
  GET_FOLLOWERS_COMPANY,
} from '../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import Loading from '../../../components/loading/LoadingComponent'
import {COMPANY_ID} from '../../../utils/globals'
import {withRouter} from 'react-router-dom'
import ModalEditDescription from '../../company/profile/profile-component/EditDescription'
import {holdingRolesContext} from '../../../utils/helpers'

function ProfileDashboard(props) {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(!open)
  }
  const idCcompany =
    props.feature === 'holding-member' ? props.match.params.id : COMPANY_ID

  const {data, error, loading, refetch} = useQuery(GET_PROFILE_BY_ID, {
    variables: {company: idCcompany},
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const {
    data: dataMember,
    error: errorMember,
    loading: loadingMember,
  } = useQuery(GET_HOLDING_MEMBER(idCcompany, 'ACTIVE'), {
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const {
    data: follower,
    error: errFollower,
    loading: loadingFollower,
  } = useQuery(GET_FOLLOWERS_COMPANY, {
    variables: {company: idCcompany},
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const res = data?.company_profiles[0]

  const officeListing = useMemo(() => {
    const dataAddress = res?.company_addresses

    const main = res?.main_office

    if (!main) {
      return dataAddress
    }

    return [main, ...dataAddress.filter(address => address.id !== main.id)]
  }, [data])

  if (loading || loadingMember || loadingFollower) {
    return <Loading />
  }
  if (error || errorMember || errFollower) {
    console.error(error || errorMember || errFollower)
  }

  const _loc = `${res?.main_office?.global_city?.name}, ${res?.main_office?.global_province?.name}`

  return (
    <div style={{width: '100%'}}>
      <ProfileSection
        avatar={res?.logo}
        banner={res.profile_field?.banner}
        headline={res.profile_field?.headline}
        brand={res?.brand_name}
        legal={res?.legal_name}
        website={res.profile_field?.website}
        // location={`${res.company_addresses[0].global_city.name}, ${res.company_addresses[0].global_province.name}`}
        location={_loc}
        codePhone={res?.country_code}
        phone={res?.phone}
        link={{
          linkedIn: res.profile_field?.linkedin,
          twitter: res.profile_field?.twitter,
          facebook: res.profile_field?.facebook,
          email: res.profile_field?.email,
        }}
        feature={props.feature}
        back={props.history.goBack}
        data={res}
        refetch={refetch}
      />
      <div style={{display: 'flex', alignItems: 'flex-start'}}>
        <div style={{marginRight: 20, width: '100%'}}>
          <DescriptionSection
            description={res.profile_field?.description}
            feature={props.feature}
            openEdit={handleClose}
          />
          <MemberSection data={dataMember?.holding_relations} />
          <OfficeLocationSection
            location={officeListing}
            mainOffice={res?.main_office?.id}
            contact={res?.profile_field}
            phone={`${res?.country_code}${res?.phone}`}
          />
        </div>
        <DetailSection
          countEmp={res.people_work_placements_aggregate?.aggregate.count}
          industry={res.global_company_industry_type?.name}
          type={res.company_legal_type?.legal_type}
          // headquarter={`${res.company_addresses[0].global_city.name}, ${res.company_addresses[0].global_province.name}`}
          headquarter={_loc}
          countFollowers={follower.getTotalFollowersCompany?.total}
          founded={res.profile_field?.founded}
          speciality={res.profile_field?.specialities}
          // speciality={res.profile_field.speciality}
        />
      </div>
      <ModalEditDescription
        data={data}
        reload={refetch}
        open={open}
        handleClose={handleClose}
        feature={props.feature}
      />
    </div>
  )
}

export default withRouter(ProfileDashboard)
