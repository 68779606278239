import gql from 'graphql-tag'

export const GET_BANK_OPTION_LIST = gql`
  query getListBank($search: String) {
    global_banks(
      where: {_or: [{name: {_ilike: $search}}, {code: {_ilike: $search}}]}
      order_by: [{name: asc}]
    ) {
      id
      code
      name
    }
  }
`

export const CEK_ID_BANK = gql`
  query checkUserBankAccountConstraint($account: String, $user: uuid) {
    people_profile_banks(
      where: {
        account_number: {_eq: $account}
        user: {_eq: $user}
        deletedAt: {_is_null: true}
      }
    ) {
      id
    }
  }
`
