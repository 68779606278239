import React from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import {
  AppBar,
  CircularProgress,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  Paper,
  Button,
  Avatar,
  ButtonGroup,
  Link,
  Menu,
  MenuItem,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import {OrgChart} from '@worklifebeyond/wlb-utils-components'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'

import {useStyles, mapOrgChartNode, mapStrokeStyle} from './OSStyles'

import DefaultAvatar from '../../../../../assets/images/default-avatar.png'

import {buildListToTree} from '../../../../../utils/helpers'
import {
  GET_TOP_DETAIL_ORG_STRUCTURE,
  GET_CHILD_DETAIL_ORG_STRUCTURE,
} from '../../../../../graphql/queries'
import {
  SET_JOBPROFILE_ASSISTANT,
  SET_JOBPROFILE_LINETYPE,
} from '../../../../../graphql/mutations/company/organization/job-profile/editAllJobProfile.mutation'

const EMPTY_ARRAY = []

const OUDetailsModal = props => {
  const {
    open = false,
    onClose,
    jobProfileId,
    organizationId,
    organizationName,
    organizationLevel,
  } = props

  const styles = useStyles()
  const instanceRef = React.useRef()

  const [listing, setListing] = React.useState(EMPTY_ARRAY)

  const [selected, setSelected] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const {loading, error} = useQuery(GET_TOP_DETAIL_ORG_STRUCTURE, {
    skip: !jobProfileId,
    fetchPolicy: 'network-only',
    variables: {
      lead: jobProfileId,
      organization: organizationId,
    },
    onCompleted: data => {
      if (data) {
        setListing(data.lead.map(mapOrgChartNode))
      }
    },
  })

  const tree = React.useMemo(() => {
    return buildListToTree(listing)
  }, [listing])

  const handleNodeMenu = (node, ev) => {
    setSelected(node)
    setAnchorEl(ev.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleSetAssistant = next => {
    handleMenuClose()

    if (next) {
      const parent = selected.parent
      const children = listing.filter(node => node.parent === parent)

      let isRegular = 0

      for (const child of children) {
        if (!child.staff) {
          isRegular += 1
        }
      }

      if (isRegular < 2) {
        enqueueSnackbar(`Cannot set position as assistant`)
        return
      }
    }

    const promise = client.mutate({
      mutation: SET_JOBPROFILE_ASSISTANT,
      variables: {
        id: selected.id,
        assistant: next,
      },
    })

    promise.then(
      () => {
        const word = next ? 'now' : 'no longer'
        enqueueSnackbar(`${selected.name} is ${word} assistant`)

        setListing(prev => {
          const arr = prev.slice()
          const index = prev.findIndex(item => item.id === selected.id)

          arr[index] = {...arr[index], staff: next}
          return arr
        })
      },
      () => {
        const word = next ? 'set' : 'unset'
        enqueueSnackbar(`Failed to ${word} ${next.name} as assistant`)
      }
    )
  }

  const handleSetStrokeStyle = next => {
    handleMenuClose()

    const promise = client.mutate({
      mutation: SET_JOBPROFILE_LINETYPE,
      variables: {
        id: selected.id,
        lineType: next,
      },
    })

    promise.then(
      () => {
        enqueueSnackbar(`${selected.name} line type set`)

        setListing(prev => {
          const arr = prev.slice()
          const index = prev.findIndex(item => item.id === selected.id)

          arr[index] = {...arr[index], strokeStyle: mapStrokeStyle(next)}
          return arr
        })
      },
      () => {
        enqueueSnackbar(`Failed to set ${next.name} line type`)
      }
    )
  }

  const expandNode = node => {
    const promise = client.query({
      query: GET_CHILD_DETAIL_ORG_STRUCTURE,
      fetchPolicy: 'network-only',
      variables: {
        lead: node.id,
        organization: organizationId,
      },
    })

    promise.then(
      result => {
        const children = result.data.company_job_profiles

        if (children.length < 1) {
          enqueueSnackbar(`${node.name} doesn't have have any subordinates`)
          return
        }

        setListing(prev => {
          const next = []
          const mapped = []

          const exists = new Set()

          for (const child of children) {
            exists.add(child.id)
            mapped.push(mapOrgChartNode(child, node))
          }

          for (const child of prev) {
            if (!exists.has(child.id)) {
              next.push(child)
            }
          }

          return next.concat(mapped)
        })
      },
      error => {
        console.error(error)

        const msg = `Failed to retrieve subordinates for ${node.title}`
        enqueueSnackbar(msg, {variant: 'error'})
      }
    )
  }

  const collapseNode = node => {
    if (!node.children || node.children.length < 1) {
      return
    }

    const removals = new Set()
    removals.add(node.id)

    // NOTE(intrnl): working around an issue with react-zoom-pan-pinch where
    // collapsing the top-most node results in the node being out of bounds.
    if (node.parent === null) {
      const instance = instanceRef.current

      requestAnimationFrame(() => {
        instance.resetTransform()
      })
    }

    setListing(prev => {
      const next = []

      for (const item of prev) {
        if (removals.has(item.parent)) {
          removals.add(item.id)
          continue
        }

        next.push(item)
      }

      return next.length === prev.length ? prev : next
    })
  }

  const renderNode = React.useCallback(
    node => {
      return (
        <OUNode
          data={node.data}
          staff={node.staff}
          strokeStyle={node.strokeStyle}
          hasChildren={!!node.children?.length}
          organizationLevel={organizationLevel}
          onExpand={() => expandNode(node)}
          onCollapse={() => collapseNode(node)}
          onMenu={ev => handleNodeMenu(node, ev)}
        />
      )
    },
    [organizationId]
  )

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <AppBar className={styles.toolbar}>
        <Toolbar>
          <div className={styles.toolbarTitle}>
            <Typography>{organizationName} Details</Typography>
            {organizationLevel && (
              <Typography variant="subtitle2" className={styles.toolbarLevel}>
                <div
                  className={styles.toolbarLevelColor}
                  style={{backgroundColor: organizationLevel.color_hex}}
                />
                {organizationLevel.name}
              </Typography>
            )}
          </div>
          <IconButton
            onClick={onClose}
            title="Close dialog"
            edge="end"
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {error ? (
        <div className={styles.container}>
          <div>Something went wrong when retrieving organization details</div>
        </div>
      ) : loading || !tree[0] ? (
        <div className={styles.container}>
          <CircularProgress style={{placeSelf: 'center'}} />
        </div>
      ) : (
        <>
          <TransformWrapper
            options={{
              // NOTE(intrnl): this is about the limit of the orgchart, any less
              // and the line path drawing will break
              minScale: 0.75,
              maxScale: 2,
            }}
          >
            {instance => (
              <div className={styles.container}>
                {/* NOTE(intrnl): This seems to be the only best way of retrieving the transform's instance */}
                {((instanceRef.current = instance), null)}

                <TransformComponent>
                  <OrgChart
                    data={tree[0]}
                    render={renderNode}
                    chartId="ouChart"
                  />
                </TransformComponent>

                <ButtonGroup
                  variant="contained"
                  color="primary"
                  className={styles.zoomContainer}
                >
                  <Button onClick={instance.zoomIn}>+</Button>
                  <Button onClick={instance.zoomOut}>-</Button>
                </ButtonGroup>
              </div>
            )}
          </TransformWrapper>

          {selected && (
            <Menu
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleSetAssistant(!selected.staff)}>
                {selected.staff ? 'Unset' : 'Set'} as {selected.parentNode.name}{' '}
                assistant
              </MenuItem>

              {selected.strokeStyle === 'solid' ? (
                <MenuItem onClick={() => handleSetStrokeStyle(2)}>
                  Change the line type to Dot
                </MenuItem>
              ) : (
                <MenuItem onClick={() => handleSetStrokeStyle(1)}>
                  Change the line type to Default
                </MenuItem>
              )}
            </Menu>
          )}
        </>
      )}
    </Dialog>
  )
}

export default OUDetailsModal

const DEFAULT_VISIBLE_PLACEMENTS = 5
const DEFAULT_LEVEL_COLOR = '#e5e5e5'

// <OUNode />
const isOUNodeEqual = (a, b) => {
  return (
    a.data.id === b.data.id &&
    a.hasChildren === b.hasChildren &&
    a.staff === b.staff &&
    a.strokeStyle === b.strokeStyle
  )
}

const OUNode = React.memo(props => {
  const {
    data,
    hasChildren,
    organizationLevel,
    onExpand,
    onCollapse,
    onMenu,
  } = props

  const styles = useStyles()
  const [visible, setVisible] = React.useState(DEFAULT_VISIBLE_PLACEMENTS)

  const rank = data.company_employee_rank
  const placements = data.people_work_placements
  const length = placements.length

  const canSeeMore = visible < length
  const canSeeLess = visible > DEFAULT_VISIBLE_PLACEMENTS

  const handleArrowClick = () => {
    if (hasChildren) {
      onCollapse(data)
    } else {
      onExpand(data)
    }
  }

  const handleSeeMore = ev => {
    ev.preventDefault()

    const next = visible + 5
    setVisible(Math.min(next, length))
  }

  const handleSeeLess = ev => {
    ev.preventDefault()

    setVisible(DEFAULT_VISIBLE_PLACEMENTS)
  }

  return (
    <div className={styles.wrapper}>
      <Paper
        className={styles.card}
        style={{
          borderStyle: 'solid',
          borderColor: organizationLevel?.color_hex || DEFAULT_LEVEL_COLOR,
          width: '15em',
        }}
      >
        <div className={styles.header}>
          <div className={styles.column}>
            <b className={styles.title}>{data.title}</b>
            <div className={styles.legend}>
              <div
                className={styles.legendColor}
                style={{
                  backgroundColor: rank?.color_hex || DEFAULT_LEVEL_COLOR,
                }}
              />
              {rank ? (
                <span className={styles.legendTitle}>{rank.name}</span>
              ) : (
                <span className={styles.legendTitle} style={{color: '#747272'}}>
                  None
                </span>
              )}
            </div>
          </div>

          {data.parent && onMenu && (
            <IconButton size="small" onClick={onMenu}>
              <MoreHorizIcon color="primary" />
            </IconButton>
          )}
        </div>

        <div className={styles.list}>
          {placements.length > 0 ? (
            placements.slice(0, visible).map(placement => {
              return (
                <div key={placement.id} className={styles.item}>
                  <Avatar
                    className={styles.itemAvatar}
                    src={placement.global_user.avatar || DefaultAvatar}
                  />

                  <span className={styles.itemName}>
                    {placement.global_user.name}
                  </span>
                </div>
              )
            })
          ) : (
            <div className={styles.item}>
              <Avatar src={DefaultAvatar} className={styles.itemAvatar} />
              <span className={styles.itemName}>{'Unassigned'}</span>
            </div>
          )}

          {(canSeeMore || canSeeLess) && (
            <div className={styles.item}>
              {canSeeMore && (
                <Link
                  component="button"
                  color="secondary"
                  className={styles.link}
                  onClick={handleSeeMore}
                >
                  +{length - visible} more
                </Link>
              )}

              <span className={styles.spacer} />

              {canSeeLess && (
                <Link
                  component="button"
                  color="secondary"
                  className={styles.link}
                  onClick={handleSeeLess}
                >
                  See less
                </Link>
              )}
            </div>
          )}
        </div>

        {data.job_profile_children_aggregate.aggregate.count > 0 && (
          <Button className={styles.more} onClick={handleArrowClick}>
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{transform: hasChildren ? 'rotate(180deg)' : ''}}
              title={`Show ${hasChildren ? 'less' : 'more'}`}
            />
          </Button>
        )}
      </Paper>
    </div>
  )
}, isOUNodeEqual)
