import React from 'react'
import {TextBlackBold, WrapperChoosePeriode} from '../../../GlobalStyles'
import {
  Radio,
  Box,
  Button,
  Icon,
  IconButton,
  Dialog,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

export default function ChoosePeriodPopup(props) {
  const {
    open,
    onClose,
    onConfirm,
    enableFuture,
    enableToday,
    custom = false,
  } = props

  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [customPeriod, setCustomPeriod] = React.useState('all')

  const maxDate = React.useMemo(() => {
    if (enableFuture) {
      return null
    }

    const date = new Date()

    if (!enableToday) {
      date.setDate(date.getDate() - 1)
    }

    date.setHours(0, 0, 0, 0)

    return date
  }, [enableFuture, enableToday])

  const handleStartDateChange = date => {
    setStartDate(date.setHours(0, 0, 0, 0))
  }

  const handleEndDateChange = date => {
    setEndDate(date.setHours(23, 59, 59, 999))
  }

  const reset = (isActionBtn = false) => {
    setStartDate(null)
    setEndDate(null)
    isActionBtn && setCustomPeriod('all')
  }

  const handleSubmit = () => {
    if (onConfirm) {
      onConfirm({startDate, endDate})
    }

    reset(true)
  }

  const handleCancel = () => {
    onClose()
    reset(true)
  }

  const handleChange = event => {
    setCustomPeriod(event.target.value)
    if (event.target.value === 'all') {
      reset()
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <WrapperChoosePeriode>
        <div className="header">
          <h1 className="title">{custom ? 'Buat Laporan' : 'Pilih Periode'}</h1>
          <IconButton
            aria-label="close"
            className="wrap-close"
            onClick={onClose}
          >
            <Icon className="icon-close">close</Icon>
          </IconButton>
        </div>
        <div className="body">
          <TextBlackBold className="title-period">
            Pilih Periode Laporan*
          </TextBlackBold>
          {custom && (
            <RadioGroup
              aria-label="gender"
              name="custom-period"
              value={customPeriod}
              onChange={handleChange}
              className="radio-custom"
            >
              <FormControlLabel
                value="all"
                control={<Radio color="primary" />}
                label="Semua Tanggal"
              />
              <FormControlLabel
                value="custom"
                control={<Radio color="primary" />}
                label="Kostumisasi Periode"
              />
            </RadioGroup>
          )}

          {(!custom || customPeriod === 'custom') && (
            <Box display="flex">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={startDate}
                  onChange={handleStartDateChange}
                  placeholder="Pilih Tanggal Mulai"
                  inputVariant="outlined"
                  maxDate={maxDate || undefined}
                  format="MMM dd, yyyy"
                  style={{marginRight: 16}}
                />
                <KeyboardDatePicker
                  value={endDate}
                  onChange={handleEndDateChange}
                  placeholder="Pilih Tanggal Selesai"
                  inputVariant="outlined"
                  minDate={startDate || undefined}
                  minDateMessage="Date should not be before start date"
                  maxDate={maxDate || undefined}
                  format="MMM dd, yyyy"
                />
              </MuiPickersUtilsProvider>
            </Box>
          )}

          <p className="text-alert-notes">
            Catatan : Pembuatan Data akan memakan waktu yang cukup lama dan
            hanya bisa diselesaikan dalam waktu sekali 24 jam. Pastikan anda
            memilih periode yang tepat.
          </p>
        </div>
        <div className="action-wrapper-modal-confirmation">
          <Button onClick={handleCancel} size="large">
            Batalkan
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={
              (custom && customPeriod === 'custom') || !custom
                ? !startDate || !endDate || startDate > endDate
                : false
            }
            color="primary"
            size="large"
            variant="contained"
            style={{marginLeft: 12}}
          >
            Buat
          </Button>
        </div>
      </WrapperChoosePeriode>
    </Dialog>
  )
}
