import gql from 'graphql-tag'

export const ADD_HOLIDAY = gql`
  mutation addHoliday($data: [time_holidays_insert_input!]!) {
    insert_time_holidays(objects: $data) {
      affected_rows
    }
  }
`

export const EDIT_COMPANY_HOLIDAY = gql`
  mutation(
    $id: Int
    $name: String
    $type: String
    $date: date
    $description: String
    $leave_policy_id: Int
  ) {
    update_time_holidays(
      _set: {
        name: $name
        type: $type
        date: $date
        description: $description
        leave_policy_id: $leave_policy_id
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
