import gql from 'graphql-tag'

export const GET_LIST_FACILITY = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: company_facilities_aggregate(where: {name: {_ilike: $search}}) {
      aggregate {
        count
      }
    }

    company_facilities(
      where: {name: {_ilike: $search}}
      offset: $offset
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`
