import gql from 'graphql-tag'

export const GET_CLAIM_DETAILS = gql`
  query($requestID: bigint!) {
    details: people_work_claims_by_pk(id: $requestID) {
      id
      formatted_id
      status
      date_added
      date_issued
      description
      nominal: value
      attachments
      proofs
      claim_fields
      approved_by
      employee: people_work_placement {
        id
        regno
        user: global_user {
          id
          name
        }
        profile: company_job_profile {
          id
          title
          supervisor: job_profile_supervisor {
            id
            title
            placements: people_work_placements(
              where: {status: {_eq: "ACTIVE"}}
              limit: 1
            ) {
              id
              user: global_user {
                id
                name
              }
            }
            supervisor: job_profile_supervisor {
              id
              title
              placements: people_work_placements(
                where: {status: {_eq: "ACTIVE"}}
                limit: 1
              ) {
                id
                user: global_user {
                  id
                  name
                }
              }
            }
          }
        }
      }
      policy: finance_claim_policy_child {
        id
        name
        confirm_type
        first_job_profile {
          id
          title
        }
        second_job_profile {
          id
          title
        }
      }
      invoice: global_invoice {
        id
        code
        final_price
        module_name
      }
      bank: people_profile_bank {
        account_number
        account_name
        provider: global_bank {
          name
        }
      }
    }
  }
`

export const GET_CLAIM_INVOICE_EXPORTS = gql`
  query($invoiceId: uuid!) {
    invoice_exports: global_invoice_exports(
      where: {invoice_id: {_eq: $invoiceId}}
    ) {
      id
      invoice_id
      url
    }
  }
`

export const GET_CLAIM_USER_DETAILS = gql`
  query($userId: uuid!, $positionId: Int!) {
    user: global_users_by_pk(id: $userId) {
      id
      name
    }
    position: company_job_profiles_by_pk(id: $positionId) {
      id
      title
    }
  }
`
