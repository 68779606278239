import {
  Button,
  Divider,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState, useEffect} from 'react'
import {
  RootContainer,
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  Flex,
} from '../../../../GlobalStyles'
import {TextGreyBold} from '../../../company/office/OfficePageStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Link, withRouter} from 'react-router-dom'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import {useQuery, useMutation, useApolloClient} from '@apollo/react-hooks'
import {
  GET_COMPETENCY_DICTIONARIES,
  GET_BALOON_STATUS_BUTTON_QUERY,
} from '../../../../graphql/queries'
import {
  CHECK_DELETE_LIST_OF_COMP,
  DELETE_LIST_OF_COMP,
  IMPORT_DATA,
  CLOSE_BALOON,
} from '../../../../graphql/mutations'

import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import ImportBaloon from '../../../../components/importExcel/ImportBaloon'
import {COMPANY_ID} from '../../../../utils/globals'
import {useSnackbar} from 'notistack'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import ModalUnableDelete from '../ModalUnableDelete'
import {competencyOptions} from '../CompetencyPageStyles'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
}))

const ListOfCompetence = props => {
  const {search} = props
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const client = useApolloClient()

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })
  const [openImport, setImport] = useState(false)
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [valueDelete, setValueDelete] = useState({
    id: 0,
    name: '',
  })

  const {data, error, loading, refetch} = useQuery(
    GET_COMPETENCY_DICTIONARIES,
    {
      variables: {
        offset: pagination.offset * pagination.limit,
        limit: pagination.limit,
        query: `%${search}%`,
      },
    }
  )

  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    refetch: refetchBaloonStatus,
    // loading: loadingGetBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON_QUERY, {
    variables: {
      company: COMPANY_ID,
      table: 'competency_dictionaries',
    },
  })

  const [deleteCompetency] = useMutation(DELETE_LIST_OF_COMP)

  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )

  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )

  const handleDelete = row => {
    setOpenDelete(true)
    setValueDelete({
      id: row.id,
      name: row.name.en,
    })
  }

  const mutationDelete = async () => {
    await client
      .query({
        query: CHECK_DELETE_LIST_OF_COMP,
        variables: {id: valueDelete.id},
      })
      .then(res => {
        if (
          res.data.competency_version_dictionaries_aggregate.aggregate.count > 0
        ) {
          setOpen(true)
        } else {
          deleteCompetency({variables: {id: valueDelete.id}})
            .then(() => {
              refetch()
              setTimeout(() => {
                enqueueSnackbar(`${valueDelete.name} removed`, {
                  variant: 'success',
                  autoHideDuration: 3000,
                })
              }, 3000)
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e), {
                variant: 'error',
                autoHideDuration: 3000,
              })
            })
            .finally(() => {
              setValueDelete({
                id: 0,
                name: '',
              })
            })
        }
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleClickOpenImport = () => {
    setImport(true)
  }

  const handleCloseImport = () => {
    setImport(false)
  }

  const importMutation = async () => {
    try {
      await mutationImport({
        variables: {
          table: 'competency_dictionaries',
          file: fileURL,
        },
      })
      // eslint-disable-next-line no-console
      refetchBaloonStatus()
      refetch()
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  }

  useEffect(() => {
    refetch()
  }, [])

  if (loading || !data || !getBaloonStatus) {
    return <CircularProgress />
  } else if (
    error ||
    mutationCloseBaloonError ||
    errorGetBaloonStatus ||
    mutationImportError
  ) {
    return (
      <div>
        {JSON.stringify(
          error ||
            mutationCloseBaloonError ||
            errorGetBaloonStatus ||
            mutationImportError
        )}
      </div>
    )
  }

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  return (
    <RootContainer>
      <ImportBaloon
        data={getBaloonStatus}
        closeBaloonMutation={closeBaloonMutation}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
        refetchBaloonStatus={refetchBaloonStatus}
      />
      <PaperContainer>
        <EnhancedToolbar>
          <Typography variant="h6" color="primary" style={{paddingLeft: 10}}>
            Daftar Kompetensi
          </Typography>
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              className={classes.backButton}
              onClick={() => handleClickOpenImport()}
              disabled={
                getBaloonStatus?.company_import_data_logs.length > 0 &&
                getBaloonStatus?.company_import_data_logs[0].status ===
                  'WAITING'
              }
            >
              {getBaloonStatus?.company_import_data_logs.length > 0 &&
              getBaloonStatus?.company_import_data_logs[0].status ===
                'WAITING' ? (
                <TextGreyBold>Importing on proces...</TextGreyBold>
              ) : (
                <>Masukkan Data</>
              )}
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={{
                pathname: '/learning/competency/list-of-competency/add',
                state: {
                  active: 'list-of-competency',
                },
              }}
            >
              Tambah Kompetensi
            </Button>
          </div>
        </EnhancedToolbar>
        <Divider></Divider>
        <MaterialTable
          style={{
            padding: '1.6rem 1.6rem 0',
          }}
          localization={{
            pagination: TableLocalization.pagination,
            body: {
              emptyDataSourceMessage: <NoDataListComponent search={search} />,
            },
          }}
          columns={[
            {
              title: 'No',
              field: 'competency_category.name',
              render: rowData => `${rowData.tableData.id + 1}.`,
            },
            {
              title: 'Kategori Kompentensi',
              field: 'competency_category.name',
            },
            {
              title: 'Kode Kompetensi',
              field: 'competency_category.code',
            },
            {
              title: 'Nama Kompentensi',
              field: 'name.id',
            },
            {
              title: 'Keterangan',
              field: 'description',
              render: row => (
                <Flex
                  style={{
                    width: 550,
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 5,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                >
                  {row?.description?.text}
                </Flex>
              ),
            },
            {
              title: 'Tipe',
              field: 'type',
              render: rowData =>
                `${rowData.type === 'level' ? 'Level' : 'Non Level'}`,
            },
            {
              title: 'Aksi',
              field: 'id',
              sorting: false,
              render: rowData => (
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <>
                      <MoreIcon {...bindTrigger(popupState)}>
                        more_horiz
                      </MoreIcon>

                      <StyledMenus {...bindMenu(popupState)}>
                        <StyledMenuItem
                          onClick={() => {
                            popupState.close()
                          }}
                          component={Link}
                          to={{
                            pathname: `/learning/competency/list-of-competency/${rowData.id}`,
                            state: {
                              data: rowData,
                              active: 'list-of-competency',
                            },
                          }}
                          fontColor="#000"
                        >
                          Rincian
                        </StyledMenuItem>
                        <StyledMenuItem
                          onClick={() => {
                            popupState.close()
                          }}
                          component={Link}
                          to={{
                            pathname: `/learning/competency/list-of-competency/edit/${rowData.id}`,
                            state: {
                              data: rowData,
                              active: 'list-of-competency',
                            },
                          }}
                          fontColor="#000"
                        >
                          Ubah
                        </StyledMenuItem>
                        <StyledMenuItem
                          onClick={() => {
                            popupState.close()
                            handleDelete(rowData)
                          }}
                          style={{color: '#ef4d5e'}}
                        >
                          Hapus
                        </StyledMenuItem>
                      </StyledMenus>
                    </>
                  )}
                </PopupState>
              ),
            },
          ]}
          data={(data && data.competency_dictionaries) || []}
          title=""
          options={{
            ...TableOptions,
            ...competencyOptions,
          }}
          components={{
            Toolbar: () => null,
          }}
          isLoading={loading || (!data && !error)}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={data?.competency_dictionaries_aggregate?.aggregate?.count}
          page={pagination.offset}
          pageSize={pagination.limit}
        />
        <ImportPopup
          open={openImport}
          position="Competency"
          handleClose={handleCloseImport}
          mutation={importMutation}
          fileTemplate={''}
          isAlreadyImported={isAlreadyImported}
          setIsAlreadyImported={setIsAlreadyImported}
          setImport={setImport}
          fileURL={fileURL}
          setFileURL={setFileURL}
          closeBaloon={closeBaloonMutation}
          errorsImport={
            getBaloonStatus.company_import_data_logs.length > 0 &&
            getBaloonStatus.company_import_data_logs[0].reports &&
            getBaloonStatus.company_import_data_logs[0].reports.failed.length >
              0
              ? getBaloonStatus.company_import_data_logs[0].reports.failed
              : []
          }
        />
        <DeletePopup
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          mutation={mutationDelete}
          id={valueDelete.id}
          name={valueDelete.name}
        />
        <ModalUnableDelete
          open={open}
          onClose={() => setOpen(false)}
          title="Competency"
          name={valueDelete.name}
          because="currently being used on Dictionary"
        />
      </PaperContainer>
    </RootContainer>
  )
}

export default withRouter(ListOfCompetence)
