import React, {useState} from 'react'
import {
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
  TextBlackBold,
  FlexBetween,
  TextGrey,
} from '../../../GlobalStyles'

import {
  Dialog,
  Button,
  Typography,
  IconButton,
  DialogActions,
  TextField,
  InputAdornment,
  Divider,
  FormControlLabel,
  Checkbox,
  Avatar,
  makeStyles,
} from '@material-ui/core'
import Search from '@material-ui/icons/Search'
import classNames from 'classnames'
import {LIST_FILTER_GENERATE_COMPANY} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {holdingRolesContext} from '../../../utils/helpers'

const generateStyle = makeStyles(() => ({
  title: {color: '#ffffff', alignSelf: 'center', fontSize: 18},
  subTitle: {fontSize: 14, marginLeft: 3},
  iconSearch: {
    color: '#014a62',
    width: 20,
    height: 20,
    marginRight: 10,
  },
  button: {fontSize: 14, width: 130},
  greyColor: {color: '#A9A8A8'},
  mt15: {
    marginTop: 15,
  },
  ml10: {
    marginLeft: 10,
  },
  ml12: {
    marginLeft: 12,
  },
  ml3: {
    marginLeft: 3,
  },
  note: {
    fontSize: 12,
    color: '#FFA000',
    marginLeft: 3,
    marginTop: 15,
  },
  alert: {
    fontSize: 12,
    color: '#f44336',
    marginLeft: 3,
    marginTop: 15,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  checkBoxStart: {
    marginTop: 15,
    marginRight: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkBoxEnd: {
    width: '100%',
    marginTop: 15,
    marginRight: 0,
  },
  selectAll: {
    fontWeight: 600,
    fontSize: 14,
    marginLeft: 3,
  },
  reset: {
    fontSize: 12,
    color: '#A9A8A8',
  },
}))

const GenerateReportList = props => {
  const classes = generateStyle()
  const client = useApolloClient()
  const {
    title,
    subTitle,
    note,
    open,
    handleClose,
    handleConfirm,
    isSelectAll = true,
    labelCheckbox = 'end',
  } = props
  const [search, setSearch] = useState('')
  const [selectedData, setSelectedData] = useState([])
  const [dataFilter, setDataFilter] = useState([])
  const [isError, setIsError] = useState(false)

  const {data: dataSelected} = useQuery(LIST_FILTER_GENERATE_COMPANY, {
    variables: {
      company: COMPANY_ID,
      limit: 6,
      in: selectedData,
    },
    skip: !open,
    ...holdingRolesContext,
  })

  const {data: dataUnSelected} = useQuery(LIST_FILTER_GENERATE_COMPANY, {
    variables: {
      company: COMPANY_ID,
      search: `%${search}%`,
      limit: 6,
      nin: selectedData,
    },
    skip: !open,
    ...holdingRolesContext,
  })

  const countSelected = dataSelected?.total?.aggregate?.count ?? 0
  const countUnSelected = dataUnSelected?.total?.aggregate?.count ?? 0

  const CompanySection = data => {
    return (
      <FlexBetween>
        <Avatar
          variant="rounded"
          alt="alternative"
          src={data?.company_member_profile?.logo}
        />
        <div className={classes.ml10}>
          <TextBlackBold>
            {data?.company_member_profile?.brand_name}
          </TextBlackBold>
          <TextGrey>{data?.company_member_profile?.legal_name}</TextGrey>
        </div>
      </FlexBetween>
    )
  }

  const CheckboxItem = ({label, name, onChange, value}) => {
    return (
      <FormControlLabel
        className={
          labelCheckbox === 'start'
            ? classes.checkBoxStart
            : classes.checkBoxEnd
        }
        control={
          <Checkbox
            checked={selectedData.includes(label.id)}
            color="primary"
            onChange={onChange}
            value={value}
            name={name}
            size="large"
          />
        }
        label={CompanySection(label)}
        labelPlacement={labelCheckbox}
      />
    )
  }

  const handleCheck = res => {
    const _selected = [...selectedData, res.id]
    setSelectedData(_selected)

    setDataFilter([...dataFilter, res?.company_member_profile?.id])
  }

  const handleUnCheck = (event, checked, dataCheckBox) => {
    event.preventDefault()
    if (!checked) {
      const notSelected = selectedData.filter(notSelect => {
        return notSelect !== dataCheckBox.id
      })
      setSelectedData(notSelected)

      const _dataFilter = dataFilter.filter(dataFilters => {
        return dataFilters !== dataCheckBox.company_member_profile.id
      })

      setDataFilter(_dataFilter)
    }
  }

  const handleSelectAll = async () => {
    const {data} = await client.query({
      query: LIST_FILTER_GENERATE_COMPANY,
      variables: {company: COMPANY_ID},
      context: holdingRolesContext.context,
    })

    const tempAllCheckbox = data?.holding_relations?.map(company => {
      return company?.id
    })

    const tempAllData = data?.holding_relations?.map(company => {
      return company?.company_member_profile?.id
    })

    setSelectedData(tempAllCheckbox)
    setDataFilter(tempAllData)
  }

  const handleReset = () => {
    setSelectedData([])
    setDataFilter([])
  }

  const handleCancel = () => {
    handleClose()
    handleReset()
  }

  const handleGenerate = () => {
    if (dataFilter.length > 0) {
      handleConfirm(dataFilter)
      handleClose()
      handleReset()
    } else {
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 3000)
    }
  }

  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <ModalHeader>
        <div style={{display: 'flex'}}>
          <Typography className={classes.title}>{title}</Typography>
        </div>
        <IconButton aria-label="close" onClick={handleClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContentEvent>
        <Typography className={classes.subTitle}>{subTitle || ''}</Typography>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          placeholder="Search"
          onChange={event => setSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment className="ml-0" position="start">
                <Search className={classes.iconSearch} />
              </InputAdornment>
            ),
          }}
        />

        {isSelectAll && (
          <FlexBetween>
            <Typography
              className={classNames(classes.selectAll, classes.cursorPointer)}
              onClick={handleSelectAll}
            >
              Select All
            </Typography>
            <Typography
              className={classNames(classes.reset, classes.cursorPointer)}
              onClick={handleReset}
            >
              Reset
            </Typography>
          </FlexBetween>
        )}

        {dataSelected?.holding_relations?.map((res, index) => {
          return (
            <CheckboxItem
              key={index}
              label={res}
              name="selected"
              onChange={(e, check) => {
                handleUnCheck(e, check, res)
              }}
              value={res.id}
            />
          )
        })}
        {countSelected > 6 && (
          <Typography
            className={classNames(classes.reset, classes.mt15, classes.ml3)}
          >
            {`and ${countSelected - 6} hidden items`}
          </Typography>
        )}

        {countSelected > 0 && countUnSelected > 0 && (
          <Divider className={classes.mt15} />
        )}

        {dataUnSelected?.holding_relations?.map((res, index) => {
          return (
            <CheckboxItem
              key={index}
              label={res}
              name="unSelected"
              onChange={() => {
                handleCheck(res)
              }}
              value={res.id}
            />
          )
        })}
        {countUnSelected > 6 && (
          <Typography
            className={classNames(classes.reset, classes.mt15, classes.ml3)}
          >
            {`and ${countUnSelected - 6} hidden items`}
          </Typography>
        )}

        {note && <Typography className={classes.note}>{note}</Typography>}
        {isError && (
          <Typography className={classes.alert}>
            Please select company
          </Typography>
        )}
      </ModalContentEvent>
      <DialogActions>
        <Button
          className={classNames(classes.button, classes.greyColor)}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="large"
          className={classNames(classes.button, classes.ml12)}
          variant="contained"
          onClick={handleGenerate}
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GenerateReportList
