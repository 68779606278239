import React, {useState} from 'react'
import {Button, IconButton, Divider, makeStyles} from '@material-ui/core'
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
  FormContainer,
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
  StyledReactSelect,
} from '../../../../GlobalStyles'
import {withRouter} from 'react-router-dom'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {GET_COMPETENCY_DICTIONARIES} from '../../../../graphql/queries'
import {ADD_COMPETENCY_IN_VERSION} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import Loading from '../../../../components/loading/LoadingComponent'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  textRed: {
    color: '#FF0000',
    fontSize: '12px',
  },
}))

const AddCompetencyComponent = props => {
  const classes = useStyles()
  const {id} = props.match.params
  const {enqueueSnackbar} = useSnackbar()

  const [open, setOpen] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [searchDropdown, setSearchDropdown] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [value, setValue] = useState({
    version: parseInt(id),
    competency: null,
  })

  const [addCompetency] = useMutation(ADD_COMPETENCY_IN_VERSION)

  const {data, error, loading} = useQuery(GET_COMPETENCY_DICTIONARIES, {
    variables: {
      offset: 0,
      limit: 20,
      query: `%${searchDropdown}%`,
      version: parseInt(id),
    },
  })

  const handleConfirm = type => {
    setOpen(true)
    setConfirmType(type)
  }

  const goBack = () => {
    return props.history.goBack()
  }

  const handleSave = () => {
    if (!value.version || !value.competency) {
      setIsError(true)
    } else {
      const variables = {
        objects: [{version: value.version, competency: value.competency.value}],
      }

      setIsLoading(true)
      addCompetency({variables})
        .then(() => {
          goBack()
          enqueueSnackbar('Competency Submited', {
            variant: 'success',
          })
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleChange = val => {
    setValue({
      ...value,
      competency: val,
    })
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const competencies = data?.competency_dictionaries.map(res => {
    return {value: res.id, label: res.name.en}
  })

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handleConfirm('discard')}
          >
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6">Add Competency</AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            className={classes.backButton}
            onClick={() => handleConfirm('discard')}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirm('submit')}
            size="large"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </div>
      </FormToolbar>
      <Divider />
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle style={{marginBottom: '10px'}}>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Competency Name*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <StyledReactSelect
              value={value.competency}
              options={competencies}
              onChange={handleChange}
              placeholder="Add Competency Name"
              isLoading={loading}
              onInputChange={d => setSearchDropdown(d)}
            />
            {isError && !value.competency && (
              <span className={classes.textRed}>This field is required</span>
            )}
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>

      <AddEdit
        open={open}
        handleClose={() => setOpen(false)}
        feature={`Competency`}
        type={confirmType}
        mutation={confirmType === 'discard' ? goBack : handleSave}
        disabled={isLoading}
      />
    </PaperContainer>
  )
}

export default withRouter(AddCompetencyComponent)
