import React from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Divider,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Icon,
  CircularProgress,
} from '@material-ui/core'

import {PaperContainer} from '../../../../../GlobalStyles'
import {BILLING_CATEGORY_COLOR, InfoTable} from '../../ClaimLimitPageStyles'
import CompanyTopupList from './CompanyTopupList'

import {COMPANY_ID} from '../../../../../utils/globals'
import {dateENOrUS, convertToRupiah} from '../../../../../utils/helpers'
import {
  GET_COMPANY_TRANSACTION_DETAILS,
  GET_TOPUP_RECIPIENTS_COUNT,
  GET_TOPUP_RECIPIENTS,
} from '../../../../../graphql/queries/budget/limit/getCompanyBalance.query'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  nominal: {
    fontSize: theme.spacing(2.5),
    fontWeight: '700',
    marginRight: theme.spacing(1),
  },

  title: {
    fontWeight: '700',
  },
  totalPrice: {
    fontWeight: '700',
  },
}))

function CompanyDetails(props) {
  const {transactionID} = props.match.params
  const styles = useStyles()

  return (
    <PaperContainer>
      <Toolbar>
        <IconButton
          aria-label="Go back to previous page"
          onClick={props.history.goBack}
          edge="start"
          className={styles.backButton}
        >
          <Icon>arrow_back</Icon>
        </IconButton>

        <Typography variant="subtitle1" style={{fontWeight: '600'}}>
          Transaction Detail
        </Typography>
      </Toolbar>

      <Divider />

      <TransactionDetails transactionID={transactionID} />
    </PaperContainer>
  )
}

export default withRouter(CompanyDetails)

function TransactionDetails(props) {
  const {transactionID} = props

  const styles = useStyles()

  const {data, loading, error} = useQuery(GET_COMPANY_TRANSACTION_DETAILS, {
    wlb_skipPatch: true,
    variables: {transactionID: transactionID, companyID: COMPANY_ID},
  })

  const details = data?.details
  const category = details?.category
  const isWithdraw = category === 'Out'

  const formattedDate = React.useMemo(() => dateENOrUS(data?.details.date), [
    data?.details.date,
  ])

  const formattedNominal = React.useMemo(
    () => convertToRupiah(data?.details.nominal),
    [data?.details.nominal]
  )

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  return (
    <>
      <Box display="flex" flexDirection="column" p={3}>
        <Typography style={{fontWeight: '700'}}>
          Nominal {details.type}
        </Typography>
        <Box display="flex" alignItems="center">
          <Icon
            className={styles.nominal}
            style={{color: BILLING_CATEGORY_COLOR[category]}}
          >
            {isWithdraw ? 'arrow_upward' : 'arrow_downward'}
          </Icon>
          <Typography
            className={styles.nominal}
            style={{color: BILLING_CATEGORY_COLOR[category]}}
          >
            {formattedNominal}
          </Typography>
          {isWithdraw && <TopUpRecipientsCount transactionID={details.id} />}
        </Box>
      </Box>

      <Divider />

      <Box display="flex" flexDirection="column" p={2}>
        <InfoTable>
          <tbody>
            <tr>
              <th>Transaction ID</th>
              <td>{details.formatted_id}</td>
            </tr>
            <tr>
              <th>Transaction Date</th>
              <td>{formattedDate}</td>
            </tr>
            <tr>
              <th>Category</th>
              <td style={{color: BILLING_CATEGORY_COLOR[category]}}>
                {category}
              </td>
            </tr>
            <tr>
              <th>Type</th>
              <td>{details.type}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{details.description || <i>No description provided</i>}</td>
            </tr>
          </tbody>
        </InfoTable>
      </Box>

      {isWithdraw && (
        <>
          <Divider />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={3}
            height={48}
          >
            <Typography className={styles.title}>Top Up List</Typography>

            <Typography className={styles.totalPrice}>
              Total: {formattedNominal}
            </Typography>
          </Box>

          <TopUpRecipientsList transactionID={details.id} />
        </>
      )}
    </>
  )
}

function TopUpRecipientsCount(props) {
  const {transactionID} = props

  const {data, loading, error} = useQuery(GET_TOPUP_RECIPIENTS_COUNT, {
    wlb_skipPatch: true,
    variables: {transactionID: transactionID},
  })

  if (loading || error) {
    return null
  }

  const count = data.total.aggregate.count

  return <Typography>({count || 0} transactions)</Typography>
}

function TopUpRecipientsList(props) {
  const {transactionID} = props

  const [pagination, setPagination] = React.useState({
    offset: 0,
    limit: 20,
  })

  const {data, loading, error} = useQuery(GET_TOPUP_RECIPIENTS, {
    wlb_skipPatch: true,
    variables: {
      transactionID: transactionID,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const handleChangeOffset = offset => {
    setPagination({...pagination, offset})
  }

  const handleChangeLimit = limit => {
    setPagination({...pagination, offset: 0, limit})
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  return (
    <CompanyTopupList
      data={data?.transactions || []}
      totalCount={data?.total.aggregate.count || 0}
      loading={loading}
      offset={pagination.offset}
      limit={pagination.limit}
      onChangeOffset={handleChangeOffset}
      onChangeLimit={handleChangeLimit}
    />
  )
}
