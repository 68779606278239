import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {
  Avatar,
  Button,
  Divider,
  Icon,
  makeStyles,
  Paper,
} from '@material-ui/core'
import {AvatarGroup} from '@material-ui/lab'
import uniqBy from 'lodash/uniqBy'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import Loading from '../../../components/loading/LoadingComponent'
import {BoldTypography} from '../../../components/typography/TypographyStyles'
import {GreyIcon, GreyTypography, SmallAvatar} from '../../../GlobalStyles'
import {GET_COMPANY_STRUCTURE_HOLDING} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'
import './Structure.css'
import CompanyStructure from './tree/CompanyStructureComponent'
import DetailNode from './tree/detail/DetailNodeComponent'
import IndustryStructure from './tree/IndustryStructureComponent'

const useStyles = makeStyles(() => ({
  root: {
    padding: 26,
    background: '#f7f8f9',
    minHeight: '70vh',
    width: '-webkit-fill-available',
  },
  mr18: {marginRight: 18},
  shadow: {boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)'},
  wrapperButton: {display: 'flex'},
  little: {minWidth: 46, background: 'white'},
  structureWrapper: {marginTop: 40},
  paperStructure: {padding: 15, width: 200, margin: 'auto'},
  divider: {margin: '15px -15px 0!important'},
  companyInfo: {padding: '14px 0', display: 'flex', alignItems: 'center'},
  agroupRoot: {margin: '0 14px 0 6px'},
  divCategory: {
    width: 116,
    height: 25,
    borderRadius: 3,
    backgroundColor: '#EFF2F4',
    fontSize: 12,
    color: '#000',
    fontWeight: 600,
    lineHeight: 2,
  },
  structureHeader: {textAlign: 'left', cursor: 'pointer'},
  wrapperCategory: {display: 'flex', textAlign: 'center', marginTop: 26},
  paddingWrapp: {padding: '26px 32px'},
  btnZoom: {
    position: 'absolute',
    top: '7rem',
    zIndex: 1,
    right: '3%',
  },
  bgPrimary: {backgroundColor: '#014a62!important', color: '#fff!important'},
  bgSecondary: {backgroundColor: '#039be5!important', color: '#fff!important'},
}))

const StructureComponent = () => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [tabs, setTabs] = useState(0)
  const [open, setOpen] = useState(false)
  var arrayToTree = require('array-to-tree')
  const [structured, setStructured] = useState([])
  const [chart, setChart] = useState([])
  const [company, setCompany] = useState(COMPANY_ID)
  const [selId, setSelId] = useState(COMPANY_ID)
  const [isHead, setIsHead] = useState(false)
  const [isCluster, setIsCluster] = useState(false)
  const client = useApolloClient()

  const {data, error} = useQuery(GET_COMPANY_STRUCTURE_HOLDING(company), {
    context: {
      headers: {
        'X-Hasura-Role': 'holding-administrator',
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleClickOpen = (id, parent, cluster) => {
    setOpen(true)
    setSelId(id)
    if (!parent) {
      setIsHead(true)
    }
    setIsCluster(cluster)
  }

  const handleClose = isCluster => {
    setOpen(false)
    if (isCluster) {
      setSelId(0)
    } else setSelId(COMPANY_ID)

    setIsHead(false)
  }

  const onExpandNode = async id => {
    setCompany(id)
    const {data: dataLazy} = await client.query({
      query: GET_COMPANY_STRUCTURE_HOLDING(id),
      context: {
        headers: {
          'X-Hasura-Role': 'holding-administrator',
        },
      },
    })
    if (dataLazy && dataLazy.members.length === 0) {
      enqueueSnackbar("This company doesn't have any members", {
        variant: 'error',
        autoHideDuration: 1500,
      })
    }
  }

  const MyNodeComponent = ({node}) => {
    return (
      Object.entries(node).length !== 0 && (
        <div style={{padding: '0 15px'}}>
          <Paper className={classes.paperStructure} style={{paddingBottom: 0}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Avatar
                variant="rounded"
                src={node.logo}
                className={classes.mr18}
              ></Avatar>
              <div
                className={classes.structureHeader}
                onClick={() => handleClickOpen(node.id, node.parent, false)}
              >
                <BoldTypography variant="body1" gutterBottom>
                  {node.brand_name}
                </BoldTypography>
                <GreyTypography variant="body2">
                  {node.legal_name}
                </GreyTypography>
              </div>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.companyInfo}>
              <AvatarGroup className={classes.agroupRoot}>
                {node.people_work_placements_aggregate.aggregate.count > 0 &&
                  node.people_work_placements
                    .slice(0, 5)
                    .map((ava, i) => (
                      <SmallAvatar
                        alt={`${i}`}
                        key={i}
                        src={ava.global_user.avatar}
                      />
                    ))}
              </AvatarGroup>
              <GreyIcon>supervisor_account</GreyIcon>
              <BoldTypography variant="body1">
                {node.people_work_placements_aggregate.aggregate.count}
              </BoldTypography>
            </div>
            <div className={classes.divCategory}>{node.category}</div>
            <Icon
              style={{cursor: 'pointer', marginTop: 10}}
              onClick={() => onExpandNode(node.id)}
            >
              keyboard_arrow_down
            </Icon>
          </Paper>
        </div>
      )
    )
  }

  useEffect(() => {
    if (data) {
      const temp = []
      temp.push({
        ...data.company_head[0],
        category: data.company_head[0].global_company_industry_type.name,
      })
      if (structured.length === 0) {
        if (data.members.length > 0) {
          data.members.forEach(val => {
            temp.push({
              ...val.company_member_profile,
              parent: data.company_head[0].id,
              category: val.holding_cluster && val.holding_cluster.name,
            })
          })
        } else {
          enqueueSnackbar("This company doesn't have any members", {
            variant: 'error',
            autoHideDuration: 4500,
          })
        }

        const structuredData = arrayToTree(temp, {
          parentProperty: 'parent',
          customID: 'id',
        })
        setChart(temp)
        setStructured(structuredData)
      } else {
        if (data.members.length > 0) {
          Array.prototype.push.apply(
            chart,
            data.members.map(val => {
              return {
                ...val.company_member_profile,
                parent: data.company_head[0].id,
                category: val.holding_cluster && val.holding_cluster.name,
              }
            })
          )
          setChart(chart)
          const structuredData = arrayToTree(uniqBy(chart, 'id'), {
            parentProperty: 'parent',
            customID: 'id',
          })
          setStructured(structuredData)
        }
      }
    }
  }, [data])

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapperButton}>
        <Button
          color={tabs === 0 ? 'primary' : 'default'}
          variant={tabs === 0 ? 'contained' : 'text'}
          size="large"
          className={`${classes.shadow} ${classes.mr18}`}
          onClick={() => setTabs(0)}
        >
          Company
        </Button>
        <Button
          size="large"
          color={tabs === 1 ? 'primary' : 'default'}
          variant={tabs === 1 ? 'contained' : 'text'}
          className={classes.shadow}
          onClick={() => setTabs(1)}
        >
          Cluster
        </Button>
      </div>
      <div className={classes.structureWrapper}>
        {tabs === 0 ? (
          <CompanyStructure
            MyNodeComponent={MyNodeComponent}
            structured={structured}
            classes={classes}
          />
        ) : (
          <IndustryStructure
            classes={classes}
            handleClickOpen={handleClickOpen}
          />
        )}
      </div>
      <DetailNode
        open={open}
        handleClose={handleClose}
        classes={classes}
        company={selId}
        isHead={isHead}
        isCluster={isCluster}
      />
    </div>
  )
}

export default StructureComponent
