import gql from 'graphql-tag'

export const ADD_JOB_GRADE = gql`
  mutation($objects: [job_grades_insert_input!]!) {
    insert_job_grades(objects: $objects) {
      affected_rows
    }
  }
`

export const EDIT_JOB_GRADE = gql`
  mutation(
    $id: Int!
    $companyId: uuid!
    $newCode: String
    $newName: String
    $newDescription: String
  ) {
    update_job_grades(
      _set: {code: $newCode, name: $newName, description: $newDescription}
      where: {_and: {id: {_eq: $id}, company: {_eq: $companyId}}}
    ) {
      affected_rows
    }
  }
`

export const DELETE_JOB_GRADE = gql`
  mutation($id: Int!, $companyId: uuid!) {
    update_job_grades(
      _set: {deletedAt: "now()"}
      where: {
        _and: {
          id: {_eq: $id}
          company: {_eq: $companyId}
          deletedAt: {_is_null: true}
        }
      }
    ) {
      affected_rows
    }
  }
`
