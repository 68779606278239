import React, {useEffect, useState} from 'react'
import {useQuery} from '@apollo/react-hooks'
import moment from 'moment'

import DateFnsUtils from '@date-io/date-fns'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {FormControl} from '@material-ui/core'

import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  Flex,
  ThinTextfield,
  TitleName,
  TitleNumber,
} from '../../../../../../GlobalStyles'

import SelectInputGroup from '../../../../../../components/form/SelectInputGroup'
import NumberInputGroup from '../../../../../../components/form/NumberInputGroup'
import FieldInformationTooltip from '../../../../../shared-components/tooltip/FieldInformationTooltip'

import {GET_PEOPLE_PROFILE_IDENTITIES} from '../../../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../../utils'
import {convertToAngka, numberFormatter} from '../../../../../../utils/helpers'
import {getValidate} from '../updateIdentity'

const informationHelperText = {
  ptkp: 'PTKP stand for Penghasilan Tidak Kena Pajak',
  basic_salary: 'Employee base salary before any addition or deduction',
  start_period: 'The beginning of salary period date',
  end_period: 'The end of salary period date',
  npwp: 'Tax identification number',
  ktp: 'Identity card number',
}

export default function BasicSettingForm({
  dataForm,
  enqueueSnackbar,
  isEdit,
  isSubmit,
  pwsData,
  setDataForm,
  userId,
  used,
}) {
  const [identityType, setIdentityType] = useState(null)
  const [skipFetchSyncButton, setSkipFetchSyncButton] = useState(true)

  const {data: synchronized_identity_data} = useQuery(
    GET_PEOPLE_PROFILE_IDENTITIES,
    {
      variables: {
        user_id: userId,
        type: identityType?.toUpperCase(),
      },
      ...GQL_FINANCE_OPTIONS,
      skip: skipFetchSyncButton,
      fetchPolicy: 'cache-and-network',
    }
  )

  useEffect(() => {
    const data = synchronized_identity_data?.people_identities

    if (data?.length > 0 && identityType) {
      setDataForm({
        ...dataForm,
        [identityType]: data?.[0]?.id,
      })
    } else if (data?.length === 0 && identityType) {
      enqueueSnackbar(
        `There is no ${identityType.toUpperCase()} on People Profile`,
        {
          variant: 'warning',
        }
      )
    }

    setSkipFetchSyncButton(true)
    setIdentityType(null)
  }, [synchronized_identity_data])

  const handleChangeDate = (date, name) => {
    setDataForm({
      ...dataForm,
      [name]: moment(date).format('YYYY-MM-DD'),
    })
  }

  const {ktp, npwp} = getValidate(isSubmit, dataForm, used)

  return (
    <>
      <SelectInputGroup
        isFullWidth
        isClearable={false}
        isSearchable={false}
        dataForm={dataForm}
        setDataForm={setDataForm}
        dataOptions={PTKP_OPTIONS}
        fieldLabel="PTKP*"
        fieldNumber="2"
        fieldName="ptkp"
        placeholder="Select PTKP"
        showErrorHelper={() => isSubmit && !dataForm?.ptkp}
        value={dataForm?.ptkp}
        tooltipMesssage={informationHelperText.ptkp}
      />

      <NumberInputGroup
        isCurrency
        isFullWidth
        dataForm={dataForm}
        setDataForm={setDataForm}
        fieldLabel="Basic Salary*"
        fieldNumber="3"
        fieldName="salary"
        value={numberFormatter
          .format(convertToAngka(dataForm?.salary))
          .toString()}
        maskingMethod={value => {
          return numberFormatter.format(convertToAngka(value)).toString()
        }}
        tooltipMesssage={informationHelperText.basic_salary}
      />

      <FormChildContainer fullWidth style={{padding: 0}}>
        <Flex>
          <FormControl>
            <FormChildTitle>
              <TitleNumber style={{color: 'white'}}>3.</TitleNumber>
              <TitleName>Start Period Date*</TitleName>
              <FieldInformationTooltip
                title={informationHelperText.start_period}
              />
            </FormChildTitle>
            <FormChildInput>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  size="small"
                  inputVariant="outlined"
                  format="MMM dd, yyyy"
                  value={dataForm?.start_date || null}
                  margin="normal"
                  placeholder="Select start date"
                  minDate={
                    !isEdit &&
                    pwsData?.prev?.start_date &&
                    moment(pwsData?.prev?.start_date)
                      .add(1, 'days')
                      .format('YYYY-MM-DD')
                  }
                  onChange={date => handleChangeDate(date, 'start_date')}
                  {...(!dataForm?.start_date && isSubmit ? {error: true} : {})}
                  {...(!dataForm?.start_date && isSubmit
                    ? {helperText: 'This field is required'}
                    : {})}
                />
              </MuiPickersUtilsProvider>
            </FormChildInput>
          </FormControl>

          <FormControl>
            <FormChildTitle>
              <TitleNumber style={{color: 'white'}}>3.</TitleNumber>
              <TitleName>End Period Date*</TitleName>
              <FieldInformationTooltip
                title={informationHelperText.end_period}
              />
            </FormChildTitle>
            <FormChildInput>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  size="small"
                  inputVariant="outlined"
                  format="MMM dd, yyyy"
                  value={dataForm?.end_date || null}
                  margin="normal"
                  minDate={dataForm?.start_date}
                  placeholder="Select end date"
                  onChange={date => handleChangeDate(date, 'end_date')}
                  {...(!dataForm?.end_date && isSubmit ? {error: true} : {})}
                  {...(!dataForm?.end_date && isSubmit
                    ? {helperText: 'This field is required'}
                    : {})}
                />
              </MuiPickersUtilsProvider>
            </FormChildInput>
          </FormControl>
        </Flex>
      </FormChildContainer>

      <FormChildContainer fullWidth>
        <FormControl>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>NPWP*</TitleName>
            <FieldInformationTooltip title={informationHelperText.npwp} />
          </FormChildTitle>
          <FormChildInput>
            <div
              style={{
                display: 'flex',
              }}
            >
              <ThinTextfield
                fullWidth
                error={npwp?.error}
                style={{flexGrow: 1}}
                value={dataForm?.npwp}
                variant="outlined"
                helperText={npwp?.helperText}
                onChange={e =>
                  setDataForm({
                    ...dataForm,
                    npwp: e.target.value,
                  })
                }
              />
              {/**
               * Sync NPWP with profile
               * */}
              {/* <Tooltip
                title="Sync NPWP with People Profile"
                placement="top-end"
              >
                <IconButton
                  style={{color: '#039be5', marginLeft: 5}}
                  onClick={() => {
                    setSkipFetchSyncButton(false)
                    setIdentityType('npwp')
                  }}
                >
                  <SyncIcon style={{fontSize: '20px'}} />
                </IconButton>
              </Tooltip> */}
            </div>
          </FormChildInput>
        </FormControl>
      </FormChildContainer>

      <FormChildContainer fullWidth>
        <FormControl>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>KTP*</TitleName>
            <FieldInformationTooltip title={informationHelperText.ktp} />
          </FormChildTitle>
          <FormChildInput>
            <div
              style={{
                display: 'flex',
              }}
            >
              <ThinTextfield
                fullWidth
                error={ktp?.error}
                style={{flexGrow: 1}}
                value={dataForm?.ktp}
                variant="outlined"
                helperText={ktp?.helperText}
                onChange={e =>
                  setDataForm({
                    ...dataForm,
                    ktp: e.target.value,
                  })
                }
              />
              {/* Sync KTP number with profile */}
              {/* <Tooltip title="Sync KTP with People Profile" placement="top-end">
                <IconButton
                  style={{color: '#039be5', marginLeft: 5}}
                  onClick={() => {
                    setSkipFetchSyncButton(false)
                    setIdentityType('ktp')
                  }}
                >
                  <SyncIcon style={{fontSize: '20px'}} />
                </IconButton>
              </Tooltip> */}
            </div>
          </FormChildInput>
        </FormControl>
      </FormChildContainer>
    </>
  )
}

const PTKP_OPTIONS = [
  {
    label: 'T/K/0 Tidak kawin, tidak ada tanggungan',
    value: 'TK/0',
  },
  {
    label: 'T/K/1 Tidak kawin, 1 tanggungan',
    value: 'TK/1',
  },
  {
    label: 'T/K/2 Tidak kawin, 2 tanggungan',
    value: 'TK/2',
  },
  {
    label: 'T/K/3 Tidak kawin, 3 tanggungan',
    value: 'TK/3',
  },
  {
    label: 'K/0 Kawin, tidak ada tanggungan',
    value: 'K/0',
  },
  {
    label: 'K/1 Kawin, 1 tanggungan',
    value: 'K/1',
  },
  {
    label: 'K/2 Kawin, 2 tanggungan',
    value: 'K/2',
  },
  {
    label: 'K/3 Kawin, 3 tanggungan',
    value: 'K/3',
  },
  {
    label: 'K/I/0 Kawin + istri, tidak ada tanggungan',
    value: 'K/I/0',
  },
  {
    label: 'K/I/1 Kawin + istri, 1 tanggungan',
    value: 'K/I/1',
  },
  {
    label: 'K/I/2 Kawin + istri, 2 tanggungan',
    value: 'K/I/2',
  },
  {
    label: 'K/I/3 Kawin + istri, 3 tanggungan',
    value: 'K/I/3',
  },
]
