import React from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import DeletePopup from '../../../../../../shared-components/popup/DeletePopup'
import {GridListing} from '../../../DetailEmployeeStyles'
import LoadingEmployee from '../../../../LoadingEmployee'
import {ButtonAddDataTab} from '../data-tab-shared'
import MedicalRecordItem from './MedicalRecordItem'
import AddEditMedicalRecord from './AddEditMedicalRecord'
import DetailMedicalRecord from './DetailMedicalRecord'

import {GET_MEDICAL_RECORDS} from '../../../../../../../graphql/queries'
import {DELETE_MEDICAL_RECORD} from '../../../../../../../graphql/mutations'

const INITIAL_DETAIL_STATE = {
  openForm: false,
  openDetails: false,
  openDelete: false,
  data: null,
}

const MedicalRecord = props => {
  const {userId} = props

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [detailState, setDetailState] = React.useState(INITIAL_DETAIL_STATE)

  const {data, error, refetch} = useQuery(GET_MEDICAL_RECORDS, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {userId: userId},
  })

  if (error) {
    return <div>{'' + error}</div>
  }

  if (!data) {
    return <LoadingEmployee noborder smallpadding />
  }

  const handleRecordDetails = data => {
    setDetailState({...detailState, openDetails: true, data: data})
  }

  const handleRecordEdit = data => {
    setDetailState({...detailState, openForm: true, data: data})
  }

  const handleRecordAdd = () => {
    setDetailState({...detailState, openForm: true, data: null})
  }

  const handleDetailsClose = () => {
    setDetailState({...detailState, openDetails: false})
  }

  const handleFormClose = () => {
    setDetailState({...detailState, openForm: false})
  }

  const handleFormSubmit = shouldRefetch => {
    handleFormClose()

    if (shouldRefetch) {
      refetch()
    }
  }

  const handleDeleteOpen = () => {
    setDetailState({...detailState, openDelete: true})
  }

  const handleDeleteClose = action => {
    setDetailState({
      ...detailState,
      openForm: action === 'action-cancel',
      openDelete: false,
    })
  }

  const handleDeleteConfirm = () => {
    const promise = client.mutate({
      mutation: DELETE_MEDICAL_RECORD,
      variables: {
        id: detailState.data.id,
      },
    })

    promise.then(
      () => {
        refetch()
        enqueueSnackbar('Medical record deleted', {variant: 'success'})
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Failed to delete medical record`, {variant: 'error'})
      }
    )
  }

  return (
    <>
      <GridListing>
        {data.people_profile_medical_records.map(item => (
          <MedicalRecordItem
            key={item.id}
            data={item}
            onEdit={() => handleRecordEdit(item)}
            onDetails={() => handleRecordDetails(item)}
          />
        ))}
      </GridListing>

      <ButtonAddDataTab onClick={handleRecordAdd}>
        Add Medical Profile
      </ButtonAddDataTab>

      <DetailMedicalRecord
        open={detailState.openDetails}
        data={detailState.data}
        onClose={handleDetailsClose}
      />

      <AddEditMedicalRecord
        open={detailState.openForm}
        userId={userId}
        initialData={detailState.data}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        onDelete={handleDeleteOpen}
      />

      <DeletePopup
        name={detailState.data?.medical_test_name}
        open={detailState.openDelete}
        mutation={handleDeleteConfirm}
        handleClose={handleDeleteClose}
      />
    </>
  )
}

export default MedicalRecord
