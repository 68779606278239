import {Button, makeStyles} from '@material-ui/core'
import React from 'react'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(() => ({
  rootWrapper: {
    padding: '25px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderTop: 'solid 1px #e5e5e5',
  },
  btnAdd: {
    padding: '25px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    borderTop: 'solid 1px #e5e5e5',
    fontSize: 16,
    width: '100%',
  },
  addIcon: {
    fontSize: 24,
    marginRight: 6,
  },
}))

export function ButtonAdd({onClick, label = null}) {
  const classes = useStyles()
  return (
    // <div className={classes.rootWrapper}>
    <Button
      className={classes.btnAdd}
      disableElevation
      onClick={onClick}
      variant="contained"
      color="primary"
    >
      <AddIcon className={classes.addIcon} />
      {`Tambahkan ${label}`}
    </Button>
    // </div>
  )
}
