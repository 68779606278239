import React, {useState} from 'react'
import {
  ItemClause,
  CaluseItemHead,
  CaluseItemBody,
  ClauseItemWrapper,
  ItemMenu,
} from '../RegulationsStyles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {Popover, Button} from '@material-ui/core'
import {Link} from 'react-router-dom'

export default function ClauseItem({data, setOpen}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'item-clause' : undefined
  return (
    <>
      <ItemClause>
        <ClauseItemWrapper>
          <CaluseItemHead>
            <span className="name-clause">{data && data.name}</span>
            <MoreHorizIcon
              onClick={e => {
                e.stopPropagation()
                setAnchorEl(e.currentTarget)
              }}
            />
          </CaluseItemHead>
          <CaluseItemBody
            dangerouslySetInnerHTML={{
              __html: data && data.content,
            }}
          ></CaluseItemBody>
        </ClauseItemWrapper>
      </ItemClause>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ItemMenu>
          <Button
            component={Link}
            to={{
              pathname: '/company/edit-clause',
              state: {active: 'edit-clause', dataEdit: data},
            }}
            className="item"
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setOpen(e => ({
                ...e,
                delete: true,
                deleteName: data && data.name,
                deleteId: data && data.id,
                model: 'Clause',
              }))
              setAnchorEl(null)
            }}
            className="item"
          >
            Delete
          </Button>
        </ItemMenu>
      </Popover>
    </>
  )
}
