import React, {useState, useEffect} from 'react'
import {PageContainer} from '../../../GlobalStyles'
// import WlbTab from '../../../components/tab/WlbTabComponent'
import Card from '../../../components/card/CardComponent'
import {withRouter} from 'react-router-dom'
import ListJobFamilyName from './name/ListJobFamilyName'
import AddEditJobFamilyName from './name/AddEditJobFamilyName'
// import ListJobFamilyFunction from './function/ListJobFamilyFunction'
// import AddEditJobFamilyFunction from './function/AddEditJobFamilyFunction'
// import ListMappingTable from './mapping-table/ListMappingTable'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'job-family':
      return <ListJobFamilyName searchText={searchText} />
    case 'add-job-family':
      return (
        <AddEditJobFamilyName
          searchText={searchText}
          status={state.status}
          dataCheck={state.dataCheck}
        />
      )
    case 'edit-job-family':
      return (
        <AddEditJobFamilyName
          searchText={searchText}
          status={state.status}
          data={state.data}
          dataCheck={state.dataCheck}
        />
      )
    // case 'jf-function':
    //   return <ListJobFamilyFunction searchText={searchText} />
    // case 'add-jf-function':
    //   return (
    //     <AddEditJobFamilyFunction
    //       searchText={searchText}
    //       status={state.status}
    //       dataCheck={state.dataCheck}
    //     />
    //   )
    // case 'edit-jf-function':
    //   return (
    //     <AddEditJobFamilyFunction
    //       searchText={searchText}
    //       status={state.status}
    //       data={state.data}
    //       dataCheck={state.dataCheck}
    //     />
    //   )
    // case 'mapping-table':
    //   return <ListMappingTable searchText={searchText} />
    default:
      return <ListJobFamilyName searchText={searchText} />
  }
}

const JobFamily = props => {
  // let activeTab = ''
  let activeMenu = 'job-family'

  let state = {}

  if (props.location.state) {
    activeMenu = props.location.state.active
      ? props.location.state.active
      : 'job-family'
    state = props.location.state
    // switch (activeMenu) {
    //   case 'job-family':
    //   case 'add-job-family':
    //   case 'edit-job-family':
    //     activeTab = 'job-family'
    //     break
    //   case 'jf-function':
    //   case 'add-jf-function':
    //   case 'edit-jf-function':
    //     activeTab = 'jf-function'
    //     break
    //   case 'mapping-table':
    //     activeTab = 'mapping-table'
    //     break
    //   default:
    //     activeTab = props.location.state.active
    // }
  }

  useEffect(() => {
    if (props.location.pathname === '/organization/job-family') {
      props.history.push({
        state: {
          active: 'job-family',
          searchable: true,
        },
      })
    }
  }, [])

  // const data_tabs1 = [
  //   {
  //     label: 'Configuring',
  //     state: 'configuring',
  //     url: '#',
  //     searchable: false,
  //   },
  //   {
  //     label: 'Job Family Name',
  //     state: 'job-family',
  //     url: '/organization/job-family',
  //     searchable: true,
  //   },
  //   {
  //     label: 'Job Family Function',
  //     state: 'jf-function',
  //     // url: '/page-under-development',
  //     url: '/organization/jf-function',
  //     searchable: true,
  //   },
  //   // {
  //   //   label: 'Job Family Category',
  //   //   state: 'jf-category',
  //   //   url: '/page-under-development',
  //   //   // url: '/organization/jf-category',
  //   //   searchable: true,
  //   // },
  //   // {
  //   //   label: 'Job Family Grade',
  //   //   state: 'jf-grade',
  //   //   url: '/page-under-development',
  //   //   // url: '/organization/jf-grade',
  //   //   searchable: true,
  //   // },
  //   // {
  //   //   label: 'Job Family Level',
  //   //   state: 'jf-level',
  //   //   url: '/page-under-development',
  //   //   // url: '/organization/jf-level',
  //   //   searchable: true,
  //   // },
  // ]

  // // const data_tabs_2 = [
  // //   {
  // //     label: 'Processing',
  // //     state: 'processing',
  // //     url: '#',
  // //     searchable: false,
  // //   },
  // //   {
  // //     label: 'Mapping Table',
  // //     state: 'mapping-table',
  // //     // url: `/page-under-development`,
  // //     url: `/organization/mapping-table`,
  // //     searchable: true,
  // //   },
  // // ]

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <Card icon="device_hub" title="Rumpun Jabatan" onSearch={onSearch}>
      <PageContainer>
        {/* <div style={{display: 'flex', flexDirection: 'column'}}> */}
        {/* <WlbTab
            data={data_tabs1}
            activeMenu={activeTab}
            disableFirstElement={true}
          /> */}
        {/* <div style={{marginTop: 20}}>
              <WlbTab
                data={data_tabs_2}
                activeMenu={activeTab}
                disableFirstElement={true}
              />
            </div> */}
        {/* </div> */}

        {renderComponent(activeMenu, state, searchText)}
      </PageContainer>
    </Card>
  )
}

export default withRouter(JobFamily)
