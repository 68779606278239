import React, {useState, useLayoutEffect} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useQuery} from '@apollo/react-hooks'
import {
  Dialog,
  Button,
  Typography,
  IconButton,
  DialogActions,
  TextField,
  InputAdornment,
  Divider,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core'
import Search from '@material-ui/icons/Search'

import helperStyle, {
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
  TextBlackBold,
  CustomTypography,
} from '../../../GlobalStyles'
import NoDataListComponent from '../../shared-components/NoDataListComponent'

import {
  GET_SUGGESTED_ASSIGNED_COMPONENT,
  GET_SELECTED_ASSIGNED_COMPONENT,
} from '../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../../pages/financial/payroll/utils'
import {COMPANY_ID} from '../../../utils/globals'

const ComponentSection = data_suggested_component => {
  const classes = helperStyle()
  return (
    <div className={classes.marginTop15}>
      <CustomTypography>{data_suggested_component?.name}</CustomTypography>
    </div>
  )
}

const FilterFinanceComponent = ({
  handleClose,
  open,
  onAdd,
  userId,
  userWorkId,
  preData,
}) => {
  const classes = helperStyle()
  const [search, setSearch] = useState('')
  const [checkedData, setCheckedData] = useState([])
  const [existingData, setExistingData] = useState([])
  const [selectedComponent, setSelectedComponent] = useState([])
  const [infiniteLoad, setInfiniteLoad] = useState({
    offset: 0,
    limit: 10,
    hasMore: true,
  })

  const CheckboxItem = ({label, name, onChange, value}) => {
    return (
      <FormControlLabel
        style={{
          margin: '0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        control={
          <Checkbox
            checked={existingData?.includes(label.id)}
            color="primary"
            onChange={onChange}
            value={value}
            name={name}
            size="large"
          />
        }
        label={ComponentSection(label)}
        labelPlacement="start"
      />
    )
  }

  const {data: data_suggested_component, error} = useQuery(
    GET_SUGGESTED_ASSIGNED_COMPONENT,
    {
      variables: {
        userid: userId,
        company: COMPANY_ID,
        search: `%${search}%`,
        excludeAssignment: existingData,
        offset: infiniteLoad.offset,
        limit: infiniteLoad.limit,
      },
      ...GQL_FINANCE_OPTIONS,
      skip: !open,
    }
  )

  const {data: data_selected_component} = useQuery(
    GET_SELECTED_ASSIGNED_COMPONENT,
    {
      variables: {
        userid: userId,
        company: COMPANY_ID,
        includeAssignment: selectedComponent,
      },
      ...GQL_FINANCE_OPTIONS,
      skip: !open,
    }
  )

  useLayoutEffect(() => {
    if (preData) {
      const _preData = preData.map(pre => {
        return pre.id
      })
      setExistingData(_preData)
    }
  }, [preData])

  if (data_suggested_component === undefined && error === undefined) {
    return ''
  } else if (error !== undefined) {
    return <div>{JSON.stringify(error)}</div>
  }

  const handleUncheckSelectedComponent = (event, checked, dataCheckBox) => {
    event.preventDefault()
    if (!checked) {
      const notSelected = existingData.filter(notSelect => {
        return notSelect !== dataCheckBox.id
      })
      setExistingData(notSelected)
      const notSelectedComponent = selectedComponent.filter(notSelect => {
        return notSelect !== dataCheckBox.id
      })
      setSelectedComponent(notSelectedComponent)
      const _checkedData = checkedData.filter(checkedDatas => {
        return checkedDatas.component_id !== dataCheckBox.id
      })
      setCheckedData(_checkedData)
    }
  }

  const handleCheckSuggestedComponent = res => {
    const _selected = [...existingData, res.id]
    setExistingData(_selected)
    const _selectedComponent = [...selectedComponent, res.id]
    setSelectedComponent(_selectedComponent)
    const _checkedData = [
      ...checkedData,
      {user_work_id: userWorkId, component_id: res.id},
    ]
    setCheckedData(_checkedData)
  }

  const lengthDataSuggested = data_suggested_component?.total?.aggregate?.count
  const fetchMoreData = () => {
    if (infiniteLoad.limit >= lengthDataSuggested) {
      setInfiniteLoad({
        ...infiniteLoad,
        hasMore: false,
      })
    }
    setTimeout(() => {
      setInfiniteLoad({
        ...infiniteLoad,
        limit: infiniteLoad.limit + 10,
      })
    }, 1000)
  }

  const handleConfirm = () => {
    onAdd(checkedData)
    setSelectedComponent([])
    setCheckedData([])
    setSearch('')
    handleClose()
  }

  const onCancel = () => {
    setSelectedComponent([])
    setCheckedData([])
    const _preData = preData.map(pre => {
      return pre.id
    })
    setExistingData(_preData)
    setSearch('')
    handleClose()
  }

  return (
    <Dialog
      onClose={onCancel}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <ModalHeader>
        <div style={{display: 'flex'}}>
          <Typography style={{color: '#ffffff', alignSelf: 'center'}}>
            Assign Component
          </Typography>
        </div>

        <IconButton aria-label="close" onClick={onCancel}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>

      <ModalContentEvent>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          placeholder="Search Component"
          onChange={event => setSearch(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment className="ml-0" position="end">
                <Search style={{color: '#014a62', width: 20, height: 20}} />
              </InputAdornment>
            ),
          }}
        />
      </ModalContentEvent>

      <div style={{maxHeight: '500px', overflow: 'auto'}} id="scrollableDiv">
        <ModalContentEvent
          className={
            data_selected_component?.selected_components?.length === 0 &&
            classes.padding0
          }
        >
          {data_selected_component?.selected_components?.length > 0 && (
            <>
              <TextBlackBold className={classes.marginTop15}>
                Selected
              </TextBlackBold>
              {data_selected_component?.selected_components?.map(
                (res, index) => {
                  return (
                    <CheckboxItem
                      key={index}
                      label={res}
                      name="name"
                      onChange={(e, check) => {
                        handleUncheckSelectedComponent(e, check, res)
                      }}
                      value={{
                        id: res.id,
                        user_work_id: userWorkId,
                      }}
                    />
                  )
                }
              )}
            </>
          )}
        </ModalContentEvent>

        <Divider />

        <ModalContentEvent style={{minHeight: '250px'}}>
          <TextBlackBold>Suggested Components</TextBlackBold>
          <InfiniteScroll
            dataLength={infiniteLoad.limit}
            scrollableTarget="scrollableDiv"
            next={fetchMoreData}
            hasMore={
              data_suggested_component?.suggested_components?.length > 5
                ? infiniteLoad.hasMore
                : false
            }
            loader={
              <div style={{textAlign: 'center'}}>
                <CircularProgress size={15} style={{marginRight: '10px'}} />
                <span>Loading</span>
              </div>
            }
          >
            {data_suggested_component?.suggested_components?.map(
              (res, index) => {
                return (
                  <CheckboxItem
                    key={index}
                    label={res}
                    name="component"
                    onChange={() => {
                      handleCheckSuggestedComponent(res)
                    }}
                    value={res.id}
                  />
                )
              }
            )}
          </InfiniteScroll>
          {data_suggested_component?.total?.aggregate?.count === 0 && (
            <NoDataListComponent />
          )}
        </ModalContentEvent>
      </div>

      <Divider />

      <DialogActions>
        <Button style={{fontSize: 12}} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={
            data_suggested_component?.total?.aggregate?.count === 0 &&
            data_selected_component?.total?.aggregate?.count === 0
          }
          size="large"
          style={{fontSize: 12, marginLeft: 12}}
          variant="contained"
          onClick={handleConfirm}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FilterFinanceComponent
