import React from 'react'
import {
  Typography,
  IconButton,
  Divider,
  Paper,
  Toolbar,
} from '@material-ui/core'
import {GET_DETAIL_COMPANY} from '../../../../graphql/queries'
import {Link, withRouter} from 'react-router-dom'
import {PaperContainer} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {SummaryDetailContainer} from '../CompanySummaryPageStyle'
import GoalDetailComponent from './GoalDetailComponent'
import TaskDetailComponent from './TaskDetailComponent'
import Loading from '../../../../components/loading/LoadingComponent'
import {useQuery} from '@apollo/react-hooks'
import {holdingRolesContext} from '../../../../utils/helpers'

const IndividualSummaryDetail = ({location}) => {
  const idCompany = location.pathname.split('/')[4]

  const {data, error} = useQuery(GET_DETAIL_COMPANY, {
    variables: {
      company: idCompany,
    },
    ...holdingRolesContext,
  })

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    console.error(error)
    return 'something went wrong'
  }

  const companyProfile = data?.company_profiles?.[0]
  return (
    <SummaryDetailContainer>
      <PaperContainer>
        <Toolbar className="toolbar-container">
          <div className="toolbar-flex">
            <IconButton
              edge="end"
              aria-label="back"
              component={Link}
              to={{
                pathname: '/holding-performance/company-summary',
                state: {
                  active: 'company-summary',
                  searchable: true,
                },
              }}
            >
              <ArrowBack />
            </IconButton>

            <Typography variant="h6" className="title">
              Performance Company Summary
            </Typography>
          </div>
        </Toolbar>
        <Divider />
        <Paper className="avatar-container">
          <img
            src={companyProfile?.logo}
            alt="ava"
            width="115"
            height="120"
            className="avatar"
          />
          <div className="avatar-section">
            <p className="p-brand">{companyProfile?.brand_name}</p>
            <p className="p-legal">{companyProfile?.legal_name}</p>
          </div>
        </Paper>
        <GoalDetailComponent idCompany={idCompany} />
        <TaskDetailComponent idCompany={idCompany} />
      </PaperContainer>
    </SummaryDetailContainer>
  )
}

export default withRouter(IndividualSummaryDetail)
