import gql from 'graphql-tag'

export const GET_COUNTRIES = gql`
  query($search: String, $limit: Int, $offset: Int, $id: [Int!]) {
    total: global_country_aggregate(
      where: {country_name: {_ilike: $search}, deletedAt: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
    countries: global_country(
      where: {
        id: {_in: $id}
        country_name: {_ilike: $search}
        deletedAt: {_is_null: true}
      }
      limit: $limit
      offset: $offset
      order_by: {country_code: asc}
    ) {
      id
      code: country_code
      name: country_name
    }
  }
`
