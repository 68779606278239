import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {withRouter} from 'react-router-dom'
import {
  MenuItem,
  TextField,
  FormControl,
  InputAdornment,
} from '@material-ui/core'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  ThinTextfield,
  TitleName,
  TitleNumber,
} from '../../../../../../../GlobalStyles'
import FieldInformationTooltip from '../../../../../../shared-components/tooltip/FieldInformationTooltip'
import {inputRequiredHelperText} from '../../formValidation.helper'

import {GET_INSURANCE_LIST} from '../../../../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../../../utils'

const PremiumForm = props => {
  const {value, handleComponent, hasSubmit} = props

  const {
    name,
    insurance,
    premium_fee,
    company_portion,
    employee_portion,
    description,
    age_expire,
    age_beneficiaries,
    // premium_fields,
    effective_date,
  } = value

  const {data: insuranceList, loading} = useQuery(GET_INSURANCE_LIST, {
    ...GQL_FINANCE_OPTIONS,
  })

  const informationText = {
    premiumCompany: `The name of the company that issues the premium`,
    premiumName: `The name of the premium`,
    price: `Total premium price`,
    companyPortion: `The premium amount that the company pays`,
    employeePortion: `The premium amount that the employee pays`,
    effectiveDate: `The date when the premium will be effective`,
    expireAge: `Employees age when the premium is expired`,
    eligibleBeneficiaryAge: `Minimum age for the beneficiary to benefit from this premium`,
    description: `An explanation of the premium`,
  }

  return (
    <>
      <FormChildContainer>
        <FormControl>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Premium Company*</TitleName>
            <FieldInformationTooltip title={informationText.premiumCompany} />
          </FormChildTitle>
          <FormChildInput>
            <TextField
              error={hasSubmit && insurance === null}
              fullWidth
              helperText={inputRequiredHelperText(
                hasSubmit,
                insurance === null
              )}
              onChange={e =>
                handleComponent('insurance', Number(e.target.value))
              }
              select
              size="small"
              value={insurance || 'placeholder'}
              variant="outlined"
            >
              <MenuItem value="placeholder" disabled>
                Select Premium Company
              </MenuItem>
              {!loading &&
                insuranceList &&
                insuranceList.global_insurance_list.map(item => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  )
                })}
            </TextField>
          </FormChildInput>
        </FormControl>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Premium Name*</TitleName>
          <FieldInformationTooltip title={informationText.premiumName} />
        </FormChildTitle>
        <FormChildInput>
          <TextField
            error={hasSubmit && !name}
            fullWidth
            helperText={inputRequiredHelperText(hasSubmit, !name)}
            margin="normal"
            onChange={e => handleComponent('name', e.target.value)}
            placeholder="Add Premium Name"
            size="small"
            value={name || ''}
            variant="outlined"
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer style={{maxWidth: 300}}>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>Price*</TitleName>
          <FieldInformationTooltip title={informationText.price} />
        </FormChildTitle>
        <FormChildInput>
          <ThinTextfield
            error={hasSubmit && !premium_fee}
            defaultValue={0}
            fullWidth
            helperText={inputRequiredHelperText(hasSubmit, !premium_fee)}
            onChange={e => handleComponent('premium_fee', e.target.value)}
            type="number"
            variant="outlined"
            inputProps={{
              min: 0,
              style: {textAlign: 'right'},
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rp</InputAdornment>
              ),
            }}
            value={premium_fee || 'placeholder'}
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer style={{maxWidth: 300}}>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>Company Portion*</TitleName>
          <FieldInformationTooltip title={informationText.companyPortion} />
        </FormChildTitle>
        <FormChildInput>
          <ThinTextfield
            error={hasSubmit && !company_portion}
            defaultValue={0}
            fullWidth
            onChange={e => handleComponent('company_portion', e.target.value)}
            helperText={inputRequiredHelperText(hasSubmit, !company_portion)}
            type="number"
            variant="outlined"
            inputProps={{
              min: 0,
              style: {textAlign: 'right'},
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rp</InputAdornment>
              ),
            }}
            value={company_portion || 'placeholder'}
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer style={{maxWidth: 300}}>
        <FormChildTitle>
          <TitleNumber>5.</TitleNumber>
          <TitleName>Employee Portion*</TitleName>
          <FieldInformationTooltip title={informationText.employeePortion} />
        </FormChildTitle>
        <FormChildInput>
          <ThinTextfield
            error={hasSubmit && !employee_portion}
            defaultValue={0}
            fullWidth
            helperText={inputRequiredHelperText(hasSubmit, !employee_portion)}
            onChange={e => handleComponent('employee_portion', e.target.value)}
            type="number"
            variant="outlined"
            inputProps={{
              min: 0,
              style: {textAlign: 'right'},
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rp</InputAdornment>
              ),
            }}
            value={employee_portion || 'placeholder'}
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>6.</TitleNumber>
          <TitleName>Premium Effective Date*</TitleName>
          <FieldInformationTooltip title={informationText.effectiveDate} />
        </FormChildTitle>
        <FormChildInput>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              error={hasSubmit && !effective_date}
              format="yyyy-MM-dd"
              fullWidth
              helperText={inputRequiredHelperText(hasSubmit, !effective_date)}
              id="date-picker-dialog"
              inputVariant="outlined"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              margin="normal"
              onChange={value => {
                handleComponent('effective_date', value)
              }}
              placeholder="Select Date"
              value={effective_date}
            />
          </MuiPickersUtilsProvider>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>7.</TitleNumber>
          <TitleName>Expire Age*</TitleName>
          <FieldInformationTooltip title={informationText.expireAge} />
        </FormChildTitle>
        <FormChildInput>
          <ThinTextfield
            error={hasSubmit && !age_expire}
            defaultValue={0}
            fullWidth
            helperText={inputRequiredHelperText(hasSubmit, !age_expire)}
            inputProps={{
              min: 0,
            }}
            onChange={e => handleComponent('age_expire', e.target.value)}
            placeholder="Expired when an Employee is xx years old"
            type="number"
            value={age_expire || 'placeholder'}
            variant="outlined"
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>8.</TitleNumber>
          <TitleName>Eligible Beneficiary Age*</TitleName>
          <FieldInformationTooltip
            title={informationText.eligibleBeneficiaryAge}
          />
        </FormChildTitle>
        <FormChildInput>
          <ThinTextfield
            error={hasSubmit && !age_beneficiaries}
            defaultValue={0}
            fullWidth
            helperText={inputRequiredHelperText(hasSubmit, !age_beneficiaries)}
            inputProps={{
              min: 0,
            }}
            onChange={e => handleComponent('age_beneficiaries', e.target.value)}
            placeholder="Expired when an Employee is xx years old"
            type="number"
            value={age_beneficiaries || 'placeholder'}
            variant="outlined"
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>9.</TitleNumber>
          <TitleName>Description</TitleName>
          <FieldInformationTooltip title={informationText.description} />
        </FormChildTitle>
        <FormChildInput>
          <TextField
            variant="outlined"
            value={description}
            rowsMax={8}
            rows={8}
            placeholder="Add description here"
            onChange={e => handleComponent('description', e.target.value)}
            multiline={true}
            fullWidth
          />
        </FormChildInput>
      </FormChildContainer>
    </>
  )
}

export default withRouter(PremiumForm)
