import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {makeStyles} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import {Link, withRouter} from 'react-router-dom'
import {
  AppliesToChild,
  AppliesToContainer,
  OvertimeApplyContainer,
  OvertimeNameChild,
  OvertimeNameContainer,
} from '../OverPageStyles'
import {GET_OVER_SETTINGS} from '../../../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import Loading from '../../../../components/loading/LoadingComponent'
import Button from '@material-ui/core/Button'
import {DELETE_OVERTIME_POLICY} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import TablePagination from '@material-ui/core/TablePagination'
import NotFoundComponent from '../../../shared-components/NotFoundComponent'
import {Divider} from '@material-ui/core'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import AllAppliedTo from './modal/AllAppliedTo'
import {DEFAULT_PAGINATION} from '../../../../utils/globals'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontStyle: 'bold',
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // padding: '10px',
  },
  expanded: {
    borderTop: '1px solid rgba(0, 0, 0, 0.16)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    margin: '0 !important',
  },
  bold: {
    fontWeight: 700,
  },
  mr15: {marginRight: '1.5rem'},
  textGrey: {color: '#a9a8a8'},
  seeMore: {
    fontSize: 12,
    fontWeight: '500',
    cursor: 'pointer',
    marginTop: 32,
    marginLeft: 24,
  },
}))

const DEFAULT_SEE_MORE = {
  open: false,
  id: undefined,
  title: '',
}

function Setting(props) {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [seeMoreApplied, setSeeMoreApplied] = React.useState(DEFAULT_SEE_MORE)
  const [limit, setLimit] = React.useState(DEFAULT_PAGINATION[0])
  const [offset, setOffset] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [rowPerPage, setRowPerPage] = React.useState(DEFAULT_PAGINATION[0])

  const {data, error, refetch} = useQuery(GET_OVER_SETTINGS, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      search: `%${props.searchText}%`,
      limit: limit,
      offset: offset,
    },
  })

  const [selectePolicy, setSelectedPolicy] = React.useState(null)
  const [deletePolicy] = useMutation(DELETE_OVERTIME_POLICY)
  const [showDeletePopUp, setDeletePopUp] = React.useState(false)
  const onDeletePolicy = id => {
    const variables = {
      id: id,
      deleteAt: new Date(),
    }
    deletePolicy({variables})
      .then(() => {
        enqueueSnackbar('Setting deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete setting failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setCurrentPage(newPage)
    setOffset(newPage * limit)
  }

  const handleChangeRowsPerPage = event => {
    setRowPerPage(+event.target.value)
    setLimit(event.target.value)
    setOffset(0)
    setCurrentPage(0)
  }

  const handleClickSeeMore = child => {
    setSeeMoreApplied(e => ({
      ...e,
      open: true,
      id: child.id,
      title: child.name,
    }))
  }

  const dataSetting = data.time_overtime_policies

  const totalData = data.total.aggregate.count

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Overtime Policy Settings
          </Typography>

          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={{
              pathname: `/time/overtime/setting/add`,
              state: {active: 'add-setting'},
            }}
          >
            Add Overtime Policy
          </Button>
        </Toolbar>
        <Divider />
        {dataSetting.length > 0 ? (
          dataSetting.map((child, i) => {
            // if (child.convert_to_leave === false) {
            //   setLeave(child.convert_to_money.IDR)
            // }
            // console.log(child.convert_to_money.IDR)
            return (
              <ExpansionPanel
                key={i}
                elevation={0}
                classes={{
                  expanded: classes.expanded,
                }}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography
                    variant="body1"
                    className={classes.bold}
                    gutterBottom
                    color="primary"
                  >
                    {child.name}
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails style={{display: 'block'}} width={1}>
                  <div style={{width: '100%'}}>
                    <Box width={1}>
                      <OvertimeNameContainer>
                        <OvertimeNameContainer>
                          <Typography
                            variant="body1"
                            className={classes.textGrey}
                            display="inline"
                            style={{marginRight: 16}}
                          >
                            Compensation for Overtime
                          </Typography>
                          {child.convert_to_leave && (
                            <AppliesToChild style={{width: 'unset'}}>
                              One day Leave
                            </AppliesToChild>
                          )}
                          {!child.convert_to_leave && (
                            <AppliesToChild style={{width: 'unset'}}>
                              {child.convert_to_money &&
                              child.convert_to_money.IDR
                                ? `${child.convert_to_money.IDR} IDR / Hour`
                                : ''}
                            </AppliesToChild>
                          )}
                        </OvertimeNameContainer>

                        <OvertimeNameChild>
                          <Typography
                            variant="body2"
                            component={Link}
                            style={{
                              color: '#007fb2',
                              marginRight: 20,
                              textDecoration: 'none',
                            }}
                            to={{
                              pathname: `/time/overtime/setting/${child.id}`,
                              state: {
                                active: 'edit-setting',
                                data: child,
                                dataCheck: data,
                              },
                            }}
                          >
                            Edit Overtime
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{color: '#f44336', cursor: 'pointer'}}
                            onClick={() => {
                              setDeletePopUp(true)
                              setSelectedPolicy(child)
                            }}
                          >
                            Delete
                          </Typography>
                        </OvertimeNameChild>
                      </OvertimeNameContainer>
                    </Box>
                    <OvertimeApplyContainer>
                      <Typography
                        className={classes.bold}
                        variant="body1"
                        style={{marginTop: '1rem'}}
                      >
                        Applies to{' '}
                        {`(${child.total_job_profile_r_overtimes.aggregate.count})`}
                        :
                      </Typography>
                      <AppliesToContainer>
                        {child.company_job_profile_r_overtimes.map(
                          (value, idx) => {
                            if (value && value.company_job_profile) {
                              return (
                                <AppliesToChild key={idx}>
                                  {value.company_job_profile &&
                                    value.company_job_profile.title}
                                </AppliesToChild>
                              )
                            }
                          }
                        )}
                      </AppliesToContainer>
                      {child.total_job_profile_r_overtimes.aggregate.count >
                        20 && (
                        <Typography
                          color="secondary"
                          className={classes.seeMore}
                          onClick={() => handleClickSeeMore(child)}
                        >
                          See More
                        </Typography>
                      )}
                    </OvertimeApplyContainer>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          })
        ) : (
          <NotFoundComponent message="There is no overtime policy" />
        )}
      </Paper>
      {dataSetting.length > 0 && (
        <TablePagination
          rowsPerPageOptions={DEFAULT_PAGINATION}
          component="div"
          rowsPerPage={rowPerPage}
          page={currentPage}
          count={totalData}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <DeletePopup
        open={showDeletePopUp}
        handleClose={() => setDeletePopUp(false)}
        name={selectePolicy && selectePolicy.name}
        mutation={() => onDeletePolicy(selectePolicy && selectePolicy.id)}
      />
      <AllAppliedTo
        dataSeeMore={seeMoreApplied}
        onClose={() => setSeeMoreApplied(DEFAULT_SEE_MORE)}
      />
    </div>
  )
}

export default withRouter(Setting)
