import React from 'react'
import {
  Typography,
  IconButton,
  Divider,
  Paper,
  Toolbar,
} from '@material-ui/core'
import {GET_DATA_AVATAR} from '../../../../graphql/queries'
import {Link, withRouter} from 'react-router-dom'
import {PaperContainer} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {SummaryDetailContainer} from '../IndividualSummaryPageStyle'
import GoalDetailComponent from './GoalDetailComponent'
import TaskDetailComponent from './TaskDetailComponent'
import BadgesDetailComponent from './BadgesDetailComponent'
import Loading from '../../../../components/loading/LoadingComponent'
import {useQuery} from '@apollo/react-hooks'

const IndividualSummaryDetail = ({location}) => {
  const idPlacement = location.pathname.split('/')[4]
  const idUser = location.pathname.split('/')[5]

  const {data, error} = useQuery(GET_DATA_AVATAR, {
    wlb_skipPatch: true,
    variables: {
      placement: idPlacement,
    },
  })

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{JSON.stringify(error)}</div>
  }

  const placement = data?.people_work_placements?.[0]
  return (
    <SummaryDetailContainer>
      <PaperContainer>
        <Toolbar className="toolbar-container">
          <div className="toolbar-flex">
            <IconButton
              edge="end"
              aria-label="back"
              component={Link}
              to={{
                pathname: '/performance/individual-summary',
                state: {
                  active: 'individual-summary',
                  searchable: true,
                },
              }}
            >
              <ArrowBack />
            </IconButton>

            <Typography variant="h6" className="title">
              Ringkasan Kinerja Individu
            </Typography>
          </div>
        </Toolbar>
        <Divider />
        <Paper className="avatar-container">
          <img
            src={placement?.global_user?.avatar}
            alt="ava"
            width="115"
            height="120"
            className="avatar"
          />
          <div className="avatar-section">
            <p className="p-name">{placement?.global_user.name}</p>
            <p className="p-id">{placement?.regno}</p>
            <p className="p-placement">
              {placement?.company_job_profile?.title}
            </p>
            <p
              className="p-status"
              style={{
                ...(placement?.status === 'INACTIVE' && {color: '#ef4d5e'}),
              }}
            >
              {placement?.status !== 'INACTIVE' ? 'Pegawai Aktif' : 'Alumni'}
            </p>
          </div>
        </Paper>
        <GoalDetailComponent idUser={idUser} />
        <TaskDetailComponent idUser={idUser} />
        <BadgesDetailComponent idUser={idUser} />
      </PaperContainer>
    </SummaryDetailContainer>
  )
}

export default withRouter(IndividualSummaryDetail)
