import React, {useState} from 'react'
import BoxSection from '../BoxSection'
import ServiceAgreement from './ServiceAgreement'

const INITIAL_ACTION_STATE = {
  open: false,
  actionType: null,
  data: null,
  isFromModal: false,
}

export default function ServiceAgreementSection({...child}) {
  const [action, setAction] = useState(INITIAL_ACTION_STATE)

  const handleAddItem = () => {
    setAction(e => ({
      ...e,
      open: true,
      actionType: 'add',
      isFromModal: false,
    }))
  }
  return (
    <BoxSection onAdd={handleAddItem} title="Perjanjian Layanan">
      <ServiceAgreement
        action={action}
        setAction={setAction}
        INITIAL_ACTION_STATE={INITIAL_ACTION_STATE}
        {...child}
      />
    </BoxSection>
  )
}
