import {useQuery, useLazyQuery} from '@apollo/react-hooks'
import {
  Avatar,
  Divider,
  Icon,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import uniqBy from 'lodash/uniqBy'
import React, {useEffect, useState} from 'react'
import OrgChart from 'react-orgchart'
import 'react-orgchart/index.css'
import {Link} from 'react-router-dom'

import Loading from '../../../../components/loading/LoadingComponent'
import {
  EnhancedToolbar,
  GreyTypography,
  MediumAvatar,
  PaperContainer,
  StyledReactSelect,
} from '../../../../GlobalStyles'
import {
  GET_ORGANIZATION,
  GET_SUCCESSION_CHART,
} from '../../../../graphql/queries'
import NotFoundComponent from '../../../shared-components/NotFoundComponent'
import {useSnackbar} from 'notistack'

const useStyles = makeStyles(() => ({
  paper: {
    // borderRadius: 5,
    // border: '1px solid #707070',
    width: 200,
    margin: 'auto',
  },
  position: {
    padding: 12,
  },
  mAuto: {
    margin: 'auto',
  },
  nodeContent: {
    padding: 12,
    paddingBottom: 0,
  },
  successors: {
    display: 'flex',
    marginBottom: 10,
    alignItems: 'center',
  },
  successorContainer: {
    textAlign: 'left',
  },
  spacer: {
    marginLeft: 10,
  },
  green: {
    color: '#4caf50',
    textDecoration: 'none',
  },
  textLink: {
    color: '#ffa000',
    textDecoration: 'none',
  },
  padding: {
    padding: '0 20px',
  },
  mt10: {marginTop: 10},
  paperContainer: {
    minHeight: '71vh',
  },
}))

const SuccessionComponent = () => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  var arrayToTree = require('array-to-tree')
  const [structured, setStructured] = useState([])
  const [chart, setChart] = useState([])
  const [organization, setOrganization] = useState(0)
  const [organizationFilter, setOrganizationFilter] = useState(null)
  const [organizationSuper, setOrganizationSuper] = useState(0)
  const [parent, setParent] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)
  // const [idToShow, setIdToShow] = useState(null)

  const variables_filter = React.useMemo(() => {
    return {
      organization: organization ? {_eq: organization} : undefined,
      _or: organizationSuper
        ? [
            {job_profile_supervisor: {organization: {_neq: organizationSuper}}},
            {parent: {_is_null: true}},
          ]
        : undefined,
      parent: parent ? {_eq: parent} : undefined,
    }
  }, [organization, organizationSuper, parent])

  const {data, error, refetch} = useQuery(GET_SUCCESSION_CHART, {
    wlb_skipPatch: true,
    variables: {
      where: variables_filter,
    },
  })

  const [loadLazy, {data: dataLazy}] = useLazyQuery(GET_SUCCESSION_CHART, {
    variables: {
      where: variables_filter,
    },
  })

  if (dataLazy && isExpanded && dataLazy.company_job_profiles.length === 0) {
    enqueueSnackbar("This employee doesn't have subordinate", {
      variant: 'error',
      autoHideDuration: 1500,
    })
  }

  const {data: dataOrg, error: errorOrg} = useQuery(GET_ORGANIZATION, {
    wlb_skipPatch: true,
  })

  const onExpandNode = id => {
    setOrganization(null) // const a = [...state.parentId, id]
    setOrganizationSuper(null)
    setParent(id)
    setIsExpanded(true)
    loadLazy()
  }

  const handleFilter = val => {
    const i = val.value
    setOrganizationFilter(val)
    setOrganization(i)
    setOrganizationSuper(i)
    setParent(null)
    setIsExpanded(false)
  }

  const MyNodeComponent = ({node}) => {
    return Object.entries(node).length !== 0 &&
      node.people_work_placements.length > 0 ? (
      <div className={classes.padding}>
        <Paper className={classes.paper}>
          <div className={classes.position}>
            <Typography
              variant="body2"
              component={Link}
              to={{
                pathname: `/talent/succession/` + node.id,
                state: {active: 'detail-succession'},
              }}
              className={node.children ? classes.textLink : classes.green}
            >
              {node.title}
            </Typography>
          </div>
          <div className={classes.nodeContent}>
            <MediumAvatar
              src={
                node.people_work_placements[0] &&
                node.people_work_placements[0].global_user &&
                node.people_work_placements[0].global_user.avatar
              }
              alt={
                node.people_work_placements[0] &&
                node.people_work_placements[0].global_user &&
                node.people_work_placements[0].global_user.name
              }
              className={classes.mAuto}
            ></MediumAvatar>
            <Typography variant="body2" gutterBottom className={classes.mt10}>
              {node.people_work_placements[0] &&
                node.people_work_placements[0].global_user &&
                node.people_work_placements[0].global_user.name}
            </Typography>

            <div className={classes.successorContainer}>
              <GreyTypography variant="caption" gutterBottom>
                {node.count_successor.aggregate.count} Successors
              </GreyTypography>
              <div className={classes.mt10}>
                {node.talent_pull_successor &&
                  node.talent_pull_successor.map((res, i) => {
                    return (
                      <div className={classes.successors} key={i}>
                        <Avatar
                          src={res.people_work_placement.global_user.avatar}
                          alt={res.people_work_placement.global_user.name}
                        ></Avatar>
                        <div className={classes.spacer}>
                          <Typography variant="body2">
                            {res.people_work_placement.global_user.name}
                          </Typography>
                          {/* <Typography
                            variant="caption"
                            className={classes.green}
                          >
                            2 years
                          </Typography> */}
                        </div>
                      </div>
                    )
                  })}
              </div>

              <Typography variant="caption" color="secondary">
                {node.count_talent_pool.aggregate.count} Talent Pool
              </Typography>
            </div>
          </div>
          <div
            style={{minHeight: 28}}
            // onMouseOver={() => setIdToShow(node.id)}
            // onMouseLeave={() => setIdToShow(null)}
          >
            {/* {idToShow === node.id && ( */}
            <Icon
              style={{cursor: 'pointer'}}
              onClick={() => onExpandNode(node.id)}
            >
              keyboard_arrow_down
            </Icon>
            {/* )} */}
          </div>
        </Paper>
      </div>
    ) : (
      <div className={classes.padding}>
        <Paper className={classes.paper}>
          <div className={classes.position}>
            <Typography
              variant="body2"
              component={Link}
              to={{
                pathname: `/talent/succession/` + node.id,
                state: {active: 'detail-succession'},
              }}
              className={node.children ? classes.textLink : classes.green}
            >
              {node.title}
            </Typography>
          </div>
          <div className={classes.nodeContent}>
            <MediumAvatar className={classes.mAuto}></MediumAvatar>
            <Typography variant="body2" gutterBottom className={classes.mt10}>
              Unassigned
            </Typography>

            <div className={classes.successorContainer}>
              <div className={classes.mt10}>
                {node.talent_pull_successor &&
                  node.talent_pull_successor.map((res, i) => {
                    return (
                      <div className={classes.successors} key={i}>
                        <Avatar
                          src={res.people_work_placement.global_user.avatar}
                          alt={res.people_work_placement.global_user.name}
                        ></Avatar>
                        <div className={classes.spacer}>
                          <Typography variant="body2">
                            {res.people_work_placement.global_user.name}
                          </Typography>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
          <div style={{minHeight: 28}}>
            <Icon
              style={{cursor: 'pointer'}}
              onClick={() => onExpandNode(node.id)}
            >
              keyboard_arrow_down
            </Icon>
            {/* )} */}
          </div>
        </Paper>
      </div>
    )
  }

  useEffect(() => {
    refetch()
    if (data) {
      if (structured.length === 0) {
        setStructured(data.company_job_profiles)
        setChart(data.company_job_profiles)
      } else {
        if (!isExpanded) {
          setStructured(data.company_job_profiles)
          setChart(data.company_job_profiles)
        } else {
          if (isExpanded && data.company_job_profiles.length > 0) {
            Array.prototype.push.apply(chart, data.company_job_profiles)
            setChart(chart)
            const structuredData = arrayToTree(uniqBy(chart, 'id'), {
              parentProperty: 'parent',
              customID: 'id',
            })
            setStructured(structuredData)
            if (
              structuredData.length > 1 &&
              structuredData[1].id === data.company_job_profiles[0].id &&
              isExpanded
            ) {
              setStructured(data.company_job_profiles)
              setChart(data.company_job_profiles)
            }
          }
        }
      }
    }
  }, [data])

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  if (dataOrg === undefined && errorOrg === undefined) {
    return <Loading />
  } else if (errorOrg !== undefined) {
    return <div>{errorOrg}</div>
  }

  const optionsOrgs = dataOrg.company_organizations.map(res => {
    return {value: res.id, label: res.name}
  })

  return (
    <PaperContainer className={classes.paperContainer}>
      <EnhancedToolbar>
        <Typography variant="h6">Suksesi Talenta</Typography>
        <StyledReactSelect
          defaultValue={dataOrg && optionsOrgs[0]}
          value={organizationFilter}
          options={optionsOrgs}
          onChange={handleFilter}
          placeholder="Organisasi"

          // getOptionLabel={groupMenu}
        />
      </EnhancedToolbar>
      <Divider />
      {organizationFilter ? (
        <div style={{padding: 16, overflow: 'auto'}}>
          <OrgChart
            tree={structured.length === 0 ? structured : structured[0]}
            NodeComponent={MyNodeComponent}
          />
        </div>
      ) : (
        <NotFoundComponent
          title="Tidak ada Data"
          message="Silakan pilih organisasi terlebih dahulu untuk melihat jabatan di dalam unit organisasi"
        />
      )}
      {/* <Snackbar
        open={openSnackbar}
        message="This employee doesn't have subordinate"
      ></Snackbar> */}
    </PaperContainer>
  )
}

export default SuccessionComponent
