import gql from 'graphql-tag'

export const GET_KPI_ASPECT = gql`
  query getKpiAspect($search: String) {
    kpi_aspects(
      where: {
        _or: [
          {code: {_ilike: $search}}
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      code
      name
      description
      status
    }
  }
`

export const CHECK_CODE_ASPECT = gql`
  query checkCode($company: uuid, $code: String) {
    kpi_aspects_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const CHECK_ASPECT_ALREADT_TAKEN = gql`
  query checkDelete($aspect: [Int!]!) {
    kpi_lists_aggregate(where: {aspect: {_in: $aspect}}) {
      aggregate {
        count
      }
    }
  }
`
