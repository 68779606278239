import {useQuery} from '@apollo/react-hooks'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core'
import {StylesProvider} from '@material-ui/styles'
import {GetModularSettings} from '@worklifebeyond/wlb-utils-components'
import React, {useEffect, useState} from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import {createGlobalStyle, ThemeProvider} from 'styled-components'
import Header from './components/header/HeaderComponent'
import Main from './components/Main'
import Navigation from './components/navigation/NavigationComponent'
import {GET_GLOBAL_MODULE} from './graphql/queries'
import UnderDevelopment from './pages/error/UnderDevelopment'
import EmpResponse from './pages/learning/micro-learning/detail/tabs/EmpResponseComponent'
import SurveyPreviewComponent from './pages/survey/multirater-nps/survei-center/preview/SurveyPreviewComponent'
import {
  ALLOWED_ROLES,
  COMPANY_ID,
  ENVIRONMENT,
  MAIN_APP_URL,
  ROLES,
} from './utils/globals'
import {listenCookieChange} from './utils/helpers'

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    font-family: 'Muli';
  }
`

const theme = createMuiTheme({
  font: 'muli',
  borderColor: 'rgb(204, 204, 204)',
  palette: {
    primary: {
      main: '#014a62',
    },
    secondary: {
      main: '#039be5',
    },
  },
  typography: {
    fontFamily: ['muli'],
    button: {
      textTransform: 'capitalize',
    },
    h6: {
      fontWeight: 700,
    },
  },
})

const AppContainer = props => {
  const checkRole = ROLES.filter(element => ALLOWED_ROLES.includes(element))

  const {data: companyModule} = GetModularSettings(COMPANY_ID)

  const {data: allModule} = useQuery(GET_GLOBAL_MODULE, {
    wlb_skipPatch: true,
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  if (ENVIRONMENT !== 'development') {
    if (!checkRole) {
      window.location = MAIN_APP_URL
    }
  }

  const [open, setOpen] = useState(true)
  const [openClicked, setOpenClicked] = useState(false)

  const handleHoverDrawer = () => {
    if (openClicked) {
      setOpen(true)
    }
  }

  const handleLeaveDrawer = () => {
    if (openClicked) {
      setOpen(false)
    }
  }

  const handleClickButton = () => {
    setOpenClicked(prevState => !prevState)
  }

  useEffect(() => {
    // detect if user has logged out in another tabs browser
    if (process.env.NODE_ENV !== 'development') {
      listenCookieChange()
    }
  }, [listenCookieChange])

  return (
    <>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            {props.location.pathname === '/page-under-development' ? (
              <Switch>
                <Route
                  exact
                  path="/page-under-development"
                  render={() => <UnderDevelopment />}
                />
              </Switch>
            ) : (
              <>
                {props.location.pathname.includes(
                  '/survey/multirater-nps/survey-center/preview'
                ) ? (
                  <Switch>
                    <Route
                      path="/survey/multirater-nps/survey-center/preview/:id"
                      component={SurveyPreviewComponent}
                    />{' '}
                  </Switch>
                ) : props.location.pathname.includes(
                    '/learning/micro-learning/response'
                  ) ? (
                  <Switch>
                    <Route
                      path="/learning/micro-learning/response/:id/:userId"
                      component={EmpResponse}
                    />{' '}
                  </Switch>
                ) : (
                  <>
                    <GlobalStyle />
                    <Header />
                    <Main
                      open={open}
                      openClicked={openClicked}
                      companyModule={companyModule}
                      allModule={allModule}
                    />
                    <Navigation
                      open={open}
                      openClicked={openClicked}
                      handleHoverDrawer={handleHoverDrawer}
                      handleLeaveDrawer={handleLeaveDrawer}
                      handleClickButton={handleClickButton}
                      companyModule={companyModule}
                      allModule={allModule}
                    />
                  </>
                )}
              </>
            )}
          </MuiThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </>
  )
}

export default withRouter(AppContainer)
