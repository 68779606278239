import React, {useState} from 'react'
import {
  HeaderSection,
  TypographyStyled,
} from '../../directory/employee/detail-employee/DetailEmployeeStyles'
import {
  WrapperInfo,
  // ButtonBlue
} from '../../../GlobalStyles'
import {TypographyStyleds} from '../../../components/typography/TypographyStyles'
import linkedin from '../../../assets/slicing/socmed/linkedin.svg'
import facebook from '../../../assets/slicing/socmed/facebook.svg'
import mail from '../../../assets/slicing/socmed/mail.svg'
import twitter from '../../../assets/slicing/socmed/twitter.svg'
import fax from '../../../assets/slicing/socmed/fax.svg'
import Edit from '@material-ui/icons/Edit'
import {makeStyles, Paper, IconButton} from '@material-ui/core'
import {Link} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Photo from '@material-ui/icons/PhotoCamera'
import CropImagePopup from '../popup/CropImagePopup'

const useStyles = makeStyles(() => ({
  bgContainer: {
    width: '100%',
    height: 252,
    background: '#fff',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover button span svg': {
      display: 'inline',
    },
    '&:hover button': {
      display: 'inline',
    },
    '& > .MuiIconButton-root:hover': {
      backgroundColor: '#000000',
      borderRadius: 'unset',
    },
  },
  photoHover: {
    fontSize: '100px',
    display: 'none',
  },
  bgImg: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    height: '100%',
    width: '100%',
    objectFit: 'fill',
  },
  infoContainer: {padding: '28px 22px 24px', display: 'flex', width: '100%'},
  profileImg: {
    width: 177,
    background: '#fff',
    bottom: 85,
    position: 'relative',
    height: 177,
    objectFit: 'contain',
    marginLeft: 22,
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover button span svg': {
      display: 'inline',
    },
    '&:hover button': {
      display: 'inline',
    },
    '& > .MuiIconButton-root:hover': {
      backgroundColor: '#000000',
      borderRadius: 'unset',
    },
  },
  profileImgIn: {
    width: 177,
    height: 177,
    borderRadius: 4,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    objectFit: 'fill',
  },
  sosmedContainer: {background: '#f7f8f9', padding: '22px 30px'},
  flexSosmed: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 170,
    alignItems: 'center',
  },
  btnEditBanner: {
    bottom: '65px',
    left: '85%',
    height: '40px',
    '& > .MuiButton-label': {
      fontSize: '14px !important',
      fontWeight: 600,
      color: '#ffffff !important',
    },
  },
  editBannerHover: {
    color: '#ffffff',
    backgroundColor: '#000000',
    opacity: '0.5',
    display: 'none',
    borderRadius: 'unset',
    bottom: 256,
    width: '100%',
    height: 252,
  },
  editFotoHover: {
    bottom: '11.3rem',
    color: '#ffffff',
    backgroundColor: '#000000',
    opacity: '0.5',
    display: 'none',
    borderRadius: 'unset',
    height: 177,
    width: 177,
  },
  greyColor: {
    color: '#a9a8a8',
  },
  bottom15: {
    bottom: 15,
  },
  btnBack: {
    bottom: '96%',
    left: 17,
    background: '#fffff',
    position: 'relative',
  },
}))

const ProfileSection = ({
  avatar,
  banner,
  headline,
  brand,
  legal,
  website,
  location,
  codePhone,
  phone,
  link,
  feature,
  back,
  data,
  refetch,
}) => {
  const classes = useStyles()
  const [openBanner, setOpenBanner] = useState(false)
  const [openLogo, setOpenLogo] = useState(false)

  const handleOpen = type => {
    if (feature === 'company' || feature === 'holding-profile') {
      if (type === 'Banner') {
        openBanner ? setOpenBanner(false) : setOpenBanner(true)
      } else {
        openLogo ? setOpenLogo(false) : setOpenLogo(true)
      }
    }
  }

  return (
    <Paper elevation={2} style={{marginBottom: 22}}>
      <div className={classes.bgContainer} onClick={() => handleOpen('Banner')}>
        <img src={banner} className={classes.bgImg}></img>
        {(feature === 'company' || feature === 'holding-profile') && (
          // <ButtonBlue className={classes.btnEditBanner}>Edit Banner</ButtonBlue>
          <IconButton className={classes.editBannerHover}>
            <Photo className={classes.photoHover} />
          </IconButton>
        )}
        {feature === 'holding-member' && (
          <div className={classes.btnBack}>
            <IconButton onClick={back} size="medium">
              <ArrowBack />
            </IconButton>
          </div>
        )}
      </div>
      <div style={{display: 'flex'}}>
        <div className={classes.profileImg} onClick={() => handleOpen('Logo')}>
          <img src={avatar} className={classes.profileImgIn} />
          {(feature === 'company' || feature === 'holding-profile') && (
            <IconButton className={classes.editFotoHover}>
              <Photo className={classes.photoHover} />
            </IconButton>
          )}
        </div>
        <div className={classes.infoContainer}>
          <HeaderSection borderRight style={{padding: '0 22px', width: '45%'}}>
            <WrapperInfo>
              <TypographyStyleds variant="h5" weight="600">
                {brand}
              </TypographyStyleds>
            </WrapperInfo>
            <WrapperInfo>
              <TypographyStyled grey="true">
                {location}, Indonesia
              </TypographyStyled>
            </WrapperInfo>
            <WrapperInfo width="60%">
              <TypographyStyled>{headline}</TypographyStyled>
            </WrapperInfo>
          </HeaderSection>
          <HeaderSection style={{width: '50%', padding: '0px 0px 0px 3vw'}}>
            <WrapperInfo>
              <TypographyStyleds style={{width: '35%'}} variant="body2">
                Legal Name
              </TypographyStyleds>
              <TypographyStyled style={{width: '65%'}} grey="true">
                {legal}
              </TypographyStyled>
            </WrapperInfo>
            <WrapperInfo>
              <TypographyStyleds style={{width: '35%'}} variant="body2">
                Brand Name
              </TypographyStyleds>
              <TypographyStyled style={{width: '65%'}} grey="true">
                {brand}
              </TypographyStyled>
            </WrapperInfo>
            <WrapperInfo>
              <TypographyStyleds style={{width: '35%'}} variant="body2">
                Website
              </TypographyStyleds>
              <TypographyStyled style={{width: '65%'}} grey="true">
                {website}
              </TypographyStyled>
            </WrapperInfo>
            <WrapperInfo>
              <TypographyStyleds style={{width: '35%'}} variant="body2">
                Phone Number
              </TypographyStyleds>
              <TypographyStyled style={{width: '65%'}} grey="true">
                {`${
                  codePhone === 'null' || !codePhone ? '' : codePhone
                }${phone}`}
              </TypographyStyled>
            </WrapperInfo>
          </HeaderSection>
          <HeaderSection style={{width: '5%'}}>
            {(feature === 'company' || feature === 'holding-profile') && (
              <Link
                to={
                  feature === 'company'
                    ? {
                        pathname: '/company/editIntro',
                        state: {active: 'edit-intro', dataEditIntro: data},
                      }
                    : {
                        pathname: '/group/profile/edit-intro',
                        state: {
                          active: 'edit-intro-group',
                          dataEditIntro: data,
                        },
                      }
                }
              >
                <IconButton
                  className={classes.bottom15}
                  edge="end"
                  aria-label="edit"
                >
                  <Edit className={classes.greyColor} />
                </IconButton>
              </Link>
            )}
          </HeaderSection>
        </div>
      </div>
      <div className={classes.sosmedContainer}>
        <div className={classes.flexSosmed}>
          <a
            target="_blank"
            href={`https://${link.linkedIn}`}
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="linkedin" />
          </a>
          <a
            target="_blank"
            href={`https://${link.twitter}`}
            rel="noopener noreferrer"
          >
            <img src={twitter} alt="twitter" />
          </a>
          <a
            target="_blank"
            href={`https://${link.facebook}`}
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            target="_blank"
            href={`mailto:${link.email}`}
            rel="noopener noreferrer"
          >
            <img src={mail} alt="mail" />
          </a>
          <a target="_blank" href={`tel:${link.fax}`} rel="noopener noreferrer">
            <img src={fax} alt="fax" />
          </a>
        </div>
        <CropImagePopup
          open={openBanner}
          onClose={() => handleOpen('Banner')}
          title="Banner"
          refetch={refetch}
          feature={feature}
        />
        <CropImagePopup
          open={openLogo}
          onClose={() => handleOpen('Logo')}
          title="Logo"
          refetch={refetch}
          feature={feature}
        />
      </div>
    </Paper>
  )
}

export default ProfileSection
