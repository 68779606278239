import React from 'react'
import {PaperContainer, EnhancedToolbar} from '../../../../GlobalStyles'
import {Typography, Divider} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import MaterialTable from 'material-table'
import {GET_LIST_RESPONDENTS} from '../../../../graphql/queries/survey/multirater-nps/respondents/getListRespondents.query'
import Loading from '../../../../components/loading/LoadingComponent'
import {useQuery} from '@apollo/react-hooks'

const TableOptions = {
  search: false,
  selection: false,
  sorting: true,
  headerStyle: {
    fontWeight: 700,
    lineHeight: 1.43,
    marginTop: `-100px`,
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
  selectionProps: () => ({
    color: 'primary',
  }),
  toolbar: false,
}

const Respondents = () => {
  const {data, error} = useQuery(GET_LIST_RESPONDENTS, {
    variables: {
      survey: null,
      search: null,
      offset: null,
      limit: null,
    },
  })

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography style={{fontWeight: '600'}} variant="subtitle1">
          Asesi
        </Typography>
        <div></div>
      </EnhancedToolbar>
      <Divider></Divider>
      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0'}}
        columns={[
          {
            title: 'No',
            // render: rowData => rowData.tableData.id + 1,
            render: rowData => (
              <Typography style={{fontSize: 14}}>
                {rowData.id && rowData.tableData.id + 1}
              </Typography>
            ),
          },
          {
            title: 'Name',
            // field: 'global_user.name',
            render: rowData => (
              <Typography style={{fontSize: 14}}>
                {rowData.global_user && rowData.global_user.name}
              </Typography>
            ),
          },
          {
            title: 'Position',
            render: rowData => (
              <Typography style={{fontSize: 14}}>
                {rowData.global_user.people_work_placements[0]
                  .company_job_profile &&
                  rowData.global_user.people_work_placements[0]
                    .company_job_profile.company_employee_position &&
                  rowData.global_user.people_work_placements[0]
                    .company_job_profile.company_employee_position.name}{' '}
                at{' '}
                {rowData.global_user.people_work_placements[0]
                  .company_job_profile &&
                  rowData.global_user.people_work_placements[0]
                    .company_job_profile.company_address.office_name}
              </Typography>
            ),
          },
          {
            title: 'Email',
            field: 'global_user.email',
            render: rowData => (
              <Typography style={{fontSize: 14}}>
                {rowData.global_user && rowData.global_user.email}
              </Typography>
            ),
          },
        ]}
        data={data.multirater_respondents}
        title=""
        options={TableOptions}
        components={{
          Toolbar: () => null,
        }}
      />
    </PaperContainer>
  )
}

export default withRouter(Respondents)
