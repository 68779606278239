import React, {useEffect, useState} from 'react'
import {
  makeStyles,
  Divider,
  Typography,
  TablePagination,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import {
  PaperContainer,
  BlueLink,
  EnhancedToolbar,
} from '../../../../GlobalStyles'
import {useQuery} from '@apollo/react-hooks'
import {GET_ASPIRATION_LIST} from '../../../../graphql/queries/talent/succession/getAspirationList'
import ImageNull from '../../../../assets/images/no_badge.png'
import {withRouter} from 'react-router-dom'
import {DEFAULT_PAGINATION} from '../../../../utils/globals'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

const AspirationComponent = props => {
  const {searchText} = props
  const {prevPage, prevrowsPerPage} = props.history.location.state
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGINATION[0])

  const variables_filter = React.useMemo(() => {
    return {
      global_user: searchText ? {name: {_ilike: `%${searchText}%`}} : undefined,
    }
  }, [searchText])

  const {data, error, loading} = useQuery(GET_ASPIRATION_LIST, {
    wlb_skipPatch: true,
    variables: {
      where: variables_filter,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    },
  })

  useEffect(() => {
    if (prevPage !== undefined) {
      setPage(prevPage)
    }
    if (prevrowsPerPage !== undefined) {
      setRowsPerPage(prevrowsPerPage)
    }
  }, [])

  if (error) {
    console.error(error)
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <PaperContainer style={{overflow: 'unset'}}>
      <EnhancedToolbar>
        <Typography variant="h6">Aspirasi</Typography>
      </EnhancedToolbar>
      <Divider></Divider>
      <div style={{margin: '0px 20px'}}>
        <MaterialTable
          title="Aspiration"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            verticalAlign: 'text-top',
            boxShadow: 'none',
          }}
          columns={[
            {
              title: 'Nama',
              field: 'global_user.name',
              render: rowData => (
                <BlueLink
                  to={{
                    pathname: `/talent/succession/aspiration/${rowData.id}`,
                    state: {
                      active: 'detail-aspiration',
                      data: rowData,
                      page: page,
                      rowsPerPage: rowsPerPage,
                    },
                  }}
                >
                  {rowData.global_user?.name}
                </BlueLink>
              ),
            },
            {
              title: 'Jabatan',
              field: 'company_job_profile',
              render: rowData => (
                <p style={{margin: 0}}>
                  {rowData?.company_job_profile?.title} at{' '}
                  {rowData?.company_job_profile?.company_address?.office_name}
                </p>
              ),
            },
            {
              title: 'Lencana',
              field: 'talent_assignments',
              render: rowData =>
                rowData.talent_assignments.length > 0 ? (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <img
                      src={rowData.talent_assignments[0].talent_box?.badge}
                      alt={rowData.talent_assignments[0].talent_box?.name}
                      style={{marginRight: 7, width: '2vw', height: '100%'}}
                    />
                    <div className={classes.column}>
                      <Typography variant="body1">
                        {rowData.talent_assignments[0].talent_box?.name}
                      </Typography>
                      <Typography variant="body1">
                        {rowData.talent_assignments[0].talent_score
                          ? rowData.talent_assignments[0].talent_score.toFixed(
                              2
                            )
                          : 0}
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={ImageNull}
                      alt="no badge"
                      style={{marginRight: 7, width: '2vw'}}
                    />
                    <div className={classes.column}>
                      <Typography variant="body2">Tidak ada Lencana</Typography>
                    </div>
                  </div>
                ),
            },
            {
              title: 'Aspirasi',
              field: 'talent_aspirations',
              render: rowData => (
                <ul style={{margin: 0}}>
                  {rowData.talent_aspirations.length === 0 ? (
                    <li>Tidak Ada Data</li>
                  ) : (
                    rowData.talent_aspirations.map((res, i) => {
                      return (
                        <li key={i} style={{margin: 0, padding: 0}}>
                          Wants to be{' '}
                          {res.company_job_profile === null
                            ? 'data null'
                            : res.company_job_profile.title}
                        </li>
                      )
                    })
                  )}
                </ul>
              ),
            },
          ]}
          data={data?.people_work_placements || []}
          isLoading={loading}
          options={{
            rowStyle: {
              fontSize: 14,
              verticalAlign: 'top',
            },
            selection: false,
            paging: false,
          }}
          components={{
            Toolbar: () => null,
          }}
        />
        <TablePagination
          rowsPerPageOptions={DEFAULT_PAGINATION}
          component="div"
          count={data?.total.aggregate.count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Baris Per halaman"
          backIconButtonText="halaman sebelumnya"
          nextIconButtonText="halaman berikutnya"
          labelDisplayedRows={({from, to, count}) =>
            `${from} - ${to} dari ${count}`
          }
        />
      </div>
    </PaperContainer>
  )
}

export default withRouter(AspirationComponent)
