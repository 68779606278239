import React, {useState, useEffect} from 'react'
import {PageContainer} from '../../../GlobalStyles'
import Card from '../../../components/card/CardComponent'
import {withRouter} from 'react-router-dom'
import ListOrgUnit from './list-organization-unit/ListOrgUnit'
import AddEditOrgUnit from './list-organization-unit/AddEditOrgUnit'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'organization-unit':
      return <ListOrgUnit searchText={searchText} refetchOrg={true} />
    case 'add-organization-unit':
      return <AddEditOrgUnit status={state.status} />
    case 'edit-organization-unit':
      return <AddEditOrgUnit status={state.status} data={state.data} />
    default:
      return <ListOrgUnit searchText={searchText} />
  }
}

const OrganizationUnit = props => {
  let activeMenu = 'organization-unit'

  let state = {}

  if (props.location.state) {
    activeMenu = props.location.state.active
      ? props.location.state.active
      : 'organization-unit'
    state = props.location.state
  }

  useEffect(() => {
    if (props.location.pathname === '/organization/organization-unit') {
      props.history.push({
        state: {
          active: 'organization-unit',
          searchable: true,
        },
      })
    }
  }, [])

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <Card icon="device_hub" title="Unit Organisasi" onSearch={onSearch}>
      <PageContainer>
        {renderComponent(activeMenu, state, searchText)}
      </PageContainer>
    </Card>
  )
}

export default withRouter(OrganizationUnit)
