import {Dialog, Icon, IconButton} from '@material-ui/core'
import React from 'react'
import {WrapperModalDetail} from '../../../GlobalStyles'

export default function ModalDetail({
  onClose,
  open,
  title,
  maxWidth = 'md',
  children,
  onDelete = null,
}) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      scroll="body"
      maxWidth={maxWidth}
    >
      <WrapperModalDetail>
        <div className="header">
          <h1 className="title">{title}</h1>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {onDelete && (
              <IconButton
                aria-label="close"
                onClick={onDelete}
                className="wrap-close"
              >
                <Icon className="icon-close">delete</Icon>
              </IconButton>
            )}
            <IconButton
              aria-label="close"
              onClick={onClose}
              className="wrap-close"
            >
              <Icon type="filled" className="icon-close">
                close
              </Icon>
            </IconButton>
          </div>
        </div>
        <div className="content">{children}</div>
      </WrapperModalDetail>
    </Dialog>
  )
}
