import React, {useState, useEffect} from 'react'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  WrapperContent,
  WhiteButton,
  TextDarkBlue,
  TextBlack,
  TextGrey,
  // FlexStart,
} from '../../OrganizationPageStyles'
import {
  Button,
  // Avatar
} from '@material-ui/core'
import {
  RootContainer,
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import Loading from '../../../../components/loading/LoadingComponent'
import {Link} from 'react-router-dom'
import MaterialTable from 'material-table'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {
  GET_LIST_ORGANIZATION,
  CHECK_PARENT_POSITION,
  GET_BALOON_STATUS_BUTTON,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import {TableOptions} from '../../../shared-components/table/TableOptions'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import OSTreeModal from './organization-structure/OSTreeModal'
import {
  DELETE_ORGANIZATION,
  IMPORT_DATA,
  CLOSE_BALOON,
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
} from '../../../../graphql/mutations'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import ImportBaloon from '../../../../components/importExcel/ImportBaloon'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import {trimString, disbaledButtonExport} from '../../../../utils/helpers'

const ListOrgUnit = ({searchText}) => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [OpenModal, setOpenModal] = useState(false)
  const [OpenAlert, setOpenAlert] = useState(false)
  const [OpenCantDel, setOpenCantDel] = useState(false)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })
  const {data, error, loading, refetch} = useQuery(GET_LIST_ORGANIZATION, {
    variables: {
      company: COMPANY_ID,
      search: `%${searchText}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const {
    data: checkParent,
    error: errorParent,
    loading: loadingParent,
    refetch: refetchParent,
  } = useQuery(CHECK_PARENT_POSITION, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  const [dataOrg, setDataOrg] = useState({})
  const [deleteOrgUnit] = useMutation(DELETE_ORGANIZATION)
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )

  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    loading: loadingGetBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      company: COMPANY_ID,
      table: 'company_organizations',
    },
  })

  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )
  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  const {data: getExportBaloon, error: errorGetExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        company: COMPANY_ID,
        table: 'company_organizations',
      },
    }
  )
  const {data: getExportDisabled, error: errorGetExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        company: COMPANY_ID,
        table: 'company_organizations',
      },
    }
  )

  const [openImport, setImport] = useState(false)
  const [openExport, setExport] = useState(false)

  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  useEffect(() => {
    refetchParent()
  }, [])

  if (
    (data === undefined && error === undefined) ||
    loadingParent ||
    loadingGetBaloonStatus
  ) {
    return <Loading />
  } else if (
    error !== undefined ||
    errorParent !== undefined ||
    mutationImportError !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationExportError !== undefined ||
    closeBaloonExportError !== undefined ||
    errorGetExportBaloon !== undefined ||
    errorGetExportDisabled !== undefined
  ) {
    return (
      <div>
        {error}
        {errorParent}
      </div>
    )
  }

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  const onDeleteOrgUnit = () => {
    const variables = {
      id: dataOrg.id,
    }
    deleteOrgUnit({variables: variables})
      .then(() => {
        enqueueSnackbar('Organization Unit deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete Organization Unit failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 1000,
          }
        )
      })
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleDownload = () => {
    setExport(!openExport)
    if (openExport) {
      mutationExport({
        variables: {
          table: 'company_organizations',
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleOpenAlert = data => {
    const countJobProfile =
      data &&
      data.company_job_profiles_aggregate &&
      data.company_job_profiles_aggregate.aggregate &&
      data.company_job_profiles_aggregate.aggregate.count > 0
    setDataOrg(data)
    countJobProfile ? setOpenCantDel(true) : setOpenAlert(true)
  }

  const handleCloseAlert = status => {
    status === 'delete' ? setOpenAlert(false) : setOpenCantDel(false)
  }

  const importMutation = async () => {
    try {
      const response = await mutationImport({
        variables: {
          table: 'company_organizations',
          file: fileURL,
        },
      })
      // eslint-disable-next-line no-console
      console.log(response)
      refetch()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const handleClickOpenImport = () => {
    setImport(true)
  }
  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  const handleCloseImport = () => {
    setImport(false)
  }

  const parentChecker =
    checkParent && checkParent.company_job_profiles.length !== 0
      ? checkParent.company_job_profiles[0]
      : false

  const column = [
    {
      title: 'Kode Unit Organisasi',
      field: 'code',
      headerStyle: {minWidth: 160},
      cellStyle: {minWidth: 160},
      defaultSort: 'asc',
    },
    {
      title: 'Nama Unit Organisasi',
      field: 'name',
      headerStyle: {minWidth: 175},
      cellStyle: {minWidth: 175},
      render: rowData => {
        return (
          <>
            <TextBlack>{rowData.name}</TextBlack>
            {rowData.organization_parent === null ? (
              <TextGrey>(Parent)</TextGrey>
            ) : (
              ''
            )}
          </>
        )
      },
    },
    {
      title: 'Induk',
      field: 'organization_parent.name',
      render: rowData => {
        return (
          <>
            <TextBlack>{rowData?.organization_parent?.name ?? ''}</TextBlack>
            <TextGrey>
              {`${trimString(rowData?.organization_parent?.code ?? '', 30)}${
                rowData?.organization_parent?.code?.length > 30 ? '...' : ''
              }`}
            </TextGrey>
          </>
        )
      },
    },
    {
      title: 'Level Organisasi',
      field: 'organization_level.name',
      render: rowData => {
        return (
          <>
            <TextBlack>{rowData?.organization_level?.name ?? ''}</TextBlack>
            <TextGrey>
              {`${trimString(rowData?.organization_level?.code ?? '', 30)}${
                rowData?.organization_level?.code?.length > 30 ? '...' : ''
              }`}
            </TextGrey>
          </>
        )
      },
    },
    {
      title: 'Total Posisi',
      field: 'company_job_profiles_aggregate.aggregate.count',
    },
    {
      title: 'Aksi',
      field: 'action',
      render: rowData => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>
              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: '/organization/position/detail',
                    state: {
                      active: 'detail-position',
                      id: rowData.id,
                      jobName: rowData.name,
                      parentChecker: parentChecker,
                    },
                  }}
                  variant="h6"
                >
                  Lihat Posisi
                </StyledMenuItem>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: `/organization/organization-unit/edit`,
                    state: {
                      active: 'edit-organization-unit',
                      status: 'Edit',
                      data: rowData,
                      idParentOUJobProfile:
                        data &&
                        data.parent_job_profile &&
                        data.parent_job_profile[0] &&
                        data.parent_job_profile[0].company_organization.id,
                      idParentTopOU:
                        data &&
                        data.parent_organization &&
                        data.parent_organization[0] &&
                        data.parent_organization[0].id,
                    },
                  }}
                  variant="h6"
                >
                  Ubah
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleOpenAlert(rowData)
                  }}
                >
                  Hapus
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>
      ),
    },
  ]

  return (
    <RootContainer>
      <ImportBaloon
        data={getBaloonStatus}
        closeBaloonMutation={closeBaloonMutation}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
      />
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
      />
      <OrganizationContainer className={classes.root}>
        <PaperContainer>
          <EnhancedToolbar>
            <BigTextBlackBold>Unit Organisasi</BigTextBlackBold>
            <WrapperContent>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <WhiteButton
                      {...bindTrigger(popupState)}
                      variant="contained"
                      size="large"
                    >
                      <TextDarkBlue>Selebihnya</TextDarkBlue>
                      <ArrowDropDown
                        className={classes.marginLeft10}
                        fontSize="small"
                      />
                    </WhiteButton>
                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        className={classes.menuMore}
                        variant="h6"
                        onClick={() => {
                          handleClickOpenImport()
                          popupState.close()
                        }}
                        disabled={
                          getBaloonStatus.company_import_data_logs.length > 0 &&
                          getBaloonStatus.company_import_data_logs[0].status ===
                            'WAITING'
                        }
                      >
                        {getBaloonStatus.company_import_data_logs.length > 0 &&
                        getBaloonStatus.company_import_data_logs[0].status ===
                          'WAITING' ? (
                          <TextGrey>Importing on proces...</TextGrey>
                        ) : (
                          <TextDarkBlue>Impor Unit Organisasi</TextDarkBlue>
                        )}
                      </StyledMenuItem>
                      <GenerateTooltip
                        title={
                          (getExportBaloon &&
                            getExportBaloon.company_export_data_logs &&
                            getExportBaloon.company_export_data_logs.length >
                              0 &&
                            getExportBaloon.company_export_data_logs[0]
                              .status === 'FAILED') ||
                          disbaledButtonExport(
                            getExportDisabled &&
                              getExportDisabled.company_export_data_logs &&
                              getExportDisabled.company_export_data_logs[0] &&
                              getExportDisabled.company_export_data_logs[0]
                                .date_added
                          )
                            ? `Anda hanya dapat menghasilkan data sekali sehari. Untuk mengunduh data gunakan link yang sudah dibuat`
                            : ``
                        }
                        placement="left"
                      >
                        <span>
                          <StyledMenuItem
                            variant="h6"
                            className={classes.menuMore}
                            disabled={
                              (getExportBaloon &&
                                getExportBaloon.company_export_data_logs &&
                                getExportBaloon.company_export_data_logs
                                  .length > 0 &&
                                getExportBaloon.company_export_data_logs[0]
                                  .status === 'FAILED') ||
                              disbaledButtonExport(
                                getExportDisabled &&
                                  getExportDisabled.company_export_data_logs &&
                                  getExportDisabled
                                    .company_export_data_logs[0] &&
                                  getExportDisabled.company_export_data_logs[0]
                                    .date_added
                              )
                            }
                            onClick={() => {
                              handleDownload()
                              popupState.close()
                            }}
                          >
                            Ekspor Data
                          </StyledMenuItem>
                        </span>
                      </GenerateTooltip>
                    </StyledMenus>
                  </>
                )}
              </PopupState>
              <WhiteButton
                variant="contained"
                size="large"
                className={classes.marginLeft10}
                onClick={() => setOpenModal(true)}
              >
                <TextDarkBlue>Struktur Organisasi</TextDarkBlue>
              </WhiteButton>
              <Button
                className={classes.marginLeft10}
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to={{
                  pathname: `/organization/organization-unit/add`,
                  state: {
                    active: 'add-organization-unit',
                    status: 'Add',
                  },
                }}
              >
                Tambah Unit Organisasi
              </Button>
            </WrapperContent>
          </EnhancedToolbar>
          <MaterialTable
            options={{
              ...TableOptions,
              selection: false,
              showTitle: false,
              toolbar: false,
              emptyRowsWhenPaging: false,
            }}
            isLoading={loading}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <NoDataListComponent search={searchText} />
                ),
              },
            }}
            columns={column}
            data={data.company_organizations}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            totalCount={data.total.aggregate.count}
            page={pagination.offset}
            pageSize={pagination.limit}
          />
        </PaperContainer>
        <OSTreeModal open={OpenModal} onClose={handleCloseModal} />
        <DeletePopup
          open={OpenAlert}
          handleClose={() => handleCloseAlert('delete')}
          id={dataOrg.id}
          name={dataOrg.name}
          mutation={() => onDeleteOrgUnit()}
        />
        <ImportPopup
          open={openImport}
          position={'Organization Unit'}
          handleClose={handleCloseImport}
          mutation={importMutation}
          fileTemplate={
            'https://public-media.wlb.co.id/77641e50-aaaa-4dba-b34f-def2c546d19f-1602486060033.xlsx'
          }
          isAlreadyImported={isAlreadyImported}
          setIsAlreadyImported={setIsAlreadyImported}
          setImport={setImport}
          fileURL={fileURL}
          setFileURL={setFileURL}
          closeBaloon={closeBaloonMutation}
          errorsImport={
            getBaloonStatus.company_import_data_logs.length > 0 &&
            getBaloonStatus.company_import_data_logs[0].reports &&
            getBaloonStatus.company_import_data_logs[0].reports.failed.length >
              0
              ? getBaloonStatus.company_import_data_logs[0].reports.failed
              : []
          }
        />
        <AlertDialog
          open={OpenCantDel}
          handleClose={() => handleCloseAlert('cantDelete')}
          feature={`Tidak dapat menghapus ${dataOrg.name}`}
          name={dataOrg.name}
          fMessage="Anda tidak dapat menghapus "
          lMessage=" karena sedang digunakan pada posisi saat ini "
          type="Confirm"
          positiveTitleButton="Konfirmasi"
        />
        <AlertDialog
          open={openExport}
          handleClose={handleDownload}
          feature="Ekspor data sedang diproses!"
          message="Ekspor data sedang diproses! Kami akan mengirimkan email kepada Anda jika sudah selesai"
          type="Confirm"
          positiveTitleButton="Konfirmasi"
        />
      </OrganizationContainer>
    </RootContainer>
  )
}
export default ListOrgUnit
