import gql from 'graphql-tag'

export const GET_LIST_RESPONDENTS = gql`
  query($survey: bigint, $search: String, $offset: Int, $limit: Int) {
    total: multirater_respondents_aggregate(
      where: {
        survey: {_eq: $survey}
        _or: [{global_user: {name: {_ilike: $search}}}]
      }
    ) {
      aggregate {
        count
      }
    }

    multirater_respondents(
      where: {
        survey: {_eq: $survey}
        _or: [{global_user: {name: {_ilike: $search}}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      global_user {
        name
        email
        people_work_placements {
          company_job_profile {
            company_employee_position {
              id
              name
            }
            company_address {
              id
              office_name
            }
          }
        }
      }
    }
  }
`
