import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router'
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import ManageLanding from './tabs/MicroLearningManageLanding'
import ManageQuestion from './tabs/MicroLearningManageQuestion'
import ManageSchedule from './tabs/MicroLearningScheduleComponent'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_COUNT_SLUG,
  GET_FILTER_BY_MICROLEARNING_ID,
  GET_MICRO_LEARNING_BY_ID,
  GET_QUESTIONS_BY_MICROLEARNING_ID,
  GET_MICROLEARNING_SCHEDULE,
} from '../../../../graphql/queries'
import {
  ADD_MICROLEARNING,
  ASSIGN_TO_MICROLEARNING,
} from '../../../../graphql/mutations/learning/micro-learning/addMicroLearning.mutation'
import {DELETE_MICROLEARNING} from '../../../../graphql/mutations/learning/micro-learning/deleteMicrolearning.mutation'
import MicroLearningAssign from './tabs/MicroLearningAssignComponent'
import {useSnackbar} from 'notistack'
import {COMPANY_ID} from '../../../../utils/globals'
import {manipulateFilter} from '../../../time/attendance/work-schedule/filterDataAssignment'
import {capitalize, disabledSpecialChar} from '../../../../utils/helpers'
import {
  UPDATE_ASSIGNMENT_MICROLEARNING,
  UPDATE_MICROLEARNING,
  UPDATE_MICROLEARNING_SCHEDULE,
} from '../../../../graphql/mutations/learning/micro-learning/updateMicrolearning.mutation'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },

  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  mb16: {
    marginBottom: '16px !important',
  },
  paper: {
    minHeight: '70vh',
  },
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
}))

const style = {
  colorPrimary: {
    color: '#014A62',
  },
  saveBtn: {
    backgroundColor: '#039BE5',
    color: '#fff',
    textTransform: 'none',
    marginLeft: 12,
  },
  header: {
    backgroundColor: '#fff',
    width: '-webkit-fill-available',
    boxShadow: '0px 3px 6px #0000001A',
    zIndex: 1,
  },
  grey: {color: '#A9A8A8'},
  chipSkyBlue: {backgroundColor: '#039BE5', marginRight: 16, marginTop: 16},
  iconSkyBlue: {
    color: '#039BE5',
    border: '1px solid #039BE5',
    padding: 5,
    marginTop: 12,
  },
  iconDisabled: {
    color: '#A9A8A8',
    border: '1px solid #A9A8A8',
    padding: 5,
    marginTop: 12,
  },
  iconRed: {
    color: '#DD5962',
    border: '1px solid #DD5962',
    padding: 5,
    marginTop: 12,
  },
  addContainer: {
    background: '#F7F8F9 0% 0% no-repeat padding-box',
    border: '1px dashed #A9A8A8',
    width: '400px',
    padding: '85px 0',
    textAlign: 'center',
    marginTop: 40,

    position: 'relative',
  },
  input: {
    opacity: 0.0,
    MsFilter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)',
    filter: 'alpha(opacity=0)',
    MozOpacity: 0.0,
    KhtmlOpacity: 0.0,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  expansion: {background: '#f7f8f9', marginBottom: 20},
  customEditorFooter: {
    width: '100%',
    height: 20,
    borderRadius: '0 0 5px 5px',
    backgroundColor: '#014a62',
  },
  textSkyBlue: {color: '#039BE5'},
  uploadedImg: {width: 400, marginRight: 16},
  answerContainer: {
    border: '1px solid #E5E5E5',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    padding: 6,
    marginBottom: 16,
  },
  topicContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const FormMicrolearning = props => {
  const {id} = props.match.params
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [confirmType, setConfirmType] = useState('')
  const [value, setValue] = useState({
    name: '',
    description: '',
    slug: '',
    banner_url: '',
    topic: [],
    category_id: '',
    level: '',
    language_id: '',
    subtitle: '',
    status: 'DRAFT',
    subcategory: '',
    price: '',
    type: '',
  })

  const [mlId, setmlId] = useState(null)

  const [isError, setIsError] = useState(false)

  const [filter, setFilter] = useState({
    filterJobFunction: {},
    filterJobFamily: {},
    filterJobTitle: {},
    filterOrgUnit: {},
    filterOrgLevel: {},
    filterJobLevel: {},
    filterJobGrade: {},
    filterOffice: {},
    filterPosition: {},
  })

  const [scheduleValue, setScheduleValue] = useState({
    dailyQuestion: 0,
    scheduleTime: null,
    schedule: '0000000',
  })

  const [countApplied, setCountApplied] = useState(null)

  const {data} = useQuery(GET_MICRO_LEARNING_BY_ID, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
      createdByOrgId: COMPANY_ID,
    },
    skip: !id,
  })

  const {data: countSlug} = useQuery(GET_COUNT_SLUG, {
    variables: {
      slug: value.slug,
      id: mlId,
    },
    skip: !mlId,
  })

  const {data: dataQuest, refetch: refetchQuest} = useQuery(
    GET_QUESTIONS_BY_MICROLEARNING_ID,
    {
      variables: {
        id: mlId || id,
      },
      skip: !id && activeStep !== 1,
    }
  )

  const {data: dataFilter} = useQuery(GET_FILTER_BY_MICROLEARNING_ID, {
    fetchPolicy: 'cache-and-network',
    variables: {
      microlearningId: id,
    },
    skip: !id,
  })

  const {data: schedule} = useQuery(GET_MICROLEARNING_SCHEDULE, {
    variables: {
      id: id,
      createdByOrgId: COMPANY_ID,
    },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  })

  const [addMicroLearning] = useMutation(ADD_MICROLEARNING)
  const [assignToMicrolearning] = useMutation(ASSIGN_TO_MICROLEARNING)
  const [mutationUpdateMicrolearning] = useMutation(UPDATE_MICROLEARNING)
  const [updateAssignmentMicrolearning] = useMutation(
    UPDATE_ASSIGNMENT_MICROLEARNING
  )
  const [updateSchedule] = useMutation(UPDATE_MICROLEARNING_SCHEDULE, {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-staff',
      },
    },
  })
  const [deleteMicroLearning] = useMutation(DELETE_MICROLEARNING)

  useEffect(() => {
    if (id) {
      setmlId(id)
      refetchQuest()
      if (data) {
        const {
          name,
          status,
          description,
          slug,
          icon_url,
          academy_course_category,
          global_language,
          topic,
          level,
          subtitle,
          price,
          type,
        } = data.microlearnings[0]

        setValue({
          name,
          description,
          slug,
          banner_url: icon_url,
          topic: !topic ? [] : topic,
          category_id:
            academy_course_category && academy_course_category.parent
              ? academy_course_category.parent.id
              : academy_course_category
              ? academy_course_category.id
              : null,
          level,
          language_id: global_language && global_language.id,
          subtitle,
          status,
          price,
          subcategory:
            academy_course_category && academy_course_category.parent
              ? academy_course_category.id
              : null,
          type,
        })
      }

      if (dataFilter) {
        const _dataFilter =
          dataFilter.microlearning_organization_assignments.length > 0 &&
          dataFilter.microlearning_organization_assignments[0].filter
        if (_dataFilter) {
          const _newFilter = {}
          const isFilterExist = (data, stateFilter) => {
            let inOrNin = {_in: _dataFilter[data]}
            if (data.includes('exclude')) {
              inOrNin = {_nin: _dataFilter[data]}
            }
            if (inOrNin._in || inOrNin._nin) {
              _newFilter[stateFilter] = {
                ..._newFilter[stateFilter],
                ...inOrNin,
              }
            }
          }
          isFilterExist('filter_exclude_org_unit', 'filterOrgUnit')
          isFilterExist('filter_include_org_unit', 'filterOrgUnit')
          isFilterExist('filter_include_org_level', 'filterOrgLevel')
          isFilterExist('filter_exclude_org_level', 'filterOrgLevel')
          isFilterExist('filter_exclude_position', 'filterPosition')
          isFilterExist('filter_include_position', 'filterPosition')
          isFilterExist('filter_exclude_job_title', 'filterJobTitle')
          isFilterExist('filter_include_job_title', 'filterJobTitle')
          isFilterExist('filter_exclude_job_level', 'filterJobLevel')
          isFilterExist('filter_include_job_level', 'filterJobLevel')
          isFilterExist('filter_exclude_office', 'filterOffice')
          isFilterExist('filter_include_office', 'filterOffice')
          isFilterExist('filter_exclude_job_grade', 'filterJobGrade')
          isFilterExist('filter_include_job_grade', 'filterJobGrade')
          setFilter(_newFilter)
        }
      }

      if (schedule) {
        setScheduleValue({
          dailyQuestion:
            schedule?.microlearning_members[0]?.daily_question || 0,
          scheduleTime:
            schedule?.microlearning_members[0]?.schedule_time || null,
          schedule: schedule?.microlearning_members[0]?.schedule || '0000000',
        })
      }
    }
  }, [data, dataFilter, schedule])

  const handleConfirm = type => {
    setOpen(true)
    setConfirmType(type)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const editVariables = {
    microlearningId: mlId,
    set: {
      name: value.name,
      description: value.description,
      slug: value.slug,
      icon_url: value.banner_url,
      topic: value.topic,
      category_id: value.subcategory,
      level: value.level,
      language_id: value.language_id,
      subtitle: value.subtitle,
      status: value.status,
    },
  }

  const createVariables = {
    microlearning: {
      name: value.name,
      subtitle: value.subtitle,
      slug: value.slug,
      description: value.description,
      language_id: value.language_id,
      level: value.level,
      category_id: value.subcategory,
      topic: value.topic,
      icon_url: value.banner_url,
      type: 'ORG',
      status: 'DRAFT',
    },
  }

  const isSlugExist =
    countSlug && countSlug.microlearnings_aggregate.aggregate.count > 0

  const _dataQuest =
    dataQuest && dataQuest.microlearnings[0].microlearning_questions

  const stepOneValidation =
    !value.name ||
    !value.slug ||
    value.topic.length === 0 ||
    !value.category_id ||
    !value.level ||
    !value.language_id ||
    !value.subcategory ||
    isSlugExist

  const stepThreeValidation =
    scheduleValue.dailyQuestion <= 0 ||
    !scheduleValue.scheduleTime ||
    !scheduleValue.schedule.includes('1')

  const publishValidation =
    (activeStep === 0 && stepOneValidation) ||
    (activeStep === 1 && (!_dataQuest || _dataQuest.length === 0)) ||
    (activeStep === 2 && stepThreeValidation) ||
    (activeStep === 3 && (!countApplied || countApplied === 0))

  const next = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const onError = () => {
    setIsError(true)
    setTimeout(() => {
      setIsError(false)
    }, 3000)
  }

  // logic button save add
  const handleNext = () => {
    if (activeStep === 0) {
      setIsLoading(true)
      if (stepOneValidation) {
        onError()
      } else {
        // pertama kali add
        if (!mlId) {
          addMicroLearning({
            variables: createVariables,
          })
            .then(d => {
              setmlId(d.data.insert_microlearnings_one.id)
              next()
            })
            .catch(e =>
              enqueueSnackbar(JSON.stringify(e), {
                variant: 'error',
              })
            )
        }
        // setelah klik back
        else {
          mutationUpdateMicrolearning({
            variables: editVariables,
          })
            .then(() => {
              next()
            })
            .catch(e =>
              enqueueSnackbar(JSON.stringify(e), {
                variant: 'error',
              })
            )
        }
      }
      setIsLoading(false)
    } else if (activeStep === 1) {
      if (activeStep === 1 && (!_dataQuest || _dataQuest.length === 0)) {
        onError()
      } else {
        next()
      }
    } else if (activeStep === 2) {
      if (stepThreeValidation) {
        onError()
      } else {
        next()
      }
    } else if (activeStep === 3) {
      if (publishValidation) {
        onError()
      } else {
        handlePublish()
      }
    }
  }

  // logic button save edit
  const handleSave = () => {
    const errorPage = page => {
      setActiveStep(page)
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 3000)
    }
    if (stepOneValidation) {
      errorPage(0)
    } else if (_dataQuest.length === 0) {
      errorPage(1)
    } else if (stepThreeValidation) {
      errorPage(2)
    } else if (value.type === 'ORG' && (!countApplied || countApplied === 0)) {
      errorPage(3)
    } else {
      handleConfirm('publish')
    }
  }

  const backAndAlert = () => {
    enqueueSnackbar(`Success ${capitalize(confirmType)} Micro Learning`, {
      variant: 'success',
    })
    props.history.goBack()
  }

  // update schedule
  const onScheduleUpdate = () => {
    updateSchedule({
      variables: {
        microlearningId: mlId || id,
        ...scheduleValue,
      },
    })
  }

  const onAssignNewMicrolearning = () => {
    assignToMicrolearning({
      variables: {
        filter: manipulateFilter(filter),
        microlearningId: mlId,
        ...scheduleValue,
      },
    })
      .then(() => {
        setIsLoading(false)
        backAndAlert()
      })
      .catch(e =>
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
        })
      )
  }

  const onSubmitAssignment = () => {
    const obj = Object.values(filter)
    if (obj.some(val => Object.keys(val).length !== 0)) {
      // jika assignment nya kosong maka ngehit add
      if (
        dataFilter &&
        dataFilter.microlearning_organization_assignments.length === 0
      ) {
        onAssignNewMicrolearning()
      }
      // kalo ada assignment sebelumnya maka hit edit
      else {
        updateAssignmentMicrolearning({
          variables: {
            filter: manipulateFilter(filter),
            orgAssignmentId:
              dataFilter &&
              dataFilter.microlearning_organization_assignments[0].id,
            // ...scheduleValue,
          },
        })
          .then(() => {
            setIsLoading(false)
            backAndAlert()
          })
          .catch(e =>
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          )
      }
    }
  }

  const handlePublish = () => {
    setIsLoading(true)
    const publishVariables = {
      ...editVariables,
      set: {
        ...editVariables.set,
        status: 'ACTIVE',
      },
    }
    // logic untuk edit
    if (id) {
      mutationUpdateMicrolearning({
        variables: publishVariables,
      })
        .then(() => {
          // jika di insert dari admin
          if (value.type === 'ORG') {
            onSubmitAssignment()
          }
          // jika bukan diinsert dari admin maka tanpa hit mutation assignment
          else {
            setIsLoading(false)
            backAndAlert()
          }
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
        .finally(() => {
          setIsLoading(false)
        })
    }
    // logic untuk add
    else {
      mutationUpdateMicrolearning({
        variables: publishVariables,
      })
        .then(() => {
          onAssignNewMicrolearning()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleChange = prop => event => {
    const _value =
      prop === 'name' ||
      prop === 'subtitle' ||
      prop === 'slug' ||
      prop === 'description'
        ? disabledSpecialChar(event.target.value)
        : event.target.value
    const vals = {
      ...value,
      [prop]: _value,
    }
    if (prop === 'category_id') {
      vals.subcategory = null
    }
    setValue(vals)
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setActiveStep(newValue)
  }

  const handleSaveAsDraft = status => {
    if (stepOneValidation) {
      setActiveStep(0)
      setIsError(true)
      setOpen(false)
      setTimeout(() => {
        setIsError(false)
      }, 3000)
    } else {
      setIsLoading(true)
      setConfirmType('save as draft')
      if (!mlId) {
        addMicroLearning({
          variables: createVariables,
        })
          .then(() => {
            setOpen(false)
            props.history.goBack()
          })
          .catch(e => {
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          })
          .finally(() => {
            setConfirmType('')
            setIsLoading(false)
          })
      } else {
        mutationUpdateMicrolearning({
          variables: {
            ...editVariables,
            set: {
              ...editVariables.set,
              status: status || editVariables.set.status,
            },
          },
        })
          .then(() => {
            if (!stepThreeValidation) {
              onScheduleUpdate()
            }
            onSubmitAssignment()
            setOpen(false)
            enqueueSnackbar(`Success Update Micro Learning`, {
              variant: 'success',
            })
            props.history.goBack()
          })
          .catch(e => {
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          })
          .finally(() => {
            setConfirmType('')
            setIsLoading(false)
          })
      }
    }
  }

  const handleDiscard = () => {
    if (activeStep !== 0) {
      const checkPath = props.location.pathname.split('/')[3]
      if (checkPath === 'add') {
        setIsLoading(true)
        deleteMicroLearning({
          variables: {
            id: mlId,
          },
        })
          .then(() => {
            props.history.goBack()
          })
          .catch(e =>
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          )
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        props.history.goBack()
      }
    } else {
      props.history.goBack()
    }
  }

  const SaveAsDraftBtn = props => {
    const {title} = props
    return (
      <Button
        onClick={() => handleSaveAsDraft()}
        size="large"
        variant="outlined"
        color="primary"
        className={classes.backButton}
      >
        {title}
      </Button>
    )
  }

  const steps = ['Halaman Arahan', 'Pertanyaan', 'Jadwal', 'Penerima Tugas']
  if (id && value.type !== 'ORG') {
    steps.pop()
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <ManageLanding
            style={style}
            value={value}
            handleChange={handleChange}
            setValue={setValue}
            isError={isError}
            isSlugExist={isSlugExist}
          />
        )
      case 1:
        return (
          <ManageQuestion
            style={style}
            data={_dataQuest}
            microlearningId={mlId}
            // microlearningId={'7c85c040-195f-45a9-8597-130fa2d39c6f'}
            refetch={refetchQuest}
            isError={isError}
          />
        )

      case 2:
        return (
          <ManageSchedule
            // schedules={schedules}
            // setSchedules={setSchedules}
            // onScheduleChange={onScheduleChange}
            scheduleValue={scheduleValue}
            setScheduleValue={setScheduleValue}
            isError={isError}
          />
        )
      case 3:
        return (
          <MicroLearningAssign
            variables={filter}
            setVars={setFilter}
            isError={isError}
            count={countApplied}
            setCount={setCountApplied}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          {!isLoading && (
            <IconButton
              edge="end"
              aria-label="back"
              onClick={() => handleConfirm('discard')}
            >
              <ArrowBack />
            </IconButton>
          )}
          <AddEditTitle variant="h6">
            {id ? 'Ubah' : 'Buat'} Pembelajaran Mikro {id && '- ' + value.name}
          </AddEditTitle>
        </FormTitleContainer>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {id ? (
              <div>
                <SaveAsDraftBtn title="Simpan Perubahan" />
                {value.status !== 'ACTIVE' && (
                  <Button
                    onClick={handleSave}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Menerbitkan
                  </Button>
                )}
              </div>
            ) : (
              <div>
                {activeStep === 0 ? (
                  <Button
                    className={classes.backButton}
                    onClick={() => handleConfirm('discard')}
                  >
                    Batal
                  </Button>
                ) : (
                  <Button onClick={handleBack} className={classes.backButton}>
                    Sebelumnya
                  </Button>
                )}
                {activeStep < steps.length - 1 && (
                  <SaveAsDraftBtn title="Simpan sebagai draf" />
                )}
                <Button
                  className={
                    activeStep === steps.length - 1 ? classes.backButton : null
                  }
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Menerbitkan' : 'Lanjut'}
                </Button>
                {activeStep === steps.length - 1 && (
                  <SaveAsDraftBtn title="Simpan sebagai draf" />
                )}
              </div>
            )}
          </>
        )}
      </FormToolbar>

      {id ? (
        <Tabs
          value={activeStep}
          onChange={handleChangeTabs}
          indicatorColor="secondary"
          classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
        >
          {steps.map(label => (
            <Tab label={label} key={label} className={classes.tabRoot} />
          ))}
        </Tabs>
      ) : (
        <>
          <Divider className={classes.mb16} />
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<CustomConnector />}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    active: classes.labelActive,
                    completed: classes.labelActive,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.iconRoot,
                      text: classes.iconText,
                      active: classes.iconActive,
                      completed: classes.iconActive,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      )}

      <div style={{padding: '2rem'}}>{getStepContent(activeStep)}</div>
      <AddEdit
        open={open}
        handleClose={() => setOpen(false)}
        feature={`Micro Learning`}
        type={confirmType}
        mutation={confirmType === 'discard' ? handleDiscard : handlePublish}
        disabled={isLoading}
        mutationDraft={() => handleSaveAsDraft('DRAFT')}
      />
    </PaperContainer>
  )
}

export default withRouter(FormMicrolearning)
