import OrganizationLevelPage from './OrganizationLevelPageComponent'

export const OrganizationLevelPageConfig = {
  routes: [
    {
      path: '/organization/organization-level',
      component: OrganizationLevelPage,
      state: 'organization-level',
      exact: true,
    },
    {
      path: '/organization/organization-level/add',
      component: OrganizationLevelPage,
      state: 'add-organization-level',
      exact: false,
    },
    {
      path: '/organization/organization-level/edit',
      component: OrganizationLevelPage,
      state: 'edit-organization-level',
      exact: false,
    },
  ],
}
