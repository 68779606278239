import gql from 'graphql-tag'

export const UPDATE_CLASSROOM_CROSS_MENTORING = gql`
  mutation(
    $classroomId: uuid!
    $avatarUrl: String
    $name: String
    $description: String
    $finished: Boolean
  ) {
    update_classrooms(
      _set: {
        privacy: 0
        avatar_url: $avatarUrl
        name: $name
        rules: $description
        is_finished: $finished
      }
      where: {id: {_eq: $classroomId}}
    ) {
      affected_rows
    }
  }
`
