import React from 'react'

import {
  GreyTypography,
  InformationContainer,
  TypographyCapitalize,
} from '../modalStyles'

import {Typography, Avatar} from '@material-ui/core'
import {goalType} from './validate'
import moment from 'moment'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {
  priority,
  status_data,
  visibility,
} from '../../../../shared-components/data/SharedData'
import {PRIORITY_STYLE, TASK_STATUS_IDN} from '../../constant'

export default function Detail({data, type}) {
  const customStyle = {
    flex: {
      display: 'flex',
    },
    flexFull: {
      display: 'flex',
      width: '100%',
    },
    PR: {
      paddingRight: '3rem',
    },
    pR: {
      paddingRight: '1.5rem',
    },
    buttonSpacer: {
      marginRight: 23,
    },
    assignedWrapper: {
      background: '#eff2f4',
      padding: '1rem',
      minHeight: '243px',
      minWidth: '203px',
      borderRadius: '5px',
    },
    dynamicWidth: {width: type === 'taskDetail' ? '32%' : '50%'},
    textPosition: {
      fontSize: '0.75rem',
    },
    dividerDetail: {
      borderRight: type === 'taskDetail' && '2px solid #a9a8a83d',
    },
    dynamicPadding: {
      paddingLeft: type === 'taskDetail' && 10,
    },
  }

  const isGoal = type === 'goal'

  const dynamicData = isGoal ? data.performance_goals : data.performance_tasks

  return dynamicData.map((res, key) => {
    const formattedStartDate = moment(res.startdate).format('ll')
    const formattedEndDate = moment(res.enddate).format('ll')
    const dataKpi = isGoal ? res.kpi_list : res.performance_goal.kpi_list
    const mainCascading =
      res?.kpi_list?.kpi_cascading_lists?.[0]?.cascading_main

    const typeGoal =
      dataKpi?.kpi_cascading_lists_aggregate?.aggregate?.count > 0

    const status = isGoal
      ? res?.total_task?.aggregate?.count ===
          res?.complete_task?.aggregate?.count &&
        res?.total_task?.aggregate?.count !== 0
        ? 'Completed'
        : 'Akan Dikerjakan'
      : res.status

    const isCompleted =
      status?.toLowerCase() === 'completed' ||
      status?.toLowerCase() === 'complete'

    return (
      <div style={customStyle.flexFull} key={key}>
        <div
          style={{...customStyle.dynamicWidth, ...customStyle.dividerDetail}}
        >
          <InformationContainer>
            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Nama {GLOBAL_GLOSSARY.performance.Goal}
              </GreyTypography>
              <div style={{display: 'flex'}}>
                <Typography
                  variant="body1"
                  style={{color: '#039be5', marginRight: '0.5rem'}}
                >
                  [PF-{isGoal ? res.id : res.performance_goal.id}]
                </Typography>
                <Typography variant="body1">
                  {isGoal ? res.name : res.performance_goal.name}
                </Typography>
              </div>
            </InformationContainer>
            {!isGoal && (
              <InformationContainer>
                <GreyTypography variant="caption" gutterBottom>
                  {`Nama ${GLOBAL_GLOSSARY.performance.Task}`}
                </GreyTypography>
                <div style={{display: 'flex'}}>
                  <Typography
                    variant="body1"
                    style={{color: '#039be5', marginRight: '0.5rem'}}
                  >
                    [PF-{res.id}]
                  </Typography>
                  <Typography variant="body1">{res.name}</Typography>
                </div>
              </InformationContainer>
            )}
            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Status
              </GreyTypography>
              <div style={{display: 'flex'}}>
                <Typography
                  variant="body1"
                  style={{color: isCompleted ? '#4caf50' : '#039be5'}}
                >
                  {isGoal ? status : TASK_STATUS_IDN[status]}
                </Typography>
              </div>
            </InformationContainer>
            {isGoal && typeGoal && (
              <InformationContainer>
                <GreyTypography variant="caption" gutterBottom>
                  Nama Turunan SKP
                </GreyTypography>
                <Typography variant="body1">{mainCascading?.name}</Typography>
                <GreyTypography variant="caption">
                  {`(${
                    status_data?.[mainCascading?.status] ||
                    mainCascading?.status?.toLowerCase() === 'active'
                      ? 'Active'
                      : 'Inactive'
                  })`}
                </GreyTypography>
              </InformationContainer>
            )}

            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Tipe{' '}
                {isGoal
                  ? GLOBAL_GLOSSARY.performance.Goal_short
                  : GLOBAL_GLOSSARY.performance.Task}
              </GreyTypography>
              <div style={{display: 'flex'}}>
                <Typography variant="body1">
                  {`${
                    typeGoal
                      ? GLOBAL_GLOSSARY.performance['Company Goal']
                      : GLOBAL_GLOSSARY.performance['Personal Goal']
                  } ${dataKpi?.type || res.method_type ? '-' : ''} ${goalType[
                    dataKpi?.type || res.method_type
                  ] ?? ''}`}
                </Typography>
              </div>
            </InformationContainer>
            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Aspek {GLOBAL_GLOSSARY.performance.Goal_short}
              </GreyTypography>
              <Typography variant="body1">
                {isGoal
                  ? dataKpi?.kpi_aspect?.name || '-'
                  : res.kpi_aspect?.name || dataKpi?.kpi_aspect?.name || '-'}
              </Typography>
            </InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              Perspektif
            </GreyTypography>
            <Typography variant="body1">
              {dataKpi?.kpi_perspective?.name}
            </Typography>
          </InformationContainer>
          {/* <InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              Weight
            </GreyTypography>
            <Typography variant="body1">{res.weight}%</Typography>
          </InformationContainer> */}

          <InformationContainer style={customStyle.flex}>
            <div style={customStyle.PR}>
              <GreyTypography variant="caption" gutterBottom>
                Tanggal Mulai
              </GreyTypography>
              <Typography variant="body1">{formattedStartDate}</Typography>
            </div>
            <div>
              <GreyTypography variant="caption" gutterBottom>
                Tanggal Berakhir
              </GreyTypography>
              <Typography variant="body1">{formattedEndDate}</Typography>
            </div>
          </InformationContainer>
        </div>
        <div
          style={{...customStyle.dynamicWidth, ...customStyle.dynamicPadding}}
        >
          {!isGoal && (
            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Visibilitas
              </GreyTypography>
              <TypographyCapitalize variant="body1">
                {visibility?.[res.visibility] || res.visibility?.toLowerCase()}
              </TypographyCapitalize>
            </InformationContainer>
          )}
          <InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              Prioritas
            </GreyTypography>
            <TypographyCapitalize
              variant="body1"
              style={{color: PRIORITY_STYLE[res.priority]}}
            >
              {priority?.[res.priority] || res.priority.toLowerCase()}
            </TypographyCapitalize>
          </InformationContainer>
          <InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              Metrik
            </GreyTypography>
            <Typography variant="body1">
              {isGoal
                ? dataKpi?.formula || '-'
                : res.metric || dataKpi?.formula || '-'}
            </Typography>
          </InformationContainer>
          <InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              Sifat Penilaian
            </GreyTypography>
            <Typography variant="body1">
              {dataKpi?.kpi_scoring_nature?.name}
            </Typography>
          </InformationContainer>
          <InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              {GLOBAL_GLOSSARY.performance['Weight Unit']}
            </GreyTypography>
            <Typography variant="body1">
              {isGoal
                ? dataKpi?.kpi_weight_unit?.name || '-'
                : res.kpi_weight_unit?.name ||
                  dataKpi?.kpi_weight_unit?.name ||
                  '-'}
            </Typography>
          </InformationContainer>
          <InformationContainer style={customStyle.flex}>
            <div style={customStyle.PR}>
              <GreyTypography variant="caption" gutterBottom>
                {isGoal
                  ? GLOBAL_GLOSSARY.performance['Target Base']
                  : `Target ${GLOBAL_GLOSSARY.performance.Task}`}
              </GreyTypography>
              <Typography variant="body1">
                {res.target} {res.kpi_weight_unit?.name}
              </Typography>
            </div>
            {/* 
            <div>
              <GreyTypography variant="caption" gutterBottom>
                Stretched Target
              </GreyTypography>
              <Typography variant="body1">
                {res.target_stretch}{' '}
                {res.kpi_weight_unit && res.kpi_weight_unit.name}
              </Typography>
            </div> */}
          </InformationContainer>
          {type === 'taskDetail' && (
            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Progress
              </GreyTypography>
              <Typography variant="body1">
                {`${res.progress} (${
                  res.progress_percentage
                    ? res.progress_percentage.toFixed(2)
                    : 0
                }%)`}
              </Typography>
            </InformationContainer>
          )}
        </div>
        {type === 'taskDetail' && (
          <div style={{width: '34%'}}>
            <div style={customStyle.assignedWrapper}>
              <GreyTypography variant="caption">Assigned To</GreyTypography>
              {res.performance_task_assignments &&
                res.performance_task_assignments.map((assignee, index) => {
                  return (
                    <div
                      style={{display: 'flex', padding: '0.5rem 0 '}}
                      key={index}
                    >
                      <Avatar
                        alt={assignee.global_user && assignee.global_user.name}
                        style={customStyle.buttonSpacer}
                        src={
                          assignee?.global_user?.avatar ||
                          require('../../../../../assets/images/default-avatar.png')
                        }
                      />
                      <div>
                        <Typography variant="body2" color="secondary">
                          {assignee.global_user && assignee.global_user.name}
                        </Typography>
                        <Typography style={customStyle.textPosition}>
                          {
                            assignee?.global_user?.people_work_placements?.[0]
                              ?.company_job_profile?.title
                          }
                        </Typography>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        )}
      </div>
    )
  })
}
