import gql from 'graphql-tag'

export const GET_COMPETENCY_CATEGORY = gql`
  query($offset: Int, $limit: Int, $search: String) {
    total: competency_categories_aggregate(
      where: {
        _or: [
          {code: {_ilike: $search}}
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    competency_categories(
      where: {
        _or: [
          {code: {_ilike: $search}}
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
        ]
      }
      order_by: [{category_parent: {name: asc_nulls_last}}]
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
      description
      icon
      category_parent {
        id
        name
        icon
      }
    }
  }
`

// Doc : https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/category/DETAIL%20CATEGORY.md
export const GET_DETAIL_COMP_CATEGORY = gql`
  query($id: bigint) {
    competency_categories(where: {id: {_eq: $id}}) {
      id
      code
      name
      description
      icon
      category_parent {
        id
        name
        icon
      }
    }
  }
`

// Doc : https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/category/INSERT%20CATEGORY.md
export const GET_PARENT_CATEGORY = gql`
  query($company: uuid) {
    competency_categories(
      where: {company: {_eq: $company}}
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`

export const CATEGORY_COMPETENCY_ICON = gql`
  query globalIcon {
    global_icon_directories {
      id
      label
      source
    }
  }
`

export const CHECK_CODE_CATEGORY = gql`
  query checkCodeCategoryCompetency($company: uuid!, $code: String!) {
    competency_categories_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`
