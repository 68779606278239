import React, {useState, useContext, useEffect} from 'react'
import moment from 'moment'
import {Icon, IconButton, Typography, Button, MenuItem} from '@material-ui/core'
import {
  CalendarToolbarContainer,
  CalendarNavigate,
} from '../../leave/LeavePageStyles'
import {ThinTextfield} from '../../../../GlobalStyles'
import {Views} from 'react-big-calendar'
import {Link} from 'react-router-dom'
import AddEvent from './detail-calendar/AddEvent'
import {ADD_HOLIDAY} from '../../../../graphql/mutations/index'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {DataContext} from '../../../../ContextConfig'

export default function CalendarToolbar(toolbar) {
  const {onView, setShowChip, onNavigate, date, setCurrentDate} = toolbar
  const chooser = {
    year: true,
    month: false,
    week: false,
    day: false,
    schedule: false,
    chooserCalendar: 'year',
  }

  const {dataContext, setDataContext} = useContext(DataContext)
  const [openEvent, setOpenEvent] = useState(false)
  const [addHolidays] = useMutation(ADD_HOLIDAY)
  const [change, setChange] = useState({
    name: '',
    type: '',
    date: new Date(),
    description: '',
  })
  const {enqueueSnackbar} = useSnackbar()

  const handleChange = name => event => {
    setChange({...change, [name]: event.target.value})
  }

  useEffect(() => {
    if (dataContext === null) {
      setDataContext(chooser)
    } else {
      if (
        dataContext.chooserCalendar === 'month' ||
        dataContext.chooserCalendar === 'week' ||
        dataContext.chooserCalendar === 'day'
      ) {
        setShowChip(true)
      } else if (
        dataContext.chooserCalendar === 'year' ||
        dataContext.chooserCalendar === 'schedule'
      ) {
        setShowChip(false)
      }
    }
  }, [])

  // useEffect(() => {
  //   console.log({chooserCalendar})
  //   if (
  //     chooserCalendar === 'month' ||
  //     chooserCalendar === 'week' ||
  //     chooserCalendar === 'day'
  //   ) {
  //     setShowChip(true)
  //   } else if (chooserCalendar === 'year' || chooserCalendar === 'schedule' &&){
  //     setShowChip(false)
  //   }

  // }, [chooserCalendar, showChip])

  const handleChooserCalendar = event => {
    switch (event.target.value) {
      case 'year':
        onView('year')
        setDataContext({
          year: true,
          month: false,
          week: false,
          day: false,
          schedule: false,
          chooserCalendar: 'year',
        })
        // setShowChip(false)
        break
      case 'month':
        onView(Views.MONTH)
        setDataContext({
          year: false,
          month: true,
          week: false,
          day: false,
          schedule: false,
          chooserCalendar: 'month',
        })
        // setShowChip(true)
        break
      case 'week':
        onView(Views.WEEK)
        setDataContext({
          year: false,
          month: false,
          week: true,
          day: false,
          schedule: false,
          chooserCalendar: 'week',
        })
        // setShowChip(true)
        break
      case 'day':
        onView(Views.DAY)
        setDataContext({
          year: false,
          month: false,
          week: false,
          day: true,
          schedule: false,
          chooserCalendar: 'day',
        })
        // setShowChip(true)
        break
      case 'schedule':
        onView(Views.AGENDA)
        setDataContext({
          year: false,
          month: false,
          week: false,
          day: false,
          schedule: true,
          chooserCalendar: 'schedule',
        })
        // setShowChip(false)
        break
      default:
        onView('year')
        break
    }
  }

  const handleDate = (name, date) => {
    setChange({...change, [name]: date})
  }

  const handleOpenAddEvent = () => {
    setOpenEvent(true)
  }

  const handleCloseEvent = () => {
    setOpenEvent(false)
  }

  const AddHoliday = () => {
    const data = {
      name: change.name,
      description: change.description,
      type: change.type,
      date: change.date,
    }
    addHolidays({variables: {data}})
      .then(() => {
        enqueueSnackbar('Holiday Added', {
          variant: 'success',
          autoHideDuration: 1000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Added Holiday failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }
  const goToBack = () => {
    // console.log(date.getYear())
    if (dataContext && dataContext.day) {
      date.setDate(date.getDate() - 1)
    } else if (dataContext && dataContext.week) {
      date.setDate(date.getDate() - 7)
    } else if (dataContext && dataContext.month) {
      date.setMonth(date.getMonth() - 1)
    } else if (
      (dataContext && dataContext.year) ||
      (dataContext && dataContext.schedule)
    ) {
      date.setMonth(date.getMonth() - 12)
    }
    setCurrentDate(getCurrentDate(date))
    onNavigate('prev')
  }

  const goToNext = () => {
    if (dataContext && dataContext.day) {
      date.setDate(date.getDate() + 1)
    } else if (dataContext && dataContext.week) {
      date.setDate(date.getDate() + 7)
    } else if (dataContext && dataContext.month) {
      date.setMonth(date.getMonth() + 1)
    } else if (
      (dataContext && dataContext.year) ||
      (dataContext && dataContext.schedule)
    ) {
      date.setMonth(date.getMonth() + 12)
    }
    setCurrentDate(getCurrentDate(date))
    onNavigate('next')
  }

  // const goToCurrent = () => {
  //   const now = new Date()
  //   date.setMonth(now.getMonth())
  //   date.setYear(now.getFullYear())
  //   onNavigate('current')
  // }

  const label = () => {
    const dates = moment(date)
    return dataContext && dataContext.day ? (
      <span>
        <span style={{color: '#014a62', fontSize: '20px'}}>
          {dates.format('LL')}
        </span>
      </span>
    ) : (dataContext && dataContext.year) ||
      (dataContext && dataContext.schedule) ? (
      <span>
        <span style={{color: '#014a62', fontSize: '20px'}}>
          {dates.format('YYYY')}
        </span>
      </span>
    ) : (
      <span>
        <b style={{color: '#014a62', fontSize: '20px'}}>
          {dates.format('MMMM')}{' '}
        </b>
        <span style={{color: '#014a62', fontSize: '20px'}}>
          {dates.format('YYYY')}
        </span>
      </span>
    )
  }

  return (
    <CalendarToolbarContainer>
      <div style={{width: '140px'}}>
        {/* <Button
          variant="contained"
          color={dataContext && dataContext.month ? 'primary' : 'default'}
          onClick={Month}
        >
          Month
        </Button>
        <Button
          variant="contained"
          color={dataContext && dataContext.day ? 'primary' : 'default'}
          onClick={Day}
        >
          Day
        </Button> */}
        <ThinTextfield
          style={{width: '200px'}}
          id="calendar-chooser"
          select
          value={dataContext && dataContext.chooserCalendar}
          onChange={handleChooserCalendar}
          variant="outlined"
        >
          <MenuItem value="year">Year</MenuItem>
          <MenuItem value="month">Month</MenuItem>
          <MenuItem value="week">Week</MenuItem>
          <MenuItem value="day">Day</MenuItem>
          <MenuItem value="schedule">Schedule</MenuItem>
        </ThinTextfield>
      </div>
      {/* style di CalendarNavigate bisa di hapus jika add event sudah fix fitur nya */}
      <CalendarNavigate
        style={{
          width: '52vw',
          display: 'flex',
          justifyContent: 'center',
          marginRight: '3vw',
        }}
      >
        <IconButton onClick={goToBack}>
          <Icon fontSize="large" style={{color: '#014a62'}}>
            chevron_left
          </Icon>
        </IconButton>
        <Typography variant="h6">{label()}</Typography>
        <IconButton onClick={goToNext}>
          <Icon fontSize="large" style={{color: '#014a62'}}>
            chevron_right
          </Icon>
        </IconButton>
      </CalendarNavigate>
      {/* <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenAddEvent}
        >
          Add Event
        </Button>
      </div> */}
      <AddEvent
        open={openEvent}
        handleClose={handleCloseEvent}
        handleChange={handleChange}
        handleDate={handleDate}
        change={change}
        mutation={AddHoliday}
        handleOpen={handleOpenAddEvent}
      />
      <div style={{width: '140px'}}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={{
            pathname: `/time/calendar/add-event`,
            state: {active: 'add-event'},
          }}
          onClick={() => setDataContext(chooser)}
        >
          Add Event
        </Button>
      </div>
    </CalendarToolbarContainer>
  )
}

const getCurrentDate = (date) => {
  const year = date?.getFullYear()
  return { startDate: `${year}-01-01`, endDate: `${year}-12-31` }
}