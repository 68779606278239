import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {
  Button,
  IconButton,
  Divider,
  makeStyles,
  TextField,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
  FormContainer,
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
  ThinTextfield,
  StyledReactSelect,
} from '../../../../GlobalStyles'
import {
  ADD_COMPETENCY_CATEGORY,
  UPDATE_COMPETENCY_CATEGORY,
} from '../../../../graphql/mutations'
import {
  GET_PARENT_CATEGORY,
  CATEGORY_COMPETENCY_ICON,
  GET_DETAIL_COMP_CATEGORY,
  CHECK_CODE_CATEGORY,
} from '../../../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {COMPANY_ID} from '../../../../utils/globals'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import ModalIcon from './PopupIcon'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  iconButton: {
    height: '40px !important',
    marginTop: '10px',
  },
  emptyIcon: {
    height: '38px',
    width: '38px',
    border: '1px solid #BCBCBC',
    borderRadius: '3px',
    margin: '10px 10px 0px 0px',
  },
  imgIcon: {
    height: '38px',
    width: '38px',
    margin: '10px 20px 0px 0px',
  },
  textRed: {
    color: '#FF0000',
    fontSize: '12px',
  },
}))

const CategoryForm = props => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const id = props?.match?.params?.id

  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [openImage, setOpenImage] = useState(false)
  const [badge, setBadge] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [parentValue, setParentValue] = useState(null)
  const [switchChecked, setSwitchChecked] = useState(null)
  const [parentOption, setParentOption] = useState([])
  const [value, setValue] = useState({
    code: '',
    name: '',
    description: '',
  })

  const [insertCategory] = useMutation(ADD_COMPETENCY_CATEGORY)
  const [updateCategory] = useMutation(UPDATE_COMPETENCY_CATEGORY)

  const {data, loading, refetch} = useQuery(GET_PARENT_CATEGORY, {
    variables: {company: COMPANY_ID},
  })

  const {data: details} = useQuery(GET_DETAIL_COMP_CATEGORY, {
    variables: {id},
    skip: !id,
  })

  const {data: icons} = useQuery(CATEGORY_COMPETENCY_ICON)

  const {data: isCodeAvailable, refetch: refetchIsCodeAvailable} = useQuery(
    CHECK_CODE_CATEGORY,
    {
      variables: {company: COMPANY_ID, code: value.code},
    }
  )

  const goBack = () => {
    return props.history.goBack()
  }

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (data) {
      const options = data?.competency_categories.map(res => {
        return {value: res.id, label: res.name}
      })
      setParentOption(options)
    }
    if (details) {
      const detail = details.competency_categories[0]

      setValue({
        code: detail.code,
        name: detail.name,
        description: detail.description,
      })
      setSwitchChecked(!detail.category_parent)
      if (detail.category_parent) {
        setParentValue({
          value: detail.category_parent.id,
          label: detail.category_parent.name,
        })
      }
      setBadge({source: detail.icon})
    }
  }, [data, details])

  const handleConfirm = type => {
    setOpen(true)
    setConfirmType(type)
  }

  const handleChangeParent = val => {
    setParentValue(val)
  }

  const handleChange = name => event => {
    const _value = {
      ...value,
      [name]: event.target.value,
    }

    setValue(_value)
    if (name === 'code') {
      refetchIsCodeAvailable()
    }
  }

  const handleSave = () => {
    if (!value.code || !value.name || !badge || !_isCodeAvailable) {
      setError(true)
    } else {
      const variables = {
        ...value,
        icon: badge.source,
        parent: !switchChecked ? parentValue.value : null,
      }

      if (id) {
        updateCategory({variables: {...variables, category: id}})
          .then(() => {
            goBack()
            enqueueSnackbar('Competency Category Updated', {
              variant: 'success',
              autoHideDuration: 3000,
            })
          })
          .catch(e => {
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
              autoHideDuration: 3000,
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        insertCategory({variables: {objects: [variables]}})
          .then(() => {
            goBack()
            enqueueSnackbar('Competency Category Submited', {
              variant: 'success',
              autoHideDuration: 3000,
            })
          })
          .catch(e => {
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
              autoHideDuration: 3000,
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
  }

  let _isCodeAvailable

  if (id && details) {
    _isCodeAvailable =
      value.code === details?.competency_categories[0].code
        ? true
        : isCodeAvailable?.competency_categories_aggregate?.aggregate?.count ===
          0
  } else {
    _isCodeAvailable =
      isCodeAvailable?.competency_categories_aggregate?.aggregate?.count === 0
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handleConfirm('discard')}
          >
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6">
            {id ? 'Ubah' : 'Tambah'} Kategori
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            className={classes.backButton}
            onClick={() => handleConfirm('discard')}
          >
            Batal
          </Button>
          <Button
            onClick={() => handleConfirm('submit')}
            size="large"
            variant="contained"
            color="primary"
          >
            Kirim
          </Button>
        </div>
      </FormToolbar>
      <Divider />
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Kode*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="Tambah Kode"
              value={value.code}
              onChange={handleChange('code')}
              error={(!value.code && error) || !_isCodeAvailable}
              helperText={
                (!value.code && error && 'Bagian ini diperlukan') ||
                (!_isCodeAvailable && 'Kode kategori sudah digunakan')
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Nama*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="Tambah Nama"
              value={value.name}
              onChange={handleChange('name')}
              error={!value.name && error}
              helperText={!value.name && error && 'Bagian ini diperlukan'}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Keterangan</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Tambah Keterangan"
              multiline
              rows={4}
              value={value.description}
              onChange={handleChange('description')}
              error={!value.description && error}
              helperText={
                !value.description && error && 'Bagian ini diperlukan'
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Induk*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <FormControlLabel
              control={
                <Switch
                  checked={switchChecked}
                  // disabled={
                  //   parentChecker ? !(parentChecker && checkParent) : false
                  // }
                  onChange={() => {
                    setSwitchChecked(!switchChecked)
                  }}
                  color="primary"
                />
              }
              label="Tetapkan sebagai induk?"
            />
          </FormChildInput>
          {!switchChecked && (
            <FormChildInput style={{marginTop: '10px'}}>
              <StyledReactSelect
                value={parentValue}
                options={parentOption}
                onChange={handleChangeParent}
                placeholder="Pilih Induk"
                isLoading={loading}
              />
              {/*  {error && !parentValue && (
                <span className={classes.textRed}>Bagian ini diperlukan</span>
              )} */}
            </FormChildInput>
          )}
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Ikon*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <div style={{display: 'flex'}}>
              {badge ? (
                <img
                  alt={badge.label}
                  src={badge.source}
                  className={classes.imgIcon}
                />
              ) : (
                <div className={classes.emptyIcon}></div>
              )}

              <Button
                onClick={() => setOpenImage(true)}
                variant="contained"
                color="primary"
                size="medium"
                className={classes.iconButton}
              >
                Memilih Ikon
              </Button>
            </div>
            {error && !badge && (
              <span className={classes.textRed}>Bagian ini diperlukan</span>
            )}
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>

      <AddEdit
        open={open}
        handleClose={() => setOpen(false)}
        mutation={confirmType === 'discard' ? goBack : handleSave}
        header={CONFIRMATION[confirmType]?.header}
        body={CONFIRMATION[confirmType]?.body}
        button={CONFIRMATION[confirmType]?.button}
        buttonClose="Batal"
        disabled={isLoading}
      />
      <ModalIcon
        open={openImage}
        handleClose={() => setOpenImage(false)}
        icons={icons?.global_icon_directories}
        icon={badge}
        setIcon={setBadge}
      />
    </PaperContainer>
  )
}

export default withRouter(CategoryForm)

const CONFIRMATION = {
  submit: {
    header: 'Kirim Kategori?',
    body: 'Apakah Anda yakin akan mengirimkan kategori ini?',
    button: 'Kirim',
  },
  discard: {
    header: 'Buang Perubahan?',
    body: 'Apakah Anda yakin ingin membuang perubahan yang belum disimpan?',
    button: 'Buang',
  },
}
