import React, {useState, useEffect} from 'react'
import MaterialTable from 'material-table'
import {Link, withRouter} from 'react-router-dom'
import helperStyle, {
  TableOptions,
  TableEmployeeStyled,
  TextBlueBold,
  pageSizeOption,
} from '../../PayrollPageStyles'
import {
  convertToRoundedRupiah,
  employeeColumnSort,
} from '../../../../../utils/helpers'
import ModalPaySlip from '../../shared-components/ModalPayslip'
import EmployeeAvatar from '../../../../shared-components/EmployeeAvatar'
import {GET_LIST_APPROVAL_THR} from '../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {USER_ID} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'

function ApprovalTHRTable(props) {
  // eslint-disable-next-line no-unused-vars
  const {religion, activeStep, searchText, location, filterData} = props
  const {state} = location

  const classes = helperStyle()
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const [openPaySlip, setOpenPaySlip] = useState(false)
  const [employeeId, setEmployeeId] = useState()
  const slicedParams = location.search.split('&')

  const {data: thrProcessData, loading} = useQuery(GET_LIST_APPROVAL_THR, {
    variables: {
      login: USER_ID,
      schedule_id: state.id ? state.id : null,
      position:
        JSON.stringify(filterData.position) === '[]'
          ? null
          : filterData.position,
      organization:
        JSON.stringify(filterData.organization) === '[]'
          ? null
          : filterData.organization,
      job_profile:
        JSON.stringify(filterData.job_profile) === '[]'
          ? null
          : filterData.job_profile,
      rank: JSON.stringify(filterData.rank) === '[]' ? null : filterData.rank,
      office:
        JSON.stringify(filterData.office) === '[]' ? null : filterData.office,
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
      search: `%${searchText}%`,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [searchText, rowsPerPage, filterData])

  return (
    <>
      {thrProcessData && (
        <TableEmployeeStyled>
          {activeStep >= 4 && (
            <ModalPaySlip
              open={openPaySlip}
              handleClose={() => {
                setEmployeeId()
                setOpenPaySlip(false)
              }}
              employeeId={employeeId}
              from={'thr'}
            />
          )}
          <MaterialTable
            data={thrProcessData.employees}
            options={{...TableOptions}}
            totalCount={thrProcessData.employee_count.aggregate.count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={page => {
              setCurrentPage(page)
            }}
            isLoading={thrProcessData && loading}
            onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
            columns={[
              {
                title: 'Employee',
                field: 'employee',
                headerStyle: {minWidth: 350},
                cellStyle: {minWidth: 350},
                customSort: (a, b) => employeeColumnSort(a, b),
                render: rowData => {
                  return (
                    <EmployeeAvatar
                      src={rowData.employee_placement?.global_user.avatar}
                      regno={rowData.employee_placement?.regno}
                      title={
                        rowData.employee_placement?.company_job_profile.title
                      }
                      global_user={rowData.employee_placement?.global_user}
                      city_name={
                        rowData.employee_placement?.company_job_profile
                          .company_address.global_city.name
                      }
                      province_name={
                        rowData.employee_placement?.company_job_profile
                          .company_address.global_province.name
                      }
                      empStatus={rowData.employee_placement?.status}
                      empEndDate={rowData.employee_placement?.end_date}
                    />
                  )
                },
              },
              // {
              //   title: 'Religion',
              //   field: 'religion',
              //   headerStyle: {minWidth: 175},
              //   cellStyle: {minWidth: 175, textAlign: 'left'},
              //   render: () => {
              //     return religion
              //   },
              // },
              {
                title: 'THR',
                field: 'thr',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.thr - b.thr
                },
                render: rowData => convertToRoundedRupiah(rowData.thr, false),
              },
              {
                title: 'Earning',
                field: 'income',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.income - b.income
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.income, false),
              },
              {
                title: 'Deduction',
                field: 'deduction',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.deduction - b.deduction
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.deduction, false),
              },
              {
                title: 'THR Total',
                field: 'thr_total',
                headerStyle: {minWidth: 175, textAlign: 'left'},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.thr_total - b.thr_total
                },
                render: rowData => {
                  // Total Formula thr + income a.k.a allowance - deduction
                  return convertToRoundedRupiah(rowData?.final_thr, false)
                },
              },
              {
                title: 'Action',
                field: 'action',
                headerStyle: {minWidth: 100},
                cellStyle: {minWidth: 100},
                sorting: false,
                render: rowData => {
                  return (
                    <Link
                      to={{
                        pathname: `/financial/payroll/thr-process/thr-detail/${rowData.id}`,
                        state: {
                          active: 'thr-detail',
                          employeeId: rowData.id,
                          filterData: filterData,
                          scheduleId: state.id || null,
                          religion: religion,
                          year: slicedParams[0].replace(/\D/g, ''),
                          month: slicedParams[1].replace(/\D/g, ''),
                        },
                      }}
                      className={classes.link}
                    >
                      <TextBlueBold>Details</TextBlueBold>
                    </Link>
                  )
                },
              },
            ]}
          />
        </TableEmployeeStyled>
      )}
    </>
  )
}

export default withRouter(ApprovalTHRTable)
