import gql from 'graphql-tag'

export const DELETE_CASECADING_KPI = id => {
  return gql`
    mutation {
      update_kpi_cascadings(
        where: {
          id: {
            _in: [${id}]
          }
        }
        _set: {
          deleted_at: "now()"
        }
      ) {
        returning {
          id
        }
      }
    }
  `
}

export const INSERT_CASCADING_KPI = gql`
  mutation createCascading($data: [kpi_cascadings_insert_input!]!) {
    insert_kpi_cascadings(objects: $data) {
      returning {
        id
      }
    }
  }
`

export const DELETE_MULTIPLE_CASCADING_KPI = gql`
  mutation($id: [Int!]!) {
    update_kpi_cascadings(
      where: {id: {_in: $id}}
      _set: {deleted_at: "now()"}
    ) {
      returning {
        id
      }
    }
  }
`

export const UPDATE_CASECADING_KPI_LIST = id => {
  return gql`
    mutation EditCascading($dataEdited: kpi_cascading_lists_set_input) {
      update_kpi_cascading_lists(
        where: {
          id: {
            _eq: ${id}
          }
        }
        _set: $dataEdited
      ) {
        returning {
          id
        }
      }
    }
  `
}

export const DELETE_CASCADING_KPI_LIST = id => {
  return gql`
  mutation {
    delete_kpi_cascading_lists(where: {
      id: {
        _in: [${id}]
      }
    }) {
      affected_rows
    }
  }
  `
}
export const UPDATE_CASECADING_KPI = id => {
  return gql`
    mutation EditCascading($dataEdited: kpi_cascadings_set_input) {
      update_kpi_cascadings(
        where: {
          id: {
            _eq: ${id}
          }
        }
        _set: $dataEdited
      ) {
        returning {
          id
        }
      }
    }
  `
}

export const INSERT_CASCADING_ITEM = gql`
  mutation createCascadingItem($data: [kpi_cascading_lists_insert_input!]!) {
    insert_kpi_cascading_lists(objects: $data) {
      returning {
        id
      }
    }
  }
`
