import gql from "graphql-tag";

export const ADD_EMPLOYEE = gql`
  mutation(
    $name: String!
    $gender: String!
    $email: String!
    $country_code_phone: String!
    $phone_number: String!
    $password: String
    $religion: Int!
    $address: String
    $province: Int!
    $city: Int!
    $postal_code: String
    $regno: String!
    $job_profile: Int!
    $role: String
    $supervisor: String
    $joindate: String
    $startdate: String
    $enddate: String
    $legal: String
    $time: String
    $place: String
    $reference: String
    $replacement: Boolean
    $birthdate: String
    $attachment: JSON
    $reminder: Int
  ) {
    addPlacement(
      name: $name
      gender: $gender
      email: $email
      country_code_phone: $country_code_phone
      phone_number: $phone_number
      password: $password
      religion: $religion
      address: $address
      province: $province
      city: $city
      postal_code: $postal_code
      regno: $regno
      job_profile: $job_profile
      role: $role
      supervisor: $supervisor
      joindate: $joindate
      startdate: $startdate
      enddate: $enddate
      legal: $legal
      time: $time
      place: $place
      reference: $reference
      replacement: $replacement
      birthdate: $birthdate
      attachment: $attachment
      reminder: $reminder
    ) {
      id
    }
  }
`;