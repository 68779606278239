import {useMutation} from '@apollo/react-hooks'
import moment from 'moment'
import React from 'react'
import {CLOSE_BALOON_DIRECTORY} from '../../../../graphql/mutations'
import BaloonWLB from '../../../shared-components/BaloonWLB'

const STYLE_TEXT = {margin: 0, fontSize: 12}
const STYLE_ACTIONS = {
  textDecoration: 'underline',
  fontWeight: '600',
  fontSize: 12,
  cursor: 'pointer',
}
const getTextDownload = (
  {data, status},
  onRetry,
  handleCloseBaloonDownload
) => {
  const date = moment(data.date_added).format('MMMM DD, YYYY')
  switch (status) {
    case 'WAITING':
      return 'Generating data! We’ll send you an email when it’s ready.'
    case 'COMPLETED':
      return (
        <div style={{color: '#4caf50'}}>
          <p style={STYLE_TEXT}>{data.filter.selections}</p>{' '}
          <span style={STYLE_TEXT}>
            {`Generate data is Complete! (Last generate ${date})`}
          </span>{' '}
          <span
            onClick={() => window.open(data && data && data.data_url, '_blank')}
            style={STYLE_ACTIONS}
          >
            Download now
          </span>
        </div>
      )
    case 'FAILED':
      return (
        <div style={{color: '#ef4d5e'}}>
          <p style={STYLE_TEXT}>{data.filter.selections}</p>{' '}
          <span style={STYLE_TEXT}>{`Generate data has failed!`}</span>{' '}
          <span
            onClick={() => {
              handleCloseBaloonDownload(data.id)
              onRetry(data.filter.selections)
            }}
            style={STYLE_ACTIONS}
          >
            Retry
          </span>
        </div>
      )
    default:
      return '-'
  }
}

export default function BaloonDownloadEmployee({
  dataBaloonDonloadDirectory,
  onRetry,
  // errorDownloadData,
}) {
  const [closeBaloon, {loading}] = useMutation(CLOSE_BALOON_DIRECTORY)

  const handleCloseBaloonDownload = (id) => {
    closeBaloon({
      variables: {
        id: id,
      },
    })
  }
  return (
    <>
      {dataBaloonDonloadDirectory &&
        dataBaloonDonloadDirectory.company_export_data_logs.map((res, key) => {
          return (
            <BaloonWLB
              key={key}
              variant={res.status}
              text={getTextDownload(
                {
                  data: res,
                  status: res.status,
                },
                onRetry,
                handleCloseBaloonDownload
              )}
              loading={loading}
            />
          )
        })}
    </>
  )
}
