import gql from 'graphql-tag'

export const UPDATE_LOCATION_RESTRICTION = gql`
  mutation UpdateLocationRestriction(
    $id: Int
    $name: String
    $location_radius: Int
    $location: jsonb
    $location_info: jsonb
  ) {
    update_time_attendance_location(
      _set: {
        name: $name
        location: $location
        location_radius: $location_radius
        location_info: $location_info
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
