import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import {Button, Divider, Popover} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import {
  ExpansionPanelStyle,
  ExpansionPanelSummaryStyle,
  ExpansionPanelDetailStyle,
  ItemMenu,
  ItemClause,
  // ClauseItemWrapper,
  // CaluseItemHead,
  // CaluseItemBody,
} from '../SettingStyles'
import {TextBlue} from '../../../component/modalStyles'
import {AppliesToChild, AppliesToContainer} from '../../../../../GlobalStyles'
import DetailDataSelectPopUp from '../../../../shared-components/popup/DetailDataSelectPopUp'

import {formatCurrency} from '../../currencyExchanges'

export default function ListPolicy({data, setOpen}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'more-setting-component' : undefined
  const [openDetail, setOpenDetail] = useState(false)

  const handleNewPos = () => {
    setOpenDetail(!openDetail)
  }

  const destinations = data.business_trip_policy_destinations.map(
    row => row.global_destination?.name
  )

  const assignee = data.company_job_profile_r_business_trips
    .filter(row => row.company_job_profile)
    .map(row => row.company_job_profile.title)

  const isPolicyUsed = data?.agg_pwbt?.aggregate.count_requests > 0

  return (
    <>
      <ExpansionPanelStyle>
        <ExpansionPanelSummaryStyle>
          <span>{data?.name}</span>
          <MoreHorizIcon
            aria-describedby={id}
            onClick={e => {
              e.stopPropagation()
              setAnchorEl(e.currentTarget)
            }}
          />
        </ExpansionPanelSummaryStyle>
        <ExpansionPanelDetailStyle>
          <ItemClause>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <span style={{padding: '20px'}}>{'Policy Details'}</span>
              <Divider></Divider>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px 20px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                  }}
                >
                  <div style={{width: '200px'}}>{'Policy Name'}</div>
                  <div>{data?.name}</div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                  }}
                >
                  <div style={{width: '200px'}}>{'Destination'}</div>
                  <div>
                    {destinations.length > 0 ? destinations.join(', ') : '-'}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                  }}
                >
                  <div style={{width: '200px'}}>{'Description'}</div>
                  <div>{data?.description}</div>
                </div>
              </div>

              <span style={{padding: '20px'}}>{'Budget Component'}</span>
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignContent: 'flex-start',
                }}
              >
                {data.v_business_trip_budget_totals.length > 0 &&
                  data.v_business_trip_budget_totals.map((row, key) => {
                    return (
                      <li
                        key={key}
                        style={{
                          paddingRight: '45px',
                          color: '#039be5',
                        }}
                      >
                        <p style={{color: '#055469'}}>{row.name}</p>
                        <p style={{color: 'black'}}>
                          {row.nominal === null
                            ? 'Flexibel'
                            : formatCurrency(data.currency_to, row.nominal)}
                        </p>
                      </li>
                    )
                  })}
              </ul>

              <span style={{padding: 20}}>Assignee</span>
              <AppliesToContainer style={{marginLeft: '0'}}>
                {assignee.length > 0 &&
                  assignee.slice(0, 20).map((applied, index) => {
                    return (
                      <AppliesToChild key={index}>{applied}</AppliesToChild>
                    )
                  })}
              </AppliesToContainer>

              {assignee.length > 20 && (
                <TextBlue
                  style={{
                    cursor: 'pointer',
                    display: 'block',
                    padding: 20,
                    paddingTop: 0,
                  }}
                  onClick={() => setOpenDetail(true)}
                >
                  Show More
                </TextBlue>
              )}
            </div>
          </ItemClause>
        </ExpansionPanelDetailStyle>
      </ExpansionPanelStyle>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ItemMenu>
          <Button
            component={Link}
            to={{
              pathname: `/business/trip/settings/edit-policy/${data.id}`,
              state: {
                active: 'edit-policy',
                type: 'Edit',
                dataEdit: data,
              },
            }}
            className="item"
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setOpen(e => ({
                ...e,
                [isPolicyUsed ? 'confirm' : 'delete']: true,
                deleteName: data && data.name,
                deleteId: data && data.id,
                model: 'Policy',
              }))
              setAnchorEl(null)
            }}
            className="item"
          >
            Delete
          </Button>
        </ItemMenu>
      </Popover>

      <DetailDataSelectPopUp
        open={openDetail}
        onClose={handleNewPos}
        title="Domestic Trip"
        appliesTo="Position"
        data={assignee}
        rowsPerPageOptions={[20, 50, 100]}
      />
    </>
  )
}
