import {
  Button,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {
  FormTitleContainer,
  FormToolbar,
  GreyTypography,
  PaperContainer,
} from '../../../../../GlobalStyles'
import helperStyle from '../../../OrganizationPageStyles'

function DetailArchive(props) {
  const classes = helperStyle()
  const [tabsValue, setTabsValue] = useState(0)

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const tabTitle = [
    'Org. Level',
    'Org. Unit',
    'Job Level',
    'Job Grade',
    'Job Family',
    'Job Title',
    'Position',
  ]

  const tabsSwitch = () => {
    switch (tabsValue) {
      case 0:
        return ''
      case 1:
        return ''
      case 2:
        return ''
      case 3:
        return ''
      case 4:
        return ''
      case 5:
        return ''
      case 6:
        return ''
      default:
        return ''
    }
  }
  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={props.history.goBack}
          >
            <ArrowBack />
          </IconButton>

          <div style={{marginLeft: 16}}>
            <Typography variant="h6">Archive March 2021</Typography>
            <GreyTypography variant="body2">Generated On ...</GreyTypography>
          </div>
        </FormTitleContainer>
        <Button variant="contained" color="primary" size="large">
          View Org. Structure
        </Button>
      </FormToolbar>
      <Divider></Divider>
      <Tabs
        value={tabsValue}
        onChange={handleChangeTabs}
        indicatorColor="secondary"
        variant="scrollable"
        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
      >
        {tabTitle.map((t, i) => (
          <Tab label={t} key={i} />
        ))}
      </Tabs>
      {tabsSwitch()}
    </PaperContainer>
  )
}

export default withRouter(DetailArchive)
