import gql from 'graphql-tag'

export const GET_FILTER_POSITION = gql`
  query(
    $companyId: uuid!
    $search: String!
    $filterJobFamily: Int_comparison_exp
    $filterJobFunction: Int_comparison_exp
    $filterJobTitle: Int_comparison_exp
    $filterOrgUnit: Int_comparison_exp
    $filterOrgLevel: Int_comparison_exp
    $filterJobLevel: Int_comparison_exp
    $filterJobGrade: Int_comparison_exp
    $filterOffice: Int_comparison_exp
    $filterPosition: Int_comparison_exp
    $limit: Int
    $offset: Int
    $policy: Int
    $isFilterPolicy: Boolean
  ) {
    total: company_job_profiles_aggregate(
      where: {
        id: $filterPosition
        company: {_eq: $companyId}
        _or: [
          {
            company_job_profile_r_attendances: {
              _not: {id: {_is_null: $isFilterPolicy}}
              policy: {_eq: $policy}
              company_job_profile: {
                _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
              }
            }
          }
          {
            _or: [
              {
                id: {_is_null: $isFilterPolicy}
                _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
              }
            ]
          }
        ]
        company_employee_position: {
          job_mapping_id: $filterJobFunction
          family_id: $filterJobFamily
          id: $filterJobTitle
        }
        company_organization: {
          id: $filterOrgUnit
          organization_level_id: $filterOrgLevel
        }
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        office_address: $filterOffice
      }
    ) {
      aggregate {
        count
      }
    }

    totalAll: company_job_profiles_aggregate(
      where: {
        id: $filterPosition
        company: {_eq: $companyId}
        _or: [
          {
            company_job_profile_r_attendances: {
              _not: {id: {_is_null: $isFilterPolicy}}
              policy: {_eq: $policy}
              company_job_profile: {}
            }
          }
          {_or: [{id: {_is_null: $isFilterPolicy}}]}
        ]
        company_employee_position: {
          job_mapping_id: $filterJobFunction
          family_id: $filterJobFamily
          id: $filterJobTitle
        }
        company_organization: {
          id: $filterOrgUnit
          organization_level_id: $filterOrgLevel
        }
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        office_address: $filterOffice
      }
    ) {
      aggregate {
        count
      }
    }

    totalPosition: company_job_profiles_aggregate(
      where: {
        id: $filterPosition
        company: {_eq: $companyId}
        company_employee_position: {
          job_mapping_id: $filterJobFunction
          family_id: $filterJobFamily
          id: $filterJobTitle
        }
        company_organization: {
          id: $filterOrgUnit
          organization_level_id: $filterOrgLevel
        }
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        office_address: $filterOffice
      }
    ) {
      aggregate {
        count
      }
    }

    prev_filter_policy: time_attendance_policies(where: {id: {_eq: $policy}}) {
      last_modified
      filter_position
    }

    company_job_profiles(
      where: {
        id: $filterPosition
        company: {_eq: $companyId}
        _or: [
          {
            company_job_profile_r_attendances: {
              _not: {id: {_is_null: $isFilterPolicy}}
              policy: {_eq: $policy}
              company_job_profile: {
                _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
              }
            }
          }
          {
            _or: [
              {
                id: {_is_null: $isFilterPolicy}
                _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
              }
            ]
          }
        ]
        company_employee_position: {
          job_mapping_id: $filterJobFunction
          family_id: $filterJobFamily
          id: $filterJobTitle
        }
        company_organization: {
          id: $filterOrgUnit
          organization_level_id: $filterOrgLevel
        }
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        office_address: $filterOffice
      }
      order_by: [{code: asc}]
      limit: $limit
      offset: $offset
    ) {
      id
      code
      title
    }
  }
`

export const GET_NEW_POSITION_ATTENDANCE = gql`
  query(
    $company: uuid
    $policy: Int
    $search: String
    $limit: Int
    $offset: Int
  ) {
    count: company_job_profiles_aggregate(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
        _not: {
          company_job_profile_r_attendances: {
            id: {_is_null: false}
            policy: {_eq: $policy}
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    company_job_profiles(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        _not: {
          company_job_profile_r_attendances: {
            id: {_is_null: false}
            policy: {_eq: $policy}
          }
        }
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
      }
      order_by: {date_added: asc}
      limit: $limit
      offset: $offset
    ) {
      title
      date_added
    }
  }
`
