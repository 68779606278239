import styled from 'styled-components'
import {Avatar, Typography} from '@material-ui/core'

export const RootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 36px 40px;
`

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${props => (props.ml ? '10px' : null)};
`

export const RowContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${props => (props.mb ? '20px' : null)};
`

export const AvatarStyled = styled(Avatar)`
  display: flex;
  flex-direction: row;
  width: 10vw !important;
  height: 10vw !important;
  margin-left: 1vw;
`

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 24vw;
  padding: 0px 0px 0px 4vw;
  border-right: ${props =>
    props.borderRight ? '1px solid rgba(0, 0, 0, 0.16)' : 'none'};
`

export const NameDetail = styled(Typography)`
  font-size: 18px;
  margin-bottom: 6px;
  font-weight: 600;
  display: flex;
  align-items: center;
`

export const TypographyStyled = styled(Typography)`
  font-size: ${props => (props.medium ? '16px' : '14px')};
  line-height: 22px;
  color: ${props => (props.grey ? '#a9a8a8' : '#111')};
  font-weight: ${props => (props.bold ? '600' : '500')};
  margin-right: ${props => (props.mr ? '10px' : null)};
  margin-bottom: ${props => (props.mb ? '20px' : null)};
  width: ${props => (props.max ? '100px' : 'auto')};
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  margin-top: 10px;
`
export const RowFollower = styled.div`
  display: flex;
  flex-direction: row;
  width: 43%;
`

export const WrapperRight = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
`

export const WrapperMTAuto = styled.div`
  margin-top: auto;
`

export const WrapperSocmed = styled.div`
  width: 140px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

export const HeaderBoxSection = styled.div`
  padding: ${props => (props.isNoBorder ? '26px 26px 12px' : '26px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const TitleSection = styled(Typography)`
  font-size: 16px;
  color: #000;
  font-weight: 600;
`

export const BodyBoxSection = styled.div`
  padding: 26px;
  display: flex;
  flex-direction: column;
`

export const WorkingContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${props => (props.utils ? '23px' : '36px')};
`

export const HeaderSharedSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const TyphoSection = styled(Typography)`
  font-size: ${props => (props.title ? '14px' : '12px')};
  color: ${props => (props.blue ? '#039be5' : props.grey ? '#a9a8a8' : '#000')};
  font-weight: ${props => (props.bold ? '600' : '500')};
  font-style: ${props => (props.italic ? 'italic' : null)};
  margin-bottom: ${props => (props.mb ? '10px' : '8px')};
  margin-top: ${props => (props.mt ? '10px' : null)};
  white-space: pre-line;
`

export const KeyRight = styled(Typography)`
  min-width: 14vw;
  font-size: 14px;
  margin-bottom: 2vh;
`
export const ValueRight = styled.div`
  max-width: 18vw;
  font-size: 14px;
  color: #a9a8a8;
  margin-bottom: 2vh;
`

export const ContentSharedSection = styled.div`
  margin-left: 137px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`

export const ImgStyled = styled(Avatar)`
  width: 40px;
  height: 40px;
  border-radius: 0px !important;
`

export const WrapperStatus = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10vw;
`

export const WrapperStatusLeft = styled.div`
  display: flex;
  flex-direction: row;
`

export const Style = {
  wrapper: {
    position: 'relative',
    border: 'solid 1px #e5e5e5',
    height: 43,
    width: '180px',
    borderRadius: '41px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 55,
    boxSizing: 'border-box',
    fontSize: 12,
  },
  wrapperSkills: {
    position: 'relative',
    border: 'solid 1px #e5e5e5',
    height: 43,
    width: '160px',
    borderRadius: '41px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 55,
    boxSizing: 'border-box',
    fontSize: 12,
  },
  score: {
    borderRadius: '50%',
    position: 'absolute',
    left: '0',
    top: '-7px',
    backgroundColor: '#014a62',
    height: '55px',
    width: '54px',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
  },
  avatarGroup: {
    display: 'flex',
    width: '200px',
    margin: '26px 0px 12px 0px',
  },
}

export const AvatarRecomendation = styled(Avatar)`
  width: 64px !important;
  height: 64px !important;
  margin-right: 35px;
`

export const WrapModalTemates = styled.div`
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: row;
    background-color: #014a62;
    padding: 12px 30px;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #fff;
      font-size: 20px;
      font-weight: normal;
    }
    .wrap-close {
      .icon-close {
        color: #fff;
      }
    }
  }
`

export const GridListing = styled.div`
  --grid-items: 2;
  display: grid;
  grid-template-columns: repeat(var(--grid-items), minmax(0, 1fr));
  grid-gap: 30px 32px;
  padding: 30px 32px;
  position: relative;

  @media (max-width: 1080px) {
    --grid-items: 1;
  }
  @media (min-width: 1600px) {
    --grid-items: 3;
  }
`

export const ItemWrapper = styled.div`
  position: relative;

  .header,
  .title,
  .subtitle,
  .text,
  .main {
    font-size: 14px;
  }

  .title,
  .main,
  .text.primary {
    color: #014a62;
  }

  .title {
    margin: 0 0 20px;
  }
  .subtitle {
    color: #817e7e;
    font-weight: 400;
    margin: 0 0 8px;
  }
  .title + .subtitle {
    margin-top: -15px;
  }

  .content {
    --header-width: 25%;
    --row-gap: 8px;
    --column-gap: 8px;
    font-size: 14px;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: var(--column-gap) var(--row-gap);
    margin: calc(var(--row-gap) * -1) calc(var(--column-gap) * -1);

    td.highlight-text {
      color: #039be5;
    }

    th {
      font-weight: 400;
      text-align: left;
      color: #817e7e;
      width: var(--header-width);
      vertical-align: top;
    }

    .MuiSvgIcon-root {
      font-size: 1.25rem;
      vertical-align: middle;
    }

    &.freeflow {
      --header-width: unset;
    }
  }

  .paragraph {
    margin: 0 0 8px;

    &:empty {
      display: none;
    }
  }

  .list {
    margin: 0 0 8px;
    padding: 0 0 0 1.3125rem;

    &:empty {
      display: none;
    }
  }

  .attachments {
    margin-top: 16px;

    &:empty {
      display: none;
    }
  }

  .attachments.row {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 8px;
  }

  .edit {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
    margin: -4px;
  }

  .MuiLink-button {
    font-family: inherit;
  }
`

export const WrapperSection = styled.div`
  display: flex;
  flex-direction: column;
  .show-more {
    font-size: 16px;
    font-weight: 600;
    color: #039be5;
    margin: 0px;
    cursor: pointer;
    padding: 0px 30px 26px;
  }
`
export const WrapperItemSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 30px;
  .divider {
    padding-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    .content {
      display: flex;
      flex-direction: column;
    }
  }
  .icon-edit {
    font-size: 18px;
    cursor: pointer;
  }
`

export const LogoImg = styled(Avatar)`
  width: 100px;
  height: 100px;
  border-radius: 0px;
  margin-right: 50px;
  .MuiAvatar-fallback {
    display: none;
  }
`
export const TerminationStyle = styled.div`
  width: auto;
  padding: 20px 22px;
  display: flex;
  flex-direction: row;
  background-color: #c5f1ff;
  border-radius: 5px;
  border: solid 1px #014a62;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  align-items: center;
  margin: 0 0 30px 8px;
  .icon {
    margin-right: 22px;
  }
  .content {
    color: #014a62;
    .title {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 4px;
    }
    .desc {
      font-size: 12px;
      font-weight: normal;
      margin: 0;
    }
  }
`

export const WrapChipsSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
  .chips-wrap {
    min-width: 263px;
    .chips {
      max-width: 232px;
      box-sizing: border-box;
      padding: 16.5px 27px;
      border-radius: 41px;
      border: solid 1px #e5e5e5;
      background-color: #ffffff;
      width: max-content;
      .chips-name {
        font-size: 14px;
        font-weight: normal;
        color: #050505;
        margin: 0px;
      }
    }
  }
  .content {
    width: 100%;
    .divider {
      padding-bottom: 30px;
      border-bottom: 1px solid #e5e5e5;
      width: 100%;
    }
  }
  .icon-edit {
    cursor: pointer;
  }
`
export const WrapIconItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 30px;
  font-size: 14px;
  border-bottom: solid 1.5px #eff2f4;
  .title {
    font-size: 16px;
  }
  .black {
    color: #000;
  }
  .grey {
    color: #a9a8a8;
  }
  .red {
    color: rgb(239, 77, 94);
  }
  p {
    margin: 0px;
    margin-bottom: 5px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .text-12 {
    font-size: 12px;
  }
  .verified {
    margin-left: 20px;
  }
  .is-main {
    padding: 12px 20px;
    background-color: #014a62;
    border-radius: 5px;
    width: max-content;
    margin-top: 10px;
    p {
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      margin: 0px;
    }
  }
  .socials-item {
    .title {
      margin-bottom: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        margin-right: 5px;
      }
      .title-name {
        font-size: 16px;
        margin: 0px;
        color: #014a62;
      }
    }
    .content {
      .account {
        margin-bottom: 14px;
        margin-left: 31px;
        .mt-15 {
          margin-top: 15px;
        }
      }
    }
  }
`

export const ModalAddEditWraper = styled.div`
  padding: 26px 32px;
`
