import React, {useState} from 'react'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {WrapperSection} from '../../DetailEmployeeStyles'
import {ServiceAgreementItem} from './ServiceAgreementItem'
import {dateFormat} from '../../../../../../utils/helpers'
import AddEdit from './AddEdit'
import {ButtonAdd} from '../../SharedDetailEmployee'

export default function ServiceAgreement({
  people_profile_service_agreements,
  user_name,
  refetch,
  setAction,
  enqueueSnackbar,
  userIdDetail,
  action,
  INITIAL_ACTION_STATE,
}) {
  const [open, setOpen] = useState(false)

  const handlePreview = link => window.open(link)

  const _renderData = (data, limit = null) => {
    return data.slice(0, limit || data.length).map(res => {
      return (
        <ServiceAgreementItem
          key={`${res.id}-itm`}
          onPreview={handlePreview}
          code={res.code || '-'}
          date={
            dateFormat(res.start_date, 'll') +
            ' - ' +
            dateFormat(res.end_date, 'll')
          }
          description={res.description || '-'}
          attachments={res.attachments || []}
          onEdit={() =>
            handleOpenForm({
              actionType: 'edit',
              data: res,
              isFromModal: action.isFromModal,
            })
          }
        />
      )
    })
  }

  const handleCloseAction = () => {
    if (action.isFromModal) {
      setOpen(true)
    }
    setAction(INITIAL_ACTION_STATE)
  }
  const handleOpenForm = ({actionType, data = null, isFromModal}) => {
    if (isFromModal) {
      setOpen(false)
    }
    setAction(e => ({
      ...e,
      open: true,
      actionType,
      data,
      isFromModal,
    }))
  }

  return (
    <>
      <WrapperSection>
        {_renderData(people_profile_service_agreements, 2)}
        {people_profile_service_agreements[0] && (
          <h3 onClick={() => setOpen(true)} className="show-more">
            Tampilkan Lebih Banyak Perjanjian Layanan...
          </h3>
        )}
      </WrapperSection>

      <ModalDetail
        title={`Perjanjian Layanan ${user_name}`}
        onClose={() => setOpen(false)}
        open={open}
      >
        {_renderData(people_profile_service_agreements)}
        <ButtonAdd
          label="Perjanjian Layanan"
          onClick={() =>
            handleOpenForm({
              actionType: 'add',
              isFromModal: true,
            })
          }
        />
      </ModalDetail>

      <AddEdit
        setAction={setAction}
        refetch={refetch}
        enqueueSnackbar={enqueueSnackbar}
        userIdDetail={userIdDetail}
        open={action.open}
        data={action.data}
        onClose={handleCloseAction}
        actionType={action.actionType}
      />
    </>
  )
}
