import {format, formatDistance} from 'date-fns'

export const dateSplitter = date => {
  if (date) {
    const arrDate = date.split('-')
    const newDate = []
    arrDate.map(res => {
      newDate.push(parseInt(res))
    })
    return newDate
  } else {
    return ['', '', '']
  }
}

export const generateRangedDate = (is_present, from, to) => {
  let result = ''
  const issued = dateSplitter(from)
  if (!is_present) {
    const expired = dateSplitter(to)
    result = formatDistance(
      new Date(issued[0], issued[1] - 1, issued[2]),
      new Date(expired[0], expired[1] - 1, expired[2])
    )
  } else {
    result = formatDistance(
      new Date(issued[0], issued[1] - 1, issued[2]),
      new Date()
    )
  }

  const _from = format(new Date(from), 'MMM yyyy')
  const _to = is_present ? 'present' : format(new Date(to), 'MMM yyyy')
  const _long = result !== '' ? `(${result})` : null
  return `${_from} - ${_to} ${_long}`
}

export const generateDobleData = (first, second, bridge = ', ') => {
  if (!first && !second) return '-'
  else if (first && second) return first + bridge + second
  else if (first && !second) return first
  else if (!first && second) return second
}
