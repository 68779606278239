import React from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery, useApolloClient} from '@apollo/react-hooks'

import {
  Box,
  Typography,
  Button,
  IconButton,
  Toolbar,
  Divider,
  TextField,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  withStyles,
  StepConnector,
  Step,
  Stepper,
  StepLabel,
  makeStyles,
  Tab,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import MaterialTable from 'material-table'

import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import {useSnackbar} from 'notistack'
import {AssignFilter} from '@worklifebeyond/wlb-utils-components'

import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  PaperContainer,
  TextHelper,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import {ExtendedTabs, TableContainer, TableOptions} from '../../ClaimPageStyles'
import FieldInformationTooltip from '../../../../shared-components/tooltip/FieldInformationTooltip'
import NoDataListFree from '../../../../shared-components/NoDataListFreeComponent'
import AlertPopup from '../../../../shared-components/popup/ResultPopup'

import {COMPANY_ID} from '../../../../../utils/globals'
import assignFiltersOld from '../../../../../utils/assignFiltersOld'
import {
  CHECK_CLAIM_POLICY_DUPLICATE,
  GET_CLAIM_POLICY_DETAILS,
  GET_CLAIM_POSITION_COUNT,
  GET_CLAIM_POSITION_DROPDOWN,
  GET_CLAIM_POSITION_LIST,
} from '../../../../../graphql/queries/claim/getClaimSettings.query'
import {
  ADD_CLAIM_POLICY,
  EDIT_CLAIM_POLICY,
} from '../../../../../graphql/mutations/claim/claimPolicies.mutation'

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const useStyles = makeStyles({
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  labelActive: {
    color: '#014a62 !important',
  },
})

const INITIAL_FORM_DATA = {
  id: undefined,

  name: '',
  description: '',

  approval_type: null,

  requires_second_approval: false,
  first_approval_by: null,
  second_approval_by: null,

  apply_type: 'custom',
  apply_filters: null,

  alert_open: false,
  alert_type: null,
}

const SELECT_APPROVAL_TYPES = [
  {
    value: 'free',
    label: 'No Approval',
  },
  {
    value: 'supervisor',
    label: 'Direct Supervisor',
  },
  {
    value: 'position',
    label: 'Position',
  },
]

const LABEL_REQUIRED = 'This field is required'
const LABEL_NAME_OVER = 'The maximum amount of characters is 50 characters'
const LABEL_NAME_DUPE = 'The name already exists'

const STATE_AVAILABLE = 0
const STATE_ERROR = 1
const STATE_FETCH = 2
const STATE_DISPATCH = 3

const steps = ['Detail Policy', 'Assign Position']

const buttonSubmit = {
  0: 'Next',
  1: 'Save',
}

function getWhereClaimPosition(filter, customValue) {
  let dataFilter = filter
  if (customValue) {
    dataFilter = Object.assign(dataFilter || {}, customValue)
  }

  const where = {
    company: {_eq: COMPANY_ID},
    id: dataFilter?.filterPosition,
    office_address: dataFilter?.filterOffice,
    rank: dataFilter?.filterJobLevel,
    job_grade_id: dataFilter?.filterJobGrade,
    company_employee_position: {
      id: dataFilter?.filterJobTitle,
      family_id: dataFilter?.filterJobFamily,
      job_mapping_id: dataFilter?.filterJobFunction,
    },
    company_organization: {
      id: dataFilter?.filterOrgUnit,
      organization_level_id: dataFilter?.filterOrgLevel,
    },
    _or: [
      {title: {_ilike: dataFilter?.search}},
      {code: {_ilike: dataFilter?.search}},
    ],
  }
  return where
}

function ClaimPolicyFormPage(props) {
  const policyId = props.match.params.id
  const classes = useStyles()

  // eslint-disable-next-line no-unused-vars
  const [data, setData] = React.useState(INITIAL_FORM_DATA)
  const [selections, setSelections] = React.useState({})
  const [state, setState] = React.useState(
    policyId ? STATE_FETCH : STATE_AVAILABLE
  )

  const [tab, setTab] = React.useState(0)

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const isError = state === STATE_ERROR
  const isNameEmpty = !data.name
  const isNameOver = data.name.length > 50
  const isApprovalTypeEmpty = !data.approval_type
  const isFirstApprovalEmpty =
    !isApprovalTypeEmpty &&
    data.approval_type?.value === 'position' &&
    !data.first_approval_by
  const isSecondApprovalEmpty =
    data.requires_second_approval && !data.second_approval_by
  const isFilterEmpty =
    data.apply_type === 'custom' && data.apply_filters === null

  const isAlertSubmit = data.alert_type === 'submit'

  const {data: dataProfileCount, loading: loadingProfileCount} = useQuery(
    GET_CLAIM_POSITION_COUNT,
    {
      skip: state === STATE_FETCH || isFilterEmpty,
      variables: {
        companyId: COMPANY_ID,
        ...data.apply_filters,
      },
    }
  )

  const {data: checkData, loading: loadingCheck} = useQuery(
    CHECK_CLAIM_POLICY_DUPLICATE,
    {
      wlb_skipPatch: true,
      fetchPolicy: 'cache-and-network',
      skip: isNameEmpty || isNameOver,
      variables: {
        where: {
          id: {_nin: data.id ? [data.id] : undefined},
          company: {_eq: COMPANY_ID},
          confirm_type: {_in: ['supervisor', 'position', 'free']},
          deletedAt: {_is_null: true},
          name: {_eq: data.name},
        },
      },
    }
  )

  const isNameDupe =
    !loadingCheck && checkData && checkData.total.aggregate.count > 0

  React.useEffect(() => {
    if (policyId && policyId !== data.id) {
      setState(STATE_FETCH)

      let active = true

      const promise = client.query({
        query: GET_CLAIM_POLICY_DETAILS,
        variables: {
          policyId,
          companyId: COMPANY_ID,
        },
        fetchPolicy: 'no-cache',
      })

      promise.then(result => {
        if (!active) {
          return
        }

        const {data, errors} = result

        if (errors) {
          // todo: handle error
          return
        }

        const details = data.details

        const approver_1 = details.approver_1
        const approver_2 = details.approver_2

        setSelections(details.filter_position || {})
        setData({
          ...data,

          id: policyId,

          name: details.name,
          description: details.description,

          approval_type: SELECT_APPROVAL_TYPES.find(
            item => item.value === details.confirm_type
          ),

          requires_second_approval: !!approver_2,
          first_approval_by: approver_1
            ? {
                label: `${approver_1.code || ''} - ${approver_1.title}`,
                value: approver_1.id,
              }
            : null,
          second_approval_by: approver_2
            ? {
                label: `${approver_2.code || ''} - ${approver_2.title}`,
                value: approver_2.id,
              }
            : null,

          apply_type: 'custom',
          apply_filters: {
            filterPosition: {
              _in: details.applies_to.map(item => item.profile.id),
            },
          },
        })

        setState(STATE_AVAILABLE)
      })

      return () => {
        active = false
      }
    }
  }, [policyId])

  // eslint-disable-next-line no-unused-vars
  const handleTabChange = (event, next) => {
    setTab(next)
  }

  const handleSearchSelect = async searchText => {
    const {data} = await client.query({
      query: GET_CLAIM_POSITION_DROPDOWN,
      variables: {
        companyId: COMPANY_ID,
        search: `%${searchText}%`,
      },
    })

    const result = data?.positions.map(position => ({
      label: `${position.code || ''} - ${position.title}`,
      value: position.id,
    }))

    return result || []
  }

  const bindInputChange = name => event => {
    setData({...data, [name]: event.target.value})
  }

  const bindCheckedChange = name => event => {
    setData({...data, [name]: event.target.checked})
  }

  const bindValueChange = name => value => {
    setData({...data, [name]: value})
  }

  const handleFilterTypeChange = event => {
    const next = event.target.value
    const isFilterAll = next === 'all'

    setData({
      ...data,
      apply_type: next,
      apply_filters: isFilterAll ? null : data.apply_filters || {},
    })

    if (isFilterAll) {
      setSelections({})
    }
  }

  const handleFilterApply = result => {
    const {include, exclude} = result

    const next = {}

    for (const p in include) {
      if (include[p] && include[p].length > 0) {
        if (!next[p]) {
          next[p] = {}
        }

        next[p]._in = include[p]
      }
    }

    for (const p in exclude) {
      if (exclude[p] && exclude[p].length > 0) {
        if (!next[p]) {
          next[p] = {}
        }

        next[p]._nin = exclude[p]
      }
    }

    setData({...data, apply_type: 'custom', apply_filters: next})
    setSelections(result)
  }

  const handleSubmitClick = () => {
    if (
      isNameEmpty ||
      isNameOver ||
      isNameDupe ||
      isApprovalTypeEmpty ||
      isFirstApprovalEmpty ||
      isSecondApprovalEmpty
    ) {
      setState(STATE_ERROR)
      return
    }

    if (isFilterEmpty || (tab === 0 && !policyId)) {
      setTab(1)
      isFilterEmpty && tab === 1 && setState(STATE_ERROR)
      return
    }

    setData({...data, alert_open: true, alert_type: 'submit'})
  }

  const handleSubmitClose = () => {
    setData({...data, alert_open: false})
  }

  const handleSubmitConfirm = () => {
    setData({...data, alert_open: false})

    setState(STATE_DISPATCH)

    const approverPositionIDs = []

    if (data.first_approval_by) {
      approverPositionIDs.push(data.first_approval_by.value)
    }

    if (
      data.second_approval_by &&
      data.approval_type &&
      data.approval_type.value !== 'free' &&
      data.requires_second_approval
    ) {
      approverPositionIDs.push(data.second_approval_by.value)
    }

    const dataFilter = data.apply_filters
    const promise = client.query({
      query: GET_CLAIM_POSITION_LIST,
      variables: {
        where: getWhereClaimPosition(dataFilter),
      },
      fetchPolicy: 'network-only',
    })

    promise
      .then(result => {
        if (result.errors) {
          return Promise.reject(result.errors)
        }

        return client.mutate({
          mutation: policyId ? EDIT_CLAIM_POLICY : ADD_CLAIM_POLICY,
          variables: {
            policyId: policyId || undefined,
            name: data.name,
            description: data.description,
            approval: data.approval_type.value,
            approverPositionIDs,
            assigneePositionIDs: result.data.positions.map(item => item.id),
            filter: selections,
          },
        })
      })
      .then(result => {
        if (result.errors) {
          return Promise.reject(result.errors)
        }

        enqueueSnackbar('Claim policy successfully created', {
          variant: 'success',
        })

        props.history.goBack()
      })
      .catch(error => {
        setState(STATE_AVAILABLE)

        console.error(error)

        enqueueSnackbar(`Failed to ${policyId ? 'edit' : 'add'} claim policy`, {
          variant: 'error',
        })
      })
  }

  const handleCancelClose = () => {
    setData({...data, alert_open: !data.alert_open, alert_type: 'cancel'})
  }

  const isCancel = policyId || tab === 0
  const handlePreviousButton = () => {
    if (isCancel) {
      return handleCancelClose()
    }
    setTab(prev => prev - 1)
  }

  return (
    <PaperContainer>
      <Toolbar>
        <IconButton
          disabled={state >= STATE_DISPATCH}
          onClick={handleCancelClose}
          aria-label="Go back to previous page"
          edge="start"
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography
          variant="subtitle1"
          style={{fontWeight: 600, flexGrow: 1, marginLeft: 8}}
        >
          {policyId ? 'Edit' : 'Add'} Claim Policy
        </Typography>

        <Button
          disabled={state >= STATE_DISPATCH}
          onClick={handlePreviousButton}
          variant="outlined"
          color="primary"
          size="large"
          style={{marginLeft: 16}}
        >
          {isCancel ? 'Cancel' : 'Previous'}
        </Button>

        <Button
          disabled={state >= STATE_FETCH || loadingProfileCount}
          onClick={handleSubmitClick}
          variant="contained"
          color="primary"
          size="large"
          style={{marginLeft: 16}}
        >
          {policyId ? 'Save' : buttonSubmit[tab]}
        </Button>
      </Toolbar>

      <Divider />

      {policyId && (
        <ExtendedTabs value={tab} onChange={handleTabChange}>
          <Tab label="Detail Policy" />
          <Tab label="Assign Position" />
        </ExtendedTabs>
      )}

      {!policyId && (
        <Stepper
          activeStep={tab}
          alternativeLabel
          connector={<CustomConnector />}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                classes={{
                  active: classes.labelActive,
                  completed: classes.labelActive,
                }}
                StepIconProps={{
                  classes: {
                    root: classes.iconRoot,
                    text: classes.iconText,
                    active: classes.iconActive,
                    completed: classes.iconActive,
                  },
                }}
              >
                {step}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {state === STATE_FETCH ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : tab === 0 ? (
        <Box p={3}>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>1.</TitleNumber>
              <TitleName>Claim Name*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                disabled={state >= STATE_FETCH}
                value={data.name}
                onChange={bindInputChange('name')}
                error={(isError && isNameEmpty) || isNameOver || isNameDupe}
                helperText={
                  (isError && isNameEmpty && LABEL_REQUIRED) ||
                  (isNameOver && LABEL_NAME_OVER) ||
                  (isNameDupe && LABEL_NAME_DUPE)
                }
                placeholder="Add claim name here"
                size="small"
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>2.</TitleNumber>
              <TitleName>Description</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                disabled={state >= STATE_FETCH}
                value={data.description}
                onChange={bindInputChange('description')}
                multiline
                minRows={4}
                placeholder="Add claim description here"
                size="small"
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer
            style={{width: '96.9156%', paddingBottom: '1.2rem'}}
          >
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Claim Approval*</TitleName>
              <FieldInformationTooltip title="Approval will be applied to claim approval" />
            </FormChildTitle>
            <FormChildInput style={{display: 'flex', alignItems: 'center'}}>
              <Select
                disabled={state >= STATE_FETCH}
                value={data.approval_type}
                onChange={bindValueChange('approval_type')}
                options={SELECT_APPROVAL_TYPES}
                placeholder="Choose Approval"
                menuPosition="fixed"
                styles={{
                  container: provided => ({
                    ...provided,
                    width: '50%',
                    marginTop: 10,
                    marginRight: 24,
                  }),
                }}
              />

              {data.approval_type && data.approval_type.value !== 'free' && (
                <FormControlLabel
                  disabled={state >= STATE_FETCH}
                  control={<Switch color="primary" />}
                  checked={data.requires_second_approval}
                  onChange={bindCheckedChange('requires_second_approval')}
                  label="Needs Second Approval"
                  style={{marginTop: 10}}
                />
              )}
            </FormChildInput>
            {isError && isApprovalTypeEmpty && (
              <FormChildInput>
                <TextHelper style={{marginTop: 6, marginLeft: 14}}>
                  {LABEL_REQUIRED}
                </TextHelper>
              </FormChildInput>
            )}
          </FormChildContainer>

          {data.approval_type?.value === 'position' && (
            <FormChildContainer>
              <FormChildInput>
                <TitleName>Position</TitleName>
              </FormChildInput>
              <FormChildInput>
                <AsyncSelect
                  isDisabled={state >= STATE_FETCH}
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSearchSelect}
                  value={data.first_approval_by}
                  onChange={bindValueChange('first_approval_by')}
                  placeholder="Choose Position"
                  menuPosition="fixed"
                  styles={{
                    container: provided => ({
                      ...provided,
                      marginTop: 10,
                    }),
                  }}
                />
                {isError && isFirstApprovalEmpty && (
                  <TextHelper style={{marginTop: 6, marginLeft: 14}}>
                    {LABEL_REQUIRED}
                  </TextHelper>
                )}
              </FormChildInput>
            </FormChildContainer>
          )}

          {data.requires_second_approval &&
            data.approval_type &&
            data.approval_type.value !== 'free' && (
              <FormChildContainer>
                <FormChildInput>
                  <TitleName>Second Approval</TitleName>
                </FormChildInput>
                <FormChildInput>
                  <AsyncSelect
                    isDisabled={state >= STATE_FETCH}
                    cacheOptions
                    defaultOptions
                    loadOptions={handleSearchSelect}
                    value={data.second_approval_by}
                    onChange={bindValueChange('second_approval_by')}
                    placeholder="Choose Position for Second Approval"
                    menuPosition="fixed"
                    styles={{
                      container: provided => ({
                        ...provided,
                        marginTop: 10,
                      }),
                    }}
                  />
                  {isError && isSecondApprovalEmpty && (
                    <TextHelper style={{marginTop: 6, marginLeft: 14}}>
                      {LABEL_REQUIRED}
                    </TextHelper>
                  )}
                </FormChildInput>
              </FormChildContainer>
            )}
        </Box>
      ) : tab === 1 ? (
        <Box p={3}>
          <div>
            <TitleName>Method of adding positions</TitleName>

            <RadioGroup
              value={data.apply_type}
              onChange={handleFilterTypeChange}
              style={{flexDirection: 'row', alignItems: 'center'}}
            >
              <FormControlLabel
                disabled={state >= STATE_FETCH}
                control={<Radio />}
                value="custom"
                label="Custom Select"
              />
              <FormControlLabel
                disabled={state >= STATE_FETCH}
                control={<Radio />}
                value="all"
                label="Select All (Allow to Edit)"
              />

              {isFilterEmpty && (
                <>
                  <div style={{flexGrow: 1}} />
                  <AssignFilter
                    filters={assignFiltersOld}
                    limit={20}
                    initialSelections={selections}
                    onApply={handleFilterApply}
                  >
                    <Button
                      disabled={state >= STATE_FETCH}
                      variant="contained"
                      color="primary"
                    >
                      Add Positions
                    </Button>
                  </AssignFilter>
                </>
              )}
            </RadioGroup>

            {isError && isFilterEmpty && (
              <TextHelper style={{marginTop: 6, marginLeft: 0}}>
                {LABEL_REQUIRED}
              </TextHelper>
            )}
          </div>

          {isFilterEmpty ? (
            <Box mt={3}>
              <NoDataListFree
                message1="Sorry, No List"
                message2={
                  <Box display="flex" justifyContent="center">
                    <p style={{width: '75%'}}>
                      Please first select the position to be added with the{' '}
                      <span style={{color: '#039BE5'}}>Add Positions</span>{' '}
                      button above or the button below
                    </p>
                  </Box>
                }
              />
              <AssignFilter
                filters={assignFiltersOld}
                limit={20}
                initialSelections={selections}
                onApply={handleFilterApply}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    disabled={state >= STATE_FETCH}
                    variant="contained"
                    color="primary"
                  >
                    Add Positions
                  </Button>
                </Box>
              </AssignFilter>
            </Box>
          ) : (
            <>
              <Box display="flex" alignItems="center" mt={3} mb={2}>
                <TitleName style={{flexGrow: 1}}>
                  Applied to {dataProfileCount?.total.aggregate.count || 0}{' '}
                  positions
                </TitleName>

                <AssignFilter
                  filters={assignFiltersOld}
                  limit={20}
                  onApply={handleFilterApply}
                  initialSelections={selections}
                >
                  <Button
                    disabled={state >= STATE_FETCH}
                    variant="contained"
                    color="primary"
                  >
                    Filter
                  </Button>
                </AssignFilter>
              </Box>

              <PositionListing filterData={data.apply_filters} />
            </>
          )}
        </Box>
      ) : null}

      <AlertPopup
        open={data.alert_open}
        mutation={isAlertSubmit ? handleSubmitConfirm : props.history.goBack}
        handleClose={isAlertSubmit ? handleSubmitClose : handleCancelClose}
        feature={`${isAlertSubmit ? 'Confirm' : 'Discard'} Changes?`}
        message={`Are you sure you want to ${
          isAlertSubmit ? 'save' : 'discard unsaved'
        } changes?`}
        type={isAlertSubmit ? 'Confirm' : 'Discard'}
        status="cancel"
      />
    </PaperContainer>
  )
}

export default withRouter(ClaimPolicyFormPage)

const INITIAL_PAGINATION = {
  page: 0,
  limit: 20,
}

function PositionListing(props) {
  const {filterData} = props

  const [search, setSearch] = React.useState('')
  const [pagination, setPagination] = React.useState(INITIAL_PAGINATION)

  const {data, loading, error} = useQuery(GET_CLAIM_POSITION_LIST, {
    variables: {
      where: getWhereClaimPosition(filterData, {search: `%${search}%`}),
      offset: pagination.page * pagination.limit,
      limit: pagination.limit,
    },
  })

  const handlePageChange = page => {
    setPagination({...pagination, page})
  }

  const handleLimitChange = limit => {
    setPagination({page: 0, limit})
  }

  const handleSearchInput = ev => {
    setSearch(ev.target.value)
  }

  return (
    <>
      <OutlinedInput
        value={search}
        onChange={handleSearchInput}
        placeholder="Search"
        fullWidth
        margin="dense"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />

      <Box mt={2}>
        {!loading && error ? (
          <div>{JSON.stringify(error)}</div>
        ) : (
          <PositionList
            loading={loading}
            data={data?.positions}
            totalCount={data?.total.aggregate.count}
            page={pagination.page}
            limit={pagination.limit}
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
          />
        )}
      </Box>
    </>
  )
}

function PositionList(props) {
  const {
    data,
    totalCount,
    loading,
    page,
    limit,
    onPageChange,
    onLimitChange,
  } = props

  const TABLE_COLUMNS_CONFIG = [
    {title: 'Position Code', field: 'code'},
    {title: 'Position', field: 'title'},
  ]

  return (
    <MaterialTable
      isLoading={loading}
      data={data}
      totalCount={totalCount}
      page={page}
      pageSize={limit}
      onChangePage={onPageChange}
      onChangeRowsPerPage={onLimitChange}
      columns={TABLE_COLUMNS_CONFIG}
      options={TableOptions}
      components={{Container: TableContainer}}
    />
  )
}
