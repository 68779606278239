import gql from 'graphql-tag'

export const UPDATE_LEAVE_POLICY = gql`
  mutation(
    $id: Int!
    $parent: Int
    $name: String
    $infinite: Boolean
    $quota: Int
    $type: String
    $first: Int
    $second: Int
    $gender: enum_gender
    $affected_job: [company_job_profile_r_leave_insert_input!]!
  ) {
    update_time_leave_policy_children(
      _set: {
        name: $name
        parent: $parent
        infinite_quota: $infinite
        quota: $quota
        confirm_type: $type
        first_confirm_profile: $first
        second_confirm_profile: $second
        gender: $gender
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }

    delete_company_job_profile_r_leave(where: {policy: {_eq: $id}}) {
      affected_rows
    }

    insert_company_job_profile_r_leave(objects: $affected_job) {
      affected_rows
    }
  }
`

export const UPDATE_LEAVE_POLICY_WITH_FILTER_ASSIGN = gql`
  mutation(
    $id: Int!
    $parent: Int!
    $name: String!
    $infinite_quota: Boolean
    $quota: Int
    $confirm_type: String
    $first_confirm_profile: Int
    $second_confirm_profile: Int
    $gender: String
    $filter: JSON
    $isUpdatePosition: Boolean
  ) {
    LeaveChildPolicyUpdate(
      id: $id
      parent: $parent
      name: $name
      infinite_quota: $infinite_quota
      quota: $quota
      confirm_type: $confirm_type
      first_confirm_profile: $first_confirm_profile
      second_confirm_profile: $second_confirm_profile
      gender: $gender
      filter: $filter
      is_update_position: $isUpdatePosition
    ) {
      success
    }
  }
`
