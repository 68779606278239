import React from 'react'
import {Doughnut} from 'react-chartjs-2'
import {Typography, Divider, makeStyles, Paper} from '@material-ui/core'
import {FlexBetween, GreyTypography} from '../../../../GlobalStyles'
const useStyles = makeStyles(() => ({
  age: {
    width: '100%',
    margin: '1rem',
    borderRadius: '5px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  titleAge: {
    color: '#055469',
    padding: 12,
    fontWeight: 600,
  },
  divAge: {
    padding: 24,
    display: 'flex',
    flexDirection: 'row',
  },
  divChart: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  chart: {
    margin: 'auto',
    width: '50%',
  },
  infoChart: {
    width: '50%',
    margin: '20px 0px',
    padding: '0 1rem',
  },
  circle: {
    borderRadius: '50%',
    marginRight: '1rem',
    width: '12px',
    height: '12px',
  },
  divMinAge: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    alignItems: 'center',
  },
  textPercentGender: {fontWeight: 400, margin: '0 0 1rem 2rem'},
}))
const ChartDetail = ({dataEmployee}) => {
  const classes = useStyles()
  const sumAge =
    (dataEmployee && dataEmployee.age1824.aggregate.count) +
    (dataEmployee && dataEmployee.age2534.aggregate.count) +
    (dataEmployee && dataEmployee.age3549.aggregate.count) +
    (dataEmployee && dataEmployee.age50.aggregate.count)
  const undefinedAge =
    (dataEmployee && dataEmployee.total_employee.aggregate.count) - sumAge

  const dataAge = {
    labels: [
      '18 - 24 Years',
      '25 - 34 Years',
      '35 - 49 Years',
      '50+ Years',
      'Undefined',
    ],
    datasets: [
      {
        data: [
          dataEmployee && dataEmployee.age1824.aggregate.count,
          dataEmployee && dataEmployee.age2534.aggregate.count,
          dataEmployee && dataEmployee.age3549.aggregate.count,
          dataEmployee && dataEmployee.age50.aggregate.count,
          undefinedAge,
        ],
        backgroundColor: [
          '#1f78b4',
          '#33a02c',
          '#a6cee3',
          '#b2df8a',
          '#e3e3e3',
        ],
      },
    ],
  }
  const countMale = dataEmployee && dataEmployee.gender_male.aggregate.count
  const countFemale = dataEmployee && dataEmployee.gender_female.aggregate.count
  const undefinedGender =
    (dataEmployee && dataEmployee.total_employee.aggregate.count) -
    (countMale + countFemale)

  const dataGender = {
    labels: ['Male', 'Female', 'Undefined'],
    datasets: [
      {
        data: [countMale, countFemale, undefinedGender],
        backgroundColor: ['#1f78b4', '#a6cee3', '#e3e3e3'],
      },
    ],
  }

  const renderLegendAge = () => {
    let newArr = []
    for (let i = 0; i < dataAge.labels.length; i++) {
      newArr.push({
        color: dataAge.datasets[0].backgroundColor[i],
        data: dataAge.datasets[0].data[i],
        labels: dataAge.labels[i],
      })
    }

    return newArr.map((resAge, i) => (
      <div className={classes.divMinAge}>
        <div
          style={{
            border: `solid 3px ${resAge.color}`,
          }}
          className={classes.circle}
        />
        <FlexBetween style={{width: '80%'}}>
          <Typography variant="body2">{resAge.labels}</Typography>
          <GreyTypography variant="body2">{resAge.data}</GreyTypography>
        </FlexBetween>
      </div>
    ))
  }

  const renderAgePercent = val => {
    const percent = (
      (val / (countMale + countFemale + undefinedGender)) *
      100
    ).toFixed(0)
    return `${val} (${percent}%)`
  }

  return (
    <div className={classes.divAge}>
      <Paper className={classes.age}>
        <Typography variant="h6" className={classes.titleAge}>
          Age
        </Typography>
        <Divider />
        <div className={classes.divChart}>
          <div className={classes.chart}>
            <Doughnut data={dataAge} options={{legend: false}} />
          </div>
          <Divider
            orientation="vertical"
            style={{
              height: '15rem',
            }}
          />
          <div className={classes.infoChart}>{renderLegendAge()}</div>
        </div>
      </Paper>
      <Paper className={classes.age}>
        <Typography variant="h6" className={classes.titleAge}>
          Gender Composition
        </Typography>
        <Divider />
        <div className={classes.divChart}>
          <div className={classes.chart}>
            <Doughnut data={dataGender} options={{legend: false}} />
          </div>
          <Divider
            orientation="vertical"
            style={{
              height: '15rem',
            }}
          />
          <div className={classes.infoChart}>
            <div className={classes.divMinAge} style={{marginBottom: 5}}>
              <div
                style={{
                  border: 'solid 3px #1f78b4',
                }}
                className={classes.circle}
              />
              <Typography variant="body1">Male</Typography>
            </div>
            <GreyTypography variant="h6" className={classes.textPercentGender}>
              {renderAgePercent(countMale)}
            </GreyTypography>
            <div className={classes.divMinAge} style={{marginBottom: 5}}>
              <div
                style={{
                  border: 'solid 3px #a6cee3',
                }}
                className={classes.circle}
              />
              <Typography variant="body1">Female</Typography>
            </div>
            <GreyTypography variant="h6" className={classes.textPercentGender}>
              {renderAgePercent(countFemale)}
            </GreyTypography>
            <div className={classes.divMinAge} style={{marginBottom: 5}}>
              <div
                style={{
                  border: 'solid 3px #e3e3e3',
                }}
                className={classes.circle}
              />
              <Typography variant="body1">Undefined</Typography>
            </div>
            <GreyTypography
              variant="h6"
              className={classes.textPercentGender}
              style={{marginBottom: 0}}
            >
              {renderAgePercent(undefinedGender)}
            </GreyTypography>
          </div>
        </div>
      </Paper>
    </div>
  )
}
export default ChartDetail
