import gql from 'graphql-tag'

export const EDIT_DESIGNATION = gql`
  mutation($id: Int!, $object: company_employee_positions_set_input!) {
    update_company_employee_positions_by_pk(
      pk_columns: {id: $id}
      _set: $object
    ) {
      id
    }
  }
`
