import gql from 'graphql-tag'

export const DELETE_DESIGNATION = gql`
  mutation($id: Int) {
    update_company_employee_positions(
      _set: {deletedAt: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
