import {useQuery} from '@apollo/react-hooks'
import {
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useEffect, useState} from 'react'
import {BlueIcon, FlexBetween, TitleName} from '../../../../../GlobalStyles'
import {GET_LOCATION_RESTRICTION_WSFORM} from '../../../../../graphql/queries'
import NoDataListFree from '../../../../shared-components/NoDataListFreeComponent'
import SelectDataPopup from '../../../../shared-components/popup/SelectDataPopup'
import {TableOptions} from '../../../../shared-components/table/TableOptions'

const TabsCheckin = props => {
  const {
    selectedLocations,
    setSelectedLocations,
    isError,
    setCount,
    locationOption,
    setLocationOption,
    isEdit,
    setPrevLoc,
    setIsUpdatePosition,
  } = props
  const [search, setSearch] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })
  const [paginationFilter, setPaginationFilter] = useState({
    offset: 0,
    limit: 10,
  })
  const [openEdit, setOpenEdit] = useState(false)
  const {data, loading, error} = useQuery(GET_LOCATION_RESTRICTION_WSFORM, {
    variables: {
      choose: selectedLocations,
      search: `%${search}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      order: {
        name: 'asc',
      },
    },
    skip: locationOption !== 1,
    fetchPolicy: 'cache-and-network',
  })

  const {data: dataFilter, error: errFilter} = useQuery(
    GET_LOCATION_RESTRICTION_WSFORM,
    {
      variables: {
        search: `%${searchFilter}%`,
        offset: paginationFilter.offset * paginationFilter.limit,
        limit: paginationFilter.limit,
        order: {
          name: 'asc',
        },
      },
      skip: locationOption !== 1 && !openEdit,
      fetchPolicy: 'cache-and-network',
    }
  )

  useEffect(() => {
    if (data) {
      setCount(data.countId.aggregate.count)
    }
  }, [data])

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleOpenEdit = () => {
    setOpenEdit(prev => !prev)
  }

  const handleSaveFilter = result => {
    setSelectedLocations(result)
    handleOpenEdit()
    if (isEdit) {
      setIsUpdatePosition(true)
    }
  }

  if (error || errFilter) {
    JSON.stringify(error || errFilter)
  }

  return (
    <>
      <TitleName gutterBottom>
        Location Option *{' '}
        {locationOption === 'null' && isError ? (
          <Typography variant="body2" color="error" display="inline">
            This field is required
          </Typography>
        ) : (
          ''
        )}
      </TitleName>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="option"
          name="option1"
          value={true}
          style={{display: 'flex', flexDirection: 'row'}}
        >
          <FormControlLabel
            control={
              <Radio
                onChange={() => setLocationOption(1)}
                checked={locationOption === 1}
              />
            }
            label="Strict"
          />
          <FormControlLabel
            control={
              <Radio
                onChange={() => setLocationOption(2)}
                checked={locationOption === 2}
              />
            }
            label="Flexible"
          />
        </RadioGroup>
        <Typography style={{color: '#a9a8a8'}}>
          {locationOption === 1
            ? 'Employee only able to check in on listed location'
            : locationOption === 2
            ? 'Employee able to check in anywhere'
            : ''}
        </Typography>
      </FormControl>
      {locationOption === 1 && (
        <>
          <FlexBetween style={{margin: '20px 0'}}>
            <Typography variant="body2" style={{fontWeight: 600}}>
              {!loading &&
                (selectedLocations.length === data.countAll.aggregate.count
                  ? `Applied to All Locations (${data.countId.aggregate.count} Locations)`
                  : `Applied to ${data.countId.aggregate.count} Locations`)}{' '}
              {isError && data?.countId.aggregate.count === 0 && (
                <Typography variant="body2" color="error" display="inline">
                  This field is required
                </Typography>
              )}
            </Typography>

            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleOpenEdit}
            >
              {selectedLocations.length > 0 ? 'Edit' : 'Add Location'}
            </Button>
          </FlexBetween>
          <TextField
            placeholder="Search"
            variant="outlined"
            size="small"
            fullWidth
            value={search}
            onChange={e => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BlueIcon>search</BlueIcon>
                </InputAdornment>
              ),
            }}
            style={{marginBottom: 10}}
          />
          <MaterialTable
            columns={[
              {
                title: 'Office Name',
                field: 'name',
              },
              {
                title: 'Address',
                field: 'location.address',
              },
            ]}
            options={{
              ...TableOptions,
              selection: false,
              emptyRowsWhenPaging: false,
            }}
            components={{
              Toolbar: () => null,
              Container: props => <Paper {...props} elevation={0} />,
            }}
            data={data?.time_attendance_location}
            isLoading={loading}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            totalCount={data?.count.aggregate.count}
            page={pagination.offset}
            pageSize={pagination.limit}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <NoDataListFree
                    message1="Sorry, No List"
                    message2="Please first select the location to be added with the Add Location button or the button bellow"
                    button={
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={handleOpenEdit}
                      >
                        Add Location
                      </Button>
                    }
                  />
                ),
              },
            }}
          />
          <SelectDataPopup
            open={openEdit}
            onCancel={handleOpenEdit}
            handleConfirm={handleSaveFilter}
            titleText="Select Office Location"
            cancelText="Cancel"
            saveText="Confirm"
            appliedText={`Locations`}
            search={searchFilter}
            setSearch={setSearchFilter}
            data={dataFilter?.time_attendance_location.map(res => ({
              id: res.id,
              value1: res.name,
              value2: res.location?.address,
            }))}
            count={dataFilter?.count.aggregate.count}
            type="officeLocation"
            pagination={paginationFilter}
            setPagination={setPaginationFilter}
            setPrev={isEdit && setPrevLoc}
          />
        </>
      )}
    </>
  )
}

export default TabsCheckin
