import styled from 'styled-components'
import {
  Dialog,
  withStyles,
  LinearProgress,
  Avatar,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  Button,
} from '@material-ui/core'

export const ModalHeader = styled.div`
  padding: 1.6rem 2rem;
  background-color: #014a62;
  color: #ffffff;
`
export const ModalHeaderWhite = styled.div`
  padding: 1.6rem 2rem;
  background-color: #eff2f4;
`
export const ModalContent = styled.div`
  padding: 1.6rem 2rem;
`

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const FlexColumnStart = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`

export const TitleXl = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
`

export const StyledLinear = withStyles({
  root: {
    borderRadius: 22,
    margin: '5px 1rem 10px 0',
    background: 'white',
    border: '1px solid lightgrey',
    height: 10,
    width: '80%',
  },
  // bar1Determinate: {
  //   background: '#4caf50',
  // },
})(LinearProgress)

export const AvatarCust = styled(Avatar)`
  width: 60px !important;
  height: 60px !important;
  margin-right: 5px !important;
`

export const StyledDialog = styled(Dialog)`
  font-family: ${props => props.theme.font};
`

export const TextSM = styled.p`
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
`

export const Pmd = styled.p`
  margin: 0;
  font-size: 14px;

  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 18px;
  }
`

export const BoxText = styled.div`
  padding-top: 10px;
  font-family: Muli;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
`

export const GreyTypography = styled(Typography)`
  color: #a9a8a8;
`

export const InformationContainer = styled.div`
  padding-bottom: 24px;
`

export const StyledBorderedExpansion = styled(ExpansionPanel)`
  margin-top: 12px;
  border: 1px solid #a9a8a8;
  border-radius: 5px;
`

export const StyledExpansionSummaryAssign = styled(ExpansionPanelSummary).attrs(
  {
    classes: {content: 'content'},
  }
)`
  .content {
    display: block;
    padding: 0 16px;
  }
`
export const StyledSmallButton = styled(Button)`
  background: ${props => props.background || '#055469'};
  border-radius: 5px;
  border: 0;
  color: white;
  height: 4.5%;
  padding: 0 15px;
  text-transform: capitalize;
  width: 5rem;
  &:hover {
    background: ${props => props.background || '#055469'};
  }
`

export const TypographyCapitalize = styled(Typography)`
  text-transform: capitalize;
`

export const TextBold = styled.div`
  font-weight: bold;
`

export const TextBlue = styled.div`
  font-family: Muli;
  color: #039be5;
`

export const PsmGrey = styled.p`
  margin: 0;
  font-size: 12px;
  color: #a9a8a8;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 15px;
  }
`

export const customGroupSelect = {
  control: provided => ({
    ...provided,
    width: '100%',
    outline: 'none',
    padding: 10,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: '#055469',
  }),
  multiValueLabel: base => ({
    ...base,
    color: '#fff',
  }),
  multiValueRemove: base => ({
    ...base,
    color: '#ffff',
    '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
  }),
  menu: base => ({
    ...base,
    maxWidth: '100%',
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: '#bebecb',
    }
  },
}
