import React, {useState, useEffect} from 'react'
import {CircularProgress} from '@material-ui/core'
import MaterialTable from 'material-table'
import {useQuery} from '@apollo/react-hooks'
import moment from 'moment'
import {GET_PAYROLL_REPORT} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import {convertToRoundedRupiah} from '../../../../../utils/helpers'
import {Link} from 'react-router-dom'
import helperStyle, {
  TableOptions,
  TextBlueBold,
  CenterWrapper,
  pageSizeOption,
} from '../../PayrollPageStyles'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import {tableStyle} from '../../PayrollTableStyles'

export default function Payroll(props) {
  const {period} = props
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const months = moment.monthsShort()

  const {data: payrollReportList, loading, error} = useQuery(
    GET_PAYROLL_REPORT,
    {
      wlb_skipPatch: true,
      variables: {
        company: COMPANY_ID,
        year: Number(moment(period).format('YYYY')),
        offset: currentPage * rowsPerPage,
        limit: rowsPerPage,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  useEffect(() => {
    setCurrentPage(0)
  }, [period, rowsPerPage])

  const classes = helperStyle()

  return (
    <>
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {payrollReportList &&
        payrollReportList.v_finance_payrolls.length === 0 &&
        !loading && (
          <NoDataListComponent
            message={`You can set your employee and customize your payroll method on settings `}
          />
        )}
      {payrollReportList && payrollReportList.v_finance_payrolls.length > 0 && (
        <MaterialTable
          options={{...TableOptions, paging: false}}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onChangePage={page => {
            setCurrentPage(page)
          }}
          isLoading={payrollReportList && loading}
          onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
          columns={[
            {
              title: 'Period',
              field: 'period',
              ...tableStyle({}, 'cell-first'),
              customSort: (a, b) => {
                return (
                  moment().set({year: a.year, month: a.month}) -
                  moment().set({year: b.year, month: b.month})
                )
              },
              render: rowData => {
                return `${months[rowData.month - 1]} ${rowData.year}`
              },
            },
            {
              title: 'Basic Salary',
              field: 'total_salary',
              ...tableStyle({}, 'cell-number'),
              render: rowData => {
                return convertToRoundedRupiah(rowData.total_salary, false)
              },
            },
            {
              title: 'Earning',
              field: 'addition',
              ...tableStyle({}, 'cell-number'),
              render: rowData => {
                return convertToRoundedRupiah(rowData.addition, false)
              },
            },
            {
              title: 'Deduction',
              field: 'deduction',
              ...tableStyle({}, 'cell-number'),
              render: rowData => {
                return convertToRoundedRupiah(rowData.deduction, false)
              },
            },

            {
              title: 'Take Home Pay',
              field: 'total_final_salary',
              ...tableStyle({}, 'cell-number'),
              render: rowData => {
                return convertToRoundedRupiah(rowData.total_final_salary, false)
              },
            },
            {
              title: 'Action',
              field: 'action',
              ...tableStyle({minWidth: 120}, 'cell'),
              sorting: false,
              render: rowData => (
                <Link
                  to={{
                    pathname: `/financial/payroll/report/payroll/detail`,
                    search: `?year=${rowData.year}&month=${rowData.month}`,
                    state: {
                      active: 'report-payroll-detail',
                      searchable: true,
                      month: rowData.month,
                      year: rowData.year,
                      showPeriod: false,
                      rangePeriod: false,
                      showMonthYearPeriod: true,
                      filter: true,
                    },
                  }}
                  className={classes.link}
                >
                  <TextBlueBold>Details</TextBlueBold>
                </Link>
              ),
            },
          ]}
          data={payrollReportList.v_finance_payrolls}
        />
      )}
    </>
  )
}
