import React, {useState} from 'react'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
} from '../../../../GlobalStyles'
import {
  Button,
  IconButton,
  makeStyles,
  Divider,
  Stepper,
  withStyles,
  StepConnector,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {
  ADD_VERSION_CROSS_MENTORING,
  UPDATE_VERSION_CROSS_MENTORING,
  ADD_MEMBER_VERSION_CROSS_MENTORING,
} from '../../../../graphql/mutations'
import {holdingRolesContext} from '../../../../utils/helpers'
import moment from 'moment'
import AboutStep from './add-version-step/AboutStepComponent'
import MentorStep from './add-version-step/MentorStepComponent'
import MenteeStep from './add-version-step/MenteeStepComponent'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    color: '#039be5 !important',
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  labelActive: {
    color: '#039be5 !important',
  },
  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  mb16: {
    marginBottom: '16px !important',
  },
  paper: {
    minHeight: '70vh',
  },
  contentDialog: {
    color: '#000',
    fontSize: 18,
    margin: '30px 0px 30px 0px',
    fontWeight: '500',
  },
  titleDialog: {
    fontSize: 22,
  },
  actionDialog: {
    padding: '28px 18px',
  },
}))

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const getSteps = () => {
  return ['About', 'Mentor', 'Mentee']
}

const AddVersionComponent = props => {
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [isError, setIsError] = useState(false)
  const [isErrorDate, setIsErrorDate] = useState(false)
  const [errorDate, setErrorDate] = useState('')
  const [open, setOpen] = useState(false)
  const [mentors, setMentors] = useState([])
  const [mentees, setMentees] = useState([])

  const generateDate = val => {
    if (val) {
      return moment(new Date()).format('DD-MM-YYYY')
    } else {
      return moment(new Date()).format('YYYY-MM-DD')
    }
  }

  const [dataContext, setDataContext] = useState({
    id: null,
    aboutStep: {
      name: '',
      description: '',
      start_date: generateDate(),
      end_date: generateDate(),
      status: '',
    },
  })

  const steps = getSteps()

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <AboutStep
            isError={isError}
            dataContext={dataContext}
            setDataContext={setDataContext}
            isErrorDate={isErrorDate}
            errorDate={errorDate}
          />
        )
      case 1:
        return (
          <MentorStep
            isError={isError}
            assignee={mentors}
            setAssignee={setMentors}
          />
        )
      case 2:
        return (
          <MenteeStep
            isError={isError}
            assignee={mentees}
            setAssignee={setMentees}
          />
        )
      default:
        return (
          <AboutStep
            isError={isError}
            dataContext={dataContext}
            setDataContext={setDataContext}
            isErrorDate={isErrorDate}
            errorDate={errorDate}
          />
        )
    }
  }

  const [mutationAddVersion] = useMutation(ADD_VERSION_CROSS_MENTORING)
  const [mutationUpdateVersion] = useMutation(UPDATE_VERSION_CROSS_MENTORING)
  const [mutationUpdateMember] = useMutation(ADD_MEMBER_VERSION_CROSS_MENTORING)

  const handleNext = () => {
    const {
      name,
      description,
      start_date,
      end_date,
      status,
    } = dataContext.aboutStep
    if (activeStep === 0) {
      if (name === '' || description === '' || status === '') {
        setIsError(true)
      } else if (end_date < start_date) {
        setIsErrorDate(true)
        setErrorDate('Date must be more than start date')
      } else {
        if (!dataContext.id) {
          const data = {
            name: name,
            description: description,
            start_date: start_date,
            end_date: end_date,
            status: status,
          }

          mutationAddVersion({
            variables: data,
            ...holdingRolesContext,
          }).then(res => {
            setDataContext({
              id:
                res.data.insert_classroom_cross_mentoring_versions.returning[0]
                  .id,
              aboutStep: {
                ...data,
              },
            })
            setIsError(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
          })
        } else {
          const dataToSend = {
            mentoringId: dataContext.id,
            name: name,
            description: description,
            start_date: start_date,
            end_date: end_date,
            status: status,
          }
          const data = {
            name: name,
            description: description,
            start_date: start_date,
            end_date: end_date,
            status: status,
          }
          mutationUpdateVersion({
            variables: dataToSend,
            ...holdingRolesContext,
          }).then(res => {
            setDataContext({
              ...dataContext,
              aboutStep: {
                ...data,
              },
            })
            setIsError(false)
            setActiveStep(prevActiveStep => prevActiveStep + 1)
          })
        }
      }
    } else if (activeStep === 1) {
      if (mentors.length === 0) {
        setIsError(true)
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
        setIsError(false)
      }
    } else if (activeStep === 2) {
      if (mentees.length === 0) {
        setIsError(true)
      } else {
        setOpen(true)
        setIsError(false)
      }
    }
  }
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleClickCancel = () => {
    if (dataContext.id) {
      const dataToSend = {
        mentoringId: dataContext.id,
        name: dataContext.aboutStep.id,
        description: dataContext.aboutStep.description,
        start_date: generateDate(dataContext.aboutStep.start_date),
        end_date: generateDate(dataContext.aboutStep.end_date),
        status: 'DRAFT',
      }
      mutationUpdateVersion({variables: dataToSend}).then(() => {
        props.history.push({
          pathname: '/holding-learning/cross-mentoring',
          state: {active: 'cross-mentoring-list'},
        })
      })
    } else {
      props.history.goBack()
    }
  }

  const handleSubmit = () => {
    let members = []
    mentors &&
      mentors.map(val => {
        members.push({
          user_work_id: val.id,
          versioning_id: dataContext.id,
          is_mentee: false,
          is_mentor: true,
        })
      })
    mentees &&
      mentees.map(val => {
        members.push({
          user_work_id: val.id,
          versioning_id: dataContext.id,
          is_mentee: true,
          is_mentor: false,
        })
      })
    mutationUpdateMember({
      variables: {
        objects: members,
      },
      ...holdingRolesContext,
    })
      .then(() => {
        setOpen(false)
        props.history.push({
          pathname: '/holding-learning/cross-mentoring',
          state: {active: 'cross-mentoring-list'},
        })
        enqueueSnackbar(`All Changes have been saved`, {
          variant: 'success',
          autoHideDuration: 1000,
        })
      })
      .catch(() => {
        enqueueSnackbar(`Update failed`, {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link onClick={handleClickCancel}>
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6">Add Version</AddEditTitle>
        </FormTitleContainer>
        <div>
          {activeStep === 0 ? (
            <Button
              className={classes.backButton}
              component={Link}
              onClick={handleClickCancel}
              size="large"
            >
              Cancel
            </Button>
          ) : (
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleBack}
              className={classes.backButton}
            >
              Previous
            </Button>
          )}

          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
          </Button>
        </div>
      </FormToolbar>
      <Divider className={classes.mb16} />
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomConnector />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel
              classes={{
                active: classes.labelActive,
                completed: classes.labelActive,
              }}
              StepIconProps={{
                classes: {
                  root: classes.iconRoot,
                  text: classes.iconText,
                  active: classes.iconActive,
                  completed: classes.iconActive,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{padding: '2rem'}}>{getStepContent(activeStep)}</div>

      <Dialog
        maxwidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle className={classes.titleDialog} style={{color: '#014A62'}}>
          Submit Version?
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText className={classes.contentDialog}>
            Are you sure to sumbit the version?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionDialog}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            size="large"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </PaperContainer>
  )
}

export default withRouter(AddVersionComponent)
