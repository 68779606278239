import React, {useState} from 'react'
import {Typography, Divider, Paper} from '@material-ui/core'
import {TrendingUp, DataUsage, AssignmentTurnedIn} from '@material-ui/icons'
import {GET_DETAIL_COMPANY_GOAL} from '../../../../graphql/queries'
import {EnhancedToolbar} from '../../../../GlobalStyles'
import {SectionDetailContainer} from '../CompanySummaryPageStyle'
import Loading from '../../../../components/loading/LoadingComponent'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import {useQuery} from '@apollo/react-hooks'
import {Doughnut} from 'react-chartjs-2'
import {
  DoughnutContainer,
  AggregateContainer,
} from '../../../../components/widgets/doughnut/DoughnutChartStyles'
import {holdingRolesContext} from '../../../../utils/helpers'

const GoalDetailComponent = ({idCompany}) => {
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  })

  const {data, error} = useQuery(GET_DETAIL_COMPANY_GOAL, {
    variables: {
      company: idCompany,
      filterDate: {
        _gte: filterDate?.startDate,
        _lte: filterDate?.endDate,
      },
    },
    ...holdingRolesContext,
  })

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    console.error(error)
    return 'something went wrong'
  }

  const totalPersonalGoal =
    data?.all_personal_goal_complete?.aggregate
      ?.total_all_personal_goal_complete +
    data?.all_personal_goal_todo?.aggregate?.total_all_personal_goal_todo
  const totalCompanyGoal =
    data?.all_company_goal_complete?.aggregate
      ?.total_all_company_goal_complete +
    data?.all_company_goal_todo?.aggregate?.total_all_company_goal_todo

  const dataGoalChart = {
    personal: {
      labels: ['Complete', 'To Do'],
      datasets: [
        {
          data: [
            data?.all_personal_goal_complete?.aggregate
              ?.total_all_personal_goal_complete ?? 0,
            data?.all_personal_goal_todo?.aggregate
              ?.total_all_personal_goal_todo ?? 0,
          ],
          backgroundColor: ['#1f78b4', '#a6cee3'],
        },
      ],
    },
    company: {
      labels: ['Complete', 'To Do'],
      datasets: [
        {
          data: [
            data?.all_company_goal_complete?.aggregate
              ?.total_all_company_goal_complete ?? 0,
            data?.all_company_goal_todo?.aggregate
              ?.total_all_company_goal_todo ?? 0,
          ],
          backgroundColor: ['#1f78b4', '#a6cee3'],
        },
      ],
    },
  }

  const renderLegendPersonal = feature => {
    const newArr = []
    for (let i = 0; i < dataGoalChart[feature].labels.length; i++) {
      newArr.push({
        color: dataGoalChart[feature].datasets[0].backgroundColor[i],
        data: dataGoalChart[feature].datasets[0].data[i],
        labels: dataGoalChart[feature].labels[i],
      })
    }
    return newArr.map((resGoal, i) => (
      <div className="legend-container" key={i}>
        <div
          style={{
            border: `solid 3px ${resGoal.color}`,
          }}
          className="circle"
        />
        <div>
          <p className="p-legend-status">{resGoal.labels}</p>
          <p className="p-legend-value-goal">{resGoal.data}</p>
        </div>
      </div>
    ))
  }

  const options = {
    cutoutPercentage: 80,
    rotation: 4.7,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: 'left',
      labels: {
        boxWidth: 15,
        fontSize: 12,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  }

  return (
    <SectionDetailContainer>
      <Paper className="paper-padding">
        <EnhancedToolbar>
          <Typography variant="h6" color="primary">
            Goal
          </Typography>
          <div className="section-filter">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                InputProps={{
                  readOnly: true,
                }}
                inputVariant="outlined"
                format="MMM dd, yyyy"
                id="start-date"
                placeholder="Start Date"
                value={filterDate.startDate && new Date(filterDate.startDate)}
                onChange={date =>
                  setFilterDate({
                    ...filterDate,
                    startDate: moment(date).format('YYYY-MM-DD'),
                  })
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  size: 'small',
                }}
              />
              <KeyboardDatePicker
                InputProps={{
                  readOnly: true,
                }}
                inputVariant="outlined"
                format="MMM dd, yyyy"
                id="end-date"
                placeholder="End Date"
                minDate={filterDate.startDate}
                value={filterDate.endDate && new Date(filterDate.endDate)}
                onChange={date =>
                  setFilterDate({
                    ...filterDate,
                    endDate: moment(date).format('YYYY-MM-DD'),
                  })
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  size: 'small',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </EnhancedToolbar>
        <Divider className="divider-margin" />
        <div className="info-container">
          <div className="info-section">
            <div className="icon-container">
              <TrendingUp className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${data?.all_goal?.aggregate?.avg?.avg_all_goal?.toFixed(
                2
              ) ?? 0}%`}</p>
              <p className="p-text">Average Goal Score</p>
            </div>
          </div>
          <div className="info-section">
            <div className="icon-container">
              <DataUsage className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${data?.all_goal?.aggregate
                ?.total_all_goal ?? 0}`}</p>
              <p className="p-text">Total Goal</p>
            </div>
          </div>
          <div className="info-section">
            <div className="icon-container">
              <AssignmentTurnedIn className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${data?.all_goal_complete?.aggregate
                ?.total_all_goal_complete ?? 0}`}</p>
              <p className="p-text">Total Complete Goal</p>
            </div>
          </div>
        </div>
        <div className="chart-container">
          <div className="wrap-chart">
            <p className="p-chart-title">Personal Goal</p>
            <div className="chart-section">
              <div className="chart-goal">
                <DoughnutContainer className="inner-chart">
                  <Doughnut data={dataGoalChart.personal} options={options} />
                  <AggregateContainer>
                    <span>{`${totalPersonalGoal ?? 0}`}</span>
                    <span>Goal</span>
                  </AggregateContainer>
                </DoughnutContainer>
                <div className="average-container">
                  <span className="span-average">Average Goal Score:</span>
                  <span className="span-average-score">{`${data?.all_personal_goal?.aggregate?.avg?.avg_all_personal_goal?.toFixed(
                    2
                  ) ?? 0}%`}</span>
                </div>
              </div>
              <div className="info-chart">
                {renderLegendPersonal('personal')}
              </div>
            </div>
          </div>
          <Divider orientation="vertical" className="divider-chart" />
          <div className="wrap-chart margin-chart">
            <p className="p-chart-title">Company Goal</p>
            <div className="chart-section">
              <div className="chart-goal">
                <DoughnutContainer className="inner-chart">
                  <Doughnut data={dataGoalChart.company} options={options} />
                  <AggregateContainer>
                    <span>{`${totalCompanyGoal ?? 0}`}</span>
                    <span>Goal</span>
                  </AggregateContainer>
                </DoughnutContainer>
                <div className="average-container">
                  <span className="span-average">Average Goal Score:</span>
                  <span className="span-average-score">{`${data?.all_company_goal?.aggregate?.avg?.avg_all_company_goal?.toFixed(
                    2
                  ) ?? 0}%`}</span>
                </div>
              </div>
              <div className="info-chart">
                {renderLegendPersonal('company')}
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </SectionDetailContainer>
  )
}

export default GoalDetailComponent
