import {
  ADD_PEOPLE_PROFILE_IDENTITY,
  UPDATE_PEOPLE_PROFILE_IDENTITY,
} from '../../../../../graphql/mutations'
import {GET_PEOPLE_PROFILE_KTP_NPWP} from '../../../../../graphql/queries'
import {inputRequiredHelperText} from '../../payroll-settings/payroll-settings-tabs/formValidation.helper'

export const updateIdentity = ({client, userId, dataForm}) => {
  const promise = client.query({
    query: GET_PEOPLE_PROFILE_KTP_NPWP,
    fetchPolicy: 'network-only',
    variables: {
      user_id: userId,
    },
  })

  promise.then(result => {
    const ktp = result.data.people_identities.filter(d => d.type === 'KTP')
    const npwp = result.data.people_identities.filter(d => d.type === 'NPWP')

    const isEditingKTP = ktp?.length > 0
    const isEditingNPWP = npwp?.length > 0

    client.mutate({
      mutation: isEditingNPWP
        ? UPDATE_PEOPLE_PROFILE_IDENTITY
        : ADD_PEOPLE_PROFILE_IDENTITY,
      variables: {
        ...(isEditingNPWP && {id: npwp?.[0]?.id}),
        new_card_id: dataForm.npwp,
        user_id: userId,
        type: 'NPWP',
      },
    })

    client.mutate({
      mutation: isEditingKTP
        ? UPDATE_PEOPLE_PROFILE_IDENTITY
        : ADD_PEOPLE_PROFILE_IDENTITY,
      variables: {
        ...(isEditingKTP && {id: ktp?.[0]?.id}),
        new_card_id: dataForm.ktp,
        user_id: userId,
        type: 'KTP',
      },
    })
  })
}

const idType = {
  ktp: 'KTP',
  npwp: 'NPWP',
}

const validate = (type, dataForm, used) => {
  const isNull = !dataForm?.[type]
  const isUsed = used[type] > 0

  if (!(isNull || isUsed)) {
    return {
      error: false,
      helperText: '',
    }
  }

  const helperText = isNull
    ? inputRequiredHelperText(true, !dataForm?.[type])
    : `${idType[type]} has been used`

  return {
    error: isNull || isUsed,
    helperText,
  }
}

export const getValidate = (isSubmit, dataForm, used) => {
  if (!isSubmit) {
    return {}
  }

  return {
    ktp: validate('ktp', dataForm, used),
    npwp: validate('npwp', dataForm, used),
  }
}
