import gql from 'graphql-tag'

export const UPDATE_KPI_ASPECT = id => {
  return gql`
      mutation updateKpiAspect($data: kpi_aspects_set_input) {
        update_kpi_aspects(where: {id: {_eq: ${id}}}, _set: $data) {
          returning {
            id
          }
        }
      }
    `
}
