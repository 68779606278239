import React from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery, useMutation} from '@apollo/react-hooks'

import clsx from 'classnames'

import {
  makeStyles,
  Box,
  Divider,
  Typography,
  Button,
  IconButton,
  Icon,
  OutlinedInput,
  CircularProgress,
  Avatar,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'

import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
} from '../../../../../GlobalStyles'
import {
  CurrencyInput,
  useInputState,
  useNumericInput,
} from '../../ClaimLimitPageStyles'
import AlertPopup from '../../../../shared-components/popup/ResultPopup'

import {COMPANY_ID} from '../../../../../utils/globals'
import {GET_INDIVIDUAL_TOPUP_INFO} from '../../../../../graphql/queries/budget/limit/getEmployeeTopUp.query'
import {TOPUP_EMPLOYEE_BALANCE} from '../../../../../graphql/mutations/budget/limit/employeeBalance.mutation'
import {convertToRupiah} from '../../../../../utils/helpers'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },

  paymentInfo: {
    maxWidth: 600,
    borderSpacing: theme.spacing(1.75, 1),
    marginBottom: theme.spacing(-1),
  },
  paymentInfoHeader: {
    color: theme.palette.text.secondary,
    fontWeight: '400',
    textAlign: 'left',
  },
  paymentInfoData: {
    color: theme.palette.primary.main,
    textAlign: 'right',
  },

  nominalRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelError: {
    color: theme.palette.error.main,
    maxWidth: 600,
    margin: theme.spacing(1, 1.75, 0),
  },
  input: {
    marginTop: theme.spacing(1),
    width: '100%',
    maxWidth: 600,
  },

  employeeAvatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
  employeeName: {
    fontWeight: '700',
  },
  employeeRegistration: {
    color: theme.palette.primary.main,
    fontWeight: '700',
  },
  employeePosition: {
    color: theme.palette.text.secondary,
  },
}))

function EmployeeIndividualTopup(props) {
  const {employeeID} = props.match.params

  const styles = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [dispatchTopup, {loading: dispatching}] = useMutation(
    TOPUP_EMPLOYEE_BALANCE
  )

  const {data, loading, error} = useQuery(GET_INDIVIDUAL_TOPUP_INFO, {
    wlb_skipPatch: true,
    variables: {
      employeePlacementID: employeeID,
      companyID: COMPANY_ID,
    },
  })

  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false)
  const [cancelIsOpen, setCancelIsOpen] = React.useState(false)

  const [nominal, maskedNominal, handleNominalInput] = useNumericInput(0)
  const [description, handleDescriptionInput] = useInputState('')

  // Confirmation popup
  const openConfirmation = () => {
    setConfirmIsOpen(true)
  }

  const handleConfirmationAccept = () => {
    setConfirmIsOpen(false)

    const variables = {
      userWalletIDs: [data.employee.user.wallet.id],
      nominal,
      description,
    }

    dispatchTopup({variables}).then(
      ({data}) => {
        const message = data.result.message
        const success = !data.result.total_failed_topups

        enqueueSnackbar(message, {variant: success ? 'success' : 'error'})

        if (success) {
          props.history.push({
            pathname: '/budget/limit/employee',
            state: {active: 'employee', searchable: true},
          })
        }
      },
      () => {
        enqueueSnackbar('Balance failed to top up', {variant: 'error'})
      }
    )
  }

  const handleConfirmationCancel = () => {
    setConfirmIsOpen(false)
  }

  /// Cancellation popup
  const openCancellation = () => {
    setCancelIsOpen(true)
  }

  const handleCancellationAccept = () => {
    props.history.goBack()
  }

  const handleCancellationClose = () => {
    setCancelIsOpen(false)
  }

  // Balance
  const companyDeposit = data?.company_in.aggregate.sum.nominal || 0
  const companyWithdraw = data?.company_out.aggregate.sum.nominal || 0
  const companyBalance = companyDeposit - companyWithdraw
  const remainingCompanyBalance = companyBalance - (nominal || 0)

  const nominalEmpty = !nominal || nominal <= 0
  const nominalOverBalance = remainingCompanyBalance < 0

  const formattedRemainingCompanyBalance = React.useMemo(
    () => convertToRupiah(remainingCompanyBalance, false),
    [remainingCompanyBalance]
  )

  return (
    <PaperContainer>
      <FormToolbar>
        <FormTitleContainer>
          <IconButton
            disabled={dispatching}
            onClick={openCancellation}
            aria-label="Go back to previous page"
            edge="start"
            className={styles.backButton}
          >
            <Icon>arrow_back</Icon>
          </IconButton>

          <Typography variant="subtitle1" style={{fontWeight: '600'}}>
            Top Up Employee
          </Typography>
        </FormTitleContainer>

        {(!loading || !error) && (
          <FormTitleContainer>
            <Button
              disabled={dispatching}
              onClick={openCancellation}
              variant="outlined"
              color="primary"
              size="large"
              style={{marginLeft: 16}}
            >
              Cancel
            </Button>
            <Button
              disabled={dispatching || nominalEmpty || nominalOverBalance}
              onClick={openConfirmation}
              variant="contained"
              color="primary"
              size="large"
              style={{marginLeft: 16}}
            >
              Submit
            </Button>
          </FormTitleContainer>
        )}
      </FormToolbar>

      <Divider />

      {!loading && error ? (
        <FormContainer>{JSON.stringify(error)}</FormContainer>
      ) : (
        <FormContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>1.</TitleNumber>
              <TitleName>Assign to*</TitleName>
            </FormChildTitle>
            <FormChildInput>
              {loading ? (
                <Box py={2.25}>
                  <CircularProgress />
                </Box>
              ) : (
                <EmployeeSummary data={data.employee} />
              )}
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>2.</TitleNumber>
              <TitleName>Nominal*</TitleName>
            </FormChildTitle>
            <FormChildInput className={styles.nominalRoot}>
              <CurrencyInput
                required
                error={nominalOverBalance}
                disabled={dispatching}
                value={maskedNominal}
                onChange={handleNominalInput}
                className={styles.input}
              />
              {nominalOverBalance && (
                <Typography className={styles.labelError}>
                  Your company balance is not enough
                </Typography>
              )}
              <table className={styles.paymentInfo}>
                <tr>
                  <th className={styles.paymentInfoHeader}>
                    Remaining balance
                  </th>
                  <td
                    className={clsx(
                      styles.paymentInfoData,
                      nominalOverBalance && styles.labelError
                    )}
                  >
                    {loading ? '-' : formattedRemainingCompanyBalance}
                  </td>
                </tr>
              </table>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Description</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <OutlinedInput
                disabled={dispatching}
                value={description}
                onChange={handleDescriptionInput}
                className={styles.input}
                placeholder="Add top up description"
                multiline
              />
            </FormChildInput>
          </FormChildContainer>
        </FormContainer>
      )}

      <AlertPopup
        open={confirmIsOpen}
        mutation={handleConfirmationAccept}
        handleClose={handleConfirmationCancel}
        feature="Top Up Employees?"
        message="Are you sure you want to top up to selected employees?"
        type="Confirm"
        status="cancel"
      />

      <AlertPopup
        open={cancelIsOpen}
        mutation={handleCancellationAccept}
        handleClose={handleCancellationClose}
        feature="Discard Changes?"
        message="Are you sure you want to discard changes this top up?"
        type="Discard"
        status="cancel"
      />
    </PaperContainer>
  )
}

export default withRouter(EmployeeIndividualTopup)

function EmployeeSummary(props) {
  const {data} = props

  const styles = useStyles()

  return (
    <Box display="flex" alignItems="center" pt={1}>
      <Avatar
        src={data.user.avatar}
        alt={data.user.name}
        className={styles.employeeAvatar}
      />

      <Box display="flex" flexDirection="column">
        <Typography className={styles.employeeName}>
          {data.user.name}
        </Typography>
        <Typography className={styles.employeeRegistration}>
          {data.regno}
        </Typography>
        <Typography className={styles.employeePosition}>
          {data.profile.position}
        </Typography>
      </Box>
    </Box>
  )
}
