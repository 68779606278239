import React from 'react'
import {withRouter} from 'react-router-dom'
import {Icon, IconButton, Typography} from '@material-ui/core'

import LoanInformation from './edit/LoanInformation'
import LoanTab from './edit/LoanTab'

import {
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
} from '../../../../GlobalStyles'

const EditLoanActivity = props => {
  const [refetchLoanInformation, setRefetchLoanInformation] = React.useState(
    false
  )

  return (
    <PaperContainer>
      <FormToolbar>
        <FormTitleContainer>
          <IconButton
            onClick={() => {
              props.history.push({
                pathname: '/financial/loan/activity',
                state: {active: 'activity', searchable: true},
              })
            }}
            aria-label="Go back to previous page"
            edge="start"
          >
            <Icon>arrow_back</Icon>
          </IconButton>

          <Typography variant="subtitle1" style={{fontWeight: '600'}}>
            Edit Loan {props.title}
          </Typography>
        </FormTitleContainer>
      </FormToolbar>

      <LoanInformation
        loanId={props.state.loanId}
        refetchLoanInformation={refetchLoanInformation}
      />

      <LoanTab
        loanId={props.state.loanId}
        activeTab={props.state.activeTab}
        setRefetchLoanInformation={setRefetchLoanInformation}
      />
    </PaperContainer>
  )
}

export default withRouter(EditLoanActivity)
