import React, {useState, useEffect, useContext} from 'react'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  WrapperContent,
  FlexBetween,
  TextDarkBlue,
  TextBlackBold,
  LitleGreyText,
  WhiteButton,
  TextGreyBold,
} from '../OfficePageStyles'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  RootContainer,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import {
  Button,
  Icon,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import MaterialTable from 'material-table'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import classNames from 'classnames'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {disbaledButtonExport} from '../../../../utils/helpers'
import {
  GET_MAIN_OFFICE,
  GET_LIST_OFFICE,
  GET_BALOON_STATUS_BUTTON,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../../../graphql/queries'
import {
  SET_MAIN_OFFICE,
  DELETE_LIST_OFFICE,
  SET_ACTIVE_INACTIVE_OFFICE,
  IMPORT_DATA,
  CLOSE_BALOON,
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
} from '../../../../graphql/mutations'
import {COMPANY_ID} from '../../../../utils/globals'
import {useSnackbar} from 'notistack'
import {DataContext} from '../../../../ContextConfig'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import {TableOptions} from '../../../shared-components/table/TableOptions'

import DeletePopup from '../../../shared-components/popup/DeletePopup'
import AlertPopup from '../../../shared-components/popup/ResultPopup'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import ImportBaloon from '../../../../components/importExcel/ImportBaloon'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'

const OfficeListComponent = ({searchText}) => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()

  const [openMainOffice, setOpenMainOffice] = useState(false)
  const [openActiveOffice, setOpenActiveOffice] = useState(false)
  const [openDeleteOffice, setOpenDeleteOffice] = useState(false)
  const [openDeleteFailJob, setOpenDeleteFailJob] = useState(false)
  const [openDeleteFailMain, setOpenDeleteFailMain] = useState(false)
  const [openMainFailInactive, setOpenMainFailInactive] = useState(false)
  const [openActiveFailMain, setOpenActiveFailMain] = useState(false)

  const [dataOffice, setDataOffice] = useState('')
  const [active, setActive] = useState('Active')

  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )

  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  const {data: getExportBaloon, error: errorGetExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_addresses',
      },
    }
  )

  const {data: getExportDisabled, error: errorGetExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_addresses',
      },
    }
  )

  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )

  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    refetch: refetchBaloonStatus,
    // loading: loadingGetBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'company_addresses',
    },
  })

  const [openImport, setImport] = useState(false)
  const [openExport, setExport] = useState(false)
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 20,
  })

  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')

  const {dataContext, setDataContext} = useContext(DataContext)

  const {
    data: mainData,
    error: mainError,
    loading: loadingMain,
    refetch: refetchMainOffice,
  } = useQuery(GET_MAIN_OFFICE, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  const {
    data: listData,
    error: listError,
    loading: loadingList,
    refetch,
  } = useQuery(GET_LIST_OFFICE, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      company: COMPANY_ID,
      search: `%${searchText}%`,
      offset: pagination.page * pagination.limit,
      limit: pagination.limit,
    },
  })

  const [setMainOffice] = useMutation(SET_MAIN_OFFICE)
  const [setActiveInactiveOffice] = useMutation(SET_ACTIVE_INACTIVE_OFFICE)
  const [deleteListOffice] = useMutation(DELETE_LIST_OFFICE)

  const listing = React.useMemo(() => {
    const addresses = listData?.company_addresses || []

    if (searchText || !mainData) {
      return addresses
    }

    const main = mainData.company_profiles[0].company_address
    const mainId = main.id

    return [main, ...addresses.filter(item => item.id !== mainId)]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainData, listData])

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      setDataContext(null)
    }, 500)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [dataContext])

  if (loadingMain || (listData === undefined && listError === undefined)) {
    return <CircularProgress />
  } else if (
    mainError !== undefined ||
    listError !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationExportError !== undefined ||
    closeBaloonExportError !== undefined ||
    errorGetExportBaloon !== undefined ||
    errorGetExportDisabled !== undefined ||
    mutationImportError !== undefined
  ) {
    return (
      <div>
        {JSON.stringify(mainError)}
        {JSON.stringify(listError)}
      </div>
    )
  }

  const dataMainOffice = mainData.company_profiles

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      page: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      page: 0,
      limit: row,
    })
  }

  const handleDelete = () => {
    const variable = {
      id: dataOffice.id,
    }

    deleteListOffice({variables: variable})
      .then(() => {
        enqueueSnackbar('Office deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })

        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete Office failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleSetActiveInactiveOffice = () => {
    const status = dataOffice.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    const variable = {
      id: dataOffice.id,
      status: status,
    }
    setActiveInactiveOffice({variables: variable})
      .then(() => {
        enqueueSnackbar(`Set ${status} Office success`, {
          variant: 'success',
          autoHideDuration: 1000,
        })

        refetch()
      })
      .catch(() => {
        enqueueSnackbar(`Set ${status} Office failed, please try again later`, {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })

    setOpenActiveOffice(false)
  }

  const handleSetMainOffice = () => {
    const variable = {
      address: dataOffice.id,
      company: COMPANY_ID,
    }
    setMainOffice({variables: variable})
      .then(() => {
        enqueueSnackbar('Set Main Office success', {
          variant: 'success',
          autoHideDuration: 1000,
        })

        refetchMainOffice()
      })
      .catch(() => {
        enqueueSnackbar('Set Main Office failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })

        refetchMainOffice()
      })

    setOpenMainOffice(false)
  }

  const handleOpenAlert = (dataOffices, type) => {
    const hasJobProfile =
      dataOffices &&
      dataOffices.company_job_profiles_aggregate &&
      dataOffices.company_job_profiles_aggregate.aggregate &&
      dataOffices.company_job_profiles_aggregate.aggregate.count > 0

    const isMainOffice = dataMainOffice[0].company_address.id === dataOffices.id
    const isInactive = dataOffices.status === 'INACTIVE'

    if (type === 'setMain') {
      if (isInactive) {
        setOpenMainFailInactive(true)
      } else {
        setOpenMainOffice(true)
      }
    } else if (type === 'delete') {
      if (isMainOffice) {
        setOpenDeleteFailMain(true)
      } else if (hasJobProfile) {
        setOpenDeleteFailJob(true)
      } else {
        setOpenDeleteOffice(true)
      }
    } else if (type === 'activeInactive') {
      if (!isInactive && isMainOffice) {
        setOpenActiveFailMain(true)
      } else {
        setOpenActiveOffice(true)
      }
    }

    setDataOffice(dataOffices)
  }

  const importMutation = async () => {
    try {
      await mutationImport({
        variables: {
          table: 'company_addresses',
          file: fileURL,
        },
      })
      refetchBaloonStatus()
      refetch()
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error',
        autoHideDuration: 1000,
      })
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error',
        autoHideDuration: 1000,
      })
    }
  }

  const handleClickOpenImport = () => {
    setImport(true)
  }
  const handleCloseImport = () => {
    setImport(false)
  }
  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }
  const handleDownload = () => {
    setExport(!openExport)
    if (openExport) {
      mutationExport({
        variables: {
          table: 'company_addresses',
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  // const defaultExport = [
  //   {
  //     'Office ID': '',
  //     'Office Name': '',
  //     Province: '',
  //     'City/District': '',
  //     'Postal Code': '',
  //     Address: '',
  //     Status: '',
  //     'Total Employee': '',
  //   },
  // ]

  // const dataForExport = list.company_addresses.map(res => {
  //   return {
  //     'Office ID': res.office_code ? res.office_code : '-',
  //     'Office Name': res.office_name ? res.office_name : '-',
  //     Province: res.global_province ? res.global_province.name : '-',
  //     'City/District': res.global_city ? res.global_city.name : '-',
  //     'Postal Code': res.postal_code ? res.postal_code : '-',
  //     Address: res.address ? res.address : '-',
  //     Status: res.status ? res.status : '-',
  //   }
  // })

  // const dataExport = dataForExport.length > 0 ? dataForExport : defaultExport

  const column = [
    {
      title: 'Office Code',
      field: 'office_code',
    },
    {
      title: 'Office Name',
      render: rowData => (
        <>
          <Typography>{rowData.office_name}</Typography>
          {rowData.id === dataMainOffice[0].company_address.id && (
            <Typography color="textSecondary" variant="subtitle2">
              (Main Office)
            </Typography>
          )}
        </>
      ),
      cellStyle: {
        width: '20%',
      },
    },
    {
      title: 'Province',
      field: 'global_province.name',
    },
    {
      title: 'City/District',
      field: 'global_city.name',
    },
    {
      title: 'Postal Code',
      field: 'postal_code',
    },
    {
      title: 'Address',
      field: 'address',
      cellStyle: {
        width: '20%',
      },
    },
    {
      title: 'Status',
      field: 'status',
      lookup: {ACTIVE: 'ACTIVE', INACTIVE: 'INACTIVE'},
      cellStyle: {
        width: '20%',
      },
      render: rowData => (
        <Typography
          style={
            rowData.status === 'ACTIVE' ? {color: 'green'} : {color: 'red'}
          }
        >
          {rowData.status}
        </Typography>
      ),
    },
    {
      title: 'Action',
      field: 'action',
      render: rowData => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: `/company/office/detail`,
                    state: {
                      active: 'detail-office',
                      dataOffice: rowData,
                      dataMain: dataMainOffice,
                      dataCheck: listData,
                    },
                  }}
                >
                  Details
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleOpenAlert(rowData, 'setMain')
                  }}
                >
                  Set Main Office
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    const actInc =
                      rowData.status === 'ACTIVE' ? 'Inactive' : 'Active'
                    popupState.close()
                    handleOpenAlert(rowData, 'activeInactive')
                    setActive(actInc)
                  }}
                >
                  {`Set ${rowData.status === 'ACTIVE' ? 'Inactive' : 'Active'}`}
                </StyledMenuItem>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: `/company/office/edit`,
                    state: {
                      active: 'edit-office',
                      dataOffice: rowData,
                      dataMain: dataMainOffice,
                      dataCheck: listData,
                    },
                  }}
                  variant="h6"
                >
                  Edit
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleOpenAlert(rowData, 'delete')
                  }}
                >
                  Delete
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>
      ),
    },
  ]

  return (
    <RootContainer>
      <ImportBaloon
        data={getBaloonStatus}
        closeBaloonMutation={closeBaloonMutation}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
        refetchBaloonStatus={refetchBaloonStatus}
      />
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
      />
      <OrganizationContainer className={classes.root}>
        <PaperContainer className={classes.overflowXNone}>
          <EnhancedToolbar>
            <BigTextBlackBold>Office</BigTextBlackBold>
            <WrapperContent>
              <GenerateTooltip
                title={
                  (getExportBaloon &&
                    getExportBaloon.company_export_data_logs &&
                    getExportBaloon.company_export_data_logs.length > 0 &&
                    getExportBaloon.company_export_data_logs[0].status ===
                      'FAILED') ||
                  disbaledButtonExport(
                    getExportDisabled &&
                      getExportDisabled.company_export_data_logs &&
                      getExportDisabled.company_export_data_logs[0] &&
                      getExportDisabled.company_export_data_logs[0].date_added
                  )
                    ? `you can only generate data once a day. to download data use the link that already generated`
                    : ``
                }
                placement="left"
              >
                <span>
                  <WhiteButton
                    variant="contained"
                    size="large"
                    style={{marginRight: 12}}
                    onClick={() => handleDownload()}
                    disabled={
                      (getExportBaloon &&
                        getExportBaloon.company_export_data_logs &&
                        getExportBaloon.company_export_data_logs.length > 0 &&
                        getExportBaloon.company_export_data_logs[0].status ===
                          'FAILED') ||
                      disbaledButtonExport(
                        getExportDisabled &&
                          getExportDisabled.company_export_data_logs &&
                          getExportDisabled.company_export_data_logs[0] &&
                          getExportDisabled.company_export_data_logs[0]
                            .date_added
                      )
                    }
                  >
                    <TextDarkBlue>Generate Data</TextDarkBlue>
                  </WhiteButton>
                </span>
              </GenerateTooltip>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{marginRight: 12}}
                onClick={() => handleClickOpenImport()}
                disabled={
                  getBaloonStatus?.company_import_data_logs.length > 0 &&
                  getBaloonStatus?.company_import_data_logs[0].status ===
                    'WAITING'
                }
              >
                {getBaloonStatus?.company_import_data_logs.length > 0 &&
                getBaloonStatus?.company_import_data_logs[0].status ===
                  'WAITING' ? (
                  <TextGreyBold>Importing on proces...</TextGreyBold>
                ) : (
                  <>Import Office</>
                )}
              </Button>
              <Button
                className={classes.marginLeft10}
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to={{
                  pathname: `/company/office/add`,
                  state: {
                    active: 'add-office',
                    status: 'Add',
                    dataCheck: listData,
                  },
                }}
              >
                Add New Office
              </Button>
            </WrapperContent>
          </EnhancedToolbar>
          <Divider />
          <OrganizationContainer className={classes.paddingX15}>
            <PaperContainer
              className={classNames(
                classes.width35P,
                classes.marginB20,
                classes.height125,
                classes.padding20
              )}
            >
              <FlexBetween>
                <Icon className={classes.iconOffice}>location_city</Icon>
                <OrganizationContainer className={classes.marginLeft10}>
                  <FlexBetween>
                    <TextDarkBlue>Main Office</TextDarkBlue>
                  </FlexBetween>
                  <TextBlackBold>
                    {dataMainOffice[0].company_address &&
                      dataMainOffice[0].company_address.office_name}
                  </TextBlackBold>
                  <LitleGreyText>
                    {dataMainOffice[0].company_address &&
                      dataMainOffice[0].company_address.address}
                  </LitleGreyText>
                </OrganizationContainer>
              </FlexBetween>
            </PaperContainer>
          </OrganizationContainer>
          <MaterialTable
            options={{
              ...TableOptions,
              selection: false,
              showTitle: false,
              toolbar: false,
              emptyRowsWhenPaging: false,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: <NoDataListComponent message={``} />,
              },
            }}
            columns={column}
            isLoading={loadingList}
            data={listing}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            totalCount={listData.total.aggregate.count}
            page={pagination.page}
            pageSize={pagination.limit}
          />
          <DeletePopup
            open={openDeleteOffice}
            handleClose={() => setOpenDeleteOffice(false)}
            name={dataOffice.office_name}
            mutation={() => handleDelete()}
          />
          <AlertPopup
            open={openMainOffice}
            handleClose={() => setOpenMainOffice(false)}
            feature={`Select Main Office`}
            name={dataOffice.office_name}
            fMessage={`Are you sure want to set `}
            lMessage={` be main Office ?`}
            type="Confirm"
            status="cancel"
            mutation={handleSetMainOffice}
          />
          <AlertPopup
            open={openActiveOffice}
            handleClose={() => setOpenActiveOffice(false)}
            feature={`Set Office ${active}`}
            message={
              active === 'Active'
                ? 'Are you sure you want to set office status become active? If you set office status become active it will be seen on user although it will not change functional the office on job profile and placement'
                : 'Are you sure you want to set office status become inactive? If you set office status become inactive it will not be seen on user although it will not change the functional office on job profile and placement'
            }
            type="Confirm"
            status="cancel"
            mutation={handleSetActiveInactiveOffice}
          />
          <AlertPopup
            open={openDeleteFailJob}
            handleClose={() => setOpenDeleteFailJob(false)}
            feature={`Unable to Delete ${dataOffice.office_name}`}
            name={dataOffice.office_name}
            fMessage="You cannot delete "
            lMessage=" because it is being used on job profile."
            type="Confirm"
          />
          <AlertPopup
            open={openDeleteFailMain}
            handleClose={() => setOpenDeleteFailMain(false)}
            feature={`Unable to Delete ${dataOffice.office_name}`}
            name={dataOffice.office_name}
            fMessage="You cannot delete "
            lMessage=" because this office is the main office in this company."
            type="Confirm"
          />
          <AlertPopup
            open={openMainFailInactive}
            handleClose={() => setOpenMainFailInactive(false)}
            feature="Cannot Set as Main Office"
            name={dataOffice.office_name}
            fMessage="Cannot set "
            lMessage=" as the main office. Please change office status to active first."
            type="Confirm"
          />
          <AlertPopup
            open={openActiveFailMain}
            handleClose={() => setOpenActiveFailMain(false)}
            feature="Cannot Change Status to Inactive"
            name={dataOffice.office_name}
            fMessage="Cannot Change Status "
            lMessage=" to Inactive because this office is the main office in this company."
            type="Confirm"
          />
          <ImportPopup
            open={openImport}
            position={'Office'}
            handleClose={handleCloseImport}
            mutation={importMutation}
            fileTemplate={
              'https://public-media.wlb.co.id/1dc6d763-8ad8-4e8a-8d26-5bb73dd569f2-1602486963025.xlsx'
            }
            isAlreadyImported={isAlreadyImported}
            setIsAlreadyImported={setIsAlreadyImported}
            setImport={setImport}
            fileURL={fileURL}
            setFileURL={setFileURL}
            closeBaloon={closeBaloonMutation}
            errorsImport={
              getBaloonStatus.company_import_data_logs.length > 0 &&
              getBaloonStatus.company_import_data_logs[0].reports &&
              getBaloonStatus.company_import_data_logs[0].reports.failed
                .length > 0
                ? getBaloonStatus.company_import_data_logs[0].reports.failed
                : []
            }
          />
          <AlertPopup
            open={openExport}
            handleClose={handleDownload}
            feature="Download Data is Processing!"
            message="Download Data is processing! We’ll send you an email when it’s ready."
            type="Confirm"
          />
        </PaperContainer>
      </OrganizationContainer>
    </RootContainer>
  )
}

export default OfficeListComponent
