import React, {useState} from 'react'
import {
  Divider,
  TextField,
  Typography,
  Dialog,
  Button,
  Icon,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import GoogleMap from '../../../../components/google-map'
import moment from 'moment'
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {UPDATE_ATTENDANCE} from '../../../../graphql/mutations'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {GET_USER_BY_ID} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {getDay, getGMT} from '../attendance-helper'
import {FlexCenters} from '../../../../GlobalStyles'
import DefaultAvatar from '../../../../assets/images/default-avatar.png'

export const AttendanceLocationDialog = ({
  isShow,
  latitude,
  longitude,
  location,
  photoUrl,
  onClose,
}) => {
  return (
    <Dialog open={isShow} onClose={onClose}>
      <GoogleMap
        latitude={latitude}
        longitude={longitude}
        photoURL={photoUrl}
        location={location}
        onClose={onClose}
      />
    </Dialog>
  )
}

export const AttendanceEditActivity = props => {
  const {attendanceData} = props

  const {data: dataProfile} = useQuery(GET_USER_BY_ID, {
    variables: {
      id: USER_ID,
    },
  })

  const profile = dataProfile && dataProfile.global_users[0]

  // let today = moment().format('DD')
  // console.log(parseInt(today));
  // const regulationCutOff = regulation ? regulation.cut_off_date : 0
  // const isCutOff = parseInt(today) > regulationCutOff
  const isCutOff = false

  const {enqueueSnackbar} = useSnackbar()
  const [startDate, setStartDate] = useState(
    attendanceData && attendanceData.clock_in
  )
  const [endDate, setEndDate] = useState(
    attendanceData && attendanceData.clock_out
  )
  const [description, setDescription] = useState(
    attendanceData && attendanceData.description
  )
  const [lateMinutes, setLateMinutes] = useState(
    attendanceData && attendanceData.late_minutes
  )
  const [fields, setFields] = useState(
    attendanceData & attendanceData.attendance_fields
  )
  const [policy, setPolicy] = useState(
    attendanceData && attendanceData.time_attendance_policy
  )

  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')

  const [updateUserAttendance] = useMutation(UPDATE_ATTENDANCE)

  // if (isCutOff) {
  //   enqueueSnackbar('Edit not allowed due cut-off date', {
  //     variant: 'warning',
  //   })
  // }

  const handleClose = () => {
    setPopup(false)
  }

  const handleClickOpen = type => {
    setConfirmType(type)
    setPopup(true)
  }

  const handleDateChange = (date, type) => {
    // console.log(date)
    if (type === 'start') {
      setStartDate(date)
    } else {
      setEndDate(date)
    }
  }

  const updateAttendance = () => {
    const fields = (attendanceData && attendanceData.attendance_fields) || {}
    if (!fields.histories) {
      fields.histories = []
    }

    const history = {
      last_modified: attendanceData.last_modified,
      clock_in: attendanceData.clock_in,
      clock_out: attendanceData.clock_out,
      late_minutes: attendanceData.late_minutes,
      description: attendanceData.description,
      edited_by: {
        id: profile.id,
        email: profile.email,
        name: profile.name,
      },
    }

    fields.histories = [history, ...fields.histories]

    const variables = {
      id: attendanceData && attendanceData.id,
      clockIn: startDate || attendanceData.clock_in,
      clockOut: endDate || attendanceData.clock_out,
      description: description || attendanceData.description,
      attendanceFields: fields,
      lateMinutes: lateMinutes || attendanceData.late_minutes,
    }

    // console.log(variables)

    updateUserAttendance({variables})
      .then(() => {
        // console.log(data)
        // enqueueSnackbar('Success Update data', {variant: 'success'})
        props.onClose(true)
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
        })
      })
  }

  return (
    <Dialog open={props.isShow} onClose={() => props.onClose(false)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={{width: 400, backgroundColor: '#fff'}}>
          <div
            style={{
              padding: 35,
              backgroundColor: '#014a62',
              fontWeight: 600,
              fontSize: 24,
              color: '#fff',
            }}
          >
            {moment(attendanceData.date_added).format('LL')}
          </div>
          <div style={{padding: 20}}>
            <Typography style={{color: '#a9a8a8'}}>Work Schedule</Typography>
            <TextField
              id="work-schedule"
              type="text"
              name="work-schedule"
              margin="normal"
              variant="outlined"
              value={
                attendanceData &&
                attendanceData.time_attendance_policy &&
                attendanceData.time_attendance_policy.name
              }
              fullWidth
              disabled
            />
            <Typography style={{color: '#a9a8a8'}}>Start</Typography>
            <DateTimePicker
              id="work-start"
              margin="normal"
              name="work-start"
              fullWidth
              variant="outlined"
              value={startDate || (attendanceData && attendanceData.clock_in)}
              maxDate={startDate || (attendanceData && attendanceData.clock_in)}
              onChange={date => {
                handleDateChange(date, 'start')
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              ampm={false}
              disabled={isCutOff}
            />
            <Typography style={{color: '#a9a8a8'}}>End</Typography>
            <DateTimePicker
              id="work-end"
              margin="normal"
              name="work-end"
              fullWidth
              variant="outlined"
              value={endDate || (attendanceData && attendanceData.clock_out)}
              onChange={date => {
                handleDateChange(date, 'end')
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              ampm={false}
              disabled={isCutOff}
            />
            <Typography style={{color: '#a9a8a8'}}>Description</Typography>
            <TextField
              id="work-schedule"
              type="text"
              name="work-schedule"
              margin="normal"
              variant="outlined"
              rows="3"
              value={description}
              onChange={event => {
                setDescription(event.target.value)
              }}
              multiline
              fullWidth
              disabled={isCutOff}
            />
          </div>
          <div
            style={{
              padding: 15,
              backgroundColor: '#fff',
              fontSize: 12,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button
              // variant="contained"
              onClick={() => {
                setStartDate(attendanceData.clock_in)
                setEndDate(attendanceData.clock_out)
                setDescription(attendanceData.description)
                setLateMinutes(attendanceData.late_minutes)
                setFields(fields)
                setPolicy(policy)
                // props.onClose()
                handleClickOpen('discard')
              }}
              style={{color: '#a9a8a8', marginRight: 20}}
            >
              Cancel
            </Button>

            {isCutOff === false && (
              <Button
                variant="contained"
                color="primary"
                // onClick={handleClickpen}
                onClick={() => handleClickOpen('edit')}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </MuiPickersUtilsProvider>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        feature="Attendance Activity"
        mutation={
          confirmType === 'discard'
            ? () => {
                handleClose()
                props.onClose()
              }
            : updateAttendance
        }
      />
    </Dialog>
  )
}

export const AttendanceActivityDetailActivity = props => {
  const {peopleData, attendanceData} = props

  const policy = attendanceData?.time_attendance_policy
  const isFlex = policy?.flexi === 1

  const clockIn = moment(attendanceData?.clock_in).format('dddd, Do MMM YYYY')

  return (
    <Dialog open={props.isShow} onClose={props.closeDialog} fullWidth>
      <DialogTitle
        disableTypography
        style={{
          background: '#003d51',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography display="inline" style={{fontWeight: 600}}>
          Attendance Detail - {clockIn}
        </Typography>

        <IconButton edge="end" color="inherit" onClick={props.closeDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div
          style={{
            backgroundColor: '#eff2f4',
            padding: 24,
            margin: `-8px -24px 0`,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography style={{fontWeight: 600}} variant="body1" gutterBottom>
              Check - In Picture
            </Typography>

            <img
              src={attendanceData?.photo || DefaultAvatar}
              width="175"
              height="175"
              style={{objectFit: 'cover'}}
              alt={'Check in picture'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography style={{fontWeight: 600}} variant="body1" gutterBottom>
              Profile Picture
            </Typography>
            <img
              src={peopleData && peopleData.global_user.avatar}
              width="175"
              height="175"
            />
          </div>
        </div>

        {policy?.id && (
          <div style={{padding: `16px 0`}}>
            <Typography color="primary" style={{fontWeight: 600}}>
              Work Schedule
            </Typography>

            <div style={{display: 'flex', marginTop: 10}}>
              <Typography style={{minWidth: 87}} variant="body1" gutterBottom>
                Type
              </Typography>
              <Typography style={{color: '#a9a8a8', marginLeft: 50}}>
                {policy?.name}
              </Typography>
            </div>

            <Typography
              style={{color: '#a9a8a8', marginLeft: 134, marginTop: 10}}
            >
              {attendanceData && (getGMT(attendanceData) || '-')}
            </Typography>
            <div style={{display: 'flex', marginTop: 10}}>
              <Typography variant="body1" gutterBottom>
                Office Hour
              </Typography>
              <Typography style={{color: '#a9a8a8', marginLeft: 50}}>
                {isFlex
                  ? 'Flexible'
                  : attendanceData && attendanceData.time_attendance_policy
                  ? `${getDay(attendanceData)}, ${attendanceData
                      .time_attendance_policy.schedule &&
                      attendanceData.time_attendance_policy.schedule[
                        getDay(attendanceData).toLowerCase()
                      ].clockIn} - ${attendanceData.time_attendance_policy
                      .schedule &&
                      attendanceData.time_attendance_policy.schedule[
                        getDay(attendanceData).toLowerCase()
                      ].clockOut}`
                  : '-'}
              </Typography>
            </div>

            <div style={{padding: `16px 0`}}>
              <Typography variant="body1" gutterBottom>
                Grace Time
              </Typography>
              <Typography style={{color: '#a9a8a8', marginLeft: 50}}>
                {isFlex
                  ? '-'
                  : attendanceData && attendanceData.time_attendance_policy
                  ? `${attendanceData.time_attendance_policy.late_tolerance ||
                      0} Minutes`
                  : '-'}
              </Typography>
            </div>

            <div style={{display: 'flex', marginTop: 10}}>
              <Typography style={{minWidth: 87}} variant="body1" gutterBottom>
                Location
              </Typography>{' '}
              <FlexCenters style={{color: '#039be5', marginLeft: 50}}>
                <Icon>location_on</Icon>
                <Typography style={{marginLeft: 12}}>
                  {isFlex
                    ? 'Flexible'
                    : attendanceData.time_attendance_policy
                        .time_attendance_policy_locations[0]
                        .time_attendance_location.location.address || '-'}
                </Typography>
              </FlexCenters>
            </div>
          </div>
        )}

        {policy?.id && <Divider />}

        <div style={{padding: `16px 0`}}>
          <Typography style={{color: '#039be5', fontWeight: 600}}>
            Check - In
          </Typography>

          <div style={{display: 'flex', marginTop: 10}}>
            <Typography style={{minWidth: 87}} variant="body1" gutterBottom>
              Time
            </Typography>
            <Typography style={{color: '#a9a8a8', marginLeft: 50}}>
              {moment(attendanceData && attendanceData.clock_in).format('LLL')}
            </Typography>
          </div>

          <div style={{display: 'flex', marginTop: 10}}>
            <Typography style={{minWidth: 87}} variant="body1" gutterBottom>
              Time Zone
            </Typography>
            <Typography style={{color: '#a9a8a8', marginLeft: 50}}>
              {attendanceData && (getGMT(attendanceData) || '-')}
            </Typography>
          </div>

          {policy?.id && (
            <div style={{display: 'flex', marginTop: 10}}>
              <Typography style={{minWidth: 87}} variant="body1" gutterBottom>
                Location
              </Typography>
              <FlexCenters style={{color: '#039be5', marginLeft: 50}}>
                <Icon>location_on</Icon>
                <Typography style={{marginLeft: 12}}>
                  {attendanceData &&
                  attendanceData.location &&
                  attendanceData.location.address
                    ? attendanceData.location.address
                    : '-'}
                </Typography>
              </FlexCenters>
            </div>
          )}
        </div>

        {attendanceData && attendanceData.clock_out !== null && <Divider />}

        {attendanceData && attendanceData.clock_out !== null && (
          <div style={{padding: `16px 0`}}>
            <Typography style={{color: '#ffa000', fontWeight: 600}}>
              Check - Out
            </Typography>

            <div style={{display: 'flex', marginTop: 10}}>
              <Typography style={{minWidth: 87}} variant="body1" gutterBottom>
                Time
              </Typography>
              <Typography style={{color: '#a9a8a8', marginLeft: 50}}>
                {moment(attendanceData.clock_out).format('LLL')}
              </Typography>
            </div>

            <div style={{display: 'flex', marginTop: 10}}>
              <Typography style={{minWidth: 87}} variant="body1" gutterBottom>
                Time Zone
              </Typography>
              <Typography style={{color: '#a9a8a8', marginLeft: 50}}>
                {getGMT(attendanceData) || '-'}
              </Typography>
            </div>

            {policy?.id && (
              <div style={{display: 'flex', marginTop: 10}}>
                <Typography style={{minWidth: 87}} variant="body1" gutterBottom>
                  Location
                </Typography>
                <FlexCenters style={{color: '#039be5', marginLeft: 50}}>
                  <Icon>location_on</Icon>
                  <Typography style={{marginLeft: 12}}>
                    {attendanceData.attendance_fields?.checkout?.address || '-'}
                  </Typography>
                </FlexCenters>
              </div>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
