import gql from 'graphql-tag'
export const GET_DETAIL_EMP_INDIVIDUAL = gql`
  subscription getDetailIndividualPerformance($placement: Int) {
    performance_individuals(
      where: {placement: {_eq: $placement}}
      order_by: [{date_added: desc}]
    ) {
      id
      startdate
      enddate
      kpi_score
      kpi_weight
      multirater_score
      multirater_weight
      nps_score
      nps_weight
      ogf_score
      ogf_weight
      index_score
    }
  }
`
