import React, {useState} from 'react'
import {withRouter, Link} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {
  PaperContainer,
  GreyTypography,
  MediumAvatar,
} from '../../../../../../GlobalStyles'
import {
  Toolbar,
  IconButton,
  Typography,
  Divider,
  makeStyles,
  Button,
  Icon,
  TablePagination,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ModalDetailTalent from '../modal-detail/ModalDetailTalent'
import {GET_LIST_ADD_TALENT_POOL} from '../../../../../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import NoBadge from '../../../../../../assets/images/no-badge.svg'
import {DEFAULT_PAGINATION} from '../../../../../../utils/globals'
import {Loader} from '../../../LoaderSuccession'
import {DELETE_TALENT_POOL} from '../../../../../../graphql/mutations/talent/succession/addTalentSuccession.mutation'

const useStyles = makeStyles(() => ({
  paper: {
    padding: '0 3rem 16px',
  },
  paperTop: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',

    borderBottom: '1px solid #e5e5e5',
    padding: '20px 0',
  },
  spacer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    width: '100%',
    alignItems: 'center',
  },
  flexTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  dividerLittle: {
    height: '20px !important',
    margin: ' 0 4px 0 7px !important',
  },
  delete: {
    borderColor: '#EF4D5E',
    color: '#EF4D5E',
  },
}))

const AddTalentPool = props => {
  const {enqueueSnackbar} = useSnackbar()
  const jobProfileId = props.match.params.id
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [dataTalent, setDataTalent] = useState({})
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGINATION[0])

  const [deleteTalentPool] = useMutation(DELETE_TALENT_POOL)

  const {data, error, loading, refetch} = useQuery(GET_LIST_ADD_TALENT_POOL, {
    fetchPolicy: 'network-only',
    variables: {
      search: `%${props.searchText}%`,
      job_profile: jobProfileId,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    },
  })

  const handleOpen = res => {
    setOpen(true)
    setDataTalent(res)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDeletePool = talent => {
    deleteTalentPool({
      variables: {
        id: talent?.talent_pool?.[0].id,
      },
    })
      .then(() => {
        refetch()
        enqueueSnackbar('Talent Pool deleted', {variant: 'success'})
      })
      .catch(() => {
        enqueueSnackbar('Failed to delete Talent Pool', {variant: 'error'})
      })
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  if (error) {
    console.error(error)
  }

  const total = data?.total.aggregate.count || 0

  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link
          to={{
            pathname: '/talent/succession/' + props.location.state.back,
            state: {active: 'detail-succession'},
          }}
        >
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <GreyTypography
          variant="h6"
          style={{marginLeft: '1rem', marginRight: 6}}
        >
          {`${props.location.state.pos.title} Succession Detail `}
        </GreyTypography>
        <Typography variant="h6">/ Add Talent Pool</Typography>
      </Toolbar>
      <Divider />
      <div className={classes.paper}>
        {loading &&
          Array.from({length: 6}, (_, idx) => <Loader key={`${_}${idx}`} />)}
        {!loading &&
          data?.people_work_placements?.map((res, i) => {
            const {talent_box, talent_score} =
              res.global_user?.talent_assignments?.[0] || {}

            return (
              <div className={classes.paperTop} key={i}>
                <MediumAvatar
                  src={res.global_user.avatar}
                  alt={res.global_user.name}
                />
                <div className={classes.spacer}>
                  <div>
                    <div className={classes.flexTitle}>
                      <Typography variant="body1" style={{marginRight: 8}}>
                        {res.global_user.name}
                      </Typography>
                      <GreyTypography variant="body2">
                        {res.company_job_profile.title}
                      </GreyTypography>
                    </div>
                    <GreyTypography variant="body2" gutterBottom>
                      {res.placement_fields?.talent_information
                        .reason_for_leaving || '-'}
                    </GreyTypography>
                    {talent_box ? (
                      <div className={classes.flexTitle}>
                        <img
                          src={talent_box?.badge}
                          alt={talent_box?.name}
                          width="20"
                          style={{marginRight: 6}}
                        />
                        <GreyTypography variant="caption">
                          {talent_box?.name}
                        </GreyTypography>
                        <Divider
                          orientation="vertical"
                          className={classes.dividerLittle}
                        ></Divider>
                        <Icon
                          style={{
                            fontSize: 20,
                            color: '#4caf50',
                            marginRight: 4,
                          }}
                        >
                          star
                        </Icon>
                        <GreyTypography variant="caption">
                          {talent_score}
                        </GreyTypography>
                      </div>
                    ) : (
                      <div className={classes.flexTitle}>
                        <img
                          src={NoBadge}
                          alt="no-badge"
                          width="20"
                          style={{marginRight: 6}}
                        />
                        <GreyTypography variant="caption">
                          Tidak Ada Lencana
                        </GreyTypography>
                        <Divider
                          orientation="vertical"
                          className={classes.dividerLittle}
                        ></Divider>
                        <Icon
                          style={{
                            fontSize: 20,
                            color: '#4caf50',
                            marginRight: 4,
                          }}
                        >
                          star
                        </Icon>
                        <GreyTypography variant="caption">-</GreyTypography>
                      </div>
                    )}
                  </div>
                  {res.talent_pool_aggregate.aggregate.count > 0 ? (
                    <Button
                      variant="outlined"
                      className={classes.delete}
                      onClick={() => handleDeletePool(res)}
                    >
                      - Hapus
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpen(res)}
                    >
                      + Tambah
                    </Button>
                  )}
                </div>
              </div>
            )
          })}
        <TablePagination
          rowsPerPageOptions={DEFAULT_PAGINATION}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="baris per halaman"
          backIconButtonText="halaman sebelumnya"
          nextIconButtonText="halaman berikutnya"
          labelDisplayedRows={({from, to, count}) =>
            `${from} - ${to} dari ${count}`
          }
        />
      </div>

      <ModalDetailTalent
        open={open}
        handleClose={handleClose}
        placementId={dataTalent?.id}
        userId={dataTalent?.global_user?.id}
        pool
        refetch={refetch}
        idJob={jobProfileId}
      />
    </PaperContainer>
  )
}

export default withRouter(AddTalentPool)
