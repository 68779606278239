import React, {useState} from 'react'
import {
  PaperContainer,
  EnhancedToolbar,
  StyledMenus,
  MoreIcon,
  StyledMenuItem,
} from '../../../../GlobalStyles'
import {
  Typography,
  Button,
  Divider,
  Checkbox,
  TablePagination,
} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {GET_LIST_RESPONDENTS_GROUP} from '../../../../graphql/queries/survey/multirater-nps/getListRespondentsGroup'
import {COMPANY_ID} from '../../../../utils/globals'
import {DELETE_RESPONDENTS_GROUP} from '../../../../graphql/mutations/survey/multirater-nps/respondentsGroup.mutation'
import {useSnackbar} from 'notistack'
import {makeStyles} from '@material-ui/core/styles'
import PopupNomor from './PopupNomor'

const useStyles = makeStyles(() => ({
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px',
    alignContent: 'center',
  },
  number: {
    width: 60,
    fontFamily: 'muli',
    textAlign: 'center',
    margin: 'auto 0px',
  },
  groupName: {fontFamily: 'muli', marginLeft: 30, margin: 'auto 0px'},
  action: {
    margin: 'auto 0px',
    marginLeft: 'auto',
    marginRight: '10px',
    fontFamily: 'muli',
    width: 50,
  },
  rowPage: {
    padding: '20px 20px 20px 0px',
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
}))

const EnchancedHeader = props => {
  const classes = useStyles()
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    selected,
    setSelected,
    dataSelected,
    setDataSelected,
  } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '15px 20px 15px 0px',
      }}
    >
      <Checkbox
        color="primary"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{'aria-label': 'select all desserts'}}
      />
      <div className={classes.number}>
        {numSelected < 1 ? (
          'No'
        ) : (
          <PopupNomor
            dataSelected={dataSelected}
            setSelected={setSelected}
            selected={selected}
            setDataSelected={setDataSelected}
          />
        )}
      </div>
      <Typography className={classes.groupName}>Group Name</Typography>
      <Typography className={classes.action}>Action</Typography>
    </div>
  )
}

const RespondentsGroup = () => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [selected, setSelected] = useState([])
  const [dataSelected, setDataSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [openDelete, setOpenDelete] = useState(false)
  const [state, setState] = useState({
    id: '',
    name: '',
  })

  const handleClickOpen = (id, name) => {
    setState({id, name})
    setOpenDelete(true)
  }

  const handleClose = () => {
    setOpenDelete(false)
  }

  const {data} = useQuery(GET_LIST_RESPONDENTS_GROUP, {
    variables: {
      company: COMPANY_ID,
    },
  })

  const [mutationDelete] = useMutation(DELETE_RESPONDENTS_GROUP)

  const onDeleteRespondentsGroup = () => {
    const variables = {
      id: state.id,
    }

    mutationDelete({variables})
      .then(() => {
        enqueueSnackbar('Asesi Group deleted', {
          variant: 'success',
          autoHideDuration: 1500,
        })
      })
      .catch(() => {
        enqueueSnackbar('Delete scoring failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1500,
        })
      })
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds =
        data && data.multirater_respondent_groups.map(n => n.id)
      setSelected(newSelecteds)

      const AllData = data && data.multirater_respondent_groups.map(n => n)
      setDataSelected(AllData)
      return
    }
    setSelected([])
    setDataSelected([])
  }

  const handleClick = (event, id, name) => {
    event.preventDefault()
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    let nameSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
      nameSelected = nameSelected.concat(dataSelected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
      nameSelected = nameSelected.concat(dataSelected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
      nameSelected = nameSelected.concat(dataSelected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
      nameSelected = nameSelected.concat(
        dataSelected.slice(0, selectedIndex),
        dataSelected.slice(selectedIndex + 1)
      )
    }

    const insert = nameSelected.map(function(el) {
      const o = Object.assign({}, el)
      o.company = COMPANY_ID
      return o
    })

    const result = insert.map(res => ({
      company: res.company,
      name: res.name,
    }))

    setSelected(newSelected)
    setDataSelected(result)
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography style={{fontWeight: '600'}} variant="subtitle1">
          Asesi Group
        </Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: `/survey/multirater-nps/respondents-group/add`,
              state: {active: 'add-respondents-group'},
            }}
          >
            Add Group
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider></Divider>
      <div className={classes.tableWrapper}>
        <EnchancedHeader
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={data && data.multirater_respondent_groups.length}
          selected={selected}
          setSelected={setSelected}
          dataSelected={dataSelected}
          setDataSelected={setDataSelected}
        />
        {data &&
          data.multirater_respondent_groups
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.id)
              const labelId = `enhanced-table-checkbox-${index}`
              return (
                <div className={classes.rowPage} key={row.id}>
                  <Checkbox
                    onClick={event => handleClick(event, row.id, row)}
                    checked={isItemSelected}
                    inputProps={{'aria-labelledby': labelId}}
                    color="primary"
                  />
                  <Typography className={classes.number}>
                    {index + 1 + rowsPerPage * page}.
                  </Typography>
                  <Typography className={classes.groupName}>
                    {row.name}
                  </Typography>
                  <div className={classes.action}>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <>
                          <MoreIcon {...bindTrigger(popupState)}>
                            more_horiz
                          </MoreIcon>
                          <StyledMenus {...bindMenu(popupState)}>
                            <StyledMenuItem
                              component={Link}
                              to={{
                                pathname: `/survey/multirater-nps/respondents-group/edit/${row.id}`,
                                state: {
                                  active: 'edit-respondents-group',
                                },
                              }}
                              variant="h6"
                            >
                              Edit
                            </StyledMenuItem>
                            <StyledMenuItem
                              onClick={() => {
                                handleClickOpen(row.id, '')
                                popupState.close()
                              }}
                            >
                              Delete
                            </StyledMenuItem>
                          </StyledMenus>
                        </>
                      )}
                    </PopupState>
                  </div>
                </div>
              )
            })}
        <Divider></Divider>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data && data.multirater_respondent_groups.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        id={state.id}
        name={state.name}
        mutation={() => onDeleteRespondentsGroup()}
      />
    </PaperContainer>
  )
}

export default withRouter(RespondentsGroup)
