// import CKEditor from '@ckeditor/ckeditor5-react'
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import {useQuery} from '@apollo/react-hooks'
// eslint-disable-next-line no-unused-vars
import {makeStyles, MenuItem, Tooltip, Typography} from '@material-ui/core'
import React from 'react'
import {
  BlueIcon,
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
} from '../../../../../../GlobalStyles'
import {GET_LIST_SCALE_AND_BUTTONS} from '../../../../../../graphql/queries/survey/multirater-nps/survey-center/getSurveyDetail.query'
import {SelectStyled} from '../../SurveyCenterStyled'

// const editorConfiguration = {
//   toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
// }

const useStyles = makeStyles(() => ({
  tooltip: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: '#f7f8f9',
    color: '#000',
    width: 200,
    padding: 13,
  },
  arrow: {color: '#f7f8f9'},
  helpIcon: {
    fontSize: '20px !important',
    marginLeft: 10,
    cursor: 'pointer',
  },
  disabled: {background: '#f7f8f9'},
}))

const StepThree = props => {
  const classes = useStyles()
  const {dataContext, setDataContext, checked, isError} = props
  const {data} = useQuery(GET_LIST_SCALE_AND_BUTTONS)

  const handleChangeList = target => e => {
    setDataContext({
      ...dataContext,
      dataSurveyCenter: {
        ...dataContext.dataSurveyCenter,
        stepOne: {
          ...dataContext.dataSurveyCenter.stepOne,
          [target]: e.target.value,
        },
      },
    })
  }

  /* WLB-3168 hide field email notification */
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (isError) {
  //       const ids = document.getElementById('top')
  //       ids.scrollIntoView({behavior: 'smooth'})
  //     }
  //   }, 100)
  // }, [isError])

  if (dataContext === null || dataContext === undefined) {
    return <h1>Loading</h1>
  }

  return (
    <>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Skala Umpan Balik Multirater</TitleName>
          <Tooltip
            title="Bagian ini hanya dapat diisi jika tipe umpan balik yang dipilih pada langkah 1 adalah multirater"
            placement="right"
            arrow
            classes={{
              tooltipArrow: classes.tooltip,
              arrow: classes.arrow,
            }}
          >
            <BlueIcon className={classes.helpIcon}>help_outline</BlueIcon>
          </Tooltip>
        </FormChildTitle>
        <FormChildInput>
          <SelectStyled
            fullWidth
            variant="outlined"
            value={dataContext.dataSurveyCenter.stepOne.scale}
            onChange={handleChangeList('scale')}
            disabled={!checked.multirater}
            classes={{disabled: classes.disabled}}
          >
            {data &&
              data.global_multirater_scales.map(res => {
                return (
                  <MenuItem key={res.id} value={res.id}>
                    {res.name
                      .toString()
                      .split(',')
                      .join(' - ')}
                  </MenuItem>
                )
              })}
          </SelectStyled>
          {isError &&
            checked.multirater &&
            !dataContext.dataSurveyCenter.stepOne.scale && (
              <Typography variant="caption" color="error">
                Bagian ini diperlukan
              </Typography>
            )}
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Tombol Umpan Balik</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <SelectStyled
            style={{width: '75%'}}
            variant="outlined"
            value={dataContext.dataSurveyCenter.stepOne.buttons}
            onChange={handleChangeList('buttons')}
          >
            {data &&
              data.global_multirater_buttons.map(res => {
                return (
                  <MenuItem key={res.id} value={res.id}>
                    {res.name
                      .toString()
                      .split(',')
                      .join(' - ')}
                  </MenuItem>
                )
              })}
          </SelectStyled>
        </FormChildInput>
      </FormChildContainer>

      {/* WLB-3168 hide field email notification */}
      {/* <FormChildTitle2>
        <TitleNumber id="top">3.</TitleNumber>
        <TitleName>Feedback Completion Notification Email</TitleName>
      </FormChildTitle2>
      <FormChildInput>
        <CKEditor
          editor={DecoupledEditor}
          config={editorConfiguration}
          onInit={editor => {
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
              )
          }}
          data={dataContext.dataSurveyCenter.stepOne.data}
          onChange={(event, editor) => {
            const data = editor.getData(event)
            setDataContext({
              ...dataContext,
              dataSurveyCenter: {
                ...dataContext.dataSurveyCenter,
                stepOne: {
                  ...dataContext.dataSurveyCenter.stepOne,
                  data,
                },
              },
            })
          }}
        />

        <FooterEditor />
        {isError ? (
          <FormHelperText style={{color: '#ef4d5e', marginTop: 10}}>
            This field must not be empty
          </FormHelperText>
        ) : null}
      </FormChildInput> */}
    </>
  )
}

export default StepThree
