import gql from 'graphql-tag'

export const GET_COMPANY_PROFILE = gql`
  query($company: uuid) {
    company_profiles(where: {id: {_eq: $company}}) {
      id
      legal_name
      brand_name
      logo
      country_code
      phone
      email
      main_office: company_address {
        id
        office_name
        office_code
        status
        global_province {
          id
          name
        }
        global_city {
          id
          name
        }
        address
        address_field
        postal_code
        phone
      }
      profile_field
      types
      global_company_industry_type {
        id
        name
      }
      company_legal_type {
        id
        legal_type
        description
      }
    }
    employees: people_work_placements_aggregate(
      where: {
        status: {_eq: "ACTIVE"}
        global_user: {status: {_eq: "ACTIVE"}, people_profiles: {}}
        start_date: {_lte: "now()"}
        replacement_status: {_eq: false}
        global_placement_termination_id: {_is_null: true}
        company_job_profile: {}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_HOLDING_COMPANY = gql`
  query($company: uuid) {
    holding_relations(where: {company_member: {_eq: $company}}) {
      company_head_profile {
        id
        legal_name
        brand_name
        logo
      }
      holding_category {
        id
        name
      }
      holding_cluster {
        id
        name
      }
      holding_subcluster {
        id
        name
      }
    }
  }
`

export const GET_LIST_OFFICE_ADDRESS = gql`
  query($company: uuid, $offset: Int, $limit: Int) {
    list_office: company_addresses(
      where: {company: {_eq: $company}}
      order_by: [{id: asc}]
      offset: $offset
      limit: $limit
    ) {
      id
      office_name
      office_code
      status
      global_province {
        id
        name
      }
      global_city {
        id
        name
      }
      address
      address_field
      postal_code
      phone
    }
  }
`

export const GET_TOTAL_FOLLOWER_COMPANY = gql`
  query($companyId: String!) {
    getTotalFollowersCompany(company: $companyId) {
      total
      is_following
      is_followed
    }
  }
`

export const GET_LIST_HEADQUARTERS = gql`
  query($company: uuid) {
    company_addresses(
      where: {company: {_eq: $company}, status: {_eq: "ACTIVE"}}
      order_by: [{id: asc}]
    ) {
      id
      office_name
    }
  }
`

export const GET_INDUSTRY_TYPE = gql`
  {
    global_company_industry_types {
      id
      name
    }
  }
`

export const GET_LEGAL_TYPE = gql`
  query {
    company_legal_types(order_by: [{legal_type: asc}]) {
      id
      legal_type
      description
    }
  }
`
