import styled from 'styled-components'

export const BodyContent = styled.div`
  padding: 24px;

  .skeleton {
    margin-bottom: 24px;
    border-radius: 5px;
  }
`

export const BodyContentModalGenerate = styled.div`
  padding: 24px 21px;

  .label {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin: 0 0 11px 0;
  }

  .label-period {
    margin: 16px 0;
    font-weight: 600;
  }

  .textfield {
    .MuiInputBase-input {
      font-size: 12px;
    }
  }

  .period {
    display: flex;
    justify-content: space-between;

    .date_generate {
      width: 48%;

      .date-picker {
        margin-top: 0px;
        width: 100%;

        .MuiInputBase-input {
          font-size: 12px;
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .generate-btn {
      margin-left: 20px;
    }
  }
`

export const HistoryItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f7f8f9;
  margin-bottom: 24px;

  .info {
    .title {
      font-size: 14px;
      color: #000;
      margin: 0 0 12px 0;
    }
    .period {
      font-size: 14px;
      text-align: left;
      color: #014a62;
      margin: 0 0 12px 0;
    }
    .generate-date {
      font-size: 12px;
      color: #a9a8a8;
      margin: 0;
    }
  }

  .download-button {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    a {
      text-decoration: none;
      color: #039be5;
    }
  }
`
