import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
} from '../../../../GlobalStyles'
import {withRouter} from 'react-router-dom'
import {
  IconButton,
  Button,
  makeStyles,
  withStyles,
  Divider,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import FormAbout from './about/FormAbout'
import CategoryList from './category/CategoryList'
import ClusterList from './cluster/ClusterList'
import ClassList from './class/ClassList'
import {
  ADD_HOLDING_VERSION,
  UPDATE_HOLDING_VERSION,
} from '../../../../graphql/mutations'
import {useMutation, useQuery} from '@apollo/react-hooks'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {useSnackbar} from 'notistack'
import {holdingRolesContext} from '../../../../utils/helpers'
import {GET_HOLDING_VERSION_BY_ID} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'

const useStyles = makeStyles(theme => ({
  paper: {
    minHeight: '70vh',
  },

  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    color: '#039be5 !important',
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  labelActive: {
    color: '#039be5 !important',
  },
  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  mb16: {
    marginBottom: '16px !important',
  },
}))

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const getSteps = () => {
  return ['About', 'Category', 'Cluster', 'Class']
}

function FormVersionGroup(props) {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const idVersion = localStorage.getItem('idVersion')

  const steps = getSteps()

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <FormAbout
            values={values}
            handleChange={handleChange}
            isError={isError}
          />
        )
      case 1:
        return <CategoryList />
      case 2:
        return <ClusterList />
      case 3:
        return <ClassList />

      default:
        return 'Unknown stepIndex'
    }
  }

  const [activeStep, setActiveStep] = useState(0)
  const [values, setValues] = useState(
    JSON.parse(localStorage.getItem('values')) || {
      name: '',
      description: '',
      start_date: new Date(),
      end_date: new Date(),
      status: '',
    }
  )

  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [isError, setIsError] = useState(false)

  const {name, description, status, start_date, end_date} = values
  const id = props.match.params.id

  const {data} = useQuery(GET_HOLDING_VERSION_BY_ID, {
    variables: {id: idVersion},
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (props.location.state.activeStep) {
      setActiveStep(props.location.state.activeStep)
    }
    if (id) {
      localStorage.setItem('idVersion', id)
      if (data) {
        setValues(data.holding_version[0])
      }
    }
  }, [data])

  const handleChange = name => event => {
    let obj = {}
    if (name === 'start_date' || name === 'end_date') {
      obj = {
        ...values,
        [name]: event,
      }
    } else {
      obj = {
        ...values,
        [name]: event.target.value,
      }
    }
    localStorage.setItem('values', JSON.stringify(obj))
    setValues(obj)
  }

  const handleClickOpen = type => {
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      if (!name || !description || !status) {
        const ids = document.getElementById('top')
        ids.scrollIntoView({behavior: 'smooth'})
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else {
        setConfirmType(type)
        setPopup(true)
      }
    }
  }
  const handleClose = () => {
    setPopup(false)
  }

  const history = () => {
    props.history.push({
      pathname: '/group/config',
      state: {searchable: true, active: 'group-configuring'},
    })
    localStorage.clear()
  }

  const [mutationAddVersion] = useMutation(ADD_HOLDING_VERSION)
  const [mutationUpdateVersion] = useMutation(UPDATE_HOLDING_VERSION)

  const handleMutation = () => {
    if (idVersion) {
      const changes = {
        name,
        description,
        status,
        start_date,
        end_date,
        company: COMPANY_ID,
      }
      mutationUpdateVersion({
        variables: {id: idVersion, changes},
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })

          setActiveStep(1)
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    } else {
      mutationAddVersion({
        variables: {object: values},
        ...holdingRolesContext,
      })
        .then(d => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })

          localStorage.setItem(
            'idVersion',
            d.data.insert_holding_version.returning[0].id
          )
          setActiveStep(1)
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      // if edit atau punya localstorage else add version
      if (idVersion) {
        if (values === data.holding_version[0]) {
          setActiveStep(1)
        } else {
          handleClickOpen('edit')
        }
      } else {
        handleClickOpen('add')
      }
    } else if (activeStep === 3) {
      enqueueSnackbar('Data successfully saved', {
        variant: 'success',
        autoHideDuration: 3000,
      })
      history()
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <>
      <PaperContainer className={classes.paper}>
        <FormToolbar disableGutters>
          <FormTitleContainer>
            <IconButton
              edge="end"
              aria-label="back"
              onClick={() => handleClickOpen('discard')}
            >
              <ArrowBack />
            </IconButton>

            <AddEditTitle variant="h6">
              {id ? 'Edit' : 'Add'} Version
            </AddEditTitle>
          </FormTitleContainer>
          {/* <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => handleClickOpen('add')}
          >
            Save
          </Button> */}
          <div>
            {activeStep === 0 ? (
              <Button
                className={classes.backButton}
                onClick={() => handleClickOpen('discard')}
              >
                Cancel
              </Button>
            ) : (
              <Button onClick={handleBack} className={classes.backButton}>
                Previous
              </Button>
            )}

            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? 'Publish' : 'Next'}
            </Button>
          </div>
        </FormToolbar>

        <Divider className={classes.mb16} />
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomConnector />}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                classes={{
                  active: classes.labelActive,
                  completed: classes.labelActive,
                }}
                StepIconProps={{
                  classes: {
                    root: classes.iconRoot,
                    text: classes.iconText,
                    active: classes.iconActive,
                    completed: classes.iconActive,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div style={{padding: '1rem'}}>{getStepContent(activeStep)}</div>
      </PaperContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={name}
        feature="Holding Version"
        mutation={confirmType === 'discard' ? history : handleMutation}
      />
    </>
  )
}

export default withRouter(FormVersionGroup)
