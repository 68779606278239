import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TablePagination,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

import {PAGE_SIZE_OPTIONS} from '../ClaimPageStyles'
import {TableBox} from './ClaimPolicyItem'
import {WrapperModalGeneral} from '../../payroll/PayrollPageStyles'

import usePrevious from '../../../../hooks/usePrevious'
import {GET_CLAIM_APPLIES} from '../../../../graphql/queries/claim/getClaimSettings.query'

const INITIAL_PAGINATION = {
  page: 0,
  limit: 20,
}

function ClaimAppliesToDialog(props) {
  const {open = false, policyId, onClose} = props

  const skip = !policyId

  const [search, setSearch] = React.useState('')
  const [pagination, setPagination] = React.useState(INITIAL_PAGINATION)

  const {data, loading, error} = useQuery(GET_CLAIM_APPLIES, {
    wlb_skipPatch: true,
    skip: skip,
    variables: {
      policyId: policyId,
      search: `%${search}%`,
      offset: pagination.page * pagination.limit,
      limit: pagination.limit,
    },
  })

  // eslint-disable-next-line no-unused-vars
  const handlePageChange = (ev, page) => {
    setPagination({...pagination, page})
  }

  const handleLimitChange = ev => {
    setPagination({page: 0, limit: ev.target.value})
  }

  const handleSearchInput = ev => {
    setSearch(ev.target.value)
  }

  React.useLayoutEffect(() => {
    if (search) {
      setSearch('')
    }
  }, [policyId])

  const prevOpen = usePrevious(open)
  const isNeverOpen = !prevOpen && prevOpen === open

  if (isNeverOpen) {
    return null
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <WrapperModalGeneral>
        <div className="header">
          <h1 className="title">Applies to</h1>
          <IconButton
            aria-label="Close dialog"
            onClick={onClose}
            className="wrap-close"
            edge="end"
          >
            <CloseIcon className="icon-close" />
          </IconButton>
        </div>
        <div className="body">
          <OutlinedInput
            value={search}
            onChange={handleSearchInput}
            placeholder="Search"
            fullWidth
            margin="dense"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />

          <Box display="grid" gridGap={8} mt={3}>
            <Typography style={{fontWeight: 600}}>
              Applies to ({data?.available.aggregate.count || 0} Positions):
            </Typography>

            {loading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : error ? (
              <div>{JSON.stringify(error)}</div>
            ) : (
              <TableBox>
                {data.positions.map(item => (
                  <li key={item.profile.id}>{item.profile.title}</li>
                ))}
              </TableBox>
            )}

            <TablePagination
              count={data?.total.aggregate.count}
              page={pagination.page}
              rowsPerPage={pagination.limit}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              style={{borderBottom: 0}}
            />
          </Box>
        </div>
      </WrapperModalGeneral>
    </Dialog>
  )
}

export default ClaimAppliesToDialog
