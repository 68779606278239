import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {IconButton, Tooltip} from '@material-ui/core'
import {ErrorOutline} from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  baseColor: {
    background: '#eff2f4',
    color: '#000',
  },
  arrow: {
    '&::before': {
      background: '#eff2f4',
    },
  },
}))

export default function FieldInformationTooltip(props) {
  const classes = useStyles()

  return (
    <Tooltip
      classes={{tooltip: classes.baseColor, arrow: classes.arrow}}
      placement={props.placement || 'right'}
      title={props.title}
      arrow
      interactive
    >
      <IconButton size="small" style={{marginLeft: '8px'}}>
        <ErrorOutline color="primary" />
      </IconButton>
    </Tooltip>
  )
}
