import {useCallback, useState} from 'react'

function useToggle(initailState = false) {
  const [state, setState] = useState(initailState)

  const toggle = useCallback(() => {
    setState(!state)
  }, [state])

  return [state, toggle]
}

export default useToggle
