import gql from 'graphql-tag'

export const GET_COMPANY_WALLET = gql`
  query($companyID: uuid!) {
    company: company_profiles_by_pk(id: $companyID) {
      id
      wallet: global_wallet_account {
        id
      }
    }
  }
`

export const GET_COMPANY_SUMMARY = gql`
  query($companyID: uuid!) {
    in: global_transactions_aggregate(
      where: {
        transaction_item_table: {_eq: "system"}
        globalWalletAccountByTo: {foreign_owner_id: {_eq: $companyID}}
      }
    ) {
      aggregate {
        sum {
          nominal: value
        }
      }
    }
    out: global_transactions_aggregate(
      where: {
        transaction_item_table: {_eq: "system"}
        global_wallet_account: {foreign_owner_id: {_eq: $companyID}}
      }
    ) {
      aggregate {
        sum {
          nominal: value
        }
      }
    }
  }
`

export const GET_COMPANY_TRANSACTION_HISTORY = gql`
  query(
    $companyID: uuid!
    $where: global_transactions_bool_exp
    $limit: Int
    $offset: Int
  ) {
    total: global_transactions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    transactions: global_transactions(
      where: $where
      order_by: {date_created: desc_nulls_last}
      limit: $limit
      offset: $offset
    ) {
      id
      formatted_id
      nominal: value
      date: date_created
      category(args: {wallet_owner_id: $companyID})
      type
    }
  }
`

export const GET_COMPANY_TRANSACTION_DETAILS = gql`
  query($transactionID: Int!, $companyID: uuid!) {
    details: global_transactions_by_pk(id: $transactionID) {
      id
      formatted_id
      nominal: value
      date: date_created
      category(args: {wallet_owner_id: $companyID})
      type
      description
    }
  }
`

export const GET_TOPUP_RECIPIENTS_COUNT = gql`
  query($transactionID: Int!) {
    total: global_transactions_aggregate(
      where: {
        _or: [
          {parent_id: {_eq: $transactionID}}
          {
            id: {_eq: $transactionID}
            from: {_is_null: false}
            to: {_is_null: false}
            parent_id: {_is_null: true}
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_TOPUP_RECIPIENTS = gql`
  query($transactionID: Int!, $limit: Int, $offset: Int) {
    total: global_transactions_aggregate(
      where: {
        _or: [
          {parent_id: {_eq: $transactionID}}
          {
            id: {_eq: $transactionID}
            from: {_is_null: false}
            to: {_is_null: false}
            parent_id: {_is_null: true}
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    transactions: global_transactions(
      where: {
        _or: [
          {parent_id: {_eq: $transactionID}}
          {
            id: {_eq: $transactionID}
            from: {_is_null: false}
            to: {_is_null: false}
            parent_id: {_is_null: true}
          }
        ]
      }
      order_by: {date_created: desc_nulls_last, id: desc}
      limit: $limit
      offset: $offset
    ) {
      id
      formatted_id
      nominal: value
      recipient: globalWalletAccountByTo {
        user: global_users {
          id
          name: name
          placements: people_work_placements(order_by: {id: desc}, limit: 1) {
            regno
            profile: company_job_profile {
              position: title
            }
          }
        }
      }
    }
  }
`
