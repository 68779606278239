import gql from 'graphql-tag'

export const UPDATE_BPJS_SETTING = gql`
  mutation($company: uuid!, $plugin: jsonb) {
    update_finance_payroll_settings_by_pk(
      pk_columns: {id: $company}
      _set: {payroll_setting_plugins: $plugin}
    ) {
      id
    }
  }
`

export const ADD_BPJSKES_EMPLOYEE_EXCLUSION = gql`
  mutation($objects: [payroll_setting_exclude_bpjs_insert_input!]!) {
    insert_payroll_setting_exclude_bpjs(
      on_conflict: {
        constraint: payroll_setting_exclude_bpjs_user_company_deletedAt_key
        update_columns: [user, company, deletedAt]
      }
      objects: $objects
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const DELETE_BPJSKES_EMPLOYEE_EXCLUSION = gql`
  mutation($id: Int!) {
    update_payroll_setting_exclude_bpjs(
      _set: {deletedAt: "now()"}
      where: {_and: [{id: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      affected_rows
    }
  }
`

export const ADD_BPJSTK_EMPLOYEE_EXCLUSION = gql`
  mutation addBPJSTKEMployeeExclusion(
    $objects: [payroll_setting_exclude_bpjstk_insert_input!]!
  ) {
    insert_payroll_setting_exclude_bpjstk(
      on_conflict: {
        constraint: payroll_setting_exclude_bpjstk_user_company_deletedAt_key
        update_columns: [user, company, deletedAt]
      }
      objects: $objects
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const DELETE_BPJSTK_EMPLOYEE_EXCLUSION = gql`
  mutation deleteBPJSTKEmployeeExclusion($id: Int!) {
    update_payroll_setting_exclude_bpjstk(
      _set: {deletedAt: "now()"}
      where: {_and: [{id: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      affected_rows
    }
  }
`
