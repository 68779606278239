import React, {useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {Button, Tab, Tooltip, Typography} from '@material-ui/core'

import ActivityTab from './tab/ActivityTab'

import {
  AntTabs,
  EnhancedToolbar,
  Flex,
  PaperContainer,
} from '../../../../GlobalStyles'

const Activity = ({title, isAbleToCreateLoan, ...rest}) => {
  const [value, setValue] = useState(0)

  const TAB_CONTENT = {
    0: <ActivityTab {...rest} />,
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="subtitle1" style={{fontWeight: '600'}}>
          Loan {title}
        </Typography>

        <Flex flexEnd>
          {isAbleToCreateLoan ? (
            <Link
              to={{
                pathname: `/financial/loan/activity/create`,
                state: {
                  active: 'create-loan-activity',
                },
              }}
              style={{textDecoration: 'none'}}
            >
              <Button color="primary" variant="contained">
                Create Loan
              </Button>
            </Link>
          ) : (
            <Tooltip
              title="Please set your loan policy in Settings menu"
              placement="left"
              arrow
            >
              <span>
                <Button color="primary" variant="contained" disabled>
                  Create Loan
                </Button>
              </span>
            </Tooltip>
          )}
        </Flex>
      </EnhancedToolbar>

      <AntTabs
        value={value}
        onChange={(e, newValue) => {
          e.preventDefault()
          setValue(newValue)
        }}
        aria-label="loan-activity-tabs"
      >
        <Tab label="Activity" />
      </AntTabs>

      {TAB_CONTENT[value]}
    </PaperContainer>
  )
}

export default withRouter(Activity)
