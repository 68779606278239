import React, {useState, useEffect} from 'react'
import {PageContainer} from '../../../GlobalStyles'
import Card from '../../../components/card/CardComponent'
import {withRouter} from 'react-router-dom'
import ListJob from './list-position/ListJob'
import AddJobProfile from './list-position/AddJobProfile'
import DetailJobProfile from './list-position/DetailJobProfile'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'position':
      return <ListJob state={state} searchText={searchText} />
    case 'add-position':
      return (
        <AddJobProfile
          id={state.id}
          jobName={state.jobName}
          status={state.status}
          dataCheck={state.dataCheck}
          parentName={state.parentName}
          parentChecker={state.parentChecker}
          menu={state.menu}
        />
      )
    case 'edit-position':
      return (
        <AddJobProfile
          id={state.id}
          jobName={state.jobName}
          status={state.status}
          dataEdit={state.dataEdit}
          // data={state.data}
          parentName={state.parentName}
          // idEdit={state.idEdit}
          dataCheck={state.dataCheck}
          parentChecker={state.parentChecker}
        />
      )
    case 'detail-position':
      return (
        <DetailJobProfile
          id={state.id}
          jobName={state.jobName}
          searchText={searchText}
          parentChecker={state.parentChecker}
        />
      )
    default:
      return <ListJob searchText={searchText} />
  }
}

const Position = props => {
  let activeMenu = 'position'

  let state = {}

  if (props.location.state) {
    activeMenu = props.location.state.active
      ? props.location.state.active
      : 'position'
    state = props.location.state
  }

  useEffect(() => {
    if (props.location.pathname === '/organization/position') {
      props.history.push({
        state: {
          active: 'position',
          searchable: true,
        },
      })
    }
  }, [])

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <Card icon="device_hub" title="Posisi" onSearch={onSearch}>
      <PageContainer>
        {renderComponent(activeMenu, state, searchText)}
      </PageContainer>
    </Card>
  )
}

export default withRouter(Position)
