import gql from 'graphql-tag'

export const UPDATE_HOLDING_CLASS = gql`
  mutation update_holding_classes(
    $id: Int
    $changes: holding_classes_set_input
  ) {
    update_holding_classes(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`
