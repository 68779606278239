import React from 'react'

import {
  TextGrey,
  TextBlack,
} from '../../pages/financial/payroll/PayrollPageStyles'
import {Flex} from '../../GlobalStyles'

export default ({title, desc, titleStyle, descStyle}) => {
  return (
    <Flex style={{gap: 10}}>
      <TextGrey
        gutterBottom
        color="primary"
        style={{minWidth: '40%', maxWidth: '40%', ...titleStyle}}
      >
        {title}
      </TextGrey>
      <TextBlack
        gutterBottom
        style={{minWidth: '60%', maxWidth: '60%', ...descStyle}}
      >
        {desc}
      </TextBlack>
    </Flex>
  )
}
