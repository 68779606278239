import React from 'react'
import {EnhancedToolbar} from '../../../GlobalStyles'
import {Typography, Button} from '@material-ui/core'
import {Link} from 'react-router-dom'

const ToolbarMember = ({title, handleOpenInvite}) => {
  return (
    <EnhancedToolbar>
      <Typography variant="h6">{title}</Typography>
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          style={{marginRight: 20}}
          onClick={handleOpenInvite}
        >
          Invite Member
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={{
            pathname: `/member/${
              title === 'Company Member' ? 'list' : 'pending'
            }/add-company`,
            state: {active: 'add-company'},
          }}
        >
          Create Company
        </Button>
      </div>
    </EnhancedToolbar>
  )
}

export default ToolbarMember
