import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Button,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core'
import React, {useState} from 'react'
import {
  EnhancedToolbar,
  PaperContainer,
  RootContainer,
} from '../../../../GlobalStyles'
import {FlexColumnStart} from '../../../performance/goal/modal/modalStyles'
import {DOWNLOAD_DATA_REPORT} from '../../../../graphql/mutations'
import {
  GET_BALOON_DOWNLOAD_SUMMARY,
  GET_STATUS_BUTTON_DONWLOAD_EMPLOYEE_REPORT,
} from '../../../../graphql/queries'
import PopupWLB from '../../../shared-components/popup/PopupWLB'
import ChoosePeriodPopup from '../../../shared-components/popup/ChoosePeriodPopup'
import BaloonDownloadEmployee from './BaloonDownloadEmployee'
import {COMPANY_ID} from '../../../../utils/globals'
import moment from 'moment'
import ImportBaloonMultiple from './ImportBaloonMultiple'
import RunningProcess from './RunningProcess'
export default function EmployeeReport() {
  const [checkbox, setCheckbox] = useState({
    range: true,
    employee: true,
    mutation: true,
  })

  const [openExport, setOpenExport] = useState(false)
  const [openAllBaloon, setOpenAllBaloon] = useState(false)
  const [openChoosePeriod, setOpenChoosePeriod] = useState(false)

  const [downloadData, {error: errorDownloadData}] = useMutation(
    DOWNLOAD_DATA_REPORT
  )

  const {data: dataBaloonDonloadDirectory} = useQuery(
    GET_BALOON_DOWNLOAD_SUMMARY,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'employee_report_summary',
        today: moment(new Date()).format('YYYY-MM-DD'),
        tomorrow: moment(new Date())
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      },
    }
  )
  const {data: dataStatusButton} = useQuery(
    GET_STATUS_BUTTON_DONWLOAD_EMPLOYEE_REPORT,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'employee_report_summary',
        today: moment(new Date()).format('YYYY-MM-DD'),
        tomorrow: moment(new Date())
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      },
    }
  )

  const handleCheckbox = event => {
    event.persist()
    if (event.target.name === 'all') {
      setCheckbox({
        range: event.target.checked,
        employee: event.target.checked,
        mutation: event.target.checked,
      })
    } else {
      setCheckbox({
        ...checkbox,
        [event.target.name]: event.target.checked,
      })
    }
  }

  const selectedAll = checkbox.range && checkbox.employee && checkbox.mutation

  const handleDownloadData = ({startDate, endDate}) => {
    setOpenChoosePeriod(false)
    const selections = Object.entries(checkbox)
      .filter(([, value]) => value)
      .map(([key]) => _selection[key])
      .filter(selected => selected)
      .join(',')
    const format = 'YYYY-MM-DD'
    const start_date = moment(startDate).format(format)
    const end_date = moment(endDate).format(format)
    setOpenExport(true)
    downloadData({
      variables: {
        table: 'employee_report_summary',
        filter: {
          selections,
          start_date,
          end_date,
        },
      },
    })
  }

  const onTry = selections => {
    setOpenExport(true)
    downloadData({
      variables: {
        table: 'employee_report_summary',
        filter: {
          selections: selections,
        },
      },
    })
  }
  const isDisableDownload = type => {
    // const present_date = new Date()
    // const download_date = new Date(dateAdded)
    // const oneHours = 1000 * 60 * 60
    // const timeCalculate = present_date.getTime() - download_date.getTime()
    // const limitDisiabled = timeCalculate / oneHours
    // return limitDisiabled < 24
    if (!checkbox.employee && !checkbox.range && !checkbox.mutation) {
      return type === 'message' ? 'Please select one of the checkboxes' : true
    } else {
      if (dataStatusButton) {
        if (dataStatusButton.company_export_data_logs.length === 4) {
          return type === 'message'
            ? 'You can only generate data once a day. To download the data use the link that already generated'
            : true
        } else {
          const filter = []
          dataStatusButton.company_export_data_logs.map(res => {
            filter.push(res.filter.selections)
          })

          if (selectedAll) {
            if (
              filter.includes(
                'Range of Service,Employee Data Changes,Mutation Report'
              )
            ) {
              return type === 'message'
                ? 'You can only generate data once a day. To download the data use the link that already generated'
                : true
            } else {
              return type === 'message' ? '' : false
            }
          } else {
            const dataSelected = Object.entries(checkbox)
              .filter(([, value]) => value)
              .map(([key]) => _selection[key])
            const isAlreadyDownloaded = filter.some(r =>
              dataSelected.includes(r)
            )
            if (isAlreadyDownloaded) {
              if (
                filter.includes('Employee Data Changes') &&
                checkbox.employee
              ) {
                return type === 'message'
                  ? `You can only generate Employee Data Changes data once a day. To download the data use the link that already generated`
                  : true
              } else if (
                filter.includes('Mutation Report') &&
                checkbox.mutation
              ) {
                return type === 'message'
                  ? `You can only generate Mutation Report data once a day. To download the data use the link that already generated`
                  : true
              } else if (
                filter.includes('Range of Service') &&
                checkbox.range
              ) {
                return type === 'message'
                  ? `You can only generate Range of Service data once a day. To download the data use the link that already generated`
                  : true
              }
            } else {
              return type === 'message' ? '' : false
            }
          }
        }
      } else {
        return type === 'message' ? '' : false
      }
    }
  }

  const isDisable = isDisableDownload('button')
  const message = isDisableDownload('message')
  const TOTAL_PROCESS = dataBaloonDonloadDirectory
    ? dataBaloonDonloadDirectory.company_export_data_logs.length
    : 0

  return (
    <RootContainer>
      {TOTAL_PROCESS > 1 ? (
        <ImportBaloonMultiple
          totalProcess={TOTAL_PROCESS}
          onSeeAll={() => setOpenAllBaloon(true)}
          onRetry={onTry}
        />
      ) : (
        <BaloonDownloadEmployee
          dataBaloonDonloadDirectory={dataBaloonDonloadDirectory}
          dataStatusButton={dataStatusButton}
          errorDownloadData={errorDownloadData}
          onRetry={onTry}
        />
      )}

      <PaperContainer style={{overflow: 'unset'}}>
        <EnhancedToolbar>
          <Typography variant="h6">Laporan Pegawai</Typography>

          <Tooltip title={message} placement="left">
            <span>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={isDisable}
                style={{marginRight: 12}}
                onClick={() => setOpenChoosePeriod(true)}
              >
                Menghasilkan data
              </Button>
            </span>
          </Tooltip>
        </EnhancedToolbar>
        <Divider />
        <div style={{padding: '23px'}}>
          <Typography style={{fontWeight: 'bold'}}>
            Pilih data yang ingin di unduh
          </Typography>
          <FlexColumnStart>
            <FormControlLabel
              value="Select All"
              control={
                <Checkbox
                  onChange={handleCheckbox}
                  checked={selectedAll}
                  color="primary"
                />
              }
              label="Pilih semua"
              name="all"
              labelPlacement="Select All"
            />
            <FormControlLabel
              value="Range of Service"
              control={
                <Checkbox
                  onChange={handleCheckbox}
                  checked={checkbox.range}
                  color="primary"
                />
              }
              label="Jangkauan layanan"
              name="range"
              labelPlacement="Range of Service"
            />
            <FormControlLabel
              value="Employee Data Changes"
              control={
                <Checkbox
                  onChange={handleCheckbox}
                  checked={checkbox.employee}
                  color="primary"
                />
              }
              label="Perubahan data pegawai"
              name="employee"
              labelPlacement="Employee Data Changes"
            />
            <FormControlLabel
              value="Employee Mutation Report"
              control={
                <Checkbox
                  onChange={handleCheckbox}
                  checked={checkbox.mutation}
                  color="primary"
                />
              }
              label="Laporan Mutasi pegawai"
              name="mutation"
              labelPlacement="Employee Mutation Report"
            />
          </FlexColumnStart>
        </div>
        <ChoosePeriodPopup
          open={openChoosePeriod}
          onClose={() => setOpenChoosePeriod(false)}
          onConfirm={handleDownloadData}
        />
        <PopupWLB
          open={openExport}
          onClose={() => setOpenExport(false)}
          onClickButton={() => setOpenExport(false)}
          title="Generate Data is Processing!"
          content="Generate Data is processing! We’ll send you an email when it’s ready."
        />
        <RunningProcess
          open={openAllBaloon}
          onClose={() => {
            setOpenAllBaloon(false)
          }}
          data={dataBaloonDonloadDirectory}
          onRetry={onTry}
        />
      </PaperContainer>
    </RootContainer>
  )
}

const _selection = {
  range: 'Range of Service',
  employee: 'Employee Data Changes',
  mutation: 'Mutation Report',
}
