import gql from 'graphql-tag'

export const UPDATE_OVERTIME_POLICY = gql`
  mutation(
    $id: Int!
    $name: String
    $convertToLeave: Boolean
    $convertToMoney: jsonb
    $type: String
    $first: Int
    $second: Int
    $affected_job: [company_job_profile_r_overtime_insert_input!]!
  ) {
    update_time_overtime_policies(
      _set: {
        name: $name
        convert_to_leave: $convertToLeave
        convert_to_money: $convertToMoney
        confirm_type: $type
        first_confirm_profile: $first
        second_confirm_profile: $second
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }

    delete_company_job_profile_r_overtime(where: {policy: {_eq: $id}}) {
      affected_rows
    }

    insert_company_job_profile_r_overtime(objects: $affected_job) {
      affected_rows
    }
  }
`

export const UPDATE_OVERTIME_POLICY_WITH_FILTER = gql`
  mutation(
    $id: Int!
    $name: String!
    $convert_to_leave: Boolean
    $minimum_duration_leave: Int
    $convert_to_money: JSON
    $confirm_type: String
    $first_confirm_profile: Int
    $second_confirm_profile: Int
    $leave_policy_children: Int
    $filter: JSON
    $isUpdatePosition: Boolean
  ) {
    OvertimesPolicyUpdate(
      id: $id
      name: $name
      convert_to_leave: $convert_to_leave
      minimum_duration_leave: $minimum_duration_leave
      convert_to_money: $convert_to_money
      confirm_type: $confirm_type
      first_confirm_profile: $first_confirm_profile
      second_confirm_profile: $second_confirm_profile
      leave_policy_children: $leave_policy_children
      filter: $filter
      is_update_position: $isUpdatePosition
    ) {
      success
    }
  }
`
