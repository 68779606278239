import React, {useState, useEffect} from 'react'
import SortableTree from 'react-sortable-tree'
import {Paper, Divider, Button} from '@material-ui/core'
import {BoldTypography} from '../../../../components/typography/TypographyStyles'
import {SmallAvatar, GreyIcon} from '../../../../GlobalStyles'
import {AvatarGroup} from '@material-ui/lab'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'
import {GET_INDUSTRY_STRUCTURE_HOLDING} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import {useQuery} from '@apollo/react-hooks'
import Loading from '../../../../components/loading/LoadingComponent'
import arrayToTree from 'array-to-tree'
import uniqBy from 'lodash/uniqBy'

const IndustryStructure = props => {
  const {classes, handleClickOpen} = props
  const [treeData, setTreeData] = useState([])

  const {data, error} = useQuery(GET_INDUSTRY_STRUCTURE_HOLDING(COMPANY_ID), {
    context: {
      headers: {
        'X-Hasura-Role': 'holding-administrator',
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data) {
      const arr = []
      const len = data.holding_subcluster.length
      for (var i = 0; i < len; i++) {
        const response = data.holding_subcluster[i]
        arr.push({
          id_parent: response.subcluster_parent.id,
          name: response.subcluster_parent.name,
          parent: null,
          expanded: true,
          count:
            response.subcluster_parent.subcluster_parent_count.aggregate.count,
          parent_member: response.subcluster_parent.parent_member,
        })
      }

      const newArr = [...data.holding_subcluster, ...uniqBy(arr, 'id_parent')]

      const structuredData = arrayToTree(newArr, {
        parentProperty: 'parent',
        customID: 'id_parent',
      })

      setTreeData(structuredData)
    }
  }, [data])

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <TransformWrapper
      defaultScale={1}
      defaultPositionX={200}
      defaultPositionY={100}
    >
      {({zoomIn, zoomOut, resetTransform}) => (
        <>
          <div className={classes.btnZoom}>
            <Button
              size="large"
              className={`${classes.shadow} ${classes.little} ${classes.mr18}`}
              onClick={zoomIn}
            >
              <GreyIcon>add</GreyIcon>
            </Button>
            <Button
              size="large"
              className={`${classes.shadow} ${classes.little} ${classes.mr18}`}
              onClick={zoomOut}
            >
              <GreyIcon>remove</GreyIcon>
            </Button>
            <Button
              size="large"
              className={`${classes.shadow} ${classes.little}`}
              onClick={resetTransform}
            >
              <GreyIcon>fullscreen</GreyIcon>
            </Button>
          </div>
          <TransformComponent>
            {treeData.length > 0 &&
              treeData.map((res, i) => {
                return (
                  <div style={{minHeight: 500, width: '22vw'}} key={i}>
                    <SortableTree
                      canDrag={false}
                      treeData={[res]}
                      onChange={data => setTreeData(data)}
                      rowHeight={150}
                      nodeContentRenderer={({node, treeIndex}) => {
                        const arrMember =
                          treeIndex !== 0
                            ? node.subcluster_member
                            : node.parent_member

                        const id = treeIndex !== 0 ? node.id : node.id_parent
                        const isHead = treeIndex !== 0

                        return (
                          <>
                            <Paper className={classes.paperStructure}>
                              <div
                                className={classes.structureHeader}
                                onClick={() =>
                                  handleClickOpen(id, isHead, true)
                                }
                              >
                                <BoldTypography variant="body1" gutterBottom>
                                  {node.name}
                                </BoldTypography>
                              </div>

                              <Divider className={classes.divider} />
                              <div className={classes.companyInfo}>
                                <AvatarGroup className={classes.agroupRoot}>
                                  {arrMember.slice(0, 5).map((resmember, i) => (
                                    <SmallAvatar
                                      key={i}
                                      alt={`${i}`}
                                      src={
                                        resmember.company_member_profile.logo
                                      }
                                    />
                                  ))}
                                </AvatarGroup>
                                <GreyIcon>business</GreyIcon>
                                <BoldTypography variant="body1">
                                  {treeIndex === 0
                                    ? node.count
                                    : node.subcluster_count.aggregate.count}
                                </BoldTypography>
                              </div>
                            </Paper>
                            {treeIndex === 0 && (
                              <Divider
                                orientation="vertical"
                                style={{
                                  height: 28,
                                  background: '#000',
                                  marginLeft: 22,
                                }}
                              />
                            )}
                          </>
                        )
                      }}
                    />
                  </div>
                )
              })}
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  )
}

export default IndustryStructure
