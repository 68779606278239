import gql from 'graphql-tag'

export const GET_EVENT_CATEGORY = gql`
  query GetEventCategoriesandType($company: uuid) {
    event_categories(
      where: {deletedAt: {_is_null: true}, company: {_eq: $company}}
    ) {
      id
      name
    }

    event_types(
      where: {deletedAt: {_is_null: true}, company: {_eq: $company}}
    ) {
      id
      name
    }
  }
`
