import gql from 'graphql-tag'

export const LIST_JOB_PROFILE = gql`
  query($offset: Int, $limit: Int, $search: String) {
    total: company_organizations_aggregate(
      where: {
        _or: [
          {name: {_ilike: $search}}
          {company_job_profiles: {title: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    company_organizations(
      offset: $offset
      limit: $limit
      where: {
        _or: [
          {name: {_ilike: $search}}
          {company_job_profiles: {title: {_ilike: $search}}}
        ]
      }
    ) {
      id
      name
      parent
      count_employee
      company_job_profiles_aggregate {
        aggregate {
          count
        }
        nodes {
          people_work_placements_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`

export const LIST_JOB_PROFILE_SHEET_TEMPLATE = gql`
  query($company: uuid!) {
    company_job_profiles(
      where: {company: {_eq: $company}}
      order_by: [{title: asc}]
    ) {
      id
      title
    }
  }
`

export const LIST_EMPLOYEE_POSITION = gql`
  query(
    $company: uuid
    $organization: [Int!]
    $designation: [Int!]
    $level: [Int!]
    $jobProfile: [Int!]
    $office: [Int!]
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        company: {_eq: $company}
        company_job_profile: {
          id: {_in: $jobProfile}
          organization: {_in: $organization}
          position: {_in: $designation}
          rank: {_in: $level}
          office_address: {_in: $office}
        }
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
        status: {_eq: "ACTIVE"}
        deletedAt: {_is_null: true}
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_placements(
      where: {
        company: {_eq: $company}
        company_job_profile: {
          id: {_in: $jobProfile}
          organization: {_in: $organization}
          position: {_in: $designation}
          rank: {_in: $level}
          office_address: {_in: $office}
        }
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
        status: {_eq: "ACTIVE"}
        deletedAt: {_is_null: true}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const LIST_ALL_PROFILE = gql`
  query($company: uuid, $search: String, $offset: Int, $limit: Int) {
    total: company_job_profiles_aggregate(
      where: {
        _or: [
          {title: {_ilike: $search}}
          {code: {_ilike: $search}}
          {job_profile_supervisor: {title: {_ilike: $search}}}
          {company_organization: {name: {_ilike: $search}}}
        ]
        company: {_eq: $company}
      }
    ) {
      aggregate {
        count
      }
    }
    company_job_profiles(
      where: {
        _or: [
          {title: {_ilike: $search}}
          {code: {_ilike: $search}}
          {job_profile_supervisor: {title: {_ilike: $search}}}
          {company_organization: {name: {_ilike: $search}}}
        ]
        company: {_eq: $company}
      }
      #order_by: [{parent: asc_nulls_first}]
      offset: $offset
      limit: $limit
    ) {
      id
      code
      title
      job_summary
      job_description
      job_icon
      slots
      experience_level
      parent
      rate_salary_from
      rate_salary_to
      attachment
      job_grade {
        id
        code
        name
      }
      require_successor
      acting_as {
        id
        code
        title
      }
      company_organization {
        id
        name
      }
      company_employee_position {
        id
        designation_code
        name
      }
      company_employee_rank {
        id
        code
        name
      }
      company_address {
        id
        office_code
        office_name
      }
      company_job_profile_r_educations {
        id
        global_degree {
          id
          name
        }
        global_faculty {
          id
          name
        }
      }
      company_job_profile_competencies(
        where: {
          competency_version_dictionary: {
            competency_version: {status: {_eq: "ACTIVE"}}
          }
        }
      ) {
        id
        competency_version_dictionary {
          id
          competency_dictionary {
            id
            name
            description
            type
          }
        }
        level
      }
      company_job_profile_r_course {
        id
        academy_course {
          id
          title
        }
      }
      company_job_profile_r_kpi {
        id
        kpi_list {
          id
          name
        }
      }
      roles
      job_profile_supervisor {
        id
        code
        organization
        title
      }
      people_work_placements_aggregate(where: {status: {_eq: "ACTIVE"}}) {
        aggregate {
          count
        }
      }
      job_profile_subordinate_aggregate {
        aggregate {
          count
        }
      }
    }
    parent_job_profile: company_job_profiles(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      title
      company_organization {
        id
        name
      }
    }
    parent_organization: company_organizations(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      name
    }
  }
`

export const GET_OFFICE_DROPDOWN = gql`
  query($company: uuid, $search: String, $offset: Int, $limit: Int) {
    total: company_addresses_aggregate(
      where: {
        company: {_eq: $company}
        _or: [
          {office_code: {_ilike: $search}}
          {office_name: {_ilike: $search}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    company_addresses(
      where: {
        company: {_eq: $company}
        _or: [
          {office_code: {_ilike: $search}}
          {office_name: {_ilike: $search}}
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      office_code
      office_name
    }
  }
`

export const CHECK_CODE_POSITION = gql`
  query($company: uuid, $code: String) {
    company_job_profiles_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_POSITION_LIST = gql`
  query($company: uuid, $search: String, $offset: Int, $limit: Int) {
    total: company_job_profiles_aggregate(
      where: {
        _or: [
          {title: {_ilike: $search}}
          {code: {_ilike: $search}}
          {job_profile_supervisor: {title: {_ilike: $search}}}
          {company_organization: {name: {_ilike: $search}}}
        ]
        company: {_eq: $company}
      }
    ) {
      aggregate {
        count
      }
    }
    company_job_profiles(
      where: {
        _or: [
          {title: {_ilike: $search}}
          {code: {_ilike: $search}}
          {job_profile_supervisor: {title: {_ilike: $search}}}
          {company_organization: {name: {_ilike: $search}}}
        ]
        company: {_eq: $company}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      job_icon
      code
      title
      company_organization {
        id
        name
        code
      }
      job_profile_supervisor {
        id
        code
        title
      }
    }
    parent_job_profile: company_job_profiles(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      title
      company_organization {
        id
        name
      }
    }
    parent_organization: company_organizations(
      where: {company: {_eq: $company}, parent: {_is_null: true}}
    ) {
      id
      code
      name
    }
  }
`

export const GET_JOB_PLACEMENT_BY_POSITION_ID = gql`
  query($position: Int) {
    total: people_work_placements_aggregate(
      where: {job_profile: {_eq: $position}}
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(where: {job_profile: {_eq: $position}}, limit: 5) {
      id
      regno
      global_user {
        name
      }
    }

    totalSub: company_job_profiles_aggregate(
      where: {parent: {_eq: $position}}
    ) {
      aggregate {
        count
      }
    }
    company_job_profiles(where: {parent: {_eq: $position}}, limit: 5) {
      id
      code
      title
    }
  }
`

export const GET_JOBPROFILE_CHILD_COUNT = gql`
  query($jobProfileId: Int!, $organizationId: Int!) {
    profile: company_job_profiles_by_pk(id: $jobProfileId) {
      id
      organization
      title
      subprofiles: job_profile_children_aggregate(
        where: {
          as_assistant: {_eq: false}
          organization: {_eq: $organizationId}
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`
