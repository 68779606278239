import {Button, Popover} from '@material-ui/core'
import React, {useState} from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {EMPOYEE_PERSONAL_DATA} from './constant'

const Item = ({label, ...child}) => {
  return (
    <Button
      {...child}
      color="primary"
      style={{
        padding: '10px 16px',
        cursor: 'pointer',
        fontSize: 12,
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        borderRadius: 0,
      }}
    >
      {label}
    </Button>
  )
}

export default function ButtonImport({onClickOption, disabled = [true, true]}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const options = [
    {
      id: 1,
      label: 'Employee List',
      type: 'Employees',
      disabled: disabled[0],
    },
    {
      id: 2,
      label: 'Employee Personal Data',
      type: EMPOYEE_PERSONAL_DATA,
      disabled: disabled[1],
    },
  ]

  const handleClickOption = type => {
    handleClose()
    onClickOption(type)
  }

  return (
    <>
      <Button
        variant={'outlined'}
        color="primary"
        size="large"
        style={{marginRight: 12, paddingRight: 10}}
        onClick={handleClick}
        aria-haspopup="true"
        aria-owns={open ? 'menu-list-grow' : undefined}
      >
        Impor Data
        <ArrowDropDownIcon style={{marginLeft: 8}} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{display: 'flex', flexDirection: 'column'}}
      >
        {options.map(({label, type, id, disabled}) => (
          <Item
            key={id}
            label={label}
            disabled={disabled}
            onClick={() => handleClickOption(type)}
          />
        ))}
      </Popover>
    </>
  )
}
