import React from 'react'

const HomePage = () => {
  return (
    <div style={{margin: 20}}>
      <h2>Homepage</h2>
      <p>this is homepage</p>
    </div>
  )
}

export default HomePage
