import gql from 'graphql-tag'

export const SEE_ALL_POOL_SUCCESSOR = gql`
  query getSuccessor($id_nomination: Int, $search: String, $status: String) {
    holding_nomination_people(
      where: {
        candidate_status: {_eq: $status}
        nomination_id: {_eq: $id_nomination}
        _or: [
          {holding_source: {people_work_placement: {regno: {_ilike: $search}}}}
          {
            holding_source: {
              people_work_placement: {global_user: {name: {_ilike: $search}}}
            }
          }
          {
            holding_source: {
              people_work_placement: {
                company_job_profile: {title: {_ilike: $search}}
              }
            }
          }
          {
            holding_source: {
              people_work_placement: {
                company_profile: {legal_name: {_ilike: $search}}
              }
            }
          }
          {
            holding_source: {
              people_work_placement: {
                company_profile: {brand_name: {_ilike: $search}}
              }
            }
          }
        ]
      }
    ) {
      id
      holding_source {
        people_work_placement {
          id
          regno
          global_user {
            id
            name
            avatar
          }
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            legal_name
            brand_name
          }
          talent_assignments {
            talent_score
            talent_box {
              id
              index_x
              index_y
              name
              badge
            }
          }
        }
      }
    }
  }
`
