import gql from 'graphql-tag'

// Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/version/DELETE%20ITEM%20LIST%20DETAIL%20VERSION.md
export const DELETE_COMPETENCY_IN_VERSION = gql`
  mutation($version: Int!, $competency: Int!) {
    delete_competency_version_dictionaries(
      where: {version: {_eq: $version}, competency: {_eq: $competency}}
    ) {
      affected_rows
    }
  }
`

// Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/version/DELETE%20ITEM%20LIST%20DETAIL%20VERSION.md
export const CHECK_DELETE_COMPETENCY = gql`
  query checkDelete($version: Int!, $competency: Int!) {
    competency_model_items_aggregate(
      where: {
        competency_version_dictionary: {
          version: {_eq: $version}
          competency: {_eq: $competency}
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
