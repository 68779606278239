import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'
import ListComponent from './ListComponent'
import PendingComponent from './PendingComponent'
import SourceComponent from './SourceComponent'
import NominationComponent from './NominationComponent'
import FormNominationComponent from './form-nomination/FormNominationComponent'
import DetailNominationComponent from './form-nomination/DetailNominationComponent'
import SeeAllComponent from './form-nomination/see-all/SeeAllComponent'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'holding-talent-list':
      return <ListComponent state={state} searchText={searchText} />
    case 'holding-talent-pending':
      return <PendingComponent state={state} searchText={searchText} />
    case 'holding-talent-source':
      return <SourceComponent state={state} searchText={searchText} />
    case 'holding-talent-nomination':
      return <NominationComponent state={state} searchText={searchText} />
    case 'add-nomination':
    case 'edit-nomination':
      return <FormNominationComponent />
    case 'detail-nomination':
      return <DetailNominationComponent />
    case 'all-successor':
    case 'all-pool':
      return <SeeAllComponent state={state} searchText={searchText} />
    default:
      return <ListComponent state={state} searchText={searchText} />
  }
}

const ListPageComponent = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <div>
      <Card icon="business" title="Talent" onSearch={onSearch}>
        <PageContainer>
          {renderComponent(active, state, searchText)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default ListPageComponent
