import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import {useQuery, useMutation} from '@apollo/react-hooks'

import {Box, Button, Divider, Typography} from '@material-ui/core'

import {EnhancedToolbar, PaperContainer} from '../../../../GlobalStyles'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import EmployeeHistoryList from './EmployeeHistoryList'
import EmployeeClaimEditDialog from './EmployeeClaimEditDialog'

import {COMPANY_ID} from '../../../../utils/globals'
import {TEMPLATES_IMPORT_PERSONAL_DATA} from '../../../directory/employee/constant'
import {CLOSE_BALOON, IMPORT_DATA} from '../../../../graphql/mutations'
import {GET_BALOON_STATUS_BUTTON_QUERY_PARALEL} from '../../../../graphql/queries'
import {GET_EMPLOYEE_BALANCES} from '../../../../graphql/queries/budget/limit/getEmployeeBalance.query'
import {getEmployeeFilterVariables} from '../filters'
import {getYearStartEndDate} from '../../../../utils/helpers'

function Employee(props) {
  const [showImportPopup, setShowImportPopup] = React.useState(false)

  const openImportPopup = () => {
    setShowImportPopup(true)
  }

  const closeImportPopup = () => {
    setShowImportPopup(false)
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
          Employee Claim Limit
        </Typography>

        <Box display="flex" alignItems="center">
          <Button
            onClick={openImportPopup}
            variant="outlined"
            color="primary"
            size="large"
          >
            Import Balance
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{marginLeft: 16}}
            component={Link}
            to={{
              pathname: '/budget/limit/employee/topup',
              state: {active: 'employee-topup'},
            }}
          >
            Top Up Employee
          </Button>
        </Box>
      </EnhancedToolbar>

      <Divider />

      <EmployeeListing {...props} />

      <EmployeeClaimImportPopup
        open={showImportPopup}
        onClose={closeImportPopup}
      />
    </PaperContainer>
  )
}

export default withRouter(Employee)

function EmployeeListing(props) {
  const {searchText, filterData} = props

  const [showEditPopup, setShowEditPopup] = React.useState(null)
  const [pagination, setPagination] = React.useState({
    offset: 0,
    limit: 20,
  })

  const ranges = React.useMemo(() => getYearStartEndDate(), [])

  const where = React.useMemo(() => {
    return getEmployeeFilterVariables({searchText, filterData})
  }, [searchText, filterData])

  const {data, loading, error, refetch} = useQuery(GET_EMPLOYEE_BALANCES, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: where,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      startDate: ranges.start,
      endDate: ranges.end,
    },
  })

  const handleChangeOffset = offset => {
    if (pagination.offset !== offset) {
      setPagination({...pagination, offset})
    }
  }

  const handleChangeLimit = limit => {
    if (pagination.limit !== limit) {
      setPagination({...pagination, offset: 0, limit})
    }
  }

  const closeEditPopup = shouldRefetch => {
    setShowEditPopup(null)

    if (shouldRefetch) {
      refetch()
    }
  }

  React.useLayoutEffect(() => {
    handleChangeOffset(0)
  }, [filterData])

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  return (
    <>
      <EmployeeHistoryList
        data={data?.employees || []}
        totalCount={data?.total.aggregate.count || 0}
        loading={loading}
        offset={pagination.offset}
        limit={pagination.limit}
        onChangeOffset={handleChangeOffset}
        onChangeLimit={handleChangeLimit}
        onEditClick={setShowEditPopup}
      />

      <EmployeeClaimEditDialog
        open={!!showEditPopup}
        onClose={closeEditPopup}
        {...showEditPopup}
      />
    </>
  )
}

function EmployeeClaimImportPopup(props) {
  const {open, onClose} = props

  /* eslint-disable */
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )

  const [isAlreadyImported, setIsAlreadyImported] = React.useState(false)
  const [fileURL, setFileURL] = React.useState('')

  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    refetch: refetchBaloonStatus,
    loading: loadingBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON_QUERY_PARALEL, {
    variables: {
      company: COMPANY_ID,
      table: 'global_users',
      multiple_table: TEMPLATES_IMPORT_PERSONAL_DATA.map(
        ({tableName}) => tableName
      ),
    },
  })

  const importMutation = async () => {
    try {
      // eslint-disable-next-line
      const response = await mutationImport({
        variables: {
          table: 'table',
          file: fileURL,
        },
      })

      refetchBaloonStatus()
    } catch (error) {
      console.log(error)
    }
  }

  const closeBaloonMutation = async id => {
    try {
      await mutationCloseBaloon({
        variables: {
          id,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }
  /* eslint-enable */

  return (
    <ImportPopup
      open={open}
      position="Employee Balance"
      handleClose={onClose}
      mutation={importMutation}
      isAlreadyImported={isAlreadyImported}
      setIsAlreadyImported={setIsAlreadyImported}
      setImport={onClose}
      fileURL={fileURL}
      setFileURL={setFileURL}
      closeBaloon={closeBaloonMutation}
      errorsImport={
        getBaloonStatus &&
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].reports &&
        getBaloonStatus.company_import_data_logs[0].reports.failed.length > 0
          ? getBaloonStatus.company_import_data_logs[0].reports.failed
          : []
      }
      // templates={{
      //   isMultiple: positionImport === EMPOYEE_PERSONAL_DATA,
      //   listTemplates: TEMPLATES_IMPORT_PERSONAL_DATA,
      //   onClickTemplates: (clickedList, MASTER_DATA) =>
      //   generateTempaltesPersonal(clickedList, MASTER_DATA),
      //   errorsImportMultiple: errorImportMultiple,
      //   isShowListTemplate,
      //   // isErrorValidateTableName: ({name}) => {
      //   //   if(name) {
      //   //     return tableNameChecker(name)
      //   //   } else return false
      //   // }
      // }}
    />
  )
}
