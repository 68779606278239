import gql from 'graphql-tag'

export const GET_PERFORMANCE_DASHBOARD = gql`
  query dashboardPerformance(
    $organization: Int
    $filterDate: date_comparison_exp
    $company: uuid!
  ) {
    all_goal_complete: performance_goals_aggregate(
      where: {
        performance_tasks: {
          performance_task_assignments: {
            global_user: {
              people_work_placements: {
                status: {_eq: "ACTIVE"}
                company_job_profile: {organization: {_eq: $organization}}
              }
            }
          }
        }
        company: {_eq: $company}
        startdate: $filterDate
        kpi_list: {
          _or: [
            {_not: {kpi_cascading_lists: {}}}
            {
              kpi_cascading_lists: {
                deleted_at: {_is_null: true}
                cascading_main: {
                  company: {_eq: $company}
                  deleted_at: {_is_null: true}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          ]
        }
        _not: {
          performance_tasks: {status: {_in: ["TODO", "INPROGRESS", "DONE"]}}
        }
      }
    ) {
      aggregate {
        total_all_goal_complete: count
        avg {
          avg_all_goal_complete: progress_percentage
        }
      }
    }
    all_goal_todo: performance_goals_aggregate(
      where: {
        performance_tasks: {
          status: {_in: ["TODO", "INPROGRESS", "DONE"]}
          performance_task_assignments: {
            global_user: {
              people_work_placements: {
                status: {_eq: "ACTIVE"}
                company_job_profile: {organization: {_eq: $organization}}
              }
            }
          }
        }
        company: {_eq: $company}
        startdate: $filterDate
        kpi_list: {
          _or: [
            {_not: {kpi_cascading_lists: {}}}
            {
              kpi_cascading_lists: {
                deleted_at: {_is_null: true}
                cascading_main: {
                  company: {_eq: $company}
                  deleted_at: {_is_null: true}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          ]
        }
      }
    ) {
      aggregate {
        total_all_goal_todo: count
        avg {
          avg_all_goal_todo: progress_percentage
        }
      }
    }
    all_task_todo: performance_tasks_aggregate(
      where: {
        performance_task_assignments: {
          global_user: {
            people_work_placements: {
              status: {_eq: "ACTIVE"}
              company_job_profile: {organization: {_eq: $organization}}
            }
          }
        }
        startdate: $filterDate
        status: {_eq: "TODO"}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_todo: count
        avg {
          avg_all_task_todo: progress_percentage
        }
      }
    }
    all_task_inprogress: performance_tasks_aggregate(
      where: {
        performance_task_assignments: {
          global_user: {
            people_work_placements: {
              status: {_eq: "ACTIVE"}
              company_job_profile: {organization: {_eq: $organization}}
            }
          }
        }
        startdate: $filterDate
        status: {_eq: "INPROGRESS"}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_inprogress: count
        avg {
          avg_all_task_inprogress: progress_percentage
        }
      }
    }
    all_task_done: performance_tasks_aggregate(
      where: {
        performance_task_assignments: {
          global_user: {
            people_work_placements: {
              status: {_eq: "ACTIVE"}
              company_job_profile: {organization: {_eq: $organization}}
            }
          }
        }
        startdate: $filterDate
        status: {_eq: "DONE"}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_done: count
        avg {
          avg_all_task_done: progress_percentage
        }
      }
    }
    all_task_completed: performance_tasks_aggregate(
      where: {
        performance_task_assignments: {
          global_user: {
            people_work_placements: {
              status: {_eq: "ACTIVE"}
              company_job_profile: {organization: {_eq: $organization}}
            }
          }
        }
        startdate: $filterDate
        status: {_in: ["COMPLETED", "ARCHIVED"]}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_completed: count
        avg {
          avg_all_task_completed: progress_percentage
        }
      }
    }
  }
`
