import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
} from '../../../../GlobalStyles'
import {
  Typography,
  Divider,
  Button,
  Radio,
  RadioGroup,
  MenuItem,
  InputAdornment,
  IconButton,
  Box,
} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import {
  TitleText,
  FormControlLabelStyled,
  InfoEmployeeUpdate,
  TextError,
} from '../EmployeePageStyles'
import ComponentInput from './ComponentInput'
import ComponentSelect from './ComponentSelect'
import ComponentDate from './ComponentDate'
import {
  errorChecker,
  formatatSalary,
  getLastSalary,
  getTypeFile,
} from './utilsForEmployee'
import {format} from 'date-fns'
import {
  GET_PROVINCE,
  GET_CITY,
  GET_RELIGION,
  GET_PLACEMENT_EMP_BY_ID,
  GET_JOB_PROFILE_DIRECTORY,
  IS_EXIST_EMAIL,
  GET_LEGAL_DROPDOWN,
  IS_EXIST_PHONE,
  IS_EXIST_REGNO,
  GET_BANK_PEOPLE_WORK_SALARY,
} from '../../../../graphql/queries'
import {
  UPDATE_EMP_INFORMATION,
  UPDATE_EMP_PLACEMENT,
  ADD_EMPLOYEE,
  UPDATE_PHONE_INFORMATION,
  ADD_PHONE_INFORMATION,
  ADD_BASIC_SETTING,
  UPDATE_PREV_PERIOD_BASIC_SETTING,
} from '../../../../graphql/mutations'
import {useQuery, useMutation, useApolloClient} from '@apollo/react-hooks'
import {
  capitalize,
  dateSubtract,
  isEmailAddress,
} from '../../../../utils/helpers'
import ComponentRSelect from './ComponentRSelect'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import LoadingEmployee from '../LoadingEmployee'
import {COMPANY_ID, TOKEN, UPLOAD_URL} from '../../../../utils/globals'
import ComponentInputPhone from './ComponentInputPhone'
import ArrowBack from '@material-ui/icons/ArrowBack'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import AttachementComponent from './AttachementComponent'
import Axios from 'axios'
import {FILE_TYPE_UPLOAD} from '../constant'
import AttachmentItem from '../../../../components/attachment/AttachmentItem'

const AddEmployee = props => {
  const client = useApolloClient()
  const {isEdit, isReplacement, userId} = props.location.state

  const {enqueueSnackbar} = useSnackbar()
  const [phoneCode, setPhoneCode] = useState(
    isEdit || isReplacement ? null : '+62'
  )
  const [globalPhoneId, setGlobalPhoneId] = useState(null)
  const [phoneId, setPhoneId] = useState(null)
  const [value, setValue] = useState({
    name: '',
    gender: '',
    birthdate: null,
    email: '',
    phone_number: null,
    religion: 'default',
    address: '',
    province: 'default',
    city: 'default',
    postal_code: null,
    regno: null,
    job_profile: 'default',
    legal: 'default',
    time: 'default',
    place: 'default',
    salary: formatatSalary(0),
    joindate: new Date(),
    startdate: new Date(),
    enddate: null,
    reference: '',
    reminder: 60,
    attachment: [],
  })

  const [status, setStatus] = useState('')
  const [error, setError] = useState(false)
  const [global, setGlobal] = useState(null)
  const [errorAttachment, setErrorAttachment] = useState({
    fileType: false,
    size: false,
    totalFiles: false,
  })

  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [isEmailExist, setEmailExist] = useState(false)
  const [isPhoneExist, setPhoneExist] = useState(false)
  const [isRegnoExist, setRegnoExist] = useState(false)
  const [stgRegno, setStgRegno] = useState(null)

  const {data: dataReligion} = useQuery(GET_RELIGION, {wlb_skipPatch: true})
  const {data: dataProvince} = useQuery(GET_PROVINCE, {wlb_skipPatch: true})
  const {data: dataCity} = useQuery(GET_CITY, {
    variables: {
      prov: value?.province?.value,
    },
  })

  const {data: dataLegal} = useQuery(GET_LEGAL_DROPDOWN, {
    wlb_skipPatch: true,
    context: {
      headers: {
        'X-Hasura-Role': 'organization-hr-admin',
      },
    },
  })

  const isPending = props.location.pathname.includes(
    '/directory/employee-pending'
  )
  const [insertEmp] = useMutation(ADD_EMPLOYEE)
  const [updateEmp] = useMutation(UPDATE_EMP_PLACEMENT)
  const [updateEmpInfo] = useMutation(UPDATE_EMP_INFORMATION)
  const [updateEmpPhone] = useMutation(UPDATE_PHONE_INFORMATION)
  const [insertEmpPhone] = useMutation(ADD_PHONE_INFORMATION)
  const [updatePrevPeriodSetting] = useMutation(
    UPDATE_PREV_PERIOD_BASIC_SETTING
  )
  const [addPeriodSetting] = useMutation(ADD_BASIC_SETTING)

  useEffect(() => {
    if (isEdit || isReplacement) {
      const setData = async () => {
        const {data} = await client.query({
          query: GET_PLACEMENT_EMP_BY_ID,
          variables: {
            id: props.match.params.id,
            user: userId,
          },
          fetchPolicy: 'network-only',
        })
        const plc = data.people_work_placements?.[0]
        const r = plc?.company_job_profile
        setGlobal(plc)
        setStgRegno(plc?.regno)
        const {global_user} = plc || {}
        if (isEdit || isReplacement) {
          setPhoneCode(global_user?.phone?.[0]?.country_code)
          setPhoneId(global_user?.phone?.[0]?.id)
          setValue({
            ...value,
            name: global_user?.name,
            gender: global_user?.people_profiles?.[0]?.gender,
            birthdate: global_user?.people_profiles?.[0]?.birthdate,
            email: global_user?.email,
            phone_number: global_user?.phone?.[0]?.phone,
            country_code_phone: global_user?.phone?.[0]?.country_code,
            religion: global_user?.people_profiles?.[0]?.global_religion?.id,
            address: global_user?.address?.[0]?.address,
            province: global_user?.address?.[0]
              ? {
                  value: global_user?.address?.[0]?.global_province?.id,
                  label: global_user?.address?.[0]?.global_province?.name,
                }
              : null,
            city: global_user?.address?.[0]
              ? {
                  value: global_user?.address?.[0]?.global_city?.id,
                  label: global_user?.address?.[0]?.global_city?.name,
                }
              : null,
            postal_code: global_user?.address?.[0]?.postal_code,
            regno: plc?.regno,
            reference: plc?.reference, // as Decree
            job_profile: {
              value: r?.id,
              label: r?.title,
              role: r?.roles || '00000010',
              supervisor:
                r?.job_profile_supervisor?.people_work_placements?.[0]?.user ||
                null,
              organization: r?.organization,
              position: r?.position,
              rank: r?.rank,
              office_address: r?.office_address,
            },
            legal: plc?.type, // as Employee Type
            time: plc?.type_time,
            place: plc?.type_place, // as Working Arrangement
            salary: String(
              getLastSalary(plc?.people_work_salaries)?.value || 0
            ),
            joindate: plc?.join_date,
            startdate: plc?.start_date,
            enddate: plc?.end_date,
            attachment: plc?.attachment || [],
            reminder: plc?.reminder,
          })
        }
        setStatus(plc?.status)
      }
      setData()
    }
  }, [])

  const handleClickOpen = async type => {
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      if (isEdit || isReplacement) {
        const {data: regnoData} = await client.query({
          query: IS_EXIST_REGNO,
          variables: {
            where: {
              regno: {_eq: value.regno},
              company: {_eq: COMPANY_ID},
              user: {_neq: userId},
            },
          },
          fetchPolicy: 'no-cache',
        })
        const countRegnoData =
          regnoData.people_work_placements_aggregate.aggregate.count

        let errorExist = false

        if (value.regno && countRegnoData > 0) {
          if ((isEdit && stgRegno !== value.regno) || isReplacement) {
            const ids = document.getElementById('regno')
            if (ids) {
              ids.scrollIntoView({behavior: 'smooth'})
            }
            setRegnoExist(true)
            errorExist = true
            setError(true)
            setTimeout(() => {
              setError(false)
              setRegnoExist(false)
            }, 3000)
          }
        }
        if (!errorExist && !errorChecker(value, setError, false, 0, 0, 0)) {
          setConfirmType(type)
          setPopup(true)
        }
      } else {
        const {data: emailData} = await client.query({
          query: IS_EXIST_EMAIL,
          variables: {email: value.email},
        })

        const {data: phoneData} = await client.query({
          query: IS_EXIST_PHONE,
          variables: {code: phoneCode, number: value.phone_number},
        })

        const {data: regnoData} = await client.query({
          query: IS_EXIST_REGNO,
          variables: {
            where: {
              regno: {_eq: value.regno},
              company: {_eq: COMPANY_ID},
            },
          },
        })

        const countPhoneData = phoneData.people_phones_aggregate.aggregate.count
        const countRegnoData =
          regnoData.people_work_placements_aggregate.aggregate.count

        if (emailData && emailData.people_emails.length > 0) {
          setEmailExist(true)
          // setErrorUtils('existEmail')
          setError(true)
          setTimeout(() => {
            setError(false)
            setEmailExist(false)
          }, 3000)
        }

        if (value.phone_number && countPhoneData > 0) {
          setPhoneExist(true)
          // setErrorUtils('existPhone')
          setError(true)
          setTimeout(() => {
            setError(false)
            setPhoneExist(false)
          }, 3000)
        }

        if (value.regno && countRegnoData > 0) {
          setRegnoExist(true)
          // setErrorUtils('existRegno')
          setError(true)
          setTimeout(() => {
            setError(false)
            setRegnoExist(false)
          }, 3000)
        }

        if (
          emailData &&
          emailData.people_emails.length === 0 &&
          !errorChecker(
            value,
            setError,
            false,
            emailData.people_emails.length,
            countPhoneData,
            countRegnoData
          ) &&
          countPhoneData === 0 &&
          countRegnoData === 0
        ) {
          setConfirmType(type)
          setPopup(true)
        }
      }
    }
  }

  const handleClickSubmit = async () => {
    const variablesInfo = {
      userId: userId,
      new: {
        name: value.name,
        birthdate:
          (value.birthdate &&
            format(new Date(value.birthdate), 'yyyy-MM-dd')) ||
          null,
        religionId: value.religion,
        email: value.email,
        gender: value.gender,
        primaryAddress: {
          address: value.address,
          province: value.province.value,
          city: value.city.value,
          postal: Number(value.postal_code),
        },
      },
    }
    const variablesPhone = {
      ...(phoneId && {id: phoneId}),
      ...(phoneId && {userId: userId}),
      input: {
        ...(!phoneId && {user: userId}),
        country_code: phoneCode,
        phone: value.phone_number,
        global_phone_code_id: globalPhoneId,
        is_main: true,
      },
    }
    const mutationPhone = phoneId ? updateEmpPhone : insertEmpPhone

    if (isEdit) {
      const variables = {
        id: props.match.params.id,
        jobProfile: value.job_profile.value,
        organization: value.job_profile.organization,
        position: value.job_profile.position,
        rank: value.job_profile.rank,
        office: value.job_profile.office_address,
        type: value.legal,
        time: value.time,
        place: value.place,
        supervisor: value.job_profile.supervisor,
        role: value.job_profile.role,
        join_date: format(new Date(value.joindate), 'yyyy-MM-dd'),
        start_date: format(new Date(value.startdate), 'yyyy-MM-dd'),
        end_date: format(new Date(value.enddate), 'yyyy-MM-dd'),
        regno: value.regno,
        status,
        reference: value.reference,
        pending_activation_edit_status: isPending,
        reminder: parseInt(value.reminder),
        attachment: value.attachment.map(({url, name, size}) => ({
          url,
          name,
          size,
        })),
      }

      if (props.location.pathname.includes('/directory/employee-alumni')) {
        variables.status = 'INACTIVE'
      }
      await mutationPhone({variables: variablesPhone})
      await updateEmpInfo({variables: variablesInfo})
      await updateEmp({variables})
        .then(() => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          props.history.goBack()
        })
        .catch(e => console.error(e))
    } else {
      let prevPwp

      if (isReplacement) {
        await mutationPhone({variables: variablesPhone})
        await updateEmpInfo({variables: variablesInfo})

        const {data} = await client.query({
          query: GET_BANK_PEOPLE_WORK_SALARY,
          variables: {
            company_id: COMPANY_ID,
            user_id: userId,
          },
        })
        prevPwp = data?.v_latest_placements?.[0].people_work_placement
      }

      const variables = {
        ...value,
        email: value.email.toLowerCase(),
        birthdate: value.birthdate
          ? format(new Date(value.birthdate), 'yyyy-MM-dd')
          : null,
        province: value.province.value,
        city: value.city.value,
        job_profile: value.job_profile.value,
        country_code_phone: phoneCode,
        password: null,
        role: null,
        supervisor: null,
        replacement: isReplacement || false,
        joindate: format(new Date(value.joindate), 'yyyy-MM-dd'),
        startdate: format(new Date(value.startdate), 'yyyy-MM-dd'),
        enddate: format(new Date(value.enddate), 'yyyy-MM-dd'),
        reminder: parseInt(value.reminder),
        attachment: value.attachment.map(({url, name, size}) => ({
          url,
          name,
          size,
        })),
      }
      delete variables.salary

      insertEmp({variables})
        .then(res => {
          const prev_end_date = dateSubtract(
            new Date(value.startdate),
            1,
            'days',
            'YYYY-MM-DD'
          )
          const pwpSalaries = prevPwp?.people_work_salaries?.[0]
          const id_prev = pwpSalaries?.id
          if (isReplacement && id_prev) {
            updatePrevPeriodSetting({
              variables: {id_prev, prev_end_date},
            }).catch(e => console.error(e))
          }
          addPeriodSetting({
            variables: {
              object: {
                user_work_id: res.data.addPlacement.id, // new placement id
                primary_bank: pwpSalaries?.people_profile_bank?.id,
                salary_fields: pwpSalaries?.salary_fields,
                value: pwpSalaries?.value,
                currency: pwpSalaries?.currency,
                ktp: pwpSalaries?.ktp,
                npwp: pwpSalaries?.npwp,
                start_date: variables?.startdate,
                end_date: variables?.enddate,
              },
            },
          })
            .then(() => {
              enqueueSnackbar('Success Add data', {
                variant: 'success',
                autoHideDuration: 3000,
              })
              props.history.goBack()
            })
            .catch(e => console.error(e))
        })
        .catch(e => console.error(e))
    }
  }

  const handleChangeInput = (e, target, other) => {
    if (other) {
      setValue(prev => {
        const isResetJoinDate =
          target === 'startdate' &&
          e?.getTime() < new Date(prev.joindate).getTime()
        const isResetEndDate =
          target === 'startdate' &&
          e?.getTime() > new Date(prev.enddate).getTime()

        return {
          ...prev,
          [target]: e,
          ...(isResetJoinDate && {joindate: null}),
          ...(isResetEndDate && {enddate: null}),
        }
      })
    } else {
      setValue({
        ...value,
        [target]: e.target.value,
      })
    }
  }

  const handleSearch = async (e, target) => {
    if (target === 'job_profile') {
      const {data} = await client.query({
        query: GET_JOB_PROFILE_DIRECTORY,
        variables: {
          company: COMPANY_ID,
          search: `%${e}%`,
          limit: 30,
        },
      })
      const result = data
        ? data.company_job_profiles.map(r => {
            return {
              value: r.id,
              label: r.title,
              role: r.roles ? r.roles : '00000010',
              supervisor:
                r.job_profile_supervisor &&
                r.job_profile_supervisor.people_work_placements[0]
                  ? r.job_profile_supervisor.people_work_placements[0].user
                  : null,
              organization: r.organization,
              position: r.position,
              rank: r.rank,
              office_address: r.office_address,
            }
          })
        : []
      return result
    }
  }

  const handleClose = () => {
    setPopup(false)
  }

  const handleDeleteAttachement = i => {
    const newState = [...value.attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValue(e => ({...e, attachment: newState}))
  }

  const handleChangeNameFile = (e, i) => {
    const newState = [...value.attachment]
    newState[i].name = e.target.value
    setValue(e => ({...e, attachment: newState}))
  }

  const _uploadFile = async val => {
    if (!FILE_TYPE_UPLOAD.includes(getTypeFile(val.name))) {
      setErrorAttachment(e => ({...e, fileType: true}))
    } else if (val.size > 1024 * 1024 * 25) {
      setErrorAttachment(e => ({...e, size: true}))
    } else {
      const formData = new FormData()
      formData.append('file', val)
      const _initialUpload = {
        id: val.name + val.size,
        name: val.name,
        size: val.size,
        url: '',
        loaded: 0,
        isUploading: true,
      }
      setValue(e => {
        return {
          ...e,
          attachment: [...(e.attachment || []), _initialUpload],
        }
      })
      await Axios.post(UPLOAD_URL, formData, {
        headers: {
          Authorization: 'Bearer ' + TOKEN,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
        onUploadProgress: progressEvent => {
          setValue(e => {
            const item = {
              id: val.name + val.size,
              name: val.name,
              size: val.size,
              url: '',
              loaded: progressEvent.loaded,
              isUploading: true,
            }
            const items = e.attachment
            const foundIndex = items.findIndex(
              x => x.id === item.id && x.isUploading
            )
            items[foundIndex] = item
            return {...e, attachment: items}
          })
        },
      }).then(res => {
        setValue(e => {
          const item = {
            id: val.name + val.size,
            name: val.name,
            size: val.size,
            url: res.data.url,
            loaded: val.size,
            isUploading: false,
          }
          const items = e.attachment
          const foundIndex = items.findIndex(
            x => x.id === item.id && x.isUploading
          )
          items[foundIndex] = item
          return {...e, attachment: items}
        })
      })
    }
  }

  const handleUploadAttachment = async e => {
    const tempFile = Array.from(e.target.files)
    if (value.attachment.length + tempFile.length > 5) {
      setErrorAttachment(e => ({...e, totalFiles: true}))
    } else {
      tempFile.forEach(val => {
        _uploadFile(val)
      })
    }
    setTimeout(() => {
      setErrorAttachment(e => ({
        ...e,
        fileType: false,
        size: false,
        totalFiles: false,
      }))
    }, 2000)
  }

  if (isEdit && !global) {
    return <LoadingEmployee />
  }

  const confirmName =
    isEdit || isReplacement ? global && global.global_user.name : value.name

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            onClick={props.history.goBack}
            edge="end"
            aria-label="back"
          >
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6">
            {isReplacement
              ? 'Penggantian'
              : isEdit
              ? 'Ubah Penempatan'
              : 'Tambah Pegawai Baru'}
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            color="primary"
            size="large"
            style={{marginRight: '1.6rem'}}
            onClick={() => handleClickOpen('discard')}
          >
            Batal
          </Button>
          <Button
            onClick={() =>
              handleClickOpen(
                isEdit ? 'edit' : isReplacement ? 'replacement' : 'add'
              )
            }
            variant="contained"
            color="primary"
            size="large"
          >
            Kirim
          </Button>
        </div>
      </FormToolbar>
      <Divider />
      {/* {!isEdit && !isReplacement && ( */}
      <>
        <FormContainer>
          {(isEdit || isReplacement) && (
            <InfoEmployeeUpdate>
              <InfoOutlinedIcon className="icon" />
              <h3 className="text">
                {isReplacement &&
                  `Formulir ini hanya digunakan untuk mempromosikan/menurunkan/memutasi pegawai. Untuk memperbaiki kesalahan input, gunakan informasi ubah penempatan`}
                {isEdit &&
                  `Formulir ini hanya digunakan untuk memperbaiki kesalahan input. Untuk mempromosikan/menurunkan/memutasi pegawai, gunakan penggantian`}
              </h3>
            </InfoEmployeeUpdate>
          )}
          <FormChildTitle>
            <TitleText>Informasi Pribadi</TitleText>
          </FormChildTitle>
          <ComponentInput
            id="name"
            number="1"
            name="Nama Lengkap*"
            value={value.name}
            error={error && value.name === ''}
            onChange={e => handleChangeInput(e, 'name')}
            placeholder="Tambahkan Nama Lengkap"
          />
          <FormChildContainer id="gender">
            <FormChildTitle>
              <TitleNumber>2.</TitleNumber>
              <TitleName>Jenis Kelamin*</TitleName>
              {error && value.gender === '' && (
                <Typography
                  variant="caption"
                  color="error"
                  style={{marginLeft: 15}}
                >
                  Bagian ini diperlukan
                </Typography>
              )}
            </FormChildTitle>
            <FormChildInput>
              <RadioGroup
                color="primary"
                style={{display: 'flex', flexDirection: 'row'}}
                aria-label="gender"
                value={value.gender}
                onChange={e => handleChangeInput(e, 'gender')}
              >
                <FormControlLabelStyled
                  value="M"
                  control={<Radio color="primary" />}
                  label="Pria"
                />
                <FormControlLabelStyled
                  value="F"
                  control={<Radio color="primary" />}
                  label="Wanita"
                />
              </RadioGroup>
            </FormChildInput>
          </FormChildContainer>
          <ComponentDate
            id="birthdate"
            number={'3'}
            name="Tanggal Lahir*"
            value={value.birthdate}
            error={error && !value.birthdate}
            maxDate={new Date()}
            onChange={e => handleChangeInput(e, 'birthdate', true)}
            placeholder="Atur tanggal"
          />
          <ComponentInput
            id="email"
            error={
              error &&
              (!value.email || !isEmailAddress(value.email) || isEmailExist)
            }
            number="4"
            name="Email*"
            value={value.email}
            onChange={e => handleChangeInput(e, 'email')}
            helperText={
              !value.email && error
                ? 'Bagian ini diperlukan'
                : !isEmailAddress(value.email) && error
                ? 'Masukkan alamat email yang valid'
                : isEmailExist && error
                ? 'Email sudah ada'
                : ''
            }
            placeholder="Tambahkan email"
          />
          <ComponentInputPhone
            phoneCode={phoneCode}
            setPhoneCode={(code, id) => {
              setPhoneCode(code)
              setGlobalPhoneId(id)
            }}
            id="phone_number"
            number="5"
            name="Nomor Telpon*"
            type="number"
            error={
              error &&
              (value.phone_number === '' || !value.phone_number || isPhoneExist)
            }
            helperText={
              (value.phone_number === '' || !value.phone_number) && error
                ? 'Bagian ini diperlukan'
                : isPhoneExist && error
                ? 'Nomor Telpon sudah ada'
                : ''
            }
            value={value.phone_number}
            onChange={e => handleChangeInput(e, 'phone_number')}
            placeholder="Tambahkan Nomor Telpon"
            inputProps={{min: 0}}
          />
          <ComponentSelect
            id="religion*"
            number="6"
            name="Agama*"
            error={error && value.religion === 'default'}
            onChange={e => handleChangeInput(e, 'religion')}
            value={value.religion}
            child={
              dataReligion &&
              dataReligion.global_religions.map((res, i) => {
                return (
                  <MenuItem key={i} value={res.id}>
                    {capitalize(res.name)}
                  </MenuItem>
                )
              })
            }
            placeholder="Pilih Agama"
          />
          <ComponentInput
            id="address"
            number="7"
            name="Alamat*"
            multiline
            rows={4}
            error={error && value.address === ''}
            value={value.address}
            onChange={e => handleChangeInput(e, 'address')}
            placeholder="Tambahkan Alamat"
          />

          <ComponentRSelect
            id="province"
            number="8"
            name="Provinsi*"
            error={error && (value.province === 'default' || !value.province)}
            onChange={v => {
              setValue({...value, province: v, city: null})
            }}
            value={value.province}
            options={
              dataProvince &&
              dataProvince.global_provinces.map(r => {
                return {
                  value: r.id,
                  label: r.name,
                }
              })
            }
            placeholder="Pilih Provinsi"
          />

          <ComponentRSelect
            id="city"
            number="9"
            name="Kota/Distrik*"
            error={error && (value.city === 'default' || !value.city)}
            onChange={v => handleChangeInput(v, 'city', true)}
            value={value.city}
            options={
              dataCity &&
              dataCity.global_cities.map(r => {
                return {
                  value: r.id,
                  label: r.name,
                }
              })
            }
            placeholder="Pilih Kota/Distrik"
          />

          <ComponentInput
            id="postal_code"
            number="10"
            name="Kode Pos*"
            type="number"
            error={error && !value.postal_code}
            value={value.postal_code}
            onChange={e => handleChangeInput(e, 'postal_code')}
            placeholder="Tambahkan Kode Pos"
            inputProps={{min: 0}}
          />
        </FormContainer>
        <Divider />
      </>
      {/* )} */}
      {/* ============================================== */}

      <FormContainer>
        <FormChildTitle>
          <TitleText>Informasi Pekerjaan</TitleText>
        </FormChildTitle>
        <ComponentInput
          id="regno"
          number={'11'}
          name="NIP*"
          // type={isEdit ? 'text' : 'number'}
          type="text"
          placeholder="Tambahkan NIP"
          error={
            error &&
            (parseInt(value.regno) <= 0 || !value.regno || isRegnoExist)
          }
          helperText={
            (value.regno === '' || !value.regno) && error
              ? 'Bagian ini diperlukan'
              : parseInt(value.regno) <= 0 && error
              ? 'Masukkan NIP yang valid'
              : isRegnoExist && error
              ? 'NIP sudah ada'
              : ''
          }
          value={value.regno}
          onChange={e => handleChangeInput(e, 'regno')}
          // disabled={isEdit}
        />

        <ComponentInput
          id="reference"
          number={'12'}
          name="Nomor SK"
          type="text"
          placeholder="Tambahkan Nomor SK"
          value={value.reference}
          onChange={e => handleChangeInput(e, 'reference')}
          // disabled={isEdit}
        />

        <ComponentRSelect
          id="job_profile"
          number={'13'}
          name="Posisi*"
          isDisabled={isEdit && !isPending}
          error={error && value.job_profile === 'default'}
          onChange={v => handleChangeInput(v, 'job_profile', true)}
          value={value.job_profile}
          isSearchable={true}
          loadOptions={e => handleSearch(e, 'job_profile')}
          placeholder="Pilih Profil Pekerjaan"
          isAsync={true}
        />

        <ComponentSelect
          id="legal"
          number={'14'}
          name="Tipe Pegawai*"
          error={error && value.legal === 'default'}
          value={value.legal}
          onChange={e => handleChangeInput(e, 'legal')}
          child={
            dataLegal &&
            dataLegal.legal_type.map((res, i) => {
              return (
                <MenuItem key={i} value={res.value}>
                  {res.name}
                </MenuItem>
              )
            })
          }
          placeholder="Pilih Tipe Pegawai"
        />
        <ComponentSelect
          id="time"
          number={'15'}
          name="Pengaturan Waktu*"
          error={error && (value.time === 'default' || !value.time)}
          value={value.time}
          onChange={e => handleChangeInput(e, 'time')}
          child={
            dataLegal &&
            dataLegal.legal_time.map((res, i) => {
              return (
                <MenuItem key={i} value={res.value}>
                  {res.name}
                </MenuItem>
              )
            })
          }
          placeholder="Pilih pengaturan waktu"
        />
        <ComponentSelect
          id="place"
          number={'16'}
          name="Pengaturan Kerja*"
          error={error && (value.place === 'default' || !value.place)}
          value={value.place}
          onChange={e => handleChangeInput(e, 'place')}
          child={
            dataLegal &&
            dataLegal.legal_place.map((res, i) => {
              return (
                <MenuItem key={i} value={res.value}>
                  {res.name}
                </MenuItem>
              )
            })
          }
          placeholder="Pilih Pengaturan Kerja"
        />
        <ComponentInput
          id="salary"
          disabled
          number={'17'}
          name="Gaji*"
          style={{width: '40%'}}
          // type="number"
          error={
            (error && value.salary === '') ||
            (error && value.salary === null) ||
            (error && value.salary < 0)
            // (error && !formatatSalary(value.salary))
          }
          value={formatatSalary(value.salary)}
          onChange={e => handleChangeInput(e, 'salary')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" disableTypography>
                <div
                  style={{
                    borderRight: '1px solid rgba(0, 0, 0, 0.27)',
                    paddingRight: 13,
                    display: 'flex',
                    alignItems: 'center',
                    height: 40,
                    marginRight: 6,
                  }}
                >
                  <p style={{fontSize: 14}}>Rp</p>
                </div>
              </InputAdornment>
            ),
          }}
        />
        <ComponentDate
          id="joindate"
          number={'18'}
          name="Tanggal Bergabung*"
          value={value.joindate}
          error={error && !value.joindate}
          onChange={e => handleChangeInput(e, 'joindate', true)}
          placeholder="Atur Tanggal"
          maxDate={value?.startdate}
        />
        <ComponentDate
          id="startdate"
          number={'19'}
          name="Mulai Bekerja*"
          value={value.startdate}
          error={error && !value.startdate}
          onChange={e => handleChangeInput(e, 'startdate', true)}
          placeholder="Atur Tanggal"
        />
        <ComponentDate
          id="enddate"
          number={'20'}
          name="Selesai Bekerja*"
          value={value.enddate}
          error={error && !value.enddate}
          minDate={value.startdate}
          onChange={e => handleChangeInput(e, 'enddate', true)}
          placeholder="Atur Tanggal"
        />
        <ComponentInput
          id="reminder"
          number={'21'}
          name="Pengingat pensiun/kontrak berakhir*"
          type="number"
          placeholder="Tambahkan Pengingat pensiun/kontrak berakhir"
          value={value.reminder}
          onChange={e =>
            e.target.value >= 0 && handleChangeInput(e, 'reminder')
          }
          error={error && !value.reminder}
          textInfo="Hari sebelum tanggal akhir"
          style={{width: 169}}
          styleWrapper={{display: 'flex', alignItems: 'center'}}
          InputProps={{
            inputProps: {
              min: 0,
              style: {textAlign: 'right'},
              pattern: '[0-9]*',
            },
          }}
          onKeyPress={event => {
            const keyCode = event.keyCode || event.which
            const keyValue = String.fromCharCode(keyCode)
            if (/\+|-/.test(keyValue)) event.preventDefault()
          }}
        />
        <AttachementComponent
          number={'22'}
          name="Lampiran"
          disabled={value.attachment.length === 5}
          onUpload={handleUploadAttachment}
        />
        {errorAttachment.fileType && (
          <TextError>Ada jenis lampiran yang tidak diperbolehkan</TextError>
        )}
        {errorAttachment.totalFiles && (
          <TextError>Jumlah lampiran tidak boleh lebih dari 5</TextError>
        )}
        {errorAttachment.size && (
          <TextError>
            Ukuran lampiran yang diunggah tidak boleh melebihi 25MB
          </TextError>
        )}

        <Box display="grid" gridGap={8} width="45%">
          {value &&
            value.attachment.map((res, i) => {
              const {name, size, url, isUploading, loaded} = res

              return (
                <AttachmentItem
                  key={i}
                  name={name}
                  size={size}
                  url={url}
                  onRemove={() => handleDeleteAttachement(i)}
                  onNameChange={e => handleChangeNameFile(e, i)}
                  isUploading={isUploading}
                  progress={loaded}
                />
              )
            })}
        </Box>
      </FormContainer>
      {/* <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={
          isEdit || isReplacement
            ? global && global.global_user.name
            : value.name
        }
        feature="Employee"
        mutation={
          confirmType === 'discard' ? props.history.goBack : handleClickSubmit
        }
      /> */}
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        header={CONFIRMATION[confirmType]?.header.replace(
          '[name]',
          confirmName
        )}
        body={CONFIRMATION[confirmType]?.body.replace('[name]', confirmName)}
        button={CONFIRMATION[confirmType]?.button}
        buttonClose="Batal"
        mutation={
          confirmType === 'discard' ? props.history.goBack : handleClickSubmit
        }
      />
    </PaperContainer>
  )
}

export default withRouter(AddEmployee)

const CONFIRMATION = {
  add: {
    header: 'Tambah [name]?',
    body: 'Apakah Anda yakin ingin menambah Pegawai - [name]?',
    button: 'Tambah',
  },
  edit: {
    header: 'Ubah [name]?',
    body: 'Apakah Anda yakin ingin mengubah Pegawai - [name]?',
    button: 'Ubah',
  },
  replacement: {
    header: 'Penggantian [name]?',
    body: 'Apakah Anda yakin ingin mengganti Pegawai - [name]?',
    button: 'Penggantian',
  },
  discard: {
    header: 'Buang Perubahan?',
    body: 'Apakah Anda yakin ingin membuang perubahan yang belum disimpan?',
    button: 'Buang',
  },
}
