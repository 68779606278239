import {
  Button,
  Checkbox,
  MenuItem,
  Paper,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import {selectProps} from '../../../../../utils/helpers'
import {useMutation} from '@apollo/react-hooks'
import {
  ADD_QUESTION_MICROLEARNING,
  UPDATE_QUESTION_MICROLEARNING,
} from '../../../../../graphql/mutations/learning/micro-learning/addQuestionMicrolearning.mutation'
import helperStyle, {CustomTypography, Flex} from '../../../../../GlobalStyles'

const editorConfiguration = {
  placeholder: 'Tambah konten',
  toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
}

export default function CreateQuestion({
  refetch,
  style,
  setOpenAdd,
  microlearningId,
  selected,
  setSelected,
}) {
  const classes = helperStyle()
  const initialStateAnswer = [
    {
      answer: '',
      is_correct: true,
    },
    {
      answer: '',
      is_correct: false,
    },
    {
      answer: '',
      is_correct: false,
    },
    {
      answer: '',
      is_correct: false,
    },
  ]
  const [question, setQuestion] = useState(selected ? selected.question : '')
  const [questionType, setType] = useState('')
  const [stateAnswer, setAnswer] = useState(initialStateAnswer)

  useEffect(() => {
    // logic untuk fill data edit
    if (selected) {
      // setQuestion(selected.question)
      setType(selected.question_type === 0 ? 'mChoice' : 'mAnswer')
      setAnswer(selected.microlearning_question_answers)
    }
  }, [selected])

  const handleType = e => {
    setType(e.target.value)
    setAnswer(initialStateAnswer)
  }

  const handleMoreAnswer = () => {
    setAnswer([
      ...stateAnswer,
      {
        answer: '',
        is_correct: false,
      },
    ])
  }

  const handleChangeAnswer = (e, i) => {
    stateAnswer[i].answer = e.target.value
    setAnswer([...stateAnswer])
  }

  const handleRadio = newCorrectIndex => {
    const prevCorrectIndex = stateAnswer.findIndex(i => i.is_correct)
    if (prevCorrectIndex !== -1) {
      stateAnswer[prevCorrectIndex].is_correct = false
    }
    stateAnswer[newCorrectIndex].is_correct = true
    setAnswer([...stateAnswer])
  }

  const onCancel = () => {
    setOpenAdd(false)
    setAnswer(initialStateAnswer)
    setSelected(null)
  }

  const handleCheckbox = (e, i) => {
    stateAnswer[i].is_correct = e.target.checked
    setAnswer([...stateAnswer])
  }

  const [mutationAddQuestion] = useMutation(ADD_QUESTION_MICROLEARNING)
  const [mutationEditQuestion] = useMutation(UPDATE_QUESTION_MICROLEARNING)

  const onSave = () => {
    if (selected) {
      const objects = {
        question_id: selected.id,
        question,
        questionType: questionType === 'mChoice' ? 0 : 1,
        answers: stateAnswer.map(res => {
          delete res.__typename
          return {
            ...res,
            microlearning_question_id: selected.id,
          }
        }),
      }

      mutationEditQuestion({
        variables: objects,
      })
        .then(() => {
          onCancel()
          refetch()
        })
        .catch(() => {})
    } else {
      const objects = [
        {
          question,
          microlearning_id: microlearningId,
          question_type: questionType === 'mChoice' ? 0 : 1,
          microlearning_question_answers: {
            data: stateAnswer,
          },
        },
      ]

      mutationAddQuestion({
        variables: {
          objects,
        },
      })
        .then(() => {
          onCancel()
          refetch()
        })
        .catch(() => {})
    }
  }

  const renderAnswer = () => (
    <div className={classes.marginBot8}>
      <Typography variant="body2" className={classes.marginTop16} paragraph>
        Jawaban
      </Typography>

      {questionType === 'mChoice' ? (
        stateAnswer.map((ans, i) => (
          <div style={style.answerContainer} key={i}>
            <Radio
              color="primary"
              checked={ans.is_correct}
              onChange={() => handleRadio(i)}
            />
            <TextField
              fullWidth
              InputProps={{disableUnderline: true}}
              placeholder="Tambah Jawaban"
              value={ans.answer}
              onChange={e => handleChangeAnswer(e, i)}
            ></TextField>
          </div>
        ))
      ) : questionType === 'mAnswer' ? (
        stateAnswer.map((ans, i) => (
          <div color="primary" style={style.answerContainer} key={i}>
            <Checkbox
              color="primary"
              checked={ans.is_correct}
              onChange={e => handleCheckbox(e, i)}
            />
            <TextField
              fullWidth
              InputProps={{disableUnderline: true}}
              placeholder="Tambah Jawaban"
              value={ans.answer}
              onChange={e => handleChangeAnswer(e, i)}
            ></TextField>
          </div>
        ))
      ) : questionType === 'sAnswer' ? (
        <TextField
          variant="outlined"
          multiline
          rows="4"
          fullWidth
          value="this is the answer"
          className="bg-white mb-16"
        />
      ) : null}
      {stateAnswer.length < 5 && (
        <Typography
          variant="body2"
          style={{color: '#039BE5', cursor: 'pointer'}}
          onClick={handleMoreAnswer}
        >
          + Tambah jawaban lain
        </Typography>
      )}
    </div>
  )

  const disabledButton =
    !questionType ||
    !question ||
    stateAnswer.some(s => !s.answer) ||
    stateAnswer.every(s => !s.is_correct)

  return (
    <Paper style={style.expansion} className={classes.padding24}>
      <CustomTypography fweight="bold" bottom="20px" size="16px">
        {selected ? 'Ubah' : 'Tambah'} pertanyaan
      </CustomTypography>
      <div className={`${classes.marginBot20} ${classes.width30P}`}>
        <Typography variant="body2">Jenis Pertanyaan</Typography>

        <TextField
          variant="outlined"
          value={questionType}
          onChange={handleType}
          fullWidth
          margin="normal"
          select
          style={{background: 'white'}}
          SelectProps={selectProps(questionType)}
        >
          <MenuItem value="" disabled>
            Pilih tipe
          </MenuItem>
          <MenuItem value="mChoice">Pilihan ganda</MenuItem>
          <MenuItem value="mAnswer">Jawaban ganda</MenuItem>
          {/* <MenuItem value="sAnswer">Short Answer</MenuItem> */}
        </TextField>
      </div>
      <div className={classes.marginBot20}>
        <Typography variant="body2" className={classes.marginBot16}>
          Pertanyaan
        </Typography>
        <CKEditor
          editor={DecoupledEditor}
          config={editorConfiguration}
          onInit={editor => {
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
              )
          }}
          data={question}
          // eslint-disable-next-line
          onChange={(event, editor) => {
            const data = editor.getData()
            setQuestion(data)
          }}
        />
        <div style={style.customEditorFooter} />
      </div>
      {questionType && renderAnswer()}
      <Flex style={{justifyContent: 'flex-end'}}>
        <Button
          size="large"
          className={classes.marginRight10}
          onClick={onCancel}
        >
          Batal
        </Button>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={disabledButton}
        >
          {selected ? 'Ubah' : 'Tambah'}
        </Button>
      </Flex>
    </Paper>
  )
}
