import gql from 'graphql-tag'

export const DELETE_ORG_LEVEL = gql`
  mutation($id: Int) {
    update_company_employee_ranks(
      _set: {deletedAt: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
