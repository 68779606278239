import gql from 'graphql-tag'

export const ADD_RESPONDENTS_GROUP = gql`
  mutation addRespondentsGroup(
    $data: [multirater_respondent_groups_insert_input!]!
  ) {
    insert_multirater_respondent_groups(objects: $data) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const UPDATE_RESPONDENTS_GROUP = gql`
  mutation($id: bigint, $name: String) {
    update_multirater_respondent_groups(
      _set: {name: $name}
      where: {id: {_eq: $id}}
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const DELETE_RESPONDENTS_GROUP = gql`
  mutation($id: bigint) {
    update_multirater_respondent_groups(
      _set: {deleted_at: "now()"}
      where: {id: {_eq: $id}}
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const DELETE_MULTIPLE_RESPONDENTS_GROUP = gql`
  mutation($id: [bigint!]) {
    update_multirater_respondent_groups(
      _set: {deleted_at: "now()"}
      where: {id: {_in: $id}}
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const DUPLICATE_RESPONDENTS_GROUP = gql`
  mutation($object: [multirater_respondent_groups_insert_input!]!) {
    insert_multirater_respondent_groups(objects: $object) {
      returning {
        id
      }
      affected_rows
    }
  }
`
