import React from 'react'
import styled from 'styled-components'

const PrimitiveDetailsTable = styled.table`
  border-spacing: 20px 24px;
  margin: -24px -20px;

  & th {
    color: ${props => props.theme.palette.grey[700]};
    font-size: 14px;
    min-width: 160px;
    text-align: left;
    vertical-align: top;
  }
`

function DetailsTable(props) {
  const {data, fields, context} = props

  const render = React.useMemo(
    () =>
      fields.map((field, index) =>
        field.hide?.(data, context) ? null : (
          <tr key={index}>
            <th>{field.title}</th>
            <td>{field.render(data, context)}</td>
          </tr>
        )
      ),
    [data, fields, context]
  )

  return (
    <PrimitiveDetailsTable>
      <tbody>{render}</tbody>
    </PrimitiveDetailsTable>
  )
}

export default DetailsTable
