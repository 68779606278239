import React, {useState, useEffect} from 'react'
import helperStyle, {
  PaperContainer,
  FormContainer,
  AddEditTitle,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  ThinTextfield,
  FormToolbar,
  FormTitleContainer,
} from '../../../../GlobalStyles'
import {
  Divider,
  IconButton,
  TextField,
  MenuItem,
  Button,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Link, withRouter} from 'react-router-dom'
import {useMutation} from '@apollo/react-hooks'
import {
  UPDATE_CASECADING_KPI,
  INSERT_CASCADING_KPI,
} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {status_data} from '../../../shared-components/data/SharedData'

const AddEditCasecading = props => {
  const classes = helperStyle()
  const {dataCascading, history} = props
  const {enqueueSnackbar} = useSnackbar()
  const [isEdit, setIsEdit] = useState(false)
  const [allData, setAllData] = useState({
    name: '',
    description: '',
    date_start: null,
    date_end: null,
    status: 'none',
  })

  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  const [mutationAddCascadingKpi] = useMutation(INSERT_CASCADING_KPI)
  const [mutationEditCascadingKpi] = useMutation(
    UPDATE_CASECADING_KPI(dataCascading && dataCascading.id)
  )

  useEffect(() => {
    if (dataCascading) {
      setIsEdit(true)
      setAllData({
        name: dataCascading.name,
        description: dataCascading.description,
        date_start: dataCascading.date_start,
        date_end: dataCascading.date_end,
        status: dataCascading.status,
      })
    }
  }, [isEdit, dataCascading])

  const start = allData && allData.date_start
  const end = allData && allData.date_end

  const mutateScoring = () => {
    if (
      !allData.name ||
      !allData.description ||
      !allData.date_start ||
      start > end ||
      !allData.date_end ||
      allData.status === 'none'
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const data = allData
      if (!isEdit) {
        mutationAddCascadingKpi({variables: {data: data}})
          .then(() => {
            enqueueSnackbar(`Success Add data`, {variant: 'success'})
            history.push({
              pathname: '/performance/kpi/cascading',
              state: {active: 'cascading', searchable: true},
            })
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
            })
          })
      } else {
        mutationEditCascadingKpi({variables: {dataEdited: data}})
          .then(() => {
            enqueueSnackbar(`Success Update data`, {
              variant: 'success',
            })
            history.push({
              pathname: '/performance/kpi/cascading',
              state: {active: 'cascading', searchable: true},
            })
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
            })
          })
      }
    }
  }

  const handleChange = (name, value) => {
    if (name === 'date_end' || name === 'date_start') {
      const dateConvert = moment(value).format('YYYY-MM-DD')
      setAllData({
        ...allData,
        [name]: dateConvert,
      })
    } else {
      setAllData({
        ...allData,
        [name]: value,
      })
    }
  }

  const dateValue = (date, cond) => {
    if (date) {
      return moment(date).format('ll')
    } else {
      return `Pilih Periode ${cond}`
    }
  }

  const action = isEdit ? 'Edit' : 'Add'

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link
            to={{
              pathname: '/performance/kpi/cascading',
              state: {active: 'cascading', searchable: true},
            }}
          >
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6" display="inline">
            {isEdit ? 'Edit' : 'Tambahkan'} Turunan{' '}
            {GLOBAL_GLOSSARY.performance.Goal}
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            size="large"
            component={Link}
            to={{
              pathname: '/performance/kpi/cascading',
              state: {active: 'cascading', searchable: true},
            }}
            style={{marginRight: 12}}
          >
            Batalkan
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="large"
          >
            Simpan
          </Button>
        </div>
      </FormToolbar>
      <Divider></Divider>
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>
              Nama Turunan {GLOBAL_GLOSSARY.performance.Goal} *
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                handleChange('name', event.target.value)
              }}
              variant="outlined"
              value={allData.name}
              error={!allData.name && isError}
              helperText={
                !allData.name && isError ? 'Bagian ini diperlukan' : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer className={classes.width80P}>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Keterangan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                handleChange('description', event.target.value)
              }}
              variant="outlined"
              value={allData.description}
              error={!allData.description && isError}
              helperText={
                !allData.description && isError ? 'Bagian ini diperlukan' : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Periode Mulai *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.height39}
                margin="normal"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                placeholder="Pilih Periode Mulai"
                error={
                  (!allData.date_start && isError) || (start > end && isError)
                }
                helperText={
                  !allData.date_start && isError
                    ? 'Bagian ini diperlukan'
                    : start > end && isError
                    ? 'End Date cannot be before the Start Date'
                    : ''
                }
                value={dateValue(allData.date_start, 'Mulai')}
                onChange={event => {
                  handleChange('date_start', event)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Periode Selesai *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.height39}
                margin="normal"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                placeholder="Pilih Periode Selesai"
                value={dateValue(allData.date_end, 'Selesai')}
                error={
                  (!allData.date_end && isError) || (start > end && isError)
                }
                helperText={
                  !allData.date_end && isError
                    ? 'Bagian ini diperlukan'
                    : start > end && isError
                    ? 'End Date cannot be before the Start Date'
                    : ''
                }
                onChange={event => {
                  handleChange('date_end', event)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Status *</TitleName>
          </FormChildTitle>
          <FormChildInput className={classes.width40P}>
            <ThinTextfield
              select
              fullWidth
              className={
                allData.status === 'none' ? classes.placeholderSelect : ''
              }
              onChange={event => {
                handleChange('status', event.target.value)
              }}
              margin="normal"
              variant="outlined"
              value={allData.status}
              error={allData.status === 'none' && isError}
              helperText={
                allData.status === 'none' && isError
                  ? 'Bagian ini diperlukan'
                  : ''
              }
            >
              <MenuItem className={classes.textGrey} value="none" disabled>
                Pilih Status
              </MenuItem>
              <MenuItem value="ACTIVE">{status_data.ACTIVE}</MenuItem>
              <MenuItem value="INACTIVE">{status_data.INACTIVE}</MenuItem>
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        mutation={mutateScoring}
        header={CONFIRMATION[action]?.header}
        body={CONFIRMATION[action]?.body}
        button={CONFIRMATION[action]?.button}
        buttonClose="Batalkan"
      />
    </PaperContainer>
  )
}

export default withRouter(AddEditCasecading)

const CONFIRMATION = {
  Add: {
    header: `Tambahkan Turunan ${GLOBAL_GLOSSARY.performance.Goal}?`,
    body: `Apakah anda yakin akan menambahkan Turunan ${GLOBAL_GLOSSARY.performance.Goal} ini?`,
    button: 'Tambah',
  },
  Edit: {
    header: `Ubah Turunan ${GLOBAL_GLOSSARY.performance.Goal}?`,
    body: `Apakah anda yakin akan mengubah Turunan ${GLOBAL_GLOSSARY.performance.Goal} ini?`,
    button: 'Ubah',
  },
}
