import RegulationsPage from './RegulationsPageComponent'

export const RegulationsConfig = {
  routes: [
    {
      path: '/company/regulations',
      component: RegulationsPage,
      state: 'list-regulations',
      exact: true,
    },
    {
      path: '/company/edit-regulations',
      component: RegulationsPage,
      state: 'edit-regulations',
      exact: true,
    },
    {
      path: '/company/add-regulations',
      component: RegulationsPage,
      state: 'add-regulations',
      exact: true,
    },
    {
      path: '/company/edit-clause',
      component: RegulationsPage,
      state: 'edit-clause',
      exact: true,
    },
    {
      path: '/company/add-clause',
      component: RegulationsPage,
      state: 'add-clause',
      exact: true,
    },
  ],
}
