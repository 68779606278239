import {useMutation} from '@apollo/react-hooks'
import React, {useEffect, useState} from 'react'
import {
  ADD_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
} from '../../../../../../graphql/mutations'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../DetailEmployeeStyles'
import {SwitchForm, ButtonSubmit} from '../../SharedDetailEmployee'
import FormInput from '../ShareComponents-detail/FormInput'
import {isErrorForm} from '../ShareComponents-detail/helperDetail.jsx'

const INITIAL_VALUES_STATE = {
  user: null,
  is_share: false,
  code: null,
  name: '',
  start_date: undefined,
  end_date: undefined,
  description: null,
  attachments: [],
}

const HELPER_TEXT = 'Bagian ini diperlukan'

export default function AddEditLicenses({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
}) {
  const [values, setValues] = useState(INITIAL_VALUES_STATE)
  const [errorState, setErrorState] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [addService] = useMutation(ADD_SERVICE)
  const [updateService] = useMutation(UPDATE_SERVICE)
  const [deleteService] = useMutation(DELETE_SERVICE)

  useEffect(() => {
    if (actionType === 'edit' && open) {
      setValues(e => ({
        ...e,
        user: userIdDetail,
        is_share: data.is_share,
        code: data.code,
        name: data.name,
        start_date: data.start_date,
        end_date: data.end_date,
        description: data.description,
        attachments: data.attachments,
      }))
    } else {
      setValues(e => ({
        ...e,
        user: userIdDetail,
      }))
    }
  }, [open, actionType])

  const fieldsList = [
    {
      label: 'Kode Perjanjian Layanan*',
      placeholder: 'Tambahkan Kode Perjanjian Layanan ',
      fieldName: 'code',
      type: 'textfield',
      required: true,
      value: values.code,
      error: !values.code,
    },
    {
      type: 'date',
      required: true,
      label: ['Tanggal Mulai*', 'Tanggal Selesai*'],
      // labelDate: 'The certification does not expire',
      fieldName: ['start_date', 'end_date'],
      value: [values.start_date, values.end_date, false, false],
      error: [!values.start_date, !values.end_date],
    },
    {
      label: 'Keterangan*',
      placeholder: 'Tambahkan Keterangan',
      fieldName: 'description',
      type: 'textfield',
      multiline: true,
      required: true,
      rows: 6,
      value: values.description,
      error: !values.description,
    },
    {
      label: 'Lampiran',
      fieldName: 'attachment',
      type: 'attachment',
      attachments: values.attachments,
      error: true,
      multiple: true,
      link: 'url',
    },
  ]
  const handleChange = ({fieldName, value}, target, ekstra) => {
    if (target === 'date') {
      setValues(e => ({
        ...e,
        [ekstra]: value,
      }))
    } else {
      setValues(e => ({
        ...e,
        [fieldName]: value,
      }))
    }
  }

  const handleClose = () => {
    onClose()
    setValues(INITIAL_VALUES_STATE)
  }

  const handleSave = async () => {
    const isError = await isErrorForm(fieldsList)
    if (isError) {
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000)
    } else {
      // const _initalSalary = await changeToNumber(values.initial_salary)
      // const _ilastSalary = await changeToNumber(values.last_salary)
      const object = values
      if (actionType === 'add') {
        addService({
          variables: {object},
        })
          .then(() => {
            refetch()
            enqueueSnackbar('Berhasil menambahkan Perjanjian Layanan', {
              variant: 'success',
              autoHideDuration: 2000,
            })
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar('Gagal menambahkan Perjanjian Layanan', {
              variant: 'error',
              autoHideDuration: 2000,
            })
          })
      } else {
        updateService({
          variables: {
            id: data.id,
            object: object,
          },
        })
          .then(() => {
            refetch()
            enqueueSnackbar('Berhasil mengubah Perjanjian Layanan', {
              variant: 'success',
              autoHideDuration: 2000,
            })
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar('Gagal mengubah Perjanjian Layanan', {
              variant: 'error',
              autoHideDuration: 2000,
            })
          })
      }
    }
  }

  const handleDelete = () => {
    deleteService({
      variables: {
        id: data.id,
        userId: userIdDetail,
      },
    })
      .then(() => {
        refetch()
        setOpenDelete(false)
        enqueueSnackbar('Berhasil menghapus Perjanjian Layanan', {
          variant: 'success',
          autoHideDuration: 2000,
        })
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar('Gagal menghapus Perjanjian Layanan', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleOpenDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  return (
    <>
      <ModalDetail
        onDelete={actionType === 'edit' && handleOpenDelete}
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        title={
          (actionType === 'add'
            ? 'Tambahkan'
            : actionType === 'edit'
            ? 'Ubah'
            : '') + ' Perjanjian Layanan'
        }
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Bagikan dengan jaringan:"
            value={values.is_share}
            labelPlacement="start"
            onChange={e =>
              handleChange({fieldName: 'is_share', value: e.target.checked})
            }
          />
        </div>
        <ModalAddEditWraper>
          <FormInput
            fieldsList={fieldsList}
            open={open}
            setValues={setValues}
            values={values}
            errorState={errorState}
            HELPER_TEXT={HELPER_TEXT}
          />
        </ModalAddEditWraper>
        <ButtonSubmit onCancel={handleClose} onSave={handleSave} />
      </ModalDetail>
      <DeletePopup
        open={openDelete}
        handleClose={handleCloseDelete}
        name="Perjanjian Layanan"
        mutation={handleDelete}
      />
    </>
  )
}
