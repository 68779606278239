import gql from 'graphql-tag'

export const COUNT_JOIN_EMPLOYEE = gql`
  query getAgeTenure($from: String, $to: String) {
    getJoinEmployee(from: $from, to: $to) {
      age1
      age2
      age3
      age4
      total_data_age
      total_age
      avg_age
      exp1
      exp2
      exp3
      exp4
      total_data_exp
      total_exp
      avg_exp
    }
  }
`
export const GET_LINE_CHART_DIRECTORY = gql`
  query getGrafik($from: String, $to: String) {
    getJoinEmployee(from: $from, to: $to) {
      active_employee {
        count
        month
      }
      join_employee {
        count
        month
      }
      turnover {
        count
        month
      }
      alumni {
        count
        month
      }
    }
  }
`
export const GET_DIRECTORY_EMPLOYEE_NEW = gql`
  query($limit: Int, $willJoin: date) {
    total: people_work_placements_aggregate(
      where: {status: {_eq: "ACTIVE"}, start_date: {_gt: $willJoin}}
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        #_or: [
        #     {global_user: {name: {_ilike: $search}}}
        #     {company_job_profile: {title: {_ilike: $search}}}
        #     {regno: {_ilike: $search}}
        #   ]
        status: {_eq: "ACTIVE"}
        start_date: {_gt: $willJoin}
      }
      limit: $limit
      order_by: {global_user: {date_added: desc}}
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
        company_address {
          id
          office_name
          address
          address_field
          global_city {
            id
            name
          }
        }
      }
      status
    }
  }
`
export const GET_DIRECTORY_ALUMNI_NEW = gql`
  query($limit: Int, $willJoin: date) {
    total: people_work_placements_aggregate(
      where: {status: {_neq: "ACTIVE"}, start_date: {_gt: $willJoin}}
    ) {
      aggregate {
        count
      }
    }

    people_work_placements(
      where: {
        # _or: [
        #     {global_user: {name: {_ilike: $search}}}
        #     {company_job_profile: {title: {_ilike: $search}}}
        #     {regno: {_ilike: $search}}
        #   ]
        status: {_neq: "ACTIVE"}
        start_date: {_gt: $willJoin}
      }
      limit: $limit
      order_by: {global_user: {date_added: desc}}
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
        company_address {
          id
          office_name
          address
          address_field
          global_city {
            id
            name
          }
        }
      }
      status
    }
  }
`
