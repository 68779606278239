import React from 'react'
import GoogleMapReact from 'google-map-react'
import {Typography, IconButton} from '@material-ui/core'
import {Close, MapMarker} from 'mdi-material-ui'

const GoogleMap = ({latitude, longitude, photoURL, location, onClose}) => {
  return (
    <div style={{padding: 8}}>
      <div
        style={{
          backgroundColor: '#fff',
          display: 'flex',
        }}
      >
        <div style={{marginRight: 12}}>
          <IconButton
            onClick={onClose}
            size="small"
            style={{position: 'absolute', background: '#eff2f4', margin: 8}}
          >
            <Close></Close>
          </IconButton>
          <img
            src={photoURL}
            alt="absence_image"
            width="300"
            height="350"
            style={{objectFit: 'cover'}}
          />
        </div>

        <div style={{height: 350, width: '17rem'}}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ',
              libraries: 'places',
            }}
            defaultCenter={{lat: latitude, lng: longitude}}
            defaultZoom={16}
          >
            <MapMarker
              style={{color: '#014a62'}}
              lat={latitude}
              lng={longitude}
              title={
                <React.Fragment>
                  address
                  <span />
                </React.Fragment>
              }
            />
          </GoogleMapReact>
        </div>
      </div>
      <div>
        <Typography variant="caption" style={{fontWeight: 700}}>
          Location
        </Typography>
        <p style={{fontSize: 12}}>{location}</p>
      </div>
    </div>
  )
}

export default GoogleMap
