import React, {useState, useEffect} from 'react'
import {
  Button,
  IconButton,
  Divider,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
  FormContainer,
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
  ThinTextfield,
} from '../../../../GlobalStyles'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {withRouter} from 'react-router-dom'
import {
  ADD_COMPETENCY_VERSION,
  EDIT_COMPETENCY_VERSION,
} from '../../../../graphql/mutations'
import {
  COUNT_ACTIVE_DICTIONARY,
  CHECK_CODE_VERSION,
} from '../../../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {COMPANY_ID} from '../../../../utils/globals'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import SubmitDialog from './SubmitDialog'
import {status_data} from '../../../shared-components/data/SharedData'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  w100: {
    width: '100% !important',
  },
}))

const DictionaryFormComponent = props => {
  const classes = useStyles()
  const today = moment().format('YYYY-MM-DD')
  const {enqueueSnackbar} = useSnackbar()
  const data = props?.location?.state?.data
  const {id} = props.match.params

  const [open, setOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState({
    code: '',
    name: '',
    date_start: today,
    date_end: today,
    status: '',
  })

  const [addCompetencyVersion] = useMutation(ADD_COMPETENCY_VERSION)
  const [editCompetencyVersion] = useMutation(EDIT_COMPETENCY_VERSION)

  const {data: activeCount, loading: countLoading} = useQuery(
    COUNT_ACTIVE_DICTIONARY,
    {
      variables: {
        company: COMPANY_ID,
      },
    }
  )

  const {data: isCodeAvailable, refetch: refetchIsCodeAvailable} = useQuery(
    CHECK_CODE_VERSION,
    {
      variables: {company: COMPANY_ID, code: value.code},
    }
  )

  useEffect(() => {
    if (data) {
      const value = {
        code: data.code,
        name: data.name,
        date_start: moment(data.date_start).format('YYYY-MM-DD'),
        date_end: moment(data.date_end).format('YYYY-MM-DD'),
        status: data.status,
      }

      setValue({...value})
    }
  }, [])

  const handleConfirm = type => {
    setOpen(true)
    setConfirmType(type)
  }

  const goBack = () => {
    return props.history.goBack()
  }

  const handleChange = prop => event => {
    const vals = {
      ...value,
      [prop]: event.target.value,
    }

    setValue(vals)
    if (prop === 'code') {
      refetchIsCodeAvailable()
    }
  }

  const handleChangeDate = (date, name) => {
    setValue({
      ...value,
      [name]: moment(date).format('YYYY-MM-DD'),
    })
  }

  const mutationSubmit = () => {
    setIsLoading(true)
    if (id) {
      editCompetencyVersion({variables: {id: id, ...value}})
        .then(() => {
          goBack()
          enqueueSnackbar('Competency Version Updated', {
            variant: 'success',
          })
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      addCompetencyVersion({
        variables: {status: value.status, objects: [value]},
      })
        .then(() => {
          goBack()
          enqueueSnackbar('Competency Version Submited', {
            variant: 'success',
          })
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    setError(false)
  }

  const handleSave = () => {
    if (
      !value.code ||
      !value.name ||
      !value.status ||
      !value.date_start ||
      !value.date_end ||
      !_isCodeAvailable
    ) {
      setError(true)
    } else {
      if (
        value.status === 'ACTIVE' &&
        activeCount?.competency_versions_aggregate?.aggregate?.count > 0
      ) {
        setOpenAlert(true)
      } else {
        handleConfirm('submit')
      }
    }
  }

  let _isCodeAvailable

  if (id && data?.code) {
    _isCodeAvailable =
      value.code === data.code
        ? true
        : isCodeAvailable?.competency_versions_aggregate?.aggregate?.count === 0
  } else {
    _isCodeAvailable =
      isCodeAvailable?.competency_versions_aggregate?.aggregate?.count === 0
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handleConfirm('discard')}
          >
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6">
            {id ? 'Ubah' : 'Tambah'} Kamus
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            className={classes.backButton}
            onClick={() => handleConfirm('discard')}
          >
            Batal
          </Button>
          <Button
            onClick={() => handleSave()}
            // onClick={() => setOpenAlert(true)}
            // onClick={() => handleConfirm('submit')}
            size="large"
            variant="contained"
            color="primary"
            disabled={isLoading || countLoading}
          >
            Kirim
          </Button>
        </div>
      </FormToolbar>
      <Divider />
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Kode Versi Kamus</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              variant="outlined"
              value={value.code}
              onChange={handleChange('code')}
              error={(!value.code && error) || !_isCodeAvailable}
              helperText={
                (!value.code && error && 'Bagian ini diperlukan') ||
                (!_isCodeAvailable && 'Kode Versi sudah digunakan')
              }
              placeholder="Tambah Kode Versi"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Nama Versi Kamus</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              variant="outlined"
              value={value.name}
              onChange={handleChange('name')}
              error={!value.name && error}
              helperText={!value.name && error && 'Bagian ini diperlukan'}
              placeholder="Tambah Nama Versi"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Periode Mulai</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                value={value.date_start}
                onChange={date => handleChangeDate(date, 'date_start')}
                margin="normal"
                className={classes.w100}
                placeholder="Tambah Periode Mulai"
                {...(!value.date_start && error ? {error: true} : {})}
                {...(!value.date_start && error
                  ? {helperText: 'Bagian ini diperlukan'}
                  : {})}
              />
            </MuiPickersUtilsProvider>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Periode Akhir</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                value={value.date_end}
                onChange={date => handleChangeDate(date, 'date_end')}
                margin="normal"
                className={classes.w100}
                minDate={value.date_start}
                placeholder="Tambah Periode Akhir"
                {...(!value.date_end && error ? {error: true} : {})}
                {...(!value.date_end && error
                  ? {helperText: 'Bagian ini diperlukan'}
                  : {})}
              />
            </MuiPickersUtilsProvider>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Status</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              select
              margin="normal"
              variant="outlined"
              value={value.status}
              onChange={handleChange('status')}
              error={!value.status && error}
              helperText={!value.status && error && 'Bagian ini diperlukan'}
            >
              <MenuItem value={'ACTIVE'}>{status_data.ACTIVE}</MenuItem>
              <MenuItem value={'INACTIVE'}>{status_data.INACTIVE}</MenuItem>
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>

      <AddEdit
        open={open}
        handleClose={() => setOpen(false)}
        mutation={confirmType === 'discard' ? goBack : mutationSubmit}
        header={CONFIRMATION[confirmType]?.header}
        body={CONFIRMATION[confirmType]?.body}
        button={CONFIRMATION[confirmType]?.button}
        buttonClose="Batal"
        disabled={isLoading}
      />

      <SubmitDialog
        open={openAlert}
        handleClose={() => setOpenAlert(false)}
        edit={id}
        mutation={mutationSubmit}
      />
    </PaperContainer>
  )
}

export default withRouter(DictionaryFormComponent)

const CONFIRMATION = {
  submit: {
    header: 'Kirim Kamus?',
    body: 'Apakah Anda yakin akan mengirimkan Kamus ini?',
    button: 'Kirim',
  },
  discard: {
    header: 'Buang Perubahan?',
    body: 'Apakah kamu yakin ingin membuang perubahan yang belum disimpan?',
    button: 'Buang',
  },
}
