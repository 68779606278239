import React, {useEffect, useState} from 'react'
import {useMutation, useQuery} from '@apollo/react-hooks'

import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../DetailEmployeeStyles'
import {SwitchForm, ButtonSubmit} from '../../SharedDetailEmployee'
import {isErrorForm} from '../ShareComponents-detail/helperDetail.jsx'
import FormInput from '../ShareComponents-detail/FormInput'

import {
  ADD_SKILL,
  DELETE_SKILL,
  UPDATE_SKILL,
} from '../../../../../../graphql/mutations'
import {
  GET_LIST_SKILL,
  GET_LIST_SKILL_LEVEL,
} from '../../../../../../graphql/queries'
import {capitalize} from '../../../../../../utils/helpers'

const INITIAL_STATE = {
  skill: null,
  level: null,
  is_share: false,
}

const INITIAL_SEARCH_STATE = {
  search_skill: '',
}

export default function AddEditSkill({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
}) {
  const [formData, setFormData] = useState(INITIAL_STATE)
  const [errorState, setErrorState] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [search, setSearch] = useState(INITIAL_SEARCH_STATE)
  const [addSkill] = useMutation(ADD_SKILL)
  const [updateSkill] = useMutation(UPDATE_SKILL)
  const [deleteSkill] = useMutation(DELETE_SKILL)

  const {data: dataSkill, loading: loadingSkill} = useQuery(GET_LIST_SKILL, {
    wlb_skipPatch: true,
    variables: {
      search: `%${search.search_skill}%`,
      limit: 10,
    },
    skip: !open && actionType !== 'edit',
  })

  const {data: dataLevel, loading: loadingLevel} = useQuery(
    GET_LIST_SKILL_LEVEL,
    {
      wlb_skipPatch: true,
      skip: !open && actionType !== 'edit',
    }
  )

  useEffect(() => {
    if (actionType === 'edit' && open) {
      setFormData({
        is_share: data.is_share,
      })
    } else {
      setFormData(e => ({
        ...e,
        user: userIdDetail,
      }))
    }
  }, [open, actionType])

  const handleInputSearchChange = field => query => {
    setSearch({...search, [field]: query})
  }

  const handleAutocompleteChange = item => {
    const {fieldName, label, value} = item
    const selectedOption = {label, value}

    setFormData({
      ...formData,
      [fieldName]: selectedOption,
    })
  }

  const saveData = async () => {
    const isError = await isErrorForm(fieldsList)
    if (isError) {
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000)
    } else {
      if (actionType === 'add') {
        const variables = {
          skill: formData.skill?.value,
          level: formData.level?.value,
          is_share: formData.is_share,
          deletedAt: null,
          user: userIdDetail,
        }
        addSkill({
          variables: {objects: [variables]},
        })
          .then(() => {
            refetch()
            closeModal()
            enqueueSnackbar('Menambahkan Keahlian Berhasil', {
              variant: 'success',
              autoHideDuration: 2000,
            })
          })
          .catch(() => {
            enqueueSnackbar(
              'Menambahkan Keahlian Gagal, silahkan coba lagi nanti',
              {
                variant: 'error',
                autoHideDuration: 2000,
              }
            )
          })
      } else {
        updateSkill({
          variables: {
            id: data.id,
            object: {...formData},
          },
        })
          .then(() => {
            refetch()
            closeModal()
            enqueueSnackbar('Mengubah Keahlian Berhasil', {
              variant: 'success',
              autoHideDuration: 2000,
            })
          })
          .catch(() => {
            enqueueSnackbar(
              'Mengubah Keahlian gagal, silahkan coba lagi nanti',
              {
                variant: 'error',
                autoHideDuration: 2000,
              }
            )
          })
      }
    }
  }

  const deleteData = () => {
    deleteSkill({
      variables: {
        id: data.id,
      },
    })
      .then(() => {
        refetch()
        closeModal()
        setOpenDelete(false)
        enqueueSnackbar('Menghapus Keahlian Berhasil', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Menghapus Keahlian Gagal, silahkan coba lagi nanti', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }
  const openModalDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }
  const closeModalDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  const closeModal = () => {
    onClose()
    setFormData(INITIAL_STATE)
  }

  const fieldsList = [
    {
      label: 'Keahlian*',
      placeholder: 'Pilih keahlian',
      fieldName: 'skill',
      type: 'autocomplete',
      required: actionType !== 'edit',
      value: formData?.skill,
      error: !formData?.skill,
      isLoading: loadingSkill,
      onChange: handleAutocompleteChange,
      onInputChange: handleInputSearchChange('search_skill'),
      option: dataSkill?.skills.map(item => ({
        value: item.id,
        label: item.name,
      })),
    },
    {
      label: 'Tingkat Keahlian*',
      placeholder: 'Pilih tingkat keahlian',
      fieldName: 'level',
      type: 'autocomplete',
      required: actionType !== 'edit',
      value: formData?.level,
      error: !formData?.level,
      isLoading: loadingLevel,
      onChange: handleAutocompleteChange,
      option: dataLevel?.levels.map(item => ({
        value: item.id,
        label: capitalize(item.name),
      })),
    },
  ]

  return (
    <>
      <ModalDetail
        onDelete={actionType === 'edit' && openModalDelete}
        onClose={closeModal}
        open={open}
        maxWidth="sm"
        title={`${actionType === 'add' ? 'Tambahkan' : 'Ubah'} Keahlian`}
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Berbagi dengan jaringan:"
            value={formData.is_share}
            labelPlacement="start"
            onChange={e =>
              setFormData({
                ...formData,
                is_share: e.target.checked,
              })
            }
          />
        </div>
        {actionType !== 'edit' && (
          <ModalAddEditWraper>
            <FormInput
              fieldsList={fieldsList}
              open={open}
              setValues={setFormData}
              values={formData}
              errorState={errorState}
            />
          </ModalAddEditWraper>
        )}
        <ButtonSubmit onCancel={closeModal} onSave={saveData} />
      </ModalDetail>

      {actionType === 'edit' && (
        <DeletePopup
          open={openDelete}
          handleClose={closeModalDelete}
          name={`${data?.people_skill?.name} skill`}
          mutation={deleteData}
        />
      )}
    </>
  )
}
