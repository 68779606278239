import gql from "graphql-tag";

export const GET_TOTAL_QUESTION = gql`
  query detailSurvey($survey: bigint) {
    total_question: multirater_survey_questions_aggregate(
      where: { multirater_survey_group: { survey: { _eq: $survey } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_LIST_SECOND_SUPERVISOR_ASESI = gql`
  query listSecondSupervisorSurvey(
    $user: uuid
    $survey: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        global_user: { name: { _ilike: $search } }
        company_job_profile: {
          job_profile_subordinate: {
            job_profile_subordinate: {
              people_work_placements: {
                status: { _eq: "ACTIVE" }
                user: { _eq: $user }
              }
            }
          }
        }
        status: { _eq: "ACTIVE" }
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        global_user: { name: { _ilike: $search } }
        company_job_profile: {
          job_profile_subordinate: {
            job_profile_subordinate: {
              people_work_placements: {
                status: { _eq: "ACTIVE" }
                user: { _eq: $user }
              }
            }
          }
        }
        status: { _eq: "ACTIVE" }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      company_job_profile {
        id
        code
        title
      }
      global_user {
        id
        name
        avatar
        multirater_responses_from_aggregate(
          where: { respond_to: { _eq: $user }, survey: { _eq: $survey } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const GET_LIST_SUPERVISOR_ASESI = gql`
  query listSupervisorSurvey(
    $user: uuid
    $survey: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        global_user: { name: { _ilike: $search } }
        company_job_profile: {
          job_profile_subordinate: {
            people_work_placements: {
              status: { _eq: "ACTIVE" }
              user: { _eq: $user }
            }
          }
        }
        status: { _eq: "ACTIVE" }
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        global_user: { name: { _ilike: $search } }
        company_job_profile: {
          job_profile_subordinate: {
            people_work_placements: {
              status: { _eq: "ACTIVE" }
              user: { _eq: $user }
            }
          }
        }
        status: { _eq: "ACTIVE" }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      company_job_profile {
        id
        code
        title
      }
      global_user {
        id
        name
        avatar
        multirater_responses_from_aggregate(
          where: { respond_to: { _eq: $user }, survey: { _eq: $survey } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const GET_LIST_PEER_ASESI = gql`
  query listPeerSurvey(
    $user: uuid
    $survey: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        global_user: { name: { _ilike: $search } }
        company_job_profile: {
          job_profile_supervisor: {
            job_profile_subordinate: {
              people_work_placements: {
                status: { _eq: "ACTIVE" }
                user: { _eq: $user }
              }
            }
          }
        }
        user: { _neq: $user }
        status: { _eq: "ACTIVE" }
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        global_user: { name: { _ilike: $search } }
        company_job_profile: {
          job_profile_supervisor: {
            job_profile_subordinate: {
              people_work_placements: {
                status: { _eq: "ACTIVE" }
                user: { _eq: $user }
              }
            }
          }
        }
        user: { _neq: $user }
        status: { _eq: "ACTIVE" }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      company_job_profile {
        id
        code
        title
      }
      global_user {
        id
        name
        avatar
        multirater_responses_from_aggregate(
          where: { respond_to: { _eq: $user }, survey: { _eq: $survey } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const GET_LIST_SECOND_SUBORDINATE_ASESI = gql`
  query listSecondSubordinateSurvey(
    $user: uuid
    $survey: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        global_user: { name: { _ilike: $search } }
        company_job_profile: {
          job_profile_supervisor: {
            job_profile_supervisor: {
              people_work_placements: {
                status: { _eq: "ACTIVE" }
                user: { _eq: $user }
              }
            }
          }
        }
        status: { _eq: "ACTIVE" }
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        global_user: { name: { _ilike: $search } }
        company_job_profile: {
          job_profile_supervisor: {
            job_profile_supervisor: {
              people_work_placements: {
                status: { _eq: "ACTIVE" }
                user: { _eq: $user }
              }
            }
          }
        }
        status: { _eq: "ACTIVE" }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      company_job_profile {
        id
        code
        title
      }
      global_user {
        id
        name
        avatar
        multirater_responses_from_aggregate(
          where: { respond_to: { _eq: $user }, survey: { _eq: $survey } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`

export const GET_LIST_SUBORDINATE_ASESI = gql`
  query listSubordinateSurvey(
    $user: uuid
    $survey: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        global_user: { name: { _ilike: $search } }
        company_job_profile: {
          job_profile_supervisor: {
            people_work_placements: {
              status: { _eq: "ACTIVE" }
              user: { _eq: $user }
            }
          }
        }
        status: { _eq: "ACTIVE" }
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        global_user: { name: { _ilike: $search } }
        company_job_profile: {
          job_profile_supervisor: {
            people_work_placements: {
              status: { _eq: "ACTIVE" }
              user: { _eq: $user }
            }
          }
        }
        status: { _eq: "ACTIVE" }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      company_job_profile {
        id
        code
        title
      }
      global_user {
        id
        name
        avatar
        multirater_responses_from_aggregate(
          where: { respond_to: { _eq: $user }, survey: { _eq: $survey } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`