import React, {useState} from 'react'
import {
  makeStyles,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  MenuItem,
  TextField,
  Icon,
} from '@material-ui/core'
import {
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
  StyledReactSelect,
  GreyTypography,
  MediumAvatar,
  FlexBetween,
  BlueIcon,
} from '../../../../../GlobalStyles'

import CKEditor from '@ckeditor/ckeditor5-react'

import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import {GET_LIST_COMMITEE_CANDIDATE} from '../../../../../graphql/queries'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  title: {
    margin: '1.2rem 0',
  },

  expansionContainer: {
    padding: '.8rem 0',
    borderRadius: 0,
    background: '#f7f8f9',
    marginTop: '1.6rem',
    width: '85%',
    '&:before': {
      display: 'none',
    },
  },
  bgWhite: {background: '#fff'},
  ml12: {
    marginLeft: '1.2rem',
  },
  icRed: {
    color: '#ef4d5e',
  },
  customEditorFooter: {
    width: '100%',
    height: 20,
    borderRadius: '0 0 5px 5px',
    backgroundColor: '#014a62',
  },
  m0: {margin: 0},

  list: {display: 'flex', margin: 16, alignItems: 'center'},
  spacer: {marginRight: 14},
  iconMask: {
    background: '#039be5',
    color: 'white',
    borderRadius: 5,
    height: 23,
  },
}))

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
}

const StepFourTalent = ({
  values,
  setValues,
  candidate,
  employee,
  setEmployee,
  isError,
  xCheck,
  isTalent,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const [search, setSearch] = useState('')
  const [searchDropdown, setSearchDropdown] = useState('')

  const handleChange = panel => (event, isExpanded) => {
    event.preventDefault()
    setExpanded(isExpanded ? panel : false)
  }

  const {data, loading} = useQuery(GET_LIST_COMMITEE_CANDIDATE, {
    variables: {
      search: `%${searchDropdown}%`,
      limit: 30,
    },
  })

  const handleChangeCommittee = (val, parent, type) => {
    const dataCandidate = [...values.talent_candidate_groups.data]
    const index = dataCandidate.findIndex(x => x.job_profile === parent)

    if (type === 'pic') {
      const dataCommittee = dataCandidate[index].talent_committees.data
      const indexCommittee = dataCommittee.findIndex(
        x => x.committee === val.target.value
      )

      const indexCommitteePic = dataCommittee.findIndex(x => x.is_pic)

      dataCommittee[indexCommittee] = {
        ...dataCommittee[indexCommittee],
        is_pic: true,
      }
      if (indexCommitteePic !== -1) {
        dataCommittee[indexCommitteePic] = {
          ...dataCommittee[indexCommitteePic],
          is_pic: false,
        }
      }

      dataCandidate[index] = {
        ...dataCandidate[index],
        talent_committees: {data: dataCommittee},
      }
    } else if (type === 'name') {
      const newData =
        val &&
        val.map(res => {
          return {
            committee: res.label,
            status: 'ACTIVE',
            is_pic: false,
          }
        })
      dataCandidate[index] = {
        ...dataCandidate[index],
        talent_committees: {data: newData ? [...newData] : []},
      }

      employee[index] = val
      setEmployee(employee)
    } else {
      if (type === 'committee_date_end' || type === 'committee_date_start') {
        val = moment(val).format('YYYY-MM-DD')
      }
      dataCandidate[index] = {
        ...dataCandidate[index],
        [type]: val,
      }
    }
    setValues({
      ...values,
      talent_candidate_groups: {data: dataCandidate},
    })
  }

  const empMenu = option => {
    return (
      <div className={classes.list}>
        <div className={classes.spacer}>
          <MediumAvatar src={option.avatar} alt={option.value}></MediumAvatar>
        </div>
        <div style={{width: '100%'}}>
          <Typography variant="body1">{option.value}</Typography>
          <GreyTypography variant="body2" gutterBottom>
            {option.jobTitle}
          </GreyTypography>
        </div>
        <div className={classes.iconMask}>
          <Icon>add</Icon>
        </div>
      </div>
    )
  }

  const filteredCandidate = candidate.filter(
    item => item.label.toLowerCase().indexOf(search.toLowerCase()) >= 0
  )

  return (
    <>
      <FlexBetween>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="body1">
            Silakan klik posisi untuk menetapkan komite
          </Typography>
          {isError && (
            <Typography variant="caption" style={{color: 'red', marginLeft: 6}}>
              Silakan isi semua bidang dan Silakan periksa tanggal mulai,
              tanggal akhirPlease fill all fields and Please check start date,
              end date
            </Typography>
          )}
        </div>
        <TextField
          placeholder="Cari"
          variant="outlined"
          InputProps={{
            endAdornment: <BlueIcon position="end">search</BlueIcon>,
          }}
          value={search}
          size="small"
          onChange={e => setSearch(e.target.value)}
        />
      </FlexBetween>
      {candidate &&
        filteredCandidate.map((res, i) => {
          const dataEmp =
            data &&
            data.people_work_placements.map(resEmp => {
              return {
                label: resEmp.global_user.id,
                value: resEmp.global_user.name,
                avatar: resEmp.global_user.avatar,
                job_profile: res.value,
                jobTitle:
                  resEmp.company_job_profile &&
                  resEmp.company_job_profile.title,
              }
            })

          const filterPic =
            values.talent_candidate_groups.data[i].talent_committees &&
            values.talent_candidate_groups.data[
              i
            ].talent_committees.data.filter(v => v.is_pic)

          return (
            <ExpansionPanel
              classes={{root: classes.expansionContainer}}
              elevation={0}
              key={i}
              expanded={expanded === 'panel' + i}
              onChange={handleChange('panel' + i)}
              style={{
                border:
                  isError &&
                  xCheck(values.talent_candidate_groups.data[i]) &&
                  '1px solid red',
              }}
            >
              <ExpansionPanelSummary>
                <Typography>{res.label}</Typography>
              </ExpansionPanelSummary>
              {expanded === 'panel' + i && (
                <ExpansionPanelDetails style={{display: 'block'}}>
                  <FormChildContainer style={{width: '100%'}}>
                    <FormChildTitle style={{marginBottom: 12}}>
                      <TitleNumber>1.</TitleNumber>
                      <TitleNumber>Nama Komite</TitleNumber>
                      {!employee[i] && isError && (
                        <Typography
                          variant="caption"
                          style={{color: 'red', marginLeft: 6}}
                        >
                          Bagian ini diperlukan
                        </Typography>
                      )}
                    </FormChildTitle>
                    <FormChildInput>
                      <StyledReactSelect
                        value={employee.length > 0 && employee[i]}
                        options={dataEmp}
                        isMulti
                        onChange={val =>
                          handleChangeCommittee(val, res.value, 'name')
                        }
                        getOptionLabel={empMenu}
                        placeholder=""
                        isLoading={loading}
                        onInputChange={d => setSearchDropdown(d)}
                        backspaceRemovesValue={false}
                      />
                    </FormChildInput>
                  </FormChildContainer>
                  <FormChildContainer>
                    <FormChildTitle>
                      <TitleNumber>2.</TitleNumber>
                      <TitleName>PIC</TitleName>
                    </FormChildTitle>
                    <FormChildInput>
                      <TextField
                        select
                        value={
                          filterPic
                            ? filterPic[0] && filterPic[0].committee
                            : ''
                        }
                        error={filterPic && filterPic.length === 0 && isError}
                        helperText={
                          filterPic && filterPic.length === 0 && isError
                            ? 'Bagian ini diperlukan'
                            : ''
                        }
                        size="small"
                        variant="outlined"
                        margin="normal"
                        className={classes.bgWhite}
                        fullWidth
                        onChange={val =>
                          handleChangeCommittee(
                            val,
                            res.value,
                            'pic',
                            filterPic
                          )
                        }
                      >
                        {employee.length > 0 && employee[i] ? (
                          employee[i].map((res, i) => {
                            return (
                              <MenuItem key={i} value={res.label}>
                                {res.value}
                              </MenuItem>
                            )
                          })
                        ) : (
                          <MenuItem disabled>
                            Silahkan pilih komite terlebih dahulu
                          </MenuItem>
                        )}
                      </TextField>
                    </FormChildInput>
                  </FormChildContainer>

                  {isTalent && (
                    <FormChildContainer style={{width: '100%'}}>
                      <FormChildTitle style={{marginBottom: '1.2rem'}}>
                        <TitleNumber>3.</TitleNumber>
                        <TitleName>Pemberitahuan Undangan Komite</TitleName>
                      </FormChildTitle>
                      <FormChildInput>
                        <CKEditor
                          editor={DecoupledEditor}
                          config={editorConfiguration}
                          onInit={editor => {
                            editor.ui
                              .getEditableElement()
                              .parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                              )
                          }}
                          data={
                            values.talent_candidate_groups.data[i]
                              .committee_email_notif_template
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            handleChangeCommittee(
                              data,
                              res.value,
                              'committee_email_notif_template'
                            )
                          }}
                        />
                        <div className={classes.customEditorFooter}></div>
                        {!values.talent_candidate_groups.data[i]
                          .committee_email_notif_template &&
                          isError && (
                            <Typography
                              variant="caption"
                              style={{color: 'red', marginLeft: 6}}
                            >
                              Bagian ini diperlukan
                            </Typography>
                          )}
                      </FormChildInput>
                    </FormChildContainer>
                  )}
                  <FormChildContainer>
                    <FormChildTitle>
                      <TitleNumber>{isTalent ? '4' : '3'}.</TitleNumber>
                      <TitleName>Tanggal Mulai</TitleName>
                    </FormChildTitle>
                    <FormChildInput>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          value={
                            values.talent_candidate_groups.data[i]
                              .committee_date_start
                          }
                          margin="normal"
                          onChange={date =>
                            handleChangeCommittee(
                              date,
                              res.value,
                              'committee_date_start'
                            )
                          }
                          className={classes.bgWhite}
                          size="small"
                          InputAdornmentProps={{
                            classes: {
                              root: classes.m0,
                            },
                          }}
                          minDate={values.start_date}
                          maxDate={values.end_date}
                        />
                      </MuiPickersUtilsProvider>
                    </FormChildInput>
                  </FormChildContainer>
                  <FormChildContainer>
                    <FormChildTitle>
                      <TitleNumber>{isTalent ? '5' : '4'}.</TitleNumber>
                      <TitleName>Tanggal Selesai</TitleName>
                    </FormChildTitle>
                    <FormChildInput>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          value={
                            values.talent_candidate_groups.data[i]
                              .committee_date_end
                          }
                          margin="normal"
                          onChange={date =>
                            handleChangeCommittee(
                              date,
                              res.value,
                              'committee_date_end'
                            )
                          }
                          className={classes.bgWhite}
                          size="small"
                          InputAdornmentProps={{
                            classes: {
                              root: classes.m0,
                            },
                          }}
                          minDate={
                            values.talent_candidate_groups.data[i]
                              .committee_date_start
                          }
                          maxDate={values.end_date}
                        />
                      </MuiPickersUtilsProvider>
                    </FormChildInput>
                  </FormChildContainer>
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>
          )
        })}
    </>
  )
}

export default withRouter(StepFourTalent)
