import gql from 'graphql-tag'

export const GET_GENERAL_SETTING = gql`
  query($company: uuid!) {
    finance_payroll_settings_by_pk(id: $company) {
      #schedule page
      pay_date
      cut_off_date
      leave_unpaid_eval
      leave_unpaid_formula
      leave_unpaid_days
      employee_irregular_start_salary
      first_approval_type
      first_approval_job_profile
      second_approval_job_profile
      third_approval_job_profile
    }
  }
`

export const GET_JOB_PROFILE = gql`
  query($company: uuid!) {
    company_job_profiles(
      order_by: {title: asc}
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      id
      code
      title
      job_icon
    }
  }
`

export const LIST_EXCLUSION_EMPLOYEE = gql`
  query ExclusionEmployeeList(
    $company: uuid!
    $limit: Int
    $offset: Int
    $search: String
  ) {
    total: finance_exclude_payroll_aggregate(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
    filter_assignee: finance_exclude_payroll(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      id
      user
      company
    }
    exclude_people: finance_exclude_payroll(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        _or: [{global_user: {name: {_ilike: $search}}}]
      }

      limit: $limit
      offset: $offset
    ) {
      id
      user
      company
      global_user {
        id
        name
        avatar
        people_work_placements {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`

export const LIST_FILTER_EMPLOYEE = gql`
  query EmployeeFilterList(
    $company: uuid
    $search: String
    $excludeUser: [uuid!]
    $limit: Int
    $offset: Int
  ) {
    total: global_users_aggregate(
      where: {
        name: {_ilike: $search}
        people_work_placements: {
          company: {_eq: $company}
          user: {_nin: $excludeUser}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    suggested_people: global_users(
      where: {
        name: {_ilike: $search}
        people_work_placements: {
          company: {_eq: $company}
          user: {_nin: $excludeUser}
        }
      }
      limit: $limit
      offset: $offset
    ) {
      id
      name
      avatar
      people_work_placements {
        id
        regno
        company_job_profile {
          id
          title
        }
      }
    }
  }
`

export const LIST_SELECTED_EMP = gql`
  query SelectedEmployeeList(
    $company: uuid
    $search: String
    $include: [uuid!]
    $limit: Int
    $offset: Int
  ) {
    total: global_users_aggregate(
      where: {
        name: {_ilike: $search}
        people_work_placements: {
          company: {_eq: $company}
          user: {_in: $include}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    suggested_people: global_users(
      where: {
        name: {_ilike: $search}
        people_work_placements: {
          company: {_eq: $company}
          user: {_in: $include}
        }
      }
      limit: $limit
      offset: $offset
    ) {
      id
      name
      avatar
      people_work_placements {
        id
        regno
        company_job_profile {
          id
          title
        }
      }
    }
  }
`
