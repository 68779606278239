import {Divider, Grid, Icon, Button} from '@material-ui/core'
import React from 'react'

const BoxesDiagram = props => {
  const {
    setOpen,
    setValues,
    values,
    mainValues,
    isReset = false,
    resetFunction,
  } = props
  const {selectedX, selectedY} = props.values ? props.values : props

  return (
    <>
      <div
        style={{
          marginTop: -16,
          height: `${mainValues.box_height * 125 +
            5 * mainValues.box_height}px`,
        }}
      >
        <Icon style={{height: 15}}>arrow_drop_up</Icon>
        <Divider
          orientation="vertical"
          style={{
            marginLeft: 11,
            background: '#707070',
            width: 2,
          }}
        ></Divider>
      </div>
      <Grid container item>
        <Grid container item xs={12}>
          {mainValues.talent_boxes.data.map((res, ids) => {
            const handleClickBox = (x, y, res) => {
              setOpen(true)
              if (values) {
                if (props.open) {
                  setOpen(false)
                  setTimeout(() => {
                    setOpen(true)
                  }, 100)
                }
                setValues({
                  ...values,
                  selectedX: x,
                  selectedY: y,
                })
              } else {
                props.handleRes(res)
              }
            }

            return (
              <Grid
                item
                key={ids}
                style={{width: `${100 / mainValues.box_width}%`}}
              >
                <div
                  style={{
                    border: `2px solid ${
                      selectedX === res.index_x && selectedY === res.index_y
                        ? '#039be5'
                        : 'white'
                    }`,
                    background: res.color_hex ? res.color_hex : '#a9a8a8',
                    height: 125,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => handleClickBox(res.index_x, res.index_y, res)}
                >
                  {res.badge && (
                    <img
                      src={res.badge}
                      alt={res.badge}
                      style={{
                        width: `${
                          parseInt(mainValues.box_width) === 1 ||
                          parseInt(mainValues.box_width) === 2
                            ? 4
                            : 12 / mainValues.box_width
                        }vw`,
                      }}
                    ></img>
                  )}
                </div>
              </Grid>
            )
          })}
        </Grid>
        <div style={{display: 'flex', width: '100%'}}>
          <Divider
            style={{
              background: '#707070',
              height: 2,
              marginTop: 11,
              width: '100%',
            }}
          ></Divider>
          <Icon>arrow_right</Icon>
        </div>
        {isReset && (
          <Button
            variant="outlined"
            style={{
              border: '1px solid #dd5962',
              color: '#dd5962',
              width: '100%',
              marginTop: 15,
            }}
            size="large"
            color="primary"
            onClick={resetFunction}
          >
            Atur Ulang
          </Button>
        )}
      </Grid>
    </>
  )
}

export default BoxesDiagram
