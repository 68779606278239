import gql from 'graphql-tag'

export const GET_LIST_CASH_ADVANCE_POLICY = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: finance_cash_advance_policies_aggregate(
      where: {name: {_ilike: $search}}
    ) {
      aggregate {
        count
      }
    }

    finance_cash_advance_policies(
      where: {name: {_ilike: $search}}
      offset: $offset
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`
