import {Avatar, Divider, Typography} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useEffect} from 'react'
import {
  Flex,
  PaperContainer,
  EnhancedToolbar,
  TextBlackBold,
} from '../../../../GlobalStyles'
import {
  NewTableOptions,
  newTableStyles,
} from '../../../shared-components/table/TableOptions'
import helperStyle, {UserWrapper, ProfileText} from '../LearningProfileStyle'
import {useQuery} from '@apollo/react-hooks'
import Loading from '../../../../components/loading/LoadingComponent'
import {holdingRolesContext} from '../../../../utils/helpers'
import {GET_LIST_HOLDING_TALENT} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import {withRouter} from 'react-router-dom'

const ListComponent = props => {
  const {searchText} = props
  const classes = helperStyle()
  const tableClasses = newTableStyles()

  const {data, error, refetch, loading} = useQuery(GET_LIST_HOLDING_TALENT, {
    variables: {companyHead: COMPANY_ID, search: `%${searchText}%`},
    ...holdingRolesContext,
  })

  useEffect(() => {
    refetch()
  }, [])

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const employeeList = data.list_head.concat(data.list_member)

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Employee List</Typography>
      </EnhancedToolbar>
      <Divider />
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          options={{
            ...NewTableOptions,
            paging: true,
            sorting: true,
            emptyRowsWhenPaging: false,
          }}
          isLoading={loading}
          columns={[
            {
              title: 'Employee',
              field: 'name',
              render: row => (
                <Flex
                  onClick={() => {
                    props.history.push({
                      pathname: `/holding-learning/detail-profile/${row?.global_user?.id}`,
                      state: {
                        active: 'detail-learning-profile',
                      },
                    })
                  }}
                  className={classes.hover}
                >
                  <Avatar
                    src={row?.global_user?.avatar}
                    className={classes.mr25}
                  />
                  <UserWrapper>
                    <TextBlackBold>{row?.global_user?.name}</TextBlackBold>
                    <ProfileText>{row?.company_job_profile?.title}</ProfileText>
                  </UserWrapper>
                </Flex>
              ),
            },
            {
              title: 'Employee ID',
              field: 'employeeId',
              render: row => <TextBlackBold>{row.regno}</TextBlackBold>,
            },
            {
              title: 'Position',
              field: 'position',
              render: row => (
                <TextBlackBold>{row?.company_job_profile?.title}</TextBlackBold>
              ),
            },
            {
              title: 'Company',
              field: 'company',
              render: row => (
                <TextBlackBold>
                  {row?.company_profile?.brand_name}
                </TextBlackBold>
              ),
            },
            {
              title: 'Email',
              field: 'email',
              render: row => (
                <ProfileText fcolor="lightBlue">{row?.email}</ProfileText>
              ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={employeeList}
        />
      </div>
    </PaperContainer>
  )
}

export default withRouter(ListComponent)
