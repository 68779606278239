import React from 'react'
import {
  ColWrapper,
  AvatarRecomendation,
  TyphoSection,
  WrapperItemSection,
} from '../../DetailEmployeeStyles'

const style = {
  date: {
    fontSize: 12,
    color: '#a9a8a8',
    marginLeft: 9,
    fontWeight: 'normal',
  },
}

export default function RecommendationItem({res, date}) {
  return (
    <WrapperItemSection>
      <AvatarRecomendation
        src={
          (res.global_user && res.global_user && res.global_user.avatar) ||
          (res.globalUserByRecommendationTo &&
            res.globalUserByRecommendationTo.avatar)
        }
        alt="user"
      />
      <div className="divider">
        <div className="content">
          <ColWrapper>
            <TyphoSection title="true" bold="true">
              {(res.global_user && res.global_user && res.global_user.name) ||
                (res.globalUserByRecommendationTo &&
                  res.globalUserByRecommendationTo.name)}{' '}
              <span style={style.date}>{date}</span>
            </TyphoSection>
            <TyphoSection grey="true" mb="true">
              {res.position}
            </TyphoSection>
            <TyphoSection style={{color: '#014a62'}} mb="true">
              {res.relationship}
            </TyphoSection>
            <TyphoSection mb="true">{res.description}</TyphoSection>
          </ColWrapper>
        </div>
      </div>
    </WrapperItemSection>
  )
}
