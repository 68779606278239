import React, {useState} from 'react'
import {
  makeStyles,
  Menu,
  Typography,
  Box,
  Tabs,
  Badge,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  FormGroup,
  CircularProgress,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import {useQuery} from '@apollo/react-hooks'
import {
  SearchContainer,
  ResetButtonWrapper,
  LabelMenuWrapper,
  CheckBoxWrapper,
  CenterWrapper,
} from './FilterPopup.style'
import {GET_ASSIGNEE_FILTER} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'

import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {
  AdornmentFilter,
  BlueIcon,
  FilterText,
  MenuDropdown,
  FlexBetween,
  TabLabel,
  LabelTabs,
} from '../../../GlobalStyles'

const styleUi = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: 250,
    padding: '0px !important',
  },
  root2: {
    zIndex: '0',
    width: '100%',
    borderTopColor: 'solid 1px #eff2f4',
    flexDirection: 'column',
    display: 'flex',
    padding: '0px !important',
  },
  root3: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    fontFamily: 'Muli',
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  reset: {
    cursor: 'pointer',
    width: '105px',
    height: '15px',
    lineHeight: '15px',
    textAlign: 'left',
    color: '#040404',
    padding: '12px',
  },
  tabPanel: {
    minWidth: 270,
    background: '#f7f8f9',
  },
  cancel: {
    cursor: 'pointer',
    width: '38px',
    height: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#a9a8a8',
    padding: '12px',
  },
  apply: {
    cursor: 'pointer',
    width: '33px',
    height: '15px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#039be5',
    padding: '12px',
  },
}))

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(() => ({
  thinTextfield: {
    padding: '12px 14px',
  },
  rootText: {
    width: '100%',
    background: '#fff',
    borderRadius: 5,
  },
}))

const SubMenu = props => {
  const {
    id,
    label,
    data,
    attributeName,
    position,
    setPosition,
    organization,
    setOrganization,
    jobProfile,
    setJobProfile,
    rank,
    setRank,
    office,
    setOffice,
    left,
  } = props

  const [isShow, setIsShow] = useState(false)
  const [search, setSearch] = useState('')
  const classes = useStyles()

  const itemName = {
    getDesignations: 'name',
    getJobProfiles: 'title',
    getLevels: 'name',
    getOffices: 'office_name',
    getOrganizations: 'name',
  }

  return (
    <div
      onMouseEnter={() => {
        setIsShow(true)
      }}
      onMouseLeave={() => {
        setIsShow(false)
      }}
    >
      <TabLabel
        label={
          <FlexBetween
            style={{
              padding: '5px',
              paddingLeft: '18px',
              paddingRight: '18px',
              width: '90%',
            }}
          >
            <LabelTabs>{label}</LabelTabs>
            {attributeName === 'getJobProfiles' && (
              <Badge color="primary" badgeContent={jobProfile.length}></Badge>
            )}
            {attributeName === 'getOffices' && (
              <Badge color="primary" badgeContent={office.length}></Badge>
            )}
            {attributeName === 'getDesignations' && (
              <Badge color="primary" badgeContent={position.length}></Badge>
            )}
            {attributeName === 'getLevels' && (
              <Badge color="primary" badgeContent={rank.length}></Badge>
            )}
            {attributeName === 'getOrganizations' && (
              <Badge color="primary" badgeContent={organization.length}></Badge>
            )}
          </FlexBetween>
        }
        {...a11yProps(id)}
      />
      <LabelMenuWrapper isShow={isShow} left={left}>
        <SearchContainer>
          <TextField
            variant="outlined"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search"
            className={classes.rootText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{display: 'flex'}}>
                  <BlueIcon>search</BlueIcon>
                </InputAdornment>
              ),
              classes: {
                input: classes.thinTextfield,
              },
            }}
          ></TextField>
        </SearchContainer>
        <ResetButtonWrapper>
          <div
            onClick={() => {
              const listOfId = []
              data.forEach(item => {
                listOfId.push(item.id)
              })
              if (attributeName === 'getJobProfiles') {
                setJobProfile(listOfId)
              }
              if (attributeName === 'getOffices') {
                setOffice(listOfId)
              }
              if (attributeName === 'getDesignations') {
                setPosition(listOfId)
              }
              if (attributeName === 'getLevels') {
                setRank(listOfId)
              }
              if (attributeName === 'getOrganizations') {
                setOrganization(listOfId)
              }
            }}
          >
            <strong>Select All</strong>
          </div>
          <div
            onClick={() => {
              if (attributeName === 'getJobProfiles') {
                setJobProfile([])
              }
              if (attributeName === 'getOffices') {
                setOffice([])
              }
              if (attributeName === 'getDesignations') {
                setPosition([])
              }
              if (attributeName === 'getLevels') {
                setRank([])
              }
              if (attributeName === 'getOrganizations') {
                setOrganization([])
              }
            }}
          >
            Reset
          </div>
        </ResetButtonWrapper>
        <CheckBoxWrapper>
          <FormGroup>
            {data.filter(item => {
              return item[itemName[attributeName]]
                .toLowerCase()
                .includes(search.toLowerCase())
            }).length === 0 && <CenterWrapper>No Data</CenterWrapper>}
            {data
              .filter(item => {
                return item[itemName[attributeName]]
                  .toLowerCase()
                  .includes(search.toLowerCase())
              })
              .map((item, i) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          (attributeName === 'getJobProfiles' &&
                            jobProfile.includes(item.id)) ||
                          (attributeName === 'getOffices' &&
                            office.includes(item.id)) ||
                          (attributeName === 'getDesignations' &&
                            position.includes(item.id)) ||
                          (attributeName === 'getLevels' &&
                            rank.includes(item.id)) ||
                          (attributeName === 'getOrganizations' &&
                            organization.includes(item.id))
                        }
                      />
                    }
                    label={item[itemName[attributeName]]}
                    value={item.id}
                    key={i}
                    onChange={e => {
                      if (e.target.checked) {
                        if (attributeName === 'getJobProfiles') {
                          setJobProfile(prev => {
                            return prev.concat(item.id)
                          })
                        }
                        if (attributeName === 'getOffices') {
                          setOffice(prev => {
                            return prev.concat(item.id)
                          })
                        }
                        if (attributeName === 'getDesignations') {
                          setPosition(prev => {
                            return prev.concat(item.id)
                          })
                        }
                        if (attributeName === 'getLevels') {
                          setRank(prev => {
                            return prev.concat(item.id)
                          })
                        }
                        if (attributeName === 'getOrganizations') {
                          setOrganization(prev => {
                            return prev.concat(item.id)
                          })
                        }
                      } else {
                        if (attributeName === 'getJobProfiles') {
                          setJobProfile(jobProfile.filter(x => x !== item.id))
                        }
                        if (attributeName === 'getOffices') {
                          setOffice(office.filter(x => x !== item.id))
                        }
                        if (attributeName === 'getDesignations') {
                          setPosition(position.filter(x => x !== item.id))
                        }
                        if (attributeName === 'getLevels') {
                          setRank(rank.filter(x => x !== item.id))
                        }
                        if (attributeName === 'getOrganizations') {
                          setOrganization(
                            organization.filter(x => x !== item.id)
                          )
                        }
                      }
                    }}
                  />
                )
              })}
          </FormGroup>
        </CheckBoxWrapper>
      </LabelMenuWrapper>
    </div>
  )
}

const FilterPopup = props => {
  const {setFilterData, left} = props

  const styleDefault = styleUi()
  const [value, setValue] = useState(0)

  const [position, setPosition] = useState([])
  const [organization, setOrganization] = useState([])
  const [jobProfile, setJobProfile] = useState([])
  const [rank, setRank] = useState([])
  const [office, setOffice] = useState([])

  const handleChangeFilterTab = newValue => {
    setValue(newValue)
  }

  const {data: listFilterData, error, loading} = useQuery(GET_ASSIGNEE_FILTER, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  const handleApply = () => {
    if (setFilterData) {
      setFilterData(prev => {
        const tmp = Object.assign({}, prev)
        tmp.position = position
        tmp.organization = organization
        tmp.job_profile = jobProfile
        tmp.rank = rank
        tmp.office = office
        return tmp
      })
    }
  }

  const handleCancel = () => {
    if (setFilterData) {
      setFilterData(prev => {
        setPosition(prev.position)
        setOrganization(prev.organization)
        setJobProfile(prev.job_profile)
        setRank(prev.rank)
        setOffice(prev.office)
        return prev
      })
    }
  }

  const handleResetAll = () => {
    if (setFilterData) {
      setPosition([])
      setOrganization([])
      setJobProfile([])
      setRank([])
      setOffice([])
      setFilterData(prev => {
        const tmp = Object.assign({}, prev)
        tmp.position = []
        tmp.organization = []
        tmp.job_profile = []
        tmp.rank = []
        tmp.office = []
        return tmp
      })
    }
  }

  const menus = [
    {
      key: 'getOffices',
      name: 'Office Location',
    },
    {
      key: 'getOrganizations',
      name: 'Organization Unit',
    },
    {
      key: 'getDesignations',
      name: 'Designation',
    },
    {
      key: 'getLevels',
      name: 'Level',
    },
    {
      key: 'getJobProfiles',
      name: 'Position',
    },
  ]

  return (
    <PopupState variant="popover" popupId="popUp2">
      {popupState => {
        return (
          <>
            <AdornmentFilter position="end" {...bindTrigger(popupState)}>
              <BlueIcon>filter_list</BlueIcon>
              <FilterText variant="body2">Filter</FilterText>
            </AdornmentFilter>

            <Menu
              {...bindMenu(popupState)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuDropdown>
                <div className={styleDefault.container}>
                  <div className={styleDefault.root}>
                    {error && (
                      <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>
                    )}
                    {loading && (
                      <CenterWrapper>
                        <CircularProgress />
                      </CenterWrapper>
                    )}
                    {!loading && listFilterData && (
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        style={{float: 'left'}}
                        onChange={handleChangeFilterTab}
                        aria-label="Vertical tabs example"
                        className={styleDefault.tabs}
                      >
                        {menus.map((item, idx) => {
                          return (
                            <SubMenu
                              key={idx}
                              label={item.name}
                              id={idx}
                              data={listFilterData[item.key]}
                              attributeName={item.key}
                              position={position}
                              setPosition={setPosition}
                              organization={organization}
                              setOrganization={setOrganization}
                              jobProfile={jobProfile}
                              setJobProfile={setJobProfile}
                              rank={rank}
                              setRank={setRank}
                              office={office}
                              setOffice={setOffice}
                              left={left}
                            />
                          )
                        })}
                      </Tabs>
                    )}
                  </div>
                  <div className={styleDefault.root2}>
                    <div
                      style={{
                        width: '262px',
                        height: '0',
                        border: 'solid 1px #eff2f4',
                      }}
                    ></div>
                    <div className={styleDefault.root3}>
                      <div
                        className={styleDefault.reset}
                        onClick={handleResetAll}
                      >
                        Reset All
                      </div>
                      <div
                        className={styleDefault.cancel}
                        onClick={() => {
                          handleCancel()
                          popupState.close()
                        }}
                      >
                        Cancel
                      </div>
                      <div
                        className={styleDefault.apply}
                        onClick={() => {
                          handleApply()
                          popupState.close()
                        }}
                      >
                        Apply
                      </div>
                    </div>
                  </div>
                </div>
              </MenuDropdown>
            </Menu>
          </>
        )
      }}
    </PopupState>
  )
}

export default FilterPopup
