import React, {useState, useEffect} from 'react'
import {
  BigTextBlackBold,
  WhiteButton,
  WrapperContent,
  TextDarkBlue,
  TextGrey,
} from '../../OrganizationPageStyles'
import {Button} from '@material-ui/core'
import {
  RootContainer,
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import Loading from '../../../../components/loading/LoadingComponent'
import {Link} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import MaterialTable from 'material-table'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_ORGANIZATION_LEVELS,
  GET_BALOON_STATUS_BUTTON,
  GET_COMPANY_ORGANIZATION_BY_ORG_LEVEL,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../../../graphql/queries'
import {
  DELETE_ORGAZNIATION_LEVEL,
  IMPORT_DATA,
  CLOSE_BALOON,
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
} from '../../../../graphql/mutations'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import {COMPANY_ID} from '../../../../utils/globals'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'

import DeletePopup from '../../../shared-components/popup/DeletePopup'
// import DownloadPopup from '../../../shared-components/popup/DownloadPopup'
import DetailDataOrganization from '../../../shared-components/popup/DetailDataOrganization'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import ImportBaloon from '../../../../components/importExcel/ImportBaloon'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import {trimString, disbaledButtonExport} from '../../../../utils/helpers'
import {status_data} from '../../../shared-components/data/SharedData'

const ListGrade = ({searchText}) => {
  // const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [OpenAlert, setOpenAlert] = useState(false)
  const [OpenCantDel, setOpenCantDel] = useState(false)

  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )

  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    refetch: refetchBaloonStatus,
    // loading: loadingGetBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'company_organization_levels',
    },
  })

  const {data: getExportBaloon, error: errorGetExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_organization_levels',
      },
    }
  )

  const {data: getExportDisabled, error: errorGetExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_organization_levels',
      },
    }
  )

  const [openImport, setImport] = useState(false)
  const [openExport, setExport] = useState(false)
  const [openDetail, setDetail] = useState(false)
  const [dataOrganizationLevel, setDataOrganizationLevel] = useState(false)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )

  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  const {data, error, loading, refetch} = useQuery(GET_ORGANIZATION_LEVELS, {
    wlb_skipPatch: true,
    variables: {
      search: `%${searchText}%`,
      companyId: COMPANY_ID,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const [deleteOrgLevel] = useMutation(DELETE_ORGAZNIATION_LEVEL)
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')

  const [skipQueryCompOrg, setSkipQueryCompOrg] = useState(true)
  const {data: dataOrganization} = useQuery(
    GET_COMPANY_ORGANIZATION_BY_ORG_LEVEL,
    {
      wlb_skipPatch: true,
      variables: {orgLevel: dataOrganizationLevel && dataOrganizationLevel.id},
      skip: skipQueryCompOrg,
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setSkipQueryCompOrg(true)
      },
    }
  )

  useEffect(() => {
    if (dataOrganization) {
      const countOrganizationUsed =
        dataOrganization &&
        dataOrganization.total &&
        dataOrganization.total.aggregate &&
        dataOrganization.total.aggregate.count > 0
      countOrganizationUsed ? setOpenCantDel(true) : setOpenAlert(true)
    }
  }, [dataOrganization])

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  if (data === undefined || getBaloonStatus === undefined) {
    return <Loading />
  } else if (
    error !== undefined ||
    mutationCloseBaloonError !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationImportError !== undefined ||
    mutationExportError !== undefined ||
    closeBaloonExportError !== undefined ||
    errorGetExportBaloon !== undefined ||
    errorGetExportDisabled !== undefined
  ) {
    return <div>{error}</div>
  }

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  const onDeleteOrgLevel = () => {
    const variables = {
      id: dataOrganizationLevel.id,
      companyId: COMPANY_ID,
    }
    deleteOrgLevel({variables: variables})
      .then(() => {
        enqueueSnackbar('Organization Level deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete Organization Level failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 1000,
          }
        )
      })
  }

  const handleOpenAlert = data => {
    setDataOrganizationLevel(data)
    setSkipQueryCompOrg(false)
  }

  const handleCloseAlert = status => {
    status === 'delete' ? setOpenAlert(false) : setOpenCantDel(false)
  }

  const importMutation = async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await mutationImport({
        variables: {
          table: 'company_organization_levels',
          file: fileURL,
        },
      })
      // eslint-disable-next-line no-console
      refetchBaloonStatus()
      refetch()
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  }

  const handleClickOpenImport = () => {
    setImport(true)
  }

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  const handleCloseImport = () => {
    setImport(false)
  }

  const handleDownload = () => {
    setExport(!openExport)
    if (openExport) {
      mutationExport({
        variables: {
          table: 'company_organization_levels',
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleOpenDetail = data => {
    setDetail(!openDetail)
    setDataOrganizationLevel(data)
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  // const defaultExport = [
  //   {
  //     'Organization Level Code': '-',
  //     'Organization Level': '-',
  //     Description: '-',
  //     Status: '-',
  //   },
  // ]

  // const dataForExport = data.company_organization_levels.map(res => {
  //   return {
  //     'Organization Level Code': res.code ? res.code : '-',
  //     'Organization Level': res.name ? res.name : '-',
  //     Description: res.description ? res.description : '-',
  //     Status: res.status ? res.status : '-',
  //   }
  // })

  // const dataExport = dataForExport.length > 0 ? dataForExport : defaultExport

  const column = [
    {
      title: 'Legenda',
      field: 'color_hex',
      render: data => {
        const color = data.color_hex

        if (!color) {
          return null
        }

        return (
          <div
            style={{
              height: 32,
              width: 32,
              borderRadius: 4,
              backgroundColor: color,
            }}
          />
        )
      },
    },
    {
      title: 'Kode Level Organisasi',
      field: 'code',
      defaultSort: 'asc',
      headerStyle: {minWidth: 200, maxWidth: 200},
      cellStyle: {minWidth: 200, maxWidth: 200},
      render: rowData => (
        <div style={{wordBreak: 'break-word'}}>
          {`${trimString(rowData.code, 100)} ${
            rowData.code && rowData.code.length > 100 ? '...more' : ''
          }`}
        </div>
      ),
    },
    {
      title: 'Nama Level Organisasi',
      field: 'name',
      headerStyle: {minWidth: 350, maxWidth: 350},
      cellStyle: {minWidth: 350, maxWidth: 350},
      // render: rowData =>
      //   `${trimString(rowData.name, 100)} ${
      //     rowData.name.length > 100 ? '...more' : ''
      //   }`,
    },
    // {
    //   title: 'Description',
    //   field: 'description',
    // },
    {
      title: 'Status',
      field: 'status',
      headerStyle: {minWidth: 250},
      cellStyle: {minWidth: 250},
      render: rowData => (
        <Button
          variant="contained"
          style={
            rowData &&
            rowData.status &&
            rowData.status.toLowerCase() === 'active'
              ? {
                  backgroundColor: '#4caf50',
                  color: '#ffffff',
                  width: '100px',
                }
              : {
                  backgroundColor: '#ef4d5e',
                  color: '#ffffff',
                  width: '100px',
                }
          }
          size="medium"
        >
          {rowData &&
          rowData.status &&
          rowData.status.toLowerCase() === 'active'
            ? status_data.ACTIVE
            : status_data.INACTIVE}
        </Button>
      ),
    },
    {
      title: 'Aksi',
      field: 'action',
      render: rowData => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleOpenDetail(rowData)
                  }}
                >
                  Rincian
                </StyledMenuItem>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: `/organization/organization-level/edit`,
                    state: {
                      active: 'edit-organization-level',
                      status: 'Edit',
                      data: rowData,
                    },
                  }}
                  variant="h6"
                >
                  Ubah
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleOpenAlert(rowData)
                  }}
                >
                  Hapus
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>
      ),
    },
  ]

  return (
    <RootContainer>
      <ImportBaloon
        data={getBaloonStatus}
        closeBaloonMutation={closeBaloonMutation}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
        refetchBaloonStatus={refetchBaloonStatus}
      />
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
      />
      <PaperContainer>
        <EnhancedToolbar>
          <BigTextBlackBold>Level Organisasi</BigTextBlackBold>
          <WrapperContent>
            <GenerateTooltip
              title={
                (getExportBaloon &&
                  getExportBaloon.company_export_data_logs &&
                  getExportBaloon.company_export_data_logs.length > 0 &&
                  getExportBaloon.company_export_data_logs[0].status ===
                    'FAILED') ||
                disbaledButtonExport(
                  getExportDisabled &&
                    getExportDisabled.company_export_data_logs &&
                    getExportDisabled.company_export_data_logs[0] &&
                    getExportDisabled.company_export_data_logs[0].date_added
                )
                  ? `Anda hanya dapat menghasilkan data sekali sehari. Untuk mengunduh data gunakan link yang sudah dibuat`
                  : ``
              }
              placement="left"
            >
              <span>
                <WhiteButton
                  variant="contained"
                  size="large"
                  style={{marginRight: 12}}
                  onClick={() => handleDownload()}
                  disabled={
                    (getExportBaloon &&
                      getExportBaloon.company_export_data_logs &&
                      getExportBaloon.company_export_data_logs.length > 0 &&
                      getExportBaloon.company_export_data_logs[0].status ===
                        'FAILED') ||
                    disbaledButtonExport(
                      getExportDisabled &&
                        getExportDisabled.company_export_data_logs &&
                        getExportDisabled.company_export_data_logs[0] &&
                        getExportDisabled.company_export_data_logs[0].date_added
                    )
                  }
                >
                  <TextDarkBlue>Ekspor Data</TextDarkBlue>
                </WhiteButton>
              </span>
            </GenerateTooltip>
            <WhiteButton
              variant="contained"
              size="large"
              style={{
                marginRight: 12,
                borderColor:
                  getBaloonStatus.company_import_data_logs.length > 0 &&
                  getBaloonStatus.company_import_data_logs[0].status ===
                    'WAITING'
                    ? 'grey'
                    : 'rgb(1, 74, 98)',
                opacity:
                  getBaloonStatus.company_import_data_logs.length > 0 &&
                  getBaloonStatus.company_import_data_logs[0].status ===
                    'WAITING'
                    ? 0.5
                    : 1,
              }}
              disabled={
                getBaloonStatus.company_import_data_logs.length > 0 &&
                getBaloonStatus.company_import_data_logs[0].status === 'WAITING'
              }
              onClick={() => handleClickOpenImport()}
            >
              {getBaloonStatus.company_import_data_logs.length > 0 &&
              getBaloonStatus.company_import_data_logs[0].status ===
                'WAITING' ? (
                <TextGrey>Importing on proces...</TextGrey>
              ) : (
                <TextDarkBlue>Impor Level Organisasi</TextDarkBlue>
              )}
            </WhiteButton>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={{
                pathname: `/organization/organization-level/add`,
                state: {
                  active: 'add-organization-level',
                  status: 'Add',
                },
              }}
            >
              Tambah Level Organisasi
            </Button>
          </WrapperContent>
        </EnhancedToolbar>
        <MaterialTable
          options={{
            ...TableOptions,
            selection: false,
            showTitle: false,
            toolbar: false,
            emptyRowsWhenPaging: false,
          }}
          columns={column}
          isLoading={loading}
          localization={{
            pagination: TableLocalization.pagination,
            body: {
              emptyDataSourceMessage: (
                <NoDataListComponent search={searchText} />
              ),
            },
          }}
          data={data.company_organization_levels}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={data.total.aggregate.count}
          page={pagination.offset}
          pageSize={pagination.limit}
        />
        <DeletePopup
          open={OpenAlert}
          handleClose={() => handleCloseAlert('delete')}
          id={dataOrganizationLevel.id}
          name={dataOrganizationLevel.name}
          mutation={() => onDeleteOrgLevel()}
        />
        <AlertDialog
          open={OpenCantDel}
          handleClose={() => handleCloseAlert('cantDelete')}
          feature={`Tidak dapat Menghapus ${dataOrganizationLevel.name}`}
          name={dataOrganizationLevel.name}
          fMessage="Anda tidak dapat menghapus "
          lMessage=" karena sedang digunakan pada Unit Organisasi."
          type="Confirm"
          positiveTitleButton="Konfirmasi"
        />
        <AlertDialog
          open={openExport}
          handleClose={handleDownload}
          feature="Ekspor data sedang diproses!"
          message="Ekspor data sedang diproses! Kami akan mengirimkan email kepada Anda jika sudah selesai"
          type="Confirm"
          positiveTitleButton="Konfirmasi"
        />
        <ImportPopup
          open={openImport}
          position="Organization Level"
          handleClose={handleCloseImport}
          mutation={importMutation}
          fileTemplate={''}
          isAlreadyImported={isAlreadyImported}
          setIsAlreadyImported={setIsAlreadyImported}
          setImport={setImport}
          fileURL={fileURL}
          setFileURL={setFileURL}
          closeBaloon={closeBaloonMutation}
          errorsImport={
            getBaloonStatus.company_import_data_logs.length > 0 &&
            getBaloonStatus.company_import_data_logs[0].reports &&
            getBaloonStatus.company_import_data_logs[0].reports.failed.length >
              0
              ? getBaloonStatus.company_import_data_logs[0].reports.failed
              : []
          }
        />
        <DetailDataOrganization
          handleClose={handleOpenDetail}
          open={openDetail}
          data={dataOrganizationLevel}
          status={true}
          feature="Level Organisasi"
          additionalInformation={{
            'Warna Legenda': dataOrganizationLevel?.color_hex && (
              <div
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 4,
                  backgroundColor: dataOrganizationLevel.color_hex,
                }}
              />
            ),
          }}
        />
      </PaperContainer>
    </RootContainer>
  )
}

export default ListGrade
