import React, {useState, useEffect} from 'react'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  WrapperContent,
} from '../../OrganizationPageStyles'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
  PaperContainer,
  EnhancedToolbar,
  TextHelper,
} from '../../../../GlobalStyles'
import {useSnackbar} from 'notistack'

import {
  Icon,
  IconButton,
  Button,
  Divider,
  TextField,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import Select from 'react-select'
import {Link, withRouter} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_JOB_FUNCTION_DROPDOWN,
  CHECK_CODE_JOB_TITLE,
  GET_DESIGNATION_DETAIL,
  GET_COMPANY_PROFILE_BY_DESIGNATION,
} from '../../../../graphql/queries'
import {ADD_DESIGNATION, EDIT_DESIGNATION} from '../../../../graphql/mutations'
import {COMPANY_ID} from '../../../../utils/globals'

import Loading from '../../../../components/loading/LoadingComponent'
import AddEditConfirm from '../../../shared-components/popup/AddEditPopup'
import AlertDialog from '../../../shared-components/popup/ResultPopup'

const AddEditDesignation = props => {
  const {status, data} = props
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const dataDesignation = data
    ? {
        designation_code: data.designation_code,
        name: data.name,
      }
    : {
        designation_code: '',
        name: '',
        description: '',
        status: 'Active',
      }
  const [hasJobProfile, setHasJobProfile] = useState(false)
  const [JTDetail, setJTDetail] = useState(null)
  const [allDataFrom, setAllDataFrom] = useState(dataDesignation)
  const [isError, setIsError] = useState(false)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [dialogCancel, setDialogCancel] = useState(false)
  const [dialogCantDel, setDialogCantDel] = useState(false)
  const [switchChecked, setSwitchChecked] = useState(true)
  const [JFDropdown, setJFDropdown] = useState(null)
  const [searchDropdown, setSearchDropdown] = useState({
    job_function: '',
  })

  const [addData] = useMutation(ADD_DESIGNATION)
  const [editData] = useMutation(EDIT_DESIGNATION)

  // const nameChecker =
  //   dataCheck &&
  //   dataCheck.company_employee_positions &&
  //   dataCheck.company_employee_positions.some(
  //     v => v.name.toLowerCase() === allDataFrom.name.toLowerCase()
  //   )

  const {data: compProfile} = useQuery(GET_COMPANY_PROFILE_BY_DESIGNATION, {
    variables: {jobTitle: data && data.id},
    skip: !data,
  })
  useQuery(GET_DESIGNATION_DETAIL, {
    variables: {id: data && data.id},
    skip: !data,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (
        data &&
        data.company_employee_positions &&
        data.company_employee_positions.length > 0
      ) {
        setJTDetail(data.company_employee_positions[0])
      }
    },
  })
  const {data: JFData, error: JFError, loading: JFLoad, refetch} = useQuery(
    GET_JOB_FUNCTION_DROPDOWN,
    {
      variables: {
        company: COMPANY_ID,
        search: `%${searchDropdown.job_function}%`,
      },
      fetchPolicy: 'cache-and-network',
    }
  )
  const {data: checkCodeJobTitle, error: checkCodeJobTitleError} = useQuery(
    CHECK_CODE_JOB_TITLE,
    {
      variables: {
        company: COMPANY_ID,
        code: allDataFrom && allDataFrom.designation_code,
      },
      fetchPolicy: 'cache-and-network',
      skip: status === 'Add' && !allDataFrom.designation_code,
    }
  )

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  useEffect(() => {
    if (JTDetail && JTDetail.job_function) {
      setJFDropdown({
        value: JTDetail.job_function.id,
        label: `${JTDetail.job_function.code} - ${JTDetail.job_function.name}`,
      })
      setAllDataFrom({
        ...allDataFrom,
        description: JTDetail.description,
        job_mapping_id: JTDetail.job_function.id,
      })
    }

    if (compProfile) {
      const countJobProfile =
        compProfile &&
        compProfile.total &&
        compProfile.total.aggregate &&
        compProfile.total.aggregate.count > 0

      setHasJobProfile(countJobProfile)
    }

    if (data && (data.status === 'Active' || data.status === 'ACTIVE')) {
      setSwitchChecked(true)
    } else if (
      data &&
      (data.status === 'Inactive' || data.status === 'INACTIVE' || !data.status)
    ) {
      setSwitchChecked(false)
    }
  }, [data, JTDetail, compProfile])

  if (
    JFLoad === undefined &&
    JFError === undefined
    // ||
    // (checkCodeJobTitle === undefined && checkCodeJobTitleError === undefined)
  ) {
    return <Loading />
  } else if (JFError !== undefined || checkCodeJobTitleError !== undefined) {
    return (
      <>
        <div>{checkCodeJobTitleError}</div>
        <div>{JFError}</div>
      </>
    )
  }

  const JF = []
  JFData &&
    JFData.job_family_functions.map(job_function => {
      const JFOptions = {
        value: job_function.id,
        label: `${job_function.code} - ${job_function.name}`,
      }

      JF.push(JFOptions)
    })

  // const checkSameNameWithAdd = data && data.name === allDataFrom.name
  const codeChecker =
    checkCodeJobTitle &&
    checkCodeJobTitle.company_employee_positions_aggregate &&
    checkCodeJobTitle.company_employee_positions_aggregate.aggregate &&
    checkCodeJobTitle.company_employee_positions_aggregate.aggregate.count > 0

  const checkSameCodeWithAdd =
    allDataFrom &&
    allDataFrom.designation_code &&
    allDataFrom.designation_code.length >= 1
      ? data && data.designation_code === allDataFrom.designation_code
      : false

  const checkCharaCode =
    allDataFrom &&
    allDataFrom.designation_code &&
    allDataFrom.designation_code.length > 30
  const checkCharaName =
    allDataFrom && allDataFrom.name && allDataFrom.name.length > 100

  const openDialogConfirm = () => {
    if (
      !allDataFrom.designation_code ||
      !allDataFrom.name ||
      !allDataFrom.job_mapping_id ||
      checkCharaCode ||
      checkCharaName ||
      // (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd) ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 2000)
    } else {
      dialogConfirm ? setDialogConfirm(false) : setDialogConfirm(true)
    }
  }

  const handleAddDesignation = () => {
    if (status === 'Add') {
      const dataForAdd = {
        objects: [allDataFrom],
      }
      addData({variables: dataForAdd})
        .then(() => {
          enqueueSnackbar('Job Title Added', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          props.history.push({
            pathname: '/organization/job-title',
            state: {active: 'job-title', searchable: true},
          })
        })
        .catch(() => {
          enqueueSnackbar('Added Job Title failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else if (status === 'Edit') {
      const dataForEdit = {
        id: data && data.id,
        object: {
          name: allDataFrom.name,
          designation_code: allDataFrom.designation_code,
          job_mapping_id: allDataFrom.job_mapping_id,
          description: allDataFrom.description,
          status: allDataFrom.status,
        },
      }
      editData({variables: dataForEdit})
        .then(() => {
          enqueueSnackbar('Job Title Edited', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          props.history.push({
            pathname: '/organization/job-title',
            state: {active: 'job-title', searchable: true},
          })
        })
        .catch(() => {
          enqueueSnackbar('Edit Job Title failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleAlert = status => {
    dialogCancel ? setDialogCancel(false) : setDialogCancel(true)
    if (dialogCancel && status !== 'notNow') {
      props.history.push({
        pathname: '/organization/job-title',
        state: {active: 'job-title', searchable: true},
      })
    }
  }

  return (
    <OrganizationContainer>
      <PaperContainer className={classes.notViewScroll}>
        <EnhancedToolbar className={classes.paddingLeft0}>
          <WrapperContent>
            <IconButton
              component={Link}
              to={{
                pathname: '/organization/job-title',
                state: {active: 'job-title', searchable: true},
              }}
              edge="end"
              aria-label="back"
            >
              <Icon>arrow_back</Icon>
            </IconButton>
            <BigTextBlackBold className={classes.marginLeft10}>{`${
              status === 'Add' ? 'Tambah' : 'Ubah'
            } Jabatan`}</BigTextBlackBold>
          </WrapperContent>
          <div>
            <Button
              size="large"
              onClick={handleAlert}
              style={{marginRight: 12}}
            >
              Batal
            </Button>
            <Button
              className={classes.marginLeft10}
              variant="contained"
              color="primary"
              size="large"
              onClick={openDialogConfirm}
            >
              Simpan
            </Button>
          </div>
        </EnhancedToolbar>
        <Divider />
        <OrganizationContainer>
          <FormContainer id="top">
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>1.</TitleNumber>
                <TitleName>Kode Jabatan *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="designationCode"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  placeholder="Tambah Kode Jabatan"
                  fullWidth
                  className={classes.helperMarginLeft0}
                  value={allDataFrom.designation_code}
                  margin="normal"
                  error={
                    (!allDataFrom.designation_code && isError) ||
                    checkCharaCode ||
                    (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
                  }
                  helperText={
                    !allDataFrom.designation_code && isError
                      ? 'Bagian ini diperlukan'
                      : (codeChecker
                        ? !checkSameCodeWithAdd
                        : checkSameCodeWithAdd)
                      ? 'Kode Jabatan sudah ada'
                      : checkCharaCode
                      ? 'Jumlah karakter maksimum adalah 30 karakter'
                      : 'Kode Jabatan harus unik'
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      designation_code: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber id="jobFamily">2.</TitleNumber>
                <TitleName>Jenis Jabatan *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <Select
                  id="JF"
                  options={JF}
                  placeholder="Pilih Jenis Jabatan"
                  value={JFDropdown}
                  className={classes.marginTop15}
                  isLoading={JFLoad}
                  onInputChange={dataSearchDropdown =>
                    setSearchDropdown({
                      ...searchDropdown,
                      job_function: dataSearchDropdown,
                    })
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      job_mapping_id: event.value,
                    })
                    setJFDropdown(event)
                  }}
                  styles={{
                    menu: provided => ({...provided, zIndex: 9999}),
                  }}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: '#bfbfbf',
                    },
                  })}
                />
                <TextHelper className={classes.marginSelect}>
                  {allDataFrom && !allDataFrom.job_mapping_id && isError
                    ? 'Bagian ini diperlukan'
                    : ''}
                </TextHelper>
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>3.</TitleNumber>
                <TitleName>Nama Jabatan *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgName"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  placeholder="Nama Jabatan tanpa jenjang jabatan"
                  fullWidth
                  value={allDataFrom.name}
                  margin="normal"
                  className={classes.helperMarginLeft0}
                  error={
                    (!allDataFrom.name && isError) || checkCharaName
                    // ||(nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
                  }
                  helperText={
                    !allDataFrom.name && isError
                      ? 'Bagian ini diperlukan'
                      : checkCharaName
                      ? 'Jumlah karakter maksimum adalah 100 karakter'
                      : ''
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      name: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>4.</TitleNumber>
                <TitleName>Deskripsi</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgName"
                  style={{padding: '0px'}}
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="Tambah Deskripsi"
                  value={allDataFrom.description}
                  margin="normal"
                  className={classes.multilinePadding}
                  multiline
                  rows="5"
                  // error={
                  //   !allDataFrom.name && isError
                  // }
                  // helperText={}
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      description: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber id="parent">5.</TitleNumber>
                <TitleName>Status *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchChecked}
                      onChange={event => {
                        const actInc = event.target.checked
                          ? 'Active'
                          : 'Inactive'
                        setSwitchChecked(event.target.checked)
                        if (hasJobProfile && data && data.status === 'Active') {
                          setDialogCantDel(!dialogCantDel)
                        } else {
                          setAllDataFrom({
                            ...allDataFrom,
                            status: actInc,
                          })
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={switchChecked ? 'Aktif' : 'Tidak Aktif'}
                />
              </FormChildInput>
            </FormChildContainer>
          </FormContainer>
        </OrganizationContainer>
      </PaperContainer>
      {hasJobProfile ? (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type="Confirm"
          applied={compProfile && compProfile.company_job_profiles}
          mutation={handleAddDesignation}
          appliedName="Position"
        />
      ) : (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type={status}
          feature="Job Title"
          name={data && data.name}
          mutation={handleAddDesignation}
        />
      )}
      <AlertDialog
        open={dialogCancel}
        handleClose={handleAlert}
        feature={`Batal Untuk ${data ? `Mengubah` : 'Menambahkan'} Jabatan?`}
        message={`Anda memiliki perubahan yang belum disimpan. Yakin ingin membatalkan ${
          data ? `perubahan Jabatan - ${data.name}` : 'penambahan Jabatan baru'
        }?`}
        positiveTitleButton="Ya, Batalkan"
        type="Yes, Cancel"
        status="notNow"
      />
      <AlertDialog
        open={dialogCantDel}
        handleClose={() => setDialogCantDel(!dialogCantDel)}
        feature="Tidak Dapat Diubah ke Tidak Aktif"
        message="Tidak dapat mengubah status menjadi Tidak Aktif karena sedang digunakan di penempatan"
        positiveTitleButton="Konfirmasi"
        type="Confirm"
        status="cancel"
        cancelAction={setSwitchChecked}
        reverseCancel={true}
      />
    </OrganizationContainer>
  )
}

export default withRouter(AddEditDesignation)
