import React from 'react'

import {ClickableIcon} from '../BaseStyles'
import {
  HeaderContainer,
  OptionsContainer,
  OptionLinkIcon,
  Dropwdown,
  HoverCustom,
  DropwdownItem,
  HoverableContainer,
  UserInfoContainer,
  HeaderDivider,
} from './HeaderStyles'
import {getCookie, createCookie} from '../../utils/helpers'
import {SSO_URL, USER_ID} from '../../utils/globals'
import {Avatar, Icon} from '@material-ui/core'
import {withRouter, Link} from 'react-router-dom'

const options = {
  followCursor: false,
}

const Header = props => {
  const data = getCookie('userData') && JSON.parse(getCookie('userData'))

  const handleLogout = () => {
    createCookie('userData', '', -1)
    setTimeout(() => {
      window.location = SSO_URL
    }, 500)
  }

  // const toProfile = () => {
  //   // props.history.push(`/directory/employee/${USER_ID}`)
  //   props.history.push({
  //     pathname: `/directory/detail-employee/${USER_ID}`,
  //     state: {
  //       active: 'detail-employee',
  //       // id: id,
  //       // jobName: jobName,
  //     },
  //   })
  //   // window.location = `${APP_DEPENDENT_URL}/directory/employee/detail_employee/${USER_ID}`
  // }

  return (
    <>
      <HeaderContainer>
        {/* <LogoContainer to={'/'}>
          <img
            alt="wlb-logo"
            src={
              'http://canary.worklifebeyond.com/assets/images/logos/wlb-combined-green.svg'
            }
            height="19.2"
            width="78.4"
          />
        </LogoContainer> */}

        <OptionsContainer>
          {/* <SearchForm placeholder="Search" /> */}
          <ClickableIcon>calendar</ClickableIcon>
        </OptionsContainer>
        <OptionsContainer right>
          {/* <OptionLinkIcon to={'/'}>
            <FontAwesomeIcon
              icon={faHome}
              style={{
                color: '#a9a8a8',
                cursor: 'pointer',
                height: 20,
                width: 20,
              }}
            />
          </OptionLinkIcon>
          <OptionLinkIcon left="true" to={'/'}>
            <FontAwesomeIcon
              icon={faUsers}
              style={{
                color: '#a9a8a8',
                cursor: 'pointer',
                height: 20,
                width: 20,
              }}
            />
          </OptionLinkIcon>
          <OptionLinkIcon left="true" to={'/'}>
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{
                color: '#a9a8a8',
                cursor: 'pointer',
                height: 20,
                width: 20,
              }}
            />
          </OptionLinkIcon>

          <OptionLinkIcon left="true" to={'/notification'}>
            <FontAwesomeIcon
              icon={faBell}
              style={{
                cursor: 'pointer',
                height: 20,
                width: 20,
                color: '#a9a8a8',
              }}
            />
          </OptionLinkIcon> */}
          <OptionLinkIcon left="true" to="#">
            <HoverCustom options={options}>
              <HoverCustom.Trigger type="trigger">
                <HoverableContainer>
                  <Avatar
                    alt="user-logged-icon"
                    src={
                      data.avatar
                        ? data.avatar
                        : require('../../assets/images/default-avatar.png')
                    }
                  />
                  <UserInfoContainer>
                    <p>{data && data.name}</p>
                    <small>{data && data.position_name}</small>
                  </UserInfoContainer>
                  <Icon
                    style={{
                      fontSize: 16,
                      marginLeft: 12,
                      marginRight: 12,
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                  >
                    keyboard_arrow_down
                  </Icon>
                </HoverableContainer>
              </HoverCustom.Trigger>
              <HoverCustom.Hover type={'hover'}>
                <Dropwdown>
                  <DropwdownItem
                    as={Link}
                    to={{
                      pathname: '/directory/employee/' + USER_ID,
                      state: {
                        active: 'detail-employee',
                        empType: '',
                      },
                    }}
                    // onClick={() => toProfile()}
                  >
                    <Icon style={{color: 'rgba(0, 0, 0, 0.54)'}}>
                      account_circle
                    </Icon>
                    <p>My Profile</p>
                  </DropwdownItem>
                  <DropwdownItem>
                    <Icon style={{color: 'rgba(0, 0, 0, 0.54)'}}>mail</Icon>
                    <p>Inbox</p>
                  </DropwdownItem>
                  <DropwdownItem onClick={handleLogout}>
                    <Icon style={{color: 'rgba(0, 0, 0, 0.54)'}}>
                      exit_to_app
                    </Icon>
                    <p>Logout</p>
                  </DropwdownItem>
                </Dropwdown>
              </HoverCustom.Hover>
            </HoverCustom>
          </OptionLinkIcon>
          <HeaderDivider orientation="vertical"></HeaderDivider>
          {/* <IconRight>search</IconRight>
          <HeaderDivider orientation="vertical"></HeaderDivider>
          <IconRight>format_list_bulleted</IconRight> */}
        </OptionsContainer>
      </HeaderContainer>
    </>
  )
}

export default withRouter(Header)
