import React, {useState} from 'react'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {WrapperSection} from '../../DetailEmployeeStyles'
import {OrganizationItem} from './OrganizationItem'
import {generateRangedDate} from '../../utilsForDetails'
import {ButtonAdd} from '../../SharedDetailEmployee'
import AddEditOrganizationActivity from './AddEditOrganizationActivity'

export default function OrganizationActivity({
  organization_activities,
  user_name_long,
  user_name,
  refetch,
  setAction,
  enqueueSnackbar,
  userIdDetail,
  action,
  INITIAL_ACTION_STATE,
}) {
  const [open, setOpen] = useState(false)

  const _renderData = (data, limit = null) => {
    return data.slice(0, limit || data.length).map(res => {
      return (
        <OrganizationItem
          key={`${res.id}-itm`}
          position_name={res.position_name || '-'}
          organization_name={res.organization_name || '-'}
          organization_type={res.organization_type || '-'}
          location={(res.city.name || '-') + ', ' + (res.province.name || '-')}
          date={generateRangedDate(!res.end_date, res.start_date, res.end_date)}
          member_id={res.member_id || '-'}
          description={res.position_description || '-'}
          onEdit={() =>
            handleOpenForm({
              actionType: 'edit',
              data: res,
              isFromModal: action.isFromModal,
            })
          }
        />
      )
    })
  }

  const handleCloseAction = () => {
    if (action.isFromModal) {
      setOpen(true)
    }
    setAction(INITIAL_ACTION_STATE)
  }

  const handleOpenForm = ({actionType, data = null, isFromModal}) => {
    if (isFromModal) {
      setOpen(false)
    }
    setAction(e => ({
      ...e,
      open: true,
      actionType,
      data,
      isFromModal,
    }))
  }

  return (
    <>
      <WrapperSection>
        {_renderData(organization_activities, 2)}
        {organization_activities[0] && (
          <h3 onClick={() => setOpen(true)} className="show-more">
            Tampilkan Lebih Banyak Keorganisasian...
          </h3>
        )}
      </WrapperSection>

      <ModalDetail
        title={`Keorganisasian ${user_name}`}
        onClose={() => setOpen(false)}
        open={open}
      >
        {_renderData(organization_activities)}
        <ButtonAdd
          label="Keorganisasian"
          onClick={() =>
            handleOpenForm({
              actionType: 'add',
              isFromModal: true,
            })
          }
        />
      </ModalDetail>

      <AddEditOrganizationActivity
        setAction={setAction}
        refetch={refetch}
        enqueueSnackbar={enqueueSnackbar}
        userIdDetail={userIdDetail}
        open={action.open}
        data={action.data}
        user_name_long={user_name_long}
        onClose={handleCloseAction}
        actionType={action.actionType}
      />
    </>
  )
}
