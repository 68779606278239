import {
  TextField,
  MenuItem,
  Avatar,
  Divider,
  Typography,
} from '@material-ui/core'
import React from 'react'
import {
  FormChildContainer,
  FormChildInput,
  FormChildInputHalf,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
  GreyTypography,
} from '../../../../../GlobalStyles'
import {selectProps} from '../../../../../utils/helpers'
import {Autocomplete} from '@material-ui/lab'
import {BoldTypography} from '../../../../../components/typography/TypographyStyles'

function FormDetail(props) {
  const {
    dataCompany,
    values,
    dataPosition,
    handleChange,
    company,
    setCompany,
    isError,
    classes,
    setValues,
  } = props
  return (
    <FormContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Vacancy Name</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            value={values.name}
            onChange={handleChange('name')}
            placeholder="Enter the name"
            error={!values.name && isError}
            helperText={!values.name && isError ? 'This field is required' : ''}
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Company</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <Autocomplete
            id="combo-box-demo"
            options={
              dataCompany &&
              dataCompany.map(res => ({
                ...res,
              }))
            }
            value={company}
            onChange={(e, newValue) => {
              e.preventDefault()
              setCompany(newValue)
              setValues({
                ...values,
                profile: '',
              })
            }}
            getOptionLabel={option => option && option.legal_name}
            renderOption={param => {
              return (
                <div className={classes.companyWrapper}>
                  <Avatar
                    variant="rounded"
                    src={param.logo}
                    className={classes.mr20}
                  />
                  <div className={classes.structureHeader}>
                    <BoldTypography variant="body2" gutterBottom>
                      {param.brand_name}
                    </BoldTypography>
                    <GreyTypography variant="caption" gutterBottom>
                      {param.legal_name}
                    </GreyTypography>
                    <Typography
                      variant="caption"
                      component="p"
                      dangerouslySetInnerHTML={{
                        __html:
                          param.profile_field.description.length > 200
                            ? `${param.profile_field.description.slice(
                                0,
                                200
                              )} ...`
                            : param.profile_field.description,
                      }}
                    />
                    <Divider className={classes.mt20} />
                  </div>
                </div>
              )
            }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select company"
                variant="outlined"
                margin="normal"
                error={!company && isError}
                helperText={!company && isError ? 'This field is required' : ''}
              />
            )}
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>Position</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            select
            value={values.profile}
            onChange={handleChange('profile')}
            // placeholder="Enter the name"
            error={!values.profile && isError}
            helperText={
              !values.profile && isError ? 'This field is required' : ''
            }
            SelectProps={selectProps(values.profile)}
          >
            <MenuItem value="" disabled>
              Select position
            </MenuItem>
            {dataPosition &&
              dataPosition.map((res, i) => (
                <MenuItem value={res.id} key={i}>
                  {res.title}
                </MenuItem>
              ))}
          </TextField>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>Description</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
            value={values.description}
            onChange={handleChange('description')}
            placeholder="Enter the description"
            error={!values.description && isError}
            helperText={
              !values.description && isError ? 'This field is required' : ''
            }
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>5.</TitleNumber>
          <TitleName>Status</TitleName>
        </FormChildTitle>
        <FormChildInputHalf>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            select
            value={values.status}
            onChange={handleChange('status')}
            error={!values.status && isError}
            helperText={
              !values.status && isError ? 'This field is required' : ''
            }
            SelectProps={selectProps(values.status)}
          >
            <MenuItem value="" disabled>
              Select status
            </MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </TextField>
        </FormChildInputHalf>
      </FormChildContainer>
    </FormContainer>
  )
}

export default FormDetail
