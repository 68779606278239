import gql from 'graphql-tag'

export const ADD_ORGANIZATION = gql`
  mutation($objects: [company_organizations_insert_input!]!) {
    insert_company_organizations(objects: $objects) {
      affected_rows
    }
  }
`

export const IMPORT_ORGANIZATION = gql`
  mutation($organizations: JSON) {
    importOrganizationUnits(organization_units: $organizations) {
      success
    }
  }
`

export const IMPORT_ORGANIZATION_VALIDATION = gql`
  query($company: uuid, $code: [String!]) {
    company_organizations(
      where: {company: {_eq: $company}, code: {_in: $code}}
    ) {
      id
      code
    }
  }
`
