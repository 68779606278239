import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'
import DetailComponent from './detail/DetailComponent'
import FormCourseComponent from './form/FormCourseComponent'
import ListComponent from './list/ListComponent'

const CoursePage = props => {
  const path = props.match.path

  const renderComponent = () => {
    switch (path) {
      case '/learning/course':
        return <ListComponent search={searchText} />
      case '/learning/course/add':
        return <FormCourseComponent />
      case '/learning/course/detail/:id':
        return <DetailComponent search={searchText} />
      case '/learning/course/edit/:id':
        return <FormCourseComponent />
      default:
        return <ListComponent search={searchText} />
    }
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }
  return (
    <Card icon="book" title="Kursus" onSearch={onSearch}>
      <PageContainer style={{minHeight: '70vh'}}>
        {renderComponent()}
      </PageContainer>
    </Card>
  )
}

export default withRouter(CoursePage)
