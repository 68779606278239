import React, {useState, useEffect} from 'react'
import MaterialTable from 'material-table'
import moment from 'moment'
import {CircularProgress} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import helperStyle, {
  CenterWrapper,
  pageSizeOption,
  TableOptions,
  TextBlueBold,
} from '../../PayrollPageStyles'
import {tableStyle} from '../../PayrollTableStyles'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import {convertToRoundedRupiah} from '../../../../../utils/helpers'

import {COMPANY_ID} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import {GET_TAX_LIST_REPORT} from '../../../../../graphql/queries'

export default function Tax(props) {
  const {period} = props
  const classes = helperStyle()

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])

  const {data: taxData, loading, error} = useQuery(GET_TAX_LIST_REPORT, {
    variables: {
      company: COMPANY_ID,
      year: Number(moment(period).format('YYYY')),
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const isPayroll = rowData => {
    return rowData.label === 'payroll'
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [period, rowsPerPage])

  return (
    <>
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {taxData?.v_finance_payroll_thr?.length === 0 && !loading && (
        <NoDataListComponent
          message={`You can set your employee and customize your payroll method on settings `}
        />
      )}
      {taxData?.v_finance_payroll_thr?.length > 0 && (
        <MaterialTable
          data={taxData?.v_finance_payroll_thr}
          isLoading={taxData && loading}
          onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
          options={{...TableOptions, paging: false}}
          page={currentPage}
          rowsPerPage={rowsPerPage}
          totalCount={taxData?.tax_list_count?.aggregate.count}
          onChangePage={page => {
            setCurrentPage(page)
          }}
          columns={[
            {
              title: 'Period',
              field: 'period',
              ...tableStyle({}, 'cell-first'),
              customSort: (a, b) => {
                return (
                  moment().set({year: a.year, month: a.month}) -
                  moment().set({year: b.year, month: b.month})
                )
              },
              render: rowData => {
                const month = moment(rowData.month.toString()).format('MMM')
                const labelType = isPayroll(rowData) ? '' : 'THR -'
                return `${labelType} ${month} ${rowData.year}`
              },
            },
            {
              title: 'Total Gross Amount',
              field: 'total_gross',
              ...tableStyle({}, 'cell-number'),
              render: rowData => {
                return convertToRoundedRupiah(
                  isPayroll(rowData)
                    ? rowData.total_gross
                    : rowData.total_final_salary_thr
                )
              },
            },
            {
              title: 'Total PPH21',
              field: 'total',
              customSort: (a, b) => {
                return (
                  a.tax_by_company +
                  a.tax_by_employee -
                  (b.tax_by_company + b.tax_by_employee)
                )
              },
              ...tableStyle({}, 'cell-number'),
              render: rowData => {
                return convertToRoundedRupiah(
                  rowData.tax_by_company + rowData.tax_by_employee,
                  false
                )
              },
            },
            {
              title: 'Action',
              field: 'action',
              sorting: false,
              ...tableStyle(
                {
                  padding: '26px 28px 26px 16px',
                },
                'cell-number'
              ),
              render: rowData => (
                <Link
                  to={{
                    pathname: `/financial/payroll/report/tax/detail`,
                    search: `?month=${rowData.month}&year=${
                      rowData.year
                    }&payroll=${isPayroll(rowData)}`,
                    state: {
                      active: 'report-tax-detail',
                      searchable: true,
                      filter: true,
                      month: rowData.month,
                      year: rowData.year,
                      payroll: isPayroll(rowData),
                    },
                  }}
                  className={classes.link}
                >
                  <TextBlueBold>Details</TextBlueBold>
                </Link>
              ),
            },
          ]}
        />
      )}
    </>
  )
}
