import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
} from '../../../../../GlobalStyles'
import {
  Button,
  IconButton,
  makeStyles,
  Divider,
  Stepper,
  withStyles,
  StepConnector,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tabs,
  Tab,
} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import StepOne from './form-step/StepOne'
import StepTwo from './form-step/StepTwo'
import StepThree from './form-step/StepThree'
import StepFour from './form-step/StepFour'
// import {DataContext} from '../../../../../ContextConfig'
import {useApolloClient, useMutation} from '@apollo/react-hooks'
import {
  ADD_SURVEY_STEP_ONE,
  UPDATE_SURVEY_STEP_ONE,
  // ADD_MULTIPLE_RESPONDENT,
} from '../../../../../graphql/mutations/survey/multirater-nps/survey-center/surveyCenter.mutation'
import {useSnackbar} from 'notistack'
import moment from 'moment'
import StepRaterList from './form-step/StepRaterList'
import {COMPANY_ID} from '../../../../../utils/globals'
import {
  GET_LIST_GROUP_QUESTION_STEPPER,
  GET_LIST_RESPONDENTS_STEP_FOUR,
  GET_LIST_SURVEY_CENTER,
} from '../../../../../graphql/queries/survey/multirater-nps/survey-center/getListSurveyCenter.query'
// import AddRater from './add-step/AddRaterComponent'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    // color: '#039be5 !important',
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  labelActive: {
    color: '#4D4F5C !important',
  },
  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  mb16: {
    marginBottom: '16px !important',
  },
  paper: {
    minHeight: '70vh',
  },
  contentDialog: {
    color: '#000',
    fontSize: 18,
    margin: '30px 0px 30px 0px',
    fontWeight: '500',
  },
  titleDialog: {
    fontSize: 22,
  },
  actionDialog: {
    padding: '28px 18px',
  },
}))

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#014a62',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#014a62',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const AntTabs = withStyles({
  root: {
    backgroundColor: '#EFF2F4',
    color: '#000',
  },
  indicator: {
    backgroundColor: '#1890ff',
    height: 2,
  },
})(Tabs)

const StyledTab = withStyles({
  root: {
    color: '#000',
    fontWeight: '500',
    fontSize: 14,
    opacity: 1,
  },
})(Tab)

const getSteps = () => {
  return ['Detail', 'Pertanyaan', 'Pengaturan', 'Daftar Penilai', 'Asesi']
}

const AddSurveyCenter = props => {
  const generateDate = () => {
    return moment(new Date()).format('YYYY-MM-DD')
  }
  const editId = props.match.params.id
  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()
  const [dataContext, setDataContext] = useState({
    dataSurveyCenter: {
      id: null,
      stepOne: {
        type: '360',
        nps: null,
        survey_name: '',
        description: '',
        status: 'DRAFT',
        date_created: generateDate(),
        start_date: generateDate(),
        end_date: generateDate(),
        scale: null,
        buttons: 1,
        data: null,
      },
    },
  })
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [isError, setIsError] = useState(false)
  const [listQuestions, setListQuestions] = useState(null)
  const [respondentLength, setRespondentLength] = useState(0)
  // const [staggedRespondent, setStaggedRespondent] = useState([])
  const [open, setOpen] = useState(false)
  const [isErrorDate, setIsErrorDate] = useState(false)
  const [checked, setChecked] = useState({
    nps: false,
    multirater: false,
    peer: {checked: false, max: 0, weight: 0},
    supervisor: {checked: false, max: 0, weight: 0},
    subordinate: {checked: false, max: 0, weight: 0},
    second_supervisor: {checked: false, max: 0, weight: 0},
    second_subordinate: {checked: false, max: 0, weight: 0},
  })

  const weightForMutation = {}
  let resultStepRaterList = 0
  const checkedRater = []
  for (const property in checked) {
    if (checked[property].weight) {
      resultStepRaterList += parseInt(checked[property].weight)
    }
    if (checked[property].checked) {
      weightForMutation[property] = {
        max: checked[property].max,
        weight: checked[property].weight,
      }
      checkedRater.push(checked[property].checked)
    }
  }

  const steps = getSteps()

  // perubahan probis ada penambahan step rater list di step 4, respondent menjadi step 5
  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <StepOne
            isError={isError}
            dataContext={dataContext}
            setDataContext={setDataContext}
            isErrorDate={isErrorDate}
            setChecked={setChecked}
            checked={checked}
            groupLength={listQuestions?.length || 0}
          />
        )
      case 1:
        return (
          <StepTwo
            isError={isError}
            setListQuestions={setListQuestions}
            dataContext={dataContext}
            setDataContext={setDataContext}
            checked={checked}
          />
        )
      case 2:
        return (
          <StepThree
            isError={isError}
            dataContext={dataContext}
            setDataContext={setDataContext}
            checked={checked}
          />
        )
      case 3:
        return (
          <StepRaterList
            checked={checked}
            setChecked={setChecked}
            result={resultStepRaterList}
            checkedRater={checkedRater}
            isError={isError}
          />
        )
      case 4:
        return (
          // step foour jadi step 5 (Respondents)
          <StepFour
            // staggedRespondent={staggedRespondent}
            // setStaggedRespondent={setStaggedRespondent}
            isError={isError}
            dataContext={dataContext}
            // setDataContext={setDataContext}
            setRespondentLength={setRespondentLength}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  const [mutationStepOne] = useMutation(ADD_SURVEY_STEP_ONE)
  const [mutationUpdateStepOne] = useMutation(UPDATE_SURVEY_STEP_ONE)
  // const [mutationAddMultipleRespondent] = useMutation(ADD_MULTIPLE_RESPONDENT)

  const fetchSurveyByID = async () => {
    const {data} = await client.query({
      query: GET_LIST_SURVEY_CENTER,
      variables: {
        company_id: COMPANY_ID,
        id: editId,
      },
      fetchPolicy: 'network-only',
    })
    if (data) {
      const {
        id,
        type,
        nps_include,
        title,
        description,
        status,
        startdate,
        enddate,
        scale,
        buttons,
        notification_email_format,
        weight_settings,
        date_created,
      } = data.multirater_surveys[0]

      setDataContext({
        dataSurveyCenter: {
          id,
          stepOne: {
            type,
            buttons,
            data: notification_email_format,
            date_created,
            description,
            end_date: enddate,
            nps: nps_include,
            scale,
            start_date: startdate,
            status,
            survey_name: title,
          },
        },
      })

      for (var name in weight_settings) {
        checked[name] = {
          ...weight_settings[name],
          checked: true,
        }
      }

      setChecked(checked)
      return id
    }
  }

  // untuk fetch checkbox multirater nps
  const fetchStep2and5 = async survey => {
    const {data} = await client.query({
      query: GET_LIST_GROUP_QUESTION_STEPPER,
      variables: {
        survey,
      },
      fetchPolicy: 'network-only',
    })
    setListQuestions(data?.multirater_survey_groups || [])
    const {data: respondent} = await client.query({
      query: GET_LIST_RESPONDENTS_STEP_FOUR,
      variables: {
        survey,
      },
      fetchPolicy: 'network-only',
    })

    if (respondent) {
      setRespondentLength(respondent.multirater_respondents.length)
    }

    if (data) {
      let isNpsExist = false
      let isMultiraterExist = false
      data.multirater_survey_groups.some(s => {
        if (s.isnps) {
          isNpsExist = true
        } else {
          isMultiraterExist = true
        }
      })
      setChecked({...checked, nps: isNpsExist, multirater: isMultiraterExist})
    }
  }

  const dateValidation = () => {
    const {start_date, end_date} = dataContext.dataSurveyCenter.stepOne
    if (moment(end_date).isBefore(start_date)) {
      const ids = document.getElementById('date')
      ids.scrollIntoView({behavior: 'smooth'})

      setIsErrorDate(true)
      setTimeout(() => {
        setIsErrorDate(false)
      }, 3000)
    }
  }

  const handleNext = () => {
    const {
      type,
      survey_name,
      description,
      status,
      start_date,
      end_date,
      scale,
      buttons,
    } = dataContext.dataSurveyCenter.stepOne
    if (activeStep === 0) {
      const {stepOne} = dataContext.dataSurveyCenter
      const {start_date, end_date} = dataContext.dataSurveyCenter.stepOne

      if (
        stepOne.survey_name === '' ||
        stepOne.description === '' ||
        (!checked.nps && !checked.multirater)
      ) {
        setIsError(true)
        setTimeout(() => setIsError(false), 5000)
        dateValidation()
      } else if (moment(end_date).isBefore(start_date)) {
        const ids = document.getElementById('date')
        ids.scrollIntoView({behavior: 'smooth'})

        setIsErrorDate(true)
        setTimeout(() => {
          setIsErrorDate(false)
        }, 5000)
      } else {
        if (!dataContext.dataSurveyCenter.id) {
          const datasend = {
            type,
            nps: checked.nps,
            title: survey_name,
            description,
            status,
            start_date,
            end_date,
            scale,
            buttons,
          }
          mutationStepOne({variables: datasend}).then(res => {
            setDataContext({
              ...dataContext,
              dataSurveyCenter: {
                ...dataContext.dataSurveyCenter,
                id: res.data.insert_multirater_surveys.returning[0].id,
              },
            })
            setActiveStep(prevActiveStep => prevActiveStep + 1)
          })
        } else {
          const data = {
            id: dataContext.dataSurveyCenter.id,
            type,
            nps: checked.nps,
            title: survey_name,
            description,
            status,
            start_date,
            end_date,
            scale,
            buttons,
          }
          mutationUpdateStepOne({variables: data}).then(() => {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
          })
        }
      }
    } else if (activeStep === 1) {
      const {isErrorGroupQuestion, isErrorQuestion} = isErrorTabQuestion(
        listQuestions
      )
      if (isErrorGroupQuestion || isErrorQuestion) {
        setIsError(true)
        setTimeout(() => setIsError(false), 5000)
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    } else if (activeStep === 2) {
      /* WLB-3168 hide field email notification */
      // if (!dataContext.dataSurveyCenter.stepOne.data) {
      //   setIsError(true)
      //   setTimeout(() => setIsError(false), 5000)
      // } else {
      const datasend = {
        id: dataContext.dataSurveyCenter.id,
        type,
        nps: checked.nps,
        title: survey_name,
        description,
        status,
        start_date,
        end_date,
        scale,
        buttons,
        notif_email: dataContext.dataSurveyCenter.stepOne.data,
      }
      mutationUpdateStepOne({variables: datasend})
        .then(() => {
          setActiveStep(prevActiveStep => prevActiveStep + 1)
        })
        .catch(() => {
          enqueueSnackbar(`Update Respondent failed`, {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
      // }
    } else if (activeStep === 3) {
      const datas = {
        id: dataContext.dataSurveyCenter.id,
        type,
        nps: checked.nps,
        title: survey_name,
        description,
        status,
        start_date,
        end_date,
        scale,
        buttons,
        weight_settings: weightForMutation,
        notif_email: dataContext.dataSurveyCenter.stepOne.data,
      }

      mutationUpdateStepOne({
        variables: datas,
      })
        .then(() => {
          setActiveStep(prevActiveStep => prevActiveStep + 1)
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        })
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const backtoTable = () => {
    props.history.push({
      pathname: '/survey/multirater-nps/survey-center',
      state: {active: 'survey-center', searchable: true},
    })
  }

  const handleClickCancel = () => {
    if (!editId) {
      // logic add
      if (activeStep !== 0) {
        const {
          type,
          // nps,
          survey_name,
          description,
          start_date,
          end_date,
          scale,
          buttons,
        } = dataContext.dataSurveyCenter.stepOne
        const data = {
          id: dataContext.dataSurveyCenter.id,
          type,
          nps: checked.nps,
          title: survey_name,
          description,
          status: 'DRAFT',
          start_date,
          end_date,
          scale,
          buttons,
        }
        mutationUpdateStepOne({variables: data}).then(() => {
          backtoTable()
        })
      } else if (activeStep === 0 && dataContext.dataSurveyCenter.id) {
        const {
          type,
          // nps,
          survey_name,
          description,
          start_date,
          end_date,
          scale,
          buttons,
        } = dataContext.dataSurveyCenter.stepOne
        const data = {
          id: dataContext.dataSurveyCenter.id,
          type,
          nps: checked.nps,
          title: survey_name,
          description,
          status: 'DRAFT',
          start_date,
          end_date,
          scale,
          buttons,
        }
        mutationUpdateStepOne({variables: data}).then(() => {
          backtoTable()
        })
      } else {
        props.history.goBack()
      }
    } else {
      // logic edit
      props.history.goBack()
    }
  }

  useEffect(() => {
    if (editId) {
      fetchSurveyByID().then(id => fetchStep2and5(id))
    }
  }, [editId])

  const handlePublish = () => {
    if (editId) {
      // logic untuk edit
      const {stepOne} = dataContext.dataSurveyCenter
      const {isErrorGroupQuestion, isErrorQuestion} = isErrorTabQuestion(
        listQuestions
      )
      const {start_date, end_date, scale} = dataContext.dataSurveyCenter.stepOne
      const thisIsError = activeTab => {
        setIsError(true)
        setTimeout(() => setIsError(false), 5000)
        setActiveStep(activeTab)
      }
      if (
        stepOne.survey_name === '' ||
        stepOne.description === '' ||
        (!checked.nps && !checked.multirater)
      ) {
        thisIsError(0)
        dateValidation()
      } else if (moment(end_date).isBefore(start_date)) {
        setActiveStep(0)
        setIsErrorDate(true)
        setTimeout(() => {
          setIsErrorDate(false)
        }, 5000)
      } else if (isErrorGroupQuestion || isErrorQuestion) {
        thisIsError(1)
      } else if (checked.multirater && !scale) {
        thisIsError(2)
      } else if (resultStepRaterList !== 100 || checkedRater.length === 0) {
        thisIsError(3)
      } else if (respondentLength === 0) {
        thisIsError(4)
      } else {
        setOpen(true)
      }
    } else {
      // logic untuk add
      if (respondentLength === 0) {
        setIsError(true)
        setTimeout(() => setIsError(false), 5000)
      } else {
        setOpen(true)
      }
    }
  }

  const handleSubmit = () => {
    const successed = () => {
      enqueueSnackbar(`All Changes have been saved`, {
        variant: 'success',
        autoHideDuration: 1000,
      })
      backtoTable()
    }
    if (editId) {
      const {
        type,
        survey_name,
        description,
        status,
        start_date,
        end_date,
        scale,
        buttons,
        data,
      } = dataContext.dataSurveyCenter.stepOne

      mutationUpdateStepOne({
        variables: {
          id: dataContext.dataSurveyCenter.id,
          type,
          nps: checked.nps,
          title: survey_name,
          description,
          status,
          start_date,
          end_date,
          scale,
          buttons,
          notif_email: data,
          weight_settings: weightForMutation,
        },
      })
        .then(() => {
          successed()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
            autoHideDuration: 1000,
          })
        )
    } else {
      successed()
    }

    // mutationAddMultipleRespondent({
    //   variables: {
    //     object: staggedRespondent,
    //   },
    // })
    //   .then(() => {
    //     setOpen(false)

    // })
    //   .catch(() => {
    //     enqueueSnackbar(`Update Respondent failed`, {
    //       variant: 'error',
    //       autoHideDuration: 1000,
    //     })
    //   })
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setActiveStep(newValue)
  }

  const renderButtonCancel = () => {
    if (editId) {
      return (
        <Button
          className={classes.backButton}
          component={Link}
          onClick={handleClickCancel}
          size="large"
        >
          Batal
        </Button>
      )
    } else {
      if (activeStep === 0) {
        return (
          <Button
            className={classes.backButton}
            component={Link}
            onClick={handleClickCancel}
            size="large"
          >
            Batal
          </Button>
        )
      } else {
        return (
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={handleBack}
            className={classes.backButton}
          >
            Sebelumnya
          </Button>
        )
      }
    }
  }

  const renderButtonSubmit = () => {
    let handle
    let text
    if (editId) {
      handle = handlePublish
      text = 'Kirim'
    } else {
      if (activeStep === steps.length - 1) {
        handle = handlePublish
        text = 'Kirim'
      } else {
        handle = handleNext
        text = 'Selanjutnya'
      }
    }
    return {handle, text}
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link onClick={handleClickCancel}>
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6">
            {editId ? 'Ubah' : 'Tambah'} Umpan Balik
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          {renderButtonCancel()}
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={renderButtonSubmit().handle}
            disabled={
              !editId && activeStep === 3 && resultStepRaterList !== 100
            }
          >
            {renderButtonSubmit().text}
          </Button>
        </div>
      </FormToolbar>
      {editId ? (
        <AntTabs
          value={activeStep}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTabs}
          aria-label="disabled tabs example"
          className={classes.tabs}
        >
          <StyledTab label="Detail Umpan Balik" />
          <StyledTab label="Pertanyaan" />
          <StyledTab label="Pengaturan" />
          <StyledTab label="Daftar Penilai" />
          <StyledTab label="Asesi" />
        </AntTabs>
      ) : (
        <>
          <Divider className={classes.mb16} />
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<CustomConnector />}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    active: classes.labelActive,
                    completed: classes.labelActive,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.iconRoot,
                      text: classes.iconText,
                      active: classes.iconActive,
                      completed: classes.iconActive,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      )}

      <div style={{padding: '2rem'}}>{getStepContent(activeStep)}</div>

      <Dialog
        maxwidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle className={classes.titleDialog} style={{color: '#014A62'}}>
          {editId ? 'Simpan Perubahan' : 'Kirim Umpan Balik?'}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText className={classes.contentDialog}>
            Apakah Anda yakin{' '}
            {editId
              ? 'ingin menyimpan perubahan?'
              : 'untuk mengirimkan laporan?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionDialog}>
          <Button onClick={() => setOpen(false)}>Batal</Button>
          <Button
            onClick={handleSubmit}
            size="large"
            variant="contained"
            color="primary"
          >
            Kirim
          </Button>
        </DialogActions>
      </Dialog>
    </PaperContainer>
  )
}

const isErrorTabQuestion = (listQuestions = []) => {
  let isErrorQuestion = false
  const group = listQuestions?.map(({multirater_survey_questions}) => {
    if (multirater_survey_questions.length === 0) {
      isErrorQuestion = true
    }
    return multirater_survey_questions
  })

  return {isErrorGroupQuestion: group?.length === 0, isErrorQuestion}
}

export default withRouter(AddSurveyCenter)
