import React, {useState} from 'react'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import MaterialTable from 'material-table'
import {
  PaperContainer,
  EnhancedToolbar,
  AddEditTitle,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import {Link, withRouter} from 'react-router-dom'
import Loading from '../../../../components/loading/LoadingComponent'
import {GET_LIST_QUESTION_BANK} from '../../../../graphql/queries'
import {DELETE_QUESTION_BANK} from '../../../../graphql/mutations'
import {useMutation, useQuery} from '@apollo/react-hooks'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {useSnackbar} from 'notistack'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import {TableLocalization} from '../../../shared-components/table/TableOptions'

const TableOptions = {
  search: false,
  selection: false,
  sorting: true,
  headerStyle: {
    fontWeight: 700,
    lineHeight: 1.43,
    marginTop: `-100px`,
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
  selectionProps: () => ({
    color: 'primary',
  }),
  toolbar: false,
}

const ViewQuestionBank = props => {
  const {id} = props.match.params
  const {enqueueSnackbar} = useSnackbar()
  const [state, setState] = useState({
    id: '',
    name: '',
  })
  const [openDelete, setOpenDelete] = useState(false)
  const {data, error, refetch} = useQuery(GET_LIST_QUESTION_BANK, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  })

  const [mutationDeleteQuestion] = useMutation(DELETE_QUESTION_BANK)

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const handleClickOpen = (id, name) => {
    setState({id, name})
    setOpenDelete(true)
  }

  const handleClose = () => {
    setOpenDelete(false)
  }

  const onDeleteQuestion = () => {
    const variables = {
      id: state.id,
    }

    mutationDeleteQuestion({variables})
      .then(() => {
        enqueueSnackbar('Pertanyaan dihapus', {
          variant: 'success',
          autoHideDuration: 500,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete scoring failed, please try again later', {
          variant: 'error',
          autoHideDuration: 500,
        })
      })
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Toolbar disableGutters>
          <Link
            to={{
              pathname: `/survey/multirater-nps/question-bank`,
              state: {active: 'question-bank'},
            }}
          >
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6">Pertanyaan</AddEditTitle>
        </Toolbar>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: `/survey/multirater-nps/question-bank/${id}/add`,
              state: {active: 'add-question'},
            }}
          >
            Tambah Pertanyaan
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider></Divider>
      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0'}}
        columns={[
          {
            title: 'No',
            render: rowData => rowData.tableData.id + 1,
          },
          {
            title: 'Pertanyaan',
            field: 'question_self',
          },
          {
            title: 'Aksi',
            field: 'id',
            sorting: false,
            render: rowData => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>
                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        component={Link}
                        to={{
                          pathname: `/survey/multirater-nps/question-bank/${id}/edit/${rowData.id}`,
                          state: {
                            active: 'edit-question',
                          },
                        }}
                        variant="h6"
                      >
                        Ubah
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          handleClickOpen(rowData.id, '')
                          popupState.close()
                        }}
                      >
                        Hapus
                      </StyledMenuItem>
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            ),
          },
        ]}
        data={data.multirater_bank_questions}
        title=""
        options={TableOptions}
        components={{
          Toolbar: () => null,
        }}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent />,
          },
        }}
      />
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        id={state.id}
        name={state.name}
        mutation={() => onDeleteQuestion()}
      />
    </PaperContainer>
  )
}

export default withRouter(ViewQuestionBank)
