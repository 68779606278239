import {useMemo} from 'react'

function useEllipsisText({maxLine = 5, maxLength = 500, text = ''}) {
  const isOverLength = useMemo(() => text?.length > maxLength, [text])
  const isOverLine = useMemo(() => text?.split('\n')?.length > maxLine, [text])
  const briefText = useMemo(
    () =>
      isOverLine
        ? text
            ?.split('\n')
            ?.slice(0, maxLine)
            ?.join('\n')
        : isOverLength
        ? text?.slice(0, maxLength)
        : text,
    [text]
  )

  return {
    briefText,
    isOverLength,
    isOverLine,
  }
}

export default useEllipsisText
