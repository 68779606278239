export const COMPANY_GOAL_TYPE = 'COMPANY_GOAL'
export const PERSONAL_GOAL_TYPE = 'PERSONAL_GOAL'

export const TASK_STATUS = {
  TODO: 'To Do',
  INPROGRESS: 'In Progress',
  DONE: 'Done',
  COMPLETED: 'Complete',
  ARCHIVED: 'Archived',
}

export const TASK_STATUS_IDN = {
  TODO: 'Akan Dikerjakan',
  INPROGRESS: 'Sedang Dikerjakan',
  DONE: 'Selesai Dikerjakan',
  COMPLETED: 'Selesai',
  ARCHIVED: 'Diarsipkan',
}

export const TASK_PRIORITY = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
}

export const STATUS_OPTIONS = [
  {
    value: 'TODO',
    label: 'To Do',
  },
  {
    value: 'INPROGRESS',
    label: 'In Progress',
  },
  {
    value: 'DONE',
    label: 'Done',
  },
  {
    value: 'COMPLETED',
    label: 'Complete',
  },
]

export const PRIORITY_STYLE = {
  HIGH: '#ef4d5e',
  MEDIUM: '#ffa000',
  LOW: '#039be5',
}

export const TYPE_STYLE = {
  [COMPANY_GOAL_TYPE]: '#a35a31',
  [PERSONAL_GOAL_TYPE]: '#874caf',
}

export const TYPE = {
  [COMPANY_GOAL_TYPE]: 'Company Goal',
  [PERSONAL_GOAL_TYPE]: 'Personal Goal',
}

export const TYPE_IDN = {
  [COMPANY_GOAL_TYPE]: 'RHK Unit',
  [PERSONAL_GOAL_TYPE]: 'RHK Pribadi',
}

export const DONE_STATUS_ASSIGNMENT = ['DONE', 'COMPLETED']
