import SuccessionPage from './SuccessionPageComponent'

export const SuccessionPageConfig = {
  routes: [
    {
      path: '/talent/succession',
      component: SuccessionPage,
      state: 'succession',
      exact: true,
    },
    {
      path: '/talent/succession/:id',
      component: SuccessionPage,
      state: 'detail-succession',
      exact: true,
    },
    {
      path: '/talent/succession/:id/list-successor',
      component: SuccessionPage,
      state: 'detail-succession',
      exact: true,
    },
    {
      path: '/talent/succession/:id/list-talent-pool',
      component: SuccessionPage,
      state: 'detail-succession',
      exact: true,
    },
    {
      path: '/talent/succession/compare/:id',
      component: SuccessionPage,
      state: 'compare-talent',
      exact: true,
    },
    {
      path: '/talent/succession/aspiration',
      component: SuccessionPage,
      state: 'aspiration',
      exact: true,
    },
    {
      path: '/talent/succession/aspiration/:id',
      component: SuccessionPage,
      state: 'detail-aspiration',
    },
    {
      path: '/talent/succession/:id/add-pool',
      component: SuccessionPage,
      state: 'add-pool',
      exact: true,
    },
    {
      path: '/talent/succession/development-plan',
      component: SuccessionPage,
      state: 'development-plan',
      exact: true,
    },
    {
      path: '/talent/succession/development-plan/detail',
      component: SuccessionPage,
      state: 'development-detail',
      exact: true,
    },
  ],
}
