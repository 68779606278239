import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {withStyles, Tab, Typography, Divider, Badge} from '@material-ui/core'

import {PaperContainer, EnhancedToolbar} from '../../../../GlobalStyles'
import NoDataListFree from '../../../shared-components/NoDataListFreeComponent'

import {DEFAULT_PAGE_SIZE, ExtendedTabs} from '../ClaimPageStyles'
import ClaimHistoryList from './ClaimHistoryList'
import ClaimActionModal from './ClaimActionModal'

import {getClaimFilterVariables} from '../claimFilterConfig'
import {
  GET_AVAILABLE_CLAIM_REQUEST,
  GET_CLAIM_ACTIVITIES,
} from '../../../../graphql/queries/claim/getClaimHistory.query'

const ExtendedBadge = withStyles({
  dot: {
    top: 4,
    right: -8,
  },
})(Badge)

// 0 = Activity
// 1 = Request

function ClaimActivity(props) {
  const [currentTab, setCurrentTab] = React.useState(0)
  const [detailsState, setDetailsState] = React.useState(null)

  // eslint-disable-next-line no-unused-vars
  const handleTabChange = (event, tab) => {
    setCurrentTab(tab)
  }

  // eslint-disable-next-line no-unused-vars
  const openDetailsModal = (event, data) => {
    setDetailsState({open: true, id: data.id})
  }

  const closeDetailsModal = () => {
    setDetailsState({...detailsState, open: false})
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
          Claim Activity
        </Typography>
      </EnhancedToolbar>

      <Divider />

      <ExtendedTabs value={currentTab} onChange={handleTabChange}>
        <Tab value={0} label="Activity" />
        <Tab
          value={1}
          label={
            <ClaimRequestBadge invisible={currentTab === 1}>
              Request
            </ClaimRequestBadge>
          }
        />
      </ExtendedTabs>

      <ClaimHistory
        {...props}
        type={currentTab}
        onDetailsClick={openDetailsModal}
      />

      <ClaimActionModal
        key={detailsState && detailsState.id}
        {...detailsState}
        onClose={closeDetailsModal}
        showApproveActions={currentTab === 1}
      />
    </PaperContainer>
  )
}

export default ClaimActivity

function ClaimRequestBadge(props) {
  const {invisible, ...rest} = props

  const {data} = useQuery(GET_AVAILABLE_CLAIM_REQUEST, {
    wlb_skipPatch: true,
    skip: invisible,
    fetchPolicy: 'cache-and-network',
  })

  return (
    <ExtendedBadge
      color="error"
      variant="dot"
      invisible={invisible || !data?.claims.length}
      {...rest}
    />
  )
}

const INITIAL_PAGINATION = {
  page: 0,
  limit: DEFAULT_PAGE_SIZE,
}

function ClaimHistory(props) {
  const {type, searchText, filterData, onDetailsClick} = props

  const [pagination, setPagination] = React.useState(INITIAL_PAGINATION)

  const where = React.useMemo(() => {
    return getClaimFilterVariables({
      tabsValue: type === 1 ? 'request' : 'activity',
      searchText,
      filterData,
    })
  }, [searchText, filterData, type])

  const {data, loading, error} = useQuery(GET_CLAIM_ACTIVITIES, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: where,
      offset: pagination.page * pagination.limit,
      limit: pagination.limit,
    },
  })

  const handleChangeOffset = page => {
    if (pagination.page !== page) {
      setPagination({...pagination, page})
    }
  }

  const handleChangeLimit = limit => {
    setPagination({page: 0, limit})
  }

  React.useLayoutEffect(() => handleChangeOffset(0), [filterData])

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  if (!loading && !data?.total.aggregate.count) {
    return (
      <NoDataListFree
        message1="Sorry, No List"
        message2={
          type
            ? 'There are no recent claim requests'
            : 'There are no claims yet'
        }
      />
    )
  }

  return (
    <ClaimHistoryList
      data={data?.claims}
      totalCount={data?.total.aggregate.count}
      loading={loading}
      offset={pagination.page}
      limit={pagination.limit}
      onChangeOffset={handleChangeOffset}
      onChangeLimit={handleChangeLimit}
      onDetailsClick={onDetailsClick}
    />
  )
}
