import gql from 'graphql-tag'

export const GET_LIST_TABLE_DESIGNATION = gql`
  query($offset: Int, $limit: Int, $search: String) {
    total: company_employee_positions_aggregate(
      where: {
        _or: [{name: {_ilike: $search}}, {designation_code: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
    company_employee_positions(
      offset: $offset
      limit: $limit
      where: {
        _or: [{name: {_ilike: $search}}, {designation_code: {_ilike: $search}}]
      }
    ) {
      id
      designation_code
      name
      status
    }
  }
`

export const GET_DESIGNATION_DETAIL = gql`
  query($id: Int) {
    company_employee_positions(where: {id: {_eq: $id}}) {
      id
      designation_code
      name
      description
      status
      job_function: job_family_mapping {
        id
        code
        name
      }
    }
  }
`

export const GET_COMPANY_PROFILE_BY_DESIGNATION = gql`
  query($jobTitle: Int) {
    total: company_job_profiles_aggregate(where: {position: {_eq: $jobTitle}}) {
      aggregate {
        count
      }
    }
    company_job_profiles(where: {position: {_eq: $jobTitle}}, limit: 5) {
      id
      code
      title
    }
  }
`

export const GET_LIST_DESIGNATION = gql`
  query($offset: Int, $limit: Int, $search: String) {
    total: company_employee_positions_aggregate(
      where: {
        _or: [{name: {_ilike: $search}}, {designation_code: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
    company_employee_positions(
      offset: $offset
      limit: $limit
      where: {
        _or: [{name: {_ilike: $search}}, {designation_code: {_ilike: $search}}]
      }
    ) {
      id
      designation_code
      name
      description
      status
      job_family_mapping {
        job_family {
          id
          name
        }
        function_id: id
        function_name: name
      }
      count_employee
      company_job_profiles_aggregate {
        aggregate {
          count
        }
      }
      company_job_profiles(limit: 5) {
        id
        title
      }
    }
  }
`

export const GET_DROPDOWN_DESIGNATION = gql`
  query($offset: Int, $limit: Int, $search: String) {
    total: company_employee_positions_aggregate(
      where: {
        _or: [{name: {_ilike: $search}}, {designation_code: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
    company_employee_positions(
      offset: $offset
      limit: $limit
      where: {
        _or: [{name: {_ilike: $search}}, {designation_code: {_ilike: $search}}]
        status: {_ilike: "Active"}
      }
    ) {
      id
      designation_code
      name
      description
      status
      job_family_mapping {
        job_family {
          id
          name
        }
        function_id: id
        function_name: name
      }
      count_employee
      company_job_profiles_aggregate {
        aggregate {
          count
        }
      }
      company_job_profiles(limit: 5) {
        id
        title
      }
    }
  }
`

export const CHECK_CODE_JOB_TITLE = gql`
  query($company: uuid, $code: String) {
    company_employee_positions_aggregate(
      where: {company: {_eq: $company}, designation_code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`
