import {useQuery} from '@apollo/react-hooks'
import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import {AssignFilter} from '@worklifebeyond/wlb-utils-components'
import MaterialTable from 'material-table'
import React, {useEffect, useState} from 'react'
import {BlueIcon, FlexBetween} from '../../../../../GlobalStyles'
import {GET_FILTER_POSITION} from '../../../../../graphql/queries'
import assignFiltersOld from '../../../../../utils/assignFiltersOld'
import {COMPANY_ID} from '../../../../../utils/globals'
import {
  TableLocalization,
  TableOptions,
} from '../../../../shared-components/table/TableOptions'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'

export default function CourseAssign({isError, variables, setVars, setCount}) {
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const {data, error, loading} = useQuery(GET_FILTER_POSITION, {
    variables: {
      ...variables,
      companyId: COMPANY_ID,
      search: `%${search}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
    fetchPolicy: 'no-cache',
  })

  const selections = React.useMemo(() => {
    const include = {}
    const exclude = {}

    for (const v in variables) {
      const {_in, _nin} = variables[v]

      include[v] = _in
      exclude[v] = _nin
    }

    return {include, exclude}
  }, [variables])

  useEffect(() => {
    if (data) {
      setCount(data.totalAll.aggregate.count)
    }
  }, [data])

  const handleApplyAssign = result => {
    const {include, exclude} = result

    const _vars = {}

    for (const p in include) {
      if (include[p] && include[p].length > 0) {
        if (!_vars[p]) {
          _vars[p] = {}
        }

        _vars[p]._in = include[p]
      }
    }

    for (const p in exclude) {
      if (exclude[p] && exclude[p].length > 0) {
        if (!_vars[p]) {
          _vars[p] = {}
        }

        _vars[p]._nin = exclude[p]
      }
    }

    setPagination({...pagination, offset: 0})
    setVars(_vars)
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  if (error) {
    JSON.stringify(error)
  }

  return (
    <>
      <FlexBetween style={{marginBottom: 20}}>
        <Typography variant="body2">
          {!loading && `Terapkan ke ${data.totalAll.aggregate.count} Posisi`}{' '}
          {!loading && (
            <>
              <br />
              <Typography
                variant="body2"
                display="inline"
                style={{color: '#FFA100'}}
              >
                *Catatan: Anda masih dapat memilih partisipan dengan tombol
                &quot;Saring&quot; di kanan
              </Typography>
            </>
          )}{' '}
          {isError && data && data.total.aggregate.count === 0 && (
            <Typography variant="body2" color="error" display="inline">
              Bagian ini diperlukan
            </Typography>
          )}
        </Typography>
        <AssignFilter
          filters={assignFiltersOld}
          limit={20}
          initialSelections={selections}
          onApply={handleApplyAssign}
          language="indonesian"
        >
          <Button size="large" variant="contained" color="primary">
            Saring
          </Button>
        </AssignFilter>
      </FlexBetween>
      <TextField
        placeholder="Cari"
        variant="outlined"
        size="small"
        fullWidth
        value={search}
        onChange={e => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BlueIcon>search</BlueIcon>
            </InputAdornment>
          ),
        }}
        style={{marginBottom: 10}}
      />
      <MaterialTable
        columns={[
          {
            title: 'Kode Posisi Jabatan',
            field: 'code',
            render: row => (row.code ? `[${row.code}]` : '-'),
            cellStyle: {color: '#039BE5'},
          },
          {
            title: 'Posisi',
            field: 'title',
          },
        ]}
        options={{
          ...TableOptions,
          selection: false,
          emptyRowsWhenPaging: false,
        }}
        components={{
          Toolbar: () => null,
          Container: props => <Paper {...props} elevation={0} />,
        }}
        data={data && data.company_job_profiles}
        isLoading={loading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={data && data.total.aggregate.count}
        page={pagination.offset}
        pageSize={pagination.limit}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
      />
    </>
  )
}
