import {
  Button,
  Icon,
  Menu,
  Paper,
  Radio,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useState, useEffect } from 'react'
import { ChromePicker } from 'react-color'
import {
  FormChildButton,
  FormChildContainerFull,
  ThinTextfield,
  TitleName,
  GreyTypography,
} from '../../../../../../GlobalStyles'
import { UPLOAD_URL, TOKEN } from '../../../../../../utils/globals'
import Axios from 'axios'
import { useSnackbar } from 'notistack'
import ModalGlobalIcon from '../../../../../shared-components/popup/GlobalIcon'
import { withRouter } from 'react-router-dom'
import { integerOnly } from '../../../../../../utils/helpers'

const AddDetailBox = props => {
  // const {id} = props.match.params
  const { enqueueSnackbar } = useSnackbar()
  const {
    classes,
    selectedX,
    selectedY,
    setValues,
    values,
    // mutationUpdateBoxes,
    colorOptions,
    setColorOptions,
    handleCloseDetail,
    isTalent,
  } = props

  const dataBoxes = [...values.talent_boxes.data]

  const index = dataBoxes.findIndex(
    x => x.index_x === selectedX && x.index_y === selectedY
  )
  const selectedData = dataBoxes[index]
  const [color, setColor] = useState('')
  const [level, setLevel] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [badge, setBadge] = useState(null)
  const [loading, setLoading] = useState(0)
  const [openImage, setOpenImage] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleChooseColor = event => {
    setColor(event.target.value)
  }

  const handleChangePicker = color => {
    setColor(color.hex)
  }

  const handleChangeFile = async e => {
    const tempFile = Array.from(e.target.files)
    const val = tempFile[0]
    if (val.size > 1000 * 1000 * 25) {
      enqueueSnackbar("file size can't be more than 25MB", { variant: 'error' })
    } else {
      const formData = new FormData()
      formData.append('file', val)
      try {
        const response = await Axios.post(UPLOAD_URL, formData, {
          withCredentials: true,
          onUploadProgress: progressEvent => {
            const loads = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            )
            setLoading(loads)
          },

          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data',
          },
        })
        setBadge(response.data.url)
        setLoading(0)
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' })
      }
    }
  }

  const handleSave = () => {
    const newData = {
      index_x: selectedX,
      index_y: selectedY,
      level,
      name,
      badge,
      color_hex: color,
      description,
    }
    const newIndex = dataBoxes.findIndex(
      x => x.index_x === newData.index_x && x.index_y === newData.index_y
    )
    // for edit
    // if (id) {
    //   dataBoxes[newIndex] = {
    //     ...newData,
    //     id: dataBoxes[newIndex].id,
    //   }
    // } else {
    dataBoxes[newIndex] = newData
    // }

    if (!color || level === '' || !name || !badge || !description) {
      setIsError(true)
      setTimeout(() => setIsError(false), 5000)
    } else {
      // for edit
      // if (id) {
      //   mutationUpdateBoxes({
      //     variables: {
      //       id: dataBoxes[newIndex].id,
      //       data: {...dataBoxes[newIndex], version: id},
      //     },
      //   })
      //     .then(() => {
      //       enqueueSnackbar('Success', {
      //         variant: 'success',
      //         autoHideDuration: 1000,
      //       })
      //       setValues({
      //         ...values,
      //         talent_boxes: {data: dataBoxes},
      //       })
      //       handleCloseDetail()
      //     })
      //     .catch(e =>
      //       enqueueSnackbar(JSON.stringify(e), {
      //         variant: 'error',
      //         autoHideDuration: 7500,
      //       })
      //     )
      // } else {
      setValues({
        ...values,
        talent_boxes: { data: dataBoxes },
      })
      handleCloseDetail()
      // }
    }
  }

  useEffect(() => {
    if (dataBoxes?.[index]?.name) {
      setName(selectedData.name)
      setLevel(selectedData.level)
      setBadge(selectedData.badge)
      setColor(selectedData.color_hex)
      setDescription(selectedData.description)
    }
  }, [])

  return (
    <Paper className={classes.paperDetail}>
      <Typography
        className={classes.title}
        variant="h6"
      >{`Kotak ${selectedX} x ${selectedY}`}</Typography>
      <FormChildContainerFull>
        <TitleName>
          {isTalent
            ? 'Tingkatan Klasifikasi Talenta'
            : 'Level Klasifikasi Kotak'}
        </TitleName>
        <ThinTextfield
          width="200px"
          variant="outlined"
          margin="normal"
          value={level}
          onChange={e => setLevel(integerOnly(e.target.value))}
          error={!level && isError}
          helperText={!level && isError ? 'Bagian ini diperlukan' : ''}
        ></ThinTextfield>
      </FormChildContainerFull>
      <FormChildContainerFull>
        <TitleName>{isTalent ? 'Nama Tingkatan' : 'Nama Level'}</TitleName>
        <ThinTextfield
          variant="outlined"
          margin="normal"
          value={name}
          onChange={e => setName(e.target.value)}
          error={!name && isError}
          helperText={!name && isError ? 'Bagian ini diperlukan' : ''}
        ></ThinTextfield>
      </FormChildContainerFull>
      <FormChildContainerFull>
        <TitleName style={{ marginBottom: 16 }}>
          {isTalent ? 'Tingkatan Lencana' : 'Lencana Level'}
        </TitleName>
        <div className={classes.badgeContainer}>
          <div
            className={classes.chooseContainer}
            onClick={() => setOpenImage(true)}
          >
            <Icon className={classes.darkGreyIcon}>image</Icon>
            <GreyTypography variant="caption" component="p">
              Pilih Gambar
            </GreyTypography>
          </div>
          <GreyTypography variant="body2" style={{ marginRight: '1.6rem' }}>
            atau
          </GreyTypography>
          <input
            style={{ display: 'none' }}
            type="file"
            id="atch"
            onChange={handleChangeFile}
            accept="image/png, image/jpeg"
          />
          <label htmlFor="atch">
            <div className={classes.chooseContainer}>
              <Icon className={classes.darkGreyIcon}>add</Icon>
              <GreyTypography variant="caption" component="p">
                Unggah gambar
              </GreyTypography>
            </div>
          </label>
          {badge && loading === 0 ? (
            <div className={classes.previewContainer}>
              <div style={{ marginRight: 8 }}>
                <img src={badge} alt="badgeUploaded" width="120"></img>
              </div>

              <IconButton
                size="small"
                color="secondary"
                onClick={() => setBadge(null)}
              >
                <Icon>close</Icon>
              </IconButton>
            </div>
          ) : loading > 0 || loading < 100 ? (
            <CircularProgress variant="static" value={loading} />
          ) : (
            ''
          )}
        </div>
        {!badge && isError && (
          <Typography variant="caption" style={{ color: 'red' }}>
            Bagian ini diperlukan
          </Typography>
        )}
      </FormChildContainerFull>
      <FormChildContainerFull>
        <TitleName>{isTalent ? 'Tingkatan Warna' : 'Warna Level'}</TitleName>
        <div className={classes.colorContainer}>
          {colorOptions.map((col, i) => {
            return (
              <Radio
                key={i}
                onChange={handleChooseColor}
                value={col.color}
                checked={color === col.color}
                icon={
                  <span
                    className={classes.icon}
                    style={{ background: col.color }}
                  />
                }
                checkedIcon={
                  <Icon
                    className={classes.checkedIcon}
                    style={{
                      background: col.color,
                      fontSize: 20,
                    }}
                  >
                    check
                  </Icon>
                }
              ></Radio>
            )
          })}
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <>
                <Icon
                  {...bindTrigger(popupState)}
                  style={{
                    border: '1px #a9a8a8 solid',
                    color: '#a9a8a8',
                    borderRadius: 5,
                    margin: '8px 16px 0 9px',
                    cursor: 'pointer',
                    fontSize: 19,
                  }}
                >
                  add
                </Icon>
                <Menu
                  style={{ padding: 14, marginBottom: 10 }}
                  {...bindMenu(popupState)}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <ChromePicker
                    color={color || false}
                    value={color}
                    onChangeComplete={handleChangePicker}
                  />
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: '80%' }}
                      onClick={() => {
                        setColorOptions(prev => [...prev, { color }])
                        popupState.close()
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </Menu>
              </>
            )}
          </PopupState>
        </div>
        {!color && isError && (
          <Typography variant="caption" style={{ color: 'red' }}>
            Bagian ini diperlukan
          </Typography>
        )}
      </FormChildContainerFull>
      <FormChildContainerFull>
        <TitleName>
          {isTalent ? 'Deskripsi Tingkatan' : 'Deskripsi Level'}
        </TitleName>
        <TextField
          variant="outlined"
          margin="normal"
          rows="4"
          multiline
          InputProps={{
            classes: { multiline: classes.white },
          }}
          fullWidth
          value={description}
          onChange={e => setDescription(e.target.value)}
          error={!description && isError}
          helperText={!description && isError ? 'Bagian ini diperlukan' : ''}
        ></TextField>
      </FormChildContainerFull>
      {/* <FormChildContainerFull>
        <TitleName>Potential Indicator Weight</TitleName>
        <Grid container className={classes.indicatorContainer}>
          <Grid container item spacing={3} className={classes.m0}>
            <Grid container item xs={6}>
              <TitleName>Indicator</TitleName>
            </Grid>
            <Grid container item xs={6}>
              <TitleName>Indicator Weight</TitleName>
            </Grid>
          </Grid>
          {potentialIndicator.map((res, idx) => {
            return (
              <Grid key={idx} container item spacing={2} className={classes.m0}>
                <Grid container item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    {res.name}
                  </Typography>
                </Grid>
                <Grid container item xs={3}>
                  <ThinTextfield variant="outlined"></ThinTextfield>
                </Grid>
                <Grid container item xs={3}>
                  <Typography variant="body1" gutterBottom>
                    {res.indicator}
                  </Typography>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </FormChildContainerFull>
      <FormChildContainerFull>
        <TitleName>Performance Indicator Weight</TitleName>
        <Grid container className={classes.indicatorContainer}>
          <Grid container item spacing={3} className={classes.m0}>
            <Grid container item xs={6}>
              <TitleName>Indicator</TitleName>
            </Grid>
            <Grid container item xs={6}>
              <TitleName>Indicator Weight</TitleName>
            </Grid>
          </Grid>
          {potentialIndicator2.map((res, idx) => {
            return (
              <Grid key={idx} container item spacing={2} className={classes.m0}>
                <Grid container item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    {res.name}
                  </Typography>
                </Grid>
                <Grid container item xs={3}>
                  <ThinTextfield variant="outlined"></ThinTextfield>
                </Grid>
                <Grid container item xs={3}>
                  <Typography variant="body1" gutterBottom>
                    {res.indicator}
                  </Typography>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </FormChildContainerFull> */}
      <FormChildButton style={{ paddingRight: 0 }}>
        <Button
          size="large"
          style={{ marginRight: 12 }}
          onClick={handleCloseDetail}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSave}
        >
          Simpan
        </Button>
      </FormChildButton>
      <ModalGlobalIcon
        open={openImage}
        handleClose={() => setOpenImage(false)}
        setIcon={setBadge}
      />
    </Paper>
  )
}

export default withRouter(AddDetailBox)
