import gql from 'graphql-tag'

/// Employee Balance > Multiple Top Up
export const GET_SELECTED_EMPLOYEES = gql`
  query(
    $where: people_work_placements_bool_exp
    $whereFiltered: people_work_placements_bool_exp
    $limit: Int
    $offset: Int
  ) {
    total: people_work_placements_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    total_filtered: people_work_placements_aggregate(where: $whereFiltered) {
      aggregate {
        count
      }
    }
    employees: people_work_placements(
      where: $whereFiltered
      order_by: [{global_user: {name: asc}}]
      limit: $limit
      offset: $offset
    ) {
      regno
      user: global_user {
        avatar
        name: name
        wallet: global_wallet_account {
          id
        }
      }
      profile: company_job_profile {
        id
        position: title
      }
    }
  }
`

/// Employee Balance > Individual Top Up
export const GET_INDIVIDUAL_TOPUP_INFO = gql`
  query($companyID: uuid!, $employeePlacementID: Int!) {
    company_in: global_transactions_aggregate(
      where: {
        _and: [
          {transaction_item_table: {_eq: "system"}}
          {globalWalletAccountByTo: {foreign_owner_id: {_eq: $companyID}}}
        ]
      }
    ) {
      aggregate {
        sum {
          nominal: value
        }
      }
    }
    company_out: global_transactions_aggregate(
      where: {
        _and: [
          {transaction_item_table: {_eq: "system"}}
          {global_wallet_account: {foreign_owner_id: {_eq: $companyID}}}
        ]
      }
    ) {
      aggregate {
        sum {
          nominal: value
        }
      }
    }
    employee: people_work_placements_by_pk(id: $employeePlacementID) {
      id
      regno
      user: global_user {
        id
        name
        avatar
        wallet: global_wallet_account {
          id
        }
      }
      profile: company_job_profile {
        id
        position: title
      }
      company: company_profile {
        id
        name: legal_name
      }
    }
  }
`
