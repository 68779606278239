import React from 'react'

import {IconButton} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import {GridListing, ItemWrapper} from '../../../DetailEmployeeStyles'
import EditAbout from './EditAbout'

import {capitalize, dateFormat} from '../../../../../../../utils/helpers'

const generateBrokenDate = date => {
  if (date) {
    // request date format from android
    const newDate = date.split('T00:00:00.000Z')
    const trueDate = newDate[0].split('-')
    const year = trueDate[0]
    let month = ''
    let day = ''
    if (trueDate[1].length === 1) {
      month = `0${trueDate[1]}`
    } else month = trueDate[1]

    if (trueDate[2].length === 1) {
      day = `0${trueDate[2]}`
    } else day = trueDate[2]
    return dateFormat(`${year}-${month}-${day}T00:00:00.000Z`, 'll')
  } else return date
}

const row = (header, content) => (
  <tr>
    <th scope="row">{header}</th>
    <td>{content || '-'}</td>
  </tr>
)

const rowArray = (header, content) =>
  row(
    header,
    content?.length &&
      content.map((d, i) => (
        <p key={i} style={{marginTop: i > 0 ? 8 : 0, marginBottom: 0}}>
          {d}
        </p>
      ))
  )

const GENDER_TYPES = {
  M: 'Male',
  F: 'Female',
}

export default function About(props) {
  const {global_users, profile_about, userId, refetch} = props
  const [edit, setEdit] = React.useState({open: false, data: null})

  const handleEdit = () => {
    setEdit({open: true, data: {global_users, profile_about}})
  }

  return (
    <GridListing style={{paddingRight: 64}}>
      <ItemWrapper>
        <table className="content">
          {row(
            'Jenis Kelamin',
            GENDER_TYPES[global_users[0]?.people_profiles?.[0]?.gender]
          )}
          {row(
            'Tempat Lahir',
            profile_about?.[0]?.profile_fields?.place_of_birth
          )}
          {row(
            'Tanggal Lahir',
            global_users[0]?.people_profiles?.[0]?.birthdate &&
              dateFormat(global_users[0].people_profiles[0].birthdate, 'LL')
          )}
          {row(
            'Agama',
            profile_about?.[0]?.global_religion?.name &&
              capitalize(profile_about[0].global_religion.name)
          )}
          {row(
            'Sekte Agama',
            profile_about?.[0]?.profile_fields?.religion_sect &&
              capitalize(profile_about[0].profile_fields.religion_sect)
          )}
          {row(
            'Status Pernikahan',
            global_users[0]?.people_profiles?.[0]?.marital_status &&
              capitalize(global_users[0].people_profiles[0].marital_status)
          )}
          {row(
            'Tanggal Pernikahan',
            profile_about?.[0]?.profile_fields?.marital_date &&
              generateBrokenDate(profile_about[0].profile_fields.marital_date)
          )}
          {row(
            'Tempat Pernikahan',
            profile_about?.[0]?.profile_fields?.marital_place
          )}
        </table>
      </ItemWrapper>
      <ItemWrapper>
        <table className="content">
          {rowArray(
            'Kebangsaan',
            profile_about?.[0]?.profile_fields?.nationality
          )}
          {rowArray(
            'Dialek',
            profile_about?.[0]?.profile_fields?.dialect?.map(res => res.name)
          )}
          {rowArray(
            'Suku',
            profile_about?.[0]?.profile_fields?.race?.map(res => res.name)
          )}
          {rowArray('Sapaan', profile_about?.[0]?.profile_fields?.salutation)}
        </table>
      </ItemWrapper>

      <IconButton
        onClick={handleEdit}
        style={{position: 'absolute', top: 18, right: 20}}
      >
        <EditIcon />
      </IconButton>

      <EditAbout
        open={edit.open}
        data={edit.data}
        userId={userId}
        refetch={refetch}
        onClose={() => setEdit({open: false, data: null})}
      />
    </GridListing>
  )
}
