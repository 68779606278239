import React, {useState, useEffect} from 'react'
import {
  Divider,
  MenuItem,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  withStyles,
  Menu,
  CircularProgress,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import {useQuery, useMutation, useLazyQuery} from '@apollo/react-hooks'
import {Link, withRouter} from 'react-router-dom'
import moment from 'moment'
import {
  PaperContainer,
  AddEditTitle,
  FormToolbar,
  FormTitleContainer,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import {
  GET_EMPLOYEE_PAYROLL_PROCESS_SAMPLING,
  GET_IS_APPROVER,
  GET_EMPLOYEE_PAYROLL_PROCESS,
  GET_EMPLOYEE_APPROVAL_PAYROLL_PROCESS,
  GET_EMPLOYEE_PAYROLL_PROCESS_CALCULATION,
} from '../../../../graphql/queries'
import {
  UPDATE_PAYROLL_STEP_PROCESS,
  UPDATE_PAYROLL_APPROVAL,
  UPDATE_PAYROLL_LAST_APPROVAL,
  REJECT_PAYROLL_APPROVAL,
} from '../../../../graphql/mutations'
import helperStyle, {
  PayrollContainer,
  CenterWrapper,
  Badge,
  BadgeWrapper,
  WaitingForText,
  pageSizeOption,
} from '../PayrollPageStyles'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../utils'
import StepPayrollData from './step-payroll-process/StepPayrollData'
import StepCalculation from './step-payroll-process/StepCalculation'
import StepSalarySlip from './step-payroll-process/StepSalarySlip'
import StepReview from './step-payroll-process/StepReview'
import StepApproval from './step-payroll-process/StepApproval'
import ModalConfirmation from './ModalConfirmation'
import ModalProcessCalculation from '../shared-components/dialog/ModalProcessCalculation'

import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import useDownloadReport from '../../../../hooks/useDownloadReport'

const getSteps = () => {
  return ['Payroll Data', 'Calculation', 'Approval', 'Review', 'Salary Slip']
}

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const sum = (arr, key) => {
  const total = arr.reduce((prev, cur) => prev + cur[key], 0)
  return total
}

const sumPostponedComponent = (arr, key, secondKey) => {
  return arr
    .map(i => i[key])
    .flat()
    .filter(i => !i.is_postponed)
    .reduce((a, b) => a + b[secondKey], 0)
}

const StepPayrollProcess = props => {
  const {
    history,
    status,
    backStep,
    searchText,
    location,
    period,
    filterData,
  } = props
  const step = status === 'Paid' ? 2 : backStep ? 1 : 0
  const [activeStep, setActiveStep] = useState(step)
  const [approveStep, setApproveStep] = useState(0)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [
    showModalProcessCalculation,
    setShowModalProcessCalculation,
  ] = useState(false)
  const [modalType, setModalType] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [alert, setAlert] = useState({
    sendAll: false,
    cancel: false,
  })
  const [isFetching, setIsFetching] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [listOfApprover, setListOfApprover] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [approvalStatus, setApprovalStatus] = useState(-1)
  const [amIApprover, setAmIApprover] = useState(false)
  const [prosessImport, setProssesImport] = useState(false)
  const [isFirstApprover, setIsFirstApprover] = useState(false)
  const [isSecondApprover, setIsSecondApprover] = useState(false)
  const [isThirdApprover, setIsThirdApprover] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [approvalStatement, setApprovalStatement] = useState(null)

  const [skipQueryCalculationDetail, setSkipQueryCalculationDetail] = useState(
    true
  )
  const [payrollCalculation, setPayrollCalculation] = useState({})
  const [openDownload2, setOpenDownload2] = useState(false)
  const [openDownload3, setOpenDownload3] = useState(false)

  const steps = getSteps()
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()

  const slicedParams = location.search.split('=')
  const year = Number(moment(period).format('YYYY'))
  const month = Number(slicedParams[slicedParams.length - 1])
  const jobProfileAttrs = [
    'first_job_profile',
    'second_job_profile',
    'third_job_profile',
  ]
  const [updateStepProcess] = useMutation(
    UPDATE_PAYROLL_STEP_PROCESS,
    GQL_FINANCE_OPTIONS
  )
  const [rejectApprovalProcces] = useMutation(
    REJECT_PAYROLL_APPROVAL,
    GQL_FINANCE_OPTIONS
  )
  const [updateApproval] = useMutation(
    UPDATE_PAYROLL_APPROVAL,
    GQL_FINANCE_OPTIONS
  )
  const [updateLastApproval] = useMutation(
    UPDATE_PAYROLL_LAST_APPROVAL,
    GQL_FINANCE_OPTIONS
  )
  const [getIsApprover, {data: isDataApprover}] = useLazyQuery(
    GET_IS_APPROVER,
    {
      variables: {
        login: USER_ID,
        company: COMPANY_ID,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )
  const {data: employeePayrollProcess, loading, error, refetch} = useQuery(
    GET_EMPLOYEE_PAYROLL_PROCESS_SAMPLING,
    {
      variables: {
        company: COMPANY_ID,
        year: year,
        month: month,
        offset: 0,
        limit: 1,
        position: null,
        organization: null,
        job_profile: null,
        rank: null,
        search: `%${searchText}%`,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )
  const {loading: calculationDetailLoading} = useQuery(
    GET_EMPLOYEE_PAYROLL_PROCESS_CALCULATION,
    {
      variables: {
        company: COMPANY_ID,
        year: year,
        month: month,
      },
      ...GQL_FINANCE_OPTIONS,
      skip: skipQueryCalculationDetail,
      fetchPolicy: 'no-cache',
      onCompleted: fetchedData => {
        const data = fetchedData && fetchedData.people_work_payroll

        if (data) {
          const basicSalaryTotal = sum(data, 'salary')
          const additionTotal = sumPostponedComponent(
            data,
            'allowances',
            'value'
          )
          const deductionTotal = sumPostponedComponent(
            data,
            'non_fixed_components',
            'value'
          )

          const payrollCalculationDetail = {
            total_salary: basicSalaryTotal,
            addition: additionTotal,
            deduction: deductionTotal,
            total_final_salary:
              basicSalaryTotal + additionTotal - deductionTotal,
          }
          setPayrollCalculation({
            ...payrollCalculation,
            month: month,
            year: year,
            ...payrollCalculationDetail,
          })
        }
        setShowModalProcessCalculation(data !== undefined)
        setSkipQueryCalculationDetail(true)
      },
    }
  )

  const {
    dataBaloon: dataBaloon2,
    isDisableButton: isDisableButton2,
    onDownload: onDownload2,
    onCloseBaloon: onCloseBaloon2,
  } = useDownloadReport({
    table: 'finance_payroll_report_2',
    filter: {period_year: year, period_month: month},
  })

  const handleDownloadStep2 = () => {
    setOpenDownload2(prev => !prev)
    if (openDownload2) {
      onDownload2({
        variables: {
          table: 'finance_payroll_report_2',
          filter: {period_year: year, period_month: month},
        },
      })
    }
  }

  const {
    dataBaloon: dataBaloon3,
    isDisableButton: isDisableButton3,
    onDownload: onDownload3,
    onCloseBaloon: onCloseBaloon3,
  } = useDownloadReport({
    table: 'finance_payroll_report_3',
    filter: {period_year: year, period_month: month},
  })

  const handleDownloadStep3 = () => {
    setOpenDownload3(prev => !prev)
    if (openDownload3) {
      onDownload3({
        variables: {
          table: 'finance_payroll_report_3',
          filter: {period_year: year, period_month: month},
        },
      })
    }
  }

  useEffect(() => {
    refetch()
  }, [activeStep])

  useEffect(() => {
    if (isDataApprover) {
      // const listOfAttr = [
      //   'first_job_profile',
      //   'second_approval_job_profile',
      //   'third_approval_job_profile',
      // ]

      if (isDataApprover.finance_payroll_settings_by_pk) {
        if (approveStep !== -1) {
          const {
            is_first,
            is_second,
            is_third,
          } = isDataApprover.finance_payroll_settings_by_pk

          if (is_first) {
            if (is_first.people_work_placements) {
              if (is_first.people_work_placements.length > 0) {
                const firstApproverList = is_first.people_work_placements.map(
                  item => item.user
                )
                setIsFirstApprover(firstApproverList.includes(USER_ID))
              }
            }
          }
          if (is_second) {
            if (is_second.people_work_placements) {
              if (is_second.people_work_placements.length > 0) {
                const secondApproverList = is_second.people_work_placements.map(
                  item => item.user
                )
                setIsSecondApprover(secondApproverList.includes(USER_ID))
              }
            }
          }
          if (is_third) {
            if (is_third.people_work_placements) {
              if (is_third.people_work_placements.length > 0) {
                const thirdApproverList = is_third.people_work_placements.map(
                  item => item.user
                )
                setIsThirdApprover(thirdApproverList.includes(USER_ID))
              }
            }
          }
        }

        jobProfileAttrs.forEach(attrName => {
          const selectedAttr =
            isDataApprover.finance_payroll_settings_by_pk[attrName]
          if (selectedAttr) {
            setListOfApprover(prev => prev.concat(selectedAttr.title))
          }
        })
      }
    }
  }, [isDataApprover])

  useEffect(() => {
    if (listOfApprover) {
      setPayrollCalculation({
        ...payrollCalculation,
        listOfApprover: listOfApprover,
      })
    }
  }, [listOfApprover])

  useEffect(() => {
    if (employeePayrollProcess) {
      if (employeePayrollProcess.people_work_payroll.length > 0) {
        setActiveStep(
          employeePayrollProcess.people_work_payroll[0].process_step
        )
        setApproveStep(
          employeePayrollProcess.people_work_payroll[0].approve_step
        )
        setPayrollCalculation({
          ...payrollCalculation,
          approveStep:
            employeePayrollProcess.people_work_payroll[0].approve_step,
        })
        if (
          [1, 2].includes(
            employeePayrollProcess.people_work_payroll[0].process_step
          )
        ) {
          getIsApprover()
        }
      }
    }
    setIsFetching(false)
  }, [employeePayrollProcess, activeStep])

  useEffect(() => {
    const listOfAttrs = ['is_first', 'is_second', 'is_third']
    if (isDataApprover && employeePayrollProcess) {
      const currentApproveStep =
        employeePayrollProcess.people_work_payroll &&
        employeePayrollProcess.people_work_payroll[0] &&
        employeePayrollProcess.people_work_payroll[0].approve_step

      const selectedApproval =
        isDataApprover.finance_payroll_settings_by_pk[
          listOfAttrs[currentApproveStep]
        ]
      if (selectedApproval) {
        if (
          selectedApproval &&
          selectedApproval.people_work_placements.length > 0
        ) {
          setAmIApprover(true)
        }
      }
      let selectedIndex = 0
      for (const key of listOfAttrs) {
        if (isDataApprover.finance_payroll_settings_by_pk[key]) {
          selectedIndex = listOfAttrs.indexOf(key)
          setApprovalStatus(selectedIndex)
          break
        }
      }
    }
  }, [isDataApprover, employeePayrollProcess])

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <StepPayrollData
            searchText={searchText}
            location={location}
            period={period}
            month={month}
            filterData={filterData}
          />
        )
      case 1:
        return (
          <StepCalculation
            searchText={searchText}
            location={location}
            period={period}
            month={month}
            filterData={filterData}
            setProssesImport={setProssesImport}
          />
        )
      case 2:
        return (
          <StepApproval
            searchText={searchText}
            location={location}
            period={period}
            filterData={filterData}
          />
        )
      case 3:
        return (
          <StepReview
            searchText={searchText}
            location={location}
            period={period}
            filterData={filterData}
          />
        )
      case 4:
        return (
          <StepSalarySlip
            status={status}
            searchText={searchText}
            location={location}
            period={period}
            filterData={filterData}
          />
        )
      case 5:
        return (
          <StepSalarySlip
            status={status}
            searchText={searchText}
            location={location}
            period={period}
            filterData={filterData}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  const handleNext = () => {
    const refetchQueries = [
      {
        query: GET_EMPLOYEE_PAYROLL_PROCESS_SAMPLING,
        variables: {
          company: COMPANY_ID,
          year: year,
          month: month,
          offset: 0,
          limit: 1,
          position: null,
          organization: null,
          job_profile: null,
          rank: null,
          search: `%%`,
        },
        ...GQL_FINANCE_OPTIONS,
      },
      {
        query: GET_EMPLOYEE_PAYROLL_PROCESS,
        variables: {
          company: COMPANY_ID,
          year: period,
          month: month,
          offset: 0,
          limit: pageSizeOption[0],
          position: null,
          organization: null,
          job_profile: null,
          rank: null,
          search: `%%`,
        },
        ...GQL_FINANCE_OPTIONS,
      },
      {
        query: GET_EMPLOYEE_APPROVAL_PAYROLL_PROCESS,
        variables: {
          login: USER_ID,
          company: COMPANY_ID,
          year: period,
          month: month,
          offset: 0,
          limit: pageSizeOption[0],
          position: null,
          organization: null,
          job_profile: null,
          rank: null,
          search: `%%`,
        },
        ...GQL_FINANCE_OPTIONS,
      },
      {
        query: GET_IS_APPROVER,
        variables: {
          login: USER_ID,
          company: COMPANY_ID,
        },
        ...GQL_FINANCE_OPTIONS,
      },
    ]

    if (activeStep !== 2) {
      const variables = {
        company: COMPANY_ID,
        year: period,
        month: month,
        process_step: activeStep + 1,
      }
      if (activeStep === 1 && listOfApprover.length === 0) {
        variables.process_step = activeStep + 2
      }
      setIsLoading(true)
      updateStepProcess({variables, refetchQueries, ...GQL_FINANCE_OPTIONS})
        .then(() => {
          if (activeStep !== 4) {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
          }
          if (activeStep === 4) {
            setAlert({...alert, sendAll: true})
          }
          enqueueSnackbar(
            activeStep === 0
              ? 'Success calculate Payroll Data'
              : activeStep === 1
              ? 'Request to approve sent'
              : activeStep === 3
              ? 'Success transfer payroll'
              : 'Success Update data',
            {
              variant: 'success',
              autoHideDuration: 1000,
            }
          )
        })
        .catch(() => {
          enqueueSnackbar('Update data error, please try again', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      // update approval
      setIsLoading(true)
      const selectedAttrName = ['is_first', 'is_second', 'is_third', 'is_third']
      const selectedApproval =
        isDataApprover.finance_payroll_settings_by_pk[
          selectedAttrName[approveStep]
        ]
      const updateApprovalVariables = {
        login: USER_ID,
        company: COMPANY_ID,
        year: period,
        month: month,
      }
      const updateLastApprovalVariables = {
        login: USER_ID,
        company: COMPANY_ID,
        year: period,
        month: month,
        process_step: 3,
      }

      if (selectedApproval === null) {
        updateApproval({
          variables: updateApprovalVariables,
          refetchQueries,
          ...GQL_FINANCE_OPTIONS,
        })
          .then(() => {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
            enqueueSnackbar(
              `Success approve ${moment(month, 'MM')} ${year} Payroll`,
              {
                variant: 'success',
                autoHideDuration: 1000,
              }
            )
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
              autoHideDuration: 1000,
            })
          })
          .finally(() => {
            setIsLoading(false)
            setAmIApprover(false)
          })
      }

      if (approveStep >= 2 && selectedApproval !== null) {
        if (selectedApproval.people_work_placements.length > 0) {
          updateLastApproval({
            variables: updateLastApprovalVariables,
            refetchQueries,
            ...GQL_FINANCE_OPTIONS,
          })
            .then(() => {
              setActiveStep(prevActiveStep => prevActiveStep + 1)
              enqueueSnackbar(
                activeStep === 0
                  ? 'Success calculate Payroll Data'
                  : activeStep === 1
                  ? 'Request to approve sent'
                  : activeStep === 3
                  ? 'Success transfer payroll'
                  : 'Success Update data',
                {
                  variant: 'success',
                  autoHideDuration: 1000,
                }
              )
            })
            .catch(() => {
              enqueueSnackbar('Update data error, please try again', {
                variant: 'error',
                autoHideDuration: 1000,
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
        } else {
          updateApproval({
            variables: updateApprovalVariables,
            refetchQueries,
            ...GQL_FINANCE_OPTIONS,
          })
            .then(() => {
              setActiveStep(prevActiveStep => prevActiveStep + 1)
              enqueueSnackbar(
                activeStep === 0
                  ? 'Success calculate Payroll Data'
                  : activeStep === 1
                  ? 'Request to approve sent'
                  : activeStep === 3
                  ? 'Success transfer payroll'
                  : activeStep === 2
                  ? `Success approve ${moment(month, 'MM')} ${year} Payroll`
                  : 'Success Update data',
                {
                  variant: 'success',
                  autoHideDuration: 1000,
                }
              )
            })
            .catch(() => {
              enqueueSnackbar('Update data error, please try again', {
                variant: 'error',
                autoHideDuration: 1000,
              })
            })
            .finally(() => {
              setIsLoading(false)
              setAmIApprover(false)
            })
        }
      }

      if (selectedApproval !== null && approveStep < 2) {
        if (selectedApproval.people_work_placements.length > 0) {
          if (
            isDataApprover.finance_payroll_settings_by_pk[
              selectedAttrName[approveStep + 1]
            ] === null
          ) {
            updateLastApproval({
              variables: updateLastApprovalVariables,
              ...GQL_FINANCE_OPTIONS,
            })
              .then(() => {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
                enqueueSnackbar(
                  activeStep === 0
                    ? 'Success calculate Payroll Data'
                    : activeStep === 1
                    ? 'Request to approve sent'
                    : activeStep === 3
                    ? 'Success transfer payroll'
                    : activeStep === 2
                    ? `Success approve ${moment(month, 'MM')} ${year} Payroll`
                    : 'Success Update data',
                  {
                    variant: 'success',
                    autoHideDuration: 1000,
                  }
                )
              })
              .catch(() => {
                enqueueSnackbar('Update data error, please try again', {
                  variant: 'error',
                  autoHideDuration: 1000,
                })
              })
              .finally(() => {
                setIsLoading(false)
              })
          } else {
            updateApproval({
              variables: updateApprovalVariables,
              refetchQueries,
              ...GQL_FINANCE_OPTIONS,
            })
              .then(() => {
                setActiveStep(prevActiveStep => prevActiveStep + 1)
                enqueueSnackbar(
                  activeStep === 0
                    ? 'Success calculate Payroll Data'
                    : activeStep === 1
                    ? 'Request to approve sent'
                    : activeStep === 3
                    ? 'Success transfer payroll'
                    : activeStep === 2
                    ? `Success approve ${moment(month, 'MM')} ${year} Payroll`
                    : 'Success Update data',
                  {
                    variant: 'success',
                    autoHideDuration: 1000,
                  }
                )
              })
              .catch(() => {
                enqueueSnackbar('Update data error, please try again', {
                  variant: 'error',
                  autoHideDuration: 1000,
                })
              })
              .finally(() => {
                setIsLoading(false)
                setAmIApprover(false)
              })
          }
        }
      }
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseAlert = type => {
    if (type === 'send') {
      setAlert({...alert, sendAll: false})
      history.push({
        pathname: '/financial/payroll/payroll-process/step',
        state: {
          active: 'step-payroll-process',
          status: 'Paid',
        },
      })
    } else {
      setAlert({...alert, cancel: false})
      history.push({
        pathname: '/financial/payroll/payroll-process',
        state: {active: 'payroll-process', searchable: false, showPeriod: true},
      })
    }
  }

  const onCloseModal = () => {
    setModalType('')
    setShowModalConfirm(false)
  }

  const onSave = () => {
    setModalType('confirmation')
    setShowModalConfirm(true)
  }

  const onCancel = () => {
    setModalType('cancel')
    setShowModalConfirm(true)
  }

  const onReject = () => {
    setModalType('reject')
    setShowModalConfirm(true)
  }

  const reShowModalProcessCalculation = () => {
    setModalType('cancel')
    setShowModalConfirm(false)
    setShowModalProcessCalculation(true)
  }

  const handleSendAllSalarySlip = type => {
    type === 'send'
      ? setAlert({...alert, sendAll: true})
      : setAlert({...alert, cancel: true})
    handleClose()
  }

  const onConfirmDiscard = () => {
    // From step 1, 3 and 4 are returned to the payroll process list page
    // If it's step 3 and modalType is reject, then go to step 2 (handled in else)
    if ([0, 2, 3].includes(activeStep) && modalType !== 'reject') {
      setModalType('cancel')
      setShowModalConfirm(true)
      history.push({
        pathname: '/financial/payroll/payroll-process',
        state: {
          active: 'payroll-process',
          searchable: false,
          showPeriod: true,
          noMaxDate: true,
        },
      })
    } else {
      const variables = {
        company: COMPANY_ID,
        year: period,
        month: month,
        process_step: activeStep - 1,
        login: USER_ID,
        approve_step: approveStep,
      }
      setIsLoading(true)
      rejectApprovalProcces({
        variables,
        ...GQL_FINANCE_OPTIONS,
        refetchQueries: [
          {
            query: GET_EMPLOYEE_PAYROLL_PROCESS,
            variables: {
              company: COMPANY_ID,
              year: period,
              month: month,
              offset: 0,
              limit: pageSizeOption[0],
              position: null,
              organization: null,
              job_profile: null,
              rank: null,
              search: `%%`,
            },
            ...GQL_FINANCE_OPTIONS,
          },
          {
            query: GET_EMPLOYEE_APPROVAL_PAYROLL_PROCESS,
            variables: {
              login: USER_ID,
              company: COMPANY_ID,
              year: period,
              month: month,
              offset: 0,
              limit: pageSizeOption[0],
              position: null,
              organization: null,
              job_profile: null,
              rank: null,
              search: `%%`,
            },
            ...GQL_FINANCE_OPTIONS,
          },
          {
            query: GET_IS_APPROVER,
            variables: {
              login: USER_ID,
              company: COMPANY_ID,
            },
            ...GQL_FINANCE_OPTIONS,
          },
        ],
      })
        .then(() => {
          setActiveStep(1)

          enqueueSnackbar(
            activeStep === 2
              ? `Success reject ${period} Payroll`
              : 'Success Update data',
            {
              variant: 'success',
              autoHideDuration: 1000,
            }
          )
        })
        .catch(() => {
          enqueueSnackbar('Update data error, please try again', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const renderApprovalStatusBadge = () => {
    if (
      (isFirstApprover && approveStep > 0) ||
      (isSecondApprover && approveStep > 1) ||
      (isThirdApprover && approveStep > 2)
    ) {
      return (
        <BadgeWrapper>
          <Badge style={{backgroundColor: '#4caf50'}}>Approved</Badge>
        </BadgeWrapper>
      )
    } else {
      return (
        <>
          <BadgeWrapper>
            <Badge style={{backgroundColor: '#ffa000'}}>Waiting</Badge>
          </BadgeWrapper>
          <WaitingForText>
            {isDataApprover.finance_payroll_settings_by_pk[
              jobProfileAttrs[approveStep]
            ] && (
              <div className="component_waiting_step_text">
                Waiting for <span>{`${listOfApprover[approveStep]}'s `}</span>
                Approval
              </div>
            )}
          </WaitingForText>
        </>
      )
    }
  }

  // const handleBack = () => {
  //   history.push({
  //     pathname: '/financial/payroll/payroll-process',
  //     state: {active: 'payroll-process', searchable: true},
  //   })
  // }

  if (error) console.error(JSON.stringify(error))

  return (
    <PaperContainer>
      <ModalConfirmation
        open={showModalConfirm}
        onClose={onCloseModal}
        onConfirm={handleNext}
        onConfirmDiscard={onConfirmDiscard}
        actionAfterClose={reShowModalProcessCalculation}
        type={modalType}
        isLoading={isLoading}
        activeStep={activeStep}
        year={period}
        month={month}
        listOfApprover={listOfApprover}
      />
      <ModalProcessCalculation
        data={showModalProcessCalculation && payrollCalculation}
        onApprove={onSave}
        onClose={() => {
          setShowModalProcessCalculation(false)
        }}
        onReject={onReject}
        onStatementChange={value => setApprovalStatement(value)}
        open={showModalProcessCalculation}
        loading={calculationDetailLoading}
        type={'Payroll'}
      />

      <FormToolbar disableGutters>
        <FormTitleContainer
          style={{
            display: 'flex',
          }}
        >
          {/* <Link
            to={{
              pathname: '/financial/payroll/payroll-process',
              state: {active: 'payroll-process', searchable: false},
            }}
          >
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link> */}
          <AddEditTitle
            variant="h6"
            display="inline"
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            Payroll Process
            {activeStep === 2 && isDataApprover && renderApprovalStatusBadge()}
          </AddEditTitle>
        </FormTitleContainer>
        {status === 'Paid' ? (
          ''
        ) : (
          <div style={{minWidth: 'max-content'}}>
            {activeStep === 1 && employeePayrollProcess && (
              <GenerateTooltip
                title={
                  isDisableButton2
                    ? `You can only generate data once a day. To download data use the link that already generated.`
                    : ``
                }
                placement="left"
              >
                <span>
                  <Button
                    color="primary"
                    disabled={isDisableButton2}
                    onClick={handleDownloadStep2}
                    size="large"
                    style={{marginRight: 8}}
                    variant="outlined"
                  >
                    Download Data
                  </Button>
                </span>
              </GenerateTooltip>
            )}

            {activeStep === 3 && employeePayrollProcess && (
              <GenerateTooltip
                title={
                  isDisableButton3
                    ? `You can only generate data once a day. To download data use the link that already generated.`
                    : ``
                }
                placement="left"
              >
                <span>
                  <Button
                    color="primary"
                    disabled={isDisableButton3}
                    onClick={handleDownloadStep3}
                    size="large"
                    style={{marginRight: 8}}
                    variant="outlined"
                  >
                    Download Data
                  </Button>
                </span>
              </GenerateTooltip>
            )}

            {[0, 1, 2, 4].includes(activeStep) && (
              <Button
                size="large"
                className={classes.backButton}
                onClick={onCancel}
                // disabled={activeStep === 2 && !amIApprover}
              >
                {activeStep === 0 || activeStep === 2 ? 'Cancel' : 'Back'}
              </Button>
            )}
            {activeStep <= 4 && (
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => {
                  activeStep === 2
                    ? setSkipQueryCalculationDetail(
                        false
                      ) /* if detail button clicked, then fetch payroll calculation detail */
                    : onSave()
                }}
                disabled={
                  (activeStep === 2 && !amIApprover) ||
                  (activeStep === 1 && prosessImport)
                }
              >
                {activeStep === 0
                  ? 'Calculate'
                  : activeStep === 1
                  ? 'Process'
                  : activeStep === 2
                  ? 'Action'
                  : activeStep === 3
                  ? 'Next'
                  : 'Send All Salary Slip'}
              </Button>
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <Link
                to={{
                  pathname: '/financial/payroll/payroll-process/step',
                  state: {
                    active: 'step-payroll-process',
                    status: 'Paid',
                  },
                }}
                className={classes.linkDarkBlue}
              > */}
              <MenuItem
                className={classes.linkDarkBlue}
                onClick={() => handleSendAllSalarySlip('send')}
              >
                Send All Salary Slip
              </MenuItem>
              {/* </Link> */}

              <Link
                to={{
                  pathname: `/financial/payroll/report`,
                  state: {
                    active: 'report',
                    // policies: data.time_leave_policies,
                  },
                }}
                className={classes.linkDarkBlue}
              >
                <MenuItem onClick={handleClose}>View Payroll Report</MenuItem>
              </Link>

              {/* <Link
                to={{
                  pathname: `/financial/payroll/report`,
                  state: {
                    active: 'report',
                    // policies: data.time_leave_policies,
                  },
                }}
                className={classes.linkDarkBlue}
              > */}
              <MenuItem
                className={classes.linkRed}
                onClick={() => handleSendAllSalarySlip('cancel')}
              >
                Cancel
              </MenuItem>
              {/* </Link> */}
            </Menu>
          </div>
        )}
      </FormToolbar>
      <Divider />

      {employeePayrollProcess && !loading && (
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomConnector />}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                classes={{
                  active: classes.labelActive,
                  completed: classes.labelActive,
                }}
                StepIconProps={{
                  classes: {
                    root: classes.iconRoot,
                    text: classes.iconText,
                    active: classes.iconActive,
                    completed: classes.iconActive,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      <PayrollContainer className={classes.marginTop10}>
        {activeStep === 1 && (
          <ExportBaloon
            availableToSeeProblem
            isLastGenerateStatus
            isReport
            data={dataBaloon2}
            closeBaloonMutation={onCloseBaloon2}
            retry={handleDownloadStep2}
          />
        )}

        {activeStep === 3 && (
          <ExportBaloon
            availableToSeeProblem
            isLastGenerateStatus
            isReport
            data={dataBaloon3}
            closeBaloonMutation={onCloseBaloon3}
            retry={handleDownloadStep3}
          />
        )}

        {(loading || isFetching) && (
          <CenterWrapper>
            <CircularProgress />
          </CenterWrapper>
        )}

        {employeePayrollProcess &&
          !loading &&
          !isFetching &&
          getStepContent(activeStep)}
      </PayrollContainer>

      <AlertDialog
        open={alert.sendAll}
        handleClose={() => handleCloseAlert('send')}
        feature={`Send Payroll Slip`}
        message="Payroll Slips have been sent successfully"
        type="Finish"
      />
      <AlertDialog
        open={alert.cancel}
        handleClose={() => handleCloseAlert('cancel')}
        feature={`Discard Changes?`}
        message={`Are you sure you want to discard unsaved changes?`}
        type="Discard"
        status="cancel"
      />
      {activeStep === 1 && (
        <AlertDialog
          open={openDownload2}
          handleClose={handleDownloadStep2}
          feature="Download Data is Processing!"
          message="Download Data is processing! We’ll send you an email when it’s ready."
          type="Confirm"
        />
      )}
      {activeStep === 3 && (
        <AlertDialog
          open={openDownload3}
          handleClose={handleDownloadStep3}
          feature="Download Data is Processing!"
          message="Download Data is processing! We’ll send you an email when it’s ready."
          type="Confirm"
        />
      )}
    </PaperContainer>
  )
}

export default withRouter(StepPayrollProcess)
