import React, {useState, useEffect} from 'react'
import helperStyle, {
  OrganizationContainer,
  TextBlackBold,
} from '../../../OrganizationPageStyles'
import {FlexBetween} from '../../../../../GlobalStyles'
import {Button, InputAdornment, TextField, Paper} from '@material-ui/core'
import {Magnify} from 'mdi-material-ui'
import MaterialTable from 'material-table'
import {
  TableLocalization,
  TableOptions,
} from '../../../../shared-components/table/TableOptions'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import classNames from 'classnames'
import {AssignFilter} from '@worklifebeyond/wlb-utils-components'
import {COMPANY_ID} from '../../../../../utils/globals'
import {useQuery} from '@apollo/react-hooks'
import {RESULT_FILTER_JOB_FAMILY} from '../../../../../graphql/queries'
import {GET_JOB_LEVEL_FILTER} from '../../../../../graphql/queries/filters/searchFilter'
import assignFilters, {
  createGetVariables,
  getDataCode,
} from '../../../../../utils/assignFilters'

const listAssign = [
  ...assignFilters.filter(field => field.fieldName === 'jobTitle'),
  {
    name: 'Jenis Jabatan',
    fieldName: 'jobFunction',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_JOB_LEVEL_FILTER,
        getVariables: createGetVariables(),
        setData: getDataCode,
      },
    },
  },
]

const TabJobTitle = props => {
  const {allDataFrom, setAllDataFrom, status} = props
  const classes = helperStyle()
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })
  const [filterData, setFilterData] = useState(null)
  const [searchDropdown, setSearchDropdown] = useState('')
  const [clickOptions, setClickOptions] = useState(false)

  const getDataVariableJFamily = filter => {
    const variables = {
      jobTitle: {
        _in: filter && filter.include && filter.include.jobTitle,
        _nin: filter && filter.exclude && filter.exclude.jobTitle,
      },
      jobFunction: {
        _in: filter && filter.include && filter.include.jobFunction,
        _nin: filter && filter.exclude && filter.exclude.jobFunction,
      },
    }

    return variables
  }
  const [variable, setVariable] = useState(
    getDataVariableJFamily(filterData || allDataFrom.jobTitle)
  )

  const selections = React.useMemo(() => {
    const include = {}
    const exclude = {}

    for (const v in variable) {
      const {_in, _nin} = variable[v]

      include[v] = _in
      exclude[v] = _nin
    }

    return {include, exclude}
  }, [variable])

  const checkNullFilter =
    variable.jobFunction._in ||
    variable.jobFunction._nin ||
    variable.jobTitle._in ||
    variable.jobTitle._nin

  const [skipQueryResult, setSkipQueryResult] = useState(true)
  const {data: resultDataFilter, loading} = useQuery(RESULT_FILTER_JOB_FAMILY, {
    variables: {
      ...variable,
      company: COMPANY_ID,
      search: `%${searchDropdown}%`,
      family: status === 'Edit' && !clickOptions ? {_eq: allDataFrom.id} : {},
    },
    fetchPolicy: 'cache-and-network',
    skip: skipQueryResult,
    // onCompleted: data => {
    //   if (data && status === 'Edit') {
    //     const idEdit = []
    //     data.company_employee_positions.map(_data => idEdit.push(_data.id))
    //     setAllDataFrom({
    //       ...allDataFrom,
    //       jobTitle: {include: {jobTitle: idEdit}},
    //     })
    //   }
    // },
  })

  useEffect(() => {
    if (status === 'Add' && allDataFrom.jobTitle) {
      setFilterData(allDataFrom.jobTitle)
      setVariable(getDataVariableJFamily(allDataFrom.jobTitle))
      setSkipQueryResult(false)
    } else if (status === 'Edit') {
      // if (resultDataFilter) {
      //   const idEdit = []
      // resultDataFilter &&
      //   resultDataFilter.company_employee_positions &&
      //   resultDataFilter.company_employee_positions.map(_data =>
      //     idEdit.push(_data.id)
      //   )
      // setAllDataFrom({...allDataFrom, jobTitle: {include: {jobTitle: idEdit}}})
      // }

      setVariable(getDataVariableJFamily(allDataFrom.jobTitle))
      setSkipQueryResult(false)
    }
  }, [filterData, status, resultDataFilter])

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleApplyAssign = result => {
    const generateIdFilter = data => {
      const _data = {}

      for (const property in data) {
        const array = data[property]

        _data[property] = array && array.length > 0 ? array : undefined
      }

      return _data
    }

    const include = generateIdFilter(result.include)
    const exclude = generateIdFilter(result.exclude)

    setFilterData({include, exclude})
    setAllDataFrom({...allDataFrom, jobTitle: {include, exclude}})
    setSkipQueryResult(false)
    setClickOptions(true)
  }

  return (
    <OrganizationContainer>
      <FlexBetween className={classes.marginTopBot20}>
        <TextBlackBold className={classes.marginLeft25}>{`Terapkan ke ${
          resultDataFilter
            ? checkNullFilter
              ? resultDataFilter.total.aggregate.count
              : `0`
            : `0`
        } Jabatan`}</TextBlackBold>
        <AssignFilter
          filters={listAssign}
          initialSelections={selections}
          limit={20}
          onApply={handleApplyAssign}
          language="indonesian"
        >
          <Button
            className={classes.marginRight25}
            variant="contained"
            color="primary"
            size="large"
            // onClick={openDialogConfirm}
          >
            Saring
          </Button>
        </AssignFilter>
      </FlexBetween>
      <TextField
        margin="normal"
        variant="outlined"
        className={classNames(
          classes.height39,
          classes.marginLeftRight25,
          classes.width95P
        )}
        fullWidth
        onChange={event => setSearchDropdown(event.target.value)}
        placeholder="Pencarian"
        InputProps={{
          startAdornment: (
            <InputAdornment className="ml-0" position="start">
              <Magnify style={{color: '#014a62', width: 20, height: 20}} />
            </InputAdornment>
          ),
        }}
      />
      <MaterialTable
        columns={[
          {
            title: 'Kode Jabatan',
            field: 'designation_code',
            defaultSort: 'asc',
            headerStyle: {minWidth: 200},
            cellStyle: {minWidth: 200},
            render: rowData => (
              <div style={{wordBreak: 'break-word'}}>
                {rowData.designation_code}
              </div>
            ),
          },
          {
            title: 'Jabatan',
            field: 'name',
            headerStyle: {minWidth: 700},
            cellStyle: {minWidth: 700},
            render: rowData => (
              <div style={{wordBreak: 'break-word'}}>{rowData.name}</div>
            ),
          },
        ]}
        data={
          checkNullFilter
            ? resultDataFilter && resultDataFilter.company_employee_positions
            : []
        }
        options={{
          ...TableOptions,
          showTitle: false,
          toolbar: false,
          selection: false,
          emptyRowsWhenPaging: false,
        }}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: (
              <NoDataListComponent search={searchDropdown} />
            ),
          },
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        page={pagination.offset}
        pageSize={pagination.limit}
        isLoading={loading}
        components={{
          Container: props => (
            <Paper
              className={classes.marginLeftRight25}
              {...props}
              elevation={0}
            />
          ),
        }}
        totalCount={resultDataFilter && resultDataFilter.total.aggregate.count}
      />
    </OrganizationContainer>
  )
}

export default TabJobTitle
