import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  FormContainer,
  AddEditTitle,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  ThinTextfield,
  FormToolbar,
  FormTitleContainer,
} from '../../../../../GlobalStyles'
import {
  Divider,
  IconButton,
  TextField,
  MenuItem,
  Button,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Link, withRouter} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  ADD_NEW_KPI_WEIGHT,
  UPDATE_KPI_WEIGHT,
} from '../../../../../graphql/mutations'
import {
  CHECK_CODE_WEIGHT,
  CHECK_WEIGHT_ALREADY_TAKEN,
} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import Loading from '../../../../../components/loading/LoadingComponent'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../../shared-components/popup/AddEditPopupFreeText'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'
import {status_data} from '../../../../shared-components/data/SharedData'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'

function FormWeight(props) {
  const {dataWeight, history} = props
  const {enqueueSnackbar} = useSnackbar()
  const [isEdit, setIsEdit] = useState(false)
  const [allDataForm, setAllDataForm] = useState({
    code: '',
    name: '',
    description: '',
    status: 'ACTIVE',
  })
  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)
  const [dialogCantDel, setDialogCantDel] = useState(false)
  const [skipQueryCheckWeight, setSkipQueryCheckWeight] = useState(true)

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  const [mutationAddWeight] = useMutation(ADD_NEW_KPI_WEIGHT)
  const [mutationEditWeight] = useMutation(
    UPDATE_KPI_WEIGHT(dataWeight && dataWeight.id)
  )

  useEffect(() => {
    if (dataWeight) {
      setIsEdit(true)
      setAllDataForm({
        ...allDataForm,
        code: dataWeight.code || '',
        name: dataWeight.name,
        description: dataWeight.description,
        status: dataWeight.status,
      })
      setSkipQueryCheckWeight(false)
    }
  }, [isEdit, dataWeight])

  const {data: checkCodeWeight, error: checkCodeWeightError} = useQuery(
    CHECK_CODE_WEIGHT,
    {
      variables: {
        company: COMPANY_ID,
        code: allDataForm && allDataForm.code,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {data: checkWeightAlreadyTaken} = useQuery(CHECK_WEIGHT_ALREADY_TAKEN, {
    variables: {
      weight: dataWeight && dataWeight.id,
    },
    skip: skipQueryCheckWeight,
    fetchPolicy: 'cache-and-network',
  })

  if (checkCodeWeight === undefined && checkCodeWeightError === undefined) {
    return <Loading />
  } else if (checkCodeWeightError !== undefined) {
    return JSON.stringify(checkCodeWeightError)
  }

  const codeChecker =
    checkCodeWeight?.kpi_weight_units_aggregate?.aggregate?.count > 0

  const isWeightAlreadyTaken =
    checkWeightAlreadyTaken?.kpi_lists_aggregate?.aggregate?.count > 0

  const checkSameCodeWithAdd =
    allDataForm?.code?.length >= 1
      ? dataWeight?.code === allDataForm.code
      : false

  const mutateWeight = () => {
    if (
      !allDataForm.code ||
      allDataForm.code.length > 50 ||
      !allDataForm.name ||
      !allDataForm.description ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const data = {
        code: allDataForm.code,
        name: allDataForm.name,
        description: allDataForm.description,
        status: allDataForm.status,
      }
      if (!isEdit) {
        mutationAddWeight({variables: {data}})
          .then(() => {
            enqueueSnackbar('Success Add data', {variant: 'success'})
            history.push({
              pathname: '/performance/kpi/weight',
              state: {active: 'weight', searchable: true},
            })
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
            })
          })
      } else {
        mutationEditWeight({variables: {data}})
          .then(() => {
            enqueueSnackbar('Success Update data', {variant: 'success'})
            history.push({
              pathname: '/performance/kpi/weight',
              state: {active: 'weight', searchable: true},
            })
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
            })
          })
      }
      //   console.log(data)
    }
  }

  const sectionName = `${GLOBAL_GLOSSARY.performance['Weight Unit']} ${GLOBAL_GLOSSARY.performance.Goal}`
  const action = isEdit ? 'Edit' : 'Add'

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link
            to={{
              pathname: '/performance/kpi/weight',
              state: {active: 'weight', searchable: true},
            }}
          >
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6" display="inline">
            {isEdit ? 'Ubah' : 'Tambahkan'} {sectionName}
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            size="large"
            component={Link}
            to={{
              pathname: '/performance/kpi/weight',
              state: {active: 'weight', searchable: true},
            }}
            style={{marginRight: 12}}
          >
            Batalkan
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="large"
          >
            Simpan
          </Button>
        </div>
      </FormToolbar>
      <Divider></Divider>
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Kode {sectionName} *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                setAllDataForm({...allDataForm, code: event.target.value})
              }}
              variant="outlined"
              value={allDataForm.code}
              error={
                (!allDataForm.code && isError) ||
                (allDataForm.code && allDataForm.code.length > 50) ||
                (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
              }
              helperText={
                !allDataForm.code && isError
                  ? 'Bagian ini diperlukan'
                  : (codeChecker
                    ? !checkSameCodeWithAdd
                    : checkSameCodeWithAdd)
                  ? `Kode ${sectionName} sudah ada`
                  : allDataForm.code && allDataForm.code.length > 50
                  ? 'Jumlah karakter maksimum adalah 50 karakter'
                  : `Kode ${sectionName} harus unik`
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Nama {sectionName} *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                setAllDataForm({...allDataForm, name: event.target.value})
              }}
              variant="outlined"
              value={allDataForm.name}
              error={allDataForm.name.length < 1 && isError}
              helperText={
                allDataForm.name.length < 1 && isError
                  ? 'Bagian ini diperlukan'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Keterangan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                setAllDataForm({
                  ...allDataForm,
                  description: event.target.value,
                })
              }}
              variant="outlined"
              value={allDataForm.description}
              error={allDataForm.description.length < 1 && isError}
              helperText={
                allDataForm.description.length < 1 && isError
                  ? 'Bagian ini diperlukan'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Status *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              select
              fullWidth
              onChange={event => {
                if (isWeightAlreadyTaken) {
                  setDialogCantDel(!dialogCantDel)
                } else {
                  setAllDataForm({
                    ...allDataForm,
                    status: event.target.value,
                  })
                }
              }}
              margin="normal"
              variant="outlined"
              value={allDataForm.status}
            >
              <MenuItem value="ACTIVE">{status_data.ACTIVE}</MenuItem>
              <MenuItem value="INACTIVE">{status_data.INACTIVE}</MenuItem>
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        mutation={mutateWeight}
        header={CONFIRMATION[action]?.header?.replace('[name]', sectionName)}
        body={CONFIRMATION[action]?.body?.replace('[name]', sectionName)}
        button={CONFIRMATION[action]?.button}
        buttonClose="Batalkan"
      />
      <AlertDialog
        open={dialogCantDel}
        handleClose={() => setDialogCantDel(!dialogCantDel)}
        feature={'Unable to Change Status'}
        message="Unable to set status to inactive because this goal weight unit is already used in the goal library"
        type="Confirm"
      />
    </PaperContainer>
  )
}

export default withRouter(FormWeight)

const CONFIRMATION = {
  Add: {
    header: `Tambahkan [name] yang baru? `,
    body: `Apakah anda yakin akan menambahkan [name] baru ini? `,
    button: 'Tambah',
  },
  Edit: {
    header: `Ubah [name] yang baru? `,
    body: `Apakah anda yakin akan mengubah [name]? `,
    button: 'Ubah',
  },
}
