import gql from 'graphql-tag'

export const TERMINATE_EMPLOYEE = gql`
  mutation(
    $idPlacement: Int!
    $idTerminationStatus: Int!
    $endDate: date
    $data: jsonb
    $company: uuid
    $user: uuid
  ) {
    update_people_work_placements(
      _set: {
        status: "INACTIVE"
        date_terminated: "now()"
        global_placement_termination_id: $idTerminationStatus
      }
      _append: {placement_fields: $data}
      where: {id: {_eq: $idPlacement}}
    ) {
      affected_rows
    }

    update_people_work_salaries(
      where: {
        people_work_placement: {
          company: { _eq: $company }
          user: { _eq: $user }
        }
        start_date: { _lte: $endDate }
        end_date: { _gte: $endDate }
      }
      _set: { end_date: $endDate }
    ) {
      affected_rows
    }
  }
`
export const LIST_TERMINATION_STATUS = gql`
  {
    termination_status: global_placement_termination {
      id
      name
    }
  }
`


export const DELETE_ALL_REGISTERED_EMPLOYEE = gql`
  mutation($user:[uuid!],$status:enum_global_users_status){
    delete_people_addresses(
    where:{user:{_in:$user}, global_user:{status:{_eq:$status}}}){
      affected_rows
    }
    
    delete_people_phones(
    where:{user:{_in:$user},global_user:{status:{_eq:$status}}}){
      affected_rows
    }
    
    delete_people_emails(
    where:{user:{_in:$user},global_user:{status:{_eq:$status}}}){
      affected_rows
    }
    
    delete_people_work_placements(
    where:{user:{_in:$user},global_user:{status:{_eq:$status}}}){
      affected_rows
    }
    
    delete_global_users(
    where:{id:{_in:$user},status:{_eq:$status}}){
      affected_rows
    }
  }
`;
