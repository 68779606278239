import React from 'react'
import {
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
} from '../../../../GlobalStyles'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import idLocale from 'date-fns/locale/id'
import {DatePickerStyled, WrapperDisable} from '../EmployeePageStyles'

export default function ComponentDate(props) {
  const {number, name, value, error, id, ...other} = props
  const length = number.split('').length
  return (
    <FormChildContainer id={id}>
      <FormChildTitle>
        <TitleNumber style={length === 2 ? {paddingRight: 10} : null}>
          {number}.
        </TitleNumber>
        <TitleName>{name}</TitleName>
      </FormChildTitle>
      <FormChildInput>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
          <WrapperDisable>
            <DatePickerStyled
              style={{fontSize: 14}}
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              size="small"
              value={value}
              error={error}
              helperText={error ? 'Bagian ini diperlukan' : null}
              {...other}
            />
          </WrapperDisable>
        </MuiPickersUtilsProvider>
      </FormChildInput>
    </FormChildContainer>
  )
}
