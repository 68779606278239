
import { useMutation } from "@apollo/react-hooks";
import { DELETE_ALL_REGISTERED_EMPLOYEE } from "../../../../graphql/mutations";

export function useDeleteRegisteredEmployee() {
  const [deleteRegisteredEmployee] = useMutation(DELETE_ALL_REGISTERED_EMPLOYEE)
  
  const onDelete = ({user, onSucess, onError}) => {
    deleteRegisteredEmployee({
      variables: {
        user,
        status: "REGISTERED",
      },
    })
      .then(async () => {
        onSucess()
      })
      .catch(() => onError())
  };

  return [onDelete];
}
