import gql from 'graphql-tag'

export const GET_LIST_QUESTION_BANK = gql`
  query getListQuestionBank($id: bigint!) {
    multirater_bank_questions(
      where: {group: {_eq: $id}}
      order_by: {number: asc}
    ) {
      id
      question_self
      question_others
      number
    }
  }
`

export const GET_DETAIL_QUESTION_BANK = gql`
  query getDetailQuestionBank($group: bigint!, $id: bigint!) {
    multirater_bank_questions(where: {group: {_eq: $group}, id: {_eq: $id}}) {
      id
      question_self
      question_others
      number
    }
  }
`
