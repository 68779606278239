import React from 'react'
import {Skeleton} from '@material-ui/lab'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  loaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 0px',
    justifyContent: 'space-between',
  },
  loaderContent: {
    display: 'flex',
  },
  loaderProfile: {
    marginLeft: 30,
  },
  loaderUserName: {
    marginBottom: 12,
  },
  loaderBadge: {
    borderRadius: 5,
  },
}))

export const Loader = ({showAction = true, width}) => {
  const classes = useStyles()

  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loaderContent}>
        <Skeleton variant="circle" width={80} height={80} />
        <div className={classes.loaderProfile}>
          <Skeleton
            className={classes.loaderUserName}
            variant="rect"
            width={width || 320}
            height={24}
          />
          <Skeleton variant="rect" width={120} height={24} />
        </div>
      </div>
      {showAction && (
        <Skeleton
          className={classes.loaderBadge}
          variant="rect"
          width={90}
          height="auto"
        />
      )}
    </div>
  )
}
