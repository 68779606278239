import OvertimePage from './OvertimePageComponent'

export const OvertimePageConfig = {
  routes: [
    {
      path: '/time/overtime',
      component: OvertimePage,
      state: 'activity',
      exact: true,
    },
    {
      path: '/time/overtime/activity',
      component: OvertimePage,
      state: 'activity',
      exact: false,
    },
    {
      path: '/time/overtime/setting',
      component: OvertimePage,
      state: 'settings',
      exact: true,
    },
    {
      path: '/time/overtime/setting/add',
      component: OvertimePage,
      state: 'add-setting',
    },
    {
      path: '/time/overtime/setting/:id',
      component: OvertimePage,
      state: 'edit-setting',
      exact: false,
    },
    {
      path: '/time/overtime/report',
      component: OvertimePage,
      state: 'report',
      exact: true,
    },
  ],
}
