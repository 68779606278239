import React, {useState, useEffect} from 'react'
import helperStyle, {OrganizationContainer} from '../../OfficePageStyles'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
  TextHelper,
} from '../../../../../GlobalStyles'
import {
  TextField,
  CircularProgress,
  InputAdornment,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import {MapMarker} from 'mdi-material-ui'
import classNames from 'classnames'
import Select from 'react-select'
import {useQuery} from '@apollo/react-hooks'
import {GET_PROVINCE, GET_CITY} from '../../../../../graphql/queries'

import MapPopup from '../../../../shared-components/popup/MapPopup'
import AlertPopup from '../../../../shared-components/popup/ResultPopup'

const TabOfficeData = props => {
  const {
    setAllData,
    allData,
    dataEdit,
    dataMain,
    isError,
    // nameChecker,
    // checkSameNameWithAdd,
    checkSameCodeWithAdd,
    codeChecker,
    switchChecked,
    setSwitchChecked,
  } = props
  const classes = helperStyle()
  const [mapOpen, setMapOpen] = useState(false)
  const [provinceDropdown, setProvinceDropdown] = useState([])
  const [cityDropdown, setCityDropdown] = useState([])

  const [openActiveFailMain, setOpenActiveFailMain] = useState(false)

  useEffect(() => {
    if (allData !== null) {
      setProvinceDropdown({
        label: allData && allData.provinceLabel,
        value: allData && allData.province,
      })
      setCityDropdown({
        label: allData && allData.cityLabel,
        value: allData && allData.city,
      })
    }
  }, [])

  useEffect(() => {
    if (dataEdit) {
      setProvinceDropdown({
        label:
          dataEdit && dataEdit.global_province && dataEdit.global_province.name,
        value:
          dataEdit && dataEdit.global_province && dataEdit.global_province.id,
      })
      setCityDropdown({
        label: dataEdit && dataEdit.global_city && dataEdit.global_city.name,
        value: dataEdit && dataEdit.global_city && dataEdit.global_city.id,
      })
    }
  }, [dataEdit])

  const {
    data: province,
    error: provinceError,
    loading: provinceLoad,
  } = useQuery(GET_PROVINCE, {
    wlb_skipPatch: true,
  })

  const {data: city, error: cityError, loading: cityLoad} = useQuery(GET_CITY, {
    variables: {
      prov: provinceDropdown && provinceDropdown.value,
    },
  })

  if (provinceLoad || cityLoad) {
    return <CircularProgress />
  } else if (provinceError !== undefined || cityError !== undefined) {
    return (
      <div>
        {provinceError}
        {cityError}
      </div>
    )
  }

  const pro = []
  province &&
    province.global_provinces.map(options => {
      const provinceOptions = {
        value: options.id,
        label: options.name,
      }

      pro.push(provinceOptions)
    })

  const ct = []
  city &&
    city.global_cities.map(options => {
      const cityOptions = {
        value: options.id,
        label: options.name,
      }

      ct.push(cityOptions)
    })

  const handleMapOpen = () => {
    mapOpen ? setMapOpen(false) : setMapOpen(true)
  }

  const setAddress = (address, lat, lng) => {
    setAllData({
      ...allData,
      address: address,
      address_field: {
        ...allData.address_field,
        lat: lat,
        lng: lng,
      },
    })
  }

  const handleSetSelect = (event, id, label) => {
    const objContext = {...allData, [id]: event.value, [label]: event.label}
    if (id === 'province') {
      setProvinceDropdown({
        label: event.label,
        value: event.value,
      })
      setCityDropdown([])
      objContext.city = null
      objContext.cityLabel = null
    } else {
      setCityDropdown({
        label: event.label,
        value: event.value,
      })
    }
    setAllData(objContext)
  }
  return (
    <OrganizationContainer>
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="parent">1.</TitleNumber>
            <TitleName>Office Code*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="office-code"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              value={allData && allData.office_code}
              fullWidth
              className={classes.helperMarginLeft0}
              margin="normal"
              error={
                (allData && !allData.office_code && isError) ||
                (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
              }
              helperText={
                allData && !allData.office_code && isError
                  ? 'This field is required'
                  : (codeChecker
                    ? !checkSameCodeWithAdd
                    : checkSameCodeWithAdd)
                  ? 'Office Code is already exist'
                  : ''
              }
              onChange={event => {
                setAllData({
                  ...allData,
                  office_code: event.target.value,
                })
              }}
              variant="outlined"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="parent">2.</TitleNumber>
            <TitleName>Office Name*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="office-name"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              value={allData && allData.office_name}
              fullWidth
              className={classes.helperMarginLeft0}
              margin="normal"
              error={
                allData && !allData.office_name && isError
                // || (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
              }
              helperText={
                allData && !allData.office_name && isError
                  ? 'This field is required'
                  : // : (nameChecker
                    //   ? !checkSameNameWithAdd
                    //   : checkSameNameWithAdd)
                    // ? 'Name is already exist'
                    ''
              }
              onChange={event => {
                setAllData({
                  ...allData,
                  office_name: event.target.value,
                })
              }}
              variant="outlined"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer className={classes.marginTop10}>
          <FormChildTitle>
            <TitleNumber id="address">3.</TitleNumber>
            <TitleName>Address*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="address"
              value={allData && allData.address}
              className={classNames(
                classes.height39,
                classes.width100P,
                classes.marginTop15,
                classes.helperMarginLeft0
              )}
              error={allData && !allData.address && isError}
              helperText={
                allData && !allData.address && isError
                  ? 'This field is required'
                  : ''
              }
              variant="outlined"
              disabled={true}
              onClick={handleMapOpen}
              InputProps={{
                className: classes.myInheritText,
                endAdornment: (
                  <InputAdornment className="ml-0" position="end">
                    <MapMarker
                      style={{color: '#a9a8a8', width: 20, height: 20}}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="province">4.</TitleNumber>
            <TitleName>Province *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              className={classes.marginTop15}
              id="parent"
              options={pro}
              value={provinceDropdown}
              onChange={event =>
                handleSetSelect(event, 'province', 'provinceLabel')
              }
              placeholder="Select Province"
              styles={{
                menu: provided => ({...provided, zIndex: 9999}),
              }}
            />
            <TextHelper className={classes.marginLeft0}>
              {allData && !allData.province && isError
                ? 'This field is required'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="city">5.</TitleNumber>
            <TitleName>City/Disrict *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              className={classes.marginTop15}
              id="parent"
              options={ct}
              value={cityDropdown}
              onChange={event => handleSetSelect(event, 'city', 'cityLabel')}
              placeholder="Select City/Disrict"
              styles={{
                menu: provided => ({...provided, zIndex: 9999}),
              }}
            />
            <TextHelper className={classes.marginLeft0}>
              {allData && !allData.city && isError
                ? 'This field is required'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="parent">6.</TitleNumber>
            <TitleName>Postal Code*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="postal-code"
              InputProps={{
                inputProps: {
                  min: 0,
                },
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              type="number"
              value={allData && allData.postal_code}
              fullWidth
              className={classes.helperMarginLeft0}
              margin="normal"
              error={allData && !allData.postal_code && isError}
              helperText={
                allData && !allData.postal_code && isError
                  ? 'This field is required'
                  : ''
              }
              onChange={event => {
                setAllData({
                  ...allData,
                  postal_code: event.target.value,
                })
              }}
              variant="outlined"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="additional-information">7.</TitleNumber>
            <TitleName>Additional Information</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="additional-information"
              InputProps={{
                inputProps: {
                  min: 0,
                },
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              value={
                allData &&
                allData.address_field &&
                allData.address_field.additional_info
              }
              onChange={event => {
                setAllData({
                  ...allData,
                  address_field: {
                    ...allData.address_field,
                    additional_info: event.target.value,
                  },
                })
              }}
              fullWidth
              size="small"
              margin="normal"
              variant="outlined"
              multiline
              rows="4"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer className={classes.width35P}>
          <FormChildTitle>
            <TitleNumber id="parent">8.</TitleNumber>
            <TitleName>Office Status*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <FormControlLabel
              control={
                <Switch
                  checked={switchChecked}
                  onChange={() => {
                    if (
                      switchChecked &&
                      dataEdit &&
                      dataEdit.id === dataMain?.[0].company_address?.id
                    ) {
                      setOpenActiveFailMain(true)
                      return
                    }

                    const actInc = !switchChecked ? 'ACTIVE' : 'INACTIVE'
                    setSwitchChecked(!switchChecked)
                    setAllData({
                      ...allData,
                      status: actInc,
                    })
                  }}
                  color="primary"
                />
              }
              label={switchChecked ? 'Active' : 'Inactive'}
            />
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <MapPopup
        handleClose={handleMapOpen}
        open={mapOpen}
        setDataAddress={setAddress}
        addressName={allData && allData.address}
        addressCordinate={allData && allData.address_field}
      />
      <AlertPopup
        open={openActiveFailMain}
        handleClose={() => setOpenActiveFailMain(false)}
        feature="Cannot Change Status to Inactive"
        name={allData && allData.office_name}
        fMessage="Cannot Change Status "
        lMessage=" to Inactive because this office is the main office in this company."
        type="Confirm"
      />
    </OrganizationContainer>
  )
}

export default TabOfficeData
