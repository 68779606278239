import gql from 'graphql-tag'

export const GET_LOCATION_RESTRICTION = gql`
  query(
    $limit: Int
    $offset: Int
    $order: [time_attendance_location_order_by!]
  ) {
    time_attendance_location(limit: $limit, offset: $offset, order_by: $order) {
      id
      name
      location
      location_radius
      location_info
      time_attendance_policy_locations_aggregate {
        aggregate {
          count
        }
        nodes {
          time_attendance_policy {
            id
            name
          }
        }
      }
    }
    time_attendance_location_aggregate {
      aggregate {
        count
      }
    }
  }
`
export const GET_LOCATION_RESTRICTION_WSFORM = gql`
  query(
    $search: String
    $limit: Int
    $offset: Int
    $order: [time_attendance_location_order_by!]
    $choose: [Int!]
  ) {
    count: time_attendance_location_aggregate(
      where: {id: {_in: $choose}, _or: [{name: {_ilike: $search}}]}
      order_by: $order
    ) {
      aggregate {
        count
      }
    }

    countId: time_attendance_location_aggregate(
      where: {id: {_in: $choose}}
      order_by: $order
    ) {
      aggregate {
        count
      }
    }

    countAll: time_attendance_location_aggregate(order_by: $order) {
      aggregate {
        count
      }
    }

    time_attendance_location(
      where: {id: {_in: $choose}, _or: [{name: {_ilike: $search}}]}
      limit: $limit
      offset: $offset
      order_by: $order
    ) {
      id
      name
      location
      location_radius
      location_info
    }
  }
`
