import React from "react";
import { Avatar, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: { display: "flex", alignItems: "center", flexDirection: "row" },
  name: { marginLeft: 16, fontSize: 14 },
}));

export default function UserItemTable({ avatar, name }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Avatar src={avatar} alt={name} />
      <Typography className={classes.name}>{name}</Typography>
    </div>
  );
}
