import React, {useState, useEffect} from 'react'
import {Toolbar, IconButton, Divider, Button} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {
  PaperContainer,
  AddEditTitle,
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  FormChildInput,
  TitleName,
  FormChildButton,
} from '../../../../GlobalStyles'
import {TextFieldStyled} from '../MultiraterNpsPageStyles'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {useMutation, useApolloClient} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {UPDATE_RESPONDENTS_GROUP} from '../../../../graphql/mutations/survey/multirater-nps/respondentsGroup.mutation'
import {COMPANY_ID} from '../../../../utils/globals'
import {GET_LIST_RESPONDENTS_GROUP} from '../../../../graphql/queries/survey/multirater-nps/getListRespondentsGroup'

const EditRespondentsGroup = props => {
  const {enqueueSnackbar} = useSnackbar()
  const [name, setName] = useState('')
  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)
  const {id} = props.match.params
  const client = useApolloClient()

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  const [mutationEdit] = useMutation(UPDATE_RESPONDENTS_GROUP)

  useEffect(() => {
    async function fetchData() {
      const {data} = await client.query({
        query: GET_LIST_RESPONDENTS_GROUP,
        variables: {
          company: COMPANY_ID,
          id: id,
        },
      })
      if (!name) {
        setName(data.multirater_respondent_groups[0].name)
      }
    }
    fetchData()
  }, [name, id, COMPANY_ID])

  const editRespondentGroup = () => {
    if (name === '') {
      const ids = document.getElementById('top')
      ids.scrollIntoView({behavior: 'smooth'})
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const data = {
        id,
        name,
      }

      mutationEdit({variables: data})
        .then(() => {
          enqueueSnackbar('Success Update data', {variant: 'success'})
          props.history.push({
            pathname: `/survey/multirater-nps/respondents-group`,
            state: {active: 'respondents-group'},
          })
        })
        .catch(() => {
          enqueueSnackbar('Update data error, please try again', {
            variant: 'error',
          })
        })
    }
  }

  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link
          to={{
            pathname: `/survey/multirater-nps/respondents-group`,
            state: {active: 'respondents-group'},
          }}
        >
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <AddEditTitle variant="h6">Edit Asesi Group</AddEditTitle>
      </Toolbar>
      <Divider></Divider>
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Name</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextFieldStyled
              onChange={event => {
                setName(event.target.value)
              }}
              value={name}
              error={name && name.length < 1 && isError}
              helperText={
                name && name.length < 1 && isError
                  ? 'This field is required'
                  : ''
              }
              margin="normal"
              variant="outlined"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildButton>
          <Button
            color="default"
            size="large"
            component={Link}
            to={{
              pathname: `/survey/multirater-nps/respondents-group`,
              state: {active: 'respondents-group'},
            }}
            style={{marginRight: 12}}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="large"
          >
            Save
          </Button>
        </FormChildButton>
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        name={'Asesi Group'}
        type={'Edit'}
        mutation={editRespondentGroup}
        feature={''}
      />
    </PaperContainer>
  )
}

export default withRouter(EditRespondentsGroup)
