import PositionPage from './PositionPageComponent'

export const PositionPageConfig = {
  routes: [
    {
      path: '/organization/position',
      component: PositionPage,
      state: 'position',
      exact: true,
    },
    {
      path: '/organization/position/detail',
      component: PositionPage,
      state: 'detail-position',
      exact: false,
    },
    {
      path: '/organization/position/add',
      component: PositionPage,
      state: 'add-position',
      exact: false,
    },
    {
      path: '/organization/position/edit',
      component: PositionPage,
      state: 'edit-position',
      exact: false,
    },
  ],
}
