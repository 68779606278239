import gql from 'graphql-tag'

export const GET_ASSIGNED_COMPONENT = gql`
  query getAssignedComponent(
    $userid: uuid!
    $company: uuid!
    $limit: Int
    $offset: Int
    $search: String
  ) {
    total: finance_nonfixed_components_aggregate(
      where: {
        name: {_ilike: $search}
        finance_component_assignments: {
          deletedAt: {_is_null: true}
          people_work_placement: {
            user: {_eq: $userid}
            company: {_eq: $company}
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    current_assigned_components_as_exclusion_component_to_be_assigned: finance_nonfixed_components(
      where: {
        finance_component_assignments: {
          deletedAt: {_is_null: true}
          people_work_placement: {
            user: {_eq: $userid}
            company: {_eq: $company}
          }
        }
      }
    ) {
      id
      name
    }

    current_assigned_components: finance_nonfixed_components(
      where: {
        name: {_ilike: $search}
        finance_component_assignments: {
          deletedAt: {_is_null: true}
          people_work_placement: {
            user: {_eq: $userid}
            company: {_eq: $company}
          }
        }
      }
      limit: $limit
      offset: $offset
    ) {
      id
      name
    }
  }
`

export const GET_SUGGESTED_ASSIGNED_COMPONENT = gql`
  query getSuggestedAssignedComponent(
    $userid: uuid!
    $company: uuid
    $search: String
    $excludeAssignment: [Int!]
    $limit: Int
    $offset: Int
  ) {
    total: finance_nonfixed_components_aggregate(
      where: {
        name: {_ilike: $search}
        deletedAt: {_is_null: true}
        id: {_nin: $excludeAssignment}
        _not: {
          _or: {
            _and: {
              finance_component_assignments: {
                deletedAt: {_is_null: true}
                people_work_placement: {
                  user: {_eq: $userid}
                  company: {_eq: $company}
                }
              }
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    suggested_components: finance_nonfixed_components(
      limit: $limit
      offset: $offset
      where: {
        name: {_ilike: $search}
        deletedAt: {_is_null: true}
        id: {_nin: $excludeAssignment}
        _not: {
          _or: {
            _and: {
              finance_component_assignments: {
                deletedAt: {_is_null: true}
                people_work_placement: {
                  user: {_eq: $userid}
                  company: {_eq: $company}
                }
              }
            }
          }
        }
      }
    ) {
      id
      name
    }
  }
`

export const GET_SELECTED_ASSIGNED_COMPONENT = gql`
  query getSelectedAssignedComponent(
    $userid: uuid!
    $company: uuid
    $search: String
    $includeAssignment: [Int!]
    $limit: Int
    $offset: Int
  ) {
    total: finance_nonfixed_components_aggregate(
      where: {
        name: {_ilike: $search}
        deletedAt: {_is_null: true}
        id: {_in: $includeAssignment}
        _not: {
          _or: {
            _and: {
              finance_component_assignments: {
                deletedAt: {_is_null: true}
                people_work_placement: {
                  user: {_eq: $userid}
                  company: {_eq: $company}
                }
              }
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    selected_components: finance_nonfixed_components(
      limit: $limit
      offset: $offset
      where: {
        name: {_ilike: $search}
        deletedAt: {_is_null: true}
        id: {_in: $includeAssignment}
        _not: {
          _or: {
            _and: {
              finance_component_assignments: {
                deletedAt: {_is_null: true}
                people_work_placement: {
                  user: {_eq: $userid}
                  company: {_eq: $company}
                }
              }
            }
          }
        }
      }
    ) {
      id
      name
    }
  }
`
