import gql from 'graphql-tag'

export const GET_LEAVE_ACTIVITY_CALENDAR = gql`
  query getLeaveActivity($where: people_work_leaves_bool_exp) {
    people_work_leaves(where: $where, order_by: {datestart: asc}) {
      id
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements {
              global_user {
                id
                name
                avatar
              }
            }
          }
        }
      }
      description
      is_half
      datestart
      dateend
      date_added
      last_modified
      contacts
      globalUserByModifiedBy {
        id
        name
      }
      status
      quota_taken
      time_leave_policy_child {
        id
        name
        confirm_type
        parent
        time_leave_policy {
          id
          name
        }
      }
      leave_fields
    }
  }
`

export const GET_HOLIDAY_CALENDAR = gql`
  query getHoliday($from: date!, $to: date!, $name: String) {
    time_holidays(
      where: {date: {_gte: $from, _lte: $to}, name: {_eq: $name}}
      order_by: {date: asc}
    ) {
      id
      name
      description
      type
      date
      recurring
      recur_time_x
      recur_end_opt
      recur_end_date
      recur_end_x
      leave_policy_id
    }
  }
`
