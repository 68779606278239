import React from 'react'
import {Icon} from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import {
  TyphoSection,
  WrapperItemSection,
  LogoImg,
} from '../../DetailEmployeeStyles'
import {format} from 'date-fns'
import {
  AttachmentItemHover,
  WrapAttachment,
} from '../../../../../shared-components/attachment'
import useEllipsisText from '../../../../../../hooks/useEllipsisText'
import useToggle from '../../../../../../hooks/useToggle'

export const LicensesItem = ({res, onEdit}) => {
  const _description = res?.description?.length === 0 ? '-' : res?.description
  const {briefText} = useEllipsisText({text: _description})
  const [more, setMore] = useToggle()

  return (
    <WrapperItemSection>
      <LogoImg
        src={res.company_profile ? res.company_profile.logo : null}
        style={{width: 100, height: 100, borderRadius: 5}}
        alt="logo"
      />
      <div className="divider">
        <div className="content">
          <a
            style={{textDecoration: 'none'}}
            href={res.credential_url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <TyphoSection title="true" bold="true">
              {res.name || '-'}
            </TyphoSection>
          </a>

          <TyphoSection grey="true" style={{lineHeight: '5px'}}>
            {`${res?.level === null ? '' : res?.level} - ${
              res?.type === null ? '' : res?.type + ' Training'
            }`}
          </TyphoSection>
          <TyphoSection blue="true" style={{lineHeight: '34px'}}>
            {res.issuer_name || ' - '}
          </TyphoSection>
          <TyphoSection grey="true">
            {format(new Date(res.date_issued), 'MMM yyyy')}
          </TyphoSection>
          <TyphoSection mt="true">
            {more ? _description : briefText}
          </TyphoSection>
          {more && (
            <>
              <TyphoSection grey="true">
                Certificate URL{' '}
                <span style={{color: '#039be5'}}>
                  {res.credential_url || '-'}
                </span>
              </TyphoSection>
              <TyphoSection italic="true" grey="true">
                Credential ID {res.credential_id || '-'}
              </TyphoSection>
              <WrapAttachment>
                {res.attachments &&
                  res.attachments.map((attachment, i) => (
                    <AttachmentItemHover
                      key={`${i}-itm`}
                      iconAction={
                        <VisibilityOutlinedIcon
                          onClick={() =>
                            window.open(attachment.link || attachment.url)
                          }
                          className="icon"
                        />
                      }
                      fileName={attachment.name}
                      url={attachment.link || attachment.url}
                    />
                  ))}
              </WrapAttachment>
            </>
          )}
          <TyphoSection
            mt="true"
            style={{color: '#a9a8a8', cursor: 'pointer'}}
            onClick={setMore}
          >
            {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
          </TyphoSection>
        </div>
      </div>

      <Icon onClick={onEdit} className="icon-edit">
        edit
      </Icon>
    </WrapperItemSection>
  )
}
