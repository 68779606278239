import gql from 'graphql-tag'

export const GET_TALENT_DETAIL_COMP = gql`
  query($name: String, $id: uuid) {
    global_users(where: {id: {_eq: $id}, name: {_ilike: $name}}) {
      id
      name
      avatar
      biography
      company_profiles {
        legal_name
        company_job_profiles {
          title
        }
      }
      people_profile_educations {
        company_profile {
          legal_name
        }
      }
      people_work_placements {
        regno
        type
        email
        type_time
        type_place
        start_date
        end_date
        company_employee_position {
          name
        }
        facilities {
          name
        }
        peopleSubordinate {
          global_user {
            name
          }
          company_profile {
            brand_name
            company_job_profiles {
              title
            }
          }
        }
        company_organizations {
          name
        }
      }
      people_profile_experiences {
        location
        from
        to
        is_present
        description
        #decree
        title
        #referal_name
        #referal_position
        #referal_contact_phone
      }
      people_profile_projects {
        project_name
        start_date
        end_date
        is_current
        location
        description
      }
      people_profiles {
        people_profile_organization_activities {
          organization_name
          organization_type
          member_id
          position_name
          position_description
          start_date
          end_date
          country
          global_city {
            name
          }
          global_province {
            name
          }
          position_description
        }
      }
      people_profile_educations {
        company_profile {
          legal_name
        }
        from
        to
        is_present
        description
        education_fields
        global_major {
          name
        }
        global_degree {
          name
        }
        global_faculty {
          name
        }
      }
      people_profile_certificates {
        date_issued
        date_expired
        can_expire
        credential_url
        credential_id
        issuer
        description
        name
        attachments
      }
      people_profile_speakers {
        event_name
        roles
        organizer
        scale
        location
        description
      }
      people_profile_skills {
        people_skill {
          name
        }
        people_skill_level {
          name
        }
      }
      people_profile_awards {
        reference_date
        letter_number
        certificate_number
        award_name
        issuing_organization
        reference_date
        remark
        attachments
        company_profile {
          legal_name
        }
      }
      #people_profile_publications {
      #publication_media
      #publication_url
      #publication_date
      #publication_title
      #description
      #attachments
      #global_user {
      #  name
      #}
      #}
      people_profile_service_agreements {
        code
        start_date
        end_date
        description
        name
        attachments
      }
      people_profile_disciplinaries {
        is_share
        reference_date
        reference_number
        discipline_name
        discipline_letter_number
        start_date
        end_date
        remark
        attachments
      }
      people_profile_ohsas {
        incident_code
        incident_location
        incident_date_time
        company_name
        company_location
        employees
        category
        severity
        description
        attachments
      }
      people_profile_hobbies {
        description
        hobby_fields
        people_hobby {
          name
          #hobby_sub_category {
          #  name
          #}
          #hobby_category {
          #  name
          #}
        }
      }
      people_profile_recommendations {
        relationship
        position
        description
        status
        globalUserByRecommendationTo {
          name
        }
      }
    }
  }
`
