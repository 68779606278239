import gql from 'graphql-tag'

export const GET_LIST_WORK_SCHEDULE = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: time_attendance_policies_aggregate(
      where: {name: {_ilike: $search}}
    ) {
      aggregate {
        count
      }
    }

    time_attendance_policies(
      where: {name: {_ilike: $search}}
      offset: $offset
      limit: $limit
    ) {
      id
      name
      type
      schedule
      flexi
      late_tolerance
      time_attendance_policy_locations {
        time_attendance_location {
          id
          name
          location
        }
      }
    }
  }
`
