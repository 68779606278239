import React, {useState, useEffect} from 'react'
import helperStyle, {
  OrganizationContainer,
  StyledDialog,
  ModalHeader,
  FlexBetween,
  ModalContent,
  DetailJobProfileContainer,
  TextBlackBold,
  TextGreyBold,
} from '../../../OrganizationPageStyles'
import {Icon, IconButton, Paper, Tab, Tabs, Typography} from '@material-ui/core'
import classNames from 'classnames'
import {GET_DETAIL_JOB_PROFILE} from '../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {convertToRupiah, fileChecker} from '../../../../../utils/helpers'
import Loading from '../../../../../components/loading/LoadingComponent'
import documents from '../../../../../assets/images/document.svg'
import pdfIcon from '../../../../../assets/images/font-awesome/file-pdf.svg'
import excelIcon from '../../../../../assets/images/font-awesome/file-excel.svg'
import pptIcon from '../../../../../assets/images/font-awesome/file-powerpoint.svg'

const {getOrgRoles} = require('wlb-role-helper')

const DetailJobProfileModal = props => {
  const {open, close, id} = props

  const classes = helperStyle()
  const [tabsValue, setTabsValue] = useState(0)

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }
  const {data, loading, error, refetch} = useQuery(GET_DETAIL_JOB_PROFILE, {
    variables: {
      id: id,
    },
  })

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [open])

  if (loading) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const code = data?.company_job_profiles[0]?.code ?? ''
  const jobName = data?.company_job_profiles[0]?.title ?? ''
  const icon = data?.company_job_profiles[0]?.job_icon ?? ''
  const parent =
    data?.company_job_profiles[0]?.job_profile_supervisor?.title ?? ''
  const parentCode =
    data?.company_job_profiles[0]?.job_profile_supervisor?.code ?? ''
  const organization =
    data?.company_job_profiles[0]?.company_organization?.name ?? ''
  const organizationCode =
    data?.company_job_profiles[0]?.company_organization?.code ?? ''
  const designation =
    data?.company_job_profiles[0]?.company_employee_position?.name ?? ''
  const designationCode =
    data?.company_job_profiles[0]?.company_employee_position
      ?.designation_code ?? ''
  const level = data?.company_job_profiles[0]?.company_employee_rank?.name ?? ''
  const levelCode =
    data?.company_job_profiles[0]?.company_employee_rank?.code ?? ''
  const grade = data?.company_job_profiles[0]?.job_grade?.name ?? ''
  const gradeCode = data?.company_job_profiles[0]?.job_grade?.code ?? ''
  const acting = data?.company_job_profiles[0]?.acting_as?.title ?? ''
  const require = data?.company_job_profiles[0]?.require_successor
    ? 'Yes'
    : 'No'
  const office =
    data?.company_job_profiles[0]?.company_address?.office_name ?? ''
  const officeCode =
    data?.company_job_profiles[0]?.company_address?.office_code ?? ''
  const jobSumary = data?.company_job_profiles[0]?.job_summary ?? ''
  const jobDesc = data?.company_job_profiles[0]?.job_description ?? ''
  const competencyModel =
    data?.company_job_profiles[0]?.company_job_profile_competencies ?? ''
  const salary_from = data?.company_job_profiles[0]?.rate_salary_from ?? 0
  const salary_to = data?.company_job_profiles[0]?.rate_salary_to ?? 0
  const slots = data?.company_job_profiles[0]?.slots ?? ''
  const education =
    data?.company_job_profiles[0]?.company_job_profile_r_educations ?? []
  // const course =
  //   data.company_job_profiles[0] &&
  //   data.company_job_profiles[0].company_job_profile_r_course
  //     ? data.company_job_profiles[0] &&
  //       data.company_job_profiles[0].company_job_profile_r_course
  //     : []
  const kpi = data?.company_job_profiles[0]?.company_job_profile_r_kpi ?? []
  // const workSchedule =
  //   data.company_job_profiles[0] &&
  //   data.company_job_profiles[0].company_job_profile_r_attendances
  // const leave =
  //   data.company_job_profiles[0] &&
  //   data.company_job_profiles[0].company_job_profile_r_leaves
  // const time =
  //   data.company_job_profiles[0] &&
  //   data.company_job_profiles[0].company_job_profile_r_overtimes
  // const allowance =
  //   data.company_job_profiles[0] &&
  //   data.company_job_profiles[0].company_job_profile_r_allowances
  // const loan =
  //   data.company_job_profiles[0] &&
  //   data.company_job_profiles[0].company_job_profile_r_loans
  // const cash =
  //   data.company_job_profiles[0] &&
  //   data.company_job_profiles[0].company_job_profile_r_cash_advs
  // const document =
  //   data.company_job_profiles[0] &&
  //   data.company_job_profiles[0].company_job_profile_r_documents
  // const facility =
  //   data.company_job_profiles[0] &&
  //   data.company_job_profiles[0].company_job_profile_r_facilities
  const access = data?.company_job_profiles[0]?.roles ?? ''
  const orgRoles = access ? getOrgRoles(access.toString()) : []

  return (
    <StyledDialog
      onClose={() => {
        close()
        setTabsValue(0)
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <ModalHeader>
        <FlexBetween>
          <Typography variant="h6">Rincian Posisi</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              close()
              setTabsValue(0)
            }}
          >
            <Icon
              style={{
                color: '#ffffff',
                fontSize: '37px',
              }}
            >
              close
            </Icon>
          </IconButton>
        </FlexBetween>
      </ModalHeader>
      <ModalContent
        className={classNames(
          classes.padding0
          // classes.maxHeight400
        )}
      >
        <OrganizationContainer>
          <Tabs
            value={tabsValue}
            onChange={handleChangeTabs}
            indicatorColor="secondary"
            classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
          >
            <Tab label="Informasi" className={classes.tabRoot} />
            <Tab label="Talenta" className={classes.tabRoot} />
            <Tab label="Pengaturan Tambahan" className={classes.tabRoot} />
          </Tabs>

          {tabsValue === 0 && (
            <DetailJobProfileContainer className={classes.padding26}>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Kode Jabatan</TextGreyBold>
                <TextBlackBold className={classes.width80P}>
                  {code}
                </TextBlackBold>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Nama Jabatan</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {icon && (
                    <img
                      className={classNames(
                        classes.width15,
                        classes.verticalAlignBot
                      )}
                      src={icon}
                      alt={icon}
                    />
                  )}
                  <TextBlackBold
                    className={classNames(
                      classes.inlineBlock,
                      icon && classes.marginLeft10
                    )}
                  >
                    {jobName}
                  </TextBlackBold>
                </DetailJobProfileContainer>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Induk</TextGreyBold>
                <div className={classes.width80P}>
                  <TextBlackBold>{parent}</TextBlackBold>
                  <TextGreyBold>{parentCode}</TextGreyBold>
                </div>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Organisasi</TextGreyBold>
                <div className={classes.width80P}>
                  <TextBlackBold>{organization}</TextBlackBold>
                  <TextGreyBold>{organizationCode}</TextGreyBold>
                </div>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Jabatan</TextGreyBold>
                <div className={classes.width80P}>
                  <TextBlackBold>{designation}</TextBlackBold>
                  <TextGreyBold>{designationCode}</TextGreyBold>
                </div>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Jenjang Jabatan</TextGreyBold>
                <div className={classes.width80P}>
                  <TextBlackBold>{level}</TextBlackBold>
                  <TextGreyBold>{levelCode}</TextGreyBold>
                </div>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Kelas Jabatan</TextGreyBold>
                <div className={classes.width80P}>
                  <TextBlackBold>{grade}</TextBlackBold>
                  <TextGreyBold>{gradeCode}</TextGreyBold>
                </div>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Bertindak sebagai</TextGreyBold>
                <TextBlackBold className={classes.width80P}>
                  {acting}
                </TextBlackBold>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Penerus Posisi</TextGreyBold>
                <TextBlackBold className={classes.width80P}>
                  {require}
                </TextBlackBold>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Lokasi Kantor</TextGreyBold>
                <div className={classes.width80P}>
                  <TextBlackBold>{office}</TextBlackBold>
                  <TextGreyBold>{officeCode}</TextGreyBold>
                </div>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Tambah Uraian Jabatan</TextGreyBold>
                <TextBlackBold
                  className={classes.width80P}
                  dangerouslySetInnerHTML={{__html: jobDesc}}
                />
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Tanggung Jawab Jabatan</TextGreyBold>
                <TextBlackBold
                  className={classes.width80P}
                  dangerouslySetInnerHTML={{__html: jobSumary}}
                />
              </FlexBetween>
              <div style={{display: 'flex'}} className={classes.marginTopBot20}>
                <TextGreyBold style={{width: '20%'}}>Lampiran</TextGreyBold>
                <div
                  style={{display: 'flex', textAlign: 'center'}}
                  className={classes.width80P}
                >
                  {data.company_job_profiles[0] &&
                    data.company_job_profiles[0].attachment &&
                    data.company_job_profiles[0].attachment.map((att, i) => {
                      return (
                        <Paper
                          key={i}
                          elevation={2}
                          className={`${classes.padding14} ${classes.buttonSpacer}`}
                          style={{width: '20%', overflow: 'hidden'}}
                        >
                          <img
                            src={
                              fileChecker(att.name) === 'document'
                                ? documents
                                : fileChecker(att.name) === 'pdf'
                                ? pdfIcon
                                : fileChecker(att.name) === 'excel'
                                ? excelIcon
                                : fileChecker(att.name) === 'powerpoint'
                                ? pptIcon
                                : att.link
                            }
                            width="25"
                          />
                          <a
                            className={`${classes.marginTop10} ${classes.links}`}
                            href={att.link}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {att.name}
                          </a>
                        </Paper>
                      )
                    })}
                </div>
              </div>
            </DetailJobProfileContainer>
          )}
          {tabsValue === 1 && (
            <DetailJobProfileContainer className={classes.padding26}>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Model Kompetensi</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {competencyModel.map(res => {
                    return (
                      <TextBlackBold key={res.id}>
                        {
                          res.competency_version_dictionary
                            .competency_dictionary.name.en
                        }
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Rentang Gaji</TextGreyBold>
                <TextBlackBold className={classes.width80P}>
                  {`${convertToRupiah(salary_from)} - ${convertToRupiah(
                    salary_to
                  )}`}
                </TextBlackBold>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>ABK</TextGreyBold>
                <TextBlackBold className={classes.width80P}>
                  {slots}
                </TextBlackBold>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Pendidikan</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {education.map((res, idx) => {
                    return (
                      <TextBlackBold key={idx}>
                        {res.global_faculty
                          ? `${res.global_degree.name} of ${res.global_faculty.name}`
                          : res.global_degree.name}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween>
              {/* <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Recomended Course</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {course.map(res => {
                    return (
                      <TextBlackBold key={res.id}>
                        {res.academy_course && res.academy_course.title}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween> */}
            </DetailJobProfileContainer>
          )}
          {tabsValue === 2 && (
            <DetailJobProfileContainer className={classes.padding26}>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Perjanjian Kinerja</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {kpi.map((res, id) => {
                    return (
                      <TextBlackBold key={id}>
                        {res.kpi_list && res.kpi_list.name}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween>
              {/* <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Work Schedule</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {workSchedule.map(res => {
                    return (
                      <TextBlackBold key={res.time_attendance_policy.id}>
                        {res.time_attendance_policy.name}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Leave Policy</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {leave.map(res => {
                    return (
                      <TextBlackBold key={res.time_leave_policy_child.id}>
                        {res.time_leave_policy_child.name}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Time Policy</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {time.map(res => {
                    return (
                      <TextBlackBold key={res.time_overtime_policy.id}>
                        {res.time_overtime_policy.name}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Allowance Policy</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {allowance.map(res => {
                    return (
                      <TextBlackBold key={res.finance_allowance_policy_child.id}>
                        {res.finance_allowance_policy_child.name}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Loan Policy</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {loan.map(res => {
                    return (
                      <TextBlackBold key={res.finance_loan_policy.id}>
                        {res.finance_loan_policy.name}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Cash Advance Policy</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {cash.map(res => {
                    return (
                      <TextBlackBold key={res.finance_cash_advance_policy.id}>
                        {res.finance_cash_advance_policy.name}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Digital Document </TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {document.map((res, id) => {
                    return (
                      <TextBlackBold key={id}>
                        {res.company_file.name}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween>
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Facility</TextGreyBold>
                <DetailJobProfileContainer className={classes.width80P}>
                  {facility.map((res, id) => {
                    return (
                      <TextBlackBold key={id}>
                        {res.company_facility.name}
                      </TextBlackBold>
                    )
                  })}
                </DetailJobProfileContainer>
              </FlexBetween> */}
              <FlexBetween className={classes.marginTopBot20}>
                <TextGreyBold>Hak Akses</TextGreyBold>
                <TextBlackBold className={classes.width80P}>
                  {orgRoles.map((res, key) => {
                    return (
                      <OrganizationContainer key={key}>
                        {res}
                      </OrganizationContainer>
                    )
                  })}
                </TextBlackBold>
              </FlexBetween>
            </DetailJobProfileContainer>
          )}
        </OrganizationContainer>
      </ModalContent>
    </StyledDialog>
  )
}

export default DetailJobProfileModal
