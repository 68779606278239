import {useLazyQuery} from '@apollo/react-hooks'
import Skeleton from '@material-ui/lab/Skeleton'
import React, {useEffect, useState} from 'react'
import {GET_LOCATION_AND_EDUCATION_DASHBOARD} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import ModalDetail from '../../../shared-components/popup/ModalDetail'
import {calculatePercent} from './LocationAndEducation'
import CardList from '../../../../components/widgets/progres-bar/card/CardListComponent'

export default function MoreLocation({title, open, onClose}) {
  const [dataChart, setDataChart] = useState({
    location: [],
    education: [],
  })

  const [getData, {data, loading}] = useLazyQuery(
    GET_LOCATION_AND_EDUCATION_DASHBOARD,
    {
      variables: {
        company: COMPANY_ID,
      },
    }
  )

  useEffect(() => {
    if (open) {
      getData()
    }
  }, [open])

  useEffect(() => {
    if (data) {
      setDataChart(() => {
        return {
          location: data.getLocation.map(({label, count}) => {
            return {
              label,
              count,
              percent: calculatePercent(
                count,
                data.total_Location.aggregate.sum.count
              ),
            }
          }),
          education: data.getEducation.map(({label, count}) => {
            return {
              label,
              count,
              percent: calculatePercent(
                count,
                data.total_Education.aggregate.sum.count
              ),
            }
          }),
          totalLocation: data.total_Location.aggregate.count,
          totalEducation: data.total_Education.aggregate.count,
        }
      })
    }
  }, [data, setDataChart, calculatePercent])

  return (
    <ModalDetail onClose={onClose} open={open} title={title} maxWidth="sm">
      <div style={{padding: '24px 12px'}}>
        {!loading && data && (
          <CardList
            isDetail
            data={
              (title === 'Lokasi' ? dataChart.location : dataChart.education) ||
              []
            }
          />
        )}
        {loading && (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </div>
    </ModalDetail>
  )
}
