import gql from 'graphql-tag'

export const DELETE_LEAVE_POLICY = gql`
  mutation DeleteLeavePolicy($deleteAt: timestamp, $id: Int) {
    update_time_leave_policy_children(
      _set: {deletedAt: $deleteAt}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
