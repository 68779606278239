import gql from 'graphql-tag'

export const SAVE_COMPONENT_SETTING = gql`
  mutation(
    $company: uuid!
    $code: String
    $name: String
    $type: Int
    $frequency: String
    $method_base: jsonb
    $calculation: String
    $calculation_mul: float8
    $calculation_value: float8
    $part_of: Int
    $taxable: Boolean
    $include_in_bpjs: Boolean
    $tax_paid_by: Int
    $effective_date: date
    $assignments: [finance_component_assignments_insert_input!]!
  ) {
    insert_finance_nonfixed_components_one(
      object: {
        company: $company
        code: $code
        name: $name
        type: $type
        component_method_base: $method_base
        component_frequency: $frequency
        component_calculation: $calculation
        component_calculation_mul: $calculation_mul
        custom_calculation_value: $calculation_value
        part_of: $part_of
        taxable: $taxable
        tax_paid_by: $tax_paid_by
        include_in_bpjs: $include_in_bpjs
        effective_date: $effective_date
        finance_component_assignments: {data: $assignments}
      }
    ) {
      id
    }
  }
`
