import React from 'react'
import {Icon} from '@material-ui/core'
import {
  TyphoSection,
  WrapperItemSection,
  LogoImg,
} from '../../DetailEmployeeStyles'
import useToggle from '../../../../../../hooks/useToggle'
import useEllipsisText from '../../../../../../hooks/useEllipsisText'

export const ProjectItem = ({
  project_name,
  date,
  location,
  description,
  position,
  project_url,
  creators,
  onEdit,
}) => {
  const _description = description?.length === 0 ? '-' : description
  const {briefText} = useEllipsisText({text: _description})
  const [more, setMore] = useToggle()

  return (
    <WrapperItemSection>
      <LogoImg
        style={{
          width: 100,
          height: 100,
          borderRadius: 5,
          marginRight: 50,
        }}
        imgProps={{
          style: {width: 'auto', height: '100%', borderRadius: 0},
        }}
      />

      <div className="divider">
        <div className="content">
          <TyphoSection title="true" bold="true">
            {project_name}
          </TyphoSection>
          <TyphoSection grey="true">{date}</TyphoSection>
          <TyphoSection blue="true">{creators}</TyphoSection>
          <TyphoSection style={{color: '#014a62'}}>{location}</TyphoSection>
          <TyphoSection mt="true">
            {more ? _description : briefText}
          </TyphoSection>

          {more && (
            <>
              <TyphoSection italic>
                {`Associate with ${creators || ''} ${position}`}
              </TyphoSection>
              <TyphoSection grey="true">
                Project URL :{' '}
                <a
                  style={{color: '#039be5'}}
                  href={`${
                    project_url.includes('http')
                      ? project_url
                      : 'https://' + project_url
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {project_url}
                </a>
              </TyphoSection>
            </>
          )}

          <TyphoSection
            mt="true"
            style={{color: '#a9a8a8', cursor: 'pointer'}}
            onClick={setMore}
          >
            {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
          </TyphoSection>
        </div>
      </div>

      <Icon onClick={onEdit} className="icon-edit">
        edit
      </Icon>
    </WrapperItemSection>
  )
}
