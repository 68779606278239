import gql from 'graphql-tag'

export const UPDATE_WORK_SCHEDULES = gql`
  mutation(
    $id: Int
    $name: String!
    $description: String!
    $type: String
    $schedule: JSON!
    $flexy: Int
    $lateTolerance: Int
    $labelColor: String
    $textColor: String
    $mobileSetting: JSON!
    $locationOption: Int
    $appliedLocations: JSON
    $isUpdatePosition: Boolean
    $filter: JSON
  ) {
    AttendancePolicyUpdate(
      id: $id
      name: $name
      description: $description
      type: $type
      schedule: $schedule
      flexy: $flexy
      late_tolerance: $lateTolerance
      label_color: $labelColor
      text_color: $textColor
      mobile_setting: $mobileSetting
      location_option: $locationOption
      applied_locations: $appliedLocations
      is_update_position: $isUpdatePosition
      filter: $filter
    ) {
      success
    }
  }
`
