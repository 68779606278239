import IndividualPage from './IndividualPageComponent'

export const IndividualPageConfig = {
  routes: [
    {
      path: '/performance/individual',
      component: IndividualPage,
      state: 'individual',
      exact: true,
    },
    {
      path: '/performance/individual/detail',
      component: IndividualPage,
      state: 'individual-detail',
      exact: true,
    },
    {
      path: '/performance/individual/detail/summary',
      component: IndividualPage,
      state: 'summary',
      exact: true,
    },
    {
      path: '/performance/individual/report',
      component: IndividualPage,
      state: 'individual-report',
      exact: true,
    },
  ],
}
