import gql from 'graphql-tag'

export const GET_CLAIM_ACTIVITIES = gql`
  query($where: people_work_claims_bool_exp, $limit: Int, $offset: Int) {
    total: people_work_claims_aggregate(where: $where, order_by: [{id: desc}]) {
      aggregate {
        count
      }
    }
    claims: people_work_claims(
      where: $where
      order_by: [{id: desc}]
      limit: $limit
      offset: $offset
    ) {
      id
      formatted_id
      date_added
      date_issued
      status
      employee: people_work_placement {
        id
        regno
        user: global_user {
          id
          name
        }
        profile: company_job_profile {
          id
          title
        }
      }
      policy: finance_claim_policy_child {
        id
        name
      }
      invoice: global_invoice {
        id
        module_name
      }
    }
  }
`

// Only check to see if we have a claim request or not. That's why the limit
// is set to 1.
export const GET_AVAILABLE_CLAIM_REQUEST = gql`
  query {
    claims: people_work_claims(where: {status: {_in: ["waiting"]}}, limit: 1) {
      id
    }
  }
`

export const GET_CLAIM_REPORT = gql`
  query($startDate: timestamp, $endDate: timestamp, $company: uuid) {
    v_claim_report_by_year(
      where: {
        date: {_gte: $startDate, _lte: $endDate}
        company: {_eq: $company}
      }
      order_by: [{date: asc}]
    ) {
      date
      value
    }
  }
`

export const GET_CLAIM_ACTIVITY_REPORT = gql`
  query ClaimActivityMonth(
    $limit: Int
    $offset: Int
    $where: people_work_claims_bool_exp
  ) {
    total: people_work_claims_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    people_work_claims(
      where: $where
      order_by: [{id: desc}]
      limit: $limit
      offset: $offset
    ) {
      claim_id: id
      claim_formatted_id: formatted_id
      people_work_placement {
        employee_id: regno
        global_user {
          employee_name: name
        }
        company_job_profile {
          id
          title
        }
      }
      claim_policy: finance_claim_policy_child {
        name
      }
      global_invoice {
        module_name
      }
      claim_date: date_issued
      claim_added: date_added
      status
      claim_nominal: value
    }
  }
`
