import MaterialTable from 'material-table'
import React from 'react'
import {Avatar} from '@material-ui/core'
import {Link} from 'react-router-dom'

import {CustomTypography, FlexCenters} from '../../../../../GlobalStyles'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import {
  TableLocalization,
  TableOptions,
} from '../../../../shared-components/table/TableOptions'

export default function TabsResponse({
  classes,
  data,
  loading,
  pagination,
  setPagination,
  microlearningId,
}) {
  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }
  return (
    <>
      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0', margin: '-24px'}}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent />,
          },
        }}
        columns={[
          {
            title: 'Peserta',
            field: 'user.name',
            render: row => (
              <FlexCenters style={{width: 250}}>
                <Avatar
                  src={row.global_user.avatar}
                  className={classes.avatar}
                />
                {row.global_user.name}
              </FlexCenters>
            ),
          },
          {
            title: 'Status',
            field: 'status',
            cellStyle: {wordBreak: 'initial'},
            render: row => (row.is_finished ? 'Finished' : 'Not Finished'),
          },
          {
            title: 'Jawaban benar',
            field: 'correct_answer_count',
            align: 'right',
            render: row =>
              row.question_answered_count + '/' + row.daily_question,
          },
          {
            title: 'Skor',
            field: 'score',
            align: 'right',
          },

          {
            title: 'Aksi',
            field: 'id',
            sorting: false,
            render: row => (
              <CustomTypography
                fcolor="lightBlue"
                cursor="pointer"
                style={{textDecoration: 'none'}}
                component={Link}
                to={`/learning/micro-learning/response/${microlearningId}/${row.global_user.id}`}
              >
                Rincian
              </CustomTypography>
            ),
          },
        ]}
        data={
          data &&
          data.microlearnings.length > 0 &&
          data.microlearnings[0].microlearning_members
        }
        title=""
        options={{
          ...TableOptions,
          selection: false,
          emptyRowsWhenPaging: false,
        }}
        components={{
          Toolbar: () => null,
        }}
        isLoading={loading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={
          data &&
          data.microlearnings.length > 0 &&
          data.microlearnings[0].microlearning_members_aggregate.aggregate.count
        }
        page={pagination.offset}
        pageSize={pagination.limit}
      />
    </>
  )
}
