export const getLocationFrom = ({pathname}) => {
  const alumni = '/directory/employee-alumni'
  const willJoin = '/directory/employee-will-join'
  const pending = '/directory/employee-pending'
  const pendingRegister = '/directory/employee-pending-register'
  const pendingReplacement = '/directory/employee-pending-replacement'
  const active = '/directory/employee'

  return {
    active,
    alumni,
    willJoin,
    pending,
    pendingRegister,
    pendingReplacement,

    isAlumni: alumni === pathname,
    isActive: active === pathname,
    isWillJoin: willJoin === pathname,
    isPendingPage:
      pendingRegister === pathname || pendingReplacement === pathname,
    isPendingRegister: pendingRegister === pathname,
    isPendingReplacement: pendingReplacement === pathname,
  }
}

export const generateVariable = ({pathname}) => {
  const {
    isWillJoin,
    isPendingPage,
    isAlumni,
    isPendingRegister,
    isPendingReplacement,
  } = getLocationFrom({pathname})

  let variables = {}
  let title = ''
  let activeState = ''

  if (isWillJoin) {
    title = 'Akan Bergabung'
    variables = {
      status: 'ACTIVE',
      willJoin: 'now()',
      activeEmployee: null,
      isTerminationNotNull: true,
    }
    activeState = 'employee-will-join'
  } else if (isPendingPage) {
    title = 'Menunggu Aktivasi'
    if (isPendingRegister) {
      variables = {
        global_user_status_filter: 'REGISTERED',
        status: 'REGISTERED',
        willJoin: null,
        activeEmployee: null,
        isTerminationNotNull: true,
      }
    }
    if (isPendingReplacement) {
      variables = {
        global_user_status_filter: 'ACTIVE',
        status: 'REGISTERED',
        willJoin: null,
        activeEmployee: null,
        isTerminationNotNull: true,
      }
    }
    activeState = 'employee-pending'
  } else if (isAlumni) {
    title = 'Alumni'
    variables = {
      status: 'INACTIVE',
      willJoin: null,
      activeEmployee: null,
      replacement_status: false,
      isTerminationNotNull: false,
    }
    activeState = 'employee-alumni'
  } else {
    title = 'Pegawai'
    variables = {
      global_user_status_filter: 'ACTIVE',
      status: 'ACTIVE',
      willJoin: null,
      activeEmployee: 'now()',
      isTerminationNotNull: true,
      replacement_status: false,
    }
    activeState = 'employee'
  }

  return {variables, title, activeState}
}
