import React, {useEffect} from 'react'
import MaterialTable from 'material-table'
import Typography from '@material-ui/core/Typography'
import {
  Avatar,
  Divider,
  IconButton,
  Icon,
  CircularProgress,
} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'

import {BlueLinks} from '../../../time/leave/LeavePageStyles'
import {PaperContainer, EnhancedToolbar} from '../../../../GlobalStyles'
import {withRouter, Link} from 'react-router-dom'
import {TableOptionsNoSorting} from '../../../shared-components/table/TableOptions'

import {GET_EMPLOYEE_INDIVIDUAL_BY_VERSION} from '../../../../graphql/queries'

const VersionDetailListComponent = props => {
  const {version} = props

  const {data, loading, error, refetch} = useQuery(
    GET_EMPLOYEE_INDIVIDUAL_BY_VERSION,
    {
      variables: {
        version: version,
        search: `%${props.searchText}%`,
      },
    }
  )

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  if (loading) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <EnhancedToolbar
        style={{justifyContent: 'flex-start', paddingLeft: '5px'}}
      >
        <IconButton
          component={Link}
          to={{
            pathname: '/performance/individual',
            state: {active: 'individual'},
            searchable: true,
          }}
          edge="end"
          aria-label="back"
        >
          <Icon>arrow_back</Icon>
        </IconButton>
        <Typography variant="h6" style={{marginLeft: '10px'}}>
          {data.performance_individual_versions[0].name} Details
        </Typography>
      </EnhancedToolbar>
      <Divider></Divider>
      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0'}}
        columns={[
          {
            title: 'Avatar',
            field: 'people_work_placement.global_user.avatar',
            render: rowData =>
              rowData.people_work_placement.global_user.avatar && (
                <Avatar
                  size="small"
                  alt={rowData.people_work_placement.global_user.name}
                  src={rowData.people_work_placement.global_user.avatar}
                />
              ),
          },
          {
            title: 'Name',
            field: 'people_work_placement.global_user.name',
            render: rowData =>
              `${
                rowData.people_work_placement.global_user
                  ? rowData.people_work_placement.global_user.name
                  : ''
              }`,
          },
          {
            title: 'Employee ID',
            field: 'people_work_placement.regno',
            render: rowData =>
              `${
                rowData.people_work_placement
                  ? rowData.people_work_placement.regno
                  : ''
              }`,
          },
          {
            title: 'Position',
            field: 'people_work_placement.company_job_profile.title',
            render: rowData =>
              `${
                rowData.people_work_placement.company_job_profile
                  ? rowData.people_work_placement.company_job_profile.title
                  : ''
              }`,
          },
          {
            title: 'Action',
            field: 'id',
            sorting: false,
            render: rowData => (
              <BlueLinks
                to={{
                  pathname: `/performance/individual/detail/summary`,
                  state: {
                    active: 'summary',
                    employee: rowData,
                    version: version,
                  },
                }}
              >
                Details
              </BlueLinks>
            ),
          },
        ]}
        data={data && data.performance_individuals}
        title=""
        options={TableOptionsNoSorting}
        components={{
          Toolbar: () => null,
        }}
      />
    </PaperContainer>
  )
}

export default withRouter(VersionDetailListComponent)
