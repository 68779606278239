import React, {useState, useEffect} from 'react'
import {
  Button,
  Divider,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
} from '@material-ui/core'
import {
  Flex,
  PaperContainer,
  EnhancedToolbar,
  FlexBetween,
} from '../../../../GlobalStyles'
import helperStyle, {
  UserWrapper,
  BigAvatar,
  ProfileText,
  DetailContainer,
  IndividualProgress,
  ModelProgress,
  CustomRadarLabel,
  IndividualContainer,
  DoughnutNoData,
} from '../LearningProfileStyle'
import {withRouter} from 'react-router-dom'
import {Radar} from 'react-chartjs-2'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_LAERNING_PROFILE,
  GET_USER_DATA,
  GET_COMPETENCY_LEARNING_PROFILE,
} from '../../../../graphql/queries'
import {LEARNING_PROFILE_REPORT} from '../../../../graphql/mutations'
import {holdingRolesContext} from '../../../../utils/helpers'
import moment from 'moment'
import DoughnutComponent from './DoughnutComponent'
import Loading from '../../../../components/loading/LoadingComponent'
import {useSnackbar} from 'notistack'

const DetailProfileComponent = props => {
  const userid = props.match.params.userid
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [tabsValue, setTabsValue] = useState(0)
  const [learningDuration, setLearningDuration] = useState([])
  const [teactDuration, setTeachDuration] = useState([])
  const [learningMaterial, setLearningMaterial] = useState([])
  const [radarLabels, setRadarLabels] = useState([])
  const [competencyIndividual, setCompetencyIndividual] = useState([])
  const [competencyModel, setCompetencyModel] = useState([])
  const [individualList, setIndividualList] = useState([])
  const [dictionadyList, setDictionaryList] = useState([])

  const userRolesContext = {
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  }

  const {data, error} = useQuery(GET_LAERNING_PROFILE, {
    variables: {
      userId: userid,
    },
    ...holdingRolesContext,
  })

  const {data: userData, loading: loadingUserData} = useQuery(GET_USER_DATA, {
    variables: {
      userId: userid,
    },
    ...userRolesContext,
  })

  let placementId = 0
  if (userData) {
    placementId =
      userData.global_users &&
      userData.global_users[0].people_work_placements.length > 0
        ? userData.global_users[0].people_work_placements[0].id
        : 0
  }
  const {data: dataRadar} = useQuery(GET_COMPETENCY_LEARNING_PROFILE, {
    variables: {
      placement: placementId,
    },
    ...holdingRolesContext,
  })

  const [mutationReport] = useMutation(LEARNING_PROFILE_REPORT)

  const renderRadarLabel = val => {
    const strCompetency = 'kompetensi'
    const newVal = val.toLowerCase()
    if (newVal.indexOf(strCompetency) !== -1) {
      const result = newVal.replace(strCompetency, '')
      return result.charAt(1).toUpperCase() + result.slice(2)
    } else {
      return val
    }
  }

  useEffect(() => {
    if (data) {
      const val = data.learningProfile

      setLearningDuration([
        val.learning_duration.course,
        val.learning_duration.ebook,
        val.learning_duration.audiobook,
        val.learning_duration.classroom,
      ])

      setTeachDuration([
        val.teach_duration.course,
        val.teach_duration.ebook,
        val.teach_duration.audiobook,
        val.teach_duration.classroom,
      ])

      setLearningMaterial([
        val.learning_material.course,
        val.learning_material.ebook,
        val.learning_material.audiobook,
        val.learning_material.classroom,
      ])

      if (dataRadar) {
        const radarData = dataRadar.competency_categories
        let _radarLabels = []
        let _competencyIndividual = []
        let _competencyModel = []
        let _individualList = []
        let _dictionaryList = []

        radarData.length > 0 &&
          radarData.map(val => {
            const _agregate =
              val.competency_dictionaries[0].competency_version_dictionaries[0]

            _radarLabels.push(renderRadarLabel(val.name))

            _competencyIndividual.push(
              _agregate.competency_individuals_aggregate.aggregate.avg.level
            )

            _competencyModel.push(
              _agregate.competency_model_items_aggregate.aggregate.avg.level
            )

            _individualList.push({
              label: renderRadarLabel(val.name),
              model_competencies:
                _agregate.competency_model_items_aggregate.aggregate.avg.level,
              individual_competencies:
                _agregate.competency_individuals_aggregate.aggregate.avg.level,
              date_added: val.date_added,
            })

            _dictionaryList.push(val)
          })

        setRadarLabels(_radarLabels)
        setCompetencyIndividual(_competencyIndividual)
        setCompetencyModel(_competencyModel)
        setIndividualList(_individualList)
        setDictionaryList(_dictionaryList)
      }
    }
  }, [data, dataRadar])

  // const zeroFill = num => {
  //   let converted
  //   if (!Number.isInteger(num) && !_.isNull(num)) {
  //     converted = num
  //       .toFixed(1)
  //       .toString()
  //       .replace('.', '')
  //   } else {
  //     converted = String(num) + '0'
  //   }
  //   return Number(converted)
  // }

  const countGap = (num1, num2) => {
    let gap = Number(num1) - Number(num2)
    if (gap < 0) {
      gap = Number(num2) - Number(num1)
    }
    return gap.toFixed(1)
  }

  const handleDownload = name => {
    let flags = {
      teach_duration: false,
      competency_profile: false,
      learning_duration: false,
      learning_material: false,
    }

    flags = {
      ...flags,
      [name]: true,
    }

    mutationReport({
      variables: {
        userId: userid,
        exportFlags: flags,
      },
      ...holdingRolesContext,
    })
      .then(res => {
        enqueueSnackbar(`Download success, check your email`, {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(err => {
        enqueueSnackbar(`Download report failed`, {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleChangeTabs = (event, newValue) => {
    setTabsValue(newValue)
  }

  const radarData = {
    labels: radarLabels,
    datasets: [
      {
        label: 'Individual Competence',
        data: competencyIndividual,
        backgroundColor: 'rgba(3, 155, 229, 0.2)',
        borderColor: '#039be5',
        borderWidth: 1,
        pointRadius: 1,
      },
      {
        label: 'Model Competence',
        data: competencyModel,
        backgroundColor: 'rgba(135, 76, 175, 0.2)',
        borderColor: '#874caf',
        borderWidth: 1,
        pointRadius: 1,
      },
    ],
  }

  const radarOptions = {
    legend: false,
    gridLines: {
      display: true,
    },
    scale: {
      gridLines: {
        color: '#eff2f4',
      },
      pointLabels: {
        fontSize: 10,
      },
      ticks: {display: false, beginAtZero: true},
      angleLines: {
        color: '#eff2f4',
      },
    },
  }

  const IndividualTab = () => {
    return (
      <div>
        {individualList.length === 0 ? (
          <DoughnutNoData radar style={{padding: '50% 0px'}}>
            <ProfileText fweight="bold">No Data</ProfileText>
          </DoughnutNoData>
        ) : (
          individualList.map((val, key) => {
            return (
              <div key={key}>
                <FlexBetween className={classes.mb15}>
                  <ProfileText fweight="bold">{val.label}</ProfileText>
                  <ProfileText fcolor="grey">
                    Last update {moment(val.date_added).format('LL')}
                  </ProfileText>
                </FlexBetween>
                <FlexBetween className={classes.mb5}>
                  <ProfileText>Model Competencies</ProfileText>
                  <div style={{width: '50%'}}>
                    <ModelProgress
                      variant="determinate"
                      value={val.model_competencies}
                    />
                  </div>
                </FlexBetween>
                <FlexBetween className={classes.mb5}>
                  <ProfileText>Individual Competencies</ProfileText>
                  <div style={{width: '50%'}}>
                    <IndividualProgress
                      variant="determinate"
                      value={val.individual_competencies}
                    />
                  </div>
                </FlexBetween>
                <FlexBetween>
                  <ProfileText>GAP</ProfileText>
                  <div style={{width: '50%'}}>
                    <ProfileText fweight="bold">
                      {countGap(
                        val.individual_competencies,
                        val.model_competencies
                      )}
                    </ProfileText>
                  </div>
                </FlexBetween>
                <Divider style={{margin: '20px 0px'}} />
              </div>
            )
          })
        )}
      </div>
    )
  }

  const DictionaryTab = () => {
    return (
      <div>
        {dictionadyList.length === 0 ? (
          <DoughnutNoData radar style={{padding: '50% 0px'}}>
            <ProfileText fweight="bold">No Data</ProfileText>
          </DoughnutNoData>
        ) : (
          dictionadyList.map((val, key) => {
            return (
              <div key={key}>
                <ProfileText fweight="bold" fcolor="lightBlue" fsize="12px">
                  {val.competency_dictionaries[0].name.en}
                </ProfileText>
                <ProfileText
                  fweight="bold"
                  fsize="14px"
                  style={{paddingTop: '10px'}}
                >
                  {val.name}
                </ProfileText>
                <Divider style={{margin: '20px 0px'}} />
              </div>
            )
          })
        )}
      </div>
    )
  }

  const LoadingComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <DetailContainer>
      <PaperContainer className={classes.mb25}>
        <div style={{padding: '1.6rem'}}>
          {!loadingUserData && userData ? (
            <Flex>
              <BigAvatar
                src={
                  userData &&
                  userData.global_users.length > 0 &&
                  userData.global_users[0].avatar
                }
                className={classes.mr25}
              />
              <UserWrapper>
                <ProfileText fweight="bold" fsize="24px">
                  {userData && userData.global_users.length > 0
                    ? userData.global_users[0].name
                    : ''}
                </ProfileText>
                <ProfileText fcolor="grey">
                  {userData &&
                  userData.global_users.length > 0 &&
                  userData.global_users[0].people_work_placements.length > 0
                    ? userData.global_users[0]?.people_work_placements[0]
                        ?.company_job_profile?.title
                    : 'Unknown Position'}{' '}
                  at{' '}
                  {userData &&
                  userData.global_users.length > 0 &&
                  userData.global_users[0].people_work_placements.length > 0
                    ? userData.global_users[0]?.people_work_placements[0]
                        ?.company_profile?.brand_name
                    : 'Unknown Company'}
                </ProfileText>
                <ProfileText fcolor="grey">
                  {userData &&
                  userData.global_users.length > 0 &&
                  userData.global_users[0].people_work_placements.length > 0
                    ? userData.global_users[0]?.people_work_placements[0]
                        ?.company_profile?.legal_name
                    : ''}
                </ProfileText>
                <ProfileText fcolor="lightBlue">
                  {userData && userData.global_users.length > 0
                    ? userData.global_users[0].email
                    : ''}
                </ProfileText>
              </UserWrapper>
            </Flex>
          ) : (
            <LoadingComponent />
          )}
        </div>
      </PaperContainer>
      <PaperContainer className={classes.mb25}>
        <EnhancedToolbar>
          <ProfileText fweight="bold" fcolor="blue" fsize="14px">
            Competency Profile
          </ProfileText>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleDownload('competency_profile')}
          >
            Download
          </Button>
        </EnhancedToolbar>
        <Divider />
        <Grid container>
          <Grid item xs={12} sm={6}>
            {radarLabels.length === 0 &&
            competencyIndividual.length === 0 &&
            competencyModel.length === 0 ? (
              <DoughnutNoData radar style={{padding: '50% 0px'}}>
                <ProfileText fweight="bold">No Data</ProfileText>
              </DoughnutNoData>
            ) : (
              <div style={{padding: '1.6rem'}}>
                <Radar data={radarData} options={radarOptions} />
                <Flex
                  className={classes.mt50}
                  style={{justifyContent: 'space-around'}}
                >
                  {radarData.datasets.map(val => {
                    return (
                      <Flex>
                        <CustomRadarLabel
                          bgcolor={val.backgroundColor}
                          className={classes.mr25}
                        />
                        <ProfileText fweight="bold" fsize="11px">
                          {val.label}
                        </ProfileText>
                      </Flex>
                    )
                  })}
                </Flex>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.rightCompetency}>
            <Tabs
              value={tabsValue}
              onChange={handleChangeTabs}
              indicatorColor="secondary"
              textColor="primary"
              classes={{root: classes.tabsRoot}}
            >
              <Tab label="Individual" />
              <Tab label="Dictionary" />
            </Tabs>
            <IndividualContainer>
              {tabsValue === 0 ? <IndividualTab /> : <DictionaryTab />}
            </IndividualContainer>
          </Grid>
        </Grid>
      </PaperContainer>
      <Grid container spacing={2}>
        <DoughnutComponent
          title="Learning Duration"
          handleDownload={handleDownload}
          data={learningDuration}
        />
        <DoughnutComponent
          title="Teach Duration"
          handleDownload={handleDownload}
          data={teactDuration}
        />
        <DoughnutComponent
          title="Learning Material"
          handleDownload={handleDownload}
          data={learningMaterial}
        />
      </Grid>
    </DetailContainer>
  )
}

export default withRouter(DetailProfileComponent)
