import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import MaterialTable from 'material-table'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import {
  RootContainer,
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {getDataVariableIndividualSum} from '../IndividualSummaryFilterData'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {TableOptions} from '../../../shared-components/table/TableOptions'
import {useSnackbar} from 'notistack'
import {Link} from 'react-router-dom'
import {COMPANY_ID} from '../../../../utils/globals'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import Loading from '../../../../components/loading/LoadingComponent'
import {trimString, disbaledButtonExport} from '../../../../utils/helpers'
import AlertDialog from '../../../shared-components/popup/ResultPopup'

import {
  GET_LIST_INDIVIDUAL_SUMMARY,
  GET_EXPORT_DISABLED_BUTTON,
  GET_STATUS_EXPORT_BALOON,
} from '../../../../graphql/queries'
import {
  CLOSE_BALOON_EXPORT,
  EXPORT_MUTATION,
} from '../../../../graphql/mutations'

const ListComponent = ({searchText, filterData}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [openExport, setExport] = useState(false)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const variables = getDataVariableIndividualSum(filterData)
  const {data, error, loading} = useQuery(GET_LIST_INDIVIDUAL_SUMMARY, {
    variables: {
      ...variables,
      search: `%${searchText}%`,
      filterStatus: {_neq: 'REGISTERED'},
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      company: COMPANY_ID,
    },
  })

  const {data: getExportBaloon, error: errorGetExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'performance_individual_summary',
      },
    }
  )

  const {data: getExportDisabled, error: errorGetExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'performance_individual_summary',
      },
    }
  )

  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )

  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (
    error !== undefined ||
    mutationExportError !== undefined ||
    closeBaloonExportError !== undefined ||
    errorGetExportBaloon !== undefined ||
    errorGetExportDisabled !== undefined
  ) {
    console.error({error})
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleDownload = () => {
    setExport(!openExport)
    if (openExport) {
      mutationExport({
        variables: {
          table: 'performance_individual_summary',
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const column = [
    {
      title: 'NIP',
      field: 'people_work_placements[0].regno',
      defaultSort: 'asc',
      headerStyle: {minWidth: 200, maxWidth: 200},
      cellStyle: {minWidth: 200, maxWidth: 200},
      render: rowData => (
        <div style={{wordBreak: 'break-word'}}>
          {`${trimString(rowData.people_work_placements[0].regno, 20)} ${
            rowData?.people_work_placements?.[0]?.regno?.length > 20
              ? '...'
              : ''
          }`}
        </div>
      ),
    },
    {
      title: 'Nama Pegawai',
      field: 'name',
      headerStyle: {minWidth: 200, maxWidth: 200},
      cellStyle: {minWidth: 200, maxWidth: 200},
      render: rowData => (
        <div style={{wordBreak: 'break-word'}}>
          {`${trimString(rowData?.name, 20)} ${
            rowData?.name?.length > 20 ? '...' : ''
          }`}
        </div>
      ),
    },
    {
      title: 'Jabatan',
      field: 'people_work_placements[0].company_job_profile.title',
      headerStyle: {minWidth: 250, maxWidth: 250},
      cellStyle: {minWidth: 250, maxWidth: 250},
      render: rowData => {
        return (
          <>
            <Typography variant="body2">
              {`${trimString(
                rowData?.people_work_placements[0].company_job_profile?.title,
                25
              )} ${
                rowData?.people_work_placements[0]?.company_job_profile?.title
                  ?.length > 25
                  ? '...'
                  : ''
              }`}
            </Typography>
            <Typography variant="body2" style={{color: '#a9a8a8'}}>
              {`${trimString(
                rowData?.people_work_placements[0].company_job_profile?.code ??
                  '',
                25
              )}${
                rowData?.people_work_placements[0]?.company_job_profile?.code
                  ?.length > 25
                  ? '...'
                  : ''
              }`}
            </Typography>
          </>
        )
      },
    },
    {
      title: 'Unit Kerja',
      field:
        'people_work_placements[0].company_job_profile.company_organization.name',
      headerStyle: {minWidth: 250, maxWidth: 250},
      cellStyle: {minWidth: 250, maxWidth: 250},
      render: rowData => {
        return (
          <>
            <Typography variant="body2">
              {`${trimString(
                rowData?.people_work_placements[0].company_job_profile
                  ?.company_organization?.name,
                25
              )} ${
                rowData?.people_work_placements[0]?.company_job_profile
                  ?.company_organization?.name?.length > 25
                  ? '...'
                  : ''
              }`}
            </Typography>
            <Typography variant="body2" style={{color: '#a9a8a8'}}>
              {`${trimString(
                rowData?.people_work_placements[0].company_job_profile
                  ?.company_organization?.code ?? '',
                25
              )}${
                rowData?.people_work_placements[0]?.company_job_profile
                  ?.company_organization?.code?.length > 25
                  ? '...'
                  : ''
              }`}
            </Typography>
          </>
        )
      },
    },
    {
      title: 'Aksi',
      field: 'action',
      sorting: false,
      render: rowData => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: `/performance/individual-summary/detail/${rowData?.people_work_placements?.[0]?.id}/${rowData?.id}`,
                    state: {
                      active: 'individual-summary-detail',
                      idPlacement: rowData?.people_work_placements?.[0]?.id,
                    },
                  }}
                  variant="h6"
                >
                  Rincian
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>
      ),
    },
  ]

  return (
    <RootContainer>
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
      />
      <PaperContainer>
        <EnhancedToolbar>
          <Typography variant="h6">Ringkasan Kinerja Individu</Typography>
          <div>
            <GenerateTooltip
              title={
                (getExportBaloon &&
                  getExportBaloon.company_export_data_logs &&
                  getExportBaloon.company_export_data_logs.length > 0 &&
                  getExportBaloon.company_export_data_logs[0].status ===
                    'FAILED') ||
                disbaledButtonExport(
                  getExportDisabled &&
                    getExportDisabled.company_export_data_logs &&
                    getExportDisabled.company_export_data_logs[0] &&
                    getExportDisabled.company_export_data_logs[0].date_added
                )
                  ? `you can only generate data once a day. to download data use the link that already generated`
                  : ``
              }
              placement="left"
            >
              <span>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={
                    (getExportBaloon &&
                      getExportBaloon.company_export_data_logs &&
                      getExportBaloon.company_export_data_logs.length > 0 &&
                      getExportBaloon.company_export_data_logs[0].status ===
                        'FAILED') ||
                    disbaledButtonExport(
                      getExportDisabled &&
                        getExportDisabled.company_export_data_logs &&
                        getExportDisabled.company_export_data_logs[0] &&
                        getExportDisabled.company_export_data_logs[0].date_added
                    )
                  }
                  onClick={handleDownload}
                >
                  Buat Laporan
                </Button>
              </span>
            </GenerateTooltip>
          </div>
        </EnhancedToolbar>
        <Divider></Divider>
        <MaterialTable
          options={{
            ...TableOptions,
            selection: false,
            showTitle: false,
            toolbar: false,
            emptyRowsWhenPaging: false,
          }}
          isLoading={loading}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <NoDataListComponent search={searchText} />
              ),
            },
          }}
          columns={column}
          data={data.global_users}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={data.total.aggregate.count}
          page={pagination.offset}
          pageSize={pagination.limit}
        />
      </PaperContainer>
      <AlertDialog
        open={openExport}
        handleClose={handleDownload}
        feature="Pembuatan data sedang dalam proses!"
        message="Pembuatan data sedang dalam proses! Kami akan mengirimkan email setelah selesai"
        type="Confirm"
        positiveTitleButton="Konfirmasi"
      />
    </RootContainer>
  )
}

export default ListComponent
