import React from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Toolbar,
  Divider,
  Typography,
  IconButton,
  Icon,
} from '@material-ui/core'

import {PaperContainer} from '../../../../../GlobalStyles'
import NoDataListFree from '../../../../shared-components/NoDataListFreeComponent'
import CompanyTransactionList from '../CompanyTransactionList'

import {getCompanyTransactionFilterVariables} from '../../filters'
import {COMPANY_ID} from '../../../../../utils/globals'
import {GET_COMPANY_TRANSACTION_HISTORY} from '../../../../../graphql/queries/budget/limit/getCompanyBalance.query'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
}))

function CompanyHistory(props) {
  const styles = useStyles()

  return (
    <PaperContainer>
      <Toolbar>
        <IconButton
          aria-label="Go back to previous page"
          onClick={props.history.goBack}
          edge="start"
          className={styles.backButton}
        >
          <Icon>arrow_back</Icon>
        </IconButton>

        <Typography variant="subtitle1" style={{fontWeight: '600'}}>
          Company Claim Limit Activity
        </Typography>
      </Toolbar>

      <Divider />

      <CompanyTransactionHistory {...props} />
    </PaperContainer>
  )
}

export default withRouter(CompanyHistory)

function CompanyTransactionHistory(props) {
  const {searchText, filterData} = props

  const [pagination, setPagination] = React.useState({
    offset: 0,
    limit: 20,
  })

  const where = React.useMemo(() => {
    return getCompanyTransactionFilterVariables({searchText, filterData})
  }, [searchText])

  const {data, loading, error} = useQuery(GET_COMPANY_TRANSACTION_HISTORY, {
    wlb_skipPatch: true,
    variables: {
      companyID: COMPANY_ID,
      where: where,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const handleChangeOffset = offset => {
    setPagination({...pagination, offset})
  }

  const handleChangeLimit = limit => {
    setPagination({...pagination, offset: 0, limit})
  }

  React.useLayoutEffect(() => {
    handleChangeOffset(0)
  }, [filterData])

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  if (!loading && !data?.total.aggregate.count) {
    return (
      <NoDataListFree
        message1="Sorry, No List"
        message2="You don't have any transactions yet"
      />
    )
  }

  return (
    <CompanyTransactionList
      data={data?.transactions || []}
      totalCount={data?.total.aggregate.count || 0}
      loading={loading}
      offset={pagination.offset}
      limit={pagination.limit}
      onChangeOffset={handleChangeOffset}
      onChangeLimit={handleChangeLimit}
    />
  )
}
