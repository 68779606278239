import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: auto;
  width: 75%;
  padding: 10px;
  display: flex;
  flex-direction: row;
`

export const LeftWrapper = styled.div`
  img {
    font-family: muli;
  }
`

export const RightWrapper = styled.div`
  margin-left: 50px;
  h2 {
    color: #1b1d1f;
  }
  p {
    color: #a9a8a8;
  }
`

export const CenterWrapper = styled.div`
  min-height: 83vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  margin-right: -15px;
`
