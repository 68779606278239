import gql from 'graphql-tag'

export const GET_LIST_ALLOWANCE_POLICY = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: finance_allowance_policy_children_aggregate {
      aggregate {
        count
      }
    }

    finance_allowance_policy_children(
      where: {name: {_ilike: $search}}
      offset: $offset
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`
