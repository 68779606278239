import gql from 'graphql-tag'

export const UPDATE_LEAVE_POLICY_PARENT = gql`
  mutation UpdateLeavePolicyParent(
    $id: Int
    $is_unpaid: Boolean
    $calc_method: String
    $label_color: String
    $leftover_valid: Boolean
    $leftover_valid_until_month: Int
    $name: String
    $quota_effective_after_month: Int
    $quota_prorate_option: Boolean
    $quota_reset_option: Int
    $quota_reset_option_date: Int
    $quota_reset_option_month: Int
    $text_color: String
    $leaveDebt: Boolean
    $leftover_type: Int
    $leftover_valid_max: Int
    $leftover_money_settings: jsonb
  ) {
    update_time_leave_policies(
      _set: {
        is_unpaid: $is_unpaid
        calc_method: $calc_method
        label_color: $label_color
        leftover_valid: $leftover_valid
        leftover_valid_until_month: $leftover_valid_until_month
        name: $name
        quota_effective_after_month: $quota_effective_after_month
        quota_prorate_option: $quota_prorate_option
        quota_reset_option: $quota_reset_option
        quota_reset_option_date: $quota_reset_option_date
        quota_reset_option_month: $quota_reset_option_month
        text_color: $text_color
        allow_leave_debt: $leaveDebt
        leftover_type: $leftover_type
        leftover_valid_max: $leftover_valid_max
        leftover_money_settings: $leftover_money_settings
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
