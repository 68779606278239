import React, {useState} from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import {WhiteModalHeader} from '../../../GlobalStyles'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},

  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnDel: {
    backgroundColor: '#ef4d5e',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ef4d5e',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {wordBreak: 'break-all'},
}))

export default function DeletePopupMini(props) {
  const classes = useStyles()
  const {
    open,
    handleClose,
    name,
    mutation,
    feature,
    title,
    setDeleteType,
    deleteType,
    description,
  } = props
  const [checked, setChecked] = useState(false)
  const handleChange = e => setChecked(e.target.checked)
  return (
    <Dialog
      onClose={() => {
        handleClose()
        setChecked(false)
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <WhiteModalHeader>
        <Typography
          color="error"
          style={{fontSize: '14px', fontWeight: 600}}
          className={classes.wordBreak}
        >
          Delete {title || name}?
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose()
            setChecked(false)
          }}
        >
          <Icon>close</Icon>
        </IconButton>
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        {feature === 'calendar' ? (
          <>
            <Typography
              style={{fontSize: '12px'}}
              variant="body2"
              display="inline"
            >
              By selecting the deletion options below, you agree to permanently
              delete
            </Typography>
            <Typography
              variant="body2"
              color="error"
              display="inline"
              className={classes.wordBreak}
              style={{fontSize: '12px'}}
            >
              {` ${name}`}
            </Typography>
            <div className={classes.checkContainer}>
              <RadioGroup
                aria-label="type"
                name="type"
                onChange={event => setDeleteType(event.target.value)}
              >
                <FormControlLabel
                  value="this"
                  control={<Radio />}
                  label="This holiday"
                />
                {/* <FormControlLabel
                  value="following"
                  control={<Radio />}
                  label="This and following holidays"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="All holidays"
                /> */}
              </RadioGroup>
            </div>
          </>
        ) : (
          <>
            {' '}
            <Typography
              style={{fontSize: '12px'}}
              variant="body2"
              display="inline"
            >
              Are you sure to delete
            </Typography>
            <Typography
              style={{fontSize: '12px'}}
              color="error"
              display="inline"
              className={classes.wordBreak}
            >
              {' '}
              {name}{' '}
            </Typography>
            <Typography
              style={{fontSize: '12px'}}
              variant="body2"
              display="inline"
            >
              permanently?
            </Typography>
            <div className={classes.checkContainer}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                color="primary"
                className={classes.checkbox}
              />
              <Typography style={{fontSize: '12px'}}>
                {`I understand that deleting this file is permanent and cannot be
                undone. ${
                  description === 'library'
                    ? 'Delete this goal library could also delete goal list in employee’s side.'
                    : ''
                }`}
              </Typography>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          style={{fontSize: '12px'}}
          onClick={() => {
            handleClose()
            setChecked(false)
          }}
        >
          Cancel
        </Button>
        <Button
          style={{fontSize: '12px'}}
          className={classes.btnDel}
          disabled={feature ? !deleteType : !checked}
          variant="contained"
          onClick={() => {
            setChecked(false)
            handleClose()
            mutation()
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
