import {
  Button,
  Checkbox,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import {selectProps} from '../../../../../utils/helpers'
import helperStyle, {Flex} from '../../../../../GlobalStyles'
import Axios from 'axios'
import {REAST_API_URL, TOKEN} from '../../../../../utils/globals'

const editorConfiguration = {
  placeholder: 'Tambah Konten',
  toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
}

export default function CreateQuizQuestion({
  style,
  setOpenAdd,
  lesson,
  selected,
  setSelected,
  getCourseById,
  getListQuestion,
  isEdit,
  setIsEdit,
  listQns,
  index,
}) {
  const classes = helperStyle()

  const initialStateAnswer = !isEdit
    ? [
        {
          answer: '',
          is_correct: true,
        },
        {
          answer: '',
          is_correct: false,
        },
        {
          answer: '',
          is_correct: false,
        },
        {
          answer: '',
          is_correct: false,
        },
      ]
    : listQns[index].choices.map(choice => {
        return {
          answer: choice,
          is_correct: listQns[index].answerKeys.find(
            answer => answer === choice
          ),
        }
      })

  const [question, setQuestion] = useState(
    !isEdit ? (selected ? selected.question : '') : listQns[index].fulltext
  )
  const [questionType, setType] = useState(
    !isEdit
      ? ''
      : listQns[index].fieldtype === 'checkbox'
      ? 'mAnswer'
      : 'mChoice'
  )
  const [stateAnswer, setAnswer] = useState(initialStateAnswer)

  useEffect(() => {
    // logic untuk fill data edit
    if (selected) {
      // setQuestion(selected.question)
      setType(selected.question_type === 0 ? 'mChoice' : 'mAnswer')
      setAnswer(selected.microlearning_question_answers)
    }
  }, [selected])

  const handleType = e => {
    setType(e.target.value)
    setAnswer(initialStateAnswer)
  }

  const handleMoreAnswer = () => {
    setAnswer([
      ...stateAnswer,
      {
        answer: '',
        is_correct: false,
      },
    ])
  }

  const handleChangeAnswer = (e, i) => {
    stateAnswer[i].answer = e.target.value
    setAnswer([...stateAnswer])
  }

  const handleRadio = newCorrectIndex => {
    const prevCorrectIndex = stateAnswer.findIndex(i => i.is_correct)
    if (prevCorrectIndex !== -1) {
      stateAnswer[prevCorrectIndex].is_correct = false
    }
    stateAnswer[newCorrectIndex].is_correct = true
    setAnswer([...stateAnswer])
  }

  const onCancel = () => {
    setOpenAdd(false)
    setAnswer(initialStateAnswer)
    setSelected(null)
  }

  const handleCheckbox = (e, i) => {
    stateAnswer[i].is_correct = e.target.checked
    setAnswer([...stateAnswer])
  }

  const onSave = async () => {
    try {
      const _answers = []
      const _correctAns = stateAnswer.filter(item => item.is_correct)
      _correctAns.map(item => _answers.push(item.answer))

      const payload = {
        choices: stateAnswer.map(item => item.answer),
        answerKeys: _answers,
        evalMethod: 'auto',
        fieldtype: questionType === 'mChoice' ? 'multiple' : 'checkbox',
        fulltext: question,
        lesson: lesson.id,
        order: lesson.Questions.length + 1,

        passingGrade: 100,
      }

      await Axios.post(`${REAST_API_URL}/academy/question/create`, payload, {
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + TOKEN,
        },
      })

      onCancel()
      getListQuestion()
      return getCourseById()
    } catch (error) {
      console.error(error)
    }
  }

  const onEdit = async () => {
    try {
      const _answers = []
      const _correctAns = stateAnswer.filter(item => item.is_correct)
      _correctAns.map(item => _answers.push(item.answer))

      const payload = {
        choices: stateAnswer.map(item => item.answer),
        answerKeys: _answers,
        evalMethod: 'auto',
        fieldtype: questionType === 'mChoice' ? 'multiple' : 'checkbox',
        fulltext: question,
        lesson: lesson.id,
        order: lesson.Questions.length + 1,

        passingGrade: 100,
      }

      await Axios.patch(
        `${REAST_API_URL}/academy/question/${listQns[index].id}/update`,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      setIsEdit(false)
      getListQuestion()

      return getCourseById()
    } catch (error) {
      console.error(error)
    }
  }

  const renderAnswer = () => (
    <div className={classes.marginBot8}>
      <Typography variant="body2" className={classes.marginTop16} paragraph>
        Jawaban
      </Typography>

      {questionType === 'mChoice' ? (
        stateAnswer.map((ans, i) => (
          <div style={style.answerContainer} key={i}>
            <Radio
              color="primary"
              checked={ans.is_correct}
              onChange={() => handleRadio(i)}
            />
            <TextField
              fullWidth
              InputProps={{disableUnderline: true}}
              placeholder="Tambah Jawaban"
              value={ans.answer}
              onChange={e => handleChangeAnswer(e, i)}
            ></TextField>
          </div>
        ))
      ) : questionType === 'mAnswer' ? (
        stateAnswer.map((ans, i) => (
          <div color="primary" style={style.answerContainer} key={i}>
            <Checkbox
              color="primary"
              checked={ans.is_correct}
              onChange={e => handleCheckbox(e, i)}
            />
            <TextField
              fullWidth
              InputProps={{disableUnderline: true}}
              placeholder="Tambah Jawaban"
              value={ans.answer}
              onChange={e => handleChangeAnswer(e, i)}
            ></TextField>
          </div>
        ))
      ) : questionType === 'sAnswer' ? (
        <TextField
          variant="outlined"
          multiline
          rows="4"
          fullWidth
          value="this is the answer"
          className="bg-white mb-16"
        />
      ) : null}
      {stateAnswer.length < 5 && (
        <Typography
          variant="body2"
          style={{color: '#039BE5', cursor: 'pointer'}}
          onClick={handleMoreAnswer}
        >
          + Tambah Jawaban lain
        </Typography>
      )}
    </div>
  )

  const disabledButton =
    !questionType ||
    !question ||
    stateAnswer.some(s => !s.answer) ||
    stateAnswer.every(s => !s.is_correct)

  if (isEdit) {
    return (
      <div>
        {/*       <CustomTypography fweight="bold" bottom="20px" size="16px">
        {selected ? 'Edit' : 'Add'} Question
      </CustomTypography> */}
        <div className={classes.marginTopBot30}>
          <Typography variant="body2">Tipe Pertanyaan</Typography>

          <TextField
            variant="outlined"
            value={questionType}
            onChange={handleType}
            fullWidth
            margin="normal"
            select
            style={{background: 'white'}}
            SelectProps={selectProps(questionType)}
          >
            <MenuItem value="" disabled>
              Pilih Tipe
            </MenuItem>
            <MenuItem value="mChoice">Pilihan Ganda</MenuItem>
            <MenuItem value="mAnswer">Jawaban Ganda</MenuItem>
            {/* <MenuItem value="sAnswer">Short Answer</MenuItem> */}
          </TextField>
        </div>
        {questionType && (
          <div className={classes.marginBot20}>
            <Typography variant="body2" className={classes.marginBot16}>
              Pertanyaan
            </Typography>
            <CKEditor
              editor={DecoupledEditor}
              config={editorConfiguration}
              onInit={editor => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  )
              }}
              data={question}
              // eslint-disable-next-line
              onChange={(event, editor) => {
                const data = editor.getData()
                setQuestion(data)
              }}
            />
            <div style={style.customEditorFooter} />
          </div>
        )}

        {questionType && renderAnswer()}
        <Flex
          style={{justifyContent: 'flex-end'}}
          className={classes.marginBot28}
        >
          <Button
            size="large"
            className={classes.marginRight10}
            // onClick={onCancel}
            onClick={() => setIsEdit(false)}
          >
            Batal
          </Button>
          <Button
            size="large"
            variant="contained"
            className={
              disabledButton ? classes.buttonDisabled : classes.buttonEnabled
            }
            onClick={onEdit}
            disabled={disabledButton}
          >
            Ubah
          </Button>
        </Flex>
      </div>
    )
  }
  return (
    <div>
      {/*       <CustomTypography fweight="bold" bottom="20px" size="16px">
        {selected ? 'Edit' : 'Add'} Question
      </CustomTypography> */}
      <div className={classes.marginTopBot30}>
        <Typography variant="body2">Tipe Pertanyaan</Typography>

        <TextField
          variant="outlined"
          value={questionType}
          onChange={handleType}
          fullWidth
          margin="normal"
          select
          style={{background: 'white'}}
          SelectProps={selectProps(questionType)}
        >
          <MenuItem value="" disabled>
            Pilih Tipe
          </MenuItem>
          <MenuItem value="mChoice">Pilihan Ganda</MenuItem>
          <MenuItem value="mAnswer">Jawaban Ganda</MenuItem>
          {/* <MenuItem value="sAnswer">Short Answer</MenuItem> */}
        </TextField>
      </div>
      {questionType && (
        <div className={classes.marginBot20}>
          <Typography variant="body2" className={classes.marginBot16}>
            Pertanyaan
          </Typography>
          <CKEditor
            editor={DecoupledEditor}
            config={editorConfiguration}
            onInit={editor => {
              editor.ui
                .getEditableElement()
                .parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.getEditableElement()
                )
            }}
            data={question}
            // eslint-disable-next-line
            onChange={(event, editor) => {
              const data = editor.getData()
              setQuestion(data)
            }}
          />
          <div style={style.customEditorFooter} />
        </div>
      )}

      {questionType && renderAnswer()}
      <Flex
        style={{justifyContent: 'flex-end'}}
        className={classes.marginBot28}
      >
        <Button
          size="large"
          className={classes.marginRight10}
          onClick={onCancel}
        >
          Batal
        </Button>
        <Button
          size="large"
          variant="contained"
          className={
            disabledButton ? classes.buttonDisabled : classes.buttonEnabled
          }
          onClick={onSave}
          disabled={disabledButton}
        >
          {selected ? 'Ubah' : 'Tambah'}
        </Button>
      </Flex>
    </div>
  )
}
