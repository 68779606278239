import styled from 'styled-components'

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${props => props.padding || ''};
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${props => props.padding || ''};
`
export const TitleList = styled.div`
  padding: 20px;
  font-weight: 600;
`
export const BoxListName = styled.div`
  width: 101px;
  font-weight: 600;
`
export const BoxListContent = styled.div`
  width: 80%;
  padding-left: 20px;
`
