import gql from 'graphql-tag'

export const UPDATE_THR_SETTINGS = gql`
  mutation(
    $company: uuid!
    $min_work_months: Int #0-12
    $roundup: Boolean
    $roundup_days: Int #0-28
    $paid_tax_by: Int
    $prorate_method: Int #1. In A Year, 2. Last Salary
    $thr_component: Int #FK from components
    $schedules: jsonb #see sample variable
    $thr_multiplier: float8
    $thr_multiplier_source: Int #1. Base Salary, #2. Take Home Pay
  ) {
    insert_finance_thr_settings_one(
      object: {
        id: $company
        min_work_months: $min_work_months
        roundup: $roundup
        roundup_days: $roundup_days
        paid_tax_by: $paid_tax_by
        prorate_method: $prorate_method
        thr_component: $thr_component
        thr_multiplier: $thr_multiplier
        thr_multiplier_source: $thr_multiplier_source
        schedules: $schedules
      }
      on_conflict: {
        constraint: finance_thr_settings_pkey
        update_columns: [
          min_work_months
          roundup
          roundup_days
          prorate_method
          paid_tax_by
          thr_component
          schedules
          thr_multiplier
          thr_multiplier_source
        ]
      }
    ) {
      id
      min_work_months
      roundup
      roundup_days
      prorate_method
      paid_tax_by
      thr_component
      schedules
    }
  }
`
export const UPDATE_PROCESS_STEP_THR = gql`
  mutation(
    $schedule_id: Int
    $company: uuid!
    $year: Int
    $month: Int
    $process_step: Int
  ) {
    update_people_work_thr(
      where: {
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {company: {_eq: $company}}
        year: {_eq: $year}
        month: {_eq: $month}
      }
      _set: {process_step: $process_step}
    ) {
      affected_rows
    }
  }
`

export const REJECT_APPROVAL_THR_PROCESS = gql`
  mutation(
    $schedule_id: Int
    $company: uuid!
    $year: Int
    $month: Int
    $process_step: Int
    $approve_step: Int
  ) {
    reject: update_people_work_thr(
      where: {
        id_schedule: {_eq: $schedule_id}
        approve_step: {_eq: $approve_step}
        year: {_eq: $year}
        month: {_eq: $month}
        people_work_placement: {company: {_eq: $company}}
      }
      _set: {approve_step: 0}
    ) {
      affected_rows
    }

    update_step_process: update_people_work_thr(
      where: {
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {company: {_eq: $company}}
        year: {_eq: $year}
        month: {_eq: $month}
      }
      _set: {process_step: $process_step}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_APPROVAL_THR = gql`
  mutation(
    $login: uuid!
    $schedule_id: Int
    $company: uuid
    $year: Int
    $month: Int
    $process_step: Int
  ) {
    step_1: update_people_work_thr(
      where: {
        approve_step: {_eq: 0}
        year: {_eq: $year}
        month: {_eq: $month}
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              first_job_profile: {people_work_placements: {user: {_eq: $login}}}
            }
          }
        }
      }
      _set: {approve_step: 1}
    ) {
      affected_rows
    }

    step_2: update_people_work_thr(
      where: {
        year: {_eq: $year}
        month: {_eq: $month}
        approve_step: {_eq: 1}
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              second_job_profile: {
                people_work_placements: {user: {_eq: $login}}
              }
            }
          }
        }
      }
      _set: {approve_step: 2}
    ) {
      affected_rows
    }

    step_3: update_people_work_thr(
      where: {
        approve_step: {_eq: 2}
        year: {_eq: $year}
        month: {_eq: $month}
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              third_job_profile: {people_work_placements: {user: {_eq: $login}}}
            }
          }
        }
      }
      _set: {approve_step: 3}
    ) {
      affected_rows
    }
  }
`
export const UPDATE_APPROVAL_THR_LAST_APPROVER = gql`
  mutation(
    $login: uuid!
    $schedule_id: Int
    $company: uuid
    $year: Int
    $month: Int
    $process_step: Int
  ) {
    step_1: update_people_work_thr(
      where: {
        approve_step: {_eq: 0}
        year: {_eq: $year}
        month: {_eq: $month}
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              first_job_profile: {people_work_placements: {user: {_eq: $login}}}
            }
          }
        }
      }
      _set: {approve_step: 1}
    ) {
      affected_rows
    }

    step_2: update_people_work_thr(
      where: {
        year: {_eq: $year}
        month: {_eq: $month}
        approve_step: {_eq: 1}
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              second_job_profile: {
                people_work_placements: {user: {_eq: $login}}
              }
            }
          }
        }
      }
      _set: {approve_step: 2}
    ) {
      affected_rows
    }

    step_3: update_people_work_thr(
      where: {
        approve_step: {_eq: 2}
        year: {_eq: $year}
        month: {_eq: $month}
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              third_job_profile: {people_work_placements: {user: {_eq: $login}}}
            }
          }
        }
      }
      _set: {approve_step: 3}
    ) {
      affected_rows
    }

    update_step_process: update_people_work_thr(
      where: {
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {company: {_eq: $company}}
        year: {_eq: $year}
        month: {_eq: $month}
      }
      _set: {process_step: $process_step}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_THR_DETAIL = gql`
  mutation(
    $id: bigint!
    $addition: jsonb
    $deduction: jsonb
    $final_thr: float8
    $addition_total: float8
    $deduction_total: float8
  ) {
    update_people_work_thr_by_pk(
      pk_columns: {id: $id}
      _set: {
        allowances: $addition
        non_fixed_components: $deduction
        final_thr: $final_thr
        income: $addition_total
        deduction: $deduction_total
      }
    ) {
      id
    }
  }
`

export const ADD_THR_EXCLUSION_EMPLOYEE = gql`
  mutation AddThrExclusionEmployee(
    $assignments: [finance_thr_assignments_insert_input!]!
  ) {
    insert_finance_thr_assignments(objects: $assignments) {
      affected_rows
    }
  }
`

export const DELETE_THR_EXCLUSION_EMPLOYEE = gql`
  mutation DeleteThrExclusionEmployee($id: Int!) {
    update_finance_thr_assignments(
      _set: {deleted_at: "now()"}
      where: {_and: [{id: {_eq: $id}}, {deleted_at: {_is_null: true}}]}
    ) {
      affected_rows
    }
  }
`
