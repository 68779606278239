import gql from 'graphql-tag'

export const GET_LIST_EMPLOYEE = gql`
  query getEmployeeStep1(
    $search: String
    $limit: Int
    $offset: Int
    $organization: Int
    $position: Int
    $rank: Int
    $office: Int
    $start_date: date
    $end_date: date
  ) {
    total: people_work_placements_aggregate(
      where: {
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
        company_organization: {id: {_eq: $organization}}
        company_employee_position: {id: {_eq: $position}}
        company_employee_rank: {id: {_eq: $rank}}
        start_date: {_eq: $start_date}
        end_date: {_eq: $end_date}
        company_address: {id: {_eq: $office}}
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
        company_organization: {id: {_eq: $organization}}
        company_employee_position: {id: {_eq: $position}}
        rank: {_eq: $rank}
        start_date: {_eq: $start_date}
        end_date: {_eq: $end_date}
        company_address: {id: {_eq: $office}}
      }
      offset: $offset
      limit: $limit
      order_by: [{performance_individual_aggregate: {max: {id: asc}}}]
    ) {
      id
      regno
      start_date
      end_date
      global_user {
        id
        name
        avatar
      }
      company_organization {
        id
        name
      }
      company_employee_rank {
        id
        name
      }
      company_employee_position {
        id
        name
      }
      company_job_profile {
        id
        title
      }
      performance_individual(limit: 1, order_by: {date_added: desc}) {
        date_added
      }
      company_address {
        id
        office_name
        global_province {
          id
          name
        }
        global_city {
          id
          name
        }
      }
    }
  }
`
export const GET_LIST_COMMITEE_CANDIDATE = gql`
  query getListCommiteeCandidateStep4($search: String, $limit: Int) {
    total: people_work_placements_aggregate(
      where: {
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
      }
      limit: $limit
      order_by: [{performance_individual_aggregate: {max: {id: asc}}}]
    ) {
      id
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_EMPLOYEE_JOB_PROFILE = gql`
  query($company: uuid, $jobProfile: [Int!], $offset: Int, $limit: Int) {
    total: people_work_placements_aggregate(
      where: {
        company: {_eq: $company}
        job_profile: {_in: $jobProfile}
        status: {_eq: "ACTIVE"}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        company: {_eq: $company}
        job_profile: {_in: $jobProfile}
        status: {_eq: "ACTIVE"}
      }
      offset: $offset
      limit: $limit
    ) {
      regno
      global_user {
        id
        name
        avatar
      }
      start_date
      end_date
      company_job_profile {
        id
        title
      }
    }
  }
`
export const IS_EXIST_EMAIL = gql`
  query($email: String) {
    people_emails(where: {email: {_eq: $email}}) {
      email
    }
  }
`
export const IS_EXIST_PHONE = gql`
  query($code: String, $number: String) {
    people_phones_aggregate(
      where: {country_code: {_eq: $code}, phone: {_eq: $number}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const IS_EXIST_REGNO = gql`
  query($where: people_work_placements_bool_exp) {
    people_work_placements_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const GET_LIST_EMPLOYEE_COMPANY = gql`
  query getEmployee($company: uuid, $search: String) {
    people_work_placements(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        deletedAt: {_is_null: true}
        _or: [
          {email: {_ilike: $search}}
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
      order_by: {global_user: {name: asc}}
    ) {
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        title
      }
      company_profile {
        legal_name
        brand_name
      }
    }
  }
`

export const GET_DROPDOWN_COMMITEE = gql`
  query($search: String, $company: uuid, $offset: Int, $limit: Int) {
    people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company: {_eq: $company}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`
