import React from 'react'
import {useMutation} from '@apollo/react-hooks'

import {
  Dialog,
  Icon,
  IconButton,
  Button,
  FormHelperText,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'

import {TextBlackBold} from '../../../../GlobalStyles'
import {WrapperModalGeneral} from '../../../financial/payroll/PayrollPageStyles'
import {CurrencyInput, useNumericInput} from '../ClaimLimitPageStyles'

import {truncateFractional} from '../../../../utils/helpers'
import {EDIT_EMPLOYEE_CLAIM_LIMIT} from '../../../../graphql/mutations/budget/limit/employeeBalance.mutation'

function EmployeeClaimEditDialog(props) {
  const {open, userWalletId, availableLimit = 0, onClose} = props

  const {enqueueSnackbar} = useSnackbar()

  const [nominal, maskedNominal, handleNominalInput] = useNumericInput(0)
  const [dispatchEdit, {loading: dispatching}] = useMutation(
    EDIT_EMPLOYEE_CLAIM_LIMIT,
    {
      variables: {
        userWalletID: userWalletId,
        nominal: nominal,
      },
    }
  )

  const truncAvailable = truncateFractional(availableLimit, 2)

  const isEmpty = truncAvailable === 0
  const isEquiv = nominal === truncAvailable
  const isOver = nominal > truncAvailable

  React.useLayoutEffect(() => {
    handleNominalInput(truncAvailable)
  }, [truncAvailable])

  const handleSubmit = () => {
    dispatchEdit().then(
      () => {
        enqueueSnackbar('Successfully edited available claim limit', {
          variant: 'success',
        })
        onClose(true)
      },
      error => {
        console.error(error)
        enqueueSnackbar('Failed to edit available claim limit', {
          variant: 'error',
        })
        onClose(true)
      }
    )
  }

  const handleClose = () => onClose(false)

  return (
    <Dialog
      open={open}
      onClose={!dispatching && handleClose}
      fullWidth
      maxWidth="xs"
    >
      <WrapperModalGeneral>
        <div className="header">
          <h1 className="title">Edit Total Claim Limit</h1>
          <IconButton
            title="Close dialog"
            disabled={dispatching}
            onClick={handleClose}
            className="wrap-close"
          >
            <Icon className="icon-close">close</Icon>
          </IconButton>
        </div>
        <div className="body">
          <TextBlackBold style={{marginBottom: 12}}>
            Available Claim Limit*
          </TextBlackBold>

          <CurrencyInput
            required
            disabled={dispatching}
            value={maskedNominal}
            onChange={handleNominalInput}
            fullWidth
            error={isOver || isEmpty}
          />

          {isEmpty && (
            <FormHelperText error>
              Available Claim Limit is 0. Please top up the employee.
            </FormHelperText>
          )}
          {isOver && (
            <FormHelperText error>
              The nominal cannot be more than total claim at this time.
            </FormHelperText>
          )}
        </div>

        <div className="action-wrapper-modal-confirmation">
          <Button disabled={dispatching} onClick={handleClose} size="large">
            Cancel
          </Button>
          <Button
            disabled={dispatching || isEquiv || isEmpty || isOver}
            onClick={handleSubmit}
            color="primary"
            size="large"
            variant="contained"
            style={{marginLeft: 12}}
          >
            Save
          </Button>
        </div>
      </WrapperModalGeneral>
    </Dialog>
  )
}

export default EmployeeClaimEditDialog
