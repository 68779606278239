import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import {
  // createMuiTheme,
  lighten,
  makeStyles,
  // MuiThemeProvider,
} from '@material-ui/core/styles'

import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  IconButton,
  Icon,
} from '@material-ui/core'

// import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import PhoneAndroid from '@material-ui/icons/PhoneAndroid'

import {Link} from 'react-router-dom'

// import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'

// import DateFnsUtils from '@date-io/date-fns'

import {AttendanceLocationDialog} from './DialogComponent'
import moment from 'moment'
import Geocode from 'react-geocode'
import {getGMT} from '../attendance-helper'
import DefaultAvatar from '../../../../assets/images/default-avatar.png'

// const theme = createMuiTheme({
//   palette: {
//     primary: {
//       main: '#014a62',
//     },
//   },
// })

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
  },
  datePickerAction: {
    marginRight: 30,
  },
  buttonAction: {
    height: 61,
    marginTop: 6,
    width: 144,
  },
  title: {
    marginLeft: 15,
  },
  uploadButton: {
    color: '#fff',
    textDecoration: 'none',
  },
}))

// const CustomDatePicker = classes => {
//   const [selectedDate, setSelectedDate] = React.useState(
//     new Date('2014-08-18T21:11:54'),
//   )

//   const handleDateChange = date => {
//     setSelectedDate(date)
//   }

//   return (
//     <MuiPickersUtilsProvider utils={DateFnsUtils}>
//       <KeyboardDatePicker
//         margin="normal"
//         id="date-picker-dialog"
//         label="Label"
//         format="MM/dd/yyyy"
//         value={selectedDate}
//         onChange={handleDateChange}
//         KeyboardButtonProps={{
//           'aria-label': 'change date',
//         }}
//         className={classes.customDatePicker}
//       />
//     </MuiPickersUtilsProvider>
//   )
// }

const EnhancedTableToolbar = ({data, previousStateData}) => {
  const classes = useToolbarStyles()

  return (
    // <MuiThemeProvider theme={theme}>
    <Toolbar disableGutters>
      <Link
        to={{
          pathname: '/time/attendance/detail',
          state: {
            active: 'detail',
            data: data,
            initialStateData: previousStateData,
          },
        }}
        style={{textDecoration: 'none'}}
      >
        <IconButton
          edge="end"
          aria-label="back"
          // onClick={handleBack}
        >
          <Icon>arrow_back</Icon>
        </IconButton>
      </Link>

      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {data.global_user.name} Activity
        </Typography>
      </div>
    </Toolbar>
    // </MuiThemeProvider>
  )
}

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
}

const DetailActivity = ({detailActivityLabel, detailActivityData}) => {
  const classes = useStyles()

  return (
    <div className={classes.detailActivityWrapper}>
      <Typography className={classes.detailActivityLabel}>
        {detailActivityLabel}
      </Typography>
      <Typography className={classes.detailActivityTitle}>
        {detailActivityData}
      </Typography>
    </div>
  )
}

DetailActivity.propTypes = {
  detailActivityLabel: PropTypes.string.isRequired,
  detailActivityData: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  whiteTextButton: {
    color: '#fff',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 17,
    marginBottom: 17,
  },
  blackTextButton: {
    color: '#000',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 15,
  },
  stepIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  detailActivityWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 41,
    paddingLeft: 81,
    paddingRight: 88,
    paddingBottom: 82,
  },
  detailActivityLabel: {
    color: '#014a62',
    fontSize: 15,
    fontWeight: 600,
  },
  detailActivityTitle: {
    color: '#000000',
    fontSize: 17,
    fontWeight: 600,
  },
  editTemplateListsTitle: {
    color: '#000',
    fontSize: 15,
  },
  editTemplateListsSpacer: {
    marginTop: 30,
  },
  stepIcon: {
    color: '#014962',
    width: 97,
    height: 97,
  },
  editTemplateListsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    minHeight: '28.5rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detail: {
    color: '#007fb2',
    cursor: 'pointer',
  },
  customDatePicker: {
    width: 160,
  },
  table: {
    minWidth: 750,
    // marginLeft: 31,
    // marginRight: 31,
  },
  tableWrapper: {
    overflowX: 'auto',
    padding: '0 1.6rem',
  },
}))

const headCells = [
  {
    id: 'hour',
    numeric: false,
    disablePadding: false,
    label: 'Hour',
  },
  {
    id: 'timezone',
    numeric: false,
    disablePadding: false,
    label: 'Time Zone',
  },
  {
    id: 'work-schedule',
    numeric: false,
    disablePadding: false,
    label: 'Work Schedule',
  },
  {id: 'detail', numeric: false, disablePadding: false, label: 'Detail'},
  {
    id: 'location',
    numeric: true,
    disablePadding: false,
    label: 'Location',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: '',
  },
]

function EnhancedTableHead(props) {
  const {
    classes,
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
              style={{width: 'max-content'}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default function ActivityDetailDate({
  attendance,
  rootData,
  previousStateData,
}) {
  const attendances = [attendance]

  const classes = useStyles()

  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [selected, setSelected] = React.useState([])
  const [address, setAddress] = React.useState('')

  // const [dense, setDense] = React.useState(false)
  const dense = false

  const [isShowLocationDialog, setIsShowLocationDialog] = useState(false)

  useEffect(() => {
    Geocode.setApiKey('AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ')

    if (attendance?.location) {
      Geocode.fromLatLng(attendance.location.lat, attendance.location.lng).then(
        response => {
          const address = response.results[0].formatted_address
          setAddress(address)
        },
        () => {}
      )
    }
  }, [attendance])

  const handleSelectAllClick = () => {
    setSelected([])
  }

  const handleRequestSort = property => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  return (
    <div className={classes.root}>
      {isShowLocationDialog && (
        <AttendanceLocationDialog
          isShow={isShowLocationDialog}
          latitude={parseFloat(attendance.location.lat)}
          longitude={parseFloat(attendance.location.lng)}
          location={
            attendance.location.address ? attendance.location.address : address
          }
          photoUrl={
            (attendance?.time_attendance_policy?.mobile_setting
              ?.isIncludePhoto &&
              attendance?.photo) ||
            DefaultAvatar
          }
          onClose={() => {
            setIsShowLocationDialog(false)
          }}
        />
      )}

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          data={rootData}
          previousStateData={previousStateData}
        />

        <Divider />

        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={5}
            />
            <TableBody>
              {attendances.map((attendance, index) => {
                const policy = attendance.time_attendance_policy

                return (
                  <TableRow role="checkbox" key={index}>
                    <TableCell component="th" scope="row" padding="none">
                      {`${moment(attendance.clock_in).format('LLL')} - ${
                        attendance.clock_out === null
                          ? ''
                          : moment(attendance.clock_out).format('LLL')
                      }`}
                    </TableCell>
                    <TableCell>{getGMT(attendance)}</TableCell>
                    <TableCell>{policy?.name || '-'}</TableCell>
                    <TableCell>{attendance.description}</TableCell>
                    <TableCell>
                      <Typography
                        style={{
                          color: '#007fb2',
                          fontSize: 13,
                          cursor: 'pointer',
                        }}
                        onClick={() => setIsShowLocationDialog(true)}
                      >
                        {attendance.location?.address || address || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <PhoneAndroid
                        style={{color: '#007fb2', cursor: 'pointer'}}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  )
}
