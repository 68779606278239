import React, {useEffect, useState} from 'react'
import {PaperContainer, EnhancedToolbar} from '../../../../GlobalStyles'
import {IconButton} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import UserProfile from './detail-section/UserProfile'
import BoxSection from './detail-section/BoxSection'
import {withRouter} from 'react-router-dom'
import {RootWrapper, TypographyStyled} from './DetailEmployeeStyles'
import WorkingStatus from './detail-section/WorkingStatus'
import {
  Publications,
  Hobbies,
  // Licenses,
  Recommendation,
} from './detail-section'
import {
  GET_DIRECTORY_EMPLOYEE_DETAIL,
  GET_VERIFYDATA,
} from '../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import Axios from 'axios'
import {TOKEN, REST_API_SOSMED_URL} from '../../../../utils/globals'
import LoadingEmployee from '../LoadingEmployee'
import TerminationSection from './SharedDetailEmployee/TerminationSection'
import {dateFormat} from '../../../../utils/helpers'
import {useSnackbar} from 'notistack'
import EducationSection from './detail-section/education'
import ExperienceSection from './detail-section/experience'
import SpeakerSection from './detail-section/speaker'
import LicensesSection from './detail-section/licenses'
import DisciplinarySection from './detail-section/disciplinary'
import ServiceAgreementSection from './detail-section/service-agreement'
import AwardSection from './detail-section/award'
import OhsaSection from './detail-section/ohsa'
import ProjectSection from './detail-section/project'
import OrganizationActivitySection from './detail-section/organization-activity'
import SkillSection from './detail-section/skils'

const DetailEmployee = props => {
  const {id} = props.match.params
  const {enqueueSnackbar} = useSnackbar()
  const {pathname} = props.location
  const [follower, setFollower] = useState({
    follower: null,
    following: null,
  })

  const {data, error, refetch} = useQuery(GET_DIRECTORY_EMPLOYEE_DETAIL, {
    wlb_skipPatch: true,
    fetchPolicy: 'network-only',
    variables: {
      user: props.match.params.id,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  const user_name_long =
    (data &&
      data.global_users &&
      data.global_users[0] &&
      data.global_users[0].name) ||
    '-'

  const user_name = user_name_long.split(' ')[0]
  const {data: dataVerify, refetch: refetchFerify} = useQuery(GET_VERIFYDATA, {
    wlb_skipPatch: true,
    variables: {
      user: props.match.params.id,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'organization-hr-admin',
      },
    },
  })

  useEffect(() => {
    Axios.get(
      `${REST_API_SOSMED_URL}/users/${props.match.params.id}/friendships/detail`,
      {
        headers: {
          Authorization: 'Bearer ' + TOKEN,
        },
      }
    )
      .then(response => {
        setFollower({
          follower: response.data.data.total_follower,
          following: response.data.data.total_following,
        })
      })
      .catch(() => {})
  }, [])

  if (data === undefined && error === undefined) {
    return <LoadingEmployee />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  return (
    <RootWrapper>
      {pathname.includes('/directory/employee-alumni') &&
        data &&
        data.working[0] &&
        data.working[0].termination_status && (
          <TerminationSection
            id="termination-section"
            info={
              (data.working[0].termination_status.name || '-') +
                ' at ' +
                dateFormat(data.working[0].end_date, 'll') || '-'
            }
            description={
              (data.working[0].placement_fields &&
                data.working[0].placement_fields.talent_information &&
                data.working[0].placement_fields.talent_information
                  .reason_for_leaving) ||
              '-'
            }
          />
        )}
      <PaperContainer
        style={{
          overflow: 'hidden',
          marginBottom: 30,
          width: 'auto',
        }}
      >
        <EnhancedToolbar style={{padding: '0px 10px'}}>
          <IconButton onClick={() => props.history.goBack()} color="primary">
            <ArrowBackIcon />
          </IconButton>
        </EnhancedToolbar>

        {/* USER PROFILE */}
        <UserProfile
          dataVerify={
            dataVerify &&
            dataVerify.people_profile &&
            dataVerify.people_profile[0]
          }
          refetchFerify={refetchFerify}
          refetch={refetch}
          follower={follower}
          data={data}
        />
      </PaperContainer>

      {/* SUMMARY */}
      <BoxSection withPadding title="Ringkasan">
        <TypographyStyled mb="true" grey="true">
          {(data.global_users.length > 0 &&
            data.global_users[0].people_profiles.length > 0 &&
            data.global_users[0].people_profiles[0].profile_fields &&
            data.global_users[0].people_profiles[0].profile_fields.summary) ||
            '-'}
        </TypographyStyled>
      </BoxSection>

      {/* WORKING STATUS */}
      <BoxSection
        withPadding
        title="Status Perkerjaan"
        edit={e => e.preventDefault()}
      >
        <WorkingStatus user_name={user_name} user={props.match.params.id} />
      </BoxSection>

      {/* EXPERIENCE */}
      <ExperienceSection
        people_profile_experiences={data.people_profile_experiences}
        user_name={user_name}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* Project */}
      <ProjectSection
        user_name={user_name}
        people_profile_projects={data.people_profile_projects || []}
        user_name_long={user_name_long}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* Organization Activity */}
      <OrganizationActivitySection
        user_name={user_name}
        organization_activities={data.organization_activities || []}
        user_name_long={user_name_long}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* EDUCATION */}
      <EducationSection
        user_name={user_name}
        people_profile_educations={data.people_profile_educations || []}
        user_name_long={user_name_long}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* LISENCES */}
      {/* <BoxSection title="Training, License and Certification">
        <Licenses
          people_profile_certificates={data.people_profile_certificates}
          user_name={user_name}
        />
      </BoxSection> */}

      <LicensesSection
        people_profile_certificates={data.people_profile_certificates}
        user_name={user_name}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* Speaker */}
      <SpeakerSection
        people_profile_speaker={data.people_profile_speaker || []}
        user_name={user_name}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* Skills */}
      <SkillSection
        people_profile_skills={data.people_profile_skills || []}
        user_name_long={user_name_long}
        user_name={user_name}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* Award */}
      <AwardSection
        people_profile_awards={data.people_profile_awards || []}
        user_name_long={user_name_long}
        user_name={user_name}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* Publications */}
      <BoxSection title="Publikasi">
        <Publications
          people_profile_publications={data.people_profile_publications}
          user_name={user_name}
        />
      </BoxSection>

      {/* Service Agreement */}
      <ServiceAgreementSection
        people_profile_service_agreements={
          data.people_profile_service_agreements
        }
        user_name={user_name}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* Disciplinary */}
      <DisciplinarySection
        people_profile_disciplinary={data.people_profile_disciplinary}
        user_name={user_name}
        user_name_long={user_name_long}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* OHSA */}
      <OhsaSection
        user_name={user_name}
        people_profile_ohsa={data.people_profile_ohsa || []}
        user_name_long={user_name_long}
        userIdDetail={id}
        enqueueSnackbar={enqueueSnackbar}
        refetch={refetch}
      />

      {/* HOBBIES */}
      <BoxSection title="Hobi">
        <Hobbies
          user_name={user_name}
          people_profile_hobbies={data.people_profile_hobbies}
        />
      </BoxSection>

      {/* RECOMMENDATION */}
      {/* HIDE BE */}
      <BoxSection isNoBorder title="Rujukan">
        <Recommendation
          recommendation_to={data.recommendation_to || []}
          user={props.match.params.id}
          user_name={user_name}
        />
      </BoxSection>
    </RootWrapper>
  )
}

export default withRouter(DetailEmployee)
