import React, {useState, useEffect} from 'react'
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import {
  FlexCenters,
  PaperContainer,
  CustomTypography,
} from '../../../../GlobalStyles'
import {GET_DETAIL_DICTIONARY} from '../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'

import CompetencyDetail from './CompetencyDetail'
import CompetencyLevel from './CompetencyLevel'
import ArrowBack from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
  root: {padding: 24},
  ml12: {marginLeft: 12},
}))

const DetailComponent = props => {
  const classes = useStyles()
  const id = props.match.params.id
  const [tabsValue, setTabsValue] = useState(0)

  const {data, loading, refetch} = useQuery(GET_DETAIL_DICTIONARY, {
    variables: {
      id,
    },
  })

  useEffect(() => {
    refetch()
  }, [])

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  return (
    <PaperContainer>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          display: 'flex',
        }}
        disableGutters
      >
        <FlexCenters>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => props.history.goBack()}
            style={{color: '#000', marginLeft: 10}}
          >
            <ArrowBack />
          </IconButton>

          <CustomTypography fweight="bold" left="10px">
            Detail Competency
          </CustomTypography>
        </FlexCenters>
      </Toolbar>
      <Tabs
        value={tabsValue}
        onChange={handleChangeTabs}
        indicatorColor="secondary"
        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
      >
        <Tab label="Competency Detail" className={classes.tabRoot} />
        <Tab label="Competency Level" className={classes.tabRoot} />
      </Tabs>

      {loading && (
        <div
          style={{
            padding: '1.6rem 1.6rem 0',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {tabsValue === 0 && !loading && (
        <CompetencyDetail data={data?.competency_dictionaries[0]} />
      )}
      {tabsValue === 1 && !loading && (
        <CompetencyLevel data={data?.competency_dictionaries[0]} />
      )}
    </PaperContainer>
  )
}
export default withRouter(DetailComponent)
