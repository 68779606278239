import {Button} from '@material-ui/core'
import React from 'react'
import {GenerateTooltip} from '../../../../GlobalStyles'

const ButtonDownload = ({isDisable, onDownload, ...child}) => {
  return (
    <GenerateTooltip
      title={
        isDisable
          ? `You can only generate data once a day. to download data use the link that already generated`
          : ``
      }
      placement="left"
    >
      <div>
        <Button
          style={{height: '100%'}}
          variant="contained"
          color="primary"
          size="large"
          onClick={onDownload}
          disabled={isDisable}
        >
          <span {...child} />
        </Button>
      </div>
    </GenerateTooltip>
  )
}

export default ButtonDownload
