import React from 'react'

import {Chip, makeStyles, Typography} from '@material-ui/core'

import {StyledReactSelect} from '../../../../../../GlobalStyles'

const useStyles = makeStyles(() => ({
  inputStyle: {
    padding: '15px 18px',
  },
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
    marginBottom: 17,
  },
  root: {marginBottom: 32},
  helperText: {marginLeft: 14},
  chipsWrapper: {marginTop: 16, gap: 12, display: 'flex', flexWrap: 'wrap'},
  chips: {padding: 8},
}))

export const SelectAutocomplete = ({
  label,
  options,
  onChange,
  placeholder,
  onInputChange,
  helperText,
  error,
  value,
  isLoading,
  isAsync = false,
  loadOptions,
  chips,
  onDeleteChips,
  disabled,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <p className={classes.label}>{label}</p>
      <StyledReactSelect
        defaultOptions
        value={value}
        placeholder={placeholder}
        style={selectStyle(!value && error)}
        filterOption={false}
        onChange={option => onChange(option)}
        onInputChange={onInputChange}
        options={options}
        isSearchable
        isLoading={isLoading}
        autoload={false}
        isAsync={isAsync}
        loadOptions={loadOptions}
        menuPosition="fixed"
        disabled={disabled}
      />
      {chips?.[0] && (
        <div className={classes.chipsWrapper}>
          {chips?.map(({value, label}, i) => {
            return (
              <Chip
                key={i}
                className={classes.chips}
                label={label}
                onDelete={() => onDeleteChips({value, label, i})}
                color="primary"
              />
            )
          })}
        </div>
      )}
      {!value && error && (
        <Typography
          className={classes.helperText}
          variant="caption"
          color="error"
        >
          {helperText}
        </Typography>
      )}
    </div>
  )
}

export const selectStyle = isError => ({
  container: base => ({...base, marginTop: 10}),
  control: base => ({...base, ...(isError && {borderColor: '#f44336'})}),
})
