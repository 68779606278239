import React, {useState} from 'react'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {MoreIcon, StyledMenus, StyledMenuItem} from '../../../../GlobalStyles'
import {useApolloClient, useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {
  DELETE_MULTIPLE_SURVEY_CENTER,
  DUPLICATE_SURVEY_CENTER,
} from '../../../../graphql/mutations/survey/multirater-nps/survey-center/surveyCenter.mutation'
import {GET_DETAIL_SURVEY_BEFORE_DUPLI} from '../../../../graphql/queries/survey/multirater-nps/survey-center/getListSurveyCenter.query'

export default function PopupNomor(props) {
  const client = useApolloClient()
  const {selected, setSelected, dataSelected, setDataSelected, refetch} = props
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteMutation] = useMutation(DELETE_MULTIPLE_SURVEY_CENTER)
  const [duplicateMutation] = useMutation(DUPLICATE_SURVEY_CENTER)
  const {enqueueSnackbar} = useSnackbar()

  const handleClose = () => {
    setOpenDelete(false)
  }

  const handleClickOpen = popupState => {
    popupState.close()
    setOpenDelete(true)
  }

  const handleDelete = () => {
    deleteMutation({
      variables: {
        id: selected,
      },
    })
      .then(() => {
        refetch()
        setSelected([])
        setDataSelected([])
        enqueueSnackbar('Umpan balik dihapus', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        setSelected([])
        setDataSelected([])
        enqueueSnackbar('Hapus Umpan Balik Gagal, silahkan coba lagi nanti', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleDuplicate = async () => {
    const {data} = await client.query({
      query: GET_DETAIL_SURVEY_BEFORE_DUPLI,
      variables: {survey: dataSelected[0].id},
      fetchPolicy: 'network-only',
    })
    if (data) {
      const datas = data.multirater_surveys[0]
      const objects = [datas]

      objects[0].multirater_survey_groups = {
        data: datas.multirater_survey_groups.map(res => {
          delete res.__typename
          return {
            ...res,
            multirater_survey_questions: {
              data: res.multirater_survey_questions.map(r => {
                delete r.__typename
                return r
              }),
            },
          }
        }),
      }

      objects[0].multirater_respondents = {
        data: datas.multirater_respondents.map(v => {
          delete v.__typename
          return v
        }),
      }

      objects[0].status = 'DRAFT'
      delete objects[0].__typename

      duplicateMutation({
        variables: {
          objects,
        },
      })
        .then(() => {
          refetch()
          setSelected([])
          setDataSelected([])
          enqueueSnackbar('Feedback Duplicated', {
            variant: 'success',
            autoHideDuration: 2000,
          })
        })
        .catch(() => {
          setSelected([])
          setDataSelected([])
          enqueueSnackbar(
            'Duplicated Feedback failed, please try again later',
            {
              variant: 'error',
              autoHideDuration: 2000,
            }
          )
        })
    }
    // dataSelected &&
    //   dataSelected.map(row => {
    //     row.status = 'DRAFT'
    //   })
  }

  return (
    <>
      <PopupState
        style={{margin: 'auto'}}
        variant="popover"
        popupId="demo-popup-menu"
      >
        {popupState => (
          <>
            <MoreIcon style={{margin: 'auto'}} {...bindTrigger(popupState)}>
              more_horiz
            </MoreIcon>
            <StyledMenus {...bindMenu(popupState)}>
              {dataSelected.length === 1 && (
                <StyledMenuItem onClick={handleDuplicate}>
                  Duplikat
                </StyledMenuItem>
              )}
              <StyledMenuItem onClick={() => handleClickOpen(popupState)}>
                Hapus
              </StyledMenuItem>
            </StyledMenus>
          </>
        )}
      </PopupState>
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        name="item yang dipilih"
        mutation={() => handleDelete()}
      />
    </>
  )
}
