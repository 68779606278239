import React from 'react'

import {IconButton} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import {ItemWrapper} from '../../../DetailEmployeeStyles'

const row = (header, content) => (
  <tr>
    <th scope="row">{header}</th>
    <td>{content || '-'}</td>
  </tr>
)

export const IdentityCardItem = props => {
  const {data, onEdit} = props

  return (
    <ItemWrapper>
      <h1 className="title">{data.type}</h1>

      <table className="content">
        {row('Nomor Identitas', data.number)}
        {row('Alamat', data.identity_fields?.address)}
        {row('Kode Pos', data.identity_fields?.postalCode)}
        {row('Masa Berlaku', data.identity_fields?.expiredDate)}
      </table>

      <IconButton onClick={onEdit} className="edit">
        <EditIcon />
      </IconButton>
    </ItemWrapper>
  )
}
