import React from 'react'
import {WrapIconItem} from '../../DetailEmployeeStyles'
import {GET_LIST_SOCIALS} from '../../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import LoadingEmployee from '../../../LoadingEmployee'
import linkedin from '../../../../../../assets/slicing/socmed/linkedin-blue.svg'
import facebook from '../../../../../../assets/slicing/socmed/facebook-blue.svg'
import twitter from '../../../../../../assets/slicing/socmed/twitter-blue.svg'
import tiktok from '../../../../../../assets/slicing/socmed/tiktok-blue.svg'
import instagram from '../../../../../../assets/slicing/socmed/instagram-blue.svg'

const _renderComponent = ({data, bridge, title, icon}) => {
  return (
    data &&
    data[bridge] &&
    data[bridge][0] && (
      <WrapIconItem>
        <div className="socials-item">
          <div className="title">
            <img src={icon} />
            <p className="title-name">{title}</p>
          </div>
          <div className="content">
            {data[bridge].map(res => (
              <div key={`${res.id}-itm`} className="account">
                <p className="black">{res.name || '-'}</p>
                <p className="grey">{res.url || '-'}</p>
                {res.is_main && (
                  <div className="is-main">
                    <p>Main Social Contact</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </WrapIconItem>
    )
  )
}

export default function Socials({userId}) {
  const {data, loading} = useQuery(GET_LIST_SOCIALS, {
    wlb_skipPatch: true,
    fetchPolicy: 'network-only',
    variables: {
      userId,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  if (loading) {
    return <LoadingEmployee noborder smallpadding />
  }

  const LIST_SOCIALS = [
    {
      data: data,
      bridge: 'facebook',
      title: 'Facebook',
      icon: facebook,
    },
    {
      data: data,
      bridge: 'twitter',
      title: 'Twitter',
      icon: twitter,
    },
    {
      data: data,
      bridge: 'instagram',
      title: 'Instagram',
      icon: instagram,
    },
    {
      data: data,
      bridge: 'linkedin',
      title: 'Linkedin',
      icon: linkedin,
    },
    {
      data: data,
      bridge: 'tiktok',
      title: 'TikTok',
      icon: tiktok,
    },
  ]

  return LIST_SOCIALS.map(res => _renderComponent(res))
}
