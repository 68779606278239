import gql from 'graphql-tag'

export const GET_JOB_PROFILE_TOP = gql`
  query($organization: Int) {
    company_job_profiles(
      where: {
        organization: {_eq: $organization}
        _or: [
          {job_profile_supervisor: {organization: {_neq: $organization}}}
          {parent: {_is_null: true}}
        ]
      }
    ) {
      id
      title
      job_summary
      job_description
      job_icon
      slots
      experience_level
      parent
      company_organization {
        id
        name
      }
      company_employee_position {
        id
        name
      }
      company_employee_rank {
        id
        name
      }
      company_address {
        id
        office_name
      }
      company_job_profile_r_educations {
        id
        global_degree {
          id
          name
        }
        global_faculty {
          id
          name
        }
      }
      company_job_profile_competencies {
        id
        competency_dictionary {
          id
          name
        }
        level
      }
    }
  }
`
