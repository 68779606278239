/* eslint-disable no-console */
import React, {useState} from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import moment from 'moment'

import {ButtonSubmit} from '../../../SharedDetailEmployee'
import {isErrorForm} from '../../ShareComponents-detail/helperDetail'
import {ModalAddEditWraper} from '../../../DetailEmployeeStyles'
import DeletePopup from '../../../../../../shared-components/popup/DeletePopup'
import FormInput from '../../ShareComponents-detail/FormInput'
import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'

import {GET_MEDICAL_CATEGORY_LIST} from '../../../../../../../graphql/queries'
import {
  ADD_MEDICAL_HISTORY,
  DELETE_MEDICAL_HISTORY,
  UPDATE_MEDICAL_HISTORY,
} from '../../../../../../../graphql/mutations'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_FORM_DATA = {
  name: '',
  age: '',
  start_date: undefined,
  end_date: undefined,
  hospital_name: '',
  doctor_name: '',
  disease: '',
  consequence: '',
  laboratory_number: '',
  laboratory_notes: '',
  medical_score: '',
  medical_fee: '',
  medical_category: null,
  attachments: [],
}

const MedicalHistoryAddEditDelete = props => {
  const {
    open = false,
    userId,
    data: initialData,
    onClose,
    isEdit,
    refetch,
  } = props
  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [formState, setFormState] = React.useState(STATE_INITIAL)
  const [formData, setFormData] = useState(
    isEdit ? initialData : INITIAL_FORM_DATA
  )
  const [searchCategory, setSearchCategory] = useState('')
  const [modalDelete, setModalDelete] = useState({
    data: initialData,
    open: false,
  })

  const isError = formState === STATE_ERROR
  const isDispatching = formState === STATE_DISPATCH

  const {data: categoryList, loading: loadingCategoryList} = useQuery(
    GET_MEDICAL_CATEGORY_LIST,
    {
      wlb_skipPatch: true,
      skip: !open,
      fetchPolicy: 'cache-first',
      variables: {
        search: `%${searchCategory}%`,
      },
    }
  )

  React.useEffect(() => {
    if (categoryList && initialData) {
      const medicalCategorySelectedValue = categoryList.medical_categories.find(
        item =>
          item.name?.toLowerCase() ===
          initialData.medical_category?.toLowerCase()
      )

      if (medicalCategorySelectedValue) {
        setFormData({
          ...initialData,
          medical_category: {
            label: medicalCategorySelectedValue?.name,
            value: medicalCategorySelectedValue?.id,
          },
        })
      } else {
        setFormData({...initialData})
      }
    }
  }, [categoryList, initialData])

  const handleAutocompleteChange = item => {
    const {fieldName, label, value} = item

    setFormData({
      ...formData,
      [fieldName]: {label, value},
    })
  }

  const deleteMutation = () => {
    const promise = client.mutate({
      mutation: DELETE_MEDICAL_HISTORY,
      variables: {
        userId: userId,
        id: initialData.id,
      },
    })

    promise.then(
      () => {
        refetch()
        onClose()
        enqueueSnackbar(`Rekam Medis dihapus`, {variant: 'success'})
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Gagal untuk menghapus rekam medis`, {variant: 'error'})
      }
    )
  }

  const submitMutation = () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const data = {
      ...formData,
      userId: userId,
      medical_category: formData?.medical_category?.label,
      age: !formData?.age ? null : Number(formData?.age),
      medical_score: !formData?.medical_score
        ? null
        : Number(formData?.medical_score),
      start_date: moment(formData.start_date).format('YYYY-MM-DD'),
      end_date: moment(formData.end_date).format('YYYY-MM-DD'),
    }
    delete data.__typename

    const verb = isEdit ? 'ubah' : 'tambah'
    let promise

    if (isEdit) {
      promise = client.mutate({
        mutation: UPDATE_MEDICAL_HISTORY,
        variables: {
          id: initialData.id,
          ...data,
        },
      })
    } else {
      promise = client.mutate({
        mutation: ADD_MEDICAL_HISTORY,
        variables: {
          ...data,
        },
      })
    }

    promise.then(
      () => {
        setFormState(STATE_INITIAL)
        refetch()
        onClose()

        enqueueSnackbar(`Riwayat medis di${verb}`, {variant: 'success'})
      },
      err => {
        setFormState(STATE_INITIAL)

        console.error(err)
        enqueueSnackbar(
          `Gagal untuk ${
            verb === 'tambah' ? 'menambahkan' : 'menghapus'
          } riwayat medis`,
          {variant: 'error'}
        )
      }
    )
  }

  const fieldsList = [
    {
      type: 'textfield',
      label: 'Nama Rekam Medis*',
      placeholder: 'Tambahkan Nama',
      required: true,
      fieldName: 'name',
      value: formData?.name,
      error: !formData?.name,
    },
    {
      type: 'textfield',
      label: 'Usia',
      placeholder: 'Tambahkan usia',
      inputType: 'number',
      required: false,
      fieldName: 'age',
      value: formData?.age,
    },
    {
      type: 'date',
      label: ['Tanggal Mulai*', 'Tanggal Selesai*'],
      fieldName: ['start_date', 'end_date'],
      required: true,
      value: [formData?.start_date, formData?.end_date, false, false],
      maxDate: [new Date(), undefined],
      error: [
        !formData?.start_date,
        !formData?.end_date || formData?.start_date > formData?.end_date,
      ],
    },
    {
      type: 'textfield',
      label: 'Nama Rumah Sakit',
      placeholder: 'Tambahkan nama rumah sakit',
      required: false,
      fieldName: 'hospital_name',
      value: formData?.hospital_name,
    },
    {
      type: 'textfield',
      label: 'Nama Dokter',
      placeholder: 'Tambahkan nama dokter',
      required: false,
      fieldName: 'doctor_name',
      value: formData?.doctor_name,
    },
    {
      type: 'textfield',
      label: 'Penyakit*',
      placeholder: 'Tambahkan penyakit',
      required: true,
      fieldName: 'disease',
      multiline: true,
      rows: 3,
      value: formData?.disease,
      error: !formData?.disease,
    },
    {
      type: 'textfield',
      label: 'Konsekuensi',
      placeholder: 'Tambahkan konsekuensi',
      required: false,
      fieldName: 'consequence',
      value: formData?.consequence,
    },
    {
      type: 'textfield',
      label: 'Nomor Laboratorium',
      placeholder: 'Tambahkan nomor laboratorium',
      required: false,
      fieldName: 'laboratory_number',
      value: formData?.laboratory_number,
    },
    {
      type: 'textfield',
      label: 'Catatan Laboratorium',
      placeholder: 'Tambahkan catatan laboratorium',
      required: false,
      fieldName: 'laboratory_notes',
      multiline: true,
      rows: 3,
      value: formData?.laboratory_notes,
    },
    {
      type: 'textfield',
      label: 'Skor Medis',
      placeholder: 'Tambahkan skor medis',
      inputType: 'number',
      required: false,
      fieldName: 'medical_score',
      value: formData?.medical_score,
    },
    {
      type: 'textfield',
      label: 'Biaya Medis',
      placeholder: 'Tambahkan biaya medis',
      inputType: 'number',
      required: false,
      fieldName: 'medical_fee',
      value: formData?.medical_fee,
    },
    {
      type: 'autocomplete',
      label: 'Kategori Medis*',
      placeholder: 'Tambahkan kategori medis',
      required: true,
      fieldName: 'medical_category',
      value: formData?.medical_category,
      option: categoryList?.medical_categories.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData?.medical_category,
      onChange: handleAutocompleteChange,
      onInputChange: query => {
        setSearchCategory(query)
      },
      isLoading: loadingCategoryList,
    },
    {
      type: 'attachment',
      label: 'Lampiran',
      fieldName: 'attachment',
      attachments: formData?.attachments,
      error: true,
      multiple: true,
      link: 'url',
    },
  ]

  return (
    <>
      <ModalDetail
        open={open}
        maxWidth="sm"
        title={`${isEdit ? 'Ubah' : 'Tambahkan'} Rekam Medis`}
        onClose={
          isDispatching
            ? null
            : () => {
                setFormData(INITIAL_FORM_DATA)
                onClose()
              }
        }
        onDelete={
          isEdit
            ? () => {
                setModalDelete({...modalDelete, open: true})
              }
            : null
        }
      >
        <ModalAddEditWraper>
          <FormInput
            open
            fieldsList={fieldsList}
            setValues={setFormData}
            values={formData}
            errorState={isError}
          />
        </ModalAddEditWraper>

        <ButtonSubmit
          disabled={isDispatching}
          onCancel={onClose}
          onSave={submitMutation}
        />
      </ModalDetail>

      <DeletePopup
        name={modalDelete.data?.name}
        open={modalDelete.open}
        mutation={deleteMutation}
        handleClose={() => setModalDelete({...modalDelete, open: false})}
      />
    </>
  )
}

export default MedicalHistoryAddEditDelete
