import gql from 'graphql-tag'

export const ADD_COMPONENT = gql`
  mutation addBusinessTripComponent(
    $object: [business_trip_component_insert_input!]!
  ) {
    insert_business_trip_component(objects: $object) {
      affected_rows
    }
  }
`

export const UPDATE_COMPONENT = gql`
  mutation update_business_trip_component(
    $id: Int
    $changes: business_trip_component_set_input
  ) {
    update_business_trip_component(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`

export const DELETE_LIST_SETTING_COMPONENT = gql`
  mutation delete_business_trip_component($id: Int) {
    update_business_trip_component(
      where: {id: {_eq: $id}}
      _set: {deletedAt: "now()"}
    ) {
      affected_rows
    }
  }
`
export const DELETE_LIST_SETTING_POLICY = gql`
  mutation delete_business_trip_policies($id: Int) {
    update_business_trip_policies(
      where: {id: {_eq: $id}}
      _set: {deletedAt: "now()"}
    ) {
      affected_rows
    }
  }
`

export const ADD_POLICY_BUSINESS_TRIP = gql`
  mutation(
    $name: String!
    $description: String!
    $confirm_type: Int
    $first_confirm_profile: Int
    $second_confirm_profile: Int
    $third_confirm_profile: Int
    $destination_type: Int
    $budget_component_type: Int
    $filter: JSON
    $setup_destination: JSON
    $currency_to: String
  ) {
    BusinessTripPolicyCreate(
      name: $name
      description: $description
      confirm_type: $confirm_type
      first_confirm_profile: $first_confirm_profile
      second_confirm_profile: $second_confirm_profile
      third_confirm_profile: $third_confirm_profile
      destination_type: $destination_type
      budget_component_type: $budget_component_type
      filter: $filter
      setup_destination: $setup_destination
      currency_to: $currency_to
    ) {
      success
    }
  }
`

export const UPDATE_NOMINAL_ACTIVITY_TRIP = gql`
  mutation UpdateNominalComponent(
    $id: Int
    $nominal: float8
    $destination_id: Int
    $update_by: uuid
  ) {
    update_people_work_business_trip_destination_budgets(
      _set: {nominal: $nominal}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
      returning {
        id
        nominal
      }
    }

    update_people_work_business_trip_destinations(
      _set: {last_modified: "now()", updated_by: $update_by}
      where: {id: {_eq: $destination_id}}
    ) {
      affected_rows
      returning {
        id
        last_modified
        updated_by
        globalUserByUpdatedBy {
          id
          name
        }
      }
    }
  }
`
export const REJECT_REQUEST_TRIP = gql`
  mutation RejectedStatus(
    $id: Int!
    $fields: jsonb!
    $approvedDate: timestamp!
  ) {
    rejected: update_people_work_business_trips(
      _set: {status: "rejected", approved_date: $approvedDate}
      _append: {business_trip_fields: $fields}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
      returning {
        id
        status
        approved_date
        business_trip_fields
      }
    }
  }
`

export const UPLOAD_PAYMENT_PROCESS = gql`
  mutation uploadPayment(
    $id: Int!
    $type: Int
    $bank_id: Int
    $status: String
    $attachments: jsonb
  ) {
    update_people_work_business_trip_destination_budgets(
      _set: {
        status: $status
        payment_type: $type
        bank_id: $bank_id
        attachment: $attachments
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
      returning {
        id
        status
        payment_type
        bank_id
        attachment
        people_profile_bank {
          id
          bank_name
          account_number
          account_name
        }
      }
    }
  }
`

export const CONFIRM_PAYMENT = gql`
  mutation ApprovedBusinessTrip(
    $id: Int!
    $approvedBy: uuid!
    $approvedDate: timestamp!
  ) {
    update_people_work_business_trips(
      _set: {
        status: "approved"
        approved_by: $approvedBy
        approved_date: $approvedDate
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
      returning {
        id
        status
        approved_by
        approved_date
        globalUserByApprovedBy {
          id
          name
          people_work_placements(limit: 1) {
            id
            company_job_profile {
              id
              title
            }
          }
        }
      }
    }
  }
`

export const CANCEL_BUSINESS_TRIP = gql`
  mutation($id: Int!, $cancelledBy: uuid!, $approvedDate: timestamp!) {
    update_people_work_business_trips(
      where: {id: {_eq: $id}, status: {_in: ["processing"]}}
      _set: {
        status: "cancelled"
        cancelled_by: $cancelledBy
        approved_date: $approvedDate
      }
    ) {
      affected_rows
      returning {
        id
        status
        approved_date
        cancelled_by
        globalUserByCancelledBy {
          id
          name
          people_work_placements(limit: 1) {
            id
            company_job_profile {
              id
              title
            }
          }
        }
      }
    }
  }
`

export const EDIT_POLICY_BUSINESS_TRIP = gql`
  mutation(
    $id: Int!
    $name: String!
    $description: String
    $confirm_type: Int
    $first_confirm_profile: Int
    $second_confirm_profile: Int
    $third_confirm_profile: Int
    $destination_type: Int
    $budget_component_type: Int
    $filter: JSON
    $isUpdatePosition: Boolean
    $setup_destination: JSON
    $isUpdateDestination: Boolean
    $currency_to: String
  ) {
    BusinessTripPolicyUpdate(
      id: $id
      name: $name
      description: $description
      confirm_type: $confirm_type
      first_confirm_profile: $first_confirm_profile
      second_confirm_profile: $second_confirm_profile
      third_confirm_profile: $third_confirm_profile
      destination_type: $destination_type
      budget_component_type: $budget_component_type
      filter: $filter
      is_update_position: $isUpdatePosition
      setup_destination: $setup_destination
      is_update_destination: $isUpdateDestination
      currency_to: $currency_to
    ) {
      success
    }
  }
`

export const TRIP_REPORT_UPDATE_VERIFY = gql`
  mutation($id: Int!, $status: String) {
    update_people_work_business_trips_by_pk(
      pk_columns: {id: $id}
      _set: {report_status: $status}
    ) {
      id
      report_status
    }
  }
`
