import React from 'react'

import clsx from 'classnames'
import {
  LinearProgress,
  makeStyles,
  TextField,
  IconButton,
  Link,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import {retrieveFileIcon} from './AttachmentStyles'

const useStyles = makeStyles(theme => ({
  item: {
    backgroundColor: '#F7F8F9',
    display: 'grid',
    gridTemplateColumns: `min-content minmax(0, 1fr) min-content`,
    gridGap: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    borderRadius: '5px',
  },

  icon: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    objectFit: 'contain',
  },
  filename: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  remove: {
    height: 16,
    width: 16,
    padding: 14,
    margin: -4,
  },
  progress: {
    width: '100%',
    margin: theme.spacing(0.8, 0),
  },

  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: theme.spacing(1.75),
    marginTop: theme.spacing(1),
  },
  dot: {
    height: theme.spacing(0.5),
    width: theme.spacing(0.5),
    margin: theme.spacing(0, 1),
    borderRadius: '50%',
    backgroundColor: theme.palette.grey.A200,
  },
}))

export default function AttachmentItem(props) {
  const {
    className,
    style,
    name,
    size = 0,
    url,
    disabled,
    isUploading,
    isPreview = true,
    progress,
    onNameChange,
    onRemove,
  } = props

  const styles = useStyles()

  const progressValue = Math.floor(progress / size) * 100

  const fieldValue = isUploading
    ? `Uploading...` + (progress != null ? ` (${progressValue}%)` : ``)
    : name

  return (
    <div className={clsx(styles.item, className)} style={style}>
      <img src={retrieveFileIcon(name, url)} className={styles.icon} />
      <div>
        <TextField
          disabled={isUploading || disabled}
          value={fieldValue}
          onChange={isUploading ? null : onNameChange}
          variant="outlined"
          size="small"
          margin="none"
          color="secondary"
          fullWidth
          className={styles.filename}
        />

        {isUploading ? (
          <div className={styles.info}>
            <LinearProgress
              value={progressValue}
              variant={progress != null ? 'determinate' : 'indeterminate'}
              className={styles.progress}
            />
          </div>
        ) : (
          <div className={styles.info}>
            <span>{(size / 1024 / 1024).toFixed(2)} MB</span>
            <div className={styles.dot} />
            {isPreview && (
              <Link target="_blank" href={url} color="secondary">
                Pratinjau
              </Link>
            )}
          </div>
        )}
      </div>
      <IconButton
        disabled={isUploading || disabled}
        onClick={onRemove}
        className={styles.remove}
      >
        <CloseIcon style={{fontSize: 14}} />
      </IconButton>
    </div>
  )
}
