import React from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import {PaperContainer, AddEditTitle} from '../../../../GlobalStyles'
import MaterialTable, {MTableBody} from 'material-table'
import {useSnackbar} from 'notistack'

import {Link, withRouter} from 'react-router-dom'
import {IconButton, Toolbar, Paper, makeStyles} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {GET_DETAIL_EMP_INDIVIDUAL} from '../../../../graphql/queries'
import {EDIT_INDIVIDUAL_PERFORMANCE} from '../../../../graphql/mutations/index'

import {TableOptionsNoSorting} from '../../../shared-components/table/TableOptions'
import {useQuery, useMutation} from '@apollo/react-hooks'
import Loading from '../../../../components/loading/LoadingComponent'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    margin: '1.6rem',
  },
  cardEmp: {padding: '0 1.6rem'},
}))

const renderEmpty = () => {
  return (
    <tbody>
      <tr>
        <td colSpan="10">
          <div style={{textAlign: 'center', padding: '3rem 0'}}>
            <img
              src={require('../../../../assets/images/nodataemp.png')}
              alt="no-data"
              height="170"
              width="170"
            ></img>
            <Typography variant="h6">No Data</Typography>
            <Typography variant="body1">
              This employee does not report yet
            </Typography>
          </div>
        </td>
      </tr>
    </tbody>
  )
}

const SummaryComponent = props => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const {emp, version} = props

  const states = {
    pathname: `/performance/individual/detail`,
    state: {
      active: 'individual-detail',
      searchable: true,
      version: version,
    },
  }

  const {data, error} = useQuery(GET_DETAIL_EMP_INDIVIDUAL, {
    variables: {
      placement:
        emp && emp.people_work_placement && emp.people_work_placement.id,
    },
  })
  const [updateIndividual] = useMutation(EDIT_INDIVIDUAL_PERFORMANCE)

  const handleEditIndividualPerformance = value => {
    const ogf = (value.ogf_score / 100) * ((value.ogf_weight / 100) * 4)
    const goal = (value.kpi_score / 100) * ((value.kpi_weight / 100) * 4)
    const multirater =
      (value.multirater_score / 100) * ((value.multirater_weight / 100) * 4)
    const nps = (value.nps_score / 100) * ((value.nps_weight / 100) * 4)
    const index = nps + ogf + goal + multirater

    if (index <= 4) {
      updateIndividual({
        variables: {
          id: value.id,
          goal: parseInt(value.kpi_score),
          multirater: parseInt(value.multirater_score),
          nps: parseInt(value.nps_score),
          ogf: parseInt(value.ogf_score),
          index_score: index,
        },
      })
      enqueueSnackbar('Success Updated', {
        variant: 'success',
      })
    } else {
      enqueueSnackbar('index score greater than 4', {
        variant: 'error',
      })
    }
  }
  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const people_work_placement = emp && emp.people_work_placement
  const global_user = people_work_placement && people_work_placement.global_user

  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link to={states}>
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <AddEditTitle variant="h6">Individual Performance Summary</AddEditTitle>
      </Toolbar>
      <Divider></Divider>
      <Paper className={classes.paper}>
        <img
          src={global_user && global_user.avatar}
          alt="ava"
          width="100"
          height="100"
        ></img>
        <div className={classes.cardEmp}>
          <Typography variant="body1" style={{fontWeight: 700}}>
            {global_user && global_user.name}
          </Typography>
          <Typography variant="body2">
            {people_work_placement && people_work_placement.regno}
          </Typography>
          <Typography variant="body2">
            {people_work_placement &&
              people_work_placement.company_job_profile &&
              people_work_placement.company_job_profile.title}
          </Typography>
        </div>
      </Paper>
      <MaterialTable
        style={{padding: '0 1.6rem'}}
        columns={[
          {
            title: 'Period',
            field: 'startdate',
            type: 'datetime',
            editable: 'never',
            align: 'center',
            render: rowData =>
              rowData.startdate && rowData.enddate
                ? moment(rowData.startdate).format('YYYY') +
                  '/' +
                  moment(rowData.enddate).format('YYYY')
                : '-',
          },

          {
            title: 'Goal',
            field: 'kpi_score',
            type: 'numeric',
            align: 'center',
            render: rowData => rowData.kpi_score.toFixed(2),
            editComponent: props => (
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
              />
            ),
          },
          {
            title: 'Goal Weight',
            field: 'kpi_weight',
            type: 'numeric',
            editable: 'never',
            align: 'center',
            render: rowData =>
              rowData.kpi_weight ? rowData.kpi_weight + '%' : '-',
          },
          {
            title: 'Multirater',
            field: 'multirater_score',
            type: 'numeric',
            align: 'center',
            render: rowData => rowData.multirater_score.toFixed(2),
            editComponent: props => (
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
              />
            ),
          },
          {
            title: 'Multirater Weight',
            field: 'multirater_weight',
            type: 'numeric',
            editable: 'never',
            align: 'center',
            render: rowData =>
              rowData.multirater_weight ? rowData.multirater_weight + '%' : '-',
          },
          {
            title: 'NPS',
            field: 'nps_score',
            type: 'numeric',
            align: 'center',
            render: rowData => rowData.nps_score.toFixed(2),
            editComponent: props => (
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
              />
            ),
          },
          {
            title: 'NPS Weight',
            field: 'nps_weight',
            type: 'numeric',
            editable: 'never',
            align: 'center',
            render: rowData =>
              rowData.nps_weight ? rowData.nps_weight + '%' : '-',
          },
          {
            title: 'OGF',
            field: 'ogf_score',
            type: 'numeric',
            align: 'center',
            render: rowData => rowData.ogf_score.toFixed(2),
            editComponent: props => (
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
              />
            ),
          },
          {
            title: 'OGF Weight',
            field: 'ogf_weight',
            editable: 'never',
            type: 'numeric',
            align: 'center',
            render: rowData =>
              rowData.ogf_weight ? rowData.ogf_weight + '%' : '-',
          },
          {
            title: 'Individual Performance Score',
            field: 'index_score',
            type: 'numeric',
            editable: 'never',
            align: 'center',
            render: rowData => rowData.index_score.toFixed(2),
          },
        ]}
        data={data.performance_individuals}
        title=""
        options={{
          ...TableOptionsNoSorting,
          actionsColumnIndex: -1,
          rowStyle: {
            wordBreak: 'normal',
          },
        }}
        components={{
          Toolbar: () => null,
          Container: props => <Paper {...props} elevation={0} />,
          Body: props => {
            return props.renderData.length === 0 ? (
              renderEmpty()
            ) : (
              <MTableBody {...props} />
            )
          },
        }}
        editable={{
          onRowUpdate: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                handleEditIndividualPerformance(newData)
                resolve()
              }, 1000)
            }),
        }}
        icons={{
          Edit: () => (
            <Typography variant="body1" color="primary">
              Edit
            </Typography>
          ),
          Clear: () => (
            <Typography variant="body1" style={{color: '#a9a8a8'}}>
              Cancel
            </Typography>
          ),
          Check: () => (
            <Button variant="contained" color="primary">
              Save
            </Button>
          ),
        }}
      />
    </PaperContainer>
  )
}

export default withRouter(SummaryComponent)
