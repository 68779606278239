import gql from 'graphql-tag'

export const GET_USER_DATA = gql`
  query($userId: uuid!) {
    global_users(where: {id: {_eq: $userId}}) {
      id
      name
      avatar
      email
      people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
        id
        company_profile {
          id
          brand_name
          legal_name
        }
        company_job_profile {
          id
          title
        }
      }
    }
  }
`
