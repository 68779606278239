import gql from 'graphql-tag'

export const GET_LOAN_ACTIVITY = gql`
  query getLoanActivity($search: String, $limit: Int, $offset: Int) {
    total_loan: people_work_loans_aggregate(
      where: {
        deleted_at: {_is_null: true}
        _or: [
          {
            people_work_placement: {
              _or: [
                {regno: {_ilike: $search}}
                {global_user: {name: {_ilike: $search}}}
              ]
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    loans: people_work_loans(
      where: {
        deleted_at: {_is_null: true}
        _or: [
          {
            people_work_placement: {
              _or: [
                {regno: {_ilike: $search}}
                {global_user: {name: {_ilike: $search}}}
              ]
            }
          }
        ]
      }
      order_by: [{date_added: desc_nulls_last}]
      limit: $limit
      offset: $offset
    ) {
      id
      loan_amount
      installment
      letter_num
      status
      date_added
      installment_num: people_work_installments(
        where: {status: {_eq: "paid"}}
      ) {
        id
        installment_num
      }
      people_work_placement {
        id
        regno
        global_user {
          id
          name
        }
      }
    }
  }
`

export const GET_LOAN_INFORMATION = gql`
  query getLoanInformation($loanId: Int) {
    total_loan: people_work_installment_aggregate(
      where: {loan: {_eq: $loanId}}
    ) {
      aggregate {
        sum {
          plan_amount
        }
      }
    }

    paid_amount: people_work_installment_aggregate(
      where: {loan: {_eq: $loanId}, status: {_eq: "paid"}}
    ) {
      aggregate {
        sum {
          actual_payment
        }
      }
    }

    loan: people_work_loans(where: {id: {_eq: $loanId}}) {
      id
      finance_loan_policy {
        id
        name
      }
      description
      installment
      last_update_by
      last_modified
      date_added
      installment_num: people_work_installments(
        where: {status: {_eq: "paid"}}
      ) {
        id
        installment_num
      }
      people_work_placement {
        id
        regno
        company_employee_position {
          id
          name
        }
        company_profile {
          id
          brand_name
        }
        global_user {
          id
          name
        }
      }
    }
  }
`

export const GET_USER_NAME_REGNO = gql`
  query getUserNameRegno($id: uuid) {
    global_users(where: {id: {_eq: $id}}) {
      id
      name
      people_work_placements {
        id
        regno
      }
    }
  }
`

export const GET_EMPLOYEE_LOAN = gql`
  query getEmployeeLoan($loanId: Int) {
    people_work_loans(where: {id: {_eq: $loanId}}) {
      id
      description
      flat_rate
      installment
      letter_num
      loan_amount
      loan_amount_w_interest
      loan_rate
      status
      remarks
    }
  }
`

export const GET_PAYMENT_PLAN = gql`
  query getPaymentPlan($loanId: Int) {
    people_work_loans(where: {id: {_eq: $loanId}}) {
      id
      amount_per_installment
      deducted_from_salary
      installment
      installment_name
      loan_amount_w_interest
      payment_date_start
    }
  }
`

export const GET_LOAN_INSTALLMENT = gql`
  query getLoanInstallment($loanId: Int) {
    people_work_installment(
      where: {loan: {_eq: $loanId}}
      order_by: [{actual_date: asc_nulls_last}]
    ) {
      id
      actual_date
      actual_payment
      interest_payment
      main_payment
      paid_in_period
      plan_amount
      plan_date
      status
    }
  }
`

export const GET_PAID_INSTALLMENT = gql`
  query getPaidInstallment($loanId: Int) {
    loan: people_work_loans(where: {id: {_eq: $loanId}}) {
      id
      installment_num: people_work_installments(
        where: {status: {_eq: "paid"}}
        limit: 1
      ) {
        id
        installment_num
      }
    }
  }
`
