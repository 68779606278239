import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'
import DetailComponent from './detail/DetailComponent'
import FormMicrolearning from './form/FormMicrolearningComponent'
import ListComponent from './list/ListComponent'

const MicroLearningPage = props => {
  const path = props.match.path
  const renderComponent = () => {
    switch (path) {
      case '/learning/micro-learning':
        return <ListComponent search={searchText} />
      case '/learning/micro-learning/add':
        return <FormMicrolearning />
      case '/learning/micro-learning/detail/:id':
        return <DetailComponent search={searchText} />
      case '/learning/micro-learning/edit/:id':
        return <FormMicrolearning />
      default:
        return <ListComponent search={searchText} />
    }
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }
  return (
    <Card
      icon="playlist_add_check"
      title="Pembelajaran Mikro"
      onSearch={onSearch}
    >
      <PageContainer style={{minHeight: '70vh'}}>
        {renderComponent()}
      </PageContainer>
    </Card>
  )
}

export default withRouter(MicroLearningPage)
