import React, {useState} from 'react'
import {useQuery} from '@apollo/react-hooks'

import {ButtonAddDataTab} from '../data-tab-shared'
import {GridListing} from '../../../DetailEmployeeStyles'
import LoadingEmployee from '../../../../LoadingEmployee'

import AssessmentAddEditDelete from './AssessmentAddEditDelete'
import AssessmentDetail from './AssessmentDetail'
import AssessmentItem from './AssessmentItem'

import {GET_ASSESSMENT_LIST} from '../../../../../../../graphql/queries'

const Assessment = ({userId}) => {
  const [modalDetail, setModalDetail] = useState({
    data: null,
    open: false,
  })

  const [modalAddEditDelete, setModalAddEditDelete] = useState({
    data: null,
    open: false,
    isEdit: false,
  })

  const {data, loading, refetch} = useQuery(GET_ASSESSMENT_LIST, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      userId,
    },
  })

  if (loading) {
    return <LoadingEmployee noborder smallpadding />
  }
  return (
    <>
      <GridListing>
        {data?.psychological_tests?.map(item => {
          return (
            <AssessmentItem
              key={item.id}
              data={item}
              onDetails={data => setModalDetail({open: true, data: data})}
              onEdit={data =>
                setModalAddEditDelete({
                  open: true,
                  data: data,
                  isEdit: true,
                })
              }
            />
          )
        })}
      </GridListing>

      <ButtonAddDataTab
        onClick={() =>
          setModalAddEditDelete({open: true, data: null, isEdit: false})
        }
      >
        Tambah Asesmen
      </ButtonAddDataTab>

      <AssessmentAddEditDelete
        {...modalAddEditDelete}
        refetch={refetch}
        userId={userId}
        onClose={() =>
          setModalAddEditDelete({open: false, data: null, isEdit: false})
        }
      />

      <AssessmentDetail
        {...modalDetail}
        onClose={() => setModalDetail({open: false, data: null})}
      />
    </>
  )
}

export default Assessment
