import gql from 'graphql-tag'

export const ADD_MEMBER_CROSS_MENTORING_CLASSROOM = gql`
  mutation($classroomMembers: [classroom_members_insert_input!]!) {
    insert_classroom_members(objects: $classroomMembers) {
      affected_rows
      returning {
        id
      }
    }
  }
`
