import gql from 'graphql-tag'

export const UPDATE_PREMIUM_SETTING = gql`
  mutation(
    $id: Int!
    $name: String
    $insurance: Int #ambil dari global_insurane_list
    $premium_fee: float8
    $company_portion: float8
    $employee_portion: float8
    $description: String
    $age_expire: Int
    $age_beneficiaries: Int
    $premium_fields: jsonb
    $effective_date: date
    $assignments: [finance_premium_assignments_insert_input!]! #fill with id from people_work_placements/assignee candidate
  ) {
    update_finance_insurance_premiums_by_pk(
      pk_columns: {id: $id}
      _set: {
        insurance_company: $insurance
        premium_name: $name
        premium_fee: $premium_fee
        company_portion: $company_portion
        employee_portion: $employee_portion
        description: $description
        effective_date: $effective_date
        age_expire: $age_expire
        age_beneficiaries: $age_beneficiaries
        premium_fields: $premium_fields
      }
    ) {
      id
    }

    delete_finance_premium_assignments(where: {premium_id: {_eq: $id}}) {
      affected_rows
    }

    insert_finance_premium_assignments(objects: $assignments) {
      affected_rows
    }
  }
`
