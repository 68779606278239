import React, {useState} from 'react'
import helperStyle, {
  OrganizationContainer,
  FlexBetween,
  TextBlueBold,
  TextDarkBlue,
  LitleGreyText,
  TextBlackBold,
} from '../OfficePageStyles'
import {
  AddEditTitle,
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  ButtonBlue,
  ButtonWhiteRed,
  FormContainer,
} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {MapMarker} from 'mdi-material-ui'
import {useSnackbar} from 'notistack'
import {IconButton, Divider, FormControlLabel, Switch} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import Geocode from 'react-geocode'
import classNames from 'classnames'
import {COMPANY_ID} from '../../../../utils/globals'

import {useMutation} from '@apollo/react-hooks'
import {
  SET_MAIN_OFFICE,
  DELETE_LIST_OFFICE,
  SET_ACTIVE_INACTIVE_OFFICE,
} from '../../../../graphql/mutations'

import GoogleMapReact from 'google-map-react'
import AlertPopup from '../../../shared-components/popup/ResultPopup'
import DeletePopup from '../../../shared-components/popup/DeletePopup'

const DetailOffice = ({dataOffice, dataMain, history, dataCheck}) => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()

  const [openActiveOffice, setOpenActiveOffice] = useState(false)
  const [openDeleteOffice, setOpenDeleteOffice] = useState(false)
  const [openDeleteFailJob, setOpenDeleteFailJob] = useState(false)
  const [openDeleteFailMain, setOpenDeleteFailMain] = useState(false)
  const [openMainFailInactive, setOpenMainFailInactive] = useState(false)
  const [openActiveFailMain, setOpenActiveFailMain] = useState(false)

  const dataCordinate = dataOffice
    ? (dataOffice &&
        dataOffice.address_field &&
        dataOffice.address_field.lat === '') ||
      (dataOffice &&
        dataOffice.address_field &&
        dataOffice.address_field.lng === '')
      ? {
          lat: -6.9174639,
          lng: 107.61912280000001,
        }
      : {
          lat:
            dataOffice &&
            dataOffice.address_field &&
            dataOffice.address_field.lat,
          lng:
            dataOffice &&
            dataOffice.address_field &&
            dataOffice.address_field.lng,
        }
    : {
        lat: -6.9174639,
        lng: 107.61912280000001,
      }

  const [latitude, setLatitude] = useState(dataCordinate.lat)
  const [longitude, setLongitude] = useState(dataCordinate.lng)
  const [isMapLoaded, setMapLoaded] = useState(false)
  const [switchChecked, setSwitchChecked] = useState(
    dataOffice.status === 'ACTIVE'
  )

  const status = switchChecked ? 'Inactive' : 'Active'

  const idOffice = dataOffice && dataOffice.id
  const idMainOffice =
    dataMain[0] && dataMain[0].company_address && dataMain[0].company_address.id

  const handleDeleteDialog = () => {
    const hasJobProfile =
      dataOffice &&
      dataOffice.company_job_profiles_aggregate &&
      dataOffice.company_job_profiles_aggregate.aggregate &&
      dataOffice.company_job_profiles_aggregate.aggregate.count > 0

    const isMainOffice = idOffice === idMainOffice

    if (isMainOffice) {
      setOpenDeleteFailMain(true)
    } else if (hasJobProfile) {
      setOpenDeleteFailJob(true)
    } else {
      setOpenDeleteOffice(true)
    }
  }

  const handleApiLoaded = maps => {
    window.google = {maps}
    if (isMapLoaded === false) setMapLoaded(true)
  }

  const handleClickMap = e => {
    Geocode.setApiKey('AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ')
    Geocode.fromLatLng(e.lat, e.lng).then(
      () => {
        setLatitude(e.lat)
        setLongitude(e.lng)
      },
      () => {}
    )
  }

  const [main, setMain] = useState(idOffice === idMainOffice)

  const [setMainOffice] = useMutation(SET_MAIN_OFFICE)
  const [deleteListOffice] = useMutation(DELETE_LIST_OFFICE)
  const [setActiveInactiveOffice] = useMutation(SET_ACTIVE_INACTIVE_OFFICE)

  const handleSetActiveInactiveOffice = () => {
    const status = dataOffice.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    const nextStatus = !switchChecked

    const variable = {
      id: dataOffice.id,
      status: status,
    }
    setActiveInactiveOffice({variables: variable})
      .then(() => {
        enqueueSnackbar(`Set ${status} Office success`, {
          variant: 'success',
          autoHideDuration: 1000,
        })
        setSwitchChecked(nextStatus)
      })
      .catch(() => {
        enqueueSnackbar(`Set ${status} Office failed, please try again later`, {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })

    setOpenActiveOffice(false)
  }

  const handleDelete = () => {
    const variable = {
      id: dataOffice && dataOffice.id,
    }

    deleteListOffice({variables: variable})
      .then(() => {
        enqueueSnackbar('Office deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        history.push({
          pathname: '/company/office',
          state: {active: 'office', searchable: true},
        })
      })
      .catch(() => {
        enqueueSnackbar('Delete Office failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleSetMain = () => {
    if (!switchChecked) {
      setOpenMainFailInactive(true)
      return
    }

    const variable = {
      address: dataOffice && dataOffice.id,
      company: COMPANY_ID,
    }

    setMainOffice({variables: variable})
      .then(() => {
        enqueueSnackbar('Set Main Office success', {
          variant: 'success',
          autoHideDuration: 1000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Set Main Office failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })

    setMain(true)
  }

  return (
    <OrganizationContainer>
      <PaperContainer
        className={classNames(classes.heightAuto, classes.overflowYNone)}
      >
        <FormToolbar disableGutters>
          <FormTitleContainer>
            <Link
              to={{
                pathname: '/company/office',
                state: {active: 'office', searchable: true},
              }}
            >
              <IconButton edge="end" aria-label="back">
                <ArrowBack />
              </IconButton>
            </Link>
            <AddEditTitle variant="h6" display="inline">
              {dataOffice && dataOffice.office_name}
            </AddEditTitle>
          </FormTitleContainer>
          <div>
            <ButtonBlue
              size="medium"
              style={{marginRight: 15}}
              component={Link}
              to={{
                pathname: `/company/office/edit`,
                state: {
                  active: 'edit-office',
                  dataOffice: dataOffice,
                  dataMain: dataMain,
                  dataCheck: dataCheck,
                },
              }}
            >
              Edit
            </ButtonBlue>
            <ButtonWhiteRed onClick={handleDeleteDialog} size="medium">
              Delete
            </ButtonWhiteRed>
          </div>
        </FormToolbar>
        <Divider />
        <FormContainer className={classes.marginX11}>
          <FlexBetween>
            <OrganizationContainer className={classes.width40P}>
              <FlexBetween>
                <AddEditTitle
                  className={classes.width70P}
                  variant="h6"
                  display="inline"
                >
                  {dataOffice && dataOffice.office_name}
                </AddEditTitle>
                {idMainOffice !== idOffice && !main && (
                  <TextBlueBold
                    className={classNames(classes.cursorNone, classes.width30P)}
                    onClick={handleSetMain}
                  >
                    Set as main office
                  </TextBlueBold>
                )}
              </FlexBetween>
              <OrganizationContainer className={classes.marginLeft1R}>
                {idOffice === idMainOffice || main ? (
                  <TextDarkBlue>Main Office</TextDarkBlue>
                ) : (
                  ''
                )}
                <LitleGreyText className={classes.marginTop10}>{`${dataOffice &&
                  dataOffice.global_province &&
                  dataOffice.global_province.name} - ${dataOffice &&
                  dataOffice.global_city &&
                  dataOffice.global_city.name}`}</LitleGreyText>
                <LitleGreyText className={classes.marginTop10}>
                  {dataOffice && dataOffice.postal_code}
                </LitleGreyText>
                <TextBlackBold className={classes.marginTop10}>
                  {dataOffice && dataOffice.address}
                </TextBlackBold>
              </OrganizationContainer>
              <Divider className={classes.marginY15} />
              <AddEditTitle
                className={classes.width70P}
                variant="h6"
                display="inline"
              >
                Office Status
              </AddEditTitle>
              <FlexBetween
                className={classNames(
                  classes.marginTop10,
                  classes.marginLeft1R
                )}
              >
                <TextBlackBold>
                  {switchChecked ? 'Active' : 'Inactive'}
                </TextBlackBold>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchChecked}
                      onChange={() => {
                        if (switchChecked && dataOffice.id === idMainOffice) {
                          setOpenActiveFailMain(true)
                        } else {
                          setOpenActiveOffice(true)
                        }
                      }}
                      color="primary"
                    />
                  }
                />
              </FlexBetween>
              {/* <Divider className={classes.marginY15} />
              <FlexBetween>
                <AddEditTitle
                  className={classes.width70P}
                  variant="h6"
                  display="inline"
                >
                  Tax and Inssurance
                </AddEditTitle>
                <LitleGreyText>
                  {dataOffice && dataOffice.npwp_regdate
                    ? dataOffice.npwp_regdate
                    : '-'}
                </LitleGreyText>
              </FlexBetween> */}
              {/* <OrganizationContainer className={classes.marginLeft1R}> */}
              {/* <TextDarkBlue>
                  {dataOffice && dataOffice.npwp_number}
                </TextDarkBlue>
                <TextGreyBold
                  className={classes.marginTop15}
                >{`With holding Tax Name`}</TextGreyBold>
                <TextBlackBold className={classes.marginTop10}>
                  {dataOffice && dataOffice.npwp_responsible_name
                    ? dataOffice.npwp_responsible_name
                    : '-'}
                </TextBlackBold> */}
              {/* <TextGreyBold
                  className={classes.marginTop15}
                >{`BPJS Ketenagakerjaan Register Number`}</TextGreyBold>
                <TextBlackBold className={classes.marginTop10}>
                  {dataOffice && dataOffice.bpjs_tk_regno}
                </TextBlackBold>
                <TextGreyBold
                  className={classes.marginTop15}
                >{`BPJS Kesehatan Register Number`}</TextGreyBold>
                <TextBlackBold className={classes.marginTop10}>
                  {dataOffice && dataOffice.bpjs_kes_regno}
                </TextBlackBold> */}
              {/* </OrganizationContainer> */}
            </OrganizationContainer>
            <OrganizationContainer
              className={classNames(classes.width55P, classes.height350)}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ',
                  libraries: 'places',
                }}
                center={{
                  lat: latitude,
                  lng: longitude,
                }}
                defaultZoom={16}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({maps}) => handleApiLoaded(maps)}
                onClick={e => handleClickMap(e)}
              >
                <MapMarker
                  style={{color: '#014a62'}}
                  lat={latitude}
                  lng={longitude}
                  title={
                    <React.Fragment>
                      address
                      <span />
                    </React.Fragment>
                  }
                />
              </GoogleMapReact>
            </OrganizationContainer>
          </FlexBetween>
        </FormContainer>
      </PaperContainer>
      <DeletePopup
        open={openDeleteOffice}
        handleClose={() => setOpenDeleteOffice(false)}
        name={dataOffice.office_name}
        mutation={handleDelete}
      />
      <AlertPopup
        open={openActiveOffice}
        handleClose={() => setOpenActiveOffice(false)}
        feature={`Set Office ${status}`}
        message={
          status === 'Active'
            ? 'Are you sure you want to set office status become active? If you set office status become active it will be seen on user although it will not change functional the office on job profile and placement'
            : 'Are you sure you want to set office status become inactive? If you set office status become inactive it will not be seen on user although it will not change the functional office on job profile and placement'
        }
        type="Confirm"
        status="cancel"
        mutation={handleSetActiveInactiveOffice}
      />
      <AlertPopup
        open={openDeleteFailJob}
        handleClose={() => setOpenDeleteFailJob(false)}
        feature={`Unable to Delete ${dataOffice.office_name}`}
        name={dataOffice.office_name}
        fMessage="You cannot delete "
        lMessage=" because it is being used on job profile."
        type="Confirm"
      />
      <AlertPopup
        open={openDeleteFailMain}
        handleClose={() => setOpenDeleteFailMain(false)}
        feature={`Unable to Delete ${dataOffice.office_name}`}
        name={dataOffice.office_name}
        fMessage="You cannot delete "
        lMessage=" because this office is the main office in this company."
        type="Confirm"
      />
      <AlertPopup
        open={openMainFailInactive}
        handleClose={() => setOpenMainFailInactive(false)}
        feature="Cannot Set as Main Office"
        name={dataOffice.office_name}
        fMessage="Cannot set "
        lMessage=" as the main office. Please change office status to active first."
        type="Confirm"
      />
      <AlertPopup
        open={openActiveFailMain}
        handleClose={() => setOpenActiveFailMain(false)}
        feature="Cannot Change Status to Inactive"
        name={dataOffice.office_name}
        fMessage="Cannot Change Status "
        lMessage=" to Inactive because this office is the main office in this company."
        type="Confirm"
      />
    </OrganizationContainer>
  )
}

export default withRouter(DetailOffice)
