import React from 'react'
import {Flex} from '../../../../GlobalStyles'
import {TextContentTitle, TextContent} from '../CompetencyPageStyles'

const DetailInfoComponent = ({data}) => {
  return (
    <div
      style={{
        padding: '1.6rem',
      }}
    >
      <Flex column style={{gap: '15px'}}>
        <Flex style={{gap: '10px'}}>
          <TextContentTitle>Competency Category</TextContentTitle>
          <div>:</div>
          <TextContent>{data?.competency_category?.name}</TextContent>
        </Flex>
        <Flex style={{gap: '10px'}}>
          <TextContentTitle>Competency Code</TextContentTitle>
          <div>:</div>
          <TextContent>{data?.dictionary_id}</TextContent>
        </Flex>
        <Flex style={{gap: '10px'}}>
          <TextContentTitle>Competency name</TextContentTitle>
          <div>:</div>
          <TextContent>{data?.name?.en}</TextContent>
        </Flex>
        <Flex style={{gap: '10px'}}>
          <TextContentTitle>Type</TextContentTitle>
          <div>:</div>
          <TextContent style={{textTransform: 'capitalize'}}>
            {data?.type}
          </TextContent>
        </Flex>
        <Flex style={{gap: '10px'}}>
          <TextContentTitle>Description</TextContentTitle>
          <div>:</div>
          <TextContent>{data?.description?.text}</TextContent>
        </Flex>
      </Flex>
    </div>
  )
}

export default DetailInfoComponent
