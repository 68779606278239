import {useMutation, useQuery} from '@apollo/react-hooks'
import {useState} from 'react'
import {
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
} from '../graphql/mutations/export/export.mutation'

import {
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../graphql/queries'
import {COMPANY_ID} from '../utils/globals'
import {isDisableButtonExport} from '../utils/helpers'

export default ({table, filter = undefined}) => {
  const [dataBaloon, setDataBaloon] = useState(undefined)
  const [dataButton, setDataButton] = useState(undefined)
  const [isDisableButton, setIsDisableButton] = useState(true)
  const [skip, setSkip] = useState({
    baloon: false,
    button: false,
  })

  const [downloadData] = useMutation(EXPORT_MUTATION)

  const [closeBaloon] = useMutation(CLOSE_BALOON_EXPORT)

  useQuery(GET_STATUS_EXPORT_BALOON, {
    variables: {
      company: COMPANY_ID,
      table,
      filter,
    },
    skip: skip.baloon,
    onCompleted: data => {
      if (data && data.company_export_data_logs[0]) {
        setDataBaloon(data)
      } else {
        setSkip(e => ({...e, baloon: true}))
      }
    },
  })

  const {loading: loadingButton} = useQuery(GET_EXPORT_DISABLED_BUTTON, {
    skip: skip.button,
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table,
      filter,
    },
    onCompleted: data => {
      if (data && data.company_export_data_logs[0]) {
        const {date_added} = data.company_export_data_logs[0]
        setDataButton(data)
        if (isDisableButtonExport(date_added)) {
          setIsDisableButton(true)
          setSkip(e => ({...e, button: true}))
        } else {
          setIsDisableButton(false)
        }
      } else {
        setIsDisableButton(false)
      }
    },
  })

  const onDownload = props => {
    downloadData(props).then(() => {
      setSkip(e => ({...e, baloon: false, button: false}))
      setIsDisableButton(true)
    })
  }

  const onCloseBaloon = async () => {
    try {
      const baloonId =
        dataBaloon.company_export_data_logs.length > 0 &&
        dataBaloon.company_export_data_logs[0].id

      await closeBaloon({
        variables: {
          id: baloonId,
        },
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsDisableButton(false)
    }
  }

  return {
    dataBaloon,
    dataButton,
    isDisableButton,
    loadingButton,
    onDownload,
    onCloseBaloon,
  }
}
