import gql from 'graphql-tag'

export const GET_HISTORY_PEOPLE_WORK_SALARY = gql`
  query getHistoryPeopleWorkSalary(
    $user_id: uuid
    $company_id: uuid
    $limit: Int
  ) {
    total: people_work_salaries_aggregate(
      where: {
        people_work_placement: {
          user: {_eq: $user_id}
          company: {_eq: $company_id}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_salaries(
      where: {
        people_work_placement: {
          user: {_eq: $user_id}
          company: {_eq: $company_id}
        }
      }
      order_by: {date_added: desc_nulls_last}
      limit: $limit
    ) {
      id
      currency
      salary_fields
      value
      date_added
      start_date
      end_date
      people_profile_bank {
        id
        account_name
        account_number
        bank_name
      }
    }
  }
`

export const GET_BANK_PEOPLE_WORK_SALARY = gql`
  query getBankPeopleWorkSalary($user_id: uuid, $company_id: uuid) {
    v_latest_placements(
      where: {
        company: {_eq: $company_id}
        people_work_placement: {
          user: {_eq: $user_id}
          deletedAt: {_is_null: true}
        }
      }
    ) {
      people_work_placement {
        id
        global_user {
          id
          name
        }
        people_work_salaries(order_by: [{id: desc}], limit: 2) {
          id
          currency
          salary_fields
          value
          ktp
          npwp
          start_date
          end_date
          people_profile_bank {
            id
            creator_company
            account_name
            account_number
            bank_name
            bank
          }
        }
      }
    }
  }
`

export const GET_PEOPLE_PROFILE_IDENTITIES = gql`
  query GetPeopleProfileIdentities($user_id: uuid, $type: String) {
    people_identities(
      where: {
        type: {_eq: $type}
        user: {_eq: $user_id}
        deletedAt: {_is_null: true}
      }
      distinct_on: type
      order_by: [{type: asc}, {date_added: desc_nulls_last}]
    ) {
      type
      id
    }
  }
`

export const GET_PEOPLE_PROFILE_KTP_NPWP = gql`
  query GetPeopleProfileKtpNpwp($user_id: uuid) {
    people_identities(
      where: {type: {_in: ["KTP", "NPWP"]}, user: {_eq: $user_id}}
      distinct_on: type
      order_by: [{type: asc}, {date_added: desc_nulls_last}]
    ) {
      type
      id
    }
  }
`

export const GET_TOTAL_USED_KTP_NPWP = gql`
  query GetUsedKtpNpwp($user_id: uuid, $ktp: String, $npwp: String) {
    total_ktp: people_identities_aggregate(
      where: {user: {_neq: $user_id}, type: {_eq: "KTP"}, id: {_eq: $ktp}}
    ) {
      aggregate {
        count
      }
    }
    total_npwp: people_identities_aggregate(
      where: {user: {_neq: $user_id}, type: {_eq: "NPWP"}, id: {_eq: $npwp}}
    ) {
      aggregate {
        count
      }
    }
  }
`
