import React, {Component} from 'react'
import * as Sentry from '@sentry/browser'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {error: null, errorInfo: null}
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    Sentry.captureException(error)
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <>
          <h2 style={{margin: 20}}>
            Please refresh the page and try again, if problem occurred please
            contact our administration
          </h2>

          {process.env.NODE_ENV === 'development' && (
            <button
              onClick={() => this.setState({error: null, errorInfo: null})}
            >
              reset
            </button>
          )}
        </>
      )
    }

    return this.props.children
  }
}
