import gql from 'graphql-tag'

export const GET_SURVEY_PREVIEW_CONTENT = gql`
  query($survey: bigint!) {
    multirater_survey_questions(
      where: {multirater_survey_group: {survey: {_eq: $survey}}}
      order_by: [{multirater_survey_group: {number: asc}}, {number: asc}]
    ) {
      id
      number
      question_self
      question_others
      multirater_survey_group {
        id
        number
        name
        isnps
        multirater_survey {
          id
          multirater_scale {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_SURVEY_PREVIEW_SIDEBAR = gql`
  query($survey: bigint!) {
    multirater_surveys(where: {id: {_eq: $survey}}) {
      id
      title
      enddate
      status
    }
    multirater_survey_groups(
      where: {survey: {_eq: $survey}}
      order_by: [{number: asc}]
    ) {
      id
      isnps
    }
  }
`
