import gql from 'graphql-tag'

export const GET_DEVELOPMENT_PLAN_LIST = gql`
  query($name: String, $offset: Int, $limit: Int, $company: uuid) {
    total: people_work_placements_aggregate(
      where: {
        global_user: {name: {_ilike: $name}}
        company_job_profile: {company: {_eq: $company}}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        global_user: {name: {_ilike: $name}}
        company_job_profile: {company: {_eq: $company}}
      }
      order_by: {date_added: asc}
      offset: $offset
      limit: $limit
    ) {
      id
      global_user {
        id
        name
      }
      company_job_profile {
        id
        title
        organization
        company
      }
      talent_assignments {
        id
        talent_score
        talent_box {
          id
          name
          badge
        }
      }
      talent_development_plans {
        id
        name
        measure_of_success
        recommended_action
        status
      }
    }
  }
`

export const GET_DEVELOPMENT_PLAN_DETAIL = gql`
  query($company: uuid!, $id: uuid!) {
    people_work_placements(
      where: {
        global_user: {id: {_eq: $id}}
        company_job_profile: {company: {_eq: $company}}
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        company
        title
        organization
      }
      talent_development_plans {
        id
        name
        status
        measure_of_success
        recommended_action
        talent_development_competencies {
          id
          competency
        }
      }
    }
  }
`
