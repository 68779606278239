import React, {useState, useEffect} from 'react'
import {EnhancedToolbar} from '../../../../GlobalStyles'
import DialogDetail from './DialogBadgeUser'
import {
  Typography,
  Divider,
  makeStyles,
  Paper,
  TextField,
  MenuItem,
  Avatar,
  capitalize,
} from '@material-ui/core'
import NineBoxes from './NineBoxes'
const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  input: {
    marginRight: '1rem',
    width: '158px',
  },
  divAvatar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divName: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
  },
  divBadge: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '2rem',
    marginBottom: '1rem',
  },
  divRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 24,
  },
  divScore: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  boxes: {
    width: '55%',
    marginTop: 24,
  },
  score: {
    marginLeft: '1rem',
  },
  position: {
    color: '#a9a8a8',
  },
  seeAll: {
    float: 'right',
    marginTop: 12,
    cursor: 'pointer',
    color: '#039be5',
  },
  talentListContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    width: '45%',
    marginTop: 24,
    position: 'relative',
  },
}))
const BoxesMatrix = ({
  dataBoxes,
  dataEmployee,
  newHeight,
  newWidth,
  setCondList,
  condList,
  // organization,
  // office,
  position,
}) => {
  const classes = useStyles()
  // const [indcator, setIndcator] = useState({
  //   nps: 0,
  //   ogf: 0,
  //   goal: 0,
  //   multirater: 0,
  //   competency: 0,
  //   attendance: 0,
  // })
  const [open, setOpen] = useState({open: false, data: null})
  const [employee, setEmployee] = useState(null)

  const [detail, setDetail] = useState(null)
  const [all, setAll] = useState(false)

  useEffect(() => {
    setEmployee(dataEmployee)
  }, [dataEmployee])

  const changeFilters = name => e => {
    setCondList({...condList, [name]: e.target.value})
  }
  const handleOpen = (res, type) => {
    if (type === 'all') {
      setOpen({open: true, data: res})
    } else {
      setDetail(res)
      setOpen({open: true, data: res})
    }
  }
  const handleClose = () => {
    setDetail(null)
    setAll(false)
    setOpen({open: false, data: null})
  }
  // const handleChangeBoxes = res => {
  //   setIndcator({
  //     nps: res.nps,
  //     ogf: res.ogf,
  //     goal: res.goal,
  //     multirater: res.multirater,
  //     competency: res.competency,
  //     attendance: res.attendance,
  //   })
  // }

  const handleChangeBage = id => {
    setCondList({...condList, badge: condList.badge === id ? null : id})
  }

  return (
    <div className={classes.root}>
      <Paper>
        <EnhancedToolbar>
          <Typography variant="h6" color="primary">
            Talent Boxes Matrix
          </Typography>
          <div
            style={{display: 'flex', marginTop: '1rem', marginBottom: '1rem'}}
          >
            {/* <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Organization"
              value={condList.organization}
              onChange={changeFilters('organization')}
            >
              <MenuItem value="all">All Organization</MenuItem>
              {organization &&
                organization.company_organizations.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField> */}
            {/* <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Office Location"
              value={condList.office}
              onChange={changeFilters('office')}
            >
              <MenuItem value="all">All Office</MenuItem>
              {office &&
                office.company_addresses.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.office_name}
                  </MenuItem>
                ))}
            </TextField> */}
            <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Position"
              value={condList.jobProfile}
              onChange={changeFilters('jobProfile')}
            >
              <MenuItem value="all">All Position</MenuItem>
              {position &&
                position.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </EnhancedToolbar>
        <Divider />
        <div className={classes.divRoot}>
          <div className={classes.boxes}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  height: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  width: '5rem',
                }}
              >
                <div>
                  <Typography
                    style={{
                      color: '#707070',
                      fontSize: 12,
                    }}
                  >
                    {dataBoxes &&
                      dataBoxes.talent_versions[0].flexy_components &&
                      dataBoxes.talent_versions[0].flexy_components.y.name}
                  </Typography>
                  <Typography
                    style={{
                      color: '#707070',
                      fontSize: 12,
                    }}
                  >
                    (
                    {dataBoxes &&
                      dataBoxes.talent_versions[0].flexy_components &&
                      dataBoxes.talent_versions[0].flexy_components.y.parameters.map(
                        (res, i) =>
                          (res.parameter === 'ogf'
                            ? 'Ongoing Feedback'
                            : res.parameter === 'socmed_post'
                            ? 'Social Media Post'
                            : res.parameter === 'nps'
                            ? 'NPS'
                            : capitalize(res.parameter)) +
                          (i + 1 !==
                          dataBoxes.talent_versions[0].flexy_components.y
                            .parameters.length
                            ? ', '
                            : '')
                      )}
                    )
                  </Typography>
                </div>
              </div>
              <NineBoxes
                newHeight={newHeight}
                newWidth={newWidth}
                litle={false}
                condList={condList}
                // indicator={indcator}
                data={dataBoxes && dataBoxes.talent_versions[0].talent_boxes}
                handleChangeBage={handleChangeBage}
              />
              <div style={{width: '10%', height: 'auto'}} />
            </div>
            <Typography
              variant="body2"
              style={{textAlign: 'center', color: '#707070', fontSize: 12}}
            >
              {dataBoxes &&
                dataBoxes.talent_versions[0].flexy_components &&
                dataBoxes.talent_versions[0].flexy_components.x.name}{' '}
              ({' '}
              {dataBoxes &&
                dataBoxes.talent_versions[0].flexy_components &&
                dataBoxes.talent_versions[0].flexy_components.x.parameters.map(
                  (res, i) =>
                    (res.parameter === 'ogf'
                      ? 'Ongoing Feedback'
                      : res.parameter === 'socmed_post'
                      ? 'Social Media Post'
                      : res.parameter === 'nps'
                      ? 'NPS'
                      : capitalize(res.parameter)) +
                    (i + 1 !==
                    dataBoxes.talent_versions[0].flexy_components.x.parameters
                      .length
                      ? ', '
                      : ' ')
                )}
              )
            </Typography>
          </div>
          <Divider
            orientation="vertical"
            style={{
              height: 'auto',
              alignItems: 'center',
            }}
          />
          <div className={classes.talentListContainer}>
            {employee &&
              employee.talent_assignments.slice(0, 5).map((res, i) => {
                return (
                  <div key={i} className={classes.divBadge}>
                    <div className={classes.divAvatar}>
                      <Avatar
                        src={res.people_work_placement.global_user.avatar}
                      />
                      <div className={classes.divName}>
                        <Typography
                          variant="body1"
                          onClick={() => handleOpen(res, 'detail')}
                          style={{cursor: 'pointer'}}
                        >
                          {res.people_work_placement.global_user.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.position}
                        >
                          {res.people_work_placement.company_job_profile.title}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.divScore}>
                      <img
                        src={res.talent_box.badge}
                        alt={res.talent_box.name}
                        style={{width: '2vw'}}
                      />
                      <Typography variant="body1" className={classes.score}>
                        {res.talent_score.toFixed(2)}
                      </Typography>
                    </div>
                  </div>
                )
              })}
            {employee && employee.talent_assignments.length > 5 && (
              <Typography
                variant="body1"
                className={classes.seeAll}
                onClick={() => handleOpen(employee, 'all')}
              >
                See All
              </Typography>
            )}
          </div>
        </div>
      </Paper>
      <DialogDetail
        open={open.open}
        handleClose={handleClose}
        data={open.data}
        setDetail={setDetail}
        detail={detail}
        all={all}
        setAll={setAll}
      />
    </div>
  )
}
export default BoxesMatrix
