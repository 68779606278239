import CompanySummaryPage from './CompanySummaryPageComponent'

export const CompanySummaryPageConfig = {
  routes: [
    {
      path: '/holding-performance/company-summary',
      component: CompanySummaryPage,
      state: 'company-summary',
      exact: true,
    },
    {
      path: '/holding-performance/company-summary/detail/:idCompany',
      component: CompanySummaryPage,
      state: 'company-summary-detail',
      exact: true,
    },
  ],
}
