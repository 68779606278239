import {Button, Divider, TablePagination} from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import {
  RootContainer,
  PaperContainer,
  EnhancedToolbar,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import {COMPANY_ID, DEFAULT_PAGINATION} from '../../../../utils/globals'
import {dateFormat, differenceDate} from '../../../../utils/helpers'
import {TablePaginationActions} from '../../../shared-components/table/TableOptions'
import NoDataListFree from '../../../shared-components/NoDataListFreeComponent'
import PopupWLB from '../../../shared-components/popup/PopupWLB'
import {BigTextBlackBold, WrapperContent} from '../../OrganizationPageStyles'
import {BodyContent} from '../HistoryStyles'
import HistoryItem from './HistoryItem'
import Skeleton from '@material-ui/lab/Skeleton'
import {ModalGenerateHistory} from './ModalGenerateHistory'
import {useQuery} from '@apollo/react-hooks'
import {GET_LIST_HISTORY} from '../../../../graphql/queries'
import useDownloadReport from '../../../../hooks/useDownloadReport'
import ExportBaloonFree from '../../../../components/exportExcel/ExportBaloonFree'

const TEXT_TOOLTIP =
  'Anda hanya dapat menghasilkan data sebulan sekali. Untuk mengunduh data, gunakan yang sudah dibuat.'
const TABLE_NAME = 'organization_history'
const DURATION = 30

export default function ListHistory({searchText}) {
  const [isShowBaloon, setIsShowBaloon] = useState(true)
  const [open, setOpen] = useState(false)
  const [isDisableButton, setIsDisableButton] = useState(true)
  const [openNotification, setOpenNotification] = useState(false)
  const [pagination, setPagination] = useState({
    limit: DEFAULT_PAGINATION[0],
    offset: 0,
  })

  const {
    dataBaloon,
    dataButton,
    loadingButton,
    onDownload,
    onCloseBaloon,
  } = useDownloadReport({table: TABLE_NAME})

  useEffect(() => {
    if (!loadingButton) {
      if (dataButton?.company_export_data_logs?.[0]) {
        const isDisable =
          differenceDate(
            dataButton?.company_export_data_logs?.[0]?.date_added,
            'days'
          ) < DURATION
        setIsDisableButton(isDisable)
      } else {
        setIsDisableButton(false)
      }
    }
  }, [dataButton, differenceDate, loadingButton])

  const {data, error, loading} = useQuery(GET_LIST_HISTORY, {
    variables: {
      company: COMPANY_ID,
      search: `%${searchText}%`,
      limit: pagination.limit,
      offset: pagination.offset * pagination.limit,
    },
  })

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPagination(e => ({...e, offset: newPage}))
  }

  const handleChangeRowsPerPage = event => {
    setPagination(e => ({
      ...e,
      offset: 0,
      limit: parseInt(event.target.value, 10),
    }))
  }

  const handleGenerate = async values => {
    const variables = {
      table: TABLE_NAME,
      filter: {
        name: values.name,
        start_date: dateFormat(values.startDate, 'YYYY-MM-DD'),
        end_date: dateFormat(values.endDate, 'YYYY-MM-DD'),
      },
    }

    await onDownload({
      variables,
    })

    setOpen(false)
    setOpenNotification(true)
  }

  if (error) {
    return JSON.stringify(error)
  }

  return (
    <>
      <RootContainer>
        {isShowBaloon && (
          <ExportBaloonFree
            data={dataBaloon}
            retry={() => setOpen(true)}
            waitingMessage="Menghasilkan Data Sedang Diproses!, Kami akan mengirimkan email kepada Anda jika sudah siap."
            completeMessage="Menghasilkan data selesai! Anda dapat mengunduh pada riwayat daftar di bawah ini"
            titleErrorPopup="Menghasilkan riwayat"
            statusAllowToClose={['GAGAL', 'SELESAI']}
            closeBaloonMutation={() => {
              setIsShowBaloon(false)
              onCloseBaloon()
            }}
            errorMessage={openPopup => {
              return (
                <>
                  Generate data has failed!{' '}
                  <span
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: '#ef4d5e',
                      fontWeight: 'bold',
                      marginLeft: 8,
                    }}
                    onClick={() => openPopup(true)}
                  >
                    See the problem
                  </span>
                </>
              )
            }}
          />
        )}
        <PaperContainer>
          <EnhancedToolbar>
            <BigTextBlackBold>Riwayat</BigTextBlackBold>
            <WrapperContent>
              <GenerateTooltip
                title={isDisableButton ? TEXT_TOOLTIP : ``}
                placement="left"
              >
                <span>
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => setOpen(true)}
                    disabled={isDisableButton}
                  >
                    Menghasilkan riwayat
                  </Button>
                </span>
              </GenerateTooltip>
            </WrapperContent>
          </EnhancedToolbar>
          <Divider />
          <BodyContent>
            {!loading &&
              data?.company_archived_logs?.map(res => (
                <HistoryItem
                  key={res.id}
                  title={res.name}
                  period={`${dateFormat(
                    res.start_date,
                    'MMMM DD, YYYY'
                  )} - ${dateFormat(res.end_date, 'MMMM DD, YYYY')}`}
                  generateDate={dateFormat(res.created_at, 'DD MMMM YYYY')}
                  url={res.url_file}
                />
              ))}
            {loading &&
              Array.from({length: 4}, (_, i) => (
                <Skeleton
                  key={`${i}-${_}`}
                  className="skeleton"
                  variant="rect"
                  width="100%"
                  height={114}
                />
              ))}
            {!loading && !data?.company_archived_logs?.[0] && (
              <NoDataListFree
                message1={
                  searchText
                    ? 'Maaf, Tidak ada di List'
                    : 'Maaf, Tidak ada Data'
                }
                message2={
                  searchText
                    ? 'Tampaknya kami tidak dapat menemukan hasil apa pun berdasarkan penelusuran Anda'
                    : 'Harap buat riwayat terlebih dahulu'
                }
              />
            )}
          </BodyContent>
          <Divider />
          <TablePagination
            rowsPerPageOptions={DEFAULT_PAGINATION}
            component="div"
            count={data?.total?.aggregate?.count || 0}
            rowsPerPage={pagination.limit}
            page={pagination.offset}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelRowsPerPage="Baris Per halaman"
            backIconButtonText="halaman sebelumnya"
            nextIconButtonText="halaman berikutnya"
            labelDisplayedRows={({from, to, count}) =>
              `${from} - ${to} dari ${count}`
            }
          />
        </PaperContainer>
      </RootContainer>

      <ModalGenerateHistory
        onGenerate={handleGenerate}
        open={open}
        onClose={() => setOpen(false)}
      />

      <PopupWLB
        onClose={() => setOpenNotification(false)}
        open={openNotification}
        title="Menghasilkan Data sedang Diproses!"
        content="Menghasilkan Data Sedang Diproses!, Kami akan mengirimkan email kepada Anda jika sudah siap."
        onClickButton={() => setOpenNotification(false)}
        textButton="Konfirmasi"
      />
    </>
  )
}
