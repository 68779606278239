import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import {Link} from 'react-router-dom'
import {TabMenu} from './WlbTabStyles'
import {Typography} from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(() => ({
  menu: {
    alignSelf: 'flex-start',
    flexShrink: 0,
    padding: 10,
    paddingLeft: 0,
    marginRight: 8,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    borderColor: '#003d51',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#003d51',
    },
  },
  noHover: {
    '& li:first-child:hover ': {
      backgroundColor: '#ffffff !important',
    },
  },
}))

export default function WlbTab({
  data,
  activeMenu,
  disableFirstElement,
  // setActiveTab,
}) {
  const classes = useStyles()
  return (
    <Card className={classes.menu}>
      <TabMenu>
        <ul className={classNames('tab-menu', classes.noHover)}>
          {data.map((val, index) => {
            return (
              <li
                key={index}
                className={val.state === activeMenu ? 'active' : ''}
              >
                {index === 0 && disableFirstElement ? (
                  <Typography
                    style={{
                      color: '#014a62',
                      marginLeft: 20,
                      fontSize: 14,
                      fontWeight: 'bolder',
                      padding: '10px 0px',
                    }}
                  >
                    {val.label}
                  </Typography>
                ) : (
                  <>
                    {/^https?:\/\//.test(val.url) ? (
                      <a
                        href={val.url ? val.url : '#'}
                        style={{
                          color: val.state === activeMenu ? '#fff' : '#a9a8a8',
                        }}
                      >
                        {val.label}
                        <i></i>
                      </a>
                    ) : (
                      <Link
                        to={{
                          pathname: val.url ? val.url : '#',
                          state: {
                            active: val.state,
                            searchable: val.searchable,
                          },
                        }}
                        style={{
                          color: val.state === activeMenu ? '#fff' : '#a9a8a8',
                        }}
                        // onClick={() => setActiveTab(val.state)}
                      >
                        {val.label}
                      </Link>
                    )}
                  </>
                )}
              </li>
            )
          })}
        </ul>
      </TabMenu>
    </Card>
  )
}
