import React, { useState } from 'react'
import {
  Chip,
  Icon,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { selectProps } from '../../../../../utils/helpers'
import { useQuery } from '@apollo/react-hooks'

import Axios from 'axios'
import { TOKEN, UPLOAD_URL } from '../../../../../utils/globals'
import {
  GET_GLOBAL_LANGUAGES,
  GET_SUB_AND_CATEGORIES,
} from '../../../../../graphql/queries'
import helperStyle from '../../../../../GlobalStyles'
import { useSnackbar } from 'notistack'

export default function ManageLanding({
  isError,
  style,
  value,
  handleChange,
  setValue,
  isSlugExist,
}) {
  const classes = helperStyle()
  const { enqueueSnackbar } = useSnackbar()
  const [addTopic, setAddTopic] = useState(false)
  const [tempTopic, setTempTopic] = useState('')
  const [loadingUpload, setLoading] = useState(false)

  const { data: dataLanguages } = useQuery(GET_GLOBAL_LANGUAGES, {
    wlb_skipPatch: true,
  })
  const { data: dataCategories } = useQuery(GET_SUB_AND_CATEGORIES, {
    wlb_skipPatch: true,
  })

  const handleTopic = () => {
    setAddTopic(!addTopic)
    setTempTopic('')
  }

  const onSubmitTopic = () => {
    const newTopic = value.topic
    newTopic.push(tempTopic)
    setValue({ ...value, topic: newTopic })
    handleTopic()
  }

  const handleDeleteTopic = top => {
    const newTopic = value.topic.filter(t => t !== top)
    setValue({ ...value, topic: newTopic })
  }

  const handleChangeFile = async e => {
    const tempFile = Array.from(e.target.files)
    const val = tempFile[0]

    if (val.size > 1000 * 1000 * 25) {
      enqueueSnackbar("File size can't be more than 25MB", {
        autoHideDuration: 2000,
        variant: 'error',
      })
    } else if (
      val.type !== 'image/jpeg' &&
      val.type !== 'image/jpg' &&
      val.type !== 'image/png'
    ) {
      enqueueSnackbar('File other than images are not allowed', {
        autoHideDuration: 2000,
        variant: 'error',
      })
    } else {
      const formData = new FormData()
      formData.append('file', val)
      try {
        const response = await Axios.post(UPLOAD_URL, formData, {
          withCredentials: true,
          onUploadProgress: () => {
            // const loads = Math.round(
            //   (progressEvent.loaded / progressEvent.total) * 100
            // )
            setLoading(true)
          },

          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data',
          },
        })
        setValue({ ...value, banner_url: response.data.url })
        setLoading(false)
      } catch (e) {
        return e
      }
    }
  }

  return (
    <>
      <div className={classes.marginBot20}>
        <Typography variant="body2">Judul Pembelajaran Mikro*</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          placeholder="Tambah judul pembelajaran mikro"
          fullWidth
          value={value.name}
          onChange={handleChange('name')}
          error={isError && !value.name}
          helperText={isError && !value.name && 'Bagian ini wajib'}
        />
      </div>
      <div className={classes.marginBot20}>
        <Typography variant="body2">Sub Judul Pembelajaran Mikro</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          placeholder="Tambah sub judul pembelajaran mikro"
          fullWidth
          value={value.subtitle}
          onChange={handleChange('subtitle')}
        />
      </div>
      <div className={classes.marginBot20}>
        <Typography variant="body2">Penanda Pembelajaran Mikro*</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          placeholder="Tambah penanda pembelajaran mikro"
          fullWidth
          value={value.slug}
          onChange={handleChange('slug')}
          error={isError && !value.slug}
        />
        <Typography
          variant="body2"
          style={{
            color: isSlugExist || !value.slug ? 'red' : '#4CAF50',
          }}
          className={classes.marginTop6}
        >
          {isSlugExist
            ? 'Slug already exist'
            : (isError && !value.slug) || !value.slug
              ? 'Bagian ini wajib'
              : 'Slug Tersedia'}
        </Typography>
      </div>
      <div className={classes.marginBot20}>
        <Typography variant="body2">Keterangan</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          placeholder="Tambah Keterangan Pembelajaran Mikro"
          fullWidth
          multiline
          rows="5"
          value={value.description}
          onChange={handleChange('description')}
        />
      </div>
      <div className={`${classes.marginBot20} ${classes.width30P}`}>
        <Typography variant="body2">Bahasa*</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          select
          value={value.language_id}
          onChange={handleChange('language_id')}
          SelectProps={selectProps(value.language_id)}
          fullWidth
          error={isError && !value.language_id}
          helperText={isError && !value.language_id && 'Bagian ini wajib'}
        >
          <MenuItem value={''} disabled>
            Pilih Bahasa
          </MenuItem>
          {dataLanguages &&
            dataLanguages.global_languages.map(d => (
              <MenuItem key={d.id} value={d.id}>
                {d.name}
              </MenuItem>
            ))}
        </TextField>
      </div>
      <div className={`${classes.marginBot20} ${classes.width30P}`}>
        <Typography variant="body2">Level*</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          select
          fullWidth
          value={value.level}
          onChange={handleChange('level')}
          SelectProps={selectProps(value.level)}
          error={isError && !value.level}
          helperText={isError && !value.level && 'Bagian ini wajib'}
        >
          <MenuItem value={''} disabled>
            Pilih Level
          </MenuItem>
          <MenuItem value={1}>Beginner</MenuItem>
          <MenuItem value={2}>Intermediate</MenuItem>
          <MenuItem value={3}>Advanced</MenuItem>
        </TextField>
      </div>
      <div className={`${classes.marginBot20} ${classes.width30P}`}>
        <Typography variant="body2">Kategori*</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          select
          fullWidth
          value={value.category_id}
          onChange={handleChange('category_id')}
          SelectProps={selectProps(value.category_id)}
          error={isError && !value.category_id}
          helperText={isError && !value.category_id && 'Bagian ini wajib'}
        >
          <MenuItem value={''} disabled>
            Pilih Kategori
          </MenuItem>
          {dataCategories &&
            dataCategories.academy_course_categories.map(d => (
              <MenuItem value={d.id} key={d.id}>
                {d.name}
              </MenuItem>
            ))}
        </TextField>
      </div>
      <div className={`${classes.marginBot20} ${classes.width30P}`}>
        <Typography variant="body2">Sub Kategori*</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          select
          fullWidth
          value={value.subcategory}
          onChange={handleChange('subcategory')}
          SelectProps={selectProps(value.subcategory)}
          error={isError && !value.subcategory}
          helperText={isError && !value.subcategory && 'Bagian ini wajib'}
        >
          <MenuItem value={''} disabled>
            Pilih Sub Kategori
          </MenuItem>
          {dataCategories &&
            value.category_id &&
            dataCategories.academy_course_categories
              .filter(d => d.id === value.category_id)[0]
              .subcategory.map(d => (
                <MenuItem key={d.id} value={d.id}>
                  {d.name}
                </MenuItem>
              ))}
        </TextField>
      </div>
      <div className={classes.marginBot28}>
        <Typography variant="body2">Topik*</Typography>
        <div style={style.topicContainer}>
          {value.topic &&
            value.topic.map((top, i) => {
              return (
                <Chip
                  key={i}
                  label={top}
                  style={style.chipSkyBlue}
                  color="secondary"
                  onDelete={() => handleDeleteTopic(top)}
                />
              )
            })}
          {addTopic ? (
            <>
              <TextField
                variant="outlined"
                placeholder="Add Topic"
                margin="normal"
                value={tempTopic}
                onChange={e => setTempTopic(e.target.value)}
              ></TextField>
              <IconButton
                style={tempTopic ? style.iconSkyBlue : style.iconDisabled}
                size="small"
                onClick={onSubmitTopic}
                className={classes.mx16}
                disabled={!tempTopic}
              >
                <Icon>check</Icon>
              </IconButton>
              <IconButton
                style={style.iconRed}
                size="small"
                onClick={handleTopic}
              >
                <Icon>close</Icon>
              </IconButton>
            </>
          ) : (
            <IconButton
              style={style.iconSkyBlue}
              size="small"
              onClick={handleTopic}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
        </div>
        {isError && value.topic.length === 0 && (
          <Typography
            variant="caption"
            style={{
              color: 'red',
            }}
            className={classes.marginTop10}
          >
            Bagian ini wajib
          </Typography>
        )}
      </div>
      <div className={classes.marginBot20}>
        <Typography variant="body2" className="mb-16">
          Gambar Pembelajaran Mikro
        </Typography>
        <Typography variant="body2" style={style.grey} gutterBottom>
          Tipe Berkas: jpg, jpeg, png
        </Typography>
        <Typography variant="body2" style={style.grey} gutterBottom>
          Ukuran Maks: 25 MB
        </Typography>
        {value.banner_url ? (
          <div
            style={{ display: 'flex', alignItems: 'flex-start', marginTop: 40 }}
          >
            <img src={value.banner_url} alt="attch" style={style.uploadedImg} />
            <IconButton onClick={() => setValue({ ...value, banner_url: '' })}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        ) : (
          <div style={style.addContainer}>
            <Icon
              style={{
                ...style.grey,
                fontSize: 40,
                marginBottomn: 11,
              }}
            >
              add
            </Icon>
            <Typography variant="body2" style={style.grey} gutterBottom>
              Tambah Gambar Pembelajaran Mikro
            </Typography>
            <input
              id="uploadImage"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              // className="hidden"
              style={style.input}
              onChange={handleChangeFile}
            />
          </div>
        )}
        {loadingUpload && (
          <div style={{ width: 400, textAlign: 'center' }}>
            <LinearProgress
              variant="indeterminate"
              className={`${classes.marginTop16} ${classes.marginBot8}`}
            />
            <Typography variant="body2">Uploading image ...</Typography>
          </div>
        )}
      </div>
    </>
  )
}
