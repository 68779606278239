import gql from 'graphql-tag'

export const KICK_CROSS_MENTORING_MEMBER = gql`
  mutation($memberId: [Int]!) {
    crossMentoringKickMember(memberId: $memberId) {
      success
      rows_affected
    }
  }
`
