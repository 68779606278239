import React, {useState, useEffect} from 'react'
import MaterialTable from 'material-table'
import {
  CircularProgress,
  // makeStyles,
  // Typography,
  // Popover,
} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {Link} from 'react-router-dom'
import helperStyle, {
  TableOptions,
  TextBlueBold,
  CenterWrapper,
  TableEmployeeStyled,
  // PostponedItem,
  // PopOverWrapper,
  pageSizeOption,
} from '../../PayrollPageStyles'
import {
  convertToRoundedRupiah,
  employeeColumnSort,
} from '../../../../../utils/helpers'
import {GET_EMPLOYEE_APPROVAL_PAYROLL_PROCESS} from '../../../../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import EmployeeAvatar from '../../../../shared-components/EmployeeAvatar'

// const useStyles = makeStyles(() => ({
//   popover: {
//     pointerEvents: 'none',
//   },
//   paper: {
//     background: '#0000002e',
//     opacity: 1,
//   },
// }))

const StepCalculation = props => {
  const {period, location, searchText, filterData} = props

  // const [anchorEl, setAnchorEl] = useState(null)
  // const [openedId, setOpenedId] = useState('')

  // const handlePopoverClose = () => {
  //   setAnchorEl(null)
  // }

  // const open = Boolean(anchorEl)
  // const popoverClasses = useStyles()
  const classes = helperStyle()

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const slicedParams = location.search.split('=')

  const {data: employeePayrollApprovalProcess, loading, error} = useQuery(
    GET_EMPLOYEE_APPROVAL_PAYROLL_PROCESS,
    {
      variables: {
        login: USER_ID,
        company: COMPANY_ID,
        year: period,
        month: Number(slicedParams[slicedParams.length - 1]),
        offset: rowsPerPage * currentPage,
        limit: rowsPerPage,
        position:
          JSON.stringify(filterData.position) === '[]'
            ? null
            : filterData.position,
        organization:
          JSON.stringify(filterData.organization) === '[]'
            ? null
            : filterData.organization,
        job_profile:
          JSON.stringify(filterData.job_profile) === '[]'
            ? null
            : filterData.job_profile,
        rank: JSON.stringify(filterData.rank) === '[]' ? null : filterData.rank,
        office:
          JSON.stringify(filterData.office) === '[]' ? null : filterData.office,
        search: `%${searchText}%`,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  useEffect(() => {
    setCurrentPage(0)
  }, [period, searchText, filterData, rowsPerPage])

  return (
    <>
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {!employeePayrollApprovalProcess && loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {employeePayrollApprovalProcess && (
        <TableEmployeeStyled>
          <MaterialTable
            options={{...TableOptions}}
            totalCount={
              employeePayrollApprovalProcess.employee_count.aggregate.count
            }
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={page => {
              setCurrentPage(page)
            }}
            onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
            isLoading={employeePayrollApprovalProcess && loading}
            columns={[
              {
                title: 'Employee',
                field: 'employee',
                headerStyle: {minWidth: 350},
                cellStyle: {minWidth: 350},
                customSort: employeeColumnSort,
                render: rowData => {
                  return (
                    <EmployeeAvatar
                      src={rowData.employee_placement?.global_user.avatar}
                      regno={rowData.employee_placement?.regno}
                      title={
                        rowData.employee_placement?.company_job_profile.title
                      }
                      global_user={rowData.employee_placement?.global_user}
                      city_name={
                        rowData.employee_placement?.company_job_profile
                          .company_address.global_city.name
                      }
                      province_name={
                        rowData.employee_placement?.company_job_profile
                          .company_address.global_province.name
                      }
                      empStatus={rowData.employee_placement?.status}
                      empEndDate={rowData.employee_placement?.end_date}
                    />
                  )
                },
              },
              {
                title: 'Basic Salary',
                field: 'basic_salary',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.salary - b.salary
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.salary, false),
              },
              {
                title: 'Earning',
                field: 'addition',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.addition - b.addition
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.addition, false),
              },
              {
                title: 'Deduction',
                field: 'deduction',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                // customSort: (a, b) => {
                //   return a.deduction - b.deduction
                // },
                render: rowData =>
                  convertToRoundedRupiah(rowData.deduction, false),
              },
              {
                title: 'Total',
                field: 'total',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.final_salary - b.final_salary
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.final_salary, false),
              },
              // {
              //   title: 'Status',
              //   field: 'status',
              //   sorting: false,
              //   headerStyle: {minWidth: 175, textAlign: 'center'},
              //   cellStyle: {minWidth: 175, textAlign: 'center'},
              //   render: rowData => {
              //     if (rowData.approver && rowData.finance_personnel) {
              //       return (
              //         <Badge style={{backgroundColor: '#FFA000'}}>
              //           Approved
              //         </Badge>
              //       )
              //     } else if (!rowData.approver && rowData.finance_personnel) {
              //       return <Badge>Need Approval 1</Badge>
              //     } else if (rowData.approver && !rowData.finance_personnel) {
              //       return <Badge>Need Approval 2</Badge>
              //     } else {
              //       return <Badge>Need Approval</Badge>
              //     }
              //   },
              // },

              /* Hide: WLB 4052
              // {
              //   title: 'Postpone',
              //   field: 'postpone',
              //   headerStyle: {minWidth: 175, textAlign: 'center'},
              //   cellStyle: {minWidth: 175, textAlign: 'center'},
              //   render: rowData => {
              //     if (rowData.postponed_items) {
              //       const totalFilteredAllowances = rowData.allowances.filter(
              //         item => {
              //           return item.is_postponed
              //         }
              //       ).length
              //       if (
              //         rowData.postponed_items.salary &&
              //         (rowData.non_fixed_components.length > 0 ||
              //           totalFilteredAllowances > 0)
              //       ) {
              //         return (
              //           <div>
              //             <div>
              //               <Typography
              //                 aria-owns={
              //                   open
              //                     ? `mouse-over-popover${rowData.id}`
              //                     : undefined
              //                 }
              //                 id={rowData.id}
              //                 aria-haspopup="true"
              //                 onMouseEnter={event => {
              //                   setAnchorEl(event.currentTarget)
              //                   setOpenedId(rowData.id)
              //                 }}
              //                 onMouseLeave={() => {
              //                   handlePopoverClose()
              //                   setOpenedId('')
              //                 }}
              //               >
              //                 <PostponedItem>
              //                   <div>
              //                     <img
              //                       src={require('../../../../../assets/images/font-awesome/exclamation.svg')}
              //                     />
              //                   </div>
              //                 </PostponedItem>
              //               </Typography>
              //               <Popover
              //                 id={`mouse-over-popover${rowData.id}`}
              //                 className={popoverClasses.popover}
              //                 classes={{
              //                   paper: popoverClasses.paper,
              //                 }}
              //                 open={open && openedId === rowData.id}
              //                 anchorEl={anchorEl}
              //                 anchorOrigin={{
              //                   vertical: 'top',
              //                   horizontal: 'center',
              //                 }}
              //                 transformOrigin={{
              //                   vertical: 'bottom',
              //                   horizontal: 'center',
              //                 }}
              //                 onClose={() => {
              //                   handlePopoverClose()
              //                   setOpenedId('')
              //                 }}
              //                 disableRestoreFocus
              //                 backdropInvisible={false}
              //               >
              //                 <PopOverWrapper>
              //                   <div>
              //                     <strong>Postpone payment</strong>
              //                     <ul>
              //                       {rowData.non_fixed_components.map(
              //                         (item, i) => {
              //                           return <li key={i}>{item.name}</li>
              //                         }
              //                       )}
              //                       {rowData.allowances.map((item, i) => {
              //                         if (item.is_postponed) {
              //                           return <li key={i}>{item.name}</li>
              //                         }
              //                       })}
              //                     </ul>
              //                   </div>
              //                 </PopOverWrapper>
              //               </Popover>
              //             </div>
              //           </div>
              //         )
              //       }
              //     }
              //     return '-'
              //   },
              // },
               */

              {
                title: 'Action',
                field: 'action',
                cellStyle: {minWidth: 100},
                sorting: false,
                render: rowData => {
                  return (
                    <Link
                      to={{
                        pathname: `/financial/payroll/payroll-process/payroll-detail/${rowData.id}`,
                        state: {
                          active: 'payroll-detail',
                          payrollId: rowData.id,
                          year: period,
                          month: Number(slicedParams[slicedParams.length - 1]),
                          // id: rowData.id,
                          // jobName: rowData.organizationUnit,
                        },
                      }}
                      className={classes.link}
                    >
                      <TextBlueBold>Details</TextBlueBold>
                    </Link>
                  )
                },
              },
            ]}
            data={employeePayrollApprovalProcess.people_work_payroll}
          />
        </TableEmployeeStyled>
      )}
    </>
  )
}

export default StepCalculation
