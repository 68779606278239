import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'

import AlertPopup from '../../../../shared-components/popup/ResultPopup'
import CheckboxInputGroup from '../../../../../components/form/CheckboxInputGroup'
import DateInputGroup from '../../../../../components/form/DateInputGroup'
import FormAction from '../../../../../components/form/FormActionComponent'
import NumberInputGroup from '../../../../../components/form/NumberInputGroup'
import TextInputGroup from '../../../../../components/form/TextInputGroup'

import {currencyMaskingMethod} from '../../../../../utils/helpers'

const PaymentPlanForm = props => {
  const {
    dataForm,
    defaultData,
    handlePreviousButton,
    isAbleToEdit = true,
    isEdit = false,
    isSubmit,
    mutation = () => {},
    serviceLoading,
    setDataForm,
    showFormAction = true,
  } = props
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const [cancelIsOpen, setCancelIsOpen] = useState(false)

  const disabledSubmitButton =
    !dataForm.installment &&
    !dataForm.amount_per_installment > 0 &&
    !dataForm.payment_date_start &&
    !dataForm.installment_name

  return (
    <>
      <NumberInputGroup
        autoFocus={!isEdit}
        dataForm={dataForm}
        setDataForm={setDataForm}
        disabled={!isAbleToEdit}
        fieldLabel="Number of Installment *"
        fieldNumber="1"
        fieldName="installment"
        placeholder="Type number of installment here"
        showErrorHelper={() => isSubmit && !dataForm?.installment}
        tooltipMesssage="Number of Installment"
        additionalonchangemethod={val => {
          setDataForm({
            ...dataForm,
            installment: val,
            amount_per_installment: dataForm?.loan_amount_w_interest / val,
          })
        }}
        maskingMethod={value => {
          return currencyMaskingMethod(value)
        }}
        value={currencyMaskingMethod(dataForm.installment)}
      />
      <NumberInputGroup
        isCurrency
        dataForm={dataForm}
        setDataForm={setDataForm}
        disabled={!isAbleToEdit}
        fieldLabel="Amount of Installment"
        fieldNumber="2"
        fieldName="amount_per_installment"
        placeholder="Type amount of installment here"
        showErrorHelper={() => isSubmit && !dataForm?.amount_per_installment}
        tooltipMesssage="Amount of Installment"
        additionalInputProps={{
          readOnly: true,
        }}
        maskingMethod={value => {
          return currencyMaskingMethod(value)
        }}
        value={currencyMaskingMethod(dataForm.amount_per_installment)}
      />
      <DateInputGroup
        dataForm={dataForm}
        dateFormat={'MMM dd, yyyy'}
        setDataForm={setDataForm}
        disabled={!isAbleToEdit}
        fieldLabel="Start Payment Date *"
        fieldNumber="3"
        fieldName="payment_date_start"
        placeholder="Select start payment date"
        tooltipMesssage="Start Payment Date"
      />
      <CheckboxInputGroup
        dataForm={dataForm}
        setDataForm={setDataForm}
        defaultValue={defaultData?.deducted_from_salary}
        disabled={!isAbleToEdit}
        fieldLabel="Deducted from Salary"
        fieldNumber="4"
        fieldName="deducted_from_salary"
        isRequired={false}
        tooltipMesssage="Deducted from Salary"
      />
      <TextInputGroup
        dataForm={dataForm}
        setDataForm={setDataForm}
        defaultValue={defaultData?.installment_name}
        disabled={!isAbleToEdit}
        fieldLabel="Installment Name (Interest) *"
        fieldNumber="5"
        fieldName="installment_name"
        placeholder="Type installment wage name (interest) here"
        showErrorHelper={() => isSubmit && !dataForm?.installment_name}
        tooltipMesssage="Installment Name (Interest)"
      />

      {isAbleToEdit && showFormAction && (
        <FormAction
          cancelLabelButton={handlePreviousButton ? 'Previous' : 'Cancel'}
          openConfirmartionDialog={() => setConfirmIsOpen(true)}
          openCancellationDialog={() =>
            handlePreviousButton
              ? handlePreviousButton()
              : setCancelIsOpen(true)
          }
          disabledSubmitButton={
            (isSubmit && disabledSubmitButton) || serviceLoading
          }
        />
      )}

      {confirmIsOpen && (
        <AlertPopup
          open={confirmIsOpen}
          feature="Loan Activity"
          message="Are you sure you want to save changes?"
          type="Confirm"
          status="cancel"
          handleClose={() => {
            setConfirmIsOpen(false)
          }}
          mutation={() => {
            setConfirmIsOpen(false)
            mutation()
          }}
        />
      )}
      {cancelIsOpen && (
        <AlertPopup
          open={cancelIsOpen}
          feature="Discard Changes?"
          message="Are you sure you want to discard changes?"
          type="Discard"
          status="cancel"
          handleClose={() => {
            setCancelIsOpen(false)
          }}
          mutation={() => {
            setCancelIsOpen(false)
            props.history.goBack()
          }}
        />
      )}
    </>
  )
}

export default withRouter(PaymentPlanForm)
