import gql from 'graphql-tag'

export const ADD_REGULATION = gql`
  mutation($objects: [company_regulations_insert_input!]!) {
    insert_company_regulations(objects: $objects) {
      affected_rows
    }
  }
`

export const ADD_CLAUSE = gql`
  mutation($objects: [company_regulation_clauses_insert_input!]!) {
    insert_company_regulation_clauses(objects: $objects) {
      affected_rows
    }
  }
`

export const UPDATE_REGULATION = gql`
  mutation($id: bigint!, $name: String, $description: String) {
    update_company_regulations(
      where: {id: {_eq: $id}}
      _set: {name: $name, description: $description}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_CLAUSE = gql`
  mutation($id: bigint!, $name: String, $content: String) {
    update_company_regulation_clauses(
      where: {id: {_eq: $id}}
      _set: {name: $name, content: $content}
    ) {
      affected_rows
    }
  }
`

export const DELETE_REGULATION = gql`
  mutation($id: bigint!) {
    update_company_regulations(
      where: {id: {_eq: $id}}
      _set: {deleted_at: "now()"}
    ) {
      affected_rows
    }
  }
`

export const DELETE_CLAUSE = gql`
  mutation($id: bigint!) {
    update_company_regulation_clauses(
      where: {id: {_eq: $id}}
      _set: {deleted_at: "now()"}
    ) {
      affected_rows
    }
  }
`
