import gql from 'graphql-tag'

export const UPDATE_PAYROLL_DETAIL = gql`
  mutation(
    $id: bigint!
    $addition: jsonb
    $deduction: jsonb
    $postpone_salary: jsonb
    $final_salary: float8
    $addition_total: float8
    $deduction_total: float8
  ) {
    update_people_work_payroll_by_pk(
      pk_columns: {id: $id}
      _set: {
        allowances: $addition
        non_fixed_components: $deduction
        postponed_items: $postpone_salary
        final_salary: $final_salary
        income: $addition_total
        deduction: $deduction_total
      }
    ) {
      id
      last_modified
    }
  }
`

export const UPDATE_PAYROLL_STEP_PROCESS = gql`
  mutation($company: uuid!, $year: Int, $month: Int, $process_step: Int) {
    update_people_work_payroll(
      where: {
        employee_placement: {company: {_eq: $company}}
        year: {_eq: $year}
        month: {_eq: $month}
      }
      _set: {process_step: $process_step}
    ) {
      affected_rows
    }
  }
`

export const REJECT_PAYROLL_APPROVAL = gql`
  mutation(
    $login: uuid
    $company: uuid!
    $year: Int
    $month: Int
    $process_step: Int
    $approve_step: Int!
  ) {
    reject: update_people_work_payroll(
      where: {
        approve_step: {_eq: $approve_step}
        year: {_eq: $year}
        month: {_eq: $month}
        employee_placement: {company: {_eq: $company}}
      }
      _set: {approve_step: 0, approver: $login}
    ) {
      affected_rows
    }
    update_step_process: update_people_work_payroll(
      where: {
        employee_placement: {company: {_eq: $company}}
        year: {_eq: $year}
        month: {_eq: $month}
      }
      _set: {process_step: $process_step}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_PAYROLL_APPROVAL = gql`
  mutation(
    $login: uuid!
    $company: uuid
    $year: Int
    $month: Int
    $process_step: Int
  ) {
    step_1: update_people_work_payroll(
      where: {
        approve_step: {_eq: 0}
        year: {_eq: $year}
        month: {_eq: $month}
        employee_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              first_job_profile: {people_work_placements: {user: {_eq: $login}}}
            }
          }
        }
      }
      _set: {approve_step: 1, approver: $login}
    ) {
      affected_rows
    }

    step_2: update_people_work_payroll(
      where: {
        year: {_eq: $year}
        month: {_eq: $month}
        approve_step: {_eq: 1}
        employee_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              second_job_profile: {
                people_work_placements: {user: {_eq: $login}}
              }
            }
          }
        }
      }
      _set: {approve_step: 2, approver: $login}
    ) {
      affected_rows
    }

    step_3: update_people_work_payroll(
      where: {
        approve_step: {_eq: 2}
        year: {_eq: $year}
        month: {_eq: $month}
        employee_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              third_job_profile: {people_work_placements: {user: {_eq: $login}}}
            }
          }
        }
      }
      _set: {approve_step: 3, approver: $login}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_PAYROLL_LAST_APPROVAL = gql`
  mutation(
    $login: uuid!
    $company: uuid
    $year: Int
    $month: Int
    $process_step: Int
  ) {
    step_1: update_people_work_payroll(
      where: {
        approve_step: {_eq: 0}
        year: {_eq: $year}
        month: {_eq: $month}
        employee_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              first_job_profile: {people_work_placements: {user: {_eq: $login}}}
            }
          }
        }
      }
      _set: {approve_step: 1, approver: $login}
    ) {
      affected_rows
    }

    step_2: update_people_work_payroll(
      where: {
        year: {_eq: $year}
        month: {_eq: $month}
        approve_step: {_eq: 1}
        employee_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              second_job_profile: {
                people_work_placements: {user: {_eq: $login}}
              }
            }
          }
        }
      }
      _set: {approve_step: 2, approver: $login}
    ) {
      affected_rows
    }

    step_3: update_people_work_payroll(
      where: {
        approve_step: {_eq: 2}
        year: {_eq: $year}
        month: {_eq: $month}
        employee_placement: {
          company: {_eq: $company}
          company_profile: {
            finance_payroll_settings: {
              third_job_profile: {people_work_placements: {user: {_eq: $login}}}
            }
          }
        }
      }
      _set: {approve_step: 3, approver: $login}
    ) {
      affected_rows
    }

    update_step_process: update_people_work_payroll(
      where: {
        employee_placement: {company: {_eq: $company}}
        year: {_eq: $year}
        month: {_eq: $month}
      }
      _set: {process_step: $process_step}
    ) {
      affected_rows
    }
  }
`
