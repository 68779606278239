import React, {useState} from 'react'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {NewTableOptions} from '../../../shared-components/table/TableOptions'
import {holdingRolesContext} from '../../../../utils/helpers'
import Loading from '../../../../components/loading/LoadingComponent'
import MaterialTable from 'material-table'
import {Avatar, IconButton, Typography, Tooltip, Icon} from '@material-ui/core'
import {BoldTypography} from '../../../../components/typography/TypographyStyles'
import {BlueIcon} from '../../../../GlobalStyles'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {GET_LIST_HOLDING_TALENT_EXTERNAL} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import {
  ADD_TO_SOURCE_TALENT_HOLDING,
  DEL_FROM_SOURCE_TALENT_HOLDING,
} from '../../../../graphql/mutations'

const ExternalTalentList = props => {
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [confirmType, setConfirmType] = useState('')
  const [openAdd, setOpenAdd] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [mutationAddToSource] = useMutation(ADD_TO_SOURCE_TALENT_HOLDING)
  const [mutationDelFromSource] = useMutation(DEL_FROM_SOURCE_TALENT_HOLDING)
  const {enqueueSnackbar} = useSnackbar()
  const {data, error, refetch} = useQuery(GET_LIST_HOLDING_TALENT_EXTERNAL, {
    variables: {companyHead: COMPANY_ID, search: `%${props.searchText}%`},
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  console.log('data external', data)

  const handleOpen = (ids, type, names) => {
    if (type === 'add') {
      setOpenAdd(true)
    } else {
      setOpenDel(true)
    }
    setId(ids)
    setName(names)
    setConfirmType(type)
  }

  const handleClose = () => {
    if (confirmType === 'add') {
      setOpenAdd(false)
    } else {
      setOpenDel(false)
    }
  }

  const handleMutation = () => {
    if (confirmType === 'add') {
      const object = {user_work_id: id, company_head: COMPANY_ID}
      mutationAddToSource({
        variables: {object},
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          refetch()
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    } else {
      mutationDelFromSource({
        variables: {id},
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          refetch()
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    }
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <>
      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0', fontSize: 12}}
        options={{...NewTableOptions, paging: true, sorting: true}}
        columns={[
          {
            title: 'Talent',
            field: 'global_user.name',
            cellStyle: {
              wordBreak: 'break-word',
              minWidth: 150,
            },
            render: row => (
              <div style={{display: 'flex'}}>
                <Avatar
                  variant="rounded"
                  src={row.global_user.avatar}
                  style={{marginRight: 16}}
                ></Avatar>
                <div>
                  <BoldTypography variant="body2" gutterBottom>
                    {row.global_user.name}
                  </BoldTypography>
                  <Typography variant="caption">
                    {row.company_profile.legal_name}
                  </Typography>
                </div>
              </div>
            ),
          },

          {
            title: 'Employee ID',
            field: 'regno',
            cellStyle: {
              textTransform: 'capitalize',
            },
          },
          {
            title: 'Position',
            field: 'company_job_profile.title',
            render: row =>
              row.company_job_profile && row.company_job_profile.title
                ? row.company_job_profile.title
                : '-',
          },
          {
            title: 'Email',
            field: 'email',
            cellStyle: {color: '#039be5', fontWeight: 'normal'},
          },
          {
            title: '',
            field: 'action',
            filtering: false,
            render: row => (
              <div style={{display: 'flex'}}>
                {row.holding_sources.length === 0 ? (
                  <Tooltip title="Add to Talent Source">
                    <IconButton
                      onClick={() =>
                        handleOpen(row.id, 'add', row.global_user.name)
                      }
                    >
                      <BlueIcon>person_add</BlueIcon>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Remove from Talent Source">
                    <IconButton
                      onClick={() =>
                        handleOpen(
                          row.holding_sources[0].id,
                          'delete',
                          row.global_user.name
                        )
                      }
                    >
                      <Icon color="secondary">person_remove</Icon>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]}
        components={{
          Toolbar: () => null,
        }}
        title=""
        data={data.list_external}
      />
      <AddEdit
        open={openAdd}
        handleClose={handleClose}
        type={confirmType}
        name={`${name} to talent source`}
        feature=""
        mutation={handleMutation}
      />
      <DeletePopup
        open={openDel}
        handleClose={handleClose}
        id={id}
        name={`${name} from talent source`}
        mutation={handleMutation}
      />
    </>
  )
}

export default ExternalTalentList
