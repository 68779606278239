import KpiPage from './KpiPageComponent'

export const KpiPageConfig = {
  routes: [
    {
      path: '/performance/kpi',
      component: KpiPage,
      state: 'aspect',
      exact: true,
    },
    {
      path: '/performance/kpi/aspect',
      component: KpiPage,
      state: 'aspect',
      exact: false,
    },
    {
      path: '/performance/kpi/method',
      component: KpiPage,
      state: 'method',
      exact: false,
    },
    {
      path: '/performance/kpi/monitoring',
      component: KpiPage,
      state: 'monitoring',
      exact: false,
    },
    {
      path: '/performance/kpi/perspective',
      component: KpiPage,
      state: 'perspective',
      exact: false,
    },
    {
      path: '/performance/kpi/scoring',
      component: KpiPage,
      state: 'scoring',
      exact: false,
    },
    {
      path: '/performance/kpi/level',
      component: KpiPage,
      state: 'level',
      exact: false,
    },
    {
      path: '/performance/kpi/weight',
      component: KpiPage,
      state: 'weight',
      exact: false,
    },
    {
      path: '/performance/kpi/version',
      component: KpiPage,
      state: 'version',
      exact: false,
    },
    {
      path: '/performance/kpi/list',
      component: KpiPage,
      state: 'list',
      exact: false,
    },
    {
      path: '/performance/kpi/cascading',
      component: KpiPage,
      state: 'cascading',
      exact: false,
    },
    {
      path: '/performance/kpi/perspective/add',
      component: KpiPage,
      state: 'add-perspective',
      exact: false,
    },
    {
      path: '/performance/kpi/edit-perspective/:id',
      component: KpiPage,
      state: 'edit-perspective',
      exact: false,
    },
    {
      path: '/performance/kpi/aspect/add',
      component: KpiPage,
      state: 'add-aspect',
    },
    {
      path: '/performance/kpi/aspect/edit',
      component: KpiPage,
      state: 'edit-aspect',
    },
    {
      path: '/performance/kpi/method/add',
      component: KpiPage,
      state: 'add-method',
    },
    {
      path: '/performance/kpi/method/edit',
      component: KpiPage,
      state: 'edit-method',
    },
    {
      path: '/performance/kpi/monitoring/add',
      component: KpiPage,
      state: 'add-monitoring',
    },
    {
      path: '/performance/kpi/monitoring/edit',
      component: KpiPage,
      state: 'edit-monitoring',
    },
    {
      path: '/performance/kpi/scoring/add',
      component: KpiPage,
      state: 'add-scoring',
    },
    {
      path: '/performance/kpi/scoring/edit',
      component: KpiPage,
      state: 'edit-scoring',
    },
    {
      path: '/performance/kpi/level/add',
      component: KpiPage,
      state: 'add-level',
    },
    {
      path: '/performance/kpi/level/edit',
      component: KpiPage,
      state: 'edit-level',
    },
    {
      path: '/performance/kpi/weight/add',
      component: KpiPage,
      state: 'add-weight',
    },
    {
      path: '/performance/kpi/weight/edit',
      component: KpiPage,
      state: 'edit-weight',
    },
    {
      path: '/performance/kpi/list/add',
      component: KpiPage,
      state: 'add-list',
    },
    {
      path: '/performance/kpi/list/edit',
      component: KpiPage,
      state: 'edit-list',
    },
    {
      path: '/performance/kpi/cascading/add',
      component: KpiPage,
      state: 'add-cascading',
    },
    {
      path: '/performance/kpi/cascading/edit',
      component: KpiPage,
      state: 'edit-cascading',
    },
    {
      path: '/performance/kpi/cascading/detail',
      component: KpiPage,
      state: 'cascading-detail',
    },
    {
      path: '/performance/kpi/cascading/add-detail',
      component: KpiPage,
      state: 'cascading-detail-add',
    },
    {
      path: '/performance/kpi/cascading/edit-detail',
      component: KpiPage,
      state: 'cascading-detail-edit',
    },
  ],
}
