import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import MaterialTable from 'material-table'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import {
  RootContainer,
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  GenerateTooltip,
  TextGrey,
} from '../../../../GlobalStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {TableOptions} from '../../../shared-components/table/TableOptions'
import {useSnackbar} from 'notistack'
import {Link} from 'react-router-dom'
import {COMPANY_ID} from '../../../../utils/globals'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import Loading from '../../../../components/loading/LoadingComponent'
import {
  disbaledButtonExport,
  holdingRolesContext,
} from '../../../../utils/helpers'
import AlertDialog from '../../../shared-components/popup/ResultPopup'

import {
  GET_LIST_COMPANY_SUMMARY,
  GET_EXPORT_DISABLED_BUTTON,
  GET_STATUS_EXPORT_BALOON,
} from '../../../../graphql/queries'
import {
  CLOSE_BALOON_EXPORT,
  EXPORT_MUTATION,
} from '../../../../graphql/mutations'
import {TextBlack} from '../../../organization/OrganizationPageStyles'
import GenerateReportList from '../../../shared-components/popup/GenerateReportList'

const ListComponent = ({searchText, filterData}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [openExport, setExport] = useState(false)
  const [openGenerate, setOpenGenerate] = useState(false)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const {data, error, loading} = useQuery(GET_LIST_COMPANY_SUMMARY, {
    variables: {
      types: filterData?.include?.type,
      exTypes: filterData?.exclude?.type,
      industry: filterData?.include?.industry,
      exIndustry: filterData?.exclude?.industry,
      company: COMPANY_ID,
      search: `%${searchText}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
    ...holdingRolesContext,
  })

  const {data: getExportBaloon, error: errorGetExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'performance_goal_holding',
      },
    }
  )

  const {data: getExportDisabled, error: errorGetExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'performance_goal_holding',
      },
    }
  )

  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )

  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id: getExportBaloon?.company_export_data_logs?.[0]?.id,
      },
    })
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (
    error !== undefined ||
    mutationExportError !== undefined ||
    closeBaloonExportError !== undefined ||
    errorGetExportBaloon !== undefined ||
    errorGetExportDisabled !== undefined
  ) {
    console.error({error})
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleDownload = idCompany => {
    setExport(!openExport)
    mutationExport({
      variables: {
        table: 'performance_goal_holding',
        filter: {
          company: idCompany,
        },
      },
    })
      .then(() => {
        enqueueSnackbar('Generate Data is processing!', {
          variant: 'success',
          autoHideDuration: 1000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Generate Data failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const column = [
    {
      title: 'Company Member Name',
      field: 'company_member_profile.brand_name',
      headerStyle: {minWidth: 300, maxWidth: 300},
      cellStyle: {minWidth: 300, maxWidth: 300},
      defaultSort: 'asc',
      render: rowData => (
        <div style={{display: 'flex'}}>
          <Avatar
            variant="rounded"
            src={rowData.company_member_profile.logo}
            style={{marginRight: 16}}
          />
          <div style={{flexDirection: 'column'}}>
            <TextBlack>{rowData.company_member_profile.brand_name}</TextBlack>
            <TextGrey>{rowData.company_member_profile.legal_name}</TextGrey>
          </div>
        </div>
      ),
    },
    {
      title: 'Type',
      field: 'company_member_profile.company_legal_type.legal_type',
      headerStyle: {minWidth: 200, maxWidth: 200},
      cellStyle: {minWidth: 200, maxWidth: 200, textTransform: 'capitalize'},
    },
    {
      title: 'Industry',
      field: 'company_member_profile.global_company_industry_type.name',
      headerStyle: {minWidth: 200, maxWidth: 200},
      cellStyle: {minWidth: 200, maxWidth: 200},
    },
    {
      title: 'Specialty',
      field: 'company_member_profile.profile_field.specialities',
      headerStyle: {minWidth: 200, maxWidth: 200},
      cellStyle: {minWidth: 200, maxWidth: 200},
    },
    {
      title: 'Action',
      field: 'action',
      sorting: false,
      render: rowData => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: `/holding-performance/company-summary/detail/${rowData.company_member_profile.id}`,
                    state: {
                      active: 'company-summary-detail',
                    },
                  }}
                  variant="h6"
                >
                  Details
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>
      ),
    },
  ]

  return (
    <RootContainer>
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={() => openGenerate(true)}
      />
      <PaperContainer>
        <EnhancedToolbar>
          <Typography variant="h6">Performance Company Summary</Typography>
          <div>
            <GenerateTooltip
              title={
                getExportBaloon?.company_export_data_logs?.[0]?.status ===
                  'FAILED' ||
                disbaledButtonExport(
                  getExportDisabled?.company_export_data_logs?.[0]?.date_added
                )
                  ? `you can only generate data once a day. to download data use the link that already generated`
                  : ``
              }
              placement="left"
            >
              <span>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={
                    getExportBaloon?.company_export_data_logs?.[0]?.status ===
                      'FAILED' ||
                    disbaledButtonExport(
                      getExportDisabled?.company_export_data_logs?.[0]
                        ?.date_added
                    )
                  }
                  onClick={() => setOpenGenerate(true)}
                >
                  Generate Report
                </Button>
              </span>
            </GenerateTooltip>
          </div>
        </EnhancedToolbar>
        <Divider></Divider>
        <MaterialTable
          options={{
            ...TableOptions,
            selection: false,
            showTitle: false,
            toolbar: false,
            emptyRowsWhenPaging: false,
          }}
          isLoading={loading}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <NoDataListComponent search={searchText} />
              ),
            },
          }}
          columns={column}
          data={data?.holding_relations ?? []}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={data?.total?.aggregate?.count ?? 0}
          page={pagination.offset}
          pageSize={pagination.limit}
        />
      </PaperContainer>
      <AlertDialog
        open={openExport}
        handleClose={() => setExport(false)}
        feature="Generate Data is Processing!"
        message="Generate Data is processing! We’ll send you an email when it’s ready."
        type="Confirm"
      />
      <GenerateReportList
        open={openGenerate}
        title="Generate Report"
        subTitle="Choose company member report"
        handleClose={() => setOpenGenerate(false)}
        handleConfirm={handleDownload}
        note="Note: Generate Data takes quite a long time and can only be done once in 24 hours."
      />
    </RootContainer>
  )
}

export default ListComponent
