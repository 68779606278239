import React, {Fragment, useState, useEffect} from 'react'
import MaterialTable from 'material-table'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  RootContainer,
  WhiteButton,
  TextDarkBlue,
  TextGrey,
} from '../../../../GlobalStyles'
import {Typography, Divider, Button} from '@material-ui/core'
import {useMutation, useQuery, useApolloClient} from '@apollo/react-hooks'
import {
  GET_KPI_SCORING,
  CHECK_SCORING_ALREADY_TAKEN,
  GET_BALOON_STATUS_BUTTON,
} from '../../../../graphql/queries'
import Loading from '../../../../components/loading/LoadingComponent'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Link} from 'react-router-dom'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {useSnackbar} from 'notistack'
import {
  DELETE_KPI_SCORING,
  DELETE_MULTIPLE_KPI_SCORING,
  CLOSE_BALOON,
  IMPORT_DATA,
} from '../../../../graphql/mutations'
import ImportBaloon from '../../../../components/importExcel/ImportBaloon'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import ReadMoreAndLess from 'react-read-more-less'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import {COMPANY_ID} from '../../../../utils/globals'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import DetailDataOrganization from '../../../shared-components/popup/DetailDataOrganization'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {status_data} from '../../../shared-components/data/SharedData'
import {DELETE_CONFIRMATION} from '../kpi-helper'

export default function ScoringComponent(props) {
  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()
  const [openDelete, setOpenDelete] = useState(false)
  const [state, setState] = useState({
    id: '',
    name: '',
    position: '',
  })
  const [openImport, setImport] = useState(false)
  const [openMultipleDelete, setOpenMultipleDelete] = useState(false)
  const [selected, setSelected] = useState([])
  const [dialogCantDel, setDialogCantDel] = useState(false)
  const [openDetail, setDetail] = useState(false)
  const [dataScoring, setDataScoring] = useState(false)
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')

  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )

  const {data: getBaloonStatus, error: errorGetBaloonStatus} = useQuery(
    GET_BALOON_STATUS_BUTTON,
    {
      wlb_skipPatch: true,
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'kpi_scoring_natures',
      },
    }
  )

  const handleClickOpenImport = () => {
    setImport(true)
  }
  const handleCloseImport = () => {
    setImport(false)
  }
  const [deleteScoring] = useMutation(DELETE_KPI_SCORING)
  const [deleteMultipleScoring] = useMutation(DELETE_MULTIPLE_KPI_SCORING)
  const onDeleteScoring = () => {
    const variables = {
      id: state.id,
    }

    deleteScoring({variables})
      .then(() => {
        enqueueSnackbar('Goal Scoring Nature deleted', {
          variant: 'success',
          autoHideDuration: 500,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete Goal Scoring Nature failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 500,
          }
        )
      })
  }

  const [skipQueryCheckScoring, setSkipQueryCheckScoring] = useState(true)
  const {data: checkScoringAlreadyTaken} = useQuery(
    CHECK_SCORING_ALREADY_TAKEN,
    {
      variables: {
        scoring: state.id,
      },
      skip: skipQueryCheckScoring,
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setSkipQueryCheckScoring(true)
      },
    }
  )

  const handleClickOpen = (id, name) => {
    setState({id, name})
    setSkipQueryCheckScoring(false)
  }
  const handleClose = () => {
    setOpenDelete(false)
    setDialogCantDel(false)
  }

  const checkDeleteMultiple = async () => {
    const dataSelected = selected
    const selectedId = []

    dataSelected.map(res => {
      selectedId.push(res.id)
    })

    const {data: checkScoringAlreadyTaken} = await client.query({
      query: CHECK_SCORING_ALREADY_TAKEN,
      variables: {
        scoring: selectedId,
      },
    })

    const isScoringAlreadyTaken =
      checkScoringAlreadyTaken &&
      checkScoringAlreadyTaken.kpi_lists_aggregate &&
      checkScoringAlreadyTaken.kpi_lists_aggregate.aggregate &&
      checkScoringAlreadyTaken.kpi_lists_aggregate.aggregate.count > 0

    if (isScoringAlreadyTaken) {
      setDialogCantDel(true)
    } else {
      setOpenMultipleDelete(true)
    }
  }

  useEffect(() => {
    if (checkScoringAlreadyTaken) {
      const isScoringAlreadyTaken =
        checkScoringAlreadyTaken &&
        checkScoringAlreadyTaken.kpi_lists_aggregate &&
        checkScoringAlreadyTaken.kpi_lists_aggregate.aggregate &&
        checkScoringAlreadyTaken.kpi_lists_aggregate.aggregate.count > 0
      isScoringAlreadyTaken ? setDialogCantDel(true) : setOpenDelete(true)
    }
  }, [checkScoringAlreadyTaken])

  const {data, error, refetch} = useQuery(GET_KPI_SCORING, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      search: `%${props.searchText}%`,
    },
  })

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  if (
    (data === undefined && error === undefined) ||
    getBaloonStatus === undefined
  ) {
    return <Loading />
  } else if (
    error !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationImportError !== undefined
  ) {
    return console.error({
      error,
      errorGetBaloonStatus,
      mutationCloseBaloonError,
      mutationImportError,
    })
  }

  const handleMultipleDelete = () => {
    const dataSelected = selected
    const selectedId = []

    dataSelected.map(res => {
      selectedId.push(res.id)
    })

    deleteMultipleScoring({
      variables: {
        id: selectedId,
      },
    })
      .then(() => {
        setSelected([])
        enqueueSnackbar('Success Delete data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete Failed, please try again later', {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const getTitle = () => {
    if (selected.length === 0) {
      return `Kode Sifat Penilaian ${GLOBAL_GLOSSARY.performance.Goal}`
    } else {
      return (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <Fragment>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>
              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    checkDeleteMultiple()
                  }}
                >
                  Hapus
                </StyledMenuItem>
              </StyledMenus>
            </Fragment>
          )}
        </PopupState>
      )
    }
  }

  const importMutation = async () => {
    try {
      await mutationImport({
        variables: {
          table: 'kpi_scoring_natures',
          file: fileURL,
        },
      })
    } catch (error) {
      console.error({error})
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
    } catch (error) {
      console.error({error})
    }
  }

  const handleOpenDetail = data => {
    setDetail(!openDetail)
    setDataScoring(data)
  }

  return (
    <RootContainer>
      <ImportBaloon
        data={getBaloonStatus}
        closeBaloonMutation={closeBaloonMutation}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
        feature="goal_library"
      />
      <PaperContainer>
        <EnhancedToolbar>
          <Typography variant="h6">
            Sifat Penilaian {GLOBAL_GLOSSARY.performance.Goal}
          </Typography>
          <div style={{display: 'flex'}}>
            <WhiteButton
              variant="contained"
              size="large"
              style={{marginRight: '10px'}}
              onClick={() => handleClickOpenImport()}
              disabled={
                getBaloonStatus.company_import_data_logs.length > 0 &&
                getBaloonStatus.company_import_data_logs[0].status === 'WAITING'
              }
            >
              {getBaloonStatus.company_import_data_logs.length > 0 &&
              getBaloonStatus.company_import_data_logs[0].status ===
                'WAITING' ? (
                <TextGrey>Importing on proces...</TextGrey>
              ) : (
                <TextDarkBlue>
                  Impor Sifat Penilaian {GLOBAL_GLOSSARY.performance.Goal}
                </TextDarkBlue>
              )}
            </WhiteButton>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={{
                pathname: `/performance/kpi/scoring/add`,
                state: {active: 'add-scoring'},
              }}
            >
              Tambahkan Sifat Penilaian {GLOBAL_GLOSSARY.performance.Goal}
            </Button>
          </div>
        </EnhancedToolbar>
        <Divider></Divider>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0'}}
          columns={[
            {
              title: getTitle(),
              field: 'code',
              // defaultSort: 'asc',
              render: rowData => (
                <ReadMoreAndLess
                  className="read-more-content"
                  charLimit={30}
                  readMoreText=" Lebih banyak"
                  readLessText=" Lebih sedikit"
                >
                  {rowData?.code ?? ''}
                </ReadMoreAndLess>
              ),
            },
            {
              title: `Nama Sifat Penilaian ${GLOBAL_GLOSSARY.performance.Goal}`,
              field: 'name',
              render: rowData => (
                <ReadMoreAndLess
                  className="read-more-content"
                  charLimit={30}
                  readMoreText=" Lebih banyak"
                  readLessText=" Lebih sedikit"
                >
                  {rowData?.name ?? ''}
                </ReadMoreAndLess>
              ),
            },
            {
              title: 'Sifat Penilaian ',
              field: 'global_scoring_nature.name',
            },
            {
              title: 'Keterangan',
              field: 'description',
              render: rowData => (
                <ReadMoreAndLess
                  className="read-more-content"
                  charLimit={30}
                  readMoreText=" Lebih banyak"
                  readLessText=" Lebih sedikit"
                >
                  {rowData?.description ?? ''}
                </ReadMoreAndLess>
              ),
            },
            {
              title: 'Status',
              field: 'status',
              lookup: {ACTIVE: 'ACTIVE', INACTIVE: 'INACTIVE'},
              render: rowData => (
                <Typography
                  style={
                    rowData && rowData.status === 'ACTIVE'
                      ? {color: 'green'}
                      : {color: 'red'}
                  }
                >
                  {rowData && rowData.status
                    ? status_data[rowData.status] || rowData.status
                    : ''}
                </Typography>
              ),
            },
            {
              title: 'Aksi',
              field: 'id',
              sorting: false,
              render: rowData => (
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <Fragment>
                      <MoreIcon {...bindTrigger(popupState)}>
                        more_horiz
                      </MoreIcon>

                      <StyledMenus {...bindMenu(popupState)}>
                        <StyledMenuItem
                          style={{justifyContent: 'left'}}
                          onClick={() => {
                            popupState.close()
                            handleOpenDetail(rowData)
                          }}
                        >
                          Rincian
                        </StyledMenuItem>
                        <StyledMenuItem
                          style={{justifyContent: 'left'}}
                          component={Link}
                          to={{
                            pathname: '/performance/kpi/scoring/edit',
                            state: {
                              active: 'edit-scoring',
                              dataScoring: rowData,
                            },
                          }}
                          variant="h6"
                        >
                          Ubah
                        </StyledMenuItem>
                        <StyledMenuItem
                          style={{justifyContent: 'left'}}
                          onClick={() => {
                            handleClickOpen(rowData.id, rowData.name)
                            popupState.close()
                          }}
                        >
                          Hapus
                        </StyledMenuItem>
                      </StyledMenus>
                    </Fragment>
                  )}
                </PopupState>
              ),
            },
          ]}
          data={data?.kpi_scoring_natures ?? []}
          title=""
          options={{
            ...TableOptions,
            showTitle: false,
            toolbar: false,
            emptyRowsWhenPaging: false,
          }}
          components={{
            Toolbar: () => null,
          }}
          localization={{pagination: TableLocalization.pagination}}
          onSelectionChange={rows => setSelected(rows)}
        />
        <DeletePopup
          open={openDelete}
          handleClose={handleClose}
          id={state.id}
          name={state.name}
          mutation={() => onDeleteScoring()}
        />
        <DeletePopup
          open={openMultipleDelete}
          handleClose={() => setOpenMultipleDelete(false)}
          name="item yang dipilih"
          mutation={() => handleMultipleDelete()}
        />
        <ImportPopup
          open={openImport}
          position="Goal Scoring"
          handleClose={handleCloseImport}
          mutation={importMutation}
          fileTemplate={''}
          isAlreadyImported={isAlreadyImported}
          setIsAlreadyImported={setIsAlreadyImported}
          setImport={setImport}
          fileURL={fileURL}
          setFileURL={setFileURL}
          closeBaloon={closeBaloonMutation}
          errorsImport={
            getBaloonStatus.company_import_data_logs.length > 0 &&
            getBaloonStatus.company_import_data_logs[0].reports &&
            getBaloonStatus.company_import_data_logs[0].reports.failed.length >
              0
              ? getBaloonStatus.company_import_data_logs[0].reports.failed
              : []
          }
        />
        <AlertDialog
          open={dialogCantDel}
          handleClose={() => setDialogCantDel(!dialogCantDel)}
          feature={DELETE_CONFIRMATION.header}
          message={DELETE_CONFIRMATION.body.replace(
            '[feature_name]',
            `Sifat Penilaian ${GLOBAL_GLOSSARY.performance.Goal}`
          )}
          positiveTitleButton="Konfirmasi"
          type="Confirm"
        />
        <DetailDataOrganization
          handleClose={handleOpenDetail}
          open={openDetail}
          data={dataScoring}
          status={true}
          feature={`Sifat Penilaian ${GLOBAL_GLOSSARY.performance.Goal}`}
          topBgColor={true}
          title="Rincian Sifat Penilaian"
        />
      </PaperContainer>
    </RootContainer>
  )
}
