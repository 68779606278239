import gql from 'graphql-tag'

export const GET_COMPANY_STRUCTURE_HOLDING = company => gql`
  query {
    company_head: company_profiles(
      where: {id: {_eq: "${company}"}}
    ) {
      id
      logo
      brand_name
      legal_name
      field
      global_company_industry_type {
        id
        name
      }
      type
      people_work_placements_aggregate(where: {status: {_eq: "ACTIVE"}}) {
        aggregate {
          count
        }
      }
      people_work_placements {
        global_user {
          avatar
        }
      }
    }

    members: holding_relations(
      where: {
        company_head: {_eq: "${company}"}
        status: {_eq: "ACTIVE"}
      }
      order_by: {id: asc}
    ) {
      id
      company_member_profile {
        id
        logo
        brand_name
        legal_name
        field
        people_work_placements_aggregate(where: {status: {_eq: "ACTIVE"}}) {
          aggregate {
            count
          }
        }
        people_work_placements {
          global_user {
            avatar
          }
        }
      }
      holding_cluster {
        id
        name
      }
    }
  }
`

export const GET_INDUSTRY_STRUCTURE_HOLDING = company => gql`
  query {
    holding_subcluster: holding_subcluster(
      where: {company: {_eq: "${company}"}, holding_relations: {status: {_eq: "ACTIVE"}}}
      order_by: {parent: asc}
    ) {
      id
      name
      parent
      subcluster_parent: holding_cluster {
        id
        name
        subcluster_parent_count: holding_relations_aggregate(
          where: {status: {_eq: "ACTIVE"}}
        ) {
          aggregate {
            count
          }
        }
        parent_member: holding_relations(where: {status: {_eq: "ACTIVE"}}) {
          company_member_profile {
            id
            logo
          }
        }
      }
      subcluster_count: holding_relations_aggregate(
        where: {status: {_eq: "ACTIVE"}}
      ) {
        aggregate {
          count
        }
      }
      subcluster_member: holding_relations(where: {status: {_eq: "ACTIVE"}}) {
        company_member_profile {
          id
          logo
        }
      }
    }
  }
`
