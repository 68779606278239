import gql from 'graphql-tag'

export const GET_LEAVE_POLICY_CHILDREN = gql`
  {
    time_leave_policy_children {
      id
      name
    }
  }
`
