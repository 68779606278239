import {useQuery} from '@apollo/react-hooks'
import {Button, Divider, Icon, IconButton, Typography} from '@material-ui/core'
import MaterialTable from 'material-table'
import React from 'react'
import Loading from '../../../components/loading/LoadingComponent'
import {
  EnhancedToolbar,
  PaperContainer,
  SkyBlueIcon,
} from '../../../GlobalStyles'
import {GET_HOLDING_MEMBER} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'
import {
  NewTableOptions,
  newTableStyles,
} from '../../shared-components/table/TableOptions'

export default function PendingComponent() {
  const tableClasses = newTableStyles()
  const {data, error} = useQuery(GET_HOLDING_MEMBER(COMPANY_ID, 'ACTIVE'), {
    context: {
      headers: {
        'X-Hasura-Role': 'holding-administrator',
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Pending Activation</Typography>

        <Button variant="contained" color="primary" size="large">
          Add Talent List
        </Button>
      </EnhancedToolbar>
      <Divider />
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontSize: 12}}
          options={{...NewTableOptions, paging: true, sorting: true}}
          columns={[
            {
              title: 'Email',
              field: 'company_member_profile.email',
              cellStyle: {color: '#039be5', fontWeight: 'normal'},
            },
            {
              title: '',
              field: 'action',
              filtering: false,
              render: () => (
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <IconButton>
                    <SkyBlueIcon>replay</SkyBlueIcon>
                  </IconButton>
                  <IconButton>
                    <Icon color="secondary">delete</Icon>
                  </IconButton>
                </div>
              ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={data.holding_relations}
        />
      </div>
    </PaperContainer>
  )
}
