import gql from 'graphql-tag'

export const UPDATE_COMPONENT_SETTING = gql`
  mutation(
    $id: Int!
    $code: String
    $name: String
    $type: Int
    $frequency: String
    $method_base: jsonb
    $calculation: String
    $calculation_mul: float8
    $calculation_value: float8
    $part_of: Int
    $taxable: Boolean
    $include_in_bpjs: Boolean
    $tax_paid_by: Int
    $effective_date: date
    $assignments: [finance_component_assignments_insert_input!]!
  ) {
    update_finance_nonfixed_components_by_pk(
      pk_columns: {id: $id}
      _set: {
        code: $code
        name: $name
        type: $type
        component_method_base: $method_base
        component_frequency: $frequency
        component_calculation: $calculation
        component_calculation_mul: $calculation_mul
        custom_calculation_value: $calculation_value
        part_of: $part_of
        taxable: $taxable
        include_in_bpjs: $include_in_bpjs
        tax_paid_by: $tax_paid_by
        effective_date: $effective_date
      }
    ) {
      id
    }

    delete_finance_component_assignments(where: {component_id: {_eq: $id}}) {
      affected_rows
    }

    insert_finance_component_assignments(objects: $assignments) {
      affected_rows
    }
  }
`

export const DELETE_COMPONENT = gql`
  mutation($id: Int!, $deletedAt: timestamp) {
    update_finance_nonfixed_components_by_pk(
      pk_columns: {id: $id}
      _set: {deletedAt: $deletedAt}
    ) {
      id
    }
  }
`
