import React, {useState} from 'react'
import {withRouter, Link} from 'react-router-dom'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
} from '../../../../../../GlobalStyles'
import {IconButton, Button, Box, Typography} from '@material-ui/core'
import moment from 'moment'
import helperStyle, {AntTabs, StyledTab} from '../../../PayrollPageStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import FormComponent from './FormComponent'
import AssigneeContainer from '../shared-component/form-asignee/AssigneeContainer'
import ModalConfirmation from '../../ModalConfirmation'
import AlertDialog from '../../../../../shared-components/popup/ResultPopup'
import {
  SAVE_COMPONENT_SETTING,
  UPDATE_COMPONENT_SETTING,
} from '../../../../../../graphql/mutations'
import {useMutation} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../../../utils/globals'
import {useSnackbar} from 'notistack'
import {GQL_FINANCE_OPTIONS} from '../../../utils'

const initialValue = {
  isUnique: null,
  company: COMPANY_ID,
  currentCode: null,
  code: null,
  name: null,
  type: null,
  frequency: null,
  method_base: [],
  calculation: null,
  calculation_mul: '',
  calculation_value: '',
  taxable: null,
  include_in_bpjs: null,
  tax_paid_by: null,
  part_of: null,
  effective_date: null,
  assignments: [],
}

const DetailPayrollComponent = props => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()

  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [modalType, setModalType] = useState('')
  const [tab, setTab] = useState(0)
  const [value, setValue] = useState(initialValue)
  const [hasSubmit, setHasSubmit] = useState(false)

  const [employees, setEmployees] = useState([])
  const [assignee, setAssignee] = useState([])
  const [assignments, setAssignments] = useState([])
  const [disableAssignee, setdisableAssignee] = useState(true)
  const [showInformationPopup, setShowInformationPopup] = useState(false)
  const [componentId, setcomponentId] = useState(null)

  const handleChangeTab = (event, newTab) => {
    event.preventDefault()
    setTab(newTab)
  }

  const handleComponent = (target, input) => {
    if (target === 'assignments') {
      const newAssignment = input.map(i =>
        Object.assign(i, {component_id: componentId})
      )
      setAssignments(newAssignment)
    } else {
      setValue({
        ...value,
        [target]: input,
      })
    }
  }

  // eslint-disable-next-line no-unused-vars
  const [addComponent, {data}] = useMutation(
    SAVE_COMPONENT_SETTING,
    GQL_FINANCE_OPTIONS
  )

  const [updateComponent] = useMutation(
    UPDATE_COMPONENT_SETTING,
    GQL_FINANCE_OPTIONS
  )

  const onCloseModal = () => {
    setModalType('')
    setShowModalConfirm(false)
  }

  const onSave = () => {
    setModalType('confirmation')
    setShowModalConfirm(true)
  }

  const onConfirm = () => {
    setHasSubmit(true)
    const variables = {
      company: COMPANY_ID,
      code: String(value.code),
      name: String(value.name),
      type: parseInt(value.type),
      frequency: String(value.frequency),
      method_base: value.method_base,
      calculation: String(value.calculation),
      calculation_mul:
        String(value.calculation) !== 'other'
          ? Number(value.calculation_mul)
          : Number(0),
      calculation_value:
        String(value.calculation) === 'other'
          ? Number(value.calculation_value)
          : Number(0),
      taxable: value.taxable,
      include_in_bpjs: value.include_in_bpjs,
      tax_paid_by: !value.taxable ? 0 : parseInt(value.tax_paid_by),
      part_of: parseInt(value.part_of),
      effective_date: moment(value.effective_date).format('YYYY-MM-DD'),
      assignments: assignments,
    }

    if (
      !value.isUnique ||
      !value.code ||
      !value.name ||
      value.frequency === null ||
      value.type === null ||
      value.taxable === null ||
      value.include_in_bpjs === null ||
      (value.taxable && value.tax_paid_by === null) ||
      value.part_of === null ||
      value.effective_date === null ||
      (value.calculation !== 'other' &&
        (String(value.calculation_mul).length === 0 ||
          value.calculation_mul === null ||
          Number(value.calculation_mul) < 0)) ||
      (value.calculation === 'other' &&
        (String(value.calculation_value).length === 0 ||
          value.calculation_value === null ||
          Number(value.calculation_value) < 0))
    ) {
      enqueueSnackbar('Please input all required data', {
        variant: 'error',
      })
    } else {
      /* if component detail has not been saved then add component */
      if (disableAssignee) {
        addComponent({
          variables,
          ...GQL_FINANCE_OPTIONS,
        })
          .then(data => {
            enqueueSnackbar('Component Added', {
              variant: 'success',
              autoHideDuration: 1500,
            })

            setcomponentId(
              data && data.data.insert_finance_nonfixed_components_one.id
            )
            setValue({
              ...value,
              currentCode: value.code,
            })

            /* undissabled Assignee tab and show information pop-up */
            setdisableAssignee(false)
            setShowInformationPopup(true)
          })
          .catch(e =>
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          )
          .finally(() => {
            onCloseModal()
            setHasSubmit(false)
          })
      } else {
        /* else, update component */
        delete variables.company
        variables.id = componentId

        updateComponent({
          variables,
          ...GQL_FINANCE_OPTIONS,
        })
          .then(() => {
            enqueueSnackbar('Component saved', {
              variant: 'success',
              autoHideDuration: 1500,
            })
            setTimeout(() => {
              props.history.push({
                pathname: '/financial/payroll/setting',
                state: {
                  active: 'settings',
                  tabValue: 1,
                },
              })
            }, 1500)
          })
          .catch(() =>
            enqueueSnackbar('Edit Component Failed', {
              variant: 'error',
              autoHideDuration: 1500,
            })
          )
          .finally(() => {
            onCloseModal()
            setHasSubmit(false)
          })
      }
    }
  }

  const handleCloseInformationPopup = () => {
    setShowInformationPopup(false)
    setTab(1)
  }

  const onCancel = () => {
    setValue(initialValue)
    setTimeout(() => {
      props.history.push({
        pathname: '/financial/payroll/setting',
        state: {
          active: 'settings',
          tabValue: 1,
        },
      })
    }, 1500)
  }

  return (
    <>
      <ModalConfirmation
        open={showModalConfirm}
        onClose={onCloseModal}
        onConfirm={onConfirm}
        type={modalType}
        onCancel={onCancel}
      />
      <PaperContainer>
        <FormToolbar disableGutters>
          <FormTitleContainer>
            <Link
              to={{
                pathname: '/financial/payroll/setting',
                state: {active: 'settings', tabValue: 1},
              }}
            >
              <IconButton edge="end" aria-label="back">
                <ArrowBack />
              </IconButton>
            </Link>
            <AddEditTitle variant="h6" display="inline">
              Add Payroll Component
            </AddEditTitle>
          </FormTitleContainer>
          <div>
            <Button
              size="large"
              className={classes.backButton}
              onClick={() => setShowModalConfirm(true)}
            >
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        </FormToolbar>
        <AntTabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          className={classes.tabs}
        >
          <StyledTab label="Detail" />
          <StyledTab label="Assignee" disabled={disableAssignee} />
        </AntTabs>
        <TabPanel value={tab} index={0}>
          <FormComponent
            setValue={setValue}
            handleComponent={handleComponent}
            value={value}
            hasSubmit={hasSubmit}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          Assign To
          <AssigneeContainer
            handleComponent={handleComponent}
            employees={employees}
            setEmployees={setEmployees}
            assignee={assignee}
            setAssignee={setAssignee}
          />
        </TabPanel>
        <AlertDialog
          open={showInformationPopup}
          feature="Payroll Component"
          handleClose={handleCloseInformationPopup}
          type="Confirm"
          message={
            <div>
              The Component has been saved. Please set the employee to be
              assigned in the <b>Assignee</b> tab or you can change it later in
              the Edit form
            </div>
          }
        />
      </PaperContainer>
    </>
  )
}

export default withRouter(DetailPayrollComponent)

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}
