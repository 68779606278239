import {COMPANY_ID} from '../../../../utils/globals'
import {getSearchVariables} from '../../../../utils/searchFilters'

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

export const MENUS = ['activity', 'attendance-report']

export const getAttendanceFilterVariables = ({
  searchText = '',
  filterData = {},
}) => {
  const search = `%${searchText}%`

  const where = {
    ...getSearchVariables(filterData),
    company: {_eq: COMPANY_ID},
    status: {_eq: 'ACTIVE'},
    _or: searchText
      ? [
          {global_user: {name: {_ilike: search}}},
          {regno: {_ilike: search}},
          {company_job_profile: {title: {_ilike: search}}},
        ]
      : undefined,
  }

  return where
}

export const getDataVariableAttendanceReport = filterData => {
  const {include, exclude} = filterData
  const _ageMin = include && include.age && include.age.min
  const _ageMax = include && include.age && include.age.max
  const _ageMinEX = exclude && exclude.age && exclude.age.min
  const _ageMaxEX = exclude && exclude.age && exclude.age.max

  const variables = {
    jobProfile: include && include.position,
    exc_jobProfile: exclude && exclude.position,
    organization: include && include.organizationUnit,
    exc_organization: exclude && exclude.organizationUnit,
    level: include && include.jobLevel,
    exc_level: exclude && exclude.jobLevel,
    office: include && include.officeLocation,
    exc_office: exclude && exclude.officeLocation,
    job_grades: include && include.jobGrade,
    exc_job_grades: exclude && exclude.jobGrade,
    employeeType: include && include.employeeType,
    exc_employeeType: exclude && exclude.employeeType,
    timeArrangement: include && include.timeArrangement,
    exc_timeArrangement: exclude && exclude.timeArrangement,
    workArrangement: include && include.workingArrangement,
    exc_workArrangement: exclude && exclude.workingArrangement,
    job_title: include && include.jobTitle,
    exc_job_title: exclude && exclude.jobTitle,
    // organization_level: JSON
    // exc_organization_level: JSON
    // job_family:JSON
    // exc_job_family:JSON
    gender: include && include.gender,
    exc_gender: exclude && exclude.gender,
    isFilterBirth: Boolean(_ageMin || _ageMax) || false,
    birthFrom: _ageMax,
    birthTo: _ageMin,
    isFilterExcBirth: Boolean(_ageMinEX || _ageMaxEX) || false,
    exc_birthFrom: _ageMaxEX,
    exc_birthTo: _ageMinEX,
  }
  return variables
}
