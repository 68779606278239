import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {
  FormChildContainer,
  FormChildContainerPayroll,
  FormChildTitle,
  TitleName,
  TitleNumber,
  FormChildInput,
} from '../../../../../../GlobalStyles'
import FieldInformationTooltip from '../../../../../shared-components/tooltip/FieldInformationTooltip'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core'
// import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
// import DateFnsUtils from '@date-io/date-fns'
import {useSnackbar} from 'notistack'
import {FlexBetween, CenterWrapper} from '../../../PayrollPageStyles'
import {
  onChangeNumber,
  onChangerWithHasChange,
  // stringHelperText,
  // checkStringInput,
  inputRequiredHelperText,
} from '../formValidation.helper'
import {GQL_FINANCE_OPTIONS} from '../../../utils'
import PayrollSettingsWrapper from '../../PayrollSettingsWrapper'
import {GET_TAX_SETTINGS} from '../../../../../../graphql/queries'
import {UPDATE_TAX_SETTING} from '../../../../../../graphql/mutations'
import TaxOfficeComponent from './list-components/ComponentTaxOffice'
import FormWarning from './list-components/FormWarning'

const taxMethods = [
  {
    name: 'Gross Up Method',
    value: 'grossup',
  },
  // Hotfix: temporary hide
  // {
  //   name: 'Gross Method',
  //   value: 'gross',
  // },
  {
    name: 'Nett Method',
    value: 'net',
  },
]

const informationText = {
  applyPPh: (
    <>
      <div>
        Income Tax Article 21 is a tax imposed on the salary, wages, honorarium,
        allowances, and other payments. This income is earned in any form in
        connection with work or position, and activities carried out by an
        individual who is a resident tax subject.
        <br />
      </div>
      <div>
        Income Tax Article 26 is a stage tax that is responsible for foreign
        taxpayers from Indonesia.
      </div>
    </>
  ),
  companyName: `Name of company that provides income to employees`,
  NPWP: `Company Identification Tax Number`,
  headCompanyName: `Head of the Company's Name as withholding tax`,
  headCompanyNPWP: `Head of the Company's NPWP as withholding tax`,
  taxMethod: (
    <>
      <div>
        1. Gross up is a tax withholding method where the company provides tax
        allowances that are equal to the amount of tax withheld from employees.
      </div>
      <div>
        2. Gross is a tax withholding method where employees bear their own
        income tax amount.
      </div>
      <div>
        3. Net is a tax withholding method where the company bears the taxes of
        its employees
      </div>
    </>
  ),
  PTKP: `Non-Taxable Income (PTKP) is the amount of personal taxpayer income that is exempt from Tax Article 21.`,
}
const TaxPayrollSettings = props => {
  const {activeComponent, setActiveComponent, selectedId} = props
  const {data: taxSettingData, loading, error, refetch} = useQuery(
    GET_TAX_SETTINGS,
    {
      variables: {
        id: selectedId,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )
  const [updateTaxSetting] = useMutation(
    UPDATE_TAX_SETTING,
    GQL_FINANCE_OPTIONS
  )
  const {enqueueSnackbar} = useSnackbar()
  const [companyName, setCompanyName] = useState(null)
  const [headDate, setHeadDate] = useState(null)
  const [headName, setHeadName] = useState(null)
  const [headNumber, setHeadNumber] = useState(null)
  const [npwp, setNpwp] = useState(null)
  const [npwpDate, setNpwpDate] = useState(null)
  const [officeAddress, setOfficeAddress] = useState('-')
  const [officeCode, setOfficeCode] = useState('-')
  const [officeName, setOfficeName] = useState('-')
  const [pph, setPph] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isReTrigger, setIsReTrigger] = useState(false)
  const [hasChange, setHasChange] = useState(false)
  const [hasSubmit, setHasSubmit] = useState(false)

  /** Tax Method */
  const [permanent, setPermanent] = useState(null)
  const [contract, setContract] = useState(null)
  const [partTimer, setPartTimer] = useState(null)
  const [freelance, setFreelance] = useState(null)
  const [associate, setAssociate] = useState(null)
  const [trainee, setTrainee] = useState(null)
  const [internship, setInternship] = useState(null)
  const [apprentice, setApprentice] = useState(null)
  const [outsource, setOutSource] = useState(null)
  // const [probation, setProbation] = useState(null)

  useEffect(() => {
    if (taxSettingData) {
      if (taxSettingData.company_addresses_by_pk) {
        const addressData = taxSettingData.company_addresses_by_pk
        if (addressData.office_code) {
          setOfficeCode(addressData.office_code)
        }
        if (addressData.office_name) {
          setOfficeName(addressData.office_name)
        }
        if (addressData.address) {
          setOfficeAddress(addressData.address)
        }
      }
      if (taxSettingData.company_addresses_by_pk.address_misc_fields) {
        if (taxSettingData.company_addresses_by_pk.address_misc_fields.IDN) {
          const taxData =
            taxSettingData.company_addresses_by_pk.address_misc_fields.IDN.tax
          const taxMethod = id => {
            const filtered = taxData.taxMethods.filter(item => item.id === id)
            if (filtered.length > 0) {
              return filtered[0].method
            } else {
              return null
            }
          }
          if (taxData.payPPH21 !== null) {
            setPph(taxData.payPPH21)
          }
          if (taxData.companyNPWP) {
            setNpwp(taxData.companyNPWP)
          }
          if (taxData.companyRegDate) {
            setNpwpDate(taxData.companyRegDate)
          }
          if (taxData.companyName) {
            setCompanyName(taxData.companyName)
          }
          if (taxData.responsibleName) {
            setHeadName(taxData.responsibleName)
          }
          if (taxData.responsibleNPWP) {
            setHeadNumber(taxData.responsibleNPWP)
          }
          if (taxData.responsibleRegDate) {
            setHeadDate(taxData.responsibleRegDate)
          }
          if (taxMethod(1)) {
            setPermanent(taxMethod(1))
          }
          if (taxMethod(2)) {
            setContract(taxMethod(2))
          }
          if (taxMethod(3)) {
            setPartTimer(taxMethod(3))
          }
          if (taxMethod(4)) {
            setFreelance(taxMethod(4))
          }
          if (taxMethod(5)) {
            setAssociate(taxMethod(5))
          }
          if (taxMethod(6)) {
            setTrainee(taxMethod(6))
          }
          if (taxMethod(7)) {
            setInternship(taxMethod(7))
          }
          if (taxMethod(8)) {
            setApprentice(taxMethod(8))
          }
          if (taxMethod(9)) {
            setOutSource(taxMethod(9))
          }
          // if (taxMethod(10)) {
          //   setProbation(taxMethod(10))
          // }
        }
      }
    }
  }, [taxSettingData, isReTrigger])
  const onUpdateTax = () => {
    setHasSubmit(true)
    if (
      pph === null ||
      (pph &&
        (!companyName ||
          !npwp ||
          !headName ||
          !headNumber ||
          !permanent ||
          !contract ||
          !partTimer ||
          !freelance ||
          !associate ||
          !trainee ||
          !internship ||
          !apprentice ||
          !outsource))
      // || !probation
      // npwpDate ||
      // headDate ||
    ) {
      enqueueSnackbar('Please input all required data', {
        variant: 'error',
      })
    } else {
      setIsDisabled(true)
      var variables = {
        address: selectedId,
        plugin: {
          IDN: {
            tax: {
              payPPH21: pph,
              companyNPWP: npwp,
              companyRegDate: npwpDate,
              companyName: companyName,
              responsibleName: headName,
              responsibleNPWP: headNumber,
              responsibleRegDate: headDate,
              taxMethods: [
                {
                  id: 1,
                  name: 'permanent',
                  method: permanent,
                },
                {
                  id: 2,
                  name: 'contract',
                  method: contract,
                },
                {
                  id: 3,
                  name: 'parttimer',
                  method: partTimer,
                },
                {
                  id: 4,
                  name: 'freelance',
                  method: freelance,
                },
                {
                  id: 5,
                  name: 'associate',
                  method: associate,
                },
                {
                  id: 6,
                  name: 'trainee',
                  method: trainee,
                },
                {
                  id: 7,
                  name: 'internship',
                  method: internship,
                },
                {
                  id: 8,
                  name: 'apprentice',
                  method: apprentice,
                },
                {
                  id: 9,
                  name: 'outsource',
                  method: outsource,
                },
                // {
                //   id: 10,
                //   name: 'probation',
                //   method: probation,
                // },
              ],
            },
          },
        },
      }
      updateTaxSetting({variables, ...GQL_FINANCE_OPTIONS})
        .then(() => {
          enqueueSnackbar('Tax settings saved', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          refetch()
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        })
        .finally(() => {
          setIsDisabled(false)
          setHasChange(false)
          setHasSubmit(false)
        })
    }
  }

  const onCancel = () => {
    refetch()
    setIsReTrigger(curr => !curr)
    setHasChange(false)
    setHasSubmit(false)
    if (!taxSettingData.company_addresses_by_pk.address_misc_fields) {
      setCompanyName(null)
      setHeadDate(null)
      setHeadName(null)
      setHeadNumber(null)
      setNpwp(null)
      setNpwpDate(null)
      setOfficeAddress('-')
      setOfficeName('-')
      setPph(null)

      /** Tax Method */
      setPermanent(null)
      setContract(null)
      setPartTimer(null)
      setFreelance(null)
      setAssociate(null)
      setTrainee(null)
      setInternship(null)
      setApprentice(null)
      setOutSource(null)
      // setProbation(null)
    }
  }

  return (
    <PayrollSettingsWrapper
      activeComponent={activeComponent}
      setActiveComponent={setActiveComponent}
      onConfirm={onUpdateTax}
      isDisabledButton={taxSettingData === undefined || isDisabled}
      onCancel={onCancel}
      secondaryButton={'Reset'}
      hasChange={hasChange}
    >
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {taxSettingData && (
        <>
          <TaxOfficeComponent
            code={officeCode}
            name={officeName}
            address={officeAddress}
          />
          <div style={{marginTop: 20}}>
            <FormChildContainerPayroll>
              <FormChildTitle>
                <TitleNumber>1.</TitleNumber>
                <TitleName>
                  Does your company apply PPh / 21/26 tax calculations?
                </TitleName>
                <FieldInformationTooltip title={informationText.applyPPh} />
              </FormChildTitle>
              <FormChildInput>
                <FormWarning>
                  <RadioGroup
                    row
                    name="position"
                    value={pph}
                    onChange={e => {
                      setPph(e.target.value === 'true')
                      setHasChange(true)
                    }}
                    error={hasSubmit && pph === null}
                    helperText={inputRequiredHelperText(
                      hasSubmit,
                      pph === null
                    )}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormWarning>
              </FormChildInput>
            </FormChildContainerPayroll>
            {pph && (
              <FormChildContainer>
                <FormChildTitle>
                  <TitleNumber>2.</TitleNumber>
                  <TitleName>Input Withholder Tax ID Number*</TitleName>
                </FormChildTitle>
                <FormControl>
                  <FormChildTitle>
                    <InputLabel>Company Name</InputLabel>
                    <FieldInformationTooltip
                      title={informationText.companyName}
                    />
                  </FormChildTitle>
                  <FormChildInput>
                    <FormWarning fullWidth>
                      <TextField
                        style={{width: '100%'}}
                        size="small"
                        margin="normal"
                        variant="outlined"
                        placeholder="Add Company Name"
                        value={companyName}
                        onChange={e =>
                          onChangerWithHasChange(
                            e,
                            setCompanyName,
                            setHasChange
                          )
                        }
                        error={hasSubmit && pph && !companyName}
                        helperText={inputRequiredHelperText(
                          hasSubmit,
                          pph && !companyName
                        )}
                      />
                    </FormWarning>
                  </FormChildInput>
                </FormControl>
                <FormControl>
                  <FormChildTitle>
                    <InputLabel>Company NPWP</InputLabel>
                    <FieldInformationTooltip title={informationText.NPWP} />
                  </FormChildTitle>
                  <FormChildInput>
                    <FormWarning fullWidth>
                      <TextField
                        style={{width: '100%'}}
                        size="small"
                        margin="normal"
                        variant="outlined"
                        placeholder="Add NPWP Number"
                        value={npwp}
                        onChange={e =>
                          onChangerWithHasChange(e, setNpwp, setHasChange)
                        }
                        error={hasSubmit && pph && !npwp}
                        helperText={inputRequiredHelperText(
                          hasSubmit,
                          pph && !npwp
                        )}
                      />
                    </FormWarning>
                  </FormChildInput>
                </FormControl>
                {/* <FormControl>
                <InputLabel>Company NPWP Registration Date*</InputLabel>
                <FormChildInput>
                  <FormWarning fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy-MM-dd"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        inputVariant="outlined"
                        value={npwpDate}
                        onChange={value => {
                          setNpwpDate(value)
                          setHasChange(true)
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormWarning>
                </FormChildInput>
              </FormControl> */}
                <FormControl>
                  <FormChildTitle>
                    <InputLabel>Head of the Company&apos;s Name</InputLabel>
                    <FieldInformationTooltip
                      title={informationText.headCompanyName}
                    />
                  </FormChildTitle>
                  <FormChildInput>
                    <FormWarning fullWidth>
                      <TextField
                        style={{width: '100%'}}
                        size="small"
                        margin="normal"
                        variant="outlined"
                        placeholder="Add Head of the Company's Name"
                        value={headName}
                        onChange={e =>
                          onChangerWithHasChange(e, setHeadName, setHasChange)
                        }
                        error={hasSubmit && pph && !headName}
                        helperText={inputRequiredHelperText(
                          hasSubmit,
                          pph && !headName
                        )}
                      />
                    </FormWarning>
                  </FormChildInput>
                </FormControl>
                <FormControl>
                  <FormChildTitle>
                    <InputLabel>Head of the Company&apos;s NPWP</InputLabel>
                    <FieldInformationTooltip
                      title={informationText.headCompanyNPWP}
                    />
                  </FormChildTitle>
                  <FormChildInput>
                    <FormWarning fullWidth>
                      <TextField
                        style={{width: '100%'}}
                        size="small"
                        margin="normal"
                        variant="outlined"
                        placeholder="Add Head of the Company's NPWP Number"
                        value={headNumber}
                        onChange={e => {
                          onChangeNumber(e, setHeadNumber)
                          setHasChange(true)
                        }}
                        error={hasSubmit && pph && !headNumber}
                        helperText={inputRequiredHelperText(
                          hasSubmit,
                          pph && !headNumber
                        )}
                      />
                    </FormWarning>
                  </FormChildInput>
                </FormControl>
                {/* <FormControl>
                <InputLabel>
                  Head of the Company NPWP Registration Date*
                </InputLabel>
                <FormChildInput>
                  <FormWarning fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy-MM-dd"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        inputVariant="outlined"
                        value={headDate}
                        onChange={value => {
                          setHeadDate(value)
                          setHasChange(true)
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormWarning>
                </FormChildInput>
              </FormControl> */}
              </FormChildContainer>
            )}
            {pph && (
              <FormChildContainer fullWidth>
                <FormChildTitle>
                  <TitleNumber>3.</TitleNumber>
                  <TitleName>Tax Method*</TitleName>
                  <FieldInformationTooltip title={informationText.taxMethod} />
                </FormChildTitle>

                <FormChildInput>
                  <FormWarning>
                    <FlexBetween>
                      <TitleName
                        style={{
                          width: '200px',
                        }}
                      >
                        Permanent Employee
                      </TitleName>
                      <FormControl error={hasSubmit && pph && !permanent}>
                        <RadioGroup
                          row
                          name="permanent"
                          value={permanent}
                          onChange={e => {
                            setPermanent(e.target.value)
                            setHasChange(true)
                          }}
                        >
                          {taxMethods.map((item, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.name}
                              />
                            )
                          })}
                        </RadioGroup>
                        {hasSubmit && pph && !permanent && (
                          <FormHelperText>
                            Need to filled mandatory field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FlexBetween>
                  </FormWarning>
                </FormChildInput>

                <FormChildInput>
                  <FormWarning>
                    <FlexBetween>
                      <TitleName
                        style={{
                          width: '200px',
                        }}
                      >
                        Contract Employee
                      </TitleName>
                      <FormControl error={hasSubmit && pph && !contract}>
                        <RadioGroup
                          row
                          name="contract"
                          value={contract}
                          onChange={e => {
                            setContract(e.target.value)
                            setHasChange(true)
                          }}
                        >
                          {taxMethods.map((item, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.name}
                              />
                            )
                          })}
                        </RadioGroup>
                        {hasSubmit && pph && !contract && (
                          <FormHelperText>
                            Need to filled mandatory field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FlexBetween>
                  </FormWarning>
                </FormChildInput>

                <FormChildInput>
                  <FormWarning>
                    <FlexBetween>
                      <TitleName
                        style={{
                          width: '200px',
                        }}
                      >
                        Part Timer
                      </TitleName>
                      <FormControl error={hasSubmit && pph && !partTimer}>
                        <RadioGroup
                          row
                          name="partTimer"
                          value={partTimer}
                          onChange={e => {
                            setPartTimer(e.target.value)
                            setHasChange(true)
                          }}
                        >
                          {taxMethods.map((item, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.name}
                              />
                            )
                          })}
                        </RadioGroup>
                        {hasSubmit && pph && !partTimer && (
                          <FormHelperText>
                            Need to filled mandatory field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FlexBetween>
                  </FormWarning>
                </FormChildInput>

                <FormChildInput>
                  <FormWarning>
                    <FlexBetween>
                      <TitleName
                        style={{
                          width: '200px',
                        }}
                      >
                        Freelance
                      </TitleName>
                      <FormControl error={hasSubmit && pph && !freelance}>
                        <RadioGroup
                          row
                          name="freelance"
                          value={freelance}
                          onChange={e => {
                            setFreelance(e.target.value)
                            setHasChange(true)
                          }}
                        >
                          {taxMethods.map((item, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.name}
                              />
                            )
                          })}
                        </RadioGroup>
                        {hasSubmit && pph && !freelance && (
                          <FormHelperText>
                            Need to filled mandatory field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FlexBetween>
                  </FormWarning>
                </FormChildInput>

                <FormChildInput>
                  <FormWarning>
                    <FlexBetween>
                      <TitleName
                        style={{
                          width: '200px',
                        }}
                      >
                        Associate
                      </TitleName>
                      <FormControl error={hasSubmit && pph && !associate}>
                        <RadioGroup
                          row
                          name="associate"
                          value={associate}
                          onChange={e => {
                            setAssociate(e.target.value)
                            setHasChange(true)
                          }}
                        >
                          {taxMethods.map((item, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.name}
                              />
                            )
                          })}
                        </RadioGroup>
                        {hasSubmit && pph && !associate && (
                          <FormHelperText>
                            Need to filled mandatory field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FlexBetween>
                  </FormWarning>
                </FormChildInput>

                <FormChildInput>
                  <FormWarning>
                    <FlexBetween>
                      <TitleName
                        style={{
                          width: '200px',
                        }}
                      >
                        Trainee
                      </TitleName>
                      <FormControl error={hasSubmit && pph && !trainee}>
                        <RadioGroup
                          row
                          name="trainee"
                          value={trainee}
                          onChange={e => {
                            setTrainee(e.target.value)
                            setHasChange(true)
                          }}
                        >
                          {taxMethods.map((item, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.name}
                              />
                            )
                          })}
                        </RadioGroup>
                        {hasSubmit && pph && !trainee && (
                          <FormHelperText>
                            Need to filled mandatory field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FlexBetween>
                  </FormWarning>
                </FormChildInput>

                <FormChildInput>
                  <FormWarning>
                    <FlexBetween>
                      <TitleName
                        style={{
                          width: '200px',
                        }}
                      >
                        Internship
                      </TitleName>
                      <FormControl error={hasSubmit && pph && !internship}>
                        <RadioGroup
                          row
                          name="internship"
                          value={internship}
                          onChange={e => {
                            setInternship(e.target.value)
                            setHasChange(true)
                          }}
                        >
                          {taxMethods.map((item, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.name}
                              />
                            )
                          })}
                        </RadioGroup>
                        {hasSubmit && pph && !internship && (
                          <FormHelperText>
                            Need to filled mandatory field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FlexBetween>
                  </FormWarning>
                </FormChildInput>

                <FormChildInput>
                  <FormWarning>
                    <FlexBetween>
                      <TitleName
                        style={{
                          width: '200px',
                        }}
                      >
                        Apprentice
                      </TitleName>
                      <FormControl error={hasSubmit && pph && !apprentice}>
                        <RadioGroup
                          row
                          name="apprentice"
                          value={apprentice}
                          onChange={e => {
                            setApprentice(e.target.value)
                            setHasChange(true)
                          }}
                        >
                          {taxMethods.map((item, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.name}
                              />
                            )
                          })}
                        </RadioGroup>
                        {hasSubmit && pph && !apprentice && (
                          <FormHelperText>
                            Need to filled mandatory field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FlexBetween>
                  </FormWarning>
                </FormChildInput>

                <FormChildInput>
                  <FormWarning>
                    <FlexBetween>
                      <TitleName
                        style={{
                          width: '200px',
                        }}
                      >
                        Outsource
                      </TitleName>
                      <FormControl error={hasSubmit && pph && !outsource}>
                        <RadioGroup
                          row
                          name="outsource"
                          value={outsource}
                          onChange={e => {
                            setOutSource(e.target.value)
                            setHasChange(true)
                          }}
                        >
                          {taxMethods.map((item, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.name}
                              />
                            )
                          })}
                        </RadioGroup>
                        {hasSubmit && pph && !outsource && (
                          <FormHelperText>
                            Need to filled mandatory field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FlexBetween>
                  </FormWarning>
                </FormChildInput>

                {/* <FormChildInput>
                  <FormWarning>
                    <FlexBetween>
                      <TitleName
                        style={{
                          width: '200px',
                        }}
                      >
                        Probation
                      </TitleName>
                      <FormControl error={hasSubmit && pph && !probation}>
                        <RadioGroup
                          row
                          name="probation"
                          value={probation}
                          onChange={e => {
                            setProbation(e.target.value)
                            setHasChange(true)
                          }}
                        >
                          {taxMethods.map((item, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.name}
                              />
                            )
                          })}
                        </RadioGroup>
                        {hasSubmit && pph && !probation && (
                          <FormHelperText>
                            Need to filled mandatory field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FlexBetween>
                  </FormWarning>
                </FormChildInput> */}
              </FormChildContainer>
            )}
            {pph && (
              <FormChildContainer>
                <FormChildTitle>
                  <TitleNumber style={{color: 'white'}}>4.</TitleNumber>
                  <TitleName>PTKP Information</TitleName>
                  <FieldInformationTooltip title={informationText.PTKP} />
                </FormChildTitle>

                <FormChildInput style={{paddingTop: 12}}>
                  <FormWarning>
                    <TitleName>
                      Taxpayer&apos;s Nontaxable Value Individual
                    </TitleName>
                    <div style={{paddingTop: 4}}>
                      <Typography variant="subtitle" color="textSecondary">
                        Rp. 60.000.000
                      </Typography>
                    </div>
                  </FormWarning>
                </FormChildInput>

                <FormChildInput style={{paddingTop: 12}}>
                  <FormWarning>
                    <TitleName>
                      Spouse&apos;s/Individual Nontaxable Income dependence
                    </TitleName>
                    <div style={{paddingTop: 4}}>
                      <Typography variant="subtitle" color="textSecondary">
                        Rp. 5.000.000
                      </Typography>
                    </div>
                  </FormWarning>
                </FormChildInput>
              </FormChildContainer>
            )}
          </div>
        </>
      )}
    </PayrollSettingsWrapper>
  )
}
export default withRouter(TaxPayrollSettings)
