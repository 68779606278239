import {
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {FlexCenters, PaperContainer} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import TabsAbout from './tabs/TabsAboutComponent'
import TabsCommentComponent from './tabs/TabsCommentComponent'
import TabsReviewComponent from './tabs/TabsReviewComponent'
import {REAST_API_URL, TOKEN} from '../../../../utils/globals'
import Axios from 'axios'
import ManageCurriculum, {
  RenderLessonDetail,
} from '../form/tabs/CourseManageCurriculum'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
  root: {padding: 24},
  iconImg: {
    width: 227,
    borderRadius: 5,
    marginRight: 24,
    objectFit: 'cover',
  },
  mb12: {marginBottom: 12},
  ml12: {marginLeft: 12},
  dividers: {margin: '24px -24px !important'},
  mb8: {marginBottom: 8},
  mb16: {marginBottom: 16},
  avatar: {
    width: 42,
    height: 42,
    margin: '0 24px 0 0',
    borderRadius: 5,
    objectFit: 'cover',
  },
}))

const style = {
  colorPrimary: {
    color: '#014A62',
  },
  saveBtn: {
    backgroundColor: '#039BE5',
    color: '#fff',
    textTransform: 'none',
    marginLeft: 12,
  },
  header: {
    backgroundColor: '#fff',
    width: '-webkit-fill-available',
    boxShadow: '0px 3px 6px #0000001A',
    zIndex: 1,
  },
  grey: {color: '#A9A8A8'},
  chipSkyBlue: {backgroundColor: '#039BE5', marginRight: 16, marginTop: 16},
  iconSkyBlue: {
    color: '#039BE5',
    border: '1px solid #039BE5',
    padding: 5,
    marginTop: 12,
  },
  iconDisabled: {
    color: '#A9A8A8',
    border: '1px solid #A9A8A8',
    padding: 5,
    marginTop: 12,
  },
  iconRed: {
    color: '#DD5962',
    border: '1px solid #DD5962',
    padding: 5,
    marginTop: 12,
  },
  addContainer: {
    background: '#F7F8F9 0% 0% no-repeat padding-box',
    border: '1px dashed #A9A8A8',
    width: '400px',
    padding: '85px 0',
    textAlign: 'center',
    marginTop: 40,

    position: 'relative',
  },
  input: {
    opacity: 0.0,
    MsFilter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)',
    filter: 'alpha(opacity=0)',
    MozOpacity: 0.0,
    KhtmlOpacity: 0.0,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  expansionWithoutBottom: {background: '#f7f8f9'},
  expansion: {background: '#f7f8f9', marginBottom: 20},
  customEditorFooter: {
    width: '100%',
    height: 20,
    borderRadius: '0 0 5px 5px',
    backgroundColor: '#014a62',
  },
  textSkyBlue: {color: '#039BE5'},
  uploadedImg: {width: 400, marginRight: 16},
  answerContainer: {
    border: '1px solid #E5E5E5',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    padding: 6,
    marginBottom: 16,
  },
  topicContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}

const DetailComponent = props => {
  const classes = useStyles()
  const [tabsValue, setTabsValue] = useState(0)
  const [course, setCourse] = useState('')
  const [comments, setComments] = useState([])
  const [reviews, setReviews] = useState([])
  const [lessonDetail, setLessonDetail] = useState('')

  useEffect(() => {
    getCourse()
    getComments()
    getReviews()
  }, [])

  useEffect(() => {
    if (lessonDetail) {
      setTabsValue(0)
    }
  }, [lessonDetail])

  const {match} = props
  const getCourse = async () => {
    try {
      const response = await Axios.get(
        `${REAST_API_URL}/academy/course/${match.params.id}`,

        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      setCourse(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const getComments = async () => {
    try {
      const response = await Axios.get(
        `${REAST_API_URL}/academy/course/${match.params.id}/discussion`,

        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      setComments(response.data.data)
    } catch (error) {
      console.error(error)
    }
  }

  const getReviews = async () => {
    try {
      const response = await Axios.get(
        `${REAST_API_URL}/academy/course/${match.params.id}/review`,

        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      setReviews(response.data.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const getCourseById = async () => {
    try {
      const response = await Axios.get(
        `${REAST_API_URL}/academy/course/${course.id}`,
        // `${REAST_API_URL}/academy/course/${284}`,

        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      getCourseBySlug(response.data.slug)
    } catch (error) {
      console.error(error)
    }
  }

  const getCourseBySlug = async slug => {
    try {
      const response = await Axios.get(
        `${REAST_API_URL}/academy/course/get/${slug}`,
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      setCourse(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <PaperContainer>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          display: 'flex',
        }}
        disableGutters
      >
        <FlexCenters>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() =>
              props.history.push({
                state: {
                  searchable: true,
                },
                pathname: '/learning/course',
              })
            }
          >
            <ArrowBack />
          </IconButton>

          <Typography variant="h6" className={classes.ml12}></Typography>
        </FlexCenters>
      </Toolbar>
      {!lessonDetail ? (
        <>
          <Tabs
            value={tabsValue}
            onChange={handleChangeTabs}
            indicatorColor="secondary"
            classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
          >
            <Tab label="Tentang" className={classes.tabRoot} />
            <Tab label="Kurikulum" className={classes.tabRoot} />
            <Tab label="Komentar" className={classes.tabRoot} />
            <Tab label="Ulasan" className={classes.tabRoot} />
          </Tabs>
          <div className={classes.root}>
            {tabsValue === 0 && <TabsAbout classes={classes} data={course} />}
            {tabsValue === 1 && (
              <ManageCurriculum
                style={style}
                courseId={match.params.id}
                isInCourseDetail={true}
                setLessonDetail={setLessonDetail}
              />
            )}
            {tabsValue === 2 && (
              <TabsCommentComponent
                classes={classes}
                data={comments}
                course={course}
                getComments={getComments}
              />
            )}
            {tabsValue === 3 && (
              <TabsReviewComponent
                classes={classes}
                data={reviews}
                course={course}
                getReviews={getReviews}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <Tabs
            value={tabsValue}
            onChange={handleChangeTabs}
            indicatorColor="secondary"
            classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
          >
            <Tab label="Detail" className={classes.tabRoot} />
            <Tab label="Comment" className={classes.tabRoot} />
          </Tabs>
          <div className={classes.root}>
            {tabsValue === 0 && course && (
              <RenderLessonDetail
                lesson={lessonDetail}
                courseId={course.id}
                style={style}
                getCourseById={getCourseById}
              />
            )}
            {tabsValue === 1 && (
              <TabsCommentComponent
                classes={classes}
                data={comments}
                course={course}
                getComments={getComments}
              />
            )}
          </div>
        </>
      )}
    </PaperContainer>
  )
}
export default withRouter(DetailComponent)
