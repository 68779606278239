import gql from 'graphql-tag'

export const UPDATE_KPI_SCORING = gql`
  mutation updateScoringNature(
    $code: String
    $name: String
    $description: String
    $status: enum_global_activation_status
    $id: Int
    $global_scoring: Int
  ) {
    update_kpi_scoring_natures(
      _set: {
        code: $code
        name: $name
        description: $description
        status: $status
        global_scoring: $global_scoring
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
