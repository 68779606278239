import gql from 'graphql-tag'

export const ADD_PEOPLE_DEPENDENT = gql`
  mutation($data: people_dependants_insert_input!) {
    insert_people_dependants_one(object: $data) {
      id
      name
      relationship
      birthplace
      birthdate
      identity_number
      marital_status
      working_status
      dependant_fields
      gender
      is_beneficiary
      civil_servant
      is_company_group_employee
      company
      blood_type
      global_country {
        id
        name: country_name
      }
      global_province {
        id
        name
      }
      global_city {
        id
        name
      }
      district
      sub_district
      postal_code
      address
      dependent_status
    }
  }
`

export const DELETE_PEOPLE_DEPENDENT = gql`
  mutation($id: Int!) {
    update_people_dependants_by_pk(
      pk_columns: {id: $id}
      _set: {deletedAt: "now()"}
    ) {
      id
      deletedAt
    }
  }
`

export const UPDATE_PEOPLE_DEPENDENT = gql`
  mutation($id: Int!, $data: people_dependants_set_input!) {
    update_people_dependants_by_pk(pk_columns: {id: $id}, _set: $data) {
      id
      name
      relationship
      birthplace
      birthdate
      identity_number
      marital_status
      working_status
      dependant_fields
      gender
      is_beneficiary
      civil_servant
      is_company_group_employee
      company
      blood_type
      global_country {
        id
        name: country_name
      }
      global_province {
        id
        name
      }
      global_city {
        id
        name
      }
      district
      sub_district
      postal_code
      address
      dependent_status
    }
  }
`
