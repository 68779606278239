import React from 'react'
import {PageContainer} from '../../../GlobalStyles'
import Calendar from './calendar/CalendarComponent'
import Card from '../../../components/card/CardComponent'
import AddEvent from './calendar/add-event/AddEvent'

const renderComponent = (activeComponent, state) => {
  switch (activeComponent) {
    case 'add-event':
      return <AddEvent />
    case 'edit-event':
      return <AddEvent dataEdit={state.dataEdit} />
    default:
      return <Calendar />
  }
}
const CalenderPageComponent = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  return (
    <div>
      <Card icon="access_time" title="Calendar" hidden={'hidden'}>
        <PageContainer>{renderComponent(active, state)}</PageContainer>
      </Card>
    </div>
  )
}

export default CalenderPageComponent
