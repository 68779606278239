import gql from 'graphql-tag'

export const UPDATE_HOLDING_SUBCLUSTER = gql`
  mutation update_holding_subcluster(
    $id: Int
    $changes: holding_subcluster_set_input
  ) {
    update_holding_subcluster(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`
