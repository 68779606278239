import {CircularProgress, makeStyles} from '@material-ui/core'
import React from 'react'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CloseIcon from '@material-ui/icons/Close'

const WRAPPER = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '18px 22px',
  borderRadius: 5,
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  marginBottom: 30,
  margin: '0 0 8px 8px',
}

const useStyles = makeStyles(() => ({
  successWrapper: {
    ...WRAPPER,
    border: '1px solid #4caf50',
    backgroundColor: '#e4f3e5',
  },
  infoWrapper: {
    ...WRAPPER,
    border: '1px solid #014a62',
    backgroundColor: '#c5f1ff',
  },
  errorWrapper: {
    ...WRAPPER,
    border: '1px solid #ef4d5e',
    backgroundColor: '#fde4e7',
  },
  warpperText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginLeft: 21,
  },
  rootLoading: {
    width: '24px !important',
    height: '24px !important',
  },
}))

export default function BaloonWLB({variant, text, onClose, loading}) {
  const classes = useStyles()
  let WRAPPER = {}
  let ICON = null
  switch (variant) {
    case 'success':
    case 'COMPLETED':
      WRAPPER = classes.successWrapper
      ICON = <CheckCircleOutlineOutlinedIcon style={{color: '#4caf50'}} />
      break
    case 'error':
    case 'FAILED':
      WRAPPER = classes.errorWrapper
      ICON = <InfoOutlinedIcon style={{color: '#ef4d5e'}} />
      break
    case 'info':
    case 'WAITING':
      WRAPPER = classes.infoWrapper
      ICON = <InfoOutlinedIcon style={{color: '#014a62'}} />
      break
    default:
      break
  }

  return (
    <div className={WRAPPER}>
      {ICON}
      <div className={classes.warpperText}>
        {text}
        <div>
          {loading && (
            <CircularProgress classes={{root: classes.rootLoading}} />
          )}
          {onClose && variant !== 'info' && (
            <CloseIcon
              onClick={onClose}
              style={{cursor: 'pointer', marginLeft: 8}}
            />
          )}
        </div>
      </div>
    </div>
  )
}
