import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
  FormContainer,
  FlexBetween,
  FormChildContainerFull,
  FormChildTitle,
  TitleNumber,
} from '../../../../../GlobalStyles'
import {Divider, Button, IconButton, makeStyles} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ComponentInput from './ComponentInput'
import {useMutation} from '@apollo/react-hooks'
import {
  ADD_HOLIDAY,
  EDIT_HOLIDAY,
  ADD_EVENT,
  ADD_COMPANY_HOLIDAY,
} from '../../../../../graphql/mutations/time/calendar/addEvent'
import {useSnackbar} from 'notistack'
import PreviewEvent from './PreviewEvent'
import {
  errorChecker,
  errorCheckerEvent,
  COMPANY_HOLIDAY_TYPE,
  EVENT_INFORMATION,
  EVENT_TYPE,
  errorCheckerCompanyHoliday,
} from './utilsForEvent'
import AddEdit from '../../../../shared-components/popup/AddEditPopupFreeText'
import FormHoliday from './form/FormHoliday'
import FormEvent from './form/FormEvent'
import FormCompanyHoliday from './form/FormCompanyHoliday'
import {changeToNumber, dateFormat} from '../../../../../utils/helpers'
import {COMPANY_ID} from '../../../../../utils/globals'
import {EDIT_COMPANY_HOLIDAY} from '../../../../../graphql/mutations/time/calendar/addHoliday'

const useStyles = makeStyles(() => ({
  rootWrapper: {paddingBottom: 66},

  typeButtonGroup: {
    display: 'flex',
    gap: '8px',
    marginLeft: '1.85rem',
  },
  typeButton: {
    padding: '6px 16px',
  },

  eventInformationWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '21px 0 0',
  },
  eventInformation: {
    fontSize: 12,
    color: '#014a62',
    margin: '0 0',
  },
  eventInformationIcon: {
    color: '#014a62',
    fontSize: 16,
    marginRight: 14,
  },
}))

const INITIAL_VALUES_STATE = {
  name: '',
  type: 'holiday',
  date: null,
  recurring: 0,
  recur_time_x: 0,
  recur_end_opt: 0,
  recur_end_date: null,
  recur_end_x: 0,
  description: '',
}

const AddEvent = ({history, dataEdit}) => {
  const classes = useStyles()
  const [value, setValue] = useState(INITIAL_VALUES_STATE)

  const [valueEvent, setValueEvent] = useState({
    name: '',
    category: '',
    type: '',
    datestarttime: null,
    datestart: null,
    dateend: null,
    dateendtime: null,
    description: '',
    // notif_timer: 0,
    // notif_unit_of_time: 1,
    location_info: '',
    poster_detail: [],
    schedule_fields: [],
    event_enrollments: [],
    // recurring: 0,
    // recur_time_x: 0,
    // recur_end_opt: 0,
    // recur_end_date: null,
    // recur_end_x: 0,
  })
  const [error, setError] = useState(false)
  const [errorEvent, setErrorEvent] = useState(false)
  const [preview, setPreview] = useState(false)
  const [recurringCheck, setRecurring] = useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const [mutationSave] = useMutation(ADD_HOLIDAY)
  const [mutationSaveEvent] = useMutation(ADD_EVENT)
  const [mutationSaveCompanyHoliday] = useMutation(ADD_COMPANY_HOLIDAY)
  const [openChanges, setOpenChanges] = useState(false)
  const [textNotif, setNotif] = useState({
    header: '',
    body: '',
    button: '',
  })
  const [confirmType, setConfirmType] = useState()
  const [mutationEdit] = useMutation(EDIT_HOLIDAY)
  const [mutationEditCompanyHoliday] = useMutation(EDIT_COMPANY_HOLIDAY)

  useEffect(() => {
    if (dataEdit) {
      setValue({
        id: dataEdit.id,
        name: dataEdit.name,
        type: dataEdit.type,
        date: dataEdit.date,
        recurring: dataEdit.recurring,
        recur_time_x: dataEdit.recur_time_x,
        recur_end_opt: dataEdit.recur_end_opt.toString(),
        recur_end_date: dataEdit.recur_end_date,
        recur_end_x: dataEdit.recur_end_x,
        description: dataEdit.description,
      })
      dataEdit.recurring && setRecurring(true)
      setPreview(true)
    }
  }, [dataEdit])

  const handleChangeInput = (e, target, other) => {
    if (target === 'date') {
      setPreview(true)
    }
    if (target === 'pricing') {
      e.target.value = changeToNumber(e.target.value)
    }
    if (other) {
      setValue({
        ...value,
        [target]: e,
      })
    } else {
      setValue({
        ...value,
        [target]: e.target.value,
      })
    }
  }

  const handleChangeInputEvent = (e, target, other) => {
    setError()
    if (target === 'date') {
      setPreview(true)
    }
    if (target === 'pricing') {
      e.target.value = changeToNumber(e.target.value)
    }
    if (other) {
      setValueEvent({
        ...valueEvent,
        [target]: e,
      })
    } else {
      setValueEvent({
        ...valueEvent,
        [target]: e.target.value,
      })
    }
  }

  const handleMutation = () => {
    if (value.type === COMPANY_HOLIDAY_TYPE) {
      mutationSaveCompanyHoliday({
        variables: {
          objects: [
            {
              name: value.name,
              date: dateFormat(value.date, 'YYYY-MM-DD'),
              type: COMPANY_HOLIDAY_TYPE,
              description: value.description,
              leave_policy_id: value?.leave_policy?.value,
            },
          ],
        },
      })
        .then(() => {
          enqueueSnackbar('Success Add data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          history.goBack()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
    } else if (value.type === 'holiday') {
      const variables = {
        name: value.name,
        description: value.description,
        type: value.type,
        recurring: value.recurring,
        recur_time_x: value.recur_time_x,
        date: dateFormat(value.date, 'YYYY-MM-DD'),
        recur_end_opt: value.recur_end_opt,
        recur_end_x: value.recur_end_x,
        recur_end_date: value.recur_end_date,
      }
      mutationSave({variables})
        .then(() => {
          enqueueSnackbar('Success Add data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          history.goBack()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
    } else {
      const object = {
        company: COMPANY_ID,
        title: value.name,
        type: valueEvent.type,
        category: valueEvent.category,
        datestart: `${dateFormat(
          valueEvent.datestart,
          'YYYY-MM-DD'
        )} ${dateFormat(valueEvent.datestarttime, 'H:mm:ss')}`,
        dateend: `${dateFormat(valueEvent.dateend, 'YYYY-MM-DD')} ${dateFormat(
          valueEvent.dateendtime,
          'H:mm:ss'
        )}`,
        // notif_timer: valueEvent.notif_timer,
        // notif_unit_of_time: valueEvent.notif_unit_of_time,
        location_info: valueEvent.location_info,
        poster: {poster_detail: valueEvent.poster_detail},
        pricing: {IDR: valueEvent.pricing},
        description: valueEvent.description,
        schedule_fields: valueEvent.schedule_fields,
        slug: `${dateFormat(valueEvent.datestart, 'YYYY-MM-DD')}-${
          value.name
        }-${new Date()}`,
        status: 'active',
        average_rating: 0,
        count_reviews: 0,
        thumbnail: valueEvent.thumbnail,
        event_enrollments: {
          data: valueEvent.event_enrollments,
        },
      }
      mutationSaveEvent({
        variables: {object},
      })
        .then(() => {
          enqueueSnackbar('Success Add data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          history.goBack()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
    }
  }

  const handleEdit = () => {
    const variables = value
    variables.date = dateFormat(value.date, 'YYYY-MM-DD')
    if (value.type === COMPANY_HOLIDAY_TYPE) {
      mutationEditCompanyHoliday({
        variables: {
          id: value.id,
          name: value.name,
          type: value.type,
          date: value.date,
          description: value.description,
          leave_policy_id: value.leave_policy.value,
        },
      })
        .then(() => {
          enqueueSnackbar('Success Edit data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          history.goBack()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
    } else {
      mutationEdit({variables})
        .then(() => {
          enqueueSnackbar('Success Edit data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          history.goBack()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
    }
  }

  const handleSave = type => {
    setConfirmType(type)
    if (type !== 'discard') {
      let validation = false
      if (value.type === COMPANY_HOLIDAY_TYPE) {
        validation = !errorCheckerCompanyHoliday(value, setError)
      } else if (value.type === 'holiday') {
        validation = !errorChecker(value, setError, recurringCheck)
      } else {
        validation = !errorCheckerEvent(value.name, valueEvent, setErrorEvent)
      }
      if (validation) {
        if (dataEdit) {
          setNotif({
            header: 'Confirm Changes?',
            body: 'Are you sure to save changes?',
            button: 'Confirm',
          })
          setOpenChanges(true)
        } else {
          setNotif({
            header: 'Add New Event?',
            body: 'Are you sure to add new event?',
            button: 'Confirm',
          })
          setOpenChanges(true)
        }
      }
    } else {
      setNotif({
        header: 'Discard Changes?',
        body: 'Are you sure want to discard unsaved changes?',
        button: 'Discard',
      })
      setOpenChanges(true)
    }
  }

  const handleClose = () => {
    setOpenChanges(false)
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            onClick={() => handleSave('discard')}
            edge="end"
            aria-label="back"
          >
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6">{`${
            dataEdit ? 'Edit' : 'Add'
          } Event`}</AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            color="primary"
            size="large"
            style={{marginRight: '1.6rem'}}
            onClick={() => handleSave('discard')}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSave()}
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </Button>
        </div>
      </FormToolbar>
      <Divider />

      <FlexBetween className={classes.rootWrapper}>
        <div style={{width: '50%', alignSelf: 'start'}}>
          <FormContainer>
            <ComponentInput
              placeholder="Add event title"
              id="name"
              number="1"
              name="Title *"
              value={value.name}
              error={error && value.name === ''}
              helperText={
                error && value.name === '' ? 'This field is required' : null
              }
              onChange={e => handleChangeInput(e, 'name')}
            />

            <div className={classes.typeButtonGroup}>
              {EVENT_TYPE.map(({type, label}) => {
                const selected = value.type === type

                return (
                  <Button
                    key={type}
                    disabled={!!dataEdit}
                    onClick={() => setValue(prev => ({...prev, type}))}
                    className={classes.typeButton}
                    variant={selected ? 'contained' : 'text'}
                    color={selected ? 'secondary' : 'default'}
                    disableElevation
                  >
                    {label}
                  </Button>
                )
              })}
            </div>

            {EVENT_INFORMATION[value.type] && (
              <div className={classes.eventInformationWrapper}>
                <InfoOutlinedIcon className={classes.eventInformationIcon} />
                <p className={classes.eventInformation}>
                  {EVENT_INFORMATION[value.type]}
                </p>
              </div>
            )}
          </FormContainer>
          {value.type === 'holiday' && (
            <FormHoliday
              handleChangeInput={handleChangeInput}
              value={value}
              error={error}
              recurringCheck={recurringCheck}
              setRecurring={setRecurring}
              setValue={setValue}
            />
          )}

          {value.type === COMPANY_HOLIDAY_TYPE && (
            <FormCompanyHoliday
              value={value}
              error={error}
              handleChangeInput={handleChangeInput}
              setValue={setValue}
              dataEdit={dataEdit}
            />
          )}

          {value.type === 'event' && (
            <FormEvent
              handleChangeInput={handleChangeInputEvent}
              value={valueEvent}
              error={errorEvent}
              recurringCheck={recurringCheck}
              setRecurring={setRecurring}
              setValue={setValueEvent}
            />
          )}
        </div>

        {/* preview */}
        <div style={{width: '50%', padding: '1.6rem', alignSelf: 'start'}}>
          {preview && (
            <>
              <FormChildContainerFull id="date">
                <FormChildTitle>
                  <TitleNumber>Preview Holiday</TitleNumber>
                </FormChildTitle>
              </FormChildContainerFull>
              <PreviewEvent
                preview={preview}
                value={value}
                recurringCheck={recurringCheck}
              />
            </>
          )}
        </div>
      </FlexBetween>

      <AddEdit
        open={openChanges}
        handleClose={handleClose}
        header={textNotif.header}
        body={textNotif.body}
        button={textNotif.button}
        mutation={
          confirmType === 'discard'
            ? () => history.goBack()
            : dataEdit
            ? handleEdit
            : handleMutation
        }
      />
    </PaperContainer>
  )
}

export default withRouter(AddEvent)
