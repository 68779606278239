import DownloadReportComponent from './DownloadReportComponent'

export const DownloadReportComponentConfig = {
  routes: [
    {
      path: '/directory/download-report',
      component: DownloadReportComponent,
      state: 'donwload-report',
      exact: true,
    },
  ],
}
