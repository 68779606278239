import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'
import ListIndividual from './individual-competency/ListIndividualCompetency'
import IndividualCompetencyDetail from './individual-competency/IndividualCompetencyDetail'
import IndividualCompetencyForm from './individual-competency/IndividualCompetencyForm'

import DictionaryList from './dictionary/ListDictionary'
import DictionaryVersionDetail from './dictionary/VersionDetail'
import DictionaryForm from './dictionary/DictionaryForm'
import DictionaryAddCompetency from './dictionary/AddCompetency'
import DictionaryDetail from './dictionary/DictionaryDetail'

import ListOfCompetence from './list-of-competence/List'
import DetailOfCompetence from './list-of-competence/Detail'
import AddListOfCompetence from './list-of-competence/Form'
import FormLevelCompetence from './list-of-competence/FormCompetenceLevel'

import ModelList from './model/ListModel'
import ModelDetailList from './model/ListModelDetail'
import ModelForm from './model/ModelForm'
import ModelDetail from './model/ModelDetail'

import CategoryList from './category/List'
import CategoryForm from './category/Form'
import CategoryDetail from './category/Detail'

import WlbTab from '../../../components/tab/WlbTabComponent'

const CompetencyPage = props => {
  const path = props.match.path
  let activeTab = ''

  if (props.location.state) {
    activeTab = props.location.state.active || 'individual-competency'
  }

  const renderComponent = () => {
    switch (path) {
      // Individual Competency
      case '/learning/competency':
        return <ListIndividual search={searchText} />
      case '/learning/competency/detail/:id':
        return <IndividualCompetencyDetail />
      case '/learning/competency/detail/:id/add':
        return <IndividualCompetencyForm />
      // Dictionary
      case '/learning/competency/dictionary':
        return <DictionaryList search={searchText} />
      case '/learning/competency/dictionary/add':
      case '/learning/competency/dictionary/edit/:id':
        return <DictionaryForm />
      case '/learning/competency/dictionary/:id':
        return <DictionaryVersionDetail search={searchText} />
      case '/learning/competency/dictionary/detail/:id':
        return <DictionaryDetail />
      case '/learning/competency/dictionary/:id/add':
        return <DictionaryAddCompetency />
      // List Of Competency
      case '/learning/competency/list-of-competency':
        return <ListOfCompetence search={searchText} />
      case '/learning/competency/list-of-competency/add':
      case '/learning/competency/list-of-competency/edit/:id':
        return <AddListOfCompetence />
      case '/learning/competency/list-of-competency/:id':
        return <DetailOfCompetence search={searchText} />
      case '/learning/competency/list-of-competency/:id/edit':
        return <FormLevelCompetence />
      // Model
      case '/learning/competency/model':
        return <ModelList search={searchText} />
      case '/learning/competency/model/:id':
        return <ModelDetailList search={searchText} />
      case '/learning/competency/model/detail/:id':
        return <ModelDetail />
      case '/learning/competency/model/:id/add':
        return <ModelForm />
      // Category
      case '/learning/competency/category':
        return <CategoryList search={searchText} />
      case '/learning/competency/category/add':
      case '/learning/competency/category/edit/:id':
        return <CategoryForm />
      case '/learning/competency/category/detail/:id':
        return <CategoryDetail />

      default:
        return <ListIndividual search={searchText} />
    }
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }
  const data_tabs_1 = [
    {
      label: 'Kompetensi Individu',
      state: 'individual-competency',
      url: '/learning/competency',
      searchable: true,
    },
  ]

  const data_tabs_2 = [
    {
      label: 'Pengaturan Kompetensi',
      state: 'competency-settings',
      url: '#',
      searchable: false,
    },
    {
      label: 'Kategori',
      state: 'competency-category',
      url: '/learning/competency/category',
      searchable: true,
    },
    {
      label: 'Daftar Kompetensi',
      state: 'list-of-competency',
      url: '/learning/competency/list-of-competency',
      searchable: true,
    },
    {
      label: 'Kamus',
      state: 'competence-dictionary',
      url: '/learning/competency/dictionary',
      searchable: true,
    },
    {
      label: 'Model',
      state: 'competence-model',
      url: '/learning/competency/model',
      searchable: true,
    },
  ]
  return (
    <Card icon="assignment_turned_in" title="Kompetensi" onSearch={onSearch}>
      <PageContainer>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <WlbTab data={data_tabs_1} activeMenu={activeTab} />

          <div style={{marginTop: 30}}>
            <WlbTab
              data={data_tabs_2}
              activeMenu={activeTab}
              disableFirstElement={true}
            />
          </div>
        </div>
        {renderComponent()}
      </PageContainer>
    </Card>
  )
}

export default withRouter(CompetencyPage)
