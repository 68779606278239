export const EXPERIENCE_TEMPLATES = [
  {
    'Employee ID': "EM202101191092123",
    "Name": "Christos Dietrich",
    'Position Name*': "Chief Technology Officer, Technology Department Head Office at Head Office",
    'Line of Business': "Chief Technology Officer, Technology Department Head Office at Head Office",
    "Company Name*": "PT. Media Information",
    "Decree": "SK-2019102312-AM-01",
    "Company Location*": "Jakarta",
    "Start Date*": "2021/01/19",
    "End Date*": "2021/12/31",
    "Currently Working": "yes",
    "Description": "Description 1",
    "Masterpiece": "yes",
    "Achievement": "Achievement 1",
    "Reason for Leaving": "-",
    "Reference Name": "Reference Name 1",
    "Reference Position": "Reference Position 1",
    "Country Code": "+62",
    "Reference Phone Number": "81923813214",
  },
  {
    'Employee ID': "EM202101191092123",
    "Name": "Christos Dietrich",
    'Position Name*': "Chief Financial Officer, Finance Department Kantor Cabang",
    'Line of Business': "Chief Financial Officer, Finance Department Kantor Cabang",
    "Company Name*": "PT. Media Information",
    "Decree": "SK-2019102312-AM-02",
    "Company Location*": "Jakarta",
    "Start Date*": "2020/01/19",
    "End Date*": "2020/12/31",
    "Currently Working": "no",
    "Description": "Description 2",
    "Masterpiece": "no",
    "Achievement": "Achievement 2",
    "Reason for Leaving": "Reason Leaving 1",
    "Reference Name": "Reference Name 2",
    "Reference Position": "Reference Position 2",
    "Country Code": "+62",
    "Reference Phone Number": "81923813215",
  },
  {
    'Employee ID': "EM202101191092123",
    "Name": "Christos Dietrich",
    'Position Name*': "Finance and Administration Lead, Finance and Administration Department Kantor Cabang",
    'Line of Business': "Finance and Administration Lead, Finance and Administration Department Kantor Cabang",
    "Company Name*": "PT. Media Information",
    "Decree": "SK-2019102312-AM-03",
    "Company Location*": "Jakarta",
    "Start Date*": "2019/01/19",
    "End Date*": "2019/12/31",
    "Currently Working": "no",
    "Description": "Description 3",
    "Masterpiece": "no",
    "Achievement": "Achievement 3",
    "Reason for Leaving": "Reason Leaving 2",
    "Reference Name": "Reference Name 3",
    "Reference Position": "Reference Position 3",
    "Country Code": "+62",
    "Reference Phone Number": "81923813216",
  },
  {
    'Employee ID': "EM02312314091",
    "Name": "Bachtiar Teodósio",
    'Position Name*': "Finance and Administration Lead, Finance and Administration Department Kantor Cabang",
    'Line of Business': "Finance and Administration Lead, Finance and Administration Department Kantor Cabang",
    "Company Name*": "PT. Media Information",
    "Decree": "SK-2019102312-AM-04",
    "Company Location*": "Jakarta",
    "Start Date*": "2021/01/25",
    "End Date*": "2021/12/31",
    "Currently Working": "yes",
    "Description": "Description 1",
    "Masterpiece": "yes",
    "Achievement": "Achievement 1",
    "Reason for Leaving": "-",
    "Reference Name": "Reference Name 1",
    "Reference Position": "Reference Position 1",
    "Country Code": "+62",
    "Reference Phone Number": "81923813215",
  },
  {
    'Employee ID': "EM02312314091",
    "Name": "Bachtiar Teodósio",
    'Position Name*': "Chief Financial Officer, Finance Department Kantor Cabang",
    'Line of Business': "Chief Financial Officer, Finance Department Kantor Cabang",
    "Company Name*": "PT. Media Information",
    "Decree": "SK-2019102312-AM-05",
    "Company Location*": "Jakarta",
    "Start Date*": "2020/01/19",
    "End Date*": "2020/12/31",
    "Currently Working": "no",
    "Description": "Description 2",
    "Masterpiece": "no",
    "Achievement": "Achievement 2",
    "Reason for Leaving": "Reason Leaving 1",
    "Reference Name": "Reference Name 2",
    "Reference Position": "Reference Position 2",
    "Country Code": "+62",
    "Reference Phone Number": "81923813216",
  },
  {
    'Employee ID': "EM02312314091",
    "Name": "Bachtiar Teodósio",
    'Position Name*': "Finance and Administration Lead, Finance and Administration Department Kantor Cabang",
    'Line of Business': "Finance and Administration Lead, Finance and Administration Department Kantor Cabang",
    "Company Name*": "PT. Media Information",
    "Decree": "SK-2019102312-AM-06",
    "Company Location*": "Jakarta",
    "Start Date*": "2019/01/19",
    "End Date*": "2019/12/31",
    "Currently Working": "no",
    "Description": "Description 3",
    "Masterpiece": "no",
    "Achievement": "Achievement 3",
    "Reason for Leaving": "Reason Leaving 2",
    "Reference Name": "Reference Name 3",
    "Reference Position": "Reference Position 3",
    "Country Code": "+62",
    "Reference Phone Number": "81923813216",
  },
  {
    'Employee ID': "EMCFO0192192123",
    "Name": "Akbar Cristiano",
    'Position Name*': "Chief Technology Officer, Technology Department Head Office",
    'Line of Business': "Chief Technology Officer, Technology Department Head Office",
    "Company Name*": "PT. Media Information",
    "Decree": "SK-2019102312-AM-07",
    "Company Location*": "Jakarta",
    "Start Date*": "2019/12/01",
    "End Date*": "2025/05/15",
    "Currently Working": "yes",
    "Description": "Description 1",
    "Masterpiece": "yes",
    "Achievement": "Achievement 1",
    "Reason for Leaving": "-",
    "Reference Name": "Reference Name 1",
    "Reference Position": "Reference Position 1",
    "Country Code": "+62",
    "Reference Phone Number": "81923813220",
  },
  {
    'Employee ID': "EMCFO0192192123",
    "Name": "Akbar Cristiano",
    'Position Name*': "Chief Financial Officer, Finance Department Kantor Cabang",
    'Line of Business': "Chief Financial Officer, Finance Department Kantor Cabang",
    "Company Name*": "PT. Media Information",
    "Decree": "SK-2019102312-AM-08",
    "Company Location*": "Jakarta",
    "Start Date*": "2020/01/19",
    "End Date*": "2020/12/31",
    "Currently Working": "no",
    "Description": "Description 2",
    "Masterpiece": "no",
    "Achievement": "Achievement 2",
    "Reason for Leaving": "Reason Leaving 1",
    "Reference Name": "Reference Name 2",
    "Reference Position": "Reference Position 2",
    "Country Code": "+62",
    "Reference Phone Number": "81923813221",
  },
  {
    'Employee ID': "EMCFO0192192123",
    "Name": "Akbar Cristiano",
    'Position Name*': "Finance and Administration Lead, Finance and Administration Department Kantor Cabang",
    'Line of Business': "Finance and Administration Lead, Finance and Administration Department Kantor Cabang",
    "Company Name*": "PT. Media Information",
    "Decree": "SK-2019102312-AM-09",
    "Company Location*": "Jakarta",
    "Start Date*": "2019/01/19",
    "End Date*": "2019/12/31",
    "Currently Working": "no",
    "Description": "Description 3",
    "Masterpiece": "no",
    "Achievement": "Achievement 3",
    "Reason for Leaving": "Reason Leaving 2",
    "Reference Name": "Reference Name 3",
    "Reference Position": "Reference Position 3",
    "Country Code": "+62",
    "Reference Phone Number": "81923813222",
  },
]

export const TRAINING_LICENSES_TEMPLATES = [
  {
    'Employee ID': "EM202101191092123",
    "Name": "Christos Dietrich",
    "Training, Licenses & Certificate Name*": "Leadership Training",
    "Training, Licenses & Certificate Type*": "fungsional",
    "Training, Licenses & Certificate Level*": "perusahaan",
    "Issuing Organization*": "Issuing Organization 1",
    "Issues Date*": "2013/01/19",
    "Expiration Dates*": "",
    "Certification Does not Expired": "yes",
    "Credential ID": "CR-000121231",
    "Credential URL": "https://drive.google.com/file/d/1f2C7mYiOHBa6XLOzImm93nN3HomDzmku/view?usp=sharing",
    "Description": "Description 1"
  },
  {
    'Employee ID': "EM202101191092123",
    "Name": "Christos Dietrich",
    "Training, Licenses & Certificate Name*": "PMP Certification",
    "Training, Licenses & Certificate Type*": "jabatan",
    "Training, Licenses & Certificate Level*": "nasional",
    "Issuing Organization*": "Issuing Organization 2",
    "Issues Date*": "2017/01/01",
    "Expiration Dates*": "2020/12/05",
    "Certification Does not Expired": "no",
    "Credential ID": "CR-000121232",
    "Credential URL": "https://drive.google.com/file/d/1f2C7mYiOHBa6XLOzImm93nN3HomDzmku/view?usp=sharing",
    "Description": "Description 2"
  },
  {
    'Employee ID': "EM202101191092123",
    "Name": "Christos Dietrich",
    "Training, Licenses & Certificate Name*": "Programming Certification",
    "Training, Licenses & Certificate Type*": "non-formal",
    "Training, Licenses & Certificate Level*": "internasional",
    "Issuing Organization*": "Issuing Organization 3",
    "Issues Date*": "2020/01/01",
    "Expiration Dates*": "2022/12/05",
    "Certification Does not Expired": "no",
    "Credential ID": "CR-000121233",
    "Credential URL": "https://drive.google.com/file/d/1f2C7mYiOHBa6XLOzImm93nN3HomDzmku/view?usp=sharing",
    "Description": "Description 3"
  },
  {
    'Employee ID': "EM02312314091",
    "Name": "Bachtiar Teodósio",
    "Training, Licenses & Certificate Name*": "Leadership Training",
    "Training, Licenses & Certificate Type*": "fungsional",
    "Training, Licenses & Certificate Level*": "non-formal",
    "Issuing Organization*": "Issuing Organization 1",
    "Issues Date*": "2013/01/19",
    "Expiration Dates*": "",
    "Certification Does not Expired": "yes",
    "Credential ID": "CR-000121234",
    "Credential URL": "https://drive.google.com/file/d/1f2C7mYiOHBa6XLOzImm93nN3HomDzmku/view?usp=sharing",
    "Description": "Description 1"
  },
  {
    'Employee ID': "EM02312314091",
    "Name": "Bachtiar Teodósio",
    "Training, Licenses & Certificate Name*": "PMP Certification",
    "Training, Licenses & Certificate Type*": "jabatan",
    "Training, Licenses & Certificate Level*": "perusahaan",
    "Issuing Organization*": "Issuing Organization 2",
    "Issues Date*": "2017/01/01",
    "Expiration Dates*": "2020/12/05",
    "Certification Does not Expired": "no",
    "Credential ID": "CR-000121235",
    "Credential URL": "https://drive.google.com/file/d/1f2C7mYiOHBa6XLOzImm93nN3HomDzmku/view?usp=sharing",
    "Description": "Description 2"
  },
  {
    'Employee ID': "EM02312314091",
    "Name": "Bachtiar Teodósio",
    "Training, Licenses & Certificate Name*": "Programming Certification",
    "Training, Licenses & Certificate Type*": "non-formal",
    "Training, Licenses & Certificate Level*": "nasional",
    "Issuing Organization*": "Issuing Organization 3",
    "Issues Date*": "2020/01/01",
    "Expiration Dates*": "2022/12/05",
    "Certification Does not Expired": "no",
    "Credential ID": "CR-000121236",
    "Credential URL": "https://drive.google.com/file/d/1f2C7mYiOHBa6XLOzImm93nN3HomDzmku/view?usp=sharing",
    "Description": "Description 3"
  },
  {
    'Employee ID': "EMCFO0192192123",
    "Name": "Akbar Cristiano",
    "Training, Licenses & Certificate Name*": "Leadership Training",
    "Training, Licenses & Certificate Type*": "fungsional",
    "Training, Licenses & Certificate Level*": "internasional",
    "Issuing Organization*": "Issuing Organization 1",
    "Issues Date*": "2013/01/19",
    "Expiration Dates*": "",
    "Certification Does not Expired": "yes",
    "Credential ID": "CR-000121237",
    "Credential URL": "https://drive.google.com/file/d/1f2C7mYiOHBa6XLOzImm93nN3HomDzmku/view?usp=sharing",
    "Description": "Description 1"
  },
  {
    'Employee ID': "EM02312314091",
    "Name": "Bachtiar Teodósio",
    "Training, Licenses & Certificate Name*": "PMP Certification",
    "Training, Licenses & Certificate Type*": "jabatan",
    "Training, Licenses & Certificate Level*": "non-formal",
    "Issuing Organization*": "Issuing Organization 2",
    "Issues Date*": "2017/01/01",
    "Expiration Dates*": "2020/12/05",
    "Certification Does not Expired": "no",
    "Credential ID": "CR-000121238",
    "Credential URL": "https://drive.google.com/file/d/1f2C7mYiOHBa6XLOzImm93nN3HomDzmku/view?usp=sharing",
    "Description": "Description 2"
  },
  {
    'Employee ID': "EM02312314091",
    "Name": "Bachtiar Teodósio",
    "Training, Licenses & Certificate Name*": "Programming Certification",
    "Training, Licenses & Certificate Type*": "non-formal",
    "Training, Licenses & Certificate Level*": "perusahaan",
    "Issuing Organization*": "Issuing Organization 3",
    "Issues Date*": "2020/01/01",
    "Expiration Dates*": "2022/12/05",
    "Certification Does not Expired": "no",
    "Credential ID": "CR-000121239",
    "Credential URL": "https://drive.google.com/file/d/1f2C7mYiOHBa6XLOzImm93nN3HomDzmku/view?usp=sharing",
    "Description": "Description 3"
  },
]