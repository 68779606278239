import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'
import Activity from './activity/ActivityComponent'
import TripReport from './trip-report/TripReportComponent'
import Settings from './settings/SettingsComponent'
import AddEditComponent from './settings/add-edit/AddEditComponent'
import AddEditPolicy from './settings/add-edit/AddEditPolicy'
import {Filter} from '@worklifebeyond/wlb-utils-components'
import {anchorOrigin, MENUS, transformOrigin} from './filter/listFilter'
import {
  BlueIcon,
  ButtonFilter,
  FilterText,
  PageContainer,
} from '../../../GlobalStyles'
import {setterFilter} from '../../../utils/helpers'
import searchFilters from '../../../utils/searchFilters'

const renderComponent = (
  activeComponent,
  state,
  searchText = '',
  filterData
) => {
  switch (activeComponent) {
    case 'Activity':
      return (
        <Activity
          state={state}
          searchText={searchText}
          filterData={filterData}
        />
      )
    case 'trip-report':
      return (
        <TripReport
          state={state}
          searchText={searchText}
          filterData={filterData}
        />
      )
    case 'settings':
      return <Settings state={state} searchText={searchText} />
    case 'add-component':
      return (
        <AddEditComponent
          state={state}
          type={state.type}
          data={state.dataEdit}
        />
      )
    case 'add-policy':
      return (
        <AddEditPolicy state={state} type={state.type} data={state.dataEdit} />
      )

    case 'edit-policy':
      return (
        <AddEditPolicy state={state} type={state.type} data={state.dataEdit} />
      )
    default:
      return (
        <Activity
          state={state}
          searchText={searchText}
          filterData={filterData}
        />
      )
  }
}

const BusinessTripPage = props => {
  const [filterData, setFilterData] = useState({})

  let active = ''
  let activeTab = ''
  let state = {}

  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
    switch (active) {
      case 'add-component':
      case 'edit-component':
      case 'add-policy':
      case 'edit-policy':
        activeTab = 'settings'
        break
      default:
        activeTab = props.location.state.active
        break
    }
  }

  const data_tabs = [
    {
      label: 'Activity',
      state: 'activity',
      url: '/business/trip/activity',
      searchable: true,
    },
    {
      label: 'Trip Report',
      state: 'trip-report',
      url: '/business/trip/trip-report',
      searchable: true,
    },
    {
      label: 'Settings',
      state: 'settings',
      url: '/business/trip/settings',
      activeTab: 'Component',
      searchable: true,
    },
  ]

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  const handleApply = result => setFilterData(setterFilter(result))
  return (
    <div>
      <Card
        icon="local_airport"
        title="Business Trip"
        onSearch={onSearch}
        endAdornment={
          MENUS.includes(activeTab) && (
            <Filter
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
              id="filter-attendance-wlb"
              onApply={handleApply}
              listFilter={searchFilters}
            >
              <ButtonFilter position="end">
                <BlueIcon>filter_list</BlueIcon>
                <FilterText variant="body2">Filter</FilterText>
              </ButtonFilter>
            </Filter>
          )
        }
      >
        <PageContainer>
          <WlbTab data={data_tabs} activeMenu={activeTab} />
          {renderComponent(active, state, searchText, filterData)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default withRouter(BusinessTripPage)
