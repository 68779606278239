// import {Status} from '../../../../shared-components/data/SharedData'
import {useQuery} from '@apollo/react-hooks'
import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Grid,
  Icon,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import moment from 'moment'
import React, {useState} from 'react'
import Loading from '../../../../../components/loading/LoadingComponent'
import {TypographyStyleds} from '../../../../../components/typography/TypographyStyles'
import {
  FlexCenters,
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  GreyTypography,
  ThinTextfield,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import {GET_COMPETENCY_VERSION} from '../../../../../graphql/queries/competency/getCompetencyVersion.query'
import {parameters_versions, selectProps} from '../../../../../utils/helpers'
import ParameterPopup from './ParameterPopup'

const useStyles = makeStyles(theme => ({
  w50: {
    width: '50% !important',
  },
  wFull: {
    width: '100% !important',
  },
  separateToForm: {color: '#a9a8a8', textAlign: 'center'},
  m0: {margin: 0},
  p0: {padding: 0},
  // gridChildRoot: {minHeight: 80},
  label: {fontSize: 12},
  spacer: {marginRight: 12},
  disabledText: {
    marginLeft: '2rem',
    fontStyle: 'italic',
  },
  paramterContainer: {
    marginTop: 24,
    marginBottom: 12,
    width: '85%',
  },
  inputWhite: {background: 'white'},
  disabledLabel: {color: '#808080 !important'},
  addWeight: {position: 'relative', left: 24, height: '1.5rem'},
  animatedParamWrapper: {
    transition: theme.transitions.create(['max-width', 'flex-basis'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  parameterPopupContainer: {
    maxWidth: 750,
  },
  inputDate: {fontSize: 12},
  removeIcon: {cursor: 'pointer', color: '#ef4d5e', marginLeft: 16},
}))
const StepOne = ({
  handleChange,
  values,
  setValues,
  handleChangeIndicator,
  isError,
  axisValue,
  indicatorTextfield,
  selectedParameter,
  totalWeight,
  isTalent,
  isDynamic,
  setIsDynamic,
  mapsDynamicWeight,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState('')

  const handleChangeDate = (date, name) => {
    // date && date.setHours(date.getHours() + 7)
    setValues({
      ...values,
      [name]: moment(date).format('YYYY-MM-DD'),
    })
  }

  const handleDynamicWeight = () => setIsDynamic(!isDynamic)

  const handleAddDynamicWeight = () => {
    handleDynamicWeight()
    mapsDynamicWeight(true)
  }

  const {data, error, loading} = useQuery(GET_COMPETENCY_VERSION)
  if (loading) {
    return <Loading />
  }
  if (error) {
    console.error(error)
  }

  const limitFeatureName = isTalent ? 50 : 250

  return (
    <div id="top">
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>
            Nama Klasifikasi {isTalent ? 'Talenta' : 'Kinerja'}
          </TitleName>
        </FormChildTitle>
        <FormChildInput>
          <ThinTextfield
            fullWidth
            margin="normal"
            variant="outlined"
            value={values.name}
            onChange={handleChange('name')}
            error={
              (values.name.length > limitFeatureName || !values.name) && isError
            }
            helperText={
              !values.name && isError
                ? 'Bagian ini diperlukan'
                : values.name.length > limitFeatureName && isError
                ? `Jumlah karakter maksimum adalah ${limitFeatureName} karakter`
                : `${values.name.length}/${limitFeatureName}`
            }
            placeholder={`Tambah nama klasifikasi ${
              isTalent ? 'talenta' : 'kinerja'
            }`}
            inputProps={{maxLength: 50}}
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Tanggal Mulai</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              size="small"
              inputVariant="outlined"
              format="MMM d, yyyy"
              InputProps={{
                readOnly: true,
              }}
              value={values.start_date}
              onChange={date => handleChangeDate(date, 'start_date')}
              margin="normal"
              className={classes.w50}
              placeholder="Pilih tanggal mulai"
              {...(!values.start_date && isError ? {error: true} : {})}
              {...(!values.start_date && isError
                ? {helperText: 'Bagian ini diperlukan'}
                : {})}
            />
          </MuiPickersUtilsProvider>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>Tanggal Selesai</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              size="small"
              inputVariant="outlined"
              format="MMM d, yyyy"
              InputProps={{
                readOnly: true,
              }}
              value={values.end_date}
              onChange={date => handleChangeDate(date, 'end_date')}
              margin="normal"
              className={classes.w50}
              minDate={values.start_date}
              placeholder="Pilih Tanggal Selesai"
              {...(!values.end_date && isError ? {error: true} : {})}
              {...(!values.end_date && isError
                ? {helperText: 'Bagian ini diperlukan'}
                : {})}
            />
          </MuiPickersUtilsProvider>
        </FormChildInput>
      </FormChildContainer>
      {/* <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>Status</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <ThinTextfield
            fullWidth
            margin="normal"
            variant="outlined"
            value={values.status}
            onChange={handleChange('status')}
            select
            className={classes.w50}
            error={!values.status && isError}
            helperText={
              !values.status && isError ? 'Bagian ini diperlukan' : ''
            }
          >
            <MenuItem value="" disabled>
              Choose Status
            </MenuItem>
            {Status.map((res, i) => {
              return (
                <MenuItem value={res.value} key={i}>
                  {res.name}
                </MenuItem>
              )
            })}
          </ThinTextfield>
        </FormChildInput>
      </FormChildContainer> */}
      {indicatorTextfield.map((result, i) => {
        return (
          <FormChildContainer className={classes.wFull} key={i}>
            <FormChildTitle style={{marginBottom: 6}}>
              <TitleNumber>{result.id}.</TitleNumber>
              <TitleName>Indikator {result.title}</TitleName>
            </FormChildTitle>
            <FormChildInput style={{width: '75%', marginBottom: 10}}>
              <ThinTextfield
                fullWidth
                margin="normal"
                variant="outlined"
                value={result.axis}
                onChange={handleChange(result.nameAxis)}
                error={
                  (result.axis === '' || result.axis.length > 50) && isError
                }
                helperText={
                  result.axis === '' && isError
                    ? 'Bagian ini diperlukan'
                    : result.axis.length > 50 && isError
                    ? 'Jumlah karakter maksimum adalah 50 karakter'
                    : `${result.axis.length}/50`
                }
                placeholder={`Tambah nama ${result.title}`}
                inputProps={{maxLength: 50}}
              />
            </FormChildInput>

            <FormChildInput style={{display: 'flex'}}>
              <div style={{width: 100, marginRight: 12}}>
                <TitleName gutterBottom>Nilai Min</TitleName>
                <ThinTextfield
                  variant="outlined"
                  value={result.min}
                  onChange={handleChange(result.nameMin)}
                  error={result.min === '' && isError}
                  helperText={
                    result.min === '' && isError ? 'Bagian ini diperlukan' : ''
                  }
                  placeholder="0"
                  type="number"
                />
              </div>
              <div style={{width: 100}}>
                <TitleName gutterBottom>Nilai Maks</TitleName>

                <ThinTextfield
                  variant="outlined"
                  value={result.max}
                  onChange={handleChange(result.nameMax)}
                  error={result.max === '' && isError}
                  helperText={
                    result.max === '' && isError ? 'Bagian ini diperlukan' : ''
                  }
                  placeholder="0"
                  type="number"
                />
              </div>
            </FormChildInput>
            {isError && parseInt(result.min) > parseInt(result.max) && (
              <Typography
                variant="caption"
                color="error"
                component="p"
                style={{marginTop: 12, paddingLeft: '1.85rem'}}
                gutterBottom
              >
                Skor minimal tidak boleh lebih dari skor maksimal
              </Typography>
            )}

            <FormChildInput style={{marginTop: 14}}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setOpen(result.title)}
              >
                Tambah Parameter
              </Button>
              {isError && axisValue[result.title].length === 0 && (
                <Typography
                  variant="caption"
                  color="error"
                  component="p"
                  style={{marginTop: 12}}
                  gutterBottom
                >
                  Silakan pilih setidaknya satu parameter
                </Typography>
              )}
              {selectedParameter(result.title).length > 0 && (
                <>
                  <Grid
                    container
                    className={`${classes.m0} ${classes.paramterContainer}`}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems="center"
                      style={{marginBottom: 24}}
                    >
                      <Switch
                        checked={!isDynamic}
                        onChange={e =>
                          e.target.checked
                            ? handleAddDynamicWeight()
                            : handleDynamicWeight()
                        }
                        color="primary"
                      />
                      <TypographyStyleds variant="body2" color="#414141">
                        Gunakan bobot untuk parameter
                      </TypographyStyleds>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3}>
                        <GreyTypography variant="body2">
                          Nama Parameter
                        </GreyTypography>
                      </Grid>
                      <Grid item container xs={8} spacing={2}>
                        <Grid item container xs={isDynamic ? 12 : 9}>
                          <Grid item xs={7}>
                            <GreyTypography variant="body2">
                              Tanggal Mulai
                            </GreyTypography>
                          </Grid>
                          <Grid item xs={5}>
                            <GreyTypography variant="body2">
                              Tanggal Berakhir
                            </GreyTypography>
                          </Grid>
                        </Grid>
                        {!isDynamic && (
                          <Grid item container xs={3}>
                            <GreyTypography variant="body2">
                              Bobot
                            </GreyTypography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {selectedParameter(result.title).map((param, iParam) => (
                      <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        key={iParam}
                      >
                        <Grid
                          item
                          // container
                          xs={3}
                        >
                          <TitleName>
                            {parameters_versions?.[param.title]?.ID ||
                              param.title}
                          </TitleName>
                        </Grid>
                        <Grid item container xs={8} spacing={2}>
                          {param.title === 'Competency' ? (
                            <Grid item xs={isDynamic ? 12 : 9}>
                              <ThinTextfield
                                size="small"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={param.indicators[1]}
                                onChange={handleChangeIndicator(
                                  param.nameParent,
                                  'version'
                                )}
                                select
                                error={
                                  param.valueCheck &&
                                  !param.indicators[1] &&
                                  isError
                                }
                                helperText={
                                  param.valueCheck &&
                                  !param.indicators[1] &&
                                  isError
                                    ? 'Bagian ini diperlukan'
                                    : ''
                                }
                                SelectProps={selectProps(param.indicators[1])}
                              >
                                <MenuItem value={''} disabled>
                                  Pilih Versi
                                </MenuItem>
                                {data.competency_versions.map((res, i) => {
                                  return (
                                    <MenuItem value={res.id} key={i}>
                                      {res.name}
                                    </MenuItem>
                                  )
                                })}
                              </ThinTextfield>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              container
                              xs={isDynamic ? 12 : 9}
                              alignItems="center"
                            >
                              <Grid item xs={5}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    fullWidth
                                    size="small"
                                    inputVariant="outlined"
                                    format="MMM d, yyyy"
                                    value={param.indicators[1]}
                                    margin="normal"
                                    placeholder="Pilih Tanggal Mulai"
                                    onChange={date =>
                                      setValues({
                                        ...values,
                                        [param.nameParent]: {
                                          ...values[param.nameParent],
                                          start_date: date,
                                        },
                                      })
                                    }
                                    InputAdornmentProps={{
                                      classes: {
                                        root: classes.m0,
                                      },
                                    }}
                                    KeyboardButtonProps={{
                                      classes: {
                                        root: classes.p0,
                                      },
                                    }}
                                    InputProps={{
                                      classes: {
                                        root: classes.inputWhite,
                                      },
                                      readOnly: true,
                                    }}
                                    maxDate={new Date()}
                                    {...(!param.indicators[1] && isError
                                      ? {error: true}
                                      : {})}
                                    {...(!param.indicators[1] && isError
                                      ? {
                                          helperText: 'Bagian ini diperlukan',
                                        }
                                      : {})}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={classes.separateToForm}
                              >
                                sampai
                              </Grid>
                              <Grid item xs={5}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    size="small"
                                    fullWidth
                                    placeholder="Pilih Tanggal Selesai"
                                    inputVariant="outlined"
                                    format="MMM d, yyyy"
                                    value={param.indicators[2]}
                                    margin="normal"
                                    onChange={date =>
                                      setValues({
                                        ...values,
                                        [param.nameParent]: {
                                          ...values[param.nameParent],
                                          end_date: date,
                                        },
                                      })
                                    }
                                    InputAdornmentProps={{
                                      classes: {
                                        root: classes.m0,
                                      },
                                    }}
                                    KeyboardButtonProps={{
                                      classes: {
                                        root: classes.p0,
                                      },
                                    }}
                                    InputProps={{
                                      classes: {
                                        root: classes.inputWhite,
                                      },
                                      readOnly: true,
                                    }}
                                    minDate={param.indicators[1]}
                                    // maxDate={new Date()}
                                    {...(!param.indicators[2] && isError
                                      ? {error: true}
                                      : {})}
                                    {...(!param.indicators[2] && isError
                                      ? {
                                          helperText: 'Bagian ini diperlukan',
                                        }
                                      : {})}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>
                          )}
                          {!isDynamic && (
                            <Grid item container xs={3}>
                              <FlexCenters>
                                <TextField
                                  style={{marginRight: 6}}
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                  variant="outlined"
                                  value={param.indicators[0]}
                                  onChange={handleChangeIndicator(
                                    param.nameParent,
                                    'weight'
                                  )}
                                  placeholder="0"
                                  error={
                                    param.valueCheck &&
                                    param.indicators[0] === '' &&
                                    isError
                                  }
                                  helperText={
                                    param.valueCheck &&
                                    param.indicators[0] === '' &&
                                    isError
                                      ? 'Bagian ini diperlukan'
                                      : ''
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                %
                              </FlexCenters>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item container xs={1}>
                          <input
                            id={param.title}
                            type="checkbox"
                            hidden
                            checked={true}
                            onChange={handleChange(
                              param.nameCheck,
                              result.title
                            )}
                          />
                          <label htmlFor={param.title} style={{height: 23}}>
                            <Icon className={classes.removeIcon}>close</Icon>
                          </label>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  {isError && !isDynamic && totalWeight(result.title) !== 100 && (
                    <Typography
                      variant="caption"
                      color="error"
                      component="p"
                      style={{marginTop: 12}}
                      gutterBottom
                    >
                      Total Weight must be 100%
                    </Typography>
                  )}
                </>
              )}
            </FormChildInput>
          </FormChildContainer>
        )
      })}
      <ParameterPopup
        values={values}
        setValues={setValues}
        handleChangeIndicator={handleChangeIndicator}
        isError={isError}
        open={open}
        indicatorTextfield={indicatorTextfield}
        handleClose={() => setOpen('')}
        classes={classes}
        handleChange={handleChange}
        data={data}
        error={error}
        axisValue={axisValue}
        isDynamic={isDynamic}
        handleDynamicWeight={handleDynamicWeight}
        handleAddDynamicWeight={handleAddDynamicWeight}
      />
    </div>
  )
}

export default StepOne
