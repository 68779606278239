import ArchivePageComponent from './ArchivePageComponent'

export const ArchivePageConfig = {
  routes: [
    {
      path: '/organization/archive',
      component: ArchivePageComponent,
      state: 'archive',
      exact: true,
    },
    {
      path: '/organization/archive/:id',
      component: ArchivePageComponent,
      state: 'detail-archive',
      exact: true,
    },
  ],
}
