import React from 'react'
import {withRouter} from 'react-router-dom'
import {useMutation} from '@apollo/react-hooks'

import {
  makeStyles,
  Divider,
  Typography,
  Button,
  IconButton,
  Icon,
  OutlinedInput,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'

import {
  PaperContainer,
  FormToolbar,
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
  FormTitleContainer,
} from '../../../../../GlobalStyles'
import FieldInformationTooltip from '../../../../shared-components/tooltip/FieldInformationTooltip'
import AlertPopup from '../../../../shared-components/popup/ResultPopup'
import {
  CurrencyInput,
  useNumericInput,
  useInputState,
} from '../../ClaimLimitPageStyles'

import {TOPUP_COMPANY_BALANCE} from '../../../../../graphql/mutations/budget/limit/companyBalance.mutation'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  input: {
    width: '100%',
    maxWidth: 600,
    marginTop: theme.spacing(1),
  },

  tooltipWrapper: {
    '& .MuiIconButton-root': {
      marginTop: -2,
    },

    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(2.25),
    },
  },
}))

function CompanyTopup(props) {
  const styles = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false)
  const [cancelIsOpen, setCancelIsOpen] = React.useState(false)

  const [nominal, maskedNominal, handleNominalInput] = useNumericInput(0)
  const [description, handleDescriptionInput] = useInputState('')

  const [dispatchTopup, {loading: dispatching}] = useMutation(
    TOPUP_COMPANY_BALANCE
  )

  // Confirmation popup
  const openConfirmation = () => {
    setConfirmIsOpen(true)
  }

  const handleConfirmationAccept = () => {
    setConfirmIsOpen(false)

    const variables = {
      nominal,
      description,
    }

    dispatchTopup({variables}).then(
      ({data}) => {
        const message = data.result.message
        const success = !data.result.total_failed_top_ups

        enqueueSnackbar(message, {variant: success ? 'success' : 'error'})

        if (success) {
          props.history.push({
            pathname: '/budget/limit/company',
            state: {active: 'company'},
          })
        }
      },
      () => {
        enqueueSnackbar('Balance failed to top up', {variant: 'error'})
      }
    )
  }

  const handleConfirmationCancel = () => {
    setConfirmIsOpen(false)
  }

  /// Cancellation popup
  const openCancellation = () => {
    setCancelIsOpen(true)
  }

  const handleCancellationAccept = () => {
    setCancelIsOpen(false)
    props.history.goBack()
  }

  const handleCancellationClose = () => {
    setCancelIsOpen(false)
  }

  // Balance
  const nominalEmpty = !nominal || nominal <= 0

  return (
    <PaperContainer>
      <FormToolbar>
        <FormTitleContainer>
          <IconButton
            disabled={dispatching}
            onClick={openCancellation}
            aria-label="Go back to previous page"
            edge="start"
            className={styles.backButton}
          >
            <Icon>arrow_back</Icon>
          </IconButton>

          <Typography variant="subtitle1" style={{fontWeight: '600'}}>
            Top Up Claim Limit
          </Typography>
        </FormTitleContainer>

        <FormTitleContainer>
          <Button
            disabled={dispatching}
            onClick={openCancellation}
            variant="outlined"
            color="primary"
            size="large"
            style={{marginLeft: 16}}
          >
            Cancel
          </Button>
          <Button
            disabled={dispatching || nominalEmpty}
            onClick={openConfirmation}
            variant="contained"
            color="primary"
            size="large"
            style={{marginLeft: 16}}
          >
            Submit
          </Button>
        </FormTitleContainer>
      </FormToolbar>

      <Divider />

      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>
              Nominal* <TopupNominalExplanation />
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <CurrencyInput
              required
              disabled={dispatching}
              value={maskedNominal}
              onChange={handleNominalInput}
              className={styles.input}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Description</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <OutlinedInput
              disabled={dispatching}
              value={description}
              onChange={handleDescriptionInput}
              className={styles.input}
              placeholder="Add top up description"
              multiline
            />
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>

      <AlertPopup
        open={confirmIsOpen}
        mutation={handleConfirmationAccept}
        handleClose={handleConfirmationCancel}
        feature="Top Up Balance"
        message="Are you sure you want to top up claim limit?"
        type="Confirm"
        status="cancel"
      />

      <AlertPopup
        open={cancelIsOpen}
        mutation={handleCancellationAccept}
        handleClose={handleCancellationClose}
        feature="Discard Changes?"
        message="Are you sure you want to discard changes top up claim limit?"
        type="Discard"
        status="cancel"
      />
    </PaperContainer>
  )
}

export default withRouter(CompanyTopup)

function TopupNominalExplanation() {
  const styles = useStyles()

  return (
    <span className={styles.tooltipWrapper}>
      <FieldInformationTooltip
        title="The balance being topped up cannot be deleted or cancelled, make
          sure the top up amount corresponds with budget or company needs"
      />
    </span>
  )
}
