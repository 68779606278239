import {Avatar, Paper, TextField, Typography, Divider} from '@material-ui/core'
import React from 'react'
import {
  FormChildContainer,
  FormChildTitle,
  FormContainer,
  GreyIcon,
  TitleName,
  GreyTypography,
} from '../../../../../GlobalStyles'
import {Autocomplete} from '@material-ui/lab'
import {BoldTypography} from '../../../../../components/typography/TypographyStyles'
import {
  ADD_HOLDING_COMMITTEE,
  DELETE_HOLDING_COMMITTEE,
} from '../../../../../graphql/mutations'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {holdingRolesContext} from '../../../../../utils/helpers'

export default function FormCommittee({
  classes,
  dataCommittee,
  committee,
  setCommittee,
  isError,
  isEdit,
  getCommitteeEdit,
}) {
  const {enqueueSnackbar} = useSnackbar()
  const [addCommittee] = useMutation(ADD_HOLDING_COMMITTEE)
  const [deleteCommittee] = useMutation(DELETE_HOLDING_COMMITTEE)
  const handleDelete = (id_pwp, id_nomination) => {
    const newArr = committee.filter(el => el.id !== id_pwp)

    if (isEdit) {
      deleteCommittee({
        variables: {
          id: id_nomination,
        },
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Data updated', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          getCommitteeEdit()
          setCommittee(newArr)
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
    } else {
      setCommittee(newArr)
    }
  }

  const handleChange = (e, newValue) => {
    e.preventDefault()
    if (isEdit) {
      const difference =
        newValue && committee && committee.filter(x => !newValue.includes(x))
      if (difference.length > 0) {
        deleteCommittee({
          variables: {
            id: difference[0].id_nomination,
          },
          ...holdingRolesContext,
        })
          .then(() => {
            enqueueSnackbar('Data updated', {
              variant: 'success',
              autoHideDuration: 1000,
            })

            setCommittee(newValue)
            getCommitteeEdit()
          })
          .catch(e =>
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          )
      } else {
        addCommittee({
          variables: {
            object: {
              user_work_id: newValue.slice(-1)[0].id,
              nomination_id: isEdit,
            },
          },
          ...holdingRolesContext,
        })
          .then(d => {
            enqueueSnackbar('Data updated', {
              variant: 'success',
              autoHideDuration: 1000,
            })
            const i = newValue.findIndex(
              res => res.id === newValue.slice(-1)[0].id
            )
            const result =
              d.data.insert_holding_nomination_commite.returning[0].id

            newValue[i] = {
              ...newValue[i],
              id_nomination: result,
            }

            setCommittee(newValue)
            getCommitteeEdit()
          })
          .catch(e =>
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          )
      }
    } else {
      setCommittee(newValue)
    }
  }

  return (
    <FormContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleName>Add Committee</TitleName>
        </FormChildTitle>

        <Autocomplete
          multiple
          options={dataCommittee}
          value={committee}
          filterSelectedOptions
          onChange={(e, newValue) => handleChange(e, newValue)}
          getOptionLabel={option => option.name}
          renderOption={param => {
            return (
              <div className={classes.companyWrapper}>
                <Avatar
                  variant="rounded"
                  src={param.global_user.avatar}
                  className={classes.mr20}
                />
                <div className={classes.structureHeader}>
                  <BoldTypography variant="body2" gutterBottom>
                    {param.global_user.name}
                  </BoldTypography>
                  <GreyTypography variant="caption" gutterBottom>
                    {param.company_job_profile &&
                      param.company_job_profile.title}{' '}
                    at{' '}
                    {param.company_profile && param.company_profile.legal_name}
                  </GreyTypography>

                  <Divider className={classes.mt20} />
                </div>
              </div>
            )
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Select committee"
              variant="outlined"
              margin="normal"
              error={committee.length === 0 && isError}
              helperText={
                committee.length === 0 && isError
                  ? 'This field is required'
                  : ''
              }
            />
          )}
        />
      </FormChildContainer>
      {committee.length > 0 && (
        <div className={classes.committeContainer}>
          <TitleName>Committee List ({committee.length})</TitleName>
          <div className={classes.committeList}>
            {committee.map((res, i) => {
              return (
                <Paper className={classes.paperCommittee} key={i}>
                  <Avatar src={res.global_user.avatar} />
                  <div className={classes.detailCom}>
                    <div>
                      <TitleName>{res.name}</TitleName>
                      <Typography variant="body2">
                        {res.company_job_profile &&
                          res.company_job_profile.title}{' '}
                        at{' '}
                        {res.company_profile && res.company_profile.legal_name}
                      </Typography>
                    </div>
                    <GreyIcon
                      style={{cursor: 'pointer'}}
                      onClick={() => handleDelete(res.id, res.id_nomination)}
                    >
                      close
                    </GreyIcon>
                  </div>
                </Paper>
              )
            })}
          </div>
        </div>
      )}
    </FormContainer>
  )
}
