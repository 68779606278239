import React from 'react'
import styled from 'styled-components'
import {
  makeStyles,
  withStyles,
  Box,
  Paper,
  Icon,
  Typography,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core'

import {TableOptions as BaseTableOptions} from '../../financial/payroll/PayrollPageStyles'

// Material table
export const TableOptions = {
  ...BaseTableOptions,
  maxBodyHeight: null,
  pageSize: 20,
  pageSizeOptions: [20, 50, 100],
}

export function TableContainer(props) {
  return <Paper {...props} elevation={0} />
}

// Currency input
export function CurrencyInput(props) {
  return (
    <OutlinedInput
      inputProps={{style: {textAlign: 'right'}}}
      startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
      {...props}
    />
  )
}

// Information details
export const InfoTable = styled.table`
  table-layout: auto;
  border-spacing: 8px;

  & th {
    font-weight: 700;
    text-align: left;
    width: 12rem;
    position: relative;
  }

  & th::after {
    content: ':';
    position: absolute;
    right: 0;
  }
`

// Details card
export const DetailsCard = withStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    boxShadow: theme.shadows[1],
  },
}))(Paper)

// Balance summary
const balanceStyles = makeStyles(theme => ({
  heading: {
    color: theme.palette.primary.main,
    fontWeight: '700',
  },
  icon: {
    color: theme.palette.primary.main,
    fontWeight: '700',
    marginRight: theme.spacing(1),
  },
  amount: {
    fontSize: theme.spacing(2.5),
    fontWeight: '700',
  },
  divider: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.divider,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}))

export function BalanceAmount(props) {
  const {label, icon, amount, children, color} = props
  const styles = balanceStyles()

  return (
    <Box flexGrow={1} display="flex">
      {icon && <Icon className={styles.icon}>{icon}</Icon>}
      <Box style={{color: color}}>
        <Typography className={styles.heading}>{label}</Typography>
        <Typography className={styles.amount}>{amount}</Typography>
        {children}
      </Box>
    </Box>
  )
}

// Category Color
export const CATEGORY_COLOR = {
  In: '#4CAF50',
  Out: '#EF4D5E',
}

// Billing Status
export const PrimitiveBillingStatus = styled.div`
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
  padding: 4px;
  border-radius: 4px;
`

export const BILLING_STATUS_COLOR = {
  Paid: '#4CAF50',
  'Need to Pay': '#FFA000',
  'Paid Off': '#874CAF',

  Approved: '#4CAF50',
  Waiting: '#FFA000',
  Rejected: '#EF4D5E',

  Cancelled: '#A9A8A8',
}

export function BillingStatus(props) {
  const {status} = props

  const color = BILLING_STATUS_COLOR[status]

  return (
    <PrimitiveBillingStatus style={{backgroundColor: color}}>
      {status}
    </PrimitiveBillingStatus>
  )
}

// Billing Category
export const BILLING_CATEGORY_COLOR = {
  In: '#4CAF50',
  Out: '#EF4D5E',
}

// Number formatter
export const numberFormatter = new Intl.NumberFormat('id-ID')

// Input state hook
export function useInputState(initialState, useState = React.useState) {
  const [state, setState] = useState(initialState)

  const handleInputEvent = event => {
    let value = event
    const target = event?.target

    if (target) {
      switch (target.type) {
        case 'number':
          value = target.valueAsNumber
          break
        case 'date':
          value = target.valueAsDate
          break
        default:
          value = target.value
          break
      }
    }

    setState(value)
  }

  return [state, handleInputEvent]
}

export function useNumericInput(initialState) {
  const [state, setState] = React.useState(initialState)

  const handleInputEvent = event => {
    const next = event.target ? event.target.value : event

    const conv =
      typeof next !== 'number'
        ? parseFloat(('' + next).replace(/\./g, '').replace(',', '.') || '0')
        : next

    setState(conv)
  }

  return [state, numberFormatter.format(state), handleInputEvent]
}

export function useDerivedState(original) {
  const [value, setValue] = React.useState(original)

  React.useLayoutEffect(() => {
    if (value !== original) {
      setValue(original)
    }
  }, [original])

  return [value, setValue]
}

// Ordinal formatter
const ordinalRule = new Intl.PluralRules('en-US', {type: 'ordinal'})

const ordinalMap = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
}

export function formatOrdinals(number) {
  const rule = ordinalRule.select(number)
  const suffix = ordinalMap[rule]

  return number + suffix
}
