import React from 'react'
import {makeStyles, Typography, Grid, Paper} from '@material-ui/core'
import {
  GreyTypography,
  FlexBetween,
  MediumAvatar,
} from '../../../../../GlobalStyles'
import {
  BoldTypography,
  IconArrowDisabled,
  IconArrowBlue,
} from '../../../../../components/typography/TypographyStyles'
// import moment from 'moment'
import {switchFlexyComponents} from '../../../../../utils/helpers'
import NoBadge from '../../../../../assets/images/no-badge.svg'
import Carousel from 'nuka-carousel'
import {level} from './TalentSuccessorComponent'

const useStyles = makeStyles(() => ({
  gridContainer: {
    margin: 0,
    width: '100%',
    padding: 8,
  },
  paper: {
    padding: 20,
  },
  textCenter: {
    textAlign: 'center',
  },
  successor: {
    margin: '12px 0 16px',
    display: 'flex',
    padding: 12,
    alignItems: 'center',
  },
  indicator: {
    padding: 12,
  },
  namedSuccessor: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    cursor: 'pointer',
  },
  green: {
    color: '#4caf50',
  },
  spacer: {
    marginLeft: 10,
  },
  spacerPool: {
    marginLeft: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  spacerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    width: '100%',
  },
  mr20: {
    marginRight: 20,
  },
  flexDetail: {
    alignItems: 'center',
    display: 'flex',
  },
  paperCategory: {
    padding: '20px 0',
    textAlign: 'center',
    height: '100%',
    boxSizing: 'border-box',
  },
  red: {
    color: '#ef4d5e',
  },
  paperMobility: {
    textAlign: 'center',
    padding: 17,
    marginTop: 20,
    marginBottom: 42,
  },
  seeAll: {
    textAlign: 'center',
    marginTop: 16,
  },
  yellow: {
    color: '#ffa000',
  },
  flexStart: {display: 'flex', alignItems: 'flex-start'},
  badgeName: {
    width: 140,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  positionName: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
  },
  versionName: {
    color: '#a9a8a8',
    fontSize: 12,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
}))

const TalentSuccessorComponent = props => {
  const classes = useStyles()
  const {resTalent} = props

  const colorSwitch = value => {
    const val = value.toLowerCase()

    let color = null
    switch (val) {
      case 'low':
        color = classes.green
        break
      case 'medium':
        color = classes.yellow
        break
      default:
        color = classes.red
    }
    return color
  }

  return (
    <>
      <Carousel
        style={{outline: 'none'}}
        swiping
        renderCenterRightControls={({currentSlide, slideCount, nextSlide}) => {
          return (
            <div>
              {slideCount - currentSlide === 1 ? (
                <IconArrowDisabled
                  style={{
                    marginBottom: '8rem',
                    marginRight: '0.5rem',
                    display: resTalent.length === 0 ? 'none' : 'block',
                  }}
                >
                  chevron_right
                </IconArrowDisabled>
              ) : (
                <IconArrowBlue
                  onClick={nextSlide}
                  style={{
                    marginBottom: '8rem',
                    marginRight: '0.5rem',
                    display: resTalent.length === 0 ? 'none' : 'block',
                  }}
                >
                  chevron_right
                </IconArrowBlue>
              )}
            </div>
          )
        }}
        renderTopRightControls={null}
        renderCenterLeftControls={({previousSlide, currentSlide}) => {
          return (
            <div>
              {currentSlide === 0 ? (
                <IconArrowDisabled
                  style={{
                    marginBottom: '8rem',
                    marginLeft: '0.5rem',
                    display: resTalent.length === 0 ? 'none' : 'block',
                  }}
                >
                  chevron_left
                </IconArrowDisabled>
              ) : (
                <IconArrowBlue
                  style={{
                    marginBottom: '8rem',
                    marginLeft: '0.5rem',
                    display: resTalent.length === 0 ? 'none' : 'block',
                  }}
                  onClick={previousSlide}
                >
                  chevron_left
                </IconArrowBlue>
              )}
            </div>
          )
        }}
        renderBottomCenterControls={null}
      >
        {resTalent.map((resPlacement, i) => {
          const res = resPlacement?.dataProfile?.talent_assignments?.[0]

          const xArr = []
          const yArr = []

          if (res?.talentVersionByTalentVersion?.flexy_components) {
            res.talentVersionByTalentVersion.flexy_components.x.parameters.forEach(
              axis => switchFlexyComponents(axis, 'x', res, xArr, yArr)
            )
            res.talentVersionByTalentVersion.flexy_components.y.parameters.forEach(
              axis => switchFlexyComponents(axis, 'y', res, xArr, yArr)
            )
          }

          return (
            <div key={i}>
              <Paper className={classes.successor}>
                <MediumAvatar
                  src={resPlacement.dataProfile.global_user.avatar}
                  alt={resPlacement.dataProfile.global_user.name}
                ></MediumAvatar>
                <div className={classes.spacerTop}>
                  <div className={classes.flexDetail}>
                    <div className={classes.flexStart}>
                      <div className={classes.mr20}>
                        <BoldTypography variant="body1">
                          {resPlacement.dataProfile.global_user.name}
                        </BoldTypography>
                        <GreyTypography variant="body2">
                          {resPlacement && resPlacement.regno}
                        </GreyTypography>

                        <Typography
                          variant="body2"
                          className={classes.positionName}
                        >
                          {resPlacement.dataProfile.company_job_profile.title}
                        </Typography>
                      </div>
                      {/* <ColoredDiv bg="#4caf50">
                        {capitalize(resPlacement.dataProfile.readiness_status)}
                      </ColoredDiv> */}
                    </div>
                  </div>
                  {resPlacement && resPlacement.resd !== null ? (
                    <div className={classes.textCenter}>
                      <img
                        src={resPlacement.resd.badge}
                        alt={resPlacement.resd.name}
                        width="50"
                      ></img>
                      <Typography
                        variant="caption"
                        className={classes.badgeName}
                        component="p"
                        gutterBottom
                      >
                        {resPlacement.resd.name}
                      </Typography>
                      <BoldTypography variant="body2">
                        {resPlacement.nilai}
                      </BoldTypography>
                      <Typography
                        variant="caption"
                        className={classes.versionName}
                        component="p"
                        gutterBottom
                      >
                        Version : {res?.talentVersionByTalentVersion?.name}
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.textCenter}>
                      <img
                        src={NoBadge}
                        alt="no-badge"
                        height="50"
                        width="75"
                      />
                      <Typography variant="subtitle2">
                        Tidak Ada Lencana
                      </Typography>
                      <BoldTypography variant="body2">-</BoldTypography>
                    </div>
                  )}
                </div>
              </Paper>
              <Grid container item spacing={2}>
                <Grid item xs={4}>
                  <Paper className={classes.paperCategory}>
                    <Typography variant="body2" gutterBottom>
                      Risiko Kehilangan
                    </Typography>
                    <BoldTypography
                      variant="body1"
                      className={colorSwitch(
                        resPlacement.dataProfile.placement_fields
                          .talent_information.risk_of_loss
                      )}
                    >
                      {
                        level[
                          resPlacement?.dataProfile?.placement_fields
                            ?.talent_information?.risk_of_loss
                        ]
                      }
                    </BoldTypography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper className={classes.paperCategory}>
                    <Typography variant="body2" gutterBottom>
                      Dampak Kehilangan
                    </Typography>
                    <BoldTypography
                      variant="body1"
                      className={colorSwitch(
                        resPlacement.dataProfile.placement_fields
                          .talent_information.impact_of_loss
                      )}
                    >
                      {
                        level[
                          resPlacement?.dataProfile?.placement_fields
                            ?.talent_information?.impact_of_loss
                        ]
                      }
                    </BoldTypography>
                  </Paper>
                </Grid>

                <Grid item xs={4}>
                  <Paper className={classes.paperCategory}>
                    <Typography variant="body2" gutterBottom>
                      Pemimpin Masa Depan
                    </Typography>
                    <BoldTypography
                      variant="body1"
                      className={
                        resPlacement.dataProfile.placement_fields
                          .talent_information.future_leader
                          ? classes.green
                          : classes.red
                      }
                    >
                      {resPlacement.dataProfile.placement_fields
                        .talent_information.future_leader
                        ? 'Iya'
                        : 'Tidak'}
                    </BoldTypography>
                  </Paper>
                </Grid>
              </Grid>
              <Paper className={classes.paperMobility}>
                <Typography variant="body2">Mobilisasi Talenta</Typography>
                <GreyTypography style={{fontWeight: 600}}>
                  {
                    resPlacement.dataProfile.placement_fields.talent_information
                      .reason_for_leaving
                  }
                </GreyTypography>
              </Paper>
              <FlexBetween>
                <BoldTypography variant="body1">
                  {res?.talentVersionByTalentVersion?.flexy_components?.x?.name}
                </BoldTypography>
              </FlexBetween>

              <div className={classes.indicator}>
                {xArr?.map((res, i) => {
                  return (
                    <FlexBetween key={i}>
                      <Typography
                        variant="body2"
                        gutterBottom
                      >{`${res.param} (${res.index})`}</Typography>
                      <Typography variant="body2">{res.value}</Typography>
                    </FlexBetween>
                  )
                })}
              </div>
              <FlexBetween>
                <BoldTypography variant="body1">
                  {res?.talentVersionByTalentVersion?.flexy_components?.y?.name}
                </BoldTypography>
              </FlexBetween>

              <div className={classes.indicator}>
                {yArr?.map((res, i) => {
                  return (
                    <FlexBetween key={i}>
                      <Typography
                        variant="body2"
                        gutterBottom
                      >{`${res.param} (${res.index})`}</Typography>
                      <Typography variant="body2">{res.value}</Typography>
                    </FlexBetween>
                  )
                })}
              </div>
            </div>
          )
        })}
      </Carousel>
    </>
  )
}

export default TalentSuccessorComponent
