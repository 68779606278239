import React from 'react'
import {TableRow, TableCell} from '@material-ui/core'

const TableNotFound = ({col}) => (
  <TableRow style={{height: 245}}>
    <TableCell
      style={{paddingTop: 0, paddingBottom: 0, textAlign: 'center'}}
      colSpan={col}
    >
      No records to display
    </TableCell>
  </TableRow>
)

export default TableNotFound
