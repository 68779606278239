import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

import {FlexColumnStart} from '../../../../performance/goal/modal/modalStyles'
import {BoxText, FlexStart} from '../../../component/modalStyles'
import {ConversionDisplay} from '../../BusinessTripStyles'
import {useStyles} from './Destination'

import {calculateBudget} from '../../businessTripHelper'

function TotalBudget({data, currencyFrom, currencyTo, approvedDate}) {
  const styles = useStyles()

  const dataDestination = []
  let totalCost = 0

  for (const trip of data) {
    let tripCost = 0

    for (const budget of trip.budget) {
      tripCost += calculateBudget(trip, budget)
    }

    totalCost += tripCost

    dataDestination.push({
      trip: `${trip.globalDestinationByFrom.name} - ${trip.globalDestinationByTo.name}`,
      date: `(${trip.duration} Days)`,
      total: tripCost,
    })
  }

  return (
    <FlexColumnStart>
      <FlexStart style={{margin: `16px 0 8px`}}>
        <BoxText>Total Budget</BoxText>
      </FlexStart>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHeaderCell}>Trip</TableCell>
              <TableCell className={styles.tableHeaderCell} align="right">
                Total Budget
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dataDestination.map((row, key) => (
              <TableRow key={key}>
                <TableCell className={styles.tableCell}>{row.trip}</TableCell>
                <TableCell className={styles.tableCell} align="right">
                  <ConversionDisplay
                    // NOTE(intrnl): Yes, this is intentionally flipped
                    from={currencyTo}
                    to={currencyFrom}
                    value={row.total}
                    date={approvedDate}
                  />
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell className={styles.tableFooterCell}>
                Total Budget
              </TableCell>
              <TableCell className={styles.tableFooterCell} align="right">
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={currencyTo}
                  to={currencyFrom}
                  value={totalCost}
                  date={approvedDate}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FlexColumnStart>
  )
}

export default TotalBudget
