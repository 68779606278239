import styled from 'styled-components'
import {Dialog, ExpansionPanelSummary} from '@material-ui/core'

export const ModalHeader = styled.div`
  padding: 0.5rem 2rem;
  background-color: #014a62;
  color: #ffffff;
`

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const FlexColumnStart = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

export const FlexColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
`

export const FlexStart = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`

export const StyledDialog = styled(Dialog)`
  font-family: ${props => props.theme.font};
`

export const BoxText = styled.div`
  padding-top: 8px;
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  font-weight: 600;
  color: #707070;

  &:empty {
    display: none;
  }
`

export const StyledExpansionSummaryAssign = styled(ExpansionPanelSummary).attrs(
  {
    classes: {content: 'content'},
  }
)`
  .content {
    display: block;
    padding: 0 16px;
  }
`

export const TextBold = styled.div`
  font-weight: bold;
`

export const TextBlue = styled.div`
  font-family: Muli;
  color: #039be5;
`

export const TextRed = styled.div`
  font-family: Muli;
  color: #ef4d5e;
`

export const TextCancel = styled.div`
  font-family: Muli;
  color: #757575;
`

export const ItemClause = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  background-color: #fff;
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 #b2b2b2;
`
