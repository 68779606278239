import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  FormContainer,
  AddEditTitle,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  ThinTextfield,
  FormToolbar,
  FormTitleContainer,
} from '../../../../../GlobalStyles'
import {
  Divider,
  IconButton,
  TextField,
  MenuItem,
  Button,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Link, withRouter} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  CHECK_CODE_MONITORING,
  CHECK_MONITORING_ALREADY_TAKEN,
} from '../../../../../graphql/queries'
import {
  ADD_NEW_KPI_MONITORING,
  UPDATE_KPI_MONITORING,
} from '../../../../../graphql/mutations'
import {COMPANY_ID} from '../../../../../utils/globals'
import Loading from '../../../../../components/loading/LoadingComponent'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../../shared-components/popup/AddEditPopupFreeText'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {status_data} from '../../../../shared-components/data/SharedData'

function FormMonitoring(props) {
  const {dataMonitoring, history} = props
  const {enqueueSnackbar} = useSnackbar()
  const [isEdit, setIsEdit] = useState(false)
  const [allDataForm, setAllDataForm] = useState({
    code: '',
    name: '',
    description: '',
    status: 'ACTIVE',
  })
  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)
  const [dialogCantDel, setDialogCantDel] = useState(false)
  const [skipQueryCheckMonitoring, setSkipQueryCheckMonitoring] = useState(true)

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  const [mutationAddMonitoring] = useMutation(ADD_NEW_KPI_MONITORING)
  const [mutationEditMonitoring] = useMutation(
    UPDATE_KPI_MONITORING(dataMonitoring && dataMonitoring.id)
  )

  useEffect(() => {
    if (dataMonitoring) {
      setIsEdit(true)
      setAllDataForm({
        ...allDataForm,
        code: dataMonitoring.code || '',
        name: dataMonitoring.name,
        description: dataMonitoring.description,
        status: dataMonitoring.status,
      })
      setSkipQueryCheckMonitoring(false)
    }
  }, [isEdit, dataMonitoring])

  const {data: checkCodeMonitoring, error: checkCodeMonitoringError} = useQuery(
    CHECK_CODE_MONITORING,
    {
      variables: {
        company: COMPANY_ID,
        code: allDataForm && allDataForm.code,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {data: checkMonitoringAlreadyTaken} = useQuery(
    CHECK_MONITORING_ALREADY_TAKEN,
    {
      variables: {
        monitoring: dataMonitoring && dataMonitoring.id,
      },
      skip: skipQueryCheckMonitoring,
      fetchPolicy: 'cache-and-network',
    }
  )

  if (
    checkCodeMonitoring === undefined &&
    checkCodeMonitoringError === undefined
  ) {
    return <Loading />
  } else if (checkCodeMonitoringError !== undefined) {
    return JSON.stringify(checkCodeMonitoringError)
  }

  const codeChecker =
    checkCodeMonitoring?.kpi_monitoring_frequencies_aggregate?.aggregate
      ?.count > 0

  const isMonitoringAlreadyTaken =
    checkMonitoringAlreadyTaken?.kpi_lists_aggregate?.aggregate?.count > 0

  const checkSameCodeWithAdd =
    allDataForm?.code?.length >= 1
      ? dataMonitoring?.code === allDataForm.code
      : false

  const mutateMonitoring = () => {
    if (
      !allDataForm.code ||
      allDataForm.code.length > 50 ||
      !allDataForm.name ||
      !allDataForm.description ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const data = {
        code: allDataForm.code,
        name: allDataForm.name,
        description: allDataForm.description,
        status: allDataForm.status,
      }
      if (!isEdit) {
        mutationAddMonitoring({variables: {data}})
          .then(() => {
            enqueueSnackbar('Success Add data', {variant: 'success'})
            history.push({
              pathname: '/performance/kpi/monitoring',
              state: {active: 'monitoring', searchable: true},
            })
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
            })
          })
      } else {
        mutationEditMonitoring({variables: {data}})
          .then(() => {
            enqueueSnackbar('Success Update data', {variant: 'success'})
            history.push({
              pathname: '/performance/kpi/monitoring',
              state: {active: 'monitoring', searchable: true},
            })
          })
          .catch(() => {
            enqueueSnackbar('Update data error, please try again', {
              variant: 'error',
            })
          })
      }
      //   console.log(data)
    }
  }

  const editName = isEdit ? dataMonitoring?.name : allDataForm.name

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link
            to={{
              pathname: '/performance/kpi/monitoring',
              state: {active: 'monitoring', searchable: true},
            }}
          >
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6" display="inline">
            {isEdit ? 'Ubah' : 'Tambahkan'} Frekuensi pemantauan{' '}
            {GLOBAL_GLOSSARY.performance.Goal}
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            size="large"
            component={Link}
            to={{
              pathname: '/performance/kpi/monitoring',
              state: {active: 'monitoring', searchable: true},
            }}
            style={{marginRight: 12}}
          >
            Batalkan
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="large"
          >
            Simpan
          </Button>
        </div>
      </FormToolbar>
      <Divider></Divider>
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>
              Kode Frekuensi pemantauan {GLOBAL_GLOSSARY.performance.Goal} *
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                setAllDataForm({...allDataForm, code: event.target.value})
              }}
              variant="outlined"
              value={allDataForm.code}
              error={
                (!allDataForm.code && isError) ||
                (allDataForm.code && allDataForm.code.length > 50) ||
                (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
              }
              helperText={
                !allDataForm.code && isError
                  ? 'Bagian ini diperlukan'
                  : (codeChecker
                    ? !checkSameCodeWithAdd
                    : checkSameCodeWithAdd)
                  ? `Kode Frekuensi pemantauan ${GLOBAL_GLOSSARY.performance.Goal} sudah ada`
                  : allDataForm.code && allDataForm.code.length > 50
                  ? 'Jumlah karakter maksimum adalah 50 karakter'
                  : `Kode Frekuensi pemantauan ${GLOBAL_GLOSSARY.performance.Goal} harus unik`
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>
              Nama Frekuensi pemantauan {GLOBAL_GLOSSARY.performance.Goal} *
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                setAllDataForm({...allDataForm, name: event.target.value})
              }}
              variant="outlined"
              value={allDataForm.name}
              error={allDataForm.name.length < 1 && isError}
              helperText={
                allDataForm.name.length < 1 && isError
                  ? 'Bagian ini diperlukan'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Keterangan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                setAllDataForm({
                  ...allDataForm,
                  description: event.target.value,
                })
              }}
              variant="outlined"
              value={allDataForm.description}
              error={allDataForm.description.length < 1 && isError}
              helperText={
                allDataForm.description.length < 1 && isError
                  ? 'Bagian ini diperlukan'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Status *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              select
              fullWidth
              onChange={event => {
                if (isMonitoringAlreadyTaken) {
                  setDialogCantDel(!dialogCantDel)
                } else {
                  setAllDataForm({
                    ...allDataForm,
                    status: event.target.value,
                  })
                }
              }}
              margin="normal"
              variant="outlined"
              value={allDataForm.status}
            >
              <MenuItem value="ACTIVE">{status_data.ACTIVE}</MenuItem>
              <MenuItem value="INACTIVE">{status_data.INACTIVE}</MenuItem>
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        mutation={mutateMonitoring}
        header={CONFIRMATION[isEdit ? 'Edit' : 'Add']?.header?.replace(
          '[name]',
          editName
        )}
        body={CONFIRMATION[isEdit ? 'Edit' : 'Add']?.body?.replace(
          '[name]',
          editName
        )}
        button={CONFIRMATION[isEdit ? 'Edit' : 'Add']?.button}
        buttonClose="Batalkan"
      />

      <AlertDialog
        open={dialogCantDel}
        handleClose={() => setDialogCantDel(!dialogCantDel)}
        feature="Tidak Dapat Mengubah Status"
        message={`Tidak dapat menetapkan status menjadi tidak aktif karena monitoring ${GLOBAL_GLOSSARY.performance.Goal} ini sudah digunakan di Kumpulan ${GLOBAL_GLOSSARY.performance.Goal}`}
        type="Confirm"
        positiveTitleButton="Konfirmasi"
      />
    </PaperContainer>
  )
}

export default withRouter(FormMonitoring)

const CONFIRMATION = {
  Add: {
    header: 'Tambahkan [name]?',
    body: `Apakah anda yakin akan menambahkan Frekuensi Pemantauan ${GLOBAL_GLOSSARY.performance.Goal} yang baru - [name]? `,
    button: 'Tambah',
  },
  Edit: {
    header: 'Ubah [name]?',
    body: `Apakah anda yakin akan mengubah Frekuensi Pemantauan ${GLOBAL_GLOSSARY.performance.Goal} - [name]? `,
    button: 'Ubah',
  },
}
