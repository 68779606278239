import React, {useState, useEffect} from 'react'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  WhiteButton,
  WrapperContent,
  TextDarkBlue,
  TextGrey,
} from '../../OrganizationPageStyles'
import {Button} from '@material-ui/core'
import {
  RootContainer,
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import Loading from '../../../../components/loading/LoadingComponent'
import {Link} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import MaterialTable from 'material-table'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_LIST_ORG_LEVEL,
  GET_BALOON_STATUS_BUTTON,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../../../graphql/queries'
import {
  DELETE_ORG_LEVEL,
  IMPORT_DATA,
  CLOSE_BALOON,
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
} from '../../../../graphql/mutations'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import {disbaledButtonExport} from '../../../../utils/helpers'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import {COMPANY_ID} from '../../../../utils/globals'

import DeletePopup from '../../../shared-components/popup/DeletePopup'
// import DownloadPopup from '../../../shared-components/popup/DownloadPopup'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import ImportBaloon from '../../../../components/importExcel/ImportBaloon'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'

const ListLevel = ({searchText}) => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [OpenAlert, setOpenAlert] = useState(false)
  const [OpenCantDel, setOpenCantDel] = useState(false)
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )
  const {data: getBaloonStatus, error: errorGetBaloonStatus} = useQuery(
    GET_BALOON_STATUS_BUTTON,
    {
      wlb_skipPatch: true,
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_employee_ranks',
      },
    }
  )

  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )

  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  const {data: getExportBaloon, error: errorGetExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_employee_ranks',
      },
    }
  )

  const {data: getExportDisabled, error: errorGetExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_employee_ranks',
      },
    }
  )

  const [openImport, setImport] = useState(false)
  const [openExport, setExport] = useState(false)
  const [dataLevel, setDataLevel] = useState(false)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })
  const {data, error, loading, refetch} = useQuery(GET_LIST_ORG_LEVEL, {
    wlb_skipPatch: true,
    variables: {
      search: `%${searchText}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })
  const [deleteLevel] = useMutation(DELETE_ORG_LEVEL)
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  if (
    (data === undefined && error === undefined) ||
    getBaloonStatus === undefined
  ) {
    return <Loading />
  } else if (
    error !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationImportError !== undefined ||
    mutationExportError !== undefined ||
    closeBaloonExportError !== undefined ||
    errorGetExportBaloon !== undefined ||
    errorGetExportDisabled !== undefined
  ) {
    return <div>{error}</div>
  }

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  const onDeleteLevel = () => {
    const variables = {
      id: dataLevel.id,
    }
    deleteLevel({variables: variables})
      .then(() => {
        enqueueSnackbar('Job Level deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete Job Level failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleOpenAlert = data => {
    const countJobProfile =
      data &&
      data.company_job_profiles_aggregate &&
      data.company_job_profiles_aggregate.aggregate &&
      data.company_job_profiles_aggregate.aggregate.count > 0
    setDataLevel(data)
    countJobProfile ? setOpenCantDel(true) : setOpenAlert(true)
  }

  const handleCloseAlert = status => {
    status === 'delete' ? setOpenAlert(false) : setOpenCantDel(false)
  }

  const importMutation = async () => {
    try {
      const response = await mutationImport({
        variables: {
          table: 'company_employee_ranks',
          file: fileURL,
        },
      })
      // eslint-disable-next-line no-console
      console.log(response)
      refetch()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const handleClickOpenImport = () => {
    setImport(true)
  }
  const handleCloseImport = () => {
    setImport(false)
  }

  const handleDownload = () => {
    setExport(!openExport)
    if (openExport) {
      mutationExport({
        variables: {
          table: 'company_employee_ranks',
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const column = [
    {
      title: 'Legenda',
      field: 'color_hex',
      render: data => {
        const color = data.color_hex

        if (!color) {
          return null
        }

        return (
          <div
            style={{
              height: 32,
              width: 32,
              borderRadius: 4,
              backgroundColor: color,
            }}
          />
        )
      },
    },
    {
      title: 'Kode Jenjang Jabatan',
      field: 'code',
      defaultSort: 'asc',
      headerStyle: {minWidth: 400, maxWidth: 400},
      cellStyle: {minWidth: 400, maxWidth: 400},
      render: rowData => (
        <div style={{wordBreak: 'break-word'}}>{rowData.code}</div>
      ),
    },
    {
      title: 'Nama Jenjang Jabatan',
      field: 'name',
      headerStyle: {minWidth: 400, maxWidth: 400},
      cellStyle: {minWidth: 400, maxWidth: 400},
      render: rowData => (
        <div style={{wordBreak: 'break-word'}}>{rowData.name}</div>
      ),
    },
    // {
    //   title: 'Total Employee',
    //   field: 'count_employee',
    //   cellStyle: {
    //     textAlign: 'center',
    //   },
    // },
    {
      title: 'Aksi',
      field: 'action',
      render: rowData => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: `/organization/job-level/edit`,
                    state: {
                      active: 'edit-job-level',
                      status: 'Edit',
                      data: rowData,
                    },
                  }}
                  variant="h6"
                >
                  Ubah
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleOpenAlert(rowData)
                  }}
                >
                  Hapus
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>
      ),
    },
  ]

  return (
    <RootContainer>
      <ImportBaloon
        data={getBaloonStatus}
        closeBaloonMutation={closeBaloonMutation}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
      />
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
      />
      <OrganizationContainer className={classes.root}>
        <PaperContainer>
          <EnhancedToolbar>
            <BigTextBlackBold>Jenjang Jabatan</BigTextBlackBold>
            <WrapperContent>
              <GenerateTooltip
                title={
                  (getExportBaloon &&
                    getExportBaloon.company_export_data_logs &&
                    getExportBaloon.company_export_data_logs.length > 0 &&
                    getExportBaloon.company_export_data_logs[0].status ===
                      'FAILED') ||
                  disbaledButtonExport(
                    getExportDisabled &&
                      getExportDisabled.company_export_data_logs &&
                      getExportDisabled.company_export_data_logs[0] &&
                      getExportDisabled.company_export_data_logs[0].date_added
                  )
                    ? `Anda hanya dapat menghasilkan data sekali sehari. Untuk mengunduh data gunakan link yang sudah dibuat`
                    : ``
                }
                placement="left"
              >
                <span>
                  <WhiteButton
                    variant="contained"
                    size="large"
                    style={{marginRight: 12}}
                    disabled={
                      (getExportBaloon &&
                        getExportBaloon.company_export_data_logs &&
                        getExportBaloon.company_export_data_logs.length > 0 &&
                        getExportBaloon.company_export_data_logs[0].status ===
                          'FAILED') ||
                      disbaledButtonExport(
                        getExportDisabled &&
                          getExportDisabled.company_export_data_logs &&
                          getExportDisabled.company_export_data_logs[0] &&
                          getExportDisabled.company_export_data_logs[0]
                            .date_added
                      )
                    }
                    onClick={() => handleDownload()}
                  >
                    <TextDarkBlue>Ekspor Data</TextDarkBlue>
                  </WhiteButton>
                </span>
              </GenerateTooltip>
              <WhiteButton
                variant="contained"
                size="large"
                style={{marginRight: 12}}
                onClick={handleClickOpenImport}
                disabled={
                  getBaloonStatus.company_import_data_logs.length > 0 &&
                  getBaloonStatus.company_import_data_logs[0].status ===
                    'WAITING'
                }
              >
                {getBaloonStatus.company_import_data_logs.length > 0 &&
                getBaloonStatus.company_import_data_logs[0].status ===
                  'WAITING' ? (
                  <TextGrey>Importing on proces...</TextGrey>
                ) : (
                  <TextDarkBlue>Impor Jenjang Jabatan</TextDarkBlue>
                )}
              </WhiteButton>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to={{
                  pathname: `/organization/job-level/add`,
                  state: {
                    active: 'add-job-level',
                    status: 'Add',
                  },
                }}
              >
                Tambah Jenjang Jabatan
              </Button>
            </WrapperContent>
          </EnhancedToolbar>
          <MaterialTable
            options={{
              ...TableOptions,
              selection: false,
              showTitle: false,
              toolbar: false,
              emptyRowsWhenPaging: false,
            }}
            isLoading={loading}
            localization={{
              pagination: TableLocalization.pagination,
              body: {
                emptyDataSourceMessage: (
                  <NoDataListComponent search={searchText} />
                ),
              },
            }}
            columns={column}
            data={data.company_employee_ranks}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            totalCount={data.total.aggregate.count}
            page={pagination.offset}
            pageSize={pagination.limit}
          />
          <DeletePopup
            open={OpenAlert}
            handleClose={() => handleCloseAlert('delete')}
            id={dataLevel.id}
            name={dataLevel.name}
            mutation={() => onDeleteLevel()}
          />
          <AlertDialog
            open={OpenCantDel}
            handleClose={() => handleCloseAlert('cantDelete')}
            feature={`Tidak dapat menghapus ${dataLevel.name}`}
            name={dataLevel.name}
            fMessage="Anda tidak dapat menghapus "
            lMessage=" karena digunakan pada posisi"
            type="Confirm"
            positiveTitleButton="Konfirmasi"
          />
          <ImportPopup
            open={openImport}
            position="Job Level"
            handleClose={handleCloseImport}
            mutation={importMutation}
            fileTemplate={
              'https://public-media.wlb.co.id/60e779b4-cb99-4049-bc6a-4e89ecfc8041-1600976478929.xlsx'
            }
            isAlreadyImported={isAlreadyImported}
            setIsAlreadyImported={setIsAlreadyImported}
            setImport={setImport}
            fileURL={fileURL}
            setFileURL={setFileURL}
            closeBaloon={closeBaloonMutation}
            errorsImport={
              getBaloonStatus.company_import_data_logs.length > 0 &&
              getBaloonStatus.company_import_data_logs[0].reports &&
              getBaloonStatus.company_import_data_logs[0].reports.failed
                .length > 0
                ? getBaloonStatus.company_import_data_logs[0].reports.failed
                : []
            }
          />
          <AlertDialog
            open={openExport}
            handleClose={handleDownload}
            feature="Ekspor data sedang diproses!"
            message="Ekspor data sedang diproses! Kami akan mengirimkan email kepada Anda jika sudah selesai"
            type="Confirm"
            positiveTitleButton="Konfirmasi"
          />
        </PaperContainer>
      </OrganizationContainer>
    </RootContainer>
  )
}

export default ListLevel
