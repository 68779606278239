import React, {useState} from 'react'
import ArrowBack from '@material-ui/icons/ArrowBack'
import MaterialTable from 'material-table'
import moment from 'moment'
import {IconButton, CircularProgress, Button} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {
  EnhancedToolbar,
  PaperContainer,
  GenerateTooltip,
} from '../../../../../../GlobalStyles'
import {
  BigTextBlackBold,
  TableOptions,
  TextBlueBold,
  CenterWrapper,
  pageSizeOption,
} from '../../../PayrollPageStyles'
import AlertDialog from '../../../../../shared-components/popup/ResultPopup'
import EmployeeAvatar from '../../../../../shared-components/EmployeeAvatar'
import ExportBaloon from '../../../../../../components/exportExcel/ExportBaloon'
import ModalPaySlip from '../../../shared-components/ModalPayslip'
import useDownloadReport from '../../../../../../hooks/useDownloadReport'
import {tableStyle} from '../../../PayrollTableStyles'

import {GQL_FINANCE_OPTIONS} from '../../../utils'
import {GET_PAYROLL_REPORT_DETAIL} from '../../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../../utils/globals'
import {
  employeeColumnSort,
  convertToRoundedRupiah,
} from '../../../../../../utils/helpers'

export default withRouter(function DetailPayrollReport(props) {
  const {searchText, filterData} = props
  const {month, year} = props.location.state

  const months = moment.monthsShort()
  const TABLE_REPORT_NAME = 'finance_payroll_report'

  const [currentPage, setCurrentPage] = useState(0)
  const [employeeId, setEmployeeId] = useState()
  const [openDownload, setOpenDownload] = useState(false)
  const [openPaySlip, setOpenPaySlip] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])

  const {data: payrollReportDetailList, loading, error} = useQuery(
    GET_PAYROLL_REPORT_DETAIL,
    {
      variables: {
        company: COMPANY_ID,
        year: year,
        month: month,
        offset: currentPage * rowsPerPage,
        limit: rowsPerPage,
        organization:
          JSON.stringify(filterData.organization) === '[]'
            ? null
            : filterData.organization,
        position:
          JSON.stringify(filterData.position) === '[]'
            ? null
            : filterData.position,
        job_profile:
          JSON.stringify(filterData.job_profile) === '[]'
            ? null
            : filterData.job_profile,
        rank: JSON.stringify(filterData.rank) === '[]' ? null : filterData.rank,
        office:
          JSON.stringify(filterData.office) === '[]' ? null : filterData.office,
        search: `%${searchText}%`,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  const {
    dataBaloon,
    isDisableButton,
    onDownload,
    onCloseBaloon,
  } = useDownloadReport({
    table: TABLE_REPORT_NAME,
    filter: {
      period_year: year,
      period_month: month,
    },
  })

  const handleDownload = () => {
    setOpenDownload(prev => !prev)
    if (openDownload) {
      onDownload({
        variables: {
          table: TABLE_REPORT_NAME,
          filter: {period_year: year, period_month: month},
        },
      })
    }
  }

  if (error) console.error(error)

  return (
    <>
      <PaperContainer>
        <EnhancedToolbar style={{paddingLeft: 12}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Link
              to={{
                pathname: '/financial/payroll/report',
                state: {
                  active: 'report',
                  searchable: false,
                  showPeriod: false,
                  tab: 'payroll',
                },
              }}
            >
              <IconButton edge="end" aria-label="back">
                <ArrowBack />
              </IconButton>
            </Link>

            <BigTextBlackBold style={{marginLeft: 16}}>
              Payroll Report Detail - {month && months[month - 1]} {year}
            </BigTextBlackBold>
          </div>

          <GenerateTooltip
            title={
              isDisableButton
                ? `You can only generate data once a day. To download data use the link that already generated.`
                : ``
            }
            placement="left"
          >
            <span>
              <Button
                size="large"
                variant="contained"
                color="primary"
                disabled={isDisableButton}
                onClick={handleDownload}
                style={{
                  color: 'white',
                  marginRight: '8px',
                }}
              >
                Generate Data
              </Button>
            </span>
          </GenerateTooltip>
        </EnhancedToolbar>

        {!payrollReportDetailList && loading && (
          <CenterWrapper>
            <CircularProgress />
          </CenterWrapper>
        )}

        <ExportBaloon
          isLastGenerateStatus
          isReport
          data={dataBaloon}
          closeBaloonMutation={onCloseBaloon}
          retry={handleDownload}
        />

        {payrollReportDetailList && (
          <>
            <MaterialTable
              totalCount={
                payrollReportDetailList.employee_count.aggregate.count
              }
              options={{...TableOptions}}
              style={{fontSize: 12}}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={page => {
                setCurrentPage(page)
              }}
              isLoading={payrollReportDetailList && loading}
              onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
              columns={[
                {
                  title: 'Name',
                  field: 'name',
                  ...tableStyle({minWidth: 380}, 'cell'),
                  customSort: employeeColumnSort,
                  render: rowData => {
                    if (rowData.employee_placement?.global_user) {
                      return (
                        <EmployeeAvatar
                          src={rowData.employee_placement?.global_user.avatar}
                          regno={rowData.employee_placement?.regno}
                          title={
                            rowData.employee_placement?.company_job_profile
                              .title
                          }
                          global_user={rowData.employee_placement?.global_user}
                          city_name={
                            rowData.employee_placement?.company_job_profile
                              .company_address.global_city.name
                          }
                          province_name={
                            rowData.employee_placement?.company_job_profile
                              .company_address.global_province.name
                          }
                          empStatus={rowData.employee_placement?.status}
                          empEndDate={rowData.employee_placement?.end_date}
                        />
                      )
                    } else {
                      return (
                        <EmployeeAvatar
                          src={''}
                          regno={rowData.employee_placement?.regno}
                          title={
                            rowData.employee_placement?.company_job_profile
                              .title
                          }
                          empStatus={rowData.employee_placement?.status}
                          empEndDate={rowData.employee_placement?.end_date}
                        />
                      )
                    }
                  },
                },
                {
                  title: 'Basic Salary',
                  field: 'salary',
                  ...tableStyle({}, 'cell-number'),
                  render: rowData =>
                    convertToRoundedRupiah(rowData.salary, false),
                },
                {
                  title: 'Earning',
                  field: 'addition',
                  ...tableStyle({}, 'cell-number'),
                  render: rowData =>
                    convertToRoundedRupiah(rowData.addition, false),
                },
                {
                  title: 'Deduction',
                  field: 'deduction',
                  ...tableStyle({}, 'cell-number'),
                  render: rowData =>
                    convertToRoundedRupiah(rowData.deduction, false),
                },
                {
                  title: 'Take Home Pay',
                  field: 'final_salary',
                  ...tableStyle({}, 'cell-number'),
                  render: rowData =>
                    convertToRoundedRupiah(rowData.final_salary, false),
                },
                {
                  title: 'Action',
                  field: 'action',
                  ...tableStyle({minWidth: 120}, 'cell'),
                  sorting: false,
                  render: rowData => (
                    <TextBlueBold
                      onClick={() => {
                        setOpenPaySlip(true)
                        setEmployeeId(rowData.id)
                      }}
                    >
                      Details
                    </TextBlueBold>
                  ),
                },
              ]}
              data={payrollReportDetailList.people_work_payroll}
            />
          </>
        )}

        {openPaySlip && (
          <ModalPaySlip
            open={openPaySlip}
            employeeId={employeeId}
            from={'payroll'}
            handleClose={() => {
              setEmployeeId()
              setOpenPaySlip(false)
            }}
          />
        )}

        {openDownload && (
          <AlertDialog
            open={openDownload}
            handleClose={handleDownload}
            feature="Generate Data is Processing!"
            message="Generate Data is processing! We’ll send you an email when it’s ready."
            type="Confirm"
          />
        )}
      </PaperContainer>
    </>
  )
})
