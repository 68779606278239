import BusinessTripPage from './BusinessTripPageComponent'

export const BusinessTripPageConfig = {
         routes: [
           {
             path: '/business/trip/activity',
             component: BusinessTripPage,
             state: 'activity',
             exact: true,
           },
           {
             path: '/business/trip/trip-report',
             component: BusinessTripPage,
             state: 'trip-report',
             exact: true,
           },
           {
             path: '/business/trip/settings',
             component: BusinessTripPage,
             state: 'settings',
             exact: true,
           },
           {
             path: '/business/trip/settings/add-component',
             component: BusinessTripPage,
             state: 'add-component',
             activeTab: 'Component',
             exact: false,
           },
           {
             path: '/business/trip/settings/add-policy',
             component: BusinessTripPage,
             state: 'add-policy',
             exact: false,
           },
           {
             path: '/business/trip/settings/edit-policy/:id',
             component: BusinessTripPage,
             state: 'add-policy',
             exact: false,
           },
         ],
       }
