import gql from 'graphql-tag'

export const REQUEST_CLAIM_INVOICE_EXPORTS = gql`
  mutation($invoiceId: String!, $userId: String!) {
    exportInvoice(invoiceId: $invoiceId, userId: $userId) {
      success
    }
  }
`

// Waiting -> Rejected
export const SET_CLAIM_REJECTED = gql`
  mutation($requestId: bigint!, $fields: jsonb) {
    update_people_work_claims_by_pk(
      pk_columns: {id: $requestId}
      _set: {status: "rejected"}
      _append: {claim_fields: $fields}
    ) {
      id
      status
      claim_fields
    }
  }
`

// Waiting -> Processing
export const APPROVE_CLAIM = gql`
  mutation($requestId: Int!) {
    approveClaim(id: $requestId, as: "admin") {
      success
      number_of_approvals {
        current
        required
      }
    }
  }
`

// Processing -> Approved
export const SET_CLAIM_APPROVED = gql`
  mutation(
    $requestId: bigint!
    $userId: uuid!
    $proofs: jsonb
    $fields: jsonb
  ) {
    update_people_work_claims_by_pk(
      pk_columns: {id: $requestId}
      _set: {status: "approved", approved_by: $userId, proofs: $proofs}
      _append: {claim_fields: $fields}
    ) {
      id
      status
      proofs
    }
  }
`
