import React from 'react'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import AddEditIdentityCard from './AddEditIdentityCard'
import DeletePopup from '../../../../../../shared-components/popup/DeletePopup'
import LoadingEmployee from '../../../../LoadingEmployee'
import {GridListing} from '../../../DetailEmployeeStyles'
import {ButtonAddDataTab} from '../data-tab-shared'
import {IdentityCardItem} from './IdentityCardItem'

import {GET_IDENTITY_CARDS} from '../../../../../../../graphql/queries'
import {
  DELETE_IDENTITY_CARD,
  DELETE_KTP_PWS,
  DELETE_NPWP_PWS,
} from '../../../../../../../graphql/mutations'

const INITIAL_DETAIL_STATE = {
  openForm: false,
  openDelete: false,
  data: null,
}

const IdentityCard = props => {
  const {userId} = props

  const {enqueueSnackbar} = useSnackbar()

  const [detailState, setDetailState] = React.useState(INITIAL_DETAIL_STATE)

  const {data, error, refetch} = useQuery(GET_IDENTITY_CARDS, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {userId},
  })

  const [deleteIdentityCard] = useMutation(DELETE_IDENTITY_CARD)
  const [deleteNpwpPws] = useMutation(DELETE_NPWP_PWS, {
    variables: {
      user_id: userId,
    },
  })
  const [deleteKtpPws] = useMutation(DELETE_KTP_PWS, {
    variables: {
      user_id: userId,
    },
  })

  if (error) {
    return <div>{'' + error}</div>
  }

  if (!data) {
    return <LoadingEmployee noborder smallpadding />
  }

  const handleCardEdit = data => {
    setDetailState({...detailState, openForm: true, data: data})
  }

  const handleCardAdd = () => {
    setDetailState({...detailState, openForm: true, data: null})
  }

  const handleFormClose = () => {
    setDetailState({...detailState, openForm: false})
  }

  const handleFormSubmit = shouldRefetch => {
    handleFormClose()

    if (shouldRefetch) {
      refetch()
    }
  }

  const handleDeleteOpen = () => {
    setDetailState({...detailState, openDelete: true})
  }

  const handleDeleteClose = action => {
    setDetailState({
      ...detailState,
      openForm: action === 'action-cancel',
      openDelete: false,
    })
  }

  const handleDeleteConfirm = () => {
    const data = detailState.data

    const promise = deleteIdentityCard({
      variables: {
        id: data.number,
        type: data.type,
        dateAdded: data.date_added,
      },
    })

    promise.then(
      () => {
        const mutation = {NPWP: deleteNpwpPws, KTP: deleteKtpPws}
        if (mutation[data.type]) {
          mutation[data.type]()
        }
        refetch()
        enqueueSnackbar('Kartu Identitas dihapus', {variant: 'success'})
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Failed to delete identity card`, {variant: 'error'})
      }
    )
  }

  return (
    <>
      <GridListing>
        {data.people_identities.map(res => (
          <IdentityCardItem
            key={res.date_added}
            data={res}
            onEdit={() => handleCardEdit(res)}
          />
        ))}
      </GridListing>

      <ButtonAddDataTab onClick={handleCardAdd}>
        Tambahkan Kartu Identitas
      </ButtonAddDataTab>

      <AddEditIdentityCard
        userIdentity={data}
        open={detailState.openForm}
        userId={userId}
        initialData={detailState.data}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        onDelete={handleDeleteOpen}
      />

      <DeletePopup
        name="Kartu Identitas"
        open={detailState.openDelete}
        mutation={handleDeleteConfirm}
        handleClose={handleDeleteClose}
      />
    </>
  )
}

export default IdentityCard
