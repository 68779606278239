import React, {useState} from 'react'
import {
  Dialog,
  IconButton,
  DialogContent,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  DialogActions,
  // FormHelperText,
} from '@material-ui/core'
import {
  DialogTitleStyled,
  DialogTitleTeksStyled,
  StyledForm,
  ButtonActionWrapper,
  ButtonActionModal,
  ListModal,
  QuestionText,
  TypeText,
  ListModalAction,
  TextAdded,
  ButtonAction,
  ButtonActionListModal,
} from '../../SurveyCenterStyled'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import {GET_LIST_QUESTION_FROM_BANK} from '../../../../../../graphql/queries/survey/multirater-nps/survey-center/getListSurveyCenter.query'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {ADD_GROUP_QUESTION_STEP_TWO} from '../../../../../../graphql/mutations/survey/multirater-nps/survey-center/surveyCenter.mutation'
import LoadingSurvey from '../../LoadingSurvey'
import {useSnackbar} from 'notistack'
import NoDataListFreeComponent from '../../../../../shared-components/NoDataListFreeComponent'

export default function DialogImportQuestion(props) {
  const {enqueueSnackbar} = useSnackbar()
  const {open, setOpenDialog, dataGroup, survey, setListData, number} = props
  // const [listQuestion, setListQuestion] = useState([])
  const [dataSend, setDataSend] = useState([])
  // const [isError, setIsError] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedGroup, setSelectedGroup] = useState('')

  const {data, loading} = useQuery(GET_LIST_QUESTION_FROM_BANK, {
    variables: {
      search: `%${search}%`,
      group: selectedGroup ? selectedGroup.id : null,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [importQuestion] = useMutation(ADD_GROUP_QUESTION_STEP_TWO)

  // useEffect(() => {
  //   if (!loading) {
  //     if (listQuestion.length === 0) {
  //       const arr = data.multirater_bank_questions.map(res => ({
  //         ...res,
  //         action: false,
  //         group: competencyGroup,
  //       }))
  //       setListQuestion(arr)
  //     }
  //   }
  // }, [data, listQuestion, competencyGroup, loading])

  // useEffect(() => {
  //   for (let i = 0; i < dataSend.length; i++) {
  //     dataSend[i].group = competencyGroup
  //   }
  // }, [competencyGroup, dataSend])

  const handleAddQuestion = (res, isAdd) => {
    // const questions = listQuestion
    // const question = questions.find(val => val.id === id)
    // question.action = !question.action
    // question.group = competencyGroup
    // setListQuestion([...questions])
    // const result = questions.filter(child => child.action === true)
    // setDataSend(result)
    const {number, question_self, question_others, id} = res
    const newData = {
      id,
      number,
      question_self,
      question_others,
    }
    if (isAdd) {
      setDataSend([...dataSend, newData])
    } else {
      setDataSend(dataSend.filter(child => child.id !== newData.id))
    }
  }

  const handleAddAll = cond => {
    // const questions = listQuestion
    // const insert = questions.map(function(el) {
    //   const o = Object.assign({}, el)
    //   o.action = cond
    //   o.group = competencyGroup
    //   return o
    // })
    // if (cond === true) {
    //   setDataSend(insert)
    //   setListQuestion([...insert])
    // } else {
    //   setDataSend([])
    //   setListQuestion([])
    // }
    if (cond) {
      const allData = data.multirater_bank_questions.map(res => {
        const {number, question_self, question_others, id} = res
        return {
          id,
          number,
          question_self,
          question_others,
        }
      })
      setDataSend(allData)
    } else {
      setDataSend([])
    }
  }

  // const elmnt = document.getElementById('top')

  const handleSubmit = () => {
    const errors = () => {
      // elmnt.scrollIntoView({behavior: 'smooth'})
      // setIsError(true)
      // setTimeout(() => {
      //   setIsError(false)
      // }, 5000)
      enqueueSnackbar(`Please choose a question and choose question group`, {
        variant: 'error',
        autoHideDuration: 3000,
      })
    }
    if (dataSend.length !== 0 && selectedGroup) {
      const keyList = ['id']
      for (let i = 0; i < dataSend.length; i++) {
        keyList.map(function(key) {
          return delete dataSend[i][key]
        })
      }

      importQuestion({
        variables: {
          objects: {
            survey,
            name: selectedGroup.name,
            isnps: false,
            number,
            multirater_survey_questions: {
              data: dataSend,
            },
          },
        },
      })
        .then(() => {
          setOpenDialog(false)
          // setListQuestion([])
          setSelectedGroup('')
          // setListData([])
          setListData()
          enqueueSnackbar('Question added', {
            variant: 'success',
            autoHideDuration: 3000,
          })
        })
        .catch(() => {
          // setListData([])
          setListData()
          // setListQuestion([])
          setDataSend([])
          errors()
        })
    } else {
      errors()
    }
  }

  const handleSearch = e => {
    // setListQuestion([])
    setSearch(e.target.value)
  }

  const handleClose = () => {
    setOpenDialog(false)
    setDataSend([])
    // setListQuestion([])
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitleStyled>
        <DialogTitleTeksStyled>Masukkan pertanyaan</DialogTitleTeksStyled>
        <IconButton onClick={handleClose} aria-label="delete">
          <CloseIcon style={{color: '#FFF'}} />
        </IconButton>
      </DialogTitleStyled>

      <DialogContent>
        <StyledForm fullWidth variant="outlined">
          {/* <InputLabel id="top">Question Group</InputLabel> */}
          <Select
            fullWidth
            variant="outlined"
            value={selectedGroup && selectedGroup.id}
            onChange={() => {
              setDataSend([])
              // handleChangeCopetency(e)
            }}
            displayEmpty
            style={{
              color: !selectedGroup ? '#808080' : 'unset',
            }}
          >
            <MenuItem disabled value="">
              Pilih Grup Pertanyaan
            </MenuItem>
            {dataGroup &&
              dataGroup.map(res => {
                return (
                  <MenuItem
                    key={res.id}
                    value={res.id}
                    onClick={() => setSelectedGroup(res)}
                  >
                    {res.name}
                  </MenuItem>
                )
              })}
          </Select>
          {/* {isError ? (
            <FormHelperText style={{color: '#EF4D5E'}}>
              You must select a Group
            </FormHelperText>
          ) : null} */}
        </StyledForm>

        <StyledForm fullWidth variant="outlined">
          <InputLabel htmlFor="search">Cari</InputLabel>
          <OutlinedInput
            id="search"
            value={search}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            labelWidth={60}
          />
        </StyledForm>
        <ButtonActionWrapper>
          <ButtonActionModal
            onClick={() => handleAddAll(false)}
            style={{color: '#014A62'}}
          >
            Hapus Semua
          </ButtonActionModal>
          <ButtonActionModal
            onClick={() => handleAddAll(true)}
            style={{color: '#014A62'}}
          >
            Tambah Semua
          </ButtonActionModal>
        </ButtonActionWrapper>
        {loading ? (
          <div
            style={{
              backgroundColor: '#fff',
              marginLeft: 10,
              marginBottom: 50,
              borderRadius: '5px',
              width: '100%',
            }}
          >
            <LoadingSurvey />
          </div>
        ) : !selectedGroup || data.multirater_bank_questions.length === 0 ? (
          <NoDataListFreeComponent
            message2={
              !selectedGroup &&
              'Anda belum memilih grup pertanyaan. Untuk dapat mengimpor pertanyaan, pilih grup pertanyaan terlebih dahulu'
            }
            message1={
              !selectedGroup
                ? 'Maaf, Tidak Ada di Daftar'
                : 'Tidak ada hasil yang ditemukan'
            }
            image={require('../../../../../../assets/images/nodatalist.png')}
          />
        ) : (
          data.multirater_bank_questions.map((res, i) => {
            return (
              <ListModal key={i}>
                <div>
                  <QuestionText>{res.question_self}</QuestionText>
                  <TypeText>Tipe: Skala peringkat</TypeText>
                </div>
                <ListModalAction>
                  {dataSend.find(val => val.id === res.id) ? (
                    <>
                      <TextAdded>Pertanyaan Ditambahkan</TextAdded>
                      <ButtonActionModal
                        onClick={() => handleAddQuestion(res, false)}
                        style={{color: '#EF4D5E'}}
                      >
                        Hapus
                      </ButtonActionModal>
                    </>
                  ) : (
                    <>
                      <ButtonActionListModal
                        onClick={() => handleAddQuestion(res, true)}
                        variant="contained"
                        color="secondary"
                      >
                        Tambahkan
                      </ButtonActionListModal>
                    </>
                  )}
                </ListModalAction>
              </ListModal>
            )
          })
        )}
      </DialogContent>

      <DialogActions>
        <ButtonAction onClick={handleClose}>Batal</ButtonAction>
        <ButtonAction
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          Tambah Pertanyaan
        </ButtonAction>
      </DialogActions>
    </Dialog>
  )
}
