import {
  // ALLOWED_ROLES,
  // isNotSuperAdmin,
  // hasRole,
  ENVIRONMENT,
  hasModule,
} from '../utils/globals'
import {generateRoutesFromConfigs} from '../utils/helpers'
import {LeavePageConfig} from '../pages/time/leave/LeavePageConfig'
import {OvertimePageConfig} from '../pages/time/overtime/OvertimePageConfig'
import {AttendancePageConfig} from '../pages/time/attendance/AttendancePageConfig'
import {CalenderPageConfig} from '../pages/time/calender/CalenderPageConfig'
import {KpiPageConfig} from '../pages/performance/kpi/KpiPageConfig'
import {DashboardConfig} from '../pages/dashboard/DashboardConfig'
import {GoalPageConfig} from '../pages/performance/goal/GoalPageConfig'
import {IndividualPageConfig} from '../pages/performance/individual/IndividualPageConfig'
import {IndividualSummaryPageConfig} from '../pages/performance/individual-summary/IndividualSummaryPageConfig'
import {ClassificationPageConfig} from '../pages/talent/classification/ClassificationPageConfig'
import {SuccessionPageConfig} from '../pages/talent/succession/SuccessionPageConfig'
import {MultiraterNpsPageConfig} from '../pages/survey/multirater-nps/MultiraterNpsPageConfig'
import {JobLevelPageConfig} from '../pages/organization/job-level/JobLevelPageConfig'
import {JobTitlePageConfig} from '../pages/organization/job-title/JobTitlePageConfig'
import {OrganizationLevelPageConfig} from '../pages/organization/organization-level/OrganizationLevelPageConfig'
import {OrganizationUnitPageConfig} from '../pages/organization/organization-unit/OrganizationUnitPageConfig'
import {JobFamilyPageConfig} from '../pages/organization/job-family/JobFamilyPageConfig'
import {HistoryPageConfig} from '../pages/organization/history/HistoryPageConfig'
import {PositionPageConfig} from '../pages/organization/position/PositionPageConfig'
import {JobFunctionPageConfig} from '../pages/organization/job-function/JobFunctionPageConfig'
import {JobGradePageConfig} from '../pages/organization/job-grade/JobGradePageConfig'
import {ProfileConfig} from '../pages/company/profile/ProfilePageConfig'
import {RegulationsConfig} from '../pages/company/regulations/RegulationsPageConfig'
import {OfficePageConfig} from '../pages/company/office/OfficePageConfig'
import {CompanyFilePageConfig} from '../pages/document/company-file/CompanyFilePageConfig'
import {EmployeePageConfig} from '../pages/directory/employee/EmployeePageConfig'
import {ConfiguringPageConfig} from '../pages/company-group/configuring/ConfiguringPageConfig'
import {ProfilePageConfig} from '../pages/company-group/profile/ProfilePageConfig'
import {ListPageConfig} from '../pages/company-member/list/ListPageConfig'
import {StructurePageConfig} from '../pages/company-member/structure/StructurePageConfig'
import {TalentHoldingListPageConfig} from '../pages/talent-holding/list/ListPageConfig'
import {HoldingClassPageConfig} from '../pages/talent-holding/classification/HoldingClassPageConfig'
import {PayrollPageConfig} from '../pages/financial/payroll/PayrollPageConfig'
import {CrossMentoringPageConfig} from '../pages/learning-holding/cross-mentoring/CrossMentoringPageConfig'
import {ArchivePageConfig} from '../pages/organization/archive/ArchivePageConfig'
import {PerfClassificationPageConfig} from '../pages/performance/classification/ClassificationPageConfig'
import {LearningReportPageConfig} from '../pages/learning-holding/learning-report/ReportPageConfig'
import {LearningProfilePageConfig} from '../pages/learning-holding/learning-profile/LearningProfileConfig'
import {DownloadReportComponentConfig} from '../pages/directory/download-report/DownloadReportPageConfig'
import {MicroLearningPageConfig} from '../pages/learning/micro-learning/MicroLearningPageConfig'
import {ClaimLimitPageConfig} from '../pages/budget/claim_limit/ClaimLimitPageConfig'
import {BusinessTripPageConfig} from '../pages/business/trip/BusinessTripPageConfig'
import {CoursePageConfig} from '../pages/learning/course/CoursePageConfig'
import {CompetencyPageConfig} from '../pages/learning/competency/CompetencyPageConfig'
import {LoanPageConfig} from '../pages/financial/loan/LoanPageConfig'
import {CompanySummaryPageConfig} from '../pages/performance-holding/company-summary/CompanySummaryPageConfig'
import {ClaimPageConfig} from '../pages/financial/claim/ClaimPageConfig'

const routeConfigs = [
  LeavePageConfig,
  OvertimePageConfig,
  AttendancePageConfig,
  CalenderPageConfig,
  KpiPageConfig,
  DashboardConfig,
  GoalPageConfig,
  IndividualPageConfig,
  IndividualSummaryPageConfig,
  ClassificationPageConfig,
  SuccessionPageConfig,
  MultiraterNpsPageConfig,
  OrganizationLevelPageConfig,
  OrganizationUnitPageConfig,
  JobLevelPageConfig,
  JobFunctionPageConfig,
  JobGradePageConfig,
  JobFamilyPageConfig,
  JobTitlePageConfig,
  HistoryPageConfig,
  PositionPageConfig,
  OfficePageConfig,
  ProfileConfig,
  RegulationsConfig,
  CompanyFilePageConfig,
  EmployeePageConfig,
  ConfiguringPageConfig,
  ProfilePageConfig,
  ListPageConfig,
  StructurePageConfig,
  TalentHoldingListPageConfig,
  HoldingClassPageConfig,
  PayrollPageConfig,
  CrossMentoringPageConfig,
  ArchivePageConfig,
  PerfClassificationPageConfig,
  LearningReportPageConfig,
  LearningProfilePageConfig,
  DownloadReportComponentConfig,
  MicroLearningPageConfig,
  ClaimLimitPageConfig,
  BusinessTripPageConfig,
  CoursePageConfig,
  CompetencyPageConfig,
  LoanPageConfig,
  CompanySummaryPageConfig,
  ClaimPageConfig,
]

const routes = (allModule, companyModule) => {
  let _routes = generateRoutesFromConfigs(routeConfigs)

  const filterRoute = val => _routes.filter(i => !i.path.includes('/' + val))

  if (ENVIRONMENT !== 'development') {
    // comment hide menu by roles
    //  if (isNotSuperAdmin) {
    //   switch (true) {
    //     case !hasRole(ALLOWED_ROLES[3]):
    //       _routes = filterRoute('time')
    //       break
    //     case !hasRole(ALLOWED_ROLES[4]):
    //       _routes = filterRoute('financial')
    //       break
    //     case !hasRole(ALLOWED_ROLES[5]):
    //       _routes = filterRoute('directory')
    //       break
    //     case !hasRole(ALLOWED_ROLES[6]):
    //       _routes = filterRoute('company')
    //       break
    //     case !hasRole(ALLOWED_ROLES[7]):
    //       _routes = filterRoute('organization')
    //       break
    //     case !hasRole(ALLOWED_ROLES[8]):
    //       _routes = filterRoute('learning')
    //       break
    //     case !hasRole(ALLOWED_ROLES[9]):
    //       _routes = filterRoute('performance')
    //       break
    //     case !hasRole(ALLOWED_ROLES[10]):
    //       _routes = filterRoute('talent')
    //       break
    //     case !hasRole(ALLOWED_ROLES[11]):
    //       _routes = filterRoute('survey')
    //       break
    //     case !hasRole(ALLOWED_ROLES[12]):
    //       _routes = filterRoute('document')
    //       break
    //     // support merupakan external url
    //     // case !hasRole(ALLOWED_ROLES[13]):
    //     //   _routes = filterRoute('/support')
    //   }
    //  }
    // hide routes by selected module
    for (let i = 0; i < allModule?.global_modules?.length; i++) {
      const name = allModule?.global_modules?.[i]?.name
      if (
        name === 'directory' ||
        name === 'company' ||
        name === 'organization' ||
        name === 'document' ||
        name === 'dashboard'
      ) {
        continue
      } else if (!hasModule(companyModule, 'holding')) {
        _routes = filterRoute('group')
        _routes = filterRoute('member')
        _routes = filterRoute('holding-learning')
        _routes = filterRoute('holding-talent')
      } else if (!hasModule(companyModule, name)) {
        _routes = filterRoute(name)
      }
    }
  }

  return _routes
}

export default routes
