import {FormControlLabel, makeStyles, Switch} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
    marginBottom: 17,
  },
  root: {
    marginBottom: 32,
  },
  labelSwitch: {
    color: '#a9a8a8',
    fontSize: 12,
  },
  noLabelSwitch: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
  },
}))

export const SwitchForm = ({
  label,
  text,
  value,
  onChange,
  labelPlacement = 'end',
}) => {
  const classes = useStyles()

  return (
    <div className={label && classes.root}>
      {label && <p className={classes.label}>{label}</p>}
      <FormControlLabel
        classes={{
          label: label ? classes.labelSwitch : classes.noLabelSwitch,
        }}
        labelPlacement={labelPlacement}
        control={
          <Switch
            checked={value}
            onChange={onChange}
            name="checkedB"
            color="primary"
            inputProps={{'aria-label': 'controlled'}}
          />
        }
        label={text}
      />
    </div>
  )
}
