import React from 'react'
import styled from 'styled-components'

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  Card,
  Typography,
  Box,
  Collapse,
} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import {ActionableLink} from '../ClaimPageStyles'

const MAX_LENGTH_TEXT = 500

export const ClaimPolicyList = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing(3)}px;
  padding: ${props => props.theme.spacing(3)}px;
`

const ClaimPanel = styled(ExpansionPanel)`
  background-color: #f7f8f9;
  border-radius: 4px;

  &::before {
    display: none;
  }

  &.Mui-expanded {
    margin: 0;
  }
`

const ClaimPanelSummary = styled(ExpansionPanelSummary)`
  height: ${props => props.theme.spacing(7)}px;
  min-height: unset !important;

  .MuiExpansionPanelSummary-content {
    align-items: center;
    justify-content: space-between;
  }
`

const ClaimPanelDetails = styled(ExpansionPanelDetails)`
  display: block;
`

export const TableBox = styled.ul`
  --columns: 2;

  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  grid-gap: 8px;
  padding: 0;
  margin: 0;

  li {
    margin-left: 18px;

    &::marker {
      color: ${props => props.theme.palette.secondary.main};
    }
  }

  @media (min-width: 1280px) {
    --columns: 3;
  }

  @media (min-width: 1600px) {
    --columns: 4;
  }
`

export function ClaimPolicyItem(props) {
  const {data, onMenuClick, onMoreClick} = props
  const [descriptionOpen, setDescriptionOpen] = React.useState(false)

  const handleMenuClick = ev => {
    ev.stopPropagation()

    if (onMenuClick) {
      onMenuClick(ev, data)
    }
  }

  const handleMoreClick = ev => {
    if (onMoreClick) {
      onMoreClick(ev, data)
    }
  }

  return (
    <ClaimPanel>
      <ClaimPanelSummary>
        <Typography>{data.name}</Typography>

        <IconButton onClick={handleMenuClick} edge="end">
          <MoreHorizIcon />
        </IconButton>
      </ClaimPanelSummary>
      <ClaimPanelDetails>
        <Card elevation={0}>
          <Box display="grid" gridGap={16} p={2}>
            <Typography style={{fontWeight: 600}}>Policy Details</Typography>
            {/* Claim approval */}
            {/* <Box display="flex">
              <Typography color="textSecondary">Claim Approval</Typography>
              <Box ml={3}>
                <Typography>
                  {data.approver_1?.placements[0]?.user.name || '-'}
                </Typography>
                {data.approver_2 && (
                  <Typography>
                    {data.approver_2.placements[0]?.user.name}
                  </Typography>
                )}
              </Box>
            </Box> */}
            <Box display="flex">
              <Typography color="textSecondary" style={{whiteSpace: 'nowrap'}}>
                Claim Description
              </Typography>
              <Box ml={3}>
                <Collapse
                  in={descriptionOpen}
                  collapsedSize={
                    data.description.length > MAX_LENGTH_TEXT ? 45 : 21
                  }
                >
                  <Typography>{data.description || '-'}</Typography>
                </Collapse>
                {data.description.length > MAX_LENGTH_TEXT && (
                  <ActionableLink
                    onClick={() => setDescriptionOpen(prev => !prev)}
                  >
                    {descriptionOpen ? 'See Less' : 'See More'}
                  </ActionableLink>
                )}
              </Box>
            </Box>
            <Box display="grid" gridGap={8}>
              <Typography style={{fontWeight: 600}}>Applies to:</Typography>
              <TableBox>
                {data.applies_to.map(
                  (item, idx) => idx < 8 && <li>{item.profile.title}</li>
                )}
              </TableBox>
              {data.applies_to.length > 8 && (
                <ActionableLink onClick={handleMoreClick}>
                  See More
                </ActionableLink>
              )}
            </Box>
          </Box>
        </Card>
      </ClaimPanelDetails>
    </ClaimPanel>
  )
}
