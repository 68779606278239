import React from 'react'
import {WrapIconItem} from '../../DetailEmployeeStyles'
import {GET_LIST_ADDRESS} from '../../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import LoadingEmployee from '../../../LoadingEmployee'

export default function Address({userId}) {
  const {data, loading} = useQuery(GET_LIST_ADDRESS, {
    wlb_skipPatch: true,
    fetchPolicy: 'network-only',
    variables: {
      userId,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  if (loading) {
    return <LoadingEmployee noborder smallpadding />
  }

  return (
    <div>
      {data &&
        data.people_addresses &&
        data.people_addresses.map(res => (
          <WrapIconItem key={`${res.id}-itm`}>
            <p className="black title mb-10">{res.address || '-'}</p>
            <p className="grey mb-10">
              {res.address_fields &&
                `${res.address_fields.owner_status || '-'}   -   ${res
                  .address_fields.living_status || '-'}`}
            </p>
            <p className="grey mb-10">
              {res.city && res.city.name + ', ' + res.province.name}
            </p>
            <p className="grey mb-10">{res.postal_code || '-'}</p>
            <p className="grey mb-10">{res.address_type || '-'}</p>
            {res.is_main && (
              <div className="is-main">
                <p>Main Address</p>
              </div>
            )}
          </WrapIconItem>
        ))}
    </div>
  )
}
