import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import {TextField, Typography} from '@material-ui/core'

export const BlueLink = styled(Link)`
  color: #007fb2;
  text-decoration: none;
  margin: 18px;
`

export const RedButton = styled(Button)`
  color: #f44336;
  text-decoration: none;
`

export const TextFieldStyled = styled(TextField)`
  width: 280px;
  height: 45px;
  margin-bottom: 30px;
`

export const HeaderTable = styled.div`
  display: flex;
  flex-direction: row;
`
export const FieldNumber = styled(Typography)`
  font-size: 14px;
`
export const FieldName = styled(Typography)`
  width: 500px;
`

export const FieldActions = styled.div`
  margin-left: auto;
`

export const TableWrapper = styled.div`
  width: auto;
  margin: auto;
`
export const TableHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px 0px;
  font-size: 16px;
  font-weight: 500;
`

export const TableBodyStyled = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 500;
`

export const NomorRow = styled.div`
  width: 70px;
  text-align: center;
  margin: auto 0px;
  font-size: inherit;
  font-weight: inherit;
`
export const TitleRow = styled(Typography)`
  width: 120px;
  text-align: left;
  margin: auto 0px;
  word-wrap: break-word;
  font-size: inherit;
  font-weight: inherit;
`

export const TypeRow = styled(Typography)`
  width: 130px;
  text-align: left;
  margin: auto 0px;
  word-wrap: break-word;
  font-size: inherit;
  font-weight: inherit;
`
export const DateRow = styled(Typography)`
  width: 130px;
  text-align: left;
  margin: auto 0px;
  word-wrap: break-word;
  line-height: 21px;
  font-size: inherit;
  font-weight: inherit;
`

export const RespondentsRow = styled(Typography)`
  width: 120px;
  text-align: left;
  margin: auto 0px;
  word-wrap: break-word;
  font-size: inherit;
  font-weight: inherit;
`

export const StatusRow = styled(Typography)`
  width: 80px;
  text-align: left;
  margin: auto 0px;
  word-wrap: break-word;
  font-size: inherit;
  font-weight: inherit;
`

export const ActionRow = styled(Typography)`
  width: 130px;
  text-align: left;
  margin: auto 0px;
  word-wrap: break-word;
  font-size: inherit;
  font-weight: inherit;
`
