import React, {useState} from 'react'
import {Typography, Divider, Paper} from '@material-ui/core'
import {TrendingUp, DataUsage, AssignmentTurnedIn} from '@material-ui/icons'
import {GET_DATA_GOAL_SUMMARY} from '../../../../graphql/queries'
import {EnhancedToolbar} from '../../../../GlobalStyles'
import {SectionDetailContainer} from '../IndividualSummaryPageStyle'
import Loading from '../../../../components/loading/LoadingComponent'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import {useQuery} from '@apollo/react-hooks'
import {Doughnut} from 'react-chartjs-2'
import {
  DoughnutContainer,
  AggregateContainer,
} from '../../../../components/widgets/doughnut/DoughnutChartStyles'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'

const GoalDetailComponent = ({idUser}) => {
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  })

  const {data, error} = useQuery(GET_DATA_GOAL_SUMMARY, {
    wlb_skipPatch: true,
    variables: {
      user: idUser,
      startdate: filterDate?.startDate ?? '',
      enddate: filterDate?.endDate ?? '',
    },
  })

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{JSON.stringify(error)}</div>
  }

  const dataGoalSummary = data?.getPerformanceIndividualSummaryGoal
  const dataGoalChart = {
    personal: {
      labels: ['Sudah Terlaksana', 'Harus Dikerjakan'],
      datasets: [
        {
          data: [
            dataGoalSummary?.total_complete_personal_goal ?? 0,
            dataGoalSummary?.total_todo_personal_goal ?? 0,
          ],
          backgroundColor: ['#1f78b4', '#a6cee3'],
        },
      ],
    },
    company: {
      labels: ['Sudah Terlaksana', 'Harus Dikerjakan'],
      datasets: [
        {
          data: [
            dataGoalSummary?.total_complete_company_goal ?? 0,
            dataGoalSummary?.total_todo_company_goal ?? 0,
          ],
          backgroundColor: ['#1f78b4', '#a6cee3'],
        },
      ],
    },
  }

  const renderLegendPersonal = feature => {
    const newArr = []
    for (let i = 0; i < dataGoalChart[feature].labels.length; i++) {
      newArr.push({
        color: dataGoalChart[feature].datasets[0].backgroundColor[i],
        data: dataGoalChart[feature].datasets[0].data[i],
        labels: dataGoalChart[feature].labels[i],
      })
    }
    return newArr.map((resGoal, i) => (
      <div className="legend-container" key={i}>
        <div
          style={{
            border: `solid 3px ${resGoal.color}`,
          }}
          className="circle"
        />
        <div>
          <p className="p-legend-status">{resGoal.labels}</p>
          <p className="p-legend-value-goal">{resGoal.data}</p>
        </div>
      </div>
    ))
  }

  const options = {
    cutoutPercentage: 80,
    rotation: 4.7,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: 'left',
      labels: {
        boxWidth: 15,
        fontSize: 12,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  }

  return (
    <SectionDetailContainer>
      <Paper className="paper-padding">
        <EnhancedToolbar>
          <Typography variant="h6" color="primary">
            {GLOBAL_GLOSSARY.performance.Goal}
          </Typography>
          <div className="section-filter">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                format="MMM dd, yyyy"
                id="start-date"
                placeholder="Tanggal Mulai"
                InputProps={{readOnly: true}}
                value={filterDate.startDate && new Date(filterDate.startDate)}
                onChange={date =>
                  setFilterDate({
                    ...filterDate,
                    startDate: moment(date).format('YYYY-MM-DD'),
                  })
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  size: 'small',
                }}
              />
              <KeyboardDatePicker
                inputVariant="outlined"
                format="MMM dd, yyyy"
                id="end-date"
                placeholder="Tanggal Selesai"
                InputProps={{readOnly: true}}
                minDate={filterDate.startDate}
                value={filterDate.endDate && new Date(filterDate.endDate)}
                onChange={date =>
                  setFilterDate({
                    ...filterDate,
                    endDate: moment(date).format('YYYY-MM-DD'),
                  })
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  size: 'small',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </EnhancedToolbar>
        <Divider className="divider-margin" />
        <div className="info-container">
          <div className="info-section">
            <div className="icon-container">
              <TrendingUp className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${dataGoalSummary?.avg_score_all_goal ??
                0}%`}</p>
              <p className="p-text">
                Nilai Rata-Rata {GLOBAL_GLOSSARY.performance.Goal}
              </p>
            </div>
          </div>
          <div className="info-section">
            <div className="icon-container">
              <DataUsage className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${dataGoalSummary?.total_all_goal ??
                0}`}</p>
              <p className="p-text">Total {GLOBAL_GLOSSARY.performance.Goal}</p>
            </div>
          </div>
          <div className="info-section">
            <div className="icon-container">
              <AssignmentTurnedIn className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${dataGoalSummary?.total_complete_goal ??
                0}`}</p>
              <p className="p-text">
                Total {GLOBAL_GLOSSARY.performance.Goal} Sudah Terlaksana
              </p>
            </div>
          </div>
        </div>
        <div className="chart-container">
          <div className="wrap-chart">
            <p className="p-chart-title">
              {GLOBAL_GLOSSARY.performance.Goal} Pribadi
            </p>
            <div className="chart-section">
              <div className="chart-goal">
                <DoughnutContainer className="inner-chart">
                  <Doughnut data={dataGoalChart.personal} options={options} />
                  <AggregateContainer>
                    <span>{`${dataGoalSummary?.total_all_personal_goal ??
                      0}`}</span>
                    <span>{GLOBAL_GLOSSARY.performance.Goal}</span>
                  </AggregateContainer>
                </DoughnutContainer>
              </div>
              <div className="info-chart">
                {renderLegendPersonal('personal')}
              </div>
            </div>
            <div className="average-container">
              <span className="span-average">
                Nilai Rata-Rata {GLOBAL_GLOSSARY.performance.Goal}:
              </span>
              <span className="span-average-score">{`${dataGoalSummary?.avg_score_personal_goal ??
                0}%`}</span>
            </div>
          </div>
          <Divider orientation="vertical" className="divider-chart" />
          <div className="wrap-chart margin-chart">
            <p className="p-chart-title">
              {GLOBAL_GLOSSARY.performance.Goal} Instansi
            </p>
            <div className="chart-section">
              <div className="chart-goal">
                <DoughnutContainer className="inner-chart">
                  <Doughnut data={dataGoalChart.company} options={options} />
                  <AggregateContainer>
                    <span>{`${dataGoalSummary?.total_all_company_goal ??
                      0}`}</span>
                    <span>{GLOBAL_GLOSSARY.performance.Goal}</span>
                  </AggregateContainer>
                </DoughnutContainer>
              </div>
              <div className="info-chart">
                {renderLegendPersonal('company')}
              </div>
            </div>
            <div className="average-container">
              <span className="span-average">
                Nilai Rata-Rata {GLOBAL_GLOSSARY.performance.Goal}:
              </span>
              <span className="span-average-score">{`${dataGoalSummary?.avg_score_company_goal ??
                0}%`}</span>
            </div>
          </div>
        </div>
      </Paper>
    </SectionDetailContainer>
  )
}

export default GoalDetailComponent
