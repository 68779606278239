import {Avatar, Divider, Icon, IconButton, Typography} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState} from 'react'
import {BoldTypography} from '../../../components/typography/TypographyStyles'
import {PaperContainer, SkyBlueIcon} from '../../../GlobalStyles'
import {
  NewTableOptions,
  newTableStyles,
} from '../../shared-components/table/TableOptions'
import {GET_HOLDING_MEMBER_PENDING} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'
import Loading from '../../../components/loading/LoadingComponent'
import {useMutation, useQuery} from '@apollo/react-hooks'
import ModalInviteCompany from './add-company/ModalInviteCompany'
import ToolbarMember from './ToolbarMember'
import AddEdit from '../../shared-components/popup/AddEditPopup'
import {useSnackbar} from 'notistack'
import {RESEND_MEMBER_INVITE} from '../../../graphql/mutations'
import {holdingRolesContext} from '../../../utils/helpers'
import {Link} from 'react-router-dom'

export default function PendingActivationComponent(props) {
  const tableClasses = newTableStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [open, setOpen] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState('')
  const [openInvite, setOpenInvite] = useState(false)

  const [mutationResendInvitation] = useMutation(RESEND_MEMBER_INVITE)
  const {data, error, refetch} = useQuery(GET_HOLDING_MEMBER_PENDING, {
    variables: {
      company: COMPANY_ID,
      search: `%${props.searchText}%`,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'holding-administrator',
      },
    },
    fetchPolicy: 'cache-and-network',
  })
  const handleOpenInvite = () => {
    setOpenInvite(prev => !prev)
  }
  const handleOpen = (ids, type, names) => {
    setOpen(true)
    setId(ids)
    setName(names)
    setConfirmType(type)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleMutation = () => {
    mutationResendInvitation({
      variables: {
        id,
        changes: {
          status: 'RESEND',
        },
      },
      ...holdingRolesContext,
    })
      .then(() => {
        enqueueSnackbar('Success resend invitation', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(
          'Update data error, please try again' + JSON.stringify(e),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
    // if (confirmType === 'add') {

    // } else {

    // }
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <ToolbarMember
        title="Pending Activation"
        handleOpenInvite={handleOpenInvite}
      />
      <Divider />
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontSize: 12}}
          options={{...NewTableOptions, paging: true, sorting: true}}
          columns={[
            {
              title: 'Company',
              field: 'company_member_profile.brand_name',
              cellStyle: {
                wordBreak: 'break-word',
                minWidth: 150,
              },
              render: row => (
                <div style={{display: 'flex'}}>
                  <Avatar
                    variant="rounded"
                    src={row.company_member_profile.logo}
                    style={{marginRight: 16}}
                  ></Avatar>
                  <Link
                    to={{
                      pathname: `/member/list/${row.company_member_profile.id}`,
                      state: {active: 'detail-company'},
                    }}
                    style={{color: '#000', textDecoration: 'none'}}
                  >
                    <BoldTypography variant="body2" gutterBottom>
                      {row.company_member_profile.brand_name}
                    </BoldTypography>
                    <Typography variant="caption">
                      {row.company_member_profile.legal_name}
                    </Typography>
                  </Link>
                </div>
              ),
            },
            {
              title: 'Phone',
              field: 'company_member_profile.phone',
            },
            {
              title: 'Email',
              field: 'company_member_profile.email',
              cellStyle: {color: '#039be5', fontWeight: 'normal'},
            },
            {
              title: '',
              field: 'action',
              filtering: false,
              render: row => (
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <IconButton
                    onClick={() =>
                      handleOpen(
                        row.id_relations,
                        'resend',
                        row.company_member_profile.brand_name
                      )
                    }
                  >
                    <SkyBlueIcon>replay</SkyBlueIcon>
                  </IconButton>
                  <IconButton>
                    <Icon color="secondary">delete</Icon>
                  </IconButton>
                </div>
              ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={data.holding_relations}
        />
      </div>
      <ModalInviteCompany
        open={openInvite}
        handleClose={handleOpenInvite}
        refetchMain={refetch}
      />
      <AddEdit
        open={open}
        handleClose={handleClose}
        type={confirmType}
        name={`Invitation email to ${name}`}
        feature=""
        mutation={handleMutation}
      />
    </PaperContainer>
  )
}
