import React from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
} from '@material-ui/core'
import {WhiteModalHeader} from '../../../GlobalStyles'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},

  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnPopup: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {
    wordBreak: 'break-all',
    color: '#014a62',
  },
  btnCancel: {
    color: '#a9a8a8',
    backgroundColor: '#ffffff',
    boxShadow: 'initial',
  },
  word: {wordBreak: 'break-all'},
  nameStyle: {
    color: '#014a62',
    fontWeight: '600',
  },
  errorTitleColor: {
    color: '#ef4d5e',
  },
}))

export default function Result(props) {
  const classes = useStyles()
  const {
    open,
    handleClose,
    feature,
    message,
    type,
    status,
    mutation,
    name,
    fMessage,
    lMessage,
    cancelAction,
    reverseCancel,
    positiveTitleButton,
  } = props

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <WhiteModalHeader>
        <Typography
          variant="h6"
          color={type !== 'Confirm' ? 'error' : 'primary'}
          className={
            type === 'warning'
              ? classes.errorTitleColor
              : type !== 'Confirm'
              ? classes.wordBreak
              : ''
          }
        >
          {feature}
        </Typography>
        {/* <IconButton aria-label="close" onClick={handleClose}>
          <Icon>close</Icon>
        </IconButton> */}
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        {name ? (
          <>
            <Typography variant="body2" display="inline">
              {fMessage}
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              display="inline"
              className={classes.nameStyle}
            >
              {name}
            </Typography>
            <Typography variant="body2" display="inline">
              {lMessage}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body2" display="inline">
              {message}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {status === 'cancel' ? (
          <Button
            className={classes.btnCancel}
            variant="contained"
            onClick={() => {
              if (cancelAction) {
                handleClose('cancel')
                cancelAction(reverseCancel && reverseCancel)
              } else {
                handleClose('cancel')
              }
            }}
          >
            Batal
          </Button>
        ) : status === 'notNow' ? (
          <Button
            className={classes.btnCancel}
            variant="contained"
            onClick={() => {
              handleClose('notNow')
            }}
          >
            Tidak sekarang
          </Button>
        ) : (
          ''
        )}
        <Button
          className={classes.btnPopup}
          variant="contained"
          onClick={() => {
            if (mutation) {
              mutation()
            } else if (cancelAction) {
              cancelAction(reverseCancel && reverseCancel)
              handleClose()
            } else {
              handleClose()
            }
          }}
        >
          {positiveTitleButton || type}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
