import React from 'react'
import {Link} from 'react-router-dom'

import MaterialTable from 'material-table'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'

import {MoreIcon, StyledMenuItem, StyledMenus} from '../../../../GlobalStyles'
import {
  TableOptions,
  TableContainer,
  CATEGORY_COLOR,
} from '../ClaimLimitPageStyles'
import {convertToRupiah, dateFormat} from '../../../../utils/helpers'

function EmployeeBalanceTransactionList(props) {
  const {
    employeeID,
    data,
    totalCount,
    loading,
    summary,
    offset,
    limit,
    onChangeOffset,
    onChangeLimit,
  } = props

  const renderActions = field => (
    <PopupState variant="popover">
      {state => (
        <>
          <MoreIcon {...bindTrigger(state)}>more_horiz</MoreIcon>

          <StyledMenus {...bindMenu(state)}>
            <StyledMenuItem
              component={Link}
              to={{
                pathname: `/budget/limit/employee/${employeeID}/${field.id}`,
                state: {active: 'employee-transaction'},
              }}
            >
              Details
            </StyledMenuItem>
          </StyledMenus>
        </>
      )}
    </PopupState>
  )

  const TABLE_COLUMNS_CONFIG = [
    {title: 'Transaction ID', field: 'formatted_id'},
    {title: 'Nominal', render: renderAmount},
    {title: 'Transaction Date', render: renderDate},
    {title: 'Category', render: renderCategory},
    {title: 'Type', field: 'type'},
    {title: 'Actions', render: renderActions, sorting: false},
  ]

  const options = summary
    ? {...TableOptions, paginationPosition: false}
    : TableOptions

  return (
    <MaterialTable
      isLoading={loading}
      data={data}
      totalCount={totalCount}
      page={offset}
      pageSize={limit}
      onChangePage={onChangeOffset}
      onChangeRowsPerPage={onChangeLimit}
      columns={TABLE_COLUMNS_CONFIG}
      options={options}
      components={{Container: TableContainer}}
    />
  )
}

export default EmployeeBalanceTransactionList

function renderCategory(data) {
  const category = data.nominal >= 0 ? 'In' : 'Out'
  return <p style={{color: CATEGORY_COLOR[category]}}>{category}</p>
}

function renderDate(data) {
  return dateFormat(data.date, 'MMM DD, YYYY')
}

function renderAmount(data) {
  return convertToRupiah(Math.abs(data.nominal))
}
