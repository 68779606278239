import React from 'react'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import {DialogContent, IconButton, Divider, Button} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import {AttachmentItemHover} from '../../../../shared-components/attachment'
import {
  FlexBetween,
  ModalHeader,
  StyledDialog,
  TextBold,
  FlexColumnStart,
  FlexCenter,
} from '../../../component/modalStyles'

import Destination from './Destination'
import TotalBudget from './TotalBudget'
import FormDetail from '../../../component/FormDetail'
import LoadingSurvey from '../../../../survey/multirater-nps/survei-center/LoadingSurvey'
import ConfirmModal from '../../../../shared-components/popup/AddEditPopupFreeText'

import {dateFormat} from '../../../../../utils/helpers'
import {getTripReportDate, handleTripDetailSort} from '../../businessTripHelper'
import {GET_REPORT_TRIP_BY_ID} from '../../../../../graphql/queries'
import {TRIP_REPORT_UPDATE_VERIFY} from '../../../../../graphql/mutations'

export default function ModalDetail(props) {
  const {open, id, handleClose} = props

  const [openConfirm, setOpenConfirm] = React.useState(false)

  const {enqueueSnackbar} = useSnackbar()
  const [updateVerify] = useMutation(TRIP_REPORT_UPDATE_VERIFY)

  const {data, error} = useQuery(GET_REPORT_TRIP_BY_ID, {
    wlb_skipPatch: true,
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id,
    },
    onCompleted: handleTripDetailSort,
  })

  const details = data?.people_work_business_trips_by_pk
  const isVerified = details?.report_status === 'verify'

  const handleVerifyChange = () => {
    const promise = updateVerify({
      variables: {
        id: id,
        status: isVerified ? 'unverify' : 'verify',
      },
    })

    promise.then(
      () => {
        const action = isVerified ? 'unverified' : 'verified'
        const msg = `Trip report ${action}`
        enqueueSnackbar(msg, {variant: 'success'})
      },
      () => {
        const action = isVerified ? 'unverify' : 'verify'
        const msg = `Failed to ${action} trip report`
        enqueueSnackbar(msg, {variant: 'error'})
      }
    )
  }

  return (
    <StyledDialog
      onClose={handleClose}
      open={open}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <ModalHeader>
        <FlexBetween>
          <TextBold style={{marginTop: '-1px'}}>
            Business Trip Completion Detail
          </TextBold>

          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon style={{color: 'white', fontSize: '37px'}} />
          </IconButton>
        </FlexBetween>
      </ModalHeader>

      {error ? (
        <div>Something went wrong while retrieving trip report details.</div>
      ) : !data || data.people_work_business_trips_by_pk.id !== id ? (
        <LoadingSurvey />
      ) : (
        <DialogContent style={{padding: '1.6rem 2rem'}}>
          <FlexColumnStart>
            <FormDetail label="Request ID">{details.id_display}</FormDetail>
            <FormDetail label="Name">
              {details.people_work_placement.global_user.name}
            </FormDetail>
            <FormDetail label="Position">
              {details.people_work_placement.company_job_profile.title}
            </FormDetail>
            <FormDetail label="Submission Date">
              {dateFormat(details.date_added, 'MMMM D, YYYY')}
            </FormDetail>

            <Divider
              style={{
                backgroundColor: '#eff2f4',
                width: '100%',
                margin: '20px 0px',
                height: '5px',
              }}
            />

            <FormDetail label="Trip Detail" color="#014a62" />
            <FormDetail label="Trip Information" color="#000000" />
            <FormDetail label="Participants" display="block">
              <div style={{color: '#039be5'}}>
                {details.people_work_placement?.global_user?.name}
              </div>
              <p>{details.people_work_placement?.regno}</p>
              <p style={{color: '#a9a8a8'}}>
                {details.people_work_placement?.company_job_profile.title}
              </p>
            </FormDetail>
            <FormDetail label="Description">{details.description}</FormDetail>
            <FormDetail label="Attachment">
              {details.attachment.length > 0
                ? details.attachment.map((row, key) => (
                    <AttachmentItemHover
                      key={key}
                      iconAction={
                        <VisibilityOutlinedIcon
                          onClick={() => window.open(`${row.url}`)}
                          className="icon"
                        />
                      }
                      fileName={row.name}
                      url={`${row.url}`}
                    />
                  ))
                : '-'}
            </FormDetail>
            <FormDetail label="Destination" color="#000000" />
            <FormDetail label="Date">
              {getTripReportDate(details.date_start, details.date_end)}
            </FormDetail>
            <FormDetail label="Destination">
              <FlexColumnStart>
                {getTripDestinationSummary(details.destination)}
              </FlexColumnStart>
            </FormDetail>

            <Divider
              style={{
                backgroundColor: '#eff2f4',
                width: '100%',
                margin: '20px 0px',
                height: '5px',
              }}
            />

            <FormDetail label="Budget" color="#014a62" />
            <FormDetail label="Budget Usage" color="#000000" />

            {details.destination.map((row, index) => (
              <Destination
                key={index}
                data={row}
                currencyFrom={details.business_trip_policy.currency_from}
                currencyTo={details.currency}
                approvedDate={details.approved_date}
                count={details.destination.length}
              />
            ))}

            {details.destination.length > 0 && (
              <TotalBudget
                data={details.destination}
                diff_action={details.budget_diff_action}
                currencyFrom={details.business_trip_policy.currency_from}
                currencyTo={details.currency}
                approvedDate={details.approved_date}
              />
            )}

            <FlexCenter
              style={{paddingTop: 10, width: '100%', justifyContent: 'center'}}
            >
              <FlexBetween style={{width: '40%'}}>
                <Button
                  variant="outlined"
                  style={{borderColor: '#014a62', color: '#014a62'}}
                >
                  Download Trip Report
                </Button>
                <Button
                  variant="contained"
                  style={{backgroundColor: '#014a62', color: '#fff'}}
                  onClick={() => setOpenConfirm(true)}
                >
                  {isVerified ? 'Unverify' : 'Verify'} Trip Report
                </Button>
              </FlexBetween>
            </FlexCenter>
          </FlexColumnStart>

          <ConfirmModal
            open={openConfirm}
            feature=""
            header={`${isVerified ? 'Unverify' : 'Verify'} Trip Report Data?`}
            body={
              isVerified
                ? 'Are you sure to unverify this trip report data?'
                : 'Are you sure to verify this trip report data? Employee who submit this trip report will not be able to change data once the data is verified'
            }
            button="Confirm"
            handleClose={() => setOpenConfirm(false)}
            mutation={handleVerifyChange}
          />
        </DialogContent>
      )}
    </StyledDialog>
  )
}

function getTripDestinationSummary(destinations) {
  if (destinations.length < 1) {
    return '-'
  }

  const mapped = destinations.map(
    r => `${r.globalDestinationByFrom.name} - ${r.globalDestinationByTo.name}`
  )

  return mapped.join(', ')
}
