import styled from 'styled-components'

export const SearchContainer = styled.div`
  width: 100%;
`
export const ResetButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 13px;
  margin-bottom: 13px;

  strong {
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    cursor: pointer;
  }

  & > div:nth-child(2) {
    font-family: Muli;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: right;
    color: #a9a8a8;
    cursor: pointer;
  }
`

export const LabelMenuWrapper = styled.div`
  width: 270px;
  min-height: 288px;
  margin-left: ${props => (props.left ? '-298px' : '260px')};
  padding: 14px 3.7px 29px 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f7f8f9;
  display: ${props => (props.isShow ? 'block' : 'none')};
  position: fixed;
  margin-top: -55px;
  padding: 14px 16px;
`

export const CheckBoxWrapper = styled.div`
  max-height: 300px;
  overflow: scroll;
`

export const CenterWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`
