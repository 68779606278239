import gql from 'graphql-tag'

export const UPDATE_HOLDING_TALENT_SOURCE = gql`
  mutation update_holding_source($id: Int, $changes: holding_source_set_input) {
    update_holding_source(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
      returning {
        id
        status
      }
    }
  }
`
