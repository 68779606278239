import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'
import {Alert, AlertTitle} from '@material-ui/lab'
import {Divider} from '@material-ui/core'

import BankInputGroup from './components/BankInputGroup'
import BasicSettingForm from './components/BasicSettingForm'
import FormAction from '../../../../../components/form/FormActionComponent'
import ModalConfirmation from '../../payroll-settings/ModalConfirmation'
import RequestWrapper from '../../../../shared-components/layouts/RequestWrapper'
import UserSalaryHistoryLog from './components/UserSalaryHistoryLog'

import {
  GET_BANK_PEOPLE_WORK_SALARY,
  GET_TOTAL_USED_KTP_NPWP,
} from '../../../../../graphql/queries'
import {
  ADD_BASIC_SETTING,
  UPDATE_BASIC_SETTING,
  UPDATE_PREV_PERIOD_BASIC_SETTING,
  UPDATE_NEXT_PERIOD_BASIC_SETTING,
} from '../../../../../graphql/mutations'
import {COMPANY_ID} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import {updateIdentity} from './updateIdentity'

const BasicSettings = ({userId, history}) => {
  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [dataForm, setDataForm] = useState(null)
  const [isEdit, setIsEdit] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false)
  const [modalType, setModalType] = useState('')
  const [pwsData, setPwsData] = useState(null)
  const [serviceLoading, setServiceLoading] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [used, setUsed] = useState({
    ktp: 0,
    npwp: 0,
  })

  const [addBasicSetting] = useMutation(ADD_BASIC_SETTING, GQL_FINANCE_OPTIONS)

  const [updateBasicSetting] = useMutation(
    UPDATE_BASIC_SETTING,
    GQL_FINANCE_OPTIONS
  )

  const [updatePrevPeriodBasicSetting] = useMutation(
    UPDATE_PREV_PERIOD_BASIC_SETTING,
    GQL_FINANCE_OPTIONS
  )

  const [updateNextPeriodBasicSetting] = useMutation(
    UPDATE_NEXT_PERIOD_BASIC_SETTING,
    GQL_FINANCE_OPTIONS
  )

  const {
    data: user_bank_salary,
    error: user_bank_salary_error,
    loading: user_bank_salary_loading,
    refetch: refetch_user_bank_salary,
  } = useQuery(GET_BANK_PEOPLE_WORK_SALARY, {
    variables: {
      user_id: userId,
      company_id: COMPANY_ID,
    },
    ...GQL_FINANCE_OPTIONS,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (user_bank_salary) {
      const userWorkSalary =
        user_bank_salary.v_latest_placements?.[0]?.people_work_placement
      const mainBank =
        userWorkSalary?.people_work_salaries?.[0]?.people_profile_bank

      if (userWorkSalary) {
        if (userWorkSalary?.people_work_salaries.length === 0) {
          setIsEdit(false)
        }
        setPwsData(
          {
            current: userWorkSalary?.people_work_salaries?.[0],
            prev: userWorkSalary?.people_work_salaries?.[1] || null,
            next: null,
          } || null
        )
        setDataForm({
          start_date:
            dataForm?.start_date ||
            userWorkSalary?.people_work_salaries?.[0]?.start_date ||
            null,
          end_date:
            dataForm?.end_date ||
            userWorkSalary?.people_work_salaries?.[0]?.end_date ||
            null,
          ptkp:
            dataForm?.ptkp || {
              label:
                userWorkSalary?.people_work_salaries?.[0]?.salary_fields?.PTKP,
              value:
                userWorkSalary?.people_work_salaries?.[0]?.salary_fields?.PTKP,
            } ||
            null,
          salary:
            dataForm?.salary ||
            userWorkSalary?.people_work_salaries?.[0]?.value,
          ktp: dataForm?.ktp || userWorkSalary?.people_work_salaries?.[0]?.ktp,
          npwp:
            dataForm?.npwp || userWorkSalary?.people_work_salaries?.[0]?.npwp,
          bank_account_id: dataForm?.bank_account_id || mainBank?.id,
        })
      }
    }
  }, [user_bank_salary])

  const onValidate = () => {
    setIsSubmit(true)

    if (
      !dataForm?.bank_account_id ||
      !dataForm?.ptkp?.value ||
      !dataForm?.npwp ||
      !dataForm?.ktp ||
      !dataForm?.start_date ||
      !dataForm?.end_date ||
      dataForm?.end_date < dataForm?.start_date
    ) {
      enqueueSnackbar('Please input all required data', {
        variant: 'error',
      })
      return ''
    } else {
      const promise = client.query({
        query: GET_TOTAL_USED_KTP_NPWP,
        fetchPolicy: 'network-only',
        variables: {
          user_id: userId,
          ktp: dataForm.ktp,
          npwp: dataForm.npwp,
        },
      })

      promise.then(({data}) => {
        setUsed({
          ktp: data.total_ktp.aggregate.count,
          npwp: data.total_npwp.aggregate.count,
        })

        if (
          data.total_ktp.aggregate.count !== 0 ||
          data.total_npwp.aggregate.count !== 0
        ) {
          enqueueSnackbar('ID number has been used', {
            variant: 'error',
          })

          setTimeout(() => {
            setIsSubmit(false)
          }, 5000)
        } else {
          onConfirm()
        }
      })
    }
  }

  const onConfirm = async () => {
    setServiceLoading(true)

    const variables = {
      object: {
        salary_fields: {PTKP: dataForm?.ptkp?.value},
        value: Number(dataForm?.salary),
        start_date: dataForm?.start_date,
        end_date: dataForm?.end_date,
        ktp: dataForm?.ktp?.toString(),
        npwp: dataForm?.npwp?.toString(),
        primary_bank: Number(dataForm?.bank_account_id) || null,
        user_work_id:
          user_bank_salary.v_latest_placements?.[0]?.people_work_placement?.id,
      },
    }

    const prevEndDate = moment(dataForm?.start_date)
      .subtract(1, 'days')
      .format('YYYY-MM-DD')

    if (isEdit) {
      if (pwsData?.prev) {
        const variables = {
          id_prev: pwsData?.prev?.id,
          prev_end_date: prevEndDate,
        }

        await updatePrevPeriodBasicSetting({
          variables,
        }).catch(e => {
          console.error(
            'Previous Period Basic Setting Failed to Update',
            JSON.stringify(e)
          )
        })
      }

      if (pwsData?.next) {
        const nextStartDate = moment(dataForm?.end_date)
          .add(1, 'days')
          .format('YYYY-MM-DD')

        const variables = {
          id_next: pwsData?.next?.id,
          next_start_date: nextStartDate,
        }

        await updateNextPeriodBasicSetting({
          variables,
        }).catch(e => {
          console.error(
            'Next Period Basic Setting Failed to Update',
            JSON.stringify(e)
          )
        })
      }

      variables.id_current = pwsData?.current?.id
      await updateBasicSetting({
        variables,
      })
        .then(() => {
          enqueueSnackbar('Basic salary setting updated successfully', {
            variant: 'success',
          })

          onCancel()
          setServiceLoading(false)
        })
        .catch(e => {
          setIsSubmit(false)

          enqueueSnackbar('Basic salary setting failed to update', {
            variant: 'error',
          })

          console.error(
            'Basic salary setting failed to update',
            JSON.stringify(e)
          )

          setServiceLoading(false)
        })
        .finally(() => {
          refetch_user_bank_salary()
        })
    } else {
      if (pwsData?.prev) {
        const variables = {
          id_prev: pwsData?.prev?.id,
          prev_end_date: prevEndDate,
        }

        await updatePrevPeriodBasicSetting({
          variables,
        }).catch(e => {
          console.error(
            'Previous Period Basic Setting Failed to Update',
            JSON.stringify(e)
          )
        })
      }

      addBasicSetting({
        variables,
      })
        .then(() => {
          enqueueSnackbar('Basic setting added successfully', {
            variant: 'success',
          })

          onCancel()
          setServiceLoading(false)
        })
        .catch(e => {
          setIsSubmit(false)

          enqueueSnackbar('Basic salary setting failed to add', {
            variant: 'error',
          })

          console.error('Basic salary setting failed to add', JSON.stringify(e))

          setServiceLoading(false)
        })
        .finally(() => {
          refetch_user_bank_salary()
        })
    }

    updateIdentity({client, userId, dataForm})
  }

  const onCancel = () => {
    setIsSubmit(false)
    setTimeout(() => {
      history.push({
        pathname: '/financial/payroll/employee-settings',
        state: {
          active: 'employee-settings',
          searchable: true,
          showPeriod: false,
          rangePeriod: false,
          filter: true,
          showMonthYearPeriod: false,
        },
      })
    }, 1500)
  }

  const onAddNewSalaryButtonClick = prevPws => {
    setIsEdit(false)
    setDataForm({
      ...dataForm,
      salary: null,
      start_date: null,
      end_date: null,
    })
    setPwsData({current: null, next: null, prev: prevPws})
  }

  const onEditSalaryButtonClick = (data, nextPws, prevPws) => {
    setPwsData({current: data, next: nextPws, prev: prevPws})
    setIsEdit(true)
    setDataForm({
      ...dataForm,
      bank_account_id: data?.people_profile_bank?.id,
      end_date: data?.end_date,
      salary: data?.value,
      start_date: data?.start_date,
      ptkp: {
        label: data?.salary_fields?.PTKP,
        value: data?.salary_fields?.PTKP,
      },
    })
  }

  const disabledSubmitButton =
    !dataForm?.bank_account_id ||
    !dataForm?.ptkp?.value ||
    !dataForm?.npwp ||
    !dataForm?.ktp ||
    !dataForm?.start_date ||
    !dataForm?.end_date ||
    dataForm?.end_date < dataForm?.start_date ||
    (!isEdit && dataForm?.start_date < pwsData?.prev?.start_date)

  return (
    <RequestWrapper
      loading={user_bank_salary_loading}
      error={user_bank_salary_error}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div style={{flexGrow: 1}}>
          <Alert
            severity={isEdit ? 'warning' : 'info'}
            style={{marginBottom: 20}}
          >
            {isEdit ? (
              <>
                <AlertTitle>
                  <strong>Warning</strong>
                </AlertTitle>
                <span>
                  Once you saved changes, the basic salary in the period{' '}
                  <strong>
                    {moment(pwsData?.current?.start_date).format(
                      'MMM DD, YYYY'
                    )}
                  </strong>{' '}
                  -{' '}
                  <strong>
                    {moment(pwsData?.current?.end_date).format('MMM DD, YYYY')}
                  </strong>{' '}
                  will be replaced
                </span>
              </>
            ) : (
              `Add new basic salary`
            )}
          </Alert>

          <form>
            <BankInputGroup
              dataForm={dataForm}
              enqueueSnackbar={enqueueSnackbar}
              isSubmit={isSubmit}
              setDataForm={setDataForm}
              userId={userId}
              userName={
                user_bank_salary?.v_latest_placements?.[0]
                  ?.people_work_placement?.global_user?.name
              }
            />

            <BasicSettingForm
              dataForm={dataForm}
              enqueueSnackbar={enqueueSnackbar}
              isEdit={isEdit}
              isSubmit={isSubmit}
              pwsData={pwsData}
              setDataForm={setDataForm}
              userId={userId}
              used={used}
            />
          </form>

          <FormAction
            disabledSubmitButton={
              (isSubmit && disabledSubmitButton) || serviceLoading
            }
            openConfirmartionDialog={() => {
              setModalType('confirmation')
              setShowModalConfirm(true)
            }}
            openCancellationDialog={() => {
              setModalType('cancel')
              setShowModalConfirm(true)
            }}
          />
        </div>

        <Divider orientation="vertical" flexItem style={{margin: '0 20px'}} />

        <UserSalaryHistoryLog
          userId={userId}
          onAddNewSalaryButtonClick={onAddNewSalaryButtonClick}
          onEditSalaryButtonClick={onEditSalaryButtonClick}
          pwsData={pwsData}
        />
      </div>

      {showModalConfirm && (
        <ModalConfirmation
          open={showModalConfirm}
          onClose={() => {
            setShowModalConfirm(false)
            setModalType('')
          }}
          // onConfirm={onConfirm}
          onConfirm={onValidate}
          type={modalType}
          onCancel={onCancel}
        />
      )}
    </RequestWrapper>
  )
}

export default withRouter(BasicSettings)
