import DocumentIcon from '../../assets/images/document.svg'
import PdfIcon from '../../assets/images/font-awesome/file-pdf.svg'
import ExcelIcon from '../../assets/images/font-awesome/file-excel.svg'
import PowerpointIcon from '../../assets/images/font-awesome/file-powerpoint.svg'

import {fileChecker} from '../../utils/helpers'

export function retrieveFileIcon(filename, src) {
  if (!filename) {
    return DocumentIcon
  }

  const type = fileChecker(filename)

  switch (type) {
    case 'pdf':
      return PdfIcon
    case 'document':
      return DocumentIcon
    case 'excel':
      return ExcelIcon
    case 'powerpoint':
      return PowerpointIcon
    case 'image':
      return src || DocumentIcon
    default:
      return DocumentIcon
  }
}
