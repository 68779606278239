import styled from 'styled-components'

export const MenuItemContainer = styled.div`
  min-height: 100%;
  background: #fff;
  width: 290px;
  flex: 1 auto;
  box-shadow: -1px -1px 3px 3px #ccc;
  z-index: 10;
  position: fixed;
`

export const BackgroundImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const ContentContainer = styled.div`
  min-height: 43px;
  max-width: 260px;
  padding: 10px 18px;
  display: flex;
  background-color: #003d51;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
`

export const ContentTitle = styled.div`
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 22px;
  color: #fff;
`

export const ContentSubtitle = styled.span`
  font-weight: lighter;
  font-size: 16px;
`

export const ContentMenu = styled.div`
  padding-top: 8px;
  padding-left: 24px;
  height: 40px;

  color: #014a62;
  display: flex;
  align-items: center;

  p {
    font-weight: 700;
    font-size: 12px;
  }
`

export const ContentMenuItem = styled.div`
  /* ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #ccc;
    opacity: 0.6;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    opacity: 0.6;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #777;
    opacity: 0.6;
  } */

  //  height: 840px;
  // height: 100%;
  // min-height: 100%;
  // -webkit-overflow-scrolling: touch;
  // overflow: auto;
  // overflow-y: overlay;
  .collapsible-menu {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    list-style: none;
    width: 280px;
  }
  .collapsible-menu > li {
  }
  .collapsible-menu li {
    padding: 0px;
    margin: 0px;
    position: relative;
    list-style: none;
    background: #fff;
    // font-family: 'Helvetica';
    font-weight: 600;
  }
  .collapsible-menu li:hover {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #f4f4f4;
  }
  .collapsible-menu li.has-children.open > ul,
  .collapsible-menu li.has-children.active > ul {
    display: block;
  }
  .collapsible-menu li.has-children.open > a > i,
  .collapsible-menu li.has-children.active > a > i {
    display: block;
    line-height: 0px;
    border-style: solid;
    border-width: 4px;
    margin-top: -2px;
    border-color: #003d51 transparent transparent transparent;
    right: 8px;
  }
  .collapsible-menu li.has-children > a > i {
    display: block;
    line-height: 0px;
    border-style: solid;
    border-width: 4px;
    border-color: transparent transparent transparent #999;
  }
  .collapsible-menu li.open,
  .collapsible-menu li.active {
    background: #f4f4f4;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .collapsible-menu li.open > a,
  .collapsible-menu li.active > a {
    background: #f4f4f4;
    /* color: #f4f4f4; */
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .collapsible-menu li ul {
    padding: 0px;
    margin: 0px;
    display: none;
  }
  .collapsible-menu li ul li {
    /* padding-left: 1em; */
  }
  .collapsible-menu li ul li li {
    background: #ededed;
  }
  .collapsible-menu li ul li li li {
    background: #e8e8e8;
  }
  .collapsible-menu li ul li li li li {
    background: #e3e3e3;
  }
  .collapsible-menu li a {
    display: block;
    color: #014a62;
    padding: 11px 12px;
    padding-left: 45px;
    margin-left: 10px;
    position: relative;
    text-decoration: none;
    font-weight: 700;
    min-height: 18px;
  }
  .collapsible-menu li a span {
    color: #014a62;
    position: absolute;
    left: 6px;
    top: 64%;
    font-size: 16px;
    margin-top: -7px;
  }
  .collapsible-menu li a i {
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
  }
`
export const ImgLogo = styled.img`
  margin-left: 12px;
`

export const Logo = styled.div``

export const ContentWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  @media (min-width: 1024px) and (max-width: 1919px) {
    max-height: 90vh;
  }
  @media (min-width: 1920px) {
    max-height: 93.5vh;
  }
`
