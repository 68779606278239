import {Button, TextField} from '@material-ui/core'
import React, {useState} from 'react'
import ModalDetail from '../../../shared-components/popup/ModalDetail'
import {BodyContentModalGenerate} from '../HistoryStyles'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'

const ERROR_VALUE = [null, '']

const DateComponent = ({id, value, onChange, helperText, ...props}) => {
  return (
    <KeyboardDatePicker
      margin="normal"
      id={id}
      placeholder="Select Date"
      format="MMM, dd yyyy"
      inputVariant="outlined"
      value={value}
      className="date-picker"
      onChange={onChange}
      helperText={helperText}
      {...props}
    />
  )
}

export const ModalGenerateHistory = ({open, onClose, onGenerate}) => {
  const [error, setError] = useState(false)
  const [values, setValues] = useState({
    name: '',
    startDate: new Date(),
    endDate: new Date(),
  })

  const handleClickGenerate = () => {
    const arr = Object.keys(values).map(key => values[key])
    const isNullValue = ERROR_VALUE.some(r => arr.indexOf(r) >= 0)
    if (isNullValue || values.name.length > 150) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    } else {
      onGenerate(values)
      setValues({
        name: '',
        startDate: new Date(),
        endDate: new Date(),
      })
    }
  }

  const handleClose = () => {
    onClose()
    setValues({
      name: '',
      startDate: new Date(),
      endDate: new Date(),
    })
  }

  return (
    <ModalDetail
      onClose={handleClose}
      open={open}
      fullWidth
      title="Menghasilkan riwayat"
      maxWidth="sm"
    >
      <BodyContentModalGenerate>
        <p className="label">Nama struktur organisasi*</p>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          className="textfield"
          placeholder="Tambah nama struktur organisasi"
          value={values.name}
          onChange={e => {
            setValues({...values, name: e.target.value})
          }}
          error={
            error &&
            (ERROR_VALUE.includes(values.name) || values.name.length > 150)
          }
          helperText={
            error && ERROR_VALUE.includes(values.name)
              ? 'Bagian ini harus diisi'
              : error && values.name.length > 150
              ? 'Nama tidak boleh lebih dari 150 karakter'
              : null
          }
        />
        <p className="label label-period">Periode</p>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="period">
            <div className="date_generate">
              <p className="label">Mulai*</p>
              <DateComponent
                id="start-date"
                value={values.startDate}
                maxDate={values.endDate}
                onChange={date =>
                  setValues(prev => ({
                    ...prev,
                    startDate: date,
                    endDate: null,
                  }))
                }
                error={error && ERROR_VALUE.includes(values.startDate)}
                helperText={
                  error && ERROR_VALUE.includes(values.startDate)
                    ? 'Bagian ini harus diisi'
                    : null
                }
              />
            </div>
            <div className="date_generate">
              <p className="label">Berakhir*</p>
              <DateComponent
                id="start-date"
                value={values.endDate}
                minDate={values.startDate}
                onChange={date => setValues(prev => ({...prev, endDate: date}))}
                error={error && ERROR_VALUE.includes(values.endDate)}
                helperText={
                  error && ERROR_VALUE.includes(values.endDate)
                    ? 'Bagian ini harus diisi'
                    : null
                }
              />
            </div>
          </div>
        </MuiPickersUtilsProvider>
        <div className="action">
          <Button onClick={handleClose}>Batal</Button>
          <Button
            disableElevation
            className="generate-btn"
            variant="contained"
            color="primary"
            onClick={handleClickGenerate}
          >
            Menghasilkan
          </Button>
        </div>
      </BodyContentModalGenerate>
    </ModalDetail>
  )
}
