import gql from 'graphql-tag'

export const ADD_NEW_KPI_MONITORING = gql`
  mutation addKPIMonitoring(
    $data: [kpi_monitoring_frequencies_insert_input!]!
  ) {
    insert_kpi_monitoring_frequencies(objects: $data) {
      affected_rows
    }
  }
`
