import ProfilePage from './ProfilePageComponent'

export const ProfileConfig = {
  routes: [
    {
      path: '/company/profile',
      component: ProfilePage,
      state: 'profile',
      exact: true,
    },
    {
      path: '/company/editIntro',
      component: ProfilePage,
      state: 'edit-intro',
      exact: true,
    },
  ],
}
