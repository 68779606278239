import {Avatar, Divider, Icon, IconButton, Typography} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState} from 'react'
import {BoldTypography} from '../../../components/typography/TypographyStyles'
import {PaperContainer} from '../../../GlobalStyles'
import {
  NewTableOptions,
  newTableStyles,
} from '../../shared-components/table/TableOptions'
import {GET_HOLDING_MEMBER} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'
import {useQuery} from '@apollo/react-hooks'
import Loading from '../../../components/loading/LoadingComponent'
import ModalInviteCompany from './add-company/ModalInviteCompany'
import ToolbarMember from './ToolbarMember'
import {Link} from 'react-router-dom'

export default function ListComponent(props) {
  const tableClasses = newTableStyles()
  const [openInvite, setOpenInvite] = useState(false)
  const {data, error, refetch} = useQuery(
    GET_HOLDING_MEMBER(COMPANY_ID, 'ACTIVE'),
    {
      variables: {
        search: `%${props.searchText}%`,
      },
      context: {
        headers: {
          'X-Hasura-Role': 'holding-administrator',
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const handleOpenInvite = () => {
    setOpenInvite(prev => !prev)
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <ToolbarMember
        title="Company Member"
        handleOpenInvite={handleOpenInvite}
      />
      <Divider />
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontSize: 12}}
          options={{...NewTableOptions, paging: true, sorting: true}}
          columns={[
            {
              title: 'Company',
              field: 'company_member_profile.brand_name',
              cellStyle: {
                wordBreak: 'break-word',
              },
              render: row => (
                <div style={{display: 'flex', minWidth: 200}}>
                  <Avatar
                    variant="rounded"
                    src={row.company_member_profile.logo}
                    style={{marginRight: 16}}
                  ></Avatar>
                  <Link
                    to={{
                      pathname: `/member/list/${row.company_member_profile.id}`,
                      state: {active: 'detail-company'},
                    }}
                    style={{color: '#000', textDecoration: 'none'}}
                  >
                    <BoldTypography variant="body2" gutterBottom>
                      {row.company_member_profile.brand_name}
                    </BoldTypography>
                    <Typography variant="caption">
                      {row.company_member_profile.legal_name}
                    </Typography>
                  </Link>
                </div>
              ),
            },

            {
              title: 'Type',
              field: 'company_member_profile.type',
              cellStyle: {
                textTransform: 'capitalize',
              },
            },
            {
              title: 'Industry',
              field: 'company_member_profile.global_company_industry_type.name',
            },
            {
              title: 'Speciality',
              field: 'company_member_profile.field',
            },
            {
              title: 'Email',
              field: 'company_member_profile.email',
              cellStyle: {color: '#039be5', fontWeight: 'normal'},
            },
            {
              title: '',
              field: 'action',
              filtering: false,
              render: () => (
                <div style={{display: 'flex'}}>
                  <IconButton>
                    <Icon color="secondary">delete</Icon>
                  </IconButton>
                </div>
              ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={data.holding_relations}
        />
      </div>
      <ModalInviteCompany
        open={openInvite}
        handleClose={handleOpenInvite}
        refetchMain={refetch}
      />
    </PaperContainer>
  )
}
