import React from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { IconButton } from "@material-ui/core";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { StyledMenuItem, StyledMenus } from "../../GlobalStyles";

export default function ButtonMoreHorizontal({
  id = "_popup_more_",
  list = [],
}) {

  const handleClickItem = (onClick, onClose) => {
    onClick()
    onClose()
  }
  return (
    <PopupState variant="popover" popupId={id}>
      {(popupState) => (
        <>
          <IconButton {...bindTrigger(popupState)}>
            <MoreHorizIcon />
          </IconButton>
          <StyledMenus {...bindMenu(popupState)}>
            {list
              .filter((res) => typeof res === "object")
              .map((res, i) => (
                <StyledMenuItem
                  style={{ justifyContent: "start" }}
                  {...res}
                  onClick={() => handleClickItem(res.onClick, popupState.close)}
                  key={`${i}`}
                >
                  {res.label}
                </StyledMenuItem>
              ))}
          </StyledMenus>
        </>
      )}
    </PopupState>
  );
}
