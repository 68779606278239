import React from 'react'
import DoughnutCardWidget from '../../../../components/widgets/doughnut/DoughnutCardComponent'
import {WrapperChart} from '../../DashboardStyles'
import SkeletonChartDoughnut from '../../SkeletonChartDoughnut'
import {Divider} from '@material-ui/core'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {status_update_task} from '../../../shared-components/data/SharedData'

const color = ['#039be5', '#4caf50', '#ffa000', '#ef4d5e']

const setterLabel = (totalData, totalAllData) => {
  return `${((totalData / totalAllData) * 100).toFixed(2) || 0}% (${totalData})`
}

export default function PerformanceChart({
  dataPerformance,
  loadingPerformance,
}) {
  const {TODO, COMPLETED, INPROGRESS, DONE} = status_update_task
  const total_goal = dataPerformance.todo_goal + dataPerformance.complete_goal
  const total_task =
    dataPerformance.todo_task +
    dataPerformance.inprogress_task +
    dataPerformance.done_task +
    dataPerformance.complete_task

  const DATA = {
    goals: {
      title: GLOBAL_GLOSSARY.performance.Goal,
      labels: [TODO, COMPLETED],
      datasets: [
        {
          data: [dataPerformance.todo_goal, dataPerformance.complete_goal],
          dataLabel: [
            setterLabel(dataPerformance.todo_goal, total_goal),
            setterLabel(dataPerformance.complete_goal, total_goal),
          ],
          backgroundColor: color,
          hoverBackgroundColor: color,
        },
      ],
      aggregate: {
        label: GLOBAL_GLOSSARY.performance.Goal,
        value: total_goal,
      },
    },
    tasks: {
      title: GLOBAL_GLOSSARY.performance.Task,
      labels: [TODO, INPROGRESS, DONE, COMPLETED],
      datasets: [
        {
          data: [
            dataPerformance.todo_task,
            dataPerformance.inprogress_task,
            dataPerformance.done_task,
            dataPerformance.complete_task,
          ],
          dataLabel: [
            setterLabel(dataPerformance.todo_task, total_task),
            setterLabel(dataPerformance.inprogress_task, total_task),
            setterLabel(dataPerformance.done_task, total_task),
            setterLabel(dataPerformance.complete_task, total_task),
          ],
          backgroundColor: color,
          hoverBackgroundColor: color,
        },
      ],
      aggregate: {
        label: GLOBAL_GLOSSARY.performance.Task,
        value: total_task,
      },
    },
  }

  return (
    <WrapperChart
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '12px',
      }}
    >
      <div style={{flex: 1}}>
        {Object.keys(dataPerformance).length > 0 && !loadingPerformance ? (
          <DoughnutCardWidget
            data={DATA.goals}
            labelType="list"
            isWithDivider={false}
          />
        ) : (
          <SkeletonChartDoughnut />
        )}
      </div>
      <Divider orientation="vertical" flexItem />
      <div style={{flex: 1}}>
        {Object.keys(dataPerformance).length > 0 && !loadingPerformance ? (
          <DoughnutCardWidget
            data={DATA.tasks}
            labelType="list"
            isWithDivider={false}
          />
        ) : (
          <SkeletonChartDoughnut />
        )}
      </div>
    </WrapperChart>
  )
}
