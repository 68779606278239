import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {Calendar} from 'react-big-calendar'
import {Card, Button} from '@material-ui/core'

export const AppliesToContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, 158px);
  gap: 34px;
  margin-left: -1rem;
`

export const AppliesToChild = styled.li`
  color: #a9a8a8;
`

export const LeaveApplyContainer = styled.div`
  margin-left: 2.3rem;
`
export const LeaveNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`
export const LeaveNameChild = styled.div`
  display: flex;
  align-items: center;
`
export const BlueLink = styled(Link)`
  color: #007fb2;
  text-decoration: none;
  margin: 12px;
`
export const CalendarContainer = styled(Calendar)`
  min-height: 100vh !important;
  padding: 24px;

  .year {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, auto));
    .month {
      .month-name {
        margin-bottom: 12px;
      }
    }
  }
`
export const StyledListCard = styled(Card)`
  margin-top: 1rem;
  padding: 0.8rem;
  display: flex;
  align-items: center;
`
export const DetailModalLeave = styled.div`
  width: 70%;
  padding: 0 1rem;
`
export const RedLink = styled(Link)`
  color: #f44336;
  text-decoration: none;
`

export const RedButton = styled(Button)`
  color: #f44336;
  text-decoration: none;
`

export const CalendarToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
`

export const CalendarNavigate = styled.div`
  align-items: center;
  display: flex;
`

export const BlueLinks = styled(Link)`
  color: #007fb2;
  text-decoration: none;
`
export const WrapperField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const TooltipExpiredBalanceContent = styled.div`
  padding: 8px;

  p {
    font-size: 10px;
    margin: 0;
    margin-bottom: 4px;
  }
  .ex {
    margin-right: 12px;
  }
  .content {
    display: flex;
    flex-direction: row;
  }
`
