import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'

import EmployeeLoanForm from '../form/EmployeeLoanForm'

import FormAction from '../../../../../components/form/FormActionComponent'
import AlertPopup from '../../../../shared-components/popup/ResultPopup'

const EmployeeLoanFormPageComponent = props => {
  const {dataForm, setDataForm, setActiveTab} = props
  const disabledSubmitButton =
    !dataForm?.policy ||
    !dataForm?.user_work_id ||
    !dataForm?.letter_num ||
    !dataForm?.description ||
    !dataForm?.loan_amount_w_interest > 0 ||
    !dataForm?.loan_amount > 0 ||
    !dataForm?.status
  // dataForm?.loan_rate === undefined ||
  // dataForm?.loan_rate === ''

  const [isNext, setIsNext] = useState(!disabledSubmitButton)
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const [cancelIsOpen, setCancelIsOpen] = useState(false)

  const mutationNextButton = () => {
    setIsNext(true)
    setConfirmIsOpen(false)

    if (!disabledSubmitButton) {
      setActiveTab(1)
    }
  }

  return (
    <>
      <EmployeeLoanForm
        dataForm={dataForm}
        setDataForm={setDataForm}
        isSubmit={isNext}
      />

      <FormAction
        disabledSubmitButton={isNext && disabledSubmitButton}
        submitLabelButton="Next"
        openCancellationDialog={() => setCancelIsOpen(true)}
        openConfirmartionDialog={() => {
          setConfirmIsOpen(true)
        }}
      />

      {confirmIsOpen && (
        <AlertPopup
          open={confirmIsOpen}
          feature="Employee Loan"
          message="Are you sure the data you have entered is correct?"
          type="Confirm"
          status="cancel"
          handleClose={() => setConfirmIsOpen(false)}
          mutation={mutationNextButton}
        />
      )}

      {cancelIsOpen && (
        <AlertPopup
          open={cancelIsOpen}
          feature="Discard Changes?"
          message="Are you sure you want to discard changes?"
          type="Discard"
          status="cancel"
          handleClose={() => setCancelIsOpen(false)}
          mutation={() => {
            setCancelIsOpen(false)
            props.history.goBack()
          }}
        />
      )}
    </>
  )
}

export default withRouter(EmployeeLoanFormPageComponent)
