import React, {useState, useEffect} from 'react'
import MaterialTable from 'material-table'
import {
  CircularProgress,
  // makeStyles,
  // Typography,
  // Popover,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {Link} from 'react-router-dom'
import helperStyle, {
  TableOptions,
  TextBlueBold,
  TextDarkBlue,
  CenterWrapper,
  TableEmployeeStyled,
  // PostponedItem,
  // PopOverWrapper,
  pageSizeOption,
  MediumTextBlackBold,
} from '../../PayrollPageStyles'
import {
  convertToRoundedRupiah,
  employeeColumnSort,
} from '../../../../../utils/helpers'
import {
  GET_BALOON_STATUS_BUTTON_QUERY_MULTIPLE,
  GET_EMPLOYEE_PAYROLL_PROCESS,
} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import {
  EnhancedToolbar,
  GreyTypography,
  Flex,
  GenerateTooltip,
} from '../../../../../GlobalStyles'
import ImportBaloonPayroll from '../../../../../components/importExcel/ImportBaloonPayroll'
import ImportPopup from '../../../../../components/importExcel/ImportPopup'
import ExportBaloon from '../../../../../components/exportExcel/ExportBaloon'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'
import EmployeeAvatar from '../../../../shared-components/EmployeeAvatar'
import {
  CLOSE_BALOON,
  IMPORT_DATA_FILTER,
} from '../../../../../graphql/mutations'
import ImportBaloonMultiple from '../../../../directory/download-report/page-download/ImportBaloonMultiple'
import ImportBaloonPayrollMultiple from '../../ImportBaloonPayrollMultiple'
import useDownloadReport from '../../../../../hooks/useDownloadReport'

// const useStyles = makeStyles(() => ({
//   popover: {
//     pointerEvents: 'none',
//   },
//   paper: {
//     background: '#0000002e',
//     opacity: 1,
//   },
// }))

const StepCalculation = props => {
  const {
    period,
    month,
    location,
    searchText,
    filterData,
    setProssesImport,
  } = props
  // const [anchorEl, setAnchorEl] = useState(null)
  // const [openedId, setOpenedId] = useState('')

  // const handlePopoverClose = () => {
  //   setAnchorEl(null)
  // }

  // const open = Boolean(anchorEl)

  const classes = helperStyle()
  // const popoverClasses = useStyles()

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const [openImport, setImport] = useState(false)
  const [openGenerateData, setOpenGenerateData] = useState(false)
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')
  const [openAllBaloon, setOpenAllBaloon] = useState(false)
  const [menu, setMenu] = useState(null)
  const openMenu = Boolean(menu)
  const slicedParams = location.search.split('=')
  const [dataerrorImport, settingErrorImport] = useState([])
  const [settingImport, setingImport] = useState({
    table: '',
    position: '',
  })
  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    refetch: refetchBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON_QUERY_MULTIPLE, {
    variables: {
      company: COMPANY_ID,
      table: [
        'finance_payroll_process_multiplier',
        'finance_payroll_process_component',
      ],
      filter: {period_year: period, period_month: month},
    },
  })

  const TABLE_PAYROLL_PROCESS = 'finance_payroll_process'
  const {
    dataBaloon: dataBaloonGenerateData,
    onDownload,
    onCloseBaloon,
  } = useDownloadReport({
    table: TABLE_PAYROLL_PROCESS,
    filter: {period_year: period, period_month: month},
  })

  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA_FILTER
  )

  const {data: employeePayrollProcess, refetch, loading, error} = useQuery(
    GET_EMPLOYEE_PAYROLL_PROCESS,
    {
      variables: {
        company: COMPANY_ID,
        year: period,
        month: Number(slicedParams[slicedParams.length - 1]),
        offset: rowsPerPage * currentPage,
        limit: rowsPerPage,
        position:
          JSON.stringify(filterData.position) === '[]'
            ? null
            : filterData.position,
        organization:
          JSON.stringify(filterData.organization) === '[]'
            ? null
            : filterData.organization,
        job_profile:
          JSON.stringify(filterData.job_profile) === '[]'
            ? null
            : filterData.job_profile,
        rank: JSON.stringify(filterData.rank) === '[]' ? null : filterData.rank,
        office:
          JSON.stringify(filterData.office) === '[]' ? null : filterData.office,
        search: `%${searchText}%`,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  useEffect(() => {
    setCurrentPage(0)
    if (
      getBaloonStatus &&
      getBaloonStatus.company_import_data_logs.length > 0
    ) {
      const check = getBaloonStatus.company_import_data_logs.filter(row => {
        return row.status === 'WAITING'
      })
      if (check.length > 0) {
        setProssesImport(true)
      } else {
        setProssesImport(false)
      }
      settingErrorImport(errorImport(settingImport.table))
    } else {
      setProssesImport(false)
    }
  }, [
    settingImport,
    getBaloonStatus,
    period,
    searchText,
    filterData,
    rowsPerPage,
  ])

  const handleGenerateData = () => {
    setOpenGenerateData(prev => !prev)

    if (openGenerateData) {
      onDownload({
        variables: {
          table: TABLE_PAYROLL_PROCESS,
          filter: {period_year: period, period_month: month},
        },
      })
    }
  }

  const importMutation = async () => {
    try {
      // eslint-disable-next-line
      const response = await mutationImport({
        variables: {
          table: settingImport.table,
          file: fileURL,
          filter: {period_year: period, period_month: month},
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus &&
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  const handleClickOpenImport = () => {
    setImport(true)
  }

  const handleCloseImport = () => {
    setImport(false)
  }
  if (
    error !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationImportError !== undefined
  ) {
    return (
      <div>
        {JSON.stringify(error) ||
          JSON.stringify(errorGetBaloonStatus) ||
          JSON.stringify(mutationCloseBaloonError) ||
          JSON.stringify(mutationImportError)}
      </div>
    )
  }

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  if (
    dataBaloonGenerateData?.company_export_data_logs?.length > 0 &&
    dataBaloonGenerateData?.company_export_data_logs?.[0]?.status ===
      'COMPLETED'
  ) {
    refetch()
  }

  const handleClickMenu = event => {
    setMenu(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setMenu(null)
  }

  const errorImport = table => {
    if (
      getBaloonStatus &&
      getBaloonStatus.company_import_data_logs.length > 0
    ) {
      const check = getBaloonStatus.company_import_data_logs.filter(row => {
        return row.reports && row.table_name === table
      })
      return check && check.length > 0 ? check[0].reports.failed : []
    } else {
      return []
    }
  }
  const disableImport = table => {
    if (
      getBaloonStatus &&
      getBaloonStatus.company_import_data_logs.length > 0
    ) {
      const check = getBaloonStatus.company_import_data_logs.filter(row => {
        return row.status === 'WAITING' && row.table_name === table
      })
      return check && check.length > 0
    } else {
      return false
    }
  }
  const TOTAL_PROCESS = getBaloonStatus
    ? getBaloonStatus.company_import_data_logs.length
    : 0
  return (
    <>
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {!employeePayrollProcess && loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}

      <EnhancedToolbar>
        <MediumTextBlackBold>
          Employee Salary Component Calculation
        </MediumTextBlackBold>
        <Flex flexEnd>
          <GenerateTooltip
            title={
              dataBaloonGenerateData?.company_export_data_logs?.[0]?.status ===
              'WAITING'
                ? `You can only generate data once an hour`
                : ``
            }
            placement="left"
          >
            <span>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={() => setOpenGenerateData(true)}
                disabled={
                  dataBaloonGenerateData?.company_export_data_logs?.[0]
                    ?.status === 'WAITING'
                }
              >
                Generate Data
              </Button>
            </span>
          </GenerateTooltip>

          <Button
            id="basic-button"
            variant={'outlined'}
            color="primary"
            size="large"
            style={{margin: '0 12px'}}
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClickMenu}
            // onClick={() => handleClickOpenImport()}
          >
            Import Data
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={menu}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={() => {
                setingImport({
                  table: 'finance_payroll_process_component',
                  position: 'Component',
                })
                handleClickOpenImport()
              }}
              disabled={disableImport('finance_payroll_process_component')}
            >
              {disableImport('finance_payroll_process_component') ? (
                <GreyTypography>
                  Importing Component on proces...
                </GreyTypography>
              ) : (
                <TextDarkBlue>Component</TextDarkBlue>
              )}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setingImport({
                  table: 'finance_payroll_process_multiplier',
                  position: 'Multiplier',
                })
                handleClickOpenImport()
              }}
              disabled={disableImport('finance_payroll_process_multiplier')}
            >
              {disableImport('finance_payroll_process_multiplier') ? (
                <GreyTypography>
                  Importing Multiplier on proces...
                </GreyTypography>
              ) : (
                <TextDarkBlue>Multiplier</TextDarkBlue>
              )}
            </MenuItem>
          </Menu>
        </Flex>
      </EnhancedToolbar>

      {TOTAL_PROCESS > 1 ? (
        <ImportBaloonMultiple
          totalProcess={TOTAL_PROCESS}
          onSeeAll={() => setOpenAllBaloon(true)}
        />
      ) : (
        <ImportBaloonPayroll
          data={getBaloonStatus && getBaloonStatus}
          closeBaloonMutation={closeBaloonMutation}
          setImport={setImport}
          mutationCloseBaloon={mutationCloseBaloon}
          refetchBaloonStatus={refetchBaloonStatus}
          setingImport={setingImport}
        />
      )}

      <ExportBaloon
        isPayroll
        availableToSeeProblem
        availableToDownloadExcel={false}
        data={dataBaloonGenerateData}
        closeBaloonMutation={onCloseBaloon}
        retry={handleGenerateData}
        feature="Payroll Process"
      />

      {employeePayrollProcess && (
        <TableEmployeeStyled>
          <MaterialTable
            data={employeePayrollProcess.people_work_payroll}
            options={{...TableOptions}}
            totalCount={employeePayrollProcess.employee_count.aggregate.count}
            isLoading={employeePayrollProcess && loading}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
            onChangePage={page => {
              setCurrentPage(page)
            }}
            columns={[
              {
                title: 'Employee',
                field: 'employee',
                headerStyle: {minWidth: 350},
                cellStyle: {minWidth: 350},
                customSort: employeeColumnSort,
                render: rowData => {
                  return (
                    <EmployeeAvatar
                      src={rowData.employee_placement?.global_user.avatar}
                      regno={rowData.employee_placement?.regno}
                      title={
                        rowData.employee_placement?.company_job_profile.title
                      }
                      global_user={rowData.employee_placement?.global_user}
                      city_name={
                        rowData.employee_placement?.company_job_profile
                          .company_address.global_city.name
                      }
                      province_name={
                        rowData.employee_placement?.company_job_profile
                          .company_address.global_province.name
                      }
                      empStatus={rowData.employee_placement?.status}
                      empEndDate={rowData.employee_placement?.end_date}
                    />
                  )
                },
              },
              {
                title: 'Basic Salary',
                field: 'basic_salary',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.salary - b.salary
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.salary, false),
              },
              {
                title: 'Earning',
                field: 'addition',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.addition - b.addition
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.addition, false),
              },
              {
                title: 'Deduction',
                field: 'deduction',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.deduction - b.deduction
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.deduction, false),
              },
              {
                title: 'Total',
                field: 'total',
                headerStyle: {minWidth: 175},
                cellStyle: {minWidth: 175, textAlign: 'left'},
                customSort: (a, b) => {
                  return a.final_salary - b.final_salary
                },
                render: rowData =>
                  convertToRoundedRupiah(rowData.final_salary, false),
              },
              // {
              //   title: 'Status',
              //   field: 'status',
              //   sorting: false,
              //   headerStyle: {minWidth: 175, textAlign: 'center'},
              //   cellStyle: {minWidth: 175, textAlign: 'center'},
              //   render: rowData => {
              //     if (rowData.approver && rowData.finance_personnel) {
              //       return (
              //         <Badge style={{backgroundColor: '#FFA000'}}>
              //           Approved
              //         </Badge>
              //       )
              //     } else if (!rowData.approver && rowData.finance_personnel) {
              //       return <Badge>Need Approval 1</Badge>
              //     } else if (rowData.approver && !rowData.finance_personnel) {
              //       return <Badge>Need Approval 2</Badge>
              //     } else {
              //       return <Badge>Need Approval</Badge>
              //     }
              //   },
              // },

              // Hide: WLB 4052
              // {
              //   title: 'Postpone',
              //   field: 'postpone',
              //   headerStyle: {minWidth: 175, textAlign: 'center'},
              //   cellStyle: {minWidth: 175, textAlign: 'center'},
              //   render: rowData => {
              //     if (rowData.postponed_items) {
              //       const totalFilteredAllowances = rowData.allowances.filter(
              //         item => {
              //           return item.is_postponed
              //         }
              //       ).length
              //       if (
              //         rowData.postponed_items.salary &&
              //         totalFilteredAllowances > 0
              //       ) {
              //         return (
              //           <div>
              //             <div>
              //               <Typography
              //                 aria-owns={
              //                   open
              //                     ? `mouse-over-popover${rowData.id}`
              //                     : undefined
              //                 }
              //                 id={rowData.id}
              //                 aria-haspopup="true"
              //                 onMouseEnter={event => {
              //                   setAnchorEl(event.currentTarget)
              //                   setOpenedId(rowData.id)
              //                 }}
              //                 onMouseLeave={() => {
              //                   handlePopoverClose()
              //                   setOpenedId('')
              //                 }}
              //               >
              //                 <PostponedItem>
              //                   <div>
              //                     <img
              //                       src={require('../../../../../assets/images/font-awesome/exclamation.svg')}
              //                     />
              //                   </div>
              //                 </PostponedItem>
              //               </Typography>
              //               <Popover
              //                 id={`mouse-over-popover${rowData.id}`}
              //                 className={popoverClasses.popover}
              //                 classes={{
              //                   paper: popoverClasses.paper,
              //                 }}
              //                 open={open && openedId === rowData.id}
              //                 anchorEl={anchorEl}
              //                 anchorOrigin={{
              //                   vertical: 'top',
              //                   horizontal: 'center',
              //                 }}
              //                 transformOrigin={{
              //                   vertical: 'bottom',
              //                   horizontal: 'center',
              //                 }}
              //                 onClose={() => {
              //                   handlePopoverClose()
              //                   setOpenedId('')
              //                 }}
              //                 disableRestoreFocus
              //                 backdropInvisible={false}
              //               >
              //                 <PopOverWrapper>
              //                   <div>
              //                     <strong>Postpone payment</strong>
              //                     <ul>
              //                       {/* posponed payment only for earning */}
              //                       {/* {rowData.non_fixed_components.map(
              //                         (item, i) => {
              //                           return <li key={i}>{item.name}</li>
              //                         }
              //                       )} */}
              //                       {rowData.allowances.map((item, i) => {
              //                         if (item.is_postponed) {
              //                           return <li key={i}>{item.name}</li>
              //                         }
              //                       })}
              //                     </ul>
              //                   </div>
              //                 </PopOverWrapper>
              //               </Popover>
              //             </div>
              //           </div>
              //         )
              //       }
              //     }
              //     return '-'
              //   },
              // },

              {
                title: 'Action',
                field: 'action',
                cellStyle: {minWidth: 100},
                sorting: false,
                render: rowData => {
                  return (
                    <Link
                      to={{
                        pathname: `/financial/payroll/payroll-process/payroll-detail/${rowData.id}`,
                        state: {
                          active: 'payroll-detail',
                          payrollId: rowData.id,
                          year: period,
                          month: Number(slicedParams[slicedParams.length - 1]),
                          // id: rowData.id,
                          // jobName: rowData.organizationUnit,
                        },
                      }}
                      className={classes.link}
                    >
                      <TextBlueBold>Details</TextBlueBold>
                    </Link>
                  )
                },
              },
            ]}
          />
        </TableEmployeeStyled>
      )}

      <ImportPopup
        open={openImport}
        position={settingImport.position}
        handleClose={handleCloseImport}
        mutation={importMutation}
        // onDownloadMaster={({provinces, cities, religions}) =>
        //   generateTemplateMaster({provinces, cities, religions})
        // }
        // fileTemplate={
        //   'https://public-media.wlb.co.id/1dc6d763-8ad8-4e8a-8d26-5bb73dd569f2-1602486963025.xlsx'
        // }
        isAlreadyImported={isAlreadyImported}
        setIsAlreadyImported={setIsAlreadyImported}
        setImport={setImport}
        fileURL={fileURL}
        setFileURL={setFileURL}
        errorsImport={dataerrorImport}
      />

      <ImportBaloonPayrollMultiple
        open={openAllBaloon}
        onClose={() => {
          setOpenAllBaloon(false)
        }}
        data={getBaloonStatus && getBaloonStatus}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
        refetchBaloonStatus={refetchBaloonStatus}
        setingImport={setingImport}
      />

      {openGenerateData && (
        <AlertDialog
          open={openGenerateData}
          handleClose={() => setOpenGenerateData(false)}
          mutation={handleGenerateData}
          feature="Warning"
          message="Current payroll will be rewritten, are you sure?"
          status="cancel"
          type="warning"
          positiveTitleButton="Yes"
        />
      )}
    </>
  )
}

export default StepCalculation
