import {
  IconButton,
  makeStyles,
  Typography,
  Divider,
  Button,
  MenuItem,
  CircularProgress,
} from '@material-ui/core'
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
  FormContainer,
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
  ThinTextfield,
} from '../../../../GlobalStyles'
import React from 'react'
import {withRouter} from 'react-router-dom'
import {ADD_INDIVIDUAL_COMPETENCY} from '../../../../graphql/mutations'
import {LIST_COMPETENCIES_DICTIONARIES} from '../../../../graphql/queries'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import {USER_ID} from '../../../../utils/globals'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabContainer: {
    marginTop: 15,
  },
  tabRoot: {fontSize: 14},
  root: {padding: 24},
  iconImg: {
    width: 227,
    borderRadius: 5,
    marginRight: 24,
    objectFit: 'cover',
  },
  mb12: {marginBottom: 12},
  ml12: {marginLeft: 12},
  dividers: {margin: '24px -24px !important'},
  mb8: {marginBottom: 8},
  mb16: {marginBottom: 16},
  avatar: {
    width: 42,
    height: 42,
    margin: '0 24px 0 0',
    borderRadius: 5,
    objectFit: 'cover',
  },
  mainRoot: {
    padding: 24,
  },
}))

const IndividualCompetencyForm = props => {
  const classes = useStyles()
  const {
    data: dataCompetenciesDictionaries,
    error: errorCompetenciesDictionaries,
    loading: loadingCompetenciesDictionaries,
  } = useQuery(LIST_COMPETENCIES_DICTIONARIES, {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-hr-admin',
      },
    },
    fetchPolicy: 'no-cache',
    variables: {
      job_profile: props.location.state.jobProfile,
      placement: props.match.params.id,
    },
  })
  const {enqueueSnackbar} = useSnackbar()
  const {id} = props.match.params
  const [open, setOpen] = React.useState(false)
  const [confirmType, setConfirmType] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [value, setValue] = React.useState({
    user: USER_ID,
    placement: id,
    dictionary: '',
    level: '',
  })

  const [addIndividualCompetency] = useMutation(ADD_INDIVIDUAL_COMPETENCY)

  const handleConfirm = type => {
    setOpen(true)
    setConfirmType(type)
  }

  const goBack = () => {
    return props.history.goBack()
  }

  const handleChange = prop => event => {
    const vals = {
      ...value,
      [prop]: event.target.value,
    }

    setValue(vals)
  }

  const handleSave = () => {
    if (!value.dictionary || !value.level) {
      setError(true)
    } else {
      setIsLoading(true)

      addIndividualCompetency({
        variables: {objects: [value]},
      })
        .then(() => {
          goBack()
          enqueueSnackbar('Employee Competency Added', {
            variant: 'success',
          })
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
      setError(false)
    }
  }

  if (loadingCompetenciesDictionaries) {
    return <CircularProgress />
  } else if (errorCompetenciesDictionaries) {
    return (
      <Typography>{JSON.stringify(errorCompetenciesDictionaries)}</Typography>
    )
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handleConfirm('discard')}
          >
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6">Tambah Kompetensi Pegawai</AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            className={classes.backButton}
            onClick={() => handleConfirm('discard')}
          >
            Batal
          </Button>
          <Button
            onClick={() => handleConfirm('submit')}
            size="large"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Kirim
          </Button>
        </div>
      </FormToolbar>
      <Divider />
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Nama Kompetensi</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              select
              margin="normal"
              variant="outlined"
              value={value.dictionary}
              onChange={handleChange('dictionary')}
              error={!value.dictionary && error}
              helperText={!value.dictionary && error && 'Bagian ini diperlukan'}
            >
              {dataCompetenciesDictionaries.competency_model_items.map(
                (item, index) => (
                  <MenuItem
                    key={index}
                    value={item.competency_version_dictionary.id}
                  >
                    {
                      item.competency_version_dictionary.competency_dictionary
                        .name.id
                    }
                  </MenuItem>
                )
              )}
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>

        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Level Individu</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              select
              margin="normal"
              variant="outlined"
              value={value.level}
              onChange={handleChange('level')}
              error={!value.level && error}
              helperText={!value.level && error && 'Bagian ini diperlukan'}
            >
              {[...new Array(5)].map((item, index) => (
                <MenuItem key={`${item}${index}`} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <AddEdit
        open={open}
        handleClose={() => setOpen(false)}
        mutation={confirmType === 'discard' ? goBack : handleSave}
        header={CONFIRMATION[confirmType]?.header}
        body={CONFIRMATION[confirmType]?.body}
        button={CONFIRMATION[confirmType]?.button}
        buttonClose="Batal"
        disabled={isLoading}
      />
    </PaperContainer>
  )
}
export default withRouter(IndividualCompetencyForm)

const CONFIRMATION = {
  submit: {
    header: 'Kirim Kompetensi Pegawai?',
    body: 'Apakah Anda yakin ingin mengirim Kompetensi Pegawai ini?',
    button: 'Kirim',
  },
  discard: {
    header: 'Buang Perubahan?',
    body: 'Apakah Anda yakin ingin membuang perubahan yang belum disimpan?',
    button: 'Buang',
  },
}
