import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Paper} from '@material-ui/core'
import {CalendarContainer} from '../../leave/LeavePageStyles'
import ModalCalendarSchedule from './detail-calendar/ModalCalendarSchedule'
import {momentLocalizer} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import moment from 'moment'
import {useQuery} from '@apollo/react-hooks'
import ModalDetailCalendar from './detail-calendar/ModalDetailCalendar'
import {
  GET_LEAVE_ACTIVITY_CALENDAR,
  GET_LEAVE_SETTINGS_PARENT,
  GET_HOLIDAY_CALENDAR,
} from '../../../../graphql/queries'
import CalendarToolbar from './CalendarToolbar'
import Year from './customize-filter/Year'
import Schedule from './customize-filter/Schedule'

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
  },
  chip_root: {
    display: 'flex',
    padding: '10px',
    justifyContent: 'left',
    flexWrap: 'wrap',
    width: '100%',
  },
  selectFilter: {marginRight: 12},
  header: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: '14px',
    color: '#a3a6b4',
    border: '0px',
  },
}))

export default function LeaveCalendar() {
  const localizer = momentLocalizer(moment)
  const baseDate = moment()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [openHoliday, setOpenHoliday] = useState(false)
  const [startDate, setStartDate] = useState(
    baseDate.startOf('year').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(
    baseDate.endOf('year').format('YYYY-MM-DD')
  )
  const [selectedData, setSelectedData] = useState(undefined)
  const [excludeParent, setExcludeParent] = useState(undefined)
  const [selectedDataHoliday, setSelectedDataHoliday] = useState(undefined)
  const [leaveEvents, setLeaveEvents] = useState([])
  const [policies, setPolicies] = useState([])
  const [showChip, setShowChip] = useState(true)
  const [selectedChildren, setSelectedChildren] = useState([])

  const where = React.useMemo(() => {
    return {
      status: {_eq: 'approved'},
      calendar_id: {_is_null: true},
      datestart: {_gte: startDate, _lte: endDate},
      time_leave_policy_child: {
        parent: {
          _in: selectedChildren.length > 0 ? selectedChildren : undefined,
        },
        time_leave_policy: {
          id: {
            _nin:
              excludeParent && excludeParent.length > 0
                ? excludeParent
                : undefined,
          },
        },
      },
    }
  }, [startDate, endDate, selectedChildren, excludeParent])

  const {data, error} = useQuery(GET_LEAVE_ACTIVITY_CALENDAR, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: where,
    },
  })

  const {
    data: dataHoliday,
    error: errorHoliday,
    refetch: refechHoliday,
  } = useQuery(GET_HOLIDAY_CALENDAR, {
    fetchPolicy: 'cache-and-network',
    variables: {
      from: startDate,
      to: endDate,
    },
  })

  const {data: dataSettings, error: errorSetting} = useQuery(
    GET_LEAVE_SETTINGS_PARENT,
    {
      wlb_skipPatch: true,
      onCompleted: ({time_leave_policies}) => {
        if (!time_leave_policies) {
          return
        }

        const holiday = {
          id: 0,
          name: 'Holiday',
          calc_method: 'holiday',
          label_color: 'red',
          text_color: '#fff',
        }
        setPolicies([...time_leave_policies, holiday])
        setSelectedChildren(time_leave_policies.map(({id}) => id))
      },
    }
  )

  useEffect(() => {
    if (data && dataHoliday && dataSettings) {
      const leaves = data.people_work_leaves
      const policies = dataSettings.time_leave_policies
      const dataHolidays = dataHoliday.time_holidays
      let leaveByDates = leaves.map(leave => leave.datestart)
      leaveByDates = [...new Set(leaveByDates)]
      let populatedLeaveByDates = leaveByDates.map(date => {
        return {
          date: date,
          unique_parents: [
            ...new Set(
              leaves.map(leave => {
                if (leave.datestart === date) {
                  return leave.time_leave_policy_child.parent
                }
              })
            ),
          ].filter(item => item),
        }
      })

      const foundUnique = []
      populatedLeaveByDates = populatedLeaveByDates.filter(item => {
        if (item.unique_parents.length > 0) {
          item.unique_parents = item.unique_parents.filter((parent, index) => {
            if (index === 0) return parent
            else {
              foundUnique.push({
                date: item.date,
                unique_parents: [parent],
              })
            }
          })
        }
        return item
      })

      populatedLeaveByDates = [...populatedLeaveByDates, ...foundUnique]
      populatedLeaveByDates = populatedLeaveByDates.map(item => {
        const selectedLeaves = []
        leaves.map(leave => {
          if (
            item.date === leave.datestart &&
            item.unique_parents[0] === leave.time_leave_policy_child.parent
          ) {
            selectedLeaves.push(leave)
          }
        })
        item.leaves = selectedLeaves
        policies.map(policy => {
          if (item.unique_parents[0] === policy.id) {
            item.label_color = policy.label_color
            item.text_color = policy.text_color
            item.id = policy.id
            item.title = policy.name
            item.allDay = true
            item.start = item.date
            item.end = item.start
            item.typeCalender = 'leave'
          }
        })
        return item
      })
      dataHolidays.map(holiday => {
        const data = {
          id: holiday.id,
          name: holiday.name,
          title: holiday.name,
          type: holiday.type,
          typeCalender: 'holiday',
          description: holiday.description,
          start: holiday.date,
          recurring: holiday.recurring,
          recur_time_x: holiday.recur_time_x,
          recur_end_opt: holiday.recur_end_opt,
          recur_end_date: holiday.recur_end_date,
          recur_end_x: holiday.recur_end_x,
          end: holiday.date,
          date: holiday.date,
          allDay: true,
          label_color: 'red',
          text_color: '#fff',
          leave_policy_id: holiday.leave_policy_id,
        }
        populatedLeaveByDates.push(data)
      })
      setLeaveEvents(populatedLeaveByDates)
    }
  }, [data, dataHoliday, dataSettings])

  const handleSelectEvent = event => {
    if (event.typeCalender !== 'holiday') {
      setSelectedData(event)
      setOpen(true)
    } else {
      setSelectedDataHoliday(event)
      setOpenHoliday(true)
    }
  }

  const handleChips = id => {
    if (id) {
      const _exclude = excludeParent || []
      const index = _exclude.indexOf(id)
      if (index !== -1) _exclude.splice(index, 1)
      else _exclude.push(id)
      setExcludeParent(_exclude.length === 0 ? undefined : _exclude)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setOpenHoliday(false)
  }

  const allViews = {
    day: true,
    week: true,
    month: true,
    year: Year,
    agenda: Schedule,
  }

  const colorHoliday = {
    backgroundColor: 'red',
    borderRadius: '5px',
    color: '#fff',
  }

  return (
    <Paper className={classes.paper}>
      <CalendarContainer
        components={{
          toolbar: props => (
            <CalendarToolbar
              setShowChip={setShowChip}
              setCurrentDate={date => {
                setStartDate(date?.startDate)
                setEndDate(date?.endDate)
              }}
              {...props}
            />
          ),
        }}
        localizer={localizer}
        events={leaveEvents}
        defaultDate={new Date()}
        startAccessor="start"
        endAccessor="end"
        defaultView="year"
        views={allViews}
        selectable
        messages={{year: 'Year'}}
        onSelectEvent={event => handleSelectEvent(event)}
        refetch={refechHoliday}
        eventPropGetter={event => {
          if (event.label_color) {
            return {
              style: {
                backgroundColor: event.label_color,
                color: event.text_color,
                borderRadius: '5px',
              },
            }
          }
          return {}
        }}
      />
      {showChip && (
        <div className={classes.chip_root}>
          {policies &&
            policies.map((policy, key) => {
              return (
                <div
                  key={key}
                  style={{
                    display: 'flex',
                    width: '20%',
                    marginBottom: '1rem',
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                  onClick={() => handleChips(policy.id)}
                >
                  <div
                    style={{
                      padding: 10,
                      borderRadius: '5px',
                      backgroundColor: selectedChildren.includes(policy.id)
                        ? policy.label_color
                        : '#a9a8a8',
                      textDecoration: excludeParent?.includes(policy.id)
                        ? 'line-through'
                        : 'none',
                    }}
                  />
                  <span
                    style={{
                      marginLeft: '1rem',
                      wordBreak: 'break-all',
                      marginRight: '1rem',
                    }}
                  >
                    {policy.name}
                  </span>
                </div>
              )
            })}
        </div>
      )}

      <ModalDetailCalendar
        open={open}
        event={selectedData}
        handleClose={handleClose}
      />
      <ModalCalendarSchedule
        refetch={refechHoliday}
        open={openHoliday}
        data={selectedDataHoliday}
        color={colorHoliday}
        handleClose={handleClose}
      />
      {error && JSON.stringify(error)}
      {errorHoliday && JSON.stringify(errorHoliday)}
      {errorSetting && JSON.stringify(errorSetting)}
    </Paper>
  )
}
