import React, {useState} from 'react'
import {
  ColWrapper,
  WorkingContent,
  HeaderSharedSection,
  TyphoSection,
  ContentSharedSection,
  ImgStyled,
  RowContentWrapper,
  WrapperStatus,
  WrapperStatusLeft,
  KeyRight,
  ValueRight,
} from '../DetailEmployeeStyles'
import {Avatar} from '@material-ui/core'
import moment from 'moment'
import {formatDistance} from 'date-fns'
import {dateSplitter} from '../utilsForDetails'
import {capitalize, accessRightSwitcher} from '../../../../../utils/helpers'
import Officemate from './data-modal-working/Officemate'
import {AttachmentItemHover} from '../../../../shared-components/attachment'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import {GET_WORKING_STATUS} from '../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'

const _generateDate = date => {
  if (date) {
    return moment(date).format('DD MMM YYYY')
  } else return '-'
}

export default function WorkingStatus({user, user_name}) {
  const [open, setOpen] = useState(false)

  const {data} = useQuery(GET_WORKING_STATUS, {
    wlb_skipPatch: true,
    fetchPolicy: 'no-cache',
    variables: {
      id: user,
    },
  })

  return (
    <>
      {data?.working?.map((res, i) => {
        let result = ''
        const issued = dateSplitter(res.start_date)
        if (res.end_date !== null) {
          const expired = dateSplitter(res.end_date)
          result = formatDistance(
            new Date(issued[0], issued[1], issued[2]),
            new Date(expired[0], expired[1], expired[2])
          )
        } else {
          result = formatDistance(
            new Date(issued[0], issued[1], issued[2]),
            new Date()
          )
        }

        const subordinate = res.company_job_profile?.subordinate || []

        const dataSubordinate = []
        subordinate.map(row => {
          row.people_work_placements.map(res => {
            dataSubordinate.push({
              title: row.title,
              ...res,
            })
          })
        })

        return (
          <ColWrapper key={i}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Avatar
                src={res?.company_logo?.logo}
                style={{width: 100, height: 100, borderRadius: 0}}
              />
              <WorkingContent>
                <HeaderSharedSection>
                  <TyphoSection title="true" bold="true">
                    {res.company_job_profile?.title}
                  </TyphoSection>
                  <TyphoSection grey="true" style={{lineHeight: '34px'}}>
                    {moment(res.start_date).format('ll')} -{' '}
                    {res.end_date
                      ? moment(res.end_date).format('ll')
                      : 'present'}{' '}
                    {result !== '' ? `(${result})` : null}
                  </TyphoSection>
                  <TyphoSection blue="true">
                    {res.company_job_profile?.company_organization?.name || '-'}
                  </TyphoSection>
                  <TyphoSection style={{color: '#014a62'}}>
                    {`bergabung sejak ${_generateDate(
                      res.join_date
                    )} (Pegawai Tetap Sejak ${_generateDate(
                      res.permanent_date
                    )})`}
                  </TyphoSection>
                  <TyphoSection>
                    Prapensiun sejak{' '}
                    {res.pre_retirement_date
                      ? _generateDate(res.pre_retirement_date)
                      : '-'}
                  </TyphoSection>
                </HeaderSharedSection>
              </WorkingContent>
            </div>
            <ContentSharedSection>
              <div>
                {res.company_job_profile?.supervisor ? (
                  <ColWrapper>
                    <TyphoSection title="true" mb="true">
                      Atasan Langsung
                    </TyphoSection>
                    <RowContentWrapper mb="true">
                      <ImgStyled
                        src={
                          res.company_job_profile?.supervisor
                            ?.people_work_placements?.[0]?.global_user?.avatar
                        }
                        alt="avatar"
                      />
                      <ColWrapper ml="true">
                        <TyphoSection bold="true">
                          {res.company_job_profile?.supervisor
                            ?.people_work_placements?.[0]?.global_user?.name ||
                            '-'}
                        </TyphoSection>
                        <TyphoSection>
                          {res.company_job_profile?.supervisor?.title || '-'}
                        </TyphoSection>
                      </ColWrapper>
                    </RowContentWrapper>
                  </ColWrapper>
                ) : null}
                {dataSubordinate.length > 0 && (
                  <ColWrapper>
                    <TyphoSection title="true" mb="true" mt="true">
                      Bawahan Langsung
                    </TyphoSection>

                    {dataSubordinate.length > 0
                      ? dataSubordinate.slice(0, 5).map((child, i) => {
                          return (
                            <RowContentWrapper key={i} mb>
                              <ImgStyled
                                src={child.global_user?.avatar || ''}
                              />
                              <ColWrapper ml>
                                <TyphoSection bold="true">
                                  {child.global_user?.name || '-'}
                                </TyphoSection>
                                <TyphoSection>
                                  {child.title || '-'}
                                </TyphoSection>
                              </ColWrapper>
                            </RowContentWrapper>
                          )
                        })
                      : '-'}
                    {dataSubordinate.length > 5 && (
                      <TyphoSection
                        style={{cursor: 'pointer'}}
                        onClick={() => setOpen(true)}
                        blue="true"
                      >
                        Tampilkan Lebih Banyak...
                      </TyphoSection>
                    )}
                  </ColWrapper>
                )}
              </div>
              <WrapperStatus>
                <WrapperStatusLeft>
                  <KeyRight>NIP</KeyRight>
                  <ValueRight grey="true">{res.regno}</ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Surat Ketetapan</KeyRight>
                  <ValueRight grey="true">
                    {res.reference ? res.reference : '-'}
                  </ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Email</KeyRight>
                  <ValueRight grey="true">{res.email}</ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Organisasi</KeyRight>
                  <ValueRight grey="true">
                    {res.company_job_profile?.company_organization?.name || '-'}
                  </ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Jabatan</KeyRight>
                  <ValueRight grey="true">
                    {res.company_job_profile?.company_employee_rank?.name ||
                      '-'}
                  </ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Jenis Kepegawaian</KeyRight>
                  <ValueRight grey="true">
                    {res.type ? capitalize(res.type) : '-'}
                  </ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Pengaturan Lokasi Kerja</KeyRight>
                  <ValueRight grey="true">
                    {res.type_place ? capitalize(res.type_place) : '-'}
                  </ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Pengaturan Waktu Kerja</KeyRight>
                  <ValueRight grey="true">
                    {res.type_time ? capitalize(res.type_time) : '-'}
                  </ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Kelas Jabatan</KeyRight>
                  <ValueRight grey="true">
                    {res.company_job_profile?.job_grade?.name || '-'}
                  </ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Pusat Pembiayaan</KeyRight>
                  <ValueRight grey="true">
                    {res.company_job_profile?.company_organization
                      ?.cost_center || '-'}
                  </ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Fasilitas</KeyRight>
                  <ValueRight grey="true">
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      {res.facilities && res.facilities.length > 0
                        ? res.facilities.map((res, i) => (
                            <span
                              style={{marginBottom: 11}}
                              key={`${res.id}-${i}`}
                            >
                              {res.name}
                            </span>
                          ))
                        : '-'}
                    </div>
                  </ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Hak Akses</KeyRight>
                  <ValueRight grey="true">
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      {res.company_job_profile?.roles
                        ? accessRightSwitcher(res.company_job_profile.roles)
                            .split(', ')
                            .map((res, i) => (
                              <span style={{marginBottom: 11}} key={`${i}`}>
                                {res}
                              </span>
                            ))
                        : ' - '}
                    </div>
                  </ValueRight>
                </WrapperStatusLeft>
                <WrapperStatusLeft>
                  <KeyRight>Lampiran</KeyRight>
                  <ValueRight
                    variant="div"
                    grey="true"
                    style={{maxWidth: '212px !important'}}
                  >
                    <div
                      style={{
                        flexFlow: 'row wrap',
                        display: 'flex',
                        flexDirection: 'unset',
                        justifyContent: 'space-between',
                        alignContent: 'space-between',
                      }}
                    >
                      {res.attachment?.length > 0
                        ? res.attachment.map((attachment, i) => (
                            <AttachmentItemHover
                              key={`${i}-itm`}
                              iconAction={
                                <VisibilityOutlinedIcon
                                  onClick={() =>
                                    window.open(
                                      attachment.link || attachment.url
                                    )
                                  }
                                  className="icon"
                                />
                              }
                              fileName={attachment.name}
                              url={attachment.url || attachment.link}
                            />
                          ))
                        : '-'}
                    </div>
                  </ValueRight>
                </WrapperStatusLeft>
              </WrapperStatus>
            </ContentSharedSection>
          </ColWrapper>
        )
      })}
      <Officemate
        dataWorking={data}
        open={open}
        user_name={user_name}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
