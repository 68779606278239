import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {PrimaryModalHeader} from '../../GlobalStyles'

const ExportInformationFailedPopup = ({
  title,
  reports,
  retry,
  setOpenInformationFailedPopup,
  openInformationFailedPopup,
}) => {
  const useStyles = makeStyles(() => ({
    content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
    actions: {paddingRight: 24, paddingBottom: 24},
  }))
  const classes = useStyles()

  return (
    <Dialog
      onClose={() => {
        setOpenInformationFailedPopup(false)
      }}
      open={openInformationFailedPopup}
      fullWidth
      maxWidth="sm"
    >
      <PrimaryModalHeader>
        <Typography variant="h6" style={{color: '#fff'}}>
          {title} Problem
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenInformationFailedPopup(false)
          }}
        >
          <Icon style={{color: '#fff'}}>close</Icon>
        </IconButton>
      </PrimaryModalHeader>
      <DialogContent className={classes.content}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div>
              <Typography variant="p">
                {title} Failed, please check the following problem:
              </Typography>
              <div style={{marginTop: '10px'}}>
                {reports?.failed?.length > 0 &&
                  reports.failed.map((val, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{
                          borderLeft: 'solid 1px #ef4d5e',
                          color: '#ef4d5e',
                          marginBottom: '5px',
                          paddingLeft: '5px',
                        }}
                      >
                        <Typography variant="p" display="inline">
                          {val.name} {val.desc}
                        </Typography>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color="primary"
          size="large"
          style={{fontSize: 12, marginLeft: 12}}
          variant="contained"
          onClick={() => {
            setOpenInformationFailedPopup(false)
            retry()
          }}
        >
          Try Again
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExportInformationFailedPopup
