import React from 'react'
import {withRouter, Link} from 'react-router-dom'
import navigationConfig from '../../configs/navigationConfig'
import navigationHoldingConfig, {
  holdingDashboardOnlyConfig,
} from '../../configs/navigationHoldingConfig'
import {Icon, makeStyles} from '@material-ui/core'
import {
  MenuItemContainer,
  ContentContainer,
  ContentMenuItem,
  ImgLogo,
  Logo,
  ContentWrapper,
} from './NavigationStyles'
import {ClickableIcon} from '../BaseStyles'
import clsx from 'clsx'
import {getCookie} from '../../utils/helpers'
import {
  MAIN_APP_URL,
  ENVIRONMENT,
  ROLES,
  ALLOWED_ROLES,
  COMPANY_LOGO,
  hasModule,
} from '../../utils/globals'

function closest(el, fn) {
  while (el) {
    if (fn(el)) return el
    el = el.parentNode
  }
}

const drawerWidth = 290

const useStyles = makeStyles(theme => ({
  hide: {
    display: 'none !important',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `${theme.spacing(7) + 1}px !important`,
    [theme.breakpoints.up('sm')]: {
      width: `${theme.spacing(8) + 1}px !important`,
    },
  },
}))

const Navigation = props => {
  const classes = useStyles()
  const {allModule, companyModule} = props
  const _pathname = props.location.pathname
  const handleToogle = e => {
    const check = e.target.parentNode
    // const openCheck = document.quer ySelector('.open')
    if (check.classList.contains('has-children')) {
      const menuEl = closest(e.target, function(el) {
        if (el.classList.contains('has-children')) {
          return el.classList.contains('has-children')
        }
      })
      if (menuEl) menuEl.classList.toggle('open')
    } else {
      // if (openCheck && !check.classList.contains('has-children')) {
      //   document.querySelector('.open').classList.remove('open')
      // }
      // check.classList.toggle('open')
    }
  }

  const {open, handleClickButton, handleHoverDrawer, handleLeaveDrawer} = props
  const isDirectory = _pathname.includes('/directory') && !props.location.state
  const checkRole = ROLES.filter(element => ALLOWED_ROLES.includes(element))

  const currentRole = getCookie('currentRole')

  const isHolding =
    currentRole === 'holding-administrator' ||
    holdingDashboardOnlyConfig[0].url === _pathname ||
    navigationHoldingConfig.some(
      parent =>
        parent.url === _pathname ||
        parent.children?.some(child => _pathname.includes(child.url))
    )

  const navigationData = isHolding
    ? !hasModule(companyModule, 'holding')
      ? holdingDashboardOnlyConfig
      : navigationHoldingConfig
    : navigationConfig(companyModule, allModule)

  if (ENVIRONMENT !== 'development' && !checkRole) {
    window.location = MAIN_APP_URL
  }

  const activeStyle = {
    color: 'white',
    pointerEvents: 'none',
    backgroundColor: '#003d51',
    borderRadius: '0 20px 20px 0',
    marginLeft: 0,
    paddingLeft: 55,
  }

  const activeIconStyle = {
    color: 'white',
    marginLeft: 10,
  }

  return (
    <>
      <MenuItemContainer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        onMouseEnter={handleHoverDrawer}
        onMouseLeave={handleLeaveDrawer}
      >
        <ContentContainer className="content">
          {COMPANY_LOGO ? (
            <Logo>
              <img
                src={COMPANY_LOGO}
                alt="logo"
                // width={open ? 60 : 32}
                height={open ? 42 : 32}
              />
            </Logo>
          ) : (
            <Logo>
              <img
                src={require('../../assets/slicing/w_logo.svg')}
                alt="logo"
                width={open ? 60 : 32}
                height={open ? 39 : 32}
              />
              <ImgLogo
                src={require('../../assets/slicing/text_logo.svg')}
                alt="text-logo"
                height={31}
                className={clsx({
                  [classes.hide]: !open,
                })}
              ></ImgLogo>
            </Logo>
          )}

          <ClickableIcon
            onClick={handleClickButton}
            className={clsx({
              [classes.hide]: !open,
            })}
          >
            menu
          </ClickableIcon>
        </ContentContainer>
        <ContentWrapper>
          {/* <ContentMenu>
            {open ? (
              <p>Applications</p>
            ) : (
              <Divider style={{width: 16, height: 2}}></Divider>
            )}
          </ContentMenu> */}
          <ContentMenuItem style={{marginTop: '10px'}}>
            <ul className="collapsible-menu">
              {navigationData.map((val, key) => {
                let pathname = ''
                let state = {}
                if (props.location.state) {
                  state = props.location.state
                  pathname = _pathname
                }

                if (val.visible) {
                  return (
                    <li
                      key={key}
                      className={val.children ? 'has-children' : ''}
                    >
                      {/^https?:\/\//.test(val.url) ? (
                        <a href={val.url || '#'} onClick={handleToogle}>
                          <span>
                            <Icon
                              className="list-item-icon flex-no-shrink icon-sidebar"
                              color="action"
                            >
                              {val.icon}
                            </Icon>
                          </span>
                          {open ? val.title : ''}

                          <i></i>
                        </a>
                      ) : (
                        <Link
                          to={{
                            pathname: val.url || pathname,
                            state: isDirectory
                              ? {
                                  ...state,
                                  searchable: true,
                                }
                              : state,
                          }}
                          onClick={handleToogle}
                          style={
                            val.url && val.url === _pathname
                              ? activeStyle
                              : null
                          }
                        >
                          <span>
                            <Icon
                              className="list-item-icon flex-no-shrink icon-sidebar"
                              color="action"
                              style={
                                val.url && val.url === _pathname
                                  ? activeIconStyle
                                  : null
                              }
                            >
                              {val.icon}
                            </Icon>
                          </span>
                          {open ? val.title : ''}
                          <i></i>
                        </Link>
                      )}
                      {val.children && (
                        <ul
                          className={clsx({
                            [classes.hide]: !open,
                          })}
                        >
                          {val.children &&
                            val.children.map((child, k) => {
                              const thisUrl = props.history.location.pathname
                              const childUrl = child.url
                              const thisMainUrl = thisUrl.split('/')[2]
                              const thisChildMainUrl = childUrl.split('/')[2]

                              if (child.visible) {
                                return (
                                  <li key={k}>
                                    {/* <Link
                                    to={{
                                      pathname: child.url,
                                      state: {active: 'activity'},
                                    }}
                                    onClick={handleToogle}
                                  >
                                    {child.title}
                                    <i></i>
                                  </Link> */}
                                    {/^https?:\/\//.test(child.url) ? (
                                      <a href={child.url}>
                                        {child.title}
                                        <i></i>
                                      </a>
                                    ) : (
                                      <Link
                                        to={{
                                          pathname: child.url,
                                          state: {
                                            active: child.state,
                                            searchable: !child.hideSearch,
                                          },
                                        }}
                                        style={
                                          thisChildMainUrl &&
                                          thisChildMainUrl === thisMainUrl
                                            ? activeStyle
                                            : null
                                        }

                                        // onClick={handleToogle}
                                      >
                                        {child.title}
                                        <i></i>
                                      </Link>
                                    )}
                                  </li>
                                )
                              }
                            })}
                        </ul>
                      )}
                    </li>
                  )
                }
              })}
            </ul>
          </ContentMenuItem>
        </ContentWrapper>
      </MenuItemContainer>
    </>
  )
}
export default withRouter(Navigation)
