import gql from 'graphql-tag'

export const GET_LEAVE_BALANCE_DETAIL = gql`
  query getLeaveSettings(
    $job_profiles: [Int!]
    $id: Int!
    $company: uuid
    $gender: enum_gender_comparison_exp
    $limit: Int
    $offset: Int
    $date_start_leave: date
    $date_end_leave: date
    $period_year: [Int!]
  ) {
    total: people_work_placements_aggregate(
      where: {
        job_profile: {_in: $job_profiles}
        status: {_eq: "ACTIVE"}
        global_user: {people_profiles: {gender: $gender}}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        job_profile: {_in: $job_profiles}
        status: {_eq: "ACTIVE"}
        company: {_eq: $company}
        global_user: {people_profiles: {gender: $gender}}
      }
      order_by: {global_user: {name: asc}}
      limit: $limit
      offset: $offset
    ) {
      id
      join_date
      regno
      global_user {
        id
        name
        employment_date: people_work_placements_aggregate(
          where: {company: {_eq: $company}, status: {_eq: "ACTIVE"}}
        ) {
          aggregate {
            min {
              start_date
            }
          }
        }
      }
      people_work_leave_quotas(
        where: {
          leave_subpolicy_id: {_eq: $id}
          period_year: {_in: $period_year}
        }
        order_by: {id: desc}
        limit: 1
      ) {
        id
        time_leave_policy_child {
          id
          time_leave_policy {
            id
            calc_method
          }
        }
        quota
        carry_over
        taken_current
        taken_carry_over
        monthly_accrual_quota
        max_monthly_accrual_quota
      }
      people_work_leaves_aggregate(
        where: {
          status: {_in: ["pending", "confirm1"]}
          policy_id: {_eq: $id}
          datestart: {_lte: $date_end_leave}
          dateend: {_gte: $date_start_leave}
        }
      ) {
        aggregate {
          sum {
            quota_planned: quota_taken
          }
        }
      }
    }
    time_leave_policy_children(where: {id: {_eq: $id}}) {
      id
      name
      infinite_quota
      quota
      time_leave_policy {
        id
        name
        leftover_valid
        leftover_valid_until_month
        calc_method
        quota_prorate_option
        quota_reset_option
        quota_reset_option_date
        quota_reset_option_month
        quota_effective_after_month
      }
    }
  }
`
