import React, {useState, useEffect} from 'react'
import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'
import Activity from './activity/ActivityComponent'
import ActivityDetailComponent from './activity/ActivityDetailComponent'
import ActivityDetailDateComponent from './activity/ActivityDetailDateComponent'
import ActivityUploadComponent from './activity/ActivityUploadComponent'
import FormWorkSchedule from './work-schedule/FormWorkScheduleComponent'
import WorkSchedule from './work-schedule/WorkScheduleComponent'
// import AttendanceNumber from './attendance-number/AttendanceNumberComponent'
// import AttendanceNumberUpload from './attendance-number/AttendanceNumberUploadComponent'
import AttendanceReport from './attendance-report/AttendanceReportComponent'
import LocationRestriction from './location-restrictions/LocationRestrictionComponent'
import LocationRestrictionAdd from './location-restrictions/LocationRestrictionAddComponent '
import LocationRestrictionEdit from './location-restrictions/LocationRestrictionEditComponent'
import MobileAttendanceSetting from './setting/MobileAttendanceSettingComponent '
import {withRouter} from 'react-router-dom'
import {Filter} from '@worklifebeyond/wlb-utils-components'
import {anchorOrigin, MENUS, transformOrigin} from './filter'
import {
  ButtonFilter,
  BlueIcon,
  FilterText,
  PageContainer,
} from '../../../../src/GlobalStyles'
import {setterFilter} from '../../../utils/helpers'
import searchFilters from '../../../utils/searchFilters'

const renderComponent = (
  activeComponent,
  state,
  searchText = '',
  filterData
) => {
  switch (activeComponent) {
    case 'upload':
      return <ActivityUploadComponent />
    case 'detail':
      return (
        <ActivityDetailComponent
          data={state.data}
          profile={state.profile}
          initialStateData={state.initialStateData}
        />
      )
    case 'date-detail':
      return (
        <ActivityDetailDateComponent
          attendance={state.attendance}
          rootData={state.rootData}
          regulation={state.regulation}
          previousStateData={state.previousStateData}
        />
      )
    case 'work-schedule':
      return <WorkSchedule searchText={searchText} />
    case 'work-schedule-add':
      return <FormWorkSchedule />
    case 'work-schedule-edit':
      return (
        <FormWorkSchedule
          schedule={state.schedule}
          dataCheck={state.dataCheck}
        />
      )
    // case 'attendance-number':
    //   return <AttendanceNumber />
    // case 'attendance-number-upload':
    //   return <AttendanceNumberUpload />
    case 'attendance-report':
      return (
        <AttendanceReport searchText={searchText} filterData={filterData} />
      )
    case 'location-restrictions':
      return <LocationRestriction />
    case 'location-restrictions-add':
      return <LocationRestrictionAdd dataCheck={state.dataCheck} />
    case 'location-restrictions-edit':
      return (
        <LocationRestrictionEdit
          data={state.data}
          dataCheck={state.dataCheck}
        />
      )
    case 'mobile-attendance-setting':
      return <MobileAttendanceSetting />
    default:
      return <Activity filterData={filterData} searchText={searchText} />
  }
}

const AttendancePage = props => {
  const [filterData, setFilterData] = useState({})
  let activeTab = ''
  let activeMenu = ''

  let state = {}

  if (props.location.state) {
    activeMenu = props.location.state.active

    state = props.location.state
    switch (props.location.state.active) {
      case 'upload':
      case 'detail':
      case 'date-detail':
        activeTab = 'activity'
        break
      case 'work-schedule':
      case 'work-schedule-add':
      case 'work-schedule-edit':
        activeTab = 'work-schedule'
        break
      case 'attendance-number':
      case 'attendance-number-upload':
        activeTab = 'attendance-number'
        break
      case 'attendance-report':
      case 'attendance-report-download':
        activeTab = 'attendance-report'
        break
      case 'location-restrictions':
      case 'location-restrictions-add':
      case 'location-restrictions-edit':
        activeTab = 'location-restrictions'
        break
      case 'mobile-attendance-setting':
        activeTab = 'mobile-attendance-setting'
        break
      default:
        activeTab = props.location.state.active
    }
  }

  useEffect(() => {
    if (props.location.pathname === '/time/attendance') {
      props.history.push({
        state: {
          active: 'activity',
          searchable: true,
        },
      })
    }
  }, [])

  const data_tabs_1 = [
    {
      label: 'Activity',
      state: 'activity',
      url: '/time/attendance/activity',
      searchable: true,
    },
    {
      label: 'Work Schedule',
      state: 'work-schedule',
      url: '/time/attendance/work_schedule',
      searchable: true,
    },
    // {
    //   label: 'Attendance Number',
    //   state: 'attendance-number',
    //   url: '/time/attendance/attendance_number',
    // },
    {
      label: 'Attendance Report',
      state: 'attendance-report',
      url: '/time/attendance/attendance_report',
      searchable: true,
    },
  ]

  const data_tabs_2 = [
    {
      label: 'Mobile Attendance',
      state: 'mobile-attendance',
      url: '#',
      searchable: false,
    },
    {
      label: 'Location Restrictions',
      state: 'location-restrictions',
      url: '/time/attendance/location_restrictions',
      searchable: false,
    },
    // {
    //   label: 'Settings',
    //   state: 'mobile-attendance-setting',
    //   url: '/time/attendance/mobile_attendance_setting',
    // },
  ]

  // const data_tabs_3 = [
  //   {
  //     label: 'Fingerprint',
  //     state: 'fingerprint',
  //     url: '#',
  //   },
  //   {
  //     label: 'Settings',
  //     state: 'settings-fingerprint',
  //     url: '/time/attendance/settings_fingerprint',
  //   },
  // ]

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  const handleApply = result => setFilterData(setterFilter(result))

  return (
    <div>
      <Card
        endAdornment={
          MENUS.includes(activeTab) && (
            <Filter
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
              id="filter-attendance-wlb"
              onApply={handleApply}
              listFilter={searchFilters}
            >
              <ButtonFilter position="end">
                <BlueIcon>filter_list</BlueIcon>
                <FilterText variant="body2">Filter</FilterText>
              </ButtonFilter>
            </Filter>
          )
        }
        icon="access_time"
        title="Attendance"
        onSearch={onSearch}
      >
        <PageContainer>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <WlbTab data={data_tabs_1} activeMenu={activeTab} />
            <div style={{marginTop: 30}}>
              <WlbTab
                data={data_tabs_2}
                activeMenu={activeTab}
                disableFirstElement={true}
              />
            </div>
          </div>
          {renderComponent(activeMenu, state, searchText, filterData)}
        </PageContainer>
      </Card>
    </div>
  )
}
export default withRouter(AttendancePage)
