import {Dialog, IconButton, Typography} from '@material-ui/core'
import React from 'react'
import {
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
} from '../../../../GlobalStyles'

const SeeAll = ({handleClose, open, type, table}) => {
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <ModalHeader>
        <Typography variant="body1">{type}</Typography>
        <IconButton onClick={handleClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContentEvent>{table}</ModalContentEvent>
    </Dialog>
  )
}

export default SeeAll
