import gql from 'graphql-tag'

export const GET_PLACEMENT_EMP_BY_ID = gql`
  query($id: Int, $user: uuid) {
    people_work_placements(where: { id: { _eq: $id } }) {
      id
      regno
      reference
      reminder
      people_work_salaries(order_by : {start_date : asc_nulls_last}) {
        id
        value
        start_date
        end_date
        active
      }
      global_user {
        id
        name
        avatar
        email
        people_profiles {
          id
          gender
          birthdate
          global_religion {
            id
            name
          }
        }
        phone: people_phones(
          where: { user: { _eq: $user }, deletedAt: { _is_null: true } }
          order_by: [{ is_main: desc_nulls_last, id: asc }]
          limit: 1
        ) {
          id
          country_code
          phone
        }
        address: people_addresses(
          where: { user: { _eq: $user }, deletedAt: { _is_null: true } }
          order_by: [{ is_main: desc_nulls_last, id: asc }]
          limit: 1
        ) {
          id
          address
          postal_code
          is_main
          global_province {
            id
            name
          }
          global_city {
            id
            name
          }
        }
      }
      company_job_profile {
        id
        title
        organization
        position
        rank
        office_address
        roles
        job_profile_supervisor {
          id
          people_work_placements(order_by: [{ id: asc }], limit: 1, offset: 0) {
            id
            user
          }
        }
      }
      type
      type_time
      type_place
      join_date
      start_date
      end_date
      current_salary
      status
      attachment
    }
  }
`