import React from 'react'
import {Grid, Button, Divider, Typography} from '@material-ui/core'
import {Flex, PaperContainer, EnhancedToolbar} from '../../../../GlobalStyles'
import helperStyle, {
  ProfileText,
  LabelWrapper,
  DoughnutNoData,
} from '../LearningProfileStyle'
import {Doughnut} from 'react-chartjs-2'

const DoughnutOptions = {
  tooltips: {
    enabled: false,
  },
}

const DoughnutComponent = props => {
  const {title, data, handleDownload} = props
  const classes = helperStyle()

  const backgroundData = ['#039be5', '#4caf50', '#ffa000', '#ef4d5e']
  const labelData = ['Course', 'E-Book', 'Audio Book', 'Social Learning']

  const dataDoughnut = {
    datasets: [
      {
        data: data,
        backgroundColor: backgroundData,
      },
    ],
  }

  const _renderLabel = val => {
    const _labelStyle = {
      width: '12px',
      height: '12px',
      border: `solid 3px ${val}`,
      borderRadius: '50%',
      marginRight: '10px',
    }
    return <span style={_labelStyle}></span>
  }

  const _renderDuration = val => {
    const result = val / 60
    return `${result.toFixed(2)} m`
  }

  return (
    <Grid item xs={6} sm={4}>
      <PaperContainer>
        <EnhancedToolbar>
          <ProfileText fweight="bold" fcolor="blue" fsize="14px">
            {title}
          </ProfileText>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() =>
              handleDownload(
                title === 'Learning Duration'
                  ? 'learning_duration'
                  : title === 'Learning Material'
                  ? 'learning_material'
                  : 'teach_duration'
              )
            }
          >
            Download
          </Button>
        </EnhancedToolbar>
        <Divider className={classes.mb25} />
        {data[0] === 0 && data[1] === 0 && data[2] === 0 ? (
          <DoughnutNoData>
            <ProfileText fweight="bold">No Data</ProfileText>
          </DoughnutNoData>
        ) : (
          <Doughnut data={dataDoughnut} options={DoughnutOptions} />
        )}

        <div style={{padding: '40px 0px'}}>
          {labelData.map((val, key) => {
            return (
              <LabelWrapper key={key}>
                <Flex>
                  {_renderLabel(backgroundData[key])}
                  <ProfileText fweight="bold">{val}</ProfileText>
                </Flex>
                <ProfileText fcolor="grey">
                  {title === 'Learning Material'
                    ? data[key]
                    : _renderDuration(data[key])}
                </ProfileText>
              </LabelWrapper>
            )
          })}
        </div>
      </PaperContainer>
    </Grid>
  )
}

export default DoughnutComponent
