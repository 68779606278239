import gql from "graphql-tag";

export const UPDATE_ABOUT = gql`
  mutation(
    $id: uuid!
    $name: String!
    $gender: enum_gender!
    $birthDate: date!
    $religion: Int!
    $maritalStatus: enum_marital_status!
    $data: jsonb!
  ) {
    update_global_users(_set: { name: $name }, where: { id: { _eq: $id } }) {
      affected_rows
    }
    update_people_profile(
      _set: {
        gender: $gender
        birthdate: $birthDate
        religion: $religion
        marital_status: $maritalStatus
      }
      _append: { profile_fields: $data }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
