import gql from 'graphql-tag'

export const GET_DROPDOWN_POSITION_ATT = gql`
  query($user: uuid!) {
    people_work_placements(
      where: {user: {_eq: $user}}
      distinct_on: [job_profile]
      order_by: [{job_profile: desc}, {id: desc}]
    ) {
      id
      status
      company_job_profile {
        id
        code
        title
      }
    }
  }
`
