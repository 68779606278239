import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import ConfiguringList from './ConfiguringList'
import {PageContainer} from '../../../GlobalStyles'

import FormVersionGroup from './form/FormVersion'
import FormCategory from './form/category/FormCategory'
import FormCluster from './form/cluster/FormCluster'
import FormClass from './form/class/FormClass'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'group-configuring':
      return <ConfiguringList state={state} searchText={searchText} />
    case 'add-version':
      return <FormVersionGroup />
    case 'add-category':
    case 'edit-category':
      return <FormCategory />
    case 'add-cluster':
    case 'add-subcluster':
    case 'edit-cluster':
    case 'edit-subcluster':
      return <FormCluster />
    case 'add-class':
    case 'edit-class':
      return <FormClass />
    case 'detail-version':
      return <FormVersionGroup />
    default:
      return <ConfiguringList state={state} searchText={searchText} />
  }
}

const ConfiguringPageComponent = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <div>
      <Card icon="location_city" title="Configuring" onSearch={onSearch}>
        <PageContainer>
          {renderComponent(active, state, searchText)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default ConfiguringPageComponent
