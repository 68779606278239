import gql from 'graphql-tag'

export const GET_REGULATION = gql`
  query getRegulation($id: uuid!, $key: String, $offset: Int, $limit: Int) {
    total: company_regulations_aggregate(
      where: {
        company: {_eq: $id}
        _or: [{name: {_ilike: $key}}, {description: {_ilike: $key}}]
      }
    ) {
      aggregate {
        count
      }
    }

    company_regulations(
      where: {
        company: {_eq: $id}
        _or: [{name: {_ilike: $key}}, {description: {_ilike: $key}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      description
      company_regulation_clauses(order_by: {name: asc}) {
        id
        name
        content
        date_added
      }
    }
  }
`
