import React, {useState} from 'react'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  WrapperContent,
} from '../../OrganizationPageStyles'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
  PaperContainer,
  EnhancedToolbar,
} from '../../../../GlobalStyles'
import {
  Icon,
  IconButton,
  Button,
  Divider,
  TextField,
  FormControlLabel,
  Switch,
  FormHelperText,
} from '@material-ui/core'
import Select from 'react-select'
import {Link, withRouter} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  ADD_ORGANIZATION,
  EDTT_ORGANIZATION,
  SET_NEW_PARENT_ORG,
} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {
  GET_LIST_ORGANIZATION,
  DROPDOWN_LEVEL,
  CHECK_CODE_ORGANIZATION,
  GET_ORGUNIT_CHILD_COUNT,
} from '../../../../graphql/queries'

import AddEditConfirm from '../../../shared-components/popup/AddEditPopup'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import NewParentPopup from '../../../shared-components/popup/NewParentPopup'
import {COMPANY_ID} from '../../../../utils/globals'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import FieldInformationTooltip from '../../../shared-components/tooltip/FieldInformationTooltip'

const LINE_TYPE_OPTIONS = [
  {value: 1, label: 'Garis Solid'},
  {value: 2, label: 'Garis Putus - Putus'},
]

const AddEditOrgUnit = props => {
  const {status, data, idParentOUJobProfile, idParentTopOU} = props
  const {enqueueSnackbar} = useSnackbar()

  const statusParent = data && !data.organization_parent

  const hasJobProfile =
    data &&
    data.company_job_profiles_aggregate &&
    data.company_job_profiles_aggregate.aggregate &&
    data.company_job_profiles_aggregate.aggregate.count > 0

  const [isParent, setIsParent] = useState(
    status === 'Edit' ? statusParent : false
  )

  const classes = helperStyle()

  // const dataActing =
  //   data && data.acting_as
  //     ? {
  //         label: data.acting_as.name,
  //         value: data.acting_as.id,
  //       }
  //     : []
  const [allDataFrom, setAllDataFrom] = useState(() => {
    const lines =
      data && LINE_TYPE_OPTIONS.find(itm => itm.value === data.line_type)

    return {
      code: data?.code || '',
      name: data?.name || '',
      parent: data?.organization_parent?.id || null,
      organization_level_id: data?.organization_level?.id || null,
      as_assistant: data?.as_assistant || false,
      line_type: lines ? lines.value : LINE_TYPE_OPTIONS[0].value,
    }
  })

  const [variable, setVariable] = useState({})

  const [isError, setIsError] = useState(false)

  const [parentDropdown, setParentDropdown] = useState(() => {
    if (!data?.organization_parent) {
      return null
    }

    return {
      value: data.organization_parent.id,
      label: `${data.organization_parent.code} - ${data.organization_parent.name}`,
    }
  })
  const [levelDropdown, setLevelDropdown] = useState(() => {
    if (!data?.organization_level) {
      return null
    }

    return {
      label: `${data.organization_level.code} - ${data.organization_level.name}`,
      value: data.organization_level.id,
    }
  })
  const [lineDropdown, setLineDropdown] = useState(() => {
    return (
      (data && LINE_TYPE_OPTIONS.find(itm => itm.value === data.line_type)) ||
      LINE_TYPE_OPTIONS[0]
    )
  })

  // const [actingAsDropdown, setActingAsDropdown] = useState(dataActing)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  // const [openCantEdit, setOpenCantEdit] = useState(false)
  const [searchDropdown, setSearchDropdown] = useState({
    level: '',
    parent: '',
  })
  const [popupNewParent, setPopupNewParent] = useState({
    idOrg: null,
    openPopup: false,
  })

  const [dialogCancel, setDialogCancel] = useState(false)
  const [errorParent, setErrorParent] = useState(false)

  const [addData] = useMutation(ADD_ORGANIZATION)
  const [editData] = useMutation(EDTT_ORGANIZATION)
  const [EditNewParent] = useMutation(SET_NEW_PARENT_ORG)

  const addDataParent = event => {
    setParentDropdown({
      label: event.label,
      value: event.value,
    })
  }

  const openDialogCancel = () => {
    setDialogCancel(true)
  }

  const {
    data: parentList,
    loading: parentListLoad,
    error: parentListError,
  } = useQuery(GET_LIST_ORGANIZATION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      company: COMPANY_ID,
      search: `%${searchDropdown.parent}%`,
      offset: 0,
      limit: 30,
      exclude_orgs: data ? [data.id] : null,
    },
  })

  const {
    data: checkCodeOrg,
    loading: checkCodeOrgLoad,
    error: checkCodeOrgError,
  } = useQuery(CHECK_CODE_ORGANIZATION, {
    fetchPolicy: 'cache-and-network',
    skip: !allDataFrom.code,
    variables: {
      company: COMPANY_ID,
      code: allDataFrom.code,
    },
  })

  const {data: level, loading: levelLoad, error: levelError} = useQuery(
    DROPDOWN_LEVEL,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        companyId: COMPANY_ID,
        search: `%${searchDropdown.level}%`,
        offset: 0,
        limit: 30,
      },
    }
  )
  const {
    data: childCount,
    loading: childCountLoad,
    error: childCountError,
  } = useQuery(GET_ORGUNIT_CHILD_COUNT, {
    fetchPolicy: 'cache-and-network',
    skip: isParent || !parentDropdown,
    variables: {
      organizationId: parentDropdown?.value,
    },
  })

  if (parentListError || levelError || checkCodeOrgError || childCountError) {
    console.error(
      parentListError || levelError || checkCodeOrgError || childCountError
    )

    return (
      <PaperContainer>
        <div style={{padding: 8}}>Something went wrong.</div>
      </PaperContainer>
    )
  }

  const isOnlyChild =
    childCount && childCount.organization.suborganizations.aggregate.count <= 1

  const isUnsuitableAssistant =
    (!data || !data.as_assistant) && !allDataFrom.as_assistant && isOnlyChild

  const parentOptions = parentList?.company_organizations.map(item => ({
    value: item.id,
    label: `${item.code} - ${item.name}`,
  }))

  const levelOptions = level?.company_organization_levels.map(opt => ({
    value: opt.id,
    label: `${opt.code} - ${opt.name}`,
  }))

  // const actingsAs =
  //   parent &&
  //   parent.company_organizations.map(opt => ({
  //     value: opt.id,
  //     label: opt.name,
  //   }))

  // const nameChecker =
  //   parent &&
  //   parent.company_organizations &&
  //   parent.company_organizations.some(
  //     v => v.name.toLowerCase() === allDataFrom.name.toLowerCase()
  //   )

  const codeChecker =
    checkCodeOrg &&
    checkCodeOrg.company_organizations_aggregate &&
    checkCodeOrg.company_organizations_aggregate.aggregate &&
    checkCodeOrg.company_organizations_aggregate.aggregate.count > 0

  // const checkSameNameWithAdd = data && data.name === allDataFrom.name
  const checkSameCodeWithAdd =
    allDataFrom && allDataFrom.code && allDataFrom.code.length >= 1
      ? (data && data.code) === (allDataFrom && allDataFrom.code)
      : false

  const parentChecker =
    parentList &&
    parentList.company_organizations &&
    parentList.company_organizations.some(v => v.organization_parent === null)

  const checkParent = data && data.organization_parent === null

  const parentName =
    parentList &&
    parentList.company_organizations &&
    parentList.company_organizations.find(v => v.organization_parent === null)

  const openDialogConfirm = () => {
    // if (parentChecker || switchChecked) {
    if (
      !allDataFrom.name ||
      !allDataFrom.code ||
      allDataFrom.name.length > 100 ||
      allDataFrom.code.length > 30 ||
      !allDataFrom.organization_level_id ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
      // ||(nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 2000)
    } else if (!isParent && !allDataFrom.parent) {
      handleErrorParent()
    } else {
      dialogConfirm ? setDialogConfirm(false) : setDialogConfirm(true)
    }
    // } else {
    //   setOpenCantEdit(!openCantEdit)
    // }
  }

  const handleSetNewParent = () => {
    if (popupNewParent.openPopup) {
      setPopupNewParent({
        idOrg: null,
        openPopup: false,
      })
      setIsParent(false)
    } else {
      setPopupNewParent({
        idOrg: data && data.id,
        openPopup: true,
      })
    }
  }

  const handleCancel = status => {
    dialogCancel ? setDialogCancel(false) : setDialogCancel(true)
    if (dialogCancel && status !== 'notNow') {
      props.history.goBack()
    }
  }

  const handleAddOrg = () => {
    if (status === 'Add') {
      const dataForAdd = {
        objects: [allDataFrom],
      }
      addData({variables: dataForAdd})
        .then(() => {
          enqueueSnackbar('Organization Unit Added', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          props.history.push({
            pathname: '/organization/organization-unit',
            state: {
              active: 'organization-unit',
              searchable: true,
            },
          })
        })
        .catch(() => {
          enqueueSnackbar(
            'Added Organization Unit failed, please try again later',
            {
              variant: 'error',
              autoHideDuration: 1000,
            }
          )
        })
    } else if (status === 'Edit') {
      const dataForEdit = {
        id: data && data.id,
        code: allDataFrom && allDataFrom.code,
        name: allDataFrom.name,
        parent: allDataFrom.parent,
        // acting_as_id: allDataFrom.acting_as_id,
        organization_level_id: allDataFrom.organization_level_id,
        companyId: COMPANY_ID,
        as_assistant: allDataFrom.as_assistant,
        line_type: allDataFrom.line_type,
      }
      EditNewParent({variables: variable})
        .then(() => {
          editData({variables: dataForEdit})
            .then(() => {
              enqueueSnackbar('Organization Unit Edited', {
                variant: 'success',
                autoHideDuration: 1000,
              })
              props.history.push({
                pathname: '/organization/organization-unit',
                state: {
                  active: 'organization-unit',
                  searchable: true,
                },
              })
            })
            .catch(() => {
              enqueueSnackbar(
                'Edit Organization Unit failed, please try again later',
                {
                  variant: 'error',
                  autoHideDuration: 1000,
                }
              )
            })
        })
        .catch(() => {
          enqueueSnackbar('Set New Parent failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const selectStyles = {
    menu: provided => ({...provided, zIndex: 9999}),
    container: provided => ({
      ...provided,
      marginTop: 16,
      marginBottom: 8,
    }),
  }

  const handleErrorParent = () => {
    setErrorParent(prev => !prev)
  }

  return (
    <OrganizationContainer>
      <PaperContainer style={{minHeight: '77vh'}}>
        <EnhancedToolbar className={classes.paddingLeft0}>
          <WrapperContent>
            <Link
              to={{
                pathname: '/organization/organization-unit',
                state: {active: 'organization-unit', searchable: true},
              }}
              style={{textDecoration: 'none'}}
            >
              <IconButton
                edge="end"
                aria-label="back"
                // onClick={openDialogCancel}
              >
                <Icon>arrow_back</Icon>
              </IconButton>
            </Link>
            <BigTextBlackBold className={classes.marginLeft10}>
              {`${status === 'Add' ? 'Tambah' : 'Ubah'} Unit Organisasi`}{' '}
              {status === 'Edit' && data && `- ${data.name}`}
            </BigTextBlackBold>
          </WrapperContent>
          <div>
            <Button
              size="large"
              className={classes.backButton}
              onClick={openDialogCancel}
            >
              Batal
            </Button>
            <Button
              className={classes.marginLeft10}
              variant="contained"
              color="primary"
              size="large"
              disabled={checkCodeOrgLoad || childCountLoad}
              onClick={openDialogConfirm}
            >
              Simpan
            </Button>
          </div>
        </EnhancedToolbar>
        <Divider />
        <OrganizationContainer>
          <FormContainer id="top">
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>1.</TitleNumber>
                <TitleName>Level Organisasi *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <Select
                  id="parent"
                  options={levelOptions}
                  placeholder="Pilih Level Organisasi"
                  value={levelDropdown}
                  isLoading={levelLoad}
                  onInputChange={dataSearchDropdown =>
                    setSearchDropdown({
                      ...searchDropdown,
                      level: dataSearchDropdown,
                    })
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      organization_level_id: event.value,
                    })
                    setLevelDropdown(event)
                  }}
                  styles={selectStyles}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: '#bfbfbf', // Placeholder color
                    },
                  })}
                />

                {isError && !allDataFrom.organization_level_id && (
                  <FormHelperText error>Bagian ini diperlukan</FormHelperText>
                )}
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>2.</TitleNumber>
                <TitleName>Kode Unit Organisasi *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgCode"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="Tambah Kode Unit Organisasi"
                  value={allDataFrom.code}
                  margin="normal"
                  className={classes.helperMarginLeft0}
                  error={
                    (!allDataFrom.code && isError) ||
                    (allDataFrom.code && allDataFrom.code.length > 30) ||
                    (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
                  }
                  helperText={
                    !allDataFrom.code && isError
                      ? 'Bagian ini diperlukan'
                      : (codeChecker
                        ? !checkSameCodeWithAdd
                        : checkSameCodeWithAdd)
                      ? 'Kode Unit Organisasi sudah ada'
                      : allDataFrom.code && allDataFrom.code.length > 30
                      ? 'Jumlah karakter maksimum adalah 30 karakter'
                      : 'Kode Jenis Jabatan harus unik'
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      code: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>3.</TitleNumber>
                <TitleName>Nama Organisasi *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgName"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="Tambah Nama Organisasi"
                  value={allDataFrom.name}
                  margin="normal"
                  className={classes.helperMarginLeft0}
                  error={
                    (!allDataFrom.name && isError) ||
                    allDataFrom.name.length > 100
                    // ||(nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
                  }
                  helperText={
                    !allDataFrom.name && isError
                      ? 'Bagian ini diperlukan'
                      : // : (nameChecker
                      //   ? !checkSameNameWithAdd
                      //   : checkSameNameWithAdd)
                      // ? 'Name is already exist'
                      allDataFrom.name.length > 100
                      ? 'Jumlah karakter maksimum adalah 30 karakter'
                      : ''
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      name: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            {/* <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>4.</TitleNumber>
                <TitleName>Acting As</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <Select
                  id="parent"
                  options={[{value: null, label: 'None'}, ...actingsAs]}
                  placeholder="Select Acting As"
                  value={actingAsDropdown}
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      acting_as_id: event.value,
                    })
                    setActingAsDropdown(event)
                  }}
                  styles={selectStyles}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: '#bfbfbf', // Placeholder color
                    },
                  })}
                />
              </FormChildInput>
            </FormChildContainer> */}
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>4.</TitleNumber>
                <TitleName>Induk *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isParent}
                      // disabled={
                      //   parentChecker ? !(parentChecker && checkParent) : false
                      // }
                      onChange={event => {
                        setIsParent(event.target.checked)
                        if (event.target.checked) {
                          if (parentChecker) {
                            if (!(parentChecker && checkParent)) {
                              handleSetNewParent()
                            }
                          } else {
                            if (idParentOUJobProfile !== (data && data.id)) {
                              if (idParentOUJobProfile || idParentTopOU) {
                                handleSetNewParent()
                              }
                            }
                            //
                          }
                          setAllDataFrom({
                            ...allDataFrom,
                            parent: null,
                          })
                          setParentDropdown(null)
                        } else {
                          setVariable({})
                        }
                      }}
                      color="primary"
                    />
                  }
                  label="Ditetapkan sebagai induk?"
                />
                {!isParent ? (
                  <>
                    <Select
                      id="parent"
                      options={parentOptions}
                      placeholder="Pilih Induk"
                      value={parentDropdown}
                      isLoading={parentListLoad}
                      menuPosition="fixed"
                      onInputChange={dataSearchDropdown =>
                        setSearchDropdown({
                          ...searchDropdown,
                          parent: dataSearchDropdown,
                        })
                      }
                      onChange={event => {
                        setAllDataFrom({
                          ...allDataFrom,
                          parent: event.value,
                        })
                        addDataParent(event)
                      }}
                      styles={selectStyles}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          neutral50: '#bfbfbf', // Placeholder color
                        },
                      })}
                    />
                    {/* <TextHelper className={classes.margin0}>
                      {!allDataFrom.parent && isError
                        ? 'This field is required'
                        : ''}
                    </TextHelper> */}
                    <TitleName
                      className={classes.marginTop10}
                    >{`Induk saat ini: ${parentName &&
                      parentName.name}`}</TitleName>
                  </>
                ) : (
                  <FormHelperText error>
                    Jika Anda menetapkan ini sebagai induk, ini akan ditetapkan
                    sebagai struktur tertinggi di perusahaan
                  </FormHelperText>
                )}
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>5.</TitleNumber>
                <TitleName>
                  Ditetapkan sebagai asisten <MemoizedAssistantFieldTooltip />
                </TitleName>
              </FormChildTitle>
              <FormChildInput>
                <FormControlLabel
                  label={`Ditetapkan sebagai asisten${
                    childCount ? ` dari  ${childCount.organization.name}` : ''
                  }?`}
                  disabled={isParent || isUnsuitableAssistant}
                  control={
                    <Switch
                      checked={allDataFrom.as_assistant}
                      onChange={event => {
                        const next = event.target.checked
                        setAllDataFrom({...allDataFrom, as_assistant: next})
                      }}
                    />
                  }
                />

                {isParent ? (
                  <FormHelperText error>
                    Tidak dapat menetapkan Induk Unit Organisasi sebagai Asisten
                  </FormHelperText>
                ) : isUnsuitableAssistant ? (
                  <FormHelperText error>
                    Tidak dapat menetapkan Unit Organisasi sebagai Asisten
                  </FormHelperText>
                ) : null}
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>6.</TitleNumber>
                <TitleName>
                  Tipe Garis Struktur <MemoizedLineTypeFieldTooltip />
                </TitleName>
              </FormChildTitle>
              <FormChildInput>
                <Select
                  options={LINE_TYPE_OPTIONS}
                  placeholder="Select Line Type"
                  value={lineDropdown}
                  onChange={next => {
                    setAllDataFrom({...allDataFrom, line_type: next.value})
                    setLineDropdown(next)
                  }}
                  styles={selectStyles}
                  menuPosition="fixed"
                />
              </FormChildInput>
            </FormChildContainer>
          </FormContainer>
        </OrganizationContainer>
      </PaperContainer>
      {hasJobProfile ? (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type="Confirm"
          applied={data && data.company_job_profiles}
          mutation={handleAddOrg}
          appliedName="Position"
        />
      ) : (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type={status}
          name={data && data.name}
          feature="Organization Unit"
          mutation={handleAddOrg}
        />
      )}
      {/* <AlertDialog
        open={openCantEdit}
        handleClose={openDialogConfirm}
        feature={`No Parent Selected`}
        message="You are not set the organization unit parent. Please set one of the organization units as parent to see the organization structure."
        type="Confirm"
      /> */}
      <NewParentPopup
        open={popupNewParent.openPopup}
        id={popupNewParent.idOrg}
        handleClose={handleSetNewParent}
        switchChecked={isParent}
        setSwitchChecked={setIsParent}
        feature="organization"
        isUndetermined={status === 'Add'}
        setVariable={setVariable}
      />
      <AlertDialog
        open={dialogCancel}
        handleClose={handleCancel}
        feature={`Batal Untuk ${
          data ? 'Mengubah' : 'Menambahkan'
        } Unit Organisasi?`}
        message={`Anda memiliki perubahan yang belum disimpan. Yakin ingin membatalkan ${
          data ? `perubahan ${data.name}` : 'penambahan Unit Organisasi baru?'
        }?`}
        positiveTitleButton="Ya, Batalkan"
        type="Yes, Cancel"
        status="notNow"
      />
      <AddEdit
        open={errorParent}
        handleClose={handleErrorParent}
        noCancelNeeded
        header="Silahkan Pilih Induk"
        body="Harap pilih induk untuk unit organisasi ini"
        button="Konfirmasi"
        mutation={() => setErrorParent(false)}
      />
    </OrganizationContainer>
  )
}

export default withRouter(AddEditOrgUnit)

const AssistantFieldTooltip = () => {
  return (
    <FieldInformationTooltip
      title={
        <div style={{textAlign: 'center'}}>
          <div style={{marginBottom: 8}}>
            Unit organisasi ini akan ditempatkan di bawah induk
          </div>
          <img
            width={171}
            height={161}
            src={require('../../../../assets/images/structure/unit_assistant.svg')}
          />
        </div>
      }
    />
  )
}

const LineTypeFieldTooltip = () => {
  return (
    <FieldInformationTooltip
      title={
        <>
          <div style={{marginBottom: 8}}>
            Konfigurasi garis antara Anak Bagan ke Induk Bagan dalam Struktur
            Organisasi
          </div>
          <div style={{display: 'flex', textAlign: 'center'}}>
            <div style={{marginRight: 24}}>
              <img
                width={41}
                height={111}
                src={require('../../../../assets/images/structure/unit_line_solid.svg')}
              />
              <div>Tipe Garis Solid</div>
            </div>
            <div>
              <img
                width={41}
                height={111}
                src={require('../../../../assets/images/structure/unit_line_dotted.svg')}
              />
              <div>Tipe Garis Putus-Putus </div>
            </div>
          </div>
        </>
      }
    />
  )
}

const MemoizedAssistantFieldTooltip = React.memo(AssistantFieldTooltip)
const MemoizedLineTypeFieldTooltip = React.memo(LineTypeFieldTooltip)
