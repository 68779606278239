import {useMutation} from '@apollo/react-hooks'
import React, {useEffect, useState} from 'react'
import {
  ADD_EXPERIENCE,
  UPDATE_EXPERIENCE,
  DELETE_EXPERIENCE,
} from '../../../../../../graphql/mutations'
import {dateFormat} from '../../../../../../utils/helpers'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {formatatSalary} from '../../../add-employee/utilsForEmployee'
import {ModalAddEditWraper} from '../../DetailEmployeeStyles'
import {
  TextFieldForm,
  DateForm,
  PhoneField,
  SwitchForm,
  ButtonSubmit,
} from '../../SharedDetailEmployee'
import {isErrorForm} from './helperExperience'

const INITIAL_VALUES_STATE = {
  company_name: undefined,
  line_off_bussiness: undefined,
  position_name: undefined,
  decree: undefined,
  company_location: undefined,
  start_date: new Date(),
  end_date: new Date(),
  description: undefined,
  masterpiece: false,
  achievement: undefined,
  initial_salary: formatatSalary(0),
  last_salary: formatatSalary(0),
  reason_leaving: undefined,
  reference_name: undefined,
  reference_position: undefined,
  phone_code: undefined,
  phone_number: undefined,
  share: false,
  is_present: false,
}

const HELPER_TEXT = 'Bagian ini diperlukan'

export default function AddEditExperience({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
}) {
  const [values, setValues] = useState(INITIAL_VALUES_STATE)
  const [errorState, setErrorState] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [addExperience] = useMutation(ADD_EXPERIENCE)
  const [updateExperience] = useMutation(UPDATE_EXPERIENCE)
  const [deleteExperience] = useMutation(DELETE_EXPERIENCE)

  useEffect(() => {
    if (actionType === 'edit' && open) {
      setValues(e => ({
        ...e,
        company_name: data.company_name,
        line_off_bussiness: data.company_line_of_business,
        position_name: data.title,
        decree: data.decree,
        company_location: data.location && data.location.experience_location,
        start_date: data.from,
        end_date: data.to,
        description: data.description,
        achievement: data.achievements,
        initial_salary: formatatSalary(`${data.initial_salary}`),
        last_salary: formatatSalary(`${data.last_salary}`),
        reason_leaving: data.reason_to_leave,
        reference_name: data.referal_name,
        masterpiece: data.is_masterpiece,
        reference_position: data.referal_position,
        share: false,
        is_present: data.is_present,
        phone_code:
          data.additional_fields &&
          data.additional_fields.referal_contact_phone &&
          data.additional_fields.referal_contact_phone.country_code,
        phone_number:
          data.additional_fields &&
          data.additional_fields.referal_contact_phone &&
          data.additional_fields.referal_contact_phone.phone,
      }))
    }
  }, [open, actionType])

  const fieldsList = [
    {
      label: 'Nama Perusahaan*',
      placeholder: 'Tambahkan Perusahaan',
      fieldName: 'company_name',
      type: 'textfield',
      required: true,
      value: values.company_name,
      error: !values.company_name,
    },
    {
      label: 'Bidang Usaha',
      placeholder: 'Tambahkan bidang usaha perusahaan',
      fieldName: 'line_off_bussiness',
      type: 'textfield',
      value: values.line_off_bussiness,
    },
    {
      label: 'Jabatan*',
      placeholder: 'Tambah Jabatan',
      fieldName: 'position_name',
      type: 'textfield',
      required: true,
      value: values.position_name,
      error: !values.position_name,
    },
    {
      label: 'Surat Ketetapan',
      placeholder: 'Tambahkan surat ketetapan',
      fieldName: 'decree',
      type: 'textfield',
      value: values.decree,
      error: true,
    },
    {
      label: 'Lokasi Perusahaan*',
      placeholder: 'Tambahkan lokasi perusahaan',
      fieldName: 'company_location',
      type: 'textfield',
      required: true,
      value: values.company_location,
      error: !values.company_location,
    },
    {
      type: 'date',
      required: true,
      fieldName: ['start_date', 'end_date', 'is_present'],
      value: [values.start_date, values.end_date, values.is_present],
      error: [!values.start_date, !values.end_date],
    },
    {
      label: 'Keterangan',
      placeholder: 'Tambahkan keterangan',
      fieldName: 'description',
      type: 'textfield',
      multiline: true,
      rows: 6,
      value: values.description,
      error: true,
    },
    {
      label: 'Hasil Karya',
      text: 'Saya punya hasil karya dalam pengalaman ini',
      type: 'switch',
      fieldName: 'masterpiece',
      value: values.masterpiece,
    },
    {
      label: 'Pencapaian',
      placeholder: 'Tambahkan pencapaian',
      fieldName: 'achievement',
      type: 'textfield',
      multiline: true,
      rows: 6,
      value: values.achievement,
    },
    // {
    //   label: 'Initial Salary',
    //   placeholder: 'Add Initial Salary',
    //   fieldName: 'initial_salary',
    //   type: 'textfield',
    //   value: values.initial_salary,
    //   isSalary: true,
    // },
    // {
    //   label: 'Last Salary',
    //   placeholder: 'Add Last Salary',
    //   fieldName: 'last_salary',
    //   type: 'textfield',
    //   value: values.last_salary,
    //   isSalary: true,
    // },
    {
      label: 'Alasan Keluar Perusahaan',
      placeholder: 'Tambahkan alasan keluar perusahaan',
      fieldName: 'reason_leaving',
      type: 'textfield',
      multiline: true,
      rows: 5,
      value: values.reason_leaving,
    },
    {
      label: 'Nomor Referensi',
      placeholder: 'Tambahkan nomor referensi',
      fieldName: 'reference_name',
      type: 'textfield',
      value: values.reference_name,
    },
    {
      label: 'Posisi Pemberi Referensi',
      placeholder: 'Tambahkan posisi pemberi referensi',
      fieldName: 'reference_position',
      type: 'textfield',
      value: values.reference_position,
    },
    {
      label: 'Nomor Kontak Referensi',
      placeholder: 'Tambahkan nomor kontak',
      fieldName: ['phone_code', 'phone_number'],
      type: 'phone-field',
      value: [values.phone_code, values.phone_number],
    },
  ]

  const handleChange = ({fieldName, value}, target, ekstra) => {
    if (target === 'date') {
      setValues(e => ({
        ...e,
        [ekstra]: value,
      }))
    } else {
      setValues(e => ({
        ...e,
        [fieldName]: value,
      }))
    }
  }

  const _renderComponent = (res, i) => {
    const {
      label,
      fieldName,
      type,
      value,
      placeholder,
      error,
      rows,
      multiline,
      text,
      isSalary,
      required,
    } = res
    const KEY = `${i}-comp`
    if (type === 'textfield') {
      return (
        <TextFieldForm
          key={KEY}
          value={isSalary ? formatatSalary(value) : value}
          label={label}
          rows={rows}
          multiline={multiline}
          placeholder={placeholder}
          onChange={e => handleChange({fieldName, value: e.target.value})}
          error={errorState && required && error}
          helperText={errorState && required && error && HELPER_TEXT}
        />
      )
    } else if (type === 'date') {
      return (
        <DateForm
          key={KEY}
          value={value}
          fieldName={fieldName}
          error={(errorState && required && error) || [false, false]}
          onChange={(value, fieldName) =>
            handleChange({value}, 'date', fieldName)
          }
        />
      )
    } else if (type === 'phone-field') {
      return (
        <PhoneField
          key={KEY}
          open={open}
          error={errorState && required && error && [false, false]}
          label={label}
          onChangePhone={val =>
            handleChange({fieldName: fieldName[0], value: val})
          }
          onChange={e =>
            handleChange({fieldName: fieldName[1], value: e.target.value})
          }
          value={value}
          placeholder={placeholder}
        />
      )
    } else if (type === 'switch') {
      return (
        <SwitchForm
          key={KEY}
          label={label}
          text={text}
          value={value}
          onChange={e => handleChange({fieldName, value: e.target.checked})}
        />
      )
    }
  }

  const handleClose = () => {
    onClose()
    setValues(INITIAL_VALUES_STATE)
  }

  const handleSave = async () => {
    const isError = await isErrorForm(fieldsList)
    if (isError) {
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000)
    } else {
      // const _initalSalary = await changeToNumber(values.initial_salary)
      // const _ilastSalary = await changeToNumber(values.last_salary)
      const _variables = {
        id: data && data.id,
        object: {
          user: userIdDetail,
          is_share: values.share,
          company_name: values.company_name,
          company_line_of_business: values.line_off_bussiness,
          title: values.position_name,
          decree: values.decree,
          from: dateFormat(values.start_date, 'YYYY-MM-DD'),
          to: values.is_present
            ? null
            : dateFormat(values.end_date, 'YYYY-MM-DD'),
          is_present: values.is_present,
          description: values.description,
          is_masterpiece: values.masterpiece,
          achievements: values.achievement,
          // initial_salary: _initalSalary,
          // last_salary: _ilastSalary,
          reason_to_leave: values.reason_leaving,
          referal_name: values.reference_name,
          referal_position: values.reference_position,
        },
        appendObject: {
          location: {
            experience_location: values.company_location,
          },
          additional_fields: {
            referal_contact_phone: {
              country_code: values.phone_code,
              phone: values.phone_number,
            },
          },
        },
      }

      if (actionType === 'add') {
        const variables = {
          objects: [
            {
              ..._variables.object,
              ..._variables.appendObject,
            },
          ],
        }
        addExperience({variables})
          .then(() => {
            refetch()
            enqueueSnackbar('Berhasil menambahkan pengalaman', {
              variant: 'success',
              autoHideDuration: 2000,
            })
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar('Gagal menambahkan pengalaman', {
              variant: 'error',
              autoHideDuration: 2000,
            })
          })
      } else {
        const variables = {
          id: _variables.id,
          setObject: _variables.object,
          appendObject: _variables.appendObject,
        }
        updateExperience({variables})
          .then(() => {
            refetch()
            enqueueSnackbar('Berhasil mengubah pengalaman', {
              variant: 'success',
              autoHideDuration: 2000,
            })
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar('Gagal mengubah pengalaman', {
              variant: 'error',
              autoHideDuration: 2000,
            })
          })
      }
    }
  }

  const handleDelete = () => {
    deleteExperience({
      variables: {
        id: data.id,
      },
    })
      .then(() => {
        refetch()
        setOpenDelete(false)
        enqueueSnackbar('Berhail menghapus pengalaman', {
          variant: 'success',
          autoHideDuration: 2000,
        })
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar('Gagal menghapus pengalaman', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleOpenDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  return (
    <>
      <ModalDetail
        onDelete={actionType === 'edit' && handleOpenDelete}
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        title={
          (actionType === 'add'
            ? 'Tambahkan'
            : actionType === 'edit'
            ? 'Ubah'
            : '') + ' Pengalaman'
        }
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Bagikan dengan jaringan:"
            value={values.share}
            labelPlacement="start"
            onChange={e =>
              handleChange({fieldName: 'share', value: e.target.checked})
            }
          />
        </div>
        <ModalAddEditWraper>
          {fieldsList.map((res, i) => _renderComponent(res, i))}
        </ModalAddEditWraper>
        <ButtonSubmit onCancel={handleClose} onSave={handleSave} />
      </ModalDetail>
      <DeletePopup
        open={openDelete}
        handleClose={handleCloseDelete}
        name={'Pengalaman'}
        mutation={handleDelete}
      />
    </>
  )
}
