import React, {useState} from 'react'
import moment from 'moment'
import {useQuery} from '@apollo/react-hooks'
import {makeStyles} from '@material-ui/core/styles'
import {IconButton, Paper} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CreateIcon from '@material-ui/icons/Create'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'

import {
  ActionLink,
  MediumTextBlackBold,
  MediumTextGrey,
  TextDarkBlue,
  TextSpanGrey,
} from '../../../PayrollPageStyles'

import {convertToRoundedRupiah} from '../../../../../../utils/helpers'
import {GET_HISTORY_PEOPLE_WORK_SALARY} from '../../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../../utils'

import RequestWrapper from '../../../../../shared-components/layouts/RequestWrapper'
import {FormChildTitle} from '../../../../../../GlobalStyles'
import FieldInformationTooltip from '../../../../../shared-components/tooltip/FieldInformationTooltip'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'fit-content',
  },
  paper: {
    padding: '0',
  },
  oppositeContent: {
    padding: 0,
    flex: 0,
  },
  fontSizeSmall: {
    padding: '0 5px',
    fontSize: '0.875rem',
  },
}))

const UserSalaryHistoryLog = ({
  onAddNewSalaryButtonClick,
  onEditSalaryButtonClick,
  pwsData,
  userId,
}) => {
  const classes = useStyles()
  const [limit, setLimit] = useState(5)

  const {data: user_salary, loading, error} = useQuery(
    GET_HISTORY_PEOPLE_WORK_SALARY,
    {
      variables: {
        company_id: COMPANY_ID,
        user_id: userId,
        limit: limit,
      },
      ...GQL_FINANCE_OPTIONS,
      skip: userId === null,
      fetchPolicy: 'no-cache',
    }
  )

  const formatedDate = date => {
    return moment(date).format('MMM DD, YYYY')
  }

  const userSalaries = user_salary?.people_work_salaries

  return (
    <div style={{width: '40%'}}>
      <FormChildTitle>
        <MediumTextBlackBold>History Log</MediumTextBlackBold>
        <FieldInformationTooltip title="Admin can add new salary and edit last salary" />
      </FormChildTitle>

      <ActionLink
        style={{marginTop: '15px'}}
        onClick={() => {
          onAddNewSalaryButtonClick(userSalaries?.[0] || null)
        }}
      >
        + Add new basic salary
      </ActionLink>

      <RequestWrapper loading={loading} error={error}>
        {user_salary && (
          <>
            <Timeline>
              {userSalaries?.map((data, index) => {
                return (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent
                      className={classes.oppositeContent}
                    ></TimelineOppositeContent>

                    <TimelineSeparator>
                      <TimelineDot
                        color={
                          data.id === pwsData?.current?.id ||
                          (!pwsData?.current?.id && index === 0)
                            ? 'primary'
                            : 'grey'
                        }
                      />
                      <TimelineConnector />
                    </TimelineSeparator>

                    <TimelineContent>
                      {data.id === pwsData?.current?.id ||
                      (!pwsData?.current?.id && index === 0) ? (
                        <MediumTextBlackBold>
                          {data.start_date
                            ? `${formatedDate(
                                data.start_date
                              )} - ${formatedDate(data.end_date)}`
                            : formatedDate(data.date_added)}
                        </MediumTextBlackBold>
                      ) : (
                        <MediumTextGrey>
                          {data.start_date
                            ? `${formatedDate(
                                data.start_date
                              )} - ${formatedDate(data.end_date)}`
                            : formatedDate(data.date_added)}

                          <IconButton
                            aria-label="edit"
                            style={{color: '#039be5'}}
                            onClick={() => {
                              onEditSalaryButtonClick(
                                data,
                                userSalaries?.[index - 1],
                                userSalaries?.[index + 1]
                              )
                            }}
                          >
                            <CreateIcon style={{fontSize: '20px'}} />
                          </IconButton>
                        </MediumTextGrey>
                      )}

                      <Paper elevation={0} className={classes.paper}>
                        <TextDarkBlue>Basic Salary</TextDarkBlue>
                        <br />
                        <TextSpanGrey>
                          {index + 1 < userSalaries?.length ? (
                            userSalaries?.[index + 1].value === data.value ? (
                              'No changes'
                            ) : (
                              <>
                                {convertToRoundedRupiah(
                                  userSalaries?.[index + 1].value
                                )}
                                <ArrowForwardIcon
                                  color="primary"
                                  fontSize="small"
                                  className={classes.fontSizeSmall}
                                />
                                {convertToRoundedRupiah(data.value)}{' '}
                              </>
                            )
                          ) : (
                            convertToRoundedRupiah(data.value)
                          )}
                        </TextSpanGrey>{' '}
                        <br />
                        <TextDarkBlue>PTKP</TextDarkBlue>
                        <br />
                        <TextSpanGrey>
                          {index + 1 < userSalaries?.length ? (
                            userSalaries?.[index + 1].salary_fields.PTKP ===
                            data.salary_fields.PTKP ? (
                              'No changes'
                            ) : (
                              <>
                                {userSalaries?.[index + 1].salary_fields.PTKP}
                                <ArrowForwardIcon
                                  color="primary"
                                  fontSize="small"
                                  className={classes.fontSizeSmall}
                                />
                                {data.salary_fields.PTKP}{' '}
                              </>
                            )
                          ) : (
                            data.salary_fields.PTKP
                          )}
                        </TextSpanGrey>{' '}
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                )
              })}

              {user_salary?.total?.aggregate?.count <= limit && (
                <TimelineItem className={classes.root}>
                  <TimelineOppositeContent
                    className={classes.oppositeContent}
                  ></TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineDot color="grey" />
                  </TimelineSeparator>

                  <TimelineContent>
                    <TextSpanGrey>
                      {user_salary?.total?.aggregate?.count === 0
                        ? 'Empty Log'
                        : 'End of log'}
                    </TextSpanGrey>
                  </TimelineContent>
                </TimelineItem>
              )}
            </Timeline>

            {limit < user_salary?.total?.aggregate?.count ? (
              <ActionLink
                style={{paddingLeft: '20px'}}
                onClick={() => setLimit(limit + 5)}
              >
                Show More
              </ActionLink>
            ) : user_salary?.total?.aggregate?.count > 5 ? (
              <ActionLink
                style={{paddingLeft: '20px'}}
                onClick={() => setLimit(5)}
              >
                Show Less
              </ActionLink>
            ) : (
              ''
            )}
          </>
        )}
      </RequestWrapper>
    </div>
  )
}

export default UserSalaryHistoryLog
