import assignFilters, {
  createGetVariables,
  getDataId,
  getDataValue,
} from './assignFilters'

import {
  GET_EDUCATION_FILTER,
  GET_EMPLOYEE_TYPE_FILTER,
  GET_HOBBY_FILTER,
  GET_RELIGION_FILTER,
  GET_TALENT_BADGE_FILTER,
  GET_TALENT_FILTER,
  GET_TIME_ARRANGEMENT_FILTER,
  GET_WORKING_ARRANGEMENT_FILTER,
} from '../graphql/queries/filters/searchFilter'

import {COMPANY_ID} from './globals'

const searchFilters = [
  ...assignFilters,

  {
    name: 'Employee Type',
    fieldName: 'employeeType',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_EMPLOYEE_TYPE_FILTER,
        getVariables: createGetVariables({
          company: false,
          searchKeys: ['name'],
          idKey: 'value',
          deletedAtKey: 'deleted_at',
        }),
        setData: getDataValue,
      },
    },
  },
  {
    name: 'Time Arrangement',
    fieldName: 'timeArrangement',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_TIME_ARRANGEMENT_FILTER,
        getVariables: createGetVariables({
          company: false,
          searchKeys: ['name'],
          idKey: 'value',
          deletedAtKey: 'deleted_at',
        }),
        setData: getDataValue,
      },
    },
  },
  {
    name: 'Working Arrangement',
    fieldName: 'workingArrangement',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_WORKING_ARRANGEMENT_FILTER,
        getVariables: createGetVariables({
          company: false,
          searchKeys: ['name'],
          idKey: 'value',
          deletedAtKey: 'deleted_at',
        }),
        setData: getDataValue,
      },
    },
  },

  {
    name: 'Age',
    fieldName: 'age',
    type: 'age',
    min: 0,
    max: 90,
  },
  {
    name: 'Gender',
    fieldName: 'gender',
    type: 'checkbox',
    options: {
      list: [
        {value: 'M', label: 'Male'},
        {value: 'F', label: 'Female'},
      ],
    },
  },
]

export default searchFilters

export const extendedSearchFilters = [
  ...searchFilters,

  {
    name: 'Salary',
    fieldName: 'salary',
    type: 'salary',
    min: 0,
    max: 99999999,
  },

  {
    name: 'Religion',
    fieldName: 'religion',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_RELIGION_FILTER,
        getVariables: createGetVariables({
          company: false,
          searchKeys: ['name'],
          deletedAtKey: 'deleted_at',
        }),
        setData: getDataId,
      },
    },
  },
  {
    name: 'Education',
    fieldName: 'education',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_EDUCATION_FILTER,
        getVariables: ({search, limit, _in, _nin}) => {
          return {
            limit: limit,
            where: {
              school: {_is_null: true},
              deletedAt: {_is_null: true},
              global_user: {
                people_work_placements: {company: {_eq: COMPANY_ID}},
              },
              company_profile: {
                id: {_in, _nin},
              },
              _or: search
                ? [
                    {company_profile: {legal_name: {_ilike: search}}},
                    {company_profile: {brand_name: {_ilike: search}}},
                  ]
                : undefined,
            },
          }
        },
        setData: result => {
          const count = result.total.aggregate.count

          const options = result.items.map(item => ({
            value: item.company_profile.id,
            label: item.company_profile.legal_name,
          }))

          return [options, count]
        },
      },
    },
  },
  {
    name: 'Hobby',
    fieldName: 'hobby',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_HOBBY_FILTER,
        getVariables: createGetVariables({
          company: false,
          searchKeys: ['name'],
          deletedAtKey: 'deletedAt',
        }),
        setData: getDataId,
      },
    },
  },

  {
    name: 'Talent',
    fieldName: 'talent',
    type: 'talent',
    min: 0,
    max: 100,
    options: {
      listTalents: {
        query: GET_TALENT_FILTER,
        options: {
          variables: {
            limit: 5,
            company: COMPANY_ID,
          },
        },
        setData: ({talent_versions}) => {
          const _data =
            talent_versions?.map(({id, name}) => ({
              value: id,
              label: name,
            })) || []

          return [_data]
        },
      },
      listBadges: {
        query: GET_TALENT_BADGE_FILTER,
        options: {
          variables: {
            limit: 4,
          },
        },
        setData: ({talent_boxes, total}) => {
          const _data =
            talent_boxes?.map(({id, badge, name}) => ({
              value: id,
              label: name,
              badge,
            })) || []

          return [_data, total.aggregate.count]
        },
      },
    },
  },
]

export const coerceFalsyArray = array => {
  const next = array.filter(el => !!el)
  return next.length > 0 ? next : undefined
}

/** Use this for filtering `people_work_placements` */
export const getSearchVariables = filterData => {
  const {include = {}, exclude = {}} = filterData

  const where = {
    type:
      include.employeeType || exclude.employeeType
        ? {_in: include.employeeType, _nin: exclude.employeeType}
        : undefined,
    type_time:
      include.timeArrangement || exclude.timeArrangement
        ? {_in: include.timeArrangement, _nin: exclude.timeArrangement}
        : undefined,
    type_place:
      include.workingArrangement || exclude.workingArrangement
        ? {_in: include.workingArrangement, _nin: exclude.workingArrangement}
        : undefined,

    global_user: {
      people_profiles: {
        gender:
          include.gender || exclude.gender
            ? {_in: include.gender, _nin: exclude.gender}
            : undefined,
        religion:
          include.religion || exclude.religion
            ? {_in: include.religion, _nin: exclude.religion}
            : undefined,
        _and: coerceFalsyArray([
          include.age && (include.age.min || include.age.max)
            ? {
                birthdate: {_gte: include.age.min, _lte: include.age.max},
              }
            : false,
          exclude.age && (exclude.age.min || exclude.age.max)
            ? {
                _not: {
                  birthdate: {_gte: exclude.age.min, _lte: exclude.age.max},
                },
              }
            : false,
        ]),
      },

      _and: coerceFalsyArray([
        include.education || exclude.education
          ? {
              people_profile_educations: {
                school: {_in: include.education, _nin: exclude.education},
              },
            }
          : false,

        include.hobby || exclude.hobby
          ? {
              people_profile_hobbies: {
                hobby: {_in: include.hobby, _nin: exclude.hobby},
              },
            }
          : false,
      ]),
    },

    company_job_profile: {
      id:
        include.position || exclude.position
          ? {_in: include.position, _nin: exclude.position}
          : undefined,
      position:
        include.jobTitle || exclude.jobTitle
          ? {_in: include.jobTitle, _nin: exclude.jobTitle}
          : undefined,
      organization:
        include.organizationUnit || exclude.organizationUnit
          ? {_in: include.organizationUnit, _nin: exclude.organizationUnit}
          : undefined,
      rank:
        include.jobLevel || exclude.jobLevel
          ? {_in: include.jobLevel, _nin: exclude.jobLevel}
          : undefined,
      office_address:
        include.officeLocation || exclude.officeLocation
          ? {_in: include.officeLocation, _nin: exclude.officeLocation}
          : undefined,
      job_grade_id:
        include.jobGrade || exclude.jobGrade
          ? {_in: include.jobGrade, _nin: exclude.jobGrade}
          : undefined,
    },

    _and: coerceFalsyArray([
      include.salary && (include.salary.min || include.salary.max)
        ? {
            current_salary: {
              _gte: include.salary.min,
              _lte: include.salary.max,
            },
          }
        : false,
      exclude.salary && (exclude.salary.min || exclude.salary.max)
        ? {
            _not: {
              current_salary: {
                _gte: exclude.salary.min,
                _lte: exclude.salary.max,
              },
            },
          }
        : false,
    ]),
  }

  return where
}
