import {
  Avatar,
  makeStyles,
  Paper,
  Typography,
  Grid,
  Icon,
  Tooltip,
} from '@material-ui/core'
import moment from 'moment'
import React, {useState, Fragment} from 'react'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  GreyTypography,
  TitleName,
  TitleNumber,
  FlexBetween,
} from '../../../../../GlobalStyles'
import ModalLevelDetail from './detail-component/ModalLevelDetail'
import {capitalize, parameters_versions} from '../../../../../utils/helpers'
import NineBoxes from '../../../../talent/classification/detail/NineBoxes'

const useStyles = makeStyles(() => ({
  detailValue: {
    marginTop: '1rem',
  },
  detailValueBox: {
    marginTop: '1rem',
    display: 'flex',
  },
  paperCommitee: {
    padding: 10,
    display: 'flex',
    border: '1px solid #014a62',
    marginRight: 12,
    alignItems: 'center',
    minHeight: 60,
  },
  spacer: {
    marginLeft: 12,
  },
  commiteeContainer: {
    padding: 8,
  },
  boxes: {
    marginTop: 24,
  },
}))

const StepFive = ({mainValues, candidate, employee, flexy, isTalent}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [resultBox, setResultBox] = useState(null)
  const handleRes = res => {
    setResultBox(res)
    setOpen(true)
  }

  const idDateFormat = date => {
    const _date = new Date(date)
    const options = {year: 'numeric', month: 'long', day: 'numeric'}
    return new Intl.DateTimeFormat('id-ID', options).format(_date)
  }

  const renderStatus = status => {
    return status === 'COMPLETED'
      ? 'Selesai'
      : status === 'INEVALUATION'
      ? 'Di evaluasi'
      : status === 'DRAFT'
      ? 'Draf'
      : status
  }

  return (
    <FormContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>
            Nama {isTalent ? 'Versi' : 'Evaluasi Kinerja Pegawai'}
          </TitleName>
        </FormChildTitle>
        <FormChildInput className={classes.detailValue}>
          <Typography variant="body1">{mainValues.name}</Typography>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>{isTalent ? 'Periode Versi' : 'Periode'}</TitleName>
        </FormChildTitle>
        <FormChildInput className={classes.detailValue}>
          <Typography variant="body1">
            {idDateFormat(mainValues.start_date)} sampai{' '}
            {idDateFormat(mainValues.end_date)}
          </Typography>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer style={{width: '75%'}}>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>
            {isTalent
              ? 'Tingkat Klasifikasi Talenta'
              : 'Level Evaluasi Kinerja Pegawai'}
          </TitleName>
        </FormChildTitle>
        <FormChildInput className={classes.detailValueBox}>
          <div className={classes.boxes}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  height: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  width: '5rem',
                }}
              >
                <div>
                  <Typography
                    style={{
                      color: '#707070',
                      fontSize: 12,
                    }}
                  >
                    {flexy && flexy.y.name}
                  </Typography>
                  <Typography
                    style={{
                      color: '#707070',
                      fontSize: 12,
                    }}
                  >
                    (
                    {flexy?.y?.parameters
                      ?.map(
                        res =>
                          parameters_versions?.[res.parameter]?.ID ||
                          capitalize(res.parameter)
                      )
                      .join(', ')}
                    )
                  </Typography>
                </div>
              </div>
              <NineBoxes
                newWidth={mainValues.box_width}
                condList={resultBox && resultBox.id}
                // indicator={indcator}
                data={mainValues.talent_boxes.data}
                handleChangeBage={handleRes}
                nofilter
              />
            </div>
            <Typography
              variant="body2"
              style={{textAlign: 'center', color: '#707070', fontSize: 12}}
            >
              {flexy && flexy.x.name} ({' '}
              {flexy?.x?.parameters
                ?.map(
                  res =>
                    parameters_versions[res.parameter].ID ||
                    capitalize(res.parameter)
                )
                .join(', ')}
              )
            </Typography>
          </div>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer style={{width: '100%'}}>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>{isTalent ? 'Kandidat' : 'Peserta'}</TitleName>
        </FormChildTitle>
        <FormChildInput className={classes.detailValue}>
          {candidate?.length > 0 &&
            candidate?.map((resCan, i) => {
              const calculateDate = () => {
                const start = moment(
                  mainValues.talent_candidate_groups.data[i]
                    .committee_date_start
                )
                const end = moment(
                  mainValues.talent_candidate_groups.data[i].committee_date_end
                )
                // let dates = end.diff(start, 'weeks')
                const dates = end.diff(start, 'days') + 1
                const times = 'Hari'

                return `${dates} ${times}`
              }

              return (
                <Fragment key={i}>
                  <Typography variant="body1">{resCan.label}</Typography>
                  <div className={classes.commiteeContainer}>
                    <GreyTypography variant="body1" gutterBottom>
                      Komite
                    </GreyTypography>
                    <Grid container spacing={3}>
                      {employee.length > 0 &&
                        employee[i].map((resCommittee, iCommittee) => {
                          return (
                            <Grid item xs={4} key={iCommittee}>
                              <Paper
                                elevation={0}
                                className={classes.paperCommitee}
                              >
                                <Avatar
                                  src={resCommittee.avatar}
                                  alt={resCommittee.value}
                                ></Avatar>
                                <FlexBetween className={classes.spacer}>
                                  <div>
                                    <Typography variant="body2" color="primary">
                                      {resCommittee.value}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      component="p"
                                      color="primary"
                                    >
                                      {calculateDate()}
                                    </Typography>
                                  </div>

                                  {mainValues.talent_candidate_groups.data[i]
                                    .talent_committees.data[iCommittee]
                                    .is_pic && (
                                    <Tooltip title="Penanggungjawab">
                                      <Icon
                                        style={{
                                          color: '#014a62',
                                          marginLeft: 6,
                                          cursor: 'pointer',
                                        }}
                                      >
                                        star
                                      </Icon>
                                    </Tooltip>
                                  )}
                                </FlexBetween>
                              </Paper>
                            </Grid>
                          )
                        })}
                    </Grid>
                  </div>
                </Fragment>
              )
            })}
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>5.</TitleNumber>
          <TitleName>Status</TitleName>
        </FormChildTitle>
        <FormChildInput className={classes.detailValue}>
          <Typography variant="body1">
            {mainValues.status && capitalize(renderStatus(mainValues.status))}
          </Typography>
        </FormChildInput>
      </FormChildContainer>
      <ModalLevelDetail
        open={open}
        handleClose={() => {
          setOpen(false)
          setResultBox(null)
        }}
        resultBox={resultBox}
        mainValues={mainValues}
        flexy={flexy}
      />
    </FormContainer>
  )
}

export default StepFive
