import React from 'react'
import {Doughnut} from 'react-chartjs-2'
import {DoughnutContainer, AggregateContainer} from './DoughnutChartStyles'

const options = {
  cutoutPercentage: 80,
  rotation: 4.7,
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
    position: 'left',
    labels: {
      boxWidth: 15,
      fontSize: 12,
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
}

const DoughnutChart = props => {
  const {aggregate} = props.data
  const {data, labelType} = props

  return (
    <DoughnutContainer isColumnLabelType={labelType === 'column'}>
      <Doughnut data={data} options={options} />
      {aggregate && (
        <AggregateContainer>
          <span>{aggregate.value}</span>
          <span className="text-center">{aggregate.label}</span>
        </AggregateContainer>
      )}
    </DoughnutContainer>
  )
}

export default DoughnutChart
