import gql from 'graphql-tag'

export const GET_CASECADING = gql`
  subscription getCasecading($search: String) {
    kpi_cascadings(where: {name: {_ilike: $search}}, limit: 1000, offset: 0) {
      id
      name
      date_start
      date_end
      description
      version
      status
    }
  }
`

export const GET_DETAIL_MAIN_BY_ID = id => {
  return gql`
  query{
    kpi_cascading_lists(where: {cascading: {_eq: ${id}}}) {
      id
      id_kpi: kpi
      kpi_main {
        id
        code
        name
      }
      kpi_parent
    }
  }
  `
}

export const GET_DROPDOWN_KPI = gql`
  subscription {
    kpi_lists {
      id
      code
      kpi_version {
        id
        name
      }
      kpi_level {
        id
        name
      }
      name
      kpi_method {
        id
        name
      }
      kpi_scoring_nature {
        id
        name
      }
      kpi_weight_unit {
        id
        name
      }
      kpi_monitoring_frequency {
        id
        name
      }
      kpi_perspective {
        id
        name
      }
      formula
      description
      status
    }
  }
`
export const COUNT_CASCADING_GOAL = gql`
  query($cascading: Int) {
    kpi_cascading_lists_aggregate(
      where: {cascading: {_eq: $cascading}, deleted_at: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_ALL_KPI_LIST = gql`
  query($company: uuid, $search: String, $cascading: Int, $limit: Int) {
    kpi_lists(
      where: {
        company: {_eq: $company}
        _or: [
          {
            _and: [
              {_not: {kpi_cascading_lists: {}}}
              {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
            ]
          }
          {
            _and: [
              {_not: {kpi_cascading_lists: {cascading: {_eq: $cascading}}}}
              {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
            ]
          }
        ]
      }
      limit: $limit
    ) {
      id
      code
      kpi_level {
        id
        name
      }
      name
    }
  }
`

export const GET_KPI_LIST_BY_CASCADING = gql`
  query($company: uuid, $cascading: Int, $search: String, $limit: Int) {
    kpi_lists(
      where: {
        company: {_eq: $company}
        kpi_cascading_lists: {cascading: {_eq: $cascading}}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
      limit: $limit
    ) {
      id
      code
      kpi_level {
        id
        name
      }
      name
    }
  }
`

export const GET_PARENT_KPI_LIST = gql`
  query($cascading: Int!, $kpi: Int!, $search: String, $is_editing: Boolean!) {
    getKpiListDropdownAutocomplete(
      cascading: $cascading
      kpi: $kpi
      search: $search
      is_editing: $is_editing
    ) {
      id
      code
      name
    }
  }
`

export const GET_KPI_LIST_WITHOUT_SELF_AND_CHILD = gql`
  query(
    $company: uuid
    $cascading: Int
    $cascading_list: Int
    $search: String
  ) {
    kpi_lists(
      where: {
        company: {_eq: $company}
        kpi_cascading_lists: {
          cascading: {_eq: $cascading}
          id: {_neq: $cascading_list}
          _or: [
            {kpi_parent: {_is_null: true}}
            {kpi_parent: {_neq: $cascading_list}}
          ]
        }
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
      limit: 30
    ) {
      id
      code
      name
    }
  }
`
