import React from 'react'

import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'
import {AttachmentCard} from '../../../../../../../components/attachment'
import {ItemWrapper} from '../../../DetailEmployeeStyles'
import {dateFormat} from '../../../../../../../utils/helpers'

const row = (header, content) => (
  <tr>
    <th scope="row">{header}</th>
    <td>{content || '-'}</td>
  </tr>
)

const AssessmentDetail = props => {
  const {open, data, onClose} = props

  if (!data) {
    return null
  }

  return (
    <ModalDetail open={open} onClose={onClose} title="Assessment Detail">
      <div style={{padding: 24}}>
        <ItemWrapper>
          <h1 className="title">{data.name}</h1>
          <table className="content" style={{'--row-gap': '16px'}}>
            <tbody>
              {row('Subject', data.subject)}
              {row('Institution', data.institution)}
              {row('Assessment Date', dateFormat(data?.test_date, 'll'))}
              {row('Assessment Cost', data.test_cost)}
              {row('Assessment Score', data.test_score)}
              {row('Assessment Score Range', data.test_score_range)}
              {row('Assessment Result', data.test_result)}
              {row('Recommendation Result', data.recommendation_result)}
              {row('General Conclusion', data.general_conclusion)}
              {row('Purpose', data.purpose)}
              {row(
                'Attachment',
                data.attachments?.length > 0 ? (
                  <div className="attachments row">
                    {data.attachments?.map((atch, i) => (
                      <AttachmentCard
                        key={`${i}-atch-assessment-detail`}
                        name={atch.name || '-'}
                        url={atch.url || atch.link}
                      />
                    ))}
                  </div>
                ) : (
                  '-'
                )
              )}
            </tbody>
          </table>
        </ItemWrapper>
      </div>
    </ModalDetail>
  )
}

export default AssessmentDetail
