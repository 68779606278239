import gql from 'graphql-tag'

export const GET_SUCCESSION_CHART = gql`
  query($where: company_job_profiles_bool_exp!) {
    company_job_profiles(where: $where) {
      id
      title
      parent
      people_work_placements(
        where: {status: {_eq: "ACTIVE"}, global_user: {status: {_eq: "ACTIVE"}}}
      ) {
        id
        global_user {
          id
          name
          avatar
        }
      }
      count_successor: talent_pull_successor_aggregate(
        where: {
          candidate_status: {_eq: "SUCCESSOR"}
          people_work_placement: {
            status: {_eq: "ACTIVE"}
            global_user: {status: {_eq: "ACTIVE"}}
          }
        }
      ) {
        aggregate {
          count
        }
      }
      count_talent_pool: talent_pull_successor_aggregate(
        where: {
          candidate_status: {_eq: "TALENT"}
          people_work_placement: {
            status: {_eq: "ACTIVE"}
            global_user: {status: {_eq: "ACTIVE"}}
          }
        }
      ) {
        aggregate {
          count
        }
      }
      talent_pull_successor(
        where: {
          candidate_status: {_eq: "SUCCESSOR"}
          people_work_placement: {
            status: {_eq: "ACTIVE"}
            global_user: {status: {_eq: "ACTIVE"}}
          }
        }
      ) {
        id
        candidate_status
        readiness_status
        people_work_placement {
          id
          global_user {
            id
            name
            avatar
          }
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`
