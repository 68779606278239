import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import ArrowBack from '@material-ui/icons/ArrowBack'
import classNames from 'classnames'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import RSelect, {components} from 'react-select'
import helperStyle, {
  AddEditTitle,
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
  TextHelper,
  TitleName,
  TitleNumber,
} from '../../../../GlobalStyles'
import {
  INSERT_CASCADING_ITEM,
  UPDATE_CASECADING_KPI_LIST,
} from '../../../../graphql/mutations'
import {
  COUNT_CASCADING_GOAL,
  GET_ALL_KPI_LIST,
  GET_KPI_LIST_BY_CASCADING,
  GET_PARENT_KPI_LIST,
} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'

const AddEditDetailCascading = props => {
  const {
    idCascading,
    dataDetailCascading,
    dataParent,
    history,
    addChild,
  } = props
  const classes = helperStyle()
  const {Goal} = GLOBAL_GLOSSARY.performance
  const {enqueueSnackbar} = useSnackbar()
  const [isEdit, setIsEdit] = useState(false)
  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)
  const [swithChecked, setSwithChecked] = useState(false)
  const [allData, setAllData] = useState({
    kpi_parent: null,
    kpi: null,
    cascading: idCascading && idCascading.id,
  })

  const {
    data: count,
    loading: loadingCount,
    error: errCount,
    refetch: refetchCount,
  } = useQuery(COUNT_CASCADING_GOAL, {
    variables: {
      cascading: idCascading && idCascading.id,
    },
  })

  const hasParent = dataDetailCascading?.kpi_parent || false

  const isNewCascading =
    count && count.kpi_cascading_lists_aggregate.aggregate.count === 0

  const isOnlyParent =
    count && count.kpi_cascading_lists_aggregate.aggregate.count === 1

  const {data, error} = useQuery(GET_ALL_KPI_LIST, {
    variables: {
      company: COMPANY_ID,
      cascading: idCascading && idCascading.id,
    },
    pollInterval: 500,
  })

  const {data: dataById, error: errById} = useQuery(GET_KPI_LIST_BY_CASCADING, {
    variables: {
      company: COMPANY_ID,
      cascading: idCascading && idCascading.id,
    },
    pollInterval: 500,
  })

  const {data: dataParentDropdown, error: errorParent} = useQuery(
    GET_PARENT_KPI_LIST,
    {
      variables: {
        cascading: idCascading?.id,
        kpi: dataDetailCascading?.id_kpi,
        search: '%%',
        is_editing: true,
      },
      skip: !isEdit,
      pollInterval: 500,
    }
  )

  useEffect(() => {
    if (dataDetailCascading) {
      setIsEdit(true)
      setAllData({
        kpi_parent: dataDetailCascading.kpi_parent,
        kpi: dataDetailCascading.id_kpi,
        cascading: idCascading && idCascading.id,
      })
      if (!dataDetailCascading.kpi_parent) {
        setSwithChecked(true)
      }
    } else if (dataParent) {
      setAllData({
        kpi_parent: dataParent.id_kpi,
        cascading: idCascading && idCascading.id,
      })
    }
    if (count) {
      refetchCount().then(d => {
        if (d.data.kpi_cascading_lists_aggregate.aggregate.count === 0) {
          setSwithChecked(true)
        }
      })
    }
    // if (data && dataById && dataParentDropdown) {
    //   refetch()
    //   refetchById()
    //   refetchParent()
    // }
  }, [
    isEdit,
    dataDetailCascading,
    dataParent,
    count,
    // data,
    // dataById,
    // dataParentDropdown,
  ])

  const [mutationAddCascadingKpiList] = useMutation(INSERT_CASCADING_ITEM)
  const [mutationEditCascadingKpiList] = useMutation(
    UPDATE_CASECADING_KPI_LIST(dataDetailCascading && dataDetailCascading.id)
  )

  if (loadingCount) {
    return <CircularProgress />
  }
  if (error || errCount || errById || errorParent) {
    return (
      <div>{JSON.stringify(error || errCount || errById || errorParent)}</div>
    )
  }

  const listKpiEditDropdown = dataParentDropdown?.getKpiListDropdownAutocomplete.filter(
    options => options.id !== dataDetailCascading?.kpi_main?.id
  )

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  const mutateScoring = () => {
    if (swithChecked) {
      if (!allData.kpi) {
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else {
        const data = allData
        if (!isEdit) {
          mutationAddCascadingKpiList({variables: {data: data}})
            .then(() => {
              enqueueSnackbar(`Success Add data`, {variant: 'success'})
              history.push({
                pathname: '/performance/kpi/cascading/detail',
                state: {active: 'cascading-detail', dataCascading: idCascading},
              })
            })
            .catch(() => {
              enqueueSnackbar('Update data error, please try again', {
                variant: 'error',
              })
            })
        } else {
          mutationEditCascadingKpiList({variables: {dataEdited: data}})
            .then(() => {
              enqueueSnackbar(`Success Update data`, {
                variant: 'success',
              })
              history.push({
                pathname: '/performance/kpi/cascading/detail',
                state: {active: 'cascading-detail', dataCascading: idCascading},
              })
            })
            .catch(() => {
              enqueueSnackbar('Update data error, please try again', {
                variant: 'error',
              })
            })
        }
      }
    } else {
      if (!allData.kpi || !allData.kpi_parent) {
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else {
        const data = allData
        if (!isEdit) {
          mutationAddCascadingKpiList({variables: {data: data}})
            .then(() => {
              enqueueSnackbar(`Success Add data`, {variant: 'success'})
              history.push({
                pathname: '/performance/kpi/cascading/detail',
                state: {active: 'cascading-detail', dataCascading: idCascading},
              })
            })
            .catch(() => {
              enqueueSnackbar('Update data error, please try again', {
                variant: 'error',
              })
            })
        } else {
          mutationEditCascadingKpiList({variables: {dataEdited: data}})
            .then(() => {
              enqueueSnackbar(`Success Update data`, {
                variant: 'success',
              })
              history.push({
                pathname: '/performance/kpi/cascading/detail',
                state: {active: 'cascading-detail', dataCascading: idCascading},
              })
            })
            .catch(() => {
              enqueueSnackbar('Update data error, please try again', {
                variant: 'error',
              })
            })
        }
      }
    }
  }

  const MenuList = props => {
    return (
      <components.MenuList {...props}>
        <div className={classNames(classes.paddingxy10, classes.rselect)}>
          <Typography
            classes={{
              subtitle1: classes.lableTitle,
            }}
            className={classNames(
              classes.width20P,
              classes.fontSize14,
              classes.margin16
            )}
            variant="subtitle1"
          >
            Kode {Goal}
          </Typography>
          <Typography
            classes={{
              subtitle1: classes.lableTitle,
            }}
            className={classNames(
              classes.width30P,
              classes.fontSize14,
              classes.margin16
            )}
            variant="subtitle1"
          >
            Level
          </Typography>
          <Typography
            classes={{
              subtitle1: classes.lableTitle,
            }}
            className={classNames(
              classes.width50P,
              classes.fontSize14,
              classes.margin16
            )}
            variant="subtitle1"
          >
            Nama {Goal}
          </Typography>
        </div>
        <Divider className={classes.height3} />
        {props.children}
      </components.MenuList>
    )
  }

  const label = option => {
    return (
      <div className={classNames(classes.paddingxy10, classes.rselect)}>
        <span
          style={{
            width: 80,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: 0,
          }}
        >
          {option.code}
        </span>
        <span
          style={{
            Width: 172,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: '0px 10px 0px 16px',
          }}
          className={classes.width30P}
        >
          {option && option.kpi_level && option.kpi_level.name}
        </span>
        <span className={classes.width50P}>{option.name}</span>
      </div>
    )
  }

  const handleChange = (value, type) => {
    setAllData({
      ...allData,
      [type]: value,
    })
  }

  const selectComponent = (isParent, isChecked) => {
    const options = () => {
      let _opt
      if (!isNewCascading && !isChecked && isParent) {
        if (isEdit) {
          _opt = listKpiEditDropdown
        } else {
          _opt = dataById?.kpi_lists
        }
      } else {
        _opt = data?.kpi_lists
      }
      return _opt
    }

    const _value = () => {
      const dataByIdParent = dataById?.kpi_lists?.filter(
        options => options.id === allData.kpi_parent
      )
      const dataByIdKpi = dataById?.kpi_lists?.filter(
        options => options.id === allData.kpi
      )
      const dataKpi = data?.kpi_lists?.filter(
        options => options.id === allData.kpi
      )
      const dataSiblingsParent = listKpiEditDropdown?.filter(
        options => options.id === allData.kpi_parent
      )

      let _val
      if (!isChecked && isParent) {
        if (isEdit) {
          if (dataDetailCascading.kpi_parent === allData.kpi_parent) {
            _val = dataByIdParent
          } else {
            _val = dataSiblingsParent
          }
        } else {
          _val = dataByIdParent
        }
      } else if (isChecked || !isParent) {
        if (!isEdit) {
          _val = dataKpi
        } else if (isEdit && !allData?.kpi_parent) {
          if (
            !allData?.kpi_parent &&
            dataDetailCascading.id_kpi === allData.kpi
          ) {
            _val = dataByIdKpi
          } else if (
            !allData?.kpi_parent &&
            dataDetailCascading.id_kpi !== allData.kpi
          ) {
            _val = dataKpi
          } else {
            _val = dataByIdKpi
          }
        } else {
          _val = dataByIdKpi
        }
      } else {
        _val = dataKpi
      }
      return _val
    }
    return (
      <RSelect
        placeholder={`Pilih${isParent ? ' Induk' : ''} ${Goal}`}
        components={{MenuList}}
        value={_value()}
        isDisabled={(dataParent && isParent) || (isEdit && !isParent)}
        onChange={event => {
          handleChange(event.id, !isChecked && isParent ? 'kpi_parent' : 'kpi')
        }}
        getOptionValue={option => [
          option && option.name,
          option && option.kpi_level && option.kpi_level.name,
          option && option.code,
        ]}
        getOptionLabel={option => label(option)}
        options={options()}
        // isLoading={loading || loadingById}
        // onInputChange={d => setSearchDropdown(d)}
      />
    )
  }

  const handleSwitch = event => {
    setSwithChecked(event.target.checked)
    if (!isEdit && !dataParent) {
      setAllData({
        ...allData,
        kpi: null,
        kpi_parent: null,
      })
    }
  }

  const action = isEdit ? 'Edit' : 'Add'

  return (
    <PaperContainer
      style={{minHeight: 545, overflow: 'visible', marginBottom: 20}}
      className={classes.notViewScroll}
    >
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link
            to={{
              pathname: '/performance/kpi/cascading/detail',
              state: {active: 'cascading-detail', dataCascading: idCascading},
            }}
          >
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6" display="inline">
            {isEdit ? 'Ubah' : 'Tambah'} {Goal}
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            size="large"
            component={Link}
            to={{
              pathname: '/performance/kpi/cascading/detail',
              state: {active: 'cascading-detail', dataCascading: idCascading},
            }}
            style={{marginRight: 12}}
          >
            Batalkan
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="large"
          >
            Simpan
          </Button>
        </div>
      </FormToolbar>
      <Divider></Divider>
      <FormContainer>
        {swithChecked ? (
          <>
            <FormChildContainer className={classes.widthFull}>
              <div className={classes.rselect}>
                <FormChildTitle>
                  <TitleNumber>1.</TitleNumber>
                  <TitleName>Induk {Goal}</TitleName>
                </FormChildTitle>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isNewCascading || (isOnlyParent && isEdit)}
                      checked={swithChecked}
                      onChange={handleSwitch}
                      color="primary"
                    />
                  }
                  label="Hanya untuk Induk?"
                />
              </div>
              <FormChildInput className={classes.width56P}>
                {selectComponent(true, true)}
              </FormChildInput>
              <TextHelper>
                {!allData.kpi && isError ? 'Bagian ini diperlukan' : ''}
              </TextHelper>
            </FormChildContainer>{' '}
          </>
        ) : (
          <>
            <FormChildContainer className={classes.widthFull}>
              <div className={classes.rselect}>
                <FormChildTitle>
                  <TitleNumber>1.</TitleNumber>
                  <TitleName>Induk {Goal}</TitleName>
                </FormChildTitle>
                {!addChild && !hasParent && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={swithChecked}
                        onChange={handleSwitch}
                        color="primary"
                      />
                    }
                    label="Hanya untuk Induk?"
                  />
                )}
              </div>
              <FormChildInput className={classes.width56P}>
                {selectComponent(true, false)}
                <TextHelper>
                  {!allData.kpi_parent && isError
                    ? 'Bagian ini diperlukan'
                    : ''}
                </TextHelper>
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer className={classes.width60P}>
              <FormChildTitle>
                <TitleNumber>2.</TitleNumber>
                <TitleName>{Goal} *</TitleName>
              </FormChildTitle>
              <FormChildInput className={classes.marginTop15}>
                {selectComponent(false)}
                <TextHelper>
                  {!allData.kpi && isError ? 'Bagian ini diperlukan' : ''}
                </TextHelper>
              </FormChildInput>
            </FormChildContainer>
          </>
        )}
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        mutation={mutateScoring}
        header={CONFIRMATION[action]?.header}
        body={CONFIRMATION[action]?.body}
        button={CONFIRMATION[action]?.button}
        buttonClose="Batalkan"
      />
    </PaperContainer>
  )
}

export default withRouter(AddEditDetailCascading)

const CONFIRMATION = {
  Add: {
    header: `Tambahkan Turunan ${GLOBAL_GLOSSARY.performance.Goal}?`,
    body: `Apakah anda yakin akan menambahkan Turunan ${GLOBAL_GLOSSARY.performance.Goal} ini?`,
    button: 'Tambah',
  },
  Edit: {
    header: `Ubah Turunan ${GLOBAL_GLOSSARY.performance.Goal}?`,
    body: `Apakah anda yakin akan mengubah Turunan ${GLOBAL_GLOSSARY.performance.Goal} ini?`,
    button: 'Ubah',
  },
}
