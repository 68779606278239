import gql from 'graphql-tag'

export const RESEND_MEMBER_INVITE = gql`
  mutation update_holding_relations(
    $id: Int
    $changes: holding_relations_set_input
  ) {
    update_holding_relations(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`
