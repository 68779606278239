import React, {useState, useEffect} from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {WhiteModalHeader} from '../../../../../GlobalStyles'
import {useLazyQuery, useMutation} from '@apollo/react-hooks'
import {
  TERMINATE_EMPLOYEE,
  LIST_TERMINATION_STATUS,
} from '../../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {convertDate} from '../../../../../utils/helpers'
import {COMPANY_ID} from '../../../../../utils/globals'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},
  textLabel: {marginTop: 20, marginBottom: 10},

  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnDel: {
    backgroundColor: '#ef4d5e',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ef4d5e',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {wordBreak: 'break-all'},
}))

export default function TerminatePopup(props) {
  const classes = useStyles()
  const {open, handleClose, name, id, working, userId} = props
  const [checked, setChecked] = useState(false)
  const [terminateEmp] = useMutation(TERMINATE_EMPLOYEE)
  const {enqueueSnackbar} = useSnackbar()
  const [terminationStatus, setTerminationStatus] = useState('')
  const [reason, setReason] = useState('')

  const [
    getListTerminationStatus,
    {data: dataTerminationStatus},
  ] = useLazyQuery(LIST_TERMINATION_STATUS)

  useEffect(() => {
    if (open) {
      getListTerminationStatus()
    }
  }, [open, getListTerminationStatus])

  const onTerminateEmp = () => {
    const _talent_information =
      working.length > 0 &&
      working[0] &&
      working[0].placement_fields &&
      working[0].placement_fields.talent_information

    const talent_information = {
      risk_of_loss:
        (_talent_information && _talent_information.risk_of_loss) || 'LOW',
      future_leader:
        (_talent_information && _talent_information.future_leader) || false,
      impact_of_loss:
        (_talent_information && _talent_information.impact_of_loss) || 'LOW',
      reason_for_leaving: reason,
    }

    const submit = {
      idPlacement: id,
      idTerminationStatus: terminationStatus,
      endDate: convertDate(new Date()),
      data: {
        talent_information,
      },
      company: COMPANY_ID,
      user: userId
    }
    terminateEmp({
      variables: submit,
    })
      .then(() => {
        enqueueSnackbar('Success Terminate Employee', {
          variant: 'success',
          autoHideDuration: 2000,
        })
        setChecked(false)
        handleClose()
      })
      .catch(e =>
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
        })
      )
  }

  const handleChange = (e, target) => {
    if (target === 'checkbox') {
      setChecked(e.target.checked)
    } else if (target === 'status') {
      setTerminationStatus(e.target.value)
    } else if (target === 'reason') {
      setReason(e.target.value)
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <WhiteModalHeader>
        <Typography variant="h6" color="error" className={classes.wordBreak}>
          Terminate {name}?
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon>close</Icon>
        </IconButton>
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        <div>
          <Typography variant="body2" display="inline">
            Are you sure to terminate
          </Typography>
          <Typography
            variant="body2"
            color="error"
            display="inline"
            className={classes.wordBreak}
          >
            {' '}
            {name}{' '}
          </Typography>
          <Typography variant="body2" display="inline">
            ?
          </Typography>
        </div>
        <Typography className={classes.textLabel} variant="body2">
          Termination Status
        </Typography>
        <FormControl fullWidth variant="outlined">
          <Select
            value={terminationStatus}
            onChange={e => handleChange(e, 'status')}
            displayEmpty
            inputProps={{'aria-label': 'Without label'}}
          >
            <MenuItem value={''} disabled>
              Choose Termination Status
            </MenuItem>
            {dataTerminationStatus &&
              dataTerminationStatus.termination_status.map(res => (
                <MenuItem key={`${res.id}-idx`} value={res.id}>
                  {res.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Typography className={classes.textLabel} variant="body2">
          Reason for leaving
        </Typography>
        <TextField
          placeholder="Add Reason for living"
          multiline
          rows={4}
          fullWidth
          value={reason}
          variant="outlined"
          onChange={e => handleChange(e, 'reason')}
        />
        <div className={classes.checkContainer}>
          <Checkbox
            checked={checked}
            onChange={e => handleChange(e, 'checkbox')}
            color="primary"
            className={classes.checkbox}
          />
          <Typography className={classes.textLabel} variant="body2">
            I understand that termination of this employee will transfer to
            Alumni data & all access from this employee to this office will be
            terminated
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={() => {
            setChecked(false)
            handleClose()
          }}
        >
          Cancel
        </Button>
        <Button
          className={classes.btnDel}
          disabled={
            !checked ||
            reason === '' ||
            !terminationStatus ||
            terminationStatus === ''
          }
          variant="contained"
          onClick={onTerminateEmp}
        >
          Terminate
        </Button>
      </DialogActions>
    </Dialog>
  )
}
