import {
  makeStyles,
  Select,
  FormHelperText,
  FormControl,
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  inputStyle: {
    padding: '15px 18px',
  },
  inputStyleTextArea: {
    padding: 0,
  },
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
    marginBottom: 17,
  },
  root: {
    marginBottom: 32,
  },
}))
const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: '#aaa',
  },
}))
const Placeholder = ({children}) => {
  const classes = usePlaceholderStyles()
  return <div className={classes.placeholder}>{children}</div>
}
export const SelectForm = ({
  label,
  placeholder,
  child,
  error,
  value,
  helperText,
  multiline = false,
  ...props
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <p className={classes.label}>{label}</p>
      <FormControl error={error} style={{width: '100%'}}>
        <Select
          variant="outlined"
          fullWidth
          displayEmpty
          InputProps={{
            classes: {
              input: multiline
                ? classes.inputStyleTextArea
                : classes.inputStyle,
            },
          }}
          {...props}
          renderValue={
            value !== null
              ? undefined
              : () => <Placeholder>{placeholder}</Placeholder>
          }
          value={value}
        >
          {child}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  )
}
