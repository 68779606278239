import StructurePageComponent from './StructurePageComponent'

export const StructurePageConfig = {
  routes: [
    {
      path: '/member/structure',
      component: StructurePageComponent,
      state: 'member-structure',
      exact: true,
    },
  ],
}
