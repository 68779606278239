import ClaimPage from './ClaimPageComponent'

export const ClaimPageConfig = {
  routes: [
    {
      path: '/financial/claim/activity',
      component: ClaimPage,
      exact: true,
    },
    {
      path: '/financial/claim/balance',
      component: ClaimPage,
      exact: true,
    },
    {
      path: '/financial/claim/report',
      component: ClaimPage,
      exact: true,
    },
    {
      path: '/financial/claim/report/detail',
      component: ClaimPage,
      exact: true,
    },
    {
      path: '/financial/claim/settings',
      component: ClaimPage,
      exact: true,
    },
    {
      // settings-add
      path: '/financial/claim/settings/add',
      component: ClaimPage,
      exact: true,
    },
    {
      // settings-edit
      path: '/financial/claim/settings/:id/edit',
      component: ClaimPage,
      exact: true,
    },
  ],
}
