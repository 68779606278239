import React from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../../DetailEmployeeStyles'
import FormInput from '../../ShareComponents-detail/FormInput'
import {ButtonSubmit} from '../../../SharedDetailEmployee'

import usePrevious from '../../../../../../../hooks/usePrevious'
import {isErrorForm} from '../../ShareComponents-detail/helperDetail'
import {
  CHECK_DUPLICATE_IDENTITY_CARD,
  GET_DEPENDANTS_SEARCH,
  GET_INSURANCE_PROVIDERS,
} from '../../../../../../../graphql/queries'
import {
  UPDATE_IDENTITY_CARD,
  ADD_IDENTITY_CARD,
} from '../../../../../../../graphql/mutations'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_FORM_DATA = {
  type: null,
  provider: null,
  number: '',
  start_date: null,
  end_date: null,
  balance: '',
  dependents: [],
  description: '',
}

const INITIAL_SEARCH_STATE = {
  dependent: '',
  provider: '',
}

const PROVIDER_TYPE_OPTIONS = [
  {name: 'Asuransi Utama', value: 'government'},
  {name: 'Asuransi Tambahan', value: 'premium'},
]

const AddEditInsuranceData = props => {
  const {open = false, userId, initialData, onClose, onSubmit, onDelete} = props

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [formState, setFormState] = React.useState(STATE_INITIAL)
  const [formData, setFormData] = React.useState(INITIAL_FORM_DATA)

  const [search, setSearch] = React.useState(INITIAL_SEARCH_STATE)

  const isEditing = !!initialData

  const isError = formState === STATE_ERROR
  const isDispatching = formState === STATE_DISPATCH

  const prevOpen = usePrevious(open)
  const isNeverOpen = prevOpen === false && prevOpen === open

  const {data: dataProviders, loading: loadingProviders} = useQuery(
    GET_INSURANCE_PROVIDERS,
    {
      wlb_skipPatch: true,
      skip: isNeverOpen || !formData.type,
      variables: {
        search: `%${search.provider}%`,
        type: [formData.type],
        limit: 20,
      },
    }
  )

  const {data: dataDependents, loading: loadingDependents} = useQuery(
    GET_DEPENDANTS_SEARCH,
    {
      wlb_skipPatch: true,
      skip: isNeverOpen,
      variables: {
        userId: userId,
        id_nin: formData.dependents.map(dep => dep.value.id),
        search: `%${search.dependent}%`,
        limit: 20,
      },
    }
  )

  React.useEffect(() => {
    if (!open) {
      return
    }

    setFormState(STATE_INITIAL)

    if (initialData) {
      const provider = initialData.global_insurance_list
      const fields = initialData.identity_fields

      setFormData({
        type: provider.type,
        provider: {value: provider.enum, label: provider.name},
        number: initialData.number,
        start_date: fields.join_date,
        end_date: fields.end_date,
        balance: fields.balance_amount,
        dependents: fields.family.map(dep => ({
          label: dep.name,
          value: {
            id: dep.id,
            name: dep.name,
            relationship: dep.relationship,
          },
        })),
        description: fields.description,
      })
    } else {
      setFormData(INITIAL_FORM_DATA)
    }
  }, [open])

  if (isNeverOpen) {
    return null
  }

  // NOTE(intrnl): this is a workaround to get the insurance provider null'd
  // when the insurance type gets changed
  const handleSetValues = update => {
    setFormData(prev => {
      const next = typeof update === 'function' ? update(prev) : update

      if (prev.type !== next.type) {
        // it should be fine to mutate it here.
        next.provider = null
      }

      return next
    })
  }

  const bindAutocompleteSearch = field => query => {
    setSearch({...search, [field]: query})
  }

  const handleAutocompleteChange = item => {
    const {fieldName, label, value} = item

    const option = {label, value}

    if (fieldName === 'dependents') {
      setFormData({...formData, [fieldName]: [...formData.dependents, option]})
    } else {
      setFormData({...formData, [fieldName]: option})
    }
  }

  const handleSubmit = () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const data = {
      user: userId,

      type: formData.provider.value,
      id: formData.number,
      identity_fields: {
        join_date: formData.start_date,
        end_date: formData.end_date,
        balance_amount:
          formData.balance !== '' ? parseInt(formData.balance) : null,
        description: formData.description,
        family: formData.dependents.map(item => ({
          id: item.value.id,
          name: item.value.name,
          relationship: item.value.relationship,
        })),
      },
      deletedAt: null,
    }

    const verb = isEditing ? 'ubah' : 'tambah'
    let promise

    if (isEditing) {
      promise = client.mutate({
        mutation: UPDATE_IDENTITY_CARD,
        variables: {
          userId: userId,
          id: initialData.number,
          type: initialData.type,
          dateAdded: initialData.date_added,
          data: data,
        },
      })
    } else {
      const prom = client.query({
        query: CHECK_DUPLICATE_IDENTITY_CARD,
        fetchPolicy: 'network-only',
        variables: {
          userId: userId,
          id: data.id,
          type: data.type,
        },
      })

      promise = prom.then(result => {
        const check = result.data

        if (check && check.people_identities_aggregate.aggregate.count > 0) {
          return client.mutate({
            mutation: UPDATE_IDENTITY_CARD,
            variables: {
              userId: userId,
              id: data.id,
              type: data.type,
              data: data,
            },
          })
        } else {
          return client.mutate({
            mutation: ADD_IDENTITY_CARD,
            variables: {
              data: data,
            },
          })
        }
      })
    }

    promise.then(
      result => {
        const mutationData = result.data

        if (!isEditing) {
          if (
            !mutationData.update_people_identities &&
            !mutationData.insert_people_identities_one
          ) {
            enqueueSnackbar(`Asuransi sudah digunakan`, {variant: 'error'})
            setFormState(STATE_INITIAL)
            return
          }

          setFormData(INITIAL_FORM_DATA)
        }

        setFormState(STATE_INITIAL)
        enqueueSnackbar(`Data Asuransi di${verb}`, {variant: 'success'})

        if (onSubmit) {
          // NOTE(intrnl): insurance data can have duplicate IDs, so relying on
          // optimistic mutation would be rather catastrophic, let's just have
          // a refetch instead.

          // to explain further: Apollo uses normalized caching, let's imagine
          // a scenario where you have an array with two results, with both
          // having the same ID.

          // [
          //   { id: 1, name: 'Foo', age: 20, __typename: 'identities' },
          //   { id: 1, name: 'Bar', age: 30, __typename: 'identities' },
          // ]

          // now, for normalized caching, we'd take `__typename` and `id` as
          // the cache key, so that becomes `identities:1`, and then because
          // we've stored the full data on the cache, we no longer need the
          // full data on the response that was there earlier.

          // [
          //   { id: 1, __typename: 'identities' },
          //   { id: 1, __typename: 'identities' },
          // ]

          // this mutated response is now stored until it's needed again, where
          // it'll be expanded with the full data from the cache. did you spot
          // the issue? we merged Foo and Bar on the same `identities:1` key!
          // now the response looks like this!

          // [
          //   { id: 1, name: 'Bar', age: 30, __typename: 'identities' },
          //   { id: 1, name: 'Bar', age: 30, __typename: 'identities' },
          // ]

          // you should not allow duplicates sharing the same ID.

          // onSubmit(!initialData || initialData.id !== data.id)
          onSubmit(true)
        }
      },
      err => {
        if (err?.message?.includes('Uniqueness violation')) {
          enqueueSnackbar(`Asuransi sudah digunakan`, {variant: 'error'})
        } else {
          console.error(err)
          enqueueSnackbar(
            `Gagal untuk ${
              verb === 'tambah' ? 'menambahkan' : 'menghapus'
            } data asuransi`,
            {
              variant: 'error',
            }
          )
        }
        setFormState(STATE_INITIAL)
      }
    )
  }

  const fieldsList = [
    {
      type: 'select',
      fieldName: 'type',
      required: true,
      label: 'Tipe Asuransi*',
      placeholder: 'Pilih tipe asuransi',
      value: formData.type,
      error: !formData.id_type,
      option: PROVIDER_TYPE_OPTIONS,
    },
    {
      type: 'autocomplete',
      fieldName: 'provider',
      required: true,
      label: 'Nama Asuransi*',
      placeholder: 'Pilih nama asuransi',
      value: formData.provider,
      error: !formData.provider,
      isLoading: loadingProviders,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('provider'),
      option: dataProviders?.providers.map(item => ({
        value: item.enum,
        label: item.name,
      })),
    },
    {
      type: 'textfield',
      fieldName: 'number',
      required: true,
      label: 'Nomor Asuransi*',
      placeholder: 'Tambahkan Nomor Asuransi',
      value: formData.number,
      error: !formData.number,
    },
    {
      type: 'date',
      label: ['Tanggal Berlaku', 'Tanggal Berakhir'],
      fieldName: ['start_date', 'end_date'],
      value: [formData.start_date, formData.end_date, false, false],
      error: [false, formData.start_date > formData.end_date],
    },
    {
      type: 'textfield',
      inputType: 'number',
      fieldName: 'balance',
      label: 'Saldo',
      placeholder: 'Tambahkan saldo',
      value: formData.balance,
    },
    {
      type: 'autocomplete',
      fieldName: 'dependents',
      label: 'Keluarga dan Penerima',
      placeholder: 'Tambahkan keluarga dan penerima',
      value: null,
      chips: formData.dependents,
      isLoading: loadingDependents,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('dependent'),
      onDeleteChips: item => {
        const idx = item.i
        const arr = formData.dependents.slice()
        arr.splice(idx, 1)

        setFormData({...formData, dependents: arr})
      },
      option: dataDependents?.dependants.map(item => ({
        label: item.name,
        value: {
          id: item.id,
          name: item.name,
          relationship: item.relationship,
        },
      })),
    },
    {
      type: 'textfield',
      fieldName: 'description',
      multiline: true,
      rows: 3,
      label: 'Keterangan',
      placeholder: 'Tambahkan keterangan',
      value: formData.description,
      error: !formData.description,
    },
  ]

  return (
    <ModalDetail
      open={open}
      maxWidth="sm"
      title={`${isEditing ? 'Ubah' : 'Tambahkan'} data asuransi `}
      onClose={isDispatching ? null : onClose}
      onDelete={isEditing ? onDelete : null}
    >
      <ModalAddEditWraper>
        <FormInput
          fieldsList={fieldsList}
          setValues={handleSetValues}
          values={formData}
          errorState={isError}
          HELPER_TEXT="Bagian ini diperlukan"
        />
      </ModalAddEditWraper>

      <ButtonSubmit
        disabled={isDispatching}
        onCancel={onClose}
        onSave={handleSubmit}
      />
    </ModalDetail>
  )
}

export default AddEditInsuranceData
