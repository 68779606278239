import React from 'react'

import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 24,
  },
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
    marginBottom: 8,
  },
}))

export const RadioForm = props => {
  const {label, options, disabled, value, onChange, row} = props

  const styles = useStyles()

  return (
    <div className={styles.root}>
      {label && <p className={styles.label}>{label}</p>}

      <RadioGroup value={value} onChange={onChange} row={row} color="primary">
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            disabled={disabled}
            control={<Radio />}
            value={option.value}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </div>
  )
}
