import React, {useState, useEffect} from 'react'
import {Button, CircularProgress, TablePagination} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {Link} from 'react-router-dom'

import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import PayrollSettingsWrapper from '../PayrollSettingsWrapper'
import PremiumListItem from './premium-payroll/PremiumListItem'
import {CenterWrapper} from '../../PayrollPageStyles'
import usePagination from '../../hooks/usePagination'
import {FromButton} from '../../../../../GlobalStyles'

import {COMPANY_ID} from '../../../../../utils/globals'
import {GET_PREMIUM_SETTING} from '../../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../utils'

const PremiumPayrollSettings = props => {
  const {activeComponent, setActiveComponent, generalSettingData} = props

  const [listOfPremiums, setListOfPremiums] = useState([])
  const [totalData, setTotalData] = useState(0)
  const {
    pageSizeOption,
    limit,
    offset,
    currentPage,
    rowPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination()

  const {data: premiumSettingData, loading, error} = useQuery(
    GET_PREMIUM_SETTING,
    {
      variables: {
        company: COMPANY_ID,
        limit: limit,
        offset: offset,
      },
      ...GQL_FINANCE_OPTIONS,
      fetchPolicy: 'cache-and-network',
    }
  )

  useEffect(() => {
    if (premiumSettingData) {
      if (premiumSettingData.finance_insurance_premiums) {
        setListOfPremiums(premiumSettingData.finance_insurance_premiums)
        setTotalData(premiumSettingData.total.aggregate.count)
      }
    }
  }, [premiumSettingData])

  return (
    <PayrollSettingsWrapper
      activeComponent={activeComponent}
      setActiveComponent={setActiveComponent}
      hasChange={false}
      generalSettingData={generalSettingData}
    >
      {listOfPremiums.length > 0 && (
        <FromButton style={{minHeight: 0}}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            component={Link}
            to={{
              pathname: '/financial/payroll/setting/add-premium',
              state: {active: 'settings-add-premium'},
            }}
          >
            Add Premium
          </Button>
        </FromButton>
      )}
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {listOfPremiums.length === 0 && !loading && (
        <NoDataListComponent message={`You can start add premium here`}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            component={Link}
            to={{
              pathname: '/financial/payroll/setting/add-premium',
              state: {active: 'settings-add-premium'},
            }}
          >
            Add Premium
          </Button>
        </NoDataListComponent>
      )}
      {listOfPremiums.map((item, i) => {
        return <PremiumListItem key={i} data={item} isOpen={i === 0} />
      })}
      {totalData > 0 && (
        <TablePagination
          rowsPerPageOptions={pageSizeOption}
          component="div"
          rowsPerPage={rowPerPage}
          page={currentPage}
          count={totalData}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </PayrollSettingsWrapper>
  )
}

export default PremiumPayrollSettings
