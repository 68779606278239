import React from 'react'

import {IconButton, Link} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import {ItemWrapper} from '../../../DetailEmployeeStyles'

const li = content => (content ? <li>{content}</li> : null)

const MedicalRecordItem = props => {
  const {data, onEdit, onDetails} = props

  return (
    <ItemWrapper>
      <h1 className="title">{data.medical_test_name}</h1>
      <p className="paragraph">{data.medical_test_reason}</p>

      <ul className="list">
        {li(data.additional_medical_conditions)}
        {li(data.disabilities)}
        {li(data.hospitalized_reason)}
      </ul>

      <Link color="secondary" component="button" onClick={onDetails}>
        Show Medical Profile Details
      </Link>

      <IconButton onClick={onEdit} className="edit">
        <EditIcon />
      </IconButton>
    </ItemWrapper>
  )
}

export default MedicalRecordItem
