import gql from 'graphql-tag'

export const UPDATE_VERSION_CROSS_MENTORING = gql`
  mutation(
    $mentoringId: uuid
    $status: String!
    $name: String!
    $description: String!
    $start_date: date!
    $end_date: date!
  ) {
    update_classroom_cross_mentoring_versions(
      where: {id: {_eq: $mentoringId}}
      _set: {
        status: $status
        name: $name
        description: $description
        start_date: $start_date
        end_date: $end_date
      }
    ) {
      affected_rows
    }
  }
`
