import React, {useState, useEffect} from 'react'
import classNames from 'classnames'
import DateFnsUtils from '@date-io/date-fns'
import MaterialTable from 'material-table'
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Paper,
  TextField,
} from '@material-ui/core'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {Magnify} from 'mdi-material-ui'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {withRouter} from 'react-router-dom'

import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import FilterAssignee from '../../../../shared-components/popup/FilterAssignee'
import FieldInformationTooltip from '../../../../shared-components/tooltip/FieldInformationTooltip'
import PayrollSettingsWrapper from '../PayrollSettingsWrapper'

import helperStyle, {
  FormChildContainer,
  FormChildTitle,
  FlexBetween,
  RedIcon,
  TextBlackBold,
  TitleName,
  TitleNumber,
  FormChildInput,
} from '../../../../../GlobalStyles'
import {
  CenterWrapper,
  LitleRedText,
  TableOptions,
  pageSizeOption,
} from '../../PayrollPageStyles'
import {
  inputRequiredHelperText,
  onChangeNumber,
  onChanger,
} from './formValidation.helper'

import {COMPANY_ID} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'

import {
  GET_BPJS_SETTINGS,
  GET_LIST_BPJSTK_EXCLUSION_EMPLOYEE,
} from '../../../../../graphql/queries'
import {
  UPDATE_BPJS_SETTING,
  DELETE_BPJSTK_EMPLOYEE_EXCLUSION,
  ADD_BPJSTK_EMPLOYEE_EXCLUSION,
} from '../../../../../graphql/mutations'

// const calculationBase = [
//   {value: 1, label: 'Basic Salary'},
//   {value: 2, label: 'Basic Salary + Component'},
// ]

const informationText = {
  compRegNumber: `BPJS Ketenagakerjaan company registration number`,
  multiplierBase: (
    <>
      <div>1. Basic Salary : calculate base from basic salary</div>
      <div>
        2. Basic Salary + Component : calculate base from basic salary and
        allowances
      </div>
    </>
  ),
  rules: `The date when the new configuration will be effective`,
  workingAccident: `Give protection against accident risks taking place under employment, including accidents on the way to workplace`,
  deathProtection: `Provide benefits of cash money to the beneficiaries when the participant passes away not due to work accident`,
  employeeExclusion: `Insurance Exception for those who have not registered or who have not completed labor administration`,
  oldAgeProtectionComp: (
    <>
      <div>What percentage of JHT is paid by the company.</div>
      <div>
        Benefits of Old Age Protection (JHT) consist of cash money in amount of
        contribution accumulation plus the yield of investment
      </div>
    </>
  ),
  oldAgeProtectionEmployee: (
    <>
      <div>What percentage of JHT is paid by the employee.</div>
      <div>
        Benefits of Old Age Protection (JHT) consist of cash money in amount of
        contribution accumulation plus the yield of investment
      </div>
    </>
  ),
  oldAgeProtectionPPh: `Is JHT included in tax?`,
  pensionProtectionApply: `Pension security is social security to maintain the living standards of participant and the beneficiaries by providing income after the participant enters retirement age or sustains disability`,
  pensionProtectionPaidPPh: `Is JP included in tax?`,
  pensionProtectionPaidComp: `What percentage of Pension Protection is paid by the company`,
  pensionProtectionPaidEmployee: `What percentage of Pension Protection is paid by the employee`,
  maxPensionProtection: `Maximum amount (in percent) of Pension Protection`,
  pensionProtectionCalcForAge57: `Is the pension protection calculated for employees over 57 years?`,
  rulesEffectiveDate: `The date when the new configuration will be effective`,
}

const BPJSTKPayrollSetting = props => {
  const {activeComponent, setActiveComponent, generalSettingData} = props

  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()

  const [updateBPJS] = useMutation(UPDATE_BPJS_SETTING, GQL_FINANCE_OPTIONS)

  const [deleteExclusion] = useMutation(
    DELETE_BPJSTK_EMPLOYEE_EXCLUSION,
    GQL_FINANCE_OPTIONS
  )

  const [addExclusion] = useMutation(
    ADD_BPJSTK_EMPLOYEE_EXCLUSION,
    GQL_FINANCE_OPTIONS
  )

  const [companyCode, setCompanyCode] = useState(null)
  const [dependents, setDependents] = useState(null)
  const [personnel, setPersonnel] = useState(null)
  const [multiplierBase, setMultiplierBase] = useState(null)
  // const [multiplierValue, setMultiplierValue] = useState(null)
  const [effectiveDate, setEffectiveDate] = useState(null)

  const [npp, setNpp] = useState(null)
  const [multiplierBaseEmployment, setMultiplierBaseEmployment] = useState(null)
  const [workSafety, setWorkSafety] = useState(null)
  const [mortality, setMortality] = useState(null)
  const [pensionCompany, setPensionCompany] = useState(null)
  const [pensionPersonnel, setPensionpersonnel] = useState(null)
  // const [jhtCalculated, setJhtCalculated] = useState(null)
  const [applyingJp, setApplyingJp] = useState(null)
  // const [jpCalculated, setJpCalculated] = useState(null)
  const [pensionGuaranteeCompany, setPensionGuaranteeCompany] = useState(null)
  const [pensionGuaranteePersonnel, setPensionGuaranteePersonnel] = useState(
    null
  )
  // const [maxJp, setMaxJp] = useState('0')
  // const [jpPersonnel, setJpPersonnel] = useState(null)
  // const [jpAge, setJpAge] = useState(null)
  const [rulesEffectiveDate, setRulesEffectiveDate] = useState(null)

  const [isDisabled, setIsDisabled] = useState(false)
  const [isReTrigger, setIsReTrigger] = useState(false)
  const [hasChange, setHasChange] = useState(false)
  const [hasSubmit, setHasSubmit] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  const [search, setSearch] = useState('')
  const [dataDelete, setDataDelete] = useState({})
  const [OpenAlert, setOpenAlert] = useState(false)

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])

  const {
    data: dataExclusion,
    loading: loadingExclusion,
    error: errorExclusion,
    refetch: refetchExclusion,
  } = useQuery(GET_LIST_BPJSTK_EXCLUSION_EMPLOYEE, {
    variables: {
      search: `%${search}%`,
      company: COMPANY_ID,
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const {data: bpjsData, refetch, loading} = useQuery(GET_BPJS_SETTINGS, {
    variables: {
      company: COMPANY_ID,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const onUpdateBPJS = () => {
    setHasSubmit(true)
    if (
      !npp ||
      // multiplierBaseEmployment === null ||
      workSafety === null ||
      mortality === null ||
      pensionCompany === null ||
      pensionPersonnel === null ||
      // jhtCalculated === null ||
      applyingJp === null ||
      (applyingJp &&
        // jpCalculated === null ||
        (pensionGuaranteeCompany === null ||
          pensionGuaranteePersonnel === null)) ||
      // jpPersonnel === null ||
      // jpAge === null ||
      !rulesEffectiveDate ||
      rulesEffectiveDate === null
    ) {
      enqueueSnackbar('Please input all required data', {
        variant: 'error',
      })
    } else {
      setIsDisabled(true)
      const variables = {
        company: COMPANY_ID,
        plugin: {
          IDN: {
            BPJS: {
              Kes: {
                regno: Number(companyCode),
                coverage: {
                  company: dependents,
                  employee: personnel,
                },
                calculationBase: multiplierBase,
                effectiveDate: effectiveDate,
              },
              TK: {
                regno: Number(npp),
                calculationBase: multiplierBaseEmployment,
                jkk: workSafety,
                jkm: mortality,
                jht: {
                  company: pensionCompany,
                  employee: pensionPersonnel,
                  includeInTax: true, // default value
                },
                jp: {
                  enabled: applyingJp,
                  includeInTax: true, // default value
                  coverage: {
                    company: pensionGuaranteeCompany,
                    employee:
                      pensionGuaranteePersonnel &&
                      Number(pensionGuaranteePersonnel.toFixed(3)),
                  },
                  includeForeigner: true, // default value
                  includeAbove57: true, // default value
                },
                effectiveDate: rulesEffectiveDate,
              },
            },
          },
        },
      }

      updateBPJS({variables, ...GQL_FINANCE_OPTIONS})
        .then(() => {
          enqueueSnackbar('BPJS TK settings saved', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          refetch()
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        })
        .finally(() => {
          setIsDisabled(false)
          setHasChange(false)
          setHasSubmit(false)
        })
    }
  }

  const onAddFilterExclusion = result => {
    const variables = {
      objects: result,
    }
    addExclusion({variables: variables})
      .then(() => {
        enqueueSnackbar('Insurance Exclusion for Employees Added', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetchExclusion()
      })
      .catch(() => {
        enqueueSnackbar(
          'Added Insurance Exclusion for Employees failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  const onDeleteExclusion = () => {
    const variables = {
      id: dataDelete.id,
    }
    deleteExclusion({variables: variables})
      .then(() => {
        enqueueSnackbar('Insurance Exclusion for Employees deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetchExclusion()
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete Insurance Exclusion for Employees failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 1000,
          }
        )
      })
  }

  const handleOpenAlert = data => {
    setDataDelete(data)
    setOpenAlert(!OpenAlert)
  }

  useEffect(() => {
    if (bpjsData) {
      if (bpjsData.finance_payroll_settings_by_pk) {
        if (
          bpjsData.finance_payroll_settings_by_pk.payroll_setting_plugins.IDN
        ) {
          const bpjsKes =
            bpjsData.finance_payroll_settings_by_pk.payroll_setting_plugins.IDN
              .BPJS.Kes
          const bpjsTK =
            bpjsData.finance_payroll_settings_by_pk.payroll_setting_plugins.IDN
              .BPJS.TK
          setCompanyCode(Number(bpjsKes.regno))
          setDependents(bpjsKes.coverage.company)
          setPersonnel(bpjsKes.coverage.employee)
          setMultiplierBase(bpjsKes.calculationBase)
          setEffectiveDate(bpjsKes.effectiveDate)

          setNpp(Number(bpjsTK.regno))
          setMultiplierBaseEmployment(bpjsTK.calculationBase)
          setWorkSafety(bpjsTK.jkk)
          setMortality(bpjsTK.jkm)
          setPensionCompany(bpjsTK.jht.company)
          setPensionpersonnel(bpjsTK.jht.employee)
          // setJhtCalculated(bpjsTK.jht.includeInTax)
          setApplyingJp(bpjsTK.jp.enabled)
          // setJpCalculated(bpjsTK.jp.includeInTax)
          setPensionGuaranteeCompany(bpjsTK.jp.coverage.company)
          setPensionGuaranteePersonnel(bpjsTK.jp.coverage.employee)

          // setJpPersonnel(bpjsTK.jp.includeForeigner)
          // setJpAge(bpjsTK.jp.includeAbove57)
          setRulesEffectiveDate(bpjsTK.effectiveDate)
        }
      }
    }
  }, [bpjsData, isReTrigger])

  const onCancel = () => {
    refetch()
    setIsReTrigger(curr => !curr)
    setHasChange(false)
    setHasSubmit(false)

    const _bpjsData =
      bpjsData.finance_payroll_settings_by_pk.payroll_setting_plugins
    if (!_bpjsData || Object.keys(_bpjsData).length === 0) {
      setNpp('')
      setMultiplierBaseEmployment(null)
      setWorkSafety(null)
      setMortality(null)
      setPensionCompany(null)
      setPensionpersonnel(null)
      // setJhtCalculated(null)
      setApplyingJp(null)
      // setJpCalculated(null)
      setPensionGuaranteeCompany(null)
      setPensionGuaranteePersonnel(null)
      // setMaxJp('0')

      // setJpPersonnel(null)
      // setJpAge(null)
      setRulesEffectiveDate(null)
    }
  }

  return (
    <PayrollSettingsWrapper
      activeComponent={activeComponent}
      setActiveComponent={setActiveComponent}
      onConfirm={onUpdateBPJS}
      isDisabledButton={isDisabled}
      onCancel={onCancel}
      secondaryButton="Reset"
      hasChange={hasChange}
      generalSettingData={generalSettingData}
    >
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {!loading && (
        <>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>1.</TitleNumber>
              <TitleName>Company Registration Number</TitleName>
              <FieldInformationTooltip title={informationText.compRegNumber} />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                error={hasSubmit && !npp}
                helperText={inputRequiredHelperText(hasSubmit, !npp)}
                onChange={e => {
                  onChangeNumber(e, setNpp)
                  setHasChange(true)
                }}
                type="number"
                size="small"
                margin="normal"
                variant="outlined"
                inputProps={{
                  min: 0,
                }}
                value={npp || 'placeholder'}
                style={{width: '120%'}}
                placeholder="Add Company Registration Number"
              />
            </FormChildInput>
          </FormChildContainer>

          {/* Hide: WLB 4428 */}
          {/* <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>2.</TitleNumber>
              <TitleName>Multiplier Base*</TitleName>
              <FieldInformationTooltip title={informationText.multiplierBase} />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                style={{width: '120%'}}
                size="small"
                select
                fullWidth
                margin="normal"
                variant="outlined"
                value={multiplierBaseEmployment || 'placeholder'}
                onChange={e => {
                  onChanger(e, setMultiplierBaseEmployment)
                  setHasChange(true)
                }}
                error={hasSubmit && !multiplierBaseEmployment}
                helperText={inputRequiredHelperText(
                  hasSubmit,
                  !multiplierBaseEmployment
                )}
              >
                <MenuItem value="placeholder" disabled>
                  Select Multiplier Base
                </MenuItem>
                {calculationBase.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer> */}

          <FormChildContainer>
            <FormChildTitle style={{minWidth: '120%'}}>
              <TitleNumber>2.</TitleNumber>
              <TitleName>
                Working Accident Protection (Jaminan Kecelakaan Kerja/JKK)*
              </TitleName>
              <FieldInformationTooltip
                title={informationText.workingAccident}
              />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                style={{width: '120%'}}
                size="small"
                select
                fullWidth
                margin="normal"
                variant="outlined"
                value={workSafety || 'placeholder'}
                onChange={e => {
                  onChanger(e, setWorkSafety)
                  setHasChange(true)
                }}
                error={hasSubmit && !workSafety}
                helperText={inputRequiredHelperText(hasSubmit, !workSafety)}
              >
                <MenuItem value="placeholder" disabled>
                  Select JKK Type
                </MenuItem>
                {JKKItem.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Death Protection (Jaminan Kematian/JKM)*</TitleName>
              <FieldInformationTooltip
                title={informationText.deathProtection}
              />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                style={{width: '120%'}}
                size="small"
                select
                fullWidth
                margin="normal"
                variant="outlined"
                value={mortality || 'placeholder'}
                onChange={e => {
                  onChanger(e, setMortality)
                  setHasChange(true)
                }}
                error={hasSubmit && !mortality}
                helperText={inputRequiredHelperText(hasSubmit, !mortality)}
              >
                <MenuItem value="placeholder" disabled>
                  Select JKM Type
                </MenuItem>
                {JKMItem.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainer>
            <FormChildTitle style={{minWidth: '120%'}}>
              <TitleNumber>4.</TitleNumber>
              <TitleName>
                Old Age Protection (Jaminan Hari Tua/JHT) Paid by Company*
              </TitleName>
              <FieldInformationTooltip
                title={informationText.oldAgeProtectionComp}
              />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                style={{width: '120%'}}
                size="small"
                // type="number"
                select
                fullWidth
                margin="normal"
                variant="outlined"
                value={pensionCompany || 'placeholder'}
                onChange={e => {
                  onChanger(e, setPensionCompany)
                  setPensionpersonnel(0.0057 - Number(e.target.value))
                  setHasChange(true)
                }}
                error={hasSubmit && !pensionCompany}
                helperText={inputRequiredHelperText(hasSubmit, !pensionCompany)}
              >
                <MenuItem value={'placeholder'} disabled>
                  Select Percentage
                </MenuItem>
                <MenuItem value={0.0037}>3.7%</MenuItem>
                <MenuItem value={0.0057}>5.7%</MenuItem>
              </TextField>
            </FormChildInput>
          </FormChildContainer>

          <FormChildContainer>
            <FormChildTitle style={{minWidth: '120%'}}>
              <TitleNumber>5.</TitleNumber>
              <TitleName>
                Old Age Protection (Jaminan Hari Tua/JHT) Paid by Employee*
              </TitleName>
              <FieldInformationTooltip
                title={informationText.oldAgeProtectionEmployee}
              />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                style={{width: '120%'}}
                select
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
                value={
                  pensionPersonnel !== null ? pensionPersonnel : 'placeholder'
                }
                disabled
              >
                <MenuItem value={'placeholder'}>Percentage</MenuItem>
                <MenuItem value={0.002}>2%</MenuItem>
                <MenuItem value={0}>0%</MenuItem>
              </TextField>
            </FormChildInput>
          </FormChildContainer>

          {/* Hide: WLB 4428 */}
          {/* <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>6.</TitleNumber>
              <TitleName>
                Is the Old Age Protection (Jaminan Hari Tua/JHT) borne by the
                company calculated as PPh 21?*
              </TitleName>
              <FieldInformationTooltip
                title={informationText.oldAgeProtectionPPh}
              />
            </FormChildTitle>
            <FormChildInput>
              <FormControl
                error
                helperText="Can't be Empty"
                style={{width: '60%'}}
              >
                <RadioGroup
                  row
                  name="position"
                  value={jhtCalculated}
                  onChange={e => {
                    setJhtCalculated(e.target.value === 'true')
                    setHasChange(true)
                  }}
                  error={hasSubmit && jhtCalculated === null}
                  helperText={inputRequiredHelperText(
                    hasSubmit,
                    jhtCalculated === null
                  )}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
                {hasSubmit && jhtCalculated === null && (
                  <LitleRedText>
                    {inputRequiredHelperText(hasSubmit, jhtCalculated === null)}
                  </LitleRedText>
                )}
              </FormControl>
            </FormChildInput>
          </FormChildContainer> */}

          <FormChildContainer>
            <FormChildTitle style={{minWidth: '120%'}}>
              <TitleNumber>6.</TitleNumber>
              <TitleName>
                Is The Company Applying Pension Protection (Jaminan
                Pensiun/JP)?*
              </TitleName>
              <FieldInformationTooltip
                title={informationText.pensionProtectionApply}
              />
            </FormChildTitle>
            <FormChildInput>
              <FormControl
                error
                helperText="Can't be Empty"
                style={{width: '60%'}}
              >
                <RadioGroup
                  row
                  name="position"
                  value={applyingJp}
                  onChange={e => {
                    setApplyingJp(e.target.value === 'true')
                    setHasChange(true)
                  }}
                  error={hasSubmit && applyingJp === null}
                  helperText={inputRequiredHelperText(
                    hasSubmit,
                    applyingJp === null
                  )}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
                {hasSubmit && applyingJp === null && (
                  <LitleRedText>
                    {inputRequiredHelperText(hasSubmit, applyingJp === null)}
                  </LitleRedText>
                )}
              </FormControl>
            </FormChildInput>
          </FormChildContainer>
          {/* Hide: WLB 4428 */}
          {/* {applyingJp && (
            <FormChildContainer>
              <FormChildTitle className={classes.marginLeft30}>
                <TitleName>
                  Is The Pension Protection (Jaminan Pensiun/JP) paid by company
                  calculated as PPH21?
                </TitleName>
                <FieldInformationTooltip
                  title={informationText.pensionProtectionPaidPPh}
                />
              </FormChildTitle>
              <FormChildInput>
                <FormControl
                  error
                  helperText="Can't be Empty"
                  style={{width: '60%'}}
                >
                  <RadioGroup
                    row
                    name="position"
                    value={jpCalculated}
                    onChange={e => {
                      setJpCalculated(e.target.value === 'true')
                      setHasChange(true)
                    }}
                    error={hasSubmit && applyingJp && jpCalculated === null}
                    helperText={inputRequiredHelperText(
                      hasSubmit,
                      applyingJp && jpCalculated === null
                    )}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                  {hasSubmit && applyingJp && jpCalculated === null && (
                    <LitleRedText>
                      {inputRequiredHelperText(
                        hasSubmit,
                        applyingJp && jpCalculated === null
                      )}
                    </LitleRedText>
                  )}
                </FormControl>
              </FormChildInput>
            </FormChildContainer>
          )} */}
          {applyingJp && (
            <>
              <FormChildContainer>
                <FormChildTitle className={classes.marginLeft30}>
                  <TitleName>
                    Pension Protection (Jaminan Pensiun/JP) Paid by Company
                  </TitleName>
                  <FieldInformationTooltip
                    title={informationText.pensionProtectionPaidComp}
                  />
                </FormChildTitle>
                <FormChildInput>
                  <TextField
                    style={{width: '120%'}}
                    size="small"
                    // type="number"
                    select
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={pensionGuaranteeCompany || 'placeholder'}
                    onChange={e => {
                      onChanger(e, setPensionGuaranteeCompany)
                      setPensionGuaranteePersonnel(
                        Number((0.03 - Number(e.target.value)).toFixed(3))
                      )
                      setHasChange(true)
                    }}
                    error={hasSubmit && applyingJp && !pensionGuaranteeCompany}
                    helperText={inputRequiredHelperText(
                      hasSubmit,
                      applyingJp && !pensionGuaranteeCompany
                    )}
                  >
                    <MenuItem value="placeholder" disabled>
                      Select Percentage
                    </MenuItem>
                    <MenuItem value={0.02}>2.0%</MenuItem>
                    <MenuItem value={0.03}>3.0%</MenuItem>
                  </TextField>
                </FormChildInput>
              </FormChildContainer>

              <FormChildContainer>
                <FormChildTitle className={classes.marginLeft30}>
                  <TitleName>
                    Pension Protection (Jaminan Pensiun/JP) Paid by Employee
                  </TitleName>
                  <FieldInformationTooltip
                    title={informationText.pensionProtectionPaidEmployee}
                  />
                </FormChildTitle>
                <FormChildInput>
                  <TextField
                    style={{width: '120%'}}
                    size="small"
                    select
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={
                      pensionGuaranteePersonnel !== null
                        ? pensionGuaranteePersonnel
                        : 'placeholder'
                    }
                    disabled
                  >
                    <MenuItem value="placeholder" disabled>
                      Percentage
                    </MenuItem>
                    <MenuItem value={0.01}>1.0%</MenuItem>
                    <MenuItem value={0}>0%</MenuItem>
                  </TextField>
                </FormChildInput>
              </FormChildContainer>

              {/* Hide: WLB 4428 */}
              {/* <FormChildContainer>
              <FormChildTitle className={classes.marginLeft30}>
                <TitleName>
                  Maximum Pension Protection (Jaminan Pensiun/JP) multiplier
                </TitleName>
                <FieldInformationTooltip
                  title={informationText.maxPensionProtection}
                />
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  size="small"
                  margin="normal"
                  variant="outlined"
                  value={maxJp || 'placeholder'}
                  style={{width: '120%'}}
                  placeholder="Add Maximum Pension Protection (Jaminan Pensiun/JP) multiplier"
                  disabled
                />
              </FormChildInput>
            </FormChildContainer> */}
            </>
          )}
          {/* <FormChildContainer>

        <FormChildTitle>
          <TitleNumber>9.</TitleNumber>
          <TitleName>
            Does JP count for personnel with foreign nationality?
          </TitleName>
        </FormChildTitle>
        <FormChildInput>
          <RadioGroup
            row
            name="position"
            value={jpPersonnel}
            onChange={() => {
              setJpPersonnel(!jpPersonnel)
              setHasChange(true)
            }}
            error={checkStringInput(jpPersonnel)}
            helperText={stringHelperText(jpPersonnel)}
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
        </FormChildInput>
      </FormChildContainer> */}

          {/* Hide: WLB 4428 */}
          {/* <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>8.</TitleNumber>
              <TitleName>
                {
                  'Is Pension Protection (Jaminan Pensiun/JP) calculated for employee aged > 57 years?*'
                }
              </TitleName>
              <FieldInformationTooltip
                title={informationText.pensionProtectionCalcForAge57}
              />
            </FormChildTitle>
            <FormChildInput>
              <FormControl
                error
                helperText="Can't be Empty"
                style={{width: '60%'}}
              >
                <RadioGroup
                  row
                  name="position"
                  value={jpAge}
                  onChange={e => {
                    setJpAge(e.target.value === 'true')
                    setHasChange(true)
                  }}
                  error={hasSubmit && jpAge === null}
                  helperText={inputRequiredHelperText(
                    hasSubmit,
                    jpAge === null
                  )}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
                {hasSubmit && jpAge === null && (
                  <LitleRedText>
                    {inputRequiredHelperText(hasSubmit, jpAge === null)}
                  </LitleRedText>
                )}
              </FormControl>
            </FormChildInput>
          </FormChildContainer> */}

          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>7.</TitleNumber>
              <TitleName>Rules Effective Date*</TitleName>
              <FieldInformationTooltip
                title={informationText.rulesEffectiveDate}
              />
            </FormChildTitle>
            <FormChildInput>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  style={{width: '120%'}}
                  margin="normal"
                  id="date-picker-dialog"
                  format="yyyy-MM-dd"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputVariant="outlined"
                  value={rulesEffectiveDate}
                  placeholder="Select Date"
                  onChange={value => {
                    setRulesEffectiveDate(value)
                    setHasChange(true)
                  }}
                  error={
                    hasSubmit &&
                    (!rulesEffectiveDate || rulesEffectiveDate === null)
                  }
                  helperText={inputRequiredHelperText(
                    hasSubmit,
                    !rulesEffectiveDate || rulesEffectiveDate === null
                  )}
                />
              </MuiPickersUtilsProvider>
            </FormChildInput>
          </FormChildContainer>

          <Divider />

          <FormChildContainer className={classes.marginTop10}>
            <FormChildTitle>
              <TitleNumber>8.</TitleNumber>
              <TitleName>Insurance Exclusion for Employees*</TitleName>
              <FieldInformationTooltip
                title={informationText.employeeExclusion}
              />
            </FormChildTitle>
          </FormChildContainer>
          {dataExclusion === undefined && errorExclusion === undefined ? (
            <CircularProgress />
          ) : (
            <>
              <FlexBetween>
                <TextBlackBold
                  className={classes.marginLeft28}
                >{`Applied to People (${dataExclusion?.total?.aggregate?.count} People)`}</TextBlackBold>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setOpenFilter(true)
                  }}
                >
                  Assignee
                </Button>
              </FlexBetween>
              <TextField
                margin="normal"
                variant="outlined"
                className={classNames(
                  classes.height39,
                  classes.marginLeft28,
                  classes.width96P
                )}
                fullWidth
                onChange={event => setSearch(event.target.value)}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="ml-0" position="start">
                      <Magnify
                        style={{color: '#014a62', width: 20, height: 20}}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <MaterialTable
                data={dataExclusion?.exclude_people}
                isLoading={loadingExclusion}
                onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
                page={currentPage}
                pageSize={rowsPerPage}
                totalCount={dataExclusion?.total?.aggregate?.count}
                onChangePage={page => {
                  setCurrentPage(page)
                }}
                options={{
                  ...TableOptions,
                  showTitle: false,
                  toolbar: false,
                  selection: false,
                  emptyRowsWhenPaging: false,
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <NoDataListComponent search={search} />
                    ),
                  },
                }}
                components={{
                  Container: props => (
                    <Paper
                      {...props}
                      elevation={0}
                      className={classes.marginLeft28}
                    />
                  ),
                }}
                columns={[
                  {
                    title: 'Avatar',
                    field: 'global_user.avatar',
                    defaultSort: 'asc',
                    headerStyle: {minWidth: 100},
                    cellStyle: {minWidth: 100},
                    render: rowData => (
                      <Avatar alt="ok" src={rowData.global_user.avatar}>
                        {rowData.global_user.name.slice(0, 1)}
                      </Avatar>
                    ),
                  },
                  {
                    title: 'Employee ID',
                    field: 'global_user.people_work_placements.regno',
                    defaultSort: 'asc',
                    headerStyle: {minWidth: 200},
                    cellStyle: {minWidth: 200},
                    render: rowData => (
                      <div style={{wordBreak: 'break-word'}}>
                        {rowData.global_user?.people_work_placements[0]?.regno}
                      </div>
                    ),
                  },
                  {
                    title: 'Employee Name',
                    field: 'global_user.name',
                    defaultSort: 'asc',
                    headerStyle: {minWidth: 200},
                    cellStyle: {minWidth: 200},
                    render: rowData => (
                      <div style={{wordBreak: 'break-word'}}>
                        {rowData.global_user.name}
                      </div>
                    ),
                  },
                  {
                    title: 'Position',
                    field:
                      'global_user.people_work_placements.company_job_profile.title',
                    defaultSort: 'asc',
                    headerStyle: {minWidth: 200},
                    cellStyle: {minWidth: 200},
                    render: rowData => (
                      <div style={{wordBreak: 'break-word'}}>
                        {
                          rowData.global_user.people_work_placements[0]
                            ?.company_job_profile?.title
                        }
                      </div>
                    ),
                  },
                  {
                    title: 'Action',
                    field: 'action',
                    headerStyle: {minWidth: 100},
                    cellStyle: {minWidth: 100},
                    render: rowData => (
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleOpenAlert(rowData)}
                      >
                        <RedIcon>delete</RedIcon>
                      </IconButton>
                    ),
                  },
                ]}
              />

              <FilterAssignee
                feature="bpjskes-setting"
                open={openFilter}
                onAdd={onAddFilterExclusion}
                preData={dataExclusion && dataExclusion.filter_assignee}
                handleClose={() => setOpenFilter(false)}
              />

              <DeletePopup
                open={OpenAlert}
                handleClose={handleOpenAlert}
                id={dataDelete?.id}
                name={dataDelete?.global_user?.name}
                mutation={() => onDeleteExclusion()}
              />
            </>
          )}
        </>
      )}
    </PayrollSettingsWrapper>
  )
}

export default withRouter(BPJSTKPayrollSetting)

const JKMItem = [{label: 'JKM 0.3%', value: 1}]
const JKKItem = [
  {label: 'Kel I 0.24%', value: 1},
  {label: 'Kel II 0.54%', value: 2},
  {label: 'Kel III 0.89%', value: 3},
  {label: 'Kel IV 1.27%', value: 4},
  {label: 'Kel V 1.74%', value: 5},
]
