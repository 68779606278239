import React, {useState} from 'react'
import {useQuery} from '@apollo/react-hooks'

import {ButtonAddDataTab} from '../data-tab-shared'
import {GridListing} from '../../../DetailEmployeeStyles'
import LoadingEmployee from '../../../../LoadingEmployee'

import MedicalHistoryAddEditDelete from './MedicalHistoryAddEditDelete'
import MedicalHistoryDetail from './MedicalHistoryDetail'
import MedicalHistoryItem from './MedicalHistoryItem'

import {GET_LIST_MEDICAL_HISTORY} from '../../../../../../../graphql/queries'

const MedicalHistory = ({userId}) => {
  const [modalDetail, setModalDetail] = useState({
    data: null,
    open: false,
  })
  const [modalAddEditDelete, setModalAddEditDelete] = useState({
    data: null,
    open: false,
    isEdit: false,
  })

  const {data, loading, refetch} = useQuery(GET_LIST_MEDICAL_HISTORY, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      userId,
    },
  })

  if (loading) {
    return <LoadingEmployee noborder smallpadding />
  }

  return (
    <>
      <GridListing>
        {data?.people_profile_medical_histories?.map(item => {
          return (
            <MedicalHistoryItem
              key={item.id}
              data={item}
              onDetails={data => setModalDetail({open: true, data: data})}
              onEdit={data =>
                setModalAddEditDelete({
                  open: true,
                  data: data,
                  isEdit: true,
                })
              }
            />
          )
        })}
      </GridListing>

      <ButtonAddDataTab
        onClick={() =>
          setModalAddEditDelete({open: true, data: null, isEdit: false})
        }
      >
        Tambahkan Rekam Medis
      </ButtonAddDataTab>

      <MedicalHistoryAddEditDelete
        {...modalAddEditDelete}
        refetch={refetch}
        userId={userId}
        onClose={() =>
          setModalAddEditDelete({open: false, data: null, isEdit: false})
        }
      />

      <MedicalHistoryDetail
        {...modalDetail}
        onClose={() => setModalDetail({open: false, data: null})}
      />
    </>
  )
}

export default MedicalHistory
