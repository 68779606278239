import React from 'react'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import moment from 'moment'

import {
  makeStyles,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import {FlexColumnStart} from '../../../../performance/goal/modal/modalStyles'
import {AttachmentItemRow} from '../../../../shared-components/attachment'
import UploadAttachment from './UploadAttachment'
import DetailAttachment from './DetailAttachment'
import {
  ConversionDisplay,
  InputConversionDisplay,
} from '../../BusinessTripStyles'

import {USER_ID} from '../../../../../utils/globals'
import {
  calculateBudget,
  getBudgetCalculationLabel,
} from '../../businessTripHelper'
import {formatCurrency, getCurrencySign} from '../../currencyExchanges'
import {UPDATE_NOMINAL_ACTIVITY_TRIP} from '../../../../../graphql/mutations'
import {GET_CURRENCY_EXCHANGE} from '../../../../../graphql/queries'

export const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    backgroundColor: '#eff2f4',
    fontWeight: 600,
  },
  tableFooterCell: {
    fontWeight: 600,
  },
  tableCell: {
    fontSize: 12,
  },

  currencyInput: {
    width: 140,

    '& .MuiTypography-root': {
      fontSize: 14,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 10,
    },
    '& .MuiInputBase-input': {
      MozApperance: 'textfield',
      textAlign: 'right',
      fontSize: 14,
      padding: `6px 10px 6px 0`,

      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
  },
  linkButton: {
    fontFamily: 'inherit',
    fontSize: 13,
  },
}))

const Destination = ({
  data,
  statusTrip,
  idUser,
  count,
  currencyFrom,
  currencyTo,
  approvedDate,
}) => {
  const styles = useStyles()

  const {enqueueSnackbar} = useSnackbar()
  const [updateBudget] = useMutation(UPDATE_NOMINAL_ACTIVITY_TRIP)

  const [openDetail, setOpenDetail] = React.useState({
    open: false,
    data: null,
  })
  const [attachment, setAttachment] = React.useState({
    open: false,
    data: null,
  })

  const canUserEdit =
    statusTrip === 'pending' ||
    statusTrip === 'confirm1' ||
    statusTrip === 'confirm2'
  const canUserProcess = statusTrip === 'processing'
  const canUserViewAttachments = canUserProcess || statusTrip === 'approved'

  let totalCost = 0

  for (const budget of data.budget) {
    totalCost += calculateBudget(data, budget)
  }

  const handleBudgetEdit = (id, nominal) => {
    const promise = updateBudget({
      variables: {
        id: id,
        destination_id: data.id,
        nominal: nominal,
        update_by: USER_ID,
      },
    })

    promise.catch(() => {
      enqueueSnackbar('Update Component failed, please try again later', {
        variant: 'error',
        autoHideDuration: 1000,
      })
    })
  }

  const tableInfo = data && [
    count > 1 && {
      label: 'Destination',
      value: `${data.globalDestinationByFrom.name} - ${data.globalDestinationByTo.name}`,
    },
    count > 1 && {
      label: 'Date',
      value:
        moment(data?.destination_start_date).format('MMMM Do') +
        ' - ' +
        moment(data?.destination_end_date).format('MMMM Do, YYYY'),
    },
    currencyFrom !== currencyTo && {
      label: 'Exchange Rate',
      value: (
        <ExchangeRateDisplay
          // NOTE(intrnl): Yes, this is intentionally flipped
          from={currencyTo}
          to={currencyFrom}
          date={approvedDate}
        />
      ),
    },
  ]

  return (
    <FlexColumnStart>
      <TableInfo data={tableInfo} />

      <TableContainer>
        <Table>
          <TableHead style={{backgroundColor: '#eff2f4'}}>
            <TableRow>
              <TableCell className={styles.tableHeaderCell}>
                Component
              </TableCell>
              <TableCell className={styles.tableHeaderCell}>
                Trip Duration
              </TableCell>
              <TableCell className={styles.tableHeaderCell}>
                Budget Calculation
              </TableCell>
              <TableCell className={styles.tableHeaderCell} align="right">
                Budget
              </TableCell>
              <TableCell className={styles.tableHeaderCell} align="right">
                Total
              </TableCell>
              {canUserViewAttachments && (
                <>
                  <TableCell className={styles.tableHeaderCell}>
                    Payment Type
                  </TableCell>
                  <TableCell className={styles.tableHeaderCell}>
                    Attachment
                  </TableCell>
                </>
              )}

              {(canUserEdit || canUserProcess) && (
                <TableCell
                  className={styles.tableHeaderCell}
                  align="center"
                  style={{width: 80}}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.budget.map(row => (
              <BudgetRow
                key={row.id}
                budget={row}
                trip={data}
                currencyFrom={currencyFrom}
                currencyTo={currencyTo}
                approvedDate={approvedDate}
                canUserEdit={canUserEdit}
                canUserProcess={canUserProcess}
                canUserViewAttachments={canUserViewAttachments}
                onEditBudget={nominal => handleBudgetEdit(row.id, nominal)}
                onProcessPayment={() => setAttachment({open: true, data: row})}
                onShowPaymentProof={() =>
                  setOpenDetail({open: true, data: row})
                }
              />
            ))}

            <TableRow>
              <TableCell colSpan="3" className={styles.tableFooterCell}>
                Total Budget Spent
              </TableCell>
              <TableCell
                colSpan={canUserViewAttachments ? 4 : 2}
                className={styles.tableFooterCell}
              >
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={currencyTo}
                  to={currencyFrom}
                  value={totalCost}
                  date={approvedDate}
                />
              </TableCell>

              {(canUserEdit || canUserProcess) && <TableCell />}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {data.last_modified !== null && (
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.57)',
            fontSize: 12,
            textAlign: 'right',
            width: '100%',
            margin: `8px 0`,
          }}
        >
          Last edited by {data.globalUserByUpdatedBy.name} at{' '}
          {moment(data.last_modified).format('MMM DD YYYY hh:mm A')}
        </div>
      )}

      <UploadAttachment
        open={attachment.open}
        onClose={() => {
          setAttachment({
            ...attachment,
            open: false,
          })
        }}
        data={attachment.data}
        idUser={idUser}
      />

      <DetailAttachment
        open={openDetail.open}
        onCancel={() => {
          setOpenDetail({
            ...openDetail,
            open: false,
          })
        }}
        data={openDetail.data}
      />
    </FlexColumnStart>
  )
}

export default Destination

const BudgetRow = ({
  budget,
  trip,
  currencyFrom,
  currencyTo,
  approvedDate,
  canUserEdit = false,
  canUserProcess = false,
  canUserViewAttachments = false,
  onEditBudget,
  onProcessPayment,
  onShowPaymentProof,
}) => {
  const styles = useStyles()

  const [isEditing, setIsEditing] = React.useState(false)
  const [nominal, setNominal] = React.useState(null)

  const attachments = budget.attachment || []

  const handleEdit = () => {
    setIsEditing(true)
    setNominal(budget.nominal)
  }

  const handleEditSubmit = () => {
    const number = Number(nominal)

    setIsEditing(false)

    if (!Number.isNaN(number) && number >= 0) {
      onEditBudget(number)
    }
  }

  const handleEditCancel = () => {
    setIsEditing(false)
  }

  return (
    <TableRow>
      <TableCell className={styles.tableCell}>
        {budget.business_trip_component.name}
      </TableCell>
      <TableCell className={styles.tableCell}>{trip.duration} Days</TableCell>
      <TableCell className={styles.tableCell}>
        {getBudgetCalculationLabel(budget)}
      </TableCell>
      <TableCell
        className={styles.tableCell}
        style={isEditing ? {padding: `0 4px`} : null}
        align="right"
      >
        {!isEditing ? (
          <ConversionDisplay
            // NOTE(intrnl): Yes, this is intentionally flipped
            from={currencyTo}
            to={currencyFrom}
            value={budget.nominal}
            date={approvedDate}
            smaller
          />
        ) : (
          <>
            <TextField
              autoFocus
              variant="outlined"
              size="small"
              type="number"
              value={nominal}
              onChange={ev => setNominal(ev.target.value)}
              className={styles.currencyInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getCurrencySign(currencyTo)}
                  </InputAdornment>
                ),
              }}
            />

            {currencyFrom !== currencyTo && (
              <InputConversionDisplay
                // NOTE(intrnl): Yes, this is intentionally flipped
                from={currencyTo}
                to={currencyFrom}
                value={nominal}
                dense
              />
            )}
          </>
        )}
      </TableCell>
      <TableCell className={styles.tableCell}>
        <ConversionDisplay
          // NOTE(intrnl): Yes, this is intentionally flipped
          from={currencyTo}
          to={currencyFrom}
          value={calculateBudget(trip, budget)}
          date={approvedDate}
          smaller
        />
      </TableCell>

      {canUserViewAttachments && (
        <TableCell className={styles.tableCell}>
          {budget.payment_type === 2 ? (
            <span>Ticket / Voucher</span>
          ) : budget.payment_type === 1 ? (
            <div>
              <div>Transfer</div>
              <div style={{color: 'rgba(0, 0, 0, 0.54)'}}>Transferred to</div>
              <div>{budget.people_profile_bank?.account_number}</div>
              <div>
                {budget.people_profile_bank?.bank_name} -{' '}
                {budget.people_profile_bank?.account_name}
              </div>
            </div>
          ) : (
            '-'
          )}
        </TableCell>
      )}

      {canUserViewAttachments && (
        <TableCell className={styles.tableCell}>
          {attachments.length < 1 && '-'}

          {attachments.slice(0, 3).map((file, idx) => (
            <AttachmentItemRow
              key={idx}
              fileName={file.name}
              url={file.url}
              iconAction={
                <VisibilityOutlinedIcon
                  className="icon"
                  onClick={() => window.open(file.url, '_blank')}
                />
              }
            />
          ))}

          {attachments.length > 3 && (
            <Link
              component="button"
              onClick={onShowPaymentProof}
              color="secondary"
            >
              ... {attachments.length - 3}+ more
            </Link>
          )}
        </TableCell>
      )}

      {canUserProcess ? (
        <TableCell className={styles.tableCell} align="center">
          <Link
            component="button"
            onClick={onProcessPayment}
            color="secondary"
            className={styles.linkButton}
          >
            Process Payment
          </Link>
        </TableCell>
      ) : canUserEdit ? (
        <TableCell className={styles.tableCell} align="center">
          {isEditing ? (
            <>
              <Link
                component="button"
                onClick={handleEditCancel}
                color="textSecondary"
                className={styles.linkButton}
                style={{marginRight: 8}}
              >
                Cancel
              </Link>

              <Link
                component="button"
                onClick={handleEditSubmit}
                color="secondary"
                className={styles.linkButton}
              >
                Save
              </Link>
            </>
          ) : (
            <Link
              component="button"
              onClick={handleEdit}
              color="secondary"
              className={styles.linkButton}
            >
              Edit
            </Link>
          )}
        </TableCell>
      ) : null}
    </TableRow>
  )
}

export const TableInfo = ({data}) => {
  const filtered = data.filter(el => !!el)

  if (filtered.length < 1) {
    return null
  }

  return (
    <table
      style={{
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 14,
        borderCollapse: 'collapse',
        margin: `2px 0 8px`,
      }}
    >
      <tbody>
        {filtered.map(({label, value}, idx) => (
          <tr key={idx}>
            <td>{label}</td>
            <td style={{padding: `0 8px`}}>:</td>
            <td style={{color: 'rgba(0, 0, 0, 0.87)'}}>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const ExchangeRateDisplay = ({from, to, date}) => {
  const {data} = useQuery(GET_CURRENCY_EXCHANGE, {
    wlb_skipPatch: true,
    fetchPolicy: date ? 'cache-first' : 'cache-and-network',
    variables: {
      currency: from,
      date: date ? date.split('T')[0] : '',
    },
  })

  const formattedFrom = React.useMemo(() => {
    return formatCurrency(from, 1)
  }, [from])

  const formattedTo = React.useMemo(() => {
    return data && formatCurrency(to, data.getCurrencyExchange.idr)
  }, [to, data])

  return (
    <span>
      <span>{formattedFrom}</span>
      <span> = </span>
      <span>{data ? formattedTo : `${getCurrencySign(to)} ⋯`}</span>
    </span>
  )
}
