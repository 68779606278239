import React from 'react'
import OrgChart from 'react-orgchart'
import 'react-orgchart/index.css'
import {Button} from '@material-ui/core'
import {GreyIcon} from '../../../../GlobalStyles'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'

const CompanyStructure = ({MyNodeComponent, structured, classes}) => {
  return (
    <TransformWrapper
      defaultScale={1}
      defaultPositionX={200}
      defaultPositionY={100}
    >
      {({zoomIn, zoomOut, resetTransform}) => (
        <>
          <div className={classes.btnZoom}>
            <Button
              size="large"
              className={`${classes.shadow} ${classes.little} ${classes.mr18}`}
              onClick={zoomIn}
            >
              <GreyIcon>add</GreyIcon>
            </Button>
            <Button
              size="large"
              className={`${classes.shadow} ${classes.little} ${classes.mr18}`}
              onClick={zoomOut}
            >
              <GreyIcon>remove</GreyIcon>
            </Button>
            <Button
              size="large"
              className={`${classes.shadow} ${classes.little}`}
              onClick={resetTransform}
            >
              <GreyIcon>fullscreen</GreyIcon>
            </Button>
          </div>
          <TransformComponent>
            <OrgChart
              tree={structured.length === 0 ? structured : structured[0]}
              NodeComponent={MyNodeComponent}
            />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  )
}

export default CompanyStructure
