import gql from 'graphql-tag'

export const ADD_ALL_JOB_PROFILE = gql`
  mutation(
    $code: String
    $title: String
    $icon: String
    $parent: Int
    $organization: Int
    $position: Int
    $rank: Int
    $office: Int
    $jobdesc: String
    $jobresp: String
    $salary_from: float8
    $salary_to: float8
    $slots: Int
    $competency_model: [competency_model_items_insert_input!]!
    $education: [company_job_profile_r_educations_insert_input!]!
    $course: [company_job_profile_r_course_insert_input!]!
    $kpi: [company_job_profile_r_kpi_insert_input!]!
    #$work_schedule: [company_job_profile_r_attendance_insert_input!]!
    #$leave: [company_job_profile_r_leave_insert_input!]!
    #$overtime: [company_job_profile_r_overtime_insert_input!]!
    #$allowance: [company_job_profile_r_allowance_insert_input!]!
    #$loan: [company_job_profile_r_loan_insert_input!]!
    #$cash_adv: [company_job_profile_r_cash_adv_insert_input!]!
    #$documents: [company_job_profile_r_documents_insert_input!]!
    #$facilities: [company_job_profile_r_facilities_insert_input!]!
    $roles: String
    $job_grade_id: Int
    $require_successor: Boolean
    $acting_as: Int
    $attachment: jsonb
    $as_assistant: Boolean
    $line_type: Int
  ) {
    insert_company_job_profiles(
      objects: {
        code: $code
        title: $title
        job_icon: $icon
        parent: $parent
        organization: $organization
        position: $position
        rank: $rank
        office_address: $office
        job_summary: $jobresp
        job_description: $jobdesc
        rate_salary_from: $salary_from
        rate_salary_to: $salary_to
        slots: $slots
        roles: $roles
        company_job_profile_competencies: {data: $competency_model}
        company_job_profile_r_educations: {data: $education}
        company_job_profile_r_course: {data: $course}
        company_job_profile_r_kpi: {data: $kpi}
        job_grade_id: $job_grade_id
        require_successor: $require_successor
        acting_as_id: $acting_as
        attachment: $attachment
        #company_job_profile_r_attendances: {data: $work_schedule}
        #company_job_profile_r_leaves: {data: $leave}
        #company_job_profile_r_overtimes: {data: $overtime}
        #company_job_profile_r_allowances: {data: $allowance}
        #company_job_profile_r_loans: {data: $loan}
        #company_job_profile_r_cash_advs: {data: $cash_adv}
        #company_job_profile_r_documents: {data: $documents}
        #company_job_profile_r_facilities: {data: $facilities}
        as_assistant: $as_assistant
        line_type: $line_type
      }
    ) {
      affected_rows
      returning {
        id
        attachment
      }
    }
  }
`

export const ADD_IMPORT_JOB_PROFILE = gql`
  mutation($jobProfiles: JSON) {
    importCompanyJobProfiles(job_profiles: $jobProfiles) {
      success
    }
  }
`
