import React from 'react'
import {makeStyles, withStyles, Tooltip, Typography} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'

const useStyles = makeStyles(() => ({
  inputStyle: {
    padding: '15px 18px',
  },
  inputStyleTextArea: {
    padding: 0,
  },
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
    marginBottom: 17,
  },
  root: {
    marginBottom: 32,
  },
  buttonFile: {
    margin: '8px 0px 12px 0px',
    width: '8vw',
    height: '8vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    width: '50%',
    height: '50%',
  },
  iconInfo: {
    marginLeft: 10,
    marginBottom: '-4px',
    width: 18,
    height: 18,
  },
  textTooltip: {
    fontSize: 12,
    color: '#000',
    display: 'block',
    margin: '2px 0px',
  },
  textBlue: {
    color: '#014a62',
    fontSize: 12,
    display: 'block',
    margin: '2px 0px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  fileUploadded: {
    backgroundColor: '#eff2f4',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    borderRadius: 5,
  },
  top: {
    display: 'flex',
    flexDirection: 'row ',
  },
  fileName: {
    backgroundColor: '#fff',
    padding: '10px 16px',
    width: '100%',
    margin: '0px 10px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    marginTop: 12,
    marginRight: '3vw',
  },
  size: {
    color: '#000',
    fontSize: 12,
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: '#a9a8a8',
    borderRadius: '50%',
    margin: '0px 10px',
  },
  preview: {
    color: '#039be5',
    fontSize: 12,
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  textError: {
    color: '#f44336',
    margin: '8px 14px 0',
    fontSize: '0.75rem',
    fontWeight: '400',
  },
}))

const InfoTooltip = withStyles(() => ({
  tooltip: {
    padding: '12px 14px',
    backgroundColor: '#eff2f4',
    color: '#000',
    fontSize: 14,
    borderRadius: 5,
  },
  arrow: {
    color: '#eff2f4',
    backgroundColor: '#eff2f4',
  },
}))(Tooltip)

export const InputAttachment = ({label, disabled, id, ...props}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <p className={classes.label}>
        {label}

        <InfoTooltip
          // arrow
          title={
            <>
              <Typography className={classes.textTooltip}>
                Ekstensi file yang sesuai:
              </Typography>
              <div className={classes.tooltipWrapper}>
                <Typography className={classes.textTooltip}>
                  Dokumen {'( '}
                </Typography>
                <Typography className={classes.textBlue}>
                  .doc, .docx, .xls, .pdf, .xlsx, .ppt
                </Typography>
                <Typography className={classes.textTooltip}>{' )'}</Typography>
              </div>
              <div className={classes.tooltipWrapper}>
                <Typography className={classes.textTooltip}>
                  Gambar {'( '}
                </Typography>
                <Typography className={classes.textBlue}>
                  .jpg, .jpeg, .png, .gif, .tiff, .pjp, .pjpeg, .jfif, .tif
                </Typography>
                <Typography className={classes.textTooltip}>{' )'}</Typography>
              </div>
              <Typography className={classes.textTooltip}>
                Ukuran file maksimum 25MB setiap file
              </Typography>
            </>
          }
          placement="right"
        >
          <ErrorOutlineOutlinedIcon
            className={classes.iconInfo}
            color="primary"
          />
        </InfoTooltip>
      </p>

      <input
        disabled={disabled}
        style={{visibility: 'hidden'}}
        type="file"
        id={id}
        {...props}
      />

      <label htmlFor={id}>
        <div
          style={disabled ? {cursor: 'not-allowed'} : null}
          className={classes.buttonFile}
        >
          <AddIcon className={classes.icon} color="primary" />
        </div>
      </label>
    </div>
  )
}
