import gql from 'graphql-tag'

export const UPDATE_COMPANY_PROFILE = gql`
  mutation(
    $id: uuid!
    $legal_name: String
    $brand_name: String
    $logo: String
    $code_phone: String
    $phone: String
    $address: Int
    $industry_type: Int
    $legal_type: Int
    $profile_field: jsonb
  ) {
    update_company_profiles(
      where: {id: {_eq: $id}}
      _set: {
        legal_name: $legal_name
        brand_name: $brand_name
        logo: $logo
        country_code: $code_phone
        phone: $phone
        address: $address
        industry_type: $industry_type
        legal_type: $legal_type
        profile_field: $profile_field
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_LOGO_COMPANY = gql`
  mutation($company: uuid, $logo: String) {
    update_company_profiles(_set: {logo: $logo}, where: {id: {_eq: $company}}) {
      affected_rows
    }
  }
`

export const UPDATE_BANNER_COMPANY = banner => {
  return gql`
    mutation($company: uuid) {
      update_company_profiles(
        _append: {
          profile_field: {
            banner: "${banner}"
          }
        },
        where: {
          id: {
            _eq: $company
          }
        }
      ) {
        affected_rows
      }
    }
  `
}
