import gql from 'graphql-tag'
import {COMPANY_ID} from '../../../../utils/globals'

export const GET_HOLDING_CLASS_BY_VERSION = gql`
  query($version: Int) {
    holding_classes(
      where: {
        company: {_eq: "${COMPANY_ID}"}
        version: {_eq: $version}
      }
      order_by: {id: asc}
    ) {
      id
      name
      description
      version
    }
  }
`

export const GET_HOLDING_CLASS_BY_ID = gql`
  query($id: Int) {
    holding_classes(
      where: {
        company: {_eq: "${COMPANY_ID}"}
        id: {_eq: $id}
      }
      order_by: {id: asc}
    ) {
      id
      name
      description
      
    }
  }
`
