import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import {Link, useHistory} from 'react-router-dom'
import UserItemTable from './shared-feedback/UserItemTable'
import LoadingItemTable from './shared-feedback/LoadingItemTable'
import {DEFAULT_PAGINATION} from '../../../../../utils/globals'
import {getTableNumber} from '../../../../../utils/helpers'
import {useQuery} from '@apollo/react-hooks'
import {GET_LIST_ASESI} from '../../../../../graphql/queries/survey/multirater-nps/survey-center/getListSurveyCenter.query'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'

const useStyles = makeStyles(() => ({
  detailWording: {color: '#039be5', textDecoration: 'none'},
}))

export default function AsesiTable({surveyId, searchText, surveyDetail}) {
  const classes = useStyles()
  const {location} = useHistory()
  const [pagination, setPagination] = React.useState({
    limit: DEFAULT_PAGINATION[0],
    offset: 0,
  })

  const {data, loading, error} = useQuery(GET_LIST_ASESI, {
    wlb_skipPatch: true,
    fetchPolicy: 'network-only',
    variables: {
      survey: Number(surveyId),
      search: `%${searchText}%`,
      offset: pagination.offset,
      limit: pagination.limit,
    },
  })

  const handleChangePage = (event, page) => {
    event.preventDefault()
    setPagination({...pagination, offset: page * pagination.limit})
  }

  const handleChangeRowsPerPage = (event, page) => {
    event.preventDefault()
    setPagination({
      ...pagination,
      limit: page.props.value,
      offset: 0,
    })
  }

  const TOTAL = data?.total?.aggregate?.count

  return (
    <>
      {TOTAL > 0 && (
        <TableContainer>
          <Table style={{boxShadow: 'none'}}>
            <TableHead>
              <TableRow>
                <TableCell align="center" width={50}>
                  No
                </TableCell>
                <TableCell width={200}>Asesi</TableCell>
                <TableCell>Posisi</TableCell>
                <TableCell width={62}>Tindakan</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                data?.multirater_respondents?.map((row, key) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      {getTableNumber(pagination.limit, pagination.offset, key)}
                    </TableCell>
                    <TableCell width={200}>
                      <UserItemTable
                        avatar={row.global_user?.avatar}
                        name={row.global_user?.name || '-'}
                      />
                    </TableCell>
                    <TableCell>
                      {row.global_user?.people_work_placements?.[0]
                        ?.company_job_profile?.title || '-'}
                    </TableCell>
                    <TableCell
                      className={classes.detailWording}
                      component={Link}
                      to={{
                        pathname: `${location.pathname}/asesi/${row.global_user?.id}`,
                        state: {
                          active: 'detail-asesi',
                          searchable: true,
                          userName: row.global_user?.name,
                          survey: Number(surveyId),
                          user_id: row.global_user?.id,
                          surveyDetail,
                        },
                      }}
                    >
                      Detail
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!loading && error && <p>{JSON.stringify(error, 0, 2)}</p>}
      {loading && (
        <LoadingItemTable width="100%" height={42} style={{marginBottom: 12}} />
      )}
      {!loading && TOTAL === 0 && (
        <NoDataListComponent
          search={!!searchText}
          message="Saat ini, tidak ada Asesi yang terdaftar untuk umpan balik ini"
        />
      )}
      {TOTAL > 0 && (
        <TablePagination
          rowsPerPageOptions={DEFAULT_PAGINATION}
          component="div"
          rowsPerPage={pagination.limit}
          page={pagination.offset}
          count={TOTAL || 0}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          backIconButtonProps={{'aria-label': 'previous page'}}
          nextIconButtonProps={{'aria-label': 'next page'}}
          labelRowsPerPage="Baris Per halaman"
          backIconButtonText="halaman sebelumnya"
          nextIconButtonText="halaman berikutnya"
          labelDisplayedRows={({from, to, count}) =>
            `${from} - ${to} dari ${count}`
          }
        />
      )}
    </>
  )
}
