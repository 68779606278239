import React, {useState} from 'react'
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@material-ui/core'
import classNames from 'classnames'
import MaterialTable from 'material-table'

import {useSnackbar} from 'notistack'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {Magnify} from 'mdi-material-ui'

import helperStyle, {
  FlexBetween,
  RedIcon,
  TextBlackBold,
} from '../../../../../GlobalStyles'
import {
  CenterWrapper,
  TableOptions,
  pageSizeOption,
} from '../../PayrollPageStyles'

import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import FilterFinanceComponent from '../../../../shared-components/popup/FilterFinanceComponent'

import {
  DELETE_ASSIGNED_COMPONENT,
  ADD_ASSIGNED_COMPONENT,
} from '../../../../../graphql/mutations'
import {GET_ASSIGNED_COMPONENT} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'

const AssignedComponents = ({userId, userWorkId}) => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()

  const [openFilter, setOpenFilter] = useState(false)
  const [search, setSearch] = useState('')
  const [dataDelete, setDataDelete] = useState({})
  const [OpenAlert, setOpenAlert] = useState(false)

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])

  const {
    data: dataAssignedComponent,
    loading: loadingAssignedComponent,
    error: errorAssignedComponent,
    refetch: refetchAssignedComponent,
  } = useQuery(GET_ASSIGNED_COMPONENT, {
    variables: {
      search: `%${search}%`,
      userid: userId,
      company: COMPANY_ID,
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const [deleteAssignedComponent] = useMutation(
    DELETE_ASSIGNED_COMPONENT,
    GQL_FINANCE_OPTIONS
  )

  const [addAssignedComponent] = useMutation(
    ADD_ASSIGNED_COMPONENT,
    GQL_FINANCE_OPTIONS
  )

  const handleOpenAlert = data => {
    setDataDelete(data)
    setOpenAlert(!OpenAlert)
  }

  const onAddFilterAssignedComponent = result => {
    const variables = {
      objects: result,
    }
    addAssignedComponent({variables: variables})
      .then(() => {
        enqueueSnackbar('Component for Employee Added', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetchAssignedComponent()
      })
      .catch(() => {
        enqueueSnackbar(
          'Added Component for Employee failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 1000,
          }
        )
      })
  }

  const onDeleteAssignedComponent = () => {
    const variables = {
      id: dataDelete?.id,
      user_work_id: userWorkId,
    }
    deleteAssignedComponent({variables: variables})
      .then(() => {
        enqueueSnackbar('Component for Employee deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetchAssignedComponent()
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete Component for Employee failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 1000,
          }
        )
      })
  }

  return (
    <div>
      {dataAssignedComponent === undefined &&
      errorAssignedComponent === undefined ? (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      ) : (
        <>
          <FlexBetween>
            <TextBlackBold
              className={classes.marginLeft28}
            >{`Applied to Component (${dataAssignedComponent?.total?.aggregate
              ?.count || 0} Components)`}</TextBlackBold>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setOpenFilter(true)
              }}
            >
              Components
            </Button>
          </FlexBetween>
          <TextField
            fullWidth
            onChange={event => setSearch(event.target.value)}
            placeholder="Search"
            margin="normal"
            variant="outlined"
            className={classNames(
              classes.height39,
              classes.marginLeft28,
              classes.width96P
            )}
            InputProps={{
              startAdornment: (
                <InputAdornment className="ml-0" position="start">
                  <Magnify style={{color: '#014a62', width: 20, height: 20}} />
                </InputAdornment>
              ),
            }}
          />
          <MaterialTable
            columns={[
              {
                title: 'No',
                render: rowData => rowData.tableData.id + 1,
                defaultSort: 'asc',
              },
              {
                title: 'Component',
                field: 'name',
                headerStyle: {minWidth: 700},
                cellStyle: {minWidth: 700},
                render: rowData => (
                  <div style={{wordBreak: 'break-word'}}>{rowData?.name}</div>
                ),
              },
              {
                title: 'Action',
                field: 'action',
                headerStyle: {minWidth: 100},
                cellStyle: {minWidth: 100},
                sorting: false,
                render: rowData => (
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleOpenAlert(rowData)}
                  >
                    <RedIcon>delete</RedIcon>
                  </IconButton>
                ),
              },
            ]}
            data={dataAssignedComponent?.current_assigned_components}
            totalCount={dataAssignedComponent?.total?.aggregate?.count}
            isLoading={loadingAssignedComponent}
            page={currentPage}
            pageSize={rowsPerPage}
            onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
            onChangePage={page => setCurrentPage(page)}
            options={{
              ...TableOptions,
              showTitle: false,
              toolbar: false,
              selection: false,
              emptyRowsWhenPaging: false,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: <NoDataListComponent search={search} />,
              },
            }}
            components={{
              Container: props => (
                <Paper
                  {...props}
                  elevation={0}
                  className={classes.marginLeft28}
                />
              ),
            }}
          />

          {openFilter && (
            <FilterFinanceComponent
              feature="employee-setting"
              open={openFilter}
              onAdd={onAddFilterAssignedComponent}
              userId={userId}
              userWorkId={userWorkId}
              preData={
                dataAssignedComponent?.current_assigned_components_as_exclusion_component_to_be_assigned
              }
              handleClose={() => setOpenFilter(false)}
            />
          )}

          {OpenAlert && (
            <DeletePopup
              open={OpenAlert}
              handleClose={handleOpenAlert}
              name={dataDelete?.name}
              mutation={() => onDeleteAssignedComponent()}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AssignedComponents
