import gql from 'graphql-tag'

export const ADD_TASK = gql`
  mutation addTask($object: [performance_tasks_insert_input!]!) {
    insert_performance_tasks(objects: $object) {
      affected_rows
    }
  }
`
export const UPDATE_REPORTER_TASK = gql`
  mutation($id: bigint!, $reporter: uuid!) {
    update_performance_tasks(
      where: {id: {_eq: $id}}
      _set: {reporter: $reporter}
    ) {
      affected_rows
    }
  }
`
