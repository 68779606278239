import React, {useState} from 'react'

import {PaperSection, FlexBetween, GreyTypography} from '../../../GlobalStyles'
import {
  Typography,
  Divider,
  makeStyles,
  InputAdornment,
  // IconButton,
  TextField,
} from '@material-ui/core'
import {TypographyStyleds} from '../../../components/typography/TypographyStyles'
import GoogleMapReact from 'google-map-react'
import {MapMarker, Magnify} from 'mdi-material-ui'
import PopupDetailOffice from '../../company/profile/profile-component/PopupDetailOffice'
// import Edit from '@material-ui/icons/Edit'
// import {Link} from 'react-router-dom'

const useStyles = makeStyles(() => ({
  divider: {margin: '22px 0!important'},
  container: {display: 'flex'},
  detailLocation: {
    padding: '16px 13px 38px 16px',
    cursor: 'pointer',
    marginRight: 5,
    borderBottom: '2px solid #f7f8f9',
  },
  grey: {background: '#f7f8f9'},
  addresContainer: {width: '35%'},
  mapsContainer: {height: 550, width: '65%'},
  search: {
    marginTop: '0px !important',
    width: '235px !important',
  },
  officeLocation: {
    height: '485px',
    overflowY: 'auto',
  },
  paddingOffice: {padding: '30px 10px 30px 20px !important'},
  marginTop10: {marginTop: '10px'},
  emptyStateContainer: {marginTop: 12, marginLeft: 16},
}))

const OfficeLocationSection = ({location, mainOffice, contact, phone}) => {
  const classes = useStyles()
  const cordinates = location[0].address_field
    ? location[0].address_field.lat === '' ||
      location[0].address_field.lng === ''
      ? {
          lat: -6.9174639,
          lng: 107.61912280000001,
        }
      : location[0].address_field
    : {
        lat: -6.9174639,
        lng: 107.61912280000001,
      }
  const [coord, setCoord] = useState(cordinates)
  const [search, setSearch] = useState('')
  const [popupDetail, setPopupDetail] = useState(false)
  const [dataOffice, setDataOffice] = useState({})

  const handleClickLocation = loc => {
    setCoord(loc)
  }

  const handleOpenPopup = data => {
    !popupDetail && setDataOffice(data)
    setPopupDetail(!popupDetail)
  }

  const filter = location?.filter(
    name => name?.office_name?.toLowerCase().indexOf(search.toLowerCase()) > -1
  )

  return (
    <PaperSection
      elevation={2}
      style={{marginBottom: 22}}
      className={classes.paddingOffice}
    >
      <FlexBetween>
        <Typography variant="body1">Office</Typography>
      </FlexBetween>
      <Divider className={classes.divider} />
      <div className={classes.container}>
        <div className={classes.addresContainer}>
          <TextField
            margin="normal"
            variant="outlined"
            className={classes.search}
            fullWidth
            onChange={event => setSearch(event.target.value)}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment className="ml-0" position="start">
                  <Magnify style={{color: '#014a62', width: 20, height: 20}} />
                </InputAdornment>
              ),
            }}
          />

          <div className={classes.officeLocation}>
            {filter.length === 0 ? (
              <div className={classes.emptyStateContainer}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{fontWeight: 'bold'}}
                >
                  No result found
                </Typography>
                <GreyTypography variant="body1">
                  It seems we can’t find any results based on your search
                </GreyTypography>
              </div>
            ) : (
              filter.map((res, i) => {
                return (
                  <div
                    className={`${
                      classes.detailLocation
                    } ${res.address_field === coord && classes.grey}`}
                    key={i}
                    onClick={() => handleClickLocation(res.address_field)}
                  >
                    <TypographyStyleds
                      variant="body2"
                      weight="600"
                      gutterBottom
                    >
                      {res.office_name}
                    </TypographyStyleds>
                    {res.id === mainOffice && (
                      <TypographyStyleds
                        variant="caption"
                        weight="600"
                        color="#039be5"
                        gutterBottom
                        component="p"
                      >
                        Main Office
                      </TypographyStyleds>
                    )}

                    <TypographyStyleds variant="caption" color="#a9a8a8">
                      {res.address}
                    </TypographyStyleds>
                    <TypographyStyleds
                      variant="caption"
                      weight="600"
                      className={classes.marginTop10}
                      color="#039be5"
                      gutterBottom
                      component="p"
                      onClick={() => handleOpenPopup(res)}
                    >
                      Detail
                    </TypographyStyleds>
                  </div>
                )
              })
            )}
          </div>
        </div>
        <div className={classes.mapsContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ',
              libraries: 'places',
            }}
            center={{
              lat: parseFloat(coord.lat),
              lng: parseFloat(coord.lng),
            }}
            defaultZoom={16}
            yesIWantToUseGoogleMapApiInternals
          >
            <MapMarker
              style={{color: '#014a62'}}
              lat={coord.lat}
              lng={coord.lng}
              title={
                <>
                  address
                  <span />
                </>
              }
            />
          </GoogleMapReact>
        </div>
      </div>
      <PopupDetailOffice
        handleClose={handleOpenPopup}
        open={popupDetail}
        data={dataOffice}
        mainOffice={mainOffice}
        contact={contact}
        telephone={phone}
      />
    </PaperSection>
  )
}

export default OfficeLocationSection
