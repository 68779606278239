import React, {useState, useEffect} from 'react'
import {GenerateTooltip, PaperContainer} from '../../../../GlobalStyles'
import {
  Typography,
  IconButton,
  Divider,
  makeStyles,
  Paper,
  Icon,
  Toolbar,
  Button,
} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ChartDetail from './ChartDetail'
import BoxesMatrix from './Boxesmatrix'
import Participant from './Participant'
import moment from 'moment'
import {COMPANY_ID} from '../../../../utils/globals'
import Loading from '../../../../components/loading/LoadingComponent'
import AlertDialog from '../../../shared-components/popup/ResultPopup'

// graphql
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_DETAIL_TALENT_CLASSIFICATION,
  GET_LIST_TALENT_LIST,
  GET_TALENT_BOXES,
  GET_ORGANIZATION_FILTERS,
  GET_OFFICE_FILTERS,
  GET_POSITION_FILTERS,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../../../graphql/queries/index'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import {
  CLOSE_BALOON_EXPORT,
  EXPORT_MUTATION,
} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {disbaledButtonExport} from '../../../../utils/helpers'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginLeft: theme.spacing(2),
  },
  mb16: {
    marginBottom: '16px !important',
  },
  talentDetailTalentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
    padding: '2rem',
  },
  talentInfoPaper: {
    display: 'flex',
    flexGrow: '1',
    textAlign: 'center',
    flexDirection: 'row',
    width: '300px',
    height: '74px',
    borderRadius: '5px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    padding: 25,
    marginRight: 30,
    '&:nth-child(4)': {
      marginRight: 0,
    },
  },
  icon: {
    width: '70px',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  talentDetailInfo: {
    fontSize: 15,
    marginBottom: 10,
  },
  root: {
    padding: 24,
  },
  subtitle: {
    color: '#a9a8a8',
  },
  subIcon: {
    textAlign: 'left',
    marginLeft: '1.5rem',
    marginTop: '1rem',
  },
}))

function ClassisficationDetail(props) {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [openExport, setExport] = useState(false)

  const [condList, setCondList] = useState({
    version: props.location.state.data.id,
    organization: 'all',
    office: 'all',
    position: 'all',
    rank: null,
    badge: null,
  })

  const getDuration = years => {
    const dates = moment(new Date()).subtract(years, 'years')
    return dates.format('YYYY/MM/DD')
  }

  const variableFilter = state => {
    return {
      organization: state.organization === 'all' ? null : state.organization,
      office: state.office === 'all' ? null : state.office,
      position: state.position === 'all' ? null : state.position,
    }
  }

  const {data: dataList, error: errList, refetch: refetchList} = useQuery(
    GET_LIST_TALENT_LIST,
    {
      variables: {
        ...condList,
        ...variableFilter(condList),
      },
      context: {
        headers: {
          'X-Hasura-Role': 'organization-staff',
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {data: dataBoxes, error: errBoxes, refetch: refetchBoxes} = useQuery(
    GET_TALENT_BOXES,
    {
      variables: {
        version: props.location.state.data.id,
        rank: condList.rank,
        ...variableFilter(condList),
      },
      fetchPolicy: 'cache-and-network',
    }
  )
  const {data: dataEmployee, refetch: refetchEmp} = useQuery(
    GET_DETAIL_TALENT_CLASSIFICATION,
    {
      wlb_skipPatch: true,
      fetchPolicy: 'cache-and-network',
      variables: {
        version: props.location.state.data.id,
        age18: getDuration(18),
        age24: getDuration(24),
        age25: getDuration(25),
        age34: getDuration(34),
        age35: getDuration(35),
        age49: getDuration(49),
        age50: getDuration(50),
      },
    }
  )
  const {data: dataOrganization} = useQuery(GET_ORGANIZATION_FILTERS, {
    wlb_skipPatch: true,
  })
  const {data: dataOffice} = useQuery(GET_OFFICE_FILTERS, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })
  const {data: dataPosition} = useQuery(GET_POSITION_FILTERS, {
    wlb_skipPatch: true,
  })

  const {data: getExportBaloon, error: errorGetExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      wlb_skipPatch: true,
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'performance_report',
        filter: {id: props.location.state.data.id},
      },
    }
  )

  const {data: getExportDisabled, error: errorGetExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      wlb_skipPatch: true,
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'performance_report',
        filter: {id: props.location.state.data.id},
      },
    }
  )

  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )

  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  useEffect(() => {
    refetchBoxes()

    refetchList()

    refetchEmp()
  }, [])

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  const handleDownload = () => {
    setExport(!openExport)
    if (openExport) {
      mutationExport({
        variables: {
          table: 'performance_report',
          filter: {id: props.location.state.data.id},
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          // refetch()
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  if (
    (dataList === undefined && errList === undefined) ||
    (dataBoxes === undefined && errBoxes === undefined)
  ) {
    return <Loading />
  } else if (
    errList !== undefined ||
    errBoxes !== undefined ||
    mutationExportError !== undefined ||
    closeBaloonExportError !== undefined ||
    errorGetExportBaloon !== undefined ||
    errorGetExportDisabled !== undefined
  ) {
    return (
      <div>
        {JSON.stringify(
          errList ||
            errBoxes ||
            mutationExportError ||
            closeBaloonExportError ||
            errorGetExportBaloon ||
            errorGetExportDisabled
        )}
      </div>
    )
  }

  return (
    <>
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
        isReport
      />
      <PaperContainer>
        <Toolbar
          style={{
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <IconButton
              edge="end"
              aria-label="back"
              component={Link}
              to={{
                pathname: '/performance/classifications',
                state: {active: 'perf-classification', searchable: true},
              }}
            >
              <ArrowBack />
            </IconButton>

            <Typography variant="h6" className={classes.backButton}>
              {props.location.state.data.name} Detail
            </Typography>
          </div>
          <GenerateTooltip
            title={
              (getExportBaloon &&
                getExportBaloon.company_export_data_logs &&
                getExportBaloon.company_export_data_logs.length > 0 &&
                getExportBaloon.company_export_data_logs[0].status ===
                  'FAILED') ||
              disbaledButtonExport(
                getExportDisabled &&
                  getExportDisabled.company_export_data_logs &&
                  getExportDisabled.company_export_data_logs[0] &&
                  getExportDisabled.company_export_data_logs[0].date_added
              )
                ? `you can only generate data once a day. to download data use the link that already generated`
                : ``
            }
            placement="left"
          >
            <span>
              <Button
                variant="contained"
                size="large"
                color="primary"
                disabled={
                  (getExportBaloon &&
                    getExportBaloon.company_export_data_logs &&
                    getExportBaloon.company_export_data_logs.length > 0 &&
                    getExportBaloon.company_export_data_logs[0].status ===
                      'FAILED') ||
                  disbaledButtonExport(
                    getExportDisabled &&
                      getExportDisabled.company_export_data_logs &&
                      getExportDisabled.company_export_data_logs[0] &&
                      getExportDisabled.company_export_data_logs[0].date_added
                  )
                }
                onClick={handleDownload}
              >
                Unduh Laporan
              </Button>
            </span>
          </GenerateTooltip>
        </Toolbar>
        <Divider />
        <div className={classes.talentDetailTalentInfo}>
          <Paper className={classes.talentInfoPaper}>
            <div
              className={classes.icon}
              style={{
                border: 'solid 2px #055464',
              }}
            >
              <Icon
                style={{
                  fontSize: '35px',
                  color: '#055464',
                }}
              >
                people
              </Icon>
            </div>
            <div className={classes.subIcon}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: '600',
                  color: '#055464',
                }}
              >
                {dataEmployee && dataEmployee.total_employee.aggregate.count}
              </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Total Pegawai
              </Typography>
            </div>
          </Paper>
          <Paper className={classes.talentInfoPaper}>
            <div
              className={classes.icon}
              style={{
                border: 'solid 2px #039be5',
              }}
            >
              <Icon
                style={{
                  fontSize: '35px',
                  color: '#039be5',
                }}
              >
                device_hub
              </Icon>
            </div>
            <div className={classes.subIcon}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: '600',
                  color: '#039be5',
                }}
              >
                {dataEmployee && dataEmployee.total_departement.aggregate.count}
              </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Total Departemen
              </Typography>
            </div>
          </Paper>

          <Paper className={classes.talentInfoPaper}>
            <div
              className={classes.icon}
              style={{
                border: 'solid 2px #ffa000',
              }}
            >
              <Icon
                style={{
                  fontSize: '35px',
                  color: '#ffa000',
                }}
              >
                accessibility
              </Icon>
            </div>
            <div className={classes.subIcon}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: '600',
                  color: '#ffa000',
                }}
              >
                {dataEmployee && dataEmployee.total_designation.aggregate.count}
              </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Total Jabatan
              </Typography>
            </div>
          </Paper>
        </div>
        <ChartDetail dataEmployee={dataEmployee} />
        <BoxesMatrix
          dataEmployee={dataList}
          dataBoxes={dataBoxes}
          newHeight={dataBoxes && dataBoxes.talent_versions[0].box_height}
          newWidth={dataBoxes && dataBoxes.talent_versions[0].box_width}
          organization={dataOrganization}
          office={dataOffice}
          position={dataPosition}
          setCondList={setCondList}
          condList={condList}
          // setBoxes={setBoxes}
        />
        <Participant version={props?.location?.state?.data?.id} />
        <AlertDialog
          open={openExport}
          handleClose={handleDownload}
          feature="Generate Data is Processing!"
          message="Generate Data is processing! We’ll send you an email when it’s ready."
          type="Confirm"
        />
      </PaperContainer>
    </>
  )
}

export default withRouter(ClassisficationDetail)
