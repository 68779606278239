import {useState} from 'react'
import {pageSizeOption} from '../PayrollPageStyles'

export default () => {
  const [limit, setLimit] = useState(pageSizeOption[0])
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [rowPerPage, setRowPerPage] = useState(pageSizeOption[0])

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setCurrentPage(newPage)
    setOffset(newPage * limit)
  }

  const handleChangeRowsPerPage = event => {
    setRowPerPage(+event.target.value)
    setLimit(event.target.value)
    setOffset(0)
    setCurrentPage(0)
  }

  return {
    pageSizeOption,
    limit,
    offset,
    currentPage,
    rowPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  }
}
