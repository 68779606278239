import gql from 'graphql-tag'

export const GET_COMPONENT_SETTING = gql`
  query GetComponentSetting(
    $company: uuid!
    $offset: Int
    $limit: Int
    $search: String
    $sort_name: order_by
    $sort_id: order_by
  ) {
    total: finance_nonfixed_components_aggregate(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }

    finance_payroll_settings_by_pk(id: $company) {
      company_profile {
        finance_nonfixed_components(
          where: {
            _and: [{_or: [{name: {_ilike: $search}}]}]
            company: {_eq: $company}
            deletedAt: {_is_null: true}
          }
          order_by: [{name: $sort_name}, {id: $sort_id}]
          offset: $offset
          limit: $limit
        ) {
          id
          code
          name
          type
          component_frequency
          component_method_base
          component_calculation
          component_calculation_mul
          custom_calculation_value
          part_of
          taxable
          tax_paid_by
          include_in_bpjs
          effective_date
          finance_component_assignments {
            id
            people_work_placement {
              id
              regno
              company_job_profile {
                id
                title
              }
              global_user {
                id
                name
                avatar
              }
            }
          }
        }
      }
    }
  }
`

export const CHECK_COMPONENTS_VALIDATION_CODE = gql`
  query($company: uuid!, $code: String) {
    total: finance_nonfixed_components_aggregate(
      where: {
        company: {_eq: $company}
        code: {_eq: $code}
        deletedAt: {_is_null: true}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_COMPONENT_SETTING_BY_ID = gql`
  query(
    $id: Int!
    $search: String
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
  ) {
    finance_nonfixed_components_by_pk(id: $id) {
      id
      code
      name
      type
      component_frequency
      component_method_base
      component_calculation
      component_calculation_mul
      custom_calculation_value
      part_of
      taxable
      tax_paid_by
      effective_date
      finance_component_assignments(
        where: {
          _or: [
            {people_work_placement: {global_user: {name: {_ilike: $search}}}}
            {people_work_placement: {regno: {_ilike: $search}}}
          ]
          people_work_placement: {
            status: {_eq: "ACTIVE"}
            company_job_profile: {
              _and: [
                {id: {_in: $job_profile}}
                {organization: {_in: $organization}}
                {position: {_in: $position}}
                {rank: {_in: $rank}}
                {office_address: {_in: $office}}
              ]
            }
          }
        }
      ) {
        id
        people_work_placement {
          id
          regno
          company_job_profile {
            id
            title
          }
          global_user {
            id
            name
            avatar
          }
        }
      }
    }
  }
`

export const GET_ASSIGNEE_FILTER = gql`
  query($company: uuid!) {
    getJobProfiles: company_job_profiles(where: {company: {_eq: $company}}) {
      id
      title
    }

    getOrganizations: company_organizations(where: {company: {_eq: $company}}) {
      id
      name
    }

    getDesignations: company_employee_positions(
      where: {company: {_eq: $company}}
    ) {
      id
      name
    }

    getLevels: company_employee_ranks(where: {company: {_eq: $company}}) {
      id
      name
    }

    getOffices: company_addresses(where: {company: {_eq: $company}}) {
      id
      office_code
      office_name
    }
  }
`
