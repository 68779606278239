import {useMutation} from '@apollo/react-hooks'
import {
  Button,
  Checkbox,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import helperStyle, {
  Flex,
  FlexCenters,
  ButtonBlue,
  ExpansionPanelCurriculum,
} from '../../../../../GlobalStyles'
import {DELETE_QUESTION_MICROLEARNING} from '../../../../../graphql/mutations/learning/micro-learning/addQuestionMicrolearning.mutation'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import CreateCurriculum from './CreateCurriculum'
import CreateLesson from './CreateLesson'
import {
  REAST_API_URL,
  TOKEN,
  LEARNING_URL,
  JW_URL,
} from '../../../../../utils/globals'
import Axios from 'axios'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import CreateQuizQuestion from './CreateQuizQuestion'
import ReactJWPlayer from 'react-jw-player'

const GetJWMediaObject = ({videoURL, jwMedia, setJWMedia}) => {
  const getJWMediaObject = async () => {
    try {
      const response = await Axios.get(JW_URL + '/' + videoURL, {
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + TOKEN,
        },
      })

      setJWMedia(response.data.url)
    } catch (error) {
      console.error('getJWMediaObject error', error)
    }
  }

  React.useEffect(() => {
    if (videoURL) {
      getJWMediaObject()
    }
  }, [videoURL])

  if (!jwMedia) {
    return true
  }

  return (
    <div style={{width: 500, display: 'block'}}>
      <ReactJWPlayer
        playerId={jwMedia.feed_instance_id}
        playerScript={'https://cdn.jwplayer.com/libraries/1gQfwd2J.js'}
        playlist={jwMedia.playlist}
        isMuted={false}
      />
    </div>
  )
}

const RenderItemQuizQuestion = ({
  index,
  classes,
  question,

  style,
  courseId,
  lesson,
  setIsAddLessonOpened,
  selected,
  setSelected,
  getCourseById,
  listQns,
  getListQuestion,
}) => {
  const [isEdit, setIsEdit] = React.useState(false)
  const deleteQuestion = async questionId => {
    try {
      await Axios.delete(
        `${REAST_API_URL}/academy/question/${questionId}/remove`,
        {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )
      getListQuestion()
      getCourseById()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {!isEdit ? (
        <ExpansionPanelCurriculum
          style={style.expansionWithoutBottom}
          key={index}
        >
          <ExpansionPanelSummary classes={{content: classes.itemsCenter}}>
            <div
              className={classes.itemsCenter}
              style={{
                paddingRight: 0,
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <FlexCenters>
                <Typography style={{fontWeight: 600, marginRight: 12}}>
                  {index + 1}.
                </Typography>
                <div
                  className={classes.widthFull}
                  dangerouslySetInnerHTML={{__html: question.fulltext}}
                ></div>
              </FlexCenters>
              <Flex>
                <IconButton
                  className={classes.marginRight10}
                  onClick={() => setIsEdit(true)}
                >
                  <Icon style={style.grey}>create</Icon>
                </IconButton>
                <IconButton onClick={() => deleteQuestion(question.id)}>
                  <Icon style={style.grey}>delete</Icon>
                </IconButton>
              </Flex>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{display: 'block'}}>
            {question.choices.map((opt, index) => {
              return question.fieldtype === 'multiple' ? (
                <FlexCenters className={classes.marginLeft10} key={index}>
                  {/* <Radio color="primary" /> */}
                  <Radio
                    checked={question.answerKeys.find(choice => choice === opt)}
                    color="primary"
                  />
                  <Typography>{opt}</Typography>
                </FlexCenters>
              ) : (
                <FlexCenters className={classes.marginLeft10} key={index}>
                  <Checkbox
                    checked={question.answerKeys.find(choice => choice === opt)}
                    color="primary"
                  />
                  {/* <Checkbox checked={opt.is_correct} color="primary" /> */}
                  <Typography>{opt}</Typography>
                </FlexCenters>
              )
            })}
          </ExpansionPanelDetails>
        </ExpansionPanelCurriculum>
      ) : (
        <CreateQuizQuestion
          courseId={courseId}
          lesson={lesson}
          style={style}
          setOpenAdd={setIsAddLessonOpened}
          selected={selected}
          setSelected={setSelected}
          getCourseById={getCourseById}
          isEdit={true}
          setIsEdit={setIsEdit}
          listQns={listQns}
          index={index}
          getListQuestion={getListQuestion}
        />
      )}
    </>
  )
}

const RenderListQuizQuestion = ({
  lesson,
  style,
  classes,
  getCourseById,
  listQns,
  getListQuestion,
}) => {
  if (!listQns) {
    return true
  }

  return (
    <>
      {listQns
        .sort(({id: previousID}, {id: currentID}) => previousID - currentID)
        .map((question, index) => (
          <RenderItemQuizQuestion
            key={index}
            index={index}
            listQns={listQns}
            classes={classes}
            question={question}
            style={style}
            lesson={lesson}
            getListQuestion={getListQuestion}
            getCourseById={getCourseById}
          />
        ))}
    </>
  )
}

export const RenderLessonDetail = ({
  lesson,
  courseId,
  style,
  getCourseById,
}) => {
  const classes = helperStyle()
  const [isAddLessonOpened, setIsAddLessonOpened] = React.useState(false)
  const [selected, setSelected] = useState(null)
  const [jwMedia, setJWMedia] = useState(null)
  const [listQns, setListQns] = useState('')

  useEffect(() => {
    if (lesson.lessonType === 'quiz') {
      getListQuestion()
    }
  }, [])

  const getListQuestion = async () => {
    try {
      const response = await Axios.get(
        `${REAST_API_URL}/academy/lesson/${lesson.id}/question`,
        {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      setListQns(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  if (lesson.lessonType === 'quiz') {
    return (
      <div className={classNames(classes.column)}>
        <div className={classNames(classes.rowCenter, classes.widthFull)}>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#fff',
              color: '#039be5',
              border: '1px solid #039be5',
            }}
            onClick={() => setIsAddLessonOpened(!isAddLessonOpened)}
          >
            + Tambah Pertanyaan
          </Button>
          <Button
            variant="contained"
            className="ml-8"
            style={{
              backgroundColor: '#039be5',
              color: '#fff',
            }}
          >
            <a
              href={`${LEARNING_URL}/instructor/course/curriculum/quiz/preview/${lesson.id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
                color: '#FFF',
              }}
            >
              Pratinjau
            </a>
          </Button>
        </div>
        {isAddLessonOpened && (
          <CreateQuizQuestion
            courseId={courseId}
            lesson={lesson}
            style={style}
            setOpenAdd={setIsAddLessonOpened}
            selected={selected}
            setSelected={setSelected}
            getCourseById={getCourseById}
            getListQuestion={getListQuestion}
          />
        )}
        <div className={(classes.marginTop15, classes.marginBot10)}>
          <RenderListQuizQuestion
            lesson={lesson}
            style={style}
            classes={classes}
            courseId={courseId}
            getCourseById={getCourseById}
            selected={selected}
            setSelected={setSelected}
            setIsAddLessonOpened={setIsAddLessonOpened}
            listQns={listQns}
            getListQuestion={getListQuestion}
          />
        </div>
      </div>
    )
  } else if (lesson.lessonType === 'article') {
    return (
      <div className={classNames(classes.column)}>
        <div className={(classes.marginTop15, classes.marginBot10)}>
          {lesson.resources.length === 0 ? (
            <div
              className={classes.widthFull}
              dangerouslySetInnerHTML={{__html: lesson.summary}}
            ></div>
          ) : (
            lesson.resources.map((resource, index) => (
              <>
                <iframe
                  title={`resource ${index + 1}`}
                  className={classes.widthFull}
                  style={{minHeight: 500}}
                  frameBorder="0"
                  src={resource}
                />
              </>
            ))
          )}
        </div>
      </div>
    )
  } else if (lesson.lessonType === 'video') {
    return (
      <div className={classNames(classes.column)}>
        <div className={(classes.marginTop15, classes.marginBot10)}>
          <div className={classNames(classes.row)}>
            <GetJWMediaObject
              videoURL={lesson.videoUrl}
              jwMedia={jwMedia}
              setJWMedia={setJWMedia}
            />

            <div className={classNames(classes.column, classes.marginLeft10)}>
              <Typography>Video Summary</Typography>
              <Typography
                variant="caption"
                className={classNames(classes.marginRight25)}
              >
                {lesson.summary}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function ManageCurriculum({
  style,
  courseId,
  refetch,
  isInCourseDetail,
  setLessonDetail,
}) {
  const classes = helperStyle()
  const [openAdd, setOpenAdd] = useState(false)
  const [openAddLesson, setOpenAddLesson] = useState(false)
  const [sectionId, setSectionId] = useState(false)
  const [lesson, setLesson] = useState('')
  const [order, setOrder] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [selected, setSelected] = useState(null)
  const [course, setCourse] = useState('')

  const [mutationDeleteQuestion] = useMutation(DELETE_QUESTION_MICROLEARNING)

  const handleOpenAdd = () => {
    setOpenAdd(true)
    setSectionId(false)
  }

  const onDelete = () => {
    mutationDeleteQuestion({
      variables: {question_id: selected.id},
    })
      .then(() => {
        refetch()
      })
      .catch(e => e)
  }

  const getCourseById = async () => {
    try {
      const response = await Axios.get(
        `${REAST_API_URL}/academy/course/${courseId}`,
        // `${REAST_API_URL}/academy/course/${284}`,

        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      getCourseBySlug(response.data.slug)
    } catch (error) {
      console.error(error)
    }
  }

  const getCourseBySlug = async slug => {
    try {
      const response = await Axios.get(
        `${REAST_API_URL}/academy/course/get/${slug}`,
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      setCourse(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const updateSection = async (title, objective) => {
    try {
      await Axios.patch(
        `${REAST_API_URL}/academy/section/${sectionId}/update`,
        {
          title,
          objective,
        },
        {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )
      getCourseById()
    } catch (error) {
      console.error(error)
    }
  }

  const deleteSection = async sectionId => {
    try {
      await Axios.delete(
        `${REAST_API_URL}/academy/section/${sectionId}/remove`,
        {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )
      getCourseById()
    } catch (error) {
      console.error(error)
    }
  }

  const deleteLesson = async lessonId => {
    try {
      await Axios.delete(`${REAST_API_URL}/academy/lesson/${lessonId}/remove`, {
        headers: {
          Authorization: 'Bearer ' + TOKEN,
        },
      })
      getCourseById()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getCourseById()
  }, [])

  return (
    <>
      {!openAddLesson && !isInCourseDetail && (
        <>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.marginBot28}
            onClick={handleOpenAdd}
          >
            Tambah Bagian
          </Button>

          {openAdd && (
            <CreateCurriculum
              courseId={courseId}
              style={style}
              setOpenAdd={setOpenAdd}
              refetch={refetch}
              selected={selected}
              setSelected={setSelected}
              getCourseById={getCourseById}
              order={order}
              updateSection={updateSection}
              sectionId={sectionId}
              setSectionId={setSectionId}
            />
          )}
        </>
      )}

      {openAddLesson ? (
        <CreateLesson
          sectionId={sectionId}
          setSectionId={setSectionId}
          order={order}
          courseId={courseId}
          style={style}
          setOpenAddLesson={setOpenAddLesson}
          refetchCourseById={getCourseById}
          lesson={lesson}
          setLesson={setLesson}
        />
      ) : (
        course &&
        course.Sections.sort(
          ({id: previousID}, {id: currentID}) => previousID - currentID
        ).map((res, i) => (
          <ExpansionPanelCurriculum style={style.expansion} key={i}>
            <ExpansionPanelSummary classes={{content: classes.itemsCenter}}>
              <div className={classes.column}>
                {sectionId && sectionId === res.id ? (
                  <CreateCurriculum
                    courseId={courseId}
                    style={style}
                    setOpenAdd={setOpenAdd}
                    refetch={refetch}
                    selected={selected}
                    setSelected={setSelected}
                    getCourseById={getCourseById}
                    order={order}
                    updateSection={updateSection}
                    sectionId={sectionId}
                    setSectionId={setSectionId}
                  />
                ) : (
                  <div
                    className={classes.row}
                    style={{
                      paddingRight: 0,
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <FlexCenters>
                      <Typography style={{fontWeight: 600}}>
                        Bagian {i + 1} :{'  '}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 600,
                          marginRight: 12,
                        }}
                      >
                        &nbsp;{res.title}
                      </Typography>
                    </FlexCenters>

                    <Flex>
                      <IconButton
                        className={classes.marginRight10}
                        onClick={() => {
                          setSectionId(res.id)
                          setOpenAdd(false)
                        }}
                      >
                        <Icon style={style.grey}>create</Icon>
                      </IconButton>
                      <IconButton onClick={() => deleteSection(res.id)}>
                        <Icon style={style.grey}>delete</Icon>
                      </IconButton>
                    </Flex>
                  </div>
                )}
                {!isInCourseDetail && (
                  <ButtonBlue
                    size="medium"
                    onClick={() => {
                      setOpenAddLesson(!openAddLesson)
                      setSectionId(res.id)
                      setOrder(res.Lessons.length + 1)
                    }}
                  >
                    Tambah Pelajaran
                  </ButtonBlue>
                )}
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{display: 'block', width: '100%'}}>
              {res.Lessons.sort(
                ({id: previousID}, {id: currentID}) => previousID - currentID
              ).map(les => {
                return (
                  <FlexCenters
                    className={classNames(
                      classes.marginLeft10,
                      classes.marginTopBot20
                    )}
                    key={les.id}
                  >
                    {/* <Typography>{les.title}</Typography> */}
                    <ExpansionPanelCurriculum
                      style={{
                        width: '95%',
                        background: '#FFF',
                        cursor: 'pointer',
                      }}
                      disabled={isInCourseDetail}
                      onClick={() =>
                        isInCourseDetail ? setLessonDetail(les) : true
                      }
                    >
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <div
                          className={classNames(classes.row)}
                          style={{width: '100%'}}
                        >
                          <div className={classNames(classes.rowCenter)}>
                            <Icon>
                              {les.lessonType === 'video'
                                ? 'videocam'
                                : les.lessonType === 'article'
                                ? 'notes'
                                : 'assignment'}
                            </Icon>
                            <Typography> {les.title} </Typography>
                          </div>
                          <div className={classes.row}>
                            <Icon
                              style={style.grey}
                              className={classes.marginRight15}
                              onClick={() => {
                                setLesson(les)
                                setOpenAddLesson(true)
                              }}
                            >
                              edit
                            </Icon>
                            <Icon
                              style={style.grey}
                              onClick={() => {
                                return deleteLesson(les.id)
                              }}
                            >
                              delete
                            </Icon>
                          </div>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <RenderLessonDetail
                          lesson={les}
                          courseId={courseId}
                          style={style}
                          getCourseById={getCourseById}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanelCurriculum>
                  </FlexCenters>
                )
              })}
            </ExpansionPanelDetails>
          </ExpansionPanelCurriculum>
        ))
      )}

      <DeletePopup
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        mutation={onDelete}
        name="question"
      />
    </>
  )
}
