import gql from 'graphql-tag'

// claim_approval values:
// - "supervisor"
// - "position"
// - "free"
export const ADD_CLAIM_POLICY = gql`
  mutation(
    $name: String!
    $description: String
    $approval: String!
    $approverPositionIDs: [Int!]
    $assigneePositionIDs: [Int!]
    $filter: JSON!
  ) {
    addClaimPolicy(
      claim_name: $name
      description: $description
      claim_approval: $approval
      approver_position_ids: $approverPositionIDs
      assignee_position_ids: $assigneePositionIDs
      filter: $filter
    ) {
      success
    }
  }
`

export const EDIT_CLAIM_POLICY = gql`
  mutation(
    $policyId: Int!
    $name: String!
    $description: String
    $approval: String!
    $approverPositionIDs: [Int]!
    $assigneePositionIDs: [Int]!
    $filter: JSON!
  ) {
    editClaimPolicy(
      claim_policy_id: $policyId
      claim_name: $name
      description: $description
      claim_approval: $approval
      approver_position_ids: $approverPositionIDs
      assignee_position_ids: $assigneePositionIDs
      filter: $filter
    ) {
      success
    }
  }
`

export const DELETE_CLAIM_POLICY = gql`
  mutation($policyId: Int!) {
    update_finance_claim_policy_children_by_pk(
      pk_columns: {id: $policyId}
      _set: {deletedAt: "now()"}
    ) {
      id
      deletedAt
    }
  }
`
