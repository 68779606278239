import {Alert} from '@material-ui/lab'
import React from 'react'

export default function ImportBaloonMultiple({totalProcess, onSeeAll}) {
  return (
    <div>
      <Alert
        color={'info'}
        severity={'info'}
        style={{marginBottom: 8, marginLeft: 8}}
      >
        There are {totalProcess} processes running
        <a
          onClick={onSeeAll}
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            marginLeft: 14,
          }}
        >
          See all
        </a>
      </Alert>
    </div>
  )
}
