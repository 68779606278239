import React from 'react'
import {
  BoldTypography,
  TypographyStyleds,
} from '../../../../../components/typography/TypographyStyles'
import {
  ModalHeader,
  WhiteIcon,
  FormChildButton,
  GreyTypography,
  FlexCenters,
} from '../../../../../GlobalStyles'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  TextField,
  Dialog,
  IconButton,
  Button,
  Switch,
} from '@material-ui/core'
import {parameters_versions, selectProps} from '../../../../../utils/helpers'

export default function ParameterPopup({
  handleClose,
  open,
  values,
  setValues,
  handleChangeIndicator,
  isError,
  indicatorTextfield,
  classes,
  handleChange,
  data,
  axisValue,
  isDynamic,
  handleDynamicWeight,
  handleAddDynamicWeight,
}) {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        classes: {
          root: classes.parameterPopupContainer,
        },
      }}
    >
      <ModalHeader>
        <Typography variant="h6">Pilih Parameter</Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <div style={{padding: 20}}>
        <BoldTypography variant="body1" gutterBottom>
          Pilih Parameter untuk Sumbu ini
        </BoldTypography>
        <FlexCenters style={{marginBottom: 16}}>
          <Switch
            checked={!isDynamic}
            onChange={e =>
              e.target.checked
                ? handleAddDynamicWeight()
                : handleDynamicWeight()
            }
            color="primary"
          />
          <TypographyStyleds variant="body2" color="#414141">
            Gunakan Bobot untuk Parameter
          </TypographyStyleds>
        </FlexCenters>

        <Grid container className={classes.m0}>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <GreyTypography variant="body2">Nama Paremeter</GreyTypography>
            </Grid>
            <Grid item container xs={9} spacing={2}>
              <Grid
                item
                container
                xs={isDynamic ? 12 : 9}
                // className={classes.animatedParamWrapper}
              >
                <Grid item xs={5}>
                  <GreyTypography variant="body2">Tanggal Mulai</GreyTypography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                  <GreyTypography variant="body2">
                    Tanggal Selesai
                  </GreyTypography>
                </Grid>
              </Grid>
              {!isDynamic && (
                <Grid item container xs={3}>
                  <GreyTypography variant="body2">Bobot</GreyTypography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {indicatorTextfield.map(result =>
            result.value.map((res, idx) => {
              const isUsed =
                open &&
                axisValue[open === 'Sumbu X' ? 'Sumbu Y' : 'Sumbu X'].some(
                  s => s === res.nameCheck
                )

              return (
                <Grid container item xs={12} alignItems="center" key={idx}>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={res.valueCheck}
                          onChange={handleChange(res.nameCheck, open)}
                          disabled={isUsed}
                        />
                      }
                      classes={{label: classes.label}}
                      label={parameters_versions?.[res.title]?.ID || res.title}
                    />
                    {isUsed && (
                      <GreyTypography
                        variant="caption"
                        component="p"
                        className={classes.disabledText}
                      >
                        Sudah digunakan untuk sumbu{' '}
                        {open === 'Sumbu X' ? 'Y' : 'X'}
                      </GreyTypography>
                    )}
                  </Grid>
                  <Grid item container xs={9} spacing={2}>
                    {res.title === 'Competency' ? (
                      <Grid
                        item
                        xs={isDynamic ? 12 : 9}
                        // className={classes.animatedParamWrapper}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          value={res.indicators[1]}
                          onChange={handleChangeIndicator(
                            res.nameParent,
                            'version'
                          )}
                          select
                          error={
                            res.valueCheck && !res.indicators[1] && isError
                          }
                          helperText={
                            res.valueCheck && !res.indicators[1] && isError
                              ? 'This field is required'
                              : ''
                          }
                          disabled={!res.valueCheck || isUsed}
                          label={
                            (!res.valueCheck || isUsed) && !res.indicators[1]
                              ? 'Choose version'
                              : ''
                          }
                          SelectProps={selectProps(res.indicators[1])}
                          InputLabelProps={{
                            shrink: false,
                          }}
                        >
                          <MenuItem value="" disabled>
                            Choose version
                          </MenuItem>
                          {data.competency_versions.map((res, i) => {
                            return (
                              <MenuItem value={res.id} key={i}>
                                {res.name}
                              </MenuItem>
                            )
                          })}
                        </TextField>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        container
                        xs={isDynamic ? 12 : 9}
                        alignItems="center"
                        // className={classes.animatedParamWrapper}
                      >
                        <Grid item xs={5}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              fullWidth
                              size="small"
                              inputVariant="outlined"
                              format="MMM d, yyyy"
                              InputProps={{
                                readOnly: true,
                              }}
                              value={res.indicators[1]}
                              margin="normal"
                              placeholder="Pilih Tanggal Mulai"
                              disabled={!res.valueCheck || isUsed}
                              onChange={date =>
                                setValues({
                                  ...values,
                                  [res.nameParent]: {
                                    ...values[res.nameParent],
                                    start_date: date,
                                  },
                                })
                              }
                              InputAdornmentProps={{
                                classes: {
                                  root: classes.m0,
                                },
                              }}
                              KeyboardButtonProps={{
                                classes: {
                                  root: classes.p0,
                                },
                              }}
                              maxDate={new Date()}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item className={classes.separateToForm} xs={2}>
                          sampai
                        </Grid>
                        <Grid item xs={5}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              fullWidth
                              disabled={!res.valueCheck || isUsed}
                              size="small"
                              placeholder="Pilih Tanggal Selesai"
                              inputVariant="outlined"
                              format="MMM d, yyyy"
                              InputProps={{
                                readOnly: true,
                              }}
                              value={res.indicators[2]}
                              margin="normal"
                              onChange={date =>
                                setValues({
                                  ...values,
                                  [res.nameParent]: {
                                    ...values[res.nameParent],
                                    end_date: date,
                                  },
                                })
                              }
                              InputAdornmentProps={{
                                classes: {
                                  root: classes.m0,
                                },
                              }}
                              KeyboardButtonProps={{
                                classes: {
                                  root: classes.p0,
                                },
                              }}
                              minDate={res.indicators[1]}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                    )}
                    {!isDynamic && (
                      <Grid item xs={3}>
                        <FlexCenters>
                          <TextField
                            style={{marginRight: 6}}
                            size="small"
                            disabled={!res.valueCheck || isUsed}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={res.indicators[0]}
                            onChange={handleChangeIndicator(
                              res.nameParent,
                              'weight'
                            )}
                            placeholder="Tambahkan Bobot"
                            error={
                              res.valueCheck &&
                              res.indicators[0] === '' &&
                              isError
                            }
                            helperText={
                              res.valueCheck &&
                              res.indicators[0] === '' &&
                              isError
                                ? 'This field is required'
                                : ''
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          %
                        </FlexCenters>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )
            })
          )}
        </Grid>
      </div>
      <FormChildButton>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClose}
        >
          Selesai
        </Button>
      </FormChildButton>
    </Dialog>
  )
}
