import gql from 'graphql-tag'

export const GET_INSURANCE_LIST = gql`
  query {
    global_insurance_list(where: {deletedAt: {_is_null: true}}) {
      id
      enum
      name
      logo
    }
  }
`

export const GET_PREMIUM_SETTING = gql`
  query($company: uuid, $offset: Int, $limit: Int) {
    total: finance_insurance_premiums_aggregate(
      where: {company: {_eq: $company}}
    ) {
      aggregate {
        count
      }
    }

    finance_insurance_premiums(
      where: {company: {_eq: $company}}
      offset: $offset
      limit: $limit
      order_by: [{id: asc_nulls_last}]
    ) {
      id
      insurance_company: global_insurance {
        id
        name
      }
      premium_name
      premium_fee
      company_portion
      employee_portion
      description
      effective_date
      age_expire
      age_beneficiaries
      finance_premium_assignments {
        people_work_placement {
          id
          regno
          company_job_profile {
            id
            title
          }
          global_user {
            id
            name
            avatar
          }
        }
      }
    }
  }
`

export const GET_DETAIL_PREMIUM = gql`
  query(
    $id: Int!
    $search: String
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
  ) {
    finance_insurance_premiums_by_pk(id: $id) {
      id
      insurance_company: global_insurance {
        id
        name
      }
      premium_name
      premium_fee
      company_portion
      employee_portion
      description
      effective_date
      age_expire
      age_beneficiaries
      premium_fields
      finance_premium_assignments(
        where: {
          _or: [
            {people_work_placement: {global_user: {name: {_ilike: $search}}}}
            {people_work_placement: {regno: {_ilike: $search}}}
          ]
          people_work_placement: {
            status: {_eq: "ACTIVE"}
            company_job_profile: {
              _and: [
                {id: {_in: $job_profile}}
                {organization: {_in: $organization}}
                {position: {_in: $position}}
                {rank: {_in: $rank}}
                {office_address: {_in: $office}}
              ]
            }
          }
        }
      ) {
        people_work_placement {
          id
          regno
          company_job_profile {
            id
            title
          }
          global_user {
            id
            name
            avatar
          }
        }
      }
    }
  }
`
