import React from 'react'
import {Typography, Grid, TextField, MenuItem, Button} from '@material-ui/core'
import {FormChildButton} from '../../../../../../GlobalStyles'

const candidateOps = [
  {
    val: 'TALENT',
    name: 'Talent Pool',
  },
  {
    val: 'SUCCESSOR',
    name: 'Successor',
  },
]

const readinessOps = [
  {val: 'READY', name: 'Ready Now'},
  {val: '1-2 YEARS', name: '1-2 Years'},
  {val: '3-5 YEARS', name: '3-5 Years'},
]

const ModalDetailTalentAction = ({
  classes,
  handleClose,
  onMutationPool,
  value,
  handleChange,
}) => {
  return (
    <div className={classes.actionContainer}>
      <Typography variant="body1" className={classes.actionBold}>
        Action
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Position
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            select
            value={value.candidate_status}
            onChange={handleChange('candidate_status')}
          >
            {candidateOps.map((res, i) => (
              <MenuItem value={res.val} key={i}>
                {res.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Readiness
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            select
            value={value.readiness_status}
            onChange={handleChange('readiness_status')}
          >
            {readinessOps.map((res, i) => (
              <MenuItem value={res.val} key={i}>
                {res.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <FormChildButton>
        <Button size="large" onClick={handleClose} style={{marginRight: 12}}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onMutationPool}
          size="large"
          disabled={
            value.readiness_status.length === 0 ||
            value.candidate_status.length === 0
          }
        >
          Confirm
        </Button>
      </FormChildButton>
    </div>
  )
}

export default ModalDetailTalentAction
