import gql from 'graphql-tag'

export const ADD_EXPERIENCE = gql`
  mutation addExperience(
    $objects: [people_profile_experiences_insert_input!]!
  ) {
    insert_people_profile_experiences(objects: $objects) {
      affected_rows
    }
  }
`
export const UPDATE_EXPERIENCE = gql`
  mutation updateExperience(
    $id: Int!
    $setObject: people_profile_experiences_set_input!
    $appendObject: people_profile_experiences_append_input!
  ) {
    update_people_profile_experiences_by_pk(
      pk_columns: {id: $id}
      _set: $setObject
      _append: $appendObject
    ) {
      id
    }
  }
`
export const DELETE_EXPERIENCE = gql`
  mutation($id: Int!) {
    update_people_profile_experiences(
      _set: {deletedAt: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const ADD_LICENSES = gql`
  mutation addCertificate(
    $objects: [people_profile_certificates_insert_input!]!
  ) {
    insert_people_profile_certificates(objects: $objects) {
      affected_rows
    }
  }
`
export const UPDATE_LICENSES = gql`
  mutation($id: Int!, $objects: people_profile_certificates_set_input!) {
    update_people_profile_certificates_by_pk(
      pk_columns: {id: $id}
      _set: $objects
    ) {
      id
    }
  }
`
export const DELETE_LICENSES = gql`
  mutation($id: Int!, $user: uuid) {
    update_people_profile_certificates(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {user: {_eq: $user}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const ADD_SERVICE = gql`
  mutation addSA($object: people_profile_service_agreements_insert_input!) {
    insert_people_profile_service_agreements_one(object: $object) {
      id
    }
  }
`

export const UPDATE_SERVICE = gql`
  mutation updateSA(
    $id: Int!
    $object: people_profile_service_agreements_set_input!
  ) {
    update_people_profile_service_agreements_by_pk(
      pk_columns: {id: $id}
      _set: $object
    ) {
      id
    }
  }
`
export const DELETE_SERVICE = gql`
  mutation($id: Int!, $userId: uuid) {
    update_people_profile_service_agreements(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {user: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const ADD_DISCIPLINE = gql`
  mutation addDiscipline($object: people_profile_disciplinary_insert_input!) {
    insert_people_profile_disciplinary_one(object: $object) {
      id
    }
  }
`

export const UPDATE_DISCIPLINE = gql`
  mutation updateDiscipline(
    $id: Int!
    $object: people_profile_disciplinary_set_input!
  ) {
    update_people_profile_disciplinary_by_pk(
      pk_columns: {id: $id}
      _set: $object
    ) {
      id
    }
  }
`

export const DELETE_DISCIPLINE = gql`
  mutation($id: Int!, $userId: uuid!) {
    update_people_profile_disciplinary(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {user: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const ADD_AWARD = gql`
  mutation addAward($object: people_profile_awards_insert_input!) {
    insert_people_profile_awards_one(object: $object) {
      id
    }
  }
`

export const UPDATE_AWARD = gql`
  mutation updateAwardByUser(
    $id: Int!
    $object: people_profile_awards_set_input!
  ) {
    update_people_profile_awards_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
    }
  }
`
export const DELETE_AWARD = gql`
  mutation($id: Int!, $userId: uuid!) {
    update_people_profile_awards(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {user: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`
export const ADD_OHSA = gql`
  mutation addOHSA($object: people_profile_ohsa_insert_input!) {
    insert_people_profile_ohsa_one(object: $object) {
      id
    }
  }
`
export const UPDATE_OHSA = gql`
  mutation updateOHSA($id: Int!, $object: people_profile_ohsa_set_input!) {
    update_people_profile_ohsa_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
    }
  }
`
export const DELETE_OHSA = gql`
  mutation($id: Int!, $userId: uuid) {
    update_people_profile_ohsa(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {user: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`
export const UPDATE_BANK_ACCOUNT = gql`
  mutation(
    $id: Int
    $bank: Int
    $account_name: String
    $account_number: String
  ) {
    update_people_profile_banks(
      _set: {
        bank: $bank
        account_name: $account_name
        account_number: $account_number
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const DELETE_BANK_ACCOUNT = gql`
  mutation update_people_profile_banks(
    $id: Int
    $changes: people_profile_banks_set_input
  ) {
    update_people_profile_banks(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`

export const ADD_PROJECT = gql`
  mutation AddProject($objects: [people_profile_projects_insert_input!]!) {
    insert_people_profile_projects(objects: $objects) {
      affected_rows
    }
  }
`
export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $id: Int!
    $object: people_profile_projects_set_input!
  ) {
    update_people_profile_projects_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
    }
  }
`
export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: Int!, $user: uuid!) {
    update_people_profile_projects(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {user: {_eq: $user}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const ADD_ORGANIZATION_ACTIVITY = gql`
  mutation AddOrganizationActivity(
    $objects: [people_profile_organization_activities_insert_input!]!
  ) {
    insert_people_profile_organization_activities(objects: $objects) {
      affected_rows
    }
  }
`
export const UPDATE_ORGANIZATION_ACTIVITY = gql`
  mutation UpdateOrganizationActivity(
    $id: Int!
    $object: people_profile_organization_activities_set_input!
  ) {
    update_people_profile_organization_activities_by_pk(
      pk_columns: {id: $id}
      _set: $object
    ) {
      id
    }
  }
`
export const DELETE_ORGANIZATION_ACTIVITY = gql`
  mutation DeleteOrganizationActivity($id: Int!, $userId: uuid!) {
    update_people_profile_organization_activities(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {user_id: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const ADD_EDUCATION = gql`
  mutation AddEducation($objects: [people_profile_educations_insert_input!]!) {
    insert_people_profile_educations(objects: $objects) {
      affected_rows
    }
  }
`
export const UPDATE_EDUCATION = gql`
  mutation UpdateEducation(
    $id: Int!
    $object: people_profile_educations_set_input!
  ) {
    update_people_profile_educations_by_pk(
      pk_columns: {id: $id}
      _set: $object
    ) {
      id
    }
  }
`
export const DELETE_EDUCATION = gql`
  mutation DeleteEducation($id: Int!) {
    update_people_profile_educations(
      _set: {deletedAt: "now()"}
      where: {_and: [{id: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      affected_rows
    }
  }
`

export const ADD_SPEAKER = gql`
  mutation AddSpeaker($objects: [people_profile_speaker_insert_input!]!) {
    insert_people_profile_speaker(objects: $objects) {
      affected_rows
    }
  }
`
export const UPDATE_SPEAKER = gql`
  mutation UpdateSpeaker(
    $id: Int!
    $object: people_profile_speaker_set_input!
  ) {
    update_people_profile_speaker_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
    }
  }
`
export const DELETE_SPEAKER = gql`
  mutation DeleteSpeaker($id: Int!) {
    update_people_profile_speaker(
      _set: {deletedAt: "now()"}
      where: {_and: [{id: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      affected_rows
    }
  }
`

export const ADD_SKILL = gql`
  mutation AddSkill($objects: [people_profile_skills_insert_input!]!) {
    insert_people_profile_skills(
      on_conflict: {
        constraint: people_profile_skills_user_skill_key
        update_columns: [level, is_share, description, deletedAt]
      }
      objects: $objects
    ) {
      affected_rows
    }
  }
`
export const UPDATE_SKILL = gql`
  mutation UpdateSkill($id: Int!, $object: people_profile_skills_set_input!) {
    update_people_profile_skills_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
    }
  }
`
export const DELETE_SKILL = gql`
  mutation DeleteSkill($id: Int!) {
    update_people_profile_skills(
      _set: {deletedAt: "now()"}
      where: {_and: [{id: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      affected_rows
    }
  }
`
