import React, {useState} from 'react'
import MaterialTable from 'material-table'
import moment from 'moment'
import {Chip, Button} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {COLOR_STATUS} from '../../../../../GlobalStyles'
import {TableOptions, pageSizeOption} from '../../../payroll/PayrollPageStyles'
import RequestWrapper from '../../../../shared-components/layouts/RequestWrapper'

import {GET_LOAN_ACTIVITY} from '../../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../../utils'
import {capitalize, convertToRoundedRupiah} from '../../../../../utils/helpers'
// import {getEmployeeFilterVariables} from '../../../../shared-components/employee-filter-plugin'

const ActivityTab = ({
  searchText,
  // filterData
}) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])

  // const filterVariables = React.useMemo(
  //   () =>
  //     getEmployeeFilterVariables(
  //       filterData
  //     ),
  //   [filterData]
  // )

  const {
    loading,
    data,
    error,
    // refetch: refetchActivityList
  } = useQuery(GET_LOAN_ACTIVITY, {
    wlb_skipPatch: true,
    variables: {
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
      search: `%${searchText}%`,
      // ...filterVariables, // Hide: waiting for query optimization
    },
    fetchPolicy: 'cache-and-network',
    ...GQL_FINANCE_OPTIONS,
  })

  const ACTIVITY_COLUMNS = [
    {
      title: 'No',
      field: 'tableData',
      align: 'center',
      render: rowData => rowData.tableData?.id + 1,
    },
    {
      title: 'Employee ID',
      field: 'regno',
      headerStyle: {minWidth: 150},
      cellStyle: {minWidth: 150},
      render: rowData => rowData.people_work_placement?.regno || '',
    },
    {
      title: 'Employee Name',
      field: 'employee_name',
      headerStyle: {minWidth: 180},
      cellStyle: {minWidth: 180},
      render: rowData => rowData.people_work_placement?.global_user?.name || '',
    },
    {
      title: 'Letter No',
      field: 'letter_num',
      align: 'center',
      headerStyle: {minWidth: 150},
      cellStyle: {minWidth: 150},
      render: rowData => rowData.letter_num || '-',
    },
    {
      title: 'Loan Date',
      field: 'date_added',
      cellStyle: {minWidth: 150},
      render: rowData => moment(rowData.date_added).format('ll'),
    },
    {
      title: 'Payment',
      field: 'installment_num',
      align: 'center',
      render: rowData =>
        `${rowData.installment_num?.length || 0} of ${rowData.installment ||
          0}`,
    },
    {
      title: 'Loan Amount',
      field: 'loan_amount',
      align: 'right',
      cellStyle: {minWidth: 160, maxWidth: 180},
      render: rowData => convertToRoundedRupiah(rowData.loan_amount || 0),
    },
    {
      title: 'Status',
      field: 'status',
      align: 'center',
      render: rowData => {
        const status = rowData.status
        return status ? (
          <Chip
            label={capitalize(status || '-')}
            size="small"
            style={{
              backgroundColor: COLOR_STATUS[status],
              color: 'white',
            }}
          />
        ) : (
          '-'
        )
      },
    },
    {
      title: 'Action',
      field: 'action',
      align: 'center',
      sorting: false,
      render: rowData => (
        <Link
          to={{
            pathname: `/financial/loan/activity/${rowData.id}/edit`,
            state: {
              active: 'edit-loan-activity',
              searchable: false,
              loanId: rowData.id,
            },
          }}
          style={{textDecoration: 'none'}}
        >
          <Button color="secondary">Edit</Button>
        </Link>
      ),
    },
  ]

  // Hide: waiting for query optimization
  // React.useLayoutEffect(() => {
  //   refetchActivityList()
  // }, [filterVariables])

  return (
    <RequestWrapper
      loading={loading && !data?.loans}
      error={error}
      isEmpty={!loading && data?.total_loan?.aggregate?.count === 0}
    >
      <MaterialTable
        columns={ACTIVITY_COLUMNS}
        data={data?.loans}
        isLoading={!loading && !data}
        onChangePage={page => setCurrentPage(page)}
        onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
        options={{...TableOptions}}
        page={currentPage}
        rowsPerPage={rowsPerPage}
        totalCount={data?.total_loan?.aggregate?.count}
      />
    </RequestWrapper>
  )
}

export default withRouter(ActivityTab)
