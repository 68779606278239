import {
  Checkbox,
  Fade,
  FormControlLabel,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'
import {GreyTypography} from '../../../../../../GlobalStyles'
import {integerOnly} from '../../../../../../utils/helpers'

const useStyles = makeStyles(() => ({
  formControlWrapper: {
    display: 'flex',
    margin: '6px 0 0 2rem',
  },
  helpIcon: {
    fontSize: '14px !important',
    marginLeft: 10,
    cursor: 'pointer',
  },
  textType: {margin: '55px 47px 0 20px'},
  title: {marginBottom: 10},
  tooltip: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    background: '#f7f8f9',
    color: '#000',
    width: 200,
    padding: 13,
  },
  arrow: {color: '#f7f8f9'},

  paperWeight: {
    position: 'absolute',
    bottom: '5rem',
    right: '4rem',
    padding: '12px 20px',
  },
  formChildWrapper: {width: 176},
  flexBasleine: {display: 'flex', alignItems: 'baseline'},
}))

export default function StepRaterList(props) {
  const {setChecked, checked, result, checkedRater, isError} = props

  const raterListForm = [
    {
      title: 'Atasan Pimpinan',
      state: 'second_supervisor',
    },
    {
      title: 'Pimpinan',
      state: 'supervisor',
    },
    {
      title: 'Rekan Kerja',
      state: 'peer',
    },
    {
      title: 'Pegawai Dibawahnya',
      state: 'subordinate',
    },
    {
      title: 'Pegawai Dua Tingkat Dibawahnya',
      state: 'second_subordinate',
    },
  ]

  const classes = useStyles()

  const handleChange = (name, feat) => event => {
    let inputs = {
      ...checked[name],
      [feat]: integerOnly(event.target.value),
    }

    if (feat === 'checked') {
      inputs = {
        [feat]: event.target.checked,
        weight: 0,
        max: 0,
      }
    }
    setChecked({
      ...checked,
      [name]: inputs,
    })
  }
  return (
    <>
      <Typography variant="body1" className={classes.title}>
        Pilih daftar penilai
      </Typography>
      {isError && checkedRater.length === 0 && (
        <Typography variant="caption" color="error" className={classes.title}>
          Silakan pilih setidaknya satu
        </Typography>
      )}

      {raterListForm.map((res, i) => (
        <div key={i}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked[res.state].checked}
                onChange={handleChange(res.state, 'checked')}
                color="primary"
              />
            }
            label={res.title}
            labelPlacement="end"
          />
          {checked[res.state].checked && (
            <Fade in={checked[res.state].checked}>
              <div className={classes.formControlWrapper}>
                {/* <div className={classes.formChildWrapper}>
                  <div style={{width: 'auto', display: 'flex'}}>
                    <GreyTypography variant="body2">
                      Max Participant
                    </GreyTypography>
                    <Tooltip
                      title="This field is for determining the maximum participants that the system will generate. You can also add or change participants defined by the system in step 5"
                      placement="right"
                      arrow
                      classes={{
                        tooltipArrow: classes.tooltip,
                        arrow: classes.arrow,
                      }}
                    >
                      <BlueIcon className={classes.helpIcon}>
                        help_outline
                      </BlueIcon>
                    </Tooltip>
                  </div>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={checked[res.state].max}
                    onChange={handleChange(res.state, 'max')}
                  />
                </div>
                <GreyTypography variant="body2" className={classes.textType}>
                  People
                </GreyTypography> */}
                <div className={classes.formChildWrapper}>
                  <GreyTypography variant="body2">Bobot</GreyTypography>

                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={checked[res.state].weight}
                    onChange={handleChange(res.state, 'weight')}
                  />
                </div>
                <GreyTypography variant="body2" className={classes.textType}>
                  %
                </GreyTypography>
              </div>
            </Fade>
          )}
        </div>
      ))}
      <Paper className={classes.paperWeight}>
        <div className={classes.flexBasleine}>
          <Typography variant="body1" style={{marginRight: 12}}>
            Bobot keseluruhan:
          </Typography>
          <Typography
            variant="h5"
            color={result !== 100 ? 'error' : 'secondary'}
          >
            {result}%
          </Typography>
        </div>
        {result !== 100 && (
          <Typography
            variant="caption"
            color="error"
            // style={{marginRight: 12}}
          >
            *Bobot keseluruhan harus 100%
          </Typography>
        )}
      </Paper>
    </>
  )
}
