import React from 'react'
import {BoxText, FlexStart} from './modalStyles'

function FormDetail({label, color, display = 'flex', ...children}) {
  return (
    <FlexStart style={{padding: '8px 0px'}}>
      <BoxText
        style={{width: color ? '100%' : '20%', color: color || '#707070'}}
      >
        {label}
      </BoxText>
      <BoxText
        style={{
          width: '100%',
          color: 'black',
          display: display,
          flexFlow: 'wrap',
        }}
        {...children}
      />
    </FlexStart>
  )
}

export default FormDetail
