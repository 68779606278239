import React from 'react'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'
import HoldingClassification from './HoldingClassificationComponent'

const renderComponent = activeComponent => {
  switch (activeComponent) {
    case 'holding-talent-classification':
      return <HoldingClassification />
    default:
      return <HoldingClassification />
  }
}

const HoldingClassPage = props => {
  let active = ''

  if (props.location.state) {
    active = props.location.state.active
  }

  return (
    <div>
      <Card icon="how_to_reg" title="Talent" hidden={'hidden'}>
        <PageContainer
          style={{
            margin: '0 -20px',
            background: '#003d51',
            minHeight: '78vh',
            display: 'block',
          }}
        >
          {renderComponent(active)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default HoldingClassPage
