import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
} from '../../../../../GlobalStyles'
import {withRouter} from 'react-router-dom'
import {
  IconButton,
  Button,
  makeStyles,
  TextField,
  Divider,
  MenuItem,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {useSnackbar} from 'notistack'
import {useMutation, useApolloClient} from '@apollo/react-hooks'
import {holdingRolesContext, selectProps} from '../../../../../utils/helpers'
import AddEdit from '../../../../shared-components/popup/AddEditPopup'
import {
  ADD_HOLDING_CLUSTER,
  ADD_HOLDING_SUBCLUSTER,
  UPDATE_HOLDING_CLUSTER,
  UPDATE_HOLDING_SUBCLUSTER,
} from '../../../../../graphql/mutations'
import {
  GET_HOLDING_CLUSTER_BY_VERSION,
  GET_HOLDING_CLUSTER_BY_ID,
  GET_HOLDING_SUBCLUSTER_BY_ID,
} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'

const useStyles = makeStyles(() => ({
  paper: {
    minHeight: '70vh',
  },
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14, minHeight: 54},
}))

function FormCluster(props) {
  const classes = useStyles()
  const client = useApolloClient()
  const subcluster =
    props.location.state.active === 'add-subcluster' ||
    props.location.state.active === 'edit-subcluster'
  const {enqueueSnackbar} = useSnackbar()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [parent, setParent] = useState('')
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [isError, setIsError] = useState(false)
  const [dataParent, setDataParent] = useState(null)
  const id = props.match.params.id

  const getParentCluster = async () => {
    const {data} = await client.query({
      query: GET_HOLDING_CLUSTER_BY_VERSION,
      variables: {version: localStorage.getItem('idVersion')},
      context: {
        headers: {
          'X-Hasura-Role': 'holding-administrator',
        },
      },
    })
    if (data) {
      setDataParent(data.holding_clusters)
    }
  }

  const getClusterByID = async () => {
    const {data} = await client.query({
      query: GET_HOLDING_CLUSTER_BY_ID,
      variables: {id},
      context: {
        headers: {
          'X-Hasura-Role': 'holding-administrator',
        },
      },
    })
    if (data) {
      setName(data.holding_clusters[0].name)
      setDescription(data.holding_clusters[0].description)
    }
  }

  const getSublusterByID = async () => {
    const {data} = await client.query({
      query: GET_HOLDING_SUBCLUSTER_BY_ID,
      variables: {id},
      context: {
        headers: {
          'X-Hasura-Role': 'holding-administrator',
        },
      },
    })
    if (data) {
      setName(data.holding_subcluster[0].name)
      setDescription(data.holding_subcluster[0].description)
      setParent(data.holding_subcluster[0].holding_cluster.id)
    }
  }

  useEffect(() => {
    if (subcluster) {
      getParentCluster()
    }
    if (id) {
      if (subcluster) {
        getSublusterByID()
      } else {
        getClusterByID()
      }
    }
  }, [id, subcluster])

  const handleClickOpen = type => {
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      if (
        (!subcluster && (!name || !description)) ||
        (subcluster && (!name || !description || !parent))
      ) {
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else {
        setConfirmType(type)
        setPopup(true)
      }
    }
  }
  const handleClose = () => {
    setPopup(false)
  }

  const history = () => {
    props.history.push({
      pathname: '/group/config/add',
      state: {activeStep: 2, active: 'add-version'},
    })
  }

  const [mutationAddIndustry] = useMutation(ADD_HOLDING_CLUSTER)
  const [mutationUpdateIndustry] = useMutation(UPDATE_HOLDING_CLUSTER)
  const [mutationAddSpeciality] = useMutation(ADD_HOLDING_SUBCLUSTER)
  const [mutationUpdateSpeciality] = useMutation(UPDATE_HOLDING_SUBCLUSTER)

  const handleMutation = () => {
    if (id) {
      const changes = {
        name,
        description,
      }
      if (subcluster) {
        mutationUpdateSpeciality({
          variables: {id, changes: {...changes, parent}},
          ...holdingRolesContext,
        })
          .then(() => {
            enqueueSnackbar('Success Update data', {
              variant: 'success',
              autoHideDuration: 3000,
            })
            history()
          })
          .catch(e => {
            enqueueSnackbar(
              'Update data error, please try again' + JSON.stringify(e),
              {
                variant: 'error',
                autoHideDuration: 3000,
              }
            )
          })
      } else {
        mutationUpdateIndustry({
          variables: {id, changes},
          ...holdingRolesContext,
        })
          .then(() => {
            enqueueSnackbar('Success Update data', {
              variant: 'success',
              autoHideDuration: 3000,
            })
            history()
          })
          .catch(e => {
            enqueueSnackbar(
              'Update data error, please try again' + JSON.stringify(e),
              {
                variant: 'error',
                autoHideDuration: 3000,
              }
            )
          })
      }
    } else {
      const object = {
        name,
        description,
        version: localStorage.getItem('idVersion'),
      }
      if (subcluster) {
        mutationAddSpeciality({
          variables: {object: {...object, parent, company: COMPANY_ID}},
          ...holdingRolesContext,
        })
          .then(() => {
            enqueueSnackbar('Success Update data', {
              variant: 'success',
              autoHideDuration: 3000,
            })
            history()
          })
          .catch(e => {
            enqueueSnackbar(
              'Update data error, please try again' + JSON.stringify(e),
              {
                variant: 'error',
                autoHideDuration: 3000,
              }
            )
          })
      } else {
        mutationAddIndustry({
          variables: {object},
          ...holdingRolesContext,
        })
          .then(() => {
            enqueueSnackbar('Success Update data', {
              variant: 'success',
              autoHideDuration: 3000,
            })
            history()
          })
          .catch(e => {
            enqueueSnackbar(
              'Update data error, please try again' + JSON.stringify(e),
              {
                variant: 'error',
                autoHideDuration: 3000,
              }
            )
          })
      }
    }
  }

  return (
    <PaperContainer className={classes.paper}>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handleClickOpen('discard')}
          >
            <ArrowBack />
          </IconButton>

          <AddEditTitle variant="h6">
            {id ? 'Edit' : 'Add'} {subcluster ? 'Subcluster' : 'Cluster'}
          </AddEditTitle>
        </FormTitleContainer>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => handleClickOpen(id ? 'edit' : 'add')}
        >
          Save
        </Button>
      </FormToolbar>
      <Divider />
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>{subcluster ? 'Subcluster' : 'Cluster'} Name</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Enter the name"
              error={!name && isError}
              helperText={!name && isError ? 'This field is required' : ''}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Description</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder="Enter the description"
              error={!description && isError}
              helperText={
                !description && isError ? 'This field is required' : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        {subcluster && (
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Parent</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                select
                value={parent}
                onChange={e => setParent(e.target.value)}
                SelectProps={selectProps(parent)}
                error={!parent && isError}
                helperText={!parent && isError ? 'This field is required' : ''}
              >
                <MenuItem value="" disabled>
                  Select parent
                </MenuItem>
                {dataParent &&
                  dataParent.map((res, i) => (
                    <MenuItem value={res.id} key={i}>
                      {res.name}
                    </MenuItem>
                  ))}
              </TextField>
            </FormChildInput>
          </FormChildContainer>
        )}
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={name}
        feature={`Holding ${subcluster ? 'Speciality' : 'Industry'}`}
        mutation={confirmType === 'discard' ? history : handleMutation}
      />
    </PaperContainer>
  )
}

export default withRouter(FormCluster)
