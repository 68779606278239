import React, {useState} from 'react'

import {PaperSection} from '../../../GlobalStyles'
import {Typography, Divider, Avatar, makeStyles} from '@material-ui/core'
import {
  BoldTypography,
  TypographyStyleds,
} from '../../../components/typography/TypographyStyles'

const useStyles = makeStyles(() => ({
  childContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
    width: '50%',
  },
  container: {display: 'flex', flexWrap: 'wrap'},
  divider: {margin: '22px 0!important'},
}))

const MemberSection = ({data}) => {
  const [item, setItem] = useState(0)
  const handleMore = () => {
    if (data.length === item + 6) {
      setItem(0)
    } else {
      setItem(data.length - 6)
    }
  }
  const classes = useStyles()
  return (
    <PaperSection elevation={2} style={{marginBottom: 22}}>
      <Typography variant="body1">Company Member ({data.length})</Typography>
      <Divider className={classes.divider} />
      <div className={classes.container}>
        {data.slice(0, 6 + item).map((res, i) => (
          <div className={classes.childContainer} key={i}>
            <Avatar
              variant="rounded"
              src={res.company_member_profile.logo}
              style={{marginRight: 18}}
            ></Avatar>
            <div>
              <BoldTypography variant="body1" gutterBottom>
                {res.company_member_profile.brand_name}
              </BoldTypography>
              <Typography variant="body2">
                {res.company_member_profile.legal_name}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      {data.length > 6 && (
        <TypographyStyleds
          variant="body2"
          weight="600"
          color="#039be5"
          pointer
          onClick={handleMore}
          width="150"
        >
          {data.length === item + 6 ? 'Hide' : ' View All Member...'}
        </TypographyStyleds>
      )}
    </PaperSection>
  )
}

export default MemberSection
