import React from 'react'

import {Dialog, DialogContent, IconButton, Typography} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import {ModalHeader} from '../../../../../GlobalStyles'
import {AttachmentItemHover} from '../../../../shared-components/attachment'
import {
  BoxText,
  FlexColumnStart,
  FlexStart,
} from '../../../component/modalStyles'

export default function DetailAttachment({open, onCancel, data}) {
  return (
    <Dialog open={open} onClose={onCancel} maxWidth="md">
      <ModalHeader>
        <Typography>Attachment</Typography>

        <IconButton onClick={onCancel} edge="end" color="inherit">
          <CloseIcon />
        </IconButton>
      </ModalHeader>

      <DialogContent style={{padding: 24}}>
        {data && (
          <FlexColumnStart>
            <FlexStart>
              <BoxText style={{width: '35%'}}>Payment Type</BoxText>
              <BoxText style={{width: '100%', color: 'black'}}>
                {data.payment_type === 2 ? `Ticket / Voucher` : 'Transfer'}
              </BoxText>
            </FlexStart>
            <FlexStart>
              <BoxText style={{width: '35%'}}>
                {data.payment_type === 2 ? `Ticket / Voucher` : 'Bank Account'}
              </BoxText>
              <BoxText style={{width: '100%', color: 'black'}}>
                {data.payment_type === 2
                  ? ``
                  : ` : ${data.people_profile_bank?.account_number} (${data.people_profile_bank?.bank_name} - ${data.people_profile_bank?.account_name})`}
              </BoxText>
            </FlexStart>

            <div style={{marginTop: 24, display: 'flex', flexWrap: 'wrap'}}>
              {data.attachment.length < 1 && '-'}

              {data.attachment.map(file => (
                <AttachmentItemHover
                  key={file.url}
                  fileName={file.name}
                  url={file.url}
                  iconAction={
                    <VisibilityOutlinedIcon
                      onClick={() => window.open(file.url)}
                      className="icon"
                    />
                  }
                />
              ))}
            </div>
          </FlexColumnStart>
        )}
      </DialogContent>
    </Dialog>
  )
}
