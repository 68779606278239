import {useMutation} from '@apollo/react-hooks'
import React, {useEffect, useState} from 'react'
import {
  ADD_AWARD,
  UPDATE_AWARD,
  DELETE_AWARD,
} from '../../../../../../graphql/mutations'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../DetailEmployeeStyles'
import {SwitchForm, ButtonSubmit} from '../../SharedDetailEmployee'
import FormInput from '../ShareComponents-detail/FormInput'
import {isErrorForm} from '../ShareComponents-detail/helperDetail.jsx'

const INITIAL_VALUES_STATE = {
  user: null,
  is_share: false,
  reference_number: null,
  letter_number: null,
  certificate_number: null,
  award_name: null,
  issuing_organization: null,
  reference_date: undefined,
  remark: null,
  attachments: [],
}

const HELPER_TEXT = 'Bagian ini diperlukan'

export default function AddEdit({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
  user_name_long,
}) {
  const [values, setValues] = useState(INITIAL_VALUES_STATE)
  const [errorState, setErrorState] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [addAward] = useMutation(ADD_AWARD)
  const [updateAward] = useMutation(UPDATE_AWARD)
  const [deleteAward] = useMutation(DELETE_AWARD)

  useEffect(() => {
    if (actionType === 'edit' && open) {
      setValues(e => ({
        ...e,
        user: userIdDetail,
        is_share: data.is_share,
        reference_number: data.reference_number,
        letter_number: data.letter_number,
        certificate_number: data.certificate_number,
        award_name: data.award_name,
        issuing_organization: data.issuing_organization,
        reference_date: data.reference_date,
        remark: data.remark,
        attachments: data.attachments,
      }))
    } else {
      setValues(e => ({
        ...e,
        user: userIdDetail,
      }))
    }
  }, [open, actionType])

  const fieldsList = [
    {
      label: 'Nomor Referensi*',
      placeholder: 'Tambahkan nomor referensi',
      fieldName: 'reference_number',
      type: 'textfield',
      required: true,
      value: values.reference_number,
      error: !values.reference_number,
    },
    {
      label: 'Nomor Surat Penghargaan',
      placeholder: 'Tambahkan nomor surat penghargaan',
      fieldName: 'letter_number',
      type: 'textfield',
      required: false,
      value: values.letter_number,
      error: !values.letter_number,
    },
    {
      label: 'Tambahkan sertifikat penghargaan',
      placeholder: 'Tambahkan nomor sertifikat penghargaan',
      fieldName: 'certificate_number',
      type: 'textfield',
      required: false,
      value: values.certificate_number,
      error: !values.certificate_number,
    },
    {
      label: 'Nama Penghargaan*',
      placeholder: 'Tambahkan nama penghargaan',
      fieldName: 'award_name',
      type: 'textfield',
      required: true,
      value: values.award_name,
      error: !values.award_name,
    },
    {
      label: 'Nama Pegawai*',
      placeholder: 'Tambahkan nama pegawai',
      fieldName: 'employee',
      type: 'textfield',
      required: true,
      value: user_name_long,
      error: !values.employee,
    },
    {
      label: 'Organisasi Penerbit*',
      placeholder: 'Tambahkan organisasi penerbit',
      fieldName: 'issuing_organization',
      type: 'textfield',
      required: true,
      value: values.issuing_organization,
      error: !values.issuing_organization,
    },
    {
      label: ['Tanggal referensi*'],
      filter: ['date'],
      fieldName: 'reference_date',
      type: 'dateCustom',
      required: true,
      value: values.reference_date,
      error: !values.reference_date,
    },
    {
      label: 'Komentar',
      placeholder: 'Tambahkan komentar',
      fieldName: 'remark',
      type: 'textfield',
      multiline: true,
      rows: 6,
      value: values.remark,
      error: true,
    },
    {
      label: 'Lampiran',
      fieldName: 'attachment',
      type: 'attachment',
      attachments: values.attachments,
      error: true,
      multiple: true,
      link: 'url',
    },
  ]
  const handleChange = ({fieldName, value}, target, ekstra) => {
    if (target === 'date') {
      setValues(e => ({
        ...e,
        [ekstra]: value,
      }))
    } else {
      setValues(e => ({
        ...e,
        [fieldName]: value,
      }))
    }
  }

  const handleClose = () => {
    onClose()
    setValues(INITIAL_VALUES_STATE)
  }

  const handleSave = async () => {
    const isError = await isErrorForm(fieldsList)
    if (isError) {
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000)
    } else {
      // const _initalSalary = await changeToNumber(values.initial_salary)
      // const _ilastSalary = await changeToNumber(values.last_salary)
      const object = values
      if (actionType === 'add') {
        addAward({
          variables: {object},
        })
          .then(() => {
            refetch()
            enqueueSnackbar('Berhasil menambahkan Penghargaan', {
              variant: 'success',
              autoHideDuration: 2000,
            })
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar('Gagal menambahkan Penghargaan', {
              variant: 'error',
              autoHideDuration: 2000,
            })
          })
      } else {
        updateAward({
          variables: {
            id: data.id,
            object: object,
          },
        })
          .then(() => {
            refetch()
            enqueueSnackbar('Berhasil mengubah Penghargaan', {
              variant: 'success',
              autoHideDuration: 2000,
            })
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar('Gagal mengubah Penghargaan', {
              variant: 'error',
              autoHideDuration: 2000,
            })
          })
      }
    }
  }

  const handleDelete = () => {
    deleteAward({
      variables: {
        id: data.id,
        userId: userIdDetail,
      },
    })
      .then(() => {
        refetch()
        setOpenDelete(false)
        enqueueSnackbar('Berhasil menghapus Penghargaan', {
          variant: 'success',
          autoHideDuration: 2000,
        })
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar('Gagal menghapus Penghargaan', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleOpenDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  return (
    <>
      <ModalDetail
        onDelete={actionType === 'edit' && handleOpenDelete}
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        title={
          (actionType === 'add'
            ? 'Tambahkan'
            : actionType === 'edit'
            ? 'Ubah'
            : '') + ' Penghargaan'
        }
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Bagikan dengan jaringan:"
            value={values.is_share}
            labelPlacement="start"
            onChange={e =>
              handleChange({
                fieldName: 'is_share',
                value: e.target.checked,
              })
            }
          />
        </div>
        <ModalAddEditWraper>
          <FormInput
            fieldsList={fieldsList}
            open={open}
            setValues={setValues}
            values={values}
            errorState={errorState}
            HELPER_TEXT={HELPER_TEXT}
          />
        </ModalAddEditWraper>
        <ButtonSubmit onCancel={handleClose} onSave={handleSave} />
      </ModalDetail>
      <DeletePopup
        open={openDelete}
        handleClose={handleCloseDelete}
        name="Penghargaan"
        mutation={handleDelete}
      />
    </>
  )
}
