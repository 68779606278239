import React, {useState, useEffect} from 'react'
import {
  withStyles,
  Stepper,
  Button,
  Step,
  StepLabel,
  StepConnector,
  Divider,
  Menu,
  MenuItem,
  Dialog,
} from '@material-ui/core'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {Link, withRouter} from 'react-router-dom'
import {
  EnhancedToolbar,
  PaperContainer,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import helperStyle, {
  BigTextBlackBold,
  WrapperModalConfirmation,
  Badge,
  FlexStart,
  TextGrey,
  BadgeWrapper,
  FlexBetween,
} from '../PayrollPageStyles'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import ModalProcessCalculation from '../shared-components/dialog/ModalProcessCalculation'
import {
  GET_LIST_EMPLOYEE_THR,
  GET_IS_APPROVER_THR,
} from '../../../../graphql/queries'
import {
  UPDATE_PROCESS_STEP_THR,
  UPDATE_APPROVAL_THR,
  UPDATE_APPROVAL_THR_LAST_APPROVER,
  REJECT_APPROVAL_THR_PROCESS,
} from '../../../../graphql/mutations'
import StepThrData from './step-thr/StepThrData'
import ApprovalTable from './step-thr/ApprovalTable'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../utils'
import moment from 'moment'
import {useSnackbar} from 'notistack'

import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import useDownloadReport from '../../../../hooks/useDownloadReport'

function StepTHRProcess(props) {
  const {history, searchText, location, filterData, payrollCalculation} = props
  const {enqueueSnackbar} = useSnackbar()

  // const step = status === 'Paid' ? 2 : 0
  const [openModal, setOpenModal] = useState(false)
  const [titleModal, setTitleModal] = useState('Confirm Changes')
  const [bodyModal, setBodyModal] = useState(
    'Are you sure want process to the next step?'
  )
  const [
    showModalProcessCalculation,
    setShowModalProcessCalculation,
  ] = useState(false)
  const [modalType, setModalType] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [approvalStatement, setApprovalStatement] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [processStepDirection, setProcessStepDirection] = useState('next')
  const [approveStep, setApproveStep] = useState(-1)
  const [isApprover, setIsApprover] = useState(false)
  const [isFirstApprover, setIsFirstApprover] = useState(false)
  const [isSecondApprover, setIsSecondApprover] = useState(false)
  const [isThirdApprover, setIsThirdApprover] = useState(false)
  const [isLastApprover, setIsLastApprover] = useState(false)
  const [listOfApprover, setListOfApprover] = useState([])
  const [openDownload2, setOpenDownload2] = useState(false)
  const [openDownload3, setOpenDownload3] = useState(false)

  const [prosessImport, setProssesImport] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [approvalStatus, setApprovalStatus] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [alert, setAlert] = useState({
    sendAll: false,
    cancel: false,
  })

  const {state} = location

  const where = {
    id_schedule: {_eq: state.id || null},
    people_work_placement: {
      _or: [{global_user: {name: {}}}, {regno: {}}],
      company_job_profile: {
        _and: [
          {id: {}},
          {organization: {}},
          {position: {}},
          {rank: {}},
          {office_address: {}},
        ],
      },
    },
  }
  const {data: thrProcessData, loading, error} = useQuery(
    GET_LIST_EMPLOYEE_THR,
    {
      variables: {
        where,
        limit: 1,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )
  const {data: approverData} = useQuery(GET_IS_APPROVER_THR, {
    variables: {
      login: USER_ID,
      company: COMPANY_ID,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const {
    dataBaloon: dataBaloon2,
    isDisableButton: isDisableButton2,
    onDownload: onDownload2,
    onCloseBaloon: onCloseBaloon2,
  } = useDownloadReport({
    table: 'finance_thr_report_2',
    filter: {id_schedule: state.id},
  })

  const handleDownloadStep2 = () => {
    setOpenDownload2(prev => !prev)
    if (openDownload2) {
      onDownload2({
        variables: {
          table: 'finance_thr_report_2',

          filter: {id_schedule: state.id},
        },
      })
    }
  }

  const {
    dataBaloon: dataBaloon3,
    isDisableButton: isDisableButton3,
    onDownload: onDownload3,
    onCloseBaloon: onCloseBaloon3,
  } = useDownloadReport({
    table: 'finance_thr_report_3',

    filter: {id_schedule: state.id},
  })

  const handleDownloadStep3 = () => {
    setOpenDownload3(prev => !prev)
    if (openDownload3) {
      onDownload3({
        variables: {
          table: 'finance_thr_report_3',

          filter: {id_schedule: state.id},
        },
      })
    }
  }

  useEffect(() => {
    if (thrProcessData) {
      if (thrProcessData.employees[0]) {
        setActiveStep(thrProcessData.employees[0].process_step)
        setApproveStep(thrProcessData.employees[0].approve_step)

        if (payrollCalculation) {
          payrollCalculation.approveStep =
            thrProcessData.employees[0].approve_step
          payrollCalculation.totalEmployee =
            thrProcessData.employee_count.aggregate.count
        }
      }
    }
  }, [thrProcessData])

  useEffect(() => {
    if (listOfApprover) {
      if (payrollCalculation) payrollCalculation.listOfApprover = listOfApprover
    }
  }, [listOfApprover])

  useEffect(() => {
    if (approverData && approveStep !== -1) {
      const listOfAttr = [
        'first_job_profile',
        'second_job_profile',
        'third_job_profile',
      ]
      if (approverData.finance_payroll_settings_by_pk) {
        const {
          is_first,
          is_second,
          is_third,
        } = approverData.finance_payroll_settings_by_pk

        if (is_first) {
          if (is_first.people_work_placements) {
            if (is_first.people_work_placements.length > 0) {
              const firstApproverList = is_first.people_work_placements.map(
                item => item.user
              )
              setIsFirstApprover(firstApproverList.includes(USER_ID))

              if (approveStep === 0) {
                setIsApprover(firstApproverList.includes(USER_ID))
                if (!is_second && !is_third) {
                  setIsLastApprover(true)
                }
              }
            }
          }
        }
        if (is_second) {
          if (is_second.people_work_placements) {
            if (is_second.people_work_placements.length > 0) {
              const secondApproverList = is_second.people_work_placements.map(
                item => item.user
              )
              setIsSecondApprover(secondApproverList.includes(USER_ID))

              if (approveStep === 1) {
                setIsApprover(secondApproverList.includes(USER_ID))
                if (!is_third) {
                  setIsLastApprover(true)
                }
              }
            }
          }
        }
        if (is_third) {
          if (is_third.people_work_placements) {
            if (is_third.people_work_placements.length > 0) {
              const thirdApproverList = is_third.people_work_placements.map(
                item => item.user
              )
              setIsThirdApprover(thirdApproverList.includes(USER_ID))

              if (approveStep === 2) {
                setIsApprover(thirdApproverList.includes(USER_ID))
                setIsLastApprover(true)
              }
            }
          }
        }
        if (approveStep === 3) {
          setIsLastApprover(true)
          setIsApprover(true)
        }

        listOfAttr.forEach(attrName => {
          const selectedAttr =
            approverData.finance_payroll_settings_by_pk[attrName]
          if (selectedAttr) {
            setListOfApprover(prev => prev.concat(selectedAttr.title))
          }
        })
      }
    }
  }, [approverData, approveStep])

  useEffect(() => {
    const listOfAttrs = ['is_first', 'is_second', 'is_third']
    if (approverData) {
      let selectedIndex = 0
      for (const key of listOfAttrs) {
        if (approverData.finance_payroll_settings_by_pk[key]) {
          if (
            approverData.finance_payroll_settings_by_pk[key]
              .people_work_placements.length > 0
          ) {
            selectedIndex = listOfAttrs.indexOf(key)
            setApprovalStatus(selectedIndex)
            break
          }
        }
      }
    }
  }, [approverData])

  const [updateStep] = useMutation(UPDATE_PROCESS_STEP_THR, GQL_FINANCE_OPTIONS)
  const [rejectApproval] = useMutation(
    REJECT_APPROVAL_THR_PROCESS,
    GQL_FINANCE_OPTIONS
  )
  const [updateApproval] = useMutation(UPDATE_APPROVAL_THR, GQL_FINANCE_OPTIONS)
  const [updateApprovalByLastApprover] = useMutation(
    UPDATE_APPROVAL_THR_LAST_APPROVER,
    GQL_FINANCE_OPTIONS
  )

  const getSteps = () => {
    return ['THR Data', 'Calculation', 'Approval', 'Review', 'THR Slip']
  }
  const steps = getSteps()
  const classes = helperStyle()

  const handleOpenModal = process => {
    setProcessStepDirection(process)
    if (process === 'prev') {
      if ([0, 1, 2].includes(activeStep)) {
        setTitleModal('Discard Changes?')
        setBodyModal('Are you sure want to discard unsave changes?')
      } else {
        setBodyModal('Are you sure want process to the previous step?')
      }
      setOpenModal(true)
    } else {
      if (activeStep === 0) {
        setTitleModal('Calculate THR')
      }
      setBodyModal('Are you sure want process to the next step?')
      if (activeStep === 4) {
        // setAnchorEl(event.currentTarget)
        setTitleModal('Send THR Slip Confirmation?')
        setBodyModal(
          `Are you sure to send ${moment(state.date).format(
            'MMMM DD, YYYY'
          )} THR Slip to all employees?`
        )
        setOpenModal(true)
      } else if (activeStep === 3) {
        setTitleModal('Transferred THP Confirmation?')
        setBodyModal('Are you sure you have transferred all of employees THR?')
        setOpenModal(true)
      } else if (activeStep === 2) {
        setShowModalProcessCalculation(true)
      } else if (activeStep === 0) {
        setBodyModal(
          `Are you sure to calculate ${moment(state.date).format(
            'MMMM DD, YYYY'
          )}  THR Data?`
        )
        setOpenModal(true)
      } else if (activeStep === 1) {
        if (listOfApprover.length > 0) {
          setTitleModal('Request Approval?')
          setBodyModal(
            `Are you sure to request approval from ${
              listOfApprover ? listOfApprover.join(', ') : ''
            }?`
          )
        } else {
          setTitleModal('Process?')
          setBodyModal(' Are you sure to process THR Data?')
        }
        setOpenModal(true)
      } else {
        setOpenModal(true)
      }
    }
  }

  const handleRejectApproval = variables => {
    variables.approve_step = approveStep
    rejectApproval({
      variables,
      ...GQL_FINANCE_OPTIONS,
      refetchQueries: [
        {
          query: GET_LIST_EMPLOYEE_THR,
          variables: {
            where,
            limit: 1,
          },
          ...GQL_FINANCE_OPTIONS,
        },
      ],
    })
      .then(() => {
        setActiveStep(variables.process_step)
        enqueueSnackbar(
          `Success reject ${moment(state.date).format('MMMM DD, YYYY')} THR`,
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        )
      })
      .finally(() => {
        setOpenModal(false)
        setModalType(null)
      })
  }

  const handleUpdateStep = () => {
    if (processStepDirection === 'prev' && [0, 2].includes(activeStep)) {
      history.push({
        pathname: '/financial/payroll/thr-process',
        state: {
          active: 'thr-process',
          searchable: false,
          showPeriod: true,
          noMaxDate: true,
        },
      })
    } else {
      const variables = {
        schedule_id: state.id,
        company: COMPANY_ID,
        year: Number(moment(state.date).format('YYYY')),
        month: Number(moment(state.date).format('MM')),
        process_step: activeStep + 1,
      }

      // Back button from step 2, 4, 5, or from reject on step 3
      if (processStepDirection === 'prev' || modalType === 'reject') {
        variables.process_step = activeStep - 1
      }

      // if (activeStep === 4) {
      //   setAnchorEl(event.currentTarget)
      // }

      if (activeStep === 2 && modalType !== 'reject') {
        // For last approver and approve
        if (isLastApprover) {
          variables.login = USER_ID
          updateApprovalByLastApprover({
            variables,
            ...GQL_FINANCE_OPTIONS,
            refetchQueries: [
              {
                query: GET_LIST_EMPLOYEE_THR,
                variables: {
                  where,
                  limit: 1,
                },
                ...GQL_FINANCE_OPTIONS,
              },
              {
                query: GET_IS_APPROVER_THR,
                variables: {
                  login: USER_ID,
                  company: COMPANY_ID,
                },
                ...GQL_FINANCE_OPTIONS,
              },
            ],
          })
            .then(() => {
              enqueueSnackbar(
                `Success approve ${moment(state.date).format(
                  'MMMM DD, YYYY'
                )} THR`,
                {
                  variant: 'success',
                  autoHideDuration: 3000,
                }
              )
            })
            .catch(e =>
              enqueueSnackbar(JSON.stringify(e), {
                variant: 'error',
              })
            )
            .finally(() => {
              setOpenModal(false)
              setIsApprover(false)
              setModalType(null)
            })
        }
        // Not last approver and approve
        else {
          variables.login = USER_ID
          delete variables.process_step

          updateApproval({
            variables,
            ...GQL_FINANCE_OPTIONS,
            refetchQueries: [
              {
                query: GET_LIST_EMPLOYEE_THR,
                variables: {
                  where,
                  limit: 1,
                },
                ...GQL_FINANCE_OPTIONS,
              },
              {
                query: GET_IS_APPROVER_THR,
                variables: {
                  login: USER_ID,
                  company: COMPANY_ID,
                },
                ...GQL_FINANCE_OPTIONS,
              },
            ],
          })
            .then(() => {
              enqueueSnackbar(
                `Success approve ${moment(state.date).format(
                  'MMMM DD, YYYY'
                )} THR`,
                {
                  variant: 'success',
                  autoHideDuration: 3000,
                }
              )
            })
            .catch(e =>
              enqueueSnackbar(JSON.stringify(e), {
                variant: 'error',
              })
            )
            .finally(() => {
              setOpenModal(false)
              setIsApprover(false)
              setModalType(null)
            })
        }
      }
      // Handle back or next action from step 1, 2, 4, 5, and from reject on step 3
      else {
        if (activeStep === 1 && listOfApprover.length === 0) {
          variables.process_step += 1
        }

        // reject approval on approval step
        if (modalType === 'reject') {
          return handleRejectApproval(variables)
        }

        updateStep({
          variables,
          ...GQL_FINANCE_OPTIONS,
          refetchQueries: [
            {
              query: GET_LIST_EMPLOYEE_THR,
              variables: {
                where,
                limit: 1,
              },
              ...GQL_FINANCE_OPTIONS,
            },
          ],
        })
          .then(() => {
            if (activeStep === 0) {
              enqueueSnackbar('Success calculate THR Data', {
                variant: 'success',
                autoHideDuration: 3000,
              })
            } else if (activeStep === 1) {
              enqueueSnackbar('Process THR Data Success', {
                variant: 'success',
                autoHideDuration: 3000,
              })
            } else if (activeStep === 3) {
              enqueueSnackbar('Success Transfer THR', {
                variant: 'success',
                autoHideDuration: 3000,
              })
            } else if (activeStep === 4 && processStepDirection !== 'prev') {
              setAlert({...alert, sendAll: true})
            } else {
              enqueueSnackbar('Success Update Process', {
                variant: 'success',
                autoHideDuration: 3000,
              })
            }
          })
          .catch(e =>
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          )
          .finally(() => {
            setOpenModal(false)
            setModalType(null)
          })
      }
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSendAllSalarySlip = type => {
    type === 'send'
      ? setAlert({...alert, sendAll: true})
      : setAlert({...alert, cancel: true})
    handleClose()
  }
  const handleCloseAlert = type => {
    if (type === 'send') {
      setAlert({...alert, sendAll: false})
      // history.push({
      //   pathname: '/financial/payroll/thr-process/step',
      //   state: {
      //     active: 'step-thr-process',
      //     status: 'Paid',
      //   },
      // })
    } else {
      setAlert({...alert, cancel: false})
      history.push({
        pathname: '/financial/payroll/thr-process',
        state: {
          active: 'thr-process',
          searchable: false,
          showPeriod: true,
          noMaxDate: true,
        },
      })
    }
  }
  const onCloseModalProcessCalculation = () => {
    setShowModalProcessCalculation(false)
  }
  const reShowModalProcessCalculation = () => {
    setOpenModal(false)
    setShowModalProcessCalculation(true)
  }
  const onReject = () => {
    setModalType('reject')
    setTitleModal('Reject Confirmation?')
    setBodyModal('Are you sure, you want to reject all of THR calculations?')
    setOpenModal(true)
  }
  const onApprove = () => {
    setModalType('approve')
    setTitleModal('Approve Confirmation?')
    setBodyModal('Are you sure, you want to approve all of THR calculations?')
    setOpenModal(true)
  }

  const renderApprovalStatusBadge = () => {
    if (
      (isFirstApprover && approveStep > 0) ||
      (isSecondApprover && approveStep > 1) ||
      (isThirdApprover && approveStep > 2)
    ) {
      return (
        <BadgeWrapper>
          <Badge style={{backgroundColor: '#4caf50'}}>Approved</Badge>
        </BadgeWrapper>
      )
    } else {
      return (
        <FlexStart
        // style={{minWidth: 500}}
        >
          <TextGrey>
            <BadgeWrapper>
              <Badge
                style={{
                  backgroundColor: '#ffa000',
                }}
              >
                Waiting
              </Badge>
            </BadgeWrapper>
          </TextGrey>

          <div
          // style={{width: '80%'}}
          >
            <TextGrey>
              Waiting for{' '}
              <span
                style={{
                  color: '#3b86ff',
                  // maxWidth: 250,
                }}
              >
                {`${listOfApprover[approveStep]}'s `}
              </span>
              Approval
            </TextGrey>
          </div>
        </FlexStart>
      )
    }
  }

  const renderActionButton = activeStep => {
    switch (activeStep) {
      case 0:
        return 'Calculate'
      case 1:
        return 'Process'
      case 2:
        return 'Action'
      case 3:
        return 'Next'
      case 4:
        return 'Send All THR Slip'
      default:
        break
    }
  }

  if (error) console.error(JSON.stringify(error))

  return (
    <PaperContainer>
      <ModalConfirm
        open={openModal}
        title={titleModal}
        body={bodyModal}
        activeStep={activeStep}
        onClose={() => setOpenModal(false)}
        onConfirm={handleUpdateStep}
        actionAfterClose={reShowModalProcessCalculation}
        processStepDirection={processStepDirection}
        type={modalType}
      />
      <ModalProcessCalculation
        data={payrollCalculation}
        onApprove={onApprove}
        onClose={onCloseModalProcessCalculation}
        onReject={onReject}
        onStatementChange={value => setApprovalStatement(value)}
        open={showModalProcessCalculation}
        loading={false}
        type={'THR'}
      />
      <EnhancedToolbar>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <BigTextBlackBold>THR Process</BigTextBlackBold>
          {activeStep === 2 && approverData && renderApprovalStatusBadge()}
        </div>
        <div style={{minWidth: 'max-content'}}>
          <FlexBetween>
            {activeStep === 1 && thrProcessData && (
              <GenerateTooltip
                title={
                  isDisableButton2
                    ? `You can only generate data once a day. To download data use the link that already generated.`
                    : ``
                }
                placement="left"
              >
                <span>
                  <Button
                    color="primary"
                    disabled={isDisableButton2}
                    onClick={handleDownloadStep2}
                    size="large"
                    style={{marginRight: 8}}
                    variant="outlined"
                  >
                    Download Data
                  </Button>
                </span>
              </GenerateTooltip>
            )}

            {activeStep === 3 && thrProcessData && (
              <GenerateTooltip
                title={
                  isDisableButton3
                    ? `You can only generate data once a day. To download data use the link that already generated.`
                    : ``
                }
                placement="left"
              >
                <span>
                  <Button
                    color="primary"
                    disabled={isDisableButton3}
                    onClick={handleDownloadStep3}
                    size="large"
                    style={{marginRight: 8}}
                    variant="outlined"
                  >
                    Download Data
                  </Button>
                </span>
              </GenerateTooltip>
            )}

            {[0, 1, 2, 4].includes(activeStep) && (
              <Button
                size="large"
                className={classes.backButton}
                color="default"
                onClick={event => handleOpenModal('prev', event)}
              >
                {[0, 2].includes(activeStep) ? 'Cancel' : 'Back'}
              </Button>
            )}
            {activeStep <= 4 ? (
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={event => handleOpenModal('next', event)}
                disabled={
                  (activeStep === 2 && !isApprover) ||
                  (activeStep === 1 && prosessImport)
                }
              >
                {renderActionButton(activeStep)}
              </Button>
            ) : null}
          </FlexBetween>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className={classes.linkDarkBlue}
              onClick={() => handleSendAllSalarySlip('send')}
            >
              Send All Salary Slip
            </MenuItem>
            <Link
              to={{
                pathname: `/financial/payroll/report`,
                state: {
                  active: 'report',
                  // policies: data.time_leave_policies,
                },
              }}
              className={classes.linkDarkBlue}
            >
              <MenuItem onClick={handleClose}>View Payroll Report</MenuItem>
            </Link>
            <MenuItem
              className={classes.linkRed}
              onClick={() => handleSendAllSalarySlip('cancel')}
            >
              Cancel
            </MenuItem>
          </Menu>
        </div>
      </EnhancedToolbar>
      <Divider />
      {thrProcessData && !loading && (
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomConnector />}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                classes={{
                  active: classes.labelActive,
                  completed: classes.labelActive,
                }}
                StepIconProps={{
                  classes: {
                    root: classes.iconRoot,
                    text: classes.iconText,
                    active: classes.iconActive,
                    completed: classes.iconActive,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {activeStep === 1 && (
        <ExportBaloon
          availableToSeeProblem
          isLastGenerateStatus
          isReport
          data={dataBaloon2}
          closeBaloonMutation={onCloseBaloon2}
          retry={handleDownloadStep2}
        />
      )}
      {activeStep === 3 && (
        <ExportBaloon
          availableToSeeProblem
          isLastGenerateStatus
          isReport
          data={dataBaloon3}
          closeBaloonMutation={onCloseBaloon3}
          retry={handleDownloadStep3}
        />
      )}
      {activeStep === 2 ? (
        <ApprovalTable
          religion={state.religion ? state.religion : null}
          searchText={searchText}
          filterData={filterData}
        />
      ) : (
        <StepThrData
          religion={state.religion ? state.religion : null}
          searchText={searchText}
          filterData={filterData}
          activeStep={activeStep}
          setProssesImport={setProssesImport}
        />
      )}
      <AlertDialog
        open={alert.sendAll}
        handleClose={() => handleCloseAlert('send')}
        feature={`Send THR Slip`}
        message={`THR Slips have been sent successfully`}
        type="Finish"
      />
      <AlertDialog
        open={alert.cancel}
        handleClose={() => handleCloseAlert('cancel')}
        feature={`Discard Changes?`}
        message={`Are you sure you want to discard unsaved changes?`}
        type="Discard"
        status="cancel"
      />
      {activeStep === 1 && (
        <AlertDialog
          open={openDownload2}
          handleClose={handleDownloadStep2}
          feature="Download Data is Processing!"
          message="Download Data is processing! We’ll send you an email when it’s ready."
          type="Confirm"
        />
      )}
      {activeStep === 3 && (
        <AlertDialog
          open={openDownload3}
          handleClose={handleDownloadStep3}
          feature="Download Data is Processing!"
          message="Download Data is processing! We’ll send you an email when it’s ready."
          type="Confirm"
        />
      )}
    </PaperContainer>
  )
}

export default withRouter(StepTHRProcess)

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

function ModalConfirm({
  open,
  onClose,
  title,
  body,
  onConfirm,
  activeStep,
  processStepDirection,
  actionAfterClose,
  type,
}) {
  const handleClose = () => {
    if ((type === 'approve' || type === 'reject') && activeStep === 2)
      return actionAfterClose()
    else return onClose()
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="xs">
      <WrapperModalConfirmation>
        <div className="header-modal-confirmation">{title}</div>
        <div className="body-modal-confirmation">{body}</div>
        <div className="action-wrapper-modal-confirmation">
          <Button style={{fontSize: 12}} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            style={{fontSize: 12, marginLeft: 12}}
            onClick={onConfirm}
            size="large"
            variant="contained"
            color="primary"
          >
            {processStepDirection === 'prev' && activeStep !== 4
              ? 'Discard'
              : 'Confirm'}
          </Button>
        </div>
      </WrapperModalConfirmation>
    </Dialog>
  )
}
