import React, {useEffect, useState} from 'react'
import ArrowBack from '@material-ui/icons/ArrowBack'
import MaterialTable from 'material-table'
import moment from 'moment'
import {Button, CircularProgress, IconButton} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {
  EnhancedToolbar,
  GenerateTooltip,
  PaperContainer,
} from '../../../../../../GlobalStyles'
import {
  BigTextBlackBold,
  CenterWrapper,
  pageSizeOption,
  TableOptions,
} from '../../../PayrollPageStyles'
import EmployeeAvatar from '../../../../../shared-components/EmployeeAvatar'
import {tableStyle} from '../../../PayrollTableStyles'
import ExportBaloon from '../../../../../../components/exportExcel/ExportBaloon'
import AlertDialog from '../../../../../shared-components/popup/ResultPopup'

import {
  GET_TAX_DETAILS_REPORT,
  GET_TAX_DETAILS_REPORT_THR,
} from '../../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../../utils/globals'
import {
  convertToRupiah,
  employeeColumnSort,
} from '../../../../../../utils/helpers'
import {GQL_FINANCE_OPTIONS} from '../../../utils'
import useDownloadReport from '../../../../../../hooks/useDownloadReport'

const TABLE_REPORT_NAME = 'finance_tax_report'

export default withRouter(function DetailTaxReport(props) {
  const {searchText, filterData} = props
  const {month, year, payroll} = props.location.state

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const [confirmDialog, setConfirmDialog] = useState(false)

  const months = moment.monthsShort()
  const period = `${month && months[month - 1]} ${year || ''}`
  const isPayroll = JSON.parse(payroll)
  const labelThr = !isPayroll && 'THR '
  const filter = {
    period_year: year,
    period_month: month,
    is_thr: !isPayroll,
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [period, rowsPerPage])

  const QueryDetail = isPayroll
    ? GET_TAX_DETAILS_REPORT
    : GET_TAX_DETAILS_REPORT_THR
  const {data: taxReportDetail, loading, error} = useQuery(QueryDetail, {
    fetchPolicy: 'cache-and-network',
    variables: {
      company: COMPANY_ID,
      year: year,
      month: month,
      search: `%${searchText}%`,
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
      organization:
        JSON.stringify(filterData.organization) === '[]'
          ? null
          : filterData.organization,
      position:
        JSON.stringify(filterData.position) === '[]'
          ? null
          : filterData.position,
      job_profile:
        JSON.stringify(filterData.job_profile) === '[]'
          ? null
          : filterData.job_profile,
      rank: JSON.stringify(filterData.rank) === '[]' ? null : filterData.rank,
      office:
        JSON.stringify(filterData.office) === '[]' ? null : filterData.office,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const {
    dataBaloon,
    onDownload,
    isDisableButton,
    onCloseBaloon,
  } = useDownloadReport({
    table: TABLE_REPORT_NAME,
    filter,
  })

  const requestDownloadReport = () => {
    setConfirmDialog(false)
    onDownload({
      variables: {
        table: TABLE_REPORT_NAME,
        filter,
      },
    })
  }

  const getPph = arrPph => {
    const PPH_ID = -7
    return arrPph.find(item => item.id === PPH_ID)?.value || 0
  }

  const taxThrDetail = taxReportDetail?.people_work_thr
  const computedTaxReportDetail = taxReportDetail?.people_work_payroll?.map(
    tax => {
      return {
        ...tax,
        computedNonFixedComponent: tax?.non_fixed_components
          ?.filter(item => item.tax_paid_by !== 0)
          ?.reduce(
            (previousValue, currentValue) => previousValue + currentValue.value,
            0
          ),
        computedAllowance: tax?.allowances
          ?.filter(item => item.tax_paid_by !== 0)
          ?.reduce(
            (previousValue, currentValue) => previousValue + currentValue.value,
            0
          ),
        computedBpjs: tax?.payroll_fields?.CompanyDeductions?.filter(
          item => item.id !== -6
        )?.reduce(
          (previousValue, currentValue) => previousValue + currentValue.value,
          0
        ),
        computedTunjangan: tax?.payroll_fields?.CompanyComponents?.find(
          data => data.id === -6
        )?.value,
        computedPph21NonFixedComponent: tax?.non_fixed_components?.find(
          data => data.id === -7
        ),
        computedPph21CompanyDeductions: tax?.payroll_fields?.CompanyDeductions?.find(
          data => data.id === -6
        ),
      }
    }
  )

  return (
    <>
      <PaperContainer>
        <EnhancedToolbar style={{paddingLeft: 12}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Link
              to={{
                pathname: '/financial/payroll/report',
                state: {
                  active: 'report',
                  searchable: false,
                  showPeriod: false,
                  tab: 'tax',
                },
              }}
            >
              <IconButton edge="end" aria-label="back">
                <ArrowBack />
              </IconButton>
            </Link>

            <BigTextBlackBold style={{marginLeft: 16}}>
              Tax Report Detail - {labelThr}
              {period}
            </BigTextBlackBold>
          </div>

          <GenerateTooltip
            title={
              isDisableButton
                ? `You can only generate data once a day. To download data use the link that already generated.`
                : ``
            }
            placement="left"
          >
            <span>
              <Button
                size="large"
                variant="contained"
                color="primary"
                style={{
                  color: 'white',
                }}
                disabled={isDisableButton}
                onClick={() => setConfirmDialog(true)}
              >
                Generate Data
              </Button>
            </span>
          </GenerateTooltip>
        </EnhancedToolbar>

        {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}

        {!computedTaxReportDetail && loading && (
          <CenterWrapper>
            <CircularProgress />
          </CenterWrapper>
        )}

        <AlertDialog
          open={confirmDialog}
          mutation={() => requestDownloadReport()}
          feature="Generate Data is Processing!"
          message="Generate Data is processing! We’ll send you an email when it’s ready."
          type="Confirm"
        />
        <ExportBaloon
          isLastGenerateStatus
          isReport
          data={dataBaloon}
          closeBaloonMutation={onCloseBaloon}
          retry={requestDownloadReport}
          feature="Tax Report"
        />

        {(computedTaxReportDetail || taxThrDetail) && (
          <>
            <MaterialTable
              data={isPayroll ? computedTaxReportDetail : taxThrDetail}
              isLoading={taxReportDetail && loading}
              onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
              options={TableOptions}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              style={{fontSize: 12}}
              totalCount={taxReportDetail?.employee_count?.aggregate?.count}
              onChangePage={page => {
                setCurrentPage(page)
              }}
              columns={[
                {
                  title: 'Employee',
                  field: 'employee',
                  customSort: employeeColumnSort,
                  headerStyle: {minWidth: 380, paddingLeft: 26},
                  cellStyle: {
                    minWidth: 380,
                    paddingLeft: 26,
                    textAlign: 'left',
                  },
                  render: rowData => {
                    const placement = isPayroll
                      ? rowData.employee_placement
                      : rowData.people_work_placement

                    return (
                      <EmployeeAvatar
                        regno={placement?.regno}
                        title={placement?.company_job_profile?.title}
                        global_user={placement?.global_user}
                        empStatus={placement?.status}
                        empEndDate={placement?.end_date}
                      />
                    )
                  },
                },
                {
                  title: 'Gross Amount',
                  field: 'gross-amount',
                  ...tableStyle({}, 'cell-number'),
                  customSort: (a, b) => {
                    const totalPrev =
                      a.salary +
                      (a.computedAllowance || 0) +
                      (a.computedBpjs || 0) +
                      (a.computedTunjangan || 0) -
                      a.computedNonFixedComponent

                    const totalCur =
                      b.salary +
                      (b.computedAllowance || 0) +
                      (b.computedBpjs || 0) +
                      (b.computedTunjangan || 0) -
                      b.computedNonFixedComponent
                    const payrollGross = totalPrev - totalCur
                    const thrGross = a.final_thr - b.final_thr

                    return isPayroll ? payrollGross : thrGross
                  },
                  render: rowData => {
                    const payrollGross =
                      rowData.salary +
                      (rowData.computedAllowance || 0) +
                      (rowData.computedBpjs || 0) +
                      (rowData.computedTunjangan || 0) -
                      rowData.computedNonFixedComponent
                    const thrGross = rowData.final_thr

                    return convertToRupiah(
                      isPayroll ? payrollGross : thrGross,
                      false
                    )
                  },
                },
                {
                  title: 'PPH21',
                  field: 'pph21',
                  ...tableStyle({}, 'cell-number'),
                  customSort: (a, b) => {
                    const totalPrev =
                      (a.computedPph21NonFixedComponent?.value || 0) +
                      (a.computedPph21CompanyDeductions?.value || 0)

                    const totalCur =
                      (b.computedPph21NonFixedComponent?.value || 0) +
                      (b.computedPph21CompanyDeductions?.value || 0)

                    const payrollPph = totalPrev - totalCur
                    const thrPph =
                      getPph(a.non_fixed_components) -
                      getPph(b.non_fixed_components)

                    return isPayroll ? payrollPph : thrPph
                  },
                  render: rowData => {
                    const payrollPph =
                      (rowData.computedPph21NonFixedComponent?.value || 0) +
                      (rowData.computedPph21CompanyDeductions?.value || 0)
                    const thrPph = getPph(rowData.non_fixed_components)

                    return convertToRupiah(
                      isPayroll ? payrollPph : thrPph,
                      false
                    )
                  },
                },
              ]}
            />
          </>
        )}
      </PaperContainer>
    </>
  )
})
