import React from 'react'
import Select from 'react-select'

import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  selectInputStyles,
  TitleName,
  TitleNumber,
  TextHelper,
} from '../../GlobalStyles'
import FieldInformationTooltip from '../../pages/shared-components/tooltip/FieldInformationTooltip'

export default function SelectInputGroup(props) {
  const {
    dataForm,
    setDataForm,
    dataOptions,
    defaultValue,
    errorText,
    fieldLabel,
    fieldNumber,
    fieldName,
    isClearable = true,
    isFullWidth,
    isLoading,
    isRequired = true,
    mapSelectOption,
    optionLableKey,
    optionValueKey,
    placeholder,
    searchText,
    setSearchText,
    showErrorHelper = () => false,
    tooltipMesssage,
    value,
    ...restProps
  } = props

  let options = dataOptions

  if (mapSelectOption || (optionLableKey && optionValueKey)) {
    options = dataOptions?.map(opt => {
      if (mapSelectOption) return mapSelectOption(opt)
      else if (optionLableKey && optionValueKey) {
        return {
          label: opt[optionLableKey],
          value: opt[optionValueKey],
        }
      }
    })
  }

  const styleFullWidth = isFullWidth ? {style: {width: '100%'}} : ''
  const formValue = value ? {value: value} : {defaultValue: defaultValue}

  return (
    <FormChildContainer {...styleFullWidth}>
      <FormChildTitle>
        <TitleNumber>{fieldNumber}.</TitleNumber>
        <TitleName>{fieldLabel}</TitleName>
        {tooltipMesssage && <FieldInformationTooltip title={tooltipMesssage} />}
      </FormChildTitle>

      <FormChildInput>
        <Select
          id={`select-${fieldNumber}-${fieldName}`}
          {...formValue}
          options={options}
          placeholder={placeholder}
          isClearable={isClearable}
          isLoading={isLoading}
          styles={selectInputStyles}
          onInputChange={newSearchText =>
            setSearchText &&
            setSearchText({
              ...searchText,
              search: newSearchText,
            })
          }
          onChange={opt => {
            setDataForm({
              ...dataForm,
              [fieldName]: opt,
            })
          }}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: '#bfbfbf',
            },
          })}
          {...restProps}
        />

        {showErrorHelper() && (
          <TextHelper style={{margin: 0}}>
            {isRequired && 'This field is required'}
            {errorText && errorText}
          </TextHelper>
        )}
      </FormChildInput>
    </FormChildContainer>
  )
}
