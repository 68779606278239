import React from 'react'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'
import ProfileDashboard from './ProfileDashboard'
import EditIntroProfile from '../../company/profile/profile-component/EditIntroProfile'

const renderComponent = (activeComponent, state) => {
  switch (activeComponent) {
    case 'group-profile':
      return <ProfileDashboard feature="holding-profile" />
    case 'edit-intro-group':
      return (
        <EditIntroProfile
          feature="holding-profile"
          dataEditIntro={state.dataEditIntro}
        />
      )

    default:
      return <ProfileDashboard feature="holding-profile" />
  }
}

const ProfilePageComponent = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  return (
    <div>
      <Card icon="location_city" title="Profile" hidden={'hidden'}>
        <PageContainer>{renderComponent(active, state)}</PageContainer>
      </Card>
    </div>
  )
}

export default ProfilePageComponent
