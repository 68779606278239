import React, {useState} from 'react'
import {useMutation, useQuery, useApolloClient} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import Axios from 'axios'
import moment from 'moment'

import {
  makeStyles,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import {AttachmentItemHover} from '../../../../shared-components/attachment'
import {FlexColumnStart} from '../../../component/modalStyles'
import LoadingSurvey from '../../../../survey/multirater-nps/survei-center/LoadingSurvey'

import Destination from './Destination'
import TotalBudget from './TotalBudget'

import {capitalize} from '../../../../../utils/helpers'
import {
  LANDING_APP_URL,
  POSITION_NAME,
  TOKEN,
  USER_ID,
  USER_NAME,
} from '../../../../../utils/globals'
import {
  GET_ACTIVITY_BY_ID,
  GET_CONFIRMED_TRIP_PAYMENTS,
  GET_CURRENCY_EXCHANGE,
} from '../../../../../graphql/queries'
import {
  REJECT_REQUEST_TRIP,
  UPDATE_TRIP_STATUS,
  CONFIRM_PAYMENT,
  CANCEL_BUSINESS_TRIP,
} from '../../../../../graphql/mutations'

import AddEditPopupFreeText from '../../../../shared-components/popup/AddEditPopupFreeText'
import {
  COLOR_STATUS_TRIP,
  getStatusApproval,
  getUpdater,
  handleTripDetailSort,
  namePending,
  STATUS_TRIP,
} from '../../businessTripHelper'
import FormDetail from '../../../component/FormDetail'

const useStyles = makeStyles(theme => ({
  textArea: {
    width: `calc(100% - ${24 * 2}px)`,
    margin: `0 24px 16px`,
  },

  buttonOutlinedRed: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  buttonContainedRed: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,

    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  actions: {
    padding: `16px 24px 24px`,
  },
  actionsCenter: {
    justifyContent: 'center',
    padding: `16px 24px 24px`,
  },
}))

export default function ModalDetail(props) {
  const {open, type, id, handleClose, onRefetchList} = props

  const {enqueueSnackbar} = useSnackbar()
  const [reason, setReason] = useState('')
  const [button, setButton] = useState(null)
  const [openConfirm, setConfirm] = useState({
    open: false,
    body: null,
    header: null,
  })
  const classes = useStyles()

  const client = useApolloClient()

  const [approveTrip] = useMutation(UPDATE_TRIP_STATUS)
  const [confirmPayment] = useMutation(CONFIRM_PAYMENT)
  const [rejectTrip] = useMutation(REJECT_REQUEST_TRIP)
  const [cancelTrip] = useMutation(CANCEL_BUSINESS_TRIP)

  const {data, error} = useQuery(GET_ACTIVITY_BY_ID, {
    wlb_skipPatch: true,
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id,
    },
    onCompleted: handleTripDetailSort,
  })

  const {data: confirmedData, error: confirmedError} = useQuery(
    GET_CONFIRMED_TRIP_PAYMENTS,
    {
      wlb_skipPatch: true,
      skip: !id,
      fetchPolicy: 'cache-and-network',
      variables: {
        id: id,
      },
    }
  )

  const isChecked = React.useMemo(() => {
    if (!confirmedData) {
      return false
    }

    const budgets = confirmedData.people_work_business_trip_destination_budgets
    return budgets.every(budget => budget.status === 'paid')
  }, [confirmedData])

  const details = data?.people_work_business_trips_by_pk

  const nameRejected = () => {
    let rejector = {}
    const {first_reject, second_reject, third_reject} =
      details?.business_trip_fields || {}

    if (first_reject) {
      rejector = first_reject?.user
    } else if (second_reject) {
      rejector = second_reject?.user
    } else if (third_reject) {
      rejector = third_reject?.user
    }

    return `${rejector?.name} ${rejector?.title}`
  }

  const handleSubmit = () => {
    if (openConfirm.type === 'approve') {
      let business_trip_fields_approve = null
      let business_trip_fields_reject = null

      const user = {
        type: 'admin',
        user: {
          id: USER_ID,
          name: USER_NAME,
          title: POSITION_NAME,
        },
      }
      if (details.status === 'pending') {
        business_trip_fields_approve = {
          first_approve: user,
        }
        business_trip_fields_reject = {
          reason_reject: reason,
          first_reject: user,
        }
      } else if (details.status === 'confirm1') {
        business_trip_fields_approve = {
          second_approve: user,
        }
        business_trip_fields_reject = {
          reason_reject: reason,
          second_reject: user,
        }
      } else if (details.status === 'confirm2') {
        business_trip_fields_approve = {
          third_approve: user,
        }
        business_trip_fields_reject = {
          reason_reject: reason,
          third_reject: user,
        }
      }

      if (button === 'rejection') {
        // NOTE(intrnl): we're basing our approved date based on the date taken
        // from the "latest" currency exchange, the problem is that the exchange
        // can be a day or two late either because of cache, or because the user
        // left the app running overnight.f
        const cached = client.query({
          query: GET_CURRENCY_EXCHANGE,
          fetchPolicy: 'cache-only',
          variables: {
            currency: details.currency,
            date: '',
          },
        })

        cached.then(res => {
          // NOTE(intrnl): if we can't get the date, then we'll just give up and
          // use the current timestamp instead.
          const date = res.data.getCurrencyExchange?.date || 'now()'

          const promise = rejectTrip({
            variables: {
              id: details.id,
              fields: business_trip_fields_reject,
              approvedDate: date,
            },
          })

          promise.then(
            () => {
              enqueueSnackbar('Trip rejected', {variant: 'success'})

              if (onRefetchList) {
                onRefetchList()
              }

              setButton(null)
              setReason('')

              handleClose()
            },
            () => {
              enqueueSnackbar('Failed to reject trip', {variant: 'error'})
            }
          )
        })
      } else {
        const nextStatus = getStatusApproval(details)

        const promise = approveTrip({
          variables: {
            id,
            status: nextStatus,
            business_trip_fields: business_trip_fields_approve,
          },
        })

        promise.then(
          () => {
            enqueueSnackbar('Trip approved', {variant: 'success'})

            if (onRefetchList) {
              onRefetchList()
            }

            setButton(null)
            setReason('')

            handleClose()
          },
          () => {
            enqueueSnackbar('Failed to approve trip', {variant: 'error'})
          }
        )
      }
    } else if (openConfirm.type === 'confirm') {
      const cached = client.query({
        query: GET_CURRENCY_EXCHANGE,
        fetchPolicy: 'cache-only',
        variables: {
          currency: details.currency,
          date: '',
        },
      })

      cached.then(res => {
        const date = res.data.getCurrencyExchange?.date || 'now()'

        const promise = confirmPayment({
          variables: {
            id: details.id,
            approvedBy: USER_ID,
            approvedDate: date,
          },
        })

        promise.then(
          () => {
            enqueueSnackbar('Trip confirmed', {variant: 'success'})

            handleClose()
          },
          () => {
            enqueueSnackbar('Failed to confirm trip', {variant: 'error'})
          }
        )
      })
    } else if (openConfirm.type === 'cancel') {
      const cached = client.query({
        query: GET_CURRENCY_EXCHANGE,
        fetchPolicy: 'cache-only',
        variables: {
          currency: details.currency,
          date: '',
        },
      })

      cached.then(res => {
        // NOTE(intrnl): if we can't get the date, then we'll just give up and
        // use the current timestamp instead.
        const date = res.data.getCurrencyExchange?.date || 'now()'

        const promise = cancelTrip({
          variables: {
            id: details.id,
            cancelledBy: USER_ID,
            approvedDate: date,
          },
        })

        promise.then(
          () => {
            enqueueSnackbar(`Trip cancelled`, {variant: 'success'})
          },
          () => {
            enqueueSnackbar(`Failed to cancel trip`, {variant: 'error'})
          }
        )
      })
    }
  }

  const handleDownloadTrip = data => {
    Axios.get(`${LANDING_APP_URL}/screen/d?s=trip_submission&id=${data.id}`, {
      headers: {
        Authorization: 'Bearer ' + TOKEN,
      },
    })
      .then(res => {
        const printWindow = window.open('', '', 'fullscreen=yes')
        printWindow.document.write(res.data)
        printWindow.document.title = `${data?.id_display}`
        printWindow.document.close()
        setTimeout(() => {
          printWindow.print()
        }, 3000)
      })
      .catch(() => {
        enqueueSnackbar(
          'Download trip submission error, please try again later',
          {variant: 'error'}
        )
      })
  }

  const buttonSubmission = variant => {
    return (
      <Button
        variant={variant}
        color="primary"
        onClick={() => handleDownloadTrip(details)}
      >
        Download Trip Submission
      </Button>
    )
  }

  const helperStatus = () => {
    let color = ''
    let name = '-'
    switch (details?.status) {
      case 'pending':
      case 'confirm1':
        color = '#f87433'
        break
      case 'rejected':
        color = '#F83202'
        name = nameRejected()
        break
      case 'cancelled':
        color = '#a9a8a8'
        name = getUpdater(details?.globalUserByCancelledBy)
        break
      case 'approved':
        color = '#4caf50'
        name = getUpdater(details?.globalUserByApprovedBy)
        break
      case 'processing':
        color = '#a20a31'
        name = 'Trip Budget'
        break
      default:
        color = '#000'
    }
    return {color, name}
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogTitle disableTypography className={classes.header}>
        <Typography style={{fontWeight: 600}}>
          Business Trip Submission Detail
        </Typography>

        <IconButton onClick={handleClose} color="inherit" edge="end">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {error || confirmedError ? (
        <div>Something went wrong</div>
      ) : !data || data.people_work_business_trips_by_pk?.id !== id ? (
        <LoadingSurvey />
      ) : (
        <>
          <DialogContent>
            <Box mx={-3} mt={-1} mb={1} px={3} pt={1} pb={2} bgcolor="#eff2f4">
              <FlexColumnStart>
                <FormDetail label={'Name'}>
                  {details.globalUserByAddedBy?.name}
                </FormDetail>
                <FormDetail label={'Employee ID'}>
                  {details.peopleWorkPlacementByAddedByWorkId?.regno}
                </FormDetail>
                <FormDetail label={'Position'}>
                  {
                    details.peopleWorkPlacementByAddedByWorkId
                      ?.company_job_profile?.title
                  }
                </FormDetail>
                <FormDetail label={'Submission Date'}>
                  {moment(details.date_added).format('MMMM D, YYYY')}
                </FormDetail>
                <FormDetail label={'Submission Status'} display={'flex'}>
                  {['pending', 'confirm1', 'confirm2'].includes(
                    details.status
                  ) ? (
                    <div>
                      <StatusSubmission status="Waiting" dataDetail={details} />
                      for{' '}
                      <span style={{color: '#039be5'}}>
                        {namePending(details)}
                      </span>{' '}
                      approval
                    </div>
                  ) : (
                    <div>
                      <StatusSubmission dataDetail={details} />
                      by
                      <span
                        style={{
                          paddingLeft: '5px',
                          color:
                            details.status === 'processing'
                              ? '#000'
                              : '#039be5',
                        }}
                      >
                        {helperStatus().name}
                      </span>
                    </div>
                  )}
                </FormDetail>
              </FlexColumnStart>
            </Box>

            <FlexColumnStart>
              <FormDetail label={'Request ID'}>{details.id_display}</FormDetail>
              <FormDetail label={'Policy Name'}>
                {details.business_trip_policy.name}
              </FormDetail>
              <FormDetail label={'Trip Date'}>
                {moment(details.date_start).format('MMMM Do')} -{' '}
                {moment(details.date_end).format('MMMM Do, YYYY')}
              </FormDetail>
              <FormDetail label={'Destination'} display={'block'}>
                <FlexColumnStart>
                  {details.destination.length > 0
                    ? details.destination.map((row, idx) => {
                        const text = `${row.globalDestinationByFrom.name} - ${row.globalDestinationByTo.name}`
                        const As = idx === 0 ? 'div' : 'p'

                        return <As key={idx}>{text}</As>
                      })
                    : '-'}
                </FlexColumnStart>
              </FormDetail>
              <FormDetail label={'Participants'} display={'block'}>
                <FlexColumnStart>
                  <p style={{margin: 0, color: '#039be5'}}>
                    {details.participant_name.name}
                  </p>
                  <p style={{margin: 0}}>
                    {details.participant_position?.regno}
                  </p>
                  <p style={{margin: 0, color: 'rgba(0, 0, 0, 0.54)'}}>
                    {details.participant_position?.company_job_profile.title}
                  </p>
                </FlexColumnStart>
              </FormDetail>
              <FormDetail label={'Description'} display={'block'}>
                {details.description}
              </FormDetail>
              <FormDetail label={'Attachment'}>
                {details.attachment.length > 0
                  ? details.attachment.map((row, key) => (
                      <AttachmentItemHover
                        key={key}
                        iconAction={
                          <VisibilityOutlinedIcon
                            onClick={() => window.open(`${row.url}`)}
                            className="icon"
                          />
                        }
                        fileName={row.name}
                        url={`${row.url}`}
                      />
                    ))
                  : '-'}
              </FormDetail>

              {details.status === 'rejected' && (
                <FormDetail label={'Rejected Statement'} display={'block'}>
                  {details.business_trip_fields.reason_reject}
                </FormDetail>
              )}
            </FlexColumnStart>

            {details.destination.length > 0 && (
              <FormDetail label={'Component'} color={'#000000'} />
            )}

            {details.destination.map((row, index) => (
              <Destination
                statusTrip={details.status}
                key={index}
                data={row}
                count={details.destination.length}
                idUser={details.participant_name?.id}
                currencyFrom={details.business_trip_policy.currency_from}
                currencyTo={details.currency}
                approvedDate={details.approved_date}
              />
            ))}

            {details.destination.length > 1 && (
              <TotalBudget
                data={details.destination}
                currencyFrom={details.business_trip_policy.currency_from}
                currencyTo={details.currency}
                approvedDate={details.approved_date}
              />
            )}
          </DialogContent>

          {type === 'request' && (
            <>
              <DialogActions className={classes.actionsCenter}>
                <Button
                  variant={button === 'rejection' ? 'contained' : 'outlined'}
                  className={
                    button === 'rejection'
                      ? classes.buttonContainedRed
                      : classes.buttonOutlinedRed
                  }
                  onClick={() => setButton('rejection')}
                  disableElevation
                >
                  Reject
                </Button>

                <Button
                  variant={button === 'approval' ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => setButton('approval')}
                  disableElevation
                >
                  Approve
                </Button>
              </DialogActions>

              {button === 'rejection' && (
                <TextField
                  multiline
                  minRows={4}
                  className={classes.textArea}
                  value={reason}
                  onChange={event => setReason(event.target.value)}
                  variant="outlined"
                  margin="dense"
                />
              )}
            </>
          )}

          {type === 'request' && button && (
            <DialogActions className={classes.actions} style={{paddingTop: 0}}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setConfirm({
                    open: true,
                    body: `Are you sure want to submit this ${button}`,
                    header: `Submit ${capitalize(button)}`,
                    type: 'approve',
                  })
                }}
              >
                Submit
              </Button>
            </DialogActions>
          )}

          {type !== 'request' && details.status !== 'processing' && (
            <DialogActions className={classes.actionsCenter}>
              {buttonSubmission('contained')}
            </DialogActions>
          )}

          {details.status === 'processing' && (
            <DialogActions className={classes.actions}>
              {buttonSubmission('outlined')}

              <div style={{flexGrow: 1}} />

              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setConfirm({
                    open: true,
                    header: `Cancel Trip?`,
                    body: `Are you sure you want to cancel this trip?`,
                    type: 'cancel',
                  })
                }}
              >
                Cancel Trip
              </Button>

              <Button
                disabled={!isChecked}
                onClick={() => {
                  setConfirm({
                    open: true,
                    header: `Confirm Payment?`,
                    body: `Are you sure want to confirm payment for this trip`,
                    type: 'confirm',
                  })
                }}
                variant="contained"
                color="primary"
              >
                Confirm Payment
              </Button>
            </DialogActions>
          )}
        </>
      )}

      <AddEditPopupFreeText
        open={openConfirm.open}
        handleClose={() =>
          setConfirm({
            ...openConfirm,
            open: false,
          })
        }
        button={'Confirm'}
        body={openConfirm.body}
        header={openConfirm.header}
        feature={''}
        mutation={handleSubmit}
      />
    </Dialog>
  )
}

const StatusSubmission = ({status, dataDetail}) => {
  return (
    <span
      style={{
        color: COLOR_STATUS_TRIP[dataDetail?.status] || '#000',
      }}
    >
      {status || STATUS_TRIP[dataDetail?.status] || dataDetail?.status}{' '}
    </span>
  )
}
