import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {SnackbarProvider} from 'notistack'
import * as Sentry from '@sentry/browser'
import App from './App'
import {SENTRY_ENV, SENTRY_URL, userData, SSO_URL} from './utils/globals'
import './assets/css/customCKEditor.css'

import {getCookie} from './utils/helpers'

Sentry.init({
  dsn: SENTRY_URL,
  environment: SENTRY_ENV,
})

Sentry.configureScope(scope => scope.setExtra('userData', userData))

if (process.env.NODE_ENV !== 'development' && !getCookie('userData')) {
  window.location = SSO_URL
} else {
  ReactDOM.render(
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>{' '}
    </BrowserRouter>,
    document.getElementById('root')
  )
}
