import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormHelperText,
  Icon,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {
  Flex,
  FlexBetween,
  FlexCenter,
  GreyTypography,
  ModalHeader,
} from '../../../../../../GlobalStyles'
import {
  ADD_MULTIPLE_RESPONDENT,
  // ADD_RESPONDENT,
  DELETE_MULTIPLE_RESPONDENT,
} from '../../../../../../graphql/mutations/survey/multirater-nps/survey-center/surveyCenter.mutation'
import {
  GET_DETAIL_LIST_RESPONDENT,
  GET_LIST_EMPLOYEE_IN_COMPANY,
  // GET_RESPONDENT_GROUP,
  GET_LIST_ORGANIZATION,
  GET_LIST_RESPONDENTS_STEP_FOUR,
} from '../../../../../../graphql/queries/survey/multirater-nps/survey-center/getListSurveyCenter.query'
import NoDataListFree from '../../../../../shared-components/NoDataListFreeComponent'
import LoadingSurvey from '../../LoadingSurvey'
import {
  // TextDropdown2,
  AvatarMenu,
  FormControlWrapper,
  IconButtonStyled,
  // RespondentsTitle,
  Information,
  // AvatarProfile,
  NameRespondents,
  PositionRespondents,
  Row2,
  RowSearch,
  TextDropdown,
} from '../../SurveyCenterStyled'
// import Loading from '../../../../../../components/loading/LoadingComponent'
// import {COMPANY_ID} from '../../../../../../utils/globals'
import ReactSelectNew from './ReactSelectNew'

const useStyles = makeStyles(() => ({
  content: {alignItems: 'center'},
  avatar: {marginLeft: 8},
  avatarWrapper: {display: 'flex', marginBottom: 24, marginTop: 12},
  accordDetail: {display: 'block', marginLeft: '7.5rem'},
  ibCreate: {border: '1px solid', padding: 9, marginRight: 10},
  avaGroup: {display: 'flex'},
  selectedCheckboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px 15px',
  },
  plusAvatar: {
    backgroundColor: '#a9a8a8',
    borderRadius: '50%',
    // padding: 10,
    width: 38,
    height: 38,
    cursor: 'pointer',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
  },
}))

const StepFour = props => {
  const classes = useStyles()
  // const {staggedRespondent, setStaggedRespondent} = props
  const {dataContext, isError, setRespondentLength} = props
  const {enqueueSnackbar} = useSnackbar()
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState([])
  const [dataSelected, setDataSelected] = useState([])
  // const [listEmployee, setlistEmployee] = useState([])
  const [listRespondent, setListRespondent] = useState([])
  const [page, setPage] = useState(0)
  const [organization, setOrganization] = useState(' ')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  // const [allGroup, setAllGroup] = useState('-')
  const [expanded, setExpanded] = useState(false)
  const [morePeople, setMorePeople] = useState(null)
  const [searchMore, setSearchMore] = useState('')

  // eslint-disable-next-line
  const handleExpand = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const surveyId =
    dataContext.dataSurveyCenter && dataContext.dataSurveyCenter.id

  const {data, loading, refetch} = useQuery(GET_LIST_RESPONDENTS_STEP_FOUR, {
    variables: {
      survey: surveyId,
    },
    fetchPolicy: 'network-only',
  })

  const {data: employees, loading: loadingEmps} = useQuery(
    GET_LIST_EMPLOYEE_IN_COMPANY,
    {
      variables: {
        search: `%${search}%`,
        offset: null,
        limit: 10,
        organization: organization === ' ' ? null : [parseInt(organization)],
        jobProfile: null,
      },
    }
  )

  const {data: detail, loading: loadingDetail} = useQuery(
    GET_DETAIL_LIST_RESPONDENT,
    {
      skip: !expanded,
      variables: {
        user: expanded,
      },
    }
  )

  const {data: listOrganization} = useQuery(GET_LIST_ORGANIZATION)

  const [mutationAddRespondent] = useMutation(ADD_MULTIPLE_RESPONDENT)

  // const {data: listGroup} = useQuery(GET_RESPONDENT_GROUP, {
  //   variables: {
  //     company: COMPANY_ID,
  //   },
  // })

  // const [addRespondent] = useMutation(ADD_RESPONDENT)
  const [deleteSelectedRespondent] = useMutation(DELETE_MULTIPLE_RESPONDENT)
  // const [updateSelectedRespondent] = useMutation(UPDATE_RESPONDENT)
  // const [updateAllRespondent] = useMutation(UPDATE_ALL_RESPONDENT)

  // useEffect(() => {
  //   if (employees && employees.people_work_placements.length !== 0) {
  //     if (listEmployee.length === 0) {
  //       const tempEmployee = []
  //       employees.people_work_placements.forEach(val => {
  //         tempEmployee.push({
  //           label: val.global_user.id,
  //           regno: val.regno,
  //           value: val.global_user.name,
  //           avatar: val.global_user.avatar,
  //           position:
  //             val && val.company_job_profile && val.company_job_profile.title,
  //           rank:
  //             val &&
  //             val.company_employee_rank &&
  //             val.company_employee_rank.name,
  //         })
  //       })
  //       setlistEmployee([...tempEmployee])
  //     }
  //   }

  /* if (data && data.multirater_respondents.length !== 0) {
      const tempRespondent = []
      if (listRespondent.length === 0) {
        data.multirater_respondents.map(val => {
          tempRespondent.push({
            respondentId: val.id,
            id: val.global_user.id,
            avatar: val.global_user.avatar,
            name: val.global_user.name,
            group:
              val.multirater_respondent_group &&
              val.multirater_respondent_group.id,
            positionName:
              val.global_user.people_work_placements[0].company_job_profile &&
              val.global_user.people_work_placements[0].company_job_profile
                .company_employee_position.name,
            rankName:
              val.global_user.people_work_placements[0].company_job_profile &&
              val.global_user.people_work_placements[0].company_job_profile
                .company_employee_rank.name,
          })
        })
        setListRespondent([...tempRespondent])
      }
    } */
  // }, [employees, listEmployee, listRespondent, data, organization])

  useEffect(() => {
    if (data) {
      setListRespondent(data.multirater_respondents)
      setRespondentLength(data.multirater_respondents.length)
    }
    // return () => {
    //   setStaggedRespondent([])
    // }
  }, [data])

  const empMenu = option => {
    return (
      <>
        <Row2>
          <AvatarMenu src={option.avatar} alt={option.name} />
          <Information>
            <NameRespondents>{option.value}</NameRespondents>
            <PositionRespondents>{option.position}</PositionRespondents>
            {/* <PositionRespondents>{option.rank}</PositionRespondents> */}
          </Information>
          <IconButtonStyled style={{width: 40}} color="secondary">
            <AddIcon style={{color: '#fff', fontSize: 16}} />
          </IconButtonStyled>
        </Row2>
      </>
    )
  }

  const handleClick = (event, id, name) => {
    event.preventDefault()
    event.stopPropagation()
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    let nameSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
      nameSelected = nameSelected.concat(dataSelected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
      nameSelected = nameSelected.concat(dataSelected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
      nameSelected = nameSelected.concat(dataSelected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
      nameSelected = nameSelected.concat(
        dataSelected.slice(0, selectedIndex),
        dataSelected.slice(selectedIndex + 1)
      )
    }

    const idSurvey = parseInt(surveyId)

    const result = nameSelected.map(res => ({
      id: res.id,
      survey: idSurvey,
      group: res.group,
    }))

    setSelected(newSelected)
    setDataSelected(result)
  }

  const handleChange = val => {
    // staggedRespondent.push({
    //   user: val.label,
    //   survey: parseInt(surveyId),
    //   group: null,
    // })
    // setlistEmployee([])
    // listRespondent.push({
    //   id: val.label,
    //   avatar: val.avatar,
    //   name: val.value,
    //   group: null,
    //   positionName: val.position,
    //   rankName: val.rank,
    // })
    // setSearch('')
    mutationAddRespondent({
      variables: {
        object: {
          user: val.label,
          survey: parseInt(surveyId),
          group: null,
        },
      },
    })
      .then(() => {
        // setlistEmployee([])
        // setListRespondent([])
        refetch()
      })
      .catch(() =>
        enqueueSnackbar('Employee already assigned', {
          variant: 'error',
          autoHideDuration: 2500,
        })
      )
  }

  // const handleSearch = e => {
  //   setlistEmployee([])
  //   setSearch(e.target.value)
  // }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds =
        listRespondent && listRespondent.map(n => n.global_user.id)
      setSelected(newSelecteds)

      const AllData = listRespondent && listRespondent.map(n => n)
      setDataSelected(AllData)
      return
    }
    setSelected([])
    setDataSelected([])
  }

  const handleRemoveSelected = () => {
    // const filtered = listRespondent.filter(function(value) {
    //   return selected.indexOf(value.id) === -1
    // })

    deleteSelectedRespondent({
      variables: {
        user: selected,
        survey: surveyId,
      },
    })
      .then(() => {
        enqueueSnackbar('Respondent deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })

    // if (listRespondent.length === selected.length) {
    //   setTimeout(() => {
    //     setListRespondent([])
    //   }, 2000)
    // } else {
    //   setListRespondent([...filtered])
    //   setStaggedRespondent([...filtered])
    // }
    setSelected([])
    setDataSelected([])
  }

  const isSelected = id => selected.indexOf(id) !== -1

  // const setGroup = (e, id, prev, respondentId) => {
  //   if (prev === null) {
  //     addRespondent({
  //       variables: {
  //         user: id,
  //         survey: surveyId,
  //         group: e.target.value,
  //       },
  //     })
  //       .then(() => {
  //         enqueueSnackbar(`Respondent added `, {
  //           variant: 'success',
  //           autoHideDuration: 3000,
  //         })
  //       })
  //       .catch(() => {
  //         enqueueSnackbar(`Add respondent error, please try again`, {
  //           variant: 'error',
  //           autoHideDuration: 3000,
  //         })
  //       })
  //       .then(() => {
  //         setTimeout(() => {
  //           setListRespondent([])
  //         }, 1000)
  //       })
  //   } else {
  //     updateSelectedRespondent({
  //       variables: {
  //         id: respondentId,
  //         survey: surveyId,
  //         group: e.target.value,
  //         user: id,
  //       },
  //     })
  //       .then(() => {
  //         enqueueSnackbar(`Respondent updated `, {
  //           variant: 'success',
  //           autoHideDuration: 3000,
  //         })
  //       })
  //       .catch(() => {
  //         enqueueSnackbar(`Updated respondent error, please try again`, {
  //           variant: 'error',
  //           autoHideDuration: 3000,
  //         })
  //       })
  //       .then(() => {
  //         setTimeout(() => {
  //           setListRespondent([])
  //         }, 1000)
  //       })
  //   }
  // }

  // const handleChangeAllGroup = e => {
  //   setAllGroup(e.target.value)
  //   const idSurvey = parseInt(surveyId)

  //   const result = dataSelected.map(res => ({
  //     survey: idSurvey,
  //     user: res.id,
  //     group: e.target.value,
  //   }))

  //   updateAllRespondent({
  //     variables: {
  //       user: selected,
  //       survey: idSurvey,
  //       object: result,
  //     },
  //   })
  //     .then(() => {
  //       enqueueSnackbar(`Multiple respondent updated `, {
  //         variant: 'success',
  //         autoHideDuration: 3000,
  //       })
  //     })
  //     .catch(() => {
  //       enqueueSnackbar(`Multiple update respondent error, please try again`, {
  //         variant: 'error',
  //         autoHideDuration: 3000,
  //       })
  //     })
  //     .then(() => {
  //       setTimeout(() => {
  //         setAllGroup('-')
  //         setSelected([])
  //         setListRespondent([])
  //       }, 1000)
  //     })
  // }

  const handleChangeOrganization = e => {
    // setlistEmployee([])
    setOrganization(e.target.value)
  }

  if (loading) {
    return <LoadingSurvey />
  }

  const handleRemoveOne = id => e => {
    e.stopPropagation()
    deleteSelectedRespondent({
      variables: {
        user: [id],
        survey: surveyId,
      },
    })
      .then(() => {
        enqueueSnackbar('Respondent deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const showAll = res => {
    setMorePeople(res)
  }

  const handleCloseMore = () => {
    setMorePeople(null)
    setSearchMore('')
  }

  return (
    <>
      <div style={{paddingTop: '1.6rem'}}>
        <RowSearch>
          <FormControlWrapper
            variant="outlined"
            // onKeyUp={e => handleSearch(e)}
            style={{marginLeft: 0}}
          >
            <ReactSelectNew
              isLoading={loadingEmps}
              indicatorSearch={true}
              value={''}
              onChange={val => handleChange(val)}
              getOptionLabel={empMenu}
              options={
                employees &&
                employees.people_work_placements.map(val => ({
                  label: val.global_user.id,
                  regno: val.regno,
                  value: val.global_user.name,
                  avatar: val.global_user.avatar,
                  position:
                    val &&
                    val.company_job_profile &&
                    val.company_job_profile.title,
                  // rank:
                  //   val &&
                  //   val.company_employee_rank &&
                  //   val.company_employee_rank.name,
                }))
              }
              placeholder="Cari"
              onInputChange={d => setSearch(d)}
            />
          </FormControlWrapper>
          <TextDropdown
            onChange={handleChangeOrganization}
            value={organization}
            variant="outlined"
            select
            size="small"
          >
            <MenuItem value=" ">Tetapkan Dipilih</MenuItem>
            {listOrganization &&
              listOrganization.company_organizations.map((res, i) => (
                <MenuItem value={res.id} key={i}>
                  {res.name}
                </MenuItem>
              ))}
          </TextDropdown>
        </RowSearch>

        {/* LIST */}

        <div className={classes.selectedCheckboxContainer}>
          <FlexCenter style={{width: 'auto'}}>
            <Checkbox
              checked={
                listRespondent.length > 0 &&
                selected.length === listRespondent.length
              }
              color="primary"
              indeterminate={
                selected.length > 0 && selected.length < listRespondent.length
              }
              onChange={e => handleSelectAllClick(e)}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
            <GreyTypography className={classes.avatar}>Asesi</GreyTypography>
            {isError && (
              <FormHelperText
                className={classes.avatar}
                style={{color: '#ef4d5e'}}
              >
                Silakan tambahkan responden
              </FormHelperText>
            )}
          </FlexCenter>
          {/* <TextDropdown
              style={{marginLeft: 20}}
              value={allGroup}
              onChange={handleChangeAllGroup}
              variant="outlined"
              select
            >
              <MenuItem value="-">Select Group</MenuItem>
              {listGroup &&
                listGroup.multirater_respondent_groups.map((val, index) => (
                  <MenuItem value={val.id} key={index}>
                    {val.name}
                  </MenuItem>
                ))}
            </TextDropdown> */}
          {selected.length > 0 && (
            <Button
              onClick={() => handleRemoveSelected()}
              style={{marginLeft: '26px'}}
            >
              <Typography variant="subtitle1" style={{color: '#EF4D5E'}}>
                Hapus yang Dipilih
              </Typography>
            </Button>
          )}
        </div>
        <Divider />

        <div style={{minHeight: '30vh'}}>
          {data && listRespondent.length === 0 ? (
            <NoDataListFree
              message1="Maaf, Tidak Ada List"
              image={require('../../../../../../assets/images/nodatalist.png')}
            />
          ) : (
            listRespondent
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.global_user.id)
                const labelId = `enhanced-table-checkbox-${index}`
                const raterComponent = detail
                  ? [
                      {
                        title: 'Second layer supervisor',
                        data: detail.second_supervisor,
                      },
                      {
                        title: 'Supervisor',
                        data: detail.supervisor,
                      },
                      {
                        title: 'Peer',
                        data: detail.peer,
                      },
                      {
                        title: 'Subordinates',
                        data: detail.subordinate,
                      },
                      {
                        title: 'Second layer subordinates',
                        data: detail.second_subordinate,
                      },
                    ]
                  : []
                const plc =
                  row.global_user && row.global_user.people_work_placements[0]
                return (
                  <Accordion
                    elevation={0}
                    key={index}
                    // expanded={expanded === row.global_user?.id}
                    expanded={expanded === row.global_user.id}
                    // onChange={handleExpand(row.global_user?.id)}
                    onChange={handleExpand(row.global_user.id)}
                  >
                    <AccordionSummary
                      classes={{
                        content: classes.content,
                      }}
                    >
                      <Checkbox
                        onClick={event =>
                          handleClick(event, row.global_user.id, row)
                        }
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                        color="primary"
                      />
                      <Avatar
                        // src={row.global_user?.avatar}
                        src={row.global_user && row.global_user.avatar}
                        className={classes.avatar}
                      />
                      <FlexBetween>
                        <Information>
                          <NameRespondents>
                            {/* {row.global_user?.name} */}
                            {row.global_user && row.global_user.name}
                          </NameRespondents>
                          <PositionRespondents>
                            {plc &&
                              plc.company_job_profile &&
                              plc.company_job_profile.title}
                          </PositionRespondents>
                          {/* <PositionRespondents>
                            {row.rankName}
                          </PositionRespondents> */}
                        </Information>
                        <IconButton
                          onClick={handleRemoveOne(row.global_user.id)}
                        >
                          <Icon color="secondary">delete</Icon>
                        </IconButton>
                      </FlexBetween>
                    </AccordionSummary>

                    {expanded === row.global_user.id && !loadingDetail ? (
                      raterComponent.map((res, i) => (
                        <AccordionDetails
                          className={classes.accordDetail}
                          key={i}
                        >
                          <PositionRespondents>{res.title}</PositionRespondents>
                          <div className={classes.avatarWrapper}>
                            {/* <IconButton
                              aria-label="delete"
                              color="secondary"
                              className={classes.ibCreate}
                              onClick={() =>
                                setOpenRater({open: true, title: res.title})
                              }
                            >
                              <Icon>create</Icon>
                            </IconButton> */}

                            {res.data && res.data.length === 0 ? (
                              '-'
                            ) : (
                              <div className={classes.avaGroup}>
                                {res.data.slice(0, 5).map((ava, i) => (
                                  <Tooltip title={ava.global_user.name} key={i}>
                                    <Avatar
                                      key={i}
                                      alt={ava.global_user.name}
                                      src={ava.global_user.avatar}
                                      style={{
                                        marginRight: 10,
                                      }}
                                    />
                                  </Tooltip>
                                ))}
                                {res.data.length > 5 && (
                                  <Tooltip title="Show All" key={i}>
                                    <div
                                      className={classes.plusAvatar}
                                      onClick={() =>
                                        showAll({
                                          ...res,
                                          name: row.global_user.name,
                                        })
                                      }
                                    >
                                      +{res.data.length - 5}
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          </div>
                        </AccordionDetails>
                      ))
                    ) : expanded === row.global_user.id && loadingDetail ? (
                      <LoadingSurvey />
                    ) : null}
                  </Accordion>
                )
              })
          )}
        </div>
        <Divider></Divider>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listRespondent && listRespondent.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Baris Per halaman"
          backIconButtonText="halaman sebelumnya"
          nextIconButtonText="halaman berikutnya"
          labelDisplayedRows={({from, to, count}) =>
            `${from} - ${to} dari ${count}`
          }
        />
      </div>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={morePeople}
        onClose={handleCloseMore}
      >
        <ModalHeader>
          <Typography variant="body1">
            {morePeople && `${morePeople.name}'s ${morePeople.title}`}
          </Typography>
          <IconButton onClick={handleCloseMore}>
            <CloseIcon style={{color: 'white'}} />
          </IconButton>
        </ModalHeader>
        <div
          style={{
            paddingRight: 16,
            paddingLeft: 16,
            paddingBottom: 6,
          }}
        >
          <TextField
            margin="normal"
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{marginBottom: 16}}
            value={searchMore}
            onChange={e => setSearchMore(e.target.value)}
          />
          {morePeople &&
            morePeople.data
              .filter(
                item =>
                  item.global_user.name
                    .toLowerCase()
                    .indexOf(searchMore.toLowerCase()) >= 0
              )
              .map((res, i) => (
                <Flex key={i} style={{marginBottom: 10}}>
                  <Avatar
                    src={res.global_user.avatar}
                    alt={res.global_user.name}
                    style={{marginRight: 12}}
                  />
                  <div>
                    <Typography variant="body1">
                      {res.global_user.name}
                    </Typography>
                    <GreyTypography variant="body1">
                      {res.company_job_profile && res.company_job_profile.title}
                    </GreyTypography>
                  </div>
                </Flex>
              ))}
        </div>
      </Dialog>
    </>
  )
}

export default withRouter(StepFour)
