import {
  Button,
  Icon,
  IconButton,
  Typography,
  TextField,
  LinearProgress,
  Paper,
  // Checkbox,
  Radio,
  Card,
  Tooltip,
} from '@material-ui/core'
import React, {useState} from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import {REAST_API_URL, TOKEN, UPLOAD_URL} from '../../../../../utils/globals'
import Axios from 'axios'
import classnames from 'classnames'
import helperStyle, {Flex} from '../../../../../GlobalStyles'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import {useSnackbar} from 'notistack'
import {AttachmentItemHover} from '../../../../shared-components/attachment'
// import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

const editorConfiguration = {
  placeholder: 'Tambah Konten',
  toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
}

export default function CreateLesson({
  style,
  refetchCourseById,
  setOpenAddLesson,
  courseId,
  sectionId,
  setSectionId,
  order,
  lesson,
  setLesson,
}) {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [state, setState] = useState({
    lessonType: lesson.lessonType || '',

    videoKey: '',
    videoThumbnail: '',
    videoSummary: lesson && lesson.lessonType === 'video' ? lesson.summary : '',
    articleType:
      lesson && lesson.resources.length === 0 ? 'manual' : 'uploadFile',
    articleContent:
      lesson && lesson.lessonType === 'article' ? lesson.summary : '',
    quizTitle: '',
    quizDescription:
      lesson && lesson.lessonType === 'quiz' ? lesson.summary : '',
    loadingUpload: false,
    loadingUploadThumbnail: false,
    loadingUploadResources: false,
    resources: lesson ? lesson.resources : [],
  })

  const [lessonTitle, setLessonTitle] = useState(lesson ? lesson.title : '')
  const [videoKey, setVideoKey] = useState(lesson ? lesson.videoUrl : '')
  const [quizDuration, setQuizDuration] = useState(
    lesson && lesson.lessonType === 'quiz'
      ? {
          h: lesson.duration.split(':')[0],
          m: lesson.duration.split(':')[1],
          s: lesson.duration.split(':')[2],
        }
      : {
          h: '',
          m: '',
          s: '',
        }
  )

  const createLesson = async () => {
    try {
      await Axios.post(
        `${REAST_API_URL}/academy/lesson/create`,
        {
          title: lessonTitle,
          summary:
            state.videoSummary || state.articleContent || state.quizDescription,
          courseId: courseId,
          sectionId: sectionId,
          videoUrl: videoKey,
          lessonType: state.lessonType,
          resources: state.resources,
          duration:
            quizDuration.h && quizDuration.m && quizDuration.s
              ? quizDuration.h + ':' + quizDuration.m + ':' + quizDuration.s
              : '00:00:00',
          order: order,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      refetchCourseById()
      setOpenAddLesson(false)
      setSectionId(false)
      setLesson('')
    } catch (error) {
      console.error(error)
    }
  }
  const updateLesson = async () => {
    try {
      await Axios.patch(
        `${REAST_API_URL}/academy/lesson/${lesson.id}/update`,
        {
          title: lessonTitle,
          summary:
            state.videoSummary || state.articleContent || state.quizDescription,

          courseId: courseId,

          videoUrl: videoKey,
          lessonType: state.lessonType,
          resources: state.resources,
          duration:
            quizDuration.h && quizDuration.m && quizDuration.s
              ? quizDuration.h + ':' + quizDuration.m + ':' + quizDuration.s
              : '00:00:00',
        },
        {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      refetchCourseById()
      setOpenAddLesson(false)
      setSectionId(false)
      setLesson('')
    } catch (error) {
      console.error(error)
    }
  }
  const uploadVideoPreview = e => {
    const tempFile = e.target.files[0]

    setState({...state, loadingUpload: true})

    Axios.post(
      UPLOAD_URL + '/video',
      {
        title: lessonTitle,
        description: state.videoSummary || `Description of ${lessonTitle}`,
        format: tempFile.type,
      },
      {
        withCredentials: true,
        // cancelToken: Axios.CancelToken.source(),
        headers: {
          Authorization: 'Bearer ' + TOKEN,
        },
      }
    )
      .then(response => {
        const url = `${response.data.link.protocol}://${response.data.link.address}${response.data.link.path}`

        Axios.put(url, tempFile, {
          params: {
            AWSAccessKeyId: response.data.link.query.AWSAccessKeyId,
            Expires: response.data.link.query.Expires,
            Signature: response.data.link.query.Signature,
          },
          headers: {
            'Content-Type': tempFile.type,
          },
        })
          .then(res => {
            if (res.status === 200) {
              setState({
                ...state,

                loadingUpload: false,
              })
              setVideoKey(response.data.media.key)
              // getMediaObject(response.data.media.key)
            } else {
              return enqueueSnackbar(response.statusText, {
                autoHideDuration: 2000,
                variant: 'error',
              })
            }
          })
          .catch(error => {
            enqueueSnackbar(JSON.stringify(error), {
              autoHideDuration: 2000,
              variant: 'error',
            })
            setState({...state, loadingUpload: false})
          })
          .finally(() => {
            setState({...state, loadingUpload: false})
          })
      })
      .catch(error => {
        setState({...state, loadingUpload: false})

        return enqueueSnackbar(JSON.stringify(error), {
          autoHideDuration: 2000,
          variant: 'error',
        })
      })
  }

  const handleChangeThumbnailLesson = async e => {
    const tempFile = Array.from(e.target.files)
    const val = tempFile[0]

    if (val.size > 1000 * 1000 * 25) {
      enqueueSnackbar("File size can't be more than 25MB", {
        autoHideDuration: 2000,
        variant: 'error',
      })
    } else if (
      val.type !== 'image/jpeg' &&
      val.type !== 'image/jpg' &&
      val.type !== 'image/png'
    ) {
      enqueueSnackbar('File other than images are not allowed', {
        autoHideDuration: 2000,
        variant: 'error',
      })
    } else {
      const formData = new FormData()
      formData.append('file', val)
      try {
        const response = await Axios.post(UPLOAD_URL, formData, {
          withCredentials: true,
          onUploadProgress: () => {
            // const loads = Math.round(
            //   (progressEvent.loaded / progressEvent.total) * 100
            // )
            setState({...state, loadingUploadThumbnail: true})
          },

          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data',
          },
        })

        setState({
          ...state,
          loadingUploadThumbnail: false,
          videoThumbnail: response.data.url,
        })
      } catch (e) {
        return e
      }
    }
  }

  const handleChangeVideoLesson = async e => {
    const tempFile = Array.from(e.target.files)
    const val = tempFile[0]

    if (val.size > 1000 * 1000 * 2000) {
      enqueueSnackbar("File size can't be more than 2GB", {
        autoHideDuration: 2000,
        variant: 'error',
      })
    } else if (val.type !== 'video/mp4' && val.type !== 'video/quicktime') {
      enqueueSnackbar('File other than videos are not allowed', {
        autoHideDuration: 2000,
        variant: 'error',
      })
    } else {
      uploadVideoPreview(e)
    }
  }

  const uploadResources = async e => {
    try {
      const rawFile = e.target.files[0]
      if (rawFile.type !== 'application/pdf') {
        e.target.value = null
        return alert('Only pdf file allowed.')
      }

      const tempFile = rawFile
      if (tempFile) {
        const formData = new FormData()
        formData.append('file', tempFile)
        Axios.post(UPLOAD_URL, formData, {
          withCredentials: true,
          onUploadProgress: () => {
            // const loads = Math.round(
            //   (progressEvent.loaded / progressEvent.total) * 100
            // )
            setState({...state, loadingUploadResources: true})
          },
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            setState({
              ...state,
              resources: [...state.resources, response.data.url],
              loadingUploadResources: false,
            })
          })
          .catch(() => {
            setState({...state, loadingUploadResources: false})
          })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const renderCreateLesson = () => {
    if (state.lessonType === 'video') {
      return (
        <div className={`${classes.marginBot20}`}>
          <Typography variant="body2" className={classes.marginTop16}>
            Judul Video
          </Typography>

          <TextField
            variant="outlined"
            /* value={state.lessonTitle}
            onChange={e => setState({...state, lessonTitle: e.target.value})} */
            value={lessonTitle}
            onChange={e => setLessonTitle(e.target.value)}
            fullWidth
            margin="normal"
            style={{background: 'white'}}
            placeholder="Tambah Judul Video"
          ></TextField>
          <Typography variant="body2" className={classes.marginTop16}>
            Rangkuman Video
          </Typography>

          <TextField
            variant="outlined"
            value={state.videoSummary}
            onChange={e => setState({...state, videoSummary: e.target.value})}
            fullWidth
            margin="normal"
            style={{background: 'white'}}
            placeholder="Tambah Rangkuman Video"
            multiline
            rows={10}
          ></TextField>
          <div className={classes.row}>
            {!videoKey && (
              <div className={classes.rowWithoutBetween}>
                <div className={classnames(classes.marginBot20)}>
                  <Typography
                    variant="body2"
                    className={(classes.marginBot16, classes.marginTop10)}
                  >
                    Gambar Sampul
                  </Typography>
                  <Typography
                    variant="body2"
                    style={style.grey}
                    className={classes.marginTop6}
                    gutterBottom
                  >
                    Resolusi min. : 1280 x 720 (HD,720p)
                  </Typography>
                  <Typography variant="body2" style={style.grey} gutterBottom>
                    Resolusi maks. : 1920 x 1080 (FHD, 1080p)
                  </Typography>
                  <Typography variant="body2" style={style.grey} gutterBottom>
                    Tipe berkas : jpg, jpeg, png
                  </Typography>
                  <Typography variant="body2" style={style.grey} gutterBottom>
                    Ukuran maks. : 25 MB
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{visibility: 'hidden'}}
                    gutterBottom
                  >
                    Wil be use as a video cover image
                  </Typography>
                  {state.videoThumbnail ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 40,
                      }}
                    >
                      <img
                        src={state.videoThumbnail}
                        alt="attch"
                        style={style.uploadedImg}
                      />
                      <IconButton
                        onClick={() => setState({...state, videoThumbnail: ''})}
                      >
                        <Icon>close</Icon>
                      </IconButton>
                    </div>
                  ) : (
                    <div style={style.addContainer}>
                      <Icon
                        style={{
                          ...style.grey,
                          fontSize: 40,
                          marginBottomn: 11,
                        }}
                      >
                        add
                      </Icon>
                      <Typography
                        variant="body2"
                        style={style.grey}
                        gutterBottom
                      >
                        Tambah gambar sampul video
                      </Typography>
                      <Typography
                        variant="body2"
                        style={style.grey}
                        gutterBottom
                      >
                        Tarik berkas di sini
                      </Typography>
                      <input
                        id="uploadImage"
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        // className="hidden"
                        style={style.input}
                        onChange={handleChangeThumbnailLesson}
                      />
                    </div>
                  )}
                  {state.loadingUploadThumbnail && (
                    <div style={{width: 400, textAlign: 'center'}}>
                      <LinearProgress
                        variant="indeterminate"
                        className={`${classes.marginTop16} ${classes.marginBot8}`}
                      />
                      <Typography variant="body2">
                        Uploading image ...
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className={classes.rowWithoutBetween}>
              <div className={classnames(classes.marginBot20)}>
                <Typography
                  variant="body2"
                  className={(classes.marginBot16, classes.marginTop10)}
                >
                  Unggah Video
                </Typography>
                <Typography
                  variant="body2"
                  style={style.grey}
                  className={classes.marginTop6}
                  gutterBottom
                >
                  Resolusi min : 1280 x 720 (HD,720p)
                </Typography>
                <Typography variant="body2" style={style.grey} gutterBottom>
                  Resolusi maks : 1920 x 1080 (FHD, 1080p)
                </Typography>
                <Typography variant="body2" style={style.grey} gutterBottom>
                  Tipe berkas : Mp4
                </Typography>
                <Typography variant="body2" style={style.grey} gutterBottom>
                  Ukuran maks : 2 GB
                </Typography>
                <Typography
                  variant="body2"
                  style={style.grey}
                  className={classes.hide}
                  gutterBottom
                >
                  Course Cover Image
                </Typography>
                {videoKey ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 40,
                    }}
                  >
                    {/*  <img
                      src={state.videoThumbnail}
                      alt="attch"
                      style={style.uploadedImg}
                    /> */}
                    <Typography variant="body2" style={style.grey} gutterBottom>
                      Lesson Video uploaded.
                    </Typography>
                    <IconButton onClick={() => setVideoKey('')}>
                      <Icon>close</Icon>
                    </IconButton>
                  </div>
                ) : !state.loadingUpload ? (
                  <div style={style.addContainer}>
                    <Icon
                      style={{
                        ...style.grey,
                        fontSize: 40,
                        marginBottomn: 11,
                      }}
                    >
                      add
                    </Icon>
                    <Typography variant="body2" style={style.grey} gutterBottom>
                      Tambah video pelajaran
                    </Typography>
                    <Typography variant="body2" style={style.grey} gutterBottom>
                      Tarik berkas di sini
                    </Typography>
                    <input
                      id="uploadVideo"
                      type="file"
                      accept="video/mp4, video/quicktime"
                      // className="hidden"
                      style={style.input}
                      onChange={handleChangeVideoLesson}
                    />
                  </div>
                ) : (
                  true
                )}
                {state.loadingUpload && (
                  <div style={{width: 400, textAlign: 'center'}}>
                    <LinearProgress
                      variant="indeterminate"
                      className={`${classes.marginTop16} ${classes.marginBot8}`}
                    />
                    <Typography variant="body2">Uploading video ...</Typography>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Flex style={{justifyContent: 'flex-end'}}>
            <Button
              size="large"
              className={classnames(classes.marginRight10, classes.textRed)}
              onClick={() => {
                setOpenAddLesson(false)
                setSectionId(false)
                setLesson('')
              }}
            >
              Batal
            </Button>
            <Button
              size="large"
              variant="contained"
              className={
                !lessonTitle || !state.videoSummary || !videoKey
                  ? classnames(classes.bgGrey, classes.textBlack)
                  : classnames(classes.bgBlue, classes.textWhite)
              }
              // color="primary"
              onClick={() => (!lesson ? createLesson() : updateLesson())}
              disabled={!!(!lessonTitle || !state.videoSummary || !videoKey)}
            >
              {lesson ? 'Ubah' : 'Kirim'}
            </Button>
          </Flex>
        </div>
      )
    } else if (state.lessonType === 'article') {
      return (
        <div className={`${classes.marginBot20}`}>
          <Typography variant="body2" className={classes.marginTop16}>
            Judul Artikel
          </Typography>

          <TextField
            variant="outlined"
            value={lessonTitle}
            onChange={e => setLessonTitle(e.target.value)}
            fullWidth
            margin="normal"
            style={{background: 'white'}}
            placeholder="Tambah Judul Artikel"
          ></TextField>

          <Typography variant="body2">Masukkan Konten Artikel</Typography>

          <div className={classes.row}>
            <div
              className={classnames(
                classes.rowWithoutBetween,
                classes.width10P
              )}
            >
              <Radio
                color="primary"
                checked={state.articleType === 'manual'}
                onChange={() => setState({...state, articleType: 'manual'})}
              />
              <Typography variant="body2">Ketik Manual</Typography>
            </div>
            <div
              className={classnames(
                classes.rowWithoutBetween,
                classes.width20P
              )}
            >
              <Radio
                color="primary"
                checked={state.articleType === 'uploadFile'}
                onChange={() => setState({...state, articleType: 'uploadFile'})}
              />
              <div className={classnames(classes.rowWithoutBetween)}>
                <Typography variant="body2">Unggah Berkas{'  '}</Typography>
                <Tooltip
                  style={{
                    backgroundColor: '#FFF',
                    marginLeft: 20,
                    marginTop: 5,
                  }}
                  title={
                    <>
                      Ekstensi berkas harus:
                      <br />
                      &#8226; Berkas PDF
                      <br />
                      <br />
                      Ukuran Maks.: 2GB
                    </>
                  }
                  placement="right-start"
                  arrow
                >
                  <Icon style={{color: '#004A62'}} fontSize="small">
                    info
                  </Icon>
                </Tooltip>
              </div>
            </div>
            <div className={classes.rowWithoutBetween}></div>
          </div>
          {state.articleType === 'manual' ? (
            <>
              <Typography variant="body2" className={classes.marginTop16}>
                Konten
              </Typography>
              <div className="document-editor__toolbar"></div>
              <CKEditor
                editor={DecoupledEditor}
                config={editorConfiguration}
                onInit={editor => {
                  window.editor = editor

                  const toolbarContainer = document.querySelector(
                    '.document-editor__toolbar'
                  )
                  toolbarContainer.appendChild(editor.ui.view.toolbar.element)
                }}
                data={state.articleContent}
                // eslint-disable-next-line
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setState({...state, articleContent: data})
                }}
              />
              <div style={style.customEditorFooter} />
            </>
          ) : (
            <>
              {/* <Typography variant="body2" className={classes.marginTop16}>
                Upload File
              </Typography> */}
              {/* <div className="flex flex-row my-64">
                <div className="mr-36">
                  <label for="uploadImage">
                    <Card className="p-24 flex justify-center cursor-pointer">
                      <Icon className="text-blue-dark self-center text-5xl">
                        add
                      </Icon>
                    </Card>
                  </label>
                  <input
                    id="uploadImage"
                    className="pt-20 hidden"
                    type="file"
                    accept="application/pdf"
                    // onChange={e => this.uploadThumbnail(e)}
                  />
                </div>
              </div> */}
              {!state.loadingUploadResources ? (
                <div className={classes.marginTop10}>
                  <label htmlFor="uploadAttachment">
                    <Card
                      className={classnames(
                        classes.width7P,
                        classes.cursorPointer
                      )}
                    >
                      <Icon
                        style={{fontSize: 30}}
                        className={classnames(
                          classes.textBlue,
                          classes.margin20
                        )}
                      >
                        add
                      </Icon>
                    </Card>
                  </label>
                  <input
                    id="uploadAttachment"
                    className={classnames(classes.hide, classes.marginTop10)}
                    type="file"
                    accept="application/pdf"
                    onChange={e => uploadResources(e)}
                  />
                </div>
              ) : (
                <div style={{width: 400, textAlign: 'center'}}>
                  <LinearProgress
                    variant="indeterminate"
                    className={`${classes.marginTop16} ${classes.marginBot8}`}
                  />
                  <Typography variant="body2">Uploading ...</Typography>
                </div>
              )}

              {state.resources.map((resource, i) => {
                return (
                  <AttachmentItemHover
                    key={`${i}-itm`}
                    /* iconAction={
                      <VisibilityOutlinedIcon
                        onClick={() => window.open(resource)}
                        className="icon"
                      />
                    } */
                    onPreview={() => window.open(resource)}
                    onDelete={() => {
                      const _resources = state.resources
                      _resources.splice(i, 1)
                      setState({
                        ...state,
                        resources: _resources,
                      })
                    }}
                    fileName={`attachment ${i + 1}`}
                    url={resource}
                  />
                )
              })}
            </>
          )}

          <Flex style={{justifyContent: 'flex-end', marginTop: 20}}>
            <Button
              size="large"
              className={classnames(classes.marginRight10, classes.textRed)}
              onClick={() => {
                setOpenAddLesson(false)
                setSectionId(false)
                setLesson('')
              }}
            >
              Batal
            </Button>
            <Button
              size="large"
              variant="contained"
              className={
                !lessonTitle
                  ? classnames(classes.bgGrey, classes.textBlack)
                  : classnames(classes.bgBlue, classes.textWhite)
              }
              onClick={() => (!lesson ? createLesson() : updateLesson())}
              disabled={!lessonTitle}
            >
              {lesson ? 'Ubah' : 'Kirim'}
            </Button>
          </Flex>
        </div>
      )
    } else if (state.lessonType === 'quiz') {
      return (
        <div className={`${classes.marginBot20}`}>
          <Typography variant="body2" className={classes.marginTop16}>
            Judul Kuis
          </Typography>

          <TextField
            variant="outlined"
            value={lessonTitle}
            onChange={e => setLessonTitle(e.target.value)}
            fullWidth
            margin="normal"
            style={{background: 'white'}}
            placeholder="Tambah Judul Kuis"
          ></TextField>
          <Typography variant="body2" className={classes.marginTop16}>
            Keterangan
          </Typography>

          <TextField
            variant="outlined"
            value={state.quizDescription}
            onChange={e =>
              setState({
                ...state,
                quizDescription: e.target.value,
              })
            }
            fullWidth
            margin="normal"
            style={{background: 'white'}}
            placeholder="Tambah Keterangan Kuis"
            multiline
            rows={10}
          ></TextField>
          <Typography variant="body2" className={classes.marginTop16}>
            Durasi Kuis
          </Typography>
          <div className={classes.rowWithoutBetween}>
            <div className={classes.rowWithoutBetween}>
              <TextField
                variant="outlined"
                type="number"
                value={quizDuration.h}
                onChange={e =>
                  setQuizDuration({
                    ...quizDuration,
                    h: e.target.value,
                  })
                }
                margin="normal"
                style={{background: 'white'}}
                className={classes.width30P}
              ></TextField>
              <Typography
                variant="body2"
                className={classnames(
                  classes.textGrey,
                  classes.marginLeft10,
                  classes.marginRight10
                )}
              >
                {' '}
                j
              </Typography>
            </div>
            <div className={classes.rowWithoutBetween}>
              <TextField
                variant="outlined"
                type="number"
                value={quizDuration.m}
                onChange={e =>
                  setQuizDuration({
                    ...quizDuration,
                    m: e.target.value,
                  })
                }
                margin="normal"
                style={{background: 'white'}}
                className={classes.width30P}
              ></TextField>
              <Typography
                variant="body2"
                className={classnames(
                  classes.textGrey,
                  classes.marginLeft10,
                  classes.marginRight10
                )}
              >
                {' '}
                m
              </Typography>
            </div>
            <div className={classes.rowWithoutBetween}>
              <TextField
                variant="outlined"
                type="number"
                value={quizDuration.s}
                onChange={e =>
                  setQuizDuration({
                    ...quizDuration,
                    s: e.target.value,
                  })
                }
                margin="normal"
                style={{background: 'white'}}
                className={classes.width30P}
              ></TextField>
              <Typography
                variant="body2"
                className={classnames(
                  classes.textGrey,
                  classes.marginLeft10,
                  classes.marginRight10
                )}
              >
                {' '}
                d
              </Typography>
            </div>
          </div>
          <Flex style={{justifyContent: 'flex-end'}}>
            <Button
              size="large"
              className={classnames(classes.marginRight10, classes.textRed)}
              onClick={() => {
                setOpenAddLesson(false)
                setSectionId(false)
                setLesson('')
              }}
            >
              Batal
            </Button>
            <Button
              size="large"
              variant="contained"
              className={
                !lessonTitle ||
                !state.quizDescription ||
                !quizDuration.h ||
                !quizDuration.m ||
                !quizDuration.s
                  ? classnames(classes.bgGrey, classes.textBlack)
                  : classnames(classes.bgBlue, classes.textWhite)
              }
              disabled={
                !!(
                  !lessonTitle ||
                  !state.quizDescription ||
                  !quizDuration.h ||
                  !quizDuration.m ||
                  !quizDuration.s
                )
              }
              onClick={() => (!lesson ? createLesson() : updateLesson())}
            >
              {/* {selected ? 'Edit' : 'Submit'} */}
              {lesson ? 'Ubah' : 'Tambah'}
            </Button>
          </Flex>
        </div>
      )
    }
  }

  return (
    <Paper className={classes.padding24} style={{backgroundColor: '#F8F8F9'}}>
      {!lesson && (
        <>
          <Typography
            className={classnames(classes.marginTop10, classes.marginBot10)}
          >
            Pilih tipe utama konten. Berkas dan tautan dapat ditambahkan sebagai
            sumber
          </Typography>
          <div className={classnames(classes.row, classes.width35P)}>
            <Button
              variant="contained"
              onClick={() => setState({...state, lessonType: 'video'})}
              className={
                state.lessonType === 'video'
                  ? classnames(classes.bgBlue, classes.textWhite)
                  : classes.textBlack
              }
            >
              <Icon>videocam</Icon>
              &nbsp; Video
            </Button>

            <Button
              variant="contained"
              onClick={() => setState({...state, lessonType: 'article'})}
              className={
                state.lessonType === 'article'
                  ? classnames(classes.bgBlue, classes.textWhite)
                  : classes.textBlack
              }
            >
              <Icon>notes</Icon>
              &nbsp; Artikel
            </Button>
            <Button
              variant="contained"
              onClick={() => setState({...state, lessonType: 'quiz'})}
              className={
                state.lessonType === 'quiz'
                  ? classnames(classes.bgBlue, classes.textWhite)
                  : classes.textBlack
              }
            >
              <Icon>assignment</Icon>
              &nbsp; Kuis
            </Button>
          </div>
        </>
      )}

      {renderCreateLesson()}
    </Paper>
  )
}
