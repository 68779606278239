import gql from 'graphql-tag'

export const DELETE_OVERTIME_POLICY = gql`
  mutation updateOvertimePolicy($id: Int!, $deleteAt: timestamp) {
    update_time_overtime_policies(
      where: {id: {_eq: $id}}
      _set: {deletedAt: $deleteAt}
    ) {
      affected_rows
    }
  }
`
