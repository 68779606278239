import React, {useState} from 'react'

import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'
import Aspect from './aspect/AspectComponent'
import Perspective from './perspective/PerspectiveComponent'
import Method from './method/MethodComponent'
import Monitoring from './monitoring/MonitoringComponent'
import Scoring from './scoring/ScoringComponent'
import LevelComponent from './level/LevelComponent'
import WeightComponent from './weight/WeightComponent'
import List from './list/ListComponent'
import Casecading from './casecading/CasecadingComponent'
import FormPerspective from './perspective/form-perspective/FormPerspective'
import AddAspect from './aspect/add-aspect/AddAspect'
import EditAspect from './aspect/add-aspect/EditAspect'
import AddMethod from './method/add-method/AddMethod'
import FormMonitoring from './monitoring/form-monitoring/FormMonitoring'
import FormScoring from './scoring/form-scoring/FormScoring'
import FormLevel from './level/form-level/FormLevel'
import FormWeight from './weight/form-weight/FormWeight'
import AddEditListComponent from './list/AddEditListComponent'
import AddEditCascading from './casecading/AddEditCasecading'
import DetailCascadingTree from './casecading/DetailCascadingTree'
import AddEditDetailCascading from './casecading/AddEditDetailCascading'
import {PageContainer} from '../../../GlobalStyles'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'

// import {APP_DEPENDENT_URL} from '../../../utils/globals'
// import {capitalize} from '../../../utils/helpers'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'aspect':
      return <Aspect searchText={searchText} />
    case 'perspective':
      return <Perspective searchText={searchText} />
    case 'add-perspective':
      return <FormPerspective />
    case 'edit-perspective':
      return <FormPerspective dataPerspective={state.dataPerspective} />
    case 'method':
      return <Method searchText={searchText} />
    case 'monitoring':
      return <Monitoring searchText={searchText} />
    case 'scoring':
      return <Scoring searchText={searchText} />
    case 'level':
      return <LevelComponent searchText={searchText} />
    case 'weight':
      return <WeightComponent searchText={searchText} />
    case 'version':
      return <h1>Hello world</h1>
    case 'list':
      return <List searchText={searchText} />
    case 'cascading':
      return <Casecading searchText={searchText} />
    case 'add-aspect':
      return <AddAspect />
    case 'edit-aspect':
      return <EditAspect dataAspect={state.dataAspect} />
    case 'add-method':
      return <AddMethod />
    case 'edit-method':
      return <AddMethod dataMethod={state.dataMethod} />
    case 'add-monitoring':
      return <FormMonitoring />
    case 'edit-monitoring':
      return <FormMonitoring dataMonitoring={state.dataMonitoring} />
    case 'add-scoring':
      return <FormScoring />
    case 'edit-scoring':
      return <FormScoring dataScoring={state.dataScoring} />
    case 'add-level':
      return <FormLevel />
    case 'edit-level':
      return <FormLevel dataLevel={state.dataLevel} />
    case 'add-weight':
      return <FormWeight />
    case 'edit-weight':
      return <FormWeight dataWeight={state.dataWeight} />
    case 'add-list':
      return <AddEditListComponent />
    case 'edit-list':
      return <AddEditListComponent dataListKpi={state.dataListKpi} />
    case 'add-cascading':
      return <AddEditCascading />
    case 'edit-cascading':
      return <AddEditCascading dataCascading={state.dataCascading} />
    case 'cascading-detail':
      return <DetailCascadingTree dataCascading={state.dataCascading} />
    case 'cascading-detail-add':
      return <AddEditDetailCascading idCascading={state.idCascading} />
    case 'cascading-detail-add-child':
      return (
        <AddEditDetailCascading
          idCascading={state.idCascading}
          dataParent={state.dataParent}
          addChild={state.addChild}
        />
      )
    case 'cascading-detail-edit':
      return (
        <AddEditDetailCascading
          idCascading={state.idCascading}
          dataDetailCascading={state.dataDetailCascading}
        />
      )
    default:
      return <Aspect searchText={searchText} />
  }
}

const KpiPage = props => {
  let activeTab = ''
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  } else {
    active = props.location.pathname.split('/')[3] || 'aspect'
  }
  if (props.location.pathname) {
    activeTab = props.location.pathname.split('/')[3]
    state = {...state, active, searchable: true}
  }
  const data_tabs1 = [
    {
      label: 'Konfigurasi',
      state: 'configuring',
      url: '#',
      searchable: false,
    },
    {
      label: 'Aspek',
      state: 'aspect',
      url: '/performance/kpi/aspect',
      searchable: true,
    },
    {
      label: 'Metode',
      state: 'method',
      url: '/performance/kpi/method',
      searchable: true,
    },
    {
      label: 'Frekuensi pemantauan',
      state: 'monitoring',
      url: '/performance/kpi/monitoring',
      searchable: true,
    },
    {
      label: 'Perspektif',
      state: 'perspective',
      url: '/performance/kpi/perspective',
      searchable: true,
    },
    {
      label: 'Sifat Penilaian',
      state: 'scoring',
      url: '/performance/kpi/scoring',
      searchable: true,
    },
    {
      label: 'Level',
      state: 'level',
      url: '/performance/kpi/level',
      searchable: true,
    },
    {
      label: `${GLOBAL_GLOSSARY.performance['Weight Unit']}`,
      state: 'weight',
      url: '/performance/kpi/weight',
      searchable: true,
    },
    // {
    //   label: 'Version',
    //   state: 'version',
    //   url: `${APP_DEPENDENT_URL}/performance/KPI/version`,
    //   searchable: false,
    // },
    {
      label: `Kumpulan ${GLOBAL_GLOSSARY.performance.Goal}`,
      state: 'list',
      url: `/performance/kpi/list`,
      searchable: true,
    },
  ]

  const data_tabs_2 = [
    {
      label: 'Memproses',
      state: 'processing',
      url: '#',
      searchable: false,
    },
    {
      label: `Turunan ${GLOBAL_GLOSSARY.performance.Goal}`,
      state: 'cascading',
      url: `/performance/kpi/cascading`,
      searchable: true,
    },
  ]

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <Card
      icon="assessment"
      title={`Kumpulan ${GLOBAL_GLOSSARY.performance.Goal}`}
      onSearch={onSearch}
      state={state}
    >
      <PageContainer>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <WlbTab
            data={data_tabs1}
            activeMenu={activeTab}
            disableFirstElement={true}
          />
          <div style={{marginTop: 20}}>
            <WlbTab
              data={data_tabs_2}
              activeMenu={activeTab}
              disableFirstElement={true}
            />
          </div>
        </div>

        {renderComponent(active, state, searchText)}
      </PageContainer>
    </Card>
  )
}

export default KpiPage
