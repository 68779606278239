import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  AddEditTitle,
  FormToolbar,
  FormTitleContainer,
} from '../../../../GlobalStyles'
import {
  Divider,
  IconButton,
  // Checkbox,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // Hide: WP-2188
  // eslint-disable-next-line no-unused-vars
  Button,
} from '@material-ui/core'
// Hide: WP-2188
// eslint-disable-next-line no-unused-vars
import {Add} from '@material-ui/icons'
import {useSnackbar} from 'notistack'
import helperStyle, {
  PayrollContainer,
  PayrollDetailWrapper,
  FlexBetween,
  TextDarkBlue,
  TextBlackBold,
  LitleGreyText,
  BigTextBlackBold,
  MediumTextBlackBold,
  CenterWrapper,
  pageSizeOption,
} from '../PayrollPageStyles'
import DetailComponentItem from '../shared-components/DetailComponentItem'
import EmployeeAvatar from '../../../shared-components/EmployeeAvatar'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Link, withRouter} from 'react-router-dom'
import classNames from 'classnames'
import {
  convertToRoundedRupiah,
  isEmpty,
  convertArrayToHashMap,
} from '../../../../utils/helpers'
import {
  GET_PAYROLL_DETAIL,
  GET_PAYROLL_DETAIL_COMPONENT_LIST,
  GET_EMPLOYEE_PAYROLL_PROCESS,
  GET_EMPLOYEE_APPROVAL_PAYROLL_PROCESS,
} from '../../../../graphql/queries'
import {UPDATE_PAYROLL_DETAIL} from '../../../../graphql/mutations'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../utils'
// import {
//   checkIntegerInput,
//   integerHelperText,
//   onChangeNumber,
//   onChanger,
//   stringHelperText,
//   checkStringInput,
// } from '../../../../utils/formValidation.helper'

const PayrollDetail = props => {
  const {history, location} = props
  const {payrollId, month, year} = location.state
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()

  const [additionComponentList, setAdditionComponentList] = useState([])
  const [deductionComponentList, setDeductionComponentList] = useState([])
  const [companyComponentList, setCompanyComponentList] = useState([])
  const [companyComponentTotal, setCompanyComponentTotal] = useState(0)
  const [companyDeductionList, setCompanyDeductionList] = useState([])
  const [companyDeductionTotal, setCompanyDeductionTotal] = useState(0)

  const [salary, setSalary] = useState('')
  const [addition, setAddition] = useState([])
  const [deduction, setDeduction] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [postponedItem, setPostponedItem] = useState(true)
  const [sumOfAddition, setSumOfAddition] = useState('')
  const [sumOfDeduction, setSumOfDeduction] = useState('')

  // eslint-disable-next-line no-unused-vars
  const [toastHasNew, setToastHasNew] = useState(false)

  const {data: payrollDetail, loading, error} = useQuery(GET_PAYROLL_DETAIL, {
    variables: {
      id: payrollId,
    },
    fetchPolicy: 'no-cache',
    ...GQL_FINANCE_OPTIONS,
  })

  const {data: componentList} = useQuery(GET_PAYROLL_DETAIL_COMPONENT_LIST, {
    variables: {
      company: COMPANY_ID,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const [updatePayroll] = useMutation(
    UPDATE_PAYROLL_DETAIL,
    GQL_FINANCE_OPTIONS
  )

  useEffect(() => {
    setSumOfAddition(
      !isEmpty(addition)
        ? addition
            .map(item => {
              if (item.is_postponed === false) {
                return Math.round(item.value)
              } else {
                return 0
              }
            })
            .reduce((a, b) => a + b)
        : 0
    )
    setSumOfDeduction(
      !isEmpty(deduction)
        ? deduction
            .map(item => {
              if (item.is_postponed === false) {
                return Math.round(item.value)
              } else {
                return 0
              }
            })
            .reduce((a, b) => a + b)
        : 0
    )
  }, [addition, deduction])

  useEffect(() => {
    if (payrollDetail && componentList) {
      if (payrollDetail.people_work_payroll_by_pk) {
        const payroll = payrollDetail.people_work_payroll_by_pk
        setSalary(payroll.salary)

        setAddition(payroll.allowances ? payroll.allowances : [])
        setDeduction(
          payroll.non_fixed_components ? payroll.non_fixed_components : []
        )

        if (payroll.payroll_fields?.CompanyComponents) {
          setCompanyComponentList(
            payroll.payroll_fields.CompanyComponents || []
          )
          setCompanyComponentTotal(
            payroll.payroll_fields.CompanyComponents.map(item =>
              Math.round(item.value || 0)
            ).reduce((prev, next) => prev + next) || 0
          )
        }

        if (payroll.payroll_fields?.CompanyDeductions) {
          setCompanyDeductionList(
            payroll.payroll_fields.CompanyDeductions || []
          )
          setCompanyDeductionTotal(
            payroll.payroll_fields.CompanyDeductions.map(item =>
              Math.round(item.value || 0)
            ).reduce((prev, next) => prev + next) || 0
          )
        }
        // setPostponedItem(
        //   payroll.postponed_items && payroll.postponed_items.salary
        // )
      }
      if (componentList.finance_nonfixed_components) {
        setAdditionComponentList(
          componentList.finance_nonfixed_components.filter(
            item => item.type === 1
          )
        )
        setDeductionComponentList(
          componentList.finance_nonfixed_components.filter(
            item => item.type === 2
          )
        )
      }
    }
  }, [payrollDetail, componentList])

  const goBack = () => {
    history.push({
      pathname: '/financial/payroll/payroll-process/step',
      search: `year=${year}&month=${month}`,
      state: {
        active: 'step-payroll-process',
        showPeriod: false,
        searchable: true,
        showMonthYearPeriod: true,
        filter: true,
        backStep: true,
      },
    })
  }

  // Hide: WP-2188
  // eslint-disable-next-line no-unused-vars
  const onAddItem = (component, defaultData) => {
    const value = {
      id: defaultData.id,
      name: defaultData.name,
      value: 0,
      is_postponed: false,
    }

    if (component === 'addition') {
      setAddition(
        addition.concat({
          ...value,
        })
      )
    } else {
      setDeduction(
        deduction.concat({
          ...value,
          is_plugin_component: false,
        })
      )
    }

    setToastHasNew(true)
  }

  const onDeleteItemAddition = id => {
    enqueueSnackbar('Detail deleted', {
      variant: 'success',
      autoHideDuration: 3000,
    })
    setAddition(addition => addition.filter(a => a.id !== id))
  }

  const onDeleteItemDeduction = id => {
    enqueueSnackbar('Detail deleted', {
      variant: 'success',
      autoHideDuration: 3000,
    })
    setDeduction(deduction => deduction.filter(d => d.id !== id))
  }

  const handleChangeAddition = (id, target, value) => {
    const newValue = {}
    if (target === 'id-name') {
      const idName = value.split('-')
      newValue.id = Number(idName[0])
      newValue.name = idName[1]
    } else {
      newValue[target] = target === 'value' ? Number(value || 0) : value
    }

    setAddition(addition => {
      const item = addition.map(a => {
        if (a.id === id) {
          return {...a, ...newValue}
        } else {
          return a
        }
      })
      return item
    })

    setToastHasNew(true)
  }

  const handleChangeDeduction = (id, target, value) => {
    const newValue = {}
    if (target === 'id-name') {
      const idName = value.split('-')
      newValue.id = Number(idName[0])
      newValue.name = idName[1]
    } else {
      newValue[target] = target === 'value' ? Number(value || 0) : value
    }

    setDeduction(deduction => {
      const item = deduction.map(d => {
        if (d.id === id) {
          return {...d, ...newValue}
        } else {
          return d
        }
      })
      return item
    })

    setToastHasNew(true)
  }

  // Hide: WP-2188
  // eslint-disable-next-line no-unused-vars
  const onUpdatePayroll = () => {
    const variables = {
      id: payrollId,
      final_salary: salary + sumOfAddition - sumOfDeduction,
      postpone_salary: {salary: postponedItem},
      addition: addition,
      deduction: deduction,
      addition_total: sumOfAddition,
      deduction_total: sumOfDeduction,
    }
    updatePayroll({
      variables,
      ...GQL_FINANCE_OPTIONS,
      refetchQueries: [
        {
          query: GET_PAYROLL_DETAIL,
          variables: {
            id: payrollId,
          },
          ...GQL_FINANCE_OPTIONS,
        },
        {
          query: GET_EMPLOYEE_PAYROLL_PROCESS,
          variables: {
            company: COMPANY_ID,
            year: year,
            month: month,
            offset: 0,
            limit: pageSizeOption[0],
            position: null,
            organization: null,
            job_profile: null,
            rank: null,
            search: `%%`,
          },
          ...GQL_FINANCE_OPTIONS,
        },
        {
          query: GET_EMPLOYEE_APPROVAL_PAYROLL_PROCESS,
          variables: {
            login: USER_ID,
            company: COMPANY_ID,
            year: year,
            month: month,
            offset: 0,
            limit: pageSizeOption[0],
            position: null,
            organization: null,
            job_profile: null,
            rank: null,
            search: `%%`,
          },
          ...GQL_FINANCE_OPTIONS,
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(() => {
        // if (!toastHasNew) {
        //   enqueueSnackbar('Success Update data', {
        //     variant: 'success',
        //     autoHideDuration: 3000,
        //   })
        // }
        // if (toastHasNew) {
        enqueueSnackbar('Detail saved', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        // }
        goBack()
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
        })
      })
  }

  const componentListMap =
    componentList &&
    convertArrayToHashMap(componentList.finance_nonfixed_components, 'id')

  const selectedAdditionOptionMap =
    addition && convertArrayToHashMap(addition, 'id')

  const selectedDeductionOptionMap =
    deduction && convertArrayToHashMap(deduction, 'id')

  const additionAvailableOptions = additionComponentList
    ? additionComponentList.filter(opt => {
        if (!selectedAdditionOptionMap[opt.id]) return opt
      })
    : []

  const deductionAvailableOptions = deductionComponentList
    ? deductionComponentList.filter(opt => {
        if (!selectedDeductionOptionMap[opt.id]) return opt
      })
    : []

  const renderStatus = data => {
    if (data.approve_step === 3) {
      return (
        <Chip
          label="Approved"
          style={{
            marginTop: '5px',
            borderRadius: '6px',
            backgroundColor: '#FFA000',
          }}
        />
      )
    } else if (data.approver && data.finance_personel) {
      return (
        <Chip
          label="Approved"
          style={{
            marginTop: '5px',
            borderRadius: '6px',
            backgroundColor: '#FFA000',
          }}
        />
      )
    } else if (!data.approver && data.finance_personel) {
      return (
        <Chip
          label="Need Approval 1"
          style={{
            marginTop: '5px',
            borderRadius: '6px',
          }}
        />
      )
    } else if (data.approver && data.finance_personel) {
      return (
        <Chip
          label="Need Approval 2"
          style={{
            marginTop: '5px',
            borderRadius: '6px',
          }}
        />
      )
    } else {
      return (
        <Chip
          label="Need Approval"
          style={{
            marginTop: '5px',
            borderRadius: '6px',
          }}
        />
      )
    }
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton edge="end" aria-label="back" onClick={goBack}>
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6" display="inline">
            Payroll Detail
          </AddEditTitle>
        </FormTitleContainer>
        {/* Hide: WP-2188
        {payrollDetail &&
          payrollDetail?.people_work_payroll_by_pk?.process_step === 1 && (
            <div>
              <Button
                size="large"
                className={classes.backButton}
                onClick={goBack}
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={onUpdatePayroll}
              >
                Save
              </Button>
            </div>
          )} */}
      </FormToolbar>
      <Divider />
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {!payrollDetail && loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {payrollDetail && (
        <PayrollDetailWrapper>
          <FlexBetween>
            <PaperContainer
              className={classNames(
                classes.width35P,
                classes.padding20,
                classes.marginTop20
              )}
            >
              <EmployeeAvatar
                src={
                  payrollDetail?.people_work_payroll_by_pk?.employee_placement
                    .global_user.avatar
                }
                regno={
                  payrollDetail?.people_work_payroll_by_pk?.employee_placement
                    ?.regno
                }
                title={
                  payrollDetail?.people_work_payroll_by_pk.employee_placement
                    ?.company_job_profile.title
                }
                global_user={
                  payrollDetail?.people_work_payroll_by_pk.employee_placement
                    ?.global_user
                }
                city_name={
                  payrollDetail?.people_work_payroll_by_pk.employee_placement
                    ?.company_job_profile.company_address.global_city.name
                }
                province_name={
                  payrollDetail?.people_work_payroll_by_pk.employee_placement
                    ?.company_job_profile.company_address.global_province.name
                }
                empStatus={
                  payrollDetail?.people_work_payroll_by_pk?.employee_placement
                    ?.status
                }
                empEndDate={
                  payrollDetail?.people_work_payroll_by_pk?.employee_placement
                    ?.end_date
                }
              />
            </PaperContainer>
            <PaperContainer
              className={classNames(
                classes.width35P,
                classes.padding20,
                classes.marginTop20
              )}
            >
              <PayrollContainer className={classes.marginLeft10}>
                <TextDarkBlue>Status</TextDarkBlue>
                <div>
                  {/* <Chip
                    label="Need Approval"
                    style={{marginTop: '5px', borderRadius: '6px'}}
                  /> */}
                  {renderStatus(payrollDetail.people_work_payroll_by_pk)}
                </div>

                <LitleGreyText>
                  Please send request approval at{' '}
                  <Link
                    to={{
                      pathname: '/financial/payroll/payroll-process/step',
                      search: `year=${year}&month=${month}`,
                      state: {
                        active: 'step-payroll-process',
                        showPeriod: true,
                        noMaxDate: true,
                        searchable: true,
                        filter: true,
                        backStep: true,
                      },
                    }}
                  >
                    payroll process
                  </Link>
                </LitleGreyText>
              </PayrollContainer>
            </PaperContainer>
          </FlexBetween>
          <div className={classes.paddingTable}>
            <Table className={classNames(classes.marginTop20)}>
              <TableHead className={classNames(classes.tableHeader)}>
                <TableRow>
                  <TableCell>
                    <TextBlackBold>Earning</TextBlackBold>
                  </TableCell>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell align="center">
                    <TextBlackBold>Postpone Pending Payment</TextBlackBold>
                  </TableCell> */}
                  <TableCell align="right">
                    <TextBlackBold>Value</TextBlackBold>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Basic Salary</TableCell>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell align="center">
                    <Checkbox
                      color="primary"
                      checked={postponedItem}
                      onChange={() => setPostponedItem(prev => !prev)}
                      inputProps={{'aria-label': 'primary checkbox'}}
                      disabled={true}
                    />
                  </TableCell> */}
                  <TableCell align="right">
                    {convertToRoundedRupiah(salary)}
                  </TableCell>
                </TableRow>
                {addition &&
                  addition.map((item, index) => (
                    <DetailComponentItem
                      key={index}
                      item={item}
                      handleChangeItem={handleChangeAddition}
                      onDeleteItem={onDeleteItemAddition}
                      disabled={
                        // Hide: WP-2188
                        // payrollDetail?.people_work_payroll_by_pk?.process_step !==
                        // 1
                        true
                      }
                      availableOptions={additionAvailableOptions}
                      componentListMap={componentListMap}
                    />
                  ))}
                <TableRow>
                  <TableCell>
                    {/* Hide: WP-2188
                    {payrollDetail &&
                      payrollDetail?.people_work_payroll_by_pk?.process_step ===
                        1 &&
                      additionAvailableOptions && (
                        <Button
                          disabled={!(additionAvailableOptions.length > 0)}
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={() =>
                            onAddItem('addition', {
                              id: additionAvailableOptions[0]?.id,
                              name: additionAvailableOptions[0]?.name,
                            })
                          }
                          startIcon={<Add />}
                        >
                          New Item
                        </Button>
                      )} */}
                  </TableCell>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell></TableCell> */}
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell></TableCell> */}
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <MediumTextBlackBold>
                      {convertToRoundedRupiah(sumOfAddition + salary)}
                    </MediumTextBlackBold>
                  </TableCell>
                </TableRow>
              </TableBody>

              {/* DEDUCTION */}
              <TableHead className={classNames(classes.tableHeader)}>
                <TableRow>
                  <TableCell>
                    <TextBlackBold>Deduction</TextBlackBold>
                  </TableCell>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell align="center">
                    <TextBlackBold>Postpone Pending Payment</TextBlackBold>
                  </TableCell> */}
                  <TableCell align="right">
                    <TextBlackBold>Value</TextBlackBold>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deduction &&
                  deduction.map((item, index) => (
                    <DetailComponentItem
                      key={index}
                      item={item}
                      handleChangeItem={handleChangeDeduction}
                      onDeleteItem={onDeleteItemDeduction}
                      isHideCheckBox
                      disabled={
                        // Hide: WP-2188
                        // payrollDetail?.people_work_payroll_by_pk?.process_step !==
                        // 1
                        true
                      }
                      availableOptions={deductionAvailableOptions}
                      componentListMap={componentListMap}
                    />
                  ))}
                <TableRow>
                  <TableCell>
                    {/* Hide: WP-2188
                    {payrollDetail &&
                      payrollDetail?.people_work_payroll_by_pk?.process_step ===
                        1 &&
                      deductionAvailableOptions && (
                        <Button
                          disabled={!(deductionAvailableOptions.length > 0)}
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            onAddItem('deduction', {
                              id: deductionAvailableOptions[0]?.id,
                              name: deductionAvailableOptions[0]?.name,
                            })
                          }}
                          startIcon={<Add />}
                        >
                          New Item
                        </Button>
                      )} */}
                  </TableCell>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell></TableCell> */}
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell></TableCell> */}
                  <TableCell align="right">
                    <MediumTextBlackBold>
                      {convertToRoundedRupiah(sumOfDeduction)}
                    </MediumTextBlackBold>
                  </TableCell>
                </TableRow>
              </TableBody>

              {/* ADDED BY COMPANY */}
              <TableHead className={classNames(classes.tableHeader)}>
                <TableRow>
                  <TableCell>
                    <TextBlackBold>Added by Company</TextBlackBold>
                  </TableCell>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell></TableCell> */}
                  <TableCell align="right">
                    <TextBlackBold>Value</TextBlackBold>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyComponentList &&
                  companyComponentList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      {/* Hide: WLB 4052 */}
                      {/* <TableCell></TableCell> */}
                      <TableCell align="right">
                        {convertToRoundedRupiah(item.value)}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell></TableCell>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell></TableCell> */}
                  <TableCell align="right">
                    <MediumTextBlackBold>
                      {convertToRoundedRupiah(companyComponentTotal)}
                    </MediumTextBlackBold>
                  </TableCell>
                </TableRow>
              </TableBody>

              {/* DEDUCTED BY COMPANY */}
              <TableHead className={classNames(classes.tableHeader)}>
                <TableRow>
                  <TableCell>
                    <TextBlackBold>Deducted by Company</TextBlackBold>
                  </TableCell>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell></TableCell> */}
                  <TableCell align="right">
                    <TextBlackBold>Value</TextBlackBold>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyDeductionList &&
                  companyDeductionList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      {/* Hide: WLB 4052 */}
                      {/* <TableCell></TableCell> */}
                      <TableCell align="right">
                        {convertToRoundedRupiah(item.value)}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell></TableCell>
                  {/* Hide: WLB 4052 */}
                  {/* <TableCell></TableCell> */}
                  <TableCell align="right">
                    <MediumTextBlackBold>
                      {convertToRoundedRupiah(companyDeductionTotal)}
                    </MediumTextBlackBold>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div
            className={classNames(classes.marginTopBot35, classes.paddingTable)}
          >
            <FlexBetween>
              <TextBlackBold
                className={classNames(classes.width70P, classes.textAlignRight)}
              >
                Take Home Pay
              </TextBlackBold>
              <BigTextBlackBold>
                {convertToRoundedRupiah(
                  salary + sumOfAddition - sumOfDeduction
                )}
              </BigTextBlackBold>
            </FlexBetween>
          </div>
        </PayrollDetailWrapper>
      )}
    </PaperContainer>
  )
}

export default withRouter(PayrollDetail)
