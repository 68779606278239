import gql from 'graphql-tag'

export const GET_TOP_DETAIL_ORG_STRUCTURE = gql`
  query($lead: Int!, $organization: Int!) {
    lead: company_job_profiles(where: {id: {_eq: $lead}}, limit: 5) {
      id
      title
      as_assistant
      line_type
      company_employee_rank {
        id
        name
        color_hex
      }
      people_work_placements(
        where: {status: {_eq: "ACTIVE"}, global_user: {status: {_eq: "ACTIVE"}}}
      ) {
        id
        regno
        global_user {
          id
          name
          avatar
        }
      }
      job_profile_children_aggregate(
        limit: 1
        where: {organization: {_eq: $organization}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_CHILD_DETAIL_ORG_STRUCTURE = gql`
  query($lead: Int!, $organization: Int!) {
    company_job_profiles(
      where: {parent: {_eq: $lead}, organization: {_eq: $organization}}
    ) {
      id
      title
      parent
      as_assistant
      line_type
      company_employee_rank {
        id
        name
        color_hex
      }
      people_work_placements(
        where: {status: {_eq: "ACTIVE"}, global_user: {status: {_eq: "ACTIVE"}}}
      ) {
        id
        regno
        global_user {
          id
          name
          avatar
        }
      }
      job_profile_children_aggregate(
        limit: 1
        where: {organization: {_eq: $organization}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`
