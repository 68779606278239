import React, {useState} from 'react'
import {ParticipantContainer} from '../ClassificationPageStyles'
import DetailParticipant from './DetailParticipant'
import {EnhancedToolbar} from '../../../../GlobalStyles'
import {dateMinENOrUS} from '../../../../utils/helpers'
import {
  Typography,
  Divider,
  Paper,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import {Magnify} from 'mdi-material-ui'
import {
  GET_LIST_PARTICIPANT,
  GET_DETAIL_PARTICIPANT,
} from '../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import Loading from '../../../../components/loading/LoadingComponent'
import MaterialTable from 'material-table'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'

const Participant = ({version}) => {
  const [search, setSearch] = useState('')
  const [openDetail, setOpenDetail] = useState(false)
  const [idDetail, setIdDetail] = useState(null)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const {data, error, loading} = useQuery(GET_LIST_PARTICIPANT, {
    wlb_skipPatch: true,
    variables: {
      version: version,
      search: `%${search}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const {data: dataDetail, loading: loadingDetail} = useQuery(
    GET_DETAIL_PARTICIPANT,
    {
      skip: !openDetail,
      variables: {
        id: idDetail,
      },
    }
  )

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{JSON.stringify(error)}</div>
  }

  const handleDetail = (status, id) => {
    if (status === 'open') {
      setIdDetail(id)
      setOpenDetail(true)
    } else {
      setIdDetail(null)
      setOpenDetail(false)
    }
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const column = [
    {
      title: 'Nomor',
      field: 'id',
      defaultSort: 'asc',
      render: rowData => {
        return (
          <>
            <p className="p-black">{rowData.tableData.id + 1}</p>
          </>
        )
      },
    },
    {
      title: 'Posisi',
      field: 'company_job_profile.title',
      render: rowData => {
        return (
          <>
            <p className="p-black">
              {rowData?.company_job_profile?.title ?? ''}
            </p>
            <p className="p-grey">{rowData?.company_job_profile?.code}</p>
          </>
        )
      },
    },
    {
      title: 'Tanggal Mulai',
      field: 'committee_date_start',
      render: rowData => {
        return (
          <>
            <p className="p-black">
              {rowData.committee_date_start
                ? dateMinENOrUS(rowData.committee_date_start)
                : '-'}
            </p>
          </>
        )
      },
    },
    {
      title: 'Tanggal Selesai',
      field: 'committee_date_end',
      render: rowData => {
        return (
          <>
            <p className="p-black">
              {rowData.committee_date_end
                ? dateMinENOrUS(rowData.committee_date_end)
                : '-'}
            </p>
          </>
        )
      },
    },
    {
      title: 'Aksi',
      field: 'action',
      render: rowData => {
        return (
          <>
            <p
              className="p-blue"
              onClick={() => handleDetail('open', rowData.id)}
            >
              Rincian
            </p>
          </>
        )
      },
    },
  ]

  return (
    <ParticipantContainer>
      <Paper>
        <EnhancedToolbar>
          <Typography variant="h6" color="primary">
            Peserta
          </Typography>
        </EnhancedToolbar>
        <Divider />
        <div className="table-container">
          <TextField
            margin="normal"
            variant="outlined"
            fullWidth
            onChange={event => setSearch(event.target.value)}
            placeholder="Cari"
            InputProps={{
              startAdornment: (
                <InputAdornment className="ml-0" position="start">
                  <Magnify className="icon-search" />
                </InputAdornment>
              ),
            }}
          />
          <MaterialTable
            options={{
              ...TableOptions,
              selection: false,
              showTitle: false,
              toolbar: false,
              emptyRowsWhenPaging: false,
            }}
            isLoading={loading}
            localization={{
              pagination: {...TableLocalization.pagination},
              body: {
                emptyDataSourceMessage: <NoDataListComponent search={search} />,
              },
            }}
            columns={column}
            data={data.talent_candidate_groups}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            totalCount={data.total.aggregate.count}
            page={pagination.offset}
            pageSize={pagination.limit}
          />
        </div>
      </Paper>
      <DetailParticipant
        handleClose={() => handleDetail('close')}
        open={openDetail}
        loading={loadingDetail}
        dataDetail={dataDetail}
      />
    </ParticipantContainer>
  )
}

export default Participant
