import {useApolloClient, useMutation} from '@apollo/react-hooks'
import {
  Divider,
  Paper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {withStyles} from '@material-ui/styles'
import {useSnackbar} from 'notistack'
import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {FormContainer} from '../../../../GlobalStyles'
import {
  ADD_NEW_WORK_SCHEDULE,
  UPDATE_WORK_SCHEDULES,
} from '../../../../graphql/mutations'
import {GET_ATT_POLICY_NAME} from '../../../../graphql/queries'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {dayConstants, scheduleDatas} from './Constants'
import {manipulateFilter} from './filterDataAssignment'
import FormToolbarWS from './form/FormToolbarWorkscheduleComponent'
import TabAssign from './form/TabsAssignComponent'
import TabsCheckin from './form/TabsCheckinComponent'
import TabsDetailAdd from './form/TabsDetailAddComponent'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  content: {
    minWidth: 850,
    minHeight: 750,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-left',
    alignItems: 'flex-left',
    padding: 25,
  },
  watchLaterIcon: {
    color: '#014a62',
    width: 126,
    height: 126,
  },
  whiteTextButton: {
    color: '#fff',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 17,
    marginBottom: 17,
  },
  blackTextButton: {
    color: '#000',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 15,
  },
  stepIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  detailActivityWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 41,
    paddingLeft: 81,
    paddingRight: 88,
    paddingBottom: 82,
  },
  detailActivityLabel: {
    color: '#014a62',
    fontSize: 15,
    fontWeight: 600,
  },
  detailActivityTitle: {
    color: '#000000',
    fontSize: 17,
    fontWeight: 600,
  },
  editTemplateListsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  editTemplateListsTitle: {
    color: '#000',
    fontSize: 15,
  },
  editTemplateListsSpacer: {
    marginTop: 30,
  },
  stepIcon: {
    color: '#014962',
    width: 97,
    height: 97,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    minHeight: '70vh',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detail: {
    color: '#007fb2',
    cursor: 'pointer',
  },
  customDatePicker: {
    width: 160,
  },

  tableWrapper: {
    overflowX: 'auto',
  },
  buttonAddWorkSchedule: {
    marginTop: 43,
  },
  greyText: {
    color: '#a9a8a8',
  },
  expandableHeading: {
    color: '#014a62',
    fontWeight: 'bold',
  },
  expandableIcon: {
    color: '#014a62',
  },
  outlinedInput: {
    padding: '10px 14px',
  },
  outlinedTimeInput: {
    padding: '6px 14px',
  },
  buttonSpacer: {marginRight: 12},
  helperText: {
    color: 'rgb(169, 168, 168)',
    width: '40vw',
    padding: '.5rem 0 0 1.85rem',
    marginBottom: 12,
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },

  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14, minHeight: 54},
}))

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

function FormWorkSchedule(props) {
  const _schedule = props.schedule
  const isEdit = _schedule
  const classes = useStyles()
  const client = useApolloClient()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  // const [method, setMethod] = useState('standard')

  const [schedules, setSchedules] = useState(
    isEdit ? _schedule.schedules : scheduleDatas
  )
  const [lateTolerance, setLateTolerance] = useState('')

  const [isFlexi, setFlexi] = useState(0)

  const [locationOption, setLocationOption] = useState('null')
  const {enqueueSnackbar} = useSnackbar()

  const [isIncludePhoto, setIncludePhoto] = useState(true)
  const [allowedMoreOnce, setAllowedMoreOnce] = useState(true)
  const [initialClockIn, setInitialClockIn] = useState('')
  const [initialClockOut, setInitialClckOut] = useState('')

  const [selectedLocations, setSelectedLocations] = useState([])

  const [selectedZone, setSelectedZone] = useState('null')

  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [tabsValue, setTabsValue] = useState(0)
  const [addPolicy] = useMutation(ADD_NEW_WORK_SCHEDULE)
  const [updatePolicy] = useMutation(UPDATE_WORK_SCHEDULES)
  const scheduleChecked = schedules.map(res => res.checked)
  const scheduleRecess = schedules.map(res => res.recess)
  const falseChecker = arr => arr.every(v => v === 0 || !v)
  const [isExist, setIsExist] = useState(false)

  const [filter, setFilter] = useState({
    filterJobFunction: {},
    filterJobFamily: {},
    filterJobTitle: {},
    filterOrgUnit: {},
    filterOrgLevel: {},
    filterJobLevel: {},
    filterJobGrade: {},
    filterOffice: {},
    filterPosition: {},
  })

  const [countApplied, setCountApplied] = useState(null)
  const [countLoc, setCountLoc] = useState(null)
  const [isUpdatePosition, setIsUpdatePosition] = useState(false)

  const setPrevLoc =
    (isEdit &&
      _schedule.time_attendance_policy_locations?.map(
        loc => loc.time_attendance_location.id
      )) ||
    []

  useEffect(() => {
    if (_schedule) {
      setName(_schedule.name)
      setDescription(_schedule.description)
      setIncludePhoto(_schedule.mobile_setting?.isIncludePhoto)
      setAllowedMoreOnce(_schedule.mobile_setting?.isClockInMoreThenOne)
      setSelectedZone(
        _schedule.mobile_setting?.timezone
          ? `${_schedule.mobile_setting.timezone}~${_schedule.mobile_setting.timezoneName}`
          : '7~SE Asia Standard Time'
      )
      const prevSchedules = dayConstants.map(schedule => {
        if (!(schedule.name === 'saturday' || schedule.name === 'sunday')) {
          schedule.checked = 1
        }
        schedule.clockIn = '-'
        schedule.clockOut = '-'
        return schedule
      })
      const dataSchedules = isFlexi ? prevSchedules : dayConstants
      const arr = dataSchedules.map(c => {
        const mc = c
        mc.checked = 0
        schedules.map(s => {
          if (c.name === s.name) {
            mc.checked = 1
            mc.clockIn = s.clockIn
            mc.clockOut = s.clockOut
            mc.recess = s.recess
          }
        })
        return mc
      })
      setSchedules(arr)
      setInitialClockIn(_schedule.mobile_setting?.initLimitClockIn || 0)
      setInitialClckOut(_schedule.mobile_setting?.initLimitClockOut || 0)
      setLateTolerance(_schedule.late_tolerance || 0)
      setSelectedLocations(setPrevLoc)
      setLocationOption(_schedule.location_option)
      setFlexi(_schedule.flexi || 0)
      if (_schedule.filter_position) {
        const dataFilter = _schedule.filter_position
        const _newFilter = {}
        const isFilterExist = (data, stateFilter) => {
          let inOrNin = {_in: dataFilter[data]}
          if (data.includes('exclude')) {
            inOrNin = {_nin: dataFilter[data]}
          }
          if (inOrNin._in || inOrNin._nin) {
            _newFilter[stateFilter] = {..._newFilter[stateFilter], ...inOrNin}
          }
        }

        isFilterExist('filter_exclude_org_unit', 'filterOrgUnit')
        isFilterExist('filter_include_org_unit', 'filterOrgUnit')
        isFilterExist('filter_include_org_level', 'filterOrgLevel')
        isFilterExist('filter_exclude_org_level', 'filterOrgLevel')
        isFilterExist('filter_exclude_position', 'filterPosition')
        isFilterExist('filter_include_position', 'filterPosition')
        isFilterExist('filter_exclude_job_title', 'filterJobTitle')
        isFilterExist('filter_include_job_title', 'filterJobTitle')
        isFilterExist('filter_exclude_job_level', 'filterJobLevel')
        isFilterExist('filter_include_job_level', 'filterJobLevel')
        isFilterExist('filter_exclude_office', 'filterOffice')
        isFilterExist('filter_include_office', 'filterOffice')
        isFilterExist('filter_exclude_job_grade', 'filterJobGrade')
        isFilterExist('filter_include_job_grade', 'filterJobGrade')
        setFilter(_newFilter)
      }
    }
  }, [_schedule])

  function handleCheckbox(event, type, identifier) {
    const val = event.target.value
    const enabled = event.target.checked

    let prevSchedules = schedules

    switch (type) {
      case 'checkbox': {
        prevSchedules = prevSchedules.map(schedule => {
          if (schedule.name === val) {
            schedule.checked = enabled === false ? 0 : 1
          }
          return schedule
        })
        setSchedules(prevSchedules)
        break
      }
      case 'clockIn': {
        prevSchedules = prevSchedules.map(schedule => {
          if (schedule.name === identifier) {
            schedule.clockIn = val
          }
          return schedule
        })
        setSchedules(prevSchedules)
        break
      }
      case 'clockOut': {
        prevSchedules = prevSchedules.map(schedule => {
          if (schedule.name === identifier) {
            schedule.clockOut = val
          }
          return schedule
        })
        setSchedules(prevSchedules)
        break
      }
      case 'recess': {
        prevSchedules = prevSchedules.map(schedule => {
          if (schedule.name === identifier) {
            schedule.recess = val
          }
          return schedule
        })

        setSchedules(prevSchedules)
        break
      }
      case 'flexi': {
        if (enabled) {
          const checked = 1

          prevSchedules = prevSchedules.map(schedule => {
            if (!(schedule.name === 'saturday' || schedule.name === 'sunday')) {
              schedule.checked = checked
            }
            schedule.clockIn = '-'
            schedule.clockOut = '-'
            return schedule
          })
          setSchedules(prevSchedules)
          setInitialClockIn('')
          setInitialClckOut('')
          setLateTolerance('')
          setFlexi(checked)
        } else {
          const checked = 0
          setSchedules(scheduleDatas)
          setFlexi(checked)
        }
        break
      }
    }
  }

  const handleClickOpen = type => {
    setConfirmType(type)
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  const stepOneVal = attName =>
    name === '' ||
    selectedZone === 'null' ||
    falseChecker(scheduleChecked) ||
    scheduleRecess.includes('') ||
    attName.time_attendance_policies.length > 0 ||
    (isFlexi !== 1 &&
      (initialClockIn === '' || initialClockOut === '' || lateTolerance === ''))

  const stepTwoVal =
    locationOption === 'null' ||
    (locationOption === 1 && selectedLocations.length === 0)

  const stepThreeVal = !countApplied || countApplied === 0

  const handleNext = async () => {
    const setError = tabs => {
      if (tabs) {
        setTabsValue(tabs)
      }
      const ids = document.getElementById('top')
      ids.scrollIntoView({behavior: 'smooth'})
      setIsError(true)
      setTimeout(() => setIsError(false), 6000)
    }
    const {data: attName} = await client.query({
      query: GET_ATT_POLICY_NAME,
      variables: {name, ids: isEdit ? [_schedule.id] : []},
    })
    if (attName.time_attendance_policies.length > 0) {
      setIsExist(true)
      setTimeout(() => setIsExist(false), 3000)
    }
    if (isEdit) {
      // logic button edit
      if (stepOneVal(attName)) {
        setError(0)
      } else if (stepTwoVal) {
        setError(1)
      } else if (stepThreeVal) {
        setError(2)
      } else {
        handleClickOpen('edit')
      }
    } else {
      // logic button add
      if (
        (tabsValue === 0 && stepOneVal(attName)) ||
        (tabsValue === 1 && stepTwoVal) ||
        (tabsValue === 2 && stepThreeVal)
      ) {
        setError()
      } else {
        if (steps.length - tabsValue === 1) {
          handleClickOpen('add')
        } else {
          setTabsValue(prev => prev + 1)
        }
      }
    }
  }

  const handleBack = () => {
    setTabsValue(prev => prev - 1)
  }

  const history = () => {
    props.history.push({
      pathname: '/time/attendance/work_schedule',
      state: {searchable: true, active: 'work-schedule'},
    })
  }

  const handleMutation = () => {
    const schedulesTransform = {}
    for (const sch of schedules) {
      if (sch.checked === 1) {
        schedulesTransform[sch.name] = {
          clockIn: sch.clockIn,
          clockOut: sch.clockOut,
          recess: sch.recess,
          timezone: parseFloat(selectedZone.split('~')[0]),
          timezoneName: selectedZone.split('~')[1]
            ? selectedZone.split('~')[1]
            : '-',
        }
      }
    }

    const variables = {
      name,
      description,
      // type: method,
      type: isEdit ? _schedule.type : 'standard',
      flexy: isFlexi,
      lateTolerance: isFlexi === 1 ? 0 : lateTolerance,
      labelColor: '#000000',
      textColor: '#ffffff',
      schedule: schedulesTransform,
      locationOption,
      appliedLocations:
        locationOption === 2
          ? {}
          : {
              location: selectedLocations,
            },
      mobileSetting: {
        isIncludePhoto: isIncludePhoto,
        initLimitClockIn: parseInt(initialClockIn),
        initLimitClockOut: parseInt(initialClockOut),
        isClockInMoreThenOne: allowedMoreOnce,
        // isClockInMoreThenOne: false,
        timezone: parseFloat(selectedZone.split('~')[0]),
        timezoneName: selectedZone.split('~')[1]
          ? selectedZone.split('~')[1]
          : '-',
      },
      filter: manipulateFilter(filter),
    }

    if (isEdit) {
      variables.id = _schedule.id
      variables.isUpdatePosition = isUpdatePosition
    }

    ;(isEdit ? updatePolicy({variables}) : addPolicy({variables}))
      .then(() => {
        enqueueSnackbar(`Success ${confirmType} data`, {variant: 'success'})
        history()
      })
      .catch(() => {
        // console.log(e)
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
        })
      })
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const steps = ['Details Policy', 'Check In Location', 'Assign Position']

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <TabsDetailAdd
            name={name}
            setName={setName}
            classes={classes}
            isError={isError}
            isExist={isExist}
            description={description}
            setDescription={setDescription}
            setSelectedZone={setSelectedZone}
            selectedZone={selectedZone}
            isIncludePhoto={isIncludePhoto}
            setIncludePhoto={setIncludePhoto}
            allowedMoreOnce={allowedMoreOnce}
            setAllowedMoreOnce={setAllowedMoreOnce}
            falseChecker={falseChecker}
            scheduleChecked={scheduleChecked}
            scheduleRecess={scheduleRecess}
            schedules={schedules}
            handleCheckbox={handleCheckbox}
            isFlexi={isFlexi}
            initialClockIn={initialClockIn}
            setInitialClockIn={setInitialClockIn}
            initialClockOut={initialClockOut}
            setInitialClckOut={setInitialClckOut}
            lateTolerance={lateTolerance}
            setLateTolerance={setLateTolerance}
          />
        )
      case 1:
        return (
          <TabsCheckin
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            count={countLoc}
            setCount={setCountLoc}
            isError={isError}
            locationOption={locationOption}
            setLocationOption={setLocationOption}
            isEdit={isEdit}
            setPrevLoc={setPrevLoc}
            setIsUpdatePosition={setIsUpdatePosition}
          />
        )
      case 2:
        return (
          <TabAssign
            variables={filter}
            setVars={setFilter}
            isError={isError}
            count={countApplied}
            setCount={setCountApplied}
            policyId={isEdit && _schedule.id}
            setIsUpdatePosition={setIsUpdatePosition}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <FormToolbarWS
          isEdit={isEdit}
          handleClickOpen={handleClickOpen}
          tabsValue={tabsValue}
          steps={steps}
          handleNext={handleNext}
          handleBack={handleBack}
        />
        {isEdit ? (
          <Tabs
            value={tabsValue}
            onChange={handleChangeTabs}
            indicatorColor="secondary"
            classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
          >
            {steps.map((s, i) => (
              <Tab label={s} key={i} className={classes.tabRoot} />
            ))}
          </Tabs>
        ) : (
          <>
            {' '}
            <Divider style={{marginTop: 7}} />
            <Stepper
              activeStep={tabsValue}
              alternativeLabel
              connector={<CustomConnector />}
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.iconRoot,
                        text: classes.iconText,
                        active: classes.iconActive,
                        completed: classes.iconActive,
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </>
        )}
        <FormContainer id="top">{getStepContent(tabsValue)}</FormContainer>
      </Paper>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={name}
        feature="Work Schedule"
        mutation={confirmType === 'discard' ? history : handleMutation}
      />
    </div>
  )
}

export default withRouter(FormWorkSchedule)
