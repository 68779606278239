import React, {useState} from 'react'
import {
  ContentExpansion,
  WrapperAction,
  // WrapperIconDrag,
  ContentQuestionExpansion,
  HeaderQuestion,
  ActionWrapper,
  ActionButton,
} from './SurveyCenterStyled'
// import DehazeIcon from '@material-ui/icons/Dehaze'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {Menu, MenuItem, TextField} from '@material-ui/core'
import './survey.style.css'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {
  UPDATE_SURVEY_QUESTION,
  DELETE_SURVEY_QUESTION,
} from '../../../../graphql/mutations/survey/multirater-nps/survey-center/surveyCenter.mutation'
import DeletePopup from '../../../shared-components/popup/DeletePopup'

export default function QuestionItem({data, question, index, setListData}) {
  const {enqueueSnackbar} = useSnackbar()
  const [open, setOpen] = useState({
    menu: false,
    anchorEl: null,
    edit: false,
    delete: false,
  })
  const [value, setValue] = useState(question)
  const [updateQuestion] = useMutation(UPDATE_SURVEY_QUESTION)
  const [deleteQuestion] = useMutation(DELETE_SURVEY_QUESTION)

  const handleUpdateQuestion = () => {
    updateQuestion({
      variables: {
        id: data.id,
        qself: value,
        qothers: '',
      },
    })
      .then(() => {
        // setListData([])
        setListData()
        setOpen({...open, edit: false})
        enqueueSnackbar('Success Update question', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Update question error, please try again', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleDelete = () => {
    deleteQuestion({
      variables: {
        id: data.id,
      },
    })
      .then(() => {
        // setListData([])
        setListData()
        setOpen({...open, delete: false})
        enqueueSnackbar('Success Delete question', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Delete question error, please try again', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  return (
    <>
      {open.edit ? (
        <ContentQuestionExpansion>
          <HeaderQuestion>Tambah Pertanyaan Baru</HeaderQuestion>
          <TextField
            onChange={e => setValue(e.target.value)}
            label="Pertanyaan"
            variant="outlined"
            value={value}
          />
          <ActionWrapper>
            <ActionButton
              onClick={() => setOpen({...open, edit: false})}
              style={{color: '#cb2519'}}
            >
              Batal
            </ActionButton>
            <ActionButton
              variant="contained"
              color="secondary"
              onClick={handleUpdateQuestion}
            >
              Ubah Pertanyaan
            </ActionButton>
          </ActionWrapper>
        </ContentQuestionExpansion>
      ) : (
        <ContentExpansion>
          {' '}
          {question}
          <WrapperAction>
            <MoreHorizIcon
              aria-controls={`simple-menu-${index}`}
              onClick={event =>
                setOpen({...open, menu: true, anchorEl: event.currentTarget})
              }
              style={{cursor: 'pointer'}}
            />
          </WrapperAction>
        </ContentExpansion>
      )}

      <Menu
        aria-controls={`simple-menu-${index}`}
        anchorEl={open.anchorEl}
        open={open.menu}
        onClose={() => setOpen({...open, menu: false, anchorEl: null})}
        classes={{
          paper: 'menu-survey-paper',
        }}
      >
        <MenuItem
          onClick={() =>
            setOpen({...open, edit: true, menu: false, anchorEl: null})
          }
        >
          Ubah
        </MenuItem>
        <MenuItem
          style={{color: '#ef4d5e'}}
          onClick={() =>
            setOpen({...open, delete: true, menu: false, anchorEl: null})
          }
        >
          Hapus
        </MenuItem>
      </Menu>

      <DeletePopup
        open={open.delete}
        name={`Pertanyaan "${question}"`}
        handleClose={() => setOpen({...open, delete: false})}
        mutation={handleDelete}
      />
    </>
  )
}
