import React, {useState, useEffect} from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import DoughnutCardWidget from '../../components/widgets/doughnut/DoughnutCardComponent'
import WidgetSection from '../../components/widgets/WidgetSection'
import WidgetTotalComponent from '../../components/widgets/total/TotalCardComponent'
import {COMPANY_ID} from '../../utils/globals'
import SkeletonChartDoughnut from './SkeletonChartDoughnut'

// query
import {
  COUNT_DASHBOARD,
  COUNT_JOIN_EMPLOYEE,
  GET_PERFORMANCE_DASHBOARD,
  GET_ORGANIZATION_DROPDOWN,
} from '../../graphql/queries'

// styling
import {
  DashboardPage,
  ContainerDashboard,
  WrapperDashboard,
  WrapperWidgetDashboard,
  WrapperChart,
} from './DashboardStyles'
import AttendanceChart from './chart/attendance/AttendanceChart'
import GenderComposition from './chart/gender/GenderComposition'
import OrganizationAndPoisition from './chart/organization-and-position/OrganizationAndPoisition'
import LocationAndEducation from './chart/location-and-education/LocationAndEducation'
import PerformanceChart from './chart/performance-chart/PerformanceChart'
import LineChartComponent from './chart/line/LineChartComponent'

const Dashboard = () => {
  const client = useApolloClient()
  const [dataCount, setDataCount] = useState({})
  const [dataActive, setDataActive] = useState({})
  const [selectedOrg, setSelectedOrg] = useState(null)
  const [dataPerformance, setDataPerformance] = useState({})
  const [loadingPerformance, setLoadingPerformance] = useState(false)
  const [totalEmployee, setTotalEmployee] = useState(undefined)
  const [dataOrgDropdown, setDataOrgDropdown] = useState([])
  const [orgDropdown, setOrgDropdown] = useState(null)
  const [searchOrgDropdown, setSearchOrgDropdown] = useState('')

  const {
    data: orgDropdownList,
    loading: orgDropdownLoading,
    error: orgDropdownError,
  } = useQuery(GET_ORGANIZATION_DROPDOWN, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      company: COMPANY_ID,
      search: `%${searchOrgDropdown}%`,
      limit: 20,
      offset: 0,
    },
  })

  useEffect(() => {
    ;(async () => {
      await getData()
    })()

    document.title = `SmartASN - Administrator`
    return () => {
      document.title = 'SmartASN - Administrator'
    }
  }, [])

  useEffect(() => {
    getDataPerformance()
  }, [selectedOrg])

  useEffect(() => {
    if (orgDropdownList) {
      let _org = orgDropdownList.org_dropdown
      _org = _org.map(opt => ({
        value: opt.id,
        label: `${opt.name}`,
      }))
      const allOrg = {
        value: 0,
        label: 'Semua Organisasi',
      }

      setDataOrgDropdown([allOrg, ..._org])
    }
  }, [orgDropdownList])

  if (orgDropdownError !== undefined) {
    return <div>{orgDropdownError}</div>
  }

  const getData = async () => {
    const date = new Date()
    const year = date.getFullYear()

    const {loading, data} = await client.query({
      query: COUNT_DASHBOARD,
      variables: {
        company: COMPANY_ID,
      },
    })

    const {loading: load, data: data_active} = await client.query({
      query: COUNT_JOIN_EMPLOYEE,
      variables: {
        from: `${year - 4}-01-01`,
        to: `${year}-12-31`,
      },
    })

    await getDataPerformance()

    if (!loading && data) {
      setDataCount(data)
      setTotalEmployee(data?.total_employee?.aggregate?.count)
    }
    if (!load && data_active) {
      setDataActive(data_active?.getJoinEmployee)
    }
  }

  const getDataPerformance = async () => {
    const {
      loading: load_performance,
      data: data_performance,
    } = await client.query({
      query: GET_PERFORMANCE_DASHBOARD,
      variables: {
        company: COMPANY_ID,
        organization: selectedOrg === 0 ? null : selectedOrg,
      },
    })

    if (!load_performance && data_performance) {
      setLoadingPerformance(false)
      setDataPerformance({
        todo_goal: data_performance.all_goal_todo.aggregate.total_all_goal_todo,
        complete_goal:
          data_performance.all_goal_complete.aggregate.total_all_goal_complete,
        complete_task:
          data_performance.all_task_completed.aggregate
            .total_all_task_completed,
        todo_task: data_performance.all_task_todo.aggregate.total_all_task_todo,
        inprogress_task:
          data_performance.all_task_inprogress.aggregate
            .total_all_task_inprogress,
        done_task: data_performance.all_task_done.aggregate.total_all_task_done,
      })
    }
  }

  const dataTurnOverEmployee = []
  const dataTurnOverEmployeeYearly = []
  let yearlyCount = 0
  if (dataActive.turnover) {
    for (let i = 0; i < dataActive.turnover.length; i++) {
      dataTurnOverEmployee.push(dataActive.turnover[i].count)
      yearlyCount += dataActive.turnover[i].count
      if ((i + 1) % 12 === 0) {
        dataTurnOverEmployeeYearly.push(
          ((yearlyCount / totalEmployee) * 100).toFixed(0)
        )
        yearlyCount = 0
      }
    }
  }

  const calculatePercent = (data, dataTotal) => {
    return Math.round((data / dataTotal) * 100) || 0
  }

  const color = ['#039be5', '#4caf50', '#ffa000', '#ef4d5e']

  const dataChartTenure = {
    title: 'Masa Jabatan',
    labels: ['0 - 1 Tahun', '1 - 3 Tahun', '3 - 5 Tahun', '5+ Tahun'],
    datasets: [
      {
        data: [
          dataActive.exp1 || 0,
          dataActive.exp2 || 0,
          dataActive.exp3 || 0,
          dataActive.exp4 || 0,
        ],
        dataLabel: [
          calculatePercent(
            dataActive?.exp1 || 0,
            dataActive?.total_data_exp || 0
          ) +
            '%' +
            `(${dataActive?.exp1 || 0})`,
          calculatePercent(
            dataActive?.exp2 || 0,
            dataActive?.total_data_exp || 0
          ) +
            '%' +
            `(${dataActive?.exp2 || 0})`,
          calculatePercent(
            dataActive?.exp3 || 0,
            dataActive?.total_data_exp || 0
          ) +
            '%' +
            `(${dataActive?.exp3 || 0})`,
          calculatePercent(
            dataActive?.exp4 || 0,
            dataActive?.total_data_exp || 0
          ) +
            '%' +
            `(${dataActive?.exp4 || 0})`,
        ],
        backgroundColor: color,
        hoverBackgroundColor: color,
      },
    ],
    aggregate: {
      label: 'Tahun',
      value: dataActive.avg_exp ? dataActive.avg_exp.toFixed(0) : 0,
    },
  }

  const dataChartAvgAge = {
    title: 'Usia',
    labels: ['18 - 24 Tahun', '25 - 34 Tahun', '35 - 49 Tahun', '50+ Tahun'],
    datasets: [
      {
        data: [
          dataActive.age1 || 0,
          dataActive.age2 || 0,
          dataActive.age3 || 0,
          dataActive.age4 || 0,
        ],
        dataLabel: [
          calculatePercent(
            dataActive?.age1 || 0,
            dataActive?.total_data_age || 0
          ) +
            '%' +
            `(${dataActive?.age1 || 0})`,
          calculatePercent(
            dataActive?.age2 || 0,
            dataActive?.total_data_age || 0
          ) +
            '%' +
            `(${dataActive?.age2 || 0})`,
          calculatePercent(
            dataActive?.age3 || 0,
            dataActive?.total_data_age || 0
          ) +
            '%' +
            `(${dataActive?.age3 || 0})`,
          calculatePercent(
            dataActive?.age4 || 0,
            dataActive?.total_data_age || 0
          ) +
            '%' +
            `(${dataActive?.age4 || 0})`,
        ],
        backgroundColor: color,
        hoverBackgroundColor: color,
      },
    ],
  }

  const handleSelectOrg = event => {
    setLoadingPerformance(true)
    setSelectedOrg(event.value)
    setOrgDropdown(event)
  }

  return (
    <DashboardPage>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: 'auto',
        }}
      >
        <ContainerDashboard style={{width: '80%'}}>
          {/* Total Widget */}
          <WrapperDashboard>
            <WrapperWidgetDashboard>
              <WidgetTotalComponent
                color="#014a62"
                icon="people"
                title="Pegawai"
                value={
                  dataCount.total_employee
                    ? dataCount.total_employee.aggregate.count
                    : 0
                }
                link="/"
              />
            </WrapperWidgetDashboard>
            <WrapperWidgetDashboard>
              <WidgetTotalComponent
                color="#039be5"
                icon="device_hub"
                title="Organisasi"
                value={
                  dataCount.total_ou ? dataCount.total_ou.aggregate.count : 0
                }
                link="/"
              />
            </WrapperWidgetDashboard>
            <WrapperWidgetDashboard>
              <WidgetTotalComponent
                color="#ffa000"
                icon="accessibility_new"
                title="Jabatan"
                value={
                  dataCount.total_position
                    ? dataCount.total_position.aggregate.count
                    : 0
                }
                link="/"
              />
            </WrapperWidgetDashboard>
          </WrapperDashboard>

          {/* ORGANIZATIOAND POSITION */}
          <OrganizationAndPoisition />
        </ContainerDashboard>

        {/* Gender Widget */}
        <ContainerDashboard style={{width: '20%', height: '100%'}}>
          <WrapperWidgetDashboard>
            <WidgetSection fontSizeTitle={15} title="Komposisi Jenis Kelamin">
              <WrapperChart>
                {/* <DoughnutCardWidget data={datachartGender} labelType="column" /> */}
                <GenderComposition
                  totalMale={dataCount?.male_aggregate?.aggregate?.count || 0}
                  totalFemale={
                    dataCount?.female_aggregate?.aggregate?.count || 0
                  }
                />
              </WrapperChart>
            </WidgetSection>
          </WrapperWidgetDashboard>
        </ContainerDashboard>
      </div>

      <ContainerDashboard style={{width: '100%', paddingBottom: '3rem'}}>
        {/* Tenure & Average Age */}
        <WrapperDashboard>
          <WrapperWidgetDashboard>
            <WidgetSection title={dataChartAvgAge.title}>
              {Object.keys(dataActive).length > 0 ? (
                <WrapperChart zeroVerticalPadding>
                  <DoughnutCardWidget
                    type="pie"
                    data={dataChartAvgAge}
                    labelType="list"
                  />
                </WrapperChart>
              ) : (
                <SkeletonChartDoughnut />
              )}
            </WidgetSection>
          </WrapperWidgetDashboard>
          <WrapperWidgetDashboard>
            <WidgetSection title={dataChartTenure.title}>
              {Object.keys(dataActive).length > 0 ? (
                <WrapperChart zeroVerticalPadding>
                  <DoughnutCardWidget
                    type="pie"
                    data={dataChartTenure}
                    labelType="list"
                  />
                </WrapperChart>
              ) : (
                <SkeletonChartDoughnut />
              )}
            </WidgetSection>
          </WrapperWidgetDashboard>
        </WrapperDashboard>

        <LineChartComponent />

        <LocationAndEducation />

        <WrapperDashboard style={{flex: '1'}}>
          <WrapperWidgetDashboard>
            <WidgetSection title="Kehadiran">
              <div style={{padding: '19px 20px'}}>
                <AttendanceChart />
              </div>
            </WidgetSection>
          </WrapperWidgetDashboard>
        </WrapperDashboard>

        {/* Performance Widget */}
        <WrapperDashboard>
          <WrapperWidgetDashboard>
            <WidgetSection
              title="Kinerja"
              action={{
                id: 'organization',
                value: orgDropdown,
                onChange: event => handleSelectOrg(event),
                options: dataOrgDropdown,
                isLoading: orgDropdownLoading,
                placeholder: 'Semua Organisasi',
                onInputChange: dataSearchDropdown =>
                  setSearchOrgDropdown(dataSearchDropdown),
              }}
            >
              <PerformanceChart
                loadingPerformance={loadingPerformance}
                dataPerformance={dataPerformance}
              />
            </WidgetSection>
          </WrapperWidgetDashboard>
        </WrapperDashboard>
      </ContainerDashboard>
    </DashboardPage>
  )
}

export default Dashboard
