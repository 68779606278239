import gql from 'graphql-tag'

export const ADD_PERSPECTIVE = gql`
  mutation addPrespective($object: [kpi_perspectives_insert_input!]!) {
    insert_kpi_perspectives(objects: $object) {
      affected_rows
    }
  }
`

export const UPDATE_PERSPECTIVE = gql`
  mutation updatePrespective(
    $code: String
    $name: String
    $description: String
    $status: enum_global_activation_status
    $id: Int
  ) {
    update_kpi_perspectives(
      _set: {code: $code, name: $name, description: $description, status: $status}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const DELETE_PERSPECTIVE = gql`
  mutation updatePrespective($id: Int!) {
    update_kpi_perspectives(
      _set: {deleted_at: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const DELETE_MULTIPLE_PERSPECTIVE = gql`
  mutation updatePrespective($id: [Int!]!) {
    update_kpi_perspectives(
      _set: {deleted_at: "now()"}
      where: {id: {_in: $id}}
    ) {
      affected_rows
    }
  }
`
