import React, {useState} from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'

import {ButtonSubmit, SwitchForm} from '../../SharedDetailEmployee'
import {isErrorForm} from '../ShareComponents-detail/helperDetail'
import {ModalAddEditWraper} from '../../DetailEmployeeStyles'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import FormInput from '../ShareComponents-detail/FormInput'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'

import {
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
  LIST_DEGREE,
  LIST_FACULTY,
  LIST_MAJOR,
  LIST_SCHOOL,
} from '../../../../../../graphql/queries'
import {
  ADD_EDUCATION,
  UPDATE_EDUCATION,
  DELETE_EDUCATION,
} from '../../../../../../graphql/mutations'
import {capitalize} from '../../../../../../utils/helpers'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_FORM_DATA = {
  degree: '',
  other_school: '',
  school: '',
  major: '',
  field_of_study: '',
  from: null,
  to: null,
  is_present: false,
  gpa: '',
  country: null,
  province: null,
  city: null,
  certificate_name: '',
  ceritificate_id: '',
  certificate_date: null,
  description: '',
  attachments: [],
  is_shared: false,
}

const INITIAL_SEARCH_STATE = {
  degree: '',
  school: '',
  major: '',
  faculty: '',
  country: '',
  province: '',
  city: '',
}

const AddEditEducation = ({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
}) => {
  const client = useApolloClient()

  const [formState, setFormState] = useState(STATE_INITIAL)
  const [formData, setFormData] = useState(INITIAL_FORM_DATA)
  const [openDelete, setOpenDelete] = useState(false)

  const [search, setSearch] = useState(INITIAL_SEARCH_STATE)

  const isEditing = actionType === 'edit'

  const isError = formState === STATE_ERROR
  const isDispatching = formState === STATE_DISPATCH

  const {data: dataProvince, loading: loadingProvince} = useQuery(
    GLOBAL_PROVINCES_SEARCHABLE,
    {
      skip: !open,
      variables: {
        search: `%${search.province}%`,
        limit: 20,
        country: formData?.country?.value,
      },
    }
  )

  const {data: dataCity, loading: loadingCity} = useQuery(
    GLOBAL_CITIES_SEARCHABLE,
    {
      skip: !open,
      variables: {
        search: `%${search.city}%`,
        limit: 20,
        province: formData?.province?.value,
      },
    }
  )

  const {data: globalDegree, loading: loadingDegree} = useQuery(LIST_DEGREE, {
    wlb_skipPatch: true,
    skip: !open,
    fetchPolicy: 'cache-first',
    variables: {
      search: `%${search.degree}%`,
      limit: 20,
    },
  })

  const {data: globalSchool, loading: loadingSchool} = useQuery(LIST_SCHOOL, {
    skip: !open,
    fetchPolicy: 'cache-first',
    variables: {
      search: `%${search.school}%`,
      limit: 20,
    },
  })

  const {data: globalMajor, loading: loadingMajor} = useQuery(LIST_MAJOR, {
    skip: !open,
    fetchPolicy: 'cache-first',
    variables: {
      search: `%${search.major}%`,
      limit: 20,
    },
  })

  const {data: globalFaculty, loading: loadingFaculty} = useQuery(
    LIST_FACULTY,
    {
      skip: !open,
      fetchPolicy: 'cache-first',
      variables: {
        search: `%${search.faculty}%`,
        limit: 20,
      },
    }
  )

  React.useEffect(() => {
    if (actionType === 'edit' && open) {
      const educationFields = data.education_fields
      const educationFieldProperties = educationFields
        ? {
            gpa: educationFields?.gpa,
            country: educationFields?.country
              ? {label: educationFields?.country, value: 103}
              : null,
            city: educationFields?.city
              ? {
                  label: educationFields?.city.name,
                  value: educationFields?.city.id,
                }
              : null,
            province: educationFields?.province
              ? {
                  label: educationFields?.province.name,
                  value: educationFields?.province.id,
                }
              : null,
            certificate_name: educationFields?.certificate_name,
            ceritificate_id: educationFields?.ceritificate_id,
            certificate_date: educationFields?.ceritificate_date,
            attachments: educationFields?.attachments,
            is_shared: educationFields?.is_shared,
          }
        : {}

      setFormData(e => ({
        ...e,
        user: userIdDetail,
        other_school: data.other_school,
        degree: data.global_degree
          ? {
              label: data.global_degree.name,
              value: data.global_degree.id,
            }
          : null,
        school: data.company_profile
          ? {
              label: data.company_profile.legal_name,
              value: data.company_profile.id,
            }
          : null,
        major: data.global_major
          ? {label: data.global_major.name, value: data.global_major.id}
          : null,
        field_of_study: data.global_faculty
          ? {
              label: capitalize(data.global_faculty.name),
              value: data.global_faculty.id,
            }
          : null,
        from: data.from,
        to: data.to,
        is_present:
          !data.to || (data.is_present === null ? false : data.is_present),
        description: data.description,
        ...educationFieldProperties,
      }))
    } else {
      setFormData(e => ({
        ...e,
        user: userIdDetail,
      }))
    }
  }, [open, actionType])

  const schoolDegree = globalDegree?.global_degrees
    .filter(degree => {
      if (
        [
          'junior high school',
          'senior high school',
          'elementary school',
        ].includes(degree.name?.toLowerCase())
      )
        return degree
    })
    .map(degree => degree.id)

  const handleInputSearchChange = field => query => {
    setSearch({...search, [field]: query})
  }

  const handleAutocompleteChange = item => {
    const {fieldName, label, value} = item

    if (fieldName === 'country') {
      setFormData({
        ...formData,
        country: {label, value},
        province: null,
        city: null,
      })
    } else if (fieldName === 'province') {
      setFormData({
        ...formData,
        province: {label, value},
        city: null,
      })
    } else if (fieldName === 'degree') {
      if (schoolDegree.includes(value)) {
        setFormData({
          ...formData,
          degree: {label, value},
          school: null,
          major: null,
          field_of_study: null,
        })
      } else {
        setFormData({
          ...formData,
          degree: {label, value},
          other_school: null,
        })
      }
    } else {
      setFormData({
        ...formData,
        [fieldName]: {label, value},
      })
    }
  }

  const handleSubmit = () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const verb = isEditing ? 'hapus' : 'tambah'
    let promise

    const isSchoolDegree = schoolDegree.includes(formData.degree?.value)
    const objectTypeVariable = Object.assign(
      {},
      {
        degree: formData.degree?.value,
        other_school: isSchoolDegree ? formData.other_school : null,
        field_of_study: isSchoolDegree ? null : formData.field_of_study?.value,
        school: isSchoolDegree ? null : formData.school?.value,
        major: isSchoolDegree ? null : formData.major?.value,
        to: formData.is_present ? null : formData.to,
        country: formData.country.label,
        province: {id: formData.province.value, name: formData.province.label},
        city: {id: formData.city.value, name: formData.city.label},
      }
    )

    const variables = {
      user: formData.user,
      is_present: formData.is_present,
      description: formData.description,
      from: formData.from,
      to: objectTypeVariable.to,
      degree: objectTypeVariable.degree,
      other_school: objectTypeVariable.other_school,
      school: objectTypeVariable.school,
      field_of_study: objectTypeVariable.field_of_study,
      major: objectTypeVariable.major,
      education_fields: {
        country: objectTypeVariable.country,
        province: objectTypeVariable.province,
        city: objectTypeVariable.city,
        is_shared: formData.is_shared,
        gpa: formData.gpa,
        certificate_name: formData.certificate_name,
        ceritificate_id: formData.ceritificate_id,
        certificate_date: formData.certificate_date,
        attachments: formData.attachments,
      },
    }

    if (isEditing) {
      promise = client.mutate({
        mutation: UPDATE_EDUCATION,
        variables: {
          id: data?.id,
          object: {...variables},
        },
      })
    } else {
      promise = client.mutate({
        mutation: ADD_EDUCATION,
        variables: {
          objects: {...variables},
        },
      })
    }

    promise.then(
      () => {
        refetch()
        setFormState(STATE_INITIAL)
        enqueueSnackbar(`Pendidikan di${verb}`, {variant: 'success'})

        handleCloseModal()
      },
      err => {
        setFormState(STATE_ERROR)
        console.error(err)
        enqueueSnackbar(
          `Gagal ${verb === 'add' ? 'menambahkan' : 'mengubah'} pendidikan`,
          {
            variant: 'error',
          }
        )
      }
    )
  }

  const handleDeleteConfirm = () => {
    const promise = client.mutate({
      mutation: DELETE_EDUCATION,
      variables: {
        id: data.id,
      },
    })

    promise.then(
      () => {
        refetch()
        handleCloseModal()
        enqueueSnackbar(`Pendidikan telah dihapus`, {variant: 'success'})
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Gagal menghapus pendidikan`, {
          variant: 'error',
        })
      }
    )
  }

  const handleOpenModalDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }

  const handleCloseModalDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  const handleCloseModal = () => {
    setFormState(STATE_INITIAL)
    setFormData(INITIAL_FORM_DATA)
    onClose()
  }

  const fieldsList = [
    {
      label: 'Tingkatan Pendidikan *',
      placeholder: 'Pilih tingkatan pendidikan',
      fieldName: 'degree',
      type: 'autocomplete',
      required: true,
      value: formData?.degree,
      error: !formData?.degree,
      isLoading: loadingDegree,
      onChange: handleAutocompleteChange,
      onInputChange: handleInputSearchChange('degree'),
      option: globalDegree?.global_degrees.map(item => ({
        value: item.id,
        label: item.name,
      })),
    },
    {
      label: 'Sekolah*',
      placeholder: 'Pilih Sekolah',
      fieldName: 'school',
      type: 'autocomplete',
      required: true,
      value: formData?.school,
      error: !formData?.school,
      isLoading: loadingSchool,
      onChange: handleAutocompleteChange,
      onInputChange: handleInputSearchChange('school'),
      option: globalSchool?.company_profiles.map(item => ({
        value: item.id,
        label: item.legal_name,
      })),
    },
    {
      label: 'Jurusan*',
      placeholder: 'Pilih jurusan',
      fieldName: 'major',
      type: 'autocomplete',
      required: true,
      value: formData?.major,
      error: !formData?.major,
      isLoading: loadingMajor,
      onChange: handleAutocompleteChange,
      onInputChange: handleInputSearchChange('major'),
      option: globalMajor?.global_major.map(item => ({
        value: item.id,
        label: item.name,
      })),
    },
    {
      label: 'Fakultas*',
      placeholder: 'Pilih fakultas',
      fieldName: 'field_of_study',
      type: 'autocomplete',
      required: true,
      value: formData?.field_of_study,
      error: !formData?.field_of_study,
      isLoading: loadingFaculty,
      onChange: handleAutocompleteChange,
      onInputChange: handleInputSearchChange('faculty'),
      option: globalFaculty?.global_faculties.map(item => ({
        value: item.id,
        label: capitalize(item.name),
      })),
    },
    {
      label: ['Tanggal Mulai*', 'Tanggal Selesai*'],
      labelDate: 'Saat ini saya sedang belajar disini',
      fieldName: ['from', 'to', 'is_present'],
      type: 'date',
      required: true,
      value: [formData.from, formData.to, formData.is_present, true],
      error: [!formData.from, !formData.to],
    },
    {
      type: 'textfield',
      label: 'Indeks Prestasi Kumulatif (IPK)',
      placeholder: 'Tambahkan IPK',
      fieldName: 'gpa',
      value: formData.gpa,
    },
    {
      type: 'autocomplete',
      label: 'Negara*',
      placeholder: 'Pilih Negara',
      required: true,
      fieldName: 'country',
      value: formData.country,
      option: [
        {
          label: 'Indonesia',
          value: 103,
        },
      ],
      error: !formData.country,
      onChange: handleAutocompleteChange,
      onInputChange: handleInputSearchChange('country'),
    },
    {
      type: 'autocomplete',
      label: 'Provinsi*',
      placeholder: 'Pilih Provinsi',
      required: true,
      fieldName: 'province',
      value: formData.province,
      option: dataProvince?.global_provinces.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData.province,
      isLoading: loadingProvince,
      onChange: handleAutocompleteChange,
      onInputChange: handleInputSearchChange('province'),
    },
    {
      type: 'autocomplete',
      label: 'Kota*',
      placeholder: 'Pilih Kota',
      required: true,
      fieldName: 'city',
      value: formData.city,
      option: dataCity?.global_cities.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData.city,
      isLoading: loadingCity,
      onChange: handleAutocompleteChange,
      onInputChange: handleInputSearchChange('city'),
    },
    {
      type: 'textfield',
      label: 'Ijazah',
      placeholder: 'Tambahkan ijazah',
      fieldName: 'certificate_name',
      value: formData.certificate_name,
    },
    {
      type: 'textfield',
      label: 'Nomor Ijazah',
      placeholder: 'Tambahkan nomor ijazah',
      fieldName: 'ceritificate_id',
      value: formData.ceritificate_id,
    },
    {
      type: 'dateCustom',
      label: ['Tanggal Terbit Ijazah'],
      filter: ['date'],
      fieldName: 'certificate_date',
      value: formData.certificate_date,
    },
    {
      type: 'textfield',
      label: 'Keterangan',
      placeholder: 'Tambahkan Keterangan',
      fieldName: 'description',
      multiline: true,
      rows: 6,
      value: formData.description,
    },
    {
      type: 'attachment',
      fieldName: 'attachments',
      label: 'Lampiran',
      attachments: formData?.attachments,
      multiple: true,
      link: 'url',
    },
  ]

  if (schoolDegree?.includes(formData.degree?.value)) {
    fieldsList[1] = {
      type: 'textfield',
      label: 'Sekolah*',
      placeholder: 'Pilih Sekolah',
      fieldName: 'other_school',
      required: true,
      value: formData.other_school,
      error: !formData.other_school,
    }
    fieldsList[2].label = 'Major'
    fieldsList[2].disabled = true
    fieldsList[2].required = false
    fieldsList[2].error = false

    fieldsList[3].label = 'Faculty'
    fieldsList[3].disabled = true
    fieldsList[3].required = false
    fieldsList[3].error = false
  }

  return (
    <>
      <ModalDetail
        onDelete={isEditing && handleOpenModalDelete}
        onClose={handleCloseModal}
        open={open}
        maxWidth="sm"
        title={`${isEditing ? 'Ubah' : 'Tambahkan'} Pendidikan `}
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Berbagi dengan jaringan:"
            value={formData.is_shared}
            labelPlacement="start"
            onChange={e =>
              setFormData({
                ...formData,
                is_shared: e.target.checked,
              })
            }
          />
        </div>

        <ModalAddEditWraper>
          <FormInput
            open
            fieldsList={fieldsList}
            setValues={setFormData}
            values={formData}
            errorState={isError}
          />
        </ModalAddEditWraper>

        <ButtonSubmit
          disabled={isDispatching}
          onCancel={onClose}
          onSave={handleSubmit}
        />
      </ModalDetail>

      <DeletePopup
        name={data?.company_profile?.legal_name || data?.other_school}
        open={openDelete}
        handleClose={handleCloseModalDelete}
        mutation={handleDeleteConfirm}
      />
    </>
  )
}

export default AddEditEducation
