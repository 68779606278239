import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  GreyTypography,
} from '../../../../GlobalStyles'
import MaterialTable from 'material-table'
import {Link} from 'react-router-dom'
import {
  TableOptions,
  TableLocalization,
} from '../../../shared-components/table/TableOptions'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {GET_TALENT_CLASSIFICATION} from '../../../../graphql/queries'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../utils/globals'
import Loading from '../../../../components/loading/LoadingComponent'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {
  CHANGE_STATUS_TALENT_VERSION,
  CHANGE_VISIBILIY_TALENT_VERSION,
  DELETE_TALENT_CLASSIFICATION,
} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {capitalize} from '../../../../utils/helpers'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'

const ListComponent = props => {
  const {enqueueSnackbar} = useSnackbar()
  const [openDelete, setOpenDelete] = useState(false)
  const [openChanges, setOpenChanges] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [state, setState] = useState({
    id: '',
    name: '',
  })

  const {data, error, refetch} = useQuery(GET_TALENT_CLASSIFICATION, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      company: COMPANY_ID,
      search: `%${props.searchText}%`,
      classification_type: 'TALENT',
    },
  })

  const [deleteClassification] = useMutation(DELETE_TALENT_CLASSIFICATION)
  const [statusClassification] = useMutation(CHANGE_STATUS_TALENT_VERSION)
  const [visibilityClassification] = useMutation(
    CHANGE_VISIBILIY_TALENT_VERSION
  )

  const handleMutation = () => {
    if (confirmType === 'in evaluation' || confirmType === 'complete') {
      const variables = {
        id: state.id,
        status: confirmType === 'complete' ? 'COMPLETED' : 'INEVALUATION',
      }

      statusClassification({variables})
        .then(() => {
          enqueueSnackbar('Data changed', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(err => {
          enqueueSnackbar(err, {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else if (confirmType === 'hide' || confirmType === 'unhide') {
      const variables = {
        id: state.id,
        visibility: confirmType === 'hide' ? 'HIDE' : 'PUBLISH',
      }

      visibilityClassification({variables})
        .then(() => {
          enqueueSnackbar('Data changed', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(err => {
          enqueueSnackbar(err, {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else {
      const variables = {
        id: state.id,
      }

      deleteClassification({variables})
        .then(() => {
          enqueueSnackbar('Data deleted', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(err => {
          enqueueSnackbar(err, {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleClickOpen = (id, name, type) => {
    if (type !== 'delete') {
      setOpenChanges(true)
    } else {
      setOpenDelete(true)
    }
    setState({id, name})
    setConfirmType(type)
  }
  const handleClose = () => {
    if (confirmType !== 'delete') {
      setOpenChanges(false)
    } else {
      setOpenDelete(false)
    }
  }

  const renderStatus = status => {
    return status === 'COMPLETED'
      ? 'Selesai'
      : status === 'INEVALUATION'
      ? 'Di evaluasi'
      : status === 'DRAFT'
      ? 'Draf'
      : status
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Klasifikasi Talenta</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: `/talent/classification/add`,
              state: {active: 'add-classification'},
            }}
          >
            Menambah Klasifikasi Talenta
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider></Divider>
      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0'}}
        columns={[
          {
            title: 'Nama talenta',
            field: 'name',
          },
          {
            title: 'Tanggal Mulai',
            field: 'start_date',
            type: 'date',
          },
          {
            title: 'Tanggal Akhir',
            field: 'end_date',
            type: 'date',
          },
          {
            title: 'Dibuat Oleh',
            field: 'created_by',
            render: rowData => (
              <Typography>
                {rowData.globalUserAdded ? rowData.globalUserAdded.name : ''}
              </Typography>
            ),
          },
          {
            title: 'Terakhir Diedit',
            field: 'last_edited',
            render: rowData => (
              <Typography>
                {rowData.globalUserModified
                  ? rowData.globalUserModified.name
                  : ''}
              </Typography>
            ),
          },
          {
            title: 'Status',
            field: 'status',

            render: rowData => (
              <>
                <Typography
                  style={
                    rowData.status === 'COMPLETED'
                      ? {color: '#4caf50'}
                      : rowData.status === 'INEVALUATION'
                      ? {color: '#039be5'}
                      : {color: '#000'}
                  }
                >
                  {capitalize(renderStatus(rowData.status))}
                </Typography>
                {rowData.visibility === 'HIDE' && (
                  <GreyTypography>(Hidden)</GreyTypography>
                )}
              </>
            ),
          },
          {
            title: 'Aksi',
            field: 'id',
            sorting: false,
            render: rowData => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={{
                          pathname: '/talent/classification/talent-detail',
                          state: {
                            active: 'classification-detail',
                            data: rowData,
                          },
                        }}
                      >
                        Detail
                      </StyledMenuItem>
                      {(rowData.status === 'DRAFT' ||
                        rowData.status === 'ACTIVE' ||
                        rowData.status === 'INACTIVE') && (
                        <StyledMenuItem
                          component={Link}
                          to={{
                            pathname:
                              '/talent/classification/edit/' + rowData.id,
                            state: {active: 'edit-classification'},
                          }}
                          variant="h6"
                        >
                          Ubah
                        </StyledMenuItem>
                      )}
                      {rowData.status === 'DRAFT' && (
                        <StyledMenuItem
                          onClick={() => {
                            handleClickOpen(
                              rowData.id,
                              rowData.name,
                              'in evaluation'
                            )
                            popupState.close()
                          }}
                        >
                          Evaluasi
                        </StyledMenuItem>
                      )}
                      {rowData.status === 'INEVALUATION' && (
                        <StyledMenuItem
                          onClick={() => {
                            handleClickOpen(
                              rowData.id,
                              rowData.name,
                              'complete'
                            )
                            popupState.close()
                          }}
                        >
                          Selesai
                        </StyledMenuItem>
                      )}
                      {rowData.status === 'COMPLETED' &&
                      (!rowData.visibility ||
                        rowData.visibility === 'PUBLISH') ? (
                        <StyledMenuItem
                          onClick={() => {
                            handleClickOpen(rowData.id, rowData.name, 'hide')
                            popupState.close()
                          }}
                        >
                          Sembunyikan
                        </StyledMenuItem>
                      ) : (
                        rowData.status === 'COMPLETED' &&
                        rowData.visibility === 'HIDE' && (
                          <StyledMenuItem
                            onClick={() => {
                              handleClickOpen(
                                rowData.id,
                                rowData.name,
                                'unhide'
                              )
                              popupState.close()
                            }}
                          >
                            Tampilkan
                          </StyledMenuItem>
                        )
                      )}
                      {(rowData.status === 'DRAFT' ||
                        rowData.status === 'ACTIVE' ||
                        rowData.status === 'INACTIVE') && (
                        <StyledMenuItem
                          onClick={() => {
                            handleClickOpen(rowData.id, rowData.name, 'delete')
                            popupState.close()
                          }}
                        >
                          Hapus
                        </StyledMenuItem>
                      )}
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            ),
          },
        ]}
        data={data.talent_versions}
        title=""
        options={{...TableOptions, selection: false}}
        components={{
          Toolbar: () => null,
        }}
        localization={TableLocalization}
      />
      <AddEdit
        open={openChanges}
        handleClose={handleClose}
        header={
          confirmType === 'in evaluation' || confirmType === 'complete'
            ? 'Change status Talent Classification'
            : `${confirmType} Talent Classification`
        }
        body={`Are you sure you want to ${
          confirmType === 'in evaluation' || confirmType === 'complete'
            ? `change this status to ${confirmType}?`
            : `${confirmType} this item?`
        }`}
        button="Proceed"
        mutation={handleMutation}
      />
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        id={state.id}
        name={state.name}
        mutation={handleMutation}
      />
    </PaperContainer>
  )
}

export default ListComponent
