import gql from 'graphql-tag'

export const DELETE_LIST_OFFICE = gql`
  mutation($id: Int) {
    update_company_addresses(
      _set: {deletedAt: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const SET_MAIN_OFFICE = gql`
  mutation($address: Int, $company: uuid) {
    update_company_profiles(
      _set: {address: $address}
      where: {id: {_eq: $company}}
    ) {
      affected_rows
    }
  }
`

export const SET_ACTIVE_INACTIVE_OFFICE = gql`
  mutation($id: Int, $status: String) {
    update_company_addresses(where: {id: {_eq: $id}}, _set: {status: $status}) {
      affected_rows
    }
  }
`

export const EDIT_OFFICE_ADDRESS = gql`
  mutation(
    $id: Int
    $office_code: String
    $office_name: String
    $address: String
    $province: Int
    $city: Int
    $postal_code: Int
    $npwp_number: String
    $npwp_regdate: date
    $npwp_responsible_name: String
    #$bpjs_tk_regno: String
    #$bpjs_kes_regno: String
    $status: String
    $address_field: jsonb
  ) {
    update_company_addresses(
      where: {id: {_eq: $id}}
      _set: {
        office_name: $office_name
        office_code: $office_code
        address: $address
        province: $province
        city: $city
        postal_code: $postal_code
        npwp_number: $npwp_number
        npwp_regdate: $npwp_regdate
        npwp_responsible_name: $npwp_responsible_name
        #bpjs_tk_regno: $bpjs_tk_regno
        #bpjs_kes_regno: $bpjs_kes_regno
        address_field: $address_field
        status: $status
      }
    ) {
      affected_rows
    }
  }
`

export const ADD_OFFICE_ADDRESS = gql`
  mutation($objects: [company_addresses_insert_input!]!) {
    insert_company_addresses(objects: $objects) {
      affected_rows
    }
  }
`

export const IMPORT_OFFICE_ADDRESS = gql`
  mutation($objects: [company_addresses_insert_input!]!) {
    insert_company_addresses(objects: $objects) {
      affected_rows
    }
  }
`
