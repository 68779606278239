import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {useQuery} from '@apollo/react-hooks'
import {
  Dialog,
  Button,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from '@material-ui/core'
import classNames from 'classnames'
import {
  FlexBetween,
  WhiteModalHeader,
  WrapperContainer,
} from '../../../../GlobalStyles'
import helperStyle, {
  BigTextBlackBold,
  CenterWrapper,
  PayrollContainer,
  TextBlackBold,
  TextGreyBold,
} from '../PayrollPageStyles'
import {convertToRoundedRupiah} from '../../../../utils/helpers'
import {COMPANY_NAME, COMPANY_LOGO} from '../../../../utils/globals'
import {
  GET_PAYROLL_PROCESS_SLIP,
  GET_THR_SLIP,
} from '../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../utils'

const ModalPaySlip = ({open, handleClose, employeeId, from}) => {
  const classes = helperStyle()

  const {data: slipData, loading, error} = useQuery(
    from === 'thr' ? GET_THR_SLIP : GET_PAYROLL_PROCESS_SLIP,
    {
      variables: {
        id: employeeId,
      },
      skip: !employeeId,
      ...GQL_FINANCE_OPTIONS,
    }
  )

  const [peopleWork, setPeopleWork] = useState()
  const [peopleWorkSalaries, setPeopleWorkSalaries] = useState([])
  const [employeePlacement, setEmployeePlacement] = useState()
  const [totalEarning, setTotalEarning] = useState()
  const [totalDeduction, setTotalDeduction] = useState()
  const [longestArr, setLongestArr] = useState()
  const [allowances, setAllowances] = useState()
  const [nonFixedComponents, setNonFixedComponents] = useState()
  const [showCompanyComponentTable, setShowCompanyComponentTable] = useState(
    false
  )
  const [companyComponentList, setCompanyComponentList] = useState([])
  const [companyComponentTotal, setCompanyComponentTotal] = useState(0)
  const [companyDeductionList, setCompanyDeductionList] = useState([])
  const [companyDeductionTotal, setCompanyDeductionTotal] = useState(0)
  const [longestCompanyCemponentArr, setLongestCompanyCemponentArr] = useState()

  useEffect(() => {
    const _allowances = []
    const _nonFixedComponents = []
    if (slipData) {
      const _peopleWork =
        from === 'thr'
          ? slipData.people_work_thr_by_pk
          : slipData.people_work_payroll_by_pk

      if (_peopleWork !== null) {
        setPeopleWork(_peopleWork)

        const dataEmployeePlacement =
          from === 'thr'
            ? _peopleWork.people_work_placement
            : _peopleWork.employee_placement

        if (dataEmployeePlacement) setEmployeePlacement(dataEmployeePlacement)

        const tmp = dataEmployeePlacement.people_work_salaries

        if (tmp.length) {
          setPeopleWorkSalaries(tmp[0])
        }

        _allowances.push({
          name: from === 'thr' ? 'THR' : 'Basic Salary',
          value: from === 'thr' ? _peopleWork.thr : _peopleWork.salary,
        })

        if (_peopleWork.allowances.length > 0) {
          _peopleWork.allowances.forEach(item => {
            if (!item.is_postponed) _allowances.push(item)
          })
        }

        if (_peopleWork.non_fixed_components.length > 0) {
          _peopleWork.non_fixed_components.forEach(item => {
            if (!item.is_postponed) _nonFixedComponents.push(item)
          })
        }

        setLongestArr(
          _allowances.length > _nonFixedComponents.length
            ? _allowances
            : _nonFixedComponents
        )
        setAllowances(_allowances)
        setNonFixedComponents(_nonFixedComponents)

        setTotalEarning(
          _allowances.length > 0
            ? _allowances
                .map(item => Math.round(item.value || 0))
                .reduce((prev, next) => prev + next) || 0
            : 0
        )

        setTotalDeduction(
          _nonFixedComponents.length > 0
            ? _nonFixedComponents
                .map(item => Math.round(item.value || 0))
                .reduce((prev, next) => prev + next) || 0
            : 0
        )

        if (_peopleWork.payroll_fields?.CompanyComponents) {
          setCompanyComponentList(
            _peopleWork.payroll_fields.CompanyComponents || []
          )
          setCompanyComponentTotal(
            _peopleWork.payroll_fields.CompanyComponents.map(item =>
              Math.round(item.value || 0)
            ).reduce((prev, next) => prev + next) || 0
          )
        }

        if (_peopleWork.payroll_fields?.CompanyDeductions) {
          setCompanyDeductionList(
            _peopleWork.payroll_fields.CompanyDeductions || []
          )
          setCompanyDeductionTotal(
            _peopleWork.payroll_fields.CompanyDeductions.map(item =>
              Math.round(item.value || 0)
            ).reduce((prev, next) => prev + next) || 0
          )
        }

        setLongestCompanyCemponentArr(
          (_peopleWork.payroll_fields?.CompanyComponents?.length || 0) >
            (_peopleWork.payroll_fields?.CompanyDeductions?.length || 0)
            ? _peopleWork.payroll_fields?.CompanyComponents
            : _peopleWork.payroll_fields?.CompanyDeductions
        )
      }
    }
  }, [slipData])

  if (error) {
    console.error(error)
  }

  return (
    <Dialog
      onClose={() => {
        setPeopleWork()
        setPeopleWorkSalaries()
        setEmployeePlacement()
        setTotalEarning(0)
        setTotalDeduction(0)
        setLongestArr()
        setAllowances()
        setNonFixedComponents()
        setCompanyComponentList()
        setCompanyComponentTotal(0)
        setCompanyDeductionList()
        setCompanyDeductionTotal(0)
        setShowCompanyComponentTable(false)
        handleClose()
      }}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <div className="printing">
        {!peopleWork && loading && (
          <CenterWrapper>
            <CircularProgress />
          </CenterWrapper>
        )}
        {peopleWork && (
          <>
            <WhiteModalHeader className={classes.padding15}>
              <WrapperContainer>
                <FlexBetween>
                  <img
                    className={classNames(classes.logo, classes.margin20)}
                    alt="logo"
                    src={COMPANY_LOGO}
                  />
                  <BigTextBlackBold>{COMPANY_NAME}</BigTextBlackBold>
                </FlexBetween>
              </WrapperContainer>
            </WhiteModalHeader>
            <WrapperContainer className={classes.height380px}>
              <FlexBetween>
                <WrapperContainer className={classes.width60P}>
                  <FlexBetween
                    className={classNames(
                      classes.marginTop10,
                      classes.marginLeft50
                    )}
                  >
                    <TextBlackBold className={classes.width30P}>
                      Name
                    </TextBlackBold>
                    <TextGreyBold className={classes.width70P}>
                      {employeePlacement.global_user.name}
                    </TextGreyBold>
                  </FlexBetween>
                  <FlexBetween
                    className={classNames(
                      classes.marginTop10,
                      classes.marginLeft50
                    )}
                  >
                    <TextBlackBold className={classes.width30P}>
                      ID Employee
                    </TextBlackBold>
                    <TextGreyBold className={classes.width70P}>
                      {employeePlacement.regno}
                    </TextGreyBold>
                  </FlexBetween>
                  <FlexBetween
                    className={classNames(
                      classes.marginTop10,
                      classes.marginLeft50
                    )}
                  >
                    <TextBlackBold className={classes.width30P}>
                      Organization
                    </TextBlackBold>
                    <TextGreyBold className={classes.width70P}>
                      {
                        employeePlacement.company_job_profile
                          .company_organization.name
                      }
                    </TextGreyBold>
                  </FlexBetween>
                  <FlexBetween
                    className={classNames(
                      classes.marginTop10,
                      classes.marginLeft50
                    )}
                  >
                    <TextBlackBold className={classes.width30P}>
                      Designation
                    </TextBlackBold>
                    <TextGreyBold className={classes.width70P}>
                      {
                        employeePlacement.company_job_profile
                          .company_employee_position.name
                      }
                    </TextGreyBold>
                  </FlexBetween>
                  <FlexBetween
                    className={classNames(
                      classes.marginTop10,
                      classes.marginLeft50
                    )}
                  >
                    <TextBlackBold className={classes.width30P}>
                      Location
                    </TextBlackBold>
                    <TextGreyBold className={classes.width70P}>
                      {
                        employeePlacement.company_job_profile.company_address
                          .office_name
                      }
                    </TextGreyBold>
                  </FlexBetween>
                </WrapperContainer>
                <WrapperContainer className={classes.width40P}>
                  <FlexBetween className={classes.marginTop10}>
                    <TextBlackBold className={classes.width40P}>
                      NPWP
                    </TextBlackBold>
                    <TextGreyBold className={classes.width60P}>
                      {employeePlacement.global_user.npwp.length
                        ? employeePlacement.global_user.npwp[0].id
                        : '-'}
                    </TextGreyBold>
                  </FlexBetween>
                  <FlexBetween className={classes.marginTop10}>
                    <TextBlackBold className={classes.width40P}>
                      PTKP
                    </TextBlackBold>
                    <TextGreyBold className={classes.width60P}>
                      {employeePlacement.people_work_salaries.length
                        ? employeePlacement.people_work_salaries[0]
                            .salary_fields.PTKP
                        : '-'}
                    </TextGreyBold>
                  </FlexBetween>
                  <FlexBetween className={classes.marginTop10}>
                    <TextBlackBold className={classes.width40P}>
                      Period
                    </TextBlackBold>
                    <TextGreyBold className={classes.width60P}>
                      {`${moment(peopleWork.month, 'MM').format('MMMM')}, ${
                        peopleWork.year
                      }`}
                    </TextGreyBold>
                  </FlexBetween>
                  <FlexBetween className={classes.marginTop10}>
                    <TextBlackBold className={classes.width40P}>
                      Created Date
                    </TextBlackBold>
                    <TextGreyBold className={classes.width60P}>
                      {employeePlacement.date_sent
                        ? moment(employeePlacement.date_sent).format(
                            'MMMM DD, YYYY'
                          )
                        : '-'}
                    </TextGreyBold>
                  </FlexBetween>
                  <FlexBetween className={classes.marginTop10}>
                    <TextBlackBold className={classes.width40P}>
                      Printed Date
                    </TextBlackBold>
                    <TextGreyBold className={classes.width60P}>
                      {employeePlacement.date_printed
                        ? moment(employeePlacement.date_printed).format(
                            'MMMM DD, YYYY'
                          )
                        : '-'}
                    </TextGreyBold>
                  </FlexBetween>
                </WrapperContainer>
              </FlexBetween>
            </WrapperContainer>
            <WrapperContainer className={classes.paddingTable}>
              {/* EARNING - DEDUCTION TABLE */}
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader}
                        colSpan={2}
                        style={{fontWeight: 700}}
                      >
                        Earning
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        colSpan={2}
                        style={{fontWeight: 700}}
                      >
                        Deduction
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {peopleWork && longestArr
                      ? // eslint-disable-next-line no-unused-vars
                        longestArr.map((_, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell className="text-xs">
                                {allowances[index] && allowances[index].name}
                              </TableCell>
                              <TableCell
                                className={`${classes.tableRightBorder} text-xs`}
                                align="right"
                              >
                                {allowances[index] &&
                                  convertToRoundedRupiah(
                                    allowances[index].value,
                                    false
                                  )}
                              </TableCell>
                              <TableCell className="text-xs">
                                {nonFixedComponents[index] &&
                                  nonFixedComponents[index].name}
                              </TableCell>
                              <TableCell className="text-xs" align="right">
                                {nonFixedComponents[index] &&
                                  convertToRoundedRupiah(
                                    nonFixedComponents[index].value,
                                    false
                                  )}
                              </TableCell>
                            </TableRow>
                          )
                        })
                      : null}
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        align="right"
                        className={`${classes.tableRightBorder}`}
                        style={{fontWeight: 700}}
                      >
                        {convertToRoundedRupiah(totalEarning, false)}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="right"
                        style={{fontWeight: 700}}
                      >
                        {convertToRoundedRupiah(totalDeduction, false)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <FormGroup style={{marginTop: 10, marginBottom: 10}}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      color="primary"
                      checked={showCompanyComponentTable}
                      onChange={() =>
                        setShowCompanyComponentTable(!showCompanyComponentTable)
                      }
                      inputProps={{'aria-label': 'controlled'}}
                    />
                  }
                  label={<p>Show Company Components</p>}
                />
              </FormGroup>

              {/* COMPANY COMPONENT TABLE */}
              {showCompanyComponentTable && (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableHeader}
                          colSpan={2}
                          style={{fontWeight: 700}}
                        >
                          Added by Company
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          colSpan={2}
                          style={{fontWeight: 700}}
                        >
                          Deducted by Company
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {peopleWork && longestCompanyCemponentArr
                        ? // eslint-disable-next-line no-unused-vars
                          longestCompanyCemponentArr.map((_, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell className="text-xs">
                                  {companyComponentList?.[index]?.name}
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableRightBorder} text-xs`}
                                  align="right"
                                >
                                  {companyComponentList?.[index]?.value &&
                                    convertToRoundedRupiah(
                                      companyComponentList?.[index]?.value,
                                      false
                                    )}
                                </TableCell>
                                <TableCell className="text-xs">
                                  {companyDeductionList?.[index]?.name}
                                </TableCell>
                                <TableCell className="text-xs" align="right">
                                  {companyDeductionList?.[index]?.value &&
                                    convertToRoundedRupiah(
                                      companyDeductionList?.[index]?.value,
                                      false
                                    )}
                                </TableCell>
                              </TableRow>
                            )
                          })
                        : null}
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          align="right"
                          className={`${classes.tableRightBorder}`}
                          style={{fontWeight: 700}}
                        >
                          {convertToRoundedRupiah(companyComponentTotal, false)}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          align="right"
                          style={{fontWeight: 700}}
                        >
                          {convertToRoundedRupiah(companyDeductionTotal, false)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <PayrollContainer className={classes.marginTopBot35}>
                <FlexBetween>
                  <WrapperContainer className={classes.width50P}>
                    <FlexBetween className={classNames(classes.marginTop10)}>
                      <TextBlackBold
                        className={classNames(
                          classes.width30P,
                          classes.alignSelfStart
                        )}
                      >
                        Bank Account
                      </TextBlackBold>
                      <TextGreyBold className={classes.width70P}>
                        {(peopleWorkSalaries &&
                          peopleWorkSalaries.people_profile_bank &&
                          peopleWorkSalaries.people_profile_bank.global_bank
                            .name) ||
                          '-'}
                        <br />
                        {(peopleWorkSalaries &&
                          peopleWorkSalaries.people_profile_bank &&
                          peopleWorkSalaries.people_profile_bank
                            .account_number) ||
                          '-'}
                      </TextGreyBold>
                    </FlexBetween>
                    <FlexBetween className={classNames(classes.marginTop10)}>
                      <TextBlackBold className={classes.width30P}>
                        A/C Name
                      </TextBlackBold>
                      <TextGreyBold className={classes.width70P}>
                        {(peopleWorkSalaries &&
                          peopleWorkSalaries.people_profile_bank &&
                          peopleWorkSalaries.people_profile_bank
                            .account_name) ||
                          '-'}
                      </TextGreyBold>
                    </FlexBetween>
                  </WrapperContainer>
                  <WrapperContainer
                    className={classNames(
                      classes.width50P,
                      classes.alignSelfStart
                    )}
                  >
                    <FlexBetween className={classes.marginTop10}>
                      <TextBlackBold className={classes.width30P}>
                        Take Home Pay
                      </TextBlackBold>
                      <BigTextBlackBold>
                        {convertToRoundedRupiah(
                          totalEarning - totalDeduction,
                          false
                        )}
                      </BigTextBlackBold>
                    </FlexBetween>
                  </WrapperContainer>
                </FlexBetween>
              </PayrollContainer>
            </WrapperContainer>
            <PayrollContainer
              className={classNames(
                classes.textAlignCenter,
                classes.marginBot30
              )}
            >
              <Button
                size="large"
                variant="contained"
                color="primary"
                className="hide-on-print"
                style={{
                  width: '200px',
                }}
                onClick={() => window.print()}
              >
                Download
              </Button>
            </PayrollContainer>
          </>
        )}
      </div>
    </Dialog>
  )
}

export default ModalPaySlip
