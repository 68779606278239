import gql from 'graphql-tag'

export const ADD_POLICY_LOCATIONS = gql`
  mutation addLocationRestriction(
    $data: [time_attendance_policy_locations_insert_input!]!
  ) {
    insert_time_attendance_policy_locations(objects: $data) {
      affected_rows
    }
  }
`
