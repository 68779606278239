import gql from 'graphql-tag'

export const ADD_MEDICAL_HISTORY = gql`
  mutation AddMedicalHistory(
    $userId: uuid!
    $name: String!
    $age: Int
    $start_date: date!
    $end_date: date!
    $hospital_name: String
    $doctor_name: String
    $disease: String!
    $consequence: String
    $laboratory_number: String
    $laboratory_notes: String
    $medical_score: Int
    $medical_fee: String
    $medical_category: String!
    $attachments: jsonb
  ) {
    insert_people_profile_medical_histories(
      objects: [
        {
          user_id: $userId
          name: $name
          age: $age
          start_date: $start_date
          end_date: $end_date
          hospital_name: $hospital_name
          doctor_name: $doctor_name
          disease: $disease
          consequence: $consequence
          laboratory_number: $laboratory_number
          laboratory_notes: $laboratory_notes
          medical_score: $medical_score
          medical_fee: $medical_fee
          medical_category: $medical_category
          attachments: $attachments
        }
      ]
    ) {
      affected_rows
    }
  }
`

export const UPDATE_MEDICAL_HISTORY = gql`
  mutation UpdateMedicalHistory(
    $id: Int!
    $userId: uuid!
    $name: String
    $age: Int
    $start_date: date
    $end_date: date
    $hospital_name: String
    $doctor_name: String
    $disease: String
    $consequence: String
    $laboratory_number: String
    $laboratory_notes: String
    $medical_score: Int
    $medical_fee: String
    $medical_category: String
    $attachments: jsonb
  ) {
    update_people_profile_medical_histories(
      _set: {
        name: $name
        age: $age
        start_date: $start_date
        end_date: $end_date
        hospital_name: $hospital_name
        doctor_name: $doctor_name
        disease: $disease
        consequence: $consequence
        laboratory_number: $laboratory_number
        laboratory_notes: $laboratory_notes
        medical_score: $medical_score
        medical_fee: $medical_fee
        medical_category: $medical_category
        attachments: $attachments
      }
      where: {
        _and: [
          {id: {_eq: $id}}
          {user_id: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const DELETE_MEDICAL_HISTORY = gql`
  mutation DeleteMedicalHistory($id: Int!, $userId: uuid!) {
    update_people_profile_medical_histories(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {user_id: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`
