import React, {useState} from 'react'
import {
  Typography,
  Dialog,
  IconButton,
  Icon,
  Divider,
  TextField,
  InputAdornment,
  makeStyles,
  Button,
} from '@material-ui/core'

import {useMutation, useQuery} from '@apollo/react-hooks'

import {useSnackbar} from 'notistack'
import {
  ModalContentEvent,
  WhiteModalHeader,
  BlueIcon,
  GreyTypography,
  MediumAvatar,
  FormChildButton,
  SkyBlueIcon,
} from '../../../../../GlobalStyles'
import {BoldTypography} from '../../../../../components/typography/TypographyStyles'
import {GET_LIST_NOMINATION_INVITE} from '../../../../../graphql/queries'
import Loading from '../../../../../components/loading/LoadingComponent'
import {COMPANY_ID} from '../../../../../utils/globals'
import {holdingRolesContext} from '../../../../../utils/helpers'
import {ADD_TO_NOMINATION_HOLDING} from '../../../../../graphql/mutations'

const useStyles = makeStyles(() => ({
  mr28: {marginRight: 28},
  mt20: {marginTop: '20px !important'},
  companyWrapper: {display: 'flex', margin: '20px 0'},
  structureHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginText: {marginTop: 8, marginBottom: 30},
  buttonSpacer: {marginRight: 12},
  fontIcon: {fontSize: '2rem!important'},
  listCompanyContainer: {minHeight: '12rem'},
  noVacancy: {
    paddingTop: '5rem',
    textAlign: 'center',
    color: 'rgb(0, 61, 81)',
  },
}))

const ModalNominateTalent = ({
  handleClose,
  open,
  source_id,
  pwp_id,
  selectedId,
  setSelectedId,
}) => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [search, setSearch] = useState('')

  const {data, error, refetch} = useQuery(GET_LIST_NOMINATION_INVITE, {
    variables: {
      company: COMPANY_ID,
      source_id,
      search: `%${search}%`,
      pwp_id,
    },
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const [inviteToNomination] = useMutation(ADD_TO_NOMINATION_HOLDING)

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const handleMutation = () => {
    const object = selectedId.map(res => {
      return {source_id, nomination_id: res, candidate_status: 'is_talent'}
    })

    inviteToNomination({variables: {object}, ...holdingRolesContext})
      .then(() => {
        enqueueSnackbar('Success Update data', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
        handleClose()
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleClickIcon = (id, type) => {
    if (type === 'add') {
      setSelectedId([...selectedId, id])
    } else {
      setSelectedId(selectedId.filter(res => res !== id))
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true}>
      <WhiteModalHeader style={{justifyContent: 'normal'}}>
        <Typography variant="body1" color="primary">
          Nominate Talent
        </Typography>
      </WhiteModalHeader>
      <Divider />
      <ModalContentEvent>
        <TextField
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BlueIcon>search</BlueIcon>
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          fullWidth
          size="small"
          margin="normal"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <div className={classes.listCompanyContainer}>
          {data.holding_nomination_config.length > 0 ? (
            data.holding_nomination_config.map((res, i) => (
              <div className={classes.companyWrapper} key={i}>
                <MediumAvatar
                  variant="rounded"
                  src={res.company_profile.logo}
                  alt={res.company_profile.brand_name}
                  className={classes.mr28}
                ></MediumAvatar>
                <div className={classes.structureHeader}>
                  <div style={{marginRight: 12}}>
                    <BoldTypography
                      variant="body1"
                      gutterBottom
                      color="primary"
                    >
                      {res.name}
                    </BoldTypography>
                    <GreyTypography variant="body2" gutterBottom>
                      {res.company_profile.brand_name}
                    </GreyTypography>
                    <Typography variant="body2" gutterBottom>
                      {res.company_job_profile.title}
                    </Typography>
                  </div>
                  {selectedId.includes(res.id) ? (
                    <IconButton
                      size="small"
                      onClick={() => handleClickIcon(res.id, 'delete')}
                    >
                      <Icon color="secondary" className={classes.fontIcon}>
                        close
                      </Icon>
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => handleClickIcon(res.id, 'add')}
                    >
                      <SkyBlueIcon className={classes.fontIcon}>
                        add
                      </SkyBlueIcon>
                    </IconButton>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className={classes.noVacancy}>
              No suitable vacancy, or this talent has already assigned to all
              vacancies
            </div>
          )}
        </div>

        <FormChildButton style={{padding: 0}}>
          <Button
            size="large"
            className={classes.buttonSpacer}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={selectedId.length === 0}
            onClick={handleMutation}
          >
            Nominate
          </Button>
        </FormChildButton>
      </ModalContentEvent>
    </Dialog>
  )
}

export default ModalNominateTalent
