import styled from 'styled-components'
import {withStyles} from '@material-ui/core/styles'
import {
  TextField,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl,
  Button,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core'

// +++============================
export const InputFilter = styled(TextField)`
  display: flex;
  justify-content: space-between;
  width: auto;
  minwidth: 155px;
  &:nth-child(2) {
    width: 220px;
  }
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
`

export const TitleDetail = styled(Typography)`
  display: flex;
  justify-content: space-between;
  width: 155px;
  maxwidth: 219px;
  &:nth-child(2) {
    width: 220px;
  }
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
  font-size: 13px;
`
export const BodyDetail = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 155px;
  &:nth-child(2) {
    width: 220px;
  }
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
  font-size: 13px;
`
// +++============================

export const HeaderDetail = styled.div`
  display: flex;
  flex-direction: row;
  margin: '0px 14px';
  align-content: center;
  padding: 12px;
`

export const NameDetail = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  font-family: muli;
`

export const NumberDetail = styled(Typography)`
  font-size: 16px;
  font-family: muli;
  color: #d0d4d4;
  margin: '-3px 0px 3px 0px';
`

export const OfficeDetail = styled(Typography)`
  font-size: 16px;
  font-family: muli;
`

export const AspirationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`

export const ExpansionPanelStyle = styled(ExpansionPanel)`
  background-color: #f7f8f9;
  box-shadow: none;
`

export const ExpansionPanelSummaryStyle = styled(ExpansionPanelSummary)`
  background-color: #f7f8f9;
  box-shadow: none;
  margin: 27px 0px;
  font-weight: 600;
`
export const ExpansionPanelDetailStyle = styled(ExpansionPanelDetails)`
  box-shadow: none;
  background-color: #f7f8f9;
`

export const TitleAspiration = styled(Typography)`
  font-size: 14px;
  font-family: muli;
  color: #039be5;
  font-size: 16px;
`

export const ContentAspiration = styled(Typography)`
  font-size: 14px;
  font-family: muli;
  margin-left: 17px;
  line-height: 23px;
  color: #a9a8a8;
`

export const IndicatorTitle = styled(Typography)`
  font-size: 16px;
  font-family: Muli;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 18px;
`
export const IndicatorValueWrapper = styled(Typography)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-right: 10px;
  padding: 0px 20px;
`
export const IndicatorValue = styled(Typography)`
  font-size: 14px;
  font-family: Muli;
  font-weight: 600;
  line-height: 18px;
`

export const SearchStyled = styled(FormControl)`
  width: 100%;
  margin: 0 0 8px 8px;
  background-color: #ffffff;
  color: #a9a8a8;
  border-radius: 5px;
  margin-bottom: 12px;
`

export const InputStyled = styled(OutlinedInput)`
  pading-right: 0px;
`

export const FilterStyled = styled(Button)`
  width: 170px;
  height: 56px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid #003d51;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
`

export const StyledMenuItem = withStyles(() => ({
  root: {
    '&:focus': {
      backgroundColor: '#f7f8f9',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#000000',
      },
    },
  },
}))(MenuItem)
