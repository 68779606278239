import React, {useMemo} from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import {Checkbox, FormControlLabel} from '@material-ui/core'

import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'
import FormInput from '../../ShareComponents-detail/FormInput'
import {ModalAddEditWraper} from '../../../DetailEmployeeStyles'
import {ButtonSubmit} from '../../../SharedDetailEmployee'
import {isErrorForm} from '../../ShareComponents-detail/helperDetail'

import {LIST_TYPE_IDENTITY} from './IdentityCardConstants'

import {dateFormat} from '../../../../../../../utils/helpers'
import {
  CHECK_DUPLICATE_IDENTITY_CARD,
  GET_KTP_NPWP_FROM_LATEST_SALARY,
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
} from '../../../../../../../graphql/queries'
import {
  UPDATE_IDENTITY_CARD,
  ADD_IDENTITY_CARD,
} from '../../../../../../../graphql/mutations'
import {updatePws} from './updatePws'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2

const INITIAL_FORM_DATA = {
  id_type: null,
  id_number: undefined,
  country: null,
  province: undefined,
  city: undefined,
  district: undefined,
  sub_district: undefined,
  postal_code: undefined,
  address: undefined,
  expired_date: undefined,
}

const AddEditIdentityCard = props => {
  const {
    open,
    userId,
    initialData,
    onClose,
    onSubmit,
    onDelete,
    userIdentity,
  } = props

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [formState, setFormState] = React.useState(STATE_INITIAL)
  const [formData, setFormData] = React.useState(INITIAL_FORM_DATA)

  const [search, setSearch] = React.useState({province: '', city: ''})

  const [isNotExpired, setIsNotExpired] = React.useState(false)

  const isEditing = !!initialData

  const isError = formState === STATE_ERROR
  const isDispatching = formState === STATE_DISPATCH

  const isSelectPrimaryIdentity = ['KTP', 'NPWP'].includes(formData.id_type)

  const {data: dataProvince, loading: loadingProvince} = useQuery(
    GLOBAL_PROVINCES_SEARCHABLE,
    {
      variables: {
        limit: 20,
        search: `%${search.province}%`,
      },
    }
  )

  const {data: dataCity, loading: loadingCity} = useQuery(
    GLOBAL_CITIES_SEARCHABLE,
    {
      variables: {
        limit: 20,
        search: `%${search.city}%`,
        province: formData?.province?.value,
      },
    }
  )

  const {data: dataPws} = useQuery(GET_KTP_NPWP_FROM_LATEST_SALARY, {
    wlb_skipPatch: true,
    skip: !open,
    variables: {
      user: userId,
    },
  })

  React.useEffect(() => {
    if (!open) {
      return
    }

    setFormState(STATE_INITIAL)

    if (initialData) {
      const fields = initialData.identity_fields

      const prov = fields.province
      const city = fields.city
      const expiry = fields.expiredDate

      const isLifetime = expiry === 'Lifetime'

      setFormData({
        id_type: initialData.type,
        id_number: initialData.number,

        country: fields.country,
        province: prov ? {value: prov.id, label: prov.name} : null,
        city: city ? {value: city.id, label: city.name} : null,
        district: fields.district,
        sub_district: fields.subDistrict,
        postal_code: fields.postalCode,
        address: fields.address,

        expired_date: isLifetime
          ? null
          : dateFormat(fields.expiredDate, 'YYYY-MM-DD'),
      })

      setIsNotExpired(isLifetime)
    } else {
      setFormData(INITIAL_FORM_DATA)
      setIsNotExpired(false)
    }
  }, [open])

  const handleSetValues = ({fieldName, value, label}) => {
    if (fieldName === 'province') {
      setFormData({...formData, province: {value, label}, city: null})
    } else if (fieldName === 'city') {
      setFormData({...formData, city: {value, label}})
    }
  }

  const handleSubmit = () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const data = {
      id: formData.id_number,
      type: formData.id_type,
      user: userId,
      deletedAt: null,
      identity_fields: {
        country: formData.country,
        province: formData.province
          ? {
              id: formData.province.value,
              name: formData.province.label,
            }
          : null,
        city: formData.city
          ? {
              id: formData.city.value,
              name: formData.city.label,
            }
          : null,
        district: formData.district,
        subDistrict: formData.sub_district,
        postalCode: formData.postal_code,
        address: formData.address,
        expiredDate: isNotExpired
          ? 'Lifetime'
          : dateFormat(formData.expired_date, 'MMM DD, YYYY'),
      },
    }

    const verb = isEditing ? 'ubah' : 'tambah'
    let promise

    if (isEditing) {
      promise = client.mutate({
        mutation: UPDATE_IDENTITY_CARD,
        variables: {
          userId: userId,
          id: initialData.number,
          type: initialData.type,
          dateAdded: initialData.date_added,
          data: data,
        },
      })
    } else {
      const prom = client.query({
        query: CHECK_DUPLICATE_IDENTITY_CARD,
        fetchPolicy: 'network-only',
        variables: {
          userId: userId,
          id: data.id,
          type: data.type,
        },
      })

      promise = prom.then(result => {
        const check = result.data

        if (check && check.people_identities_aggregate.aggregate.count > 0) {
          return client.mutate({
            mutation: UPDATE_IDENTITY_CARD,
            variables: {
              userId: userId,
              id: data.id,
              type: data.type,
              data: data,
            },
          })
        } else {
          return client.mutate({
            mutation: ADD_IDENTITY_CARD,
            variables: {
              data: data,
            },
          })
        }
      })
    }

    promise.then(
      result => {
        const mutationData = result.data

        if (!isEditing) {
          if (
            !mutationData.update_people_identities &&
            !mutationData.insert_people_identities_one
          ) {
            enqueueSnackbar(`Kartu Identitas sudah digunakan`, {
              variant: 'error',
            })
            setFormState(STATE_INITIAL)
            return
          }

          setFormData(INITIAL_FORM_DATA)
          setIsNotExpired(false)
        }

        if (['KTP', 'NPWP'].includes(formData.id_type)) {
          updatePws({client, formData, dataPws})
        }

        setFormState(STATE_INITIAL)
        enqueueSnackbar(`Kartu Identitas di${verb}`, {variant: 'success'})

        if (onSubmit) {
          // NOTE(intrnl): See `AddEditInsuranceData.jsx` for details.

          // onSubmit(!initialData || initialData.id !== data.id)
          onSubmit(true)
        }
      },
      err => {
        if (err?.message?.includes('Uniqueness violation')) {
          enqueueSnackbar(`Kartu Identitas sudah digunakan`, {variant: 'error'})
        } else {
          console.error(err)
          enqueueSnackbar(`Failed to ${verb} identity card`, {variant: 'error'})
        }

        setFormState(STATE_INITIAL)
      }
    )
  }

  const asterisk = isSelectPrimaryIdentity ? '' : '*'

  const listIdentityType = useMemo(() => getListIdentityType(userIdentity), [
    userIdentity,
  ])

  const fieldsList = [
    {
      label: 'Tipe Identitas*',
      placeholder: 'Pilih tipe identitas',
      fieldName: 'id_type',
      required: true,
      type: 'select',
      value: formData.id_type,
      error: !formData.id_type,
      option: listIdentityType,
    },
    {
      type: 'textfield',
      label: 'Nomor Identitas' + asterisk,
      fieldName: 'id_number',
      value: formData.id_number,
      placeholder: 'Tambahkan nomor identitas',
      error: !formData.id_number,
      required: true,
    },
    {
      type: 'select',
      label: 'Negara' + asterisk,
      fieldName: 'country',
      value: formData.country,
      placeholder: 'Pilih negara',
      error: !formData.country,
      option: [{value: 'Indonesia', name: 'Indonesia'}],
      required: !isSelectPrimaryIdentity,
    },
    {
      type: 'autocomplete',
      label: 'Provinsi' + asterisk,
      fieldName: 'province',
      value: formData.province,
      placeholder: 'Pilih provinsi',
      error: !formData.province,
      required: !isSelectPrimaryIdentity,
      isLoading: loadingProvince,
      onChange: handleSetValues,
      onInputChange: search => setSearch(prev => ({...prev, province: search})),
      option: dataProvince?.global_provinces?.map(({id, name}) => ({
        value: id,
        label: name,
      })),
    },
    {
      type: 'autocomplete',
      label: 'Kota' + asterisk,
      fieldName: 'city',
      value: formData.city,
      placeholder: 'Pilih kota',
      error: !formData.city,
      required: !isSelectPrimaryIdentity,
      isLoading: loadingCity,
      onChange: data => handleSetValues(data),
      onInputChange: search => setSearch(prev => ({...prev, city: search})),
      option: dataCity?.global_cities?.map(({id, name}) => ({
        value: id,
        label: name,
      })),
    },
    {
      type: 'textfield',
      label: 'Kecamatan' + asterisk,
      fieldName: 'district',
      value: formData.district,
      placeholder: 'Tambahkan kecamatan',
      error: !formData.district,
      required: !isSelectPrimaryIdentity,
    },
    {
      type: 'textfield',
      label: 'Kelurahan',
      fieldName: 'sub_district',
      value: formData.sub_district,
      placeholder: 'Tambahkan kelurahan',
    },
    {
      type: 'textfield',
      inputType: 'number',
      label: 'Kode Pos' + asterisk,
      fieldName: 'postal_code',
      value: formData.postal_code,
      placeholder: 'Tambahkan kode pos',
      error: !formData.postal_code,
      required: !isSelectPrimaryIdentity,
    },
    {
      type: 'textfield',
      label: 'Alamat' + asterisk,
      fieldName: 'address',
      rows: 5,
      multiline: true,
      value: formData.address,
      placeholder: 'Tambahkan alamat',
      error: !formData.address,
      required: !isSelectPrimaryIdentity,
    },
    {
      type: 'dateCustom',
      label: [`Masa Berlaku${isNotExpired ? '' : asterisk}`],
      filter: ['date'],
      fieldName: 'expired_date',
      required: !isSelectPrimaryIdentity && !isNotExpired,
      value: formData.expired_date,
      error: !formData.expired_date,
      disabled: isNotExpired,
    },
  ]

  return (
    <ModalDetail
      open={open}
      maxWidth="sm"
      title={`${isEditing ? 'Ubah' : 'Tambahkan'} Kartu Identitas`}
      onClose={isDispatching ? null : onClose}
      onDelete={isEditing ? onDelete : null}
    >
      <ModalAddEditWraper>
        <FormInput
          fieldsList={fieldsList}
          setValues={setFormData}
          values={formData}
          errorState={isError}
          HELPER_TEXT="Bagian ini diperlukan"
        />
        <FormControlLabel
          label="Kartu identitas ini masih berlaku "
          control={
            <Checkbox
              checked={isNotExpired}
              onChange={e => setIsNotExpired(e.target.checked)}
              name="isNotExpired"
              color="primary"
            />
          }
        />
      </ModalAddEditWraper>

      <ButtonSubmit
        disabled={isDispatching}
        onCancel={onClose}
        onSave={handleSubmit}
      />
    </ModalDetail>
  )
}

export default AddEditIdentityCard

const getListIdentityType = userIdentity => {
  const ownedType = userIdentity?.people_identities.map(({type}) => {
    if (['KTP', 'NPWP'].includes(type)) {
      return type
    }
  })

  return LIST_TYPE_IDENTITY.map(type => ({
    ...type,
    disabled: ownedType.includes(type.value),
  }))
}
