import React, {useState} from 'react'
import {withRouter, Link} from 'react-router-dom'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
} from '../../../../../../GlobalStyles'
import {IconButton, Button, Box, Typography} from '@material-ui/core'
import moment from 'moment'
import helperStyle, {AntTabs, StyledTab} from '../../../PayrollPageStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import FormComponent from './FormComponent'
import AssigneeContainer from '../shared-component/form-asignee/AssigneeContainer'
import ModalConfirmation from '../../ModalConfirmation'
import {UPDATE_COMPONENT_SETTING} from '../../../../../../graphql/mutations'
import {useMutation} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../../../utils/globals'
import {useSnackbar} from 'notistack'
import {GQL_FINANCE_OPTIONS} from '../../../utils'

const EditPayrollComponent = props => {
  const {componentData} = props.location.state

  const editValueComponent = {
    isUnique: true,
    company: COMPANY_ID,
    currentCode: componentData.code || null,
    code: componentData.code || null,
    name: componentData.name || null,
    type: componentData.type || null,
    frequency: componentData.component_frequency || null,
    method_base: componentData.component_method_base || [],
    calculation: componentData.component_calculation || null,
    calculation_mul:
      componentData.component_calculation_mul?.toString() || null,
    calculation_value:
      componentData.custom_calculation_value?.toString() || null,
    taxable: componentData.taxable === null ? null : componentData.taxable,
    include_in_bpjs:
      componentData.include_in_bpjs === null
        ? null
        : componentData.include_in_bpjs,
    tax_paid_by: componentData.tax_paid_by || null,
    part_of: componentData.part_of || null,
    effective_date:
      componentData.effective_date || moment(new Date()).format('YYYY-MM-DD'),
    assignments: componentData.finance_component_assignments || [],
  }

  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()

  const assigneeMapping = assignees => {
    if (assignees)
      return assignees.map(i =>
        Object.assign(
          {user_work_id: i.people_work_placement.id},
          {component_id: componentData.id}
        )
      )
  }

  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [modalType, setModalType] = useState('')
  const [tab, setTab] = useState(0)
  const [value, setValue] = useState(editValueComponent)
  const [forAssignment, setForAssignment] = useState(
    assigneeMapping(editValueComponent.assignments) || []
  )
  const [hasSubmit, setHasSubmit] = useState(false)

  const [employees, setEmployees] = useState([])
  const [assignee, setAssignee] = useState(editValueComponent.assignments || [])

  const handleChangeTab = (event, newTab) => {
    event.preventDefault()
    setTab(newTab)
  }

  const handleComponent = (target, input, type = undefined) => {
    if (target === 'assignments') {
      const newAssignment = input.map(i =>
        Object.assign(i, {component_id: componentData.id})
      )
      setForAssignment(newAssignment)
    } else {
      if (type === 'number') {
        if (Number(input) >= 0) {
          setValue({
            ...value,
            [target]: input,
          })
        }
      } else {
        setValue({
          ...value,
          [target]: input,
        })
      }
    }
  }

  const [updateComponent] = useMutation(
    UPDATE_COMPONENT_SETTING,
    GQL_FINANCE_OPTIONS
  )

  const onCloseModal = () => {
    setModalType('')
    setShowModalConfirm(false)
  }

  const onSave = () => {
    setModalType('confirmation')
    setShowModalConfirm(true)
  }

  const onConfirm = () => {
    setHasSubmit(true)
    const editedVariables = {
      id: parseInt(componentData.id),
      code: String(value.code),
      name: String(value.name),
      type: parseInt(value.type),
      frequency: String(value.frequency),
      method_base: value.method_base,
      calculation: String(value.calculation),
      calculation_mul: Number(value.calculation_mul),
      calculation_value: Number(value.calculation_value),
      taxable: value.taxable,
      include_in_bpjs: value.include_in_bpjs,
      tax_paid_by: !value.taxable ? 0 : parseInt(value.tax_paid_by),
      part_of: parseInt(value.part_of),
      effective_date: moment(value.effective_date).format('YYYY-MM-DD'),
      assignments: forAssignment,
    }

    if (
      !value.isUnique ||
      !value.code ||
      !value.name ||
      value.frequency === null ||
      value.type === null ||
      value.taxable === null ||
      value.include_in_bpjs === null ||
      (value.taxable && value.tax_paid_by === null) ||
      value.part_of === null ||
      value.effective_date === null ||
      (value.calculation !== 'other' &&
        (String(value.calculation_mul).length === 0 ||
          value.calculation_mul === null ||
          Number(value.calculation_mul) < 0)) ||
      (value.calculation === 'other' &&
        (String(value.calculation_value).length === 0 ||
          value.calculation_value === null ||
          Number(value.calculation_value) < 0))
    ) {
      enqueueSnackbar('Please input all required data', {
        variant: 'error',
      })
    } else {
      updateComponent({
        variables: editedVariables,
        ...GQL_FINANCE_OPTIONS,
      })
        .then(() => {
          enqueueSnackbar('Component saved', {
            variant: 'success',
            autoHideDuration: 1500,
          })
          setTimeout(() => {
            props.history.push({
              pathname: '/financial/payroll/setting',
              state: {
                active: 'settings',
                tabValue: 1,
              },
            })
          }, 1500)
        })
        .catch(() =>
          enqueueSnackbar('Edit Component Failed', {
            variant: 'error',
            autoHideDuration: 1500,
          })
        )
        .finally(() => {
          onCloseModal()
          setHasSubmit(false)
        })
    }
  }

  const onCancelEdit = () => {
    // setValue(editValueComponent)
    props.history.push({
      pathname: '/financial/payroll/setting',
      state: {
        active: 'settings',
        tabValue: 1,
      },
    })
  }

  return (
    <>
      <ModalConfirmation
        open={showModalConfirm}
        onClose={onCloseModal}
        onConfirm={onConfirm}
        type={modalType}
        onCancel={onCancelEdit}
      />
      <PaperContainer>
        <FormToolbar disableGutters>
          <FormTitleContainer>
            <Link
              to={{
                pathname: '/financial/payroll/setting',
                state: {active: 'settings', tabValue: 1},
              }}
            >
              <IconButton edge="end" aria-label="back">
                <ArrowBack />
              </IconButton>
            </Link>
            <AddEditTitle variant="h6" display="inline">
              Edit Payroll Component
            </AddEditTitle>
          </FormTitleContainer>
          <div>
            <Button
              size="large"
              className={classes.backButton}
              onClick={() => setShowModalConfirm(true)}
            >
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        </FormToolbar>
        <AntTabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          className={classes.tabs}
        >
          <StyledTab label="Detail" />
          <StyledTab label="Assignee" />
        </AntTabs>
        <TabPanel value={tab} index={0}>
          <FormComponent
            setValue={setValue}
            handleComponent={handleComponent}
            value={value}
            hasSubmit={hasSubmit}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          Assign To
          <AssigneeContainer
            handleComponent={handleComponent}
            employees={employees}
            setEmployees={setEmployees}
            assignee={assignee}
            setAssignee={setAssignee}
          />
        </TabPanel>
      </PaperContainer>
    </>
  )
}

export default withRouter(EditPayrollComponent)

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}
