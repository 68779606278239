import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import {FlexColumnStart} from '../../../../performance/goal/modal/modalStyles'
import FormDetail from '../../../component/FormDetail'
import {useStyles} from '../../activity/detail/Destination'
import {calculateBudget} from '../../businessTripHelper'
import {ConversionDisplay} from '../../BusinessTripStyles'

export const PAYMENT_METHOD = {
  POSITIVE: {
    type: {
      manual: 'Returned Directly',
      payroll: 'Cut from Salary',
    },
  },
  NEGATIVE: {
    type: {
      manual: 'Pay Directly',
      claim: 'Claimed by Reimbursement',
      payroll: 'Add it Up to Salary',
    },
  },
}

function TotalBudget({
  data,
  diff_action,
  currencyFrom,
  currencyTo,
  approvedDate,
}) {
  const styles = useStyles()

  let totalGiven = 0
  let totalUsed = 0

  const nameRemaining = () => {
    const number = totalGiven - totalUsed < 0 ? 'NEGATIVE' : 'POSITIVE'

    return PAYMENT_METHOD[number].type[diff_action]
  }

  return (
    <FlexColumnStart style={{marginTop: 8}}>
      <FormDetail label={'Total Remaining Money'} color={'#000000'} />

      <TableContainer>
        <Table>
          <TableHead style={{backgroundColor: '#eff2f4'}}>
            <TableRow>
              <TableCell className={styles.tableHeaderCell}>Trip</TableCell>
              <TableCell className={styles.tableHeaderCell} align="right">
                Total Budget Given
              </TableCell>
              <TableCell className={styles.tableHeaderCell} align="right">
                Total Budget Used
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map(trip => {
              let given = 0
              let used = 0

              for (const budget of trip.budget) {
                given += calculateBudget(trip, budget)
                used += budget.realization
              }

              totalGiven += given
              totalUsed += used

              return (
                <TableRow key={trip.id}>
                  <TableCell className={styles.tableCell}>
                    {trip.globalDestinationByFrom.name} -{' '}
                    {trip.globalDestinationByTo.name}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={currencyTo}
                      to={currencyFrom}
                      value={given}
                      date={approvedDate}
                      smaller
                    />
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={currencyTo}
                      to={currencyFrom}
                      value={used}
                      date={approvedDate}
                      smaller
                    />
                  </TableCell>
                </TableRow>
              )
            })}

            <TableRow key="total">
              <TableCell className={styles.tableFooterCell}>Total</TableCell>
              <TableCell>
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={currencyTo}
                  to={currencyFrom}
                  value={totalGiven}
                  date={approvedDate}
                />
              </TableCell>
              <TableCell>
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={currencyTo}
                  to={currencyFrom}
                  value={totalUsed}
                  date={approvedDate}
                />
              </TableCell>
            </TableRow>

            <TableRow key="remaining">
              <TableCell className={styles.tableFooterCell}>
                <div>Total Remaining Budget</div>
                {totalGiven - totalUsed !== 0 && (
                  <div
                    style={{color: '#757575', fontWeight: 400, fontSize: 12}}
                  >
                    Total Remaining Money will be{' '}
                    <span style={{color: '#039be5'}}>{nameRemaining()}</span>
                  </div>
                )}
              </TableCell>
              <TableCell colSpan={2}>
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={currencyTo}
                  to={currencyFrom}
                  value={totalGiven - totalUsed}
                  date={approvedDate}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FlexColumnStart>
  )
}

export default TotalBudget
