import gql from 'graphql-tag'

export const GET_JOB_POSITION_TEMPLATE_SHEETS = gql`
  query($company: uuid!) {
    organization: company_organizations(where: {company: {_eq: $company}}) {
      code
      name
    }
    designation: company_employee_positions(
      where: {
        _and: [
          {company: {_eq: $company}}
          {status: {_eq: "Active"}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      designation_code
      name
    }
    level: company_employee_ranks(where: {company: {_eq: $company}}) {
      code
      name
    }
    office_address: company_addresses(where: {company: {_eq: $company}}) {
      office_code
      office_name
    }
  }
`
