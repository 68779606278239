import React, {useState} from 'react'
import {PageContainer} from '../../../GlobalStyles'
import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'
import {withRouter} from 'react-router-dom'
import QuestionBank from './question-bank/QuestionBankComponent'
import ViewQuestionBank from './question-bank/ViewQuestionBankComponent'
import AddQuestionBank from './question-bank/AddQuestionBank'
import AddGroupQuestionBank from './question-bank/AddGroupQuestionBank'
import EditGroupQuestionBank from './question-bank/EditGroupQuestionBank'
import EditQuestionBank from './question-bank/EditQuestionBank'
import Respondents from './respondents/RespondentsComponent'
import AddRespondents from './respondents/AddRespondents'
import EditRespondents from './respondents/EditRespondents'
import RespondentsGroup from './respondents-group/RespondentsGroupComponents'
// import RespondentsGroup from './respondents-group/RespondentsGroupComponents'
import AddRespondentsGroup from './respondents-group/AddRespondentsGroup'
import EditRespondentsGroup from './respondents-group/EditRespondentsGroup'
import SurveyCenter from './survei-center/SurveyCenterComponent'
import AddSurveyCenter from './survei-center/form/FormSurveyCenterComponent'
import SurveyDetail from './survei-center/detail/SurveyDetailComponent'
import DetailAsesiPage from './survei-center/detail/detail-asesi/DetailAsesiPage'

import {useDebounced} from '@worklifebeyond/wlb-utils-components'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'view-question-bank':
      return <ViewQuestionBank searchText={searchText} />
    case 'add-question':
      return <AddQuestionBank />
    case 'add-group-question':
      return <AddGroupQuestionBank />
    case 'edit-group-question':
      return <EditGroupQuestionBank />
    case 'edit-question':
      return <EditQuestionBank />
    case 'respondents':
      return <Respondents />
    case 'add-respondents':
      return <AddRespondents />
    case 'edit-respondents':
      return <EditRespondents />
    case 'respondents-group':
      return <RespondentsGroup />
    case 'add-respondents-group':
      return <AddRespondentsGroup />
    case 'edit-respondents-group':
      return <EditRespondentsGroup />
    case 'survey-center':
      return <SurveyCenter state={state} searchText={searchText} />
    case 'add-survey-center':
      return <AddSurveyCenter />
    case 'edit-survey-center':
      return <AddSurveyCenter />
    // <EditSurveyCenter />
    case 'detail-survey-center':
      return <SurveyDetail searchText={searchText} />
    case 'detail-asesi':
      return <DetailAsesiPage state={state} searchText={searchText} />
    case 'question-bank':
      return <QuestionBank state={state} searchText={searchText} />
    default:
      return <SurveyCenter state={state} searchText={searchText} />
  }
}

// const setTitle = active => {
//   switch (active) {
//     case 'survey-center':
//     case 'add-survey-center':
//     case 'edit-survey-center':
//     case 'detail-survey-center':
//       return '- Feedback Center'
//     case 'view-question-bank':
//     case 'detail-question-bank':
//     case 'add-question':
//     case 'add-group-question':
//     case 'edit-group-question':
//     case 'edit-question':
//     case 'question-bank':
//       return '- Question Bank'
//     default:
//       return ''
//   }
// }

const MultiraterNpsPage = props => {
  let active = ''
  let activeTab = ''
  let state = {}

  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
    switch (props.location.state.active) {
      case 'view-question-bank':
      case 'detail-question-bank':
      case 'add-question':
      case 'add-group-question':
      case 'edit-group-question':
      case 'edit-question':
      case 'question-bank':
        activeTab = 'question-bank'
        break
      case 'add-respondents':
        activeTab = 'respondents'
        break
      case 'add-respondents-group':
      case 'edit-respondents-group':
        activeTab = 'respondents-group'
        break
      case 'add-survey-center':
      case 'edit-survey-center':
      case 'detail-survey-center':
      case 'detail-asesi':
        activeTab = 'survey-center'
        break
      default:
        activeTab = props.location.state.active
    }
  }
  const data_tabs = [
    {
      label: 'Pusat Umpan Balik',
      state: 'survey-center',
      url: '/survey/multirater-nps/survey-center',
      searchable: true,
    },
    // {
    //   label: 'Respondents',
    //   state: 'respondents',
    //   url: '/survey/multirater-nps/respondents',
    //   searchable: true,
    // },
    // {
    //   label: 'Respondents Group',
    //   state: 'respondents-group',
    //   url: '/survey/multirater-nps/respondents-group',
    //   searchable: true,
    // },
    {
      label: 'Kumpulan Pertanyaan',
      state: 'question-bank',
      url: '/survey/multirater-nps/question-bank',
      searchable: true,
    },
  ]

  const [searchText, setSearchText] = useState('')
  const [debounced] = useDebounced(searchText, 500)

  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <div>
      <Card icon="access_time" title="Multirater & NPS" onSearch={onSearch}>
        <PageContainer>
          <WlbTab data={data_tabs} activeMenu={activeTab} />
          {renderComponent(active, state, debounced)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default withRouter(MultiraterNpsPage)
