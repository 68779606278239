import gql from 'graphql-tag'

export const GET_CITY = gql`
  query($prov: Int) {
    global_cities(where: {province: {_eq: $prov}}, order_by: {name: asc}) {
      id
      code
      name
    }
  }
`

export const GLOBAL_CITIES_SEARCHABLE = gql`
  query getCitiesByProvince(
    $search: String
    $limit: Int
    $offset: Int
    $province: Int
  ) {
    total_data: global_cities_aggregate(
      where: {
        province: {_eq: $province}
        name: {_ilike: $search}
        deletedAt: {_is_null: true}
      }
    ) {
      aggregate {
        count
      }
    }
    global_cities(
      where: {
        province: {_eq: $province}
        name: {_ilike: $search}
        deletedAt: {_is_null: true}
      }
      limit: $limit
      offset: $offset
      order_by: [{name: asc}]
    ) {
      id
      code
      name
      province
    }
  }
`
