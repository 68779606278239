import {getSearchVariables} from '../../../../utils/searchFilters'

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

export const MENUS = ['activity', 'trip-report', 'settings']

export const getTripFilterVariables = ({
  tabsValue,
  searchText = '',
  filterData = {},
}) => {
  const search = `%${searchText}%`

  const where = {
    status:
      tabsValue === 'request'
        ? {_in: ['pending', 'confirm1', 'confirm2']}
        : undefined,
    report_status:
      tabsValue === 'report'
        ? {_in: ['reported', 'verify', 'unverify']}
        : undefined,
    _or: searchText
      ? [
          {id_display: {_ilike: search}},
          {people_work_placement: {global_user: {name: {_ilike: search}}}},
          {people_work_placement: {regno: {_ilike: search}}},
        ]
      : undefined,
    people_work_placement: getSearchVariables(filterData),
  }

  return where
}
