import React from 'react'
import {
  Flex,
  FlexBetween,
  FlexCenters,
  TextGrey,
  TextDarkBlue,
  FlexBetweenCustom,
  TextHelper,
  ButtonBlue,
  TextBlackBold,
} from '../../../../../GlobalStyles'
import {
  Divider,
  Typography,
  Icon,
  Avatar,
  Button,
  TextField,
} from '@material-ui/core'
import moment from 'moment'
import Axios from 'axios'
import {REAST_API_URL, TOKEN, userData} from '../../../../../utils/globals'

const Review = ({data, classes, getReviews, averageReview, stars, index}) => {
  const [state, setState] = React.useState({
    isReplyReview: false,
    commentText: '',
  })

  const createReply = async () => {
    try {
      await Axios.patch(
        `${REAST_API_URL}/academy/enrollment/${data.id}/update`,
        {
          reviewReplyText: state.commentText,
        },
        {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      getReviews()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {index === 0 && <Divider className={classes.dividers} />}
      <Flex>
        <Avatar
          src={
            data.Student.avatar ||
            require('../../../../../assets/images/default-avatar.png')
          }
          className={classes.avatar}
          variant="circle"
        />
        <FlexBetween style={{paddingRight: 20}}>
          <Flex column>
            <FlexBetween>
              <Typography className="font-semibold">
                {data.Student.name}
              </Typography>
            </FlexBetween>
            <Flex column>
              <Flex>
                {averageReview !== 0 &&
                  stars.map((review, index) => {
                    if (index < averageReview) {
                      return (
                        <Icon
                          style={{
                            color: '#039be5',
                          }}
                          key={review + index}
                        >
                          star
                        </Icon>
                      )
                    }
                    return false
                  })}

                {averageReview !== 0 &&
                  stars.map((review, index) => {
                    if (index < stars.length - averageReview) {
                      return (
                        <Icon
                          style={{
                            color: 'grey',
                          }}
                          key={review + index}
                        >
                          star
                        </Icon>
                      )
                    }
                    return false
                  })}
              </Flex>
            </Flex>
            <TextBlackBold>{data.reviewText}</TextBlackBold>
            {!data.reviewReplyText && (
              <FlexCenters
                onClick={() =>
                  setState({...state, isReplyReview: !state.isReplyReview})
                }
              >
                <Icon style={{color: '#014a62', cursor: 'pointer'}}>reply</Icon>
                <TextDarkBlue>Reply</TextDarkBlue>
              </FlexCenters>
            )}
            {state.isReplyReview && (
              <Flex
                column
                style={{marginTop: 40, width: 512, cursor: 'pointer'}}
              >
                <Typography>Reply </Typography>
                <TextField
                  multiline={true}
                  variant="outlined"
                  minRows={7}
                  maxRows={7}
                  style={{width: '100%', marginTop: 5}}
                  placeholder="Add Reply"
                  value={state.commentText}
                  onChange={e =>
                    setState({...state, commentText: e.target.value})
                  }
                />
                <Flex style={{marginTop: 10}} flexEnd>
                  <TextHelper
                    style={{marginRight: 15, fontSize: 16, cursor: 'pointer'}}
                    onClick={() => setState({...state, isReplyReview: false})}
                  >
                    Cancel
                  </TextHelper>
                  <ButtonBlue onClick={() => createReply(data)}>
                    Reply
                  </ButtonBlue>
                </Flex>
              </Flex>
            )}
          </Flex>

          {/* <TextGrey>
            {data.dateAdded !== null
              ? moment(data.dateAdded).format('MMM Do YY')
              : 'just now'}
          </TextGrey> */}
        </FlexBetween>
        <TextGrey>
          {data.dateAdded !== null
            ? moment(data.dateAdded).format('MMM Do YY')
            : 'just now'}
        </TextGrey>
      </Flex>
      <Divider className={classes.dividers} />
      <Flex column>
        {data.reviewReplyText && (
          <Flex style={{marginLeft: 70, marginTop: 20, marginBottom: 20}}>
            <Avatar
              src={
                userData.avatar ||
                require('../../../../../assets/images/default-avatar.png')
              }
              className={classes.avatar}
              variant="circle"
            />
            <FlexBetween
              style={{
                paddingRight: 20,
              }}
            >
              <Flex column>
                <FlexBetween>
                  <Typography className="font-semibold">
                    {userData.name}
                  </Typography>
                </FlexBetween>
                <TextBlackBold>{data.reviewReplyText}</TextBlackBold>
              </Flex>

              <TextGrey>
                {data.reviewReplyDate !== null
                  ? moment(data.reviewReplyDate).format('MMM Do YY')
                  : 'just now'}
              </TextGrey>
            </FlexBetween>
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default function TabsReview({classes, course, getReviews, data}) {
  const stars = [1, 2, 3, 4, 5]
  let averageReview = 0

  return (
    <>
      <FlexBetweenCustom column>
        <FlexBetweenCustom width="75%">
          <Flex column flexCenter>
            {data &&
              data.map(review => {
                if (review.rating !== null) {
                  averageReview += parseInt(review.rating)
                }
                return true
              })}

            {averageReview !== 0 ? (
              <Typography className="text-grey-darker" variant="h2">
                {' '}
                {(averageReview / (data && data.length)).toFixed(2)}{' '}
              </Typography>
            ) : (
              <Typography className="text-grey-darker" variant="h2">
                {' '}
                0{' '}
              </Typography>
            )}
            <Flex>
              {averageReview !== 0 &&
                stars.map((review, index) => {
                  if (index < averageReview) {
                    return (
                      <Icon
                        style={{
                          color: '#039be5',
                        }}
                        key={review + index}
                      >
                        star
                      </Icon>
                    )
                  }
                  return false
                })}

              {averageReview !== 0 &&
                stars.map((review, index) => {
                  if (index < stars.length - averageReview) {
                    return (
                      <Icon
                        style={{
                          color: 'grey',
                        }}
                        key={review + index}
                      >
                        star
                      </Icon>
                    )
                  }
                  return false
                })}
            </Flex>
            <TextGrey>Berdasarkan {data.length} Ulasan</TextGrey>
          </Flex>
        </FlexBetweenCustom>
      </FlexBetweenCustom>
      <FlexBetweenCustom
        width="40%"
        style={{
          marginTop: 20,
        }}
      >
        <Button variant="outlined">Semua</Button>
        <Button
          variant="outlined"
          style={{
            marginLeft: 20,
          }}
        >
          <Icon
            style={{
              color: '#039be5',
            }}
          >
            star
          </Icon>
          <Typography className="ml-4">5</Typography>
        </Button>
        <Button
          variant="outlined"
          // className={classNames(classes.marginLeft10, classes.marginRight10)}
          style={{
            marginLeft: 20,
          }}
        >
          <Icon
            style={{
              color: '#039be5',
            }}
          >
            star
          </Icon>
          <Typography className="ml-4">4</Typography>
        </Button>
        <Button
          variant="outlined"
          style={{
            marginLeft: 20,
          }}
        >
          <Icon
            style={{
              color: '#039be5',
            }}
          >
            star
          </Icon>
          <Typography className="ml-4">3</Typography>
        </Button>
        <Button
          variant="outlined"
          style={{
            marginLeft: 20,
          }}
        >
          <Icon
            style={{
              color: '#039be5',
            }}
          >
            star
          </Icon>
          <Typography className="ml-4">2</Typography>
        </Button>
        <Button
          variant="outlined"
          style={{
            marginLeft: 20,
          }}
        >
          <Icon
            style={{
              color: '#039be5',
            }}
          >
            star
          </Icon>
          <Typography className="ml-4">1</Typography>
        </Button>
      </FlexBetweenCustom>

      {data.map((data, index) => (
        <Review
          key={index}
          course={course}
          data={data}
          classes={classes}
          getReviews={getReviews}
          averageReview={averageReview}
          stars={stars}
          index={index}
        />
      ))}
    </>
  )
}
