import React, {useState} from 'react'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {WrapperSection} from '../../DetailEmployeeStyles'
import {ButtonAdd} from '../../SharedDetailEmployee'
import AddEditSkill from './AddEditSkill'
import {SkilsItem} from './SkilsItem'

export default function Skils({
  people_profile_skills,
  user_name_long,
  user_name,
  refetch,
  setAction,
  enqueueSnackbar,
  userIdDetail,
  action,
  INITIAL_ACTION_STATE,
}) {
  const [open, setOpen] = useState(false)

  const _renderItem = (data, isDetail = false) => {
    return data.slice(0, isDetail ? data.length : 2).map((res, i) => {
      res.user = userIdDetail
      return (
        <SkilsItem
          key={`${i}`}
          res={res}
          onEdit={() =>
            handleOpenForm({
              actionType: 'edit',
              data: res,
              isFromModal: action.isFromModal,
            })
          }
        />
      )
    })
  }

  const handleCloseAction = () => {
    if (action.isFromModal) {
      setOpen(true)
    }
    setAction(INITIAL_ACTION_STATE)
  }

  const handleOpenForm = ({actionType, data = null, isFromModal}) => {
    if (isFromModal) {
      setOpen(false)
    }
    setAction(e => ({
      ...e,
      open: true,
      actionType,
      data,
      isFromModal,
    }))
  }

  return (
    <>
      <WrapperSection>
        {_renderItem(people_profile_skills)}
        {people_profile_skills.length > 0 && (
          <h3 onClick={() => setOpen(true)} className="show-more">
            Tampilkan Lebih Banyak Keahlian...
          </h3>
        )}
      </WrapperSection>

      <ModalDetail
        onClose={() => setOpen(false)}
        open={open}
        title={`Keahlian ${user_name}`}
      >
        {_renderItem(people_profile_skills, true)}
        <ButtonAdd
          label="Keahlian"
          onClick={() =>
            handleOpenForm({
              actionType: 'add',
              isFromModal: true,
            })
          }
        />
      </ModalDetail>

      <AddEditSkill
        setAction={setAction}
        refetch={refetch}
        enqueueSnackbar={enqueueSnackbar}
        userIdDetail={userIdDetail}
        open={action.open}
        data={action.data}
        user_name_long={user_name_long}
        onClose={handleCloseAction}
        actionType={action.actionType}
      />
    </>
  )
}
