import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Button, Divider, Popover} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import InformationItem from '../basic-information-item/InformationItem'

import {
  AsigneeComponentWrapper,
  ExpansionPanelDetailStyle,
  ExpansionPanelStyle,
  ExpansionPanelSummaryStyle,
  ItemMenu,
  MediumTextBlackBold,
} from '../../pages/financial/payroll/PayrollPageStyles'

import {capitalizeEachWord} from '../../utils/helpers'

export default function ExpansionPanel(props) {
  const {
    ApplyToModal,
    applyToSectionTitle,
    data,
    dataApplyTo,
    dataDetailSection,
    detailSectionTitle,
    editPathname,
    handleDelete,
    isOpen = false,
    panelStyle,
    panelTitle,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [isExpanded, setIsExpanded] = useState(isOpen)
  const [isModalShow, setIsModalShow] = useState(false)

  const open = Boolean(anchorEl)
  const id = open ? 'more-regulations' : undefined

  return (
    <>
      <ExpansionPanelStyle
        style={{...panelStyle}}
        expanded={isExpanded}
        /* eslint-disable no-unused-vars */
        onChange={(_, value) => {
          setIsExpanded(value)
        }}
      >
        <ExpansionPanelSummaryStyle>
          <MediumTextBlackBold>
            {capitalizeEachWord(panelTitle)}
          </MediumTextBlackBold>

          <MoreHorizIcon
            aria-describedby={id}
            onClick={e => {
              e.stopPropagation()
              setAnchorEl(e.currentTarget)
            }}
          />
        </ExpansionPanelSummaryStyle>

        <ExpansionPanelDetailStyle>
          <h4>{detailSectionTitle}</h4>

          <Divider orientation="horizontal" flexItem />

          {dataDetailSection?.map((item, index) => {
            return (
              <InformationItem
                key={index}
                titleStyle={{minWidth: '30%'}}
                descStyle={{minWidth: '50%'}}
                {...item}
              />
            )
          })}

          {dataApplyTo?.length > 0 && (
            <>
              <h4>{applyToSectionTitle}</h4>

              <AsigneeComponentWrapper>
                {/**
                   * TODO: map data into <ApplyToItem />
                   * slice 0,12 -> map
                  <ul>
                  </ul>
                */}

                {dataApplyTo?.length > 12 && (
                  <div
                    className="view-more"
                    onClick={() => setIsModalShow(true)}
                  >
                    View More
                  </div>
                )}
              </AsigneeComponentWrapper>

              {ApplyToModal && (
                <ApplyToModal
                  open={isModalShow}
                  onClose={() => setIsModalShow(false)}
                  id={dataApplyTo.id}
                />
              )}
            </>
          )}
        </ExpansionPanelDetailStyle>
      </ExpansionPanelStyle>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ItemMenu>
          <Button component={Link} to={editPathname} className="item">
            Edit
          </Button>

          <Button
            onClick={() => {
              handleDelete(data)
              setAnchorEl(null)
            }}
            className="item item-delete"
          >
            Delete
          </Button>
        </ItemMenu>
      </Popover>
    </>
  )
}
