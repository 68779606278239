import CrossMentoringPageComponent from './CrossMentoringPageComponent'

export const CrossMentoringPageConfig = {
  routes: [
    {
      path: '/holding-learning/cross-mentoring',
      component: CrossMentoringPageComponent,
      state: 'cross-mentoring-list',
      exact: true,
    },
    {
      path: '/holding-learning/cross-mentoring-detail/:id',
      component: CrossMentoringPageComponent,
      state: 'cross-mentoring-detail',
      exact: true,
    },
    {
      path: '/holding-learning/add-version',
      component: CrossMentoringPageComponent,
      state: 'add-version',
      exact: true,
    },
    {
      path: '/holding-learning/:id/add-group-learning',
      component: CrossMentoringPageComponent,
      state: 'add-classroom',
      exact: true,
    },
    {
      path: '/holding-learning/:id/edit-group-learning',
      component: CrossMentoringPageComponent,
      state: 'edit-group-learning',
      exact: true,
    },
  ],
}
