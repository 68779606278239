import ClassificationPage from './ClassificationPageComponent'

export const ClassificationPageConfig = {
  routes: [
    {
      path: '/talent/classification',
      component: ClassificationPage,
      state: 'classification',
      exact: true,
    },
    {
      path: '/talent/classification/talent-detail',
      component: ClassificationPage,
      state: 'classification-detail',
      exact: true,
    },
    {
      path: '/talent/classification/add',
      component: ClassificationPage,
      state: 'add-classification',
      exact: true,
    },
    {
      path: '/talent/classification/edit/:id',
      component: ClassificationPage,
      state: 'edit-classification',
      exact: true,
    },
  ],
}
