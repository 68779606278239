import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import MaterialTable from 'material-table'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
} from '../../../../GlobalStyles'
import {Link} from 'react-router-dom'
import Loading from '../../../../components/loading/LoadingComponent'
import {GET_LIST_GROUP_QUESTION_BANK} from '../../../../graphql/queries'
import {DELETE_GROUP_QUESTION_BANK} from '../../../../graphql/mutations'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../utils/globals'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {useSnackbar} from 'notistack'
import {TableLocalization} from '../../../shared-components/table/TableOptions'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'

const TableOptions = {
  search: false,
  selection: false,
  sorting: true,
  headerStyle: {
    fontWeight: 700,
    lineHeight: 1.43,
    marginTop: `-100px`,
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
  selectionProps: () => ({
    color: 'primary',
  }),
  toolbar: false,
}

const QuestionBank = props => {
  const {searchText} = props
  const {enqueueSnackbar} = useSnackbar()
  const [state, setState] = useState({
    id: '',
    name: '',
  })
  const [openDelete, setOpenDelete] = useState(false)
  const {data, error, refetch} = useQuery(GET_LIST_GROUP_QUESTION_BANK, {
    fetchPolicy: 'cache-and-network',
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
      search: `%${searchText}%`,
    },
  })

  const [mutationDeleteQuestion] = useMutation(DELETE_GROUP_QUESTION_BANK)

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const handleClickOpen = (id, name) => {
    setState({id, name})
    setOpenDelete(true)
  }

  const handleClose = () => {
    setOpenDelete(false)
  }

  const onDeleteQuestion = () => {
    const variables = {
      id: state.id,
    }

    mutationDeleteQuestion({variables})
      .then(() => {
        enqueueSnackbar('Question Group deleted', {
          variant: 'success',
          autoHideDuration: 500,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete scoring failed, please try again later', {
          variant: 'error',
          autoHideDuration: 500,
        })
      })
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="subtitle1">Kumpulan Pertanyaan</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: `/survey/multirater-nps/question-bank/add`,
              state: {active: 'add-group-question'},
            }}
          >
            Tambahkan Grup Umpan Balik
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider></Divider>
      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0'}}
        columns={[
          {
            title: 'No',
            render: rowData => rowData.tableData.id + 1,
          },
          {
            title: 'Grup umpan balik',
            field: 'name',
          },
          {
            title: 'Deskripsi',
            field: 'description',
          },
          {
            title: 'Total Pertanyaan',
            field: 'multirater_bank_questions_aggregate.aggregate.count',
          },
          {
            title: 'Aksi',
            field: 'id',
            sorting: false,
            render: rowData => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        component={Link}
                        to={{
                          pathname: `/survey/multirater-nps/question-bank/${rowData.id}`,
                          state: {
                            active: 'view-question-bank',
                          },
                        }}
                        variant="h6"
                      >
                        Lihat Pertanyaan
                      </StyledMenuItem>
                      <StyledMenuItem
                        component={Link}
                        to={{
                          pathname: `/survey/multirater-nps/question-bank/${rowData.id}/edit`,
                          state: {
                            active: 'edit-group-question',
                          },
                        }}
                        variant="h6"
                      >
                        Ubah
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          handleClickOpen(rowData.id, '')
                          popupState.close()
                        }}
                      >
                        Hapus
                      </StyledMenuItem>
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            ),
          },
        ]}
        data={data.multirater_bank_groups}
        title=""
        options={TableOptions}
        components={{
          Toolbar: () => null,
        }}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent />,
          },
        }}
      />
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        id={state.id}
        name={state.name}
        mutation={() => onDeleteQuestion()}
      />
    </PaperContainer>
  )
}

export default QuestionBank
