import {Typography} from '@material-ui/core'
import React, {useState} from 'react'
import {
  ContainerApplied,
  ContainerListApplied,
  CountApplied,
  ListApplied,
} from '../../../GlobalStyles'

const AppliedContent = ({applied, classes, target}) => {
  const [limit, setLimit] = useState(5)

  return (
    <ContainerApplied style={{marginBottom: 10}}>
      <Typography variant="body2" display="inline">
        Perubahan akan diterapkan pada {target} berikut ini:
      </Typography>
      <ContainerListApplied>
        {applied &&
          applied.slice(0, limit).map((data, index) => {
            return (
              <ListApplied key={index} className={classes.marginTop5}>
                {data.title || data.name}
              </ListApplied>
            )
          })}
        {applied && applied.length >= 5 && limit <= 5 ? (
          <CountApplied
            className={classes.marginTop10}
            onClick={() => setLimit(applied && applied.length)}
          >
            {(applied && applied.length - 5) === 0
              ? ''
              : `Lihat semua ${applied && applied.length - 5} ${target}`}
          </CountApplied>
        ) : (
          <CountApplied
            className={classes.marginTop10}
            onClick={() => setLimit(5)}
          >
            {applied && applied.length >= 5 ? 'Lihat lebih sedikit' : ''}
          </CountApplied>
        )}
      </ContainerListApplied>
    </ContainerApplied>
  )
}

export default AppliedContent
