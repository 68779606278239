import gql from 'graphql-tag'

export const LIST_SCHOOL = gql`
  query GetListSchool($search: String, $id: uuid, $offset: Int, $limit: Int) {
    company_profiles(
      where: {
        deletedAt: {_is_null: true}
        id: {_eq: $id}
        types: {_ilike: "_1%"}
        legal_name: {_ilike: $search}
      }
      order_by: [{legal_name: asc}]
      offset: $offset
      limit: $limit
    ) {
      id
      logo
      legal_name
    }
  }
`

export const LIST_FACULTY = gql`
  query GetListFaculty($search: String, $limit: Int, $id: Int) {
    global_faculties(
      where: {
        id: {_eq: $id}
        name: {_ilike: $search}
        deleted_at: {_is_null: true}
      }
      limit: $limit
    ) {
      id
      name
    }
  }
`

export const LIST_DEGREE = gql`
  query GetListDegree($search: String, $limit: Int, $id: Int) {
    global_degrees(
      where: {id: {_eq: $id}, name: {_ilike: $search}}
      limit: $limit
    ) {
      id
      name
    }
  }
`

export const LIST_MAJOR = gql`
  query GetListMajor($search: String, $id: Int, $limit: Int) {
    global_major(
      where: {
        id: {_eq: $id}
        name: {_ilike: $search}
        deletedAt: {_is_null: true}
      }
      limit: $limit
      order_by: {id: asc}
    ) {
      id
      name
    }
  }
`

export const GET_LIST_SKILL_CATEGORY = gql`
  query GetListSkillCategory {
    people_skills(where: {parent: {_is_null: true}}, order_by: [{name: asc}]) {
      id
      name
    }
  }
`

export const GET_LIST_SKILL = gql`
  query GetListSkill($search: String, $limit: Int, $offset: Int) {
    skills: people_skills(
      where: {
        _and: [
          {deletedAt: {_is_null: true}}
          {_or: [{name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
`

export const GET_LIST_SKILL_LEVEL = gql`
  query GetListSkillLevel {
    levels: people_skill_levels(order_by: [{id: asc}]) {
      id
      name
    }
  }
`

export const GET_PEOPLE_PROFILE_PROJECT_POSITION = gql`
  query GetPeopleProfileProjectPosition(
    $userId: uuid!
    $search: String
    $limit: Int
  ) {
    experiences: people_profile_experiences(
      where: {
        user: {_eq: $userId}
        deletedAt: {_is_null: true}
        _or: [{company_name: {_ilike: $search}}, {title: {_ilike: $search}}]
      }
      limit: $limit
    ) {
      id
      work_at: company_name
      as: title
    }
    educations: people_profile_educations(
      where: {
        user: {_eq: $userId}
        deletedAt: {_is_null: true}
        _or: [{company_profile: {brand_name: {_ilike: $search}}}]
      }
      limit: $limit
    ) {
      id
      study_at: company_profile {
        id
        brand_name
      }
    }
  }
`
