import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router'
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
} from '../../../../GlobalStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import ManageLanding from './tabs/CourseManageLanding'
import ManageCurriculum from './tabs/CourseManageCurriculum'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {GET_FILTER_BY_COURSE_ID} from '../../../../graphql/queries'
import {ASSIGN_TO_COURSE} from '../../../../graphql/mutations/learning/course/addCourse.mutation'
import {UPDATE_ASSIGNMENT_COURSE} from '../../../../graphql/mutations/learning/course/updateCourse.mutation'
import CourseAssign from './tabs/CourseAssignComponent'
import {useSnackbar} from 'notistack'
import {
  COMPANY_ID,
  REAST_API_URL,
  TOKEN,
  USER_ID,
} from '../../../../utils/globals'
import {manipulateFilter} from '../../../time/attendance/work-schedule/filterDataAssignment'
import {disabledSpecialChar} from '../../../../utils/helpers'

import Axios from 'axios'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },

  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  mb16: {
    marginBottom: '16px !important',
  },
  paper: {
    minHeight: '70vh',
  },
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
}))

const style = {
  colorPrimary: {
    color: '#014A62',
  },
  saveBtn: {
    backgroundColor: '#039BE5',
    color: '#fff',
    textTransform: 'none',
    marginLeft: 12,
  },
  header: {
    backgroundColor: '#fff',
    width: '-webkit-fill-available',
    boxShadow: '0px 3px 6px #0000001A',
    zIndex: 1,
  },
  grey: {color: '#A9A8A8'},
  chipSkyBlue: {backgroundColor: '#039BE5', marginRight: 16, marginTop: 16},
  iconSkyBlue: {
    color: '#039BE5',
    border: '1px solid #039BE5',
    padding: 5,
    marginTop: 12,
  },
  iconDisabled: {
    color: '#A9A8A8',
    border: '1px solid #A9A8A8',
    padding: 5,
    marginTop: 12,
  },
  iconRed: {
    color: '#DD5962',
    border: '1px solid #DD5962',
    padding: 5,
    marginTop: 12,
  },
  addContainer: {
    background: '#F7F8F9 0% 0% no-repeat padding-box',
    border: '1px dashed #A9A8A8',
    width: '400px',
    padding: '85px 0',
    textAlign: 'center',
    marginTop: 40,

    position: 'relative',
  },
  input: {
    opacity: 0.0,
    MsFilter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)',
    filter: 'alpha(opacity=0)',
    MozOpacity: 0.0,
    KhtmlOpacity: 0.0,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  expansionWithoutBottom: {background: '#f7f8f9'},
  expansion: {background: '#f7f8f9', marginBottom: 20},
  customEditorFooter: {
    width: '100%',
    height: 20,
    borderRadius: '0 0 5px 5px',
    backgroundColor: '#014a62',
  },
  textSkyBlue: {color: '#039BE5'},
  uploadedImg: {width: 400, marginRight: 16},
  answerContainer: {
    border: '1px solid #E5E5E5',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    padding: 6,
    marginBottom: 16,
  },
  topicContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const FormCourse = props => {
  const {id} = props.match.params
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const [activeStep, setActiveStep] = useState(0)
  const [confirmType, setConfirmType] = useState('')
  const [value, setValue] = useState({
    name: '',
    description: '',
    slug: '',
    isSlugExist: false,
    banner_url: '',
    promotional_url: '',
    topic: [],
    category_id: '',
    level: '',
    language_id: '',
    subtitle: '',
    status: '',
    subcategory: '',
    price: 0,
    type: '',
    course: '',
  })
  const [courseId, setCourseId] = useState(null)
  // const [courseId, setCourseId] = useState(284)

  const [isError, setIsError] = useState(false)

  const [filter, setFilter] = useState({
    filterJobFunction: {},
    filterJobFamily: {},
    filterJobTitle: {},
    filterOrgUnit: {},
    filterOrgLevel: {},
    filterJobLevel: {},
    filterJobGrade: {},
    filterOffice: {},
    filterPosition: {},
  })

  const [countApplied, setCountApplied] = useState(null)

  const {data: dataFilter} = useQuery(GET_FILTER_BY_COURSE_ID, {
    variables: {
      courseId: id,
    },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  })

  const [assignToCourse] = useMutation(ASSIGN_TO_COURSE)
  const [updateAssignmentCourse] = useMutation(UPDATE_ASSIGNMENT_COURSE)

  useEffect(() => {
    if (id) {
      getCourseById()
    }
  }, [])

  useEffect(() => {
    if (dataFilter) {
      const _dataFilter =
        dataFilter.course_organization_assignments.length > 0 &&
        dataFilter.course_organization_assignments[0].filter
      if (_dataFilter) {
        const _newFilter = {}
        const isFilterExist = (data, stateFilter) => {
          let inOrNin = {_in: _dataFilter[data]}
          if (data.includes('exclude')) {
            inOrNin = {_nin: _dataFilter[data]}
          }
          if (inOrNin._in || inOrNin._nin) {
            _newFilter[stateFilter] = {
              ..._newFilter[stateFilter],
              ...inOrNin,
            }
          }
        }
        isFilterExist('filter_exclude_org_unit', 'filterOrgUnit')
        isFilterExist('filter_include_org_unit', 'filterOrgUnit')
        isFilterExist('filter_include_org_level', 'filterOrgLevel')
        isFilterExist('filter_exclude_org_level', 'filterOrgLevel')
        isFilterExist('filter_exclude_position', 'filterPosition')
        isFilterExist('filter_include_position', 'filterPosition')
        isFilterExist('filter_exclude_job_title', 'filterJobTitle')
        isFilterExist('filter_include_job_title', 'filterJobTitle')
        isFilterExist('filter_exclude_job_level', 'filterJobLevel')
        isFilterExist('filter_include_job_level', 'filterJobLevel')
        isFilterExist('filter_exclude_office', 'filterOffice')
        isFilterExist('filter_include_office', 'filterOffice')
        isFilterExist('filter_exclude_job_grade', 'filterJobGrade')
        isFilterExist('filter_include_job_grade', 'filterJobGrade')
        setFilter(_newFilter)
      }
    }
  }, [dataFilter])

  const handleConfirm = type => {
    setOpen(true)
    setConfirmType(type)
  }

  const handleBack = () => {
    if (activeStep === 1) {
      setCourseId(null)
      deleteCourse()
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const isSlugExist = value.isSlugExist

  /* const _dataQuest =
    dataQuest && dataQuest.microlearnings[0].microlearning_questions */

  const stepOneValidation =
    !value.name ||
    !value.slug ||
    value.topic.length === 0 ||
    !value.category_id ||
    !value.level ||
    !value.language_id ||
    !value.subcategory ||
    isSlugExist

  const createCourse = async (isSaveAsDraft = false) => {
    try {
      const response = await Axios.post(
        `${REAST_API_URL}/academy/course/create`,
        {
          title: value.name,
          subtitle: value.subtitle,
          slug: value.slug,
          description: value.description,
          language: value.language_id,
          level: value.level,
          category: value.subcategory,
          topic: value.topic,
          thumbnail: value.banner_url,
          video: value.promotional_url,
          status: 'draft',
          type: 1,
          creator: USER_ID,
          organizationId: COMPANY_ID,
          priceTier: value.price,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + (TOKEN || ''),
          },
        }
      )

      if (isSaveAsDraft) {
        return props.history.goBack()
      }
      setCourseId(response.data.id)
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getCourseById = async () => {
    try {
      Axios.get(`${REAST_API_URL}/academy/course/${id}`, {
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + TOKEN,
        },
      })
        .then(response => {
          setValue({
            ...value,
            name: response.data.title,
            subtitle: response.data.subtitle,
            slug: response.data.slug,
            description: response.data.description,
            language_id: response.data.language,
            level: response.data.level,
            subcategory: response.data.category,

            topic: response.data.topic,
            banner_url: response.data.thumbnail,
            promotional_url: response.data.video,
            status: response.data.status,
          })

          // setFollower({
          //   follower: response.data.data.total_follower,
          //   following: response.data.data.total_following,
          // })
        })
        .finally(() => {
          setIsLoading(false)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const checkCourseSlug = async (isSaveAsDraft = false) => {
    try {
      Axios.get(`${REAST_API_URL}/academy/course/get/${value.slug}`, {
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + TOKEN,
        },
      })
        .then(() => {
          setValue({
            ...value,
            isSlugExist: true,
          })

          // setFollower({
          //   follower: response.data.data.total_follower,
          //   following: response.data.data.total_following,
          // })
          if (id) {
            return updateCourse()
          }
        })
        .catch(() => {
          setValue({
            ...value,
            isSlugExist: false,
          })
          if (!courseId) {
            createCourse(isSaveAsDraft)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const deleteCourse = async () => {
    try {
      await Axios.delete(
        `${REAST_API_URL}/academy/course/${courseId || id}/remove`,
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )
    } catch (error) {
      console.error(error)
    }
  }
  // logic button save add
  const handleNext = async () => {
    setValue({...value, isSlugExist: false})
    if (stepOneValidation) {
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 3000)
    } else {
      if (activeStep === 0) {
        setIsLoading(true)
        // pertama kali add
        if (!courseId) {
          checkCourseSlug()
        }
      } else if (activeStep === 2) {
        handleConfirm('add')
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    }
  }

  // logic button save edit
  const handleSave = () => {
    /* if (courseId || id) {
      handleConfirm('publish')
    } */
    handleConfirm('publish')
    const errorPage = page => {
      setActiveStep(page)
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 3000)
    }
    if (stepOneValidation) {
      errorPage(0)
    } else if (value.type === 'ORG' && (!countApplied || countApplied === 0)) {
      /* else if (_dataQuest.length === 0) {
      errorPage(1)
    } */
      errorPage(2)
    } else {
      handleConfirm('publish')
    }
  }

  const activatedCourse = async () => {
    try {
      await Axios.patch(
        `${REAST_API_URL}/academy/course/${courseId || id}/update`,
        {
          status: 'active',
        },
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      props.history.goBack()
    } catch (error) {
      console.error(error)
    }
  }
  const updateCourse = async () => {
    try {
      await Axios.patch(
        `${REAST_API_URL}/academy/course/${id}/update`,
        {
          ...value,
          language: value.language_id,
          title: value.name,
          type: 1,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )
      if (activeStep === 2) {
        return onAssignCourse()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onAssignCourse = () => {
    if (id && dataFilter?.course_organization_assignments?.length > 0) {
      if (activeStep === 2) {
        updateAssignmentCourse({
          variables: {
            filter: manipulateFilter(filter),
            orgAssignmentId:
              dataFilter && dataFilter.course_organization_assignments[0].id,
          },
        })
          .then(() => {
            setIsLoading(false)
            activatedCourse()
          })
          .catch(e =>
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          )
      } else {
        activatedCourse()
      }
    } else {
      assignToCourse({
        variables: {
          filter: manipulateFilter(filter),
          courseId: courseId || id,
        },
      })
        .then(() => {
          setIsLoading(false)
          //
          activatedCourse()
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
    }
  }

  const handleMutation = () => {
    onAssignCourse()
  }

  const handleChange = prop => event => {
    const _value =
      prop === 'name' ||
      prop === 'subtitle' ||
      prop === 'slug' ||
      prop === 'description'
        ? disabledSpecialChar(event.target.value)
        : event.target.value
    const vals = {
      ...value,
      [prop]: _value,
    }
    if (prop === 'category_id') {
      vals.subcategory = null
    }
    setValue(vals)
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setActiveStep(newValue)
  }

  const handleSaveAsDraft = () => {
    setValue({...value, isSlugExist: false})

    setIsLoading(true)
    setConfirmType('save as draft')
    if (activeStep === 1) {
      return props.history.goBack()
    } else if (activeStep === 2) {
      if (!id) {
        assignToCourse({
          variables: {
            filter: manipulateFilter(filter),
            courseId: courseId || id,
          },
        })
          .then(() => {
            setIsLoading(false)
          })
          .catch(e =>
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          )
      } else {
        updateAssignmentCourse({
          variables: {
            filter: manipulateFilter(filter),
            orgAssignmentId:
              dataFilter && dataFilter.course_organization_assignments[0].id,
          },
        })
          .then(() => {
            setIsLoading(false)
          })
          .catch(e =>
            enqueueSnackbar(JSON.stringify(e), {
              variant: 'error',
            })
          )
      }
    }

    if (!courseId) {
      checkCourseSlug(true)
    }
  }

  const handleDiscard = () => {
    if (activeStep === 1 && courseId) {
      return deleteCourse()
    }
    return props.history.goBack()
  }

  const SaveAsDraftBtn = props => {
    const {title} = props
    return (
      <Button
        onClick={handleSaveAsDraft}
        size="large"
        variant="outlined"
        color="primary"
        className={classes.backButton}
      >
        {title}
      </Button>
    )
  }

  const steps = ['Halaman Arahan', 'Kurikulum', 'Penerima Tugas']
  /* if (id && value.type !== 'ORG') {
    steps.pop()
  } */

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <ManageLanding
            style={style}
            value={value}
            handleChange={handleChange}
            setValue={setValue}
            isError={isError}
            isSlugExist={isSlugExist}
            checkCourseSlug={checkCourseSlug}
            courseId={courseId || id}
          />
        )
      case 1:
        return (
          <ManageCurriculum
            style={style}
            courseId={courseId || id}
            // value={value}
            isInCourseDetail={false}
            // microlearningId={'7c85c040-195f-45a9-8597-130fa2d39c6f'}
          />
        )
      case 2:
        return (
          <CourseAssign
            variables={filter}
            setVars={setFilter}
            isError={isError}
            count={countApplied}
            setCount={setCountApplied}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          {!isLoading && (
            <IconButton
              edge="end"
              aria-label="back"
              onClick={() => handleConfirm('discard')}
            >
              <ArrowBack />
            </IconButton>
          )}
          <AddEditTitle variant="h6">
            {id ? 'Ubah' : 'Buat'} Kursus {id && '- ' + value.name}
          </AddEditTitle>
        </FormTitleContainer>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {id ? (
              <div>
                <SaveAsDraftBtn title="Simpan Perubahan" />

                {value.status !== 'active' && (
                  <Button
                    onClick={handleSave}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Menerbitkan
                  </Button>
                )}
              </div>
            ) : (
              <div>
                {activeStep === 0 ? (
                  <Button
                    className={classes.backButton}
                    onClick={() => handleConfirm('discard')}
                  >
                    Batal
                  </Button>
                ) : (
                  <Button onClick={handleBack} className={classes.backButton}>
                    Sebelumnya
                  </Button>
                )}
                {activeStep < steps.length - 1 && (
                  <SaveAsDraftBtn title="Simpan sebagai Draft" />
                )}
                <Button
                  className={
                    activeStep === steps.length - 1 ? classes.backButton : null
                  }
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1
                    ? 'Menerbitkan'
                    : 'Selanjutnya'}
                </Button>
                {activeStep === steps.length - 1 && (
                  <SaveAsDraftBtn title="Simpan sebagai Draft" />
                )}
              </div>
            )}
          </>
        )}
      </FormToolbar>

      {id ? (
        <Tabs
          value={activeStep}
          onChange={handleChangeTabs}
          indicatorColor="secondary"
          classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
        >
          {steps.map(label => (
            <Tab label={label} key={label} className={classes.tabRoot} />
          ))}
        </Tabs>
      ) : (
        <>
          <Divider className={classes.mb16} />
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<CustomConnector />}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    active: classes.labelActive,
                    completed: classes.labelActive,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.iconRoot,
                      text: classes.iconText,
                      active: classes.iconActive,
                      completed: classes.iconActive,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      )}

      <div style={{padding: '2rem'}}>{getStepContent(activeStep)}</div>
      <AddEdit
        open={open}
        handleClose={() => setOpen(false)}
        mutation={confirmType === 'discard' ? handleDiscard : handleMutation}
        header={CONFIRMATION[confirmType]?.header}
        body={CONFIRMATION[confirmType]?.body}
        button={CONFIRMATION[confirmType]?.button}
        buttonClose="Batal"
        disabled={isLoading}
      />
    </PaperContainer>
  )
}

export default withRouter(FormCourse)

const CONFIRMATION = {
  add: {
    header: 'Tambah Kursus?',
    body: 'Apakah Anda yakin akan menambah kursus ini?',
    button: 'Kirim',
  },
  publish: {
    header: 'Menerbitkan Kursus?',
    body: 'Apakah Anda yakin akan menerbitkan kursus ini?',
    button: 'Menerbitkan',
  },
  discard: {
    header: 'Buang Perubahan?',
    body: 'Apakah Anda yakin ingin membuang perubahan yang belum disimpan?',
    button: 'Buang',
  },
}
