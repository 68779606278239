import gql from 'graphql-tag'

export const DELETE_CROSS_MENTORING = gql`
  mutation($id: uuid!) {
    update_classroom_cross_mentoring_versions(
      _set: {deleted_at: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
