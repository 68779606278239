import React, {useState, useEffect} from 'react'
import {
  RowWrapper,
  ButtonStyled,
  // ButtonStyledNPS,
} from '../../SurveyCenterStyled'
import {Typography, FormHelperText} from '@material-ui/core'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_DROPDOWN_IMPORT,
  GET_LIST_GROUP_QUESTION_STEPPER,
} from '../../../../../../graphql/queries/survey/multirater-nps/survey-center/getListSurveyCenter.query'
import {
  ADD_GROUP_QUESTION_STEP_TWO,
  ADD_QUESTION_STEP_TWO,
} from '../../../../../../graphql/mutations/survey/multirater-nps/survey-center/surveyCenter.mutation'
import {useSnackbar} from 'notistack'
import SpeedIcon from '@material-ui/icons/Speed'
import FieldInput from './FieldNPSInput'
import MultiraterIcon from '../../../../../../assets/images/multirater-icon.png'

import MultiraterGroupExpan from './MultiraterGroupExpan'
import DialogImportQuestion from './DialogImportQuestion'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {COMPANY_ID} from '../../../../../../utils/globals'

const StepTwoPlus = props => {
  const {enqueueSnackbar} = useSnackbar()
  const [addSurvey, setAddSurvey] = useState({
    multirater: false,
    nps: false,
  })
  const [addQuestion, setAddQuestion] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [isError, setIsError] = useState(false)
  const {
    dataContext,
    checked,
    setListQuestions,
    isError: isErrorNextStep,
  } = props
  const [teks, setTeks] = useState({
    group: '',
    question: '',
    isnps: null,
  })
  const [listData, setListData] = useState([])

  const {data, refetch} = useQuery(GET_LIST_GROUP_QUESTION_STEPPER, {
    variables: {
      survey: dataContext.dataSurveyCenter && dataContext.dataSurveyCenter.id,
    },
    fetchPolicy: 'network-only',
  })

  const {data: dataGroup} = useQuery(GET_DROPDOWN_IMPORT, {
    variables: {
      company: COMPANY_ID,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [mutationAddGroup] = useMutation(ADD_GROUP_QUESTION_STEP_TWO)
  const [mutationAddQuestion] = useMutation(ADD_QUESTION_STEP_TWO)

  useEffect(() => {
    if (data) {
      // if (listData.length === 0) {
      const dataToState = data.multirater_survey_groups
      setListData(dataToState)
      // }
      setListQuestions(dataToState)
    }
  }, [data])

  const handleAddGroup = (number, isnps) => {
    if (teks.group.length < 1) {
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const objects = {
        survey: dataContext.dataSurveyCenter.id,
        isnps,
        name: teks.group,
        number: number + 1,
      }

      if (isnps) {
        objects.multirater_survey_questions = {
          data: [
            {
              number: 1,
              question_self:
                'Seberapa besar kemungkinan Anda akan merekomendasikan pegawai ini kepada teman atau kolega?',
              question_others:
                'Seberapa besar kemungkinan Anda akan merekomendasikan pegawai ini kepada teman atau kolega?',
            },
          ],
        }
      }
      mutationAddGroup({
        variables: {
          objects,
        },
      })
        .then(() => {
          // setListData([])
          refetch()
          setAddSurvey({
            multirater: false,
            nps: false,
          })
          // enqueueSnackbar('Success Update data', {variant: 'success'})
          setTeks({
            ...teks,
            group: '',
            question: '',
          })
        })
        .catch(() => {
          enqueueSnackbar('Update data error, please try again', {
            variant: 'error',
          })
        })
    }
  }

  const handleChangeGroup = isnps => e => {
    setTeks({
      ...teks,
      group: e.target.value,
      isnps,
    })
  }

  const handleSubmitQuestionGroup = (id, number) => {
    if (teks.question.length < 1) {
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      mutationAddQuestion({
        variables: {
          object: [
            {
              group: id,
              question_self: teks.question,
              question_others: '',
              number: number + 1,
            },
          ],
        },
      })
        .then(() => {
          // setListData([])
          refetch()
          setAddQuestion(false)
          // enqueueSnackbar('Success Update data', {variant: 'success'})
          setTeks({
            ...teks,
            question: '',
          })
        })
        .catch(() => {
          enqueueSnackbar('Update data error, please try again', {
            variant: 'error',
          })
        })
    }
  }

  const handleChangeQuestion = e => {
    setTeks({
      ...teks,
      question: e.target.value,
    })
  }

  const handleOpenGrupQuestion = () => {
    setAddQuestion(true)
  }
  const handleCloseGrupQuestion = () => {
    setAddQuestion(false)
  }

  const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    if (result.type === 'GROUPS') {
      const groups = Reorder(
        listData,
        result.source.index,
        result.destination.index
      )

      setListData(groups)
    } else {
      const answers = Reorder(
        listData[parseInt(result.type, 10)].multirater_survey_questions,
        result.source.index,
        result.destination.index
      )

      const listDataNew = JSON.parse(JSON.stringify(listData))

      listDataNew[
        parseInt(result.type, 10)
      ].multirater_survey_questions = answers

      setListData(listDataNew)
    }
  }

  return (
    <>
      <RowWrapper>
        <div>
          {!checked.nps && !checked.multirater && (
            <Typography
              variant="caption"
              color="error"
              component="p"
              style={{marginTop: 10}}
            >
              Pilih jenis umpan balik terlebih dahulu
            </Typography>
          )}
          {checked.multirater && (
            <ButtonStyled
              onClick={() =>
                setAddSurvey({
                  ...addSurvey,
                  multirater: !addSurvey.multirater,
                  nps: false,
                })
              }
              variant="contained"
              color="primary"
            >
              <img src={MultiraterIcon} alt="multi-icon" />
              <Typography
                style={{fontSize: 14, marginLeft: 5, fontWeight: '600'}}
              >
                Tambah pertanyaan multirater
              </Typography>
            </ButtonStyled>
          )}
          {checked.nps && (
            <ButtonStyled
              onClick={() =>
                setAddSurvey({
                  ...addSurvey,
                  nps: !addSurvey.nps,
                  multirater: false,
                })
              }
              variant="contained"
              color="primary"
            >
              <SpeedIcon />
              <Typography
                style={{fontSize: 14, marginLeft: 5, fontWeight: '600'}}
              >
                Tambah pertanyaan NPS
              </Typography>
            </ButtonStyled>
          )}
        </div>
        <ButtonStyled
          onClick={() => setOpenDialog(true)}
          variant="outlined"
          color="secondary"
          disabled={!checked.nps && !checked.multirater}
        >
          Masukkan pertanyaan
        </ButtonStyled>
      </RowWrapper>
      {isErrorNextStep && data?.multirater_survey_groups?.length === 0 ? (
        <FormHelperText style={{color: '#ef4d5e'}}>
          Tolong buat setidaknya satu grup pertanyaan
        </FormHelperText>
      ) : null}

      {/* EXPANSION */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="GROUPS">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {listData &&
                listData.map((res, index) => {
                  const numberString = String(res.number)
                  return (
                    <MultiraterGroupExpan
                      setListData={refetch}
                      key={index}
                      data={res}
                      isErrorNextStep={isErrorNextStep}
                      keyDrag={numberString}
                      draggableId={numberString}
                      indexDrag={index}
                      forGroup={addQuestion}
                      handleChangeQuestion={handleChangeQuestion}
                      handleOpenQuestionGroupTrue={handleOpenGrupQuestion}
                      handleOpenQuestionGroupFalse={handleCloseGrupQuestion}
                      teks={teks.question}
                      handleSubmitQuestion={() =>
                        handleSubmitQuestionGroup(
                          res.id,
                          res.multirater_survey_questions.length
                        )
                      }
                    />
                  )
                })}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {addSurvey.multirater ? (
        <FieldInput
          header="multirater"
          onChange={handleChangeGroup(false)}
          error={teks.group.length < 1 && isError}
          helperText={
            teks.group.length < 1 && isError ? 'Bagian ini diperlukan' : ''
          }
          onClickCancel={() =>
            setAddSurvey({
              ...addSurvey,
              multirater: false,
              nps: false,
            })
          }
          onClickSubmit={() =>
            handleAddGroup(data && data.multirater_survey_groups.length, false)
          }
        />
      ) : null}

      {addSurvey.nps ? (
        <FieldInput
          header="NPS"
          onChange={handleChangeGroup(true)}
          error={teks.group.length < 1 && isError}
          helperText={
            teks.group.length < 1 && isError ? 'Bagian ini diperlukan' : ''
          }
          onClickCancel={() =>
            setAddSurvey({
              ...addSurvey,
              multirater: false,
              nps: false,
            })
          }
          onClickSubmit={() =>
            handleAddGroup(data && data.multirater_survey_groups.length, true)
          }
        />
      ) : null}

      {/* DIALOG */}
      <DialogImportQuestion
        open={openDialog}
        setOpenDialog={setOpenDialog}
        dataGroup={dataGroup && dataGroup.multirater_bank_groups}
        setListData={refetch}
        survey={dataContext.dataSurveyCenter && dataContext.dataSurveyCenter.id}
        number={data && data.multirater_survey_groups.length + 1}
      />
    </>
  )
}

export default StepTwoPlus
