import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import MaterialTable from 'material-table'
import helperStyle, {
  PaperContainer,
  EnhancedToolbar,
} from '../../../../GlobalStyles'
import {GET_TRIP_ACTIVITY} from '../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {TextBlueBold} from '../../../organization/OrganizationPageStyles'
import {makeStyles, Tab, Tabs} from '@material-ui/core'
import {getFullDateWithOrdinal} from '../../../../utils/helpers'
import DetailComponent from './detail/DetailComponent'
import {getTripFilterVariables} from '../filter'
import NoDataListFree from '../../../shared-components/NoDataListFreeComponent'
import {
  COLOR_STATUS_TRIP,
  getTripDate,
  STATUS_TRIP,
} from '../businessTripHelper'

const TableOptions = {
  fontFamily: 'muli',
  search: false,
  selection: false,
  sorting: true,
  headerStyle: {
    fontWeight: 700,
    lineHeight: 1.43,
    marginTop: `-100px`,
  },
  pageSize: 20,
  pageSizeOptions: [20, 50, 100],
  rowStyle: {
    wordBreak: 'break-word',
  },
  emptyRowsWhenPaging: false,
  selectionProps: () => ({
    color: 'primary',
  }),
  toolbar: false,
}

const useToolbarStyles = makeStyles(theme => ({
  tabsRoot: {
    backgroundColor: '#f7f8f9',
  },
  root: {
    // marginLeft: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },

  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
  },
  title: {
    flex: '0 0 auto',
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginleft: theme.spacing(3),
  },
  customDatePicker: {
    width: '10rem',
    marginRight: 12,
  },
}))
const Activity = props => {
  const INITIAL_DATA_PAGINATION = {
    offset: 0,
    limit: 20,
  }

  const {filterData, searchText} = props
  const [tabsValue, setTabsValue] = useState('activity')
  const [open, setOpen] = useState({
    detail: false,
    type: 'activity',
  })

  const [pagination, setPagination] = useState(INITIAL_DATA_PAGINATION)
  const classes = helperStyle()
  const classesTabs = useToolbarStyles()

  const variables_filter = React.useMemo(() => {
    return getTripFilterVariables({
      tabsValue,
      searchText,
      filterData,
    })
  }, [searchText, tabsValue, filterData])

  const {data, loading, error, refetch} = useQuery(GET_TRIP_ACTIVITY, {
    wlb_skipPatch: true,
    fetchPolicy: 'network-only',
    variables: {
      where: variables_filter,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  if (error) {
    console.error(error)
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
    setPagination(INITIAL_DATA_PAGINATION)
    // props.setActiveMenuTab(newValue)
  }

  const CloseModalDetail = () => {
    setOpen({
      ...open,
      detail: false,
    })
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      offset: 0,
      limit: row,
    })
  }
  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
          Activity
        </Typography>
      </EnhancedToolbar>
      <Divider></Divider>
      <Tabs
        value={tabsValue}
        onChange={handleChangeTabs}
        indicatorColor="secondary"
        classes={{
          root: classesTabs.tabsRoot,
          indicator: classesTabs.tabsIndicator,
        }}
      >
        <Tab
          label="Activity"
          value="activity"
          className={classesTabs.tabRoot}
        />
        <Tab label="Request" value="request" className={classesTabs.tabRoot} />
      </Tabs>
      <MaterialTable
        columns={[
          {
            title: 'Request ID',
            field: 'id_display',
            headerStyle: {minWidth: 120},
            cellStyle: {minWidth: 120},
          },
          {
            title: 'Employee ID',
            field: 'people_work_placement.regno',
          },
          {
            title: 'Employee Name',
            field: 'global_user.name',
            headerStyle: {minWidth: 120},
            cellStyle: {minWidth: 120},
          },
          {
            title: 'Position',
            field: 'people_work_placement.company_job_profile.title',
            headerStyle: {minWidth: 150},
            cellStyle: {minWidth: 150},
          },
          {
            title: 'Trip Date',
            field: 'date_start',
            render: rowData =>
              getTripDate(rowData?.date_start, rowData?.date_end),
            headerStyle: {minWidth: 200},
            cellStyle: {minWidth: 200},
          },
          {
            title: 'Submission Date',
            field: 'date_added',
            render: rowData => getFullDateWithOrdinal(rowData?.date_added),
            headerStyle: {minWidth: 170},
            cellStyle: {minWidth: 170},
          },
          {
            title: 'Status',
            field: 'status',
            render: rowData => (
              <>
                <Typography
                  style={{
                    backgroundColor:
                      COLOR_STATUS_TRIP?.[rowData.status] || '#000',
                    color: 'white',
                    textAlign: 'center',
                    padding: '4px',
                    borderRadius: '6px',
                    fontSize: '12px',
                  }}
                >
                  {STATUS_TRIP[rowData?.status] || rowData?.status}
                </Typography>
              </>
            ),
          },
          {
            title: 'Action',
            field: 'id',
            sorting: false,
            headerStyle: {minWidth: 100},
            cellStyle: {minWidth: 100},
            render: rowData => {
              if (tabsValue === 'activity') {
                return (
                  <>
                    <a
                      onClick={() => {
                        setOpen({
                          detail: true,
                          type: tabsValue,
                          id: rowData.id,
                        })
                      }}
                      className={classes.link}
                    >
                      <TextBlueBold>Details</TextBlueBold>
                    </a>
                  </>
                )
              } else {
                return (
                  <>
                    <a
                      onClick={() => {
                        setOpen({
                          detail: true,
                          type: tabsValue,
                          id: rowData.id,
                        })
                      }}
                      className={classes.link}
                    >
                      <TextBlueBold>Process</TextBlueBold>
                    </a>
                  </>
                )
              }
            },
          },
        ]}
        data={data && data.trips}
        title=""
        isLoading={loading}
        options={TableOptions}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={(data && data.total.aggregate.count) || 0}
        page={pagination.offset}
        pageSize={pagination.limit}
        components={{
          Toolbar: () => null,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <NoDataListFree
                message1="Sorry, No List"
                message2={'No one has applied for trip'}
              />
            ),
          },
        }}
      />
      <DetailComponent
        open={open.detail}
        type={open.type}
        id={open.id}
        handleClose={CloseModalDetail}
        onRefetchList={refetch}
      />
    </PaperContainer>
  )
}

export default Activity
