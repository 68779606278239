import {makeStyles} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import {Route, Switch} from 'react-router-dom'
import styled from 'styled-components'
import routes from '../configs/routesConfig'
import NoRoles from '../pages/error/NoRoles'
import NotFoundComponent from '../pages/error/NotFoundComponent'
import Homepage from '../pages/homepage/HomepageComponent'
import PrivateRoute from '../pages/private/PrivateRoute'
import {ALLOWED_ROLES, ENVIRONMENT, MAIN_APP_URL, ROLES} from '../utils/globals'
import ErrorBoundary from './../ErrorBoundary'
import Error500Component from '../pages/error/Error500Component'
import NoConnectionComponent from '../pages/error/NoConnectionComponent'

// const theme = createMuiTheme({
//   font: 'muli',
//   borderColor: 'rgb(204, 204, 204)',
//   palette: {
//     primary: {
//       main: '#014a62',
//     },
//     secondary: {
//       main: '#039be5',
//     },
//   },
//   typography: {
//     fontFamily: ['muli'],
//     button: {
//       textTransform: 'capitalize',
//     },
//     h6: {
//       fontWeight: 700,
//     },
//   },
// })

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShift: {
    left: `${theme.spacing(7) + 1}px !important`,

    [theme.breakpoints.up('sm')]: {
      left: `${theme.spacing(8) + 1}px !important`,
    },
  },
}))

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 290px;
  right: 0;
  top: 62px;
  bottom: 0;
  overflow-y: auto;
  background-color: #f7f8f9;
`

const Main = props => {
  const classes = useStyles()
  const {open, allModule, companyModule} = props

  const checkRole = ROLES.filter(element => ALLOWED_ROLES.includes(element))

  if (ENVIRONMENT !== 'development') {
    if (!checkRole) {
      window.location = MAIN_APP_URL
    }
  }

  return (
    <AppWrapper
      className={clsx(classes.appBar, {
        [classes.appBarShift]: !open,
      })}
    >
      <ErrorBoundary>
        <Switch>
          {/* <Route exact path="/" component={Homepage} /> */}
          <PrivateRoute exact path="/" component={Homepage} />
          <Route path="/access-denied" component={NoRoles} />
          <Route path="/error-500" component={Error500Component} />
          <Route path="/disconnected" component={NoConnectionComponent} />

          {routes(allModule, companyModule).map((val, key) => (
            <PrivateRoute
              exact={val.exact}
              key={key}
              path={val.path}
              component={val.component}
              refreshContext={val.refreshContext ? val.refreshContext : false}
            />
          ))}
          <Route component={NotFoundComponent} />
        </Switch>
      </ErrorBoundary>
    </AppWrapper>
  )
}

export default Main
