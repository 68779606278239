import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import ListComponent from './list/ListComponent'
import FormGoalComponent from './form/FormGoalComponent'
import {
  ButtonFilter,
  BlueIcon,
  FilterText,
  PageContainer,
} from '../../../../src/GlobalStyles'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'
import {Filter} from '@worklifebeyond/wlb-utils-components'
import {
  anchorOrigin,
  listFilterGoal,
  listFilterTask,
  transformOrigin,
} from './GoalFilterData'
import moment from 'moment'
import DetailPageComponent from './detail/DetailPageComponent'

const renderComponent = (
  activeComponent,
  state,
  searchText,
  filterData,
  setIsGoal
) => {
  switch (activeComponent) {
    case 'goal':
      return (
        <ListComponent
          state={state}
          searchText={searchText}
          filterData={filterData}
          setIsGoal={setIsGoal}
        />
      )
    case 'create-goal':
    case 'edit-goal':
      return <FormGoalComponent state={state} />
    case 'detail-goal-task':
      return <DetailPageComponent state={state} />
    default:
      return (
        <ListComponent
          state={state}
          searchText={searchText}
          filterData={filterData}
        />
      )
  }
}

const GoalPage = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  const isTask = state.feature === 'Task'

  const [searchText, setSearchText] = useState('')
  const [filterData, setFilterData] = useState({})
  const [isGoal, setIsGoal] = useState(!isTask)

  const onSearch = search => {
    setSearchText(search)
  }

  const handleApply = res => {
    const raw = res[0]
    const include = {}

    for (const key in raw) {
      let value = raw[key]
      if (Array.isArray(value)) {
        const tempData = []
        const taskType = []

        for (let idx = 0, len = value.length; idx < len; idx++) {
          const item = value[idx]
          const val = item.value

          if (key === 'type') {
            const splitVal = val.split('-')
            const typeVariant = splitVal[0].trim()
            const typeMethod = parseInt(splitVal[1].trim())
            if (!taskType.includes(typeVariant)) {
              taskType.push(typeVariant)
            }

            if (!tempData.includes(typeMethod)) {
              tempData.push(typeMethod)
            }

            if (taskType.includes('Personal') && taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = true
            } else if (taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = false
            } else {
              include.isCompany = false
              include.isPersonal = true
            }
          } else if (key === 'percentage') {
            const splitVal = val.split('-')
            if (
              !tempData.includes(parseInt(splitVal[0])) &&
              !tempData.includes(parseInt(splitVal[1]))
            ) {
              tempData.push(splitVal[0])
              tempData.push(splitVal[1])
            }
            include.progressPersenFrom = Math.min(...tempData)
            include.progressPersenTo = Math.max(...tempData)
          } else {
            tempData.push(val)
          }
        }
        value = tempData.length ? tempData : null
      } else if (key === 'period') {
        const format = 'YYYY-MM-DD'
        include.dateFrom = moment(value.from).format(format)
        include.dateTo = moment(value.to).format(format)
      }

      if (key !== 'percentage' && key !== 'period') {
        if (key === 'filterStatus') {
          if (value?.length === 1) {
            include[key] = value[0]
          } else {
            include[key] = null
          }
        } else {
          include[key] = value
        }
      }
    }
    setFilterData({
      ...filterData,
      include,
    })
  }

  return (
    <Card
      icon="assessment"
      title={`Daftar ${
        isGoal
          ? GLOBAL_GLOSSARY.performance.Goal
          : GLOBAL_GLOSSARY.performance.Task
      }`}
      onSearch={onSearch}
      endAdornment={
        <Filter
          includeExclude={false}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          id="filter-goals-wlb"
          onApply={handleApply}
          listFilter={isGoal ? listFilterGoal : listFilterTask}
          language="indonesian"
        >
          <ButtonFilter position="end">
            <BlueIcon>filter_list</BlueIcon>
            <FilterText variant="body2">Saring</FilterText>
          </ButtonFilter>
        </Filter>
      }
    >
      <PageContainer style={{display: 'block'}}>
        {renderComponent(active, state, searchText, filterData, setIsGoal)}
      </PageContainer>
    </Card>
  )
}

export default GoalPage
