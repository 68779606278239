import gql from 'graphql-tag'

export const GET_INSURANCE_PROVIDERS = gql`
  query($search: String, $type: [String!], $offset: Int, $limit: Int) {
    providers: global_insurance_list(
      where: {type: {_in: $type}, _or: [{name: {_ilike: $search}}]}
      offset: $offset
      limit: $limit
    ) {
      id
      enum
      type
      name
      logo
    }
  }
`
