import gql from 'graphql-tag'

export const GET_LIST_TIME_POLICY = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: time_overtime_policies_aggregate(where: {name: {_ilike: $search}}) {
      aggregate {
        count
      }
    }

    time_overtime_policies(
      where: {name: {_ilike: $search}}
      offset: $offset
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
      convert_to_leave
      convert_to_money
      confirm_type
      first_job_profile {
        id
        title
      }
      second_job_profile {
        id
        title
      }
      company_job_profile_r_overtimes {
        company_job_profile {
          id
          title
        }
      }
    }
  }
`
