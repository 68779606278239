import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {
  AddEditTitle,
  FlexCenter,
  FormTitleContainer,
  FormToolbar,
  GenerateTooltip,
  GreyTypography,
  PaperContainer,
  RootContainer,
} from '../../../../../GlobalStyles'
import {UPDATE_SURVEY_STATUS} from '../../../../../graphql/mutations/survey/multirater-nps/survey-center/surveyCenter.mutation'
import {GET_SURVEY_DETAIL} from '../../../../../graphql/queries/survey/multirater-nps/survey-center/getListSurveyCenter.query'
import LoadingSurvey from '../LoadingSurvey'
import {
  AvatarRespondent,
  ContentWrapper,
  DialogContentStyled,
  DialogTitleStyled,
  DialogTitleTeksStyled,
  NameRespondentsDialog,
  RespondenInformation,
  RespondentDetail,
  RespondentsWrapper,
  UserInfo,
} from '../SurveyCenterStyled'
import ExportBaloon from '../../../../../components/exportExcel/ExportBaloon'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'
import useDownloadReport from '../../../../../hooks/useDownloadReport'
import AsesiTable from './AsesiTable'
import {status_data} from '../../../../shared-components/data/SharedData'

const useStyles = makeStyles(() => ({
  rootWrapper: {
    padding: '14px 24px',
  },
  expansion: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  expanDetail: {
    boxShadow: 'none',
    paddingTop: 0,
    paddingBottom: 0,
  },
  expanSummary: {
    height: 85,
    padding: '0px 14px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  wrapperHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '24px 14px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  nomor: {
    width: '10%',
  },
  title: {
    width: '20%',
    marginLeft: 6,
  },
  length: {
    width: '7rem',
    textAlign: 'right',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  avatarGroup: {
    width: '35%',
    marginLeft: '4rem',
    textAlign: 'left',
  },
  avatarGroupHeader: {
    width: '35%',
    marginLeft: '4rem',
  },
  flex: {display: 'flex'},
  groupLeft: {width: '7rem'},
  detailWrapper: {background: '#eff2f4', padding: 30, marginBottom: 30},
  tableWrapper: {marginTop: 16},
}))

const SurveyDetail = props => {
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const {id} = props.match.params
  const {searchText} = props
  const TABLE_REPORT_NAME = 'survey_report'
  const [search, setSearch] = useState('')
  const [conditionDialog, setConditionDialog] = useState({
    open: false,
    data: null,
  })
  const [status, setStatus] = useState('')
  const [openDownload, setOpenDownload] = useState(false)

  const {data, error, refetch} = useQuery(GET_SURVEY_DETAIL, {
    wlb_skipPatch: true,
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  })

  const [updateSurveyStatus] = useMutation(UPDATE_SURVEY_STATUS)

  const {
    dataBaloon,
    isDisableButton,
    onDownload,
    onCloseBaloon,
  } = useDownloadReport({
    table: TABLE_REPORT_NAME,
    filter: {id},
  })

  useEffect(() => {
    if (data) {
      setStatus(data.multirater_surveys[0].status)
    }
  }, [data])

  if (data === undefined || data === error) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          marginLeft: 10,
          marginBottom: 50,
          borderRadius: '5px',
          width: '100%',
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        }}
      >
        <LoadingSurvey />
      </div>
    )
  } else if (error !== undefined) {
    return <div>{'Get Data Error: ' + JSON.stringify(error, 0, 2)}</div>
  }

  const handleCloseDialog = () => {
    setConditionDialog({
      ...conditionDialog,
      open: false,
      data: null,
    })
    setSearch('')
  }

  const handleChangeStatus = e => {
    updateSurveyStatus({
      variables: {
        id,
        status: e.target.value,
      },
    })
      .then(() => {
        enqueueSnackbar(`Feedback updated`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        setStatus(e.target.value)
        refetch()
      })
      .catch(() => {
        enqueueSnackbar(`Update failed, please try again later`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const handleDownload = () => {
    setOpenDownload(prev => !prev)
    if (openDownload) {
      onDownload({
        variables: {
          table: TABLE_REPORT_NAME,
          filter: {id},
        },
      })
    }
  }

  return (
    <RootContainer>
      <ExportBaloon
        isLastGenerateStatus
        isReport
        data={dataBaloon}
        closeBaloonMutation={onCloseBaloon}
        retry={handleDownload}
      />
      <PaperContainer>
        <FormToolbar disableGutters>
          <FormTitleContainer>
            <Link
              to={{
                pathname: '/survey/multirater-nps/survey-center',
                state: {active: 'survey-center', searchable: true},
              }}
            >
              <IconButton edge="end" aria-label="back">
                <ArrowBack />
              </IconButton>
            </Link>
            <AddEditTitle variant="h6">Detail Umpan Balik</AddEditTitle>
          </FormTitleContainer>
          <div>
            {data && data.multirater_surveys[0].status === 'COMPLETED' && (
              <GenerateTooltip
                title={
                  isDisableButton
                    ? `Anda hanya dapat menghasilkan data sekali sehari. Untuk mengunduh data gunakan link yang sudah dibuat.`
                    : ``
                }
                placement="left"
              >
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{marginRight: 12}}
                    onClick={handleDownload}
                    disabled={isDisableButton}
                  >
                    Unduh
                  </Button>
                </span>
              </GenerateTooltip>
            )}
            <Button
              variant="outlined"
              component={Link}
              to={`/survey/multirater-nps/survey-center/preview/${id}`}
              color="primary"
              size="large"
              style={{marginRight: 12}}
            >
              Umpan Balik Pratinjau
            </Button>
            {data && data.multirater_surveys[0].status === 'DRAFT' && (
              <Button
                component={Link}
                to={{
                  pathname: `/survey/multirater-nps/survey-center/edit/${id}`,
                  state: {
                    active: 'edit-survey-center',
                  },
                }}
                size="large"
                variant="contained"
                color="primary"
              >
                Ubah Umpan Balik
              </Button>
            )}
          </div>
        </FormToolbar>
        <Divider />
        <div className={classes.rootWrapper}>
          <Typography variant="h6">
            {data.multirater_surveys[0].title}
          </Typography>
          <GreyTypography variant="body2" paragraph>
            Dibuat pada{' '}
            {moment(data.multirater_surveys[0].date_created).format('ll')}
          </GreyTypography>
          <div className={classes.flex}>
            <div className={classes.groupLeft}>
              <Typography variant="body1" gutterBottom>
                Periode
              </Typography>
              <Typography variant="body1" gutterBottom>
                Diubah pada
              </Typography>
              <Typography variant="body1">Status</Typography>
            </div>
            <div>
              <Typography variant="body1" gutterBottom>
                :&nbsp;{' '}
                {moment(data.multirater_surveys[0].startdate).format('ll')} -{' '}
                {moment(data.multirater_surveys[0].enddate).format('ll')}
              </Typography>
              <Typography variant="body1" gutterBottom>
                :&nbsp;{' '}
                {data.multirater_surveys[0].last_modified
                  ? moment(data.multirater_surveys[0].last_modified).format(
                      'll'
                    )
                  : ' - '}
              </Typography>
              <FlexCenter style={{width: 'max-content'}}>
                :
                {status === 'COMPLETED' ? (
                  <Typography variant="body1" style={{color: '#4CAF50'}}>
                    &nbsp; {status_data.COMPLETED}
                  </Typography>
                ) : (
                  <TextField
                    variant="outlined"
                    select
                    size="small"
                    style={{marginLeft: 5}}
                    value={status}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem disabled value={status}>
                      {status_data[status]}
                    </MenuItem>
                    <MenuItem
                      value={status === 'DRAFT' ? 'PUBLISH' : 'COMPLETED'}
                    >
                      {status_data[status]}
                    </MenuItem>
                  </TextField>
                )}
              </FlexCenter>
            </div>
          </div>
          <div className={classes.tableWrapper}>
            <AsesiTable
              surveyDetail={data}
              surveyId={id}
              searchText={searchText}
            />
          </div>
        </div>
        <AlertDialog
          open={openDownload}
          handleClose={handleDownload}
          feature="Generate Data is Processing!"
          message="Generate Data is processing! We’ll send you an email when it’s ready."
          type="Confirm"
        />
      </PaperContainer>

      <Dialog
        fullWidth
        maxWidth="md"
        open={conditionDialog.open}
        onClose={handleCloseDialog}
        scroll="paper"
      >
        <div>
          <DialogTitleStyled>
            <DialogTitleTeksStyled>Asesi</DialogTitleTeksStyled>
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon style={{color: '#FFF'}} />
            </IconButton>
          </DialogTitleStyled>
          <div style={{margin: '0px 24px'}}>
            <TextField
              style={{width: '100%', marginBottom: 20}}
              fullwidth
              variant="outlined"
              size="small"
              value={search}
              onChange={e => setSearch(e.target.value)}
              // label="Search"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <DialogContentStyled>
          <ContentWrapper>
            {conditionDialog.data &&
              conditionDialog.data
                .filter(item =>
                  item.global_user
                    ? item.global_user.name
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) >= 0
                    : item.global_user_respond_to.name
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) >= 0
                )
                .map((res, index) => {
                  const global = res.global_user
                    ? res.global_user
                    : res.global_user_respond_to
                  return (
                    <RespondentsWrapper key={index}>
                      <UserInfo>
                        <AvatarRespondent src={global.avatar} />
                        <RespondenInformation>
                          <NameRespondentsDialog>
                            {global.name}
                          </NameRespondentsDialog>
                          <RespondentDetail>
                            {global.people_work_placements[0]
                              .company_job_profile
                              ? global.people_work_placements[0]
                                  .company_job_profile.title
                              : ' - '}
                          </RespondentDetail>
                          <RespondentDetail>
                            {global.people_work_placements[0]
                              .company_job_profile
                              ? global.people_work_placements[0]
                                  .company_job_profile.company_organization.name
                              : ' - '}
                          </RespondentDetail>
                          <RespondentDetail>
                            {global.people_work_placements[0]
                              .company_job_profile
                              ? global.people_work_placements[0]
                                  .company_job_profile.company_address
                                  .office_name
                              : ' - '}
                          </RespondentDetail>
                        </RespondenInformation>
                      </UserInfo>
                    </RespondentsWrapper>
                  )
                })}
          </ContentWrapper>
        </DialogContentStyled>
      </Dialog>
    </RootContainer>
  )
}

export default withRouter(SurveyDetail)
