import gql from 'graphql-tag'

export const GET_HOLDING_USER = gql`
  query($offset: Int, $limit: Int, $keyword: String!) {
    people_work_placements(
      offset: $offset
      limit: $limit
      where: {global_user: {name: {_ilike: $keyword}}}
    ) {
      id
      company_profile {
        brand_name
        legal_name
      }
      company_job_profile {
        position
        title
      }
      global_user {
        id
        name
        avatar
      }
    }
  }
`
