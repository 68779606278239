import React, {useEffect} from 'react'
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  Tooltip,
  withStyles,
} from '@material-ui/core'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  RowWrapper,
  AvatarStyled,
  HeaderSection,
  NameDetail,
  TypographyStyled,
  Row,
  RowFollower,
  WrapperRight,
  WrapperMTAuto,
  WrapperSocmed,
  ColWrapper,
} from './../DetailEmployeeStyles'
import TerminatePopup from '../popup/TerminatePopup'
import About from './data-tab/about/About'
import IdentityCard from './data-tab/identity-card/IdentityCard'
import Dependent from './data-tab/dependent/Dependent'
import EmergencyContact from './data-tab/emergency-contact/EmergencyContact'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {StyledMenus, StyledMenuItem} from '../../../../../GlobalStyles'
import {Link, withRouter} from 'react-router-dom'
import BankAccount from './data-tab/BankAccount'
import InsuranceData from './data-tab/insurance-data/InsuranceData'
import MedicalRecord from './data-tab/medical-record/MedicalRecord'
import MedicalHistory from './data-tab/medical-history/MedicalHistory'
import Assessment from './data-tab/assessment/Assessment'
import PhoneIcon from '@material-ui/icons/Phone'
import MailIcon from '@material-ui/icons/Mail'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import LanguageIcon from '@material-ui/icons/Language'
import ModalDetail from '../../../../shared-components/popup/ModalDetail'
import PhoneNumber from './data-icon/PhoneNumber'
import EmailAddress from './data-icon/EmailAddress'
import Address from './data-icon/Address'
import Socials from './data-icon/Socials'
import LoadingEmployee from '../../LoadingEmployee'
import AddEdit from '../../../../shared-components/popup/AddEditPopup'
import ConfirmationDialog from '../../../../shared-components/popup/AddEditPopupFreeText'
import {useMutation, useLazyQuery} from '@apollo/react-hooks'
import {SET_VERIFIY, GET_EDUCATION_INTRO} from '../../../../../graphql/queries'
import {useSnackbar} from 'notistack'
import verified from '../../../../../assets/images/verified.svg'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  )
}

const styleIcon = {color: '#014a62', marginRight: 23, cursor: 'pointer'}
const INITAL_STATE_ICON = {
  open: false,
  component: null,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const _getDetailIcon = (component, userId) => {
  switch (component) {
    case 'phone':
      return <PhoneNumber userId={userId} />
    case 'mail':
      return <EmailAddress userId={userId} />
    case 'address':
      return <Address userId={userId} />
    case 'contact':
      return <Socials userId={userId} />
    default:
      return <LoadingEmployee noborder smallpadding />
  }
}

const getListunverify = data => {
  if (data) {
    return data.unverified_list.map((res, i) => {
      return {
        id: i + 1,
        title: res + ' Information',
      }
    })
  } else return []
}

const VerifiedTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f7f8f9',
    color: '#000',
  },
}))(Tooltip)

function UserProfile(props) {
  const {location, match} = props
  // const {empType} = location.state
  const {data, follower, refetchFerify, refetch, dataVerify} = props
  const {enqueueSnackbar} = useSnackbar()

  const userId = props.match.params.id

  const {address, global_users, working, phone, profile_about} = data
  const main_education =
    (global_users[0] && global_users[0].main_education) || null
  const profile_fields =
    (global_users[0] &&
      global_users[0].people_profiles &&
      global_users[0].people_profiles[0] &&
      global_users[0].people_profiles[0].profile_fields) ||
    null
  const [value, setValue] = React.useState(0)
  const [openVerivy, setOpenVerivy] = React.useState({
    verify: false,
    unverify: false,
  })
  const [open, setOpen] = React.useState({
    open: false,
    name: '',
    id: null,
  })
  const [icon, setIcon] = React.useState(INITAL_STATE_ICON)
  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue)
  }

  const [setVerifyData] = useMutation(SET_VERIFIY, {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-hr-admin',
      },
    },
  })

  const id = working[0] && working[0].id
  const alumni = '/directory/employee-alumni'
  const active = '/directory/employee/' + match.params.id

  const [getEducation, {data: dataMainEducation}] = useLazyQuery(
    GET_EDUCATION_INTRO,
    {
      context: {
        headers: {
          'X-Hasura-Role': 'public',
        },
      },
    }
  )

  useEffect(() => {
    if (main_education) {
      getEducation({
        variables: {
          educationId: main_education,
          userId,
        },
      })
    }
  }, [main_education, userId])

  const _listIntro = ({name, value}, i) => {
    return (
      <WrapperRight key={`${i}list`}>
        <div style={{width: 100}}>
          <TypographyStyled max="true">{name}</TypographyStyled>
        </div>
        <TypographyStyled grey="true">{value}</TypographyStyled>
      </WrapperRight>
    )
  }

  const INTRO = [
    {
      name: 'Nama',
      value: (profile_fields && profile_fields.official_name) || '-',
    },
    {
      name: 'Nama Panggilan',
      value: (profile_fields && profile_fields.initial_name) || '-',
    },
    {
      name: 'Posisi Sekarang',
      value:
        ((working[0] &&
          working[0].company_job_profile &&
          working[0].company_job_profile.title) ||
          '-') +
        ' at ' +
        ((working[0] &&
          working[0].company_job_profile &&
          working[0].company_job_profile.company_address &&
          working[0].company_job_profile.company_address.office_name) ||
          '-'),
    },
    {
      name: 'Pendidikan',
      value:
        (dataMainEducation &&
          dataMainEducation.people_profile[0] &&
          dataMainEducation.people_profile[0].global_user &&
          dataMainEducation.people_profile[0].global_user.school[0] &&
          dataMainEducation.people_profile[0].global_user.school[0]
            .company_profile &&
          dataMainEducation.people_profile[0].global_user.school[0]
            .company_profile.legal_name) ||
        '-',
    },
  ]

  const list = getListunverify(dataVerify)

  const handleVerifyData = () => {
    setVerifyData({
      variables: {
        user: match.params.id,
        status: !dataVerify.is_verified,
      },
    })
      .then(() => {
        enqueueSnackbar(`Success Verify data`, {variant: 'success'})
        refetchFerify()
      })
      .catch(() => {
        enqueueSnackbar('Verify data error, please try again', {
          variant: 'error',
        })
      })
  }
  return (
    <div>
      <ColWrapper>
        <RowWrapper>
          <AvatarStyled
            src={global_users.length > 0 && global_users[0].avatar}
            alt="profile"
          />
          <HeaderSection borderRight>
            <NameDetail>
              {global_users.length > 0 && global_users[0].name}
              {dataVerify && dataVerify.is_verified && (
                <VerifiedTooltip
                  title="Profile is verified by the company"
                  placement="right"
                >
                  <img src={verified} alt="verified" style={{marginLeft: 7}} />
                </VerifiedTooltip>
              )}
            </NameDetail>
            <TypographyStyled>
              {working?.[0]?.company_job_profile?.title || '-'} di{' '}
              {working?.[0]?.company_job_profile?.company_address
                ?.office_name || '-'}
            </TypographyStyled>
            <TypographyStyled grey="true">
              {working?.[0]?.company_job_profile?.company_address?.global_city
                ?.name || '-'}
              ,{' '}
              {working?.[0]?.company_job_profile?.company_address
                ?.global_province?.name || '-'}
            </TypographyStyled>
            <Row>
              <RowFollower>
                <TypographyStyled bold="true" mr="true" medium="true">
                  {follower && follower.follower}
                </TypographyStyled>
                <TypographyStyled grey="true">Pengikut</TypographyStyled>
              </RowFollower>
              <RowFollower>
                <TypographyStyled bold="true" mr="true" medium="true">
                  {follower && follower.following}
                </TypographyStyled>
                <TypographyStyled grey="true">Mengikuti</TypographyStyled>
              </RowFollower>
            </Row>
            <div style={{marginTop: 24, width: 100}}>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{width: '100%'}}
                      {...bindTrigger(popupState)}
                    >
                      Aksi
                    </Button>
                    <StyledMenus
                      {...bindMenu(popupState)}
                      getContentAnchorEl={null}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                      transformOrigin={{vertical: 'top', horizontal: 'left'}}
                    >
                      {id !== undefined && (
                        <StyledMenuItem
                          style={{justifyContent: 'left', color: '#000'}}
                          component={Link}
                          to={{
                            pathname: `/directory/${
                              location.pathname.includes(alumni)
                                ? 'employee-alumni'
                                : 'employee'
                            }/edit/${id}`,
                            state: {
                              active: 'edit-employee',
                              isEdit: true,
                              userId: global_users[0].id,
                            },
                          }}
                        >
                          Ubah penempatan informasi
                        </StyledMenuItem>
                      )}
                      {id !== undefined && (
                        <StyledMenuItem
                          style={{justifyContent: 'left', color: '#000'}}
                          component={Link}
                          to={{
                            pathname: `/directory/${
                              location.pathname.includes(alumni)
                                ? 'employee-alumni'
                                : 'employee'
                            }/edit/${id}`,
                            state: {
                              active: 'edit-employee',
                              isReplacement: true,
                              userId: global_users[0].id,
                            },
                          }}
                        >
                          Penggantian
                        </StyledMenuItem>
                      )}
                      {location.pathname === active && (
                        <StyledMenuItem
                          style={{justifyContent: 'left', color: '#000'}}
                          onClick={() => {
                            if (dataVerify && dataVerify.is_verified) {
                              setOpenVerivy({...openVerivy, unverify: true})
                            } else {
                              setOpenVerivy({...openVerivy, verify: true})
                            }
                            popupState.close()
                          }}
                        >
                          {dataVerify && dataVerify.is_verified
                            ? 'Batalkan Verifikasi'
                            : 'Verifikasi data'}
                        </StyledMenuItem>
                      )}
                      {location.pathname === active && (
                        <StyledMenuItem
                          style={{justifyContent: 'left', color: '#ef4d5e'}}
                          onClick={() => {
                            setOpen({
                              open: true,
                              name:
                                global_users.length > 0
                                  ? global_users[0].name
                                  : ' - ',
                              id: id,
                            })
                            popupState.close()
                          }}
                        >
                          Mengakhiri
                        </StyledMenuItem>
                      )}
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            </div>
            {/*  */}
          </HeaderSection>
          <HeaderSection>
            <WrapperMTAuto>
              {INTRO.map((res, i) => _listIntro(res, i))}
              <WrapperSocmed>
                <PhoneIcon
                  style={styleIcon}
                  onClick={() =>
                    setIcon(e => ({
                      ...e,
                      open: true,
                      title: 'Phone Number',
                      component: 'phone',
                    }))
                  }
                />
                <MailIcon
                  style={styleIcon}
                  onClick={() =>
                    setIcon(e => ({
                      ...e,
                      open: true,
                      title: 'Email Address',
                      component: 'mail',
                    }))
                  }
                />
                <LocationOnIcon
                  style={styleIcon}
                  onClick={() =>
                    setIcon(e => ({
                      ...e,
                      open: true,
                      title: 'Address',
                      component: 'address',
                    }))
                  }
                />
                <LanguageIcon
                  style={styleIcon}
                  onClick={() =>
                    setIcon(e => ({
                      ...e,
                      open: true,
                      title: 'Socials',
                      component: 'contact',
                    }))
                  }
                />
              </WrapperSocmed>
            </WrapperMTAuto>
          </HeaderSection>
        </RowWrapper>
        <div style={{backgroundColor: '#f7f8f9'}}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Tentang" {...a11yProps(4)} />
            <Tab label="Kartu Identitas" {...a11yProps(1)} />
            <Tab label="Tanggungan" {...a11yProps(2)} />
            <Tab label="Kontak Darurat" {...a11yProps(3)} />
            <Tab label="Rekening" {...a11yProps(0)} />
            <Tab label="Data Asuransi" {...a11yProps(5)} />
            <Tab label="Medical Profile" {...a11yProps(6)} />
            <Tab label="Rekam Medis" {...a11yProps(7)} />
            <Tab label="Asesmen" {...a11yProps(8)} />
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <About
            address={address}
            global_users={global_users}
            phone={phone}
            profile_about={profile_about}
            userId={userId}
            refetch={refetch}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <IdentityCard userId={userId} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Dependent userId={userId} userName={global_users[0]?.name || '-'} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <EmergencyContact userId={userId} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <BankAccount userId={userId} userName={global_users?.[0]?.name} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <InsuranceData userId={userId} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <MedicalRecord userId={userId} />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <MedicalHistory userId={userId} />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <Assessment userId={userId} />
        </TabPanel>
      </ColWrapper>
      <TerminatePopup
        open={open.open}
        working={working}
        userId={userId}
        handleClose={() =>
          setOpen({
            open: false,
            name: '',
            id: null,
          })
        }
        name={open.name}
        id={open.id}
      />
      <ModalDetail
        onClose={() => setIcon(INITAL_STATE_ICON)}
        open={icon.open}
        title={icon.title}
        maxWidth="sm"
      >
        {_getDetailIcon(icon.component, userId)}
      </ModalDetail>
      <AddEdit
        open={openVerivy.verify}
        handleClose={() => setOpenVerivy({...openVerivy, verify: false})}
        type="Verify"
        feature="Employee Data"
        titleChanges="This employee just change their information such as following :"
        seeAllText="See all changes"
        applied={list}
        appliedName="Employee Data"
        mutation={handleVerifyData}
      />
      <ConfirmationDialog
        open={openVerivy.unverify}
        header="Unverify Employee Data?"
        body="Are you sure to unverify this employee data?"
        button="Unverify"
        handleClose={() => setOpenVerivy({...openVerivy, unverify: false})}
        mutation={handleVerifyData}
      />
    </div>
  )
}

export default withRouter(UserProfile)
