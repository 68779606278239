import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import {
  Typography,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import {
  PaperContainer,
  EnhancedToolbar,
  Flex,
  PageContainer,
} from '../../../GlobalStyles'
import helperStyle, {ReportText} from './ReportPageStyles'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {LEARNING_HOLDING_REPORT} from '../../../graphql/queries'
import {useMutation} from '@apollo/react-hooks'
import {holdingRolesContext} from '../../../utils/helpers'

const LearningReportPageComponent = () => {
  const classes = helperStyle()
  const [openDownload, setOpenDownload] = useState(false)
  const [dialogType, setDialogType] = useState('')
  const [checkValue, setCheckValue] = useState([
    {
      label: 'learning_management_materi_access',
      name: 'Learning Management Materi Access',
      checked: false,
    },
    {
      label: 'learning_contributor_holding',
      name: 'Learning Contributor (HOLDING)',
      checked: false,
    },
    {
      label: 'learning_management_learning_contrib',
      name: 'Learning Management (Learning Contributor)',
      checked: false,
    },
    {
      label: 'social_learning_contrib_participant',
      name: 'Social Learning Contributor/Participant',
      checked: false,
    },
    {label: 'dashboard_lms', name: 'Dashboard LMS', checked: false},
  ])

  const [downloadReport] = useMutation(LEARNING_HOLDING_REPORT)

  const handleCheckAll = e => {
    const value = e.target.checked
    const check = []

    checkValue.map(val => {
      check.push({
        ...val,
        checked: !!value,
      })
    })
    setCheckValue(check)
  }

  const handleChange = index => e => {
    const array = checkValue
    array[index] = {...array[index], checked: e.target.checked}
    setCheckValue([...array])
  }

  const onDownloadClick = () => {
    setOpenDownload(true)
    setDialogType('')
  }

  const handleDownload = () => {
    setOpenDownload(false)
    const flag = {
      learning_management_materi_access: checkValue[0].checked,
      learning_contributor_holding: checkValue[1].checked,
      learning_management_learning_contrib: checkValue[2].checked,
      social_learning_contrib_participant: checkValue[3].checked,
      dashboard_lms: checkValue[4].checked,
    }

    downloadReport({
      variables: {
        exportFlags: flag,
      },
      ...holdingRolesContext,
    })
      .then(() => {
        setDialogType('success')
        setOpenDownload(true)
      })
      .catch(() => {
        setDialogType('error')
        setOpenDownload(true)
      })
  }

  return (
    <div>
      {/* <Card icon="school" title="Learning" onSearch={onSearch}> */}
      <Card icon="school" title="Learning">
        <PageContainer>
          <PaperContainer>
            <EnhancedToolbar>
              <Typography variant="h6">Learning Report</Typography>

              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => onDownloadClick()}
              >
                Download
              </Button>
            </EnhancedToolbar>
            <Divider />
            <div style={{padding: '1.6rem'}}>
              <ReportText fweight="bold" className={classes.mb15}>
                Select the data that you want to download
              </ReportText>
              <Flex>
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={state.checkedB}
                      onChange={handleCheckAll}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Select All"
                />
              </Flex>
              {checkValue.map((val, key) => {
                return (
                  <Flex key={key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={val.checked}
                          onChange={handleChange(key)}
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={val.name}
                    />
                  </Flex>
                )
              })}
            </div>
          </PaperContainer>
        </PageContainer>
      </Card>
      <Dialog
        open={openDownload}
        onClose={() => setOpenDownload(false)}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Download Report</DialogTitle>
        <DialogContent>
          {dialogType === 'success' ? (
            <DialogContentText>
              Export data success, data will be send to your email.
            </DialogContentText>
          ) : dialogType === 'error' ? (
            <DialogContentText>Export Data failed</DialogContentText>
          ) : (
            <DialogContentText>
              Data to download
              {checkValue
                .filter(val => val.checked === true)
                .map((data, idx) => (
                  <Flex key={idx}>&bull; {data.name}</Flex>
                ))}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDownload(false)} color="primary">
            Cancel
          </Button>
          {dialogType === 'success' || dialogType === 'error' ? (
            <Button onClick={() => setOpenDownload(false)} color="primary">
              Okay
            </Button>
          ) : (
            <Button onClick={() => handleDownload()} color="primary" autoFocus>
              Download
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default LearningReportPageComponent
