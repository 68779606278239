/**
 * Common words that change frequently
 * see: https://smartasn.atlassian.net/browse/SMARTASN-109?focusedCommentId=10494
 */
export const GLOBAL_GLOSSARY = {
  performance: {
    Goal: 'Rencana Hasil Kerja',
    Goal_short: 'RHK',
    Task: 'Rencana Aksi',
    Activity: 'Realisasi',
    /**
     * `Description_goal` here in the context of the `Description` on `Goal`
     */
    Description_goal: 'Indikator Kinerja',
    Reporter: 'Pemberi Tugas',
    'Personal Goal': 'Hasil Kerja Pribadi',
    'Company Goal': 'Hasil Kerja Instansi',
    'Weight Unit': 'Satuan Target',
    'Target Base': 'Target',
    Qualitative: 'Kualitatif',
    Quantitative: 'Kuantitatif',
  },
}
