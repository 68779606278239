import {FormControlLabel, MenuItem, Radio, TextField} from '@material-ui/core'
import React from 'react'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import {selectProps} from '../../../../../utils/helpers'
import {useStyles} from '../EditSettingComponent'
import AsyncSelect from 'react-select/async'
import PayWithMoney from './PayWithMoney'
import PayWithLeave from './PayWithLeave'

export const EditDetailTabPolicy = ({
  setName,
  name,
  isError,
  isConvertToMoney,
  isConvertToLeave,
  setConvertToMoney,
  setConvertToLeave,
  moneyCompensation,
  setMoneyCompensation,
  onConfirmationChange,
  selectedConfirmationType,
  confirmationTypes,
  handleSearch,
  selectedPosition,
  setSelectedPosition,
  setSecondConfirmationState,
  secondConfirmationState,
  secondConfirmations,
  setSelectedPositionSecond,
  selectedPositionSecond,
  nameChecker,
  leavePolicyChildren,
  setLeavePolicyChildren,
  minimumHour,
  setMinimumHour,
  standard,
  setStandard,
}) => {
  const classes = useStyles()
  return (
    <>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Overtime Name *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            id="overtime-name"
            InputProps={{
              classes: {
                input: classes.outlinedInput,
              },
            }}
            placeholder="Overtime Name"
            fullWidth
            margin="normal"
            onChange={event => {
              setName(event.target.value)
            }}
            variant="outlined"
            value={name}
            error={(name.length < 1 && isError) || (nameChecker && isError)}
            helperText={
              name.length < 1 && isError
                ? 'This field is required'
                : nameChecker && isError
                ? 'Name is already exist'
                : ''
            }
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer style={{width: '100%'}}>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Overtime compensation *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <div style={{margin: '10px 0'}}>
            <FormControlLabel
              control={
                <Radio
                  checked={isConvertToMoney}
                  onChange={() => {
                    setConvertToMoney(true)
                    setConvertToLeave(false)
                  }}
                  value={isConvertToMoney}
                  inputProps={{
                    'aria-label': 'pay-with-money',
                  }}
                  color="primary"
                />
              }
              label="Pay with money"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={isConvertToLeave}
                  onChange={() => {
                    setConvertToMoney(false)
                    setConvertToLeave(true)
                  }}
                  value={isConvertToLeave}
                  inputProps={{
                    'aria-label': 'pay-with-leave',
                  }}
                  color="primary"
                />
              }
              label="Convert to Leave"
            />
          </div>

          {isConvertToMoney && (
            <PayWithMoney
              classes={classes}
              isError={isError}
              moneyCompensation={moneyCompensation}
              isConvertToMoney={isConvertToMoney}
              setMoneyCompensation={setMoneyCompensation}
              standard={standard}
              setStandard={setStandard}
            />
          )}
          {isConvertToLeave && (
            <PayWithLeave
              setLeavePolicyChildren={setLeavePolicyChildren}
              leavePolicyChildren={leavePolicyChildren}
              minimumHour={minimumHour}
              setMinimumHour={setMinimumHour}
              classes={classes}
              errorLeave={isError && !leavePolicyChildren}
              errorHour={isError && isNaN(minimumHour)}
            />
          )}
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer style={{paddingBottom: 0}}>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>Overtime Approval From *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            id="part-of-leave"
            InputProps={{
              classes: {
                input: classes.outlinedInput,
              },
            }}
            select
            fullWidth
            onChange={onConfirmationChange}
            margin="normal"
            variant="outlined"
            value={selectedConfirmationType}
            SelectProps={selectProps(selectedConfirmationType)}
          >
            <MenuItem value="" disabled>
              Select approval from
            </MenuItem>
            {confirmationTypes.map((type, key) => {
              return (
                <MenuItem key={key} value={type.value}>
                  {type.name}
                </MenuItem>
              )
            })}
          </TextField>
        </FormChildInput>
      </FormChildContainer>

      {selectedConfirmationType === '2' && (
        <FormChildContainer style={{paddingBottom: 0}}>
          <FormChildInput>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={e => handleSearch(e)}
              value={selectedPosition}
              onChange={v => {
                setSelectedPosition(v)
              }}
              fontSize="14px"
              placeholder="Select Position"
              marginNormal
              error
              required
            />
          </FormChildInput>
        </FormChildContainer>
      )}
      <FormChildContainer style={{padding: '1rem 0 0 0'}}>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>After Getting Approval Then</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            id="select-position"
            InputProps={{
              classes: {
                input: classes.outlinedInput,
              },
            }}
            select
            fullWidth
            onChange={event => {
              // setSelectedPosition(event.target.value);
              setSecondConfirmationState(event.target.value)
            }}
            margin="normal"
            variant="outlined"
            value={secondConfirmationState}
            SelectProps={selectProps(secondConfirmationState)}
          >
            <MenuItem value="" disabled>
              Select after approval actions
            </MenuItem>
            {secondConfirmations.map((type, key) => {
              return (
                <MenuItem key={key} value={type.value}>
                  {type.name}
                </MenuItem>
              )
            })}
          </TextField>
        </FormChildInput>
      </FormChildContainer>

      {secondConfirmationState === '2' && (
        <FormChildContainer style={{paddingBottom: 0}}>
          <FormChildInput>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={e => handleSearch(e)}
              value={selectedPositionSecond}
              onChange={v => {
                setSelectedPositionSecond(v)
              }}
              fontSize="14px"
              placeholder="Select Position"
              marginNormal
              error
              required
            />
          </FormChildInput>
        </FormChildContainer>
      )}
    </>
  )
}
