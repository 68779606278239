import {useMutation, useQuery} from '@apollo/react-hooks'
import {Tab, Tabs} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MaterialTable from 'material-table'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {GenerateTooltip, RootContainer} from '../../../../GlobalStyles'
import {
  APPROVE_STATUS_LEAVE_CONFIRM1,
  APPROVE_STATUS_LEAVE_CONFIRM2,
  REJECT_STATUS_LEAVE,
} from '../../../../graphql/mutations/index'
import {
  GET_LEAVE_ACTIVITY,
  GET_LEAVE_REQUEST,
} from '../../../../graphql/queries'
import {capitalize} from '../../../../utils/helpers'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {TableOptionsTime} from '../../../shared-components/table/TableOptions'
import ModalDetailActivity from './detail-activity/ModalDetailActivity'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {POSITION_NAME, USER_ID, USER_NAME} from '../../../../utils/globals'
import useDownloadReport from '../../../../hooks/useDownloadReport'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import {getLeaveActivityFilterVariables} from '../filter'

const TEXT_TOOLTIP = `You can only generate data once a day. to download data use the link that already generated`
const TABLE_REPORT_NAME = 'people_work_leaves'
const INITIAL_DATA_PAGINATION = {
  offset: 0,
  limit: 20,
}

const getApproalType = (data, actionStatus) => {
  let approvalType = ''

  const _getApprove = (data, type) => {
    let _type = `first_${type}`
    if (data && data.time_leave_policy_child) {
      const {confirm_type} = data.time_leave_policy_child
      if (confirm_type === '1' && data.status === 'confirm1')
        _type = `second_${type}`
      else if (confirm_type === '2') {
        if (data.status === 'confirm1') {
          _type = `second_${type}`
        } else {
          _type = `first_${type}`
        }
      }
    }
    return _type
  }
  if (actionStatus === 'approved') approvalType = _getApprove(data, 'approve')
  else if (actionStatus === 'rejected')
    approvalType = _getApprove(data, 'reject')

  return approvalType
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    // width: '82%',
    // marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
  },
  title: {
    flex: '0 0 auto',
  },
  flex: {display: 'flex'},
  datePicker: {width: '44%', marginRight: '1rem'},
  btnDownload: {minHeight: 50},
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
  },
  customDatePicker: {
    width: '10rem',
    marginRight: 12,
  },
}))

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles()
  const {
    startDate,
    onSelectedStartDate,
    onSelectedEndDate,
    endDate,
    isDisable,
  } = props

  return (
    <Toolbar>
      <div className={classes.title}>
        <Typography style={{fontSize: 18}} variant="h6" id="tableTitle">
          Leave and Permission Activity
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            format="MMM dd, yyyy"
            id="start-date"
            label="Start Date"
            value={new Date(startDate)}
            onChange={onSelectedStartDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              size: 'small',
            }}
            className={classes.customDatePicker}
          />
          <KeyboardDatePicker
            inputVariant="outlined"
            format="MMM dd, yyyy"
            id="end-date"
            label="End Date"
            value={new Date(endDate)}
            onChange={onSelectedEndDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              size: 'small',
            }}
            className={classes.customDatePicker}
          />
        </MuiPickersUtilsProvider>
        <GenerateTooltip title={isDisable ? TEXT_TOOLTIP : ``} placement="left">
          <div>
            <Button
              style={{height: '100%', width: 147}}
              variant="contained"
              color="primary"
              size="large"
              disabled={isDisable}
              onClick={props.handleDownload}
            >
              Generate Data
            </Button>
          </div>
        </GenerateTooltip>
      </div>
    </Toolbar>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // marginBottom: '7rem',
  },
  table: {
    minWidth: 750,
  },

  selectFilter: {marginRight: 12, minWidth: '19%'},
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
}))

export default function Activity(props) {
  const {searchText, filterData} = props

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(undefined)
  const [startDate, setStartDate] = useState(
    `${moment()
      .startOf('month')
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss')}`
  )
  const dateEnd = moment()
    .endOf('month')
    .endOf('day')
    .format('YYYY-MM-DD HH:mm:ss')
  const [endDate, setEndDate] = useState(dateEnd)
  const [tabsValue, setTabsValue] = useState(0)
  const [type, setType] = useState(0)

  const [openPopup, setPopup] = useState(false)
  const [openDownload, setOpenDownload] = useState(false)
  const [confirmType, setConfirmType] = useState('Approve')

  const {enqueueSnackbar} = useSnackbar()
  const [rejectStatusLeave] = useMutation(REJECT_STATUS_LEAVE)
  const [approveLeaveConfirm1] = useMutation(APPROVE_STATUS_LEAVE_CONFIRM1)
  const [approveLeaveConfirm2] = useMutation(APPROVE_STATUS_LEAVE_CONFIRM2)
  const [reject, setReject] = useState(false)
  const [reason, setReason] = useState('')
  const [pagination, setPagination] = useState(INITIAL_DATA_PAGINATION)

  const {dataBaloon, isDisableButton, onDownload} = useDownloadReport({
    table: TABLE_REPORT_NAME,
  })

  const where = React.useMemo(() => {
    return getLeaveActivityFilterVariables({
      tabsValue: tabsValue === 1 ? 'request' : 'activity',
      startDate,
      endDate,
      searchText,
      filterData,
    })
  }, [searchText, filterData, tabsValue, startDate, endDate])

  const {data, loading: loadingActivity, error, refetch} = useQuery(
    GET_LEAVE_ACTIVITY,
    {
      wlb_skipPatch: true,
      skip: tabsValue !== 0,
      variables: {
        where: where,
        offset: pagination.offset * pagination.limit,
        limit: pagination.limit,
      },
    }
  )

  const {
    data: dataRequest,
    loading: loadingRequest,
    error: errorRequest,
    refetch: refetchRequest,
  } = useQuery(GET_LEAVE_REQUEST, {
    wlb_skipPatch: true,
    skip: tabsValue !== 1,
    variables: {
      where: where,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const handleClickOpen = (item, type) => {
    setSelectedItem(item)
    setOpen(true)
    setType(type)
  }
  const handleModalClose = () => {
    setOpen(false)
  }

  const handleModalAction = (action, reason) => {
    setConfirmType(action)
    setReason(reason)
    setPopup(true)
    // OPEN THE CONFIRMATION DIALOG
  }

  const handleConfirmClose = () => {
    setPopup(false)
  }
  const handleConfirmAction = () => {
    let status = ''
    switch (confirmType) {
      case 'approve':
        status = 'approved'
        break
      case 'reject':
        status = 'rejected'
        break
    }
    const approvalType = getApproalType(selectedItem, status)

    if (status === 'approved') {
      const variables = {
        id: selectedItem.id,
        latest_status: selectedItem.status,
        leave_fields: {
          [approvalType]: {
            type: 'admin',
            user: {
              id: USER_ID,
              name: USER_NAME,
              title: POSITION_NAME,
            },
          },
        },
      }

      const approveMutation =
        selectedItem.time_leave_policy_child.confirm_type === '2'
          ? approveLeaveConfirm2
          : approveLeaveConfirm1

      approveMutation({variables})
        .then(() => {
          enqueueSnackbar('Success Update data', {variant: 'success'})
          setTimeout(() => {
            handleConfirmClose()
            handleModalClose()
            setReason('')
            setReject(false)
          }, 1000)
          refetchRequest()
          refetch()
        })
        .catch(err => {
          handleConfirmClose()
          handleModalClose()
          enqueueSnackbar(err, {
            variant: 'error',
          })
        })
    } else {
      const variables = {
        id: selectedItem.id,
        status,
        leave_fields: {
          reason: reason,
          endDate: selectedItem && selectedItem.leave_fields.endDate,
          startDate: selectedItem && selectedItem.leave_fields.startDate,
          [approvalType]: {
            type: 'admin',
            user: {
              id: USER_ID,
              name: USER_NAME,
              title: POSITION_NAME,
            },
          },
        },
      }
      rejectStatusLeave({variables})
        .then(() => {
          enqueueSnackbar('Success Update data', {variant: 'success'})
          setTimeout(() => {
            handleConfirmClose()
            handleModalClose()
            setReason('')
            setReject(false)
          }, 1000)
          refetchRequest()
          refetch()
        })
        .catch(() => {
          handleConfirmClose()
          handleModalClose()
          enqueueSnackbar('Update data error, please try again', {
            variant: 'error',
          })
        })
    }
  }

  if (error !== undefined) {
    return <div>{JSON.stringify(error, 0, 2)}</div>
  }

  if (errorRequest !== undefined) {
    return <div>{JSON.stringify(errorRequest, 0, 2)}</div>
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
    setPagination(INITIAL_DATA_PAGINATION)
    props.setActiveMenuTab(newValue === 0 ? 'activity' : 'request')
  }

  const renderStatus = data => {
    if (data === 'confirm1') {
      return 'Waiting for second approval'
    } else if (data === 'pending') {
      return 'Waiting'
    } else {
      return data && capitalize(data)
    }
  }
  const handleDownload = () => {
    setOpenDownload(prev => !prev)
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      offset: 0,
      limit: row,
    })
  }

  const handleDownloadData = (start_date, end_date) => {
    onDownload({
      variables: {
        table: TABLE_REPORT_NAME,
        filter: {
          start_date,
          end_date,
        },
      },
    })
  }

  return (
    <RootContainer>
      <ExportBaloon isLastGenerateStatus data={dataBaloon} />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          handleDownload={handleDownload}
          startDate={startDate}
          onSelectedStartDate={date =>
            setStartDate(
              moment(date)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss')
            )
          }
          onSelectedEndDate={date =>
            setEndDate(
              moment(date)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss')
            )
          }
          endDate={endDate}
          isDisable={isDisableButton}
        />
        {/* <Divider /> */}
        <Tabs
          value={tabsValue}
          onChange={handleChangeTabs}
          indicatorColor="secondary"
          classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
        >
          <Tab label="Activity" className={classes.tabRoot} />
          <Tab label="Request" className={classes.tabRoot} />
        </Tabs>

        <MaterialTable
          isLoading={tabsValue === 0 ? loadingActivity : loadingRequest}
          columns={[
            {
              title: 'Request ID',
              field: 'id_display',
              // render: row => {
              //   const date = convertDateForID(row && row.date_added)
              //   const id = String(row && row.id)
              //   const customId = id.padStart(5, '0')
              //   return `LV-${date}${customId}`
              // },
            },
            {
              title: 'Employee ID',
              field: 'people_work_placement.regno',
            },
            {
              title: 'Name',
              field: 'people_work_placement.global_user.name',
            },
            {
              title: 'Position',
              field: 'people_work_placement.company_job_profile.title',
              render: row =>
                row &&
                row.people_work_placement &&
                row.people_work_placement.company_job_profile &&
                row.people_work_placement.company_job_profile.title,
            },
            {
              title: 'Leave and Permission Date',
              field: 'datestart',
              defaultSort: 'desc',
              render: row => moment(row.datestart).format('LL'),
            },
            {
              title: 'Leave and Permission Type',
              field: 'time_leave_policy_child.name',
            },
            {
              title: 'Status',
              field: 'status',
              render: row => renderStatus(row.status),
            },
            {
              title: 'Action',
              sorting: false,
              cellStyle: {minWidth: '3.5rem'},
              render: row =>
                tabsValue === 0 ? (
                  <Typography
                    color="secondary"
                    variant="body2"
                    style={{cursor: 'pointer', marginLeft: 3}}
                    onClick={() => handleClickOpen(row, 'activity')}
                  >
                    Detail
                  </Typography>
                ) : (
                  <Typography
                    color="secondary"
                    variant="body2"
                    style={{cursor: 'pointer', marginLeft: 3}}
                    onClick={() => handleClickOpen(row, 'request')}
                  >
                    Process
                  </Typography>
                ),
            },
          ]}
          data={
            tabsValue === 0
              ? data && data.people_work_leaves
              : dataRequest && dataRequest.people_work_leaves
          }
          title=""
          // options={TableOptionsTime}
          options={{
            ...TableOptionsTime,
            selection: false,
            emptyRowsWhenPaging: false,
          }}
          components={{
            Toolbar: () => null,
            Container: props => <Paper elevation={0} {...props}></Paper>,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={
            tabsValue === 0
              ? data && data.total.aggregate.count
              : dataRequest && dataRequest.total.aggregate.count
          }
          page={pagination.offset}
          pageSize={pagination.limit}
        />
      </Paper>

      <ModalDetailActivity
        type={type}
        open={open}
        selectedItem={selectedItem}
        handleClose={handleModalClose}
        handleModalAction={handleModalAction}
        reject={reject}
        setReject={setReject}
        reason={reason}
        setReason={setReason}
      />

      <AddEdit
        open={openPopup}
        handleClose={handleConfirmClose}
        type={confirmType}
        name={
          selectedItem &&
          selectedItem.people_work_placement.global_user &&
          selectedItem.time_leave_policy_child &&
          selectedItem.time_leave_policy_child.name +
            ' by ' +
            selectedItem.people_work_placement.global_user.name
        }
        feature="Leave and Permission Request"
        mutation={handleConfirmAction}
      />
      <AddEdit
        open={openDownload}
        handleClose={handleDownload}
        type="download"
        feature="Leave and Permission Activity"
        mutation={() => handleDownloadData(startDate, endDate)}
      />
    </RootContainer>
  )
}
