import React from 'react'
import {Chip} from '@material-ui/core'
import helperStyle from '../../GlobalStyles'

export default function TerminatedChip({status, endDate}) {
  const classes = helperStyle()

  return (
    <>
      {new Date(endDate)?.getFullYear() < 1900 ? (
        <Chip
          className={classes.errorChips}
          size="small"
          label={'Invalid End Date'}
        />
      ) : status === 'INACTIVE' && new Date(endDate) <= new Date() ? (
        <Chip
          className={classes.errorChips}
          size="small"
          label={'Terminated'}
        />
      ) : (
        ''
      )}
    </>
  )
}
