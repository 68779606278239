import gql from 'graphql-tag'

export const GET_KPI_SCORING = gql`
  query getKpiScoring($search: String) {
    kpi_scoring_natures(
      where: {
        _or: [
          {code: {_ilike: $search}}
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      code
      name
      description
      status
      global_scoring_nature {
        id
        name
      }
    }
  }
`

export const CHECK_CODE_SCORING = gql`
  query checkCodeMonitoring($company: uuid, $code: String) {
    kpi_scoring_natures_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const CHECK_SCORING_ALREADY_TAKEN = gql`
  query checkDeleteMonitoring($scoring: [Int!]!) {
    kpi_lists_aggregate(where: {scoring: {_in: $scoring}}) {
      aggregate {
        count
      }
    }
  }
`
