export const listMaritalStatus = [
  {
    value: 'single',
    name: 'Single'
  },
  {
    value: 'married',
    name: 'Married'
  },
  {
    value: 'divorced',
    name: 'Divorced'
  },
  {
    value: 'separated',
    name: 'Separated'
  },
  {
    value: 'widowed',
    name: 'Widowed'
  }
]

export const optionNationaly = [
  {
    value: 'WNA',
    label: 'WNA'
  },
  {
    value: 'WNI',
    label: 'WNI'
  }
];

export const optionSalutation = [
  {
    value: 'Mr',
    label: 'Mr'
  },
  {
    value: 'Ms',
    label: 'Ms'
  },
  {
    value: 'Mrs',
    label: 'Mrs'
  }
];

export const getErrorForm = (values) => {
  const notNull = [
    "fullname",
    "gender",
    "place_of_birth",
    "birth_date",
    "marital_status",
    "nationality",
    "salutation",
    "race",
  ];
  let isError = false
  notNull.map((field) => {
    if (!values[field]?.[0]) {
      isError = true
    }
  });
  if(!values.religion) {
    isError = true
  }
  return isError
};
