import React from 'react'
import {
  DialogContent,
  Typography,
  makeStyles,
  Dialog,
  IconButton,
  Icon,
} from '@material-ui/core'
import {PrimaryModalHeader, FlexBetween} from '../../../../GlobalStyles'
import linkedin from '../../../../assets/slicing/socmed/linkedin.svg'
import facebook from '../../../../assets/slicing/socmed/facebook.svg'
import mail from '../../../../assets/slicing/socmed/mail.svg'
import phone from '../../../../assets/slicing/socmed/phone.png'
import twitter from '../../../../assets/slicing/socmed/twitter.svg'
import fax from '../../../../assets/slicing/socmed/fax.svg'
import GoogleMapReact from 'google-map-react'
import {MapMarker} from 'mdi-material-ui'
import classNames from 'classnames'

const useStyles = makeStyles(() => ({
  divider: {margin: '22px 0!important'},
  container: {display: 'flex'},
  detailLocation: {
    padding: '16px 13px 38px 16px',
    cursor: 'pointer',
    marginRight: 5,
    borderBottom: '2px solid #f7f8f9',
  },
  grey: {background: '#f7f8f9'},
  addresContainer: {width: '35%'},
  mapsContainer: {height: 250, width: '100%'},
  search: {
    marginTop: '0px !important',
    width: '235px !important',
  },
  officeLocation: {
    height: '485px',
    overflowY: 'auto',
  },
  paddingOffice: {padding: '30px 10px 30px 20px !important'},
  marginTop30: {marginTop: '30px'},
  marginTop20: {marginTop: '20px'},
  marginTop10: {marginTop: '10px'},
  marginTop5: {marginTop: '5px'},
  marginLeft10: {marginLeft: '10px'},
  padding25p: {padding: '25px'},
  width90P: {width: '90%'},
  width70P: {width: '70%'},
  width60P: {width: '60%'},
  width40P: {width: '40%'},
  width10P: {width: '10%'},
  largeWhiteText: {color: '#fff', fontSize: '16px', fontWeight: 600},
  largeBlackText: {color: '#000000', fontSize: '16px', fontWeight: 600},
  BlackText: {color: '#000000', fontSize: '14px'},
  GreenText: {color: '#4caf50', fontSize: '14px'},
  RedText: {color: '#ff0000', fontSize: '14px'},
  littleDarkBlueText: {color: '#014a62', fontSize: '12px', fontWeight: 600},
  littleGreyText: {color: '#a9a8a8', fontSize: '12px', fontWeight: 600},
  alignSelfStart: {alignSelf: 'start'},
}))

const PopupDetailOffice = props => {
  const {handleClose, open, data, mainOffice, contact, telephone} = props

  const classes = useStyles()

  const lat = data?.address_field?.lat
  const lng = data?.address_field?.lng

  const isActiveOffice = data?.status?.toLowerCase() === 'active'

  const renderContact = contactType => {
    const renderIcon = {
      phone: phone,
      mail: mail,
      fax: fax,
      linkedin: linkedin,
      twitter: twitter,
      facebook: facebook,
    }

    const renderDataContact = {
      phone: telephone,
      mail: contact?.email,
      fax: contact?.fax,
      linkedin: contact?.linkedin,
      twitter: contact?.twitter,
      facebook: contact?.facebook,
    }

    return (
      <FlexBetween className={classes.marginTop10}>
        <div className={classes.width10P}>
          <img
            src={renderIcon[contactType]}
            alt="contact"
            style={{width: 22, height: 22}}
          />
        </div>
        <Typography
          variant="span"
          className={classNames(classes.BlackText, classes.width90P)}
        >
          {renderDataContact[contactType]}
        </Typography>
      </FlexBetween>
    )
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <PrimaryModalHeader>
        <Typography className={classes.largeWhiteText}>
          {data?.office_name}
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon style={{color: '#fff'}}>close</Icon>
        </IconButton>
      </PrimaryModalHeader>
      <DialogContent className={classes.padding25p}>
        <div className={classes.mapsContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ',
              libraries: 'places',
            }}
            center={{lat, lng}}
            defaultZoom={16}
            yesIWantToUseGoogleMapApiInternals
          >
            <MapMarker
              style={{color: '#014a62'}}
              lat={lat}
              lng={lng}
              title={
                <>
                  address
                  <span />
                </>
              }
            />
          </GoogleMapReact>
        </div>
        <FlexBetween className={classes.marginTop30}>
          <div className={classNames(classes.width60P, classes.alignSelfStart)}>
            <Typography className={classes.largeBlackText}>
              {data?.office_name}
            </Typography>
            {data?.id === mainOffice && (
              <Typography
                className={classNames(
                  classes.littleDarkBlueText,
                  classes.marginTop5
                )}
              >
                Main Office
              </Typography>
            )}
            <Typography
              className={classNames(classes.littleGreyText, classes.marginTop5)}
            >
              {data?.office_code}
            </Typography>
            <Typography
              className={classNames(
                classes.BlackText,
                classes.marginTop5,
                classes.width70P
              )}
            >
              {data?.address}
            </Typography>
            <Typography
              className={classNames(classes.littleGreyText, classes.marginTop5)}
            >
              {`${data?.global_province?.name ?? ''} - ${data?.global_city
                ?.name ?? ''}`}
            </Typography>
            <Typography
              className={classNames(classes.littleGreyText, classes.marginTop5)}
            >
              {data?.postal_code}
            </Typography>
            <Typography
              className={classNames(
                classes.largeBlackText,
                classes.marginTop30
              )}
            >
              Additional Information
            </Typography>
            <Typography
              className={classNames(
                classes.BlackText,
                classes.marginTop5,
                classes.width70P
              )}
            >
              {data?.address_field?.additional_info ?? '-'}
            </Typography>
          </div>
          <div className={classNames(classes.width40P, classes.alignSelfStart)}>
            <Typography className={classes.largeBlackText}>
              Office Status
            </Typography>
            <Typography
              className={
                isActiveOffice
                  ? classNames(classes.GreenText, classes.marginTop10)
                  : classNames(classes.RedText, classes.marginTop10)
              }
            >
              {isActiveOffice ? 'Active' : 'Inactive'}
            </Typography>
            <Typography
              className={classNames(
                classes.largeBlackText,
                classes.marginTop20
              )}
            >
              Contact
            </Typography>
            {renderContact('phone')}
            {renderContact('mail')}
            {renderContact('fax')}
            {renderContact('linkedin')}
            {renderContact('twitter')}
            {renderContact('facebook')}
          </div>
        </FlexBetween>
      </DialogContent>
    </Dialog>
  )
}

export default PopupDetailOffice
