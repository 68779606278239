import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'

const DialogReportDownload = props => {
  const {open, handleClose} = props

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle style={{color: '#014a62'}}>
        Generate Data is Processing!
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Generate Data is processing! We’ll send you an email when it’s ready.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogReportDownload
