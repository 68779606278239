import gql from 'graphql-tag'

export const ADD_QUESTION_MICROLEARNING = gql`
  mutation($objects: [microlearning_questions_insert_input!]!) {
    insert_microlearning_questions(objects: $objects) {
      affected_rows
    }
  }
`

export const DELETE_QUESTION_MICROLEARNING = gql`
  mutation($question_id: uuid!) {
    update_microlearning_questions(
      _set: {deleted_at: "now()"}
      where: {id: {_eq: $question_id}}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_QUESTION_MICROLEARNING = gql`
  mutation(
    $question_id: uuid!
    $question: String!
    $answers: [microlearning_question_answers_insert_input!]!
    $questionType: smallint
  ) {
    update_microlearning_question_answers(
      _set: {deleted_at: "now()"}
      where: {microlearning_question_id: {_eq: $question_id}}
    ) {
      affected_rows
    }
    update_microlearning_questions(
      where: {id: {_eq: $question_id}}
      _set: {question: $question, question_type: $questionType}
    ) {
      affected_rows
    }
    insert_microlearning_question_answers(objects: $answers) {
      affected_rows
    }
  }
`
