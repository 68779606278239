import gql from 'graphql-tag'
import {COMPANY_ID} from '../../../../../utils/globals'

export const GET_DETAIL_CLUSTER = gql`
  query getClusterPeople($id: Int, $search: String) {
    holding_relations(
      where: {
        status:{_eq:"ACTIVE"},
        holding_cluster: {
          id: {_eq: $id}
          _and: {company: {_eq: "${COMPANY_ID}"}}
        }
        _or: [
          {
            _or: [
              {company_member_profile: {legal_name: {_ilike: $search}}}
              {company_member_profile: {brand_name: {_ilike: $search}}}
            ]
          }
          {holding_category: {name: {_ilike: $search}}}
        ]
      }
      order_by: {holding_cluster: {id: asc}}
    ) {
      cluster: holding_cluster {
        id
        name
      }
      category: holding_category {
        id
        name
      }
      profile_company: company_member_profile {
        id
        legal_name
        brand_name
        logo
        company_people_count: people_work_placements_aggregate(
          where: {status: {_eq: "ACTIVE"}}
        ) {
          aggregate {
            count
          }
        }
        people_member: people_work_placements(
          where: {status: {_eq: "ACTIVE"}}
        ) {
          id_placement: id
          regno
          global_user {
            avatar
          }
        }
      }
    }
    holding_count:holding_relations_aggregate(
      where: {
          status:{_eq:"ACTIVE"},        
          holding_cluster: {
              id: {_eq: $id}, 
              _and: {company: {_eq: "${COMPANY_ID}"}}
          }

          _or:[
                {
                _or:[
                    {
                    company_member_profile:{
                        legal_name:{
                        _ilike:$search
                        }
                    }
                    },
                    {
                    company_member_profile:{
                        brand_name:{
                        _ilike:$search
                        }
                    }
                    }
                ]
                },

                {
                holding_category:{
                    name:{
                    _ilike:$search
                    }
                }
                }
            ]
        })
  {
    aggregate{
      count
    }
  }
  }
`

export const GET_DETAIL_SUBCLUSTER = gql`
  query getSubclusterPeople($id: Int, $search: String) {
    holding_relations(
      where: {
        status:{_eq:"ACTIVE"},
        holding_subcluster: {
          id: {_eq: $id}
          _and: {company: {_eq: "${COMPANY_ID}"}}
        }
        _or: [
          {
            _or: [
              {company_member_profile: {legal_name: {_ilike: $search}}}
              {company_member_profile: {brand_name: {_ilike: $search}}}
            ]
          }
          {holding_category: {name: {_ilike: $search}}}
        ]
      }
    ) {
      subcluster: holding_subcluster {
        id
        name
      }
      category: holding_category {
        id
        name
      }
      profile_company: company_member_profile {
        id
        legal_name
        brand_name
        logo
        company_people_count: people_work_placements_aggregate(
          where: {status: {_eq: "ACTIVE"}}
        ) {
          aggregate {
            count
          }
        }
        people_member: people_work_placements(
          where: {status: {_eq: "ACTIVE"}}
        ) {
          id_placemnet: id
          regno
          global_user {
            avatar
          }
        }
      }
    }
    holding_count:holding_relations_aggregate(
      where: {
          status:{_eq:"ACTIVE"},        
          holding_subcluster: {
              id: {_eq: $id}, 
              _and: {company: {_eq: "${COMPANY_ID}"}}
          }

          _or:[
                {
                _or:[
                    {
                    company_member_profile:{
                        legal_name:{
                        _ilike:$search
                        }
                    }
                    },
                    {
                    company_member_profile:{
                        brand_name:{
                        _ilike:$search
                        }
                    }
                    }
                ]
                },

                {
                holding_category:{
                    name:{
                    _ilike:$search
                    }
                }
                }
            ]
        })
  {
    aggregate{
      count
    }
  }
  }
`
