import CompanyFilePage from './CompanyFileComponent'

export const CompanyFilePageConfig = {
  routes: [
    {
      path: '/document/company-file',
      component: CompanyFilePage,
      state: 'company-file',
      exact: true,
    },
    {
      path: '/document/company-file/upload-file',
      component: CompanyFilePage,
      state: 'company-file',
      exact: true,
    },
  ],
}
