import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {InputAdornment} from '@material-ui/core'

import {FormContainer} from '../../../../../GlobalStyles'

import NumberInputGroup from '../../../../../components/form/NumberInputGroup'
import FormAction from '../../../../../components/form/FormActionComponent'
import SelectInputGroup from '../../../../../components/form/SelectInputGroup'
import TextInputGroup from '../../../../../components/form/TextInputGroup'

import AlertPopup from '../../../../shared-components/popup/ResultPopup'

import {convertToAngka, numberFormatter} from '../../../../../utils/helpers'

const PolicyDetailForm = props => {
  const {
    dataForm,
    defaultData,
    isSubmit,
    mutation = () => {},
    serviceLoading,
    setDataForm,
  } = props
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const [cancelIsOpen, setCancelIsOpen] = useState(false)

  const disabledSubmitButton = !dataForm?.name
  // Remove the mandatory properties from fields: untill further notice
  // !dataForm?.loan_max_amount > 0 ||
  // !dataForm?.installment ||
  // dataForm?.interest_rate === undefined ||
  // dataForm?.interest_rate === ''

  return (
    <FormContainer>
      <TextInputGroup
        dataForm={dataForm}
        setDataForm={setDataForm}
        defaultValue={defaultData?.name}
        fieldLabel="Loan Policy Name *"
        fieldNumber="1"
        fieldName="name"
        placeholder="Type loan policy name here"
        showErrorHelper={() => isSubmit && !dataForm?.name}
        tooltipMesssage="Loan Policy Name"
      />

      <NumberInputGroup
        isCurrency
        dataForm={dataForm}
        setDataForm={setDataForm}
        defaultValue={defaultData?.loan_max_amount}
        fieldLabel="Maximum Amount of Loan"
        fieldNumber="2"
        fieldName="loan_max_amount"
        placeholder="Type maximum amount of loan here"
        required={false}
        // showErrorHelper={() => isSubmit && !dataForm?.loan_max_amount}
        tooltipMesssage="Maximum Amount of Loan"
        maskingMethod={value => {
          return numberFormatter.format(convertToAngka(value)).toString()
        }}
      />

      <SelectInputGroup
        isSearchable={false}
        dataForm={dataForm}
        setDataForm={setDataForm}
        dataOptions={INSTALLMENT_OPTIONS}
        defaultValue={defaultData?.installment}
        fieldLabel="Installment"
        fieldNumber="3"
        fieldName="installment"
        placeholder="Select here"
        required={false}
        // showErrorHelper={() => isSubmit && !dataForm?.installment}
        tooltipMesssage="Installment"
      />

      <NumberInputGroup
        dataForm={dataForm}
        setDataForm={setDataForm}
        defaultValue={defaultData?.interest_rate?.toString()}
        fieldLabel="Interest Rate"
        fieldNumber="4"
        fieldName="interest_rate"
        isDecimal={true}
        placeholder="Type interest rate here"
        tooltipMesssage="Interest Rate"
        type="number"
        required={false}
        // showErrorHelper={() =>
        //   isSubmit &&
        //   (dataForm?.interest_rate === undefined ||
        //     dataForm?.interest_rate === '')
        // }
        additionalInputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          inputProps: {
            min: 0,
            step: '0.1',
            style: {textAlign: 'right'},
          },
        }}
        maskingMethod={value => {
          return parseFloat(value).toString()
        }}
      />

      <FormAction
        openConfirmartionDialog={() => setConfirmIsOpen(true)}
        openCancellationDialog={() => setCancelIsOpen(true)}
        disabledSubmitButton={
          (isSubmit && disabledSubmitButton) || serviceLoading
        }
      />

      {confirmIsOpen && (
        <AlertPopup
          open={confirmIsOpen}
          feature="Loan Policy"
          message="Are you sure you want to save changes?"
          type="Confirm"
          status="cancel"
          handleClose={() => {
            setConfirmIsOpen(false)
          }}
          mutation={() => {
            setConfirmIsOpen(false)
            mutation()
          }}
        />
      )}

      {cancelIsOpen && (
        <AlertPopup
          open={cancelIsOpen}
          feature="Discard Changes?"
          message="Are you sure you want to discard changes?"
          type="Discard"
          status="cancel"
          handleClose={() => {
            setCancelIsOpen(false)
          }}
          mutation={() => {
            setCancelIsOpen(false)
            props.history.goBack()
          }}
        />
      )}
    </FormContainer>
  )
}

const INSTALLMENT_OPTIONS = [
  {
    label: '3x',
    value: 3,
  },
  {
    label: '6x',
    value: 6,
  },
  {
    label: '12x',
    value: 12,
  },
  {
    label: '24x',
    value: 24,
  },
]

export default withRouter(PolicyDetailForm)
