import { APP_COOKIE, SSO_URL } from './globals'
import moment from 'moment'
import 'moment/locale/id'

// Currency formatting
const idrFormatterPadded = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
})

const idrFormatter = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  minimumFractionDigits: 0,
})

export function convertToRupiah(nominal, paddedFraction = true) {
  const formatter = paddedFraction ? idrFormatterPadded : idrFormatter

  // The formatter does rounding when truncating fractions,
  // if you have 0.999, it should not mean that you have Rp 1,00
  const fractions = paddedFraction ? 2 : 0
  const formatted = formatter.format(truncateFractional(nominal, fractions))

  // Additionally, there shouldn't be a space between currency sign and nominal
  return formatted.replace(/\s+/g, '')
}

export function convertToRoundedRupiah(nominal, paddedFraction) {
  return convertToRupiah(Math.round(nominal || 0), paddedFraction)
}

export function truncateFractional(number, digit) {
  const pow = 10 ** digit
  return Math.trunc(number * pow) / pow
}

export const formatatedSalary = salary => {
  if (salary && !Number.isInteger(salary) && salary !== '') {
    const number_string = salary.replace(/[^,\d]/g, '').toString()
    const split = number_string.split(',')
    const sisa = split[0].length % 3
    let rupiah = split[0].substr(0, sisa)
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    if (ribuan) {
      const separator = sisa ? '.' : ''
      rupiah += separator + ribuan.join('.')
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
    return rupiah
  } else {
    return salary
  }
}

export const changeToNumber = (number, spacer = null) => {
  if (number && !Number.isInteger(number)) {
    if (spacer) {
      const dataNumber = number.split(spacer)[1]
      return parseInt(dataNumber.split('.').join(''))
    } else {
      return parseInt(number.split('.').join(''))
    }
  } else {
    return number
  }
}

export function convertToAngka(rupiah) {
  return parseInt(('' + rupiah).replace(/[^0-9]/g, '') || '0', 10)
}

export const convertToCurrency = number => {
  return number
    ? number
      .toString()
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : 0
}

export const currencyToNormal = currency => {
  if (currency) {
    return parseInt(currency.split('.').join(''))
  }
}

export function nextAlphabet(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1)
}

export function countDownTimer(seconds) {
  const second = seconds / 1000
  let h = Math.floor(second / 3600)
  let m = Math.floor((second % 3600) / 60)
  let s = Math.floor((second % 3600) % 60)

  if (h < 10) h = '0' + h
  if (m < 10) m = '0' + m
  if (s < 10) s = '0' + s

  return h + ':' + m + ':' + s
}

export function getDate(date) {
  const initDate = new Date(date)
  let dd = initDate.getDate()
  let mm = initDate.getMonth() + 1
  const yyyy = initDate.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }

  if (mm < 10) {
    mm = `0${mm}`
  }

  const dateFull = `${dd}/${mm}/${yyyy}`
  return dateFull
}

export function dateENOrUS(date) {
  return date ? moment(date).format('MMMM DD, YYYY') : ''
}

export function dateMinENOrUS(date) {
  return date ? moment(date).format('MMM DD, YYYY') : ''
}

export function getFullDateWithOrdinal(date) {
  return date ? moment(date).format('MMMM Do, YYYY') : null
}
export function dateFormat(date, format) {
  return moment(date).format(format)
}

export function dateEndOf(date, unit, format) {
  return moment(date)
    .endOf(unit)
    .format(format)
}
export function dateSubtract(date, long, time, format) {
  return moment(date)
    .subtract(long, time)
    .format(format)
}
export function differenceDate(date, diff = 'years') {
  return moment().diff(date, diff)
}
export function differenceDateFrom(start, to, diff = 'years') {
  return moment(start).diff(to, diff)
}

export function convertDate(date) {
  const initDate = new Date(date)
  let dd = initDate.getDate()
  let mm = initDate.getMonth() + 1
  const yyyy = initDate.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }

  if (mm < 10) {
    mm = `0${mm}`
  }

  const dateFull = `${yyyy}-${mm}-${dd}`
  return dateFull
}

export function convertDateForID(date) {
  const initDate = new Date(date)
  let dd = initDate.getDate()
  let mm = initDate.getMonth() + 1
  const yyyy = initDate.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }

  if (mm < 10) {
    mm = `0${mm}`
  }

  const dateFull = `${dd}${mm}${yyyy}`
  return dateFull
}

export function createCookie(name, value, days) {
  let expires
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toGMTString()
  } else {
    expires = ''
  }
  document.cookie =
    name + '=' + value + expires + '; path=/; domain=' + APP_COOKIE
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=')
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end === -1) {
        c_end = document.cookie.length
      }
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}

export function setRoutes(config) {
  let routes = [...config.routes]

  if (config.settings || config.auth) {
    routes = routes.map(route => {
      let auth = config.auth ? [...config.auth] : []
      auth = route.auth ? [...auth, ...route.auth] : auth
      return {
        ...route,
        settings: {
          ...config.settings,
          ...route.settings,
        },
        auth,
      }
    })
  }

  return [...routes]
}

export function generateRoutesFromConfigs(configs) {
  let allRoutes = []
  configs.forEach(config => {
    allRoutes = [...allRoutes, ...setRoutes(config)]
  })
  return allRoutes
}

export const generateBadge = dataBadge => {
  return dataBadge.data.filter(item => item.check).length
}

export const capitalize = ([first, ...rest]) =>
  first?.toUpperCase() + rest?.join('')?.toLowerCase()

export const capitalizeEachWord = words => {
  const _words = words?.split(' ')?.map(word => {
    return word?.[0]?.toUpperCase() + word?.substring(1)
  })

  return _words?.join(' ')
}

export const MathBoxes = (dataList, dataBoxes) => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let uid = null
  let Xnpf = []
  let Xgoal = []
  let Xmultirater = []
  let Xogf = []
  let Yattedance = []
  let Ycompetency = []
  let dataProfile = []
  const setBoxes = []

  dataList &&
    dataList.people_work_placements.forEach(data => {
      data.talent_assignments.forEach(resx => {
        Xnpf.push(resx.indicator_perf_nps)
        Xgoal.push(resx.indicator_perf_goal)
        Xmultirater.push(resx.indicator_perf_multirater)
        Xogf.push(resx.indicator_perf_ogf)
        Yattedance.push(resx.indicator_pot_attendance)
        Ycompetency.push(resx.indicator_pot_competence)
      })
      dataProfile = data
      uid = data.global_user.id
      const dataIndicator = {
        npf: Xnpf.reduce((a, b) => a + b, 0).toFixed(2),
        ogf: Xogf.reduce((a, b) => a + b, 0).toFixed(2),
        goal: Xgoal.reduce((a, b) => a + b, 0).toFixed(2),
        multirater: Xmultirater.reduce((a, b) => a + b, 0).toFixed(2),
        attendance: Yattedance.reduce((a, b) => a + b, 0).toFixed(2),
        competency: Ycompetency.reduce((a, b) => a + b, 0).toFixed(2),
      }
      dataBoxes &&
        dataBoxes.talent_versions.forEach(res => {
          start = res.start_date
          end = res.end_date
          x =
            (res.indicator_perf_nps
              ? (Xnpf.reduce((a, b) => a + b, 0) *
                res.indicator_perf_nps_fields.weight) /
              100
              : 0) +
            (res.indicator_perf_ogf
              ? (Xogf.reduce((a, b) => a + b, 0) *
                res.indicator_perf_ogf_fields.weight) /
              100
              : 0) +
            (res.indicator_perf_goal
              ? (Xgoal.reduce((a, b) => a + b, 0) *
                res.indicator_perf_goal_fields.weight) /
              100
              : 0) +
            (res.indicator_perf_multirater
              ? (Xmultirater.reduce((a, b) => a + b, 0) *
                res.indicator_perf_multirater_fields.weight) /
              100
              : 0)
          y =
            (res.indicator_pot_attendance
              ? (Yattedance.reduce((a, b) => a + b, 0) *
                res.indicator_pot_attendance_fields.weight) /
              100
              : 0) +
            (res.indicator_pot_competence
              ? (Ycompetency.reduce((a, b) => a + b, 0) *
                res.indicator_pot_competence_fields.weight) /
              100
              : 0)
          const nilai = (x + y) / 2

          if (x >= res.min_perf && y >= res.min_pot) {
            persentaseX = (res.max_perf - res.min_perf) / 2
            persentaseY = (res.max_pot - res.min_pot) / 2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            const modX = x % persentaseX
            const modY = y % persentaseY
            if (modX > 0) {
              akhirX = Math.floor(akhirX)
            } else {
              akhirX = Math.floor(akhirX - 1)
            }

            if (modY > 0) {
              akhirY = Math.floor(akhirY)
            } else {
              akhirY = Math.floor(akhirY - 1)
            }

            data.talent_assignments.forEach(resx => {
              res.talent_boxes.forEach(resd => {
                if (resx.badge_classification !== null) {
                  if (resx.badge_classification === resd.id) {
                    setBoxes.push({
                      resd,
                      nilai: nilai.toFixed(2),
                      start,
                      end,
                      dataProfile,
                      dataIndicator,
                      uid,
                    })
                  }
                } else {
                  if (akhirX === resd.index_x && akhirY === resd.index_y) {
                    setBoxes.push({
                      resd,
                      nilai: nilai.toFixed(2),
                      start,
                      end,
                      dataProfile,
                      dataIndicator,
                      uid,
                    })
                  }
                }
              })
            })
          }
        })
      Xnpf = []
      Xgoal = []
      Xmultirater = []
      Xogf = []
      Yattedance = []
      Ycompetency = []
    })
  return setBoxes
}
export const MathDetailBoxes = (dataBoxes, flexy) => {
  const setBoxes = []
  const res = dataBoxes

  const boxRangeX =
    (parseInt(flexy.x.max) - parseInt(flexy.x.min)) / parseInt(res.box_width)
  const boxRangeY =
    (parseInt(flexy.y.max) - parseInt(flexy.y.min)) / parseInt(res.box_height)
  const findValue = parameter =>
    flexy.x.parameters.some(f => f.parameter === parameter)

  res.talent_boxes.data.forEach(resd => {
    const minBoxX = parseInt(flexy.x.min) + (resd.index_x - 1) * boxRangeX
    const minBoxY = parseInt(flexy.y.min) + (resd.index_y - 1) * boxRangeY

    const nps =
      res.indicator_perf_nps &&
      (parseInt(res.indicator_perf_nps_fields.weight) *
        (findValue('nps') ? minBoxX : minBoxY)) /
      100
    const ogf =
      res.indicator_perf_ogf &&
      (parseInt(res.indicator_perf_ogf_fields.weight) *
        (findValue('ogf') ? minBoxX : minBoxY)) /
      100
    const task =
      res.indicator_perf_goal &&
      (parseInt(res.indicator_perf_goal_fields.weight) *
        (findValue('task') ? minBoxX : minBoxY)) /
      100
    const multirater =
      res.indicator_perf_multirater &&
      (parseInt(res.indicator_perf_multirater_fields.weight) *
        (findValue('multirater') ? minBoxX : minBoxY)) /
      100
    const competency =
      res.indicator_pot_competence &&
      (parseInt(res.indicator_pot_competence_fields.weight) *
        (findValue('competency') ? minBoxX : minBoxY)) /
      100
    const attendance =
      res.indicator_pot_attendance &&
      (parseInt(res.indicator_pot_attendance_fields.weight) *
        (findValue('attendance') ? minBoxX : minBoxY)) /
      100
    const course =
      res.indicator_pot_total_course &&
      (parseInt(res.indicator_pot_total_course_fields.weight) *
        (findValue('course') ? minBoxX : minBoxY)) /
      100
    const socmed_post =
      res.indicator_pot_socmed_post &&
      (parseInt(res.indicator_pot_socmed_post_fields.weight) *
        (findValue('socmed_post') ? minBoxX : minBoxY)) /
      100
    const data = {
      nps,
      ogf,
      task,
      multirater,
      competency,
      attendance,
      course,
      socmed_post,
      index_x: resd.index_x,
      index_y: resd.index_y,
    }
    setBoxes.push(data)
  })

  return setBoxes
}

export const ClassificationBadge = dataBoxes => {
  const setBoxes = []
  dataBoxes &&
    dataBoxes.talent_versions.forEach(res => {
      const boxRangeY = (res.max_pot - res.min_pot) / res.box_height
      const boxRangeX = (res.max_perf - res.min_perf) / res.box_width
      res.talent_boxes.forEach(resd => {
        const minBoxY = (res.min_pot + resd.index_y) * boxRangeY
        const minBoxX = (res.min_perf + resd.index_x) * boxRangeX
        const nps = (res.indicator_perf_nps_fields.weight * minBoxX) / 100
        const ogf = (res.indicator_perf_ogf_fields.weight * minBoxX) / 100
        const goal = (res.indicator_perf_goal_fields.weight * minBoxX) / 100
        const multirater =
          (res.indicator_perf_multirater_fields.weight * minBoxX) / 100
        const competency =
          (res.indicator_pot_competence_fields.weight * minBoxY) / 100
        const attendance =
          (res.indicator_pot_attendance_fields.weight * minBoxY) / 100
        const data = {
          nps: nps.toFixed(2),
          ogf: ogf.toFixed(2),
          goal: goal.toFixed(2),
          multirater: multirater.toFixed(2),
          competency: competency.toFixed(2),
          attendance: attendance.toFixed(2),
          resd,
        }
        setBoxes.push(data)
      })
    })

  return setBoxes
}
export const persentaseData = (a, b) => {
  let nilai = 0
  if (a === 0 && b === 0) {
    nilai = 0
  } else {
    nilai = (a / b) * 100
  }
  return Math.floor(nilai)
}

export const dataNotDefined = (a, b, c, d) => {
  let nilai = 0
  if (a === 0 && b === 0 && c === 0 && d === 0) {
    nilai = 0
  } else {
    nilai = a - (b + c + d)
  }
  return nilai
}
export const PersonalBoxes = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []
  dataList &&
    dataList.people_work_placements.forEach(data => {
      if (data.talent_assignments.length !== 0) {
        data.talent_assignments.forEach(resx => {
          const dataIndicator = {
            npf: parseInt(resx.indicator_perf_nps).toFixed(2),
            ogf: parseInt(resx.indicator_perf_ogf).toFixed(2),
            goal: parseInt(resx.indicator_perf_goal).toFixed(2),
            multirater: parseInt(resx.indicator_perf_multirater).toFixed(2),
            attendance: parseInt(resx.indicator_pot_attendance).toFixed(2),
            competency: parseInt(resx.indicator_pot_competence).toFixed(2),
          }
          const nilai =
            (resx.talentVersionByTalentVersion.indicator_perf_nps
              ? (resx.indicator_perf_nps *
                resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_ogf
              ? (resx.indicator_perf_ogf *
                resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_goal
              ? (resx.indicator_perf_goal *
                resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_multirater
              ? (resx.indicator_perf_multirater *
                resx.talentVersionByTalentVersion
                  .indicator_perf_multirater_fields.weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_pot_attendance
              ? (resx.indicator_pot_attendance *
                resx.talentVersionByTalentVersion
                  .indicator_pot_attendance_fields.weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_pot_competence
              ? (resx.indicator_pot_competence *
                resx.talentVersionByTalentVersion
                  .indicator_pot_competence_fields.weight) /
              100
              : 0) /
            2

          x =
            (resx.talentVersionByTalentVersion.indicator_perf_nps
              ? (resx.indicator_perf_nps *
                resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_ogf
              ? (resx.indicator_perf_ogf *
                resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_goal
              ? (resx.indicator_perf_goal *
                resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_multirater
              ? (resx.indicator_perf_multirater *
                resx.talentVersionByTalentVersion
                  .indicator_perf_multirater_fields.weight) /
              100
              : 0)
          y =
            (resx.talentVersionByTalentVersion.indicator_pot_attendance
              ? (resx.indicator_pot_attendance *
                resx.talentVersionByTalentVersion
                  .indicator_pot_attendance_fields.weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_pot_competence
              ? (resx.indicator_pot_competence *
                resx.talentVersionByTalentVersion
                  .indicator_pot_competence_fields.weight) /
              100
              : 0)

          if (
            x >= resx.talentVersionByTalentVersion.min_perf &&
            y >= resx.talentVersionByTalentVersion.min_pot
          ) {
            persentaseX =
              (resx.talentVersionByTalentVersion.max_perf -
                resx.talentVersionByTalentVersion.min_perf) /
              2
            persentaseY =
              (resx.talentVersionByTalentVersion.max_pot -
                resx.talentVersionByTalentVersion.min_pot) /
              2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            const modX = x % persentaseX
            const modY = y % persentaseY
            if (modX > 0) {
              akhirX = Math.floor(akhirX)
            } else {
              akhirX = Math.floor(akhirX - 1)
            }

            if (modY > 0) {
              akhirY = Math.floor(akhirY)
            } else {
              akhirY = Math.floor(akhirY - 1)
            }

            dataProfile = data
            start = resx.talentVersionByTalentVersion.start_date
            end = resx.talentVersionByTalentVersion.end_date
            resx.talentVersionByTalentVersion.talent_boxes.forEach(resd => {
              if (resx.badge_classification !== null) {
                if (resx.badge_classification === resd.id) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                  })
                }
              } else {
                if (akhirX === resd.index_x && akhirY === resd.index_y) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                  })
                }
              }
            })
          } else {
            setBoxes.push({
              resd: null,
              nilai: null,
              start: null,
              end: null,
              dataProfile: data,
              dataIndicator: null,
            })
          }
        })
      } else {
        setBoxes.push({
          resd: null,
          nilai: null,
          start: null,
          end: null,
          dataProfile: data,
          dataIndicator: null,
        })
      }
    })

  return setBoxes
}

const talent_successor = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []
  dataList &&
    dataList.forEach(data => {
      if (
        data.people_work_placement.global_user.talent_assignments.length !== 0
      ) {
        data.people_work_placement.global_user.talent_assignments.forEach(
          resx => {
            const dataIndicator = {
              npf: parseInt(resx.indicator_perf_nps).toFixed(2),
              ogf: parseInt(resx.indicator_perf_ogf).toFixed(2),
              goal: parseInt(resx.indicator_perf_goal).toFixed(2),
              multirater: parseInt(resx.indicator_perf_multirater).toFixed(2),
              attendance: parseInt(resx.indicator_pot_attendance).toFixed(2),
              competency: parseInt(resx.indicator_pot_competence).toFixed(2),
            }
            const nilai =
              (resx.talentVersionByTalentVersion.indicator_perf_nps
                ? (resx.indicator_perf_nps *
                  resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_ogf
                ? (resx.indicator_perf_ogf *
                  resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_goal
                ? (resx.indicator_perf_goal *
                  resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_multirater
                ? (resx.indicator_perf_multirater *
                  resx.talentVersionByTalentVersion
                    .indicator_perf_multirater_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_attendance
                ? (resx.indicator_pot_attendance *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_attendance_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_competence
                ? (resx.indicator_pot_competence *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_competence_fields.weight) /
                100
                : 0) /
              2
            x =
              (resx.talentVersionByTalentVersion.indicator_perf_nps
                ? (resx.indicator_perf_nps *
                  resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_ogf
                ? (resx.indicator_perf_ogf *
                  resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_goal
                ? (resx.indicator_perf_goal *
                  resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_multirater
                ? (resx.indicator_perf_multirater *
                  resx.talentVersionByTalentVersion
                    .indicator_perf_multirater_fields.weight) /
                100
                : 0)
            y =
              (resx.talentVersionByTalentVersion.indicator_pot_attendance
                ? (resx.indicator_pot_attendance *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_attendance_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_competence
                ? (resx.indicator_pot_competence *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_competence_fields.weight) /
                100
                : 0)

            if (
              x >= resx.talentVersionByTalentVersion.min_perf &&
              y >= resx.talentVersionByTalentVersion.min_pot
            ) {
              persentaseX =
                (resx.talentVersionByTalentVersion.max_perf -
                  resx.talentVersionByTalentVersion.min_perf) /
                2
              persentaseY =
                (resx.talentVersionByTalentVersion.max_pot -
                  resx.talentVersionByTalentVersion.min_pot) /
                2
              akhirX = x / persentaseX
              akhirY = y / persentaseY
              const modX = x % persentaseX
              const modY = y % persentaseY
              if (modX > 0) {
                akhirX = Math.floor(akhirX)
              } else {
                akhirX = Math.floor(akhirX - 1)
              }

              if (modY > 0) {
                akhirY = Math.floor(akhirY)
              } else {
                akhirY = Math.floor(akhirY - 1)
              }

              dataProfile = data
              start = resx.talentVersionByTalentVersion.start_date
              end = resx.talentVersionByTalentVersion.end_date
              resx.talentVersionByTalentVersion.talent_boxes.forEach(resd => {
                if (resx.badge_classification !== null) {
                  if (resx.badge_classification === resd.id) {
                    setBoxes.push({
                      resd,
                      nilai: nilai.toFixed(2),
                      start,
                      end,
                      dataProfile,
                      dataIndicator,
                    })
                  }
                } else {
                  if (akhirX === resd.index_x && akhirY === resd.index_y) {
                    setBoxes.push({
                      resd,
                      nilai: nilai.toFixed(2),
                      start,
                      end,
                      dataProfile,
                      dataIndicator,
                    })
                  }
                }
              })
            } else {
              setBoxes.push({
                resd: null,
                nilai: null,
                start: null,
                end: null,
                dataProfile: data,
                dataIndicator: null,
              })
            }
          }
        )
      } else {
        setBoxes.push({
          resd: null,
          nilai: null,
          start: null,
          end: null,
          dataProfile: data,
          dataIndicator: null,
        })
      }
    })

  return setBoxes
}
const talent_pool = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []
  dataList &&
    dataList.forEach(data => {
      if (
        data.people_work_placement &&
        data.people_work_placement.global_user &&
        data.people_work_placement.global_user.talent_assignments.length !== 0
      ) {
        data.people_work_placement.global_user.talent_assignments.forEach(
          resx => {
            const dataIndicator = {
              npf: parseInt(resx.indicator_perf_nps).toFixed(2),
              ogf: parseInt(resx.indicator_perf_ogf).toFixed(2),
              goal: parseInt(resx.indicator_perf_goal).toFixed(2),
              multirater: parseInt(resx.indicator_perf_multirater).toFixed(2),
              attendance: parseInt(resx.indicator_pot_attendance).toFixed(2),
              competency: parseInt(resx.indicator_pot_competence).toFixed(2),
            }
            const nilai =
              (resx.talentVersionByTalentVersion.indicator_perf_nps
                ? (resx.indicator_perf_nps *
                  resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_ogf
                ? (resx.indicator_perf_ogf *
                  resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_goal
                ? (resx.indicator_perf_goal *
                  resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_multirater
                ? (resx.indicator_perf_multirater *
                  resx.talentVersionByTalentVersion
                    .indicator_perf_multirater_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_attendance
                ? (resx.indicator_pot_attendance *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_attendance_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_competence
                ? (resx.indicator_pot_competence *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_competence_fields.weight) /
                100
                : 0) /
              2

            x =
              (resx.talentVersionByTalentVersion.indicator_perf_nps
                ? (resx.indicator_perf_nps *
                  resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_ogf
                ? (resx.indicator_perf_ogf *
                  resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_goal
                ? (resx.indicator_perf_goal *
                  resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_multirater
                ? (resx.indicator_perf_multirater *
                  resx.talentVersionByTalentVersion
                    .indicator_perf_multirater_fields.weight) /
                100
                : 0)
            y =
              (resx.talentVersionByTalentVersion.indicator_pot_attendance
                ? (resx.indicator_pot_attendance *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_attendance_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_competence
                ? (resx.indicator_pot_competence *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_competence_fields.weight) /
                100
                : 0)

            if (
              x >= resx.talentVersionByTalentVersion.min_perf &&
              y >= resx.talentVersionByTalentVersion.min_pot
            ) {
              persentaseX =
                (resx.talentVersionByTalentVersion.max_perf -
                  resx.talentVersionByTalentVersion.min_perf) /
                2
              persentaseY =
                (resx.talentVersionByTalentVersion.max_pot -
                  resx.talentVersionByTalentVersion.min_pot) /
                2
              akhirX = x / persentaseX
              akhirY = y / persentaseY
              const modX = x % persentaseX
              const modY = y % persentaseY
              if (modX > 0) {
                akhirX = Math.floor(akhirX)
              } else {
                akhirX = Math.floor(akhirX - 1)
              }

              if (modY > 0) {
                akhirY = Math.floor(akhirY)
              } else {
                akhirY = Math.floor(akhirY - 1)
              }

              dataProfile = data
              start = resx.talentVersionByTalentVersion.start_date
              end = resx.talentVersionByTalentVersion.end_date
              resx.talentVersionByTalentVersion.talent_boxes.forEach(resd => {
                if (resx.badge_classification !== null) {
                  if (resx.badge_classification === resd.id) {
                    setBoxes.push({
                      resd,
                      nilai: nilai.toFixed(2),
                      start,
                      end,
                      dataProfile,
                      dataIndicator,
                    })
                  }
                } else {
                  if (akhirX === resd.index_x && akhirY === resd.index_y) {
                    setBoxes.push({
                      resd,
                      nilai: nilai.toFixed(2),
                      start,
                      end,
                      dataProfile,
                      dataIndicator,
                    })
                  }
                }
              })
            } else {
              setBoxes.push({
                resd: null,
                nilai: null,
                start: null,
                end: null,
                dataProfile: data,
                dataIndicator: null,
              })
            }
          }
        )
      } else {
        setBoxes.push({
          resd: null,
          nilai: null,
          start: null,
          end: null,
          dataProfile: data,
          dataIndicator: null,
        })
      }
    })

  return setBoxes
}
export const add_talent_pool = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []
  dataList &&
    dataList.people_work_placements.forEach(data => {
      if (data.global_user.talent_assignments.length !== 0) {
        data.global_user.talent_assignments.forEach(resx => {
          const dataIndicator = {
            npf: parseInt(resx.indicator_perf_nps).toFixed(2),
            ogf: parseInt(resx.indicator_perf_ogf).toFixed(2),
            goal: parseInt(resx.indicator_perf_goal).toFixed(2),
            multirater: parseInt(resx.indicator_perf_multirater).toFixed(2),
            attendance: parseInt(resx.indicator_pot_attendance).toFixed(2),
            competency: parseInt(resx.indicator_pot_competence).toFixed(2),
          }
          const nilai =
            (resx.talentVersionByTalentVersion.indicator_perf_nps
              ? (resx.indicator_perf_nps *
                resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_ogf
              ? (resx.indicator_perf_ogf *
                resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_goal
              ? (resx.indicator_perf_goal *
                resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_multirater
              ? (resx.indicator_perf_multirater *
                resx.talentVersionByTalentVersion
                  .indicator_perf_multirater_fields.weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_pot_attendance
              ? (resx.indicator_pot_attendance *
                resx.talentVersionByTalentVersion
                  .indicator_pot_attendance_fields.weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_pot_competence
              ? (resx.indicator_pot_competence *
                resx.talentVersionByTalentVersion
                  .indicator_pot_competence_fields.weight) /
              100
              : 0) /
            2

          x =
            (resx.talentVersionByTalentVersion.indicator_perf_nps
              ? (resx.indicator_perf_nps *
                resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_ogf
              ? (resx.indicator_perf_ogf *
                resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_goal
              ? (resx.indicator_perf_goal *
                resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_multirater
              ? (resx.indicator_perf_multirater *
                resx.talentVersionByTalentVersion
                  .indicator_perf_multirater_fields.weight) /
              100
              : 0)
          y =
            (resx.talentVersionByTalentVersion.indicator_pot_attendance
              ? (resx.indicator_pot_attendance *
                resx.talentVersionByTalentVersion
                  .indicator_pot_attendance_fields.weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_pot_competence
              ? (resx.indicator_pot_competence *
                resx.talentVersionByTalentVersion
                  .indicator_pot_competence_fields.weight) /
              100
              : 0)

          if (
            x >= resx.talentVersionByTalentVersion.min_perf &&
            y >= resx.talentVersionByTalentVersion.min_pot
          ) {
            persentaseX =
              (resx.talentVersionByTalentVersion.max_perf -
                resx.talentVersionByTalentVersion.min_perf) /
              2
            persentaseY =
              (resx.talentVersionByTalentVersion.max_pot -
                resx.talentVersionByTalentVersion.min_pot) /
              2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            const modX = x % persentaseX
            const modY = y % persentaseY
            if (modX > 0) {
              akhirX = Math.floor(akhirX)
            } else {
              akhirX = Math.floor(akhirX - 1)
            }

            if (modY > 0) {
              akhirY = Math.floor(akhirY)
            } else {
              akhirY = Math.floor(akhirY - 1)
            }

            dataProfile = data
            start = resx.talentVersionByTalentVersion.start_date
            end = resx.talentVersionByTalentVersion.end_date
            resx.talentVersionByTalentVersion.talent_boxes.forEach(resd => {
              if (resx.badge_classification !== null) {
                if (resx.badge_classification === resd.id) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                  })
                }
              } else {
                if (akhirX === resd.index_x && akhirY === resd.index_y) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                  })
                }
              }
            })
          }
        })
      } else {
        setBoxes.push({
          resd: null,
          nilai: null,
          start: null,
          end: null,
          dataProfile: data,
          dataIndicator: null,
        })
      }
    })

  return setBoxes
}
export const SuccessionBoxes = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []

  dataList &&
    dataList.people_work_placements.forEach(data => {
      const talent_pools = talent_pool(data.company_job_profile.talent_pool)
      const talent_successors = talent_successor(
        data.company_job_profile.successors
      )
      if (data.talent_assignments.length !== 0) {
        data.talent_assignments.forEach(resx => {
          const dataIndicator = {
            npf: parseInt(resx.indicator_perf_nps).toFixed(2),
            ogf: parseInt(resx.indicator_perf_ogf).toFixed(2),
            goal: parseInt(resx.indicator_perf_goal).toFixed(2),
            multirater: parseInt(resx.indicator_perf_multirater).toFixed(2),
            attendance: parseInt(resx.indicator_pot_attendance).toFixed(2),
            competency: parseInt(resx.indicator_pot_competence).toFixed(2),
          }
          const nilai =
            (resx.talentVersionByTalentVersion.indicator_perf_nps
              ? (resx.indicator_perf_nps *
                resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_ogf
              ? (resx.indicator_perf_ogf *
                resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_goal
              ? (resx.indicator_perf_goal *
                resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_multirater
              ? (resx.indicator_perf_multirater *
                resx.talentVersionByTalentVersion
                  .indicator_perf_multirater_fields.weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_pot_attendance
              ? (resx.indicator_pot_attendance *
                resx.talentVersionByTalentVersion
                  .indicator_pot_attendance_fields.weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_pot_competence
              ? (resx.indicator_pot_competence *
                resx.talentVersionByTalentVersion
                  .indicator_pot_competence_fields.weight) /
              100
              : 0) /
            2

          x =
            (resx.talentVersionByTalentVersion.indicator_perf_nps
              ? (resx.indicator_perf_nps *
                resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_ogf
              ? (resx.indicator_perf_ogf *
                resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_goal
              ? (resx.indicator_perf_goal *
                resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                  .weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_perf_multirater
              ? (resx.indicator_perf_multirater *
                resx.talentVersionByTalentVersion
                  .indicator_perf_multirater_fields.weight) /
              100
              : 0)
          y =
            (resx.talentVersionByTalentVersion.indicator_pot_attendance
              ? (resx.indicator_pot_attendance *
                resx.talentVersionByTalentVersion
                  .indicator_pot_attendance_fields.weight) /
              100
              : 0) +
            (resx.talentVersionByTalentVersion.indicator_pot_competence
              ? (resx.indicator_pot_competence *
                resx.talentVersionByTalentVersion
                  .indicator_pot_competence_fields.weight) /
              100
              : 0)

          if (
            x >= resx.talentVersionByTalentVersion.min_perf &&
            y >= resx.talentVersionByTalentVersion.min_pot
          ) {
            persentaseX =
              (resx.talentVersionByTalentVersion.max_perf -
                resx.talentVersionByTalentVersion.min_perf) /
              2
            persentaseY =
              (resx.talentVersionByTalentVersion.max_pot -
                resx.talentVersionByTalentVersion.min_pot) /
              2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            const modX = x % persentaseX
            const modY = y % persentaseY
            if (modX > 0) {
              akhirX = Math.floor(akhirX)
            } else {
              akhirX = Math.floor(akhirX - 1)
            }

            if (modY > 0) {
              akhirY = Math.floor(akhirY)
            } else {
              akhirY = Math.floor(akhirY - 1)
            }

            dataProfile = data
            start = resx.talentVersionByTalentVersion.start_date
            end = resx.talentVersionByTalentVersion.end_date
            resx.talentVersionByTalentVersion.talent_boxes.forEach(resd => {
              if (resx.badge_classification !== null) {
                if (resx.badge_classification === resd.id) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    successors: talent_successors,
                    talent_pool: talent_pools,
                    dataIndicator,
                  })
                }
              } else {
                if (akhirX === resd.index_x && akhirY === resd.index_y) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    successors: talent_successors,
                    talent_pool: talent_pools,
                    dataIndicator,
                  })
                }
              }
            })
          } else {
            setBoxes.push({
              resd: null,
              nilai: null,
              start: null,
              end: null,
              dataProfile: data,
              successors: talent_successors,
              talent_pool: talent_pools,
              dataIndicator: null,
            })
          }
        })
      } else {
        setBoxes.push({
          resd: null,
          nilai: null,
          start: null,
          end: null,
          dataProfile: data,
          successors: talent_successors,
          talent_pool: talent_pools,
          dataIndicator: null,
        })
      }
    })

  return setBoxes
}

export const replaceChar = (origString, replaceChar, index) => {
  const newStringArray = origString.split('')

  newStringArray[index] = replaceChar

  const newString = newStringArray.join('')

  return newString
}

export const getValueRolesToNumber = (selectedRoles, roles) => {
  const role = [0, 0, 0, 0, 0, 0, 0, 0]

  for (let j = 0; j < selectedRoles.length; j++) {
    const index = roles.findIndex(val => val.value === selectedRoles[j])
    role[index] = 1
  }

  return role.join('')
}

export const formatBytes = (a, b) => {
  if (a === 0) {
    return '0 Bytes'
  }
  const c = 1024
  const d = b || 2
  const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const f = Math.floor(Math.log(a) / Math.log(c))
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}

export function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    const A = a[key]
    const B = b[key]

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    } else {
      return -1
    }
  })

  return array
}

export function isEmailAddress(str) {
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      str
    )
  ) {
    return true
  }
  return false
}

export function accessRightSwitcher(res) {
  let accessRight = ''
  const _res = res.split('')
  if (_res[0] === '1') {
    accessRight += 'Organization Administrator, '
  }
  if (_res[1] === '1') {
    accessRight += 'Organization Department Head, '
  }
  if (_res[2] === '1') {
    accessRight += 'Organization Finance Admin, '
  }
  if (_res[3] === '1') {
    accessRight += 'Organization HR_Admin, '
  }
  if (_res[4] === '1') {
    accessRight += 'Organization HR_Assistant, '
  }
  if (_res[5] === '1') {
    accessRight += 'Organization Payroll Master, '
  }
  if (_res[6] === '1') {
    accessRight += 'Organization Staff, '
  }
  if (_res[7] === '1') {
    accessRight += 'Organization Supervisor, '
  }
  return accessRight
}

export const selectProps = val => {
  return {
    displayEmpty: true,
    style: {
      color: !val ? '#bcbcbc' : 'unset',
    },
  }
}

export const holdingRolesContext = {
  context: {
    headers: {
      'X-Hasura-Role': 'holding-administrator',
    },
  },
}
export const holdingNominationDetail = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []
  dataList &&
    dataList.current_incumbent[0].company_job_profile.people_work_placements.forEach(
      data => {
        if (data.global_user.talent_assignments.length !== 0) {
          data.global_user.talent_assignments.forEach(resx => {
            const dataIndicator = {
              npf: parseInt(resx.indicator_perf_nps).toFixed(2),
              ogf: parseInt(resx.indicator_perf_ogf).toFixed(2),
              goal: parseInt(resx.indicator_perf_goal).toFixed(2),
              multirater: parseInt(resx.indicator_perf_multirater).toFixed(2),
              attendance: parseInt(resx.indicator_pot_attendance).toFixed(2),
              competency: parseInt(resx.indicator_pot_competence).toFixed(2),
            }

            const nilai =
              (resx.talentVersionByTalentVersion.indicator_perf_nps
                ? (resx.indicator_perf_nps *
                  resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_ogf
                ? (resx.indicator_perf_ogf *
                  resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_goal
                ? (resx.indicator_perf_goal *
                  resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_multirater
                ? (resx.indicator_perf_multirater *
                  resx.talentVersionByTalentVersion
                    .indicator_perf_multirater_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_attendance
                ? (resx.indicator_pot_attendance *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_attendance_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_competence
                ? (resx.indicator_pot_competence *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_competence_fields.weight) /
                100
                : 0) /
              2

            x =
              (resx.talentVersionByTalentVersion.indicator_perf_nps
                ? (resx.indicator_perf_nps *
                  resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_ogf
                ? (resx.indicator_perf_ogf *
                  resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_goal
                ? (resx.indicator_perf_goal *
                  resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_multirater
                ? (resx.indicator_perf_multirater *
                  resx.talentVersionByTalentVersion
                    .indicator_perf_multirater_fields.weight) /
                100
                : 0)
            y =
              (resx.talentVersionByTalentVersion.indicator_pot_attendance
                ? (resx.indicator_pot_attendance *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_attendance_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_competence
                ? (resx.indicator_pot_competence *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_competence_fields.weight) /
                100
                : 0)

            // if (
            //   x >= resx.talentVersionByTalentVersion.min_perf &&
            //   y >= resx.talentVersionByTalentVersion.min_pot
            // ) {
            persentaseX =
              (resx.talentVersionByTalentVersion.max_perf -
                resx.talentVersionByTalentVersion.min_perf) /
              2
            persentaseY =
              (resx.talentVersionByTalentVersion.max_pot -
                resx.talentVersionByTalentVersion.min_pot) /
              2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            const modX = x % persentaseX
            const modY = y % persentaseY
            if (modX > 0) {
              akhirX = Math.floor(akhirX)
            } else {
              akhirX = Math.floor(akhirX - 1)
            }

            if (modY > 0) {
              akhirY = Math.floor(akhirY)
            } else {
              akhirY = Math.floor(akhirY - 1)
            }

            dataProfile = data
            start = resx.talentVersionByTalentVersion.start_date
            end = resx.talentVersionByTalentVersion.end_date

            resx.talentVersionByTalentVersion.talent_boxes.forEach(resd => {
              if (resx.badge_classification !== null) {
                if (resx.badge_classification === resd.id) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    // successors: talent_successors,
                    // talent_pool: talent_pools,
                    dataIndicator,
                  })
                }
              } else {
                if (akhirX === resd.index_x && akhirY === resd.index_y) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    // successors: talent_successors,
                    // talent_pool: talent_pools,
                    dataIndicator,
                  })
                }
              }
            })
            // }
          })
        } else {
          setBoxes.push({
            resd: null,
            nilai: null,
            start: null,
            end: null,
            dataProfile: data,
            // successors: talent_successors,
            // talent_pool: talent_pools,
            dataIndicator: null,
          })
        }
      }
    )
  return setBoxes
}

export const nominationSuccessor = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []
  dataList &&
    dataList.successor.forEach(data => {
      if (
        data.holding_source.people_work_placement.talent_assignments.length !==
        0
      ) {
        data.holding_source.people_work_placement.talent_assignments.forEach(
          resx => {
            const dataIndicator = {
              npf: parseInt(resx.indicator_perf_nps).toFixed(2),
              ogf: parseInt(resx.indicator_perf_ogf).toFixed(2),
              goal: parseInt(resx.indicator_perf_goal).toFixed(2),
              multirater: parseInt(resx.indicator_perf_multirater).toFixed(2),
              attendance: parseInt(resx.indicator_pot_attendance).toFixed(2),
              competency: parseInt(resx.indicator_pot_competence).toFixed(2),
            }
            const nilai =
              (resx.talentVersionByTalentVersion.indicator_perf_nps
                ? (resx.indicator_perf_nps *
                  resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_ogf
                ? (resx.indicator_perf_ogf *
                  resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_goal
                ? (resx.indicator_perf_goal *
                  resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_multirater
                ? (resx.indicator_perf_multirater *
                  resx.talentVersionByTalentVersion
                    .indicator_perf_multirater_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_attendance
                ? (resx.indicator_pot_attendance *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_attendance_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_competence
                ? (resx.indicator_pot_competence *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_competence_fields.weight) /
                100
                : 0) /
              2

            x =
              (resx.talentVersionByTalentVersion.indicator_perf_nps
                ? (resx.indicator_perf_nps *
                  resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_ogf
                ? (resx.indicator_perf_ogf *
                  resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_goal
                ? (resx.indicator_perf_goal *
                  resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                    .weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_perf_multirater
                ? (resx.indicator_perf_multirater *
                  resx.talentVersionByTalentVersion
                    .indicator_perf_multirater_fields.weight) /
                100
                : 0)
            y =
              (resx.talentVersionByTalentVersion.indicator_pot_attendance
                ? (resx.indicator_pot_attendance *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_attendance_fields.weight) /
                100
                : 0) +
              (resx.talentVersionByTalentVersion.indicator_pot_competence
                ? (resx.indicator_pot_competence *
                  resx.talentVersionByTalentVersion
                    .indicator_pot_competence_fields.weight) /
                100
                : 0)
            // console.log(x, y, resx.talentVersionByTalentVersion.min_perf)
            // if (
            //   x >= resx.talentVersionByTalentVersion.min_perf &&
            //   y >= resx.talentVersionByTalentVersion.min_pot
            // ) {
            persentaseX =
              (resx.talentVersionByTalentVersion.max_perf -
                resx.talentVersionByTalentVersion.min_perf) /
              2
            persentaseY =
              (resx.talentVersionByTalentVersion.max_pot -
                resx.talentVersionByTalentVersion.min_pot) /
              2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            const modX = x % persentaseX
            const modY = y % persentaseY
            if (modX > 0) {
              akhirX = Math.floor(akhirX)
            } else {
              akhirX = Math.floor(akhirX - 1)
            }

            if (modY > 0) {
              akhirY = Math.floor(akhirY)
            } else {
              akhirY = Math.floor(akhirY - 1)
            }

            dataProfile = data
            start = resx.talentVersionByTalentVersion.start_date
            end = resx.talentVersionByTalentVersion.end_date
            resx.talentVersionByTalentVersion.talent_boxes.forEach(resd => {
              if (resx.badge_classification !== null) {
                if (resx.badge_classification === resd.id) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                  })
                }
              } else {
                if (akhirX === resd.index_x && akhirY === resd.index_y) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                  })
                }
              }
            })
          }
          // }
        )
      } else {
        setBoxes.push({
          resd: null,
          nilai: null,
          start: null,
          end: null,
          dataProfile: data,
          dataIndicator: null,
        })
      }
    })
  // console.log(dataList)

  return setBoxes
}

export const integerOnly = vals => {
  // no negative and comma value
  const val = vals.replace(/[^0-9]*/g, '')
  return val
}

export const addOneDay = (date, format = 'yyyy-MM-DD') => {
  return `${moment(date)
    .add(1, 'day')
    .format(format)} 00:00:00`
}

export const getYearDiff = val => {
  const d = new Date()
  const day = d.getDate()
  const month = d.getMonth()
  const year = d.getFullYear()
  const diffYear = year - parseInt(val)

  return `${diffYear}-${month}-${day}`
}

export const fileChecker = name => {
  const nameSplited = name.split('.')
  const idx = nameSplited.length - 1
  const res = nameSplited[idx]

  if (res === 'doc' || res === 'docx') {
    return 'document'
  } else if (res === 'xls' || res === 'xlsx' || res === 'csv') {
    return 'excel'
  } else if (res === 'pdf') {
    return 'pdf'
  } else if (res === 'ppt' || res === 'pptx') {
    return 'powerpoint'
  } else if (
    res === 'jpg' ||
    res === 'jpeg' ||
    res === 'png' ||
    res === 'gif' ||
    res === 'tiff' ||
    res === 'pjp' ||
    res === 'pjpeg' ||
    res === 'jfif' ||
    res === 'tif'
  ) {
    return 'image'
  } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
    return 'image'
  } else {
    return false
  }
}

export const trimString = (data, length) => {
  return data && `${data.substring(0, length)}`
}

export const disbaledButtonExport = dateAdded => {
  const present_date = new Date()
  const download_date = new Date(dateAdded)

  present_date.setHours(0, 0, 0, 0)
  download_date.setHours(0, 0, 0, 0)

  const oneHours = 1000 * 60 * 60
  const timeCalculate = present_date.getTime() - download_date.getTime()
  const limitDisiabled = timeCalculate / oneHours
  return limitDisiabled < 24
}

export const isLessThanOneHour = (lastDownloadDate = null) => {
  const end_season_download = moment(lastDownloadDate).add(1, 'hours')
  return moment().isBefore(end_season_download)
}

// new probis
export const isDisableButtonExport = (dateAdded = null) => {
  const end_season_download = moment(dateAdded).endOf('day')
  return moment().isBefore(end_season_download)
}

export const isEmpty = val => {
  return val === undefined || val.length === 0
}

export function sum(input) {
  if (toString.call(input) !== '[object Array]') return false

  var total = 0
  for (var i = 0; i < input.length; i++) {
    if (isNaN(input[i])) {
      continue
    }
    total += Number(input[i])
  }
  return total
}

export const setterFilter = result => {
  const _formated = date => dateFormat(date, 'YYYY-MM-DD')
  const _subtracted = long => dateSubtract(long, 'years', 'YYYY-MM-DD')
  const generateIdFilter = data => {
    const _data = {}
    for (const property in data) {
      if (Array.isArray(data[property])) {
        _data[property] = data[property][0]
          ? data[property].map(itm => itm.value)
          : undefined
      } else if (property === 'date') {
        _data[property] = {
          ...(data[property].from && {
            from: _formated(data[property].from),
          }),
          ...(data[property].to && {
            to: _formated(data[property].to),
          }),
        }
      } else if (property === 'age') {
        _data[property] = {
          ...(data[property].min && {
            min: _subtracted(data[property].min),
          }),
          ...(data[property].max && {
            max: _subtracted(data[property].max + 1),
          }),
        }
      } else {
        _data[property] = data[property]
      }
    }
    return _data
  }
  const include = generateIdFilter(result[0])
  const exclude = generateIdFilter(result[1])

  return { include, exclude }
}

export const percentProgressMethod = percentProgres => {
  const scoringNature =
    percentProgres &&
    percentProgres.performance_goal &&
    percentProgres.performance_goal.kpi_list &&
    percentProgres.performance_goal.kpi_list.kpi_scoring_nature &&
    percentProgres.performance_goal.kpi_list.kpi_scoring_nature
      .global_scoring_nature &&
    percentProgres.performance_goal.kpi_list.kpi_scoring_nature
      .global_scoring_nature.name
  let kpiScore = 0
  if (scoringNature === 'Higher is Better') {
    kpiScore += (percentProgres.progress / percentProgres.target) * 100
    if (percentProgres.progress === percentProgres.target) {
      kpiScore = 100
    }
    if (!kpiScore) {
      kpiScore = 0
    }
  } else if (scoringNature === 'Lower is Better') {
    let low = 0
    let delta = 100
    if (percentProgres.target !== 0) {
      delta = Math.abs(percentProgres.target)
    }
    if (percentProgres.progress === percentProgres.target) {
      low += 100
    } else if (percentProgres.progress > percentProgres.target) {
      low +=
        100 - (percentProgres.progress - percentProgres.target) / (delta / 100)
    } else if (percentProgres.progress < percentProgres.target) {
      low +=
        100 + (percentProgres.target - percentProgres.progress) / (delta / 100)
    }
    kpiScore += low
    if (!kpiScore) {
      kpiScore = 0
    }
  } else if (scoringNature === 'Precise is Better') {
    let precise = 0
    let delta = 100
    if (percentProgres.target !== 0) {
      delta = Math.abs(percentProgres.target)
    }
    if (percentProgres.progress === percentProgres.target) {
      precise += 100
    } else if (percentProgres.progress > percentProgres.target) {
      precise +=
        100 - (percentProgres.progress - percentProgres.target) / (delta / 100)
    } else if (percentProgres.progress < percentProgres.target) {
      precise +=
        100 - (percentProgres.target - percentProgres.progress) / (delta / 100)
    }
    kpiScore += precise
    if (!kpiScore) {
      kpiScore = 0
    }
  } else if (scoringNature === 'Special Scoring') {
    let special = 0
    if (percentProgres.progress !== percentProgres.target) {
      special += 100
    }
    kpiScore += special
  } else {
    kpiScore += (percentProgres.progress / percentProgres.target) * 100
    if (percentProgres.progress === percentProgres.target) {
      kpiScore = 100
    }
    if (!kpiScore) {
      kpiScore = 0
    }
  }
  return kpiScore.toFixed(2)
}

export const convertArrayToHashMap = (
  array,
  identifier,
  additionalAttr = null
) => {
  const map = {}

  array.forEach(el => {
    if (!map[el[identifier]])
      map[el[identifier]] = {
        ...el,
        ...additionalAttr,
      }
  })

  return map
}

export const disabledSpecialChar = vals => {
  const val = vals.replace(/[^0-9a-zA-Z?'() "]*/g, '')
  return val
}

export function getYearStartEndDate(date = new Date()) {
  const year = date.getFullYear()

  const start = `${year}-01-01T00:00:00.000`
  const end = `${year}-12-31T23:59:59.999`

  return { start, end }
}

export const employeeColumnSort = (a, b) => {
  if (
    a.employee_placement?.global_user?.name ===
    b.employee_placement?.global_user?.name
  ) {
    return 0
  } else if (
    a.employee_placement?.global_user?.name >
    b.employee_placement?.global_user?.name
  ) {
    return 1
  } else {
    return -1
  }
}

export const downloadFile = (fileUrl, fileName, fileExtension) => {
  const nameaHasExtension = fileName?.indexOf('.') > 0
  const addExtension = !nameaHasExtension ? `.${fileExtension}` : ''
  fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${fileName}${addExtension}`
      link.click()
    })
}

// Number formatter
export const numberFormatter = new Intl.NumberFormat('id-ID')

export const getTypeFile = (href = '') => {
  return href
    .split('.')
    .slice(-1)
    .pop()
    .toLocaleLowerCase()
}

export const handleChangeAttachment = (
  valueAttachment,
  setDisableButton,
  setValues,
  axios,
  uploadUrl,
  token
) => {
  const formData = new FormData()
  formData.append('file', valueAttachment)
  const handleResponse = {
    id: valueAttachment.name + valueAttachment.size,
    name: valueAttachment.name,
    size: valueAttachment.size,
    url: '',
    loaded: 0,
    isUploading: true,
  }
  setDisableButton(true)
  setValues(prevState => {
    return {
      ...prevState,
      attachment: [...(prevState.attachment || []), handleResponse],
    }
  })
  return axios
    .post(uploadUrl, formData, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
      onUploadProgress: progressEvent => {
        handleResponse.loaded = progressEvent.loaded
        setDisableButton(true)
        setValues(prevState => {
          const items = prevState.attachment
          const foundIndex = items.findIndex(
            x => x.id === handleResponse.id && x.isUploading
          )
          items[foundIndex] = handleResponse
          return { ...prevState, attachment: items }
        })
      },
    })
    .then(response => {
      handleResponse.url = response.data.url
      handleResponse.loaded = valueAttachment.size
      handleResponse.isUploading = false
      setDisableButton(false)
      setValues(prevState => {
        const items = prevState.attachment
        const foundIndex = items.findIndex(
          x => x.id === handleResponse.id && x.isUploading
        )
        items[foundIndex] = handleResponse
        return { ...prevState, attachment: items }
      })
    })
    .catch(error => {
      console.error({ error })
    })
}

export function listenCookieChange() {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (!getCookie('userData')) {
        window.location.href = SSO_URL
      }
    }
  })
}

export const getTableNumber = (limit, offset, index) =>
  limit * offset + (index + 1)

export const currencyMaskingMethod = (value = 0) => {
  return numberFormatter.format(convertToAngka(Math.round(value))).toString()
}

export const isEmployeeValid = (status, end_date) => {
  return (
    status === 'ACTIVE' ||
    (status === 'REGISTERED' && new Date(end_date) > new Date())
  )
}

export const getNumber = value => {
  let isNumber = false
  let number = 0

  const numberOnly = /^\d+$/
  const _number = Number(value.replace(/[^0-9,-]+/g, ''))
  if (numberOnly.test(_number)) {
    isNumber = true
    number = _number
  }

  return { isNumber, number }
}

export const buildListToTree = (array, options = {}) => {
  const { idKey = 'id', parentKey = 'parent' } = options

  const map = new Map([[null, []]])

  for (let idx = 0, len = array.length; idx < len; idx++) {
    const children = []
    const node = { ...array[idx], children }
    const parent = map.get(node[parentKey])

    map.set(node[idKey], children)
    parent.push(node)
  }

  return map.get(null)
}

export const parameters_versions = {
  attendance: { EN: 'Attendance', ID: 'Kehadiran' },
  competency: { EN: 'Competency', ID: 'Kompetensi' },
  course: { EN: 'Course', ID: 'Pembelajaran' },
  socmed_post: { EN: 'Social Media Post', ID: 'Kiriman Sosial Media' },
  task: { EN: 'Task', ID: 'Rencana Aksi' },
  Task: { EN: 'Task', ID: 'Rencana Aksi' },
  ogf: { EN: 'On Going Feedback', ID: 'Umpan Balik Berkelanjutan' },
  'Ongoing Feedback': {
    EN: 'On Going Feedback',
    ID: 'Umpan Balik Berkelanjutan',
  },
  multirater: { EN: 'Multirater', ID: 'Multirater' },
  nps: { EN: 'NPS', ID: 'NPS' },
}

export const switchFlexyComponents = (data, axis, detail, xArr, yArr) => {
  let indicatorState
  let index
  switch (data.parameter) {
    case 'competency':
      indicatorState = 'indicator_pot_competence'
      index = 'Indeks'
      break
    case 'attendance':
      indicatorState = 'indicator_pot_attendance'
      index = 'Kehadiran'
      break
    case 'course':
      indicatorState = 'indicator_pot_total_course'
      index = 'Pembelajaran'
      break
    case 'socmed_post':
      indicatorState = 'indicator_pot_socmed_post'
      index = 'Kiriman'
      break
    case 'task':
      indicatorState = 'indicator_perf_goal'
      index = 'Indeks'
      break
    case 'ogf':
      indicatorState = 'indicator_perf_ogf'
      index = 'Total'
      break
    case 'multirater':
      indicatorState = 'indicator_perf_multirater'
      index = 'Total'
      break
    case 'nps':
      indicatorState = 'indicator_perf_nps'
      index = 'Total'
      break
  }

  const newAxisValue = {
    param: parameters_versions?.[data.parameter]?.ID,
    value: detail[indicatorState]?.toFixed(2),
    index,
  }

  if (axis === 'x') {
    xArr.push(newAxisValue)
  } else {
    yArr.push(newAxisValue)
  }
}
