import React from 'react'
import {Divider, Typography} from '@material-ui/core'
import Select from 'react-select'
import {PaperContainer, EnhancedToolbar} from '../../GlobalStyles'

const WidgetSection = props => {
  const selectStyles = {
    menu: provided => ({...provided, zIndex: 9999}),
    container: provided => ({
      ...provided,
      marginTop: 16,
      marginBottom: 8,
      minWidth: 300,
      maxWidth: 300,
    }),
  }

  return (
    <PaperContainer
      style={{
        overflow: 'unset',
        borderRadius: '5px',
        height: '100%',
        marginBottom: 0,
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.04)',
      }}
    >
      <EnhancedToolbar>
        <Typography variant="h6" style={{color: '#055469', fontSize: props.fontSizeTitle || 18}}>
          {props.title}
        </Typography>
        {props.action && <Select styles={selectStyles} {...props.action} />}
      </EnhancedToolbar>
      <Divider />
      <div>{props.children}</div>
    </PaperContainer>
  )
}

export default WidgetSection
