import gql from 'graphql-tag'

export const INSERT_KPI_LIST = gql`
  mutation createKpiList($data: [kpi_lists_insert_input!]!) {
    insert_kpi_lists(objects: $data) {
      returning {
        id
      }
    }
  }
`

export const DELETE_KPI_LIST = gql`
  mutation updateKPIList($id: Int) {
    update_kpi_lists(_set: {deleted_at: "now"}, where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`

export const DELETE_MULTIPLE_KPI_LIST = gql`
  mutation updateKPIList($id: [Int!]) {
    update_kpi_lists(_set: {deleted_at: "now()"}, where: {id: {_in: $id}}) {
      affected_rows
    }
  }
`

export const UPDATE_KPI_LIST = id => {
  return gql`
    mutation EditList($dataEdited: kpi_lists_set_input) {
      update_kpi_lists(where: {
        id: {
          _eq: ${id}
        }
      }, _set: $dataEdited) {
        returning {
          id
        }
      }
    }
  `
}
