import { IconButton } from "@material-ui/core";
import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
} from "../../../../../../GlobalStyles";
import { useHistory } from "react-router-dom";

export default function ToolbarDetailAsesi({ title }) {
  const { goBack } = useHistory();
  return (
    <FormToolbar disableGutters>
      <FormTitleContainer>
        <IconButton onClick={() => goBack()} aria-label="back">
          <ArrowBackIcon />
        </IconButton>
        <AddEditTitle variant="h6">{title}</AddEditTitle>
      </FormTitleContainer>
    </FormToolbar>
  );
}
