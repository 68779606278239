import gql from 'graphql-tag'

export const ADD_JOB_FAMILY_NAME = gql`
  mutation($objects: [job_families_insert_input!]!) {
    insert_job_families(objects: $objects) {
      returning {
        id
      }
    }
  }
`

export const EDIT_JOB_FAMILY_NAME = gql`
  mutation(
    $id: Int!
    $object: job_families_set_input!
    $company: uuid
    $functionFilter: Int_comparison_exp
    $jobTitleFilter: Int_comparison_exp
  ) {
    update_job_families_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
    }
    u1: update_company_employee_positions(
      _set: {family_id: null}
      where: {family_id: {_eq: $id}}
    ) {
      affected_rows
    }
    u2: update_company_employee_positions(
      _set: {family_id: $id}
      where: {
        company: {_eq: $company}
        job_mapping_id: $functionFilter
        id: $jobTitleFilter
      }
    ) {
      affected_rows
    }
  }
`

export const DELETE_JOB_FAMILY_NAME = gql`
  mutation($id: Int!, $companyId: uuid!) {
    update_job_families(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const ADD_FILTER_JFAMILY_JOB_TITLE = gql`
  mutation(
    $family: Int
    $company: uuid
    $functionFilter: Int_comparison_exp
    $jobTitleFilter: Int_comparison_exp
    $filter_job_title: jsonb
  ) {
    update_job_families(
      where: {id: {_eq: $family}}
      _set: {filter_job_title: $filter_job_title}
    ) {
      affected_rows
    }
    update_company_employee_positions(
      _set: {family_id: $family}
      where: {
        company: {_eq: $company}
        job_mapping_id: $functionFilter
        id: $jobTitleFilter
      }
    ) {
      affected_rows
    }
  }
`

export const ADD_JOB_FAMILY_FUNCTION = gql`
  mutation($objects: [job_family_functions_insert_input!]!) {
    insert_job_family_functions(objects: $objects) {
      affected_rows
    }
  }
`

export const EDIT_JOB_FAMILY_FUNCTION = gql`
  mutation($id: Int!, $object: job_family_functions_set_input!) {
    update_job_family_functions_by_pk(_set: $object, pk_columns: {id: $id}) {
      id
    }
  }
`

export const DELETE_JOB_FAMILY_FUNCTION = gql`
  mutation($id: Int!, $companyId: uuid!) {
    update_job_family_functions(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`

export const EDIT_MAPPING_TABLE = gql`
  mutation(
    $id: Int!
    $companyId: uuid!
    $name: Int!
    $function: Int!
    $category: Int
    $grade: Int
    $level: Int
  ) {
    update_job_families(
      _set: {
        name: $name
        function: $function
        category: $category
        grade: $grade
        level: $level
      }
      where: {_and: {id: {_eq: $id}, company: {_eq: $companyId}}}
    ) {
      affected_rows
    }
  }
`
