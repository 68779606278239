import gql from 'graphql-tag'

export const GET_EMPLOYEE_THR_DETAIL = gql`
  query GetEmployeeThrDetail($id: bigint!) {
    people_work_thr_by_pk(id: $id) {
      employee_placement: people_work_placement {
        id
        regno
        status
        end_date
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          company_organization {
            id
            name
          }
          company_employee_position {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
      }
      id
      thr
      income
      deduction
      allowances
      non_fixed_components
      payroll_fields
      date_sent
      date_printed
      process_step
      approve_step
    }
  }
`

export const GET_THR_COMPONENT_LIST = gql`
  query($company: uuid, $type: Int) {
    finance_nonfixed_components(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        part_of: {_eq: 1}
      }
    ) {
      id
      name
      type
    }
  }
`
