import gql from 'graphql-tag'

export const GET_TAX_LIST_REPORT = gql`
  query getTaxListReport(
    $company: uuid
    $year: Int
    $limit: Int
    $offset: Int
  ) {
    tax_list_count: v_finance_payroll_thr_aggregate(
      where: {
        status: {_eq: "paid"}
        company: {_eq: $company}
        year: {_eq: $year}
      }
    ) {
      aggregate {
        count
      }
    }

    v_finance_payroll_thr(
      limit: $limit
      offset: $offset
      where: {
        status: {_eq: "paid"}
        company: {_eq: $company}
        year: {_eq: $year}
      }
    ) {
      label
      year
      month
      total_final_salary_thr
      tax_by_company
      tax_by_employee
      total_gross
    }
  }
`

export const GET_TAX_DETAILS_REPORT = gql`
  query getTaxDetailsReport(
    $company: uuid
    $year: Int
    $month: Int
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $search: String
  ) {
    employee_count: people_work_payroll_aggregate(
      where: {
        process_step: {_eq: 5}
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_payroll(
      limit: $limit
      offset: $offset
      where: {
        process_step: {_eq: 5}
        employee_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      employee_placement {
        id
        regno
        status
        end_date
        global_user {
          id
          name
        }
        company_job_profile {
          id
          title
        }
      }
      non_fixed_components
      payroll_fields
      allowances
      salary
    }
  }
`

export const GET_TAX_DETAILS_REPORT_THR = gql`
  query getTaxDetailsReport(
    $company: uuid
    $year: Int
    $month: Int
    $offset: Int
    $limit: Int
    $search: String
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
  ) {
    employee_count: people_work_thr_aggregate(
      where: {
        process_step: {_eq: 5}
        people_work_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_thr(
      limit: $limit
      offset: $offset
      where: {
        process_step: {_eq: 5}
        people_work_placement: {
          company: {_eq: $company}
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
        year: {_eq: $year}
        month: {_eq: $month}
      }
    ) {
      people_work_placement {
        id
        regno
        status
        end_date
        global_user {
          id
          name
        }
        company_job_profile {
          id
          title
        }
      }
      non_fixed_components
      final_thr
    }
  }
`
