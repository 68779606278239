import gql from 'graphql-tag'

export const GET_LIST_KPIS = gql`
  query getListKpi($search: String, $offset: Int, $limit: Int) {
    kpi_lists(
      where: {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
      offset: $offset
      limit: $limit
      order_by: {id: desc}
    ) {
      id
      code
      name
      description
      formula
      kpi_version {
        id
        name
      }
      kpi_level {
        id
        name
        code
      }
      name
      kpi_method {
        id
        name
        code
      }
      kpi_scoring_nature {
        id
        name
        code
      }
      kpi_weight_unit {
        id
        name
        code
      }
      kpi_monitoring_frequency {
        id
        name
        code
      }
      kpi_perspective {
        id
        name
        code
      }
      kpi_aspect {
        id
        name
        code
      }
      status
      type
    }
  }
`

export const LIST_DROPDOWN_LEVEL = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    kpi_levels(
      where: {
        _and: [
          {company: {_eq: $companyId}, status: {_eq: "ACTIVE"}}
          {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
    }
  }
`

export const LIST_DROPDOWN_PERSPECTIVE = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    kpi_perspectives(
      where: {
        _and: [
          {company: {_eq: $companyId}, status: {_eq: "ACTIVE"}}
          {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
    }
  }
`

export const LIST_DROPDOWN_SCORING = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    kpi_scoring_natures(
      where: {
        _and: [
          {company: {_eq: $companyId}, status: {_eq: "ACTIVE"}}
          {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
    }
  }
`

export const LIST_DROPDOWN_WEIGHT = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    kpi_weight_units(
      where: {
        _and: [
          {company: {_eq: $companyId}, status: {_eq: "ACTIVE"}}
          {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
    }
  }
`

export const LIST_DROPDOWN_ASPECT = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    kpi_aspects(
      where: {
        _and: [
          {company: {_eq: $companyId}, status: {_eq: "ACTIVE"}}
          {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
    }
  }
`

export const LIST_DROPDOWN_METHOD = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    kpi_methods(
      where: {
        _and: [
          {company: {_eq: $companyId}, status: {_eq: "ACTIVE"}}
          {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
    }
  }
`

export const LIST_DROPDOWN_MONITORING = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    kpi_monitoring_frequencies(
      where: {
        _and: [
          {company: {_eq: $companyId}, status: {_eq: "ACTIVE"}}
          {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
    }
  }
`

export const DROPDOWN_ITEM_LIST = gql`
  {
    version: kpi_versions {
      id
      name
      description
      date_start
      date_end
      status
    }
    level: kpi_levels {
      id
      name
      description
      status
    }
    scoring: kpi_scoring_natures {
      id
      name
      description
      status
    }
    weight: kpi_weight_units {
      id
      name
      description
      status
    }
    aspect: kpi_aspects {
      id
      name
      description
      status
    }
    method: kpi_methods {
      id
      name
      description
      status
    }
    monitoring: kpi_monitoring_frequencies {
      id
      name
      description
      status
    }
    perspective: kpi_perspectives {
      id
      name
    }
  }
`

export const CHECK_CODE_LIBRARY = gql`
  query checkCodeLibrary($company: uuid, $code: String) {
    kpi_lists_aggregate(where: {company: {_eq: $company}, code: {_eq: $code}}) {
      aggregate {
        count
      }
    }
  }
`

export const CHECK_LIBRARY_ALREADY_TAKEN = gql`
  query($kpi: [Int!]!) {
    kpi_cascading_lists_aggregate(
      where: {
        kpi: {_in: $kpi}
        deleted_at: {_is_null: true}
        cascading_main: {deleted_at: {_is_null: true}}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
