import React from 'react'
import {makeStyles, Menu, Typography, Box} from '@material-ui/core'
import PropTypes from 'prop-types'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {
  AdornmentFilter,
  BlueIcon,
  FilterText,
  MenuDropdown,
  // FlexBetween,
  // CheckBoxLabel,
} from '../../../GlobalStyles'

const styleUi = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 250,
    padding: '0px !important',
  },
  root2: {
    zIndex: '0',
    width: '264px',
    borderTopColor: 'solid 1px #eff2f4',
    flexDirection: 'column',
    marginTop: '-59px',
    display: 'flex',
    padding: '0px !important',
  },
  root3: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    fontFamily: 'Muli',
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '190px',
    overflowY: 'auto',
  },
  reset: {
    cursor: 'pointer',
    width: '105px',
    height: '15px',
    lineHeight: '15px',
    textAlign: 'left',
    color: '#040404',
    padding: '12px',
  },
  tabPanel: {
    width: 350,
    background: '#f7f8f9',
  },
  tabPanelLarge: {
    width: 620,
    background: '#f7f8f9',
  },
  listContainer: {
    overflowY: 'auto',
    height: '130px',
    width: '400px',
  },
  listContainerLarge: {
    overflowY: 'auto',
    height: '130px',
    width: '600px',
  },
  cancel: {
    cursor: 'pointer',
    width: '38px',
    height: '15px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letteSpacing: 'normal',
    textAlign: 'right',
    color: '#a9a8a8',
    padding: '12px',
  },
  apply: {
    cursor: 'pointer',
    width: '33px',
    height: '15px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#039be5',
    padding: '12px',
  },
  typeButton: {
    borderRadius: '5px',
    border: '1px solid #a9a8a8',
    minHeight: '39px !important',
    paddingRight: '18px !important',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  },
  height40px: {
    height: '40px',
  },
  width100P: {
    width: '100%',
  },
  hideOverflowFilter: {
    '& > .MuiMenu-paper': {
      overflow: 'hidden',
    },
  },
}))

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// const FilterPopup = (filterData, setFilterData, button, setSearch) => {
const FilterPopup = props => {
  const styleDefault = styleUi()

  return (
    <PopupState variant="popover" popupId="popUp2">
      {popupState => (
        <>
          <AdornmentFilter
            className={props && props.button && styleDefault.typeButton}
            position="end"
            {...bindTrigger(popupState)}
          >
            <BlueIcon>filter_list</BlueIcon>
            <FilterText variant="body2">Filter</FilterText>
          </AdornmentFilter>

          <Menu
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            style={{overflowY: 'hidden'}}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            className={styleDefault.hideOverflowFilter}
          >
            <MenuDropdown>
              <div className={styleDefault.container}>
                {props && props.children && (
                  <div className={styleDefault.root}>
                    {props && props.children}
                  </div>
                )}
                <div className={styleDefault.root2}>
                  <div
                    style={{
                      width: '262px',
                      height: '0',
                      border: 'solid 1px #eff2f4',
                    }}
                  ></div>
                  <div className={styleDefault.root3}>
                    <div
                      className={styleDefault.reset}
                      // onClick={
                      //   props &&
                      //   props.methodFromChild.current &&
                      //   props.methodFromChild.current.handleResetAll()
                      // }
                    >
                      Reset All
                    </div>
                    <div
                      className={styleDefault.cancel}
                      onClick={() => popupState.close()}
                    >
                      Cancel
                    </div>
                    <div
                      className={styleDefault.apply}
                      onClick={() =>
                        props &&
                        props.methodFromChild.current &&
                        props.methodFromChild.current.handleApply(popupState)
                      }
                    >
                      Apply
                    </div>
                  </div>
                </div>
              </div>
            </MenuDropdown>
          </Menu>
        </>
      )}
    </PopupState>
  )
}

export default FilterPopup
