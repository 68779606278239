import gql from 'graphql-tag'

export const GET_ASSESSMENT_LIST = gql`
  query GetAssessmentList($userId: uuid!) {
    psychological_tests: people_profile_psychological_tests(
      where: {_and: [{user_id: {_eq: $userId}}, {deletedAt: {_is_null: true}}]}
      order_by: [{id: desc}]
    ) {
      id
      name
      subject
      institution
      test_date
      test_cost
      test_score
      test_score_range
      test_result
      recommendation_result
      general_conclusion
      purpose
      attachments
    }
  }
`
export const GET_LIST_MEDICAL_HISTORY = gql`
  query GetMedicalHistoryList($userId: uuid!) {
    people_profile_medical_histories(
      where: {_and: [{user_id: {_eq: $userId}}, {deletedAt: {_is_null: true}}]}
      order_by: [{id: desc}]
    ) {
      id
      name
      age
      start_date
      end_date
      hospital_name
      doctor_name
      disease
      consequence
      laboratory_number
      laboratory_notes
      medical_score
      medical_fee
      medical_category
      attachments
    }
  }
`
export const GET_MEDICAL_RECORDS = gql`
  query($userId: uuid!) {
    people_profile_medical_records(
      where: {_and: [{user_id: {_eq: $userId}}, {deletedAt: {_is_null: true}}]}
    ) {
      id
      medical_test_name
      medical_test_reason
      additional_medical_conditions
      disabilities
      hospitalized_reason
      blood_type
      blood_rhesus
      body_height
      body_weight
      head_size
      clothes_size
      pants_size
    }
  }
`
export const GET_INSURANCE_DATA_LIST = gql`
  query($user: uuid!) {
    insurances: people_identities(
      where: {
        user: {_eq: $user}
        deletedAt: {_is_null: true}
        global_insurance_list: {id: {_is_null: false}}
      }
    ) {
      number: id
      date_added
      type
      global_insurance_list {
        id
        enum
        type
        name
      }
      identity_fields
    }
  }
`

export const GET_USER_BANK_ACCOUNT = gql`
  query getBankAccounts($userID: uuid!) {
    main_bank: people_work_salaries(
      where: {
        people_work_placement: {user: {_eq: $userID}, status: {_eq: "ACTIVE"}}
        active: {_eq: true}
        deletedAt: {_is_null: true}
        people_profile_bank: {deletedAt: {_is_null: true}}
      }
      order_by: [{id: desc}]
      limit: 1
    ) {
      id
      currency
      salary_fields
      value
      date_added
      people_profile_bank {
        id
        bank
        bank_name
        account_name
        account_number
        creator_company
      }
    }
    all_bank: people_profile_banks(
      where: {user: {_eq: $userID}, deletedAt: {_is_null: true}}
      order_by: [{creator_company: asc}, {id: desc}]
    ) {
      id
      bank
      bank_name
      account_name
      account_number
      creator_company
    }
  }
`

export const GET_LIST_PHONE_NUMBER = gql`
  query($userId: uuid!) {
    phone_numbers: people_phones(
      where: {_and: [{user: {_eq: $userId}}, {deletedAt: {_is_null: true}}]}
      order_by: [{is_main: desc_nulls_last, id: asc}]
    ) {
      id
      country_code
      phone
      global_phone_code {
        id
        country
      }
      is_main
      is_visible
    }
  }
`

export const GET_LIST_EMAIL_ADDRESS = gql`
  query($userId: uuid!) {
    owner: global_users_by_pk(id: $userId) {
      id
      email
    }
    people_emails(
      where: {_and: [{user: {_eq: $userId}}, {deletedAt: {_is_null: true}}]}
    ) {
      email
      is_visible
      is_verified
      date_exp_verification
    }
  }
`
export const GET_LIST_ADDRESS = gql`
  query($userId: uuid!) {
    people_addresses(
      where: {_and: [{user: {_eq: $userId}}, {deletedAt: {_is_null: true}}]}
      order_by: [{is_main: desc_nulls_last, id: asc}]
    ) {
      id
      address_type: name
      province: global_province {
        id
        name
      }
      city: global_city {
        id
        name
      }
      postal_code
      address
      is_main
      address_fields
    }
  }
`
export const GET_LIST_SOCIALS = gql`
  query($userId: uuid!) {
    instagram: people_profile_social_contacts(
      where: {
        _and: [
          {user_id: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
          {global_social_medium: {name: {_eq: "Instagram"}}}
        ]
      }
      order_by: {is_main: desc}
    ) {
      id
      global_social_medium {
        id
        name
      }
      name
      url
      is_main
    }

    linkedin: people_profile_social_contacts(
      where: {
        _and: [
          {user_id: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
          {global_social_medium: {name: {_eq: "LinkedIn"}}}
        ]
      }
      order_by: {is_main: desc}
    ) {
      id
      global_social_medium {
        id
        name
      }
      name
      url
      is_main
    }

    tiktok: people_profile_social_contacts(
      where: {
        _and: [
          {user_id: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
          {global_social_medium: {name: {_eq: "TikTok"}}}
        ]
      }
      order_by: {is_main: desc}
    ) {
      id
      global_social_medium {
        id
        name
      }
      name
      url
      is_main
    }

    twitter: people_profile_social_contacts(
      where: {
        _and: [
          {user_id: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
          {global_social_medium: {name: {_eq: "Twitter"}}}
        ]
      }
      order_by: {is_main: desc}
    ) {
      id
      global_social_medium {
        id
        name
      }
      name
      url
      is_main
    }

    facebook: people_profile_social_contacts(
      where: {
        _and: [
          {user_id: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
          {global_social_medium: {name: {_eq: "Facebook"}}}
        ]
      }
      order_by: {is_main: desc}
    ) {
      id
      global_social_medium {
        id
        name
      }
      name
      url
      is_main
    }
  }
`
export const GET_EDUCATION_INTRO = gql`
  query($userId: uuid!, $educationId: Int!) {
    people_profile(where: {id: {_eq: $userId}}) {
      global_user {
        id
        school: people_profile_educations(where: {id: {_eq: $educationId}}) {
          id
          company_profile {
            id
            legal_name
            brand_name
          }
        }
      }
    }
  }
`

export const GET_MEDICAL_CATEGORY_LIST = gql`
  query GetMedicalCategoryList($search: String, $limit: Int, $offset: Int) {
    medical_categories: people_profile_medical_categories(
      where: {name: {_ilike: $search}}
      limit: $limit
      offset: $offset
      order_by: [{id: asc}]
    ) {
      id
      name
    }
  }
`
