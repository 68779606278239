import React from 'react'

import {Chip, makeStyles, TextField, Typography} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(() => ({
  inputStyle: {
    padding: '15px 18px',
  },
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
    marginBottom: 17,
  },
  root: {marginBottom: 32},
  helperText: {marginLeft: 14},
  chipsWrapper: {marginTop: 16, gap: 12, display: 'flex', flexWrap: 'wrap'},
  chips: {padding: 8},
  tag: {display: 'none'},
}))

export const SelectChips = ({
  label,
  options,
  onChange,
  onInputChange,
  placeholder,
  helperText,
  error,
  value,
  isLoading,
  onDeleteChips,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <p className={classes.label}>{label}</p>
      <Autocomplete
        id="autocomplete"
        multiple
        filterSelectedOptions
        options={options || []}
        loading={isLoading}
        disableClearable
        onChange={(event, newValue) => {
          event.preventDefault()
          onChange(newValue)
        }}
        classes={{tag: classes.tag}}
        onInputChange={(event, newInputValue) => {
          event.preventDefault()
          onInputChange(newInputValue)
        }}
        autoHighlight
        getOptionLabel={option => option.label}
        renderOption={option => option.label}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
      {value?.[0] && (
        <div className={classes.chipsWrapper}>
          {value?.map(({value, label}, i) => {
            return (
              <Chip
                key={i}
                className={classes.chips}
                label={label}
                onDelete={() => onDeleteChips({value, label, i})}
                color="primary"
              />
            )
          })}
        </div>
      )}
      {error && (
        <Typography
          className={classes.helperText}
          variant="caption"
          color="error"
        >
          {helperText}
        </Typography>
      )}
    </div>
  )
}
