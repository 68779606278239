import {Divider, Typography, CircularProgress} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState} from 'react'
import {
  PaperContainer,
  EnhancedToolbar,
  Flex,
  StyledMenuItem,
} from '../../../../GlobalStyles'
import {withRouter, Link} from 'react-router-dom'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import {useQuery} from '@apollo/react-hooks'
import {LIST_MODEL} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import {competencyOptions} from '../CompetencyPageStyles'

const ListModelComponent = ({search}) => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const {
    data: listModel,
    error: errorListModel,
    loading: loadingListModel,
  } = useQuery(LIST_MODEL, {
    variables: {
      company: COMPANY_ID,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      search: `%${search}%`,
    },
  })

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  if (errorListModel) {
    return <div>{JSON.stringify(errorListModel)}</div>
  } else if (loadingListModel) {
    return <CircularProgress />
  }
  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6" color="primary" style={{paddingLeft: 10}}>
          Model
        </Typography>
      </EnhancedToolbar>
      <Divider></Divider>

      <MaterialTable
        style={{
          padding: '1.6rem 1.6rem 0',
        }}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
        columns={[
          {
            title: 'Kode',
            field: 'code',
            render: row => <Typography>{row.code}</Typography>,
          },
          {
            title: 'Posisi',
            field: 'title',
            render: row => (
              <Flex
                style={{
                  width: 250,
                }}
              >
                {row.title}
              </Flex>
            ),
          },
          {
            title: 'Unit Organisasi',
            field: 'company_organization.name',
          },
          {
            title: 'Job Level',
            field: 'company_employee_rank.name',
          },
          {
            title: 'Total Kompetensi',
            field: 'company_job_profile_competencies_aggregate.aggregate.count',
          },
          {
            title: 'Aksi',
            field: 'status',
            render: row => (
              <StyledMenuItem
                fontColor="#049BE5"
                component={Link}
                to={{
                  pathname: `/learning/competency/model/${row.id}`,
                  state: {active: 'competence-model', position: row.title},
                }}
              >
                Rincian
              </StyledMenuItem>
            ),
          },
        ]}
        data={listModel.company_job_profiles}
        title=""
        options={{
          ...TableOptions,
          ...competencyOptions,
        }}
        components={{
          Toolbar: () => null,
        }}
        isLoading={false}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={listModel.total.aggregate.count}
        page={pagination.offset}
        pageSize={pagination.limit}
      />
    </PaperContainer>
  )
}

export default withRouter(ListModelComponent)
