import React from 'react'
import {
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleName,
  TitleNumber,
  FormChildInput,
  GreyTypography,
} from '../../../../../../GlobalStyles'
import {
  TextField,
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import LoadingSurvey from '../../LoadingSurvey'

const useStyles = makeStyles(() => ({
  w50: {
    width: '50%',
  },
  wFull: {
    width: '100% !important',
  },

  w45: {
    width: '45% !important',
  },
  separateTo: {width: '10%', textAlign: 'center'},
  m0: {margin: 0},
  p0: {padding: 0},
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  mr: {
    marginRight: 30,
  },
  mt: {
    marginTop: 10,
  },
  checkBox: {
    marginLeft: 30,
    fontSize: '12px !important',
    fontWeight: '400',
  },
  radio: {
    marginBottom: '-10px',
    marginRight: 45,
  },
  icon: {
    borderRadius: 3,
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
  },
  formWrapper: {
    paddingBttom: '1.2rem',
  },
  status: {
    width: '240px !important',
  },
}))

const StepOne = props => {
  const classes = useStyles()

  const {
    dataContext,
    setDataContext,
    isError,
    isErrorDate,
    setChecked,
    checked,
    groupLength,
  } = props

  const handleChange = name => e => {
    setDataContext({
      ...dataContext,
      dataSurveyCenter: {
        ...dataContext.dataSurveyCenter,
        stepOne: {
          ...dataContext.dataSurveyCenter.stepOne,
          [name]: e.target.value,
        },
      },
    })
  }

  const handleChangeDate = name => e => {
    setDataContext({
      ...dataContext,
      dataSurveyCenter: {
        ...dataContext.dataSurveyCenter,
        stepOne: {
          ...dataContext.dataSurveyCenter.stepOne,
          [name]: moment(e).format('YYYY-MM-DD'),
        },
      },
    })
  }

  const handleCheckBox = event => {
    setChecked({...checked, [event.target.name]: event.target.checked})
  }

  if (dataContext === null || dataContext.dataSurveyCenter === null) {
    return <LoadingSurvey />
  }

  return (
    <FormContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Nama Umpan Balik *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            onChange={handleChange('survey_name')}
            style={{width: '120%'}}
            size="small"
            margin="normal"
            variant="outlined"
            value={dataContext.dataSurveyCenter.stepOne.survey_name}
            error={
              dataContext.dataSurveyCenter.stepOne.survey_name.length < 1 &&
              isError
            }
            helperText={
              dataContext.dataSurveyCenter.stepOne.survey_name.length < 1 &&
              isError
                ? 'Bagian ini diperlukan'
                : ''
            }
          />
        </FormChildInput>
      </FormChildContainer>
      <div className={classes.formWrapper}>
        <FormChildTitle id="survey">
          <TitleNumber>2.</TitleNumber>
          <TitleName>Tipe Umpan Balik *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <div className={classes.rowWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.multirater}
                  onChange={handleCheckBox}
                  name="multirater"
                  color="primary"
                  disabled={groupLength > 0}
                />
              }
              label="Multirater"
              labelPlacement="end"
              className={classes.radio}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.nps}
                  onChange={handleCheckBox}
                  name="nps"
                  color="primary"
                  disabled={groupLength > 0}
                />
              }
              label="NPS"
              labelPlacement="end"
              className={classes.radio}
            />
          </div>
          {!checked.nps && !checked.multirater && isError && (
            <Typography
              variant="caption"
              color="error"
              component="p"
              style={{marginBottom: 10}}
            >
              Harap pilih setidaknya satu tipe
            </Typography>
          )}
          {groupLength > 0 && (
            <GreyTypography
              variant="caption"
              component="p"
              style={{marginBottom: 10}}
            >
              Hapus pertanyaan dan grup pertanyaan apabila anda ingin mengubah
              tipe survey
            </GreyTypography>
          )}
        </FormChildInput>
      </div>

      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>Deskripsi *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            onChange={handleChange('description')}
            value={dataContext.dataSurveyCenter.stepOne.description}
            error={
              dataContext.dataSurveyCenter.stepOne.description.length < 1 &&
              isError
            }
            helperText={
              dataContext.dataSurveyCenter.stepOne.description.length < 1 &&
              isError
                ? 'Bagian ini diperlukan'
                : ''
            }
            fullWidth
            size="small"
            margin="normal"
            variant="outlined"
            multiline
            rows="6"
            style={{width: '120%'}}
          />
        </FormChildInput>
      </FormChildContainer>
      <div className={classes.formWrapper}>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>Tanggal *</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <div className={classes.row}>
            <div className={classes.mr}>
              <Typography className={classes.mt}>Tanggal Mulai</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={dataContext.dataSurveyCenter.stepOne.start_date}
                  onChange={handleChangeDate('start_date')}
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  size="small"
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <Typography className={classes.mt} id="date">
                Tanggal Akhir
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={dataContext.dataSurveyCenter.stepOne.end_date}
                  onChange={handleChangeDate('end_date')}
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  size="small"
                  error={isErrorDate}
                  helperText={
                    isErrorDate
                      ? 'Tanggal Akhir tidak boleh sebelum Tanggal Mulai'
                      : null
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </FormChildInput>
      </div>
    </FormContainer>
  )
}

export default StepOne
