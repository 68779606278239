import gql from 'graphql-tag'

export const SOFT_DELETE_WORK_SCHEDULE = gql`
  mutation DeleteWorkSchedules($deleteAt: timestamp, $id: Int!) {
    update_time_attendance_policies(
      _set: {deletedAt: $deleteAt}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
