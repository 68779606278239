import React from 'react'
import {useQuery, useApolloClient} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import LoadingEmployee from '../../../../LoadingEmployee'
import {GridListing} from '../../../DetailEmployeeStyles'
import {ButtonAddDataTab} from '../data-tab-shared'
import DeletePopup from '../../../../../../shared-components/popup/DeletePopup'
import DependentItem from './DependentItem'
import DetailDependent from './DetailDependent'
import AddEditDependent from './AddEditDependent'

import {GET_DEPENDANTS} from '../../../../../../../graphql/queries'
import {DELETE_PEOPLE_DEPENDENT} from '../../../../../../../graphql/mutations'

const INITIAL_DETAIL_STATE = {
  openForm: false,
  openDetails: false,
  openDelete: false,
  data: null,
}

const Dependent = props => {
  const {userId, userName} = props

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const [detailState, setDetailState] = React.useState(INITIAL_DETAIL_STATE)

  const {data, error, refetch} = useQuery(GET_DEPENDANTS, {
    wlb_skipPatch: true,
    cachePolicy: 'cache-and-network',
    variables: {
      userId: userId,
    },
  })

  if (error) {
    return <div>{'' + error}</div>
  }

  if (!data) {
    return <LoadingEmployee noborder smallpadding />
  }

  const handleDependentDetails = data => {
    setDetailState({...detailState, openDetails: true, data: data})
  }

  const handleDependentEdit = data => {
    setDetailState({...detailState, openForm: true, data: data})
  }

  const handleDependentAdd = () => {
    setDetailState({...detailState, openForm: true, data: null})
  }

  const handleDetailsClose = () => {
    setDetailState({...detailState, openDetails: false})
  }

  const handleFormClose = () => {
    setDetailState({...detailState, openForm: false})
  }

  const handleFormSubmit = shouldRefetch => {
    handleFormClose()

    if (shouldRefetch) {
      refetch()
    }
  }

  const handleDeleteOpen = () => {
    setDetailState({...detailState, openDelete: true})
  }

  const handleDeleteClose = action => {
    setDetailState({
      ...detailState,
      openForm: action === 'action-cancel',
      openDelete: false,
    })
  }

  const handleDeleteConfirm = () => {
    const promise = client.mutate({
      mutation: DELETE_PEOPLE_DEPENDENT,
      variables: {
        id: detailState.data.id,
      },
    })

    promise.then(
      () => {
        refetch()
        enqueueSnackbar(`Tanggungan dihapus`, {variant: 'success'})
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Failed to delete dependent`, {variant: 'error'})
      }
    )
  }

  return (
    <>
      <GridListing>
        {data.people_dependants.map(item => (
          <DependentItem
            key={item.id}
            data={item}
            userName={userName}
            onDetails={() => handleDependentDetails(item)}
            onEdit={() => handleDependentEdit(item)}
          />
        ))}
      </GridListing>

      <ButtonAddDataTab onClick={handleDependentAdd}>
        Tambah Informasi Tanggungan
      </ButtonAddDataTab>

      <DetailDependent
        open={detailState.openDetails}
        data={detailState.data}
        onClose={handleDetailsClose}
      />

      <AddEditDependent
        open={detailState.openForm}
        userId={userId}
        initialData={detailState.data}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        onDelete={handleDeleteOpen}
      />

      <DeletePopup
        name={detailState.data?.name}
        open={detailState.openDelete}
        mutation={handleDeleteConfirm}
        handleClose={handleDeleteClose}
      />
    </>
  )
}

export default Dependent
