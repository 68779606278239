import {useQuery} from '@apollo/react-hooks'
import React, {useState} from 'react'
import {GET_JOB_PROFILE_OT} from '../../../../../graphql/queries'
import ModalDetail from '../../../../shared-components/popup/ModalDetail'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  TablePagination,
  TextField,
  useTheme,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {AppliesToChild, AppliesToContainer} from '../../../../../GlobalStyles'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

function TablePaginationActions(props) {
  const theme = useTheme()
  const {count, page, rowsPerPage, onChangePage} = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      {/* <p>{(rowsPerPage * page) + 1}-{rowsPerPage} of {count} </p> */}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

const LoadingData = () => {
  return (
    <div
      style={{
        padding: '12rem 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

const DEFAULT_SEARCH = ''
const DEFAULT_PAGINATION = {
  page: 0,
  rowsPerPage: 28,
}

export default function AllAppliedTo({dataSeeMore, onClose}) {
  const {id, open, title} = dataSeeMore
  const [search, setSearch] = useState(DEFAULT_SEARCH)
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)

  const {data, loading, error} = useQuery(GET_JOB_PROFILE_OT, {
    wlb_skipPatch: true,
    skip: !id,
    variables: {
      id,
      search: `%${search}%`,
      limit: pagination.rowsPerPage,
      offset: pagination.page,
    },
  })

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPagination(e => ({...e, page: newPage}))
  }

  const handleChangeRowsPerPage = event => {
    setPagination(e => ({
      ...e,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    }))
  }

  const handleClose = () => {
    onClose()
    setPagination(DEFAULT_PAGINATION)
    setSearch(DEFAULT_SEARCH)
  }
  const TOTAL_APPLIED =
    (data &&
      data.time_overtime_policies[0] &&
      data.time_overtime_policies[0].total.aggregate.count) ||
    0

  return (
    <ModalDetail onClose={handleClose} open={open} title={title}>
      <DialogContent>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search"
          size="small"
          value={search}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div
          style={{
            paddingTop: 30,
            minHeight: 500,
          }}
        >
          <label style={{fontWeight: '500', fontSize: 12}}>
            {`Applied to (${TOTAL_APPLIED} Position)`}:
          </label>
          {loading && <LoadingData />}
          {error && JSON.stringify(error, 0, 2)}
          {!loading && data && (
            <AppliesToContainer>
              {data &&
              data.time_overtime_policies[0] &&
              data.time_overtime_policies[0]
                .company_job_profile_r_overtimes[0] ? (
                data.time_overtime_policies[0].company_job_profile_r_overtimes.map(
                  (applied, index) => {
                    if (applied.company_job_profile) {
                      return (
                        <AppliesToChild key={index}>
                          {applied.company_job_profile &&
                            applied.company_job_profile.title}
                        </AppliesToChild>
                      )
                    }
                  }
                )
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{fontSize: 12, padding: '11rem 0px'}}>
                    No Record to Display
                  </div>
                </div>
              )}
            </AppliesToContainer>
          )}
        </div>
        <Divider />
        <TablePagination
          style={{
            marginLeft: 'auto',
            display: 'block',
            border: 'none',
          }}
          rowsPerPageOptions={[28, 28 * 2, 28 * 3]}
          count={TOTAL_APPLIED}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </DialogContent>
    </ModalDetail>
  )
}
