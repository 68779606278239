import gql from 'graphql-tag'

export const GET_OVERTIME_REPORT = gql`
  query getOvertimeReport(
    $start: timestamp
    $end: timestamp
    $name: String
    $order: [people_work_overtimes_order_by!]
    $limit: Int
    $offset: Int
    $jobProfile: [Int!]
    $organization: [Int!]
    $job_title: [Int!]
    $level: [Int!]
    $office: [Int!]
    $job_grades: [Int!]
    $organization_level: [Int!]
    $job_family: [Int!]
    $employeeType: [String!]
    $timeArrangement: [String!]
    $workArrangement: [String!]
    $gender: [enum_gender!]
    $birthFrom: date
    $birthTo: date
    $isFilterBirth: Boolean
    $EXC_jobProfile: [Int!]
    $EXC_organization: [Int!]
    $EXC_job_title: [Int!]
    $EXC_level: [Int!]
    $EXC_office: [Int!]
    $EXC_job_grades: [Int!]
    $EXC_organization_level: [Int!]
    $EXC_job_family: [Int!]
    $EXC_employeeType: [String!]
    $EXC_timeArrangement: [String!]
    $EXC_workArrangement: [String!]
    $EXC_gender: [enum_gender!]
    $EXC_birthFrom: date
    $EXC_birthTo: date
    $isFilterExcBirth: Boolean
  ) {
    people_work_overtimes(
      where: {
        datestart: {_gte: $start, _lte: $end}
        status: {_eq: "approved"}
        people_work_placement: {
          company_job_profile: {
            id: {_in: $jobProfile, _nin: $EXC_jobProfile}
            organization: {_in: $organization, _nin: $EXC_organization}
            position: {_in: $job_title, _nin: $EXC_job_title}
            rank: {_in: $level, _nin: $EXC_level}
            office_address: {_in: $office, _nin: $EXC_office}
            job_grade_id: {_in: $job_grades, _nin: $EXC_job_grades}
            company_organization: {
              organization_level_id: {
                _in: $organization_level
                _nin: $EXC_organization_level
              }
            }
            company_employee_position: {
              job_mapping_id: {_in: $job_family, _nin: $EXC_job_family}
            }
          }
          type: {_in: $employeeType, _nin: $EXC_employeeType}
          type_time: {_in: $timeArrangement, _nin: $EXC_timeArrangement}
          type_place: {_in: $workArrangement, _nin: $EXC_workArrangement}
          global_user: {
            name: {_ilike: $name}
            people_profiles: {
              gender: {_in: $gender, _nin: $EXC_gender}
              _and: [
                {
                  _or: [
                    {
                      _and: [
                        {birthdate: {_gte: $birthFrom, _lte: $birthTo}}
                        {_not: {id: {_is_null: $isFilterBirth}}}
                      ]
                    }
                    {id: {_is_null: $isFilterBirth}}
                  ]
                }
                {
                  _or: [
                    {
                      _and: [
                        {
                          _not: {
                            birthdate: {
                              _gte: $EXC_birthFrom
                              _lte: $EXC_birthTo
                            }
                          }
                        }
                        {_not: {id: {_is_null: $isFilterExcBirth}}}
                      ]
                    }
                    {id: {_is_null: $isFilterExcBirth}}
                  ]
                }
              ]
            }
          }
        }
      }
      order_by: $order
      limit: $limit
      offset: $offset
    ) {
      id
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(limit: 1) {
              global_user {
                id
                name
                avatar
              }
            }
          }
        }
      }
      description
      datestart
      dateend
      convert_to
      status
      date_added
      last_modified
      added_by
      modified_by
      #cancelled_by
      overtime_fields
      time_overtime_policy {
        id
        name
        convert_to_leave
        convert_to_money
        first_job_profile {
          id
          title
          people_work_placements {
            id
            global_user {
              id
              name
              avatar
            }
          }
        }
        second_job_profile {
          id
          title
          people_work_placements {
            id
            global_user {
              id
              name
              avatar
            }
          }
        }
        TimeLeavePolicyChildren {
          quota
        }
      }
      globalUserByModifiedBy {
        id
        name
        avatar
      }
    }
    people_work_overtimes_aggregate(
      where: {
        datestart: {_gte: $start, _lte: $end}
        status: {_eq: "approved"}
        people_work_placement: {
          company_job_profile: {
            id: {_in: $jobProfile, _nin: $EXC_jobProfile}
            organization: {_in: $organization, _nin: $EXC_organization}
            position: {_in: $job_title, _nin: $EXC_job_title}
            rank: {_in: $level, _nin: $EXC_level}
            office_address: {_in: $office, _nin: $EXC_office}
            job_grade_id: {_in: $job_grades, _nin: $EXC_job_grades}
            company_organization: {
              organization_level_id: {
                _in: $organization_level
                _nin: $EXC_organization_level
              }
            }
            company_employee_position: {
              job_mapping_id: {_in: $job_family, _nin: $EXC_job_family}
            }
          }
          type: {_in: $employeeType, _nin: $EXC_employeeType}
          type_time: {_in: $timeArrangement, _nin: $EXC_timeArrangement}
          type_place: {_in: $workArrangement, _nin: $EXC_workArrangement}
          global_user: {
            name: {_ilike: $name}
            people_profiles: {
              gender: {_in: $gender, _nin: $EXC_gender}
              _and: [
                {
                  _or: [
                    {
                      _and: [
                        {birthdate: {_gte: $birthFrom, _lte: $birthTo}}
                        {_not: {id: {_is_null: $isFilterBirth}}}
                      ]
                    }
                    {id: {_is_null: $isFilterBirth}}
                  ]
                }
                {
                  _or: [
                    {
                      _and: [
                        {
                          _not: {
                            birthdate: {
                              _gte: $EXC_birthFrom
                              _lte: $EXC_birthTo
                            }
                          }
                        }
                        {_not: {id: {_is_null: $isFilterExcBirth}}}
                      ]
                    }
                    {id: {_is_null: $isFilterExcBirth}}
                  ]
                }
              ]
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
      nodes {
        datestart
        dateend
      }
    }
  }
`
export const GET_OVER_REPORT_DOWNLOAD = gql`
  query getOvertimeReport($start: timestamp, $end: timestamp) {
    people_work_overtimes(
      where: {datestart: {_gte: $start, _lte: $end}, status: {_eq: "approved"}}
      order_by: {global_user: {name: asc}}
    ) {
      id
      people_work_placement {
        id
        regno
        global_user {
          id
          name
        }
      }
      datestart
      dateend
      convert_to
      time_overtime_policy {
        id
        name
        convert_to_leave
        convert_to_money
        TimeLeavePolicyChildren {
          id
          name
        }
      }
      description
    }
  }
`
