import React from 'react'

import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core'

import {
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  FormChildInput,
  TitleNumber,
  TitleName,
  GreyTypography,
} from '../../../../../GlobalStyles'

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const MicroLearningScheduleComponent = props => {
  const {scheduleValue, setScheduleValue, isError} = props

  const onScheduleChange = (idx, event) => {
    const schedule = scheduleValue.schedule.split('')
    const value = event.target.checked ? '1' : '0'
    schedule[idx] = value

    setScheduleValue({...scheduleValue, schedule: schedule.join('')})
  }

  const onChange = prop => event => {
    if (prop === 'dailyQuestion' && event.target.value < 0) {
      return
    }
    setScheduleValue({
      ...scheduleValue,
      [prop]: event.target.value,
    })
  }

  return (
    <FormContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Answer Question Every:</TitleName>
          {isError && !scheduleValue.schedule.includes('1') && (
            <Typography
              variant="caption"
              color="error"
              style={{marginLeft: 15}}
            >
              This field is required
            </Typography>
          )}
        </FormChildTitle>
        <FormChildInput>
          <FormGroup>
            {scheduleValue.schedule.split('').map((val, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      color="primary"
                      size="medium"
                      checked={val === '1'}
                      onChange={e => onScheduleChange(index, e)}
                    />
                  }
                  label={
                    <Typography variant="subtitle2">{days[index]}</Typography>
                  }
                />
              )
            })}
          </FormGroup>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Time</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            variant="outlined"
            margin="normal"
            type="time"
            fullWidth
            value={scheduleValue.scheduleTime}
            onChange={onChange('scheduleTime')}
            error={isError && !scheduleValue.scheduleTime}
            helperText={
              isError && !scheduleValue.scheduleTime && 'This field is required'
            }
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>Number of Question</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            variant="outlined"
            margin="normal"
            type="number"
            fullWidth
            value={scheduleValue.dailyQuestion}
            onChange={onChange('dailyQuestion')}
            error={isError && scheduleValue?.dailyQuestion <= 0}
            helperText={
              isError &&
              scheduleValue?.dailyQuestion <= 0 &&
              'This field cannot be 0'
            }
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildInput>
          <GreyTypography>
            This Field to determine the number of questions than will be
            answered each time it appears according to the time set above
          </GreyTypography>
        </FormChildInput>
      </FormChildContainer>
    </FormContainer>
  )
}

export default MicroLearningScheduleComponent
