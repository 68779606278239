import {
  GET_FILTER_ASPECT,
  GET_FILTER_GOAL_LABEL,
  GET_FILTER_LABEL,
  GET_FILTER_PERSPECTIVE,
  GET_FILTER_PLACEMENT,
  GET_FILTER_SCORING_NATURE,
  GET_FILTER_WEIGHT_UNIT,
} from '../../../graphql/queries'

import {GLOBAL_GLOSSARY} from '../../../utils/constant'
import {createGetVariables, getDataId} from '../../../utils/assignFilters'

import {COMPANY_ID} from '../../../utils/globals'

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const DEFAULT_OPTIONS = {
  context: {
    headers: {
      'X-Hasura-Role': 'user',
    },
  },
}

const getVariablesPlacement = ({search, limit, _in, _nin}) => {
  return {
    limit: limit,
    where: {
      company: {_eq: COMPANY_ID},
      status: {_eq: 'ACTIVE'},
      global_user: {id: {_in, _nin}},
      _or: search
        ? [
            {regno: {_ilike: search}},
            {global_user: {name: {_ilike: search}}},
            {company_job_profile: {title: {_ilike: search}}},
          ]
        : undefined,
    },
  }
}

const setDataPlacement = data => {
  const _data = data.people_work_placements.map(
    ({global_user: {id, avatar, name}, company_job_profile}) => {
      return {
        value: id,
        label: {
          avatar,
          name,
          title: company_job_profile?.title ?? '-',
        },
      }
    }
  )

  return [_data, data.total.aggregate.count]
}

export const listFilterGoal = [
  {
    name: `Tugaskan ${GLOBAL_GLOSSARY.performance.Goal_short}`,
    fieldName: 'assignee',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        options: DEFAULT_OPTIONS,
        getVariables: getVariablesPlacement,
        setData: setDataPlacement,
      },
    },
  },
  {
    name: `${GLOBAL_GLOSSARY.performance.Reporter} ${GLOBAL_GLOSSARY.performance.Goal_short}`,
    fieldName: 'reporter',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        options: DEFAULT_OPTIONS,
        getVariables: getVariablesPlacement,
        setData: setDataPlacement,
      },
    },
  },
  {
    name: `Pembuat ${GLOBAL_GLOSSARY.performance.Goal_short}`,
    fieldName: 'creator',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        options: DEFAULT_OPTIONS,
        getVariables: getVariablesPlacement,
        setData: setDataPlacement,
      },
    },
  },
  {
    name: `Tipe ${GLOBAL_GLOSSARY.performance.Goal_short}`,
    fieldName: 'type',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'Personal - 2',
          label: 'Personal - Quantitative',
        },
        {
          value: 'Personal - 1',
          label: 'Personal - Qualitative',
        },
        {
          value: 'Company - 2',
          label: 'Company - Quantitative',
        },
        {value: 'Company - 1', label: 'Company - Qualitative'},
      ],
    },
  },
  {
    name: 'Prioritas',
    fieldName: 'priority',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'HIGH',
          label: 'High',
        },
        {
          value: 'MEDIUM',
          label: 'Medium',
        },
        {
          value: 'LOW',
          label: 'Low',
        },
      ],
    },
  },
  {
    name: 'Label',
    fieldName: 'label',
    type: 'checkbox',
    options: {
      checkboxType: 'label',
      fetch: {
        query: GET_FILTER_GOAL_LABEL,
        getVariables: ({search, limit, _in, _nin}) => {
          return {
            limit: limit,
            where: {
              company: {_eq: COMPANY_ID},
              name: search ? {_ilike: search} : undefined,
              id: {_in, _nin},
            },
          }
        },
        setData: data => {
          if (data?.performance_goal_groups) {
            const _data = data.performance_goal_groups.map(
              ({id, name, color}) => {
                return {
                  value: id,
                  label: {
                    color,
                    name,
                  },
                }
              }
            )
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Status',
    fieldName: 'filterStatus',
    type: 'checkbox',
    options: {
      list: [
        {
          value: [
            {
              _not: {
                performance_tasks: {
                  status: {_in: ['COMPLETED', 'ARCHIVED']},
                },
              },
            },
          ],
          label: 'To Do',
        },
        {
          value: [
            {
              _not: {
                performance_tasks: {
                  status: {_in: ['TODO', 'INPROGRESS', 'DONE']},
                },
              },
            },
            {performance_tasks: null},
          ],
          label: 'Complete',
        },
      ],
    },
  },
  {
    name: 'Periode',
    fieldName: 'period',
    type: 'date',
  },
  {
    name: `Aspek ${GLOBAL_GLOSSARY.performance.Goal_short}`,
    fieldName: 'aspect',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_ASPECT,
        getVariables: createGetVariables({
          deletedAtKey: 'deleted_at',
          status: 'ACTIVE',
          statusEq: '_eq',
        }),
        setData: getDataId,
      },
    },
  },
  {
    name: 'Perspektif',
    fieldName: 'perspective',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_PERSPECTIVE,
        getVariables: createGetVariables({
          deletedAtKey: 'deleted_at',
          status: 'ACTIVE',
          statusEq: '_eq',
        }),
        setData: getDataId,
      },
    },
  },
  {
    name: 'Sifat Penilaian',
    fieldName: 'scoringNature',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_SCORING_NATURE,
        getVariables: createGetVariables({
          deletedAtKey: 'deleted_at',
          status: 'ACTIVE',
          statusEq: '_eq',
        }),
        setData: getDataId,
      },
    },
  },
  {
    name: `${GLOBAL_GLOSSARY.performance['Weight Unit']}`,
    fieldName: 'weightUnit',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_WEIGHT_UNIT,
        getVariables: createGetVariables({
          deletedAtKey: 'deleted_at',
          status: 'ACTIVE',
          statusEq: '_eq',
        }),
        setData: getDataId,
      },
    },
  },
]

export const listFilterTask = [
  {
    name: 'Task Reporter',
    fieldName: 'reporter',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        getVariables: getVariablesPlacement,
        setData: setDataPlacement,
      },
    },
  },
  {
    name: 'Task Assignee',
    fieldName: 'assignee',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        getVariables: getVariablesPlacement,
        setData: setDataPlacement,
      },
    },
  },
  {
    name: 'Creator',
    fieldName: 'creator',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        getVariables: getVariablesPlacement,
        setData: setDataPlacement,
      },
    },
  },
  {
    name: 'Task Type',
    fieldName: 'type',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'Personal - 2',
          label: 'Personal - Quantitative',
        },
        {
          value: 'Personal - 1',
          label: 'Personal - Qualitative',
        },
        {
          value: 'Company - 2',
          label: 'Company - Quantitative',
        },
        {value: 'Company - 1', label: 'Company - Qualitative'},
      ],
    },
  },
  {
    name: 'Priority',
    fieldName: 'priority',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'HIGH',
          label: 'High',
        },
        {
          value: 'MEDIUM',
          label: 'Medium',
        },
        {
          value: 'LOW',
          label: 'Low',
        },
      ],
    },
  },

  {
    name: 'Label',
    fieldName: 'label',
    type: 'checkbox',
    options: {
      checkboxType: 'label',
      fetch: {
        query: GET_FILTER_LABEL,
        getVariables: ({search, limit, _in, _nin}) => {
          return {
            limit: limit,
            where: {
              company: {_eq: COMPANY_ID},
              name: search ? {_ilike: search} : undefined,
              id: {_in, _nin},
            },
          }
        },
        setData: data => {
          if (data?.performance_groups) {
            const _data = data.performance_groups.map(({id, name, color}) => {
              return {
                value: id,
                label: {
                  color,
                  name,
                },
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'TODO',
          label: 'To Do',
        },
        {
          value: 'INPROGRESS',
          label: 'In Progress',
        },
        {
          value: 'DONE',
          label: 'Done',
        },
        {
          value: 'COMPLETED',
          label: 'Complete',
        },
      ],
    },
  },
  {
    name: 'Percentage',
    fieldName: 'percentage',
    type: 'checkbox',
    options: {
      list: [
        {
          value: '100.1-9999',
          label: 'Exceed',
        },
        {
          value: '100-100',
          label: 'Meet',
        },
        {
          value: '0-99.9',
          label: 'Below',
        },
      ],
    },
  },
  {
    name: 'Period',
    fieldName: 'period',
    type: 'date',
  },
  {
    name: 'Goal Aspect',
    fieldName: 'aspect',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_ASPECT,
        getVariables: createGetVariables({
          deletedAtKey: 'deleted_at',
          status: 'ACTIVE',
          statusEq: '_eq',
        }),
        setData: getDataId,
      },
    },
  },
  {
    name: 'Perspective',
    fieldName: 'perspective',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_PERSPECTIVE,
        getVariables: createGetVariables({
          deletedAtKey: 'deleted_at',
          status: 'ACTIVE',
          statusEq: '_eq',
        }),
        setData: getDataId,
      },
    },
  },
  {
    name: 'Scoring Nature',
    fieldName: 'scoringNature',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_SCORING_NATURE,
        getVariables: createGetVariables({
          deletedAtKey: 'deleted_at',
          status: 'ACTIVE',
          statusEq: '_eq',
        }),
        setData: getDataId,
      },
    },
  },
  {
    name: 'Weight Unit',
    fieldName: 'weightUnit',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_WEIGHT_UNIT,
        getVariables: createGetVariables({
          deletedAtKey: 'deleted_at',
          status: 'ACTIVE',
          statusEq: '_eq',
        }),
        setData: getDataId,
      },
    },
  },
]
