import React from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import {Checkbox, FormControlLabel, makeStyles} from '@material-ui/core'
import idLocale from 'date-fns/locale/id'

const useStyles = makeStyles(() => ({
  inputStyle: {
    padding: '15px 0px 15px 18px',
  },
  root: {
    margin: 0,
  },
  rootInput: {
    padding: 0,
  },
  rootWrapper: {
    marginBottom: 32,
  },
  dateWrapper: {
    width: '47%',
  },
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
    marginBottom: 17,
  },
  strip: {
    marginTop: 26,
  },
  labelSwitch: {
    color: '#a9a8a8',
    fontSize: 12,
  },
  labelRoot: {
    marginTop: 14,
  },
}))

export const DateForm = ({
  label = ['Tanggal Mulai*', 'Tanggal Selesai*'],
  error = [false, false],
  value = [new Date(), new Date(), false, true],
  labelDate = 'Saat ini saya sedang bekerja dalam posisi ini',
  onChange,
  fieldName,
  maxDate = [undefined, undefined],
}) => {
  const classes = useStyles()
  const handleChangeDate = async date => {
    await onChange(date, fieldName[0])
    await onChange(null, fieldName[1])
  }
  return (
    <div className={classes.rootWrapper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
        <Grid container alignItems="center" justifyContent="space-between">
          <div className={classes.dateWrapper}>
            <p className={classes.label}>{label[0]}</p>
            <KeyboardDatePicker
              margin="normal"
              format="MM/dd/yyyy"
              value={(value && value[0]) || null}
              inputVariant="outlined"
              placecholder="Pilih Tanggal"
              emptyLabel="Pilih Tanggal"
              onChange={handleChangeDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              classes={{
                root: classes.root,
              }}
              InputProps={{
                classes: {
                  root: classes.rootInput,
                  input: classes.inputStyle,
                },
              }}
              error={error[0]}
              helperText={error[0] && 'Bagian ini diperlukan'}
              maxDate={maxDate[0]}
              cancelLabel="Batal"
            />
          </div>
          {value && !value[2] && <div className={classes.strip}>-</div>}
          {value && !value[2] && (
            <div className={classes.dateWrapper}>
              <p className={classes.label}>{label[1]}</p>
              <KeyboardDatePicker
                margin="normal"
                format="MM/dd/yyyy"
                emptyLabel="Pilih Tanggal"
                value={(value && value[1]) || null}
                placecholder="Pilih Tanggal"
                inputVariant="outlined"
                onChange={date => onChange(date, fieldName[1])}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                classes={{
                  root: classes.root,
                }}
                InputProps={{
                  classes: {
                    root: classes.rootInput,
                    input: classes.inputStyle,
                  },
                }}
                error={error[1]}
                helperText={error[1] && 'Bagian ini diperlukan'}
                minDate={value[0]}
                maxDate={maxDate[1]}
                cancelLabel="Batal"
              />
            </div>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
      {value[3] && (
        <FormControlLabel
          classes={{
            root: classes.labelRoot,
            label: classes.labelSwitch,
          }}
          color="primary"
          checked={value && value[2]}
          // onChange={handleChange}
          onChange={() => {
            onChange(!(value && value[2]), fieldName[2])
          }}
          control={<Checkbox color="primary" name="checkedA" />}
          label={labelDate}
        />
      )}
    </div>
  )
}
