import React from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import {CircularProgress, TablePagination} from '@material-ui/core'
import {
  TaxComponentsWrapper,
  MediumTextBlackBold,
  CenterWrapper,
} from '../../../PayrollPageStyles'
import TaxOfficeComponent from './list-components/ComponentTaxOffice'
import PayrollSettingsWrapper from '../../PayrollSettingsWrapper'
import NoDataListComponent from '../../../../../shared-components/NoDataListComponent'
import {COMPANY_ID} from '../../../../../../utils/globals'
import {GET_TAX_OFFICE_ADDRESS} from '../../../../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from '../../../utils'
import usePagination from '../../../hooks/usePagination'

const TaxPayrollSettings = props => {
  const {
    activeComponent,
    setActiveComponent,
    setCurrentPage,
    setSelectedId,
    generalSettingData,
  } = props

  const {
    pageSizeOption,
    limit,
    offset,
    currentPage,
    rowPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination()
  let totalData

  const {data: officeAddress, loading, error} = useQuery(
    GET_TAX_OFFICE_ADDRESS,
    {
      variables: {
        company: COMPANY_ID,
        limit: limit,
        offset: offset,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  if (officeAddress) totalData = officeAddress.total.aggregate.count

  return (
    <PayrollSettingsWrapper
      activeComponent={activeComponent}
      setActiveComponent={setActiveComponent}
      hasChange={false}
      generalSettingData={generalSettingData}
    >
      <MediumTextBlackBold>Choose Your Office</MediumTextBlackBold>
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {loading ? (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      ) : officeAddress.company_addresses.length > 0 ? (
        <TaxComponentsWrapper>
          {officeAddress.company_addresses.map(item => {
            return (
              <TaxOfficeComponent
                key={item.id}
                onClick={() => {
                  setSelectedId(item.id)
                  setCurrentPage(1)
                }}
                code={item.office_code}
                name={item.office_name}
                address={item.address}
              />
            )
          })}
        </TaxComponentsWrapper>
      ) : (
        <NoDataListComponent
          message={
            <div>
              No office data here, please check your
              <br /> office data at company&apos;s tab
            </div>
          }
        />
      )}
      {totalData > 0 && (
        <TablePagination
          rowsPerPageOptions={pageSizeOption}
          component="div"
          rowsPerPage={rowPerPage}
          page={currentPage}
          count={totalData}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </PayrollSettingsWrapper>
  )
}

export default withRouter(TaxPayrollSettings)
