import gql from 'graphql-tag'

export const ADD_PEOPLE_PROFILE_BANK = gql`
  mutation addUserBankAccount(
    $user: uuid
    $bank: Int
    $bank_name: String
    $account_name: String
    $account_number: String
    $creator_company: uuid
  ) {
    insert_people_profile_banks(
      objects: {
        user: $user
        bank: $bank
        bank_name: $bank_name
        account_name: $account_name
        account_number: $account_number
        creator_company: $creator_company
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_PEOPLE_PROFILE_BANK = gql`
  mutation editUserBankAccount(
    $pp_bank_id: Int!
    $pp_bank_creator_company: uuid!
    $global_bank_id: Int
    $account_number: String
    $account_name: String
    $bank_name: String
  ) {
    update_people_profile_banks(
      where: {
        id: {_eq: $pp_bank_id}
        creator_company: {_eq: $pp_bank_creator_company}
        deletedAt: {_is_null: true}
      }
      _set: {
        bank: $global_bank_id
        account_number: $account_number
        account_name: $account_name
        bank_name: $bank_name
      }
    ) {
      affected_rows
      returning {
        id
        bank_name
        bank
        account_name
        account_number
      }
    }
  }
`

export const DELETE_PEOPLE_PROFILE_BANK = gql`
  mutation deleteUserBankAccount($id: Int!) {
    update_people_profile_banks(
      _set: {deletedAt: "now()"}
      where: {_and: [{id: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      affected_rows
      __typename
    }
  }
`
