import gql from 'graphql-tag'

export const GET_COMPONENT_ANALYTICS = gql`
  query($company: uuid!) {
    finance_nonfixed_components(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      id
      name
    }
  }
`
export const GET_LIST_MONTHLY_SALARY = gql`
  query(
    $company: uuid!
    $year_start: Int
    $year_end: Int
    $month_start: Int
    $month_end: Int
  ) {
    v_payroll_dashboard_monthly_salary(
      where: {
        company: {_eq: $company}
        _and: [
          {year: {_lte: $year_end}, month: {_lte: $month_end}}
          {year: {_gte: $year_start}, month: {_gte: $month_start}}
        ]
      }
    ) {
      year
      month
      total
    }
  }
`
export const GET_LIST_MONTHLY_COMPONENT = gql`
  query(
    $company: uuid!
    $year_start: Int
    $year_end: Int
    $month_start: Int
    $month_end: Int
    $component: Int
  ) {
    v_payroll_dashboard_monthly_component(
      where: {
        company: {_eq: $company}
        component: {_eq: $component}
        _and: [
          {year: {_lte: $year_end}, month: {_lte: $month_end}}
          {year: {_gte: $year_start}, month: {_gte: $month_start}}
        ]
      }
    ) {
      year
      month
      component
      total
    }
  }
`
