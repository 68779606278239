import { Skeleton } from "@material-ui/lab";
import React from "react";

export default function LoadingItemTable({
  total = 4,
  variant = "rect",
  width,
  height,
  ...props
}) {
  return (
    <>
      {new Array(total).fill(null).map((row, i) => (
        <Skeleton
          key={`${row}-${i}`}
          variant={variant}
          width={width}
          height={height}
          {...props}
        />
      ))}
    </>
  );
}
