import React, {useState, useEffect} from 'react'
import {useQuery} from '@apollo/react-hooks'
import {withRouter} from 'react-router-dom'
import {CircularProgress} from '@material-ui/core'
import {CenterWrapper} from './PayrollPageStyles'
import {PageContainer} from '../../../GlobalStyles'
import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'
import ListPayrollProcess from './payroll-process/ListPayrollProcess'
import StepPayrollProcess from './payroll-process/StepPayrollProcess'
import PayrollDetail from './payroll-process/PayrollDetail'
import THRDetail from './thr/step-thr/THRDetail'
import StepTHRProcess from './thr/StepTHRProcess'
import PayrollSettings from './payroll-settings/PayrollSettings'
import DetailPayrollComponent from './payroll-settings/payroll-settings-tabs/component-payroll/DetailPayrollComponent'
import EditPayrollComponent from './payroll-settings/payroll-settings-tabs/component-payroll/EditPayrollComponent'
import ListEmployeeSalary from './employee-settings/ListEmployeeSalary'
import EmployeeSettingsEditPage from './employee-settings/edit/EmployeeSettingsEditPage'
import ReportComponent from './report/ReportComponent'
import DetailAnnualReport from './report/tab/detail/DetailAnnualReport'
import DetailPayrollReport from './report/tab/detail/DetailPayrollReport'
import DetailTaxReport from './report/tab/detail/DetailTaxReport'
import DetailThrReport from './report/tab/detail/DetailThrReport'
import ListTHRProcess from './thr/ListTHRProcess'
import PayrollAnalytics from './analytics/PayrollAnalytics'
import ModifyPremium from './payroll-settings/payroll-settings-tabs/premium-payroll/form/ModifyPremium'
import './payrollPrint.css'
import {COMPANY_ID} from '../../../utils/globals'
import {GET_GENERAL_SETTING} from '../../../graphql/queries'
import {GQL_FINANCE_OPTIONS} from './utils'

const PayrollPage = props => {
  let activeTab = ''
  let activeMenu = 'analytic'
  let state = {}
  const [filterData, setFilterData] = useState({
    position: [],
    organization: [],
    job_profile: [],
    rank: [],
    office: [],
  })

  const {data, loading, error} = useQuery(GET_GENERAL_SETTING, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const renderComponent = (
    activeComponent,
    state,
    searchText = '',
    period,
    startDate,
    endDate,
    history,
    year,
    filterData
  ) => {
    const generalSettingData = data && data.finance_payroll_settings_by_pk
    const isDisabled = generalSettingData === null

    switch (activeComponent) {
      case 'analytic':
        return <PayrollAnalytics />
      case 'payroll-process':
        return (
          <ListPayrollProcess
            searchText={searchText}
            period={period}
            isDisabled={isDisabled}
          />
        )
      case 'step-payroll-process':
        return (
          <StepPayrollProcess
            searchText={searchText}
            status={state.status}
            backStep={state.backStep}
            period={period}
            year={year}
            filterData={filterData}
          />
        )
      case 'payroll-detail':
        return <PayrollDetail searchText={searchText} />
      case 'thr-detail':
        return <THRDetail searchText={searchText} />
      case 'thr-process':
        return (
          <ListTHRProcess
            searchText={searchText}
            startDate={startDate}
            endDate={endDate}
            period={period}
            isDisabled={isDisabled}
          />
        )
      case 'step-thr-process':
        return (
          <StepTHRProcess
            status={state.status}
            searchText={searchText}
            filterData={filterData}
            payrollCalculation={state.payrollCalculation}
          />
        )
      case 'employee-settings':
        return (
          <ListEmployeeSalary searchText={searchText} filterData={filterData} />
        )
      case 'employee-settings-edit':
        return <EmployeeSettingsEditPage />
      case 'report':
        return (
          <ReportComponent
            period={period}
            searchText={searchText}
            history={history}
            startDate={startDate}
            endDate={endDate}
          />
        )
      case 'report-payroll-detail':
        return (
          <DetailPayrollReport
            searchText={searchText}
            filterData={filterData}
          />
        )
      case 'report-thr-detail':
        return (
          <DetailThrReport searchText={searchText} filterData={filterData} />
        )
      case 'report-annual-detail':
        return (
          <DetailAnnualReport searchText={searchText} filterData={filterData} />
        )
      case 'report-tax-detail':
        return (
          <DetailTaxReport searchText={searchText} filterData={filterData} />
        )
      case 'settings':
        return <PayrollSettings generalSettingData={generalSettingData} />
      case 'settings-add-component':
        return <DetailPayrollComponent />
      case 'settings-edit-component':
        return <EditPayrollComponent />
      case 'settings-add-premium':
      case 'settings-edit-premium':
        return <ModifyPremium />
      default:
        return <PayrollAnalytics />
    }
  }

  if (props.location.state) {
    activeMenu = props.location.state.active
      ? props.location.state.active
      : 'analytic'
    state = props.location.state
    switch (activeMenu) {
      case 'analytic':
        activeTab = 'analytic'
        break
      case 'payroll-process':
      case 'step-payroll-process':
      case 'payroll-detail':
        activeTab = 'payroll-process'
        break
      case 'thr-process':
      case 'step-thr-process':
      case 'thr-detail':
        activeTab = 'thr-process'
        break
      case 'employee-settings':
      case 'employee-settings-edit':
        activeTab = 'employee-settings'
        break
      case 'report':
      case 'report-payroll-detail':
      case 'report-thr-detail':
      case 'report-tax-detail':
      case 'report-annual-detail':
        activeTab = 'report'
        break
      case 'settings':
      case 'settings-add-component':
      case 'settings-edit-component':
      case 'settings-add-premium':
      case 'settings-edit-premium':
      case 'setting-tax-detail':
        activeTab = 'settings'
        break
      default:
        activeTab = props.location.state.active
    }
  }

  useEffect(() => {
    if (props.location.pathname === '/financial/payroll/analytic') {
      props.history.push({
        state: {
          active: 'analytic',
          searchable: true,
        },
      })
    }

    if (activeMenu === 'step-payroll-process') {
      props.history.push({
        pathname: '/financial/payroll/payroll-process/step',
        search: props.location.search,
        state: {
          active: 'step-payroll-process',
          searchable: true,
          showPeriod: false,
          showMonthYearPeriod: true,
          rangePeriod: false,
          filter: true,
        },
      })
    }

    if (activeMenu === 'step-thr-process') {
      props.history.push({
        pathname: '/financial/payroll/thr-process/step',
        search: props.location.search,
        state: {
          active: 'step-thr-process',
          searchable: true,
          showPeriod: false,
          showMonthYearPeriod: true,
          rangePeriod: false,
          filter: true,
        },
      })
    }
    if (activeMenu === 'employee-settings') {
      props.history.push({
        state: {
          active: 'employee-settings',
          searchable: true,
          filter: true,
        },
      })
    }

    if (activeMenu === 'payroll-process') {
      props.history.push({
        state: {
          active: 'payroll-process',
          searchable: false,
          showPeriod: true,
          noMaxDate: true,
          rangePeriod: false,
          showMonthYearPeriod: false,
          filter: false,
        },
      })
    }

    if (activeMenu === 'thr-process') {
      props.history.push({
        state: {
          active: 'thr-process',
          searchable: false,
          showPeriod: true,
          noMaxDate: true,
          rangePeriod: false,
          showMonthYearPeriod: false,
          filter: false,
        },
      })
    }

    if (activeMenu === 'report-payroll-detail') {
      props.history.push({
        state: {
          active: 'report',
          searchable: true,
          showPeriod: false,
          rangePeriod: false,
          showMonthYearPeriod: true,
          filter: true,
        },
      })
    }

    if (activeMenu === 'report') {
      props.history.push({
        state: {
          active: 'report',
          searchable: false,
          showPeriod: false,
          rangePeriod: false,
          filter: false,
          showMonthYearPeriod: false,
        },
      })
    }

    document.title = `SmartASN - Administrator - Financial ${
      props.location.state ? props.location.state.active : 'analytic'
    }`
    return () => {
      document.title = 'SmartASN - Administrator'
    }
  }, [])

  const data_tabs = [
    {
      label: 'Analytic',
      state: 'analytic',
      url: '/financial/payroll/analytic',
      searchable: true,
      showPeriod: false,
      rangePeriod: false,
      filter: false,
      showMonthYearPeriod: false,
    },
    {
      label: 'Payroll Process',
      state: 'payroll-process',
      url: '/financial/payroll/payroll-process',
      searchable: false,
      showPeriod: true,
      noMaxDate: true,
      rangePeriod: false,
      filter: false,
      showMonthYearPeriod: false,
    },
    {
      label: 'THR Process',
      state: 'thr-process',
      url: '/financial/payroll/thr-process',
      searchable: false,
      showPeriod: true,
      noMaxDate: true,
      rangePeriod: false,
      filter: false,
      showMonthYearPeriod: false,
    },
    {
      label: 'Employee Settings',
      state: 'employee-settings',
      url: '/financial/payroll/employee-settings',
      searchable: true,
      showPeriod: false,
      rangePeriod: false,
      filter: true,
      showMonthYearPeriod: false,
    },
    {
      label: 'Report',
      state: 'report',
      url: '/financial/payroll/report',
      searchable: false,
      showPeriod: false,
      rangePeriod: false,
      filter: false,
      showMonthYearPeriod: false,
    },
    {
      label: 'Settings',
      state: 'settings',
      url: '/financial/payroll/setting',
      searchable: false,
      showPeriod: false,
      rangePeriod: false,
      filter: false,
      showMonthYearPeriod: false,
    },
  ]

  const [searchText, setSearchText] = useState('')
  const [period, setPeriod] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [year, setYear] = useState(new Date())

  const {showYearPicker} = state

  const onSearch = search => {
    setSearchText(search)
  }
  const onChangePeriod = period => {
    setPeriod(period)
  }
  const onChangeYear = year => {
    setYear(year)
  }

  if (error) {
    console.error(error)
  }

  return (
    <div>
      {/* {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>} */}
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {!loading && data && (
        <Card
          icon="attach_money"
          title="Payroll"
          onSearch={onSearch}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          showYearPicker={showYearPicker}
          year={year}
          onChangeYear={onChangeYear}
          period={period}
          onChangePeriod={onChangePeriod}
          tabFilter={<div />}
          setFilterData={setFilterData}
        >
          <PageContainer>
            <WlbTab data={data_tabs} activeMenu={activeTab} />
            {renderComponent(
              activeMenu,
              state,
              searchText,
              period,
              startDate,
              endDate,
              props.history,
              year,
              filterData
            )}
          </PageContainer>
        </Card>
      )}
    </div>
  )
}

export default withRouter(PayrollPage)
