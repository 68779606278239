import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import {withRouter} from 'react-router-dom'
import RegulationsList from './list/RegulationsList'
import AddEditComponent from './add-edit-regulation/AddEditComponent'
import {PageContainer} from '../../../GlobalStyles'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'list-regulations':
      return <RegulationsList state={state} searchText={searchText} />
    case 'edit-regulations':
      return (
        <AddEditComponent
          type="Edit"
          model="Regulation"
          state={state}
          searchText={searchText}
        />
      )
    case 'add-regulations':
      return (
        <AddEditComponent
          type="Add"
          model="Regulation"
          state={state}
          searchText={searchText}
        />
      )
    case 'edit-clause':
      return (
        <AddEditComponent
          type="Edit"
          model="Clause"
          state={state}
          searchText={searchText}
        />
      )
    case 'add-clause':
      return (
        <AddEditComponent
          type="Add"
          model="Clause"
          state={state}
          searchText={searchText}
        />
      )
    default:
      return <RegulationsList state={state} searchText={searchText} />
  }
}

const RegulationsPageComponent = props => {
  const [searchText, setSearchText] = useState('')

  let activeMenu = 'list-regulations'
  let state = {}

  if (props.location.state) {
    activeMenu = props.location.state.active
    state = props.location.state
  }

  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <div>
      <Card icon="location_city" title="Regulations" onSearch={onSearch}>
        <PageContainer>
          {renderComponent(activeMenu, state, searchText)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default withRouter(RegulationsPageComponent)
