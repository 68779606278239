import {LIST_FILTER_TYPE, LIST_FILTER_INDUSTRY} from '../../../graphql/queries'

import {createGetVariables, getDataId} from '../../../utils/assignFilters'

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

export const listFilter = [
  {
    name: 'Type',
    fieldName: 'type',
    type: 'checkbox',
    options: {
      fetch: {
        query: LIST_FILTER_TYPE,
        getVariables: createGetVariables({
          searchKeys: ['legal_type'],
          company: false,
          deletedAtKey: 'deleted_at',
        }),
        setData: getDataId,
      },
    },
  },
  {
    name: 'Industry',
    fieldName: 'industry',
    type: 'checkbox',
    options: {
      fetch: {
        query: LIST_FILTER_INDUSTRY,
        getVariables: createGetVariables({
          searchKeys: ['legal_type'],
          company: false,
          deletedAtKey: 'deleted_at',
        }),
        setData: getDataId,
      },
    },
  },
]
