import gql from 'graphql-tag'

export const GET_OVER_ACTIVITY = gql`
  query($where: people_work_overtimes_bool_exp, $offset: Int, $limit: Int) {
    total: people_work_overtimes_aggregate(where: $where) {
      aggregate {
        count
      }
    }

    people_work_overtimes(
      where: $where
      order_by: [{id: desc}]
      limit: $limit
      offset: $offset
    ) {
      id
      id_display
      people_work_placement {
        id
        regno
        people_work_salaries(
          where: {
            _and: [
              {start_date: {_is_null: false}}
              {start_date: {_lte: "now()"}}
            ]
          }
          order_by: {start_date: asc_nulls_last}
        ) {
          id
          value
          start_date
          end_date
          active
        }
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
              global_user {
                id
                name
                avatar
              }
            }
          }
        }
      }
      description
      datestart
      dateend
      convert_to
      status
      date_added
      last_modified
      added_by
      modified_by
      #cancelled_by
      overtime_fields
      time_overtime_policy {
        id
        name
        convert_to_leave
        convert_to_money
        confirm_type
        minimum_duration_leave
        first_job_profile {
          id
          title
          people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
            id
            global_user {
              id
              name
              avatar
            }
          }
        }
        second_job_profile {
          id
          title
          people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
            id
            global_user {
              id
              name
              avatar
            }
          }
        }
        TimeLeavePolicyChildren {
          quota
        }
      }
      globalUserByModifiedBy {
        id
        name
        avatar
      }
    }
  }
`

export const GET_OVER_SETTINGS = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: time_overtime_policies_aggregate(where: {name: {_ilike: $search}}) {
      aggregate {
        count
      }
    }

    time_overtime_policies(
      where: {name: {_ilike: $search}}
      offset: $offset
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
      filter_position
      convert_to_leave
      convert_to_money
      confirm_type
      minimum_duration_leave
      leave_policy_children
      first_job_profile {
        id
        title
      }
      second_job_profile {
        id
        title
      }
      total_job_profile_r_overtimes: company_job_profile_r_overtimes_aggregate(
        where: {company_job_profile: {deletedAt: {_is_null: true}}}
      ) {
        aggregate {
          count
        }
      }
      company_job_profile_r_overtimes(
        where: {company_job_profile: {deletedAt: {_is_null: true}}}
        order_by: [{company_job_profile: {code: asc}}]
        limit: 20
      ) {
        company_job_profile {
          id
          title
          code
        }
      }
    }
  }
`

export const GET_TOTAL_EXIST_NAME_OVERTIME = gql`
  query($name: String) {
    total: time_overtime_policies_aggregate(where: {name: {_eq: $name}}) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LIST_APPLIED_POSITION_OVERTIME = gql`
  query(
    $companyId: uuid!
    $search: String!
    $filterJobFamily: Int_comparison_exp
    $filterJobFunction: Int_comparison_exp
    $filterJobTitle: Int_comparison_exp
    $filterOrgUnit: Int_comparison_exp
    $filterOrgLevel: Int_comparison_exp
    $filterJobLevel: Int_comparison_exp
    $filterJobGrade: Int_comparison_exp
    $filterOffice: Int_comparison_exp
    $filterPosition: Int_comparison_exp
    $limit: Int
    $offset: Int
    $policy: Int
    $isFilterPolicy: Boolean
  ) {
    total: company_job_profiles_aggregate(
      where: {
        id: $filterPosition
        company: {_eq: $companyId}
        _or: [
          {
            company_job_profile_r_overtimes: {
              _not: {id: {_is_null: $isFilterPolicy}}
              policy: {_eq: $policy}
              company_job_profile: {
                _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
              }
            }
          }
          {
            _or: [
              {
                id: {_is_null: $isFilterPolicy}
                _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
              }
            ]
          }
        ]
        company_employee_position: {
          job_mapping_id: $filterJobFunction
          family_id: $filterJobFamily
          id: $filterJobTitle
        }
        company_organization: {
          id: $filterOrgUnit
          organization_level_id: $filterOrgLevel
        }
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        office_address: $filterOffice
      }
    ) {
      aggregate {
        count
      }
    }

    totalAll: company_job_profiles_aggregate(
      where: {
        id: $filterPosition
        company: {_eq: $companyId}
        _or: [
          {
            company_job_profile_r_overtimes: {
              _not: {id: {_is_null: $isFilterPolicy}}
              policy: {_eq: $policy}
              company_job_profile: {}
            }
          }
          {_or: [{id: {_is_null: $isFilterPolicy}}]}
        ]
        company_employee_position: {
          job_mapping_id: $filterJobFunction
          family_id: $filterJobFamily
          id: $filterJobTitle
        }
        company_organization: {
          id: $filterOrgUnit
          organization_level_id: $filterOrgLevel
        }
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        office_address: $filterOffice
      }
    ) {
      aggregate {
        count
      }
    }

    totalPosition: company_job_profiles_aggregate(
      where: {
        id: $filterPosition
        company: {_eq: $companyId}
        company_employee_position: {
          job_mapping_id: $filterJobFunction
          family_id: $filterJobFamily
          id: $filterJobTitle
        }
        company_organization: {
          id: $filterOrgUnit
          organization_level_id: $filterOrgLevel
        }
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        office_address: $filterOffice
      }
    ) {
      aggregate {
        count
      }
    }

    prev_filter_policy: time_leave_policy_children(
      where: {id: {_eq: $policy}}
    ) {
      last_modified
      filter_position
    }

    company_job_profiles(
      where: {
        id: $filterPosition
        company: {_eq: $companyId}
        _or: [
          {
            company_job_profile_r_overtimes: {
              _not: {id: {_is_null: $isFilterPolicy}}
              policy: {_eq: $policy}
              company_job_profile: {
                _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
              }
            }
          }
          {
            _or: [
              {
                id: {_is_null: $isFilterPolicy}
                _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
              }
            ]
          }
        ]
        company_employee_position: {
          job_mapping_id: $filterJobFunction
          family_id: $filterJobFamily
          id: $filterJobTitle
        }
        company_organization: {
          id: $filterOrgUnit
          organization_level_id: $filterOrgLevel
        }
        rank: $filterJobLevel
        job_grade_id: $filterJobGrade
        office_address: $filterOffice
      }
      order_by: [{code: asc}]
      limit: $limit
      offset: $offset
    ) {
      id
      code
      title
    }
  }
`

export const GET_OVER_POLICY_NAME = gql`
  query overPolName($name: String) {
    time_overtime_policies(where: {name: {_ilike: $name}}) {
      id
      name
    }
  }
`

export const GET_JOB_PROFILE_OT = gql`
  query($id: Int, $search: String, $limit: Int, $offset: Int) {
    time_overtime_policies(where: {id: {_eq: $id}}) {
      total: company_job_profile_r_overtimes_aggregate(
        where: {company_job_profile: {title: {_ilike: $search}}}
      ) {
        aggregate {
          count
        }
      }
      company_job_profile_r_overtimes(
        where: {company_job_profile: {title: {_ilike: $search}}}
        limit: $limit
        offset: $offset
      ) {
        company_job_profile {
          id
          title
        }
      }
    }
  }
`

export const GET_NEW_POSITION_OVERTIME = gql`
  query(
    $company: uuid
    $policy: Int
    $search: String
    $limit: Int
    $offset: Int
  ) {
    count: company_job_profiles_aggregate(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
        _not: {
          company_job_profile_r_overtimes: {
            id: {_is_null: false}
            policy: {_eq: $policy}
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    company_job_profiles(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        _not: {
          company_job_profile_r_overtimes: {
            id: {_is_null: false}
            policy: {_eq: $policy}
          }
        }
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
      }
      order_by: {date_added: asc}
      limit: $limit
      offset: $offset
    ) {
      title
      date_added
    }
  }
`

export const GET_LIST_LEAVE_PAY_WITH_LEAVE = gql`
  query getDropdownLeave($company: uuid) {
    time_leave_policy_children(
      where: {
        deletedAt: {_is_null: true}
        time_leave_policy: {company: {_eq: $company}}
      }
      order_by: {name: asc}
    ) {
      id
      name
    }
  }
`
