import gql from 'graphql-tag'

export const GET_BPJS_SETTINGS = gql`
  query($company: uuid!) {
    finance_payroll_settings_by_pk(id: $company) {
      payroll_setting_fields
      payroll_setting_plugins
    }
  }
`

export const GET_LIST_BPJSKES_EXCLUSION_EMPLOYEE = gql`
  query getListBPJSKesExclusionCompany(
    $company: uuid!
    $limit: Int
    $offset: Int
    $search: String
  ) {
    total: payroll_setting_exclude_bpjs_aggregate(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
    filter_assignee: payroll_setting_exclude_bpjs(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      id
      user
      company
    }
    exclude_people: payroll_setting_exclude_bpjs(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        _or: [{global_user: {name: {_ilike: $search}}}]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      user
      company
      global_user {
        id
        name
        avatar
        people_work_placements {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`

export const GET_LIST_BPJSTK_EXCLUSION_EMPLOYEE = gql`
  query getListBPJSTKExclusionCompany(
    $company: uuid!
    $limit: Int
    $offset: Int
    $search: String
  ) {
    total: payroll_setting_exclude_bpjstk_aggregate(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
    filter_assignee: payroll_setting_exclude_bpjstk(
      where: {company: {_eq: $company}, deletedAt: {_is_null: true}}
    ) {
      id
      user
      company
    }
    exclude_people: payroll_setting_exclude_bpjstk(
      where: {
        company: {_eq: $company}
        deletedAt: {_is_null: true}
        _or: [{global_user: {name: {_ilike: $search}}}]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      user
      company
      global_user {
        id
        name
        avatar
        people_work_placements {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`
