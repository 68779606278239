import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'

export const isError = (error, value) => {
  return !!(error && !value)
}

export const isErrorDetected = values => {
  const CHECK = [
    'name',
    'visibility',
    'description',
    'target',
    'target_unit',
    'aspect',
    'startdate',
    'enddate',
    'priority',
    'progress',
  ]

  let result = false
  for (const element of CHECK) {
    if (!values[element]) {
      result = true
    }
  }
  return result
}

export const isErrorScoring = (dataKpi, values) => {
  if (dataKpi) {
    const nameScoring =
      dataKpi.kpi_scoring_nature &&
      dataKpi.kpi_scoring_nature.global_scoring_nature &&
      dataKpi.kpi_scoring_nature.global_scoring_nature.name
    if (
      (nameScoring === 'Lower is Better' &&
        parseInt(values.target_stretch) < parseInt(values.target)) ||
      (nameScoring === 'Higher is Better' &&
        parseInt(values.target_stretch) > parseInt(values.target)) ||
      (nameScoring === 'Precise is Better' &&
        parseInt(values.target_stretch) === parseInt(values.target))
    ) {
      return false
    } else {
      return nameScoring !== 'Special Scoring'
    }
  } else {
    return false
  }
}

export const renderErrorStretch = dataKpi => {
  const nameScoring =
    dataKpi.kpi_scoring_nature &&
    dataKpi.kpi_scoring_nature.global_scoring_nature &&
    dataKpi.kpi_scoring_nature.global_scoring_nature.name
  if (dataKpi) {
    if (nameScoring === 'Lower is Better') {
      return 'Target stretch must be lower than target base'
    } else if (nameScoring === 'Higher is Better') {
      return 'Target stretch must be higher than target base'
    } else if (nameScoring === 'Precise is Better') {
      return 'Target stretch must be same with target base'
    }
  } else {
    return ''
  }
}

export const IntersectionOfDate = (minDate, date, maxDate) => {
  const convertminDate = new Date(minDate)
  const convertDate = new Date(date)
  const convertMaxDate = new Date(maxDate)

  return convertDate <= convertMaxDate && convertDate >= convertminDate
}

export const goalType = {
  1: GLOBAL_GLOSSARY.performance.Qualitative,
  2: GLOBAL_GLOSSARY.performance.Quantitative,
}

export const goalTypes = [
  {
    value: 1,
    label: GLOBAL_GLOSSARY.performance.Qualitative,
  },
  {
    value: 2,
    label: GLOBAL_GLOSSARY.performance.Quantitative,
  },
]
