import {Divider, Icon, IconButton, Typography, Button} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState, useEffect} from 'react'
import {BlackLinkBold} from '../CrossMentoringPageStyles'
import {
  PaperContainer,
  EnhancedToolbar,
  TextBlackBold,
} from '../../../../GlobalStyles'
import {
  NewTableOptions,
  newTableStyles,
} from '../../../shared-components/table/TableOptions'
import {GET_LIST_CROSS_MENTORING} from '../../../../graphql/queries'
import {DELETE_CROSS_MENTORING} from '../../../../graphql/mutations'
import {useQuery, useMutation} from '@apollo/react-hooks'
import Loading from '../../../../components/loading/LoadingComponent'
import {capitalize, holdingRolesContext} from '../../../../utils/helpers'
import moment from 'moment'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {Link} from 'react-router-dom'
import {useSnackbar} from 'notistack'

export default function ListComponent() {
  const tableClasses = newTableStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [selectedId, setSelectedId] = useState('')
  const [selectedName, setSelectedName] = useState('')
  const [openDelete, setOpenDelete] = useState(false)

  const {data, error, refetch} = useQuery(GET_LIST_CROSS_MENTORING, {
    context: {
      headers: {
        'X-Hasura-Role': 'holding-administrator',
      },
    },
  })

  const [deleteVersion] = useMutation(DELETE_CROSS_MENTORING)

  useEffect(() => {
    refetch()
  }, [])

  const handleDelete = () => {
    deleteVersion({variables: {id: selectedId}, ...holdingRolesContext})
      .then(() => {
        refetch()
        enqueueSnackbar('Data deleted', {
          variant: 'success',
          autoHideDuration: 1500,
        })
      })
      .catch(e => {
        enqueueSnackbar(
          'Delete failed, please try again later' + JSON.stringify(e),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  const handleClickOpen = (id, name) => {
    setSelectedId(id)
    setSelectedName(name)
    setOpenDelete(true)
  }

  const handleClose = () => {
    setOpenDelete(false)
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Cross Mentoring</Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={{
            pathname: `/holding-learning/add-version`,
            state: {
              active: 'add-version',
            },
          }}
        >
          Add Version
        </Button>
      </EnhancedToolbar>
      <Divider />
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontSize: 12}}
          options={{...NewTableOptions, paging: true, sorting: true}}
          columns={[
            {
              title: 'Name',
              field: 'name',
              render: row => (
                <BlackLinkBold
                  to={{
                    pathname: `/holding-learning/cross-mentoring-detail/${row.id}`,
                    state: {
                      active: 'cross-mentoring-detail',
                    },
                  }}
                >
                  <TextBlackBold>{row.name}</TextBlackBold>
                </BlackLinkBold>
              ),
            },
            {
              title: 'Period',
              field: 'period',
              render: row => (
                <TextBlackBold>
                  {moment(row.start_date).format('ll')} -{' '}
                  {moment(row.end_date).format('ll')}
                </TextBlackBold>
              ),
            },
            {
              title: 'Description',
              field: 'period',
              render: row => <TextBlackBold>{row.description}</TextBlackBold>,
            },
            {
              title: 'Status',
              field: 'status',
              render: row => (
                <Typography
                  style={
                    row.status === 'ACTIVE' ? {color: 'green'} : {color: 'red'}
                  }
                >
                  {capitalize(row.status)}
                </Typography>
              ),
            },
            {
              title: '',
              field: 'action',
              filtering: false,
              render: row => (
                <div style={{display: 'flex'}}>
                  <IconButton
                    component={Link}
                    to={{
                      pathname: `/holding-learning/${row.id}/edit-group-learning`,
                      state: {
                        active: 'edit-group-learning',
                      },
                    }}
                  >
                    <Icon color="primary">create</Icon>
                  </IconButton>
                  <IconButton onClick={() => handleClickOpen(row.id, row.name)}>
                    <Icon color="secondary">delete</Icon>
                  </IconButton>
                </div>
              ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={data.classroom_cross_mentoring_versions}
        />
      </div>
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        name={selectedName}
        mutation={handleDelete}
      />
    </PaperContainer>
  )
}
