import gql from 'graphql-tag'

export const GET_TAX_SETTINGS = gql`
  query($id: Int!) {
    company_addresses_by_pk(id: $id) {
      id
      office_code
      office_name
      address
      address_field
      address_misc_fields
      global_province {
        id
        name
      }
      global_city {
        id
        name
      }
      status
    }
  }
`

export const GET_TAX_OFFICE_ADDRESS = gql`
  query($company: uuid, $limit: Int, $offset: Int) {
    total: company_addresses_aggregate(
      where: {company: {_eq: $company}, status: {_eq: "ACTIVE"}}
    ) {
      aggregate {
        count
      }
    }

    company_addresses(
      limit: $limit
      offset: $offset
      where: {company: {_eq: $company}, status: {_eq: "ACTIVE"}}
    ) {
      id
      office_code
      office_name
      address
    }
  }
`
