import React, {useState, useLayoutEffect} from 'react'
import {useQuery} from '@apollo/react-hooks'
import {makeStyles, IconButton} from '@material-ui/core'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import AssigneePeopleComponent from './AssigneePeopleComponent '
import {
  GET_HOLDING_INSTRUCTOR,
  GET_HOLDING_USER,
} from '../../../../graphql/queries'
import {holdingRolesContext} from '../../../../utils/helpers'

const AssigneeFormComponent = props => {
  const {
    handleComponent,
    assignee,
    employees,
    setEmployees,
    setAssignee,
    setHasChange,
    mentee,
    dataMentee,
    groupLearning,
  } = props
  const classes = useStyles()
  const [checked, setChecked] = useState([])
  const [employeeSearch, setEmployeeSearch] = useState('')
  const [assigneeSearch, setAssigneeSearch] = useState('')
  const [loading, setLoading] = useState([])
  const numberOfChecked = items => intersection(checked, items).length
  const leftChecked = intersection(checked, employees)
  const rightChecked = intersection(checked, assignee)

  const {data: holdingUser, loading: loadingHoldingUser} = useQuery(
    GET_HOLDING_USER,
    {
      variables: {
        limit: 20,
        offset: 0,
        keyword: `%${employeeSearch}%`,
      },
      ...holdingRolesContext,
    }
  )

  const {
    data: assigneeCandidate,
    loading: assigneeCandidateLoading,
  } = useQuery(GET_HOLDING_INSTRUCTOR, {...holdingRolesContext})

  useLayoutEffect(() => {
    if (mentee) {
      if (groupLearning) {
        setEmployees(symmetricalDifference(dataMentee, assignee))
        setLoading(false)
      } else {
        if (holdingUser) {
          setEmployees(
            symmetricalDifference(holdingUser.people_work_placements, assignee)
          )
          setLoading(loadingHoldingUser)
        }
      }
    } else {
      if (assigneeCandidate) {
        setEmployees(
          symmetricalDifference(
            assigneeCandidate.people_work_placements,
            assignee
          )
        )
        setLoading(assigneeCandidateLoading)
      }
    }
  }, [assigneeCandidate, holdingUser])

  const onClickRight = () => {
    const currentValue = assignee.length
    setAssignee(assignee.concat(leftChecked))
    setEmployees(not(employees, leftChecked))
    setChecked(not(checked, leftChecked))
    const toTransfer = assignee.concat(leftChecked).map(a => {
      return {id: a.id}
    })
    handleComponent('assignments', toTransfer)
    if (setHasChange) {
      if (currentValue !== assignee.concat(leftChecked).length) {
        setHasChange(true)
      }
    }
  }
  const onClickLeft = () => {
    const currentValue = employees.length
    setEmployees(employees.concat(rightChecked))
    setAssignee(not(assignee, rightChecked))
    setChecked(not(checked, rightChecked))
    const toTransfer = assignee.concat(leftChecked).map(a => {
      return {id: a.id}
    })
    handleComponent('assignments', toTransfer)
    if (setHasChange) {
      if (currentValue !== employees.concat(rightChecked).length) {
        setHasChange(true)
      }
    }
  }

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }
  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <AssigneePeopleComponent
          title="People"
          people={employees.filter(item => {
            return item.global_user.name
              .toLowerCase()
              .includes(employeeSearch.toLowerCase())
          })}
          search={employeeSearch}
          setSearch={setEmployeeSearch}
          handleToggle={handleToggle}
          handleToggleAll={handleToggleAll}
          checked={checked}
          numberOfChecked={numberOfChecked}
          loading={loading}
        />
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className={classes.arrow}>
            <IconButton onClick={() => onClickRight()}>
              <ChevronRight />
            </IconButton>
          </div>
          <div className={classes.arrow}>
            <IconButton onClick={() => onClickLeft()}>
              <ChevronLeft />
            </IconButton>
          </div>
        </div>
        <AssigneePeopleComponent
          title="Assigned"
          people={assignee.filter(item => {
            return item.global_user.name
              .toLowerCase()
              .includes(assigneeSearch.toLowerCase())
          })}
          checked={checked}
          search={assigneeSearch}
          setSearch={setAssigneeSearch}
          handleToggle={handleToggle}
          handleToggleAll={handleToggleAll}
          numberOfChecked={numberOfChecked}
          loading={loading}
        />
      </div>
    </>
  )
}
export default AssigneeFormComponent
const useStyles = makeStyles(() => ({
  arrow: {border: '1px solid #eff2f4', borderRadius: '5px', margin: 20},
}))
function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}
function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}
function union(a, b) {
  return [...a, ...not(b, a)]
}
const symmetricalDifference = (list1, list2, isUnion = false) =>
  list1.filter(a => isUnion === list2.some(b => a.id === b.id))
