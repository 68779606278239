import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'

export const AppliesToContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, 158px);
  gap: 34px;
  margin-left: -1rem;
`

export const AppliesToChild = styled.li`
  color: #a9a8a8;
`

export const OvertimeApplyContainer = styled.div`
  margin-left: 2.3rem;
`
export const OvertimeNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`
export const OvertimeNameChild = styled.div`
  display: flex;
`
export const BlueLink = styled(Link)`
  color: #007fb2;
  text-decoration: none;
  margin: 18px;
`

export const RedButton = styled(Button)`
  color: #f44336;
  text-decoration: none;
`
