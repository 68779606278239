import React from 'react'

import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'
import {ItemWrapper} from '../../../DetailEmployeeStyles'

const row = (header, content) => (
  <tr>
    <th scope="row">{header}</th>
    <td>{content || '-'}</td>
  </tr>
)

const DetailMedicalRecord = props => {
  const {open, data, onClose} = props

  if (!data) {
    return null
  }

  return (
    <ModalDetail open={open} onClose={onClose} title="Medical Profile Detail">
      <div style={{padding: 24}}>
        <ItemWrapper>
          <h1 className="title">{data.medical_test_name}</h1>
          <table
            className="content"
            style={{'--row-gap': '16px', '--header-width': '30%'}}
          >
            {row('Reason', data.medical_test_reason)}
            {row(
              'Medical Condition Should Be Aware Of',
              data.additional_medical_conditions
            )}
            {row('Reason Being Hospitalized', data.hospitalized_reason)}
            {row('Disability', data.disabilities)}
            {row('Blood Type', data.blood_type)}
            {row('Blood Rhesus', data.blood_rhesus)}
            {row('Body Height', data.body_height)}
            {row('Body Weight', data.body_weight)}
            {row('Head Size', data.head_size)}
            {row('Clothes Size', data.clothes_size)}
            {row('Pants Size', data.pants_size)}
          </table>
        </ItemWrapper>
      </div>
    </ModalDetail>
  )
}

export default DetailMedicalRecord
