/* eslint-disable-next-line no-unused-vars */
import React, {useState} from 'react'
import {withRouter, Link} from 'react-router-dom'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import moment from 'moment'
import {Button, IconButton, Box, Typography} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
} from '../../../../../../../GlobalStyles'
import AssigneeContainer from '../../shared-component/form-asignee/AssigneeContainer'
import FormComponent from './FormComponent'
import ModalConfirmation from '../../../ModalConfirmation'
import helperStyle, {AntTabs, StyledTab} from '../../../../PayrollPageStyles'

import {GQL_FINANCE_OPTIONS} from '../../../../utils'
import {
  INSERT_PREMIUM_SETTING,
  UPDATE_PREMIUM_SETTING,
} from '../../../../../../../graphql/mutations'

const ModifyPremium = props => {
  const isEdit = props.location.state.active === 'settings-edit-premium'
  const {premiumData} = props.location.state
  const premiumId = premiumData ? props.location.state.premiumData.id : null

  const initialValue = premiumData
    ? {
        name: premiumData.premium_name || null,
        insurance: premiumData.insurance_company.id || null,
        premium_fee: premiumData.premium_fee || null,
        company_portion: premiumData.company_portion || null,
        employee_portion: premiumData.employee_portion || null,
        description: premiumData.description || '',
        age_expire: premiumData.age_expire || null,
        age_beneficiaries: premiumData.age_beneficiaries || null,
        premium_fields: {},
        effective_date: premiumData.effective_date || null,
        assignments: premiumData.finance_premium_assignments || [],
      }
    : {
        name: null,
        insurance: null,
        premium_fee: null,
        company_portion: null,
        employee_portion: null,
        description: '',
        age_expire: null,
        age_beneficiaries: null,
        premium_fields: {},
        effective_date: null,
        assignments: [],
      }

  const assigneeMapping = assignees => {
    if (assignees)
      return assignees.map(i =>
        Object.assign(
          {user_work_id: i.people_work_placement.id},
          {premium_id: premiumData.id}
        )
      )
  }

  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [modalType, setModalType] = useState('')
  const [tab, setTab] = useState(0)
  const [value, setValue] = useState(initialValue)
  const [forAssignment, setForAssignment] = useState(
    assigneeMapping(initialValue.assignments) || []
  )
  const [hasSubmit, setHasSubmit] = useState(false)

  const [employees, setEmployees] = useState([])
  const [assignee, setAssignee] = useState(initialValue.assignments)

  const handleChangeTab = (event, newTab) => {
    event.preventDefault()
    setTab(newTab)
  }

  const handleComponent = (target, input) => {
    if (isEdit) {
      if (target === 'assignments') {
        const newAssignment = input.map(i =>
          Object.assign(i, {premium_id: premiumData.id})
        )
        setForAssignment(newAssignment)
      }
    }

    setValue({
      ...value,
      [target]: input,
    })
  }

  const [addPremium] = useMutation(INSERT_PREMIUM_SETTING, GQL_FINANCE_OPTIONS)

  const [updatePremium] = useMutation(
    UPDATE_PREMIUM_SETTING,
    GQL_FINANCE_OPTIONS
  )

  const onCloseModal = () => {
    setModalType('')
    setShowModalConfirm(false)
  }

  const onSave = () => {
    setModalType('confirmation')
    setShowModalConfirm(true)
  }

  const onConfirm = () => {
    setHasSubmit(true)
    const variables = {
      name: String(value.name),
      insurance: Number(value.insurance),
      premium_fee: Number(value.premium_fee),
      company_portion: Number(value.company_portion),
      employee_portion: Number(value.employee_portion),
      age_expire: Number(value.age_expire),
      age_beneficiaries: Number(value.age_beneficiaries),
      premium_fields: {},
      effective_date: moment(value.effective_date).format('YYYY-MM-DD'),
      description: String(value.description),
      assignments: isEdit ? forAssignment : value.assignments,
    }
    if (isEdit) variables.id = premiumId

    if (
      !value.name ||
      value.insurance === null ||
      value.premium_fee === null ||
      value.premium_fee === 0 ||
      value.company_portion === null ||
      value.employee_portion === null ||
      value.employee_portion === null ||
      value.age_expire === null ||
      value.age_expire === 0 ||
      value.age_beneficiaries === null ||
      value.age_beneficiaries === 0 ||
      value.effective_date === null
    ) {
      enqueueSnackbar('Please input all required data', {
        variant: 'error',
      })
    } else if (isEdit) {
      updatePremium({
        variables,
        ...GQL_FINANCE_OPTIONS,
      })
        .then(() => {
          enqueueSnackbar('Premium Edited', {
            variant: 'success',
            autoHideDuration: 1500,
          })

          setTimeout(() => {
            props.history.push({
              pathname: '/financial/payroll/setting',
              state: {
                active: 'settings',
                tabValue: 4,
              },
            })
          }, 1500)
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
        .finally(() => {
          onCloseModal()
          setHasSubmit(false)
        })
    } else {
      addPremium({
        variables,
        ...GQL_FINANCE_OPTIONS,
      })
        .then(() => {
          enqueueSnackbar('Premium Added', {
            variant: 'success',
            autoHideDuration: 1500,
          })

          setTimeout(() => {
            props.history.push({
              pathname: '/financial/payroll/setting',
              state: {
                active: 'settings',
                tabValue: 4,
              },
            })
          }, 1500)
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
        .finally(() => {
          onCloseModal()
          setHasSubmit(false)
        })
    }
  }

  const onCancel = () => {
    setValue(initialValue)
    setTimeout(() => {
      props.history.push({
        pathname: '/financial/payroll/setting',
        state: {
          active: 'settings',
          tabValue: 4,
        },
      })
    }, 1500)
  }

  return (
    <>
      <ModalConfirmation
        open={showModalConfirm}
        onClose={onCloseModal}
        onConfirm={onConfirm}
        type={modalType}
        onCancel={onCancel}
      />
      <PaperContainer>
        <FormToolbar disableGutters>
          <FormTitleContainer>
            <Link
              to={{
                pathname: '/financial/payroll/setting',
                state: {active: 'settings', tabValue: 4},
              }}
            >
              <IconButton edge="end" aria-label="back">
                <ArrowBack />
              </IconButton>
            </Link>
            <AddEditTitle variant="h6" display="inline">
              {isEdit ? 'Edit' : 'Add'} Premium
            </AddEditTitle>
          </FormTitleContainer>
          <div>
            <Button
              size="large"
              className={classes.backButton}
              onClick={() => setShowModalConfirm(true)}
            >
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        </FormToolbar>

        <AntTabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          className={classes.tabs}
        >
          <StyledTab label="Detail" />
          <StyledTab label="Assignee" />
        </AntTabs>

        <TabPanel value={tab} index={0}>
          <FormComponent
            setValue={setValue}
            handleComponent={handleComponent}
            value={value}
            hasSubmit={hasSubmit}
          />
        </TabPanel>

        <TabPanel value={tab} index={1}>
          Assign To
          <AssigneeContainer
            handleComponent={handleComponent}
            employees={employees}
            setEmployees={setEmployees}
            assignee={assignee}
            setAssignee={setAssignee}
          />
        </TabPanel>
      </PaperContainer>
    </>
  )
}

export default withRouter(ModifyPremium)

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}
