import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  InputAdornment,
  TablePagination,
  TextField,
  Typography,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {
  AppliesToChild,
  AppliesToContainer,
  BlueIcon,
  FlexBetween,
  FlexCenters,
  GreyTypography,
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
} from '../../../GlobalStyles'
import NoDataListComponent from '../NoDataListComponent'
import {TablePaginationActions} from '../table/TableOptions'

export default function SelectDataPopup({
  titleText,
  open,
  onCancel,
  handleConfirm,
  saveText,
  cancelText,
  appliedText,
  search,
  setSearch,
  data,
  count,
  type,
  pagination,
  setPagination,
  setPrev,
  dialogWidth,
  altText,
}) {
  const [selected, setSelected] = useState([])

  const onSave = () => {
    handleConfirm(selected)
  }

  const handleCheck = (id, checked) => {
    let _ids = selected

    if (checked) {
      _ids = [..._ids, id]
    } else {
      _ids = _ids.filter(_id => _id !== id)
    }
    setSelected(_ids)
  }
  const handleChangePage = (e, page) => {
    e.preventDefault()
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowsPerPage = e => {
    setPagination({
      offset: 0,
      limit: parseInt(e.target.value),
    })
  }

  const renderBody = () => {
    return data?.length === 0 ? (
      <NoDataListComponent />
    ) : type === 'officeLocation' ? (
      data?.map((res, i) => {
        return (
          <FlexCenters style={{marginTop: 16}} key={i}>
            <Checkbox
              checked={selected.includes(res.id)}
              color="primary"
              value={res.id}
              onChange={event => {
                handleCheck(res.id, event.target.checked)
              }}
            />
            <div style={{marginLeft: 10}}>
              <Typography
                variant="body2"
                gutterBottom
                style={{fontWeight: 600}}
              >
                {res.value1}
              </Typography>
              <GreyTypography variant="body2">{res.value2}</GreyTypography>
            </div>
          </FlexCenters>
        )
      })
    ) : type === 'newPosition' ? (
      <AppliesToContainer>
        {data?.map((res, i) => {
          return (
            <AppliesToChild key={i}>
              {res.value1}
              <GreyTypography variant="body2">{res.value2}</GreyTypography>
            </AppliesToChild>
          )
        })}
      </AppliesToContainer>
    ) : null
  }

  useEffect(() => {
    // untuk mendapatkan data sebelumnya
    if (setPrev) {
      setSelected(setPrev)
    }
  }, [])

  return (
    <Dialog
      onClose={onCancel}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={dialogWidth || 'md'}
    >
      <ModalHeader>
        <div style={{display: 'flex'}}>
          <Typography style={{color: '#ffffff', alignSelf: 'center'}}>
            {titleText}
          </Typography>
        </div>
        <IconButton aria-label="close" onClick={onCancel}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContentEvent style={{marginTop: 16}}>
        {search !== false && (
          <TextField
            placeholder="Search"
            variant="outlined"
            size="small"
            fullWidth
            value={search}
            onChange={e => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BlueIcon>search</BlueIcon>
                </InputAdornment>
              ),
            }}
            style={{marginBottom: 30}}
          />
        )}
        <FlexBetween>
          <Typography variant="body2" style={{fontWeight: 600}} gutterBottom>
            {type === 'newPosition'
              ? appliedText
              : `Applied to ${selected.length} ${appliedText}`}
          </Typography>

          {/* <Typography
            variant="body2"
            style={{fontWeight: 600, cursor: 'pointer'}}
            color="secondary"
          >
            Select All
          </Typography> */}
        </FlexBetween>
        {altText && <Typography variant="body2">{altText}</Typography>}
        {/* Custom map item berdasarkan fitur */}
        <div style={{minHeight: '43vh'}}>{renderBody()}</div>
        <Divider style={{marginTop: 16}} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={pagination.limit}
          page={pagination.offset}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </ModalContentEvent>

      <DialogActions style={{padding: '0.8rem 1.6rem'}}>
        <Button style={{fontSize: 12}} onClick={onCancel}>
          {cancelText}
        </Button>
        <Button
          color="primary"
          // disabled={loading}
          size="large"
          style={{fontSize: 12, marginLeft: 12}}
          variant="contained"
          onClick={onSave}
        >
          {saveText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
