import React, {useState, useEffect} from 'react'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  WrapperContent,
} from '../../OrganizationPageStyles'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
  PaperContainer,
  EnhancedToolbar,
} from '../../../../GlobalStyles'
import {useSnackbar} from 'notistack'
import {
  Icon,
  IconButton,
  Button,
  Divider,
  TextField,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import {withRouter, Link} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  ADD_ORGAZNIATION_LEVEL,
  EDIT_ORGAZNIATION_LEVEL,
} from '../../../../graphql/mutations'
import {
  CHECK_CODE_ORGANIZATION_LEVEL,
  CHECK_COMPANY_ORTGANIZATION,
} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'

import ColorInputGroup from '../../../../components/form/ColorInputGroup'
import Loading from '../../../../components/loading/LoadingComponent'
import AddEditConfirm from '../../../shared-components/popup/AddEditPopup'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import {status_data} from '../../../shared-components/data/SharedData'

const AddEditGrade = props => {
  const {status, data} = props
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const dataJFname = data
    ? {
        code: data.code,
        name: data.name,
        description: data.description,
        company: COMPANY_ID,
        status: data.status,
        color_hex: data.color_hex,
      }
    : {
        code: '',
        name: '',
        description: '',
        // company: COMPANY_ID,
        status: 'Active',
        color_hex: null,
      }

  const [allDataFrom, setAllDataFrom] = useState(dataJFname)
  const [isError, setIsError] = useState(false)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [dialogCancel, setDialogCancel] = useState(false)
  const [dialogCantDel, setDialogCantDel] = useState(false)
  const [switchChecked, setSwitchChecked] = useState(true)
  const [skipQueryCheckCompany, setSkipQueryCheckCompany] = useState(true)

  const {
    data: dataCheckCompany,
    loading: loadingCheckCompany,
    error: errorCheckCompany,
  } = useQuery(CHECK_COMPANY_ORTGANIZATION, {
    variables: {orgLevel: data && data.id},
    skip: skipQueryCheckCompany,
  })

  const {data: checkCodeOrgLvl, error: checkCodeOrgLvlError} = useQuery(
    CHECK_CODE_ORGANIZATION_LEVEL,
    {
      variables: {
        company: COMPANY_ID,
        code: allDataFrom && allDataFrom.code,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const [addData] = useMutation(ADD_ORGAZNIATION_LEVEL)
  const [editData] = useMutation(EDIT_ORGAZNIATION_LEVEL)

  // const nameChecker =
  //   dataCheck &&
  //   dataCheck.company_organization_levels &&
  //   dataCheck.company_organization_levels.some(
  //     v => v.name.toLowerCase() === allDataFrom.name.toLowerCase()
  //   )

  useEffect(() => {
    if (data && (data.status === 'Active' || data.status === 'ACTIVE')) {
      setSwitchChecked(true)
    } else if (
      data &&
      (data.status === 'Inactive' || data.status === 'INACTIVE' || !data.status)
    ) {
      setSwitchChecked(false)
    }

    if (data) setSkipQueryCheckCompany(false)
  }, [data])

  if (
    (checkCodeOrgLvl === undefined && checkCodeOrgLvlError === undefined) ||
    loadingCheckCompany
  ) {
    return <Loading />
  } else if (
    checkCodeOrgLvlError !== undefined ||
    errorCheckCompany !== undefined
  ) {
    return (
      <div>
        {checkCodeOrgLvlError}
        {errorCheckCompany}
      </div>
    )
  }

  const hasOrganization =
    dataCheckCompany &&
    dataCheckCompany.total &&
    dataCheckCompany.total.aggregate &&
    dataCheckCompany.total.aggregate.count > 0

  const codeChecker =
    checkCodeOrgLvl &&
    checkCodeOrgLvl.company_organization_levels_aggregate &&
    checkCodeOrgLvl.company_organization_levels_aggregate.aggregate &&
    checkCodeOrgLvl.company_organization_levels_aggregate.aggregate.count > 0

  // const checkSameNameWithAdd = data && data.name === allDataFrom.name
  const checkSameCodeWithAdd =
    allDataFrom && allDataFrom.code && allDataFrom.code.length >= 1
      ? data && data.code === allDataFrom.code
      : false

  const checkCharaCode =
    allDataFrom && allDataFrom.code && allDataFrom.code.length > 30
  const checkCharaName =
    allDataFrom && allDataFrom.name && allDataFrom.name.length > 100

  const openDialogConfirm = () => {
    if (
      !allDataFrom.code ||
      !allDataFrom.name ||
      !allDataFrom.color_hex ||
      checkCharaCode ||
      checkCharaName ||
      // (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd) ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 2000)
    } else {
      dialogConfirm ? setDialogConfirm(false) : setDialogConfirm(true)
    }
  }

  const handleAddJFName = () => {
    if (status === 'Add') {
      const dataForAdd = {
        objects: [allDataFrom],
      }
      addData({variables: dataForAdd})
        .then(() => {
          enqueueSnackbar('Organization Level Added', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          props.history.push({
            pathname: '/organization/organization-level',
            state: {active: 'organization-level', searchable: true},
          })
        })
        .catch(() => {
          enqueueSnackbar(
            'Added Organization Level failed, please try again later',
            {
              variant: 'error',
              autoHideDuration: 1000,
            }
          )
        })
    } else if (status === 'Edit') {
      const dataForEdit = {
        id: data && data.id,
        companyId: COMPANY_ID,
        newCode: allDataFrom.code,
        newName: allDataFrom.name,
        newDescription: allDataFrom.description,
        newStatus: allDataFrom.status,
        newColor: allDataFrom.color_hex,
      }
      editData({variables: dataForEdit})
        .then(() => {
          enqueueSnackbar('Organization Level Edited', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          props.history.push({
            pathname: '/organization/organization-level',
            state: {active: 'organization-level', searchable: true},
          })
        })
        .catch(() => {
          enqueueSnackbar(
            'Edit Organization Level failed, please try again later',
            {
              variant: 'error',
              autoHideDuration: 1000,
            }
          )
        })
    }
  }

  const handleAlert = status => {
    dialogCancel ? setDialogCancel(false) : setDialogCancel(true)
    if (dialogCancel && status !== 'notNow') {
      props.history.push({
        pathname: '/organization/organization-level',
        state: {active: 'organization-level', searchable: true},
      })
    }
  }

  return (
    <OrganizationContainer>
      <PaperContainer className={classes.notViewScroll}>
        <EnhancedToolbar className={classes.paddingLeft0}>
          <WrapperContent>
            <IconButton
              component={Link}
              to={{
                pathname: '/organization/organization-level',
                state: {active: 'organization-level', searchable: true},
              }}
              edge="end"
              aria-label="back"
            >
              <Icon>arrow_back</Icon>
            </IconButton>
            <BigTextBlackBold className={classes.marginLeft10}>{`${
              status === 'Add' ? 'Tambah' : 'Ubah'
            } Level Organisasi`}</BigTextBlackBold>
          </WrapperContent>
          <div>
            <Button
              size="large"
              onClick={handleAlert}
              style={{marginRight: 12}}
            >
              Batal
            </Button>
            <Button
              className={classes.marginLeft10}
              variant="contained"
              color="primary"
              size="large"
              onClick={openDialogConfirm}
            >
              Simpan
            </Button>
          </div>
        </EnhancedToolbar>
        <Divider />
        <OrganizationContainer>
          <FormContainer id="top">
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>1.</TitleNumber>
                <TitleName>Kode Level Organisasi *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="levelCode"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="Tambah Kode Level Organisasi"
                  value={allDataFrom.code}
                  className={classes.helperMarginLeft0}
                  margin="normal"
                  error={
                    (!allDataFrom.code && isError) ||
                    checkCharaCode ||
                    (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
                  }
                  helperText={
                    !allDataFrom.code && isError
                      ? 'Bagian ini diperlukan'
                      : (codeChecker
                        ? !checkSameCodeWithAdd
                        : checkSameCodeWithAdd)
                      ? 'Kode Level Organisasi is sudah ada'
                      : checkCharaCode
                      ? 'Jumlah karakter maksimum adalah 30 karakter'
                      : 'Kode Level Organisasi harus unik'
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      code: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>2.</TitleNumber>
                <TitleName>Nama Level Organisasi *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgName"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="Tambah Nama Level Organisasi"
                  value={allDataFrom.name}
                  margin="normal"
                  className={classes.helperMarginLeft0}
                  error={
                    (!allDataFrom.name && isError) || checkCharaName
                    // ||(nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
                  }
                  helperText={
                    !allDataFrom.name && isError
                      ? 'Bagian ini diperlukan'
                      : checkCharaName
                      ? 'Jumlah karakter maksimum adalah 100 karakter'
                      : ''
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      name: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>3.</TitleNumber>
                <TitleName>Deskripsi</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgName"
                  style={{padding: '0px'}}
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="Tambah Deskripsi"
                  value={allDataFrom.description}
                  margin="normal"
                  className={classes.multilinePadding}
                  multiline
                  rows="5"
                  // error={
                  //   !allDataFrom.name && isError
                  // }
                  // helperText={}
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      description: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber id="parent">4.</TitleNumber>
                <TitleName>Status *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchChecked}
                      onChange={event => {
                        const actInc = event.target.checked
                          ? 'Active'
                          : 'Inactive'
                        setSwitchChecked(event.target.checked)
                        if (
                          hasOrganization &&
                          data &&
                          data.status === 'Active'
                        ) {
                          setDialogCantDel(!dialogCantDel)
                        } else {
                          setAllDataFrom({
                            ...allDataFrom,
                            status: actInc,
                          })
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={
                    switchChecked ? status_data.ACTIVE : status_data.INACTIVE
                  }
                />
              </FormChildInput>
            </FormChildContainer>

            <ColorInputGroup
              fieldNumber="5"
              fieldLabel="Warna Legenda *"
              tooltipMessage="Warna Legenda akan ditampilkan di Struktur Organisasi"
              value={allDataFrom.color_hex}
              error={isError && !allDataFrom.color_hex}
              onChange={value => {
                setAllDataFrom({...allDataFrom, color_hex: value})
              }}
            />
          </FormContainer>
        </OrganizationContainer>
      </PaperContainer>
      {hasOrganization ? (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type="Confirm"
          applied={dataCheckCompany && dataCheckCompany.company_organizations}
          mutation={handleAddJFName}
          appliedName="Organization Unit"
        />
      ) : (
        <AddEditConfirm
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          type={status}
          name={data && data.name}
          feature="Organization Level"
          mutation={handleAddJFName}
        />
      )}
      <AlertDialog
        open={dialogCancel}
        handleClose={handleAlert}
        feature={`Batal Untuk ${
          data ? 'Mengubah' : 'Menambahkan'
        } Level Organisasi?`}
        message={`Anda memiliki perubahan yang belum disimpan. Yakin ingin membatalkan ${
          data ? `perubahan ${data.name}` : 'penambahan Level Organisasi baru'
        }?`}
        positiveTitleButton="Ya, Batalkan"
        type="Yes, Cancel"
        status="notNow"
      />
      <AlertDialog
        open={dialogCantDel}
        handleClose={() => setDialogCantDel(!dialogCantDel)}
        feature="Tidak Dapat Diubah ke Tidak Aktif"
        message="Tidak dapat mengubah status menjadi Tidak Aktif karena sedang digunakan di Unit Organisasi"
        positiveTitleButton="Konfirmasi"
        type="Confirm"
        status="cancel"
        cancelAction={setSwitchChecked}
        reverseCancel={true}
      />
    </OrganizationContainer>
  )
}

export default withRouter(AddEditGrade)
