import gql from 'graphql-tag'

export const DELETE_KPI_WEIGHT = gql`
  mutation updateKPIWeight($id: Int!) {
    update_kpi_weight_units(
      _set: {deleted_at: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const DELETE_MULTIPLE_KPI_WEIGHT = gql`
  mutation updateKPIWeight($id: [Int!]!) {
    update_kpi_weight_units(
      _set: {deleted_at: "now()"}
      where: {id: {_in: $id}}
    ) {
      affected_rows
    }
  }
`
