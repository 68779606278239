import gql from 'graphql-tag'

// Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/Model/LIST%20MODEL.md
export const LIST_MODEL = gql`
  query listModel($company: uuid, $search: String, $offset: Int, $limit: Int) {
    total: company_job_profiles_aggregate(
      where: {
        company: {_eq: $company}
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
    company_job_profiles(
      where: {
        company: {_eq: $company}
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      title
      company_organization {
        id
        code
        name
      }
      company_employee_rank {
        id
        code
        name
      }
      company_job_profile_competencies_aggregate(
        where: {
          competency_version_dictionary: {
            competency_version: {status: {_eq: "ACTIVE"}}
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

// Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/Model/DETAIL%20MODEL.md
export const DETAIL_MODEL = gql`
  query($job_profile: Int!, $search: String, $offset: Int, $limit: Int) {
    total: competency_model_items_aggregate(
      where: {
        job_profile: {_eq: $job_profile}
        competency_version_dictionary: {
          competency_version: {status: {_eq: "ACTIVE"}}
          competency_dictionary: {
            _or: [
              {dictionary_id: {_ilike: $search}}
              {search_name: {_ilike: $search}}
            ]
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    competency_model_items(
      where: {
        job_profile: {_eq: $job_profile}
        competency_version_dictionary: {
          competency_version: {status: {_eq: "ACTIVE"}}
          competency_dictionary: {
            _or: [
              {dictionary_id: {_ilike: $search}}
              {search_name: {_ilike: $search}}
            ]
          }
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      competency_version_dictionary {
        id
        competency_dictionary {
          id
          dictionary_id
          description
          type
          name
          competency_category {
            id
            code
            name
          }
        }
      }
      level
    }
  }
`
