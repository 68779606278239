import React from 'react'
import {
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
} from '../../../../GlobalStyles'
import {
  TextField,
  Popover,
  Typography,
  InputAdornment,
  Button,
} from '@material-ui/core'
import {TextFieldStyled, WrapperDisable} from '../EmployeePageStyles'
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {GET_GLOBAL_PHONE_CODE} from '../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'

export default function ComponentInputPhone(props) {
  const {
    number,
    name,
    error,
    id,
    setPhoneCode,
    phoneCode,
    disabled,
    ...other
  } = props

  const {data} = useQuery(GET_GLOBAL_PHONE_CODE, {
    wlb_skipPatch: true,
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  const handleClick = ({code, id}, popupState) => {
    setPhoneCode(code, id)
    popupState.setOpen(false)
  }

  return (
    <FormChildContainer id={id}>
      <FormChildTitle>
        <TitleNumber>{number}.</TitleNumber>
        <TitleName>{name}</TitleName>
      </FormChildTitle>
      <FormChildInput>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <PopupState variant="popover" popupId="demo-popup-popover">
            {popupState => (
              <>
                <WrapperDisable widthAuto>
                  <TextFieldStyled
                    style={{
                      marginTop: 10,
                      marginBottom: 8,
                      width: 110,
                      marginRight: 14,
                      cursor: 'pointer',
                    }}
                    placeholder="Country Code"
                    size="small"
                    variant="outlined"
                    value={phoneCode}
                    helperText={error ? ' ' : null}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          style={{cursor: 'pointer', paddingRight: 0}}
                          position="end"
                        >
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      ),
                    }}
                    disabled={disabled}
                    {...bindTrigger(popupState)}
                  />
                </WrapperDisable>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '8px 0px',
                    }}
                  >
                    {data &&
                      data.global_phone_code.map((res, i) => (
                        <Button
                          key={i}
                          onClick={() =>
                            handleClick(
                              {code: res.code, id: res.id},
                              popupState
                            )
                          }
                          style={
                            phoneCode === res.code
                              ? {
                                  backgroundColor: 'rgba(0, 0, 0, 0.14)',
                                  justifyContent: 'flex-start',
                                  cursor: 'pointer',
                                  borderRadius: 0,
                                }
                              : {
                                  cursor: 'pointer',
                                  justifyContent: 'flex-start',
                                }
                          }
                        >
                          <Typography style={{fontSize: 14}}>
                            {res.country} ({res.code})
                          </Typography>
                        </Button>
                      ))}
                  </div>
                </Popover>
              </>
            )}
          </PopupState>
          <WrapperDisable>
            <TextField
              fullWidth
              size="small"
              margin="normal"
              variant="outlined"
              error={error}
              helperText={error ? 'This field is required' : null}
              disabled={disabled}
              {...other}
            />
          </WrapperDisable>
        </div>
      </FormChildInput>
    </FormChildContainer>
  )
}
