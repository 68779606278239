import gql from 'graphql-tag'

export const UPDATE_LEAVE_BALANCE = gql`
  mutation(
    $leave_subpolicy_id: Int
    $user_work_id: Int
    $period_year: Int
    $carry_over: float8
    $quota: float8
    $taken_current: float8
    $taken_carry_over: float8
  ) {
    insert_people_work_leave_quotas_one(
      object: {
        leave_subpolicy_id: $leave_subpolicy_id
        user_work_id: $user_work_id
        period_year: $period_year
        carry_over: $carry_over
        quota: $quota
        taken_current: $taken_current
        taken_carry_over: $taken_carry_over
      }
      on_conflict: {
        constraint: people_work_carry_over_user_work_id_period_year_key
        update_columns: [
          leave_subpolicy_id
          user_work_id
          period_year
          carry_over
          quota
          taken_current
          taken_carry_over
        ]
      }
    ) {
      id
    }
  }
`

export const UPDATE_LEAVE_BALANCE_ACCRUAL = gql`
  mutation(
    $leave_subpolicy_id: Int
    $user_work_id: Int
    $period_year: Int
    $carry_over: float8
    $quota: float8
    $taken_current: float8
    $taken_carry_over: float8
    $max_monthly_accrual_quota: float8
  ) {
    insert_people_work_leave_quotas_one(
      object: {
        leave_subpolicy_id: $leave_subpolicy_id
        user_work_id: $user_work_id
        period_year: $period_year
        carry_over: $carry_over
        quota: $quota
        taken_current: $taken_current
        taken_carry_over: $taken_carry_over
        monthly_accrual_quota: $quota
        max_monthly_accrual_quota: $max_monthly_accrual_quota
      }
      on_conflict: {
        constraint: people_work_carry_over_user_work_id_period_year_key
        update_columns: [
          leave_subpolicy_id
          user_work_id
          period_year
          carry_over
          quota
          taken_current
          taken_carry_over
          monthly_accrual_quota
          max_monthly_accrual_quota
        ]
      }
    ) {
      id
    }
  }
`
