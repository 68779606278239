import React, {Fragment, useState} from 'react'
import {
  Grid,
  TextField,
  MenuItem,
  Icon,
  makeStyles,
  Avatar,
  Typography,
  Card,
  Button,
  InputAdornment,
  Radio,
  Paper,
  IconButton,
} from '@material-ui/core'
import {
  GreyTypography,
  FromButton,
  FlexBetween,
  GenerateTooltip,
  TextHelper,
  LineClamp,
} from '../../../../../GlobalStyles'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Select, {components} from 'react-select'
import {useQuery, useMutation, useApolloClient} from '@apollo/react-hooks'
import _ from 'lodash'
import {
  CHECK_GROUP_TASK,
  GET_DROPDOWN_ASPECT,
  // GET_COUNT_TARGET_GOAL,
  GET_DROPDOWN_WEIGHT,
  GET_LIST_GROUP,
} from '../../../../../graphql/queries'
import {
  ADD_TASK,
  UPDATE_REPORTER_TASK,
} from '../../../../../graphql/mutations/task/addTask.query'
import {
  ADD_NEW_GROUP,
  EDIT_TASK,
} from '../../../../../graphql/mutations/task/editTask.query'
import {
  COMPANY_ID,
  TOKEN,
  UPLOAD_URL,
  USER_ID,
} from '../../../../../utils/globals'
import axios from 'axios'
import TooltipAttachmentGoal from '../../TooltipAttachmentGoal'
import {
  getTypeFile,
  handleChangeAttachment,
  selectProps,
  trimString,
} from '../../../../../utils/helpers'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {
  isError,
  isErrorDetected,
  IntersectionOfDate,
  goalTypes,
  // isErrorScoring,
  // renderErrorStretch,
} from './validate'
import {useSnackbar} from 'notistack'
import UploadButton from '../../../../../components/attachment/UploadButton'
import AttachmentItem from '../../../../../components/attachment/AttachmentItem'
import {ChromePicker} from 'react-color'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import {
  FILE_TYPE_UPLOAD,
  priorities,
  visibilitys,
} from '../../../../shared-components/data/SharedData'
import {GET_DROPDOWN_EMP_GOALS_REPORTER} from '../../../../../graphql/queries/task/getListEmp.query'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {customGroupSelect, FlexCenter, PsmGrey} from '../modalStyles'
import {DELETE_TASK_GROUP} from '../../../../../graphql/mutations'

const prevDropdownData = val => ({
  avatar: val.avatar,
  value: val.name,
  label: val.id,
  pos: val.people_work_placements?.[0]?.company_job_profile?.title,
})

const useStyles = makeStyles(() => ({
  outlinedInput: {
    padding: '10px 14px',
  },
  nameField: {marginBottom: 13},
  radioRoot: {padding: 0},
  icon: {
    borderRadius: 5,
    padding: 10,
  },
  checkedIcon: {
    borderRadius: 5,
    color: '#fff',
  },
  list: {display: 'flex', margin: '10px 0'},
  textGrey: {color: '#a9a8a8'},
  spacer: {marginRight: 10},
  selectedEmp: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '20rem',
    overflow: 'auto',
  },
  cardEmp: {
    height: 71,
    width: 330,
    margin: '0px 10px 10px 0px',
    padding: 9,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
  },
  flexAbsolute: {
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
  },
  wrapperReporter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  nameReporter: {
    fontSize: 14,
    marginLeft: '1rem',
  },
  rootReporterSelect: {
    minWidth: 241,
    backgroundColor: '#fff',
    marginRight: 12,
  },
  dropdownWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarDropdown: {
    width: 30,
    height: 30,
    marginRight: 12,
  },
  nameDropdown: {
    fontSize: 14,
    color: '#000',
    fontWeight: 600,
  },
  positionDropdown: {
    fontSize: 14,
    color: '#a9a8a8',
    marginTop: 4,
  },
  uploadButton: {
    marginTop: 16,
  },
  attachmentItem: {
    maxWidth: 500,
    marginTop: 24,
  },
  disabled: {
    backgroundColor: '#F7F8F9',
    color: '#a9a8a8',
  },
  select: {
    marginTop: '7px !important',
  },
  buttonCancel: {
    textTransform: 'none',
    fontWeight: 600,
    color: '#a9a8a8',
    height: 45,
    width: 100,
  },
  buttonSave: {
    textTransform: 'none',
    backgroundColor: '#014a62',
    height: 45,
    width: 100,
  },
  marginTextHelper: {
    marginLeft: '0px !important',
    marginTop: '5px !important',
  },
}))

const AddTask = props => {
  const customSelect = {
    control: provided => ({
      ...provided,
      width: '100%',
      outline: 'none',
      marginTop: 8,
      marginBottom: 4,
      minHeight: 55,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: '#055469',
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#fff',
    }),
    multiValueRemove: base => ({
      ...base,
      color: '#ffff',
      '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
    }),
    menu: base => ({
      ...base,
      maxWidth: '100%',
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: '#bfbfbf',
      }
    },
  }

  const classes = useStyles()
  const client = useApolloClient()
  const disabledStyle = {classes: {disabled: classes.disabled}}
  const {enqueueSnackbar} = useSnackbar()
  // Variable
  const {
    idGoals,
    dataKpi,
    onBack,
    dataEdit,
    setDataCreate,
    dataCreate,
    isErrorTask,
    dataDateGoal = null,
  } = props

  const editSelected = []
  if (dataEdit) {
    dataEdit.performance_task_assignments.map(res => {
      editSelected.push({
        idx: 0,
        label: res.global_user?.id,
        value: res.global_user?.name,
        avatar: res.global_user?.avatar,
        pos:
          res.global_user?.people_work_placements?.[0]?.company_job_profile
            ?.title,
      })
    })
  }

  const DATA_EDIT_LABEL = dataEdit
    ? dataEdit?.performance_group
      ? [
          {
            idGroup: dataEdit.performance_group.id,
            value: dataEdit.performance_group.name,
            label: dataEdit.performance_group.name,
            color: dataEdit.performance_group.color,
          },
        ]
      : [
          {
            idGroup: null,
            value: 'Tidak Ada',
            label: 'Tidak Ada',
            color: '#e5e5e5',
          },
        ]
    : []

  const [selectedRep, setSelectedRep] = useState(editSelected)
  const [selectedGroup, setSelectedGroup] = useState(DATA_EDIT_LABEL)
  const [label, setLabel] = useState(false)
  const [idLabel, setIdLabel] = useState('')
  const [color, setColor] = useState('#055469')
  const [colorPicker, setColorPicker] = useState(false)
  const [labelName, setLabelName] = useState('')
  const [reporter, setReporter] = useState(
    dataEdit &&
      prevDropdownData(dataEdit.ReporterUser || dataEdit.created_by_user)
  )
  const INITIAL_ALERT_LABEL = {
    canDelete: true,
    open: false,
    idLabel: null,
  }
  const [alertLabel, setAlertLabel] = useState(INITIAL_ALERT_LABEL)

  const [colorOptions, setColorOptions] = useState([
    {color: '#ef4d5e'},
    {color: '#a9a8a8'},
    {color: '#ffa000'},
    {color: '#007fb2'},
    {color: '#4caf50'},
    {color: '#a35a31'},
    {color: '#874caf'},
    {color: '#f04ba0'},
  ])

  const [error, setError] = useState(false)
  // const [errorScoringNature, setErrorScoringNature] = useState(false)
  const [data, setData] = useState({
    goal: idGoals,
    name: dataEdit?.name ?? null,
    description: dataEdit?.description ?? null,
    target: dataEdit?.target ?? null,
    target_stretch: dataEdit?.target_stretch ?? null,
    weight: dataEdit?.weight ?? null,
    aspect: dataEdit
      ? dataEdit?.kpi_aspect?.id || dataKpi?.kpi_aspect?.id || null
      : null,
    priority: dataEdit?.priority ?? null,
    visibility: dataEdit?.visibility ?? null,
    method_type: dataEdit?.method_type ?? null,
    startdate: dataEdit?.startdate ?? null,
    enddate: dataEdit?.enddate ?? null,
    group: dataEdit?.group ?? null,
    progress: dataEdit?.progress ?? null,
    metric: dataEdit?.metric || dataKpi?.formula || '-',
    // status: dataEdit ? (dataEdit.status ? dataEdit.status : 'TODO') : 'TODO',
    performance_task_assignments: {data: []},
    attachment: dataEdit?.attachment ?? [],
    target_unit: dataEdit
      ? dataEdit?.kpi_weight_unit?.id || dataKpi?.kpi_weight_unit?.id || null
      : null,
  })
  const [weightDropdown, setWeightDropdown] = useState(
    dataEdit
      ? {
          value:
            dataEdit?.kpi_weight_unit?.id || dataKpi.kpi_weight_unit.id || '-',
          label:
            dataEdit?.kpi_weight_unit?.name ||
            dataKpi.kpi_weight_unit.name ||
            '-',
        }
      : null
  )

  const [aspectDropdown, setAspectDropdown] = useState(
    dataEdit
      ? {
          value: dataEdit?.kpi_aspect?.id || dataKpi.kpi_aspect.id || '-',
          label: dataEdit?.kpi_aspect?.name || dataKpi.kpi_aspect.name || '-',
        }
      : null
  )
  const [mutationAdd] = useMutation(ADD_TASK)
  const [mutationEdit] = useMutation(EDIT_TASK)
  const [updateReporter] = useMutation(UPDATE_REPORTER_TASK)
  const [addGroup] = useMutation(ADD_NEW_GROUP)
  const [deleteTaskGroup] = useMutation(DELETE_TASK_GROUP, {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-staff',
      },
    },
  })

  const [disabledButton, setDisabledButton] = useState(false)
  const [searchRec, setSearchRec] = useState('')
  const [searchDropdown, setSearchDropdown] = useState({
    weight: '',
    aspect: '',
  })

  const [tData, setTData] = useState([])

  const {data: dataEmp, error: errorEmp, loading: loadingEmp} = useQuery(
    GET_DROPDOWN_EMP_GOALS_REPORTER,
    {
      variables: {
        company: COMPANY_ID,
        limit: 20,
        search: `%${searchRec}%`,
      },
    }
  )

  const {
    data: dataWeight,
    error: errorWeight,
    loading: loadingWeight,
  } = useQuery(GET_DROPDOWN_WEIGHT, {
    variables: {
      companyId: COMPANY_ID,
      search: `%${searchDropdown.weight}%`,
      limit: 20,
    },
    fetchPolicy: 'cache-and-network',
  })

  const {
    data: dataAspect,
    error: errorAspect,
    loading: loadingAspect,
  } = useQuery(GET_DROPDOWN_ASPECT, {
    variables: {
      search: `%${searchDropdown.aspect}%`,
      limit: 20,
    },
    fetchPolicy: 'cache-and-network',
  })

  const {data: dataGroup, refetch: refetchGroup} = useQuery(GET_LIST_GROUP)

  if (errorEmp || errorWeight || errorAspect) {
    console.error(errorEmp || errorWeight || errorAspect)
  }

  const handleChange = onBack ? setData : setDataCreate
  const values = onBack ? data : dataCreate
  const isErrors = onBack ? error : isErrorTask
  const minDate =
    dataDateGoal?.startDate || dataEdit?.performance_goal?.startdate
  const maxDate = dataDateGoal?.endDate || dataEdit?.performance_goal?.enddate
  const isEditedDate = dataEdit && values.startdate !== dataEdit.startdate
  const isOffsideDate = IntersectionOfDate(minDate, values?.startdate, maxDate)
  const idTask = dataEdit?.id || null

  const _dataWeight = dataWeight?.kpi_weight_units?.map(options => ({
    value: options.id,
    label: options.name,
  }))

  const _dataApsect = dataAspect?.kpi_aspects?.map(options => ({
    value: options.id,
    label: options.name,
  }))

  const listEmp = dataEmp?.people_work_placements.map(res => {
    return {
      label: res.global_user.id,
      value: res.global_user.name,
      avatar: res.global_user.avatar,
      pos: res.company_job_profile && res.company_job_profile.title,
    }
  })

  const options = [
    {
      idGroup: null,
      value: 'Tidak Ada',
      label: 'Tidak Ada',
      color: '#A9A8A8',
      user: 'DEFAULT',
    },
  ]
  const tempDataOptions = dataGroup?.performance_groups?.map(res => {
    const option = {
      idGroup: res.id,
      value: res.name,
      label: res.name,
      color: res.color,
      user: res.user,
    }
    options.push(option)
  })

  const empMenu = option => {
    return (
      <div className={classes.list}>
        <div className={classes.spacer}>
          <Avatar src={option.avatar} alt={option.value}></Avatar>
        </div>
        <div>
          <Typography variant="body2">{option.value}</Typography>
          <Typography variant="body2" className={classes.textGrey}>
            {option.pos}
          </Typography>
        </div>
      </div>
    )
  }

  const handleDeleteLabel = async idLabel => {
    const {data, error} = await client.query({
      query: CHECK_GROUP_TASK,
      variables: {group: idLabel},
    })

    if (error) {
      return console.error({error})
    }

    const lengthLabel = data?.performance_tasks?.length
    const isExsist = lengthLabel > 0

    handleChange({...values, group: null})
    setSelectedGroup([])
    setAlertLabel({
      canDelete: !isExsist,
      idLabel: idLabel,
      open: true,
    })
  }

  const onDeleteLabel = () => {
    deleteTaskGroup({variables: {id: alertLabel.idLabel}})
      .then(() => {
        enqueueSnackbar('Success delete new label', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        setAlertLabel(INITIAL_ALERT_LABEL)
        refetchGroup()
      })
      .catch(() => {
        enqueueSnackbar('Delete data error, please try again', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const groupMenu = option => {
    const canDelete = option.user !== 'DEFAULT'
    return (
      <FlexBetween>
        <div style={{width: '80%', display: 'flex'}}>
          <div
            style={{
              background: option.color,
              width: 0,
              borderRadius: 5,
              padding: 10,
              marginRight: 12,
            }}
          />
          <LineClamp
            lineClamp={1}
            style={{
              alignSelf: 'center',
              fontSize: 12,
              color: '#a9a8a8',
            }}
          >
            {option.label}
          </LineClamp>
        </div>
        {canDelete && (
          <IconButton
            style={{padding: 0}}
            onClick={() => handleDeleteLabel(option.idGroup)}
          >
            <DeleteOutlineIcon style={{color: '#EF4D5E'}} />
          </IconButton>
        )}
      </FlexBetween>
    )
  }

  const renderValue = option => {
    return (
      <FlexCenter>
        <div
          style={{
            background: option.color || '#A9A8A8',
            width: 0,
            borderRadius: 5,
            padding: 10,
            marginRight: 12,
          }}
        />
        <PsmGrey>{trimString(option.label || 'Tidak Ada', 50)}</PsmGrey>
      </FlexCenter>
    )
  }

  const handleChangeValues = event => {
    handleChange({...values, [event.target.name]: event.target.value})
  }

  const ChangeDFrom = date => {
    handleChange({...values, startdate: date, enddate: null})
  }
  const ChangeDTo = date => {
    handleChange({...values, enddate: date})
  }

  const DropdownIndicator = props => {
    const {isArrow, isDisabled} = props.selectProps
    return (
      <components.DropdownIndicator {...props}>
        <Icon style={{color: isArrow && !isDisabled ? '#757575' : '#a9a8a8'}}>
          {isArrow ? 'arrow_drop_down' : 'search'}
        </Icon>
      </components.DropdownIndicator>
    )
  }

  const handleChangeGroup = dataGroups => {
    handleChange({
      ...values,
      group: dataGroups.idGroup,
    })
    setSelectedGroup([
      {
        idGroup: dataGroups.idGroup,
        value: dataGroups.label,
        label: dataGroups.label,
        color: dataGroups.color,
      },
    ])
  }

  const SingleValue = props => (
    <components.SingleValue
      {...props}
    >{`${props?.data?.value}, ${props?.data?.pos}`}</components.SingleValue>
  )

  const handleChangeRep = (val, i) => {
    const dataRep = {idx: i, ...val}
    const isDuplicate = _.some(selectedRep, dataRep)
    if (!isDuplicate) {
      setSelectedRep(oldArray => [...oldArray, dataRep])
      const newData = [...selectedRep, dataRep]
      handleRecipient(newData)
    }
  }

  const handleDel = (idTask, name) => {
    const aryName = selectedRep

    const filter = {
      idx: idTask,
      value: name,
    }
    const wbDel = aryName.filter(function(item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] !== filter[key]) return false
      }
      return true
    })
    const arrAfterDel = aryName.filter(e => e !== wbDel[0])
    setSelectedRep(arrAfterDel)
    handleRecipient(arrAfterDel)
  }

  const handleAddLabel = () => {
    setLabel(true)
    setIdLabel(idTask)
  }

  const handleChangeFile = e => {
    const tempFile = Array.from(e.target.files)
    const fileCount =
      (tempFile?.length ?? 0) + (values?.attachment?.length ?? 0)

    if (fileCount > 10) {
      enqueueSnackbar('Maximal 10 attachments', {
        variant: 'error',
        autoHideDuration: 1000,
      })
    } else {
      tempFile.forEach(val => {
        if (!FILE_TYPE_UPLOAD.includes(getTypeFile(val.name))) {
          enqueueSnackbar('There are file types that are not allowed', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        } else if (val.size >= 1024 * 1024 * 25) {
          enqueueSnackbar('Max file size is 25MB', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        } else {
          handleChangeAttachment(
            val,
            setDisabledButton,
            handleChange,
            axios,
            UPLOAD_URL,
            TOKEN
          )
        }
      })
    }
  }

  const handleDeleteAttachButton = i => {
    const newState = [...values.attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    handleChange(prevState => ({...prevState, attachment: newState}))
  }

  const handleChangeNameFileAttBtn = (e, i) => {
    const newState = [...values.attachment]
    newState[i].name = e.target.value
    handleChange(prevState => ({...prevState, attachment: newState}))
  }

  const handleRecipient = data => {
    setTData([])
    const tempData = data || selectedRep
    tempData.map(res => {
      return tData.push({
        user: res.label,
      })
    })
    handleChange({...values, performance_task_assignments: {data: tData}})
  }

  const triggerSnackbar = success => {
    enqueueSnackbar(success ? 'Success save data' : 'Something went wrong', {
      variant: success ? 'success' : 'error',
      autoHideDuration: 1000,
    })
    if (success) {
      props.refetch()
      onBack()
    }
  }

  const handleSave = () => {
    handleRecipient()

    const object = values
    if (dataEdit === null) {
      object.reporter = USER_ID
      mutationAdd({
        variables: {object},
      })
        .then(() => triggerSnackbar(true))
        .catch(err => {
          triggerSnackbar(false)
          console.error(err)
        })
    } else {
      object.id = dataEdit.id
      const task = []
      values.performance_task_assignments.data.map(res => {
        task.push({task: dataEdit.id, user: res.user})
      })
      object.assignment = task
      delete object.performance_task_assignments
      delete object.method_type
      delete object.metric
      delete object.target_unit

      mutationEdit({
        variables: object,
      })
        .then(() => {
          updateReporter({
            variables: {
              id: dataEdit.id,
              reporter: reporter.label,
            },
          })
            .then(() => triggerSnackbar(true))
            .catch(err => {
              triggerSnackbar(false)
              console.error(err)
            })
        })
        .catch(err => {
          triggerSnackbar(false)
          console.error(err)
        })
    }
  }

  const onSaveLabel = () => {
    const data = {name: labelName, color: color}
    const variable = {data}
    addGroup({
      variables: variable,
      context: {
        headers: {
          'X-Hasura-Role': 'organization-staff',
        },
      },
    })
      .then(() => {
        enqueueSnackbar('Success add new label', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        setLabelName('')
        setLabel(false)
        refetchGroup()
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const handleValidate = () => {
    if (
      isErrorDetected(values) ||
      values?.name?.length > 255 ||
      values?.metric?.length > 250
    ) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    } else {
      handleSave()
    }
  }

  const stylesSelect = {
    control: provided => ({
      ...provided,
      borderColor: dataEdit ? '#b1b3b5' : provided.borderColor,
      backgroundColor: dataEdit ? '#F7F8F9' : provided.backgroundColor,
      '&:hover': {borderColor: '#000'},
      marginTop: 7,
      minHeight: 55,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: '#bfbfbf',
      }
    },
  }

  return (
    <div style={{padding: onBack ? 30 : 0}}>
      <Grid container spacing={3}>
        <Grid item sm={onBack ? 12 : 9}>
          <GreyTypography variant="body2" className={classes.nameField}>
            Nama {GLOBAL_GLOSSARY.performance.Task}*
          </GreyTypography>
          <TextField
            className={classes.nameField}
            variant="outlined"
            placeholder={`Nama ${GLOBAL_GLOSSARY.performance.Task}`}
            fullWidth
            onChange={handleChangeValues}
            value={values.name}
            name={'name'}
            error={
              isError(isErrors, values.name) ||
              (values?.name?.length > 255 && isErrors)
            }
            helperText={
              isError(isErrors, values.name)
                ? 'Bagian ini diperlukan'
                : values?.name?.length > 255 && isErrors
                ? "Name can't be more than 255 characters"
                : ''
            }
          />
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={onBack ? 3 : 5}>
            <GreyTypography variant="body2" className={classes.nameField}>
              Visibilitas*
            </GreyTypography>
            <TextField
              select
              variant="outlined"
              placeholder="Conduct Usability Testing for Performance Module"
              fullWidth
              onChange={handleChangeValues}
              value={values.visibility || ''}
              name={'visibility'}
              error={isError(isErrors, values.visibility)}
              helperText={
                isError(isErrors, values.visibility) && 'Bagian ini diperlukan'
              }
              SelectProps={selectProps(values.visibility || '')}
            >
              <MenuItem value="" disabled>
                Pilih Visibilitas
              </MenuItem>
              {visibilitys.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={onBack ? 3 : 5}>
            <GreyTypography variant="body2" className={classes.nameField}>
              Tipe {GLOBAL_GLOSSARY.performance.Task}
            </GreyTypography>
            <TextField
              select
              variant="outlined"
              fullWidth
              disabled={dataEdit}
              onChange={handleChangeValues}
              value={values.method_type || dataEdit?.method_type || ''}
              name={'method_type'}
              InputProps={disabledStyle}
              error={isError(isErrors, values.method_type)}
              helperText={
                isError(isErrors, values.method_type) && 'Bagian ini diperlukan'
              }
              SelectProps={selectProps(
                values.method_type || dataEdit?.method_type || ''
              )}
            >
              <MenuItem value="" disabled>
                Pilih Tipe Rencana Aksi
              </MenuItem>
              {goalTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={3} className={classes.nameField}>
            <Grid item sm={onBack ? 3 : 5} style={{paddingRight: 0}}>
              <GreyTypography variant="body2" className={classes.nameField}>
                Label
              </GreyTypography>
              <Select
                value={selectedGroup}
                options={options || tempDataOptions}
                onChange={value => handleChangeGroup(value)}
                styles={customGroupSelect}
                placeholder="Pilih Label"
                getOptionLabel={groupMenu}
                isArrow={true}
                components={{
                  SingleValue: ({data}) => renderValue(data),
                  Menu: props => (
                    <Fragment>
                      <components.Menu {...props}>
                        {props.children}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 16,
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              textTransform: 'capitalize',
                              width: '100%',
                            }}
                            onClick={handleAddLabel}
                          >
                            Buat Label Baru
                          </Button>
                        </div>
                      </components.Menu>
                    </Fragment>
                  ),
                  DropdownIndicator: props => DropdownIndicator(props),
                }}
              />
              <div style={{marginTop: 25}}>
                <GreyTypography variant="body2" className={classes.nameField}>
                  Aspek {GLOBAL_GLOSSARY.performance.Goal_short}
                </GreyTypography>
                <Select
                  className={classes.select}
                  id="goal-aspect"
                  options={_dataApsect}
                  value={aspectDropdown}
                  placeholder="Pilih Aspek RHK"
                  isLoading={loadingAspect}
                  isDisabled={dataEdit}
                  components={{DropdownIndicator}}
                  isArrow={true}
                  onInputChange={dataSearchDropdown =>
                    setSearchDropdown({
                      ...searchDropdown,
                      aspect: dataSearchDropdown,
                    })
                  }
                  onChange={event => {
                    handleChange({
                      ...values,
                      aspect: event.value,
                    })
                    setAspectDropdown(event)
                  }}
                  styles={stylesSelect}
                />
                {!values.aspect && isErrors && (
                  <TextHelper className={classes.marginTextHelper}>
                    Bagian ini diperlukan
                  </TextHelper>
                )}
              </div>
            </Grid>
            {label && idLabel === idTask && (
              <Grid item sm={onBack ? 3 : 5}>
                <div style={{margin: '33px 0px 0px'}}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.outlinedInput,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <div
                              style={{
                                padding: 10,
                                background: color,
                                borderRadius: 5,
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                      }}
                      value={labelName}
                      onChange={event => {
                        setLabelName(event.target.value)
                      }}
                    />
                    <Button
                      style={{marginLeft: 10}}
                      variant="contained"
                      color="primary"
                      disabled={labelName === ''}
                      onClick={onSaveLabel}
                    >
                      Simpan
                    </Button>
                  </div>
                  <div style={{marginTop: 16}}>
                    <PsmGrey>Pilih Warna</PsmGrey>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: 350,
                      }}
                    >
                      {colorOptions.map((col, i) => {
                        return (
                          <Radio
                            key={i}
                            onChange={event => {
                              setColor(event.target.value)
                            }}
                            value={col.color}
                            checked={color === col.color}
                            icon={
                              <span
                                className={classes.icon}
                                style={{background: col.color}}
                              />
                            }
                            checkedIcon={
                              <Icon
                                className={classes.checkedIcon}
                                style={{
                                  background: col.color,
                                  fontSize: 20,
                                }}
                              >
                                check
                              </Icon>
                            }
                          ></Radio>
                        )
                      })}
                      <Icon
                        onClick={() => {
                          setColorPicker(true)
                        }}
                        style={{
                          border: '1px #a9a8a8 solid',
                          color: '#a9a8a8',
                          borderRadius: 5,
                          cursor: 'pointer',
                          fontSize: 19,
                          margin: 9,
                        }}
                      >
                        add
                      </Icon>
                    </div>
                    <div className={classes.flexAbsolute}>
                      {colorPicker && (
                        <Paper style={{padding: 14, marginBottom: 10}}>
                          <Typography variant="body2" gutterBottom>
                            Pilih Warna
                          </Typography>
                          <ChromePicker
                            color={color || false}
                            value={color}
                            onChangeComplete={color => {
                              setColor(color.hex)
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 10,
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{width: '80%'}}
                              onClick={() => {
                                setColorOptions(prev => [...prev, {color}])
                                setColorPicker(false)
                              }}
                            >
                              Tutup
                            </Button>
                          </div>
                        </Paper>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={onBack ? 12 : 5}>
            <GreyTypography variant="body2" className={classes.nameField}>
              Perspektif
            </GreyTypography>
            <TextField
              variant="outlined"
              value={dataKpi?.kpi_perspective?.name || '-'}
              disabled
              fullWidth
              InputProps={disabledStyle}
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={onBack ? 12 : 6}>
            <GreyTypography variant="body2" className={classes.nameField}>
              Deskripsi*
            </GreyTypography>
            <TextField
              variant="outlined"
              placeholder="Tambah Deskripsi"
              fullWidth
              multiline={true}
              rows={10}
              rowsMax={10}
              onChange={handleChangeValues}
              value={values.description}
              name={'description'}
              error={isError(isErrors, values.description)}
              helperText={
                isError(isErrors, values.description) && 'Bagian ini diperlukan'
              }
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={onBack ? 3 : 6}>
            <GreyTypography variant="body2" className={classes.nameField}>
              Metrik
            </GreyTypography>
            <TextField
              variant="outlined"
              name={'metric'}
              onChange={handleChangeValues}
              value={values.metric}
              disabled={dataEdit}
              placeholder="Scorecard System Implemented"
              fullWidth
              InputProps={disabledStyle}
              error={
                isError(isErrors, values.metric) ||
                (values?.metric?.length > 250 && isErrors)
              }
              helperText={
                isError(isErrors, values.metric)
                  ? 'Bagian ini diperlukan'
                  : values?.metric?.length > 250 && isErrors
                  ? "Metric can't be more than 250 characters"
                  : ''
              }
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={3}>
            <Grid item sm={onBack ? 3 : 4}>
              <GreyTypography variant="body2" className={classes.nameField}>
                Sifat Penilaian*
              </GreyTypography>
              <TextField
                variant="outlined"
                value={dataKpi?.kpi_scoring_nature?.name || '-'}
                disabled
                placeholder=""
                fullWidth
                InputProps={disabledStyle}
              />
            </Grid>
            <Grid item sm={onBack ? 3 : 4}>
              <GreyTypography variant="body2" className={classes.nameField}>
                {GLOBAL_GLOSSARY.performance['Weight Unit']}
              </GreyTypography>
              <Select
                className={classes.select}
                id="select-weight"
                options={_dataWeight}
                value={weightDropdown}
                placeholder="Pilih Satuan Target"
                isLoading={loadingWeight}
                isDisabled={dataEdit}
                components={{DropdownIndicator}}
                isArrow={true}
                onInputChange={dataSearchDropdown =>
                  setSearchDropdown({
                    ...searchDropdown,
                    weight: dataSearchDropdown,
                  })
                }
                onChange={event => {
                  handleChange({
                    ...values,
                    target_unit: event.value,
                  })
                  setWeightDropdown(event)
                }}
                styles={stylesSelect}
              />
              <TextHelper className={classes.marginTextHelper}>
                {!values.target_unit && isErrors ? 'Bagian ini diperlukan' : ''}
              </TextHelper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={3} className={classes.nameField}>
            <Grid item sm={onBack ? 3 : 4}>
              <GreyTypography variant="body2" className={classes.nameField}>
                Target {GLOBAL_GLOSSARY.performance.Task}
              </GreyTypography>
              <TextField
                variant="outlined"
                type="number"
                placeholder={`Tambahkan Target ${GLOBAL_GLOSSARY.performance.Task}`}
                fullWidth
                onChange={handleChangeValues}
                value={values.target}
                name={'target'}
                error={
                  isError(isErrors, values.target)
                  // || targetTaskValidation()
                }
                helperText={
                  isError(isErrors, values.target) && 'Bagian ini diperlukan'
                  // : targetTaskValidation()
                  // ? 'Target Task must not exceed the Target Goal'
                  // : ''
                }
              />
            </Grid>
            <Grid item sm={onBack ? 3 : 4}>
              <GreyTypography variant="body2" className={classes.nameField}>
                Perkembangan Terbaru*
              </GreyTypography>
              <TextField
                variant="outlined"
                type="number"
                placeholder="Tambah Perkembangan Terbaru"
                fullWidth
                onChange={handleChangeValues}
                value={values.progress}
                name={'progress'}
                error={isError(isErrors, values.progress)}
                helperText={
                  isError(isErrors, values.progress) && 'Bagian ini diperlukan'
                }
              />
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={3}>
              <Grid item sm={onBack ? 3 : 4}>
                <GreyTypography variant="body2" className={classes.nameField}>
                  Tanggal Mulai*
                </GreyTypography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    inputVariant="outlined"
                    format="yyyy/MM/dd"
                    value={values.startdate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={ChangeDFrom}
                    name="startdate"
                    error={isError(isErrors, values.startdate)}
                    helperText={
                      isError(isErrors, values.startdate) &&
                      'Bagian ini diperlukan'
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item sm={onBack ? 3 : 4}>
                <GreyTypography variant="body2" className={classes.nameField}>
                  Tanggal Berakhir*
                </GreyTypography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    inputVariant="outlined"
                    format="yyyy/MM/dd"
                    value={values.enddate}
                    onChange={ChangeDTo}
                    name="enddate"
                    error={isError(isErrors, values.enddate)}
                    helperText={
                      isError(isErrors, values.enddate) &&
                      'Bagian ini diperlukan'
                    }
                    minDate={
                      isEditedDate || isOffsideDate ? values.startdate : minDate
                    }
                    maxDate={maxDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={3}>
            <Grid item sm={onBack ? 3 : 5}>
              <GreyTypography variant="body2" className={classes.nameField}>
                Prioritas*
              </GreyTypography>
              <TextField
                select
                variant="outlined"
                placeholder=""
                fullWidth
                onChange={handleChangeValues}
                value={values.priority || ''}
                name={'priority'}
                error={isError(isErrors, values.priority)}
                helperText={
                  isError(isErrors, values.priority) && 'Bagian ini diperlukan'
                }
                SelectProps={selectProps(values.priority || '')}
              >
                <MenuItem value="" disabled>
                  Pilih Prioritas
                </MenuItem>
                {priorities.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        {dataEdit && (
          <Grid item sm={12}>
            <Grid container spacing={3}>
              <Grid item sm={onBack ? 7 : 5}>
                <GreyTypography variant="body2" className={classes.nameField}>
                  {GLOBAL_GLOSSARY.performance.Reporter}
                </GreyTypography>
                <Select
                  placeholder={`Tambah ${GLOBAL_GLOSSARY.performance.Reporter}`}
                  components={{
                    DropdownIndicator,
                    SingleValue,
                  }}
                  value={reporter}
                  getOptionLabel={empMenu}
                  options={listEmp}
                  onChange={val => {
                    setReporter(val)
                    if (!onBack) {
                      handleChange({
                        ...values,
                        reporter: val,
                      })
                    }
                  }}
                  styles={customSelect}
                  menuPlacement="auto"
                  onInputChange={data => setSearchRec(data)}
                  isLoading={loadingEmp}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item sm={onBack ? 7 : 5}>
          <GreyTypography variant="body2" className={classes.nameField}>
            Penerima
          </GreyTypography>
          <Select
            placeholder="Tambah Penerima"
            components={{
              DropdownIndicator,
              SingleValue: () => 'Tambah Penerima',
            }}
            value={selectedRep}
            getOptionLabel={empMenu}
            options={listEmp}
            onChange={value => handleChangeRep(value, 0)}
            styles={customSelect}
            menuPlacement="auto"
            onInputChange={data => setSearchRec(data)}
            isLoading={loadingEmp}
          />
        </Grid>

        <Grid item sm={12}>
          <div className={classes.selectedEmp}>
            {selectedRep &&
              selectedRep
                .filter(v => v.idx === 0)
                .map((option, i) => {
                  return (
                    <Card key={i} className={classes.cardEmp} elevation={0}>
                      <div className={classes.spacer}>
                        <Avatar src={option.avatar} alt={option.value}></Avatar>
                      </div>
                      <FlexBetween>
                        <div>
                          <Typography className={classes.nameDropdown}>
                            {option.value}
                          </Typography>
                          <Typography className={classes.positionDropdown}>
                            {option.pos}
                          </Typography>
                        </div>
                        <Icon
                          className={classes.textGrey}
                          style={{cursor: 'pointer'}}
                          onClick={() => handleDel(option.idx, option.value)}
                        >
                          close
                        </Icon>
                      </FlexBetween>
                    </Card>
                  )
                })}
          </div>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={12}>
            <Grid item sm={12}>
              <GreyTypography
                variant="body2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#a9a8a8',
                }}
              >
                Lampiran (Opsional){' '}
                <GenerateTooltip
                  title={<TooltipAttachmentGoal />}
                  placement="right"
                >
                  <InfoOutlinedIcon
                    style={{marginLeft: 7, width: 16}}
                    color="primary"
                  />
                </GenerateTooltip>
              </GreyTypography>
              <UploadButton
                multiple
                disabled={values?.attachment?.length > 10 || disabledButton}
                onChange={handleChangeFile}
                className={classes.uploadButton}
              />
              {values?.attachment?.map((res, i) => {
                const {name, size, url, isUploading, loaded} = res
                return (
                  <AttachmentItem
                    key={i}
                    name={name}
                    size={size}
                    url={url}
                    isPreview={false}
                    onRemove={() => handleDeleteAttachButton(i)}
                    onNameChange={e => handleChangeNameFileAttBtn(e, i)}
                    isUploading={isUploading}
                    progress={loaded}
                    className={classes.attachmentItem}
                  />
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {onBack && (
        <FromButton
          style={{
            paddingRight: '0px !important',
            paddingBottom: '0px !important',
          }}
        >
          <Button size="sm" className={classes.buttonCancel} onClick={onBack}>
            Batalkan
          </Button>
          <Button
            className={classes.buttonSave}
            style={{
              textTransform: 'none',
              backgroundColor: '#014a62',
            }}
            variant="contained"
            color="secondary"
            size="sm"
            onClick={handleValidate}
          >
            Simpan
          </Button>
        </FromButton>
      )}
      <AlertDialog
        open={alertLabel.open && !alertLabel.canDelete}
        handleClose={() => setAlertLabel(INITIAL_ALERT_LABEL)}
        feature="Tidak dapat menghapus Label"
        message="Tidak dapat menghapus karena label ini masih digunakan pada RHK atau Rencana Aksi"
        type="Konfirmasi"
      />
      <DeletePopup
        open={alertLabel.open && alertLabel.canDelete}
        handleClose={() => setAlertLabel(INITIAL_ALERT_LABEL)}
        name="label ini"
        mutation={onDeleteLabel}
        title="Label ini"
        isClose={false}
        isHighlight={false}
        isTitle={true}
      />
    </div>
  )
}

export default AddTask
