import React from 'react'
import {CircularProgress} from '@material-ui/core'

export default function LoadingSurvey() {
  return (
    <div
      style={{
        width: '100%',
        padding: '120px 0px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress />
      <p style={{marginTop: 30}}>Loading Data...</p>
    </div>
  )
}
