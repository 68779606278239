import gql from 'graphql-tag'

export const COUNT_ATTENDANCE = gql`
  query getDashboardAttendanceCount(
    $company: uuid
    $today_start_ts: timestamptz
    $today_end_ts: timestamptz
  ) {
    total_employee: people_work_placements_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        start_date: {_lte: "now()", _gt: null}
        replacement_status: {_eq: false}
        global_placement_termination_id: {_is_null: true}
        global_user: {status: {_eq: "ACTIVE"}, people_profiles: {}}
        company_job_profile: {}
      }
    ) {
      aggregate {
        count
      }
    }

    present_today: people_work_attendances_aggregate(
      where: {
        global_user: {
          status: {_eq: "ACTIVE"}
          people_profiles: {}
          people_work_placements: {
            company: {_eq: $company}
            status: {_eq: "ACTIVE"}
            start_date: {_lte: "now()", _gt: null}
            replacement_status: {_eq: false}
            global_placement_termination_id: {_is_null: true}
            company_job_profile: {}
          }
        }
        clock_in: {_gte: $today_start_ts, _lte: $today_end_ts}
      }
      distinct_on: user_work_id
    ) {
      aggregate {
        count
      }
    }
  }
`
