import gql from 'graphql-tag'

export const GET_ATTENDANCE_ACTIVITY = gql`
  query(
    $where: people_work_placements_bool_exp
    $order: [people_work_placements_order_by!]
    $offset: Int
    $limit: Int
  ) {
    total: people_work_placements_aggregate(where: $where) {
      aggregate {
        count
      }
    }

    people_work_placements(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`
