import React from 'react'
import {FormWarningWrapper} from '../../../../PayrollPageStyles'
// import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import Grid from '@material-ui/core/Grid'
// import ModalFormWarning from './ModalFormWarning'

export default function ComponentTaxOffice(props) {
  const {children, fullWidth} = props

  // const [isShow, setIsShow] = useState(false)

  return (
    <>
      {/* <ModalFormWarning open={isShow} onClose={() => setIsShow(false)} /> */}
      <FormWarningWrapper fullWidth={fullWidth}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>{children}</Grid>
          {/* <Grid item>
            <span onClick={() => setIsShow(true)}>
              <ErrorOutlineOutlinedIcon />
            </span>
          </Grid> */}
        </Grid>
      </FormWarningWrapper>
    </>
  )
}
