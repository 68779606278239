import React from 'react'
import {
  Dialog,
  Typography,
  IconButton,
  Grid,
  Paper,
  makeStyles,
  capitalize,
} from '@material-ui/core'
import {
  ModalHeader,
  WhiteIcon,
  ModalContentEvent,
  GreyTypography,
} from '../../../../../../GlobalStyles'
import {
  MathDetailBoxes,
  parameters_versions,
} from '../../../../../../utils/helpers'

const useStyles = makeStyles(() => ({
  paperDetail: {
    padding: '8px 16px',
    border: '1px solid #cbcbcb',
  },
  paperDetailLevel: {
    padding: '8px 16px',
    width: 'fit-content',
    border: '1px solid #cbcbcb',
  },
  paperDetailBetween: {
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 6,
    border: '1px solid #cbcbcb',
  },
  mt1: {
    marginTop: '1rem',
  },
  name: {
    'word-break': 'break-word',
  },
}))

const ModalLevelDetail = props => {
  const classes = useStyles()
  const {open, handleClose, resultBox, mainValues, flexy} = props
  const checkIndicator = MathDetailBoxes(mainValues, flexy)
  const index =
    resultBox &&
    checkIndicator.findIndex(
      x => x.index_x === resultBox.index_x && x.index_y === resultBox.index_y
    )

  const resI = checkIndicator[index]

  const switchFlexyComponents = (data, axis, xArr, yArr) => {
    let weight
    switch (data.parameter) {
      case 'competency':
        weight = mainValues.indicator_pot_competence_fields.weight
        break
      case 'attendance':
        weight = mainValues.indicator_pot_attendance_fields.weight
        break
      case 'course':
        weight = mainValues.indicator_pot_total_course_fields.weight
        break
      case 'socmed_post':
        weight = mainValues.indicator_pot_socmed_post_fields.weight
        break
      case 'task':
        weight = mainValues.indicator_perf_goal_fields.weight
        break
      case 'ogf':
        weight = mainValues.indicator_perf_ogf_fields.weight
        break
      case 'multirater':
        weight = mainValues.indicator_perf_multirater_fields.weight
        break
      case 'nps':
        weight = mainValues.indicator_perf_nps_fields.weight
        break
    }

    const newAxisValue = {
      name:
        data.parameter === 'ogf'
          ? 'Ongoing Feedback'
          : data.parameter === 'socmed_post'
          ? 'Social Media Post'
          : data.parameter === 'nps'
          ? 'NPS'
          : capitalize(data.parameter),
      value: resI && resI[data.parameter] && resI[data.parameter].toFixed(2),
      weight,
    }

    if (axis === 'x') {
      xArr.push(newAxisValue)
    } else {
      yArr.push(newAxisValue)
    }
  }

  const xArr = []
  const yArr = []

  if (flexy) {
    flexy.x.parameters.forEach(axis =>
      switchFlexyComponents(axis, 'x', xArr, yArr)
    )
    flexy.y.parameters.forEach(axis =>
      switchFlexyComponents(axis, 'y', xArr, yArr)
    )
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true}>
      <ModalHeader>
        <Typography variant="h6">Rincian Level</Typography>

        <IconButton aria-label="close" onClick={handleClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContentEvent>
        <Grid container spacing={5}>
          <Grid container item xs={6} spacing={2}>
            <Grid item xs={12}>
              <GreyTypography variant="body2" gutterBottom>
                Nama Level
              </GreyTypography>
              <Paper elevation={0} className={classes.paperDetail}>
                <Typography className={classes.name} variant="body1">
                  {resultBox && resultBox.name}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <GreyTypography variant="body2" gutterBottom>
                Level
              </GreyTypography>
              <Paper elevation={0} className={classes.paperDetailLevel}>
                <Typography variant="body1">
                  {resultBox && resultBox.level}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={6} spacing={2}>
            <Grid item xs={12}>
              <GreyTypography variant="body2" gutterBottom>
                Lencana Level
              </GreyTypography>
              <Paper elevation={0} className={classes.paperDetailLevel}>
                {resultBox && (
                  <img
                    src={resultBox.badge}
                    alt={resultBox.name}
                    width="100"
                  ></img>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.mt1}>
          <Grid item xs={12}>
            <GreyTypography variant="body2" gutterBottom>
              {mainValues.x_name} ({mainValues.min_pot} - {mainValues.max_pot})
            </GreyTypography>
            {xArr.map((res, i) => {
              return (
                <Paper
                  elevation={0}
                  key={i}
                  className={classes.paperDetailBetween}
                >
                  <Typography variant="body1">{`${parameters_versions?.[
                    res.name
                  ]?.ID || res.name} (${parseFloat(res.weight).toFixed(
                    2
                  )}%)`}</Typography>
                  <Typography variant="body1">{res.value}</Typography>
                </Paper>
              )
            })}
          </Grid>
          <Grid item xs={12}>
            <GreyTypography variant="body2" gutterBottom>
              {mainValues.y_name} ({mainValues.min_perf} - {mainValues.max_perf}
              )
            </GreyTypography>
            {yArr.map((res, i) => {
              return (
                <Paper
                  elevation={0}
                  key={i}
                  className={classes.paperDetailBetween}
                >
                  <Typography variant="body1">{`${parameters_versions?.[
                    res.name
                  ]?.ID || res.name} (${parseFloat(res.weight).toFixed(
                    2
                  )}%)`}</Typography>
                  <Typography variant="body1">{res.value}</Typography>
                </Paper>
              )
            })}
          </Grid>
        </Grid>
      </ModalContentEvent>
    </Dialog>
  )
}

export default ModalLevelDetail
