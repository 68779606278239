import gql from 'graphql-tag'

export const EXPORT_MUTATION = gql`
  mutation($table: String, $filter: jsonb) {
    insert_company_export_data_logs_one(
      object: {table_name: $table, filter: $filter}
    ) {
      date_added
    }
  }
`

export const CLOSE_BALOON_EXPORT = gql`
  mutation($id: Int) {
    update_company_export_data_logs(
      _set: {viewed: true}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
