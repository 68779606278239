import React, {useState} from 'react'
import {Toolbar, IconButton, Divider, Button} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {
  PaperContainer,
  AddEditTitle,
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  FormChildInput,
  TitleName,
  FormChildButton,
} from '../../../../GlobalStyles'
import {TextFieldStyled} from '../MultiraterNpsPageStyles'
import {useMutation} from '@apollo/react-hooks'
import {ADD_RESPONDENTS_GROUP} from '../../../../graphql/mutations/survey/multirater-nps/respondentsGroup.mutation'
import {COMPANY_ID} from '../../../../utils/globals'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../shared-components/popup/AddEditPopup'

const AddRespondentsGroup = props => {
  const {enqueueSnackbar} = useSnackbar()
  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)
  const [name, setName] = useState('')

  const [mutationRespondentsGroup] = useMutation(ADD_RESPONDENTS_GROUP)
  const addRespondentGroup = () => {
    if (name === '') {
      const ids = document.getElementById('top')
      ids.scrollIntoView({behavior: 'smooth'})
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const data = {
        company: COMPANY_ID,
        name: name,
      }
      mutationRespondentsGroup({variables: {data}})
        .then(() => {
          props.history.push({
            pathname: `/survey/multirater-nps/respondents-group`,
            state: {active: 'respondents-group'},
          })
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 2000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Update data error, please try again', {
            variant: 'error',
            autoHideDuration: 2000,
          })
        })
    }
  }

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }

  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <Link
          to={{
            pathname: `/survey/multirater-nps/respondents-group`,
            state: {active: 'respondents-group'},
          }}
        >
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <AddEditTitle variant="h6">Add Asesi Group</AddEditTitle>
      </Toolbar>
      <Divider></Divider>
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Name</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextFieldStyled
              value={name}
              onChange={event => {
                setName(event.target.value)
              }}
              margin="normal"
              variant="outlined"
              error={name.length < 1 && isError}
              helperText={
                name.length < 1 && isError ? 'This field is required' : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildButton>
          <Button
            color="default"
            size="large"
            component={Link}
            to={{
              pathname: `/survey/multirater-nps/respondents-group`,
              state: {active: 'respondents-group'},
            }}
            style={{marginRight: 12}}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="large"
          >
            Save
          </Button>
        </FormChildButton>
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        name={'New Asesi Group'}
        type={'Add'}
        mutation={addRespondentGroup}
        feature={''}
      />
    </PaperContainer>
  )
}

export default withRouter(AddRespondentsGroup)
