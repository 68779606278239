import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
  FormContainer,
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
} from '../../../../../GlobalStyles'
import {withRouter} from 'react-router-dom'
import {
  IconButton,
  Button,
  makeStyles,
  TextField,
  Divider,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  ADD_HOLDING_CATEGORY,
  UPDATE_HOLDING_CATEGORY,
} from '../../../../../graphql/mutations'
import {holdingRolesContext} from '../../../../../utils/helpers'
import {useSnackbar} from 'notistack'
import AddEdit from '../../../../shared-components/popup/AddEditPopup'
import {GET_HOLDING_CATEGORY_BY_ID} from '../../../../../graphql/queries'

const useStyles = makeStyles(() => ({
  paper: {
    minHeight: '70vh',
  },
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14, minHeight: 54},
}))

function FormCategory(props) {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [isError, setIsError] = useState(false)
  const id = props.match.params.id

  const {data} = useQuery(GET_HOLDING_CATEGORY_BY_ID, {
    variables: {id},
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (id) {
      if (data) {
        setName(data.holding_categories[0].name)
        setDescription(data.holding_categories[0].description)
      }
    }
  }, [data])

  const handleClickOpen = type => {
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      if (!name || !description) {
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else {
        setConfirmType(type)
        setPopup(true)
      }
    }
  }
  const handleClose = () => {
    setPopup(false)
  }

  const history = () => {
    props.history.push({
      pathname: '/group/config/add',
      state: {activeStep: 1, active: 'add-version'},
    })
  }

  const [mutationAddCategory] = useMutation(ADD_HOLDING_CATEGORY)
  const [mutationUpdateCategory] = useMutation(UPDATE_HOLDING_CATEGORY)

  const handleMutation = () => {
    if (id) {
      const changes = {
        name,
        description,
      }

      mutationUpdateCategory({
        variables: {id, changes},
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          history()
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    } else {
      const object = {
        name,
        description,
        version: localStorage.getItem('idVersion'),
      }

      mutationAddCategory({
        variables: {object},
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Success Add data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          history()
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    }
  }

  return (
    <PaperContainer className={classes.paper}>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handleClickOpen('discard')}
          >
            <ArrowBack />
          </IconButton>

          <AddEditTitle variant="h6">
            {id ? 'Edit' : 'Add'} Category
          </AddEditTitle>
        </FormTitleContainer>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => handleClickOpen(id ? 'edit' : 'add')}
        >
          Save
        </Button>
      </FormToolbar>
      <Divider />
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Category Name</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Enter the name"
              error={!name && isError}
              helperText={!name && isError ? 'This field is required' : ''}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Description</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder="Enter the description"
              error={!description && isError}
              helperText={
                !description && isError ? 'This field is required' : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={name}
        feature="Holding Category"
        mutation={confirmType === 'discard' ? history : handleMutation}
      />
    </PaperContainer>
  )
}

export default withRouter(FormCategory)
