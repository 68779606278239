import styled from 'styled-components'
import {Icon, Typography} from '@material-ui/core'

export const Title = styled.h1`
  font-weight: bold;
  color: white;
`
export const SubTitle = styled.h2`
  font-weight: bolder;
`

export const P = styled.p`
  margin: 8px 0;
  font-size: 14px;
`

export const P0 = styled.p`
  margin: 0;
`

export const Pmd = styled.p`
  margin: 0;
  font-size: 15px;
`
export const PmdG = styled.p`
  margin: 0;
  font-size: 12px;
  color: #a9a8a8;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 14px;
  }
`
export const Psm = styled.p`
  margin: 0;
  font-size: 12px;
`
export const TextBig = styled.h1`
  font-weight: 500;
  font-size: 2.5rem;
  margin: 0;
`

export const IconArrowDisabled = styled(Icon)`
  border: 1px #a9a8a8 solid;
  color: #a9a8a8;
  border-radius: 5px;
`

export const IconArrowBlue = styled(Icon)`
  border: 1px #039be5 solid;
  background-color: #039be5;
  color: white;
  border-radius: 5px;
  cursor: pointer;
`

export const BoldTypography = styled(Typography)`
  font-weight: 600;
`

export const TypographyStyleds = styled(Typography)`
  font-family: Muli;
  font-size: ${props => props.size} !important;
  font-weight: ${props => props.weight} !important;
  color: ${props => (props.color ? props.color : '#000000')};
  width: ${props => props.width}px !important;
  cursor: ${props => props.pointer && 'pointer'};
`
