import React from 'react'

import MaterialTable from 'material-table'

import {TableOptions, TableContainer} from '../../ClaimLimitPageStyles'
import {convertToRupiah} from '../../../../../utils/helpers'

function CompanyTopupList(props) {
  const {
    data,
    totalCount,
    loading,
    offset,
    limit,
    onChangeOffset,
    onChangeLimit,
  } = props

  const TABLE_COLUMNS_CONFIG = [
    {title: 'Request ID', field: 'formatted_id'},
    {title: 'Employee ID', field: 'recipient.user[0].placements[0].regno'},
    {title: 'Employee Name', field: 'recipient.user[0].name'},
    {
      title: 'Position',
      field: 'recipient.user[0].placements[0].profile.position',
    },
    {title: 'Nominal', render: renderNominal, align: 'right'},
  ]

  return (
    <MaterialTable
      isLoading={loading}
      data={data}
      totalCount={totalCount}
      page={offset}
      pageSize={limit}
      onChangePage={onChangeOffset}
      onChangeRowsPerPage={onChangeLimit}
      columns={TABLE_COLUMNS_CONFIG}
      options={TableOptions}
      components={{Container: TableContainer}}
    />
  )
}

export default CompanyTopupList

function renderNominal(data) {
  const nominal = convertToRupiah(data.nominal)
  return <b>{nominal}</b>
}
