import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import React from 'react'
import {
  CircleTagEvent,
  FormChildInputPad15,
  TitleName,
} from '../../../../../../GlobalStyles'
import {FlexStart} from '../../../../../organization/OrganizationPageStyles'

import {
  DatePickerStyled,
  TextFieldStyled,
} from '../../../../../directory/employee/EmployeePageStyles'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'

function RepeatComponent(props) {
  const {value, error, handleChangeInput} = props

  const weekButton = () => {
    const day = moment(value.date).format('dddd')

    return (
      <FormChildInputPad15>
        <TitleName>Repeat On</TitleName>
        <FlexStart>
          <CircleTagEvent
            style={{
              backgroundColor: day === 'Sunday' ? '#039be5' : '#f7f8f9',
              color: day === 'Sunday' ? '#ffffff' : '#a9a8a8',
            }}
          >
            S
          </CircleTagEvent>
          <CircleTagEvent
            style={{
              backgroundColor: day === 'Monday' ? '#039be5' : '#f7f8f9',
              color: day === 'Monday' ? '#ffffff' : '#a9a8a8',
            }}
          >
            M
          </CircleTagEvent>
          <CircleTagEvent
            style={{
              backgroundColor: day === 'Tuesday' ? '#039be5' : '#f7f8f9',
              color: day === 'Tuesday' ? '#ffffff' : '#a9a8a8',
            }}
          >
            T
          </CircleTagEvent>
          <CircleTagEvent
            style={{
              backgroundColor: day === 'Wednesday' ? '#039be5' : '#f7f8f9',
              color: day === 'Wednesday' ? '#ffffff' : '#a9a8a8',
            }}
          >
            W
          </CircleTagEvent>
          <CircleTagEvent
            style={{
              backgroundColor: day === 'Thursday' ? '#039be5' : '#f7f8f9',
              color: day === 'Thursday' ? '#ffffff' : '#a9a8a8',
            }}
          >
            T
          </CircleTagEvent>
          <CircleTagEvent
            style={{
              backgroundColor: day === 'Friday' ? '#039be5' : '#f7f8f9',
              color: day === 'Friday' ? '#ffffff' : '#a9a8a8',
            }}
          >
            F
          </CircleTagEvent>
          <CircleTagEvent
            style={{
              backgroundColor: day === 'Saturday' ? '#039be5' : '#f7f8f9',
              color: day === 'Saturday' ? '#ffffff' : '#a9a8a8',
            }}
          >
            S
          </CircleTagEvent>
        </FlexStart>
      </FormChildInputPad15>
    )
  }
  return (
    <>
      <FormChildInputPad15>
        <TitleName>Repeat Every</TitleName>
        <FlexStart>
          <TextField
            style={{width: '80px'}}
            size="small"
            margin="normal"
            variant="outlined"
            value={value.recur_time_x}
            error={error && !value.recur_time_x}
            onChange={e => handleChangeInput(e, 'recur_time_x')}
            helperText={
              error && !value.recur_time_x ? 'This field is required' : null
            }
          />
          <TextFieldStyled
            style={{paddingLeft: '25px', width: '110px'}}
            size="small"
            margin="normal"
            variant="outlined"
            select
            value={value.recurring}
            error={error && !value.recurring}
            onChange={e => handleChangeInput(e, 'recurring')}
            helperText={
              error && !value.recurring ? 'This field is required' : null
            }
          >
            <MenuItem value="default" disabled>
              Select
            </MenuItem>
            <MenuItem value={1}>Day</MenuItem>
            <MenuItem value={2}>Week</MenuItem>
            <MenuItem value={3}>Month</MenuItem>
            <MenuItem value={4}>Year</MenuItem>
          </TextFieldStyled>
        </FlexStart>
      </FormChildInputPad15>
      {value.recurring === 2 && weekButton()}
      <FormChildInputPad15>
        <TitleName>Ends</TitleName>
        <RadioGroup
          value={value.recur_end_opt}
          onChange={e => handleChangeInput(e, 'recur_end_opt')}
        >
          <FormControlLabel value={`0`} control={<Radio />} label="Never" />
          <FlexStart>
            <FormControlLabel value={`1`} control={<Radio />} label="On" />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePickerStyled
                style={{fontSize: 14, marginLeft: '13px'}}
                inputVariant="outlined"
                format="MMMM dd, yyyy"
                margin="normal"
                placeholder="Select Date"
                size="small"
                value={value.recur_end_date}
                error={
                  error && value.recur_end_opt === '1' && !value.recur_end_date
                }
                onChange={e => handleChangeInput(e, 'recur_end_date', true)}
                helperText={
                  error && value.recur_end_opt === '1' && !value.recur_end_date
                    ? 'This field is required'
                    : null
                }
              />
            </MuiPickersUtilsProvider>
          </FlexStart>
          <FlexStart>
            <FormControlLabel value={`2`} control={<Radio />} label="After" />
            <TextField
              style={{width: '80px'}}
              size="small"
              margin="normal"
              variant="outlined"
              value={value.recur_end_x}
              error={
                error && value.recur_end_opt === '1' && value.recur_end_x === ''
              }
              onChange={e => handleChangeInput(e, 'recur_end_x')}
              helperText={
                error && value.recur_end_opt === '1' && value.recur_end_x === ''
                  ? 'This field is required'
                  : null
              }
            />
            <TitleName style={{marginLeft: '13px'}}>Occurences</TitleName>
          </FlexStart>
        </RadioGroup>
      </FormChildInputPad15>{' '}
    </>
  )
}

export default RepeatComponent
