import styled from 'styled-components'

export const TabMenu = styled.div`
  .tab-menu {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    list-style: none;
    width: 180px;
  }
  .tab-menu > li {
  }
  .tab-menu li {
    padding: 0px;
    margin: 0px;
    position: relative;
    list-style: none;
    background: #fff;
    // font-family: 'Helvetica';
    font-weight: 600;
  }
  .tab-menu li:hover {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #f4f4f4;
  }
  .tab-menu li a {
    display: block;
    color: #003d51;
    padding: 12px;
    padding-left: 20px;
    position: relative;
    text-decoration: none;
  }

  .tab-menu li.active > a {
    background: #003d51;
    opacity: 0.95;
    color: #f4f4f4;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`
