import React, {useState} from 'react'
import AssigneeContainer from '../AssigneeContainer'
import helperStyle, {
  TextBlackBold,
  TextContentRed,
  FlexStart,
} from '../../CrossMentoringPageStyles'

const MentorStepComponent = props => {
  const classes = helperStyle()
  const {assignee, setAssignee, isError} = props
  const [hasChange, setHasChange] = useState(false)

  const [employees, setEmployees] = useState([])
  // const [assignee, setAssignee] = useState([])
  const [assignment, setAssignment] = useState({assignments: []})

  const onChangeForAssignment = (target, input) => {
    setAssignment({
      ...assignment,
      [target]: input,
    })
  }

  return (
    <>
      <FlexStart className={classes.mb20}>
        <TextBlackBold className={classes.mr25}>Mentee List</TextBlackBold>
        {isError && assignee.length === 0 ? (
          <TextContentRed>Please select mentee</TextContentRed>
        ) : (
          ''
        )}
      </FlexStart>
      <AssigneeContainer
        handleComponent={onChangeForAssignment}
        employees={employees}
        setEmployees={setEmployees}
        assignee={assignee}
        setAssignee={setAssignee}
        setHasChange={setHasChange}
        mentee
      />
    </>
  )
}

export default MentorStepComponent
