import React, {Fragment, useState} from 'react'
import {
  ExpansionPanelStyle,
  ExpansionPanelSummaryStyle,
  MLAuto,
  ExpansionPanelDetailStyle,
  ColumnWrapper,
  ContentQuestionExpansion,
  // ContentExpansion,
  // TitleExpansion,
  ButtonQuestionStyled,
  HeaderQuestion,
  ActionWrapper,
  ActionButton,
  ActionButtonGreen,
  ActionButtonRed,
} from '../../SurveyCenterStyled'
import MultiraterIconPrimary from '../../../../../../assets/images/multirater-icon-primary.png'
// import DehazeIcon from '@material-ui/icons/Dehaze'
import {makeStyles, Menu, MenuItem, TextField} from '@material-ui/core'
// import {Draggable, Droppable} from 'react-beautiful-dnd'
import SpeedIcon from '@material-ui/icons/Speed'
import QuestionItem from '../../QuestionItem'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {FlexCenter, MoreIcon} from '../../../../../../GlobalStyles'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import {useMutation} from '@apollo/react-hooks'
import {
  DELETE_SURVEY_GROUP_QUESTION,
  UPDATE_SURVEY_GROUP_QUESTION,
} from '../../../../../../graphql/mutations/survey/multirater-nps/survey-center/surveyCenter.mutation'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'

const useStyles = makeStyles(() => ({
  content: {alignItems: 'center'},
  focused: {backgroundColor: '#f7f8f9 !important'},
  input: {background: 'white', padding: 14},
  dehaze: {marginRight: 20, marginTop: 6},
  textError: {fontSize: 12, color: '#ef4d5e', margin: '-12px 16px 24px'},
}))

const MultiraterGroupExpan = ({
  handleChangeQuestion,
  handleOpenQuestionGroupFalse,
  handleOpenQuestionGroupTrue,
  teks,
  handleSubmitQuestion,
  forGroup,
  data,
  setListData,
  isErrorNextStep,
}) => {
  const classes = useStyles()
  const [selected, setSelected] = useState({id: '', name: ''})
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [actionType, setActionType] = useState('')

  const [updateQuestionGroup] = useMutation(UPDATE_SURVEY_GROUP_QUESTION)
  const [deleteQuestionGroup] = useMutation(DELETE_SURVEY_GROUP_QUESTION)

  const stopPropagation = e => e.stopPropagation()

  const handleAction = (data, type) => {
    if (data === 'cancel') {
      setActionType('')
      setSelected({id: '', name: ''})
    } else if (data === 'submit') {
      const mutation =
        type === 'delete'
          ? deleteQuestionGroup({
              variables: {id: selected.id},
            })
          : updateQuestionGroup({
              variables: {id: selected.id, name: selected.name},
            })

      mutation.then(() => {
        setSelected({id: '', name: ''})
        // setListData([])
        setListData()
        setActionType('')
      })
    } else {
      setActionType(type)
      setSelected({id: data.id, name: data.name})
    }
  }

  const handleChangeEdit = e => {
    setSelected({...selected, name: e.target.value})
  }

  const handleAddNewQuestion = (event, id) => {
    event.preventDefault()
    setSelectedGroup(id)
    handleOpenQuestionGroupTrue()
  }

  const handleSumbitNewQuestion = event => {
    event.preventDefault()
    setSelectedGroup(null)
    handleOpenQuestionGroupFalse()
  }

  const handleCancelAddNewQuestion = event => {
    event.preventDefault()
    setSelectedGroup(null)
    handleSubmitQuestion()
  }

  const isErrorGroupQuestion =
    data.multirater_survey_questions.length === 0 && isErrorNextStep

  return (
    <Fragment key={data.id}>
      {/* <Draggable key={keyDrag} draggableId={draggableId} index={indexDrag}>
        {provided => ( */}
      <div>
        <ExpansionPanelStyle
          border={isErrorGroupQuestion && '1px solid #ef4d5e'}
        >
          <ExpansionPanelSummaryStyle
            classes={{content: classes.content, focused: classes.focused}}
          >
            <FlexCenter style={{width: 'auto'}}>
              {data.isnps ? (
                <SpeedIcon style={{marginRight: 20}} />
              ) : (
                <img
                  src={MultiraterIconPrimary}
                  alt="multi-icon-prim"
                  style={{marginRight: 20}}
                />
              )}
              {data.id === selected.id && actionType === 'edit' ? (
                <TextField
                  value={selected.name}
                  variant="outlined"
                  onClick={stopPropagation}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  fullWidth
                  onChange={handleChangeEdit}
                />
              ) : (
                data.name
              )}
            </FlexCenter>
            {data.id === selected.id && actionType === 'edit' ? (
              <>
                <ActionButtonGreen
                  variant="contained"
                  onClick={e => {
                    handleAction('submit', 'edit')
                    stopPropagation(e)
                  }}
                >
                  <DoneIcon style={{color: '#FFF'}} />
                </ActionButtonGreen>
                <ActionButtonRed
                  onClick={e => {
                    handleAction('cancel', 'edit')
                    stopPropagation(e)
                  }}
                >
                  <ClearIcon style={{color: '#FFF'}} />
                </ActionButtonRed>
              </>
            ) : (
              <MLAuto>
                <PopupState variant="popover">
                  {popupState => (
                    <div onClick={stopPropagation}>
                      <MoreIcon {...bindTrigger(popupState)}>
                        more_horiz
                      </MoreIcon>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            handleAction(data, 'edit')
                            popupState.close()
                          }}
                        >
                          Ubah
                        </MenuItem>
                        <MenuItem
                          style={{color: '#ef4d5e'}}
                          onClick={() => {
                            handleAction(data, 'delete')
                            popupState.close()
                          }}
                        >
                          Hapus
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </PopupState>
              </MLAuto>
            )}
          </ExpansionPanelSummaryStyle>
          <ExpansionPanelDetailStyle>
            <ColumnWrapper>
              {forGroup ? null : (
                <ButtonQuestionStyled
                  variant="contained"
                  color="primary"
                  onClick={e => handleAddNewQuestion(e, data.id)}
                >
                  Tambah Pertanyaan
                </ButtonQuestionStyled>
              )}
              {data.id === selectedGroup && forGroup ? (
                <ContentQuestionExpansion>
                  <HeaderQuestion>Tambah Pertanyaan Baru</HeaderQuestion>
                  <TextField
                    onChange={handleChangeQuestion}
                    placeholder={
                      data.isnps
                        ? 'Apakah Anda ingin orang ini menjadi pemimpin tim?'
                        : 'Pertanyaan'
                    }
                    variant="outlined"
                    value={teks}
                  />
                  {/* )} */}
                  <ActionWrapper>
                    <ActionButton
                      onClick={e => handleSumbitNewQuestion(e)}
                      style={{color: '#cb2519'}}
                    >
                      Batal
                    </ActionButton>
                    <ActionButton
                      variant="contained"
                      color="primary"
                      onClick={e => handleCancelAddNewQuestion(e)}
                    >
                      Tambah Pertanyaan
                    </ActionButton>
                  </ActionWrapper>
                </ContentQuestionExpansion>
              ) : null}
              {/* <Droppable
                    droppableId={`droppable${data.id}`}
                    type={`${indexDrag}`}
                  >
                    {provided => ( */}
              <div>
                {data.multirater_survey_questions &&
                  data.multirater_survey_questions.map((question, index) => {
                    return (
                      <div key={index}>
                        <QuestionItem
                          setListData={setListData}
                          data={question}
                          index={`${question.id}-${index}`}
                          question={question && question.question_self}
                        />
                      </div>
                    )
                  })}
              </div>
              {/* )}
                  </Droppable> */}
            </ColumnWrapper>
          </ExpansionPanelDetailStyle>
        </ExpansionPanelStyle>
        {isErrorGroupQuestion && (
          <p className={classes.textError}>
            Harap tambahkan setidaknya satu pertanyaan ke grup ini
          </p>
        )}
      </div>
      {/* )}
      </Draggable> */}
      <DeletePopup
        open={actionType === 'delete'}
        name={`Pertanyaan "${selected.name}"`}
        handleClose={() => handleAction('cancel', 'delete')}
        mutation={() => handleAction('submit', 'delete')}
      />
    </Fragment>
  )
}

export default MultiraterGroupExpan
