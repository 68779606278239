import gql from 'graphql-tag'

export const GET_KPI_LIST = gql`
  query(
    $company: uuid
    $perspective: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: kpi_lists_aggregate(
      where: {
        company: {_eq: $company}
        kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}
        prespective: {_eq: $perspective}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }

    kpi_lists(
      where: {
        company: {_eq: $company}
        kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}
        prespective: {_eq: $perspective}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
      type
      formula
      description
      kpi_scoring_nature {
        id
        name
        global_scoring_nature {
          id
          name
        }
      }
      kpi_weight_unit {
        id
        name
      }
      kpi_aspect {
        id
        name
      }
      kpi_level {
        id
        name
      }
      kpi_version {
        id
        name
      }
      kpi_method {
        id
        name
      }
      kpi_monitoring_frequency {
        id
        name
      }
      kpi_perspective {
        id
        name
      }
      kpi_cascading_lists(
        where: {cascading_main: {status: {_eq: "ACTIVE"}}}
        order_by: [{id: desc}]
        limit: 1
      ) {
        id
        cascading_main {
          id
          name
          date_start
          date_end
        }
      }
    }
  }
`

export const GET_GOAL_LIST = gql`
  query selectGoal(
    $company: uuid!
    $search: String!
    $perspective: Int_comparison_exp
    $offset: Int!
    $limit: Int!
  ) {
    total: performance_goals_aggregate(
      where: {
        company: {_eq: $company}
        name: {_ilike: $search}
        kpi_list: {
          deleted_at: {_is_null: true}
          _or: [
            {
              kpi_cascading_lists: {
                cascading_main: {
                  status: {_eq: "ACTIVE"}
                  company: {_eq: $company}
                }
              }
            }
            {_not: {kpi_cascading_lists: {}}}
          ]
          prespective: $perspective
        }
      }
    ) {
      aggregate {
        count
      }
    }

    performance_goals(
      where: {
        company: {_eq: $company}
        name: {_ilike: $search}
        kpi_list: {
          deleted_at: {_is_null: true}
          _or: [
            {
              kpi_cascading_lists: {
                cascading_main: {
                  status: {_eq: "ACTIVE"}
                  company: {_eq: $company}
                }
              }
            }
            {_not: {kpi_cascading_lists: {}}}
          ]
          prespective: $perspective
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      startdate
      enddate
      priority
      kpi_list {
        id
        formula
        kpi_aspect {
          id
          name
        }
        kpi_weight_unit {
          id
          name
        }
        kpi_perspective {
          id
          name
        }
        kpi_scoring_nature {
          id
          name
          global_scoring_nature {
            id
            name
          }
        }
        kpi_cascading_lists_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`

export const GET_LIST_PERSPECTIVE_FILTER = gql`
  query listPerspective(
    $company: uuid!
    $search: String
    $offset: Int!
    $limit: Int!
  ) {
    kpi_perspectives(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
    }
  }
`
