import * as XLSX from 'xlsx'

export const generateTemplateMaster = ({provinces, cities, religions}) => {
  const provincesWS = XLSX.utils.json_to_sheet(provinces)
  const citiesWS = XLSX.utils.json_to_sheet(cities)
  const religionsWS = XLSX.utils.json_to_sheet(religions)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, provincesWS, 'Provinces')
  XLSX.utils.book_append_sheet(wb, citiesWS, 'Cities')
  XLSX.utils.book_append_sheet(wb, religionsWS, 'Religions')
  XLSX.writeFile(wb, 'template-master-data.xls')
}
