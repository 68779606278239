import {EMPOYEE_PERSONAL_DATA} from '../constant'

export const getTotalImportProcess = data => {
  if (data) {
    return data.company_import_data_logs.length + data.multiple_table.length
  } else return 0
}

export const generateDataProcess = data => {
  const res =
    [
      ...((data &&
        data.multiple_table.length > 0 &&
        data.multiple_table.map((data) => {
          return {
            type: EMPOYEE_PERSONAL_DATA,
            data,
          }
        })) ||
        []),
    ] || []
  if (data && data.company_import_data_logs.length > 0) {
    res.push({
      type: 'Employee List',
      data: data.company_import_data_logs[0],
    })
  }

  return res
    .sort((a, b) => b.data.id - a.data.id)
    .sort((a, b) => {
      if (a.data.status === 'WAITING') {
        if (a.data.status > b.data.status) return -1
        if (a.data.status < b.data.status) return 1
        return 0
      } else return 0
    })
}

export const generateIdBaloon = data => {
  if (data) {
    const _import = data.company_import_data_logs.map(({id}) => id)
    const _import_2 = data.multiple_table.map(({id}) => id)
    return [..._import, ..._import_2]
  } else return []
}
