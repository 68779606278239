import gql from 'graphql-tag'

export const GET_COMPANY_TO_INVITE = gql`
  query getCompanyProfile($search: String, $company_head: uuid) {
    company_profiles(
      where: {
        id: {_neq: $company_head}
        status: {_eq: "ACTIVE"}
        people_work_placements: {deletedAt: {_is_null: true}}
        _not: {
          holdingRelationsByCompanyMember: {company_head: {_eq: $company_head}}
        }
        _and: {
          _or: [
            {legal_name: {_ilike: $search}}
            {brand_name: {_ilike: $search}}
          ]
        }
      }
      order_by: {brand_name: asc}
    ) {
      id
      logo
      legal_name
      brand_name
      profile_field
    }
  }
`
