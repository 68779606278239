import React, {useState, useEffect} from 'react'
import {PageContainer} from '../../../GlobalStyles'
import Card from '../../../components/card/CardComponent'
import {withRouter} from 'react-router-dom'
import ListGrade from './list-job-grade/ListGrade'
import AddEditGrade from './list-job-grade/AddEditGrade'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'job-grade':
      return <ListGrade searchText={searchText} />
    case 'add-job-grade':
      return <AddEditGrade status={state.status} />
    case 'edit-job-grade':
      return <AddEditGrade status={state.status} data={state.data} />
    default:
      return <ListGrade searchText={searchText} />
  }
}

const JobGrade = props => {
  let activeMenu = 'job-grade'

  let state = {}

  if (props.location.state) {
    activeMenu = props.location.state.active
      ? props.location.state.active
      : 'job-grade'
    state = props.location.state
  }

  useEffect(() => {
    if (props.location.pathname === '/organization/job-grade') {
      props.history.push({
        state: {
          active: 'job-grade',
          searchable: true,
        },
      })
    }
  }, [])

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <Card icon="device_hub" title="Kelas Jabatan" onSearch={onSearch}>
      <PageContainer>
        {renderComponent(activeMenu, state, searchText)}
      </PageContainer>
    </Card>
  )
}

export default withRouter(JobGrade)
