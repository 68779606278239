export const LIST_TYPE_IDENTITY = [
  {
    value: 'KTP',
    name: 'KTP',
  },
  {
    value: 'SIM A',
    name: 'SIM A',
  },
  {
    value: 'SIM B1',
    name: 'SIM B1',
  },
  {
    value: 'SIM B2',
    name: 'SIM B2',
  },
  {
    value: 'SIM C',
    name: 'SIM C',
  },
  {
    value: 'SIM D',
    name: 'SIM D',
  },
  {
    value: 'NPWP',
    name: 'NPWP',
  },
  {
    value: 'PASSPORT',
    name: 'PASSPORT',
  },
]
