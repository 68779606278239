import gql from 'graphql-tag'

export const ADD_EMERGENCY_CONTACT = gql`
  mutation($data: people_emergency_contacts_insert_input!) {
    insert_people_emergency_contacts_one(object: $data) {
      id
      name
      relationship
      country_code
      phone
      address
      emergency_contact_fields
    }
  }
`

export const DELETE_EMERGENCY_CONTACT = gql`
  mutation($id: Int!) {
    update_people_emergency_contacts_by_pk(
      pk_columns: {id: $id}
      _set: {deletedAt: "now()"}
    ) {
      id
      deletedAt
    }
  }
`

export const UPDATE_EMERGENCY_CONTACT = gql`
  mutation($id: Int!, $data: people_emergency_contacts_set_input!) {
    update_people_emergency_contacts_by_pk(pk_columns: {id: $id}, _set: $data) {
      id
      name
      relationship
      country_code
      phone
      address
      emergency_contact_fields
    }
  }
`
