import React, {useState, useEffect} from 'react'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import {IconButton} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import {GridListing, ItemWrapper} from '../../DetailEmployeeStyles'
import AddEditBankAccount from '../../../../../shared-components/popup/AddEditBankAccountPopup'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import LoadingEmployee from '../../../LoadingEmployee'
import {ButtonAddDataTab} from './data-tab-shared'

import {DELETE_BANK_ACCOUNT} from '../../../../../../graphql/mutations'
import {GET_USER_BANK_ACCOUNT} from '../../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../../utils/globals'

export default function BankAccount({userId, userName}) {
  const {enqueueSnackbar} = useSnackbar()

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false)
  const [bankData, setBankData] = useState(null)
  const [selectedData, setSelectedData] = useState({
    id: null,
    name: null,
  })

  const [deleteBankAccount] = useMutation(DELETE_BANK_ACCOUNT)

  const {data, loading, error, refetch} = useQuery(GET_USER_BANK_ACCOUNT, {
    wlb_skipPatch: true,
    variables: {
      userID: userId,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'organization-hr-admin',
      },
    },
    fetchPolicy: 'no-cache',
  })

  const handleDeleteButtonClicked = () => {
    setOpenAddEditPopup(false)
    setOpenDeleteDialog(true)
  }

  const handleDeleteBankAccount = () => {
    const changes = {
      deletedAt: new Date(),
    }
    deleteBankAccount({
      variables: {
        id: selectedData?.id,
        changes,
      },
      context: {
        headers: {
          'X-Hasura-Role': 'organization-hr-admin',
        },
      },
    })
      .then(() => {
        enqueueSnackbar('Rekening berhasil dihapus', {
          variant: 'success',
        })
        refetch()
        setOpenDeleteDialog(false)
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {variant: 'error'})
      })
  }

  const BankAccountItem = React.useCallback(({res}) => {
    const {
      id,
      bank_name,
      account_name,
      account_number,
      isMain,
      isAdminAllowedToWrite,
    } = res

    return (
      <ItemWrapper>
        <h1 className="title">{bank_name || '-'}</h1>

        <table className="content">
          <tr>
            <th scope="row">Nama Pemilik Rekening</th>
            <td>{account_name}</td>
          </tr>
          <tr>
            <th scope="row">Nomor Akun</th>
            <td>{account_number}</td>
          </tr>
        </table>

        {isMain && (
          <h2 className="main" style={{margin: '8px 0 0'}}>
            Rekening Utama
          </h2>
        )}

        {isAdminAllowedToWrite && (
          <IconButton
            className="edit"
            onClick={() => {
              setOpenAddEditPopup(true)
              setSelectedData(prev => ({
                ...prev,
                ...res,
                id: id,
                name: `${account_name}'s ${bank_name} Bank Account`,
                title: 'Bank Account',
                type: 'edit',
              }))
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </ItemWrapper>
    )
  }, [])

  useEffect(() => {
    if (data) {
      setBankData([
        ...data.main_bank?.map(account => ({
          ...account?.people_profile_bank,
          isMain: true,
          isAdminAllowedToWrite:
            account?.people_profile_bank?.creator_company === COMPANY_ID,
        })),
        ...data.all_bank
          .map(account => {
            if (
              account?.account_number !==
              data.main_bank?.[0]?.people_profile_bank?.account_number
            ) {
              return {
                ...account,
                isAdminAllowedToWrite: account?.creator_company === COMPANY_ID,
              }
            }
          })
          .filter(account => account !== undefined),
      ])
    }
  }, [data])

  if (loading) {
    return <LoadingEmployee noborder smallpadding />
  }

  if (error !== undefined) {
    return <div>{JSON.stringify(error)}</div>
  }

  return (
    <>
      <GridListing>
        {bankData?.map((res, i) => {
          return <BankAccountItem key={`${res.id}-${i}`} res={res} />
        })}
      </GridListing>

      <ButtonAddDataTab
        onClick={() => {
          setSelectedData({...selectedData, type: 'add'})
          setOpenAddEditPopup(true)
        }}
      >
        Tambahkan Rekening
      </ButtonAddDataTab>

      {openDeleteDialog && (
        <DeletePopup
          open={openDeleteDialog}
          handleClose={() => {
            setSelectedData({
              id: null,
              name: null,
            })
            setOpenDeleteDialog(false)
          }}
          id={selectedData?.id}
          name={selectedData?.name}
          title={selectedData?.title}
          mutation={() => {
            handleDeleteBankAccount()
          }}
        />
      )}

      {openAddEditPopup && (
        <AddEditBankAccount
          withDelete={selectedData?.type === 'edit' && !selectedData?.isMain}
          data={selectedData}
          onDeleteClicked={handleDeleteButtonClicked}
          open={openAddEditPopup}
          refetch={refetch}
          userId={userId}
          userName={userName}
          onClose={() => {
            setSelectedData({
              id: null,
              name: null,
            })
            setOpenAddEditPopup(false)
          }}
        />
      )}
    </>
  )
}
