import gql from 'graphql-tag'

export const UPDATE_IDENTITY_CARD = gql`
  mutation(
    $userId: uuid!
    $id: String!
    $type: String!
    $dateAdded: timestamptz
    $data: people_identities_set_input!
  ) {
    update_people_identities(
      _set: $data
      where: {
        id: {_eq: $id}
        type: {_eq: $type}
        date_added: {_eq: $dateAdded}
        user: {_eq: $userId}
      }
    ) {
      affected_rows
      returning {
        number: id
        type
        identity_fields
      }
    }
  }
`

export const ADD_IDENTITY_CARD = gql`
  mutation($data: people_identities_insert_input!) {
    insert_people_identities_one(object: $data) {
      number: id
      type
      user
      identity_fields
      deletedAt
    }
  }
`

// `dateAdded` is an additional condition for extra measure, for some reason
// the `people_identities` table has `deletedAt` as a primary key, which means
// that in the case we matched multiple identity cards, this mutation will fail
export const DELETE_IDENTITY_CARD = gql`
  mutation($id: String!, $type: String!, $dateAdded: timestamptz) {
    update_people_identities(
      _set: {deletedAt: "now()"}
      where: {
        id: {_eq: $id}
        type: {_eq: $type}
        date_added: {_eq: $dateAdded}
        deletedAt: {_is_null: true}
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_KTP = gql`
  mutation($id: Int!, $id_number: String) {
    update_people_work_salaries_by_pk(
      pk_columns: {id: $id}
      _set: {ktp: $id_number}
    ) {
      id
      value
      start_date
      end_date
      ktp
      npwp
    }
  }
`

export const UPDATE_NPWP = gql`
  mutation($id: Int!, $id_number: String) {
    update_people_work_salaries_by_pk(
      pk_columns: {id: $id}
      _set: {npwp: $id_number}
    ) {
      id
      value
      start_date
      end_date
      ktp
      npwp
    }
  }
`
export const DELETE_NPWP_PWS = gql`
  mutation($user_id: uuid!) {
    update_people_work_salaries(
      where: {people_work_placement: {user: {_eq: $user_id}}}
      _set: {npwp: null}
    ) {
      affected_rows
    }
  }
`

export const DELETE_KTP_PWS = gql`
  mutation($user_id: uuid!) {
    update_people_work_salaries(
      where: {people_work_placement: {user: {_eq: $user_id}}}
      _set: {ktp: null}
    ) {
      affected_rows
    }
  }
`
