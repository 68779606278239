import React, {Fragment, useState} from 'react'
import {
  GreyTypography,
  ModalContent,
  ModalHeader,
  WhiteIcon,
  LongBtn,
  CapsuleContent,
} from '../../../../../GlobalStyles'

import {
  Dialog,
  Button,
  Typography,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core'
import moment from 'moment'
// import numeral from 'numeral'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
// import {convertDateForID} from '../../../../../utils/helpers'
// import {USER_ID} from '../../../../../utils/globals'

import {SwitchValue} from '../../../../../components/case-switcher/CaseSwitcher'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  paper: {
    width: '100%',
    // marginBottom: theme.spacing(2),
    marginBottom: '7rem',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
    padding: '0 1.6rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detailContainer: {display: 'flex', paddingBottom: '1rem'},
  w30: {width: '30%'},
  w40: {width: '40%'},
  w70: {width: '70%'},
  w60: {width: '60%'},
  detailLeave1: {
    marginTop: '1rem',
  },
  detailLeave2: {
    margin: '0 -1.6rem -.8rem',
    padding: '1.6rem 1.6rem .8rem',
    background: '#fff',
  },
  btnStatus: {
    textTransform: 'capitalize',
    color: '#fff !important',
    marginRight: '1rem',
  },
  selectFilter: {marginRight: 12, minWidth: '19%'},
  flexAction: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-around',
    width: '88%',
    margin: 'auto',
  },
  btnReject: {color: '#ef4d5e', border: '1.5px solid #ef4d5e'},
  // paperDialog,
  flexAction2: {display: 'flex', justifyContent: 'flex-end', marginTop: 20},
  buttonSpacer: {marginRight: 12},
  pointer: {
    cursor: 'pointer',
  },
  blueSky: {
    color: '#039be5 !important',
    textDecoration: 'none',
  },
}))

export default function ModalDetailActivity(props) {
  const {
    selectedItem,
    open,
    handleClose,
    handleModalAction,
    type,
    reject,
    reason,
    setReject,
    setReason,
  } = props
  // const [reject, setReject] = useState(false)
  // const [reason, setReason] = useState('')

  const [isError, setIsError] = useState(false)
  const classes = useStyles()
  const attachment =
    selectedItem &&
    selectedItem.leave_fields &&
    selectedItem.leave_fields.attachment
      ? selectedItem.leave_fields.attachment
      : []
  const hourStart =
    selectedItem &&
    selectedItem.leave_fields &&
    selectedItem.leave_fields.startDate
  const hourEnd =
    selectedItem &&
    selectedItem.leave_fields &&
    selectedItem.leave_fields.endDate

  const handleAction = status => {
    if (reject && !reason) {
      setIsError(true)
      setTimeout(() => setIsError(false), 2000)
    } else {
      let action = ''
      switch (status) {
        case 'approved':
        case 'Approved':
          action = 'approve'
          break
        case 'rejected':
        case 'Rejected':
          action = 'reject'
          break
      }

      handleModalAction(action, reason)
    }
  }

  const handleClear = () => {
    setReject && setReject(false)
    setReason && setReason('')
  }

  const buttonAction = () => {
    return (
      <>
        <div className={classes.flexAction}>
          <LongBtn
            variant="outlined"
            className={classes.btnReject}
            // onClick={() => handleAction('rejected')}
            onClick={() => setReject(true)}
          >
            Reject
          </LongBtn>
          <LongBtn
            variant="contained"
            color="primary"
            onClick={() => handleAction('approved')}
          >
            Approve
          </LongBtn>
        </div>
        {reject && (
          <>
            <TextField
              variant="outlined"
              multiline
              rows="4"
              fullWidth
              placeholder="Add Rejection Reason Here"
              value={reason}
              error={!reason && isError}
              helperText={!reason && isError ? 'This field is required' : ''}
              onChange={e => setReason(e.target.value)}
            ></TextField>
            <div className={classes.flexAction2}>
              <Button className={classes.buttonSpacer} onClick={handleClear}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                // onClick={() => {
                //   handleClickConfirm('reject')
                //   handleClose()
                // }}
                onClick={() => handleAction('rejected')}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </>
    )
  }

  const job_profile_supervisor_1 =
    selectedItem &&
    selectedItem.people_work_placement.company_job_profile
      .job_profile_supervisor
  const job_profile_supervisor_2 =
    job_profile_supervisor_1 && job_profile_supervisor_1.spv_2
  const job_profile_supervisor_3 =
    job_profile_supervisor_2 && job_profile_supervisor_2.spv_3

  const is_spv_1 =
    job_profile_supervisor_1 &&
    job_profile_supervisor_1.people_work_placements[0]
  const is_spv_2 =
    job_profile_supervisor_2 &&
    job_profile_supervisor_2.people_work_placements[0]
  const is_spv_3 =
    job_profile_supervisor_3 &&
    job_profile_supervisor_3.people_work_placements[0]

  const nameSupervisor =
    (is_spv_1 || is_spv_2 || is_spv_3) &&
    (is_spv_1 || is_spv_2 || is_spv_3).global_user.name

  const nameDesignationSupervisor = is_spv_1
    ? job_profile_supervisor_1.title
    : is_spv_2
    ? job_profile_supervisor_2.title
    : is_spv_3
    ? job_profile_supervisor_3.title
    : ''

  const confirmType =
    selectedItem &&
    selectedItem.time_leave_policy_child &&
    selectedItem.time_leave_policy_child.confirm_type

  const firstPosition =
    selectedItem &&
    selectedItem.time_leave_policy_child &&
    selectedItem.time_leave_policy_child.first_job_profile &&
    selectedItem.time_leave_policy_child.first_job_profile.title

  const secondPosition =
    selectedItem &&
    selectedItem.time_leave_policy_child &&
    selectedItem.time_leave_policy_child.second_job_profile

  const isRejected = selectedItem && selectedItem.status === 'rejected'
  const bridge = isRejected
    ? [`first_reject`, 'second_reject']
    : [`first_approve`, 'second_approve']
  const leave_fields = selectedItem && selectedItem.leave_fields
  const firstUserAction =
    leave_fields &&
    leave_fields[bridge[0]] &&
    ' by ' +
      leave_fields[bridge[0]].user.name +
      ' ' +
      (leave_fields[bridge[0]].user.title || '')
  const secondUserAction =
    leave_fields &&
    leave_fields[bridge[1]] &&
    ' by ' +
      leave_fields[bridge[1]].user.name +
      ' ' +
      (leave_fields[bridge[1]].user.title || '')

  // const buttonHideHandle = () => {
  //   const firstPositionId =
  //     selectedItem &&
  //     selectedItem.time_leave_policy_child &&
  //     selectedItem.time_leave_policy_child.first_job_profile &&
  //     selectedItem.time_leave_policy_child.first_job_profile
  //       .people_work_placements[0] &&
  //     selectedItem.time_leave_policy_child.first_job_profile
  //       .people_work_placements[0].global_user.id

  //   const secondPositionId =
  //     selectedItem &&
  //     selectedItem.time_leave_policy_child &&
  //     selectedItem.time_leave_policy_child.second_job_profile &&
  //     selectedItem.time_leave_policy_child.second_job_profile
  //       .people_work_placements[0] &&
  //     selectedItem.time_leave_policy_child.second_job_profile
  //       .people_work_placements[0].global_user.id

  //   const superviserID =
  //     selectedItem &&
  //     selectedItem.people_work_placement &&
  //     selectedItem.people_work_placement.company_job_profile &&
  //     selectedItem.people_work_placement.company_job_profile
  //       .job_profile_supervisor &&
  //     selectedItem.people_work_placement.company_job_profile
  //       .job_profile_supervisor.people_work_placements[0] &&
  //     selectedItem.people_work_placement.company_job_profile
  //       .job_profile_supervisor.people_work_placements[0].global_user &&
  //     selectedItem.people_work_placement.company_job_profile
  //       .job_profile_supervisor.people_work_placements[0].global_user.id

  //   let buttonHide = false

  //   if ((selectedItem && selectedItem.status) === 'pending') {
  //     if (firstPosition) {
  //       buttonHide = firstPositionId === USER_ID
  //     } else {
  //       buttonHide = superviserID === USER_ID
  //     }
  //   } else if ((selectedItem && selectedItem.status) === 'confirm1') {
  //     if (secondPositionId) {
  //       buttonHide = secondPositionId === USER_ID
  //     } else {
  //       buttonHide = superviserID === USER_ID
  //     }
  //   }

  //   return buttonHide
  // }

  // const newId = `LV-${date}${customId}`
  const renderSwitch = param => {
    switch (param) {
      case 'approved':
      case 'Approved':
        return (
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Submission Status
            </Typography>
            <GreyTypography variant="body2" className={classes.w60}>
              Approved
              {confirmType === '1'
                ? secondPosition
                  ? secondUserAction || ` by ${secondPosition.title}`
                  : firstUserAction ||
                    ` by ${nameSupervisor}, ${nameDesignationSupervisor}`
                : confirmType === '2'
                ? secondUserAction || secondPosition
                  ? secondUserAction || ` by ${secondPosition.title}`
                  : firstUserAction || ` by ${firstPosition}`
                : secondUserAction || secondPosition
                ? secondUserAction || ` by ${secondPosition.title}`
                : ''}
            </GreyTypography>
          </div>
        )
      case 'pending':
      case 'Pending':
        return (
          <Fragment>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w40}>
                Submission Status
              </Typography>
              <GreyTypography variant="body2" className={classes.w60}>
                Waiting for{' '}
                {selectedItem &&
                selectedItem.time_leave_policy_child &&
                selectedItem.time_leave_policy_child.first_job_profile
                  ? selectedItem.time_leave_policy_child.first_job_profile.title
                  : `${nameSupervisor} ${nameDesignationSupervisor}`}{' '}
                approval
              </GreyTypography>
            </div>
          </Fragment>
        )
      case 'rejected':
        return (
          <>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w40}>
                Submission Status
              </Typography>
              <GreyTypography variant="body2" className={classes.w60}>
                Rejected by{' '}
                {confirmType === '1'
                  ? secondPosition
                    ? secondUserAction ||
                      firstUserAction ||
                      `${nameSupervisor} ${nameDesignationSupervisor}`
                    : firstUserAction || firstPosition
                  : secondUserAction || secondPosition.title}
                {/* {selectedItem &&
                selectedItem.time_leave_policy_child &&
                selectedItem.time_leave_policy_child.first_job_profile
                  ? selectedItem.time_leave_policy_child.first_job_profile.title
                  : `${nameSupervisor} ${nameDesignationSupervisor}`} */}
              </GreyTypography>
            </div>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w40}>
                Rejected Statement
              </Typography>
              <GreyTypography variant="body2">
                {(selectedItem &&
                  selectedItem.leave_fields &&
                  selectedItem.leave_fields.leaveFields &&
                  selectedItem.leave_fields.leaveFields.reason) ||
                  (selectedItem &&
                    selectedItem.leave_fields &&
                    selectedItem.leave_fields.reject_reason) ||
                  (selectedItem &&
                    selectedItem.leave_fields &&
                    selectedItem.leave_fields.reason)}
              </GreyTypography>
            </div>
          </>
        )
      case 'cancelled':
        return (
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Submission Status
            </Typography>
            <GreyTypography variant="body2" className={classes.w60}>
              Cancelled by User
            </GreyTypography>
          </div>
        )
      default:
        return (
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Submission Status
            </Typography>
            <GreyTypography variant="body2" className={classes.w60}>
              Waiting for{' '}
              {selectedItem && selectedItem.status !== 'confirm1'
                ? firstPosition
                : secondPosition
                ? secondPosition.title
                : `${nameSupervisor} ${nameDesignationSupervisor}`}{' '}
              approval
            </GreyTypography>
          </div>
        )
    }
  }
  return (
    <Dialog
      onClose={() => {
        handleClose()
        handleClear()
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      // classes={{paper: classes.paperDialog}}
    >
      <ModalHeader>
        <div style={{display: 'flex'}}>
          <Button
            variant="contained"
            className={classes.btnStatus}
            style={{
              background: SwitchValue(
                selectedItem && selectedItem.status,
                'colorStatus'
              ),
            }}
            disabled
          >
            {selectedItem && selectedItem.status === 'confirm1'
              ? 'Half Approved'
              : selectedItem && selectedItem.status === 'pending'
              ? 'Waiting'
              : selectedItem && selectedItem.status}
          </Button>
          <Typography variant="h6" className={classes.title}>
            {selectedItem &&
              selectedItem.time_leave_policy_child &&
              selectedItem.time_leave_policy_child.name}
          </Typography>
        </div>

        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose()
            handleClear()
          }}
        >
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContent>
        <div className={classes.detailLeave1}>
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Name
            </Typography>
            <GreyTypography variant="body2" className={classes.w60}>
              {selectedItem &&
                selectedItem.people_work_placement.global_user &&
                selectedItem.people_work_placement.global_user.name}
            </GreyTypography>
          </div>
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Position
            </Typography>
            <GreyTypography variant="body2" className={classes.w60}>
              {selectedItem &&
                selectedItem.people_work_placement.company_job_profile &&
                selectedItem.people_work_placement.company_job_profile.title}
            </GreyTypography>
          </div>
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Submission Date
            </Typography>
            <GreyTypography variant="body2" className={classes.w60}>
              {selectedItem && moment(selectedItem.date_added).format('ll')}
            </GreyTypography>
          </div>
          {selectedItem &&
            (selectedItem.status === 'approved' ||
              selectedItem.status === 'Approved' ||
              selectedItem.status === 'rejected') && (
              <div className={classes.detailContainer}>
                <Typography variant="body2" className={classes.w40}>
                  Response Date
                </Typography>
                <GreyTypography variant="body2" className={classes.w60}>
                  {selectedItem &&
                    moment(selectedItem.last_modified).format('ll')}
                </GreyTypography>
              </div>
            )}
        </div>
        <div className={classes.detailLeave2}>
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Request ID
            </Typography>
            <GreyTypography variant="body2" className={classes.w60}>
              {/* {newId} */}
              {selectedItem && selectedItem.id_display}
            </GreyTypography>
          </div>
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Leave and Permission Date
            </Typography>
            <GreyTypography variant="body2">
              {selectedItem && selectedItem.quota_taken > 1
                ? `${selectedItem &&
                    moment(selectedItem.datestart).format(
                      'LL'
                    )} - ${selectedItem &&
                    moment(selectedItem.dateend).format('LL')}`
                : selectedItem && moment(selectedItem.datestart).format('LL')}
            </GreyTypography>
            <CapsuleContent>
              {selectedItem && !selectedItem.is_half
                ? selectedItem && selectedItem.quota_taken > 0
                  ? `${selectedItem && selectedItem.quota_taken} Days`
                  : `1 Days`
                : selectedItem && selectedItem.is_half
                ? 'Half Day'
                : '1 Day'}
            </CapsuleContent>
          </div>
          {selectedItem && selectedItem.is_half && (
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w40}>
                Time
              </Typography>
              <GreyTypography variant="body2">
                {hourStart} - {hourEnd}
              </GreyTypography>
            </div>
          )}
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Contact Number
            </Typography>
            <GreyTypography variant="body2" className={classes.w60}>
              {selectedItem &&
                selectedItem.contacts &&
                selectedItem.contacts.phone}
            </GreyTypography>
          </div>
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Description
            </Typography>
            <GreyTypography variant="body2" style={{width: '60%'}}>
              {selectedItem && selectedItem.description}
            </GreyTypography>
          </div>

          {renderSwitch(selectedItem && selectedItem.status)}
          <div className={classes.detailContainer}>
            <Typography variant="body2" className={classes.w40}>
              Attachment
            </Typography>
            <div className={classes.w60}>
              {attachment
                ? attachment.map((data, key) => {
                    return (
                      <div key={key}>
                        <a
                          component={Link}
                          href={data.link}
                          rel="noopener noreferrer"
                          target="_blank"
                          className={classNames(
                            classes.w60,
                            classes.pointer,
                            classes.blueSky
                          )}
                        >
                          {data.name}
                        </a>
                      </div>
                    )
                  })
                : '-'}
            </div>
          </div>
          {type === 'request' && buttonAction()}
        </div>
      </ModalContent>
    </Dialog>
  )
}
