import React, {useState} from 'react'
import {Typography, Divider, Paper} from '@material-ui/core'
import {
  TrendingUp,
  DataUsage,
  AssignmentTurnedIn,
  PlaylistAddCheck,
  HourglassFull,
  CheckCircleOutline,
} from '@material-ui/icons'
import {
  DoughnutContainer,
  AggregateContainer,
} from '../../../../components/widgets/doughnut/DoughnutChartStyles'
import {GET_DATA_TASK_SUMMARY} from '../../../../graphql/queries'
import {EnhancedToolbar} from '../../../../GlobalStyles'
import {COMPANY_ID} from '../../../../utils/globals'
import {SectionDetailContainer} from '../IndividualSummaryPageStyle'
import Loading from '../../../../components/loading/LoadingComponent'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import {Doughnut} from 'react-chartjs-2'
import {useQuery} from '@apollo/react-hooks'

const TaskDetailComponent = ({idUser}) => {
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  })

  const {data, error} = useQuery(GET_DATA_TASK_SUMMARY, {
    variables: {
      user: idUser,
      filterDate: {
        _gte: filterDate?.startDate,
        _lte: filterDate?.endDate,
      },
      company: COMPANY_ID,
    },
  })

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{JSON.stringify(error)}</div>
  }

  const dataTaskChart = {
    personal_legenda: {
      labels: [
        'Harus Dikerjakan',
        'Sedang Dikerjakan',
        'Rata-rata Nilai Sedang Dikerjakan',
        'Selesai Dikerjakan',
        'Rata-rata Nilai Selesai Dikerjakan',
        'Sudah Terlaksana',
        'Rata-rata Nilai Sudah Terlaksana',
      ],
      datasets: [
        {
          data: [
            data?.personal_task_todo?.aggregate?.total_personal_task_todo ?? 0,
            data?.personal_task_inprogress?.aggregate
              ?.total_personal_task_inprogress ?? 0,
            data?.personal_task_inprogress?.aggregate?.avg
              ?.avg_personal_task_inprogress
              ? data?.personal_task_inprogress?.aggregate?.avg?.avg_personal_task_inprogress?.toFixed(
                  2
                ) + '%'
              : '0%',
            data?.personal_task_done?.aggregate?.total_personal_task_done ?? 0,
            data?.personal_task_done?.aggregate?.avg?.avg_personal_task_done
              ? data?.personal_task_done?.aggregate?.avg?.avg_personal_task_done?.toFixed(
                  2
                ) + '%'
              : '0%',
            data?.personal_task_completed?.aggregate
              ?.total_personal_task_completed ?? 0,
            data?.personal_task_completed?.aggregate?.avg
              ?.avg_personal_task_completed
              ? data?.personal_task_completed?.aggregate?.avg?.avg_personal_task_completed?.toFixed(
                  2
                ) + '%'
              : '0%',
          ],
          backgroundColor: [
            '#1f78b4',
            '#33a02c',
            '#ffffff',
            '#a6cee3',
            '#ffffff',
            '#b2df8a',
            '#ffffff',
          ],
        },
      ],
    },
    company_legenda: {
      labels: [
        'Harus Dikerjakan',
        'Sedang Dikerjakan',
        'Rata-rata Nilai Sedang Dikerjakan',
        'Selesai Dikerjakan',
        'Rata-rata Nilai Selesai Dikerjakan',
        'Sudah Terlaksana',
        'Rata-rata Nilai Sudah Terlaksana',
      ],
      datasets: [
        {
          data: [
            data?.company_task_todo?.aggregate?.total_company_task_todo ?? 0,
            data?.company_task_inprogress?.aggregate
              ?.total_company_task_inprogress ?? 0,
            data?.company_task_inprogress?.aggregate?.avg
              ?.avg_company_task_inprogress
              ? data?.company_task_inprogress?.aggregate?.avg?.avg_company_task_inprogress?.toFixed(
                  2
                ) + '%'
              : '0%',
            data?.company_task_done?.aggregate?.total_company_task_done ?? 0,
            data?.company_task_done?.aggregate?.avg?.avg_company_task_done
              ? data?.company_task_done?.aggregate?.avg?.avg_company_task_done?.toFixed(
                  2
                ) + '%'
              : '0%',
            data?.company_task_completed?.aggregate
              ?.total_company_task_completed ?? 0,
            data?.company_task_completed?.aggregate?.avg
              ?.avg_company_task_completed
              ? data?.company_task_completed?.aggregate?.avg?.avg_company_task_completed?.toFixed(
                  2
                ) + '%'
              : '0%',
          ],
          backgroundColor: [
            '#1f78b4',
            '#33a02c',
            '#ffffff',
            '#a6cee3',
            '#ffffff',
            '#b2df8a',
            '#ffffff',
          ],
        },
      ],
    },
    personal: {
      labels: ['To Do', 'In Progress', 'Done', 'Complete'],
      datasets: [
        {
          data: [
            data?.personal_task_todo?.aggregate?.total_personal_task_todo ?? 0,
            data?.personal_task_inprogress?.aggregate
              ?.total_personal_task_inprogress ?? 0,
            data?.personal_task_done?.aggregate?.total_personal_task_done ?? 0,
            data?.personal_task_completed?.aggregate
              ?.total_personal_task_completed ?? 0,
          ],
          backgroundColor: ['#1f78b4', '#33a02c', '#a6cee3', '#b2df8a'],
        },
      ],
    },
    company: {
      labels: ['To Do', 'In Progress', 'Done', 'Complete'],
      datasets: [
        {
          data: [
            data?.company_task_todo?.aggregate?.total_company_task_todo ?? 0,
            data?.company_task_inprogress?.aggregate
              ?.total_company_task_inprogress ?? 0,
            data?.company_task_done?.aggregate?.total_company_task_done ?? 0,
            data?.company_task_completed?.aggregate
              ?.total_company_task_completed ?? 0,
          ],
          backgroundColor: ['#1f78b4', '#33a02c', '#a6cee3', '#b2df8a'],
        },
      ],
    },
  }

  const renderLegendPersonal = feature => {
    const newArr = []
    for (let i = 0; i < dataTaskChart[feature].labels.length; i++) {
      newArr.push({
        color: dataTaskChart[feature].datasets[0].backgroundColor[i],
        data: dataTaskChart[feature].datasets[0].data[i],
        labels: dataTaskChart[feature].labels[i],
      })
    }

    return newArr.map((resAge, i) => (
      <div className="legend-container" key={i}>
        <div
          style={{
            border: `solid 3px ${resAge.color}`,
          }}
          className="circle"
        />
        <div className="legend-info-containter">
          <p className="p-legend-status">{resAge.labels}</p>
          <p className="p-legend-value-task">{resAge.data}</p>
        </div>
      </div>
    ))
  }

  const options = {
    cutoutPercentage: 80,
    rotation: 4.7,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: 'left',
      labels: {
        boxWidth: 15,
        fontSize: 12,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  }
  return (
    <SectionDetailContainer>
      <Paper className="paper-padding space-section">
        <EnhancedToolbar>
          <Typography variant="h6" color="primary">
            Rencana Aksi
          </Typography>
          <div className="section-filter">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                InputProps={{
                  readOnly: true,
                }}
                inputVariant="outlined"
                format="MMM dd, yyyy"
                id="start-date"
                placeholder="Tanggal Mulai"
                InputProps={{readOnly: true}}
                value={filterDate.startDate && new Date(filterDate.startDate)}
                onChange={date =>
                  setFilterDate({
                    ...filterDate,
                    startDate: moment(date).format('YYYY-MM-DD'),
                  })
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  size: 'small',
                }}
              />
              <KeyboardDatePicker
                InputProps={{
                  readOnly: true,
                }}
                inputVariant="outlined"
                format="MMM dd, yyyy"
                id="end-date"
                placeholder="Tanggal Selesai"
                minDate={filterDate.startDate}
                InputProps={{readOnly: true}}
                value={filterDate.endDate && new Date(filterDate.endDate)}
                onChange={date =>
                  setFilterDate({
                    ...filterDate,
                    endDate: moment(date).format('YYYY-MM-DD'),
                  })
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  size: 'small',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </EnhancedToolbar>
        <Divider className="divider-margin" />
        <div className="info-container">
          <div className="info-section">
            <div className="icon-container">
              <TrendingUp className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${data?.all_task?.aggregate?.avg?.avg_all_task?.toFixed(
                2
              ) ?? 0}%`}</p>
              <p className="p-text">Nilai Rata-Rata Rencana Aksi</p>
            </div>
          </div>
          <div className="info-section">
            <div className="icon-container">
              <DataUsage className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${data?.all_task?.aggregate
                ?.total_all_task ?? 0}`}</p>
              <p className="p-text">Total Rencana Aksi</p>
            </div>
          </div>
          <div className="info-section">
            <div className="icon-container">
              <AssignmentTurnedIn className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${data?.all_task_completed?.aggregate
                ?.total_all_task_completed ?? 0}`}</p>
              <p className="p-text">Total Rencana Aksi Sudah Terlaksana</p>
            </div>
          </div>
        </div>
        <div className="info-container">
          <div className="info-section">
            <div className="icon-container">
              <PlaylistAddCheck className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${data?.all_task_todo?.aggregate
                ?.total_all_task_todo ?? 0}`}</p>
              <p className="p-text">Total Rencana Aksi Harus Dikerjakan</p>
            </div>
          </div>
          <div className="info-section">
            <div className="icon-container">
              <HourglassFull className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${data?.all_task_inprogress?.aggregate
                ?.total_all_task_inprogress ?? 0}`}</p>
              <p className="p-text">Total Rencana Aksi Sedang Dikerjakan</p>
            </div>
          </div>
          <div className="info-section">
            <div className="icon-container">
              <CheckCircleOutline className="icon-style" />
            </div>
            <div className="text-container">
              <p className="p-number">{`${data?.all_task_done?.aggregate
                ?.total_all_task_done ?? 0}`}</p>
              <p className="p-text">Total Rencana Aksi Selesai Dikerjakan</p>
            </div>
          </div>
        </div>
        <div className="chart-container">
          <div className="wrap-chart">
            <p className="p-chart-title">Rencana Hasil Kerja Pribadi</p>
            <div className="chart-section">
              <div className="chart-task">
                <DoughnutContainer className="inner-chart">
                  <Doughnut data={dataTaskChart.personal} options={options} />
                  <AggregateContainer>
                    <span>{`${data?.personal_task?.aggregate
                      ?.total_personal_task ?? 0}`}</span>
                    <span>Rencana Aksi</span>
                  </AggregateContainer>
                </DoughnutContainer>
              </div>
              <div className="info-chart info-chart-task">
                {renderLegendPersonal('personal_legenda')}
              </div>
            </div>
            <div className="average-container">
              <span className="span-average">
                Rata-rata Nilai Rencana Aksi:
              </span>
              <span className="span-average-score">{`${data?.personal_task?.aggregate?.avg?.avg_personal_task?.toFixed(
                2
              ) ?? 0}%`}</span>
            </div>
          </div>
          <Divider orientation="vertical" className="divider-chart" />
          <div className="wrap-chart margin-chart">
            <p className="p-chart-title">Rencana Hasil Kerja Instansi</p>
            <div className="chart-section">
              <div className="chart-task">
                <DoughnutContainer className="inner-chart">
                  <Doughnut data={dataTaskChart.company} options={options} />
                  <AggregateContainer>
                    <span>{`${data?.company_task?.aggregate
                      ?.total_company_task ?? 0}`}</span>
                    <span>Rencana Aksi</span>
                  </AggregateContainer>
                </DoughnutContainer>
              </div>
              <div className="info-chart info-chart-task">
                {renderLegendPersonal('company_legenda')}
              </div>
            </div>
            <div className="average-container">
              <span className="span-average">
                Rata-rata Nilai Rencana Aksi:
              </span>
              <span className="span-average-score">{`${data?.company_task?.aggregate?.avg?.avg_company_task?.toFixed(
                2
              ) ?? 0}%`}</span>
            </div>
          </div>
        </div>
      </Paper>
    </SectionDetailContainer>
  )
}

export default TaskDetailComponent
