import React from 'react'
import {
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
} from '../../../../../GlobalStyles'
import {MenuItem} from '@material-ui/core'
import {TextFieldStyled} from '../../../../directory/employee/EmployeePageStyles'

export default function ComponentSelect(props) {
  const {id, number, name, child, error, placeholder, ...other} = props
  const length = number.split('').length
  return (
    <FormChildContainer id={id}>
      <FormChildTitle>
        <TitleNumber style={length === 2 ? {paddingRight: 10} : null}>
          {number}.
        </TitleNumber>
        <TitleName>{name}</TitleName>
      </FormChildTitle>
      <FormChildInput>
        <TextFieldStyled
          fullWidth
          size="small"
          margin="normal"
          variant="outlined"
          select
          displayEmpty
          value="religion"
          error={error}
          helperText={error ? 'This field is required' : null}
          {...other}
        >
          <MenuItem value="" disabled>
            {placeholder !== '' ? placeholder : 'select'}
          </MenuItem>
          {child}
        </TextFieldStyled>
      </FormChildInput>
    </FormChildContainer>
  )
}
