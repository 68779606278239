import React, {useState, useEffect} from 'react'
import helperStyle, {
  PaperContainer,
  FormContainer,
  AddEditTitle,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  ThinTextfield,
  FormToolbar,
  FormTitleContainer,
  TextHelper,
} from '../../../../GlobalStyles'
import {
  FlexStart,
  LitleRedText,
  LitleLightBlueText,
} from '../../../organization/OrganizationPageStyles'
import FieldInformationTooltip from '../../../shared-components/tooltip/FieldInformationTooltip'
import {
  Divider,
  IconButton,
  // TextField,
  // MenuItem,
  Button,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import Select from 'react-select'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {withRouter} from 'react-router-dom'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
// import {useMutation} from '@apollo/react-hooks'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import AlertDialog from '../../../shared-components/popup/ResultPopup'

import Loading from '../../../../components/loading/LoadingComponent'
import {COMPANY_ID} from '../../../../../src/utils/globals'
import classNames from 'classnames'

import {
  GET_LIST_HEADQUARTERS,
  GET_INDUSTRY_TYPE,
  GET_GLOBAL_PHONE_CODE,
  GET_LEGAL_TYPE,
} from '../../../../graphql/queries'
import {useSnackbar} from 'notistack'
import {UPDATE_COMPANY_PROFILE} from '../../../../graphql/mutations'
import {useQuery, useMutation} from '@apollo/react-hooks'

const EditIntroProfile = ({dataEditIntro, history, feature}) => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [openPopup, setPopup] = useState(false)
  const [dialogCancel, setDialogCancel] = useState(false)
  const [isError, setIsError] = useState(false)
  const [headquartersDropdown, setHeadquartersDropdown] = useState([])
  const [medsosDropdown, setMedsosDropdown] = useState([])
  const [medsosData, setMedsosData] = useState([])
  const [countryCodeDropdown, setcountryCodeDropdown] = useState([])

  const [industryDropdown, setIndustryDropdown] = useState([])
  const [legalTypesDropdown, setLegalTypesDropdown] = useState([])
  const [countAnoContact, setCountAnoContact] = useState(0)
  const [allData, setAllData] = useState({})
  const [updateIntro] = useMutation(UPDATE_COMPANY_PROFILE)
  const context = {
    context: {
      headers: {
        'X-Hasura-Role':
          feature === 'holding-profile'
            ? 'holding-administrator'
            : 'organization-hr-admin',
      },
    },
  }
  useEffect(() => {
    if (dataEditIntro) {
      setAllData({
        id: dataEditIntro.id,
        address: dataEditIntro.main_office?.id,
        legal_name: dataEditIntro.legal_name,
        code_phone: dataEditIntro.country_code,
        brand_name: dataEditIntro.brand_name,
        logo: dataEditIntro.logo,
        phone: dataEditIntro.phone,
        industry_type: dataEditIntro.global_company_industry_type?.id,
        legal_type: dataEditIntro.company_legal_type?.id,
        profile_field: {
          description: dataEditIntro.profile_field?.description,
          banner: dataEditIntro.profile_field?.banner,
          headline: dataEditIntro.profile_field?.headline,
          founded: dataEditIntro.profile_field?.founded,
          website: dataEditIntro.profile_field?.website,
          specialities: dataEditIntro.profile_field?.specialities,
          linkedin: dataEditIntro.profile_field?.linkedin,
          twitter: dataEditIntro.profile_field?.twitter,
          facebook: dataEditIntro.profile_field?.facebook,
          email: dataEditIntro.profile_field?.email,
          fax: dataEditIntro.profile_field?.fax,
          structure_visible: dataEditIntro.profile_field?.structure_visible,
        },
      })
      setHeadquartersDropdown({
        value: dataEditIntro.main_office?.id,
        label: dataEditIntro.main_office?.office_name,
      })
      setIndustryDropdown({
        value: dataEditIntro.global_company_industry_type?.id,
        label: dataEditIntro.global_company_industry_type?.name,
      })
      setcountryCodeDropdown({
        value: dataEditIntro.country_code,
        label: dataEditIntro.country_code,
      })
      setLegalTypesDropdown(
        dataEditIntro.company_legal_type
          ? {
              value: dataEditIntro.company_legal_type?.id,
              label: dataEditIntro.company_legal_type?.legal_type,
            }
          : []
      )

      const tempProfileField = [
        dataEditIntro.profile_field?.linkedin,
        dataEditIntro.profile_field?.twitter,
        dataEditIntro.profile_field?.facebook,
        dataEditIntro.profile_field?.email,
        dataEditIntro.profile_field?.fax,
      ]

      const optionsDropdown = {
        0: {value: 'linkedin', label: 'Linkedin'},
        1: {value: 'twitter', label: 'Twitter'},
        2: {value: 'facebook', label: 'Facebook'},
        3: {value: 'email', label: 'Email'},
        4: {value: 'fax', label: 'Fax'},
      }

      const optionsValue = {
        0: dataEditIntro.profile_field?.linkedin,
        1: dataEditIntro.profile_field?.twitter,
        2: dataEditIntro.profile_field?.facebook,
        3: dataEditIntro.profile_field?.email,
        4: dataEditIntro.profile_field?.fax,
      }

      const tempDropdown = []
      const tempValue = []
      for (let index = 0; index < tempProfileField.length; index++) {
        if (tempProfileField[index]) {
          tempDropdown.push(optionsDropdown[index])
          tempValue.push(optionsValue[index])
        }
        setMedsosDropdown(tempDropdown)
        setMedsosData(tempValue)
        setCountAnoContact(tempDropdown?.length ? tempDropdown?.length - 1 : 0)
      }
    }
  }, [dataEditIntro])

  const {data, loading, error} = useQuery(GET_LIST_HEADQUARTERS, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
    ...context,
  })

  const {
    data: legalType,
    loading: legalTypeLoad,
    error: legalTypeError,
  } = useQuery(GET_LEGAL_TYPE, {
    wlb_skipPatch: true,
    ...context,
  })

  const {
    data: industry,
    loading: industryLoad,
    error: industryError,
  } = useQuery(GET_INDUSTRY_TYPE, {
    wlb_skipPatch: true,
    ...context,
  })

  const {
    data: countryCode,
    loading: countryCodeLoad,
    error: countryCodeError,
  } = useQuery(GET_GLOBAL_PHONE_CODE, {
    wlb_skipPatch: true,
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  if (loading || industryLoad || countryCodeLoad || legalTypeLoad) {
    return <Loading />
  } else if (
    error !== undefined ||
    industryError !== undefined ||
    legalTypeError !== undefined ||
    countryCodeError !== undefined
  ) {
    return (
      <div>
        {error}
        {industryError}
        {countryCodeError}
        {legalTypeError}
      </div>
    )
  }

  const headquarters = data?.company_addresses.map(options => ({
    value: options.id,
    label: options.office_name,
  }))

  const legalTypes = legalType?.company_legal_types.map(options => ({
    value: options.id,
    label: options.legal_type,
  }))

  const industries = industry?.global_company_industry_types.map(options => ({
    value: options.id,
    label: options.name,
  }))

  const countryCodes = countryCode?.global_phone_code.map(options => ({
    value: options.code,
    label: `${options.country} (${options.code})`,
  }))

  const handleClickOpen = () => {
    if (allData.profile_field && !allData.profile_field.headline) {
      setIsError(true)
      setTimeout(() => setIsError(false), 1000)
    } else {
      setPopup(true)
    }
  }
  const handleClose = () => {
    setPopup(false)
  }

  const handleAlert = status => {
    dialogCancel ? setDialogCancel(false) : setDialogCancel(true)
    if (dialogCancel && status !== 'notNow') {
      history.goBack()
    }
  }
  const mutationEditIntro = () => {
    updateIntro({
      variables: allData,
      ...context,
    })
      .then(() => {
        enqueueSnackbar('Edit Intro Success', {
          variant: 'success',
          autoHideDuration: 1000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Edit Intro failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
    history.goBack()
  }

  const handleChange = (name, eventData, profile, dropdown) => {
    if (profile) {
      setAllData({
        ...allData,
        profile_field: {
          ...allData.profile_field,
          [name]: eventData,
        },
      })
    } else if (dropdown) {
      setAllData({
        ...allData,
        [name]: eventData.value,
      })
      if (name === 'address') {
        setHeadquartersDropdown({
          label: eventData.label,
          value: eventData.value,
        })
      } else if (name === 'industry_type') {
        setIndustryDropdown({
          label: eventData.label,
          value: eventData.value,
        })
      } else if (name === 'legal_type') {
        setLegalTypesDropdown({
          label: eventData.label,
          value: eventData.value,
        })
      } else {
        setcountryCodeDropdown({
          label: eventData.label,
          value: eventData.value,
        })
      }
    } else {
      setAllData({
        ...allData,
        [name]: eventData,
      })
    }
  }

  const anoContacts = [
    {
      value: 'facebook',
      label: 'Facebook',
    },
    {
      value: 'linkedin',
      label: 'Linkedin',
    },
    {
      value: 'twitter',
      label: 'Twitter',
    },
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'fax',
      label: 'Fax',
    },
  ]

  const addTextFieldAnoContact = () => {
    const length = countAnoContact
    setCountAnoContact(length + 1)
  }

  // const delTextFieldAnoContact = id => {
  const delTextFieldAnoContact = id => {
    const dataMedsos = medsosDropdown
    const dataAnoContact = medsosData
    const delDataMedsos = medsosDropdown[id] && medsosDropdown[id].value
    const length = countAnoContact
    setCountAnoContact(length - 1)

    dataMedsos.splice(id, 1)
    dataAnoContact.splice(id, 1)
    // dataForm.splice(id, 1)
    let dataDelete = {}
    if (delDataMedsos === 'facebook') {
      dataDelete = {
        ...allData.profile_field,
        facebook: '',
      }
    } else if (delDataMedsos === 'linkedin') {
      dataDelete = {
        ...allData.profile_field,
        linkedin: '',
      }
    } else if (delDataMedsos === 'twitter') {
      dataDelete = {
        ...allData.profile_field,
        twitter: '',
      }
    } else if (delDataMedsos === 'email') {
      dataDelete = {
        ...allData.profile_field,
        email: '',
      }
    } else if (delDataMedsos === 'fax') {
      dataDelete = {
        ...allData.profile_field,
        fax: '',
      }
    }
    setAllData({
      ...allData,
      profile_field: dataDelete,
    })
    setMedsosDropdown(dataMedsos)
    setMedsosData(dataAnoContact)
  }

  const addDataAnoContact = (event, id) => {
    const data = medsosData
    const dataDropdown = medsosDropdown[id] && medsosDropdown[id].value

    if (data.length === id) {
      const add = [...data, event]

      setMedsosData(add)
    } else {
      const update = [...data]
      update[id] = event

      setMedsosData(update)
    }
    setAllData({
      ...allData,
      profile_field: {
        ...allData.profile_field,
        [dataDropdown]: event,
      },
    })
  }

  const addDataMedsosContact = (event, id) => {
    const data = medsosDropdown
    // const dataMedsos = medsosData[id] ? medsosData[id] : 'https://'
    if (data.length === id) {
      const add = [
        ...data,
        {
          label: event.label,
          value: event.value,
        },
      ]
      setMedsosDropdown(add)
    } else {
      const update = [...data]
      update[id].label = event.label
      update[id].value = event.value

      setMedsosDropdown(update)
    }
  }

  const anotherContact = index => {
    const htmlAnotherContact = []

    for (let count = 0; index >= count; count++) {
      htmlAnotherContact.push(
        <FlexStart key={count}>
          <Select
            className={classNames(
              classes.width35P,
              classes.marginTop16,
              classes.marginBot8
            )}
            id="options"
            placeholder="Select Options"
            options={anoContacts}
            value={medsosDropdown[count] ? medsosDropdown[count] : ''}
            onChange={event => addDataMedsosContact(event, count)}
            styles={{
              menu: provided => ({...provided, zIndex: 9999}),
            }}
          />
          <ThinTextfield
            fullWidth
            style={{marginTop: 16}}
            className={classNames(classes.marginLeft10, classes.height38)}
            margin="normal"
            onChange={event => {
              addDataAnoContact(event.target.value, count)
            }}
            variant="outlined"
            value={medsosData[count]}
            // error={!allData.name && isError}
            // helperText={
            // !allData.name && isError ? 'This field is required' : ''
            // }
          />
          {index === 0 ? (
            ''
          ) : (
            <LitleRedText
              onClick={() => delTextFieldAnoContact(count)}
              // onClick={delTextFieldAnoContact}
              className={classes.marginLeft10}
            >
              Remove
            </LitleRedText>
          )}
        </FlexStart>
      )
    }

    return htmlAnotherContact
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          {/* <Link
            to={{
              pathname: '/company/profile',
              state: {active: 'profile'},
            }}
          > */}
          <IconButton edge="end" aria-label="back" onClick={history.goBack}>
            <ArrowBack />
          </IconButton>
          {/* </Link> */}
          <AddEditTitle variant="h6" display="inline">
            Edit Intro
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button size="large" onClick={handleAlert} style={{marginRight: 12}}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="large"
          >
            Save
          </Button>
        </div>
      </FormToolbar>
      <Divider></Divider>
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Headline*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                handleChange('headline', event.target.value, true)
              }}
              variant="outlined"
              value={allData.profile_field?.headline}
              error={!allData.headline && isError}
              helperText={
                !allData.headline && isError ? 'This field is required' : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Headquarters Location</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              id="headquarters"
              className={classes.marginTop15}
              onChange={event => handleChange('address', event, false, true)}
              options={headquarters}
              value={headquartersDropdown}
              placeholder="Select Headquarters"
              styles={{
                menu: provided => ({...provided, zIndex: 9999}),
              }}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Legal Name</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                handleChange('legal_name', event.target.value, false)
              }}
              variant="outlined"
              value={allData.legal_name}
              // error={!allData.name && isError}
              // helperText={
              // !allData.name && isError ? 'This field is required' : ''
              // }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Brand Name</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                handleChange('brand_name', event.target.value, false)
              }}
              variant="outlined"
              value={allData && allData.brand_name}
              // error={!allData.name && isError}
              // helperText={
              // !allData.name && isError ? 'This field is required' : ''
              // }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Founded</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                format="yyyy-MM-dd"
                value={allData.profile_field?.founded}
                onChange={event => {
                  handleChange('founded', event, true)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputVariant="outlined"
                className={classes.customDatePicker}
              />
            </MuiPickersUtilsProvider>
            {/* <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                handleChange('founded', event.target.value, true)
              }}
              variant="outlined"
              value={
                allData.profile_field?.founded
              }
              error={!allData.name && isError}
              helperText={
              !allData.name && isError ? 'This field is required' : ''
              }
            /> */}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>6.</TitleNumber>
            <TitleName>Website</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                handleChange('website', event.target.value, true)
              }}
              variant="outlined"
              value={allData.profile_field?.website}
              // error={!allData.name && isError}
              // helperText={
              // !allData.name && isError ? 'This field is required' : ''
              // }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>7.</TitleNumber>
            <TitleName>Phone Number</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <FlexStart>
              <Select
                className={classNames(
                  classes.width60P,
                  classes.marginTop16,
                  classes.marginBot8,
                  classes.marginRight10
                )}
                id="options"
                placeholder="Country Code"
                options={countryCodes}
                value={countryCodeDropdown}
                onChange={event =>
                  handleChange('code_phone', event, false, true)
                }
                styles={{
                  menu: provided => ({...provided, zIndex: 9999}),
                }}
              />
              <ThinTextfield
                fullWidth
                margin="normal"
                style={{marginTop: 16}}
                className={classes.height38}
                onChange={event => {
                  handleChange('phone', event.target.value, false)
                }}
                variant="outlined"
                value={allData && allData.phone}
                // error={!allData.name && isError}
                // helperText={
                // !allData.name && isError ? 'This field is required' : ''
                // }
              />
            </FlexStart>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>8.</TitleNumber>
            <TitleName>Industry</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              id="industry"
              className={classes.marginTop15}
              onChange={event =>
                handleChange('industry_type', event, false, true)
              }
              options={industries}
              value={industryDropdown}
              placeholder="Select Industry"
              styles={{
                menu: provided => ({...provided, zIndex: 9999}),
              }}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>9.</TitleNumber>
            <TitleName>Type</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              id="legalTypes"
              className={classes.marginTop15}
              onChange={event => handleChange('legal_type', event, false, true)}
              options={legalTypes}
              value={legalTypesDropdown}
              placeholder="Select Legal Types"
              styles={{
                menu: provided => ({...provided, zIndex: 9999}),
              }}
            />
            {/* <ThinTextfield
              fullWidth
              disabled
              margin="normal"
              onChange={event => {
                handleChange('types', event.target.value)
              }}
              variant="outlined"
              value={allData && allData.types}
              error={!allData.name && isError}
              helperText={
              !allData.name && isError ? 'This field is required' : ''
              }
            /> */}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>10.</TitleNumber>
            <TitleName>Speciality</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                handleChange('specialities', event.target.value, true)
              }}
              variant="outlined"
              value={allData.profile_field?.specialities}
              // error={!allData.name && isError}
              // helperText={
              // !allData.name && isError ? 'This field is required' : ''
              // }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>11.</TitleNumber>
            <TitleName>
              Organization Structure Visibility
              <FieldInformationTooltip title="If the toggle is active, guest could see the company's organization structure" />
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={allData.profile_field?.structure_visible ?? true}
                  onChange={event => {
                    handleChange(
                      'structure_visible',
                      event.target.checked,
                      true
                    )
                  }}
                />
              }
              label={
                allData.profile_field?.structure_visible ?? true
                  ? 'Show'
                  : 'Hide'
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <Divider />
        <FormChildContainer
          className={classNames(classes.marginTop15, classes.width80P)}
        >
          <FormChildTitle>
            <TitleNumber>12.</TitleNumber>
            <TitleName>Another Contact</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {anotherContact(countAnoContact)}
            <LitleLightBlueText
              onClick={addTextFieldAnoContact}
              className={classes.marginTop10}
            >
              Add More
            </LitleLightBlueText>
            <TextHelper className={classes.margin0}></TextHelper>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        // name={allData.name}
        feature="Intro"
        type="Edit"
        mutation={mutationEditIntro}
      />
      <AlertDialog
        open={dialogCancel}
        handleClose={handleAlert}
        feature={`Cancel to Edit Intro?`}
        message={`You have unsaved changes. Are you sure want to cancel edit intro?`}
        type="Yes, Cancel"
        status="notNow"
      />
    </PaperContainer>
  )
}

export default withRouter(EditIntroProfile)
