import gql from 'graphql-tag'

export const ADD_TO_NOMINATION_HOLDING = gql`
  mutation addHoldingNominationPeople(
    $object: [holding_nomination_people_insert_input!]!
  ) {
    insert_holding_nomination_people(objects: $object) {
      affected_rows
    }
  }
`

export const UPDATE_HOLDING_NOMINATION_PEOPLE = gql`
  mutation update_holding_nomination_people(
    $id: Int
    $changes: holding_nomination_people_set_input
  ) {
    update_holding_nomination_people(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`
