import HoldingClassPage from './HoldingClassPageComponent'

export const HoldingClassPageConfig = {
  routes: [
    {
      path: '/holding-talent/classification',
      component: HoldingClassPage,
      state: 'holding-talent-classification',
      exact: true,
      searchable: false,
    },
  ],
}
