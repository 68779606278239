import React from 'react'
import {Divider} from '@material-ui/core'
import {
  ExpansionPanelStyle,
  ExpansionPanelSummaryStyle,
  ExpansionPanelDetailStyle,
  ItemClause,
  //   ClauseItemWrapper,
  //   CaluseItemHead,
  //   CaluseItemBody,
} from '../../SettingStyles'
import {
  FlexColumn,
  TextBlue,
  TextRed,
  FlexBetween,
} from '../../../../component/modalStyles'
import {Flex, TitleName} from '../../../../../../GlobalStyles'

import {getBudgetCalculationLabel} from '../../../businessTripHelper'
import {ConversionDisplay} from '../../../BusinessTripStyles'

export default function ListBudget({
  data,
  currency,
  noMargin = false,
  setDataBudget,
  dataBudget,
  setisUpdateDestination,
  setOpen,
  budgetType,
}) {
  const handleDelete = index => {
    setisUpdateDestination(true)
    const i = dataBudget.findIndex(i => i.city === data.city)
    const tempArray = dataBudget.slice()
    tempArray[i].budget.splice(index, 1)

    setDataBudget(tempArray)
  }

  return (
    <>
      {budgetType === 'spesific' ? (
        <ExpansionPanelStyle style={noMargin ? {marginTop: 0} : null}>
          <ExpansionPanelSummaryStyle>
            <TitleName>{data?.name}</TitleName>
          </ExpansionPanelSummaryStyle>
          <ExpansionPanelDetailStyle>
            <FlexColumn>
              {data?.budget?.length > 0 &&
                data?.budget?.map((row, index) => {
                  return (
                    <ItemClause key={index}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <FlexBetween>
                          <TitleName style={{padding: '20px'}}>
                            {row?.type?.label}
                          </TitleName>
                          <Flex
                            flexBetween
                            style={{
                              width: '10%',
                              paddingRight: '12px',
                            }}
                          >
                            <TextRed
                              style={{
                                cursor: 'pointer',
                                fontWeight: 'bold',
                              }}
                              onClick={() => handleDelete(index)}
                            >
                              Delete
                            </TextRed>
                            <TextBlue
                              style={{
                                cursor: 'pointer',
                                fontWeight: 'bold',
                              }}
                              onClick={() => {
                                setOpen({
                                  open: true,
                                  data: data,
                                  type: 'edit',
                                  index: index,
                                  values: {
                                    select: row?.type,
                                    checkbox: row.nominal !== null ? 2 : 1,
                                    nominal: row.nominal,
                                    budget_calculation: row.budget_calculation,
                                  },
                                })
                              }}
                            >
                              Edit
                            </TextBlue>
                          </Flex>
                        </FlexBetween>
                        <Divider />
                        <FlexColumn style={{width: '45%'}}>
                          <FlexBetween>
                            <TitleName style={{padding: '20px'}}>
                              Budget Component Type
                            </TitleName>
                            <TitleName style={{padding: '20px'}}>
                              {row.nominal !== null ? 'Strict' : 'Flexibel'}
                            </TitleName>
                          </FlexBetween>

                          {row.nominal !== null && (
                            <FlexBetween>
                              <TitleName style={{padding: '20px'}}>
                                Budget Amount
                              </TitleName>
                              <TitleName style={{padding: '20px'}}>
                                <ConversionDisplay
                                  from={currency}
                                  to={'idr'}
                                  value={row.nominal}
                                />
                              </TitleName>
                            </FlexBetween>
                          )}

                          <FlexBetween>
                            <TitleName style={{padding: '20px'}}>
                              Budget Calculation
                            </TitleName>
                            <TitleName style={{padding: '20px'}}>
                              {getBudgetCalculationLabel(row)}
                            </TitleName>
                          </FlexBetween>
                        </FlexColumn>
                      </div>
                    </ItemClause>
                  )
                })}
            </FlexColumn>
            <TitleName
              style={{
                paddingTop: 14,
                cursor: 'pointer',
                fontWeight: 'bold',
                color: '#039be5',
              }}
              onClick={() =>
                setOpen({
                  open: true,
                  data: data,
                  type: 'add',
                  values: {
                    select: undefined,
                    checkbox: 1,
                    nominal: null,
                    budget_calculation: 'multiply_by_days',
                  },
                })
              }
            >
              Add New Component
            </TitleName>
          </ExpansionPanelDetailStyle>
        </ExpansionPanelStyle>
      ) : (
        <FlexColumn>
          {data?.budget?.length > 0 &&
            data.budget.map((row, index) => {
              return (
                <ItemClause key={index}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <FlexBetween>
                      <TitleName style={{padding: '20px'}}>
                        {row?.type?.label}
                      </TitleName>
                      <Flex
                        flexBetween
                        style={{
                          width: '10%',
                          paddingRight: '12px',
                        }}
                      >
                        <TextRed
                          style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                          onClick={() => handleDelete(index)}
                        >
                          Delete
                        </TextRed>
                        <TextBlue
                          style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                          onClick={() => {
                            setOpen({
                              open: true,
                              data: data,
                              type: 'edit',
                              index: index,
                              values: {
                                select: row?.type,
                                checkbox: row.nominal !== null ? 2 : 1,
                                nominal: row.nominal,
                                budget_calculation: row.budget_calculation,
                              },
                            })
                          }}
                        >
                          Edit
                        </TextBlue>
                      </Flex>
                    </FlexBetween>
                    <Divider></Divider>
                    <FlexColumn style={{width: '45%'}}>
                      <FlexBetween>
                        <TitleName style={{padding: '20px'}}>
                          Budget Component Type
                        </TitleName>
                        <TitleName style={{padding: '20px'}}>
                          {row.nominal !== null ? 'Strict' : 'Flexibel'}
                        </TitleName>
                      </FlexBetween>
                      {row.nominal !== null && (
                        <FlexBetween>
                          <TitleName style={{padding: '20px'}}>
                            Budget Amount
                          </TitleName>
                          <TitleName style={{padding: '20px'}}>
                            <ConversionDisplay
                              from={currency}
                              to={'idr'}
                              value={row.nominal}
                            />
                          </TitleName>
                        </FlexBetween>
                      )}
                      <FlexBetween>
                        <TitleName style={{padding: '20px'}}>
                          Budget Calculation
                        </TitleName>
                        <TitleName style={{padding: '20px'}}>
                          {getBudgetCalculationLabel(row)}
                        </TitleName>
                      </FlexBetween>
                    </FlexColumn>
                  </div>
                </ItemClause>
              )
            })}
        </FlexColumn>
      )}
    </>
  )
}
