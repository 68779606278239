import React from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import {
  AppBar,
  CircularProgress,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  Paper,
  Button,
  Avatar,
  ButtonGroup,
  Link,
  Menu,
  MenuItem,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import {OrgChart} from '@worklifebeyond/wlb-utils-components'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'

import {useStyles, mapOrgChartNode, mapStrokeStyle} from './OSStyles'
import OUDetailsModal from './OUDetailsModal'

import DefaultAvatar from '../../../../../assets/images/default-avatar.png'

import {buildListToTree} from '../../../../../utils/helpers'
import {COMPANY_ID} from '../../../../../utils/globals'
import {
  GET_CHILD_ORG_UNIT_STRUCTURE,
  GET_TOP_ORG_UNIT_STRUCTURE,
} from '../../../../../graphql/queries'
import {
  SET_ORGANIZATION_ASSISTANT,
  SET_ORGANIZATION_LINETYPE,
} from '../../../../../graphql/mutations/company/organization/org-unit/editOrgUnit.mutation'

const EMPTY_ARRAY = []

const OSTreeModal = props => {
  const {open, onClose} = props

  const styles = useStyles()
  const instanceRef = React.useRef()

  const [detailsState, setDetailsState] = React.useState(null)
  const [listing, setListing] = React.useState(EMPTY_ARRAY)

  const [selected, setSelected] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const {loading, error} = useQuery(GET_TOP_ORG_UNIT_STRUCTURE, {
    wlb_skipPatch: true,
    skip: !open,
    fetchPolicy: 'network-only',
    variables: {
      company: COMPANY_ID,
    },
    onCompleted: data => {
      if (data) {
        setListing(data.top.map(node => mapOrgChartNode(node, null)))
      }
    },
  })

  const tree = React.useMemo(() => {
    return buildListToTree(listing)
  }, [listing])

  const handleDetailsClose = () => {
    setDetailsState({...detailsState, open: false})
  }

  const handleDetailsClick = data => {
    setDetailsState({open: true, ...data})
  }

  const handleNodeMenu = (node, ev) => {
    setSelected(node)
    setAnchorEl(ev.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleSetAssistant = next => {
    handleMenuClose()

    if (next) {
      const parent = selected.parent
      const children = listing.filter(node => node.parent === parent)

      let isRegular = 0

      for (const child of children) {
        if (!child.staff) {
          isRegular += 1
        }
      }

      if (isRegular < 2) {
        enqueueSnackbar(`Cannot set organization unit as assistant`)
        return
      }
    }

    const promise = client.mutate({
      mutation: SET_ORGANIZATION_ASSISTANT,
      variables: {
        id: selected.id,
        assistant: next,
      },
    })

    promise.then(
      () => {
        const word = next ? 'now' : 'no longer'
        enqueueSnackbar(`${selected.name} is ${word} assistant`)

        setListing(prev => {
          const arr = prev.slice()
          const index = prev.findIndex(item => item.id === selected.id)

          arr[index] = {...arr[index], staff: next}
          return arr
        })
      },
      () => {
        const word = next ? 'set' : 'unset'
        enqueueSnackbar(`Failed to ${word} ${next.name} as assistant`)
      }
    )
  }

  const handleSetStrokeStyle = next => {
    handleMenuClose()

    const promise = client.mutate({
      mutation: SET_ORGANIZATION_LINETYPE,
      variables: {
        id: selected.id,
        lineType: next,
      },
    })

    promise.then(
      () => {
        enqueueSnackbar(`${selected.name} line type set`)

        setListing(prev => {
          const arr = prev.slice()
          const index = prev.findIndex(item => item.id === selected.id)

          arr[index] = {...arr[index], strokeStyle: mapStrokeStyle(next)}
          return arr
        })
      },
      () => {
        enqueueSnackbar(`Failed to set ${next.name} line type`)
      }
    )
  }

  const expandNode = node => {
    const promise = client.query({
      query: GET_CHILD_ORG_UNIT_STRUCTURE,
      fetchPolicy: 'network-only',
      variables: {
        parent: node.id,
      },
    })

    promise.then(
      result => {
        const children = result.data.company_organizations

        if (children.length < 1) {
          enqueueSnackbar(`${node.name} doesn't have have any suborganizations`)
          return
        }

        setListing(prev => {
          const next = []
          const mapped = []

          const exists = new Set()

          for (const child of children) {
            exists.add(child.id)
            mapped.push(mapOrgChartNode(child, node))
          }

          for (const child of prev) {
            if (!exists.has(child.id)) {
              next.push(child)
            }
          }

          return next.concat(mapped)
        })
      },
      error => {
        console.error(error)

        const msg = `Failed to retrieve suborganizations for ${node.name}`
        enqueueSnackbar(msg, {variant: 'error'})
      }
    )
  }

  const collapseNode = node => {
    if (!node.children || node.children.length < 1) {
      return
    }

    const removals = new Set()
    removals.add(node.id)

    // NOTE(intrnl): working around an issue with react-zoom-pan-pinch where
    // collapsing the top-most node results in the node being out of bounds.
    if (node.parent === null) {
      const instance = instanceRef.current

      requestAnimationFrame(() => {
        instance.resetTransform()
      })
    }

    setListing(prev => {
      const next = []

      for (const item of prev) {
        if (removals.has(item.parent)) {
          removals.add(item.id)
          continue
        }

        next.push(item)
      }

      return next.length === prev.length ? prev : next
    })
  }

  const renderNode = React.useCallback(node => {
    // NOTE(intrnl): `staff` and `strokeStyle` is meant to bust the memoized render
    return (
      <OUNode
        data={node.data}
        staff={node.staff}
        strokeStyle={node.strokeStyle}
        hasChildren={!!node.children?.length}
        onDetails={handleDetailsClick}
        onExpand={() => expandNode(node)}
        onCollapse={() => collapseNode(node)}
        onMenu={ev => handleNodeMenu(node, ev)}
      />
    )
  }, [])

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <AppBar className={styles.toolbar}>
        <Toolbar>
          <Typography className={styles.toolbarTitle}>
            Struktur Organisasi
          </Typography>
          <IconButton
            onClick={onClose}
            title="Close dialog"
            edge="end"
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {error ? (
        <div className={styles.container}>
          <div>Terjadi kesalahan saat mengambil detail organisasi</div>
        </div>
      ) : loading || !tree[0] ? (
        <div className={styles.container}>
          <CircularProgress style={{placeSelf: 'center'}} />
        </div>
      ) : (
        <>
          <TransformWrapper
            options={{
              // NOTE(intrnl): this is about the limit of the orgchart, any less
              // and the line path drawing will break
              minScale: 0.75,
              maxScale: 2,
            }}
          >
            {instance => (
              <div className={styles.container}>
                {/* NOTE(intrnl): This seems to be the only best way of retrieving the transform's instance */}
                {((instanceRef.current = instance), null)}

                <TransformComponent>
                  <OrgChart
                    data={tree[0]}
                    render={renderNode}
                    chartId="osChart"
                  />
                </TransformComponent>

                <ButtonGroup
                  variant="contained"
                  color="primary"
                  className={styles.zoomContainer}
                >
                  <Button onClick={instance.zoomIn}>+</Button>
                  <Button onClick={instance.zoomOut}>-</Button>
                </ButtonGroup>
              </div>
            )}
          </TransformWrapper>

          {selected && (
            <Menu
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleSetAssistant(!selected.staff)}>
                {selected.staff ? 'Tidak Disetel' : 'Tetapkan'} Sebagai{' '}
                {selected.parentNode.name} asisten
              </MenuItem>

              {selected.strokeStyle === 'solid' ? (
                <MenuItem onClick={() => handleSetStrokeStyle(2)}>
                  Ubah Garis menjadi *Bergaris / Solid*
                </MenuItem>
              ) : (
                <MenuItem onClick={() => handleSetStrokeStyle(1)}>
                  Ubah jenis garis menjadi bawaan
                </MenuItem>
              )}
            </Menu>
          )}

          <OUDetailsModal {...detailsState} onClose={handleDetailsClose} />
        </>
      )}
    </Dialog>
  )
}

export default OSTreeModal

// NOTE(intrnl): we'll be memoizing these heavily because we can have a
// noticeable perf regression when you expand the organization structure nodes
// large enough

// we do not need to make our callbacks memoized because we have a custom
// equality check that only allows rerenders if the node ID or hasChildren
// differs

// we need a `hasChildren` property because the `children` array is actually
// coming from a mutation which means that we cannot actually check equality
// from there.

const DEFAULT_VISIBLE_PLACEMENTS = 5
const EMPTY_PROFILE = {
  id: null,
  title: 'Unassigned',
  people_work_placements: [],
}

/// <OUNode />
const isOUNodeEqual = (a, b) => {
  return (
    a.data.id === b.data.id &&
    a.hasChildren === b.hasChildren &&
    a.staff === b.staff &&
    a.strokeStyle === b.strokeStyle
  )
}

const OUNode = React.memo(props => {
  const {data, hasChildren, onDetails, onExpand, onCollapse, onMenu} = props

  const styles = useStyles()

  const level = data.organization_level
  const profiles = data.company_job_profiles

  const handleArrowClick = hasChildren ? onCollapse : onExpand

  const handleDetailsClick = jobProfileId => {
    onDetails({
      organizationId: data.id,
      organizationName: data.name,
      organizationLevel: level,
      jobProfileId: jobProfileId,
    })
  }

  return (
    <div className={styles.wrapper}>
      <Paper className={styles.card}>
        <div className={styles.header}>
          <div className={styles.column}>
            <b className={styles.title}>{data.name}</b>
            <div className={styles.legend}>
              <div
                className={styles.legendColor}
                style={{backgroundColor: level?.color_hex || '#e5e5e5'}}
              />
              {level ? (
                <span className={styles.legendTitle}>{level.name}</span>
              ) : (
                <span className={styles.legendTitle} style={{color: '#747272'}}>
                  None
                </span>
              )}
            </div>
          </div>

          {data.parent && onMenu && (
            <IconButton size="small" onClick={onMenu}>
              <MoreHorizIcon color="primary" />
            </IconButton>
          )}
        </div>

        <div className={styles.grid} style={{'--items': profiles.length || 1}}>
          {profiles.length > 0 ? (
            profiles.map(prof => (
              <OUSubnode
                key={prof.id}
                data={prof}
                onDetails={handleDetailsClick}
              />
            ))
          ) : (
            <OUSubnode data={EMPTY_PROFILE} />
          )}
        </div>

        {data.organization_children_aggregate.aggregate.count > 0 && (
          <Button className={styles.more} onClick={handleArrowClick}>
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{transform: hasChildren ? 'rotate(180deg)' : ''}}
              title={`Show ${hasChildren ? 'less' : 'more'}`}
            />
          </Button>
        )}
      </Paper>
    </div>
  )
}, isOUNodeEqual)

/// <OUSubnode />
const isOUSubnodeEqual = (a, b) => {
  return a.data.id === b.data.id
}

const OUSubnode = React.memo(props => {
  const {data, onDetails} = props

  const styles = useStyles()
  const [visible, setVisible] = React.useState(DEFAULT_VISIBLE_PLACEMENTS)

  const placements = data.people_work_placements
  const length = placements.length

  const canSeeMore = visible < length
  const canSeeLess = visible > DEFAULT_VISIBLE_PLACEMENTS

  const handleSeeMore = ev => {
    ev.preventDefault()

    const next = visible + 5
    setVisible(Math.min(next, length))
  }

  const handleSeeLess = ev => {
    ev.preventDefault()

    setVisible(DEFAULT_VISIBLE_PLACEMENTS)
  }

  return (
    <div className={styles.subcard}>
      <div className={styles.header}>
        <b className={styles.title}>{data.title}</b>

        {data !== EMPTY_PROFILE && (
          <IconButton
            className={styles.expand}
            onClick={() => {
              if (onDetails) {
                onDetails(data.id)
              }
            }}
          >
            <OpenInNewIcon className={styles.expandIcon} />
          </IconButton>
        )}
      </div>

      <div className={styles.list}>
        {placements.length > 0 ? (
          placements.slice(0, visible).map(emp => (
            <div key={emp.id} className={styles.item}>
              <Avatar
                src={emp.global_user.avatar || DefaultAvatar}
                className={styles.itemAvatar}
              />

              <span className={styles.itemName}>{emp.global_user.name}</span>
            </div>
          ))
        ) : (
          <div className={styles.item}>
            <Avatar src={DefaultAvatar} className={styles.itemAvatar} />
            <span className={styles.itemName}>Unassigned</span>
          </div>
        )}

        {(canSeeMore || canSeeLess) && (
          <div className={styles.item}>
            {canSeeMore && (
              <Link
                component="button"
                color="secondary"
                className={styles.link}
                onClick={handleSeeMore}
              >
                +{length - visible} more
              </Link>
            )}

            <span className={styles.spacer} />

            {canSeeLess && (
              <Link
                component="button"
                color="secondary"
                className={styles.link}
                onClick={handleSeeLess}
              >
                See less
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  )
}, isOUSubnodeEqual)
