import React, {useState} from 'react'
import {
  Typography,
  Dialog,
  IconButton,
  DialogActions,
  Button,
  Divider,
} from '@material-ui/core'
import {
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
} from '../../../../../../GlobalStyles'

const ValidationCommittee = props => {
  const {handleClose, open, dataValidation} = props
  const [limit, setLimit] = useState(5)

  const _handleClose = () => {
    handleClose()
    setLimit(5)
  }

  return (
    <Dialog
      onClose={_handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <ModalHeader>
        <div>
          <Typography className="title" style={{}}>
            Some position are not configured
          </Typography>
        </div>
        <IconButton aria-label="close" onClick={_handleClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContentEvent>
        <Typography variant="body2">
          There some position are not configure:
        </Typography>
        {dataValidation?.slice(0, limit).map((data, key) => (
          <div
            key={key}
            style={{
              borderLeft: '2px solid #ef4d5e',
              paddingLeft: 10,
              margin: '12px 0px',
            }}
          >
            <Typography
              variant="body2"
              style={{color: '#ef4d5e'}}
            >{`Row ${data.row}: ${data.title}`}</Typography>
          </div>
        ))}
        {dataValidation?.length >= 5 && limit <= 5 ? (
          <Typography
            variant="body2"
            style={{color: '#039be5', cursor: 'pointer'}}
            onClick={() => setLimit(dataValidation?.length)}
          >
            {dataValidation?.length - 5 === 0 ? '' : 'See more'}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            style={{color: '#039be5', cursor: 'pointer'}}
            onClick={() => setLimit(5)}
          >
            {dataValidation?.length >= 5 && 'See less'}
          </Typography>
        )}
      </ModalContentEvent>
      <Divider style={{margin: '12px 0px'}} />
      <DialogActions>
        <Button onClick={_handleClose}>Cancel</Button>
        <Button variant="contained" onClick={_handleClose} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ValidationCommittee
