import React, {useState} from 'react'
import classNames from 'classnames'
// import Select from 'react-select'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {withRouter, Link} from 'react-router-dom'

import {Icon, IconButton, Button, Divider, TextField} from '@material-ui/core'

import {CHECK_CODE_JOB_FUNCTION} from '../../../../graphql/queries'
import helperStyle, {
  OrganizationContainer,
  BigTextBlackBold,
  WrapperContent,
} from '../../OrganizationPageStyles'

import {
  EnhancedToolbar,
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  PaperContainer,
  // TextHelper,
  TitleName,
  TitleNumber,
} from '../../../../GlobalStyles'

import AddEditPopup from '../../../shared-components/popup/AddEditPopupFreeText'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import AppliedContent from '../../../shared-components/popup/AppliedContent'

import {
  ADD_JOB_FUNCTION,
  EDIT_JOB_FUNCTION,
} from '../../../../graphql/mutations'
import {COMPANY_ID} from '../../../../utils/globals'

const AddEditJobFunction = props => {
  const {status, data} = props
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const dataJFFunction = data
    ? {
        code: data.code,
        name: data.name,
        description: data.description,
        company: COMPANY_ID,
      }
    : {
        code: '',
        name: '',
        description: '',
        // company: COMPANY_ID,
      }

  const hasJobProfile =
    data &&
    data.company_employee_positions_aggregate &&
    data.company_employee_positions_aggregate.aggregate &&
    data.company_employee_positions_aggregate.aggregate.count > 0

  const [allDataFrom, setAllDataFrom] = useState(dataJFFunction)
  const [isError, setIsError] = useState(false)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [dialogCancel, setDialogCancel] = useState(false)

  const [addData] = useMutation(ADD_JOB_FUNCTION)
  const [editData] = useMutation(EDIT_JOB_FUNCTION)

  const {data: checkCodeJobFunction} = useQuery(CHECK_CODE_JOB_FUNCTION, {
    variables: {
      company: COMPANY_ID,
      code: allDataFrom && allDataFrom.code,
    },
    fetchPolicy: 'cache-and-network',
    skip: status === 'Add' && !allDataFrom.code,
  })

  // const nameChecker =
  //   dataCheck &&
  //   dataCheck.company_employee_ranks &&
  //   dataCheck.company_employee_ranks.some(
  //     v => v.name.toLowerCase() === allDataFrom.name.toLowerCase()
  //   )

  const codeChecker =
    checkCodeJobFunction &&
    checkCodeJobFunction.job_family_functions_aggregate &&
    checkCodeJobFunction.job_family_functions_aggregate.aggregate &&
    checkCodeJobFunction.job_family_functions_aggregate.aggregate.count > 0

  // const checkSameNameWithAdd = data && data.name === allDataFrom.name
  const checkSameCodeWithAdd =
    allDataFrom && allDataFrom.code && allDataFrom.code.length > 1
      ? data && data.code === allDataFrom.code
      : false

  const checkCharaCode = allDataFrom && allDataFrom.code.length > 30
  const checkCharaName = allDataFrom && allDataFrom.name.length > 100

  const openDialogConfirm = () => {
    if (
      !allDataFrom.code ||
      !allDataFrom.name ||
      checkCharaCode ||
      checkCharaName ||
      // (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd) ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      setIsError(true)
      setTimeout(() => setIsError(false), 2000)
    } else {
      dialogConfirm ? setDialogConfirm(false) : setDialogConfirm(true)
    }
  }

  const handleAddJFFunction = () => {
    if (status === 'Add') {
      const dataForAdd = {
        objects: [allDataFrom],
      }
      addData({variables: dataForAdd})
        .then(() => {
          enqueueSnackbar('Job Function Added', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Added Job Function failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else if (status === 'Edit') {
      const dataForEdit = {
        id: data && data.id,
        object: {
          code: allDataFrom.code,
          name: allDataFrom.name,
          description: allDataFrom.description,
          family: null,
        },
      }
      editData({variables: dataForEdit})
        .then(() => {
          enqueueSnackbar('Job Function Edited', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(() => {
          enqueueSnackbar('Edit Job Function failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
    props.history.push({
      pathname: '/organization/job-function',
      state: {active: 'job-function', searchable: true},
    })
  }

  const handleAlert = status => {
    dialogCancel ? setDialogCancel(false) : setDialogCancel(true)
    if (dialogCancel && status !== 'notNow') {
      props.history.push({
        pathname: '/organization/job-function',
        state: {active: 'job-function', searchable: true},
      })
    }
  }

  return (
    <OrganizationContainer>
      <PaperContainer
        className={classNames(classes.height600px, classes.notViewScroll)}
      >
        <EnhancedToolbar className={classes.paddingLeft0}>
          <WrapperContent>
            <IconButton
              component={Link}
              to={{
                pathname: '/organization/job-function',
                state: {active: 'job-function', searchable: true},
              }}
              edge="end"
              aria-label="back"
            >
              <Icon>arrow_back</Icon>
            </IconButton>
            <BigTextBlackBold className={classes.marginLeft10}>{`${
              status === 'Add' ? 'Tambah' : 'Ubah'
            } Jenis Jabatan`}</BigTextBlackBold>
          </WrapperContent>
          <div>
            <Button
              size="large"
              onClick={handleAlert}
              style={{marginRight: 12}}
            >
              Batal
            </Button>
            <Button
              className={classes.marginLeft10}
              variant="contained"
              color="primary"
              size="large"
              onClick={openDialogConfirm}
            >
              Simpan
            </Button>
          </div>
        </EnhancedToolbar>
        <Divider />
        <OrganizationContainer>
          <FormContainer id="top">
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>1.</TitleNumber>
                <TitleName>Kode Jenis Jabatan *</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="functionCode"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  className={classes.helperMarginLeft0}
                  fullWidth
                  placeholder="Tambah Kode Jenis Jabatan"
                  value={allDataFrom.code}
                  margin="normal"
                  error={
                    (!allDataFrom.code && isError) ||
                    checkCharaCode ||
                    (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
                  }
                  helperText={
                    !allDataFrom.code && isError
                      ? 'Bagian ini diperlukan'
                      : (codeChecker
                        ? !checkSameCodeWithAdd
                        : checkSameCodeWithAdd)
                      ? 'Kode Jenis Jabatan sudah ada'
                      : checkCharaCode
                      ? 'Jumlah karakter maksimum adalah 30 karakter'
                      : 'Kode Jenis Jabatan harus unik'
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      code: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>2.</TitleNumber>
                <TitleName>Nama Jenis Jabatan*</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgName"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  className={classes.helperMarginLeft0}
                  fullWidth
                  placeholder="Tambah Nama Jenis Jabatan"
                  value={allDataFrom.name}
                  margin="normal"
                  error={
                    (!allDataFrom.name && isError) || checkCharaName
                    // ||(nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
                  }
                  helperText={
                    !allDataFrom.name && isError
                      ? 'Bagian ini diperlukan'
                      : checkCharaName
                      ? 'Jumlah karakter maksimum adalah 30 karakter'
                      : ''
                  }
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      name: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>3.</TitleNumber>
                <TitleName>Deskripsi</TitleName>
              </FormChildTitle>
              <FormChildInput>
                <TextField
                  id="orgName"
                  style={{padding: '0px'}}
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  fullWidth
                  placeholder="Tambah Deskripsi"
                  value={allDataFrom.description}
                  margin="normal"
                  className={classes.multilinePadding}
                  multiline
                  rows="5"
                  // error={
                  //   !allDataFrom.name && isError
                  // }
                  // helperText={}
                  onChange={event => {
                    setAllDataFrom({
                      ...allDataFrom,
                      description: event.target.value,
                    })
                  }}
                  variant="outlined"
                />
              </FormChildInput>
            </FormChildContainer>
          </FormContainer>
        </OrganizationContainer>
      </PaperContainer>
      {hasJobProfile ? (
        <AddEditPopup
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          mutation={handleAddJFFunction}
          header={'Konfirmasi Perubahan?'}
          body={
            <div>
              <AppliedContent
                applied={data && data.company_employee_positions}
                classes={classes}
                target="Jenis Jabatan"
              />
              Apakah Anda yakin ingin menyimpan perubahan?
            </div>
          }
          buttonClose="Batal"
          button="Konfirmasi"
        />
      ) : (
        <AddEditPopup
          open={dialogConfirm}
          handleClose={openDialogConfirm}
          mutation={handleAddJFFunction}
          header={CONFIRMATION[status].header.replace('[name]', data?.name)}
          body={CONFIRMATION[status].body.replace('[name]', data?.name)}
          button={CONFIRMATION[status].button}
          buttonClose="Batal"
        />
      )}
      <AlertDialog
        open={dialogCancel}
        handleClose={handleAlert}
        feature={`Batal Untuk ${data ? 'Ubah' : 'Tambah'} Jenis Jabatan?`}
        message={`Anda memiliki perubahan yang belum disimpan. Anda yakin ingin membatalkan ${
          data
            ? `ubah Jenis Jabatan - ${data.name}`
            : 'menambahkan Jenis Jabatan baru'
        }?`}
        type="Yes, Cancel"
        positiveTitleButton="Ya, Batal"
        status="notNow"
      />
    </OrganizationContainer>
  )
}

export default withRouter(AddEditJobFunction)

const CONFIRMATION = {
  Add: {
    header: 'Tambah Jenis Jabatan',
    body: 'Apakah Anda yakin ingin menambahkan Jenis Jabatan?',
    button: 'Tambah',
  },
  Edit: {
    header: 'Ubah [name]',
    body: 'Apakah Anda yakin ingin mengubah Jenis Jabatan - [name]?',
    button: 'Ubah',
  },
}
