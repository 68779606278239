import {
  Avatar,
  Divider,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState} from 'react'
import {BoldTypography} from '../../../components/typography/TypographyStyles'
import {
  EnhancedToolbar,
  PaperContainer,
  SkyBlueIcon,
} from '../../../GlobalStyles'

import {
  NewTableOptions,
  newTableStyles,
} from '../../shared-components/table/TableOptions'
import {
  GET_DROPDOWN_EDIT_MEMBER,
  GET_HOLDING_MEMBER_CONFIGURING,
} from '../../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import Loading from '../../../components/loading/LoadingComponent'
import {COMPANY_ID} from '../../../utils/globals'
import {holdingRolesContext, selectProps} from '../../../utils/helpers'
import {UPDATE_HOLDING_RELATION} from '../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import AddEdit from '../../shared-components/popup/AddEditPopup'
import {Link} from 'react-router-dom'

export default function ConfiguringComponent(props) {
  const tableClasses = newTableStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [idEdit, setIdEdit] = useState('')
  const [dataToEdit, setDataToEdit] = useState({
    version: null,
    member_category: null,
    member_cluster: null,
    member_class: null,
    member_subcluster: null,
  })
  const [openConfirmation, setOpenConfirmation] = useState(false)

  const [confirmType, setConfirmType] = useState('')

  const [mutationUpdateRelation] = useMutation(UPDATE_HOLDING_RELATION)

  const {data, error, refetch} = useQuery(GET_HOLDING_MEMBER_CONFIGURING, {
    variables: {
      company: COMPANY_ID,
      search: `%${props.searchText}%`,
    },
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const {data: dataDropdown, error: errorDropdown} = useQuery(
    GET_DROPDOWN_EDIT_MEMBER,
    {
      variables: {
        company: COMPANY_ID,
        version: dataToEdit.version,
        cluster: dataToEdit.member_cluster,
      },
      ...holdingRolesContext,
      fetchPolicy: 'cache-and-network',
    }
  )

  const handleOpenEdit = data => {
    setIdEdit(data.id)
    setDataToEdit({
      version: data.holding_version && data.holding_version.id,
      member_category: data.holding_category && data.holding_category.id,
      member_cluster: data.holding_cluster && data.holding_cluster.id,
      member_class: data.holding_class && data.holding_class.id,
      member_subcluster: data.holding_subcluster && data.holding_subcluster.id,
    })
  }

  const handleCloseEdit = () => {
    setIdEdit('')
  }

  const handleOpenConfirmation = type => {
    setOpenConfirmation(true)
    setConfirmType(type)
  }

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
  }

  const handleAction = () => {
    if (confirmType === 'edit') {
      mutationUpdateRelation({
        variables: {id: idEdit, changes: dataToEdit},
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          refetch()
          handleCloseEdit()
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    } else {
      handleCloseEdit()
    }
  }

  if (
    (data === undefined && error === undefined) ||
    (dataDropdown === undefined && errorDropdown === undefined)
  ) {
    return <Loading />
  } else if (error !== undefined || errorDropdown !== undefined) {
    return <div>{error || errorDropdown}</div>
  }

  const renderDropdown = (value, option, state) => {
    return (
      <TextField
        variant="outlined"
        size="small"
        select
        value={value}
        onChange={e => {
          let objValue = {}
          if (state === 'version') {
            objValue = {
              [state]: e.target.value,
              member_category: null,
              member_cluster: null,
              member_class: null,
              member_subcluster: null,
            }
          } else if (state === 'member_cluster') {
            objValue = {
              ...dataToEdit,
              [state]: e.target.value,
              member_subcluster: null,
            }
          } else {
            objValue = {...dataToEdit, [state]: e.target.value}
          }
          setDataToEdit(objValue)
        }}
        SelectProps={selectProps(value)}
      >
        <MenuItem value={null} disabled>
          Select
        </MenuItem>
        {option &&
          option.map((opt, i) => (
            <MenuItem value={opt && opt.id} key={i}>
              {opt && opt.name}
            </MenuItem>
          ))}
      </TextField>
    )
  }
  const isNullSome = Object.values(dataToEdit).some(o => o === null)

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Configuring</Typography>
      </EnhancedToolbar>
      <Divider />
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontSize: 14}}
          options={{...NewTableOptions, paging: true, sorting: true}}
          columns={[
            {
              title: 'Company',
              field: 'company_member_profile.brand_name',
              cellStyle: {
                wordBreak: 'break-word',
                minWidth: 150,
              },
              render: row => (
                <div style={{display: 'flex', minWidth: 200}}>
                  <Avatar
                    variant="rounded"
                    src={row.company_member_profile.logo}
                    style={{marginRight: 16}}
                  ></Avatar>
                  <Link
                    to={{
                      pathname: `/member/list/${row.company_member_profile.id}`,
                      state: {active: 'detail-company'},
                    }}
                    style={{color: '#000', textDecoration: 'none'}}
                  >
                    <BoldTypography variant="body2" gutterBottom>
                      {row.company_member_profile.brand_name}
                    </BoldTypography>
                    <Typography variant="caption">
                      {row.company_member_profile.legal_name}
                    </Typography>
                  </Link>
                </div>
              ),
            },
            {
              title: 'Version',
              field: 'holding_version.name',
              render: row =>
                row.id === idEdit
                  ? renderDropdown(
                      dataToEdit.version,
                      dataDropdown.holding_version,
                      'version'
                    )
                  : row.holding_version && row.holding_version.name,
            },
            {
              title: 'Category',
              field: 'holding_category.name',
              render: row =>
                row.id === idEdit
                  ? renderDropdown(
                      dataToEdit.member_category,
                      dataDropdown.holding_categories,
                      'member_category'
                    )
                  : row.holding_category && row.holding_category.name,
            },
            {
              title: 'Cluster',
              field: 'holding_cluster.name',
              render: row =>
                row.id === idEdit
                  ? renderDropdown(
                      dataToEdit.member_cluster,
                      dataDropdown.holding_clusters,
                      'member_cluster'
                    )
                  : row.holding_cluster && row.holding_cluster.name,
            },
            {
              title: 'Subcluster',
              field: 'holding_subcluster.name',
              render: row =>
                row.id === idEdit
                  ? renderDropdown(
                      dataToEdit.member_subcluster,
                      dataDropdown.holding_subcluster,
                      'member_subcluster'
                    )
                  : row.holding_subcluster && row.holding_subcluster.name,
            },
            {
              title: 'Class',
              field: 'holding_class.name',
              render: row =>
                row.id === idEdit
                  ? renderDropdown(
                      dataToEdit.member_class,
                      dataDropdown.holding_classes,
                      'member_class'
                    )
                  : row.holding_class && row.holding_class.name,
            },

            {
              title: '',
              field: 'action',
              filtering: false,
              render: row =>
                row.id === idEdit ? (
                  <div style={{display: 'flex'}}>
                    <IconButton
                      disabled={isNullSome}
                      onClick={() => handleOpenConfirmation('edit')}
                    >
                      <SkyBlueIcon color={isNullSome ? 'disabled' : ''}>
                        done
                      </SkyBlueIcon>
                    </IconButton>
                    <IconButton
                      onClick={() => handleOpenConfirmation('discard')}
                    >
                      <Icon color="secondary">close</Icon>
                    </IconButton>
                  </div>
                ) : (
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <IconButton onClick={() => handleOpenEdit(row)}>
                      <SkyBlueIcon>edit</SkyBlueIcon>
                    </IconButton>
                  </div>
                ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={data.holding_relations}
        />
      </div>
      <AddEdit
        open={openConfirmation}
        handleClose={handleCloseConfirmation}
        type={confirmType}
        name={confirmType === 'edit' && 'this company member'}
        feature=""
        mutation={handleAction}
      />
    </PaperContainer>
  )
}
