import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import TaxPayrollSettings from './TaxPayrollSettings'
import DetailTaxPayrollSettings from './DetailTaxPayrollSettings'

const Index = props => {
  const {activeComponent, setActiveComponent} = props

  const [currentPage, setCurrentPage] = useState(0)
  const [selectedId, setSelectedId] = useState()

  if (currentPage === 0) {
    return (
      <TaxPayrollSettings
        activeComponent={activeComponent}
        setActiveComponent={setActiveComponent}
        setCurrentPage={setCurrentPage}
        setSelectedId={setSelectedId}
      />
    )
  }

  return (
    <DetailTaxPayrollSettings
      activeComponent={activeComponent}
      setActiveComponent={setActiveComponent}
      setCurrentPage={setCurrentPage}
      selectedId={selectedId}
    />
  )
}

export default withRouter(Index)
