import styled from 'styled-components'

export const WrapperTitleChart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .title {
    font-family: Muli;
    font-size: 18px;
    font-weight: 600;
    color: #055469;
    margin: 0px;
    margin-right: 18px;
  }
`
