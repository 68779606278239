import gql from 'graphql-tag'

//Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/Dictionaries/INSERT%20DICTIONARY.md
export const ADD_COMPETENCY = gql`
  mutation($objects: [competency_dictionaries_insert_input!]!) {
    insert_competency_dictionaries(objects: $objects) {
      affected_rows
    }
  }
`
