import React from 'react'
import {Paper, FormControlLabel, Radio, IconButton} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import CreateIcon from '@material-ui/icons/Create'
import {TextDarkBlue} from '../../../PayrollPageStyles'

const BankRadioItem = ({
  value,
  selectedData,
  setSelectedData,
  deleteButtonAction,
  editButtonAction,
}) => {
  return (
    <Paper
      key={value?.id}
      variant="outlined"
      style={{
        background: '#EFF2F4',
        padding: '1px 10px',
        margin: '5px 0',
        minWidth: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FormControlLabel
        value={value?.id?.toString()}
        control={<Radio />}
        style={{flex: 1}}
        label={
          <>
            <span>
              <TextDarkBlue>{value?.bank_name}</TextDarkBlue>
            </span>

            <br />
            <span>{`${value?.account_name} - ${value?.account_number}`}</span>
          </>
        }
      />
      <div>
        {value?.isAdminAllowedToWrite && (
          <IconButton
            aria-label="edit"
            style={{color: '#039be5'}}
            onClick={() => {
              setSelectedData({
                ...selectedData,
                ...value,
              })
              editButtonAction()
            }}
          >
            <CreateIcon style={{fontSize: '20px'}} />
          </IconButton>
        )}

        {value?.isAdminAllowedToWrite && !value?.isMain && (
          <IconButton
            aria-label="delete"
            style={{color: '#ef4d5e'}}
            onClick={() => {
              setSelectedData({
                id: value.id,
                name: `${value.bank_name} - ${value.account_name}`,
              })
              deleteButtonAction()
            }}
          >
            <ClearIcon style={{fontSize: '20px'}} />
          </IconButton>
        )}
      </div>
    </Paper>
  )
}

export default BankRadioItem
