import React, {useState} from 'react'
import {
  PaperContainer,
  FormContainer,
  AddEditTitle,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  ThinTextfield,
  FormToolbar,
  FormTitleContainer,
} from '../../../../../GlobalStyles'
import {
  Divider,
  IconButton,
  TextField,
  MenuItem,
  Button,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Link, withRouter} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {ADD_NEW_KPI_ASPECT} from '../../../../../graphql/mutations'
import {CHECK_CODE_ASPECT} from '../../../../../graphql/queries'
import {useSnackbar} from 'notistack'
import {COMPANY_ID} from '../../../../../utils/globals'
import Loading from '../../../../../components/loading/LoadingComponent'
import AddEdit from '../../../../shared-components/popup/AddEditPopupFreeText'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {status_data} from '../../../../shared-components/data/SharedData'

function AddAspect(props) {
  const {enqueueSnackbar} = useSnackbar()
  const [allDataForm, setAllDataForm] = useState({
    code: '',
    name: '',
    description: '',
    status: 'ACTIVE',
  })
  const [isError, setIsError] = useState(false)
  const [openPopup, setPopup] = useState(false)

  const handleClickOpen = () => {
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }
  const [mutationAddAspect] = useMutation(ADD_NEW_KPI_ASPECT)

  const {data: checkCodeAspect, error: checkCodeAspectError} = useQuery(
    CHECK_CODE_ASPECT,
    {
      variables: {
        company: COMPANY_ID,
        code: allDataForm && allDataForm.code,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  if (checkCodeAspect === undefined && checkCodeAspectError === undefined) {
    return <Loading />
  } else if (checkCodeAspectError !== undefined) {
    return JSON.stringify(checkCodeAspectError)
  }

  const codeChecker =
    checkCodeAspect &&
    checkCodeAspect.kpi_aspects_aggregate &&
    checkCodeAspect.kpi_aspects_aggregate.aggregate &&
    checkCodeAspect.kpi_aspects_aggregate.aggregate.count > 0

  const addAspect = () => {
    if (
      !allDataForm.code ||
      allDataForm.code.length > 50 ||
      !allDataForm.name ||
      !allDataForm.description ||
      codeChecker
    ) {
      const ids = document.getElementById('top')
      ids.scrollIntoView({behavior: 'smooth'})
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      const data = {
        code: allDataForm.code,
        name: allDataForm.name,
        description: allDataForm.description,
        status: allDataForm.status,
      }

      mutationAddAspect({variables: {data}})
        .then(() => {
          enqueueSnackbar('Success Update data', {variant: 'success'})
          props.history.push({
            pathname: '/performance/kpi/aspect',
            state: {active: 'aspect', searchable: true},
          })
        })
        .catch(() => {
          enqueueSnackbar('Update data error, please try again', {
            variant: 'error',
          })
        })
    }
  }
  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link
            to={{
              pathname: '/performance/kpi/aspect',
              state: {active: 'aspect', searchable: true},
            }}
          >
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle display="inline" variant="h6">
            Tambahkan Aspek {GLOBAL_GLOSSARY.performance.Goal}
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            color="danger"
            size="large"
            component={Link}
            to={{
              pathname: '/performance/kpi/aspect',
              state: {active: 'aspect', searchable: true},
            }}
            style={{marginRight: 12}}
          >
            Batalkan
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="large"
          >
            Simpan
          </Button>
        </div>
      </FormToolbar>
      <Divider></Divider>
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>
              Kode Aspek {GLOBAL_GLOSSARY.performance.Goal} *
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                setAllDataForm({...allDataForm, code: event.target.value})
              }}
              variant="outlined"
              value={allDataForm.code}
              error={
                (!allDataForm.code && isError) ||
                (allDataForm.code && allDataForm.code.length > 50) ||
                codeChecker
              }
              helperText={
                !allDataForm.code && isError
                  ? 'Bagian ini diperlukan'
                  : codeChecker
                  ? `Kode Aspek ${GLOBAL_GLOSSARY.performance.Goal} sudah ada`
                  : allDataForm.code && allDataForm.code.length > 50
                  ? 'Jumlah karakter maksimum adalah 50 karakter'
                  : `Kode Aspek ${GLOBAL_GLOSSARY.performance.Goal} harus unik`
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>
              Nama Aspek {GLOBAL_GLOSSARY.performance.Goal} *
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              margin="normal"
              onChange={event => {
                setAllDataForm({...allDataForm, name: event.target.value})
              }}
              variant="outlined"
              value={allDataForm.name}
              error={allDataForm.name.length < 1 && isError}
              helperText={
                allDataForm.name.length < 1 && isError
                  ? 'Bagian ini diperlukan'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Keterangan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              multiline
              rows="4"
              onChange={event => {
                setAllDataForm({
                  ...allDataForm,
                  description: event.target.value,
                })
              }}
              variant="outlined"
              value={allDataForm.description}
              error={allDataForm.description.length < 1 && isError}
              helperText={
                allDataForm.description.length < 1 && isError
                  ? 'Bagian ini diperlukan'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Status *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              select
              fullWidth
              onChange={event => {
                setAllDataForm({...allDataForm, status: event.target.value})
              }}
              margin="normal"
              variant="outlined"
              value={allDataForm.status}
            >
              <MenuItem value="ACTIVE">{status_data.ACTIVE}</MenuItem>
              <MenuItem value="INACTIVE">{status_data.INACTIVE}</MenuItem>
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        mutation={addAspect}
        header="Tambahkan Individu"
        body={`Apakah anda yakin akan menambahkan ${allDataForm.name} yang baru ?`}
        button="Tambahkan"
        buttonClose="Batalkan"
      />
    </PaperContainer>
  )
}

export default withRouter(AddAspect)
