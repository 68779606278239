import gql from 'graphql-tag'

export const GET_EMPLOYEE_DROPDOWN = gql`
  query GetEmployeeDropdown(
    $company: uuid
    $search: String
    $limit: Int
    $offset: Int
  ) {
    v_latest_placements(
      where: {
        company: {_eq: $company}
        people_work_placement: {
          _or: [
            {regno: {_ilike: $search}}
            {global_user: {name: {_ilike: $search}}}
          ]
        }
      }
      order_by: [{regno: asc}]
      limit: $limit
      offset: $offset
    ) {
      people_work_placement {
        id
        regno
        status
        end_date
        global_user {
          id
          name
        }
      }
    }
  }
`
