import React, {useEffect, useState} from 'react'
import {
  Typography,
  TextField,
  Dialog,
  IconButton,
  makeStyles,
  MenuItem,
  Icon,
  DialogActions,
  Button,
  Card,
} from '@material-ui/core'
import {
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
  StyledReactSelect,
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
  GreyTypography,
  MediumAvatar,
} from '../../../../../../GlobalStyles'
import {useQuery} from '@apollo/react-hooks'
import {GET_DROPDOWN_COMMITEE} from '../../../../../../graphql/queries'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  title: {
    margin: '1.2rem 0',
  },

  expansionContainer: {
    padding: '.8rem 0',
    borderRadius: 0,
    background: '#f7f8f9',
    marginTop: '1.6rem',
    width: '85%',
    '&:before': {
      display: 'none',
    },
  },
  bgWhite: {background: '#fff'},
  ml12: {
    marginLeft: '1.2rem',
  },
  icRed: {
    color: '#ef4d5e',
  },
  customEditorFooter: {
    width: '100%',
    height: 20,
    borderRadius: '0 0 5px 5px',
    backgroundColor: '#014a62',
  },
  m0: {margin: 0},

  list: {display: 'flex', margin: 16, alignItems: 'center'},
  spacer: {marginRight: 14},
  iconMask: {
    background: '#039be5',
    color: 'white',
    borderRadius: 5,
    height: 23,
  },
  iconDelete: {
    color: '#a9a8a8',
    cursor: 'pointer',
  },
  cardPadding: {
    margin: '10px 0px',
  },
  btnImport: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
}))

const ModalAddCommitee = props => {
  const {
    handleClose,
    open,
    values,
    setValues,
    employee,
    setEmployee,
    dataAddCommittee,
    setDataAddCommittee,
  } = props
  const classes = useStyles()
  const [searchDropdown, setSearchDropdown] = useState('')
  const [oldDataValues, setOldDataValues] = useState(null)
  const [newEmp, setNewEmp] = useState(null)
  const [oldDataAddCommittee, setOldDataAddCommittee] = useState(null)
  const [isChange, setIsChange] = useState(false)
  const [error, setError] = useState(false)

  const {data, loading} = useQuery(GET_DROPDOWN_COMMITEE, {
    variables: {
      search: `%${searchDropdown}%`,
      limit: 20,
    },
  })

  useEffect(() => {
    if (!isChange) {
      setOldDataValues(values)
      setNewEmp(employee)
      setOldDataAddCommittee(dataAddCommittee)
    }
  }, [isChange])

  const dataEmpFiltered = data?.people_work_placements?.filter(resEmp => {
    return !newEmp?.[dataAddCommittee?.index]?.some(
      search => search.label === resEmp.global_user.id
    )
  })

  const dataEmp = dataEmpFiltered?.map(filtered => {
    return {
      label: filtered.global_user.id,
      value: filtered.global_user.name,
      avatar: filtered.global_user.avatar,
      job_profile: dataAddCommittee?.value,
      position: filtered?.company_job_profile?.title,
    }
  })

  const filterPic = values?.talent_candidate_groups?.data?.[
    dataAddCommittee?.index
  ]?.talent_committees?.data?.filter(v => v.is_pic)

  const handleChangeCommittee = (val, parent, type) => {
    setIsChange(true)
    const dataCandidate = [...values.talent_candidate_groups.data]
    const index = dataCandidate.findIndex(x => x.job_profile === parent)

    if (type === 'pic') {
      const dataCommittee = dataCandidate[index].talent_committees.data
      const indexCommittee = dataCommittee.findIndex(
        x => x.committee === val.target.value
      )

      const indexCommitteePic = dataCommittee.findIndex(x => x.is_pic)

      dataCommittee[indexCommittee] = {
        ...dataCommittee[indexCommittee],
        is_pic: true,
      }
      if (indexCommitteePic !== -1) {
        dataCommittee[indexCommitteePic] = {
          ...dataCommittee[indexCommitteePic],
          is_pic: false,
        }
      }

      dataCandidate[index] = {
        ...dataCandidate[index],
        talent_committees: {data: dataCommittee},
      }
    } else if (type === 'name') {
      const oldData =
        newEmp?.[dataAddCommittee?.index]?.map(res => {
          return {
            committee: res.label,
            status: 'ACTIVE',
            is_pic: false,
          }
        }) ?? []
      const dataChosen = [
        {committee: val.label, status: 'ACTIVE', is_pic: false},
      ]
      const newData = [...oldData, ...dataChosen]
      const newList = [...(newEmp?.[dataAddCommittee?.index] ?? []), ...[val]]

      dataCandidate[index] = {
        ...dataCandidate[index],
        talent_committees: {data: newData ? [...newData] : []},
      }
      const mainEmp = [...newEmp]
      let empChange = {...mainEmp[index]}
      empChange = newList
      mainEmp[index] = empChange
      setNewEmp(mainEmp)
    } else {
      if (type === 'committee_date_end' || type === 'committee_date_start') {
        val = moment(val).format('YYYY-MM-DD')
      }
      dataCandidate[index] = {
        ...dataCandidate[index],
        [type]: val,
      }
    }
    setValues({
      ...values,
      talent_candidate_groups: {data: dataCandidate},
    })
  }

  const empMenu = option => {
    return (
      <div className={`${classes.list} margin-selected`}>
        <div className={classes.spacer}>
          <MediumAvatar
            className="avatar"
            src={option.avatar}
            alt={option.value}
          ></MediumAvatar>
        </div>
        <div style={{width: '100%'}}>
          <Typography variant="body1">{option.value}</Typography>
          <GreyTypography
            variant="body2"
            className="hide-when-selected"
            gutterBottom
          >
            {option.position}
          </GreyTypography>
        </div>
      </div>
    )
  }

  const handleDeleteCommittee = key => {
    const newState = [...newEmp?.[dataAddCommittee?.index]]
    const dataCandidate = [...values.talent_candidate_groups.data]

    if (key > -1) {
      newState.splice(key, 1)
    }

    const mainEmp = [...newEmp]
    let empChange = {...mainEmp[dataAddCommittee?.index]}
    empChange = newState
    mainEmp[dataAddCommittee?.index] = empChange

    dataCandidate[dataAddCommittee?.index].talent_committees.data = mainEmp

    setNewEmp(mainEmp)
  }

  const listCommittee = (dataCommitee, key) => {
    return (
      <Card key={key} className={classes.cardPadding}>
        <div className={classes.list}>
          <div className={classes.spacer}>
            <MediumAvatar src={dataCommitee.avatar} alt={dataCommitee.value} />
          </div>
          <div style={{width: '100%'}}>
            <Typography variant="body1">{dataCommitee.value}</Typography>
            <GreyTypography variant="body2" gutterBottom>
              {dataCommitee.position}
            </GreyTypography>
          </div>
          <div
            className={classes.iconDelete}
            onClick={() => handleDeleteCommittee(key)}
          >
            <Icon>close</Icon>
          </div>
        </div>
      </Card>
    )
  }

  const handleCancel = () => {
    if (!filterPic?.[0]?.committee && newEmp?.length > 0) {
      setError(true)
    } else {
      setValues(oldDataValues)
      setNewEmp(employee)
      setDataAddCommittee(oldDataAddCommittee)
      handleClose()
    }
  }

  const handleSave = () => {
    if (!filterPic?.[0]?.committee || newEmp?.length === 0) {
      setError(true)
    } else {
      handleClose()
      setEmployee(newEmp)
    }
  }
  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <ModalHeader>
        <div>
          <Typography className="title">{`Ubah ${dataAddCommittee?.label}`}</Typography>
        </div>
        <IconButton aria-label="close" onClick={handleClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContentEvent>
        <FormChildContainer style={{width: '100%'}}>
          <FormChildTitle style={{marginBottom: 12}}>
            <TitleNumber>1.</TitleNumber>
            <TitleNumber>Nama Komite</TitleNumber>
            {newEmp?.length === 0 && error && (
              <Typography
                variant="caption"
                style={{color: 'red', marginLeft: 6}}
              >
                This field is required
              </Typography>
            )}
          </FormChildTitle>
          <FormChildInput>
            <StyledReactSelect
              value={newEmp?.length > 0 && newEmp?.[dataAddCommittee?.index]}
              options={dataEmp}
              isClearable={false}
              components={{
                SingleValue: () => {
                  return (
                    <span style={{color: '#a9a8a8'}}>Tambahkan Komite</span>
                  )
                },
              }}
              onChange={val =>
                handleChangeCommittee(val, dataAddCommittee?.value, 'name')
              }
              getOptionLabel={empMenu}
              placeholder="Tambahkan Komite"
              isLoading={loading}
              onInputChange={d => setSearchDropdown(d)}
              backspaceRemovesValue={false}
            />
            {newEmp?.[dataAddCommittee?.index]?.map((committee, key) =>
              listCommittee(committee, key)
            )}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Penanggungjawab</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              select
              value={filterPic?.[0]?.committee ?? ''}
              error={!filterPic?.[0]?.committee && error}
              helperText={
                !filterPic?.[0]?.committee && error
                  ? 'This field is required'
                  : ''
              }
              size="small"
              variant="outlined"
              margin="normal"
              className={classes.bgWhite}
              fullWidth
              onChange={val =>
                handleChangeCommittee(val, dataAddCommittee?.value, 'pic')
              }
            >
              {newEmp?.length > 0 && newEmp?.[dataAddCommittee?.index] ? (
                newEmp?.[dataAddCommittee?.index]?.map((res, i) => {
                  return (
                    <MenuItem key={i} value={res.label}>
                      {res.value}
                    </MenuItem>
                  )
                })
              ) : (
                <MenuItem disabled>Please choose committee first</MenuItem>
              )}
            </TextField>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Tanggal Mulai</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                format="MM/dd/yyyy"
                value={
                  values?.talent_candidate_groups?.data?.[
                    dataAddCommittee?.index
                  ]?.committee_date_start
                }
                margin="normal"
                onChange={date =>
                  handleChangeCommittee(
                    date,
                    dataAddCommittee?.value,
                    'committee_date_start'
                  )
                }
                className={classes.bgWhite}
                size="small"
                InputAdornmentProps={{
                  classes: {
                    root: classes.m0,
                  },
                }}
                minDate={values.start_date}
                maxDate={values.end_date}
              />
            </MuiPickersUtilsProvider>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Tanggal Selesai</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                format="MM/dd/yyyy"
                value={
                  values?.talent_candidate_groups?.data?.[
                    dataAddCommittee?.index
                  ]?.committee_date_end
                }
                margin="normal"
                onChange={date =>
                  handleChangeCommittee(
                    date,
                    dataAddCommittee?.value,
                    'committee_date_end'
                  )
                }
                className={classes.bgWhite}
                size="small"
                InputAdornmentProps={{
                  classes: {
                    root: classes.m0,
                  },
                }}
                minDate={
                  values?.talent_candidate_groups?.data?.[
                    dataAddCommittee?.index
                  ]?.committee_date_start
                }
                maxDate={values.end_date}
              />
            </MuiPickersUtilsProvider>
          </FormChildInput>
        </FormChildContainer>
      </ModalContentEvent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleCancel}>Batalkan</Button>
        <Button
          className={classes.btnImport}
          variant="contained"
          onClick={handleSave}
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalAddCommitee
