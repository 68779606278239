import React from 'react'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import {
  Button,
  DialogActions,
  Dialog,
  IconButton,
  Typography,
  TextField,
  MenuItem,
  Switch,
} from '@material-ui/core'
import {
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
} from '../../../../../GlobalStyles'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import AddEdit from '../../../../shared-components/popup/AddEditPopup'

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
    fontWeight: 600,
  },
  sub_title: {
    flexGrow: 1,
    fontWeight: 100,
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
  subTitle: {
    color: '#a9a8a8',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontWeight: 'normal',
    textAlign: 'left',
  },
  blueLink: {color: '#039be5', cursor: 'pointer'},
  select: {
    width: '50%',
  },
  formControl: {
    marginBottom: '1rem',
  },
  switch: {
    display: 'flex',
  },
  textArea: {
    width: '100%',
    height: '170px',
    borderRadius: '5px',
    border: 'solid 1px #d0d4d4',
  },
  btnImport: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
}))

const SwitchDate = withStyles({
  switchBase: {
    '&$checked': {
      color: '#014a62',
    },
    '&$checked + $track': {
      backgroundColor: '#014a62',
    },
  },
  checked: {},
  track: {},
})(Switch)

export default function AddEvent(props) {
  const classes = useStyles()
  const {handleChange, handleDate, change, mutation, handleOpen} = props
  const [openMutation, setOpenMutation] = React.useState(false)
  const [openCancel, setOpenCancel] = React.useState(false)
  const [state, setState] = React.useState(false)
  const handleCloseMutation = () => {
    setOpenMutation(false)
  }
  const handleOpenMutation = () => {
    setOpenMutation(true)
  }
  const handleCloseCancel = () => {
    setOpenCancel(false)
  }
  const handleOpenCancel = () => {
    setOpenCancel(true)
  }
  const handleSwitch = event => {
    setState(event.target.checked)
  }
  const type = [
    {
      value: 'national',
      label: 'National',
    },
  ]

  return (
    <>
      <Dialog onClose={false} open={props.open} maxWidth="sm" fullWidth={true}>
        <ModalHeader>
          <div>
            <Typography variant={'h6'} className={classes.sub_title}>
              Add Event
            </Typography>
          </div>
          <IconButton aria-label="close" onClick={props.handleClose}>
            <WhiteIcon>close</WhiteIcon>
          </IconButton>
        </ModalHeader>
        <ModalContentEvent>
          <div className={classes.formControl}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.subTitle}
            >
              Name
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              onChange={handleChange('name')}
            />
          </div>
          <div className={classes.formControl}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.subTitle}
            >
              Type
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              select
              className={classes.select}
              onChange={handleChange('type')}
              value={change.type}
            >
              {type.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.switch}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.subTitle}
              style={{marginRight: '1rem'}}
            >
              All Day
            </Typography>
            <SwitchDate
              onChange={handleSwitch}
              checked={state}
              style={{marginBottom: '1rem'}}
            />
          </div>
          <div className={classes.formControl}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.subTitle}
            >
              Date
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="dd/mm/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={change.date}
                onChange={date => handleDate('date', date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                minDate={new Date()}
                maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.formControl}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.subTitle}
            >
              Description
            </Typography>
            <textarea
              className={classes.textArea}
              onChange={handleChange('description')}
            ></textarea>
          </div>
        </ModalContentEvent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={() => {
              handleOpenCancel()
              props.handleClose()
            }}
          >
            Cancel
          </Button>
          <Button
            className={classes.btnImport}
            variant="contained"
            onClick={() => {
              handleOpenMutation()
              props.handleClose()
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <AddEdit
        open={openMutation}
        handleClose={handleCloseMutation}
        name={props.change.name}
        type={'Add'}
        feature={'New Calendar'}
        mutation={mutation}
      />
      <AddEdit
        open={openCancel}
        handleClose={handleOpen}
        type={'discard '}
        name={props.change.name}
        feature="New Calendar"
        mutation={handleCloseCancel}
      />
    </>
  )
}
