import React from 'react'
import {makeStyles, IconButton, useTheme} from '@material-ui/core'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

export const TableOptions = {
  search: false,
  selection: true,
  sorting: true,
  headerStyle: {
    fontWeight: 700,
    lineHeight: 1.43,
    fontFamily: 'Muli',
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
  selectionProps: () => ({
    color: 'primary',
  }),
  pageSize: 20,
  pageSizeOptions: [20, 50, 100],
}

export const TableOptionsNoSorting = {
  search: false,
  selection: true,
  sorting: false,
  headerStyle: {
    fontWeight: 700,
    lineHeight: 1.43,
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
  selectionProps: () => ({
    color: 'primary',
  }),
  pageSize: 20,
  pageSizeOptions: [20, 50, 100],
}

export const TableOptionsTime = {
  search: false,
  sorting: true,
  headerStyle: {
    lineHeight: 1.43,
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
  selectionProps: () => ({
    color: 'primary',
  }),
  pageSize: 20,
  pageSizeOptions: [20, 50, 100],
}

export const TableStyle = {
  padding: '1.6rem 1.6rem 0',
  fontSize: 12,
}

export const newTableStyles = makeStyles(() => ({
  tableRoot: {
    '& .MuiTable-root': {
      borderCollapse: 'separate',
      borderSpacing: '1em',
      padding: 2,
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  greyTableRoot: {
    '& .MuiTable-root': {
      borderCollapse: 'separate',
      borderSpacing: '1em',
      padding: 2,
      background: '#f7f8f9',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      background: '#f7f8f9',
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
}))

export const NewTableOptions = {
  search: false,
  selection: true,
  headerStyle: {
    fontWeight: 700,
    lineHeight: 1.43,
  },
  rowStyle: {
    // wordBreak: 'break-word',
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  selectionProps: () => ({
    color: 'primary',
  }),
  draggable: false,
  sorting: false,
  pageSize: 20,
  pageSizeOptions: [20, 50, 100],
}

export const TablePaginationActions = props => {
  const theme = useTheme()
  const {count, page, rowsPerPage, onChangePage} = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      {/* <p>{(rowsPerPage * page) + 1}-{rowsPerPage} of {count} </p> */}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}
export const TableLocalization = {
  pagination: {
    labelRowsSelect: 'Baris',
    firstTooltip: 'Halaman Pertama',
    previousTooltip: 'Halaman Sebelumnya',
    nextTooltip: 'Halaman Selanjutnya',
    lastTooltip: 'Halaman Terakhir',
    labelDisplayedRows: '{from}-{to} dari {count}',
  },
}
