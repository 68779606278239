import gql from 'graphql-tag'

export const DELETE_LOCATION_RESTRICTIONS = gql`
  mutation deleteBulkLocations(
    $objects: [time_attendance_policy_locations_bool_exp]
  ) {
    delete_time_attendance_policy_locations(where: {_or: $objects}) {
      affected_rows
    }
  }
`
