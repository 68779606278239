const setErrorUtils = (value, recurringCheck) => {
  const {
    name,
    date,
    recurring,
    recur_end_date,
    recur_end_x,
    description,
  } = value

  let target = null
  if (recurringCheck) {
    if (name === '' || name === null) {
      target = 'name'
    } else if (date === null || date === '') {
      target = 'date'
    } else if (description === null || description === '') {
      target = 'description'
    } else if (recurring === null || recurring === 0 || recurring === '') {
      target = 'recurring'
    } else if (recur_end_date === '' || recur_end_date === null) {
      target = 'recur_end_date'
    } else if (recur_end_x === '' || recur_end_x === null) {
      target = recur_end_x
    }
  } else {
    if (name === '' || name === null) {
      target = 'name'
    } else if (date === null || date === '') {
      target = 'date'
    } else if (description === null || description === '') {
      target = 'description'
    }
  }

  if (target) {
    const ids = document.getElementById(target)
    if (ids) {
      ids.scrollIntoView({behavior: 'smooth'})
    }
  }
}

export const errorCheckerCompanyHoliday = (value, setError) => {
  const isMandatoryField = ['name', 'date', 'leave_policy']
  let isError = false
  for (const field of isMandatoryField) {
    if (!value[field]) {
      isError = true
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    }
  }
  return isError
}

export const errorChecker = (value, setError, recurringCheck) => {
  const {
    name,
    date,
    recurring,
    recur_end_opt,
    recur_end_date,
    recur_end_x,
    description,
  } = value
  if (recurringCheck) {
    if (recur_end_opt === '0') {
      if (
        name === '' ||
        name === null ||
        date === null ||
        date === '' ||
        description === null ||
        description === '' ||
        recurring === null ||
        recurring === 0 ||
        recurring === ''
      ) {
        setErrorUtils(value, recurringCheck)
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 3000)
        return true
      }
    } else if (recur_end_opt === '1') {
      if (
        name === '' ||
        name === null ||
        date === null ||
        date === '' ||
        description === null ||
        description === '' ||
        recurring === null ||
        recurring === 0 ||
        recurring === '' ||
        recur_end_date === '' ||
        recur_end_date === null
      ) {
        setErrorUtils(value, recurringCheck)
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 3000)
        return true
      }
    } else if (recur_end_opt === '2') {
      if (
        name === '' ||
        name === null ||
        date === null ||
        date === '' ||
        description === null ||
        description === '' ||
        recurring === null ||
        recurring === 0 ||
        recurring === '' ||
        recur_end_x === '' ||
        recur_end_x === 0 ||
        recur_end_x === null
      ) {
        setErrorUtils(value, recurringCheck)
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 3000)
        return true
      }
    }
  } else {
    if (
      name === '' ||
      name === null ||
      date === null ||
      date === '' ||
      description === null ||
      description === ''
    ) {
      setErrorUtils(value, recurringCheck)
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
      return true
    }
  }
}

// event

const setErrorUtilsEvent = (name, value) => {
  const {
    category,
    type,
    datestarttime,
    datestart,
    dateend,
    dateendtime,
    description,
    notif_timer,
    notif_unit_of_time,
    location_info,
    poster_detail,
    schedule_fields,
    event_enrollments,
  } = value

  let target = null
  if (name === '') {
    target = 'name'
  } else if (category === '') {
    target = 'category'
  } else if (type === '') {
    target = 'type'
  } else if (datestarttime === null) {
    target = 'datestarttime'
  } else if (datestart === null) {
    target = 'datestart'
  } else if (dateend === null) {
    target = 'dateend'
  } else if (dateendtime === null) {
    target = 'dateendtime'
  } else if (description === '') {
    target = 'description'
  } else if (notif_timer === '') {
    target = 'notif_timer'
  } else if (notif_unit_of_time === '') {
    target = 'notif_unit_of_time'
  } else if (location_info === '') {
    target = 'location_info'
  } else if (poster_detail.length === 0) {
    target = 'poster_detail'
  } else if (schedule_fields.length === 0) {
    target = 'schedule_fields'
  } else if (event_enrollments.length === 0) {
    target = 'event_enrollments'
  }

  if (target) {
    const ids = document.getElementById(target)
    if (ids) {
      ids.scrollIntoView({behavior: 'smooth'})
    }
  }
}

export const errorCheckerEvent = (name, value, setError) => {
  const {
    category,
    type,
    datestarttime,
    datestart,
    dateend,
    dateendtime,
    description,
    notif_timer,
    notif_unit_of_time,
    location_info,
    poster_detail,
    schedule_fields,
    event_enrollments,
  } = value
  if (
    name === '' ||
    category === '' ||
    type === '' ||
    datestarttime === null ||
    datestart === null ||
    dateend === null ||
    dateendtime === null ||
    description === '' ||
    notif_timer === '' ||
    notif_unit_of_time === '' ||
    location_info === '' ||
    poster_detail.length === 0 ||
    schedule_fields.length === 0 ||
    event_enrollments.length === 0
  ) {
    setErrorUtilsEvent(name, value)
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 3000)
    return true
  } else {
    return false
  }
}

const HOLIDAY_TYPE = 'holiday'
export const COMPANY_HOLIDAY_TYPE = 'leave'

export const EVENT_TYPE = [
  {type: HOLIDAY_TYPE, label: 'Holiday'},
  {type: COMPANY_HOLIDAY_TYPE, label: 'Company Holiday'},
]

export const EVENT_INFORMATION = {
  [HOLIDAY_TYPE]: 'Holidays will cut off working days',
  [COMPANY_HOLIDAY_TYPE]: 'Company holidays will be taken from the leave quota',
}
