import React, {useState, useEffect} from 'react'
import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'
import Activity from './activity/ActivityComponent'
import Setting from './setting/SettingComponent'
import EditOvertimePolicy from './setting/EditSettingComponent'
import AddOvertimePolicy from './setting/AddSettingComponent'
// import Report from './report/ReportComponent'
import {withRouter} from 'react-router-dom'
import {Filter} from '@worklifebeyond/wlb-utils-components'
import {anchorOrigin, listFilter, MENUS, transformOrigin} from './filter'
import {
  ButtonFilter,
  BlueIcon,
  FilterText,
  PageContainer,
} from '../../../../src/GlobalStyles'
import {setterFilter} from '../../../utils/helpers'

const renderComponent = (
  activeComponent,
  state,
  searchText = '',
  filterData,
  setActiveMenuTab
) => {
  switch (activeComponent) {
    // case 'report':
    //   return <Report searchText={searchText} filterData={filterData} />
    case 'settings':
      return <Setting searchText={searchText} />
    case 'edit-setting':
      return (
        <EditOvertimePolicy data={state.data} dataCheck={state.dataCheck} />
      )
    case 'add-setting':
      return <AddOvertimePolicy />
    default:
      return (
        <Activity
          setActiveMenuTab={setActiveMenuTab}
          filterData={filterData}
          searchText={searchText}
        />
      )
  }
}

const OvertimePage = props => {
  const [filterData, setFilterData] = useState({})
  const [activeMenuTab, setActiveMenuTab] = useState('activity')
  const [willResetActiveFilter, setWillResetActiveFilter] = useState(false)
  let activeTab = ''
  let activeMenu = ''

  let state = {}

  if (props.location.state) {
    activeMenu = props.location.state.active
    state = props.location.state
    switch (props.location.state.active) {
      case 'add-setting':
      case 'edit-setting':
        activeTab = 'settings'
        break
      default:
        activeTab = props.location.state.active
    }
  }

  useEffect(() => {
    if (props.location.pathname === '/time/overtime') {
      props.history.push({
        state: {
          active: 'activity',
          searchable: true,
        },
      })
    }
  }, [])

  const data_tabs = [
    {
      label: 'Activity',
      state: 'activity',
      url: '/time/overtime/activity',
      searchable: true,
    },
    // Hide (15/07/2021)
    // {
    //   label: 'Report',
    //   state: 'report',
    //   url: '/time/overtime/report',
    //   searchable: false,
    // },
    {
      label: 'Settings',
      state: 'settings',
      url: '/time/overtime/setting',
      searchable: true,
    },
  ]

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  const handleApply = result => setFilterData(setterFilter(result))
  const handleClick = () => {
    if (activeMenuTab === 'request') {
      setWillResetActiveFilter(false)
    } else if (activeMenuTab === 'activity') {
      setWillResetActiveFilter(true)
    }
  }

  return (
    <div>
      <Card
        endAdornment={
          MENUS.includes(activeTab) && (
            <Filter
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
              id="filter-overtime-wlb"
              onApply={handleApply}
              listFilter={listFilter(activeMenuTab)}
              onClose={handleClick}
              options={{
                isSetActiveMenuAfterClose: false,
                isSetActiveMenuWhenOpen:
                  filterData !== 'activity' && willResetActiveFilter,
                setActiveMenuToIndex: 0,
                delayResetMenu: 1000,
              }}
            >
              <ButtonFilter position="end">
                <BlueIcon>filter_list</BlueIcon>
                <FilterText variant="body2">Filter</FilterText>
              </ButtonFilter>
            </Filter>
          )
        }
        icon="access_time"
        title="Overtime"
        onSearch={onSearch}
      >
        <PageContainer>
          <WlbTab data={data_tabs} activeMenu={activeTab} />
          {renderComponent(
            activeMenu,
            state,
            searchText,
            filterData,
            setActiveMenuTab
          )}
        </PageContainer>
      </Card>
    </div>
  )
}

export default withRouter(OvertimePage)
