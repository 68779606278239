import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() => ({
  rootDialog: {
    maxWidth: 432,
  },
  textTitle: {
    fontSize: 14,
    margin: 0,
    fontWeight: '600',
    color: '#014a62',
  },
  rootTite: {
    padding: '25px 20px',
    borderBottom: '1px solid #eff2f4',
  },
  rootContent: {
    padding: '22px 20px',
    fontSize: 12,
  },
  rootActions: {
    padding: '24px',
  },
}))

export default function PopupWLB({
  onClose,
  open,
  title,
  content,
  onClickButton,
  onClickCancel = undefined,
  textButton = 'Konfirmasi',
  isLoading = false,
}) {
  const classes = useStyles()

  return (
    <Dialog
      classes={{
        paper: classes.rootDialog,
      }}
      onClose={onClose}
      open={open}
      scroll="body"
      fullWidth
    >
      <DialogTitle classes={{root: classes.rootTite}}>
        <h6 className={classes.textTitle}>{title}</h6>
      </DialogTitle>
      <DialogContent classes={{root: classes.rootContent}}>
        {content}
      </DialogContent>
      <DialogActions classes={{root: classes.rootActions}}>
        {isLoading && <CircularProgress size={28} />}
        {onClickCancel && (
          <Button onClick={onClickCancel} disableElevation>
            Batalkan
          </Button>
        )}
        <Button
          onClick={onClickButton}
          disableElevation
          disabled={isLoading}
          variant="contained"
          color="primary"
        >
          {textButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
