import gql from 'graphql-tag'

export const GET_LIST_HOLDING_TALENT = gql`
  query($companyHead: uuid, $search: String) {
    list_head: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company: {_eq: $companyHead}
        _or: [
          {email: {_ilike: $search}}
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      regno
      email
      global_user {
        id
        name
        avatar
      }
      company_profile {
        id
        legal_name
        brand_name
      }
      company_job_profile {
        id
        title
      }
      holding_sources {
        id
      }
    }

    list_member: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company_profile: {
          holdingRelationsByCompanyMember: {
            status: {_eq: "ACTIVE"}
            company_head: {_eq: $companyHead}
          }
        }
        _or: [
          {email: {_ilike: $search}}
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      regno
      email
      global_user {
        id
        name
        avatar
      }
      company_profile {
        id
        legal_name
        brand_name
      }
      company_job_profile {
        id
        title
      }
      holding_sources {
        id
      }
    }
  }
`
