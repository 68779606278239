import gql from 'graphql-tag'

export const ADD_GROUP_QUESTION_BANK = gql`
  mutation addGroupQuestionBank(
    $data: [multirater_bank_groups_insert_input!]!
  ) {
    insert_multirater_bank_groups(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const UPDATE_GROUP_QUESTION_BANK = gql`
  mutation($id: bigint, $name: String, $description: String) {
    update_multirater_bank_groups(
      _set: {name: $name, description: $description}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const DELETE_GROUP_QUESTION_BANK = gql`
  mutation($id: bigint) {
    update_multirater_bank_groups(
      _set: {deleted_at: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
