import gql from 'graphql-tag'

export const ADD_SURVEY_STEP_ONE = gql`
  mutation(
    $type: String!
    $nps: Boolean
    $title: String!
    $description: String!
    $status: String!
    $start_date: date!
    $end_date: date!
    $scale: Int
    $buttons: Int
    $notif_email: String
  ) {
    insert_multirater_surveys(
      objects: {
        type: $type
        nps_include: $nps
        title: $title
        description: $description
        status: $status
        startdate: $start_date
        enddate: $end_date
        scale: $scale
        buttons: $buttons
        notification_email_format: $notif_email
      }
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const UPDATE_SURVEY_STEP_ONE = gql`
  mutation(
    $id: bigint!
    $type: String!
    $nps: Boolean!
    $title: String!
    $description: String!
    $status: String!
    $start_date: date!
    $end_date: date!
    $scale: Int
    $buttons: Int!
    $notif_email: String
    $weight_settings: jsonb
  ) {
    update_multirater_surveys(
      where: {id: {_eq: $id}}
      _set: {
        title: $title
        description: $description
        type: $type
        status: $status
        nps_include: $nps
        startdate: $start_date
        enddate: $end_date
        scale: $scale
        buttons: $buttons
        notification_email_format: $notif_email
        weight_settings: $weight_settings
      }
    ) {
      affected_rows
    }
  }
`
export const UPDATE_SURVEY_STATUS = gql`
  mutation($id: bigint!, $status: String!) {
    update_multirater_surveys(
      where: {id: {_eq: $id}}
      _set: {status: $status}
    ) {
      affected_rows
    }
  }
`

export const ADD_GROUP_QUESTION_STEP_TWO = gql`
  mutation($objects: [multirater_survey_groups_insert_input!]!) {
    insert_multirater_survey_groups(objects: $objects) {
      affected_rows
    }
  }
`

export const ADD_QUESTION_STEP_TWO = gql`
  mutation($object: [multirater_survey_questions_insert_input!]!) {
    insert_multirater_survey_questions(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const DELETE_SURVEY = gql`
  mutation($id: bigint!) {
    update_multirater_surveys(
      where: {id: {_eq: $id}}
      _set: {deleted_at: "now()"}
    ) {
      affected_rows
    }
  }
`

export const DUPLICATE_SURVEY_CENTER = gql`
  mutation($objects: [multirater_surveys_insert_input!]!) {
    insert_multirater_surveys(objects: $objects) {
      affected_rows
    }
  }
`
export const DELETE_MULTIPLE_SURVEY_CENTER = gql`
  mutation($id: [bigint!]!) {
    update_multirater_surveys(
      _set: {deleted_at: "now()"}
      where: {id: {_in: $id}}
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const ADD_RESPONDENT = gql`
  mutation($user: uuid!, $survey: bigint, $group: bigint) {
    delete_multirater_respondents(
      where: {survey: {_eq: $survey}, user: {_eq: $user}, group: {_eq: $group}}
    ) {
      affected_rows
    }

    insert_multirater_respondents(
      objects: {survey: $survey, user: $user, group: $group}
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`
export const UPDATE_RESPONDENT = gql`
  mutation($id: bigint!, $survey: bigint!, $group: bigint!, $user: uuid!) {
    update_multirater_respondents(
      _set: {survey: $survey, group: $group, user: $user}
      where: {id: {_eq: $id}}
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const UPDATE_ALL_RESPONDENT = gql`
  mutation(
    $user: [uuid!]!
    $survey: bigint!
    $object: [multirater_respondents_insert_input!]!
  ) {
    delete_multirater_respondents(
      where: {survey: {_eq: $survey}, user: {_in: $user}}
    ) {
      affected_rows
    }

    insert_multirater_respondents(objects: $object) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const DELETE_MULTIPLE_RESPONDENT = gql`
  mutation($user: [uuid!], $survey: bigint) {
    delete_multirater_respondents(
      where: {survey: {_eq: $survey}, user: {_in: $user}}
    ) {
      affected_rows
    }
  }
`
export const ADD_MULTIPLE_RESPONDENT = gql`
  mutation($object: [multirater_respondents_insert_input!]!) {
    insert_multirater_respondents(objects: $object) {
      returning {
        id
      }
    }
  }
`

export const UPDATE_SURVEY_QUESTION = gql`
  mutation($id: bigint!, $qself: String, $qothers: String) {
    update_multirater_survey_questions(
      _set: {question_self: $qself, question_others: $qothers}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const DELETE_SURVEY_QUESTION = gql`
  mutation($id: bigint!) {
    update_multirater_survey_questions(
      _set: {deleted_at: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_SURVEY_GROUP_QUESTION = gql`
  mutation($id: bigint!, $name: String) {
    update_multirater_survey_groups(
      _set: {name: $name}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const DELETE_SURVEY_GROUP_QUESTION = gql`
  mutation($id: bigint!) {
    delete_multirater_survey_groups(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`
