import React from 'react'
import {TableOptions} from '../../../shared-components/table/TableOptions'
import {competencyOptions} from '../CompetencyPageStyles'
import {CustomTypography} from '../../../../GlobalStyles'

import MaterialTable from 'material-table'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'

const DetailLevelComponent = ({data}) => {
  if (data.type === 'nonlevel') {
    return (
      <div style={{minHeight: 500, padding: '1.6rem'}}>
        <CustomTypography>
          Competencies with non level types do not have a list of levels
        </CustomTypography>
      </div>
    )
  }

  return (
    <MaterialTable
      style={{
        padding: '1.6rem 1.6rem 0',
      }}
      localization={{
        body: {
          emptyDataSourceMessage: <NoDataListComponent />,
        },
      }}
      columns={[
        {
          title: 'Level',
          field: 'number',
          render: row => <div>Level {row.number}</div>,
        },
        {
          title: 'Description',
          field: 'description',
          render: row => (
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: row.description || '',
                }}
              />
            </div>
          ),
        },
      ]}
      data={data?.description?.levels || []}
      title=""
      options={{
        ...TableOptions,
        ...competencyOptions,
      }}
      components={{
        Toolbar: () => null,
      }}
    />
  )
}

export default DetailLevelComponent
