import gql from 'graphql-tag'

export const EDIT_ALL_JOB_PROFILE = gql`
  mutation(
    $id: Int!
    $code: String
    $title: String
    $icon: String
    $parent: Int
    $organization: Int
    $position: Int
    $rank: Int
    $office: Int
    $jobdesc: String
    $jobresp: String
    $salary_from: float8
    $salary_to: float8
    $slots: Int
    $competency_model: [competency_model_items_insert_input!]!
    $education: [company_job_profile_r_educations_insert_input!]!
    $course: [company_job_profile_r_course_insert_input!]!
    $kpi: [company_job_profile_r_kpi_insert_input!]!
    #$work_schedule: [company_job_profile_r_attendance_insert_input!]!
    #$leave: [company_job_profile_r_leave_insert_input!]!
    #$overtime: [company_job_profile_r_overtime_insert_input!]!
    #$allowance: [company_job_profile_r_allowance_insert_input!]!
    #$loan: [company_job_profile_r_loan_insert_input!]!
    #$cash_adv: [company_job_profile_r_cash_adv_insert_input!]!
    #$documents: [company_job_profile_r_documents_insert_input!]!
    #$facilities: [company_job_profile_r_facilities_insert_input!]!
    $roles: String
    $job_grade_id: Int
    $require_successor: Boolean
    $acting_as: Int
    $attachment: jsonb
    $as_assistant: Boolean
    $line_type: Int
  ) {
    update_company_job_profiles(
      _set: {
        code: $code
        title: $title
        job_icon: $icon
        parent: $parent
        organization: $organization
        position: $position
        rank: $rank
        office_address: $office
        job_summary: $jobresp
        job_description: $jobdesc
        rate_salary_from: $salary_from
        rate_salary_to: $salary_to
        slots: $slots
        roles: $roles
        job_grade_id: $job_grade_id
        require_successor: $require_successor
        acting_as_id: $acting_as
        attachment: $attachment
        as_assistant: $as_assistant
        line_type: $line_type
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
      returning {
        id
      }
    }

    delete_competency_model_items(where: {job_profile: {_eq: $id}}) {
      affected_rows
    }
    insert_competency_model_items(objects: $competency_model) {
      affected_rows
    }

    delete_company_job_profile_r_educations(where: {job_profile: {_eq: $id}}) {
      affected_rows
    }
    insert_company_job_profile_r_educations(objects: $education) {
      affected_rows
    }

    delete_company_job_profile_r_course(where: {job_profile: {_eq: $id}}) {
      affected_rows
    }
    insert_company_job_profile_r_course(objects: $course) {
      affected_rows
    }

    delete_company_job_profile_r_kpi(where: {job_profile: {_eq: $id}}) {
      affected_rows
    }
    insert_company_job_profile_r_kpi(objects: $kpi) {
      affected_rows
    }

    #delete_company_job_profile_r_attendance(where: {profile: {_eq: $id}}) {
    #  affected_rows
    #}
    #insert_company_job_profile_r_attendance(objects: $work_schedule) {
    #  affected_rows
    #}

    #delete_company_job_profile_r_leave(where: {profile: {_eq: $id}}) {
    #  affected_rows
    #}
    #insert_company_job_profile_r_leave(objects: $leave) {
    #  affected_rows
    #}

    #delete_company_job_profile_r_overtime(where: {profile: {_eq: $id}}) {
    #  affected_rows
    #}
    #insert_company_job_profile_r_overtime(objects: $overtime) {
    #  affected_rows
    #}

    #delete_company_job_profile_r_allowance(where: {profile: {_eq: $id}}) {
    #  affected_rows
    #}
    #insert_company_job_profile_r_allowance(objects: $allowance) {
    #  affected_rows
    #}

    #delete_company_job_profile_r_loan(where: {profile: {_eq: $id}}) {
    #  affected_rows
    #}
    #insert_company_job_profile_r_loan(objects: $loan) {
    #  affected_rows
    #}

    #delete_company_job_profile_r_cash_adv(where: {profile: {_eq: $id}}) {
    #  affected_rows
    #}
    #insert_company_job_profile_r_cash_adv(objects: $cash_adv) {
    #  affected_rows
    #}

    #delete_company_job_profile_r_documents(where: {job_profile: {_eq: $id}}) {
    #  affected_rows
    #}
    #insert_company_job_profile_r_documents(objects: $documents) {
    #  affected_rows
    #}

    #delete_company_job_profile_r_facilities(where: {job_profile: {_eq: $id}}) {
    #  affected_rows
    #}
    #insert_company_job_profile_r_facilities(objects: $facilities) {
    #  affected_rows
    #}
  }
`

export const SET_JOBPROFILE_ASSISTANT = gql`
  mutation($id: Int!, $assistant: Boolean!) {
    update_company_job_profiles_by_pk(
      pk_columns: {id: $id}
      _set: {as_assistant: $assistant}
    ) {
      id
      as_assistant
    }
  }
`

export const SET_JOBPROFILE_LINETYPE = gql`
  mutation($id: Int!, $lineType: Int!) {
    update_company_job_profiles_by_pk(
      pk_columns: {id: $id}
      _set: {line_type: $lineType}
    ) {
      id
      line_type
    }
  }
`
