import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Avatar,
  Button,
  DialogContent,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import {Autocomplete} from '@material-ui/lab'
import moment from 'moment'
import React, {Fragment, useState} from 'react'
import {UPDATE_REPORTER} from '../../../../graphql/mutations'
import {
  GET_ASSIGNMENT_GOALS,
  GET_DETAIL_GOAL,
} from '../../../../graphql/queries'
import {GET_DROPDOWN_EMP_GOALS_REPORTER} from '../../../../graphql/queries/task/getListEmp.query'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {COMPANY_ID} from '../../../../utils/globals'
import LoadingSurvey from '../../../survey/multirater-nps/survei-center/LoadingSurvey'
import {
  AvatarCust,
  FlexBetween,
  FlexCenter,
  FlexColumnStart,
  GreyTypography,
  ModalHeader,
  ModalHeaderWhite,
  StyledDialog,
  StyledLinear,
  TextBold,
  TextSM,
  TitleXl,
} from './modalStyles'
import Description from './tabs/Description'
import Detail from './tabs/Detail'
import Task from './tabs/Task'

const useStyles = makeStyles(() => ({
  wrapperReporter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  nameReporter: {
    fontSize: 14,
  },
  avatarSpacerReporter: {marginRight: '1rem'},
  rootReporterSelect: {
    minWidth: 241,
    backgroundColor: '#fff',
  },
  dropdownWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarDropdown: {
    width: 30,
    height: 30,
    marginRight: 12,
  },
  nameDropdown: {
    fontSize: 12,
    color: '#000',
  },
  positionDropdown: {
    fontSize: 12,
    color: '#a9a8a8',
  },
  titleReporter: {color: '#a9a8a8'},
  pfGroupWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'start',
    margin: '12px 0px',
    width: '30%',
  },
  pfGroup: {
    width: '75%',
    fontSize: 14,
    margin: 0,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  box: {
    width: 30,
    height: 30,
    borderRadius: 5,
    marginRight: 15,
  },
}))
export default function ModalDetail(props) {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [openReporter, setOpenReporter] = useState(false)
  const [reporter, setReporter] = useState('')
  const [valueReporter, setValueReporter] = useState(null)
  const [limitAssignment, setLimitAssignment] = useState(5)
  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue)
  }

  const [searchReporter, setSearchReporter] = useState('')

  // let resWeight = useState(0)

  const {data, error, refetch, loading} = useQuery(GET_DETAIL_GOAL, {
    wlb_skipPatch: true,
    variables: {id: props.id},
    skip: !props.open,
    pollInterval: 500,
  })

  const {data: dataAssignment, loading: loadingAssignment} = useQuery(
    GET_ASSIGNMENT_GOALS,
    {
      wlb_skipPatch: true,
      variables: {goal: props.id, limit: limitAssignment},
      skip: !props.open,
    }
  )

  const {data: dataEmployee, loading: loadingEmp} = useQuery(
    GET_DROPDOWN_EMP_GOALS_REPORTER,
    {
      wlb_skipPatch: true,
      skip: !props.open,
      variables: {
        company: COMPANY_ID,
        search: `%${searchReporter}%`,
        limit: 5,
      },
    }
  )
  const [updateReporter] = useMutation(UPDATE_REPORTER)

  if (error !== undefined) {
    return JSON.stringify(error)
  }

  const dataGoals = data?.performance_goals?.[0]
  const dataDateGoals = {
    startDate: dataGoals?.startdate,
    endDate: dataGoals?.enddate,
  }
  const isInactiveCascading =
    dataGoals?.kpi_list?.kpi_cascading_lists?.[0]?.cascading_main?.status?.toLowerCase() ===
    'active'
  const goal_types =
    dataGoals?.kpi_list?.kpi_cascading_lists_aggregate?.aggregate?.count > 0

  const handleSelectReporter = () => {
    updateReporter({
      variables: {
        id: dataGoals.id,
        reporter: reporter,
      },
    }).then(() => {
      refetch()
      setOpenReporter(false)
      setSearchReporter('')
    })
  }

  const handleCancelReporter = () => {
    setOpenReporter(false)
    setSearchReporter('')
    setReporter('')
    setValueReporter(null)
  }

  const handleMoreAssignee = () => {
    setLimitAssignment(prev =>
      limitAssignment > dataAssignment.total.aggregate.count ? 5 : prev + 5
    )
  }

  return (
    <StyledDialog
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      {loading || loadingAssignment ? (
        <LoadingSurvey />
      ) : (
        <>
          {' '}
          <ModalHeader>
            {data.performance_goals.map((res, i) => {
              const formattedDateAdded = moment(res.startdate).format('ll')
              // resWeight =
              //   res.kpi_list &&
              //   res.kpi_list.kpi_weight_unit &&
              //   res.kpi_list.kpi_weight_unit.name

              return (
                <FlexBetween key={i}>
                  <FlexColumnStart>
                    <TitleXl>{res.name ? res.name : '-'}</TitleXl>{' '}
                    <TextSM
                      style={{
                        marginTop: '-1px',
                      }}
                    >
                      Dibuat oleh {res.global_user.name}{' '}
                      {res.startdate ? `pada ${formattedDateAdded}` : '-'}
                    </TextSM>
                  </FlexColumnStart>
                  <IconButton aria-label="close" onClick={props.handleClose}>
                    <Icon
                      style={{
                        color: '#ffffff',
                        fontSize: '37px',
                      }}
                    >
                      close
                    </Icon>
                  </IconButton>
                </FlexBetween>
              )
            })}
          </ModalHeader>
          <ModalHeaderWhite style={{paddingBottom: '0px !important'}}>
            <span className={classes.titleReporter}>
              {' '}
              {GLOBAL_GLOSSARY.performance.Reporter}
            </span>
            <FlexBetween>
              <div style={{width: '70%'}}>
                <div className={classes.wrapperReporter}>
                  {openReporter ? (
                    <>
                      <Autocomplete
                        value={valueReporter}
                        options={
                          dataEmployee && dataEmployee.people_work_placements
                        }
                        inputValue={searchReporter}
                        freeSolo
                        style={{marginRight: 12}}
                        // eslint-disable-next-line
                        onChange={(e, val) => {
                          setValueReporter(val)
                          setReporter(
                            val && val.global_user ? val.global_user.id : ''
                          )
                        }}
                        // eslint-disable-next-line
                        onInputChange={(event, newInputValue) => {
                          setSearchReporter(newInputValue)
                        }}
                        getOptionLabel={option =>
                          option.global_user && option.global_user.name
                        }
                        loading={loadingEmp}
                        renderOption={assign => {
                          return (
                            <div className={classes.dropdownWrapper}>
                              <Avatar
                                className={classes.avatarDropdown}
                                src={assign.global_user.avatar}
                              />
                              <div>
                                <div className={classes.nameDropdown}>
                                  {assign.global_user.name}
                                </div>
                                <div className={classes.positionDropdown}>
                                  {assign.company_job_profile &&
                                    assign.company_job_profile.title}
                                </div>
                              </div>
                            </div>
                          )
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            classes={{
                              root: classes.rootReporterSelect,
                            }}
                            fullWidth
                          />
                        )}
                      />
                      <Button
                        style={{marginRight: 12}}
                        onClick={handleCancelReporter}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleSelectReporter}
                        variant="contained"
                        color="secondary"
                        disabled={!reporter}
                      >
                        Confirm
                      </Button>
                    </>
                  ) : (
                    <>
                      <Avatar
                        src={
                          dataGoals && dataGoals.globalUserByReporter
                            ? dataGoals && dataGoals.globalUserByReporter.avatar
                            : null
                        }
                        className={classes.avatarSpacerReporter}
                      />

                      <div>
                        <span className={classes.nameReporter}>
                          {dataGoals && dataGoals.globalUserByReporter
                            ? dataGoals && dataGoals.globalUserByReporter.name
                            : `${GLOBAL_GLOSSARY.performance.Reporter} tidak ditetapkan`}{' '}
                          {(isInactiveCascading || !goal_types) && (
                            <EditIcon
                              onClick={() => setOpenReporter(true)}
                              style={{
                                fontSize: 12,
                                color: '#039be5',
                                cursor: 'pointer',
                                marginLeft: 6,
                              }}
                            />
                          )}
                        </span>
                        <GreyTypography variant="caption" component="p">
                          {dataGoals && dataGoals.globalUserByReporter
                            ? dataGoals.globalUserByReporter
                                .people_work_placements.length > 0 &&
                              dataGoals.globalUserByReporter
                                .people_work_placements[0]
                                .company_job_profile &&
                              dataGoals.globalUserByReporter
                                .people_work_placements[0].company_job_profile
                                .title
                            : '-'}
                        </GreyTypography>
                      </div>
                    </>
                  )}
                </div>
                <FlexCenter style={{marginTop: 10}}>
                  <StyledLinear
                    variant="determinate"
                    color="secondary"
                    value={
                      dataGoals.progress_percentage > 100
                        ? 100
                        : dataGoals.progress_percentage
                    }
                  />
                  <TextBold>
                    {/* Hide Progress Goal */}
                    {/* {dataGoals.performance_tasks_aggregate.aggregate.sum.progress
                  ? dataGoals.performance_tasks_aggregate.aggregate.sum.progress
                  : 0}{' '}
                {resWeight} ( */}
                    {dataGoals.progress_percentage
                      ? dataGoals.progress_percentage.toFixed(2)
                      : 0}
                    %{/* ) */}
                  </TextBold>
                </FlexCenter>
              </div>
              <div className={classes.pfGroupWrap}>
                <div
                  className={classes.box}
                  style={{
                    backgroundColor:
                      dataGoals?.performance_goal_group?.color || '#e5e5e5',
                  }}
                />
                <Typography
                  className={classes.pfGroup}
                  gutterBottom
                  style={{
                    fontStyle: dataGoals?.performance_goal_group
                      ? 'initial'
                      : 'italic',
                  }}
                >
                  {dataGoals?.performance_goal_group?.name || 'None'}
                </Typography>
              </div>
            </FlexBetween>

            <Grid container>
              {dataAssignment &&
                dataAssignment.total.aggregate.count > 0 &&
                dataAssignment.performance_task_assignments.map(
                  (assignee, index) => {
                    return (
                      <Tooltip
                        key={index}
                        title={
                          assignee.global_user && assignee.global_user.name
                        }
                      >
                        <AvatarCust
                          key={index}
                          alt={
                            assignee.global_user && assignee.global_user.name
                          }
                          src={
                            assignee.global_user && assignee.global_user.avatar
                              ? assignee.global_user.avatar
                              : require('../../../../assets/images/default-avatar.png')
                          }
                        />
                      </Tooltip>
                    )
                  }
                )}
              {dataAssignment && dataAssignment.total.aggregate.count > 5 && (
                <AvatarCust
                  onClick={handleMoreAssignee}
                  style={{cursor: 'pointer'}}
                >
                  +
                  {dataAssignment.total.aggregate.count - limitAssignment < 0
                    ? 0
                    : dataAssignment.total.aggregate.count - limitAssignment}
                </AvatarCust>
              )}
            </Grid>

            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
            >
              <Tab style={{fontWeight: 'bold'}} label="Rincian" />
              <Tab
                style={{fontWeight: 'bold'}}
                label={GLOBAL_GLOSSARY.performance.Description_goal}
              />
              <Tab
                style={{fontWeight: 'bold'}}
                label={GLOBAL_GLOSSARY.performance.Task}
              />
            </Tabs>
          </ModalHeaderWhite>
          <DialogContent>
            <Fragment>
              {value === 0 && <Detail data={data} type={'goal'} />}
              {value === 1 && <Description data={data} type={'goal'} />}
              {value === 2 && (
                <Task
                  idGoals={dataGoals.id}
                  dataKpi={dataGoals.kpi_list}
                  dataDateGoal={dataDateGoals}
                />
              )}
            </Fragment>
          </DialogContent>
        </>
      )}
    </StyledDialog>
  )
}
