import moment from 'moment'
import {COMPANY_ID} from '../../../utils/globals'
import {
  coerceFalsyArray,
  getSearchVariables,
} from '../../../utils/searchFilters'

export const COMPANY_TRANSACTION_FILTER_CONFIG = [
  {
    name: 'Category',
    fieldName: 'category',
    type: 'checkbox',
    options: {
      list: [
        {value: 'in', label: 'In'},
        {value: 'out', label: 'Out'},
      ],
    },
  },
  {
    name: 'Type',
    fieldName: 'type',
    type: 'checkbox',
    options: {
      list: [
        {value: 'company', label: 'Top Up'},
        {value: 'employee', label: 'Top Up Employee'},
      ],
    },
  },
  {
    name: 'Date',
    fieldName: 'date',
    type: 'date',
  },
]

function coerceDate(data) {
  if (!data) {
    return undefined
  }

  const dateFrom = data.from
  const dateTo = data.to

  const conditions = {
    _gte: dateFrom ? moment(dateFrom).format() : undefined,
    _lte: dateTo ? moment(dateTo).format() : undefined,
  }

  return conditions
}

function filterBool(filterData, prop, value) {
  const {include: inc} = filterData

  // if (exc?.[prop]?.length && exc[prop].includes(value)) {
  //   return false
  // }

  if (inc?.[prop]?.length) {
    return inc[prop].includes(value)
  }

  return true
}

export function getCompanyTransactionFilterVariables({
  searchText = '',
  filterData = {},
}) {
  const search = `%${searchText}%`

  const includeIn = filterBool(filterData, 'category', 'in')
  const includeOut = filterBool(filterData, 'category', 'out')
  const includeTopupCompany = filterBool(filterData, 'type', 'company')
  const includeTopupEmployee = filterBool(filterData, 'type', 'employee')
  const includeTopupEmployeeMulti = filterBool(filterData, 'type', 'employee')

  const where = {
    formatted_id: searchText ? {_ilike: search} : undefined,
    transaction_item_table: {_eq: 'system'},
    parent_id: {_is_null: true},
    date_created: coerceDate(filterData.include?.date),
    _or: coerceFalsyArray([
      includeIn &&
        includeTopupCompany && {
          global_wallet_account: {
            company_profiles: {id: {_eq: COMPANY_ID}},
          },
        },

      includeOut && {
        globalWalletAccountByTo: {
          company_profiles: {id: {_eq: COMPANY_ID}},
        },

        to: {
          _is_null:
            includeTopupEmployee && includeTopupEmployeeMulti
              ? undefined
              : !!includeTopupEmployeeMulti,
        },
      },
    ]),
    _not: {
      from: {_is_null: false},
      to: {_is_null: false},
      parent_id: {_is_null: true},
      value: {_lt: 0},
    },
  }

  return where
}

export const EMPLOYEE_TRANSACTION_FILTER_CONFIG = [
  {
    name: 'Date',
    fieldName: 'date',
    type: 'date',
  },
]

export function getEmployeeTransactionFilterVariables({
  userId,
  searchText = '',
  filterData = {},
}) {
  const search = `%${searchText}%`

  const where = {
    formatted_id: searchText ? {_ilike: search} : undefined,
    date_created: coerceDate(filterData.include?.date),
    transaction_item_table: {_eq: 'system'},
    _or: [
      {
        global_wallet_account: {foreign_owner_id: {_eq: COMPANY_ID}},
        globalWalletAccountByTo: {foreign_owner_id: {_eq: userId}},
      },
      {
        parent: {
          global_wallet_account: {foreign_owner_id: {_eq: COMPANY_ID}},
        },
        globalWalletAccountByTo: {foreign_owner_id: {_eq: userId}},
      },
      {
        global_wallet_account: {foreign_owner_id: {_eq: userId}},
        globalWalletAccountByTo: {foreign_owner_id: {_eq: COMPANY_ID}},
      },
    ],
  }

  return where
}

export function getEmployeeFilterVariables({searchText = '', filterData = {}}) {
  const search = `%${searchText}%`

  const where = {
    ...getSearchVariables(filterData),
    _or: searchText
      ? [
          {regno: {_ilike: search}},
          {company_job_profile: {title: {_ilike: search}}},
          {global_user: {name: {_ilike: search}}},
        ]
      : undefined,
  }

  return where
}

export function getEmployeeTopupFilterVariables({
  searchText = '',
  filterData = {},
  excludeWallets = [],
}) {
  const search = `%${searchText}%`

  const orig = {
    ...getSearchVariables(filterData),
    _or: searchText
      ? [
          {regno: {_ilike: search}},
          {company_job_profile: {title: {_ilike: search}}},
          {global_user: {name: {_ilike: search}}},
        ]
      : undefined,
  }

  orig.global_user.wallet_account = {_nin: excludeWallets}
  return orig
}
