import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {Tab, Typography} from '@material-ui/core'

import EmployeeLoanFormPageComponent from './create/EmployeeLoanFormPageComponent'
import PaymentPlanFormPageComponent from './create/PaymentPlanFormPageComponent'

import {
  AntTabs,
  FormTitleContainer,
  FormContainer,
  FormToolbar,
  PaperContainer,
} from '../../../../GlobalStyles'

const CreateLoanActivity = props => {
  const [activeTab, setActiveTab] = useState(props.activeTab || 0)
  const [dataForm, setDataForm] = useState({
    // flat_rate: false, // Hide: until further notice
    deducted_from_salary: false,
  })

  return (
    <PaperContainer>
      <FormToolbar>
        <FormTitleContainer>
          <Typography variant="subtitle1" style={{fontWeight: '600'}}>
            Create Loan {props.title}
          </Typography>
        </FormTitleContainer>
      </FormToolbar>

      <AntTabs
        value={activeTab}
        onChange={(e, newValue) => {
          e.preventDefault()
          setActiveTab(newValue)
        }}
        aria-label="loan-activity-tabs"
      >
        <Tab label="Employee Loan" />
        <Tab label="Payment Plan" disabled={activeTab !== 1} />
      </AntTabs>

      <FormContainer>
        <div style={{display: `${activeTab === 0 ? 'block' : 'none'}`}}>
          <EmployeeLoanFormPageComponent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            dataForm={dataForm}
            setDataForm={setDataForm}
          />
        </div>

        <div style={{display: `${activeTab === 1 ? 'block' : 'none'}`}}>
          <PaymentPlanFormPageComponent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            dataForm={dataForm}
            setDataForm={setDataForm}
          />
        </div>
      </FormContainer>
    </PaperContainer>
  )
}

export default withRouter(CreateLoanActivity)
