import React from 'react'
import { formatatSalary } from '../../../add-employee/utilsForEmployee'
import {
  TextFieldForm,
  DateForm,
  PhoneField,
  SwitchForm,
  SelectForm,
  InputAttachment,
  DateForCustom,
  SelectAutocomplete,
  SelectChips,
  RadioForm,
  TagTextFieldForm,
} from '../../SharedDetailEmployee'
import ClearIcon from '@material-ui/icons/Clear'
import { MenuItem, Typography, makeStyles, TextField } from '@material-ui/core'
import photo from '../../../../../../assets/images/photo.svg'
import document from '../../../../../../assets/images/document.svg'
import { TOKEN, UPLOAD_URL } from '../../../../../../utils/globals'
import axios from 'axios'
import {
  faFileExcel,
  faFileWord,
  faFilePdf,
  faFile,
  faFilePowerpoint,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(() => ({
  buttonFile: {
    margin: '8px 0px 12px 0px',
    width: '8vw',
    height: '8vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    width: '50%',
    height: '50%',
  },
  iconInfo: {
    marginLeft: 10,
    width: 18,
    height: 18,
  },
  textTooltip: {
    fontSize: 12,
    color: '#000',
    display: 'block',
    margin: '2px 0px',
  },
  textBlue: {
    color: '#014a62',
    fontSize: 12,
    display: 'block',
    margin: '2px 0px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  fileUploadded: {
    backgroundColor: '#eff2f4',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    borderRadius: 5,
  },
  top: {
    display: 'flex',
    flexDirection: 'row ',
  },
  fileName: {
    backgroundColor: '#fff',
    padding: '10px 16px',
    width: '100%',
    margin: '0px 10px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    marginTop: 12,
    marginRight: '3vw',
  },
  size: {
    color: '#000',
    fontSize: 12,
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: '#a9a8a8',
    borderRadius: '50%',
    margin: '0px 10px',
  },
  preview: {
    color: '#039be5',
    fontSize: 12,
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  textError: {
    color: '#f44336',
    margin: '8px 14px 0',
    fontSize: '0.75rem',
    fontWeight: '400',
  },
  // icon: {
  //   fontSize: '33px',
  // },
  pdf: {
    color: '#ef4d5e',
  },
  xls: {
    color: '#2c5a1a',
  },
  ppt: {
    color: '#ef4d5e',
  },
  doc: {
    color: '#039be5',
  },
}))

const getIcon = url => {
  let type = null
  let icon = faFile
  if (url) {
    const fileType = url.split('.').pop()
    switch (fileType) {
      case 'pdf':
        type = 'pdf'
        icon = faFilePdf
        break
      case 'xls':
      case 'xlsx':
        type = 'xls'
        icon = faFileExcel
        break
      case 'ppt':
        type = 'ppt'
        icon = faFilePowerpoint
        break
      case 'doc':
      case 'docx':
        type = 'doc'
        icon = faFileWord
        break
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'tiff':
      case 'pjp':
      case 'pjpeg':
        type = 'img'
        icon = null
        break
      default:
        type = null
        icon = faFile
        break
    }
  }
  return [type, icon]
}
function FormInput({
  fieldsList,
  values,
  setValues,
  errorState,
  HELPER_TEXT = 'Bagian ini diperlukan',
  open,
}) {
  const classes = useStyles()

  const [fileType, setFileType] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [errorSize, setErrorSize] = React.useState(false)
  const [errorFile, setErrorFile] = React.useState(false)
  const [errorMax, setErrorMax] = React.useState(false)

  const fileChecker = name => {
    const nameSplited = name.split('.')
    const idx = nameSplited.length - 1
    const res = nameSplited[idx]

    if (
      res === 'doc' ||
      res === 'docx' ||
      res === 'xls' ||
      res === 'pdf' ||
      res === 'xlsx' ||
      res === 'ppt'
    ) {
      setFileType('document')
      return false
    } else if (
      res === 'jpg' ||
      res === 'jpeg' ||
      res === 'png' ||
      res === 'gif' ||
      res === 'tiff' ||
      res === 'pjp' ||
      res === 'pjpeg' ||
      res === 'jfif' ||
      res === 'tif'
    ) {
      setFileType('image')
      return false
    } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
      setFileType('image')
      return false
    } else {
      return true
    }
  }

  const handleChange = ({ fieldName, value }, target, ekstra) => {
    if (target === 'date') {
      setValues(e => ({
        ...e,
        [ekstra]: value,
      }))
    } else {
      setValues(e => ({
        ...e,
        [fieldName]: value,
      }))
    }
  }

  const setClearFile = () => {
    setValues({
      ...values,
      attachments: [],
    })
    setFileType(null)
  }
  const handleDeleteAttachement = (i, attachments) => {
    const newState = [...attachments]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValues({
      ...values,
      attachments: newState,
    })
  }

  const handleChangeFile = (e, link) => {
    const tempFile = Array.from(e.target.files)
    if (tempFile.length + values.attachments.length > 5) {
      setErrorMax(true)
      setTimeout(() => {
        setErrorMax(false)
      }, 3000)
    } else if (tempFile[0].size > 1024 * 1024 * 25) {
      setErrorSize(true)
      setTimeout(() => {
        setErrorSize(false)
      }, 3000)
    } else if (fileChecker(tempFile[0].name)) {
      setErrorFile(true)
      setTimeout(() => {
        setErrorFile(false)
      }, 3000)
    } else {
      setLoading(true)
      tempFile.map(val => {
        const formData = new FormData()
        formData.append('file', val)
        return axios
          .post(UPLOAD_URL, formData, {
            headers: {
              Authorization: 'Bearer ' + TOKEN,
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
          })
          .then(response => {
            setLoading(false)
            setValues(prev => {
              if (link === 'url') {
                return {
                  ...prev,
                  attachments: [
                    ...prev.attachments,
                    { name: val.name, url: response.data.url, size: val.size },
                  ],
                }
              } else {
                return {
                  ...prev,
                  attachments: [
                    ...prev.attachments,
                    { name: val.name, link: response.data.url, size: val.size },
                  ],
                }
              }
            })
          })
          .catch(() => {
            setLoading(false)
          })
      })
      e.target.value = null
    }
  }

  const handleChangeNameFile = (e, i, attachment) => {
    const newState = [...attachment]
    newState[i].name = e.target.value
    setValues({
      ...values,
      attachments: newState,
    })
  }

  const _renderComponent = (res, i) => {
    if (!res) {
      return null
    }

    const {
      label,
      fieldName,
      type,
      value,
      placeholder,
      error,
      rows,
      multiline,
      text,
      labelDate,
      isSalary,
      required,
      option,
      attachments,
      multiple,
      link = 'link',
      filter,
      style,
      inputType = 'text',
      disabled,
      onChange,
      isLoading,
      onInputChange,
      onDeleteChips,
      isAsync,
      loadOptions,
      chips,
      row,
      endAdornment,
      maxDate,
      inputProps,
      customHandleChange,
      customHelperText,
    } = res

    const KEY = `${i}-comp`
    if (type === 'textfield') {
      return (
        <TextFieldForm
          key={KEY}
          value={isSalary ? formatatSalary(value) : value}
          label={label}
          rows={rows}
          style={style}
          type={inputType}
          multiline={multiline}
          placeholder={placeholder}
          onChange={e => {
            customHandleChange
              ? customHandleChange({ fieldName, value: e.target.value })
              : handleChange({ fieldName, value: e.target.value })
          }}
          error={errorState && required && error}
          helperText={
            errorState && required && error && (customHelperText || HELPER_TEXT)
          }
          disabled={disabled}
          endAdornment={endAdornment}
          inputProps={inputProps}
        />
      )
    } else if (type === 'tag-textfield') {
      return (
        <TagTextFieldForm
          key={KEY}
          label={label}
          rows={rows}
          style={style}
          type={inputType}
          multiline={multiline}
          placeholder={placeholder}
          error={errorState && required && error}
          helperText={errorState && required && error && HELPER_TEXT}
          disabled={disabled}
          endAdornment={endAdornment}
          name={fieldName}
          values={values}
          setValues={setValues}
        />
      )
    } else if (type === 'tag-textfield') {
      return (
        <TagTextFieldForm
          key={KEY}
          label={label}
          rows={rows}
          style={style}
          type={inputType}
          multiline={multiline}
          placeholder={placeholder}
          error={errorState && required && error}
          helperText={errorState && required && error && HELPER_TEXT}
          disabled={disabled}
          endAdornment={endAdornment}
          name={fieldName}
          values={values}
          setValues={setValues}
        />
      )
    } else if (type === 'date') {
      return (
        <DateForm
          key={KEY}
          value={value}
          label={label}
          labelDate={labelDate}
          fieldName={fieldName}
          error={(errorState && required && error) || [false, false]}
          maxDate={maxDate}
          onChange={(value, fieldName) =>
            handleChange({ value }, 'date', fieldName)
          }
        />
      )
    } else if (type === 'phone-field') {
      return (
        <PhoneField
          key={KEY}
          open={open}
          error={errorState && required && error}
          label={label}
          onChangePhone={val =>
            handleChange({ fieldName: fieldName[0], value: val })
          }
          onChange={e =>
            handleChange({ fieldName: fieldName[1], value: e.target.value })
          }
          value={value}
          placeholder={placeholder}
        />
      )
    } else if (type === 'switch') {
      return (
        <SwitchForm
          key={KEY}
          label={label}
          text={text}
          value={value}
          onChange={e => handleChange({ fieldName, value: e.target.checked })}
        />
      )
    } else if (type === 'radio') {
      return (
        <RadioForm
          key={KEY}
          label={label}
          options={option}
          disabled={disabled}
          value={value}
          row={row}
          onChange={e => handleChange({ fieldName, value: e.target.value })}
        />
      )
    } else if (type === 'select') {
      return (
        <SelectForm
          key={KEY}
          value={value}
          label={label}
          rows={rows}
          multiline={multiline}
          placeholder={placeholder}
          onChange={e => handleChange({ fieldName, value: e.target.value })}
          error={errorState && required && error}
          helperText={errorState && required && error && HELPER_TEXT}
          child={
            option &&
            option.map((res, i) => {
              return (
                <MenuItem
                  key={i}
                  value={res.value}
                  disabled={res.disabled}
                  style={{ whiteSpace: 'normal' }}
                >
                  {res.name}
                </MenuItem>
              )
            })
          }
        />
      )
    } else if (type === 'attachment') {
      return (
        <>
          <InputAttachment
            key={KEY}
            id={`upload-${KEY}`}
            label={label}
            name={KEY}
            multiple={multiple}
            onChange={e => handleChangeFile(e, link)}
            disabled={attachments.length === 5 || loading}
          />
          {errorSize ? (
            <Typography className={classes.textError}>
              file size cannot exceed 25MB
            </Typography>
          ) : null}
          {errorFile ? (
            <Typography className={classes.textError}>
              This file type is not permitted
            </Typography>
          ) : null}
          {errorMax && (
            <Typography className={classes.textError}>
              the number of attachments must not exceed 5
            </Typography>
          )}
          {attachments.length > 0
            ? attachments.map((res, i) => {
              const [type, icon] = getIcon(res[link])
              return (
                <div key={i} className={classes.fileUploadded}>
                  <div className={classes.top}>
                    {/* {fileCheck(res.name)} */}
                    {icon ? (
                      <FontAwesomeIcon
                        className={classes[type]}
                        icon={icon}
                        style={{ marginRight: 8, fontSize: '56px' }}
                      />
                    ) : (
                      <img
                        alt="icon"
                        className={classes[type]}
                        src={res[link]}
                        style={{ marginRight: 6, width: '45px' }}
                      />
                    )}
                    {/* <img
                        src={fileType === 'document' ? document : photo}
                        style={{marginRight: 6}}
                        alt="doc"
                      /> */}
                    <TextField
                      value={res.name}
                      variant="outlined"
                      fullWidth
                      onChange={e => handleChangeNameFile(e, i, attachments)}
                      className="text-field"
                      size="medium"
                    />
                    <ClearIcon
                      onClick={() => handleDeleteAttachement(i, attachments)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div className={classes.bottom}>
                    <Typography className={classes.size}>
                      {res.size / 1000} kB
                    </Typography>
                    <div className={classes.dot}></div>
                    <a
                      href={res[link]}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={classes.link}
                    >
                      <Typography className={classes.preview}>
                        Pratinjau
                      </Typography>
                    </a>
                  </div>
                </div>
              )
            })
            : null}
          {loading ? (
            <div className={classes.fileUploadded}>
              <div className={classes.top}>
                {/* {fileCheck(res.name)} */}
                <img
                  src={fileType === 'document' ? document : photo}
                  style={{ marginRight: 6 }}
                  alt="doc"
                />
                <div className={classes.fileName}>Uploading data</div>
                <ClearIcon
                  onClick={() => setClearFile()}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className={classes.bottom}>
                <Typography className={classes.size}></Typography>
                <div className={classes.dot}></div>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className={classes.link}
                >
                  <Typography className={classes.preview}></Typography>
                </a>
              </div>
            </div>
          ) : null}
        </>
      )
    } else if (type === 'dateCustom') {
      return (
        <DateForCustom
          key={KEY}
          value={value}
          helperText={errorState && required && error && HELPER_TEXT}
          error={errorState && required && error}
          label={label}
          filter={filter}
          disabled={disabled}
          onChange={value => handleChange({ fieldName, value })}
        />
      )
    } else if (type === 'autocomplete') {
      return (
        <SelectAutocomplete
          key={KEY}
          label={label}
          value={value}
          placeholder={placeholder}
          error={errorState && required && error}
          helperText={errorState && required && error && HELPER_TEXT}
          onChange={({ value, label }) => onChange({ fieldName, value, label })}
          onInputChange={onInputChange}
          options={option}
          isLoading={isLoading}
          isAsync={isAsync}
          loadOptions={loadOptions}
          chips={chips}
          onDeleteChips={data => onDeleteChips({ fieldName, ...data })}
          disabled={disabled}
        />
      )
    } else if (type === 'chips') {
      return (
        <SelectChips
          key={KEY}
          label={label}
          value={value}
          placeholder={placeholder}
          error={errorState && required && error}
          helperText={errorState && required && error && HELPER_TEXT}
          onChange={newValue => onChange({ fieldName, arr: newValue })}
          onInputChange={search => onInputChange({ fieldName, search })}
          options={option}
          isLoading={isLoading}
          onDeleteChips={data => onDeleteChips({ fieldName, ...data })}
        />
      )
    }
  }

  return <div>{fieldsList.map((res, i) => _renderComponent(res, i))}</div>
}

export default FormInput
