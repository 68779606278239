import React from 'react'

import {
  makeStyles,
  Typography,
  Link,
  Divider,
  IconButton,
} from '@material-ui/core'
import Edit from '@material-ui/icons/Edit'

import {PaperSection, FlexBetween} from '../../../GlobalStyles'

// No browsers support `line-clamp` CSS property yet, but the vendor prefix
// `-webkit-line-clamp` is supported by Firefox as well. However this support
// comes with some issues.

// Firefox doesn't like it if we truncate a node that contains multiple "block"
// child nodes, it truncates them separately instead of as a whole, to remedy
// that we'll just replace <p> with <span> as it's inline, and use <br> to
// provide spacing between paragraphs.

// While it is hack-ish at best, this approach is still better than manually
// trying to measure the appropriate truncation.

const useStyles = makeStyles(() => ({
  description: {
    color: '#a9a8a8',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-line',
    overflow: 'hidden',
  },
  more: {
    fontSize: 14,
    cursor: 'pointer',
    display: 'inline-block',
    marginTop: 8,
  },
}))

const READ_INITIAL = 0
const READ_HIDDEN = 1
const READ_VISIBLE = 2

const DescriptionComponent = ({description, feature, openEdit}) => {
  const transformed = React.useMemo(() => {
    if (!description) {
      return ''
    }

    return description
      .replace(/<\/p><p>/g, '</p><br><br><p>')
      .replace(/<p>/g, '<span>')
      .replace(/<\/p>/g, '</span>')
  }, [description])

  const styles = useStyles()

  const [state, setState] = React.useState(READ_INITIAL)
  const nodeRef = React.useRef()

  const toggleShowMore = () => {
    setState(state === READ_HIDDEN ? READ_VISIBLE : READ_HIDDEN)
  }

  React.useEffect(() => {
    const el = nodeRef.current

    if (el.$text === transformed) {
      return
    }

    if (state !== READ_INITIAL) {
      setState(READ_INITIAL)
      return
    }

    el.$text = transformed

    if (el.scrollHeight > el.clientHeight) {
      setState(READ_HIDDEN)
    }
  }, [transformed, state])

  return (
    <PaperSection elevation={2} style={{marginBottom: 22}}>
      <FlexBetween>
        <Typography variant="body1">Description</Typography>

        {(feature === 'company' || feature === 'holding-profile') && (
          <IconButton edge="end" aria-label="edit" onClick={openEdit}>
            <Edit style={{color: '#a9a8a8'}} />
          </IconButton>
        )}
      </FlexBetween>

      <Divider style={{margin: '22px 0'}} />

      <Typography
        innerRef={nodeRef}
        variant="body1"
        className={styles.description}
        style={{WebkitLineClamp: state === READ_VISIBLE ? 'initial' : 5}}
        dangerouslySetInnerHTML={{__html: transformed}}
      />

      {state >= READ_HIDDEN && (
        <Link
          color="secondary"
          onClick={toggleShowMore}
          className={styles.more}
        >
          See {state === READ_HIDDEN ? 'More' : 'Less'}
        </Link>
      )}
    </PaperSection>
  )
}

export default DescriptionComponent
