/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core'
import {
  PrimaryModalHeader,
  WhiteModalHeader,
  WrapperScroll,
} from '../../GlobalStyles'
import { makeStyles } from '@material-ui/styles'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import Divider from '../divider/DividerStyles'
import XLSX from 'xlsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileExcel,
  faFileWord,
  faFilePowerpoint,
  faFilePdf,
  faFile,
} from '@fortawesome/free-solid-svg-icons'
import { COMPANY_ID, TOKEN, UPLOAD_URL, REF_PROV_CITY } from '../../utils/globals'
import axios from 'axios'

// GRAPHQL STUFFS
import { useQuery } from '@apollo/react-hooks'
import {
  GET_PROVINCE,
  GET_CITY,
  GET_JOB_POSITION_TEMPLATE_SHEETS,
  GET_RELIGION,
  // GET_LIST_ORGANIZATION,
  // LIST_JOB_PROFILE,
  GET_LIST_ORG_LEVEL_TEMPLATE_SHEET,
  LIST_JOB_PROFILE_SHEET_TEMPLATE,
  GET_JOB_FAMILY_FUNCTION_SHEET,
  // GET_JOB_FAMILY_NAME,
  GET_JOB_TITLE_TEMPLATE_SHEET,
  GET_LIST_JOB_GRADE_SHEET,
} from '../../graphql/queries'
import { IMPORT_ORGANIZATION_VALIDATION } from '../../graphql/mutations'
import MultipleFile from './MultipleFile'
import { GLOBAL_GLOSSARY } from '../../utils/constant'

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}
const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
  },
  checkbox: { padding: 0, marginRight: 10 },
  content: { borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24 },
  btnImport: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: { paddingRight: 24, paddingBottom: 24 },
}))
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`
const DEFAULT_STATE_TEMPLATES = {
  isMultiple: false,
  listTemplates: [],
  errorsImportMultiple: [],
  isShowListTemplate: true,
  // isErrorValidateTableName: () => false,
}

export default function ImportPopup(props) {
  const classes = useStyles()
  const {
    open,
    handleClose,
    position,
    mutation,
    existingData,
    isAlreadyImported,
    setIsAlreadyImported,
    setImport,
    templates = DEFAULT_STATE_TEMPLATES,
    onDownloadMaster = undefined,
    fileLinkTemplate,
  } = props
  const [check, setCheck] = useState(false)
  const [confirmToImport, setConfirmToImport] = useState(false)
  const [dis, setDis] = useState(true)
  const [isRead, setIsRead] = useState(false)
  const [validations, setValidations] = useState(props.errorsImport)
  const [validationsMultiple, setValidationsMultiple] = useState([])
  const [rowsCantImported, setRowsCantImported] = useState([])
  // const [uniqueFields, setUniqueFields] = useState([])
  const [officeCodes, setOfficeCodes] = useState(['OUTEST2'])
  const [file, setFile] = useState({
    file: {},
    data: [],
    multipleFiles: [],
  })

  const [search, setSearch] = useState('')
  // baris yang dipilih ketika akan melakukan import per baris / bulk
  const [selectedRows, setSelectedRows] = useState([])
  React.useEffect(() => {
    if (open) {
      setValidations(props.errorsImport)
      setValidationsMultiple(templates.errorsImportMultiple)
    }
  }, [props.errorsImport, templates.errorsImportMultiple, open])

  const uploadFileImport = async (file, isMultipleFile = false) => {
    const _uploadFile = async data => {
      const formData = new FormData()
      formData.append('file', data)
      const _initialUpload = {
        id: data.name,
        name: data.name,
        size: data.size,
        url: '',
        loaded: 0,
        isUploaded: false,
      }
      setFile(e => {
        return {
          ...e,
          multipleFiles: [...(e.multipleFiles || []), _initialUpload],
        }
      })
      const response = await axios.post(UPLOAD_URL, formData, {
        headers: {
          Authorization: 'Bearer ' + TOKEN,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,

        onUploadProgress: progressEvent => {
          setFile(e => {
            const item = {
              id: data.name,
              name: data.name,
              size: data.size,
              url: '',
              loaded: progressEvent.loaded,
              isUploaded: false,
            }
            const items = e.multipleFiles
            const foundIndex = items.findIndex(
              x => x.id === item.id && !x.isUploaded
            )
            items[foundIndex] = item
            return { ...e, multipleFiles: items }
          })
        },
      })
      setFile(e => {
        const item = {
          id: data.name,
          name: data.name,
          size: data.size,
          url: response.data.url,
          loaded: data.size,
          isUploaded: true,
        }
        const items = e.multipleFiles
        const foundIndex = items.findIndex(
          x => x.id === item.id && !x.isUploaded
        )
        items[foundIndex] = item
        return { ...e, multipleFiles: items }
      })
    }

    if (isMultipleFile) {
      file.forEach(fileRes => {
        const reader = new FileReader()
        const rABS = !!reader.readAsBinaryString
        reader.onload = e => {
          const bstr = e.target.result
          const wb = XLSX.read(bstr, {
            type: rABS ? 'binary' : 'array',
            bookVBA: true,
          })
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws)
          if (fileRes.size > 5000000) {
            return setValidationsMultiple(vals => [
              ...vals,
              {
                name: 'File Is Too Large',
                desc: 'File size is not more than 5 MB',
              },
            ])
          } else if (data.length === 0) {
            return setValidationsMultiple(vals => [
              ...vals,
              {
                name: 'Error Data',
                desc: 'Please ensure the data is exist at least 1 row',
              },
            ])
          }
          // else if(templates.isErrorValidateTableName(fileRes)) {
          //   return setValidationsMultiple(vals => [
          //     ...vals,
          //     {
          //       name: 'Error Upload',
          //       desc: "Make sure the uploaded file name contains the template name",
          //     },
          //   ])
          // }
          else _uploadFile(fileRes)
        }
        if (rABS) {
          reader.readAsBinaryString(fileRes)
        } else {
          reader.readAsArrayBuffer(fileRes)
        }
      })
    } else {
      try {
        const formData = new FormData()
        formData.append('file', file)
        const response = await axios.post(UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true
        })
        props.setFileURL(response.data.url)
        setFile({ file: file, data: [] })
      } catch (error) {
        console.error('error', error)
      } finally {
        setDis(false)
        setIsRead(false)
        setCheck(true)
      }
    }
  }

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles) {
        if (templates && templates.isMultiple) {
          uploadFileImport(acceptedFiles, true)
        } else {
          const files = acceptedFiles[0]
          setIsRead(true)
          if (files) {
            if (!navigator.onLine) {
              setIsRead(false)
              setCheck(true)
              setDis(true)
              setFile({ file: files, data: [] })
              return setValidations(vals => [
                ...vals,
                {
                  name: 'Connection Problem',
                  desc: 'Please check your internet connection',
                },
              ])
            }

            if (files.size > 5000000) {
              /* //max file size issue
            enqueueSnackbar('File Size too large', {
              variant: 'error',
              autoHideDuration: 3000,
            }) */
              setIsRead(false)
              setCheck(true)
              setDis(true)
              setFile({ file: files, data: [] })
              return setValidations(vals => [
                ...vals,
                {
                  name: 'File Is Too Large',
                  desc: 'File size is not more than 5 MB',
                },
              ])
            }

            // if (files.type !== 'application/vnd.ms-excel') {
            //   setIsRead(false)
            //   setCheck(true)
            //   setDis(true)
            //   setFile({file: files, data: []})
            //   return setValidations(vals => [
            //     ...vals,
            //     {
            //       name: 'Invalid File Extension',
            //       desc: 'Accepted file type is .csv',
            //     },
            //   ])
            // }

            const reader = new FileReader()
            const rABS = !!reader.readAsBinaryString
            reader.onload = e => {
              /* Parse data */
              const bstr = e.target.result
              const wb = XLSX.read(bstr, {
                type: rABS ? 'binary' : 'array',
                bookVBA: true,
              })
              /* Get first worksheet */
              const wsname = wb.SheetNames[0]
              const ws = wb.Sheets[wsname]
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_json(ws)
              /* Validations */
              if (data.length === 0) {
                setIsRead(false)
                setCheck(true)
                setDis(true)
                setFile({ file: files, data: data })
                return setValidations(vals => [
                  ...vals,
                  {
                    name: 'Error Data',
                    desc: 'Please ensure the data is exist at least 1 row',
                  },
                ])
              }

              /* Update state */
              if (position === 'Office' || position === 'Competency') {
                uploadFileImport(files)
              } else if (position === 'Level') {
                const filteredData = data.map(function (orgLevel) {
                  return {
                    code: orgLevel.code,
                    name: orgLevel.name,
                  }
                })

                setFile({ file: files, data: filteredData })
              } else if (position === 'Regulations') {
                const filteredData = data.map(function (regulation) {
                  return {
                    name: regulation.name,
                    description: regulation.description,
                  }
                })

                setFile({ file: files, data: filteredData })
              } else {
                uploadFileImport(files)
              }
            }
            if (rABS) {
              reader.readAsBinaryString(files)
            } else {
              reader.readAsArrayBuffer(files)
            }
          }
        }
      }
    },
    [position]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    /* acceptedFiles, */
  } = useDropzone({ onDrop })
  const SheetJSFT = ['.csv']

  React.useEffect(() => {
    if (position === 'Office' && file.data.length > 0) {
      file.data.filter((data, index) => {
        // Check mandatory fields
        if (!data.address) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Address cannot be empty`,
          ])
        }
        if (!data.city) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | City cannot be empty`,
          ])
        }
        if (!data.office_code) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Office Code cannot be empty`,
          ])
        }
        if (!data.office_name) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Office Name cannot be empty`,
          ])
        }
        if (!data.postal_code) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Postal Code cannot be empty`,
          ])
        }
        if (!data.province) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Province cannot be empty`,
          ])
        }

        if (!data.status) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Status cannot be empty`,
          ])
        }
        // Check mandatory columns
        if (!('address' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for address should be exist`,
          ])
        }
        if (!('city' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for city should be exist`,
          ])
        }
        if (!('office_code' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Office Code should be exist`,
          ])
        }
        if (!('office_name' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Office Name should be exist`,
          ])
        }
        if (!('postal_code' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Postal Code should be exist`,
          ])
        }
        if (!('province' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Province should be exist`,
          ])
        }
        if (!('status' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Status should be exist`,
          ])
        }

        // Duplicate value
        if (
          existingData.some(
            existingData => existingData.office_code === data.office_code
          )
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Office Code must be unique`,
          ])
        }

        // Max characters
        if (data.office_code && data.office_code.length > 30) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Office Code: The maximum number of characters is 30 characters`,
          ])
        }

        if (data.office_name && data.office_name.length > 100) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Office Name: The maximum number of characters is 100 characters`,
          ])
        }
      })
    } else if (position === 'Organization Unit' && file.data.length > 0) {
      file.data.filter((data, index) => {
        // Check mandatory fields
        if (!data.level) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Org Level cannot be empty`,
          ])
        }
        if (!data.code) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Org Unit Code cannot be empty`,
          ])
        }

        if (!data.name) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Org Name cannot be empty`,
          ])
        }

        if (!data.parent) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Parent cannot be empty`,
          ])
        }

        // Check mandatory columns
        if (!('level' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Org Level should be exist`,
          ])
        }
        if (!('code' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Org Unit Code should be exist`,
          ])
        }
        if (!('name' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Org Name should be exist`,
          ])
        }
        if (!('parent' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Parent should be exist`,
          ])
        }
        /* if (!('actingAs' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Acting As should be exist`,
          ])
        } */

        // Duplicate value
        if (
          existingData.some(existingData => existingData.code === data.code)
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Org Unit Code must be unique`,
          ])
        }

        // Max characters
        if (data.code && data.code.length > 30) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Org Unit Code: The maximum number of characters is 30 characters`,
          ])
        }

        if (data.name && data.name.length > 100) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Org Unit Name: The maximum number of characters is 100 characters`,
          ])
        }
      })
    } else if (position === 'Job Grade' && file.data.length > 0) {
      file.data.filter((data, index) => {
        // Check mandatory fields

        if (!data.code) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Grade Code cannot be empty`,
          ])
        }

        if (!data.name) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Grade Name cannot be empty`,
          ])
        }

        /* if (!data.description) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Grade Description cannot be empty`,
          ])
        } */

        // Check mandatory columns
        if (!('code' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Grade Code should be exist`,
          ])
        }
        if (!('name' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Grade Name should be exist`,
          ])
        }
        /* if (!('description' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Grade Description should be exist`,
          ])
        } */

        // Duplicate value
        if (
          existingData.some(existingData => existingData.code === data.code)
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Org Level Code must be unique`,
          ])
        }

        // Max characters
        if (data.code && data.code.length > 30) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Code: The maximum number of characters is 30 characters`,
          ])
        }

        if (data.name && data.name.length > 100) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Name: The maximum number of characters is 100 characters`,
          ])
        }
      })
    } else if (position === 'Job Family' && file.data.length > 0) {
      file.data.filter((data, index) => {
        // Check mandatory fields

        if (!data.code) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | JF Name Code cannot be empty`,
          ])
        }

        if (!data.name) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | JF Name cannot be empty`,
          ])
        }

        /* if (!data.description) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Grade Description cannot be empty`,
          ])
        } */

        // Check mandatory columns
        if (!('code' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for JF Name Code should be exist`,
          ])
        }
        if (!('name' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for JF Name should be exist`,
          ])
        }
        /* if (!('description' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Grade Description should be exist`,
          ])
        } */

        // Duplicate value
        if (
          existingData.some(existingData => existingData.code === data.code)
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | JF Name Code must be unique`,
          ])
        }

        // Max characters
        if (data.code && data.code.length > 30) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | JF Name Code: The maximum number of characters is 30 characters`,
          ])
        }

        if (data.name && data.name.length > 100) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | JF Name: The maximum number of characters is 100 characters`,
          ])
        }
      })
    } else if (position === 'Job Family Function' && file.data.length > 0) {
      file.data.filter((data, index) => {
        // Check mandatory fields

        if (!data.code) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | JF Function Code cannot be empty`,
          ])
        }

        if (!data.name) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | JF Function Code cannot be empty`,
          ])
        }

        if (!data.family) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | JF Name Code cannot be empty`,
          ])
        }

        /* if (!data.description) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Description cannot be empty`,
          ])
        } */

        // Check mandatory columns
        if (!('code' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for JF Function Code should be exist`,
          ])
        }
        if (!('name' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for JF Function Name Code should be exist`,
          ])
        }
        if (!('family' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for JF Name Code should be exist`,
          ])
        }

        if (
          existingData.some(existingData => existingData.code === data.code)
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | JF Function Code must be unique`,
          ])
        }

        // Max characters
        if (data.code && data.code.length > 30) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | JF Function Code: The maximum number of characters is 30 characters`,
          ])
        }

        if (data.name && data.name.length > 100) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | JF Function Name: The maximum number of characters is 100 characters`,
          ])
        }
      })
    } else if (position === 'Designation' && file.data.length > 0) {
      file.data.filter((data, index) => {
        // Check mandatory fields
        if (!data.code) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Title Code cannot be empty`,
          ])
        }

        /*  if (!data.family) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Family cannot be empty`,
          ])
        } */

        if (!data.name) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Title Name cannot be empty`,
          ])
        }

        if (!data.status) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Status cannot be empty`,
          ])
        }

        // Check mandatory columns
        if (!('code' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Title Code should be exist`,
          ])
        }
        if (!('name' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Title Name should be exist`,
          ])
        }
        /* if (!('family' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Title Family should be exist`,
          ])
        } */
        if (!('status' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Status should be exist`,
          ])
        }

        // Duplicate value
        if (
          existingData.some(
            existingData => existingData.designation_code === data.code
          )
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Title Code must be unique`,
          ])
        }

        // Max characters
        if (data.code && data.code.length > 30) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Job Title Code: The maximum number of characters is 30 characters`,
          ])
        }

        /* if (data.family && data.family.length > 30) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
              1} | Job Family Code: The maximum number of characters is 30 characters`,
          ])
        } */

        if (data.name && data.name.length > 100) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Job Title Name: The maximum number of characters is 100 characters`,
          ])
        }
      })
    } else if (position === 'Employees' && file.data.length > 0) {
      file.data.filter((data, index) => {
        // Check mandatory fields
        if (!data.name) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Name cannot be empty`,
          ])
        }

        if (!data.gender) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Gender cannot be empty`,
          ])
        }

        if (!data.email) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Gender cannot be empty`,
          ])
        }

        if (!data.country_code_phone) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Country Code Phone cannot be empty`,
          ])
        }

        if (!data.phone_number) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Phone Number cannot be empty`,
          ])
        }

        if (!Number.isInteger(data.phone_number)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Phone Number should be number`,
          ])
        }

        if (!data.password) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Password cannot be empty`,
          ])
        }

        if (!data.religion) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Religion cannot be empty`,
          ])
        }

        if (!data.address) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Address cannot be empty`,
          ])
        }

        if (!data.province) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Province cannot be empty`,
          ])
        }

        if (!data.city) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | City cannot be empty`,
          ])
        }

        if (!data.postal_code) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Postal Code cannot be empty`,
          ])
        }

        if (!data.regno) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Regno cannot be empty`,
          ])
        }

        if (!data.job_profile) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Profile cannot be empty`,
          ])
        }

        if (!data.role) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Role cannot be empty`,
          ])
        }

        if (!data.startdate) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Start Date cannot be empty`,
          ])
        }

        if (
          data.startdate &&
          data.startdate.toString().split('-').length !== 3
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Start Date format should be YYYY-MM-DD`,
          ])
        }

        if (!data.enddate) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | End Date cannot be empty`,
          ])
        }

        if (data.enddate && data.enddate.toString().split('-').length !== 3) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | End Date format should be YYYY-MM-DD`,
          ])
        }

        if (!data.salary) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Salary cannot be empty`,
          ])
        }

        if (!data.legal) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Legal cannot be empty`,
          ])
        }

        if (!data.time) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Time cannot be empty`,
          ])
        }

        if (!data.place) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Place cannot be empty`,
          ])
        }
        if (!data.replacement) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Replacement cannot be empty`,
          ])
        }

        // Check mandatory columns
        if (!('name' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for name should be exist`,
          ])
        }
        if (!('gender' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for gender should be exist`,
          ])
        }
        if (!('email' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for email should be exist`,
          ])
        }
        if (!('country_code_phone' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for country_code_phone should be exist`,
          ])
        }
        if (!('phone_number' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for phone_number should be exist`,
          ])
        }
        if (!('password' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for password should be exist`,
          ])
        }
        if (!('religion' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for religion should be exist`,
          ])
        }
        if (!('address' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for address should be exist`,
          ])
        }
        if (!('province' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for province should be exist`,
          ])
        }
        if (!('city' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for city should be exist`,
          ])
        }
        if (!('postal_code' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for postal_code should be exist`,
          ])
        }
        if (!('regno' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for regno should be exist`,
          ])
        }
        if (!('job_profile' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for job_profile should be exist`,
          ])
        }
        if (!('role' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for role should be exist`,
          ])
        }
        if (!('startdate' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for startdate should be exist`,
          ])
        }
        if (!('enddate' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for enddate should be exist`,
          ])
        }
        if (!('salary' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for salary should be exist`,
          ])
        }
        if (!('legal' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for legal should be exist`,
          ])
        }
        if (!('time' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for time should be exist`,
          ])
        }
        if (!('place' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for place should be exist`,
          ])
        }
        if (!('replacement' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for replacement should be exist`,
          ])
        }

        // Duplicate value
        if (
          existingData.people_work_placements.some(
            existingData => existingData.regno === data.regno
          )
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Regno must be unique`,
          ])
        }

        if (
          existingData.emails.some(
            existingData => existingData.email === data.email
          )
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Email must be unique`,
          ])
        }

        if (
          existingData.people_phones.some(
            existingData => existingData.phone === data.phone_number
          )
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Phone Number must be unique`,
          ])
        }
      })
    } else if (position === 'Position' && file.data.length > 0) {
      file.data.filter((data, index) => {
        // Check mandatory fields
        if (!data.positionID) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Position Code cannot be empty`,
          ])
        }

        if (!data.positionName) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Position Name cannot be empty`,
          ])
        }

        if (!data.organizationUnit) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Organization Unit cannot be empty`,
          ])
        }
        if (!data.parent) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Parent cannot be empty`,
          ])
        }

        if (!data.designation) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Designation cannot be empty`,
          ])
        }

        if (!data.officeAddress) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Office Address cannot be empty`,
          ])
        }

        if (!data.level) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Level cannot be empty`,
          ])
        }

        if (!data.grade) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Grade cannot be empty`,
          ])
        }

        if (!data.slotPerson) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Slot Person cannot be empty`,
          ])
        }

        if (!data.access_right) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Access Right cannot be empty`,
          ])
        }

        if (!data.job_description) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Description cannot be empty`,
          ])
        }

        if (!data.job_responsibility) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Job Responsibility cannot be empty`,
          ])
        }

        if (!data.salary_rate_from) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Salary rate from cannot be empty`,
          ])
        }

        if (!data.salary_rate_to) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Salary rate to cannot be empty`,
          ])
        }

        // Check mandatory columns
        if (!('positionID' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Position ID should be exist`,
          ])
        }
        if (!('positionName' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Position Name should be exist`,
          ])
        }
        if (!('organizationUnit' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Organization Unit should be exist`,
          ])
        }
        if (!('parent' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Parent should be exist`,
          ])
        }
        if (!('designation' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Designation should be exist`,
          ])
        }
        if (!('officeAddress' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Office Address should be exist`,
          ])
        }
        if (!('level' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Level should be exist`,
          ])
        }
        if (!('grade' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Level should be exist`,
          ])
        }
        if (!('slotPerson' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Slot Person should be exist`,
          ])
        }
        if (!('access_right' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Access Right should be exist`,
          ])
        }
        if (!('job_description' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Description should be exist`,
          ])
        }
        if (!('job_responsibility' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Job Responsibility should be exist`,
          ])
        }
        if (!('salary_rate_from' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Salary Rate From should be exist`,
          ])
        }
        if (!('salary_rate_to' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for Salary Rate To should be exist`,
          ])
        }

        // Duplicate value
        // console.log("existingData position", existingData)
        if (
          existingData.some(
            existingData => existingData.code === data.positionID
          )
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Code must be unique`,
          ])
        }

        // Max characters
        if (data.positionID && data.positionID.length > 30) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Position Code: The maximum number of characters is 30 characters`,
          ])
        }

        if (data.positionName && data.positionName.length > 150) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Position Name: The maximum number of characters is 150 characters`,
          ])
        }
      })
    } else if (position === 'Job Level' && file.data.length > 0) {
      file.data.filter((data, index) => {
        // Check mandatory fields
        if (!data.code) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Code cannot be empty`,
          ])
        }

        if (!data.name) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Name cannot be empty`,
          ])
        }

        if (!data.color_hex) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Legend Color cannot be empty`,
          ])
        }

        // Check mandatory columns
        if (!('code' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for code should be exist`,
          ])
        }
        if (!('name' in data)) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Error Column | Mandatory column for name should be exist`,
          ])
        }

        // Duplicate value
        if (
          existingData.some(existingData => existingData.code === data.code)
        ) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index + 1} | Code must be unique`,
          ])
        }

        // Max characters
        if (data.code && data.code.length > 30) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Code: The maximum number of characters is 30 characters`,
          ])
        }

        if (data.name && data.name.length > 100) {
          setRowsCantImported(rowsCantImported => [
            ...rowsCantImported,
            `Row ${index +
            1} | Name: The maximum number of characters is 100 characters`,
          ])
        }
      })
    }
  }, [file.data])

  React.useEffect(() => {
    if (validations.length > 0) {
      setDis(true)
    }
  }, [validations])

  const handleChange = e => {
    // if(e.target.files){
    if (templates && templates.isMultiple) {
      uploadFileImport(
        Object.keys(e.target.files).map(key => e.target.files[key]),
        true
      )
    } else {
      setIsRead(true)
      const files = e.target.files

      if (!navigator.onLine) {
        setIsRead(false)
        setCheck(true)
        setDis(true)
        setFile({ file: files[0], data: [] })
        return setValidations(vals => [
          ...vals,
          {
            name: 'Connection Problem',
            desc: 'Please check your internet connection',
          },
        ])
      }

      if (files[0].size > 5000000) {
        setIsRead(false)
        setCheck(true)
        setDis(true)
        setFile({ file: files[0], data: [] })
        return setValidations(vals => [
          ...vals,
          {
            name: 'File Is Too Large',
            desc: 'File size is not more than 5 MB',
          },
        ])
      }

      // if (files[0].type !== 'application/vnd.ms-excel') {
      //   setIsRead(false)
      //   setCheck(true)
      //   setDis(true)
      //   setFile({file: files[0], data: []})
      //   return setValidations(vals => [
      //     ...vals,
      //     {
      //       name: 'Invalid File Extension',
      //       desc: 'Accepted file type is .csv',
      //     },
      //   ])
      // }

      const reader = new FileReader()
      const rABS = !!reader.readAsBinaryString

      reader.onload = e => {
        /* Parse data */
        const bstr = e.target.result
        const wb = XLSX.read(bstr, {
          type: rABS ? 'binary' : 'array',
          bookVBA: true,
        })
        /* Get first worksheet */
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws)

        /* Validations */
        if (data.length === 0) {
          setIsRead(false)
          setCheck(true)
          setDis(true)
          setFile({ file: files[0], data: data })
          return setValidations(vals => [
            ...vals,
            {
              name: 'Error Data',
              desc: 'Please ensure the data is exist at least 1 row',
            },
          ])
        }

        uploadFileImport(files[0])
      }

      if (rABS) {
        reader.readAsBinaryString(files[0])
      } else {
        reader.readAsArrayBuffer(files[0])
      }
    }
  }

  // SHEETS TEMPLATE
  const {
    data: province,
    error: provinceError,
    loading: provinceLoad,
  } = useQuery(GET_PROVINCE, { wlb_skipPatch: true })

  const { data: city, error: cityError, loading: cityLoad } = useQuery(GET_CITY)

  const {
    data: religion,
    error: religionError,
    loading: religionLoad,
  } = useQuery(GET_RELIGION, {
    wlb_skipPatch: true,
  })

  const {
    data: templateSheets,
    error: templateSheetsError,
    loading: templateSheetsLoad,
  } = useQuery(GET_JOB_POSITION_TEMPLATE_SHEETS, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  /* const {
    data: jobProfile,
    error: jobProfileError,
    loading: jobProfileLoad,
  } = useQuery(LIST_JOB_PROFILE) */

  const {
    data: jobProfileSheet,
    error: jobProfileErrorSheet,
    loading: jobProfileLoadSheet,
  } = useQuery(LIST_JOB_PROFILE_SHEET_TEMPLATE, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  const {
    data: orgLevelSheet,
    error: orgLevelErrorSheet,
    loading: orgLevelLoadSheet,
  } = useQuery(GET_LIST_ORG_LEVEL_TEMPLATE_SHEET, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  const {
    data: jobFamSheet,
    error: jobFamErrorSheet,
    loading: jobFamLoadSheet,
  } = useQuery(GET_JOB_FAMILY_FUNCTION_SHEET, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  const {
    data: jobFamNameSheet,
    error: jobFamNameErrorSheet,
    loading: jobFamNameLoadSheet,
  } = useQuery(GET_JOB_FAMILY_FUNCTION_SHEET, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  const {
    data: jobTitleTemplateSheet,
    error: jobTitleTemplateErrorSheet,
    loading: jobTitleTemplateLoadSheet,
  } = useQuery(GET_JOB_TITLE_TEMPLATE_SHEET, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  const {
    data: jobGradeTemplateSheet,
    error: jobGradeTemplateErrorSheet,
    loading: jobGradeTemplateLoadSheet,
  } = useQuery(GET_LIST_JOB_GRADE_SHEET, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  // VALIDATION QUERIES
  const {
    data: listOfficeCodesValidations,
    error: errorListOfficeCodesValidations,
    loading: loadingListOfficeCodesValidations,
  } = useQuery(IMPORT_ORGANIZATION_VALIDATION, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
      code: ['OUTEST2'],
    },
  })

  if (
    provinceLoad ||
    cityLoad ||
    templateSheetsLoad ||
    loadingListOfficeCodesValidations ||
    religionLoad ||
    orgLevelLoadSheet ||
    // || jobProfileLoad
    jobProfileLoadSheet ||
    jobFamLoadSheet ||
    jobFamNameLoadSheet ||
    jobTitleTemplateLoadSheet ||
    jobGradeTemplateLoadSheet
  ) {
    return <CircularProgress />
  } else if (
    provinceError !== undefined ||
    cityError !== undefined ||
    religionError !== undefined ||
    errorListOfficeCodesValidations !== undefined ||
    templateSheetsError !== undefined ||
    jobProfileErrorSheet !== undefined ||
    orgLevelErrorSheet !== undefined ||
    jobFamErrorSheet !== undefined ||
    jobFamNameErrorSheet !== undefined ||
    jobTitleTemplateErrorSheet !== undefined ||
    jobGradeTemplateErrorSheet !== undefined
    // || jobProfileError !== undefined
  ) {
    return (
      <div>
        {JSON.stringify(provinceError)}
        {JSON.stringify(cityError)}
        {JSON.stringify(religionError)}
        {JSON.stringify(templateSheetsError)}
        {/* {jobProfileError} */}
        {JSON.stringify(orgLevelErrorSheet)}
        {JSON.stringify(jobProfileErrorSheet)}
        {JSON.stringify(jobFamErrorSheet)}
        {JSON.stringify(jobFamNameErrorSheet)}
        {JSON.stringify(jobTitleTemplateErrorSheet)}
        {JSON.stringify(jobGradeTemplateErrorSheet)}
      </div>
    )
  }

  const pro = []

  const MASTER_DATA = {
    province,
    city,
    religion,
  }

  if (province) {
    // pro = province.global_provinces
    province.global_provinces.filter(prov =>
      pro.push({
        id: prov.id,
        code: prov.code,
        name: prov.name,
      })
    )
  }

  const cit = []
  if (city) {
    // cit = city.global_cities
    city.global_cities.filter(ci =>
      cit.push({
        id: ci.id,
        code: ci.code,
        name: ci.name,
      })
    )
  }

  const rel = []
  if (religion) {
    // rel = religion.global_religions
    religion.global_religions.filter(religion =>
      rel.push({
        id: religion.id,
        name: religion.name,
      })
    )
  }

  const org = []
  const off = []
  const lvl = []
  const dsg = []
  if (templateSheets) {
    templateSheets.organization.filter(perOrg => {
      org.push({
        'Organization Unit Code': perOrg.code,
        'Organization Unit Name': perOrg.name,
      })
    })

    templateSheets.office_address.filter(perOff => {
      off.push({
        'Office Code': perOff.office_code,
        'Office Name': perOff.office_name,
      })
    })

    templateSheets.level.filter(perLvl => {
      lvl.push({
        'Job Level Code': perLvl.code,
        'Job Level Name': perLvl.name,
      })
    })

    templateSheets.designation.filter(perDes => {
      dsg.push({
        'Job Title Code': perDes.designation_code,
        'Job Title Name': perDes.name,
      })
    })
    // console.log('templateSheets.designation', templateSheets.designation)
  }

  const joPro = []
  /* if (jobProfile) {
    joPro = jobProfile.company_organizations
  } */
  if (jobProfileSheet) {
    // joPro = jobProfileSheet.company_job_profiles
    jobProfileSheet.company_job_profiles.filter(joProp => {
      joPro.push({
        id: joProp.id,
        title: joProp.title,
      })
    })
  }

  const orgLvl = []

  if (orgLevelSheet) {
    // console.log("orgLevelSheet.company_organization_levels", orgLevelSheet.company_organization_levels)
    orgLevelSheet.company_organization_levels.filter(perOrgLvl => {
      orgLvl.push({
        'Org Level Code': perOrgLvl.code,
        'Org Level Name': perOrgLvl.name,
      })
    })
  }

  let jobFam = []

  if (jobFamSheet) {
    jobFam = jobFamSheet.job_family_functions
  }

  const jobFamName = []

  if (jobFamNameSheet) {
    // console.log("jobFamNameSheetx", jobFamNameSheet)
    // jobFamName = jobFamNameSheet.job_families
    jobFamNameSheet.job_families.filter(perJobFamName => {
      jobFamName.push({
        'Job Family Code': perJobFamName.code,
        'Job Family Name': perJobFamName.name,
      })
    })
  }

  const jobTitleTempSheet = []
  if (jobTitleTemplateSheet) {
    // console.log("jobTitleTemplateSheet", jobTitleTemplateSheet)
    jobTitleTemplateSheet.job_family_functions.filter(perJt => {
      jobTitleTempSheet.push({
        'Job Family Code': perJt.code,
        'Job Family Name':
          (perJt.job_family ? perJt.job_family.name : '') + perJt.name,
        // "Job Family Name" : perJt.name + perJt.job_family,
      })
    })
  }

  const jobGrades = []
  if (jobGradeTemplateSheet) {
    jobGradeTemplateSheet.job_grades.filter(perJg => {
      jobGrades.push({
        'Job Grade Code': perJg.code,
        'Job Grade Name': perJg.name,
      })
    })
  }
  /* let listOfficeCodes = []
  if (listOfficeCodesValidations) {

    listOfficeCodes = listOfficeCodesValidations.company_addresses;
  } */
  /* province.global_provinces.map(options => {
      const provinceOptions = {
        value: options.id,
        label: options.name,
      }
      pro.push(provinceOptions)
    }) */

  const closeCheck = () => {
    setDis(true)
    setCheck(false)
    setIsRead(false)
    setFile({
      file: {},
      data: [],
    })
    setValidations([])
    setRowsCantImported([])
    setConfirmToImport(false)
    setIsAlreadyImported(false)
    props.setFileURL('')
  }
  const submitImport = () => {
    if (templates.isMultiple) {
      setConfirmToImport(false)
      props.mutation({ isMultiple: templates.isMultiple, file })
      handleClose()
      resetData()
    } else {
      mutation(file.data)
      // mutation(selectedRows)
      setCheck(false)
      setIsRead(false)
      setDis(true)
      setFile({
        file: {},
        data: [],
      })
    }
  }
  const tryAgain = async () => {
    if (templates.isMultiple) {
      setValidationsMultiple([])
    }
    setValidations([])
    setCheck(false)
    setIsRead(false)
    setDis(true)
    setFile({
      file: {},
      data: [],
      multipleFiles: [],
    })
    setValidations([])
    setRowsCantImported([])
    setConfirmToImport(false)
    setIsAlreadyImported(false)
    if (
      position === 'Bank & Salary' ||
      position === 'Component' ||
      position === 'Multiplier' ||
      position === 'Assignment Component'
    ) {
      props.closeBaloon()
    }
    // props.closeBaloon()
  }

  const generateTemplate = () => {
    if (position === 'Office') {
      const data = {
        template: [
          {
            office_code: 'WLB1001',
            office_name: 'Kreasi Inovasi Digital 1',
            address: 'Jl. Jend Sudirman Kav 52',
            province: 3,
            city: 119,
            postal_code: 12190,
            status: 'Active',
          },
          {
            office_code: 'WLB1002',
            office_name: 'Kreasi Inovasi Digital 2',
            address: 'Jl. Jend Sudirman Kav 52',
            province: 3,
            city: 119,
            postal_code: 12190,
            status: 'Inactive',
          },
        ],
        provinces: pro,
        cities: cit,
      }

      var templateWS = XLSX.utils.json_to_sheet(data.template)
      var provincesWS = XLSX.utils.json_to_sheet(data.provinces)
      var citiesWS = XLSX.utils.json_to_sheet(data.cities)
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      // XLSX.utils.book_append_sheet(wb, provincesWS, 'provinces')
      // XLSX.utils.book_append_sheet(wb, citiesWS, 'cities')

      XLSX.writeFile(wb, 'WLBimportOfficeTemplate.csv')
      setTimeout(() => {
        var link = document.createElement('a')
        link.setAttribute('download', 'Province_city_ref')
        link.href = REF_PROV_CITY
        document.body.appendChild(link)
        link.click()
        link.remove()
      }, 500)
    } else if (position === 'Job Level') {
      const data = {
        template: [
          {
            code: 'KD100',
            name: 'Supervisor',
            color_hex: '#42e9f5',
          },
        ],
      }

      const headers = {
        code: 'Job Level Code*',
        name: 'Job Level Name*',
        color_hex: 'Legend Color (Hex)*',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Templat_Impor_JenjangJabatan.csv')
    } else if (position === 'Regulations') {
      const data = {
        template: [
          {
            name: 'Kebijakan A',
            description: 'Deksripsi Kebijakan A',
          },
        ],
      }

      var templateWS = XLSX.utils.json_to_sheet(data.template)

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'WLBimportCompanyRegulationsTemplate.xlsx')
    } else if (position === 'Organization Unit') {
      const data = {
        template: [
          {
            level: 'OL01',
            code: 'OUTEST1',
            name: 'Organization Unite Test 1',
            parent: 'OUTEST1',
            // acting_as: '',
          },
          {
            level: 'OL01',
            code: 'OUTEST2',
            name: 'Organization Unite Test 2',
            parent: 'OUTEST1',
            // acting_as: '',
          },
        ],
        orgLevel: orgLvl,
      }

      const headers = {
        level: 'Organization Level Code*',
        code: 'Organization Unit Code*',
        name: 'Organization Unit Name*',
        parent: 'Parent*',
        // acting_as: 'Acting As',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      // var templateWS = XLSX.utils.json_to_sheet(data.template)
      var orgLevelWS = XLSX.utils.json_to_sheet(data.orgLevel)

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      // XLSX.utils.book_append_sheet(wb, orgLevelWS, 'organization levels')

      // XLSX.writeFile(wb, 'Import_OrganizationUnit_Template.xlsx')
      XLSX.writeFile(wb, 'Templat_Impor_UnitOrganisasi.csv')
    } else if (position === 'Position') {
      const data = {
        template: [
          {
            positionID: 'FE0001',
            positionName: 'Frontend Developer',
            organizationUnit: 'TEST004',
            parent: '',
            designation: 'DDX',
            level: 'KD999',
            grade: 'JGTT102',
            officeAddress: 'HMD0001',
            slotPerson: 1,
            // access_right: '0000001000',
            job_description: 'Sample of job description',
            job_responsibility: 'Sample of job responsibility',
            salary_rate_from: '5000000',
            salary_rate_to: '10000000',
            // requireSuccessor: 'Yes',
            actingAs: 'FE0001',
          },
          {
            positionID: 'FE0002',
            positionName: 'Frontend Developer',
            organizationUnit: 'TEST004',
            parent: 'FE0002',
            designation: 'DDX',
            level: 'KD999',
            grade: 'JGTT102',
            officeAddress: 'HMD0001',
            slotPerson: 1,
            // access_right: '0000001000',
            job_description: 'Sample of job description',
            job_responsibility: 'Sample of job responsibility',
            salary_rate_from: '5000000',
            salary_rate_to: '10000000',
            // requireSuccessor: 'No',
            actingAs: 'FE0002',
          },
        ],
        organizationUnit: org,
        officeAddress: off,
        level: lvl,
        jobGrades: jobGrades,
        designation: dsg,
        access_right: [
          {
            organizationAdministrator: '0',
            organizationDepartmentHead: '0',
            organizationFinanceAdmin: '0',
            organizationHRAdmin: '0',
            organizationHRAssistant: '0',
            organizationPayrollMaster: '0',
            organizationStaff: '0',
            organizationSupervisor: '0',
            holdingAdministrator: '1',
            organizationBranchAdministrator: '0',
          },
        ],
      }

      const headers = {
        positionID: 'Position Code*',
        positionName: 'Position Name*',
        organizationUnit: 'Organization Unit Code*',
        parent: 'Parent Code*',
        designation: 'Job Title Code*',
        level: 'Job Level Code*',
        grade: 'Job Grade Code',
        officeAddress: 'Office Code*',
        slotPerson: 'Slot Person*',
        // access_right: 'Access Right*',
        job_description: 'Job Description',
        job_responsibility: 'Job Responsibility',
        salary_rate_from: 'Salary Rate from',
        salary_rate_to: 'Salary Rate to',
        // requireSuccessor: 'Require Successor*',
        actingAs: 'Acting As',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var orgUnitsWS = XLSX.utils.json_to_sheet(data.organizationUnit)
      var officeAddressWS = XLSX.utils.json_to_sheet(data.officeAddress)
      var levelsWS = XLSX.utils.json_to_sheet(data.level)
      var gradesWS = XLSX.utils.json_to_sheet(data.jobGrades)
      var designationsWS = XLSX.utils.json_to_sheet(data.designation)
      var accessRightWS = XLSX.utils.json_to_sheet(data.access_right)
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      /* XLSX.utils.book_append_sheet(wb, orgUnitsWS, 'organization units')
      XLSX.utils.book_append_sheet(wb, officeAddressWS, 'office address')
      XLSX.utils.book_append_sheet(wb, levelsWS, 'job levels')
      XLSX.utils.book_append_sheet(wb, gradesWS, 'job grades')
      XLSX.utils.book_append_sheet(wb, designationsWS, 'job titles')
      XLSX.utils.book_append_sheet(wb, accessRightWS, 'access rights') */

      // XLSX.writeFile(wb, 'Import_Position_Template.xlsx')
      XLSX.writeFile(wb, 'Templat_Impor_Posisi.csv')
    } else if (position === 'Employees') {
      const data = {
        template: [
          {
            employeeID: 'TEST001',
            name: 'Name 1',
            email: 'name1@email.com',
            password: 'Test123!',
            gender: 'M',
            birthdate: '1995-01-01',
            religion: 1,
            country_code_phone: '+62',
            phone_number: '89876789',
            address: 'test address',
            province: 2,
            city: 3,
            postal_code: '3456',
            position_code: 'JP002',
            employee_type: 'contract',
            time_arrangement: 'fulltime',
            working_arrangement: 'onsite',
            salary: 10000000,
            join_date: '2021-01-24',
            start_date: '2021-01-25',
            end_date: '2021-12-25',
            decree: 'SK.CB 0027',
          },
        ],
        provinces: pro,
        cities: cit,
        religions: rel,
        jobProfiles: joPro,
      }

      const headers = {
        employeeID: 'Employee ID*',
        name: 'Name*',
        email: 'Email*',
        password: 'Password*',
        gender: 'Gender*',
        birthdate: 'Birth Date',
        religion: 'Religion*',
        country_code_phone: 'Country Code Phone*',
        phone_number: 'Phone Number*',
        address: 'Address*',
        province: 'Province*',
        city: 'City*',
        postal_code: 'Postal Code*',
        position_code: 'Position Code*',
        employee_type: 'Employee Type*',
        time_arrangement: 'Time Arrangement*',
        working_arrangement: 'Working Arrangement*',
        salary: 'Salary*',
        join_date: 'Join Date*',
        start_date: 'Start Date*',
        end_date: 'End Date*',
        decree: 'Decree*',
      }

      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })

      var provincesWS = XLSX.utils.json_to_sheet(data.provinces)
      var citiesWS = XLSX.utils.json_to_sheet(data.cities)
      var religionsWS = XLSX.utils.json_to_sheet(data.religions)
      var jobProfilesWS = XLSX.utils.json_to_sheet(data.jobProfiles)

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      /* XLSX.utils.book_append_sheet(wb, provincesWS, 'provinces')
      XLSX.utils.book_append_sheet(wb, citiesWS, 'cities')
      XLSX.utils.book_append_sheet(wb, religionsWS, 'religions')
      XLSX.utils.book_append_sheet(wb, jobProfilesWS, 'job profiles')
 */
      // XLSX.writeFile(wb, 'WLBimportEmployeeTemplate.xlsx')
      XLSX.writeFile(wb, 'WLBimportEmployeeTemplate.csv')
    } else if (position === 'Designation') {
      const data = {
        template: [
          {
            code: 'JT001',
            function: 'JFF02',
            name: 'Development Designation',
            status: 'Active',
            description: 'description',
          },
          {
            code: 'JT002',
            function: 'JFF02',
            name: 'Research Designation',
            status: 'Inactive',
            description: 'description',
          },
        ],
        // jobFamily: jobTitleTempSheet,
      }

      const headers = {
        code: 'Job Title Code*',
        function: 'Job Function*',
        name: 'Job Title Name*',
        status: 'Status*',
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      // var jobFamWS = XLSX.utils.json_to_sheet(data.jobFamily)

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      // XLSX.utils.book_append_sheet(wb, jobFamWS, 'job families')

      XLSX.writeFile(wb, 'Templat_Impor_Jabatan.csv')
    } else if (position === 'Organization Level') {
      const data = {
        template: [
          {
            code: 'ORGLVLCODE1',
            name: 'Org Level Code 1',
            status: 'Active',
            description: 'description',
            color_hex: '#42f557',
          },
          {
            code: 'ORGLVLCODE2',
            name: 'Org Level Code 2',
            status: 'Inactive',
            description: 'description',
            color_hex: '#42f557',
          },
        ],
      }

      const headers = {
        code: 'Organization Level Code*',
        name: 'Organization Level Name*',
        status: 'Status*',
        description: 'Description',
        color_hex: 'Legend Color (Hex)*',
      }

      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Templat_Impor_LevelOrganisasi.csv')
    } else if (position === 'Job Grade') {
      const data = {
        template: [
          {
            code: 'JG101',
            name: 'Job Grade 101',
            description: 'Description of Job Grade 101',
          },
          {
            code: 'JG102',
            name: 'Job Grade 102',
            description: 'Description of Job Grade 102',
          },
        ],
      }

      const headers = {
        code: 'Job Grade Code*',
        name: 'Job Grade Name*',
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Templat_Impor_KelasJabatan.csv')
    } else if (position === 'Job Family') {
      const data = {
        template: [
          {
            code: 'JFN101',
            name: 'Job Family Name 101',
            description: 'Description of Job Family 101',
          },
          {
            code: 'JFN102',
            name: 'Job Family Name 102',
            description: 'Description of Job Family 102',
          },
        ],
      }

      const headers = {
        code: 'Job Family Code*',
        name: 'Job Family Name*',
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Templat_Impor_RumpunJabatan.csv')
    } else if (position === 'Job Function') {
      const data = {
        template: [
          {
            code: 'JFF101',
            name: 'Job Function 101',
            description: 'Description of Function 101',
            // family: 'JF02',
          },
          {
            code: 'JFF102',
            name: 'Job Function 102',
            description: 'Description of Function 102',
            // family: 'JF02',
          },
        ],
        jobFamily: jobFamName,
      }

      const headers = {
        code: 'Job Function Code*',
        name: 'Job Function Name*',
        /* family: 'JF Name Code*',
        name: 'JF Function Name Code*',
        family: 'JF Name Code*', */
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var jobFamWS = XLSX.utils.json_to_sheet(data.jobFamily)

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      // XLSX.utils.book_append_sheet(wb, jobFamWS, 'job families')

      XLSX.writeFile(wb, 'Templat_Impor_JenisJabatan.csv')
    } else if (position === 'Competency') {
      const data = {
        template: [
          {
            code: 'COM1',
            name: 'Kepemimpinan',
            categoryCode: 'CK001',
            type: 'level',
            desc: 'Deskripsi Kepemimpinan',
            descLevel1: 'Deskripsi Kepemimpinan Level 1',
            descLevel2: 'Deskripsi Kepemimpinan Level 2',
            descLevel3: 'Deskripsi Kepemimpinan Level 3',
            descLevel4: 'Deskripsi Kepemimpinan Level 4',
            descLevel5: 'Deskripsi Kepemimpinan Level 5',
          },
        ],
      }

      const headers = {
        code: 'Competency Code*',
        name: 'Competency Name*',
        categoryCode: 'Category Competency Code*',
        type: 'Competency Type*',
        desc: 'Description',
        descLevel1: 'Description Level 1',
        descLevel2: 'Description Level 2',
        descLevel3: 'Description Level 3',
        descLevel4: 'Description Level 4',
        descLevel5: 'Description Level 5',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Import_Competency_Dictionaries_Template.csv')
    } else if (position === 'Bank & Salary') {
      const data = {
        template: [
          {
            employee_id: 'EM001',
            PTKP: 'TK/0',
            KTP: '1234567890',
            NPWP: '1234567890',
            base_salary: '5000000',
            bank_id: '2',
            bank_code: '14',
            bank_name: 'BANK BCA',
            bank_branch: '',
            bank_account_number: '1234567890',
            bank_account_holder_name: 'Employee 1',
            start_date: '2022-01-01',
            end_date: '2022-12-31',
          },
        ],
        orgLevel: orgLvl,
      }
      const headers = {
        employee_id: 'Employee ID*',
        PTKP: 'PTKP*',
        KTP: 'KTP*',
        NPWP: 'NPWP*',
        base_salary: 'Base Salary*',
        bank_id: 'Bank ID*',
        bank_code: 'Bank Code*',
        bank_name: 'Bank Name*',
        bank_branch: 'Bank Branch',
        bank_account_number: 'Bank Account Number*',
        bank_account_holder_name: 'Bank Account Holder*',
        start_date: 'Start Date*',
        end_date: 'End Date*',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      // var templateWS = XLSX.utils.json_to_sheet(data.template)
      var orgLevelWS = XLSX.utils.json_to_sheet(data.orgLevel)

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      // XLSX.utils.book_append_sheet(wb, orgLevelWS, 'organization levels')

      // XLSX.writeFile(wb, 'Import_OrganizationUnit_Template.xlsx')
      XLSX.writeFile(wb, 'Import_Employee_Salary.csv')
    } else if (position === 'Component') {
      const data = {
        template: [
          {
            employee_regno: 'EM001',
            component_code: 'CC001',
            value: '200000',
            // family: 'JF02',
          },
        ],
        jobFamily: jobFamName,
      }

      const headers = {
        employee_regno: 'employee_regno',
        component_code: 'component_code',
        value: 'value',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var jobFamWS = XLSX.utils.json_to_sheet(data.jobFamily)

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      // XLSX.utils.book_append_sheet(wb, jobFamWS, 'job families')

      XLSX.writeFile(wb, 'Import Component_step_2_Template.csv')
    } else if (position === 'Component Data') {
      const data = {
        template: [
          {
            code: 'CC001',
            component_name: 'Tunjangan 1',
            component_type: 'earning / deduction',
            include_in_thr: 'yes / no',
            include_in_bpjs: 'yes / no',
            taxable: 'yes / no',
            tax_paid_by: 'employee / company',
            component_frequency: 'monthly / custom',
            calculation_base: 'base / thp / other',
            component_value: '50000',
            component_multiplier: '1',
            effective_date: '2021-08-21',
          },
        ],
      }

      const headers = {
        code: 'code',
        component_name: 'component_name',
        component_type: 'component_type',
        include_in_thr: 'include_in_thr',
        include_in_bpjs: 'include_in_bpjs',
        taxable: 'taxable',
        tax_paid_by: 'tax_paid_by',
        component_frequency: 'component_frequency',
        calculation_base: 'calculation_base',
        component_value: 'component_value',
        component_multiplier: 'component_multiplier',
        effective_date: 'effective_date',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      // XLSX.utils.book_append_sheet(wb, jobFamWS, 'job families')

      XLSX.writeFile(wb, 'import_Payroll_component_data_Template.csv')
    } else if (position === 'Multiplier') {
      const data = {
        template: [
          {
            employee_regno: 'EM001',
            component_code: 'CC001',
            multiplier: '0.1',
          },
        ],
        jobFamily: jobFamName,
      }

      const headers = {
        employee_regno: 'employee_regno',
        component_code: 'component_code',
        multiplier: 'multiplier',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      // XLSX.utils.book_append_sheet(wb, jobFamWS, 'job families')

      XLSX.writeFile(wb, 'Import Multiplier_step_2_Template.csv')
    } else if (position === 'Assignment Component') {
      const data = {
        template: [
          {
            employee_id: 'EM001',
            component_code: 'CC001',
          },
        ],
        jobFamily: jobFamName,
      }

      const headers = {
        employee_id: 'Employee ID*',
        component_code: 'Component Code*',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })

      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      // XLSX.utils.book_append_sheet(wb, jobFamWS, 'job families')

      XLSX.writeFile(wb, 'Import Assignment Component_Template.csv')
    } else if (position === 'Goal Aspect') {
      const data = {
        template: [
          {
            code: 'GAC01',
            name: 'Goal Aspect Code 1',
            status: 'ACTIVE',
            description: 'description',
          },
          {
            code: 'GAC02',
            name: 'Goal Aspect Code 2',
            status: 'INACTIVE',
            description: 'description',
          },
        ],
      }

      const headers = {
        code: 'Goal Aspect Code*',
        name: 'Goal Aspect Name*',
        status: 'Status*',
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Import_GoalAspect_Template.csv')
    } else if (position === 'Goal Method') {
      const data = {
        template: [
          {
            code: 'GMC01',
            name: 'Goal Method Code 1',
            status: 'ACTIVE',
            description: 'description',
          },
          {
            code: 'GMC02',
            name: 'Goal Method Code 2',
            status: 'INACTIVE',
            description: 'description',
          },
        ],
      }

      const headers = {
        code: 'Goal Method Code*',
        name: 'Goal Method Name*',
        status: 'Status*',
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Import_GoalMethod_Template.csv')
    } else if (position === 'Goal Monitoring') {
      const data = {
        template: [
          {
            code: 'Monitoring01',
            name: 'Monitoring 1',
            status: 'ACTIVE',
            description: 'description',
          },
          {
            code: 'Monitoring02',
            name: 'Monitoring 2',
            status: 'INACTIVE',
            description: 'description',
          },
        ],
      }

      const headers = {
        code: 'Goal Monitoring Frequency Code*',
        name: 'Goal Monitoring Frequency Name*',
        status: 'Status*',
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Import_GoalMonitoringFrequency_Template.csv')
    } else if (position === 'Goal Perspective') {
      const data = {
        template: [
          {
            code: 'Perspective01',
            name: 'Perspective 1',
            status: 'ACTIVE',
            description: 'description',
          },
          {
            code: 'Perspective02',
            name: 'Perspective 2',
            status: 'INACTIVE',
            description: 'description',
          },
        ],
      }

      const headers = {
        code: 'Goal Perspective Code*',
        name: 'Goal Perspective Name*',
        status: 'Status*',
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Import_GoalPerspective_Template.csv')
    } else if (position === 'Goal Scoring') {
      const data = {
        template: [
          {
            code: 'Scoring Nature01',
            name: 'Lower is Better',
            scoring: 'Lower is Better',
            status: 'ACTIVE',
            description: 'description',
          },
          {
            code: 'Scoring Nature02',
            name: 'Higher is Better',
            scoring: 'Higher is Better',
            status: 'INACTIVE',
            description: 'description',
          },
        ],
      }

      const headers = {
        code: 'Goal Scoring Nature Code*',
        name: 'Goal Scoring Nature Name*',
        scoring: 'Scoring Nature',
        status: 'Status*',
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Import_GoalScoringNature_Template.csv')
    } else if (position === 'Goal Level') {
      const data = {
        template: [
          {
            code: 'Level01',
            name: 'Level 1',
            status: 'ACTIVE',
            description: 'description',
          },
          {
            code: 'Level02',
            name: 'Level 2',
            status: 'INACTIVE',
            description: 'description',
          },
        ],
      }

      const headers = {
        code: 'Goal Level Code*',
        name: 'Goal Level Name*',
        status: 'Status*',
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Import_GoalLevel_Template.csv')
    } else if (position === 'Goal Weight Unit') {
      const data = {
        template: [
          {
            code: 'Weight Unit01',
            name: 'Weight Unit 1',
            status: 'ACTIVE',
            description: 'description',
          },
          {
            code: 'Weight Unit02',
            name: 'Weight Unit 2',
            status: 'INACTIVE',
            description: 'description',
          },
        ],
      }

      const headers = {
        code: 'Goal Weight Unit Code*',
        name: 'Goal Weight Unit Name*',
        status: 'Status*',
        description: 'Description',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Import_GoalWeightUnit_Template.csv')
    } else if (position === 'Goal Library') {
      const data = {
        template: [
          {
            name: 'Goal 2021',
            code: 'Code001',
            level: 'GL01',
            prespective: 'GP001',
            formula: 'metric',
            scoring: 'GSN01',
            weight: 'GWU01',
            aspect: 'GS01',
            method: 'GM01',
            monitoring: 'GM01',
            description: 'Description',
            type: 'Qualitative',
          },
          {
            name: 'Goal 2022',
            code: 'Code002',
            level: 'GL02',
            prespective: 'GP002',
            formula: 'metric',
            scoring: 'GSN02',
            weight: 'GWU02',
            aspect: 'GS02',
            method: 'GM02',
            monitoring: 'GM02',
            description: 'Description',
            type: 'Quantitave',
          },
        ],
      }

      const headers = {
        name: 'Goal Library Name*',
        code: 'Goal Code*',
        level: 'Goal Level*',
        prespective: 'Goal Persepective*',
        formula: 'Metric*',
        scoring: 'Goal Scoring Nature*',
        weight: 'Goal Weight Unit*',
        aspect: 'Goal Aspect*',
        method: 'Goal Method*',
        monitoring: 'Goal Monitoring Frequency*',
        description: 'Description',
        type: 'Goal Type*',
      }
      data.template.unshift(headers)

      var templateWS = XLSX.utils.json_to_sheet(data.template, {
        skipHeader: true,
      })
      var wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')

      XLSX.writeFile(wb, 'Import_GoalLibrary_Template.csv')
    } else if (position === 'Attendance') {
      const template = [
        {
          employeeId: 'Employee ID*',
          employeeName: 'Employee Name*',
          checkInDate: 'Date Check In*',
          checkInTime: 'Check In Time*',
          checkOutDate: 'Date Check Out*',
          checkOutTime: 'Check Out Time*',
        },
        {
          employeeId: 'HID01238932822',
          employeeName: 'Dex Velma',
          checkInDate: '29-09-2022',
          checkInTime: '09:00',
          checkOutDate: '29-09-2022',
          checkOutTime: '14:56',
        },
        {
          employeeId: 'HID01238932824',
          employeeName: 'Orrell Neil',
          checkInDate: '29-09-2022',
          checkInTime: '10:00',
          checkOutDate: '30-09-2022',
          checkOutTime: '15:56',
        },
      ]

      const templateWS = XLSX.utils.json_to_sheet(template, { skipHeader: true })
      const wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, templateWS, 'template')
      XLSX.writeFile(wb, 'Import_Attendance_Template.csv')
    }
  }

  // kolom untuk mapping data excels
  const tableColumns = () => {
    if (position === 'Office') {
      return [
        {
          title: 'Row',
          field: 'row',
        },
        {
          title: 'Office ID',
          field: 'office_code',
        },
        {
          title: 'Office Name',
          field: 'office_name',
          cellStyle: {
            width: '20%',
          },
        },
        {
          title: 'Province',
          field: 'province',
        },
        {
          title: 'City/District',
          field: 'city',
        },
        {
          title: 'Postal Code',
          field: 'postal_code',
        },
        {
          title: 'Address',
          field: 'address',
          cellStyle: {
            width: '20%',
          },
        },
        {
          title: 'Status',
          field: 'status',
        },
      ]
    } else if (position === 'Job Level') {
      return [
        {
          title: 'Row',
          field: 'row',
        },
        {
          title: 'Code',
          field: 'code',
        },
        {
          title: 'Level Name',
          field: 'name',
          // cellStyle: {
          //   width: '20%',
          // },
        },
      ]
    } else if (position === 'Regulations') {
      return [
        {
          title: 'Name',
          field: 'name',
        },
        {
          title: 'Description',
          field: 'description',
          // cellStyle: {
          //   width: '20%',
          // },
        },
      ]
    } else if (position === 'Organization Unit') {
      return [
        {
          title: 'Row',
          field: 'row',
        },
        {
          title: 'Org Level*',
          field: 'level',
        },
        {
          title: 'Org Unit Code*',
          field: 'code',
        },
        {
          title: 'Org Name*',
          field: 'name',
        },
        {
          title: 'Parent*',
          field: 'parent',
        },
        {
          title: 'Acting As',
          field: 'acting_as',
        },
      ]
    } else if (position === 'Organization Level') {
      return [
        {
          title: 'Row',
          field: 'row',
        },
        {
          title: 'Org Level Code*',
          field: 'code',
        },
        {
          title: 'Org Level Name*',
          field: 'name',
        },
        {
          title: 'Status*',
          field: 'status',
        },
        {
          title: 'Description',
          field: 'description',
        },
        {
          title: 'Legend Color (Hex)*',
          field: 'color_hex',
        },
      ]
    } else if (position === 'Designation') {
      return [
        {
          title: 'Row',
          field: 'row',
        },
        {
          title: 'Job Title Code*',
          field: 'code',
        },
        {
          title: 'Job Family Code*',
          field: 'family',
        },
        {
          title: 'Job Title Name*',
          field: 'name',
        },
        {
          title: 'Status*',
          field: 'status',
        },
        {
          title: 'Description*',
          field: 'description',
        },
      ]
    } else if (position === 'Position') {
      return [
        {
          title: 'Row',
          field: 'row',
        },
        {
          title: 'Position Code*',
          field: 'positionID',
        },
        {
          title: 'Position Name*',
          field: 'positionName',
        },
        {
          title: 'Org Unit*',
          field: 'organizationUnit',
        },
        {
          title: 'Parent*',
          field: 'parent',
        },
        {
          title: 'Job Title*',
          field: 'designation',
        },
        {
          title: 'Job Level*',
          field: 'level',
        },
        {
          title: 'Job Grade*',
          field: 'grade',
        },
        {
          title: 'Office*',
          field: 'officeAddress',
        },
        {
          title: 'Slot Person*',
          field: 'slotPerson',
        },
        {
          title: 'Access Right*',
          field: 'access_right',
        },
        {
          title: 'Job Description',
          field: 'job_description',
        },
        {
          title: 'Job Responsibility',
          field: 'job_responsibility',
        },
        {
          title: 'Salary Rate from',
          field: 'salary_rate_from',
        },
        {
          title: 'Salary Rate to',
          field: 'salary_rate_to',
        },
        {
          title: 'Require Successor',
          field: 'requireSuccessor',
        },
        {
          title: 'Acting As',
          field: 'actingAs',
        },
      ]
    } else if (position === 'Employees') {
      return [
        {
          title: 'Row',
          field: 'row',
        },
        {
          title: 'Name',
          field: 'name',
        },
        {
          title: 'Gender',
          field: 'gender',
        },
        {
          title: 'Email',
          field: 'email',
        },
        {
          title: 'Country Code Phone',
          field: 'country_code_phone',
        },
        {
          title: 'Phone Number',
          field: 'phone_number',
        },
        {
          title: 'Password',
          field: 'password',
        },
        {
          title: 'Religion',
          field: 'religion',
        },
        {
          title: 'Address',
          field: 'address',
        },
        {
          title: 'Province',
          field: 'province',
        },
        {
          title: 'City',
          field: 'city',
        },
        {
          title: 'Postal Code',
          field: 'postal_code',
        },
        {
          title: 'Regno',
          field: 'regno',
        },
        {
          title: 'Job Profile',
          field: 'job_profile',
        },
        {
          title: 'Role',
          field: 'role',
        },
        {
          title: 'Supervisor',
          field: 'supervisor',
        },
        {
          title: 'Startdate',
          field: 'startdate',
        },
        {
          title: 'Enddate',
          field: 'enddate',
        },
        {
          title: 'Salary',
          field: 'salary',
        },
        {
          title: 'Legal',
          field: 'legal',
        },
        {
          title: 'Time',
          field: 'time',
        },
        {
          title: 'Place',
          field: 'place',
        },
        {
          title: 'Replacement',
          field: 'replacement',
        },
      ]
    } else if (position === 'Job Grade') {
      return [
        {
          title: 'Row',
          field: 'row',
        },
        {
          title: 'Job Grade Code*',
          field: 'code',
        },
        {
          title: 'Job Grade Name*',
          field: 'name',
        },
        {
          title: 'Description',
          field: 'description',
        },
      ]
    } else if (position === 'Job Family') {
      return [
        {
          title: 'Row',
          field: 'row',
        },
        {
          title: 'JF Name Code*',
          field: 'code',
        },
        {
          title: 'JF Name*',
          field: 'name',
        },
        {
          title: 'Description',
          field: 'description',
        },
      ]
    } else if (position === 'Job Family Function') {
      return [
        {
          title: 'Row',
          field: 'row',
        },
        {
          title: 'JF Function Code*',
          field: 'code',
        },
        {
          title: 'JF Function Name Code*',
          field: 'name',
        },
        {
          title: 'JF Name Code*',
          field: 'family',
        },
        {
          title: 'Description',
          field: 'description',
        },
      ]
    }
  }

  // filter isi table by search text
  const searchHandler = data => {
    if (position === 'Office') {
      return data.filter(
        off =>
          (off.office_code &&
            off.office_code.toLowerCase().includes(search.toLowerCase())) ||
          (off.office_name &&
            off.office_name.toLowerCase().includes(search.toLowerCase())) ||
          (off.postal_code &&
            String(off.postal_code)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (off.province &&
            String(off.province)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (off.city &&
            String(off.city)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (off.status &&
            off.status.toLowerCase().includes(search.toLowerCase()))
      )
    } else if (position === 'Level') {
      return data.filter(level => level.name.includes(search))
    } else if (position === 'Regulations') {
      return data.filter(regulation => regulation.name.includes(search))
    } else if (position === 'Organization Unit') {
      return data.filter(
        de =>
          (de.level && de.level.toLowerCase().includes(search.toLowerCase())) ||
          (de.code && de.code.toLowerCase().includes(search.toLowerCase())) ||
          (de.name && de.name.toLowerCase().includes(search.toLowerCase())) ||
          (de.parent &&
            de.parent.toLowerCase().includes(search.toLowerCase())) ||
          (de.acting_as &&
            de.acting_as.toLowerCase().includes(search.toLowerCase()))
      )
    } else if (position === 'Designation') {
      return data.filter(
        de =>
          (de.name && de.name.toLowerCase().includes(search.toLowerCase())) ||
          (de.code && de.code.toLowerCase().includes(search.toLowerCase())) ||
          (de.description &&
            de.description.toLowerCase().includes(search.toLowerCase())) ||
          (de.family &&
            de.family.toLowerCase().includes(search.toLowerCase())) ||
          (de.status && de.status.toLowerCase().includes(search.toLowerCase()))
      )
    } else if (position === 'Organization Level') {
      return data.filter(
        de =>
          (de.name && de.name.toLowerCase().includes(search.toLowerCase())) ||
          (de.code && de.code.toLowerCase().includes(search.toLowerCase())) ||
          (de.description &&
            de.description.toLowerCase().includes(search.toLowerCase())) ||
          (de.status && de.status.toLowerCase().includes(search.toLowerCase()))
      )
    } else if (position === 'Job Grade') {
      return data.filter(
        de =>
          (de.name && de.name.toLowerCase().includes(search.toLowerCase())) ||
          (de.code && de.code.toLowerCase().includes(search.toLowerCase())) ||
          (de.description &&
            de.description.toLowerCase().includes(search.toLowerCase()))
      )
    } else if (position === 'Job Level') {
      return data.filter(
        de =>
          (de.name && de.name.toLowerCase().includes(search.toLowerCase())) ||
          (de.code && de.code.toLowerCase().includes(search.toLowerCase()))
      )
    } else if (position === 'Job Family') {
      return data.filter(
        jfn =>
          (jfn.name && jfn.name.toLowerCase().includes(search.toLowerCase())) ||
          (jfn.code && jfn.code.toLowerCase().includes(search.toLowerCase())) ||
          (jfn.description &&
            jfn.description.toLowerCase().includes(search.toLowerCase()))
      )
    } else if (position === 'Job Family Function') {
      return data.filter(
        jff =>
          (jff.name && jff.name.toLowerCase().includes(search.toLowerCase())) ||
          (jff.code && jff.code.toLowerCase().includes(search.toLowerCase())) ||
          (jff.family &&
            String(jff.family)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (jff.description &&
            jff.description.toLowerCase().includes(search.toLowerCase()))
      )
    } else if (position === 'Position') {
      return data.filter(
        pos =>
          (pos.positionID &&
            pos.positionID.toLowerCase().includes(search.toLowerCase())) ||
          (pos.positionName &&
            pos.positionName.toLowerCase().includes(search.toLowerCase())) ||
          (pos.organizationUnit &&
            pos.organizationUnit
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (pos.parent &&
            pos.parent.toLowerCase().includes(search.toLowerCase())) ||
          (pos.designation &&
            pos.designation.toLowerCase().includes(search.toLowerCase())) ||
          (pos.level &&
            pos.level.toLowerCase().includes(search.toLowerCase())) ||
          (pos.grade &&
            pos.grade.toLowerCase().includes(search.toLowerCase())) ||
          (pos.officeAddress &&
            pos.officeAddress.toLowerCase().includes(search.toLowerCase())) ||
          (pos.slotPerson &&
            String(pos.slotPerson)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (pos.access_right &&
            pos.access_right.toLowerCase().includes(search.toLowerCase())) ||
          (pos.job_description &&
            pos.job_description.toLowerCase().includes(search.toLowerCase())) ||
          (pos.job_responsibility &&
            pos.job_responsibility
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (pos.salary_rate_from &&
            pos.salary_rate_from
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (pos.salary_rate_to &&
            pos.salary_rate_to.toLowerCase().includes(search.toLowerCase())) ||
          (pos.requireSuccessor &&
            pos.requireSuccessor
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (pos.actingAs &&
            pos.actingAs.toLowerCase().includes(search.toLowerCase()))
      )
    } else if (position === 'Employees') {
      return data.filter(
        emp =>
          (emp.name && emp.name.toLowerCase().includes(search.toLowerCase())) ||
          (emp.gender &&
            emp.gender.toLowerCase().includes(search.toLowerCase())) ||
          (emp.email &&
            emp.email.toLowerCase().includes(search.toLowerCase())) ||
          (emp.country_code_phone &&
            emp.country_code_phone
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (emp.phone_number &&
            String(emp.phone_number)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (emp.password &&
            emp.password.toLowerCase().includes(search.toLowerCase())) ||
          (emp.religion &&
            String(emp.religion)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (emp.address &&
            emp.address.toLowerCase().includes(search.toLowerCase())) ||
          (emp.province &&
            String(emp.province)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (emp.city &&
            String(emp.city)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (emp.postal_code &&
            emp.postal_code.toLowerCase().includes(search.toLowerCase())) ||
          (emp.regno &&
            emp.regno.toLowerCase().includes(search.toLowerCase())) ||
          (emp.job_profile &&
            String(emp.job_profile)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (emp.role && emp.role.toLowerCase().includes(search.toLowerCase())) ||
          (emp.supervisor &&
            emp.supervisor.toLowerCase().includes(search.toLowerCase())) ||
          (emp.startdate &&
            emp.startdate.toLowerCase().includes(search.toLowerCase())) ||
          (emp.enddate &&
            emp.enddate.toLowerCase().includes(search.toLowerCase())) ||
          (emp.salary &&
            String(emp.salary)
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (emp.legal &&
            emp.legal.toLowerCase().includes(search.toLowerCase())) ||
          (emp.time && emp.time.toLowerCase().includes(search.toLowerCase())) ||
          (emp.place &&
            emp.place.toLowerCase().includes(search.toLowerCase())) ||
          (emp.replacement &&
            emp.replacement.toLowerCase().includes(search.toLowerCase()))
      )
    }
  }

  const handleDeleteMultipleFiles = i => {
    const newState = [...file.multipleFiles]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setFile(e => ({
      ...e,
      multipleFiles: newState,
    }))
  }

  const isDisableImportButton = isMultipleTemplate => {
    if (isMultipleTemplate) {
      return (
        (file.multipleFiles && file.multipleFiles.length === 0) ||
        validationsMultiple.length > 0
      )
    } else {
      return (
        validations.length > 0 ||
        rowsCantImported.length > 0 ||
        isRead ||
        !props.fileURL
      )
    }
  }

  const resetData = () => {
    if (templates.isMultiple) {
      setFile(e => ({ ...e, multipleFiles: [] }))
      setValidationsMultiple([])
    }
  }

  const translatePosition = namePosition => {
    const { Goal, 'Weight Unit': Weight } = GLOBAL_GLOSSARY.performance

    switch (namePosition) {
      case 'Organization Level':
        return 'Level Organisasi'
      case 'Organization Unit':
        return 'Unit Organisasi'
      case 'Job Level':
        return 'Jenjang Jabatan'
      case 'Job Grade':
        return 'Kelas Jabatan'
      case 'Job Function':
        return 'Jenis Jabatan'
      case 'Designation':
        return 'Jabatan'
      case 'Job Family':
        return 'Rumpun Jabatan'
      case 'Position':
        return 'Posisi'

      case 'Competency':
        return 'Kompetensi'

      case 'Goal Aspect':
        return `Aspek ${Goal}`
      case 'Goal Level':
        return `Level ${Goal}`
      case 'Goal Library':
        return `Kumpulan ${Goal}`
      case 'Goal Method':
        return `Metode ${Goal}`
      case 'Goal Monitoring':
        return `Frekuensi pemantauan ${Goal}`
      case 'Goal Perspective':
        return `Perspektif ${Goal}`
      case 'Goal Scoring':
        return `Penilaian ${Goal}`
      case 'Goal Weight Unit':
        return `${Weight} ${Goal}`

      default:
        return namePosition
    }
  }

  return (
    <Dialog
      onClose={() => {
        setCheck(false)
        setDis(true)
        setIsRead(false)
        handleClose()
        resetData()
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      {!confirmToImport && !isAlreadyImported ? (
        <PrimaryModalHeader>
          <Typography variant="h6" style={{ color: '#fff' }}>
            Impor {translatePosition(position)}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setCheck(false)
              setDis(true)
              setIsRead(false)
              handleClose()
              resetData()
            }}
          >
            <Icon style={{ color: '#fff' }}>close</Icon>
          </IconButton>
        </PrimaryModalHeader>
      ) : (
        <WhiteModalHeader>
          <Typography variant="h6" style={{ color: '#014a62' }}>
            Impor {isAlreadyImported ? 'Success' : 'File'}
          </Typography>
        </WhiteModalHeader>
      )}

      {!confirmToImport && !isAlreadyImported ? (
        <>
          <DialogContent className={classes.content}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Typography variant="h6" display="inline">
                  Pilih Berkas
                </Typography>
                <div className="container">
                  {check ? (
                    <div
                      style={{
                        backgroundColor: '#f7f8f9',
                        justifyContent: 'space-between',
                        display: 'flex',
                        minHeight: '70px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          // lineHeight: '4pt' ,
                        }}
                      >
                        <div style={{ flex: '0.3' }}>
                          {file.file && (
                            <FontAwesomeIcon
                              icon={
                                String(file.file.name)
                                  .split('.')
                                  .pop() === 'xls' ||
                                  String(file.file.name)
                                    .split('.')
                                    .pop() === 'xlsx' ||
                                  String(file.file.name)
                                    .split('.')
                                    .pop() === 'csv'
                                  ? faFileExcel
                                  : String(file.file.name)
                                    .split('.')
                                    .pop() === 'docx'
                                    ? faFileWord
                                    : String(file.file.name)
                                      .split('.')
                                      .pop() === 'pdf'
                                      ? faFilePdf
                                      : String(file.file.name)
                                        .split('.')
                                        .pop() === 'pptx'
                                        ? faFilePowerpoint
                                        : faFile
                              }
                              style={{
                                color:
                                  String(file.file.name)
                                    .split('.')
                                    .pop() === 'xls' ||
                                    String(file.file.name)
                                      .split('.')
                                      .pop() === 'xlsx' ||
                                    String(file.file.name)
                                      .split('.')
                                      .pop() === 'csv'
                                    ? 'green'
                                    : String(file.file.name)
                                      .split('.')
                                      .pop() === 'docx'
                                      ? '#039be5'
                                      : String(file.file.name)
                                        .split('.')
                                        .pop() === 'pdf' ||
                                        String(file.file.name)
                                          .split('.')
                                          .pop() === 'pptx'
                                        ? 'red'
                                        : 'grey',
                                flexDirection: 'column',
                                cursor: 'pointer',
                                fontSize: '35px',
                                margin: '20px',
                              }}
                              onClick={props.onClose}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: '10px',
                          }}
                        >
                          {file.file && (
                            <>
                              <p style={{ color: '#014a62', margin: '0px' }}>
                                {file.file.name}
                              </p>
                              <p style={{ color: '#a9a8a8', margin: '0px' }}>
                                {file.file.size > 1000000
                                  ? (file.file.size / 1000000).toFixed(1) +
                                  ' MB'
                                  : file.file.size
                                    ? `${file.file.size} Bytes`
                                    : `Read File...`}
                              </p>
                            </>
                          )}
                        </div>
                      </div>

                      <IconButton aria-label="close" onClick={closeCheck}>
                        <Icon style={{ color: '#a9a8a8' }}>close</Icon>
                      </IconButton>
                    </div>
                  ) : (
                    <Container
                      {...getRootProps({
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                      })}
                    >
                      {isRead ? (
                        <div className="container">
                          <div
                            style={{
                              backgroundColor: '#f7f8f9',
                              justifyContent: 'space-between',
                              display: 'flex',
                              minHeight: '70px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                // lineHeight: '4pt' ,
                              }}
                            >
                              <div style={{ flex: '0.3' }}>
                                <FontAwesomeIcon
                                  icon={faFileExcel}
                                  style={{
                                    color: 'green',
                                    flexDirection: 'column',
                                    cursor: 'pointer',
                                    fontSize: '35px',
                                    margin: '20px',
                                  }}
                                  onClick={props.onClose}
                                />
                              </div>
                              <div
                                style={{
                                  marginTop: '25px',
                                }}
                              >
                                <p
                                  style={{
                                    color: '#014a62',
                                    margin: '0px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Uploading...
                                </p>
                                <LinearProgress />
                                <p style={{ visibility: 'hidden', height: '0' }}>
                                  1dc6d763-8ad8-4e8a-8d26-5bb73dd569f2-1602486963025
                                  (1).xlsx
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <input
                            type="file"
                            {...getInputProps()}
                            onChange={handleChange}
                            id="file"
                            accept={SheetJSFT}
                          />
                          <p>
                            Tarik dan letakkan berkas di sini atau{' '}
                            <a style={{ color: '#039be5', cursor: 'pointer' }}>
                              jelajah file anda
                            </a>
                          </p>
                        </>
                      )}
                    </Container>
                  )}
                  <WrapperScroll
                    style={{ marginTop: 14, overflowY: 'auto' }}
                    maxHeight="435px"
                  >
                    {templates &&
                      templates.isMultiple &&
                      file.multipleFiles &&
                      file.multipleFiles.map(
                        ({ name, size, url, isUploaded, loaded }, i) => {
                          return (
                            <MultipleFile
                              key={`${name}-${size}`}
                              name={name}
                              isUploaded={isUploaded}
                              size={size}
                              url={url}
                              loaded={loaded}
                              onDelete={() => handleDeleteMultipleFiles(i)}
                            />
                          )
                        }
                      )}
                  </WrapperScroll>
                </div>
                {/* Validations things */}
                {!templates.isMultiple && validations.length > 0 && (
                  <>
                    <Divider style={{ marginTop: '25px' }} />
                    <div style={{ marginTop: '10px' }}>
                      <Typography variant="p" style={{ color: '#ef4d5e' }}>
                        {`${validations.length} ${validations.length > 1 ? 'rows' : 'row'
                          } cannot be imported`}
                      </Typography>
                      <br />
                      <br />
                      <Typography variant="p">
                        Please check the following problem:
                      </Typography>
                      <div>
                        {validations.map(val => {
                          return (
                            <>
                              <br />
                              <br />
                              <Typography
                                variant="p"
                                display="inline"
                                style={{ color: '#ef4d5e' }}
                              >
                                {val.name}
                              </Typography>
                              <br />
                              <Typography
                                variant="p"
                                display="inline"
                                style={{ color: '#a9a8a8' }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: val.desc,
                                  }}
                                ></div>
                              </Typography>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </>
                )}
                {templates.isMultiple && validationsMultiple.length > 0 && (
                  <>
                    <Divider style={{ marginTop: '25px' }} />
                    <div style={{ marginTop: '10px' }}>
                      <Typography variant="p" style={{ color: '#ef4d5e' }}>
                        {`${validationsMultiple.length} ${validationsMultiple.length > 1 ? 'rows' : 'row'
                          } cannot be imported`}
                      </Typography>
                      <br />
                      <br />
                      <Typography variant="p">
                        Please check the following problem:
                      </Typography>
                      <div>
                        {validationsMultiple.map(val => {
                          return (
                            <>
                              <br />
                              <br />
                              <Typography
                                variant="p"
                                display="inline"
                                style={{ color: '#ef4d5e' }}
                              >
                                {val.name}
                              </Typography>
                              <br />
                              <Typography
                                variant="p"
                                display="inline"
                                style={{ color: '#a9a8a8' }}
                              >
                                {val.desc}
                              </Typography>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </>
                )}
                <Divider style={{ marginTop: '10px' }} />

                <div style={{ marginTop: '15px' }}>
                  {templates && templates.isMultiple ? (
                    templates.isShowListTemplate && (
                      <div>
                        <Typography
                          style={{ color: '#bdbdbd', margin: '0 0 8px' }}
                        >
                          Download templates
                        </Typography>
                        <WrapperScroll maxHeight="126px">
                          {templates.listTemplates.map(({ templateName }) => {
                            return (
                              <p
                                key={templateName}
                                onClick={() =>
                                  templates.onClickTemplates(
                                    templateName,
                                    MASTER_DATA
                                  )
                                }
                                style={{
                                  color: '#039be5',
                                  cursor: 'pointer',
                                  margin: '0 0 8px',
                                  fontSize: 14,
                                }}
                              >
                                {templateName}
                              </p>
                            )
                          })}
                        </WrapperScroll>
                        <Divider style={{ marginTop: '10px' }} />
                      </div>
                    )
                  ) : (
                    <Typography
                      variant="p"
                      display="inline"
                      style={{ color: '#bdbdbd' }}
                    >
                      Unduh berkas templat{' '}
                      <span
                        onClick={() => generateTemplate()}
                        style={{ color: '#039be5', cursor: 'pointer' }}
                      >
                        disini
                      </span>
                    </Typography>
                  )}
                  <br />
                  {fileLinkTemplate && (
                    <>
                      <Typography
                        variant="p"
                        display="inline"
                        style={{ color: '#bdbdbd' }}
                      >
                        {`Download Bank List Data template (do not upload this template) `}
                        <span
                          onClick={() =>
                            window.open(fileLinkTemplate, '_blank')
                          }
                          style={{ color: '#039be5', cursor: 'pointer' }}
                        >
                          here
                        </span>
                      </Typography>
                      <br />
                    </>
                  )}
                  {onDownloadMaster && (
                    <>
                      <Typography
                        variant="p"
                        display="inline"
                        style={{ color: '#bdbdbd' }}
                      >
                        {
                          'Download master data template (do not upload this template) '
                        }
                        <span
                          onClick={() =>
                            onDownloadMaster({
                              provinces: pro,
                              cities: cit,
                              religions: rel,
                            })
                          }
                          style={{ color: '#039be5', cursor: 'pointer' }}
                        >
                          here
                        </span>
                      </Typography>
                      <br />
                    </>
                  )}
                  <Typography
                    variant="p"
                    display="inline"
                    style={{ color: '#bdbdbd' }}
                  >
                    Tipe berkas yang diterima adalah .csv{' '}
                    {position === 'Employees' && ` (MS DOS)`}
                  </Typography>
                  <br />
                  <Typography
                    variant="p"
                    display="inline"
                    style={{ color: '#bdbdbd' }}
                  >
                    {/* Max file size is 5 MB {JSON.stringify(listOfficeCodesValidations)} */}
                    Ukuran maks berkas 5 MB
                  </Typography>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions className={classes.actions}>
            {(!templates.isMultiple && validations.length === 0) ||
              (templates.isMultiple && validationsMultiple.length === 0) ? (
              <>
                <Button
                  onClick={() => {
                    closeCheck()
                    handleClose()
                    resetData()
                  }}
                  disabled={isRead}
                >
                  Batalkan
                </Button>

                <Button
                  className={classes.btnImport}
                  variant="contained"
                  // onClick={() => setConfirmToImport(true)}
                  onClick={() => {
                    if (templates.isMultiple) {
                      setConfirmToImport(true)
                    } else {
                      props.mutation({ isMultiple: templates.isMultiple, file })
                      handleClose()
                      resetData()
                      setCheck(false)
                    }
                  }}
                  disabled={isDisableImportButton(templates.isMultiple)}
                >
                  Impor
                </Button>
              </>
            ) : (
              <Button
                className={classes.btnImport}
                variant="contained"
                onClick={tryAgain}
                disabled={false}
              >
                Coba Lagi
              </Button>
            )}
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent className={classes.content}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  // display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <p>
                  {isAlreadyImported
                    ? `Import ${position.toLowerCase()} has been successful`
                    : `Are you sure want to import ${position === 'Designation'
                      ? 'job title'
                      : position.toLowerCase()
                    }?`}
                </p>
              </div>
            </div>
          </DialogContent>

          {!isAlreadyImported ? (
            <DialogActions className={classes.actions}>
              <Button onClick={() => setConfirmToImport(false)}>
                Batalkan
              </Button>

              <Button
                className={classes.btnImport}
                variant="contained"
                onClick={() => {
                  if (!confirmToImport) {
                    setCheck(false)
                    setIsRead(true)
                    setConfirmToImport(false)
                  } else {
                    submitImport()
                  }
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          ) : (
            <DialogActions className={classes.actions}>
              <Button
                className={classes.btnImport}
                variant="contained"
                onClick={() => {
                  closeCheck()
                  setImport(false)
                  // handleClose()
                }}
              >
                Finish
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  )
}
