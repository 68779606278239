import React from 'react'
import {Alert} from '@material-ui/lab'

const ImportBaloonPayroll = function ({
  data,
  setImport,
  mutationCloseBaloon,
  refetchBaloonStatus,
  setingImport = false
}) {
    const titleHandle = (title) => {
        if(title ==='finance_payroll_process_multiplier'){
            return 'Multiplier'
        }else if(title === 'finance_settings_component_assign'){
            return 'Assignment Component'
        }
        else if (title === 'finance_payroll_employee_salaries'){
            return 'Bank and Salary'
        }
        else{
            return 'Component'
        }
    }
  return (
    <>
      {data && data.company_import_data_logs.length > 0 &&
        data.company_import_data_logs.map(row=> {
            return row.status !== 'WAITING' &&
            !row.viewed ? (
              <Alert
                color={
                  row.status === 'FAILED'
                    ? 'error'
                    : 'success'
                }
                severity={
                  row.status === 'FAILED'
                    ? 'error'
                    : 'success'
                }
                style={{marginBottom: 8, marginLeft: 8,alignItems:'center'}}
                onClose={async () => {
                  try {
                    await mutationCloseBaloon({
                      variables: {
                        id: row.id,
                      },
                    })
                    refetchBaloonStatus()
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                {(function () {
                  if (row.status === 'WAITING') {
                    return (
                      <>
                      <div>{titleHandle(row.table_name)}</div>
                        Import Data is still in progress. Don't worry, you can still
                        do other activities.{' '}
                        <a
                          onClick={() =>
                            refetchBaloonStatus() 
                          }
                          style={{cursor: 'pointer', textDecoration: 'underline'}}
                        >
                          Reload Import Status.
                        </a>
                      </>
                    )
                  } else if (row.status === 'FAILED') {
                    return (
                      <>
                      <div>{titleHandle(row.table_name)}</div>
                        Import data has failed!{' '}
                        <a
                          onClick={() => {
                              if(setingImport){
                                setingImport({
                                  table : row.table_name ,
                                  position : titleHandle(row.table_name)
                                })
                              }
                              setImport(true)
                            }
                          }
                          style={{cursor: 'pointer', textDecoration: 'underline'}}
                        >
                          See the problem
                        </a>
                      </>
                    )
                  } else {
                    return (
                      <>
                        <div>{titleHandle(row.table_name)}</div>
                        Import data is completed!
                      </>
                      )
                  }
                })()}
              </Alert>
            ) : row.status === 'WAITING' ? (
              <Alert
                color={'info'}
                severity={'info'}
                style={{marginBottom: 8, marginLeft: 8,alignItems:'center'}}
                onClose={async () => {
                  try {
                    await mutationCloseBaloon({
                      variables: {
                        id: row.id,
                      },
                    })
                    refetchBaloonStatus()
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                <div>{titleHandle(row.table_name)}</div>
                Import Data is still in progress. Don't worry, you can still do other
                activities.{' '}
                <a
                  onClick={() =>
                    refetchBaloonStatus()
                  }
                  style={{cursor: 'pointer', textDecoration: 'underline'}}
                >
                  Reload Import Status.
                </a>
              </Alert>
            ) : (
              true
            )    
        })
      }
    </>
  )
}

export default ImportBaloonPayroll
