import styled, {css} from 'styled-components'

export const DashboardPage = styled.div`
  flex-direction: column;
  display: flex;
  padding: 0 16px;
`

export const ContainerDashboard = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperDashboard = styled.div`
  display: flex;
  flex-direction: row;
`

export const WrapperWidgetDashboard = styled.div`
  text-align: left;
  padding-top: 1.4rem;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
  flex: 1;
`

export const WrapperDashboardSidebarRight = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperChart = styled.div`
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  ${props =>
    props.zeroVerticalPadding &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
  ${props =>
    props.isList &&
    css`
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 12px;
    `}
`
