import React from 'react'
import {
  CustomTypography,
  Flex,
  FlexCenters,
  GreyTypography,
  SkyBlueIcon,
} from '../../../../../GlobalStyles'
import defaultImg from '../../../../../assets/images/course-default-thumbnail.jpg'
import {Divider, Typography} from '@material-ui/core'
import moment from 'moment'
import Axios from 'axios'
import {REAST_API_URL, TOKEN} from '../../../../../utils/globals'
import {GET_GLOBAL_LANGUAGES} from '../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {
  level_skill,
  status_data,
} from '../../../../shared-components/data/SharedData'

export default function TabsAbout({classes, data}) {
  const [categories, setCategories] = React.useState([])
  const {data: dataLanguages} = useQuery(GET_GLOBAL_LANGUAGES)

  React.useEffect(() => {
    if (data) {
      getCategories()
    }
  }, [data])

  if (!data) {
    return null
  }

  const getCategories = async () => {
    try {
      const response = await Axios.get(
        REAST_API_URL + '/academy/category/get/',
        {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          },
        }
      )

      setCategories(response.data.data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Flex>
        <img src={data.thumbnail || defaultImg} className={classes.iconImg} />
        <div>
          <CustomTypography
            variant="body1"
            fweight="bold"
            bottom="4px"
            fcolor="blue"
          >
            {data.title}
          </CustomTypography>
          <CustomTypography size="12px" bottom="8px" fcolor="grey">
            Dibuat pada {moment(data.dateAdded).format('LL')}
          </CustomTypography>
          <CustomTypography
            size="12px"
            bottom="17px"
            fcolor={
              data.status === 'active'
                ? 'green'
                : data.status === 'pending'
                ? 'orange'
                : data.status === 'draft'
                ? 'grey'
                : '#red'
            }
          >
            {data.status && status_data[data.status.toUpperCase()]}
          </CustomTypography>
          <FlexCenters className={classes.mb12}>
            <SkyBlueIcon>person</SkyBlueIcon>
            <Typography variant="body2">
              {data.countEnrollment} Peserta
            </Typography>
          </FlexCenters>
          <FlexCenters className={classes.mb12}>
            <SkyBlueIcon>star</SkyBlueIcon>
            <Typography variant="body2">
              {data.averageRating.toFixed(1)} ({data.countEnrollment})
            </Typography>
          </FlexCenters>
        </div>
      </Flex>
      <Divider className={classes.dividers} />
      <div>
        <CustomTypography fweight="bold" bottom="20px">
          Rincian
        </CustomTypography>

        <FlexCenters className={classes.mb12}>
          <Typography variant="body2" style={{width: 127}}>
            Bahasa
          </Typography>
          :
          {data.language && dataLanguages && (
            <GreyTypography variant="body2" className={classes.ml12}>
              {
                dataLanguages.global_languages.find(d => {
                  return parseInt(d.id) === parseInt(data.language)
                }).name
              }
            </GreyTypography>
          )}
        </FlexCenters>
        <FlexCenters className={classes.mb12}>
          <Typography variant="body2" style={{width: 127}}>
            Kategori
          </Typography>
          :
          {(function() {
            let _categoryId
            const _subCategories = []

            if (data?.AcademyCourseCategory.id && categories.length > 0) {
              categories.map(cat => {
                cat.AcademyCourseCategories.map(cat2 => {
                  _subCategories.push(cat2)
                })
              })

              _categoryId =
                categories &&
                _subCategories.find(
                  subCat => subCat.id === data.AcademyCourseCategory.id
                ) &&
                _subCategories.find(
                  subCat => subCat.id === data.AcademyCourseCategory.id
                ).parent

              if (_categoryId) {
                return (
                  <GreyTypography variant="body2" className={classes.ml12}>
                    {categories.find(cat => cat.id === _categoryId).name}
                  </GreyTypography>
                )
              }
            }
          })(this)}
        </FlexCenters>
        <FlexCenters className={classes.mb12}>
          <Typography variant="body2" style={{width: 127}}>
            Sub Kategori
          </Typography>
          :
          <GreyTypography variant="body2" className={classes.ml12}>
            {data.AcademyCourseCategory.name}
          </GreyTypography>
        </FlexCenters>
        <FlexCenters className={classes.mb12}>
          <Typography variant="body2" style={{width: 127}}>
            Level
          </Typography>
          :
          <GreyTypography variant="body2" className={classes.ml12}>
            {data.level === 2
              ? level_skill.intermediate
              : data.level === 3
              ? level_skill.advanced
              : data.level === 1
              ? level_skill.beginner
              : '-'}
          </GreyTypography>
        </FlexCenters>
      </div>
      <Divider className={classes.dividers} />
      <CustomTypography fweight="bold" bottom="20px">
        Rincian
      </CustomTypography>
      <CustomTypography>{data.description}</CustomTypography>
    </>
  )
}
