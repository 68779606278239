import React from 'react'
import {
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
  FlexCenter,
} from '../../../../GlobalStyles'
import {
  Dialog,
  Typography,
  IconButton,
  CircularProgress,
  Avatar,
} from '@material-ui/core'
import {DetailParticipantContainer} from '../ClassificationPageStyles'
import {dateMinENOrUS} from '../../../../utils/helpers'

const DetailParticipant = props => {
  const {handleClose, open, dataDetail, loading} = props

  const renderListParticipant = (list, key) => {
    return (
      <div className="list-people" key={key}>
        <Avatar alt={list?.global_user?.name} src={list?.global_user?.avatar} />
        <div className="people-detail">
          <p className="p-title">{list?.global_user?.name}</p>
          <p className="p-small">
            {
              list?.global_user?.people_work_placements?.[0]
                ?.company_job_profile?.title
            }
          </p>
        </div>
      </div>
    )
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DetailParticipantContainer>
        <ModalHeader>
          <div>
            <Typography className="title">Rincian Peserta</Typography>
          </div>
          <IconButton aria-label="close" onClick={handleClose}>
            <WhiteIcon>close</WhiteIcon>
          </IconButton>
        </ModalHeader>
        <div className="body-wrap">
          {open && loading && !dataDetail ? (
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          ) : (
            <>
              <ModalContentEvent className="section-detail">
                <div className="wrap-section">
                  <div>
                    <p className="p-title">Peserta</p>
                    <p className="p-value">
                      {
                        dataDetail?.talent_candidate_groups?.[0]
                          ?.company_job_profile?.title
                      }
                    </p>
                  </div>
                  <div className="text-section">
                    <p className="p-title">Tanggal Evaluasi</p>
                    <p className="p-value">
                      {`${dateMinENOrUS(
                        dataDetail?.talent_candidate_groups?.[0]
                          ?.committee_date_start
                      )} - ${dateMinENOrUS(
                        dataDetail?.talent_candidate_groups?.[0]
                          ?.committee_date_end
                      )}`}
                    </p>
                  </div>
                </div>
              </ModalContentEvent>
              <ModalContentEvent className="section-detail grey-section">
                <div className="wrap-section">
                  <div>
                    <p className="p-title">Penanggungjawab</p>
                    <div className="people-section">
                      {dataDetail?.talent_candidate_groups?.[0]?.pic?.map(
                        (data, key) => {
                          return renderListParticipant(data, key)
                        }
                      ) ?? '-'}
                    </div>
                  </div>
                  <div className="text-section">
                    <p className="p-title">Komite</p>
                    <div className="people-section">
                      {dataDetail?.talent_candidate_groups?.[0]?.committee?.map(
                        (data, key) => {
                          return renderListParticipant(data, key)
                        }
                      ) ?? '-'}
                    </div>
                  </div>
                </div>
              </ModalContentEvent>
            </>
          )}
        </div>
      </DetailParticipantContainer>
    </Dialog>
  )
}

export default DetailParticipant
