import gql from 'graphql-tag'

export const UPDATE_KPI_LEVEL = id => {
  return gql`
      mutation updateKPILevel($data: kpi_levels_set_input) {
        update_kpi_levels(where: {id: {_eq: ${id}}}, _set: $data) {
          returning {
            id
          }
        }
      }
    `
}
