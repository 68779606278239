import gql from 'graphql-tag'

export const GET_EMPLOYEE_INDIVIDUAL_BY_VERSION = gql`
  query(
    $version: Int
    $search: String
    $dateFrom: date
    $dateTo: date
    $offset: Int
    $limit: Int
  ) {
    total: performance_individuals_aggregate(
      where: {
        performance_individual_candidate: {
          performance_individual_version: {
            id: {_eq: $version}
            start_date: {_gte: $dateFrom, _lte: $dateTo}
          }
        }
        people_work_placement: {
          _or: [
            {regno: {_ilike: $search}}
            {global_user: {name: {_ilike: $search}}}
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    performance_individual_versions(where: {id: {_eq: $version}}) {
      id
      name
    }
    performance_individuals(
      where: {
        performance_individual_candidate: {
          performance_individual_version: {
            id: {_eq: $version}
            start_date: {_gte: $dateFrom, _lte: $dateTo}
          }
        }
        people_work_placement: {
          _or: [
            {regno: {_ilike: $search}}
            {global_user: {name: {_ilike: $search}}}
          ]
        }
      }
      distinct_on: [user]
      offset: $offset
      limit: $limit
    ) {
      id
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          code
          title
        }
      }
      date_added
      last_modified
    }
  }
`

export const GET_EMPLOYEE_INDIVIDUAL = gql`
  subscription getEmployee($search: String) {
    people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_organization {
        id
        name
      }
      company_employee_rank {
        id
        name
      }
      company_employee_position {
        id
        name
      }
      company_address {
        id
        office_name
      }
      performance_individual(limit: 1, order_by: {date_added: desc}) {
        date_added
      }
    }
  }
`
