import gql from 'graphql-tag'

export const UPDATE_HOLDING_VERSION = gql`
  mutation update_holding_version(
    $id: Int
    $changes: holding_version_set_input
  ) {
    update_holding_version(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`
