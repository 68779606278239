import React, {useState, useContext} from 'react'
import helperStyle, {
  GreyTypography,
  ModalContent,
  ModalHeader,
  FlexBetween,
  CalendarChip,
  DetailCalendarSchedule,
} from '../../../../../GlobalStyles'
import {DataContext} from '../../../../../ContextConfig'

import {Dialog, Typography, IconButton, Icon} from '@material-ui/core'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import moment from 'moment'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {withRouter} from 'react-router-dom'
import {DELETE_HOLIDAY} from '../../../../../graphql/mutations'
import {COMPANY_ID} from '../../../../../utils/globals'

const ModalCalendarSchedule = props => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const {open, handleClose, data, color, history, refetch} = props
  const {setDataContext} = useContext(DataContext)
  const [modalAlert, setModalAlert] = useState(false)
  const [deleteType, setDeleteType] = useState('')
  const [deleteHoliday] = useMutation(DELETE_HOLIDAY)
  const handleOpenAlert = () => {
    if (modalAlert) {
      setModalAlert(false)
      handleClose()
    } else {
      setModalAlert(true)
    }
  }

  const handleEdit = data => {
    if (data && data.typeCalender === 'holiday') {
      history.push({
        pathname: `/time/calendar/edit-event`,
        state: {active: 'edit-event', dataEdit: data},
      })
      setDataContext({
        year: true,
        month: false,
        week: false,
        day: false,
        schedule: false,
        chooserCalendar: 'year',
      })
    }
  }

  const handleDelete = () => {
    let where = null

    switch (deleteType) {
      case 'this':
        where = {
          id: {_eq: data.id},
          deletedAt: {_is_null: true},
        }
        break
      case 'following':
        where = {
          id: {_eq: data.id},
          type: {_eq: data.type},
          deletedAt: {_is_null: true},
        }
        break
      case 'all':
        where = {
          company: {_eq: COMPANY_ID},
          deletedAt: {_is_null: true},
        }
        break
      default:
        break
    }

    deleteHoliday({variables: {where}})
      .then(() => {
        if (refetch) {
          refetch()
        }
        enqueueSnackbar('Holiday deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Delete Holiday failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  return (
    <Dialog
      onClose={() => {
        handleClose()
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <ModalHeader>
        <FlexBetween>
          <div className={classes.width70P}>
            <CalendarChip style={color} />
            <span className={classes.marginLeft10}>{data && data.title}</span>
          </div>
          <div className={classes.width30P}>
            <FlexBetween>
              <IconButton aria-label="edit" onClick={() => handleEdit(data)}>
                <Icon style={{color: '#fff', fontSize: '20px'}}>edit</Icon>
              </IconButton>
              <IconButton aria-label="delete" onClick={handleOpenAlert}>
                <Icon style={{color: '#fff', fontSize: '20px'}}>delete</Icon>
              </IconButton>
              <IconButton aria-label="close" onClick={handleClose}>
                <Icon style={{color: '#fff', fontSize: '20px'}}>close</Icon>
              </IconButton>
            </FlexBetween>
          </div>
        </FlexBetween>
      </ModalHeader>
      <ModalContent>
        <DetailCalendarSchedule>
          <div className="content-detail">
            <GreyTypography variant="body2" className={classes.width30P}>
              Title
            </GreyTypography>
            <Typography variant="body2" className={classes.width70P}>
              {data && data.title}
            </Typography>
          </div>
          <div className="content-detail">
            <GreyTypography variant="body2" className={classes.width30P}>
              Schedule
            </GreyTypography>
            <Typography variant="body2" className={classes.width70P}>
              {data && moment(data.date).format('LL')}
            </Typography>
          </div>
          <div className="content-detail">
            <GreyTypography variant="body2" className={classes.width30P}>
              Description
            </GreyTypography>
            <Typography variant="body2" className={classes.width70P}>
              {data?.description || data?.leaves?.[0]?.description || '-'}
            </Typography>
          </div>
        </DetailCalendarSchedule>
      </ModalContent>
      <DeletePopup
        open={modalAlert}
        handleClose={handleOpenAlert}
        name={data && data.title}
        mutation={data && data.typeCalender === 'holiday' && handleDelete}
        feature={'calendar'}
        setDeleteType={setDeleteType}
        deleteType={deleteType}
      />
    </Dialog>
  )
}

export default withRouter(ModalCalendarSchedule)
