import React from 'react'
import {
  FormChildContainerFull,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
} from '../../../../../GlobalStyles'
import {TextField} from '@material-ui/core'

export default function ComponentInput(props) {
  const {number, name, error, id, ...other} = props
  const length = number.split('').length
  return (
    <FormChildContainerFull id={id}>
      <FormChildTitle>
        <TitleNumber style={length === 2 ? {paddingRight: 10} : null}>
          {number}.
        </TitleNumber>
        <TitleName>{name}</TitleName>
      </FormChildTitle>
      <FormChildInput>
        <TextField
          fullWidth
          size="small"
          margin="normal"
          variant="outlined"
          error={error}
          helperText={error ? 'This field is required' : null}
          {...other}
        />
      </FormChildInput>
    </FormChildContainerFull>
  )
}
