import React, {useState, Fragment} from 'react'
import {
  Typography,
  Button,
  makeStyles,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
} from '@material-ui/core'
import {
  ThinTextfield,
  TextBlackBold,
  BtnAddSquare,
  Flex,
  GenerateTooltip,
} from '../../../../../GlobalStyles'
import AddDetailBox from './detail-component/AddDetailBox'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {integerOnly, downloadFile} from '../../../../../utils/helpers'
import BoxesDiagram from '../../../../talent/shared-components/BoxesDiagram'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import XLSX from 'xlsx'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileExcel} from '@fortawesome/free-solid-svg-icons'
import {REF_BADGES} from '../../../../../utils/globals'
import {useSnackbar} from 'notistack'

const iconPlusStyle = {
  fontSize: 30,
  color: '#039be5',
}

const useStyles = makeStyles(() => ({
  flexTextfield: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '1.6rem',
  },
  box: {background: '#a9a8a8', height: 125, cursor: 'pointer'},
  paperDetail: {
    width: '100%',
    border: '1px solid #707070',
    padding: '1.6rem',
    background: '#eff2f4',
  },
  title: {marginBottom: '1.2rem'},
  icon: {
    borderRadius: 5,
    padding: 10,
  },
  checkedIcon: {
    borderRadius: 5,
    color: '#fff',
  },
  colorContainer: {
    display: 'flex',
    marginTop: 10,
    flexWrap: 'wrap',
  },
  indicatorContainer: {
    padding: '1.2rem',
    background: '#f7f8f9',
    marginTop: '1rem',
  },
  m0: {
    margin: 0,
  },
  darkGreyIcon: {
    color: '#a9a8a8',
    fontSize: '2.5rem!important',
  },
  badgeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chooseContainer: {
    textAlign: 'center',
    width: 75,
    border: '1px solid #a9a8a8',
    borderRadius: 5,
    padding: 8,
    cursor: 'pointer',
    background: 'white',
    marginRight: '1.6rem',
  },
  previewContainer: {
    marginLeft: 16,
    display: 'flex',
    alignItems: 'end',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  white: {
    background: 'white',
  },
  labelRadioWrapper: {
    marginLeft: '31px',
  },
  chooseFile: {
    color: '#333333 !important',
    fontSize: '14px !important',
    display: 'flex',
    alignItems: 'center',
  },
  downloadHelper: {
    color: '#a9a8a8 !important',
    fontSize: '12px !important',
    marginBottom: '10px',
  },
  downloadHelperImport: {
    color: '#a9a8a8 !important',
    fontSize: '14px !important',
    marginBottom: '10px',
  },
  input: {
    display: 'none',
  },
  fileWrapper: {
    width: '471px',
    padding: '15px',
    borderRadius: '5px',
    backgroundColor: '#f7f8f9',
  },
  fileNameWrapper: {
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '3px',
    marginBottom: '5px',
  },
  textTooltip: {
    fontSize: 12,
    color: '#000',
    display: 'block',
    margin: '2px 0px',
  },
  textBlue: {
    color: '#014a62',
    fontSize: 12,
    display: 'block',
    margin: '2px 0px 2px 2px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

const ContentTooltip = () => {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.textTooltip}>
        Appropriate file extension:
      </Typography>
      <Typography className={classes.textTooltip}>
        {`• Document (xlsx, xls)`}
      </Typography>
      <div className={classes.tooltipWrapper}>
        <Typography className={classes.textTooltip}>Max size:</Typography>
        <Typography className={classes.textBlue}>5 MB</Typography>
      </div>
    </>
  )
}

const StepTwo = ({
  setMainValues,
  mainValues,
  isError,
  undefinedChecker,
  colorOptions,
  setColorOptions,
  // mutationUpdateBoxes,
  isTalent,
  setIsError,
  status = false,
}) => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [values, setValues] = useState({
    width: mainValues.box_width,
    height: mainValues.box_height,
    selectedX: '',
    selectedY: '',
  })

  const [tempBoxData, setTempBoxData] = useState(null)
  const [open, setOpen] = useState(false)
  const [radioVal, setRadioVal] = useState(mainValues?.methodBox || '')
  const [file, setFile] = useState(mainValues?.methodBoxFile || null)
  const [dataToImport, setDataToImport] = useState(null)
  const [isEditImport, setIsEditImport] = useState(false)

  const {width, height, selectedX, selectedY} = values
  const isEditPerformance = status === 'perf-classification-edit'

  const handleChange = name => event => {
    if (event.target.value > 10) {
      event.target.value = 10
    } else if (event.target.value <= 0) {
      event.target.value = ''
    }

    setValues({
      ...values,
      [name]: integerOnly(event.target.value),
    })
  }

  const handleChangeRadio = event => {
    setRadioVal(event.target.value)
    setFile(null)
    setDataToImport(null)
    setValues({
      ...values,
      width: '',
      height: '',
    })
    setMainValues({
      ...mainValues,
      box_width: '',
      box_height: '',
      talent_boxes: {data: []},
      methodBox: event.target.value,
    })
  }

  const generateBox = () => {
    if (radioVal === 'import') {
      if (!dataToImport) {
        setIsError(true)
      } else {
        let _width = 0
        let _height = 0
        const box = []
        dataToImport.map(val => {
          if (val.index_x || val['X Axis'] > _width) {
            _width = val.index_x || val['X Axis']
          }
          if (val.index_y || val['Y Axis'] > _height) {
            _height = val.index_y || val['Y Axis']
          }
        })

        for (var y = _height; y > 0; y--) {
          for (var x = 0; x < _width; x++) {
            box.push({index_x: x + 1, index_y: y})
          }
        }

        setValues({
          ...values,
          width: _width,
          height: _height,
        })

        box.map((val, key) => {
          const index = dataToImport.findIndex(
            e =>
              parseInt(val.index_x) === parseInt(e.index_x || e['X Axis']) &&
              parseInt(val.index_y) === parseInt(e.index_y || e['Y Axis'])
          )
          box[key] = {...dataToImport[index]}
        })

        const dataBoxPerformance = box.map(val => {
          return {
            index_x: val['X Axis'],
            index_y: val['Y Axis'],
            level: val['Performance Classification Level'],
            name: val['Level Name'],
            description: val['Badge Description'],
            color_hex: val['Color Hex'],
            badge: val['Badge Icon'],
          }
        })

        setMainValues({
          ...mainValues,
          box_width: _width,
          box_height: _height,
          talent_boxes: {data: isTalent ? box : dataBoxPerformance},
        })
      }
    } else {
      if (!width || !height) {
        setIsError(true)
      } else {
        const box = []
        for (var _y = height; _y > 0; _y--) {
          for (var _x = 0; _x < width; _x++) {
            box.push({index_x: _x + 1, index_y: _y})
          }
        }

        setMainValues({
          ...mainValues,
          box_width: width,
          box_height: height,
          talent_boxes: {data: box},
        })
      }
    }
  }

  const isNotTemplateImport = dataImport => {
    return isTalent
      ? !dataImport[0].index_x ||
          !dataImport[0].index_y ||
          !dataImport[0].level ||
          !dataImport[0].name ||
          !dataImport[0].description ||
          !dataImport[0].color_hex ||
          !dataImport[0].badge
      : !dataImport[0]['X Axis'] ||
          !dataImport[0]['Y Axis'] ||
          !dataImport[0]['Performance Classification Level'] ||
          !dataImport[0]['Level Name'] ||
          !dataImport[0]['Badge Description'] ||
          !dataImport[0]['Color Hex'] ||
          !dataImport[0]['Badge Icon']
  }

  const validationSaveTemplate = mainValues?.talent_boxes?.data.some(val => {
    return (
      !val.level ||
      !val.name ||
      !val.description ||
      !val.color_hex ||
      !val.badge
    )
  })

  const handleCloseDetail = () => {
    setOpen(false)
    const ids = document.getElementById('top')
    ids.scrollIntoView({behavior: 'smooth'})
  }

  const handleClickReset = () => {
    setRadioVal('')
    setFile(null)
    setDataToImport(null)
    setValues({
      ...values,
      width: '',
      height: '',
    })
    setMainValues({
      ...mainValues,
      box_width: '',
      box_height: '',
      talent_boxes: {data: []},
    })
  }

  const handleValDuplicate = event => {
    event.target.value = ''
  }

  const handleBrowseFile = event => {
    const files = event.target.files[0]
    const reader = new FileReader()

    if (files.size > 1024 * 1024 * 5) {
      enqueueSnackbar('Max size is 5 MB', {
        variant: 'error',
        autoHideDuration: 1000,
      })
    } else {
      reader.onload = evt => {
        /* Parse data */
        const bstr = evt.target.result
        const wb = XLSX.read(bstr, {type: 'binary'})
        /* Get first worksheet */
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {blankrows: false})

        const isNotTemplate = isNotTemplateImport(data)

        if (isNotTemplate) {
          enqueueSnackbar(
            'Wrong file, please upload file according to the template.',
            {
              variant: 'error',
              autoHideDuration: 1000,
            }
          )
        } else {
          setDataToImport(data)
          setFile(files)
          setMainValues({...mainValues, methodBoxFile: files})
        }
      }
      reader.readAsBinaryString(files)
    }
  }

  const handleDeleteFile = () => {
    setFile(null)
    setDataToImport(null)
  }

  const renderSize = val => {
    let value
    if (val > 1073741824) {
      value = `${(val / 1073741824).toFixed(1)} GB`
    } else if (val > 1048576) {
      value = `${(val / 1048576).toFixed(1)} MB`
    } else if (val > 1024) {
      value = `${(val / 1024).toFixed(1)} KB`
    } else {
      value = `${val} B`
    }
    return value
  }

  const generateTemplate = type => {
    const data = [
      {
        index_x: 1,
        index_y: 1,
        level: 1,
        name: 'Level 1',
        description: 'Level 1 Description',
        color_hex: '#ef4d5e',
        badge:
          'https://public-media.worklifebeyond.com/d6502ea0-4bb1-48ed-8c5c-ee4d33e6e14a-1586280678513.png',
      },
      {
        index_x: 2,
        index_y: 1,
        level: 2,
        name: 'Level 2',
        description: 'Level 2 Description',
        color_hex: '#f04ba0',
        badge:
          'https://public-media.worklifebeyond.com/5e8fde5b-4f52-4bdd-aa1d-982bf350fe9a-1586280726649.png',
      },
      {
        index_x: 1,
        index_y: 2,
        level: 3,
        name: 'Level 3',
        description: 'Level 3 Description',
        color_hex: '#fcba03',
        badge:
          'https://public-media.worklifebeyond.com/5e8fde5b-4f52-4bdd-aa1d-982bf350fe9a-1586280726649.png',
      },
      {
        index_x: 2,
        index_y: 2,
        level: 4,
        name: 'Level 4',
        description: 'Level 4 Description',
        color_hex: '#fcba03',
        badge:
          'https://public-media.worklifebeyond.com/5e8fde5b-4f52-4bdd-aa1d-982bf350fe9a-1586280726649.png',
      },
    ]

    const headers = {
      index_x: 'X Axis',
      index_y: 'Y Axis',
      level: 'Performance Classification Level',
      name: 'Level Name',
      description: 'Badge Description',
      color_hex: 'Color Hex',
      badge: 'Badge Icon',
    }

    const dataTalentBox = mainValues?.talent_boxes?.data
    if (!isTalent) {
      data.unshift(headers)
      dataTalentBox.unshift(headers)
    }

    var templateWS = XLSX.utils.json_to_sheet(
      type === 'save' ? dataTalentBox : data,
      {
        skipHeader: !isTalent,
      }
    )
    var wb = XLSX.utils.book_new()

    const nameFile = isTalent
      ? 'TalentBoxTemplate.xlsx'
      : 'Template Import Box - Performance Classification.xlsx'

    XLSX.utils.book_append_sheet(wb, templateWS, 'template')
    XLSX.writeFile(wb, nameFile)
    !isTalent &&
      type !== 'save' &&
      downloadFile(REF_BADGES, 'FileReferensiBadge.xlsx')
  }

  const GenerateBoxComponent = () => {
    return (
      <>
        <div className={classes.flexTextfield} style={{marginBottom: 0}}>
          <ThinTextfield
            id="version-name"
            variant="outlined"
            value={
              (isTalent || !isEditPerformance) && radioVal !== 'generate'
                ? ''
                : width
            }
            width="100px"
            disabled={
              (isTalent || !isEditPerformance || isEditImport) &&
              radioVal !== 'generate'
            }
            onChange={handleChange('width')}
            error={
              isTalent || !isEditPerformance
                ? radioVal === 'generate' &&
                  (!mainValues.box_width || !width) &&
                  isError
                : (!mainValues.box_width || !width) && isError
            }
            helperText={
              isTalent || !isEditPerformance
                ? radioVal === 'generate' &&
                  (!mainValues.box_width || !width) &&
                  isError
                  ? 'This field is required'
                  : ''
                : (!mainValues.box_width || !width) && isError
                ? 'This field is required'
                : ''
            }
          />
          <strong style={{margin: '10px 15px'}}>X</strong>
          <ThinTextfield
            id="version-name"
            variant="outlined"
            value={
              (isTalent || !isEditPerformance) && radioVal !== 'generate'
                ? ''
                : height
            }
            style={{marginRight: 20}}
            width="100px"
            disabled={
              (isTalent || !isEditPerformance || isEditImport) &&
              radioVal !== 'generate'
            }
            onChange={handleChange('height')}
            error={
              isTalent || !isEditPerformance
                ? radioVal === 'generate' &&
                  (!mainValues.box_width || !height) &&
                  isError
                : (!mainValues.box_width || !height) && isError
            }
            helperText={
              isTalent || !isEditPerformance
                ? radioVal === 'generate' &&
                  (!mainValues.box_width || !height) &&
                  isError
                  ? 'This field is required'
                  : ''
                : (!mainValues.box_width || !height) && isError
                ? 'This field is required'
                : ''
            }
          />
          <GenerateTooltip
            title={
              ((isTalent || !isEditPerformance || isEditImport) &&
                radioVal !== 'generate') ||
              ((mainValues.talent_boxes.data.length === 0 ||
                validationSaveTemplate) &&
                (isTalent || !isEditPerformance))
                ? `Silahkan pilih Atur Manual dan isi setiap bidang sebelum menyimpan templat kotak klasifikasi`
                : ''
            }
            placement="right"
          >
            <span>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() =>
                  isTalent || !isEditPerformance
                    ? generateTemplate('save')
                    : generateBox()
                }
                style={{maxHeight: 39}}
                disabled={
                  ((isTalent || !isEditPerformance || isEditImport) &&
                    radioVal !== 'generate') ||
                  ((mainValues.talent_boxes.data.length === 0 ||
                    validationSaveTemplate) &&
                    (isTalent || !isEditPerformance))
                }
              >
                {isTalent || !isEditPerformance
                  ? 'Simpan Contoh File'
                  : isEditPerformance
                  ? 'Ubah Kotak'
                  : 'Hasilkan'}
              </Button>
            </span>
          </GenerateTooltip>
        </div>

        <TextBlackBold
          className={classes.downloadHelper}
          style={{margin: '0.5rem 0rem 1.6rem 0rem'}}
        >
          Maksimal 10 Kotak
        </TextBlackBold>
      </>
    )
  }

  return (
    <>
      <div id="top">
        <TextBlackBold
          style={{
            ...(isEditPerformance && {fontSize: '14px'}),
            marginBottom: '10px',
          }}
        >
          {isTalent
            ? 'Kotak Klasifikasi Talenta'
            : isEditPerformance
            ? 'Kotak Klasifikasi Kinerja'
            : 'Metode Penentuan Klasifikasi Kotak Kinerja'}
        </TextBlackBold>
        {isTalent || !isEditPerformance ? (
          <RadioGroup
            name="gender1"
            value={radioVal}
            onChange={handleChangeRadio}
          >
            <FormControlLabel
              value="import"
              control={<Radio />}
              label={
                <TextBlackBold>
                  {`Impor Kotak ${
                    isTalent ? 'Talenta' : 'Klasifikasi Kinerja'
                  }`}
                  {radioVal === 'import' && isError && !dataToImport && (
                    <span style={{color: '#dd5962', fontStyle: 'italic'}}>
                      *silahkan pilih berkas
                    </span>
                  )}
                </TextBlackBold>
              }
            />
            <div className={classes.labelRadioWrapper}>
              <TextBlackBold className={classes.chooseFile}>
                Pilih Berkas
                {!isTalent && (
                  <GenerateTooltip title={<ContentTooltip />} placement="right">
                    <InfoOutlinedIcon
                      style={{marginLeft: 7, width: 16, alignSelf: 'start'}}
                      color="primary"
                    />
                  </GenerateTooltip>
                )}
              </TextBlackBold>
              <TextBlackBold className={classes.downloadHelper}>
                {isTalent ? 'Unduh templat berkas' : 'Unduh Contoh File'}
                <span
                  onClick={() => generateTemplate()}
                  style={{color: '#007fb2', cursor: 'pointer'}}
                >
                  disini
                </span>
              </TextBlackBold>
              {file ? (
                <div className={classes.fileWrapper}>
                  <Grid container spacing={1}>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        style={{
                          fontSize: '35px',
                          color: 'green',
                        }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Flex style={{flexDirection: 'column'}}>
                        <div className={classes.fileNameWrapper}>
                          {file ? file.name : ''}
                        </div>
                        <TextBlackBold
                          style={{
                            fontSize: '10px !important',
                            textAlign: 'right',
                          }}
                        >
                          {renderSize(file.size)}
                        </TextBlackBold>
                      </Flex>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDeleteFile()}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <>
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    onChange={handleBrowseFile}
                    onClick={handleValDuplicate}
                    disabled={
                      (isTalent || !isEditPerformance) && radioVal !== 'import'
                    }
                  />
                  <label htmlFor="icon-button-file">
                    <BtnAddSquare
                      disabled={
                        (isTalent || !isEditPerformance) &&
                        radioVal !== 'import'
                      }
                      style={{width: 'auto'}}
                      component="span"
                    >
                      <AddIcon style={iconPlusStyle} />
                    </BtnAddSquare>
                  </label>
                </>
              )}
            </div>
            <FormControlLabel
              value="generate"
              control={<Radio />}
              label={<TextBlackBold>Atur Manual</TextBlackBold>}
            />
            <div className={classes.labelRadioWrapper}>
              <GenerateBoxComponent />
            </div>
          </RadioGroup>
        ) : (
          <div style={{marginBottom: '20px'}}>
            <GenerateBoxComponent />
            <TextBlackBold
              className={classes.downloadHelperImport}
              style={{
                margin: '0.5rem 0rem 1.6rem 0rem',
              }}
            >
              atau ubah dengan impor berkas.{' '}
              <span
                onClick={() => {
                  if (!isEditImport) {
                    handleClickReset()
                    setTempBoxData({
                      box_width: mainValues.box_width,
                      box_height: mainValues.box_height,
                      talent_boxes: mainValues.talent_boxes,
                    })
                    setIsEditImport(true)
                    setRadioVal('import')
                  }
                }}
                style={{
                  color: '#007fb2',
                  cursor: 'pointer',
                  fontSize: '14px',
                  textDecoration: 'underline',
                }}
              >
                Import here
              </span>
            </TextBlackBold>
            {isEditImport && (
              <>
                <TextBlackBold className={classes.chooseFile}>
                  Choose File
                  {!isTalent && (
                    <GenerateTooltip
                      title={<ContentTooltip />}
                      placement="right"
                    >
                      <InfoOutlinedIcon
                        style={{marginLeft: 7, width: 16, alignSelf: 'start'}}
                        color="primary"
                      />
                    </GenerateTooltip>
                  )}
                </TextBlackBold>
                <TextBlackBold className={classes.downloadHelper}>
                  Download template file{' '}
                  <span
                    onClick={() => generateTemplate()}
                    style={{color: '#007fb2', cursor: 'pointer'}}
                  >
                    here
                  </span>
                </TextBlackBold>
                {file ? (
                  <div className={classes.fileWrapper}>
                    <Grid container spacing={1}>
                      <Grid item xs={1}>
                        <FontAwesomeIcon
                          icon={faFileExcel}
                          style={{
                            fontSize: '35px',
                            color: 'green',
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Flex style={{flexDirection: 'column'}}>
                          <div className={classes.fileNameWrapper}>
                            {file ? file.name : ''}
                          </div>
                          <TextBlackBold
                            style={{
                              fontSize: '10px !important',
                              textAlign: 'right',
                            }}
                          >
                            {renderSize(file.size)}
                          </TextBlackBold>
                        </Flex>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => handleDeleteFile()}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <>
                    <input
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      className={classes.input}
                      id="icon-button-file"
                      type="file"
                      onChange={handleBrowseFile}
                      onClick={handleValDuplicate}
                      disabled={
                        (isTalent || !isEditPerformance) &&
                        radioVal !== 'import'
                      }
                    />
                    <label htmlFor="icon-button-file">
                      <BtnAddSquare
                        disabled={
                          (isTalent || !isEditPerformance) &&
                          radioVal !== 'import'
                        }
                        style={{width: 'auto'}}
                        component="span"
                      >
                        <AddIcon style={iconPlusStyle} />
                      </BtnAddSquare>
                    </label>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {(isTalent || !isEditPerformance || isEditImport) && (
        <div
          className={classes.labelRadioWrapper}
          style={{...(isEditImport && {margin: 0})}}
        >
          <div className={classes.flexTextfield}>
            <Button
              variant="outlined"
              style={{
                ...(isEditImport && {
                  border: '1px solid #dd5962',
                  color: '#dd5962',
                }),
                marginRight: '20px',
                width: '195px',
              }}
              size="large"
              color="primary"
              onClick={() => {
                if (isEditPerformance) {
                  setIsEditImport(false)
                  setRadioVal('generate')
                  setMainValues({
                    ...mainValues,
                    box_height: tempBoxData.box_height,
                    box_width: tempBoxData.box_width,
                    talent_boxes: tempBoxData.talent_boxes,
                  })
                  setValues({
                    ...values,
                    height: tempBoxData.box_height,
                    width: tempBoxData.box_width,
                  })
                } else {
                  handleClickReset()
                }
              }}
            >
              {isEditPerformance ? 'Batal' : 'Atur Ulang'}
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={generateBox}
              disabled={
                radioVal
                  ? !dataToImport && (!values.width || !values.height)
                  : true
              }
              style={{width: '195px'}}
            >
              {isTalent ? 'Hasilkan' : 'Buat'}
            </Button>
          </div>
        </div>
      )}

      {mainValues.box_width > 0 && mainValues.box_height > 0 && (
        <Fragment>
          <div className={classes.titleContainer}>
            <Typography variant="body1">Pratinjau Kotak</Typography>
            {undefinedChecker(mainValues.talent_boxes.data) && isError && (
              <Typography
                variant="caption"
                style={{color: 'red', marginLeft: 6}}
              >
                Please fill all fields in each boxes
              </Typography>
            )}
          </div>

          <Grid container spacing={4} style={{margin: 0, minHeight: '104vh'}}>
            <Grid
              container
              item
              xs={5}
              style={{
                paddingLeft: 0,
                height: 'fit-content',
                flexWrap: 'noWrap',
              }}
            >
              <BoxesDiagram
                open={open}
                setOpen={setOpen}
                setValues={setValues}
                values={values}
                mainValues={mainValues}
                isReset={isEditPerformance}
                resetFunction={handleClickReset}
              />
            </Grid>

            <Grid container item xs={7}>
              {open && (
                <AddDetailBox
                  classes={classes}
                  selectedX={selectedX}
                  selectedY={selectedY}
                  values={mainValues}
                  setValues={setMainValues}
                  setOpen={setOpen}
                  colorOptions={colorOptions}
                  setColorOptions={setColorOptions}
                  handleCloseDetail={handleCloseDetail}
                  isTalent={isTalent}
                  // mutationUpdateBoxes={mutationUpdateBoxes}
                ></AddDetailBox>
              )}
            </Grid>
          </Grid>
        </Fragment>
      )}
    </>
  )
}

export default StepTwo
