import gql from 'graphql-tag'

export const GET_DIRECTORY_EMPLOYEE = gql`
  query(
    $search: String
    $jobProfile: [Int!]
    $organization: [Int!]
    $designation: [Int!]
    $level: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $status: String
    $willJoin: date
    $activeEmployee: date
    $employeeType: [String!]
    $timeArrangement: [String!]
    $workArrangement: [String!]
    $salaryFrom: float8
    $salaryTo: float8
    $birthFrom: date
    $birthTo: date
    $gender: [enum_gender!]
    $religion: [Int!] #
    $education: [Int!] #
    $replacement_status: Boolean
  ) {
    total: people_work_placements_aggregate(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        company_job_profile: {
          id: {_in: $jobProfile}
          organization: {_in: $organization}
          position: {_in: $designation}
          rank: {_in: $level}
          office_address: {_in: $office}
        }
        replacement_status: {_eq: $replacement_status}
        status: {_eq: $status}
        start_date: {_lte: $activeEmployee, _gt: $willJoin}
        type: {_in: $employeeType}
        type_time: {_in: $timeArrangement}
        type_place: {_in: $workArrangement}
        current_salary: {_gte: $salaryFrom, _lte: $salaryTo}
        global_user: {
          people_profiles: {
            birthdate: {_gte: $birthFrom, _lte: $birthTo}
            gender: {_in: $gender}
            religion: {_in: $religion}
          }
          people_profile_educations: {degree: {_in: $education}}
        }
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_placements(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        company_job_profile: {
          id: {_in: $jobProfile}
          organization: {_in: $organization}
          position: {_in: $designation}
          rank: {_in: $level}
          office_address: {_in: $office}
        }
        replacement_status: {_eq: $replacement_status}
        status: {_eq: $status}
        start_date: {_lte: $activeEmployee, _gt: $willJoin}
        type: {_in: $employeeType}
        type_time: {_in: $timeArrangement}
        type_place: {_in: $workArrangement}
        current_salary: {_gte: $salaryFrom, _lte: $salaryTo}
        global_user: {
          people_profiles: {
            birthdate: {_gte: $birthFrom, _lte: $birthTo}
            gender: {_in: $gender}
            religion: {_in: $religion}
          }
          people_profile_educations: {degree: {_in: $education}}
        }
      }
      offset: $offset
      limit: $limit
      order_by: {global_user: {name: asc}}
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
      type
      type_time
      type_place
      start_date
      end_date
      date_added
      status
      termination_status: global_placement_termination {
        id
        name
      }
    }
  }
`
export const GET_DIRECTORY_EMPLOYEE_WT_EDUCATION = gql`
  query(
    $search: String
    $jobProfile: [Int!]
    $organization: [Int!]
    $job_title: [Int!]
    $level: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $status: String
    $willJoin: date
    $activeEmployee: date
    $employeeType: [String!]
    $timeArrangement: [String!]
    $workArrangement: [String!]
    $salaryFrom: float8
    $salaryTo: float8
    $isFilterSalary: Boolean
    $birthFrom: date
    $birthTo: date
    $isFilterBirth: Boolean
    $gender: [enum_gender!]
    $religion: [Int!]
    $education: [uuid!]
    $isFilterEducation: Boolean
    $job_grades: [Int!]
    $organization_level: [Int!]
    $hobbies: [Int!]
    $isFilterHobbies: Boolean
    $job_family: [Int!]
    $EXC_jobProfile: [Int!]
    $EXC_organization: [Int!]
    $EXC_job_title: [Int!]
    $EXC_level: [Int!]
    $EXC_office: [Int!]
    $EXC_job_grades: [Int!]
    $EXC_organization_level: [Int!]
    $EXC_employeeType: [String!]
    $EXC_timeArrangement: [String!]
    $EXC_workArrangement: [String!]
    $EXC_gender: [enum_gender!]
    $EXC_religion: [Int!]
    $EXC_job_family: [Int!]
    $EXC_education: [uuid!]
    $EXC_hobbies: [Int!]
    $EXC_salaryFrom: float8
    $EXC_salaryTo: float8
    $isFilterSalaryExc: Boolean
    $EXC_birthFrom: date
    $EXC_birthTo: date
    $isFilterExcBirth: Boolean
    $replacement_status: Boolean
    $isTerminationNotNull: Boolean
    $global_user_status_filter: enum_global_users_status
    $badges: [Int!]
    $EXC_badges: [Int!]
    $talentScoreFrom: float8
    $talentScoreTo: float8
    $EXC_talentScoreFrom: float8
    $EXC_talentScoreTo: float8
    $isFilterTalent: Boolean
  ) {
    total_no_filter: people_work_placements_aggregate(
      where: {
        company_job_profile: {
          id: {_in: null, _nin: null}
          organization: {_in: null, _nin: null}
          position: {_in: null, _nin: null}
          rank: {_in: null, _nin: null}
          office_address: {_in: null, _nin: null}
          job_grade_id: {_in: null, _nin: null}
          company_organization: {organization_level_id: {_in: null, _nin: null}}
          company_employee_position: {job_mapping_id: {_in: null, _nin: null}}
        }
        status: {_eq: $status}
        global_placement_termination_id: {_is_null: $isTerminationNotNull}
        replacement_status: {_eq: $replacement_status}
        start_date: {_lte: $activeEmployee, _gt: $willJoin}
        type: {_in: null, _nin: null}
        type_time: {_in: null, _nin: null}
        type_place: {_in: null, _nin: null}
        global_user: {
          status: {_eq: $global_user_status_filter}
          people_profiles: {
            id: {_is_null: false}
            gender: {_in: null, _nin: null}
            religion: {_in: null, _nin: null}
          }
          _and: [
            {
              _or: [
                {
                  _and: [
                    {
                      people_profile_educations: {
                        school: {_in: null, _nin: null}
                      }
                    }
                    {_not: {id: {_is_null: false}}}
                  ]
                }
                {id: {_is_null: false}}
              ]
            }
            {
              _or: [
                {
                  _and: [
                    {people_profile_hobbies: {hobby: {_in: null, _nin: null}}}
                    {_not: {id: {_is_null: false}}}
                  ]
                }
                {id: {_is_null: false}}
              ]
            }
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    total: people_work_placements_aggregate(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        company_job_profile: {
          id: {_in: $jobProfile, _nin: $EXC_jobProfile}
          organization: {_in: $organization, _nin: $EXC_organization}
          position: {_in: $job_title, _nin: $EXC_job_title}
          rank: {_in: $level, _nin: $EXC_level}
          office_address: {_in: $office, _nin: $EXC_office}
          job_grade_id: {_in: $job_grades, _nin: $EXC_job_grades}
          company_organization: {
            organization_level_id: {
              _in: $organization_level
              _nin: $EXC_organization_level
            }
          }
          company_employee_position: {
            job_mapping_id: {_in: $job_family, _nin: $EXC_job_family}
          }
        }
        replacement_status: {_eq: $replacement_status}
        global_placement_termination_id: {_is_null: $isTerminationNotNull}
        status: {_eq: $status}
        start_date: {_lte: $activeEmployee, _gt: $willJoin}
        type: {_in: $employeeType, _nin: $EXC_employeeType}
        type_time: {_in: $timeArrangement, _nin: $EXC_timeArrangement}
        type_place: {_in: $workArrangement, _nin: $EXC_workArrangement}
        _and: [
          {
            _or: [
              {
                _and: [
                  {current_salary: {_gte: $salaryFrom, _lte: $salaryTo}}
                  {_not: {id: {_is_null: $isFilterSalary}}}
                ]
              }
              {id: {_is_null: $isFilterSalary}}
            ]
          }
          {
            _or: [
              {
                _and: [
                  {
                    _not: {
                      current_salary: {
                        _gte: $EXC_salaryFrom
                        _lte: $EXC_salaryTo
                      }
                    }
                  }
                  {_not: {id: {_is_null: $isFilterSalaryExc}}}
                ]
              }
              {id: {_is_null: $isFilterSalaryExc}}
            ]
          }
        ]
        global_user: {
          status: {_eq: $global_user_status_filter}
          people_profiles: {
            _and: [
              {
                _or: [
                  {
                    _and: [
                      {birthdate: {_gte: $birthFrom, _lte: $birthTo}}
                      {_not: {id: {_is_null: $isFilterBirth}}}
                    ]
                  }
                  {id: {_is_null: $isFilterBirth}}
                ]
              }
              {
                _or: [
                  {
                    _and: [
                      {
                        _not: {
                          birthdate: {_gte: $EXC_birthFrom, _lte: $EXC_birthTo}
                        }
                      }
                      {_not: {id: {_is_null: $isFilterExcBirth}}}
                    ]
                  }
                  {id: {_is_null: $isFilterExcBirth}}
                ]
              }
            ]
            gender: {_in: $gender, _nin: $EXC_gender}
            religion: {_in: $religion, _nin: $EXC_religion}
          }
          _and: [
            {
              _or: [
                {
                  _and: [
                    {
                      people_profile_educations: {
                        school: {_in: $education, _nin: $EXC_education}
                      }
                    }
                    {_not: {id: {_is_null: $isFilterEducation}}}
                  ]
                }
                {id: {_is_null: $isFilterEducation}}
              ]
            }
            {
              _or: [
                {
                  _and: [
                    {
                      people_profile_hobbies: {
                        hobby: {_in: $hobbies, _nin: $EXC_hobbies}
                      }
                    }
                    {_not: {id: {_is_null: $isFilterHobbies}}}
                  ]
                }
                {id: {_is_null: $isFilterHobbies}}
              ]
            }
            {
              _or: [
                {
                  _and: [
                    {
                      talent_assignments: {
                        badge_classification: {_in: $badges, _nin: $EXC_badges}
                        _and: [
                          {
                            talent_score: {
                              _gte: $talentScoreFrom
                              _lte: $talentScoreTo
                            }
                          }
                          {
                            _not: {
                              talent_score: {
                                _gte: $EXC_talentScoreFrom
                                _lte: $EXC_talentScoreTo
                              }
                            }
                          }
                        ]
                      }
                    }
                    {_not: {id: {_is_null: $isFilterTalent}}}
                  ]
                }
                {id: {_is_null: $isFilterTalent}}
              ]
            }
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        company_job_profile: {
          id: {_in: $jobProfile, _nin: $EXC_jobProfile}
          organization: {_in: $organization, _nin: $EXC_organization}
          position: {_in: $job_title, _nin: $EXC_job_title}
          rank: {_in: $level, _nin: $EXC_level}
          office_address: {_in: $office, _nin: $EXC_office}
          job_grade_id: {_in: $job_grades, _nin: $EXC_job_grades}
          company_organization: {
            organization_level_id: {
              _in: $organization_level
              _nin: $EXC_organization_level
            }
          }
          company_employee_position: {
            job_mapping_id: {_in: $job_family, _nin: $EXC_job_family}
          }
        }
        replacement_status: {_eq: $replacement_status}
        global_placement_termination_id: {_is_null: $isTerminationNotNull}
        status: {_eq: $status}
        start_date: {_lte: $activeEmployee, _gt: $willJoin}
        type: {_in: $employeeType, _nin: $EXC_employeeType}
        type_time: {_in: $timeArrangement, _nin: $EXC_timeArrangement}
        type_place: {_in: $workArrangement, _nin: $EXC_workArrangement}
        _and: [
          {
            _or: [
              {
                _and: [
                  {current_salary: {_gte: $salaryFrom, _lte: $salaryTo}}
                  {_not: {id: {_is_null: $isFilterSalary}}}
                ]
              }
              {id: {_is_null: $isFilterSalary}}
            ]
          }
          {
            _or: [
              {
                _and: [
                  {
                    _not: {
                      current_salary: {
                        _gte: $EXC_salaryFrom
                        _lte: $EXC_salaryTo
                      }
                    }
                  }
                  {_not: {id: {_is_null: $isFilterSalaryExc}}}
                ]
              }
              {id: {_is_null: $isFilterSalaryExc}}
            ]
          }
        ]
        global_user: {
          status: {_eq: $global_user_status_filter}
          people_profiles: {
            _and: [
              {
                _or: [
                  {
                    _and: [
                      {birthdate: {_gte: $birthFrom, _lte: $birthTo}}
                      {_not: {id: {_is_null: $isFilterBirth}}}
                    ]
                  }
                  {id: {_is_null: $isFilterBirth}}
                ]
              }
              {
                _or: [
                  {
                    _and: [
                      {
                        _not: {
                          birthdate: {_gte: $EXC_birthFrom, _lte: $EXC_birthTo}
                        }
                      }
                      {_not: {id: {_is_null: $isFilterExcBirth}}}
                    ]
                  }
                  {id: {_is_null: $isFilterExcBirth}}
                ]
              }
            ]
            gender: {_in: $gender, _nin: $EXC_gender}
            religion: {_in: $religion, _nin: $EXC_religion}
          }
          _and: [
            {
              _or: [
                {
                  _and: [
                    {
                      people_profile_educations: {
                        school: {_in: $education, _nin: $EXC_education}
                      }
                    }
                    {_not: {id: {_is_null: $isFilterEducation}}}
                  ]
                }
                {id: {_is_null: $isFilterEducation}}
              ]
            }
            {
              _or: [
                {
                  _and: [
                    {
                      people_profile_hobbies: {
                        hobby: {_in: $hobbies, _nin: $EXC_hobbies}
                      }
                    }
                    {_not: {id: {_is_null: $isFilterHobbies}}}
                  ]
                }
                {id: {_is_null: $isFilterHobbies}}
              ]
            }
            {
              _or: [
                {
                  _and: [
                    {
                      talent_assignments: {
                        badge_classification: {_in: $badges, _nin: $EXC_badges}
                        _and: [
                          {
                            talent_score: {
                              _gte: $talentScoreFrom
                              _lte: $talentScoreTo
                            }
                          }
                          {
                            _not: {
                              talent_score: {
                                _gte: $EXC_talentScoreFrom
                                _lte: $EXC_talentScoreTo
                              }
                            }
                          }
                        ]
                      }
                    }
                    {_not: {id: {_is_null: $isFilterTalent}}}
                  ]
                }
                {id: {_is_null: $isFilterTalent}}
              ]
            }
          ]
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
    }
  }
`

export const GET_DIRECTORY_EMPLOYEE_LIST_DETAIL = gql`
  query($id: [Int!]!) {
    people_work_placements(
      where: {id: {_in: $id}}
      distinct_on: [regno]
      order_by: [{regno: asc}, {id: desc}]
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
        email
        status
      }
      company_job_profile {
        id
        title
      }
      type
      type_time
      type_place
      start_date
      end_date
      date_added
      status
      termination_status: global_placement_termination {
        id
        name
      }
    }
  }
`

export const GET_VERIFYDATA = gql`
  query getUnverifiedList($user: uuid!) {
    people_profile(where: {id: {_eq: $user}}) {
      is_verified
      unverified_list
    }
  }
`
export const SET_VERIFIY = gql`
  mutation($user: uuid!, $status: Boolean!) {
    update_people_profile(
      _set: {is_verified: $status, unverified_list: []}
      where: {id: {_eq: $user}}
    ) {
      affected_rows
    }
  }
`

export const GET_DIRECTORY_EMPLOYEE_DETAIL = gql`
  query GetDirectoryEmployeeDetail($user: uuid!, $offset: Int, $limit: Int) {
    global_users(where: {id: {_eq: $user}}) {
      id
      name
      avatar
      email
      main_education
      people_profiles {
        gender
        birthdate
        birth_city: global_city {
          id
          name
        }
        birth_province: global_province {
          id
          name
        }
        global_religion {
          id
          name
        }
        marital_status
        blood_type
        profile_fields
      }
    }

    profile_about: people_profile(
      where: {id: {_eq: $user}, deletedAt: {_is_null: true}}
    ) {
      id
      gender
      global_city {
        name
      }
      birthdate
      marital_status
      blood_type
      profile_fields
      global_religion {
        id
        name
      }
    }

    address: people_addresses(
      where: {user: {_eq: $user}}
      order_by: {id: asc}
      offset: $offset
      limit: $limit
    ) {
      id
      name
      global_province {
        id
        name
      }
      global_city {
        id
        name
      }
      address
      postal_code
      address_fields
    }

    phone: people_phones(where: {user: {_eq: $user}}, order_by: {id: asc}) {
      id
      country_code
      phone
    }

    working: people_work_placements(
      where: {
        user: {_eq: $user}
        status: {_in: ["ACTIVE", "REGISTERED", "INACTIVE"]}
        deletedAt: {_is_null: true}
      }
      order_by: [{id: desc}]
      limit: 1
    ) {
      id
      end_date
      placement_fields
      termination_status: global_placement_termination {
        id
        name
      }
      company_job_profile {
        id
        title
        company_address {
          id
          office_name
          global_province {
            id
            name
          }
          global_city {
            id
            name
          }
        }
      }
    }

    people_profile_experiences(
      where: {user: {_eq: $user}, deletedAt: {_is_null: true}}
      order_by: {id: desc}
      offset: $offset
      limit: $limit
    ) {
      id
      title
      description
      is_present
      is_share
      company_name
      company_profile {
        id
        brand_name
        legal_name
        logo
      }
      location
      from
      to
      decree
      achievements
      initial_salary
      last_salary
      company_name
      referal_name
      referal_position
      additional_fields
      reason_to_leave
      company_line_of_business
      is_masterpiece
    }

    people_profile_projects(
      where: {_and: [{user: {_eq: $user}}, {deletedAt: {_is_null: true}}]}
      order_by: {id: desc}
    ) {
      id
      is_current
      is_share
      project_name
      creators
      location
      start_date
      end_date
      description
      associate_user {
        id
        name
        people_work_placements(
          where: {
            _and: [{status: {_eq: "ACTIVE"}}, {deletedAt: {_is_null: true}}]
          }
        ) {
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
      project_url
      position
    }

    organization_activities: people_profile_organization_activities(
      where: {_and: [{user_id: {_eq: $user}}, {deletedAt: {_is_null: true}}]}
      order_by: [{start_date: desc}]
    ) {
      id
      organization_name
      organization_type
      member_id
      position_name
      position_description
      start_date
      end_date
      country
      province: global_province {
        id
        name
      }
      city: global_city {
        id
        name
      }
      is_shared_with_network
      logo
    }

    people_profile_educations(
      where: {
        _and: [
          {user: {_eq: $user}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {
                company_profile: {
                  _and: [
                    {types: {_ilike: "_1%"}}
                    {deletedAt: {_is_null: true}}
                  ]
                }
              }
              {other_school: {_is_null: false}}
            ]
          }
        ]
      }
      order_by: [{from: desc_nulls_last}, {to: desc_nulls_first}]
    ) {
      id
      is_present
      company_profile {
        id
        brand_name
        legal_name
        logo
        company_address {
          id
          office_name
          global_city {
            id
            name
          }
          global_province {
            id
            name
          }
          address_field
        }
      }
      from
      to
      description
      other_school
      global_faculty {
        id
        name
      }
      global_degree {
        id
        name
      }
      global_major {
        id
        name
      }
      education_fields
    }

    people_profile_certificates(
      where: {user: {_eq: $user}, deletedAt: {_is_null: true}}
      offset: $offset
      limit: $limit
      order_by: {id: desc}
    ) {
      id
      name
      company_profile {
        id
        brand_name
        legal_name
        logo
      }
      date_issued
      date_expired
      can_expire
      credential_id
      credential_url
      issuer_name
      type
      level
      description
      attachments
      is_shared
    }
    people_profile_speaker(
      where: {_and: [{user: {_eq: $user}}, {deletedAt: {_is_null: true}}]}
      order_by: {id: desc}
    ) {
      id
      event_name
      location
      organizer
      roles
      scale
      total_participants
      description
      is_share
    }
    people_profile_hobbies(
      where: {_and: [{user: {_eq: $user}}, {deletedAt: {_is_null: true}}]}
    ) {
      id
      hobby
      people_hobby {
        id
        icon
        name
        hobby_category {
          id
          name
        }
        hobby_sub_category {
          id
          name
        }
      }
      hobby_fields
      description
      people_profile_hobby_endorsements(limit: 5) {
        id
        global_user {
          id
          name
          avatar
        }
        comment
      }
    }

    people_profile_skills(
      where: {user: {_eq: $user}, deletedAt: {_is_null: true}}
      order_by: {id: desc}
    ) {
      id
      description
      level
      skill_fields
      is_share
      people_skill_level {
        id
        name
      }
      people_skill {
        id
        name
        people_profile_skills(
          where: {_and: [{user: {_neq: $user}}, {deletedAt: {_is_null: true}}]}
          limit: 4
          order_by: {id: desc}
        ) {
          id
          user
          global_user {
            id
            name
            avatar
          }
        }
        people_profile_skills_aggregate(
          where: {
            _and: [
              {user: {_neq: $user}}
              {deletedAt: {_is_null: true}}
              {is_share: {_eq: true}}
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
      endorsements_skill_count: people_profile_skill_endorsements_aggregate {
        aggregate {
          count
        }
      }
    }

    people_profile_awards(
      where: {_and: [{user: {_eq: $user}}, {deletedAt: {_is_null: true}}]}
      order_by: {id: desc}
    ) {
      id
      award_name
      company_profile {
        id
        legal_name
        brand_name
        logo
      }
      issuing_organization
      reference_date
      reference_number
      letter_number
      certificate_number
      remark
      attachments
      is_share
    }

    people_profile_publications(
      where: {_and: [{user: {_eq: $user}}, {deletedAt: {_is_null: true}}]}
      order_by: {id: desc}
    ) {
      id
      is_share
      publication_title
      authors
      publication_media
      publication_date
      description
      publication_url
      attachments
    }

    people_profile_service_agreements(
      where: {_and: [{user: {_eq: $user}}, {deletedAt: {_is_null: true}}]}
      order_by: {id: desc}
    ) {
      id
      is_share
      code
      name
      start_date
      end_date
      description
      attachments
    }

    people_profile_disciplinary(
      where: {_and: [{user: {_eq: $user}}, {deletedAt: {_is_null: true}}]}
      order_by: {id: desc}
    ) {
      id
      discipline_name
      reference_number
      start_date
      end_date
      discipline_letter_number
      reference_date
      remark
      attachments
      is_share
    }

    people_profile_ohsa(
      where: {_and: [{user: {_eq: $user}}, {deletedAt: {_is_null: true}}]}
      order_by: {id: desc}
    ) {
      id
      company_name
      company_location
      category
      employees: global_user {
        name
      }
      incident_location
      incident_date_time
      severity
      description
      incident_code
      attachments
      is_share
    }

    recommendation_to: people_profile_recommendations(
      where: {recommendation_to: {_eq: $user}, deletedAt: {_is_null: true}}
      order_by: [{id: desc}]
    ) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
      position
      relationship
      description
      status
      date_added
      last_modified
    }
  }
`
export const GET_DETAIL_OFFICEMATE = gql`
  query($user: uuid!) {
    subordinate: people_work_placements(
      where: {
        _and: [
          {
            company_job_profile: {
              job_profile_supervisor: {
                people_work_placements: {
                  _and: [
                    {user: {_eq: $user}}
                    {status: {_eq: "ACTIVE"}}
                    {deletedAt: {_is_null: true}}
                  ]
                }
              }
            }
          }
          {status: {_eq: "ACTIVE"}}
          {deletedAt: {_is_null: true}}
        ]
      }
      order_by: {global_user: {name: asc}}
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }

    working: people_work_placements(
      where: {
        _and: [
          {user: {_eq: $user}}
          {deletedAt: {_is_null: true}}
          {status: {_eq: "ACTIVE"}}
        ]
      }
      order_by: {id: desc}
    ) {
      id
      company_job_profile {
        id
        supervisor: job_profile_supervisor {
          id
          title
          people_work_placements(
            where: {
              _and: [
                {global_user: {deletedAt: {_is_null: true}}}
                {status: {_eq: "ACTIVE"}}
                {deletedAt: {_is_null: true}}
              ]
            }
            order_by: [{global_user: {name: desc}}]
            limit: 1
          ) {
            global_user {
              id
              name
              avatar
            }
          }
        }
      }
    }
  }
`

export const LIST_RECOMMENDATION_SENT = gql`
  query($user: uuid!) {
    people_profile_recommendations(
      where: {recommendation_from: {_eq: $user}, deletedAt: {_is_null: true}}
      order_by: [{id: desc}]
    ) {
      id
      globalUserByRecommendationTo {
        id
        name
        avatar
        # people_work_placements(where: { status: { _eq: "ACTIVE" } }) {
        #   company_job_profile {
        #     title
        #   }
        # }
      }
      position
      relationship
      description
      status
      date_added
      last_modified
    }
  }
`

export const GET_DEPENDANTS = gql`
  query($userId: uuid!) {
    people_dependants(
      where: {user: {_eq: $userId}, deletedAt: {_is_null: true}}
      order_by: [{id: desc}]
    ) {
      id
      name
      relationship
      birthplace
      birthdate
      identity_number
      marital_status
      working_status
      dependant_fields
      gender
      is_beneficiary
      civil_servant
      is_company_group_employee
      company
      blood_type
      global_country {
        id
        name: country_name
      }
      global_province {
        id
        name
      }
      global_city {
        id
        name
      }
      district
      sub_district
      postal_code
      address
      dependent_status
    }
  }
`

export const GET_DEPENDANTS_SEARCH = gql`
  query(
    $userId: uuid!
    $search: String
    $id_nin: [Int!]
    $offset: Int
    $limit: Int
  ) {
    dependants: people_dependants(
      where: {
        id: {_nin: $id_nin}
        user: {_eq: $userId}
        deletedAt: {_is_null: true}
        _or: [{name: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      relationship
    }
  }
`

export const GET_EMERGENCY_CONTACTS = gql`
  query($user: uuid!) {
    people_emergency_contacts(
      where: {user: {_eq: $user}, deletedAt: {_is_null: true}}
      order_by: {id: asc}
    ) {
      id
      name
      relationship
      country_code
      phone
      address
      emergency_contact_fields
    }
  }
`

// NOTE(intrnl): this is a workaround for `people_emergency_contacts` having
// unusual fields for the location
export const GET_EMERGENCY_CONTACT_LOCATION_ = gql`
  query(
    $countryName_in: [String!]
    $provinceId_in: [Int!]
    $cityId_in: [Int!]
  ) {
    countries: global_country(where: {country_name: {_in: $countryName_in}}) {
      id
      code: country_code
      name: country_name
    }
    provinces: global_provinces(where: {id: {_in: $provinceId_in}}) {
      id
      code
      name
    }
    cities: global_cities(where: {id: {_in: $cityId_in}}) {
      id
      code
      name
    }
  }
`

export const CHECK_DUPLICATE_IDENTITY_CARD = gql`
  query($userId: uuid!, $id: String!, $type: String) {
    people_identities_aggregate(
      where: {
        user: {_eq: $userId}
        id: {_eq: $id}
        type: {_eq: $type}
        deletedAt: {_is_null: true}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_IDENTITY_CARDS = gql`
  query($userId: uuid!) {
    people_identities(
      where: {
        _and: [
          {user: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
          {
            type: {
              _in: [
                "KTP"
                "SIM A"
                "SIM B1"
                "SIM B2"
                "SIM C"
                "SIM D"
                "NPWP"
                "PASSPORT"
              ]
            }
          }
        ]
      }
      order_by: [{date_added: desc_nulls_last}]
    ) {
      number: id
      date_added
      type
      identity_fields
    }
  }
`

export const GET_KTP_NPWP_FROM_LATEST_SALARY = gql`
  query($user: uuid!) {
    people_work_salaries(
      where: {people_work_placement: {user: {_eq: $user}}}
      order_by: [{id: desc}]
      limit: 2
    ) {
      id
      ktp
      npwp
    }
  }
`

export const GET_PROVINCE_AND_CITY = gql`
  query($province: Int, $city: Int) {
    global_provinces(where: {id: {_eq: $province}}) {
      id
      name
    }

    global_cities(where: {id: {_eq: $city}}) {
      id
      name
    }
  }
`

export const GET_GLOBAL_PHONE_CODE = gql`
  query {
    global_phone_code(order_by: [{country: asc}]) {
      id
      country
      code
    }
  }
`

export const GET_SALARY_EMPLOYEE = gql`
  query($id: Int) {
    people_work_placements(where: {id: {_eq: $id}}) {
      id
      current_salary
    }
  }
`
export const GET_BALOON_DOWNLOAD_DIRECTORY = gql`
  query($company: uuid, $table: String) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        _or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]
      }
      limit: 1
    ) {
      id
      status
      data_url
      date_added
    }
  }
`

export const GET_BALOON_DOWNLOAD_DIRECTORY_QUERY = gql`
  query($company: uuid, $table: String) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        _or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]
      }
      limit: 1
      order_by: [{id: desc}]
    ) {
      id
      status
      data_url
      date_added
    }
  }
`
export const GET_STATUS_BUTTON_DONWLOAD = gql`
  query($company: uuid, $table: String) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        status: {_neq: "FAILED"}
      }
      limit: 1
    ) {
      date_added
    }
  }
`
export const GET_STATUS_BUTTON_DONWLOAD_EMPLOYEE_REPORT = gql`
  query(
    $company: uuid
    $table: String
    $today: timestamptz
    $tomorrow: timestamptz
  ) {
    company_export_data_logs(
      where: {
        _and: [
          {company: {_eq: $company}}
          {table_name: {_eq: $table}}
          {date_added: {_gte: $today}}
          {date_added: {_lt: $tomorrow}}
          {status: {_neq: "FAILED"}}
        ]
      }
      order_by: [{id: desc}]
    ) {
      date_added
      filter
    }
  }
`

export const GET_STATUS_BUTTON_DONWLOAD_QUERY = gql`
  query($company: uuid, $table: String) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        status: {_neq: "FAILED"}
      }
      limit: 1
      order_by: [{id: desc}]
    ) {
      date_added
    }
  }
`

export const GET_BALOON_DOWNLOAD_SUMMARY = gql`
  query(
    $company: uuid
    $table: String
    $today: timestamptz
    $tomorrow: timestamptz
  ) {
    company_export_data_logs(
      where: {
        _and: [
          {company: {_eq: $company}}
          {table_name: {_eq: $table}}
          {date_added: {_gte: $today}}
          {date_added: {_lt: $tomorrow}}
          {_or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]}
        ]
      }
      order_by: [{id: desc}]
    ) {
      id
      table_name
      filter
      status
      data_url
      date_added
    }
  }
`

export const GET_WORKING_STATUS = gql`
  query getWorkingStatus($id: uuid!) {
    working: people_work_placements(
      where: {
        _and: [
          {user: {_eq: $id}}
          {deletedAt: {_is_null: true}}
          {status: {_in: ["ACTIVE", "INACTIVE", "REGISTERED"]}}
        ]
      }
      order_by: [{id: desc}]
      limit: 1
    ) {
      id
      company
      regno
      email
      type
      type_time
      type_place
      start_date
      decree: reference
      join_date
      end_date
      decree: reference
      attachment
      permanent_date
      pre_retirement_date
      global_user {
        id
        date_added
      }
      company_logo: company_profile {
        id
        logo
      }
      company_job_profile {
        id
        title
        roles
        company_organization {
          id
          name
        }
        company_employee_rank {
          id
          name
        }
        company_address {
          id
          office_name
          global_province {
            id
            name
          }
          global_city {
            id
            name
          }
        }
        supervisor: job_profile_supervisor {
          id
          title
          people_work_placements(
            where: {
              _and: [
                {global_user: {deletedAt: {_is_null: true}}}
                {status: {_eq: "ACTIVE"}}
                {deletedAt: {_is_null: true}}
              ]
            }
            limit: 1
            order_by: {global_user: {name: asc}}
          ) {
            global_user {
              id
              name
              avatar
            }
          }
        }
        subordinate: job_profile_children {
          id
          title
          people_work_placements(
            where: {
              _and: [
                {
                  company_job_profile: {
                    job_profile_supervisor: {
                      people_work_placements: {
                        _and: [
                          {user: {_eq: $id}}
                          {status: {_eq: "ACTIVE"}}
                          {deletedAt: {_is_null: true}}
                        ]
                      }
                    }
                  }
                }
                {status: {_eq: "ACTIVE"}}
                {deletedAt: {_is_null: true}}
              ]
            }
          ) {
            global_user {
              id
              name
              avatar
            }
          }
        }
      }
    }
  }
`

export const GET_PROJECT_CREATOR_LIST = gql`
  query GetProjectCreatorList($search: String, $limit: Int, $nin: [uuid!]) {
    creator_user: global_users(
      where: {
        deletedAt: {_is_null: true}
        status: {_eq: "ACTIVE"}
        name: {_like: $search}
      }
      limit: $limit
      order_by: {name: asc}
    ) {
      id
      name
      status
    }

    creator_company: company_profiles(
      where: {
        deletedAt: {_is_null: true}
        status: {_eq: "ACTIVE"}
        legal_name: {_like: $search}
        id: {_nin: $nin}
      }
      limit: $limit
      order_by: {legal_name: asc}
    ) {
      id
      legal_name
    }
  }
`

export const GET_PROJECT_ASSOCIATE_LIST = gql`
  query GetProjectAssociateList($search: String, $limit: Int) {
    global_users(
      where: {
        _and: [
          {name: {_ilike: $search}}
          {status: {_eq: "ACTIVE"}}
          {deletedAt: {_is_null: true}}
        ]
      }
      limit: $limit
      order_by: {name: asc}
    ) {
      id
      name
      status
    }
  }
`

export const GET_PEOPLE_WITH_SAME_SKILL = gql`
  query GetPeopleWithSameSkill(
    $user: uuid
    $search: String
    $limit: Int
    $skill: Int
  ) {
    people_profile_skills(
      where: {
        _and: [
          {user: {_eq: $user}}
          {skill: {_eq: $skill}}
          {deletedAt: {_is_null: true}}
        ]
      }
      order_by: {id: desc}
    ) {
      id
      people_skill {
        id
        name
        people_profile_skills(
          where: {
            _and: [
              {user: {_neq: $user}}
              {deletedAt: {_is_null: true}}
              {is_share: {_eq: true}}
            ]
            _or: {global_user: {name: {_ilike: $search}}}
          }
          limit: $limit
          order_by: {id: desc}
        ) {
          id
          user
          global_user {
            id
            name
            avatar
            people_profile_experiences(
              where: {
                deletedAt: {_is_null: true}
                position_name: {_is_null: false}
              }
            ) {
              id
              position_name
              company_name
            }
          }
        }
        people_count: people_profile_skills_aggregate(
          where: {
            _and: [
              {user: {_neq: $user}}
              {deletedAt: {_is_null: true}}
              {is_share: {_eq: true}}
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`
