import {getCookie} from '../utils/helpers'

export const userData = getCookie('userData')
  ? JSON.parse(getCookie('userData'))
  : []
let access_token = ''
let company_id = ''
let company_name = ''
let company_logo = ''
let user_id = ''
let user_name = ''
let position_id = ''
let position_name = ''
let roles = []
if (userData && userData.oauth) {
  company_id = userData.company_id
  company_name = userData.company_name
  company_logo = userData.company_logo
  access_token = userData.oauth.access_token
  user_id = userData.id
  user_name = userData.name
  position_id = userData.position_id
  position_name = userData.position_name
  roles = userData.roles
}
const {
  REACT_APP_GRAPHQL_URL,
  // REACT_APP_GRAPHQL_URL_WS,
  REACT_APP_API_URL,
  REACT_APP_API_SOSMED_URL,
  REACT_APP_DEPENDENT_URL,
  REACT_APP_SENTRY,
  REACT_APP_SSO,
  REACT_APP_MAIN_APP_URL,
  REACT_APP_COOKIE,
  NODE_ENV,
  REACT_APP_UPLOAD_URL,
  REACT_APP_REF_PROV_CITY,
  REACT_APP_REF_BADGES,
  REACT_APP_SENTRY_ENV,
  REACT_APP_JW_URL,
  REACT_APP_LEARNING_URL,
  REACT_APP_LANDING,
} = process.env
export const GRAPHQL_URL = REACT_APP_GRAPHQL_URL
// export const GRAPHQL_WS_URL = REACT_APP_GRAPHQL_URL_WS
export const REAST_API_URL = REACT_APP_API_URL
export const REST_API_SOSMED_URL = REACT_APP_API_SOSMED_URL
export const TOKEN = access_token
export const COMPANY_ID = company_id
export const COMPANY_NAME = company_name
export const COMPANY_LOGO = company_logo
export const ROLE = 'organization-hr-admin'
export const ROLES = roles
export const USER_ID = user_id
export const USER_NAME = user_name
export const POSITION_NAME = position_name
export const POSITION_ID = position_id
export const APP_DEPENDENT_URL = REACT_APP_DEPENDENT_URL
export const SENTRY_URL = REACT_APP_SENTRY
export const SSO_URL = REACT_APP_SSO
export const MAIN_APP_URL = REACT_APP_MAIN_APP_URL
export const ENVIRONMENT = NODE_ENV
export const APP_COOKIE = REACT_APP_COOKIE
export const UPLOAD_URL = REACT_APP_UPLOAD_URL
export const REF_PROV_CITY = REACT_APP_REF_PROV_CITY
export const REF_BADGES = REACT_APP_REF_BADGES
export const SENTRY_ENV = REACT_APP_SENTRY_ENV
export const LANDING_APP_URL = REACT_APP_LANDING

export const JW_URL = REACT_APP_JW_URL
export const LEARNING_URL = REACT_APP_LEARNING_URL
export const ALLOWED_ROLES = [
  'organization-administrator',
  'organization-hr-admin',
  'holding-administrator',
  'organization-time-administrator',
  'organization-finance-admin',
  'organization-directory-administrator',
  'organization-company-administrator',
  'organization-organization-administrator',
  'organization-learning-administrator',
  'organization-performance-administrator',
  'organization-talent-administrator',
  'organization-survey-administrator',
  'organization-document-administrator',
  'organization-support-administrator',
  // 'organization-settings-administrator',
]
export const DEFAULT_PAGINATION = [20, 50, 100]
export const IS_BRANCH_ADMIN = ROLES.find(
  element => element === 'organization-branch-administrator'
)

export const hasRole = role => ROLES.includes(role)

export const isNotSuperAdmin =
  !hasRole(ALLOWED_ROLES[0]) && !hasRole(ALLOWED_ROLES[1])

export const hasModule = (data, moduleName) =>
  data?.company_module_settings?.length === 0 ||
  data?.company_module_settings?.some(o => o.global_module.name === moduleName)
