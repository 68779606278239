import gql from 'graphql-tag'

export const GET_LOAN_POLICY = gql`
  query getLoanPolicy(
    $company: uuid!
    $id: Int
    $limit: Int
    $offset: Int
    $search: String
  ) {
    total: finance_loan_policies_aggregate(
      where: {
        company: {_eq: $company}
        deleted_at: {_is_null: true}
        _or: [{name: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }

    finance_loan_policies(
      where: {
        company: {_eq: $company}
        deleted_at: {_is_null: true}
        _or: [{name: {_ilike: $search}, id: {_eq: $id}}]
      }
      order_by: [{id: asc_nulls_last}]
      limit: $limit
      offset: $offset
    ) {
      id
      loan_max_amount
      installment
      interest_rate
      name
    }
  }
`
