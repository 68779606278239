import React, {useState} from 'react'
import {withRouter, Link} from 'react-router-dom'
import {
  Toolbar,
  IconButton,
  Divider,
  Paper,
  Typography,
  Avatar,
  TablePagination,
  Button,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {AddEditTitle, PaperContainer} from '../../../../../GlobalStyles'
import NoBadge from '../../../../../assets/images/no-badge.svg'
import {capitalize} from '../../../../../utils/helpers'
import {makeStyles} from '@material-ui/core/styles'
import ModalDetailTalent from './modal-detail/ModalDetailTalent'
import {useQuery} from '@apollo/react-hooks'
import {GET_LIST_DETAIL_SUCCESSOR} from '../../../../../graphql/queries'
import {DEFAULT_PAGINATION} from '../../../../../utils/globals'
import {Loader} from '../../LoaderSuccession'

const useStyles = makeStyles(() => ({
  paper: {
    padding: 30,
  },
  paper2: {
    padding: '20px 30px 0px 30px',
    minHeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  informationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  badgeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '6vw',
  },
  name: {
    fontSize: 16,
  },
  commite: {
    color: '#A9A8A8',
    fontSize: 14,
    margin: '0px 0px 7px 0px',
  },
  year: {
    color: '#4CAF50',
    fontSize: 10,
  },
  avatar: {
    width: '80px !important',
    height: '80px !important',
    marginRight: 30,
  },
  badge: {
    width: 40,
    margin: 'auto',
  },
  rank: {
    fontSize: 10,
    marginTop: 6,
  },
  score: {
    fontSize: 12,
    fontWeight: '600',
  },
  grey: {
    color: '#A9A8A8',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 0px',
    cursor: 'pointer',
  },
  title: {
    fontSize: 16,
    color: '#A9A8A8',
    marginBottom: 10,
  },
  pagination: {
    marginTop: 'auto',
  },
  addButton: {
    marginLeft: 'auto',
    marginRight: 20,
    padding: '9px 34px',
  },
}))

const ListSuccessor = props => {
  const {pos, userId, type} = props.location.state
  const jobProfileId = props.match.params.id

  const classes = useStyles()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGINATION[0])
  const [open, setOpen] = useState(false)
  const [dataTalent, setDataTalent] = useState({})

  const isSucessor = type === 'successor'

  const {data, loading, error, refetch} = useQuery(GET_LIST_DETAIL_SUCCESSOR, {
    fetchPolicy: 'network-only',
    variables: {
      jobProfile: userId,
      status: isSucessor ? 'SUCCESSOR' : 'TALENT',
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    },
  })

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleOpen = res => {
    setOpen(true)
    setDataTalent(res)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const statusStyle = {
    READY: '#4CAF50',
    '3-5 YEARS': '#FFA000',
  }

  if (error) {
    console.error(error)
  }

  return (
    <PaperContainer>
      <Toolbar disableGutters>
        <div onClick={() => props.history.goBack()}>
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </div>
        <AddEditTitle variant="h6" className={classes.grey}>
          {pos.title} Succession Detail
        </AddEditTitle>
        <Typography style={{marginLeft: 10}} variant="h6">
          / List {isSucessor ? 'Successor' : 'Talent Pool'}
        </Typography>
        {type === 'talent' && (
          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            component={Link}
            size="large"
            to={{
              pathname: `/talent/succession/${jobProfileId}/add-pool`,
              state: {
                active: 'add-pool',
                pos,
                back: jobProfileId,
                searchable: true,
              },
            }}
          >
            Add
          </Button>
        )}
      </Toolbar>
      <Divider />
      <Paper className={classes.paper}>
        <Paper className={classes.paper2}>
          <div>
            <Typography className={classes.title}>
              {isSucessor ? 'Successor' : 'Talent Pool'}
            </Typography>
            {loading &&
              Array.from({length: 6}, (_, idx) => (
                <Loader key={`${_}${idx}`} />
              ))}
            {!loading &&
              data?.talent_pull_successors?.map((successor, idx) => {
                const {global_user} = successor.people_work_placement || {}
                const {talent_box, talent_score} =
                  global_user?.talent_assignments?.[0] || {}

                return (
                  <div
                    key={idx}
                    className={classes.row}
                    onClick={() => handleOpen(successor)}
                  >
                    <Avatar
                      src={global_user.avatar}
                      className={classes.avatar}
                    />
                    <div className={classes.informationWrapper}>
                      <Typography className={classes.name}>
                        {global_user.name}
                      </Typography>
                      <Typography className={classes.commite}>
                        {isSucessor ? 'Successor' : 'Talent'}
                      </Typography>
                      {isSucessor && (
                        <Typography
                          style={{
                            color:
                              statusStyle[successor?.readiness_status] ||
                              '#039BE5',
                          }}
                          className={classes.years}
                        >
                          {capitalize(successor.readiness_status || '-')}
                        </Typography>
                      )}
                    </div>
                    <div className={classes.badgeWrapper}>
                      <img
                        src={talent_box?.badge || NoBadge}
                        className={classes.badge}
                        alt="badge"
                      />
                      <Typography className={classes.rank}>
                        {talent_box?.name || 'No Badge'}
                      </Typography>
                      <Typography className={classes.score}>
                        {talent_score?.toFixed(2)}
                      </Typography>
                    </div>
                  </div>
                )
              })}
          </div>
          <div className={classes.pagination}>
            <Divider />
            <TablePagination
              rowsPerPageOptions={DEFAULT_PAGINATION}
              component="div"
              count={
                data?.talent_pull_successors_aggregate.aggregate.count || 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
      </Paper>
      <ModalDetailTalent
        open={open}
        handleClose={handleClose}
        userId={dataTalent?.people_work_placement?.global_user?.id}
        placementId={dataTalent?.people_work_placement?.id}
        idJob={pos.id}
        refetch={refetch}
      />
    </PaperContainer>
  )
}

export default withRouter(ListSuccessor)
