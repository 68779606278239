import {Button, makeStyles} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  rootWrapper: {
    padding: '32px 35px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderTop: 'solid 1px #e5e5e5',
  },
  btnCalcel: {
    marginRight: 30,
    padding: '9px 27px',
  },
  btnSave: {
    padding: '9px 27px',
  },
}))

export function ButtonSubmit({disabled, onCancel, onSave}) {
  const classes = useStyles()

  return (
    <div className={classes.rootWrapper}>
      <Button
        className={classes.btnCalcel}
        disabled={disabled}
        onClick={onCancel}
      >
        Batalkan
      </Button>
      <Button
        className={classes.btnSave}
        disableElevation
        onClick={onSave}
        disabled={disabled}
        variant="contained"
        color="primary"
      >
        Simpan
      </Button>
    </div>
  )
}
