import gql from 'graphql-tag'

export const ADD_ASSESSMENT = gql`
  mutation AddAssessment(
    $objects: [people_profile_psychological_tests_insert_input!]!
  ) {
    insert_people_profile_psychological_tests(objects: $objects) {
      affected_rows
    }
  }
`

export const UPDATE_ASSESSMENT = gql`
  mutation UpdateAssessment(
    $id: Int!
    $objects: people_profile_psychological_tests_set_input!
  ) {
    update_people_profile_psychological_tests_by_pk(
      pk_columns: {id: $id}
      _set: $objects
    ) {
      id
      name
      subject
      institution
      test_date
      test_cost
      test_score
      test_score_range
      test_result
      recommendation_result
      general_conclusion
      purpose
      attachments
    }
  }
`

export const DELETE_ASSESSMENT = gql`
  mutation DeleteAssessment($id: Int!, $userId: uuid) {
    update_people_profile_psychological_tests(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {user_id: {_eq: $userId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`
