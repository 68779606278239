import React from 'react'
import {Dialog, Divider, Avatar} from '@material-ui/core'
import {ModalContentEvent, WhiteModalHeader} from '../../../../GlobalStyles'
import helperStyle, {
  TitleBlueBold,
  FlexStart,
  UserWrapper,
  TextBlackBold,
  TextContentBold,
} from '../CrossMentoringPageStyles'

const ModalAddMentor = props => {
  const classes = helperStyle()
  const {
    handleClose,
    open,
    selectedMentor,
    setSelectedMentor,
    dataMentor,
  } = props

  const handleSelectMentor = item => {
    setSelectedMentor(item)
    handleClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true}>
      <WhiteModalHeader style={{justifyContent: 'normal'}}>
        <TitleBlueBold>Select Mentor</TitleBlueBold>
      </WhiteModalHeader>
      <Divider />
      <ModalContentEvent>
        {dataMentor &&
          dataMentor.map(val => (
            <FlexStart
              onClick={() => handleSelectMentor(val)}
              className={classes.my15}
              style={{cursor: 'pointer'}}
            >
              <Avatar src={val.global_user.avatar} className={classes.mr25} />
              <UserWrapper>
                <TextBlackBold>{val.global_user.name}</TextBlackBold>
                <TextContentBold>
                  {val.company_job_profile ? val.company_job_profile.title : ''}
                </TextContentBold>
              </UserWrapper>
            </FlexStart>
          ))}
      </ModalContentEvent>
    </Dialog>
  )
}

export default ModalAddMentor
