import React from 'react'
import {Search} from '@material-ui/icons'
import {useQuery} from '@apollo/react-hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Avatar,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import ModalPopup from '../../popup/ModalPopup'
import {Flex, ModalContentEvent} from '../../../../../../GlobalStyles'
import {GET_PEOPLE_WITH_SAME_SKILL} from '../../../../../../graphql/queries'

export default function ModalPeopleSkillEndorsement({user, skill, ...rest}) {
  const LIMIT = 5

  const [search, setSearch] = React.useState('')
  const [infiniteLoad, setInfiniteLoad] = React.useState({
    limit: LIMIT,
    hasMore: true,
  })

  const {data} = useQuery(GET_PEOPLE_WITH_SAME_SKILL, {
    variables: {
      user,
      skill,
      limit: infiniteLoad.limit,
      search: `%${search}%`,
    },
    skip: !rest?.open,
  })

  const lengthDataSuggested =
    data?.people_profile_skills?.[0]?.people_skill?.people_count?.aggregate
      .count

  const fetchMoreData = () => {
    if (infiniteLoad.limit >= lengthDataSuggested) {
      setInfiniteLoad({
        ...infiniteLoad,
        hasMore: false,
      })
    }
    setTimeout(() => {
      setInfiniteLoad({
        ...infiniteLoad,
        limit: infiniteLoad.limit + LIMIT,
      })
    }, 1000)
  }

  const dataPeople =
    data?.people_profile_skills?.[0]?.people_skill?.people_profile_skills || []

  return (
    <ModalPopup {...rest} maxWidth="sm">
      <ModalContentEvent>
        <TextField
          fullWidth
          placeholder="Search"
          variant="outlined"
          onChange={event => setSearch(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment className="ml-0" position="end">
                <Search style={{color: '#014a62', width: 20, height: 20}} />
              </InputAdornment>
            ),
          }}
        />
      </ModalContentEvent>

      <div style={{maxHeight: '350px', overflow: 'auto'}} id="scrollableDiv">
        <ModalContentEvent style={{minHeight: '50px'}}>
          <InfiniteScroll
            dataLength={infiniteLoad.limit}
            scrollableTarget="scrollableDiv"
            next={fetchMoreData}
            hasMore={dataPeople?.length > 3 ? infiniteLoad.hasMore : false}
            loader={
              <div style={{textAlign: 'center'}}>
                <CircularProgress size={15} style={{marginRight: '10px'}} />
                <span>Loading</span>
              </div>
            }
          >
            {dataPeople?.map((data, index) => (
              <Flex
                key={`people-with-same-skill-${index}`}
                style={{gap: '0.8rem', padding: '0.8rem 0'}}
              >
                <Avatar aria-label="avatar" src={data?.global_user?.avatar}>
                  {data?.global_user?.name.slice(0, 1) || ''}
                </Avatar>

                <div style={{margin: '0 0.8rem'}}>
                  <Typography variant="body2">
                    {data.global_user?.name || '-'}
                  </Typography>

                  {data.global_user?.people_profile_experiences
                    ?.position_name && (
                    <Typography variant="caption" color="textSecondary">
                      {
                        data.global_user?.people_profile_experiences
                          ?.position_name
                      }
                    </Typography>
                  )}
                </div>
              </Flex>
            ))}
          </InfiniteScroll>
        </ModalContentEvent>
      </div>
    </ModalPopup>
  )
}
