import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'

import {useSnackbar} from 'notistack'
import {Button, Divider, Typography} from '@material-ui/core'
import MaterialTable from 'material-table'

import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  //   GreyTypography,
  Flex,
  SkyBlueIcon,
  GreyIcon,
} from '../../../../GlobalStyles'

import {COMPANY_ID} from '../../../../utils/globals'

import {GET_LIST_MICROLEARNING} from '../../../../graphql/queries'
import {DELETE_MICROLEARNING} from '../../../../graphql/mutations/learning/micro-learning/deleteMicrolearning.mutation'

import defaultImg from '../../../../assets/images/course-default-thumbnail.jpg'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {status_data} from '../../../shared-components/data/SharedData'

const styles = {
  avatar: {
    width: 42,
    height: 42,
    margin: '0 24px 0 0',
    borderRadius: 5,
    objectFit: 'cover',
  },
}
const ListComponent = ({search}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })
  const [openDelete, setOpenDelete] = useState(false)
  const [state, setState] = useState({
    id: '',
    name: '',
  })
  const {data, loading, error, refetch} = useQuery(GET_LIST_MICROLEARNING, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      createdByOrgId: COMPANY_ID,
      search: `%${search}%`,
    },
  })

  const [deleteMicroLearning] = useMutation(DELETE_MICROLEARNING)

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleClickOpen = row => {
    setOpenDelete(true)
    setState({
      id: row.id,
      name: row.name,
    })
  }

  const handleClose = () => {
    setOpenDelete(false)
  }

  const handleMutation = () => {
    deleteMicroLearning({
      variables: {
        microlearningId: state.id,
      },
    })
      .then(() => {
        enqueueSnackbar('Data deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(e =>
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
        })
      )
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Pembelajaran Mikro</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{pathname: '/learning/micro-learning/add'}}
          >
            Buat Pembelajaran Mikro
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider></Divider>
      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0'}}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
        columns={[
          {
            title: 'Nama Pembelajaran Mikro',
            field: 'name',
            render: row => (
              <Flex style={{width: 250}}>
                <img
                  src={row.icon_url ? row.icon_url : defaultImg}
                  style={styles.avatar}
                />
                {row.name}
              </Flex>
            ),
          },
          {
            title: 'Dibuat Pada',
            field: 'date_added',
            type: 'date',
          },
          {
            title: 'Diikuti',
            field: 'enroll_count.aggregate.count',
          },
          {
            title: 'Selesai',
            field: 'total_finished',
          },
          {
            title: 'Penilaian',
            field: 'microlearning_reviews_aggregate.aggregate.avg.star',
            render: row => (
              <Flex>
                {row.microlearning_reviews_aggregate.aggregate.avg.star ? (
                  <SkyBlueIcon style={{marginRight: 12}}>star</SkyBlueIcon>
                ) : (
                  <GreyIcon style={{marginRight: 12}}>star</GreyIcon>
                )}
                {row.microlearning_reviews_aggregate.aggregate.avg.star
                  ? row.microlearning_reviews_aggregate.aggregate.avg.star.toFixed(
                      1
                    )
                  : 0}
              </Flex>
            ),
          },
          {
            title: 'Status',
            field: 'status',
            render: row => (
              <div
                style={{
                  padding: '6px 22px 5px',
                  borderRadius: 5,
                  backgroundColor:
                    row.status === 'ACTIVE'
                      ? '#4caf50'
                      : row.status === 'PENDING'
                      ? '#ffa000'
                      : row.status === 'DRAFT'
                      ? '#a9a8a8'
                      : '#ef4d5e',
                  color: 'white',
                  fontSize: 12,
                  wordBreak: 'initial',
                  textAlign: 'center',
                  width: 85,
                }}
              >
                {row.status && status_data[row.status]}
              </div>
            ),
          },

          {
            title: 'Aksi',
            field: 'id',
            sorting: false,
            render: rowData => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={'/learning/micro-learning/detail/' + rowData.id}
                      >
                        Rincian
                      </StyledMenuItem>
                      <StyledMenuItem
                        component={Link}
                        to={'/learning/micro-learning/edit/' + rowData.id}
                        variant="h6"
                      >
                        Ubah
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          handleClickOpen(rowData)
                          popupState.close()
                        }}
                      >
                        Hapus
                      </StyledMenuItem>
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            ),
          },
        ]}
        data={data && data.microlearnings}
        title=""
        options={{
          ...TableOptions,
          selection: false,
          emptyRowsWhenPaging: false,
        }}
        components={{
          Toolbar: () => null,
        }}
        isLoading={loading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={data && data.microlearnings_aggregate.aggregate.count}
        page={pagination.offset}
        pageSize={pagination.limit}
      />
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        mutation={handleMutation}
        id={state.id}
        name={state.name}
      />
    </PaperContainer>
  )
}

export default ListComponent
