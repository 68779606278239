import React, { useState, useEffect } from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
} from '@material-ui/core'
import { PrimaryModalHeader } from '../../../GlobalStyles'
import { makeStyles } from '@material-ui/styles'
import { useDropzone } from 'react-dropzone'
import Axios from 'axios'
import styled from 'styled-components'
import Divider from '../../../components/divider/DividerStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import { useSnackbar } from 'notistack'
import { UPLOAD_URL, TOKEN } from '../../../utils/globals'

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}
const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
  },
  checkbox: { padding: 0, marginRight: 10 },
  content: { borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24 },
  btnImport: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: { paddingRight: 24, paddingBottom: 24 },
}))
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 500px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

export default function ImportPopup(props) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { open, handleClose, getData, type, setLoading } = props
  const [check, setCheck] = useState(false)
  const [icon, setIcon] = useState('')
  const [dis, setDis] = useState(true)

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone()
  const acceptFile = [
    '.webp',
    '.jpg',
    '.png',
    '.jpeg',
    '.gif',
    // '.tiff',
    // '.pjp',
    // '.pjpeg',
    '.jfif',
    // '.tif',
  ]

  useEffect(() => {
    const typeFile = acceptedFiles[0] ? acceptedFiles[0].type : ''
    const checkAcceptFile = acceptFile.find(
      file =>
        typeFile
          .toLowerCase()
          .indexOf(file.replace('.', '').toLocaleLowerCase()) > -1
    )

    if (
      (acceptedFiles[0] && acceptedFiles[0].size) < 5000000 &&
      typeof checkAcceptFile !== 'undefined'
    ) {
      setIcon(acceptedFiles[0])
      setCheck(true)
      setDis(false)
    } else if ((acceptedFiles[0] && acceptedFiles[0].size) > 5000000) {
      enqueueSnackbar('File Size too large', {
        variant: 'error',
        autoHideDuration: 3000,
      })
    } else if (
      typeof checkAcceptFile === 'undefined' &&
      acceptedFiles.length !== 0
    ) {
      enqueueSnackbar('This file types is not allowed', {
        variant: 'error',
        autoHideDuration: 3000,
      })
    }
  }, [acceptedFiles])

  const handleChange = e => {
    const files = e.target.files
    if (files[0].size > 5000000) {
      enqueueSnackbar('File Size too large', {
        variant: 'error',
        autoHideDuration: 3000,
      })
      setCheck(false)
      setDis(true)
    } else {
      setIcon(files[0])
      setCheck(true)
      setDis(false)
    }
  }

  const closeCheck = () => {
    setCheck(false)
    setIcon({})
  }

  const submitImport = async () => {
    const formData = new FormData()
    const val = icon
    formData.append('file', val)
    try {
      const response = await Axios.post(UPLOAD_URL, formData, {
        withCredentials: true,
        onUploadProgress: progressEvent => {
          const loads = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )
          setLoading(loads)
        },

        headers: {
          Authorization: 'Bearer ' + TOKEN,
          'Content-Type': 'multipart/form-data',
        },
      })
      getData(response.data.url)
      setLoading(0)
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' })
    }
    setCheck(false)
    setDis(true)
    handleClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <PrimaryModalHeader>
        <Typography variant="h6" style={{ color: '#fff' }}>
          Import {type}
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon style={{ color: '#fff' }}>close</Icon>
        </IconButton>
      </PrimaryModalHeader>
      <DialogContent className={classes.content}>
        <Typography variant="h6" display="inline">
          Choose File
        </Typography>
        <div className="container">
          {check && icon ? (
            <div
              style={{
                backgroundColor: '#f7f8f9',
                justifyContent: 'space-between',
                display: 'flex',
                height: '70px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  lineHeight: '4pt',
                }}
              >
                <div style={{ flex: '0.3' }}>
                  <FontAwesomeIcon
                    icon={faFileImage}
                    style={{
                      color: 'green',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      fontSize: '35px',
                      margin: '20px',
                    }}
                    onClick={props.onClose}
                  />
                </div>
                <div
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <p style={{ color: '#014a62' }}>{icon && icon.name}</p>
                  <p style={{ color: '#a9a8a8' }}>{icon && icon.size} Bytes</p>
                </div>
              </div>

              <IconButton aria-label="close" onClick={closeCheck}>
                <Icon style={{ color: '#a9a8a8' }}>close</Icon>
              </IconButton>
            </div>
          ) : (
            <Container
              {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
            >
              <input
                type="file"
                {...getInputProps()}
                onChange={handleChange}
                id="file"
                accept={acceptFile}
              />
              <p>
                Drag and drop files here or{' '}
                <a style={{ color: '#039be5' }}>browse</a>
              </p>
            </Container>
          )}
        </div>
        <Typography display="inline" style={{ color: '#bdbdbd' }}>
          (File size is not more than 5 MB)
        </Typography>
        <Divider style={{ marginTop: '10px' }} />
        <div style={{ marginTop: '15px' }}>
          <Typography display="inline" style={{ color: '#bdbdbd' }}>
            Accepted file type is .png, .jpg, .jpeg, .gif, .jfif, and .webp
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          className={classes.btnImport}
          variant="contained"
          onClick={() => submitImport()}
          disabled={dis}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  )
}
