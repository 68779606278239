import gql from 'graphql-tag'

export const GET_LIST_HOLDING_TALENT_EXTERNAL = gql`
  query($companyHead: uuid, $search: String) {
    list_external: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company_profile: {status: {_eq: "ACTIVE"}}
        _not: {
          _or: [
            {company_profile: {id: {_eq: $companyHead}}}
            {
              company_profile: {
                holdingRelationsByCompanyMember: {
                  company_head: {_eq: $companyHead}
                }
              }
            }
          ]
        }
        _or: [
          {email: {_ilike: $search}}
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      regno
      email
      global_user {
        id
        name
        avatar
      }
      company_profile {
        id
        legal_name
        brand_name
      }
      company_job_profile {
        id
        title
      }
      holding_sources {
        id
      }
    }
  }
`
