import {
  Dialog,
  Typography,
  IconButton,
  Icon,
  makeStyles,
  DialogContent,
} from '@material-ui/core'
import React from 'react'
import {PrimaryModalHeader} from '../../../../../GlobalStyles'
import BaloonDownloadBusinessTrip from './BaloonDownloadBusinessTrip'

const useStyles = makeStyles(() => ({
  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  alert: {
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  type: {fontSize: 10},
  message: {fontSize: 12, fontWeight: '500'},
  titleGroup: {marginBottom: 10, fontWeight: '600'},
}))

export default function RunningProcessBusinessTrip({
  open,
  onClose,
  data,
  onRetry,
}) {
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <PrimaryModalHeader>
        <Typography variant="h6" style={{color: '#fff'}}>
          Running Process
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon style={{color: '#fff'}}>close</Icon>
        </IconButton>
      </PrimaryModalHeader>
      <DialogContent className={classes.content}>
        <Typography className={classes.titleGroup}>Generate Data</Typography>
        <BaloonDownloadBusinessTrip
          dataBaloonDonloadDirectory={data}
          onRetry={onRetry}
        />
      </DialogContent>
    </Dialog>
  )
}
