import gql from 'graphql-tag'

export const ADD_ASSIGNED_COMPONENT = gql`
  mutation addAssignedComponent(
    $objects: [finance_component_assignments_insert_input!]!
  ) {
    insert_finance_component_assignments(
      objects: $objects
      on_conflict: {
        constraint: finance_component_assignments_user_work_id_component_id_key
        update_columns: [deletedAt]
      }
    ) {
      affected_rows
    }
  }
`

export const DELETE_ASSIGNED_COMPONENT = gql`
  mutation deleteAssignedComponent($id: Int!, $user_work_id: Int!) {
    delete_finance_component_assignments(
      where: {
        _and: [
          {component_id: {_eq: $id}, user_work_id: {_eq: $user_work_id}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`
