import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import EmployeeList from './EmployeeList'
import AddEmployee from './add-employee/AddEmployee'
import DetailEmployee from './detail-employee/DetailEmployee'
import {
  ButtonFilter,
  BlueIcon,
  FilterText,
  PageContainer,
} from '../../../../src/GlobalStyles'
import {Filter} from '@worklifebeyond/wlb-utils-components'
import {anchorOrigin, transformOrigin} from './diretoryFilterData'
import {dateFormat, dateSubtract} from '../../../utils/helpers'
import {extendedSearchFilters} from '../../../utils/searchFilters'

const renderComponent = (activeComponent, state, searchText, filterData) => {
  switch (activeComponent) {
    case 'employee':
      return (
        <EmployeeList
          state={state}
          searchText={searchText}
          filterData={filterData}
        />
      )
    case 'add-employee':
      return <AddEmployee />
    case 'detail-employee':
      return <DetailEmployee />
    case 'edit-employee':
      return <AddEmployee />
    case 'employee-will-join':
      return (
        <EmployeeList
          state={state}
          searchText={searchText}
          empType="willJoin"
          filterData={filterData}
        />
      )
    case 'employee-pending':
      return (
        <EmployeeList
          state={state}
          searchText={searchText}
          empType="pending"
          filterData={filterData}
        />
      )
    case 'employee-alumni':
      return (
        <EmployeeList
          state={state}
          searchText={searchText}
          empType="alumni"
          filterData={filterData}
        />
      )
    default:
      return (
        <EmployeeList
          state={state}
          searchText={searchText}
          filterData={filterData}
        />
      )
  }
}

const EmployeePage = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }
  // const searchable = props.location.pathname.split('/')[3] ? false : true
  const [searchText, setSearchText] = useState('')
  const [filterData, setFilterData] = useState({})

  const onSearch = search => {
    setSearchText(search)
  }

  const handleApply = res => {
    const _formated = date => dateFormat(date, 'YYYY-MM-DD')
    const _subtracted = long =>
      dateSubtract(new Date(), long, 'years', 'YYYY-MM-DD')
    const generateIdFilter = data => {
      const _data = {}
      for (const property in data) {
        if (Array.isArray(data[property])) {
          _data[property] = data[property][0]
            ? data[property].map(itm => itm.value)
            : undefined
        } else if (property === 'date') {
          _data[property] = {
            ...(data[property].from && {
              from: _formated(data[property].from),
            }),
            ...(data[property].to && {to: _formated(data[property].to)}),
          }
        } else if (property === 'age') {
          _data[property] = {
            ...(data[property].min && {min: _subtracted(data[property].min)}),
            ...(data[property].max && {
              max: _subtracted(data[property].max + 1),
            }),
          }
        } else {
          _data[property] = data[property]
        }
      }
      return _data
    }
    const include = generateIdFilter(res[0])
    const exclude = generateIdFilter(res[1])
    setFilterData({
      include,
      exclude,
    })
  }

  return (
    <div>
      <Card
        icon="people"
        title="Pegawai"
        onSearch={onSearch}
        searchable={true}
        endAdornment={
          <Filter
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            id="filter-employee-wlb"
            onApply={handleApply}
            listFilter={extendedSearchFilters}
            maxHeight="450px"
            language="indonesian"
          >
            <ButtonFilter position="end">
              <BlueIcon>filter_list</BlueIcon>
              <FilterText variant="body2">Saring</FilterText>
            </ButtonFilter>
          </Filter>
        }
      >
        <PageContainer>
          {renderComponent(active, state, searchText, filterData)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default EmployeePage
