import React, {useEffect} from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {makeStyles} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {GET_LEAVE_SETTINGS} from '../../../../graphql/queries'
import {DELETE_LEAVE_POLICY} from '../../../../graphql/mutations'
import Box from '@material-ui/core/Box'
import {Link} from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {
  AppliesToChild,
  AppliesToContainer,
  LeaveApplyContainer,
  LeaveNameChild,
  LeaveNameContainer,
} from '../LeavePageStyles'
import Loading from '../../../../components/loading/LoadingComponent'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import classNames from 'classnames'
import {useSnackbar} from 'notistack'
import {Divider, TablePagination} from '@material-ui/core'
import {PaperContainer} from '../../../../GlobalStyles'
import NotFoundComponent from '../../../shared-components/NotFoundComponent'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {COMPANY_ID, DEFAULT_PAGINATION} from '../../../../utils/globals'
import AllAppliedTo from './modal/AllAppliedTo'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontStyle: 'bold',
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  expanded: {
    borderTop: '1px solid rgba(0, 0, 0, 0.16)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    margin: '0 !important',
  },
  bold: {
    fontWeight: 700,
  },
  mr15: {marginRight: '1.5rem'},
  textGrey: {color: '#a9a8a8'},
  link: {color: '#000', textDecoration: 'none'},
  panelContentContainer: {display: 'flex', alignItems: 'center'},
  buttonSpacer: {marginRight: 12},
  btnEdit: {marginRight: 15, textDecoration: 'none'},
  seeMore: {
    fontSize: 12,
    fontWeight: '500',
    cursor: 'pointer',
    marginBottom: 32,
    marginLeft: 24,
  },
}))

const DEFAULT_SEE_MORE = {
  open: false,
  id: undefined,
  title: '',
}

export default function Setting({searchText}) {
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const [currentPage, setCurrentPage] = React.useState(0)
  const [rowPerPage, setRowPerPage] = React.useState(DEFAULT_PAGINATION[0])
  const [seeMoreApplied, setSeeMoreApplied] = React.useState(DEFAULT_SEE_MORE)
  const {data, error, refetch} = useQuery(GET_LEAVE_SETTINGS, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
      search: `%${searchText}%`,
      limit: rowPerPage,
      offset: rowPerPage * currentPage,
    },
  })
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [selectePolicy, setSelectedPolicy] = React.useState(null)
  const [deletePolicy] = useMutation(DELETE_LEAVE_POLICY)
  const [showDeletePopUp, setDeletePopUp] = React.useState(false)

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const onDeletePolicy = id => {
    const variables = {
      id: id,
      deleteAt: new Date(),
    }
    deletePolicy({variables})
      .then(() => {
        enqueueSnackbar('Schedule deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete schedule failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowPerPage(+event.target.value)
    setCurrentPage(0)
  }

  const handleClickSeeMore = child => {
    setSeeMoreApplied(e => ({
      ...e,
      open: true,
      id: child.id,
      title: child.name,
    }))
  }

  // console.log(data)
  const totalData = data.time_leave_policies_aggregate.aggregate.count
  return (
    <div className={classes.root}>
      <PaperContainer>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Leave and Permission Policy Settings
          </Typography>

          <Button
            color="primary"
            size="large"
            variant="contained"
            className={classes.bold}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            Add Leave and Permission Policy
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link
              to={{
                pathname: `/time/leave/settings/addparent`,
                state: {
                  active: 'add-settings-parent',
                },
              }}
              className={classes.link}
            >
              <MenuItem onClick={handleClose}>Add Parent Policy</MenuItem>
            </Link>

            <Link
              to={{
                pathname: `/time/leave/settings/add`,
                state: {
                  active: 'add-settings',
                  policies: data.time_leave_policies,
                },
              }}
              className={classes.link}
            >
              <MenuItem onClick={handleClose}>Add Child Policy</MenuItem>
            </Link>
          </Menu>
        </Toolbar>
        <Divider />
        {data.time_leave_policies.length === 0 ? (
          <NotFoundComponent message="There is no leave policy" />
        ) : (
          data.time_leave_policies.map((policy, key) => {
            return (
              <ExpansionPanel
                key={key}
                elevation={0}
                classes={{
                  expanded: classes.expanded,
                }}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  classes={{content: classes.panelContentContainer}}
                >
                  <Typography
                    variant="body1"
                    className={classNames(classes.bold, classes.buttonSpacer)}
                    gutterBottom
                    color="primary"
                  >
                    {policy.name}
                  </Typography>

                  <IconButton
                    component={Link}
                    to={{
                      pathname: `/time/leave/settings/group/${policy.id}`,
                      state: {
                        active: 'edit-settings-parent',
                        selected: policy,
                        dataCheck: data,
                      },
                    }}
                    aria-label="edit"
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails style={{display: 'block'}} width={1}>
                  {policy.time_leave_policy_children.map((child, i) => {
                    return (
                      <div style={{width: '100%'}} key={i}>
                        <Box width={1}>
                          <LeaveNameContainer>
                            <LeaveNameChild>
                              <Typography
                                variant="body1"
                                className={(classes.bold, classes.mr15)}
                              >
                                {i + 1}.
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.bold}
                              >
                                {child.name}
                              </Typography>
                            </LeaveNameChild>

                            <LeaveNameChild>
                              <Typography
                                variant="body2"
                                component={Link}
                                to={{
                                  pathname: `/time/leave/settings/${child.id}`,
                                  state: {
                                    active: 'edit-settings',
                                    rootData: data.time_leave_policies,
                                    selectedParent: policy,
                                    selectedItem: child,
                                  },
                                }}
                                color="secondary"
                                className={classes.btnEdit}
                              >
                                Edit
                              </Typography>
                              <Typography
                                onClick={() => {
                                  setDeletePopUp(true)
                                  setSelectedPolicy(child)
                                }}
                                color="error"
                                style={{cursor: 'pointer'}}
                                variant="body2"
                              >
                                Delete
                              </Typography>
                            </LeaveNameChild>
                          </LeaveNameContainer>
                        </Box>
                        <LeaveApplyContainer>
                          <Typography
                            variant="body1"
                            className={classes.textGrey}
                            style={{marginBottom: '1rem'}}
                          >
                            Leave and Permission Balance:{' '}
                            {child.infinite_quota ? 'Unlimited' : child.quota}
                          </Typography>
                          <Typography className={classes.bold} variant="body1">
                            Applies to{' '}
                            {`(${child.total_job_profile_r_leaves.aggregate.count})`}
                            :
                          </Typography>
                          <AppliesToContainer>
                            {child.company_job_profile_r_leaves.map(
                              (position, key) => (
                                <AppliesToChild key={key}>
                                  {position.company_job_profile &&
                                    position.company_job_profile.title}
                                </AppliesToChild>
                              )
                            )}
                          </AppliesToContainer>
                          {child.total_job_profile_r_leaves.aggregate.count >
                            20 && (
                            <Typography
                              color="secondary"
                              className={classes.seeMore}
                              onClick={() => handleClickSeeMore(child)}
                            >
                              See More
                            </Typography>
                          )}
                        </LeaveApplyContainer>
                      </div>
                    )
                  })}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          })
        )}
      </PaperContainer>
      {data.time_leave_policies.length > 0 && (
        <TablePagination
          rowsPerPageOptions={DEFAULT_PAGINATION}
          component="div"
          rowsPerPage={rowPerPage}
          page={currentPage}
          count={totalData}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <DeletePopup
        open={showDeletePopUp}
        handleClose={() => setDeletePopUp(false)}
        name={selectePolicy && selectePolicy.name}
        mutation={() => onDeletePolicy(selectePolicy && selectePolicy.id)}
      />
      <AllAppliedTo
        dataSeeMore={seeMoreApplied}
        onClose={() => setSeeMoreApplied(DEFAULT_SEE_MORE)}
      />
    </div>
  )
}
