export const holdingDashboardOnlyConfig = [
  {
    id: 'dashboard-menu',
    title: 'Dashboard',
    type: 'item',
    icon: 'dashboard',
    visible: true,
    url: '/holding-dashboard',
    state: 'holding-dashboard',
  },
]

const navigationHoldingConfig = [
  {
    id: 'dashboard-menu',
    title: 'Dashboard',
    type: 'item',
    icon: 'dashboard',
    visible: true,
    url: '/holding-dashboard',
    state: 'holding-dashboard',
  },
  {
    id: 'company-group',
    title: 'Company Group',
    type: 'collapse',
    icon: 'location_city',
    visible: true,
    children: [
      {
        id: 'holding-group-profile',
        title: 'Profile',
        type: 'item',
        url: '/group/profile',
        state: 'group-profile',
        visible: true,
        hideSearch: true,
      },
      {
        id: 'holding-group-configuring',
        title: 'Configuring',
        type: 'item',
        url: '/group/config',
        state: 'group-configuring',
        visible: true,
      },
    ],
  },
  {
    id: 'company-member',
    title: 'Company Member',
    type: 'collapse',
    icon: 'business',
    visible: true,
    children: [
      {
        id: 'holding-member-list',
        title: 'List',
        type: 'item',
        url: '/member/list',
        state: 'member-list',
        visible: true,
      },
      {
        id: 'holding-member-pending',
        title: 'Pending Activation',
        type: 'item',
        url: '/member/pending',
        state: 'member-pending',
        visible: true,
      },
      {
        id: 'holding-member-structure',
        title: 'Company Structure',
        type: 'item',
        url: '/member/structure',
        state: 'member-structure',
        visible: true,
      },
      {
        id: 'holding-member-config',
        title: 'Configuring',
        type: 'item',
        url: '/member/configs',
        state: 'member-config',
        visible: true,
      },
    ],
  },
  {
    id: 'holding-talent',
    title: 'Talent',
    type: 'collapse',
    icon: 'how_to_reg',
    visible: true,
    children: [
      {
        id: 'holding-talent-list',
        title: 'List',
        type: 'item',
        url: '/holding-talent/list-talent',
        state: 'holding-talent-list',
        visible: true,
      },
      {
        id: 'holding-talent-pending',
        title: 'Pending Activation',
        type: 'item',
        url: '/holding-talent/pending-talent',
        state: 'holding-talent-pending',
        visible: true,
      },
      {
        id: 'holding-talent-source',
        title: 'Source',
        type: 'item',
        url: '/holding-talent/source',
        state: 'holding-talent-source',
        visible: true,
      },
      {
        id: 'holding-talent-classification',
        title: 'Classification',
        type: 'item',
        url: '/holding-talent/classification',
        state: 'holding-talent-classification',
        visible: true,
      },
      {
        id: 'holding-talent-nomination',
        title: 'Vacancy',
        type: 'item',
        url: '/holding-talent/nomination',
        state: 'holding-talent-nomination',
        visible: true,
      },
    ],
  },
  {
    id: 'holding-learning',
    title: 'Learning',
    type: 'collapse',
    icon: 'school',
    visible: true,
    children: [
      {
        id: 'learning-profile-list',
        title: 'Learning Profile List',
        type: 'item',
        url: '/holding-learning/learning-profile',
        state: 'learning-profile-list',
        visible: true,
      },
      {
        id: 'cross-mentoring-list',
        title: 'Cross Mentoring',
        type: 'item',
        url: '/holding-learning/cross-mentoring',
        state: 'cross-mentoring-list',
        visible: true,
      },
      {
        id: 'download-report',
        title: 'Download Report',
        type: 'item',
        url: '/holding-learning/learning-report',
        state: 'download-report',
        visible: true,
      },
    ],
  },
  {
    id: 'holding-performance',
    title: 'Performance',
    type: 'collapse',
    icon: 'bar_chart',
    visible: true,
    children: [
      {
        id: 'performance-company-summary',
        title: 'Performance Company Summary',
        type: 'item',
        url: '/holding-performance/company-summary',
        state: 'performance-company-summary',
        visible: true,
      },
    ],
  },
]

export default navigationHoldingConfig
