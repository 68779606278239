import gql from 'graphql-tag'

export const ADD_ARCHIVE_OM = gql`
  mutation($obj: company_archived_logs_insert_input!) {
    insert_company_archived_logs_one(object: $obj) {
      id
      name
      description
      status
      created_at
    }
  }
`
