import JobFunction from './JobFunctionPageComponent'

export const JobFunctionPageConfig = {
  routes: [
    {
      path: '/organization/job-function',
      component: JobFunction,
      state: 'job-function',
      exact: true,
    },
    {
      path: '/organization/job-function/add',
      component: JobFunction,
      state: 'add-job-function',
      exact: false,
    },
    {
      path: '/organization/job-function/edit',
      component: JobFunction,
      state: 'edit-job-function',
      exact: false,
    },
  ],
}
