import gql from 'graphql-tag'

export const GET_LIST_COMPANY_FILE = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: company_files_aggregate(
      where: {
        _or: [
          {name: {_ilike: $search}}
          {global_file_type: {name: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    company_files(
      where: {
        _or: [
          {name: {_ilike: $search}}
          {global_file_type: {name: {_ilike: $search}}}
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      global_file_type {
        id
        name
      }
      file
      date_added
    }
  }
`
