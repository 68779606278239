import gql from 'graphql-tag'

export const COUNT_LEAVE_DASHBOARD = gql`
  query getLeaveDashboard($from: String!, $to: String!) {
    getLeaveDashboard(from: $from, to: $to) {
      month
      count
    }
  }
`
