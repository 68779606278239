import gql from 'graphql-tag'

export const GET_SCHEDULE_SETTING = gql`
  query($company: uuid!) {
    finance_payroll_settings_by_pk(id: $company) {
      pay_date
      cut_off_date
      leave_unpaid_eval
      leave_unpaid_formula
      leave_unpaid_days
      employee_irregular_start_salary
    }
  }
`
