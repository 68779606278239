import gql from 'graphql-tag'

export const ADD_HOLDING_COMMITTEE = gql`
  mutation addHoldingCommite(
    $object: [holding_nomination_commite_insert_input!]!
  ) {
    insert_holding_nomination_commite(objects: $object) {
      returning {
        id
      }
    }
  }
`
