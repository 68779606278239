import searchFilters from '../../../utils/searchFilters'

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

export const listFilter = searchFilters.filter(
  item => item.fieldName === 'organizationUnit' || item.fieldName === 'position'
)

export const getDataVariableIndividualSum = filterData => {
  const variables = {
    filterOrganization: {
      _in: filterData?.include?.organizationUnit,
      _nin: filterData?.exclude?.organizationUnit,
    },
    filterPosition: {
      _in: filterData?.include?.position,
      _nin: filterData?.exclude?.position,
    },
  }

  return variables
}
