import React, {useEffect, useState} from 'react' // useState, useEffect
import {
  DialogContent,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Switch,
  DialogActions,
  Button,
  Dialog,
  // IconButton,
  // Icon,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {WhiteModalHeader, FlexBetween} from '../../../GlobalStyles'
import {COMPANY_ID} from '../../../utils/globals'
import {withStyles} from '@material-ui/core/styles'
import {green, grey} from '@material-ui/core/colors'
import {
  GET_DATA_NEWPARENT_ORG,
  GET_DATA_NEWPARENT_POSITION,
  LIST_ALL_PROFILE,
  GET_LIST_ORGANIZATION,
} from '../../../graphql/queries'
import {useApolloClient, useQuery} from '@apollo/react-hooks'

import CheckCircle from '@material-ui/icons/CheckCircle'
import Brightness1 from '@material-ui/icons/Brightness1'
import classNames from 'classnames'
import AsyncSelect from 'react-select/async'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
  },
  checkbox: {padding: 0, marginRight: 10},
  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnImport: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  inline: {display: 'inline'},
  semiBold: {fontWeight: 600},
  explanationText: {color: '#a9a8a8', fontSize: '12px'},
  marginTop10: {marginTop: '10px'},
  marginTop20: {marginTop: '20px'},
  margin0: {margin: '0px'},
  width90P: {width: '90%'},
  width89P: {width: '89%'},
  width80P: {width: '80%'},
  width10P: {width: '10%'},
  paddingUnset: {padding: '8px 0px'},
  alignSelfStart: {alignSelf: 'start'},
}))

const GreenCheckbox = withStyles({
  root: {
    color: grey[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

const NewParentPopup = props => {
  const {
    handleClose,
    open,
    id,
    feature,
    switchChecked,
    setSwitchChecked,
    setVariable,
    organziationData,
    isUndetermined,
  } = props

  const canRefetch = true

  const classes = useStyles()

  const [toogleOrg, setToogleOrg] = useState(true)
  const [tooglePosition, setTooglePosition] = useState(true)
  const [newParentData, setNewParentData] = useState({})
  // const [newParentDropdown, setNewParentDropdown] = useState([])

  const conditionQuery =
    feature === 'organization'
      ? GET_DATA_NEWPARENT_ORG
      : GET_DATA_NEWPARENT_POSITION

  const variableNewParent = id
    ? {
        company: COMPANY_ID,
        organization: id,
      }
    : {
        company: COMPANY_ID,
      }

  const {
    data: dataNewParent,
    loading: loadingNewParent,
    error: errorNewParent,
    refetch,
  } = useQuery(conditionQuery, {
    variables: variableNewParent,
  })

  useEffect(() => {
    if (dataNewParent) {
      setNewParentData({
        organization1:
          dataNewParent &&
          dataNewParent.company_organizations &&
          (dataNewParent.company_organizations[0]
            ? dataNewParent.company_organizations[0].id
            : null),
        position1:
          dataNewParent &&
          dataNewParent.company_job_profiles &&
          (dataNewParent.company_job_profiles[0]
            ? dataNewParent.company_job_profiles[0].id
            : null),
        a: dataNewParent && dataNewParent.company_organizations.length !== 0,
        b: dataNewParent && dataNewParent.company_job_profiles.length !== 0,
        c:
          (dataNewParent && dataNewParent.company_organizations.length !== 0) ||
          (dataNewParent && dataNewParent.company_job_profiles.length !== 0),
      })
    }
    if (canRefetch) {
      refetch()
    }
    if (!switchChecked) {
      setToogleOrg(true)
      setTooglePosition(true)
      // setNewParentDropdown([])
    }
  }, [dataNewParent, canRefetch, switchChecked])

  // const newParent = dataNewParent?.dropdown.map(options => {
  //   const newParentOptions = {
  //     value: options.id,
  //     label: feature === 'organization' ? options.title : options.name,
  //   }
  //   return newParentOptions
  // })

  const client = useApolloClient()

  if (loadingNewParent) {
    return <CircularProgress />
  } else if (errorNewParent !== undefined) {
    return <div>{errorNewParent}</div>
  }

  const handleOrganizationSearch = async searchText => {
    const {data} = await client.query({
      query: GET_LIST_ORGANIZATION,
      fetchPolicy: 'no-cache',
      variables: {
        company: COMPANY_ID,
        search: `%${searchText}%`,
        offset: 0,
        limit: 30,
      },
    })

    const result = data?.company_organizations.map(item => ({
      value: item.id,
      // label: `${item.code} - ${item.name}`,
      label: (
        <>
          <Typography color="primary" display="inline">
            {item.code}
          </Typography>{' '}
          - {item.name}
        </>
      ),
    }))

    return result || []
  }

  const handlePositionSearch = async searchText => {
    const {data} = await client.query({
      query: LIST_ALL_PROFILE,
      fetchPolicy: 'no-cache',
      variables: {
        company: COMPANY_ID,
        search: `%${searchText}%`,
        offset: 0,
        limit: 30,
      },
    })

    const result = data?.company_job_profiles.map(item => ({
      value: item.id,
      // label: `${item.code} - ${item.title}`,
      label: (
        <>
          <Typography color="primary" display="inline">
            {item.code}
          </Typography>{' '}
          - {item.title}
        </>
      ),
    }))

    return result || []
  }

  const handleConfirm = () => {
    setVariable(newParentData)
    handleClose()
    setSwitchChecked(true)
  }

  const unsetOrganization = () => {
    // if (feature === 'organization') {
    if (dataNewParent && dataNewParent.company_organizations.length !== 0) {
      return (
        <FlexBetween className={classes.marginTop10}>
          <FormControlLabel
            className={classNames(
              classes.margin0,
              classes.width10P,
              classes.alignSelfStart
            )}
            disabled
            control={
              <GreenCheckbox
                checked={!toogleOrg}
                icon={<Brightness1 fontSize="large" />}
                checkedIcon={<CheckCircle fontSize="large" />}
                name="setNewOU"
              />
            }
          />
          <div
            className={classNames(
              classes.inline,
              classes.width80P,
              classes.paddingUnset
            )}
          >
            <Typography className={classes.semiBold} variant="span">
              Hapus
            </Typography>
            <Typography
              className={classes.semiBold}
              variant="span"
              color="primary"
            >
              {` ${dataNewParent &&
                dataNewParent.company_organizations &&
                dataNewParent.company_organizations[0] &&
                dataNewParent.company_organizations[0].name} `}
            </Typography>
            <Typography className={classes.semiBold} variant="span">
              sebagai induk unit organisasi
            </Typography>
            <Typography
              className={classNames(classes.semiBold, classes.explanationText)}
            >
              Nonaktifkan tombol untuk membatalkan setelan induk Unit Organisasi
              saat ini
            </Typography>
            {!toogleOrg && (
              <>
                <Typography
                  className={classNames(
                    classes.semiBold,
                    classes.explanationText,
                    classes.marginTop20
                  )}
                >
                  {`What is ${dataNewParent &&
                    dataNewParent.company_organizations &&
                    dataNewParent.company_organizations[0].name} new parent?`}
                </Typography>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleOrganizationSearch}
                  onChange={item => {
                    setNewParentData({
                      ...newParentData,
                      parentOrganization1: item.value,
                    })
                  }}
                  menuPosition="fixed"
                />
              </>
            )}
          </div>
          <FormControlLabel
            className={classNames(
              classes.margin0,
              classes.width10P,
              classes.alignSelfStart
            )}
            control={
              <Switch
                checked={toogleOrg}
                onChange={event => setToogleOrg(event.target.checked)}
                color="primary"
                size="large"
              />
            }
          />
        </FlexBetween>
      )
    }
    // } else {
    // }
  }

  const unsetPosition = () => {
    // if (feature === 'organization') {
    if (dataNewParent && dataNewParent.company_job_profiles.length !== 0) {
      return (
        <FlexBetween className={classes.marginTop10}>
          <FormControlLabel
            className={classNames(
              classes.margin0,
              classes.width10P,
              classes.alignSelfStart
            )}
            disabled
            control={
              <GreenCheckbox
                checked={!tooglePosition}
                icon={<Brightness1 fontSize="large" />}
                checkedIcon={<CheckCircle fontSize="large" />}
                name="setNewPosition"
              />
            }
          />
          <div
            className={classNames(
              classes.inline,
              classes.width80P,
              classes.paddingUnset
            )}
          >
            <Typography className={classes.semiBold} variant="span">
              Hapus
            </Typography>
            <Typography
              className={classes.semiBold}
              variant="span"
              color="primary"
            >
              {` ${dataNewParent &&
                dataNewParent.company_job_profiles.length !== 0 &&
                dataNewParent &&
                dataNewParent.company_job_profiles &&
                dataNewParent.company_job_profiles[0].title} `}
            </Typography>
            <Typography className={classes.semiBold} variant="span">
              sebagai induk posisi
            </Typography>
            <Typography
              className={classNames(classes.semiBold, classes.explanationText)}
            >
              Nonaktifkan tombol untuk membatalkan pengaturan induk Posisi saat
              ini
            </Typography>
            {!tooglePosition && (
              <>
                <Typography
                  className={classNames(
                    classes.semiBold,
                    classes.explanationText,
                    classes.marginTop20
                  )}
                >
                  {`What is ${dataNewParent &&
                    dataNewParent.company_job_profiles.length !== 0 &&
                    dataNewParent &&
                    dataNewParent.company_job_profiles &&
                    dataNewParent.company_job_profiles[0].title} new parent?`}
                </Typography>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handlePositionSearch}
                  onChange={item => {
                    const variable =
                      feature === 'organization'
                        ? 'positionDropdown'
                        : 'organizationDropdown'

                    const dataVariable =
                      feature === 'organization'
                        ? dataNewParent &&
                          dataNewParent.set_new_parent &&
                          dataNewParent.set_new_parent.length !== 0 &&
                          dataNewParent.set_new_parent[0].id
                        : organziationData?.id

                    setNewParentData({
                      ...newParentData,
                      parentPosition1: item.value,
                      [variable]: dataVariable,
                    })
                  }}
                  menuPosition="fixed"
                />
              </>
            )}
          </div>
          <FormControlLabel
            className={classNames(
              classes.margin0,
              classes.width10P,
              classes.alignSelfStart
            )}
            control={
              <Switch
                checked={tooglePosition}
                onChange={event => setTooglePosition(event.target.checked)}
                color="primary"
                size="large"
              />
            }
          />
        </FlexBetween>
      )
    }
    // } else {
    // }
  }

  const disabledButton = () => {
    return (
      newParentData &&
      ((dataNewParent &&
        dataNewParent.company_organizations.length !== 0 &&
        !newParentData.parentOrganization1) ||
        (dataNewParent &&
          dataNewParent.company_job_profiles.length !== 0 &&
          !newParentData.parentPosition1) ||
        (feature === 'organization'
          ? !newParentData.positionDropdown
          : !newParentData.organizationDropdown))
    )
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <WhiteModalHeader>
        <Typography className={classes.semiBold} variant="h6" color="primary">
          Tetapkan Induk Baru
        </Typography>
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        <Typography className={classes.semiBold} display="inline">
          Untuk mengatur induk baru, anda perlu :
        </Typography>
        {unsetOrganization()}
        {unsetPosition()}
        {((dataNewParent && dataNewParent.company_job_profiles.length !== 0) ||
          (dataNewParent &&
            dataNewParent.company_organizations.length !== 0)) && (
          // <Typography
          //   className={classNames(classes.semiBold, classes.marginTop10)}
          // >
          //   Then, you need to :
          // </Typography>
          <Typography
            className={classNames(classes.semiBold, classes.marginTop10)}
          >
            {`Induk  ${
              feature === 'organisasi ' ? 'posisi' : 'unit organisasi'
            } baru :`}
          </Typography>
        )}
        <FlexBetween className={classes.marginTop10}>
          <FormControlLabel
            className={classNames(
              classes.margin0,
              classes.width10P,
              classes.alignSelfStart
            )}
            disabled
            control={
              <GreenCheckbox
                checked={
                  feature === 'organization'
                    ? newParentData.positionDropdown > 0
                    : newParentData.organizationDropdown > 0
                }
                icon={<Brightness1 fontSize="large" />}
                checkedIcon={<CheckCircle fontSize="large" />}
                name="newParent"
              />
            }
          />
          <div
            className={classNames(
              classes.inline,
              classes.width90P,
              classes.paddingUnset
            )}
          >
            <Typography
              className={classes.semiBold}
              variant="span"
              color="primary"
            >
              {isUndetermined
                ? '-'
                : feature === 'organization'
                ? dataNewParent?.set_new_parent?.[0]?.title || '-'
                : organziationData?.name || '-'}
            </Typography>
            {!isUndetermined && dataNewParent?.set_new_parent?.length === 0 && (
              <Typography
                className={classNames(
                  classes.semiBold,
                  classes.explanationText
                )}
              >
                New parent will generate automatically
              </Typography>
            )}
            {/* <Typography className={classes.semiBold} variant="span">
              {`Set a new ${
                feature === 'organization' ? `position` : `organization unit`
              } parent`}
            </Typography>
            <Typography
              className={classNames(classes.semiBold, classes.explanationText)}
            >
              {`Select the new ${
                feature === 'organization' ? `position` : `organization unit`
              } parent`}
            </Typography> */}
            {/* <Select
              id="positionParent"
              className={classNames(classes.marginTop10, classes.width89P)}
              options={newParent}
              placeholder={
                feature === 'organization'
                  ? `Choose new position parent`
                  : `Choose new organization unit parent`
              }
              value={newParentDropdown}
              onChange={event => {
                const variable =
                  feature === 'organization'
                    ? 'positionDropdown'
                    : 'organizationDropdown'
                setNewParentData({
                  ...newParentData,
                  [variable]: event.value,
                })
                setNewParentDropdown(event)
              }}
              styles={{
                menu: provided => ({...provided, zIndex: 9999}),
              }}
            /> */}
          </div>
        </FlexBetween>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose}>Batal</Button>
        <Button
          className={classes.btnImport}
          variant="contained"
          onClick={() => handleConfirm()}
          disabled={disabledButton()}
        >
          Konfirmasi
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewParentPopup
