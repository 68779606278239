import gql from 'graphql-tag'

// Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/version/INSERT%20VERSION.md
export const ADD_COMPETENCY_VERSION = gql`
  mutation($objects: [competency_versions_insert_input!]!, $status: String!) {
    update_status: update_competency_versions(
      where: {status: {_eq: $status}}
      _set: {status: "INACTIVE"}
    ) {
      affected_rows
    }

    insert_competency_versions(objects: $objects) {
      affected_rows
    }
  }
`

// Doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/version/INSERCT%20COMPETENCY%20IN%20VERSION.md
export const ADD_COMPETENCY_IN_VERSION = gql`
  mutation($objects: [competency_version_dictionaries_insert_input!]!) {
    insert_competency_version_dictionaries(objects: $objects) {
      affected_rows
    }
  }
`
