export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const getFilterBadges = talent => {
  return (
    talent &&
    Object.values({...talent})
      .flat()
      .map(({value}) => value)
      .filter(e => e)
  )
}

const isFilterTalent = ({include, exclude}) => {
  return Boolean(
    include?.talent?.checkbox ||
      exclude?.talent?.checkbox ||
      include?.talent?.range?.min ||
      include?.talent?.range?.max ||
      exclude?.talent?.range?.min ||
      exclude?.talent?.range?.max
  )
}

export const getDataVariableEmployee = filterData => {
  const {include, exclude} = filterData
  const _salaryFrom = include && include.salary && include.salary.min
  const _salaryTo = include && include.salary && include.salary.max
  const _salaryFromEX = exclude && exclude.salary && exclude.salary.min
  const _salaryToEX = exclude && exclude.salary && exclude.salary.max
  const _ageMin = include && include.age && include.age.min
  const _ageMax = include && include.age && include.age.max
  const _ageMinEX = exclude && exclude.age && exclude.age.min
  const _ageMaxEX = exclude && exclude.age && exclude.age.max
  const variables = {
    job_family: include && include.jobFamily,
    job_title: include && include.jobTitle,
    organization: include && include.organizationUnit,
    level: include && include.jobLevel,
    office: include && include.officeLocation,
    employeeType: include && include.employeeType,
    timeArrangement: include && include.timeArrangement,
    workArrangement: include && include.workingArrangement,
    job_grades: include && include.jobGrade,
    organization_level: include && include.organizationLevel,
    badges: getFilterBadges(include?.talent?.checkbox),
    EXC_badges: getFilterBadges(exclude?.talent?.checkbox),
    talentScoreFrom: include?.talent?.range?.min,
    talentScoreTo: include?.talent?.range?.max,
    EXC_talentScoreFrom: exclude?.talent?.range?.min || 200,
    EXC_talentScoreTo: exclude?.talent?.range?.max || 300,
    isFilterTalent: isFilterTalent(filterData),
    salaryFrom: _salaryFrom,
    salaryTo: _salaryTo,
    isFilterSalary: Boolean(_salaryFrom || _salaryTo) || false, // params untuk filter SALARY, ketika menggunakan ubah jadi TRUE

    birthFrom: _ageMax,
    birthTo: _ageMin,
    isFilterBirth: Boolean(_ageMin || _ageMax) || false, // params untuk filter BIRTH, ketika menggunakan ubah jadi TRUE

    gender: include && include.gender,
    religion: include && include.religion,
    jobProfile: include && include.position, // untuk filter position
    education: include && include.education,
    isFilterEducation:
      ((include && include.education) || (exclude && exclude.education) || [])
        .length > 0 || false, // ini default ketika akan menggunakan filter edu ubah jadi TRUE,

    hobbies: include && include.hobby,
    isFilterHobbies:
      ((include && include.hobby) || (exclude && exclude.hobby) || []).length >
        0 || false, // ini default ketika akan menggunakan filter edu ubah jadi TRUE,

    EXC_gender: exclude && exclude.gender,
    EXC_religion: exclude && exclude.religion,
    EXC_jobProfile: exclude && exclude.position, // untuk filter position
    EXC_job_title: exclude && exclude.jobTitle,
    EXC_organization: exclude && exclude.organizationUnit, // untuk organization unit
    EXC_level: exclude && exclude.jobLevel,
    EXC_office: exclude && exclude.officeLocation,
    EXC_employeeType: exclude && exclude.employeeType,
    EXC_timeArrangement: exclude && exclude.timeArrangement,
    EXC_workArrangement: exclude && exclude.workingArrangement,
    EXC_job_grades: exclude && exclude.jobGrade,
    EXC_organization_level: exclude && exclude.organizationLevel,
    EXC_job_family: exclude && exclude.jobFamily,
    EXC_education: exclude && exclude.education,
    EXC_hobbies: exclude && exclude.hobby,

    EXC_salaryFrom: _salaryFromEX,
    EXC_salaryTo: _salaryToEX,
    isFilterSalaryExc: Boolean(_salaryFromEX || _salaryToEX) || false, // params untuk filter SALARY EXCLUDE, ketika menggunakan ubah jadi TRUE

    EXC_birthFrom: _ageMaxEX,
    EXC_birthTo: _ageMinEX,
    isFilterExcBirth: Boolean(_ageMinEX || _ageMaxEX) || false, // params untuk filter BIRTH EXCLUDE, ketika menggunakan ubah jadi TRUE
  }
  return variables
}
const filterVariable = (filterData, arrName, path, end) =>
  !!filterData &&
  !!filterData[path] &&
  !!filterData[path][arrName] &&
  !!filterData[path][arrName][end]

const isUseFilter = (filterData, arrName) =>
  filterVariable(filterData, arrName, 'include', 0) ||
  filterVariable(filterData, arrName, 'exclude', 0)

const isUseFilterRange = (filterData, arrName) =>
  filterVariable(filterData, arrName, 'include', 'min') ||
  filterVariable(filterData, arrName, 'include', 'max') ||
  filterVariable(filterData, arrName, 'exclude', 'min') ||
  filterVariable(filterData, arrName, 'exclude', 'max')

export const getDataVariableAlumni = filterData => {
  const {include, exclude} = filterData
  return {
    isOrganizationLevelFilterActive: isUseFilter(
      filterData,
      'organizationLevel'
    ),
    incOrganizationLevelIDs: include && include.organizationLevel,
    excOrganizationLevelIDs: exclude && exclude.organizationLevel,
    isOrganizationUnitFilterActive: isUseFilter(filterData, 'organizationUnit'),
    incOrganizationUnitIDs: include && include.organizationUnit,
    excOrganizationUnitIDs: exclude && exclude.organizationUnit,
    isJobLevelFilterActive: isUseFilter(filterData, 'jobLevel'),
    incJobLevelIDs: include && include.jobLevel,
    excJobLevelIDs: exclude && exclude.jobLevel,
    isJobGradeFilterActive: isUseFilter(filterData, 'jobGrade'),
    incJobGradeIDs: include && include.jobGrade,
    excJobGradeIDs: exclude && exclude.jobGrade,
    isJobTitleFilterActive: isUseFilter(filterData, 'jobTitle'),
    incJobTitleIDs: include && include.jobTitle,
    excJobTitleIDs: exclude && exclude.jobTitle,
    isJobFamilyFilterActive: isUseFilter(filterData, 'jobFamily'),
    incJobFamilyIDs: include && include.jobFamily,
    excJobFamilyIDs: exclude && exclude.jobFamily,
    isPositionFilterActive: isUseFilter(filterData, 'position'),
    incPositionIDs: include && include.position,
    excPositionIDs: exclude && exclude.position,
    isOfficeLocationFilterActive: isUseFilter(filterData, 'officeLocation'),
    incOfficeLocationIDs: include && include.officeLocation,
    excOfficeLocationIDs: exclude && exclude.officeLocation,
    isEmployeeTypeFilterActive: isUseFilter(filterData, 'employeeType'),
    incEmployeeTypes: include && include.employeeType,
    excEmployeeTypes: exclude && exclude.employeeType,
    isTimeArrangementFilterActive: isUseFilter(filterData, 'timeArrangement'),
    incTimeArrangements: include && include.employeeType,
    excTimeArrangements: exclude && exclude.timeArrangement,
    isWorkingArrangementFilterActive: isUseFilter(
      filterData,
      'workingArrangement'
    ),
    incWorkingArrangements: include && include.workingArrangement,
    excWorkingArrangements: exclude && exclude.workingArrangement,
    isSalaryFilterActive: isUseFilterRange(filterData, 'salary'),
    incMinSalary:
      include && include.salary && include.salary && include.salary.min,
    incMaxSalary:
      include && include.salary && exclude.salary && exclude.salary.max,
    excMinSalary:
      exclude && exclude.salary && exclude.salary && exclude.salary.min,
    excMaxSalary:
      exclude && exclude.salary && exclude.salary && exclude.salary.max,
    isAgeFilterActive: isUseFilterRange(filterData, 'age'),
    incMinBirthdate: include && include.age && include.age && include.age.min,
    incMaxBirthdate: include && include.age && exclude.age && exclude.age.max,
    excMinBirthdate: include && include.age && include.age && include.age.min,
    excMaxBirthdate: include && include.age && exclude.age && exclude.age.max,
    isGenderFilterActive: isUseFilter(filterData, 'gender'),
    incGenders: include && include.gender,
    excGenders: exclude && exclude.gender,
    isReligionFilterActive: isUseFilter(filterData, 'religion'),
    incReligionIDs: include && include.religion,
    excReligionIDs: exclude && exclude.religion,
    isEducationFilterActive: isUseFilter(filterData, 'education'),
    incEducationIDs: include && include.education,
    excEducationIDs: exclude && exclude.education,
    isHobbyFilterActive: isUseFilter(filterData, 'hobby'),
    incHobbyIDs: include && include.hobby,
    excHobbyIDs: exclude && exclude.hobby,
    incBadges: getFilterBadges(include?.talent?.checkbox),
    excBadges: getFilterBadges(exclude?.talent?.checkbox),
    incTalentScoreFrom: include?.talent?.range?.min,
    incTalentScoreTo: include?.talent?.range?.max,
    excTalentScoreFrom: exclude?.talent?.range?.min || 200,
    excTalentScoreTo: exclude?.talent?.range?.max || 300,
    isFilterTalent: isFilterTalent(filterData),
  }
}
