import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  EnhancedToolbar,
  RootContainer,
} from '../../../../GlobalStyles'
import {
  Typography,
  Divider,
  makeStyles,
  Paper,
  Icon,
  Button,
  Toolbar,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ChartDetail from './ChartDetail'
import BoxesMatrix from './Boxesmatrix'
import Committee from './Committee'
import TalentInformation from './TalentInformation'
import TableMaterial from './TableComAsp'
import moment from 'moment'
import Loading from '../../../../components/loading/LoadingComponent'
import {COMPANY_ID} from '../../../../utils/globals'
import {useSnackbar} from 'notistack'

// graphql
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_DETAIL_TALENT_CLASSIFICATION,
  GET_TALENT_DASHBOARD_INFORMATION,
  GET_ASPIRATION_DASHBOARD,
  GET_COMPETENCY_DASHBOARD,
  GET_LIST_TALENT_LIST,
  GET_TALENT_BOXES,
  GET_POSITION_TALENT_FILTERS,
  GET_BALOON_DOWNLOAD_TALENT_CLASSIFICATION,
  GET_BUTTON_DOWNLOAD_TALENT_CLASSIFICATION,
  GET_TALENT_CLASS_ID,
  GET_TALENT_CLASS_COMMITTEE,
} from '../../../../graphql/queries/index'
import {
  TALENT_CLASSIFICATION_REPORT,
  TALENT_CLOSE_BALOON_EXPORT,
} from '../../../../graphql/mutations'

// Component
import BaloonDownloadTalent from './BaloonDownloadTalent'
import DialogReportDownload from './DialogReportDownload'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginLeft: theme.spacing(2),
  },
  mb16: {
    marginBottom: '16px !important',
  },
  talentDetailTalentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
    padding: '2rem',
  },
  talentInfoPaper: {
    display: 'flex',
    flexGrow: '1',
    textAlign: 'center',
    flexDirection: 'row',
    width: '300px',
    height: '74px',
    borderRadius: '5px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    padding: 25,
    marginRight: 30,
    '&:nth-child(4)': {
      marginRight: 0,
    },
  },
  icon: {
    width: '70px',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  talentDetailInfo: {
    fontSize: 15,
    marginBottom: 10,
  },
  root: {
    padding: 24,
  },
  subtitle: {
    color: '#a9a8a8',
  },
  subIcon: {
    textAlign: 'left',
    marginLeft: '1.5rem',
    marginTop: '1rem',
  },
  btnLoading: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  subToolbar: {
    size: '12px',
    marginLeft: theme.spacing(2),
  },
}))

function ClassisficationDetail(props) {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const rowData = props.location.state.data
  const tableName = 'talent_report'

  const [isLoading, setIsLoading] = useState(false)
  const [boxes, setBoxes] = useState(null)
  const [listTalent, setListTalent] = useState(null)
  const [positionFilter, setPositionFilter] = useState([])
  const [open, setOpen] = useState(false)
  const [condTalentInformation, setCondTalentInformation] = useState({
    version: props.location.state.data.id,
    organization: null,
    office: null,
    position: null,
    jobProfile: null,
  })
  const [condAspiration, setCondAspiration] = useState({
    version: props.location.state.data.id,
    organization: null,
    office: null,
    position: null,
    jobProfile: null,
  })
  const [condCompetency, setCondCompetency] = useState({
    version: props.location.state.data.id,
    organization: null,
    office: null,
    position: null,
  })
  const [condCommittee, setCondCommittee] = useState({
    version: props.location.state.data.id,
    group: null,
    search: '',
    sort: 'asc',
  })
  const [condList, setCondList] = useState({
    version: props.location.state.data.id,
    organization: null,
    office: null,
    position: null,
    rank: null,
    badge: null,
    jobProfile: null,
  })

  const getDuration = years => {
    const dates = moment(new Date()).subtract(years, 'years')
    return dates.format('YYYY/MM/DD')
  }

  const variableFilter = state => {
    return {
      jobProfile: state.jobProfile === 'all' ? null : state.jobProfile,
      // office: state.office === 'all' ? null : state.office,
      // position: state.position === 'all' ? null : state.position,
    }
  }

  const isDisableDownload = dateAdded => {
    const present_date = new Date()
    const download_date = new Date(dateAdded)
    const oneHours = 1000 * 60 * 60
    const timeCalculate = present_date.getTime() - download_date.getTime()
    const limitDisiabled = timeCalculate / oneHours
    return limitDisiabled < 24
  }

  const [generateReport] = useMutation(TALENT_CLASSIFICATION_REPORT)
  const [closeBaloonExport] = useMutation(TALENT_CLOSE_BALOON_EXPORT)

  const {data: dataInformation} = useQuery(GET_TALENT_DASHBOARD_INFORMATION, {
    variables: {
      ...condTalentInformation,
      ...variableFilter(condTalentInformation),
    },
  })
  const {data: dataAspiration} = useQuery(GET_ASPIRATION_DASHBOARD, {
    variables: {...condAspiration, ...variableFilter(condAspiration)},
  })
  const {data: dataCompetency, error: errCompetency} = useQuery(
    GET_COMPETENCY_DASHBOARD,
    {
      variables: {...condCompetency, ...variableFilter(condCompetency)},
    }
  )

  const {data: dataList, error: errList} = useQuery(GET_LIST_TALENT_LIST, {
    variables: {
      ...condList,
      ...variableFilter(condList),
    },
    context: {
      headers: {
        'X-Hasura-Role': 'organization-staff',
      },
    },
  })

  const {data: dataBoxes, error: errBoxes} = useQuery(GET_TALENT_BOXES, {
    variables: {
      version: props.location.state.data.id,
      rank: condList.rank,
      ...variableFilter(condList),
    },
  })
  const {data: dataEmployee} = useQuery(GET_DETAIL_TALENT_CLASSIFICATION, {
    wlb_skipPatch: true,
    variables: {
      version: props.location.state.data.id,
      age18: getDuration(18),
      age24: getDuration(24),
      age25: getDuration(25),
      age34: getDuration(34),
      age35: getDuration(35),
      age49: getDuration(49),
      age50: getDuration(50),
    },
  })
  const {data: dataPosition} = useQuery(GET_POSITION_TALENT_FILTERS, {
    variables: {
      version: props.location.state.data.id,
    },
  })
  const {data: detailData, error: errorDetailData} = useQuery(
    GET_TALENT_CLASS_ID,
    {
      wlb_skipPatch: true,
      variables: {
        version: props.location.state.data.id,
      },
    }
  )
  const {data: committeeData, error: errorCommittee} = useQuery(
    GET_TALENT_CLASS_COMMITTEE,
    {
      variables: {
        version: props.location.state.data.id,
        group: condCommittee.group === 'all' ? null : condCommittee.group,
        search: `%${condCommittee.search}%`,
        sort: condCommittee.sort,
      },
      context: {
        headers: {
          'X-Hasura-Role': 'organization-hr-admin',
        },
      },
    }
  )
  const {data: dataBaloonDownload, error: errBaloonDownload} = useQuery(
    GET_BALOON_DOWNLOAD_TALENT_CLASSIFICATION,
    {
      wlb_skipPatch: true,
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: tableName,
        filter: {id: rowData.id},
      },
    }
  )
  const {data: dataStatusButton} = useQuery(
    GET_BUTTON_DOWNLOAD_TALENT_CLASSIFICATION,
    {
      wlb_skipPatch: true,
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: tableName,
        filter: {id: rowData.id},
      },
    }
  )

  const handleDownload = () => {
    setIsLoading(true)
    generateReport({
      variables: {
        table: tableName,
        filter: {id: rowData.id},
      },
    })
      .then(() => {
        enqueueSnackbar('Generate Data is processing!', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        setOpen(true)
      })
      .catch(() => {
        enqueueSnackbar('Generate Data failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const closeBaloonMutation = () => {
    closeBaloonExport({
      variables: {
        id:
          dataBaloonDownload.company_export_data_logs.length > 0 &&
          dataBaloonDownload.company_export_data_logs[0].id,
      },
    })
  }

  useEffect(() => {
    if (dataList && dataBoxes && dataPosition) {
      setBoxes(dataBoxes)
      setListTalent(dataList)

      const tempFilter = []
      dataPosition.talent_candidate_groups.map(val => {
        tempFilter.push({
          id: val.company_job_profile.id,
          name: val.company_job_profile.title,
          code: val.company_job_profile.code,
        })
      })
      setPositionFilter(tempFilter)
    }
  }, [dataList, dataBoxes, dataPosition])

  if (
    (dataList === undefined && errList === undefined) ||
    (dataBoxes === undefined && errBoxes === undefined) ||
    (dataCompetency === undefined && errCompetency === undefined) ||
    (dataBaloonDownload === undefined && errBaloonDownload === undefined) ||
    (detailData === undefined && errorDetailData === undefined) ||
    (committeeData === undefined && errorCommittee === undefined)
  ) {
    return <Loading />
  } else if (
    errList !== undefined ||
    errBoxes !== undefined ||
    errCompetency !== undefined ||
    errBaloonDownload !== undefined ||
    errorDetailData !== undefined ||
    errorCommittee !== undefined
  ) {
    return (
      // <div>{errorCommittee}</div>
      <div>
        {errList ||
          errBoxes ||
          errCompetency ||
          errBaloonDownload ||
          errorDetailData ||
          errorCommittee}
      </div>
    )
  }

  const isDisable = isDisableDownload(
    dataStatusButton &&
      dataStatusButton.company_export_data_logs &&
      dataStatusButton.company_export_data_logs[0] &&
      dataStatusButton.company_export_data_logs[0].date_added
  )

  const details =
    detailData && detailData.talent_versions.length > 0
      ? detailData.talent_versions[0]
      : null

  return (
    <RootContainer>
      {dataBaloonDownload &&
        dataBaloonDownload.company_export_data_logs.length > 0 && (
          <BaloonDownloadTalent
            baloonData={
              dataBaloonDownload && dataBaloonDownload.company_export_data_logs
            }
            closeBaloonMutation={closeBaloonMutation}
            retry={handleDownload}
          />
        )}
      <PaperContainer>
        <EnhancedToolbar>
          <Toolbar disableGutters>
            <IconButton
              edge="end"
              aria-label="back"
              component={Link}
              to={{
                pathname: '/talent/classification',
                state: {active: 'classification', searchable: true},
              }}
            >
              <ArrowBack />
            </IconButton>
            <div>
              <Typography variant="h6" className={classes.backButton}>
                {props.location.state.data.name} Detail
              </Typography>
              {details && details.globalUserAdded && (
                <Typography className={classes.subToolbar}>
                  Created by{' '}
                  <span style={{color: '#039be5'}}>
                    {details.globalUserAdded.name}
                  </span>{' '}
                  at {moment(details.date_added).format('LL')} &bull;{' '}
                  {moment(details.date_added).format('LT')}
                </Typography>
              )}
              {details && details.globalUserModified && (
                <Typography className={classes.subToolbar}>
                  Last Edited by{' '}
                  <span style={{color: '#039be5'}}>
                    {details.globalUserModified.name}
                  </span>{' '}
                  at {moment(details.date_modified).format('LL')} &bull;{' '}
                  {moment(details.date_modified).format('LT')}
                </Typography>
              )}
            </div>
          </Toolbar>
          <Toolbar>
            {rowData && rowData.status === 'COMPLETED' && (
              <div className={classes.btnLoading}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.input}
                  disabled={isLoading || isDisable}
                  onClick={() => handleDownload()}
                >
                  Generate Report
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            )}
          </Toolbar>
        </EnhancedToolbar>
        <Divider />
        <div className={classes.talentDetailTalentInfo}>
          <Paper className={classes.talentInfoPaper}>
            <div
              className={classes.icon}
              style={{
                border: 'solid 2px #055464',
              }}
            >
              <Icon
                style={{
                  fontSize: '35px',
                  color: '#055464',
                }}
              >
                people
              </Icon>
            </div>
            <div className={classes.subIcon}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: '600',
                  color: '#055464',
                }}
              >
                {dataEmployee && dataEmployee.total_employee.aggregate.count}
              </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Total Employee
              </Typography>
            </div>
          </Paper>
          <Paper className={classes.talentInfoPaper}>
            <div
              className={classes.icon}
              style={{
                border: 'solid 2px #039be5',
              }}
            >
              <Icon
                style={{
                  fontSize: '35px',
                  color: '#039be5',
                }}
              >
                device_hub
              </Icon>
            </div>
            <div className={classes.subIcon}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: '600',
                  color: '#039be5',
                }}
              >
                {dataEmployee && dataEmployee.total_departement.aggregate.count}
              </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Total Department
              </Typography>
            </div>
          </Paper>

          <Paper className={classes.talentInfoPaper}>
            <div
              className={classes.icon}
              style={{
                border: 'solid 2px #ffa000',
              }}
            >
              <Icon
                style={{
                  fontSize: '35px',
                  color: '#ffa000',
                }}
              >
                accessibility
              </Icon>
            </div>
            <div className={classes.subIcon}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: '600',
                  color: '#ffa000',
                }}
              >
                {dataEmployee && dataEmployee.total_designation.aggregate.count}
              </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Total Position
              </Typography>
            </div>
          </Paper>
        </div>
        <ChartDetail dataEmployee={dataEmployee} />
        <Committee
          data={committeeData}
          position={dataPosition}
          setCondList={setCondCommittee}
          condList={condCommittee}
        />
        <BoxesMatrix
          dataEmployee={listTalent}
          dataBoxes={boxes}
          newHeight={dataBoxes && dataBoxes.talent_versions[0].box_height}
          newWidth={dataBoxes && dataBoxes.talent_versions[0].box_width}
          position={positionFilter}
          setCondList={setCondList}
          condList={condList}
        />
        <TalentInformation
          data={dataInformation}
          position={positionFilter}
          condTalentInformation={condTalentInformation}
          setCondTalentInformation={setCondTalentInformation}
        />
        <TableMaterial
          dataAspiration={dataAspiration && dataAspiration.company_job_profiles}
          dataCompetency={
            dataCompetency && dataCompetency.people_work_placements
          }
          position={positionFilter}
          setCondAspiration={setCondAspiration}
          setCondCompetency={setCondCompetency}
          condAspiration={condAspiration}
          condCompetency={condCompetency}
        />
        <DialogReportDownload open={open} handleClose={() => setOpen(false)} />
      </PaperContainer>
    </RootContainer>
  )
}

export default withRouter(ClassisficationDetail)
