import gql from 'graphql-tag'

export const GET_DETAIL_JOB_PROFILE = gql`
  query($id: Int) {
    company_job_profiles(where: {id: {_eq: $id}}) {
      id
      code
      title
      job_summary
      job_description
      job_icon
      slots
      experience_level
      parent
      rate_salary_from
      rate_salary_to
      attachment
      as_assistant
      line_type
      job_grade {
        id
        code
        name
      }
      require_successor
      acting_as {
        id
        code
        title
      }
      company_organization {
        id
        name
        code
      }
      company_employee_position {
        id
        designation_code
        name
      }
      company_employee_rank {
        id
        code
        name
      }
      company_address {
        id
        office_code
        office_name
      }
      company_job_profile_r_educations {
        id
        global_degree {
          id
          name
        }
        global_faculty {
          id
          name
        }
      }
      company_job_profile_competencies(
        where: {
          competency_version_dictionary: {
            competency_version: {status: {_eq: "ACTIVE"}}
          }
        }
      ) {
        id
        competency_version_dictionary {
          id
          competency_dictionary {
            id
            name
            description
            type
          }
        }
        level
      }
      company_job_profile_r_course {
        id
        academy_course {
          id
          title
        }
      }
      company_job_profile_r_kpi {
        id
        kpi_list {
          id
          name
        }
      }
      roles
      job_profile_supervisor {
        id
        code
        organization
        title
      }
      people_work_placements_aggregate(where: {status: {_eq: "ACTIVE"}}) {
        aggregate {
          count
        }
      }
      job_profile_subordinate_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`
