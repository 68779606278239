import {Button, Divider, Typography} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useEffect, useState} from 'react'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  //   GreyTypography,
  Flex,
  SkyBlueIcon,
  GreyIcon,
} from '../../../../GlobalStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Link} from 'react-router-dom'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {GET_LIST_COURSE} from '../../../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import defaultImg from '../../../../assets/images/course-default-thumbnail.jpg'
import {capitalize} from '../../../../utils/helpers'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {DELETE_MICROLEARNING} from '../../../../graphql/mutations/learning/micro-learning/deleteMicrolearning.mutation'
import {useSnackbar} from 'notistack'
import {status_data} from '../../../shared-components/data/SharedData'

const styles = {
  avatar: {
    width: 42,
    height: 42,
    margin: '0 24px 0 0',
    borderRadius: 5,
    objectFit: 'cover',
  },
}
const ListComponent = ({search}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })
  const [openDelete, setOpenDelete] = useState(false)
  const [state, setState] = useState({
    id: '',
    name: '',
  })
  const {data, loading, error, refetch} = useQuery(GET_LIST_COURSE, {
    variables: {
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      company: COMPANY_ID,
      author: USER_ID,
      search: `%${search}%`,
    },
  })

  const [deleteMicroLearning] = useMutation(DELETE_MICROLEARNING)

  useEffect(() => {
    if (data) {
      refetch()
    }
  }, [data])

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleClose = () => {
    setOpenDelete(false)
  }

  const handleMutation = () => {
    deleteMicroLearning({
      variables: {
        id: state.id,
      },
    })
      .then(() => {
        enqueueSnackbar('Data deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(e =>
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
        })
      )
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Kursus</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: '/learning/course/add',
            }}
          >
            Buat Kursus
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider></Divider>
      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0'}}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
        columns={[
          {
            title: 'Nama Kursus',
            field: 'name',
            render: row => (
              <Flex style={{width: 250}}>
                <img
                  src={row.icon_url ? row.icon_url : defaultImg}
                  style={styles.avatar}
                />
                {row.title}
              </Flex>
            ),
          },
          {
            title: 'Dibuat Pada',
            field: 'date_added',
            type: 'date',
          },
          {
            title: 'Diikuti',
            field: 'count_enrollment',
          },
          /*  {
            title: 'Finished',
            field: 'finish.aggregate.count',
          }, */
          {
            title: 'Penilaian',
            field: 'microlearning_reviews_aggregate.aggregate.avg.star',
            render: row => (
              <Flex>
                {row.average_rating > 0 ? (
                  <SkyBlueIcon style={{marginRight: 12}}>star</SkyBlueIcon>
                ) : (
                  <GreyIcon style={{marginRight: 12}}>star</GreyIcon>
                )}
                {row.average_rating ? row.average_rating.toFixed(1) : 0}
              </Flex>
            ),
          },
          {
            title: 'Status',
            field: 'status',
            render: row => (
              <div
                style={{
                  padding: '6px 22px 5px',
                  borderRadius: 5,
                  backgroundColor:
                    row.status === 'active'
                      ? '#4caf50'
                      : row.status === 'pending'
                      ? '#ffa000'
                      : row.status === 'draft'
                      ? '#a9a8a8'
                      : '#ef4d5e',
                  color: 'white',
                  fontSize: 12,
                  wordBreak: 'initial',
                  textAlign: 'center',
                  width: 85,
                }}
              >
                {status_data[row.status?.toUpperCase()] || capitalize(row.status)}
              </div>
            ),
          },
          {
            title: 'Aksi',
            field: 'id',
            sorting: false,
            render: rowData => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={'/learning/course/detail/' + rowData.id}
                      >
                        Rincian
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={'/learning/course/edit/' + rowData.id}
                      >
                        Ubah
                      </StyledMenuItem>
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            ),
          },
        ]}
        data={data && data.academy_courses}
        title=""
        options={{
          ...TableOptions,
          selection: false,
          emptyRowsWhenPaging: false,
        }}
        components={{
          Toolbar: () => null,
        }}
        isLoading={loading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={data && data.total.aggregate.count}
        page={pagination.offset}
        pageSize={pagination.limit}
      />
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        mutation={handleMutation}
        id={state.id}
        name={state.name}
      />
    </PaperContainer>
  )
}

export default ListComponent
