import gql from "graphql-tag";

export const LIST_RACE = gql`
  query getGlobalRace($search: String, $nin: [Int!]) {
    global_race(
      where: { name: { _ilike: $search }, id: { _nin: $nin } }
      limit: 20
      order_by: { id: asc }
    ) {
      id
      name
    }
  }
`;

export const LIST_DIALECT = gql`
  query getGlobalDialects($search: String, $nin: [Int!]) {
    global_dialects(
      where: { name: { _ilike: $search }, id: { _nin: $nin } }
      limit: 20
      order_by: { id: asc }
    ) {
      id
      name
    }
  }
`;
