import React, {useState} from 'react'
import {
  makeStyles,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  Grid,
  FormControlLabel,
  Checkbox,
  Divider,
  Button,
  Tooltip,
  Badge,
} from '@material-ui/core'
import {TextBlackBold, FlexBetween, Flex} from '../../../../../../GlobalStyles'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(() => ({
  my20: {
    margin: '20px 0px',
  },
  my5: {
    margin: '5px 0px',
  },
  my10: {
    margin: '10px 0px !important',
  },
  noListText: {
    textAlign: 'center',
    color: '#a9a8a8 !important',
    margin: '20px 0px',
  },
  moreText: {
    fontWeight: 'normal !important',
    color: '#a9a8a8 !important',
    fontStyle: 'italic',
  },
  unselectText: {
    fontWeight: 'normal !important',
    color: '#a9a8a8 !important',
    cursor: 'pointer',
  },
  selectAllText: {
    fontWeight: 'normal !important',
    color: '#039be5 !important',
    cursor: 'pointer',
  },
}))

const ModalAddCandidate = props => {
  const classes = useStyles()
  const {handleSave, position, setPosition, setOpen} = props
  const [value, setValue] = useState(0)
  const [searchText, setSearchText] = useState('')

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  const handlePositionSearch = e => {
    setSearchText(e.target.value)
  }

  const handleChange = id => e => {
    let array = position
    const index = array.findIndex(item => item.id === id)
    array[index] = {...array[index], checked: e.target.checked}
    setPosition([...array])
  }

  const handleSelectAll = val => {
    let check = []
    position.map(res => {
      check.push({
        ...res,
        checked: val,
      })
    })

    setPosition(check)
  }

  const handleApply = val => {
    handleSave(val)
    setOpen(false)
  }

  const positionList =
    position &&
    position.filter(item => {
      return (
        item.title.toLowerCase().includes(searchText.toLowerCase()) &&
        !item.checked
      )
    })

  const selectedPosition =
    position &&
    position.filter(item => {
      return (
        item.title.toLowerCase().includes(searchText.toLowerCase()) &&
        item.checked
      )
    })

  return (
    <>
      <div style={{backgroundColor: '#014a62', color: '#fff'}}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab label="Organization Unit" />
        </Tabs>
      </div>
      {value === 0 ? (
        <div style={{padding: '26px 30px'}}>
          <TextField
            variant="outlined"
            placeholder="Search"
            size="small"
            fullWidth
            className={classes.my20}
            value={searchText}
            onChange={handlePositionSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FlexBetween>
            <TextBlackBold>Selected ({selectedPosition.length})</TextBlackBold>
            <TextBlackBold
              className={classes.unselectText}
              onClick={() => handleSelectAll(false)}
            >
              Unselect all
            </TextBlackBold>
          </FlexBetween>
          <Grid container className={classes.my5}>
            {selectedPosition.length > 0 ? (
              selectedPosition.map((val, key) => {
                return (
                  <Grid item xs={3} key={key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={true}
                          onChange={handleChange(val.id)}
                          color="primary"
                          size="small"
                        />
                      }
                      label={
                        <Tooltip title={val.title} placement="right">
                          <TextBlackBold>
                            {val.title.length > 20
                              ? `${val.title.slice(0, 20)} ...`
                              : val.title}
                          </TextBlackBold>
                        </Tooltip>
                      }
                    />
                  </Grid>
                )
              })
            ) : (
              <Grid item xs={12}>
                <TextBlackBold className={classes.noListText}>
                  No Selected
                </TextBlackBold>
              </Grid>
            )}
          </Grid>
          <Divider className={classes.my10} />
          <FlexBetween>
            <TextBlackBold>Suggested</TextBlackBold>
            <TextBlackBold
              className={classes.selectAllText}
              onClick={() => handleSelectAll(true)}
            >
              Select all
            </TextBlackBold>
          </FlexBetween>
          <Grid container className={classes.my5}>
            {positionList.length > 0 ? (
              positionList.slice(0, 12).map((val, key) => {
                return (
                  <Grid item xs={3} key={key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={false}
                          onChange={handleChange(val.id)}
                          color="primary"
                          size="small"
                        />
                      }
                      label={
                        <Tooltip title={val.title} placement="right">
                          <TextBlackBold>
                            {val.title.length > 20
                              ? `${val.title.slice(0, 20)} ...`
                              : val.title}
                          </TextBlackBold>
                        </Tooltip>
                      }
                    />
                  </Grid>
                )
              })
            ) : (
              <Grid item xs={12}>
                <TextBlackBold className={classes.noListText}>
                  No Selected
                </TextBlackBold>
              </Grid>
            )}
          </Grid>
          <TextBlackBold
            className={classes.moreText}
            style={{marginBottom: '20px'}}
          >
            {positionList.length > 12
              ? `and ${positionList.length - 12} hidden items`
              : ''}
          </TextBlackBold>
          <Divider className={classes.my10} />
          <Flex style={{justifyContent: 'flex-end'}}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{marginLeft: '10px'}}
              disabled={selectedPosition.length === 0}
              onClick={() => handleApply(selectedPosition)}
            >
              Apply
            </Button>
          </Flex>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default ModalAddCandidate
