import gql from 'graphql-tag'

export const GET_NOMINATION_HOLDING_TALENT = gql`
  query getHoldingNomination($company: uuid, $status: String, $search: String) {
    holding_nomination_config(
      where: {
        head_company: {_eq: $company}
        status: {_eq: $status}
        _or: [
          {name: {_ilike: $search}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
    ) {
      id
      name
      status
      company_profile {
        id
        brand_name
        legal_name
        logo
      }
      company_job_profile {
        id
        title
      }
    }
  }
`
export const GET_LIST_NOMINATION_INVITE = gql`
  query getListHoldingNomination(
    $company: uuid
    $source_id: Int
    $search: String
    $pwp_id: Int
  ) {
    holding_nomination_config(
      where: {
        head_company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _not: {
          _or: [
            {holding_nomination_people: {source_id: {_eq: $source_id}}}
            {holding_nomination_commites: {user_work_id: {_eq: $pwp_id}}}
          ]
        }
        _or: [
          {name: {_ilike: $search}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
    ) {
      id
      name
      company_profile {
        id
        brand_name
        legal_name
      }
      company_job_profile {
        id
        title
      }
    }
  }
`
