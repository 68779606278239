import ListPageComponent from './ListPageComponent'

export const ListPageConfig = {
  routes: [
    {
      path: '/member/list',
      component: ListPageComponent,
      state: 'member-list',
      exact: true,
    },
    {
      path: '/member/pending',
      component: ListPageComponent,
      state: 'member-pending',
      exact: true,
    },
    {
      path: '/member/configs',
      component: ListPageComponent,
      state: 'member-config',
      exact: true,
    },
    {
      path: '/member/list/add-company',
      component: ListPageComponent,
      state: 'add-company',
      exact: true,
    },
    {
      path: '/member/pending/add-company',
      component: ListPageComponent,
      state: 'add-company',
      exact: true,
    },
    {
      path: '/member/list/:id',
      component: ListPageComponent,
      state: 'detail-company',
      exact: true,
    },
  ],
}
