import React, {useState, useEffect} from 'react'
import helperStyle, {
  OrganizationContainer,
} from '../../../OrganizationPageStyles'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
  TextHelper,
  GreyTypography,
} from '../../../../../GlobalStyles'
import {
  GET_LIST_ORGANIZATION,
  LIST_JOB_PROFILE_TREE,
  GET_OFFICE_DROPDOWN,
  GET_LIST_ORG_LEVEL,
  GET_DROPDOWN_DESIGNATION,
  GET_LIST_JOB_GRADE,
  GET_JOBPROFILE_CHILD_COUNT,
} from '../../../../../graphql/queries'
import {withRouter} from 'react-router-dom'
import CKEditor from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import {
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  Radio,
  Tooltip,
  withStyles,
  Typography,
  RadioGroup,
  FormHelperText,
} from '@material-ui/core'
import ModalGlobalIcon from '../../../../../pages/shared-components/popup/GlobalIcon'
import Select from 'react-select'
import {useQuery} from '@apollo/react-hooks'
import IconChooser from '../../../../shared-components/popup/IconChooserPopup'
import NewParentPopup from '../../../../shared-components/popup/NewParentPopup'
import ClassNames from 'classnames'
import {COMPANY_ID} from '../../../../../utils/globals'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import AddIcon from '@material-ui/icons/Add'
import documents from '../../../../../assets/images/document.svg'
import pdfIcon from '../../../../../assets/images/font-awesome/file-pdf.svg'
import excelIcon from '../../../../../assets/images/font-awesome/file-excel.svg'
import pptIcon from '../../../../../assets/images/font-awesome/file-powerpoint.svg'
import ClearIcon from '@material-ui/icons/Clear'
import {fileChecker} from '../../../../../utils/helpers'
import FieldInformationTooltip from '../../../../shared-components/tooltip/FieldInformationTooltip'

const LINE_TYPE_OPTIONS = [
  {value: 1, label: 'Garis Solid'},
  {value: 2, label: 'Garis putus-putus'},
]

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
}

const InfoTooltip = withStyles(() => ({
  tooltip: {
    padding: '12px 14px',
    backgroundColor: '#eff2f4',
    color: '#000',
    fontSize: 14,
    borderRadius: 5,
  },
  arrow: {
    color: '#eff2f4',
    backgroundColor: '#eff2f4',
  },
}))(Tooltip)

const TabJobProfile = props => {
  const classes = helperStyle()
  const {
    // id,
    // jobName,
    setAllData,
    allDatas,
    dataEdit,
    isError,
    status,
    isParent,
    setIsParent,
    // nameChecker,
    codeChecker,
    // parentName,
    // checkSameNameWithAdd,
    checkSameCodeWithAdd,
    parentChecker,
    idParentOUJobProfile,
    idParentTopOU,
    setVariable,
    menu,
    valueAttachment,
    setValueAttachment,
  } = props
  const [openImport, setImport] = useState(false)
  const [loading, setLoading] = useState(0)
  const [openImage, setOpenImage] = useState(false)
  const [popupNewParent, setPopupNewParent] = useState({
    idOrg: null,
    openPopup: false,
  })
  const [errorSize, setErrorSize] = useState(false)
  const [errorFile, setErrorFile] = useState(false)

  const handleClickOpenImport = () => {
    setImport(!openImport)
  }

  const handleSetNewParent = () => {
    if (popupNewParent.openPopup) {
      setPopupNewParent({
        idOrg: null,
        openPopup: false,
      })
      setIsParent(false)
    } else {
      setPopupNewParent({
        idOrg: dataEdit?.company_organization?.id,
        openPopup: true,
      })
    }
  }

  // const [organizationName, setOrganizationName] = useState('')
  const dataMerge = dataEdit
    ? {
        positionJob: dataEdit?.company_employee_position?.name,
        office: dataEdit?.company_address?.office_name,
        organization: dataEdit?.company_organization?.name,
      }
    : {
        positionJob: '',
        office: '',
        organization: '',
      }

  const [mergePosition, setMergePosition] = useState(dataMerge)
  const [parentDropdown, setParentDropdown] = useState(null)
  const [lineTypeDropdown, setLineTypeDropdown] = useState(LINE_TYPE_OPTIONS[0])
  const [positionDropdown, setPositionDropdown] = useState(null)
  const [rankDropdown, setRankDropdown] = useState(null)
  const [officeDropdown, setOfficeDropdown] = useState(null)
  const [organizationDropdown, setOrganizationDropdown] = useState(null)
  const [gradeDropdown, setGradeDropdown] = useState(null)
  const [actingDropdown, setActingDropdown] = useState(null)
  const [searchDropdown, setSearchDropdown] = useState({
    organzationUnit: '',
    parent: '',
    jobTitle: '',
    jobLevel: '',
    jobGrade: '',
    officeLocation: '',
  })
  // const [parentnNameState, setparentnNameState] = useState(
  //   parentChecker ? parentChecker.title : 'No Parent Selected'
  // )

  useEffect(() => {
    let ids = ''
    if (
      (allDatas && !allDatas.code && isError) ||
      (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
    ) {
      ids = document.getElementById('code')
      ids && ids.scrollIntoView({behavior: 'smooth'})
    } else if (
      allDatas &&
      !allDatas.title &&
      isError
      // || (nameChecker && isError)
    ) {
      ids = document.getElementById('name')
      ids && ids.scrollIntoView({behavior: 'smooth'})
    }
    // else if (allDatas && !allDatas.icon && isError) {
    //   ids = document.getElementById('icon')
    //   ids.scrollIntoView({behavior: 'smooth'})
    // }
    else if (allDatas && !allDatas.position && isError) {
      ids = document.getElementById('designation')
      ids && ids.scrollIntoView({behavior: 'smooth'})
    } else if (allDatas && !allDatas.rank && isError) {
      ids = document.getElementById('level')
      ids && ids.scrollIntoView({behavior: 'smooth'})
    }
    // else if (allDatas && !allDatas.grade && isError) {
    //   ids = document.getElementById('grade')
    //   ids.scrollIntoView({behavior: 'smooth'})
    // }
    else if (allDatas && !allDatas.office && isError) {
      ids = document.getElementById('office')
      ids && ids.scrollIntoView({behavior: 'smooth'})
    }
    // else if (
    //   allDatas &&
    //   !allDatas.require_successor &&
    //   allDatas.require_successor !== false
    // ) {
    //   ids = document.getElementById('successor')
    //   ids.scrollIntoView({behavior: 'smooth'})
    // }
    // else if (allDatas && !allDatas.jobdesc && isError) {
    //   ids = document.getElementById('description')
    //   ids.scrollIntoView({behavior: 'smooth'})
    // } else if (allDatas && !allDatas.jobresp && isError) {
    //   ids = document.getElementById('respon')
    //   ids.scrollIntoView({behavior: 'smooth'})
    // }
  }, [isError])

  const {data: parent, loading: parentLoad, error: parentError} = useQuery(
    LIST_JOB_PROFILE_TREE,
    {
      variables: {
        id_nin: dataEdit ? [dataEdit.id] : undefined,
        search: `%${searchDropdown.parent}%`,
        organization: null,
        offset: 0,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  useEffect(() => {
    if (status === 'Add') {
      if (!allDatas) {
        setAllData({
          code: '',
          title: '',
          icon: '',
          parent: '',
          organization: '',
          position: '',
          rank: '',
          office: '',
          jobdesc: '',
          jobresp: '',
          asAssistant: false,
          lineType: 0,
          require_successor: false,
        })
      }
    } else if (status === 'Edit') {
      if (!allDatas) {
        const parent = dataEdit && !dataEdit.parent

        setIsParent(parent)

        setAllData({
          ...allDatas,
          code: dataEdit.code,
          title: dataEdit.title,
          icon: dataEdit.job_icon,
          jobdesc: dataEdit.job_description,
          jobresp: dataEdit.job_summary,
          parent: dataEdit?.job_profile_supervisor?.id,
          parentLabel: dataEdit?.job_profile_supervisor?.title,
          parentCode: dataEdit?.job_profile_supervisor?.code,
          asAssistant: dataEdit?.as_assistant || false,
          lineType: dataEdit?.line_type,
          organization: dataEdit?.company_organization?.id,
          organizationLabel: dataEdit?.company_organization?.name,
          organizationCode: dataEdit?.company_organization?.code,
          position: dataEdit?.company_employee_position?.id,
          positionLabel: dataEdit?.company_employee_position?.name,
          positionCode: dataEdit?.company_employee_position?.designation_code,
          rank: dataEdit?.company_employee_rank?.id,
          rankLabel: dataEdit?.company_employee_rank?.name,
          rankCode: dataEdit?.company_employee_rank?.code,
          office: dataEdit?.company_address?.id,
          officeLabel: dataEdit?.company_address?.office_name,
          officeCode: dataEdit?.company_address?.office_code,
          grade: dataEdit?.job_grade?.id,
          gradeLabel: dataEdit?.job_grade?.name,
          gradeCode: dataEdit?.job_grade?.code,
          acting_as: dataEdit?.acting_as?.id,
          acting_asLabel: dataEdit?.acting_as?.title,
          acting_asCode: dataEdit?.acting_as?.code,
          require_successor:
            dataEdit &&
            (dataEdit.require_successor || !dataEdit.require_successor)
              ? dataEdit.require_successor !== null
                ? dataEdit.require_successor
                : false
              : false,
        })

        if (dataEdit?.line_type != null) {
          const lineType =
            LINE_TYPE_OPTIONS.find(item => item.value === dataEdit.line_type) ||
            LINE_TYPE_OPTIONS[0]
          setLineTypeDropdown(lineType)
        }

        if (dataEdit?.attachment) {
          setValueAttachment(
            dataEdit.attachment.map(res => ({
              name: res.name,
              link: res.link,
              size: res.size,
              type: fileChecker(res.name),
            }))
          )
        }
      }
    }

    if (status === 'Edit' && !allDatas) {
      // do nothing
    } else {
      setParentDropdown(
        allDatas?.parent
          ? {
              value: allDatas.parent,
              label: `${allDatas.parentCode} - ${allDatas.parentLabel}`,
            }
          : null
      )

      setPositionDropdown(
        allDatas?.position
          ? {
              value: allDatas.position,
              label: `${allDatas.positionCode} - ${allDatas.positionLabel}`,
            }
          : null
      )

      setRankDropdown(
        allDatas?.rank
          ? {
              value: allDatas.rank,
              label: `${allDatas.rankCode} - ${allDatas.rankLabel}`,
            }
          : null
      )

      setOfficeDropdown(
        allDatas?.office
          ? {
              value: allDatas.office,
              label: `${allDatas.officeCode} - ${allDatas.officeLabel}`,
            }
          : null
      )

      setActingDropdown(
        allDatas?.acting_as
          ? {
              value: allDatas.acting_as,
              label: `${allDatas.acting_asCode} - ${allDatas.acting_asLabel}`,
            }
          : null
      )

      setGradeDropdown(
        allDatas?.grade
          ? {
              value: allDatas.grade,
              label: `${allDatas.gradeCode} - ${allDatas.gradeLabel}`,
            }
          : null
      )

      setOrganizationDropdown(
        allDatas?.organization
          ? {
              value: allDatas.organization,
              label: `${allDatas.organizationCode} - ${allDatas.organizationLabel}`,
              name: allDatas.organizationLabel,
              id: allDatas.organization,
            }
          : null
      )
    }
  }, [setIsParent, status, parent])

  const {data: dataGrade, loading: loadingGrade, error: errorGrade} = useQuery(
    GET_LIST_JOB_GRADE,
    {
      variables: {
        companyId: COMPANY_ID,
        search: `%${searchDropdown.jobGrade}%`,
        offset: 0,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  // useEffect(() => {
  //   const setRefetch = setTimeout(() => {
  //     refetch()
  //   }, 2000)

  //   return () => {
  //     clearTimeout(setRefetch)
  //   }
  // }, [])

  const {
    data: position,
    loading: positionLoad,
    error: positionError,
  } = useQuery(GET_DROPDOWN_DESIGNATION, {
    variables: {
      search: `%${searchDropdown.jobTitle}%`,
      offset: 0,
      limit: 30,
    },
    fetchPolicy: 'cache-and-network',
  })

  const {
    data: organizations,
    loading: organizationsLoad,
    error: organizationsError,
  } = useQuery(GET_LIST_ORGANIZATION, {
    variables: {
      company: COMPANY_ID,
      search: `%${searchDropdown.organzationUnit}%`,
      offset: 0,
      limit: 30,
    },
    fetchPolicy: 'cache-and-network',
  })

  const {data: rank, loading: rankLoad, error: rankError} = useQuery(
    GET_LIST_ORG_LEVEL,
    {
      variables: {
        search: `%${searchDropdown.jobLevel}%`,
        offset: 0,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {data: office, loading: officeLoad, error: officeError} = useQuery(
    GET_OFFICE_DROPDOWN,
    {
      variables: {
        company: COMPANY_ID,
        search: `%${searchDropdown.officeLocation}%`,
        offset: 0,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const {data: childCount, error: childCountError} = useQuery(
    GET_JOBPROFILE_CHILD_COUNT,
    {
      fetchPolicy: 'cache-and-network',
      skip: !allDatas?.parent || !allDatas?.organization,
      variables: {
        jobProfileId: allDatas?.parent,
        organizationId: allDatas?.organization,
      },
    }
  )

  if (
    (parent === undefined && parentError === undefined) ||
    (position === undefined && positionError === undefined) ||
    (rank === undefined && rankError === undefined) ||
    (office === undefined && officeError === undefined) ||
    (organizations === undefined && organizationsError === undefined) ||
    (dataGrade === undefined && errorGrade === undefined)
  ) {
    return <CircularProgress />
  } else if (
    parentError ||
    organizationsError ||
    positionError ||
    rankError ||
    officeError ||
    errorGrade ||
    childCountError
  ) {
    console.error(
      parentError ||
        organizationsError ||
        positionError ||
        rankError ||
        officeError ||
        errorGrade ||
        childCountError
    )

    return <div style={{padding: 24}}>Something went wrong.</div>
  }

  const isOnlyChild =
    childCount && childCount.profile.subprofiles.aggregate.count <= 1

  const isParentOrgInequal =
    !childCount || childCount.profile.organization !== allDatas.organization

  const isUnsuitableAssistant =
    allDatas &&
    (!dataEdit || !dataEdit.as_assistant) &&
    !allDatas.asAssistant &&
    isOnlyChild

  const jobOptions =
    parent &&
    parent.company_job_profiles.map(item => ({
      value: item.id,
      label: `${item.code} - ${item.title}`,
      name: item.title,
      organizationName: item.company_organization?.name,
      organizationID: item.company_organization?.id,
      parentName: item.job_profile_supervisor?.title,
    }))

  const organizationOptions =
    organizations &&
    organizations.company_organizations.map(item => ({
      value: item.id,
      label: `${item.code} - ${item.name}`,
      name: item.name,
      id: item.id,
    }))

  const positionOptions =
    position &&
    position.company_employee_positions.map(item => ({
      value: item.id,
      label: `${item.designation_code} - ${item.name}`,
      name: item.name,
    }))

  const rankOptions =
    rank &&
    rank.company_employee_ranks.map(item => ({
      value: item.id,
      label: `${item.code} - ${item.name}`,
    }))

  const officeOptions =
    office &&
    office.company_addresses.map(item => ({
      value: item.id,
      label: `${item.office_code} - ${item.office_name}`,
      name: item.office_name,
    }))

  const gradeOptions =
    dataGrade &&
    dataGrade.job_grades.map(item => ({
      value: item.id,
      label: `${item.code} - ${item.name}`,
    }))

  const addDataParent = event => {
    setAllData({
      ...allDatas,
      asAssistant: false,
      parent: event.value,
      parentLabel: event.label,
      // organization: dataEdit
      //   ? dataEdit.company_organization && dataEdit.company_organization.id
      //   : menu === 'position'
      //   ? organizationDropdown.value
      //   : id,
    })
    // setOrganizationName(event.organizationName)
    // setparentnNameState(
    //   dataEdit
    //     ? dataEdit.job_profile_supervisor &&
    //         dataEdit.job_profile_supervisor.title
    //     : event.parentName
    // )
    setParentDropdown({
      label: event.label,
      value: event.value,
    })
  }

  const addDataPosition = event => {
    const org = mergePosition.organization

    setMergePosition({
      ...mergePosition,
      // positionJob: event.name + ', ' + org,
      positionJob: event.name,
    })
    setAllData({
      ...allDatas,
      position: event.value,
      positionLabel: event.name,
      title: `${event.name}, ${org} ${mergePosition.office}`,
    })
    setPositionDropdown({
      label: event.label,
      value: event.value,
    })
  }

  const addDataOrganization = event => {
    setAllData({
      ...allDatas,
      organization: event.value,
      organizationLabel: event.name,
      title: `${mergePosition.positionJob}, ${event.name} ${mergePosition.office}`,
    })
    setMergePosition({
      ...mergePosition,
      organization: event.name,
    })
    setOrganizationDropdown({
      label: event.label,
      value: event.value,
      name: event.name,
    })
  }

  const addDataRank = event => {
    setAllData({
      ...allDatas,
      rank: event.value,
      rankLabel: event.label,
    })
    setRankDropdown({
      label: event.label,
      value: event.value,
    })
  }

  const addDataOffice = event => {
    setMergePosition({
      ...mergePosition,
      office: event.name,
    })
    setAllData({
      ...allDatas,
      office: event.value,
      officeLabel: event.name,
      title: `${mergePosition.positionJob}, ${mergePosition.organization}  ${event.name}`,
    })
    setOfficeDropdown({
      label: event.label,
      value: event.value,
    })
  }

  const handleIcon = data => {
    setAllData({
      ...allDatas,
      icon: data,
    })
  }

  const addDataGrade = event => {
    setAllData({
      ...allDatas,
      grade: event.value,
      gradeLabel: event.label,
    })
    setGradeDropdown({
      label: event.label,
      value: event.value,
    })
  }

  const addDataActing = event => {
    setAllData({
      ...allDatas,
      acting_as: event.value,
      acting_asLabel: event.label,
    })
    setActingDropdown({
      label: event.label,
      value: event.value,
    })
  }

  const selectStyles = {
    menu: provided => ({...provided, zIndex: 9999}),
    container: provided => ({
      ...provided,
      marginTop: 16,
      marginBottom: 8,
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: '#bfbfbf',
      }
    },
  }

  const handleChangeFile = e => {
    const tempFile = Array.from(e.target.files)

    if (tempFile.some(t => t.size > 1000 * 1000 * 25)) {
      setErrorSize(true)
      setTimeout(() => {
        setErrorSize(false)
      }, 3000)
      e.target.value = null
    } else if (tempFile.some(t => !fileChecker(t.name))) {
      setErrorFile(true)
      setTimeout(() => {
        setErrorFile(false)
      }, 3000)
    } else {
      const newAttachment = []
      tempFile.forEach((file, i) => {
        const filesTarget = e.target.files[i]
        const link = URL.createObjectURL(file)
        newAttachment.push({
          name: file.name,
          link,
          size: file.size,
          type: fileChecker(file.name),
          filesTarget,
        })
      })
      setValueAttachment([...valueAttachment, ...newAttachment])

      e.target.value = null
    }
  }

  return (
    <OrganizationContainer>
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="name">1.</TitleNumber>
            <TitleName>Kode Posisi *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="code"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              placeholder="JP-2040-0001 / JP-{Kode Instansi}-Increment"
              // value={allDatas.title}
              value={allDatas && allDatas.code}
              fullWidth
              className={classes.helperMarginLeft0}
              margin="normal"
              error={
                (allDatas && !allDatas.code && isError) ||
                (allDatas && allDatas.code && allDatas.code.length > 30) ||
                (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
              }
              helperText={
                allDatas && !allDatas.code && isError
                  ? 'Bagian ini harus diisi'
                  : (codeChecker
                    ? !checkSameCodeWithAdd
                    : checkSameCodeWithAdd)
                  ? 'Kode Posisi sudah ada'
                  : allDatas && allDatas.code && allDatas.code.length > 30
                  ? 'The maximum number of characters is 30 characters'
                  : 'Kode posisi harus unik'
              }
              onChange={event => {
                setAllData({
                  ...allDatas,
                  code: event.target.value,
                })
              }}
              variant="outlined"
            />
          </FormChildInput>
        </FormChildContainer>
        {/* {menu === 'position' && status === 'Add' && ( */}
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="designation">2.</TitleNumber>
            <TitleName>Unit Organisasi *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              className={classes.thinSelectfield}
              id="designation"
              options={organizationOptions}
              isLoading={organizationsLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  organzationUnit: dataSearchDropdown,
                })
              }
              value={organizationDropdown}
              onChange={addDataOrganization}
              placeholder="Pilih Unit Organisasi"
              styles={selectStyles}
            />
            <TextHelper>
              {allDatas && !allDatas.position && isError
                ? 'Bagian ini harus diisi'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        {/* )} */}
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="parent">{`${
              menu === 'position' && status === 'Add' ? '3' : ' 3'
            }.`}</TitleNumber>
            <TitleName>Induk *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <FormControlLabel
              control={
                <Switch
                  checked={isParent}
                  onChange={event => {
                    setIsParent(event.target.checked)
                    if (event.target.checked) {
                      if (parentChecker) {
                        if (status === 'Edit' && !dataEdit.parent) {
                          // do nothing
                        } else {
                          handleSetNewParent()
                        }
                      } else {
                        if (
                          idParentTopOU !==
                          (dataEdit &&
                            dataEdit.company_organization &&
                            dataEdit.company_organization.id)
                        ) {
                          if (idParentOUJobProfile || idParentTopOU) {
                            handleSetNewParent()
                          }
                        }
                      }
                      setAllData({
                        ...allDatas,
                        parent: null,
                        parentLabel: null,
                        organization:
                          dataEdit &&
                          dataEdit.company_organization &&
                          dataEdit.company_organization.id,
                      })
                      setParentDropdown(null)
                    } else {
                      setVariable({})
                    }
                  }}
                  color="primary"
                />
              }
              label="Tetapkan sebagai induk?"
            />
            {!isParent ? (
              <>
                <Select
                  className={classes.thinSelectfield}
                  id="parent"
                  options={jobOptions}
                  value={parentDropdown}
                  isLoading={parentLoad}
                  onInputChange={dataSearchDropdown =>
                    setSearchDropdown({
                      ...searchDropdown,
                      parent: dataSearchDropdown,
                    })
                  }
                  onChange={addDataParent}
                  placeholder="Pilih Pimpinan Posisi"
                  styles={selectStyles}
                />
                <TextHelper>
                  {allDatas && !allDatas.parent && isError
                    ? 'Bagian ini harus diisi'
                    : ''}
                </TextHelper>
                <TitleName>{`Posisi saat ini: ${
                  parentChecker
                    ? parentChecker.title
                    : 'Tidak ada Pimpinan Posisi yang dipilih'
                }`}</TitleName>
              </>
            ) : (
              <TextHelper className={classes.margin0}>
                Jika Anda menetapkan ini sebagai Pimpinan Posisi, ini akan
                ditetapkan sebagai bagian atas struktur di perusahaan
              </TextHelper>
            )}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>
              Tetapkan sebagai Asisten <MemoizedAssistantFieldTooltip />
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <FormControlLabel
              label={`Tetapkan sebagai Asisten ${
                childCount ? ` of ${childCount.profile.title}` : ''
              }?`}
              disabled={
                isParent ||
                !allDatas ||
                isParentOrgInequal ||
                isUnsuitableAssistant
              }
              control={
                <Switch
                  checked={(allDatas && allDatas.asAssistant) || false}
                  onChange={event => {
                    const next = event.target.checked
                    setAllData({...allDatas, asAssistant: next})
                  }}
                />
              }
            />

            {isParent ? (
              <FormHelperText error>
                Tidak dapat mengatur Pimpinan Posisi sebagai asisten
              </FormHelperText>
            ) : isParentOrgInequal || isUnsuitableAssistant ? (
              <FormHelperText error>
                Tidak dapat mengatur Posisi sebagai Asisten
              </FormHelperText>
            ) : null}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>
              Tipe Garis <MemoizedLineTypeFieldTooltip />
            </TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              options={LINE_TYPE_OPTIONS}
              placeholder="Pilih Tipe Garis"
              value={lineTypeDropdown}
              onChange={next => {
                setAllData({...allDatas, lineType: next.value})
                setLineTypeDropdown(next)
              }}
              styles={selectStyles}
              menuPosition="fixed"
            />
          </FormChildInput>
        </FormChildContainer>
        {/* {(menu === 'organization' || status === 'Edit') && (
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Organization Unit *</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                fullWidth
                disabled
                margin="normal"
                value={
                  dataEdit && dataEdit.company_organization
                    ? dataEdit.company_organization.name
                    : menu === 'position'
                    ? organizationName
                    : jobName
                }
                variant="outlined"
              />
            </FormChildInput>
          </FormChildContainer>
        )} */}
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="designation">6.</TitleNumber>
            <TitleName>Jabatan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              className={classes.thinSelectfield}
              id="designation"
              options={positionOptions}
              value={positionDropdown}
              isLoading={positionLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  jobTitle: dataSearchDropdown,
                })
              }
              onChange={addDataPosition}
              placeholder="Pilih Jabatan"
              styles={selectStyles}
            />
            <TextHelper>
              {allDatas && !allDatas.position && isError
                ? 'Bagian ini harus diisi'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="level">7.</TitleNumber>
            <TitleName>Jenjang Jabatan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              className={classes.thinSelectfield}
              options={rankOptions}
              value={rankDropdown}
              isLoading={rankLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  jobLevel: dataSearchDropdown,
                })
              }
              onChange={addDataRank}
              placeholder="Pilih Jenjang"
              styles={selectStyles}
            />
            <TextHelper>
              {allDatas && !allDatas.rank && isError
                ? 'Bagian ini harus diisi'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="grade">8.</TitleNumber>
            <TitleName>Kelas Jabatan</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              className={classes.thinSelectfield}
              options={gradeOptions}
              value={gradeDropdown}
              isLoading={loadingGrade}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  jobGrade: dataSearchDropdown,
                })
              }
              onChange={addDataGrade}
              placeholder="Pilih Kelas Jabatan"
              styles={selectStyles}
            />
            {/* <TextHelper>
              {!allDatas.grade && isError ? 'This field is required' : ''}
            </TextHelper> */}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="office">9.</TitleNumber>
            <TitleName>Lokasi Kantor *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              className={classes.thinSelectfield}
              id="officeAddress"
              options={officeOptions}
              value={officeDropdown}
              isLoading={officeLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  officeLocation: dataSearchDropdown,
                })
              }
              onChange={addDataOffice}
              placeholder="Pilih Lokasi Kantor"
              styles={selectStyles}
            />
            <TextHelper>
              {allDatas && !allDatas.office && isError
                ? 'Bagian ini harus diisi'
                : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer className={classes.width80P}>
          <FormChildTitle>
            <TitleNumber id="name">10.</TitleNumber>
            <TitleName>Nama Posisi *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="title"
              placeholder="Tambah Nama Posisi"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              // value={allDatas.title}
              value={allDatas && allDatas.title}
              fullWidth
              className={classes.helperMarginLeft0}
              margin="normal"
              error={
                (allDatas && !allDatas.title && isError) ||
                (allDatas && allDatas.title && allDatas.title.length > 150)
                // || (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
              }
              helperText={
                allDatas && !allDatas.title && isError
                  ? 'Bagian ini harus diisi'
                  : // : (nameChecker
                  //   ? !checkSameNameWithAdd
                  //   : checkSameNameWithAdd)
                  // ? 'Name is already exist'
                  allDatas && allDatas.title && allDatas.title.length > 150
                  ? 'Jumlah maksimum karakter adalah 150 karakter'
                  : 'Contoh : Analis Kebijakan Ahli Pertama | Asisten Deputi Bidang keuangan'
              }
              onChange={event => {
                setAllData({
                  ...allDatas,
                  title: event.target.value,
                })
              }}
              variant="outlined"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="successor">11.</TitleNumber>
            <TitleName>Apakah Posisi Ini Memerlukan Penerus?</TitleName>
          </FormChildTitle>
          <FormChildInput className={classes.marginNormal}>
            <RadioGroup row value={true}>
              <FormControlLabel
                value={allDatas && allDatas.require_successor}
                control={
                  <Radio
                    color="primary"
                    onChange={() =>
                      setAllData({
                        ...allDatas,
                        require_successor: true,
                      })
                    }
                  />
                }
                label="Ya"
              />
              <FormControlLabel
                className={classes.marginLeft10}
                value={allDatas && allDatas.require_successor === false}
                control={
                  <Radio
                    color="primary"
                    onChange={() =>
                      setAllData({
                        ...allDatas,
                        require_successor: false,
                      })
                    }
                  />
                }
                label="Tidak"
              />
            </RadioGroup>
            {/* {allDatas &&
              !allDatas.require_successor &&
              allDatas.require_successor !== false &&
              isError && (
                <Typography className={classes.textError}>
                  This field is required
                </Typography>
              )} */}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="acting">12.</TitleNumber>
            <TitleName>Bertindak sebagai</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <Select
              className={classes.thinSelectfield}
              id="actingAs"
              options={jobOptions}
              value={actingDropdown}
              isLoading={parentLoad}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  parent: dataSearchDropdown,
                })
              }
              onChange={addDataActing}
              placeholder="Pilih posisi rangkap"
              styles={selectStyles}
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber id="icon">13.</TitleNumber>
            <TitleName>Logo Posisi </TitleName>
          </FormChildTitle>
          <FormChildInput className={classes.marginNormal}>
            {loading === 0 ? (
              <div className={classes.iconBorder}>
                <img
                  className={ClassNames(classes.width15, classes.maxHeight25)}
                  src={allDatas && allDatas.icon}
                  alt={allDatas && allDatas.icon}
                />
              </div>
            ) : loading > 0 || loading < 100 ? (
              <CircularProgress variant="static" value={loading} />
            ) : (
              ''
            )}

            <Button
              className={classes.marginLeft10}
              onClick={() => setOpenImage(true)}
              variant="contained"
              color="primary"
              size="medium"
            >
              Pilih Logo
            </Button>
            <Button
              className={classes.marginLeft10}
              onClick={handleClickOpenImport}
              variant="contained"
              color="primary"
              size="medium"
            >
              Unggah Logo Custom
            </Button>
            {/* <TextHelper>
              {!allDatas.icon && isError ? 'This field is required' : ''}
            </TextHelper> */}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer className={classes.width100P}>
          <FormChildTitle>
            <TitleNumber id="description">14.</TitleNumber>
            <TitleName>Uraian Tugas</TitleName>
          </FormChildTitle>
          <FormChildInput className={classes.marginNormal}>
            <CKEditor
              id="job_desc"
              editor={DecoupledEditor}
              config={{
                ...editorConfiguration,
                placeholder: 'Tambah Uraian Jabatan',
              }}
              onInit={editor => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  )
                editor.setData(dataEdit.job_description)
              }}
              data={allDatas && allDatas.jobdesc}
              // eslint-disable-next-line no-unused-vars
              onChange={(event, editor) => {
                const data = editor.getData()
                setAllData({
                  ...allDatas,
                  jobdesc: data,
                })
              }}
            />
            {/* <TextHelper>
              {!allDatas.jobdesc && isError ? 'This field is required' : ''}
            </TextHelper> */}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer className={classes.width100P}>
          <FormChildTitle>
            <TitleNumber id="respon">15.</TitleNumber>
            <TitleName>Tanggung Jawab Jabatan</TitleName>
          </FormChildTitle>
          <FormChildInput className={classes.marginNormal}>
            <CKEditor
              id="job_resp"
              editor={DecoupledEditor}
              config={{
                ...editorConfiguration,
                placeholder: 'Tambah Tanggung Jawab Jabatan',
              }}
              data={allDatas && allDatas.jobresp}
              onInit={editor => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  )
                editor.setData(dataEdit.job_summary)
              }}
              // eslint-disable-next-line no-unused-vars
              onChange={(event, editor) => {
                const data = editor.getData()
                setAllData({
                  ...allDatas,
                  jobresp: data,
                })
              }}
            />
            {/* <TextHelper>
              {!allDatas.jobresp && isError ? 'This field is required' : ''}
            </TextHelper> */}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer className={classes.width80P}>
          <FormChildTitle style={{alignItems: 'center'}}>
            <TitleNumber id="attachment">16.</TitleNumber>
            <TitleName>Lampiran</TitleName>
            <GreyTypography variant="caption" component="p">
              {' '}
              (Opsional)
            </GreyTypography>
            <InfoTooltip
              className={classes.marginLeft10}
              // arrow
              title={
                <>
                  <Typography className={classes.textTooltip}>
                    Ekstensi file yang sesuai:
                  </Typography>
                  <ul style={{paddingLeft: 16}}>
                    <li className={classes.liTooltip}>
                      Dokumen (
                      <span className={classes.textBlue}>
                        .doc, .docx, .xls, .pdf, .xlsx, .ppt
                      </span>
                      )
                    </li>
                    <li className={classes.liTooltip}>
                      Gambar (
                      <span className={classes.textBlue}>
                        .jpg, .jpeg, .png, .gif
                        {/* , .tiff, .pjp, .pjpeg, .jfif, .tif */}
                      </span>
                      )
                    </li>
                  </ul>

                  <Typography className={classes.textTooltip}>
                    Ukuran Maksimal:{' '}
                    <span className={classes.textBlue}>25MB</span>
                  </Typography>
                </>
              }
              placement="right"
            >
              <ErrorOutlineOutlinedIcon
                className={classes.iconTooltip}
                color="primary"
              />
            </InfoTooltip>
          </FormChildTitle>
          <FormChildInput className={classes.marginNormal}>
            <input
              hidden
              type="file"
              id="atch"
              name="updateFile"
              onChange={handleChangeFile}
              // git
              multiple
            />
            <label htmlFor="atch">
              <div className={classes.buttonFile}>
                <AddIcon className={classes.iconAtatch} color="secondary" />
              </div>
            </label>
            {errorSize && (
              <Typography className={classes.textError}>
                Beberapa ukuran file lebih dari 25MB
              </Typography>
            )}
            {errorFile && (
              <Typography className={classes.textError}>
                Beberapa jenis file tidak diizinkan
              </Typography>
            )}
            {valueAttachment.length > 0 &&
              valueAttachment.map((res, i) => (
                <div key={i} className={classes.fileUploadded}>
                  <div className={classes.top}>
                    {/* {fileCheck(res.name)} */}
                    <img
                      src={
                        res.type === 'document'
                          ? documents
                          : res.type === 'pdf'
                          ? pdfIcon
                          : res.type === 'excel'
                          ? excelIcon
                          : res.type === 'powerpoint'
                          ? pptIcon
                          : res.link
                      }
                      style={{
                        marginRight: 6,
                        width: 36,
                        height: 36,
                      }}
                      alt="doc"
                    />
                    <TextField
                      value={valueAttachment[i].name}
                      variant="outlined"
                      onChange={e => {
                        const newVal = valueAttachment.slice()
                        newVal[i].name = e.target.value
                        setValueAttachment(newVal)
                      }}
                      fullWidth
                      style={{background: 'white', margin: '0 10px'}}
                    />
                    <ClearIcon
                      onClick={() => {
                        const newVal = valueAttachment.slice()
                        newVal.splice(i, 1)
                        setValueAttachment(newVal)
                      }}
                      style={{cursor: 'pointer'}}
                    />
                  </div>
                  <div className={classes.bottom}>
                    <Typography className={classes.size}>
                      {res.size / 1000} kB
                    </Typography>
                    <div className={classes.dot}></div>
                    <a
                      href={res.link}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={classes.link}
                    >
                      <Typography className={classes.preview}>
                        Pratinjau
                      </Typography>
                    </a>
                  </div>
                </div>
              ))}
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
      <IconChooser
        open={openImport}
        handleClose={handleClickOpenImport}
        type={'Icon'}
        getData={handleIcon}
        setLoading={setLoading}
      />
      <ModalGlobalIcon
        open={openImage}
        handleClose={() => setOpenImage(false)}
        setIcon={handleIcon}
      />
      <NewParentPopup
        open={popupNewParent.openPopup}
        // id={popupNewParent.idOrg}
        handleClose={handleSetNewParent}
        switchChecked={isParent}
        setSwitchChecked={setIsParent}
        feature="position"
        setVariable={setVariable}
        organziationData={organizationDropdown}
      />
    </OrganizationContainer>
  )
}
export default withRouter(TabJobProfile)

const AssistantFieldTooltip = () => {
  return (
    <FieldInformationTooltip
      title={
        <div style={{textAlign: 'center'}}>
          <div style={{marginBottom: 8}}>
            Posisi ini akan ditempatkan di bawah Induk
          </div>
          <img
            width={171}
            height={161}
            src={require('../../../../../assets/images/structure/unit_assistant.svg')}
          />
        </div>
      }
    />
  )
}

const LineTypeFieldTooltip = () => {
  return (
    <FieldInformationTooltip
      title={
        <>
          <div style={{marginBottom: 8}}>
            Konfigurasi garis antara anak ke induk dalam struktur Organisasi
          </div>
          <div style={{display: 'flex', textAlign: 'center'}}>
            <div style={{marginRight: 24}}>
              <img
                width={41}
                height={111}
                src={require('../../../../../assets/images/structure/unit_line_solid.svg')}
              />
              <div>Tipe Garis Solid</div>
            </div>
            <div>
              <img
                width={41}
                height={111}
                src={require('../../../../../assets/images/structure/unit_line_dotted.svg')}
              />
              <div>Tipe Garis putus-putus</div>
            </div>
          </div>
        </>
      }
    />
  )
}

const MemoizedAssistantFieldTooltip = React.memo(AssistantFieldTooltip)
const MemoizedLineTypeFieldTooltip = React.memo(LineTypeFieldTooltip)
