import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import {Typography, Dialog} from '@material-ui/core'
import SortableTree from 'react-sortable-tree'
import 'react-sortable-tree/style.css'

export const OrganizationContainer = styled.div`
  width: 100%;
`

export const DetailJobProfileContainer = styled.div``

export const BlueLink = styled(Link)`
  color: #007fb2;
  text-decoration: none;
  margin: 18px;
`

export const RedButton = styled(Button)`
  background: #f44336;
  text-decoration: none;
  &:hover {
    background: red;
  }
`

export const BigTextBlackBold = styled.span`
  font-size: 1.25rem;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #000000;
`

export const TextBlackBold = styled(Typography)`
  font-size: 13px;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #000000;
`

export const TextGreyBold = styled(Typography)`
  font-size: 13px;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #a9a8a8;

  ul {
    padding-left: 15px;
    margin: 0px;
  }

  ol {
    padding-left: 15px;
    margin: 0px;
  }

  p {
    margin: 0px;
  }
`

export const TextBlueBold = styled(Typography)`
  font-size: 13px;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #039be5;
  cursor: pointer;
`

export const BigRedText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #ef4d5e;
`

export const RedText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #ef4d5e;
`

export const TextDarkBlue = styled.span`
  font-family: ${props => props.theme.font};
  font-size: 0.9rem;
  font-weight: 600;
  color: #014a62;
`

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font};
  justify-content: space-between;
`

export const FlexStart = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font};
  justify-content: flex-start;
`

export const WrapperContent = styled.div``

export const StyledTree = styled(SortableTree)`
  .rst__rowLabel {
    padding: 0px;
    width: 100%;
  }
`

export const LitleRedText = styled(Typography)`
  font-size: 12px;
  color: #ef4d5e;
  cursor: pointer;
`

export const LitleLightBlueText = styled(Typography)`
  font-size: 12px;
  color: #039be5;
  cursor: pointer;
`

export const LitleGreyText = styled(Typography)`
  font-size: 13px;
  color: #a9a8a8;
`

export const BigTextWhite = styled.span`
  font-family: ${props => props.theme.font};
  font-size: 17px;
  color: #ffffff;
`

export const RpContainer = styled.div`
  border-right: 1px solid #a9a8a8;
  color: #000000;
  padding: 10px;
`

export const StyledDialog = styled(Dialog)`
  font-family: ${props => props.theme.font};
`

export const ModalHeader = styled.div`
  padding: 1.6rem 2rem;
  background-color: #014a62;
  color: #ffffff;
`
export const ModalHeaderWhite = styled.div`
  padding: 1.6rem 2rem;
  background-color: #eff2f4;
`
export const ModalContent = styled.div`
  padding: 1.6rem 2rem;
`

export const WhiteButton = styled(Button)`
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgb(1, 74, 98);
`

export const TableOptions = {
  search: false,
  selection: true,
  sorting: true,
  showTitle: false,
  toolbar: false,
  style: {
    fontWeight: 700,
    lineHeight: 1.43,
    marginTop: `-100px`,
    color: '#a9a8a8',
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
}

export const TitleModalWhite = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
`

const helperStyle = makeStyles(theme => ({
  root: {
    marginLeft: '0px !important',
  },
  marginLeft10: {
    marginLeft: '10px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: '0 0 auto',
    marginLeft: 10,
  },
  spacer: {
    flex: '1 1 25%',
  },
  link: {
    textDecoration: 'none',
    color: '#007fb2',
  },
  padding0: {
    padding: '0px !important',
  },
  padding5: {
    padding: '5px',
  },
  padding20: {
    padding: '20px',
  },
  paddingLeft0: {
    paddingLeft: '0px',
  },
  paddingRight0: {
    paddingRight: '0px !important',
  },
  paddingX15: {
    padding: '0px 15px',
  },
  cursorNone: {
    cursor: 'pointer',
  },
  rootTree: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  margin0: {
    margin: '0px !important',
  },
  marginTopBot20: {
    margin: '20px auto',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginTop15: {
    marginTop: '15px',
  },
  marginY15: {
    margin: '15px 0px !important',
  },
  marginLeftRight10: {
    margin: 'auto 10px',
  },
  buttonText: {
    textDecoration: 'none',
    color: '#fff',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
  },
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  outlinedInput: {
    padding: '10px 14px',
  },
  iconBorder: {
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '30px',
    height: '25px',
    margin: '10px auto',
    padding: '5px',
    display: 'inline-block',
    borderRadius: '5px',
    border: 'solid 1px #a9a8a8',
  },
  iconBigBorder: {
    width: '67px',
    height: '67px',
    padding: '5px',
    margin: '10px',
    borderRadius: '5px',
    border: 'solid 1px #a9a8a8',
    cursor: 'pointer',
  },
  width100P: {
    width: '100% !important',
  },
  width83P: {
    width: '83% !important',
  },
  width80P: {
    width: '80% !important',
  },
  width70P: {
    width: '70% !important',
  },
  width60P: {
    width: '60% !important',
  },
  width55P: {
    width: '55% !important',
  },
  width40P: {
    width: '40% !important',
  },
  width35P: {
    width: '35% !important',
  },
  width30P: {
    width: '30% !important',
  },
  width20P: {
    width: '20% !important',
  },
  width15: {
    width: '20px',
  },
  buttonSpacer: {marginRight: 12},
  flexWrap: {
    flexWrap: 'wrap',
  },
  thinTextfield: {
    padding: '12px 14px',
  },
  verticalAlignBot: {
    verticalAlign: 'bottom',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  backgroundColorRed: {
    backgroundColor: '#ef4d5e',
  },
  colorRed: {
    color: '#ef4d5e',
    '&$checked': {
      color: '#ef4d5e',
    },
  },
  heightAuto: {
    height: 'auto',
  },
  maxHeight25: {
    maxHeight: '25px',
  },
  btnDel: {
    backgroundColor: '#ef4d5e',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ef4d5e',
    },
  },

  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    color: '#039be5 !important',
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  labelActive: {
    color: '#039be5 !important',
  },
  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  height125: {
    maxHeight: '135px',
  },
  maxHeight400: {
    minHeight: '400px',
    maxHeight: '400px',
  },
  height350: {
    height: '350px',
  },
  bgColorGrey: {
    backgroundColor: '#eff2f4',
  },
  notViewScroll: {
    '&::-webkit-scrollbar': {
      width: '0px',
    },
  },
  helperMarginLeft0: {
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
    },
  },
  marginLeft0: {
    marginLeft: '0px !important',
  },
  marginLeft1R: {
    marginLeft: '1rem',
  },
  iconOffice: {
    fontSize: '40px !important',
    color: '#a9a8a8',
  },
  marginB20: {
    margin: '20px 0px 20px 0px !important',
  },
  marginX11: {
    margin: '0px 11px !important',
  },
  overflowXNone: {
    overflowX: 'hidden !important',
  },
  overflowYNone: {
    overflowY: 'hidden !important',
  },
  height39: {
    '& > .MuiOutlinedInput-root': {
      height: '39px !important',
    },
  },
  checkBoxRoot: {
    color: [['#007fb2'], '!important'],
    '&:checked': {
      color: [['#007fb2'], '!important'],
    },
  },
}))
export default helperStyle
