import React, {useState} from 'react'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {WrapperSection} from '../../DetailEmployeeStyles'
import {PublicationsItem} from './PublicationsItem'

export default function Publications({people_profile_publications, user_name}) {
  const [open, setOpen] = useState(false)

  const handlePreview = link => window.open(link)

  const _renderData = (data, limit = null) => {
    return data.slice(0, limit || data.length).map(res => {
      return (
        <PublicationsItem
          key={`${res.id}-itm`}
          onPreview={handlePreview}
          publication_title={res.publication_title || '-'}
          authors={res.authors || []}
          publication_media={res.publication_media || '-'}
          date={res.publication_date || '-'}
          description={res.description || '-'}
          publication_url={res.publication_url || '-'}
          attachments={res.attachments || []}
        />
      )
    })
  }

  return (
    <>
      <WrapperSection>
        {_renderData(people_profile_publications, 2)}
        {people_profile_publications[0] && (
          <h3 onClick={() => setOpen(true)} className="show-more">
            Tampilkan Lebih Banyak Publications...
          </h3>
        )}
      </WrapperSection>

      <ModalDetail
        onClose={() => setOpen(false)}
        open={open}
        title={`${user_name}'s Publications`}
      >
        {_renderData(people_profile_publications)}
      </ModalDetail>
    </>
  )
}
