import gql from 'graphql-tag'

export const GET_PREVIEW_INDIVIDUAL = gql`
  query(
    $job_profile: Int
    $from: String
    $to: String
    $kpi_weight: Float
    $multirater_weight: Float
    $ogf_weight: Float
    $nps_weight: Float
  ) {
    getIndividualPerformance(
      job_profile: $job_profile
      from: $from
      to: $to
      kpi_weight: $kpi_weight
      multirater_weight: $multirater_weight
      ogf_weight: $ogf_weight
      nps_weight: $nps_weight
    ) {
      user
      regno
      name
      avatar
      kpi_score
      kpi_weight
      multirater_score
      multirater_weight
      nps_score
      nps_weight
      ogf_score
      ogf_weight
      index_score
    }
  }
`
