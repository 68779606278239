import React from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../../DetailEmployeeStyles'
import FormInput from '../../ShareComponents-detail/FormInput'
import {ButtonSubmit} from '../../../SharedDetailEmployee'

import usePrevious from '../../../../../../../hooks/usePrevious'
import {capitalize} from '../../../../../../../utils/helpers'
import {
  GET_COUNTRIES,
  GET_DEPENDANT_RELATIONSHIPS,
  GET_EMERGENCY_CONTACT_LOCATION_,
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
} from '../../../../../../../graphql/queries'
import {isErrorForm} from '../../ShareComponents-detail/helperDetail'
import {
  ADD_EMERGENCY_CONTACT,
  UPDATE_EMERGENCY_CONTACT,
} from '../../../../../../../graphql/mutations'
import {CircularProgress} from '@material-ui/core'

const STATE_INITIAL = 0
const STATE_ERROR = 1
const STATE_DISPATCH = 2
const STATE_FETCHING = 3

const INITIAL_FORM_DATA = {
  name: '',
  relationship: null,
  phone_code: null,
  phone_number: null,

  country: null,
  province: null,
  city: null,
  district: '',
  subdistrict: '',
  postal_code: '',
  address: '',
}

const INITIAL_SEARCH_STATE = {
  relation: '',
  country: '',
  province: '',
  city: '',
}

const AddEditEmergencyContact = props => {
  const {open = false, userId, initialData, onClose, onSubmit, onDelete} = props

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [formState, setFormState] = React.useState(STATE_INITIAL)
  const [formData, setFormData] = React.useState(INITIAL_FORM_DATA)

  const [search, setSearch] = React.useState(INITIAL_SEARCH_STATE)

  const isEditing = !!initialData

  const isError = formState === STATE_ERROR
  const isDispatching = formState >= STATE_DISPATCH

  const prevOpen = usePrevious(open)
  const isNeverOpen = prevOpen === false && prevOpen === open

  const {data: dataRelation, loading: loadingRelation} = useQuery(
    GET_DEPENDANT_RELATIONSHIPS,
    {
      wlb_skipPatch: true,
      skip: isNeverOpen,
      variables: {
        search: `%${search.relation}%`,
        limit: 20,
      },
    }
  )

  const {data: dataCountry, loading: loadingCountry} = useQuery(GET_COUNTRIES, {
    wlb_skipPatch: true,
    skip: isNeverOpen,
    variables: {
      search: `%${search.country}%`,
      limit: 20,
      id: [103],
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })

  const {data: dataProvince, loading: loadingProvince} = useQuery(
    GLOBAL_PROVINCES_SEARCHABLE,
    {
      skip: isNeverOpen,
      variables: {
        search: `%${search.province}%`,
        limit: 20,
        country: formData?.country?.value,
      },
    }
  )

  const {data: dataCity, loading: loadingCity} = useQuery(
    GLOBAL_CITIES_SEARCHABLE,
    {
      skip: isNeverOpen,
      variables: {
        search: `%${search.city}%`,
        limit: 20,
        province: formData?.province?.value,
      },
    }
  )

  React.useEffect(() => {
    if (!open) {
      return
    }

    if (initialData) {
      // NOTE(intrnl): for some reason, `people_emergency_contacts` has the
      // countries, provinces, and city values stored differently, they're in
      // the custom data field instead of using `global_country`,
      // `global_province`, and `global_city`

      // because of this, we'll have to do an additional fetch to get the
      // "correct" values for these in the dropdown.

      let canceled = false

      setFormState(STATE_FETCHING)

      const fields = initialData.emergency_contact_fields
      const relation = initialData.relationship

      const countryName = fields.country
      const provinceId = fields.province
      const cityId = fields.city

      const promise = client.query({
        query: GET_EMERGENCY_CONTACT_LOCATION_,
        variables: {
          countryName_in: countryName ? [countryName] : [],
          provinceId_in: provinceId ? [provinceId] : [],
          cityId_in: cityId ? [cityId] : [],
        },
      })

      cbify(promise, (error, result) => {
        if (error) {
          console.error(error)
        }

        if (canceled) {
          return
        }

        const data = result.data

        const country = data?.countries[0]
        const province = data?.provinces[0]
        const city = data?.cities[0]

        setFormState(STATE_INITIAL)

        setFormData({
          name: initialData.name,
          relationship: relation
            ? {label: capitalize(relation), value: relation}
            : null,
          phone_code: initialData.country_code,
          phone_number: initialData.phone,
          country: country ? {label: country.name, value: country.id} : null,
          province: province
            ? {label: province.name, value: province.id}
            : null,
          city: city ? {label: city.name, value: city.id} : null,
          district: fields.district,
          subdistrict: fields.sub_district,
          postal_code: fields.postal_code,
          address: initialData.address,
        })
      })

      return () => {
        canceled = true
      }
    } else {
      setFormState(STATE_INITIAL)
      setFormData(INITIAL_FORM_DATA)
    }
  }, [open, initialData])

  if (isNeverOpen) {
    return null
  }

  const bindAutocompleteSearch = field => query => {
    setSearch({...search, [field]: query})
  }

  const handleAutocompleteChange = item => {
    const {fieldName, label, value} = item

    if (fieldName === 'country') {
      setFormData({
        ...formData,
        country: {label, value},
        province: null,
        city: null,
      })
    } else if (fieldName === 'province') {
      setFormData({
        ...formData,
        province: {label, value},
        city: null,
      })
    } else {
      setFormData({
        ...formData,
        [fieldName]: {label, value},
      })
    }
  }

  const handleSubmit = () => {
    if (isErrorForm(fieldsList)) {
      setFormState(STATE_ERROR)
      return
    }

    setFormState(STATE_DISPATCH)

    const data = {
      user: userId,

      name: formData.name,
      relationship: formData.relationship.value,
      country_code: formData.phone_code,
      phone: formData.phone_number,
      address: formData.address,
      emergency_contact_fields: {
        country: formData.country.label,
        province: formData.province.value,
        city: formData.city.value,
        district: formData.district,
        sub_district: formData.subdistrict,
        postal_code: formData.postal_code,
      },
    }

    const verb = isEditing ? 'edit' : 'add'
    let promise

    if (isEditing) {
      promise = client.mutate({
        mutation: UPDATE_EMERGENCY_CONTACT,
        variables: {
          id: initialData.id,
          data: data,
        },
      })
    } else {
      promise = client.mutate({
        mutation: ADD_EMERGENCY_CONTACT,
        variables: {
          data: data,
        },
      })
    }

    promise.then(
      () => {
        if (!isEditing) {
          setFormData(INITIAL_FORM_DATA)
        }

        setFormState(STATE_INITIAL)
        enqueueSnackbar(`Emergency contact ${verb}ed`, {variant: 'success'})

        if (onSubmit) {
          onSubmit(!isEditing)
        }
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Failed to ${verb} emergency contact`, {
          variant: 'error',
        })

        setFormState(STATE_INITIAL)
      }
    )
  }

  const fieldsList = [
    {
      type: 'textfield',
      label: 'Nama*',
      placeholder: 'Tambahkan Nama',
      required: true,
      fieldName: 'name',
      value: formData.name,
      error: !formData.name,
    },
    {
      type: 'autocomplete',
      label: 'Hubungan*',
      placeholder: 'Tambahkan hubungan',
      required: true,
      fieldName: 'relationship',
      value: formData.relationship,
      option: dataRelation?.relationships.map(item => ({
        label: capitalize(item.name),
        value: item.name,
      })),
      error: !formData.relationship,
      isLoading: loadingRelation,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('relation'),
    },
    {
      type: 'phone-field',
      label: 'Nomor Telepon*',
      placeholder: 'Tambahkan Nomor Telepon',
      required: true,
      fieldName: ['phone_code', 'phone_number'],
      value: [formData.phone_code, formData.phone_number],
      error: [!formData.phone_code, !formData.phone_number],
    },
    {
      type: 'autocomplete',
      label: 'Negara*',
      placeholder: 'Pilih negara',
      required: true,
      fieldName: 'country',
      value: formData.country,
      option: dataCountry?.countries.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData.country,
      isLoading: loadingCountry,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('country'),
    },
    {
      type: 'autocomplete',
      label: 'Provinsi*',
      placeholder: 'Pilih provinsi',
      required: true,
      fieldName: 'province',
      value: formData.province,
      option: dataProvince?.global_provinces.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData.province,
      isLoading: loadingProvince,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('province'),
    },
    {
      type: 'autocomplete',
      label: 'Kota*',
      placeholder: 'Pilih kota',
      required: true,
      fieldName: 'city',
      value: formData.city,
      option: dataCity?.global_cities.map(item => ({
        label: item.name,
        value: item.id,
      })),
      error: !formData.city,
      isLoading: loadingCity,
      onChange: handleAutocompleteChange,
      onInputChange: bindAutocompleteSearch('city'),
    },
    {
      type: 'textfield',
      label: 'Kecamatan*',
      placeholder: 'Tambahkan Kecamatan',
      required: true,
      fieldName: 'district',
      value: formData.district,
      error: !formData.district,
    },
    {
      type: 'textfield',
      label: 'Kelurahan',
      placeholder: 'Tambahkan Kelurahan',
      fieldName: 'subdistrict',
      value: formData.subdistrict,
    },
    {
      type: 'textfield',
      label: 'Kode Pos*',
      placeholder: 'Tambahkan kode pos',
      inputType: 'number',
      inputProps: {min: 0},
      required: true,
      fieldName: 'postal_code',
      value: formData.postal_code,
      error: !formData.postal_code,
    },
    {
      type: 'textfield',
      label: 'Alamat*',
      placeholder: 'Tambahkan alamat',
      fieldName: 'address',
      multiline: true,
      rows: 5,
      required: true,
      value: formData.address,
      error: !formData.address,
    },
  ]

  return (
    <ModalDetail
      open={open}
      maxWidth="sm"
      title={`${isEditing ? 'Ubah' : 'Tambah'} Kontak Darurat`}
      onClose={isDispatching ? null : onClose}
      onDelete={isEditing ? onDelete : null}
    >
      {formState === STATE_FETCHING ? (
        <ModalAddEditWraper style={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </ModalAddEditWraper>
      ) : (
        <>
          <ModalAddEditWraper>
            <FormInput
              open
              fieldsList={fieldsList}
              setValues={setFormData}
              values={formData}
              errorState={isError}
              HELPER_TEXT="Bagian ini diperlukan"
            />
          </ModalAddEditWraper>

          <ButtonSubmit
            disabled={isDispatching}
            onCancel={onClose}
            onSave={handleSubmit}
          />
        </>
      )}
    </ModalDetail>
  )
}

export default AddEditEmergencyContact

/**
 * @template T, R
 * @param {Promise<T>} promise
 * @param {(error: unknown, value: T) => R} cb
 * @returns {Promise<R>}
 */
const cbify = (promise, cb) => {
  return promise.then(
    val => cb(null, val),
    err => cb(err, null)
  )
}
