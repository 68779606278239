import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'

import ListComponent from './components/ListComponent'
import DetailComponent from './components/DetailComponent'
import AddVersionComponent from './components/AddVersionComponent'
import AddClassroomComponent from './components/AddClassroomComponent'
import EditVersionComponent from './components/EditVersionComponent'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'cross-mentoring-list':
      return <ListComponent state={state} searchText={searchText} />
    case 'cross-mentoring-detail':
      return <DetailComponent state={state} />
    case 'add-version':
      return <AddVersionComponent />
    case 'add-classroom':
      return <AddClassroomComponent />
    case 'edit-group-learning':
      return <EditVersionComponent />
    default:
      return <ListComponent state={state} searchText={searchText} />
  }
}

const CrossMentoringPageComponent = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <Card icon="school" title="Learning" onSearch={onSearch}>
      <PageContainer>
        {renderComponent(active, state, searchText)}
      </PageContainer>
    </Card>
  )
}

export default CrossMentoringPageComponent
