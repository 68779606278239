import gql from 'graphql-tag'

export const UPDATE_EMP_PLACEMENT = gql`
  mutation(
    $id: Int!
    $jobProfile: Int
    $organization: Int
    $position: Int
    $rank: Int
    $office: Int
    $type: String
    $time: String
    $place: String
    $supervisor: uuid
    $role: String
    $join_date: date
    $start_date: date
    $end_date: date
    $regno: String
    $status: String
    $reference: String
    $pending_activation_edit_status: Boolean
    $attachment: jsonb
    $reminder: Int
  ) {
    update_people_work_placements(
      where: { id: { _eq: $id } }
      _set: {
        job_profile: $jobProfile
        organization: $organization
        position: $position
        rank: $rank
        office_address: $office
        type: $type
        type_time: $time
        join_date: $join_date
        type_place: $place
        supervisor_id: $supervisor
        role: $role
        start_date: $start_date
        end_date: $end_date
        regno: $regno
        status: $status
        reference: $reference
        pending_activation_edit_status: $pending_activation_edit_status
        attachment: $attachment
        reminder: $reminder
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_PHONE_INFORMATION = gql`
  mutation($id: Int!, $userId: uuid!, $input: people_phones_set_input) {
    update_people_phones(
      _set: $input
      where: {
        _and: {
          id: { _eq: $id }
          user: { _eq: $userId }
          deletedAt: { _is_null: true }
        }
      }
    ) {
      affected_rows
    }
  }
`

export const ADD_PHONE_INFORMATION = gql`
  mutation($input: people_phones_insert_input!) {
    insert_people_phones_one(object: $input) {
      id
    }
  }
`

export const UPDATE_EMP_INFORMATION = gql`
  mutation($userId: String!, $new: NewPlacementPersonalInfo!) {
    editPlacementPersonalInfo(userId: $userId, new: $new) {
      success
    }
  }
`

export const UPDATE_STATUS_PENDING_ACTIVATION = gql`
  mutation($id: [Int!]!, $user: [uuid!]!) {
    update_people_work_placements(
      where: {id: {_in: $id}, global_user: {status: {_eq: "ACTIVE"}}}
      _set: {status: "ACTIVE"}
    ) {
      affected_rows
    }

    update_global_users(
      where: {id: {_in: $user}, status: {_eq: "ACTIVE"}}
      _set: {last_sync: "now()"}
    ) {
      affected_rows
    }
  }
`
