import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {
  Button,
  IconButton,
  Divider,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
  FormContainer,
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  TitleName,
  TitleNumber,
  ThinTextfield,
} from '../../../../GlobalStyles'
import {UPDATE_COMPETENCY} from '../../../../graphql/mutations'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import CKEditor from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
}))

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
}

const FormCompetencyLevel = props => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const id = props?.match?.params?.id
  const {data, dataEdit} = props?.location?.state

  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [value, setValue] = useState({
    number: dataEdit.number || 0,
    description: dataEdit.description || '',
  })

  const [updateCompetency] = useMutation(UPDATE_COMPETENCY)

  const goBack = () => {
    return props.history.goBack()
  }

  const handleConfirm = type => {
    setOpen(true)
    setConfirmType(type)
  }

  const handleChange = name => event => {
    const _value = {
      ...value,
      [name]: event.target.value,
    }

    setValue(_value)
  }

  const handleChangeDesc = (data, name) => {
    const _value = {
      ...value,
      [name]: data,
    }

    setValue(_value)
  }

  const handleSave = () => {
    if (!value.number || !value.description) {
      setError(true)
    } else {
      const _levels = data.description.levels
      const index = _levels.findIndex(data => data.number === value.number)
      _levels[index] = {..._levels[index], ...value}

      const variable = {
        id,
        dictionary_id: data.dictionary_id,
        name: {id: data.name.id, en: data.name.en},
        description: {text: data.description.text, levels: _levels},
        type: data.type,
        category: data.category,
      }

      setIsLoading(true)
      updateCompetency({
        variables: variable,
      })
        .then(() => {
          goBack()
          enqueueSnackbar('Competency Updated', {
            variant: 'success',
            autoHideDuration: 1000,
          })
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handleConfirm('discard')}
          >
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6">
            {id ? 'Ubah' : 'Tambah'} Kompetensi {data.name.id}
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            className={classes.backButton}
            onClick={() => handleConfirm('discard')}
          >
            Batal
          </Button>
          <Button
            onClick={() => handleConfirm('submit')}
            size="large"
            variant="contained"
            color="primary"
          >
            Kirim
          </Button>
        </div>
      </FormToolbar>
      <Divider />
      <FormContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Level*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <ThinTextfield
              fullWidth
              select
              disabled
              margin="normal"
              variant="outlined"
              value={value.number}
              onChange={handleChange('number')}
              error={!value.number && error}
              helperText={!value.number && error && 'This field is required'}
            >
              {[1, 2, 3, 4, 5].map(val => {
                return (
                  <MenuItem value={val} key={val}>
                    {val}
                  </MenuItem>
                )
              })}
            </ThinTextfield>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Keterangan*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <CKEditor
              editor={DecoupledEditor}
              config={editorConfiguration}
              onInit={editor => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  )
              }}
              data={value.description}
              onChange={(event, editor) => {
                const data = editor.getData()
                handleChangeDesc(data, 'description')
              }}
            />
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>

      <AddEdit
        open={open}
        handleClose={() => setOpen(false)}
        mutation={confirmType === 'discard' ? goBack : handleSave}
        header={CONFIRMATION[confirmType]?.header}
        body={CONFIRMATION[confirmType]?.body}
        button={CONFIRMATION[confirmType]?.button}
        buttonClose="Batal"
        disabled={isLoading}
      />
    </PaperContainer>
  )
}

export default withRouter(FormCompetencyLevel)

const CONFIRMATION = {
  submit: {
    header: 'Kirim Kompetensi?',
    body: 'Apakah Anda yakin akan mengirimkan Kompetensi ini?',
    button: 'Kirim',
  },
  discard: {
    header: 'Buang Perubahan?',
    body: 'Apakah Anda yakin akan membuang perubahan yang belum disimpan?',
    button: 'Buang',
  },
}
