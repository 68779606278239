import PayrollPage from './PayrollPageComponent'

export const PayrollPageConfig = {
  routes: [
    {
      path: '/financial/payroll/analytic',
      component: PayrollPage,
      state: 'analytic',
      exact: true,
    },
    {
      path: '/financial/payroll/payroll-process',
      component: PayrollPage,
      state: 'payroll-process',
      exact: false,
    },
    {
      path: '/financial/payroll/payroll-process/step',
      component: PayrollPage,
      state: 'step-payroll-process',
      exact: false,
    },
    {
      path: '/financial/payroll/payroll-process/payroll-detail',
      component: PayrollPage,
      state: 'payroll-detail',
      exact: false,
    },
    {
      path: '/financial/payroll/thr-process',
      component: PayrollPage,
      state: 'thr-process',
      exact: false,
    },
    {
      path: '/financial/payroll/thr-process/step',
      component: PayrollPage,
      state: 'step-thr-process',
      exact: false,
    },
    {
      path: '/financial/payroll/thr-process/thr-detail',
      component: PayrollPage,
      state: 'thr-detail',
      exact: false,
    },
    {
      path: '/financial/payroll/employee-settings',
      component: PayrollPage,
      state: 'employee-settings',
      exact: false,
    },
    {
      path: '/financial/payroll/employee-settings/:id/edit',
      component: PayrollPage,
      state: 'employee-settings-edit',
      exact: false,
    },
    {
      path: '/financial/payroll/report',
      component: PayrollPage,
      state: 'report',
      exact: false,
    },
    {
      path: '/financial/payroll/report/payroll/detail/:id',
      component: PayrollPage,
      state: 'report',
      exact: false,
    },
    {
      path: '/financial/payroll/report/tax/detail',
      component: PayrollPage,
      state: 'report-tax-detail',
      exact: false,
    },
    {
      path: '/financial/payroll/setting',
      component: PayrollPage,
      state: 'settings',
      exact: false,
    },
    {
      path: '/financial/payroll/setting/add-component',
      component: PayrollPage,
      state: 'settings-add-component',
      exact: false,
    },
    {
      path: `/financial/payroll/setting/edit-component`,
      component: PayrollPage,
      state: 'settings-edit-component',
      exact: false,
    },
    {
      path: '/financial/payroll/setting/add-premium',
      component: PayrollPage,
      state: 'settings-add-premium',
      exact: false,
    },
    {
      path: `/financial/payroll/setting/edit-premium`,
      component: PayrollPage,
      state: 'settings-edit-premium',
      exact: false,
    },
  ],
}
