import React, {useState, useEffect} from 'react'
import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'
import Activity from './activity/ActivityComponent'
import LeaveBalance from './leave-balance/LeaveBalanceComponent'
import EditLeaveBalance from './leave-balance/EditLeaveBalanceComponent'
import Setting from './setting/SettingComponent'
import EditSetting from './setting/EditSettingComponent'
import AddLeavePolicy from './setting/AddLeavePolicyChildComponent'
import AddLeavePolicyParent from './setting/AddLeavePolicyParentComponent'
import EditSettingParent from './setting/EditSettingParentComponent'
import {Filter} from '@worklifebeyond/wlb-utils-components'
import {anchorOrigin, listFilter, MENUS, transformOrigin} from './filter'
import {
  ButtonFilter,
  BlueIcon,
  FilterText,
  PageContainer,
} from '../../../../src/GlobalStyles'
import {setterFilter} from '../../../utils/helpers'

const renderComponent = (
  activeComponent,
  state,
  searchText = '',
  filterData,
  setActiveMenuTab
) => {
  switch (activeComponent) {
    case 'leave-balance':
      return <LeaveBalance searchText={searchText} />
    case 'edit-leave-balance':
      return <EditLeaveBalance policy={state.policy} />
    case 'edit-settings':
      return (
        <EditSetting
          root={state.rootData}
          parent={state.selectedParent}
          selected={state.selectedItem}
          // handleBack={handleBack}
        />
      )
    case 'edit-settings-parent':
      return (
        <EditSettingParent
          selected={state.selected}
          dataCheck={state.dataCheck}
        />
      )
    case 'add-settings':
      return <AddLeavePolicy policies={state.policies} />
    case 'add-settings-parent':
      return <AddLeavePolicyParent />
    case 'settings':
      return <Setting searchText={searchText} />
    default:
      return (
        <Activity
          filterData={filterData}
          setActiveMenuTab={setActiveMenuTab}
          searchText={searchText}
        />
      )
  }
}

const LeavePage = props => {
  const [filterData, setFilterData] = useState({})
  const [activeMenuTab, setActiveMenuTab] = useState('activity')
  const [willResetActiveFilter, setWillResetActiveFilter] = useState(false)
  let activeTab = ''
  let activeMenu = ''

  let state = {}

  if (props.location.state) {
    activeMenu = props.location.state.active
    state = props.location.state
    switch (props.location.state.active) {
      case 'add-settings-parent':
      case 'edit-settings-parent':
      case 'add-settings':
      case 'edit-settings':
        activeTab = 'settings'
        break
      case 'edit-leave-balance':
        activeTab = 'leave-balance'
        break
      default:
        activeTab = props.location.state.active
    }
  }

  useEffect(() => {
    if (props.location.pathname === '/time/leave') {
      props.history.push({
        state: {
          active: 'activity',
          searchable: true,
        },
      })
    }
  }, [])

  const data_tabs = [
    {
      label: 'Activity',
      state: 'activity',
      url: '/time/leave/activity',
      searchable: true,
    },
    {
      label: 'Leave and Permission Balance',
      state: 'leave-balance',
      url: '/time/leave/leave-balance',
      searchable: true,
    },
    {
      label: 'Settings',
      state: 'settings',
      url: '/time/leave/settings',
      searchable: true,
    },
  ]
  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  const handleApply = result => setFilterData(setterFilter(result))
  const handleClick = () => {
    if (activeMenuTab === 'request') {
      setWillResetActiveFilter(false)
    } else if (activeMenuTab === 'activity') {
      setWillResetActiveFilter(true)
    }
  }

  return (
    <div>
      <Card
        icon="access_time"
        title="Leave and Permission"
        onSearch={onSearch}
        endAdornment={
          MENUS.includes(activeTab) && (
            <Filter
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
              id="filter-overtime-wlb"
              onApply={handleApply}
              listFilter={listFilter(activeMenuTab)}
              onClose={handleClick}
              options={{
                isSetActiveMenuAfterClose: false,
                isSetActiveMenuWhenOpen:
                  filterData !== 'activity' && willResetActiveFilter,
                setActiveMenuToIndex: 0,
                delayResetMenu: 1000,
              }}
            >
              <ButtonFilter position="end">
                <BlueIcon>filter_list</BlueIcon>
                <FilterText variant="body2">Filter</FilterText>
              </ButtonFilter>
            </Filter>
          )
        }
      >
        <PageContainer>
          <WlbTab data={data_tabs} activeMenu={activeTab} />
          {renderComponent(
            activeMenu,
            state,
            searchText,
            filterData,
            setActiveMenuTab
          )}
        </PageContainer>
      </Card>
    </div>
  )
}

export default LeavePage
