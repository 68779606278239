import React from 'react'
import {Doughnut} from 'react-chartjs-2'
import {Typography, Divider, makeStyles, Paper} from '@material-ui/core'
import {FlexBetween, GreyTypography} from '../../../../GlobalStyles'
const useStyles = makeStyles(() => ({
  age: {
    width: '100%',
    margin: '1rem',
    borderRadius: '5px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  titleAge: {
    color: '#055469',
    padding: 12,
    fontWeight: 600,
  },
  divAge: {
    padding: 24,
    display: 'flex',
    flexDirection: 'row',
  },
  divChart: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  chart: {
    margin: 'auto',
    width: '50%',
  },
  infoChart: {
    width: '50%',
    margin: 'auto',
    padding: '0 1rem',
  },
  circle: {
    borderRadius: '50%',
    marginRight: '1rem',
    width: '12px',
    height: '12px',
  },
  divMinAge: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    alignItems: 'center',
  },
  textPercentGender: {fontWeight: 400, margin: '0 0 1rem 2rem'},
}))
const ChartDetail = ({dataEmployee}) => {
  const classes = useStyles()
  const dataAge = {
    labels: ['18 - 24 Tahun', '25 - 34 Tahun', '35 - 49 Tahun', '50+ Tahun'],
    datasets: [
      {
        data: [
          dataEmployee && dataEmployee.age1824.aggregate.count,
          dataEmployee && dataEmployee.age2534.aggregate.count,
          dataEmployee && dataEmployee.age3549.aggregate.count,
          dataEmployee && dataEmployee.age50.aggregate.count,
        ],
        backgroundColor: ['#1f78b4', '#33a02c', '#a6cee3', '#b2df8a'],
      },
    ],
  }
  const countMale = dataEmployee && dataEmployee.gender_male.aggregate.count
  const countFemale = dataEmployee && dataEmployee.gender_female.aggregate.count
  const dataGender = {
    labels: ['Pria', 'Wanita'],
    datasets: [
      {
        data: [countMale, countFemale],
        backgroundColor: ['#1f78b4', '#a6cee3'],
      },
    ],
  }

  const renderLegendAge = () => {
    const newArr = []
    for (let i = 0; i < dataAge.labels.length; i++) {
      newArr.push({
        color: dataAge.datasets[0].backgroundColor[i],
        data: dataAge.datasets[0].data[i],
        labels: dataAge.labels[i],
      })
    }

    return newArr.map((resAge, i) => (
      <div className={classes.divMinAge} key={i}>
        <div
          style={{
            border: `solid 3px ${resAge.color}`,
          }}
          className={classes.circle}
        />
        <FlexBetween style={{width: '80%'}}>
          <Typography variant="body2">{resAge.labels}</Typography>
          <GreyTypography variant="body2">{resAge.data}</GreyTypography>
        </FlexBetween>
      </div>
    ))
  }
  return (
    <div className={classes.divAge}>
      <Paper className={classes.age}>
        <Typography variant="h6" className={classes.titleAge}>
          Usia
        </Typography>
        <Divider />
        <div className={classes.divChart}>
          <div className={classes.chart}>
            <Doughnut data={dataAge} options={{legend: false}} />
          </div>
          <Divider
            orientation="vertical"
            style={{
              height: '11rem',
            }}
          />
          <div className={classes.infoChart}>{renderLegendAge()}</div>
        </div>
      </Paper>
      <Paper className={classes.age}>
        <Typography variant="h6" className={classes.titleAge}>
          Komposisi Jenis Kelamin
        </Typography>
        <Divider />
        <div className={classes.divChart}>
          <div className={classes.chart}>
            <Doughnut data={dataGender} options={{legend: false}} />
          </div>
          <Divider
            orientation="vertical"
            style={{
              height: '11rem',
            }}
          />
          <div className={classes.infoChart}>
            <div className={classes.divMinAge} style={{marginBottom: 5}}>
              <div
                style={{
                  border: 'solid 3px #1f78b4',
                }}
                className={classes.circle}
              />
              <Typography variant="body1">Pria</Typography>
            </div>
            <GreyTypography variant="h6" className={classes.textPercentGender}>
              {((countMale / (countMale + countFemale)) * 100).toFixed(0)}%
            </GreyTypography>
            <div className={classes.divMinAge} style={{marginBottom: 5}}>
              <div
                style={{
                  border: 'solid 3px #a6cee3',
                }}
                className={classes.circle}
              />
              <Typography variant="body1">Wanita</Typography>
            </div>
            <GreyTypography
              variant="h6"
              className={classes.textPercentGender}
              style={{marginBottom: 0}}
            >
              {((countFemale / (countMale + countFemale)) * 100).toFixed(0)}%
            </GreyTypography>
          </div>
        </div>
      </Paper>
    </div>
  )
}
export default ChartDetail
