import HistoryPage from './HistoryPageComponent'

export const HistoryPageConfig = {
  routes: [
    {
      path: '/organization/history',
      component: HistoryPage,
      state: 'position',
      exact: true,
    },
  ],
}
