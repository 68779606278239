import React, {useState, useEffect} from 'react'
import {CircularProgress} from '@material-ui/core'
import MaterialTable from 'material-table'
import {useQuery} from '@apollo/react-hooks'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {GET_THR_REPORT} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import {convertToRoundedRupiah} from '../../../../../utils/helpers'
import helperStyle, {
  TableOptions,
  TextBlueBold,
  CenterWrapper,
  pageSizeOption,
} from '../../PayrollPageStyles'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import {tableStyle} from '../../PayrollTableStyles'

export default function Thr(props) {
  const {searchText, period} = props
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const classes = helperStyle()
  const months = moment.monthsShort()

  const {data: thrData, loading, error} = useQuery(GET_THR_REPORT, {
    variables: {
      company: COMPANY_ID,
      date_start: `${moment(period).format('YYYY')}-01-01`,
      date_end: `${moment(period).format('YYYY')}-12-31`,
      search: `%${searchText}%`,
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [searchText, rowsPerPage])

  return (
    <>
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {thrData && thrData.schedules.length === 0 && !loading && (
        <NoDataListComponent
          message={`You can set your employee and customize your payroll method on settings `}
        />
      )}
      {thrData && thrData.schedules.length > 0 && (
        <MaterialTable
          options={{...TableOptions, paging: false}}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onChangePage={page => {
            setCurrentPage(page)
          }}
          isLoading={thrData && loading}
          onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
          columns={[
            {
              title: 'Period',
              field: 'schedule',
              ...tableStyle({}, 'cell-first'),
              customSort: (a, b) => {
                return moment(a.schedule) - moment(b.schedule)
              },
              render: rowData => {
                const month = moment(rowData.schedule).format('MM')
                const year = moment(rowData.schedule).format('YYYY')
                return `${months[month - 1]} ${year}`
              },
            },
            // {
            //   title: 'Religion',
            //   field: 'religion',
            //   headerStyle: {minWidth: 175},
            //   cellStyle: {minWidth: 175, textAlign: 'left'},
            //   // customSort: (a, b) => {
            //   //   if (a.global_religion.name === b.global_religion.name) {
            //   //     return 0
            //   //   } else if (a.global_religion.name > b.global_religion.name) {
            //   //     return 1
            //   //   } else {
            //   //     return -1
            //   //   }
            //   // },
            //   render: rowData => {
            //     return rowData.global_religion.name
            //   },
            // },
            // {
            //   title: 'Status',
            //   field: 'status',
            //   // customSort: (a, b) => {
            //   //   return a.status - b.status
            //   // },
            //   render: rowData => (
            //     <Typography
            //       style={
            //         rowData.status === 'paid'
            //           ? {color: 'green'}
            //           : {color: 'red'}
            //       }
            //     >
            //       {rowData.status}
            //     </Typography>
            //   ),
            // },
            {
              title: 'THR Total Transferred',
              field: 'total',
              ...tableStyle({}, 'cell-number'),
              render: rowData => {
                return convertToRoundedRupiah(rowData.total, false)
              },
            },
            {
              title: 'Action',
              field: 'action',
              sorting: false,
              ...tableStyle(
                {
                  padding: '26px 28px 26px 16px',
                },
                'cell-number'
              ),
              render: rowData => (
                <Link
                  to={{
                    pathname: `/financial/payroll/report/payroll/detail`,
                    search: `?id=${rowData.id}&month=${moment(
                      rowData.schedule
                    ).format('MM')}&year=${moment(rowData.schedule).format(
                      'YYYY'
                    )}`,
                    state: {
                      active: 'report-thr-detail',
                      searchable: true,
                      filter: true,
                      religion: rowData.global_religion?.name,
                      month: moment(rowData.schedule).format('MM'),
                      year: moment(rowData.schedule).format('YYYY'),
                    },
                  }}
                  className={classes.link}
                >
                  <TextBlueBold>Details</TextBlueBold>
                </Link>
              ),
            },
          ]}
          data={thrData.schedules}
          totalCount={thrData.thr_count?.aggregate?.count}
        />
      )}
    </>
  )
}
