import React, {useState} from 'react'
import helperStyle, {
  BigRedText,
  RedText,
  FlexBetween,
  WrapperContent,
  FlexStart,
} from '../../../OrganizationPageStyles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  IconButton,
  Icon,
  Checkbox,
} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import {useMutation} from '@apollo/react-hooks'
import {DELETE_JOB_PROFILE} from '../../../../../graphql/mutations'
import {useSnackbar} from 'notistack'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DeleteAlertModal = props => {
  const {open, idJob, close, namePosition, data, doDelete, refetch} = props

  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [check, setCheck] = useState(false)

  const [deleteJobProfile] = useMutation(DELETE_JOB_PROFILE, {
    variables: {
      id: idJob,
    },
  })

  const removeFromTree = (root, id, parent, idx) => {
    if (root.id === id) {
      if (parent) {
        parent.children.splice(idx, 1)
      } else return null
    }

    for (const [i, e] of root.children.entries()) {
      removeFromTree(e, id, root, i)
    }

    return root
  }

  const handleDelete = () => {
    const newData = data && removeFromTree(data[0], idJob)
    const dataForDelete = newData !== null ? [newData] : []
    deleteJobProfile()
      .then(() => {
        enqueueSnackbar('Position deleted', {
          variant: 'success',
          autoHideDuration: 2000,
        })
        doDelete(dataForDelete)
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete Position failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
    // data ? doDelete(dataForDelete) : refetch()
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        style={{borderBottom: '2px solid #f7f8f9', color: '#014a62'}}
        id="alert-dialog-slide-title"
      >
        <FlexBetween>
          <BigRedText>{`Delete ${namePosition}?`}</BigRedText>
          <IconButton aria-label="close" onClick={close}>
            <Icon
              style={{
                color: '#000000',
                fontSize: '37px',
              }}
            >
              close
            </Icon>
          </IconButton>
        </FlexBetween>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{color: '#000000'}}
        >
          Are you sure to delete
          <RedText>{` ${namePosition} `}</RedText>
          permanently?
        </DialogContentText>
        <WrapperContent className={classes.marginTop10}>
          <FlexStart>
            <Checkbox
              value="primary"
              onChange={event => setCheck(event.target.checked)}
              className={classes.colorRed}
              inputProps={{'aria-label': 'primary checkbox'}}
            />
            I understand that deleting this file is permanent and cannot be
            undone. This action will also delete comments in this
          </FlexStart>
        </WrapperContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>
          <span style={{color: '#a9a8a8'}}>Cancel</span>
        </Button>

        <Button
          className={classes.btnDel}
          variant="contained"
          onClick={handleDelete}
          disabled={!check}
        >
          <span style={{color: '#ffffff'}}>Delete</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withRouter(DeleteAlertModal)
