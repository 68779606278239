import {Button, Divider, Typography, CircularProgress} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useEffect, useState} from 'react'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
} from '../../../../GlobalStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Link, withRouter} from 'react-router-dom'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import {useQuery} from '@apollo/react-hooks'
import {LIST_COMPETENCY_VERSION} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import {capitalize} from '../../../../utils/helpers'
import {status_data} from '../../../shared-components/data/SharedData'
import {competencyOptions} from '../CompetencyPageStyles'
import {format} from 'date-fns'

const ListDictionaryComponent = ({search}) => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const {
    data: listDictionaries,
    error: errorListDictionaries,
    loading: loadingListDictionaries,
    refetch: refetchListDictionaries,
  } = useQuery(LIST_COMPETENCY_VERSION, {
    variables: {
      company: COMPANY_ID,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      search: `%${search}%`,
    },
  })

  useEffect(() => {
    refetchListDictionaries()
  }, [])

  if (errorListDictionaries) {
    return <div>{JSON.stringify(errorListDictionaries)}</div>
  } else if (loadingListDictionaries) {
    return <CircularProgress />
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6" color="primary" style={{paddingLeft: 10}}>
          Kamus
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={{
            pathname: '/learning/competency/dictionary/add',
            state: {
              active: 'competence-dictionary',
            },
          }}
        >
          Tambah Versi Kamus
        </Button>
      </EnhancedToolbar>
      <Divider></Divider>

      <MaterialTable
        style={{
          padding: '1.6rem 1.6rem 0',
        }}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
        columns={[
          {
            title: 'Kode Versi Kamus',
            field: 'code',
          },
          {
            title: 'Nama Versi Kamus',
            field: 'name',
          },
          {
            title: 'Periode Mulai',
            field: 'date_start',
            render: row => (
              <span>{format(new Date(row.date_start), 'PP')}</span>
            ),
          },
          {
            title: 'Periude Berakhir',
            field: 'date_end',
            render: row => <span>{format(new Date(row.date_end), 'PP')}</span>,
          },
          {
            title: 'Status',
            field: 'status',
            render: row => (
              <span
                style={{
                  color: row.status === 'ACTIVE' ? 'green' : 'red',
                }}
              >
                {status_data[row.status] || capitalize(row.status)}
              </span>
            ),
          },

          {
            title: 'Aksi',
            field: 'id',
            sorting: false,
            render: rowData => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={{
                          pathname: `/learning/competency/dictionary/${rowData.id}`,
                          state: {
                            active: 'competence-dictionary',
                          },
                        }}
                        fontColor="#000"
                      >
                        Rincian
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={{
                          pathname: `/learning/competency/dictionary/edit/${rowData.id}`,
                          state: {
                            data: rowData,
                            active: 'competence-dictionary',
                          },
                        }}
                        fontColor="#000"
                      >
                        Ubah
                      </StyledMenuItem>
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            ),
          },
        ]}
        data={listDictionaries.competency_versions || []}
        title=""
        options={{
          ...TableOptions,
          ...competencyOptions,
        }}
        components={{
          Toolbar: () => null,
        }}
        isLoading={loadingListDictionaries}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={listDictionaries.total.aggregate.count}
        page={pagination.offset}
        pageSize={pagination.limit}
      />
    </PaperContainer>
  )
}

export default withRouter(ListDictionaryComponent)
