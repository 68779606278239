import gql from 'graphql-tag'

export const GET_LIST_GROUP_QUESTION_BANK = gql`
  query getListGroupQuestionBank($company: uuid!, $search: String) {
    multirater_bank_groups(
      where: {company: {_eq: $company}, name: {_ilike: $search}}
    ) {
      id
      name
      description
      multirater_bank_questions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_DETAIL_GROUP_QUESTION_BANK = gql`
  query getDetailGroupQuestionBank($company: uuid!, $id: bigint!) {
    multirater_bank_groups(where: {company: {_eq: $company}, id: {_eq: $id}}) {
      id
      name
      description
      multirater_bank_questions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`
