import gql from 'graphql-tag'

export const ADD_ORGAZNIATION_LEVEL = gql`
  mutation($objects: [company_organization_levels_insert_input!]!) {
    insert_company_organization_levels(objects: $objects) {
      affected_rows
    }
  }
`

export const EDIT_ORGAZNIATION_LEVEL = gql`
  mutation(
    $id: Int!
    $companyId: uuid!
    $newCode: String
    $newName: String
    $newDescription: String
    $newStatus: String
    $newColor: String
  ) {
    update_company_organization_levels(
      _set: {
        code: $newCode
        name: $newName
        description: $newDescription
        status: $newStatus
        color_hex: $newColor
      }
      where: {_and: {id: {_eq: $id}, company: {_eq: $companyId}}}
    ) {
      affected_rows
    }
  }
`

export const DELETE_ORGAZNIATION_LEVEL = gql`
  mutation($id: Int!, $companyId: uuid!) {
    update_company_organization_levels(
      _set: {deletedAt: "now()"}
      where: {
        _and: {
          id: {_eq: $id}
          company: {_eq: $companyId}
          deletedAt: {_is_null: true}
        }
      }
    ) {
      affected_rows
    }
  }
`
