import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'
import {Divider} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {FormContainer, TabsStyled, TabStyled} from '../../../../GlobalStyles'
import {ADD_NEW_LEAVE_POLICY_CHILD_WITH_FILTER} from '../../../../graphql/mutations'
import {
  GET_LEAVE_CHILD_POLICY_NAME,
  LIST_JOB_PROFILE_DROPDOWN_FORM,
} from '../../../../graphql/queries/index'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {manipulateFilter} from '../../attendance/work-schedule/filterDataAssignment'
import {
  AddDetailPolicyTabChild,
  AssignPositionTabChild,
} from './add-edit-tab-policy-child'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  heading: {
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontStyle: 'bold',
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    marginLeft: '12px',
  },
  paper: {
    width: '100%',
    // marginBottom: '10rem',
    marginBottom: theme.spacing(2),
  },
  expanded: {
    borderTop: '1px solid rgba(0, 0, 0, 0.16)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    margin: '0 !important',
  },
  bold: {
    fontWeight: 700,
  },
  mr15: {marginRight: '1.5rem'},
  textGrey: {color: '#a9a8a8'},
  outlinedInput: {
    padding: '10px 14px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'baseline',
    width: '18rem',
  },
  buttonSpacer: {marginRight: 12},
  listRoot: {
    paddingTop: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: '1rem',
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  titlePage: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    display: 'flex',
  },
}))

function AddLeavePolicy(props) {
  const policies = props.policies
  const client = useApolloClient()
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const secondConfirmations = [
    {
      name: 'Leave can be taken',
      value: '1',
    },
    {
      name: 'Second confirmation',
      value: '2',
    },
  ]

  const [selectedParent, setSelectedParent] = useState('')
  const [balance, setBalance] = useState(0)
  const [infiniteQuota, setInfiniteQuota] = useState(false)
  const [selectedConfirmationType, setSelectedConfirmationType] = useState('')
  const [tabsValue, setTabsValue] = useState(0)

  const [selectedPosition, setSelectedPosition] = useState('')
  const [selectedPositionSecond, setSelectedPositionSecond] = useState('')
  const [secondConfirmationState, setSecondConfirmationState] = useState('')
  // const [checked, setChecked] = React.useState([])
  const [name, setName] = React.useState('')
  const [gender, setGender] = React.useState('')
  const [isError, setIsError] = React.useState(false)
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [isExist, setIsExist] = useState(false)
  const [countApplied, setCountApplied] = useState(null)
  const [filter, setFilter] = useState({
    filterJobFunction: {},
    filterJobFamily: {},
    filterJobTitle: {},
    filterOrgUnit: {},
    filterOrgLevel: {},
    filterJobLevel: {},
    filterJobGrade: {},
    filterOffice: {},
    filterPosition: {},
  })
  const [searchJP, setSearchJP] = useState('')

  const {data: _dataJobProfile, loading: loadingJP} = useQuery(
    LIST_JOB_PROFILE_DROPDOWN_FORM,
    {
      variables: {
        search: `%${searchJP}%`,
        limit: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const dataJP = _dataJobProfile?.company_job_profiles.map(r => {
    return {
      value: r.id,
      label: r.title,
    }
  })

  const handleClickOpen = async type => {
    const setError = val => {
      setTabsValue(val)
      const ids = document.getElementById('top')
      ids.scrollIntoView({behavior: 'smooth'})
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    }
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      const {data: childName} = await client.query({
        query: GET_LEAVE_CHILD_POLICY_NAME,
        variables: {name},
      })
      if (childName.time_leave_policies.length > 0) {
        setIsExist(true)
        setTimeout(() => setIsExist(false), 3000)
        // console.log('exist')
      }
      if (
        !selectedParent ||
        !name ||
        (parseInt(balance) === 0 && !infiniteQuota) ||
        !selectedConfirmationType ||
        (selectedConfirmationType === 2 && !selectedPosition) ||
        (secondConfirmationState === secondConfirmations[1].value &&
          !selectedPositionSecond) ||
        childName.time_leave_policies.length > 0
      ) {
        setError(0)
      } else if (!countApplied || countApplied === 0) {
        setError(1)
      } else {
        setConfirmType(type)
        setPopup(true)
      }
    }
  }
  const handleClose = () => {
    setPopup(false)
  }

  const [addPolicy] = useMutation(ADD_NEW_LEAVE_POLICY_CHILD_WITH_FILTER)

  const history = () => {
    props.history.push({
      pathname: '/time/leave/settings',
      state: {searchable: true, active: 'settings'},
    })
  }
  const addPolicyChild = () => {
    const variables = {
      parent: selectedParent.value,
      name: name,
      infinite_quota: infiniteQuota,
      quota: balance,
      confirm_type: selectedConfirmationType,
      first_confirm_profile: selectedPosition.value || null,
      second_confirm_profile: selectedPositionSecond.value || null,
      gender: gender || 'U',
      filter: manipulateFilter(filter),
    }
    addPolicy({variables})
      .then(() => {
        enqueueSnackbar('Success Update data', {
          variant: 'success',
          autoHideDuration: 2000,
        })
        history()
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleSearch = e => {
    setSearchJP(e)
  }

  const handleChangeTab = (e, newValue) => {
    e.preventDefault()
    setTabsValue(newValue)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar} disableGutters>
          <div className={classes.titlePage}>
            <IconButton
              onClick={() => handleClickOpen('discard')}
              edge="end"
              aria-label="back"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add Leave and Permission Policy Child
            </Typography>
          </div>
          <div className={classes.action}>
            <Button
              size="large"
              className={classes.buttonSpacer}
              onClick={() => handleClickOpen('discard')}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickOpen('add')}
              size="large"
            >
              Save
            </Button>
          </div>
        </Toolbar>
        <Divider />
        <TabsStyled value={tabsValue} onChange={handleChangeTab}>
          <TabStyled label="Detail Policy" />
          <TabStyled label="Assign Position" />
        </TabsStyled>
        <FormContainer style={{paddingBottom: 0}} id="top">
          {tabsValue === 0 && (
            <AddDetailPolicyTabChild
              name={name}
              setName={setName}
              selectedParent={selectedParent}
              setSelectedParent={setSelectedParent}
              policies={policies}
              balance={balance}
              setBalance={setBalance}
              infiniteQuota={infiniteQuota}
              setInfiniteQuota={setInfiniteQuota}
              selectedConfirmationType={selectedConfirmationType}
              setSelectedConfirmationType={setSelectedConfirmationType}
              selectedPosition={selectedPosition}
              setSelectedPosition={setSelectedPosition}
              handleSearch={handleSearch}
              secondConfirmationState={secondConfirmationState}
              setSecondConfirmationState={setSecondConfirmationState}
              selectedPositionSecond={selectedPositionSecond}
              setSelectedPositionSecond={setSelectedPositionSecond}
              gender={gender}
              setGender={setGender}
              isExist={isExist}
              isError={isError}
              dataJP={dataJP}
              loading={loadingJP}
            />
          )}
          {tabsValue === 1 && (
            <AssignPositionTabChild
              count={countApplied}
              setCount={setCountApplied}
              isError={isError}
              variablesQuery={filter}
              setVars={setFilter}
            />
          )}
        </FormContainer>
        <Divider />
      </Paper>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={name}
        feature="Leave and Permission Policy"
        mutation={confirmType === 'discard' ? history : addPolicyChild}
      />
    </div>
  )
}

export default withRouter(AddLeavePolicy)
