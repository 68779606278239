import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  Button,
  Divider,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'

import {SettingsContentWrapper} from './SettingStyles'
import ListComponent from './list/ListComponent'
import ListPolicy from './list/ListPolicy'

import {PaperContainer, EnhancedToolbar} from '../../../../GlobalStyles'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import NoDataListFree from '../../../shared-components/NoDataListFreeComponent'
import DeletePopupMini from '../../../shared-components/popup/DeletePopupMini'
import Loading from '../../../../components/loading/LoadingComponent'

import {COMPANY_ID} from '../../../../utils/globals'
import {
  GET_LIST_TRIP_COMPONENT,
  GET_LIST_TRIP_POLICY,
} from '../../../../graphql/queries'
import {
  DELETE_LIST_SETTING_COMPONENT,
  DELETE_LIST_SETTING_POLICY,
} from '../../../../graphql/mutations'
import {DELETE_MESSAGE} from '../businessTripHelper'

const useToolbarStyles = makeStyles(theme => ({
  tabsRoot: {
    backgroundColor: '#f7f8f9',
  },
  root: {
    // marginLeft: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },

  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
  },
  title: {
    flex: '0 0 auto',
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginleft: theme.spacing(3),
  },
  customDatePicker: {
    width: '10rem',
    marginRight: 12,
  },
}))

const Settings = props => {
  const {searchText} = props
  const {enqueueSnackbar} = useSnackbar()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const [open, setOpen] = useState({
    confirm: false,
    delete: false,
    deleteId: null,
    model: '',
    deleteName: '',
  })

  const [tabsValue, setTabsValue] = useState('Component')
  const isComponentTab = tabsValue === 'Component'

  const [mutationDeleteComponent] = useMutation(DELETE_LIST_SETTING_COMPONENT)
  const [mutationDeletePolicy] = useMutation(DELETE_LIST_SETTING_POLICY)

  const classesTabs = useToolbarStyles()

  const {
    data: componentData,
    error: errorComponent,
    refetch: refetchComponent,
  } = useQuery(GET_LIST_TRIP_COMPONENT, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      company: COMPANY_ID,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      search: `%${searchText}%`,
    },
  })

  const {
    data: policyData,
    error: errorPolicy,
    refetch: refetchPolicy,
  } = useQuery(GET_LIST_TRIP_POLICY, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      company: COMPANY_ID,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      search: `%${searchText}%`,
    },
  })

  if (componentData === undefined && errorComponent === undefined) {
    return <Loading />
  } else if (errorComponent !== undefined) {
    return <div>{errorComponent}</div>
  }

  if (policyData === undefined && errorPolicy === undefined) {
    return <Loading />
  } else if (errorPolicy !== undefined) {
    return <div>{errorPolicy}</div>
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const handleClose = type => {
    setOpen({
      ...open,
      [type]: false,
    })
  }

  const onDeleteCompnent = () => {
    if (open.model === 'Component') {
      mutationDeleteComponent({
        variables: {id: open.deleteId},
      })
        .then(() => {
          enqueueSnackbar(`Success Delete data component`, {variant: 'success'})
          refetchComponent()
        })
        .catch(e => e)
    } else {
      mutationDeletePolicy({
        variables: {id: open.deleteId},
      })
        .then(() => {
          enqueueSnackbar(`Success Delete data component`, {variant: 'success'})
          refetchPolicy()
        })
        .catch(e => e)
    }
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const dataNull = () => {
    return (
      <NoDataListFree
        message1="Sorry, No List"
        message2={`You haven't create any ${tabsValue}`}
        button={
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            style={{fontSize: 12}}
            to={{
              pathname: isComponentTab
                ? '/business/trip/settings/add-component'
                : '/business/trip/settings/add-policy',
              state: {
                active: isComponentTab ? 'add-component' : 'add-policy',
                type: 'Add',
              },
            }}
          >
            {`Add ${tabsValue}`}
          </Button>
        }
      />
    )
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography style={{fontWeight: 'bold'}} variant="subtitle1">
          Settings
        </Typography>
      </EnhancedToolbar>
      <Divider></Divider>
      <Tabs
        value={tabsValue}
        onChange={handleChangeTabs}
        indicatorColor="secondary"
        classes={{
          root: classesTabs.tabsRoot,
          indicator: classesTabs.tabsIndicator,
        }}
      >
        <Tab
          label="Component"
          value="Component"
          className={classesTabs.tabRoot}
        />
        <Tab label="Policy" value="Policy" className={classesTabs.tabRoot} />
      </Tabs>
      <EnhancedToolbar>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          style={
            isComponentTab
              ? componentData?.total?.aggregate.count === 0
                ? {display: 'none'}
                : {fontSize: 12}
              : policyData?.total?.aggregate.count === 0
              ? {display: 'none'}
              : {fontSize: 12}
          }
          to={{
            pathname: isComponentTab
              ? '/business/trip/settings/add-component'
              : '/business/trip/settings/add-policy',
            state: {
              active: isComponentTab ? 'add-component' : 'add-policy',
              type: 'Add',
            },
          }}
        >
          {`Add ${tabsValue}`}
        </Button>
      </EnhancedToolbar>

      <SettingsContentWrapper>
        {isComponentTab
          ? componentData?.total?.aggregate.count > 0
            ? componentData.business_trip_component.map((row, key) => (
                <ListComponent
                  setOpen={setOpen}
                  key={key}
                  noMargin
                  data={row}
                />
              ))
            : dataNull()
          : tabsValue === 'Policy'
          ? policyData?.total?.aggregate.count > 0
            ? policyData.business_trip_policies.map((row, key) => (
                <ListPolicy setOpen={setOpen} key={key} noMargin data={row} />
              ))
            : dataNull()
          : null}
      </SettingsContentWrapper>

      <Divider />

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        count={
          isComponentTab
            ? componentData?.total?.aggregate.count
            : policyData?.total?.aggregate.count
        }
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={
          isComponentTab
            ? componentData?.total?.aggregate.count === 0
              ? {display: 'none'}
              : null
            : policyData?.total?.aggregate.count === 0
            ? {display: 'none'}
            : null
        }
      />

      <DeletePopupMini
        open={open.delete}
        handleClose={() => handleClose('delete')}
        id={open.deleteId}
        name={open.deleteName}
        mutation={() => onDeleteCompnent()}
      />

      <AlertDialog
        open={open.confirm}
        handleClose={() => handleClose('confirm')}
        feature={`Unable to delete`}
        message={DELETE_MESSAGE?.[open.model]?.replace(
          '[name]',
          open.deleteName
        )}
        type="Confirm"
        status="false"
      />
    </PaperContainer>
  )
}

export default Settings
