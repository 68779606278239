import JobTitle from './JobTitlePageComponent'

export const JobTitlePageConfig = {
  routes: [
    {
      path: '/organization/job-title',
      component: JobTitle,
      state: 'job-title',
      exact: true,
    },
    {
      path: '/organization/job-title/add',
      component: JobTitle,
      state: 'add-job-title',
      exact: false,
    },
    {
      path: '/organization/job-title/edit',
      component: JobTitle,
      state: 'edit-job-title',
      exact: false,
    },
  ],
}
