import React from 'react'
import {TextField, MenuItem} from '@material-ui/core'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import moment from 'moment'

const AboutStepComponent = props => {
  const {isError, isErrorDate, errorDate, dataContext, setDataContext} = props
  const dateNow = moment(new Date()).format('YYYY-MM-DD')

  const handleChange = name => e => {
    setDataContext({
      ...dataContext,
      aboutStep: {
        ...dataContext.aboutStep,
        [name]: e.target.value,
      },
    })
  }

  const handleChangeDate = name => e => {
    setDataContext({
      ...dataContext,
      aboutStep: {
        ...dataContext.aboutStep,
        [name]: moment(e).format('YYYY-MM-DD'),
      },
    })
  }

  return (
    <FormContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Version Name</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            value={dataContext.aboutStep.name}
            onChange={handleChange('name')}
            placeholder="Enter the name"
            error={!dataContext.aboutStep.name && isError}
            helperText={
              !dataContext.aboutStep.name && isError
                ? 'This field is required'
                : ''
            }
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Start Date</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              size="small"
              minDate={dateNow}
              value={dataContext.aboutStep.start_date}
              onChange={handleChangeDate('start_date')}
              error={!dataContext.aboutStep.start_date && isError}
              helperText={
                !dataContext.aboutStep.start_date && isError
                  ? 'This field is required'
                  : ''
              }
            />
          </MuiPickersUtilsProvider>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>End Date</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              size="small"
              minDate={dataContext.aboutStep.start_date}
              value={dataContext.aboutStep.end_date}
              onChange={handleChangeDate('end_date')}
              error={errorDate && isErrorDate}
              helperText={errorDate && isErrorDate ? errorDate : ''}
            />
          </MuiPickersUtilsProvider>
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>Description</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            multiline
            rows="6"
            value={dataContext.aboutStep.description}
            onChange={handleChange('description')}
            placeholder="Enter the description"
            error={!dataContext.aboutStep.description && isError}
            helperText={
              !dataContext.aboutStep.description && isError
                ? 'This field is required'
                : ''
            }
          />
        </FormChildInput>
      </FormChildContainer>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>5.</TitleNumber>
          <TitleName>Status</TitleName>
        </FormChildTitle>
        <FormChildInput>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            select
            value={dataContext.aboutStep.status}
            onChange={handleChange('status')}
            placeholder="Enter the status"
            error={!dataContext.aboutStep.status && isError}
            helperText={
              !dataContext.aboutStep.status && isError
                ? 'This field is required'
                : ''
            }
          >
            <MenuItem value="" disabled>
              Select status
            </MenuItem>
            <MenuItem value="ACTIVE" style={{color: 'green'}}>
              ACTIVE
            </MenuItem>
            <MenuItem value="DRAFT">DRAFT</MenuItem>
          </TextField>
        </FormChildInput>
      </FormChildContainer>
    </FormContainer>
  )
}

export default AboutStepComponent
