import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'
import AddEditJobFunction from './list-job-function/AddEditJobFunction'
import ListJobFunction from './list-job-function/ListJobFunction'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'job-function':
      return <ListJobFunction searchText={searchText} />
    case 'add-job-function':
      return (
        <AddEditJobFunction status={state.status} dataCheck={state.dataCheck} />
      )
    case 'edit-job-function':
      return (
        <AddEditJobFunction
          status={state.status}
          data={state.data}
          dataCheck={state.dataCheck}
        />
      )
    default:
      return <ListJobFunction searchText={searchText} />
  }
}

const JobFunction = props => {
  let activeMenu = 'job-function'

  let state = {}

  if (props.location.state) {
    activeMenu = props.location.state.active
      ? props.location.state.active
      : 'job-function'
    state = props.location.state
  }

  useEffect(() => {
    if (props.location.pathname === '/organization/job-function') {
      props.history.push({
        state: {
          active: 'job-function',
          searchable: true,
        },
      })
    }
  }, [])

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <Card icon="device_hub" title="Jenis Jabatan" onSearch={onSearch}>
      <PageContainer>
        {renderComponent(activeMenu, state, searchText)}
      </PageContainer>
    </Card>
  )
}

export default withRouter(JobFunction)
