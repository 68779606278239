import React, {useState} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
} from '@material-ui/core'
import {TextDarkBlue, TextBlackBold, Flex} from '../../../../GlobalStyles'

const SubmitDialogComponent = props => {
  const {open, handleClose, edit, mutation} = props

  const [checked, setChecked] = useState(false)

  const setWord = edit ? 'menukar' : 'menetapkan'

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <TextDarkBlue>
          {edit ? 'Menukar' : 'Menetapkan'} ke Kamus yang saat ini Aktif?
        </TextDarkBlue>
      </DialogTitle>
      <DialogContent>
        <TextBlackBold>
          Jika kamu {setWord} kamus ini ke aktif, kamus yang saat ini aktif akan
          berubah menjadi nonaktif. Apakah kamu yakin ingin memproses?
        </TextBlackBold>
        <Flex style={{marginTop: 20}}>
          <Checkbox
            checked={checked}
            color="primary"
            onChange={() => setChecked(!checked)}
          />
          <TextBlackBold>
            Saya memahami bahwa Kamus yang saat ini aktif akan diubah menjadi
            nonaktif dan akan mengaktifkan Kamus ini.
          </TextBlackBold>
        </Flex>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Batal
        </Button>
        <Button
          onClick={mutation}
          color="primary"
          variant="contained"
          disabled={!checked}
        >
          Konfirmasi
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubmitDialogComponent
