import gql from 'graphql-tag'

export const TOPUP_COMPANY_BALANCE = gql`
  mutation($nominal: Float, $description: String) {
    result: topUpCompany(nominal: $nominal, description: $description) {
      message
      transaction_id
      formatted_id
      total_successful_top_ups
      total_failed_top_ups
    }
  }
`

export const UPDATE_COMPANY_WALLET_FIELDS = gql`
  mutation($companyID: uuid!, $data: jsonb!) {
    result: update_global_wallet_accounts(
      where: {company_profiles: {id: {_eq: $companyID}}}
      _set: {account_fields: $data}
    ) {
      affected_rows
    }
  }
`
