import React, {useEffect, useState} from 'react'
import MaterialTable from 'material-table'
import {
  CircularProgress,
  Button,
  Typography,
  IconButton,
  DialogContent,
  Dialog,
  Icon,
  makeStyles,
} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import {useApolloClient, useQuery} from '@apollo/react-hooks'

import AlertDialog from '../../../../shared-components/popup/ResultPopup'
import EmployeeAvatar from '../../../../shared-components/EmployeeAvatar'
import ExportBaloon from '../../../../../components/exportExcel/ExportBaloon'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'

import {
  RootContainer,
  GenerateTooltip,
  PrimaryModalHeader,
} from '../../../../../GlobalStyles'
import {
  CenterWrapper,
  TableOptions,
  pageSizeOption,
} from '../../PayrollPageStyles'
import {tableStyle} from '../../PayrollTableStyles'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import {
  COUNT_PAYROLL_PROCESS,
  CHECK_ANNUAL_REPORT,
  GET_ANNUAL_REPORT,
} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import {
  convertToRoundedRupiah,
  employeeColumnSort,
} from '../../../../../utils/helpers'

const TABLE_REPORT_NAME = 'finance_annual_report'
const TABLE_REPORT_GENERATE = 'finance_annual_generate'

const useStyles = makeStyles(() => ({
  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  alert: {
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  type: {fontSize: 10},
  message: {fontSize: 12, fontWeight: '500'},
  titleGroup: {marginBottom: 10, fontWeight: '600'},
}))

function MultipleProcessAlert({open, onClose, children}) {
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <PrimaryModalHeader>
        <Typography variant="h6" style={{color: '#fff'}}>
          Running Process
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon style={{color: '#fff'}}>close</Icon>
        </IconButton>
      </PrimaryModalHeader>
      <DialogContent className={classes.content}>
        <Typography className={classes.titleGroup}>
          Generate & Download Annual Report
        </Typography>
        {children}
      </DialogContent>
    </Dialog>
  )
}

const Annual = props => {
  const client = useApolloClient()
  const {
    year,
    searchText,
    filterData,
    setIsDownloadable,
    onGenerate,
    onDownload,
    isDisableButtonGenerate,
    dataBaloon,
    dataGenerate,
    onCloseBaloon,
    onCloseGenerate,
  } = props

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const [openGenerateAlert, setOpenGenerateAlert] = useState(false)
  const [payrollMonthTotal, setPayrollMonthTotal] = useState(null)
  const [alertProcess, setAlertProcess] = useState(false)

  const [
    openGenerateAnnualInformationPopup,
    setOpenGenerateAnnualInformationPopup,
  ] = useState(false)
  const [
    openGenerateAnnualWarningPopup,
    setOpenGenerateAnnualWarningPopup,
  ] = useState(false)

  const {data: payrollReportList, refetch, loading, error} = useQuery(
    GET_ANNUAL_REPORT,
    {
      variables: {
        company: COMPANY_ID,
        year: year,
        offset: currentPage * rowsPerPage,
        limit: rowsPerPage,
        search: `%${searchText}%`,
        rank:
          JSON.stringify(filterData?.rank) === '[]' ? null : filterData?.rank,
        organization:
          JSON.stringify(filterData?.organization) === '[]'
            ? null
            : filterData?.organization,
        position:
          JSON.stringify(filterData?.position) === '[]'
            ? null
            : filterData?.position,
        office:
          JSON.stringify(filterData?.office) === '[]'
            ? null
            : filterData?.office,
      },
      onCompleted: data => {
        if (data) {
          setIsDownloadable(data?.people_work_payroll_annual?.length > 0)
        }
      },
      fetchPolicy: 'cache-and-network',
      ...GQL_FINANCE_OPTIONS,
    }
  )

  const handleCheckPayrollProcess = async () => {
    const {data} = await client.query({
      query: COUNT_PAYROLL_PROCESS,
      variables: {
        company: COMPANY_ID,
        year: year,
      },
      fetchPolicy: 'network-only',
      ...GQL_FINANCE_OPTIONS,
    })

    setPayrollMonthTotal(data?.v_finance_payrolls_aggregate?.aggregate?.count)
    if (data?.v_finance_payrolls_aggregate?.aggregate?.count === 12) {
      setOpenGenerateAlert(prev => !prev)
    } else {
      setOpenGenerateAnnualWarningPopup(true)
    }
  }

  const handleClickGenerate = async () => {
    const {data} = await client.query({
      query: CHECK_ANNUAL_REPORT,
      variables: {
        company: COMPANY_ID,
        year: year,
      },
      fetchPolicy: 'network-only',
      ...GQL_FINANCE_OPTIONS,
    })

    if (data?.people_work_payroll_aggregate?.aggregate?.count > 0) {
      handleCheckPayrollProcess()
    } else {
      setOpenGenerateAnnualInformationPopup(true)
    }
  }

  const onGenerateAnnualReport = () => {
    onGenerate({
      variables: {
        table: TABLE_REPORT_GENERATE,
        filter: {period_year: year},
      },
    })
    setOpenGenerateAlert(false)
  }

  const handleAnnualDownload = () => {
    onDownload({
      variables: {
        table: TABLE_REPORT_NAME,
        filter: {period_year: year},
      },
    })
  }

  const reducePayrollAnnual = (payrolls, number = false) => {
    if (payrolls) {
      const reducedPayroll = payrolls.reduce(
        (current, next) => +current + +next.value,
        0
      )
      return number
        ? reducedPayroll
        : convertToRoundedRupiah(reducedPayroll || 0, false)
    }
    return number ? 0 : convertToRoundedRupiah(0, false)
  }

  /**
   * earnings = allowance_corrections
   * deduction = nfc_corrections
   * take home pay = (salary + earnings) - deduction
   */
  const reduceTakeHomePay = (rowData, number = false) => {
    const thp =
      rowData?.salary +
      reducePayrollAnnual(rowData?.allowance_corrections, true) -
      reducePayrollAnnual(rowData?.nfc_corrections, true)
    return number ? thp : convertToRoundedRupiah(thp, false)
  }

  const renderGenerateButton = (
    buttonSize,
    buttonTitle,
    buttonVariant = 'text'
  ) => (
    <GenerateTooltip
      title={
        isDisableButtonGenerate ? `You can only generate data once a day` : ``
      }
      placement="left"
    >
      <span>
        <Button
          size={buttonSize || 'small'}
          variant={buttonVariant}
          color="primary"
          disabled={isDisableButtonGenerate}
          onClick={handleClickGenerate}
          style={{
            fontSize: `${buttonSize ? '.9375rem' : '.8125rem'}`,
            height: '100%',
            minWidth: 125,
            justifyContent: 'flex-start',
          }}
        >
          {buttonTitle || 'Generate'}
        </Button>
      </span>
    </GenerateTooltip>
  )

  useEffect(() => {
    setCurrentPage(0)
  }, [year, rowsPerPage])

  useEffect(() => {
    refetch()
  }, [dataBaloon, dataGenerate])
  return (
    <RootContainer>
      <MultipleProcessAlert
        open={alertProcess}
        onClose={() => setAlertProcess(false)}
      >
        <ExportBaloon
          availableToSeeProblem
          availableToDownloadExcel={false}
          data={dataGenerate}
          closeBaloonMutation={onCloseGenerate}
          feature="Annual Report Generate"
        />
        <ExportBaloon
          availableToSeeProblem
          isReport
          data={dataBaloon}
          closeBaloonMutation={onCloseBaloon}
          retry={handleAnnualDownload}
          feature="Annual Report"
        />
      </MultipleProcessAlert>

      {(!!dataBaloon || !!dataGenerate) && (
        <Alert color={'info'} severity={'info'} style={{marginBottom: 8}}>
          List running processes
          <a
            onClick={() => setAlertProcess(true)}
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              marginLeft: 14,
            }}
          >
            See all
          </a>
        </Alert>
      )}

      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}

      {payrollReportList?.people_work_payroll_annual?.length === 0 &&
        !loading && (
          <NoDataListComponent
            message={`You can start generate annual report here`}
          >
            {renderGenerateButton(
              'large',
              `Generate Annual Report - ${year}`,
              'contained'
            )}
          </NoDataListComponent>
        )}

      {payrollReportList?.people_work_payroll_annual?.length > 0 && (
        <MaterialTable
          data={payrollReportList?.people_work_payroll_annual}
          options={{...TableOptions}}
          page={currentPage}
          totalCount={payrollReportList?.total?.aggregate?.count}
          rowsPerPage={rowsPerPage}
          isLoading={payrollReportList && loading}
          onChangePage={page => {
            setCurrentPage(page)
          }}
          onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
          columns={[
            {
              title: 'Employee',
              ...tableStyle(
                {
                  minWidth: 350,
                  whiteSpace: 'normal',
                },
                'cell'
              ),
              customSort: employeeColumnSort,
              render: rowData => {
                if (rowData.employee_placement.global_user) {
                  return (
                    <EmployeeAvatar
                      src={rowData.employee_placement.global_user.avatar}
                      regno={rowData.employee_placement.regno}
                      title={
                        rowData.employee_placement.company_job_profile.title
                      }
                      global_user={rowData.employee_placement.global_user}
                      city_name={
                        rowData.employee_placement.company_job_profile
                          .company_address.global_city.name
                      }
                      province_name={
                        rowData.employee_placement.company_job_profile
                          .company_address.global_province.name
                      }
                      empStatus={rowData.employee_placement?.status}
                      empEndDate={rowData.employee_placement?.end_date}
                    />
                  )
                }
              },
            },
            {
              title: 'Basic Salary',
              field: 'salary',
              ...tableStyle({}, 'cell-number'),
              customSort: (a, b) => {
                return a.salary - b.salary
              },
              render: rowData => {
                return convertToRoundedRupiah(rowData.salary, false)
              },
            },
            {
              title: 'Earning',
              field: 'income',
              ...tableStyle({}, 'cell-number'),
              customSort: (a, b) => {
                return (
                  reducePayrollAnnual(a.allowance_corrections, true) -
                  reducePayrollAnnual(b.allowance_corrections, true)
                )
              },
              render: rowData => {
                return reducePayrollAnnual(rowData.allowance_corrections)
              },
            },
            {
              title: 'Deduction',
              field: 'deduction,',
              ...tableStyle({}, 'cell-number'),
              customSort: (a, b) => {
                return (
                  reducePayrollAnnual(a.nfc_corrections, true) -
                  reducePayrollAnnual(b.nfc_corrections, true)
                )
              },
              render: rowData => {
                return reducePayrollAnnual(rowData.nfc_corrections)
              },
            },
            {
              title: 'Take Home Pay',
              field: 'final_salary',
              ...tableStyle({}, 'cell-number'),
              customSort: (a, b) => {
                return reduceTakeHomePay(a, true) - reduceTakeHomePay(b, true)
              },
              render: rowData => {
                return reduceTakeHomePay(rowData)
              },
            },
          ]}
        />
      )}
      <AlertDialog
        open={openGenerateAnnualInformationPopup}
        handleClose={() => setOpenGenerateAnnualInformationPopup(false)}
        feature={`Payroll Source Not Found`}
        message={`Payroll source not found, please generate payroll process in year ${year}`}
        type="Confirm"
      />
      <AlertDialog
        open={openGenerateAnnualWarningPopup}
        handleClose={() => setOpenGenerateAnnualWarningPopup(false)}
        feature={`Warning`}
        message={`Incomplete payroll data, only ${payrollMonthTotal} months exist of required 12. Generate anyway?`}
        mutation={() => {
          setOpenGenerateAnnualWarningPopup(false)
          setTimeout(() => {
            setOpenGenerateAlert(prev => !prev)
          }, 300)
        }}
        positiveTitleButton="Yes"
        status="cancel"
        type="Confirm"
      />
      <AlertDialog
        open={openGenerateAlert}
        handleClose={onGenerateAnnualReport}
        feature="Generate Data is Processing!"
        message="Generate data is processing! Don’t worry, you can still do other activities"
        type="Confirm"
      />
    </RootContainer>
  )
}

export default Annual
