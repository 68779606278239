import gql from 'graphql-tag'

/// Employee Balance
export const GET_EMPLOYEE_BALANCES = gql`
  query(
    $where: people_work_placements_bool_exp
    $search: String
    $limit: Int
    $offset: Int
    $startDate: timestamp
    $endDate: timestamp
  ) {
    total: people_work_placements_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    employees: people_work_placements(
      where: $where
      order_by: {regno: asc_nulls_last}
      limit: $limit
      offset: $offset
    ) {
      id
      regno
      user: global_user {
        id
        name
        wallet: global_wallet_account {
          id
          total: globalTransactionsByTo_aggregate(
            where: {
              transaction_item_table: {_eq: "system"}
              date_created: {_gte: $startDate}
            }
          ) {
            aggregate {
              sum {
                value
              }
            }
          }
          in: globalTransactionsByTo(
            where: {
              transaction_item_table: {_eq: "system"}
              date_created: {_gte: $startDate}
              _not: {
                from: {_is_null: false}
                to: {_is_null: false}
                parent_id: {_is_null: true}
                value: {_lt: 0}
              }
            }
            order_by: {date_created: desc_nulls_last}
            limit: 1
          ) {
            id
            nominal: value
          }
        }
      }
      claimed: people_work_claims_aggregate(
        where: {
          invoice_id: {_is_null: false}
          status: {_eq: "approved"}
          last_modified: {_gte: $startDate, _lte: $endDate}
        }
      ) {
        aggregate {
          sum {
            value
          }
        }
      }
    }
  }
`

/// Employee Balance > Employee Summary
export const GET_EMPLOYEE_SUMMARY = gql`
  query($employeePlacementID: Int!) {
    summary: people_work_placements_by_pk(id: $employeePlacementID) {
      id
      regno
      user: global_user {
        id
        avatar
        name
        wallet: global_wallet_account {
          id
        }
      }
      profile: company_job_profile {
        id
        position: title
      }
      company: company_profile {
        id
        name: legal_name
      }
      address: company_address {
        id
        office_name
        city: global_city {
          id
          name
        }
      }
    }
  }
`

export const GET_EMPLOYEE_BALANCE = gql`
  query(
    $userID: uuid!
    $companyID: uuid!
    $startDate: timestamp
    $endDate: timestamp
  ) {
    total: global_transactions_aggregate(
      where: {
        transaction_item_table: {_eq: "system"}
        date_created: {_gte: $startDate}
        _or: [
          {
            global_wallet_account: {foreign_owner_id: {_eq: $companyID}}
            globalWalletAccountByTo: {foreign_owner_id: {_eq: $userID}}
          }
          {
            parent: {
              global_wallet_account: {foreign_owner_id: {_eq: $companyID}}
            }
            globalWalletAccountByTo: {foreign_owner_id: {_eq: $userID}}
          }
          {
            global_wallet_account: {foreign_owner_id: {_eq: $userID}}
            globalWalletAccountByTo: {foreign_owner_id: {_eq: $companyID}}
          }
        ]
      }
    ) {
      aggregate {
        sum {
          value
        }
      }
    }
    claimed: people_work_claims_aggregate(
      where: {
        people_work_placement: {user: {_eq: $userID}, status: {_eq: "ACTIVE"}}
        invoice_id: {_is_null: false}
        status: {_eq: "approved"}
        last_modified: {_gte: $startDate, _lte: $endDate}
      }
    ) {
      aggregate {
        sum {
          value
        }
      }
    }
  }
`

/// Employee Balance > Employee Transaction History
export const GET_EMPLOYEE_TRANSACTION_HISTORY = gql`
  query($where: global_transactions_bool_exp, $offset: Int, $limit: Int) {
    total: global_transactions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    transactions: global_transactions(
      where: $where
      order_by: {date_created: desc_nulls_last}
      limit: $limit
      offset: $offset
    ) {
      id
      formatted_id
      nominal: value
      date: date_created
      type
    }
  }
`

/// Employee Balance > Employee Transaction Details
export const GET_EMPLOYEE_TRANSACTION_DETAILS = gql`
  query($transactionID: Int!) {
    details: global_transactions_by_pk(id: $transactionID) {
      id
      formatted_id
      nominal: value
      date: date_created
      type
      description
      invoice: global_invoice {
        vendor: global_wallet_vendor {
          company: company_profiles {
            id
            name: legal_name
          }
          user: global_users {
            id
            name
          }
        }
      }
    }
  }
`
