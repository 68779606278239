import React, {useState} from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import {Divider, makeStyles} from '@material-ui/core'
import {useMutation, useApolloClient} from '@apollo/react-hooks'
import {
  LIST_JOB_PROFILE_DROPDOWN_FORM,
  GET_OVER_POLICY_NAME,
} from '../../../../graphql/queries'
import {ADD_OVERTIME_POLICY_WITH_FILTER} from '../../../../graphql/mutations'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import {Link, withRouter} from 'react-router-dom'
import {ArrowBack} from '@material-ui/icons'
import {useSnackbar} from 'notistack'

import {FormContainer, TabsStyled, TabStyled} from '../../../../GlobalStyles'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {AddDetailTabPolicy} from './add-edit-tab-policy'
import {AssignPositionTabChild} from './add-edit-tab-policy/AssignPositionTabChild'
import {manipulateFilter} from '../../attendance/work-schedule/filterDataAssignment'
import {compansationHandler} from './add-edit-tab-policy/standard-type/helpers'

export const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontStyle: 'bold',
  },
  title: {
    flexGrow: 1,
    marginLeft: '15px',
  },
  paper: {
    width: '100%',
    // marginBottom: '10rem',
    marginBottom: 228,
    marginLeft: theme.spacing(1),
    // padding: '10px',
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  outlinedInput: {
    padding: '10px 14px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'baseline',
    width: '18rem',
  },
  listRoot: {
    paddingTop: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: '1rem',
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  buttonSpacer: {marginRight: 12},
  fiedCurrency: {textAlign: 'right'},
  flexPerHour: {display: 'flex', marginTop: 10, alignItems: 'center'},
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  titlePage: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    display: 'flex',
  },
  formContainer: {
    marginBottom: 54,
  },
}))

function AddOvertimePolicy(props) {
  const confirmationTypes = [
    {
      name: 'Direct Supervisor',
      value: '1',
    },
    {
      name: 'Position',
      value: '2',
    },
    {
      name: 'No Approval',
      value: '3',
    },
  ]

  const secondConfirmations = [
    {
      name: 'Overtime can be taken',
      value: '1',
    },
    {
      name: 'Second confirmation',
      value: '2',
    },
  ]

  const client = useApolloClient()

  const classes = useStyles()

  const {enqueueSnackbar} = useSnackbar()

  const [name, setName] = React.useState('')
  // sebelum
  // const [isConvertToMoney, setConvertToMoney] = React.useState(false)

  // some fitur hide
  const [isConvertToMoney, setConvertToMoney] = React.useState(true)
  const [isConvertToLeave, setConvertToLeave] = React.useState(false)
  const [moneyCompensation, setMoneyCompensation] = React.useState(null)
  const [standard, setStandard] = React.useState('')
  const [selectedConfirmationType, setSelectedConfirmationType] = useState('')
  const [selectedPosition, setSelectedPosition] = useState('')
  const [secondConfirmationState, setSecondConfirmationState] = useState('')
  const [selectedPositionSecond, setSelectedPositionSecond] = useState('')
  const [leavePolicyChildren, setLeavePolicyChildren] = useState('')
  const [minimumHour, setMinimumHour] = useState(0)
  const [isError, setIsError] = React.useState(false)
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [isExist, setIsExist] = useState(false)
  const [tabsValue, setTabsValue] = useState(0)
  const [countApplied, setCountApplied] = useState(null)
  const [filter, setFilter] = useState({
    filterJobFunction: {},
    filterJobFamily: {},
    filterJobTitle: {},
    filterOrgUnit: {},
    filterOrgLevel: {},
    filterJobLevel: {},
    filterJobGrade: {},
    filterOffice: {},
    filterPosition: {},
  })

  const onConfirmationChange = event => {
    setSelectedConfirmationType(event.target.value)
  }

  const [addPolicy] = useMutation(ADD_OVERTIME_POLICY_WITH_FILTER)

  const handleClickOpen = async type => {
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      const {data: overName} = await client.query({
        query: GET_OVER_POLICY_NAME,
        variables: {name},
      })
      if (overName.time_overtime_policies.length > 0) {
        setIsExist(true)
        setTimeout(() => setIsExist(false), 3000)
      }
      const {isError: isErrorCompensation} = compansationHandler(
        standard,
        moneyCompensation
      )

      if (
        name === '' ||
        (isConvertToMoney && isErrorCompensation) ||
        overName.time_overtime_policies.length > 0 ||
        (isConvertToLeave && !leavePolicyChildren) ||
        (isConvertToLeave && isNaN(minimumHour)) ||
        !selectedConfirmationType ||
        (selectedConfirmationType === '2' && !selectedPosition) ||
        !secondConfirmationState ||
        (secondConfirmationState === '2' && !selectedPositionSecond)
      ) {
        setTabsValue(0)
        const ids = document.getElementById('top')
        ids.scrollIntoView({behavior: 'smooth'})
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else if (!countApplied || countApplied === 0) {
        setTabsValue(1)
        const ids = document.getElementById('top')
        ids.scrollIntoView({behavior: 'smooth'})
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else {
        setConfirmType(type)
        setPopup(true)
      }
    }
  }

  const handleClose = () => {
    setPopup(false)
  }

  const history = () => {
    props.history.push({
      pathname: '/time/overtime/setting',
      state: {searchable: true, active: 'settings'},
    })
  }

  const updatePolicySettings = () => {
    const {compensation} = compansationHandler(standard, moneyCompensation)
    const variables = {
      name: name,
      convert_to_leave: isConvertToLeave,
      minimum_duration_leave: isConvertToLeave ? minimumHour * 60 : 0,
      convert_to_money: compensation,
      confirm_type: selectedConfirmationType,
      first_confirm_profile:
        selectedConfirmationType === '2' ? selectedPosition.value : null,
      second_confirm_profile:
        secondConfirmationState === '2' ? selectedPositionSecond.value : null,
      leave_policy_children: isConvertToLeave ? leavePolicyChildren : null,
      filter: manipulateFilter(filter),
    }

    addPolicy({variables})
      .then(() => {
        enqueueSnackbar('Success Update data', {
          variant: 'success',
          autoHideDuration: 2000,
        })
        history()
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleSearch = async e => {
    const {data} = await client.query({
      query: LIST_JOB_PROFILE_DROPDOWN_FORM,
      variables: {
        search: `%${e}%`,
        limit: 30,
      },
    })
    const result = data
      ? data.company_job_profiles.map(r => {
          return {
            value: r.id,
            label: r.title,
          }
        })
      : []
    return result
  }

  const handleChangeTab = (e, newValue) => {
    e.preventDefault()
    setTabsValue(newValue)
  }

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar} disableGutters>
        <div className={classes.titlePage}>
          <Link
            to={{
              pathname: '/time/overtime/setting',
              state: {searchable: true, active: 'settings'},
            }}
          >
            <IconButton
              edge="end"
              aria-label="back"
              // onClick={handleBack}
            >
              <ArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6" className={classes.title}>
            Add Overtime Policy
          </Typography>
        </div>
        <div className={classes.action}>
          <Button
            size="large"
            className={classes.buttonSpacer}
            onClick={() => handleClickOpen('discard')}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickOpen('add')}
            size="large"
          >
            Save
          </Button>
        </div>
      </Toolbar>
      <Divider />
      <TabsStyled value={tabsValue} onChange={handleChangeTab}>
        <TabStyled label="Detail Policy" />
        <TabStyled label="Assign Position" />
      </TabsStyled>
      <FormContainer className={classes.formContainer} id="top">
        {tabsValue === 0 && (
          <AddDetailTabPolicy
            setName={setName}
            name={name}
            isExist={isExist}
            isError={isError}
            isConvertToLeave={isConvertToLeave}
            isConvertToMoney={isConvertToMoney}
            setConvertToMoney={setConvertToMoney}
            setConvertToLeave={setConvertToLeave}
            moneyCompensation={moneyCompensation}
            setMoneyCompensation={setMoneyCompensation}
            onConfirmationChange={onConfirmationChange}
            selectedConfirmationType={selectedConfirmationType}
            confirmationTypes={confirmationTypes}
            handleSearch={handleSearch}
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
            setSecondConfirmationState={setSecondConfirmationState}
            secondConfirmationState={secondConfirmationState}
            secondConfirmations={secondConfirmations}
            setSelectedPositionSecond={setSelectedPositionSecond}
            selectedPositionSecond={selectedPositionSecond}
            leavePolicyChildren={leavePolicyChildren}
            setLeavePolicyChildren={setLeavePolicyChildren}
            minimumHour={minimumHour}
            setMinimumHour={setMinimumHour}
            standard={standard}
            setStandard={setStandard}
          />
        )}
        {tabsValue === 1 && (
          <AssignPositionTabChild
            variablesQuery={filter}
            setVariablesQuery={setFilter}
            isError={isError}
            setCount={setCountApplied}
          />
        )}
      </FormContainer>
      <Divider />
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={name}
        feature="Overtime Policy"
        mutation={confirmType === 'discard' ? history : updatePolicySettings}
      />
    </Paper>
  )
}

export default withRouter(AddOvertimePolicy)
