import React from 'react'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import {
  AttachmentItemHover,
  WrapAttachment,
} from '../../../../../shared-components/attachment'
import {
  TyphoSection,
  WrapperItemSection,
  LogoImg,
} from '../../DetailEmployeeStyles'
import useEllipsisText from '../../../../../../hooks/useEllipsisText'
import useToggle from '../../../../../../hooks/useToggle'

export const PublicationsItem = ({
  onPreview,
  publication_title,
  authors,
  publication_media,
  date,
  description,
  publication_url,
  attachments,
}) => {
  const _description = description?.length === 0 ? '-' : description
  const {briefText} = useEllipsisText({text: _description})
  const [more, setMore] = useToggle()

  return (
    <WrapperItemSection>
      <LogoImg
        style={{width: 100, height: 100, borderRadius: 5, marginRight: 50}}
        imgProps={{
          style: {width: 'auto', height: '100%', borderRadius: 0},
        }}
      />
      <div className="divider">
        <div className="content">
          <TyphoSection title="true" bold="true">
            {publication_title}
          </TyphoSection>
          <div
            style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}
          >
            {authors &&
              authors.map((author, i) => (
                <TyphoSection
                  style={{marginRight: 4}}
                  key={`${i}-${author}`}
                  blue="true"
                >
                  {author}
                  {authors.length !== i + 1 ? ', ' : ''}
                </TyphoSection>
              ))}
          </div>
          <TyphoSection grey="true">{publication_media}</TyphoSection>
          <TyphoSection style={{color: '#1b1d1f'}}>{date}</TyphoSection>
          <TyphoSection>{more ? _description : briefText}</TyphoSection>

          {more && (
            <>
              <TyphoSection grey="true">
                Publication URL :{' '}
                <span style={{color: '#039be5'}}>{publication_url}</span>
              </TyphoSection>
              <WrapAttachment>
                {attachments &&
                  attachments.map((attachment, i) => (
                    <AttachmentItemHover
                      key={`${i}-itm`}
                      iconAction={
                        <VisibilityOutlinedIcon
                          onClick={() =>
                            onPreview(attachment.url || attachment.link)
                          }
                          className="icon"
                        />
                      }
                      fileName={attachment.name}
                      url={attachment.url || attachment.link}
                    />
                  ))}
              </WrapAttachment>
            </>
          )}
          <TyphoSection
            mt="true"
            style={{color: '#a9a8a8', cursor: 'pointer'}}
            onClick={setMore}
          >
            {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
          </TyphoSection>
        </div>
      </div>
    </WrapperItemSection>
  )
}
