import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {Avatar, Typography, List, Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

export const ReportText = styled(Typography)`
  font-size: ${props => (props.fsize ? props.fsize : '14px')};
  color: ${props => (props.fcolor === 'blue' ? '#014A62' : '#000000')}
  font-weight: ${props => (props.fweight ? props.fweight : 'normal')}
`

const helperStyle = makeStyles(() => ({
  mb15: {
    marginBottom: '15px',
  },
  my20: {
    margin: '20px 0px',
  },
}))

export default helperStyle
