import gql from 'graphql-tag'

export const ADD_MICROLEARNING = gql`
  mutation($microlearning: microlearnings_insert_input!) {
    insert_microlearnings_one(object: $microlearning) {
      id
    }
  }
`

export const ASSIGN_TO_MICROLEARNING = gql`
  mutation(
    $filter: JSON!
    $microlearningId: String!
    $dailyQuestion: Int!
    $schedule: String!
    $scheduleTime: String!
  ) {
    microlearningOrgAssign(
      filter: $filter
      microlearningId: $microlearningId
      dailyQuestion: $dailyQuestion
      schedule: $schedule
      scheduleTime: $scheduleTime
    ) {
      orgAssignId
      success
      __typename
    }
  }
`
