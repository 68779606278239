import React from 'react'
import moment from 'moment'
import MaterialTable from 'material-table'
import {Link} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {convertToRoundedRupiah} from '../../../../utils/helpers'
import {useGetParams} from './ClaimReport'
import {COMPANY_ID} from '../../../../utils/globals'
import {GET_CLAIM_REPORT} from '../../../../graphql/queries/claim/getClaimHistory.query'

import {TableOptions} from '../ClaimPageStyles'
import {CustomTypography} from '../../../../GlobalStyles'
import RequestWrapper from '../../../shared-components/layouts/RequestWrapper'

const optionDate = {
  type: 'year',
  format: 'YYYY-MM-DD',
}

const TABLE_COLUMNS_CONFIG = [
  {
    title: 'Period',
    field: 'date',
    render: rowData => moment(rowData.date).format('MMM YYYY'),
  },
  {
    title: 'Total Claim',
    field: 'value',
    render: rowData => convertToRoundedRupiah(rowData.value, false),
  },
  {
    title: 'Action',
    sorting: false,
    cellStyle: {
      width: '15%',
    },
    render: rowData => (
      <CustomTypography
        fweight="bold"
        component={Link}
        fcolor="lightBlue"
        cursor="pointer"
        style={{textDecoration: 'none'}}
        to={{
          pathname: `/financial/claim/report/detail`,
          state: {active: 'report-detail', searchable: true},
          search: `?date=${rowData.date}`,
        }}
      >
        Detail
      </CustomTypography>
    ),
  },
]

function ClaimReportList() {
  const params = useGetParams()
  const currentYear = new Date().getFullYear()
  const year = params?.year || currentYear

  const dateMoment = moment(year.toString())
  const variables = {
    startDate: dateMoment.startOf(optionDate.type).format(optionDate.format),
    endDate: dateMoment.endOf(optionDate.type).format(optionDate.format),
    company: COMPANY_ID,
  }

  const {data, loading, error} = useQuery(GET_CLAIM_REPORT, {
    skip: !year,
    fetchPolicy: 'cache-and-network',
    variables,
  })

  const claimReport = data?.v_claim_report_by_year

  return (
    <RequestWrapper
      loading={loading}
      isEmpty={!loading && !claimReport?.length}
      error={error}
      emptyMessage="There is no claim report"
    >
      <MaterialTable
        data={data?.v_claim_report_by_year}
        columns={TABLE_COLUMNS_CONFIG}
        options={{...TableOptions, paging: false}}
      />
    </RequestWrapper>
  )
}

export default ClaimReportList
