import React, {useState} from 'react'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {MoreIcon, StyledMenus, StyledMenuItem} from '../../../../GlobalStyles'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {
  DELETE_MULTIPLE_RESPONDENTS_GROUP,
  DUPLICATE_RESPONDENTS_GROUP,
} from '../../../../graphql/mutations/survey/multirater-nps/respondentsGroup.mutation'
import DeletePopup from '../../../shared-components/popup/DeletePopup'

export default function PopupNomor(props) {
  const {selected, setSelected, dataSelected, setDataSelected} = props
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteMutation] = useMutation(DELETE_MULTIPLE_RESPONDENTS_GROUP)
  const [duplicateMutation] = useMutation(DUPLICATE_RESPONDENTS_GROUP)
  const {enqueueSnackbar} = useSnackbar()

  const handleClose = () => {
    setOpenDelete(false)
  }

  const handleClickOpen = () => {
    setOpenDelete(true)
  }

  const handleDelete = () => {
    deleteMutation({
      variables: {
        id: selected,
      },
    })
      .then(() => {
        setSelected([])
        setDataSelected([])
        enqueueSnackbar('Group Deleted', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        setSelected([])
        setDataSelected([])
        enqueueSnackbar('Delete Group Failed, please try again later', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleDuplicate = () => {
    duplicateMutation({
      variables: {
        object: dataSelected,
      },
    })
      .then(() => {
        setSelected([])
        setDataSelected([])
        enqueueSnackbar('Group Duplicated', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        setSelected([])
        setDataSelected([])
        enqueueSnackbar('Duplicated Group failed, please try again later', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  return (
    <>
      <PopupState
        style={{margin: 'auto'}}
        variant="popover"
        popupId="demo-popup-menu"
      >
        {popupState => (
          <>
            <MoreIcon style={{margin: 'auto'}} {...bindTrigger(popupState)}>
              more_horiz
            </MoreIcon>
            <StyledMenus {...bindMenu(popupState)}>
              <StyledMenuItem onClick={handleDuplicate}>
                Duplicate
              </StyledMenuItem>
              <StyledMenuItem onClick={handleClickOpen}>Delete</StyledMenuItem>
            </StyledMenus>
          </>
        )}
      </PopupState>
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        name="this Selected"
        mutation={() => handleDelete()}
      />
    </>
  )
}
