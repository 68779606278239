import React, {useState, useEffect} from 'react'
import {useQuery} from '@apollo/react-hooks'
import {Link} from 'react-router-dom'
import {Avatar, CircularProgress, Button} from '@material-ui/core'
import MaterialTable from 'material-table'
import helperStyle, {
  PayrollContainer,
  TableEmployeeStyled,
  TableOptions,
  CenterWrapper,
  FlexBetween,
  TextDarkBlue,
  TextBlack,
  TextGrey,
  pageSizeOption,
} from '../../PayrollPageStyles'
import {convertToRoundedRupiah} from '../../../../../utils/helpers'
import {GET_EMPLOYEE_SALARY} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import TerminatedChip from '../../../../../components/chip/TerminatedChip'

export default function TableEmployeeSalary(props) {
  const {searchText, filterData} = props

  const classes = helperStyle()
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])

  const {data: employeeSalary, loading, error} = useQuery(GET_EMPLOYEE_SALARY, {
    variables: {
      company: COMPANY_ID,
      organization:
        JSON.stringify(filterData.organization) === '[]'
          ? null
          : filterData.organization,
      position:
        JSON.stringify(filterData.position) === '[]'
          ? null
          : filterData.position,
      job_profile:
        JSON.stringify(filterData.job_profile) === '[]'
          ? null
          : filterData.job_profile,
      rank: JSON.stringify(filterData.rank) === '[]' ? null : filterData.rank,
      office:
        JSON.stringify(filterData.office) === '[]' ? null : filterData.office,
      offset: currentPage * rowsPerPage,
      search: `%${searchText}%`,
      limit: rowsPerPage,
    },
    ...GQL_FINANCE_OPTIONS,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [searchText, rowsPerPage, filterData])

  return (
    <div style={{position: 'relative'}}>
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {employeeSalary && employeeSalary.employees.length === 0 && !loading && (
        <NoDataListComponent
          message={`You can set your employee and customize your payroll method on settings `}
        />
      )}
      {employeeSalary && employeeSalary.employees.length > 0 && (
        <TableEmployeeStyled>
          <MaterialTable
            data={employeeSalary.employees}
            totalCount={employeeSalary.employee_count.aggregate.count}
            page={currentPage}
            onChangePage={page => {
              setCurrentPage(page)
            }}
            onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
            options={{...TableOptions, rowStyle: {}}}
            isLoading={employeeSalary && loading}
            columns={[
              {
                title: 'Avatar',
                headerStyle: {minWidth: 100},
                cellStyle: {minWidth: 100},
                render: rowData => {
                  if (rowData.global_user) {
                    return (
                      <FlexBetween className={classes.rootAvatar}>
                        <Avatar alt="ok" src={rowData.global_user.avatar}>
                          {rowData.global_user.name.slice(0, 1)}
                        </Avatar>
                      </FlexBetween>
                    )
                  }

                  return (
                    <FlexBetween className={classes.rootAvatar}>
                      <Avatar alt="ok" />
                    </FlexBetween>
                  )
                },
              },
              {
                title: 'Name',
                field: 'Name',
                headerStyle: {minWidth: 400},
                cellStyle: {minWidth: 400},
                render: rowData => {
                  if (rowData.global_user) {
                    return (
                      <PayrollContainer>
                        <span>
                          <TextDarkBlue>
                            {rowData.global_user.name}
                          </TextDarkBlue>{' '}
                          <TerminatedChip
                            status={rowData.status}
                            endDate={rowData.end_date}
                          ></TerminatedChip>
                        </span>

                        <TextBlack>{`Employee ID ${rowData.regno ||
                          '-'}`}</TextBlack>

                        <TextGrey>{rowData.company_job_profile.title}</TextGrey>

                        <TextGrey>
                          {
                            rowData.company_job_profile.company_address
                              .global_city.name
                          }
                          ,{' '}
                          {
                            rowData.company_job_profile.company_address
                              .global_province.name
                          }
                        </TextGrey>
                      </PayrollContainer>
                    )
                  } else {
                    return (
                      <PayrollContainer>
                        <TextDarkBlue>-</TextDarkBlue>
                        <TextBlack>{rowData.regno}</TextBlack>
                        <TextGrey>{rowData.company_job_profile.title}</TextGrey>
                      </PayrollContainer>
                    )
                  }
                },
              },
              {
                title: 'Bank Account',
                field: 'rekening',
                headerStyle: {minWidth: 200},
                cellStyle: {minWidth: 200},
                render: rowData => {
                  return (
                    <div>
                      <TextBlack>
                        {rowData?.global_user?.people_work_placements?.[0]
                          ?.people_work_salaries?.[0]?.people_profile_bank
                          ?.account_number || '-'}
                      </TextBlack>
                      <TextBlack>
                        {rowData?.global_user?.people_work_placements?.[0]
                          ?.people_work_salaries?.[0]?.people_profile_bank
                          ?.bank_name || ''}
                      </TextBlack>
                    </div>
                  )
                },
              },
              {
                title: 'KTP & NPWP',
                cellStyle: {minWidth: 250},
                render: rowData => {
                  return (
                    <>
                      <TextBlack>{`KTP ${rowData.global_user
                        ?.people_work_placements?.[0]?.people_work_salaries?.[0]
                        ?.ktp || '-'}`}</TextBlack>

                      <TextBlack>{`NPWP ${rowData.global_user
                        ?.people_work_placements?.[0]?.people_work_salaries?.[0]
                        ?.npwp || '-'}`}</TextBlack>
                    </>
                  )
                },
              },
              {
                title: 'PTKP',
                field: 'ptkp',
                render: rowData => {
                  return rowData?.global_user?.people_work_placements?.[0]
                    ?.people_work_salaries?.[0]?.salary_fields?.PTKP
                },
              },
              {
                title: 'Basic Salary',
                field: 'salary',
                headerStyle: {minWidth: 170},
                cellStyle: {minWidth: 170},
                render: rowData => {
                  return convertToRoundedRupiah(
                    rowData?.global_user?.people_work_placements?.[0]
                      ?.people_work_salaries?.[0]?.value || 0,
                    false
                  )
                },
              },
              {
                title: 'Action',
                field: 'action',
                sorting: false,
                headerStyle: {textAlign: 'center'},
                cellStyle: {
                  textAlign: 'center',
                },
                render: rowData => {
                  return (
                    <div className="action">
                      <Button
                        className="blue"
                        component={Link}
                        to={{
                          pathname: `/financial/payroll/setting/employee-settings/${rowData.id}/edit`,
                          state: {
                            active: 'employee-settings-edit',
                            employeeSettingData: rowData,
                            userId: rowData?.global_user?.id,
                            userWorkId: rowData?.id,
                            tab: 0,
                          },
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  )
                },
              },
            ]}
          />
        </TableEmployeeStyled>
      )}
    </div>
  )
}
