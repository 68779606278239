import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/react-hooks'
import PropTypes from 'prop-types'

import {
  makeStyles,
  Divider,
  FormControlLabel,
  Switch,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  CircularProgress,
  Button,
} from '@material-ui/core'
import {
  GET_ATTENDANCE_ACTIVITY,
  GET_BALOON_STATUS_BUTTON,
} from '../../../../graphql/queries'

import {
  EnhancedToolbar,
  PaperContainer,
  RootContainer,
} from '../../../../GlobalStyles'
import TableNotFound from '../../../shared-components/TableNotFoundComponent'
import ImportBaloon from '../../../../components/importExcel/ImportBaloon'
import ImportPopup from '../../../../components/importExcel/ImportPopup'

import {COMPANY_ID} from '../../../../utils/globals'
import {getAttendanceFilterVariables} from '../filter'
import {CLOSE_BALOON, IMPORT_DATA} from '../../../../graphql/mutations'

const headCells = [
  {
    id: 'nik',
    numeric: false,
    disablePadding: false,
    label: 'Employee ID',
  },
  {id: 'name', numeric: true, disablePadding: false, label: 'Name'},
  {id: 'position', numeric: false, disablePadding: false, label: 'Position'},

  {id: 'action', numeric: true, disablePadding: false, label: 'Action'},
]

function EnhancedTableHead(props) {
  const {classes, order, orderBy, onRequestSort} = props

  const createSortHandler = property => event => {
    event.preventDefault()
    onRequestSort(property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          const cellId = headCell.id
          const active = cellId === orderBy

          return (
            <TableCell
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={active ? order : 'asc'}
              style={{fontWeight: 600}}
            >
              {headCell.id === 'action' ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={active}
                  direction={active ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}

                  {active && (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  )}
                </TableSortLabel>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detail: {
    color: '#007fb2',
    cursor: 'pointer',
  },
  loadingStyle: {
    display: 'block',
    margin: '100px auto',
  },
}))

const getSortQuery = (key, order) => {
  switch (key) {
    case 'id':
      return {id: order}
    case 'nik':
      return {regno: order}
    case 'name':
      return {global_user: {name: order}}
    case 'position':
      return {company_organization: {name: order}}
  }
}

export default function Activity(props) {
  const {searchText, filterData} = props

  const classes = useStyles()

  const [openImportDialog, setOpenImportDialog] = React.useState(false)
  const [isAlreadyImported, setIsAlreadyImported] = React.useState(false)
  const [importFileUrl, setImportFileUrl] = React.useState('')

  const [dense, setDense] = React.useState(false)

  const [limit, setLimit] = React.useState(20)
  const [page, setPage] = React.useState(0)

  const [sortOrder, setSortOrder] = React.useState('asc')
  const [sortKey, setSortKey] = React.useState('id')

  const [dispatchImport] = useMutation(IMPORT_DATA)
  const [dispatchBaloonClose] = useMutation(CLOSE_BALOON)

  const {
    data: importStatus,
    refetch: refetchImportStatus = () => {},
  } = useQuery(GET_BALOON_STATUS_BUTTON, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'people_work_attendance_import',
    },
  })

  const where = React.useMemo(() => {
    return getAttendanceFilterVariables({searchText, filterData})
  }, [searchText, filterData])

  const {data, error, loading} = useQuery(GET_ATTENDANCE_ACTIVITY, {
    wlb_skipPatch: true,
    variables: {
      where: where,
      order: getSortQuery(sortKey, sortOrder),
      limit: limit,
      offset: page * limit,
    },
  })

  const totalData = data?.total.aggregate.count || 0
  const people = data?.people_work_placements || []

  const topImportStatus = importStatus?.company_import_data_logs[0]
  const isImportWaiting = !importStatus || topImportStatus?.status === 'WAITING'

  const handleRequestSort = property => {
    if (sortKey === property) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortOrder('asc')
      setSortKey(property)
    }
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setLimit(+event.target.value)
    setPage(0)
  }

  const handleChangeDense = event => {
    setDense(event.target.checked)
  }

  const handleImportConfirm = () => {
    const promise = dispatchImport({
      variables: {
        table: 'people_work_attendance_import',
        file: importFileUrl,
      },
    })

    promise.then(() => {
      refetchImportStatus()
    })
  }

  const handleOpenImportDialog = () => {
    setOpenImportDialog(true)
  }

  const handleImportDialogClose = () => {
    setOpenImportDialog(false)
  }

  const handleCloseImportBaloon = () => {
    if (topImportStatus) {
      const id = topImportStatus.id
      dispatchBaloonClose({variables: {id}}).then(() => refetchImportStatus())
    }
  }

  return (
    <RootContainer>
      {topImportStatus && (
        <ImportBaloon
          feature="Attendance"
          data={importStatus}
          closeBaloonMutation={handleCloseImportBaloon}
          setImport={setOpenImportDialog}
          mutationCloseBaloon={dispatchBaloonClose}
          refetchBaloonStatus={refetchImportStatus}
        />
      )}

      <PaperContainer>
        <EnhancedToolbar>
          <Typography variant="subtitle1" style={{fontWeight: '600'}}>
            Attendance Activity
          </Typography>

          <Button
            disabled={isImportWaiting}
            onClick={handleOpenImportDialog}
            variant="contained"
            color="primary"
            size="large"
          >
            Import
          </Button>
        </EnhancedToolbar>

        <Divider />

        <div>
          {error ? (
            <div>Something went wrong</div>
          ) : loading ? (
            <CircularProgress className={classes.loadingStyle} />
          ) : (
            <Table className={classes.table} size={dense ? 'small' : 'medium'}>
              <EnhancedTableHead
                classes={classes}
                order={sortOrder}
                orderBy={sortKey}
                onRequestSort={handleRequestSort}
              />
              {people.length > 0 ? (
                <TableBody>
                  {people.map(row => {
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.regno}
                        </TableCell>
                        <TableCell>{row.global_user?.name}</TableCell>
                        <TableCell>{row.company_job_profile?.title}</TableCell>

                        <TableCell>
                          <Link
                            component={RouterLink}
                            color="secondary"
                            to={{
                              pathname: '/time/attendance/detail',
                              state: {active: 'detail', data: row},
                            }}
                          >
                            Details
                          </Link>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              ) : (
                <TableNotFound col={4} />
              )}
            </Table>
          )}
        </div>

        <TablePagination
          component="div"
          rowsPerPageOptions={[20, 50, 100]}
          count={totalData}
          rowsPerPage={limit}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PaperContainer>

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />

      <ImportPopup
        open={openImportDialog}
        position="Attendance"
        handleClose={handleImportDialogClose}
        mutation={handleImportConfirm}
        isAlreadyImported={isAlreadyImported}
        setIsAlreadyImported={setIsAlreadyImported}
        setImport={setOpenImportDialog}
        fileURL={importFileUrl}
        setFileURL={setImportFileUrl}
        closeBaloon={handleCloseImportBaloon}
        errorsImport={topImportStatus?.reports?.failed || []}
      />
    </RootContainer>
  )
}
