import gql from 'graphql-tag'

export const GET_GLOBAL_ICONS = gql`
  query($search: String, $offset: Int, $limit: Int, $category: Int) {
    total: global_icon_directories_aggregate(
      where: {label: {_ilike: $search}, category: {_eq: $category}}
    ) {
      aggregate {
        count
      }
    }

    global_icon_directories(
      where: {label: {_ilike: $search}, category: {_eq: $category}}
      offset: $offset
      limit: $limit
    ) {
      id
      label
      source
      global_icon_category {
        id
        name
      }
    }
  }
`
