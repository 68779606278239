import React from 'react'
import {Alert} from '@material-ui/lab'

const ImportBaloon = function({
  data,
  closeBaloonMutation,
  setImport,
  mutationCloseBaloon,
  refetchBaloonStatus,
  bridge = 'company_import_data_logs',
  feature,
}) {
  return (
    <>
      {data[bridge].length > 0 &&
      data[bridge][0].status !== 'WAITING' &&
      !data[bridge][0].viewed ? (
        <Alert
          color={data[bridge][0].status === 'FAILED' ? 'error' : 'success'}
          severity={data[bridge][0].status === 'FAILED' ? 'error' : 'success'}
          style={{marginBottom: 8}}
          onClose={() => closeBaloonMutation(data[bridge][0].id)}
        >
          {(function() {
            if (data[bridge][0].status === 'WAITING') {
              if (feature === 'goal_library') {
                return (
                  <>
                    Import Data is still in progress. Don&apos;t worry, you can
                    still do other activities
                  </>
                )
              } else {
                return (
                  <>
                    Processing in Background (You may close the browser tab).{' '}
                    <a
                      onClick={() => refetchBaloonStatus()}
                      style={{cursor: 'pointer', textDecoration: 'underline'}}
                    >
                      Reload Import Status.
                    </a>
                  </>
                )
              }
            } else if (data[bridge][0].status === 'FAILED') {
              return (
                <>
                  Import data has failed!{' '}
                  <a
                    onClick={() => setImport(true)}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                  >
                    See the problem
                  </a>
                </>
              )
            } else {
              return 'Import data is completed!'
            }
          })()}
        </Alert>
      ) : data[bridge].length > 0 && data[bridge][0].status === 'WAITING' ? (
        <Alert
          color={'info'}
          severity={'info'}
          style={{marginBottom: 8}}
          onClose={
            !feature &&
            (async () => {
              try {
                await mutationCloseBaloon({
                  variables: {
                    id: data[bridge][0].id,
                  },
                })
              } catch (error) {
                console.error(error)
              }
            })
          }
        >
          {(function() {
            if (feature === 'goal_library') {
              return (
                <>
                  Import Data is still in progress. Don&apos;t worry, you can
                  still do other activities
                </>
              )
            } else {
              return (
                <>
                  Processing in Background (You may close the browser tab).{' '}
                  <a
                    onClick={() => refetchBaloonStatus()}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                  >
                    Reload Import Status.
                  </a>
                </>
              )
            }
          })()}
        </Alert>
      ) : (
        true
      )}
    </>
  )
}

export default ImportBaloon
