import React, {useState, useEffect} from 'react'
import {useQuery} from '@apollo/react-hooks'

import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import RSelect, {components} from 'react-select'

import helperStyle, {TitleName} from '../../../../../../GlobalStyles'
import {
  convertToCurrency,
  currencyToNormal,
} from '../../../../../../utils/helpers'
import {
  FlexBetween,
  ModalHeader,
  StyledDialog,
} from '../../../../component/modalStyles'

import {GET_CURRENCY_EXCHANGE} from '../../../../../../graphql/queries'
import {formatCurrency, getCurrencySign} from '../../../currencyExchanges'

const MenuList = props => {
  return <components.MenuList {...props} />
}

const customStyles = {
  control: base => ({
    ...base,
    height: 50,
    minHeight: 50,
    borderRadius: 5,
    width: '85%',
  }),
  singleValue: css => ({
    ...css,
    minWidth: '100%',
  }),
}

export default function AddBudgetModal(props) {
  const classes = helperStyle()

  const {
    options,
    setDataBudget,
    index = null,
    values,
    data,
    dataBudget,
    type,
    setisUpdateDestination,
    budgetType,
    currency,
  } = props

  const [value, setValues] = useState(values)
  useEffect(() => {
    setValues(values)
  }, [values])

  const label = option => {
    return (
      <div className={classNames(classes.rselect2)}>
        <div
          style={{
            width: '98%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#000',
            fontWeight: 'bold',
            margin: 0,
          }}
        >
          {option.label}
        </div>
        <div
          style={{
            Width: '98%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {option.description}
        </div>
      </div>
    )
  }

  const handleChange = (event, set) => {
    if (set === 'select') {
      setValues({
        ...value,
        select: event,
      })
    } else {
      setValues({
        ...value,
        nominal: currencyToNormal(event.target.value),
      })
    }
  }

  const setBudget = () => {
    const i =
      budgetType === 'spesific'
        ? dataBudget.findIndex(i => i.city === data.city)
        : 0
    let tempArray = null

    if (type === 'add') {
      tempArray = dataBudget.slice()
      if (tempArray[i]?.budget?.length !== undefined) {
        tempArray[i].budget.push({
          type: value.select,
          nominal: value.nominal,
          budget_calculation: value.budget_calculation,
        })
      } else {
        tempArray[i].budget = [
          {
            type: value.select,
            nominal: value.nominal,
            budget_calculation: value.budget_calculation,
          },
        ]
      }
    } else {
      tempArray = dataBudget.slice()
      tempArray[i].budget[index].type = value.select
      tempArray[i].budget[index].nominal = value.nominal
      tempArray[i].budget[index].budget_calculation = value.budget_calculation
    }

    props.handleClose()

    setDataBudget(tempArray)
    setisUpdateDestination(true)
  }

  const indexBudget =
    budgetType === 'spesific'
      ? dataBudget.findIndex(i => i?.city === data?.city)
      : 0

  const datasBudget =
    dataBudget[indexBudget]?.budget?.map(row => row.type.value) || []

  const isDisabled =
    value?.select === undefined ||
    value?.nominal === undefined ||
    value?.budget_calculation === undefined

  return (
    <StyledDialog
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <ModalHeader>
        <FlexBetween>
          <Typography>Add New Budget Component</Typography>

          <IconButton
            onClick={props.handleClose}
            edge="end"
            color="inherit"
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </FlexBetween>
      </ModalHeader>

      <Divider />

      <DialogContent style={{padding: '1.6rem 2rem'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
          <TitleName style={{paddingBottom: '10px'}}>
            Budget Component Name
          </TitleName>
          <RSelect
            placeholder={'Choose Budget Component'}
            components={{MenuList}}
            value={value?.select}
            onChange={event => {
              handleChange(event, 'select')
            }}
            styles={customStyles}
            getOptionValue={option => option.label}
            getOptionLabel={option => label(option)}
            options={
              options &&
              options.business_trip_component
                .filter(filter => !datasBudget.includes(filter.id))
                .map(res => ({
                  label: res.name,
                  value: res.id,
                  description: res.description,
                }))
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            paddingTop: '10px',
          }}
        >
          <TitleName style={{paddingBottom: '10px'}}>
            Budget Component Type
          </TitleName>
          <RadioGroup
            key={1}
            aria-label="option"
            name="option1"
            value={true}
            style={{display: 'flex', flexDirection: 'row'}}
          >
            <FormControlLabel
              control={
                <Radio
                  onChange={() => {
                    setValues({
                      ...value,
                      checkbox: 1,
                      nominal: null,
                    })
                  }}
                  checked={value?.checkbox === 1}
                />
              }
              label="Flexible"
            />

            <FormControlLabel
              control={
                <Radio
                  onChange={() => {
                    setValues({
                      ...value,
                      checkbox: 2,
                      nominal: 0,
                    })
                  }}
                  checked={value?.checkbox === 2}
                />
              }
              label="Strict"
            />
          </RadioGroup>
          <Typography
            style={{marginBottom: 12}}
            color="textSecondary"
            variant="body2"
            display="inline"
          >
            {value?.checkbox === 2
              ? 'Company will determine the budget for the component'
              : 'Employees will determine the budget for this component themselves'}
          </Typography>
        </div>

        {value?.checkbox === 2 && (
          <div
            key={3}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
              paddingTop: '10px',
              paddingBottom: '22px',
            }}
          >
            <TitleName style={{paddingBottom: '10px'}}>Budget Amount</TitleName>
            <TextField
              id="from"
              variant="outlined"
              size="small"
              rows={2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getCurrencySign(currency)}
                  </InputAdornment>
                ),
                inputProps: {min: 0},
              }}
              style={{width: '85%'}}
              value={convertToCurrency(value?.nominal)}
              onChange={event => handleChange(event, 'nominal')}
            />

            {currency !== 'idr' && (
              <InputConversionDisplay
                from={currency}
                to={'idr'}
                value={value?.nominal || 0}
              />
            )}
          </div>
        )}

        <div
          key={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            paddingTop: '10px',
          }}
        >
          <TitleName style={{paddingBottom: '10px'}}>
            Budget Calculation
          </TitleName>
          <RadioGroup style={{display: 'flex', flexDirection: 'row'}}>
            <FormControlLabel
              control={
                <Radio
                  onChange={() => {
                    setValues({
                      ...value,
                      budget_calculation: 'flat',
                    })
                  }}
                  checked={value?.budget_calculation === 'flat'}
                />
              }
              label="Flat"
            />

            <FormControlLabel
              control={
                <Radio
                  onChange={() => {
                    setValues({
                      ...value,
                      budget_calculation: 'multiply_by_days',
                    })
                  }}
                  checked={value?.budget_calculation === 'multiply_by_days'}
                />
              }
              label="Multiply by day(s)"
            />
          </RadioGroup>
          <Typography
            style={{marginBottom: 12}}
            color="textSecondary"
            variant="body2"
            display="inline"
          >
            {value?.budget_calculation === 'flat'
              ? 'Budget will be paid flat without counting trip duration'
              : 'Budget will be multiplied by trip duration'}
          </Typography>
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button variant="text" onClick={props.handleClose}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={isDisabled}
          onClick={setBudget}
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

function InputConversionDisplay({from, to = 'idr', value}) {
  const {data} = useQuery(GET_CURRENCY_EXCHANGE, {
    wlb_skipPatch: true,
    skip: from === to,
    fetchPolicy: 'cache-and-network',
    variables: {
      currency: from,
      date: '',
    },
  })

  const formattedTo = React.useMemo(() => {
    return (
      data &&
      formatCurrency(to, value * Math.round(data.getCurrencyExchange.idr))
    )
  }, [to, value, data])

  return (
    <Typography style={{marginTop: 8}} color="textSecondary" variant="body2">
      {data ? formattedTo : `${getCurrencySign(to)} ⋯`}
    </Typography>
  )
}
