import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  AddEditTitle,
  FormToolbar,
  FormTitleContainer,
} from '../../../../GlobalStyles'
import {
  IconButton,
  makeStyles,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  withStyles,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import StepOne from './add-step/StepOne'
import StepTwo from './add-step/StepTwo'
import StepThree from './add-step/StepThree'
import StepThreeTalent from './add-step/StepThreeTalent'
import StepFour from './add-step/StepFour'
import StepFourTalent from './add-step/StepFourTalent'
import StepFive from './add-step/StepFive'
import {
  ADD_TALENT_CLASSIFICATION,
  EDIT_TALENT_VERSION,
  EDIT_TALENT_BOXES_ALL,
  EDIT_CANDIDATE_GROUP_ALL,
} from '../../../../graphql/mutations'
import {useMutation, useLazyQuery, useApolloClient} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {
  GET_TALENT_CLASS_ID,
  GET_LIST_EMPLOYEE,
} from '../../../../graphql/queries'
import Loading from '../../../../components/loading/LoadingComponent'
// import {omit} from 'lodash'
import {GET_JOB_PROFILE_BY_ID} from '../../../../graphql/queries/company/job-profile/getJobProfileDropdown.query'
import {integerOnly, mapOrder} from '../../../../utils/helpers'
import AddEdit from '../../../shared-components/popup/AddEditPopupFreeText'
import ValidationCommittee from './add-step/detail-component/ValidationCommittee'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },

  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  mb16: {
    marginBottom: '16px !important',
  },
  paper: {
    minHeight: '70vh',
  },
}))

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const ClassificationAddComponent = props => {
  const {id} = props.match.params
  const {path} = props.match
  const {active: feature} = props.location.state

  // CORE OF THE CORE
  const isTalent = path.includes('talent')

  const confirmation_target = isTalent ? 'Talenta' : 'Kinerja'

  const getSteps = () => {
    if (isTalent) {
      return ['Versi', 'Tingkatan', 'Kandidat', 'Komite', 'Pratinjau']
    } else {
      return ['Nama', 'Level', 'Peserta', 'Komite', 'Pratinjau']
    }
  }

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [colorOptions, setColorOptions] = useState([
    {color: '#ef4d5e'},
    {color: '#a9a8a8'},
    {color: '#ffa000'},
    {color: '#007fb2'},
    {color: '#4caf50'},
    {color: '#a35a31'},
    {color: '#874caf'},
    {color: '#f04ba0'},
  ])
  const [confirmType, setConfirmType] = useState('')

  const [values, setValues] = useState({
    name: '',
    start_date: null,
    end_date: null,
    status: 'DRAFT',
    filter_position: {},
    indicator_pot_attendance: false,
    indicator_pot_competence: false,
    indicator_pot_total_course: false,
    indicator_pot_socmed_post: false,
    indicator_pot_experience: false,
    indicator_perf_nps: false,
    indicator_perf_ogf: false,
    indicator_perf_multirater: false,
    indicator_perf_goal: false,
    box_width: '',
    box_height: '',
    x_name: '',
    y_name: '',
    min_pot: '',
    max_pot: '',
    min_perf: '',
    max_perf: '',
    indicator_pot_attendance_fields: {
      start_date: new Date(),
      end_date: new Date(),
      weight: 0,
    },
    indicator_pot_competence_fields: {
      version: '',
      weight: 0,
    },
    indicator_pot_total_course_fields: {
      start_date: new Date(),
      end_date: new Date(),
      weight: 0,
    },
    indicator_pot_socmed_post_fields: {
      start_date: new Date(),
      end_date: new Date(),
      weight: 0,
    },
    indicator_perf_nps_fields: {
      start_date: new Date(),
      end_date: new Date(),
      weight: 0,
    },
    indicator_perf_ogf_fields: {
      start_date: new Date(),
      end_date: new Date(),
      weight: 0,
    },
    indicator_perf_multirater_fields: {
      start_date: new Date(),
      end_date: new Date(),
      weight: 0,
    },
    indicator_perf_goal_fields: {
      start_date: new Date(),
      end_date: new Date(),
      weight: 0,
    },
    talent_boxes: {data: []},
    talent_candidate_groups: {
      data: [],
    },
  })
  const [isLoading, setIsLoading] = useState(false)
  const [radioVal, setRadioVal] = useState('custom')

  const {
    name,
    start_date,
    end_date,
    status,
    filter_position,
    // indicator_pot_attendance,
    // indicator_pot_competence,
    // indicator_pot_total_course,
    // indicator_pot_experience,
    // indicator_pot_socmed_post,
    // indicator_perf_nps,
    // indicator_perf_ogf,
    // indicator_perf_multirater,
    // indicator_perf_goal,
    box_width,
    box_height,
    x_name,
    y_name,
    // indicator_pot_attendance_fields,
    // indicator_pot_competence_fields,
    // indicator_pot_total_course_fields,
    // indicator_pot_socmed_post_fields,
    // indicator_perf_nps_fields,
    // indicator_perf_ogf_fields,
    // indicator_perf_multirater_fields,
    // indicator_perf_goal_fields,
    min_pot,
    max_pot,
    min_perf,
    max_perf,
    talent_boxes,
    talent_candidate_groups,
  } = values

  const stepOneData = [
    {
      id: 4,
      title: 'Sumbu X',
      axis: values.x_name,
      nameAxis: 'x_name',
      min: values.min_pot,
      nameMin: 'min_pot',
      nameMax: 'max_pot',
      max: values.max_pot,
      value: isTalent
        ? [
            {
              title: 'Competency',
              nameCheck: 'indicator_pot_competence',
              valueCheck: values.indicator_pot_competence,
              nameParent: 'indicator_pot_competence_fields',
              indicators: [
                values.indicator_pot_competence
                  ? values.indicator_pot_competence_fields.weight
                  : null,
                values.indicator_pot_competence
                  ? values.indicator_pot_competence_fields.version
                  : null,
              ],
            },
            {
              title: 'Attendance',
              nameCheck: 'indicator_pot_attendance',
              valueCheck: values.indicator_pot_attendance,
              nameParent: 'indicator_pot_attendance_fields',
              indicators: [
                values.indicator_pot_attendance
                  ? values.indicator_pot_attendance_fields.weight
                  : null,
                values.indicator_pot_attendance
                  ? values.indicator_pot_attendance_fields.start_date
                  : null,
                values.indicator_pot_attendance
                  ? values.indicator_pot_attendance_fields.end_date
                  : null,
              ],
            },
            // {
            //   title: 'Total Course',
            //   nameCheck: 'indicator_pot_total_course',
            //   valueCheck: values.indicator_pot_total_course,
            //   nameParent: 'indicator_pot_total_course_fields',
            //   indicators: [
            //     values.indicator_pot_total_course
            //       ? values.indicator_pot_total_course_fields.weight
            //       : null,
            //     values.indicator_pot_total_course
            //       ? values.indicator_pot_total_course_fields.start_date
            //       : null,
            //     values.indicator_pot_total_course
            //       ? values.indicator_pot_total_course_fields.end_date
            //       : null,
            //   ],
            // },
            // {
            //   title: 'Social Media Post',
            //   nameCheck: 'indicator_pot_socmed_post',
            //   valueCheck: values.indicator_pot_socmed_post,
            //   nameParent: 'indicator_pot_socmed_post_fields',
            //   indicators: [
            //     values.indicator_pot_socmed_post
            //       ? values.indicator_pot_socmed_post_fields.weight
            //       : null,
            //     values.indicator_pot_socmed_post
            //       ? values.indicator_pot_socmed_post_fields.start_date
            //       : null,
            //     values.indicator_pot_socmed_post
            //       ? values.indicator_pot_socmed_post_fields.end_date
            //       : null,
            //   ],
            // },
          ]
        : [],
    },
    {
      id: 5,
      title: 'Sumbu Y',
      axis: values.y_name,
      nameAxis: 'y_name',
      min: values.min_perf,
      nameMin: 'min_perf',
      nameMax: 'max_perf',
      max: values.max_perf,
      value: [
        {
          title: 'Task',
          nameCheck: 'indicator_perf_goal',
          valueCheck: values.indicator_perf_goal,
          nameParent: 'indicator_perf_goal_fields',
          indicators: [
            values.indicator_perf_goal
              ? values.indicator_perf_goal_fields.weight
              : null,
            values.indicator_perf_goal
              ? values.indicator_perf_goal_fields.start_date
              : null,
            values.indicator_perf_goal
              ? values.indicator_perf_goal_fields.end_date
              : null,
          ],
        },
        {
          title: 'Ongoing Feedback',
          nameCheck: 'indicator_perf_ogf',
          valueCheck: values.indicator_perf_ogf,
          nameParent: 'indicator_perf_ogf_fields',
          indicators: [
            values.indicator_perf_ogf
              ? values.indicator_perf_ogf_fields.weight
              : null,
            values.indicator_perf_ogf
              ? values.indicator_perf_ogf_fields.start_date
              : null,
            values.indicator_perf_ogf
              ? values.indicator_perf_ogf_fields.end_date
              : null,
          ],
        },
        {
          title: 'Multirater',
          nameCheck: 'indicator_perf_multirater',
          valueCheck: values.indicator_perf_multirater,
          nameParent: 'indicator_perf_multirater_fields',
          indicators: [
            values.indicator_perf_multirater
              ? values.indicator_perf_multirater_fields.weight
              : null,
            values.indicator_perf_multirater
              ? values.indicator_perf_multirater_fields.start_date
              : null,
            values.indicator_perf_multirater
              ? values.indicator_perf_multirater_fields.end_date
              : null,
          ],
        },
        {
          title: 'NPS',
          nameCheck: 'indicator_perf_nps',
          valueCheck: values.indicator_perf_nps,
          nameParent: 'indicator_perf_nps_fields',
          indicators: [
            values.indicator_perf_nps
              ? values.indicator_perf_nps_fields.weight
              : null,
            values.indicator_perf_nps
              ? values.indicator_perf_nps_fields.start_date
              : null,
            values.indicator_perf_nps
              ? values.indicator_perf_nps_fields.end_date
              : null,
          ],
        },
      ],
    },
  ]

  const selectedParameter = axis =>
    stepOneData[0].value
      .concat(stepOneData[1].value)
      .filter(f => f.valueCheck && axisValue[axis].some(s => s === f.nameCheck))

  const totalWeight = title => {
    let total = 0
    for (let t = 0; t < selectedParameter(title).length; t++) {
      total += parseInt(selectedParameter(title)[t].indicators[0])
    }
    return total
  }

  const [isError, setIsError] = useState(false)
  const [idCandidate, setIdCandidate] = useState([])
  const [candidate, setCandidate] = useState([])
  const [employee, setEmployee] = useState([])
  // const [dataEmp, setDataEmp] = useState([])
  const [axisValue, setAxisValue] = useState({
    'Sumbu X': [],
    'Sumbu Y': [],
  })
  // is dynamic weight
  const [isDynamic, setIsDynamic] = useState(true)
  const [openValidCommitee, setOpenValidCommitee] = useState({
    open: false,
    dataValidation: null,
  })

  const [loadsJP] = useLazyQuery(GET_JOB_PROFILE_BY_ID, {
    variables: {id: idCandidate},
    onCompleted: dataJobProfile => {
      var item_array, item_order
      item_array = dataJobProfile.company_job_profiles
      item_order = idCandidate
      mapOrder(item_array, item_order, 'id')
      setCandidate(
        dataJobProfile.company_job_profiles.map(r => {
          return {value: r.id, label: r.title, code: r.code}
        })
      )
    },
  })

  const [loads, {loading, data}] = useLazyQuery(GET_TALENT_CLASS_ID, {
    variables: {
      version: id,
    },
    onCompleted: temps => {
      const tempIdCandidate = temps.talent_versions[0].talent_candidate_groups.map(
        r => {
          return r.job_profile
        }
      )
      setIdCandidate(tempIdCandidate)
      loadsJP()
    },
    fetchPolicy: 'cache-and-network',
  })

  const setEmp = async () => {
    const {data: emps} = await client.query({
      query: GET_LIST_EMPLOYEE,
    })
    // setDataEmp(emps)
    if (id && data) {
      const tempEmp = data.talent_versions[0].talent_candidate_groups.map(x => {
        return x.talent_committees.map(z => {
          const index = emps.people_work_placements.findIndex(
            i => i.global_user.id === z.committee
          )
          const global = emps?.people_work_placements?.[index]?.global_user
          const position =
            emps?.people_work_placements?.[index]?.company_job_profile

          return {
            label: z.committee,
            job_profile: x.job_profile,
            value: global?.name,
            avatar: global?.avatar,
            position: position?.title,
          }
        })
      })
      setEmployee(tempEmp)
    }
  }

  useEffect(() => {
    setEmp()
    if (id) {
      loads()
      if (data) {
        const {
          name,
          start_date,
          end_date,
          filter_position,
          status,
          box_width,
          box_height,
          flexy_components,
          talent_boxes,
          talent_candidate_groups,
        } = data.talent_versions[0]

        const tempValues = {
          ...values,
          name,
          start_date,
          end_date,
          filter_position,
          box_height,
          box_width,
          status,
          talent_boxes: {
            data: talent_boxes,
          },
          talent_candidate_groups: {
            data: talent_candidate_groups.map(r => ({
              ...r,
              talent_committees: {
                data: r.talent_committees,
              },
            })),
          },
          x_name: flexy_components && flexy_components.x.name,
          y_name: flexy_components && flexy_components.y.name,
          min_pot: flexy_components && flexy_components.x.min,
          max_pot: flexy_components && flexy_components.x.max,
          min_perf: flexy_components && flexy_components.y.min,
          max_perf: flexy_components && flexy_components.y.max,
        }

        const xAxisValue = []
        const yAxisValue = []

        const switchFlexyComponents = (data, axis) => {
          let indicatorState
          switch (data.parameter) {
            case 'competency':
              indicatorState = 'indicator_pot_competence'
              break
            case 'attendance':
              indicatorState = 'indicator_pot_attendance'
              break
            case 'course':
              indicatorState = 'indicator_pot_total_course'
              break
            case 'socmed_post':
              indicatorState = 'indicator_pot_socmed_post'
              break
            case 'task':
              indicatorState = 'indicator_perf_goal'
              break
            case 'ogf':
              indicatorState = 'indicator_perf_ogf'
              break
            case 'multirater':
              indicatorState = 'indicator_perf_multirater'
              break
            case 'nps':
              indicatorState = 'indicator_perf_nps'
              break
          }
          if (axis === 'x') {
            xAxisValue.push(indicatorState)
          } else {
            yAxisValue.push(indicatorState)
          }

          tempValues[indicatorState] = true
          if (data.parameter === 'competency') {
            tempValues[indicatorState + '_fields'] = {
              version: data.version,
              weight: data.weight,
            }
          } else {
            tempValues[indicatorState + '_fields'] = {
              start_date: data.start_date,
              end_date: data.end_date,
              weight: data.weight,
            }
          }
        }
        if (flexy_components) {
          setIsDynamic(flexy_components.is_dynamic)
          flexy_components.x.parameters.forEach(axis =>
            switchFlexyComponents(axis, 'x')
          )
          flexy_components.y.parameters.forEach(axis =>
            switchFlexyComponents(axis, 'y')
          )
        }

        setAxisValue({
          'Sumbu X': xAxisValue,
          'Sumbu Y': yAxisValue,
        })
        setValues(tempValues)

        // const res = data.talent_versions[0]
        // const temp = {...values}
        // for (var key in res) {
        //   if (key === 'talent_boxes') {
        //     temp[key] = {data: res[key]}
        //   } else if (key === 'talent_candidate_groups') {
        //     temp[key] = {
        //       data: res[key].map(r => {
        //         return {
        //           ...r,
        //           talent_committees: {data: r.talent_committees},
        //         }
        //       }),
        //     }
        //   } else {
        //     temp[key] = res[key]
        //   }
        // }
        // const temps = omit(temp, ['__typename'])
        // setValues(temps)
      }
    }
  }, [loads, id, data])

  useEffect(() => {
    if (isDynamic) {
      mapsDynamicWeight()
    }
  }, [isDynamic, axisValue])

  // which axis this indicator currently in, and count the weight
  const countDynamicWeight = (indicator, _isDynamic) => {
    // _isDynamic (Boolean), when clicked from `+ add weight`
    let currentAxis
    let weight

    Object.keys(axisValue).forEach(key => {
      if (axisValue[key].some(res => res === indicator)) {
        currentAxis = key
      }
    })

    // eslint-disable-next-line prefer-const
    weight = currentAxis ? 100 / axisValue[currentAxis]?.length : 0

    return _isDynamic ? Math.round(weight) : weight
  }

  // map countDynamicWeight to state
  const mapsDynamicWeight = _isDynamic => {
    const newValues = {}
    Object.keys(axisValue).forEach(key => {
      axisValue[key].forEach(
        name =>
          (newValues[name + '_fields'] = {
            ...values[name + '_fields'],
            weight: countDynamicWeight(name, _isDynamic),
          })
      )
    })

    setValues({...values, ...newValues})
  }

  const handleChange = (name, axis) => event => {
    if (axis) {
      let newAxisValue = {}
      if (event.target.checked) {
        newAxisValue = {
          ...axisValue,
          [axis]: [...axisValue[axis], name],
        }
      } else {
        newAxisValue = {
          ...axisValue,
          [axis]: axisValue[axis].filter(x => x !== name),
        }
      }
      setAxisValue(newAxisValue)
    }
    let inputs = {}
    if (name.includes('indicator')) {
      inputs = {
        ...values,
        [name]: event.target.checked,
        [name + '_fields']: {
          ...values[name + '_fields'],
          weight: 0,
        },
      }
    }
    // else if (
    //   name === 'min_pot' ||
    //   name === 'max_pot' ||
    //   name === 'min_perf' ||
    //   name === 'max_perf'
    // ) {
    //   if (event.target.value < 0 || event.target.value > 100) {
    //     inputs = {...values, [name]: event.target.value < 0 ? 0 : 100}
    //   } else inputs = {...values, [name]: integerOnly(event.target.value)}
    // }
    else {
      inputs = {
        ...values,
        [name]: event.target.value,
      }
    }
    setValues(inputs)
  }

  const handleChangeIndicator = (parent, indicator) => event => {
    const _value = event.target.value
    let inputs = {}
    if (indicator === 'weight') {
      if (_value < 0 || _value > 100) {
        inputs = _value < 0 ? 0 : 100
      } else inputs = integerOnly(_value)
    } else {
      inputs = _value
    }
    setValues({
      ...values,
      [parent]: {
        ...values[parent],
        [indicator]: inputs,
      },
    })
  }

  const undefinedChecker = arr => arr.some(v => !v.badge)
  const stepFourChecker = arr =>
    arr.map(
      v =>
        v.talent_committees &&
        v.talent_committees.data.some(
          x => x.committee !== undefined && x.is_pic
        )
    )

  const xCheck = x =>
    (isTalent && !x.committee_email_notif_template) ||
    moment(x.committee_date_start).isAfter(moment(x.committee_date_end)) ||
    moment(values.start_date).isAfter(moment(x.committee_date_start)) ||
    moment(x.committee_date_end).isAfter(moment(values.end_date)) ||
    !x.committee_date_start ||
    !x.committee_date_end ||
    x.committee_date_start === 'Invalid date' ||
    x.committee_date_end === 'Invalid date'

  const [mutationAddClassification] = useMutation(ADD_TALENT_CLASSIFICATION)
  const [mutationUpdateVersion] = useMutation(EDIT_TALENT_VERSION)
  const [mutationUpdateBoxes] = useMutation(EDIT_TALENT_BOXES_ALL)
  const [mutationUpdateCandidate] = useMutation(EDIT_CANDIDATE_GROUP_ALL)

  const steps = getSteps()

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <StepOne
            values={values}
            setValues={setValues}
            handleChange={handleChange}
            handleChangeIndicator={handleChangeIndicator}
            isError={isError}
            axisValue={axisValue}
            indicatorTextfield={stepOneData}
            selectedParameter={selectedParameter}
            totalWeight={totalWeight}
            isTalent={isTalent}
            isDynamic={isDynamic}
            setIsDynamic={setIsDynamic}
            mapsDynamicWeight={mapsDynamicWeight}
          />
        )
      case 1:
        return (
          <StepTwo
            setMainValues={setValues}
            mainValues={values}
            isError={isError}
            undefinedChecker={undefinedChecker}
            colorOptions={colorOptions}
            setColorOptions={setColorOptions}
            // mutationUpdateBoxes={mutationUpdateBoxes}
            isTalent={isTalent}
            setIsError={setIsError}
            status={feature}
          />
        )
      case 2:
        return isTalent ? (
          <StepThreeTalent
            values={values}
            setValues={setValues}
            candidate={candidate}
            setCandidate={setCandidate}
            isError={isError}
            employee={employee}
            setEmployee={setEmployee}
          />
        ) : (
          <StepThree
            values={values}
            setValues={setValues}
            candidate={candidate}
            setCandidate={setCandidate}
            isError={isError}
            employee={employee}
            setEmployee={setEmployee}
            isTalent={isTalent}
            radioVal={radioVal}
            setRadioVal={setRadioVal}
            status={feature}
          />
        )

      case 3:
        return isTalent ? (
          <StepFourTalent
            values={values}
            setValues={setValues}
            candidate={candidate}
            employee={employee}
            setEmployee={setEmployee}
            isError={isError}
            xCheck={xCheck}
            // data={dataEmp}
            isTalent={isTalent}
          />
        ) : (
          <StepFour
            values={values}
            setValues={setValues}
            candidate={candidate}
            employee={employee}
            setEmployee={setEmployee}
            isError={isError}
            xCheck={xCheck}
            // data={dataEmp}
            isTalent={isTalent}
          />
        )
      case 4:
        return (
          <StepFive
            mainValues={values}
            candidate={candidate}
            employee={employee}
            flexy={variableData.flexy_components}
            isTalent={isTalent}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  const setParameters = title => {
    const param = []
    selectedParameter(title).forEach(res => {
      const parameter = res.title.toLowerCase()
      let obj = {
        parameter:
          parameter === 'total course'
            ? 'course'
            : parameter === 'social media post'
            ? 'socmed_post'
            : parameter === 'ongoing feedback'
            ? 'ogf'
            : parameter,
        weight: res?.indicators?.[0]?.toString() ?? '0',
      }
      if (parameter === 'competency') {
        obj = {
          ...obj,
          version: res.indicators[1],
        }
      } else {
        obj = {
          ...obj,
          start_date: moment(res.indicators[1]).format('YYYY-MM-DD'),
          end_date: moment(res.indicators[2]).format('YYYY-MM-DD'),
        }
      }
      param.push(obj)
    })
    return param
  }

  const variableData = {
    name,
    start_date,
    end_date,
    status,
    filter_position,
    box_width,
    box_height,
    talent_boxes,
    talent_candidate_groups,
    flexy_components: {
      is_dynamic: isDynamic,
      x: {
        name: x_name,
        min: min_pot?.toString() ?? '0',
        max: max_pot?.toString() ?? '0',
        parameters: setParameters('Sumbu X'),
      },
      y: {
        name: y_name,
        min: min_perf?.toString() ?? '0',
        max: max_perf?.toString() ?? '0',
        parameters: setParameters('Sumbu Y'),
      },
    },
    classification_type: isTalent ? 'TALENT' : 'PERFORMANCE',
  }

  const handleMutation = () => {
    setIsLoading(true)
    if (id) {
      mutationUpdateVersion({
        variables: {
          version_id: id,
          data: {
            name,
            start_date,
            end_date,
            status,
            box_width,
            box_height,
            flexy_components: variableData.flexy_components,
            filter_position,
          },
        },
      })
        .then(() => {
          mutationUpdateBoxes({
            variables: {
              version: id,
              box: values.talent_boxes.data.map(res => {
                delete res.id
                delete res.__typename
                return {
                  ...res,
                  version: id,
                }
              }),
            },
          }).then(() => {
            mutationUpdateCandidate({
              variables: {
                version: id,
                group: values.talent_candidate_groups.data.map(canGroup => {
                  delete canGroup.__typename
                  return {
                    version: id,
                    talent_committees: {
                      data: canGroup.talent_committees.data.map(res => {
                        delete res.id
                        delete res.__typename
                      }),
                    },
                    ...canGroup,
                  }
                }),
              },
            })
              .then(() => {
                enqueueSnackbar('Success edit data', {
                  variant: 'success',
                  autoHideDuration: 1500,
                })
                props.history.goBack()
              })
              .catch(e =>
                enqueueSnackbar(JSON.stringify(e), {
                  variant: 'error',
                })
              )
          })
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
            autoHideDuration: 7500,
          })
        })
    } else {
      mutationAddClassification({
        variables: {
          data: variableData,
        },
      })
        .then(() => {
          enqueueSnackbar('Success Add data', {
            variant: 'success',
            autoHideDuration: 1500,
          })
          props.history.goBack()
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        })
    }
  }

  const handleConfirm = type => {
    setOpen(true)
    setConfirmType(type)
  }

  const handleNext = () => {
    const limitFeatureName = isTalent ? 50 : 250
    if (activeStep === steps.length - 1) {
      // setOpen(true)
      handleConfirm(id ? 'edit' : 'add')
    } else {
      if (
        (activeStep === 0 &&
          // presentaseValidation('potential') ||
          // presentaseValidation('performance') ||
          (!values.name ||
            values.name.length > limitFeatureName ||
            !values.status ||
            !values.start_date ||
            !values.end_date ||
            values.start_date === 'Invalid date' ||
            values.end_date === 'Invalid date' ||
            values.min_pot === '' ||
            values.min_perf === '' ||
            values.max_pot === '' ||
            values.max_perf === '' ||
            parseInt(values.min_perf) > parseInt(values.max_perf) ||
            parseInt(values.min_pot) > parseInt(values.max_pot) ||
            values.x_name === '' ||
            values.x_name.length > 50 ||
            values.y_name === '' ||
            values.y_name.length > 50 ||
            axisValue['Sumbu X'].length === 0 ||
            axisValue['Sumbu Y'].length === 0 ||
            (!isDynamic && totalWeight('Sumbu X') !== 100) ||
            (!isDynamic && totalWeight('Sumbu Y') !== 100) ||
            (values.indicator_pot_attendance &&
              (values.indicator_pot_attendance_fields.weight === '' ||
                !values.indicator_pot_attendance_fields.start_date ||
                !values.indicator_pot_attendance_fields.end_date)) ||
            (values.indicator_pot_competence &&
              (values.indicator_pot_competence_fields.weight === '' ||
                !values.indicator_pot_competence_fields.version)) ||
            (values.indicator_pot_total_course &&
              (values.indicator_pot_total_course_fields.weight === '' ||
                !values.indicator_pot_total_course_fields.start_date ||
                !values.indicator_pot_total_course_fields.end_date)) ||
            (values.indicator_pot_socmed_post &&
              (values.indicator_pot_socmed_post_fields.weight === '' ||
                !values.indicator_pot_socmed_post_fields.start_date ||
                !values.indicator_pot_socmed_post_fields.end_date)) ||
            (values.indicator_perf_nps &&
              (values.indicator_perf_nps_fields.weight === '' ||
                !values.indicator_perf_nps_fields.start_date ||
                !values.indicator_perf_nps_fields.end_date)) ||
            (values.indicator_perf_ogf &&
              (values.indicator_perf_ogf_fields.weight === '' ||
                !values.indicator_perf_ogf_fields.start_date ||
                !values.indicator_perf_ogf_fields.end_date)) ||
            (values.indicator_perf_multirater &&
              (values.indicator_perf_multirater_fields.weight === '' ||
                !values.indicator_perf_multirater_fields.start_date ||
                !values.indicator_perf_multirater_fields.end_date)) ||
            (values.indicator_perf_goal &&
              (values.indicator_perf_goal_fields.weight === '' ||
                !values.indicator_perf_goal_fields.start_date ||
                !values.indicator_perf_goal_fields.end_date)))) ||
        (activeStep === 1 &&
          (!values.box_width ||
            !values.box_height ||
            undefinedChecker(values.talent_boxes.data))) ||
        (activeStep === 2 &&
          values.talent_candidate_groups.data.length === 0) ||
        (activeStep === 3 &&
          (stepFourChecker(values.talent_candidate_groups.data).some(x => !x) ||
            values.talent_candidate_groups.data.some(x => xCheck(x))))
      ) {
        setIsError(true)
        setTimeout(() => setIsError(false), 5000)
        if (activeStep === 3 && !isTalent) {
          const tempValidation = []
          const checkStepFour = stepFourChecker(
            values.talent_candidate_groups.data
          ).map(x => !x)
          values.talent_candidate_groups.data.map((x, key) => {
            if (xCheck(x) || checkStepFour[key]) {
              const find = candidate?.filter(
                _find => _find.value === x.job_profile
              )
              const notValid = {
                row: key + 1,
                title: find?.[0]?.label,
              }
              tempValidation.push(notValid)
            }
            setOpenValidCommitee({
              open: true,
              dataValidation: tempValidation,
            })
          })
        }
        activeStep === 1 &&
          (!values.box_width ||
            !values.box_height ||
            undefinedChecker(values.talent_boxes.data)) &&
          enqueueSnackbar('Please generate file first.', {
            variant: 'error',
            autoHideDuration: 1000,
          })
      } else {
        // logic edit kalo lolos validasi
        // if (id) {
        //   if (activeStep === 1) {
        //     mutationUpdateVersion({
        //       variables: {
        //         version_id: id,
        //         data: {
        //           name,
        //           start_date,
        //           end_date,
        //           status,
        //           box_width,
        //           box_height,
        //           flexy_components: variableData.flexy_components,
        //         },
        //       },
        //     })
        //       .then(() => {
        //         enqueueSnackbar('Success Update data', {
        //           variant: 'success',
        //           autoHideDuration: 1000,
        //         })
        //         setActiveStep(prevActiveStep => prevActiveStep + 1)
        //       })
        //       .catch(e => {
        //         enqueueSnackbar(JSON.stringify(e), {
        //           variant: 'error',
        //           autoHideDuration: 7500,
        //         })
        //       })
        //   } else {
        //     setActiveStep(prevActiveStep => prevActiveStep + 1)
        //   }
        // } else {
        // else add
        setActiveStep(prevActiveStep => prevActiveStep + 1)
        // }
      }
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <PaperContainer className={classes.paper}>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          {!isLoading && (
            <IconButton
              edge="end"
              aria-label="back"
              onClick={() => handleConfirm('discard')}
            >
              <ArrowBack />
            </IconButton>
          )}

          {/* </Link> */}
          <AddEditTitle variant="h6">
            {id ? 'Ubah ' : 'Tambah '}
            {isTalent ? 'Klasifikasi Talenta' : 'Evaluasi Kinerja Pegawai'}
          </AddEditTitle>
        </FormTitleContainer>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <div>
            {activeStep === 0 ? (
              <Button
                className={classes.backButton}
                onClick={() => handleConfirm('discard')}
              >
                Batal
              </Button>
            ) : (
              <Button onClick={handleBack} className={classes.backButton}>
                Sebelumnya
              </Button>
            )}

            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? 'Kirimkan' : 'Selanjutnya'}
            </Button>
          </div>
        )}
      </FormToolbar>
      <Divider className={classes.mb16} />
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomConnector />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel
              classes={{
                active: classes.labelActive,
                completed: classes.labelActive,
              }}
              StepIconProps={{
                classes: {
                  root: classes.iconRoot,
                  text: classes.iconText,
                  active: classes.iconActive,
                  completed: classes.iconActive,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div style={{padding: '2rem'}}>{getStepContent(activeStep)}</div>
      <AddEdit
        open={open}
        handleCancel={() => setOpen(false)}
        handleClose={() => setOpen(false)}
        mutation={
          confirmType === 'discard' ? props.history.goBack : handleMutation
        }
        header={confirmation.header?.[confirmType]?.replace(
          '[t]',
          confirmation_target
        )}
        body={confirmation.body?.[confirmType]?.replace(
          '[t]',
          confirmation_target
        )}
        button={confirmation.button?.[confirmType]}
        buttonClose={confirmation.buttonClose?.[confirmType]}
      />
      {activeStep === 3 && (
        <ValidationCommittee
          open={openValidCommitee.open}
          dataValidation={openValidCommitee.dataValidation}
          handleClose={() =>
            setOpenValidCommitee({
              open: false,
              dataValidation: null,
            })
          }
        />
      )}
    </PaperContainer>
  )
}

export default withRouter(ClassificationAddComponent)

const confirmation = {
  header: {
    add: 'Tambah Evaluasi [t] Pegawai?',
    edit: 'Ubah Evaluasi [t] Pegawai?',
    discard: 'Discard Changes?',
  },
  body: {
    add: 'Apakah anda yakin ingin menambahkan Evaluasi [t] Pegawai ini?',
    edit: 'Apakah anda yakin ingin mengubah Evaluasi [t] Pegawai ini?',
    discard: 'Are you sure you want to discard unsaved changes?',
  },
  button: {add: 'Tambah', edit: 'Ubah', discard: 'Discard'},
  buttonClose: {add: 'Batalkan', edit: 'Batalkan', discard: 'Cancel'},
}
