import React from 'react'

import moment from 'moment'

import {
  makeStyles,
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  inputStyle: {
    padding: '15px 18px',
  },
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
    marginBottom: 17,
  },
  root: {
    marginBottom: 32,
  },
  dateWrapper: {
    width: '32%',
  },
}))

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: '#aaa',
  },
}))

const Placeholder = ({children}) => {
  const classes = usePlaceholderStyles()
  return <div className={classes.placeholder}>{children}</div>
}

const DEFAULT_FILTER = ['date']

const MONTH_OPTIONS = [
  {value: '01', label: 'Januari'},
  {value: '02', label: 'Februari'},
  {value: '03', label: 'Maret'},
  {value: '04', label: 'April'},
  {value: '05', label: 'Mei'},
  {value: '06', label: 'Juni'},
  {value: '07', label: 'Juli'},
  {value: '08', label: 'Agustus'},
  {value: '09', label: 'September'},
  {value: '10', label: 'Oktober'},
  {value: '11', label: 'November'},
  {value: '12', label: 'Desember'},
]

const pad = value => (value ? value.padStart(2, '0') : null)

export const DateForCustom = props => {
  const {
    label,
    filter = DEFAULT_FILTER,
    disabled,
    value,
    onChange,
    error,
    helperText,
  } = props

  const classes = useStyles()

  const date = React.useMemo(() => {
    const current = new Date()

    const val = !value || value === 'Invalid date' ? '' : value
    const [date, time = ''] = val.split(/\s+|T|Z/)

    const [year, month, day] = date.split('-')
    const [hour, minute] = time.split(':')

    return {
      day: pad(day),
      month: pad(month),
      year: pad(year),

      hour: pad(hour),
      minute: pad(minute),

      currentYear: current.getFullYear(),
    }
  }, [value])

  const handleChange = ev => {
    const target = ev.target

    const field = target.name
    const fieldValue = target.value

    const o = {...date, [field]: fieldValue}
    const dt = `${o.year || o.currentYear}-${o.month || '01'}-${o.day ||
      '01'}T${o.hour || '00'}:${o.minute || '00'}:00`

    let format = ''

    if (filter.includes('date')) {
      format += 'YYYY-MM-DD'
    }

    if (filter.includes('time')) {
      format && (format += ' ')
      format += 'HH:mm:ss'
    }

    const value = moment(dt).format(format)
    onChange(value === 'Invalid date' ? null : value)
  }

  return (
    <>
      {filter.includes('date') && (
        <div className={classes.root}>
          <p className={classes.label}>{label[0]}</p>

          <Grid container alignItems="center" justify="space-between">
            <div className={classes.dateWrapper}>
              <FormControl
                error={error && date.day === null}
                style={{width: '100%'}}
              >
                <Select
                  name="day"
                  disabled={disabled}
                  variant="outlined"
                  fullWidth
                  displayEmpty
                  InputProps={{
                    classes: {
                      input: classes.inputStyle,
                    },
                  }}
                  value={date.day}
                  onChange={handleChange}
                  renderValue={
                    date.day !== null
                      ? undefined
                      : () => <Placeholder>{'Tanggal'}</Placeholder>
                  }
                >
                  {/* eslint-disable-next-line no-unused-vars */}
                  {Array.from({length: 31}, (_, idx) => {
                    const value = `${idx < 9 ? '0' : ''}${idx + 1}`

                    return (
                      <MenuItem key={idx} value={value}>
                        {value}
                      </MenuItem>
                    )
                  })}
                </Select>
                {error && date.day === null && (
                  <FormHelperText>{helperText}</FormHelperText>
                )}
              </FormControl>
            </div>

            <div className={classes.dateWrapper}>
              <FormControl
                error={error && date.month === null}
                style={{width: '100%'}}
              >
                <Select
                  name="month"
                  disabled={disabled}
                  variant="outlined"
                  fullWidth
                  displayEmpty
                  InputProps={{
                    classes: {
                      input: classes.inputStyle,
                    },
                  }}
                  value={date.month}
                  onChange={handleChange}
                  renderValue={
                    date.month !== null
                      ? undefined
                      : () => <Placeholder>{'Bulan'}</Placeholder>
                  }
                >
                  {MONTH_OPTIONS.map((month, i) => {
                    return (
                      <MenuItem key={i} value={month.value}>
                        {month.label}
                      </MenuItem>
                    )
                  })}
                </Select>
                {error && date.month === null && (
                  <FormHelperText>{helperText}</FormHelperText>
                )}
              </FormControl>
            </div>

            <div className={classes.dateWrapper}>
              <FormControl
                error={error && date.year === null}
                style={{width: '100%'}}
              >
                <Select
                  name="year"
                  disabled={disabled}
                  variant="outlined"
                  fullWidth
                  displayEmpty
                  InputProps={{
                    classes: {
                      input: classes.inputStyle,
                    },
                  }}
                  value={date.year}
                  onChange={handleChange}
                  renderValue={
                    date.year !== null
                      ? undefined
                      : () => <Placeholder>{'Tahun'}</Placeholder>
                  }
                >
                  {/* eslint-disable-next-line no-unused-vars */}
                  {Array.from({length: 50 + 1 + 50}, (_, idx) => {
                    const value = date.currentYear - 50 + idx

                    return (
                      <MenuItem key={idx} value={value}>
                        {value}
                      </MenuItem>
                    )
                  })}
                </Select>
                {error && date.year === null && (
                  <FormHelperText>{helperText}</FormHelperText>
                )}
              </FormControl>
            </div>
          </Grid>
        </div>
      )}

      {filter.includes('time') && (
        <div className={classes.root}>
          <p className={classes.label}>{label[1]}</p>

          <Grid container>
            <div className={classes.dateWrapper}>
              <FormControl
                error={error && date.hour === null}
                style={{width: '100%'}}
              >
                <Select
                  name="hour"
                  variant="outlined"
                  fullWidth
                  displayEmpty
                  InputProps={{
                    classes: {
                      input: classes.inputStyle,
                    },
                  }}
                  value={date.hour}
                  onChange={handleChange}
                  renderValue={
                    date.hour !== null
                      ? undefined
                      : () => <Placeholder>{'Jam'}</Placeholder>
                  }
                >
                  {/* eslint-disable-next-line no-unused-vars */}
                  {Array.from({length: 24}, (_, idx) => {
                    const value = `${idx < 10 ? '0' : ''}${idx}`

                    return (
                      <MenuItem key={idx} value={value}>
                        {value}
                      </MenuItem>
                    )
                  })}
                </Select>
                {error && date.hour === null && (
                  <FormHelperText>{helperText}</FormHelperText>
                )}
              </FormControl>
            </div>

            <div className={classes.dateWrapper}>
              <FormControl
                error={error && date.minute === null}
                style={{width: '100%', marginLeft: '10px'}}
              >
                <Select
                  name="minute"
                  variant="outlined"
                  fullWidth
                  displayEmpty
                  InputProps={{
                    classes: {
                      input: classes.inputStyle,
                    },
                  }}
                  value={date.minute}
                  onChange={handleChange}
                  renderValue={
                    date.minute !== null
                      ? undefined
                      : () => <Placeholder>{'Menit'}</Placeholder>
                  }
                >
                  {/* eslint-disable-next-line no-unused-vars */}
                  {Array.from({length: 60}, (_, idx) => {
                    const value = `${idx < 10 ? '0' : ''}${idx}`

                    return (
                      <MenuItem key={idx} value={value}>
                        {value}
                      </MenuItem>
                    )
                  })}
                </Select>

                {error && date.minute === null && (
                  <FormHelperText>{helperText}</FormHelperText>
                )}
              </FormControl>
            </div>
          </Grid>
        </div>
      )}
    </>
  )
}
