import React from 'react'
import {Icon} from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import {
  TyphoSection,
  WrapperItemSection,
  LogoImg,
} from '../../DetailEmployeeStyles'
import {
  AttachmentItemHover,
  WrapAttachment,
} from '../../../../../shared-components/attachment'

import {capitalizeEachWord} from '../../../../../../utils/helpers'
import {dateSplitter, generateDobleData} from '../../utilsForDetails'
import useEllipsisText from '../../../../../../hooks/useEllipsisText'
import useToggle from '../../../../../../hooks/useToggle'

export default function EducationItem({res, onEdit}) {
  const _description = res?.description?.length === 0 ? '-' : res?.description
  const {briefText} = useEllipsisText({text: _description})
  const [more, setMore] = useToggle()
  const from = dateSplitter(res.from)
  const to = dateSplitter(res.to)

  return (
    <WrapperItemSection>
      <LogoImg
        style={{width: 100, height: 100, borderRadius: 5, marginRight: 50}}
        imgProps={{
          style: {width: 'auto', height: '100%', borderRadius: 0},
        }}
      />
      <div className="divider">
        <div className="content">
          <TyphoSection title="true" bold="true">
            {res?.company_profile?.legal_name || res?.other_school || '-'}
          </TyphoSection>
          <TyphoSection style={{color: '#014a62'}}>
            {res.education_fields
              ? generateDobleData(
                  res?.education_fields?.city?.name,
                  res?.education_fields?.province?.name
                )
              : '-'}
          </TyphoSection>

          <TyphoSection blue="true">
            {res?.global_degree?.name &&
              `${capitalizeEachWord(res?.global_degree?.name || '')} Degree`}
            {res?.global_faculty?.name &&
              `, ${capitalizeEachWord(res?.global_faculty?.name)}`}
          </TyphoSection>
          <TyphoSection grey="true">
            {from[0]} - {res.is_present || !res.to ? 'present' : to[0]}
          </TyphoSection>
          <TyphoSection>GPA: {res?.education_fields?.gpa || '-'}</TyphoSection>
          <TyphoSection>{more ? _description : briefText}</TyphoSection>

          {more && (
            <>
              <TyphoSection italic="true" style={{color: '#014a62'}}>
                Certificate ID {res?.education_fields?.ceritificate_id || '-'}
              </TyphoSection>
              <WrapAttachment>
                {res?.education_fields?.attachments?.map((attachment, i) => (
                  <AttachmentItemHover
                    key={`${i}-itm`}
                    iconAction={
                      <VisibilityOutlinedIcon
                        onClick={() =>
                          window.open(attachment.url || attachment.link)
                        }
                        className="icon"
                      />
                    }
                    fileName={attachment.name}
                    url={attachment.url || attachment.link}
                  />
                ))}
              </WrapAttachment>
            </>
          )}
          <TyphoSection
            mt="true"
            style={{color: '#a9a8a8', cursor: 'pointer'}}
            onClick={setMore}
          >
            {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
          </TyphoSection>
        </div>
      </div>

      <Icon onClick={onEdit} className="icon-edit">
        edit
      </Icon>
    </WrapperItemSection>
  )
}
