import gql from 'graphql-tag'

export const ADD_NEW_GOALS = gql`
  mutation addGoal($object: [performance_goals_insert_input!]!) {
    insert_performance_goals(objects: $object) {
      affected_rows
    }
  }
`
// export const UPDATE_ATTACHMENT = (attachment) => {
//   return gql`
//     mutation updateAttachment($id: bigint!) {
//       update_performance_goals(
//         _set: {
//           attachment: [${attachment}]
//         }
//         where: { id:{_eq: $id }}
//       ){
//         affected_rows
//       }
//     }
//   `;
// };

export const UPDATE_GOAL = gql`
  mutation updateGoal(
    $id: bigint!
    $name: String!
    # $visibility: String!
    $description: String!
    $weight: float8
    $target: float8!
    # $target_stretch: float8!
    $startdate: date!
    $enddate: date!
    $priority: enum_mpp_priority!
    $attachment: jsonb
    $group: uuid
  ) {
    update_performance_goals(
      _set: {
        name: $name
        description: $description
        target: $target
        #target_stretch: $target_stretch
        weight: $weight
        priority: $priority
        # visibility: $visibility
        startdate: $startdate
        enddate: $enddate
        attachment: $attachment
        group: $group
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const ADD_NEW_GOAL_GROUP = gql`
  mutation insertGoalLabel($name: String!, $color: String!) {
    insert_performance_goal_groups(objects: [{name: $name, color: $color}]) {
      affected_rows
    }
  }
`

export const UPDATE_REPORTER = gql`
  mutation($id: bigint!, $reporter: uuid!) {
    update_performance_tasks(
      where: {id: {_eq: $id}}
      _set: {reporter: $reporter}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_PROGRESS_TASK = gql`
  mutation UpdateProgress($id: bigint!, $progress: float8!) {
    update_performance_tasks(
      _set: {progress: $progress}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const DELETE_TASK_GROUP = gql`
  mutation deleteTaskLabel($id: bigint!) {
    delete_performance_groups(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`

export const DELETE_GOAL_GROUP = gql`
  mutation deleteGoalLabel($id: uuid!) {
    delete_performance_goal_groups(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`
