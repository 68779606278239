import React from 'react'
import {Icon} from '@material-ui/core'

import {
  TyphoSection,
  WrapperItemSection,
  LogoImg,
} from '../../DetailEmployeeStyles'
import useEllipsisText from '../../../../../../hooks/useEllipsisText'
import useToggle from '../../../../../../hooks/useToggle'

export const OrganizationItem = ({
  position_name,
  organization_name,
  organization_type,
  location,
  date,
  member_id,
  description,
  onEdit,
}) => {
  const _description = description?.length === 0 ? '-' : description
  const {briefText, isOverLine, isOverLength} = useEllipsisText({
    text: _description,
  })
  const [more, setMore] = useToggle()

  return (
    <WrapperItemSection>
      <LogoImg
        style={{
          width: 100,
          height: 100,
          borderRadius: 5,
          marginRight: 50,
        }}
        imgProps={{
          style: {width: 'auto', height: '100%', borderRadius: 0},
        }}
      />
      <div className="divider">
        <div className="content">
          <TyphoSection title="true" bold="true">
            {position_name}
          </TyphoSection>
          <TyphoSection blue="true">{organization_name}</TyphoSection>
          <TyphoSection style={{color: '#014a62'}}>
            {organization_type}
          </TyphoSection>
          <TyphoSection>{location}</TyphoSection>
          <TyphoSection grey="true">{date || '-'}</TyphoSection>
          <TyphoSection grey="true">Member ID {member_id}</TyphoSection>

          <TyphoSection>{more ? _description : briefText}</TyphoSection>

          {(isOverLine || isOverLength) && (
            <TyphoSection
              mt="true"
              style={{color: '#a9a8a8', cursor: 'pointer'}}
              onClick={setMore}
            >
              {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
            </TyphoSection>
          )}
        </div>
      </div>

      <Icon onClick={onEdit} className="icon-edit">
        edit
      </Icon>
    </WrapperItemSection>
  )
}
