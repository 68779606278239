import React, {useState, useEffect} from 'react'
import {Button} from '@material-ui/core'
import {useMutation, useQuery} from '@apollo/react-hooks'
// import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'
// import DateFnsUtils from '@date-io/date-fns'
import {Link} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import MaterialTable from 'material-table'
import moment from 'moment'

import {
  EnhancedToolbar,
  PaperContainer,
  RootContainer,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import helperStyle, {
  BigTextBlackBold,
  TableOptions,
  TextBlueBold,
  TableEmployeeStyled,
  pageSizeOption,
} from '../PayrollPageStyles'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
// import {DatePickerWrap} from '../../../../components/card/CardStyles'
import GenerateManualPopup from '../../../shared-components/payroll/popup/GenerateManualPopup'
import RequestWrapper from '../../../shared-components/layouts/RequestWrapper'

import {
  convertArrayToHashMap,
  convertToRoundedRupiah,
} from '../../../../utils/helpers'
import {
  GET_LIST_THR,
  GET_RELIGIONS,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../../../graphql/queries'
import {
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
} from '../../../../graphql/mutations'
import {COMPANY_ID} from '../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../utils'

const steps = [
  'THR Data',
  'Calculation',
  'Approval',
  'Review',
  'THR Slip',
  'PAID',
]

export default function ListTHRProcess(props) {
  const {period, isDisabled} = props
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()

  const [showModalGenerateManual, setShowModalGenerateManual] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const [year, setYear] = useState(null)
  const [religionMap, setReligionMap] = useState(null)

  const {data: listOfReligions} = useQuery(GET_RELIGIONS, {
    variables: {religions: null},
    ...GQL_FINANCE_OPTIONS,
  })

  const {data: thrData, loading, error, refetch} = useQuery(GET_LIST_THR, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
      date_start: moment(year)
        .startOf('year')
        .format('YYYY-MM-DD'),
      date_end: moment(year)
        .endOf('year')
        .format('YYYY-MM-DD'),
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
    },
    skip: year === null,
    ...GQL_FINANCE_OPTIONS,
    fetchPolicy: 'network-only',
  })

  const [mutationExport] = useMutation(EXPORT_MUTATION)

  const [closeBaloonExport] = useMutation(CLOSE_BALOON_EXPORT)

  const {data: getExportBaloon} = useQuery(GET_STATUS_EXPORT_BALOON, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'finance_thr_process',
    },
  })

  useQuery(GET_EXPORT_DISABLED_BUTTON, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'finance_thr_process',
    },
  })

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  const handleGenerateData = period => {
    if (period?.period_month) {
      mutationExport({
        variables: {
          table: 'finance_thr_process',
          filter: period,
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  if (getExportBaloon) {
    if (
      getExportBaloon.company_export_data_logs.length > 0 &&
      getExportBaloon.company_export_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  useEffect(() => {
    setYear(moment(period || new Date()))
  }, [period])

  useEffect(() => {
    setCurrentPage(0)
  }, [year, rowsPerPage])

  useEffect(() => {
    if (listOfReligions) {
      setReligionMap(
        convertArrayToHashMap(listOfReligions.global_religions, 'id')
      )
    }
  }, [listOfReligions])

  const isDisabledGenerateData = thrData?.schedules?.find(
    data =>
      data?.process_step < 5 &&
      data?.total_aggregate?.aggregate?.sum?.final_thr > 0
  )

  return (
    <RootContainer style={{width: '82%'}}>
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={closeBaloonExportMutation}
        feature="THR Process"
        isPayroll
        availableToSeeProblem
        availableToDownloadExcel={false}
      />
      <PaperContainer>
        <EnhancedToolbar>
          <BigTextBlackBold>THR Process</BigTextBlackBold>
          {/* <DatePickerWrap>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              views={['year']}
              className="date-picker-dialog"
              KeyboardButtonProps={{'aria-label': 'change date'}}
              inputVariant="outlined"
              format="yyyy"
              placeholder="Choose Period"
              value={year}
              color={'primary'}
              onChange={value => {
                setYear(moment(value).format('YYYY'))
              }}
            />
          </MuiPickersUtilsProvider>
        </DatePickerWrap> */}
          <GenerateTooltip
            title={
              isDisabled || isDisabledGenerateData
                ? `You can only generate data after the status of all period is PAID`
                : ``
            }
            placement="left"
          >
            <span>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={() => setShowModalGenerateManual(true)}
                disabled={isDisabled || isDisabledGenerateData}
              >
                Generate Data
              </Button>
            </span>
          </GenerateTooltip>
        </EnhancedToolbar>

        <RequestWrapper
          loading={loading && !thrData?.schedule && !religionMap}
          error={error}
          isEmpty={!loading && thrData?.thr_count?.aggregate?.count === 0}
          emptyMessage="You can set your employee and customize your payroll method on settings"
        >
          <TableEmployeeStyled>
            <MaterialTable
              data={thrData?.schedules}
              options={{...TableOptions, paging: false}}
              totalCount={thrData?.thr_count?.aggregate?.count}
              page={currentPage}
              onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
              onChangePage={page => {
                setCurrentPage(page)
              }}
              columns={[
                /* Hide WLB 4053
                // {
                //   title: 'Holiday Date',
                //   field: 'holiday_date',
                //   headerStyle: {minWidth: 155},
                //   cellStyle: {minWidth: 155, textAlign: 'left'},
                //   customSort: (a, b) => {
                //     return moment(a.holiday_date) - moment(b.holiday_date)
                //   },
                //   render: rowData => {
                //     return (
                //       rowData.holiday_date &&
                //       moment(rowData.holiday_date).format('MMMM DD, YYYY')
                //     )
                //   },
                // },
                */
                {
                  title: 'Disbursement Date',
                  field: 'schedule',
                  headerStyle: {minWidth: 165},
                  cellStyle: {minWidth: 155, textAlign: 'left'},
                  customSort: (a, b) => {
                    return moment(a.schedule) - moment(b.schedule)
                  },
                  render: rowData => {
                    return (
                      rowData.schedule &&
                      moment(rowData.schedule).format('MMMM DD, YYYY')
                    )
                  },
                },
                {
                  title: 'Religion',
                  field: 'religions',
                  headerStyle: {minWidth: 80},
                  cellStyle: {minWidth: 80, textAlign: 'left'},
                  customSort: (a, b) => {
                    if (a.religions === b.religions) {
                      return 0
                    } else if (a.religions > b.religions) {
                      return 1
                    } else {
                      return -1
                    }
                  },
                  render: rowData => {
                    const religionIds = rowData.religions?.split(',')
                    return religionIds.map(id => {
                      return (
                        <>
                          {religionMap?.[id]?.name}
                          <br />
                        </>
                      )
                    })
                  },
                },
                {
                  title: 'THR',
                  field: 'final_thr',
                  headerStyle: {minWidth: 175},
                  cellStyle: {minWidth: 175, textAlign: 'left'},
                  render: rowData =>
                    convertToRoundedRupiah(
                      rowData.total_aggregate?.aggregate?.sum?.thr,
                      false
                    ),
                },
                {
                  title: 'Earning',
                  field: 'income',
                  headerStyle: {minWidth: 175},
                  cellStyle: {minWidth: 175, textAlign: 'left'},
                  render: rowData =>
                    convertToRoundedRupiah(
                      rowData.total_aggregate?.aggregate?.sum?.income,
                      false
                    ),
                },
                {
                  title: 'Deduction',
                  field: 'deduction',
                  headerStyle: {minWidth: 175},
                  cellStyle: {minWidth: 175, textAlign: 'left'},
                  render: rowData =>
                    convertToRoundedRupiah(
                      rowData.total_aggregate?.aggregate?.sum?.deduction,
                      false
                    ),
                },
                {
                  title: 'THR Total',
                  field: 'thr_total',
                  headerStyle: {minWidth: 175},
                  cellStyle: {minWidth: 175, textAlign: 'left'},
                  render: rowData =>
                    convertToRoundedRupiah(
                      rowData.total_aggregate?.aggregate?.sum?.final_thr,
                      false
                    ),
                },
                // {
                //   title: 'Status',
                //   field: 'status',
                //   render: rowData => (
                //     <Typography
                //       style={
                //         rowData.status === 'paid'
                //           ? {color: 'green'}
                //           : {color: 'red'}
                //       }
                //     >
                //       {rowData.status === 'unpaid' ? 'not paid' : rowData.status}
                //     </Typography>
                //   ),
                // },
                {
                  title: 'Status',
                  field: 'process_step',
                  headerStyle: {minWidth: 120},
                  cellStyle: {minWidth: 120},
                  customSort: (a, b) => {
                    return a.process_step - b.process_step
                  },
                  render: rowData =>
                    typeof rowData.process_step === 'number'
                      ? steps[rowData.process_step]
                      : '-',
                },
                {
                  title: 'Action',
                  field: 'action',
                  headerStyle: {minWidth: 100},
                  cellStyle: {minWidth: 100},
                  render: rowData => {
                    const search = `?year=${Number(
                      moment(rowData.schedule).format('YYYY')
                    )}&month=${Number(
                      moment(rowData.schedule).format('M')
                    )}&id=${rowData.id}&religion=${rowData.religions}`
                    if (
                      rowData.status.toLowerCase() === 'paid' ||
                      rowData.process_step === 5
                    ) {
                      return (
                        <>
                          <Link
                            to={{
                              pathname: `/financial/payroll/thr-process/step`,
                              search: search,
                              state: {
                                active: 'step-thr-process',
                                status: 'Paid',
                                showPeriod: false,
                                searchable: true,
                                rangePeriod: false,
                                showMonthYearPeriod: true,
                                filter: true,
                                id: rowData.id,
                                religion: rowData.religions,
                                date: rowData.schedule,
                                payrollCalculation: rowData,
                              },
                            }}
                            className={classes.link}
                          >
                            <TextBlueBold>Details</TextBlueBold>
                          </Link>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <Link
                            to={{
                              pathname: `/financial/payroll/thr-process/step`,
                              search: search,
                              state: {
                                active: 'step-thr-process',
                                showPeriod: false,
                                searchable: true,
                                rangePeriod: false,
                                showMonthYearPeriod: true,
                                filter: true,
                                id: rowData.id,
                                religion: rowData.religions,
                                date: rowData.schedule,
                                payrollCalculation: rowData,
                              },
                            }}
                            className={classes.link}
                          >
                            <TextBlueBold>Process</TextBlueBold>
                          </Link>
                        </>
                      )
                    }
                  },
                },
              ]}
            />
          </TableEmployeeStyled>
        </RequestWrapper>

        {showModalGenerateManual && (
          <GenerateManualPopup
            mutation={handleGenerateData}
            onClose={() => setShowModalGenerateManual(false)}
            open={showModalGenerateManual}
            title={'THR'}
            type={'thr'}
            enqueueSnackbar={enqueueSnackbar}
          />
        )}
      </PaperContainer>
    </RootContainer>
  )
}
