import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faFileExcel,
  faFileWord,
  faFilePowerpoint,
  faFilePdf,
  faFile,
} from '@fortawesome/free-solid-svg-icons'
import {Icon, IconButton, LinearProgress} from '@material-ui/core'

export default function MultipleFile({
  name,
  size,
  isUploaded,
  loaded,
  onDelete,
}) {
  const percent = Math.round((loaded * 100) / size)
  const percentCompleted = percent > 100 ? 100 : percent

  return (
    <div
      style={{
        backgroundColor: '#f7f8f9',
        justifyContent: 'space-between',
        display: 'flex',
        minHeight: '70px',
        marginBottom: 14,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div style={{flex: '0.3'}}>
          <FontAwesomeIcon
            icon={
              String(name).split('.').pop() === 'xls' ||
              String(name).split('.').pop() === 'xlsx' ||
              String(name).split('.').pop() === 'csv'
                ? faFileExcel
                : String(name).split('.').pop() === 'docx'
                ? faFileWord
                : String(name).split('.').pop() === 'pdf'
                ? faFilePdf
                : String(name).split('.').pop() === 'pptx'
                ? faFilePowerpoint
                : faFile
            }
            style={{
              color:
                String(name).split('.').pop() === 'xls' ||
                String(name).split('.').pop() === 'xlsx' ||
                String(name).split('.').pop() === 'csv'
                  ? 'green'
                  : String(name).split('.').pop() === 'docx'
                  ? '#039be5'
                  : String(name).split('.').pop() === 'pdf' ||
                    String(name).split('.').pop() === 'pptx'
                  ? 'red'
                  : 'grey',
              flexDirection: 'column',
              cursor: 'pointer',
              fontSize: '35px',
              margin: '20px',
            }}
          />
        </div>

        {isUploaded ? (
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <>
              <p style={{color: '#014a62', margin: '0px'}}>{name}</p>
              <p style={{color: '#a9a8a8', margin: '0px'}}>
                {size > 1000000
                  ? (size / 1000000).toFixed(1) + ' MB'
                  : size
                  ? `${size} Bytes`
                  : `Read File...`}
              </p>
            </>
          </div>
        ) : (
          <div
            style={{
              padding: '22px 0',
              width: 420,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <p
                style={{fontWeight: '600', fontSize: 12, margin: 0}}
              >{`Uploading... (${percentCompleted || 0}%)`}</p>
              <p style={{color: '#a9a8a8', fontSize: 10, margin: 0}}>
                {loaded >= size ? size : loaded}/{size}
              </p>
            </div>
            <LinearProgress variant="determinate" value={percentCompleted} />
          </div>
        )}
      </div>
      <IconButton aria-label="close" onClick={onDelete}>
        <Icon style={{color: '#a9a8a8'}}>close</Icon>
      </IconButton>
    </div>
  )
}
