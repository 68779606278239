import gql from 'graphql-tag'

export const GET_HOLDING_MEMBER = (company, status) => gql`
  query getCompanyList($search:String){
    holding_relations(
      where: {
        company_head: {_eq: "${company}"}
        status: {_eq: "${status}"}
        _or:[
        
        {
          _or:[
            {
              company_member_profile:{
                legal_name:{
                  _ilike:$search
                }
              }
            },
            {
              company_member_profile:{
                brand_name:{
                  _ilike:$search
                }
              }
            },
            {
              company_member_profile:{
                email:{
                  _ilike:$search
                }
              }
            },
            {
              company_member_profile:{
                global_company_industry_type:{
                  name:{
                  _ilike:$search
                  }
                }
              }
            }
          ]
        },

        {
          holding_cluster:{
            name:{
              _ilike:$search
            }
          }
        }

        {
          holding_subcluster:{
            name:{
              _ilike:$search
            }
          }
        }
      ]
      }
      order_by: {id: asc}
    ) {
      id
      company_member_profile {
        id
        logo
        brand_name
        legal_name
        global_company_industry_type {
          id
          name
        }
        type
        field
        email
      }
    }
  }
`
export const GET_HOLDING_MEMBER_PENDING = gql`
  query getPendingActivationCompany($company: uuid, $search: String) {
    holding_relations(
      where: {
        company_head: {_eq: $company}
        status: {_eq: "INACTIVE"}
        _or: [
          {
            _or: [
              {company_member_profile: {legal_name: {_ilike: $search}}}
              {company_member_profile: {brand_name: {_ilike: $search}}}
              {company_member_profile: {email: {_ilike: $search}}}
              {company_member_profile: {phone: {_ilike: $search}}}
            ]
          }
        ]
      }
      order_by: {id: asc}
    ) {
      id_relations: id
      status_relations: status
      company_member_profile {
        id
        logo
        brand_name
        legal_name
        phone
        email
      }
    }
  }
`
export const GET_HOLDING_MEMBER_CONFIGURING = gql`
  query getActiveCompanyList($company: uuid, $search: String) {
    holding_relations(
      where: {
        company_head: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {
            _or: [
              {company_member_profile: {legal_name: {_ilike: $search}}}
              {company_member_profile: {brand_name: {_ilike: $search}}}
              {company_member_profile: {email: {_ilike: $search}}}
              {
                company_member_profile: {
                  global_company_industry_type: {name: {_ilike: $search}}
                }
              }
            ]
          }
          {holding_cluster: {name: {_ilike: $search}}}
          {holding_subcluster: {name: {_ilike: $search}}}
          {holding_version: {name: {_ilike: $search}}}
          {holding_class: {name: {_ilike: $search}}}
          {holding_category: {name: {_ilike: $search}}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      company_member_profile {
        id
        logo
        email
        brand_name
        legal_name
        field
        global_company_industry_type {
          id
          name
        }
      }
      holding_version {
        id
        name
      }
      holding_category {
        id
        name
      }
      holding_cluster {
        id
        name
      }
      holding_subcluster {
        id
        name
      }
      holding_class {
        id
        name
      }
    }
  }
`
