import React from 'react'
import {Icon} from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import {
  TyphoSection,
  WrapperItemSection,
  LogoImg,
} from '../../DetailEmployeeStyles'
import {
  AttachmentItemHover,
  WrapAttachment,
} from '../../../../../shared-components/attachment'
import useEllipsisText from '../../../../../../hooks/useEllipsisText'
import useToggle from '../../../../../../hooks/useToggle'

export const AwardItem = ({
  onPreview,
  award_name,
  issuing_organization,
  date,
  reference_number,
  user_name,
  certificate_number,
  remark,
  attachments,
  onEdit,
}) => {
  const _remark = remark?.length === 0 ? '-' : remark
  const {briefText, isOverLine, isOverLength} = useEllipsisText({
    text: _remark,
  })
  const [more, setMore] = useToggle()

  return (
    <WrapperItemSection>
      <LogoImg
        style={{
          width: 100,
          height: 100,
          borderRadius: 5,
          marginRight: 50,
        }}
        imgProps={{
          style: {width: 'auto', height: '100%', borderRadius: 0},
        }}
      />

      <div className="divider">
        <div className="content">
          <TyphoSection title="true" bold="true">
            {award_name}
          </TyphoSection>
          <TyphoSection blue="true">{issuing_organization}</TyphoSection>
          <TyphoSection grey="true">{date}</TyphoSection>
          <TyphoSection style={{color: '#014a62'}}>
            Ref No.{reference_number}
          </TyphoSection>
          <TyphoSection>Awarded to {user_name}</TyphoSection>
          <TyphoSection grey="true">Award No.{certificate_number}</TyphoSection>
          <TyphoSection>{more ? _remark : briefText}</TyphoSection>

          {more && (
            <WrapAttachment>
              {attachments &&
                attachments.map((attachment, i) => (
                  <AttachmentItemHover
                    key={`${i}-itm`}
                    iconAction={
                      <VisibilityOutlinedIcon
                        onClick={() =>
                          onPreview(attachment.link || attachment.url)
                        }
                        className="icon"
                      />
                    }
                    fileName={attachment.name}
                    url={attachment.link || attachment.url}
                  />
                ))}
            </WrapAttachment>
          )}

          {(isOverLine || isOverLength || attachments?.length > 0) && (
            <TyphoSection
              mt="true"
              style={{color: '#a9a8a8', cursor: 'pointer'}}
              onClick={setMore}
            >
              {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
            </TyphoSection>
          )}
        </div>
      </div>

      <Icon onClick={onEdit} className="icon-edit">
        edit
      </Icon>
    </WrapperItemSection>
  )
}
