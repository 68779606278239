import gql from 'graphql-tag'

export const GET_LIST_TASK_IN_GOAL = gql`
  query($goal: bigint, $offset: Int, $limit: Int) {
    total: performance_tasks_aggregate(where: {goal: {_eq: $goal}}) {
      aggregate {
        count
      }
    }
    performance_tasks(
      where: {goal: {_eq: $goal}}
      offset: $offset
      limit: $limit
    ) {
      id
      name
      priority
      progress_percentage
      progress
      status
      kpi_weight_unit {
        id
        name
      }
    }
  }
`

export const GET_DETAIL_TASK = gql`
  query($id: bigint) {
    performance_tasks(where: {id: {_eq: $id}}) {
      id
      name
      group
      description
      attachment
      visibility
      startdate
      enddate
      date_added
      status
      priority
      method_type
      metric
      weight
      aspect
      target
      target_stretch
      progress
      progress_percentage
      kpi_aspect {
        id
        code
        name
      }
      kpi_weight_unit {
        id
        name
      }
      created_by_user {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
      ReporterUser {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
      performance_group {
        id
        name
        color
      }
      performance_goal {
        id
        name
        startdate
        enddate
        description
        kpi_list {
          id
          name
          formula
          description
          kpi_scoring_nature {
            id
            name
          }
          kpi_weight_unit {
            id
            name
          }
          kpi_aspect {
            id
            name
          }
          kpi_level {
            id
            name
          }
          kpi_version {
            id
            name
          }
          kpi_method {
            id
            name
          }
          kpi_monitoring_frequency {
            id
            name
          }
          kpi_perspective {
            id
            name
          }
          kpi_cascading_lists(limit: 1, order_by: [{id: desc}]) {
            id
            cascading_main {
              id
              name
              status
            }
          }
          kpi_cascading_lists_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      performance_task_assignments {
        id
        global_user {
          id
          name
          avatar
          people_work_placements {
            id
            company_job_profile {
              id
              title
            }
          }
        }
      }
    }
  }
`
