import gql from 'graphql-tag'

export const UPDATE_COMPETENCY_CATEGORY = gql`
  mutation(
    $category: bigint!
    $code: String!
    $name: String!
    $icon: String!
    $description: String!
    $parent: Int
  ) {
    update_competency_categories(
      where: {id: {_eq: $category}}
      _set: {
        code: $code
        name: $name
        icon: $icon
        parent: $parent
        description: $description
      }
    ) {
      affected_rows
    }
  }
`
