import AttendancePage from './AttendancePageComponent'

export const AttendancePageConfig = {
  routes: [
    {
      path: '/time/attendance',
      component: AttendancePage,
      state: 'activity',
      exact: true,
    },
    {
      path: '/time/attendance/activity',
      component: AttendancePage,
      state: 'activity',
      exact: false,
    },
    {
      path: '/time/attendance/work_schedule',
      component: AttendancePage,
      state: 'work-schedule',
      exact: false,
    },
    {
      path: '/time/attendance/attendance_number',
      component: AttendancePage,
      state: 'attendance-number',
      exact: false,
    },
    {
      path: '/time/attendance/upload',
      component: AttendancePage,
      state: 'upload',
    },
    {
      path: '/time/attendance/attendance_number_upload',
      component: AttendancePage,
      state: 'attendance-number-upload',
    },
    {
      path: '/time/attendance/detail',
      component: AttendancePage,
      state: 'detail',
    },
    {
      path: '/time/attendance/date/detail',
      component: AttendancePage,
      state: 'date-detail',
    },
    {
      path: '/time/attendance/work_schedule/add',
      component: AttendancePage,
      state: 'work-schedule-add',
    },
    {
      path: '/time/attendance/work_schedule/edit',
      component: AttendancePage,
      state: 'work-schedule-edit',
    },
    {
      path: '/time/attendance/attendance_report',
      component: AttendancePage,
      state: 'attendance-report',
    },
    {
      path: '/time/attendance/location_restrictions',
      component: AttendancePage,
      state: 'location-restrictions',
    },
    {
      path: '/time/attendance/location_restrictions/add',
      component: AttendancePage,
      state: 'location-restrictions-add',
    },
    {
      path: '/time/attendance/location_restrictions/edit',
      component: AttendancePage,
      state: 'location-restrictions-edit',
    },
    {
      path: '/time/attendance/mobile_attendance/settings',
      component: AttendancePage,
      state: 'location-restrictions-edit',
    },
    {
      path: '/time/attendance/mobile_attendance/settings',
      component: AttendancePage,
      state: 'mobile-attendance-setting',
    },
  ],
}
