import React, {useEffect, useState} from 'react'
import {useQuery} from '@apollo/react-hooks'
import {
  FormChildContainer,
  FormChildTitle,
  TitleName,
  TitleNumber,
  FormChildInput,
  FlexBetween,
} from '../../../../../../GlobalStyles'
import FieldInformationTooltip from '../../../../../../pages/shared-components/tooltip/FieldInformationTooltip'
import {withRouter} from 'react-router-dom'
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  // Button,
  FormControl,
  FormGroup,
  Checkbox,
  FormHelperText,
  Divider,
} from '@material-ui/core'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
// import userInputState from '../../../../../../hooks/userInputState'
import {
  checkStringInput,
  // checkIntegerInput,
  // stringHelperText,
  // integerHelperText,
  inputRequiredHelperText,
} from '../formValidation.helper'
import {CHECK_COMPONENTS_VALIDATION_CODE} from '../../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../../utils'
import TextFieldCurrency from '../../../../../../components/form/TextFieldCurrency'

const FormComponent = props => {
  const {value, handleComponent, hasSubmit} = props
  const {
    currentCode,
    code,
    name,
    type,
    frequency,
    calculation,
    calculation_mul,
    calculation_value,
    tax_paid_by,
    taxable,
    part_of,
    effective_date,
    include_in_bpjs,
  } = value

  const [skipQuery, setSkipQuery] = useState(true)
  const [componentCode, setComponentCode] = useState('')
  const [isUnique, setisUnique] = useState(code === null ? null : true)

  /**
   * fetch only when code component field is onBlur and code is not same as previous
   */
  useQuery(CHECK_COMPONENTS_VALIDATION_CODE, {
    variables: {
      company: COMPANY_ID,
      code: componentCode,
    },
    ...GQL_FINANCE_OPTIONS,
    skip: skipQuery || componentCode === currentCode,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data) {
        handleComponent('isUnique', data.total.aggregate.count === 0)
        setisUnique(data.total.aggregate.count === 0)
        setSkipQuery(true)
      }
    },
  })

  const renderComponentCodeHelperText = () => {
    if (!isUnique && isUnique !== null) return 'Component Code is already exist'
    else if (hasSubmit && !code) {
      return 'Need to filled mandatory field'
    }
  }

  const informationText = {
    componentCode: `Unique code to identify components`,
    componentName: `The name of the component`,
    componentType: `Is the component type a deduction or an earning`,
    componentFrequency: `Components are calculated based on the frequency`,
    calcBase: `The basis for calculating the earning or deduction component`,
    calcMul: `The amount of the fixed percentage of the earning or deduction component`,
    calcValue: `The amount of the fixed value component`,
    taxable: `Calculate tax for this component`,
    tax_paid_by: `Is the tax of this component paid by employee or company`,
    includeInTHR: `Calculate this component in THR`,
    includeInBPJS: `Calculate this component in BPJS`,
    calcDateStartingFrom: `The starting date of the calculation`,
  }

  /**
   * for edit: if code is same as previous, then code uniqueness is true
   * check only when code component field is onBlur
   */
  useEffect(() => {
    if (componentCode !== '' && currentCode !== null) {
      if (componentCode === currentCode) {
        handleComponent('isUnique', true)
        setisUnique(true)
      }
    }
  }, [skipQuery])

  return (
    <>
      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Component Code*</TitleName>
          <FieldInformationTooltip title={informationText.componentCode} />
        </FormChildTitle>
        <FormChildInput>
          <TextField
            size="small"
            margin="normal"
            variant="outlined"
            placeholder="Add Component Code"
            onBlur={e => {
              setComponentCode(e.target.value)
              setSkipQuery(false)
            }}
            onChange={e => {
              handleComponent('code', e.target.value)
            }}
            value={code || ''}
            error={
              (hasSubmit && !code) ||
              (hasSubmit && !isUnique) ||
              (isUnique !== null && !isUnique)
            }
            helperText={renderComponentCodeHelperText()}
          />
        </FormChildInput>
      </FormChildContainer>

      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Component Name*</TitleName>
          <FieldInformationTooltip title={informationText.componentName} />
        </FormChildTitle>
        <FormChildInput>
          <TextField
            size="small"
            margin="normal"
            variant="outlined"
            placeholder="Add Component Name"
            onChange={e => handleComponent('name', e.target.value)}
            value={name || ''}
            error={hasSubmit && !name}
            helperText={inputRequiredHelperText(hasSubmit, !name)}
          />
        </FormChildInput>
      </FormChildContainer>

      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>Component Type*</TitleName>
          <FieldInformationTooltip title={informationText.componentType} />
        </FormChildTitle>
        <FormChildInput>
          <FormControl error={hasSubmit && !type}>
            <RadioGroup
              row
              name="position"
              onChange={e => handleComponent('type', parseInt(e.target.value))}
              value={type}
            >
              <FormControlLabel
                key="1"
                value={1}
                control={<Radio color="primary" />}
                label="Earning"
              />
              <FormControlLabel
                key="0"
                value={2}
                control={<Radio color="primary" />}
                label="Deduction"
              />
            </RadioGroup>
            <FormHelperText>
              {inputRequiredHelperText(hasSubmit, !type) || ''}
            </FormHelperText>
          </FormControl>
        </FormChildInput>
      </FormChildContainer>

      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>Component Frequency*</TitleName>
          <FieldInformationTooltip title={informationText.componentFrequency} />
        </FormChildTitle>
        <FormChildInput>
          <TextField
            select
            fullWidth
            variant="outlined"
            onChange={e => handleComponent('frequency', e.target.value)}
            value={frequency || 'placeholder'}
            error={hasSubmit && !frequency}
            helperText={inputRequiredHelperText(hasSubmit, !frequency)}
          >
            <MenuItem key="0" value="placeholder" disabled>
              Select Component Frequency
            </MenuItem>
            <MenuItem key="1" value="monthly">
              Monthly
            </MenuItem>
            {/* Hide: WLB-3835 */}
            {/* <MenuItem key="2" value="daily">
              Daily
            </MenuItem>
            <MenuItem key="3" value="quarterly">
              Quarterly
            </MenuItem> */}
            <MenuItem key="4" value="custom">
              Custom
            </MenuItem>
          </TextField>
        </FormChildInput>
      </FormChildContainer>
      {/* <MethodBaseCheckBox {...props} /> */}

      <FormChildContainer>
        <FlexBetween>
          <div>
            <FormChildTitle>
              <TitleNumber>5.</TitleNumber>
              <TitleName>Calculation Base*</TitleName>
              <FieldInformationTooltip title={informationText.calcBase} />
            </FormChildTitle>
            <FormChildInput>
              <TextField
                select
                fullWidth
                variant="outlined"
                onChange={e => handleComponent('calculation', e.target.value)}
                value={calculation || 'placeholder'}
                error={hasSubmit && !calculation}
                helperText={inputRequiredHelperText(hasSubmit, !calculation)}
                style={{
                  width: '231px',
                }}
              >
                <MenuItem value="placeholder" disabled>
                  Select Calculation Base
                </MenuItem>
                <MenuItem value="base">Basic Salary</MenuItem>
                <MenuItem value="thp">Take Home Pay</MenuItem>
                {/* Hide: WLB-3835 */}
                {/* <MenuItem value="pmw">Province Minimum Wage</MenuItem> */}
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </FormChildInput>
          </div>
          {['base', 'thp', 'pmw'].includes(calculation) && (
            <div>
              <FormChildTitle>
                <TitleNumber>
                  <span style={{color: 'white'}}>3.</span>
                </TitleNumber>
                <TitleName
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Component Calculation Mul (%)
                </TitleName>
                <FieldInformationTooltip title={informationText.calcMul} />
              </FormChildTitle>
              <FormChildInput
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    marginRight: '10px',
                  }}
                >
                  (
                </span>
                <TextField
                  error={
                    hasSubmit &&
                    calculation !== 'other' &&
                    (String(value.calculation_mul).length === 0 ||
                      value.calculation_mul === null ||
                      Number(value.calculation_mul) < 0)
                  }
                  helperText={inputRequiredHelperText(
                    hasSubmit,
                    calculation !== 'other' &&
                      (String(value.calculation_mul).length === 0 ||
                        value.calculation_mul === null ||
                        Number(value.calculation_mul) < 0)
                  )}
                  onChange={e =>
                    handleComponent('calculation_mul', e.target.value, 'number')
                  }
                  type="number"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    min: 0,
                    style: {textAlign: 'right'},
                  }}
                  value={calculation_mul || 'placeholder'}
                  style={{
                    width: '100px',
                  }}
                />
                <span
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  )
                </span>
              </FormChildInput>
            </div>
          )}
          {['other'].includes(calculation) && (
            <div>
              <FormChildTitle>
                <TitleNumber>
                  <span style={{color: 'white'}}>3.</span>
                </TitleNumber>
                <TitleName
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Calculation Value (Rp)
                </TitleName>
                <FieldInformationTooltip title={informationText.calcValue} />
              </FormChildTitle>
              <FormChildInput
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    marginRight: '10px',
                  }}
                >
                  (
                </span>
                <TextFieldCurrency
                  error={
                    hasSubmit &&
                    calculation === 'other' &&
                    (String(value.calculation_value).length === 0 ||
                      value.calculation_value === null ||
                      Number(value.calculation_value) < 0)
                  }
                  helperText={inputRequiredHelperText(
                    hasSubmit,
                    calculation === 'other' &&
                      (String(value.calculation_value).length === 0 ||
                        value.calculation_value === null ||
                        Number(value.calculation_value) < 0)
                  )}
                  onChange={e =>
                    handleComponent(
                      'calculation_value',
                      e.target.value,
                      'number'
                    )
                  }
                  size="small"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      style: {
                        textAlign: 'right',
                      },
                    },
                  }}
                  value={calculation_value}
                  style={{
                    width: '120px',
                  }}
                />
                <span
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  )
                </span>
              </FormChildInput>
            </div>
          )}
        </FlexBetween>
      </FormChildContainer>

      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>6.</TitleNumber>
          <TitleName>Include in THR*</TitleName>
          <FieldInformationTooltip title={informationText.includeInTHR} />
        </FormChildTitle>
        <FormChildInput>
          <FormControl error={hasSubmit && !part_of}>
            <RadioGroup
              row
              name="part_of"
              onChange={e =>
                handleComponent('part_of', parseInt(e.target.value))
              }
              value={part_of}
            >
              <FormControlLabel
                key="1"
                value={1}
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                key="0"
                value={2}
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
            <FormHelperText>
              {inputRequiredHelperText(hasSubmit, !part_of)}
            </FormHelperText>
          </FormControl>
        </FormChildInput>
      </FormChildContainer>

      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>7.</TitleNumber>
          <TitleName>Include in BPJS*</TitleName>
          <FieldInformationTooltip title={informationText.includeInBPJS} />
        </FormChildTitle>
        <FormChildInput>
          <FormControl error={hasSubmit && !include_in_bpjs}>
            <RadioGroup
              row
              name="include_in_bpjs"
              value={include_in_bpjs}
              onChange={e =>
                handleComponent('include_in_bpjs', e.target.value === 'true')
              }
              error={hasSubmit && include_in_bpjs === null}
              helperText={include_in_bpjs === null}
            >
              <FormControlLabel
                key="1"
                value={true}
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                key="0"
                value={false}
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
            <FormHelperText>
              {inputRequiredHelperText(hasSubmit, include_in_bpjs === null)}
            </FormHelperText>
          </FormControl>
        </FormChildInput>
      </FormChildContainer>

      <FormChildContainer>
        <FormChildTitle>
          <TitleNumber>8.</TitleNumber>
          <TitleName>Taxable*</TitleName>
          <FieldInformationTooltip title={informationText.taxable} />
        </FormChildTitle>
        <FormChildInput>
          <FormControl error={hasSubmit && !taxable}>
            <RadioGroup
              row
              name="taxable"
              value={taxable}
              onChange={e =>
                handleComponent('taxable', e.target.value === 'true')
              }
              error={hasSubmit && taxable === null}
              helperText={taxable === null}
            >
              <FormControlLabel
                key="1"
                value={true}
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                key="0"
                value={false}
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
            <FormHelperText>
              {inputRequiredHelperText(hasSubmit, taxable === null)}
            </FormHelperText>
          </FormControl>
        </FormChildInput>
      </FormChildContainer>

      {taxable && (
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber style={{color: 'white'}}>8.</TitleNumber>
            <TitleName>Tax Paid by</TitleName>
            <FieldInformationTooltip title={informationText.tax_paid_by} />
          </FormChildTitle>
          <FormChildInput>
            <FormControl error={hasSubmit && !tax_paid_by}>
              <RadioGroup
                row
                name="tax_paid_by"
                onChange={e =>
                  handleComponent('tax_paid_by', parseInt(e.target.value))
                }
                value={tax_paid_by}
                error={checkStringInput(tax_paid_by)}
                helperText={!tax_paid_by}
              >
                <FormControlLabel
                  key="1"
                  value={1}
                  control={<Radio color="primary" />}
                  label="Employee"
                />
                <FormControlLabel
                  key="0"
                  value={2}
                  control={<Radio color="primary" />}
                  label="Company"
                />
              </RadioGroup>
              <FormHelperText>
                {inputRequiredHelperText(hasSubmit, !tax_paid_by)}
              </FormHelperText>
            </FormControl>
          </FormChildInput>
        </FormChildContainer>
      )}

      <FormChildContainer style={{maxWidth: 300}}>
        <FormChildTitle>
          <TitleNumber>9.</TitleNumber>
          <TitleName>Calculation Date Starting From*</TitleName>
          <FieldInformationTooltip
            title={informationText.calcDateStartingFrom}
          />
        </FormChildTitle>
        <FormChildInput>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              margin="normal"
              id="date-picker-dialog"
              format="yyyy-MM-dd"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              inputVariant="outlined"
              onChange={value => {
                handleComponent('effective_date', value)
              }}
              value={effective_date}
              placeholder="Select Date"
              error={hasSubmit && !effective_date}
              helperText={inputRequiredHelperText(hasSubmit, !effective_date)}
            />
          </MuiPickersUtilsProvider>
        </FormChildInput>
      </FormChildContainer>
    </>
  )
}

export default withRouter(FormComponent)

const checkboxStyle = {
  borderRadius: '5px',
  border: '1px solid #eff2f4',
  padding: '10px 15px',
}

// eslint-disable-next-line no-unused-vars
const MethodBaseCheckBox = props => {
  const {value, handleComponent} = props
  const {method_base} = value
  const [state, setState] = useState(method_base)
  // const [state, setState] = useState(['level', 'dow', 'position'])

  // const {level, dow, position} = state
  const handleChange = event => {
    setState(oldState => {
      if (oldState.includes(event.target.value)) {
        return oldState.filter(i => i !== event.target.value)
      } else {
        return oldState.concat(event.target.value)
      }
    })
    let newValue = []
    if (state.includes(event.target.value)) {
      newValue = state.filter(i => i !== event.target.value)
    } else {
      newValue = state.concat(event.target.value)
    }
    handleComponent('method_base', newValue)
  }

  // const error = ['level', 'dow', 'position'].filter(v => v).length !== 2

  return (
    <FormChildContainer>
      <FormChildTitle>
        <TitleNumber>4.</TitleNumber>
        <TitleName>Component Method Based on</TitleName>
      </FormChildTitle>
      <FormChildInput>
        <FormControl component="fieldset" required>
          <FormGroup style={checkboxStyle}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!state.includes('level')}
                  onChange={handleChange}
                  name="level"
                  value="level"
                  color="primary"
                />
              }
              label="Level"
            />
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!state.includes('dow')}
                  onChange={handleChange}
                  name="dow"
                  value="dow"
                  color="primary"
                />
              }
              label="Days of Work"
            />
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!state.includes('position')}
                  onChange={handleChange}
                  name="position"
                  value="position"
                  color="primary"
                />
              }
              label="Position"
            />
          </FormGroup>
          {/* <FormHelperText>You can display an error</FormHelperText> */}
        </FormControl>
      </FormChildInput>
    </FormChildContainer>
  )
}
