import {Button, IconButton, makeStyles, Tab, Tabs} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
} from '../../../../GlobalStyles'
import FormDetail from './tabs/FormDetail'
import FormCommittee from './tabs/FormCommittee'
import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_COMPANY_HOLDING,
  GET_JOB_PROFILE_HOLDING,
  GET_LIST_COMMITTEE_HOLDING,
  GET_NOMINATION_BY_ID,
  GET_COMMITTEE_BY_ID_NOMINATION,
  GET_LIST_COMMITTEE_HOLDING_EDIT,
} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import {holdingRolesContext} from '../../../../utils/helpers'
import Loading from '../../../../components/loading/LoadingComponent'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {
  ADD_HOLDING_NOMINATION,
  UPDATE_HOLDING_NOMINATION,
} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
  committeContainer: {marginTop: 10},
  committeList: {display: 'flex', flexWrap: 'wrap'},
  paperCommittee: {
    padding: 20,
    width: '27%',
    margin: '16px 9px 0 9px',
    display: 'flex',
  },
  detailCom: {
    marginLeft: 28,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  mr20: {marginRight: 20},
  mt20: {marginTop: '20px !important'},
  companyWrapper: {display: 'flex', marginTop: 20, width: '100%'},
  structureHeader: {textAlign: 'left', cursor: 'pointer', width: '100%'},
}))

function FormNomination(props) {
  const client = useApolloClient()
  const id = props.match.params.id
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const [tabsValue, setTabsValue] = useState(0)
  const [dataCompany, setDataCompany] = useState(null)

  const [dataCommittee, setDataCommittee] = useState(null)
  const [values, setValues] = useState({
    name: '',
    description: '',
    profile: '',
    status: '',
  })
  const [company, setCompany] = useState(null)
  const [committee, setCommittee] = useState([])
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [isError, setIsError] = useState(false)

  const {name, description, profile, status} = values

  const [mutationAddNomination] = useMutation(ADD_HOLDING_NOMINATION)
  const [mutationUpdateNomination] = useMutation(UPDATE_HOLDING_NOMINATION)

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const handleClickOpen = type => {
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      if (
        !values.name ||
        !values.description ||
        !values.status ||
        !values.profile ||
        !company
      ) {
        setTabsValue(0)
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else if (committee.length === 0) {
        setTabsValue(1)
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else {
        setConfirmType(type)
        setPopup(true)
      }
    }
  }

  const handleClose = () => {
    setPopup(false)
  }

  const handleMutation = () => {
    if (id) {
      mutationUpdateNomination({
        variables: {
          id,
          changes: {
            company: company.id,
            profile,
            name,
            description,
            status,
          },
        },
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          props.history.goBack()
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    } else {
      const objects = {
        company: company.id,
        head_company: COMPANY_ID,
        profile,
        name,
        description,
        holding_nomination_commites: {
          data: committee.map(res => {
            return {
              user_work_id: res.id,
            }
          }),
        },
        status,
      }
      mutationAddNomination({
        variables: {objects},
        ...holdingRolesContext,
      })
        .then(() => {
          enqueueSnackbar('Success Update data', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          props.history.goBack()
        })
        .catch(e => {
          enqueueSnackbar(
            'Update data error, please try again' + JSON.stringify(e),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          )
        })
    }
  }

  const getCompany = async () => {
    const {data} = await client.query({
      query: GET_COMPANY_HOLDING,
      variables: {company: COMPANY_ID, search: null},
      ...holdingRolesContext,
    })
    if (data) {
      const _dataCompanyMember = []
      data &&
        data.list_company_member.map(res =>
          _dataCompanyMember.push(res.company_member_profile)
        )
      const _dataCompany = data.list_company_head.concat(_dataCompanyMember)
      setDataCompany(_dataCompany)
    }
  }
  // console.log('datacompany', dataCompany)

  // const getPosition = async () => {
  //   const {data} = await client.query({
  //     query: GET_JOB_PROFILE_HOLDING,
  //     variables: {company: company ? company.id : COMPANY_ID},
  //     ...holdingRolesContext,
  //   })
  //   if (data) {
  //     setDataPosition(data.company_job_profiles)
  //   }
  // }

  const {data: dataPosition, error: errorPosition} = useQuery(
    GET_JOB_PROFILE_HOLDING,
    {
      variables: {company: company ? company.id : COMPANY_ID},
      ...holdingRolesContext,
    }
  )

  const getCommittee = async () => {
    const {data} = await client.query({
      query: GET_LIST_COMMITTEE_HOLDING,
      variables: {companyHead: COMPANY_ID, search: null},
      ...holdingRolesContext,
    })
    if (data) {
      const newArr = data.list_head.concat(data.list_member)
      setDataCommittee(
        newArr.map(res => {
          return {
            ...res,
            name: res.global_user.name,
          }
        })
      )
    }
  }

  const getCommitteeEdit = async () => {
    const {data} = await client.query({
      query: GET_LIST_COMMITTEE_HOLDING_EDIT,
      variables: {companyHead: COMPANY_ID, nomination_id: id, search: null},
      ...holdingRolesContext,
      fetchPolicy: 'network-only',
    })

    if (data) {
      const newArr = data.list_head.concat(data.list_member)
      setDataCommittee(
        newArr.map(res => {
          return {
            ...res,
            name: res.global_user.name,
          }
        })
      )
    }
  }

  const getNominationByID = async () => {
    const {data} = await client.query({
      query: GET_NOMINATION_BY_ID,
      variables: {id},
      ...holdingRolesContext,
    })
    if (data) {
      setValues({
        name: data.holding_nomination_config[0].name,
        description: data.holding_nomination_config[0].description,
        profile: data.holding_nomination_config[0].company_job_profile.id,
        status: data.holding_nomination_config[0].status,
      })
      setCompany(data.holding_nomination_config[0].company_profile)
    }
  }

  const getCommitteeByID = async () => {
    const {data} = await client.query({
      query: GET_COMMITTEE_BY_ID_NOMINATION,
      variables: {id},
      ...holdingRolesContext,
    })
    if (data) {
      const arr = []
      for (
        let index = 0;
        index < data.holding_nomination_commite.length;
        index++
      ) {
        arr.push({
          ...data.holding_nomination_commite[index].people_work_placement,
          id_nomination: data.holding_nomination_commite[index].id,
          name:
            data.holding_nomination_commite[index].people_work_placement
              .global_user.name,
        })
      }
      setCommittee(arr)
    }
  }

  useEffect(() => {
    getCompany()
    // getPosition()

    if (id) {
      getNominationByID()
      getCommitteeByID()
      getCommitteeEdit()
    } else {
      getCommittee()
    }
  }, [])

  const handleChange = name => event => {
    let obj = {}

    obj = {
      ...values,
      [name]: event.target.value,
    }

    setValues(obj)
  }

  if (
    !dataCommittee ||
    !dataCompany ||
    (dataPosition === undefined && errorPosition === undefined)
  ) {
    return <Loading />
  } else if (errorPosition !== undefined) {
    return <div>{errorPosition}</div>
  }

  return (
    <PaperContainer style={{minHeight: '70vh'}}>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            // onClick={props.history.goBack}
            onClick={() => handleClickOpen('discard')}
          >
            <ArrowBack />
          </IconButton>

          <AddEditTitle variant="h6">
            {id ? 'Edit' : 'Add'} Holding Vacancy
          </AddEditTitle>
        </FormTitleContainer>

        <div>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => handleClickOpen(id ? 'edit' : 'add')}
          >
            Save
          </Button>
        </div>
      </FormToolbar>
      <Tabs
        value={tabsValue}
        onChange={handleChangeTabs}
        indicatorColor="secondary"
        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
      >
        <Tab label="Detail" className={classes.tabRoot} />
        <Tab label="Committee" className={classes.tabRoot} />
      </Tabs>
      {tabsValue === 0 ? (
        <FormDetail
          dataPosition={dataPosition.company_job_profiles}
          values={values}
          handleChange={handleChange}
          dataCompany={dataCompany}
          isError={isError}
          setCompany={setCompany}
          company={company}
          classes={classes}
          setValues={setValues}
        />
      ) : (
        <FormCommittee
          classes={classes}
          dataCommittee={dataCommittee}
          committee={committee}
          setCommittee={setCommittee}
          isError={isError}
          isEdit={id}
          getCommitteeEdit={getCommitteeEdit}
        />
      )}
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={values.name}
        feature="Holding Nomination"
        mutation={
          confirmType === 'discard' ? props.history.goBack : handleMutation
        }
      />
    </PaperContainer>
  )
}

export default withRouter(FormNomination)
