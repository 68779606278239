import gql from 'graphql-tag'

export const LIST_JOB_PROFILE_TREE = gql`
  query(
    $organization: Int
    $search: String
    $limit: Int
    $offset: Int
    $id_nin: [Int!]
  ) {
    company_job_profiles(
      where: {
        _or: [
          {title: {_ilike: $search}}
          {code: {_ilike: $search}}
          {job_profile_supervisor: {title: {_ilike: $search}}}
          {company_organization: {name: {_ilike: $search}}}
        ]
        organization: {_eq: $organization}
        id: {_nin: $id_nin}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      title
      job_summary
      job_description
      job_icon
      slots
      experience_level
      parent
      rate_salary_from
      rate_salary_to
      attachment
      job_grade {
        id
        code
        name
      }
      require_successor
      acting_as {
        id
        code
        title
      }
      company_organization {
        id
        name
      }
      company_employee_position {
        id
        designation_code
        name
      }
      company_employee_rank {
        id
        code
        name
      }
      company_address {
        id
        office_code
        office_name
      }
      company_job_profile_r_educations {
        id
        global_degree {
          id
          name
        }
        global_faculty {
          id
          name
        }
      }
      company_job_profile_competencies(
        where: {
          competency_version_dictionary: {
            competency_version: {status: {_eq: "ACTIVE"}}
          }
        }
      ) {
        id
        competency_version_dictionary {
          id
          competency_dictionary {
            id
            name
            description
            type
          }
        }
        level
      }
      company_job_profile_r_course {
        id
        academy_course {
          id
          title
        }
      }
      company_job_profile_r_kpi {
        id
        kpi_list {
          id
          name
        }
      }
      roles
      job_profile_supervisor {
        id
        code
        organization
        title
      }
      people_work_placements(limit: 5, where: {status: {_eq: "ACTIVE"}}) {
        global_user {
          id
          name
          avatar
        }
      }
      people_work_placements_aggregate(where: {status: {_eq: "ACTIVE"}}) {
        aggregate {
          count
        }
      }
      job_profile_subordinate_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`
export const LIST_JOB_PROFILE_DROPDOWN_FORM = gql`
  query($search: String, $limit: Int, $id: Int) {
    company_job_profiles(
      where: {
        _or: [
          {title: {_ilike: $search}}
          {code: {_ilike: $search}}
          {job_profile_supervisor: {title: {_ilike: $search}}}
          {company_organization: {name: {_ilike: $search}}}
        ]
      }
      limit: $limit
    ) {
      id
      title
    }
  }
`

export const LIST_JOB_PROFILE_LIST_FILTER_ASSIGN_TO = gql`
  query(
    $policyId: Int_comparison_exp
    $companyId: uuid!
    $search: String!
    $job_family: Int_comparison_exp
    $job_function: Int_comparison_exp
    $job_title: Int_comparison_exp
    $org_unit: Int_comparison_exp
    $org_level: Int_comparison_exp
    $job_level: Int_comparison_exp
    $job_grade: Int_comparison_exp
    $office: Int_comparison_exp
    $position: Int_comparison_exp
    $limit: Int
    $offset: Int
  ) {
    total_assign: company_job_profiles_aggregate(
      where: {
        id: $position
        company: {_eq: $companyId}
        company_employee_position: {
          job_mapping_id: $job_function
          family_id: $job_family
          id: $job_title
        }
        company_organization: {id: $org_unit, organization_level_id: $org_level}
        rank: $job_level
        job_grade_id: $job_grade
        office_address: $office
      }
    ) {
      aggregate {
        count
      }
    }
    total: company_job_profiles_aggregate(
      where: {
        id: $position
        company: {_eq: $companyId}
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
        company_employee_position: {
          job_mapping_id: $job_function
          family_id: $job_family
          id: $job_title
        }
        company_organization: {id: $org_unit, organization_level_id: $org_level}
        rank: $job_level
        job_grade_id: $job_grade
        office_address: $office
      }
    ) {
      aggregate {
        count
      }
    }
    company_job_profiles(
      where: {
        id: $position
        company: {_eq: $companyId}
        _or: [{code: {_ilike: $search}}, {title: {_ilike: $search}}]
        company_employee_position: {
          job_mapping_id: $job_function
          family_id: $job_family
          id: $job_title
        }
        company_organization: {id: $org_unit, organization_level_id: $org_level}
        rank: $job_level
        job_grade_id: $job_grade
        office_address: $office
      }
      order_by: [{code: asc}]
      limit: $limit
      offset: $offset
    ) {
      id
      code
      title
    }
  }
`

export const LIST_JOB_PROFILE_LIST_FILTER = gql`
  query(
    $organization: Int
    $parent: Int
    $search: String
    $limit: Int
    $offset: Int
  ) {
    company_job_profiles(
      where: {
        _or: [
          {title: {_ilike: $search}}
          {code: {_ilike: $search}}
          {job_profile_supervisor: {title: {_ilike: $search}}}
          {company_organization: {name: {_ilike: $search}}}
        ]
        organization: {_eq: $organization}
        parent: {_eq: $parent}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      title
    }
  }
`
