import React, {useState} from 'react'
import {IconButton, makeStyles, Tab, Tabs, Toolbar} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import {
  FlexCenters,
  PaperContainer,
  CustomTypography,
} from '../../../../GlobalStyles'

import DetailInfo from './DetailInfo'
import DetailLevel from './DetailLevel'
import ArrowBack from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
  root: {padding: 24},
  ml12: {marginLeft: 12},
}))

const DictionaryDetail = props => {
  const classes = useStyles()
  const state = props.location.state
  const [tabsValue, setTabsValue] = useState(0)
  const [detail, setDetail] = useState(null)

  React.useEffect(() => {
    if (!state?.data) {
      props.history.push('/learning/competency/model')
    } else {
      const {data} = state

      setDetail(data)
    }
  }, [state])

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  return (
    <PaperContainer>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          display: 'flex',
        }}
        disableGutters
      >
        <FlexCenters>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => props.history.goBack()}
            style={{color: '#000', marginLeft: 10}}
          >
            <ArrowBack />
          </IconButton>

          <CustomTypography fweight="bold" left="10px">
            Detail Competency
          </CustomTypography>
        </FlexCenters>
      </Toolbar>
      <Tabs
        value={tabsValue}
        onChange={handleChangeTabs}
        indicatorColor="secondary"
        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
      >
        <Tab label="Competency Detail" className={classes.tabRoot} />
        <Tab label="Competency Level" className={classes.tabRoot} />
      </Tabs>

      {tabsValue === 0 && <DetailInfo data={detail} />}
      {tabsValue === 1 && <DetailLevel data={detail} />}
    </PaperContainer>
  )
}
export default withRouter(DictionaryDetail)
