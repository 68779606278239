import React from 'react'
import {CircularProgress} from '@material-ui/core'

import {CenterWrapper} from '../../../GlobalStyles'
import NoDataListComponent from '../NoDataListComponent'

// eslint-disable-next-line handle-callback-err
export default function RequestWrapper({
  error,
  loading,
  isEmpty,
  emptyMessage,
  emptyChildrenComponent,
  children,
}) {
  if (error) {
    console.error({error})
    return null
  }
  if (loading)
    return (
      <CenterWrapper>
        <CircularProgress />
      </CenterWrapper>
    )
  if (isEmpty) {
    return (
      <NoDataListComponent message={emptyMessage}>
        {emptyChildrenComponent}
      </NoDataListComponent>
    )
  } else return children
}
