import CoursePageComponent from './CoursePageComponent'

export const CoursePageConfig = {
  routes: [
    {
      path: '/learning/course',
      component: CoursePageComponent,
      exact: true,
    },
    {
      path: '/learning/course/detail/:id',
      component: CoursePageComponent,
      exact: true,
    },
    {
      path: '/learning/course/add',
      component: CoursePageComponent,
      exact: true,
    },
    {
      path: '/learning/course/edit/:id',
      component: CoursePageComponent,
      exact: true,
    },
  ],
}
