import React, {useState} from 'react'
import {
  GreyTypography,
  ModalContent,
  ModalHeader,
  WhiteIcon,
  LongBtn,
  CapsuleContent,
} from '../../../../../GlobalStyles'

import {
  Dialog,
  Button,
  Typography,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core'
import moment from 'moment'

import {
  REJECT_OVERTIME_REQUEST,
  UPDATE_STATUS_OVERTIME_REQUST,
} from '../../../../../graphql/mutations/index'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {Link} from 'react-router-dom'
import numeral from 'numeral'
import AddEdit from '../../../../shared-components/popup/AddEditPopup'
import {POSITION_NAME, USER_ID, USER_NAME} from '../../../../../utils/globals'
import classNames from 'classnames'
import {
  compensationType,
  generateSubmissionStatus,
  getApproalType,
  getCompensationDescription,
} from './helperOvertime'

import {getLastSalary} from '../../../../directory/employee/add-employee/utilsForEmployee'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  paper: {
    width: '100%',
    // marginBottom: theme.spacing(2),
    marginBottom: '7rem',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
    padding: '0 1.6rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detailContainer: {display: 'flex', paddingBottom: '1rem'},
  w30: {width: '36%'},
  w70: {width: '64%'},
  detailLeave1: {
    marginTop: '1rem',
  },
  detailLeave2: {
    margin: '0 -1.6rem -.8rem',
    padding: '1.6rem 1.6rem .8rem',
    background: '#fff',
  },
  btnStatus: {
    textTransform: 'capitalize',
    color: '#fff !important',
    marginRight: '1rem',
  },
  selectFilter: {marginRight: 12, minWidth: '19%'},
  flexAction: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-around',
    width: '88%',
    margin: 'auto',
  },
  btnReject: {color: '#ef4d5e', border: '1.5px solid #ef4d5e'},
  flexAction2: {display: 'flex', justifyContent: 'flex-end', marginTop: 20},
  buttonSpacer: {marginRight: 12},
  pointer: {
    cursor: 'pointer',
  },
  blueSky: {
    color: '#039be5 !important',
    textDecoration: 'none',
  },
}))

export default function ModalDetailActivity(props) {
  const {selectedItem, open, handleClose, type, handleOpenActivity} = props
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [reject, setReject] = useState(false)
  const [reason, setReason] = useState('')
  const [isError, setIsError] = useState(false)

  const attachment = selectedItem?.overtime_fields?.attachment || []

  const hourStart = moment(selectedItem?.datestart).format('HH:mm')
  const hourEnd = moment(selectedItem?.dateend).format('HH:mm')

  const handleClickConfirm = type => {
    if (reject && !reason) {
      setIsError(true)
      setTimeout(() => setIsError(false), 3000)
    } else {
      setConfirmType(type)
      setPopup(true)
    }
  }

  const last_salary = getLastSalary(
    selectedItem?.people_work_placement?.people_work_salaries
  )?.value

  const handleCloseConfirm = () => {
    setPopup(false)
    handleOpenActivity()
  }

  const handleClear = () => {
    setReject(false)
    setReason('')
  }

  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [rejectOvertime] = useMutation(REJECT_OVERTIME_REQUEST)
  const [approveOvertime] = useMutation(UPDATE_STATUS_OVERTIME_REQUST)
  const onOvertimeChange = (status, id) => {
    const approvalType = getApproalType(selectedItem, status)
    let variables = {}
    if (status === 'rejected') {
      variables = {
        id: id,
        status: 'rejected',
        overtime_fields: {
          ...selectedItem.overtime_fields,
          reason,
          [approvalType]: {
            type: 'admin',
            user: {
              id: USER_ID,
              name: USER_NAME,
              title: POSITION_NAME,
            },
          },
        },
      }
      rejectOvertime({variables})
        .then(() => {
          props.refetch()
          props.refetchRequest()
          enqueueSnackbar('Item updated', {
            variant: 'success',
          })
          setTimeout(() => {
            setPopup(false)
            handleClose()
            setReason('')
            setReject(false)
          }, 300)
        })
        .catch(() => {
          enqueueSnackbar('Error when update data', {
            variant: 'error',
          })

          setTimeout(() => {
            setPopup(false)
            handleClose()
          }, 300)
        })
    } else {
      variables = {
        id,
        status:
          selectedItem?.time_overtime_policy?.second_job_profile === null ||
          selectedItem.status === 'confirm1'
            ? 'approved'
            : 'confirm1',
        overtime_fields: {
          [approvalType]: {
            type: 'admin',
            user: {
              id: USER_ID,
              name: USER_NAME,
              title: POSITION_NAME,
            },
          },
        },
      }
      approveOvertime({variables})
        .then(() => {
          props.refetch()
          props.refetchRequest()
          enqueueSnackbar('Item updated', {
            variant: 'success',
          })
          setTimeout(() => {
            setPopup(false)
            handleClose()
            setReason('')
            setReject(false)
          }, 300)
        })
        .catch(() => {
          enqueueSnackbar('Error when update data', {
            variant: 'error',
          })
          setTimeout(() => {
            setPopup(false)
            handleClose()
          }, 300)
        })
    }
  }

  const buttonAction = () => {
    return (
      <>
        <div className={classes.flexAction}>
          <LongBtn
            variant="outlined"
            className={classes.btnReject}
            onClick={() => setReject(true)}
          >
            Reject
          </LongBtn>
          <LongBtn
            variant="contained"
            color="primary"
            onClick={() => {
              handleClickConfirm('approve')
              handleClose()
            }}
          >
            Approve
          </LongBtn>
        </div>
        {reject && (
          <>
            <TextField
              variant="outlined"
              multiline
              rows="4"
              fullWidth
              placeholder="Add Rejection Reason Here"
              value={reason}
              onChange={e => setReason(e.target.value)}
              error={!reason && isError}
              helperText={!reason && isError ? 'This field is required' : ''}
            ></TextField>
            <div className={classes.flexAction2}>
              <Button className={classes.buttonSpacer} onClick={handleClear}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  handleClickConfirm('reject')
                }}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Dialog
        onClose={() => {
          handleClose()
          handleClear()
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        classes={{paper: classes.paperDialog}}
      >
        <ModalHeader>
          <div style={{display: 'flex'}}>
            <Typography variant="h6" className={classes.title}>
              Overtime Submission Detail
            </Typography>
          </div>

          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose()
              handleClear()
            }}
          >
            <WhiteIcon>close</WhiteIcon>
          </IconButton>
        </ModalHeader>
        <ModalContent>
          <div className={classes.detailLeave1}>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Name
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {selectedItem &&
                  selectedItem.people_work_placement &&
                  selectedItem.people_work_placement.global_user &&
                  selectedItem.people_work_placement.global_user.name}
              </GreyTypography>
            </div>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Position
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {selectedItem &&
                  selectedItem.people_work_placement &&
                  selectedItem.people_work_placement.company_job_profile &&
                  selectedItem.people_work_placement.company_job_profile.title}
              </GreyTypography>
            </div>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Submission Date
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {moment(selectedItem && selectedItem.date_added).format('LL')}
              </GreyTypography>
            </div>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Submission Status
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {selectedItem &&
                  generateSubmissionStatus({
                    data: selectedItem,
                    policy_type: 'time_overtime_policy',
                    field_from: 'overtime_fields',
                  })}
              </GreyTypography>
            </div>
            {((selectedItem && selectedItem.status === 'approved') ||
              (selectedItem && selectedItem.status === 'rejected')) && (
              <div className={classes.detailContainer}>
                <Typography variant="body2" className={classes.w30}>
                  Response Date
                </Typography>
                <GreyTypography variant="body2" className={classes.w70}>
                  {moment(selectedItem && selectedItem.last_modified).format(
                    'll'
                  )}
                </GreyTypography>
              </div>
            )}
          </div>
          <div className={classes.detailLeave2}>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Request ID
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {selectedItem && selectedItem.id_display}
              </GreyTypography>
            </div>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Overtime Date
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {moment(selectedItem && selectedItem.datestart).format('LL')}
              </GreyTypography>
            </div>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Long Time
              </Typography>
              <GreyTypography variant="body2">
                {hourStart} - {hourEnd}
              </GreyTypography>
              <CapsuleContent>
                {numeral(
                  Math.abs(
                    moment
                      .duration(
                        moment(selectedItem && selectedItem.datestart).diff(
                          moment(selectedItem && selectedItem.dateend)
                        )
                      )
                      .asHours()
                  )
                ).format('0,0.0')}{' '}
                Hours
              </CapsuleContent>
            </div>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Compensation
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {compensationType[selectedItem?.convert_to]}
              </GreyTypography>
            </div>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                {selectedItem?.convert_to === 'Leave'
                  ? 'Compensation Description'
                  : 'Overtime Total'}
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {getCompensationDescription({
                  selectedItem,
                  salary: last_salary,
                })}
              </GreyTypography>
            </div>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Description
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {selectedItem?.description || '-'}
              </GreyTypography>
            </div>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Attachment
              </Typography>
              <div className={classes.w70}>
                {attachment[0]
                  ? attachment.map((data, key) => {
                      return (
                        <div key={key}>
                          <a
                            component={Link}
                            href={data.link}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={classNames(
                              classes.w70,
                              classes.pointer,
                              classes.blueSky
                            )}
                          >
                            {data.name}
                          </a>
                        </div>
                      )
                    })
                  : '-'}
              </div>
            </div>
            {selectedItem && selectedItem.status === 'rejected' && (
              <div className={classes.detailContainer}>
                <Typography variant="body2" className={classes.w30}>
                  Rejected Statement
                </Typography>
                <GreyTypography variant="body2" className={classes.w70}>
                  {(selectedItem &&
                    selectedItem.overtime_fields &&
                    selectedItem.overtime_fields.reject_reason) ||
                    (selectedItem &&
                      selectedItem.overtime_fields &&
                      selectedItem.overtime_fields.overtimeFields &&
                      selectedItem.overtime_fields.overtimeFields.reason) ||
                    (selectedItem &&
                      selectedItem.overtime_fields &&
                      selectedItem.overtime_fields.reject_reason) ||
                    (selectedItem &&
                      selectedItem.overtime_fields &&
                      selectedItem.overtime_fields.reason)}
                </GreyTypography>
              </div>
            )}

            {type === 'request' && buttonAction()}
          </div>
        </ModalContent>
      </Dialog>
      <AddEdit
        open={openPopup}
        handleClose={handleCloseConfirm}
        type={confirmType}
        name={
          selectedItem &&
          selectedItem.time_overtime_policy &&
          selectedItem.time_overtime_policy.name
        }
        feature="Overtime Request"
        mutation={() =>
          onOvertimeChange(
            confirmType === 'approve' ? 'approved' : 'rejected',
            selectedItem && selectedItem.id
            // selectedItem && selectedItem.status,
            // selectedItem &&
            //   selectedItem.time_overtime_policy.second_confirm_position !== null
          )
        }
      />
    </>
  )
}
