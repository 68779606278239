import gql from 'graphql-tag'

export const ADD_NEW_LEAVE_POLICY_CHILD = gql`
  mutation addLeavePolicyChild(
    $data: [time_leave_policy_children_insert_input!]!
  ) {
    insert_time_leave_policy_children(objects: $data) {
      affected_rows
    }
  }
`

export const ADD_NEW_LEAVE_POLICY_CHILD_WITH_FILTER = gql`
  mutation(
    $parent: Int!
    $name: String!
    $infinite_quota: Boolean
    $quota: Int
    $confirm_type: String
    $first_confirm_profile: Int
    $second_confirm_profile: Int
    $gender: String
    $filter: JSON
  ) {
    LeaveChildPolicyCreate(
      parent: $parent
      name: $name
      infinite_quota: $infinite_quota
      quota: $quota
      confirm_type: $confirm_type
      first_confirm_profile: $first_confirm_profile
      second_confirm_profile: $second_confirm_profile
      gender: $gender
      filter: $filter
    ) {
      success
    }
  }
`
