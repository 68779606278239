import React, {useState} from 'react'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {WrapperSection} from '../../DetailEmployeeStyles'
import {HobbiesItem} from './HobbiesItem'

export default function Hobbies({people_profile_hobbies, user_name}) {
  const [open, setOpen] = useState(false)

  const _renderItem = (data, isDetail = false) => {
    return data.slice(0, isDetail ? data.length : 2).map((res, i) => {
      return <HobbiesItem key={`${i}`} res={res} />
    })
  }

  return (
    <>
      <WrapperSection>
        {_renderItem(people_profile_hobbies)}
        {people_profile_hobbies.length > 0 && (
          <h3 onClick={() => setOpen(true)} className="show-more">
            Tampilkan Lebih Banyak Hobi...
          </h3>
        )}
      </WrapperSection>

      <ModalDetail
        onClose={() => setOpen(false)}
        open={open}
        title={`Hobi ${user_name}`}
      >
        {_renderItem(people_profile_hobbies, true)}
      </ModalDetail>
    </>
  )
}
