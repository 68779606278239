import gql from 'graphql-tag'

export const GET_KPI_MONITORING = gql`
  query getKpiMonitoring($search: String!) {
    kpi_monitoring_frequencies(
      where: {
        _or: [
          {code: {_ilike: $search}}
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      code
      name
      description
      status
    }
  }
`

export const CHECK_CODE_MONITORING = gql`
  query checkCodeMonitoring($company: uuid, $code: String) {
    kpi_monitoring_frequencies_aggregate(
      where: {company: {_eq: $company}, code: {_eq: $code}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const CHECK_MONITORING_ALREADY_TAKEN = gql`
  query checkDeleteMonitoring($monitoring: [Int!]!) {
    kpi_lists_aggregate(where: {monitoring: {_in: $monitoring}}) {
      aggregate {
        count
      }
    }
  }
`
