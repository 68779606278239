import React, {useState} from 'react'
import {WrapperSection} from '../../DetailEmployeeStyles'
import {formatDistance, formatDistanceStrict, isSameMonth} from 'date-fns'
import {dateSplitter} from '../../utilsForDetails'
import ExperienceItem from './ExperienceItem'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import AddEditExperience from './AddEditExperience'
import {ButtonAdd} from '../../SharedDetailEmployee'

export default function Experience({
  people_profile_experiences,
  user_name,
  userIdDetail,
  enqueueSnackbar,
  refetch,
  INITIAL_ACTION_STATE,
  action,
  setAction,
}) {
  const [open, setOpen] = useState(false)

  const handleCloseAction = () => {
    if (action.isFromModal) {
      setOpen(true)
    }
    setAction(INITIAL_ACTION_STATE)
  }
  const handleOpenForm = ({actionType, data = null, isFromModal}) => {
    if (isFromModal) {
      setOpen(false)
    }
    setAction(e => ({...e, open: true, actionType, data, isFromModal}))
  }

  const _renderData = (data, limit = null) => {
    return data.slice(0, limit || data.length).map((res, i) => {
      let result = ''
      const issued = dateSplitter(res.from)
      if (!res.is_present) {
        const expired = dateSplitter(res.to)
        result = formatDistance(
          new Date(issued[0], issued[1], issued[2]),
          new Date(expired[0], expired[1], expired[2])
        )
      } else {
        const date = new Date()
        const startDate = new Date(issued[0], issued[1] - 1, issued[2])
        const _isSameMonth = isSameMonth(date, startDate)
        const firstDayOfStartDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          1
        )

        result = formatDistanceStrict(firstDayOfStartDate, date, {
          unit: _isSameMonth ? 'day' : 'month',
          ...(!_isSameMonth && {roundingMethod: 'floor'}),
        })
      }
      return (
        <ExperienceItem
          onEdit={() =>
            handleOpenForm({
              actionType: 'edit',
              data: res,
              isFromModal: !limit,
            })
          }
          key={`${i}-itm`}
          result={result}
          res={res}
        />
      )
    })
  }

  return (
    <>
      <WrapperSection>
        {_renderData(people_profile_experiences, 2)}
        {people_profile_experiences.length > 0 && (
          <h3 onClick={() => setOpen(true)} className="show-more">
            Tampilkan Lebih Banyak Pengalaman...
          </h3>
        )}
      </WrapperSection>

      <ModalDetail
        onClose={() => setOpen(false)}
        open={open}
        title={`Pengalaman ${user_name}`}
      >
        {_renderData(people_profile_experiences)}
        <ButtonAdd
          label="Pengalaman"
          onClick={() =>
            handleOpenForm({
              actionType: 'add',
              isFromModal: true,
            })
          }
        />
      </ModalDetail>
      <AddEditExperience
        setAction={setAction}
        refetch={refetch}
        enqueueSnackbar={enqueueSnackbar}
        userIdDetail={userIdDetail}
        open={action.open}
        data={action.data}
        onClose={handleCloseAction}
        actionType={action.actionType}
      />
    </>
  )
}
