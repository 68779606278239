import gql from 'graphql-tag'

export const ADD_HOLDING_VERSION = gql`
  mutation addHoldingVersion($object: [holding_version_insert_input!]!) {
    insert_holding_version(objects: $object) {
      returning {
        id
      }
    }
  }
`
