import gql from 'graphql-tag'

export const GET_STATUS_EXPORT_BALOON = gql`
  query($company: uuid, $table: String, $filter: jsonb) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        filter: {_eq: $filter}
        _or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]
      }
      limit: 1
      order_by: [{id: desc}]
    ) {
      id
      status
      data_url
      date_added
      reports
    }
  }
`

export const GET_EXPORT_DISABLED_BUTTON = gql`
  query($company: uuid, $table: String, $filter: jsonb) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        filter: {_eq: $filter}
        status: {_neq: "FAILED"}
      }
      limit: 1
      order_by: [{id: desc}]
    ) {
      id
      date_added
    }
  }
`
