import {TEMPLATES_IMPORT_PERSONAL_DATA} from '../constant'

export const handlerImportMultiple = (files = []) => {
  const result = []
  TEMPLATES_IMPORT_PERSONAL_DATA.forEach(({templateName, tableName}) => {
    files.forEach(({name, url}) => {
      if (name.toLowerCase().includes(templateName.toLowerCase())) {
        result.push({
          tableName,
          url,
        })
      }
    })
  })
  return result
}

export const tableNameChecker = fileName => {
  let result = false
  TEMPLATES_IMPORT_PERSONAL_DATA.forEach(({templateName}) => {
    if (fileName.toLowerCase().includes(templateName.toLowerCase())) {
      result = false
    } else result = true
  })
  return result
}
