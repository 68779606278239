import React, {useState, useEffect} from 'react'
import {
  Paper,
  makeStyles,
  Typography,
  TextField,
  MenuItem,
  Divider,
  Avatar,
  Grid,
} from '@material-ui/core'
import {EnhancedToolbar} from '../../../../GlobalStyles'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import DialogDetail from './DialogBadgeUser'

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  content: {
    padding: 24,
  },
  input: {
    marginRight: '1rem',
    width: '158px',
  },
  userWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  divName: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
  },
  position: {
    color: '#a9a8a8',
  },
  seeAll: {
    textAlign: 'right',
    marginTop: 15,
    cursor: 'pointer',
    color: '#039be5',
  },
}))

const CommitteeComponent = props => {
  const classes = useStyles()

  const {data, position, setCondList, condList} = props

  const [positions, setPositions] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (position) {
      setPositions(position.talent_candidate_groups)
    }
  }, [position])

  const changeFilters = name => e => {
    setCondList({...condList, [name]: e.target.value})
  }

  return (
    <div className={classes.root}>
      <Paper>
        <EnhancedToolbar>
          <Typography variant="h6" color="primary">
            Commmittee
          </Typography>
          <div
            style={{display: 'flex', marginTop: '1rem', marginBottom: '1rem'}}
          >
            <TextField
              size="small"
              variant="outlined"
              className={classes.input}
              select
              label="Position"
              value={condList.group || 'all'}
              onChange={changeFilters('group')}
            >
              <MenuItem value="all">All Position</MenuItem>
              {positions &&
                positions.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {option?.company_job_profile?.title}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </EnhancedToolbar>
        <Divider />
        <div className={classes.content}>
          <Grid container spacing={3}>
            {data && data.talent_committees.length > 0 ? (
              data.talent_committees.slice(0, 6).map((val, key) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    key={key}
                    className={classes.userWrapper}
                  >
                    <Avatar src={val?.global_user?.avatar} />
                    <div className={classes.divName}>
                      <Typography variant="body1">
                        {val?.global_user?.name}
                      </Typography>
                      <Typography variant="body2" className={classes.position}>
                        {val.global_user.people_work_placements.length > 0
                          ? val.global_user.people_work_placements[0]
                              ?.company_job_profile?.title
                          : ''}
                      </Typography>
                    </div>
                  </Grid>
                )
              })
            ) : (
              <NoDataListComponent />
            )}
          </Grid>
          {data.talent_committees.length > 5 && (
            <Typography
              variant="body1"
              className={classes.seeAll}
              onClick={() => setOpen(true)}
            >
              See All
            </Typography>
          )}
        </div>
      </Paper>
      <DialogDetail
        open={open}
        handleClose={() => setOpen(false)}
        data={data}
        positions={positions}
        condList={condList}
        changeFilters={changeFilters}
        detail
        committee
      />
    </div>
  )
}

export default CommitteeComponent
