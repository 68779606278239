import {useQuery} from '@apollo/react-hooks'
import {MenuItem, TextField} from '@material-ui/core'
import React from 'react'
import {TitleName} from '../../../../../GlobalStyles'
import {GET_LIST_LEAVE_PAY_WITH_LEAVE} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import {selectProps} from '../../../../../utils/helpers'

export default function PayWithLeave({
  classes,
  leavePolicyChildren,
  setLeavePolicyChildren,
  minimumHour,
  setMinimumHour,
  errorLeave,
  errorHour,
}) {
  const {data} = useQuery(GET_LIST_LEAVE_PAY_WITH_LEAVE, {
    variables: {
      company: COMPANY_ID,
    },
  })

  const handleChangeLeave = e => {
    setLeavePolicyChildren(e.target.value)
  }

  const handleChangeMinimumHour = e => {
    if (!isNaN(e.target.value)) {
      const min = 0
      let value = parseInt(e.target.value, 10)
      if (value < min) value = min
      setMinimumHour(value)
    }
  }

  return (
    <div>
      <TitleName>Type Leave *</TitleName>
      <TextField
        id="type-leave"
        InputProps={{
          classes: {
            input: classes.outlinedInput,
          },
        }}
        error={errorLeave}
        helperText={errorLeave ? 'This field is required' : ''}
        style={{width: 286}}
        value={leavePolicyChildren}
        onChange={handleChangeLeave}
        select
        margin="normal"
        variant="outlined"
        SelectProps={selectProps(leavePolicyChildren)}
      >
        <MenuItem value="" disabled>
          Select type leave
        </MenuItem>
        {data?.time_leave_policy_children?.map(({name, id}) => {
          return (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          )
        })}
      </TextField>

      <TitleName style={{marginTop: 4}}>Minimum Hour *</TitleName>
      <TextField
        id="minimum-hour"
        type="number"
        margin="normal"
        variant="outlined"
        error={errorHour}
        style={{width: 286}}
        placeholder="Set minimum overtime hour"
        helperText={
          errorHour
            ? 'This field is required'
            : '*Minimum overtime hour equals to 1 day leave'
        }
        value={minimumHour}
        onChange={handleChangeMinimumHour}
        InputProps={{
          classes: {
            input: classes.outlinedInput,
          },
        }}
      />
    </div>
  )
}
