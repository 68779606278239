import {Divider, makeStyles} from '@material-ui/core'
import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {PaperContainer, RootContainer} from '../../../../../../GlobalStyles'
import {
  GET_LIST_SECOND_SUPERVISOR_ASESI,
  GET_LIST_SUPERVISOR_ASESI,
  GET_LIST_PEER_ASESI,
  GET_TOTAL_QUESTION,
  GET_LIST_SECOND_SUBORDINATE_ASESI,
  GET_LIST_SUBORDINATE_ASESI,
} from '../../../../../../graphql/queries'
import RaterItemDetailAsesi from './RaterItemDetailAsesi'
import ToolbarDetailAsesi from './ToolbarDetailAsesi'
import {useHistory} from 'react-router-dom'
import LoadingItemTable from '../shared-feedback/LoadingItemTable'
import NoDataListComponent from '../../../../../shared-components/NoDataListComponent'

const useStyles = makeStyles(() => ({
  contentContainer: {padding: '24px 28px'},
  titlePage: {fontSize: 18, fontWeight: '600', margin: '0 0 24px'},
}))

export default function DetailAsesiPage({state, searchText}) {
  const classes = useStyles()
  const {location} = useHistory()

  const {weight_settings} = state?.surveyDetail?.multirater_surveys?.[0] || {}

  const {data, loading, error} = useQuery(GET_TOTAL_QUESTION, {
    fetchPolicy: 'cache-and-network',
    variables: {survey: state.survey},
  })

  const raterListData = Object.keys(weight_settings || {})

  const RATER_LIST = [
    {
      title: 'Atasan Pimpinan',
      query: GET_LIST_SECOND_SUPERVISOR_ASESI,
      show: raterListData.includes('second_supervisor'),
    },
    {
      title: 'Pimpinan',
      query: GET_LIST_SUPERVISOR_ASESI,
      show: raterListData.includes('supervisor'),
    },
    {
      title: 'Rekan Kerja',
      query: GET_LIST_PEER_ASESI,
      show: raterListData.includes('peer'),
    },
    {
      title: 'Pegawai dibawahnya',
      query: GET_LIST_SUBORDINATE_ASESI,
      show: raterListData.includes('subordinate'),
    },
    {
      title: 'Pegawai dua tingkat dibawahnya',
      query: GET_LIST_SECOND_SUBORDINATE_ASESI,
      show: raterListData.includes('second_subordinate'),
    },
  ]

  return (
    <RootContainer>
      <PaperContainer>
        <ToolbarDetailAsesi
          title={`${location.state.userName} Detail Umpan Balik`}
        />
        <Divider />
        <div className={classes.contentContainer}>
          <h2 className={classes.titlePage}>Daftar Penilai</h2>
          {!loading &&
            RATER_LIST.map(({title, query, show}) => (
              <RaterItemDetailAsesi
                key={title}
                show={show}
                title={title}
                query={query}
                user={state.user_id}
                survey={state.survey}
                searchText={searchText}
                totalQuestion={data?.total_question?.aggregate?.count || 0}
              />
            ))}
          {loading && (
            <LoadingItemTable
              total={5}
              variant="rect"
              width="100%"
              height={67}
              style={{marginBottom: 12}}
            />
          )}
          {!loading && RATER_LIST.filter(({show}) => show)?.length === 0 && (
            <NoDataListComponent message="Currently, no rater listed for this asesi" />
          )}
        </div>
        {error && <p>{JSON.stringify(error)}</p>}
      </PaperContainer>
    </RootContainer>
  )
}
