import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'
import CompanyFileList from './CompanyFileList'
import UploadFile from './UploadFile'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'company-file':
      return <CompanyFileList state={state} searchText={searchText} />
    case 'upload-file':
      return <UploadFile />
    default:
      return <CompanyFileList state={state} searchText={searchText} />
  }
}

const CompanyFilePage = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  return (
    <div>
      <Card
        icon="insert_drive_file"
        title="Digital Document"
        onSearch={onSearch}
      >
        <PageContainer>
          {renderComponent(active, state, searchText)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default CompanyFilePage
