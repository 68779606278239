import ConfiguringPageComponent from './ConfiguringPageComponent'

export const ConfiguringPageConfig = {
  routes: [
    {
      path: '/group/config',
      component: ConfiguringPageComponent,
      state: 'group-configuring',
      exact: true,
    },
    {
      path: '/group/config/add',
      component: ConfiguringPageComponent,
      state: 'add-version',
      exact: true,
    },
    {
      path: '/group/config/add-category',
      component: ConfiguringPageComponent,
      state: 'add-category',
      exact: true,
    },
    {
      path: '/group/config/add-cluster',
      component: ConfiguringPageComponent,
      state: 'add-cluster',
      exact: true,
    },
    {
      path: '/group/config/add-subcluster',
      component: ConfiguringPageComponent,
      state: 'add-subcluster',
      exact: true,
    },
    {
      path: '/group/config/add-class',
      component: ConfiguringPageComponent,
      state: 'add-class',
      exact: true,
    },
    {
      path: '/group/config/:id',
      component: ConfiguringPageComponent,
      state: 'detail-version',
      exact: true,
    },
    {
      path: '/group/config/category/:id',
      component: ConfiguringPageComponent,
      state: 'edit-category',
      exact: true,
    },
    {
      path: '/group/config/class/:id',
      component: ConfiguringPageComponent,
      state: 'edit-class',
      exact: true,
    },
    {
      path: '/group/config/cluster/:id',
      component: ConfiguringPageComponent,
      state: 'edit-cluster',
      exact: true,
    },
    {
      path: '/group/config/subcluster/:id',
      component: ConfiguringPageComponent,
      state: 'edit-subcluster',
      exact: true,
    },
  ],
}
