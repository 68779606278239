import React from 'react'
import {withRouter} from 'react-router-dom'
import {
  CircleTag,
  FlexCenter,
  FormChildContainerFull,
  FormChildInputPad15,
  FormChildTitle,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import {
  FlexStart,
  TextBlack,
  TextBlueBold,
  TextGrey,
} from '../../../../organization/OrganizationPageStyles'
import moment from 'moment'

const PreviewEvent = props => {
  const {value, preview, recurringCheck} = props
  const d1 = new Date(value.date)
  const [limitNever, setLimitNever] = React.useState(5)
  const [changeLimitAll, setChangeLimitAll] = React.useState(true)
  const [limitAll, setLimitAll] = React.useState([])
  const [finalPreview, setFinalPreview] = React.useState([])

  const monthDiff = (d1, d2) => {
    var months
    months = (d2.getFullYear() - d1.getFullYear()) * 12
    months -= d1.getMonth() + 1
    months += d2.getMonth()
    // edit: increment months if d2 comes later in its month than d1 in its month
    if (d2.getDate() >= d1.getDate()) months++
    // end edit
    return months <= 0 ? 0 : months
  }
  const dayDiff = (d1, d2) => {
    const date1 = new Date(d1)
    const date2 = new Date(d2)
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  }
  const dateDiffInYears = (dateold, datenew) => {
    var ynew = datenew.getFullYear()
    var mnew = datenew.getMonth()
    var dnew = datenew.getDate()
    var yold = dateold.getFullYear()
    var mold = dateold.getMonth()
    var dold = dateold.getDate()
    var diff = ynew - yold
    if (mold > mnew) diff--
    else {
      if (mold === mnew) {
        if (dold > dnew) diff--
      }
    }
    return diff
  }
  React.useEffect(() => {
    const previewDate = []
    if (recurringCheck) {
      if (preview) {
        let loop = 0
        if (value.recur_end_opt === '0') {
          loop = limitNever
        } else if (value.recur_end_opt === '1') {
          if (value.recur_end_date !== null) {
            if (value.recurring === 1) {
              loop = Math.floor(
                dayDiff(d1, value.recur_end_date) / parseInt(value.recur_time_x)
              )
            } else if (value.recurring === 2) {
              loop = Math.floor(
                dayDiff(d1, value.recur_end_date) /
                  (parseInt(value.recur_time_x) * 7)
              )
            } else if (value.recurring === 3) {
              loop = Math.floor(
                monthDiff(d1, value.recur_end_date) /
                  parseInt(value.recur_time_x)
              )
            } else if (value.recurring === 4) {
              loop = Math.floor(
                dateDiffInYears(d1, value.recur_end_date) /
                  parseInt(value.recur_time_x)
              )
            }
          }
        } else if (value.recur_end_opt === '2') {
          loop = parseInt(value.recur_end_x) - 1
        }

        previewDate.push({
          year: moment(new Date(d1)).format('YYYY'),
          day: moment(new Date(d1)).format('D'),
          detail: moment(new Date(d1)).format('MMMM, dddd'),
        })

        if (loop !== 0) {
          if (value.recurring === 1) {
            for (let i = 0; i < loop; i++) {
              d1.setDate(d1.getDate() + parseInt(value.recur_time_x))
              previewDate.push({
                year: moment(new Date(d1)).format('YYYY'),
                day: moment(new Date(d1)).format('D'),
                detail: moment(new Date(d1)).format('MMMM, dddd'),
              })
            }
          }
          if (value.recurring === 2) {
            for (let i = 0; i < loop; i++) {
              d1.setDate(d1.getDate() + parseInt(value.recur_time_x) * 7)
              previewDate.push({
                year: moment(new Date(d1)).format('YYYY'),
                day: moment(new Date(d1)).format('D'),
                detail: moment(new Date(d1)).format('MMMM, dddd'),
              })
            }
          }
          if (value.recurring === 3) {
            const day = moment(value.date).format('D')
            for (let i = 0; i < loop; i++) {
              // console.log(d1)
              d1.setDate(1)
              d1.setMonth(d1.getMonth() + parseInt(value.recur_time_x))
              if (parseInt(day) >= 30) {
                // console.log(d1)
                const day2 = moment(d1)
                  .endOf('month')
                  .format('D')
                if (parseInt(day2) < parseInt(day)) {
                  d1.setDate(day2)
                } else {
                  d1.setDate(day)
                }
              } else {
                d1.setDate(day)
              }

              previewDate.push({
                year: moment(new Date(d1)).format('YYYY'),
                day: moment(new Date(d1)).format('D'),
                detail: moment(new Date(d1)).format('MMMM, dddd'),
              })
            }
          }

          if (value.recurring === 4) {
            for (let i = 0; i < loop; i++) {
              d1.setYear(d1.getFullYear() + parseInt(value.recur_time_x))
              previewDate.push({
                year: moment(new Date(d1)).format('YYYY'),
                day: moment(new Date(d1)).format('D'),
                detail: moment(new Date(d1)).format('MMMM, dddd'),
              })
            }
          }
        }
      }
    } else {
      previewDate.push({
        year: moment(new Date(d1)).format('YYYY'),
        day: moment(new Date(d1)).format('D'),
        detail: moment(new Date(d1)).format('MMMM, dddd'),
      })
    }

    const finalPreview1 = []
    const limitAll1 = []
    const dataTahun = []
    previewDate.map(row => {
      if (dataTahun.filter(val => val.year === row.year).length === 0) {
        dataTahun.push({year: row.year})
        limitAll1.push({[row.year]: 3})
      }
    })
    dataTahun.forEach(value => {
      value.items = previewDate.filter(val => val.year === value.year)
      finalPreview1.push(value)
    })
    setFinalPreview(finalPreview1)
    if (changeLimitAll) {
      setChangeLimitAll(false)
      setLimitAll(limitAll1)
    } else {
      if (limitAll1.length !== limitAll.length) {
        const limit2 = []
        limitAll1.map((row, index) => {
          console.log(limitAll[index])
          if (limitAll[index] === undefined) {
            limit2.push(row)
          } else {
            limit2.push(limitAll[index])
          }
        })
        setLimitAll(limit2)
      }
    }
  }, [value, limitNever])

  const handleSeeMore = year => {
    const data = limitAll.filter(row => {
      row[year] = row[year] + 5
      return row
    })
    if (value.recur_end_opt === '0') {
      setLimitNever(limitNever + 6)
    }
    setLimitAll(data)
  }

  const handleHide = year => {
    const data = limitAll.filter(row => {
      row[year] = 3
      return row
    })
    setLimitAll(data)
  }

  // console.log(finalPreview)
  return (
    <>
      {finalPreview.length >= 1 &&
        finalPreview.map((row, index) => {
          return (
            <FormChildContainerFull id="date" key={index}>
              <FormChildTitle>
                <TitleNumber>{row.year}</TitleNumber>
              </FormChildTitle>
              {row.items
                .slice(0, limitAll[index][row.year])
                .map((row1, index1) => (
                  <>
                    <FormChildInputPad15>
                      <FormChildTitle key={index1}>
                        <TitleNumber>{row1.day}</TitleNumber>
                        <TitleName>{row1.detail}</TitleName>
                      </FormChildTitle>
                      <hr />
                      <FormChildInputPad15>
                        <FlexStart>
                          <CircleTag style={{backgroundColor: '#ef4d5e'}} />
                          <TextGrey style={{marginLeft: '30px', width: '90px'}}>
                            All Day
                          </TextGrey>
                          <TextBlack>{value.name}</TextBlack>
                        </FlexStart>
                      </FormChildInputPad15>
                    </FormChildInputPad15>
                  </>
                ))}
              {limitAll[index][row.year] < row.items.length && (
                <FormChildTitle>
                  <FlexCenter>
                    <TextBlueBold
                      onClick={() => {
                        handleSeeMore(row.year)
                      }}
                    >
                      See More
                    </TextBlueBold>
                  </FlexCenter>
                </FormChildTitle>
              )}
              {limitAll[index][row.year] > row.items.length &&
                row.items.length > 3 && (
                  <FormChildTitle>
                    <FlexCenter>
                      <TextBlueBold
                        onClick={() => {
                          handleHide(row.year)
                        }}
                      >
                        hide
                      </TextBlueBold>
                    </FlexCenter>
                  </FormChildTitle>
                )}
            </FormChildContainerFull>
          )
        })}
    </>
  )
}

export default withRouter(PreviewEvent)
