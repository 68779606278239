import {useMutation} from '@apollo/react-hooks'
import React, {useEffect, useState} from 'react'
import {
  ADD_LICENSES,
  UPDATE_LICENSES,
  DELETE_LICENSES,
} from '../../../../../../graphql/mutations'
import DeletePopup from '../../../../../shared-components/popup/DeletePopup'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../DetailEmployeeStyles'
import {SwitchForm, ButtonSubmit} from '../../SharedDetailEmployee'
import FormInput from '../ShareComponents-detail/FormInput'
import {isErrorForm} from '../ShareComponents-detail/helperDetail.jsx'

const INITIAL_VALUES_STATE = {
  user: null,
  is_shared: false,
  name: null,
  type: null,
  level: null,
  issuer_name: null,
  date_issued: undefined,
  date_expired: undefined,
  can_expire: false,
  credential_id: null,
  credential_url: null,
  description: null,
  attachments: [],
}

const HELPER_TEXT = 'Bagian ini diperlukan'

export default function AddEditLicenses({
  onClose,
  data,
  open,
  actionType = '',
  userIdDetail,
  enqueueSnackbar,
  refetch,
  setAction,
}) {
  const [values, setValues] = useState(INITIAL_VALUES_STATE)
  const [errorState, setErrorState] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [addlicenses] = useMutation(ADD_LICENSES)
  const [updateLicenses] = useMutation(UPDATE_LICENSES)
  const [deleteLicenses] = useMutation(DELETE_LICENSES)

  useEffect(() => {
    if (actionType === 'edit' && open) {
      setValues(e => ({
        ...e,
        user: userIdDetail,
        is_shared: data.is_shared,
        name: data.name,
        type: data.type,
        level: data.level,
        issuer_name: data.issuer_name,
        date_issued: data.date_issued,
        date_expired: data.date_expired,
        can_expire: !data.can_expire,
        credential_id: data.credential_id,
        credential_url: data.credential_url,
        description: data.description,
        attachments: data.attachments,
      }))
    } else {
      setValues(e => ({
        ...e,
        user: userIdDetail,
      }))
    }
  }, [open, actionType])

  const fieldsList = [
    {
      label: 'Nama Pelatihan/Lisensi/Sertifikasi*',
      placeholder: 'Tambahkan nama Pelatihan/Lisensi/Sertifikasi',
      fieldName: 'name',
      type: 'textfield',
      required: true,
      value: values.name,
      error: !values.name,
    },
    {
      label: 'Tipe Pelatihan/Lisensi/Sertifikasi*',
      placeholder: 'Pilih tipe Pelatihan/Lisensi/Sertifikasi',
      fieldName: 'type',
      type: 'select',
      required: true,
      value: values.type,
      error: !values.type,
      option: [
        {name: 'Fungsional', value: 'Fungsional'},
        {name: 'Jabatan', value: 'Jabatan'},
        {name: 'Non-Formal', value: 'Non-Formal'},
      ],
    },
    {
      label: 'Tingkatan Pelatihan/Lisensi/Sertifikasi*',
      placeholder: 'Pilih tingkatan Pelatihan/Lisensi/Sertifikasi',
      fieldName: 'level',
      type: 'select',
      required: true,
      value: values.level,
      error: !values.level,
      option: [
        {name: 'Perusahaan', value: 'Perusahaan'},
        {name: 'Nasional', value: 'Nasional'},
        {name: 'Internasional', value: 'Internasional'},
        {name: 'Non-Formal', value: 'Non-Formal'},
      ],
    },
    {
      label: 'Organisasi Penerbit*',
      placeholder: 'Tambahkan organisasi',
      fieldName: 'issuer_name',
      type: 'textfield',
      value: values.issuer_name,
      required: true,
      error: true,
    },
    {
      type: 'date',
      required: true,
      label: ['Tanggal Terbit*', 'Tanggal Berakhir*'],
      labelDate: 'Sertifikat masih belum berakhir',
      fieldName: ['date_issued', 'date_expired', 'can_expire'],
      value: [values.date_issued, values.date_expired, values.can_expire, true],
      error: [!values.date_issued, !values.date_expired],
    },
    {
      label: 'Nomor Sertifikat',
      placeholder: 'Tambahkan nomor sertifikat',
      fieldName: 'credential_id',
      type: 'textfield',
      value: values.credential_id,
    },
    {
      label: 'Tautan Sertifikat',
      placeholder: 'Tambahkan tautan sertifikat',
      fieldName: 'credential_url',
      type: 'textfield',
      value: values.credential_url,
    },
    {
      label: 'Keterangan',
      placeholder: 'Tambahkan Keterangan',
      fieldName: 'description',
      type: 'textfield',
      multiline: true,
      rows: 6,
      value: values.description,
      error: true,
    },
    {
      label: 'Lampiran',
      fieldName: 'attachment',
      type: 'attachment',
      attachments: values.attachments,
      error: true,
      multiple: true,
    },
  ]

  const handleChange = ({fieldName, value}, target, ekstra) => {
    if (target === 'date') {
      setValues(e => ({
        ...e,
        [ekstra]: value,
      }))
    } else {
      setValues(e => ({
        ...e,
        [fieldName]: value,
      }))
    }
  }

  const handleClose = () => {
    onClose()
    setValues(INITIAL_VALUES_STATE)
  }

  const handleSave = async () => {
    const isError = await isErrorForm(fieldsList)
    if (isError) {
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000)
    } else {
      // const _initalSalary = await changeToNumber(values.initial_salary)
      // const _ilastSalary = await changeToNumber(values.last_salary)
      values.can_expire = !values.can_expire
      values.date_expired = !values.can_expire ? null : values.date_expired
      const objects = values
      if (actionType === 'add') {
        addlicenses({
          variables: {objects},
        })
          .then(() => {
            refetch()
            enqueueSnackbar(
              'Menambahkan Pelatihan, Lisensi dan Sertifikasi Berhasil',
              {
                variant: 'success',
                autoHideDuration: 2000,
              }
            )
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar(
              'Menambahkan Pelatihan, Lisensi dan Sertifikasi Gagal, silahkan coba lagi nanti',
              {
                variant: 'error',
                autoHideDuration: 2000,
              }
            )
          })
      } else {
        updateLicenses({
          variables: {
            id: data.id,
            objects: objects,
          },
        })
          .then(() => {
            refetch()
            enqueueSnackbar(
              'Mengubah Pelatihan, Lisensi dan Sertifikasi Berhasil',
              {
                variant: 'success',
                autoHideDuration: 2000,
              }
            )
            handleClose()
          })
          .catch(() => {
            enqueueSnackbar(
              'Mengubah Pelatihan, Lisensi dan Sertifikasi Gagal, silahkan coba lagi nanti',
              {
                variant: 'error',
                autoHideDuration: 2000,
              }
            )
          })
      }
    }
  }

  const handleDelete = () => {
    deleteLicenses({
      variables: {
        id: data.id,
        user: userIdDetail,
      },
    })
      .then(() => {
        refetch()
        setOpenDelete(false)
        enqueueSnackbar(
          'Menghapus Pelatihan, Lisensi dan Sertifikasi Berhasil',
          {
            variant: 'success',
            autoHideDuration: 2000,
          }
        )
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar(
          'Menghapus Pelatihan, Lisensi dan Sertifikasi Gagal, silahkan coba lagi nanti',
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        )
      })
  }

  const handleOpenDelete = () => {
    setOpenDelete(true)
    setAction(e => ({...e, open: false}))
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setAction(e => ({...e, open: true}))
  }

  return (
    <>
      <ModalDetail
        onDelete={actionType === 'edit' && handleOpenDelete}
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        title={
          (actionType === 'add'
            ? 'Tambahkan'
            : actionType === 'edit'
            ? 'Ubah'
            : '') + ' Pelatihan, Lisensi & Sertifikasi'
        }
      >
        <div
          style={{
            padding: '25px 26px 16px 16px',
            borderBottom: 'solid 1px #e5e5e5',
          }}
        >
          <SwitchForm
            text="Berbagi dengan jaringan:"
            value={values.is_shared}
            labelPlacement="start"
            onChange={e =>
              handleChange({fieldName: 'is_shared', value: e.target.checked})
            }
          />
        </div>
        <ModalAddEditWraper>
          <FormInput
            fieldsList={fieldsList}
            open={open}
            setValues={setValues}
            values={values}
            errorState={errorState}
            HELPER_TEXT={HELPER_TEXT}
          />
        </ModalAddEditWraper>
        <ButtonSubmit onCancel={handleClose} onSave={handleSave} />
      </ModalDetail>
      <DeletePopup
        open={openDelete}
        handleClose={handleCloseDelete}
        name={'Training, License and Certification'}
        mutation={handleDelete}
      />
    </>
  )
}
