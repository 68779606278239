import styled from 'styled-components'
import {FormControlLabel, TextField, Typography} from '@material-ui/core'
import {KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers'

export const TableOptions = {
  search: false,
  selection: true,
  sorting: true,
  headerStyle: {
    fontWeight: 700,
    lineHeight: 1.43,
    wordBreak: 'normal',
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
  selectionProps: () => ({
    color: 'primary',
  }),
  pageSize: 20,
  pageSizeOptions: [20, 50, 100],
}

export const TitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-right: 1rem;
  color: #014a62;
  margin-bottom: 1.6rem;
`

export const FormControlLabelStyled = styled(FormControlLabel)`
  & > .MuiFormControlLabel-label {
    font-size: 14px;
  }
`

export const TextFieldStyled = styled(TextField)`
  & > .MuiInputBase-formControl {
    font-size: 14px;
    padding-right: 8px;
    cursor: pointer;

    & > input {
      cursor: pointer;
    }
  }
`

export const DatePickerStyled = styled(KeyboardDatePicker)`
  & > .MuiInputBase-formControl {
    font-size: 14px;
  }
`

export const LabelFilterTabs = styled(Typography)`
  width: 150px;
  padding-right: 5px;
  height: 15px;
  font-family: Muli;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`

export const TimePickerStyled = styled(KeyboardTimePicker)`
  & > .MuiInputBase-formControl {
    font-size: 14px;
  }
`
export const InfoEmployeeUpdate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #014a62;
  background-color: #c5f1ff;
  margin-bottom: 30px;
  .icon {
    margin-right: 22px;
    font-size: 20px;
  }
  .text {
    color: #014a62;
    margin: 0px;
    font-size: 14px;
    font-weight: normal;
  }
`
export const WrapperDisable = styled.div`
  width: ${props => (props.widthAuto ? 'auto' : '100%')};
  .Mui-disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
  }
  .MuiFormHelperText-root {
    background-color: #fff !important;
  }
`

export const TextError = styled.p`
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  padding: 0 0 0 29.6px;
`
