import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import ListComponent from './list/ListComponent'
import IndividualSummaryDetail from './detail/IndividualSummaryDetail'
import {ButtonFilter, BlueIcon, FilterText} from '../../../../src/GlobalStyles'
import {Filter} from '@worklifebeyond/wlb-utils-components'
import {
  anchorOrigin,
  listFilter,
  transformOrigin,
} from './IndividualSummaryFilterData'
import {IndividualSummaryContainer} from './IndividualSummaryPageStyle'

const renderComponent = (activeComponent, state, searchText, filterData) => {
  switch (activeComponent) {
    case 'individual-summary':
      return (
        <ListComponent
          state={state}
          searchText={searchText}
          filterData={filterData}
        />
      )
    case 'individual-summary-detail':
      return <IndividualSummaryDetail />
    default:
      return <ListComponent searchText={searchText} filterData={filterData} />
  }
}

const IndividualSummaryPage = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }
  // const searchable = props.location.pathname.split('/')[3] ? false : true
  const [searchText, setSearchText] = useState('')
  const [filterData, setFilterData] = useState({})

  const onSearch = search => {
    setSearchText(search)
  }

  const handleApply = res => {
    const generateIdFilter = data => {
      const _data = {}
      for (const property in data) {
        if (Array.isArray(data[property])) {
          _data[property] = data[property][0]
            ? data[property].map(itm => itm.value)
            : undefined
        }
      }
      return _data
    }
    const include = generateIdFilter(res[0])
    const exclude = generateIdFilter(res[1])
    setFilterData({
      include,
      exclude,
    })
  }

  return (
    <div>
      <Card
        icon="bar_chart"
        title="Ringkasan Kinerja Individu"
        onSearch={onSearch}
        longTitle
        endAdornment={
          <Filter
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            id="filter-goals-wlb"
            onApply={handleApply}
            listFilter={listFilter}
            language="indonesian"
          >
            <ButtonFilter position="end">
              <BlueIcon>filter_list</BlueIcon>
              <FilterText variant="body2">Saring</FilterText>
            </ButtonFilter>
          </Filter>
        }
      >
        <IndividualSummaryContainer>
          {renderComponent(active, state, searchText, filterData)}
        </IndividualSummaryContainer>
      </Card>
    </div>
  )
}

export default IndividualSummaryPage
