import OrganizationUnitPage from './OrganizationUnitPageComponent'

export const OrganizationUnitPageConfig = {
  routes: [
    {
      path: '/organization/organization-unit',
      component: OrganizationUnitPage,
      state: 'organization-unit',
      exact: true,
    },
    {
      path: '/organization/organization-unit/structure',
      component: OrganizationUnitPage,
      state: 'structure-organization-unit',
      exact: false,
    },
    {
      path: '/organization/organization-unit/add',
      component: OrganizationUnitPage,
      state: 'add-organization-unit',
      exact: false,
    },
    {
      path: '/organization/organization-unit/edit',
      component: OrganizationUnitPage,
      state: 'edit-organization-unit',
      exact: false,
    },
  ],
}
