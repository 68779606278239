import CalenderPageComponent from './CalenderPageComponent'

export const CalenderPageConfig = {
  routes: [
    {
      path: '/time/calendar',
      component: CalenderPageComponent,
      state: 'activity',
      exact: true,
    },
    {
      path: '/time/calendar/activity',
      component: CalenderPageComponent,
      state: 'activity',
      exact: false,
      refreshContext: true,
    },
    {
      path: '/time/calendar/add-event',
      component: CalenderPageComponent,
      state: 'add-event',
      exact: false,
    },
    {
      path: '/time/calendar/edit-event',
      component: CalenderPageComponent,
      state: 'edit-event',
      exact: false,
    },
  ],
}
