import React, {useState} from 'react'
import MaterialTable from 'material-table'
import moment from 'moment'
import {Button, CircularProgress, Divider, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {useSubscription, useMutation} from '@apollo/react-hooks'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
} from '../../../../GlobalStyles'
import {CenterWrapper} from '../../../financial/payroll/PayrollPageStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {useSnackbar} from 'notistack'
import {TableOptions} from '../../../shared-components/table/TableOptions'
import {GET_VERSION_LIST} from '../../../../graphql/queries'
import {DELETE_INDIVIDUAL_PERFORMANCE} from '../../../../graphql/mutations'

import DeletePopup from '../../../shared-components/popup/DeletePopup'

const VersionListComponent = props => {
  const {enqueueSnackbar} = useSnackbar()
  const [OpenAlert, setOpenAlert] = useState(false)
  const [dataVersion, setDataVersion] = useState({})

  const [deleteVersion] = useMutation(DELETE_INDIVIDUAL_PERFORMANCE)
  const {data, loading} = useSubscription(GET_VERSION_LIST, {
    variables: {
      search: `%${props.searchText}%`,
    },
  })

  const handleCloseAlert = () => {
    OpenAlert ? setOpenAlert(false) : setOpenAlert(true)
  }

  const onDeleteVersion = () => {
    const variables = {
      id: dataVersion.id,
    }
    deleteVersion({variables: variables})
      .then(() => {
        enqueueSnackbar('Individual Performance deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete Individual Performance failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 1000,
          }
        )
      })
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Individual Performance</Typography>
        {data && !loading && (
          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={{
                pathname: `/performance/individual/report`,
                state: {active: 'individual-report'},
              }}
            >
              Generate Report
            </Button>
          </div>
        )}
      </EnhancedToolbar>
      <Divider></Divider>
      {!data && loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {data && !loading && (
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0'}}
          columns={[
            {
              title: 'Name',
              field: 'name',
            },
            {
              title: 'Start Date',
              type: 'date',
              field: 'start_date',
              render: rowData =>
                rowData.start_date
                  ? moment(rowData.start_date).format('ll')
                  : '-',
            },
            {
              title: 'End Date',
              type: 'date',
              field: 'end_date',
              render: rowData =>
                rowData.end_date ? moment(rowData.end_date).format('ll') : '-',
            },
            // {
            //   title: 'Last Generated',
            //   type: 'date',
            //   field: 'last_modified',
            //   render: rowData =>
            //     rowData.last_modified
            //       ? moment(rowData.last_modified).format('L')
            //       : '-',
            // },

            {
              title: 'Action',
              field: 'id',
              sorting: false,
              render: rowData => (
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <>
                      <MoreIcon {...bindTrigger(popupState)}>
                        more_horiz
                      </MoreIcon>
                      <StyledMenus {...bindMenu(popupState)}>
                        <StyledMenuItem
                          component={Link}
                          to={{
                            pathname: `/performance/individual/detail`,
                            state: {
                              active: 'individual-detail',
                              version: rowData.id,
                              searchable: true,
                            },
                          }}
                          variant="h6"
                        >
                          Details
                        </StyledMenuItem>
                        <StyledMenuItem
                          onClick={() => {
                            popupState.close()
                            setDataVersion(rowData)
                            setOpenAlert(true)
                          }}
                        >
                          Delete
                        </StyledMenuItem>
                      </StyledMenus>
                    </>
                  )}
                </PopupState>
              ),
            },
          ]}
          data={data && data.performance_individual_versions}
          title=""
          options={{
            ...TableOptions,
            showTitle: false,
            toolbar: false,
            emptyRowsWhenPaging: false,
            selection: false,
          }}
          components={{
            Toolbar: () => null,
          }}
        />
      )}
      <DeletePopup
        open={OpenAlert}
        handleClose={() => handleCloseAlert()}
        name={dataVersion.name}
        mutation={() => onDeleteVersion()}
      />
    </PaperContainer>
  )
}

export default VersionListComponent
