import React from 'react'

import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'
import {AttachmentCard} from '../../../../../../../components/attachment'
import {ItemWrapper} from '../../../DetailEmployeeStyles'
import {dateFormat} from '../../../../../../../utils/helpers'

const row = (header, content) => (
  <tr>
    <th scope="row">{header}</th>
    <td>{content || '-'}</td>
  </tr>
)

const MedicalHistoryDetail = props => {
  const {open, data, onClose} = props

  if (!data) {
    return null
  }

  return (
    <ModalDetail open={open} onClose={onClose} title="Medical History Detail">
      <div style={{padding: 24}}>
        <ItemWrapper>
          <h1 className="title">{data.name}</h1>
          <table className="content" style={{'--row-gap': '16px'}}>
            <tbody>
              {row('Age', data.age)}
              {row(
                'Date',
                dateFormat(data?.start_date, 'll') +
                  ' - ' +
                  dateFormat(data?.end_date, 'll')
              )}
              {row('Hospital Name', data.hospital_name)}
              {row('Doctor Name', data.doctor_name)}
              {row('Disease', data.disease)}
              {row('Consequence', data.consequence)}
              {row('Laboratory Number', data.laboratory_number)}
              {row('Laboratory Notes', data.laboratory_notes)}
              {row('Medical Score', data.medical_score)}
              {row('Medical Fee', data.medical_fee)}
              {row('Medical Category', data.medical_category)}
              {row(
                'Attachment',
                data.attachments?.length > 0 ? (
                  <div className="attachments row">
                    {data.attachments?.map((atch, i) => (
                      <AttachmentCard
                        key={`${i}-atch-medical-history-detail`}
                        name={atch.name || '-'}
                        url={atch.url || atch.link}
                      />
                    ))}
                  </div>
                ) : (
                  '-'
                )
              )}
            </tbody>
          </table>
        </ItemWrapper>
      </div>
    </ModalDetail>
  )
}

export default MedicalHistoryDetail
