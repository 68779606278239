import React, {useEffect} from 'react'
import {Divider, Typography, Button, IconButton} from '@material-ui/core'
import MaterialTable from 'material-table'
import {ClassroomThumbnail} from '../CrossMentoringPageStyles'
import {
  PaperContainer,
  TextBlackBold,
  Flex,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
} from '../../../../GlobalStyles'
import {
  NewTableOptions,
  newTableStyles,
} from '../../../shared-components/table/TableOptions'
import {Link, withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {useQuery} from '@apollo/react-hooks'
import {GET_CLASSROOM_BY_VERSION} from '../../../../graphql/queries'
import Loading from '../../../../components/loading/LoadingComponent'

const DetailComponent = props => {
  const id = props.match.params.id

  const {data, error, refetch} = useQuery(GET_CLASSROOM_BY_VERSION, {
    variables: {
      id: id,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'holding-administrator',
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [])

  const tableClasses = newTableStyles()

  const renderPrivacy = val => {
    if (val === 2) {
      return 'Closed'
    }
    if (val === 0) {
      return 'Public'
    } else if (val === 1) {
      return 'Private'
    }
  }

  const handleClickCancel = () => {
    props.history.goBack()
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link onClick={handleClickCancel}>
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6">
            {data ? data.classroom_cross_mentoring_versions[0].name : ''}
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            size="large"
            to={{
              pathname: `/holding-learning/${id}/add-group-learning`,
              state: {
                active: 'add-classroom',
              },
            }}
          >
            Add Group Learning
          </Button>
        </div>
      </FormToolbar>
      <Divider />
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontSize: 12}}
          options={{...NewTableOptions, paging: true, sorting: true}}
          columns={[
            {
              title: 'Group Learning',
              field: 'name',
              render: row => (
                <Flex>
                  <ClassroomThumbnail variant="rounded" src={row.avatar_url} />
                  <TextBlackBold>{row.name}</TextBlackBold>
                </Flex>
              ),
            },
            {
              title: 'Privacy',
              field: 'privacy',
              render: row => (
                <TextBlackBold>
                  {row.privacy ? renderPrivacy(row.privacy) : ''}
                </TextBlackBold>
              ),
            },
            {
              title: 'Category',
              field: 'category',
              render: row => (
                <TextBlackBold>
                  {row.academy_course_category
                    ? row.academy_course_category.name
                    : ''}
                </TextBlackBold>
              ),
            },
            {
              title: 'Status',
              field: 'status',
              render: row => (
                <Typography
                  style={row.is_finished ? {color: 'blue'} : {color: 'green'}}
                >
                  {row.is_finished ? 'END SESSION' : 'ACTIVE'}
                </Typography>
              ),
            },
            {
              title: 'Instructor',
              field: 'instructor',
              filtering: false,
              render: row => (
                <>
                  {row.global_user && (
                    <Flex>
                      <ClassroomThumbnail src={row.global_user.avatar} />
                      <Flex style={{flexDirection: 'column'}}>
                        <TextBlackBold>{row.global_user.name}</TextBlackBold>
                      </Flex>
                    </Flex>
                  )}
                </>
              ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={data.classroom_cross_mentoring_versions[0].classrooms}
        />
      </div>
    </PaperContainer>
  )
}

export default withRouter(DetailComponent)
