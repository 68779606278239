import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    userSelect: 'none',
    backgroundColor: '#eff2f4',
    height: '100%',
    width: '100%',
    marginTop: 64,

    '& .react-transform-component': {
      position: 'absolute',
      top: 64,
      left: 0,
      height: `calc(100% - 64px) !important`,
      width: `100% !important`,
    },
    '& #osChart, & #ouChart': {
      '--orgchart-stroke-color': '#a9a8a8',
      cursor: 'grab',
      padding: 240,

      '&:active': {
        cursor: 'grabbing',
      },
    },
  },

  // for some reason, the toolbar primary coloring would get overridden,
  // here's a temporary fix for the time being.
  toolbar: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  toolbarTitle: {
    flexGrow: '1',
  },
  toolbarLevel: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarLevelColor: {
    height: 16,
    width: 16,
    borderRadius: 4,
    marginRight: 8,
  },

  zoomContainer: {
    position: 'absolute',
    top: 64,
    right: 0,
    margin: 16,
  },

  wrapper: {
    display: 'flex',
    width: 'min-content',
    margin: `0 auto`,
    padding: `0 20px`,
  },
  card: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    margin: `0 auto`,
    borderWidth: 0,
    borderTopWidth: 4,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 8,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  title: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    margin: 4,
    marginRight: 8,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
    margin: 4,
  },
  legendColor: {
    height: 14,
    width: 14,
    marginRight: 8,
    borderRadius: 4,
  },
  legendTitle: {
    fontSize: 14,
  },

  expand: {
    padding: 3,
  },
  expandIcon: {
    fontSize: 20,
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(min(var(--items), 3), 240px)`,
    gridGap: 8,
    padding: 8,
  },
  subcard: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
  },
  itemAvatar: {
    height: 32,
    width: 32,
    marginRight: 8,
  },
  itemName: {
    fontSize: 12,
    fontWeight: 600,
    margin: 0,
  },
  link: {
    fontSize: 12,
  },
  spacer: {
    flexGrow: '1',
  },

  more: {
    width: `100%`,
    minWidth: 'unset',
    padding: 4,
    borderRadius: `0 0 4px 4px`,
  },
  moreIcon: {
    fontSize: 20,
  },
}))

export const mapStrokeStyle = type => {
  switch (type) {
    case 1:
      return 'solid'
    case 2:
      return 'dotted'
    default:
      return 'solid'
  }
}

export const mapOrgChartNode = (item, parent) => {
  return {
    data: item,
    name: item.name || item.title,
    id: item.id,
    parent: parent ? parent.id : null,
    parentNode: parent || null,
    staff: item.as_assistant || false,
    strokeStyle: mapStrokeStyle(item.line_type),
    // NOTE(intrnl): this will be filled in by `buildListToTree`
    children: null,
  }
}
