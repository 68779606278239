import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, {useState, useEffect} from 'react'
import {useSnackbar} from 'notistack'
import {Link, withRouter} from 'react-router-dom'
import Loading from '../../../../../components/loading/LoadingComponent'
import {BoldTypography} from '../../../../../components/typography/TypographyStyles'
import {
  AddEditTitle,
  FlexBetween,
  GreyTypography,
  MediumAvatar,
  PaperContainer,
} from '../../../../../GlobalStyles'
import {
  GET_DETAIL_SUCCESSION,
  GET_LIST_SUCCESSOR,
} from '../../../../../graphql/queries'
import {capitalize, SuccessionBoxes} from '../../../../../utils/helpers'
import ModalDetailTalent from './modal-detail/ModalDetailTalent'
import TalentDetailComponent from './TalentDetailComponent'
import '../../../../../assets/css/nukaCarousel.css'
import {Loader} from '../../LoaderSuccession'
import {DELETE_TALENT_POOL} from '../../../../../graphql/mutations'

const useStyles = makeStyles(() => ({
  gridContainer: {
    margin: 0,
    width: '100%',
    padding: 8,
  },
  paper: {
    padding: 20,
  },
  textCenter: {
    textAlign: 'center',
  },
  successor: {
    padding: '12px 0 24px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  indicator: {
    padding: 12,
  },
  namedSuccessor: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    cursor: 'pointer',
  },
  talentPool: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  talentPoolUser: {
    display: 'flex',
    alignItems: 'center',
  },
  talentPoolName: {
    marginLeft: 10,
    maxWidth: 210,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  green: {
    color: '#4caf50',
  },
  spacer: {
    marginLeft: 10,
  },
  spacerPool: {
    marginLeft: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  spacerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    width: '100%',
  },
  mr20: {
    marginRight: 20,
  },
  flexDetail: {
    alignItems: 'center',
    display: 'flex',
  },
  paperCategory: {
    padding: '20px 0',
    textAlign: 'center',
  },
  red: {
    color: '#ef4d5e',
  },
  paperMobility: {
    textAlign: 'center',
    padding: 17,
    marginTop: 20,
    marginBottom: 42,
  },
  seeAll: {
    textAlign: 'center',
    marginTop: 20,
    width: '100%',
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  deleteTalentPool: {
    borderColor: '#EF4D5E',
    color: '#EF4D5E',
  },
}))

const DetailSuccessionComponent = props => {
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const jobProfileId = props.match.params.id
  const [open, setOpen] = useState(false)
  const [dataTalent, setDataTalent] = useState({})
  const [boxes, setBoxes] = useState([])

  const [deleteTalentPool] = useMutation(DELETE_TALENT_POOL)

  const {data, error, refetch} = useQuery(GET_DETAIL_SUCCESSION, {
    variables: {job_profile: jobProfileId},
  })

  const {
    data: dataSuccessors,
    refetch: refetchSuccessors,
    loading: loadingSuccessors,
  } = useQuery(GET_LIST_SUCCESSOR, {
    fetchPolicy: 'network-only',
    variables: {
      jobProfile: jobProfileId,
      limit: 5,
    },
  })

  useEffect(() => {
    const refetchBoxes = () => {
      refetch()
      setBoxes(SuccessionBoxes(data))
    }
    refetchBoxes()
  }, [refetch, data])

  const handleOpen = res => {
    setOpen(true)
    setDataTalent(res)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDeletePool = (e, talent_pool) => {
    e.stopPropagation()

    deleteTalentPool({
      variables: {
        id: talent_pool.id,
      },
    })
      .then(() => {
        refetchSuccessors()
        enqueueSnackbar('Talent Pool deleted', {variant: 'success'})
      })
      .catch(() => {
        enqueueSnackbar('Failed to delete Talent Pool', {variant: 'error'})
      })
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer overflow="unset">
      <Toolbar disableGutters>
        <Link
          to={{
            pathname: '/talent/succession',
            state: {active: 'succession', searchable: true},
          }}
        >
          <IconButton edge="end" aria-label="back">
            <ArrowBack />
          </IconButton>
        </Link>
        <FlexBetween className={classes.mr20}>
          <AddEditTitle variant="h6">
            {boxes.length !== 0
              ? boxes[0].dataProfile.company_job_profile.title
              : null}{' '}
            Rincian Suksesi
          </AddEditTitle>
          <Button
            variant="contained"
            size="large"
            color="primary"
            component={Link}
            to={{
              pathname: `/talent/succession/compare/` + jobProfileId,
              state: {
                active: 'compare-talent',
                pos:
                  boxes.length !== 0
                    ? boxes[0].dataProfile.company_job_profile.title
                    : null,
              },
            }}
          >
            Bandingkan
          </Button>
        </FlexBetween>
      </Toolbar>
      <Divider />
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <GreyTypography variant="body1">Current Incumbent</GreyTypography>
            <TalentDetailComponent resTalent={boxes} />
          </Paper>
        </Grid>
        <Grid item container xs={6} alignContent="flex-start" spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FlexBetween>
                <GreyTypography variant="body1">Named Successor</GreyTypography>
              </FlexBetween>
              {loadingSuccessors
                ? Array.from({length: 3}, (_, idx) => (
                    <Loader width={220} showAction={false} key={`${_}${idx}`} />
                  ))
                : dataSuccessors?.named_successor?.map((dataProfile, i) => {
                    return (
                      <div
                        className={classes.namedSuccessor}
                        onClick={() => handleOpen(dataProfile)}
                        key={i}
                      >
                        <MediumAvatar
                          src={
                            dataProfile.people_work_placement.global_user.avatar
                          }
                          alt={
                            dataProfile.people_work_placement.global_user.name
                          }
                        />
                        <div className={classes.spacer}>
                          <BoldTypography variant="body2">
                            {dataProfile.people_work_placement.global_user.name}
                          </BoldTypography>
                          <Typography
                            variant="caption"
                            className={
                              dataProfile.readiness_status === 'READY'
                                ? classes.green
                                : classes.red
                            }
                          >
                            {capitalize(dataProfile.readiness_status)}
                          </Typography>
                        </div>
                      </div>
                    )
                  })}
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.seeAll}
                  color="secondary"
                  component={Link}
                  to={{
                    pathname: `/talent/succession/${jobProfileId}/list-successor`,
                    state: {
                      active: 'list-successor',
                      pos:
                        boxes.length !== 0
                          ? boxes[0].dataProfile.company_job_profile
                          : null,
                      userId: jobProfileId,
                      type: 'successor',
                    },
                  }}
                >
                  See All
                </Button>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FlexBetween>
                <GreyTypography variant="body1">
                  Kelompok Rencana Suksesi
                </GreyTypography>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  component={Link}
                  to={{
                    pathname: `/talent/succession/${jobProfileId}/add-pool`,
                    state: {
                      active: 'add-pool',
                      pos:
                        boxes.length !== 0
                          ? boxes[0].dataProfile.company_job_profile
                          : null,
                      back: jobProfileId,
                      searchable: true,
                    },
                  }}
                >
                  Add
                </Button>
              </FlexBetween>
              {loadingSuccessors
                ? Array.from({length: 3}, (_, idx) => (
                    <Loader width={180} showAction={false} key={`${_}${idx}`} />
                  ))
                : dataSuccessors?.talent_pool?.map((dataProfile, i) => {
                    return (
                      <div
                        className={classes.talentPool}
                        key={i}
                        onClick={() => handleOpen(dataProfile)}
                      >
                        <div className={classes.talentPoolUser}>
                          <MediumAvatar
                            src={
                              dataProfile.people_work_placement.global_user
                                .avatar
                            }
                            alt={
                              dataProfile.people_work_placement.global_user.name
                            }
                          />
                          <BoldTypography
                            className={classes.talentPoolName}
                            variant="body2"
                          >
                            {dataProfile.people_work_placement.global_user.name}
                          </BoldTypography>
                        </div>
                        <Button
                          variant="outlined"
                          className={classes.deleteTalentPool}
                          onClick={e => handleDeletePool(e, dataProfile)}
                        >
                          - Delete
                        </Button>
                      </div>
                    )
                  })}
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.seeAll}
                  color="secondary"
                  component={Link}
                  to={{
                    pathname: `/talent/succession/${jobProfileId}/list-talent-pool`,
                    // state: {
                    //   active: 'list-talent-pool',
                    //   pos: boxes[0].company_job_profile.title,
                    //   data: resTalentPool,
                    //   resPlacement: resPlacement,

                    // },
                    state: {
                      active: 'list-talent-pool',
                      pos:
                        boxes.length !== 0
                          ? boxes[0].dataProfile.company_job_profile
                          : null,
                      userId: jobProfileId,
                      type: 'talent',
                    },
                  }}
                >
                  See All
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <ModalDetailTalent
        open={open}
        handleClose={handleClose}
        placementId={dataTalent?.people_work_placement?.id}
        userId={dataTalent?.people_work_placement?.global_user?.id}
        idJob={jobProfileId}
        refetch={refetchSuccessors}
      />
    </PaperContainer>
  )
}

export default withRouter(DetailSuccessionComponent)
