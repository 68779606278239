import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {
  BigAvatar,
  FlexBetween,
  FlexCenter,
  GreyTypography,
} from '../../../../../GlobalStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {useQuery} from '@apollo/react-hooks'
import LoadingSurvey from '../LoadingSurvey'
import {
  GET_SURVEY_PREVIEW_CONTENT,
  GET_SURVEY_PREVIEW_SIDEBAR,
} from '../../../../../graphql/queries/survey/multirater-nps/survey-center/getSurveyPreview.query'
import {selectProps} from '../../../../../utils/helpers'
import NoDataListFree from '../../../../shared-components/NoDataListFreeComponent'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    height: 81,
    boxShadow: '0px 3px 6px #0000001A',
    justifyContent: 'space-between',
    padding: '0 98px',
    alignItems: 'center',
    textAlign: 'center',
    zIndex: 1,
    position: 'relative',
  },
  pointer: {cursor: 'pointer'},
  body: {
    padding: '22px 86px',
    background: '#F7F8F9 0% 0% no-repeat padding-box',
    display: 'flex',
    minHeight: 'calc(100vh - 81px - 44px)',
  },
  sidebar: {width: '24vw', marginRight: '1vw'},
  sidebarTitle: {padding: '14px 20px'},
  content: {width: '75vw', padding: '20px 24px'},
  jobprogile: {
    cursor: 'pointer',
    marginLeft: 12,
  },
  loading: {
    backgroundColor: '#fff',
    marginLeft: 10,
    marginTop: '25vh',
    borderRadius: '5px',
    width: '100%',
    fontFamily: 'Muli',
    height: '100vh',
  },
  questionContainer: {margin: '37px 0 60px', textAlign: 'center'},
  avatarQuestion: {margin: 'auto', marginBottom: 8},
  scoreStep: {
    display: 'flex',
    flexDirection: 'row',
    color: 'grey',
    margin: '20px 0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  accordion: {
    '&::before': {
      display: 'none',
    },
  },
  accordionSummary: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  accordionDetails: {padding: '0'},
  raterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 12px 12px 16px',
    width: '100%',
    cursor: 'pointer',
  },
  raterContent: {marginLeft: 16},
  raterName: {fontSize: 14},
  raterPosition: {fontSize: 12, color: '#a9a8a8'},
}))

const SurveyPreview = props => {
  const classes = useStyles()
  const [questionId, setQuestionId] = useState('')
  const [answer, setAnswer] = useState(null)
  const [activeAsesi, setActiveAsesi] = useState('Atasan Pimpinan')

  const {data, loading} = useQuery(GET_SURVEY_PREVIEW_SIDEBAR, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {survey: props.match.params.id},
  })

  const {data: dataContent, loading: loadingContent} = useQuery(
    GET_SURVEY_PREVIEW_CONTENT,
    {
      wlb_skipPatch: true,
      fetchPolicy: 'cache-and-network',
      variables: {
        survey: props.match.params.id,
      },
    }
  )

  useEffect(() => {
    if (dataContent && dataContent.multirater_survey_questions.length > 0) {
      setQuestionId(dataContent.multirater_survey_questions[0].id)
      if (
        dataContent.multirater_survey_questions[0].multirater_responses
          ?.length > 0
      ) {
        setAnswer(
          dataContent.multirater_survey_questions[0].multirater_responses?.[0]
            ?.answer
        )
      }
    }
  }, [dataContent])

  const back = () => props.history.goBack()

  if (loading || loadingContent) {
    return (
      <div className={classes.loading}>
        <LoadingSurvey />
      </div>
    )
  }

  const currentQuestion =
    dataContent &&
    dataContent.multirater_survey_questions.find(
      element => element.id === questionId
    )

  const currentQuestionIndex =
    dataContent &&
    dataContent.multirater_survey_questions.findIndex(i => i.id === questionId)

  const handleNextPrev = next => {
    if (dataContent.multirater_survey_questions.length > 0) {
      setQuestionId(
        dataContent.multirater_survey_questions[
          next ? currentQuestionIndex + 1 : currentQuestionIndex - 1
        ].id
      )
    }
  }

  let isNpsExist = false
  let isMultiraterExist = false
  if (data) {
    data.multirater_survey_groups.some(s => {
      if (s.isnps) {
        isNpsExist = true
      } else {
        isMultiraterExist = true
      }
    })
  }

  const raterList = [
    'Atasan Pimpinan',
    'Pimpinan',
    'Rekan Kerja',
    'Pegawai di Bawahnya',
    'Pegawai Dua Tingkat Dibawahnya',
    'Diri Sendiri',
  ]

  return (
    <>
      <div className={classes.header}>
        <img
          src={require('../../../../../assets/slicing/logo-wlb-teal.svg')}
          alt="logo"
          width={150}
          onClick={back}
          className={classes.pointer}
        />
        <div>
          <Typography variant="body1" color="primary">
            {data.multirater_surveys[0].title}
          </Typography>

          <Typography variant="body2">
            {isNpsExist && 'NPS'}
            {isNpsExist && isMultiraterExist && ', '}
            {isMultiraterExist && 'Multirater'}
          </Typography>
        </div>
        <GreyTypography
          variant="body1"
          color="primary"
          className={classes.pointer}
          onClick={back}
        >
          Tutup
        </GreyTypography>
      </div>
      <div className={classes.body}>
        <Paper elevation={3} className={classes.sidebar}>
          <Typography
            variant="h6"
            color="primary"
            className={classes.sidebarTitle}
          >
            Daftar Penilai
          </Typography>
          {raterList.map((title, i) => (
            <Accordion
              elevation={0}
              key={title}
              className={classes.accordion}
              defaultExpanded={i === 0}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="body1">{title}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <div
                  onClick={() => setActiveAsesi(title)}
                  style={{
                    backgroundColor:
                      activeAsesi === title ? 'rgb(247, 248, 249)' : '#fff',
                  }}
                  className={classes.raterContainer}
                >
                  <Avatar />
                  <div className={classes.raterContent}>
                    <Typography className={classes.raterName}>
                      Nama Asesi
                    </Typography>
                    <Typography className={classes.raterPosition}>
                      Posisi Asesi
                    </Typography>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
        <Paper elevation={3} className={classes.content}>
          {!loading &&
          dataContent?.multirater_survey_questions?.length === 0 ? (
            <NoDataListFree
              message1="Sorry, No List"
              message2="No questions found"
              image={require('../../../../../assets/images/nodatalist.png')}
            />
          ) : (
            <>
              <FlexBetween>
                <GreyTypography variant="body2">
                  {currentQuestionIndex + 1} dari{' '}
                  {dataContent.multirater_survey_questions.length} Pertanyaan
                </GreyTypography>
                <FlexCenter style={{width: 'auto'}}>
                  <GreyTypography variant="body2">Lompat ke</GreyTypography>
                  <TextField
                    variant="outlined"
                    select
                    className={classes.jobprogile}
                    size="small"
                    value={questionId}
                    onChange={e => setQuestionId(e.target.value)}
                    SelectProps={selectProps(questionId)}
                    style={{width: 200}}
                  >
                    <MenuItem value="" disabled>
                      Select question
                    </MenuItem>
                    {dataContent.multirater_survey_questions.map((res, i) => (
                      <MenuItem
                        key={i}
                        value={res.id}
                        onClick={() =>
                          res.multirater_responses?.length > 0 &&
                          setAnswer(res.multirater_responses?.[0]?.answer)
                        }
                      >
                        {res.question_others
                          ? res.question_others
                          : res.question_self}
                      </MenuItem>
                    ))}
                  </TextField>
                </FlexCenter>
              </FlexBetween>
              <div className={classes.questionContainer}>
                <BigAvatar className={classes.avatarQuestion} />
                <Typography variant="h6" color="primary" gutterBottom>
                  Asesi Name
                </Typography>
                <Typography variant="body1" paragraph>
                  Posisi asesi
                </Typography>
                <Typography variant="body1" color="primary" gutterBottom>
                  {currentQuestion && currentQuestion.question_others
                    ? currentQuestion.question_others
                    : currentQuestion && currentQuestion.question_self}
                </Typography>
                <div className={classes.scoreStep}>
                  {currentQuestion &&
                  currentQuestion.multirater_survey_group &&
                  currentQuestion.multirater_survey_group.isnps ? (
                    <>
                      <GreyTypography variant="body1" style={{marginRight: 12}}>
                        Score:
                      </GreyTypography>
                      {Array.from({length: 10}).map((_, i) => (
                        <Button
                          key={`${_}${i}`}
                          style={{
                            padding: 14,
                            borderStyle: 'solid',
                            borderColor: 'grey',
                            borderWidth: 1,
                            borderRightWidth: i === 9 ? 1 : 0,
                            borderTopLeftRadius: i === 0 ? 5 : 0,
                            borderBottomLeftRadius: i === 0 ? 5 : 0,
                            borderTopRightRadius: i === 9 ? 5 : 0,
                            borderBottomRightRadius: i === 9 ? 5 : 0,
                            color: answer === i + 1 && 'white',
                            backgroundColor: answer === i + 1 && '#014a62',
                            minWidth: 0,
                            lineHeight: 1,
                          }}
                          onClick={() => setAnswer(i + 1)}
                        >
                          {i + 1}
                        </Button>
                      ))}
                    </>
                  ) : (
                    currentQuestion &&
                    currentQuestion.multirater_survey_group &&
                    currentQuestion.multirater_survey_group.multirater_survey
                      .multirater_scale &&
                    currentQuestion.multirater_survey_group.multirater_survey.multirater_scale.name.map(
                      (scales, i) => (
                        <div key={i} style={{margin: '0 6px', minWidth: 115}}>
                          <Button
                            key={i}
                            style={{
                              padding: 14,
                              borderStyle: 'solid',
                              borderColor: 'grey',
                              borderRadius: 5,
                              borderWidth: 1,
                              color: answer === i + 1 && 'white',
                              backgroundColor: answer === i + 1 && '#014a62',
                              minWidth: 0,
                              lineHeight: 1,
                              margin: '0 23px 8px 23px',
                            }}
                            onClick={() => setAnswer(i + 1)}
                          >
                            {i + 1}
                          </Button>
                          <Typography component="p" variant="caption">
                            {scales}
                          </Typography>
                        </div>
                      )
                    )
                  )}
                </div>
                {/* {currentQuestion?.multirater_survey_group?.isnps && ( */}
                {currentQuestion &&
                  currentQuestion.multirater_survey_group &&
                  currentQuestion.multirater_survey_group.isnps && (
                    <TextField
                      multiline
                      rows="4"
                      variant="outlined"
                      placeholder="Tulis alasan anda disini"
                      fullWidth
                      style={{width: '32rem'}}
                    />
                  )}
              </div>
              <FlexBetween>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => handleNextPrev(false)}
                  disabled={currentQuestionIndex === 0}
                >
                  Sebelumnya
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleNextPrev(true)}
                  disabled={
                    currentQuestionIndex + 1 ===
                    dataContent.multirater_survey_questions.length
                  }
                >
                  Selanjutnya
                </Button>
              </FlexBetween>
            </>
          )}
        </Paper>
      </div>
    </>
  )
}

export default withRouter(SurveyPreview)
