import gql from 'graphql-tag'
import {COMPANY_ID} from '../../../../utils/globals'

export const GET_HOLDING_VERSION = gql`
  query getVersionHolding($search: String) {
    holding_version(
      where: {
        company: {_eq: "${COMPANY_ID}"}
        _or: [
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
          {status: {_ilike: $search}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      name
      description
      status
    }
  }
`
