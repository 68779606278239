// import { Avatar } from "@material-ui/core";
import React from 'react'
import {format} from 'date-fns'
import {Icon} from '@material-ui/core'

import {
  TyphoSection,
  WrapperItemSection,
  LogoImg,
} from '../../DetailEmployeeStyles'
import useEllipsisText from '../../../../../../hooks/useEllipsisText'
import useToggle from '../../../../../../hooks/useToggle'

export default function ExperienceItem({result, res, onEdit}) {
  const _description = res?.description?.length === 0 ? '-' : res?.description
  const {briefText} = useEllipsisText({text: _description})
  const [more, setMore] = useToggle()

  const _generate = data => {
    if (!data || data === '') {
      return '-'
    } else return data
  }

  const generatePhone = (code, phone) => {
    if (!code && !phone) return '-'
    else return _generate(code) + _generate(phone)
  }

  return (
    <WrapperItemSection>
      <LogoImg
        src={res?.company_profile?.logo}
        style={{width: 100, height: 100, borderRadius: 5, marginRight: 50}}
        imgProps={{
          style: {width: 'auto', height: '100%', borderRadius: 0},
        }}
      />
      <div className="divider">
        <div className="content">
          <TyphoSection title="true" bold="true">
            {res.title}
          </TyphoSection>
          <TyphoSection blue="true">
            {res.company_name ? res.company_name : ' - '}
          </TyphoSection>
          <TyphoSection style={{color: '#014a62'}}>
            {(res.location && res.location.experience_location) || '-'}
          </TyphoSection>
          <TyphoSection grey="true">
            {format(new Date(res.from), 'MMM yyyy')}-{' '}
            {res.is_present ? 'present' : format(new Date(res.to), 'MMM yyyy')}{' '}
            {result !== '' ? `(${result})` : null}
          </TyphoSection>
          <TyphoSection style={{color: '#014a62'}}>
            {res.decree || '-'}
          </TyphoSection>
          <TyphoSection mt="true">
            {more ? _description : briefText}
          </TyphoSection>

          {more && (
            <>
              <TyphoSection italic="true" mt="true">
                {res.achievements || '-'}
              </TyphoSection>
              <TyphoSection mt="true" style={{color: '#014a62'}}>
                Reference
              </TyphoSection>
              <TyphoSection>{res.referal_name || '-'}</TyphoSection>
              <TyphoSection grey="true">
                {res.referal_position || '-'}
              </TyphoSection>
              <TyphoSection>
                {res.additional_fields
                  ? generatePhone(
                      res.additional_fields.referal_contact_phone &&
                        res.additional_fields.referal_contact_phone
                          .country_code,
                      res.additional_fields.referal_contact_phone &&
                        res.additional_fields.referal_contact_phone.phone
                    )
                  : '-'}
              </TyphoSection>
            </>
          )}
          <TyphoSection
            mt="true"
            style={{color: '#a9a8a8', cursor: 'pointer'}}
            onClick={setMore}
          >
            {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
          </TyphoSection>
        </div>
      </div>
      <Icon onClick={onEdit} className="icon-edit">
        edit
      </Icon>
    </WrapperItemSection>
  )
}
