import EmployeePage from './EmployeeComponent'

export const EmployeePageConfig = {
  routes: [
    {
      path: '/directory/employee',
      component: EmployeePage,
      state: 'employee',
      exact: true,
    },
    {
      path: '/directory/employee/:id',
      component: EmployeePage,
      state: 'detail-employee',
      exact: true,
    },
    {
      path: '/directory/employee-alumni/:id',
      component: EmployeePage,
      state: 'detail-employee',
      exact: true,
    },
    {
      path: '/directory/employee/add-employee',
      component: EmployeePage,
      state: 'add-employee',
      exact: true,
    },
    {
      path: '/directory/employee/edit/:id',
      component: EmployeePage,
      state: 'edit-employee',
      exact: true,
    },
    {
      path: '/directory/employee-will-join/edit/:id',
      component: EmployeePage,
      state: 'edit-employee',
      exact: true,
    },
    {
      path: '/directory/employee-pending/edit/:id',
      component: EmployeePage,
      state: 'edit-employee',
      exact: true,
    },
    {
      path: '/directory/employee-alumni/edit/:id',
      component: EmployeePage,
      state: 'edit-employee',
      exact: true,
    },
    {
      path: '/directory/employee-will-join',
      component: EmployeePage,
      state: 'employee-will-join',
      exact: true,
    },
    {
      path: '/directory/employee-pending',
      component: EmployeePage,
      state: 'employee-pending',
      exact: true,
    },
    {
      path: '/directory/employee-pending-register',
      component: EmployeePage,
      state: 'employee-pending',
      exact: true,
    },
    {
      path: '/directory/employee-pending-replacement',
      component: EmployeePage,
      state: 'employee-pending',
      exact: true,
    },
    {
      path: '/directory/employee-alumni',
      component: EmployeePage,
      state: 'employee-alumni',
      exact: true,
    },
  ],
}
