export const RADIO_YES = 'y'
export const RADIO_NO = 'n'

export const RADIO_BOOL_OPTIONS = [
  {label: 'Iya', value: RADIO_YES},
  {label: 'Tidak', value: RADIO_NO},
]

export const BLOOD_TYPE_OPTIONS = [
  {name: 'A', value: 'A'},
  {name: 'B', value: 'B'},
  {name: 'AB', value: 'AB'},
  {name: 'O', value: 'O'},
]

export const BLOOD_RHESUS_OPTIONS = [
  {name: 'Positive', value: 'Positive'},
  {name: 'Negative', value: 'Negative'},
]

export const MARTITAL_STATUS = {
  single: 'Lajang',
  married: 'Menikah',
  divorced: 'Cerai',
  separated: 'Berpisah',
  widowed: 'Janda',
}

export const MARITAL_STATUS_OPTIONS = [
  {name: MARTITAL_STATUS.single, value: 'single'},
  {name: MARTITAL_STATUS.married, value: 'married'},
  {name: MARTITAL_STATUS.divorced, value: 'divorced'},
  {name: MARTITAL_STATUS.separated, value: 'separated'},
  {name: MARTITAL_STATUS.widowed, value: 'widowed'},
]

export const WORKING_STATUS = {
  employee: 'Karyawan',
  unemployed: 'Penganggur',
}

export const WORKING_STATUS_OPTIONS = [
  {name: WORKING_STATUS.employee, value: 'employee'},
  {name: WORKING_STATUS.unemployed, value: 'unemployed'},
]

export const GENDER_TYPES = {
  M: 'Laki - laki',
  F: 'Perempuan',
}

export const GENDER_OPTIONS = [
  {name: GENDER_TYPES.M, value: 'M'},
  {name: GENDER_TYPES.F, value: 'F'},
]
