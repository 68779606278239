import gql from 'graphql-tag'

export const GET_COMPANY_HOLDING = gql`
  query getCompanyHolding($company: uuid, $search: String) {
    list_company_head: company_profiles(
      where: {
        id: {_eq: $company}
        status: {_eq: "ACTIVE"}
        people_work_placements: {deletedAt: {_is_null: true}}
        _or: [{legal_name: {_ilike: $search}}, {brand_name: {_ilike: $search}}]
      }
    ) {
      id
      legal_name
      brand_name
      profile_field
    }

    list_company_member: holding_relations(
      where: {
        company_head: {_eq: $company}
        status: {_eq: "ACTIVE"}
        company_member_profile: {
          people_work_placements: {deletedAt: {_is_null: true}}
        }
        _or: [
          {company_member_profile: {legal_name: {_ilike: $search}}}
          {company_member_profile: {brand_name: {_ilike: $search}}}
        ]
      }
    ) {
      company_member_profile {
        id
        legal_name
        brand_name
        profile_field
      }
    }
  }
`

export const GET_JOB_PROFILE_HOLDING = gql`
  query getJobProfile($company: uuid) {
    company_job_profiles(where: {company: {_eq: $company}}) {
      id
      title
    }
  }
`

export const GET_LIST_COMMITTEE_HOLDING = gql`
  query($companyHead: uuid, $search: String) {
    list_head: people_work_placements(
      where: {
        company: {_eq: $companyHead}
        status: {_eq: "ACTIVE"}
        _or: [
          {email: {_ilike: $search}}
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      regno
      email
      global_user {
        id
        name
        avatar
      }
      company_profile {
        id
        legal_name
        brand_name
      }
      company_job_profile {
        id
        title
      }
    }

    list_member: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company_profile: {
          holdingRelationsByCompanyMember: {
            company_head: {_eq: $companyHead}
            status: {_eq: "ACTIVE"}
          }
        }
        _or: [
          {email: {_ilike: $search}}
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
      }
      order_by: {id: asc}
    ) {
      id
      regno
      email
      global_user {
        id
        name
        avatar
      }
      company_profile {
        id
        legal_name
        brand_name
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_LIST_COMMITTEE_HOLDING_EDIT = gql`
  query($companyHead: uuid, $search: String, $nomination_id: Int) {
    list_head: people_work_placements(
      where: {
        company: {_eq: $companyHead}
        status: {_eq: "ACTIVE"}
        _or: [
          {email: {_ilike: $search}}
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
        _not: {
          holding_nomination_commites: {nomination_id: {_eq: $nomination_id}}
        }
      }
      order_by: {id: asc}
    ) {
      id
      regno
      email
      global_user {
        id
        name
        avatar
      }
      company_profile {
        id
        legal_name
        brand_name
      }
      company_job_profile {
        id
        title
      }
    }

    list_member: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company_profile: {
          holdingRelationsByCompanyMember: {
            company_head: {_eq: $companyHead}
            status: {_eq: "ACTIVE"}
          }
        }
        _or: [
          {email: {_ilike: $search}}
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_profile: {legal_name: {_ilike: $search}}}
          {company_profile: {brand_name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
        _not: {
          holding_nomination_commites: {nomination_id: {_eq: $nomination_id}}
        }
      }
      order_by: {id: asc}
    ) {
      id
      regno
      email
      global_user {
        id
        name
        avatar
      }
      company_profile {
        id
        legal_name
        brand_name
      }
      company_job_profile {
        id
        title
      }
    }
  }
`
