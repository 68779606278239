import gql from 'graphql-tag'

export const GET_LIST_KPI = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: kpi_lists_aggregate(
      where: {
        kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}
        name: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }

    kpi_lists(
      where: {
        kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}
        name: {_ilike: $search}
      }
      order_by: [{name: asc}]
      offset: $offset
      limit: $limit
    ) {
      id
      name
      code
    }
  }
`
