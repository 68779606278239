import React, {useState} from 'react'
import {Alert} from '@material-ui/lab'
import moment from 'moment'

const STYLE_TEXT = {margin: 0, fontSize: 14}
const STYLE_ACTIONS = {
  textDecoration: 'underline',
  fontWeight: '600',
  fontSize: 14,
  cursor: 'pointer',
}

const BaloonDownloadTalent = props => {
  const {baloonData, closeBaloonMutation, retry} = props
  // const [severity, setSeverity] = useState('info')

  const data = baloonData && baloonData.length > 0 ? baloonData[0] : null

  const getContent = status => {
    switch (status) {
      case 'WAITING':
        return 'Generating data! We’ll send you an email when it’s ready.'
      case 'COMPLETED':
        return (
          <div style={{color: '#4caf50'}}>
            <span style={STYLE_TEXT}>
              Generate data is Complete! (Last generate{' '}
              {moment(data && data.date_added).format('ll')})!
            </span>{' '}
            <span
              onClick={() => window.open(data && data.data_url, '_blank')}
              style={STYLE_ACTIONS}
            >
              Download now
            </span>
          </div>
        )
      case 'FAILED':
        return (
          <div style={{color: '#ef4d5e'}}>
            <span style={STYLE_TEXT}>Download data has failed!</span>{' '}
            {/* <span onClick={onRetry} style={STYLE_ACTIONS}> */}
            <span style={STYLE_ACTIONS} onClick={() => retry()}>
              Retry
            </span>
          </div>
        )
      default:
        return '-'
    }
  }

  const renderSeverity = status => {
    switch (status) {
      case 'WAITING':
        return 'info'
      case 'COMPLETED':
        return 'success'
      case 'FAILED':
        return 'error'
      default:
        return 'info'
    }
  }

  return (
    <Alert
      severity={renderSeverity(data && data.status)}
      style={{marginBottom: 8, marginLeft: 8}}
      onClose={data && data.status === 'FAILED' && closeBaloonMutation}
    >
      {getContent(data && data.status)}
    </Alert>
  )
}

export default BaloonDownloadTalent
