import React from 'react'
import {Icon} from '@material-ui/core'

import {
  AttachmentItemHover,
  WrapAttachment,
} from '../../../../../shared-components/attachment'
import {
  TyphoSection,
  WrapperItemSection,
  LogoImg,
} from '../../DetailEmployeeStyles'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import useToggle from '../../../../../../hooks/useToggle'

export const OhsaItem = ({
  company_name,
  company_location,
  category,
  employees,
  time,
  date,
  severity,
  description,
  incident_code,
  incident_location,
  attachments,
  onEdit,
}) => {
  const [more, setMore] = useToggle()
  return (
    <WrapperItemSection>
      <LogoImg
        style={{
          width: 100,
          height: 100,
          borderRadius: 5,
          marginRight: 50,
        }}
        imgProps={{
          style: {width: 'auto', height: '100%', borderRadius: 0},
        }}
      />
      <div className="divider">
        <div className="content">
          <TyphoSection title="true" bold="true">
            {company_name}
          </TyphoSection>
          <TyphoSection grey="true">{company_location}</TyphoSection>
          <TyphoSection style={{color: '#014a62'}}>{category}</TyphoSection>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <TyphoSection style={{marginRight: 4}} blue="true">
              {employees}
            </TyphoSection>
          </div>
          <TyphoSection grey="true">{incident_location}</TyphoSection>

          {more && (
            <>
              <TyphoSection style={{color: '#014a62'}}>
                {time} in {date}
              </TyphoSection>
              <TyphoSection style={{color: '#1b1d1f'}}>{severity}</TyphoSection>
              <TyphoSection>{description}</TyphoSection>
              <TyphoSection grey="true">
                Incident Code :{' '}
                <span style={{color: '#000'}}>{incident_code}</span>
              </TyphoSection>
              {more && (
                <>
                  <WrapAttachment>
                    {attachments &&
                      attachments.map((attachment, i) => (
                        <AttachmentItemHover
                          key={`${i}-itm`}
                          iconAction={
                            <VisibilityOutlinedIcon
                              onClick={() =>
                                window.open(attachment.url || attachment.link)
                              }
                              className="icon"
                            />
                          }
                          fileName={attachment.name}
                          url={attachment.url || attachment.link}
                        />
                      ))}
                  </WrapAttachment>
                </>
              )}
            </>
          )}

          <TyphoSection
            mt="true"
            style={{color: '#a9a8a8', cursor: 'pointer'}}
            onClick={setMore}
          >
            {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
          </TyphoSection>
        </div>
      </div>
      <Icon onClick={onEdit} className="icon-edit">
        edit
      </Icon>
    </WrapperItemSection>
  )
}
