import React from 'react'
import {withRouter} from 'react-router-dom'

import {Filter} from '@worklifebeyond/wlb-utils-components'
import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'
import {BlueIcon, ButtonFilter, FilterText} from '../../../GlobalStyles'

import {ClaimPageContainer} from './ClaimPageStyles'

import ClaimActivity from './activity/ClaimActivity'
import ClaimReport from './report/ClaimReport'
import ClaimSettings from './settings/ClaimSettings'
import ClaimPolicyFormPage from './settings/form/ClaimPolicyForm'

import searchFilters from '../../../utils/searchFilters'

const TABS_CONFIG = [
  {
    label: 'Activity',
    state: 'activity',
    url: '/financial/claim/activity',
    searchable: true,
  },
  // {
  //   label: 'Balance',
  //   state: 'balance',
  //   url: '/financial/claim/balance',
  //   searchable: true,
  // },
  {
    label: 'Report',
    state: 'report',
    url: '/financial/claim/report',
    searchable: false,
  },
  {
    label: 'Settings',
    state: 'settings',
    url: '/financial/claim/settings',
    searchable: true,
  },
]

const FILTER_INITIAL_DATA = {}

function ClaimPage(props) {
  const [search, handleSearchInput] = React.useState('')
  const [filter, setFilter] = React.useState(FILTER_INITIAL_DATA)

  let active
  let activeTab
  let activeFilter

  if (props.location.state) {
    active = props.location.state.active

    switch (active) {
      default:
      case 'activity':
        activeTab = 'activity'
        activeFilter = searchFilters
        break
      case 'report':
      case 'report-detail':
        activeTab = 'report'
        activeFilter = searchFilters
        break
      case 'settings':
      case 'settings-add':
      case 'settings-edit':
        activeTab = 'settings'
        break
    }
  }

  const handleFilterApply = result => {
    const include = mapFilterResults(result[0])
    const exclude = mapFilterResults(result[1])

    setFilter({include, exclude})
  }

  return (
    <Card
      icon="history"
      title="Claim"
      onSearch={handleSearchInput}
      endAdornment={
        activeFilter && (
          <Filter
            id="filter-claim"
            onApply={handleFilterApply}
            listFilter={activeFilter}
          >
            <ButtonFilter>
              <BlueIcon>filter_list</BlueIcon>
              <FilterText variant="body2">Filter</FilterText>
            </ButtonFilter>
          </Filter>
        )
      }
    >
      <ClaimPageContainer>
        <WlbTab data={TABS_CONFIG} activeMenu={activeTab} />
        {renderComponent(active, search, filter)}
      </ClaimPageContainer>
    </Card>
  )
}

export default withRouter(ClaimPage)

function renderComponent(active, search, filter) {
  switch (active) {
    default:
    case 'activity':
      return <ClaimActivity searchText={search} filterData={filter} />
    case 'report':
    case 'report-detail':
      return <ClaimReport searchText={search} filterData={filter} />
    case 'settings':
      return <ClaimSettings searchText={search} />
    case 'settings-add':
    case 'settings-edit':
      return <ClaimPolicyFormPage />
  }
}

function mapFilterResults(obj) {
  const ret = {}

  for (const prop in obj) {
    let val = obj[prop]

    if (Array.isArray(val)) {
      val = val.map(item => item.value)
    }

    ret[prop] = val
  }

  return ret
}
