import {Divider, Typography, Avatar, CircularProgress} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState} from 'react'
import {
  PaperContainer,
  EnhancedToolbar,
  StyledMenuItem,
  Flex,
} from '../../../../GlobalStyles'
import {Link} from 'react-router-dom'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {LIST_INDIVIDUAL_COMPETENCY} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import defaultImg from '../../../../assets/images/course-default-thumbnail.jpg'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {DELETE_MICROLEARNING} from '../../../../graphql/mutations/learning/micro-learning/deleteMicrolearning.mutation'
import {useSnackbar} from 'notistack'

const ListComponent = ({search}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })
  const [openDelete, setOpenDelete] = useState(false)
  const [state] = useState({
    id: '',
    name: '',
  })

  const {
    data: listIndividualCompetency,
    error: errorListIndividualCompetency,
    loading: loadingListIndividualCompetency,
  } = useQuery(LIST_INDIVIDUAL_COMPETENCY, {
    variables: {
      company: COMPANY_ID,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      search: `%${search}%`,
    },
  })

  const [deleteMicroLearning] = useMutation(DELETE_MICROLEARNING)

  if (errorListIndividualCompetency) {
    return <div>{JSON.stringify(errorListIndividualCompetency)}</div>
  } else if (loadingListIndividualCompetency) {
    return <CircularProgress />
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleClose = () => {
    setOpenDelete(false)
  }

  const handleMutation = () => {
    deleteMicroLearning({
      variables: {
        id: state.id,
      },
    })
      .then(() => {
        enqueueSnackbar('Data deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        // refetch()
      })
      .catch(e =>
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
        })
      )
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Kompetensi Individu</Typography>
        {/*         <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: '/learning/course/add',
            }}
          >
            Add New Company Competency
          </Button>
        </div> */}
      </EnhancedToolbar>
      <Divider></Divider>

      <MaterialTable
        style={{padding: '1.6rem 1.6rem 0'}}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
        columns={[
          {
            title: '',
            field: 'avatar',
            render: row => {
              return (
                <Flex style={{width: 10}}>
                  <Avatar src={row.global_user.avatar || defaultImg} />
                </Flex>
              )
            },
          },
          {
            title: 'NIP',
            field: 'employeeID',
            render: row => <Flex style={{width: 250}}>{row.regno}</Flex>,
          },
          {
            title: 'Nama',
            field: 'name',
            render: row => <Typography>{row.global_user.name}</Typography>,
          },
          {
            title: 'Jabatan',
            field: 'position',
            render: row => (
              <Typography>{row.company_job_profile?.title}</Typography>
            ),
          },

          {
            title: 'Aksi',
            field: 'id',
            align: 'center',
            sorting: false,
            render: row => (
              <StyledMenuItem
                fontColor="#049BE5"
                component={Link}
                to={{
                  pathname: '/learning/competency/detail/' + row.id,
                  state: {
                    active: 'individual-competency',
                    jobProfile: row.company_job_profile?.id,
                  },
                }}
              >
                Rincian
              </StyledMenuItem>
            ),
            /* render: rowData => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={'/learning/competence/detail/' + rowData.id}
                      >
                        Detail
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={'/learning/competence/edit/' + rowData.id}
                      >
                        Edit
                      </StyledMenuItem>
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            ), */
          },
        ]}
        data={listIndividualCompetency.people_work_placements || []}
        title=""
        options={{
          ...TableOptions,
          selection: false,
          emptyRowsWhenPaging: false,
        }}
        components={{
          Toolbar: () => null,
        }}
        isLoading={loadingListIndividualCompetency}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={listIndividualCompetency?.total?.aggregate.count || 0}
        page={pagination.offset}
        pageSize={pagination.limit}
      />
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        mutation={handleMutation}
        id={state.id}
        name={state.name}
      />
    </PaperContainer>
  )
}

export default ListComponent
