import React, {useState, useEffect} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
} from '../../../../GlobalStyles'
import {
  Button,
  IconButton,
  Tab,
  Tabs,
  CircularProgress,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import moment from 'moment'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {GET_CLASSROOM_BY_VERSION} from '../../../../graphql/queries'
import {
  UPDATE_VERSION_CROSS_MENTORING,
  KICK_CROSS_MENTORING_MEMBER,
  ADD_MEMBER_VERSION_CROSS_MENTORING,
} from '../../../../graphql/mutations'
import {holdingRolesContext} from '../../../../utils/helpers'
import {useSnackbar} from 'notistack'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AboutTab from './add-version-step/AboutStepComponent'
import MentorTab from './add-version-step/MentorStepComponent'
import MenteeTab from './add-version-step/MenteeStepComponent'
import Loading from '../../../../components/loading/LoadingComponent'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
    overflowX: 'auto',
  },
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const EditVersionComponent = props => {
  const versionId = props.match.params.id
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const [tabsValue, setTabsValue] = useState(0)
  const [isError, setIsError] = useState(false)
  const [isErrorDate, setIsErrorDate] = useState(false)
  const [errorDate, setErrorDate] = useState('')
  const [mentors, setMentors] = useState([])
  const [mentees, setMentees] = useState([])
  const [loading, setLoading] = useState(false)

  const generateDate = val => {
    if (val) {
      return moment(new Date()).format('DD-MM-YYYY')
    } else {
      return moment(new Date()).format('YYYY-MM-DD')
    }
  }

  const [dataContext, setDataContext] = useState({
    id: null,
    aboutStep: {
      name: '',
      description: '',
      start_date: generateDate(),
      end_date: generateDate(),
      status: '',
    },
  })

  const [mutationUpdateVersion] = useMutation(UPDATE_VERSION_CROSS_MENTORING)
  const [mutationDeleteMember] = useMutation(KICK_CROSS_MENTORING_MEMBER)
  const [mutationUpdateMember] = useMutation(ADD_MEMBER_VERSION_CROSS_MENTORING)
  const {data, error, refetch, loading: loadingData} = useQuery(
    GET_CLASSROOM_BY_VERSION,
    {
      variables: {
        id: versionId,
      },
      ...holdingRolesContext,
    }
  )

  useEffect(() => {
    refetch()
  }, [])

  const handleClickCancel = () => {
    props.history.goBack()
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const handleSubmit = () => {
    const value = data.classroom_cross_mentoring_versions[0]
    const _allMembers = value.classroom_cross_mentoring_mentees
    const {
      name,
      description,
      start_date,
      end_date,
      status,
    } = dataContext.aboutStep
    if (name === '' || description === '' || status === '') {
      setIsError(true)
    } else if (end_date < start_date) {
      setIsErrorDate(true)
      setErrorDate('Date must be more than start date')
    } else if (mentors.length === 0 || mentees.length === 0) {
      setIsError(true)
    } else {
      setLoading(true)
      const dataToSend = {
        mentoringId: dataContext.id,
        name: name,
        description: description,
        start_date: start_date,
        end_date: end_date,
        status: status,
      }
      const _data = {
        name: name,
        description: description,
        start_date: start_date,
        end_date: end_date,
        status: status,
      }

      let _members = []
      let toDeleteMember = []
      _allMembers &&
        _allMembers.map(val => {
          toDeleteMember.push(val.member_id)
        })
      mentors &&
        mentors.map(val => {
          _members.push({
            user_work_id: val.id,
            versioning_id: dataContext.id,
            is_mentee: false,
            is_mentor: true,
          })
        })
      mentees &&
        mentees.map(val => {
          _members.push({
            user_work_id: val.id,
            versioning_id: dataContext.id,
            is_mentee: true,
            is_mentor: false,
          })
        })

      mutationUpdateVersion({
        variables: dataToSend,
        ...holdingRolesContext,
      })
        .then(() => {
          setDataContext({
            ...dataContext,
            aboutStep: {
              ..._data,
            },
          })
          // handleDeleteMember(toDeleteMember, _members)
          if (toDeleteMember.length > 0) {
            handleDeleteMember(toDeleteMember, _members)
          } else {
            handleUpdateMember(_members)
          }
        })
        .catch(() => {
          setLoading(false)
          enqueueSnackbar(`Update Version Failed`, {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleDeleteMember = (toDelete, toUpdate) => {
    mutationDeleteMember({
      variables: {memberId: toDelete},
      ...holdingRolesContext,
    })
      .then(() => {
        handleUpdateMember(toUpdate)
      })
      .catch(() => {
        setLoading(false)
        enqueueSnackbar(`Update Member Failed`, {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleUpdateMember = toUpdate => {
    mutationUpdateMember({
      variables: {objects: toUpdate},
      ...holdingRolesContext,
    })
      .then(() => {
        setLoading(false)
        setIsError(false)
        refetch()
        enqueueSnackbar(`All Changes have been saved`, {
          variant: 'success',
          autoHideDuration: 1000,
        })
        props.history.push({
          pathname: '/holding-learning/cross-mentoring',
          state: {active: 'cross-mentoring-list'},
        })
      })
      .catch(() => {
        setLoading(false)
        enqueueSnackbar(`Submit Member Failed`, {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  useEffect(() => {
    if (!loadingData && data) {
      const value = data.classroom_cross_mentoring_versions[0]
      const _allMembers = value.classroom_cross_mentoring_mentees
      let _mentors = []
      let _mentees = []

      setDataContext({
        id: value.id,
        aboutStep: {
          name: value.name,
          description: value.description,
          start_date: value.start_date,
          end_date: value.end_date,
          status: value.status,
        },
      })

      if (_allMembers) {
        _allMembers.map(val => {
          if (val.is_mentor) {
            _mentors.push({
              member_id: val.member_id,
              ...val.people_work_placement,
            })
          }
          if (val.is_mentee) {
            _mentees.push({
              member_id: val.member_id,
              ...val.people_work_placement,
            })
          }
        })
      }

      const uniqMentor = [...new Set(_mentors)]
      const uniqMentee = [...new Set(_mentees)]

      setMentors(uniqMentor)
      setMentees(uniqMentee)
    }
  }, [data, loadingData])

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link onClick={handleClickCancel}>
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6">Edit Version</AddEditTitle>
        </FormTitleContainer>
        <div style={{position: 'relative'}}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            disabled={loading}
          >
            Save
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </FormToolbar>
      <Tabs
        value={tabsValue}
        onChange={handleChangeTabs}
        indicatorColor="secondary"
        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
      >
        <Tab label="About" className={classes.tabRoot} />
        <Tab label="Mentor" className={classes.tabRoot} />
        <Tab label="Mentee" className={classes.tabRoot} />
      </Tabs>
      <div style={{padding: '2rem'}}>
        {tabsValue === 0 ? (
          <AboutTab
            isError={isError}
            dataContext={dataContext}
            setDataContext={setDataContext}
            isErrorDate={isErrorDate}
            errorDate={errorDate}
          />
        ) : tabsValue === 1 ? (
          <MentorTab
            isError={isError}
            assignee={mentors}
            setAssignee={setMentors}
          />
        ) : (
          <MenteeTab
            isError={isError}
            assignee={mentees}
            setAssignee={setMentees}
          />
        )}
      </div>
    </PaperContainer>
  )
}

export default withRouter(EditVersionComponent)
