import gql from 'graphql-tag'

export const GET_DEPENDANT_RELATIONSHIPS = gql`
  query($search: String, $limit: Int, $offset: Int) {
    relationships: people_dependant_relationships(
      where: {name: {_ilike: $search}, deletedAt: {_is_null: true}}
      order_by: {name: asc}
      limit: $limit
      offset: $offset
    ) {
      id
      name
    }
  }
`
