import React, {useState} from 'react'
import {PaperContainer, EnhancedToolbar} from '../../../GlobalStyles'
import {
  Typography,
  Button,
  Divider,
  Checkbox,
  TablePagination,
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {GET_LIST_COMPANY_FILE} from '../../../graphql/queries/document/company-file/getListCompanyFIle.query'
import moment from 'moment'
import document from '../../../assets/images/document.svg'
import photo from '../../../assets/images/photo.svg'
import DeletePopup from '../../shared-components/popup/DeletePopup'
import {DELETE_COMPANY_FILE} from '../../../graphql/mutations/document/company-file/deleteCompanyFile'
import {useSnackbar} from 'notistack'

const useStyles = makeStyles(() => ({
  table: {
    // minHeight: "600px"
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px 20px',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '18vw',
    fontSize: 12,
  },
  itemName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '24vw',
    fontSize: 12,
  },
  checkbox: {
    minWidth: '8vw',
    display: 'flex',
    flexDirection: 'row',
    marginRight: 8,
  },
  action: {
    width: 'auto',
  },
  img: {
    marginLeft: '1.2rem',
  },
}))

export default function CompanyFileList(props) {
  const {searchText} = props
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [page, setPage] = React.useState(0)
  const [open, setOpen] = React.useState({
    open: false,
    id: '',
    name: '',
  })
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const {data} = useQuery(GET_LIST_COMPANY_FILE, {
    wlb_skipPatch: true,
    variables: {
      search: `%${searchText}%`,
    },
  })
  const [deleteCompanyFle] = useMutation(DELETE_COMPANY_FILE)

  const row = data && data.company_files

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = row.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClick = (event, name) => {
    event.preventDefault()
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleClickDelete = res => {
    setOpen({
      open: true,
      id: res.id,
      name: res.name,
    })
  }

  const handleCloseDelete = () => {
    setOpen({
      open: false,
      id: '',
      name: '',
    })
  }

  const onDeleteFile = () => {
    const selectedId = []
    selectedId.push(open.id)

    deleteCompanyFle({
      variables: {
        id: selectedId,
      },
    })
      .then(() => {
        enqueueSnackbar('Company file deleted', {
          variant: 'success',
          autoHideDuration: 3000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Delete failed, please try again later', {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const fileCheck = file => {
    const nameSplited = file.split('.')
    const idx = nameSplited.length - 1
    const res = nameSplited[idx]

    if (
      res === 'doc' ||
      res === 'docx' ||
      res === 'xls' ||
      res === 'pdf' ||
      res === 'xlsx' ||
      res === 'ppt'
    ) {
      return document
    } else {
      return photo
    }
  }

  const isSelected = name => selected.indexOf(name) !== -1

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Company File</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: `/document/company-file/upload-file`,
              state: {active: 'upload-file'},
            }}
          >
            Upload File
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider />
      <div className={classes.table}>
        <div className={classes.row}>
          <div className={classes.itemName}>
            <div className={classes.checkbox}>
              <Checkbox
                indeterminate={
                  selected.length > 0 &&
                  selected.length < (data && data.company_files.length)
                }
                checked={
                  data &&
                  data.company_files.length > 0 &&
                  selected.length === (data && data.company_files.length)
                }
                onChange={handleSelectAllClick}
                inputProps={{'aria-label': 'select all desserts'}}
              />
            </div>
            File Name
          </div>
          <div className={classes.item}>Type</div>
          <div className={classes.item}>Upload Date</div>
          <div className={classes.action}>Action</div>
        </div>
        {data &&
          data.company_files
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((res, index) => {
              const isItemSelected = isSelected(res.name)
              const labelId = `enhanced-table-checkbox-${index}`
              return (
                <div key={index} className={classes.row}>
                  <div className={classes.itemName}>
                    <div className={classes.checkbox}>
                      <Checkbox
                        onChange={event => handleClick(event, res.name)}
                        checked={isItemSelected}
                        inputProps={{'aria-labelledby': labelId}}
                      />
                      <a
                        without
                        rel="noopener noreferrer"
                        target="_blank"
                        href={res.file}
                      >
                        <img
                          src={fileCheck(res.file)}
                          className={classes.img}
                          alt="doc"
                          style={{width: 24}}
                        />
                      </a>
                    </div>
                    {res.name}
                  </div>
                  <div className={classes.item}>
                    {res.global_file_type.name}
                  </div>
                  <div className={classes.item}>
                    {moment(res.date_added).format('L')}
                  </div>
                  <div className={classes.action}>
                    {/* <IconButton>
                    <MoreHorizIcon />
                  </IconButton> */}
                    <Typography
                      variant="body2"
                      style={{cursor: 'pointer'}}
                      color="error"
                      onClick={() => handleClickDelete(res)}
                    >
                      Delete
                    </Typography>
                  </div>
                </div>
              )
            })}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data && data.company_files.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <DeletePopup
        open={open.open}
        handleClose={handleCloseDelete}
        id={open.id}
        name={open.name}
        mutation={() => onDeleteFile()}
      />
    </PaperContainer>
  )
}
