import gql from 'graphql-tag'

export const SAVE_INDIVIDUAL_PERFORMANCE = gql`
  mutation($objects: [performance_individual_versions_insert_input!]!) {
    insert_performance_individual_versions(objects: $objects) {
      affected_rows
    }
  }
`
export const EDIT_INDIVIDUAL_PERFORMANCE = gql`
  mutation(
    $id: Int!
    $goal: float8
    $multirater: float8
    $nps: float8
    $ogf: float8
    $index_score: float8
  ) {
    update_performance_individuals(
      _set: {
        kpi_score: $goal
        multirater_score: $multirater
        nps_score: $nps
        ogf_score: $ogf
        index_score: $index_score
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const DELETE_INDIVIDUAL_PERFORMANCE = gql`
  mutation($id: Int) {
    update_performance_individual_versions(
      _set: {deletedAt: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
