import gql from 'graphql-tag'

export const REMOVE_CATEGORY = gql`
  mutation($category: bigint!) {
    update_competency_categories(
      where: {id: {_eq: $category}}
      _set: {deleted_at: "now()"}
    ) {
      affected_rows
    }
  }
`

export const CHECK_REMOVE_CATEGORY = gql`
  query($category: Int!) {
    competency_dictionaries_aggregate(where: {category: {_eq: $category}}) {
      aggregate {
        count
      }
    }
  }
`
