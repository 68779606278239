import React, {useState} from 'react'
import {Alert} from '@material-ui/lab'
import moment from 'moment'
import ExportInformationFailedPopup from './ExportInformationFailedPopup'

const ExportBaloon = ({
  data,
  closeBaloonMutation,
  retry,
  isReport,
  isPayroll,
  availableToDownloadExcel = true,
  feature = '',
  availableToSeeProblem = false,
  isLastGenerateStatus = false,
}) => {
  const [openInformationFailedPopup, setOpenInformationFailedPopup] = useState(
    false
  )

  const lastGenetate = ` (Terakhir dibuat pada ${moment(
    data &&
      data.company_export_data_logs &&
      data.company_export_data_logs.length > 0 &&
      data.company_export_data_logs[0].date_added
  ).format(isPayroll ? 'LLL' : 'll')}) `

  const anchorStyle = {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#ef4d5e',
    fontWeight: 'bold',
  }

  return (
    <>
      {data &&
      data.company_export_data_logs &&
      data.company_export_data_logs.length > 0 &&
      data &&
      data.company_export_data_logs &&
      data.company_export_data_logs[0].status !== 'WAITING' &&
      !data.company_export_data_logs[0].viewed ? (
        <Alert
          color={
            data.company_export_data_logs[0].status === 'FAILED'
              ? 'error'
              : 'success'
          }
          severity={
            data.company_export_data_logs[0].status === 'FAILED'
              ? 'error'
              : 'success'
          }
          style={{marginBottom: 8}}
          onClose={
            data.company_export_data_logs[0].status === 'FAILED' &&
            closeBaloonMutation
          }
        >
          {(function() {
            if (data.company_export_data_logs[0].status === 'WAITING') {
              return (
                isReport
                  ? 'Download data'
                  : isPayroll
                  ? `Generate Data is still in progress. Don’t worry, you can still do other activities`
                  : 'Generate Data',
                ' is processing! We’ll send you an email when it’s ready.'
              )
            } else if (data.company_export_data_logs[0].status === 'FAILED') {
              return (
                <>
                  <span style={{color: '#ef4d5e'}}>
                    Generate data has failed!{' '}
                  </span>
                  {availableToSeeProblem ? (
                    <a
                      onClick={() => setOpenInformationFailedPopup(true)}
                      style={{...anchorStyle}}
                    >
                      See The Problem
                    </a>
                  ) : (
                    <a onClick={retry} style={{...anchorStyle}}>
                      Retry
                    </a>
                  )}
                </>
              )
            } else {
              return (
                <>
                  <span style={{color: '#4caf50'}}>
                    {`${
                      isReport ? 'Download' : 'Pembuatan'
                    } data sudah selesai! ${
                      !isLastGenerateStatus ? lastGenetate : ''
                    } `}
                  </span>
                  {availableToDownloadExcel && (
                    <a
                      href={
                        data &&
                        data.company_export_data_logs &&
                        data.company_export_data_logs[0].data_url
                      }
                      rel="noreferrer"
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: '#4caf50',
                        fontWeight: 'bold',
                      }}
                    >
                      Unduh sekarang
                    </a>
                  )}

                  {isLastGenerateStatus && (
                    <span style={{color: '#4caf50'}}>{lastGenetate}</span>
                  )}
                </>
              )
            }
          })()}
        </Alert>
      ) : data &&
        data.company_export_data_logs &&
        data.company_export_data_logs.length > 0 &&
        data.company_export_data_logs[0].status === 'WAITING' ? (
        <Alert color={'info'} severity={'info'} style={{marginBottom: 8}}>
          {isReport ? 'Download data' : 'Generate Data'}{' '}
          {isPayroll
            ? `is still in progress. Don’t worry, you can still do other activities`
            : `is processing! We’ll send you an email when it’s ready.`}
        </Alert>
      ) : (
        true
      )}
      <ExportInformationFailedPopup
        title={`${isReport ? 'Download' : 'Generate'} ${feature}`}
        reports={data?.company_export_data_logs?.[0]?.reports}
        retry={retry}
        setOpenInformationFailedPopup={setOpenInformationFailedPopup}
        openInformationFailedPopup={openInformationFailedPopup}
      />
    </>
  )
}

export default ExportBaloon
