import React from 'react'
import {TextField, InputAdornment, Typography} from '@material-ui/core'
import {
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildContainers,
} from '../../../../../GlobalStyles'

const StepTwo = props => {
  const {allDatas, setAllData, isError, totalWeight} = props

  const addData = event => {
    setAllData({...allDatas, [event.target.name]: event.target.value})
  }

  return (
    <div style={{paddingBottom: '2rem'}}>
      <FormChildContainers>
        <FormChildTitle>
          <TitleNumber>1.</TitleNumber>
          <TitleName>Goals Weight</TitleName>
        </FormChildTitle>
        <TextField
          variant="outlined"
          placeholder="Add Goals Weight"
          name="kpiWeight"
          value={allDatas.kpiWeight}
          onChange={addData}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          type="number"
        ></TextField>
      </FormChildContainers>

      <FormChildContainers>
        <FormChildTitle>
          <TitleNumber>2.</TitleNumber>
          <TitleName>Multirater Weight</TitleName>
        </FormChildTitle>
        <TextField
          variant="outlined"
          placeholder="Add Multirater Weight"
          name="multilaterWeight"
          value={allDatas.multilaterWeight}
          onChange={addData}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          type="number"
        ></TextField>
      </FormChildContainers>
      <FormChildContainers>
        <FormChildTitle>
          <TitleNumber>3.</TitleNumber>
          <TitleName>NPS Weight</TitleName>
        </FormChildTitle>
        <TextField
          variant="outlined"
          placeholder="Add NPS Weight"
          name="npsWeight"
          value={allDatas.npsWeight}
          onChange={addData}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          type="number"
        ></TextField>
      </FormChildContainers>
      <FormChildContainers>
        <FormChildTitle>
          <TitleNumber>4.</TitleNumber>
          <TitleName>OGF Weight</TitleName>
        </FormChildTitle>
        <TextField
          variant="outlined"
          placeholder="Add OGF Weight"
          name="ogfWeight"
          value={allDatas.ogfWeight}
          onChange={addData}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          type="number"
        ></TextField>
      </FormChildContainers>
      {isError && totalWeight !== 100 && (
        <Typography variant="body2" color="error">
          Total weight must be 100%
        </Typography>
      )}
    </div>
  )
}

export default StepTwo
