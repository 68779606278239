import React, {useState, useEffect} from 'react'
import MaterialTable from 'material-table'
import {Button, CircularProgress} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {Link} from 'react-router-dom'
import helperStyle, {
  TableOptions,
  TextBlueBold,
  CenterWrapper,
  TableEmployeeStyled,
  pageSizeOption,
  MediumTextBlackBold,
} from '../../PayrollPageStyles'
import {EnhancedToolbar, GenerateTooltip} from '../../../../../GlobalStyles'
import ExportBaloon from '../../../../../components/exportExcel/ExportBaloon'
import AlertDialog from '../../../../shared-components/popup/ResultPopup'
import EmployeeAvatar from '../../../../shared-components/EmployeeAvatar'
import useDownloadReport from '../../../../../hooks/useDownloadReport'
import {
  convertToRoundedRupiah,
  employeeColumnSort,
} from '../../../../../utils/helpers'
import {GET_EMPLOYEE_PAYROLL_PROCESS} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'

const StepCalculation = props => {
  const {period, month, location, searchText, filterData} = props
  const classes = helperStyle()

  const [openGenerateData, setOpenGenerateData] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOption[0])
  const slicedParams = location.search.split('=')

  const TABLE_PAYROLL_PROCESS = 'finance_payroll_process'
  const {
    dataBaloon: dataBaloonGenerateData,
    onDownload,
    onCloseBaloon,
  } = useDownloadReport({
    table: TABLE_PAYROLL_PROCESS,
    filter: {period_year: period, period_month: month},
  })

  const {data: employeePayrollProcess, loading, refetch, error} = useQuery(
    GET_EMPLOYEE_PAYROLL_PROCESS,
    {
      variables: {
        company: COMPANY_ID,
        year: period,
        month: Number(slicedParams[slicedParams.length - 1]),
        offset: rowsPerPage * currentPage,
        limit: rowsPerPage,
        position:
          JSON.stringify(filterData.position) === '[]'
            ? null
            : filterData.position,
        organization:
          JSON.stringify(filterData.organization) === '[]'
            ? null
            : filterData.organization,
        job_profile:
          JSON.stringify(filterData.job_profile) === '[]'
            ? null
            : filterData.job_profile,
        rank: JSON.stringify(filterData.rank) === '[]' ? null : filterData.rank,
        office:
          JSON.stringify(filterData.office) === '[]' ? null : filterData.office,
        search: `%${searchText}%`,
      },
      ...GQL_FINANCE_OPTIONS,
    }
  )

  useEffect(() => {
    setCurrentPage(0)
  }, [period, searchText, filterData, rowsPerPage])

  const handleGenerateData = () => {
    setOpenGenerateData(prev => !prev)

    if (openGenerateData) {
      onDownload({
        variables: {
          table: TABLE_PAYROLL_PROCESS,
          filter: {period_year: period, period_month: month},
        },
      })
    }
  }

  if (
    dataBaloonGenerateData?.company_export_data_logs?.length > 0 &&
    dataBaloonGenerateData?.company_export_data_logs?.[0]?.status ===
      'COMPLETED'
  ) {
    refetch()
  }

  return (
    <>
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {!employeePayrollProcess && loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}

      {employeePayrollProcess && (
        <>
          <EnhancedToolbar>
            <MediumTextBlackBold>
              Employee Salary Component Calculation
            </MediumTextBlackBold>

            <GenerateTooltip
              title={
                dataBaloonGenerateData?.company_export_data_logs?.[0]
                  ?.status === 'WAITING'
                  ? `You can only generate data once an hour`
                  : ``
              }
              placement="left"
            >
              <span>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={() => setOpenGenerateData(true)}
                  disabled={
                    dataBaloonGenerateData?.company_export_data_logs?.[0]
                      ?.status === 'WAITING'
                  }
                >
                  Generate Data
                </Button>
              </span>
            </GenerateTooltip>
          </EnhancedToolbar>

          <ExportBaloon
            isPayroll
            availableToSeeProblem
            availableToDownloadExcel={false}
            data={dataBaloonGenerateData}
            closeBaloonMutation={onCloseBaloon}
            retry={handleGenerateData}
            feature="Payroll Process"
          />

          <TableEmployeeStyled>
            <MaterialTable
              data={employeePayrollProcess.people_work_payroll}
              options={{...TableOptions}}
              totalCount={employeePayrollProcess.employee_count.aggregate.count}
              isLoading={employeePayrollProcess && loading}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
              onChangePage={page => {
                setCurrentPage(page)
              }}
              columns={[
                {
                  title: 'Employee',
                  field: 'employee',
                  headerStyle: {minWidth: 350},
                  cellStyle: {minWidth: 350},
                  customSort: employeeColumnSort,
                  render: rowData => {
                    return (
                      <EmployeeAvatar
                        src={rowData.employee_placement?.global_user.avatar}
                        regno={rowData.employee_placement?.regno}
                        title={
                          rowData.employee_placement?.company_job_profile.title
                        }
                        global_user={rowData.employee_placement?.global_user}
                        city_name={
                          rowData.employee_placement?.company_job_profile
                            .company_address.global_city.name
                        }
                        province_name={
                          rowData.employee_placement?.company_job_profile
                            .company_address.global_province.name
                        }
                        empStatus={rowData.employee_placement?.status}
                        empEndDate={rowData.employee_placement?.end_date}
                      />
                    )
                  },
                },
                {
                  title: 'Basic Salary',
                  field: 'salary',
                  headerStyle: {minWidth: 175},
                  cellStyle: {minWidth: 175, textAlign: 'left'},
                  customSort: (a, b) => {
                    return a.salary - b.salary
                  },
                  render: rowData =>
                    convertToRoundedRupiah(rowData.salary, false),
                },
                {
                  title: 'Earning',
                  field: 'addition',
                  headerStyle: {minWidth: 175},
                  cellStyle: {minWidth: 175, textAlign: 'left'},
                  customSort: (a, b) => {
                    return a.addition - b.addition
                  },
                  render: rowData =>
                    convertToRoundedRupiah(rowData.addition, false),
                },
                {
                  title: 'Deduction',
                  field: 'deduction',
                  headerStyle: {minWidth: 175},
                  cellStyle: {minWidth: 175, textAlign: 'left'},
                  customSort: (a, b) => {
                    return a.deduction - b.deduction
                  },
                  render: rowData =>
                    convertToRoundedRupiah(rowData.deduction, false),
                },
                {
                  title: 'Total',
                  field: 'total',
                  headerStyle: {minWidth: 175},
                  cellStyle: {minWidth: 175, textAlign: 'left'},
                  customSort: (a, b) => {
                    return a.final_salary - b.final_salary
                  },
                  render: rowData =>
                    convertToRoundedRupiah(rowData.final_salary, false),
                },
                // {
                //   title: 'Status',
                //   field: 'status',
                //   sorting: false,
                //   headerStyle: {minWidth: 175, textAlign: 'center'},
                //   cellStyle: {minWidth: 175, textAlign: 'center'},
                //   render: rowData => {
                //     if (rowData.approver && rowData.finance_personnel) {
                //       return (
                //         <Badge style={{backgroundColor: '#FFA000'}}>
                //           Approved
                //         </Badge>
                //       )
                //     } else if (!rowData.approver && rowData.finance_personnel) {
                //       return <Badge>Need Approval 1</Badge>
                //     } else if (rowData.approver && !rowData.finance_personnel) {
                //       return <Badge>Need Approval 2</Badge>
                //     } else {
                //       return <Badge>Need Approval</Badge>
                //     }
                //   },
                // },
                // {
                //   title: 'Postpone',
                //   field: 'postpone',
                //   headerStyle: {minWidth: 175, textAlign: 'center'},
                //   cellStyle: {minWidth: 175, textAlign: 'center'},
                //   render: () => {
                //     return '-'
                //   },
                // },
                {
                  title: 'Action',
                  field: 'action',
                  cellStyle: {minWidth: 100},
                  sorting: false,
                  render: rowData => {
                    return (
                      <Link
                        to={{
                          pathname: `/financial/payroll/payroll-process/payroll-detail/${rowData.id}`,
                          state: {
                            active: 'payroll-detail',
                            payrollId: rowData.id,
                            year: period,
                            month: Number(
                              slicedParams[slicedParams.length - 1]
                            ),
                          },
                        }}
                        className={classes.link}
                      >
                        <TextBlueBold>Details</TextBlueBold>
                      </Link>
                    )
                  },
                },
              ]}
            />
          </TableEmployeeStyled>

          {openGenerateData && (
            <AlertDialog
              open={openGenerateData}
              handleClose={() => setOpenGenerateData(false)}
              mutation={handleGenerateData}
              feature="Warning"
              message="Current payroll will be rewritten, are you sure?"
              status="cancel"
              type="warning"
              positiveTitleButton="Yes"
            />
          )}
        </>
      )}
    </>
  )
}

export default StepCalculation
