import gql from 'graphql-tag'

export const GET_EMPLOYEE_SALARY = gql`
  query GetEmployeeSettings(
    $company: uuid!
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $search: String
  ) {
    employee_count: v_employee_finances_aggregate(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        company: {_eq: $company}
        company_job_profile: {
          _and: [
            {id: {_in: $job_profile}}
            {organization: {_in: $organization}}
            {position: {_in: $position}}
            {rank: {_in: $rank}}
            {office_address: {_in: $office}}
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    employees: v_employee_finances(
      limit: $limit
      offset: $offset
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        company: {_eq: $company}
        company_job_profile: {
          _and: [
            {id: {_in: $job_profile}}
            {organization: {_in: $organization}}
            {position: {_in: $position}}
            {rank: {_in: $rank}}
            {office_address: {_in: $office}}
          ]
        }
      }
    ) {
      id
      regno
      status
      start_date
      end_date
      company_job_profile {
        id
        title
        company_employee_position {
          id
          name
        }
        company_organization {
          id
          name
        }
        company_employee_rank {
          id
          name
        }
        company_address {
          id
          office_name
          global_province {
            id
            name
          }
          global_city {
            id
            name
          }
        }
      }
      global_user {
        id
        name
        avatar
        people_work_placements(
          limit: 1
          order_by: [{end_date: desc_nulls_first}]
          where: {people_work_salaries: {start_date: {_lte: "now()"}}}
        ) {
          start_date
          end_date
          people_work_salaries(
            limit: 1
            order_by: [{end_date: desc_nulls_first}]
            where: {start_date: {_lte: "now()"}}
          ) {
            id
            currency
            value
            salary_fields
            ktp
            npwp
            start_date
            end_date
            people_profile_bank {
              id
              account_name
              account_number
              creator_company
              bank
              bank_name
              branch
              bank_fields
            }
          }
        }
      }
    }
  }
`

export const GET_EMPLOYEE_BANK_LIST = gql`
  query($employee: uuid!) {
    people_profile_banks(
      where: {user: {_eq: $employee}, deletedAt: {_is_null: true}}
    ) {
      id
      account_name
      account_number
      bank_name
      branch
      global_bank {
        id
        name
      }
      bank_fields
    }
  }
`
