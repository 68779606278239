import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import {
  BoxCustom,
  BoxCustomContainer,
  CardContainer,
  CardContent,
  CardHeader,
  DatePickerWrap,
  IconCustom,
  IconText,
  SearchContainer,
} from './CardStyles'
import useInputState from '../../hooks/userInputState'
import {TextField, makeStyles, InputAdornment} from '@material-ui/core'
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {BlueIcon} from '../../GlobalStyles'
import EmployeeFilterPopUp from '../../pages/shared-components/popup/EmployeeFilterPopUp'
import FilterPopup from '../../pages/shared-components/popup/FilterPopup'

const useStyles = makeStyles(() => ({
  thinTextfield: {
    padding: '12px 14px',
  },
  rootText: {
    width: '100%',
    background: '#fff',
    borderRadius: 5,
  },
}))

const Card = props => {
  const {endAdornment, setFilterData, isNotPayroll, year: yearProps} = props

  const classes = useStyles()

  const [period, onChangePeriod] = useState(new Date())
  const [currentMonth, setCurrentMonth] = useState(0)
  const [search, onSearch] = useInputState('')
  const [year, onChangeYear] = useInputState(yearProps)

  let searchable = props.searchable || false
  let showPeriod = props.showPeriod || false
  let filter = props.filter || false
  let showMonthYearPeriod = props.showMonthYearPeriod || false
  let noMaxDate = props.noMaxDate || false

  let showYearPicker = props.showYearPicker || false
  if (props.location.state) {
    searchable = props.location.state.searchable
  } else {
    searchable = props.state ? props.state.searchable : searchable
  }
  if (searchable && props.onSearch) {
    props.onSearch(search)
  }

  if (props.location.state) {
    showPeriod = props.location.state.showPeriod
    filter = props.location.state.filter || props.filter
    showMonthYearPeriod = props.location.state.showMonthYearPeriod
    showYearPicker = props.location.state.showYearPicker
    noMaxDate = props.location.state.noMaxDate
  } else {
    showPeriod = props.state ? props.state.showPeriod : showPeriod
    showMonthYearPeriod = props.state
      ? props.state.showMonthYearPeriod
      : showPeriod
    showYearPicker = props.state ? props.state.showYearPicker : showYearPicker
  }
  if (props.onChangePeriod) {
    props.onChangePeriod(period)
  }

  if (props.onChangeYear) {
    props.onChangeYear(year)
  }
  const listOfYears = []
  for (let year = 2000; year <= Number(moment().format('YYYY')); year++) {
    listOfYears.push(year)
  }

  useEffect(() => {
    if (props.location.search) {
      const currentParam = props.location.search
      if (currentParam.includes('year') && !showMonthYearPeriod) {
        const currentYear = Number(currentParam.split('=')[1].slice(0, 4))
        onChangePeriod(currentYear)
      }
      if (showMonthYearPeriod) {
        if (currentParam.includes('year') && currentParam.includes('month')) {
          const [year, month] = currentParam.split('&')
          setCurrentMonth(Number(month.slice(6)) - 1)
          onChangePeriod(year.slice(6))
        }
      }
    }
  }, [props.location])

  return (
    <CardContainer>
      <CardHeader>
        <BoxCustomContainer style={{maxWidth: props.longTitle && '200px'}}>
          <BoxCustom display="flex" flexDirection="row" alignItems="center">
            <IconCustom>{props.icon}</IconCustom>
            <IconText>{props.title}</IconText>
          </BoxCustom>
        </BoxCustomContainer>

        {showYearPicker && (
          <DatePickerWrap>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                views={['year']}
                className="date-picker-dialog"
                KeyboardButtonProps={{'aria-label': 'change date'}}
                inputVariant="outlined"
                format="yyyy"
                placeholder="Choose Period"
                value={year}
                color={'primary'}
                onChange={onChangeYear}
              />
            </MuiPickersUtilsProvider>
          </DatePickerWrap>
        )}

        {showPeriod && (
          <DatePickerWrap>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                maxDate={noMaxDate ? undefined : new Date()}
                margin="normal"
                className="date-picker-dialog"
                KeyboardButtonProps={{'aria-label': 'change date'}}
                inputVariant="outlined"
                views={['year']}
                value={
                  isNaN(period) || period === undefined || period === null
                    ? new Date(`${new Date().getFullYear()}`)
                    : new Date(`${period}`)
                }
                onChange={value => {
                  onChangePeriod(value)
                  if (props.location.search) {
                    const currentParam = props.location.search
                    if (currentParam.includes('year')) {
                      const replacedParam = currentParam.replace(
                        /(?:(?:19|20)[0-9]{2})/,
                        moment(value).format('YYYY')
                      )
                      props.history.push({
                        search: replacedParam,
                        state: {
                          ...props.location.state,
                          active: 'step-payroll-process',
                        },
                      })
                    }
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </DatePickerWrap>
        )}
        {showMonthYearPeriod && (
          <>
            <DatePickerWrap>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  margin="normal"
                  className="date-picker-dialog"
                  KeyboardButtonProps={{'aria-label': 'change date'}}
                  inputVariant="outlined"
                  views={['month', 'year']}
                  format="MMM yyyy"
                  value={moment().set({year: period, month: currentMonth})}
                  disabled
                />
              </MuiPickersUtilsProvider>
            </DatePickerWrap>
          </>
        )}
        <SearchContainer>
          {searchable && (
            <TextField
              variant="outlined"
              value={search}
              onChange={onSearch}
              type={props.hidden}
              placeholder="Cari"
              className={classes.rootText}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{display: props.hidden ? 'none' : 'flex'}}
                  >
                    <BlueIcon>search</BlueIcon>
                  </InputAdornment>
                ),
                classes: {
                  input: classes.thinTextfield,
                },
                endAdornment: endAdornment || (
                  <>
                    {filter && (
                      <div>
                        {isNotPayroll ? (
                          <FilterPopup
                            methodFromChild={props.tabFilter.ref}
                            left
                            setFilterData={setFilterData}
                          >
                            {props.tabFilter}
                          </FilterPopup>
                        ) : (
                          <EmployeeFilterPopUp
                            methodFromChild={props.tabFilter.ref}
                            left
                            setFilterData={setFilterData}
                          >
                            {props.tabFilter}
                          </EmployeeFilterPopUp>
                        )}
                      </div>
                    )}
                  </>
                ),
              }}
            ></TextField>
          )}
        </SearchContainer>
      </CardHeader>
      <CardContent>{props.children}</CardContent>
    </CardContainer>
  )
}

export default withRouter(Card)
