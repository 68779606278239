import React from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'

import {
  makeStyles,
  Box,
  Avatar,
  Toolbar,
  Divider,
  Typography,
  IconButton,
  Icon,
  CircularProgress,
} from '@material-ui/core'

import {PaperContainer} from '../../../../../GlobalStyles'
import {CATEGORY_COLOR, InfoTable} from '../../ClaimLimitPageStyles'

import {convertToRupiah, dateENOrUS} from '../../../../../utils/helpers'
import {
  GET_EMPLOYEE_SUMMARY,
  GET_EMPLOYEE_TRANSACTION_DETAILS,
} from '../../../../../graphql/queries/budget/limit/getEmployeeBalance.query'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },

  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginRight: theme.spacing(3),
  },
  employeeName: {
    color: theme.palette.common.black,
    fontWeight: '700',
  },
  employeeId: {
    color: theme.palette.primary.main,
    fontWeight: '700',
  },
  employeePosition: {
    color: theme.palette.text.secondary,
  },

  amount: {
    fontSize: theme.spacing(3),
    fontWeight: '700',
    marginRight: theme.spacing(1),
  },

  title: {
    fontWeight: '700',
  },
}))

function EmployeeTransactionDetails(props) {
  const {employeeID, transactionID} = props.match.params

  const styles = useStyles()

  const {data, loading, error} = useQuery(GET_EMPLOYEE_SUMMARY, {
    wlb_skipPatch: true,
    variables: {
      employeePlacementID: employeeID,
    },
  })

  return (
    <PaperContainer>
      <Toolbar>
        <IconButton
          aria-label="Go back to previous page"
          onClick={props.history.goBack}
          edge="start"
          className={styles.backButton}
        >
          <Icon>arrow_back</Icon>
        </IconButton>

        <Typography variant="subtitle1" style={{fontWeight: '600'}}>
          Transaction Detail
        </Typography>
      </Toolbar>

      <Divider />

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <div>{JSON.stringify(error)}</div>
      ) : (
        <>
          <Box display="flex" alignItems="center" p={3}>
            <EmployeeSummary data={data.summary} />
          </Box>

          <Divider />

          <TransactionDetails transactionID={transactionID} />
        </>
      )}
    </PaperContainer>
  )
}

export default withRouter(EmployeeTransactionDetails)

function EmployeeSummary(props) {
  const {data} = props

  const styles = useStyles()

  return (
    <>
      <Avatar
        alt={data.user.name}
        src={data.user.avatar}
        variant="rounded"
        className={styles.avatar}
      />

      <Box display="flex" flexDirection="column">
        <Typography className={styles.employeeName}>
          {data.user.name}
        </Typography>
        <Typography className={styles.employeeId}>{data.regno}</Typography>
        <Typography className={styles.employeePosition}>
          {data.profile.position}
        </Typography>
      </Box>
    </>
  )
}

function TransactionDetails(props) {
  const {transactionID} = props

  const styles = useStyles()

  const {data, loading, error} = useQuery(GET_EMPLOYEE_TRANSACTION_DETAILS, {
    wlb_skipPatch: true,
    variables: {
      transactionID: transactionID,
    },
  })

  const details = data?.details

  const formattedDate = React.useMemo(() => dateENOrUS(details?.date), [
    details?.date,
  ])

  const formattedNominal = React.useMemo(
    () => convertToRupiah(Math.abs(details?.nominal)),
    [details?.nominal]
  )

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  const isDeposit = details.nominal >= 0
  const category = isDeposit ? 'In' : 'Out'

  return (
    <>
      <Box display="flex" flexDirection="column" p={3}>
        <Typography className={styles.title}>Nominal {details.type}</Typography>
        <Typography
          className={styles.amount}
          style={{color: CATEGORY_COLOR[category]}}
        >
          {formattedNominal}
        </Typography>
      </Box>

      <Divider />

      <Box p={2}>
        <InfoTable>
          <tbody>
            <tr>
              <th>Transaction ID</th>
              <td>{details.formatted_id || '-'}</td>
            </tr>
            <tr>
              <th>Transaction Date</th>
              <td>{formattedDate}</td>
            </tr>
            <tr>
              <th>Category</th>
              <td style={{color: CATEGORY_COLOR[category]}}>{category}</td>
            </tr>
            <tr>
              <th>Type</th>
              <td>{details.type}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{details.description || '-'}</td>
            </tr>
          </tbody>
        </InfoTable>
      </Box>
    </>
  )
}
