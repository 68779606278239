import gql from 'graphql-tag'

export const UPDATE_TAX_SETTING = gql`
  mutation($address: Int!, $plugin: jsonb) {
    update_company_addresses_by_pk(
      pk_columns: {id: $address}
      _set: {address_misc_fields: $plugin}
    ) {
      id
      address_misc_fields
    }
  }
`
