import React from 'react'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles({
  warpper: {
    display: 'flex',
    flexDirection: 'row',
    height: 270,
    justifyContent: 'space-around',
  },
  barSection: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  barWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 200,
  },
  infoBar: {
    height: 'auto',
    marginTop: 'auto',
    textAlign: 'center',
  },
  bar: {
    width: 60,
    borderRadius: 5,
  },
  title: {
    margin: 0,
    color: '#333',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
  },
  percentage: {
    margin: 0,
    color: '#333',
    fontSize: 14,
    fontWeight: '600',
  },
  totalData: {
    margin: 0,
    color: '#a9a8a8',
    fontSize: 12,
  },
  legendBox: {
    width: 13,
    height: 13,
    borderRadius: 5,
    marginRight: 5,
  },
})

const BarComponent = ({percentage, bgColor, info, total}) => {
  const classes = useStyles()
  return (
    <div className={classes.barSection}>
      <div className={classes.barWrapper}>
        <div
          style={{height: percentage, backgroundColor: bgColor}}
          className={classes.bar}
        />
      </div>
      <div className={classes.infoBar}>
        <p className={classes.title}>
          <div
            className={classes.legendBox}
            style={{backgroundColor: bgColor}}
          />
          {info}
        </p>
        <p className={classes.percentage}>{percentage}</p>
        <p className={classes.totalData}>{`(${total})`}</p>
      </div>
    </div>
  )
}

const getPercent = (data, totalData) =>
  `${((data / totalData) * 100).toFixed(2)}%`

export default function GenderComposition({totalMale, totalFemale}) {
  const classes = useStyles()
  const TOTAL_EMPLOYEE = totalMale + totalFemale
  return (
    <div className={classes.warpper}>
      <BarComponent
        percentage={getPercent(totalMale, TOTAL_EMPLOYEE)}
        bgColor="#014a62"
        info="Laki-laki"
        total={totalMale}
      />
      <BarComponent
        percentage={getPercent(totalFemale, TOTAL_EMPLOYEE)}
        bgColor="#f04ba0"
        info="Perempuan"
        total={totalFemale}
      />
    </div>
  )
}
