import React from 'react'
import { Grid, TextField, MenuItem, Avatar, Button } from '@material-ui/core'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'
import helperStyle, {
  ItemCenter,
  TextContentRed,
} from '../../CrossMentoringPageStyles'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import { GET_CLASSROOM_CATEGORY } from '../../../../../graphql/queries'
import { holdingRolesContext } from '../../../../../utils/helpers'
import { UPLOAD_URL, TOKEN } from '../../../../../utils/globals'
import Axios from 'axios'
import { useSnackbar } from 'notistack'

const BtnChangeAva = withStyles({
  root: {
    backgroundColor: '#0063cc',
    color: '#fff',
    marginTop: '20px',
  },
})(Button)

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
}))

const AboutStepComponent = props => {
  const classes = helperStyle()
  const styles = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { dataContext, setDataContext, isError } = props

  const {
    data: dataCategories,
    loading: loadingCategories,
  } = useQuery(GET_CLASSROOM_CATEGORY, { ...holdingRolesContext })

  const handleChange = name => e => {
    setDataContext({
      ...dataContext,
      aboutStep: {
        ...dataContext.aboutStep,
        [name]: e.target.value,
      },
    })
  }

  const handleChangeQuota = name => e => {
    const re = /^[0-9\b]+$/

    if (e.target.value === '' || re.test(e.target.value)) {
      setDataContext({
        ...dataContext,
        aboutStep: {
          ...dataContext.aboutStep,
          [name]: e.target.value,
        },
      })
    }
  }

  const handleUploadAvatar = async e => {
    const formData = new FormData()
    const imagefile = e.target.files[0]
    formData.append('file', imagefile)

    await Axios.post(UPLOAD_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true
    })
      .then(res => {
        setDataContext({
          ...dataContext,
          aboutStep: {
            ...dataContext.aboutStep,
            ['avatar_url']: res.data.url,
          },
        })
      })
      .catch(() => {
        enqueueSnackbar(`Upload Image Failed`, {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  return (
    <Grid container>
      <Grid item xs={8}>
        <FormContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>1.</TitleNumber>
              <TitleName>Group Learning Name</TitleName>
            </FormChildTitle>
            <FormChildInput style={{ width: '140%' }}>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
                value={dataContext.aboutStep.name}
                onChange={handleChange('name')}
                placeholder="Enter the name"
                error={!dataContext.aboutStep.name && isError}
                helperText={
                  !dataContext.aboutStep.name && isError
                    ? 'This field is required'
                    : ''
                }
              />
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>2.</TitleNumber>
              <TitleName>Privacy</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
                select
                value={dataContext.aboutStep.privacy}
                onChange={handleChange('privacy')}
                error={!dataContext.aboutStep.privacy && isError}
                helperText={
                  !dataContext.aboutStep.privacy && isError
                    ? 'This field is required'
                    : ''
                }
              >
                <MenuItem value="" disabled>
                  Select Privacy
                </MenuItem>
                {/* <MenuItem value="0">Public Social Learning</MenuItem> */}
                <MenuItem value="1">Private Group Learning</MenuItem>
              </TextField>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Category</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
                select
                value={dataContext.aboutStep.category_id}
                onChange={handleChange('category_id')}
                error={!dataContext.aboutStep.category_id && isError}
                helperText={
                  !dataContext.aboutStep.category_id && isError
                    ? 'This field is required'
                    : ''
                }
              >
                <MenuItem value="" disabled>
                  Select Category
                </MenuItem>
                {loadingCategories
                  ? 'Loading'
                  : dataCategories &&
                  dataCategories.academy_course_categories.map(val => {
                    return (
                      <MenuItem value={val.id} key={val.id}>
                        {val.name}
                      </MenuItem>
                    )
                  })}
              </TextField>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>4.</TitleNumber>
              <TitleName>Group Learning Description</TitleName>
            </FormChildTitle>
            <FormChildInput style={{ width: '140%' }}>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
                multiline
                rows="6"
                value={dataContext.aboutStep.description}
                onChange={handleChange('description')}
                placeholder="Enter the Description"
                error={!dataContext.aboutStep.description && isError}
                helperText={
                  !dataContext.aboutStep.description && isError
                    ? 'This field is required'
                    : ''
                }
              />
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>5.</TitleNumber>
              <TitleName>Group Learning Rules</TitleName>
            </FormChildTitle>
            <FormChildInput style={{ width: '140%' }}>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
                multiline
                rows="6"
                value={dataContext.aboutStep.rules}
                onChange={handleChange('rules')}
                placeholder="Enter the Rules"
                error={!dataContext.aboutStep.rules && isError}
                helperText={
                  !dataContext.aboutStep.rules && isError
                    ? 'This field is required'
                    : ''
                }
              />
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>5.</TitleNumber>
              <TitleName>Quota</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
                value={dataContext.aboutStep.quota}
                onChange={handleChangeQuota('quota')}
                error={!dataContext.aboutStep.rules && isError}
                helperText={
                  !dataContext.aboutStep.rules && isError
                    ? 'This field is required'
                    : ''
                }
              />
            </FormChildInput>
          </FormChildContainer>
        </FormContainer>
      </Grid>
      <Grid item xs={4}>
        <ItemCenter className={classes.width100P}>
          {!dataContext.aboutStep.avatar_url && isError && (
            <TextContentRed className={classes.mb20}>
              Please select an image
            </TextContentRed>
          )}
          <Avatar
            src={dataContext.aboutStep.avatar_url}
            className={
              !dataContext.aboutStep.avatar_url && isError
                ? classes.errorAvatar
                : classes.avatarSize
            }
          />
          <input
            accept="image/*"
            className={styles.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleUploadAvatar}
          />
          <label htmlFor="contained-button-file">
            <BtnChangeAva variant="contained" component="span">
              Change Avatar
            </BtnChangeAva>
          </label>
        </ItemCenter>
      </Grid>
    </Grid>
  )
}

export default AboutStepComponent
