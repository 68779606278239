import React from 'react'
import {useHistory, useLocation, withRouter} from 'react-router-dom'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {useQuery} from '@apollo/react-hooks'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'

import {DEFAULT_PAGE_SIZE} from '../ClaimPageStyles'
import {GET_CLAIM_ACTIVITY_REPORT} from '../../../../graphql/queries/claim/getClaimHistory.query'
import {ArrowBack} from '@material-ui/icons'
import {Box, Typography, Button, Divider, IconButton} from '@material-ui/core'
import {
  PaperContainer,
  EnhancedToolbar,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import {DatePickerWrap} from '../../../../components/card/CardStyles'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import useDownloadReport from '../../../../hooks/useDownloadReport'
import {useDebounced} from '@worklifebeyond/wlb-utils-components'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'

import ClaimReportDetail from './ClaimReportDetail'
import ClaimReportList from './ClaimReportList'

export const optionDate = {
  type: 'month',
  format: 'YYYY-MM-DD',
}

export function useGetParams() {
  const {search} = useLocation()

  const paramsSearch = new URLSearchParams(search)
  const params = {}
  for (const key of paramsSearch.keys()) {
    params[key] = paramsSearch.get(key)
  }
  return params
}

function TitleHeader({isReport, title}) {
  const history = useHistory()
  const params = useGetParams()
  // const location = useLocation()

  const d = moment(params?.date)
  const year = d.format('YYYY')
  const month = d.format('MMM')

  const labelDetail = !isReport ? `- ${month} ${year}` : ''

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      <Box hidden={isReport}>
        <IconButton edge="start" onClick={handleGoBack}>
          <ArrowBack />
        </IconButton>
      </Box>
      <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
        {title || `Claim Report ${labelDetail}`}
      </Typography>
    </Box>
  )
}

function ActionHeader({isReport, onDownload, isDisableButton}) {
  const history = useHistory()
  const params = useGetParams()
  const location = useLocation()
  const currentYear = new Date().getFullYear()
  const year = (isReport ? params?.year : params?.date) || currentYear
  const [openGenerateAlert, setOpenGenerateAlert] = React.useState(false)

  const date = moment(String(year))
  const variables = {
    table: 'people_work_claims',
    filter: {
      start_date: date.startOf(optionDate.type).format(optionDate.format),
      end_date: date.endOf(optionDate.type).format(optionDate.format),
    },
  }

  const handleChange = value => {
    const localeDate = new Date(value).getFullYear().toString()
    history.replace({...location, search: `?year=${localeDate}`})
  }

  const handleCloseAlert = () => {
    setOpenGenerateAlert(false)
  }

  const handleGenerateReport = () => {
    handleCloseAlert()
    onDownload({
      variables,
    })
  }

  return isReport ? (
    <DatePickerWrap style={{marginRight: 0}}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          views={['year']}
          className="date-picker-dialog"
          KeyboardButtonProps={{'aria-label': 'change date'}}
          inputVariant="outlined"
          format="yyyy"
          placeholder="Choose Period"
          color={'primary'}
          onChange={handleChange}
          value={String(year)}
        />
      </MuiPickersUtilsProvider>
    </DatePickerWrap>
  ) : (
    <div>
      <GenerateTooltip
        title={isDisableButton ? `You can only generate data once a day` : ``}
        placement="left"
      >
        <span>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={isDisableButton}
            onClick={() => setOpenGenerateAlert(true)}
          >
            Generate Report
          </Button>
        </span>
      </GenerateTooltip>
      <AlertDialog
        open={openGenerateAlert}
        handleClose={handleCloseAlert}
        mutation={handleGenerateReport}
        feature="Generate Data is Processing!"
        message="Generate Data data is processing!. Don’t worry, you can still do other activities"
        type="Confirm"
      />
    </div>
  )
}

function ClaimReport(props) {
  const params = useGetParams()
  const active = props.location.state.active
  const isReport = active === 'report'
  const currentYear = new Date().getFullYear()
  const year = (isReport ? params?.year : params?.date) || currentYear

  const date = moment(String(year))
  const variables = {
    table: 'people_work_claims',
    filter: {
      start_date: date.startOf(optionDate.type).format(optionDate.format),
      end_date: date.endOf(optionDate.type).format(optionDate.format),
    },
  }

  const {
    dataBaloon,
    onDownload,
    isDisableButton,
    onCloseBaloon,
  } = useDownloadReport(variables)

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <TitleHeader isReport={isReport} />
        <ActionHeader
          isReport={isReport}
          onDownload={onDownload}
          isDisableButton={isDisableButton}
        />
      </EnhancedToolbar>

      <Divider />
      {!isReport && (
        <ExportBaloon
          availableToSeeProblem
          isReport
          data={dataBaloon}
          closeBaloonMutation={onCloseBaloon}
          feature="Claim Report"
        />
      )}

      {isReport ? <ClaimReportList /> : <ClaimReportHistory {...props} />}
    </PaperContainer>
  )
}

export default withRouter(ClaimReport)

function ClaimReportHistory(props) {
  const {searchText, filterData} = props
  const {date} = useGetParams()
  const [debounce] = useDebounced(searchText, 500)

  const [pagination, setPagination] = React.useState({
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  })

  const filterValue = (type, filter) => {
    return filterData[type]?.[filter] || []
  }

  const getFilterValue = filter => {
    return {
      _in: filterValue('include', filter),
      _nin: filterValue('exclude', filter),
    }
  }

  const dateMoment = moment(String(date))
  const {limit, offset} = pagination
  const where = {
    status: {_eq: 'approved'},
    date_added: {
      _gte: dateMoment.startOf(optionDate.type).format(optionDate.format),
      _lte: dateMoment
        .endOf(optionDate.type)
        .add(1, 'd')
        .format(optionDate.format),
    },
    _or: [
      {formatted_id: {_ilike: `%${debounce}%`}},
      {
        people_work_placement: {
          company_job_profile: {
            _and: [
              {id: getFilterValue()},
              {organization: getFilterValue('organizationUnit')},
              {position: getFilterValue('position')},
              {rank: getFilterValue()},
              {office_address: getFilterValue('officeLocation')},
            ],
          },
        },
      },
      {
        people_work_placement: {
          global_user: {
            name: {
              _ilike: `%${debounce}%`,
            },
          },
        },
      },
    ],
  }
  const variables = {
    limit,
    offset: offset * limit,
    where,
  }

  const {data, loading} = useQuery(GET_CLAIM_ACTIVITY_REPORT, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  const handleChangeOffset = offset => {
    setPagination({...pagination, offset})
  }

  const handleChangeLimit = limit => {
    setPagination({offset: 0, limit})
  }

  React.useLayoutEffect(() => handleChangeOffset(0), [filterData])

  return (
    <ClaimReportDetail
      data={data?.people_work_claims}
      totalCount={data?.total.aggregate.count}
      loading={loading}
      offset={pagination.offset}
      limit={pagination.limit}
      onChangeOffset={handleChangeOffset}
      onChangeLimit={handleChangeLimit}
    />
  )
}
