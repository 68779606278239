import React from 'react'
import {
  FormChildContainer,
  FormChildTitle,
  TitleNumber,
  TitleName,
  FormChildInput,
  StyledReactSelect,
} from '../../../../GlobalStyles'
import {Typography} from '@material-ui/core'
import AsyncSelect from 'react-select/async'

export default function ComponentRSelect(props) {
  const {
    id,
    number,
    name,
    error,
    loadOptions,
    isAsync = false,
    ...other
  } = props
  const length = number.split('').length
  return (
    <FormChildContainer id={id}>
      <FormChildTitle>
        <TitleNumber style={length === 2 ? {paddingRight: 10} : null}>
          {number}.
        </TitleNumber>
        <TitleName>{name}</TitleName>
      </FormChildTitle>
      <FormChildInput style={{paddingTop: isAsync ? 10 : 0}}>
        {isAsync ? (
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            fontSize="14px"
            marginNormal
            error
            {...other}
          />
        ) : (
          <StyledReactSelect fontSize="14px" marginNormal error {...other} />
        )}
        <Typography variant="caption" color="error">
          {error && 'Bagian ini diperlukan'}
        </Typography>
      </FormChildInput>
    </FormChildContainer>
  )
}
