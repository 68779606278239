import React from 'react'
import {WrapIconItem} from '../../DetailEmployeeStyles'
import {GET_LIST_PHONE_NUMBER} from '../../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import LoadingEmployee from '../../../LoadingEmployee'

export default function PhoneNumber({userId}) {
  const {data, loading} = useQuery(GET_LIST_PHONE_NUMBER, {
    wlb_skipPatch: true,
    fetchPolicy: 'network-only',
    variables: {
      userId,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'public',
      },
    },
  })

  if (loading) {
    return <LoadingEmployee noborder smallpadding />
  }

  return (
    <div>
      {data &&
        data.phone_numbers &&
        data.phone_numbers.map(res => (
          <WrapIconItem key={`${res.id}-itm`}>
            <p className="black title">
              {res.country_code}
              {res.phone}
            </p>
            <p className="grey mb-10">
              {res.global_phone_code && res.global_phone_code.country}
            </p>
            {res.is_main && (
              <div className="is-main">
                <p>Main Phone Number</p>
              </div>
            )}
          </WrapIconItem>
        ))}
    </div>
  )
}
