import styled from 'styled-components'
import {Icon} from '@material-ui/core'

export const IconBG = styled(Icon)`
  width: 31.5px;
  height: 31.5px;
  margin: 10px 16px;
  padding: 6.5px;
  border: solid 0.5px;
  border-color: ${props => props.color};
  color: ${props => props.color};
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
  color: ${props => props.color};
`
