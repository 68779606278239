import {
  Avatar,
  Divider,
  Icon,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState} from 'react'
import {BoldTypography} from '../../../components/typography/TypographyStyles'
import {PaperContainer, EnhancedToolbar} from '../../../GlobalStyles'
import {
  NewTableOptions,
  newTableStyles,
} from '../../shared-components/table/TableOptions'
import {GET_NOMINATION_HOLDING_TALENT} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'
import {useQuery, useMutation} from '@apollo/react-hooks'
import Loading from '../../../components/loading/LoadingComponent'

import {Link} from 'react-router-dom'
import {capitalize, holdingRolesContext} from '../../../utils/helpers'
import {useSnackbar} from 'notistack'
import {DELETE_HOLDING_NOMINATION} from '../../../graphql/mutations'
import DeletePopup from '../../shared-components/popup/DeletePopup'

export default function NominationComponent(props) {
  const {enqueueSnackbar} = useSnackbar()
  const [openDelete, setOpenDelete] = useState(false)
  const [selected, setSelected] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [name, setName] = useState('')
  const tableClasses = newTableStyles()
  const {data, error, refetch} = useQuery(GET_NOMINATION_HOLDING_TALENT, {
    variables: {search: `%${props.searchText}%`, company: COMPANY_ID},
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const [deleteNom] = useMutation(DELETE_HOLDING_NOMINATION(selectedId))

  const onDeleteNom = () => {
    deleteNom({...holdingRolesContext})
      .then(() => {
        enqueueSnackbar('Data deleted', {
          variant: 'success',
          autoHideDuration: 1500,
        })
        if (selected instanceof Array) {
          setSelected([])
        }
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(
          'Delete failed, please try again later' + JSON.stringify(e),
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  const handleClickOpen = (id, name) => {
    setSelectedId(id)
    setName(name)
    setOpenDelete(true)
  }
  const handleClose = () => {
    setOpenDelete(false)
  }

  const handleClickMultipleDelete = () => {
    const idToDel = selected.map(resToDel => {
      return resToDel.id
    })
    setSelectedId(idToDel)
    setName('selected item')
    setOpenDelete(true)
  }

  const getTitle = () => {
    if (selected.length === 0) {
      return 'Name'
    } else {
      return (
        <Icon
          style={{cursor: 'pointer'}}
          onClick={handleClickMultipleDelete}
          color="secondary"
        >
          delete
        </Icon>
      )
    }
  }

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Holding Vacancy</Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={{
            pathname: `/holding-talent/nomination/add`,
            state: {active: 'add-nomination'},
          }}
        >
          Add Holding Vacancy
        </Button>
      </EnhancedToolbar>
      <Divider />
      <div className={tableClasses.tableRoot}>
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0', fontSize: 14}}
          options={{...NewTableOptions, paging: true, sorting: true}}
          columns={[
            {
              title: getTitle(),
              field: 'name',
              sorting: selected.length === 0,
              render: row => (
                <Link
                  to={{
                    pathname: '/holding-talent/nomination/' + row.id,
                    state: {active: 'detail-nomination'},
                  }}
                  style={{
                    color: '#000',
                    textDecoration: 'none',
                    fontWeight: 600,
                  }}
                >
                  {row.name}
                </Link>
              ),
            },
            {
              title: 'Company',
              field: 'company_profile.brand_name',
              cellStyle: {
                wordBreak: 'break-word',
              },
              render: row => (
                <div style={{display: 'flex', minWidth: 200}}>
                  <Avatar
                    variant="rounded"
                    src={row.company_profile.logo}
                    style={{marginRight: 16}}
                  ></Avatar>
                  <div>
                    <BoldTypography variant="body2" gutterBottom>
                      {row.company_profile.brand_name}
                    </BoldTypography>
                    <Typography variant="caption">
                      {row.company_profile.legal_name}
                    </Typography>
                  </div>
                </div>
              ),
            },

            {
              title: 'Position',
              field: 'company_job_profile.title',
            },
            {
              title: 'Status',
              field: 'status',
              render: rowData => (
                <Typography
                  style={
                    rowData.status === 'ACTIVE'
                      ? {color: 'green'}
                      : {color: 'red'}
                  }
                >
                  {capitalize(rowData.status)}
                </Typography>
              ),
            },
            {
              title: '',
              field: 'action',
              filtering: false,
              render: rowData => (
                <div style={{display: 'flex'}}>
                  <IconButton
                    onClick={() => handleClickOpen(rowData.id, rowData.name)}
                  >
                    <Icon color="secondary">delete</Icon>
                  </IconButton>
                </div>
              ),
            },
          ]}
          components={{
            Toolbar: () => null,
          }}
          title=""
          data={data.holding_nomination_config}
          onSelectionChange={rows => setSelected(rows)}
        />
      </div>
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        name={name}
        mutation={onDeleteNom}
      />
    </PaperContainer>
  )
}
