import React from 'react'
import moment from 'moment'

import {
  CustomTypography,
  Flex,
  FlexCenters,
  GreyTypography,
  SkyBlueIcon,
} from '../../../../../GlobalStyles'
import defaultImg from '../../../../../assets/images/course-default-thumbnail.jpg'
import {Divider, Typography} from '@material-ui/core'

import {status_data} from '../../../../shared-components/data/SharedData'

export default function TabsAbout({classes, data}) {
  return (
    <>
      <Flex>
        <img
          src={data.icon_url ? data.icon_url : defaultImg}
          className={classes.iconImg}
        />
        <div>
          <CustomTypography
            variant="body1"
            fweight="bold"
            bottom="4px"
            fcolor="blue"
          >
            {data.name}
          </CustomTypography>
          <CustomTypography size="12px" bottom="8px" fcolor="grey">
            Dibuat pada {moment(data.date_added).format('LL')}
          </CustomTypography>
          <CustomTypography
            size="12px"
            bottom="17px"
            fcolor={
              data.status === 'ACTIVE'
                ? 'green'
                : data.status === 'PENDING'
                ? 'orange'
                : data.status === 'DRAFT'
                ? 'grey'
                : '#red'
            }
          >
            {status_data[data.status]}
          </CustomTypography>
          <FlexCenters className={classes.mb12}>
            <SkyBlueIcon>person</SkyBlueIcon>
            <Typography variant="body2">
              {data.enroll_count.aggregate.count} peserta
            </Typography>
          </FlexCenters>
          <FlexCenters className={classes.mb12}>
            <SkyBlueIcon>person</SkyBlueIcon>
            <Typography variant="body2">
              {data.total_finished} peserta selesai
            </Typography>
          </FlexCenters>
          <FlexCenters className={classes.mb12}>
            <SkyBlueIcon>star</SkyBlueIcon>
            <Typography variant="body2">
              {data.microlearning_reviews_aggregate.aggregate.avg.star
                ? data.microlearning_reviews_aggregate.aggregate.avg.star.toFixed(
                    1
                  )
                : 0}{' '}
              ({data.microlearning_reviews_aggregate.aggregate.count})
            </Typography>
          </FlexCenters>
        </div>
      </Flex>
      <Divider className={classes.dividers} />
      <div>
        <CustomTypography fweight="bold" bottom="20px">
          Rincian
        </CustomTypography>
        <FlexCenters className={classes.mb12}>
          <Typography variant="body2" style={{width: 127}}>
            Pertanyaan
          </Typography>
          :
          <GreyTypography variant="body2" className={classes.ml12}>
            {data.question_count.aggregate.count}
          </GreyTypography>
        </FlexCenters>
        <FlexCenters className={classes.mb12}>
          <Typography variant="body2" style={{width: 127}}>
            Bahasa
          </Typography>
          :
          <GreyTypography variant="body2" className={classes.ml12}>
            {data.global_language && data.global_language.name}
          </GreyTypography>
        </FlexCenters>
        <FlexCenters className={classes.mb12}>
          <Typography variant="body2" style={{width: 127}}>
            Kategori
          </Typography>
          :
          <GreyTypography variant="body2" className={classes.ml12}>
            {data.academy_course_category
              ? data.academy_course_category.parent
                ? data.academy_course_category.parent.name
                : data.academy_course_category.name
              : '-'}
          </GreyTypography>
        </FlexCenters>
        <FlexCenters className={classes.mb12}>
          <Typography variant="body2" style={{width: 127}}>
            Sub Kategori
          </Typography>
          :
          <GreyTypography variant="body2" className={classes.ml12}>
            {data.academy_course_category && data.academy_course_category.parent
              ? data.academy_course_category.name
              : '-'}
          </GreyTypography>
        </FlexCenters>
        <FlexCenters className={classes.mb12}>
          <Typography variant="body2" style={{width: 127}}>
            Level
          </Typography>
          :
          <GreyTypography variant="body2" className={classes.ml12}>
            {data.level === 1
              ? 'Intermediate'
              : data.level === 2
              ? 'Advanced'
              : data.level === 0
              ? 'Beginner'
              : '-'}
          </GreyTypography>
        </FlexCenters>
      </div>
      <Divider className={classes.dividers} />
      <CustomTypography fweight="bold" bottom="20px">
        Keterangan
      </CustomTypography>
      <CustomTypography>{data.description}</CustomTypography>
    </>
  )
}
