import React, {useEffect, useState} from 'react'
import {
  GreyTypography,
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
  FlexCenter,
  TabsStyled,
  TabStyled,
  CountApplied,
  ListApplied,
} from '../../../GlobalStyles'

import {
  Dialog,
  Button,
  Typography,
  IconButton,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import {trimString} from '../../../utils/helpers'
import classNames from 'classnames'
import {RESULT_FILTER_JOB_FAMILY} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'
import {useQuery} from '@apollo/react-hooks'
import {status_data} from '../data/SharedData'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: '7rem',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
    padding: '0 1.6rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detailContainer: {display: 'flex', paddingBottom: '1rem'},
  w45: {width: '45%'},
  w55: {width: '55%'},
  detailLeave1: {
    marginTop: '1rem',
  },
  whiteBody: {
    background: '#fff',
  },
  btnStatus: {
    textTransform: 'capitalize',
    color: '#fff !important',
    marginRight: '1rem',
  },
  selectFilter: {marginRight: 12, minWidth: '19%'},
  flexAction: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-around',
    width: '88%',
    margin: 'auto',
  },
  btnReject: {color: '#ef4d5e', border: '1.5px solid #ef4d5e'},
  flexAction2: {display: 'flex', justifyContent: 'flex-end', marginTop: 20},
  buttonSpacer: {marginRight: 12},
  pointer: {
    cursor: 'pointer',
  },
  blueSky: {
    color: '#039be5 !important',
    textDecoration: 'none',
  },
  more: {
    color: '#039be5',
    cursor: 'pointer',
  },
  padding0: {
    padding: '0px!important',
  },
  padding15: {
    padding: '15px!important',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginTop5: {
    marginTop: '5px',
  },
  bgGrey: {
    background: '#eff2f4',
  },
  paddingforTop: {
    padding: '15px 15px 0px 15px',
  },
  paddingforBottom: {
    padding: '0px 15px 15px 15px',
  },
}))

const DetailDataOrganization = props => {
  const {
    handleClose,
    open,
    loading,
    data,
    status,
    feature,
    additionalInformation,
    isTabs,
    topBgColor,
    bottomBgColor,
    title,
    featureInTitle = true,
  } = props
  const classes = useStyles()
  const [more, setMore] = useState(false)
  const [tabsValue, setTabsValue] = useState(0)
  const [limit, setLimit] = useState(5)

  const [skipQueryResult, setSkipQueryResult] = useState(true)
  const {data: resultDataFilter} = useQuery(RESULT_FILTER_JOB_FAMILY, {
    variables: {
      company: COMPANY_ID,
      family: {
        _eq: data && data.id,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: skipQueryResult,
  })

  useEffect(() => {
    tabsValue === 1 && setSkipQueryResult(false)
  }, [tabsValue])

  const renderAdditionalInformation = () => {
    if (additionalInformation) {
      const temp_information = []
      for (const property in additionalInformation) {
        temp_information.push(
          <div className={classes.detailContainer}>
            <GreyTypography variant="body2" className={classes.w45}>
              {property}
            </GreyTypography>
            <Typography variant="body2" className={classes.w55}>
              {additionalInformation[property] || '-'}
            </Typography>
          </div>
        )
      }
      return temp_information
    }
  }

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const translateFeature = nameFeature => {
    switch (nameFeature) {
      case 'Job Grade':
        return 'Kelas Jabatan'
      case 'Job Family':
        return 'Rumpun Jabatan'
      case 'Job Title':
        return 'Jabatan'
      default:
        return nameFeature
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <ModalHeader>
        <div style={{display: 'flex'}}>
          {open && loading ? (
            ''
          ) : (
            <>
              {status && (
                <Button
                  variant="contained"
                  size="small"
                  className={classes.btnStatus}
                  style={
                    data &&
                    data.status &&
                    data.status.toLowerCase() === 'active'
                      ? {
                          background: '#4caf50',
                        }
                      : {
                          background: '#ef4d5e',
                        }
                  }
                  disabled
                >
                  {data && data.status && data.status.toLowerCase() === 'active'
                    ? status_data.ACTIVE
                    : status_data.INACTIVE}
                </Button>
              )}
              <Typography style={{color: '#ffffff', alignSelf: 'center'}}>
                {title || data?.name}
              </Typography>
            </>
          )}
        </div>
        <IconButton aria-label="close" onClick={handleClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContentEvent
        className={(isTabs || topBgColor || bottomBgColor) && classes.padding0}
      >
        {open && loading ? (
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        ) : (
          <>
            {isTabs && (
              <TabsStyled value={tabsValue} onChange={handleChange}>
                <TabStyled label="Detail Rumpun Jabatan" />
                <TabStyled label="Jabatan" />
              </TabsStyled>
            )}
            {tabsValue === 0 && (
              <div
                className={
                  isTabs
                    ? classNames(classes.whiteBody, classes.padding15)
                    : classes.whiteBody
                }
              >
                <div
                  className={
                    topBgColor
                      ? classNames(classes.bgGrey, classes.paddingforTop)
                      : bottomBgColor
                      ? classes.paddingforTop
                      : ''
                  }
                >
                  <div className={classes.detailContainer}>
                    <GreyTypography variant="body2" className={classes.w45}>
                      {` ${feature === 'Goal Library' ? 'Nama' : 'Kode'}`}{' '}
                      {featureInTitle && translateFeature(feature)}
                    </GreyTypography>
                    <Typography variant="body2" className={classes.w55}>
                      {feature === 'Job Title'
                        ? data?.designation_code
                        : feature === 'Goal Library'
                        ? data?.name
                        : data?.code}
                    </Typography>
                  </div>
                  <div className={classes.detailContainer}>
                    <GreyTypography variant="body2" className={classes.w45}>
                      {` ${
                        feature === 'Job Family Name'
                          ? ''
                          : feature === 'Goal Library'
                          ? 'Kode'
                          : 'Nama'
                      }`}{' '}
                      {featureInTitle && translateFeature(feature)}
                    </GreyTypography>
                    <Typography variant="body2" className={classes.w55}>
                      {feature === 'Goal Library' ? data?.code : data?.name}
                    </Typography>
                  </div>

                  {additionalInformation && renderAdditionalInformation()}
                </div>

                <div
                  className={
                    topBgColor
                      ? classNames(classes.detailContainer, classes.padding15)
                      : bottomBgColor
                      ? classNames(
                          classes.detailContainer,
                          classes.bgGrey,
                          classes.padding15
                        )
                      : classes.detailContainer
                  }
                >
                  <GreyTypography variant="body2" className={classes.w45}>
                    Keterangan
                  </GreyTypography>
                  <Typography variant="body2" className={classes.w55}>
                    {more
                      ? data && data.description
                      : data && trimString(data.description, 200)}
                    {data && data.description && data.description.length > 200 && (
                      <Typography
                        className={classes.more}
                        onClick={() => setMore(!more)}
                        variant="span"
                      >
                        {more ? ` ...less` : ` ...more`}
                      </Typography>
                    )}
                  </Typography>
                </div>
              </div>
            )}
            {tabsValue === 1 && (
              <div className={classNames(classes.whiteBody, classes.padding15)}>
                <Typography variant="body2" className={classes.w55}>
                  {`Terapkan ${resultDataFilter &&
                    resultDataFilter.total.aggregate
                      .count} Jabatan untuk ${data && data.name}:`}
                </Typography>
                {resultDataFilter &&
                  resultDataFilter.company_employee_positions
                    .slice(0, limit)
                    .map((data, index) => {
                      return (
                        <ListApplied key={index} className={classes.marginTop5}>
                          {`${data && data.designation_code} - ${data &&
                            data.name}`}
                        </ListApplied>
                      )
                    })}
                {resultDataFilter &&
                resultDataFilter.total.aggregate.count >= 5 &&
                limit <= 5 ? (
                  <CountApplied
                    className={classes.marginTop10}
                    onClick={() =>
                      setLimit(
                        resultDataFilter &&
                          resultDataFilter.total.aggregate.count
                      )
                    }
                  >
                    {(resultDataFilter &&
                      resultDataFilter.total.aggregate.count - 5) === 0
                      ? ''
                      : `Lihat Lebih Banyak`}
                  </CountApplied>
                ) : (
                  <CountApplied
                    className={classes.marginTop10}
                    onClick={() => setLimit(5)}
                  >
                    {resultDataFilter &&
                      resultDataFilter.total.aggregate.count >= 5 &&
                      'Lihat Lebih Sedikit'}
                  </CountApplied>
                )}
              </div>
            )}
          </>
        )}
      </ModalContentEvent>
    </Dialog>
  )
}

export default DetailDataOrganization
