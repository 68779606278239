import React, {useState, useLayoutEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import helperStyle, {
  FormChildContainer,
  FormChildTitle,
  TitleName,
  TitleNumber,
  FormChildInput,
  TextBlackBold,
  FlexBetween,
  RedIcon,
} from '../../../../../GlobalStyles'
import FieldInformationTooltip from '../../../../../pages/shared-components/tooltip/FieldInformationTooltip'
import {Magnify} from 'mdi-material-ui'
import MaterialTable from 'material-table'
import classNames from 'classnames'
import DeletePopup from '../../../../shared-components/popup/DeletePopup'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  TextField,
  MenuItem,
  CircularProgress,
  Switch,
  Button,
  InputAdornment,
  Paper,
  Divider,
  Avatar,
  IconButton,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  CenterWrapper,
  // WrapperModalConfirmation,
  LitleRedText,
  TableOptions,
  pageSizeOption,
} from '../../PayrollPageStyles'
import {
  inputRequiredHelperText,
  onChangerWithHasChange,
} from './formValidation.helper'
import {
  GET_GENERAL_SETTING,
  GET_JOB_PROFILE,
  LIST_EXCLUSION_EMPLOYEE,
} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'
import {GQL_FINANCE_OPTIONS} from '../../utils'
import PayrollSettingsWrapper from '../PayrollSettingsWrapper'
import {
  UPDATE_SCHEDULE_SETTINGS,
  DELETE_EXCLUSION_EMPLOYEE,
  ADD_EXCLUSION_EMPLOYEE,
} from '../../../../../graphql/mutations'
import FilterAssignee from '../../../../shared-components/popup/FilterAssignee'

const firstApprovalItems = [
  {value: 0, label: 'No Approval'},
  {value: 2, label: 'Position'},
]
const informationText = {
  payDate: `The date that an Employee's Compensation is paid to the Employee by the Employer through the payment of cash, issuance of a check, electronic funds transfer or other method.`,
  cutOff: `The last date of attendance is calculated.`,
  prorateMethod: (
    <>
      <div>Method of prorating salary</div>
      <div>1. By Working Days: According to working day arrangements</div>
      <div>2. By Calender Days: According to the calendar date</div>
      <div>3. By Fixed Days: The date has been set by the company</div>
      <div>4. No Prorate : Salary will not be prorated</div>
    </>
  ),
  fixedDayInMonth: `How many days of the month are calculated to prorate`,
  prorateFormula: (
    <>
      <div>The formula for prorating salary</div>
      <div>1. Basic Salary: prorate the base salary</div>
      <div>
        2. Basic Salary + Component: prorate the basic salary and allowances
      </div>
    </>
  ),
  prorateSalary: `Do the prorated salary regulations apply to new employees?`,
  payrollTHRApproval: `Do Payroll and THR require approval? Who is the responsible person for Payroll and THR approval?`,
  payrollExclusion: `Payroll Exclusion for outsourced employees or shareholder / direction member`,
}
// eslint-disable-next-line no-unused-vars
const dates = Array.from(Array(31), (x, index) => index + 1)
const prorateMethodItems = [
  {value: 1, label: 'By Working Days'},
  {value: 2, label: 'By Calendar Days'},
  {value: 3, label: 'By Fixed Days'},
  {value: 4, label: 'No Prorate'},
]
const prorateFormulaItems = [
  {value: 1, label: 'Basic Salary'},
  {value: 2, label: 'Basic Salary + Component'},
]

const GeneralPayrollSettings = props => {
  const {activeComponent, setActiveComponent, generalSettingData} = props
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [payDate, setPayDate] = useState(null)
  const [cutOffDate, setCutOffDate] = useState(null)
  const [prorateMethod, setProrateMethod] = useState(null)
  const [fixDaysInMonth, setFixDaysInMonth] = useState(null)
  const [prorateFormula, setProrateFormula] = useState(null)
  const [prorateSalary, setProrateSalary] = useState(null)
  const [firstApprovalType, setFirstApprovalType] = useState(null)
  const [firstApprovalJob, setFirstApprovalJob] = useState(null)
  const [secondApprovalSwitch, setSecondApprovalSwitch] = useState(false)
  const [secondApprovalJob, setSecondApprovalJob] = useState(null)
  const [thirdApprovalSwitch, setThirdApprovalSwitch] = useState(false)
  const [thirdApprovalJob, setThirdApprovalJob] = useState(null)
  const [isRetrigger, setIsRetrigger] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [hasChange, setHasChange] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  // const [openModal, setOpenModal] = useState(false)
  // const [titleModal, setTitleModal] = useState(' ')
  // const [bodyModal, setBodyModal] = useState(' ')
  const [hasSubmit, setHasSubmit] = useState(false)
  const [dataDelete, setDataDelete] = useState({})
  const [OpenAlert, setOpenAlert] = useState(false)
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: pageSizeOption[0],
  })

  const {data: jobProfileData, loading, error} = useQuery(GET_JOB_PROFILE, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const {
    data: dataExclusion,
    loading: loadingExclusion,
    error: errorExclusion,
    refetch: refetchExclusion,
  } = useQuery(LIST_EXCLUSION_EMPLOYEE, {
    wlb_skipPatch: true,
    variables: {
      search: `%${search}%`,
      company: COMPANY_ID,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
    ...GQL_FINANCE_OPTIONS,
  })

  const [deleteExclusion] = useMutation(
    DELETE_EXCLUSION_EMPLOYEE,
    GQL_FINANCE_OPTIONS
  )

  const [addExclusion] = useMutation(
    ADD_EXCLUSION_EMPLOYEE,
    GQL_FINANCE_OPTIONS
  )

  let jobProfileSelectOptions = []
  if (jobProfileData) {
    jobProfileSelectOptions = jobProfileData.company_job_profiles.map(res => ({
      id: res.id,
      title: `${res.code !== null ? res.code + ' - ' : ''}${res.title} `,
    }))
  }

  const [updateGeneralSetting] = useMutation(
    UPDATE_SCHEDULE_SETTINGS,
    GQL_FINANCE_OPTIONS
  )

  useLayoutEffect(() => {
    if (generalSettingData) {
      const data = generalSettingData
      setPayDate(data.pay_date)
      setCutOffDate(data.cut_off_date)
      setProrateMethod(data.leave_unpaid_eval)
      setFixDaysInMonth(data.leave_unpaid_days)
      setProrateFormula(data.leave_unpaid_formula)
      setProrateSalary(data.employee_irregular_start_salary)
      setFirstApprovalJob(data.first_approval_job_profile)
      setSecondApprovalSwitch(!!data.second_approval_job_profile)
      setSecondApprovalJob(data.second_approval_job_profile)
      setThirdApprovalSwitch(!!data.third_approval_job_profile)
      setThirdApprovalJob(data.third_approval_job_profile)
      if (data.first_approval_job_profile) {
        setFirstApprovalType(2)
      } else {
        setFirstApprovalType(data.first_approval_type)
      }
      if (data.second_approval_job_profile) {
        setSecondApprovalSwitch(true)
      }
      if (data.third_approval_job_profile) {
        setThirdApprovalSwitch(true)
      }
    }
  }, [generalSettingData, isRetrigger])

  const onUpdateGeneral = () => {
    setHasSubmit(true)
    if (
      payDate === null ||
      cutOffDate === null ||
      prorateMethod === null ||
      (prorateMethod === 3 && fixDaysInMonth === null) ||
      prorateFormula === null ||
      prorateSalary === null ||
      firstApprovalType === null ||
      (firstApprovalType === 2 && firstApprovalJob === null) ||
      (firstApprovalType !== 0 &&
        secondApprovalSwitch &&
        secondApprovalJob === null) ||
      (firstApprovalType !== 0 &&
        secondApprovalSwitch &&
        thirdApprovalSwitch &&
        thirdApprovalJob === null)
    ) {
      enqueueSnackbar('Please input all required data', {
        variant: 'error',
        autoHideDuration: 3000,
      })
    } else {
      setIsDisabled(true)
      const variables = {
        pay_date: payDate,
        cut_off_date: cutOffDate,
        leave_unpaid_eval: prorateMethod,
        leave_unpaid_formula: prorateFormula,
        leave_unpaid_days: fixDaysInMonth,
        employee_irregular_start_salary: prorateSalary,
        first_approval_type: firstApprovalType,
        first_approval_job_profile: firstApprovalJob,
        second_approval_job_profile: secondApprovalJob,
        third_approval_job_profile: thirdApprovalJob,
      }

      updateGeneralSetting({
        variables,
        ...GQL_FINANCE_OPTIONS,
        refetchQueries: [
          {
            query: GET_GENERAL_SETTING,
            variables: {
              company: COMPANY_ID,
            },
            ...GQL_FINANCE_OPTIONS,
          },
        ],
      })
        .then(() => {
          enqueueSnackbar('General Setting saved', {
            variant: 'success',
            autoHideDuration: 3000,
          })
        })
        .catch(e =>
          enqueueSnackbar(JSON.stringify(e), {
            variant: 'error',
          })
        )
        .finally(() => {
          setIsDisabled(false)
          setHasChange(false)
          setHasSubmit(false)
        })
    }
  }

  const handleOpenAlert = data => {
    setDataDelete(data)
    setOpenAlert(!OpenAlert)
  }

  const onChangeApprovalType = value => {
    if (value === 0) {
      setFirstApprovalJob(null)
      setSecondApprovalJob(null)
      setThirdApprovalJob(null)
      setSecondApprovalSwitch(false)
      setFirstApprovalType(Number(value))
    } else {
      setFirstApprovalType(Number(value))
    }
  }

  const onCancel = () => {
    setIsRetrigger(!isRetrigger)
    setHasChange(false)
    setHasSubmit(false)
    if (!generalSettingData) {
      setPayDate(null)
      setCutOffDate(null)
      setProrateMethod(null)
      setFixDaysInMonth(null)
      setProrateFormula(null)
      setProrateSalary(null)
      setFirstApprovalType(null)
      setFirstApprovalJob(null)
      setSecondApprovalSwitch(null)
      setSecondApprovalJob(null)
      setThirdApprovalSwitch(null)
      setThirdApprovalJob(null)
      setSecondApprovalSwitch(null)
    }
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const onAddFilterExclusion = result => {
    const variables = {
      objects: result,
    }
    addExclusion({variables: variables})
      .then(() => {
        enqueueSnackbar('Payroll Exclusion for Employees Added', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetchExclusion()
      })
      .catch(() => {
        enqueueSnackbar(
          'Added Payroll Exclusion for Employees failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        )
      })
  }

  const onDeleteExclusion = () => {
    const variables = {
      id: dataDelete.id,
    }
    deleteExclusion({variables: variables})
      .then(() => {
        enqueueSnackbar('Payroll Exclusion for Employees deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetchExclusion()
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete Payroll Exclusion for Employees failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 1000,
          }
        )
      })
  }

  if (dataExclusion === undefined && errorExclusion === undefined) {
    return <CircularProgress />
  } else if (errorExclusion !== undefined) {
    return JSON.stringify(errorExclusion)
  }

  return (
    <PayrollSettingsWrapper
      activeComponent={activeComponent}
      setActiveComponent={setActiveComponent}
      onConfirm={onUpdateGeneral}
      isDisabledButton={generalSettingData === undefined || isDisabled}
      onCancel={onCancel}
      secondaryButton="Reset"
      hasChange={hasChange}
      generalSettingData={generalSettingData}
    >
      {error && <CenterWrapper>{JSON.stringify(error)}</CenterWrapper>}
      {loading && (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      )}
      {jobProfileData && (
        <>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>1.</TitleNumber>
              <TitleName>Payroll Date *</TitleName>
              <FieldInformationTooltip title={informationText.payDate} />
            </FormChildTitle>
            <FormChildInput style={{display: 'flex'}}>
              <TextField
                style={{width: '60%'}}
                size="small"
                select
                fullWidth
                variant="outlined"
                value={payDate || 'placeholder'}
                onChange={e =>
                  onChangerWithHasChange(e, setPayDate, setHasChange)
                }
                error={hasSubmit && !payDate}
                helperText={inputRequiredHelperText(hasSubmit, !payDate)}
              >
                <MenuItem value="placeholder" disabled>
                  Select Date
                </MenuItem>
                {dates.map(item => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>2.</TitleNumber>
              <TitleName>Attendance Cut Off *</TitleName>
              <FieldInformationTooltip title={informationText.cutOff} />
            </FormChildTitle>
            <FormChildInput style={{display: 'flex'}}>
              <TextField
                style={{width: '60%'}}
                size="small"
                select
                fullWidth
                variant="outlined"
                value={cutOffDate || 'placeholder'}
                onChange={e =>
                  onChangerWithHasChange(e, setCutOffDate, setHasChange)
                }
                error={hasSubmit && !cutOffDate}
                helperText={inputRequiredHelperText(hasSubmit, !cutOffDate)}
              >
                <MenuItem value="placeholder" disabled>
                  Select Date
                </MenuItem>
                {dates.map(item => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Prorate Method *</TitleName>
              <FieldInformationTooltip title={informationText.prorateMethod} />
            </FormChildTitle>
            <FormChildInput style={{display: 'flex'}}>
              <TextField
                style={{width: '60%'}}
                size="small"
                select
                fullWidth
                variant="outlined"
                value={prorateMethod || 'placeholder'}
                onChange={e =>
                  onChangerWithHasChange(e, setProrateMethod, setHasChange)
                }
                error={hasSubmit && !prorateMethod}
                helperText={inputRequiredHelperText(hasSubmit, !prorateMethod)}
              >
                <MenuItem value="placeholder" disabled>
                  Select Method
                </MenuItem>
                {prorateMethodItems.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer>
          {prorateMethod === 3 && (
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>
                  <span style={{color: 'white'}}>3.</span>
                </TitleNumber>
                <TitleName>Fixed days in a month</TitleName>
                <FieldInformationTooltip
                  title={informationText.fixedDayInMonth}
                />
              </FormChildTitle>
              <FormChildInput style={{display: 'flex'}}>
                <TextField
                  style={{width: '60%'}}
                  size="small"
                  select
                  fullWidth
                  variant="outlined"
                  value={fixDaysInMonth || 'placeholder'}
                  onChange={e =>
                    onChangerWithHasChange(e, setFixDaysInMonth, setHasChange)
                  }
                  error={
                    hasSubmit && prorateMethod === 3 && fixDaysInMonth === null
                  }
                  helperText={inputRequiredHelperText(
                    hasSubmit,
                    prorateMethod === 3 && fixDaysInMonth === null
                  )}
                >
                  <MenuItem value="placeholder" disabled>
                    Select Date
                  </MenuItem>
                  {dates.map(item => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </FormChildInput>
            </FormChildContainer>
          )}
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>4.</TitleNumber>
              <TitleName>Prorate Formula *</TitleName>
              <FieldInformationTooltip title={informationText.prorateFormula} />
            </FormChildTitle>
            <FormChildInput style={{display: 'flex'}}>
              <TextField
                style={{width: '60%'}}
                size="small"
                select
                fullWidth
                variant="outlined"
                value={prorateFormula || 'placeholder'}
                onChange={e =>
                  onChangerWithHasChange(e, setProrateFormula, setHasChange)
                }
                error={hasSubmit && !prorateFormula}
                helperText={inputRequiredHelperText(hasSubmit, !prorateFormula)}
              >
                <MenuItem value="placeholder" disabled>
                  Select Formula
                </MenuItem>
                {prorateFormulaItems.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>5.</TitleNumber>
              <TitleName>Prorate Salary: New Employee *</TitleName>
              <FieldInformationTooltip title={informationText.prorateSalary} />
            </FormChildTitle>
            <FormChildInput>
              <FormControl
                error
                helperText="Can'be Empty"
                style={{width: '60%'}}
              >
                <RadioGroup
                  row
                  name="position"
                  value={prorateSalary}
                  onChange={e => {
                    setProrateSalary(Number(e.target.value))
                    setHasChange(true)
                  }}
                  error={hasSubmit && !prorateSalary}
                  helperText={inputRequiredHelperText(
                    hasSubmit,
                    !prorateSalary
                  )}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label="Yes"
                    style={{width: '50%'}}
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
                {hasSubmit && !prorateSalary && (
                  <LitleRedText>
                    {inputRequiredHelperText(hasSubmit, !prorateSalary)}
                  </LitleRedText>
                )}
              </FormControl>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>6.</TitleNumber>
              <TitleName>Payroll &#38; THR Approval *</TitleName>
              <FieldInformationTooltip
                title={informationText.payrollTHRApproval}
              />
            </FormChildTitle>
            <FormChildInput style={{display: 'flex', width: '170%'}}>
              <TextField
                size="small"
                select
                fullWidth
                variant="outlined"
                value={
                  firstApprovalType === null ? 'placeholder' : firstApprovalType
                }
                onChange={e => {
                  onChangeApprovalType(e.target.value)
                  setHasChange(true)
                }}
                error={
                  hasSubmit &&
                  !(firstApprovalType === 0 || firstApprovalType === 2)
                }
                helperText={inputRequiredHelperText(
                  hasSubmit,
                  !(firstApprovalType === 0 || firstApprovalType === 2)
                )}
              >
                <MenuItem value={'placeholder'} disabled>
                  Select Approval
                </MenuItem>
                {firstApprovalItems.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </TextField>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    disabled={
                      firstApprovalJob === null || firstApprovalType === 0
                    }
                    checked={secondApprovalSwitch}
                    onChange={() => {
                      setSecondApprovalSwitch(!secondApprovalSwitch)
                      setThirdApprovalSwitch(false)
                      setSecondApprovalJob(null)
                      setThirdApprovalJob(null)
                      setHasChange(true)
                    }}
                  />
                }
                label="Need Second Approval"
                style={{width: '70%', marginLeft: '40px'}}
              />
            </FormChildInput>
          </FormChildContainer>
          {firstApprovalType === 2 && (
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>
                  <span style={{color: 'white'}}>6.</span>
                </TitleNumber>
                <TitleName>First Approval</TitleName>
              </FormChildTitle>
              <FormChildInput style={{display: 'flex', width: '91%'}}>
                <Autocomplete
                  value={
                    jobProfileSelectOptions &&
                    jobProfileSelectOptions.find(
                      ({id}) => id === firstApprovalJob
                    )
                  }
                  options={jobProfileSelectOptions}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.title}
                  style={{width: '100%'}}
                  onChange={(e, newValue) => {
                    e.preventDefault()
                    setFirstApprovalJob(newValue.id)
                    setHasChange(true)
                  }}
                  renderInput={params => (
                    <TextField
                      fullWidth
                      placeholder="Select Approval"
                      size="small"
                      variant="outlined"
                      {...params}
                      error={
                        hasSubmit &&
                        firstApprovalType === 2 &&
                        firstApprovalJob === null
                      }
                      helperText={inputRequiredHelperText(
                        hasSubmit,
                        firstApprovalType === 2 && firstApprovalJob === null
                      )}
                    />
                  )}
                />
              </FormChildInput>
            </FormChildContainer>
          )}
          {firstApprovalType !== 0 && secondApprovalSwitch && (
            <FormChildContainer>
              <FormChildTitle>
                <TitleNumber>
                  <span style={{color: 'white'}}>7.</span>
                </TitleNumber>
                <TitleName>Second Approval</TitleName>
              </FormChildTitle>
              <FormChildInput style={{display: 'flex', width: '170%'}}>
                <Autocomplete
                  value={
                    jobProfileSelectOptions &&
                    jobProfileSelectOptions.find(
                      ({id}) => id === secondApprovalJob
                    )
                  }
                  options={jobProfileSelectOptions}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={option => option.title}
                  style={{width: '100%'}}
                  onChange={(e, newValue) => {
                    e.preventDefault()
                    setSecondApprovalJob(newValue.id)
                    setHasChange(true)
                  }}
                  renderInput={params => (
                    <TextField
                      fullWidth
                      placeholder="Select Approval"
                      size="small"
                      variant="outlined"
                      {...params}
                      error={
                        hasSubmit &&
                        firstApprovalType !== 0 &&
                        secondApprovalSwitch &&
                        secondApprovalJob === null
                      }
                      helperText={inputRequiredHelperText(
                        hasSubmit,
                        firstApprovalType !== 0 &&
                          secondApprovalSwitch &&
                          secondApprovalJob === null
                      )}
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={thirdApprovalSwitch}
                      disabled={secondApprovalJob === null}
                      onChange={() => {
                        setThirdApprovalSwitch(!thirdApprovalSwitch)
                        setThirdApprovalJob(null)
                        setHasChange(true)
                      }}
                    />
                  }
                  label="Need Third Approval"
                  style={{width: '70%', marginLeft: '40px'}}
                />
              </FormChildInput>
            </FormChildContainer>
          )}
          {firstApprovalType !== 0 &&
            secondApprovalSwitch &&
            thirdApprovalSwitch && (
              <FormChildContainer>
                <FormChildTitle>
                  <TitleNumber>
                    <span style={{color: 'white'}}>8.</span>
                  </TitleNumber>
                  <TitleName>Third Approval</TitleName>
                </FormChildTitle>
                <FormChildInput style={{display: 'flex', width: '91%'}}>
                  <Autocomplete
                    value={
                      jobProfileSelectOptions &&
                      jobProfileSelectOptions.find(
                        ({id}) => id === thirdApprovalJob
                      )
                    }
                    options={jobProfileSelectOptions}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={option => option.title}
                    style={{width: '100%'}}
                    onChange={(e, newValue) => {
                      e.preventDefault()
                      setThirdApprovalJob(newValue.id)
                      setHasChange(true)
                    }}
                    renderInput={params => (
                      <TextField
                        fullWidth
                        placeholder="Select Approval"
                        size="small"
                        variant="outlined"
                        {...params}
                        error={
                          hasSubmit &&
                          firstApprovalType !== 0 &&
                          secondApprovalSwitch &&
                          thirdApprovalSwitch &&
                          thirdApprovalJob === null
                        }
                        helperText={inputRequiredHelperText(
                          hasSubmit,
                          firstApprovalType !== 0 &&
                            secondApprovalSwitch &&
                            thirdApprovalSwitch &&
                            thirdApprovalJob === null
                        )}
                      />
                    )}
                  />
                </FormChildInput>
              </FormChildContainer>
            )}
          <Divider />
          <FormChildContainer className={classes.marginTop10}>
            <FormChildTitle>
              <TitleNumber>7.</TitleNumber>
              <TitleName>Payroll Exclusion for Employees *</TitleName>
              <FieldInformationTooltip
                title={informationText.payrollExclusion}
              />
            </FormChildTitle>
          </FormChildContainer>
          <FlexBetween>
            <TextBlackBold
              className={classes.marginLeft28}
            >{`Applied to People (${dataExclusion.total.aggregate.count} People)`}</TextBlackBold>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setOpenFilter(true)
              }}
            >
              Assignee
            </Button>
          </FlexBetween>
          <TextField
            margin="normal"
            variant="outlined"
            className={classNames(
              classes.height39,
              classes.marginLeft28,
              classes.width96P
            )}
            fullWidth
            onChange={event => setSearch(event.target.value)}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment className="ml-0" position="start">
                  <Magnify style={{color: '#014a62', width: 20, height: 20}} />
                </InputAdornment>
              ),
            }}
          />
          <MaterialTable
            columns={[
              {
                title: 'Avatar',
                field: 'global_user.avatar',
                defaultSort: 'asc',
                headerStyle: {minWidth: 100},
                cellStyle: {minWidth: 100},
                render: rowData => (
                  <Avatar alt="ok" src={rowData.global_user.avatar}>
                    {rowData.global_user.name.slice(0, 1)}
                  </Avatar>
                ),
              },
              {
                title: 'Employee ID',
                field: 'global_user.people_work_placements.regno',
                defaultSort: 'asc',
                headerStyle: {minWidth: 200},
                cellStyle: {minWidth: 200},
                render: rowData => (
                  <div style={{wordBreak: 'break-word'}}>
                    {rowData.global_user.people_work_placements[0] &&
                      rowData.global_user.people_work_placements[0].regno}
                  </div>
                ),
              },
              {
                title: 'Employee Name',
                field: 'global_user.name',
                defaultSort: 'asc',
                headerStyle: {minWidth: 200},
                cellStyle: {minWidth: 200},
                render: rowData => (
                  <div style={{wordBreak: 'break-word'}}>
                    {rowData.global_user.name}
                  </div>
                ),
              },
              {
                title: 'Position',
                field:
                  'global_user.people_work_placements.company_job_profile.title',
                defaultSort: 'asc',
                headerStyle: {minWidth: 200},
                cellStyle: {minWidth: 200},
                render: rowData => (
                  <div style={{wordBreak: 'break-word'}}>
                    {rowData.global_user.people_work_placements[0] &&
                      rowData.global_user.people_work_placements[0]
                        .company_job_profile.title}
                  </div>
                ),
              },
              {
                title: 'Action',
                field: 'action',
                headerStyle: {minWidth: 100},
                cellStyle: {minWidth: 100},
                render: rowData => (
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleOpenAlert(rowData)}
                  >
                    <RedIcon>delete</RedIcon>
                  </IconButton>
                ),
              },
            ]}
            data={dataExclusion.exclude_people}
            options={{
              ...TableOptions,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: <NoDataListComponent search={search} />,
              },
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            page={pagination.offset}
            pageSize={pagination.limit}
            isLoading={loadingExclusion}
            components={{
              Container: props => (
                <Paper
                  {...props}
                  elevation={0}
                  className={classes.marginLeft28}
                />
              ),
            }}
            totalCount={dataExclusion.total.aggregate.count}
          />
        </>
      )}
      <FilterAssignee
        feature="general-setting"
        open={openFilter}
        onAdd={onAddFilterExclusion}
        preData={dataExclusion && dataExclusion.filter_assignee}
        handleClose={() => setOpenFilter(false)}
      />
      <DeletePopup
        open={OpenAlert}
        handleClose={handleOpenAlert}
        id={dataDelete && dataDelete.id}
        name={
          dataDelete && dataDelete.global_user && dataDelete.global_user.name
        }
        mutation={() => onDeleteExclusion()}
      />
    </PayrollSettingsWrapper>
  )
}

// function ModalInformation({open, onClose, title, body}) {
//   return (
//     <Dialog onClose={onClose} open={open} fullWidth maxWidth="xs">
//       <WrapperModalConfirmation>
//         <div className="header-modal-confirmation">{title}</div>
//         <div className="body-modal-confirmation">{body}</div>
//         <div className="action-wrapper-modal-confirmation">
//           <Button
//             style={{fontSize: 12}}
//             onClick={onClose}
//             size="large"
//             variant="contained"
//             color="primary"
//           >
//             Ok
//           </Button>
//         </div>
//       </WrapperModalConfirmation>
//     </Dialog>
//   )
// }
export default withRouter(GeneralPayrollSettings)
