import gql from 'graphql-tag'

export const GET_LIST_ARCHIVE_ORG = gql`
  query($search: String) {
    company_archived_logs(
      where: {
        _or: [{name: {_ilike: $search}}, {description: {_ilike: $search}}]
      }
    ) {
      id
      name
      created_at
      description
      status
    }
  }
`
