// beberapa fitur di hide based on TIME-656 jira
import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {withRouter} from 'react-router-dom'

import TextField from '@material-ui/core/TextField'
// import Select from '@material-ui/core/Select'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import {Button, Divider, Radio, RadioGroup} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import {useMutation} from '@apollo/react-hooks'
import {
  calcMethods,
  months,
  // leftOverValidOptions,
  quotaStartOptions,
  quotaRenewOptions,
} from './Constants'
// import ColorPicker from 'material-ui-color-picker'
import {
  UPDATE_LEAVE_POLICY_PARENT,
  DELETE_LEAVE_POLICY_PARENT,
} from '../../../../graphql/mutations'
import {
  FormContainer,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  FormChildButton,
} from '../../../../GlobalStyles'
import classNames from 'classnames'
import {RedButton, WrapperField} from '../LeavePageStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import {selectProps} from '../../../../utils/helpers'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import ExpiredBalanceTooltip from './modal/ExpiredBalanceTooltip'
import {isErrorBalance} from './AddLeavePolicyParentComponent'
// import ColorPickerPopup from '../../../shared-components/color-picker/ColorPickerPopup'

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontStyle: 'bold',
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    marginLeft: '12px',
  },
  paper: {
    width: '100%',
    // marginBottom: '10rem',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  outlinedInput: {
    padding: '10px 14px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'baseline',
    // width: '18rem',
  },
  inputWidth: {
    width: '50%',
  },
  withSwitch: {
    width: '75% !important',
  },
  buttonSpacer: {marginRight: 12},
  toolbar: {display: 'flex', justifyContent: 'space-between'},
  titleText: {display: 'flex', alignItems: 'center'},
}))

function DeletePopUp({onDismiss, onDelete, open, selectedPolicy}) {
  const isHasChildren = selectedPolicy.time_leave_policy_children.length > 0
  const childrenCount = selectedPolicy.time_leave_policy_children.length
  return (
    <div>
      <Dialog
        open={open}
        onClose={onDismiss}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete <strong>{selectedPolicy.name}</strong>
        </DialogTitle>
        <DialogContent>
          {!isHasChildren && (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete leave and permission setting:{' '}
              <strong>{selectedPolicy.name}</strong>?
            </DialogContentText>
          )}
          {isHasChildren && (
            <DialogContentText id="alert-dialog-description">
              You want to delete leave and permission setting:{' '}
              <strong>{selectedPolicy.name}</strong>, it has {childrenCount}{' '}
              sub-policies dependant to it. Please delete or remove all
              sub-policies from this leave and permission setting.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onDismiss} color="primary">
            Cancel
          </Button>
          {!isHasChildren && (
            <RedButton
              onClick={() => onDelete(selectedPolicy.id)}
              color="secondary"
              autoFocus
            >
              Delete Anyway
            </RedButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

function EditSettingParent(props) {
  const {selected, dataCheck} = props
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [name, setName] = React.useState(selected.name || undefined)

  const [allow_leave_debt, setAllowLeaveDebt] = useState(
    selected.allow_leave_debt
  )
  const [selectedMethod, setSelectedMethod] = React.useState(
    selected.calc_method || 'standard'
  )
  const [selectedMonth, setSelectedMonth] = useState(
    selected.quota_reset_option_month || 1
  )
  const [selectedDate, setSelectedDate] = useState(
    selected.quota_reset_option_date || 1
  )
  const [selectedRenewOption, setSelectedRenewOption] = useState(
    selected.quota_reset_option || 1
  )
  const [selectedQuotaStart, setSelectedQuotaStart] = useState(
    selected.quota_effective_after_month || 0
  )
  const [isProRate, setIsProRate] = useState(
    selected.quota_prorate_option || false
  )
  const [colorLabel, setColorLabel] = useState(
    selected.label_color || '#000000'
  )
  const [colorText, setColorText] = useState(selected.text_color || '#ffffff')
  const [isError, setIsError] = React.useState(false)
  const [showDeletePopUp, setDeletePopUp] = React.useState(false)
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [isUnpaid, setIsUnpaid] = React.useState(selected.is_unpaid || false)
  const [remainingLeaveBalance, setRemainingLeaveBalance] = useState(1) // value 1 because `Paid with Money` is hidden
  const [leftoverType, setLeftoverType] = useState({
    leftover_valid_max: selected?.leftover_valid_max || 0,
    leftover_valid_until_month: selected?.leftover_valid_until_month || 0,
    leftover_money_settings: selected?.value?.leftover_money_settings || 0,
  })

  const nameChecker =
    dataCheck &&
    dataCheck.time_leave_policies &&
    dataCheck.time_leave_policies.some(
      v => v.name.toLowerCase() === name.toLowerCase()
    )
  const checkSameNameWithAdd = selected && selected.name === name

  const handleClickOpen = type => {
    const {
      leftover_valid_max,
      leftover_valid_until_month,
      leftover_money_settings,
    } = leftoverType
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      if (
        name === '' ||
        (selectedRenewOption === 3 && !selectedDate) ||
        nameChecker
          ? !checkSameNameWithAdd
          : checkSameNameWithAdd
      ) {
        const ids = document.getElementById('top')
        ids.scrollIntoView({behavior: 'smooth'})
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else if (
        (remainingLeaveBalance === 1 && isErrorBalance(leftover_valid_max)) ||
        (remainingLeaveBalance === 1 &&
          isErrorBalance(leftover_valid_until_month)) ||
        (remainingLeaveBalance === 2 && isErrorBalance(leftover_money_settings))
      ) {
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else {
        setConfirmType(type)
        setPopup(true)
      }
    }
  }
  const handleClose = () => {
    setPopup(false)
  }

  const [updatePolicy] = useMutation(UPDATE_LEAVE_POLICY_PARENT)
  const [deletePolicy] = useMutation(DELETE_LEAVE_POLICY_PARENT)
  const history = () => {
    props.history.push({
      pathname: '/time/leave/settings',
      state: {searchable: true, active: 'settings'},
    })
  }
  const deletePolicySetting = () => {
    const variables = {
      id: selected.id,
      deleteAt: new Date(),
    }

    deletePolicy({variables})
      .then(() => {
        enqueueSnackbar('Success delete data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        history()
      })
      .catch(error => {
        enqueueSnackbar(error, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const handleAllowDebt = value => {
    setAllowLeaveDebt(value === 'true')
  }
  const handleRemainingLeaveBalance = value => {
    setRemainingLeaveBalance(parseInt(value))
  }
  const handleChangeLeftOver = (value, target) => {
    setLeftoverType(e => ({...e, [target]: value}))
  }

  const handleSelectedMethod = event => {
    const {value} = event.target
    if (value === 'accrual') {
      setSelectedRenewOption(2)
    }
    setSelectedMethod(value)
  }

  const handleSelectedRenew = event => {
    const {value} = event.target
    if (value === 2) {
      setIsProRate(false)
    }
    setSelectedRenewOption(value)
  }

  const updatePolicySettings = () => {
    const {
      leftover_valid_max,
      leftover_valid_until_month,
      leftover_money_settings,
    } = leftoverType
    const variables = {
      id: selected.id,
      calc_method: selectedMethod,
      label_color: colorLabel,
      leftover_valid: false,
      name: name,
      quota_effective_after_month: selectedQuotaStart,
      quota_prorate_option: isProRate,
      quota_reset_option: selectedRenewOption,
      quota_reset_option_date: selectedDate,
      quota_reset_option_month: selectedMonth,
      text_color: colorText,
      leaveDebt: allow_leave_debt,
      is_unpaid: isUnpaid,
      leftover_type: remainingLeaveBalance,
      leftover_valid_max:
        remainingLeaveBalance === 1 ? parseInt(leftover_valid_max) : null,
      leftover_valid_until_month:
        remainingLeaveBalance === 1
          ? parseInt(leftover_valid_until_month)
          : null,
      leftover_money_settings:
        remainingLeaveBalance === 2
          ? {value: parseInt(leftover_money_settings)}
          : null,
    }

    updatePolicy({variables})
      .then(() => {
        enqueueSnackbar('Success Update data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        history()
      })
      .catch(error => {
        enqueueSnackbar(error, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar} disableGutters>
        <div className={classes.titleText}>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handleClickOpen('discard')}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Edit {selected && selected.name}
          </Typography>
        </div>
        <FormChildButton>
          <Button
            variant="contained"
            color="primary"
            // onClick={updatePolicySettings}
            onClick={() => handleClickOpen('edit')}
            size="large"
            className={classes.buttonSpacer}
          >
            Save
          </Button>
          <Button
            onClick={() => handleClickOpen('discard')}
            size="large"
            className={classes.buttonSpacer}
          >
            Cancel
          </Button>
          <RedButton
            onClick={() => setDeletePopUp(true)}
            className={classes.buttonSpacer}
            size="large"
          >
            Delete
          </RedButton>
        </FormChildButton>
      </Toolbar>
      <Divider />
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Leave and Permission Name *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="leave-name"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              placeholder="Leave and Permission Name"
              fullWidth
              margin="normal"
              onChange={event => {
                setName(event.target.value)
              }}
              value={name}
              variant="outlined"
              error={
                (name.length < 1 && isError) ||
                (nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
              }
              helperText={
                name.length < 1 && isError
                  ? 'This field is required'
                  : (nameChecker
                    ? !checkSameNameWithAdd
                    : checkSameNameWithAdd)
                  ? 'Name is already exist'
                  : ''
              }
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Leave and Permission Type *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <RadioGroup
              row
              name="gender"
              value={isUnpaid ? 'Paid' : 'Unpaid'}
              onChange={e => {
                setIsUnpaid(e.target.value === 'Paid' || false)
              }}
            >
              <FormControlLabel
                value={'Paid'}
                control={<Radio color="primary" />}
                label="Paid"
              />
              <FormControlLabel
                value={'Unpaid'}
                control={<Radio color="primary" />}
                label="Unpaid"
              />
            </RadioGroup>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer
          className={classes.withSwitch}
          style={{paddingBottom: 0}}
        >
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Calculation Method *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <div className={classes.flexCenter}>
              <TextField
                id="part-of-leave"
                className={classNames(classes.inputWidth, classes.buttonSpacer)}
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                select
                margin="normal"
                variant="outlined"
                value={selectedMethod}
                onChange={handleSelectedMethod}
                SelectProps={selectProps(selectedMethod)}
              >
                <MenuItem value="" disabled>
                  Select calculation method
                </MenuItem>
                {calcMethods.map((method, i) => {
                  return (
                    <MenuItem key={i} value={method.value}>
                      {method.name}
                    </MenuItem>
                  )
                })}
              </TextField>
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={isLeftOver}
                    onChange={event => {
                      setIsLeftOver(event.target.checked)
                    }}
                    color="primary"
                    inputProps={{'aria-label': 'primary checkbox'}}
                  />
                }
                label="Is leftover enable"
              /> */}
            </div>
          </FormChildInput>
        </FormChildContainer>
        {/* {selectedMethod === calcMethods[1].value && (
          <FormChildInput>
            <TextField
              id="employee-balance"
              helperText="add after date"
              value={1}
              disabled={true}
              type="number"
              margin="normal"
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
            />
          </FormChildInput>
        )}
        {isLeftOver && (
          <FormChildContainer style={{paddingBottom: 0}}>
            <FormChildInput>
              <TextField
                id="left-over-options"
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                select
                helperText="Leftover Valid Until Month(s)"
                fullWidth
                value={leftOverMonth}
                onChange={event => {
                  setLeftOverMonth(event.target.value)
                }}
                margin="normal"
                variant="outlined"
              >
                {leftOverValidOptions.map((method, i) => {
                  return (
                    <MenuItem key={i} value={method.value}>
                      {method.name}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer>
        )} */}
        <FormChildContainer
          className={classes.withSwitch}
          style={{padding: '1rem 0 0 0'}}
        >
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Quota renewable every *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <div className={classes.flexCenter}>
              <TextField
                id="renew-option"
                className={classNames(classes.inputWidth, classes.buttonSpacer)}
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                select
                value={selectedRenewOption}
                onChange={handleSelectedRenew}
                margin="normal"
                variant="outlined"
                SelectProps={selectProps(selectedRenewOption)}
                disabled={selectedMethod === 'accrual'}
              >
                <MenuItem value="" disabled>
                  Select quota renewable options
                </MenuItem>
                {quotaRenewOptions.map((method, i) => {
                  return (
                    <MenuItem key={i} value={method.value}>
                      {method.name}
                    </MenuItem>
                  )
                })}
              </TextField>
              <FormControlLabel
                control={
                  <Switch
                    checked={isProRate}
                    onChange={event => {
                      setIsProRate(event.target.checked)
                    }}
                    color="primary"
                    inputProps={{'aria-label': 'primary checkbox'}}
                    disabled={selectedRenewOption === 2}
                  />
                }
                label="Is Prorate"
              />
            </div>
          </FormChildInput>
        </FormChildContainer>
        {selectedRenewOption === 3 && (
          <FormChildContainer>
            <FormChildInput className={classes.flexCenter}>
              <TextField
                id="renew-every"
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                className={classNames(classes.inputWidth, classes.buttonSpacer)}
                error={!selectedDate && isError}
                helperText="Date *"
                type="number"
                margin="normal"
                value={selectedDate}
                onChange={event => {
                  setSelectedDate(event.target.value)
                }}
                variant="outlined"
              />
              <TextField
                id="part-of-leave"
                className={classes.inputWidth}
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                select
                value={selectedMonth}
                onChange={event => {
                  setSelectedMonth(event.target.value)
                }}
                margin="normal"
                variant="outlined"
                helperText="Month *"
              >
                {months.map((method, i) => {
                  return (
                    <MenuItem key={i} value={method.value}>
                      {method.name}
                    </MenuItem>
                  )
                })}
              </TextField>
            </FormChildInput>
          </FormChildContainer>
        )}
        <FormChildContainer style={{paddingTop: '1rem '}}>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Staff can use this quota from *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="quota-start-from"
              className={classes.inputWidth}
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              select
              fullWidth
              margin="normal"
              variant="outlined"
              value={selectedQuotaStart}
              onChange={event => {
                setSelectedQuotaStart(event.target.value)
              }}
              // SelectProps={selectProps(selectedQuotaStart)}
            >
              <MenuItem value="" disabled>
                Select when staff can use this quota
              </MenuItem>
              {quotaStartOptions.map((method, i) => {
                return (
                  <MenuItem key={i} value={method.value}>
                    {method.name}
                  </MenuItem>
                )
              })}
            </TextField>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>6.</TitleNumber>
            <TitleName>Label Color *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="label-color"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              fullWidth
              margin="normal"
              onChange={event => setColorLabel(event.target.value)}
              variant="outlined"
              value={colorLabel}
              type="color"
              className={classes.inputWidth}
              // defaultValue={colorLabel}
            />
            {/* <ColorPickerPopup
              id="label-color"
              color={colorLabel}
              setColor={color => setColorLabel(color)}
              errorText={
                colorLabel === '' && isError && 'This field is required'
              }
            /> */}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>7.</TitleNumber>
            <TitleName>Text Color *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="text-color"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              className={classes.inputWidth}
              fullWidth
              margin="normal"
              onChange={event => setColorText(event.target.value)}
              variant="outlined"
              value={colorText}
              type="color"
              // defaultValue={colorLabel}
            />
            {/* <ColorPickerPopup
              id="text-color"
              color={colorText}
              setColor={color => setColorText(color)}
              errorText={
                colorText === '' && isError && 'This field is required'
              }
            /> */}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>8.</TitleNumber>
            <TitleName>Allow Leave and Permission Debt</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <RadioGroup
              row
              name="position"
              // value={allowDebt}
              onChange={event => {
                handleAllowDebt(event.target.value)
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Yes"
                checked={allow_leave_debt}
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
                checked={!allow_leave_debt}
              />
            </RadioGroup>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>9.</TitleNumber>
            <TitleName>Remaining Leave Balance*</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <RadioGroup
              row
              name="leftover-type"
              value={remainingLeaveBalance}
              onChange={event => {
                handleRemainingLeaveBalance(event.target.value)
              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="Carry Over"
                checked={remainingLeaveBalance === 1}
              />
              {/* <FormControlLabel
                value={2}
                control={<Radio color="primary" />}
                label="Paid with Money"
                checked={remainingLeaveBalance === 2}
              /> */}
            </RadioGroup>
          </FormChildInput>
        </FormChildContainer>

        {remainingLeaveBalance === 1 && (
          <FormChildContainer style={{paddingBottom: 0}}>
            <FormChildInput
              style={{flexDirection: 'column'}}
              className={classes.flexCenter}
            >
              <TitleName>Maximal Balance*</TitleName>
              <WrapperField>
                <TextField
                  id="leftover-valid-max"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  placeholder="Add Maximal Balance"
                  className={classNames(classes.buttonSpacer)}
                  style={{width: '350px'}}
                  type="number"
                  margin="normal"
                  error={
                    isErrorBalance(leftoverType.leftover_valid_max) && isError
                  }
                  value={leftoverType.leftover_valid_max}
                  onChange={event => {
                    const min = 0
                    let value = parseInt(event.target.value, 10)
                    if (value < min) value = min
                    handleChangeLeftOver(value, 'leftover_valid_max')
                  }}
                  fullWidth
                  variant="outlined"
                  helperText={
                    isErrorBalance(leftoverType.leftover_valid_max) && isError
                      ? 'This field is required'
                      : ''
                  }
                />
                <div>Days</div>
              </WrapperField>
            </FormChildInput>
            <FormChildInput
              style={{flexDirection: 'column'}}
              className={classes.flexCenter}
            >
              <TitleName>
                Balance Duration* <ExpiredBalanceTooltip />
              </TitleName>
              <WrapperField>
                <TextField
                  id="leftover-valid-until-month"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                    inputProps: {
                      min: 0,
                      pattern: '[0-9]*',
                    },
                  }}
                  className={classNames(classes.buttonSpacer)}
                  style={{width: '350px'}}
                  fullWidth
                  variant="outlined"
                  type="number"
                  margin="normal"
                  placeholder="Add Expired Balance"
                  error={
                    isErrorBalance(leftoverType.leftover_valid_until_month) &&
                    isError
                  }
                  value={leftoverType.leftover_valid_until_month}
                  onChange={event => {
                    const max = 12
                    const min = 0
                    let value = parseInt(event.target.value, 10)
                    if (value > max) value = max
                    else if (value < min) value = min
                    handleChangeLeftOver(value, 'leftover_valid_until_month')
                  }}
                  helperText={
                    isErrorBalance(leftoverType.leftover_valid_until_month) &&
                    isError
                      ? 'This field is required'
                      : ''
                  }
                />
                <div>Month</div>
              </WrapperField>
            </FormChildInput>
          </FormChildContainer>
        )}
        {remainingLeaveBalance === 2 && (
          <FormChildContainer style={{paddingBottom: 0}}>
            <FormChildInput
              style={{flexDirection: 'column'}}
              className={classes.flexCenter}
            >
              <TitleName>Paid per Day*</TitleName>
              <WrapperField>
                <TextField
                  id="leftover-money-settings"
                  InputProps={{
                    classes: {
                      input: classes.outlinedInput,
                    },
                  }}
                  className={classNames(classes.buttonSpacer)}
                  style={{width: '350px'}}
                  fullWidth
                  type="number"
                  margin="normal"
                  placeholder="Add Paid per Day Amount"
                  error={
                    isErrorBalance(leftoverType.leftover_money_settings) &&
                    isError
                  }
                  value={leftoverType.leftover_money_settings}
                  onChange={event => {
                    const min = 0
                    let value = parseInt(event.target.value, 10)
                    if (value < min) value = min
                    handleChangeLeftOver(value, 'leftover_money_settings')
                  }}
                  variant="outlined"
                  helperText={
                    isErrorBalance(leftoverType.leftover_money_settings) &&
                    isError
                      ? 'This field is required'
                      : ''
                  }
                />
                <div>Days</div>
              </WrapperField>
            </FormChildInput>
          </FormChildContainer>
        )}
      </FormContainer>
      {/* <Divider />
      <FormChildButton>
        <Button
          variant="contained"
          color="primary"
          // onClick={updatePolicySettings}
          onClick={() => handleClickOpen('edit')}
          size="large"
          className={classes.buttonSpacer}
        >
          Save
        </Button>
        <Button
          onClick={() => handleClickOpen('discard')}
          size="large"
          className={classes.buttonSpacer}
        >
          Cancel
        </Button>
        <RedButton
          onClick={() => setDeletePopUp(true)}
          className={classes.buttonSpacer}
          size="large"
        >
          Delete
        </RedButton>
      </FormChildButton> */}

      {selected.time_leave_policy_children.length > 0 ? (
        showDeletePopUp && (
          <DeletePopUp
            open={showDeletePopUp}
            onDelete={() => {
              setDeletePopUp(false)
              deletePolicySetting()
            }}
            onDismiss={() => {
              setDeletePopUp(false)
            }}
            selectedPolicy={selected}
          />
        )
      ) : (
        <DeletePopup
          open={showDeletePopUp}
          handleClose={() => setDeletePopUp(false)}
          name={selected.name}
          mutation={() => deletePolicySetting(selected.id)}
        />
      )}
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={selected.name}
        feature="Leave Policy"
        mutation={confirmType === 'discard' ? history : updatePolicySettings}
      />
    </Paper>
  )
}

export default withRouter(EditSettingParent)