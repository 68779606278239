import gql from 'graphql-tag'

export const GET_LIST_DIGITAL_DOCUMENT = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: company_files_aggregate(where: {name: {_ilike: $search}}) {
      aggregate {
        count
      }
    }

    company_files(
      where: {name: {_ilike: $search}}
      offset: $offset
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
      global_file_type {
        id
        name
      }
    }
  }
`
