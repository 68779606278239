export function integerFilter(x) {
  const ans = x.replace(/(?!-)[^0-9]/g, '')
  return parseInt(ans < 0 || ans === '' ? 0 : ans, 10)
}

export function checkIntegerInput(param) {
  return (
    (param !== null && typeof param === 'string' && param.length === 0) ||
    (param !== null && typeof param === 'string' && Number(param) <= 0)
  )
}

export function checkStringInput(param) {
  return param !== null && typeof param === 'string' && param.length === 0
}

export function integerHelperText(param) {
  return (
    (param !== null &&
      typeof param === 'string' &&
      param.length === 0 &&
      'This field is required') ||
    (param !== null &&
      typeof param === 'string' &&
      Number(param) <= 0 &&
      'The value must be greater 0')
  )
}

export function stringHelperText(param) {
  return (
    param !== null &&
    typeof param === 'string' &&
    param.length === 0 &&
    'This field is required'
  )
}

export function inputRequiredHelperText(condition1, condition2) {
  if (condition1 && condition2) {
    return 'Perlu diisi inputan wajib ini'
  }
}

export function onChangeNumber(e, setter) {
  e.persist()
  if (e.target.value >= 0) {
    setter(integerFilter(e.target.value))
  }
}

export function onChanger(e, setter) {
  e.persist()
  setter(e.target.value)
}
export function onChangerWithHasChange(e, setter, setHasChange) {
  e.persist()
  setter(e.target.value)
  setHasChange(true)
}
