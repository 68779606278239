import gql from 'graphql-tag'

export const GET_CATEGORY_ICON = gql`
  {
    global_icon_categories {
      id
      name
    }
  }
`
