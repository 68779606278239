import gql from 'graphql-tag'

export const GET_VERSION_LIST = gql`
  query(
    $company: uuid
    $search: String
    $dateFrom: date
    $dateTo: date
    $offset: Int
    $limit: Int
  ) {
    total: performance_individual_versions_aggregate(
      where: {
        company: {_eq: $company}
        name: {_ilike: $search}
        start_date: {_gte: $dateFrom, _lte: $dateTo}
      }
    ) {
      aggregate {
        count
      }
    }
    performance_individual_versions(
      where: {
        company: {_eq: $company}
        name: {_ilike: $search}
        start_date: {_gte: $dateFrom, _lte: $dateTo}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      start_date
      end_date
      date_added
      last_modified
    }
  }
`
