import React from 'react'

import {Button, makeStyles} from '@material-ui/core'

import {retrieveFileIcon} from './AttachmentStyles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    fontSize: theme.spacing(1.75),
    height: theme.spacing(16),
    width: theme.spacing(16),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  label: {
    display: 'grid',
    justifyItems: 'center',
    textAlign: 'center',
    lineHeight: 'normal',
  },

  icon: {
    objectFit: 'contain',
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  filename: {
    wordBreak: 'break-all',
  },
}))

export default function AttachmentCard(props) {
  const {name, url} = props

  const styles = useStyles()
  const icon = retrieveFileIcon(name, url)

  const trim = name.length > 24 ? name.slice(0, 24 - 1) + '…' : name

  return (
    <Button
      component="a"
      target="_blank"
      href={url}
      title={name}
      classes={{root: styles.root, label: styles.label}}
      variant="contained"
    >
      <img src={icon} className={styles.icon} />
      <span className={styles.filename}>{trim}</span>
    </Button>
  )
}
