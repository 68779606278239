import React from 'react'
import PropTypes from 'prop-types'
// import clsx from 'clsx'

import {
  lighten,
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'

import {Divider, Toolbar, Typography, Paper, Button} from '@material-ui/core'

import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined'
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined'
import EditOutlined from '@material-ui/icons/EditOutlined'

import {Link} from 'react-router-dom'

import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#014a62',
    },
  },
})

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
  },
  datePickerAction: {
    marginRight: 30,
  },
  buttonAction: {
    height: 61,
    marginTop: 6,
    width: 144,
  },
  title: {
    flex: '0 0 auto',
    marginLeft: 30,
  },
  uploadButton: {
    color: '#fff',
    textDecoration: 'none',
  },
}))

const CustomDatePicker = classes => {
  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2014-08-18T21:11:54')
  )

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        label="Label"
        format="MM/dd/yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        className={classes.customDatePicker}
      />
    </MuiPickersUtilsProvider>
  )
}

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles()

  return (
    <MuiThemeProvider theme={theme}>
      <Toolbar className={classes.root}>
        <Link
          to={{
            pathname: '/time/attendance',
            state: {active: 'activity'},
          }}
        >
          <KeyboardBackspace />
        </Link>
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            Upload Attendance File
          </Typography>
        </div>
      </Toolbar>
    </MuiThemeProvider>
  )
}

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
}

const Step = ({stepNumber, stepTitle, stepDesc, isEdit, isUpload}) => {
  const classes = useStyles()
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#014a62',
      },
      secondary: {
        main: '#a9a8a8',
      },
    },
  })
  const editTemplateLists = [
    {
      title: '1. Badge number',
      desc: 'Staff ID in the WLB attendance system',
    },
    {
      title: '2. Badge name',
      desc: 'Staff name in the WLB attendance system',
    },
    {
      title: '3. Date',
      desc: 'Date of attendance',
    },
    {
      title: '4. In',
      desc: 'Hours in of the office',
    },
    {
      title: '5. Out',
      desc: 'Hours out of the office',
    },
  ]

  return (
    <div className={classes.stepWrapper}>
      <Typography className={classes.stepLabel}>Step {stepNumber}</Typography>
      <Typography className={classes.stepTitle}>{stepTitle}</Typography>
      <div className={classes.stepIconWrapper}>
        {isEdit ? (
          <EditOutlined className={classes.stepIcon} />
        ) : isUpload ? (
          <CloudUploadOutlined className={classes.stepIcon} />
        ) : (
          <CloudDownloadOutlined className={classes.stepIcon} />
        )}
      </div>
      <Typography className={classes.stepLabel}>{stepDesc}</Typography>
      {isEdit ? (
        <React.Fragment>
          <div className={classes.editTemplateListsWrapper}>
            <div className={classes.editTemplateListsSpacer} />

            {editTemplateLists.map((ediTemplate, index) => (
              <div key={index}>
                <Typography
                  className={classes.editTemplateListsTitle}
                  key={index}
                >
                  {ediTemplate.title}
                </Typography>
                <Typography className={classes.stepLabel}>
                  {ediTemplate.desc}
                </Typography>
              </div>
            ))}
          </div>
        </React.Fragment>
      ) : isUpload ? (
        <React.Fragment>
          <input type="file" />
          <MuiThemeProvider theme={theme}>
            <Button variant="contained" color="primary">
              <Typography className={classes.whiteTextButton}>
                Upload
              </Typography>
            </Button>
          </MuiThemeProvider>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CustomDatePicker classes={classes.customDatePicker} />
          <MuiThemeProvider theme={theme}>
            <Button variant="contained" color="secondary">
              <Typography className={classes.whiteTextButton}>
                Download
              </Typography>
            </Button>
          </MuiThemeProvider>
        </React.Fragment>
      )}
    </div>
  )
}

Step.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepTitle: PropTypes.string.isRequired,
  stepDesc: PropTypes.string.isRequired,
  isDownload: PropTypes.bool,
  isEdit: PropTypes.bool,
  isUpload: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  whiteTextButton: {
    color: '#fff',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 17,
    marginBottom: 17,
  },
  blackTextButton: {
    color: '#000',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 15,
  },
  stepIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  stepWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 41,
    paddingLeft: 81,
    paddingRight: 88,
    paddingBottom: 82,
  },
  stepLabel: {
    color: '#a9a8a8',
    fontSize: 15,
  },
  stepTitle: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 600,
  },
  editTemplateListsTitle: {
    color: '#000',
    fontSize: 15,
  },
  editTemplateListsSpacer: {
    marginTop: 30,
  },
  stepIcon: {
    color: '#014962',
    width: 97,
    height: 97,
  },
  editTemplateListsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detail: {
    color: '#007fb2',
    cursor: 'pointer',
  },
  customDatePicker: {
    width: 160,
  },
}))

export default function ActivityUpload() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar />
        <Divider />
        <div className={classes.content}>
          <Step
            stepNumber={1}
            stepTitle={'Download Template'}
            stepDesc={
              'WLB prepares active employee data files according to the specified period.'
            }
            isDownload={true}
          />
          <Step
            stepNumber={2}
            stepTitle={'Edit Template'}
            stepDesc={
              'Edit employee attendance data according to attendance hours.'
            }
            isEdit={true}
          />
          <Step
            stepNumber={3}
            stepTitle={'Upload Template'}
            stepDesc={
              'after filling in the template file data. You can upload it to be processed automatically by the WLB'
            }
            isUpload={true}
          />
        </div>
      </Paper>
    </div>
  )
}
