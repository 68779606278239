import React, {useState, useEffect, useContext} from 'react'
import helperStyle, {
  BigTextBlackBold,
  WrapperContent,
  WhiteButton,
  TextDarkBlue,
  TextGrey,
  TextBlack,
} from '../../OrganizationPageStyles'

import {
  RootContainer,
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  GenerateTooltip,
} from '../../../../GlobalStyles'
import {Button} from '@material-ui/core'
import {COMPANY_ID} from '../../../../utils/globals'
import {Link} from 'react-router-dom'
import {
  GET_POSITION_LIST,
  GET_JOB_PLACEMENT_BY_POSITION_ID,
  CHECK_PARENT_POSITION,
  GET_BALOON_STATUS_BUTTON,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../../../graphql/queries'
import {
  DELETE_JOB_PROFILE,
  IMPORT_DATA,
  CLOSE_BALOON,
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
} from '../../../../graphql/mutations'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {DataContext} from '../../../../ContextConfig'
import {trimString, disbaledButtonExport} from '../../../../utils/helpers'

import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import DetailJobProfileModal from './modal-job-profile/DetailJobProfileModal'
import MaterialTable from 'material-table'
import Loading from '../../../../components/loading/LoadingComponent'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import ImportBaloon from '../../../../components/importExcel/ImportBaloon'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
// import DownloadPopup from '../../../shared-components/popup/DownloadPopup'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import classNames from 'classnames'

const ListJob = ({searchText}) => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [openImport, setImport] = useState(false)
  const [openExport, setExport] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)

  const [position, setPosition] = useState({
    id: 0,
    name: '',
  })
  const [notification, setNotification] = useState({
    notifDelete: false,
    notifAlert: false,
    notifAlertCantDel: false,
  })
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const [mutationExport, {error: mutationExportError}] = useMutation(
    EXPORT_MUTATION
  )

  const [closeBaloonExport, {error: closeBaloonExportError}] = useMutation(
    CLOSE_BALOON_EXPORT
  )

  const {data: getExportBaloon, error: errorGetExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_job_profiles',
      },
    }
  )

  const {data: getExportDisabled, error: errorGetExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: 'company_job_profiles',
      },
    }
  )

  const [deletePosition] = useMutation(DELETE_JOB_PROFILE)

  const [skipQueryJobPlacement, setSkipQueryJobPlacement] = useState(true)
  const {data: jobPlacement} = useQuery(GET_JOB_PLACEMENT_BY_POSITION_ID, {
    variables: {position: position && position.id},
    skip: skipQueryJobPlacement,
    onCompleted: () => {
      setSkipQueryJobPlacement(true)
    },
  })

  // const [skipQueryPositionDetail, setSkipQueryPositionDetail] = useState(true)

  // const renderFormEdit = () => {
  //   setSkipQueryPositionDetail(false)
  //   useQuery(GET_DETAIL_JOB_PROFILE, {
  //     variables: {id: position && position.id},
  //     skip: skipQueryPositionDetail,
  //     onCompleted: positionDetail => {
  //       setSkipQueryPositionDetail(true)
  //       history.push({
  //         pathname: `/organization/position/edit`,
  //         state: {
  //           active: 'edit-position',
  //           status: 'Edit',
  //           dataEdit: positionDetail && positionDetail.company_job_profiles[0],
  //           // data: data.company_job_profiles,
  //           // parentName:
  //           //   rowData.job_profile_supervisor &&
  //           //   rowData.job_profile_supervisor.title,
  //           // idEdit: rowData.id,
  //           parentChecker: parentChecker,
  //           idParentOUJobProfile:
  //             data &&
  //             data.parent_job_profile &&
  //             data.parent_job_profile[0] &&
  //             data.parent_job_profile[0].company_organization.id,
  //           idParentTopOU:
  //             data &&
  //             data.parent_organization &&
  //             data.parent_organization[0] &&
  //             data.parent_organization[0].id,
  //         },
  //       })
  //     },
  //   })
  // }

  /* if delete button clicked, then fetch job placement by position */
  useEffect(() => {
    if (jobPlacement) {
      const countPlacement =
        jobPlacement &&
        jobPlacement.total &&
        jobPlacement.total.aggregate &&
        jobPlacement.total.aggregate.count > 0

      const countChild =
        jobPlacement &&
        jobPlacement.totalSub &&
        jobPlacement.totalSub.aggregate &&
        jobPlacement.totalSub.aggregate.count === 0

      const notif = {...notification}
      // eslint-disable-next-line dot-notation
      if (countPlacement) notif['notifAlertCantDel'] = true
      else notif[countChild ? 'notifDelete' : 'notifAlert'] = true

      setNotification(notif)
    }
  }, [jobPlacement])

  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')
  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )
  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )

  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    // loading: loadingGetBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
      table: 'company_job_profiles',
    },
  })

  const {dataContext, setDataContext} = useContext(DataContext)
  const {
    data: dataJob,
    error: errorJob,
    loading: loadingJob,
    refetch: refetchJob,
  } = useQuery(CHECK_PARENT_POSITION)

  const {data, error, loading, refetch} = useQuery(GET_POSITION_LIST, {
    variables: {
      search: `%${searchText}%`,
      company: COMPANY_ID,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const clearUseContext = () => {
    setDataContext(null)
  }

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [dataContext])

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      clearUseContext()
    }, 50)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [dataContext])

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetchJob()
    }, 500)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  const handleClickOpenImport = () => {
    setImport(true)
  }
  const handleCloseImport = () => {
    setImport(false)
  }

  const handleClick = (id, name) => {
    setPosition({
      id: id,
      name: name,
    })
  }

  // const handleOpenAlert = data => {
  /* moved to useEffect */
  // const countPlacement =
  //   data &&
  //   data.people_work_placements_aggregate &&
  //   data.people_work_placements_aggregate.aggregate &&
  //   data.people_work_placements_aggregate.aggregate.count > 0

  // const countChild =
  //   data &&
  //   data.job_profile_subordinate_aggregate &&
  //   data.job_profile_subordinate_aggregate.aggregate &&
  //   data.job_profile_subordinate_aggregate.aggregate.count === 0

  // if (countChild) {
  //   if (countPlacement) {
  //     setNotification({...notification, notifAlertCantDel: true})
  //   } else {
  //     setNotification({...notification, notifDelete: true})
  //   }
  // } else {
  //   if (countPlacement) {
  //     setNotification({...notification, notifAlertCantDel: true})
  //   } else {
  //     setNotification({...notification, notifAlert: true})
  //   }
  // }
  // }

  const handleCloseAlert = () => {
    setNotification({
      notifDelete: false,
      notifAlert: false,
      notifAlertCantDel: false,
    })
  }

  const parentChecker =
    dataJob && dataJob.company_job_profiles.length !== 0
      ? dataJob.company_job_profiles[0]
      : false

  if (
    (data === undefined && error === undefined) ||
    loadingJob ||
    getBaloonStatus === undefined
  ) {
    return <Loading />
  } else if (
    error !== undefined ||
    errorJob !== undefined ||
    errorGetBaloonStatus !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationExportError !== undefined ||
    closeBaloonExportError !== undefined ||
    errorGetExportBaloon !== undefined ||
    errorGetExportDisabled !== undefined ||
    mutationImportError !== undefined
  ) {
    return (
      <div>
        {error}
        {errorJob}
      </div>
    )
  }

  if (getBaloonStatus) {
    if (
      getBaloonStatus.company_import_data_logs.length > 0 &&
      getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
    ) {
      refetch()
    }
  }

  const importMutation = async () => {
    try {
      const response = await mutationImport({
        variables: {
          table: 'company_job_profiles',
          file: fileURL,
        },
      })
      // eslint-disable-next-line no-console
      console.log(response)
      refetch()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const dataListJob = []
  data.company_organizations &&
    data.company_organizations.map(res => {
      dataListJob.push({
        id: res && res.id,
        organizationUnit: res && res.name,
        totalJobProfile:
          res.company_job_profiles_aggregate &&
          res.company_job_profiles_aggregate.aggregate.count,
        totalEmployee: res && res.count_employee,
        parent: res && res.parent,
      })
    })

  const handleDownload = () => {
    setExport(!openExport)
    if (openExport) {
      mutationExport({
        variables: {
          table: 'company_job_profiles',
        },
      })
        .then(() => {
          enqueueSnackbar('Generate Data is processing!', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          refetch()
        })
        .catch(() => {
          enqueueSnackbar('Generate Data failed, please try again later', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    }
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  const column = [
    {
      title: '',
      render: rowData => (
        <img className={classNames(classes.width15)} src={rowData.job_icon} />
      ),
    },
    {
      title: 'Kode Posisi',
      field: 'code',
      defaultSort: 'asc',
    },
    {
      title: 'Nama Posisi',
      field: 'title',
      headerStyle: {minWidth: 175},
      cellStyle: {minWidth: 175},
    },
    {
      title: 'Unit Organisasi',
      field: 'company_organization.name',
      headerStyle: {minWidth: 175},
      cellStyle: {minWidth: 175},
      render: rowData => {
        return (
          <>
            <TextBlack>{rowData?.company_organization?.name ?? ''}</TextBlack>
            <TextGrey>
              {`${trimString(rowData?.company_organization?.code ?? '', 30)}${
                rowData?.company_organization?.code?.length > 30 ? '...' : ''
              }`}
            </TextGrey>
          </>
        )
      },
    },
    {
      title: 'Induk',
      field: 'job_profile_supervisor.title',
      headerStyle: {minWidth: 175},
      cellStyle: {minWidth: 175},
      render: rowData => {
        return (
          <>
            <TextBlack>
              {rowData?.job_profile_supervisor?.title ?? ''}
            </TextBlack>
            <TextGrey>
              {`${trimString(rowData?.job_profile_supervisor?.code ?? '', 30)}${
                rowData?.job_profile_supervisor?.code?.length > 30 ? '...' : ''
              }`}
            </TextGrey>
          </>
        )
      },
    },

    {
      title: 'Aksi',
      field: 'action',
      render: rowData => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <>
              <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>
              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  variant="h6"
                  onClick={() => {
                    popupState.close()
                    handleClick(rowData.id, rowData.title)
                    setOpenDetail(true)
                  }}
                >
                  Rincian
                </StyledMenuItem>
                <StyledMenuItem
                  component={Link}
                  to={{
                    pathname: `/organization/position/edit`,
                    state: {
                      active: 'edit-position',
                      status: 'Edit',
                      dataEdit: rowData,
                      // data: data.company_job_profiles,
                      // parentName:
                      //   rowData.job_profile_supervisor &&
                      //   rowData.job_profile_supervisor.title,
                      // idEdit: rowData.id,
                      parentChecker: parentChecker,
                      idParentOUJobProfile:
                        data &&
                        data.parent_job_profile &&
                        data.parent_job_profile[0] &&
                        data.parent_job_profile[0].company_organization.id,
                      idParentTopOU:
                        data &&
                        data.parent_organization &&
                        data.parent_organization[0] &&
                        data.parent_organization[0].id,
                    },
                  }}
                >
                  Ubah
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    popupState.close()
                    handleClick(rowData.id, rowData.title)
                    setSkipQueryJobPlacement(false)
                  }}
                >
                  Hapus
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>
      ),
    },
  ]

  const onDeletePosition = () => {
    const variables = {
      id: position.id,
    }
    deletePosition({variables: variables})
      .then(() => {
        enqueueSnackbar('Position Unit deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete Position Unit failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  return (
    <RootContainer>
      <ImportBaloon
        data={getBaloonStatus}
        closeBaloonMutation={closeBaloonMutation}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
      />
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
      />
      <PaperContainer>
        <EnhancedToolbar>
          <BigTextBlackBold>Posisi</BigTextBlackBold>
          <WrapperContent>
            <GenerateTooltip
              title={
                (getExportBaloon &&
                  getExportBaloon.company_export_data_logs &&
                  getExportBaloon.company_export_data_logs.length > 0 &&
                  getExportBaloon.company_export_data_logs[0].status ===
                    'FAILED') ||
                disbaledButtonExport(
                  getExportDisabled &&
                    getExportDisabled.company_export_data_logs &&
                    getExportDisabled.company_export_data_logs[0] &&
                    getExportDisabled.company_export_data_logs[0].date_added
                )
                  ? `you can only generate data once a day. to download data use the link that already generated`
                  : ``
              }
              placement="left"
            >
              <span>
                <WhiteButton
                  variant="contained"
                  size="large"
                  style={{marginRight: 12}}
                  onClick={() => handleDownload()}
                  disabled={
                    (getExportBaloon &&
                      getExportBaloon.company_export_data_logs &&
                      getExportBaloon.company_export_data_logs.length > 0 &&
                      getExportBaloon.company_export_data_logs[0].status ===
                        'FAILED') ||
                    disbaledButtonExport(
                      getExportDisabled &&
                        getExportDisabled.company_export_data_logs &&
                        getExportDisabled.company_export_data_logs[0] &&
                        getExportDisabled.company_export_data_logs[0].date_added
                    )
                  }
                >
                  <TextDarkBlue>Ekspor Data</TextDarkBlue>
                </WhiteButton>
              </span>
            </GenerateTooltip>
            <WhiteButton
              variant="contained"
              size="large"
              onClick={() => handleClickOpenImport()}
              disabled={
                getBaloonStatus.company_import_data_logs.length > 0 &&
                getBaloonStatus.company_import_data_logs[0].status === 'WAITING'
              }
            >
              {getBaloonStatus.company_import_data_logs.length > 0 &&
              getBaloonStatus.company_import_data_logs[0].status ===
                'WAITING' ? (
                <TextGrey>Impor sedang diproses...</TextGrey>
              ) : (
                <TextDarkBlue>Impor Data</TextDarkBlue>
              )}
            </WhiteButton>
            <Button
              className={classes.marginLeft10}
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={{
                pathname: '/organization/position/add',
                state: {
                  active: 'add-position',
                  status: 'Add',
                  parentChecker: parentChecker,
                  menu: 'position',
                },
              }}
            >
              Tambah Posisi
            </Button>
          </WrapperContent>
        </EnhancedToolbar>
        <MaterialTable
          options={{
            ...TableOptions,
            selection: false,
            showTitle: false,
            toolbar: false,
            emptyRowsWhenPaging: false,
          }}
          isLoading={loading}
          localization={{
            pagination: TableLocalization.pagination,
            body: {
              emptyDataSourceMessage: (
                <NoDataListComponent search={searchText} />
              ),
            },
          }}
          columns={column}
          data={data.company_job_profiles}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={data.total.aggregate.count}
          page={pagination.offset}
          pageSize={pagination.limit}
        ></MaterialTable>
        <DetailJobProfileModal
          open={openDetail}
          close={() => setOpenDetail(false)}
          id={position.id}
        />
        <ImportPopup
          open={openImport}
          position={'Position'}
          handleClose={handleCloseImport}
          mutation={importMutation}
          fileTemplate={
            'https://public-media.wlb.co.id/5db6681b-f1e6-456a-9a9e-c347bdffdad1-1602485495533.xlsx'
          }
          isAlreadyImported={isAlreadyImported}
          setIsAlreadyImported={setIsAlreadyImported}
          setImport={setImport}
          fileURL={fileURL}
          setFileURL={setFileURL}
          closeBaloon={closeBaloonMutation}
          errorsImport={
            getBaloonStatus.company_import_data_logs.length > 0 &&
            getBaloonStatus.company_import_data_logs[0].reports &&
            getBaloonStatus.company_import_data_logs[0].reports.failed.length >
              0
              ? getBaloonStatus.company_import_data_logs[0].reports.failed
              : []
          }
        />
        <DeletePopup
          open={notification.notifDelete}
          handleClose={() => handleCloseAlert()}
          id={position.id}
          name={position.name}
          mutation={() => onDeletePosition()}
        />
        <AlertDialog
          open={notification.notifAlert}
          handleClose={handleCloseAlert}
          feature={`Hapus ${position.name}`}
          name={position.name}
          fMessage="Tidak dapat menghapus "
          lMessage=" , harap hapus atau pindahkan posisi dasar ke posisi lain yang sudah ada sebelum melanjutkan penghapusan ini "
          type="Cancel"
          positiveTitleButton="Batal"
        />
        <AlertDialog
          open={notification.notifAlertCantDel}
          handleClose={handleCloseAlert}
          feature={`Tidak dapat menghapus ${position.name}`}
          name={position.name}
          fMessage="Anda tidak dapat menghapus "
          lMessage=" karena digunakan pada posisi "
          type="Confirm"
          positiveTitleButton="Konfirmasi"
        />
        <AlertDialog
          open={openExport}
          handleClose={handleDownload}
          feature="Ekspor data sedang diproses!"
          message="Ekspor data sedang diproses! Kami akan mengirimkan email kepada Anda jika sudah selesai"
          type="Confirm"
          positiveTitleButton="Konfirmasi"
        />
      </PaperContainer>
    </RootContainer>
  )
}

export default ListJob
