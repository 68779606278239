import React from 'react'

import {makeStyles, Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 32px 30px',
  },
}))

export const ButtonAddDataTab = props => {
  const {children, onClick} = props

  const styles = useStyles()

  return (
    <div className={styles.root}>
      <Button onClick={onClick} color="secondary" startIcon={<AddIcon />}>
        {children}
      </Button>
    </div>
  )
}
