import React, {useState} from 'react'
import {InputAdornment, makeStyles, TextField} from '@material-ui/core'
import {TitleName} from '../../../../../../GlobalStyles'
import {
  convertToRupiah,
  currencyMaskingMethod,
  getNumber,
} from '../../../../../../utils/helpers'
import {getErrorMultiplier} from './helpers'

export const useStyles = makeStyles(() => ({
  wrap: {display: 'flex', flexDirection: 'column', paddingTop: 10},
  wrapRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapItm50: {width: '50%'},
  wrapItmSml: {width: '38%'},
  multiplierWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  title: {marginBottom: 12},
  silumationWrap: {
    display: 'flex',
    padding: 24,
    flexDirection: 'column',
    backgroundColor: '#f7f8f9',
    borderRadius: 5,
  },
  titleSimulation: {
    color: '#014a62',
    fontSize: 14,
    fontWeight: '600',
    margin: '0px 0px 12px',
  },
  itm: {margin: '0px 0px 12px'},
  rootInput: {backgroundColor: '#fff', marginBottom: 12},
  textfieldNumber: {textAlign: 'right'},
  salaryDivider: {
    borderRight: '1px solid rgba(0, 0, 0, 0.27)',
    paddingRight: 13,
    display: 'flex',
    alignItems: 'center',
    height: 40,
    marginRight: 6,
  },
  divisorField: {width: 132, marginBottom: 4},
}))

export default function BasedOnSalary(props) {
  const {moneyCompensation, setMoneyCompensation, isError} = props
  const classes = useStyles()

  const {isErrorMultiplier, indexOfError} = getErrorMultiplier(
    moneyCompensation?.multipliers || []
  )

  const handleChangeMultiplier = (e, idx) => {
    e.persist()

    const isFloat = /^$|^[+]?\d+(\.\d+)?$/.test(e.target.value)

    if (isFloat) {
      setMoneyCompensation(prev => {
        const multipliers = [...prev.multipliers]
        multipliers[idx].value = e.target.value
        return {...prev, multipliers}
      })
    }
  }

  const handleChangeDivisor = e => {
    const {isNumber, number} = getNumber(e.target.value)

    if (isNumber) {
      setMoneyCompensation(prev => ({...prev, divisor: number}))
    }
  }

  const errorDivisor =
    isError && (!moneyCompensation?.divisor || moneyCompensation?.divisor === 0)

  const helperText = errorDivisor
    ? 'This field is required'
    : 'Default divisor is 173 based on average working hours each/per month'

  return (
    <>
      <TitleName className={classes.title}>Overtime Calculation</TitleName>
      <div className={classes.wrap}>
        <TitleName className={classes.title}>Multiplier</TitleName>
        <div className={classes.wrapRow}>
          <div className={classes.wrapItm50}>
            {moneyCompensation?.multipliers.map((v, idx) => {
              const errorMultiplier =
                isError && isErrorMultiplier && indexOfError.includes(idx)

              return (
                <MultiplierItem
                  key={v.id}
                  value={v.value}
                  number={v.id + 1}
                  onChange={e => handleChangeMultiplier(e, idx)}
                  error={errorMultiplier}
                />
              )
            })}
            <div className={classes.wrap}>
              <span style={{margin: 0}} className={classes.itm}>
                Divisor
              </span>
              <TextField
                onChange={handleChangeDivisor}
                InputProps={{classes: {root: classes.divisorField}}}
                value={moneyCompensation?.divisor}
                size="small"
                variant="outlined"
                error={errorDivisor}
                placeholder="Add Divisor"
                helperText={helperText}
                onFocus={e => e.target.select()}
              />
            </div>
          </div>
          <div className={classes.wrapItmSml}>
            <Simulation
              multipliers={moneyCompensation?.multipliers || []}
              divisor={moneyCompensation?.divisor}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const MultiplierItem = ({number, onChange, value, error}) => {
  const classes = useStyles()

  return (
    <div className={classes.multiplierWrapper}>
      <p>{number} Hour</p>
      <TextField
        onChange={onChange}
        value={value}
        error={error}
        size="small"
        step="0.01"
        min="0"
        type="number"
        variant="outlined"
        placeholder="Add Multiplier"
        onFocus={e => e.target.select()}
        helperText={error ? 'This field is required' : ''}
      />
    </div>
  )
}

const Simulation = ({multipliers, divisor}) => {
  const classes = useStyles()
  const [salary, setSalary] = useState('')

  const handleChangeSalary = e => {
    const {isNumber, number} = getNumber(e.target.value)

    if (isNumber) {
      setSalary(number)
    }
  }

  return (
    <div className={classes.silumationWrap}>
      <p className={classes.titleSimulation}>Simulation</p>
      <p className={classes.titleSimulation}>Salary</p>
      <TextField
        onChange={handleChangeSalary}
        value={currencyMaskingMethod(salary)}
        size="small"
        variant="outlined"
        placeholder="Add Salary"
        classes={{root: classes.rootInput}}
        onFocus={e => e.target.select()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div className={classes.salaryDivider}>
                <p>Rp</p>
              </div>
            </InputAdornment>
          ),
          classes: {
            input: classes.textfieldNumber,
          },
        }}
      />
      <div style={{marginTop: 12}} className={classes.wrapRow}>
        <p style={{fontWeight: '600'}} className={classes.itm}>
          Overtime Duration
        </p>
        <p style={{fontWeight: '600'}} className={classes.itm}>
          Total Paid
        </p>
      </div>
      {multipliers.map((v, idx) => {
        const calculated =
          (parseFloat(v?.value || 0) * parseFloat(salary || 0)) / divisor
        const result = convertToRupiah(calculated)

        return (
          <div key={idx} className={classes.wrapRow}>
            <span className={classes.itm}>{v.id + 1} Hour</span>
            <span className={classes.itm}>
              {v?.value && salary ? result : '-'}
            </span>
          </div>
        )
      })}
    </div>
  )
}
