import React from 'react'
import {Typography} from '@material-ui/core'
import {PaperContainer} from '../../../GlobalStyles'
import {IconBG, InformationWrapper} from './TotalCardStyles'

const WidgetTotal = props => {
  const {icon, value, color, title} = props

  return (
    <PaperContainer
      style={{
        display: 'flex',
        borderRadius: 5,
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.04)',
      }}
    >
      <IconBG color={color}>{icon}</IconBG>
      <InformationWrapper color={color}>
        <Typography variant="h6">{value}</Typography>
        <Typography variant="body2" style={{color: '#a9a8a8'}}>
          {`Total ${title}`}
        </Typography>
      </InformationWrapper>
    </PaperContainer>
  )
}

export default WidgetTotal
