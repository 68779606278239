import React from 'react'
import {useQuery, useMutation} from '@apollo/react-hooks'
import moment from 'moment'

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from '@material-ui/core'

import {
  EnhancedToolbar,
  GenerateTooltip,
  PaperContainer,
  RootContainer,
} from '../../../../GlobalStyles'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import ChoosePeriodPopup from '../../../shared-components/popup/ChoosePeriodPopup'
import {FlexColumnStart} from '../../../performance/goal/modal/modalStyles'

import {isDisableButtonExport} from '../../../../utils/helpers'
import {COMPANY_ID} from '../../../../utils/globals'
import {
  EXPORT_MUTATION,
  CLOSE_BALOON_EXPORT,
} from '../../../../graphql/mutations'
import {
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../../../graphql/queries'

const EXPORT_TABLE = 'wallet_report'

function WalletReport() {
  const [showConfirmPopup, setShowConfirmPopup] = React.useState(false)

  const [checkbox, setCheckbox] = React.useState({
    cb: true,
    eb: true,
    // vb: true,
  })

  const [dispatchExport, {loading: dispatching}] = useMutation(EXPORT_MUTATION)
  const [dispatchCloseExportBaloon] = useMutation(CLOSE_BALOON_EXPORT)

  const {data: exportBaloonData, loading: loadingExportBaloon} = useQuery(
    GET_STATUS_EXPORT_BALOON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: EXPORT_TABLE,
      },
    }
  )

  const {data: exportDisabledData, loading: loadingExportDisabled} = useQuery(
    GET_EXPORT_DISABLED_BUTTON,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 10000,
      variables: {
        company: COMPANY_ID,
        table: EXPORT_TABLE,
      },
    }
  )

  const closeExportBaloon = () => {
    const logs = exportBaloonData.company_export_data_logs

    if (!logs) {
      return
    }

    const id = logs[0].id

    dispatchCloseExportBaloon({
      variables: {id},
    })
  }

  const handleCheckbox = event => {
    if (event.target.name === 'all') {
      setCheckbox({
        cb: event.target.checked,
        eb: event.target.checked,
        // vb: event.target.checked,
      })
    } else {
      setCheckbox({
        ...checkbox,
        [event.target.name]: event.target.checked,
      })
    }
  }

  const handleReportConfirm = ({startDate, endDate}) => {
    setShowConfirmPopup(false)

    const selections = Object.entries(checkbox)
      .filter(([, value]) => value)
      .map(([key]) => key)
      .join(',')

    const format = 'YYYY-MM-DD'
    const start_date = moment(startDate).format(format)
    const end_date = moment(endDate).format(format)

    dispatchExport({
      variables: {
        table: EXPORT_TABLE,
        filter: {
          selections,
          start_date,
          end_date,
        },
      },
    })
  }

  const selectedOne = checkbox.cb || checkbox.eb /* || checkbox.vb */
  const selectedAll = checkbox.cb && checkbox.eb /* && checkbox.vb */

  const isExportFailed =
    exportBaloonData?.company_export_data_logs?.[0]?.status === 'FAILED'
  const isExportDisabled = isDisableButtonExport(
    exportDisabledData?.company_export_data_logs?.[0]?.date_added
  )

  return (
    <RootContainer>
      <ExportBaloon
        data={exportBaloonData}
        closeBaloonMutation={closeExportBaloon}
        retry={null}
        feature="Claim Limit Report"
        availableToSeeProblem
        availableToDownloadExcel
      />
      <PaperContainer>
        <EnhancedToolbar>
          <Typography style={{fontWeight: '600'}} variant="subtitle1">
            Download Report
          </Typography>

          <GenerateTooltip
            title={
              isExportFailed
                ? 'Your previous export had failed, please check the report'
                : isExportDisabled
                ? 'You can only generate data once every 24 hours'
                : ''
            }
            placement="left"
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  !selectedOne ||
                  isExportFailed ||
                  isExportDisabled ||
                  loadingExportBaloon ||
                  loadingExportDisabled ||
                  dispatching
                }
                onClick={() => setShowConfirmPopup(true)}
              >
                Generate Data
              </Button>
            </div>
          </GenerateTooltip>
        </EnhancedToolbar>

        <Divider />

        <div style={{padding: '24px'}}>
          <Typography style={{fontWeight: 'bold', marginBottom: 16}}>
            Select the data that you want to download
          </Typography>
          <FlexColumnStart>
            <FormControlLabel
              value="Select All"
              control={
                <Checkbox
                  onChange={handleCheckbox}
                  checked={selectedAll}
                  color="primary"
                />
              }
              label="Select All"
              name="all"
            />
            <FormControlLabel
              value="Report Company Balance"
              control={
                <Checkbox
                  onChange={handleCheckbox}
                  checked={checkbox.cb}
                  color="primary"
                />
              }
              label="Report Company Balance"
              name="cb"
            />
            <FormControlLabel
              value="Report Employee Balance"
              control={
                <Checkbox
                  onChange={handleCheckbox}
                  checked={checkbox.eb}
                  color="primary"
                />
              }
              label="Report Employee Balance"
              name="eb"
            />
          </FlexColumnStart>
        </div>

        <ChoosePeriodPopup
          open={showConfirmPopup}
          onClose={() => setShowConfirmPopup(false)}
          onConfirm={handleReportConfirm}
        />
      </PaperContainer>
    </RootContainer>
  )
}

export default WalletReport
