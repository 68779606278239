import React from 'react'
import {
  ColWrapper,
  ImgStyled,
  RowContentWrapper,
  TyphoSection,
} from '../../DetailEmployeeStyles'
import ModalPopup from '../../popup/ModalPopup'

const STYLE = {
  wrap: {
    display: 'flex',
    flexDiraction: 'row',
    flexWrap: 'wrap',
  },
  paddingModal: {
    padding: '26px 34px',
  },
}

const Content = ({title, data = [], limitData = null}) => {
  return (
    <ColWrapper>
      <TyphoSection title="true" mb="true">
        {title}
      </TyphoSection>
      <div style={STYLE.wrap}>
        {data.length > 0
          ? data.splice(0, limitData || data.length).map((res, i) => (
              <RowContentWrapper
                style={{width: '50%'}}
                key={`${i}-itm`}
                mb="true"
              >
                <ImgStyled src={res.avatar} alt="avatar" />
                <ColWrapper style={{width: '90%'}} ml="true">
                  <TyphoSection bold="true">{res.name}</TyphoSection>
                  <TyphoSection>{res.position}</TyphoSection>
                </ColWrapper>
              </RowContentWrapper>
            ))
          : '-'}
      </div>
    </ColWrapper>
  )
}

export default function Officemate({dataWorking, user_name, onClose, open}) {
  const subordinate =
    dataWorking?.working?.[0]?.company_job_profile?.subordinate || []
  const supervisor =
    dataWorking?.working?.[0]?.company_job_profile?.supervisor || {}

  const dataSubordinate = []
  subordinate.map(row => {
    row.people_work_placements.map(res => {
      dataSubordinate.push({
        title: row.title,
        ...res,
      })
    })
  })

  return (
    <ModalPopup
      onClose={onClose}
      open={open}
      title={`${user_name}'s Officemate`}
    >
      <div style={STYLE.paddingModal}>
        {supervisor?.people_work_placements?.[0] && (
          <Content
            title="Atasan Langsung"
            limitData={1}
            data={supervisor?.people_work_placements?.map(res => {
              return {
                name: (res.global_user && res.global_user.name) || '-',
                avatar: (res.global_user && res.global_user.avatar) || null,
                position: supervisor?.title || '-',
              }
            })}
          />
        )}

        <Content
          title="Bawahan Langsung"
          data={dataSubordinate.map(res => {
            return {
              name: res.global_user?.name || '-',
              avatar: res.global_user?.avatar || null,
              position: res.title || '-',
            }
          })}
        />
      </div>
    </ModalPopup>
  )
}
