import React, {useState} from 'react'

import {lighten, makeStyles} from '@material-ui/core/styles'

import {
  IconButton,
  Button,
  Divider,
  MenuItem,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import {MapMarker} from 'mdi-material-ui'

import GoogleMapReact from 'google-map-react'
import {ArrowBack} from '@material-ui/icons'
import {Link, withRouter} from 'react-router-dom'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import {ADD_LOCATION_RESTRICTION} from '../../../../graphql/mutations'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {
  FormContainer,
  FormChildTitle,
  FormChildContainer,
  TitleName,
  TitleNumber,
  FormChildInput,
  FormChildButton,
} from '../../../../GlobalStyles'

import Geocode from 'react-geocode'
import AddEdit from '../../../shared-components/popup/AddEditPopup'

const useToolbarStyles = makeStyles(theme => ({
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
  },
  datePickerAction: {
    marginRight: 30,
  },
  buttonAction: {
    height: 61,
    marginTop: 6,
    width: 200,
  },
  buttonText: {
    textDecoration: 'none',
    color: '#fff',
  },
  title: {
    flex: '0 0 auto',
    marginLeft: 10,
  },
  uploadButton: {
    color: '#fff',
    textDecoration: 'none',
  },
}))

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  content: {
    minWidth: 850,
    minHeight: 750,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-left',
    alignItems: 'flex-left',
    padding: 25,
  },
  watchLaterIcon: {
    color: '#014a62',
    width: 126,
    height: 126,
  },
  whiteTextButton: {
    color: '#fff',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 17,
    marginBottom: 17,
  },
  blackTextButton: {
    color: '#000',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 15,
  },
  stepIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  detailActivityWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 41,
    paddingLeft: 81,
    paddingRight: 88,
    paddingBottom: 82,
  },
  detailActivityLabel: {
    color: '#014a62',
    fontSize: 15,
    fontWeight: 600,
  },
  detailActivityTitle: {
    color: '#000000',
    fontSize: 17,
    fontWeight: 600,
  },
  editTemplateListsTitle: {
    color: '#000',
    fontSize: 15,
  },
  editTemplateListsSpacer: {
    marginTop: 30,
  },
  stepIcon: {
    color: '#014962',
    width: 97,
    height: 97,
  },
  editTemplateListsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detail: {
    color: '#007fb2',
    cursor: 'pointer',
  },
  customDatePicker: {
    width: 160,
  },

  tableWrapper: {
    overflowX: 'auto',
  },
  buttonAddWorkSchedule: {
    marginTop: 43,
  },
  greyText: {
    color: '#a9a8a8',
  },
  expandableHeading: {
    color: '#014a62',
    fontWeight: 'bold',
  },
  expandableIcon: {
    color: '#014a62',
  },
  outlinedInput: {
    padding: '10px 14px',
  },
  divider: {margin: '.4rem -1.6rem 1.2rem -1.6rem'},
  buttonSpacer: {marginRight: 12},
}))

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles()

  return (
    <Toolbar disableGutters>
      <Link
        to={{
          pathname: '/time/attendance/location_restrictions',
          state: {active: 'location-restrictions'},
        }}
      >
        <IconButton edge="end" aria-label="back">
          <ArrowBack />
        </IconButton>
      </Link>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Add New Location
        </Typography>
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
}

function LocationRestrictionAdd(props) {
  const {dataCheck} = props
  const classes = useStyles()

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [addressPlaceholder, setAddressPlaceholder] = useState('')
  const [latitude, setLatitude] = useState(-6.9174639)
  const [longitude, setLongitude] = useState(107.61912280000001)
  const [radius, setRadius] = useState(0)
  const [locationInfo, setLocationInfo] = useState(null)
  const [isMapLoaded, setMapLoaded] = useState(false)
  const [isError, setIsError] = useState(false)
  const [addLocationRestriction] = useMutation(ADD_LOCATION_RESTRICTION)
  const {enqueueSnackbar} = useSnackbar()
  const [openPopup, setPopup] = useState(false)
  const [confirmType, setConfirmType] = useState('')

  const nameChecker =
    dataCheck &&
    dataCheck.time_attendance_location &&
    dataCheck.time_attendance_location.some(
      v => v.name.toLowerCase() === name.toLowerCase()
    )

  const handleClose = () => {
    setPopup(false)
  }

  const history = () => {
    props.history.push({
      pathname: '/time/attendance/location_restrictions',
      state: {active: 'location-restrictions'},
    })
  }

  const handleClickOpen = type => {
    if (type === 'discard') {
      setConfirmType(type)
      setPopup(true)
    } else {
      if (
        name === '' ||
        (latitude === 0 && longitude === 0) ||
        address.length < 2 ||
        radius <= 0 ||
        nameChecker
      ) {
        const ids = document.getElementById('top')
        ids.scrollIntoView({behavior: 'smooth'})
        setIsError(true)
        setTimeout(() => setIsError(false), 3000)
      } else {
        setConfirmType(type)
        setPopup(true)
      }
    }
  }

  const updateLocation = () => {
    const data = {
      name: name,
      location_radius: radius,
      location: {
        address: addressPlaceholder,
        lat: latitude,
        lng: longitude,
      },
      location_info: locationInfo,
    }

    addLocationRestriction({variables: {data}})
      .then(() => {
        // console.log(data)
        enqueueSnackbar('Success Add data', {variant: 'success'})
        history()
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
        })
      })
  }

  const handleApiLoaded = maps => {
    window.google = {maps}
    if (isMapLoaded === false) setMapLoaded(true)
  }

  const handleChange = address => {
    // console.log(address)
    setAddress(address)
  }

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        // console.log(results)
        setLocationInfo(results)
        return getLatLng(results[0])
      })
      .then(latLng => {
        // console.log('Success', latLng.lat, latLng.lng)
        setLatitude(latLng.lat)
        setLongitude(latLng.lng)
        setAddressPlaceholder(address)
        setAddress(address)
      })
      .catch(() => {})
  }

  const handleClickMap = e => {
    Geocode.setApiKey('AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ')
    Geocode.fromLatLng(e.lat, e.lng).then(
      response => {
        const address = response.results[0].formatted_address
        setLatitude(e.lat)
        setLongitude(e.lng)
        setAddressPlaceholder(address)
        setAddress(address)
      },
      () => {}
    )
  }

  const handleRadius = value => {
    if (value < 0) {
      value = 0
    }
    setRadius(value)
  }

  // console.log(latitude, longitude)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar />
        <Divider />
        <FormContainer id="top">
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>1.</TitleNumber>
              <TitleName>Location Name *</TitleName>
            </FormChildTitle>
            <FormChildInput id="asd">
              <TextField
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                margin="normal"
                variant="outlined"
                value={name}
                fullWidth
                placeholder="Location Name"
                onChange={event => setName(event.target.value)}
                error={(name.length < 1 && isError) || (nameChecker && isError)}
                helperText={
                  name.length < 1 && isError
                    ? 'This field is required'
                    : nameChecker && isError
                    ? 'Name is already exist'
                    : ''
                }
              />
            </FormChildInput>
          </FormChildContainer>
          <Divider className={classes.divider} />
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>2.</TitleNumber>
              <TitleName>Search Location *</TitleName>
            </FormChildTitle>
            <FormChildInput>
              {isMapLoaded && (
                <PlacesAutocomplete
                  value={address || ''}
                  onChange={handleChange}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        {...getInputProps({
                          placeholder: 'Search Places ...',
                          className: 'location-search-input',
                        })}
                        InputProps={{
                          classes: {
                            input: classes.outlinedInput,
                          },
                        }}
                        fullWidth
                        error={address.length < 2 && isError}
                        helperText={
                          address.length < 2 && isError
                            ? 'This field is required'
                            : ''
                        }
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, key) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item'
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                            : {backgroundColor: '#ffffff', cursor: 'pointer'}
                          return (
                            <div
                              key={key}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <MenuItem>{suggestion.description}</MenuItem>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
              <div
                style={{
                  height: 510,
                  width: '45vw',

                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyCXay4misQ7TO0SRlEQVhYF2Ij2AfrexaQ',
                    libraries: 'places',
                  }}
                  // defaultCenter={{lat: latitude | -6.917464, lng: longitude | 107.619125}}
                  center={{
                    lat: latitude,
                    lng: longitude,
                  }}
                  defaultZoom={16}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({maps}) => handleApiLoaded(maps)}
                  onClick={e => handleClickMap(e)}
                >
                  {/* <Typography
                    lat={latitude | -6.917464}
                    lng={longitude | 107.619125}
                  >
                    Marker
                  </Typography> */}
                  <MapMarker
                    style={{color: '#014a62'}}
                    lat={latitude}
                    lng={longitude}
                    title={
                      <React.Fragment>
                        address
                        <span />
                      </React.Fragment>
                    }
                  />
                </GoogleMapReact>
              </div>
            </FormChildInput>
          </FormChildContainer>
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>3.</TitleNumber>
              <TitleName>Location Address</TitleName>
            </FormChildTitle>
            <FormChildInput>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                margin="normal"
                variant="outlined"
                value={addressPlaceholder || ''}
                onChange={event => setAddressPlaceholder(event.target.value)}
                fullWidth
                placeholder="Location Address"
              />
            </FormChildInput>
          </FormChildContainer>
          <Divider className={classes.divider} />
          <FormChildContainer>
            <FormChildTitle>
              <TitleNumber>4.</TitleNumber>
              <TitleName>Radius (Meter) *</TitleName>
            </FormChildTitle>
            <FormChildInput style={{width: '31vw', marginTop: 10}}>
              <Typography style={{color: '#a9a8a8'}}>
                Radius for making an access in the WLB application
              </Typography>
            </FormChildInput>

            <FormChildInput
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <TextField
                margin="normal"
                variant="outlined"
                value={radius}
                onChange={event => handleRadius(event.target.value)}
                type="number"
                InputProps={{
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                error={radius <= 0 && isError}
                helperText={
                  radius <= 0 && isError ? 'This field is required' : ''
                }
              />
              <Typography style={{marginLeft: 11, color: '#a9a8a8'}}>
                meter
              </Typography>
            </FormChildInput>
          </FormChildContainer>
        </FormContainer>
        <Divider></Divider>
        <FormChildButton>
          <Button
            color="danger"
            size="large"
            className={classes.buttonSpacer}
            // component={Link}
            // to={{
            //   pathname: '/time/attendance/location_restrictions',
            //   state: {active: 'location-restrictions'},
            // }}
            onClick={() => handleClickOpen('discard')}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickOpen('add')}
            // onClick={updateLocation}
            size="large"
          >
            Save
          </Button>
        </FormChildButton>

        {/* <TextField
          margin="normal"
          variant="outlined"
          value={addressPlaceholder || ''}
          onChange={event => setAddressPlaceholder(event.target.value)}
        /> */}
      </Paper>
      <AddEdit
        open={openPopup}
        handleClose={handleClose}
        type={confirmType}
        name={name}
        feature="Location Restriction"
        mutation={confirmType === 'discard' ? history : updateLocation}
      />
    </div>
  )
}

export default withRouter(LocationRestrictionAdd)
