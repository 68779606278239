import React, {useState} from 'react'
import ModalDetail from '../../../../../shared-components/popup/ModalDetail'
import {WrapperSection} from '../../DetailEmployeeStyles'
import {LicensesItem} from './LicensesItem'
import AddEditLicenses from './AddEditLicenses'
import {ButtonAdd} from '../../SharedDetailEmployee'

export default function Licenses({
  people_profile_certificates,
  user_name,
  refetch,
  setAction,
  enqueueSnackbar,
  userIdDetail,
  action,
  INITIAL_ACTION_STATE,
}) {
  const [open, setOpen] = useState(false)

  const _renderItem = (data, isDetail = false) => {
    return data.slice(0, isDetail ? data.length : 2).map((res, i) => {
      return (
        <LicensesItem
          key={`${i}`}
          res={res}
          onEdit={() =>
            handleOpenForm({
              actionType: 'edit',
              data: res,
              isFromModal: action.isFromModal,
            })
          }
        />
      )
    })
  }

  const handleCloseAction = () => {
    if (action.isFromModal) {
      setOpen(true)
    }
    setAction(INITIAL_ACTION_STATE)
  }
  const handleOpenForm = ({actionType, data = null, isFromModal}) => {
    if (isFromModal) {
      setOpen(false)
    }
    setAction(e => ({
      ...e,
      open: true,
      actionType,
      data,
      isFromModal,
    }))
  }

  return (
    <>
      <WrapperSection>
        {_renderItem(people_profile_certificates)}
        {people_profile_certificates.length > 0 && (
          <h3 onClick={() => setOpen(true)} className="show-more">
            Tampilkan Lebih Banyak Pelatihan, Lisensi & Sertifikasi...
          </h3>
        )}
      </WrapperSection>

      <ModalDetail
        onClose={() => setOpen(false)}
        open={open}
        title={`Pelatihan, Lisensi & Sertifikasi ${user_name}`}
      >
        {_renderItem(people_profile_certificates, true)}
        <ButtonAdd
          label="Pelatihan, Lisensi & Sertifikasi"
          onClick={() =>
            handleOpenForm({
              actionType: 'add',
              isFromModal: true,
            })
          }
        />
      </ModalDetail>

      <AddEditLicenses
        setAction={setAction}
        refetch={refetch}
        enqueueSnackbar={enqueueSnackbar}
        userIdDetail={userIdDetail}
        open={action.open}
        data={action.data}
        onClose={handleCloseAction}
        actionType={action.actionType}
      />
    </>
  )
}
