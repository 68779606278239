import MaterialTable from 'material-table'
import React from 'react'
import {Flex, BlueLink, CustomTypography} from '../../../../GlobalStyles'
import {withRouter} from 'react-router-dom'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import {competencyOptions} from '../CompetencyPageStyles'

import NoDataListComponent from '../../../shared-components/NoDataListComponent'

const ListDetailCompetence = props => {
  const id = props?.match?.params?.id
  const {search, data} = props

  if (data.type === 'nonlevel') {
    return (
      <div style={{minHeight: 500, padding: '1.6rem'}}>
        <CustomTypography>
          Competencies with non level types do not have a list of levels
        </CustomTypography>
      </div>
    )
  } else {
    return (
      <MaterialTable
        style={{
          padding: '0 1.6rem',
        }}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
        columns={[
          {
            title: 'Level',
            field: 'level',
            render: row => (
              <Flex
                style={{
                  width: 100,
                }}
              >
                Level {row.number}
              </Flex>
            ),
          },
          {
            title: 'Keterangan',
            field: 'description',
            render: row => (
              <Flex
                style={{
                  width: 500,
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 5,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: row.description || '',
                  }}
                />
              </Flex>
            ),
          },
          {
            title: 'Aksi',
            field: 'id',
            sorting: false,
            render: rowData => (
              <BlueLink
                to={{
                  pathname: `/learning/competency/list-of-competency/${id}/edit`,
                  state: {
                    data: data,
                    dataEdit: rowData,
                    active: 'list-of-competency',
                  },
                }}
              >
                Ubah
              </BlueLink>
            ),
          },
        ]}
        data={data?.description?.levels}
        title=""
        options={{
          ...TableOptions,
          ...competencyOptions,
        }}
        components={{
          Toolbar: () => null,
        }}
      />
    )
  }
}

export default withRouter(ListDetailCompetence)
