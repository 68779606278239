import React, {useState, useEffect} from 'react'
import helperStyle, {
  PaperContainer,
  AddEditTitle,
  FormToolbar,
  FormTitleContainer,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
} from '../../../../GlobalStyles'
import {
  FlexBetween,
  WrapperContent,
  TextBlackBold,
  StyledTree,
} from '../../../organization/OrganizationPageStyles'
import {IconButton, CircularProgress, Divider, Button} from '@material-ui/core'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Link} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {useSnackbar} from 'notistack'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import AlertDialog from '../../../shared-components/popup/ResultPopup'

import {useQuery, useMutation} from '@apollo/react-hooks'
import {GET_DETAIL_MAIN_BY_ID} from '../../../../graphql/queries'
import {DELETE_CASCADING_KPI_LIST} from '../../../../graphql/mutations'
import {trimString} from '../../../../utils/helpers'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'

const DetailCascadingTree = ({dataCascading}) => {
  const classes = helperStyle()
  const {enqueueSnackbar} = useSnackbar()
  const [openDelete, setOpenDelete] = useState(false)
  const [openCantDelete, setOpenCantDelete] = useState(false)
  const [treeData, setTreeData] = useState([])
  const [state, setState] = useState({
    id: '',
    name: '',
  })

  const handleClickOpen = (id, name, child) => {
    setState({id, name})
    if (child?.length === 0 || typeof child?.length === 'undefined') {
      setOpenDelete(true)
    } else {
      setOpenCantDelete(true)
    }
  }
  const handleClose = () => {
    setOpenDelete(false)
  }

  const {data, loading, error, refetch} = useQuery(
    GET_DETAIL_MAIN_BY_ID(dataCascading.id)
  )
  const [deleteCascadingKpiList] = useMutation(
    DELETE_CASCADING_KPI_LIST(state.id)
  )

  // const removeFromTree = (root, id) => {
  //   for (var i = 0; i < root.length; i++) {
  //     if (root[i].id === id) {
  //       root.splice(i, 1)
  //       break
  //     }
  //   }

  //   return root
  // }

  const onDeleteCascadingKpiList = () => {
    // const newData = removeFromTree(treeData, state.id)
    deleteCascadingKpiList()
      .then(() => {
        refetch()
        setTreeData([])
        enqueueSnackbar('Cascading Goal deleted', {
          variant: 'success',
          autoHideDuration: 500,
        })
      })
      .catch(() => {
        enqueueSnackbar(
          'Delete Cascading Goal failed, please try again later',
          {
            variant: 'error',
            autoHideDuration: 500,
          }
        )
      })
  }

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 2000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (data && treeData.length === 0) {
        const topParent =
          data &&
          data.kpi_cascading_lists.filter(val => {
            return val.kpi_parent === null
          })

        if (topParent[0] === undefined) {
          setTreeData([...data.kpi_cascading_lists])
        } else {
          var arrayToTree = require('array-to-tree')
          var magicTree = arrayToTree(data.kpi_cascading_lists, {
            parentProperty: 'kpi_parent',
            customID: 'id_kpi',
          })
          setTreeData([...magicTree])
        }
      }
    }, 3000)
  }, [data, treeData])

  if (loading) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <PaperContainer className={classes.notViewScroll}>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link
            to={{
              pathname: '/performance/kpi/cascading',
              state: {active: 'cascading', searchable: true},
            }}
          >
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6" display="inline">
            {`${dataCascading && dataCascading.name} Rincian`}
          </AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={{
              pathname: '/performance/kpi/cascading/add-detail',
              state: {
                active: 'cascading-detail-add',
                idCascading: dataCascading,
              },
            }}
            size="large"
          >
            Tambah {GLOBAL_GLOSSARY.performance.Goal}
          </Button>
        </div>
      </FormToolbar>
      <Divider></Divider>
      <StyledTree
        className={classes.notViewScrollTree}
        canDrag={false}
        treeData={treeData}
        onChange={data => {
          setTreeData(data)
        }}
        generateNodeProps={({node}) => ({
          title: (
            <FlexBetween>
              <TextBlackBold>
                {node.kpi_main
                  ? `${node?.kpi_main?.code} - ${trimString(
                      node?.kpi_main?.name ?? '',
                      50
                    )}${node?.kpi_main?.name?.length > 50 ? '...' : ''}`
                  : node.title}
              </TextBlackBold>
              <WrapperContent>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <>
                      <MoreIcon {...bindTrigger(popupState)}>
                        more_horiz
                      </MoreIcon>

                      <StyledMenus {...bindMenu(popupState)}>
                        <StyledMenuItem
                          component={Link}
                          to={{
                            pathname:
                              '/performance/kpi/cascading/add-child-detail',
                            state: {
                              active: 'cascading-detail-add-child',
                              idCascading: dataCascading,
                              dataParent: node,
                              addChild: true,
                            },
                          }}
                          variant="h6"
                        >
                          Tambahkan Anak
                        </StyledMenuItem>
                        <StyledMenuItem
                          component={Link}
                          to={{
                            pathname: '/performance/kpi/cascading/edit-detail',
                            state: {
                              active: 'cascading-detail-edit',
                              idCascading: dataCascading,
                              dataDetailCascading: node,
                            },
                          }}
                          variant="h6"
                        >
                          Ubah
                        </StyledMenuItem>
                        <StyledMenuItem
                          onClick={() => {
                            handleClickOpen(
                              node.id,
                              node.kpi_main && node.kpi_main.name,
                              node.children
                            )
                            popupState.close()
                          }}
                        >
                          Hapus
                        </StyledMenuItem>
                      </StyledMenus>
                    </>
                  )}
                </PopupState>
              </WrapperContent>
            </FlexBetween>
          ),
        })}
      />
      <DeletePopup
        open={openDelete}
        handleClose={handleClose}
        id={state.id}
        name={state.name}
        mutation={() => onDeleteCascadingKpiList()}
      />
      <AlertDialog
        open={openCantDelete}
        handleClose={() => setOpenCantDelete(false)}
        feature={`Unable to delete`}
        name={state.name}
        fMessage="Unable to delete "
        lMessage=". Please delete or move underlying goal library to another existing goal library before proceeding with this deletion. "
        type="Confirm"
      />
    </PaperContainer>
  )
}

export default DetailCascadingTree
