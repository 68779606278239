import gql from 'graphql-tag'

export const GET_TYPE_LIST = gql`
  query {
    global_file_types(order_by: [{name: asc}]) {
      id
      name
    }
  }
`
