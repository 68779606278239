import React, { useState } from 'react'
import {
  Chip,
  Icon,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { selectProps } from '../../../../../utils/helpers'
import { useQuery } from '@apollo/react-hooks'

import Axios from 'axios'
import { TOKEN, UPLOAD_URL } from '../../../../../utils/globals'
import {
  GET_GLOBAL_LANGUAGES,
  GET_SUB_AND_CATEGORIES,
} from '../../../../../graphql/queries'
import helperStyle from '../../../../../GlobalStyles'
import { useSnackbar } from 'notistack'
import classNames from 'classnames'
import { level_skill } from '../../../../shared-components/data/SharedData'

export default function ManageLanding({
  isError,
  style,
  value,
  handleChange,
  setValue,
  isSlugExist,
  courseId,
}) {
  const classes = helperStyle()
  const { enqueueSnackbar } = useSnackbar()
  const [addTopic, setAddTopic] = useState(false)
  const [tempTopic, setTempTopic] = useState('')
  const [loadingUpload, setLoading] = useState(false)
  const [loadingUploadVideo, setLoadingVideo] = useState(false)

  const { data: dataLanguages } = useQuery(GET_GLOBAL_LANGUAGES)
  const { data: dataCategories } = useQuery(GET_SUB_AND_CATEGORIES)

  const handleTopic = () => {
    setAddTopic(!addTopic)
    setTempTopic('')
  }

  const onSubmitTopic = () => {
    const newTopic = value.topic
    newTopic.push(tempTopic)
    setValue({ ...value, topic: newTopic })
    handleTopic()
  }

  const handleDeleteTopic = top => {
    const newTopic = value.topic.filter(t => t !== top)
    setValue({ ...value, topic: newTopic })
  }

  const handleChangeFile = async e => {
    const tempFile = Array.from(e.target.files)
    const val = tempFile[0]

    if (val.size > 1000 * 1000 * 25) {
      enqueueSnackbar("File size can't be more than 25MB", {
        autoHideDuration: 2000,
        variant: 'error',
      })
    } else if (
      val.type !== 'image/jpeg' &&
      val.type !== 'image/jpg' &&
      val.type !== 'image/png'
    ) {
      enqueueSnackbar('File other than images are not allowed', {
        autoHideDuration: 2000,
        variant: 'error',
      })
    } else {
      const formData = new FormData()
      formData.append('file', val)
      try {
        const response = await Axios.post(UPLOAD_URL, formData, {
          withCredentials: true,
          onUploadProgress: () => {
            // const loads = Math.round(
            //   (progressEvent.loaded / progressEvent.total) * 100
            // )
            setLoading(true)
          },

          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data',
          },
        })
        setValue({ ...value, banner_url: response.data.url })
        setLoading(false)
      } catch (e) {
        return e
      }
    }
  }

  const uploadVideoPreview = e => {
    const tempFile = e.target.files[0]

    setLoadingVideo(true)

    Axios.post(
      UPLOAD_URL + '/video',
      {
        title: value.name
          ? `${value.name} Promotional Course Video`
          : 'Promotional Course Video Title',
        description:
          value.description || 'Promotional Course Video Description',
        format: tempFile.type,
      },
      {
        // cancelToken: Axios.CancelToken.source(),
        headers: {
          Authorization: 'Bearer ' + TOKEN,
        },
        withCredentials: true
      }
    )
      .then(response => {
        /* this.setState({
          videoManifestAWSAccessKeyId: response.data.link.query.AWSAccessKeyId,
          videoManifestKey: response.data.media.key,
          videoManifestExpires: response.data.link.query.Expires,
          videoManifestSignature: response.data.link.query.Signature,
        }) */

        const url = `${response.data.link.protocol}://${response.data.link.address}${response.data.link.path}`

        Axios.put(url, tempFile, {
          params: {
            AWSAccessKeyId: response.data.link.query.AWSAccessKeyId,
            Expires: response.data.link.query.Expires,
            Signature: response.data.link.query.Signature,
          },
          headers: {
            'Content-Type': tempFile.type,
          },
        })
          .then(res => {
            if (res.status === 200) {
              setValue({
                ...value,
                promotional_url: response.data.media.key,
              })
              // getMediaObject(response.data.media.key)
            } else {
              return enqueueSnackbar(response.statusText, {
                autoHideDuration: 2000,
                variant: 'error',
              })
            }
          })
          .finally(() => {
            setLoadingVideo(false)
          })
      })
      .catch(error => {
        setLoadingVideo(false)

        return enqueueSnackbar(JSON.stringify(error), {
          autoHideDuration: 2000,
          variant: 'error',
        })
      })
  }

  const handleChangePromotionalFile = async e => {
    const tempFile = Array.from(e.target.files)
    const val = tempFile[0]

    if (val.size > 1000 * 1000 * 2000) {
      enqueueSnackbar("File size can't be more than 2GB", {
        autoHideDuration: 2000,
        variant: 'error',
      })
    } else if (val.type !== 'video/mp4' && val.type !== 'video/quicktime') {
      enqueueSnackbar('File other than videos are not allowed', {
        autoHideDuration: 2000,
        variant: 'error',
      })
    } else {
      uploadVideoPreview(e)
    }
  }

  return (
    <>
      <div className={classes.rowWithoutBetween}>
        <Typography variant="body2">1. </Typography>
        <div
          className={classNames(
            classes.marginBot20,
            classes.marginLeft28,
            classes.widthFull
          )}
        >
          <Typography variant="body2">Judul Kursus*</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            placeholder="Tambah Judul Kursus"
            fullWidth
            value={value.name}
            onChange={handleChange('name')}
            error={isError && !value.name}
            helperText={isError && !value.name && 'Bagian ini diperlukan'}
          />
        </div>
      </div>
      <div className={classes.rowWithoutBetween}>
        <Typography variant="body2">2. </Typography>
        <div
          className={classNames(
            classes.marginBot20,
            classes.marginLeft28,
            classes.widthFull
          )}
        >
          <Typography variant="body2">Subjudul Kursus</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            placeholder="Tambah Subjudul Kursus"
            fullWidth
            value={value.subtitle}
            onChange={handleChange('subtitle')}
          />
        </div>
      </div>
      <div className={classes.rowWithoutBetween}>
        <Typography variant="body2">3. </Typography>
        <div
          className={classNames(
            classes.marginBot20,
            classes.marginLeft28,
            classes.widthFull
          )}
        >
          <Typography variant="body2">Penanda Kursus*</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            placeholder="Tambah Penanda Kursus"
            fullWidth
            value={value.slug}
            onChange={handleChange('slug')}
            error={isError && !value.slug}
          />
          <Typography
            variant="body2"
            style={{
              color: isSlugExist || !value.slug ? 'red' : '#4CAF50',
            }}
            className={classes.marginTop6}
          >
            {/* {isSlugExist
              ? 'Slug already exist'
              : (isError && !value.slug) || !value.slug
              ? 'Bagian ini diperlukan'
              : 'Slug available'} */}
            {isSlugExist && !courseId && 'Slug already exist'}
          </Typography>
        </div>
      </div>
      <div className={classes.rowWithoutBetween}>
        <Typography variant="body2">4. </Typography>
        <div
          className={classNames(
            classes.marginBot20,
            classes.marginLeft28,
            classes.widthFull
          )}
        >
          <Typography variant="body2">Keterangan</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            placeholder="Tambah Keterangan Kursus"
            fullWidth
            multiline
            rows="5"
            value={value.description}
            onChange={handleChange('description')}
          />
        </div>
      </div>
      <div className={classes.rowWithoutBetween}>
        <Typography variant="body2">5. </Typography>
        <div
          className={`${classes.marginBot20} ${classes.width30P} ${classes.marginLeft28}`}
        >
          <Typography variant="body2">Bahasa*</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            select
            value={value.language_id}
            onChange={handleChange('language_id')}
            SelectProps={selectProps(value.language_id)}
            fullWidth
            error={isError && !value.language_id}
            helperText={
              isError && !value.language_id && 'Bagian ini diperlukan'
            }
          >
            <MenuItem value={''} disabled>
              Pilih Bahasa
            </MenuItem>
            {dataLanguages &&
              dataLanguages.global_languages.map(d => (
                <MenuItem key={d.id} value={d.id}>
                  {d.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </div>
      <div className={classes.rowWithoutBetween}>
        <Typography variant="body2">6. </Typography>
        <div
          className={`${classes.marginBot20} ${classes.width30P} ${classes.marginLeft28}`}
        >
          <Typography variant="body2">Level*</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            select
            fullWidth
            value={value.level}
            onChange={handleChange('level')}
            SelectProps={selectProps(value.level)}
            error={isError && !value.level}
            helperText={isError && !value.level && 'Bagian ini diperlukan'}
          >
            <MenuItem value={''} disabled>
              Pilih Level
            </MenuItem>
            <MenuItem value={1}>{level_skill.beginner}</MenuItem>
            <MenuItem value={2}>{level_skill.intermediate}</MenuItem>
            <MenuItem value={3}>{level_skill.advanced}</MenuItem>
          </TextField>
        </div>
      </div>
      {courseId ? (
        <>
          <div className={classes.rowWithoutBetween}>
            <Typography variant="body2">7. </Typography>
            <div
              className={`${classes.marginBot20} ${classes.width30P} ${classes.marginLeft28}`}
            >
              <Typography variant="body2">Kategori*</Typography>
              {(function () {
                let _categoryId
                const _subCategories = []

                if (value.subcategory && dataCategories) {
                  dataCategories.academy_course_categories.map(cat => {
                    cat.subcategory.map(cat2 => {
                      _subCategories.push(cat2)
                    })
                  })

                  _categoryId =
                    dataCategories.academy_course_categories &&
                    _subCategories.find(
                      subCat => subCat.id === value.subcategory
                    ) &&
                    _subCategories.find(
                      subCat => subCat.id === value.subcategory
                    ).parent

                  if (_categoryId && !value.category_id) {
                    setValue({
                      ...value,
                      category_id: _categoryId,
                    })
                  }
                }
              })(this)}
              <TextField
                variant="outlined"
                margin="normal"
                select
                fullWidth
                value={value.category_id}
                onChange={handleChange('category_id')}
                SelectProps={selectProps(value.category_id)}
                error={isError && !value.category_id}
                helperText={
                  isError && !value.category_id && 'Bagian ini diperlukan'
                }
              >
                <MenuItem value={''} disabled>
                  Pilih Kategori
                </MenuItem>
                {dataCategories &&
                  dataCategories.academy_course_categories.map(d => (
                    <MenuItem value={d.id} key={d.id}>
                      {d.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
          </div>

          <div className={classes.rowWithoutBetween}>
            <Typography variant="body2">8. </Typography>
            <div
              className={`${classes.marginBot20} ${classes.width30P} ${classes.marginLeft28}`}
            >
              <Typography variant="body2">Sub Kategori*</Typography>
              <TextField
                variant="outlined"
                margin="normal"
                select
                fullWidth
                value={value.subcategory}
                onChange={handleChange('subcategory')}
                SelectProps={selectProps(value.subcategory)}
                error={isError && !value.subcategory}
                helperText={
                  isError && !value.subcategory && 'Bagian ini diperlukan'
                }
              >
                <MenuItem value={''} disabled>
                  Pilih Sub Kategori
                </MenuItem>
                {/*  {dataCategories && 
                  value.category_id &&
                  dataCategories.academy_course_categories
                    .filter(d => d.id === value.category_id)[0]
                    .subcategory.map(d => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.name}
                      </MenuItem>
                    ))} */}
                {dataCategories &&
                  dataCategories.academy_course_categories.find(
                    c => c.id === value.category_id
                  ) &&
                  dataCategories.academy_course_categories
                    .find(c => c.id === value.category_id)
                    .subcategory.map((item, cIndex) => {
                      return (
                        <MenuItem key={cIndex} value={item.id}>
                          {item.name} Certification
                        </MenuItem>
                      )
                    })}
              </TextField>
            </div>
          </div>
        </>
      ) : (
        <>
          {' '}
          <div className={classes.rowWithoutBetween}>
            <Typography variant="body2">7. </Typography>
            <div
              className={`${classes.marginBot20} ${classes.width30P} ${classes.marginLeft28}`}
            >
              <Typography variant="body2">Kategori*</Typography>
              <TextField
                variant="outlined"
                margin="normal"
                select
                fullWidth
                value={value.category_id}
                onChange={handleChange('category_id')}
                SelectProps={selectProps(value.category_id)}
                error={isError && !value.category_id}
                helperText={
                  isError && !value.category_id && 'Bagian ini diperlukan'
                }
              >
                <MenuItem value={''} disabled>
                  Pilih Kategori
                </MenuItem>
                {dataCategories &&
                  dataCategories.academy_course_categories.map(d => (
                    <MenuItem value={d.id} key={d.id}>
                      {d.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
          </div>
          <div className={classes.rowWithoutBetween}>
            <Typography variant="body2">8. </Typography>
            <div
              className={`${classes.marginBot20} ${classes.width30P} ${classes.marginLeft28}`}
            >
              <Typography variant="body2">Sub Kategori*</Typography>
              <TextField
                variant="outlined"
                margin="normal"
                select
                fullWidth
                value={value.subcategory}
                onChange={handleChange('subcategory')}
                SelectProps={selectProps(value.subcategory)}
                error={isError && !value.subcategory}
                helperText={
                  isError && !value.subcategory && 'Bagian ini diperlukan'
                }
              >
                <MenuItem value={''} disabled>
                  Pilih Sub Kategori
                </MenuItem>
                {dataCategories &&
                  value.category_id &&
                  dataCategories.academy_course_categories
                    .filter(d => d.id === value.category_id)[0]
                    .subcategory.map(d => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.name}
                      </MenuItem>
                    ))}
              </TextField>
            </div>
          </div>
        </>
      )}

      <div className={classes.rowWithoutBetween}>
        <Typography variant="body2">9. </Typography>
        <div className={classNames(classes.marginBot28, classes.marginLeft28)}>
          <Typography variant="body2">Topik*</Typography>
          <div style={style.topicContainer}>
            {value.topic &&
              value.topic.map((top, i) => {
                return (
                  <Chip
                    key={i}
                    label={top}
                    style={style.chipSkyBlue}
                    color="secondary"
                    onDelete={() => handleDeleteTopic(top)}
                  />
                )
              })}
            {addTopic ? (
              <>
                <TextField
                  variant="outlined"
                  placeholder="Tambah Topik"
                  margin="normal"
                  value={tempTopic}
                  onChange={e => setTempTopic(e.target.value)}
                ></TextField>
                <IconButton
                  style={tempTopic ? style.iconSkyBlue : style.iconDisabled}
                  size="small"
                  onClick={onSubmitTopic}
                  className={classes.mx16}
                  disabled={!tempTopic}
                >
                  <Icon>check</Icon>
                </IconButton>
                <IconButton
                  style={style.iconRed}
                  size="small"
                  onClick={handleTopic}
                >
                  <Icon>close</Icon>
                </IconButton>
              </>
            ) : (
              <IconButton
                style={style.iconSkyBlue}
                size="small"
                onClick={handleTopic}
              >
                <Icon>add</Icon>
              </IconButton>
            )}
          </div>
          {isError && value.topic.length === 0 && (
            <Typography
              variant="caption"
              style={{
                color: 'red',
              }}
              className={classes.marginTop10}
            >
              Bagian ini diperlukan
            </Typography>
          )}
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.rowWithoutBetween}>
          <Typography variant="body2">10. </Typography>
          <div
            className={classNames(classes.marginBot20, classes.marginLeft20)}
          >
            <Typography variant="body2" className="mb-16">
              Gambar Kursus
            </Typography>
            <Typography variant="body2" style={style.grey} gutterBottom>
              750x422 pixels
            </Typography>
            <Typography variant="body2" style={style.grey} gutterBottom>
              Tipe berkas: jpg, jpeg, png
            </Typography>
            <Typography variant="body2" style={style.grey} gutterBottom>
              Ukuran Maks.: 25 MB
            </Typography>
            <Typography
              variant="body2"
              style={style.grey}
              className={classes.hide}
              gutterBottom
            >
              Course Cover Image
            </Typography>
            {value.banner_url ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: 40,
                }}
              >
                <img
                  src={value.banner_url}
                  alt="attch"
                  style={style.uploadedImg}
                />
                <IconButton
                  onClick={() => setValue({ ...value, banner_url: '' })}
                >
                  <Icon>close</Icon>
                </IconButton>
              </div>
            ) : (
              <div style={style.addContainer}>
                <Icon
                  style={{
                    ...style.grey,
                    fontSize: 40,
                    marginBottomn: 11,
                  }}
                >
                  add
                </Icon>
                <Typography variant="body2" style={style.grey} gutterBottom>
                  Tambah gambar kursus
                </Typography>
                <Typography variant="body2" style={style.grey} gutterBottom>
                  Tarik berkas di sini
                </Typography>
                <input
                  id="uploadImage"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  // className="hidden"
                  style={style.input}
                  onChange={handleChangeFile}
                />
              </div>
            )}
            {loadingUpload && (
              <div style={{ width: 400, textAlign: 'center' }}>
                <LinearProgress
                  variant="indeterminate"
                  className={`${classes.marginTop16} ${classes.marginBot8}`}
                />
                <Typography variant="body2">Uploading image ...</Typography>
              </div>
            )}
          </div>
        </div>
        <div className={classes.rowWithoutBetween}>
          <Typography variant="body2">11. </Typography>
          <div
            className={classNames(classes.marginBot20, classes.marginLeft20)}
          >
            <Typography variant="body2" className="mb-16">
              Video Promosi
            </Typography>
            <Typography variant="body2" style={style.grey} gutterBottom>
              Resolusi min : 1289 x 720 (HD,720p)
            </Typography>
            <Typography variant="body2" style={style.grey} gutterBottom>
              Resolusi maks : 1920 x 1080 (FHD, 1080p)
            </Typography>
            <Typography variant="body2" style={style.grey} gutterBottom>
              Tipe berkas : Mp4
            </Typography>
            <Typography variant="body2" style={style.grey} gutterBottom>
              Ukuran Maks : 2 GB
            </Typography>
            {value.promotional_url ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 40,
                }}
              >
                <Typography variant="body2" style={style.grey} gutterBottom>
                  Promotional Video uploaded.
                </Typography>
                <IconButton
                  onClick={() => setValue({ ...value, promotional_url: '' })}
                >
                  <Icon>close</Icon>
                </IconButton>
              </div>
            ) : (
              <div style={style.addContainer}>
                <Icon
                  style={{
                    ...style.grey,
                    fontSize: 40,
                    marginBottomn: 11,
                  }}
                >
                  add
                </Icon>
                <Typography variant="body2" style={style.grey} gutterBottom>
                  Tambah video promosi
                </Typography>
                <Typography variant="body2" style={style.grey} gutterBottom>
                  Tarik berkas di sini
                </Typography>
                <input
                  id="uploadVideo"
                  type="file"
                  accept="video/mp4, video/quicktime"
                  style={style.input}
                  onChange={handleChangePromotionalFile}
                />
              </div>
            )}
            {loadingUploadVideo && (
              <div style={{ width: 400, textAlign: 'center' }}>
                <LinearProgress
                  variant="indeterminate"
                  className={`${classes.marginTop16} ${classes.marginBot8}`}
                />
                <Typography variant="body2">Uploading video ...</Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
