import React from 'react'
import {
  Dialog,
  Typography,
  IconButton,
  Icon,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core'
import {WhiteModalHeader} from '../../../GlobalStyles'

const useStyles = makeStyles(() => ({
  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {
    wordBreak: 'break-all',
    color: '#014a62',
    textTransform: 'capitalize',
  },
  btnPopup: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  textBlue: {
    color: '#039be5',
  },
}))

const ModalUnableDelete = props => {
  const {open, onClose, title, name, because} = props
  const classes = useStyles()

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <WhiteModalHeader>
        <Typography variant="h6" color="error" className={classes.wordBreak}>
          Unable to Delete {title}
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        You cannot delete <span className={classes.textBlue}>{name}</span>{' '}
        because {because}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          className={classes.btnPopup}
          variant="contained"
          onClick={onClose}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalUnableDelete
