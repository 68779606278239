import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import ListComponent from './list/ListComponent'
import CompanySummaryDetail from './detail/CompanySummaryDetail'
import {ButtonFilter, BlueIcon, FilterText} from '../../../../src/GlobalStyles'
import {Filter} from '@worklifebeyond/wlb-utils-components'
import {
  anchorOrigin,
  listFilter,
  transformOrigin,
} from './CompanySummaryFilterData'
import {CompanySummaryContainer} from './CompanySummaryPageStyle'

const renderComponent = (activeComponent, state, searchText, filterData) => {
  switch (activeComponent) {
    case 'company-summary':
      return (
        <ListComponent
          state={state}
          searchText={searchText}
          filterData={filterData}
        />
      )
    case 'company-summary-detail':
      return <CompanySummaryDetail />
    default:
      return <ListComponent searchText={searchText} filterData={filterData} />
  }
}

const CompanySummaryPage = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  const [searchText, setSearchText] = useState('')
  const [filterData, setFilterData] = useState({})

  const onSearch = search => {
    setSearchText(search)
  }

  const handleApply = res => {
    const generateIdFilter = data => {
      const _data = {}
      for (const property in data) {
        if (Array.isArray(data[property])) {
          _data[property] = data[property][0]
            ? data[property].map(itm => itm.value)
            : undefined
        }
      }
      return _data
    }
    const include = generateIdFilter(res[0])
    const exclude = generateIdFilter(res[1])
    setFilterData({
      include,
      exclude,
    })
  }

  return (
    <Card
      icon="bar_chart"
      title="Performance Company Summary"
      onSearch={onSearch}
      longTitle
      endAdornment={
        <Filter
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          id="filter-goals-wlb"
          onApply={handleApply}
          listFilter={listFilter}
        >
          <ButtonFilter position="end">
            <BlueIcon>filter_list</BlueIcon>
            <FilterText variant="body2">Filter</FilterText>
          </ButtonFilter>
        </Filter>
      }
    >
      <CompanySummaryContainer>
        {renderComponent(active, state, searchText, filterData)}
      </CompanySummaryContainer>
    </Card>
  )
}

export default CompanySummaryPage
