import {LinearProgress, makeStyles} from '@material-ui/core'
import React from 'react'
import {COUNT_ATTENDANCE} from '../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../utils/globals'
import {dateFormat} from '../../../../utils/helpers'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 50,
    margin: '19px 0px 10px 0px',
    backgroundColor: '#ef4d5e',
    borderRadius: 5,
  },
  present: {
    backgroundColor: '#039be5',
  },
  rootWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    color: '#014a62',
    margin: '0 16px 0 0',
  },
  date: {
    fontSize: 14,
    color: '#a9a8a8',
    margin: 0,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  legend: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  presentTxt: {
    margin: 0,
    color: '#039be5',
    fontWeight: '600',
  },
  absenceTxt: {
    margin: 0,
    color: '#ef4d5e',
    fontWeight: '600',
  },
  totalEmployee: {
    fontSize: 16,
    fontWeight: '600',
    color: '#039be5',
    margin: 0,
  },
})

export default function AttendanceChart() {
  const classes = useStyles()

  const {data, loading} = useQuery(COUNT_ATTENDANCE, {
    wlb_skipPatch: true,
    fetchPolicy: 'network-only',
    variables: {
      company: COMPANY_ID,
      today_start_ts: dateFormat(new Date(), 'YYYY-MM-DD') + ' 00:00:00',
      today_end_ts: dateFormat(new Date(), 'YYYY-MM-DD') + ' 23:59:59',
    },
  })

  return (
    <div className={classes.rootWrapper}>
      <div className={classes.legend}>
        <div className={classes.titleWrapper}>
          <h3 className={classes.title}>Kehadiran Pegawai Hari Ini</h3>
          <p className={classes.date}>{dateFormat(new Date(), 'll')}</p>
        </div>
        {loading && <Skeleton height="20px" width="145px" />}
        {!loading && data && (
          <p className={classes.totalEmployee}>
            {data.total_employee.aggregate.count} Jumlah Pegawai
          </p>
        )}
      </div>
      {loading && <Skeleton width="100%" height="50px" />}
      {!loading && data && (
        <LinearProgress
          classes={{
            root: classes.root,
            barColorPrimary: classes.present,
            barColorSecondary: classes.absence,
          }}
          variant="determinate"
          value={
            (data.present_today.aggregate.count /
              data.total_employee.aggregate.count) *
            100
          }
        />
      )}
      <div className={classes.legend}>
        <p className={classes.presentTxt}>Hadir</p>
        <p className={classes.absenceTxt}>Tidak Hadir</p>
      </div>
    </div>
  )
}
