import React from 'react'

import moment from 'moment'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import {FlexColumnStart} from '../../../../performance/goal/modal/modalStyles'
import {AttachmentItemRow} from '../../../../shared-components/attachment'
import DetailAttachment from '../../activity/detail/DetailAttachment'
import {
  ExchangeRateDisplay,
  TableInfo,
  useStyles,
} from '../../activity/detail/Destination'
import {ConversionDisplay} from '../../BusinessTripStyles'

import {calculateBudget} from '../../businessTripHelper'

function Destination({data, currencyFrom, currencyTo, approvedDate}) {
  const styles = useStyles()

  const [openDetail, setOpenDetail] = React.useState({
    open: false,
    data: null,
  })

  let totalCost = 0

  const tableInfo = [
    {
      label: 'Destination',
      value:
        data.globalDestinationByFrom.name +
        ' - ' +
        data.globalDestinationByTo.name,
    },
    {
      label: 'Date',
      value:
        moment(data?.destination_start_date).format('MMMM D') +
        ' - ' +
        moment(data?.destination_end_date).format('MMMM D, YYYY'),
    },
    currencyFrom !== currencyTo && {
      label: 'Exchange Rate',
      value: (
        <ExchangeRateDisplay
          // NOTE(intrnl): Yes, this is intentionally flipped
          from={currencyTo}
          to={currencyFrom}
          date={approvedDate}
        />
      ),
    },
  ]

  return (
    <FlexColumnStart>
      <TableInfo data={tableInfo} />

      <TableContainer>
        <Table>
          <TableHead style={{backgroundColor: '#eff2f4'}}>
            <TableRow>
              <TableCell className={styles.tableHeaderCell}>
                Component
              </TableCell>
              <TableCell className={styles.tableHeaderCell} align="right">
                Budget Given
              </TableCell>
              <TableCell className={styles.tableHeaderCell} align="right">
                Budget Used
              </TableCell>
              <TableCell className={styles.tableHeaderCell}>
                Description
              </TableCell>
              <TableCell className={styles.tableHeaderCell}>
                Attachment
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.budget.map(row => {
              const realized = row.realization
              totalCost += realized

              const attachments = row.attachment || []

              return (
                <TableRow key={row.id}>
                  <TableCell className={styles.tableCell}>
                    <div>{row.business_trip_component.name}</div>
                    <div style={{color: '#757575'}}>
                      {
                        row.business_trip_component.business_trip_component_type
                          ?.name
                      }
                    </div>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={currencyTo}
                      to={currencyFrom}
                      value={calculateBudget(data, row)}
                      date={approvedDate}
                      smaller
                    />
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <ConversionDisplay
                      // NOTE(intrnl): Yes, this is intentionally flipped
                      from={currencyTo}
                      to={currencyFrom}
                      value={realized}
                      date={approvedDate}
                      smaller
                    />
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {row.business_trip_component.description || '-'}
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    {attachments.length < 1 && '-'}

                    {attachments.slice(0, 3).map((file, idx) => (
                      <AttachmentItemRow
                        key={idx}
                        fileName={file.name}
                        url={file.url}
                        iconAction={
                          <VisibilityOutlinedIcon
                            className="icon"
                            onClick={() => window.open(file.url, '_blank')}
                          />
                        }
                      />
                    ))}

                    {attachments.length > 3 && (
                      <Link
                        component="button"
                        onClick={() => setOpenDetail({open: true, data: row})}
                        color="secondary"
                      >
                        ... {attachments.length - 3}+ more
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}

            <TableRow>
              <TableCell colSpan="3" className={styles.tableFooterCell}>
                Total Budget Used
              </TableCell>
              <TableCell colSpan={2} className={styles.tableFooterCell}>
                <ConversionDisplay
                  // NOTE(intrnl): Yes, this is intentionally flipped
                  from={currencyTo}
                  to={currencyFrom}
                  value={totalCost}
                  date={approvedDate}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <DetailAttachment
        open={openDetail.open}
        onCancel={() => setOpenDetail({...openDetail, open: false})}
        data={openDetail.data}
      />
    </FlexColumnStart>
  )
}

export default Destination
