import React from 'react'
import {IconButton, Button} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {Toolbar} from '../../BusinessTripPageStyles'

export default function Header(props) {
  const {onClickCancel, onClickSubmit, type} = props
  return (
    <Toolbar disableGutters>
      <div className="title-wrapper">
        {/* <Link
          to={{
            pathname: '/business/trip/settings',
            state: {active: 'settings', searchable: true},
          }}
          style={{textDecoration: 'none'}}
        > */}
        <IconButton onClick={onClickCancel} edge="end" aria-label="back">
          <ArrowBack style={{fontSize: 18}} />
        </IconButton>
        {/* </Link> */}
        <span className="title">{`${type} Component`}</span>
      </div>
      <div className="action-wrapper">
        <Button
          style={{fontSize: 12}}
          onClick={() => {
            onClickCancel()
          }}
        >
          Cancel
        </Button>
        <Button
          style={{fontSize: 12, marginLeft: 12}}
          onClick={onClickSubmit}
          size="large"
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </div>
    </Toolbar>
  )
}
