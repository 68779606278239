import {useQuery} from '@apollo/react-hooks'
import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import {AssignFilter} from '@worklifebeyond/wlb-utils-components'
import isEmpty from 'lodash/isEmpty'
import MaterialTable from 'material-table'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {
  BlueIcon,
  Flex,
  FlexBetween,
  GreyTypography,
} from '../../../../../GlobalStyles'
import {
  GET_FILTER_POSITION,
  GET_NEW_POSITION_ATTENDANCE,
} from '../../../../../graphql/queries'
import assignFiltersOld from '../../../../../utils/assignFiltersOld'
import {COMPANY_ID} from '../../../../../utils/globals'
import SelectDataPopup from '../../../../shared-components/popup/SelectDataPopup'
import {TableOptions} from '../../../../shared-components/table/TableOptions'

export default function TabAssign({
  isError,
  variables,
  setVars,
  setCount,
  policyId,
  setIsUpdatePosition,
}) {
  const [search, setSearch] = useState('')
  const [openNewPos, setOpenNewPos] = useState(false)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const [paginationPos, setPaginationPos] = useState({
    offset: 0,
    limit: 10,
  })

  const [isFilterPolicy, setIsFilterPolicy] = useState(!!policyId)

  const {data, error, loading} = useQuery(GET_FILTER_POSITION, {
    variables: {
      ...variables,
      companyId: COMPANY_ID,
      search: `%${search}%`,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      policy: policyId || null,
      isFilterPolicy,
    },
    fetchPolicy: 'no-cache',
  })

  const {data: newData} = useQuery(GET_NEW_POSITION_ATTENDANCE, {
    variables: {
      policy: policyId,
      company: COMPANY_ID,
      search: `%${search}%`,
      offset: paginationPos.offset * paginationPos.limit,
      limit: paginationPos.limit,
    },
    fetchPolicy: 'no-cache',
  })
  const selections = React.useMemo(() => {
    const include = {}
    const exclude = {}

    for (const v in variables) {
      const {_in, _nin} = variables[v]

      include[v] = _in
      exclude[v] = _nin
    }

    return {include, exclude}
  }, [variables])

  useEffect(() => {
    if (data) {
      setCount(data.totalAll.aggregate.count)
    }
  }, [data])

  const handleApplyAssign = result => {
    const {include, exclude} = result

    const _vars = {}

    for (const p in include) {
      if (include[p] && include[p].length > 0) {
        if (!_vars[p]) {
          _vars[p] = {}
        }

        _vars[p]._in = include[p]
      }
    }

    for (const p in exclude) {
      if (exclude[p] && exclude[p].length > 0) {
        if (!_vars[p]) {
          _vars[p] = {}
        }

        _vars[p]._nin = exclude[p]
      }
    }

    setPagination({...pagination, offset: 0})
    setVars(_vars)

    if (policyId) {
      setIsUpdatePosition(true)
    }
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleNewPos = () => {
    setOpenNewPos(prev => !prev)
  }

  const onSyncPosition = () => {
    setIsFilterPolicy(false)
    setIsUpdatePosition(true)
    setOpenNewPos(false)
  }

  if (error) {
    JSON.stringify(error)
  }

  return (
    <>
      <FlexBetween style={{marginBottom: 20}}>
        <div>
          <Typography variant="body2" style={{fontWeight: 600}} gutterBottom>
            {!loading &&
              (data?.totalAll.aggregate.count ===
              data?.totalPosition.aggregate.count
                ? `Applied to All Positions (${data.totalAll.aggregate.count} Positions)`
                : `Applied to ${data.totalAll.aggregate.count} Positions`)}{' '}
            {isError && data && data.total.aggregate.count === 0 && (
              <Typography variant="body2" color="error" display="inline">
                This field is required
              </Typography>
            )}
          </Typography>
          {isEmpty(variables) &&
            data?.totalAll.aggregate.count !==
              data?.totalPosition.aggregate.count && (
              <Flex>
                <GreyTypography variant="body2">
                  There are new positions added recently
                </GreyTypography>
                <Typography
                  style={{marginLeft: 8, cursor: 'pointer'}}
                  color="secondary"
                  variant="body2"
                  onClick={handleNewPos}
                >
                  View new positions
                </Typography>
              </Flex>
            )}
        </div>

        <AssignFilter
          filters={assignFiltersOld}
          limit={20}
          initialSelections={selections}
          onApply={handleApplyAssign}
        >
          <Button size="large" variant="contained" color="primary">
            Filter
          </Button>
        </AssignFilter>
      </FlexBetween>

      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        fullWidth
        value={search}
        onChange={e => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BlueIcon>search</BlueIcon>
            </InputAdornment>
          ),
        }}
        style={{marginBottom: 10}}
      />
      <MaterialTable
        columns={[
          {
            title: 'Position Code',
            field: 'code',
            render: row => (row.code ? `[${row.code}]` : '-'),
            cellStyle: {color: '#039BE5'},
          },
          {
            title: 'Position',
            field: 'title',
          },
        ]}
        options={{
          ...TableOptions,
          selection: false,
          emptyRowsWhenPaging: false,
        }}
        components={{
          Toolbar: () => null,
          Container: props => <Paper {...props} elevation={0} />,
        }}
        data={data && data.company_job_profiles}
        isLoading={loading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={data && data.total.aggregate.count}
        page={pagination.offset}
        pageSize={pagination.limit}
      />
      <SelectDataPopup
        open={openNewPos}
        onCancel={handleNewPos}
        handleConfirm={onSyncPosition}
        titleText="New Positions"
        cancelText="Cancel"
        saveText="Synchronize All"
        appliedText={`New Positions (${newData?.count.aggregate.count} Positions)`}
        search={false}
        data={newData?.company_job_profiles.map(res => ({
          id: res.id,
          value1: res.title,
          value2: `Added on ${moment(res.date_added).format('LL')}`,
        }))}
        count={newData?.count.aggregate.count}
        type="newPosition"
        pagination={paginationPos}
        setPagination={setPaginationPos}
        altText="To apply policy to these positions, you need to synchronize these positions to current position listed on policy"
      />
    </>
  )
}
