import React, {useState} from 'react'
import {Typography, TextField} from '@material-ui/core'
import {
  FlexBetween,
  BlueIcon,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
} from '../../../../../GlobalStyles'
import {dateMinENOrUS, trimString} from '../../../../../utils/helpers'
import {withRouter} from 'react-router-dom'
import {
  TableLocalization,
  TableOptions,
} from '../../../../shared-components/table/TableOptions'
import MaterialTable from 'material-table'
import NoDataListComponent from '../../../../shared-components/NoDataListComponent'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import DetailParticipant from '../../detail/DetailParticipant'
import ModalAddCommitee from './detail-component/ModalAddCommitee'

const StepFour = props => {
  const {values, candidate, employee, isError} = props
  const [openAddCommittee, setOpenAddCommittee] = useState(false)
  const [openDetailCommittee, setOpenDetailCommittee] = useState(false)
  const [search, setSearch] = useState('')
  const [dataDetailCommittee, setDataDetailCommittee] = useState(false)
  const [dataAddCommittee, setDataAddCommittee] = useState(false)

  const filteredCandidate = candidate?.filter(
    item =>
      item?.label?.toLowerCase().indexOf(search?.toLowerCase()) >= 0 ||
      item?.code?.toLowerCase().indexOf(search?.toLowerCase()) >= 0
  )

  const column = [
    {
      title: 'Nomor',
      field: 'tableData.id',
      defaultSort: 'asc',
      headerStyle: {minWidth: 50, maxWidth: 50},
      cellStyle: {minWidth: 50, maxWidth: 50},
      render: rowData => rowData.tableData.id + 1,
    },
    {
      title: 'Posisi',
      field: 'label',
      headerStyle: {minWidth: 350, maxWidth: 350},
      cellStyle: {minWidth: 350, maxWidth: 350},
      render: rowData => (
        <>
          <Typography>
            {' '}
            {`${trimString(rowData?.label, 60)} ${
              rowData?.label?.length > 60 ? '...' : ''
            }`}
          </Typography>
          <Typography style={{color: '#a9a8a8'}}>{rowData.code}</Typography>
        </>
      ),
    },
    {
      title: 'Tanggal Mulai',
      field: 'start_date',
      headerStyle: {minWidth: 150, maxWidth: 150},
      cellStyle: {minWidth: 150, maxWidth: 150},
      render: rowData => {
        const dataCandidate = [...values.talent_candidate_groups.data]
        const index = dataCandidate.findIndex(
          x => x.job_profile === rowData.value
        )
        return values?.talent_candidate_groups?.data?.[index]
          ?.committee_date_start
          ? dateMinENOrUS(
              values?.talent_candidate_groups?.data?.[index]
                ?.committee_date_start
            )
          : '-'
      },
    },
    {
      title: 'Tanggal Selesai',
      field: 'end_date',
      headerStyle: {minWidth: 150, maxWidth: 150},
      cellStyle: {minWidth: 150, maxWidth: 150},
      render: rowData => {
        const dataCandidate = [...values.talent_candidate_groups.data]
        const index = dataCandidate.findIndex(
          x => x.job_profile === rowData.value
        )
        return values?.talent_candidate_groups?.data?.[index]
          ?.committee_date_end
          ? dateMinENOrUS(
              values?.talent_candidate_groups?.data?.[index]?.committee_date_end
            )
          : '-'
      },
    },
    {
      title: 'Aksi',
      field: 'action',
      sorting: false,
      headerStyle: {minWidth: 100, maxWidth: 100},
      cellStyle: {minWidth: 100, maxWidth: 100},
      render: rowData => {
        const filterPic = values?.talent_candidate_groups?.data?.[
          rowData.tableData.id
        ]?.talent_committees?.data?.filter(v => v.is_pic)

        const dataCandidate = [...values.talent_candidate_groups.data]
        const index = dataCandidate.findIndex(
          x => x.job_profile === rowData.value
        )

        const dataEmployee = employee?.[index]?.map(v => {
          return {
            global_user: {
              name: v.value,
              avatar: v.avatar,
              label: v.label,
              people_work_placements: [
                {
                  company_job_profile: {
                    title: v.position,
                  },
                },
              ],
            },
          }
        })

        const dataCommittee = dataEmployee?.filter(
          v => v.global_user.label !== filterPic?.[0]?.committee
        )

        const dataPIC = dataEmployee?.filter(
          v => v.global_user.label === filterPic?.[0]?.committee
        )

        const dataDetail = {
          talent_candidate_groups: [
            {
              company_job_profile: {
                title: rowData?.label,
              },
              committee_date_start:
                values?.talent_candidate_groups?.data?.[index]
                  ?.committee_date_start,
              committee_date_end:
                values?.talent_candidate_groups?.data?.[index]
                  ?.committee_date_end,
              pic: dataPIC,
              committee: dataCommittee,
            },
          ],
        }

        return (
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <>
                <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>
                <StyledMenus {...bindMenu(popupState)}>
                  <StyledMenuItem
                    variant="h6"
                    onClick={() => {
                      setDataDetailCommittee(dataDetail)
                      setOpenDetailCommittee(true)
                      popupState.close()
                    }}
                  >
                    Rincian
                  </StyledMenuItem>
                  <StyledMenuItem
                    variant="h6"
                    onClick={() => {
                      setOpenAddCommittee(true)
                      setDataAddCommittee({...rowData, index: index})
                      popupState.close()
                    }}
                  >
                    Ubah
                  </StyledMenuItem>
                </StyledMenus>
              </>
            )}
          </PopupState>
        )
      },
    },
  ]

  return (
    <>
      <FlexBetween style={{marginBottom: 20}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="body1">
            Silahkan ubah posisi dibawah untuk mengkonfigurasi komite
          </Typography>
          {isError && (
            <Typography variant="caption" style={{color: 'red', marginLeft: 6}}>
              Please fill all fields and Please check start date, end date
            </Typography>
          )}
        </div>
        <TextField
          placeholder="Cari"
          variant="outlined"
          InputProps={{
            endAdornment: <BlueIcon position="end">search</BlueIcon>,
          }}
          value={search}
          size="small"
          onChange={e => setSearch(e.target.value)}
        />
      </FlexBetween>
      <MaterialTable
        options={{
          ...TableOptions,
          selection: false,
          showTitle: false,
          toolbar: false,
          emptyRowsWhenPaging: false,
        }}
        localization={{
          pagination: {...TableLocalization.pagination},
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
        columns={column}
        data={filteredCandidate}
      />

      <DetailParticipant
        handleClose={() => setOpenDetailCommittee(false)}
        open={openDetailCommittee}
        dataDetail={dataDetailCommittee}
      />
      <ModalAddCommitee
        handleClose={() => setOpenAddCommittee(false)}
        open={openAddCommittee}
        dataAddCommittee={dataAddCommittee}
        setDataAddCommittee={setDataAddCommittee}
        {...props}
      />
    </>
  )
}

export default withRouter(StepFour)
