import React, {useState, useEffect} from 'react'
import ProfileSection from '../../../shared-components/company-profile/ProfileSectionComponent'
import DescriptionSection from '../../../shared-components/company-profile/DescriptionSectionComponent'
import DetailSection from '../../../shared-components/company-profile/DetailSectionComponent'
import OfficeLocationSection from '../../../shared-components/company-profile/OfficeLocationSectionComponent'
// import HoldingSection from '../../../shared-components/company-profile/HoldingSectionComponent'
import {
  GET_COMPANY_PROFILE,
  GET_LIST_OFFICE_ADDRESS,
  GET_TOTAL_FOLLOWER_COMPANY,
  // GET_HOLDING_COMPANY,
} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../src/utils/globals'
import {useQuery} from '@apollo/react-hooks'
import ModalEditDescription from './EditDescription'

import Loading from '../../../../components/loading/LoadingComponent'

const ProfileComponent = () => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    open ? setOpen(false) : setOpen(true)
  }

  const {
    data: dataProfile,
    loading: loadProfile,
    error: errorProfile,
    refetch: refetchProfile,
  } = useQuery(GET_COMPANY_PROFILE, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  // const {
  //   data: dataHolding,
  //   loading: loadHolding,
  //   error: errorHolding,
  // } = useQuery(GET_HOLDING_COMPANY, {
  //   variables: {
  //     company: COMPANY_ID,
  //   },
  //   context: {
  //     headers: {
  //       'X-Hasura-Role': 'public',
  //     },
  //   },
  // })

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetchProfile()
    }, 2000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  const {data: dataAddress} = useQuery(GET_LIST_OFFICE_ADDRESS, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
  })

  const {data: dataFollower} = useQuery(GET_TOTAL_FOLLOWER_COMPANY, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: COMPANY_ID,
    },
  })

  const officeListing = React.useMemo(() => {
    if (!dataAddress || !dataProfile) {
      return []
    }

    const addresses = dataAddress?.list_office
    const main = dataProfile?.company_profiles[0].main_office

    if (!main) {
      return addresses
    }

    return [main, ...addresses.filter(address => address.id !== main.id)]
  }, [dataAddress, dataProfile])

  if (loadProfile) {
    return <Loading />
  } else if (errorProfile !== undefined) {
    return <div>Something went wrong</div>
  }

  const feature = 'company'

  const res = dataProfile && dataProfile.company_profiles[0]
  const folower = dataFollower && dataFollower.getTotalFollowersCompany
  // const holding = dataHolding && dataHolding.holding_relations
  const _loc = `${res?.main_office?.global_city?.name}, ${res?.main_office?.global_province?.name}`

  return (
    <div style={{width: '100%'}}>
      <ProfileSection
        avatar={res?.logo}
        banner={res?.profile_field?.banner}
        headline={res?.profile_field?.headline}
        brand={res?.brand_name}
        legal={res?.legal_name}
        website={res?.profile_field?.website}
        location={_loc}
        codePhone={res?.country_code}
        phone={res?.phone}
        link={{
          linkedIn: res?.profile_field?.linkedin,
          twitter: res?.profile_field?.twitter,
          facebook: res?.profile_field?.facebook,
          email: res?.profile_field?.email,
          fax: res?.profile_field?.fax,
        }}
        feature={feature}
        data={res}
        refetch={refetchProfile}
      />
      <div style={{display: 'flex', alignItems: 'flex-start'}}>
        <div style={{marginRight: 20, width: '100%'}}>
          <DescriptionSection
            description={res?.profile_field?.description}
            openEdit={handleClose}
            feature={feature}
          />
          <OfficeLocationSection
            location={officeListing}
            mainOffice={res?.main_office?.id}
            contact={res?.profile_field}
            phone={`${res?.country_code}${res?.phone}`}
          />
        </div>

        <DetailSection
          countEmp={dataProfile?.employees.aggregate.count}
          industry={res?.global_company_industry_type?.name}
          type={res?.company_legal_type?.legal_type}
          headquarter={_loc}
          countFollowers={folower?.total || '-'}
          founded={res?.profile_field?.founded}
          speciality={res?.profile_field?.specialities}
        />
        {/* <HoldingSection
            logo={
              holding &&
              holding.company_head_profile &&
              holding.company_head_profile.logo
            }
            brand={
              holding &&
              holding.company_head_profile &&
              holding.company_head_profile.brand_name
            }
            legalName={
              holding &&
              holding.company_head_profile &&
              holding.company_head_profile.legal_name
            }
            category={
              holding &&
              holding.holding_category &&
              holding.holding_category.name
            }
            cluster={
              holding && holding.holding_cluster && holding.holding_cluster.name
            }
            subCluster={
              holding &&
              holding.holding_subcluster &&
              holding.holding_subcluster.name
            }
          ></HoldingSection> */}
      </div>
      <ModalEditDescription
        data={dataProfile}
        reload={refetchProfile}
        open={open}
        handleClose={handleClose}
      />
    </div>
  )
}

export default ProfileComponent
