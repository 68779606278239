import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'

import {
  Divider,
  Button,
  TablePagination,
  Toolbar,
  Typography,
  Paper,
} from '@material-ui/core'

import {GET_LOCATION_RESTRICTION} from '../../../../graphql/queries'
import {DELETE_LOCATION_RESTRICTION} from '../../../../graphql/mutations'
import {useMutation, useQuery} from '@apollo/react-hooks'
import Loading from '../../../../components/loading/LoadingComponent'
import {Link} from 'react-router-dom'
import {RedButton} from '../../overtime/OverPageStyles'
import numeral from 'numeral'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import {LeaveNameChild} from '../../leave/LeavePageStyles'
import {TableOptions} from '../../../shared-components/table/TableOptions'
import MaterialTable from 'material-table'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
  },
  datePickerAction: {
    marginRight: 30,
  },
  buttonAction: {
    // height: 55,
    // marginTop: 6,
    minWidth: '14rem',
  },
  buttonText: {
    textDecoration: 'none',
    color: '#fff',
  },
  title: {
    flex: '0 0 auto',
    marginLeft: 10,
  },
  flexButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))

const EnhancedTableToolbar = ({dataCheck}) => {
  const classes = useToolbarStyles()

  return (
    <Toolbar>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Location Restriction
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.flexButton}>
        <Button
          variant="contained"
          component={Link}
          to={{
            pathname: '/time/attendance/location_restrictions/add',
            state: {active: 'location-restrictions-add', dataCheck: dataCheck},
          }}
          color="primary"
          size="large"
          className={classes.buttonAction}
        >
          Add Location Restrictions
        </Button>
      </div>
    </Toolbar>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
    padding: 20,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detail: {
    color: '#007fb2',
    cursor: 'pointer',
  },
  customDatePicker: {
    width: 160,
  },
  btnEdit: {marginRight: 15, textDecoration: 'none'},
}))

function DeletePopUp({onDismiss, onDelete, open, selectedPolicy}) {
  const childrenCount =
    selectedPolicy.time_attendance_policy_locations_aggregate.aggregate.count
  const isHasChildren = childrenCount > 0

  return (
    <div>
      <Dialog
        open={open}
        onClose={onDismiss}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete <strong>{selectedPolicy.name}</strong>
        </DialogTitle>
        <DialogContent>
          {!isHasChildren && (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete location restriction setting:{' '}
              <strong>{selectedPolicy.name}</strong> ?
            </DialogContentText>
          )}
          {isHasChildren && (
            <DialogContentText id="alert-dialog-description">
              You want to delete location restriction:{' '}
              <strong>{selectedPolicy.name}</strong>, it has {childrenCount}{' '}
              attendance policies dependant to it. Please delete or remove all
              attendance policies from this location restriction.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onDismiss} color="primary">
            Cancel
          </Button>
          {!isHasChildren && (
            <RedButton
              onClick={() => onDelete(selectedPolicy.id)}
              color="secondary"
              autoFocus
            >
              Delete Anyway
            </RedButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default function LocationRestriction() {
  const {enqueueSnackbar} = useSnackbar()

  const [deleteLocation] = useMutation(DELETE_LOCATION_RESTRICTION)

  const classes = useStyles()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [currentLimit, setCurrentLimit] = React.useState(20)
  const [currentOffset, setCurrentOffset] = React.useState(0)

  const {data, error, loading, refetch} = useQuery(GET_LOCATION_RESTRICTION, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: currentLimit,
      offset: currentOffset,
    },
  })

  const [showDeletePopup, setDeletePopup] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)

  const deleteLocationRestriction = id => {
    const variables = {
      id: id,
      deleteAt: new Date(),
    }
    deleteLocation({variables})
      .then(() => {
        enqueueSnackbar('location restriction deleted', {
          variant: 'success',
          autoHideDuration: 500,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Deleted item failed, please try again later', {
          variant: 'error',
          autoHideDuration: 500,
        })
      })
  }

  if (loading) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
    setCurrentOffset(newPage * currentLimit)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setCurrentLimit(event.target.value)
    setPage(0)
  }

  const totalData = data.time_attendance_location_aggregate.aggregate.count

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar dataCheck={data} />
        <Divider />

        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0'}}
          columns={[
            {
              title: 'Location',
              field: 'name',
              cellStyle: {minWidth: 480},
              render: row => (
                <>
                  <Typography variant="subtitle2" gutterBottom>
                    {row.name}
                  </Typography>
                  {row.location && row.location.address}
                </>
              ),
            },
            {
              title: 'Radius',
              field: 'location_radius',
              cellStyle: {minWidth: 120},
              render: row => `${numeral(row.location_radius).format('0,0')} m`,
            },
            {
              title: 'Action',
              sorting: false,
              cellStyle: {padding: 0, minWidth: 85},
              render: row => (
                <LeaveNameChild>
                  <Typography
                    variant="body2"
                    component={Link}
                    color="secondary"
                    to={{
                      pathname: '/time/attendance/location_restrictions/edit',
                      state: {
                        active: 'location-restrictions-edit',
                        data: row,
                        dataCheck: data,
                      },
                    }}
                    className={classes.btnEdit}
                  >
                    Edit
                  </Typography>
                  <Typography
                    onClick={() => {
                      setItemToDelete(row)
                      setDeletePopup(true)
                    }}
                    variant="body2"
                    style={{cursor: 'pointer'}}
                    color="error"
                  >
                    Delete
                  </Typography>
                </LeaveNameChild>
              ),
            },
          ]}
          data={data.time_attendance_location}
          title=""
          options={{...TableOptions, selection: false, paging: false}}
          components={{
            Toolbar: () => null,
            Container: props => <Paper elevation={0} {...props}></Paper>,
          }}
        />
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={totalData}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {showDeletePopup && (
          <DeletePopUp
            open={showDeletePopup}
            onDelete={id => {
              setDeletePopup(false)
              deleteLocationRestriction(id)
            }}
            onDismiss={() => {
              setDeletePopup(false)
            }}
            selectedPolicy={itemToDelete}
          />
        )}
      </Paper>
    </div>
  )
}
