import gql from "graphql-tag";

export const GET_LIST_HISTORY = gql`
  query(
    $company: uuid
    $search: String
    $filterDate: timestamptz_comparison_exp
    $offset: Int
    $limit: Int
  ) {
    total: company_archived_logs_aggregate(
      where: {
        company: { _eq: $company }
        name: { _ilike: $search }
        created_at: $filterDate
        url_file: { _is_null: false }
      }
    ) {
      aggregate {
        count
      }
    }
    company_archived_logs(
      where: {
        company: { _eq: $company }
        name: { _ilike: $search }
        created_at: $filterDate
        url_file: { _is_null: false }
      }
      offset: $offset
      limit: $limit
      order_by: {id: desc}
    ) {
      id
      name
      start_date
      end_date
      url_file
      created_at
    }
  }
`;
