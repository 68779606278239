import gql from 'graphql-tag'

export const UPDATE_KPI_WEIGHT = id => {
  return gql`
      mutation updateKPIWeight($data: kpi_weight_units_set_input) {
        update_kpi_weight_units(where: {id: {_eq: ${id}}}, _set: $data) {
          returning {
            id
          }
        }
      }
    `
}
