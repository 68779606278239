import React from 'react'
import styled from 'styled-components'
import ReactCrop from 'react-image-crop'

import {
  Icon,
  Typography,
  Paper,
  Toolbar,
  TextField,
  Menu,
  MenuItem,
  Button,
  InputAdornment,
  MenuList,
  Tab,
  Tabs,
  InputLabel,
  Avatar,
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  FormControlLabel,
  withStyles,
  Tooltip,
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import Select, {components} from 'react-select'
import AsyncSelect from 'react-select/async'

export const ModalHeader = styled.div`
  padding: 1rem 1.6rem;
  background: #014a62;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ModalContent = styled.div`
  padding: 0.8rem 1.6rem;
  background: #eff2f4;
`
export const ModalContentEvent = styled.div`
  padding: 0.8rem 1.6rem;
  background: #fff;
`
export const WhiteIcon = styled(Icon)`
  color: #fff;
`

export const RedIcon = styled(Icon)`
  color: #ef4d5e;
`

export const BlueIcon = styled(Icon)`
  color: #014a62;
`

export const CheckBoxLabel = styled(FormControlLabel)`
  // width: 26px;
  height: 15px;
  padding-bottom: 10px;
  font-family: Muli;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`

export const MoreIcon = styled(Icon)`
  color: #014a62;
  margin-left: 10px;
  cursor: pointer;
`

export const Psm = styled.p`
  margin: 0;
  font-size: 12px;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 14px;
  }
`

export const GreyTypography = styled(Typography)`
  color: #a9a8a8;
`

export const FormContainer = styled.div`
  padding: 1.6rem;
`

export const FormChildContainer = styled.div`
  padding-bottom: 1.2rem;
  width: 50%;
  ${({fullWidth}) =>
    fullWidth &&
    `
    width: 100%;
    & > div:not(:first-child) {
      padding-top: 28px;
      padding-bottom: 28px;
    }
  `}
  & > .MuiFormControl-root {
    width: 100%;
    margin-top: 20px;
  }
  .MuiTextField-root {
    margin-top: 10px;
  }
  .MuiInputLabel-formControl {
    padding-left: 1.85em;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
  }
`

export const FormChildContainerPayroll = styled.div`
  padding-bottom: 1.2rem;
  width: 90%;
`

export const FormChildTitle = styled.div`
  display: flex;
  align-items: center;
`

export const TitleNumber = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  padding-right: 1rem;
`

export const TitleName = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`
export const FormChildInput = styled.div`
  padding-left: 1.85rem;
`

export const TextBlackBold = styled(Typography)`
  font-size: 13px;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #000000;
`

export const TextGrey = styled(Typography)`
  font-size: 0.875rem;
  font-family: ${props => props.theme.font};
  font-weight: 400;
  color: #a9a8a8;
`

export const TextDarkBlue = styled.span`
  font-family: ${props => props.theme.font};
  font-size: 0.9rem;
  font-weight: 600;
  color: #014a62;
`

export const TextDarkAzure = styled.span`
  font-family: ${props => props.theme.font};
  font-size: 0.9rem;
  font-weight: 600;
  color: #007fb2;
`

export const FormChildInputPad15 = styled.div`
  padding-left: 1.85rem;
  padding-top: 15px;
`

export const CropperPhoto = styled(ReactCrop)`
  &:after {
    content: ' ';
    width: 100vw;
    height: 100px;
    border: 2px solid #fff;
    padding: 20px;
  }
`

export const CropperBanner = styled(ReactCrop)``

export const FormChildInputHalf = styled.div`
  padding-left: 1.85rem;
  width: 50%;
`

export const FormChildButton = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 80px;
  align-items: center;
  padding-right: 1.6rem;
`
// export const StyledTextField = styled(TextField).attrs({
//   classes: {root: 'root'},
// })`
//   .root {
//     padding: 0;
//   }
// `

export const ScheduleContainer = styled.div`
  .schedule-container {
    width: 100%;
    .schedule-date {
      width: 100%;
    }
    .schedule-row {
      cursor: pointer;
      margin: 20px 0px;
      padding-left: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      .chip-schedule {
        width: 10%;
        width: 15px;
        height: 15px;
        border-radius: 10px !important;
      }
      .time-schedule {
        padding: 0px 10px;
        width: 15%;
      }
      .name-schedule {
        padding: 0px 10px;
        width: 75%;
      }
    }
  }
`

export const CalendarChip = styled.div`
  display: inline-block;
  width: 10%;
  width: 15px;
  height: 15px;
  border-radius: 5px !important;
`

export const DetailCalendarSchedule = styled.div`
  margin-top: 1rem;
  .content-detail {
    display: flex;
    padding-bottom: 1rem;
  }
  .button-container {
    text-align: center;
    margin-top: 15px;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  padding: 1rem 1.6rem 0 1.6rem;
  align-items: center;
`

export const BlueLink = styled(Link)`
  color: #007fb2;
  text-decoration: none;
`

export const AppliesToContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
`

export const AppliesToChild = styled.li`
  width: 21%;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
`

export const RootContainer = styled.div`
  display: flex;
  min-width: 0;
  flex-direction: column;
  flex-grow: 1;
`

export const PaperContainer = styled(Paper)`
  flex-grow: 1;
  overflow: ${props => props.overflow || 'auto'};
`

export const EnhancedToolbar = styled(Toolbar)`
  justify-content: space-between;
`

export const FilterTextfield = styled(TextField)`
  margin-right: 12px;
  min-width: 19%;
`
export const FilterContainers = styled.div`
  padding: 1rem 0 0.5rem 3.5rem;
`
export const StyledMenus = styled(({...other}) => (
  <Menu classes={{list: 'list'}} {...other} />
))`
  & .list {
    min-width: 120px;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  justify-content: ${props => props.justifyContent || 'center'};
  color: ${props => props.fontColor || '#003d51'};
`

export const AddEditTitle = styled(Typography)`
  margin-left: 1rem;
`
export const ThinTextfield = styled(({...other}) => (
  <TextField
    InputProps={{
      classes: {
        input: 'input',
      },
    }}
    FormHelperTextProps={{
      classes: {
        contained: 'contained',
      },
    }}
    {...other}
  />
))`
  width: ${props => props.width || '100%'};
  & .input {
    padding: 10px 14px;
    background: white;
  }
  & .contained {
    margin: 0px;
    margin-top: 10px;
  }
`

export const WhiteModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`

export const ContainerApplied = styled.div`
  background-color: #eff2f4;
  border-radius: 5px;
  padding: 20px;
`

export const ContainerListApplied = styled.div`
  margin-top: 10px;
`

export const ListApplied = styled.li`
  font-size: 12px;
`

export const CountApplied = styled(Typography)`
  font-size: 12px;
  color: #039be5;
  cursor: pointer;
`

export const PrimaryModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #014a62 !important;
`

export const FormToolbar = styled(Toolbar)`
  justify-content: space-between;
  padding-right: 16px;
  min-height: 70px;
`

export const FormTitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const LongBtn = styled(Button)`
  min-width: 150px;
`

export const CapsuleContent = styled.div`
  font-size: 12px;
  background: #a9a8a8;
  border-radius: 4px;
  padding: 3px 8px;
  color: #fff;
  margin-left: 20px;
`

export const PmdGrey = styled.p`
  margin: 0;
  font-size: 14px;
  color: #a9a8a8;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 18px;
  }
`

export const FlexBetween = styled.div`
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const FlexBetweenCustom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.width || '100%'};
`

export const FlexAround = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const FormChildContainers = styled.div`
  padding-bottom: 1rem;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FromButton = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 80px;
  align-items: center;
`

export const FilterText = styled(Typography)`
  margin-left: 15px;
  font-weight: 700;
`

export const AdornmentFilter = styled(InputAdornment)`
  /* border-left: 1px solid #a9a8a8; */
  min-height: 43px;
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
`
export const ButtonFilter = styled.div`
  border-left: 1px solid #a9a8a8;
  min-height: 43px;
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const MenuDropdown = styled(MenuList)`
  padding: 0px !important;
`

export const TabLabel = styled(Tab)`
  width: 270px;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  background-color: #ffffff;
  &:hover {
    background-color: #014a62;
    color: #ffffff;
  }
`

export const TabsStyled = styled(Tabs)`
  background-color: #f7f8f9;
`

export const TabStyled = styled(Tab)`
  &:hover {
    background-color: #f7f8f9;
    color: #000000;
  }
  &.Mui-selected {
    background-color: #f7f8f9;
    color: #000000;
    span {
      font-weight: 600;
    }
  }
`

export const LabelTabs = styled(Typography)`
  padding-right: 5px;
  height: 15px;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`

export const StepOneContainer = styled.div``

export const WrapperContainer = styled.div``

export const LabelInput = styled(InputLabel)`
  margin-left: 15px;
  font-family: Muli;
  font-size: 12px;
  color: #707070;
`

export const TextHelper = styled.p`
  color: #f44336;
  font-size: 0.75rem;
  margin-left: 25px;
`

export const FormChildContainerFull = styled.div`
  margin-bottom: 1.2rem;
`

export const MediumAvatar = styled(Avatar)`
  width: 75px !important;
  height: 75px !important;
`

export const BigAvatar = styled(Avatar)`
  width: 125px !important;
  height: 125px !important;
`

export const ColoredDiv = styled.div`
  border-radius: 5px;
  background: ${props => props.bg || '#014a62'};
  padding: 8px 24px;
  color: #fff;
  font-size: 0.8rem;
`

export const ButtonFullWidthDarkBlue = styled(Button)`
  width: 100%;
  padding: 13px 8px;
  border-radius: 0;
  background-color: #014a62;
  &:hover {
    background: #014a62;
  }
  & > .MuiButton-label {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
  }
`

export const ButtonBlue = styled(Button)`
  width: 130px;
  border-radius: 5px;
  background-color: #039be5;
  &:hover {
    background: #0f74a5;
  }
  & > .MuiButton-label {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
  }
`

export const ButtonWhiteRed = styled(Button)`
  width: 130px;
  border-radius: 5px;
  border: solid 1px #ef4d5e;
  border-c &:hover {
    background: #0f74a5;
  }
  & > .MuiButton-label {
    font-size: 16px;
    font-weight: 600;
    color: #ef4d5e;
  }
`

export const ButtonDarkBlue = styled(Button)`
  width: 120px;
  height: 50px;
  border-radius: 5px;
  margin-left: 10px;
  background-color: #014a62;
  &:hover {
    background: #0f74a5;
  }
  & > .MuiButton-label {
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
  }
`

export const WhiteButton = styled(Button)`
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgb(1, 74, 98);
`

const RSDropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon>arrow_drop_down</Icon>
    </components.DropdownIndicator>
  )
}

const RSDropdownIndicatorSearch = props => {
  return (
    <components.DropdownIndicator {...props}>
      <BlueIcon>search</BlueIcon>
    </components.DropdownIndicator>
  )
}

const RSSingleValue = props => {
  return <components.SingleValue {...props} />
}

const RSSingleValuePlain = props => {
  return (
    <components.SingleValue {...props}>
      {props.data.value}
    </components.SingleValue>
  )
}

const RSMultiValue = props => {
  return (
    <components.MultiValue {...props}>{props.data.value}</components.MultiValue>
  )
}

export const StyledReactSelect = props => {
  const customSelect = {
    control: provided => ({
      ...provided,
      width: '100%',
      minWidth: 250,
      outline: 'none',
      fontSize: props.fontSize,
      margin: props.marginNormal ? '16px 0 8px' : '0',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: '#039be5',
      padding: 8,
      borderRadius: 5,
      margin: 5,
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#fff',
    }),
    multiValueRemove: base => ({
      ...base,
      color: '#ffff',
      '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
    }),
    menu: base => ({
      ...base,
      maxWidth: '100%',
      zIndex: 10,
    }),
  }

  const SelectComponent = props.isAsync ? AsyncSelect : Select

  return (
    <SelectComponent
      isDisabled={props.disabled}
      components={{
        DropdownIndicator: props.indicatorSearch
          ? RSDropdownIndicatorSearch
          : RSDropdownIndicator,
        MultiValue: RSMultiValue,
        SingleValue: props.singleValuePlain
          ? RSSingleValuePlain
          : RSSingleValue,
      }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral50: '#bfbfbf', // Placeholder color
        },
      })}
      {...props}
      styles={{...customSelect, ...(props.style || null)}}
      noOptionsMessage={() => 'Tidak ada opsi'}
    />
  )
}

export const PaperFloatingBg = styled(Paper)`
  padding: ${props => props.padding || '20px'};
  width: 100%;
  background: linear-gradient(to bottom, #014a62 25%, transparent 0);
`
export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  justify-content: ${props =>
    props.flexEnd
      ? 'flex-end'
      : props.flexCenter
      ? 'center'
      : props.flexAround
      ? 'space-around'
      : props.flexBetween
      ? 'space-between'
      : 'flex-start'};
`
// !!!!! "Tambah", jangan "merubah"
const helperStyle = makeStyles(() => ({
  btnWhiteDarkBlue: {
    padding: '13px',
    borderRadius: '5px',
    border: 'solid 1px #014a62',
    cursor: 'pointer',
    '&:hover': {
      background: '#dadada',
    },
  },
  textBlack: {
    color: '#000000',
  },
  textWhite: {
    color: '#FFF',
  },
  textGrey: {
    color: '#a9a8a8 !important',
  },
  textRed: {
    color: '#FF0000',
  },
  textBlue: {
    color: '#039be5',
  },
  bgBlue: {
    backgroundColor: '#039be5',
  },
  bgGrey: {
    backgroundColor: '#a9a8a8',
  },
  buttonDisabled: {
    backgroundColor: 'grey',
    color: '#000',
  },
  buttonEnabled: {
    backgroundColor: '#039be5',
    color: '#fff',
  },
  inheritHeightForm: {
    height: 'inherit',
    '& div:nth-child(1)': {
      paddingTop: '8.5px',
      paddingBottom: '8.5px',
    },
  },
  displayNone: {
    display: 'none',
  },
  datePicker: {
    cursor: 'pointer',
    '& input': {
      cursor: 'pointer',
    },
  },
  myInheritText: {
    height: 'inherit',
    // width: "inherit"
    width: '100%',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  // width80P: {
  //   width: '80% !important',
  // },
  height39: {
    '& > .MuiOutlinedInput-root': {
      height: '39px !important',
    },
  },
  height38: {
    '& > .MuiOutlinedInput-root': {
      height: '38px !important',
    },
  },
  placeholderSelect: {
    '& .MuiSelect-select': {
      color: '#a9a8a8 !important',
    },
  },
  hideScrollX: {
    '& .css-2613qy-menu': {
      overflowX: 'hidden !important',
    },
  },
  hide: {
    visibility: 'hidden',
  },
  paddingTop5: {
    paddingTop: '0.5rem',
  },
  widthFull: {
    width: '100% !important',
  },
  height3: {
    height: '3px !important',
  },
  height380px: {
    height: '380px',
  },
  paddingxy10: {
    padding: '10px !important',
  },
  padding15: {
    padding: '15px !important',
  },
  padding24: {
    padding: 24,
  },
  paddingHeaderMap: {
    padding: '0px',
    marginRight: '10px',
    marginTop: '8px',
    alignSelf: 'start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  rowWithoutBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  width5P: {
    width: '5%',
  },
  width7P: {
    width: '7%',
  },
  width10P: {
    width: '10%',
  },
  width20P: {
    width: '20%',
  },
  width30P: {
    width: '30%',
  },
  width35P: {
    width: '35%',
  },
  width40P: {
    width: '40% !important',
  },
  width50P: {
    width: '50% !important',
  },
  width56P: {
    width: '56% !important',
  },
  width60P: {
    width: '60% !important',
  },
  width70P: {
    width: '70% !important',
  },
  width80P: {
    width: '80% !important',
  },
  width96P: {
    width: '96% !important',
  },
  fontSize14: {
    fontSize: '14px',
  },
  rselect: {
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
  },
  margin0: {
    margin: '0px !important',
  },
  marginTop8: {
    margin: '8px',
  },
  margin16: {
    margin: '10px',
  },
  margin20: {
    margin: '20px',
  },
  marginTopBot20: {
    margin: '20px auto',
  },
  marginTopBot30: {
    margin: '30px 0 30px 0',
  },
  marginTop6: {
    marginTop: '6px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginTop15: {
    marginTop: '15px',
  },
  marginTop16: {
    marginTop: '16px',
  },
  marginBot8: {
    marginBottom: '8px',
  },
  marginRight10: {
    marginRight: '10px',
  },
  marginRight15: {
    marginRight: '15px',
  },
  marginRight25: {
    marginRight: '25px',
  },
  marginLeft10: {
    marginLeft: '10px',
  },
  marginLeft20: {
    marginLeft: '20px',
  },
  marginLeft28: {
    marginLeft: '28px',
  },
  marginLeft50: {
    marginLeft: '50px',
  },
  padding0: {
    padding: '0px!important',
  },
  marginBot10: {
    marginBottom: '10px',
  },
  marginBot20: {
    marginBottom: 20,
  },
  marginBot28: {
    marginBottom: 28,
  },
  marginBot16: {
    marginBottom: 16,
  },
  mx16: {
    margin: '0 16px',
  },
  notViewScroll: {
    '&::-webkit-scrollbar': {
      width: '0px',
    },
  },
  notViewScrollTree: {
    '& div > .rst__virtualScrollOverride': {
      '&::-webkit-scrollbar': {
        width: '0px !important',
      },
    },
  },
  iconClose: {
    color: '#014a62',
    fontSize: '40px !important',
  },
  customDatePicker: {
    width: '11rem',
    marginRight: 12,
    '& > .MuiOutlinedInput-root': {
      height: '39px !important',
    },
  },
  cropperImgBanner: {
    '& img': {
      maxHeight: '300px !important',
      objectFit: 'fill',
    },
    '&::after': {
      border: 'none !important',
      padding: '0px !important',
    },
  },
  cropperImgPhoto: {
    '& img': {
      maxHeight: '300px !important',
      maxWidth: '300px !important',
      objectFit: 'fill',
    },
    '&::after': {
      border: 'none !important',
      padding: '0px !important',
    },
  },
  sizeAvatar: {
    height: '50px!important',
    width: '50px!important',
    display: 'inline-block!important',
  },
  listEmployee: {
    display: 'inline-block',
    width: '45% !important',
  },
  itemsCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
  rselect2: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    color: '#a9a8a8',
  },
  link: {
    textDecoration: 'none',
  },
  inputNumberPlaceholder: {
    '&::placeholder': {
      textAlign: 'left',
    },
  },
  errorChips: {
    backgroundColor: 'rgb(244, 67, 54) !important',
    color: 'white !important',
  },
}))
export default helperStyle

export const StyledExpansion = styled(ExpansionPanel)`
  margin-top: 1em;
  border-radius: 5px;
  background: #f7f8f9;
`

export const ExpansionPanelCurriculum = withStyles({
  root: {},
  disabled: {},
})(ExpansionPanel)

export const StyledExpansionSummary = styled(ExpansionPanelSummary).attrs({
  classes: {content: 'content'},
})`
  .content {
    align-items: center;
  }
`

export const SkyBlueIcon = styled(Icon)`
  color: #039be5;
`
export const GreyIcon = styled(Icon)`
  color: #a9a8a8;
`
export const SmallAvatar = styled(Avatar)`
  width: 26px !important;
  height: 26px !important;
`

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 9.3px;
  width: ${props => props.width};
`
export const PaperSection = styled(Paper)`
  padding: 30px;
`
export const WrapperInfoBlock = styled.div`
  margin-bottom: 21px;
`
export const CarouselCard = styled('div')`
  display: flex;
  overflow-x: auto;
`
export const CircleTag = styled('div')`
  border-radius: 50%;
  padding: 10px;
`
export const CircleTagEvent = styled('div')`
  margin-right: 5px;
  border-radius: 50%;
  padding: 10px;
  font-size: 12px;
  width: 15px;
  text-align: center;
`

export const AttachmentHoverWrap = styled('div')`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0px 24px 12px 0px;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  .icon {
    font-size: 33px;
  }
  .pdf {
    color: #ef4d5e;
  }
  .xls {
    color: #2c5a1a;
  }
  .ppt {
    color: #ef4d5e;
  }
  .doc {
    color: #039be5;
  }
  .img {
    width: 40px;
    max-width: 70px;
  }
  .full-img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    border-radius: 5px;
  }
  .file-name {
    margin-top: 11px;
    width: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
    p {
      margin: 0px;
      font-size: 12px;
    }
  }
  .hover {
    border-radius: 5px;
    height: 100%;
    width: 100%;
    opacity: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.3);
    }
    .icon {
      color: #fff;
      cursor: pointer;
      font-size: 26px;
    }
    .mr {
      margin-right: 10px;
    }
  }
`
export const WrapperModalDetail = styled('div')`
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: row;
    background-color: #014a62;
    padding: 12px 30px;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #fff;
      font-size: 20px;
      font-weight: normal;
    }
    .wrap-close {
      .icon-close {
        color: #fff;
      }
      .icon-delete {
        color: #fff;
        margin-right: 16px;
      }
    }
  }
  .content {
  }
`

export const CustomTypography = styled(Typography)`
  font-size: ${props => (props.size ? props.size : '14px')};
  font-family: ${props => props.theme.font};
  font-weight: ${props => (props.fweight === 'bold' ? '600' : 'normal')};
  color: ${props =>
    props.fcolor === 'lightBlue'
      ? '#039be5'
      : props.fcolor === 'grey'
      ? '#a9a8a8'
      : props.fcolor === 'blue'
      ? '#003d51'
      : props.fcolor === 'red'
      ? '#ef4d5e'
      : props.fcolor === 'green'
      ? '#4caf50'
      : props.fcolor === 'orange'
      ? '#ffa000'
      : '#000000'};
  font-style: ${props => (props.fstyle ? props.fstyle : 'normal')};
  margin-right: ${props => (props.right ? props.right : 0)};
  margin-left: ${props => (props.left ? props.left : 0)};
  margin-top: ${props => (props.top ? props.top : 0)};
  margin-bottom: ${props => (props.bottom ? props.bottom : 0)};
  cursor: ${props => (props.cursor ? props.cursor : 'default')};
`

export const GenerateTooltip = withStyles(() => ({
  tooltip: {
    padding: '12px 14px',
    backgroundColor: props => props.bgColor || '#eff2f4',
    color: '#000',
    fontSize: 12,
    borderRadius: 5,
    boxShadow: props => props.boxShadow || '',
  },
  arrow: {
    color: props => props.bgColor || '#eff2f4',
    backgroundColor: props => props.bgColor || '#eff2f4',
  },
}))(Tooltip)

export const WrapperScroll = styled('div')`
  max-height: ${props => props.maxHeight || '450px'};
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
`
export const BtnAddSquare = styled(Button)`
  padding: 24.6px 25.4px 25.4px 24.6px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
`

export const FlexCenters = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const WrapperChoosePeriode = styled.div`
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    background-color: #014a62;
    padding: 12px 30px;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #fff;
      font-size: 20px;
      font-weight: normal;
    }
    .wrap-close {
      .icon-close {
        color: #fff;
      }
    }
  }

  .body {
    margin: 25px 30px;

    .title-period {
      font-size: 1rem;
      margin-bottom: 12px;
    }

    .radio-custom {
      margin-bottom: 14px;
    }

    .wrap-date-picker {
      margin-top: 10px;
      width: 100%;
    }

    .text-alert-notes {
      margin-left: 2px;
      color: #ffa000;
    }
  }

  .action-wrapper-modal-confirmation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 30px 20px;
  }
`

export const AttachmentRowHoverWrap = styled('div')`
  width: 150px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0px 0px 9px 0px;
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  .icon {
    font-size: 23px;
    width: auto;
    height: 100%;
  }
  .pdf {
    color: #ef4d5e;
  }
  .xls {
    color: #2c5a1a;
  }
  .ppt {
    color: #ef4d5e;
  }
  .doc {
    color: #039be5;
  }
  .img {
    width: 40px;
    max-width: 70px;
  }
  .file-name {
    width: 150px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
    p {
      margin: 0px;
      font-size: 12px;
    }
  }
  .hover {
    border-radius: 5px;
    height: 100%;
    width: 100%;
    opacity: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.3);
    }
    .icon {
      color: #fff;
      cursor: pointer;
      font-size: 26px;
    }
    .mr {
      margin-right: 10px;
    }
  }
`

export const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${props => props.theme.font};
  color: black;
  width: 100%;
`

export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 20px;
`

export const LineClamp = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.lineClamp || '2'};
  margin: ${props => props.margin || '0px'};
`

export const AntTabs = withStyles({
  root: {
    backgroundColor: '#EFF2F4',
    color: '#000',
  },
  indicator: {
    backgroundColor: '#1890ff',
    height: 2,
  },
})(Tabs)

export const COLOR_STATUS = {
  waiting: '#ffa810',
  rejected: '#ec4c5c',
  approved: '#4dad54',
  processing: '#a45c34',
  settled: '#003d51',
}

export const selectInputStyles = {
  menu: provided => ({...provided, zIndex: 9999}),
  container: provided => ({
    ...provided,
    marginTop: 16,
    marginBottom: 8,
    minWidth: 400,
  }),
  control: base => ({
    ...base,
    '&:hover': {borderColor: 'gray'},
    border: '1px solid lightgray',
    boxShadow: 'none',
  }),
}

export const TextFieldStyledNoLine = styled(TextField)`
  & > div {
    &:before {
      content: none;
    }
  }
`
