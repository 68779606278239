import gql from 'graphql-tag'

export const GET_LIST_SURVEY_CENTER = gql`
  query($company_id: uuid!, $id: bigint) {
    multirater_surveys(
      where: {company: {_eq: $company_id}, id: {_eq: $id}}
      order_by: {id: desc}
    ) {
      id
      type
      nps_include
      date_created
      title
      description
      last_modified
      status
      startdate
      enddate
      scale
      buttons
      notification_email_format
      weight_settings
      multirater_responses_aggregate {
        aggregate {
          count(distinct: true, columns: respond_from)
        }
      }
      multirater_respondents_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`
export const GET_LIST_SURVEY_CENTER_SUBSCRIBE = gql`
  query(
    $company_id: uuid!
    $title: String
    $id: bigint
    $limit: Int
    $offset: Int
  ) {
    total: multirater_surveys_aggregate(
      where: {
        company: {_eq: $company_id}
        id: {_eq: $id}
        title: {_ilike: $title}
      }
    ) {
      aggregate {
        count
      }
    }
    multirater_surveys(
      where: {
        company: {_eq: $company_id}
        id: {_eq: $id}
        title: {_ilike: $title}
      }
      order_by: {id: desc}
      limit: $limit
      offset: $offset
    ) {
      id
      type
      nps_include
      date_created
      title
      description
      last_modified
      status
      startdate
      enddate
      scale
      buttons
      notification_email_format
      multirater_survey_groups {
        id
        isnps
      }
      multirater_responses_aggregate {
        aggregate {
          count(distinct: true, columns: respond_from)
        }
      }
      multirater_respondents_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_LIST_GROUP_QUESTION = gql`
  query($survey: bigint) {
    multirater_survey_groups(
      where: {survey: {_eq: $survey}}
      order_by: [{number: asc}]
    ) {
      id
      survey
      name
      isnps
      number
      multirater_survey_questions(order_by: [{number: asc}], limit: 5) {
        id
        number
        question_self
        question_others
      }
    }
  }
`

export const GET_LIST_GROUP_QUESTION_STEPPER = gql`
  query($survey: bigint!) {
    multirater_survey_groups(
      where: {survey: {_eq: $survey}}
      order_by: [{number: asc}]
    ) {
      id
      survey
      name
      isnps
      number
      multirater_survey_questions(order_by: [{number: asc}]) {
        id
        number
        question_self
        question_others
      }
    }
  }
`

export const GET_DROPDOWN_IMPORT = gql`
  query($company: uuid!) {
    multirater_bank_groups(where: {company: {_eq: $company}}) {
      id
      name
      multirater_bank_questions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_SURVEY_DETAIL = gql`
  query($id: bigint) {
    multirater_surveys(where: {id: {_eq: $id}}) {
      id
      title
      description
      startdate
      enddate
      status
      date_created
      last_modified
      count_respondents
      weight_settings
    }
  }
`

export const GET_LIST_ASESI = gql`
  query listasesi(
    $survey: bigint!
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: multirater_respondents_aggregate(
      where: {survey: {_eq: $survey}, global_user: {name: {_ilike: $search}}}
    ) {
      aggregate {
        count
      }
    }
    multirater_respondents(
      where: {survey: {_eq: $survey}, global_user: {name: {_ilike: $search}}}
      offset: $offset
      limit: $limit
    ) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
          id
          regno
          company_job_profile {
            id
            code
            title
          }
        }
      }
    }
  }
`

export const GET_LIST_RESPONDENTS_STEP_FOUR = gql`
  query getListGroupQuestion($survey: bigint) {
    multirater_respondents(where: {survey: {_eq: $survey}}) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`

export const GET_DETAIL_LIST_RESPONDENT = gql`
  query($user: uuid) {
    second_supervisor: people_work_placements(
      where: {
        company_job_profile: {
          job_profile_subordinate: {
            job_profile_subordinate: {
              people_work_placements: {user: {_eq: $user}}
            }
          }
        }
        status: {_eq: "ACTIVE"}
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }

    supervisor: people_work_placements(
      where: {
        company_job_profile: {
          job_profile_subordinate: {
            people_work_placements: {user: {_eq: $user}}
          }
        }
        status: {_eq: "ACTIVE"}
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }

    peer: people_work_placements(
      where: {
        _or: [
          {company_job_profile: {people_work_placements: {user: {_eq: $user}}}}
          {
            company_job_profile: {
              job_profile_supervisor: {
                job_profile_subordinate: {
                  people_work_placements: {user: {_eq: $user}}
                }
              }
            }
          }
        ]
        status: {_eq: "ACTIVE"}
        user: {_neq: $user}
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }

    subordinate: people_work_placements(
      where: {
        company_job_profile: {
          job_profile_supervisor: {people_work_placements: {user: {_eq: $user}}}
        }
        status: {_eq: "ACTIVE"}
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }

    second_subordinate: people_work_placements(
      where: {
        company_job_profile: {
          job_profile_supervisor: {
            job_profile_supervisor: {
              people_work_placements: {user: {_eq: $user}}
            }
          }
        }
        status: {_eq: "ACTIVE"}
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_LIST_QUESTION_FROM_BANK = gql`
  query($search: String, $group: bigint) {
    multirater_bank_questions(
      where: {group: {_eq: $group}, question_self: {_like: $search}}
    ) {
      id
      group
      question_self
      question_others
      number
    }
  }
`

export const GET_LIST_GROUP_QUESTION_FROM_BANK = gql`
  query($company: uuid!, $id: bigint) {
    multirater_bank_groups(where: {company: {_eq: $company}, id: {_eq: $id}}) {
      id
      name
      multirater_bank_questions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_RESPONDENT_GROUP = gql`
  query($company: uuid!) {
    multirater_respondent_groups(where: {company: {_eq: $company}}) {
      id
      name
    }
  }
`

export const GET_LIST_EMPLOYEE_IN_COMPANY = gql`
  query getEmployee(
    $search: String
    $jobProfile: [Int!]
    $organization: [Int!]
    $limit: Int
    $offset: Int
  ) {
    people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
        company_job_profile: {
          id: {_in: $jobProfile}
          organization: {_in: $organization}
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_LIST_ORGANIZATION = gql`
  query {
    company_organizations {
      id
      name
    }
  }
`

export const GET_DETAIL_SURVEY_BEFORE_DUPLI = gql`
  query($survey: bigint) {
    multirater_surveys(where: {id: {_eq: $survey}}) {
      title
      description
      type
      nps_include
      scale
      startdate
      enddate
      notification_email_format
      weight_settings
      buttons
      multirater_survey_groups {
        name
        isnps
        number
        multirater_survey_questions {
          question_self
          question_others
          number
        }
      }
      multirater_respondents {
        user
      }
    }
  }
`
