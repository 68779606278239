import gql from 'graphql-tag'

export const LIST_JOB_TITLE_FILTER = gql`
  query(
    $companyId: uuid!
    $search: String
    $limit: Int
    $nin: [Int!]
    $in: [Int!]
  ) {
    job_title_data: company_employee_positions(
      where: {
        company: {_eq: $companyId}
        deletedAt: {_is_null: true}
        _or: [{designation_code: {_ilike: $search}}, {name: {_ilike: $search}}]
        status: {_ilike: "Active"}
        id: {_nin: $nin, _in: $in}
      }
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
      designation_code
    }
    count_job_title: company_employee_positions_aggregate(
      where: {
        company: {_eq: $companyId}
        deletedAt: {_is_null: true}
        _or: [{designation_code: {_ilike: $search}}, {name: {_ilike: $search}}]
        status: {_ilike: "Active"}
        id: {_nin: $nin}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const LIST_JOB_FUNCTION_FILTER = gql`
  query(
    $companyId: uuid!
    $search: String
    $offset: Int
    $limit: Int
    $nin: [Int!]
    $in: [Int!]
  ) {
    count_job_function: job_family_functions_aggregate(
      where: {
        _and: [
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    job_function_data: job_family_functions(
      where: {
        _and: [
          {id: {_nin: $nin, _in: $in}}
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {code: {_ilike: $search}}
              {name: {_ilike: $search}}
              {description: {_ilike: $search}}
            ]
          }
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
      description
    }
  }
`

export const RESULT_FILTER_JOB_FAMILY = gql`
  query(
    $family: Int_comparison_exp
    $company: uuid
    $search: String
    $jobTitle: Int_comparison_exp
    $jobFunction: Int_comparison_exp
    $offset: Int
    $limit: Int
  ) {
    total: company_employee_positions_aggregate(
      where: {
        company: {_eq: $company}
        _or: [{designation_code: {_ilike: $search}}, {name: {_ilike: $search}}]
        id: $jobTitle
        job_mapping_id: $jobFunction
        family_id: $family
        status: {_ilike: "Active"}
      }
    ) {
      aggregate {
        count
      }
    }
    company_employee_positions(
      where: {
        company: {_eq: $company}
        _or: [{designation_code: {_ilike: $search}}, {name: {_ilike: $search}}]
        id: $jobTitle
        job_mapping_id: $jobFunction
        family_id: $family
        status: {_ilike: "Active"}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      designation_code
      name
    }
  }
`
