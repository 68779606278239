import ReportPageComponent from './ReportPageComponent'

export const LearningReportPageConfig = {
  routes: [
    {
      path: '/holding-learning/learning-report',
      component: ReportPageComponent,
      state: 'download-report',
      exact: true,
    },
  ],
}
