import moment from 'moment'
import {isEmailAddress} from '../../../../utils/helpers'

export const formatatSalary = salary => {
  if (salary && !Number.isInteger(salary) && salary !== '') {
    const number_string = salary.replace(/[^,\d]/g, '').toString()
    const split = number_string.split(',')
    const sisa = split[0].length % 3
    let rupiah = split[0].substr(0, sisa)
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    if (ribuan) {
      const separator = sisa ? '.' : ''
      rupiah += separator + ribuan.join('.')
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
    return rupiah
  } else {
    return salary
  }
}

const setErrorUtils = (
  value,
  isEdit,
  emailCount = 0,
  phoneCount = 0,
  regnoCount = 0
) => {
  const {
    name,
    email,
    phone_number,
    religion,
    address,
    province,
    city,
    postal_code,
    gender,
    regno,
    job_profile,
    legal,
    time,
    place,
    salary,
    joindate,
    startdate,
    enddate,
    birthdate,
    reminder,
  } = value

  let target = null
  if (!isEdit) {
    if (name === '') {
      target = 'name'
    } else if (gender === '') {
      target = 'gender'
    } else if (!birthdate) {
      target = 'birthdate'
    } else if (
      email === '' ||
      !isEmailAddress(email) ||
      // value === 'existEmail' ||
      emailCount !== 0
    ) {
      target = 'email'
    } else if (
      phone_number === '' ||
      phone_number === null ||
      // value === 'existPhone' ||
      phoneCount !== 0
    ) {
      target = 'phone_number'
    } else if (religion === 'default') {
      target = 'religion*'
    } else if (address === '') {
      target = 'address'
    } else if (province === 'default' || !province) {
      target = 'province'
    } else if (city === 'default' || !city) {
      target = 'city'
    } else if (!postal_code) {
      target = 'postal_code'
    } else if (
      parseInt(regno) <= 0 ||
      regno === null ||
      regno === '' ||
      // value === 'existRegno' ||
      regnoCount !== 0
    ) {
      target = 'regno'
    } else if (job_profile === 'default') {
      target = 'job_profile'
    } else if (legal === 'default') {
      target = 'legal'
    } else if (time === 'default') {
      target = 'time'
    } else if (place === 'default') {
      target = 'place'
    } else if (salary === null || salary === '') {
      target = 'salary'
    } else if (!joindate) {
      target = 'joindate'
    } else if (!startdate) {
      target = 'startdate'
    } else if (moment(startdate).isAfter(enddate) || !enddate) {
      target = 'enddate'
    } else if (!reminder) {
      target = 'reminder'
    } else {
      target = null
    }
  } else {
    if (
      parseInt(regno) <= 0 ||
      regno === null ||
      regno === '' ||
      // value === 'existRegno' ||
      regnoCount !== 0
    ) {
      target = 'regno'
    } else if (job_profile === 'default') {
      target = 'job_profile'
    } else if (legal === 'default') {
      target = 'legal'
    } else if (time === 'default') {
      target = 'time'
    } else if (place === 'default') {
      target = 'place'
    } else if (salary === null || salary === '') {
      target = 'salary'
    } else if (!joindate) {
      target = 'joindate'
    } else if (!startdate) {
      target = 'startdate'
    } else if (moment(startdate).isAfter(enddate) || !enddate) {
      target = 'enddate'
    } else if (!reminder) {
      target = 'reminder'
    } else {
      target = null
    }
  }

  if (target) {
    const ids = document.getElementById(target)
    if (ids) {
      ids.scrollIntoView({behavior: 'smooth'})
    }
  }
}

export const errorChecker = (
  value,
  setError,
  isEdit,
  emailCount,
  phoneCount,
  regnoCount
) => {
  const {
    name,
    email,
    phone_number,
    religion,
    address,
    province,
    city,
    postal_code,
    gender,
    regno,
    job_profile,
    legal,
    time,
    place,
    salary,
    joindate,
    startdate,
    enddate,
    birthdate,
    reminder,
  } = value

  if (isEdit) {
    if (
      job_profile === 'default' ||
      regno === '' ||
      regno === null ||
      legal === 'default' ||
      !legal ||
      time === 'default' ||
      !time ||
      place === 'default' ||
      !place ||
      salary === null ||
      salary === '' ||
      parseInt(salary) < 0 ||
      moment(startdate).isAfter(enddate) ||
      !joindate ||
      !startdate ||
      !enddate ||
      !reminder
    ) {
      setErrorUtils(value, true)
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
      return true
    } else {
      return false
    }
  } else {
    if (
      name === '' ||
      gender === '' ||
      !birthdate ||
      email === '' ||
      emailCount !== 0 ||
      phoneCount !== 0 ||
      regnoCount !== 0 ||
      !isEmailAddress(email) ||
      phone_number === '' ||
      phone_number === null ||
      religion === 'default' ||
      address === '' ||
      province === 'default' ||
      !province ||
      city === 'default' ||
      !city ||
      postal_code === null ||
      job_profile === 'default' ||
      regno === '' ||
      regno === null ||
      legal === 'default' ||
      time === 'default' ||
      place === 'default' ||
      salary === null ||
      salary === '' ||
      parseInt(salary) < 0 ||
      moment(startdate).isAfter(enddate) ||
      !joindate ||
      !startdate ||
      !enddate ||
      !reminder
    ) {
      setErrorUtils(value, false, emailCount, phoneCount, regnoCount)
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
      return true
    } else {
      return false
    }
  }
}

export const changeToNumber = number => {
  if (number && !Number.isInteger(number)) {
    return parseInt(number.split('.').join(''))
  } else {
    return number
  }
}

export const legalData = [
  {name: 'permanent', value: 'Permanent'},
  {name: 'contract', value: 'Contract'},
  {name: 'freelance', value: 'Freelance'},
  {name: 'internship', value: 'Internship'},
]

export const timeData = [
  {name: 'fulltime', value: 'Full Time'},
  {name: 'parttime', value: 'Part Time'},
  {name: 'daily', value: 'Daily'},
  {name: 'weekly', value: 'Weekly'},
  {name: 'ondemand', value: 'On Demand'},
  {name: 'flexible', value: 'Flexible'},
  {name: 'weekend', value: 'Weekend'},
]

export const placeData = [
  {name: 'onsite', value: 'On Site'},
  {name: 'remote', value: 'Remote'},
  {name: 'nomadic', value: 'Nomadic/Travel'},
]

export const getTypeFile = (href = '') => {
  return href
    .split('.')
    .slice(-1)
    .pop()
    .toLocaleLowerCase()
}

export function getLastSalary(pws = []) {
  const reversed = pws.reverse();
  const filterByPassed = reversed.filter(
    (e) => e.start_date != null && new Date(e.start_date) <= new Date()
  );
  if (filterByPassed.length == 1) return filterByPassed[0];
  else if (filterByPassed.length > 1) {
    const filterByPassedActive = filterByPassed.filter((e) => e.active);
    if (filterByPassedActive.length > 0) return filterByPassedActive[0];
    else return filterByPassed[0];
  }
  return reversed.find((e) => e.active);
}
