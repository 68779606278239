import gql from 'graphql-tag'

export const GET_PAYROLL_SETTINGS = gql`
  {
    finance_payroll_settings {
      cut_off_date
      id
      pay_date
    }
  }
`
