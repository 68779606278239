import React from 'react'
import {WidgetChartWrapper} from './DoughnutChartStyles'
import LabelList from './label/LabelListComponent'
import LabelColumn from './label/LabelColumnComponent'
import DoughnutChart from './DoughnutChartComponent'
import PieChart from './PieChartComponent'

const DoughnutCardWidget = props => {
  const {labelType, type, alignItems = 'center'} = props

  return (
    <WidgetChartWrapper
      alignItems={alignItems}
      direction={labelType === 'column' ? 'column' : 'row'}
    >
      {type === 'pie' ? <PieChart {...props} /> : <DoughnutChart {...props} />}
      {labelType === 'list' ? (
        <LabelList {...props} />
      ) : (
        <LabelColumn {...props} />
      )}
    </WidgetChartWrapper>
  )
}

export default DoughnutCardWidget
