import CompetencyPageComponent from './CompetencyPageComponent'

export const CompetencyPageConfig = {
  routes: [
    // Individual Competency
    {
      path: '/learning/competency',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/detail/:id',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/detail/:id/add',
      component: CompetencyPageComponent,
      exact: true,
    },
    // Dictionary
    {
      path: '/learning/competency/dictionary',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/dictionary/add',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/dictionary/:id',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/dictionary/detail/:id',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/dictionary/edit/:id',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/dictionary/:id/add',
      component: CompetencyPageComponent,
      exact: true,
    },
    // List Of Competency
    {
      path: '/learning/competency/list-of-competency',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/list-of-competency/add',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/list-of-competency/edit/:id',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/list-of-competency/:id',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/list-of-competency/:id/edit',
      component: CompetencyPageComponent,
      exact: true,
    },
    // Model
    {
      path: '/learning/competency/model',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/model/:id',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/model/detail/:id',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/model/:id/add',
      component: CompetencyPageComponent,
      exact: true,
    },
    // Category
    {
      path: '/learning/competency/category',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/category/add',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/category/edit/:id',
      component: CompetencyPageComponent,
      exact: true,
    },
    {
      path: '/learning/competency/category/detail/:id',
      component: CompetencyPageComponent,
      exact: true,
    },
  ],
}
