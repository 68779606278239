import React, {useState, useEffect} from 'react'
import helperStyle, {
  FlexStart,
  OrganizationContainer,
  LitleRedText,
  LitleLightBlueText,
  RpContainer,
  LitleGreyText,
} from '../../../OrganizationPageStyles'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
  TextHelper,
} from '../../../../../GlobalStyles'
import {TextField, InputAdornment, CircularProgress} from '@material-ui/core'
import Select from 'react-select'
import classNames from 'classnames'
import {
  GET_COMPETENCY,
  GET_COURSE,
  GET_DEGREE,
  GET_FACULTY,
} from '../../../../../graphql/queries/index'
import {useQuery} from '@apollo/react-hooks'
// import {ADD_TALENT_JOB_PROFILE} from '../../../../../graphql/mutations'
import {withRouter} from 'react-router-dom'
import {convertToCurrency} from '../../../../../utils/helpers'
import {COMPANY_ID} from '../../../../../utils/globals'

const TabTalent = props => {
  const {setAllData, allDatas, status, dataEdit, isError} = props
  const classes = helperStyle()

  const [countCompetency, setCountCompetency] = useState(0)
  const [countEducation, setCountEducation] = useState(0)
  // const [countCourse, setCountCourse] = useState(0)
  const [competencyDropdown, setCompetencyDropdown] = useState([])
  const [levelDropdown, setLevelDropdown] = useState([])
  const [facultyDropdown, setFacultyDropdown] = useState([])
  const [degreeDropdown, setDegreeDropdown] = useState([])
  // const [courseDropdown, setCourseDropdown] = useState([])

  const [idCompetency, setIdCompetency] = useState([])
  const [idEducation, setIdEducation] = useState([])
  const [idCourse, setIdCourse] = useState([])
  const [searchDropdown, setSearchDropdown] = useState({
    competencyModel: '',
    degree: '',
    faculty: '',
    course: '',
  })

  useEffect(() => {
    if (
      allDatas.IdCompetency &&
      allDatas.dataDropdownCompetency &&
      allDatas.dataDropdownLevel &&
      idCompetency.length === 0
    ) {
      if (allDatas.IdCompetency.length > 0) {
        setIdCompetency(allDatas.IdCompetency)
        setCompetencyDropdown(allDatas.dataDropdownCompetency)
        setLevelDropdown(allDatas.dataDropdownLevel)
        setCountCompetency(allDatas.IdCompetency.length - 1)
      }
    }

    if (
      allDatas.IdEducation &&
      allDatas.dataDropdownDegree &&
      allDatas.dataDropdownFaculty &&
      idEducation.length === 0
    ) {
      if (allDatas.IdEducation.length > 0) {
        setFacultyDropdown(allDatas.dataDropdownFaculty)
        setDegreeDropdown(allDatas.dataDropdownDegree)
        setIdEducation(allDatas.IdEducation)
        setCountEducation(allDatas.IdEducation.length - 1)
      }
    }
    if (
      allDatas.IdCourse &&
      allDatas.dataDropdownCourse &&
      idCourse.length === 0
    ) {
      if (allDatas.IdCourse.length > 0) {
        setIdCourse([...allDatas.IdCourse])
        // setCourseDropdown([...allDatas.dataDropdownCourse])
        // setCountCourse(allDatas.IdCourse.length - 1)
      }
    }
    if (status === 'Add') {
      setAllData({
        ...allDatas,
        IdCompetency: [],
        IdCourse: [],
        IdEducation: [],
      })
    }
  }, [idCompetency, idEducation, idCourse, countCompetency])

  useEffect(() => {
    let ids = ''
    if (
      (typeof allDatas.IdCompetency === 'undefined' && isError) ||
      (allDatas.IdCompetency && allDatas.IdCompetency.length === 0)
    ) {
      ids = document.getElementById('competency')
      ids && ids.scrollIntoView({behavior: 'smooth'})
    } else if (
      (allDatas && !allDatas.salary_from && isError) ||
      (allDatas && !allDatas.salary_to && isError)
    ) {
      ids = document.getElementById('salary')
      ids && ids.scrollIntoView({behavior: 'smooth'})
    } else if (allDatas && !allDatas.slot && isError) {
      ids = document.getElementById('slot')
      ids && ids.scrollIntoView({behavior: 'smooth'})
    } else if (
      (typeof allDatas.IdEducation === 'undefined' && isError) ||
      (allDatas.IdEducation && allDatas.IdEducation.length === 0)
    ) {
      ids = document.getElementById('education')
      ids && ids.scrollIntoView({behavior: 'smooth'})
    }
  }, [isError])

  // const varCom =
  //   status === 'Edit'
  //     ? {
  //         job_profile: dataEdit.id,
  //         filter: true,
  //       }
  //     : {
  //         job_profile: null,
  //         filter: false,
  //       }

  const {
    data: competancy,
    loading: competancyLoad,
    error: competancyError,
  } = useQuery(GET_COMPETENCY, {
    variables: {
      company: COMPANY_ID,
      search: `%${searchDropdown.competencyModel}%`,
      offset: 0,
      limit: 30,
    },
  })

  const {
    data: courses,
    // loading: coursesLoad,
    error: coursesError,
  } = useQuery(GET_COURSE, {
    variables: {
      search: `%${searchDropdown.course}%`,
      offset: 0,
      limit: 30,
    },
  })

  const {data: faculty, loading: facultyLoad, error: facultyError} = useQuery(
    GET_FACULTY,
    {
      variables: {
        search: `%${searchDropdown.faculty}%`,
        offset: 0,
        limit: 30,
      },
    }
  )

  const {data: degree, loading: degreeLoad, error: degreeError} = useQuery(
    GET_DEGREE,
    {
      variables: {
        search: `%${searchDropdown.degree}%`,
        offset: 0,
        limit: 30,
      },
    }
  )

  const competancysLevel = [
    {
      value: 1,
      label: 'Level 1',
    },
    {
      value: 2,
      label: 'Level 2',
    },
    {
      value: 3,
      label: 'Level 3',
    },
    {
      value: 4,
      label: 'Level 4',
    },
    {
      value: 5,
      label: 'Level 5',
    },
  ]

  const checkHasLevelCompetency = id => {
    return competencyDropdown[id] && competencyDropdown[id].type === 'level'
      ? competancysLevel
      : [
          {
            value: null,
            label: 'Tidak ada Level',
          },
        ]
  }

  useEffect(() => {
    if (status === 'Edit') {
      if (!allDatas.slot) {
        const competancys = []
        const level = []
        const idCompetancys = []
        dataEdit &&
          dataEdit.company_job_profile_competencies.map(options => {
            const competancyData = {
              value:
                options &&
                options.competency_version_dictionary &&
                options.competency_version_dictionary.competency_dictionary &&
                options.competency_version_dictionary.competency_dictionary.id,
              label:
                options &&
                options.competency_version_dictionary &&
                options.competency_version_dictionary.competency_dictionary &&
                options.competency_version_dictionary.competency_dictionary.name
                  .en,
              type:
                options &&
                options.competency_version_dictionary &&
                options.competency_version_dictionary.competency_dictionary &&
                options.competency_version_dictionary.competency_dictionary
                  .type,
              id:
                options &&
                options.competency_version_dictionary &&
                options.competency_version_dictionary.id,
            }

            // console.log({dataEdit})
            // console.log({options})

            const levelData = competancysLevel.find(
              levels => options.level === levels.value
            )

            const idCompetancysData = {
              job_profile: dataEdit.id,
              competency:
                options &&
                options.competency_version_dictionary &&
                options.competency_version_dictionary.competency_dictionary &&
                options.competency_version_dictionary.competency_dictionary.id,
              dictionary: options.competency_version_dictionary.id,
              level: options && options.level,
            }

            level.push(levelData)
            competancys.push(competancyData)
            idCompetancys.push(idCompetancysData)
          })

        setIdCompetency(idCompetancys)
        setCompetencyDropdown(competancys)
        setLevelDropdown(level)
        setCountCompetency(dataEdit.company_job_profile_competencies.length - 1)

        const degree = []
        const faculty = []
        const idEducations = []

        dataEdit &&
          dataEdit.company_job_profile_r_educations.map(options => {
            const degreeData = {
              value: options.global_degree.id,
              label: options.global_degree.name,
            }

            const facultyData = {
              value: options.global_faculty ? options.global_faculty.id : null,
              label: options.global_faculty ? options.global_faculty.name : '-',
            }

            const idEducationsData = {
              job_profile: dataEdit.id,
              degree: options.global_degree.id,
              major: options.global_faculty ? options.global_faculty.id : null,
            }

            degree.push(degreeData)
            faculty.push(facultyData)
            idEducations.push(idEducationsData)
          })

        setDegreeDropdown(degree)
        setFacultyDropdown(faculty)
        setIdEducation(idEducations)
        setCountEducation(dataEdit.company_job_profile_r_educations.length - 1)

        const courses = []
        const idCourses = []
        dataEdit &&
          dataEdit.company_job_profile_r_course.map(options => {
            const courseData = {
              value:
                options && options.academy_course && options.academy_course.id,
              label:
                options &&
                options.academy_course &&
                options.academy_course.title,
            }

            const idCourseData = {
              job_profile: dataEdit.id,
              course:
                options && options.academy_course && options.academy_course.id,
            }

            courses.push(courseData)
            idCourses.push(idCourseData)
          })

        // setCourseDropdown(courses)
        setIdCourse(idCourses)
        // setCountCourse(dataEdit.company_job_profile_r_course.length - 1)

        setAllData({
          ...allDatas,
          IdEducation: idEducations,
          IdCourse: idCourses,
          IdCompetency: idCompetancys,
          salary_from: dataEdit.rate_salary_from
            ? convertToCurrency(dataEdit.rate_salary_from.toString())
            : '',
          salary_to: dataEdit.rate_salary_to
            ? convertToCurrency(dataEdit.rate_salary_to.toString())
            : '',
          slot: dataEdit.slots,
          competancysSelected: competancys,
          levelSelected: level,
          countCom: dataEdit.company_job_profile_competencies.length - 1,
          degreeSelected: degree,
          facultySelected: faculty,
          countEdu: dataEdit.company_job_profile_r_educations.length - 1,
          coursesSelected: courses,
          countCou: dataEdit.company_job_profile_r_course.length - 1,
        })
      } else {
        setCompetencyDropdown(allDatas.competancysSelected)
        setLevelDropdown(allDatas.levelSelected)
        setCountCompetency(allDatas.countCom)
        setDegreeDropdown(allDatas.degreeSelected)
        setFacultyDropdown(allDatas.facultySelected)
        setCountEducation(allDatas.countEdu)
        // setCourseDropdown(allDatas.coursesSelected)
        // setCountCourse(allDatas.countCou)
      }
    } else if (status === 'Add') {
      if (allDatas.slot) {
        setCompetencyDropdown(allDatas.dataDropdownCompetency)
        setLevelDropdown(allDatas.dataDropdownLevel)
        setCountCompetency(
          allDatas.dataDropdownCompetency &&
            allDatas.dataDropdownCompetency.length - 1
        )
        setDegreeDropdown(allDatas.dataDropdownDegree)
        setFacultyDropdown(allDatas.dataDropdownFaculty)
        setCountEducation(
          allDatas.dataDropdownDegree && allDatas.dataDropdownDegree.length - 1
        )
        // setCourseDropdown(allDatas.dataDropdownCourse)
        // setCountCourse(
        //   allDatas.dataDropdownCourse && allDatas.dataDropdownCourse.length - 1
        // )
      }
    }
  }, [status])

  if (
    (competancy === undefined && competancyError === undefined) ||
    (courses === undefined && coursesError === undefined) ||
    (faculty === undefined && facultyError === undefined) ||
    (degree === undefined && degreeError === undefined)
  ) {
    return <CircularProgress />
  } else if (
    competancyError !== undefined ||
    coursesError !== undefined ||
    facultyError !== undefined ||
    degreeError !== undefined
  ) {
    return (
      <div>
        {competancyError} {coursesError} {facultyError} {degreeError}
      </div>
    )
  }
  // console.log(competancy)
  const competancys = []
  competancy &&
    competancy.competency_version_dictionaries.map(options => {
      const competancyOptions = {
        value: options.competency_dictionary.id,
        label: options.competency_dictionary.name.en,
        id: options.id,
        type: options.competency_dictionary.type,
      }

      competancys.push(competancyOptions)
    })
  // console.log(competancys)

  const degrees = []
  degree &&
    degree.global_degrees.map(options => {
      const degreesOptions = {
        value: options.id,
        label: options.name,
      }

      degrees.push(degreesOptions)
    })

  const facultys = [
    {
      value: null,
      label: '-',
    },
  ]
  faculty &&
    faculty.global_faculties.map(options => {
      const facultysOptions = {
        value: options.id,
        label: options.name,
      }

      facultys.push(facultysOptions)
    })

  const coursess = []
  courses &&
    courses.academy_courses.map(options => {
      const degreeOptions = {
        value: options.id,
        label: options.title,
      }

      coursess.push(degreeOptions)
    })

  // console.log(dataEdit)
  const addDataCompetancy = (event, id) => {
    const data = competencyDropdown
    const dataForm = idCompetency
    if (data.length === id) {
      const add = [
        ...data,
        {
          label: event.label,
          value: event.value,
          type: event.type,
        },
      ]

      let addFrom = []
      if (status === 'Add') {
        addFrom = [
          ...dataForm,
          {
            competency: event.value,
            competencyLabel: event.label,
            dictionary: event.id,
          },
        ]
      } else if (status === 'Edit') {
        addFrom = [
          ...dataForm,
          {
            job_profile: dataEdit.id,
            competency: event.value,
            dictionary: event.id,
          },
        ]
      }
      // console.log(addFrom)
      setIdCompetency(addFrom)
      setCompetencyDropdown(add)
      setAllData({
        ...allDatas,
        dataDropdownCompetency: add,
        IdCompetency: addFrom,
      })
    } else {
      const update = [...data]
      update[id].label = event.label
      update[id].value = event.value
      update[id].id = event.id

      const updateForm = [...dataForm]
      updateForm[id].competency = event.value
      updateForm[id].dictionary = event.id

      setCompetencyDropdown(update)
      setIdCompetency(updateForm)
    }
  }

  const addDataLevel = (event, id) => {
    const data = levelDropdown
    const dataForm = idCompetency[id]
    dataForm.level = event.value

    const addForm = [...idCompetency]
    if (data.length === id) {
      const add = [
        ...data,
        {
          label: event.label,
          value: event.value,
        },
      ]

      setLevelDropdown(add)
      setAllData({
        ...allDatas,
        dataDropdownLevel: add,
        IdCompetency: addForm,
      })
    } else {
      const update = [...data]
      update[id].label = event.label
      update[id].value = event.value

      setLevelDropdown(update)
    }
    setIdCompetency(addForm)
  }

  const addDataDegree = (event, id) => {
    const data = degreeDropdown
    const dataForm = idEducation
    if (data.length === id) {
      const add = [
        ...data,
        {
          label: event.label,
          value: event.value,
        },
      ]

      let addForm = []
      if (status === 'Add') {
        addForm = [
          ...dataForm,
          {
            degree: event.value,
          },
        ]
      } else if (status === 'Edit') {
        addForm = [
          ...dataForm,
          {
            job_profile: dataEdit.id,
            degree: event.value,
          },
        ]
      }

      setIdEducation(addForm)
      setDegreeDropdown(add)
      setAllData({
        ...allDatas,
        dataDropdownDegree: add,
      })
    } else {
      const update = [...data]
      update[id].label = event.label
      update[id].value = event.value

      const updateForm = [...dataForm]
      updateForm[id].degree = event.value

      setIdEducation(updateForm)
      setDegreeDropdown(update)
    }
  }

  const addDataFaculty = (event, id) => {
    const data = facultyDropdown
    const dataForm = idEducation[id]
    dataForm.major = event.value
    // const addMajor = [...dataForm]
    const addForm = [...idEducation]
    if (data.length === id) {
      const add = [
        ...data,
        {
          label: event.label,
          value: event.value,
        },
      ]

      setFacultyDropdown(add)
      setAllData({
        ...allDatas,
        dataDropdownFaculty: add,
        IdEducation: addForm,
      })
    } else {
      const update = [...data]
      update[id].label = event.label
      update[id].value = event.value

      // const updateForm = [...dataForm]
      // updateForm[id].major = event.value

      // setIdEducation(updateForm)
      setFacultyDropdown(update)
    }
    setIdEducation(addForm)
  }

  // const addDataCourse = (event, id) => {
  //   const data = courseDropdown
  //   const dataForm = idCourse
  //   if (data.length === id) {
  //     const add = [
  //       ...courseDropdown,
  //       {
  //         label: event.label,
  //         value: event.value,
  //       },
  //     ]

  //     let addForm = []
  //     if (status === 'Add') {
  //       addForm = [
  //         ...dataForm,
  //         {
  //           course: event.value,
  //         },
  //       ]
  //     } else if (status === 'Edit') {
  //       addForm = [
  //         ...dataForm,
  //         {
  //           job_profile: dataEdit.id,
  //           course: event.value,
  //         },
  //       ]
  //     }

  //     setIdCourse(addForm)
  //     setCourseDropdown(add)
  //     setAllData({
  //       ...allDatas,
  //       dataDropdownCourse: add,
  //       IdCourse: addForm,
  //     })
  //   } else {
  //     const update = [...data]
  //     update[id].label = event.label
  //     update[id].value = event.value

  //     const updateForm = [...dataForm]
  //     updateForm[id].course = event.value

  //     setIdCourse(updateForm)
  //     setCourseDropdown(update)
  //   }
  // }

  // console.log(dataEdit)
  const addTextFieldCompetency = () => {
    const length = countCompetency
    setCountCompetency(length + 1)
  }

  const delTextFieldCompetency = id => {
    const data = competencyDropdown
    const dataForm = idCompetency
    const length = countCompetency

    data.splice(id, 1)
    dataForm.splice(id, 1)
    setIdCompetency(dataForm)
    setCompetencyDropdown(data)
    setAllData({
      ...allDatas,
      dataDropdownCompetency: data,
      IdCompetency: dataForm,
    })
    if (countCompetency > 0) {
      setCountCompetency(length - 1)
    } else {
      setCountCompetency(length)
    }
  }

  const addTextFieldEducation = () => {
    const length = countEducation
    setCountEducation(length + 1)
  }

  const delTextFieldEducation = id => {
    const dataDegree = degreeDropdown
    const dataFaculty = facultyDropdown
    const dataForm = idEducation
    const length = countEducation
    setCountEducation(length - 1)

    dataDegree.splice(id, 1)
    dataFaculty.splice(id, 1)
    dataForm.splice(id, 1)

    setDegreeDropdown(dataDegree)
    setIdEducation(dataForm)
  }

  // const addTextFieldCourse = () => {
  //   const length = countCourse
  //   setCountCourse(length + 1)
  // }

  // const delTextFieldCourse = id => {
  //   const data = courseDropdown
  //   const dataForm = idCourse
  //   const length = countCourse
  //   setCountCourse(length - 1)

  //   data.splice(id, 1)
  //   dataForm.splice(id, 1)

  //   setCourseDropdown(data)
  //   setIdCourse(dataForm)
  // }

  const competencyModel = index => {
    const htmlSelectCompetency = []
    for (let count = 0; index >= count; count++) {
      htmlSelectCompetency.push(
        <FlexStart key={count}>
          <Select
            className={classNames(classes.width80P, classes.marginTop10)}
            id="competency"
            placeholder="Pilih Model Kompetensi"
            options={competancys}
            value={competencyDropdown[count] ? competencyDropdown[count] : ''}
            isLoading={competancyLoad}
            onInputChange={dataSearchDropdown =>
              setSearchDropdown({
                ...searchDropdown,
                competencyModel: dataSearchDropdown,
              })
            }
            onChange={event => addDataCompetancy(event, count)}
            styles={{
              menu: provided => ({...provided, zIndex: 9999}),
            }}
          />
          <Select
            className={classNames(
              classes.width80P,
              classes.marginTop10,
              classes.marginLeft10
            )}
            id="level"
            placeholder="Pilih Level"
            options={checkHasLevelCompetency(count)}
            value={levelDropdown[count] ? levelDropdown[count] : ''}
            onChange={event => addDataLevel(event, count)}
            styles={{
              menu: provided => ({...provided, zIndex: 9999}),
            }}
          />
          {index === 0 ? (
            ''
          ) : (
            <LitleRedText
              onClick={() => delTextFieldCompetency(count)}
              className={classes.marginLeft10}
            >
              Hapus
            </LitleRedText>
          )}
        </FlexStart>
      )
    }
    return htmlSelectCompetency
  }

  const education = index => {
    const htmlEducation = []
    for (let count = 0; index >= count; count++) {
      htmlEducation.push(
        <FlexStart key={count}>
          <Select
            className={classNames(classes.width80P, classes.marginTop10)}
            id="degree"
            options={degrees}
            value={degreeDropdown[count] ? degreeDropdown[count] : ''}
            placeholder="Pilih Gelar"
            isLoading={degreeLoad}
            onInputChange={dataSearchDropdown =>
              setSearchDropdown({
                ...searchDropdown,
                degree: dataSearchDropdown,
              })
            }
            onChange={event => addDataDegree(event, count)}
            styles={{
              menu: provided => ({...provided, zIndex: 9999}),
            }}
          />
          <Select
            className={classNames(
              classes.width80P,
              classes.marginTop10,
              classes.marginLeft10
            )}
            id="faculty"
            placeholder="Pilih Fakultas"
            options={facultys}
            value={facultyDropdown[count] ? facultyDropdown[count] : ''}
            isLoading={facultyLoad}
            onInputChange={dataSearchDropdown =>
              setSearchDropdown({
                ...searchDropdown,
                faculty: dataSearchDropdown,
              })
            }
            onChange={event => addDataFaculty(event, count)}
            styles={{
              menu: provided => ({...provided, zIndex: 9999}),
            }}
          />
          {index === 0 ? (
            ''
          ) : (
            <LitleRedText
              onClick={() => delTextFieldEducation(count)}
              className={classes.marginLeft10}
            >
              Hapus
            </LitleRedText>
          )}
        </FlexStart>
      )
    }
    return htmlEducation
  }

  // const course = index => {
  //   const htmlSelectCourse = []
  //   for (let count = 0; index >= count; count++) {
  //     htmlSelectCourse.push(
  //       <FlexStart key={count}>
  //         <Select
  //           className={classNames(classes.width80P, classes.marginTop10)}
  //           id="course"
  //           placeholder="Select Course"
  //           options={coursess}
  //           value={courseDropdown[count] ? courseDropdown[count] : ''}
  // isLoading={coursesLoad}
  // onInputChange={dataSearchDropdown =>
  //   setSearchDropdown({
  //     ...searchDropdown,
  //     course: dataSearchDropdown,
  //   })
  // }
  //           onChange={event => addDataCourse(event, count)}
  //           styles={{
  //             menu: provided => ({...provided, zIndex: 9999}),
  //           }}
  //         />
  //         {index === 0 ? (
  //           ''
  //         ) : (
  //           <LitleRedText
  //             onClick={() => delTextFieldCourse(count)}
  //             className={classes.marginLeft10}
  //           >
  //             Remove
  //           </LitleRedText>
  //         )}
  //       </FlexStart>
  //     )
  //   }
  //   return htmlSelectCourse
  // }

  return (
    <OrganizationContainer>
      <FormContainer id="top">
        <FormChildContainer className={classes.width90P}>
          <FormChildTitle>
            <TitleNumber id="competency">1.</TitleNumber>
            <TitleName>Model Kompetensi</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {competencyModel(countCompetency)}
            <LitleLightBlueText
              onClick={addTextFieldCompetency}
              className={classes.marginTop10}
            >
              Tambah
            </LitleLightBlueText>
            {/* <TextHelper className={classes.margin0}>
              {(typeof allDatas.IdCompetency === 'undefined' && isError) ||
              (allDatas.IdCompetency && allDatas.IdCompetency.length === 0)
                ? 'This field is required'
                : ''}
            </TextHelper> */}
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer className={classes.width80P}>
          <FormChildTitle>
            <TitleNumber id="salary">2.</TitleNumber>
            <TitleName>Rentang Gaji</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <FlexStart>
              <TextField
                type="text"
                // error={!allDatas.salary_from && isError}
                // helperText={
                //   !allDatas.salary_from && isError
                //     ? 'This field is required'
                //     : ''
                // }
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  classes: {
                    input: classes.outlinedInput,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <RpContainer>Rp</RpContainer>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                margin="normal"
                value={
                  allDatas.salary_from
                    ? allDatas.salary_from
                    : status === 'Edit'
                    ? dataEdit.rate_salary_from
                    : ''
                }
                onChange={event => {
                  setAllData({
                    ...allDatas,
                    salary_from: convertToCurrency(event.target.value),
                  })
                }}
                variant="outlined"
              />
              <LitleGreyText className={classes.marginLeftRight10}>
                sampai
              </LitleGreyText>
              <TextField
                id="salaryto"
                type="text"
                value={
                  allDatas.salary_to
                    ? allDatas.salary_to
                    : status === 'Edit'
                    ? dataEdit.rate_salary_to
                    : ''
                }
                // error={!allDatas.salary_to && isError}
                // helperText={
                //   !allDatas.salary_to && isError ? 'This field is required' : ''
                // }
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  classes: {
                    input: classes.outlinedInput,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <RpContainer>Rp</RpContainer>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                className={classes.marginLeft10}
                margin="normal"
                onChange={event => {
                  setAllData({
                    ...allDatas,
                    salary_to: convertToCurrency(event.target.value),
                  })
                }}
                variant="outlined"
              />
            </FlexStart>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer className={classes.width30P}>
          <FormChildTitle>
            <TitleNumber id="slot">3.</TitleNumber>
            <TitleName>Analisis Beban Kerja *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <FlexStart>
              <TextField
                id="jobProfile"
                type="number"
                error={!allDatas.slot && isError}
                helperText={
                  !allDatas.slot && isError ? 'Bagian ini harus diisi' : ''
                }
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  classes: {
                    input: classes.outlinedInput,
                  },
                }}
                min="0"
                value={
                  allDatas.slot
                    ? allDatas.slot
                    : status === 'Edit' && allDatas.slot
                    ? allDatas.slot
                    : status === 'Edit'
                    ? dataEdit.slots
                    : ''
                }
                fullWidth
                margin="normal"
                onChange={event => {
                  setAllData({
                    ...allDatas,
                    slot: event.target.value,
                  })
                }}
                variant="outlined"
              />
              <LitleGreyText className={classes.marginLeft10}>
                Orang
              </LitleGreyText>
            </FlexStart>
            <TextHelper className={classes.margin0}>
              {allDatas.slot === 0 && isError ? 'Bagian ini harus diisi' : ''}
            </TextHelper>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer className={classes.width90P}>
          <FormChildTitle>
            <TitleNumber id="education">4.</TitleNumber>
            <TitleName>Pendidikan</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {education(countEducation)}
            <LitleLightBlueText
              onClick={addTextFieldEducation}
              className={classes.marginTop10}
            >
              Tambah
            </LitleLightBlueText>
            {/* <TextHelper className={classes.margin0}>
              {(typeof allDatas.IdEducation === 'undefined' && isError) ||
              (allDatas.IdEducation && allDatas.IdEducation.length === 0)
                ? 'This field is required'
                : ''}
            </TextHelper> */}
          </FormChildInput>
        </FormChildContainer>
        {/* <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Recommended Course</TitleName>
          </FormChildTitle>
          <FormChildInput>
            {course(countCourse)}
            <LitleLightBlueText
              onClick={addTextFieldCourse}
              className={classes.marginTop10}
            >
              Add More
            </LitleLightBlueText>
          </FormChildInput>
        </FormChildContainer> */}
      </FormContainer>
    </OrganizationContainer>
  )
}

export default withRouter(TabTalent)
