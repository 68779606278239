import gql from 'graphql-tag'

export const GET_DETAIL_HOLDING_NOMINATION = gql`
  query getDataDetailNomination($id_nomination: Int, $company_head: uuid) {
    detail: holding_nomination_config(where: {id: {_eq: $id_nomination}}) {
      id
      name
      description
      company_job_profile {
        id
        title
      }
      company_profile {
        id
        legal_name
        brand_name
        logo
      }
    }

    current_incumbent: holding_nomination_config(
      where: {id: {_eq: $id_nomination}}
    ) {
      company_job_profile {
        people_work_placements {
          id
          regno
          start_date
          end_date
          placement_fields
          global_user {
            id
            name
            avatar
            talent_assignments {
              id
              talent_score
              talentVersionByTalentVersion {
                id
                name
                start_date
                end_date
                indicator_perf_nps
                indicator_perf_ogf
                indicator_perf_goal
                indicator_perf_multirater
                indicator_pot_attendance
                indicator_pot_competence
                indicator_pot_socmed_post
                indicator_pot_total_course
                indicator_perf_nps_fields
                indicator_perf_ogf_fields
                indicator_perf_goal_fields
                indicator_perf_multirater_fields
                indicator_pot_attendance_fields
                indicator_pot_competence_fields
                indicator_pot_socmed_post_fields
                indicator_pot_total_course_fields
                box_width
                box_height
                talent_boxes {
                  id
                  name
                  index_x
                  index_y
                  badge
                  color_hex
                  description
                }
              }
              indicator_pot_competence
              indicator_pot_attendance
              indicator_pot_total_course
              indicator_pot_socmed_post
              indicator_pot_experience
              indicator_perf_ogf
              indicator_perf_goal
              indicator_perf_multirater
              indicator_perf_nps
              status
              talent_box {
                id
                name
                index_x
                index_y
                badge
                color_hex
                description
              }
            }
          }
        }
      }
    }

    successor: holding_nomination_people(
      where: {
        candidate_status: {_eq: "is_successor"}
        nomination_id: {_eq: $id_nomination}
      }
    ) {
      id
      status
      holding_source {
        people_work_placement {
          id
          regno
          start_date
          end_date
          global_user {
            id
            name
            avatar
          }
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            legal_name
            brand_name
          }
        }
      }
    }

    talent_pool: holding_nomination_people(
      where: {
        candidate_status: {_eq: "is_talent"}
        nomination_id: {_eq: $id_nomination}
      }
    ) {
      id
      status
      holding_source {
        id
        people_work_placement {
          id
          regno
          start_date
          end_date
          global_user {
            id
            name
            avatar
          }
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            legal_name
            brand_name
          }
          talent_assignments {
            id
            talent_score
            talent_box {
              id
              index_x
              index_y
              name
            }
          }
        }
      }
    }
  }
`

export const GET_NOMINATION_BY_ID = gql`
  query getDataDetailNomination($id: Int) {
    holding_nomination_config(where: {id: {_eq: $id}}) {
      id
      name
      description
      status
      company_job_profile {
        id
        title
      }
      company_profile {
        id
        legal_name
        brand_name
        logo
        profile_field
      }
    }
  }
`
export const GET_COMMITTEE_BY_ID_NOMINATION = gql`
  query($id: Int) {
    holding_nomination_commite(where: {nomination_id: {_eq: $id}}) {
      id
      people_work_placement {
        id
        global_user {
          id
          name
          avatar
        }
        company_profile {
          id
          legal_name
          brand_name
        }
        company_job_profile {
          id
          title
        }
      }
    }
  }
`
