import React, {useEffect, useState} from 'react'
import ModalDetail from '../../../../../../shared-components/popup/ModalDetail'
import {ModalAddEditWraper} from '../../../DetailEmployeeStyles'
import {ButtonSubmit} from '../../../SharedDetailEmployee'
import FormInput from '../../ShareComponents-detail/FormInput'
import {useLazyQuery, useMutation} from '@apollo/react-hooks'
import {LIST_DIALECT, LIST_RACE} from '../../../../../../../graphql/queries'
import {
  getErrorForm,
  listMaritalStatus,
  optionNationaly,
  optionSalutation,
} from './aboutHelper'
import {UPDATE_ABOUT} from '../../../../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {GET_RELIGION_FILTER} from '../../../../../../../graphql/queries/filters/searchFilter'

export default function EditAbout({open, onClose, data, userId, refetch}) {
  const {enqueueSnackbar} = useSnackbar()

  const [isError, setIsError] = useState(false)
  const [search, setSearch] = useState({race: '', dialect: ''})
  const [values, setValues] = useState({
    fullname: '',
    gender: null,
    place_of_birth: '',
    birth_date: undefined,
    religion: null,
    religion_sect: '',
    marital_status: null,
    marital_date: undefined,
    marital_place: '',
    nationality: [],
    salutation: [],
    race: [],
    dialect: [],
  })

  const [updateAbout] = useMutation(UPDATE_ABOUT)

  const OPTIONS = {
    context: {headers: {'X-Hasura-Role': 'public'}},
  }

  const [getReligion, {data: dataReligion}] = useLazyQuery(
    GET_RELIGION_FILTER,
    OPTIONS
  )

  const [getRace, {data: dataRace, loading: loadingRace}] = useLazyQuery(
    LIST_RACE,
    OPTIONS
  )

  const [
    getDialect,
    {data: dataDialect, loading: loadingDialect},
  ] = useLazyQuery(LIST_DIALECT, OPTIONS)

  useEffect(() => {
    if (open) {
      getReligion()
      getRace()
      getDialect()
      if (data) {
        const {global_users, profile_about} = data
        setValues({
          fullname: global_users?.[0]?.name,
          gender: profile_about?.[0]?.gender,
          place_of_birth: profile_about?.[0]?.profile_fields?.place_of_birth,
          birth_date: profile_about?.[0]?.birthdate,
          religion: profile_about?.[0]?.global_religion?.id || '',
          religion_sect: profile_about?.[0]?.profile_fields?.religion_sect,
          marital_status: profile_about?.[0]?.marital_status,
          marital_place:
            profile_about?.[0]?.profile_fields?.marital_place || '',
          marital_date:
            profile_about?.[0]?.profile_fields?.marital_date || undefined,
          nationality: profile_about?.[0]?.profile_fields?.nationality?.map(
            res => ({value: res, label: res})
          ),
          salutation: profile_about?.[0]?.profile_fields?.salutation?.map(
            res => ({value: res, label: res})
          ),
          race: profile_about?.[0]?.profile_fields?.race?.map(race => ({
            value: race.id,
            label: race.name,
          })),
          dialect: profile_about?.[0]?.profile_fields?.dialect?.map(
            dialect => ({
              value: dialect.id,
              label: dialect.name,
            })
          ),
        })
      }
    }
  }, [open, data, getReligion])

  useEffect(() => {
    getRace({
      variables: {
        search: `%${search.race}%`,
        nin: values.race?.map(race => race.value) || null,
      },
    })
    getDialect({
      variables: {
        search: `%${search.dialect}%`,
        nin: values.dialect?.map(dialect => dialect.value) || null,
      },
    })
  }, [getRace, getDialect, search, values])

  const handleClose = () => {
    onClose()
  }

  const handleSave = () => {
    const isError = getErrorForm(values)
    if (isError) {
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 3000)
    } else {
      const submit = {
        id: userId,
        name: values.fullname,
        gender: values.gender,
        birthDate: values.birth_date,
        religion: values.religion,
        maritalStatus: values.marital_status,
        data: {
          place_of_birth: values.place_of_birth,
          nationality: values.nationality?.map(
            nationality => nationality.value
          ),
          salutation: values.salutation?.map(salutation => salutation.value),
          religion_sect: values.religion_sect,
          race: values.race?.map(race => ({
            id: race.value,
            name: race.label,
          })),
          dialect: values.dialect?.map(dialect => ({
            id: dialect.value,
            name: dialect.label,
          })),
          marital_place: values.marital_place || null,
          marital_date: values.marital_date,
        },
      }
      updateAbout({variables: submit})
        .then(() => {
          enqueueSnackbar('Update about success', {variant: 'success'})
          refetch()
          handleClose()
        })
        .catch(() => {
          enqueueSnackbar('Update about error, please try again later', {
            variant: 'error',
          })
        })
    }
  }

  const handleSearch = ({fieldName, search}) => {
    setSearch(prev => ({...prev, [fieldName]: search}))
  }

  const handleSetValues = args => {
    switch (args.fieldName) {
      case 'dialect':
      case 'race': {
        const {fieldName} = args
        setValues(prev => {
          const mapped = [...(prev?.[fieldName] || []), ...(args.arr || [])]
          const uniqueIds = []
          const newRace = mapped.filter(element => {
            const isDuplicate = uniqueIds.includes(element.value)
            if (!isDuplicate) {
              uniqueIds.push(element.value)
              return true
            }
            return false
          })
          return {
            ...prev,
            [fieldName]: newRace,
          }
        })
        break
      }
      default: {
        const {fieldName, value, label} = args
        setValues(prev => ({
          ...prev,
          [fieldName]: [...(prev?.[fieldName] || []), {value, label}],
        }))
        break
      }
    }
  }

  const handleDeleteChips = args => {
    const {fieldName, i} = args
    const newState = [...values?.[fieldName]]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValues({...values, [fieldName]: newState})
  }

  const fieldsList = [
    {
      type: 'textfield',
      label: 'Full Name*',
      fieldName: 'fullname',
      value: values.fullname,
      placeholder: 'Add Full Name',
      error: !values.fullname,
      required: true,
    },
    {
      label: 'Gender*',
      placeholder: 'Choose Gender',
      fieldName: 'gender',
      required: true,
      type: 'select',
      value: values.gender,
      error: !values.gender,
      option: [
        {value: 'F', name: 'Female'},
        {value: 'M', name: 'Male'},
      ],
    },
    {
      type: 'textfield',
      label: 'Place of Birth*',
      fieldName: 'place_of_birth',
      value: values.place_of_birth,
      placeholder: 'Add Place of Birth',
      error: !values.place_of_birth,
      required: true,
      style: {width: '60%'},
    },
    {
      type: 'dateCustom',
      label: ['Birth Date*'],
      filter: ['date'],
      fieldName: 'birth_date',
      required: true,
      value: values.birth_date,
      error: !values.birth_date,
    },
    {
      type: 'autocomplete',
      label: 'Nationality*',
      fieldName: 'nationality',
      value: undefined,
      placeholder: 'Choose Nationality',
      error: !values?.nationality || values?.nationality?.length === 0,
      required: true,
      isClearOnSelect: true,
      onChange: data => handleSetValues(data),
      onDeleteChips: data => handleDeleteChips(data),
      chips: values.nationality || [],
      option: [...optionNationaly].filter(
        elem => !values?.nationality?.find(({value}) => elem.value === value)
      ),
    },
    {
      type: 'autocomplete',
      label: 'Salutation*',
      fieldName: 'salutation',
      value: undefined,
      placeholder: 'Choose Salutation',
      error: !values?.salutation || values?.salutation?.length === 0,
      required: true,
      isClearOnSelect: true,
      onChange: data => handleSetValues(data),
      onDeleteChips: data => handleDeleteChips(data),
      chips: values.salutation || [],
      option: [...optionSalutation].filter(
        elem => !values?.salutation?.find(({value}) => elem.value === value)
      ),
    },
    {
      label: 'Religion*',
      placeholder: 'Choose Religion',
      fieldName: 'religion',
      required: true,
      type: 'select',
      value: values.religion,
      error: !values.religion,
      option: [
        {value: '', name: 'Choose Religion'},
        ...(dataReligion?.global_religions?.map(religion => ({
          value: religion.id,
          name: religion.name,
        })) || []),
      ],
    },
    {
      type: 'textfield',
      label: 'Religion Sect',
      fieldName: 'religion_sect',
      value: values.religion_sect,
      placeholder: 'Add Religion Sect',
      error: !values.religion_sect,
    },
    {
      type: 'chips',
      label: 'Race*',
      fieldName: 'race',
      value: values.race || [],
      isLoading: loadingRace,
      placeholder: 'Choose Race',
      error: !values?.race || values?.race?.length === 0,
      required: true,
      onChange: data => handleSetValues(data),
      onInputChange: handleSearch,
      onDeleteChips: data => handleDeleteChips(data),
      option: dataRace?.global_race?.map(res => ({
        value: res.id,
        label: res.name,
      })),
    },
    {
      type: 'chips',
      label: 'Dialect',
      fieldName: 'dialect',
      value: values.dialect || [],
      isLoading: loadingDialect,
      placeholder: 'Choose Dialect',
      error: !values?.dialect && values?.dialect?.length === 0,
      onChange: data => handleSetValues(data),
      onInputChange: handleSearch,
      onDeleteChips: data => handleDeleteChips(data),
      option: dataDialect?.global_dialects?.map(res => ({
        value: res.id,
        label: res.name,
      })),
    },
    {
      label: 'Marital Status*',
      placeholder: 'Choose Marital Status',
      fieldName: 'marital_status',
      required: true,
      type: 'select',
      value: values.marital_status,
      error: !values.marital_status,
      option: listMaritalStatus,
    },
    {
      type: 'textfield',
      label: 'Marital Place',
      fieldName: 'marital_place',
      value: values.marital_place,
      placeholder: 'Add Marital Place',
      error: !values.marital_place,
    },
    {
      type: 'dateCustom',
      label: ['Marital Date'],
      filter: ['date'],
      fieldName: 'marital_date',
      required: false,
      value: values.marital_date,
      error: !values.marital_date,
    },
  ]

  return (
    <ModalDetail
      open={open}
      maxWidth="sm"
      onClose={handleClose}
      title="Edit About"
    >
      <ModalAddEditWraper>
        <FormInput
          fieldsList={fieldsList}
          setValues={setValues}
          values={values}
          errorState={isError}
          HELPER_TEXT="This field is required"
        />
      </ModalAddEditWraper>
      <ButtonSubmit onCancel={handleClose} onSave={handleSave} />
    </ModalDetail>
  )
}
