import gql from 'graphql-tag'

export const GET_LEAVE_SETTINGS = gql`
  query($company: uuid, $search: String, $limit: Int, $offset: Int) {
    time_leave_policies(
      where: {company: {_eq: $company}, name: {_ilike: $search}}
      order_by: [{name: asc}]
      limit: $limit
      offset: $offset
    ) {
      id
      name
      is_unpaid
      calc_method
      quota_reset_option_month
      quota_reset_option_date
      quota_reset_option
      quota_effective_after_month
      quota_prorate_option
      label_color
      text_color
      allow_leave_debt
      leftover_type
      leftover_valid_max
      leftover_valid_until_month
      leftover_money_settings
      time_leave_policy_children {
        id
        name
        quota
        infinite_quota
        total_job_profile_r_leaves: company_job_profile_r_leaves_aggregate(
          where: {company_job_profile: {deletedAt: {_is_null: true}}}
        ) {
          aggregate {
            count
          }
        }
        company_job_profile_r_leaves(
          limit: 20
          where: {company_job_profile: {deletedAt: {_is_null: true}}}
        ) {
          company_job_profile {
            id
            title
          }
        }
      }
    }
    time_leave_policies_aggregate(where: {name: {_ilike: $search}}) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LEAVE_PARENT_POLICY_NAME = gql`
  query leaveParentPolName($name: String, $limit: Int) {
    time_leave_policies(
      where: { name: { _ilike: $name } }
      order_by: [{ name: asc }]
      limit: $limit
    ) {
      id
      name
    }
  }
`

export const GET_LEAVE_CHILD_POLICY_NAME = gql`
  query leaveChildPolName($name: String) {
    time_leave_policies(
      where: {time_leave_policy_children: {name: {_ilike: $name}}}
    ) {
      id
      time_leave_policy_children {
        id
        name
      }
    }
  }
`
