import React, {useState, useEffect} from 'react'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Button,
  TextField,
  InputAdornment,
  Paper,
  Icon,
} from '@material-ui/core'
import {TextBlackBold, FlexBetween, Flex} from '../../../../../GlobalStyles'
import {
  TableOptions,
  TableLocalization,
} from '../../../../shared-components/table/TableOptions'
import {GET_JOB_PROFILE_DROPDOWN} from '../../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../../../../utils/globals'
import MaterialTable from 'material-table'
import SearchIcon from '@material-ui/icons/Search'
import ModalDetail from '../../../../shared-components/popup/ModalDetail'
import ModalAddCandidate from './detail-component/ModalAddCandidate'
import Loading from '../../../../../components/loading/LoadingComponent'

const useStyles = makeStyles(() => ({
  my20: {
    margin: '20px 0px',
  },
  py20: {
    padding: '20px 0px',
  },
  icRed: {
    color: '#ef4d5e',
    cursor: 'pointer',
  },
  nolistWrapper: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentNoList: {
    textAlign: 'center',
    color: '#a9a8a8 !important',
    width: '30%',
    padding: '10px 0px',
  },
}))

const StepThreeTalent = props => {
  const classes = useStyles()
  const {
    values,
    setValues,
    candidate,
    setCandidate,
    employee,
    setEmployee,
    isError,
  } = props
  const [radioVal, setRadioVal] = useState('custom')
  const [positionSearch, setPositionSearch] = useState('')
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [position, setPosition] = useState([])

  const {data, error} = useQuery(GET_JOB_PROFILE_DROPDOWN, {
    variables: {
      company: COMPANY_ID,
      //   search: `%${searchText}%`,
      //   limit: 30,
    },
  })

  const handlePositionSearch = event => {
    setPositionSearch(event.target.value)
  }

  const handleDel = (val, i) => {
    const candidateGroup = values.talent_candidate_groups.data
    const newDataCandidate = candidate.filter(v => v !== val)
    const newData = candidateGroup.filter(v => v.job_profile !== val.value)

    const array = position
    const index = array.findIndex(item => item.id === val.value)
    array[index] = {...array[index], checked: false}
    setPosition([...array])

    setCandidate(newDataCandidate)
    employee.splice(i, 1)
    setEmployee(employee)
    setValues({
      ...values,
      talent_candidate_groups: {data: newData},
    })
  }

  const handleSave = val => {
    const newData = []
    val.map(res => {
      newData.push({
        job_profile: res.id,
        committee_email_notif_template: '',
        committee_date_start: values.start_date,
        committee_date_end: values.end_date,
      })
    })

    const _candidate = []
    val.map(e => {
      _candidate.push({
        value: e.id,
        label: e.title,
      })
    })

    setValues({
      ...values,
      talent_candidate_groups: {data: newData},
    })
    setCandidate(_candidate)
    setIsLoading(false)
  }

  const handleChangeRadio = event => {
    setRadioVal(event.target.value)
    setIsLoading(true)
    const _position = []
    position.map(val => {
      _position.push({
        ...val,
        checked: event.target.value === 'all',
      })
    })
    setPosition(_position)
    handleSave(event.target.value === 'all' ? _position : [])
  }

  const NoListComponent = () => {
    return (
      <Flex column className={classes.nolistWrapper}>
        <img
          src={require('../../../../../assets/images/nodatalist.png')}
          width="250px"
          height="auto"
          alt="no list"
          className={classes.my20}
        />
        <TextBlackBold>Maaf, Tidak Ada Daftar</TextBlackBold>
        <TextBlackBold className={classes.contentNoList}>
          Silakan pilih terlebih dahulu posisi kandidat yang akan ditambahkan
          dengan tombol{' '}
          <span
            style={{color: '#039be5', cursor: 'pointer'}}
            onClick={() => setOpen(true)}
          >
            Tambah Kandidat
          </span>{' '}
          atau tombol di bawah ini
        </TextBlackBold>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            className={classes.my20}
            style={{width: 'auto'}}
          >
            Tambah Kandidat
          </Button>
        </div>
      </Flex>
    )
  }

  useEffect(() => {
    if (data) {
      const _pos = []
      data.company_job_profiles.map(val => {
        const found = candidate.find(element => element.value === val.id)
        _pos.push({
          ...val,
          checked: !!found,
        })
      })
      setPosition(_pos)
    }
  }, [data])

  if (data === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <div>
      <TextBlackBold>Metode untuk menambah kandidat</TextBlackBold>
      <RadioGroup
        row
        name="position"
        value={radioVal}
        onChange={handleChangeRadio}
        className={classes.py20}
      >
        <FormControlLabel
          value="custom"
          control={<Radio color="primary" />}
          label={<TextBlackBold>Pilih Kustom</TextBlackBold>}
        />
        <FormControlLabel
          value="all"
          control={<Radio color="primary" />}
          label={
            <TextBlackBold>
              Pilih Semua (Mengizinkan untuk mengedit)
            </TextBlackBold>
          }
        />
      </RadioGroup>
      <FlexBetween>
        <TextBlackBold>
          Berlaku untuk {candidate.length} Posisi
          {values.talent_candidate_groups.data.length === 0 && isError && (
            <span style={{color: 'red', marginLeft: 6, fontStyle: 'italic'}}>
              *Silakan pilih setidaknya satu kandidat
            </span>
          )}
        </TextBlackBold>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          {radioVal === 'custom' ? 'Tambah Kandidat' : 'Filter'}
        </Button>
      </FlexBetween>
      <TextField
        variant="outlined"
        placeholder="Cari"
        size="small"
        fullWidth
        className={classes.py20}
        onChange={handlePositionSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {candidate.length > 0 ? (
        <MaterialTable
          style={{padding: '1.6rem 1.6rem 0'}}
          columns={[
            // {
            //   title: 'Position Code',
            //   field: 'position_code',
            // },
            {
              title: 'Jabatan',
              field: 'label',
            },
            {
              title: 'Aksi',
              field: 'action',
              render: (row, i) => (
                <Icon
                  className={classes.icRed}
                  onClick={() => handleDel(row, i)}
                >
                  delete
                </Icon>
              ),
            },
          ]}
          data={candidate.filter(item => {
            return item.label
              .toLowerCase()
              .includes(positionSearch.toLowerCase())
          })}
          title=""
          isLoading={isLoading}
          options={{
            ...TableOptions,
            selection: false,
            emptyRowsWhenPaging: false,
          }}
          components={{
            Toolbar: () => null,
            Container: props => <Paper {...props} elevation={0} />,
          }}
          localization={TableLocalization}
        />
      ) : (
        <NoListComponent />
      )}

      <ModalDetail
        onClose={() => setOpen(false)}
        open={open}
        title="Include"
        maxWidth="md"
      >
        <ModalAddCandidate
          handleSave={handleSave}
          position={position}
          setPosition={setPosition}
          setOpen={setOpen}
        />
      </ModalDetail>
    </div>
  )
}

export default StepThreeTalent
