import {makeStyles, TextField} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  inputStyle: {
    padding: '15px 18px',
  },
  inputStyleTextArea: {
    padding: 0,
  },
  label: {
    color: '#a9a8a8',
    fontSize: 12,
    margin: 0,
    marginBottom: 17,
  },
  root: {
    marginBottom: 32,
  },
}))

export const TextFieldForm = props => {
  const {label, endAdornment, multiline = false, inputProps, ...rest} = props

  const classes = useStyles()

  return (
    <div className={classes.root}>
      {label && <p className={classes.label}>{label}</p>}
      <TextField
        variant="outlined"
        fullWidth
        InputProps={{
          classes: {
            input: multiline ? classes.inputStyleTextArea : classes.inputStyle,
          },
          inputProps,
        }}
        multiline={multiline}
        {...rest}
      />
      {endAdornment}
    </div>
  )
}
