import {useQuery} from '@apollo/react-hooks'
import DateFnsUtils from '@date-io/date-fns'
import {Tab, Tabs, Button} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import MaterialTable from 'material-table'
import moment from 'moment'
import numeral from 'numeral'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {GenerateTooltip, RootContainer} from '../../../../GlobalStyles'
import {GET_APPROVAL_LIST, GET_OVER_ACTIVITY} from '../../../../graphql/queries'
import {TableOptionsTime} from '../../../shared-components/table/TableOptions'
import ModalDetailActivity from './detail-activity/ModalDetailActivity'
import AddEdit from '../../../shared-components/popup/AddEditPopup'
import useDownloadReport from '../../../../hooks/useDownloadReport'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import {getOvertimeActivityFilterVariables} from '../filter'
import {compensationType} from './detail-activity/helperOvertime'

const TEXT_TOOLTIP = `You can only generate data once a day. to download data use the link that already generated`
const TABLE_REPORT_NAME = 'people_work_overtimes'

const INITIAL_DATA_PAGINATION = {
  offset: 0,
  limit: 20,
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    // marginLeft: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },

  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
  },
  title: {
    flex: '0 0 auto',
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginleft: theme.spacing(3),
  },
  customDatePicker: {
    width: '10rem',
    marginRight: 12,
  },
}))

const EnhancedTableToolbarHeader = props => {
  const classes = useToolbarStyles()

  const {
    handleDownload,
    startDate,
    onSelectedStartDate,
    onSelectedEndDate,
    endDate,
    isDisable,
  } = props

  return (
    <Toolbar>
      <div className={classes.title}>
        <Typography style={{fontSize: 18}} variant="h6" id="tableTitle">
          Overtime Activity
        </Typography>
      </div>

      <div className={classes.spacer} />
      <div className={classes.actions}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            format="MMM dd, yyyy"
            id="start-date"
            label="Start Date"
            value={new Date(startDate)}
            onChange={onSelectedStartDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              size: 'small',
            }}
            className={classes.customDatePicker}
          />
          <KeyboardDatePicker
            inputVariant="outlined"
            format="MMM dd, yyyy"
            id="end-date"
            label="End Date"
            value={new Date(endDate)}
            onChange={onSelectedEndDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              size: 'small',
            }}
            className={classes.customDatePicker}
          />
        </MuiPickersUtilsProvider>
        <GenerateTooltip title={isDisable ? TEXT_TOOLTIP : ``} placement="left">
          <div>
            <Button
              style={{height: '100%', width: 147}}
              variant="contained"
              color="primary"
              size="large"
              onClick={handleDownload}
              disabled={isDisable}
            >
              Generate Data
            </Button>
          </div>
        </GenerateTooltip>
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbarHeader.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: 60,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  selectFilter: {marginRight: 12, minWidth: '19%'},
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
}))

const Activity = props => {
  const {searchText, filterData} = props

  const classes = useStyles()
  const [selected] = useState([])
  const [startDate, setStartDate] = useState(
    `${moment().format('YYYY')}-${moment().format('MM')}-01`
  )
  const dateEnd = moment()
    .endOf('month')
    .format('YYYY-MM-DD')
  const [endDate, setEndDate] = useState(dateEnd)

  const [tabsValue, setTabsValue] = useState('activity')
  const [open, setOpen] = useState(false)
  const [type, setType] = useState(0)
  const [selectedItem, setSelectedItem] = React.useState([])
  const [compensation, setCompensation] = useState('')
  const [status, setStatus] = useState('')
  const [pagination, setPagination] = useState(INITIAL_DATA_PAGINATION)

  const [openDownload, setOpenDownload] = useState(false)

  const {dataBaloon, isDisableButton, onDownload} = useDownloadReport({
    table: TABLE_REPORT_NAME,
  })

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      offset: 0,
      limit: row,
    })
  }

  const where = React.useMemo(() => {
    return getOvertimeActivityFilterVariables({
      tabsValue,

      startDate: startDate,
      endDate: moment(endDate)
        .add(1, 'days')
        .format('YYYY-MM-DD'),

      searchText,
      filterData,
    })
  }, [searchText, filterData, tabsValue, startDate, endDate])

  const {data, error, loading: loadingActivity, refetch} = useQuery(
    GET_OVER_ACTIVITY,
    {
      wlb_skipPatch: true,
      skip: tabsValue !== 'activity',
      variables: {
        where: where,
        offset: pagination.offset * pagination.limit,
        limit: pagination.limit,
      },
    }
  )

  const {
    data: dataRequests,
    error: errorRequest,
    loading: loadingRequest,
    refetch: refetchRequest,
  } = useQuery(GET_APPROVAL_LIST, {
    wlb_skipPatch: true,
    skip: tabsValue !== 'request',
    variables: {
      where: where,
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
    },
  })

  const handleClickOpen = (item, compensation, status) => {
    setSelectedItem(item)
    setOpen(true)
    setType(tabsValue)
    setCompensation(compensation)
    setStatus(status)
  }
  const handleClose = () => {
    setOpen(false)
  }

  if (error !== undefined) {
    return <div>{JSON.stringify(error, 0, 2)}</div>
  }

  if (errorRequest !== undefined) {
    return <div>{JSON.stringify(errorRequest, 0, 2)}</div>
  }

  const dataTable =
    tabsValue === 'activity'
      ? data && data.people_work_overtimes
      : dataRequests && dataRequests.people_work_overtimes
  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
    setPagination(INITIAL_DATA_PAGINATION)
    props.setActiveMenuTab(newValue)
  }

  const getDuration = row =>
    numeral(
      Math.abs(
        moment
          .duration(moment(row.datestart).diff(moment(row.dateend)))
          .asHours()
      )
    ).format('0,0.0')

  const approvalStatus = {
    approved: 'Approved',
    rejected: 'Rejected',
    confirm1: 'Waiting for Second Approval',
    pending: 'Waiting',
    cancelled: 'Cancelled',
  }

  const getStatus = row => approvalStatus[row.status]

  const handleDownload = () => {
    setOpenDownload(prev => !prev)
  }

  const handleDownloadData = (start_date, end_date) => {
    onDownload({
      variables: {
        table: TABLE_REPORT_NAME,
        filter: {
          start_date,
          end_date,
        },
      },
    })
  }

  return (
    <RootContainer>
      <ExportBaloon isLastGenerateStatus data={dataBaloon} />
      <Paper className={classes.paper}>
        <EnhancedTableToolbarHeader
          numSelected={selected.length}
          startDate={startDate}
          onSelectedStartDate={date =>
            setStartDate(moment(date).format('YYYY-MM-DD'))
          }
          onSelectedEndDate={date =>
            setEndDate(moment(date).format('YYYY-MM-DD'))
          }
          endDate={endDate}
          handleDownload={handleDownload}
          isDisable={isDisableButton}
        />
        <Tabs
          value={tabsValue}
          onChange={handleChangeTabs}
          indicatorColor="secondary"
          classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
        >
          <Tab label="Activity" value="activity" className={classes.tabRoot} />
          <Tab label="Request" value="request" className={classes.tabRoot} />
        </Tabs>
        <MaterialTable
          style={{paddingTop: '1.6rem ', position: 'relative'}}
          columns={[
            {
              title: 'Request ID',
              field: 'id_display',
              cellStyle: {minWidth: 200},
            },
            {
              title: 'Employee ID',
              field: 'people_work_placement.regno',
              cellStyle: {minWidth: 160},
            },
            {
              title: 'Employee Name',
              field: 'people_work_placement.global_user.name',
              cellStyle: {minWidth: 180},
            },
            {
              title: 'Overtime Date',
              field: 'datestart',
              render: row => moment(row.datestart).format('LL'),
              cellStyle: {minWidth: 180},
            },
            {
              title: 'Duration',
              customSort: (a, b) => getDuration(a) - getDuration(b),
              render: row => getDuration(row) + ' Hours',
            },
            {
              title: 'Compensation',
              render: row => compensationType[row.convert_to],
              cellStyle: {minWidth: 160},
            },
            {
              title: 'Type',
              field: 'time_overtime_policy.name',
              cellStyle: {minWidth: 160},
            },
            {
              title: 'Status',
              field: 'status',
              render: row => getStatus(row),
            },
            {
              title: 'Action',
              sorting: false,
              render: row => (
                <Typography
                  color="secondary"
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    marginLeft: 3,
                    wordBreak: 'initial',
                  }}
                  onClick={() =>
                    handleClickOpen(
                      row,
                      compensationType[row.convert_to],
                      getStatus(row)
                    )
                  }
                >
                  {tabsValue === 'activity' ? 'Detail' : 'Process'}
                </Typography>
              ),
            },
          ]}
          data={dataTable}
          title=""
          options={{...TableOptionsTime, emptyRowsWhenPaging: false}}
          components={{
            Toolbar: () => null,
            Container: props => <Paper elevation={0} {...props}></Paper>,
          }}
          isLoading={
            tabsValue === 'activity' ? loadingActivity : loadingRequest
          }
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
          totalCount={
            tabsValue === 'activity'
              ? data && data.total.aggregate.count
              : dataRequests && dataRequests.total.aggregate.count
          }
          page={pagination.offset}
          pageSize={pagination.limit}
        />
      </Paper>

      <ModalDetailActivity
        type={type}
        open={open}
        selectedItem={selectedItem}
        handleClose={handleClose}
        handleOpenActivity={() =>
          handleClickOpen(selectedItem, compensation, status)
        }
        compensation={compensation}
        status={status}
        refetch={refetch}
        refetchRequest={refetchRequest}
      />

      <AddEdit
        open={openDownload}
        handleClose={handleDownload}
        type="download"
        feature="Overtime Activity"
        mutation={() => handleDownloadData(startDate, endDate)}
      />
    </RootContainer>
  )
}
export default Activity
