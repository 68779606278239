import styled from 'styled-components'
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  TextField,
  Avatar,
  Paper,
  DialogContent,
} from '@material-ui/core'

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
`

export const WrapperIconDrag = styled.div`
  margin-left: 30px;
`

export const WrapperAction = styled.div`
  margin-left: auto;
  display: flex;
`
export const MLAuto = styled.div`
  margin-left: auto;
`
export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ButtonStyled = styled(Button)`
  padding: 14px 12px;
  font-size: 12px;
  margin-right: 11px;
`
export const ButtonStyledNPS = styled(Button)`
  padding: 14px 12px;
  font-size: 12px;
  margin-right: 11px;
  background-color: #e5e5e5;
`

export const TitleExpansion = styled(Typography)`
  font-weight: 600;
`

export const ButtonQuestionStyled = styled(Button)`
  padding: 10px 22px;
  font-size: 16px;
  width: 230px;
  margin-bottom: 25px;
`

export const ExpansionPanelStyle = styled(ExpansionPanel)`
  background-color: #f7f8f9;
  box-shadow: none;
  margin-bottom: 30px !important;
  padding: 1px 22px;
  border: ${props => props.border || 'none'};
  &::before {
    opacity: 0;
  }
  border-radius: 5px;
`

export const QuestionStyled = styled.div`
  background-color: #f7f8f9;
  margin-bottom: 30px;
  padding: 20px 46px;
  border-radius: 5px;
`

export const ExpansionPanelSummaryStyle = styled(ExpansionPanelSummary)`
  background-color: #f7f8f9;
  box-shadow: none;
  margin: 10px 0px;
  font-weight: 600;
`

export const ExpansionPanelDetailStyle = styled(ExpansionPanelDetails)`
  box-shadow: none;
  background-color: #f7f8f9;
  margin-bottom: 30px;
`

export const ContentExpansion = styled.div`
  font-size: 14px;
  font-family: muli;
  height: auto;
  line-height: 20px;
  color: #000;
  background-color: #fff;
  padding: 20px 40px;
  width: auto;
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 20px;
  border-radius: 5px;
`

// export const ContentExpansion = styled(Typography)`
//   font-size: 14px;
//   font-family: muli;
//   height: auto;
//   line-height: 20px;
//   color: #000;
//   background-color: #fff;
//   padding: 20px 40px;
//   width: auto;
//   display: flex;
//   flex-direction: row;
//   align-content: center;
//   margin-bottom: 20px;
//   border-radius: 5px;
// `

export const ContentQuestionExpansion = styled.div`
  height: auto;
  background-color: #fff;
  padding: 20px 40px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-bottom: 20px;
  border-radius: 5px;
`
export const SurveyField = styled.div`
  height: auto;
  background-color: #fff;
  padding: 30px 40px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  border-radius: 5px;
`

export const HeaderQuestion = styled(Typography)`
  font-size: 14px;
  font-family: muli;
  margin-bottom: 30px;
`
export const HeaderNPSQuestion = styled(Typography)`
  font-size: 16px;
  font-family: muli;
  margin-bottom: 30px;
`
export const NPSWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ActionWrapper = styled.div`
  margin-top: 40px;
  margin-left: auto;
`
export const ActionButton = styled(Button)`
  margin-left: 30px;
`
export const ActionButtonGreen = styled(Button)`
  margin-left: 15px;
  background-color: #4caf50;
  width: 48px;
  height: 48px;
`
export const ActionButtonRed = styled(Button)`
  margin-left: 15px;
  background-color: #ef4d5e;
  width: 48px;
  height: 48px;
`

export const StyledForm = styled(FormControl)`
  margin-bottom: 30px;
`

export const ButtonActionWrapper = styled.div`
  margin-left: auto;
  width: 158px;
  margin-top: -20px;
  margin-bottom: 10px;
`
export const ButtonActionModal = styled(Button)`
  font-size: 11px;
  margin-left: 10px;
`
export const ListModal = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 5px;
  border-bottom: 0.5px solid #a9a8ab;
  justify-content: space-between;
`

export const ListModalAction = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`

export const TextAdded = styled(Typography)`
  font-size: 11px;
  display: flex;
  align-content: center;
  margin: auto;
`

export const QuestionText = styled(Typography)`
  font-size: 16px;
  margin-bottom: 7px;
`
export const TypeText = styled(Typography)`
  font-size: 14px;
  color: #a9a8ab;
`
export const ButtonActionListModal = styled(Button)`
  font-size: 14px;
  padding: 6px 50px;
`

export const DialogTitleStyled = styled.div`
  background-color: #014a62;
  padding: 20px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 30px;
`

export const DialogTitleTeksStyled = styled(Typography)`
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
`

export const ButtonAction = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  margin: 24px 24px 24px 0px;
  padding: 10px 24px;
`

export const SelectStyled = styled(Select)`
  margin: 20px 0px;
`

export const FooterEditor = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 0px 0px 5px 5px;
  background-color: #014a62;
`

export const RowSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const FormControlStyled = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FormControlWrapper = styled(FormControl)`
  width: 100%;
  margin-left: 20px;
`

export const TextDropdown = styled(TextField)`
  margin: 0px;
  width: 250px;
  height: 50px !important;
`
export const TextDropdown2 = styled(TextField)`
  margin: 0px;
  width: 250px;
  margin-left: auto;
`
export const TextSearch = styled(OutlinedInput)`
  margin-left: 10px;
  width: 100%;
`
export const RespondentsTitle = styled(Typography)`
  color: #a9a8a8;
  font-size: 16px;
  margin: 50px 0px 0px 0px;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(169, 168, 171, 0.3);
  padding: 30px 0px;
`
export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0px;
`
export const Row3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 20px;
`
export const Information = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`
export const AvatarProfile = styled(Avatar)`
  width: 75px !important;
  height: 75px !important;
  margin-left: 30px !important;
`
export const NameRespondents = styled(Typography)`
  font-size: 16px;
  margin-bottom: 5px;
`
export const PositionRespondents = styled(Typography)`
  font-size: 16px;
  color: #a9a8ab;
  line-height: 20px;
`

export const FormChildTitle2 = styled.div`
  display: flex;
  margin-bottom: 12px;
`
export const OptionWrapper = styled.div`
  padding: 10px 40px;
`

export const AvatarMenu = styled(Avatar)`
  width: 75px !important;
  height: 75px !important;
`
export const IconButtonStyled = styled(Button)`
  margin-left: auto;
  background-color: #039be5;
  width: 40px;
  height: 40px;
  padding: 0px;
`

export const DetailSurveyWrapper = styled.div`
  margin: 0px auto;
  width: 85%;
  border-radius: 5px;
  background-color: #eff2f4;
  padding: 30px 20px 10px 20px;
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const RespondentsWrapper = styled.div`
  display: inline-block;
  width: 48%;
  margin-bottom: 30px;
`

export const UserInfo = styled(Paper)`
  display: flex;
  flex-direction: row;
  padding: 20px 30px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
`

export const AvatarRespondent = styled(Avatar)`
  width: 90px !important;
  height: 90px !important;
`
export const RespondenInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
`

export const NameRespondentsDialog = styled(Typography)`
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;
`

export const RespondentDetail = styled(Typography)`
  font-size: 14px;
  color: #a9a8a8;
`

export const DialogContentStyled = styled(DialogContent)`
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background: #a9a8a8;
    border-radius: 5px;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const TextFieldWrapper = styled.div`
  background-color: #ffffff;
  padding: 5px 20px;
  width: 100%;
  margin: 0px 20px;
`
