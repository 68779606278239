import gql from 'graphql-tag'

export const ADD_JOB_FUNCTION = gql`
  mutation($objects: [job_family_functions_insert_input!]!) {
    insert_job_family_functions(objects: $objects) {
      affected_rows
    }
  }
`

export const EDIT_JOB_FUNCTION = gql`
  mutation($id: Int!, $object: job_family_functions_set_input!) {
    update_job_family_functions_by_pk(_set: $object, pk_columns: {id: $id}) {
      id
    }
  }
`

export const DELETE_JOB_FUNCTION = gql`
  mutation($id: Int!, $companyId: uuid!) {
    update_job_family_functions(
      _set: {deletedAt: "now()"}
      where: {
        _and: [
          {id: {_eq: $id}}
          {company: {_eq: $companyId}}
          {deletedAt: {_is_null: true}}
        ]
      }
    ) {
      affected_rows
    }
  }
`
