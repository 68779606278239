import gql from 'graphql-tag'

export const EDIT_TASK = gql`
  mutation updateTask(
    $goal: bigint
    $name: String
    $description: String
    $target: float8
    $target_stretch: float8
    $weight: float8
    $aspect: Int
    $priority: enum_mpp_priority!
    $visibility: String
    $startdate: date
    $enddate: date
    $group: bigint
    $progress: float8
    # $status: String
    $id: bigint!
    $assignment: [performance_task_assignments_insert_input!]!
    $attachment: jsonb
  ) {
    update_performance_tasks(
      _set: {
        goal: $goal
        name: $name
        description: $description
        target: $target
        target_stretch: $target_stretch
        weight: $weight
        aspect: $aspect
        priority: $priority
        visibility: $visibility
        startdate: $startdate
        enddate: $enddate
        group: $group
        progress: $progress
        # status: $status
        attachment: $attachment
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }

    delete_performance_task_assignments(where: {task: {_eq: $id}}) {
      affected_rows
    }

    insert_performance_task_assignments(objects: $assignment) {
      affected_rows
    }
  }
`

export const UPDATE_GROUP_TASK = gql`
  mutation UpdateGroupTasks($idGroup: bigint!, $idTask: bigint!) {
    update_performance_tasks(
      _set: {group: $idGroup}
      where: {id: {_eq: $idTask}}
    ) {
      affected_rows
    }
  }
`

export const ADD_NEW_GROUP = gql`
  mutation addGroup($data: [performance_groups_insert_input!]!) {
    insert_performance_groups(objects: $data) {
      affected_rows
    }
  }
`
