import React from 'react'
import {
  // Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core'
import {Close} from '@material-ui/icons'

import {FlexBetween} from '../PayrollPageStyles'
import {convertToRoundedRupiah} from '../../../../utils/helpers'

const DetailComponentItem = props => {
  const {
    availableOptions,
    componentListMap,
    disabled,
    handleChangeItem,
    // isHideCheckBox,
    item,
    onDeleteItem,
  } = props
  // const [isChecked, setIsChecked] = useState(item.is_postponed)

  return (
    <TableRow>
      <TableCell>
        <FlexBetween>
          {/**
           * is_plugin_component: true indicates BPJS/PPH21 component,
           * and those component can not be delete, but able to adjust.
           */}
          {!item.is_plugin_component
            ? !disabled && (
                <IconButton onClick={() => onDeleteItem(item.id)}>
                  <Close color="primary" fontSize="small" />
                </IconButton>
              )
            : ''}
          {/**
           * If component exist in component list or component is new added,
           * then display on select option.
           * Else, display component name only on plain text.
           */}
          {(item.name === '' || componentListMap[item.id]) &&
          !item.is_plugin_component ? (
            <TextField
              select
              placeholder="Choose Component"
              value={`${item.id}-${item.name}` || ''}
              variant="outlined"
              size="small"
              style={{minWidth: '200px'}}
              onChange={e =>
                handleChangeItem(item.id, 'id-name', e.target.value)
              }
              disabled={disabled}
            >
              <MenuItem selected disabled>
                Select Component
              </MenuItem>
              {availableOptions.map(component => (
                <MenuItem
                  key={component.id}
                  value={`${component.id}-${component.name}`}
                >
                  {component.name}
                </MenuItem>
              ))}
              <MenuItem key={item.id} value={`${item.id}-${item.name}`}>
                {item.name}
              </MenuItem>
            </TextField>
          ) : (
            item.name
          )}
        </FlexBetween>
      </TableCell>

      {/* Hide: WLB 4052 */}
      {/* <TableCell align="center">
        {!isHideCheckBox && (
          <Checkbox
            color="primary"
            checked={isChecked}
            onChange={() => {
              handleChangeItem(item.id, 'is_postponed', !isChecked)
              setIsChecked(!isChecked)
            }}
            inputProps={{'aria-label': 'primary checkbox'}}
            disabled={disabled}
          />
        )}
      </TableCell> */}

      <TableCell align="right">
        {!item.is_plugin_component ? (
          <TextField
            onChange={e =>
              handleChangeItem(item.id, 'value', parseInt(e.target.value))
            }
            type="number"
            variant="outlined"
            InputProps={{
              inputProps: {min: 1, style: {textAlign: 'right'}},
              startAdornment: (
                <InputAdornment position="start">Rp</InputAdornment>
              ),
            }}
            value={Math.round(item.value) || 'placeholder'}
            disabled={disabled}
            size="small"
          />
        ) : (
          convertToRoundedRupiah(item.value || 0)
        )}
      </TableCell>
    </TableRow>
  )
}

export default DetailComponentItem
