import gql from 'graphql-tag'

export const DELETE_ORGANIZATION = gql`
  mutation($id: Int) {
    update_company_organizations(
      _set: {deletedAt: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
