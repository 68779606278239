import React from 'react'
import {Avatar} from '@material-ui/core'
import TerminatedChip from '../../components/chip/TerminatedChip'
import helperStyle, {
  FlexBetween,
  PayrollContainer,
  TextBlack,
  TextDarkBlue,
  TextGrey,
} from '../financial/payroll/PayrollPageStyles'

/**
 * Example use:
 * 1. src\pages\financial\payroll\report\tab\detail\DetailTaxReport.jsx
 * 2. src\pages\financial\payroll\report\tab\Annual.jsx
 */

export default function EmployeeAvatar({
  src,
  regno,
  title,
  global_user,
  city_name,
  province_name,
  empStatus = null,
  empEndDate = null,
}) {
  const classes = helperStyle()

  return (
    <FlexBetween className={classes.rootAvatar}>
      <Avatar alt="ok" src={global_user?.avatar || src}>
        {global_user?.name.slice(0, 1) || ''}
      </Avatar>
      <PayrollContainer className={classes.marginLeft10}>
        <span>
          <TextDarkBlue>{global_user?.name || '-'}</TextDarkBlue>{' '}
          <TerminatedChip status={empStatus} endDate={empEndDate} />
        </span>
        <TextBlack>{regno}</TextBlack>
        <TextGrey>{title}</TextGrey>
        {global_user && (
          <>
            <TextGrey>
              {province_name ? city_name + ', ' : city_name || ''}
            </TextGrey>
            <TextGrey>{province_name || ''}</TextGrey>
          </>
        )}
      </PayrollContainer>
    </FlexBetween>
  )
}
