export const STANDARD_BASE_ON_SALARY = 'SALARY'
export const STANDARD_PERHOUR = 'PERHOUR'

export const compansationHandler = (standard, moneyCompensation) => {
  let isError = false
  let compensation = {}

  if (!Object.values(moneyCompensation || {})[0]) {
    isError = true
  }

  switch (standard) {
    case STANDARD_PERHOUR: {
      compensation = moneyCompensation
      if (parseInt(moneyCompensation?.IDR) === 0) {
        isError = true
      }
      break
    }
    case STANDARD_BASE_ON_SALARY: {
      const {multipliers, divisor} = moneyCompensation || {}
      const {isErrorMultiplier, lastIndex} = getErrorMultiplier(
        multipliers || []
      )
      const multipliersResult = isErrorMultiplier
        ? []
        : multipliers
            ?.map(({value}) => parseFloat(value))
            .slice(0, lastIndex + 1)

      if (!multipliersResult[0] || !divisor || divisor === 0) {
        isError = true
      }

      compensation = {multipliers: multipliersResult, divisor}
      break
    }
    default:
      compensation = moneyCompensation
      break
  }

  return {isError, compensation}
}

export const getInitialMoneyComp = dataSetting => {
  let moneyCompansation
  let standard

  const multipliers = dataSetting?.convert_to_money?.multipliers

  if (multipliers) {
    standard = STANDARD_BASE_ON_SALARY

    // eslint-disable-next-line no-unused-vars
    const multiplierResult = Array.from({length: 12}, (_, id) => ({
      id,
      value: multipliers[id] || '',
    }))

    moneyCompansation = {
      ...dataSetting?.convert_to_money,
      multipliers: multiplierResult,
    }
  } else {
    standard = STANDARD_PERHOUR
    moneyCompansation = dataSetting?.convert_to_money
  }

  return {
    initialStandard: standard,
    initialMoneyCompansation: moneyCompansation,
  }
}

export const getErrorMultiplier = multipliers => {
  let isErrorMultiplier = false
  let lastIndex = 0
  const indexOfError = []

  for (let index = 0; index < multipliers.length; index++) {
    if (multipliers[index].value) {
      lastIndex = index
    }
  }

  for (let idx = 0; idx <= lastIndex; idx++) {
    if (!multipliers[idx].value) {
      indexOfError.push(idx)
      isErrorMultiplier = true
    }
  }

  return {isErrorMultiplier, indexOfError, lastIndex}
}
