import {
  Button,
  Divider,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  EnhancedToolbar,
  MoreIcon,
  StyledMenus,
  StyledMenuItem,
  Flex,
} from '../../../../GlobalStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {Link, withRouter} from 'react-router-dom'
import {
  TableLocalization,
  TableOptions,
} from '../../../shared-components/table/TableOptions'
import {useQuery, useMutation, useApolloClient} from '@apollo/react-hooks'
import {GET_COMPETENCY_CATEGORY} from '../../../../graphql/queries'
import {
  REMOVE_CATEGORY,
  CHECK_REMOVE_CATEGORY,
} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import ModalUnableDelete from '../ModalUnableDelete'
import {competencyOptions} from '../CompetencyPageStyles'

const useStyles = makeStyles(() => ({
  imgIcon: {
    height: '27px',
    width: '27px',
  },
}))

const ListOfCompetence = props => {
  const {search} = props
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const client = useApolloClient()

  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [valueDelete, setValueDelete] = useState({
    id: 0,
    name: '',
  })
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 20,
  })

  const [removeCategory] = useMutation(REMOVE_CATEGORY)

  const {data, error, loading, refetch} = useQuery(GET_COMPETENCY_CATEGORY, {
    variables: {
      offset: pagination.offset * pagination.limit,
      limit: pagination.limit,
      search: `%${search}%`,
    },
  })

  useEffect(() => {
    refetch()
  }, [])

  if (loading) {
    return <CircularProgress />
  } else if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  const handleChangePage = page => {
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowPerPage = row => {
    setPagination({
      ...pagination,
      limit: row,
    })
  }

  const handleDelete = row => {
    setOpenDelete(true)
    setValueDelete({
      id: row.id,
      name: row.name,
    })
  }

  const mutationDelete = async () => {
    await client
      .query({
        query: CHECK_REMOVE_CATEGORY,
        variables: {category: valueDelete.id},
      })
      .then(res => {
        if (res.data.competency_dictionaries_aggregate.aggregate.count > 0) {
          setOpen(true)
        } else {
          removeCategory({variables: {category: valueDelete.id}})
            .then(() => {
              refetch()
              enqueueSnackbar(`${valueDelete.name} removed`, {
                variant: 'success',
                autoHideDuration: 3000,
              })
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e), {
                variant: 'error',
                autoHideDuration: 3000,
              })
            })
            .finally(() => {
              setValueDelete({
                id: 0,
                name: '',
              })
            })
        }
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6" color="primary" style={{paddingLeft: 10}}>
          Kategori
        </Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={{
              pathname: '/learning/competency/category/add',
              state: {
                active: 'competency-category',
              },
            }}
          >
            Tambah Kategori
          </Button>
        </div>
      </EnhancedToolbar>
      <Divider />

      <MaterialTable
        style={{
          padding: '1.6rem 1.6rem 0',
        }}
        localization={{
          pagination: TableLocalization.pagination,
          body: {
            emptyDataSourceMessage: <NoDataListComponent search={search} />,
          },
        }}
        columns={[
          {
            title: '',
            field: 'icon',
            render: row => (
              <div
                style={{
                  textAlign: 'center',
                  width: '30px',
                }}
              >
                {row.icon && (
                  <img alt="" src={row.icon} className={classes.imgIcon} />
                )}
              </div>
            ),
          },
          {
            title: 'Kode',
            field: 'code',
          },
          {
            title: 'Nama',
            field: 'name',
          },
          {
            title: 'Keterangan',
            field: 'description',
            render: row => (
              <Flex
                style={{
                  width: 250,
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 5,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {row?.description}
              </Flex>
            ),
          },
          {
            title: 'Induk',
            field: 'category_parent.name',
          },
          {
            title: 'Aksi',
            field: 'id',
            sorting: false,
            render: row => (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {popupState => (
                  <>
                    <MoreIcon {...bindTrigger(popupState)}>more_horiz</MoreIcon>

                    <StyledMenus {...bindMenu(popupState)}>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={{
                          pathname: `/learning/competency/category/detail/${row.id}`,
                          state: {
                            active: 'competency-category',
                          },
                        }}
                        fontColor="#000"
                      >
                        Detail
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                        }}
                        component={Link}
                        to={{
                          pathname: `/learning/competency/category/edit/${row.id}`,
                          state: {
                            data: row,
                            active: 'competency-category',
                          },
                        }}
                        fontColor="#000"
                      >
                        Ubah
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => {
                          popupState.close()
                          handleDelete(row)
                        }}
                        fontColor="#ff0000"
                      >
                        Hapus
                      </StyledMenuItem>
                    </StyledMenus>
                  </>
                )}
              </PopupState>
            ),
          },
        ]}
        data={data?.competency_categories || []}
        title=""
        options={{
          ...TableOptions,
          ...competencyOptions,
        }}
        components={{
          Toolbar: () => null,
        }}
        isLoading={loading}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPerPage}
        totalCount={data?.total?.aggregate?.count || 0}
        page={pagination.offset}
        pageSize={pagination.limit}
      />
      <DeletePopup
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        mutation={mutationDelete}
        id={valueDelete.id}
        name={valueDelete.name}
      />
      <ModalUnableDelete
        open={open}
        onClose={() => setOpen(false)}
        title="Category"
        name={valueDelete.name}
        because="currently being used on List of Competency"
      />
    </PaperContainer>
  )
}

export default withRouter(ListOfCompetence)
