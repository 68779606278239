import gql from 'graphql-tag'

export const ADD_OVERTIME_POLICY = gql`
  mutation($objects: [time_overtime_policies_insert_input!]!) {
    insert_time_overtime_policies(objects: $objects) {
      affected_rows
    }
  }
`
export const ADD_OVERTIME_POLICY_WITH_FILTER = gql`
  mutation(
    $name: String!
    $convert_to_leave: Boolean
    $minimum_duration_leave: Int
    $convert_to_money: JSON
    $confirm_type: String
    $first_confirm_profile: Int
    $second_confirm_profile: Int
    $leave_policy_children: Int
    $filter: JSON
  ) {
    OvertimesPolicyCreate(
      name: $name
      convert_to_leave: $convert_to_leave
      minimum_duration_leave: $minimum_duration_leave
      convert_to_money: $convert_to_money
      confirm_type: $confirm_type
      first_confirm_profile: $first_confirm_profile
      second_confirm_profile: $second_confirm_profile
      leave_policy_children: $leave_policy_children
      filter: $filter
    ) {
      success
    }
  }
`
