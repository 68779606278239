import styled from 'styled-components'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import {Typography, Avatar, LinearProgress} from '@material-ui/core'

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 10px;
`

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProfileText = styled(Typography)`
  font-size: ${props => (props.fsize ? props.fsize : '13px')};
  font-family: ${props => props.theme.font};
  font-weight: ${props => (props.fweight ? props.fweight : 'normal')};
  color: ${props =>
    props.fcolor === 'lightBlue'
      ? '#039BE5'
      : props.fcolor === 'grey'
      ? '#A9A8A8'
      : props.fcolor === 'blue'
      ? '#014A62'
      : '#000000'};
`

export const BigAvatar = styled(Avatar)`
  width: 106.31px !important;
  height: 106.31px !important;
`

export const CustomRadarLabel = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 5px;
  opacity: 1;
  background-color: ${props => (props.bgcolor ? props.bgcolor : '#A9A8A8')};
`

export const LabelWrapper = styled.div`
  padding: 5px 40px;
  display: flex;
  justify-content: space-between;
`

export const IndividualContainer = styled.div`
  height: 350px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 1.6rem;

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 7px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a9a8a8;
  }
`

export const DoughnutNoData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  height: ${props => (props.radar ? 'auto' : '163px')};
`

export const IndividualProgress = withStyles(theme => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'rgba(3, 155, 229)',
  },
}))(LinearProgress)

export const ModelProgress = withStyles(theme => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'rgba(135, 76, 175, 0.5)',
  },
}))(LinearProgress)

const helperStyle = makeStyles(() => ({
  tabRoot: {fontSize: 14},
  mt50: {
    marginTop: '50px',
  },
  mr25: {
    marginRight: '25px',
  },
  mb5: {
    marginBottom: '5px',
  },
  mb15: {
    marginBottom: '15px',
  },
  mb25: {
    marginBottom: '25px !important',
  },
  hover: {cursor: 'pointer'},
  rightCompetency: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
  tabsRoot: {background: '#eff2f4'},
}))

export default helperStyle
