import gql from 'graphql-tag'

export const GET_DEGREE = gql`
  query($search: String, $offset: Int, $limit: Int) {
    total: global_degrees_aggregate(where: {name: {_ilike: $search}}) {
      aggregate {
        count
      }
    }

    global_degrees(
      where: {name: {_ilike: $search}}
      offset: $offset
      limit: $limit
    ) {
      id
      name
    }
  }
`
