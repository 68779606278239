import React, {useState, useLayoutEffect} from 'react'
import helperStyle, {
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
  TextBlackBold,
  FlexBetween,
  TextGrey,
} from '../../../GlobalStyles'

import {
  Dialog,
  Button,
  Typography,
  IconButton,
  DialogActions,
  TextField,
  InputAdornment,
  Divider,
  FormControlLabel,
  Checkbox,
  Avatar,
  CircularProgress,
} from '@material-ui/core'
import Search from '@material-ui/icons/Search'

import {COMPANY_ID} from '../../../utils/globals'
import {useQuery} from '@apollo/react-hooks'

import {LIST_FILTER_EMPLOYEE, LIST_SELECTED_EMP} from '../../../graphql/queries'
import InfiniteScroll from 'react-infinite-scroll-component'

const EmployeeSection = data => {
  const classes = helperStyle()
  return (
    <FlexBetween className={classes.marginTop15}>
      <Avatar alt="alternative" src={data?.avatar} />
      <div className={classes.marginLeft10}>
        <TextBlackBold>{data?.name}</TextBlackBold>
        <TextGrey>
          {data?.people_work_placements?.[0]?.company_job_profile?.title}
        </TextGrey>
      </div>
    </FlexBetween>
  )
}

const FilterAssignee = ({
  handleClose,
  open,
  onAdd,
  preData,
  isUsePwpId = false,
}) => {
  const classes = helperStyle()
  const [search, setSearch] = useState('')
  const [dataFilter, setDataFilter] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const [selectedAssignee, setSelectedAssignee] = useState([])
  const [infiniteLoad, setInfiniteLoad] = useState({
    offset: 0,
    limit: 10,
    hasMore: true,
  })

  const CheckboxItem = ({label, name, onChange, value}) => {
    return (
      <FormControlLabel
        style={{
          margin: '0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        control={
          <Checkbox
            checked={selectedData.includes(label.id)}
            color="primary"
            onChange={onChange}
            value={value}
            name={name}
            size="large"
          />
        }
        label={EmployeeSection(label)}
        labelPlacement="start"
      />
    )
  }

  const {data, error} = useQuery(LIST_FILTER_EMPLOYEE, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
      search: `%${search}%`,
      excludeUser: selectedData,
      offset: infiniteLoad.offset,
      limit: infiniteLoad.limit,
    },
    skip: !open,
  })

  const {data: _data} = useQuery(LIST_SELECTED_EMP, {
    variables: {
      company: COMPANY_ID,
      // search: `%${search}%`,
      include: selectedAssignee,
      // offset: infiniteLoad.offset,
      // limit: infiniteLoad.limit,
    },
    skip: !open,
  })

  useLayoutEffect(() => {
    if (preData) {
      const _preData = preData.map(pre => {
        return pre.user
      })
      setSelectedData(_preData)
      // const _dataFilter = preData.map(pre => {
      //   return {
      //     company: pre.company,
      //     user: pre.user,
      //   }
      // })
      // setDataFilter(_dataFilter)
    }
  }, [preData])

  if (data === undefined && error === undefined) {
    return ''
  } else if (error !== undefined) {
    return <di>{error}</di>
  }

  const handleCheckedFilter = (event, checked, dataCheckBox) => {
    event.preventDefault()
    if (!checked) {
      const notSelected = selectedData.filter(notSelect => {
        return notSelect !== dataCheckBox.id
      })
      setSelectedData(notSelected)
      const notSelectedAssignee = selectedAssignee.filter(notSelect => {
        return notSelect !== dataCheckBox.id
      })
      setSelectedAssignee(notSelectedAssignee)
      const _dataFilter = dataFilter.filter(dataFilters => {
        return dataFilters.id !== dataCheckBox.id
      })
      setDataFilter(_dataFilter)
    }
  }

  const _handleCheck = res => {
    const _selected = [...selectedData, res.id]
    setSelectedData(_selected)
    const _selectedAssignee = [...selectedAssignee, res.id]
    setSelectedAssignee(_selectedAssignee)

    const checkedEmployee = {
      company: COMPANY_ID,
      user: res.id,
    }
    if (isUsePwpId)
      checkedEmployee.user_work_id = res?.people_work_placements?.[0]?.id

    setDataFilter([...dataFilter, checkedEmployee])
  }

  const lengthDataSuggested = data.total.aggregate.count
  const fetchMoreData = () => {
    if (infiniteLoad.limit >= lengthDataSuggested) {
      setInfiniteLoad({
        ...infiniteLoad,
        hasMore: false,
      })
    }
    setTimeout(() => {
      setInfiniteLoad({
        ...infiniteLoad,
        limit: infiniteLoad.limit + 10,
      })
    }, 1000)
  }

  const handleConfirm = () => {
    onAdd(dataFilter)
    setSelectedAssignee([])
    setDataFilter([])
    setSearch('')
    handleClose()
  }

  const onCancel = () => {
    setSelectedAssignee([])
    setDataFilter([])
    const _preData = preData.map(pre => {
      return pre.user
    })
    setSelectedData(_preData)
    setSearch('')
    handleClose()
  }

  return (
    <Dialog
      onClose={onCancel}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <ModalHeader>
        <div style={{display: 'flex'}}>
          <Typography style={{color: '#ffffff', alignSelf: 'center'}}>
            Assignee
          </Typography>
        </div>
        <IconButton aria-label="close" onClick={onCancel}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContentEvent>
        <TextField
          // style={{width: '40%'}}
          margin="normal"
          variant="outlined"
          fullWidth
          placeholder="Search Employee"
          // className={classes.height39}
          onChange={event => setSearch(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment className="ml-0" position="end">
                <Search style={{color: '#014a62', width: 20, height: 20}} />
              </InputAdornment>
            ),
          }}
        />
      </ModalContentEvent>
      <div style={{maxHeight: '500px', overflow: 'auto'}} id="scrollableDiv">
        <ModalContentEvent
          className={
            _data && _data.suggested_people.length === 0 && classes.padding0
          }
        >
          {_data && _data.suggested_people.length > 0 && (
            <>
              <TextBlackBold className={classes.marginTop15}>
                Selected
              </TextBlackBold>
              {_data &&
                _data.suggested_people.map((res, index) => {
                  return (
                    <CheckboxItem
                      key={index}
                      label={res}
                      name="nama"
                      onChange={(e, check) => {
                        handleCheckedFilter(e, check, res)
                      }}
                      value={res.id}
                    />
                  )
                })}
            </>
          )}
        </ModalContentEvent>
        <Divider />
        <ModalContentEvent style={{minHeight: '250px'}}>
          <TextBlackBold>Suggested Employee</TextBlackBold>
          <InfiniteScroll
            dataLength={infiniteLoad.limit}
            scrollableTarget="scrollableDiv"
            next={fetchMoreData}
            hasMore={
              data.suggested_people.length > 5 ? infiniteLoad.hasMore : false
            }
            loader={
              <div style={{textAlign: 'center'}}>
                <CircularProgress size={15} style={{marginRight: '10px'}} />
                <span>Loading</span>
              </div>
            }
          >
            {data.suggested_people.map((res, index) => {
              return (
                <CheckboxItem
                  key={index}
                  label={res}
                  name="nama"
                  onChange={() => {
                    _handleCheck(res)
                  }}
                  value={res.id}
                />
              )
            })}
          </InfiniteScroll>
        </ModalContentEvent>
      </div>
      <Divider />
      <DialogActions>
        <Button style={{fontSize: 12}} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          // disabled={loading}
          size="large"
          style={{fontSize: 12, marginLeft: 12}}
          variant="contained"
          onClick={handleConfirm}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FilterAssignee
