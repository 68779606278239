import React from 'react'
import {makeStyles, TextField, Typography} from '@material-ui/core'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {
  FormChildContainerFull,
  FormChildInput,
  FormChildInputPad15,
  FormChildTitle,
  TitleName,
  TitleNumber,
} from '../../../../../../GlobalStyles'
import {DatePickerStyled} from '../../../../../directory/employee/EmployeePageStyles'
import DateFnsUtils from '@date-io/date-fns'
import RepeatComponent from './RepeatComponent'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  wrap: {
    padding: '0 1.6rem',
  },
  recurringInfo: {
    fontSize: 12,
    margin: '0 0 12px',
    color: '#ffa000',
    fontStyle: 'italic',
  },
  rootRecurring: {
    marginRight: '-6px',
  },
  labelRecurring: {
    fontSize: 12,
  },
  wrapDate: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 337,
  },
  chooseDate: {
    maxWidth: 223,
    fontSize: 12,
    marginBottom: 12,
  },
}))

function FormHoliday(props) {
  const classes = useStyles()
  const {
    handleChangeInput,
    value,
    error,
    recurringCheck,
    // setRecurring,
    // setValue,
  } = props

  // const handleCheck = event => {
  //   setRecurring(event.target.checked)
  //   if (event.target.checked) {
  //     setValue({
  //       ...value,
  //       recurring: 1,
  //       recur_time_x: 1,
  //       recur_end_opt: '0',
  //     })
  //   } else {
  //     setValue({
  //       ...value,
  //       recurring: 0,
  //       recur_time_x: 0,
  //       recur_end_opt: '0',
  //     })
  //   }
  // }
  const warningDate = () => {
    if (value.date) {
      const date = moment(value.date).format('D')
      if (parseInt(date) >= 30) {
        return (
          <p className={classes.recurringInfo}>
            *In a month that does not have a {date}st, the recurrence will be
            automatically set to the last date of the month.
          </p>
        )
      }
    }
  }

  return (
    <>
      <div className={classes.wrap}>
        <FormChildContainerFull style={{width: '100% !important'}} id="date">
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Date </TitleName>
            {/* {error && value.date === '' && (
              <Typography
                variant="caption"
                color="error"
                style={{marginLeft: 15}}
              >
                This field is required
              </Typography>
            )} */}
          </FormChildTitle>
          <FormChildInputPad15>
            <TitleName>Choose date *</TitleName>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className={classes.wrapDate}>
                <DatePickerStyled
                  fullWidth
                  inputVariant="outlined"
                  format="MMMM dd, yyyy"
                  margin="normal"
                  placeholder="Select Date"
                  size="small"
                  className={classes.chooseDate}
                  value={value.date}
                  error={error && !value.date}
                  onChange={e => handleChangeInput(e, 'date', true)}
                  helperText={
                    error && !value.date ? 'This field is required' : null
                  }
                />
                {warningDate()}
                {/* <FormControlLabel
                  label="Repeat"
                  classN
                  classes={{
                    root: classes.rootRecurring,
                    label: classes.labelRecurring,
                  }}
                  control={
                    <Checkbox checked={recurringCheck} onChange={handleCheck} />
                  }
                /> */}
              </div>
            </MuiPickersUtilsProvider>
          </FormChildInputPad15>

          {recurringCheck && (
            <RepeatComponent
              value={value}
              error={error}
              handleChangeInput={handleChangeInput}
            />
          )}
        </FormChildContainerFull>
      </div>
      <div className={classes.wrap}>
        <FormChildContainerFull id="date">
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Description *</TitleName>
            {error && value.description === '' && (
              <Typography
                variant="caption"
                color="error"
                style={{marginLeft: 15}}
              >
                This field is required
              </Typography>
            )}
          </FormChildTitle>
          <FormChildInput style={{paddingTop: '12px'}}>
            <TextField
              rows="10"
              cols="65"
              multiline
              size="small"
              margin="normal"
              variant="outlined"
              fullWidth
              placeholder="Add event description"
              value={value.description}
              error={error && !value.description}
              onChange={e => handleChangeInput(e, 'description')}
            />
          </FormChildInput>
        </FormChildContainerFull>
      </div>
    </>
  )
}

export default FormHoliday
