import gql from 'graphql-tag'

export const GET_USER_BY_ID = gql`
  query getUserById($id: uuid) {
    global_users(where: {id: {_eq: $id}}) {
      id
      biography
      avatar
      email
      name
    }
  }
`
