import gql from 'graphql-tag'

export const UPDATE_TRIP_STATUS = gql`
  mutation TupdateTripStatus(
    $id: Int!
    $business_trip_fields: jsonb
    $status: String
  ) {
    update_people_work_business_trips(
      _set: {status: $status}
      _append: {business_trip_fields: $business_trip_fields}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
      returning {
        id
        status
        business_trip_fields
      }
    }
  }
`
