import React, {useState, useEffect} from 'react'
import {
  PaperContainer,
  FormToolbar,
  FormTitleContainer,
  AddEditTitle,
} from '../../../../GlobalStyles'
import {
  Button,
  IconButton,
  makeStyles,
  Divider,
  Stepper,
  withStyles,
  StepConnector,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'
import {
  ADD_CROSS_MENTORING_CLASSROOM,
  ADD_MEMBER_CROSS_MENTORING_CLASSROOM,
} from '../../../../graphql/mutations'
import {GET_CLASSROOM_BY_VERSION} from '../../../../graphql/queries'
import {holdingRolesContext} from '../../../../utils/helpers'
import AboutStepComponent from './add-classroom-step/AboutStepComponent'
import MemberStepComponent from './add-classroom-step/MemberStepComponent'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    color: '#039be5 !important',
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  labelActive: {
    color: '#039be5 !important',
  },
  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
  mb16: {
    marginBottom: '16px !important',
  },
  paper: {
    minHeight: '70vh',
  },
  contentDialog: {
    color: '#000',
    fontSize: 18,
    margin: '30px 0px 30px 0px',
    fontWeight: '500',
  },
  titleDialog: {
    fontSize: 22,
  },
  actionDialog: {
    padding: '28px 18px',
  },
}))

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const getSteps = () => {
  return ['About', 'Member']
}

const AddClasroomComponent = props => {
  const id = props.match.params.id
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [isError, setIsError] = useState(false)
  const [open, setOpen] = useState(false)
  const [dataMentor, setDataMentor] = useState([])
  const [dataMentee, setDataMentee] = useState([])
  const [selectedMentee, setSelectedMentee] = useState([])
  const [selectedMentor, setSelectedMentor] = useState(null)
  const [dataContext, setDataContext] = useState({
    id: null,
    aboutStep: {
      name: '',
      description: '',
      avatar_url: '',
      // privacy: '',
      category_id: '',
      quota: '',
      rules: '',
    },
  })

  const {data} = useQuery(GET_CLASSROOM_BY_VERSION, {
    variables: {
      id: id,
    },
    ...holdingRolesContext,
  })

  const steps = getSteps()

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <AboutStepComponent
            dataContext={dataContext}
            setDataContext={setDataContext}
            isError={isError}
          />
        )
      case 1:
        return (
          <MemberStepComponent
            dataContext={dataContext}
            isError={isError}
            assignee={selectedMentee}
            setAssignee={setSelectedMentee}
            selectedMentor={selectedMentor}
            setSelectedMentor={setSelectedMentor}
            dataMentor={dataMentor}
            dataMentee={dataMentee}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  const [mutationAddClassroom] = useMutation(ADD_CROSS_MENTORING_CLASSROOM)
  const [mutationAddMember] = useMutation(ADD_MEMBER_CROSS_MENTORING_CLASSROOM)

  const handleNext = () => {
    if (activeStep === 0) {
      const {
        name,
        description,
        avatar_url,
        category_id,
        quota,
        rules,
      } = dataContext.aboutStep
      if (
        name === '' ||
        description === '' ||
        rules === '' ||
        category_id === '' ||
        quota === '' ||
        avatar_url === ''
      ) {
        setIsError(true)
      } else {
        setIsError(false)
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    } else if (activeStep === 1) {
      if (!selectedMentor || selectedMentee.length === 0) {
        setIsError(true)
      } else {
        setIsError(false)
        setOpen(true)
      }
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleClickCancel = () => {
    props.history.goBack()
  }

  const handleSubmit = () => {
    const data = {
      name: dataContext.aboutStep.name,
      description: dataContext.aboutStep.description,
      rules: dataContext.aboutStep.rules,
      avatar_url: dataContext.aboutStep.avatar_url,
      category_id: dataContext.aboutStep.category_id,
      owner_user_id: selectedMentor.global_user.id,
      versioning_id: id,
      quota: parseInt(dataContext.aboutStep.quota),
    }
    setOpen(false)
    mutationAddClassroom({
      variables: {
        object: data,
      },
      ...holdingRolesContext,
    })
      .then(res => {
        let members = []
        let classroom_id = res.data.insert_classrooms_one.id
        if (selectedMentor) {
          members.push({
            classroom_id: classroom_id,
            is_admin: true,
            member_user_id: selectedMentor.global_user.id,
          })
        }
        if (selectedMentee) {
          selectedMentee &&
            selectedMentee.map(val => {
              members.push({
                classroom_id: classroom_id,
                is_admin: false,
                member_user_id: val.global_user.id,
              })
            })
        }
        mutationAddMember({
          variables: {
            classroomMembers: members,
          },
          ...holdingRolesContext,
        })
          .then(() => {
            setOpen(false)
            enqueueSnackbar(`All Changes have been saved`, {
              variant: 'success',
              autoHideDuration: 1000,
            })
            props.history.push({
              pathname: `/holding-learning/cross-mentoring-detail/${id}`,
              state: {active: 'cross-mentoring-detail'},
            })
          })
          .catch(() => {
            enqueueSnackbar(`Add member failed`, {
              variant: 'error',
              autoHideDuration: 1000,
            })
          })
      })
      .catch(() => {
        enqueueSnackbar(`Insert classroom failed`, {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  useEffect(() => {
    if (data) {
      const value =
        data.classroom_cross_mentoring_versions[0]
          .classroom_cross_mentoring_mentees
      let _mentors = []
      let _mentees = []
      value.map(val => {
        const _dataUser = val.people_work_placement
        if (val.is_mentee) {
          _mentees.push(_dataUser)
        }
        if (val.is_mentor) {
          _mentors.push(_dataUser)
        }
      })
      setDataMentee(_mentees)
      setDataMentor(_mentors)
    }
  }, [data])

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link onClick={handleClickCancel}>
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6">Add Group Learning</AddEditTitle>
        </FormTitleContainer>
        <div>
          {activeStep === 0 ? (
            <Button
              className={classes.backButton}
              component={Link}
              onClick={handleClickCancel}
              size="large"
            >
              Cancel
            </Button>
          ) : (
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleBack}
              className={classes.backButton}
            >
              Previous
            </Button>
          )}

          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
          </Button>
        </div>
      </FormToolbar>
      <Divider className={classes.mb16} />
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomConnector />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel
              classes={{
                active: classes.labelActive,
                completed: classes.labelActive,
              }}
              StepIconProps={{
                classes: {
                  root: classes.iconRoot,
                  text: classes.iconText,
                  active: classes.iconActive,
                  completed: classes.iconActive,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{padding: '2rem'}}>{getStepContent(activeStep)}</div>

      <Dialog
        maxwidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle className={classes.titleDialog} style={{color: '#014A62'}}>
          Submit Group Learning?
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText className={classes.contentDialog}>
            Are you sure to Sumbit the Group Learning?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionDialog}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            size="large"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </PaperContainer>
  )
}

export default withRouter(AddClasroomComponent)
