import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import {makeStyles} from '@material-ui/core/styles'
import {
  BlueLink,
  AppliesToChild,
  AppliesToContainer,
} from '../../../../GlobalStyles'

import {
  Button,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper,
  Toolbar,
  Typography,
  TablePagination,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import WatchLater from '@material-ui/icons/WatchLater'

import {Link} from 'react-router-dom'

import classNames from 'classnames'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {GET_ATTENDANCE_POLICY} from '../../../../graphql/queries'
import {SOFT_DELETE_WORK_SCHEDULE} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import Loading from '../../../../components/loading/LoadingComponent'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {COMPANY_ID} from '../../../../utils/globals'
import AllAppliedTo from './modal/AllAppliedTo'

// const theme = createMuiTheme({
//   palette: {
//     primary: {
//       main: '#014a62',
//     },
//   },
// })

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  spacer: {
    flex: '1',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
  },
  datePickerAction: {
    marginRight: 30,
  },
  buttonAction: {
    // height: 61,
    // marginTop: 6,
    // width: 200,
  },
  buttonText: {
    textDecoration: 'none',
    color: '#fff',
  },
  title: {
    flex: '0 0 auto',
    // marginLeft: 10,
  },
  uploadButton: {
    color: '#fff',
    textDecoration: 'none',
  },
}))

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles()

  return (
    // <MuiThemeProvider theme={theme}>
    <Toolbar>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Work Schedule
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classNames(classes.actions)}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonAction}
        >
          <Link
            className={classes.buttonText}
            to={{
              pathname: '/time/attendance/work_schedule/add',
              state: {
                active: 'work-schedule-add',
              },
            }}
          >
            Add Work Schedule
          </Link>
        </Button>
      </div>
    </Toolbar>
    // </MuiThemeProvider>
  )
}

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
}

const DetailActivity = ({detailActivityLabel, detailActivityData}) => {
  const classes = useStyles()

  return (
    <div className={classes.detailActivityWrapper}>
      <Typography className={classes.detailActivityLabel}>
        {detailActivityLabel}
      </Typography>
      <Typography className={classes.detailActivityTitle}>
        {detailActivityData}
      </Typography>
    </div>
  )
}

DetailActivity.propTypes = {
  detailActivityLabel: PropTypes.string.isRequired,
  detailActivityData: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  content: {
    // minWidth: 850,
    minHeight: '50vh',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-left',
    alignItems: 'flex-left',
    // padding: 25,
  },
  watchLaterIcon: {
    color: '#014a62',
    width: 126,
    height: 126,
  },
  whiteTextButton: {
    color: '#fff',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 17,
    marginBottom: 17,
  },
  blackTextButton: {
    color: '#000',
    marginLeft: 41,
    marginRight: 41,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 15,
  },
  stepIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  detailActivityWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 41,
    paddingLeft: 81,
    paddingRight: 88,
    paddingBottom: 82,
  },
  detailActivityLabel: {
    color: '#014a62',
    fontSize: 15,
    fontWeight: 600,
  },
  detailActivityTitle: {
    color: '#000000',
    fontSize: 17,
    fontWeight: 600,
  },
  editTemplateListsTitle: {
    color: '#000',
    fontSize: 15,
  },
  editTemplateListsSpacer: {
    marginTop: 30,
  },
  stepIcon: {
    color: '#014962',
    width: 97,
    height: 97,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  detail: {
    color: '#007fb2',
    cursor: 'pointer',
  },
  customDatePicker: {
    width: 160,
  },

  tableWrapper: {
    overflowX: 'auto',
  },
  buttonAddWorkSchedule: {
    marginTop: 43,
  },
  greyText: {
    color: '#a9a8a8',
  },
  expandableHeading: {
    color: '#014a62',
    fontWeight: 'bold',
  },
  expandableIcon: {
    color: '#014a62',
  },
  expanded: {
    borderTop: '1px solid rgba(0, 0, 0, 0.16)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    margin: '0 !important',
  },
  panelContentContainer: {display: 'flex', alignItems: 'center'},
  panelSummaryRoot: {minHeight: 65},
  seeMore: {
    fontSize: 12,
    fontWeight: '500',
    cursor: 'pointer',
    marginBottom: 16,
  },
}))

const DEFAULT_SEE_MORE = {
  open: false,
  id: undefined,
  name: '',
}
export default function WorkSchedule(props) {
  const classes = useStyles()
  const [seeMoreApplied, setSeeMoreApplied] = useState(DEFAULT_SEE_MORE)

  const {enqueueSnackbar} = useSnackbar()
  const [policies, setPolicies] = useState([])
  const [showDeletePopup, setDeletePopup] = useState(false)
  const [scheduleToDelete, setSchedulteToDelete] = useState({
    id: null,
    name: '',
  })
  const [limit, setLimit] = React.useState(20)
  const [offset, setOffset] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [rowPerPage, setRowPerPage] = React.useState(20)

  const {data: dataPolicies, refetch, error} = useQuery(GET_ATTENDANCE_POLICY, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      search: `%${props.searchText}%`,
      company: COMPANY_ID,
      offset,
      limit,
    },
  })

  const [deletePolcy] = useMutation(SOFT_DELETE_WORK_SCHEDULE)
  useEffect(() => {
    if (dataPolicies) {
      let data = dataPolicies.time_attendance_policies
      const sorter = {
        // "sunday": 0, // << if sunday is first day of week
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
      }
      data = data.map(policy => {
        if (policy.schedule) {
          policy.schedules = Object.keys(policy.schedule).map(key => {
            return Object.assign({name: key}, policy.schedule[key])
          })

          policy.schedules.sort(function sortByDay(a, b) {
            const day1 = a.name.toLowerCase()
            const day2 = b.name.toLowerCase()
            return sorter[day1] - sorter[day2]
          })
        }
        return policy
      })

      setPolicies(data)
    }
  }, [dataPolicies])

  const onDeletePolicy = id => {
    const variables = {
      id: id,
      deleteAt: new Date(),
    }
    deletePolcy({variables})
      .then(() => {
        enqueueSnackbar('Schedule deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete schedule failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setCurrentPage(newPage)
    setOffset(newPage * limit)
  }

  const handleChangeRowsPerPage = event => {
    setRowPerPage(+event.target.value)
    setLimit(event.target.value)
    setOffset(0)
    setCurrentPage(0)
  }

  const handleClickSeeMore = workSchedule => {
    setSeeMoreApplied(e => ({
      ...e,
      open: true,
      id: workSchedule.id,
      name: workSchedule.name,
    }))
  }

  if (dataPolicies === undefined && error === undefined) {
    return <Loading />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar />
        <Divider />

        {policies.length === 0 ? (
          <div className={classNames(classes.content, classes.center)}>
            <WatchLater className={classes.watchLaterIcon} />
            <Typography>There is no work schedule</Typography>
            <Typography className={classes.greyText}>
              {`Your company's work schedule will appear here.`}
            </Typography>
          </div>
        ) : (
          <div className={classNames(classes.left)}>
            {policies &&
              policies.map((workSchedule, index) => {
                return (
                  <ExpansionPanel
                    elevation={0}
                    key={index}
                    classes={{
                      expanded: classes.expanded,
                    }}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        <ExpandMoreIcon className={classes.expandableIcon} />
                      }
                      aria-controls={`panel${index}bh-content`}
                      id={`panel${index}bh-header`}
                      classes={{
                        root: classes.panelSummaryRoot,
                        content: classes.panelContentContainer,
                      }}
                    >
                      <Typography className={classes.expandableHeading}>
                        {workSchedule.flexi === 1
                          ? workSchedule.name + ' (Flexible)'
                          : workSchedule.name}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 8,
                          }}
                        >
                          {workSchedule.flexi === 0 && (
                            <label
                              style={{
                                fontWeight: 'bold',
                                width: '35%',
                                paddingRight: '3rem',
                              }}
                            >
                              Work Hour
                            </label>
                          )}

                          <div
                            style={{
                              justifyContent: 'space-between',
                              display: 'flex',
                              width: workSchedule.flexi === 0 ? '65%' : '100%',
                            }}
                          >
                            {
                              <label style={{fontWeight: 'bold'}}>
                                {workSchedule.flexi === 0 && 'Recess'}
                              </label>
                            }
                            <div>
                              <label
                                style={{
                                  fontWeight: 'bold',
                                  color: '#ef4d5e',
                                  cursor: 'pointer',
                                  marginRight: 15,
                                }}
                                onClick={() => {
                                  setSchedulteToDelete({
                                    id: workSchedule.id,
                                    name: workSchedule.name,
                                  })
                                  setDeletePopup(true)
                                }}
                              >
                                Delete
                              </label>
                              <BlueLink
                                to={{
                                  pathname:
                                    '/time/attendance/work_schedule/edit',
                                  state: {
                                    active: 'work-schedule-edit',
                                    schedule: workSchedule,
                                    dataCheck: dataPolicies,
                                  },
                                }}
                                // onClick={() =>
                                //   handleEdit(
                                //     data.time_leave_policies,
                                //     policy,
                                //     child,
                                //   )
                                // }
                              >
                                Edit
                              </BlueLink>
                            </div>
                          </div>
                        </div>
                        {workSchedule &&
                          workSchedule.schedules &&
                          workSchedule.schedules.map((schedule, index) => {
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                key={index}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      width: '35%',
                                      justifyContent: 'space-between',
                                      marginRight: '2rem',
                                    }}
                                  >
                                    {workSchedule.flexi === 0 && (
                                      <>
                                        <label
                                          style={{
                                            color: '#a9a8a8',
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {schedule.name}
                                        </label>
                                        <label
                                          style={{
                                            color: '#a9a8a8',
                                            marginLeft: 50,
                                          }}
                                        >
                                          {`${schedule.clockIn} - ${schedule.clockOut}`}
                                        </label>
                                      </>
                                    )}
                                  </div>
                                  {workSchedule.flexi === 0 && (
                                    <label style={{color: '#a9a8a8'}}>
                                      {schedule.recess} Minutes
                                    </label>
                                  )}
                                  <div />
                                  <div />
                                </div>
                              </div>
                            )
                          })}
                        <div
                          style={{
                            paddingTop: 30,
                          }}
                        >
                          <label style={{fontWeight: 'bold'}}>
                            {`Applied To (${workSchedule.total_applied_to.aggregate.count}):`}
                          </label>
                          <AppliesToContainer>
                            {workSchedule &&
                              workSchedule.company_job_profile_r_attendances.map(
                                (applied, index) => {
                                  if (applied.company_job_profile) {
                                    return (
                                      <AppliesToChild key={index}>
                                        {applied.company_job_profile &&
                                          applied.company_job_profile.title}
                                      </AppliesToChild>
                                    )
                                  }
                                }
                              )}
                          </AppliesToContainer>
                          {workSchedule &&
                            workSchedule.total_applied_to.aggregate.count >
                              20 && (
                              <Typography
                                color="secondary"
                                className={classes.seeMore}
                                onClick={() => handleClickSeeMore(workSchedule)}
                              >
                                See More
                              </Typography>
                            )}
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              })}
          </div>
        )}
      </Paper>
      {policies.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          rowsPerPage={rowPerPage}
          page={currentPage}
          count={dataPolicies.total.aggregate.count}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <DeletePopup
        open={showDeletePopup}
        handleClose={() => setDeletePopup(false)}
        name={scheduleToDelete.name}
        mutation={() => onDeletePolicy(scheduleToDelete.id)}
      />
      <AllAppliedTo
        dataSeeMore={seeMoreApplied}
        onClose={() => setSeeMoreApplied(DEFAULT_SEE_MORE)}
      />
    </div>
  )
}
