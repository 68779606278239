import React, {Fragment, useState, useEffect} from 'react'
import {
  InputAdornment,
  IconButton,
  Paper,
  Typography,
  TextField,
  makeStyles,
  Menu,
  Button,
  Divider,
} from '@material-ui/core'
import Select from 'react-select'
import {
  BlueIcon,
  FlexBetween,
  LabelInput,
  StepOneContainer,
} from '../../../../../GlobalStyles'
import MaterialTable, {MTableBody} from 'material-table'
import {TableOptionsNoSorting} from '../../../../shared-components/table/TableOptions'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'

import {COMPANY_ID} from '../../../../../utils/globals'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {
  GET_EMPLOYEE_JOB_PROFILE,
  LIST_ALL_PROFILE,
} from '../../../../../graphql/queries'
import Loading from '../../../../../components/loading/LoadingComponent'

const useStyles = makeStyles(theme => ({
  thinTextfield: {
    padding: '12px 14px',
  },
  thinSelectfield: {
    width: '215px',
    margin: '12px',
  },
  filterRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterDate: {
    width: '29%',
  },
  filterSearch: {
    width: '69%',
  },
  menus: {
    padding: 16,
    paddingBottom: 0,
  },
  pickerContainer: {
    display: 'flex',
    marginTop: 18,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1.5),
      width: 215,
    },
    marginBottom: '100px',
  },
  inputForm: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
  },
}))

const selectStyles = {
  menu: provided => ({...provided, zIndex: 9999}),
  container: provided => ({
    ...provided,
    marginTop: 16,
    marginBottom: 8,
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: '#bfbfbf',
    }
  },
}

const renderEmpty = () => {
  return (
    <tbody>
      <tr>
        <td colSpan="4">
          <div style={{textAlign: 'center', padding: '3rem 0'}}>
            <img
              src={require('../../../../../assets/images/nodataemp.png')}
              alt="no-data"
              height="170"
              width="170"
            ></img>
            <Typography variant="h6">No Data</Typography>
            <Typography variant="body1">
              Please classify the report data first
            </Typography>
          </div>
        </td>
      </tr>
    </tbody>
  )
}

const dateValue = (date, cond) => {
  if (date) {
    return moment(date).format('ll')
  } else {
    return `Select ${cond} Date`
  }
}

// const dateVariable = date => {
//   if (date !== null) {
//     return `${moment(date).format('YYYY-MM-DD')}`
//   } else {
//     return null
//   }
// }

const StepOne = props => {
  const {allDatas, setAllData, isError, setIsError} = props
  const classes = useStyles()

  const [dataTable, setDataTable] = useState([])
  const [positionDropdown, setPositionDropdown] = useState([])
  const [searchDropdown, setSearchDropdown] = useState('')

  // const formattedStart = moment(startDate).format('ll')
  // const formattedEnd = moment(endDate).format('ll')
  const client = useApolloClient()

  const {
    data: positions,
    // error: errorPosition,
    loading: loadingPosition,
  } = useQuery(LIST_ALL_PROFILE, {
    variables: {
      search: `%${searchDropdown}%`,
      company: COMPANY_ID,
      offset: 0,
      limit: 30,
    },
  })

  const position = []
  positions &&
    positions.company_job_profiles.map(options => {
      const positionOptions = {
        value: options.id,
        label: options.title,
      }

      position.push(positionOptions)
    })

  const addDataPosition = event => {
    if (event) {
      setPositionDropdown({
        value: event.value,
        label: event.label,
      })
      setAllData({
        ...allDatas,
        position: event.value,
        positionLabel: event.label,
      })
    } else {
      setPositionDropdown([])
      setAllData({
        ...allDatas,
        position: null,
        positionLabel: null,
      })
    }
  }

  const handleChange = (type, date) => {
    if (type === 'start') {
      setAllData({
        ...allDatas,
        start_date: new Date(date),
      })
    } else {
      setAllData({
        ...allDatas,
        end_date: new Date(date),
      })
    }
  }

  useEffect(() => {
    if (!allDatas) {
      setAllData({
        name: '',
        start_date: '',
        end_date: '',
        position: '',
      })
    } else {
      setPositionDropdown(
        allDatas && allDatas.position
          ? {
              label: allDatas && allDatas.positionLabel,
              value: allDatas && allDatas.position,
            }
          : []
      )
    }
  }, [])

  const handleApply = async e => {
    e.preventDefault()
    try {
      if (
        !allDatas.name ||
        !allDatas.start_date ||
        !allDatas.end_date
        // || !allDatas.position
      ) {
        setIsError(true)
        setTimeout(() => {
          setIsError(false)
        }, 3000)
      } else {
        const {loading, data, error} = await client.query({
          query: GET_EMPLOYEE_JOB_PROFILE,
          variables: {
            company: COMPANY_ID,
            jobProfile: [allDatas && allDatas.position],
          },
        })

        if (loading) {
          return <Loading />
        } else if (error !== undefined) {
          return <div>{error}</div>
        }

        const dataListEmployee = []
        data &&
          data.people_work_placements.map(res => {
            const user = res && res.global_user
            const period_start = moment(res.start_date).format('MMMM YYYY')
            const period_end = res.end_date
              ? moment(res.end_date).format('MMMM YYYY')
              : ''
            dataListEmployee.push({
              nik: res.regno,
              name: user.name,
              per: `${period_start} ${period_end ? ' - ' + period_end : ''}`,
              pos: res.company_job_profile.title,
            })
          })
        setDataTable(dataListEmployee)
      }
    } catch (error) {
      setDataTable([])
    }
  }

  return (
    <StepOneContainer>
      <form className={classes.root}>
        <div>
          <LabelInput htmlFor="versionName">Name *</LabelInput>
          <TextField
            id="versionName"
            required
            placeholder="Name"
            value={allDatas && allDatas.name}
            onChange={event => {
              setAllData({
                ...allDatas,
                name: event.target.value,
              })
            }}
            type="text"
            variant="outlined"
            error={allDatas && !allDatas.name && isError}
            helperText={
              allDatas && !allDatas.name && isError
                ? 'This field is required'
                : ''
            }
            InputProps={{
              classes: {
                input: classes.thinTextfield,
              },
            }}
          />
        </div>
        <div className={classes.inputForm}>
          <div>
            <LabelInput htmlFor="startDate">Start Date *</LabelInput>
            <TextField
              id="startDate"
              className={classes.filterDate}
              variant="outlined"
              placeholder="Select Start Date"
              disabled
              value={dateValue(allDatas && allDatas.start_date, 'Start')}
              error={allDatas && !allDatas.start_date && isError}
              helperText={
                allDatas && !allDatas.start_date && isError
                  ? 'This field is required'
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{marginLeft: 0}}>
                    <p
                      style={{cursor: 'pointer'}}
                      onClick={() =>
                        setAllData({...allDatas, start_date: null})
                      }
                    >
                      X
                    </p>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <Fragment>
                          <IconButton
                            {...bindTrigger(popupState)}
                            style={{padding: 6}}
                          >
                            <BlueIcon>today</BlueIcon>
                          </IconButton>

                          <Menu
                            classes={{list: classes.menus}}
                            {...bindMenu(popupState)}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <FlexBetween>
                              <Typography variant="body2">
                                Pick a date
                              </Typography>
                              <div>
                                <Button onClick={popupState.close}>
                                  Cancel
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={popupState.close}
                                >
                                  Done
                                </Button>
                              </div>
                            </FlexBetween>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <div className={classes.pickerContainer}>
                                <DatePicker
                                  disableToolbar
                                  autoOk
                                  orientation="landscape"
                                  variant="static"
                                  openTo="date"
                                  value={allDatas && allDatas.start_date}
                                  onChange={date => handleChange('start', date)}
                                />
                                <Divider
                                  orientation="vertical"
                                  style={{height: '34vh'}}
                                />
                                <DatePicker
                                  disableToolbar
                                  autoOk
                                  orientation="landscape"
                                  variant="static"
                                  openTo="date"
                                  value={allDatas && allDatas.end_date}
                                  onChange={date => handleChange('end', date)}
                                  //   minDate={new Date()}
                                />
                              </div>
                            </MuiPickersUtilsProvider>
                          </Menu>
                        </Fragment>
                      )}
                    </PopupState>
                  </InputAdornment>
                ),
                classes: {
                  input: classes.thinTextfield,
                },
              }}
            ></TextField>
          </div>
          <div>
            <LabelInput htmlFor="endDate">End Date *</LabelInput>
            <TextField
              id="endDate"
              className={classes.filterDate}
              variant="outlined"
              placeholder="Select End Date"
              disabled
              value={dateValue(allDatas && allDatas.end_date, 'End')}
              error={allDatas && !allDatas.end_date && isError}
              helperText={
                allDatas && !allDatas.end_date && isError
                  ? 'This field is required'
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{marginLeft: 0}}>
                    <p
                      style={{cursor: 'pointer'}}
                      onClick={() => setAllData({...allDatas, end_date: null})}
                    >
                      X
                    </p>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <Fragment>
                          <IconButton
                            {...bindTrigger(popupState)}
                            style={{padding: 6}}
                          >
                            <BlueIcon>today</BlueIcon>
                          </IconButton>

                          <Menu
                            classes={{list: classes.menus}}
                            {...bindMenu(popupState)}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <FlexBetween>
                              <Typography variant="body2">
                                Pick a date
                              </Typography>
                              <div>
                                <Button onClick={popupState.close}>
                                  Cancel
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={popupState.close}
                                >
                                  Done
                                </Button>
                              </div>
                            </FlexBetween>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <div className={classes.pickerContainer}>
                                <DatePicker
                                  disableToolbar
                                  autoOk
                                  orientation="landscape"
                                  variant="static"
                                  openTo="date"
                                  value={allDatas && allDatas.start_date}
                                  onChange={date => handleChange('start', date)}
                                />
                                <Divider
                                  orientation="vertical"
                                  style={{height: '34vh'}}
                                />
                                <DatePicker
                                  disableToolbar
                                  autoOk
                                  orientation="landscape"
                                  variant="static"
                                  openTo="date"
                                  value={allDatas && allDatas.end_date}
                                  onChange={date => handleChange('end', date)}
                                  //   minDate={new Date()}
                                />
                              </div>
                            </MuiPickersUtilsProvider>
                          </Menu>
                        </Fragment>
                      )}
                    </PopupState>
                  </InputAdornment>
                ),
                classes: {
                  input: classes.thinTextfield,
                },
              }}
            ></TextField>
          </div>
        </div>

        <div className={classes.inputForm}>
          <div>
            <LabelInput htmlFor="organitation">Position</LabelInput>
            <Select
              className={classes.thinSelectfield}
              isLoading={loadingPosition}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown(dataSearchDropdown)
              }
              onChange={addDataPosition}
              isClearable={true}
              id="organitation"
              placeholder="Select Position"
              value={positionDropdown}
              options={position}
              styles={selectStyles}
            />
          </div>
        </div>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleApply}
          style={{float: 'right', marginRight: '12px'}}
        >
          Apply
        </Button>
      </form>
      <MaterialTable
        style={{padding: '0 1.6rem', marginTop: 20}}
        columns={[
          {
            title: 'ID Number',
            field: 'nik',
          },
          {
            title: 'Name',
            field: 'name',
          },
          {
            title: 'Period',
            field: 'per',
          },
          {
            title: 'Position',
            field: 'pos',
          },
        ]}
        // data={dataListEmployee}
        data={dataTable}
        title=""
        options={TableOptionsNoSorting}
        components={{
          Toolbar: () => null,
          Container: props => <Paper {...props} elevation={0} />,
          Body: props => {
            return props.renderData.length === 0 ? (
              renderEmpty()
            ) : (
              <MTableBody {...props} />
            )
          },
        }}
      />
    </StepOneContainer>
  )
}

export default StepOne
