import gql from 'graphql-tag'

export const GET_LIST_COURSE = gql`
  query(
    $company: uuid
    $author: uuid
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: academy_courses_aggregate(
      where: {
        organization_id: {_eq: $company}
        title: {_ilike: $search}
        global_user: {id: {_eq: $author}}
      }
    ) {
      aggregate {
        count
      }
    }
    academy_courses(
      where: {
        organization_id: {_eq: $company}
        title: {_ilike: $search}
        global_user: {id: {_eq: $author}}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      title
      date_added
      count_enrollment
      finish: academy_course_enrollments_aggregate {
        aggregate {
          count
        }
      }
      average_rating
      status
    }
  }
`

export const GET_FILTER_BY_COURSE_ID = gql`
  query($courseId: Int) {
    course_organization_assignments(where: {course_id: {_eq: $courseId}}) {
      id
      company_id
      course_id
      filter
      date_added
    }
  }
`
