import gql from 'graphql-tag'

export const GET_PROFILE_BY_ID = gql`
  query($company: uuid) {
    company_profiles(where: {id: {_eq: $company}}) {
      id
      brand_name
      legal_name
      logo
      profile_field
      global_company_industry_type {
        id
        name
      }
      company_legal_type {
        id
        legal_type
        description
      }
      type
      email
      address
      main_office: company_address {
        id
        office_name
        office_code
        status
        global_province {
          id
          name
        }
        global_city {
          id
          name
        }
        address
        address_field
        postal_code
        phone
      }
      phone
      country_code
      field
      people_work_placements_aggregate(
        where: {
          status: {_eq: "ACTIVE"}
          global_user: {status: {_eq: "ACTIVE"}, people_profiles: {}}
          start_date: {_lte: "now()", _gt: null}
          replacement_status: {_eq: false}
          global_placement_termination_id: {_is_null: true}
          company_job_profile: {}
        }
      ) {
        aggregate {
          count
        }
      }
      company_addresses(
        where: {company: {_eq: $company}}
        order_by: [{id: asc}]
      ) {
        id
        office_name
        office_code
        status
        global_province {
          id
          name
        }
        global_city {
          id
          name
        }
        address
        address_field
        postal_code
        phone
      }
    }
  }
`
