// !!!!!!! this component is used in several feature !!!!!!

export const manipulateFilter = stateFilter => {
  const {
    // filterJobFunction,
    filterJobFamily,
    filterJobTitle,
    filterOrgUnit,
    filterOrgLevel,
    filterJobLevel,
    filterJobGrade,
    filterOffice,
    filterPosition,
  } = stateFilter
  const _filter = {}
  if (filterJobFamily) {
    _filter.filter_include_job_family = filterJobFamily._in
    _filter.filter_exclude_job_family = filterJobFamily._nin
  }
  if (filterJobTitle) {
    _filter.filter_include_job_title = filterJobTitle._in
    _filter.filter_exclude_job_title = filterJobTitle._nin
  }
  if (filterOrgUnit) {
    _filter.filter_include_org_unit = filterOrgUnit._in
    _filter.filter_exclude_org_unit = filterOrgUnit._nin
  }
  if (filterOrgLevel) {
    _filter.filter_include_org_level = filterOrgLevel._in
    _filter.filter_exclude_org_level = filterOrgLevel._nin
  }
  if (filterJobLevel) {
    _filter.filter_exclude_job_level = filterJobLevel._nin
    _filter.filter_include_job_level = filterJobLevel._in
  }
  if (filterJobGrade) {
    _filter.filter_include_job_grade = filterJobGrade._in
    _filter.filter_exclude_job_grade = filterJobGrade._nin
  }
  if (filterOffice) {
    _filter.filter_include_office = filterOffice._in
    _filter.filter_exclude_office = filterOffice._nin
  }
  if (filterPosition) {
    _filter.filter_include_position = filterPosition._in
    _filter.filter_exclude_position = filterPosition._nin
  }

  for (const p in _filter) {
    if (!_filter[p]) {
      delete _filter[p]
    }
  }
  return _filter
}
