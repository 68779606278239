import React, {useEffect, useState, useContext} from 'react'
import moment from 'moment'

import {startOf, range, add} from 'react-big-calendar/lib/utils/dates'
import {navigate} from 'react-big-calendar/lib/utils/constants'
import './styles.css'
import {DataContext} from '../../../../../ContextConfig'

const createCalendar = currentDate => {
  if (!currentDate) {
    currentDate = moment()
  } else {
    currentDate = moment(currentDate)
  }

  const first = currentDate.clone().startOf('month')
  const last = currentDate.clone().endOf('month')
  const weeksCount = Math.ceil((first.day() + last.date()) / 7)
  const calendar = Object.assign([], {currentDate, first, last})

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = []
    calendar.push(week)
    calendar.year = currentDate.year()
    calendar.month = currentDate.month()

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set('date', day + 1 - first.day())
      date.calendar = calendar
      week.push(date)
    }
  }

  return calendar
}

const CalendarDate = props => {
  const {dateToRender, dateOfMonth} = props
  const today =
    dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ? 'today'
      : ''

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    )
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    )
  }

  return (
    <button
      className={`date in-month ${today}`}
      onClick={() => props.onClick(dateToRender)}
    >
      {dateToRender.date()}
    </button>
  )
}

const Calendar = props => {
  const {date, onDrillDown} = props
  const {setDataContext} = useContext(DataContext)
  const [calendar, setCalendar] = useState(undefined)

  useEffect(() => {
    setCalendar(createCalendar(date))
  }, [])
  if (!calendar) {
    return null
  } else {
    return (
      <div className="month">
        <div
          className="month-name"
          onClick={() => {
            onDrillDown(date, 'month')
            setDataContext({
              year: false,
              month: true,
              week: false,
              day: false,
              schedule: false,
              chooserCalendar: 'month',
            })
          }}
        >
          {calendar.currentDate.format('MMMM').toUpperCase()}
        </div>
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
          <span
            key={index}
            className="day"
            onClick={() => {
              onDrillDown(date, 'week')
              setDataContext({
                year: false,
                month: false,
                week: true,
                day: false,
                schedule: false,
                chooserCalendar: 'week',
              })
            }}
          >
            {day}
          </span>
        ))}
        {calendar.map((week, index) => (
          <div key={index}>
            {week.map(date => (
              <CalendarDate
                key={date.date()}
                dateToRender={date}
                dateOfMonth={calendar.currentDate}
                onClick={
                  () => {
                    onDrillDown(date._d, 'day')
                    setDataContext({
                      year: false,
                      month: false,
                      week: false,
                      day: true,
                      schedule: false,
                      chooserCalendar: 'day',
                    })
                  }
                }
              />
            ))}
          </div>
        ))}
      </div>
    )
  }
}

const Year = props => {
  const {date} = props
  range(date)
  const months = []
  const firstMonth = startOf(date, 'year')

  for (let i = 0; i < 12; i++) {
    months.push(
      <Calendar {...props} key={i + 1} date={add(firstMonth, i, 'month')} />
    )
  }

  return <div className="year">{months.map(month => month)}</div>
}

Year.ranges = date => {
  return [startOf(date, 'year')]
}

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return add(date, -1, 'year')

    case navigate.NEXT:
      return add(date, 1, 'year')

    default:
      return date
  }
}

Year.title = date => moment(date).format('YYYY')

export default Year
