import GoalPage from './GoalPageComponent'

export const GoalPageConfig = {
  routes: [
    {
      path: '/performance/goal/create',
      component: GoalPage,
      state: 'create-goal',
      exact: false,
    },
    {
      path: '/performance/goal',
      component: GoalPage,
      state: 'goal',
      exact: true,
    },
    {
      path: '/performance/goal/edit/:id',
      component: GoalPage,
      state: 'edit-goal',
      exact: true,
    },
    {
      path: '/performance/goal/detail/:id',
      component: GoalPage,
      state: 'detail-goal-task',
      exact: true,
    },
  ],
}
