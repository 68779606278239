import {
  Button,
  Divider,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React from 'react'
import {withRouter} from 'react-router-dom'
import {
  AddEditTitle,
  FormChildContainer,
  FormChildInput,
  FormChildInputHalf,
  FormChildTitle,
  FormContainer,
  FormTitleContainer,
  FormToolbar,
  PaperContainer,
  TitleName,
  TitleNumber,
} from '../../../../GlobalStyles'
import {selectProps} from '../../../../utils/helpers'

const useStyles = makeStyles(() => ({
  paper: {
    minHeight: '70vh',
  },
  tabsRoot: {background: '#eff2f4'},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14, minHeight: 54},
}))

function AddCompanyComponent(props) {
  const classes = useStyles()

  return (
    <PaperContainer className={classes.paper}>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={props.history.goBack}
          >
            <ArrowBack />
          </IconButton>

          <AddEditTitle variant="h6">Create Company</AddEditTitle>
        </FormTitleContainer>
        <Button size="large" variant="contained" color="primary">
          Submit
        </Button>
      </FormToolbar>
      <Divider />

      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Company Name</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="Enter the name"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Type</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              select
              SelectProps={selectProps('state')}
            >
              <MenuItem value="" disabled>
                Select company type
              </MenuItem>
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">Inactive</MenuItem>
            </TextField>
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Number of Company</TitleName>
          </FormChildTitle>
          <FormChildInputHalf>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="Enter numbers"
              type="number"
            />
          </FormChildInputHalf>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>4.</TitleNumber>
            <TitleName>Phone Number</TitleName>
          </FormChildTitle>
          <FormChildInputHalf>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="Enter phone number"
              type="number"
            />
          </FormChildInputHalf>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>5.</TitleNumber>
            <TitleName>Email</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="Enter email"
              type="email"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>6.</TitleNumber>
            <TitleName>Description</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              placeholder="Enter description"
            />
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
    </PaperContainer>
  )
}

export default withRouter(AddCompanyComponent)
