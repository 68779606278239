import gql from 'graphql-tag'

export const GET_LEAVE_SETTINGS_PARENT = gql`
  {
    time_leave_policies {
      id
      name
      calc_method
      label_color
      text_color
      leftover_valid
      leftover_valid_until_month
      quota_reset_option
      quota_reset_option_date
      quota_reset_option_month
      quota_prorate_option
      quota_effective_after_month
    }
  }
`
