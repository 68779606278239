import React from 'react'

import {Filter} from '@worklifebeyond/wlb-utils-components'
import Card from '../../../components/card/CardComponent'
import WlbTab from '../../../components/tab/WlbTabComponent'

import {
  BlueIcon,
  ButtonFilter,
  FilterText,
  PageContainer,
} from '../../../GlobalStyles'

import Company from './company/Company'
import CompanyHistory from './company/history/CompanyHistory'
import CompanyTopup from './company/topup/CompanyTopup'
import CompanyDetails from './company/details/CompanyDetails'

import Employee from './employee/Employee'
import EmployeeTopup from './employee/topup/EmployeeTopup'
import EmployeeDetails from './employee/individual/EmployeeDetails'
import EmployeeHistory from './employee/individual/EmployeeHistory'
import EmployeeIndividualTopup from './employee/individual/EmployeeInvidivualTopup'
import EmployeeTransactionDetails from './employee/transaction/EmployeeTransactionDetails'

import Report from './report/Report'

import {
  COMPANY_TRANSACTION_FILTER_CONFIG,
  EMPLOYEE_TRANSACTION_FILTER_CONFIG,
} from './filters'

import searchFilters from '../../../utils/searchFilters'

const TABS_CONFIG = [
  {
    label: 'Company Claim Limit',
    state: 'company',
    url: '/budget/limit/company',
    searchable: true,
  },
  {
    label: 'Employee Claim Limit',
    state: 'employee',
    url: '/budget/limit/employee',
    searchable: true,
  },
  {
    label: 'Download Report',
    state: 'report',
    url: '/budget/limit/report',
    searchable: true,
  },
]

const renderComponent = (activeComponent, searchText, filterData) => {
  switch (activeComponent) {
    default:
    case 'company':
      return <Company searchText={searchText} />
    case 'company-history':
      return <CompanyHistory searchText={searchText} filterData={filterData} />
    case 'company-topup':
      return <CompanyTopup />
    case 'company-details':
      return <CompanyDetails searchText={searchText} />

    case 'employee':
      return <Employee searchText={searchText} filterData={filterData} />
    case 'employee-topup':
      return <EmployeeTopup />
    case 'employee-details':
      return <EmployeeDetails searchText={searchText} />
    case 'employee-history':
      return <EmployeeHistory searchText={searchText} filterData={filterData} />
    case 'employee-individual-topup':
      return <EmployeeIndividualTopup />
    case 'employee-transaction':
      return <EmployeeTransactionDetails searchText={searchText} />

    case 'report':
      return <Report />
  }
}

const FILTER_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right',
}

const FILTER_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'right',
}

function ClaimLimitPage(props) {
  const [searchText, handleSearch] = React.useState('')
  const [filterData, setFilterData] = React.useState({})

  let active = ''
  let activeTab = ''
  let activeFilter
  let activeFilterExclude = true

  if (props.location.state) {
    active = props.location.state.active

    switch (active) {
      default:
      case 'company':
      case 'company-history':
      case 'company-topup':
      case 'company-details':
        activeTab = 'company'
        break

      case 'employee':
      case 'employee-topup':
      case 'employee-details':
      case 'employee-history':
      case 'employee-individual-topup':
      case 'employee-transaction':
        activeTab = 'employee'
        break

      case 'report':
        activeTab = 'report'
        break
    }

    switch (active) {
      case 'company-history':
        activeFilter = COMPANY_TRANSACTION_FILTER_CONFIG
        break
      case 'employee-history':
        activeFilter = EMPLOYEE_TRANSACTION_FILTER_CONFIG
        break
      case 'employee':
        activeFilter = searchFilters
        break
    }

    switch (active) {
      case 'company-history':
      case 'employee-history':
        activeFilterExclude = false
        break
      case 'employee':
        activeFilterExclude = true
        break
    }
  }

  const handleFilterApply = result => {
    const include = mapFilterResults(result[0])
    const exclude = mapFilterResults(result[1])

    setFilterData({include, exclude})
  }

  return (
    <div>
      <Card
        icon="account_balance_wallet"
        title="Claim Limit"
        onSearch={handleSearch}
        endAdornment={
          activeFilter && (
            <Filter
              id="filter-budget"
              onApply={handleFilterApply}
              listFilter={activeFilter}
              includeExclude={activeFilterExclude}
              anchorOrigin={FILTER_ANCHOR_ORIGIN}
              transformOrigin={FILTER_TRANSFORM_ORIGIN}
            >
              <ButtonFilter position="end">
                <BlueIcon>filter_list</BlueIcon>
                <FilterText variant="body2">Filter</FilterText>
              </ButtonFilter>
            </Filter>
          )
        }
      >
        <PageContainer>
          <WlbTab data={TABS_CONFIG} activeMenu={activeTab} />
          {renderComponent(active, searchText, filterData)}
        </PageContainer>
      </Card>
    </div>
  )
}

export default ClaimLimitPage

function mapFilterResults(obj) {
  const ret = {}

  for (const prop in obj) {
    let val = obj[prop]

    if (Array.isArray(val)) {
      val = val.map(item => item.value)
    }

    ret[prop] = val
  }

  return ret
}
