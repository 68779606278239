import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import {InputAdornment} from '@material-ui/core'

// import CheckboxInputGroup from '../../../../../components/form/CheckboxInputGroup'
import NumberInputGroup from '../../../../../components/form/NumberInputGroup'
import SelectInputGroup from '../../../../../components/form/SelectInputGroup'
import TextInputGroup from '../../../../../components/form/TextInputGroup'

import {
  GET_EMPLOYEE_DROPDOWN,
  GET_LOAN_POLICY,
} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals.js'
import {
  currencyMaskingMethod,
  isEmployeeValid,
} from '../../../../../utils/helpers'
import {GQL_FINANCE_OPTIONS} from '../../../utils'

const EmployeeLoanForm = props => {
  const {
    dataForm,
    setDataForm,
    defaultData,
    isAbleToEdit = true,
    isSubmit,
    isEdit = false,
  } = props
  const [loanPolicySearchText, setLoanPolicySearchText] = useState({search: ''})
  const [empSearchText, setEmpSearchText] = useState({search: ''})

  const {
    data: policyOptions,
    loading: policyLoading,
    error: policyError,
  } = useQuery(GET_LOAN_POLICY, {
    variables: {
      company: COMPANY_ID,
      search: `%${loanPolicySearchText?.search}%`,
      offset: 0,
      limit: 30,
    },
    skip: isEdit,
    fetchPolicy: 'cache-and-network',
    ...GQL_FINANCE_OPTIONS,
  })

  const {data: empOptions, loading: empLoading, error: empError} = useQuery(
    GET_EMPLOYEE_DROPDOWN,
    {
      variables: {
        company: COMPANY_ID,
        search: `%${empSearchText?.search}%`,
        offset: 0,
        limit: 30,
      },
      skip: isEdit,
      fetchPolicy: 'cache-and-network',
      ...GQL_FINANCE_OPTIONS,
    }
  )

  const validEmpOptions = empOptions?.v_latest_placements
    ?.map(emp => {
      if (
        isEmployeeValid(
          emp?.people_work_placement?.status,
          emp?.people_work_placement?.end_date
        )
      ) {
        return emp
      }
    })
    ?.filter(element => {
      return element !== undefined
    })

  if (policyError || empError) console.error(policyError || empError)

  return (
    <>
      {!isEdit && (
        <>
          <SelectInputGroup
            dataForm={dataForm}
            setDataForm={setDataForm}
            dataOptions={policyOptions?.finance_loan_policies}
            defaultValue={defaultData?.policy}
            disabled={!isAbleToEdit}
            fieldLabel="Loan Policy *"
            fieldNumber="1"
            fieldName="policy"
            isClearable={false}
            isLoading={policyLoading}
            placeholder="Select here"
            searchText={loanPolicySearchText}
            setSearchText={setLoanPolicySearchText}
            showErrorHelper={() => isSubmit && !dataForm?.policy}
            tooltipMesssage="Loan Policy"
            onChange={({value}) => {
              setDataForm({
                ...dataForm,
                policy: value,
                installment: value?.installment,
                loan_amount: value?.loan_max_amount,
                loan_rate: value?.interest_rate,
                loan_amount_w_interest:
                  value?.loan_max_amount +
                  (value?.loan_max_amount * value?.interest_rate) / 100,
                amount_per_installment:
                  (value?.loan_max_amount +
                    (value?.loan_max_amount * value?.interest_rate) / 100) /
                  value?.installment,
              })
            }}
            mapSelectOption={opt => ({
              label: opt.name,
              value: opt,
            })}
          />

          <SelectInputGroup
            dataForm={dataForm}
            setDataForm={setDataForm}
            dataOptions={validEmpOptions}
            defaultValue={defaultData?.user_work_id}
            disabled={!isAbleToEdit}
            fieldLabel="Employee *"
            fieldNumber="2"
            fieldName="user_work_id"
            isLoading={!empLoading && !validEmpOptions}
            placeholder="Select here"
            searchText={empSearchText}
            setSearchText={setEmpSearchText}
            showErrorHelper={() => isSubmit && !dataForm?.user_work_id}
            tooltipMesssage="Employee"
            mapSelectOption={opt => ({
              label: `${opt?.people_work_placement?.regno} - ${opt?.people_work_placement?.global_user?.name}`,
              value: opt?.people_work_placement?.id,
            })}
          />
        </>
      )}

      <TextInputGroup
        dataForm={dataForm}
        setDataForm={setDataForm}
        defaultValue={defaultData?.letter_num}
        disabled={!isAbleToEdit}
        fieldLabel="Letter Number *"
        fieldNumber={isEdit ? '1' : '3'}
        fieldName="letter_num"
        placeholder="Type letter number here"
        showErrorHelper={() => isSubmit && !dataForm?.letter_num}
        tooltipMesssage="Letter Number"
      />

      <TextInputGroup
        dataForm={dataForm}
        setDataForm={setDataForm}
        defaultValue={defaultData?.description}
        disabled={!isAbleToEdit}
        fieldLabel="Purpose *"
        fieldNumber={isEdit ? '2' : '4'}
        fieldName="description"
        placeholder="Type loan purpose here"
        showErrorHelper={() => isSubmit && !dataForm?.description}
        tooltipMesssage="Loan Purpose"
      />

      <NumberInputGroup
        isCurrency
        dataForm={dataForm}
        setDataForm={setDataForm}
        disabled={!isAbleToEdit}
        fieldLabel="Loan Amount *"
        fieldNumber={isEdit ? '3' : '5'}
        fieldName="loan_amount"
        placeholder="Type loan amount here"
        showErrorHelper={() => isSubmit && !dataForm?.loan_amount}
        tooltipMesssage="Loan Amount"
        additionalonchangemethod={val => {
          setDataForm({
            ...dataForm,
            loan_amount: val,
            loan_amount_w_interest:
              val + ((dataForm?.loan_rate || 0) * val) / 100,
          })
        }}
        maskingMethod={value => {
          return currencyMaskingMethod(value)
        }}
        value={currencyMaskingMethod(dataForm?.loan_amount)}
      />

      <NumberInputGroup
        dataForm={dataForm}
        setDataForm={setDataForm}
        disabled={!isAbleToEdit}
        fieldLabel="Rate of Interest [%] / year"
        fieldNumber={isEdit ? '4' : '6'}
        fieldName="loan_rate"
        isDecimal={true}
        isRequired={false}
        placeholder="Type rate of interest here"
        tooltipMesssage="Rate of Interest"
        type="number"
        value={dataForm?.loan_rate?.toString()}
        additionalonchangemethod={val => {
          setDataForm({
            ...dataForm,
            loan_rate: val,
            loan_amount_w_interest:
              dataForm?.loan_amount + (dataForm?.loan_amount * val) / 100,
          })
        }}
        additionalInputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          inputProps: {min: 0, step: '0.1', style: {textAlign: 'right'}},
        }}
        maskingMethod={value => {
          return parseFloat(value).toString()
        }}
        // showErrorHelper={() =>
        //   isSubmit &&
        //   (dataForm?.loan_rate === undefined || dataForm?.loan_rate === '')
        // }
      />

      <NumberInputGroup
        isCurrency
        readOnly
        dataForm={dataForm}
        setDataForm={setDataForm}
        disabled={!isAbleToEdit}
        fieldLabel="Loan Amount with Interest"
        fieldNumber={isEdit ? '5' : '7'}
        fieldName="loan_amount_w_interest"
        placeholder="Type loan amount with interest here"
        showErrorHelper={() => isSubmit && !dataForm?.loan_amount_w_interest}
        tooltipMesssage="Loan Amount with Interest"
        additionalInputProps={{
          readOnly: true,
        }}
        additionalonchangemethod={val => {
          setDataForm({
            ...dataForm,
            loan_amount_w_interest: val,
            amount_per_installment: val / dataForm?.installment,
          })
        }}
        maskingMethod={value => {
          return currencyMaskingMethod(value)
        }}
        value={currencyMaskingMethod(dataForm.loan_amount_w_interest)}
      />

      <SelectInputGroup
        isSearchable={false}
        dataForm={dataForm}
        setDataForm={setDataForm}
        dataOptions={STATUS_OPTIONS}
        defaultValue={defaultData?.status}
        fieldLabel="Status *"
        fieldNumber={isEdit ? '6' : '8'}
        fieldName="status"
        placeholder="Select here"
        showErrorHelper={() => isSubmit && !dataForm?.status}
        tooltipMesssage="Status"
      />

      <TextInputGroup
        isRequired={false}
        dataForm={dataForm}
        setDataForm={setDataForm}
        defaultValue={defaultData?.remarks}
        fieldLabel="Remarks / Comment"
        fieldNumber={isEdit ? '7' : '9'}
        fieldName="remarks"
        placeholder="Type remarks / comments here"
        tooltipMesssage="Loan Remarks / Comment"
      />

      {/* Hide: until further notice  */}
      {/* <CheckboxInputGroup
        dataForm={dataForm}
        setDataForm={setDataForm}
        defaultValue={defaultData?.flat_rate}
        disabled={!isAbleToEdit}
        fieldLabel="Flat Rate"
        fieldNumber={isEdit ? '7' : '9'}
        fieldName="flat_rate"
        isRequired={false}
        tooltipMesssage="Flat Rate"
      /> */}
    </>
  )
}

const STATUS_OPTIONS = [
  {
    label: 'Processing',
    value: 'processing',
  },
  {
    label: 'Waiting',
    value: 'waiting',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Settled',
    value: 'settled',
  },
]

export default withRouter(EmployeeLoanForm)
