import gql from 'graphql-tag'

export const GET_ORGANIZATION_UNIT_FILTER = gql`
  query($where: company_organizations_bool_exp, $limit: Int) {
    total: company_organizations_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: company_organizations(
      where: $where
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
      code
    }
  }
`

export const GET_JOB_LEVEL_FILTER = gql`
  query($where: company_employee_ranks_bool_exp, $limit: Int) {
    total: company_employee_ranks_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: company_employee_ranks(
      where: $where
      limit: $limit
      order_by: [{name: asc}]
    ) {
      id
      name
      code
    }
  }
`

export const GET_JOB_FUNCTION_FILTER = gql`
  query($where: job_family_functions_bool_exp, $limit: Int) {
    total: job_family_functions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: job_family_functions(where: $where, offset: $offset, limit: $limit) {
      id
      code
      name
      description
    }
  }
`

export const GET_JOB_GRADE_FILTER = gql`
  query($where: job_grades_bool_exp, $limit: Int) {
    total: job_grades_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: job_grades(where: $where, limit: $limit, order_by: [{name: asc}]) {
      id
      name
      code
    }
  }
`

export const GET_JOB_TITLE_FILTER = gql`
  query($where: company_employee_positions_bool_exp, $limit: Int) {
    total: company_employee_positions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: company_employee_positions(where: $where, limit: $limit) {
      id
      name
      code: designation_code
    }
  }
`

export const GET_POSITION_FILTER = gql`
  query($where: company_job_profiles_bool_exp, $limit: Int) {
    total: company_job_profiles_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: company_job_profiles(where: $where, limit: $limit) {
      id
      name: title
      code
    }
  }
`

export const GET_OFFICE_LOCATION_FILTER = gql`
  query($where: company_addresses_bool_exp, $limit: Int) {
    total: company_addresses_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: company_addresses(where: $where, limit: $limit) {
      id
      name: office_name
      code: office_code
    }
  }
`

export const GET_EMPLOYEE_TYPE_FILTER = gql`
  query($where: global_legal_type_placements_bool_exp, $limit: Int) {
    total: global_legal_type_placements_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: global_legal_type_placements(where: $where, limit: $limit) {
      id
      name
      value
    }
  }
`

export const GET_TIME_ARRANGEMENT_FILTER = gql`
  query($where: global_legal_time_placements_bool_exp, $limit: Int) {
    total: global_legal_time_placements_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: global_legal_time_placements(where: $where, limit: $limit) {
      id
      name
      value
    }
  }
`

export const GET_WORKING_ARRANGEMENT_FILTER = gql`
  query($where: global_legal_place_placements_bool_exp, $limit: Int) {
    total: global_legal_place_placements_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: global_legal_place_placements(where: $where, limit: $limit) {
      id
      name
      value
    }
  }
`

export const GET_RELIGION_FILTER = gql`
  query($where: global_religions_bool_exp, $limit: Int) {
    total: global_religions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: global_religions(where: $where, limit: $limit) {
      id
      name
    }
  }
`

export const GET_EDUCATION_FILTER = gql`
  query($where: people_profile_educations_bool_exp, $limit: Int) {
    total: people_profile_educations_aggregate(
      where: $where
      distinct_on: school
    ) {
      aggregate {
        count
      }
    }
    items: people_profile_educations(
      where: $where
      distinct_on: school
      limit: $limit
    ) {
      id
      company_profile {
        id
        legal_name
      }
    }
  }
`

export const GET_HOBBY_FILTER = gql`
  query($where: people_hobbies_bool_exp, $limit: Int) {
    total: people_hobbies_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: people_hobbies(where: $where, limit: $limit) {
      id
      name
    }
  }
`

export const GET_TALENT_FILTER = gql`
  query($company: uuid!, $limit: Int, $search: String) {
    talent_versions(
      limit: $limit
      where: {
        company: {_eq: $company}
        status: {_eq: "COMPLETED"}
        classification_type: {_eq: "TALENT"}
        name: {_ilike: $search}
      }
    ) {
      id
      name
    }
  }
`

export const GET_TALENT_BADGE_FILTER = gql`
  query($talent: uuid!, $limit: Int, $search: String, $nin: [Int!]) {
    talent_boxes(
      limit: $limit
      where: {
        version: {_eq: $talent}
        name: {_ilike: $search}
        id: {_nin: $nin}
      }
    ) {
      id
      badge
      name
    }
    total: talent_boxes_aggregate(
      where: {
        version: {_eq: $talent}
        name: {_ilike: $search}
        id: {_nin: $nin}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
