import React from 'react'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import {
  AttachmentItemHover,
  WrapAttachment,
} from '../../../../../shared-components/attachment'
import {TyphoSection} from '../../DetailEmployeeStyles'
import ChipsItem from '../../SharedDetailEmployee/ChipsItem'
import useEllipsisText from '../../../../../../hooks/useEllipsisText'
import useToggle from '../../../../../../hooks/useToggle'

export const DisciplinaryItem = ({
  onPreview,
  discipline_name,
  reference_number,
  date,
  discipline_letter_number,
  user_name,
  reference_date,
  remark,
  attachments,
  onEdit,
}) => {
  const _remark = remark?.length === 0 ? '-' : remark
  const {briefText, isOverLine, isOverLength} = useEllipsisText({
    text: _remark,
  })
  const [more, setMore] = useToggle()

  return (
    <ChipsItem chipsName={discipline_name} onEdit={onEdit}>
      <TyphoSection title="true" bold="true">
        Ref No.{reference_number}
      </TyphoSection>
      <TyphoSection grey="true">{date}</TyphoSection>
      <TyphoSection style={{color: '#014a62'}}>
        Disciplinary No.{discipline_letter_number}
      </TyphoSection>
      <TyphoSection style={{color: '#1b1d1f'}}>
        Given to {user_name}
      </TyphoSection>
      <TyphoSection grey="true">{reference_date}</TyphoSection>
      <TyphoSection style={{color: '#333333'}}>
        {more ? _remark : briefText}
      </TyphoSection>

      {more && (
        <>
          <WrapAttachment>
            {attachments &&
              attachments.map((attachment, i) => (
                <AttachmentItemHover
                  key={`${i}-itm`}
                  iconAction={
                    <VisibilityOutlinedIcon
                      onClick={() =>
                        onPreview(attachment.url || attachment.link)
                      }
                      className="icon"
                    />
                  }
                  fileName={attachment.name}
                  url={attachment.url || attachment.link}
                />
              ))}
          </WrapAttachment>
        </>
      )}

      {(isOverLine || isOverLength || attachments?.length > 0) && (
        <TyphoSection
          mt="true"
          style={{color: '#a9a8a8', cursor: 'pointer'}}
          onClick={setMore}
        >
          {more ? '...Lihat Lebih Sedikit' : '...Lihat Lebih Banyak'}
        </TyphoSection>
      )}
    </ChipsItem>
  )
}
