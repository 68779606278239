import React from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
} from '@material-ui/core'
import {WhiteModalHeader} from '../../../GlobalStyles'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},

  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnPopup: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {
    color: '#014a62',
  },
  word: {wordBreak: 'break-all'},
  marginTop5: {
    marginTop: '5px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  marginBot10: {
    marginBottom: '10px',
  },
}))

export default function AddEdit(props) {
  const classes = useStyles()
  const {
    open,
    handleCancel,
    handleClose,
    mutation,
    header,
    body,
    button,
    headerClose = true,
    disabled = false,
    noCancelNeeded,
    buttonClose = 'Batal',
  } = props

  return (
    <Dialog
      onClose={handleCancel || handleClose}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <WhiteModalHeader>
        <Typography variant="h6" color="error" className={classes.wordBreak}>
          {header}
        </Typography>
        {headerClose && (
          <IconButton aria-label="close" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        )}
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        <Typography variant="body2" display="inline">
          {body}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!noCancelNeeded && (
          <Button onClick={handleCancel || handleClose}>{buttonClose}</Button>
        )}

        <Button
          className={classes.btnPopup}
          variant="contained"
          disabled={disabled}
          onClick={() => {
            handleClose()
            mutation()
          }}
        >
          {button}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
