import React, {useState, useContext} from 'react'
import {
  PaperContainer,
  AddEditTitle,
  FormToolbar,
  FormTitleContainer,
} from '../../../../GlobalStyles'
import {
  IconButton,
  makeStyles,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  withStyles,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core'
import {DataContext} from '../../../../ContextConfig'
import {convertDate} from '../../../../utils/helpers'
import {withRouter, Link} from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import StepOne from './report-step/StepOne'
import StepTwo from './report-step/StepTwo'
// import StepThree from './report-step/StepThree'
import {useMutation} from '@apollo/react-hooks'
import {SAVE_INDIVIDUAL_PERFORMANCE} from '../../../../graphql/mutations'

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    color: '#039be5 !important',
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
  labelActive: {
    color: '#039be5 !important',
  },
  stepContainer: {
    padding: '2rem',
    paddingBottom: 0,
  },
}))

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const getSteps = () => {
  return [
    'Select',
    'Weight Scaling',
    // , 'Finalize'
  ]
}

const ReportComponent = () => {
  const classes = useStyles()
  const {dataContext, setDataContext} = useContext(DataContext)
  const [activeStep, setActiveStep] = useState(0)
  const [isError, setIsError] = useState(false)

  const [notification, setNoification] = useState({
    open: false,
    message: '',
  })

  const forDataMutation = {
    name: dataContext && dataContext.name,
    start_date: dataContext && convertDate(dataContext.start_date),
    end_date: dataContext && convertDate(dataContext.end_date),
    kpi_weight: parseInt(dataContext && dataContext.kpiWeight),
    multirater_weight: parseInt(dataContext && dataContext.multilaterWeight),
    ogf_weight: parseInt(dataContext && dataContext.npsWeight),
    nps_weight: parseInt(dataContext && dataContext.ogfWeight),
    performance_individual_candidates: {
      data: [{job_profile: dataContext && dataContext.position}],
    },
  }

  const steps = getSteps()

  const [saveData] = useMutation(SAVE_INDIVIDUAL_PERFORMANCE, {
    variables: {
      objects: [forDataMutation],
    },
  })

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const totalWeight =
    parseInt(dataContext && dataContext.kpiWeight) +
    parseInt(dataContext && dataContext.multilaterWeight) +
    parseInt(dataContext && dataContext.npsWeight) +
    parseInt(dataContext && dataContext.ogfWeight)

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <StepOne
            allDatas={dataContext}
            setAllData={setDataContext}
            isError={isError}
            setIsError={setIsError}
          />
        )
      case 1:
        return (
          <StepTwo
            allDatas={dataContext}
            setAllData={setDataContext}
            isError={isError}
            totalWeight={totalWeight}
          />
        )
      // case 2:
      //   return <StepThree stepOne={stepOne} stepTwo={stepTwo} />
      default:
        return 'Unknown stepIndex'
    }
  }

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (
          !dataContext.name ||
          !dataContext.start_date ||
          !dataContext.end_date
          // || !dataContext.position
        ) {
          setIsError(true)
          setTimeout(() => {
            setIsError(false)
          }, 3000)
        } else {
          setActiveStep(prevActiveStep => prevActiveStep + 1)
        }
        break
      case 1:
        if (totalWeight !== 100) {
          setIsError(true)
          setTimeout(() => {
            setIsError(false)
          }, 3000)
        } else {
          saveData()
            .then(() => {
              setNoification({
                open: true,
                message: 'All Report have been sent successfully',
              })
            })
            .catch(error => {
              setNoification({
                open: true,
                message: error,
              })
            })
          // setActiveStep(prevActiveStep => prevActiveStep + 1)
        }
        break
      // case 2:
      //   saveData()
      //     .then(() => {
      //       setNoification({
      //         open: true,
      //         message: 'All Report have been sent successfully',
      //       })
      //     })
      //     .catch(error => {
      //       setNoification({
      //         open: true,
      //         message: error,
      //       })
      //     })
      //   break
      default:
        setActiveStep(prevActiveStep => prevActiveStep + 1)
        break
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <PaperContainer>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <Link
            to={{
              pathname: '/performance/individual',
              state: {active: 'individual-performance'},
            }}
          >
            {/* <IconButton onClick={() => localStorage.clear()} edge="end" aria-label="back"> */}
            <IconButton edge="end" aria-label="back">
              <ArrowBack />
            </IconButton>
          </Link>
          <AddEditTitle variant="h6">Generate Report</AddEditTitle>
        </FormTitleContainer>
        <div>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.backButton}
          >
            Previous
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {activeStep === steps.length - 1 ? 'Send Report' : 'Next'}
          </Button>
        </div>
      </FormToolbar>
      <Divider />
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomConnector />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel
              classes={{
                active: classes.labelActive,
                completed: classes.labelActive,
              }}
              StepIconProps={{
                classes: {
                  root: classes.iconRoot,
                  text: classes.iconText,
                  active: classes.iconActive,
                  completed: classes.iconActive,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div style={{padding: '2rem'}}>{getStepContent(activeStep)}</div>

      <Dialog
        open={notification.open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{borderBottom: '2px solid #f7f8f9', color: '#014a62'}}
          id="alert-dialog-slide-title"
        >
          Send All Report
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{color: '#000000'}}
          >
            {notification.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link
            style={{
              backgroundColor: '#014a62',
              borderRadius: '5px',
            }}
            to={{
              pathname: '/performance/individual',
              state: {active: 'individual-performance'},
            }}
          >
            <Button color="primary">
              <span style={{color: '#ffffff'}}>Finish</span>
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </PaperContainer>
  )
}

export default withRouter(ReportComponent)
