import gql from 'graphql-tag'

export const GET_ORGANIZATION = gql`
  {
    company_organizations(order_by: {name: asc}) {
      id
      name
    }
  }
`

export const GET_ORGANIZATION_WITH_SEARCH = gql`
  query($search: String) {
    company_organizations(
      where: {name: {_ilike: $search}}
      order_by: {name: asc}
    ) {
      id
      name
    }
  }
`
