import Dashboard from './DashboardComponent'

export const DashboardConfig = {
  routes: [
    {
      path: '/dashboard',
      component: Dashboard,
      state: 'dashboard',
      exact: false,
    },
    {
      path: '/holding-dashboard',
      component: Dashboard,
      state: 'holding-dashboard',
      exact: false,
    },
  ],
}
