import {useMutation, useQuery} from '@apollo/react-hooks'
import {Paper, Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import ArrowBack from '@material-ui/icons/ArrowBack'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import Loading from '../../../../components/loading/LoadingComponent'
import {
  AddEditTitle,
  FormTitleContainer,
  FormToolbar,
} from '../../../../GlobalStyles'
import {ADD_NEW_GOALS, UPDATE_GOAL} from '../../../../graphql/mutations'
import {
  ADD_TASK,
  UPDATE_REPORTER_TASK,
} from '../../../../graphql/mutations/task/addTask.query'
import {EDIT_TASK} from '../../../../graphql/mutations/task/editTask.query'
import {
  GET_DETAIL_GOALS_BY_ID_EDIT,
  GET_DETAIL_TASK,
} from '../../../../graphql/queries'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {capitalize} from '../../../../utils/helpers'
import AddEditPopupFreeText from '../../../shared-components/popup/AddEditPopupFreeText'
import AddTask from '../modal/tabs/AddTask'
import {isErrorDetected} from '../modal/tabs/validate'
import {
  CustomConnector,
  formGoalStyles,
  getStepContent,
  getSteps,
} from './FormGoalConfig'
import DetailStep from './step/DetailStep'

const FormGoal = props => {
  const {state, match, history} = props
  const {enqueueSnackbar} = useSnackbar()
  const editId = match.params.id
  const isGoal = state.feature === 'Goal'
  const classes = formGoalStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps(isGoal)
  const [value, setValue] = useState([])
  const [id, setId] = useState('')
  const [idPerspective, setIdPerspective] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [confirmType, setConfirmType] = useState('')

  const [openPopup, setOpenPopup] = useState(false)
  const [change, setChange] = useState({
    goal: '',
    visibility: '',
    description: '',
    weight: '',
    targetBase: '',
    targetStretch: '',
    start: new Date(),
    end: new Date(),
    priority: '',
    attachment: [],
  })

  const [isError, setIsError] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState([])
  const [colorPicker, setColorPicker] = useState(false)
  const [label, setLabel] = useState({
    open: false,
    name: '',
    color: '#055469',
  })
  const [colorOptions, setColorOptions] = useState([
    {color: '#ef4d5e'},
    {color: '#a9a8a8'},
    {color: '#ffa000'},
    {color: '#007fb2'},
    {color: '#4caf50'},
    {color: '#a35a31'},
    {color: '#874caf'},
    {color: '#f04ba0'},
  ])

  // start for edit
  const {data} = useQuery(GET_DETAIL_GOALS_BY_ID_EDIT, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: editId,
    },
    skip: isGoal ? !editId : true,
  })

  const {data: dataTask, error} = useQuery(GET_DETAIL_TASK, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: editId,
    },
    skip: !isGoal ? !editId : true,
  })

  useEffect(() => {
    // for edit only
    if (data) {
      setDisabled(false)
      const {
        name,
        visibility,
        description,
        attachment,
        weight,
        target,
        startdate,
        enddate,
        priority,
        performance_goal_group,
      } = data.performance_goals[0]

      setChange({
        ...change,
        goal: name,
        visibility,
        description,
        weight,
        targetBase: target,
        start: startdate,
        end: enddate,
        priority,
        attachment,
        group: performance_goal_group?.id,
      })

      if (performance_goal_group) {
        setSelectedGroup([
          {
            idGroup: performance_goal_group?.id,
            value: performance_goal_group?.name,
            label: performance_goal_group?.name,
            color: performance_goal_group?.color,
          },
        ])
      }
    }
    if (dataTask) {
      const dataTasks = dataTask.performance_tasks[0]
      const dataAssigment = dataTasks.performance_task_assignments.map(
        assigment => ({user: assigment.global_user.id})
      )

      setChange({
        ...change,
        name: dataTasks.name,
        description: dataTasks.description,
        target: dataTasks.target,
        progress: dataTasks.progress,
        weight: dataTasks.weight,
        aspect:
          dataTasks?.kpi_aspect?.id ||
          dataTasks?.performance_goal?.kpi_list?.kpi_aspect?.id ||
          null,
        priority: dataTasks.priority,
        visibility: dataTasks.visibility,
        group: dataTasks.group,
        startdate: dataTasks.startdate,
        enddate: dataTasks.enddate,
        metric: dataTasks.metric,
        method_type: dataTasks.method_type,
        attachment: dataTasks.attachment,
        goal: dataTasks.performance_goal.id,
        performance_task_assignments: {data: dataAssigment},
        reporter: {
          label: dataTasks.ReporterUser?.id || dataTasks.created_by_user?.id,
        },
        target_unit:
          dataTasks.kpi_weight_unit.id ||
          dataTasks.performance_goal.kpi_list.kpi_weight_unit?.id ||
          null,
      })
    }
  }, [data, dataTask])

  const [updateGoals] = useMutation(UPDATE_GOAL)
  const [mutationAddTask] = useMutation(ADD_TASK)
  const [mutationEdit] = useMutation(EDIT_TASK)
  const [updateReporter] = useMutation(UPDATE_REPORTER_TASK)

  // end for edit

  const [addGoals] = useMutation(ADD_NEW_GOALS, {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-staff',
      },
    },
  })

  if (!isGoal && editId && dataTask === undefined && error === undefined) {
    return <Loading />
  }

  if (error) {
    console.error({error})
  }

  const dataDetail = data?.performance_goals[0]
  const dataEdit = dataTask?.performance_tasks?.[0]

  const name = dataDetail?.name || dataEdit?.name
  const dataKpi = dataDetail?.kpi_list || dataEdit?.performance_goal?.kpi_list
  const dataDateGoal = {
    startDate: dataEdit?.performance_goal?.startdate,
    endDate: dataEdit?.performance_goal?.enddate,
  }

  const TYPE_HEADER_ALERT = {
    Buang: 'Buang perubahan?',
    add: 'Add Goal List?',
    confirm: 'Add New Task?',
    edit: `Edit ${name}?`,
  }

  const TYPE_BODY_ALERT = {
    Buang: 'Apakah anda yakin ingin membuang perubahan yang belum disimpan?',
    add: 'Are you sure you want to add this goal list?',
    confirm: (
      <>
        <Typography variant="body2" component="span">
          Are you sure to add new task to
        </Typography>
        <Typography variant="body2" component="span" style={{color: '#039BE5'}}>
          {` [PF-${value.id}] - ${value.name}`}
        </Typography>
        <Typography variant="body2" component="span">
          ?
        </Typography>
      </>
    ),
    edit: (
      <>
        <Typography variant="body2" component="span">
          Are you sure you want to edit goal list -
        </Typography>
        <Typography variant="body2" component="span" style={{color: '#039BE5'}}>
          {` ${name}`}
        </Typography>
        <Typography variant="body2" component="span">
          ?
        </Typography>
      </>
    ),
  }

  const handleChange = name => e => {
    setChange({...change, [name]: e.target.value})
  }
  const handleDate = (name, date) => {
    setChange({...change, [name]: date})
  }
  const handleChangeRadio = (e, data, id) => {
    setDisabled(false)
    setId(e.target.value)
    setValue(data)
    setIdPerspective(id)
    !isGoal && setChange({...change, metric: data.kpi_list.formula})
  }

  const validationProcess = fallback => {
    if (isGoal) {
      if (
        change.goal === '' ||
        change.description === '' ||
        change.targetBase === '' ||
        change.priority === '' ||
        moment(change.start).isAfter(change.end) ||
        !change.end ||
        !change.start
      ) {
        setIsError(true)
        setTimeout(() => setIsError(false), 10000)
      } else {
        fallback()
      }
    } else {
      if (
        isErrorDetected(change) ||
        change?.name?.length > 255 ||
        change?.metric?.length > 250
      ) {
        setIsError(true)
        setTimeout(() => setIsError(false), 10000)
      } else {
        fallback()
      }
    }
  }

  const handlePopup = type => {
    setConfirmType(type)
    setOpenPopup(true)
  }

  const handleNext = () => {
    // for add
    if (activeStep === 1) {
      validationProcess(() => handlePopup(isGoal ? 'add' : 'confirm'))
    } else {
      if (editId) {
        validationProcess(() => handlePopup('edit'))
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    }
  }

  const handleBack = () => {
    // for add
    if (activeStep === 1) {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
    if (activeStep === 0) {
      handlePopup('Buang')
    }
  }

  const handleBackButton = () => {
    history.push({
      pathname: '/performance/goal',
      state: {active: 'goal', searchable: true, feature: state.feature},
    })
  }

  const mutationGoals = () => {
    setDisabled(true)
    if (isGoal) {
      const object = {
        kpi: value.id,
        name: change.goal,
        description: change.description,
        target: change.targetBase,
        group: change.group,
        priority: change.priority,
        startdate: change.start,
        enddate: change.end,
        status: 'OPEN',
        attachment: change.attachment,
        cascading_kpi: value.kpi_cascading_lists?.[0].id,
      }

      if (editId) {
        delete object.kpi
        delete object.status
        delete object.cascading_kpi
        object.id = editId
      }

      const _mutation = editId
        ? updateGoals({
            variables: object,
          })
        : addGoals({variables: {object}})

      _mutation
        .then(() => {
          enqueueSnackbar(`Success ${capitalize(confirmType)} Goals`, {
            variant: 'success',
            autoHideDuration: 3000,
          })
          handleBackButton()
        })
        .catch(() => {
          enqueueSnackbar(`Error ${capitalize(confirmType)} goals`, {
            variant: 'error',
            autoHideDuration: 3000,
          })
        })
    } else {
      const object = {
        name: change.name,
        description: change.description,
        target: change.target,
        progress: change.progress,
        weight: change.target_unit,
        aspect: change.aspect,
        priority: change.priority,
        visibility: change.visibility,
        method_type: change.method_type,
        startdate: change.startdate,
        enddate: change.enddate,
        metric: change.metric,
        attachment: change.attachment,
        goal: value.id,
        group: change.group,
        performance_task_assignments: change.performance_task_assignments,
        target_unit: change.target_unit,
      }

      const objectEdit = {
        ...object,
        id: dataEdit?.id,
        goal: dataEdit?.performance_goal.id,
      }
      const task = []
      change.performance_task_assignments.data.map(res => {
        task.push({task: dataEdit?.id, user: res.user})
      })
      objectEdit.assignment = task
      delete objectEdit.performance_task_assignments
      delete objectEdit.method_type
      delete objectEdit.metric
      delete objectEdit.target_unit

      const _mutation = editId ? mutationEdit : mutationAddTask
      const variable = editId ? objectEdit : {object}

      _mutation({
        variables: variable,
      })
        .then(() => {
          if (editId) {
            updateReporter({
              variables: {
                id: dataEdit?.id,
                reporter: change.reporter.label,
              },
            })
              .then(() => {
                enqueueSnackbar(`Success Edit Task`, {
                  variant: 'success',
                  autoHideDuration: 3000,
                })
                handleBackButton()
              })
              .catch(() => {
                enqueueSnackbar(`Error update reporter`, {
                  variant: 'error',
                  autoHideDuration: 3000,
                })
              })
          } else {
            enqueueSnackbar(`Success Add Task`, {
              variant: 'success',
              autoHideDuration: 3000,
            })
            handleBackButton()
          }
        })
        .catch(() => {
          enqueueSnackbar(`Error ${capitalize(confirmType)} task`, {
            variant: 'error',
            autoHideDuration: 3000,
          })
        })
    }
  }

  const _renderContent = () =>
    editId ? (
      <div className={classes.instructions}>
        {isGoal ? (
          <DetailStep
            dataKpi={dataKpi}
            handleChange={handleChange}
            change={change}
            handleDate={handleDate}
            isError={isError}
            setChange={setChange}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            colorPicker={colorPicker}
            setColorPicker={setColorPicker}
            label={label}
            setLabel={setLabel}
            colorOptions={colorOptions}
            setColorOptions={setColorOptions}
          />
        ) : (
          <AddTask
            idGoals={id}
            dataKpi={dataKpi}
            dataDateGoal={dataDateGoal}
            setDataCreate={setChange}
            dataCreate={change}
            isErrorTask={isError}
            dataEdit={dataEdit || null}
          />
        )}
      </div>
    ) : (
      <>
        <Stepper
          className={classes.steperPosition}
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomConnector />}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                classes={{
                  active: classes.labelActive,
                  completed: classes.labelActive,
                }}
                StepIconProps={{
                  classes: {
                    root: classes.iconRoot,
                    text: classes.iconText,
                    active: classes.iconActive,
                    completed: classes.iconActive,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.instructions}>
          {getStepContent(
            activeStep,
            handleChangeRadio,
            id,
            idPerspective,
            value,
            handleChange,
            change,
            setChange,
            handleDate,
            isError,
            selectedGroup,
            setSelectedGroup,
            colorPicker,
            setColorPicker,
            label,
            setLabel,
            colorOptions,
            setColorOptions,
            isGoal
          )}
        </div>
      </>
    )

  const _renderButton = () => (
    // editId ? (
    //   <Button
    //     // disabled={activeStep === 0}
    //     onClick={() => validationProcess(() => handlePopup('edit'))}
    //     color="primary"
    //     size="large"
    //     variant="contained"
    //   >
    //     Save
    //   </Button>
    // ) : (
    <div style={{width: '27%'}}>
      <Button
        onClick={handleBack}
        style={{border: activeStep !== 0 && '1px solid #014A62', width: 125}}
        className={classes.backButton}
        size="large"
      >
        {activeStep === 0 ? 'Batalkan' : 'Kembali'}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
        disabled={!editId && !value?.id}
        size="large"
      >
        {activeStep === steps.length - 1 || editId ? 'Simpan' : 'Selanjutnya'}
      </Button>
    </div>
  )
  // )

  return (
    <Paper className={classes.paper}>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton
            edge="end"
            aria-label="back"
            onClick={() => handlePopup('Buang')}
          >
            <ArrowBack style={{color: '#014A62'}} />
          </IconButton>
          <AddEditTitle variant="h6" style={{fontWeight: 'normal'}}>
            {`${editId ? 'Ubah' : 'Buat'} Daftar ${
              isGoal
                ? GLOBAL_GLOSSARY.performance.Goal
                : GLOBAL_GLOSSARY.performance.Task
            } ${editId && isGoal ? `- ${name}` : ''}`}
          </AddEditTitle>
        </FormTitleContainer>

        {_renderButton()}
      </FormToolbar>
      <Divider />
      {_renderContent()}

      <AddEditPopupFreeText
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        button={confirmType}
        body={TYPE_BODY_ALERT[confirmType]}
        header={TYPE_HEADER_ALERT[confirmType]}
        mutation={confirmType === 'Buang' ? handleBackButton : mutationGoals}
        disabled={disabled}
      />
    </Paper>
  )
}

export default withRouter(FormGoal)

// eslint-disable-next-line no-unused-vars
const CONFIRMATION = {
  add: {
    header: `Tambah Daftar ${GLOBAL_GLOSSARY.performance.Goal_short}?`,
    body: `Apakah anda yakin ingin menambahkan daftar ${GLOBAL_GLOSSARY.performance.Goal_short} ini?`,
    button: 'Tambahkan',
  },
  edit: {
    header: `Ubah [name]?`,
    body: `Apakah anda yakin ingin mengubah Daftar ${GLOBAL_GLOSSARY.performance.Goal_short} - [name]`,
    button: 'Ubah',
  },
  Buang: {
    header: `Buang Perubahan?`,
    body: `Apakah Anda yakin ingin membuang perubahan yang belum disimpan?`,
    button: 'Buang',
  },
}
