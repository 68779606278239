import gql from 'graphql-tag'

export const UPDATE_EMPLOYEE_SALARY = gql`
  mutation(
    $user_work_id: Int!
    $currency: String
    $value: float8
    $bank: Int
    $salary_fields: jsonb #ptkp TK/0 ~ TK/3, K/0 ~ K/3, K/I/0 ~ K/I/3
  ) {
    insert_people_work_salaries_one(
      object: {
        user_work_id: $user_work_id
        currency: $currency
        value: $value
        active: true
        salary_fields: $salary_fields
        primary_bank: $bank
      }
    ) {
      id
    }
  }
`
