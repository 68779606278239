import React from 'react'
import {
  DialogContent,
  Typography,
  Dialog,
  IconButton,
  Icon,
  Paper,
  Avatar,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {GreyTypography, PrimaryModalHeader} from '../../../../GlobalStyles'
import {capitalize} from '../../../../utils/helpers'
import {makeStyles} from '@material-ui/styles'
import Moment from 'moment'
const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},

  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnPopup: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {
    wordBreak: 'break-all',
    color: '#fff',
    textTransform: 'capitalize',
  },
  word: {wordBreak: 'break-all'},
  close: {
    color: '#fff',
  },
  back: {
    color: '#fff',
    marginRight: '1rem',
  },
  divBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  paper2: {
    padding: 24,
    marginTop: '1rem',
  },
  talentDetailTalentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
  },
  talentDetail: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  talentDetailScore: {
    marginTop: '2rem',

    marginLeft: '1rem',
  },
  score: {
    color: '#4caf50',
    marginBottom: '1rem',
  },
  subScore: {
    marginBottom: '1rem',
    textTransform: 'capitalize',
  },
  talentDetailName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  talentInfoPaper: {
    display: 'flex',
    flexGrow: '1',
    textAlign: 'center',
    flexDirection: 'column',
    margin: '20px 0px 0px 0px',
    padding: 25,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  talentDetailInfo: {
    marginBottom: 10,
  },
  divUser: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameUser: {
    marginLeft: '1rem',
  },
  divBadge: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  regno: {
    color: '#a9a8a8',
  },
  job: {
    marginTop: '1rem',
  },
  divAvatar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divName: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
  },
  divBadges: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '2rem',
    marginBottom: '1rem',
    cursor: 'pointer',
  },
  divRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 24,
  },
  divScore: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  boxes: {
    width: '50%',
  },
  scores: {
    marginLeft: '1rem',
  },
  form: {
    marginBottom: 20,
  },
}))

export default function DialogBadgeUser(props) {
  const classes = useStyles()
  const {
    open,
    handleClose,
    data,
    setDetail,
    detail,
    all,
    setAll,
    committee,
    positions,
    condList,
    changeFilters,
  } = props
  const handleOpen = res => {
    setDetail(res)
    setAll(true)
  }
  const colorInformation = status => {
    if (status === 'HIGH') {
      return {
        fontWeght: 600,
        color: '#ef4d5e',
      }
    } else if (status === 'LOW') {
      return {
        fontWeght: 600,
        color: '#4caf50',
      }
    } else {
      return {
        fontWeght: 600,
        color: '#ffa000',
      }
    }
  }

  const xAxisValue = []
  const yAxisValue = []

  const switchFlexyComponents = (data, axis) => {
    let indicatorState
    let index
    switch (data.parameter) {
      case 'competency':
        indicatorState = 'indicator_pot_competence'
        index = 'Index'
        break
      case 'attendance':
        indicatorState = 'indicator_pot_attendance'
        index = 'Attendances'
        break
      case 'course':
        indicatorState = 'indicator_pot_total_course'
        index = 'Courses'
        break
      case 'socmed_post':
        indicatorState = 'indicator_pot_socmed_post'
        index = 'Posts'
        break
      case 'task':
        indicatorState = 'indicator_perf_goal'
        index = 'Index'
        break
      case 'ogf':
        indicatorState = 'indicator_perf_ogf'
        index = 'Total'
        break
      case 'multirater':
        indicatorState = 'indicator_perf_multirater'
        index = 'Total'
        break
      case 'nps':
        indicatorState = 'indicator_perf_nps'
        index = 'Total'
        break
    }

    const newAxisValue = {
      param: data.parameter,
      value: detail[indicatorState].toFixed(2),
      index,
    }

    if (axis === 'x') {
      xAxisValue.push(newAxisValue)
    } else {
      yAxisValue.push(newAxisValue)
    }
  }

  if (!committee) {
    if (detail && detail.talentVersionByTalentVersion.flexy_components) {
      detail.talentVersionByTalentVersion.flexy_components.x.parameters.forEach(
        axis => switchFlexyComponents(axis, 'x')
      )
      detail.talentVersionByTalentVersion.flexy_components.y.parameters.forEach(
        axis => switchFlexyComponents(axis, 'y')
      )
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <PrimaryModalHeader>
        <div className={classes.divBack}>
          {all ? (
            <IconButton
              aria-label="close"
              onClick={() => {
                setDetail(null)
                setAll(false)
              }}
              className={classes.back}
            >
              <Icon>keyboard_backspace</Icon>
            </IconButton>
          ) : null}
          <Typography variant="h6" color="error" className={classes.wordBreak}>
            {committee ? 'Committee' : 'Talent Details'}
          </Typography>
        </div>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.close}
        >
          <Icon>close</Icon>
        </IconButton>
      </PrimaryModalHeader>
      {detail && !committee ? (
        <DialogContent className={classes.content}>
          <Paper className={classes.paper}>
            <div className={classes.divUser}>
              <Avatar
                src={detail && detail.people_work_placement.global_user.avatar}
                style={{width: '5vw', height: '5vw'}}
              />
              <div className={classes.nameUser}>
                <Typography variant="body1">
                  {detail && detail.people_work_placement.global_user.name}
                </Typography>
                <Typography variant="body1" className={classes.regno}>
                  {detail && detail.people_work_placement.regno}
                </Typography>
                <Typography variant="body1" className={classes.job}>
                  {detail &&
                    detail.people_work_placement.company_job_profile.title}
                </Typography>
              </div>
            </div>
            <div className={classes.divBadge}>
              <img
                src={detail && detail.talent_box.badge}
                alt={detail && detail.talent_box.name}
                style={{width: '3vw'}}
              />
              <Typography variant="body1">
                {detail && detail.talent_box.name}
              </Typography>
              <Typography variant="body1">
                {detail && detail.talent_score.toFixed(2)}
              </Typography>
            </div>
          </Paper>
          <div className={classes.talentDetailTalentInfo}>
            <Paper className={classes.talentInfoPaper}>
              <Typography variant="body1" className={classes.talentDetailInfo}>
                Risk of Loss
              </Typography>
              <Typography
                variant="body1"
                style={colorInformation(
                  detail &&
                    detail.people_work_placement.placement_fields
                      .talent_information.risk_of_loss
                )}
              >
                {capitalize(
                  detail
                    ? detail &&
                        detail.people_work_placement.placement_fields
                          .talent_information.risk_of_loss
                    : 'test'
                )}
              </Typography>
            </Paper>
            <Paper
              className={classes.talentInfoPaper}
              style={{marginRight: '1rem', marginLeft: '1rem'}}
            >
              <Typography variant="body1" className={classes.talentDetailInfo}>
                Impact of Loss
              </Typography>
              <Typography
                variant="body1"
                style={colorInformation(
                  detail &&
                    detail.people_work_placement.placement_fields
                      .talent_information.impact_of_loss
                )}
              >
                {capitalize(
                  detail
                    ? detail &&
                        detail.people_work_placement.placement_fields
                          .talent_information.impact_of_loss
                    : 'test'
                )}
              </Typography>
            </Paper>

            <Paper className={classes.talentInfoPaper}>
              <Typography className={classes.talentDetailInfo} variant="body1">
                Future Leader
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontWeight: '600',
                  color:
                    detail &&
                    detail.people_work_placement.placement_fields
                      .talent_information.future_leader
                      ? '#4caf50'
                      : '#ef4d5e',
                }}
              >
                {detail &&
                detail.people_work_placement.placement_fields.talent_information
                  .future_leader
                  ? 'Yes'
                  : 'No'}
              </Typography>
            </Paper>
          </div>
          <Paper className={classes.talentInfoPaper}>
            <Typography variant="body1" className={classes.talentDetailInfo}>
              Talent Mobility
            </Typography>
            <Typography
              variant="body1"
              style={{fontWeight: '600', color: '#a9a8a8'}}
            >
              {detail &&
              detail.people_work_placement.placement_fields.talent_information
                .reason_for_leaving === ''
                ? '-'
                : detail &&
                  detail.people_work_placement.placement_fields
                    .talent_information.reason_for_leaving}
            </Typography>
          </Paper>
          <Paper className={classes.paper2} elevation={2}>
            <div className={classes.talentDetail}>
              <Typography variant="h6">
                {detail &&
                  detail.talentVersionByTalentVersion.flexy_components &&
                  detail.talentVersionByTalentVersion.flexy_components.x.name}
              </Typography>
              <Typography variant="body1">
                Last Update on{' '}
                {Moment(detail && detail.date_modified).format('DD/MM/YYYY')}
              </Typography>
            </div>
            <div className={classes.talentDetailScore}>
              {xAxisValue.length > 0 &&
                xAxisValue.map((x, i) => (
                  <div className={classes.talentDetailName} key={i}>
                    <Typography variant="body1" className={classes.subScore}>
                      {x.param === 'ogf' ? 'Ongoing Feedback' : x.param} (
                      {x.index})
                    </Typography>
                    <Typography variant="body1" className={classes.score}>
                      {x.value}
                    </Typography>
                  </div>
                ))}
            </div>
          </Paper>
          <Paper className={classes.paper2} elevation={2}>
            <div className={classes.talentDetail}>
              <Typography variant="h6">
                {detail &&
                  detail.talentVersionByTalentVersion.flexy_components &&
                  detail.talentVersionByTalentVersion.flexy_components.y.name}
              </Typography>
              <Typography variant="body1">
                Last Update on{' '}
                {Moment(detail && detail.date_modified).format('DD/MM/YYYY')}
              </Typography>
            </div>
            <div className={classes.talentDetailScore}>
              {yAxisValue.length > 0 &&
                yAxisValue.map((y, i) => (
                  <div className={classes.talentDetailName} key={i}>
                    <Typography variant="body1" className={classes.subScore}>
                      {y.param === 'ogf' ? 'Ongoing Feedback' : y.param} (
                      {y.index})
                    </Typography>
                    <Typography variant="body1" className={classes.score}>
                      {y.value}
                    </Typography>
                  </div>
                ))}
            </div>
          </Paper>
        </DialogContent>
      ) : detail && committee ? (
        <DialogContent className={classes.content}>
          <Grid container spacing={3} className={classes.form}>
            <Grid item xs={12} sm={2}>
              <TextField
                size="small"
                variant="outlined"
                select
                value={condList.sort}
                onChange={changeFilters('sort')}
                fullWidth
              >
                <MenuItem value="asc">A-Z</MenuItem>
                <MenuItem value="desc">Z-A</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                placeholder="Search"
                variant="outlined"
                size="small"
                onChange={changeFilters('search')}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                variant="outlined"
                // className={classes.input}
                select
                placeholder="Type Position here"
                value={condList.group || 'all'}
                onChange={changeFilters('group')}
                fullWidth
              >
                <MenuItem value="all">All Position</MenuItem>
                {positions &&
                  positions.map((option, i) => (
                    <MenuItem key={i} value={option.id}>
                      {option?.company_job_profile?.title}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {data &&
              data.talent_committees.map((val, key) => {
                return (
                  <Grid
                    item
                    xs={1}
                    sm={4}
                    key={key}
                    className={classes.divAvatar}
                  >
                    <Avatar src={val?.global_user?.avatar} />
                    <div className={classes.nameUser}>
                      <Typography variant="body1">
                        {val?.global_user?.name}
                      </Typography>
                      <GreyTypography
                        variant="body2"
                        className={classes.position}
                      >
                        {val.global_user.people_work_placements.length > 0
                          ? val.global_user.people_work_placements[0]
                              ?.company_job_profile?.title
                          : ''}
                      </GreyTypography>
                    </div>
                  </Grid>
                )
              })}
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent className={classes.content}>
          {data &&
            data.talent_assignments.map((res, i) => {
              return (
                <div
                  key={i}
                  className={classes.divBadges}
                  onClick={() => handleOpen(res)}
                >
                  <div className={classes.divAvatar}>
                    <Avatar
                      src={res.people_work_placement.global_user.avatar}
                    />
                    <div className={classes.divName}>
                      <Typography
                        variant="body1"
                        // onClick={() => handleOpen(res, 'detail')}
                        style={{cursor: 'pointer'}}
                      >
                        {res.people_work_placement.global_user.name}
                      </Typography>
                      <GreyTypography
                        variant="body2"
                        className={classes.position}
                      >
                        {res.people_work_placement.company_job_profile.title}
                      </GreyTypography>
                    </div>
                  </div>
                  <div className={classes.divScore}>
                    <img
                      src={res.talent_box.badge}
                      alt={res.talent_box.name}
                      style={{width: '2vw'}}
                    />
                    <Typography variant="body1" className={classes.scores}>
                      {res.talent_score.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              )
            })}
        </DialogContent>
      )}
    </Dialog>
  )
}
