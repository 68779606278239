import React from 'react'
import {TerminationStyle} from '../DetailEmployeeStyles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

export default function TerminationSection({info, description, id}) {
  return (
    <TerminationStyle id={id}>
      <InfoOutlinedIcon className="icon" />
      <div className="content">
        <h5 className="title">{info}</h5>
        <p className="desc">{description}</p>
      </div>
    </TerminationStyle>
  )
}
