import gql from 'graphql-tag'

export const GET_MICRO_LEARNING_BY_ID = gql`
  query($id: uuid, $createdByOrgId: uuid!) {
    microlearnings(
      where: {id: {_eq: $id}, created_by_org_id: {_eq: $createdByOrgId}}
      order_by: [{date_added: asc}]
    ) {
      id
      slug
      description
      total_finished
      total_earned
      enroll_count: microlearning_members_aggregate(
        where: {is_valid: {_eq: true}}
      ) {
        aggregate {
          count
        }
      }
      microlearning_reviews_aggregate {
        aggregate {
          avg {
            star
          }
          count
        }
      }
      academy_course_category {
        id
        name
        parent: academy_course_category {
          id
          name
        }
      }
      global_user {
        id
        name
        avatar
      }
      global_language {
        id
        name
      }
      question_count: microlearning_questions_aggregate {
        aggregate {
          count
        }
      }
      name
      date_added
      status
      price
      type
      icon_url
      level
      topic
      subtitle
    }
  }
`

export const GET_QUESTIONS_BY_MICROLEARNING_ID = gql`
  query($id: uuid, $offset: Int, $limit: Int) {
    microlearnings(where: {id: {_eq: $id}}) {
      id
      microlearning_questions_aggregate {
        aggregate {
          count
        }
      }
      microlearning_questions(
        offset: $offset
        limit: $limit
        where: {deleted_at: {_is_null: true}}
        order_by: {date_added: asc_nulls_last}
      ) {
        id
        question
        date_added
        question_type
        microlearning_question_answers {
          answer
          is_correct
        }
      }
    }
  }
`

export const GET_RESPONSE_BY_MICROLEARNING_ID = gql`
  query($id: uuid!, $userId: uuid, $offset: Int, $limit: Int, $query: String) {
    microlearnings(where: {id: {_eq: $id}}) {
      id
      name
      icon_url
      question_count
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      microlearning_members(
        where: {user_id: {_eq: $userId}, global_user: {name: {_ilike: $query}}}
        offset: $offset
        limit: $limit
      ) {
        id
        global_user {
          id
          name
          email
          avatar
        }
        schedule
        schedule_time
        score
        is_finished
        daily_question
        expired_unanswered_question_count
        question_answered_count
      }
    }
  }
`

export const GET_FILTER_BY_MICROLEARNING_ID = gql`
  query($microlearningId: uuid) {
    microlearning_organization_assignments(
      where: {microlearning_id: {_eq: $microlearningId}}
    ) {
      id
      company_id
      microlearning_id
      filter
    }
  }
`

export const GET_MICROLEARNING_INDIVIDUAL_REPONSE = gql`
  query($microlearningId: uuid!, $userId: uuid!) {
    microlearnings(where: {id: {_eq: $microlearningId}}) {
      id
      name
      status
      question_count
      microlearning_members(where: {user_id: {_eq: $userId}}) {
        id
        score
        question_answered_count
        expired_unanswered_question_count
        global_user {
          id
          name
          avatar
        }
      }
      microlearning_questions {
        question
        question_type
        microlearning_question_answers {
          id
          answer
          is_correct
          microlearning_member_answer_options(
            where: {
              microlearning_member_answer: {
                is_answered: {_eq: true}
                microlearning_member_daily: {
                  microlearning_member: {user_id: {_eq: $userId}}
                }
              }
            }
          ) {
            id
          }
        }
        microlearning_member_answers(
          where: {
            is_answered: {_eq: true}
            microlearning_member_daily: {
              microlearning_member: {user_id: {_eq: $userId}}
            }
          }
        ) {
          id
          score
          description
          date_answered
        }
      }
    }
  }
`
export const GET_MICROLEARNING_SCHEDULE = gql`
  query($id: uuid, $createdByOrgId: uuid) {
    microlearning_members(
      where: {
        microlearning: {
          id: {_eq: $id}
          created_by_org_id: {_eq: $createdByOrgId}
        }
      }
      order_by: {id: desc}
      limit: 1
    ) {
      id
      schedule
      schedule_time
      daily_question
    }
  }
`
