import gql from 'graphql-tag'
export const GET_DETAIL_GOAL = gql`
  query($id: bigint) {
    performance_goals(where: {id: {_eq: $id}}) {
      id
      name
      description
      global_user {
        id
        name
        avatar
      }
      globalUserByReporter {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
      kpi_list {
        id
        name
        type
        formula
        description
        kpi_scoring_nature {
          id
          name
          global_scoring_nature {
            id
            name
          }
        }
        kpi_weight_unit {
          id
          name
        }
        kpi_aspect {
          id
          name
        }
        kpi_level {
          id
          name
        }
        kpi_version {
          id
          name
        }
        kpi_method {
          id
          name
        }
        kpi_monitoring_frequency {
          id
          name
        }
        kpi_perspective {
          id
          name
        }
        kpi_cascading_lists(
          order_by: [{cascading_main: {status: asc}}]
          limit: 1
        ) {
          id
          cascading_main {
            id
            name
            status
          }
        }
        kpi_cascading_lists_aggregate {
          aggregate {
            count
          }
        }
      }
      target
      target_stretch
      weight
      startdate
      enddate
      priority
      visibility
      attachment
      status
      date_added
      progress
      progress_percentage
      performance_goal_group {
        id
        name
        color
      }
      performance_tasks_aggregate {
        aggregate {
          sum {
            progress
          }
        }
      }
      total_task: performance_tasks_aggregate {
        aggregate {
          count
        }
      }
      complete_task: performance_tasks_aggregate(
        where: {status: {_in: ["COMPLETED", "ARCHIVED"]}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_ASSIGNMENT_GOALS = gql`
  query($goal: bigint, $limit: Int) {
    total: performance_task_assignments_aggregate(
      where: {performance_task: {goal: {_eq: $goal}}}
      distinct_on: [user]
    ) {
      aggregate {
        count
      }
    }
    performance_task_assignments(
      where: {performance_task: {goal: {_eq: $goal}}}
      distinct_on: [user]
      limit: $limit
    ) {
      global_user {
        id
        avatar
        name
      }
    }
  }
`
