import React, {useState, useEffect} from 'react'
import {PaperContainer, EnhancedToolbar} from '../../../../GlobalStyles'
import {Divider, Button, TablePagination} from '@material-ui/core'
import {RegulationsContentWrapper} from '../RegulationsStyles'
import RegulationListItem from './RegulationListItem'
import {Link} from 'react-router-dom'
import {GET_REGULATION} from '../../../../graphql/queries'
import {DELETE_REGULATION, DELETE_CLAUSE} from '../../../../graphql/mutations'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {COMPANY_ID, DEFAULT_PAGINATION} from '../../../../utils/globals'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {useSnackbar} from 'notistack'
import NoDataListComponent from '../../../shared-components/NoDataListComponent'
import Skeleton from '@material-ui/lab/Skeleton'
import {useDebounced} from '@worklifebeyond/wlb-utils-components'

export default function RegulationsList({searchText}) {
  const {enqueueSnackbar} = useSnackbar()
  const [search] = useDebounced(searchText, 200)

  const [open, setOpen] = useState({
    delete: false,
    deleteId: null,
    model: '',
    deleteName: '',
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [rowPerPage, setRowPerPage] = useState(DEFAULT_PAGINATION[0])

  const [deleteRegulation] = useMutation(DELETE_REGULATION)
  const [deleteClause] = useMutation(DELETE_CLAUSE)

  const {data, error, loading, refetch} = useQuery(GET_REGULATION, {
    wlb_skipPatch: true,
    variables: {
      id: COMPANY_ID,
      key: `%${search}%`,
      limit: rowPerPage,
      offset: rowPerPage * currentPage,
    },
  })

  useEffect(() => {
    const setRefetch = setTimeout(() => {
      refetch()
    }, 1000)

    return () => {
      clearTimeout(setRefetch)
    }
  }, [])

  if (error) {
    console.error(error)
  }

  const totalData = data?.total.aggregate.count || 0

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowPerPage(+event.target.value)
    setCurrentPage(0)
  }

  const onDeleteRegulation = () => {
    const variables = {
      id: open && open.deleteId,
    }
    deleteRegulation({variables: variables})
      .then(() => {
        enqueueSnackbar('Regulation deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete Regulation failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const onDeleteClause = () => {
    const variables = {
      id: open && open.deleteId,
    }
    deleteClause({variables: variables})
      .then(() => {
        enqueueSnackbar('Clause deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete Clause failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  return (
    <>
      <PaperContainer>
        <EnhancedToolbar>
          Regulations
          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              style={{fontSize: 12}}
              to={{
                pathname: '/company/add-regulations',
                state: {active: 'add-regulations'},
              }}
            >
              Add New Regulations
            </Button>
          </div>
        </EnhancedToolbar>
        <Divider />
        <RegulationsContentWrapper>
          {loading &&
            Array.from({length: 6}, (_, i) => (
              <Skeleton
                key={`${i}-${_}`}
                variant="rect"
                width="100%"
                height="58px"
                style={{marginBottom: 20, borderRadius: 5}}
              />
            ))}
          {!loading &&
            data?.company_regulations.map((val, key) => {
              return (
                <RegulationListItem
                  setOpen={setOpen}
                  key={key}
                  data={val}
                  clauses={val.company_regulation_clauses}
                />
              )
            })}
          {!loading && totalData === 0 && <NoDataListComponent message={``} />}
        </RegulationsContentWrapper>
        <Divider />
        {data?.company_regulations.length > 0 && (
          <TablePagination
            rowsPerPageOptions={DEFAULT_PAGINATION}
            component="div"
            rowsPerPage={rowPerPage}
            page={currentPage}
            count={totalData}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </PaperContainer>

      <DeletePopup
        open={open.delete}
        handleClose={() =>
          setOpen({
            ...open,
            delete: false,
            deleteName: '',
            deleteId: null,
          })
        }
        id={open.deleteId}
        name={open.deleteName}
        mutation={
          open && open.model === 'Regulation'
            ? onDeleteRegulation
            : onDeleteClause
        }
      />
    </>
  )
}
