import React, { useState, useRef, useCallback, useEffect } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import helperStyle, {
  CropperPhoto,
  CropperBanner,
  WhiteModalHeader,
  FlexBetween,
  WrapperContainer,
  // ButtonDarkBlue,
} from '../../../GlobalStyles'
import {
  Slider,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
  withStyles,
  Button,
} from '@material-ui/core'
import classNames from 'classnames'
import { UPLOAD_URL, TOKEN, COMPANY_ID } from '../../../utils/globals'
import Axios from 'axios'
import {
  UPDATE_LOGO_COMPANY,
  UPDATE_BANNER_COMPANY,
} from '../../../graphql/mutations'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

const pixelRatio = 4

const PrettoSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

const getResizedCanvas = (canvas, newWidth, newHeight) => {
  const tmpCanvas = document.createElement('canvas')
  tmpCanvas.width = newWidth
  tmpCanvas.height = newHeight

  const ctx = tmpCanvas.getContext('2d')
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  )

  return tmpCanvas
}

const CropImagePopup = ({ open, onClose, refetch, title, feature }) => {
  const classes = helperStyle()
  const { enqueueSnackbar } = useSnackbar()
  const [upImg, setUpImg] = useState()
  const cropSize = title === 'Banner' ? { aspect: 3 / 1 } : { aspect: 1 }
  const [crop, setCrop] = useState(cropSize)
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [zoom, setZoom] = useState(0)
  const [loading, setloading] = useState(false)
  const [banner, setBanner] = useState('')
  const context = {
    context: {
      headers: {
        'X-Hasura-Role':
          feature === 'holding-profile'
            ? 'holding-administrator'
            : 'organization-hr-admin',
      },
    },
  }

  const [updateLogoCompany] = useMutation(UPDATE_LOGO_COMPANY, context)
  const [updateBannerCompany] = useMutation(
    UPDATE_BANNER_COMPANY(banner),
    context
  )

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingEnabled = false

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
  }, [completedCrop])

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  const handleClose = () => {
    setCompletedCrop(null)
    onClose()
  }

  const handleUpdateBanner = () => {
    updateBannerCompany({
      variables: {
        company: COMPANY_ID,
      },
    })
      .then(() => {
        setloading(false)
        handleClose()
        enqueueSnackbar('Change Banner success', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(err => {
        setloading(false)
        enqueueSnackbar(`Change Banner Error, please try again later, ${err}`, {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleUpdateLogo = link => {
    updateLogoCompany({
      variables: {
        company: COMPANY_ID,
        logo: link,
      },
    })
      .then(() => {
        setloading(false)
        handleClose()
        enqueueSnackbar('Change Logo success', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
      })
      .catch(err => {
        setloading(false)
        enqueueSnackbar(`Change Logo Error, please try again later, ${err}`, {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const generateDownload = (previewCanvas, crop, setloading) => {
    if (!crop || !previewCanvas) {
      // message.info(`Please choose a photo to upload`)
      return
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height)
    canvas.toBlob(
      blob => {
        setloading(true)
        const anchor = document.createElement('a')
        anchor.download = 'new-profile.png'
        anchor.href = URL.createObjectURL(blob)
        const formData = new FormData()
        formData.append('file', blob)
        return Axios.post(UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
          .then(response => {
            if (title === 'Banner') {
              setBanner(response.data.url)
              handleUpdateBanner()
            } else {
              handleUpdateLogo(response.data.url)
            }
          })
          .catch(() => {
            setloading(false)
          })
      },
      'image/png',
      1
    )
  }
  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <WhiteModalHeader>
        <Typography variant="h6" style={{ color: '#000000' }}>
          {title}
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon style={{ color: '#a9a8a8', fontSize: '30px' }}>close</Icon>
        </IconButton>
      </WhiteModalHeader>
      <DialogContent style={{ textAlign: 'center' }}>
        <WrapperContainer style={{ transform: `scale(${1 + zoom / 500})` }}>
          {title === 'Banner' ? (
            <CropperBanner
              className={classes.cropperImgBanner}
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={c => setCompletedCrop(c)}
            />
          ) : (
            <CropperPhoto
              className={classes.cropperImgPhoto}
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={c => setCompletedCrop(c)}
            />
          )}
        </WrapperContainer>
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            width: (completedCrop && completedCrop.width) || 0,
            height: (completedCrop && completedCrop.height) || 0,
          }}
        />
      </DialogContent>
      <DialogActions>
        <FlexBetween>
          <WrapperContainer
            className={classNames(classes.width50P, classes.paddingxy10)}
          >
            <Typography
              variant="span"
              id="slider-zoom"
              style={{ color: '#000000' }}
            >
              Zoom
            </Typography>
            <PrettoSlider
              value={zoom}
              valueLabelDisplay="auto"
              onChange={(event, value) => {
                event.preventDefault()
                setZoom(value)
              }}
              aria-labelledby="slider-zoom"
            />
          </WrapperContainer>
          <WrapperContainer
            style={{ selfAlign: 'center' }}
            className={classes.width30P}
          >
            <input
              onChange={onSelectFile}
              style={{ display: 'none' }}
              type="file"
              id="change-photo-profile"
              accept="image/*"
            />
            <label
              htmlFor="change-photo-profile"
              style={{ display: 'inline-block' }}
            >
              <div className={classes.btnWhiteDarkBlue}>
                <Typography variant="span" style={{ color: '#014a62' }}>
                  Upload Photo
                </Typography>
              </div>
            </label>
            <Button
              onClick={() =>
                generateDownload(
                  previewCanvasRef.current,
                  completedCrop,
                  setloading
                )
              }
              size="large"
              disabled={!completedCrop || completedCrop.width === 0 || loading}
              variant="contained"
              color="primary"
              style={{ width: 120, height: 50, borderRadius: 5, marginLeft: 10 }}
            >
              {loading ? 'Uploading..' : 'Save'}
            </Button>
          </WrapperContainer>
        </FlexBetween>
      </DialogActions>
    </Dialog>
  )
}

export default CropImagePopup
