import React, {useState, useEffect} from 'react'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {Button, Divider, Menu, MenuItem} from '@material-ui/core'

import AlertDialog from '../../../shared-components/popup/ResultPopup'
import ExportBaloon from '../../../../components/exportExcel/ExportBaloon'
import ImportBaloonMultiple from '../../../directory/download-report/page-download/ImportBaloonMultiple'
import ImportBaloonPayroll from '../../../../components/importExcel/ImportBaloonPayroll'
import ImportBaloonPayrollMultiple from '../ImportBaloonPayrollMultiple'
import ImportPopup from '../../../../components/importExcel/ImportPopup'
import TableEmployeeSalary from './table/TableEmployeeSalary'
import useDownloadReport from '../../../../hooks/useDownloadReport'
import {
  EnhancedToolbar,
  Flex,
  GenerateTooltip,
  GreyTypography,
  PaperContainer,
} from '../../../../GlobalStyles'
import {BigTextBlackBold, TextDarkBlue} from '../PayrollPageStyles'

import {CLOSE_BALOON, IMPORT_DATA} from '../../../../graphql/mutations'
import {GET_BALOON_STATUS_BUTTON_QUERY_MULTIPLE} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'

export default function ListEmployeeSalary(props) {
  const {searchText, filterData} = props
  const TABLE_EMP_SALARY = 'employee_salary'
  // const classes = helperStyle()
  const [openImport, setImport] = useState(false)
  const [isAlreadyImported, setIsAlreadyImported] = useState(false)
  const [fileURL, setFileURL] = useState('')
  const [openAllBaloon, setOpenAllBaloon] = useState(false)
  const [openDownload, setOpenDownload] = useState(false)

  const [dataerrorImport, settingErrorImport] = useState([])

  const [settingImport, setingImport] = useState({
    table: '',
    position: '',
    template: false,
  })

  const [menu, setMenu] = useState(null)
  const openMenu = Boolean(menu)
  const {
    data: getBaloonStatus,
    error: errorGetBaloonStatus,
    refetch: refetchBaloonStatus,
  } = useQuery(GET_BALOON_STATUS_BUTTON_QUERY_MULTIPLE, {
    variables: {
      company: COMPANY_ID,
      table: [
        'finance_payroll_employee_salaries',
        'finance_settings_component_assign',
      ],
    },
  })

  useEffect(() => {
    settingErrorImport(errorImport(settingImport.table))
  }, [settingImport])

  const {
    dataBaloon,
    isDisableButton,
    onDownload,
    onCloseBaloon,
  } = useDownloadReport({
    table: TABLE_EMP_SALARY,
  })

  const [mutationImport, {error: mutationImportError}] = useMutation(
    IMPORT_DATA
  )

  const [mutationCloseBaloon, {error: mutationCloseBaloonError}] = useMutation(
    CLOSE_BALOON
  )

  const handleClickOpenImport = () => {
    setImport(true)
  }

  const handleCloseImport = () => {
    setImport(false)
  }
  const importMutation = async () => {
    try {
      // eslint-disable-next-line
      const response = await mutationImport({
        variables: {
          table: settingImport.table,
          file: fileURL,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }

  const closeBaloonMutation = async () => {
    try {
      const baloonId =
        getBaloonStatus &&
        getBaloonStatus.company_import_data_logs.length > 0 &&
        getBaloonStatus.company_import_data_logs[0].id
      await mutationCloseBaloon({
        variables: {
          id: baloonId,
        },
      })
      refetchBaloonStatus()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }
  if (
    errorGetBaloonStatus !== undefined ||
    mutationCloseBaloonError !== undefined ||
    mutationImportError !== undefined
  ) {
    return (
      <div>
        {JSON.stringify(errorGetBaloonStatus) ||
          JSON.stringify(mutationCloseBaloonError) ||
          JSON.stringify(mutationImportError)}
      </div>
    )
  }

  const handleClickMenu = event => {
    setMenu(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setMenu(null)
  }

  const errorImport = table => {
    if (
      getBaloonStatus &&
      getBaloonStatus.company_import_data_logs.length > 0
    ) {
      const check = getBaloonStatus.company_import_data_logs.filter(row => {
        return row.reports && row.table_name === table
      })
      return check && check.length > 0 ? check[0].reports.failed : []
    } else {
      return []
    }
  }

  const disableImport = table => {
    if (
      getBaloonStatus &&
      getBaloonStatus.company_import_data_logs.length > 0
    ) {
      const check = getBaloonStatus.company_import_data_logs.filter(row => {
        return row.status === 'WAITING' && row.table_name === table
      })
      return check && check.length > 0
    } else {
      return false
    }
  }

  const handleDownload = () => {
    setOpenDownload(prev => !prev)
    if (openDownload) {
      onDownload({
        variables: {
          table: TABLE_EMP_SALARY,
        },
      })
    }
  }

  const TOTAL_PROCESS = getBaloonStatus
    ? getBaloonStatus.company_import_data_logs.length
    : 0

  // if (getBaloonStatus) {
  //   // console.log("[getBaloonStatus]",getBaloonStatus)
  //   if (
  //     getBaloonStatus.company_import_data_logs.length > 0 &&
  //     getBaloonStatus.company_import_data_logs[0].status === 'COMPLETED'
  //   ) {
  //     refetch()
  //   }
  // }
  return (
    <PaperContainer>
      {TOTAL_PROCESS > 1 ? (
        <ImportBaloonMultiple
          totalProcess={TOTAL_PROCESS}
          onSeeAll={() => setOpenAllBaloon(true)}
        />
      ) : (
        <ImportBaloonPayroll
          data={getBaloonStatus && getBaloonStatus}
          closeBaloonMutation={closeBaloonMutation}
          setImport={setImport}
          mutationCloseBaloon={mutationCloseBaloon}
          refetchBaloonStatus={refetchBaloonStatus}
          setingImport={setingImport}
        />
      )}

      <ExportBaloon
        availableToSeeProblem
        data={dataBaloon}
        closeBaloonMutation={onCloseBaloon}
        retry={handleDownload}
        feature="Employee Salary"
      />

      <EnhancedToolbar>
        <BigTextBlackBold>Employee Settings</BigTextBlackBold>

        <Flex flexEnd>
          <Button
            id="basic-button"
            variant={'outlined'}
            color="primary"
            size="large"
            style={{marginRight: 12}}
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClickMenu}
            // onClick={() => handleClickOpenImport()}
          >
            Import Data
          </Button>
          {
            <GenerateTooltip
              title={
                isDisableButton
                  ? `You can only generate data once a day. To download data use the link that already generated.`
                  : ``
              }
              placement="left"
            >
              <span>
                <Button
                  id="generate-data-button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleDownload}
                  disabled={isDisableButton}
                >
                  Generate Data
                </Button>
              </span>
            </GenerateTooltip>
          }
        </Flex>

        <Menu
          id="basic-menu"
          anchorEl={menu}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              setingImport({
                table: 'finance_payroll_employee_salaries',
                position: 'Bank & Salary',
                template:
                  'https://public-media.worklifebeyond.com/cbe78a08-4ad7-4719-8eec-c4dbc3ce7362-1629708388167.xlsx',
              })
              handleClickOpenImport()
            }}
            disabled={disableImport('finance_payroll_employee_salaries')}
          >
            {disableImport('finance_payroll_employee_salaries') ? (
              <GreyTypography>
                Importing Import Bank & Salary on proces...
              </GreyTypography>
            ) : (
              <TextDarkBlue>Import Bank & Salary</TextDarkBlue>
            )}
          </MenuItem>

          <MenuItem
            onClick={() => {
              setingImport({
                table: 'finance_settings_component_assign',
                position: 'Assignment Component',
                template: false,
              })
              handleClickOpenImport()
            }}
            disabled={disableImport('finance_settings_component_assign')}
          >
            {disableImport('finance_settings_component_assign') ? (
              <GreyTypography>
                Importing Assignment Component on proces...
              </GreyTypography>
            ) : (
              <TextDarkBlue>Assignment Component</TextDarkBlue>
            )}
          </MenuItem>
        </Menu>
      </EnhancedToolbar>

      <Divider />

      <TableEmployeeSalary searchText={searchText} filterData={filterData} />

      <ImportPopup
        open={openImport}
        position={settingImport.position}
        handleClose={handleCloseImport}
        mutation={importMutation}
        // onDownloadMaster={({provinces, cities, religions}) =>
        //   generateTemplateMaster({provinces, cities, religions})
        // }
        fileLinkTemplate={settingImport.template}
        isAlreadyImported={isAlreadyImported}
        setIsAlreadyImported={setIsAlreadyImported}
        setImport={setImport}
        fileURL={fileURL}
        setFileURL={setFileURL}
        closeBaloon={closeBaloonMutation}
        errorsImport={dataerrorImport}
      />
      <ImportBaloonPayrollMultiple
        open={openAllBaloon}
        onClose={() => {
          setOpenAllBaloon(false)
        }}
        data={getBaloonStatus && getBaloonStatus}
        setImport={setImport}
        mutationCloseBaloon={mutationCloseBaloon}
        refetchBaloonStatus={refetchBaloonStatus}
        setingImport={setingImport}
      />
      <AlertDialog
        open={openDownload}
        handleClose={handleDownload}
        feature="Generate Data is Processing!"
        message="Generate Data is processing! We’ll send you an email when it’s ready."
        type="Confirm"
      />
    </PaperContainer>
  )
}
