import React from 'react'
import {CircularProgress} from '@material-ui/core'

export default function LoadingEmployee({
  smallpadding = false,
  noborder = false,
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: smallpadding ? '80px 0px' : '220px 0px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        border: noborder ? 'none' : '1px solid rgba(0, 0, 0, 0.16)',
      }}
    >
      <CircularProgress />
      <h5 style={{marginTop: 16, fontSize: 12}}>Loading Data...</h5>
    </div>
  )
}
