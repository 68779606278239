import searchFilters, {
  getSearchVariables,
} from '../../../../utils/searchFilters'

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

export const MENUS = ['activity', 'report']

export const listFilter = active => {
  if (active === 'activity') {
    return searchFilters.concat([
      {
        name: 'Status',
        fieldName: 'status',
        type: 'checkbox',
        options: {
          list: [
            {value: 'pending', label: 'Pending'},
            {value: 'confirm1', label: 'Waiting from second approval'},
            {value: 'approved', label: 'Approved'},
            {value: 'rejected', label: 'Rejected'},
            {value: 'cancelled', label: 'Cancelled'},
          ],
        },
      },
    ])
  }

  return searchFilters
}

export const getOvertimeActivityFilterVariables = ({
  tabsValue,
  startDate,
  endDate,
  searchText = '',
  filterData = {},
}) => {
  const {include = {}, exclude = {}} = filterData

  const search = `%${searchText}%`

  const where = {
    people_work_placement: getSearchVariables(filterData),

    status:
      tabsValue === 'request'
        ? {_in: ['pending', 'confirm1']}
        : include.status || exclude.status
        ? {_in: include.status, _nin: exclude.status}
        : undefined,

    datestart: {_gte: startDate, _lte: endDate},

    _or: searchText
      ? [
          {people_work_placement: {regno: {_ilike: search}}},
          {people_work_placement: {global_user: {name: {_ilike: search}}}},
          {id_display: {_ilike: search}},
        ]
      : undefined,
  }

  return where
}

export const getDataVariableOvertime = filterData => {
  const {include, exclude} = filterData
  const _ageMin = include && include.age && include.age.min
  const _ageMax = include && include.age && include.age.max
  const _ageMinEX = exclude && exclude.age && exclude.age.min
  const _ageMaxEX = exclude && exclude.age && exclude.age.max
  const variables = {
    organization: include && include.organizationUnit,
    level: include && include.jobLevel,
    job_grades: include && include.jobGrade,
    job_title: include && include.jobTitle,
    jobProfile: include && include.position,
    office: include && include.officeLocation,
    employeeType: include && include.employeeType,
    timeArrangement: include && include.timeArrangement,
    workArrangement: include && include.workingArrangement,
    status: include && include.status,
    birthFrom: _ageMax,
    birthTo: _ageMin,
    isFilterBirth: Boolean(_ageMin || _ageMax) || false,
    gender: include && include.gender,
    /* =================================== */
    EXC_organization: exclude && exclude.organizationUnit,
    EXC_level: exclude && exclude.jobLevel,
    EXC_job_grades: exclude && exclude.jobGrade,
    EXC_job_title: exclude && exclude.jobTitle,
    EXC_jobProfile: exclude && exclude.position,
    EXC_office: exclude && exclude.officeLocation,
    EXC_employeeType: exclude && exclude.employeeType,
    EXC_timeArrangement: exclude && exclude.timeArrangement,
    EXC_workArrangement: exclude && exclude.workingArrangement,
    EXC_status: exclude && exclude.status,
    EXC_birthFrom: _ageMaxEX,
    EXC_birthTo: _ageMinEX,
    isFilterExcBirth: Boolean(_ageMinEX || _ageMaxEX) || false,
    EXC_gender: exclude && exclude.gender,
  }
  return variables
}
