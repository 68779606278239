import {useQuery} from '@apollo/react-hooks'
import {
  Icon,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import React, {useState} from 'react'
import Plot from 'react-plotly.js'
import {GET_DROPDOWN_CHART, GET_HOLDING_CHART} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'
import {holdingRolesContext} from '../../../utils/helpers'
import LoadingEmployee from '../../directory/employee/LoadingEmployee'
import DetailChart from './detail/DetailChartComponent'

const useStyles = makeStyles(() => ({
  filterContainer: {
    zIndex: 1,
    width: 713,
    paddingBottom: '0!important',
    position: 'absolute',
    right: 0,
    top: 0,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    background: '#fff',
  },
  diagramContainer: {width: '90%'},
  selectWrapper: {width: 211},
  closeFilter: {textAlign: 'center', marginTop: 3},
  filterWrapper: {justifyContent: 'space-between'},
}))

export default function HoldingClassification() {
  const classes = useStyles()
  const [valX, setValX] = useState([])
  const [valY, setValY] = useState([])
  const [valZ, setValZ] = useState([])
  const [open, setOpen] = useState(false)
  const [hideFilter, setHideFilter] = useState(false)
  const [search_x, setSearchx] = useState(0)
  const [search_y, setSearchy] = useState(0)
  const [search_z, setSearchz] = useState(0)

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickMarker = d => {
    if (d.points[0].data.text[0] !== 'No Data') {
      setOpen(true)
      setSearchx(d.points[0].x)
      setSearchy(d.points[0].y)
      setSearchz(d.points[0].z)
    }
  }

  const {data, error} = useQuery(GET_DROPDOWN_CHART, {
    variables: {company: COMPANY_ID},
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  const {data: dataChart, error: errorChart} = useQuery(GET_HOLDING_CHART, {
    variables: {
      company_holding_id: COMPANY_ID,
      search_x: valX.map(res => res.id),
      search_y: valY.map(res => res.id),
      search_z: valZ.map(res => res.id),
    },
    ...holdingRolesContext,
    fetchPolicy: 'cache-and-network',
  })

  if (
    (dataChart === undefined && errorChart === undefined) ||
    (data === undefined && error === undefined)
  ) {
    return <LoadingEmployee />
  } else if (errorChart !== undefined || error !== undefined) {
    return <div>{errorChart || error}</div>
  }

  const noDataScatterChart = [
    {
      x: [5],
      y: [5],
      z: [5],
      type: 'scatter3d',
      mode: 'markers+text',
      text: ['No Data'],
      textposition: 'middle center',
      hovertemplate:
        '<b>No Data, Please Choose Another axis</b>' + '<extra></extra>',
      marker: {
        // size: 20,
        size: 65,
        sizeref: 4000,
        sizemode: 'area',
        opacity: 0.8,
        color:
          '#' +
          Math.random()
            .toString(16)
            .substr(2, 6),
      },
    },
  ]

  const dataScatterChart = dataChart.v_holding_chart.map(res => {
    return {
      x: [res.axis_x.id],
      y: [res.axis_y.id],
      z: [res.axis_z.id],
      type: 'scatter3d',
      mode: 'markers+text',
      text: [res.count],
      textfont: {size: 30},
      textposition: 'middle center',
      // hovertext: ['x', 'y', 'z'],
      hovertemplate:
        `<br> <br>` +
        `<b>   X</b>   :   ${res.axis_x.name}   ` +
        `<br> <br>` +
        `<b>   Y</b>   :   ${res.axis_y.name}   ` +
        `<br> <br>` +
        `<b>   Z</b>   :   ${res.axis_z.name}   ` +
        `<br> <br>` +
        '<extra></extra>',
      marker: {
        // size: 20,
        sizemin: 45,
        size: [res.count * 3],
        opacity: 0.8,
        color:
          '#' +
          Math.random()
            .toString(16)
            .substr(2, 6),
      },
    }
  })

  const layout = {
    showlegend: false,
    // width: 1000,
    height: 1000,
    autoSize: true,
    font: {
      family: 'Muli',
      color: '#fff',
    },
    hoverlabel: {
      bgcolor: '#fff',
      font: {
        family: 'Muli',
        color: '#000',
        size: 16,
      },
    },
    paper_bgcolor: '#003d51',
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
    },
    scene: {
      xaxis: {gridcolor: '#fff', gridwidth: 1.5, spikecolor: 'red'},
      yaxis: {gridcolor: '#fff', gridwidth: 1.5, spikecolor: 'yellow'},
      zaxis: {gridcolor: '#fff', gridwidth: 1.5, spikecolor: 'green'},
    },
  }

  return (
    <>
      <div
        className={classes.filterContainer}
        style={{padding: hideFilter ? 0 : 20}}
      >
        <div
          className={classes.filterWrapper}
          style={{
            display: hideFilter ? 'none' : 'flex',
          }}
        >
          <div className={classes.selectWrapper}>
            <Typography variant="body2">X Axis</Typography>
            <Autocomplete
              multiple
              options={data.list_axis_x}
              value={valX}
              getOptionLabel={option => option.name}
              onChange={(event, newValue) => {
                event.preventDefault()
                setValX(newValue)
              }}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  // label="Industry"
                  placeholder="Industry"
                />
              )}
            />
          </div>
          <div className={classes.selectWrapper}>
            <Typography variant="body2">Y Axis</Typography>
            <Autocomplete
              multiple
              options={data.list_axis_y}
              value={valY}
              getOptionLabel={option => option.name}
              onChange={(event, newValue) => {
                event.preventDefault()
                setValY(newValue)
              }}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  // label="Speciality"
                  placeholder="Speciality"
                />
              )}
            />
          </div>
          <div className={classes.selectWrapper}>
            <Typography variant="body2">Z Axis</Typography>
            <Autocomplete
              multiple
              options={data.list_axis_z}
              value={valZ}
              getOptionLabel={option => option.name}
              onChange={(event, newValue) => {
                event.preventDefault()
                setValZ(newValue)
              }}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  // label="Category"
                  placeholder="Category"
                />
              )}
            />
          </div>
        </div>
        <div className={classes.closeFilter}>
          <IconButton size="small" onClick={() => setHideFilter(prev => !prev)}>
            <Icon>
              {hideFilter ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
            </Icon>
          </IconButton>
        </div>
      </div>

      <div className={classes.diagramContainer}>
        <Plot
          onClick={d => handleClickMarker(d)}
          data={
            dataChart.v_holding_chart.length > 0
              ? dataScatterChart
              : noDataScatterChart
          }
          config={{
            responsive: true,
          }}
          useResizeHandler
          style={{width: '100%', height: '100%'}}
          layout={layout}
        />
      </div>
      <DetailChart
        open={open}
        handleClose={handleClose}
        search_x={search_x}
        search_y={search_y}
        search_z={search_z}
      />
    </>
  )
}
