import React from 'react'
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks'

import {
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  CircularProgress,
  Box,
  Button,
  TextField,
  FormHelperText,
  Link,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSnackbar } from 'notistack'

import {
  AttachmentItem,
  AttachmentCard,
  UploadButton,
} from '../../../../components/attachment'
import AddEditPopup from '../../../shared-components/popup/AddEditPopup'
import FieldInformationTooltip from '../../../shared-components/tooltip/FieldInformationTooltip'

import {
  ActionableLink,
  StatusColors,
  StatusPill,
  getClaimTypeLabel,
} from '../ClaimPageStyles'
import DetailsTable from './DetailsTable'

import axios from 'axios'
import usePrevious from '../../../../hooks/usePrevious'
import {
  dateENOrUS,
  convertToRupiah,
  capitalize,
  fileChecker,
} from '../../../../utils/helpers'
import {
  POSITION_ID,
  TOKEN,
  UPLOAD_URL,
  USER_ID,
} from '../../../../utils/globals'
import {
  GET_CLAIM_DETAILS,
  GET_CLAIM_INVOICE_EXPORTS,
  GET_CLAIM_USER_DETAILS,
} from '../../../../graphql/queries/claim/getClaimDetails.query'
import {
  APPROVE_CLAIM,
  REQUEST_CLAIM_INVOICE_EXPORTS,
  SET_CLAIM_APPROVED,
  SET_CLAIM_REJECTED,
} from '../../../../graphql/mutations/claim/claim.mutation'

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: theme.spacing(3),
  },
  title: {
    fontWeight: 700,
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  close: {
    color: 'inherit',
    marginTop: theme.spacing(-1.5),
    marginBottom: theme.spacing(-1.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(-1.5),
  },
  content: {
    padding: theme.spacing(3),
  },

  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))

const useUploadStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.grey[700],
    fontSize: theme.spacing(1.75),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },

  list: {
    display: 'grid',
    gridGap: theme.spacing(1.5),
    marginTop: theme.spacing(3),

    '&:empty': {
      display: 'none',
    },
  },

  textTooltip: {
    fontSize: 12,
    color: '#000',
    display: 'block',
    margin: '2px 0px',
  },
  textBlue: {
    color: '#014a62',
    fontSize: 12,
    display: 'block',
    margin: '2px 0px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

const useDescriptionStyles = makeStyles({
  description: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  more: {
    fontFamily: 'inherit',
    display: 'block',
    marginTop: 4,
  },
})

const SUBMISSION_CONFIG = [
  { title: 'Employee Name', render: data => data.employee.user.name },
  { title: 'Employee ID', render: data => data.employee.regno },
  { title: 'Position', render: data => data.employee.profile.title },
  { title: 'Submission Date', render: data => dateENOrUS(data.date_added) },
  { title: 'Submission Status', render: renderSubmissionStatus },
]

const REQUEST_CONFIG = [
  { title: 'Request ID', render: data => data.formatted_id },
  {
    title: 'Invoice ID',
    hide: data => !data.invoice,
    render: data => data.invoice.code,
  },
  {
    title: 'Nominal',
    render: data =>
      convertToRupiah(data.invoice ? data.invoice.final_price : data.nominal),
  },
  {
    title: 'Issue Date',
    render: data => (data.date_issued ? dateENOrUS(data.date_issued) : '-'),
  },
  {
    title: 'Description',
    hide: data => data.invoice,
    render: data => <TransactionDescription description={data.description} />,
  },
  {
    title: 'Rejected Statement',
    hide: data => data.status !== 'rejected',
    render: data => (
      <TransactionDescription description={data.claim_fields?.reason} />
    ),
  },
  {
    title: 'Transaction Invoice',
    render: data =>
      data.invoice ? (
        <TransactionInvoiceExports claimDetail={data} />
      ) : (
        <TransactionAttachments files={data.attachments || []} />
      ),
  },
]

const TRANSFER_CONFIG = [
  { title: 'Bank Account', render: data => data.bank?.provider?.name || '-' },
  { title: 'Account Number', render: data => data.bank?.account_number || '-' },
  { title: 'Account Name', render: data => data.bank?.account_name || '-' },
  {
    title: 'Transfer Evidence',
    hide: data => data.status === 'processing' || data.status === 'waiting',
    render: data => <TransactionAttachments files={data.proofs} />,
  },
]

const REJECT_BUTTON_STYLES = { borderColor: '#ef4d5e', color: '#ef4d5e' }
const APPROVE_BUTTON_STYLES = { marginLeft: 16 }

function ClaimActionModal(props) {
  const { open = false, id, onClose, showApproveActions } = props

  const styles = useStyles()

  const { data, loading, error, refetch } = useQuery(GET_CLAIM_DETAILS, {
    wlb_skipPatch: true,
    skip: !id,
    variables: {
      requestID: id,
    },
  })

  const prevOpen = usePrevious(open)
  const isNeverOpen = !prevOpen && prevOpen === open

  if (isNeverOpen) {
    return null
  }

  const details = data?.details

  if (error) {
    console.error(error)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography className={styles.header}>
        {details ? (
          <>
            <StatusPill status={details.status} />
            <Typography className={styles.title}>
              {details.policy
                ? details.policy.name
                : `${getClaimTypeLabel(details)} Claim`}
            </Typography>
          </>
        ) : (
          <Typography className={styles.title}>
            Claim Submission Details
          </Typography>
        )}
        <IconButton
          title="Close modal"
          onClick={onClose}
          className={styles.close}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.content}>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <div>Something went wrong when requesting claim data</div>
        ) : (
          <>
            <DetailsTable fields={SUBMISSION_CONFIG} data={details} />

            <Divider className={styles.divider} />

            <DetailsTable fields={REQUEST_CONFIG} data={details} />

            <Divider className={styles.divider} />

            <DetailsTable fields={TRANSFER_CONFIG} data={details} />

            {showApproveActions && details.status === 'waiting' ? (
              <ClaimApproval id={id} refetch={refetch} />
            ) : details.status === 'processing' ? (
              <ClaimProcessing id={id} onCancel={onClose} />
            ) : null}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ClaimActionModal

const STATE_NORMAL = 0
const STATE_ERROR = 1
const STATE_CONFIRM = 2
const STATE_DISPATCHING = 3

const INITIAL_ATMT = {
  files: [],
  pending: 0,
  error: false,
  invalidType: false,
  overSize: false,
}

function ClaimProcessing(props) {
  const { id, onCancel } = props

  const styles = useUploadStyles()

  const client = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()

  const [state, setState] = React.useState(STATE_NORMAL)
  const [atmt, setAtmt] = React.useState(INITIAL_ATMT)

  const isFilesEmpty = atmt.files.length < 1

  const handleUploadAddition = ev => {
    const pending = ev.target.files
    const queue = []

    let invalidType = false
    let overSize = false

    for (const file of pending) {
      if (!fileChecker(file.name)) {
        invalidType = true
        continue
      }

      if (file.size >= 25 * 1024 * 1024) {
        overSize = true
        continue
      }

      queue.push(file)
    }

    setAtmt(prev => ({
      ...prev,
      error: prev.pending ? prev.error : false,
      pending: prev.pending + queue.length,
      invalidType,
      overSize,
    }))

    for (const file of queue) {
      const form = new FormData()
      form.append('file', file)

      const promise = axios.post(UPLOAD_URL, form, {
        headers: {
          Authorization: 'Bearer ' + TOKEN,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
      })

      cbify(promise, (error, response) => {
        const data = response && response.data

        setAtmt(prev => {
          let files = prev.files

          if (data) {
            files = files.slice()
            files.push({ url: data.url, name: file.name, size: file.size })
          }

          return {
            ...prev,
            files,
            error: prev.error || error !== null,
            pending: prev.pending - 1,
          }
        })
      })
    }
  }

  const bindNameChange = idx => ev => {
    const files = atmt.files.slice()

    files[idx].name = ev.target.value
    setAtmt({ ...atmt, files })
  }

  const bindRemove = idx => () => {
    const files = atmt.files.slice()

    files.splice(idx, 1)
    setAtmt({ ...atmt, files })
  }

  const handleProcessConfirm = () => {
    setState(STATE_DISPATCHING)

    const promise = client.mutate({
      mutation: SET_CLAIM_APPROVED,
      variables: {
        requestId: id,
        userId: USER_ID,
        proofs: atmt.files,
        fields: {
          finalizer: {
            user: USER_ID,
            position: POSITION_ID,
          },
        },
      },
    })

    cbify(promise, (error, result) => {
      if (error || result.errors) {
        enqueueSnackbar('Failed to approve claim', { variant: 'error' })
        return
      }

      enqueueSnackbar('Approve claim successful', { variant: 'success' })
    })
  }

  const handleProcessCancel = () => {
    setState(STATE_NORMAL)
  }

  const handleSubmitClick = () => {
    if (isFilesEmpty) {
      setState(STATE_ERROR)
      return
    }

    setState(STATE_CONFIRM)
  }

  return (
    <Box mt={3}>
      <Typography className={styles.header}>
        Transfer Evidence*
        <FieldInformationTooltip
          title={
            <>
              <Typography className={styles.textTooltip}>
                Appropriate file extension:
              </Typography>
              <div className={styles.tooltipWrapper}>
                <Typography className={styles.textTooltip}>
                  Document {'( '}
                </Typography>
                <Typography className={styles.textBlue}>
                  .pdf, .xlsx, .pptx, .docx
                </Typography>
                <Typography className={styles.textTooltip}>{' )'}</Typography>
              </div>
              <div className={styles.tooltipWrapper}>
                <Typography className={styles.textTooltip}>
                  Image {'( '}
                </Typography>
                <Typography className={styles.textBlue}>
                  .jpg, .png, .gif
                </Typography>
                <Typography className={styles.textTooltip}>{' )'}</Typography>
              </div>
              <Typography className={styles.textTooltip}>
                Maximum file size of 25MB
              </Typography>
            </>
          }
        />
      </Typography>

      <UploadButton multiple onChange={handleUploadAddition} />

      <Box className={styles.list}>
        {atmt.files.map((file, idx) => (
          <AttachmentItem
            key={idx}
            name={file.name}
            size={file.size}
            url={file.url}
            disabled={state === STATE_DISPATCHING}
            onNameChange={bindNameChange(idx)}
            onRemove={bindRemove(idx)}
          />
        ))}

        {atmt.pending > 0 && <AttachmentItem isUploading />}
      </Box>

      {atmt.error && (
        <FormHelperText error>Some attachments failed to upload</FormHelperText>
      )}
      {atmt.invalidType && (
        <FormHelperText error>
          Attachments cannot have other file types
        </FormHelperText>
      )}
      {atmt.overSize && (
        <FormHelperText error>
          Attachments cannot exceed 25 MB in size
        </FormHelperText>
      )}

      {state === STATE_ERROR && isFilesEmpty && (
        <FormHelperText error>This field is required</FormHelperText>
      )}

      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button disabled={state === STATE_DISPATCHING} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={state === STATE_DISPATCHING || atmt.pending > 0}
          onClick={handleSubmitClick}
          variant="contained"
          color="primary"
          style={APPROVE_BUTTON_STYLES}
        >
          Submit
        </Button>
      </Box>

      <AddEditPopup
        open={state === STATE_CONFIRM}
        type="process"
        feature="claim request"
        mutation={handleProcessConfirm}
        handleClose={handleProcessCancel}
      />
    </Box>
  )
}

function ClaimApproval(props) {
  const { id, refetch } = props

  const [state, setState] = React.useState(STATE_NORMAL)
  const [approvalState, setApprovalState] = React.useState(null)
  const [rejectMessage, setRejectMessage] = React.useState(null)

  const client = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()

  const handleRejectClick = () => {
    setRejectMessage('')
  }

  const handleRejectSubmit = () => {
    setApprovalState({ open: true, type: 'reject' })
  }

  const handleRejectCancel = () => {
    setRejectMessage(null)
  }

  const handleApproveSubmit = () => {
    setRejectMessage(null)
    setApprovalState({ open: true, type: 'approve' })
  }

  const handleApprovalConfirm = () => {
    const type = approvalState.type
    const isReject = type === 'reject'

    const typeMsg = capitalize(type)

    setRejectMessage(null)

    let variables

    if (isReject) {
      variables = {
        requestId: id,
        fields: {
          reason: rejectMessage,
          finalizer: {
            user: USER_ID,
            position: POSITION_ID,
          },
        },
      }
    } else {
      variables = {
        requestId: id,
      }
    }

    const promise = client.mutate({
      mutation: isReject ? SET_CLAIM_REJECTED : APPROVE_CLAIM,
      variables: variables,
    })

    setState(STATE_DISPATCHING)

    promise.then(
      result => {
        if (result.errors) {
          return Promise.reject(result.errors)
        }

        if (!isReject) {
          // Despite APPROVE_CLAIM mutation returning `people_work_claim` field,
          // unfortunately the type it returns is `PeopleWorkClaim` which is
          // only used for supervisor's claim request list, the type used in
          // details modal is `people_work_claims`

          refetch()
        }

        enqueueSnackbar(`${typeMsg} claim successful`, { variant: 'success' })
      },
      error => {
        console.error(error)
        enqueueSnackbar(`${typeMsg} claim failed`, { variant: 'error' })
      }
    )
  }

  const handleApprovalClose = () => {
    setApprovalState({ ...approvalState, open: false })
  }

  const handleRejectMessageChange = ev => {
    setRejectMessage(ev.target.value)
  }

  return (
    <>
      <Box display="flex" justifyContent="center" mt={3}>
        <Button
          disabled={state === STATE_DISPATCHING}
          onClick={handleRejectClick}
          variant="outlined"
          style={state !== STATE_DISPATCHING ? REJECT_BUTTON_STYLES : null}
        >
          Reject
        </Button>
        <Button
          disabled={state === STATE_DISPATCHING}
          onClick={handleApproveSubmit}
          variant="contained"
          color="primary"
          style={APPROVE_BUTTON_STYLES}
        >
          Approve
        </Button>
      </Box>

      {rejectMessage !== null && (
        <Box mt={3}>
          <TextField
            disabled={state === STATE_DISPATCHING}
            value={rejectMessage}
            onChange={handleRejectMessageChange}
            multiline
            minRows={3}
            variant="outlined"
            fullWidth
            autoFocus
            placeholder="Add rejection reasoning here"
          />

          <Box display="flex" justifyContent="end" mt={1}>
            <Button
              disabled={state === STATE_DISPATCHING}
              onClick={handleRejectCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={state === STATE_DISPATCHING || !rejectMessage.trim()}
              onClick={handleRejectSubmit}
              variant="contained"
              color="primary"
              style={APPROVE_BUTTON_STYLES}
            >
              Submit
            </Button>
          </Box>
        </Box>
      )}

      <AddEditPopup
        {...approvalState}
        feature="claim request"
        mutation={handleApprovalConfirm}
        handleClose={handleApprovalClose}
      />
    </>
  )
}

function renderSubmissionStatus(data) {
  const status = data.status
  const approved_by = data.claim_fields.approved_by

  const el = <b style={{ color: StatusColors[status] }}>{capitalize(status)}</b>

  if (status === 'waiting') {
    const policy = data.policy
    const confirm_type = policy?.confirm_type

    if (confirm_type === 'supervisor') {
      const supervisor = retrieveDirectSupervisor(data.employee.profile)

      if (!supervisor) {
        return <>Application needs approving by administrator</>
      }

      const user = supervisor.placements[0].user

      return (
        <>
          {el} for <b>{user.name}</b>, <b>{supervisor.title}</b> approval
        </>
      )
    } else if (confirm_type === 'position') {
      const approvals = [policy.first_job_profile, policy.second_job_profile]
      const current_approval = approvals[approved_by.length]

      return (
        <>
          {el} for <b>{current_approval.title}</b> approval
        </>
      )
    } else {
      return (
        <>
          {el} for <b>Finance Admin</b> approval
        </>
      )
    }
  } else if (status === 'approved' || status === 'rejected') {
    const finalizer = data.claim_fields.finalizer || {}
    const userId = finalizer.user
    const positionId = finalizer.position

    return (
      <>
        {el} by <StatusUser userId={userId} positionId={positionId} />
      </>
    )
  }

  return el
}

function retrieveDirectSupervisor(prof) {
  while (prof) {
    if (prof.placements?.length > 0) {
      return prof
    }

    prof = prof.supervisor
  }
}

function StatusUser(props) {
  const { userId, positionId } = props

  const { data, error } = useQuery(GET_CLAIM_USER_DETAILS, {
    skip: !userId && !positionId,
    variables: {
      userId,
      positionId,
    },
  })

  if (!data) {
    return <>- {error && '(failed to retrieve user)'}</>
  }

  return (
    <>
      <b>{data.user.name}</b>, <b>{data.position.title}</b>
    </>
  )
}

function TransactionInvoiceExports({ claimDetail }) {
  const invoiceId = claimDetail.invoice.id
  const userId = claimDetail.employee.user.id

  const { data, error, refetch } = useQuery(GET_CLAIM_INVOICE_EXPORTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      invoiceId: invoiceId,
    },
  })

  const [dispatchInvoiceRequest, { error: requestError }] = useMutation(
    REQUEST_CLAIM_INVOICE_EXPORTS,
    {
      variables: {
        invoiceId: invoiceId,
        userId,
      },
    }
  )

  // We use `data?.invoice_exports?.length` as dependency than `data` here,
  // because it seems that the effect is getting rerun twice despite being the
  // "exact" same.

  React.useEffect(() => {
    if (data && data.invoice_exports.length < 1) {
      dispatchInvoiceRequest().then(() => refetch())
    }
  }, [data?.invoice_exports?.length])

  if (requestError) {
    console.error(requestError)
    return <div>Something went wrong while trying to generate an invoice</div>
  }

  if (error) {
    console.error(error)
    return <div>Something went wrong when requesting the invoice</div>
  }

  if (!data || data.invoice_exports.length < 1) {
    return <CircularProgress />
  }

  const files = data.invoice_exports.map((invoice, idx) => ({
    name: `invoice${idx > 0 ? `-${idx + 1}` : ''}.pdf`,
    url: invoice.url,
  }))

  return <TransactionAttachments files={files} />
}

function TransactionAttachments(props) {
  const { files, max = 3 } = props

  const [more, setMore] = React.useState(false)

  const memoized = React.useMemo(
    () =>
      files
        .slice(0, more ? files.length : max)
        .map((item, index) => (
          <AttachmentCard key={index} name={item.name} url={item.url} />
        )),
    [files, max, more]
  )

  return (
    <div>
      {memoized}

      {files.length > max && (
        <ActionableLink onClick={() => setMore(!more)}>
          {more ? 'View Less' : 'View More'}
        </ActionableLink>
      )}
    </div>
  )
}

function TransactionDescription(props) {
  const { description } = props

  const styles = useDescriptionStyles()

  const READ_INITIAL = 0
  const READ_HIDDEN = 1
  const READ_VISIBLE = 2

  const [state, setState] = React.useState(READ_INITIAL)

  const ref = React.useCallback(
    el => {
      if (!el || el.$text === description) {
        return
      }

      if (state !== READ_INITIAL) {
        setState(READ_INITIAL)
        return
      }

      el.$text = description

      if (el.scrollHeight > el.clientHeight) {
        setState(READ_HIDDEN)
      }
    },
    [description, state]
  )

  const toggleShowMore = () => {
    setState(state === READ_HIDDEN ? READ_VISIBLE : READ_HIDDEN)
  }

  return (
    <div>
      <div
        ref={ref}
        className={styles.description}
        style={{ WebkitLineClamp: state === READ_VISIBLE ? 'initial' : 5 }}
      >
        {description}
      </div>

      {state >= READ_HIDDEN && (
        <Link
          component="button"
          onClick={toggleShowMore}
          className={styles.more}
          color="secondary"
        >
          See {state === READ_HIDDEN ? 'More' : 'Less'}
        </Link>
      )}
    </div>
  )
}

function cbify(promise, cb) {
  return promise.then(
    r => cb(null, r),
    e => cb(e, null)
  )
}
