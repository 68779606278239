import React from 'react'
import {
  CardContainer,
  CaptionContainter,
  CaptionLabel,
  CaptionLabelDetail,
  ListContainer,
  ProgressLineBarWrap,
} from './CardStyles'

const Bar = ({percent, total}) => {
  return (
    <ProgressLineBarWrap percent={percent}>
      <div className="bar">
        {percent >= 20 && (
          <p className="absolute">{`${percent}% (${total})`}</p>
        )}
      </div>
      {percent < 20 && (
        <p className="ml-12 color-secondary">{`${percent}% (${total})`}</p>
      )}
    </ProgressLineBarWrap>
  )
}

const CardProgressBarWithStatus = props => {
  const {data, isDetail = false} = props

  const ListLabel = isDetail ? CaptionLabelDetail : CaptionLabel

  return (
    <CardContainer>
      {data.map((_object, idx) => (
        <ListContainer key={idx}>
          <CaptionContainter>
            <ListLabel isList>{_object.label}</ListLabel>
          </CaptionContainter>
          <Bar percent={_object.percent} total={_object.count} />
        </ListContainer>
      ))}
    </CardContainer>
  )
}

export default CardProgressBarWithStatus
