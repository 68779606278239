import React from 'react'

import {IconButton} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import {ItemWrapper} from '../../../DetailEmployeeStyles'

import {convertToRupiah, dateFormat} from '../../../../../../../utils/helpers'

const formatDate = date => {
  return dateFormat(date, 'll')
}

const formatDateRange = (join, end) => {
  if (join && end) {
    return `${formatDate(join)} - ${formatDate(end)}`
  } else if (join && !end) {
    return `Mulai dari ${formatDate(join)}`
  } else if (!join && end) {
    return `Sampai ${formatDate(end)}`
  }
}

const row = (header, content) => (
  <tr>
    <th scope="row">{header}</th>
    <td>{content || '-'}</td>
  </tr>
)

const InsuranceDataItem = props => {
  const {data, onEdit} = props

  const provider = data.global_insurance_list
  const fields = data.identity_fields

  return (
    <ItemWrapper>
      <h1 className="header">{provider?.name || '-'}</h1>

      <table className="content">
        {row('Nomor Asuransi', data.number)}
        {row(
          'Date',
          fields && formatDateRange(fields.join_date, fields.end_date)
        )}
        {row(
          'Saldo',
          fields?.balance_amount
            ? convertToRupiah(fields.balance_amount)
            : 'Rp -'
        )}
        {row(
          'Keluarga dan Penerima',
          fields?.family?.length &&
            fields.family.map((res, idx) => (
              <p
                key={idx}
                style={{marginTop: idx > 0 ? 8 : 0, marginBottom: 0}}
              >
                {res.name}
              </p>
            ))
        )}
        {row('Keterangan', fields?.description)}
      </table>

      <IconButton onClick={onEdit} className="edit">
        <EditIcon />
      </IconButton>
    </ItemWrapper>
  )
}

export default InsuranceDataItem
