import gql from 'graphql-tag'

export const GET_ALL_CATEGORY = gql`
  subscription {
    competency_categories(limit: 10, offset: 0) {
      id
      description
      fa_class
      name
      status
    }
  }
`

export const GET_LIST_CATEGORY_OHSA = gql`
  {
    people_profile_ohsa_categories(order_by: {id: asc}) {
      name: name
      value: name
    }
  }
`
