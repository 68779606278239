import styled, {css} from 'styled-components'

export const LabelContainer = styled.div`
  display: flex;
  margin-left: 10px;
  ${props =>
    props.list &&
    css`
      flex-direction: column;
      justify-content: center;
      width: 50%;
    `}
  ${props =>
    props.column &&
    css`
      flex-direction: row;
      height: auto;
      justify-content: space-around;
      margin-left: 0;
      width: 100%;
    `}
`

export const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
  ${props =>
    props.column &&
    css`
      align-items: start;
    `}
`

export const LabelSymbol = styled.div`
  border: 3px solid ${props => props.color};
  border-radius: 50%;
  height: 8px;
  margin-right: 5px;
  width: 8px;
`

export const LabelMore = styled.div`
  margin-top: 6px;
  font-size: 12px;
  color: #039be5;
  cursor: pointer;
`

export const LabelCaptionList = styled.span`
  font-size: 0.8rem;
  &.main-label {
    width: 80%;
  }
  &.value-label {
    color: #a9a8a8;
    width: 10%;
    text-align: right;
    padding-right: 5px;
  }
  &.w-90 {
    width: 90%;
  }
  &.clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`

export const LabelCaptionColumn = styled.span`
  font-size: 0.8rem;
  &.value-label {
    font-size: 1.25rem;
    color: ${props => props.color};
  }
`

export const LabelCaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
