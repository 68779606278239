import gql from 'graphql-tag'

export const GET_LIST_LOAN_POLICY = gql`
  query getLoanPolicies($search: String, $offset: Int, $limit: Int) {
    total: finance_loan_policies_aggregate(where: {name: {_ilike: $search}}) {
      aggregate {
        count
      }
    }

    finance_loan_policies(
      where: {name: {_ilike: $search}}
      offset: $offset
      limit: $limit
    ) {
      id
      name
      quota
      max_tenor
      affected_jobs: company_job_profile_r_loans {
        company_job_profile {
          id
          company_employee_position {
            id
            name
          }

          company_organization {
            id
            name
          }

          company_employee_rank {
            id
            name
          }

          company_address {
            id
            office_name
          }
        }
      }
    }
  }
`
