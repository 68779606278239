import gql from 'graphql-tag'

export const GET_LIST_TALENT_SEARCHED = gql`
  query($id: uuid) {
    global_users(where: {id: {_eq: $id}}) {
      id
      name
      avatar
      people_work_placements(
        where: {status: {_eq: "ACTIVE"}}
        order_by: [{id: desc}]
        limit: 1
      ) {
        regno
        start_date
        end_date
        placement_fields
        talent_assignments(
          where: {
            talentVersionByTalentVersion: {
              classification_type: {_eq: "TALENT"}
              status: {_eq: "COMPLETED"}
            }
          }
          order_by: [{id: desc}]
          limit: 1
        ) {
          talent_box {
            id
            name
            badge
          }
          talent_score
        }
      }
      people_profile_experiences {
        title
        from
        to
        company_name
      }
      people_profile_projects {
        project_name
        location
        start_date
        end_date
      }
      people_profile_educations {
        id
        education_fields
        from
        to
        company_profile {
          id
          legal_name
        }
      }
      people_profile_skills {
        people_skill {
          name
        }
        people_skill_level {
          name
        }
      }
      people_profile_certificates {
        name
      }
      people_profile_awards {
        award_name
        issuing_organization
      }
      #people_profile_publications {
      #  publication_title
      #  publication_media
      #}
      people_profile_speakers {
        event_name
        organizer
      }
      people_profiles {
        people_profile_organization_activities {
          organization_name
          start_date
          end_date
        }
      }
    }
  }
`
export const GET_LIST_COMPARE_TALENT = gql`
  query getListCompareTalent($search: String, $limit: Int) {
    total: people_work_placements_aggregate(
      where: {
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
      }
      limit: $limit
      order_by: [{performance_individual_aggregate: {max: {id: asc}}}]
    ) {
      id
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_LIST_TALENT_POOL = gql`
  query($search: String, $offset: Int, $limit: Int, $job_profile: Int) {
    total: people_work_placements_aggregate(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        status: {_eq: "ACTIVE"}
        global_user: {status: {_eq: "ACTIVE"}}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        status: {_eq: "ACTIVE"}
        global_user: {status: {_eq: "ACTIVE"}}
      }
      offset: $offset
      limit: $limit
      order_by: {global_user: {name: asc}}
    ) {
      id
      regno
      start_date
      end_date
      placement_fields
      global_user {
        id
        name
        avatar
        talent_assignments(limit: 1, order_by: [{id: desc}]) {
          id
          talent_version
          badge_classification
          status
          indicator_perf_nps
          indicator_perf_ogf
          indicator_perf_goal
          indicator_perf_multirater
          indicator_pot_attendance
          indicator_pot_competence
          indicator_pot_experience
          indicator_pot_socmed_post
          indicator_pot_total_course
          talentVersionByTalentVersion {
            id
            name
            start_date
            end_date
            box_height
            box_width
            indicator_perf_nps
            indicator_perf_ogf
            indicator_perf_goal
            indicator_perf_multirater
            indicator_pot_attendance
            indicator_pot_competence
            indicator_pot_socmed_post
            indicator_pot_total_course
            indicator_perf_nps_fields
            indicator_perf_ogf_fields
            indicator_perf_goal_fields
            indicator_perf_multirater_fields
            indicator_pot_attendance_fields
            indicator_pot_competence_fields
            indicator_pot_socmed_post_fields
            indicator_pot_total_course_fields
            talent_boxes {
              id
              name
              level
              badge
              color_hex
              index_x
              index_y
              description
            }
            min_pot
            min_perf
            max_pot
            max_perf
          }
        }
      }
      talent_pool_aggregate(where: {job_profile: {_eq: $job_profile}}) {
        aggregate {
          count
        }
      }
      company_job_profile {
        id
        title
      }
      talent_aspirations {
        id
        company_job_profile {
          id
          title
        }
      }
    }
  }
`
export const GET_LIST_ADD_TALENT_POOL = gql`
  query($search: String, $offset: Int, $limit: Int, $job_profile: Int) {
    total: people_work_placements_aggregate(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        status: {_eq: "ACTIVE"}
        global_user: {status: {_eq: "ACTIVE"}}
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
        status: {_eq: "ACTIVE"}
        global_user: {status: {_eq: "ACTIVE"}}
      }
      offset: $offset
      limit: $limit
      order_by: {global_user: {name: asc}}
    ) {
      id
      placement_fields
      global_user {
        id
        name
        avatar
        talent_assignments(
          where: {
            talentVersionByTalentVersion: {
              status: {_eq: "COMPLETED"}
              visibility: {_eq: "PUBLISH"}
              classification_type: {_eq: "TALENT"}
            }
            badge_classification: {_is_null: false}
          }
          order_by: [{id: desc}]
          limit: 1
        ) {
          id
          talent_score
          talent_box {
            id
            name
            badge
          }
        }
      }
      talent_pool(
        where: {
          job_profile: {_eq: $job_profile}
          candidate_status: {_eq: "TALENT"}
        }
      ) {
        id
      }

      talent_pool_aggregate(
        where: {
          job_profile: {_eq: $job_profile}
          candidate_status: {_eq: "TALENT"}
        }
      ) {
        aggregate {
          count
        }
      }
      company_job_profile {
        id
        title
      }
    }
  }
`
