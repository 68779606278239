import XLSX from 'xlsx'
import { EXPERIENCE_TEMPLATES, TRAINING_LICENSES_TEMPLATES } from './templateImportPersonal';

export const generateTempaltesPersonal = (templates) => {
  const wb = XLSX.utils.book_new();
  let templateWS

  switch (templates) {
    case 'Experience':
      templateWS = XLSX.utils.json_to_sheet(EXPERIENCE_TEMPLATES);
      XLSX.utils.book_append_sheet(wb, templateWS, templates);
      break;
    case 'Training Licenses Certification':
      templateWS = XLSX.utils.json_to_sheet(TRAINING_LICENSES_TEMPLATES);
      XLSX.utils.book_append_sheet(wb, templateWS, templates);
      break;
    default:
      break;
  }

  XLSX.writeFile(wb, `${templates}.csv`);
};

