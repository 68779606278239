import gql from 'graphql-tag'

export const DELETE_TALENT_CLASSIFICATION = gql`
  mutation($id: uuid!) {
    update_talent_versions(
      where: {id: {_eq: $id}}
      _set: {date_deleted: "now()"}
    ) {
      affected_rows
    }
  }
`
export const EDIT_TALENT_VERSION = gql`
  mutation($version_id: uuid!, $data: talent_versions_set_input) {
    update_talent_versions(where: {id: {_eq: $version_id}}, _set: $data) {
      returning {
        id
      }
      affected_rows
    }
  }
`

export const EDIT_TALENT_BOXES = gql`
  mutation($id: Int!, $data: talent_boxes_set_input) {
    update_talent_boxes(where: {id: {_eq: $id}}, _set: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const EDIT_CANDIDATE_GROUP = gql`
  mutation($id: Int!, $data: talent_candidate_groups_set_input) {
    update_talent_candidate_groups(where: {id: {_eq: $id}}, _set: $data) {
      returning {
        id
      }
    }
  }
`

export const DELETE_CANDIDATE_GROUP = gql`
  mutation($id: Int!) {
    update_talent_candidate_groups(
      where: {id: {_eq: $id}}
      _set: {date_deleted: "now()"}
    ) {
      returning {
        id
      }
    }
  }
`
export const DELETE_TALENT_COMMITTEE = gql`
  mutation($id: Int) {
    update_talent_committees(
      _set: {date_deleted: "now()"}
      where: {id: {_eq: $id}}
    ) {
      returning {
        id
      }
    }
  }
`

export const UPDATE_TALENT_COMMITTEE = gql`
  mutation(
    $id: Int
    $committee: uuid
    $group: Int
    $status: String
    $is_pic: Boolean
  ) {
    update_talent_committees(
      _set: {
        committee: $committee
        candidate_group: $group
        status: $status
        is_pic: $is_pic
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`

export const EDIT_TALENT_BOXES_ALL = gql`
  mutation($version: uuid!, $box: [talent_boxes_insert_input!]!) {
    delete_talent_boxes(where: {version: {_eq: $version}}) {
      affected_rows
    }

    insert_talent_boxes(objects: $box) {
      affected_rows
    }
  }
`
export const EDIT_CANDIDATE_GROUP_ALL = gql`
  mutation($version: uuid!, $group: [talent_candidate_groups_insert_input!]!) {
    delete_talent_candidate_groups(where: {version: {_eq: $version}}) {
      affected_rows
    }

    insert_talent_candidate_groups(objects: $group) {
      affected_rows
    }
  }
`
