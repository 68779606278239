import React from 'react'
import moment from 'moment'
import {
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import {
  GreyTypography,
  ModalContent,
  ModalHeader,
  WhiteIcon,
  LongBtn,
  FlexAround,
} from '../../../../../GlobalStyles'

import {convertToRoundedRupiah, dateENOrUS} from '../../../../../utils/helpers'

const useStyles = makeStyles(() => ({
  detailContainer: {display: 'flex', paddingBottom: '1rem'},
  w30: {width: '30%'},
  w70: {width: '70%'},
  detailCalculation1: {
    marginTop: '1rem',
  },
  detailCalculation2: {
    margin: '0 -1.6rem -.8rem',
    padding: '1.6rem 1.6rem .8rem',
    background: '#fff',
  },
  orangePeelColor: {
    color: '#FFA000',
  },
  ceruleanColor: {
    color: '#039BE5',
  },
  btnReject: {
    backgroundColor: '#ef4d5e',
    border: '1.5px solid #ef4d5e',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ef3145',
    },
  },
}))

export default function ModalProcessCalculation(props) {
  const {
    data,
    onApprove,
    onClose,
    onReject,
    onStatementChange,
    calculationDetailLoading,
    open,
    type,
  } = props
  // console.log('data')
  // console.log(data)
  // const [statement, setStatement] = useState('')
  const handleClear = () => {
    // setStatement('')
  }

  const classes = useStyles()

  if (calculationDetailLoading) return <CircularProgress />

  return (
    <>
      <Dialog
        onClose={() => {
          onClose()
          handleClear()
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        classes={{paper: classes.paperDialog}}
      >
        <ModalHeader>
          <Typography variant="h6" className={classes.title}>
            Payroll Process Calculations
          </Typography>

          <IconButton
            aria-label="close"
            onClick={() => {
              onClose()
              handleClear()
            }}
          >
            <WhiteIcon>close</WhiteIcon>
          </IconButton>
        </ModalHeader>
        <ModalContent>
          <div className={classes.detailCalculation1}>
            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Payroll Type
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {type}
              </GreyTypography>
            </div>

            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Period
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {data &&
                  type &&
                  (type === 'Payroll'
                    ? data.month && data.year
                      ? `${moment(data.month, 'MM').format('MMMM')}, ${
                          data.year
                        }`
                      : '-'
                    : data.schedule
                    ? dateENOrUS(data.schedule)
                    : '-')}
              </GreyTypography>
            </div>

            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Submission Date
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {`-`}
              </GreyTypography>
            </div>

            <div className={classes.detailContainer}>
              <Typography variant="body2" className={classes.w30}>
                Submission Status
              </Typography>
              <GreyTypography variant="body2" className={classes.w70}>
                {data &&
                  data.listOfApprover &&
                  data.listOfApprover.length > 0 &&
                  data.approveStep < data.listOfApprover.length && (
                    <>
                      <span className={classes.orangePeelColor}>Waiting</span>{' '}
                      for
                      <span className={classes.ceruleanColor}>
                        {' '}
                        {data.listOfApprover[data.approveStep]}
                        {`'s`}
                      </span>
                      {` approval`}
                    </>
                  )}
              </GreyTypography>
            </div>
          </div>

          <div className={classes.detailCalculation2}>
            {type && type === 'Payroll' ? (
              <>
                <div className={classes.detailContainer}>
                  <Typography variant="body2" className={classes.w30}>
                    Total Basic Salary
                  </Typography>
                  <GreyTypography variant="body2" className={classes.w70}>
                    {data && convertToRoundedRupiah(data.total_salary)}
                  </GreyTypography>
                </div>

                <div className={classes.detailContainer}>
                  <Typography variant="body2" className={classes.w30}>
                    Total Earning
                  </Typography>
                  <GreyTypography variant="body2" className={classes.w70}>
                    {data && convertToRoundedRupiah(data.addition)}
                  </GreyTypography>
                </div>

                <div className={classes.detailContainer}>
                  <Typography variant="body2" className={classes.w30}>
                    Total Deduction
                  </Typography>
                  <GreyTypography variant="body2" className={classes.w70}>
                    {data && convertToRoundedRupiah(data.deduction)}
                  </GreyTypography>
                </div>

                <div className={classes.detailContainer}>
                  <Typography variant="body2" className={classes.w30}>
                    Total Take Home Pay
                  </Typography>
                  <GreyTypography variant="body2" className={classes.w70}>
                    {data && convertToRoundedRupiah(data.total_final_salary)}
                  </GreyTypography>
                </div>
              </>
            ) : (
              <>
                <div className={classes.detailContainer}>
                  <Typography variant="body2" className={classes.w30}>
                    Total Employee
                  </Typography>
                  <GreyTypography variant="body2" className={classes.w70}>
                    {data &&
                      data.totalEmployee &&
                      `${data.totalEmployee} Employee`}
                  </GreyTypography>
                </div>

                <div className={classes.detailContainer}>
                  <Typography variant="body2" className={classes.w30}>
                    THR Total
                  </Typography>
                  <GreyTypography variant="body2" className={classes.w70}>
                    {data &&
                      convertToRoundedRupiah(
                        data.total_aggregate?.aggregate?.sum?.final_thr +
                          (data.total_aggregate?.aggregate?.sum?.income || 0) -
                          (data.total_aggregate?.aggregate?.sum?.deduction || 0)
                      )}
                  </GreyTypography>
                </div>
              </>
            )}

            <TextField
              fullWidth
              multiline={true}
              placeholder="Add statement here"
              rows={4}
              rowsMax={6}
              variant="outlined"
              onChange={e => onStatementChange(e.target.value)}
            />

            <FlexAround style={{marginTop: 15}}>
              <LongBtn
                className={classes.btnReject}
                variant="contained"
                onClick={e => {
                  e.preventDefault()
                  if (onReject) {
                    onReject()
                  }
                  onClose()
                }}
              >
                Reject
              </LongBtn>
              <LongBtn
                color="primary"
                variant="contained"
                onClick={e => {
                  e.preventDefault()
                  if (onApprove) {
                    onApprove()
                  }
                  onClose()
                }}
              >
                Approve
              </LongBtn>
            </FlexAround>
          </div>
        </ModalContent>
      </Dialog>
    </>
  )
}
