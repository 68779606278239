import {
  Dialog,
  Divider,
  IconButton,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core'
import React, {useState} from 'react'
import {
  ModalContentEvent,
  ModalHeader,
  WhiteIcon,
  FormChildButton,
} from '../../../../../../GlobalStyles'
import TalentSuccessorComponent from '../TalentSuccessorComponent'
import ModalDetailTalentAction from './ModalDetailTalentAction'
import {ADD_TALENT_SUCCESSOR} from '../../../../../../graphql/mutations/talent/succession/addTalentSuccession.mutation'
import {useMutation} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

const useStyles = makeStyles(() => ({
  paperDetail: {
    padding: '16px 20px',
  },
  paperTop: {
    padding: '8px 20px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  paperCategory: {
    padding: '20px 0',
    textAlign: 'center',
  },
  spacer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    width: '100%',
  },
  mr20: {
    marginRight: 20,
  },
  flexDetail: {
    alignItems: 'center',
    display: 'flex',
  },
  center: {
    textAlign: 'center',
  },
  green: {
    color: '#4caf50',
  },
  red: {
    color: '#ef4d5e',
  },
  indicator: {
    padding: 12,
    paddingBottom: 0,
  },
  divider: {
    margin: '16px 0 6px !important',
  },
  actionBold: {
    fontWeight: 700,
    marginBottom: 14,
  },
  actionContainer: {padding: 12},
  paperMobility: {
    textAlign: 'center',
    padding: 17,
  },
}))

const ModalDetailTalent = ({
  open,
  handleClose,
  pool,
  idJob,
  refetch,
  placementId,
  userId,
}) => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [value, setValue] = useState({
    candidate_status: '',
    readiness_status: '',
  })

  const {readiness_status, candidate_status} = value

  const handleChange = name => event => {
    setValue({
      ...value,
      [name]: event.target.value,
    })
  }

  const [addTalent] = useMutation(ADD_TALENT_SUCCESSOR)

  const onMutationPool = () => {
    const variables = {
      job_profile: idJob,
      candidate: placementId,
      candidate_status: pool ? 'TALENT' : candidate_status,
      readiness_status: pool ? '' : readiness_status,
    }

    addTalent({variables})
      .then(() => {
        enqueueSnackbar('Success Add data', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        refetch()
        if (!pool) {
          setValue({
            candidate_status: '',
            readiness_status: '',
          })
        }

        handleClose()
      })
      .catch(err => {
        enqueueSnackbar(err, {variant: 'error'})
      })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <ModalHeader>
        <Typography variant="h6">Talent Details</Typography>

        <IconButton aria-label="close" onClick={handleClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>
      <ModalContentEvent>
        <TalentSuccessorComponent userId={userId} type="talent-detail" />
        <Divider className={classes.divider} />
        {pool ? (
          <FormChildButton>
            <Button
              size="large"
              onClick={handleClose}
              style={{marginRight: 12}}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onMutationPool}
              size="large"
            >
              Add
            </Button>
          </FormChildButton>
        ) : (
          <ModalDetailTalentAction
            handleClose={handleClose}
            classes={classes}
            onMutationPool={onMutationPool}
            value={value}
            handleChange={handleChange}
          />
        )}
      </ModalContentEvent>
    </Dialog>
  )
}

export default ModalDetailTalent
