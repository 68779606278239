import gql from 'graphql-tag'

//Doc:https://github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/Individuals/EDIT%20INDIVIDUAL%20LEVEL.md
export const EDIT_INDIVIDUAL_LEVEL = gql`
  mutation updateCompetencyIndividualLevel($id: bigint!, $level: Int!) {
    update_competency_individuals(
      where: {id: {_eq: $id}}
      _set: {level: $level}
    ) {
      affected_rows
    }
  }
`
