import React, {useState, useEffect} from 'react'
import {
  Button,
  Dialog,
  Icon,
  IconButton,
  TextField,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import Select from 'react-select'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {useSnackbar} from 'notistack'
import {useQuery, useMutation} from '@apollo/react-hooks'

import {
  inputRequiredHelperText,
  onChangeNumber,
} from '../../financial/payroll/payroll-settings/payroll-settings-tabs/formValidation.helper'
import {
  WrapperModalGeneral,
  StyledFormLabel,
} from '../../financial/payroll/PayrollPageStyles'
import AddEditConfimationPopup from './AddEditPopupFreeText'

import {
  ADD_PEOPLE_PROFILE_BANK,
  UPDATE_PEOPLE_PROFILE_BANK,
} from '../../../graphql/mutations'
import {GET_BANK_OPTION_LIST, CEK_ID_BANK} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'

export default function AddEditBankAccount({
  data,
  onClose,
  onDeleteClicked = () => {},
  open,
  refetch,
  role = 'organization-hr-admin',
  userId,
  userName,
  withDelete = false,
}) {
  const {enqueueSnackbar} = useSnackbar()

  const isEdit = !!data?.account_number
  const _role = {
    context: {
      headers: {
        'X-Hasura-Role': role,
      },
    },
  }

  const [openAddEditPopup, setOpenAddEditPopup] = useState(open)
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false)
  const [confirmationPopupType, setConfirmationPopupType] = useState(null)
  const [confirmationPopupSetting, setConfirmationPopupSetting] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isSkipAccountIdChecking, setIsSkipAccountIdChecking] = useState(false)
  const [accountName, setAccountName] = useState(data?.account_name || null)
  const [accountNumber, setAccountNumber] = useState(
    data?.account_number || null
  )
  const [selectedBank, setSelectedBank] = useState(
    isEdit
      ? {
          value: data?.bank,
          label: data?.bank_name || '',
        }
      : []
  )

  const [addBankAccount] = useMutation(ADD_PEOPLE_PROFILE_BANK, _role)
  const [editBankAccount] = useMutation(UPDATE_PEOPLE_PROFILE_BANK, _role)

  const {data: bank_data, error: bank_data_error} = useQuery(
    GET_BANK_OPTION_LIST,
    {
      ..._role,
      skip: !open,
      fetchPolicy: 'cache-first',
    }
  )

  /** It's run only when save button is clicked */
  const {
    data: check_user_bank_account,
    error: check_user_bank_account_error,
  } = useQuery(CEK_ID_BANK, {
    variables: {
      account: accountNumber?.toString() || '',
      user: userId,
    },
    ..._role,
    skip: !isSkipAccountIdChecking,
    fetchPolicy: 'network-only',
    onCompleted() {
      if (isSkipAccountIdChecking) {
        setIsSkipAccountIdChecking(false)

        if (check_user_bank_account?.people_profile_banks?.length > 0) {
          if (
            /** If it's EDIT,
             *  and bank account is same as current (edited) bank account,
             *  then run mutation */
            check_user_bank_account?.people_profile_banks?.[0]?.id === data?.id
          ) {
            handleConfirm()
          } else {
            /** If it's ADD,
             *  and bank account is already exist,
             *  then display error message */
            enqueueSnackbar('Rekening sudah ada', {
              variant: 'error',
            })
            onClose()
            return false
          }
        } else {
          /** If bank account does not exist, then run mutation */
          handleConfirm()
        }
      }
    },
  })

  const handleConfirm = () => {
    /** If bank account is not already exist or it's current (edited) bank account */
    if (
      check_user_bank_account?.people_profile_banks?.length === 0 ||
      check_user_bank_account?.people_profile_banks?.[0]?.id === data?.id
    ) {
      setIsSubmit(true)

      if (
        !selectedBank ||
        !accountNumber ||
        accountNumber === '' ||
        !accountName ||
        accountName === ''
      ) {
        enqueueSnackbar('Please input all required data', {
          variant: 'error',
        })
        setOpenAddEditPopup(true)
        setOpenConfirmationPopup(false)
      } else {
        setOpenAddEditPopup(false)
        const variables = {
          account_name: accountName,
          account_number: accountNumber.toString(),
          bank_name: selectedBank.label,
        }

        if (isEdit) {
          variables.pp_bank_id = data?.id
          variables.pp_bank_creator_company = data?.creator_company
          variables.global_bank_id = selectedBank.value

          editBankAccount({
            variables,
            ..._role,
          })
            .then(() => {
              enqueueSnackbar('Rekening berhasil diubah', {
                variant: 'success',
                autoHideDuration: 3000,
              })
              refetch()
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e), {
                variant: 'error',
              })
            })
            .finally(() => {
              handleModalClose()
            })
        } else {
          /** If it's ADD */
          variables.bank = selectedBank.value
          variables.creator_company = COMPANY_ID
          variables.user = userId

          addBankAccount({
            variables,
            ..._role,
          })
            .then(() => {
              enqueueSnackbar('Rekening berhasil ditambahkan', {
                variant: 'success',
                autoHideDuration: 3000,
              })
              refetch()
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e), {
                variant: 'error',
              })
            })
            .finally(() => {
              handleModalClose()
            })
        }
      }
    }
  }

  const handleModalClose = () => {
    setSelectedBank([])
    setAccountNumber(null)
    setAccountName(null)
    setIsSubmit(false)
    setConfirmationPopupSetting(null)
    setConfirmationPopupType(false)
    setOpenAddEditPopup(false)
    setOpenConfirmationPopup(false)

    onClose()
  }

  const bankOptions = bank_data?.global_banks?.map(option => {
    return {
      value: option.id,
      label: option.name,
    }
  })

  useEffect(() => {
    switch (confirmationPopupType) {
      case 'add':
        setConfirmationPopupSetting({
          mutation: () => {
            setIsSkipAccountIdChecking(true)
          },
          header: 'Tambahkan Rekening',
          body: `Apakah anda yakin menambahkan profil rekening ${(userName &&
            `ke ${userName} ini`) ||
            ''}?`,
        })
        break
      case 'edit':
        setConfirmationPopupSetting({
          mutation: () => {
            setIsSkipAccountIdChecking(true)
          },
          header: 'Simpan Perubahan',
          body: `Apakah anda yakin untuk menyimpan perubahan?`,
        })
        break
      case 'cancel':
        setConfirmationPopupSetting({
          mutation: handleModalClose,
          header: 'Batalkan Perubahan',
          body: `Apakah anda yakin untuk membatalkan perubahan?`,
        })
        break
    }
  }, [confirmationPopupType])

  const bankRequiredError = isSubmit && selectedBank?.length < 1

  if (
    bank_data_error !== undefined ||
    check_user_bank_account_error !== undefined
  ) {
    return (
      <div>
        {JSON.stringify(bank_data_error) ||
          JSON.stringify(check_user_bank_account_error)}
      </div>
    )
  }

  return (
    <>
      <Dialog
        fullWidth
        open={openAddEditPopup}
        scroll="body"
        maxWidth="sm"
        onClose={handleModalClose}
      >
        <WrapperModalGeneral>
          <div className="header">
            <h1 className="title">{`${
              isEdit ? 'Ubah' : 'Tambahkan'
            } Rekening `}</h1>
            <div style={{display: 'flex', alignItems: 'end'}}>
              {withDelete && (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    onDeleteClicked()
                  }}
                >
                  <DeleteOutlineIcon
                    style={{
                      color: 'white',
                    }}
                  />
                </IconButton>
              )}
              <IconButton
                aria-label="close"
                className="wrap-close"
                onClick={handleModalClose}
              >
                <Icon className="icon-close">close</Icon>
              </IconButton>
            </div>
          </div>
          <div className="body">
            <StyledFormLabel component="legend" style={{marginTop: 0}}>
              Bank *
            </StyledFormLabel>
            <FormControl
              required
              error={bankRequiredError}
              component="fieldset"
              style={{width: '100%'}}
            >
              <Select
                id="bank-select"
                className="single-select"
                classNamePrefix="react-select"
                placeholder="Pilih bank"
                options={bankOptions}
                value={selectedBank}
                style={{marginTop: 10}}
                maxMenuHeight={200}
                styles={{
                  menu: provided => ({...provided, zIndex: 9999}),
                  container: () => ({marginTop: 10}),
                  control: base => ({
                    ...base,
                    boxShadow: null,
                  }),
                  valueContainer: provided => ({
                    ...provided,
                    height: '30px',
                    padding: '0 6px',
                  }),
                }}
                onChange={event =>
                  setSelectedBank({
                    label: event.label,
                    value: event.value,
                  })
                }
              />
              <FormHelperText style={{marginLeft: 14}}>
                {bankRequiredError ? 'Perlu diisi inputan wajib ini' : ''}
              </FormHelperText>
            </FormControl>

            <StyledFormLabel component="legend">
              Nama Pemilik Rekening *
            </StyledFormLabel>
            <TextField
              required
              error={isSubmit && !accountName}
              helperText={inputRequiredHelperText(isSubmit, !accountName)}
              type="text"
              size="small"
              margin="normal"
              variant="outlined"
              placeholder="Tambahkan nama"
              value={accountName}
              style={{width: '100%'}}
              onChange={e => {
                setAccountName(e.target.value)
              }}
            />

            <StyledFormLabel component="legend">Nomor Akun *</StyledFormLabel>
            <TextField
              required
              error={isSubmit && !accountNumber}
              helperText={inputRequiredHelperText(isSubmit, !accountNumber)}
              type="number"
              size="small"
              margin="normal"
              variant="outlined"
              style={{width: '100%'}}
              placeholder="Tambahkan nomor akun"
              value={accountNumber}
              onChange={e => {
                onChangeNumber(e, setAccountNumber)
              }}
              inputProps={{
                min: 0,
              }}
            />
          </div>

          <div className="action-wrapper-modal-confirmation">
            <Button
              style={{fontSize: 12}}
              onClick={() => {
                setConfirmationPopupType('cancel')
                setOpenAddEditPopup(false)
                setOpenConfirmationPopup(true)
              }}
            >
              Batal
            </Button>
            <Button
              color="primary"
              size="large"
              variant="contained"
              style={{fontSize: 12, marginLeft: 12}}
              onClick={() => {
                setConfirmationPopupType(isEdit ? 'edit' : 'add')
                setOpenAddEditPopup(false)
                setOpenConfirmationPopup(true)
              }}
            >
              Simpan
            </Button>
          </div>
        </WrapperModalGeneral>
      </Dialog>

      {openConfirmationPopup && (
        <AddEditConfimationPopup
          open={openConfirmationPopup}
          mutation={confirmationPopupSetting?.mutation}
          header={confirmationPopupSetting?.header}
          body={confirmationPopupSetting?.body}
          button={'Konfirmasi'}
          handleClose={() => {
            setOpenConfirmationPopup(false)
          }}
          handleCancel={() => {
            setOpenAddEditPopup(true)
            setOpenConfirmationPopup(false)
          }}
        />
      )}
    </>
  )
}
