import gql from 'graphql-tag'

export const GET_THR_REPORT = gql`
  query getThrReportList(
    $company: uuid!
    $date_start: date
    $date_end: date
    $limit: Int
    $offset: Int
    $search: String
  ) {
    thr_count: finance_thr_schedules_aggregate(
      where: {
        company: {_eq: $company}
        deleted_at: {_is_null: true}
        people_work_thrs: {process_step: {_eq: 5}}
        _or: [
          {global_religion: {name: {_ilike: $search}}}
          {religion: {_is_null: true}}
        ]
        _and: [{schedule: {_gte: $date_start}}, {schedule: {_lte: $date_end}}]
      }
    ) {
      aggregate {
        count
      }
    }

    schedules: finance_thr_schedules(
      limit: $limit
      offset: $offset
      where: {
        company: {_eq: $company}
        deleted_at: {_is_null: true}
        people_work_thrs: {process_step: {_eq: 5}}
        _or: [
          {global_religion: {name: {_ilike: $search}}}
          {religion: {_is_null: true}}
        ]
        _and: [{schedule: {_gte: $date_start}}, {schedule: {_lte: $date_end}}]
      }
      order_by: [{schedule: desc}]
    ) {
      id
      schedule
      status
      total
      global_religion {
        id
        name
      }
    }
  }
`

export const GET_THR_REPORT_DETAIL = gql`
  query GetThrReportDetail(
    $schedule_id: Int
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $rank: [Int!]
    $office: [Int!]
    $offset: Int
    $limit: Int
    $search: String
  ) {
    employee_count: people_work_thr_aggregate(
      where: {
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    employees: people_work_thr(
      limit: $limit
      offset: $offset
      where: {
        id_schedule: {_eq: $schedule_id}
        people_work_placement: {
          _or: [
            {global_user: {name: {_ilike: $search}}}
            {regno: {_ilike: $search}}
          ]
          company_job_profile: {
            _and: [
              {id: {_in: $job_profile}}
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {rank: {_in: $rank}}
              {office_address: {_in: $office}}
            ]
          }
        }
      }
    ) {
      id
      thr
      date_sent
      date_printed
      process_step
      approve_step
      employee_placement: people_work_placement {
        id
        regno
        status
        end_date
        company_job_profile {
          id
          title
          company_employee_position {
            id
            name
          }
          company_organization {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
            global_province {
              id
              name
            }
            global_city {
              id
              name
            }
          }
        }
        global_user {
          id
          name
          avatar
          people_profiles {
            id
            religion
          }
        }
      }
    }
  }
`

export const GET_THR_REPORT_SLIP = gql`
  query($id: bigint!) {
    people_work_thr_by_pk(id: $id) {
      year
      month
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          npwp: people_identities(where: {type: {_eq: "NPWP"}}) {
            id
          }
        }
        company_job_profile {
          id
          title
          company_organization {
            id
            name
          }
          company_employee_position {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
        people_work_salaries(where: {active: {_eq: true}}) {
          currency
          value
          salary_fields
          people_profile_bank {
            id
            global_bank {
              id
              name
            }
            branch
            account_name
            account_number
          }
        }
      }
      id
      thr
      income
      deduction
      allowances
      non_fixed_components
      payroll_fields
      date_sent
      date_printed
    }
  }
`
