import gql from 'graphql-tag'

export const GET_LIST_CROSS_MENTORING = gql`
  query {
    classroom_cross_mentoring_versions {
      id
      name
      created_by_org
      status
      description
      # Period
      start_date
      end_date
      classroom_cross_mentoring_mentees {
        people_work_placement {
          id
          global_user {
            id
            name
          }
        }
      }
    }
  }
`
