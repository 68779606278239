import gql from 'graphql-tag'

export const GET_LIST_COMPANY_SUMMARY = gql`
  query(
    $company: uuid!
    $search: String
    $types: [Int!]
    $exTypes: [Int!]
    $industry: [Int!]
    $exIndustry: [Int!]
    $offset: Int!
    $limit: Int!
  ) {
    total: holding_relations_aggregate(
      where: {
        company_head: {_eq: $company}
        status: {_eq: "ACTIVE"}
        company_member_profile: {
          legal_type: {_in: $types, _nin: $exTypes}
          _or: [
            {brand_name: {_ilike: $search}}
            {legal_name: {_ilike: $search}}
          ]
          industry_type: {_in: $industry, _nin: $exIndustry}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    holding_relations(
      where: {
        company_head: {_eq: $company}
        status: {_eq: "ACTIVE"}
        company_member_profile: {
          legal_type: {_in: $types, _nin: $exTypes}
          _or: [
            {brand_name: {_ilike: $search}}
            {legal_name: {_ilike: $search}}
          ]
          industry_type: {_in: $industry, _nin: $exIndustry}
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      company_member_profile {
        id
        legal_name
        brand_name
        logo
        types
        company_legal_type {
          id
          legal_type
        }
        global_company_industry_type {
          id
          name
        }
        profile_field
      }
    }
  }
`

export const LIST_FILTER_TYPE = gql`
  query($where: company_legal_types_bool_exp, $limit: Int) {
    total: company_legal_types_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: company_legal_types(where: $where, limit: $limit) {
      id
      name: legal_type
    }
  }
`

export const LIST_FILTER_INDUSTRY = gql`
  query($where: global_company_industry_types_bool_exp, $limit: Int) {
    total: global_company_industry_types_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    items: global_company_industry_types(where: $where, limit: $limit) {
      id
      name
    }
  }
`

export const LIST_FILTER_GENERATE_COMPANY = gql`
  query(
    $company: uuid!
    $search: String
    $offset: Int
    $limit: Int
    $nin: [Int!]
    $in: [Int!]
  ) {
    total: holding_relations_aggregate(
      where: {
        id: {_nin: $nin, _in: $in}
        company_head: {_eq: $company}
        status: {_eq: "ACTIVE"}
        company_member_profile: {
          _or: [
            {brand_name: {_ilike: $search}}
            {legal_name: {_ilike: $search}}
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    holding_relations(
      where: {
        id: {_nin: $nin, _in: $in}
        company_head: {_eq: $company}
        status: {_eq: "ACTIVE"}
        company_member_profile: {
          _or: [
            {brand_name: {_ilike: $search}}
            {legal_name: {_ilike: $search}}
          ]
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      company_member_profile {
        id
        legal_name
        brand_name
        logo
        types
        company_legal_type {
          id
          legal_type
        }
        global_company_industry_type {
          id
          name
        }
        profile_field
      }
    }
  }
`

export const GET_DETAIL_COMPANY = gql`
  query getCompanyByID($company: uuid!) {
    company_profiles(where: {id: {_eq: $company}}) {
      id
      logo
      brand_name
      legal_name
    }
  }
`

export const GET_DETAIL_COMPANY_GOAL = gql`
  query dashboardPerformanceCompanyGoal(
    $filterDate: date_comparison_exp
    $company: uuid!
  ) {
    all_goal: performance_goals_aggregate(
      where: {
        company: {_eq: $company}
        startdate: $filterDate
        kpi_list: {
          _or: [
            {_not: {kpi_cascading_lists: {}}}
            {
              kpi_cascading_lists: {
                deleted_at: {_is_null: true}
                cascading_main: {
                  company: {_eq: $company}
                  deleted_at: {_is_null: true}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          ]
        }
      }
    ) {
      aggregate {
        total_all_goal: count
        avg {
          avg_all_goal: progress_percentage
        }
      }
    }
    all_goal_complete: performance_goals_aggregate(
      where: {
        company: {_eq: $company}
        startdate: $filterDate
        kpi_list: {
          _or: [
            {_not: {kpi_cascading_lists: {}}}
            {
              kpi_cascading_lists: {
                deleted_at: {_is_null: true}
                cascading_main: {
                  company: {_eq: $company}
                  deleted_at: {_is_null: true}
                  status: {_eq: "ACTIVE"}
                }
              }
            }
          ]
        }
        _not: {
          performance_tasks: {status: {_in: ["TODO", "INPROGRESS", "DONE"]}}
        }
      }
    ) {
      aggregate {
        total_all_goal_complete: count
      }
    }
    all_personal_goal: performance_goals_aggregate(
      where: {
        company: {_eq: $company}
        startdate: $filterDate
        kpi_list: {_not: {kpi_cascading_lists: {}}}
      }
    ) {
      aggregate {
        total_all_personal_goal: count
        avg {
          avg_all_personal_goal: progress_percentage
        }
      }
    }
    all_personal_goal_complete: performance_goals_aggregate(
      where: {
        company: {_eq: $company}
        startdate: $filterDate
        kpi_list: {_not: {kpi_cascading_lists: {}}}
        _not: {
          performance_tasks: {status: {_in: ["TODO", "INPROGRESS", "DONE"]}}
        }
      }
    ) {
      aggregate {
        total_all_personal_goal_complete: count
        avg {
          avg_all_personal_goal_complete: progress_percentage
        }
      }
    }
    all_personal_goal_todo: performance_goals_aggregate(
      where: {
        company: {_eq: $company}
        startdate: $filterDate
        kpi_list: {_not: {kpi_cascading_lists: {}}}
        _not: {performance_tasks: {status: {_in: ["COMPLETED", "ARCHIVED"]}}}
      }
    ) {
      aggregate {
        total_all_personal_goal_todo: count
        avg {
          avg_all_personal_goal_todo: progress_percentage
        }
      }
    }
    all_company_goal: performance_goals_aggregate(
      where: {
        company: {_eq: $company}
        startdate: $filterDate
        kpi_list: {
          kpi_cascading_lists: {
            deleted_at: {_is_null: true}
            cascading_main: {
              company: {_eq: $company}
              deleted_at: {_is_null: true}
              status: {_eq: "ACTIVE"}
            }
          }
        }
      }
    ) {
      aggregate {
        total_all_company_goal: count
        avg {
          avg_all_company_goal: progress_percentage
        }
      }
    }
    all_company_goal_complete: performance_goals_aggregate(
      where: {
        company: {_eq: $company}
        startdate: $filterDate
        kpi_list: {
          kpi_cascading_lists: {
            deleted_at: {_is_null: true}
            cascading_main: {
              company: {_eq: $company}
              deleted_at: {_is_null: true}
              status: {_eq: "ACTIVE"}
            }
          }
        }
        _not: {
          performance_tasks: {status: {_in: ["TODO", "INPROGRESS", "DONE"]}}
        }
      }
    ) {
      aggregate {
        total_all_company_goal_complete: count
        avg {
          avg_all_company_goal_complete: progress_percentage
        }
      }
    }
    all_company_goal_todo: performance_goals_aggregate(
      where: {
        company: {_eq: $company}
        startdate: $filterDate
        kpi_list: {
          kpi_cascading_lists: {
            deleted_at: {_is_null: true}
            cascading_main: {
              company: {_eq: $company}
              deleted_at: {_is_null: true}
              status: {_eq: "ACTIVE"}
            }
          }
        }
        _not: {performance_tasks: {status: {_in: ["COMPLETED", "ARCHIVED"]}}}
      }
    ) {
      aggregate {
        total_all_company_goal_todo: count
        avg {
          avg_all_company_goal_todo: progress_percentage
        }
      }
    }
  }
`

export const GET_DETAIL_COMPANY_TASK = gql`
  query dashboardPerformanceCompanyTask(
    $filterDate: date_comparison_exp
    $company: uuid!
  ) {
    all_task: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task: count
        avg {
          avg_all_task: progress_percentage
        }
      }
    }
    all_task_todo: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "TODO"}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_todo: count
        avg {
          avg_all_task_todo: progress_percentage
        }
      }
    }
    all_task_inprogress: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "INPROGRESS"}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_inprogress: count
        avg {
          avg_all_task_inprogress: progress_percentage
        }
      }
    }
    all_task_done: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "DONE"}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_done: count
        avg {
          avg_all_task_done: progress_percentage
        }
      }
    }
    all_task_completed: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_in: ["COMPLETED", "ARCHIVED"]}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_completed: count
        avg {
          avg_all_task_completed: progress_percentage
        }
      }
    }
    personal_task: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        company: {_eq: $company}
        performance_goal: {kpi_list: {_not: {kpi_cascading_lists: {}}}}
      }
    ) {
      aggregate {
        total_personal_task: count
        avg {
          avg_personal_task: progress_percentage
        }
      }
    }
    personal_task_todo: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "TODO"}
        company: {_eq: $company}
        performance_goal: {kpi_list: {_not: {kpi_cascading_lists: {}}}}
      }
    ) {
      aggregate {
        total_personal_task_todo: count
        avg {
          avg_personal_task_todo: progress_percentage
        }
      }
    }
    personal_task_inprogress: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "INPROGRESS"}
        company: {_eq: $company}
        performance_goal: {kpi_list: {_not: {kpi_cascading_lists: {}}}}
      }
    ) {
      aggregate {
        total_personal_task_inprogress: count
        avg {
          avg_personal_task_inprogress: progress_percentage
        }
      }
    }
    personal_task_done: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "DONE"}
        company: {_eq: $company}
        performance_goal: {kpi_list: {_not: {kpi_cascading_lists: {}}}}
      }
    ) {
      aggregate {
        total_personal_task_done: count
        avg {
          avg_personal_task_done: progress_percentage
        }
      }
    }
    personal_task_completed: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_in: ["COMPLETED", "ARCHIVED"]}
        company: {_eq: $company}
        performance_goal: {kpi_list: {_not: {kpi_cascading_lists: {}}}}
      }
    ) {
      aggregate {
        total_personal_task_completed: count
        avg {
          avg_personal_task_completed: progress_percentage
        }
      }
    }
    company_task: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            kpi_cascading_lists: {
              deleted_at: {_is_null: true}
              cascading_main: {
                company: {_eq: $company}
                deleted_at: {_is_null: true}
                status: {_eq: "ACTIVE"}
              }
            }
          }
        }
      }
    ) {
      aggregate {
        total_company_task: count
        avg {
          avg_company_task: progress_percentage
        }
      }
    }
    company_task_todo: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "TODO"}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            kpi_cascading_lists: {
              deleted_at: {_is_null: true}
              cascading_main: {
                company: {_eq: $company}
                deleted_at: {_is_null: true}
                status: {_eq: "ACTIVE"}
              }
            }
          }
        }
      }
    ) {
      aggregate {
        total_company_task_todo: count
        avg {
          avg_company_task_todo: progress_percentage
        }
      }
    }
    company_task_inprogress: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "INPROGRESS"}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            kpi_cascading_lists: {
              deleted_at: {_is_null: true}
              cascading_main: {
                company: {_eq: $company}
                deleted_at: {_is_null: true}
                status: {_eq: "ACTIVE"}
              }
            }
          }
        }
      }
    ) {
      aggregate {
        total_company_task_inprogress: count
        avg {
          avg_company_task_inprogress: progress_percentage
        }
      }
    }
    company_task_done: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "DONE"}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            kpi_cascading_lists: {
              deleted_at: {_is_null: true}
              cascading_main: {
                company: {_eq: $company}
                deleted_at: {_is_null: true}
                status: {_eq: "ACTIVE"}
              }
            }
          }
        }
      }
    ) {
      aggregate {
        total_company_task_done: count
        avg {
          avg_company_task_done: progress_percentage
        }
      }
    }
    company_task_completed: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_in: ["COMPLETED", "ARCHIVED"]}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            kpi_cascading_lists: {
              deleted_at: {_is_null: true}
              cascading_main: {
                company: {_eq: $company}
                deleted_at: {_is_null: true}
                status: {_eq: "ACTIVE"}
              }
            }
          }
        }
      }
    ) {
      aggregate {
        total_company_task_completed: count
        avg {
          avg_company_task_completed: progress_percentage
        }
      }
    }
  }
`
