import ClassificationPage from './ClassificationPageComponent'

export const PerfClassificationPageConfig = {
  routes: [
    {
      path: '/performance/classifications',
      component: ClassificationPage,
      state: 'perf-classification',
      exact: true,
    },
    {
      path: '/performance/classifications/add',
      component: ClassificationPage,
      state: 'perf-classification-add',
      exact: true,
    },
    {
      path: '/performance/classifications/edit/:id',
      component: ClassificationPage,
      state: 'perf-classification-edit',
      exact: true,
    },
    {
      path: '/performance/classifications/detail',
      component: ClassificationPage,
      state: 'perf-classification-detail',
      exact: true,
    },
  ],
}
