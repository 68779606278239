import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Button,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Loading from '../../../../components/loading/LoadingComponent'
import {BoldTypography} from '../../../../components/typography/TypographyStyles'
import {
  EnhancedToolbar,
  Flex,
  FlexBetween,
  GreyTypography,
  MoreIcon,
  PaperContainer,
  StyledMenuItem,
  StyledMenus,
} from '../../../../GlobalStyles'
import {GET_LIST_ARCHIVE_ORG} from '../../../../graphql/queries'
import {FlexCenter} from '../../../performance/goal/modal/modalStyles'
import FormArchive from './form-archive/FormArchive'
import moment from 'moment'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import {DELETE_ARCHIVE_OM} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'

const InfoTooltip = withStyles(() => ({
  tooltip: {
    padding: '12px 14px',
    backgroundColor: '#fff',
    color: '#000',
    fontSize: 14,
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  arrow: {
    color: '#fff',
  },
}))(Tooltip)

const ListArchive = ({search}) => {
  const {enqueueSnackbar} = useSnackbar()
  const {data, loading, refetch} = useQuery(GET_LIST_ARCHIVE_ORG, {
    variables: {
      search: `%${search}%`,
    },
  })

  const [deleteArchive] = useMutation(DELETE_ARCHIVE_OM)

  const [openModal, setOpenModal] = useState('')
  const [openDelete, setOpenDelete] = useState(false)
  const [selected, setSelected] = useState(null)

  if (loading) {
    return <Loading />
  }

  const handleModalArchive = (isEdit, sel) => {
    if (isEdit) {
      setOpenModal('edit')
      setSelected({
        id: sel.id,
        name: sel.name,
        description: sel.description,
      })
    } else {
      setOpenModal('add')
    }
  }

  const handleModalDelete = res => {
    setOpenDelete(prev => !prev)
    setSelected(res)
  }

  const onCloseModal = () => {
    setOpenModal('')
    setSelected(null)
  }

  const onDeleteArchive = () => {
    deleteArchive({variables: {id: selected.id}}).then(() => {
      refetch()
      enqueueSnackbar('Delete Success', {
        variant: 'success',
        autoHideDuration: 2500,
      })
    })
  }
  return (
    <PaperContainer>
      <EnhancedToolbar>
        <Typography variant="h6">Archive</Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleModalArchive(false)}
        >
          Generate Current Archive
        </Button>
      </EnhancedToolbar>
      <Divider></Divider>
      <div style={{padding: '24px 20px'}}>
        {data.company_archived_logs.map((res, i) => (
          <ExpansionPanel
            style={{
              padding: 6,
              background: '#f7f8f9',
              marginBottom: 20,
            }}
            key={i}
          >
            <ExpansionPanelSummary>
              <FlexBetween>
                <FlexCenter>
                  <BoldTypography variant="body2">{res.name}</BoldTypography>
                  {res.status === 'PENDING' && (
                    <InfoTooltip
                      title="This archive is still in the process of being generated. It may take some time."
                      arrow
                      placement="right-end"
                    >
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          padding: 2,
                          color: 'rgb(255, 160, 0)',
                          border: '2px solid rgb(255, 160, 0)',
                          borderRadius: '50%',
                          textAlign: 'center',
                          marginLeft: 18,
                          fontWeight: 600,
                          fontSize: 16,
                          cursor: 'pointer',
                        }}
                      >
                        !
                      </div>
                    </InfoTooltip>
                  )}
                </FlexCenter>
                <PopupState variant="popover">
                  {popupState => (
                    <>
                      <div onClick={e => e.stopPropagation()}>
                        <MoreIcon {...bindTrigger(popupState)}>
                          more_horiz
                        </MoreIcon>
                      </div>

                      <StyledMenus {...bindMenu(popupState)}>
                        <StyledMenuItem
                          component={Link}
                          to={{
                            pathname: '/organization/archive/' + res.id,
                            state: {active: 'detail-archive'},
                          }}
                        >
                          Detail
                        </StyledMenuItem>
                        <StyledMenuItem
                          onClick={e => {
                            handleModalArchive(true, res)
                            popupState.close()
                            e.stopPropagation()
                          }}
                        >
                          Edit
                        </StyledMenuItem>
                        <StyledMenuItem
                          onClick={e => {
                            handleModalDelete({
                              id: res.id,
                              name: res.name,
                            })
                            popupState.close()
                            e.stopPropagation()
                          }}
                        >
                          Delete
                        </StyledMenuItem>
                      </StyledMenus>
                    </>
                  )}
                </PopupState>
              </FlexBetween>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{
                display: 'block',
                background: 'white',
                margin: '0 20px 20px',
                padding: '12px 22px',
              }}
            >
              <BoldTypography variant="body2" paragraph>
                Archive Information
              </BoldTypography>
              <Flex>
                <GreyTypography
                  variant="body2"
                  gutterBottom
                  style={{width: '20%'}}
                >
                  Date generated
                </GreyTypography>
                <Typography paragraph gutterBottom variant="body2">
                  {moment(res.created_at).format('LL')}
                </Typography>
              </Flex>
              <Flex>
                <GreyTypography variant="body2" style={{width: '20%'}}>
                  Description
                </GreyTypography>
                <Typography variant="body2">{res.description}</Typography>
              </Flex>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
      <FormArchive
        open={openModal}
        refetch={refetch}
        handleClose={onCloseModal}
        selected={selected}
      />
      <DeletePopup
        open={openDelete}
        handleClose={() => handleModalDelete(null)}
        id={selected && selected.id}
        name={selected && selected.name}
        mutation={onDeleteArchive}
      />
    </PaperContainer>
  )
}

export default ListArchive
