import React, {useState} from 'react'
import Card from '../../../components/card/CardComponent'
import {PageContainer} from '../../../GlobalStyles'

import ListComponent from './components/ListComponent'
import DetailProfileComponent from './components/DetailProfileComponent'

const renderComponent = (activeComponent, state, searchText = '') => {
  switch (activeComponent) {
    case 'learning-profile-list':
      return <ListComponent state={state} searchText={searchText} />
    case 'detail-learning-profile':
      return <DetailProfileComponent />
    default:
      return <ListComponent state={state} searchText={searchText} />
  }
}

const CrossMentoringPageComponent = props => {
  let active = ''
  let state = {}
  if (props.location.state) {
    active = props.location.state.active
    state = props.location.state
  }

  const [searchText, setSearchText] = useState('')
  const onSearch = search => {
    setSearchText(search)
  }

  const title =
    active === 'detail-learning-profile'
      ? 'Learning Profile Detail'
      : 'Learning'

  return (
    <Card icon="school" title={title} onSearch={onSearch}>
      <PageContainer>
        {renderComponent(active, state, searchText)}
      </PageContainer>
    </Card>
  )
}

export default CrossMentoringPageComponent
