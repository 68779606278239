import gql from 'graphql-tag'

export const UPDATE_MEDICAL_RECORD = gql`
  mutation($id: Int!, $data: people_profile_medical_records_set_input!) {
    update_people_profile_medical_records_by_pk(
      pk_columns: {id: $id}
      _set: $data
    ) {
      id
      medical_test_name
      medical_test_reason
      additional_medical_conditions
      disabilities
      hospitalized_reason
      blood_type
      blood_rhesus
      body_height
      body_weight
      head_size
      clothes_size
      pants_size
    }
  }
`

export const ADD_MEDICAL_RECORD = gql`
  mutation($data: people_profile_medical_records_insert_input!) {
    insert_people_profile_medical_records_one(object: $data) {
      id
    }
  }
`

export const DELETE_MEDICAL_RECORD = gql`
  mutation($id: Int!) {
    update_people_profile_medical_records_by_pk(
      pk_columns: {id: $id}
      _set: {deletedAt: "now()"}
    ) {
      id
      deletedAt
    }
  }
`
