import gql from 'graphql-tag'

//github.com/worklifebeyond/wlb-api-documentation/blob/master/competency/Individuals/DELETE%20INDIVIDUAL.md
export const DELETE_INDIVIDUAL_COMPETENCY = gql`
  mutation deleteCompetencyIndividual($id: bigint!) {
    update_competency_individuals(
      where: {id: {_eq: $id}}
      _set: {deleted_at: "now()"}
    ) {
      affected_rows
    }
  }
`
