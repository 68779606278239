import React, {useState, useEffect} from 'react'
import {Typography, Divider, Popover, TablePagination} from '@material-ui/core'
import {
  PaperContainer,
  TextBlackBold,
  EnhancedToolbar,
  Flex,
  MoreIcon,
  StyledMenuItem,
} from '../../../../GlobalStyles'
import MaterialTable from 'material-table'
import {NewTableOptions} from '../../../shared-components/table/TableOptions'
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state'
import {Link} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import {GET_DEVELOPMENT_PLAN_LIST} from '../../../../graphql/queries'
import {COMPANY_ID, DEFAULT_PAGINATION} from '../../../../utils/globals'
import {capitalize} from '../../../../utils/helpers'
import NoBadge from '../../../../assets/images/no-badge.svg'
import helperStyle from './DevelopmentPageStyles'

const DevelopmentPlanComponent = props => {
  const classes = helperStyle()
  const {searchText} = props

  const [pagination, setPagination] = useState({
    currentPage: 0,
    rowsPerPage: DEFAULT_PAGINATION[0],
  })

  useEffect(() => {
    setPagination(prev => ({...prev, currentPage: 0}))
  }, [searchText])

  const {data, loading} = useQuery(GET_DEVELOPMENT_PLAN_LIST, {
    wlb_skipPatch: true,
    variables: {
      name: `%${searchText}%`,
      company: COMPANY_ID,
      offset: pagination.rowsPerPage * pagination.currentPage,
      limit: pagination.rowsPerPage,
    },
  })

  const renderStatus = val => {
    const _status = {
      waiting: 'Menunggu',
      onprogress: 'Dalam Perkembangan',
      active: 'Aktif',
      done: 'Selesai',
    }
    if (val) {
      let _color
      if (val.toLowerCase() === 'done') {
        _color = '#4caf50'
      } else if (val.toLowerCase() === 'waiting') {
        _color = '#ffa000'
      } else {
        _color = '#039be5'
      }
      return (
        <div className={classes.divStatus} style={{backgroundColor: _color}}>
          {_status[val.toLowerCase()] || capitalize(val)}
        </div>
      )
    } else {
      return ''
    }
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPagination(prev => ({...prev, currentPage: newPage}))
  }

  const handleChangeRowsPerPage = event => {
    setPagination(prev => ({
      ...prev,
      rowsPerPage: +event.target.value,
      currentPage: 0,
    }))
  }

  return (
    <PaperContainer style={{overflow: 'unset'}}>
      <EnhancedToolbar>
        <Typography variant="h6">Rencana Pengembangan</Typography>
      </EnhancedToolbar>
      <Divider />
      <div style={{margin: '0px 20px'}}>
        <MaterialTable
          title="Aspiration"
          isLoading={loading}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            verticalAlign: 'text-top',
            boxShadow: 'none',
          }}
          columns={[
            {
              title: 'Nama',
              field: 'name',
              render: rowData => (
                <TextBlackBold>{rowData.global_user.name}</TextBlackBold>
              ),
            },
            {
              title: 'Posisi',
              field: 'company_job_profile',
              render: rowData => (
                <TextBlackBold>
                  {rowData?.company_job_profile?.title}
                </TextBlackBold>
              ),
            },
            {
              title: 'Lencana',
              field: 'talent_assignments',
              render: rowData =>
                rowData.talent_assignments.length > 0 ? (
                  <Flex>
                    <img
                      src={rowData?.talent_assignments[0]?.talent_box?.badge}
                      alt={rowData?.talent_assignments[0]?.talent_box?.name}
                      width="35"
                      style={{marginRight: '15px'}}
                    />
                    <Flex className={classes.column}>
                      <TextBlackBold>
                        {rowData?.talent_assignments[0]?.talent_box?.name}
                      </TextBlackBold>
                      <TextBlackBold>
                        {rowData?.talent_assignments[0]?.talent_score?.toFixed(
                          2
                        )}
                      </TextBlackBold>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex>
                    <img
                      src={NoBadge}
                      alt="No Badge"
                      width="35"
                      height="35"
                      style={{marginRight: '15px'}}
                    />
                    <TextBlackBold>Tidak ada Lencana</TextBlackBold>
                  </Flex>
                ),
            },
            {
              title: 'Rencana Pengembangan',
              field: 'development_plan',
              render: rowData =>
                rowData && rowData.talent_development_plans.length > 0 ? (
                  <TextBlackBold>
                    {rowData?.talent_development_plans[0]?.name}
                  </TextBlackBold>
                ) : (
                  <TextBlackBold>Tidak ada Rencana Pengembangan</TextBlackBold>
                ),
            },
            {
              title: 'Status',
              field: 'status',
              render: rowData => (
                <TextBlackBold>
                  {renderStatus(rowData?.talent_development_plans[0]?.status)}
                </TextBlackBold>
              ),
            },
            {
              title: 'Aksi',
              field: 'action',
              render: rowData => (
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {popupState => (
                    <div>
                      <MoreIcon {...bindTrigger(popupState)}>
                        more_horiz
                      </MoreIcon>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <StyledMenuItem
                          component={Link}
                          to={{
                            pathname: `/talent/succession/development-plan/detail`,
                            state: {
                              active: 'development-detail',
                              companyId: rowData.company_job_profile.company,
                              userId: rowData.global_user.id,
                            },
                          }}
                        >
                          Rincian
                        </StyledMenuItem>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              ),
            },
          ]}
          data={data?.people_work_placements}
          options={{
            ...NewTableOptions,
            paging: false,
            sorting: true,
            selection: false,
            emptyRowsWhenPaging: false,
          }}
          components={{
            Toolbar: () => null,
          }}
        />
        <TablePagination
          rowsPerPageOptions={DEFAULT_PAGINATION}
          component="div"
          count={data?.total.aggregate.count || 0}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </PaperContainer>
  )
}

export default DevelopmentPlanComponent
