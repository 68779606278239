import React from 'react'
import helperStyle, {
  OrganizationContainer,
} from '../../../OrganizationPageStyles'
import {
  FormChildContainer,
  FormChildInput,
  FormChildTitle,
  FormContainer,
  TitleName,
  TitleNumber,
} from '../../../../../GlobalStyles'

import {TextField} from '@material-ui/core'

const TabDetailJobFamily = props => {
  const classes = helperStyle()
  const {
    allDataFrom,
    setAllDataFrom,
    checkSameCodeWithAdd,
    checkCharaCode,
    checkCharaName,
    isError,
    codeChecker,
  } = props
  return (
    <OrganizationContainer>
      <FormContainer id="top">
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>1.</TitleNumber>
            <TitleName>Kode rumpun jabatan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="levelCode"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              fullWidth
              placeholder="Tambah rumpun jabatan"
              value={allDataFrom.code}
              margin="normal"
              error={
                (!allDataFrom.code && isError) ||
                checkCharaCode ||
                (codeChecker ? !checkSameCodeWithAdd : checkSameCodeWithAdd)
              }
              className={classes.helperMarginLeft0}
              helperText={
                !allDataFrom.code && isError
                  ? 'Bagian ini diperlukan'
                  : (codeChecker
                    ? !checkSameCodeWithAdd
                    : checkSameCodeWithAdd)
                  ? 'Nama rumpun jabatan sudah ada'
                  : checkCharaCode
                  ? 'Jumlah karakter maksimum adalah 30 karakter'
                  : 'Kode rumpun jabatan harus unik'
              }
              onChange={event => {
                setAllDataFrom({
                  ...allDataFrom,
                  code: event.target.value,
                })
              }}
              variant="outlined"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>2.</TitleNumber>
            <TitleName>Nama rumpun jabatan *</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="orgName"
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              fullWidth
              placeholder="Tambah nama rumpun jabatan"
              value={allDataFrom.name}
              margin="normal"
              className={classes.helperMarginLeft0}
              error={
                (!allDataFrom.name && isError) || checkCharaName
                // ||(nameChecker ? !checkSameNameWithAdd : checkSameNameWithAdd)
              }
              helperText={
                !allDataFrom.name && isError
                  ? 'Bagian ini diperlukan'
                  : checkCharaName
                  ? 'Jumlah karakter maksimum adalah 100 karakter'
                  : ''
              }
              onChange={event => {
                setAllDataFrom({
                  ...allDataFrom,
                  name: event.target.value,
                })
              }}
              variant="outlined"
            />
          </FormChildInput>
        </FormChildContainer>
        <FormChildContainer>
          <FormChildTitle>
            <TitleNumber>3.</TitleNumber>
            <TitleName>Deskripsi</TitleName>
          </FormChildTitle>
          <FormChildInput>
            <TextField
              id="orgName"
              style={{padding: '0px'}}
              InputProps={{
                classes: {
                  input: classes.outlinedInput,
                },
              }}
              fullWidth
              placeholder="Tambah Deskripsi"
              value={allDataFrom.description}
              margin="normal"
              className={classes.multilinePadding}
              multiline
              rows="5"
              // error={
              //   !allDataFrom.name && isError
              // }
              // helperText={}
              onChange={event => {
                setAllDataFrom({
                  ...allDataFrom,
                  description: event.target.value,
                })
              }}
              variant="outlined"
            />
          </FormChildInput>
        </FormChildContainer>
      </FormContainer>
    </OrganizationContainer>
  )
}

export default TabDetailJobFamily
