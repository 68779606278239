import React from 'react'
import {Typography} from '@material-ui/core'
import {ParameterInfoContainer} from '../ClassificationPageStyles'
import {dateMinENOrUS, parameters_versions} from '../../../../utils/helpers'

const ParameterInformation = ({dataParameter}) => {
  const {x, y} = dataParameter

  return (
    <>
      <Typography variant="h6" color="primary">
        Informasi Parameter
      </Typography>
      <ParameterInfoContainer>
        <div className="axis-box">
          <div className="bundle-container">
            <p className="p-title">Nama Parameter Sumbu X</p>
            <p className="p-value">{x?.name}</p>
          </div>
          <div className="bundle-container flex-between">
            <div className="bundle-container margin-0">
              <p className="p-title">Nilai Min</p>
              <p className="p-value">{x?.min}</p>
            </div>
            <div className="bundle-container margin-0">
              <p className="p-title">Nilai Maks</p>
              <p className="p-value">{x?.max}</p>
            </div>
          </div>
          <div className="bundle-container">
            <p className="p-parameter">Indikator Parameter</p>
            {x?.parameters?.map((data, key) => (
              <div className="bundle-container" key={key}>
                <p className="p-value">
                  {`${key + 1}. ${parameters_versions?.[data?.parameter]?.ID ||
                    data?.parameter}`}
                </p>
                <div className="indicatior-container">
                  <div className="title-container">
                    <p className="p-indicator">Periode</p>
                    <p className="p-indicator">Bobot</p>
                  </div>
                  <div className="value-container">
                    <p className="p-indicator">{`: ${dateMinENOrUS(
                      data?.start_date
                    )} - ${dateMinENOrUS(data?.end_date)}`}</p>
                    <p className="p-indicator">{`: ${parseFloat(
                      data?.weight ?? 0
                    ).toFixed(2)}`}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="axis-box">
          <div className="bundle-container">
            <p className="p-title">Nama Parameter Sumbu Y</p>
            <p className="p-value">{y?.name}</p>
          </div>
          <div className="bundle-container flex-between">
            <div className="bundle-container margin-0">
              <p className="p-title">Nilai Min</p>
              <p className="p-value">{y?.min}</p>
            </div>
            <div className="bundle-container margin-0">
              <p className="p-title">Nilai Maks</p>
              <p className="p-value">{y?.max}</p>
            </div>
          </div>
          <div className="bundle-container">
            <p className="p-parameter">Indikator Parameter</p>
            {y?.parameters?.map((data, key) => (
              <div className="bundle-container" key={key}>
                <p className="p-value">
                  {`${key + 1}. ${parameters_versions?.[data?.parameter]?.ID ||
                    data?.parameter}`}
                </p>
                <div className="indicatior-container">
                  <div className="title-container">
                    <p className="p-indicator">Periode</p>
                    <p className="p-indicator">Bobot</p>
                  </div>
                  <div className="value-container">
                    <p className="p-indicator">{`: ${dateMinENOrUS(
                      data?.start_date
                    )} - ${dateMinENOrUS(data?.end_date)}`}</p>
                    <p className="p-indicator">{`: ${parseFloat(
                      data?.weight ?? 0
                    ).toFixed(2)}`}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ParameterInfoContainer>
    </>
  )
}

export default ParameterInformation
