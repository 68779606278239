import React, {useEffect, useState} from 'react'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Tabs,
  Tab,
} from '@material-ui/core'
import {formGoalStyles} from '../form/FormGoalConfig'
import {
  AddEditTitle,
  ButtonWhiteRed,
  CustomTypography,
  FlexBetween,
  FormTitleContainer,
  FormToolbar,
} from '../../../../GlobalStyles'
import {withRouter} from 'react-router-dom'
import {PRIORITY_STYLE, TYPE_IDN, TYPE_STYLE} from '../constant'
import {
  DELETE_TASK,
  UPDATE_PROGRESS_TASK,
  UPDATE_REPORTER,
} from '../../../../graphql/mutations'
import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'
import {dateFormat} from '../../../../utils/helpers'
import {useSnackbar} from 'notistack'
import Loading from '../../../../components/loading/LoadingComponent'
import EditIcon from '@material-ui/icons/Edit'
import AsyncSelect from 'react-select/async'
import AlertDialog from '../../../shared-components/popup/ResultPopup'
import DeletePopup from '../../../shared-components/popup/DeletePopup'
import DEFAULT_AVATAR from '../../../../assets/images/default-avatar.png'
import {StyledLinear} from '../modal/modalStyles'
import Detail from '../modal/tabs/Detail'
import Description from '../modal/tabs/Description'
import {
  GET_DETAIL_TASK,
  GET_DROPDOWN_REPORTER_TASK,
} from '../../../../graphql/queries'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {priority} from '../../../shared-components/data/SharedData'

const DetailPageComponent = props => {
  const {history, state, match} = props
  const {enqueueSnackbar} = useSnackbar()
  const detailID = match.params.id
  const classes = formGoalStyles()
  const client = useApolloClient()

  const INITIAL_REPORTER_DATA = {
    open: false,
    id: '',
    search: '',
  }

  const INITIAL_ACTION = {
    open: false,
    action: '',
    feature: '',
    message: '',
    id: '',
  }

  const INITIAL_UPDATE_DATA = {
    open: false,
    data: '',
  }

  const HASURA_ROLE_STAFF = {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-staff',
      },
    },
  }

  const [reporterData, setReporterData] = useState(INITIAL_REPORTER_DATA)
  const [openAction, setOpenAction] = useState(INITIAL_ACTION)
  const [openUpdate, setOpenUpdate] = useState(INITIAL_UPDATE_DATA)
  const [tabsValue, setTabsValue] = useState(0)
  const {data, error, refetch} = useQuery(GET_DETAIL_TASK, {
    variables: {
      id: detailID,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [updateReporter] = useMutation(UPDATE_REPORTER)
  const [updateProgress] = useMutation(UPDATE_PROGRESS_TASK)
  const [deleteTask] = useMutation(DELETE_TASK)

  useEffect(() => {
    refetch()
  }, [data])

  if (data === undefined && error === undefined) {
    return <Loading />
  }

  if (error) {
    console.error({error})
  }

  const dataPerformance = data?.performance_tasks?.[0]
  const goalTypes =
    dataPerformance.performance_goal.kpi_list.kpi_cascading_lists_aggregate
      .aggregate.count > 0
      ? 'COMPANY_GOAL'
      : 'PERSONAL_GOAL'
  const isCompanyType = goalTypes === 'COMPANY_GOAL'
  const isInactiveCascading =
    dataPerformance?.performance_goal?.kpi_list?.kpi_cascading_lists?.[0]?.cascading_main?.status?.toLowerCase() !==
    'active'
  const isInactive = isCompanyType && isInactiveCascading
  const isComplete = dataPerformance.progress_percentage >= 100
  const weightUnit = dataPerformance.kpi_weight_unit?.name ?? '-'

  const handleSelectReporter = () => {
    updateReporter({
      variables: {
        id: dataPerformance.id,
        reporter: reporterData.id,
      },
      ...HASURA_ROLE_STAFF,
    })
      .then(() => {
        enqueueSnackbar('Reporter updated', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
        setReporterData(INITIAL_REPORTER_DATA)
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const onDeleteTask = () => {
    deleteTask({
      variables: {
        id: openAction.id,
      },
    })
      .then(() => {
        enqueueSnackbar('Task deleted', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        handleBackButton()
      })
      .catch(() => {
        enqueueSnackbar('Delete Task failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleBackButton = () => {
    history.push({
      pathname: '/performance/goal',
      state: {active: 'goal', searchable: true, feature: state.feature},
    })
  }

  const handleCancelReporter = () => {
    setReporterData(INITIAL_REPORTER_DATA)
  }

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const handleEditAction = () => {
    if (!isInactive) {
      history.push({
        pathname: '/performance/goal/edit/' + dataPerformance.id,
        state: {
          active: 'edit-goal',
          feature: state.feature,
        },
      })
    } else {
      setOpenAction({
        open: true,
        action: 'alert',
        feature: 'Unable to Edit',
        message:
          'Unable to edit because cascading goal used in this goal is inactive',
      })
    }
  }

  const handleDeleteAction = () => {
    if (isInactive) {
      setOpenAction({
        open: true,
        action: 'alert',
        feature: 'Unable to Delete',
        message:
          'Unable to delete task because the task cascading status is inactive.',
      })
    } else {
      setOpenAction({
        open: true,
        action: 'delete',
        feature: dataPerformance.name,
        id: dataPerformance.id,
      })
    }
  }

  const handleUpdate = () => {
    updateProgress({
      variables: {
        id: dataPerformance.id,
        progress: openUpdate.data,
      },
      ...HASURA_ROLE_STAFF,
    })
      .then(() => {
        enqueueSnackbar('Progress updated', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetch()
        setOpenUpdate(INITIAL_UPDATE_DATA)
      })
      .catch(() => {
        enqueueSnackbar('Update data error, please try again', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const renderValue = dataValue => {
    return (
      <div className={classes.dropdownWrapper}>
        <Avatar
          className={classes.avatarDropdown}
          src={dataValue?.global_user?.avatar ?? DEFAULT_AVATAR}
        />
        <div className={classes.nameDropdown} style={{alignSelf: 'center'}}>
          {dataValue?.global_user?.name ?? '-'}
        </div>
      </div>
    )
  }

  const handleReporterSearch = async searchText => {
    const {data} = await client.query({
      query: GET_DROPDOWN_REPORTER_TASK,
      fetchPolicy: 'no-cache',
      variables: {
        task: detailID,
        search: `%${searchText}%`,
        limit: 5,
        offset: 0,
      },
    })

    const result = data?.performance_task_assignments?.map(assign => ({
      value: assign,
      // label: `${item.code} - ${item.name}`,
      label: (
        <div className={classes.dropdownWrapper}>
          <Avatar
            className={classes.avatarDropdown}
            src={assign?.global_user?.avatar ?? DEFAULT_AVATAR}
          />
          <div>
            <div className={classes.nameDropdown}>
              {assign?.global_user?.name ?? '-'}
            </div>
            <div className={classes.positionDropdown}>
              {assign?.global_user?.people_work_placements?.[0]
                ?.company_job_profile?.title ?? '-'}
            </div>
          </div>
        </div>
      ),
    }))

    return result || []
  }

  const renderStatus = dataProgress => {
    if (dataProgress < 100) {
      return 'Di bawah'
    } else if (dataProgress === 100) {
      return 'Memenuhi'
    } else {
      return 'Melebihi'
    }
  }

  return (
    <Paper className={classes.paper}>
      <FormToolbar disableGutters>
        <FormTitleContainer>
          <IconButton edge="end" aria-label="back" onClick={handleBackButton}>
            <ArrowBack />
          </IconButton>
          <AddEditTitle variant="h6">{`Rincian ${GLOBAL_GLOSSARY.performance.Task}`}</AddEditTitle>
        </FormTitleContainer>
        <div>
          <ButtonWhiteRed
            className={classes.buttonAction}
            onClick={handleDeleteAction}
          >
            Hapus
          </ButtonWhiteRed>
          <Button
            onClick={handleEditAction}
            style={{marginLeft: 20}}
            className={classes.buttonAction}
            color="primary"
            size="large"
            variant="contained"
          >
            Ubah
          </Button>
        </div>
      </FormToolbar>
      <Divider />
      <div className={classes.instructions}>
        <div id="id-name-section">
          <CustomTypography
            component="span"
            fcolor="lightBlue"
            size="16px"
            fweight="bold"
          >
            {`[PF-${dataPerformance.id}]`}
          </CustomTypography>
          <CustomTypography
            component="span"
            size="16px"
            fweight="bold"
            left="12px"
          >
            {dataPerformance.name}
          </CustomTypography>
        </div>
        <div id="created-section" style={{marginTop: 8}}>
          <CustomTypography component="span">Dibuat oleh</CustomTypography>
          <CustomTypography component="span" fcolor="lightBlue">
            {` ${dataPerformance.created_by_user.name} `}
          </CustomTypography>
          <CustomTypography component="span">
            {`pada ${dateFormat(
              dataPerformance.date_added,
              'LL'
            )} • ${dateFormat(dataPerformance.date_added, 'LT')}`}
          </CustomTypography>
        </div>
        <FlexBetween id="label-section" style={{marginTop: 12}}>
          <div id="chip">
            <div
              style={{
                backgroundColor: PRIORITY_STYLE[dataPerformance.priority],
              }}
              className={classes.chipsRoot}
            >
              {priority[dataPerformance.priority]}
            </div>
            <div
              style={{backgroundColor: TYPE_STYLE[goalTypes], marginLeft: 16}}
              className={classes.chipsRoot}
            >
              {TYPE_IDN[goalTypes]}
            </div>
          </div>
          <div id="label" className={classes.pfGroupWrap}>
            <div
              className={classes.box}
              style={{
                backgroundColor:
                  dataPerformance?.performance_group?.color ?? '#e5e5e5',
              }}
            />
            <CustomTypography
              className={classes.pfGroup}
              gutterBottom
              style={{
                fontStyle: dataPerformance.performance_group
                  ? 'initial'
                  : 'italic',
              }}
            >
              {dataPerformance?.performance_group?.name ?? 'None'}
            </CustomTypography>
          </div>
        </FlexBetween>
        <FlexBetween id="people-section" style={{marginTop: 28}}>
          <div id="reporter">
            <CustomTypography fcolor="grey" bottom="12px">
              Pelapor
            </CustomTypography>
            <div className={classes.wrapperReporter}>
              {reporterData.open ? (
                <>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={handleReporterSearch}
                    onChange={dataOptions => {
                      setReporterData({
                        ...reporterData,
                        id: dataOptions.value.global_user.id,
                      })
                    }}
                    styles={{
                      control: provided => ({
                        ...provided,
                        width: 250,
                        height: 66,
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      Placeholder: () => null,
                      SingleValue: ({data}) => renderValue(data.value),
                    }}
                    menuPosition="fixed"
                  />
                  <Button
                    className={classes.buttonAction}
                    style={{margin: '0px 12px'}}
                    onClick={handleCancelReporter}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.buttonAction}
                    onClick={handleSelectReporter}
                    variant="contained"
                    color="secondary"
                    disabled={!reporterData.id}
                  >
                    Confirm
                  </Button>
                </>
              ) : (
                <>
                  <Avatar
                    src={
                      dataPerformance?.ReporterUser?.avatar ?? DEFAULT_AVATAR
                    }
                    className={classes.avatarSpacerReporter}
                  />

                  <div>
                    <div className={classes.dropdownWrapper}>
                      <span className={classes.nameReporter}>
                        {dataPerformance?.ReporterUser?.name ??
                          'Reporter not set'}
                      </span>
                      <EditIcon
                        onClick={() =>
                          setReporterData({...reporterData, open: true})
                        }
                        className={classes.editIcon}
                      />
                    </div>

                    <CustomTypography
                      fcolor="grey"
                      variant="caption"
                      component="p"
                      top="6px"
                    >
                      {dataPerformance?.ReporterUser
                        ?.people_work_placements?.[0]?.company_job_profile
                        ?.title ?? '-'}
                    </CustomTypography>
                  </div>
                </>
              )}
            </div>
          </div>
          <div id="assignee">
            <CustomTypography fcolor="grey" bottom="12px">
              Penerima tugas
            </CustomTypography>
            <div className={classes.dropdownWrapper}>
              {dataPerformance.performance_task_assignments
                ?.slice(0, 5)
                .map(data => {
                  return (
                    <Avatar
                      className={classes.assignee}
                      key={data.id}
                      alt={data.global_user.name}
                      src={data.global_user?.avatar ?? DEFAULT_AVATAR}
                    />
                  )
                })}
              {dataPerformance.performance_task_assignments?.length > 5 && (
                <Avatar style={{cursor: 'pointer'}}>
                  {`+${dataPerformance.performance_task_assignments.length -
                    5}`}
                </Avatar>
              )}
            </div>
          </div>
        </FlexBetween>
        <div id="progress-section">
          <StyledLinear
            variant="determinate"
            className={
              isComplete ? classes.completedProgress : classes.onProgress
            }
            value={isComplete ? 100 : dataPerformance.progress_percentage}
          />
          <FlexBetween>
            {openUpdate.open ? (
              <div className={classes.dropdownWrapper}>
                <TextField
                  className={classes.updateField}
                  variant="outlined"
                  type="number"
                  onChange={event =>
                    setOpenUpdate({...openUpdate, data: event.target.value})
                  }
                  value={openUpdate.data}
                />
                <div style={{alignSelf: 'center'}}>
                  <CustomTypography component="span" left="6px">
                    {weightUnit}
                  </CustomTypography>
                  <CustomTypography
                    component="span"
                    left="12px"
                    fcolor={isComplete ? 'green' : 'blue'}
                  >
                    {`(${renderStatus(dataPerformance.progress_percentage) ||
                      '-'})`}
                  </CustomTypography>
                </div>
                <Button
                  className={classes.buttonUpdate}
                  onClick={handleUpdate}
                  variant="contained"
                  color="secondary"
                  disabled={!openUpdate.data}
                  style={{margin: '0px 12px 0px 36px'}}
                >
                  Ubah
                </Button>
                <Button
                  className={classes.buttonUpdate}
                  onClick={() => setOpenUpdate(INITIAL_UPDATE_DATA)}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div>
                <CustomTypography
                  component="span"
                  fcolor={isComplete ? 'green' : undefined}
                >
                  {`${dataPerformance.progress_percentage?.toFixed(2)}%`}
                </CustomTypography>
                <CustomTypography
                  component="span"
                  fcolor="lightBlue"
                  left="12px"
                  cursor="pointer"
                  onClick={() =>
                    setOpenUpdate({
                      ...openUpdate,
                      open: true,
                      data: dataPerformance.progress,
                    })
                  }
                >
                  Ubah
                </CustomTypography>
              </div>
            )}
            <div>
              <CustomTypography component="span">
                {`${dataPerformance.progress} ${weightUnit} / ${dataPerformance.target} ${weightUnit}`}
              </CustomTypography>
              <CustomTypography
                component="span"
                left="12px"
                fcolor={isComplete ? 'green' : 'blue'}
              >
                {`(${renderStatus(dataPerformance.progress_percentage) ||
                  '-'})`}
              </CustomTypography>
            </div>
          </FlexBetween>
        </div>
      </div>
      <div id="tab-section" style={{marginTop: 4}}>
        <Tabs
          value={tabsValue}
          onChange={handleChangeTabs}
          indicatorColor="secondary"
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
        >
          <Tab label="Rincian" className={classes.tabRoot} />
          <Tab label="Deskripsi" className={classes.tabRoot} />
        </Tabs>
      </div>
      <div className={classes.instructions}>
        {tabsValue === 0 && <Detail data={data} type={'task'} />}
        {tabsValue === 1 && <Description data={data} type={'task'} />}
      </div>
      <AlertDialog
        open={openAction.open && openAction.action === 'alert'}
        handleClose={() => setOpenAction(INITIAL_ACTION)}
        feature={openAction.feature}
        message={openAction.message}
        type="Confirm"
      />
      <DeletePopup
        open={openAction.open && openAction.action === 'delete'}
        handleClose={() => setOpenAction(INITIAL_ACTION)}
        name={openAction.feature}
        description="This action will also delete all data in this"
        mutation={() => onDeleteTask()}
      />
    </Paper>
  )
}

export default withRouter(DetailPageComponent)
