import gql from 'graphql-tag'

export const GET_APPROVAL_LIST = gql`
  query($where: people_work_overtimes_bool_exp, $offset: Int, $limit: Int) {
    total: people_work_overtimes_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    people_work_overtimes(where: $where, limit: $limit, offset: $offset) {
      id
      id_display
      convert_to
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          avatar
        }
        people_work_salaries(
          where: {
            _and: [
              {start_date: {_is_null: false}}
              {start_date: {_lte: "now()"}}
            ]
          }
          order_by: {start_date: asc_nulls_last}
        ) {
          id
          value
          start_date
          end_date
          active
        }
        company_job_profile {
          id
          title
          job_profile_supervisor {
            id
            title
            people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
              id
              global_user {
                id
                name
              }
            }
          }
        }
      }
      time_overtime_policy {
        id
        name
        confirm_type
        convert_to_money
        minimum_duration_leave
        first_job_profile {
          id
          title
          people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
            id
            global_user {
              id
              name
            }
          }
        }
        second_job_profile {
          id
          title
          people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
            id
            global_user {
              id
              name
            }
          }
        }
      }
      overtime_fields
      date_added
      description
      datestart
      dateend
      cancelled_by_user {
        name
        id
      }
      globalUserByModifiedBy {
        name
        id
      }
      status
    }
  }
`
