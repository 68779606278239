import {useQuery} from '@apollo/react-hooks'
import React, {useState} from 'react'
import DoughnutCardWidget from '../../../../components/widgets/doughnut/DoughnutCardComponent'
import WidgetSection from '../../../../components/widgets/WidgetSection'
import {GET_ORGANIUZATION_AND_POSITION_DASHBOARD} from '../../../../graphql/queries'
import {COMPANY_ID} from '../../../../utils/globals'
import {
  WrapperChart,
  WrapperDashboard,
  WrapperWidgetDashboard,
} from '../../DashboardStyles'
import SkeletonChartDoughnut from '../../SkeletonChartDoughnut'
import MoreOrganizationAndPosition from './MoreOrganizationAndPosition'

const getColor = array => {
  const color = ['#039be5', '#4caf50', '#ffa000', '#ef4d5e']
  for (let index = 0; index < array.length; index++) {
    color.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
  }
  return color
}

export default function OrganizationAndPoisition() {
  const [more, setMore] = useState({
    open: false,
    title: '',
  })

  const [dataChart, setDataChart] = useState({
    organization: {},
    position: {},
  })

  const handleClickMore = target => {
    setMore({
      open: true,
      title: target,
    })
  }

  const {data, loading} = useQuery(GET_ORGANIUZATION_AND_POSITION_DASHBOARD, {
    wlb_skipPatch: true,
    variables: {
      company: COMPANY_ID,
    },
    onCompleted: data => {
      const COLOR_ORGANIZATION = getColor(data.getOrganization)
      const COLOR_POSITION = getColor(data.getPosition)

      setDataChart(() => {
        return {
          organization: {
            title: 'Organisasi',
            labels: data.getOrganization.map(({name}) => name),
            dataSlice: 4,
            more: {
              onClickMore: () => handleClickMore('Organisasi'),
              moreText: `+${data.len_data_organization.aggregate.count -
                4} Lebih Organisasi`,
            },
            datasets: [
              {
                data: data.getOrganization.map(({count}) => count),
                dataLabel: data.getOrganization.map(({count}) => {
                  const TOTAL_DATA =
                    data.total_getOrganization.aggregate.sum.count
                  return `${((count / TOTAL_DATA) * 100).toFixed(
                    2
                  )}% (${count})`
                }),
                backgroundColor: COLOR_ORGANIZATION,
                hoverBackgroundColor: COLOR_ORGANIZATION,
              },
            ],
            aggregate: {
              label: 'Organisasi',
              value: data.len_data_organization.aggregate.count,
            },
          },
          position: {
            title: 'Posisi Jabatan',
            labels: data.getPosition.map(({title}) => title),
            dataSlice: 4,
            more: {
              onClickMore: () => handleClickMore('Posisi Jabatan'),
              moreText: `+${data.len_data_position.aggregate.count -
                4} Lebih Jabatan`,
            },
            datasets: [
              {
                data: data.getPosition.map(({count}) => count),
                dataLabel: data.getPosition.map(({count}) => {
                  const TOTAL_DATA = data.total_getPosition.aggregate.sum.count
                  return `${((count / TOTAL_DATA) * 100).toFixed(
                    2
                  )}% (${count})`
                }),
                backgroundColor: COLOR_POSITION,
                hoverBackgroundColor: COLOR_POSITION,
              },
            ],
            aggregate: {
              label: 'Posisi Jabatan',
              value: data.len_data_position.aggregate.count,
            },
          },
        }
      })
    },
  })

  return (
    <>
      <WrapperDashboard style={{height: '100%'}}>
        <WrapperWidgetDashboard>
          <WidgetSection title="Organisasi">
            {!loading && data && (
              <WrapperChart>
                <DoughnutCardWidget
                  data={dataChart.organization}
                  labelType="list"
                />
              </WrapperChart>
            )}
            {loading && <SkeletonChartDoughnut />}
          </WidgetSection>
        </WrapperWidgetDashboard>

        <WrapperWidgetDashboard>
          <WidgetSection title="Posisi Jabatan">
            {!loading && data && (
              <WrapperChart>
                <DoughnutCardWidget
                  data={dataChart.position}
                  labelType="list"
                />
              </WrapperChart>
            )}
            {loading && <SkeletonChartDoughnut />}
          </WidgetSection>
        </WrapperWidgetDashboard>
      </WrapperDashboard>
      <MoreOrganizationAndPosition
        open={more.open}
        title={more.title}
        data={dataChart}
        onClose={() =>
          setMore(e => ({
            ...e,
            open: false,
            title: '',
          }))
        }
        loading={loading}
      />
    </>
  )
}
