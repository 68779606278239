import React from 'react'

import {
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  TablePagination,
  TextField,
  Typography,
  DialogContent,
} from '@material-ui/core'

import {
  AppliesToChild,
  AppliesToContainer,
  BlueIcon,
  FlexBetween,
  ModalHeader,
  WhiteIcon,
} from '../../../GlobalStyles'
import NoDataListFreeComponent from '../NoDataListFreeComponent'
import {TablePaginationActions} from '../table/TableOptions'

const DEFAULT_ROWS_PER_PAGE = [10, 25, 100]

export default function DetailDataSelectPopUp({
  open,
  title,
  onClose,
  data,
  appliesTo,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE,
}) {
  const [search, setSearch] = React.useState('')

  const [pagination, setPagination] = React.useState({
    offset: 0,
    limit: rowsPerPageOptions[0],
  })

  const listing = React.useMemo(() => {
    const arr = data.filter(name => name.toLowerCase().includes(search))

    return {
      size: arr.length,
      data: arr.slice(pagination.offset, pagination.limit),
    }
  }, [data, pagination, search])

  const handleChangePage = (e, page) => {
    e.preventDefault()
    setPagination({
      ...pagination,
      offset: page,
    })
  }

  const handleChangeRowsPerPage = e => {
    setPagination({
      offset: 0,
      limit: parseInt(e.target.value),
    })
  }

  React.useLayoutEffect(() => {
    if (open) {
      setSearch('')
    }
  }, [open])

  return (
    <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="md">
      <ModalHeader>
        <div style={{display: 'flex'}}>
          <Typography style={{color: '#ffffff', alignSelf: 'center'}}>
            {title}
          </Typography>
        </div>
        <IconButton aria-label="close" onClick={onClose}>
          <WhiteIcon>close</WhiteIcon>
        </IconButton>
      </ModalHeader>

      <DialogContent style={{padding: 24}}>
        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          fullWidth
          value={search}
          onChange={e => {
            setSearch(e.target.value)
            setPagination({
              offset: 0,
              limit: 20,
            })
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BlueIcon>search</BlueIcon>
              </InputAdornment>
            ),
          }}
          style={{marginBottom: 24}}
        />

        <FlexBetween>
          <Typography variant="body2" style={{fontWeight: 600}} gutterBottom>
            Applied to {listing.size} {appliesTo}
          </Typography>
        </FlexBetween>

        <AppliesToContainer>
          {listing.size === 0 ? (
            <NoDataListFreeComponent
              message1="Sorry, No List"
              message2="It seems we can't find any results based on your search"
            />
          ) : (
            listing.data.map((name, idx) => (
              <AppliesToChild key={idx}>{name}</AppliesToChild>
            ))
          )}
        </AppliesToContainer>

        <Divider style={{marginTop: 16}} />

        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={listing.size}
          rowsPerPage={pagination.limit}
          page={pagination.offset}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </DialogContent>
    </Dialog>
  )
}
