import React from 'react'
import {useApolloClient, useQuery} from '@apollo/react-hooks'
import {useSnackbar} from 'notistack'

import DeletePopup from '../../../../../../shared-components/popup/DeletePopup'
import {GridListing, ItemWrapper} from '../../../DetailEmployeeStyles'
import LoadingEmployee from '../../../../LoadingEmployee'
import {ButtonAddDataTab} from '../data-tab-shared'
import InsuranceDataItem from './InsuranceDataItem'
import AddEditInsuranceData from './AddEditInsuranceData'

import {GET_INSURANCE_DATA_LIST} from '../../../../../../../graphql/queries'
import {DELETE_IDENTITY_CARD} from '../../../../../../../graphql/mutations'

const INITIAL_DETAIL_STATE = {
  openForm: false,
  openDelete: false,
  data: null,
}

const InsuranceData = props => {
  const {userId} = props

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [detailState, setDetailState] = React.useState(INITIAL_DETAIL_STATE)

  const {data, error, refetch} = useQuery(GET_INSURANCE_DATA_LIST, {
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {user: userId},
  })

  const [government, premium] = React.useMemo(() => {
    const gov = []
    const pre = []

    if (data) {
      for (const item of data.insurances) {
        switch (item.global_insurance_list.type) {
          case 'government': {
            gov.push(item)
            break
          }
          case 'premium': {
            pre.push(item)
            break
          }
        }
      }
    }

    return [gov, pre]
  }, [data])

  if (error) {
    return <div>{'' + error}</div>
  }

  if (!data) {
    return <LoadingEmployee noborder smallpadding />
  }

  const handleInsuranceEdit = data => {
    setDetailState({...detailState, openForm: true, data: data})
  }

  const handleInsuranceAdd = () => {
    setDetailState({...detailState, openForm: true, data: null})
  }

  const handleFormClose = () => {
    setDetailState({...detailState, openForm: false})
  }

  const handleFormSubmit = shouldRefetch => {
    handleFormClose()

    if (shouldRefetch) {
      refetch()
    }
  }

  const handleDeleteOpen = () => {
    setDetailState({...detailState, openDelete: true})
  }

  const handleDeleteClose = action => {
    setDetailState({
      ...detailState,
      openForm: action === 'action-cancel',
      openDelete: false,
    })
  }

  const handleDeleteConfirm = () => {
    const data = detailState.data

    const promise = client.mutate({
      mutation: DELETE_IDENTITY_CARD,
      variables: {
        id: data.number,
        type: data.type,
        dateAdded: data.date_added,
      },
    })

    promise.then(
      () => {
        refetch()
        enqueueSnackbar('Data Asuransi Dihapus', {variant: 'success'})
      },
      err => {
        console.error(err)
        enqueueSnackbar(`Gagal untuk menghapus data asuransi`, {
          variant: 'error',
        })
      }
    )
  }

  return (
    <>
      <GridListing style={{'--grid-items': 1}}>
        {government.length > 0 && (
          <ItemWrapper>
            <h1 className="title" style={{marginBottom: 8}}>
              Asuransi Utama
            </h1>

            <GridListing style={{padding: 0}}>
              {government.map(item => (
                <InsuranceDataItem
                  key={item.date_added}
                  data={item}
                  onEdit={() => handleInsuranceEdit(item)}
                />
              ))}
            </GridListing>
          </ItemWrapper>
        )}

        {premium.length > 0 && (
          <ItemWrapper>
            <h1 className="title" style={{marginBottom: 8}}>
              Asuransi Tambahan
            </h1>

            <GridListing style={{padding: 0}}>
              {premium.map(item => (
                <InsuranceDataItem
                  key={item.date_added}
                  data={item}
                  onEdit={() => handleInsuranceEdit(item)}
                />
              ))}
            </GridListing>
          </ItemWrapper>
        )}
      </GridListing>

      <ButtonAddDataTab onClick={handleInsuranceAdd}>
        Tambahkan Data Asuransi
      </ButtonAddDataTab>

      <AddEditInsuranceData
        open={detailState.openForm}
        userId={userId}
        initialData={detailState.data}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        onDelete={handleDeleteOpen}
      />

      <DeletePopup
        name={detailState.data?.global_insurance_list.name}
        open={detailState.openDelete}
        mutation={handleDeleteConfirm}
        handleClose={handleDeleteClose}
      />
    </>
  )
}

export default InsuranceData
